import './HubVolunteerModal.scss';
import { IApplication, IGigSummaryFE, IGigVolunteerSummary } from '@gigit/interfaces';
import { OwnerType, getAddressParts, formatQuery, typeHelpers } from '../../../../helpers';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../../shared/PopupMenu/PopupMenu';
import React, { FC, useState } from 'react';
import TitleSection, {
  ITabConfig,
  ITitleSectionTab,
} from '../../../UserDashboard/TitleSection/TitleSection';
import { gigRequestActions, userRequestActions } from '../../../../requestActions';
import AddToCalendarModal from '../../../shared/AddToCalendarModal/AddToCalendarModal';
import Button from '../../../Button/Button';
import { Constants } from '@gigit/constants';
import EditVolunteerOpportunity from '../../HubManagement/shared/EditVolunteerOpportunity/EditVolunteerOpportunity';
import { IUserState } from '../../../../reducers/user';
import Modal from '../../../Modal/Modal';
import { OwnerObjectTypes } from '../../../../interfaces';
import { uiConstants } from '../../../../constants';
import { useEffect } from 'react';
import useSharedModal from '../../../../hooks/useSharedModal';
import useToastDispatcher from '../../../../hooks/useToaster';
import { Prompt } from '../../../Prompt/Prompt';
import OpportunityDetails from './OpportunityDetails/OpportunityDetails';
import Interested from './Interested/Interested';
import Settings from './Settings/Settings';
import { EStyleTypeVolunteerCard } from '../../../VolunteerCard/VolunteerCard';

interface IProps {
  show: boolean;
  onClose(): void;
  onUpdate(): void;
  onDeleteVolunteerOpportunity(): void;
  hideInterested?: boolean;
  styleType?: EStyleTypeVolunteerCard;
  isCausePosting?: boolean;
  openCalendarModal?: (gig_id: string) => void;
  selected: IGigSummaryFE | null;
  userState?: IUserState;
}

const HubVolunteerModal: FC<IProps> = (props: IProps) => {
  const [applicants, setApplicants] = useState<IApplication[]>();
  const [gigVolunteers, setGigVolunteers] = useState<IGigVolunteerSummary | null>(null);
  const [activeTabId, setActiveTabId] = useState<string>('opportunityDetails');
  const sharedModals = useSharedModal();
  const [showHeadingMenu, setShowHeadingMenu] = useState<boolean>(false);
  const [isInterested, setIsInterested] = useState<boolean | null>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showDeleteConfirmPrompt, setShowDeleteConfirmPrompt] = useState<boolean>(false);
  const [showAddToCalendarModal, setShowAddToCalendarModal] = useState<boolean>(false);
  const [showEditOpportunityModal, setShowEditOpportunityModal] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string>('');

  const headingMenuConfig: IShowPopupConfig = {
    showMenu: showHeadingMenu,
    setShowMenu: setShowHeadingMenu,
    position: {
      type: 'bottom',
      alternate: 'start',
      additionalLeft: 50,
    },
  };

  const popupMenuConfig: IShowPopupConfig = {
    showMenu: showMenu,
    setShowMenu: setShowMenu,
    position: {
      type: 'bottom',
      alternate: 'start',
      additionalLeft: 250,
    },
  };

  const { dispatchToastError } = useToastDispatcher();

  async function getApplicants() {
    try {
      typeHelpers.assertNotNullOrUndefined(props.selected?.id, 'Expected Gig ID');

      const result: IApplication[] = await gigRequestActions.getVolunteersList(
        props.selected.id,
        formatQuery({
          filters: [
            {
              type: 'boolean',
              label: 'is_interested',
              value: 'true',
              id: 'is_interested',
            },
          ],
        }),
      );
      setApplicants(result);
    } catch (error) {
      dispatchToastError(error, 'Get Applicants');
    }
  }

  async function getGigVolunteer() {
    if (props.selected?.id) {
      try {
        const result = await gigRequestActions.getGigVolunteer(
          props.selected.id,
          props.userState?.user?.id || '',
        );
        setIsInterested(result.is_interested ?? null);
        setGigVolunteers(result);
      } catch (error) {
        dispatchToastError(error, 'Get Applicants');
      }
    }
  }

  const tabs: ITitleSectionTab[] = [
    { id: 'opportunityDetails', title: 'Opportunity Details', show: true },
    {
      id: 'interested',
      title: `Interested (${applicants?.length || 0})`,
      show: userRole === uiConstants.role.admin || false,
    },
    { id: 'settings', title: 'Settings', show: userRole === uiConstants.role.admin || false },
  ];

  const headingMenuItems: IPopupMenuItem[] = [
    {
      id: '0',
      label: 'Edit Opportunity',
      isSelected: false,
      onClick: () => {
        setShowEditOpportunityModal(true);
      },
    },
    {
      id: '1',
      label: 'Delete Opportunity',
      isSelected: false,
      customClass: 'delete-opportunity',
      onClick: () => {
        setShowDeleteConfirmPrompt(true);
      },
    },
  ];

  const checkIsinterested = (flag: string) => {
    if (gigVolunteers) {
      switch (flag) {
        case 'is_interested':
          return gigVolunteers.is_interested;
        case 'is_confirmed':
          return gigVolunteers.is_confirmed;
        default:
          return false;
      }
    }
  };

  const contextMenuItems: IPopupMenuItem[] = [
    {
      id: '0',
      label: "I'm Interested",
      icon:
        checkIsinterested('is_interested') && !checkIsinterested('is_confirmed')
          ? 'fa fa-dot-circle-o'
          : 'fa fa-circle-o',
      onClick: async () => {
        setIsLoading(true);
        await gigRequestActions.setVolunteerFlag(
          props.selected?.id || '',
          props.userState?.user?.id || '',
          'is_interested',
          true,
        );
        props.onUpdate();
        setIsLoading(false);
      },
    },
    {
      id: '1',
      label: 'Confirm RSVP',
      icon: checkIsinterested('is_confirmed') ? 'fa fa-dot-circle-o' : 'fa fa-circle-o',
      onClick: async () => {
        setIsLoading(true);
        await gigRequestActions.setVolunteerFlag(
          props.selected?.id || '',
          props.userState?.user?.id || '',
          'is_confirmed',
          true,
        );
        props.onUpdate();
        setIsLoading(false);
      },
    },
    {
      id: '2',
      label: 'Not Interested',
      icon:
        !checkIsinterested('is_confirmed') && !checkIsinterested('is_interested')
          ? 'fa fa-dot-circle-o'
          : 'fa fa-circle-o',
      onClick: async () => {
        setIsLoading(true);
        await gigRequestActions.setVolunteerFlag(
          props.selected?.id || '',
          props.userState?.user?.id || '',
          'is_interested',
          false,
        );
        props.onUpdate();
        setIsLoading(false);
      },
    },
    {
      id: '3',
      label: 'Share to Calendar',
      icon: 'fa fa-calendar',
      onClick: () => {
        setShowAddToCalendarModal(true);
      },
    },
  ];

  const tabConfig: ITabConfig = {
    tabs: tabs.filter((t) => t.show),
    activeTabId: activeTabId,
    onTabClick: (v) => {
      setActiveTabId(v);
    },
  };

  async function getHubRoles() {
    const userRoleName = await userRequestActions.getUserHubRole(props.selected?.hub_id || '');
    setUserRole(userRoleName.role_name);
  }

  useEffect(() => {
    getGigVolunteer();
    if (props.selected?.hub_id) {
      getHubRoles();

      if (
        userRole === uiConstants.role.admin &&
        props.styleType === EStyleTypeVolunteerCard.HUB_ONLY
      ) {
        getApplicants();
      }
    }
  }, [loading, userRole, props.onUpdate, props.selected?.hub_id]);

  useEffect(() => {
    getHubRoles();
  }, []);

  return (
    <Modal
      class="HubVolunteer"
      show={props.show}
      onClose={() => props.onClose()}
      closeIcon="fas fa-times"
    >
      {props.selected && (
        <AddToCalendarModal
          showModal={showAddToCalendarModal}
          onCloseModal={() => setShowAddToCalendarModal(false)}
          eventToAdd={{
            title: props.selected.title,
            start: props.selected?.start_date ? new Date(props.selected.start_date) : new Date(),
            end: props.selected?.end_date ? new Date(props.selected.end_date) : new Date(),
            location: props.selected?.locations
              ? getAddressParts(props.selected.locations[0], ['line1', 'line2', 'city', 'country'])
              : 'N/A',
          }}
        />
      )}

      <Prompt
        show={showDeleteConfirmPrompt}
        title="Delete Volunteer Opportunity"
        message="Are you sure you want to delete the Volunteer Opportunity?"
        yesMessage="Yes"
        yesClass="fa fa-trash"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={props.onDeleteVolunteerOpportunity}
        onClose={() => {
          setShowDeleteConfirmPrompt(false);
        }}
      />

      <Modal
        show={showEditOpportunityModal}
        onClose={() => setShowEditOpportunityModal(false)}
      >
        <EditVolunteerOpportunity
          onCancel={() => setShowEditOpportunityModal(false)}
          onSaveCallback={() => {}}
          hub_id={props.selected?.hub_id || ''}
          volunteerOpportunity={props.selected as IGigSummaryFE}
        />
      </Modal>

      <div className="HubVolunteerModal">
        {props.selected && (
          <div className="volunteer-content">
            <div className="image-container">
              <div className="bg-image-container">
                {props.selected.cover_image_url ? (
                  <img
                    src={props.selected.cover_image_url}
                    alt="Gig Image"
                    className={'gig-image'}
                    loading="lazy"
                  />
                ) : (
                  <div className="empty-cover-image" />
                )}
                <div className="overlay"></div>
              </div>
            </div>

            <div className="ModalContent">
              <div className="heading-post">
                <div className="min-container">
                  <a href={`/gig/${props.selected.id}`}>
                    {props.selected.profile_image_url ? (
                      <img
                        src={`${props.selected.profile_image_url}`}
                        alt=""
                        className="logo"
                      />
                    ) : (
                      <div className="empty-img" />
                    )}
                  </a>
                  <div
                    className="owner-info-container"
                    notranslate="yes"
                  >
                    <h5>
                      <a href={`/gig/${props.selected.id}`}>{`${props.selected.title}`}</a>
                    </h5>
                    <span>{props.selected?.hub?.title}</span>
                  </div>
                </div>
                {userRole === uiConstants.role.admin && (
                  <PopupMenu
                    showMenuConfig={headingMenuConfig}
                    menuItems={headingMenuItems}
                    popupMenuClass="heading-menu"
                    className={`${showHeadingMenu ? 'show' : ''}`}
                    onClick={(event: { stopPropagation: () => void }) => {
                      event.stopPropagation();
                      setShowHeadingMenu(!showHeadingMenu);
                    }}
                  >
                    <div className="menu">
                      <i className="fas fa-ellipsis-v" />
                    </div>
                  </PopupMenu>
                )}
              </div>

              <div className="volunteer-actions">
                {props.hideInterested && (
                  <PopupMenu
                    showMenuConfig={popupMenuConfig}
                    menuItems={contextMenuItems}
                    popupMenuClass="status-menu"
                    className={`${showMenu ? 'show' : ''}`}
                    onClick={(event: { stopPropagation: () => void }) => {
                      event.stopPropagation();
                      setShowMenu(!showMenu);
                    }}
                  >
                    <div className="interested-container">
                      {isInterested && (
                        <>
                          <i className="fas fa-star" /> I'm Interested
                        </>
                      )}
                      {isInterested === false && (
                        <>
                          <i className="far fa-star" /> I'm Not Interested
                        </>
                      )}
                      {isInterested === null && 'Are you interested?'}
                    </div>
                  </PopupMenu>
                )}

                <Button
                  icon="fas fa-share-alt"
                  text="Share"
                  onClick={() => {
                    sharedModals.showShareModal({
                      ownerType: uiConstants.ownerType.gig as OwnerType,
                      ownerObject: props.selected as OwnerObjectTypes,
                      handle: props.selected?.handle || '',
                    });
                    props.onClose();
                  }}
                />
              </div>
              <TitleSection
                title=""
                tabConfig={tabConfig}
              />
              {activeTabId === 'opportunityDetails' && (
                <OpportunityDetails
                  selected={props.selected}
                  totalApplicants={props.selected.number_of_interested || 0}
                />
              )}
              {activeTabId === 'interested' && (
                <Interested
                  selected={props.selected}
                  applicants={applicants || []}
                  onUpdate={props.onUpdate}
                />
              )}
              {activeTabId === 'settings' && (
                <Settings
                  selected={props.selected}
                  isCausePosting={props.isCausePosting || false}
                  applicants={applicants || []}
                  onUpdate={props.onUpdate}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default HubVolunteerModal;
