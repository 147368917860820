import React from 'react';

import './CommunityFeedPostSkeleton.scss';

export default function CommunityFeedPostSkeleton() {
  return (
    <div className="CommunityFeedPostSkeleton">
      <div className="post-header">
        <div className="header-left">
          <div className="header-img"></div>
          <div className="header-text">
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="header-right">
          <div></div>
        </div>
      </div>
      <div className="post-content">
        <span></span>
        <span></span>
        <div></div>
      </div>
      <div className="post-bottom">
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
