import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { formatQuery, handleInputChange, routes, swapRouteParams, toastError } from '../../helpers';
import {
  IPage,
  IAddress,
  IEventSummaryFE,
  ICampaign,
  IAuctionItem,
  IPageComponent,
} from '@gigit/interfaces';
import { IEventState } from '../../reducers/event';
import { IGroupState } from '../../reducers/group';
import { IHubState } from '../../reducers/hub';
import { IMediaAlbumState } from '../../reducers/mediaAlbum';
import { IProfileState } from '../../reducers/profile';
import { IUserState } from '../../reducers/user';
import { IGigState } from '../../reducers/gig';
import { IAuctionState } from '../../reducers/auction';
import { IStoreState } from '../../reducers/store';
import { createToast } from '../../actions/toaster';
import { deleteGroupPageComponent, updateGroupPageComponent } from '../../actions/group';
import { deleteUserPageComponent, updateUserPageComponent } from '../../actions/user';
import { deleteEventPageComponent, updateEventPageComponent } from '../../actions/event';
import { clearEventError } from '../../actions/event';
import { updateMediaAlbum, uploadMediaAlbum, createMediaAlbum } from '../../actions/mediaAlbum';
import { getProfileStats } from '../../actions/profile';
import { Prompt } from '../Prompt/Prompt';
import EventList from './EventList/EventList';
import Description from './Description/Description';
import Locations from './Locations/Locations';
import MediaAlbum from './MediaAlbum/MediaAlbum';
import GigList from './GigList/GigList';
import Fundraiser from './Fundraiser/Fundraiser';
import Skills from './Skills/Skills';
import Causes from './Causes/Causes';
import Auction from './Auction/Auction';
import Donations from './Donations/Donations';
import UserStats, { stats } from './UserStats/UserStats';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import TextField from '../TextField/TextField';
import ImageUpload from '../ImageUpload/ImageUpload';
import StoreItemForm from '../StoreItemForm/StoreItemForm';
import AuctionItemForm from '../AuctionItemForm/AuctionItemForm';
import Store from './Store/Store';
import Sponsors from './Sponsors/Sponsors';
import Livestream from './Livestream/Livestream';
import LivestreamManagement from './Livestream/LivestreamManagement/LivestreamManagement';
import CreateEventModal from '../CreateEventModal/CreateEventModal';
import CreateGigModal from '../CreateGigModal/CreateGigModal';
import './PageComponent.scss';
import FundraisingEventList from './FundraisingEventList/FundraisingEventList';
import ChildEventList from './ChildEventList/ChildEventList';
import {
  IAuctionComponent,
  IToast,
  IUserStatsComponentMetadata,
  ReduxActionType,
} from '../../interfaces';
import axios from 'axios';
import errorHelpers from '../../helpers/errorHelpers';
import { IOwnerObject } from '../../interfaces';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import AddLocationModal from '../AddLocationModal/AddLocationModal';
import PageComponentPaginator from './shared/PageComponentPaginator/PageComponentPaginator';
import { uiConstants } from '../../constants';
import { getStoreItems, StoreItemOwnerType } from '../../actions/store';

interface IProps extends RouteComponentProps<any> {
  getStoreItems: ReduxActionType<typeof getStoreItems>;
  eventState: IEventState;
  groupState: IGroupState;
  auctionState: IAuctionState;
  storeState: IStoreState;
  gigState: IGigState;
  profileState: IProfileState;
  userState: IUserState;
  mediaAlbumState: IMediaAlbumState;
  hubState: IHubState;
  component: IPageComponent;
  permissions?: any;
  page: IPage;
  deleteGroupPageComponent(groupId: string, pageId: string, componentId: string): void;
  deleteUserPageComponent(pageId: string, componentId: string): void;
  deleteEventPageComponent(eventId: string, pageId: string, componentId: string): void;
  createToast(toast: IToast): void;
  updateMediaAlbum(item: Partial<IMediaAlbumState>): void;
  uploadMediaAlbum(): void;
  createMediaAlbum(_parentType: string): void;
  clearEventError(): void;
  updateEventPageComponent(
    eventId: string,
    _pageId: string,
    _componentId: string,
    _payload: any,
    callback?: () => void,
  ): void;
  updateGroupPageComponent(
    groupId: string,
    _pageId: string,
    _componentId: string,
    _payload: any,
    callback?: () => void,
  ): void;
  updateUserPageComponent(_pageId: string, _componentId: string, _payload: any): void;
  getProfileStats(userId: string): void;
  onDelete?(): void;
  onComponentUpdate?(disableLoad?: boolean): void;
  campaign?: ICampaign | null;
  dragProps: any;
  currentPageIndex: number;
  owner: IOwnerObject;
  isUserStillLoggedIn: boolean; // TODO: `isUserStillLoggedIn` should just be built into a redux selector instead of needing to be passed down through multiple cimponents. see: https://github.com/gigitmarket/gigit-platform/blob/6ce501044bf79b6d5d60275c3216671402fddaf5/packages/ui/gigit-ui-web/src/selectors/user.tsx#L5
}

interface IState {
  showDelete: boolean;
  showLocationForm: boolean;
  showMediaForm: boolean;
  showEventForm: boolean;
  showGigForm: boolean;
  mediaAlbumName: string;
  edit: boolean;
  form_id: string;
  campaign_id: string;
  showAuctionForm: boolean;
  componentObjectIds: any[];
  showStoreForm: boolean;
  showEventManagement: boolean;
  showStoreManagement: boolean;
  showUserStatsManagement: boolean;
  userStatsVisibility: IUserStatsComponentMetadata;
  showAuctionItemForm: boolean;
  showSponsorVisibility: boolean;
  showLivestreamManage: boolean;
  showChildEventManagement: boolean;
  groupEvents: IEventSummaryFE[];
  auctionItems: IAuctionItem[];
  locations: IAddress[];
  storeItemSearchValue: string;
  storeItemSearchTimeout: null | ReturnType<typeof setTimeout>;
}

class PageComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showDelete: false,
      showLocationForm: false,
      showMediaForm: false,
      showEventForm: false,
      showGigForm: false,
      mediaAlbumName: 'My New Album',
      edit: false,
      form_id: '',
      campaign_id: '',
      showAuctionForm: false,
      componentObjectIds: [],
      showStoreForm: false,
      showEventManagement: false,
      showStoreManagement: false,
      showUserStatsManagement: false,
      userStatsVisibility: {},
      showAuctionItemForm: false,
      showSponsorVisibility: false,
      showLivestreamManage: false,
      showChildEventManagement: false,
      groupEvents: [],
      auctionItems: [],
      locations: [],
      storeItemSearchValue: '',
      storeItemSearchTimeout: null,
    };

    this.deleteComponent = this.deleteComponent.bind(this);
    this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
    this.renderComponent = this.renderComponent.bind(this);
    this.toggleEventForm = this.toggleEventForm.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.onMediaItemSelect = this.onMediaItemSelect.bind(this);
    this.toggleLocationForm = this.toggleLocationForm.bind(this);
    this.hasPagePermission = this.hasPagePermission.bind(this);
    this.isLoggedInUser = this.isLoggedInUser.bind(this);
    this.toggleAuctionForm = this.toggleAuctionForm.bind(this);
    this.toggleComponentItem = this.toggleComponentItem.bind(this);
    this.saveComponentItems = this.saveComponentItems.bind(this);
    this.toggleStoreManagement = this.toggleStoreManagement.bind(this);
    this.onAuctionSave = this.onAuctionSave.bind(this);
    this.getGroupEvents = this.getGroupEvents.bind(this);
  }

  componentDidMount() {
    if (
      this.props.isUserStillLoggedIn &&
      this.props.owner.ownerType !== uiConstants.ownerType.gig
    ) {
      this.props.getStoreItems({
        ownerType: this.props.owner.ownerType as StoreItemOwnerType,
        ownerId: this.props.owner.ownerId,
      });
    }

    this.getComponentData();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.state.showStoreManagement !== prevState.showStoreManagement &&
      this.props.owner.ownerType !== uiConstants.ownerType.gig &&
      this.props.isUserStillLoggedIn
    ) {
      this.fetchStoreItemsData();
    }

    if (
      this.props.component !== prevProps.component ||
      this.props.permissions !== prevProps.permissions
    ) {
      this.getComponentData();
    }

    if (
      this.props.component.content_references?.object_ids !==
      prevProps.component.content_references?.object_ids
    ) {
      let _ids: string[] = [];

      if (
        this.props.component.content_references &&
        this.props.component.content_references.object_ids
      ) {
        _ids = [...this.props.component.content_references.object_ids];
      }

      this.setState({
        componentObjectIds: _ids,
      });
    }

    if (this.props.mediaAlbumState.componentId === this.props.component.id) {
      if (prevProps.mediaAlbumState !== this.props.mediaAlbumState) {
        if (
          this.props.mediaAlbumState.totalItems === this.props.mediaAlbumState.itemsLoaded &&
          this.props.mediaAlbumState.totalItems !== 0 &&
          !this.props.mediaAlbumState.isCreated
        ) {
          this.props.createMediaAlbum(this.props.owner.ownerType);
        }
      }

      if (
        this.state.showMediaForm &&
        this.props.mediaAlbumState.totalItems === this.props.mediaAlbumState.itemsLoaded &&
        this.props.mediaAlbumState.totalItems !== 0 &&
        (prevProps.groupState.groupMediaAlbums !== this.props.groupState.groupMediaAlbums ||
          prevProps.eventState.mediaAlbums !== this.props.eventState.mediaAlbums ||
          prevProps.profileState.mediaAlbums !== this.props.profileState.mediaAlbums ||
          prevProps.gigState.mediaAlbums !== this.props.gigState.mediaAlbums)
      ) {
        this.toggleMediaForm(false);
      }
    }

    if (
      !this.props.eventState.success &&
      this.props.eventState.error !== '' &&
      this.state.showEventForm
    ) {
      const toast = toastError(this.props.eventState.error, 'Event Error');
      this.props.createToast(toast);

      this.props.clearEventError();
    } else if (this.props.eventState.success) {
      this.props.clearEventError();

      this.setState({
        showEventForm: false,
      });
    }
  }

  getComponentData() {
    if (this.props.isUserStillLoggedIn) {
      if (this.props.owner.ownerType === uiConstants.ownerType.user) {
        this.props.getProfileStats(this.props.profileState.user.id!);
      }

      if (
        this.props.component.component_type === uiConstants.ownerType.event &&
        this.props.permissions['MANAGE_GROUP_EVENTS']
      ) {
        this.getGroupEvents('?event_type=default');
      }

      if (
        this.props.component.component_type === 'fundraising_event' &&
        this.props.permissions['MANAGE_GROUP_EVENTS']
      ) {
        this.getGroupEvents('?event_type=obo_fundraiser');
      }

      let _ids: string[] = [];

      if (
        this.props.component.content_references &&
        this.props.component.content_references.object_ids
      ) {
        _ids = [...this.props.component.content_references.object_ids];
      }

      this.setState({
        componentObjectIds: _ids,
      });
    }

    // Moved out of the condtional above because there are no guards preventing this from being called.
    if (this.props.component.component_type === 'location') {
      this.getLocations();
    }
  }

  getLocations() {
    const route: { [key: string]: string } = {
      group: swapRouteParams(routes.GET_GROUP_LOCATIONS, { groupId: this.props.owner.ownerId }),
      event: swapRouteParams(routes.GET_EVENT_LOCATIONS, { eventId: this.props.owner.ownerId }),
      gig: swapRouteParams(routes.GET_GIG_LOCATIONS, { gigId: this.props.owner.ownerId }),
    };

    axios
      .get<IAddress[]>(route[this.props.owner.ownerType])
      .then((response) => {
        this.setState({ locations: response.data });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Cannot get locations');
        this.props.createToast(toast);
      });
  }

  getGroupEvents(query: string = '') {
    let url = swapRouteParams(routes.GET_EVENTS_BY_GROUP + query, {
      groupId: this.props.owner.ownerId,
    });

    axios
      .get<IEventSummaryFE[]>(url)
      .then((response) => {
        this.setState({
          groupEvents: response.data,
        });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Get Events');
        this.props.createToast(toast);
      });
  }

  hubHasApprovedGroups() {
    if (this.props.hubState.current?.groups) {
      for (let g in this.props.hubState.current.groups) {
        let item;
        item = this.props.hubState.current.groups[g];

        if (item && item.hubs) {
          item.hubs.forEach((h) => {
            if (
              h &&
              h.group_status &&
              h.group_status.code !== 'rejected' &&
              h.group_status.code !== 'main'
            ) {
              return true;
            }
          });
        }
      }
    }

    return false;
  }

  fetchStoreItemsData(): void {
    let query = formatQuery({
      sort: [
        {
          id: 'name',
          order: 'asc',
        },
      ],
      filters:
        this.state.storeItemSearchValue !== ''
          ? [
              {
                id: 'name',
                label: 'name',
                type: 'text',
                value: this.state.storeItemSearchValue,
              },
            ]
          : undefined,
    });

    this.props.getStoreItems({
      ownerType: this.props.owner.ownerType as StoreItemOwnerType,
      ownerId: this.props.owner.ownerId,
      query,
    });
  }

  storeItemSearch() {
    if (this.state.storeItemSearchTimeout !== null) {
      clearTimeout(this.state.storeItemSearchTimeout);
    }

    this.setState({
      storeItemSearchTimeout: setTimeout(() => {
        if (this.props.isUserStillLoggedIn) {
          this.fetchStoreItemsData();
        }

        this.setState({
          storeItemSearchTimeout: null,
        });
      }, 300),
    });
  }

  renderComponent() {
    // TODO: Remove {...props} in these calls...
    const id = this.props.component.id;
    switch (this.props.component.component_type) {
      // Old gigs still have "description" component type by default
      case 'description':
      case 'content_block':
        return (
          <Description
            key={id}
            edit={this.state.edit}
            {...this.props}
            hasEditPermissions={this.userHasEditPermissions()}
            showEdit={() => this.toggleEdit(true)}
            alternativeDescriptionValue={this.props?.gigState?.gig?.description}
          />
        );
      case 'fundraising_event':
        return (
          <FundraisingEventList
            key={id}
            edit={this.state.edit}
            {...this.props}
            hasEditPermissions={this.userHasEditPermissions()}
            onCreateEventClicked={() => this.toggleEventForm(true)}
            onManageEventsClicked={() => this.toggleEventManagement(true)}
          />
        );
      case 'event':
        return (
          <EventList
            key={id}
            edit={this.state.edit}
            {...this.props}
            hasEditPermissions={this.userHasEditPermissions()}
            onCreateEventClicked={() => this.toggleEventForm(true)}
            onManageEventsClicked={() => this.toggleEventManagement(true)}
          />
        );
      case 'child_event':
        return (
          <ChildEventList
            key={id}
            edit={this.state.edit}
            {...this.props}
            hasEditPermissions={this.userHasEditPermissions()}
            onManageChildEventsClicked={() => this.toggleChildEventManagement(true)}
          />
        );
      case 'store_item':
        return (
          <Store
            key={id}
            onComponentUpdate={() => {
              if (this.props.onComponentUpdate) {
                this.props.onComponentUpdate();
              }
            }}
            {...this.props}
            edit={this.state.edit}
            hasEditPermissions={this.userHasEditPermissions()}
            onCreateStoreClicked={() => this.toggleStoreForm(true)}
            onManageStoreClicked={() => this.toggleStoreManagement(true)}
          />
        );
      case 'location':
        return (
          <Locations
            key={id}
            {...this.props}
            edit={this.state.edit}
            hasEditPermissions={this.userHasEditPermissions()}
            locations={this.state.locations}
            onCreateLocationClicked={() => this.toggleLocationForm(true)}
          />
        );
      case 'media_album':
        return (
          <MediaAlbum
            key={id}
            {...this.props}
            edit={this.state.edit}
            hasEditPermissions={this.userHasEditPermissions()}
            onCreateAlbumClicked={() => this.toggleMediaForm(true)}
            onComponentUpdate={() => {
              if (this.props.onComponentUpdate) {
                this.props.onComponentUpdate();
              }
            }}
          />
        );
      case 'gig':
        return (
          <GigList
            key={id}
            {...this.props}
            edit={this.state.edit}
            hasEditPermissions={this.userHasEditPermissions()}
            onCreateGigClicked={() => this.toggleGigForm(true)}
            onComponentUpdate={() => {
              if (this.props.onComponentUpdate) {
                this.props.onComponentUpdate();
              }
            }}
          />
        );
      case 'fundraising':
        return (
          <Fundraiser
            key={id}
            {...this.props}
            edit={this.state.edit}
            hasEditPermissions={this.userHasEditPermissions()}
          />
        );
      case 'skill':
        return (
          <Skills
            key={id}
            {...this.props}
            edit={this.state.edit}
            hasEditPermissions={this.userHasEditPermissions()}
          />
        );
      case 'cause':
        return (
          <Causes
            key={id}
            {...this.props}
            edit={this.state.edit}
            hasEditPermissions={this.userHasEditPermissions()}
            onComponentUpdate={() => {
              if (this.props.onComponentUpdate) {
                this.props.onComponentUpdate();
              }
            }}
          />
        );
      case 'auction':
        return (
          <Auction
            key={id}
            {...this.props}
            edit={this.state.edit}
            hasEditPermissions={this.userHasEditPermissions()}
            onManageEventsClicked={() => this.toggleAuctionForm(true)}
            onCreateAuctionItemClicked={() => this.toggleAuctionItemForm(true)}
          />
        );
      case 'donations':
        return (
          <Donations
            key={id}
            {...this.props}
            edit={this.state.edit}
            hasEditPermissions={this.userHasEditPermissions()}
          />
        );
      case 'user_stats':
        return (
          <UserStats
            key={id}
            userId={this.props.profileState.user.id!}
            edit={this.state.edit}
            page={this.props.page}
            component={this.props.component}
            hasEditPermissions={this.userHasEditPermissions()}
          />
        );
      case 'sponsors':
        return (
          <Sponsors
            key={id}
            owner={this.props.owner}
            onComponentUpdate={() => {
              if (this.props.onComponentUpdate) {
                this.props.onComponentUpdate();
              }
            }}
            showManageSponsorVisibility={this.state.showSponsorVisibility}
            setShowManageSponsorVisibility={(value) =>
              this.setState({ showSponsorVisibility: value })
            }
            userId={this.props.profileState.user.id!}
            edit={this.state.edit}
            hasEditPermissions={this.userHasEditPermissions()}
            component={this.props.component}
            page={this.props.page}
            permissions={this.props.permissions}
          />
        );
      case 'livestream':
        return (
          <Livestream
            key={id}
            {...this.props}
            component={this.props.component}
            hasEditPermissions={this.userHasEditPermissions()}
          />
        );
    }

    return null;
  }

  uploadMediaAlbum() {
    this.props.uploadMediaAlbum();
  }

  toggleLocationForm(value?: boolean) {
    this.setState({
      showLocationForm: value ? value : !this.state.showLocationForm,
    });
  }

  toggleEventForm(value?: boolean) {
    this.setState({
      showEventForm: value ? value : !this.state.showEventForm,
    });
  }

  toggleMediaForm(value?: boolean) {
    this.setState(
      {
        showMediaForm: value ? value : !this.state.showMediaForm,
        mediaAlbumName: value ? '' : this.state.mediaAlbumName,
      },
      () => {
        if (this.state.showMediaForm) {
          this.props.updateMediaAlbum({
            componentId: this.props.component.id,
            parentId: this.props.owner.ownerId,
            totalItems: 0,
            itemsLoaded: 0,
            albumName: this.state.mediaAlbumName,
            albumItems: [],
            isUploading: false,
            isCreated: false,
          });
        }
      },
    );
  }

  toggleGigForm(value?: boolean) {
    this.setState({
      showGigForm: value ? value : !this.state.showGigForm,
    });
  }

  toggleAuctionForm(value?: boolean) {
    this.setState(
      {
        showAuctionForm: value ? value : !this.state.showAuctionForm,
      },
      () => {
        if (
          this.state.showAuctionForm &&
          (this.props.owner.ownerType === uiConstants.ownerType.group ||
            this.props.owner.ownerType === uiConstants.ownerType.event)
        ) {
          this.fetchAuctionItems();

          let _ids: string[] = [];

          if (
            this.props.component.content_references &&
            this.props.component.content_references.object_ids
          ) {
            _ids = [...this.props.component.content_references.object_ids];
          }

          this.setState({
            componentObjectIds: _ids,
          });
        }
      },
    );
  }

  async fetchAuctionItems() {
    const route =
      this.props.owner.ownerType === uiConstants.ownerType.group
        ? routes.CREATE_GROUP_AUCTION_ITEM
        : routes.GET_EVENT_AUCTION_ITEMS;
    const idField =
      this.props.owner.ownerType === uiConstants.ownerType.group ? 'groupId' : 'eventId';

    let _route = swapRouteParams(route, { [idField]: this.props.owner.ownerId });

    axios
      .get<IAuctionItem[]>(_route)
      .then((response) => {
        this.setState({
          auctionItems: response.data,
        });
      })
      .catch((error) => {
        const errObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errObj.translatedMessage, 'Fetch Auction Items');
        this.props.createToast(toast);
      });
  }

  toggleEdit(value?: boolean) {
    this.setState({
      edit: value ? value : !this.state.edit,
    });
  }

  onMediaItemSelect(file: any) {
    let _albumItems = [
      ...this.props.mediaAlbumState.albumItems,
      { url: file.src, uploaded: false },
    ];

    this.props.updateMediaAlbum({
      albumItems: _albumItems,
      totalItems: _albumItems.length,
    });
  }

  hasPagePermission() {
    switch (this.props.owner.ownerType) {
      case uiConstants.ownerType.group:
        return this.props.permissions['EDIT_GROUP_PAGES'];
      case uiConstants.ownerType.event:
        return this.props.permissions['EDIT_EVENT_PAGES'];
      case uiConstants.ownerType.gig:
        return this.props.permissions['MANAGE_GIGS'];
    }

    return false;
  }

  userHasEditPermissions() {
    return (
      ((this.props.permissions && this.hasPagePermission()) ||
        (this.props.owner.ownerType === uiConstants.ownerType.user && this.isLoggedInUser())) &&
      this.props.isUserStillLoggedIn
    );
  }

  isLoggedInUser() {
    return this.props.profileState.user.handle === this.props.userState.user.handle;
  }

  onAuctionSave(id: string) {
    const component = this.props.component as IAuctionComponent;
    let componentItems: string[] = (component.content_objects ?? []).map((item) => item.id ?? '');

    componentItems.push(id);

    this.setState({
      componentObjectIds: componentItems,
    });

    this.saveComponentItems('auction_item');
  }

  toggleComponentItem(id: string) {
    let _componentItems: string[] = [...this.state.componentObjectIds];

    if (_componentItems.includes(id)) {
      for (let a = 0; a < _componentItems.length; a++) {
        if (_componentItems[a] === id) {
          _componentItems.splice(a, 1);
        }
      }
    } else {
      _componentItems.push(id);
    }

    this.setState({
      componentObjectIds: _componentItems,
    });
  }

  handleManageAuctionItemDrag = (result: DropResult, provided: ResponderProvided) => {
    if (result.destination !== null) {
      let componentObjectIds: string[] = [...this.state.componentObjectIds];
      let auctionItems = [...this.state.auctionItems];

      auctionItems.splice(
        result.destination?.index || 0,
        0,
        auctionItems.splice(result.source.index, 1)[0],
      );
      componentObjectIds.splice(
        result.destination?.index || 0,
        0,
        componentObjectIds.splice(result.source.index, 1)[0],
      );

      this.setState({
        auctionItems: auctionItems,
        componentObjectIds: componentObjectIds,
      });
    }
  };

  saveComponentItems(objectType: string) {
    if (this.props.owner.ownerType === uiConstants.ownerType.event) {
      this.props.updateEventPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: objectType,
            object_ids: this.state.componentObjectIds,
          },
        },
        this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
      );
    } else if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      this.props.updateGroupPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: objectType,
            object_ids: this.state.componentObjectIds,
          },
        },
        this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
      );
    }
  }

  updateUserStatVisibility(id: string, value: boolean) {
    this.setState({
      userStatsVisibility: {
        ...this.state.userStatsVisibility,
        [id]: value,
      },
    });
  }
  onDeleteConfirmation() {
    this.setState({ showDelete: true });
  }
  saveUserStatsVisilbility() {
    if (this.props.owner.ownerType === uiConstants.ownerType.user) {
      this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, {
        meta_data: this.state.userStatsVisibility,
      });
    }

    this.toggleUserStatsManagement(false);
  }

  toggleStoreForm(value: boolean) {
    this.setState({
      showStoreForm: value,
    });
  }

  toggleAuctionItemForm(value: boolean) {
    if (
      this.props.owner.ownerType === uiConstants.ownerType.group ||
      this.props.owner.ownerType === uiConstants.ownerType.event
    ) {
      this.setState({
        showAuctionItemForm: value,
      });
    }
  }

  toggleEventManagement(value: boolean) {
    this.setState({
      showEventManagement: value,
    });
  }

  toggleChildEventManagement(value: boolean) {
    this.setState({
      showChildEventManagement: value,
    });
  }

  toggleStoreManagement(value: boolean) {
    this.setState({
      showStoreManagement: value,
      storeItemSearchValue: '',
    });
  }

  toggleUserStatsManagement(value: boolean) {
    this.setState({
      showUserStatsManagement: value,
      userStatsVisibility: this.props.component.meta_data || {},
    });
  }
  deleteComponent() {
    switch (this.props.owner.ownerType) {
      case 'user':
        this.props.deleteUserPageComponent(this.props.page.id, this.props.component.id);
        break;
      case 'group':
        this.props.onDelete
          ? this.props.onDelete()
          : this.props.deleteGroupPageComponent(
              this.props.owner.ownerId,
              this.props.page.id,
              this.props.component.id,
            );
        break;
      case 'event':
        this.props.onDelete
          ? this.props.onDelete()
          : this.props.deleteEventPageComponent(
              this.props.owner.ownerId,
              this.props.page.id,
              this.props.component.id,
            );
        break;
    }
  }

  // TODO: Merge with `userHasEditPermissions()` as I don't think theres a meaningful difference between the 2. Also look to remove `hasPagePermission()`.
  canEdit(): boolean {
    return this.props.isUserStillLoggedIn && this.userHasEditPermissions();
  }

  render() {
    return (
      <div className={this.canEdit() ? 'PageComponent admin' : 'PageComponent'}>
        <div className="component-wrap">{this.renderComponent()}</div>
        {((this.props.permissions && this.hasPagePermission()) ||
          (this.props.owner.ownerType === uiConstants.ownerType.user && this.isLoggedInUser())) && (
          <div className="admin-actions">
            <ul>
              {this.props.component.component_type === 'livestream' && (
                <li
                  onClick={() => {
                    this.setState({ showLivestreamManage: true });
                  }}
                >
                  <i className="fas fa-cog" />
                  <span>Manage</span>
                </li>
              )}
              {this.props.component.component_type === 'store_item' && (
                <li
                  onClick={() => {
                    this.toggleStoreManagement(true);
                  }}
                >
                  <i className="fas fa-cog" />
                  <span>Manage</span>
                </li>
              )}
              {this.props.component.component_type === 'auction' && (
                <li
                  onClick={() => {
                    this.toggleAuctionForm(true);
                  }}
                >
                  <i className="fas fa-cog" />
                  <span>Manage</span>
                </li>
              )}
              {this.props.component.component_type === 'auction' && (
                <li
                  onClick={() => {
                    this.toggleAuctionItemForm(true);
                  }}
                >
                  <i className="fas fa-plus-circle" />
                  <span>Add Item</span>
                </li>
              )}
              {this.props.component.component_type === 'location' && (
                <li
                  onClick={() => {
                    this.toggleLocationForm(true);
                  }}
                >
                  <i className="fas fa-plus-circle" />
                  <span>Create</span>
                </li>
              )}
              {this.props.component.component_type === 'gig' && (
                <li
                  onClick={() => {
                    this.toggleGigForm(true);
                  }}
                >
                  <i className="fas fa-plus-circle" />
                  <span>Create</span>
                </li>
              )}
              {this.props.component.component_type === 'media_album' && (
                <li
                  onClick={() => {
                    this.toggleMediaForm(true);
                  }}
                >
                  <i className="fas fa-plus-circle" />
                  <span>Create</span>
                </li>
              )}
              {this.props.component.component_type === 'event' && (
                <li
                  onClick={() => {
                    this.toggleEventManagement(true);
                  }}
                >
                  <i className="fas fa-plus-circle" />
                  <span>Manage</span>
                </li>
              )}
              {this.props.component.component_type === 'fundraising_event' && (
                <li
                  onClick={() => {
                    this.toggleEventManagement(true);
                  }}
                >
                  <i className="fas fa-plus-circle" />
                  <span>Manage</span>
                </li>
              )}
              {this.props.component.component_type === 'child_event' && (
                <li
                  onClick={() => {
                    this.toggleChildEventManagement(true);
                  }}
                >
                  <i className="fas fa-plus-circle" />
                  <span>Manage</span>
                </li>
              )}
              {this.props.component.component_type === 'event' && (
                <li
                  onClick={() => {
                    this.toggleEventForm(true);
                  }}
                >
                  <i className="fas fa-plus-circle" />
                  <span>Create</span>
                </li>
              )}
              {this.props.component.component_type === 'store_item' && (
                <li
                  onClick={() => {
                    this.toggleStoreForm(true);
                  }}
                >
                  <i className="fas fa-plus-circle" />
                  <span>Add Item</span>
                </li>
              )}
              {this.props.component.component_type === 'user_stats' && (
                <li
                  onClick={() => {
                    this.toggleUserStatsManagement(true);
                  }}
                >
                  <i className="fal fa-eye" />
                  <span>Visibility</span>
                </li>
              )}
              {this.props.component.component_type === 'sponsors' && (
                <li
                  onClick={() => {
                    this.setState({ showSponsorVisibility: true });
                  }}
                >
                  <i className="fal fa-eye" />
                  <span>Visibility</span>
                </li>
              )}
              {!this.state.edit && this.props.component.component_type !== 'livestream' && (
                <li
                  onClick={() => {
                    this.toggleEdit(true);
                  }}
                >
                  <i className="fas fa-pencil" />
                  <span>Edit</span>
                </li>
              )}
              {this.state.edit && this.props.component.component_type !== 'livestream' && (
                <li
                  onClick={() => {
                    this.toggleEdit(false);
                  }}
                >
                  <i className="fas fa-pencil" />
                  <span>Save</span>
                </li>
              )}
              {this.props.owner.ownerType === 'user' && (
                <li
                  onClick={() => {
                    this.onDeleteConfirmation();
                  }}
                >
                  <i className="fad fa-trash-alt" />
                  <span>Delete</span>
                </li>
              )}
              {this.props.owner.ownerType === 'group' && (
                <li
                  onClick={() => {
                    this.onDeleteConfirmation();
                  }}
                >
                  <i className="fad fa-trash-alt" />
                  <span>Delete</span>
                </li>
              )}
              {this.props.owner.ownerType === 'event' && (
                <li
                  onClick={() => {
                    this.onDeleteConfirmation();
                  }}
                >
                  <i className="fad fa-trash-alt" />
                  <span>Delete</span>
                </li>
              )}
              {this.props.owner.ownerType !== 'gig' && (
                <li {...this.props.dragProps}>
                  <i className="fas fa-arrows-alt" />
                  <span>Drag</span>
                </li>
              )}
            </ul>
          </div>
        )}

        <Prompt
          show={this.state.showDelete}
          title="Delete Component"
          yesMessage="Yes"
          yesClass="fas fa-check"
          yesStyle="normal"
          cancelMessage="Cancel"
          message="Are you sure you want to delete this component? All content inside this component may be lost."
          onYes={() => this.deleteComponent()}
          onClose={() => {
            this.setState({ showDelete: false });
          }}
        />
        <CreateGigModal
          show={this.state.showGigForm}
          history={this.props.history}
          onClose={() => this.setState({ showGigForm: false })}
          owner={this.props.owner}
          redirectToGigAfterCreate={true}
        />

        <CreateEventModal
          owner={this.props.owner}
          show={this.state.showEventForm}
          pageComponentToAddEvent={{ page: this.props.page, component: this.props.component }}
          history={this.props.history}
          onClose={() => this.setState({ showEventForm: false })}
          redirectToEventAfterCreate={true}
        />

        <Modal
          show={this.state.showMediaForm}
          onClose={() => {
            this.toggleMediaForm(false);
          }}
          title="Create a Media Album"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.uploadMediaAlbum();
            }}
            className="create-media-album create-modal"
          >
            <div className="row">
              <TextField
                required={true}
                label="Name"
                value={this.state.mediaAlbumName}
                name="mediaAlbumName"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this, false, () => {
                    this.props.updateMediaAlbum({ albumName: this.state.mediaAlbumName });
                  });
                }}
              />
            </div>
            <div className="media-items">
              {this.props.mediaAlbumState.albumItems.length === 0 && (
                <p className="no-media">You haven't added any images (yet).</p>
              )}
              {this.props.mediaAlbumState.albumItems.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="media-item"
                  >
                    <img
                      alt={'Media Album Item ' + index}
                      src={item.url}
                    />
                    {this.props.mediaAlbumState.isUploading && !item.uploaded && (
                      <div className="overlay">
                        <Loader loading={!item.uploaded} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="form-actions">
              <ImageUpload onImgSelect={this.onMediaItemSelect} />
              <Button
                isDisabled={this.props.mediaAlbumState.albumItems.length === 0}
                loading={this.props.mediaAlbumState.isUploading}
                type="submit"
                text="Create Media Album"
              />
            </div>
          </form>
        </Modal>
        {this.state.showLocationForm && (
          <AddLocationModal
            owner={this.props.owner}
            onClose={() => this.setState({ showLocationForm: false })}
            onLocationAdded={(location: IAddress) => {
              this.setState({ locations: [...this.state.locations, location] });
            }}
          />
        )}
        <Modal
          show={this.state.showEventManagement}
          onClose={() => {
            this.setState({ showEventManagement: false });
          }}
          title="Events"
        >
          <div className="manage-auction-items">
            <div className="auction-items">
              <div className="row header">
                <div className="col name">Event Name</div>
                <div className="col visibility">Visibility</div>
              </div>
              <div className="row-wrap">
                {this.state.groupEvents &&
                  this.state.groupEvents.map((item, index) => {
                    if (item.status && item.status.code !== 'archived') {
                      return (
                        <div
                          key={index}
                          className="row"
                        >
                          <div className="col name">
                            <span notranslate="yes">{item.title}</span>
                          </div>
                          <div className="col visibility">
                            <i
                              onClick={() => {
                                this.toggleComponentItem(item.id);
                              }}
                              className={
                                this.state.componentObjectIds.includes(item.id)
                                  ? 'fad fa-toggle-on'
                                  : 'fad fa-toggle-off'
                              }
                            />
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            <div className="actions">
              <Button
                text="Cancel"
                onClick={() => {
                  this.setState({ showEventManagement: false });
                }}
              />
              <Button
                text="Apply Changes"
                onClick={() => {
                  this.saveComponentItems(uiConstants.ownerType.event);
                  this.setState({ showEventManagement: false });
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showChildEventManagement}
          onClose={() => {
            this.setState({ showChildEventManagement: false });
          }}
          title="Child Events"
        >
          <div className="manage-auction-items">
            <div className="auction-items">
              <div className="row header">
                <div className="col name">Event Name</div>
                <div className="col visibility">Visibility</div>
              </div>
              <div className="row-wrap">
                {this.props.eventState.childEvents &&
                  this.props.eventState.childEvents.map((item, index) => {
                    if (item.status && item.status.code !== 'archived') {
                      return (
                        <div
                          key={index}
                          className="row"
                        >
                          <div className="col name">
                            <span>{item.title}</span>
                          </div>
                          <div className="col visibility">
                            <i
                              onClick={() => {
                                this.toggleComponentItem(item.id);
                              }}
                              className={
                                this.state.componentObjectIds.includes(item.id)
                                  ? 'fad fa-toggle-on'
                                  : 'fad fa-toggle-off'
                              }
                            />
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            <div className="actions">
              <Button
                text="Cancel"
                onClick={() => {
                  this.setState({ showChildEventManagement: false });
                }}
              />
              <Button
                text="Apply Changes"
                onClick={() => {
                  this.saveComponentItems(uiConstants.ownerType.event);
                  this.setState({ showChildEventManagement: false });
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showStoreManagement}
          onClose={() => {
            this.setState({ showStoreManagement: false });
          }}
          title="Store Items"
        >
          <div className="search-container">
            <TextField
              icon="fas fa-search"
              placeholder="Search store items..."
              value={this.state.storeItemSearchValue}
              type="text"
              name="searchValue"
              onChange={(e) => {
                this.setState({
                  storeItemSearchValue: e.target.value,
                });
                this.storeItemSearch();
              }}
            />
          </div>
          <div className="manage-auction-items">
            <div className="auction-items">
              <div className="row header">
                <div className="col name">Item Name</div>
                <div className="col visibility">Visibility</div>
              </div>
              {this.props.storeState.storeItems && (
                <PageComponentPaginator
                  itemsPerPage={uiConstants.storeItemItemsPerPage}
                  items={this.props.storeState.storeItems}
                >
                  {(itemsToRender) => {
                    return (
                      <div className="row-wrap">
                        {itemsToRender.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="row"
                            >
                              <div className="col name">
                                <span notranslate="yes">{item.name}</span>
                              </div>
                              <div className="col visibility">
                                <i
                                  onClick={() => {
                                    this.toggleComponentItem(item.id!);
                                  }}
                                  className={
                                    this.state.componentObjectIds.includes(item.id!)
                                      ? 'fad fa-toggle-on'
                                      : 'fad fa-toggle-off'
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                </PageComponentPaginator>
              )}
            </div>
            <div className="actions">
              <Button
                text="Cancel"
                onClick={() => {
                  this.setState({ showStoreManagement: false });
                }}
              />
              <Button
                text="Apply Changes"
                onClick={() => {
                  this.saveComponentItems('store_item');
                  this.setState({ showStoreManagement: false });
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showAuctionForm}
          onClose={() => {
            this.setState({ showAuctionForm: false });
          }}
          title="Auction Items"
        >
          <div className="manage-auction-items">
            <div className="auction-items">
              <div className="row header">
                <div className="col name">Item Name</div>
                <div className="col visibility">Visibility</div>
              </div>
              <DragDropContext onDragEnd={this.handleManageAuctionItemDrag}>
                <Droppable droppableId="manage-auction-items-modal">
                  {(provided, snapshot) => (
                    <div
                      className="row-wrap"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {this.state.auctionItems.map((item, index) => {
                        return (
                          <Draggable
                            key={item.id}
                            draggableId={'auction-item-' + item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                className="row"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="col name">
                                  <span notranslate="yes">{item.name}</span>
                                </div>
                                <div className="col visibility">
                                  <i
                                    onClick={() => {
                                      this.toggleComponentItem(item.id!);
                                    }}
                                    className={
                                      this.state.componentObjectIds.includes(item.id)
                                        ? 'fad fa-toggle-on'
                                        : 'fad fa-toggle-off'
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="actions">
              <Button
                text="Cancel"
                onClick={() => {
                  this.setState({ showAuctionForm: false });
                }}
              />
              <Button
                text="Apply Changes"
                onClick={() => {
                  this.saveComponentItems('auction_item');
                  this.setState({ showAuctionForm: false });
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal
          class="ModalStoreItemForm"
          closeIcon="fas fa-times"
          show={this.state.showStoreForm}
          onClose={() => this.setState({ showStoreForm: false })}
          title="Create Store Item"
        >
          <StoreItemForm
            onClose={() => {
              this.setState({ showStoreForm: false });
            }}
            {...this.props}
            onComponentUpdate={() => {
              if (this.props.onComponentUpdate) {
                this.props.onComponentUpdate();
              }
            }}
          />
        </Modal>
        {(this.props.owner.ownerType === uiConstants.ownerType.group ||
          this.props.owner.ownerType === uiConstants.ownerType.event) && (
          <Modal
            show={this.state.showAuctionItemForm}
            onClose={() => this.setState({ showAuctionItemForm: false })}
            title="Add Auction Item"
          >
            <AuctionItemForm
              owner={this.props.owner}
              onAuctionSave={this.onAuctionSave}
              onClose={() => {
                this.setState({ showAuctionItemForm: false });
              }}
            />
          </Modal>
        )}
        <Modal
          show={this.state.showUserStatsManagement}
          onClose={() => {
            this.toggleUserStatsManagement(false);
          }}
          title="Profile Stat Visibility"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.saveUserStatsVisilbility();
            }}
            className="user-stats-visibility create-modal"
          >
            <div className="table">
              <label className="header col0">Stat Title</label>
              <label className="header col1">Amount Accumulated</label>
              <label className="header col2">Visibility</label>
              {stats.map((stat, index) => (
                <Fragment key={index}>
                  <p className="item col0">{stat.label}</p>
                  <p className="item col1">
                    {!this.props.profileState.isProfileStatsLoading
                      ? this.props.profileState.profileStats?.[stat.id]
                      : '-'}
                  </p>
                  <div className="item col2 toggle-container">
                    <label className="toggle">
                      <input
                        type="checkbox"
                        checked={
                          this.state.userStatsVisibility[stat.id] !== undefined
                            ? this.state.userStatsVisibility[stat.id]
                            : true
                        }
                        onChange={(e) => {
                          this.updateUserStatVisibility(stat.id, e.target.checked);
                        }}
                      />
                      <span className="slider" />
                    </label>
                  </div>
                </Fragment>
              ))}
            </div>
            <div className="form-actions">
              <Button
                type="button"
                className="cancel-button"
                text="Cancel"
                onClick={() => this.toggleUserStatsManagement(false)}
              />
              <Button
                type="submit"
                className="save-button"
                text="Apply Changes"
              />
            </div>
          </form>
        </Modal>
        <Modal
          show={this.state.showLivestreamManage}
          onClose={() => {
            this.setState({ showLivestreamManage: false });
          }}
          title="Manage Livestreams"
        >
          <LivestreamManagement
            {...this.props}
            page={this.props.page}
            component={this.props.component}
            onClose={() => {
              this.setState({ showLivestreamManage: false });
            }}
            onComponentUpdate={() => {
              if (this.props.onComponentUpdate) {
                this.props.onComponentUpdate();
              }
            }}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    mediaAlbumState: store.mediaAlbumState,
    hubState: store.hubState,
    eventState: store.eventState,
    auctionState: store.auctionState,
    profileState: store.profileState,
    userState: store.userState,
    gigState: store.gigState,
    storeState: store.storeState,
  };
};

const mapDispatchToProps = {
  createMediaAlbum,
  uploadMediaAlbum,
  updateMediaAlbum,
  deleteGroupPageComponent,
  deleteUserPageComponent,
  deleteEventPageComponent,
  createToast,
  clearEventError,
  updateEventPageComponent,
  updateGroupPageComponent,
  updateUserPageComponent,
  getProfileStats,
  getStoreItems: getStoreItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageComponent);
