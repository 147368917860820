import axios from 'axios';
import { Dispatch } from 'redux';
import { routes, swapRouteParams, toastError } from '../helpers';
import errorHelpers from '../helpers/errorHelpers';
import { localizeHelpers } from '../localizeHelpers';
import { createToast } from './toaster';
import { IAppError } from '../interfaces';
import { Constants } from '@gigit/constants';

export enum CartActionTypes {
  UPDATE_CART = 'UPDATE_CART',
}

export interface IUpdateCartActions {
  items: any[];
  lastItem: {};
  totalItems: 0;
  total: {};
  parentType: '';
  type: CartActionTypes.UPDATE_CART;
}

export type CartActions = IUpdateCartActions;

export const updateQuantity = (
  index: number,
  quantity: number,
  shippingOptions?: any[],
  discountCode?: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _items = [...getState().cartState.items];
    let _totalItems = 0;
    let _calcItems = [];

    _items[index].quantity = quantity;

    for (let i in _items) {
      _totalItems = _totalItems + _items[i].quantity;

      let _item: any = {
        item_id: _items[i].id,
        quantity: _items[i].quantity,
      };

      if (shippingOptions && shippingOptions[i] && shippingOptions[i] === 'ship') {
        _item.ship_by_vendor = true;
      }

      _calcItems.push(_item);
    }

    await calculateTotal(
      _items[0].owner_id,
      {
        payment_method: 'card',
        items: _calcItems,
        discount_code: discountCode,
      },
      _items[0].owner_type,
    )(dispatch, getState);

    dispatch({
      items: _items,
      totalItems: _totalItems,
      type: CartActionTypes.UPDATE_CART,
    });
  };
};

export const addItem = (item: any, parent_type: string, options?: { callback?: () => void }) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _currentParentType = getState().cartState.parentType;
    let _items = [...getState().cartState.items];
    let _exists = false;
    let _totalItems = 0;
    let _calcItems = [];

    if (item.pickup_locations && item.pickup_locations.length > 0) {
      item.pickup_locations = item.pickup_locations.sort((a: any, b: any) => {
        const textA = a.title.toUpperCase();
        const textB = b.title.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }

    if (
      (item.item_type === 'sponsorship' &&
        _items.some((item) => item.item_type !== 'sponsorship')) ||
      ((item.item_type === 'merchandise' || item.item_type === 'ticket') &&
        _items.some((item) => item.item_type === 'sponsorship'))
    ) {
      dispatch({
        error: localizeHelpers.translate(
          `Unable to add sponsorships with items of different type. Please empty your cart or checkout before purchasing items of ${item.item_type} type.`,
        ),
        type: CartActionTypes.UPDATE_CART,
      });
      return;
    }

    let existingOwners = _items.map((cartItem) => cartItem.owner_id);

    existingOwners.push(item.owner_id);

    let existingOwnersLength = Array.from(new Set(existingOwners)).length;

    if (_items.length > 0 && existingOwnersLength !== 1) {
      dispatch({
        error: localizeHelpers.translate(
          'Unable to add items from different causes/events to your cart. Please empty your cart or checkout before purchasing items from a different cause/event.',
        ),
        type: CartActionTypes.UPDATE_CART,
      });
    } else {
      for (let i in _items) {
        if (_items[i].id === item.id) {
          if (_items[i].inventory) {
            if (
              _items[i].inventory.max_purchase_quantity &&
              _items[i].inventory.available_quantity
            ) {
              if (
                _items[i].inventory.available_quantity >= _items[i].inventory.max_purchase_quantity
              ) {
                if (
                  _items[i].quantity + item.quantity <=
                  _items[i].inventory.max_purchase_quantity
                ) {
                  _items[i].quantity = _items[i].quantity + item.quantity;
                } else {
                  _items[i].quantity = _items[i].inventory.max_purchase_quantity;

                  const errorMessage = localizeHelpers.translate(
                    'You may purchase only {{max_purchase_quantity}} of {{item_name}}',
                    {
                      max_purchase_quantity: _items[i].inventory.max_purchase_quantity,
                      item_name: _items[i].name,
                    },
                  );
                  let toast = toastError(errorMessage, 'Max Purchase Limit');
                  createToast(toast)(dispatch, getState);
                }
              } else {
                if (_items[i].quantity + item.quantity <= _items[i].available_quantity) {
                  _items[i].quantity = _items[i].quantity + item.quantity;
                } else {
                  _items[i].quantity = _items[i].inventory.available_quantity;

                  const errorMessage = localizeHelpers.translate(
                    'There are only {{available_quantity}} of {{item_name}} remaining',
                    {
                      available_quantity: _items[i].inventory.available_quantity,
                      item_name: _items[i].name,
                    },
                  );
                  let toast = toastError(errorMessage, 'Max Purchase Limit');
                  createToast(toast)(dispatch, getState);
                }
              }
            } else if (_items[i].inventory.max_purchase_quantity) {
              if (_items[i].quantity + item.quantity <= _items[i].inventory.max_purchase_quantity) {
                _items[i].quantity = _items[i].quantity + item.quantity;
              } else {
                _items[i].quantity = _items[i].inventory.max_purchase_quantity;

                const errorMessage = localizeHelpers.translate(
                  'You may purchase only {{max_purchase_quantity}} of {{item_name}}',
                  {
                    max_purchase_quantity: _items[i].inventory.max_purchase_quantity,
                    item_name: _items[i].name,
                  },
                );
                let toast = toastError(errorMessage, 'Max Purchase Limit');
                createToast(toast)(dispatch, getState);
              }
            } else if (_items[i].inventory.available_quantity) {
              if (_items[i].quantity + item.quantity <= _items[i].inventory.available_quantity) {
                _items[i].quantity = _items[i].quantity + item.quantity;
              } else {
                _items[i].quantity = _items[i].inventory.available_quantity;

                const errorMessage = localizeHelpers.translate(
                  'There are only {{available_quantity}} of {{item_name}} remaining',
                  {
                    available_quantity: _items[i].inventory.available_quantity,
                    item_name: _items[i].name,
                  },
                );
                let toast = toastError(errorMessage, 'Max Purchase Limit');
                createToast(toast)(dispatch, getState);
              }
            }
          } else {
            _items[i].quantity = _items[i].quantity + item.quantity;
          }
          _exists = true;
          if (item?.participants) {
            _items[i].participants = item?.participants;
          }

          if (item?.participant) {
            _items[i].participant = item?.participant;
          }
        }

        _calcItems.push({
          item_id: _items[i].id,
          quantity: _items[i].quantity,
        });

        _totalItems = _totalItems + _items[i].quantity;
      }

      if (!_exists) {
        let error = false;
        if (item.inventory) {
          if (item.inventory.available_quantity && item.inventory.max_purchase_quantity) {
            if (item.inventory.available_quantity >= item.inventory.max_purchase_quantity) {
              if (item.quantity > item.inventory.max_purchase_quantity) {
                const errorMessage = localizeHelpers.translate(
                  'You may purchase only {{max_purchase_quantity}} of {{item_name}}',
                  {
                    max_purchase_quantity: item.inventory.max_purchase_quantity,
                    item_name: item.name,
                  },
                );
                let toast = toastError(errorMessage, 'Max Purchase Limit');
                createToast(toast)(dispatch, getState);

                error = true;
              }
            } else {
              if (item.quantity > item.inventory.available_quantity) {
                const errorMessage = localizeHelpers.translate(
                  'There are only {{available_quantity}} of {{item_name}} remaining',
                  {
                    available_quantity: item.inventory.available_quantity,
                    item_name: item.name,
                  },
                );
                let toast = toastError(errorMessage, 'Max Purchase Limit');
                createToast(toast)(dispatch, getState);
                error = true;
              }
            }
          } else if (item.inventory.available_quantity) {
            if (item.quantity > item.inventory.available_quantity) {
              const errorMessage = localizeHelpers.translate(
                'There are only {{available_quantity}} of {{item_name}} remaining',
                {
                  available_quantity: item.inventory.available_quantity,
                  item_name: item.name,
                },
              );
              let toast = toastError(errorMessage, 'Max Purchase Limit');
              createToast(toast)(dispatch, getState);
              error = true;
            }
          } else if (item.inventory.max_purchase_quantity) {
            if (item.quantity > item.inventory.max_purchase_quantity) {
              const errorMessage = localizeHelpers.translate(
                'You may purchase only {{max_purchase_quantity}} of {{item_name}}',
                {
                  max_purchase_quantity: item.inventory.max_purchase_quantity,
                  item_name: item.name,
                },
              );
              let toast = toastError(errorMessage, 'Max Purchase Limit');
              createToast(toast)(dispatch, getState);
              error = true;
            }
          }
        }

        if (!error) {
          _items.push(item);
          _totalItems = _totalItems + item.quantity;

          _calcItems.push({
            item_id: item.id,
            quantity: item.quantity,
          });
        }
      }

      let account;
      if (item.owner_type === 'group') {
        account = getState().groupState.group.account;
      } else {
        account = getState().eventState.event.group?.account;
      }

      if (account) {
        await calculateTotal(
          item.owner_id,
          {
            payment_method: 'card',
            items: _calcItems,
            discount_code: '',
          },
          item.owner_type,
          options,
        )(dispatch, getState);
      } else {
        await getCurrentStoreItems(item.owner_type, item.owner_id, options)(dispatch, getState);
      }

      dispatch({
        items: _items,
        totalItems: _totalItems,
        lastItem: item,
        parentType: parent_type,
        type: CartActionTypes.UPDATE_CART,
      });
    }
  };
};

export const setCartCurrency = (currency: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const total = getState().cartState.total;
    total.currency = currency;
    dispatch({
      total: total,
      type: CartActionTypes.UPDATE_CART,
    });
  };
};

export const removeItem = (index: number) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _items = [...getState().cartState.items];
    let _totalItems = getState().cartState.totalItems - (_items[index]?.quantity || 1);
    let _calcItems = [];

    _items.splice(index, 1);

    for (let i in _items) {
      _calcItems.push({
        item_id: _items[i].id,
        quantity: _items[i].quantity,
      });
    }

    if (_items.length > 0) {
      await calculateTotal(
        _items[0].owner_id,
        {
          payment_method: 'card',
          items: _calcItems,
          discount_code: '',
        },
        _items[0].owner_type,
      )(dispatch, getState);
    } else {
      dispatch({
        total: {
          amount: 0,
          currency: 'cad',
          discount: 0,
          gigit_fee: 0,
          gigit_fee_percent: 0,
          payment_platform_fee: 0,
          tip: 0,
          total: 0,
          total_application_fee: 0,
        },
        type: CartActionTypes.UPDATE_CART,
      });
    }

    let _d: any = {
      items: _items,
      totalItems: _totalItems,
      type: CartActionTypes.UPDATE_CART,
    };

    if (_items.length === 0) {
      _d = {
        items: _items,
        totalItems: _totalItems,
        parentType: '',
        type: CartActionTypes.UPDATE_CART,
      };
    }

    dispatch(_d);
  };
};

export const calculateTotal = (
  ownerId: string,
  _payload: any,
  _type: string,
  options?: { callback?: (error?: IAppError) => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _route = routes.CALCULATE_CART as string;
    let param = 'groupId';

    if (_type === Constants.object_type.event) {
      _route = routes.CALCULATE_EVENT_CART;
      param = 'eventId';
    }

    if (_type === Constants.object_type.hub) {
      _route = routes.CALCULATE_HUB_CART;
      param = 'hubId';
    }

    axios
      .post(swapRouteParams(_route, { [param]: ownerId }), _payload)
      .then((response) => {
        dispatch({
          total: response.data,
          type: CartActionTypes.UPDATE_CART,
        });

        let _cartItems = [...getState().cartState.items];

        if (_cartItems.length > 0) {
          getCurrentStoreItems(
            _cartItems[0].owner_type,
            _cartItems[0].owner_id,
            options,
          )(dispatch, getState);
        } else {
          options?.callback?.();
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Store Cart');
        createToast(toast)(dispatch, getState);
        options?.callback?.(errorObj);
      });
  };
};

export const clearCart = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      items: [],
      lastItem: {},
      totalItems: 0,
      parentType: '',
      total: {
        amount: 0,
        currency: 'cad',
        discount: 0,
        gigit_fee: 0,
        gigit_fee_percent: 0,
        payment_platform_fee: 0,
        tip: 0,
        total: 0,
        total_application_fee: 0,
      },
      type: CartActionTypes.UPDATE_CART,
    });
  };
};

export const getCurrentStoreItems = (
  ownerType: string,
  ownerId: string,
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group'
        ? routes.GET_STORE_FORMS
        : ownerType === Constants.object_type.hub
          ? routes.GET_HUB_STORE_FORMS
          : routes.GET_EVENT_STORE_FORMS;

    const idField =
      ownerType === 'group'
        ? 'groupId'
        : ownerType === Constants.object_type.hub
          ? 'hubId'
          : 'eventId';

    axios.get(swapRouteParams(route, { [idField]: ownerId })).then((response) => {
      let _cartItems = [...getState().cartState.items];

      for (let i in response.data) {
        for (let c in _cartItems) {
          let _cartItem = _cartItems[c];
          let _item = response.data[i];

          if (_item.id === _cartItem.id) {
            _cartItems[c].form = _item.form;
          }
        }
      }

      dispatch({
        items: _cartItems,
        type: CartActionTypes.UPDATE_CART,
      });

      options?.callback?.();
    });
  };
};

export const resetCartError = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      error: '',
      type: CartActionTypes.UPDATE_CART,
    });
  };
};
