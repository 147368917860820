import React, { ReactNode } from 'react';
import './FormSection.scss';

interface IProps {
  className?: string;
  children: ReactNode;
}

export const FormSection: React.FC<IProps> = (props: IProps) => {
  return (
    <div className={props.className ? `FormSection ${props.className}` : `FormSection`}>
      {props.children}
    </div>
  );
};
