import React from 'react';
import { connect } from 'react-redux';
import { defaultCurrency, formatCurrency } from '../../../helpers';
import { IAppState } from '../../../store';

import './AlertBoxWidget.scss';
import { userSelectors } from '../../../selectors/user';

export enum AlertBoxDisplayState {
  DISPLAY = 1,
  HIDING = 2,
  HIDE = 3,
}

interface IProps {
  locale: string;
  widget: IEventWidgetAlertBoxProps;
  donation: IAlertBoxDonation | null;
  displayState: AlertBoxDisplayState;
  isPreview?: boolean;
}

export interface IEventWidgetAlertBoxProps {
  widget_theme: string;
  widget_style: string;
  currency?: string;
}

export interface IAlertBoxDonation {
  display_name: string;
  amount: number;
}

interface IState {}

/** Stateless widget that displays a donation alert. */
class AlertBoxWidget extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    let className = 'AlertBoxWidget';

    const displayClass =
      this.props.displayState === AlertBoxDisplayState.DISPLAY
        ? 'display'
        : this.props.displayState === AlertBoxDisplayState.HIDING
          ? 'hiding'
          : 'hidden';

    className += ` ${displayClass}`;

    className += ` ${this.props.widget.widget_theme}`;

    className += ` ${this.props.widget.widget_style.replace(/_/g, '-')}`;

    if (this.props.isPreview) {
      className += ` preview`;
    }

    return (
      <div className="AlertBoxWrap">
        <div className={className}>
          <div className="content">
            <div className="new-donation">NEW DONATION</div>
            {this.props.donation && (
              <React.Fragment>
                <div className="name">{this.props.donation.display_name}</div>
                <div className="amount">
                  {formatCurrency(
                    this.props.donation.amount || 0,
                    this.props.widget.currency ?? defaultCurrency,
                    this.props.locale,
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AlertBoxWidget);
