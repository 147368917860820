import React from 'react';
import { connect } from 'react-redux';
import { Config } from '@gigit/config';
import GoogleMapReact from 'google-map-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IAddress } from '@gigit/interfaces';

import { IAppState } from '../../store';
import { ISettingsState } from '../../reducers/settings';

import TextField from '../TextField/TextField';
import Loader from '../Loader/Loader';

import './Location.scss';
import locationHelpers, { IGeocodeResponse } from './locationHelpers';
import Marker from '../MapComponents/Marker/Marker';
import { handleDebounce } from '../../helpers';

interface IMapViewport {
  latitude: number;
  longitude: number;
  zoom?: number;
}

interface IMapAddress {
  [key: string]: any;
}

interface IProps extends WithTranslation {
  locationName?: string;
  locationNameValue?: string;
  searchValue: string;
  name: string;
  label: string;
  settings: ISettingsState;
  onSearchChange(searchValue: string): void;
  onNameChange(name: string): void;
  onChange(address: IAddress): void;
}

interface IState {
  viewport: IMapViewport;
  addressTimeout: any;
  loadingLatLng: boolean;
  addressError: boolean;
  mounted: boolean;
}

class Location extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      viewport: {
        latitude: 0,
        longitude: 0,
        zoom: 14,
      },
      addressTimeout: false,
      loadingLatLng: false,
      addressError: false,
      mounted: false,
    };

    this.updateViewport = this.updateViewport.bind(this);
    this.updateLatLng = this.updateLatLng.bind(this);
  }

  updateViewport(viewport: any) {
    if (this.state.mounted) {
      this.setState({
        viewport: viewport,
      });
    }
  }

  componentDidMount() {
    this.setState(
      {
        mounted: true,
      },
      () => {
        this.updateLatLng();
      },
    );
  }

  updateLatLng() {
    this.setState(
      {
        loadingLatLng: true,
        addressError: false,
      },
      () => {
        let _lat = 0;
        let _long = 0;
        let _addressError = false;

        locationHelpers
          .getGeocodeInfoFromSearch(this.props.searchValue)
          .then((response: IGeocodeResponse) => {
            if (response.results.length > 0) {
              _lat = response.results[0].geometry.location.lat;
              _long = response.results[0].geometry.location.lng;

              let address = locationHelpers.getAddressParts(response.results[0]);

              let line1 = undefined;
              if (address.route) {
                line1 = address.route;

                if (address.street_number) {
                  line1 = `${address.street_number} ${line1}`;
                }
              }

              this.props.onChange({
                line1: line1,
                line2: address.neighborhood,
                city: address.locality,
                state: address.administrative_area_level_1,
                country: address.country,
                postal_code: address.postal_code,
                searchable: true,
                location: {
                  coordinates: [_long, _lat],
                },
              });

              if (this.state.mounted) {
                this.setState({
                  viewport: {
                    latitude: _lat,
                    longitude: _long,
                    zoom: 14,
                  },
                });
              }
            } else {
              _addressError = true;
            }

            this.setState({
              loadingLatLng: false,
              addressError: _addressError,
            });
          });
      },
    );
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.searchValue !== prevProps.searchValue) {
      handleDebounce(this.props.searchValue).then((res) => {
        this.updateLatLng();

        this.setState({
          addressTimeout: false,
          addressError: false,
        });
      });
    }
  }

  render() {
    const { viewport, addressTimeout, addressError, loadingLatLng } = this.state;
    const {
      label,
      name,
      searchValue,
      locationName,
      locationNameValue,
      onSearchChange,
      onNameChange,
    } = this.props;

    return (
      <div
        className="Location"
        notranslate="yes"
      >
        <Loader loading={addressTimeout || loadingLatLng} />
        {addressTimeout === false && addressError && <i className="fas fa-times-circle"></i>}
        <div style={{ width: '100%', height: 300 }}>
          <GoogleMapReact
            draggable={false}
            center={{
              lat: viewport.latitude,
              lng: viewport.longitude,
            }}
            zoom={viewport.zoom}
            bootstrapURLKeys={{
              key: Config.web.REACT_APP_GOOGLE_API_KEY,
            }}
          >
            <Marker
              lat={viewport.latitude}
              lng={viewport.longitude}
              offsetLeft={-8}
              offsetTop={-12}
            >
              <i className="fas fa-map-marker-alt"></i>
            </Marker>
          </GoogleMapReact>
        </div>

        <div className="Location-info">
          <div className="Location-info-inner">
            <TextField
              type="text"
              icon="fad fa-location"
              placeholder={label}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value)}
              name={name}
              value={searchValue}
            />
            {locationName !== undefined && locationNameValue !== undefined && (
              <TextField
                type="text"
                placeholder="Location Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onNameChange(e.target.value)}
                name={locationName}
                value={locationNameValue}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    user: store.userState,
    settings: store.settingsState,
  };
};

export default withTranslation('translations')(connect(mapStateToProps)(Location));
