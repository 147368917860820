import React from 'react';
import ListCard from '../../Shared/ListCard/ListCard';
import './HubEventSingleItem.scss';
import { IEventFE, IGroup, ICause } from '@gigit/interfaces';
import Portrait from '../../../Portrait/Portrait';
import { useEffect } from 'react';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CoverImage from '../../../CoverImage/CoverImage';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import { uiConstants } from '../../../../constants';

interface IProps {
  event: IEventFE;
  group: IGroup;
  // causes:string[]
}

const HubEventSingleItem: React.FC<IProps> = (props) => {
  const [showAllCauses, setShowAllCauses] = useState(false);

  return (
    <>
      <div className="HubEventSingleItem">
        <div className="cover-image-container">
          <CoverImage currentImage={props.event.cover_image_url} />
        </div>
        <div className="description-section">
          <div className="top-section">
            <Portrait
              size={50}
              source={props.group?.profile_image_url}
            />
            <div className="top-section-description">
              <p>{props.group?.title}</p>
              <p>Supporting Cause</p>
            </div>
          </div>
          <div className="bottom-section">
            <p className="title">{props.event.title}</p>
            <QuillTextEditor
              value={props.event.description}
              preserveWhitespace={true}
              theme={uiConstants.quill.readOnlyTheme}
              readOnly={true}
              className="description"
            />
          </div>

          {/* TODO: uncomment this after we have BE for causes */}
          {/* <div className="causes">
                    
                    {!showAllCauses && 
                    <React.Fragment>
                       {props.causes[0]?.cause && <p>{props.causes[0]?.cause}</p>}
                        {props.causes[1]?.cause && <p>{props.causes[1]?.cause}</p>}
                        {props.causes[2] && 
                        <p 
                            onClick={()=>setShowAllCauses(true)} 
                            style={{background:'none',cursor:'pointer'}}
                        >
                            + {props.causes.length-2} more
                        </p>
                       }                     
                    </React.Fragment>
                    }
                    {showAllCauses && props.causes.map((e,index)=> <p key={index}>{e.cause}</p>)}
                </div> */}
        </div>
      </div>
    </>
  );
};

export default HubEventSingleItem;
