import React from 'react';
import './DatePicker.scss';
import Dropdown from '../Dropdown/Dropdown';
import TextField from '../TextField/TextField';
import { combineClassNames, handleInputChange } from '../../helpers';
import moment, { Moment } from 'moment';
import { localizeHelpers } from '../../localizeHelpers';

interface IProps {
  default?: any;
  name: string;
  label: string;
  onChange: (date: Moment) => void;
  date: Moment;
  maxYear?: string;
  showTime?: boolean;
  error?: boolean | string;

  yearClass?: string;
  monthClass?: string;
  dayClass?: string;
}

interface IState {
  months: Array<any>;
  maxDays: string;
  month: number;
  day: string;
  year: string;
  currentYear: string;
  hour: string;
  minute: string;
  suffix: string;
}

// TODO: This should be replaced with <DateTime/>
class DatePicker extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      months: localizeHelpers.getMonths().map((date, idx) => {
        return { label: date, value: idx };
      }),
      month: this.props.date.month(),
      maxDays: '30',
      day: this.props.date.date().toString(),
      year: this.props.date.year().toString(),
      currentYear: moment().year().toString(),
      hour: moment(this.props.date).format('h'),
      minute: this.roundMinutes(parseInt(moment(this.props.date).format('m'))).toString(),
      suffix: moment(this.props.date).format('A'),
    };

    this.getDaysInMonth = this.getDaysInMonth.bind(this);
  }

  getDaysInMonth() {
    let date = new Date();
    date = new Date(date.setMonth(this.state.month));
    if (this.state.year.length > 0) {
      date = new Date(date.setFullYear(parseInt(this.state.year)));
    }
    let days = moment(date).daysInMonth();
    this.setState({ maxDays: `${days}` });
  }

  setDate() {
    let _hour: number =
      this.state.suffix === 'AM' ? parseInt(this.state.hour) : parseInt(this.state.hour) + 12;

    _hour = this.state.suffix === 'PM' && _hour === 24 ? 12 : _hour;
    _hour = this.state.suffix === 'AM' && _hour === 12 ? 0 : _hour;

    let d = moment()
      .year(parseInt(this.state.year))
      .month(this.state.month)
      .date(parseInt(this.state.day))
      .hour(_hour)
      .minute(parseInt(this.state.minute));
    this.props.onChange(d);

    this.getDaysInMonth();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    //updating when day/hour number fields are null causes weird bugs when backspacing the input
    if (
      prevProps.date !== this.props.date &&
      this.state.day &&
      this.state.hour &&
      this.state.year
    ) {
      this.setState({
        year: this.props.date.year().toString(),
        day: this.props.date.date().toString(),
        month: this.props.date.month(),
        hour: moment(this.props.date).format('h'),
        minute: this.roundMinutes(parseInt(moment(this.props.date).format('m'))).toString(),
        suffix: moment(this.props.date).format('A'),
      });
    }
  }

  componentDidMount() {
    this.getDaysInMonth();
    this.setDate();
  }

  roundMinutes(minute: number) {
    let intervals = [0, 15, 30, 45];
    let _m = 0;

    for (let i in intervals) {
      if (minute >= intervals[i]) {
        _m = intervals[i];
      }
    }

    return _m;
  }

  render() {
    return (
      <div className="DatePicker">
        <label
          className={`dp-label ${this.props.error ? 'error' : ''}`}
        >{`${this.props.label} ${typeof this.props.error === 'string' ? this.props.error : ''}`}</label>
        <div className="dp-inner">
          <div className={combineClassNames('month', this.props.monthClass)}>
            <Dropdown
              name="month"
              options={this.state.months}
              value={this.state.month}
              onChange={(e) => {
                handleInputChange(e, this, false, () => {
                  this.setDate();
                  this.getDaysInMonth();
                });
              }}
            />
          </div>
          <div className={combineClassNames('day', this.props.dayClass)}>
            <TextField
              name="day"
              required={true}
              value={this.state.day}
              type="number"
              min="1"
              max={this.state.maxDays}
              onChange={(e) => {
                const day = parseInt(e.target.value);
                if (day <= 0 || day > parseInt(this.state.maxDays)) return;

                handleInputChange(e, this, false, () => this.setDate());
              }}
            />
          </div>
          <div className={combineClassNames('year', this.props.yearClass)}>
            <TextField
              name="year"
              min="1900"
              max={this.props.maxYear ? this.props.maxYear : undefined}
              required={true}
              value={this.state.year}
              type="number"
              onChange={(e) => {
                if (e.target.value.length < 5) {
                  handleInputChange(e, this, false, () => this.setDate());
                }
              }}
            />
          </div>
          {this.props.showTime && (
            <div className="time">
              <TextField
                label={'Time'}
                value={this.state.hour}
                name="hour"
                type="number"
                max="12"
                min="1"
                required={true}
                onChange={(e) => {
                  const hours = parseInt(e.target.value);
                  if (hours <= 0 || hours > 12) return;

                  handleInputChange(e, this, false, () => this.setDate());
                }}
              />
              <Dropdown
                name="minute"
                value={this.state.minute}
                options={[
                  { label: '00', value: '0' },
                  { label: '15', value: '15' },
                  { label: '30', value: '30' },
                  { label: '45', value: '45' },
                ]}
                onChange={(e) => {
                  handleInputChange(e, this, false, () => this.setDate());
                }}
              />
              <Dropdown
                name="suffix"
                value={this.state.suffix}
                options={[
                  { label: 'AM', value: 'AM' },
                  { label: 'PM', value: 'PM' },
                ]}
                onChange={(e) => {
                  handleInputChange(e, this, false, () => this.setDate());
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DatePicker;
