export const componentConfig = [
  {
    title: 'Events',
    description: 'Create and manage events and fundraisers.',
    type: 'event',
    icon: 'far fa-calendar-star',
    requires: ['group'],
  },
  {
    title: 'Fundraising Events',
    description: 'Manage fundraising events.',
    type: 'fundraising_event',
    icon: 'fal fa-funnel-dollar',
    requires: ['group'],
  },
  {
    title: 'Volunteer Opportunities',
    description: 'Post paid and volunteer opportunities so community members can apply.',
    type: 'gig',
    icon: 'fas fa-handshake',
    requires: ['group', 'event'],
  },
  {
    title: 'Content Block',
    description: 'Build custom content on your page with styled text, links and images.',
    type: 'content_block',
    icon: 'fas fa-pencil-alt',
    requires: ['group', 'event', 'user'],
  },
  {
    title: 'Media',
    description: 'Add multimedia albums to your page.',
    type: 'media_album',
    icon: 'fal fa-photo-video',
    requires: ['group', 'event', 'user'],
  },
  {
    title: 'Location',
    description: 'A map component that allows you to show multiple locations.',
    type: 'location',
    icon: 'fas fa-location',
    requires: ['group', 'event'],
  },
  {
    title: 'Fundraising',
    description: 'Turn your event into a peer-to-peer fundraiser by adding a leaderboard.',
    type: 'fundraising',
    icon: 'fal fa-funnel-dollar',
    requires: ['event'],
  },
  {
    title: 'Hub',
    description:
      'The Hub component shows a listing of causes that are in your Hub and allows you to manage Hub applications.',
    type: 'hub',
    icon: 'fas fa-users',
    requires: ['group'],
  },
  {
    title: 'Store',
    description:
      'Sell tickets, sponsorships, and products from an online store for your cause or an event.',
    type: 'store_item',
    icon: 'fas fa-users',
    requires: ['group', 'event'],
  },
  {
    title: 'Skills',
    description: 'Add skills to your page so others can see them.',
    type: 'skill',
    icon: 'fas fa-user-tag',
    requires: ['user'],
  },
  {
    title: 'Focus Areas',
    description:
      'Show page visitors the focus areas that your cause supports. This is used in searches.',
    type: 'cause',
    icon: 'fas fa-plus-hexagon',
    requires: ['group', 'event', 'user'],
  },
  {
    title: 'Auction',
    description: 'Add an online auction to your cause or event page.',
    type: 'auction',
    icon: 'fas fa-gavel',
    requires: ['event'],
  },
  {
    title: 'Donations',
    description: 'Drive engagement by showing donations to your cause or event.',
    type: 'donations',
    icon: 'far fa-heart',
    requires: ['group', 'event'],
  },
  {
    title: 'Stats',
    description: 'Track key user stats like the number of donations given or hours volunteered.',
    type: 'user_stats',
    icon: 'fal fa-chart-bar',
    requires: ['user'],
  },
  {
    title: 'Sponsors',
    description: 'Track sponsorships for your event.',
    type: 'sponsors',
    icon: 'fal fa-users',
    requires: ['event'],
  },
  {
    title: 'Livestream',
    description: 'Display livestreams from your peer-to-peer fundraisers, or add custom streams.',
    type: 'livestream',
    icon: 'far fa-play-circle',
    requires: ['event', 'user'],
  },
  {
    title: 'Child Events',
    description: 'List and Manage child Events and fundraisers of this Event.',
    type: 'child_event',
    icon: 'far fa-calendar-star',
    requires: ['event'],
  },
];
