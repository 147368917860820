import React, { ReactNode, useState, useEffect } from 'react';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { Config } from '@gigit/config';

/** Provides Stripe <Elements> wrapper.
 * - Should be a parent element around any component that needs Stripe.
 */
export const StripeProvider: React.FC<{ children: ReactNode }> = (props) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    setStripePromise(loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY));
  }, []);

  return <Elements stripe={stripePromise}>{props.children}</Elements>;
};
