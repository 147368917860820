import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Config } from '@gigit/config';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Elements, ElementsConsumer, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import {
  CheckoutLocale,
  loadStripe,
  PaymentMethodCreateParams,
  StripeError,
  PaymentRequest,
  Stripe,
} from '@stripe/stripe-js';
import { IAppState } from '../../store';
import {
  handleInputChange,
  formatAddressLine,
  defaultCurrency,
  toastError,
  toastSuccess,
  isMobileScreen,
  typeHelpers,
  errorHelpers,
  setSEOMetatags,
  swapRouteParams,
  routes,
  OwnerType,
} from '../../helpers';
import queryString from 'query-string';
import {
  IEventSummaryFE,
  IGroup,
  IJoinTeamInfo,
  IParticipant,
  IPaymentIntentResponse,
  IShoppingCartItem,
} from '@gigit/interfaces';

import { IUserState } from '../../reducers/user';
import { ICartState } from '../../reducers/cart';
import { IGroupState } from '../../reducers/group';
import { IEventState } from '../../reducers/event';

import { addItem, removeItem, clearCart, calculateTotal, updateQuantity } from '../../actions/cart';
import { purchaseGroupItems } from '../../actions/group';
import { getStoreItems, StoreItemOwnerType } from '../../actions/store';
import { purchaseEventItems, purchaseFundraise } from '../../actions/event';
import { createToast } from '../../actions/toaster';
import { setFlow, resetFlowState } from '../../actions/register';

import Portrait from '../../components/Portrait/Portrait';
import Dropdown from '../../components/Dropdown/Dropdown';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import CreditCard from '../../components/CreditCard/CreditCard';
import Checkbox from '../../components/Checkbox/Checkbox';
import FormSteps from '../../components/FormSteps/FormSteps';

import './Checkout.scss';
import { userSelectors } from '../../selectors/user';
import { localizeHelpers } from '../../localizeHelpers';
import { IStoreState } from '../../reducers/store';
import { IFlowStateConfig, IRegisterState } from '../../reducers/register';
import { IRegisterToFundraiserInfo, IShoppingCart, IUserRole } from '@gigit/interfaces';
import { formatCurrency } from '../../helpers';
import { Constants } from '@gigit/constants';
import { IOwnerObject, IToast, IAppError } from '../../interfaces';
import { PaymentMethodOption } from '../../components/CreditCard/PaymentMethodOption';
import {
  eventRequestActions,
  groupRequestActions,
  hubRequestActions,
  loggerRequestActions,
} from '../../requestActions';
import { uiConstants } from '../../constants';
import axios from 'axios';

interface IProps extends RouteComponentProps<any> {
  updateQuantity(
    index: number,
    quantity: number,
    shippingOptions?: any[],
    discountCode?: string,
  ): void;
  calculateTotal(
    ownerId: string,
    payload: any,
    type: string,
    options?: { callback?: (error?: IAppError) => void },
  ): void;
  getStoreItems(params: {
    ownerType: StoreItemOwnerType;
    ownerId: string;
    query?: URLSearchParams;
  }): void;
  userState: IUserState;
  groupState: IGroupState;
  eventState: IEventState;
  cartState: ICartState;
  storeState: IStoreState;
  registerState: IRegisterState;
  removeItem(index: number): void;
  createToast(toast: IToast): void;
  purchaseGroupItems(groupId: string, payload: any, callback?: any): void;
  addItem(item: any, parent: string): void;
  purchaseEventItems(eventId: string, payload: any, callback?: any, groupHandle?: string): void;
  purchaseFundraise(groupId: string, eventId: string, payload: any, callback?: () => void): void;
  clearCart(): void;
  adminPurchase?: boolean;
  onAdminPurchase?(): void;
  adminParentType?: string;
  type?: string;
  ownerId?: string;
  setFlow(flowConfig: IFlowStateConfig): void;
  resetFlowState(isComplete?: boolean): void;
  locale: string;
  userInfo?: IUserRole;
  owner?: IOwnerObject;
}

interface ISponsorAnswer {
  sponsor_name: string;
  sponsor_url: string;
  sponsor_img: string;
}

interface IState {
  // TODO [HOTFIX/3.4.4]: Make these not any.
  supportedAltPaymentMethods: any[];
  parentType: string;
  cartMeta: any;
  showCheckout: boolean;
  tryCreatePaymentMethod: boolean;
  paymentMethodLoading: boolean;
  trySubmitPayment: boolean;
  paymentIntent: IPaymentIntentResponse | null;
  cardName: string;
  // TODO: These address fields aren't being used?
  streetAddress: string;
  aptNum: string;
  city: string;
  province: string;
  country: string;
  postal: string;
  first_name: string;
  last_name: string;
  billingStreetAddress: string;
  billingAptNum: string;
  billingCity: string;
  billingProvince: string;
  billingCountry: string;
  billingPostal: string;
  shippingStreetAddress: string;
  shippingAptNum: string;
  shippingCity: string;
  shippingProvince: string;
  shippingCountry: string;
  shippingPostal: string;
  stripePromiseLoaded: boolean;
  stripePromise: Promise<Stripe | null> | null;
  email: string;
  paymentMethod: any;
  redirectToPage: boolean;
  formAnswers: any[];
  shipAnswers: any[];
  formAnswersLoading: boolean;
  sidebarClass: string;
  sidebarRef: any;
  discountCode: string;
  showTaxForm: boolean;
  phone: string;
  height: number;
  width: number;
  resize: boolean;
  items: Array<any>;
  isRegisterOnly: boolean;
  owner: IOwnerObject;
  search: string;
  storeItems: any[];
  showAdminStore: boolean;
  sameAsBilling: boolean;
  fundraisingTicket: string;
  testVal: string;
  sponsorInfo: ISponsorAnswer[];
  sponsorFormsLoading: boolean;
  donationAmount: string;
  donationAnonymous: boolean;
  selectedAltMethod: number;
  paymentRequest: PaymentRequest | null;
  showCheckoutButton: boolean;
}

class Checkout extends React.Component<IProps, IState> {
  private readonly checkoutTitleRef = React.createRef<HTMLHeadingElement>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      supportedAltPaymentMethods: [],
      parentType: '',
      paymentRequest: null,
      cartMeta: [],
      showCheckout: false,
      tryCreatePaymentMethod: false,
      paymentMethodLoading: false,
      trySubmitPayment: false,
      paymentIntent: null,
      email:
        this.props.userState.isLoggedIn && !this.props.adminPurchase
          ? this.props.userState.user.email
          : '',
      cardName:
        this.props.userState.isLoggedIn && !this.props.adminPurchase
          ? this.props.userState.user.first_name + ' ' + this.props.userState.user.last_name
          : '',
      streetAddress: '',
      aptNum: '',
      city: '',
      province: '',
      country: 'Canada',
      postal: '',
      billingStreetAddress: '',
      billingAptNum: '',
      billingCity: '',
      billingProvince: '',
      billingCountry: 'Canada',
      billingPostal: '',
      shippingStreetAddress: '',
      shippingAptNum: '',
      shippingCity: '',
      shippingProvince: '',
      shippingCountry: 'Canada',
      shippingPostal: '',
      phone: '',
      first_name: '',
      last_name: '',
      stripePromiseLoaded: !!this.props.adminPurchase,
      stripePromise: null,
      paymentMethod: null,
      redirectToPage: false,
      formAnswers: [],
      shipAnswers: [],
      formAnswersLoading: true,
      sidebarRef: React.createRef(),
      sidebarClass: '',
      discountCode: '',
      showTaxForm: false,
      height: window.innerHeight,
      width: window.innerWidth,
      resize: false,
      items: [],
      isRegisterOnly: false,
      owner: {} as IOwnerObject,
      search: '',
      storeItems: [],
      showAdminStore: !!this.props.adminPurchase,
      sameAsBilling: false,
      fundraisingTicket: '',
      testVal: '',
      sponsorInfo: [],
      sponsorFormsLoading: false,
      donationAmount: '',
      donationAnonymous: false,
      selectedAltMethod: -1,
      showCheckoutButton: true,
    };

    this.setCartMetaData = this.setCartMetaData.bind(this);
    this.toggleItemQuestions = this.toggleItemQuestions.bind(this);
    this.confirmOrder = this.confirmOrder.bind(this);
    this.onSuccessfulPaymentIntent = this.onSuccessfulPaymentIntent.bind(this);
    this.onFailedPaymentIntent = this.onFailedPaymentIntent.bind(this);
    this.onFailedSubmitPayment = this.onFailedSubmitPayment.bind(this);
    this.onSuccessfulSubmitPayment = this.onSuccessfulSubmitPayment.bind(this);
    this.onSuccessfulRegisterItem = this.onSuccessfulRegisterItem.bind(this);
    this.onSubmitSubscription = this.onSubmitSubscription.bind(this);
    this.getPurchasePayload = this.getPurchasePayload.bind(this);
    this.handleFormInputChange = this.handleFormInputChange.bind(this);
    this.checkDiscountCode = this.checkDiscountCode.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.showCheckout = this.showCheckout.bind(this);
    this.onFailedPurchase = this.onFailedPurchase.bind(this);
    this.getFormMetaData = this.getFormMetaData.bind(this);
    this.getAdminStep = this.getAdminStep.bind(this);
    this.itemInCart = this.itemInCart.bind(this);
    this.onMediaItemSelect = this.onMediaItemSelect.bind(this);
    this.onFundraiseSuccess = this.onFundraiseSuccess.bind(this);
    this.validateCheckout = this.validateCheckout.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  componentDidMount() {
    setSEOMetatags({
      title: localizeHelpers.translate('Checkout | Kambeo'),
      urlPath: `checkout`,
    });
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.setCartMetaData();

    //if admin purchase
    if (
      this.props.adminPurchase &&
      this.props.adminParentType &&
      this.props.ownerId &&
      this.props.owner
    ) {
      this.props.getStoreItems({
        ownerType: this.props.adminParentType as StoreItemOwnerType,
        ownerId: this.props.ownerId,
      });
      this.setupStripeInstance(this.props.owner);
    }

    //if cart purchase
    if (this.props.cartState.items.length > 0) {
      const getOwnerData =
        this.props.cartState.parentType === Constants.object_type.group
          ? groupRequestActions.getGroupByHandleOrId
          : this.props.cartState.parentType === Constants.object_type.hub
            ? hubRequestActions.getHubByID
            : eventRequestActions.getEventByHandleOrId;

      getOwnerData(this.props.cartState.items[0].owner_id)
        .then((responseOwner) => {
          this.setState(
            {
              owner: typeHelpers.createOwnerObject(
                this.props.cartState.parentType as OwnerType,
                responseOwner,
              ),
            },
            () => {
              const owner = typeHelpers.getGroupFromOwner(this.state.owner);
              this.setupStripeInstance(this.state.owner);
            },
          );
        })
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          this.props.createToast(toastError(errorObj.translatedMessage, 'Get Owner Information'));
        });
    }

    if (!this.props.adminPurchase) {
      this.getFormMetaData();
    }
    this.setSponsorForms();

    this.checkDiscountCode();

    this.setState({
      isRegisterOnly: this.props.cartState.total.total === 0,
    });

    // reset any stale fundraiser flows
    const _urlParams = queryString.parse(window.location.search);

    if (!_urlParams.fundraiser) {
      this.props.resetFlowState();
    }

    if (this.props.userInfo) {
      this.setState({
        first_name: this.props.userInfo.user?.first_name || '',
        last_name: this.props.userInfo?.user?.last_name || '',
        email: this.props.userInfo.user?.email || '',
        phone: this.props.userInfo.user?.phone || '',
      });
    }

    if (this.props.userState.locations) {
      this.props.userState.locations.map((location) => {
        this.setState({
          billingStreetAddress: location.line1 ? location.line1 : '',
          billingAptNum: location.line2 ? location.line2 : '',
          billingCity: location.city ? location.city : '',
          billingProvince: location.state ? location.state : '',
          billingCountry: location.country ? location.country : 'Canada',
          billingPostal: location.postal_code ? location.postal_code : '',
        });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const parentType = this.props.adminParentType || this.props.cartState.parentType;
    if (
      (prevState.showAdminStore && !this.state.showAdminStore) ||
      this.props.cartState.totalItems !== prevProps.cartState.totalItems
    ) {
      this.getFormMetaData();
      this.setSponsorForms();
    }

    // Don't show payment info if the final total is 0.
    if (prevProps.cartState.total.total !== this.props.cartState.total.total) {
      this.setState({
        isRegisterOnly: this.props.cartState.total.total === 0,
      });
    }

    if (
      this.props.adminPurchase &&
      prevProps.storeState.storeItems !== this.props.storeState.storeItems
    ) {
      this.setState({
        storeItems: this.props.storeState.storeItems,
      });
    }

    if (this.props.cartState !== prevProps.cartState) {
      this.setCartMetaData();
    }

    // TODO [Hotfix/3.4.4]: Avoid duplicated if.
    if (parentType === Constants.object_type.group) {
      if (
        prevProps.groupState.purchaseIntent !== this.props.groupState.purchaseIntent &&
        this.props.groupState.purchaseIntent !== null
      ) {
        this.setState({
          trySubmitPayment: true,
        });
      }
    } else if (parentType === Constants.object_type.event) {
      if (
        prevProps.eventState.purchaseIntent !== this.props.eventState.purchaseIntent &&
        this.props.eventState.purchaseIntent !== null
      ) {
        this.setState({
          trySubmitPayment: true,
        });
      }
    }

    if (
      this.state.showCheckout !== prevState.showCheckout &&
      this.state.showCheckout &&
      isMobileScreen()
    ) {
      this.checkoutTitleRef.current?.scrollIntoView({ behavior: 'auto' });
    }
  }
  /** Initializes Stripe instance based on owner account info. */
  setupStripeInstance(owner: IOwnerObject) {
    const account =
      owner.parentOwnerType === 'hub' ? (owner?.object as any)?.hub?.account : owner.account;
    if (!account) {
      throw new Error(
        localizeHelpers.translate(
          'Expected an account to receive payment. Contact administrator if this error persists.',
        ),
      );
    }

    this.setState({
      stripePromise: loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY, {
        stripeAccount: account.account_number,
        locale: this.props.locale as CheckoutLocale,
      }),
      stripePromiseLoaded: true,
    });
  }

  getFormMetaData() {
    this.setState(
      {
        formAnswersLoading: true,
      },
      () => {
        let _formAnswers = [];
        let _shipAnswers = [];

        for (let i in this.props.cartState.items) {
          if (
            this.props.cartState.items[i].form &&
            this.props.cartState.items[i].form.questions !== undefined
          ) {
            for (
              let _quantity = 0;
              _quantity < this.props.cartState.items[i].quantity;
              _quantity++
            ) {
              for (let q = 0; q < this.props.cartState.items[i].form.questions.length; q++) {
                if (this.props.cartState.items[i].form.questions[q].options.length > 0) {
                  if (
                    this.props.cartState.items[i].form.questions[q].question_type === 'checkbox'
                  ) {
                    _formAnswers.push([]);
                  } else {
                    _formAnswers.push(
                      this.props.cartState.items[i].form.questions[q].options[0].label,
                    );
                  }
                } else {
                  _formAnswers.push('');
                }
              }
            }
          }

          if (
            this.props.cartState.items[i].is_shipping_available ||
            (this.props.cartState.items[i].pickup_locations &&
              this.props.cartState.items[i].pickup_locations.length > 0)
          ) {
            _shipAnswers.push('');
          } else {
            _shipAnswers.push(false);
          }
        }

        this.setState(
          {
            shipAnswers: _shipAnswers,
            formAnswers: _formAnswers,
          },
          () => {
            this.setState({
              formAnswersLoading: false,
            });
          },
        );
      },
    );
  }

  setSponsorForms() {
    this.setState(
      {
        sponsorFormsLoading: true,
      },
      () => {
        let _sponsorAnswers: ISponsorAnswer[] = [];

        for (let i in this.props.cartState.items) {
          //if it is of type sponsorhip
          if (this.props.cartState.items[i].item_type === 'sponsorship') {
            for (
              let _quantity = 0;
              _quantity < this.props.cartState.items[i].quantity;
              _quantity++
            ) {
              // for (let q = 0; q < 3; q++) {
              //     _sponsorAnswers.push("");
              // }
              _sponsorAnswers.push({
                sponsor_img: '',
                sponsor_name: '',
                sponsor_url: '',
              });
            }
          }
        }

        this.setState(
          {
            sponsorInfo: _sponsorAnswers,
          },
          () => {
            this.setState({
              sponsorFormsLoading: false,
            });
          },
        );
      },
    );
  }

  setCartMetaData() {
    let _cartMeta = [];

    for (let i in this.props.cartState) {
      _cartMeta.push({
        index: i,
        collapsed: false,
        sponsorCollapsed: false,
      });
    }

    this.setState({
      cartMeta: _cartMeta,
    });
  }

  toggleItemQuestions(index: number) {
    let _cartMeta = this.state.cartMeta;

    _cartMeta[index].collapsed = !_cartMeta[index].collapsed;

    this.setState({
      cartMeta: _cartMeta,
    });
  }

  toggleSponsorQuestions(index: number) {
    let _cartMeta = this.state.cartMeta;

    _cartMeta[index].sponsorCollapsed = !_cartMeta[index].sponsorCollapsed;

    this.setState({
      cartMeta: _cartMeta,
    });
  }

  onFundraiseSuccess() {
    this.props.history.push(`/event/${this.state.owner.ownerHandle}/`);
  }

  async confirmOrder() {
    if (parseFloat(this.state.donationAmount) < 0) {
      const toast = toastError(
        localizeHelpers.translate('Donations cannot be a negative value.'),
        'Donation Amount',
      );
      this.props.createToast(toast);
      return;
    }

    // TODO: Solve type for getPurchasePayload
    let _payload = this.getPurchasePayload() as IRegisterToFundraiserInfo & IShoppingCart;
    // if the user is not logged in
    if (this.props.userState.isLoggedIn === false) {
      // @ts-ignore
      if (_payload?.purchaser) {
        // @ts-ignore
        _payload.purchaser.language = this.props.userState.loggedOutLanguage;
      }
      // @ts-ignore
      else if (_payload?.shopping_cart.purchaser) {
        // @ts-ignore
        _payload.shopping_cart.purchaser.language = this.props.userState.loggedOutLanguage;
      }
    }

    const parentType = this.props.adminParentType || this.props.cartState.parentType;
    const groupId =
      parentType === Constants.object_type.group
        ? this.props.cartState.items[0].owner_id
        : this.state.owner.parentOwnerId;

    if (this.state.paymentMethod === Constants.payment_method.invoice) {
      //if user pays invoice

      loggerRequestActions.log({
        message: 'Starting Invoice Payment',
        level: 'info',
        extra_options: {
          purchaser_email: _payload.purchaser.email,
        },
      });

      _payload.payment_method = 'invoice';
      if (
        (this.props.registerState?.flowConfig.fundraiser?.ticket ||
          this.props.registerState.flowConfig.fundraiser?.userHasTicket) &&
        _payload?.shopping_cart?.payment_method
      ) {
        _payload.shopping_cart.payment_method = 'invoice';
        this.setState({ paymentMethodLoading: true }, () => {
          this.props.purchaseFundraise(
            groupId,
            this.props.cartState.items[0].owner_id,
            _payload,
            this.onSuccessfulRegisterItem,
          );
        });
        return;
      }
      if (parentType === Constants.object_type.group) {
        this.props.purchaseGroupItems(
          this.props.cartState.items[0].owner_id,
          _payload,
          this.onSuccessfulRegisterItem,
        );
      } else if (parentType === Constants.object_type.event) {
        this.props.purchaseEventItems(
          this.props.cartState.items[0].owner_id,
          _payload,
          this.onSuccessfulRegisterItem,
          this.state.owner.parentOwnerId!,
        );
      }
    } else {
      //if user pays by card
      if (this.state.isRegisterOnly) {
        if (
          this.props.registerState?.flowConfig.fundraiser?.ticket ||
          this.props.registerState.flowConfig.fundraiser?.userHasTicket
        ) {
          this.setState(
            {
              paymentMethodLoading: true,
            },
            () => {
              this.props.purchaseFundraise(
                groupId,
                this.props.cartState.items[0].owner_id,
                _payload,
                this.onSuccessfulRegisterItem,
              );
            },
          );
          return;
        }

        if (this.props.cartState.items.length > 0) {
          this.setState(
            {
              paymentMethodLoading: true,
            },
            () => {
              if (parentType === Constants.object_type.group) {
                this.props.purchaseGroupItems(
                  this.props.cartState.items[0].owner_id,
                  _payload,
                  this.onSuccessfulRegisterItem,
                );
              } else if (parentType === Constants.object_type.event) {
                // TODO: BURN THIS FILE; HACKY FIX FOR /GIG-6042 (NEED TO GRAB EVENT TO HAVE GROUP ID FOR NOW)
                axios
                  .get<IEventSummaryFE>(
                    swapRouteParams(routes.GET_EVENT, {
                      handleOrId: this.props.cartState.items[0].owner_id,
                    }),
                  )
                  .then((response) => {
                    const eventOwnerId = response.data.group_id;
                    if (eventOwnerId) {
                      this.props.purchaseEventItems(
                        this.props.cartState.items[0].owner_id,
                        _payload,
                        this.onSuccessfulRegisterItem,
                        eventOwnerId,
                      );
                    }
                  });
              }
            },
          );
        }
      } else {
        this.setState({
          tryCreatePaymentMethod: true,
          paymentMethodLoading: true,
        });
      }
    }
  }

  onSuccessfulPaymentIntent(_paymentMethod: any) {
    this.setState(
      {
        paymentMethod: _paymentMethod,
      },
      () => {
        let _payload: any = this.getPurchasePayload();

        if (this.props.cartState.items.length > 0) {
          const parentType = this.props.adminParentType || this.props.cartState.parentType;
          const groupId =
            parentType === Constants.object_type.group
              ? this.props.cartState.items[0].owner_id
              : this.state.owner.parentOwnerId;

          if (
            this.props.registerState?.flowConfig.fundraiser?.ticket ||
            this.props.registerState.flowConfig.fundraiser
          ) {
            let _payload: any = this.getPurchasePayload();

            this.props.purchaseFundraise(groupId, this.props.cartState.items[0].owner_id, _payload);
            return;
          }
          if (parentType === Constants.object_type.group) {
            this.props.purchaseGroupItems(
              this.props.cartState.items[0].owner_id,
              _payload,
              this.onFailedPurchase,
            );
          } else if (parentType === Constants.object_type.event) {
            this.props.purchaseEventItems(
              this.props.cartState.items[0].owner_id,
              _payload,
              this.onFailedPurchase,
            );
          } else if (parentType === Constants.object_type.hub) {
            hubRequestActions
              .createPurchaseRequest(this.props.cartState.items[0].owner_id, _payload)
              .then((response) => {
                this.setState({
                  trySubmitPayment: true,
                  paymentIntent: response.data,
                });
              })
              .catch(this.onFailedPurchase);
          }
        }
      },
    );
  }

  onFailedPaymentIntent(_error: any) {
    const toast = toastError(
      localizeHelpers.translate(_error.message),
      'Payment Information Error',
    );
    this.props.createToast(toast);

    this.setState({
      paymentMethodLoading: false,
    });
  }

  onFailedSubmitPayment(_error: StripeError) {
    this.setState({
      paymentMethodLoading: false,
    });

    const message = localizeHelpers.translate('{{item_description}}: {{error_message}}', {
      item_description: _error.payment_intent?.description || 'Payment',
      error_message: _error.message || 'Unknown Error',
    });

    const toast = toastError(message, 'Purchase Error');
    this.props.createToast(toast);
  }

  onFailedPurchase(_error: any) {
    this.setState({
      paymentMethodLoading: false,
    });
  }

  checkFundraisingTicket() {
    // (ST) GIG-1379: Check if we came from Event fundraise action. If so we need to pass back the fundraising ticket id so Event page knows it was bought.
    // Done due to timing issue from when transaction gets confirmed (Won't get counted as bought until it goes from purchase_request -> purchase)
    // (LG) TODO: This is kinda hacky approach. Probably better way can be found.
    const _urlParams = queryString.parse(window.location.search);

    if (
      _urlParams.redirect &&
      _urlParams.redirect.includes('fn=fundraise') &&
      this.props.eventState.fundraisingTicket
    ) {
      const fundraisingTicketInCart = this.props.cartState.items.find(
        (i) => i.id === this.props.eventState.fundraisingTicket?.id,
      );
      if (fundraisingTicketInCart) {
        this.setState({
          fundraisingTicket: fundraisingTicketInCart.id,
        });
      }
    }
  }

  onSuccessfulSubmitPayment() {
    this.checkFundraisingTicket();

    const parentType = this.props.adminParentType || this.props.cartState.parentType;
    this.setState({ parentType });
    this.props.clearCart();

    let _msg = "You've successfully purchased items from {{owner_title}}.";

    if (this.props.adminPurchase) {
      _msg =
        "You've successfully purchased items for {{email}}. The transaction record may take a few minutes to appear.";
    }

    if (this.props.registerState.flowConfig.fundraiser) {
      _msg = `You've successfully registered to fundraise for {{event_handle}}.`;
    }

    const toast = toastSuccess(
      localizeHelpers.translate(_msg, {
        owner_title: this.state.owner.ownerTitle,
        email: this.state.email,
        event_handle: this.state.owner.ownerHandle,
      }),
      'Successful Purchase',
    );
    this.props.createToast(toast);

    if (
      this.props.registerState.flowConfig?.fundraiser?.ticket ||
      this.props.registerState.flowConfig?.fundraiser?.userHasTicket
    ) {
      if (
        this.props.registerState.flowConfig.fundraiser.type === 'individual' ||
        this.props.registerState.flowConfig.fundraiser.type === 'join'
      ) {
        this.props.history.push(
          `/event/${this.state.owner.ownerHandle}/individual/${this.props.userState.user.handle}?help=1`,
        );
      } else {
        this.props.history.push(
          `/event/${this.state.owner.ownerHandle}/team/${this.props.eventState?.newTeam}`,
        );
      }
    }

    if (
      !this.props.adminPurchase &&
      !this.props.registerState.flowConfig.fundraiser?.ticket &&
      !this.props.registerState.flowConfig.fundraiser?.userHasTicket
    ) {
      this.setState({
        redirectToPage: true,
      });
    } else {
      if (this.props.onAdminPurchase) {
        this.props.onAdminPurchase();
      }
    }
  }

  onSuccessfulRegisterItem() {
    this.checkFundraisingTicket();

    let _msg = "You've successfully purchased items from {{owner_title}}.";

    this.props.clearCart();

    if (this.props.adminPurchase) {
      _msg = "You've successfully purchased items for {{email}}.";
    }

    const toast = toastSuccess(
      localizeHelpers.translate(_msg, {
        owner_title: this.state.owner.ownerTitle,
        email: this.state.email,
      }),
      'Successful Purchase',
    );
    this.props.createToast(toast);

    if (
      this.props.registerState.flowConfig?.fundraiser?.ticket ||
      this.props.registerState.flowConfig?.fundraiser?.userHasTicket
    ) {
      if (
        this.props.registerState.flowConfig.fundraiser.type === 'individual' ||
        this.props.registerState.flowConfig.fundraiser.type === 'join'
      ) {
        this.props.history.push(
          `/event/${this.state.owner.ownerHandle}/individual/${this.props.userState.user.handle}?help=1`,
        );
      } else {
        this.props.history.push(
          `/event/${this.state.owner.ownerHandle}/team/${this.props.eventState?.newTeam}`,
        );
      }
    }

    this.props.history.push(`/${this.state.owner.ownerType}/${this.state.owner.ownerHandle}`);

    this.setState(
      {
        paymentMethodLoading: false,
      },
      () => {
        if (!this.props.adminPurchase) {
          this.setState({
            redirectToPage: true,
          });
        } else {
          if (this.props.onAdminPurchase) {
            this.props.onAdminPurchase();
          }
        }
      },
    );
  }

  onSubmitSubscription() {
    //
  }

  getPurchasePayload() {
    let _items: IShoppingCartItem[] = [];

    for (let i in this.props.cartState.items) {
      if (
        (this.props.cartState.items[i].form &&
          this.props.cartState.items[i].form.questions !== undefined) ||
        // If multiple of the same sponsorship are bought, need to be split to properly work.
        (this.props.cartState.items[i].item_type === 'sponsorship' &&
          this.props.cartState.items[i].quantity > 0)
      ) {
        let _offset = 0;
        let sponsorOffset = 0;

        for (let ic = 0; ic < parseInt(i); ic++) {
          if (
            this.props.cartState.items[ic].form &&
            this.props.cartState.items[ic].form.questions !== undefined
          ) {
            _offset +=
              this.props.cartState.items[ic].form.questions.length *
              parseInt(this.props.cartState.items[ic].quantity);
          }

          if (this.props.cartState.items[ic].item_type === 'sponsorship') {
            sponsorOffset += parseInt(this.props.cartState.items[ic].quantity);
          }
        }

        for (
          let quantityIndex = 0;
          quantityIndex < this.props.cartState.items[i].quantity;
          quantityIndex++
        ) {
          let participantsOnly =
            this.props.cartState.items[i]?.participants?.length ===
            this.props.cartState.items[i].quantity;
          let participantsWithMainUser =
            this.props.cartState.items[i]?.participants?.length + 1 ===
            this.props.cartState.items[i].quantity;
          // attatch item type and sponsor here

          let participant: IParticipant | undefined;
          if (participantsWithMainUser) {
            participant =
              quantityIndex === 0
                ? null
                : this.props.cartState.items[i].participants[quantityIndex - 1];
          } else if (participantsOnly) {
            participant = this.props.cartState.items[i].participants[quantityIndex];
          }

          let _item: IShoppingCartItem = {
            item_id: this.props.cartState.items[i].id,
            form: [],
            quantity: 1,
            participant: participant,
          };

          for (let q in this.props.cartState.items[i].form.questions) {
            let _formQuestionIndex =
              parseInt(q) +
              this.props.cartState.items[i].form.questions.length * quantityIndex +
              _offset;
            let _question = this.props.cartState.items[i].form.questions[q];
            let _q: any = {};

            _q.question_id = _question._id;
            _q.question_type = _question.question_type;
            _q.form_id = this.props.cartState.items[i].form.id;
            _q.question_label = _question.label;
            _q.answer = this.state.formAnswers[_formQuestionIndex];

            _item.form?.push(_q);
          }

          let sponsorIndex = sponsorOffset + quantityIndex;

          if (this.state.sponsorInfo[sponsorIndex]) {
            _item.sponsor = {
              sponsor_name: this.state.sponsorInfo[sponsorIndex].sponsor_name,
              sponsor_url: this.state.sponsorInfo[sponsorIndex].sponsor_url,
              sponsor_img: this.state.sponsorInfo[sponsorIndex].sponsor_img,
            };
          }

          if (this.state.shipAnswers[i] === 'ship') {
            _item.ship_by_vendor = true;
          } else if (this.state.shipAnswers[i] !== '' && this.state.shipAnswers[i] !== false) {
            for (let l in this.props.cartState.items[i].pickup_locations) {
              if (
                formatAddressLine(this.props.cartState.items[i].pickup_locations[l]) ===
                this.state.shipAnswers[i]
              ) {
                _item.pickup_location = { ...this.props.cartState.items[i].pickup_locations[l] };
                break;
              }
            }
          }

          _items.push(_item);
        }
      } else {
        // attach item type and sponsor here
        let _item: any = {
          item_id: this.props.cartState.items[i].id,
          quantity: this.props.cartState.items[i].quantity,
          item_type: this.props.cartState.items[i].item_type,
        };

        let sponsorOffset = 0;

        for (let ic = 0; ic < parseInt(i); ic++) {
          if (this.props.cartState.items[ic].item_type === 'sponsorship') {
            sponsorOffset += parseInt(this.props.cartState.items[ic].quantity);
          }
        }

        let sponsorIndex = sponsorOffset;

        if (this.state.sponsorInfo[sponsorIndex]) {
          _item.sponsor = {
            sponsor_name: this.state.sponsorInfo[sponsorIndex].sponsor_name,
            sponsor_url: this.state.sponsorInfo[sponsorIndex].sponsor_url,
            sponsor_img: this.state.sponsorInfo[sponsorIndex].sponsor_img,
          };
        }

        if (this.state.shipAnswers[i] === 'ship') {
          _item.ship_by_vendor = true;
        } else if (this.state.shipAnswers[i] !== '' && this.state.shipAnswers[i] !== false) {
          for (let l in this.props.cartState.items[i].pickup_locations) {
            if (
              formatAddressLine(this.props.cartState.items[i].pickup_locations[l]) ===
              this.state.shipAnswers[i]
            ) {
              _item.pickup_location = { ...this.props.cartState.items[i].pickup_locations[l] };
              break;
            }
          }
        }

        if (
          this.props.cartState.items[i].quantity === 1 &&
          this.props.cartState.items[i]?.participant
        ) {
          _item.participant = this.props.cartState.items[i].participant;
        } else if (
          this.props.cartState.items[i].quantity > 1 &&
          this.props.cartState.items[i].participants
        ) {
          _item.participants = this.props.cartState.items[i].participants;
        }

        _items.push(_item);
      }
    }

    let _payload: IShoppingCart = {
      payment_method_id: this.state.paymentMethod?.id,
      payment_method_country: this.state.paymentMethod?.card?.country,
      payment_method: this.state.paymentMethod?.type,
      items: _items,
      discount_code: this.state.discountCode,
      purchaser: {
        email: this.state.email,
        phone: this.state.phone,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
      },
      address: {
        line1: this.state.billingStreetAddress,
        line2: this.state.billingAptNum,
        city: this.state.billingCity,
        state: this.state.billingProvince,
        country: this.state.billingCountry,
        postal_code: this.state.billingPostal,
      },
      billing_address: {
        line1: this.state.billingStreetAddress,
        line2: this.state.billingAptNum,
        city: this.state.billingCity,
        state: this.state.billingProvince,
        country: this.state.billingCountry,
        postal_code: this.state.billingPostal,
      },
    };

    if (this.state.isRegisterOnly) {
      delete _payload.payment_method_id;
      delete _payload.payment_method_country;

      _payload.payment_method = 'card';
    }

    if (this.isShippingItem()) {
      if (this.state.sameAsBilling) {
        _payload.shipping_address = { ..._payload.billing_address };
      } else {
        _payload.shipping_address = {
          line1: this.state.shippingStreetAddress,
          line2: this.state.shippingAptNum,
          city: this.state.shippingCity,
          state: this.state.shippingProvince,
          country: this.state.shippingCountry,
          postal_code: this.state.shippingPostal,
        };
      }
    }

    if (this.state.donationAmount) {
      _payload.donation = {
        price: {
          amount: parseFloat(this.state.donationAmount),
          amount_type: 'fixed',
        },
        anonymous: this.state.donationAnonymous,
      };
    }

    if (this.props.userState.isLoggedIn === false) {
      _payload.purchaser.language = this.props.userState.loggedOutLanguage;
    }

    if (
      this.props.registerState.flowConfig.fundraiser?.ticket ||
      this.props.registerState.flowConfig?.fundraiser?.userHasTicket
    ) {
      let participants = this.props.registerState.flowConfig.fundraiser?.participants;

      let fundraisePayload: IRegisterToFundraiserInfo = {
        individual_create: {
          goal: this.props.registerState.flowConfig.fundraiser?.goal,
          story: '',
          content_creator: this.props.registerState.flowConfig.fundraiser?.content_creator,
          hasTicket: this.props.registerState.flowConfig.fundraiser?.userHasTicket,
        },
        registration_item_id: this.props.registerState.flowConfig.fundraiser?.ticket?.id || '',
        shopping_cart: {
          ..._payload,
        },
        team_create: undefined,
        join_team: undefined,
        participants: this.props.registerState.flowConfig.fundraiser?.participants,
      };

      if (this.props.registerState.flowConfig.fundraiser.type === 'create') {
        let teamPayload = {
          name: this.props.registerState.flowConfig.fundraiser?.teamName,
          goal: this.props.registerState.flowConfig.fundraiser.teamGoal,
          personal_goal: this.props.registerState.flowConfig.fundraiser?.goal,
          content_creator: this.props.registerState.flowConfig.fundraiser?.content_creator,
        };

        fundraisePayload.team_create = teamPayload;
      }

      if (this.props.registerState.flowConfig.fundraiser.type === 'join') {
        let joinPayload: IJoinTeamInfo = {
          story: '',
          goal: this.props.registerState.flowConfig.fundraiser?.goal,
          content_creator: this.props.registerState.flowConfig.fundraiser?.content_creator,
          teamId: this.props.registerState?.flowConfig?.fundraiser?.teamId,
          fitness_tracking_enabled: false,
        };

        fundraisePayload.join_team = joinPayload;
      }

      return fundraisePayload;
    }

    return _payload;
  }

  handleFormInputChange(event: any, index: number) {
    const target = event.target;
    const value = target.value;

    let _formAnswers = [...this.state.formAnswers];
    // _formAnswers[index] = value;

    if (target.type === 'checkbox') {
      if (!this.state.formAnswers[index].includes(value)) {
        _formAnswers[index] = [..._formAnswers[index], value];
      } else {
        let r = _formAnswers[index];

        let idx = r.findIndex((i: any) => {
          return i === value;
        });

        r.splice(idx, 1);
        _formAnswers[index] = r;
      }
    } else {
      _formAnswers[index] = value;
    }

    this.setState({
      formAnswers: _formAnswers,
    });
  }

  handleSponsorInputChange(event: any, index: number) {
    const target = event.target;
    const value = target.value;

    let sponsorAnswers = [...this.state.sponsorInfo];
    (sponsorAnswers[index] as any)[event.target.name] = value;

    this.setState({
      sponsorInfo: sponsorAnswers,
    });
  }

  checkDiscountCode() {
    if (this.props.cartState.items.length > 0) {
      let _items: any = [];

      for (let i in this.props.cartState.items) {
        let _item: any = {
          item_id: this.props.cartState.items[i].id,
          quantity: this.props.cartState.items[i].quantity,
        };

        if (this.state.shipAnswers[i] === 'ship') {
          _item.ship_by_vendor = true;
        } else if (this.state.shipAnswers[i] !== '' && this.state.shipAnswers[i] !== false) {
          for (let l in this.props.cartState.items[i].pickup_locations) {
            if (
              formatAddressLine(this.props.cartState.items[i].pickup_locations[l]) ===
              this.state.shipAnswers[i]
            ) {
              _item.pickup_location = { ...this.props.cartState.items[i].pickup_locations[l] };
              break;
            }
          }
        }

        _items.push(_item);
      }

      let payload: Partial<IShoppingCart> = {
        payment_method: 'card',
        items: _items,
        discount_code: this.state.discountCode,
      };

      if (this.state.donationAmount) {
        payload.donation = {
          price: {
            amount: parseFloat(this.state.donationAmount),
            amount_type: 'fixed',
          },
          anonymous: this.state.donationAnonymous,
        };
      }

      this.props.calculateTotal(
        this.props.cartState.items[0].owner_id,
        payload,
        this.props.cartState.items[0].owner_type,
        {
          callback: (error) => {
            this.setState({
              showCheckoutButton: !error,
            });
          },
        },
      );
    }
  }

  showTax() {
    this.setState({
      showTaxForm: true,
    });
  }

  showCheckout() {
    let _empty_response = false;
    let _empty_shipping = false;
    let _missing_quantity = false;
    let missingSponsor = false;
    let missingSponsorSpecifics = {
      sponsor_name: true,
      sponsor_img: true,
      sponsor_url: true,
    };

    for (let f in this.state.formAnswers) {
      if (
        this.state.formAnswers[f] === '' ||
        this.state.formAnswers[f] === undefined ||
        (Array.isArray(this.state.formAnswers[f]) && this.state.formAnswers[f].length <= 0)
      ) {
        _empty_response = true;
      }
    }

    for (let s in this.state.shipAnswers) {
      if (this.state.shipAnswers[s] === '') {
        _empty_shipping = true;
      }
    }

    for (let s in this.state.sponsorInfo) {
      if (!this.state.sponsorInfo[s].sponsor_name) {
        missingSponsor = true;
      }

      let fields = Object.keys(this.state.sponsorInfo[s]);

      for (let objProp of fields) {
        if (!this.state.sponsorInfo[s][objProp as keyof ISponsorAnswer]) {
          missingSponsorSpecifics[objProp as keyof ISponsorAnswer] = false;
        }
      }
    }

    for (let i in this.props.cartState.items) {
      if (this.props.cartState.items[i].quantity <= 0) {
        _missing_quantity = true;
      }
    }

    if (!_empty_response && !_empty_shipping && !missingSponsor) {
      this.setState({
        showCheckout: true,
      });
    } else {
      let _msg = 'Please answer all store item questions to checkout.';

      if (_empty_shipping) {
        _msg = 'Please answer all shipping/pick-up options to checkout.';
      }

      if (_missing_quantity) {
        _msg = 'Please enter a quantity for all items in your cart.';
      }

      if (missingSponsor) {
        _msg = 'Please enter a Sponsor Name';
      }

      const toast = toastError(localizeHelpers.translate(_msg), 'Store Item Error');
      this.props.createToast(toast);
    }
  }

  getSubTotal() {
    let _subtotal = this.props.cartState.total.total;
    const ownerObject = typeHelpers.tryGetOwnerObjectAs(this.state.owner, 'group', 'event', 'hub');

    if (ownerObject?.fee_control && !ownerObject.fee_control.covers_gigit_fees) {
      _subtotal -= this.props.cartState.total.gigit_fee;
    }

    if (ownerObject?.fee_control && !ownerObject.fee_control.covers_processing_fees) {
      _subtotal -= this.props.cartState.total.payment_platform_fee;
    }

    if (this.isShippingItem()) {
      _subtotal -= this.props.cartState.total.shipping;
    }

    return _subtotal;
  }

  isShippingItem() {
    for (let s in this.state.shipAnswers) {
      if (this.state.shipAnswers[s] === 'ship') {
        return true;
      }
    }

    return false;
  }

  getAdminStep() {
    if (this.state.showAdminStore) {
      return 0;
    }

    if (!this.state.showCheckout && !this.state.showAdminStore) {
      return 1;
    }

    if (this.state.showCheckout) {
      return 2;
    }

    return 0;
  }

  itemInCart(id: string) {
    for (let i in this.props.cartState.items) {
      if (this.props.cartState.items[i].id === id) {
        return true;
      }
    }

    return false;
  }

  getItemIndex(id: string) {
    for (let i in this.props.cartState.items) {
      if (this.props.cartState.items[i].id === id) {
        return parseInt(i);
      }
    }

    return 0;
  }

  onMediaItemSelect(file: any, index: number) {
    let sponsorInfo = [...this.state.sponsorInfo];

    sponsorInfo[index].sponsor_img = file.file;

    this.setState({
      sponsorInfo: sponsorInfo,
    });
  }

  handleParticipantRemoval(index: number, item: any) {
    let p = this.props.registerState.flowConfig.fundraiser.participants;
    p.splice(index, 1);

    let flowUpdate = { ...this.props.registerState.flowConfig };
    flowUpdate.fundraiser.participants = p;
    this.props.setFlow(flowUpdate);

    this.props.removeItem(this.getItemIndex(item.id));
  }

  getBillingDetails(): PaymentMethodCreateParams.BillingDetails {
    return {
      // Don't provide email if payee is a "Dummy" user.
      email: !this.props.userInfo?.user?.is_dummy_user ? this.state.email : undefined,
      name: this.state.cardName,
    };
  }

  // TODO [HOTFIX/3.4.4]: Replace type `any` with proper type.
  async validateCheckout(e: any) {
    let inputs = document.querySelectorAll('input');
    // Check all the required fields values and prevent or allow the intent creation
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].required && !inputs[i].value) {
        e.preventDefault();
        inputs[i].focus();
        inputs[i].scrollIntoView();

        // TODO [HOTFIX/3.4.4]: Make this message localized.
        const toast = toastError(
          `Please fill out the required fields (${inputs[i].getAttribute('label')})`,
          'Checkout',
        );
        this.props.createToast(toast);
        return;
      }
    }

    // Continue creating intents as modal is opening.
    if (parseFloat(this.state.donationAmount) < 0) {
      e.preventDefault();
      const toast = toastError(
        localizeHelpers.translate('Donations cannot be a negative value.'),
        'Donation Amount',
      );
      this.props.createToast(toast);
      return;
    }

    this.setState({
      tryCreatePaymentMethod: true,
    });
  }

  render() {
    let _found = false;
    const ownerObject = typeHelpers.tryGetOwnerObjectAs(this.state.owner, 'group', 'event', 'hub');
    const currency = this.state.owner.account?.currency ?? defaultCurrency;
    let _params = queryString.parse(this.props.location.search);
    const _urlParams = queryString.parse(window.location.search);
    let isFundraiseCheckout = _urlParams?.fundraiser;

    if (this.state.redirectToPage) {
      if (_params.redirect) {
        return this.state.fundraisingTicket ? (
          <Redirect
            to={_params.redirect.toString() + `&fundraisingTicket=${this.state.fundraisingTicket}`}
          />
        ) : (
          <Redirect to={_params.redirect.toString()} />
        );
      } else {
        let tab = '/account?t=purchases';
        if (this.state.parentType === Constants.object_type.group) {
          tab = `/group/${this.state.owner.ownerHandle}`;
        } else if (this.state.parentType === Constants.object_type.event) {
          tab = `/event/${this.state.owner.ownerHandle}`;
        }

        return <Redirect to={tab} />;
      }
    } else {
      return (
        <div className="Checkout">
          <div className="Checkout-inner">
            <div className="left-wrap">
              {this.props.adminPurchase && (
                <div className="steps">
                  <div className="title">Add Store Purchase</div>
                  {this.state.showAdminStore && (
                    <div className="sub-title">Select items below to continue</div>
                  )}
                  <FormSteps
                    steps={['Order', 'Purchase', 'Confirmation']}
                    current={this.getAdminStep()}
                  />
                </div>
              )}
              {this.state.showAdminStore && (
                <div className="admin-store">
                  <div className="search">
                    <TextField
                      placeholder="Find a store item..."
                      name="search"
                      value={this.state.search}
                      type="text"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                    <Button
                      icon="fal fa-search"
                      text="Search"
                    />
                  </div>
                  <div className="store-items">
                    <div className="store-title">Select Store Items</div>
                    <div className="headers">
                      <div className="col select" />
                      <div className="col item-details">Item Details</div>
                      <div className="col price">Price</div>
                    </div>
                    <div className="store-items-inner">
                      {this.state.storeItems.map((item: any, index: number) => {
                        if (
                          this.state.search === '' ||
                          item.name.toLowerCase().includes(this.state.search.toLowerCase())
                        ) {
                          _found = true;
                          return (
                            <div
                              key={index}
                              className="row"
                            >
                              <div className="row-inner">
                                <div className="col select">
                                  {!this.itemInCart(item.id) && (
                                    <i
                                      className="fas fa-square"
                                      onClick={() => {
                                        this.props.addItem(
                                          { ...item, ...{ quantity: 1 } },
                                          this.props.type ? this.props.type : 'group',
                                        );
                                      }}
                                    />
                                  )}
                                  {this.itemInCart(item.id) && (
                                    <i
                                      className="fas fa-check-square"
                                      onClick={() => {
                                        this.props.removeItem(this.getItemIndex(item.id));
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col item-details">
                                  <div className="item-details-inner">
                                    <Portrait
                                      size={80}
                                      currentImage={item.media.length > 0 ? item.media[0].url : ''}
                                    />
                                    <div
                                      className="item-name"
                                      notranslate="yes"
                                    >
                                      {item.name}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col price"
                                  notranslate="yes"
                                >
                                  {formatCurrency(item.price || 0, currency, this.props.locale)}
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                      {this.state.search !== '' && !_found && (
                        <div className="search-error">Your search returned 0 results</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!this.state.showCheckout && !this.state.showAdminStore && (
                <div className="cart-details">
                  <h1>Your Cart</h1>
                  <div className="cart-items">
                    {this.props.cartState.items.length > 0 && (
                      <div className="headers">
                        <div className="col item-details">Item Details</div>
                        <div className="col quantity">Quantity</div>
                        <div className="col price">Price</div>
                        <div className="col actions" />
                      </div>
                    )}
                    {this.props.cartState.items.map((item: any, index: number) => {
                      let _quantity = [];

                      for (let _q = 0; _q < item.quantity; _q++) {
                        _quantity.push('');
                      }

                      let _offset = 0;
                      let sponsorOffset = 0;

                      for (let ic = 0; ic < index; ic++) {
                        if (
                          this.props.cartState.items[ic].form &&
                          this.props.cartState.items[ic].form.questions !== undefined
                        ) {
                          _offset +=
                            this.props.cartState.items[ic].form.questions.length *
                            parseInt(this.props.cartState.items[ic].quantity);
                        }

                        if (this.props.cartState.items[ic].item_type === 'sponsorship') {
                          sponsorOffset += parseInt(this.props.cartState.items[ic].quantity);
                        }
                      }

                      return (
                        <div
                          className="item-wrap"
                          key={index}
                        >
                          <div className="item">
                            <div className="col item-details">
                              <Portrait
                                size={this.state.width > 560 ? 100 : 40}
                                currentImage={item.media[0]?.url || ''}
                              />
                              <div className="item-details">
                                <div
                                  className="item-name"
                                  notranslate="yes"
                                >
                                  {item.name}
                                </div>
                                <div
                                  className="mobile-price"
                                  notranslate="yes"
                                >
                                  {formatCurrency(item.price, currency, this.props.locale)}
                                </div>
                                <p
                                  className="item-description"
                                  notranslate="yes"
                                >
                                  {item.description
                                    .replace(/<[^>]*>?/gm, '')
                                    .replace(/&nbsp;/g, ' ')}
                                </p>
                              </div>
                            </div>

                            <div className="col quantity">
                              <div className="actions">
                                <i
                                  onClick={() => {
                                    this.props.removeItem(index);
                                  }}
                                  className="fal fa-trash-alt"
                                />
                              </div>
                              {/* // disable quantity here if fundraiser */}
                              {!isFundraiseCheckout && (
                                <i
                                  onClick={() =>
                                    item.quantity > 1
                                      ? this.props.updateQuantity(
                                          index,
                                          item.quantity - 1,
                                          this.state.shipAnswers,
                                          this.state.discountCode,
                                        )
                                      : undefined
                                  }
                                  className={`${item.quantity === 1 ? 'disabled' : ''} minus far fa-minus-circle`}
                                />
                              )}
                              <span notranslate="yes">{item.quantity}</span>
                              {!isFundraiseCheckout && (
                                <i
                                  onClick={() =>
                                    item.quantity < item.inventory?.max_purchase_quantity ||
                                    item.quantity < 999
                                      ? this.props.updateQuantity(
                                          index,
                                          item.quantity + 1,
                                          this.state.shipAnswers,
                                          this.state.discountCode,
                                        )
                                      : undefined
                                  }
                                  className={`${item.quantity < item.inventory?.max_purchase_quantity || item.quantity < 999 ? '' : 'disabled'} plus far fa-plus-circle`}
                                />
                              )}
                            </div>
                            <div
                              className="col price"
                              notranslate="yes"
                            >
                              {formatCurrency(item.price, currency, this.props.locale)}
                            </div>
                            <div className="col actions">
                              <i
                                onClick={() => {
                                  this.props.removeItem(index);
                                }}
                                className="fal fa-trash-alt"
                              />
                            </div>
                          </div>
                          {item.item_type === 'sponsorship' && (
                            <div className="item-questions">
                              {_quantity.map((qItem, quantityIndex) => {
                                const sponsorIndex = sponsorOffset + quantityIndex;

                                let sponsors = ['image', 'name', 'website'];

                                if (!this.state.sponsorInfo[sponsorIndex]) {
                                  return null;
                                }

                                return (
                                  <div
                                    className="question-inner-wrap"
                                    key={sponsorIndex}
                                  >
                                    <div className="form-name">
                                      <span className="no-select">
                                        Sponsor (Item{' '}
                                        <var data-var="sponsor_number">{sponsorIndex + 1}</var>)
                                      </span>
                                      {sponsorIndex === 0 && (
                                        <i
                                          onClick={() => {
                                            this.toggleSponsorQuestions(index);
                                          }}
                                          className={
                                            this.state.cartMeta[index]?.sponsorCollapsed
                                              ? 'fal fa-plus'
                                              : 'fal fa-minus'
                                          }
                                        />
                                      )}
                                    </div>

                                    {!this.state.cartMeta[index]?.sponsorCollapsed && (
                                      <Fragment>
                                        <div className="question-wrap">
                                          <div className="question">
                                            <div className="label no-select">
                                              Sponsor Image<span className="required">*</span>
                                            </div>
                                            {/* <ImageUpload onImgSelect={(file: any) => this.onMediaItemSelect(file, sponsorIndex)}/> */}
                                            <Portrait
                                              onChange={(file) =>
                                                this.onMediaItemSelect(file, sponsorIndex)
                                              }
                                              currentImage={
                                                this.state.sponsorInfo[sponsorIndex].sponsor_img ??
                                                undefined
                                              }
                                              size={80}
                                              overlay={true}
                                            />
                                          </div>
                                        </div>

                                        <div className="question-wrap">
                                          <div className="question">
                                            <div className="label no-select">
                                              Sponsor Name <span className="required">*</span>
                                            </div>
                                            <div className="answer">
                                              <TextField
                                                required={true}
                                                value={
                                                  this.state.sponsorInfo[sponsorIndex].sponsor_name
                                                }
                                                name="sponsor_name"
                                                type="text"
                                                onChange={(e) => {
                                                  this.handleSponsorInputChange(e, sponsorIndex);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="question-wrap">
                                          <div className="question">
                                            <div className="label no-select">
                                              Sponsor Website <span className="required">*</span>
                                            </div>
                                            <div className="answer">
                                              <TextField
                                                value={
                                                  this.state.sponsorInfo[sponsorIndex].sponsor_url
                                                }
                                                name="sponsor_url"
                                                type="text"
                                                onChange={(e) => {
                                                  this.handleSponsorInputChange(e, sponsorIndex);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </Fragment>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          {!this.state.formAnswersLoading &&
                            item.form?.questions != null &&
                            item.form?.questions.length > 0 && (
                              <div className="item-questions">
                                {_quantity.map((qItem, quantityIndex: any) => {
                                  let itemText;

                                  if (
                                    this.props.registerState.flowConfig.fundraiser?.ticket ||
                                    this.props.registerState.flowConfig.fundraiser?.userHasTicket
                                  ) {
                                    if (quantityIndex === 0) {
                                      if (
                                        item.id ===
                                        this.props.registerState.flowConfig.fundraiser?.ticket?.id
                                      ) {
                                        itemText = `${this.props.userState.user?.first_name} ${this.props.userState.user?.last_name}`;
                                      } else if (item?.participants) {
                                        itemText = `${item?.participants[quantityIndex]?.first_name} ${item?.participants[quantityIndex]?.last_name}`;
                                      } else {
                                        itemText = `${this.props.userState.user?.first_name} ${this.props.userState.user?.last_name}`;
                                      }
                                    } else {
                                      if (
                                        item?.participants &&
                                        item.id !==
                                          this.props.registerState.flowConfig.fundraiser?.ticket?.id
                                      ) {
                                        itemText = `${item?.participants[quantityIndex]?.first_name} ${item?.participants[quantityIndex]?.last_name}`;
                                      } else if (
                                        item?.participants &&
                                        item.id ===
                                          this.props.registerState.flowConfig.fundraiser?.ticket?.id
                                      ) {
                                        itemText = `${item?.participants[quantityIndex - 1]?.first_name} ${item?.participants[quantityIndex - 1]?.last_name}`;
                                      } else {
                                        itemText = `${this.props.userState.user?.first_name} ${this.props.userState.user?.last_name}`;
                                      }
                                    }
                                  } else {
                                    itemText = localizeHelpers.translate(`Item {{form_index}}`, {
                                      form_index: quantityIndex + 1,
                                    });
                                  }

                                  return (
                                    <div
                                      className="question-inner-wrap"
                                      key={quantityIndex}
                                    >
                                      <div className="form-name">
                                        <span
                                          className="no-select"
                                          notranslate="yes"
                                        >
                                          {item.form.form_name} ({itemText})
                                        </span>
                                        {quantityIndex === 0 && (
                                          <i
                                            onClick={() => {
                                              this.toggleItemQuestions(index);
                                            }}
                                            className={
                                              this.state.cartMeta[index]?.collapsed
                                                ? 'fal fa-plus'
                                                : 'fal fa-minus'
                                            }
                                          />
                                        )}
                                      </div>
                                      {!this.state.cartMeta[index]?.collapsed &&
                                        item.form.questions.map((question: any, qIndex: any) => {
                                          let _formQuestionIndex =
                                            parseInt(qIndex) +
                                            item.form.questions.length * parseInt(quantityIndex) +
                                            _offset;
                                          switch (question.question_type) {
                                            case 'text':
                                              return (
                                                <div
                                                  key={qIndex}
                                                  className="question-wrap"
                                                >
                                                  <div className="question">
                                                    <div
                                                      className="label no-select"
                                                      notranslate="yes"
                                                    >
                                                      {question.label}
                                                    </div>
                                                    <div className="answer">
                                                      <TextField
                                                        value={
                                                          this.state.formAnswers[_formQuestionIndex]
                                                        }
                                                        name="answer"
                                                        type="text"
                                                        onChange={(e) => {
                                                          this.handleFormInputChange(
                                                            e,
                                                            _formQuestionIndex,
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            case 'checkbox':
                                              return (
                                                <div
                                                  key={qIndex}
                                                  className="question-wrap"
                                                >
                                                  <div className="question">
                                                    <div
                                                      className="label no-select"
                                                      notranslate="yes"
                                                    >
                                                      {question.label}
                                                    </div>
                                                    <div
                                                      className="answer"
                                                      notranslate="yes"
                                                    >
                                                      {question.options.map(
                                                        (option: any, oIndex: any) => {
                                                          return (
                                                            <Checkbox
                                                              key={oIndex}
                                                              name={option.label}
                                                              onChange={(e) => {
                                                                this.handleFormInputChange(
                                                                  e,
                                                                  _formQuestionIndex,
                                                                );
                                                              }}
                                                              checked={
                                                                this.state.formAnswers[
                                                                  _formQuestionIndex
                                                                ] &&
                                                                this.state.formAnswers[
                                                                  _formQuestionIndex
                                                                ].includes(option.label)
                                                              }
                                                              value={option.label}
                                                              label={option.label}
                                                            />
                                                          );
                                                        },
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            case 'dropdown':
                                              let _options = [];

                                              for (let _o in question.options) {
                                                _options.push({
                                                  label: question.options[_o].label,
                                                  value: question.options[_o].label,
                                                });
                                              }

                                              return (
                                                <div
                                                  key={qIndex}
                                                  className="question-wrap"
                                                >
                                                  <div className="question">
                                                    <div
                                                      className="label no-select"
                                                      notranslate="yes"
                                                    >
                                                      {question.label}
                                                    </div>
                                                    <div
                                                      className="answer"
                                                      notranslate="yes"
                                                    >
                                                      <Dropdown
                                                        shouldSort={true}
                                                        name={'dd-' + _formQuestionIndex}
                                                        value={
                                                          this.state.formAnswers[_formQuestionIndex]
                                                        }
                                                        onChange={(e) => {
                                                          this.handleFormInputChange(
                                                            e,
                                                            _formQuestionIndex,
                                                          );
                                                        }}
                                                        options={_options}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            default:
                                              return null;
                                          }
                                        })}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          {!item.form?.questions && item.participants?.length > 0 && (
                            <div className="item-questions">
                              {_quantity.map((qItem, quantityIndex: number) => {
                                let itemText;
                                if (this.props.registerState.flowConfig.fundraiser?.ticket) {
                                  if (quantityIndex === 0) {
                                    return null;
                                  } else {
                                    itemText = `${item?.participants[quantityIndex - 1]?.first_name} ${item?.participants[quantityIndex - 1]?.last_name}`;
                                  }

                                  return (
                                    <div
                                      key={quantityIndex}
                                      className="question-inner-wrap"
                                    >
                                      <div className="form-name no-marg">
                                        <span className="no-select">
                                          {item.name} - {itemText}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          )}
                          {this.state.shipAnswers[index] !== false && (
                            <div className="item-questions shipping">
                              <div className="form-name">
                                <i
                                  className={
                                    this.state.shipAnswers[index] === ''
                                      ? 'fa fa-times-circle'
                                      : 'fa fa-check-circle'
                                  }
                                />
                                <span className="no-select">Shipping or Pick-up</span>
                              </div>
                              <p>
                                Please select your preferred shipping or pick-up method to use on
                                this order.
                              </p>
                              {item.is_shipping_available && (
                                <div className="ship-option">
                                  <div className="inner-title">Shipping By Vendor</div>
                                  <div
                                    className="option"
                                    onClick={() => {
                                      let _shipAnswers = [...this.state.shipAnswers];
                                      _shipAnswers[index] = 'ship';
                                      this.setState({ shipAnswers: _shipAnswers }, () => {
                                        this.checkDiscountCode();
                                      });
                                    }}
                                  >
                                    {this.state.shipAnswers[index] === 'ship' ? (
                                      <i className="fas fa-circle" />
                                    ) : (
                                      <i className="far fa-circle" />
                                    )}
                                    {item.shipping_price_type === 'Amount' && (
                                      <span>
                                        Shipping Rate{' '}
                                        {formatCurrency(
                                          item.shipping_price,
                                          currency,
                                          this.props.locale,
                                        )}
                                      </span>
                                    )}
                                    {item.shipping_price_type !== 'Amount' && (
                                      <span>{item.shipping_price_type}</span>
                                    )}
                                  </div>
                                </div>
                              )}
                              {item.pickup_locations && item.pickup_locations.length > 0 && (
                                <div className="pickup-option">
                                  <div className="inner-title">Pick-up</div>
                                  {item.pickup_locations.map((location: any, ii: number) => {
                                    return (
                                      <div
                                        notranslate="yes"
                                        key={ii}
                                        className="option"
                                        onClick={() => {
                                          let _shipAnswers = [...this.state.shipAnswers];
                                          _shipAnswers[index] = formatAddressLine(location);
                                          this.setState({ shipAnswers: _shipAnswers }, () => {
                                            this.checkDiscountCode();
                                          });
                                        }}
                                      >
                                        {formatAddressLine(location) ===
                                        this.state.shipAnswers[index] ? (
                                          <i className="fas fa-circle" />
                                        ) : (
                                          <i className="far fa-circle" />
                                        )}
                                        {location.title}
                                        <span>({formatAddressLine(location)})</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {this.props.cartState.items.length === 0 && (
                      <div className="empty-cart">
                        You haven't added any items to your cart yet.
                      </div>
                    )}
                  </div>
                </div>
              )}
              {this.state.showCheckout && (
                <div className="checkout-details">
                  <div className="checkout-details-inner">
                    <h2 ref={this.checkoutTitleRef}>Checkout</h2>
                    <form
                      className="checkout-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.confirmOrder();
                      }}
                    >
                      <h2>Account</h2>
                      <div className="details-row">
                        <div className="details-col">
                          <TextField
                            disabled={
                              (this.props.userState.isLoggedIn && !this.props.adminPurchase) ||
                              !!this.props.userInfo
                            }
                            type="text"
                            name="email"
                            required={!this.props.adminPurchase || !this.props.userInfo}
                            onChange={(e) => {
                              handleInputChange(e, this);
                            }}
                            value={this.state.email}
                            label="Email"
                          />
                        </div>
                        <div className="details-row">
                          <TextField
                            type="tel"
                            name="phone"
                            onChange={(e) => {
                              handleInputChange(e, this);
                            }}
                            value={this.state.phone}
                            label="Phone"
                          />
                        </div>
                      </div>
                      <div className="details-row">
                        <TextField
                          type="text"
                          name="first_name"
                          required
                          onChange={(e) => {
                            handleInputChange(e, this);
                          }}
                          value={this.state.first_name}
                          label="First Name"
                        />
                      </div>
                      <div className="details-row">
                        <TextField
                          type="text"
                          name="last_name"
                          required
                          onChange={(e) => {
                            handleInputChange(e, this);
                          }}
                          value={this.state.last_name}
                          label="Last Name"
                        />
                      </div>
                      {!this.state.isRegisterOnly && <h2>Payment Information</h2>}

                      {this.state.owner.ownerType !== uiConstants.ownerType.hub &&
                        !this.state.isRegisterOnly &&
                        (ownerObject as IGroup)?.allow_invoicing && (
                          <div
                            onClick={() => {
                              this.setState({
                                paymentMethod:
                                  this.state.paymentMethod === null
                                    ? Constants.payment_method.invoice
                                    : null,
                              });
                            }}
                            className="invoice-payment"
                          >
                            <i
                              className={
                                this.state.paymentMethod === Constants.payment_method.invoice
                                  ? 'fas fa-circle'
                                  : 'far fa-circle'
                              }
                            />
                            <span>Request an invoice and pay later</span>
                          </div>
                        )}
                      {this.state.paymentMethod !== Constants.payment_method.invoice && (
                        <div>
                          {/* We have to display the Credit card payment method selection option here.
                                                This is because "Name of Card" field sits outside `CreditCard` (Where the other alternative payment options live). */}
                          {!this.state.isRegisterOnly && (
                            <PaymentMethodOption
                              item="card"
                              index={-1}
                              selectedAltMethod={this.state.selectedAltMethod}
                              setSelectedAltMethod={(index) =>
                                this.setState({ selectedAltMethod: index })
                              }
                            />
                          )}

                          {!this.state.isRegisterOnly && this.state.selectedAltMethod === -1 && (
                            <div className="details-row">
                              <TextField
                                type="text"
                                name="cardName"
                                required
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                value={this.state.cardName}
                                label="Name on Card"
                              />
                            </div>
                          )}
                          <div className="details-row">
                            {!this.state.isRegisterOnly &&
                              this.state.stripePromiseLoaded &&
                              this.state.stripePromise !== null &&
                              (this.props.cartState.parentType === 'group' ||
                                this.props.cartState.parentType === Constants.object_type.hub) && (
                                <Elements stripe={this.state.stripePromise}>
                                  <ElementsConsumer>
                                    {({ elements, stripe }) => (
                                      <CreditCard
                                        billingDetails={this.getBillingDetails()}
                                        elements={elements}
                                        stripe={stripe}
                                        resetPaymentMethodTry={() => {
                                          this.setState({ tryCreatePaymentMethod: false });
                                        }}
                                        tryCreatePaymentMethod={this.state.tryCreatePaymentMethod}
                                        onSuccessfulPaymentMethod={this.onSuccessfulPaymentIntent}
                                        onFailedPaymentMethod={this.onFailedPaymentIntent}
                                        trySubmitPayment={this.state.trySubmitPayment}
                                        resetSubmitPayment={() => {
                                          this.setState({ trySubmitPayment: false });
                                        }}
                                        onFailedSubmitPayment={this.onFailedSubmitPayment}
                                        onSuccessfulSubmitPayment={this.onSuccessfulSubmitPayment}
                                        onSubmitSubscription={this.onSubmitSubscription}
                                        paymentIntent={this.state.paymentIntent}
                                        // Alternative payment method props
                                        paymentRequest={this.state.paymentRequest}
                                        setPaymentRequest={(pr) =>
                                          this.setState({ paymentRequest: pr })
                                        }
                                        selectedAltMethod={this.state.selectedAltMethod}
                                        setSelectedAltMethod={(index) =>
                                          this.setState({ selectedAltMethod: index })
                                        }
                                        options={{ hidePostalCode: true }}
                                        ownerType={
                                          this.props.adminParentType ||
                                          this.props.cartState.parentType
                                        }
                                        {...this.props}
                                        type="purchase"
                                      />
                                    )}
                                  </ElementsConsumer>
                                </Elements>
                              )}
                            {/* TODO: Remove this? It seems like a copy paste of the "group" logic above */}
                            {!this.state.isRegisterOnly &&
                              this.state.stripePromiseLoaded &&
                              this.props.cartState.parentType === 'event' &&
                              this.state.stripePromise !== null && (
                                <Elements stripe={this.state.stripePromise}>
                                  <ElementsConsumer>
                                    {({ elements, stripe }) => (
                                      <CreditCard
                                        billingDetails={this.getBillingDetails()}
                                        elements={elements}
                                        stripe={stripe}
                                        resetPaymentMethodTry={() => {
                                          this.setState({ tryCreatePaymentMethod: false });
                                        }}
                                        tryCreatePaymentMethod={this.state.tryCreatePaymentMethod}
                                        onSuccessfulPaymentMethod={this.onSuccessfulPaymentIntent}
                                        onFailedPaymentMethod={this.onFailedPaymentIntent}
                                        trySubmitPayment={this.state.trySubmitPayment}
                                        resetSubmitPayment={() => {
                                          this.setState({ trySubmitPayment: false });
                                        }}
                                        onFailedSubmitPayment={this.onFailedSubmitPayment}
                                        onSuccessfulSubmitPayment={this.onSuccessfulSubmitPayment}
                                        onSubmitSubscription={this.onSubmitSubscription}
                                        // Alternative payment method props
                                        paymentRequest={this.state.paymentRequest}
                                        setPaymentRequest={(pr) =>
                                          this.setState({ paymentRequest: pr })
                                        }
                                        selectedAltMethod={this.state.selectedAltMethod}
                                        setSelectedAltMethod={(index) =>
                                          this.setState({ selectedAltMethod: index })
                                        }
                                        options={{ hidePostalCode: true }}
                                        ownerType={
                                          this.props.adminParentType ||
                                          this.props.cartState.parentType
                                        }
                                        {...this.props}
                                        type="purchase"
                                      />
                                    )}
                                  </ElementsConsumer>
                                </Elements>
                              )}
                          </div>
                        </div>
                      )}
                      {/* TODO: Not used? should be deleted */}
                      {this.state.showTaxForm && (
                        <div>
                          <h2>Tax Receipt Information</h2>
                          <div className="details-row">
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="streetAddress"
                                required
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                value={this.state.streetAddress}
                                label="Street Address"
                              />
                            </div>
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="aptNum"
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                value={this.state.aptNum}
                                label="Apartment / Suite #"
                              />
                            </div>
                          </div>
                          <div className="details-row">
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="city"
                                required
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                value={this.state.city}
                                label="City"
                              />
                            </div>
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="province"
                                required
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                value={this.state.province}
                                label="Province / State"
                              />
                            </div>
                          </div>
                          <div className="details-row">
                            <div className="details-col">
                              <Dropdown
                                value={this.state.country}
                                shouldSort={true}
                                label="Country"
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                name="country"
                                options={[
                                  { label: 'Canada', value: 'Canada' },
                                  { label: 'United States of America', value: 'USA' },
                                ]}
                              />
                            </div>
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="postal"
                                required
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                value={this.state.postal}
                                label="Postal Code / ZIP"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {this.props.cartState.total.amount ? (
                        <div>
                          <h2>
                            {this.state.isRegisterOnly
                              ? 'Address Information'
                              : 'Billing Information'}
                          </h2>
                          <div className="details-row">
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="billingStreetAddress"
                                required
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                value={this.state.billingStreetAddress}
                                label="Street Address"
                              />
                            </div>
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="billingAptNum"
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                value={this.state.billingAptNum}
                                label="Apartment / Suite #"
                              />
                            </div>
                          </div>
                          <div className="details-row">
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="billingCity"
                                required
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                value={this.state.billingCity}
                                label="City"
                              />
                            </div>
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="billingProvince"
                                required
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                value={this.state.billingProvince}
                                label="Province / State"
                              />
                            </div>
                          </div>
                          <div className="details-row">
                            <div className="details-col">
                              <Dropdown
                                shouldSort={true}
                                value={this.state.billingCountry}
                                label="Country"
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                name="billingCountry"
                                options={[
                                  { label: 'Canada', value: 'Canada' },
                                  { label: 'United States of America', value: 'USA' },
                                ]}
                              />
                            </div>
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="billingPostal"
                                required
                                onChange={(e) => {
                                  handleInputChange(e, this);
                                }}
                                value={this.state.billingPostal}
                                label="Postal Code / ZIP"
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.isShippingItem() && (
                        <div>
                          <h2>Shipping Information</h2>
                          {!this.state.isRegisterOnly && (
                            <div
                              onClick={() => {
                                this.setState({ sameAsBilling: !this.state.sameAsBilling });
                              }}
                              className="same-as-billing"
                            >
                              <i
                                className={
                                  this.state.sameAsBilling ? 'fas fa-circle' : 'far fa-circle'
                                }
                              />
                              <span>Same as billing address</span>
                            </div>
                          )}
                          {!this.state.sameAsBilling && (
                            <div>
                              <div className="details-row">
                                <div className="details-col">
                                  <TextField
                                    type="text"
                                    name="shippingStreetAddress"
                                    required
                                    onChange={(e) => {
                                      handleInputChange(e, this);
                                    }}
                                    value={this.state.shippingStreetAddress}
                                    label="Street Address"
                                  />
                                </div>
                                <div className="details-col">
                                  <TextField
                                    type="text"
                                    name="shippingAptNum"
                                    onChange={(e) => {
                                      handleInputChange(e, this);
                                    }}
                                    value={this.state.shippingAptNum}
                                    label="Apartment / Suite #"
                                  />
                                </div>
                              </div>
                              <div className="details-row">
                                <div className="details-col">
                                  <TextField
                                    type="text"
                                    name="shippingCity"
                                    required
                                    onChange={(e) => {
                                      handleInputChange(e, this);
                                    }}
                                    value={this.state.shippingCity}
                                    label="City"
                                  />
                                </div>
                                <div className="details-col">
                                  <TextField
                                    type="text"
                                    name="shippingProvince"
                                    required
                                    onChange={(e) => {
                                      handleInputChange(e, this);
                                    }}
                                    value={this.state.shippingProvince}
                                    label="Province / State"
                                  />
                                </div>
                              </div>
                              <div className="details-row">
                                <div className="details-col">
                                  <Dropdown
                                    shouldSort={true}
                                    value={this.state.shippingCountry}
                                    label="Country"
                                    onChange={(e) => {
                                      handleInputChange(e, this);
                                    }}
                                    name="shippingCountry"
                                    options={[
                                      { label: 'Canada', value: 'Canada' },
                                      { label: 'United States of America', value: 'USA' },
                                    ]}
                                  />
                                </div>
                                <div className="details-col">
                                  <TextField
                                    type="text"
                                    name="shippingPostal"
                                    required
                                    onChange={(e) => {
                                      handleInputChange(e, this);
                                    }}
                                    value={this.state.shippingPostal}
                                    label="Postal Code / ZIP"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      <div className="checkout-actions">
                        <Button
                          onClick={() => {
                            this.setState({ showCheckout: false });
                          }}
                          text="Back to Cart"
                        />
                        {this.state.paymentRequest &&
                        this.state.selectedAltMethod !== -1 &&
                        !this.state.isRegisterOnly ? (
                          <Elements stripe={this.state.stripePromise}>
                            <ElementsConsumer>
                              {({ elements, stripe }) => (
                                <PaymentRequestButtonElement
                                  onClick={(e) => this.validateCheckout(e)}
                                  className="Button"
                                  options={{
                                    style: { paymentRequestButton: { type: 'buy' } },
                                    paymentRequest: this.state.paymentRequest!,
                                  }}
                                />
                              )}
                            </ElementsConsumer>
                          </Elements>
                        ) : (
                          <Button
                            loading={this.state.paymentMethodLoading}
                            isDisabled={
                              this.state.first_name === '' ||
                              this.state.last_name === '' ||
                              this.state.email === '' ||
                              this.state.paymentMethodLoading
                            }
                            text="Confirm Order"
                          />
                        )}
                      </div>
                    </form>
                    {this.state.paymentMethodLoading && (
                      <div className="payment-loading">
                        <Loader loading={this.state.paymentMethodLoading} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {Object.keys(this.props.cartState.total).length && (
              <div className="payment-summary">
                <div
                  ref={this.state.sidebarRef}
                  className={'summary-inner ' + this.state.sidebarClass}
                >
                  <h2>Order Summary</h2>
                  <div className="subtotal">
                    <span>Subtotal</span>
                    <span notranslate="yes">
                      {formatCurrency(this.getSubTotal(), currency, this.props.locale)}
                    </span>
                  </div>
                  <div className="subtotal">
                    <span>Shipping</span>
                    <span notranslate="yes">
                      {formatCurrency(
                        this.props.cartState.total.shipping || 0,
                        currency,
                        this.props.locale,
                      )}
                    </span>
                  </div>
                  <div className="discounts">
                    <span>Discounts</span>
                    <span notranslate="yes">
                      {formatCurrency(
                        this.props.cartState.total.discount,
                        currency,
                        this.props.locale,
                      )}
                    </span>
                  </div>
                  {this.state.donationAmount && (
                    <div className="donation">
                      <span>Donation</span>
                      <span>
                        {formatCurrency(
                          parseFloat(this.state.donationAmount),
                          currency,
                          this.props.locale,
                        )}
                      </span>
                    </div>
                  )}
                  {ownerObject?.fee_control && !ownerObject.fee_control.covers_gigit_fees && (
                    <div className="tax">
                      <span>Platform Fees</span>
                      <span notranslate="yes">
                        {this.state.paymentMethod === Constants.payment_method.invoice
                          ? formatCurrency(0, currency, this.props.locale)
                          : formatCurrency(
                              this.props.cartState.total.gigit_fee,
                              currency,
                              this.props.locale,
                            )}
                      </span>
                    </div>
                  )}
                  {ownerObject?.fee_control && !ownerObject.fee_control.covers_processing_fees && (
                    <div className="tax">
                      <span>Processing Fees</span>
                      <span notranslate="yes">
                        {this.state.paymentMethod === Constants.payment_method.invoice
                          ? formatCurrency(0, currency, this.props.locale)
                          : formatCurrency(
                              this.props.cartState.total.payment_platform_fee,
                              currency,
                              this.props.locale,
                            )}
                      </span>
                    </div>
                  )}
                  <div className="total">
                    <span>Total</span>
                    <span notranslate="yes">
                      {this.state.paymentMethod === Constants.payment_method.invoice ? (
                        formatCurrency(
                          this.getSubTotal() + (this.props.cartState.total.shipping || 0),
                          currency,
                          this.props.locale,
                        )
                      ) : (
                        <span>
                          {formatCurrency(
                            this.props.cartState.total.total,
                            currency,
                            this.props.locale,
                          )}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="discount-codes">
                    <span className="discount-label">Discount Codes</span>
                    <div
                      className={
                        this.state.discountCode !== ''
                          ? this.props.cartState.total.discount > 0
                            ? 'discount-wrap valid'
                            : 'discount-wrap invalid'
                          : 'discount-wrap'
                      }
                    >
                      <TextField
                        value={this.state.discountCode}
                        name="discountCode"
                        type="text"
                        onChange={(e) => {
                          handleInputChange(e, this, false, this.checkDiscountCode);
                        }}
                      />
                      <Button
                        isDisabled={true}
                        text=""
                        icon={
                          this.state.discountCode !== ''
                            ? this.props.cartState.total.discount > 0
                              ? 'far fa-check'
                              : 'far fa-times'
                            : 'fas fa-search'
                        }
                      />
                    </div>

                    {this.state.owner.ownerType !== uiConstants.ownerType.hub &&
                      (ownerObject as IGroup)?.allow_checkout_donations && (
                        <React.Fragment>
                          <span className="donation-amount discount-label">Make a donation</span>
                          <TextField
                            value={this.state.donationAmount}
                            name="donationAmount"
                            type="number"
                            min={'0'}
                            onChange={(e) => {
                              handleInputChange(e, this, false, this.checkDiscountCode);
                            }}
                          />
                        </React.Fragment>
                      )}

                    {Number(this.state.donationAmount) > 0 && (
                      <div className="anonymous-donation-container">
                        <Checkbox
                          name="anonymous_donation"
                          label="Anonymous donation"
                          value="anonymous_donation_value"
                          checked={this.state.donationAnonymous}
                          onChange={() => {
                            this.setState({ donationAnonymous: !this.state.donationAnonymous });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {this.state.showAdminStore && (
                    <div className="checkout-actions">
                      <Button
                        isDisabled={this.props.cartState.items.length < 1}
                        onClick={() => {
                          this.setState({ showAdminStore: false });
                        }}
                        text="Continue"
                      />
                    </div>
                  )}
                  {!this.state.showCheckout && !this.state.showAdminStore && (
                    <div
                      className={
                        this.props.adminPurchase ? 'checkout-actions' : 'checkout-actions multi'
                      }
                    >
                      {this.state.showCheckoutButton && (
                        <Button
                          isDisabled={this.props.cartState.items.length < 1}
                          onClick={() => this.showCheckout()}
                          text="Checkout"
                        />
                      )}
                      {!this.props.adminPurchase && this.props.cartState.items.length > 0 && (
                        <Link
                          to={
                            '/' +
                            this.props.cartState.items[0].owner_type +
                            '/' +
                            this.state.owner.ownerHandle
                          }
                        >
                          Continue Shopping
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    cartState: store.cartState,
    groupState: store.groupState,
    eventState: store.eventState,
    storeState: store.storeState,
    registerState: store.registerState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  calculateTotal,
  clearCart,
  purchaseGroupItems,
  purchaseEventItems,
  removeItem,
  createToast,
  updateQuantity,
  addItem,
  getStoreItems,
  purchaseFundraise,
  setFlow,
  resetFlowState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
