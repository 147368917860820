import React from 'react';
import { History } from 'history';
import moment, { Moment } from 'moment';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  handleInputChange,
  swapRouteParams,
  routes,
  toastError,
  toastInfo,
  defaultCurrency,
  capitalizeString,
  errorHelpers,
  toastSuccess,
} from '../../helpers';
import Axios from 'axios';
import { IAppState } from '../../store';
import { IGroupState } from '../../reducers/group';
import { updateGroup, setCampaignConnect, getGroupReceiptNumberPreview } from '../../actions/group';
import { createToast } from '../../actions/toaster';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import DatePicker from '../DatePicker/DatePicker';
import ContextMenu from '../ContextMenu/ContextMenu';
import './CampaignManagement.scss';
import { ICampaign, ICampaignFund, IGroup } from '@gigit/interfaces';
import Loader from '../Loader/Loader';
import { formatCurrency } from '../../helpers';
import { Tooltip } from '../shared/Tooltip/Tooltip';
import { IToast, IOwnerObject } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import { campaignRequestActions } from '../../requestActions';
import { Constants } from '@gigit/constants';

interface IProps extends WithTranslation {
  owner: IOwnerObject;
  history: History;
  groupState: IGroupState;
  locale: string;
  updateGroup(payload: any, groupId: string, page?: any): void;
  setCampaignConnect(groupId: string, payload: any): void;
  getGroupReceiptNumberPreview(
    groupId: string,
    prefix: string | undefined,
    padding: number | undefined,
    value: number,
  ): void;
  getGroup(handle: string): void;
  createToast(toast: IToast): void;
}

interface IState {
  searchValue: string;
  showCreate: boolean;
  default: string;
  campaignTitle: string;
  selectedCampaign: string;
  campaignStatuses: Array<any>;
  fund: string;
  goal: number;
  funds: ICampaignFund[];
  showActions: string;
  startDate: Moment;
  endDate: Moment;
  activeStatus: string;
  currentCampaign: ICampaign | null;
  prefix: string;
  padding: string;
  startingNumber: string;
  campaigns: ICampaign[];
  invalidPrefix: boolean;
  hasBeenUsed: boolean;
}

class CampaignManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hasBeenUsed: false,
      invalidPrefix: false,
      campaigns: [],
      searchValue: '',
      showCreate: false,
      default: '',
      campaignTitle: '',
      selectedCampaign: '',
      goal: 0,
      campaignStatuses: [
        { value: 'active', label: 'Active' },
        { value: 'closed', label: 'Closed' },
        { value: 'draft', label: 'Draft' },
      ], //value should be id once hooked up
      fund: '',
      funds: [],
      showActions: '',
      startDate: moment(),
      endDate: moment(),
      activeStatus: Constants.campaign_status.active,
      currentCampaign: null,
      prefix: '',
      padding: '',
      startingNumber: '',
    };
  }

  componentDidMount() {
    this.getCampaigns();
  }

  async getCampaigns(): Promise<void> {
    try {
      const campaignsData = await campaignRequestActions.getCampaigns(
        this.props.groupState.group.id,
      );
      this.setState({ campaigns: campaignsData });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Get Campaigns');
      this.props.createToast(toast);
    }
  }

  toggleCampaignModal(value: boolean) {
    this.setState({
      showCreate: value,
    });

    if (!value) {
      this.setState({
        campaignTitle: '',
        goal: 0,
        activeStatus: Constants.campaign_status.active,
        currentCampaign: null,
        fund: '',
        funds: [],
        startDate: moment(),
        endDate: moment(),
        prefix: '',
        padding: '',
        startingNumber: '',
        hasBeenUsed: false,
        invalidPrefix: false,
      });
    } else {
      this.getReceiptNumberPreview();
    }
  }

  async makeDefault(c: ICampaign) {
    try {
      this.props.updateGroup({ campaign_id: c.id }, this.props.groupState.group.id);
      const toast = toastSuccess(
        localizeHelpers.translate(`${c.title} set as default campaign`),
        'Set Default Campaign',
      );
      this.props.createToast(toast);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObj.translatedMessage, 'Set Default Campaign');
      this.props.createToast(toast);
    }
  }

  async saveCampaign() {
    if (this.state.startDate.isAfter(this.state.endDate)) {
      const toast = toastError(
        localizeHelpers.translate('Start date cannot be greater than end date'),
        'Create Campaign',
      );
      this.props.createToast(toast);
      return;
    }

    let payload = {
      groupId: this.props.groupState.group.id,
      title: this.state.campaignTitle,
      status: { code: this.state.activeStatus },
      goal: this.state.goal,
      funds: this.state.funds,
      start_date: this.state.startDate.toDate(),
      end_date: this.state.endDate.toDate(),
    } as ICampaign;

    if (this.state.prefix !== '') {
      payload.receipt_number_prefix = this.state.prefix;
    }

    if (this.state.padding !== '') {
      payload.receipt_number_length = parseInt(this.state.padding);
    }

    if (this.state.currentCampaign?.id) {
      try {
        // UPDATE
        await campaignRequestActions.updateCampaign(
          this.props.groupState.group.id,
          this.state.currentCampaign.id,
          payload,
        );
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Campaign');
        this.props.createToast(toast);

        if (this.state.currentCampaign && this.state.currentCampaign?.id) {
          try {
            const data = await campaignRequestActions.getCurrentReceiptNumber(
              this.props.groupState.group.id,
              this.state.currentCampaign.id,
            );
            this.setState({
              prefix: this.state.currentCampaign?.receipt_number_prefix?.toString() || '',
              padding: this.state.currentCampaign?.receipt_number_length?.toString() || '0',
              startingNumber: data.toString(),
              hasBeenUsed: errorObj.errorCode === 'ERROR.CAMPAIGNS.HAS_BEEN_USED',
            });
          } catch (error) {
            const errorObj = errorHelpers.getErrorObject(error);
            let toast = toastError(errorObj.translatedMessage, 'Get Current Receipt Number');
            this.props.createToast(toast);
          }
        }
      } finally {
        await this.saveReceiptStartNumber(
          this.state.currentCampaign?.id,
          parseInt(this.state.startingNumber),
        );
        this.setState({ currentCampaign: null });
      }

      // Set accepting donations flag on Group to false if current campaign is set to in-active
      if (
        this.state.activeStatus !== Constants.campaign_status.active &&
        this.props.groupState.group.campaign_id === this.state.currentCampaign.id &&
        this.props.groupState.group.accepting_donations
      ) {
        const toast = toastInfo(
          localizeHelpers.translate(
            'This campaign is not connected for payments. Donations will be disabled until an account is connected.',
          ),
          'Campaigns',
        );
        this.props.createToast(toast);
        this.props.updateGroup({ accepting_donations: false }, this.props.groupState.group.id);
      }
    } else {
      // CREATE
      try {
        const campaign = await campaignRequestActions.createCampaign(
          this.props.groupState.group.id,
          payload,
        );
        await this.saveReceiptStartNumber(campaign.id!, parseInt(this.state.startingNumber));
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Campaigns');
        this.props.createToast(toast);
      }
    }

    await this.getCampaigns();
    this.toggleCampaignModal(false);
  }

  saveReceiptStartNumber = async (campaignId: string, value: number) => {
    try {
      await campaignRequestActions.updateReceiptNumber(
        this.props.groupState.group.id,
        campaignId,
        value,
      );
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Receipt Number');
      this.props.createToast(toast);
    }
  };

  getFunds(campaign: ICampaign) {
    return campaign.funds
      .map((c) => {
        return c.name;
      })
      .join(', ');
  }

  addFund() {
    let temp = this.state.funds;
    temp.push({ name: this.state.fund });
    this.setState({
      funds: temp,
      fund: '',
    });
  }

  removeFund(i: number) {
    let temp = [...this.state.funds];
    temp.splice(i, 1);
    this.setState({
      funds: temp,
    });
  }

  getPercentage(raised: number, goal: number) {
    if (raised && goal) {
      return ((raised * 100) / goal).toFixed(2);
    } else {
      return '0';
    }
  }

  showActions(id: string) {
    if (id && this.state.showActions === id) {
      this.setState({
        showActions: '',
      });
    } else {
      this.setState({
        showActions: id,
      });
    }
  }

  isDefaultCampaign(campaign: ICampaign) {
    return this.props.groupState.group.campaign_id === campaign.id ? 'is-default' : '';
  }

  async closeCampaign(campaignId: string) {
    try {
      await campaignRequestActions.closeGroupCampaign(this.props.groupState.group.id, campaignId);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Close Campaign');
      this.props.createToast(toast);
    }

    await this.getCampaigns();
  }

  isActiveStatus(status: string) {
    return status === this.state.activeStatus ? 'status chosen' : 'status';
  }

  setCampaignStatus(status: string) {
    this.setState({
      activeStatus: status,
    });
  }

  async checkIsPrefixUsed(): Promise<void> {
    if (this.state.prefix !== this.state?.currentCampaign?.receipt_number_prefix) {
      if (this.state.prefix?.length > 0) {
        try {
          const isPrefixUsedData = await campaignRequestActions.isPrefixUsed(
            this.props.groupState.group.id,
            this.state.prefix,
          );
          this.setState({ invalidPrefix: isPrefixUsedData });
        } catch (error) {
          const errorObj = errorHelpers.getErrorObject(error);
          let toast = toastError(errorObj.translatedMessage, 'Is Prefix Used');
          this.props.createToast(toast);
        }
      } else {
        this.setState({
          prefix: '',
          invalidPrefix: false,
        });
      }
    }
  }

  async editCampaign(c: any): Promise<void> {
    this.setState({
      campaignTitle: c.title,
      goal: c.goal,
      activeStatus: c.status.code,
      currentCampaign: c,
      funds: c.funds,
      prefix: c.receipt_number_prefix,
      padding: c.receipt_number_length,
      startDate: moment(c.start_date),
      endDate: moment(c.end_date),
    });

    try {
      const currentReceiptNumber = await campaignRequestActions.getCurrentReceiptNumber(
        this.props.groupState.group.id,
        c.id,
      );
      if (currentReceiptNumber.value !== null) {
        this.setState({ startingNumber: currentReceiptNumber.value.toString() });
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Get Current Receipt Number');
      this.props.createToast(toast);
    }

    try {
      const hasBeenUsed = await campaignRequestActions.receiptNumberHasBeenUsed(
        this.props.groupState.group.id,
        c.id,
      );
      this.setState({ hasBeenUsed });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Receipt Number Has Been Used');
      this.props.createToast(toast);
    } finally {
      this.toggleCampaignModal(true);
    }
  }

  getReceiptNumberPreview = () => {
    this.props.getGroupReceiptNumberPreview(
      this.props.groupState.group.id,
      this.state.prefix,
      Number(this.state.padding),
      Number(this.state.startingNumber),
    );
  };

  render() {
    let noSearchResults = true;

    return (
      <div className="CampaignManagement section-wrap">
        <div className="section-title">
          <div className="forms-title">Campaigns</div>
          <div className="forms-create">
            <div className="form-buttons">
              <Button
                icon="fal fa-plus"
                text="New Campaign"
                onClick={() => {
                  this.toggleCampaignModal(true);
                }}
              />
            </div>
          </div>
        </div>

        <div className="section-inner">
          <div className="CampaignManagement-list">
            <div className="search">
              <TextField
                icon="fas fa-search"
                placeholder="Search campaigns..."
                value={this.state.searchValue}
                type="text"
                name="searchValue"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="list">
              <div className="headers">
                <div className="col default" />
                <div className="col title">Name</div>
                <div className="col status">Status</div>
                <div className="col goal">Goal</div>
                <div className="col raised">Amount Raised</div>
                <div className="col progress">Progress</div>
                <div className="col progress">Currency</div>
                <div className="col actions" />
              </div>
              <div className="list-inner">
                <div className="list-rows">
                  {this.state.campaigns &&
                    this.state.campaigns?.length > 0 &&
                    this.state.campaigns.map((campaign, index) => {
                      if (
                        (this.state.searchValue.toLowerCase() !== '' &&
                          campaign.title
                            .toLowerCase()
                            .includes(this.state.searchValue.toLowerCase())) ||
                        this.state.searchValue === ''
                      ) {
                        noSearchResults = false;
                        let _menuItems = [
                          {
                            onClick: async () => {
                              await this.editCampaign(campaign);
                            },
                            icon: 'fas fa-pencil',
                            label: 'Edit',
                          },
                          {
                            onClick: async () => {
                              if (campaign?.id) {
                                await this.closeCampaign(campaign.id);
                              }
                            },
                            icon: 'fa fa-times',
                            label: 'Close',
                          },
                        ];

                        return (
                          <div
                            key={index}
                            className="row"
                          >
                            <div className="col default">
                              <i
                                onClick={() => {
                                  this.makeDefault(campaign);
                                }}
                                title="Set as active campaign"
                                className={'fas fa-circle ' + this.isDefaultCampaign(campaign)}
                              />
                            </div>
                            <div
                              className="col title"
                              notranslate="yes"
                            >
                              {campaign.title}
                            </div>
                            <div className="col status">
                              <span className={campaign.status.code}>
                                {capitalizeString(campaign.status.code)}
                              </span>
                            </div>
                            <div
                              className="col goal"
                              notranslate="yes"
                            >
                              {formatCurrency(
                                campaign.goal || 0,
                                this.props.groupState.group.account?.currency ?? defaultCurrency,
                                this.props.locale,
                              )}
                            </div>
                            <div
                              className="col raised"
                              notranslate="yes"
                            >
                              {formatCurrency(
                                campaign.raised || 0,
                                this.props.groupState.group.account?.currency ?? defaultCurrency,
                                this.props.locale,
                              )}
                            </div>
                            <div
                              className="col progress"
                              notranslate="yes"
                            >
                              {this.getPercentage(campaign.raised || 0, campaign.goal || 0)}%
                            </div>
                            <div
                              className="col progress"
                              notranslate="yes"
                            >
                              {this.props.groupState.group.account?.currency?.toUpperCase()}
                            </div>
                            <div className="col actions">
                              <i className="fal fa-ellipsis-h-alt" />
                              <ContextMenu
                                onMouseLeave={() => {}}
                                showMenu={true}
                                menuItems={_menuItems}
                              />
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  {this.props.groupState.groupCampaigns.length === 0 && (
                    <div className="empty">You haven't created any campaigns yet.</div>
                  )}
                  {this.props.groupState.groupCampaigns.length !== 0 && noSearchResults && (
                    <div className="empty">Your search returned 0 results.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showCreate}
          onClose={() => this.toggleCampaignModal(false)}
          title={this.state.currentCampaign?.id ? 'Edit Campaign' : 'Create Campaign'}
        >
          <div className="CreateCampaign">
            <div className="modal-title-row">Campaign Information</div>
            <div className="form">
              <div className="form-field title-amt">
                <TextField
                  label={'Campaign Title'}
                  value={this.state.campaignTitle}
                  name="campaignTitle"
                  type="text"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
                <TextField
                  icon="fa fa-dollar"
                  required={true}
                  label={'Fundraising Goal'}
                  value={this.state.goal}
                  name="goal"
                  type="number"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
              </div>
              <div className="form-field">
                <div className="start-date">
                  <DatePicker
                    name="startDate"
                    label="Start Date"
                    error={this.state.startDate.isAfter(this.state.endDate)}
                    onChange={(date) => {
                      this.setState({ startDate: moment(date).second(0).millisecond(0) });
                    }}
                    date={this.state.startDate}
                  />
                </div>
              </div>
              <div className="form-field">
                <div className="end-date">
                  <DatePicker
                    name="endDate"
                    label="End Date"
                    error={this.state.startDate.isAfter(this.state.endDate)}
                    onChange={(date) => {
                      this.setState({ endDate: moment(date).second(0).millisecond(0) });
                    }}
                    date={this.state.endDate}
                  />
                </div>
              </div>
              <div className="form-field status">
                <label>Status</label>
                <div className="status-section">
                  <div
                    className={this.isActiveStatus(Constants.campaign_status.active)}
                    onClick={() => this.setCampaignStatus(Constants.campaign_status.active)}
                  >
                    Active
                  </div>
                  <div
                    className={this.isActiveStatus(Constants.campaign_status.draft)}
                    onClick={() => this.setCampaignStatus(Constants.campaign_status.draft)}
                  >
                    Draft
                  </div>
                  <div
                    className={this.isActiveStatus(Constants.campaign_status.closed)}
                    onClick={() => this.setCampaignStatus(Constants.campaign_status.closed)}
                  >
                    Closed
                  </div>
                </div>
              </div>
              <form
                className="funds-row form-field"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.addFund();
                }}
              >
                <TextField
                  required={true}
                  label={'Add a Fund'}
                  value={this.state.fund}
                  name="fund"
                  type="text"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
                <Button
                  icon="fal fa-plus"
                  isDisabled={this.state.fund === ''}
                  text="Add Fund"
                />
              </form>
              <div className="form-field selected-funds">
                {this.state.funds && this.state.funds.length > 0 && (
                  <div className="funds-subheader">Added Funds:</div>
                )}
                <div className="funds">
                  {this.state.funds &&
                    this.state.funds.map((f, i) => {
                      return (
                        <div
                          key={i}
                          className="fund"
                        >
                          <span>{f.name}</span>
                          <i
                            onClick={() => this.removeFund(i)}
                            className="fa fa-times remove-fund"
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="modal-title-row">Receipt Settings</div>
            <div className="form">
              <div className="prefix">
                <div className="title">
                  <span>Prefix, Padding & Starting Number</span>

                  <Tooltip
                    className="CampaignManagement-ReceiptPreviewTooltip"
                    direction="bottom"
                    renderTooltip={() => {
                      return (
                        <React.Fragment>
                          <div className="hint-title">Will appear on statement as:</div>
                          <div className="hint-example">
                            <div className="prefix">ABC</div>
                            <div className="padding">00000</div>
                            <div className="starting">168</div>
                          </div>
                          <div className="hint-example labels">
                            <div className="prefix">Prefix</div>
                            <div className="padding">Padding</div>
                            <div className="starting">Starting Number</div>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  >
                    {(ref) => (
                      <i
                        ref={ref}
                        className="fas fa-question-circle"
                      />
                    )}
                  </Tooltip>
                </div>
                <div className="sub">
                  Define a custom prefix, padding and starting number for your receipts.
                </div>
                <div className="prefix-input">
                  <TextField
                    error={this.state.invalidPrefix ? 'Already in use' : undefined}
                    label={'Prefix'}
                    value={this.state.prefix}
                    name="prefix"
                    type="text"
                    disabled={this.state.hasBeenUsed}
                    onChange={(e) => {
                      handleInputChange(e, this, undefined, async () => {
                        await this.checkIsPrefixUsed();
                        this.getReceiptNumberPreview();
                      });
                    }}
                  />
                  <TextField
                    label={'Padding'}
                    value={this.state.padding}
                    name="padding"
                    type="number"
                    disabled={this.state.hasBeenUsed}
                    onChange={(e) => {
                      handleInputChange(e, this, undefined, this.getReceiptNumberPreview);
                    }}
                  />
                  <TextField
                    label={'Starting Number'}
                    error={
                      +this.state.startingNumber < 0
                        ? localizeHelpers.translate(
                            'Starting Number must be greater than or equal to {{min}}',
                            { min: 0 },
                          )
                        : undefined
                    }
                    value={this.state.startingNumber}
                    name="startingNumber"
                    type="number"
                    disabled={this.state.hasBeenUsed}
                    onChange={(e) => {
                      handleInputChange(e, this, undefined, this.getReceiptNumberPreview);
                    }}
                  />
                </div>

                {this.props.groupState.receiptNumberPreviewIsLoading ? (
                  <Loader loading={true} />
                ) : (
                  <div className="current">
                    Current:
                    <var data-var="current_receipt_number">
                      {this.props.groupState.receiptNumberPreview}
                    </var>
                  </div>
                )}

                {this.state.currentCampaign?.id && this.state.prefix?.length < 1 && (
                  <div className="empty-prefix-err">
                    <i className="far fa-exclamation-triangle" />
                    <span>The prefix must be filled</span>
                  </div>
                )}
              </div>
              <div className="form-field actions">
                <Button
                  isDisabled={Boolean(
                    +this.state.startingNumber < 0 ||
                      this.state.invalidPrefix ||
                      (this.state.currentCampaign?.id && this.state.prefix?.length < 1),
                  )}
                  icon={`fal ${this.state.currentCampaign?.id ? 'fa-pencil' : 'fa-plus'}`}
                  text={this.state.currentCampaign?.id ? 'Update' : 'Create'}
                  onClick={() => this.saveCampaign()}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  setCampaignConnect,
  updateGroup,
  getGroupReceiptNumberPreview,
  createToast,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(CampaignManagement),
);
