import { useState } from 'react';
import { IPaymentInfo } from '../interfaces';

interface IUsePaymentInfo {
  paymentInfo: IPaymentInfo;
  handlePaymentInfo(update: Partial<IPaymentInfo>): void;
}

export function usePaymnetInfo<T>(): IUsePaymentInfo {
  const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo>({
    paymentMethod: null,
    isAltMethod: false,
    isNewMethod: false,
    address: '',
    apartment: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    stripe: null,
    customer: null,
  });

  function handlePaymentInfo(update: IPaymentInfo) {
    setPaymentInfo(update);
  }
  return { paymentInfo, handlePaymentInfo };
}
