import { Config } from '@gigit/config';
import { IAddressBase } from '@gigit/interfaces';

/** A geocoding API result. */
interface IGeocodeResult {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    location_type: string;
    viewport: {
      northeast: {
        lat: number;
        lng: number;
      };
      southwest: {
        lat: number;
        lng: number;
      };
    };
  };
  place_id: string;
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  types: string[];
}

/** Response that's returned from Googles Geocoding API (See: https://developers.google.com/maps/documentation/geocoding/overview) */
export interface IGeocodeResponse {
  results: IGeocodeResult[];
  status: string;
}

export const locationHelpers = {
  /** Returns the Base url for geocoding */
  getGeocodeUrl() {
    return (
      'https://maps.googleapis.com/maps/api/geocode/json?key=' + Config.web.REACT_APP_GOOGLE_API_KEY
    );
  },
  /** Returns Geocode info from an address search string. */
  getGeocodeInfoFromSearch(locationSearch: string) {
    const url = this.getGeocodeUrl() + '&address=' + encodeURI(locationSearch);
    return fetch(url).then((response: any) => response.json() as IGeocodeResponse);
  },
  /** Returns Geocode info from an address obejct */
  getGeocodeInfoFromAddressComponents(addressObject: IAddressBase) {
    let url = this.getGeocodeUrl();
    let components: { [index: string]: string } = {};

    // Have to split line1 into street number/name.
    if (addressObject.line1) {
      const line1Parts = addressObject.line1.split(' ');

      if (line1Parts.length === 2) {
        components['street_number'] = line1Parts[0];
        components['route'] = line1Parts[1];
      } else if (line1Parts.length === 1) {
        components['route'] = line1Parts[0];
      }
    }

    if (addressObject.city) {
      components['locality'] = addressObject.city;
    }

    if (addressObject.state) {
      components['administrative_area_level_1'] = addressObject.state;
    }

    if (addressObject.country) {
      components['country'] = addressObject.country;
    }

    if (addressObject.postal_code) {
      components['postal_code'] = addressObject.postal_code;
    }

    if (Object.keys(components).length > 0) {
      url += '&components=';

      for (const key in components) {
        url += `${key}:${components[key]}`;
      }
    }

    return fetch(url).then((response: any) => response.json() as IGeocodeResponse);
  },
  /** Converts the address components array of a Geocode result into an object w/ key/value pairs. */
  getAddressParts(result: IGeocodeResult) {
    let address: { [index: string]: string } = {};
    const address_components = result.address_components;

    address_components.forEach((element: any) => {
      address[element.types[0]] = element.long_name;
    });

    return address;
  },
};

export default locationHelpers;
