import React, { ReactNode } from 'react';
import { ButtonType } from '../../Button/Button';
import { ColSortDirection, IActiveFilter, ISortSettings } from '../../../interfaces';
import _ from 'lodash';
import {
  IColumnOptions,
  IRowSelectionOptions,
  ITableActionOptions,
  ITableProps,
  RowSelectionMode,
} from './Table';
import { ISelectFieldProps } from '../forms/select/SelectField/SelectField';
import { IMultiSelectFieldProps } from '../forms/select/MultiSelectField/MultiSelectField';
import { IAsyncSelectFieldProps } from '../forms/select/AsyncSelectField/AsyncSelectField';
import { IAsyncMultiSelectFieldProps } from '../forms/select/AsyncMultiSelectField/AsyncMultiSelectField';
import { IDateRangeProps } from '../forms/DatePicker/DateRangePicker/DateRangePickerField';
import { IPopupMenuItem } from '../PopupMenu/PopupMenu';
import { QueryHelpers } from '../../../helpers';
import { ISelectOption } from '../forms/select/types';
import moment from 'moment';

//Helper types
export type StringKey<T> = Extract<keyof T, string>;
export type CellValue<V = any> = V;
export type Primitive = number | string | boolean;
export type IdType<T> = StringKey<T> | string;

export type Renderer<T, TableMeta = any> = (original: T, meta?: TableMeta) => ReactNode;
export type Accessor<T> = (
  rowData: T,
  index: number,
) => number | string | boolean | null | undefined;
export type AccessorReturnType<T> = ReturnType<Accessor<T>>;
type DispatchType<T> = (value: TableAction<T>) => void;

//Action types
type TableActionType =
  | 'HEADER'
  | 'HEADER_SELECTED_ROW_ACTION'
  | 'HEADER_POPUP_MENU'
  | 'ROW_CONTEXT_MENU'
  | 'ROW_BUTTON';

interface ITableBaseAction<T> {
  label: string;
  icon?: string;
  hideIf?: (rowData: T) => boolean;
  disableIf?: (rowData: T) => boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

interface IBaseButtonAction {
  buttonType: ButtonType;
}

interface IHeaderAction<T> extends Omit<ITableBaseAction<T>, 'onClick'>, IBaseButtonAction {
  type: 'HEADER';
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

interface IHeaderActionPopupMenu<T>
  extends Omit<ITableBaseAction<T>, 'onClick' | 'icon' | 'hideIf' | 'disableIf' | 'label'> {
  type: 'HEADER_POPUP_MENU';
  menuItems: IPopupMenuItem[];
  className?: string;
  popupMenuClass?: string;
  renderPopupMenuView(): ReactNode;
}

interface IHeaderMultiSelectAction<T>
  extends Omit<ITableBaseAction<T>, 'onClick' | 'hideIf' | 'disableIf'> {
  type: 'HEADER_SELECTED_ROW_ACTION';
  // hideIf?: (rowData: T[]) => boolean; //TODO
  disableIf?: (rowData: T[]) => boolean;
  onClick: (e: React.MouseEvent<HTMLElement>, rowData: T[]) => void;
}

export interface IRowContextMenuAction<T> extends Omit<ITableBaseAction<T>, 'onClick'> {
  type: 'ROW_CONTEXT_MENU';
  onClick: (e: React.MouseEvent<HTMLElement>, rowData: T) => void;
}

interface IRowButtonAction<T> extends Omit<ITableBaseAction<T>, 'onClick'>, IBaseButtonAction {
  type: 'ROW_BUTTON';
  onClick: (e: React.MouseEvent<HTMLElement>, rowData: T) => void;
}

export type ITableAction<T> =
  | IHeaderAction<T>
  | IHeaderMultiSelectAction<T>
  | IRowContextMenuAction<T>
  | IRowButtonAction<T>
  | IHeaderActionPopupMenu<T>;

//Column Types
type StatusColor = 'GREY' | 'RED' | 'GREEN' | 'YELLOW';

export type PredefinedColumnType<T> = {
  type: 'STATUS';
  columnObject: (rowData: T) => MappedStatusColumnObject;
};
// | {
//     type: "DATE";
//     columnObject: (rowData: T) => {

//     }
// }
// | {
//     type: "CURRENCY";
//     columnObject: (rowData: T) => {

//     }
// }
// | {
//     type: "LINK";
//     columnObject: (rowData: T) => {
//         to: string;
//         label: string;
//     }
// }

// type ColumnType =
// | "STATUS"
// | "LINK"
// | "DROPDOWN"
// | "AVATAR"
// | "CUSTOM";

// {
//     // TODO: Dropdown column
//     type: "DROPDOWN";
//     columnObject: (rowData: T) => {
//     }
// }

type MappedStatusColumnObject = {
  [statusId: string]: {
    label: string;
    color: StatusColor;
  };
};

type IColumnInstanceType = 'DEFAULT' | 'SELECTABLE' | 'ACTION_BUTTONS' | 'ACTION_CONTEXT_MENU';

export interface IColumnInstance<T> extends IColumnOptions<T> {
  shouldRender: boolean;
  type: IColumnInstanceType;
}

export interface ICellInstance<T> {
  id: string;
  index: number;
  column: IColumnInstance<T>;
  row: IRowInstance<T>;
  value: AccessorReturnType<T>;
}

export interface IRowInstance<T> {
  id: string;
  cells: Array<ICellInstance<T>>;
  index: number;
  rowData: T;
  getRowButtonActions: () => Array<IRowButtonAction<T>>;
  getRowContextMenuActions: () => Array<IRowContextMenuAction<T>>;
  /** When rows are built, this holds a reference to @see IColumn.actionMenu */
  actionMenu?: (rowData: T) => Array<IRowContextMenuAction<T>>;
}

type DisabledRowInstanceMap<T> = Record<string, IRowInstance<T>>;
type SelectedRowInstanceMap<T> = Record<string, IRowInstance<T>>;

export type ExactStringValue = {
  type: 'text';
  value: string;
};

export type RangeStringValue = {
  type: 'range';
  valueRange: {
    from: string;
    to: string;
  };
};

export type SelectValue = {
  type: 'select';
  value: string;
  selectedOption: ISelectOption | undefined;
};

export type MultiSelectValue = {
  type: 'multi-select';
  selectedOptions: Array<ISelectOption>;
  selectedValues: Array<string>;
};

// //Function overloads to update specific value types
// export function createFilterValue(
//     filter: FilterInstance & ExactStringValue,
//     newValue: ExactStringValue
// ): FilterInstance & ExactStringValue;
// export function createFilterValue(
//     filter: FilterInstance & SelectValue,
//     newValue: SelectValue
// ): FilterInstance & SelectValue;
// export function createFilterValue(
//     filter: FilterInstance & MultiSelectValue,
//     newValue: MultiSelectValue
// ): FilterInstance & MultiSelectValue;
// export function createFilterValue(
//     filter: FilterInstance & RangeStringValue,
//     newValue: RangeStringValue
// ): FilterInstance & RangeStringValue;

// export function createFilterValue<TInstance, TValue>(
//     filter: TInstance,
//     newValue: TValue,
// ): TInstance {
//     return {
//         ...filter,
//         ...newValue,
//     };
// }

interface IBaseFieldOptions {
  label: string;
  // validateField: (fieldInstance: FieldInstance) => boolean;
  placeholder?: string;
}
export interface IFilterOptions {
  fieldId: string;
  fieldOptions: FilterFieldOptions;
}

export type FieldType =
  | 'textField'
  | 'selectField'
  | 'multiSelectField'
  | 'asyncSelectField'
  | 'asyncMultiSelectField'
  | 'dateRangeField'
  | 'dateField'
  | 'numberField';

type FieldOptionMap = Record<FieldType, unknown> & {
  textField: IBaseFieldOptions;
  numberField: IBaseFieldOptions;
  selectField: IBaseFieldOptions & Pick<ISelectFieldProps, 'options'>;
  multiSelectField: IBaseFieldOptions & Pick<IMultiSelectFieldProps, 'options'>;
  asyncSelectField: IBaseFieldOptions & Pick<IAsyncSelectFieldProps, 'onSearchRequest' | 'limit'>;
  asyncMultiSelectField: IBaseFieldOptions &
    Pick<IAsyncSelectFieldProps, 'onSearchRequest' | 'limit'>;
  dateRangeField: Pick<IDateRangeProps, 'toFieldOptions' | 'fromFieldOptions' | 'locale'>;
  dateField: IBaseFieldOptions & {};
};

export type FieldValueMap = Record<FieldType, unknown> & {
  textField: ExactStringValue;
  numberField: ExactStringValue;
  selectField: SelectValue;
  multiSelectField: MultiSelectValue;
  asyncSelectField: SelectValue;
  asyncMultiSelectField: MultiSelectValue;
  dateRangeField: RangeStringValue;
  dateField: ExactStringValue;
};

export type FieldFilterValue = ExactStringValue | SelectValue | MultiSelectValue | RangeStringValue;

export type FieldOption<T extends FieldType = FieldType> = T extends T
  ? {
      type: T;
      fieldId: string;
      options: FieldOptionMap[T];
    }
  : never;

export type FieldInstance<T extends FieldType = FieldType> = T extends T
  ? {
      type: T;
      fieldId: string;
      options: FieldOptionMap[T];
      value: FieldValueMap[T];
    }
  : never;

type FilterFieldOptions =
  | ({
      type: 'TEXT-SEARCH';
    } & IBaseFieldOptions)
  | ({
      type: 'SELECT-FILTER';
    } & IBaseFieldOptions &
      Pick<ISelectFieldProps, 'options'>)
  | ({
      type: 'MULTI-SELECT-FILTER';
    } & IBaseFieldOptions &
      Pick<IMultiSelectFieldProps, 'options'>)
  | ({
      type: 'ASYNC-SELECT-FILTER';
    } & IBaseFieldOptions &
      Pick<IAsyncSelectFieldProps, 'onSearchRequest' | 'limit'>)
  | ({
      type: 'ASYNC-MULTI-SELECT-FILTER';
    } & IBaseFieldOptions &
      Pick<IAsyncMultiSelectFieldProps, 'onSearchRequest' | 'limit'>)
  | ({
      type: 'DATE-RANGE-FILTER';
    } & Pick<IDateRangeProps, 'toFieldOptions' | 'fromFieldOptions' | 'locale'>)
  | ({
      type: 'DATE-SELECTOR';
    } & IBaseFieldOptions);

interface IRowButtonAction<T> extends Omit<ITableBaseAction<T>, 'onClick'>, IBaseButtonAction {
  type: 'ROW_BUTTON';
  onClick: (e: React.MouseEvent<HTMLElement>, rowData: T) => void;
}

//DrawerState types
interface IFilterDrawer {
  type: 'FILTER_DRAWER';
}

interface ISelectedRowDrawer<T> {
  type: 'SELECTED_ROW_DRAWER';
  selectedRow: IRowInstance<T>;
}

//We have two different types of drawers "SELECTED_ROW_DRAWER" | "FILTER_DRAWER"
export type DrawerState<T> = IFilterDrawer | ISelectedRowDrawer<T> | null;

export interface ITableState<T> {
  rowState: {
    rowInstances: Array<IRowInstance<T>>;
    disabledRowInstanceMap: DisabledRowInstanceMap<T>;
    selectedRowData: T[];
    selectedRowInstanceMap: SelectedRowInstanceMap<T>;
    selectedRowCount: number;
    showColumnContextMenuById: string | null;
  };
  paginationState: {
    isLoading: boolean;
    pageSizeOptions: Array<number>;
  };
  columnState: {
    columnInstances: Array<IColumnInstance<T>>;
  };
  filterState: {
    tableSearchValue: string;
    activeColumnFilters: Array<IActiveFilter>;
    sortedColumnFilters: Array<ISortSettings>;
  };
  drawerState: {
    activeDrawer: DrawerState<T>;
  };
  headerState: {
    showTableSearchInput: boolean;
    headerActions: Array<IHeaderAction<T>>;
    headerSelectedRowActions: Array<IHeaderMultiSelectAction<T>>;
    headerActionsPopupMenu: Array<IHeaderActionPopupMenu<T>>;
    headerActionsPopupMenuShowActiveMenu: boolean;
    headerActionsPopupMenuActiveMenuId: number;
    /** If toggled to true, it replaces default header actions.
     * Used with action for multiple rows that are selected.
     */
    showRowSelectActions: boolean;
  };
}

type CoreTableAction<T> =
  | { type: 'build-column-instances'; payload: { columnInstances: Array<IColumnInstance<T>> } }
  | { type: 'build-row-instances'; payload: { rowInstances: Array<IRowInstance<T>> } }
  | {
      type: 'build-header-action-instances';
      payload: {
        headerActions: Array<IHeaderAction<T>>;
        headerSelectedRowActions: Array<IHeaderMultiSelectAction<T>>;
        headerActionsPopupMenu: Array<IHeaderActionPopupMenu<T>>;
      };
    };

type RowAction =
  | { type: 'disable-row'; payload: { rowId: string } }
  | {
      type: 'toggle-select-row';
      payload: { rowId: string; mode: RowSelectionMode; shouldSelectRow?: boolean };
    }
  | { type: 'unselect-all-rows' }
  | {
      type: 'toggle-cell-context-menu';
      payload: { cellId: string; showColumnContextMenu: boolean };
    };

type HeaderAction = {
  type: 'toggle-filter-drawer-action';
  payload: { showFilterDrawerAction?: boolean };
};

type DrawerAction<T> =
  | { type: 'toggle-active-drawer'; payload: { activeDrawer: DrawerState<T> } }
  | { type: 'close-active-drawer' };

type FilterAction<T> =
  | { type: 'apply-column-filter'; payload: { filter: IActiveFilter } }
  | { type: 'remove-all-table-filters' }
  | {
      type: 'apply-column-sort';
      payload: { columnId: string; direction?: ColSortDirection | null };
    }
  | { type: 'search-table'; payload: { tableSearchValue: string } }
  | { type: 'clear-table-search-value' }
  | { type: 'update-filter-instance'; payload: { filter: IActiveFilter } }
  | { type: 'update-all-filter-instances'; payload: { filters: Array<IActiveFilter> } }
  | { type: 'clear-filters' };

type PopupMenuAction<T> = {
  type: 'toggle-active-popup-menu';
  payload: { isActive: boolean; id: number };
};

export type TableAction<T> =
  | RowAction
  | DrawerAction<T>
  | FilterAction<T>
  | PopupMenuAction<T>
  | CoreTableAction<T>;

export function tableReducer<T>(state: ITableState<T>, action: TableAction<T>): ITableState<T> {
  switch (action.type) {
    //CoreTableAction
    case 'build-row-instances': {
      return {
        ...state,
        rowState: {
          ...state.rowState,
          rowInstances: action.payload.rowInstances,
        },
      };
    }
    case 'build-column-instances': {
      return {
        ...state,
        columnState: {
          ...state.columnState,
          columnInstances: action.payload.columnInstances,
        },
      };
    }
    case 'build-header-action-instances': {
      return {
        ...state,
        headerState: {
          ...state.headerState,
          headerActions: action.payload.headerActions,
          headerSelectedRowActions: action.payload.headerSelectedRowActions,
          headerActionsPopupMenu: action.payload.headerActionsPopupMenu,
        },
      };
    }

    //RowAction
    case 'toggle-select-row': {
      const { selectedRowInstanceMap } = state.rowState;
      const selectedRowInstance = state.rowState.rowInstances.find(
        (row) => row.id === action.payload.rowId,
      );

      if (!!selectedRowInstance) {
        let resultingSelectedRowInstanceMap = {
          ...(action.payload.mode === 'multi-select' ? selectedRowInstanceMap : {}),
        };
        const isAlreadySelected = selectedRowInstanceMap.hasOwnProperty(selectedRowInstance.id);
        if (isAlreadySelected) {
          delete resultingSelectedRowInstanceMap[selectedRowInstance.id];

          const selectedRowData = Object.values(resultingSelectedRowInstanceMap).map(
            (rowInstance) => rowInstance.rowData,
          );
          return {
            ...state,
            headerState: {
              ...state.headerState,
              showRowSelectActions: selectedRowData.length > 0,
            },
            rowState: {
              ...state.rowState,
              selectedRowData,
              selectedRowInstanceMap: resultingSelectedRowInstanceMap,
              selectedRowCount: Object.keys(resultingSelectedRowInstanceMap).length,
            },
          };
        }

        const selectedRowData = [
          ...Object.values(resultingSelectedRowInstanceMap).map(
            (rowInstance) => rowInstance.rowData,
          ),
          selectedRowInstance.rowData,
        ];
        return {
          ...state,
          headerState: {
            ...state.headerState,
            showRowSelectActions: true,
          },
          rowState: {
            ...state.rowState,
            selectedRowData,
            selectedRowInstanceMap: {
              ...resultingSelectedRowInstanceMap,
              [action.payload.rowId]: selectedRowInstance,
            },
            selectedRowCount: Object.keys(resultingSelectedRowInstanceMap).length + 1,
          },
        };
      }

      return state;
    }
    case 'unselect-all-rows': {
      return {
        ...state,
        rowState: {
          ...state.rowState,

          selectedRowInstanceMap: {},
        },
        headerState: {
          ...state.headerState,
          showRowSelectActions: false,
        },
      };
    }
    case 'toggle-cell-context-menu': {
      const { showColumnContextMenu, cellId } = action.payload;

      return {
        ...state,
        rowState: {
          ...state.rowState,
          showColumnContextMenuById: showColumnContextMenu ? cellId : null,
        },
      };
    }

    //FilterAction
    case 'apply-column-sort': {
      const columnFilter = state.filterState.sortedColumnFilters.find(
        (col) => col.id === action.payload.columnId,
      );
      const nextSortDirection =
        action.payload.direction || getNextColumnSortDirection(columnFilter?.order || null);
      const filterOutExistingColumnFilter = state.filterState.sortedColumnFilters.filter(
        (col) => col.id !== action.payload.columnId,
      );

      if (columnFilter && nextSortDirection) {
        return {
          ...state,
          filterState: {
            ...state.filterState,
            sortedColumnFilters: [
              ...filterOutExistingColumnFilter,
              {
                ...columnFilter,
                order: nextSortDirection,
              },
            ],
          },
        };
      } else if (columnFilter && nextSortDirection == null) {
        return {
          ...state,
          filterState: {
            ...state.filterState,
            sortedColumnFilters: filterOutExistingColumnFilter,
          },
        };
      } else if (columnFilter == null && nextSortDirection != null) {
        return {
          ...state,
          filterState: {
            ...state.filterState,
            sortedColumnFilters: [
              ...state.filterState.sortedColumnFilters,
              {
                id: action.payload.columnId,
                order: nextSortDirection,
              },
            ],
          },
        };
      }

      return state;
    }
    case 'apply-column-filter': {
      const newFilter = action.payload.filter;
      const filterAlreadyExists = state.filterState.activeColumnFilters.find(
        (filter) => filter.id !== newFilter.id,
      );

      if (!filterAlreadyExists) {
        return {
          ...state,
          filterState: {
            ...state.filterState,
            activeColumnFilters: [...state.filterState.activeColumnFilters],
          },
        };
      }

      return state;
    }
    case 'remove-all-table-filters': {
      return {
        ...state,
        filterState: {
          ...state.filterState,
          activeColumnFilters: [],
          sortedColumnFilters: [],
          tableSearchValue: '',
        },
      };
    }
    case 'search-table': {
      return {
        ...state,
        filterState: {
          ...state.filterState,
          tableSearchValue: action.payload.tableSearchValue,
        },
      };
    }
    case 'clear-table-search-value': {
      return {
        ...state,
        filterState: {
          ...state.filterState,
          tableSearchValue: '',
        },
      };
    }
    case 'update-all-filter-instances': {
      return {
        ...state,
        filterState: {
          ...state.filterState,
          activeColumnFilters: action.payload.filters,
        },
      };
    }
    case 'update-filter-instance': {
      return {
        ...state,
        filterState: {
          ...state.filterState,
          activeColumnFilters: [
            ...state.filterState.activeColumnFilters.map((filter) => {
              if (filter.id === action.payload.filter.id) {
                return action.payload.filter;
              }

              return filter;
            }),
          ],
        },
      };
    }
    case 'clear-filters': {
      return {
        ...state,
        filterState: {
          ...state.filterState,
          activeColumnFilters: [],
        },
      };
    }

    //DrawerAction
    case 'toggle-active-drawer': {
      return {
        ...state,
        drawerState: {
          activeDrawer: action.payload.activeDrawer,
        },
      };
    }
    case 'close-active-drawer': {
      return {
        ...state,
        drawerState: {
          activeDrawer: null,
        },
      };
    }

    //PopupMenu
    case 'toggle-active-popup-menu': {
      return {
        ...state,
        headerState: {
          ...state.headerState,
          headerActionsPopupMenuActiveMenuId: action.payload.id,
          headerActionsPopupMenuShowActiveMenu: action.payload.isActive,
        },
      };
    }

    default:
      return state;
  }
}

export function getDefaultTableState<T>(): ITableState<T> {
  return {
    columnState: {
      columnInstances: [],
    },
    rowState: {
      rowInstances: [],
      selectedRowInstanceMap: {},
      selectedRowData: [],
      selectedRowCount: 0,
      disabledRowInstanceMap: {},
      showColumnContextMenuById: null,
    },
    drawerState: {
      activeDrawer: null,
    },
    filterState: {
      activeColumnFilters: [],
      sortedColumnFilters: [],
      tableSearchValue: '',
    },
    headerState: {
      headerActions: [],
      headerSelectedRowActions: [],
      headerActionsPopupMenu: [],
      headerActionsPopupMenuActiveMenuId: -1,
      headerActionsPopupMenuShowActiveMenu: false,
      showRowSelectActions: false,
      showTableSearchInput: false,
    },
    paginationState: {
      isLoading: true,
      pageSizeOptions: [10],
    },
  };
}

/**
 * Creates header actions based on table props. If the filter drawer action is enabled, it will be added on to
 * the Header actions.
 */
export function createHeaderActionInstances<T, TableMeta>(
  tableProps: ITableProps<T, TableMeta>,
  dispatch?: DispatchType<T>,
) {
  const headerSelectedRowActions = getTableActionsByPosition<T, IHeaderMultiSelectAction<T>>(
    'HEADER_SELECTED_ROW_ACTION',
    tableProps.tableActionOptions?.tableActions || [],
  );
  const headerActionsPopupMenu = getTableActionsByPosition<T, IHeaderActionPopupMenu<T>>(
    'HEADER_POPUP_MENU',
    tableProps.tableActionOptions?.tableActions || [],
  );
  let headerActions = getTableActionsByPosition<T, IHeaderAction<T>>(
    'HEADER',
    tableProps.tableActionOptions?.tableActions || [],
  );

  // BE not implemented
  /* 
    if (tableProps.filterOptions?.enableFilterDrawer && dispatch) {
        headerActions.push({
            type: "HEADER",
            label: "Filter",
            buttonType: "outline-dark",
            onClick: () =>
                dispatch({
                    type: "toggle-active-drawer",
                    payload: { activeDrawer: { type: "FILTER_DRAWER" }
                }
            }),
        })
    }
 */
  return {
    headerActions,
    headerSelectedRowActions,
    headerActionsPopupMenu,
  };
}

export function createTableState<T, TableMeta>(
  tableProps: ITableProps<T, TableMeta>,
): ITableState<T> {
  const defaultTableState = getDefaultTableState<T>();

  const columnInstances = createColumnInstances({
    columnOptions: tableProps.columns,
    rowSelectionOptions: tableProps.rowSelectionOptions,
    tableActionOptions: tableProps.tableActionOptions,
  });
  return {
    ...defaultTableState,
    headerState: {
      ...defaultTableState.headerState,
      showTableSearchInput: tableProps.filterOptions?.enableTableSearch || false,
    },
    columnState: {
      ...defaultTableState.columnState,
      columnInstances: columnInstances,
    },
  };
}

interface ICreateColumnInstancesOptions<T, TableMeta> {
  columnOptions: Array<IColumnOptions<T, TableMeta>>;
  tableActionOptions: ITableActionOptions<T> | undefined;
  rowSelectionOptions: IRowSelectionOptions<T> | undefined;
}

export function createColumnInstances<T, TableMeta>(
  options: ICreateColumnInstancesOptions<T, TableMeta>,
): Array<IColumnInstance<T>> {
  const { columnOptions, rowSelectionOptions, tableActionOptions } = options;
  const isRowSelectionEnabled = !!rowSelectionOptions?.enableRowSelectionMode;
  const columnInstances: Array<IColumnInstance<T>> = [
    {
      id: 'selectable',
      shouldRender: isRowSelectionEnabled,
      type: 'SELECTABLE',
    },
    ...columnOptions.map<IColumnInstance<T>>((column) => ({
      ...column,
      shouldRender: column.showColumn ?? true,
      id: column.id,
      type: 'DEFAULT' as IColumnInstanceType,
    })),
    {
      id: 'action-buttons',
      shouldRender: !!tableActionOptions?.enableRowButtonActions,
      type: 'ACTION_BUTTONS',
    },
    {
      id: 'action-menu',
      shouldRender: !!tableActionOptions?.enableRowContextMenuActions,
      type: 'ACTION_CONTEXT_MENU',
    },
  ];

  return columnInstances;
}

interface ICreateRowInstanceOptions<T> {
  rowData: T[];
  columnInstances: Array<IColumnInstance<T>>;
  tableOptions: ITableProps<T>;
  dispatch: DispatchType<T>;
}
export function createRowInstances<T>(
  options: ICreateRowInstanceOptions<T>,
): Array<IRowInstance<T>> {
  const { columnInstances, rowData, tableOptions, dispatch } = options;
  const { rowSelectionOptions, tableActionOptions } = tableOptions;

  const rowInstances = rowData.map((rowData, idx) => {
    const rowId = rowSelectionOptions?.rowId(rowData) || `row_${idx}`;

    let rowInstance: IRowInstance<T> = {
      id: rowId,
      index: idx,
      rowData: rowData,
      getRowContextMenuActions() {
        const expandRowDetailAction: IRowContextMenuAction<T> = {
          type: 'ROW_CONTEXT_MENU',
          label: 'Expand Row Details',
          hideIf: () =>
            !tableActionOptions?.rowDetailDrawerOptions ||
            !!tableActionOptions?.rowDetailDrawerOptions?.(rowData).hideIf,
          onClick: () =>
            dispatch({
              type: 'toggle-active-drawer',
              payload: {
                activeDrawer: {
                  type: 'SELECTED_ROW_DRAWER',
                  selectedRow: rowInstance,
                },
              },
            }),
        };
        return [
          expandRowDetailAction,
          ...getTableActionsByPosition<T, IRowContextMenuAction<T>>(
            'ROW_CONTEXT_MENU',
            tableActionOptions?.tableActions || [],
          ),
        ];
      },
      getRowButtonActions: () =>
        getTableActionsByPosition<T, IRowButtonAction<T>>(
          'ROW_BUTTON',
          tableActionOptions?.tableActions || [],
        ),

      cells: [],
    };

    rowInstance.cells = columnInstances.map<ICellInstance<T>>((columnInstance, idx) => {
      return {
        id: `cell_${rowId}_${columnInstance.id}`,
        index: idx,
        column: columnInstance,
        row: rowInstance,
        value: columnInstance.accessor?.(rowData, idx),
      };
    });

    return rowInstance;
  });

  return rowInstances;
}

//Table helpers
function getTableActionsByPosition<T, ActionType extends ITableAction<T>>(
  actionType: TableActionType,
  tableActions: Array<ITableAction<T>>,
): Array<ActionType> {
  return tableActions.filter((action) => action.type === actionType) as Array<ActionType>;
}

export function createActiveFilterInstance(fieldInstance: FieldInstance): IActiveFilter[] | null {
  switch (fieldInstance.type) {
    case 'textField':
      return [
        {
          id: fieldInstance.fieldId,
          label: fieldInstance.options.label,
          type: 'text',
          value: fieldInstance.value.value,
        },
      ];
    case 'dateRangeField': {
      return [
        {
          id: fieldInstance.fieldId,
          type: 'with_prefix',
          label: fieldInstance.options.fromFieldOptions.label,
          prefix: QueryHelpers.FILTER_TYPE_OPERATOR_MAP.greater_than_or_equal,
          value: moment(new Date(fieldInstance.value.valueRange.from)).toISOString(),
        },
        {
          id: fieldInstance.fieldId,
          type: 'with_prefix',
          label: fieldInstance.options.toFieldOptions.label,
          prefix: QueryHelpers.FILTER_TYPE_OPERATOR_MAP.less_than_or_equal,
          value: moment(new Date(fieldInstance.value.valueRange.to)).toISOString(),
        },
      ];
    }
    case 'numberField':
    case 'dateField':
    case 'asyncSelectField':
    case 'selectField': {
      return [
        {
          id: fieldInstance.fieldId,
          label: fieldInstance.options.label,
          type: 'with_prefix',
          value: fieldInstance.value.value,
          prefix: QueryHelpers.FILTER_TYPE_OPERATOR_MAP.equal,
        },
      ];
    }
    case 'multiSelectField': {
      return [
        {
          id: fieldInstance.fieldId,
          label: fieldInstance.options.label,
          type: 'with_prefix',
          value: fieldInstance.value.selectedValues.join('|'),
          prefix: QueryHelpers.FILTER_TYPE_OPERATOR_MAP.equal,
        },
      ];
    }
    default: {
      return null;
    }
  }
}

//Column Helpers
function getNextColumnSortDirection(
  currentDirection: ColSortDirection | null,
): ColSortDirection | null {
  switch (currentDirection) {
    case 'asc': {
      return null;
    }
    case 'desc': {
      return 'asc';
    }
    case null: {
      return 'desc';
    }
    default: {
      return null;
    }
  }
}

//Filter helpers
export function getCurrentColumnSortDirection<T>(
  columnId: string,
  activeSortFilters: Array<ISortSettings>,
): ISortSettings | undefined {
  return activeSortFilters.find((setting) => setting.id === columnId);
}
