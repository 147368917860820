import React from 'react';

import './FormSteps.scss';

interface IProps {
  steps: string[];
  current: number;
}

interface IState {}

class FormSteps extends React.Component<IProps, IState> {
  render() {
    return (
      <div className="FormSteps">
        {this.props.steps.map((item, index) => {
          return (
            <div
              key={index}
              className={
                this.props.current === index
                  ? 'step current'
                  : this.props.current > index
                    ? 'step complete'
                    : 'step'
              }
            >
              <div className="circle">
                <i className="fas fa-check"></i>
              </div>
              <div className="label">{item}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default FormSteps;
