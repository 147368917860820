import React, { ReactElement, useEffect, useState } from 'react';
import { IApplication, ICause, IGigSummary } from '@gigit/interfaces';
import { ReactComponent as ApplicationsIcon } from '../../../../assets/dashboard/activity/applications-icon.svg';
import { Constants } from '@gigit/constants';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { capitalizeString } from '../../../../helpers';
import './ApplicationsDetailView.scss';
import useToastDispatcher from '../../../../hooks/useToaster';
import { causeRequestActions, gigRequestActions } from '../../../../requestActions';
import {
  HorizontalScroller,
  IHorizontalScrollerRenderProps,
} from '../../../shared/HorizontalScroller/HorizontalScroller';

interface IProps {
  application: IApplication;
  locale: string;
  contactOrganizer?(): void;
  hideDetailView(): void;
}

const ApplicationsDetailView: React.FC<IProps> = (props) => {
  const { dispatchToastError } = useToastDispatcher();
  const [gig, setGig] = useState<IGigSummary>();
  const [causes, setCauses] = useState<ICause[]>([]);

  const getCauses = (): void => {
    causeRequestActions
      .getCauses()
      .then((res) => {
        setCauses(res);
      })
      .catch((error) => dispatchToastError(error, 'Get Causes'));
  };

  const renderCauses = (scrollerProps: IHorizontalScrollerRenderProps): ReactElement[] => {
    let tmpCausesIds: string[] = gig?.causes || [];
    let causesNames: string[] = [];

    for (let cause of causes) {
      tmpCausesIds.forEach((tmpCauseId) => {
        if (cause.id === tmpCauseId) {
          causesNames.push(cause.cause);
        }
      });
    }

    return causesNames.map((tag, index) => (
      <span
        key={index}
        className="pill light tag"
      >
        {tag}
      </span>
    ));
  };

  useEffect(() => {
    if (props.application.gig?.handle) {
      gigRequestActions
        .getGigByHandle(props.application.gig.handle)
        .then((res) => {
          setGig(res);
        })
        .catch((error) => dispatchToastError(error, 'Get Gig'));
    }

    getCauses();
  }, [props.application.gig]);

  return (
    <section className="ApplicationsDetailView">
      <div
        className="back-to"
        onClick={() => {
          props.hideDetailView();
        }}
      >
        <span>
          <i className="fas fa-arrow-left" /> Back to Applications
        </span>
      </div>
      <div className="detail-view-header">
        <div className="main-details">
          {props.application?.group?.profile_image_url ? (
            <img
              src={props.application?.group?.profile_image_url}
              alt="group logo"
            />
          ) : (
            <ApplicationsIcon />
          )}
          <div className="names">
            <h2 notranslate="yes">{props.application.gig ? props.application.gig.title : 'N/A'}</h2>
            <span>
              <var data-var="event_title">{props.application.event?.title}</var>
              <span className="lighter">{' hosted by '}</span>
              <var data-var="group_title">{props.application.group?.title}</var>
            </span>
          </div>
        </div>
        <span
          className="contact-icon"
          onClick={() => {
            props.contactOrganizer && props.contactOrganizer();
          }}
        >
          <i className="fas fa-comment-alt-lines" />
        </span>
      </div>
      <div className="sub-info-container">
        <span
          notranslate="yes"
          className="date-info"
        >
          {props.application?.created_at &&
            localizeHelpers.translate('Applied on {{date}}', {
              date: localizeHelpers.formatDate(
                props.application.created_at,
                LocaleDateFormats.ll,
                props.locale,
              ),
            })}
        </span>
        {props.application?.status?.code && (
          <span className={`tag ${props.application.status.code}`}>
            {props.application.status.code === Constants.application_status.rejected
              ? capitalizeString('not accepted')
              : capitalizeString(props.application.status.code)}
          </span>
        )}
      </div>
      <div className="gig-details-container">
        <h4 className="mid-title with-border">Volunteer Opportunity Details</h4>
        {gig && (
          <>
            <h5 className="small-title">Description</h5>
            <p>{gig.description}</p>
          </>
        )}
        {props.application.user?.skills && props.application.user?.skills.length > 0 && (
          <>
            <h5 className="small-title">Skills</h5>
            <div className="pills">
              {props.application.user.skills.map((skill) => (
                <span className="pill light">{skill}</span>
              ))}
            </div>
          </>
        )}

        {props.application.user?.causes && props.application.user?.causes.length > 0 && (
          <>
            <h5 className="small-title">Focus Areas</h5>
            <div className="pills">
              <HorizontalScroller>
                {(scrollerProps) => renderCauses(scrollerProps)}
              </HorizontalScroller>
            </div>
          </>
        )}
      </div>
      <div className="application-details-container">
        <h4 className="mid-title with-border">My Application Details</h4>

        <h5 className="small-title">Applicant Details</h5>
        <span
          className="info"
          notranslate="yes"
        >
          {props.application.user?.first_name} {props.application.user?.last_name}
        </span>
        <span
          className="info"
          notranslate="yes"
        >
          {props.application.user?.email}
        </span>
        <span
          className="info"
          notranslate="yes"
        >
          {props.application.user?.phone}
        </span>

        {props.application.user?.emergency_contacts &&
          props.application.user?.emergency_contacts.length > 0 && (
            <>
              <h5 className="small-title">Emergency Contact</h5>
              <div className="contacts">
                {props.application.user.emergency_contacts.map((contact) => (
                  <>
                    <span
                      notranslate="yes"
                      className="info"
                    >
                      {contact.name}
                    </span>
                    <span
                      notranslate="yes"
                      className="info"
                    >
                      {contact.phone}
                    </span>
                  </>
                ))}
              </div>
            </>
          )}

        <h5 className="small-title">Availability</h5>
        <div className="availability-container">
          {props.application?.volunteer_availability ? (
            props.application?.volunteer_availability.days.map((day) => (
              <>
                <div className="day">
                  <span className="info">{day.day}</span>
                  <span className="info">{day.times.join(', ')}</span>
                </div>
              </>
            ))
          ) : (
            <span className="info">N/A</span>
          )}
        </div>
      </div>
    </section>
  );
};

export default ApplicationsDetailView;
