import React from 'react';

import { IPage } from '@gigit/interfaces';

import './PageSelector.scss';
import { combineClassNames } from '../../../helpers';

import { ReactComponent as AddButton } from '../../../assets/add-button.svg';
import {
  HorizontalScroller,
  IHorizontalScrollerRenderProps,
} from '../../shared/HorizontalScroller/HorizontalScroller';

interface IProps {
  pages: IPage[];
  currentPageIndex: number;
  editable: boolean;
  isLoading: boolean;

  onOpenManageModal(): void;
  onPageClick(index: number): void;
}

interface IState {
  showManageModal: boolean;
}

/** Page tab selector for allowing user to change the current page. */
export class PageSelector extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showManageModal: false,
    };
  }

  render() {
    return (
      <div className="PageSelector">
        <div className="scroller">
          <HorizontalScroller>
            {(scrollerProps) =>
              this.props.pages.map((page, index) => this.renderPageItem(page, index, scrollerProps))
            }
          </HorizontalScroller>
        </div>

        {this.props.editable && (
          <div
            className="add-button"
            onClick={() => this.props.onOpenManageModal()}
          >
            <AddButton />
          </div>
        )}
      </div>
    );
  }

  renderPageItem(page: IPage, index: number, scrollerProps: IHorizontalScrollerRenderProps) {
    const onClick = () => {
      this.props.onPageClick(index);
      scrollerProps.moveItemIntoFocus(index);
    };

    return (
      <li
        key={index}
        className={combineClassNames(
          'page-item',
          this.props.currentPageIndex === index ? 'selected' : null,
        )}
        notranslate="yes"
        onClick={onClick}
      >
        {page.label}
      </li>
    );
  }
}
