import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import './ShiftManage.scss';
import axios from 'axios';
import { IShift, IShiftRecurrence } from '@gigit/interfaces';
import moment from 'moment';
import DateTimePicker from '../shared/DateTimePicker/DateTimePicker';
import Button from '../Button/Button';
import DateTime from 'react-datetime';
import {
  errorHelpers,
  handleInputChange,
  routes,
  swapRouteParams,
  toastError,
} from '../../helpers';
import Dropdown from '../Dropdown/Dropdown';
import TextField from '../TextField/TextField';
import SearchList from '../SearchList/SearchList';
import Portrait from '../Portrait/Portrait';
import { IGroupState } from '../../reducers/group';
import { createShift } from '../../actions/gig';
import { IUserState } from '../../reducers/user';
import { localizeHelpers } from '../../localizeHelpers';
import { IToast } from '../../interfaces';
import { createToast } from '../../actions/toaster';
import { userSelectors } from '../../selectors/user';
import { Constants } from '@gigit/constants';
import { uiConstants } from '../../constants';

const WEEKLY_DAYS_START = 0; //0-Sunday. 1-Mon, 2-Tue...
const MAX_SHIFT_CAPACITY = 12;
interface IListItem {
  label?: string;
  image?: string;
  id: string;
}

interface IProps extends RouteComponentProps {
  userState: IUserState;
  groupState: IGroupState;
  shift?: IShift | null;
  gigId: string;
  workers: IListItem[];
  locale: string;

  onClose(): void;
  createToast(toast: IToast): void;
}

interface IState {
  shiftDate: moment.Moment;
  startHour: string | moment.Moment;
  endHour: string | moment.Moment;
  endReoccurrance: string | moment.Moment;
  reoccurrance: string;
  frequency: string;
  occurrence: string;
  filteredWorkerList: IListItem[];
  weeklyDays: number[];
  ends: string;
  num_people: string;
}

class ShiftManage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      shiftDate: moment(),
      startHour: moment().set({
        hour: 6,
        minute: 0,
        second: 0,
      }),
      endHour: moment().set({
        hour: 18,
        minute: 0,
        second: 0,
      }),
      reoccurrance: 'no',
      frequency: '1',
      occurrence: '2',
      filteredWorkerList: [],
      weeklyDays: [WEEKLY_DAYS_START],
      ends: 'on',
      num_people: '1',
      endReoccurrance: moment(),
    };
  }

  componentDidMount() {
    if (this.props.shift) {
      this.setState({
        shiftDate: moment(this.props.shift.start_date),
        startHour: moment(this.props.shift.start_date),
        endHour: moment(this.props.shift.end_date),
        num_people: String(this.props.shift?.num_people) || '1',
      });
    }
  }

  isStartBeforeEnd() {
    let isStartBeforeEnd: boolean = false;

    if (typeof this.state.startHour !== 'string') {
      isStartBeforeEnd = this.state.startHour?.isBefore(this.state.endHour);
    }

    if (!isStartBeforeEnd) {
      this.showTimeError();
    }

    return isStartBeforeEnd;
  }

  editShift() {
    let payload = this.generatePayload();
    axios
      .put(
        swapRouteParams(routes.UPDATE_GIG_SHIFT, {
          id: this.props.gigId,
          shift_id: this.props.shift?.id,
        }),
        payload,
      )
      .then((response) => {
        this.props.onClose();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Edit Shift');
        this.props.createToast(toast);
      });
  }

  generatePayload(isCreate?: boolean) {
    let startTime = moment(this.state.startHour).hour();
    let startMinute = moment(this.state.startHour).minute();

    let endTime = moment(this.state.endHour).hour();
    let endMinute = moment(this.state.endHour).minute();

    let startDate = moment(this.state.shiftDate)
      .set({
        hour: startTime,
        minute: startMinute,
        second: 0,
      })
      .utc()
      .toDate();

    let endDate = moment(this.state.shiftDate)
      .set({
        hour: endTime,
        minute: endMinute,
        second: 0,
      })
      .utc()
      .toDate();

    let payload: IShiftRecurrence = {
      start_date: startDate,
      end_date: endDate,
      num_people: parseInt(this.state.num_people),
      status: {
        code: Constants.gig_shift_status.active,
        status_date: new Date(startDate),
        status_by: this.props.userState.user,
      },
    };

    if (isCreate) {
      let endReoccurrance = moment(this.state.endReoccurrance)
        .set({
          hour: 12,
          minute: 0,
          second: 0,
        })
        .utc()
        .format();

      if (this.state.reoccurrance !== 'no' && this.state.ends === 'on') {
        payload.recurrence = {
          recurrence_start_date: new Date(startDate),
          recurrence_end_date: new Date(endReoccurrance),
          frequency: parseInt(this.state.frequency),
          recurrence_type: 'daily',
        };
      } else if (this.state.reoccurrance !== 'no' && this.state.ends === 'after') {
        payload.recurrence = {
          recurrence_start_date: new Date(startDate),
          occurrences: parseInt(this.state.occurrence),
          frequency: parseInt(this.state.frequency),
          recurrence_type: 'daily',
        };
      }
      if (this.state.reoccurrance === 'daily' && payload.recurrence) {
        payload.recurrence.recurrence_type = 'daily';
      } else if (this.state.reoccurrance === 'weekly' && payload.recurrence) {
        payload.recurrence.recurrence_type = 'weekly';
        payload.recurrence.on = this.state.weeklyDays;
      }

      if (this.state.filteredWorkerList.length > 0) {
        payload.assigned_users = [];

        for (let w in this.state.filteredWorkerList) {
          let _worker = this.state.filteredWorkerList[w];
          payload.assigned_users.push({ id: _worker.id });
        }
      }
    } else {
      payload.total_hours =
        Math.round((100 * Math.abs(endDate.getTime() - startDate.getTime())) / (1000 * 60 * 60)) /
        100;
    }

    return payload;
  }

  showTimeError() {
    const toast = toastError(
      localizeHelpers.translate('Shift end time cannot be before the start time.'),
      'Create Shift Error',
    );
    this.props.createToast(toast);
  }

  createShift() {
    let isCreate = true;
    let payload = this.generatePayload(isCreate);

    axios
      .post(swapRouteParams(routes.CREATE_SHIFT, { gigId: this.props.gigId }), payload)
      .then((response) => {
        this.props.onClose();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Create Shift');
        this.props.createToast(toast);
      });
  }

  handleDate(date: string | moment.Moment) {
    this.setState({
      shiftDate: moment(date),
    });
  }

  handleReoccurrenceDate(date: string | moment.Moment) {
    this.setState({
      endReoccurrance: date,
    });
  }

  handleHour(type: string, hour: string | moment.Moment) {
    if (type === 'start') {
      this.setState({
        startHour: hour,
      });
    } else {
      this.setState({
        endHour: hour,
      });
    }
  }

  toggleDay(day: number) {
    if (!this.state.weeklyDays.includes(day)) {
      let _days = [...this.state.weeklyDays];
      _days.push(day);

      this.setState({
        weeklyDays: _days,
      });
    } else {
      // do not let user to have empty weeklyDays
      if (this.state.weeklyDays.length === 1) return;

      for (let w in this.state.weeklyDays) {
        let _day = this.state.weeklyDays[w];

        if (_day === day) {
          let _days = [...this.state.weeklyDays];
          _days.splice(parseInt(w), 1);

          this.setState({
            weeklyDays: _days,
          });

          break;
        }
      }
    }
  }

  addWorker(worker: IListItem) {
    if (this.state.filteredWorkerList.length === MAX_SHIFT_CAPACITY) {
      const toast = toastError(
        localizeHelpers.translate('Maximum shift capacity of {{max_capacity}} workers reached', {
          max_capacity: MAX_SHIFT_CAPACITY,
        }),
        'Edit Shift',
      );
      this.props.createToast(toast);
      return;
    }

    let workers = this.state.filteredWorkerList.filter((app) => {
      return app.id !== worker.id;
    });

    workers.push(worker);

    this.setState({
      filteredWorkerList: workers,
    });

    if (this.state.num_people < workers.length.toString()) {
      this.setState({
        num_people: workers.length.toString(),
      });
    }
  }

  removeWorker(index: number) {
    let _workers = [...this.state.filteredWorkerList];

    _workers.splice(index, 1);

    this.setState({
      filteredWorkerList: _workers,
    });
  }

  render() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (this.isStartBeforeEnd()) {
            this.props.shift ? this.editShift() : this.createShift();
          }
        }}
        className="ShiftManage"
      >
        <div className="form-inner">
          <div className="form-row date-row">
            <label>Shift Date</label>
            <DateTimePicker
              inputProps={{ placeholder: 'Shift Date', readOnly: true }}
              className="custom-dtp"
              value={this.state.shiftDate}
              onChange={(date) => this.handleDate(date)}
              timeFormat={false}
              locale={this.props.locale}
            />
          </div>
          <div className="form-row time-row">
            <div className="start-time time-picker">
              <label>Start Time</label>
              <DateTime
                inputProps={{ label: 'Start Hour', placeholder: 'Start Hour', readOnly: true }}
                className="custom-dtp"
                timeFormat="hh:mm A"
                value={this.state.startHour}
                timeConstraints={{
                  minutes: {
                    min: 0,
                    max: 59,
                    step: 15,
                  },
                }}
                onChange={(value) => {
                  this.handleHour('start', value);
                }}
                dateFormat={false}
              />
            </div>
            <div className="end-time time-picker">
              <label>End Time</label>
              <DateTime
                inputProps={{ placeholder: 'Shift Date', readOnly: true }}
                className="custom-dtp"
                timeFormat="hh:mm A"
                value={this.state.endHour}
                timeConstraints={{
                  minutes: {
                    min: 0,
                    max: 59,
                    step: 15,
                  },
                }}
                onChange={(value) => {
                  this.handleHour('end', value);
                }}
                dateFormat={false}
              />
            </div>
          </div>
          {!this.props.shift?.id && (
            <div className="form-row reoccurring">
              <Dropdown
                value={this.state.reoccurrance}
                label="Is this a reoccurring shift?"
                name="reoccurrance"
                options={[
                  { label: 'No', value: 'no' },
                  { label: 'Daily', value: 'daily' },
                  { label: 'Weekly', value: 'weekly' },
                ]}
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
          )}
          {!this.props.shift?.id && this.state.reoccurrance === 'daily' && (
            <div className="reoccurrance daily">
              <div className="form-row inline">
                <TextField
                  label="Repeat every"
                  value={this.state.frequency}
                  name="frequency"
                  min="1"
                  type="number"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
                <span className="end">days</span>
              </div>
              <div className="select-row">
                <div className="select-label">Ends</div>
                <div className="select-options">
                  <div className="select-option">
                    <div
                      onClick={() => {
                        this.setState({ ends: 'on' });
                      }}
                      className="select-radio"
                    >
                      <i
                        className={this.state.ends === 'on' ? 'fas fa-circle' : 'fal fa-circle'}
                      ></i>
                      <span>On</span>
                    </div>
                    <div className="dbl-date-wrap">
                      <i className="fal fa-calendar"></i>
                      <DateTimePicker
                        className="custom-dtp"
                        value={this.state.endReoccurrance}
                        onChange={(date) => this.handleReoccurrenceDate(date)}
                        timeFormat={false}
                        locale={this.props.locale}
                      />
                    </div>
                  </div>
                  <div className="select-option">
                    <div
                      onClick={() => {
                        this.setState({ ends: 'after' });
                      }}
                      className="select-radio"
                    >
                      <i
                        className={this.state.ends === 'after' ? 'fas fa-circle' : 'fal fa-circle'}
                      ></i>
                      <span>After</span>
                    </div>
                    <TextField
                      value={this.state.occurrence}
                      name="occurrence"
                      min="1"
                      type="number"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                    <span className="end">occurrences</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!this.props.shift?.id && this.state.reoccurrance === 'weekly' && (
            <div className="reoccurrance weekly">
              <div className="form-row inline">
                <TextField
                  label="Repeat every"
                  value={this.state.frequency}
                  name="frequency"
                  min="1"
                  type="number"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
                <span className="end">weeks</span>
              </div>
              <div className="select-row">
                <div className="select-label">Ends</div>
                <div className="select-options">
                  <div className="select-option">
                    <div
                      onClick={() => {
                        this.setState({ ends: 'on' });
                      }}
                      className="select-radio"
                    >
                      <i
                        className={this.state.ends === 'on' ? 'fas fa-circle' : 'fal fa-circle'}
                      ></i>
                      <span>On</span>
                    </div>
                    <div className="dbl-date-wrap">
                      <i className="fal fa-calendar"></i>
                      <DateTimePicker
                        className="custom-dtp"
                        value={this.state.endReoccurrance}
                        onChange={(date) => this.handleReoccurrenceDate(date)}
                        timeFormat={false}
                        locale={this.props.locale}
                      />
                    </div>
                  </div>
                  <div className="select-option">
                    <div
                      onClick={() => {
                        this.setState({ ends: 'after' });
                      }}
                      className="select-radio"
                    >
                      <i
                        className={this.state.ends === 'after' ? 'fas fa-circle' : 'fal fa-circle'}
                      ></i>
                      <span>After</span>
                    </div>
                    <TextField
                      value={this.state.occurrence}
                      name="occurrence"
                      min="1"
                      type="number"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                    <span className="end">occurrences</span>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="weekly-days">
                  <span className="label">Repeat on</span>
                  <ul className="week-buttons">
                    <li
                      className={
                        this.state.weeklyDays.includes(0) ? 'active no-select' : 'no-select'
                      }
                      onClick={() => {
                        this.toggleDay(0);
                      }}
                    >
                      Sunday
                    </li>
                    <li
                      className={
                        this.state.weeklyDays.includes(1) ? 'active no-select' : 'no-select'
                      }
                      onClick={() => {
                        this.toggleDay(1);
                      }}
                    >
                      Monday
                    </li>
                    <li
                      className={
                        this.state.weeklyDays.includes(2) ? 'active no-select' : 'no-select'
                      }
                      onClick={() => {
                        this.toggleDay(2);
                      }}
                    >
                      Tuesday
                    </li>
                    <li
                      className={
                        this.state.weeklyDays.includes(3) ? 'active no-select' : 'no-select'
                      }
                      onClick={() => {
                        this.toggleDay(3);
                      }}
                    >
                      Wednesday
                    </li>
                    <li
                      className={
                        this.state.weeklyDays.includes(4) ? 'active no-select' : 'no-select'
                      }
                      onClick={() => {
                        this.toggleDay(4);
                      }}
                    >
                      Thursday
                    </li>
                    <li
                      className={
                        this.state.weeklyDays.includes(5) ? 'active no-select' : 'no-select'
                      }
                      onClick={() => {
                        this.toggleDay(5);
                      }}
                    >
                      Friday
                    </li>
                    <li
                      className={
                        this.state.weeklyDays.includes(6) ? 'active no-select' : 'no-select'
                      }
                      onClick={() => {
                        this.toggleDay(6);
                      }}
                    >
                      Saturday
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          <div className="form-row time-row">
            <TextField
              label="Capacity"
              value={this.state.num_people}
              name="num_people"
              type="number"
              max={uiConstants.maxShiftCapacity}
              min={uiConstants.minShiftCapacity}
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
          </div>
          {!this.props.shift?.id && (
            <div className="form-row">
              <SearchList
                onItemClick={(item: IListItem) => {
                  this.addWorker(item);
                }}
                list={this.props.workers}
                label="Add Volunteers/Workers"
                notranslate="yes"
              />
              <div className="workers">
                {this.state.filteredWorkerList.map((worker, index) => {
                  return (
                    <div
                      key={index}
                      className="worker"
                    >
                      <Portrait
                        size={30}
                        currentImage={worker.image || ''}
                      />
                      <span>{worker.label}</span>
                      <i
                        onClick={(e) => {
                          this.removeWorker(index);
                        }}
                        className="fal fa-times"
                      ></i>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="actions">
          <Button
            type="submit"
            text={this.props.shift?.id ? 'Apply' : 'Create Shift'}
          />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    userState: store.userState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createShift,
  createToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShiftManage));
