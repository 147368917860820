import React from 'react';
import { combineClassNames } from '../../../helpers';
import './Banner.scss';

export type BannerType = 'success' | 'failure' | 'info' | 'warning';

interface IProps {
  className?: string;
  type: BannerType;
  title: string;
  content: string;
  actionButton?: {
    buttonText: string;
    onClick: () => void;
  };
}

const icons: Record<BannerType, string> = {
  success: 'fas fa-check-circle',
  warning: 'fas fa-exclamation-circle',
  failure: 'fas fa-times-circle',
  info: 'fas fa-info-circle',
};

/** Creates a Banner
 * - Should be used for alerts on pages or calling attention to something.
 */
export const Banner: React.FC<IProps> = (props) => {
  return (
    <div className={combineClassNames(props.className, 'Banner', props.type)}>
      <i className={combineClassNames('icon', icons[props.type])} />

      <div className="title">{props.title}</div>

      <div className="content">{props.content}</div>

      {props.actionButton && (
        <button
          className="action-button"
          onClick={props.actionButton.onClick}
        >
          {props.actionButton.buttonText}
        </button>
      )}
    </div>
  );
};
