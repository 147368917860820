import { Reducer } from 'redux';
import { CartActions, CartActionTypes } from '../actions/cart';

export interface ICartState {
  items: any[];
  lastItem: any;
  totalItems: number;
  total: any;
  parentType: 'event' | 'group' | 'hub' | '';
  error: string;
}

export const initialCartState: ICartState = {
  items: [],
  lastItem: {},
  totalItems: 0,
  parentType: '',
  total: {
    amount: 0,
    currency: 'cad',
    discount: 0,
    gigit_fee: 0,
    gigit_fee_percent: 0,
    payment_platform_fee: 0,
    tip: 0,
    total: 0,
    total_application_fee: 0,
  },
  error: '',
};

export const cartReducer: Reducer<ICartState, CartActions> = (state = initialCartState, action) => {
  switch (action.type) {
    case CartActionTypes.UPDATE_CART: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
