import React, { FC, Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './CardCommonHub.scss';
import { CardCommon, CardHeader, CardContent, CardActions } from '../../components/CardCommon';
import { IHub } from '@gigit/interfaces';
import { IAppState } from '../../store';
import Portrait from '../Portrait/Portrait';
import editImg from '../../assets/edit.svg';
import settingsImg from '../../assets/settings.svg';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../selectors/user';
import useToastDispatcher from '../../hooks/useToaster';
import { hubRequestActions, userRequestActions } from '../../requestActions';
import { Constants } from '@gigit/constants';
import Modal from '../Modal/Modal';
import Share from '../Share/Share';
import { typeHelpers, capitalizeString, OwnerType } from '../../helpers';
import { Config } from '@gigit/config';
import { uiConstants } from '../../constants';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import PopupMenu, { IPopupMenuItem } from '../shared/PopupMenu/PopupMenu';
import { CardCommonHubSkeleton } from './CardCommonHubSkeleton';

export enum EViewTypeCardCommonHub {
  GRID,
  LIST,
}

export enum EStyleTypeCardCommonHub {
  MANAGE,
  DISCOVER,
}

interface IProps {
  hub: IHub;
  isUserLoggedIn?: boolean;
  viewType: EViewTypeCardCommonHub;
  styleType: EStyleTypeCardCommonHub;
  locale?: string;
  scheduleMenuItems?: IPopupMenuItem[];
  canManage?: boolean;
  canEdit?: boolean;
  loading?: boolean;
  getIsFollowing?: boolean;
  followItem?: () => void;
  deleteItem?: () => void;
}

export const CardCommonHub: FC<IProps> = (props: IProps) => {
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { hub } = props;
  const [userLanguage, setUserLanguage] = useState<string>(user.language ?? 'en');
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const history = useHistory();

  const menuItems: IPopupMenuItem[] =
    props.styleType === EStyleTypeCardCommonHub.MANAGE
      ? [
          {
            id: 'share',
            label: 'Share',
            onClick: () => {
              handleShare();
            },
          },
          {
            id: 'delete',
            label: 'Delete',
            onClick: () => {
              props?.deleteItem?.();
            },
          },
        ]
      : props.scheduleMenuItems || [];

  useEffect(() => {
    if (isUserLoggedIn && props.getIsFollowing) {
      doesUserFollowHub(`${hub.id}`).then((res) => setIsFollowing(res));
    }
  }, [props.hub]);

  useEffect(() => {
    if (user.language) {
      setUserLanguage(user.language);
    }
  }, [user.language]);

  async function doesUserFollowHub(hubId: string): Promise<boolean> {
    if (user?.id) {
      try {
        const result = await userRequestActions.getUserHubRole(hubId);

        return result?.user_capacity
          ? result?.user_capacity.includes(Constants.user_capacity.follower)
          : false;
      } catch (error) {
        dispatchToastError(error, 'Does User Follow Hub');
      }
    }

    return false;
  }

  async function toggleFollow(hubId: string) {
    if (user?.id) {
      const isFollowing = await doesUserFollowHub(hubId);

      try {
        if (isFollowing) {
          await hubRequestActions.userUnfollowHub(user.id, hubId);
          setIsFollowing(false);

          dispatchToastSuccess('Successfully unfollowed Hub', 'Unfollow Hub');
        } else {
          await hubRequestActions.userFollowHub(user.id, hubId);
          setIsFollowing(true);

          dispatchToastSuccess('Successfully followed Hub', 'Follow Hub');
        }
      } catch (error) {
        dispatchToastError(error, `${isFollowing ? 'Unfollow Hub' : 'Follow Hub'}`);
      }
    } else {
      history.push('/login');
    }
  }

  const handleShare = () => {
    user?.id ? setShowShareModal(true) : history.push('/login');
  };

  const renderDiscoverContent = () => {
    return (
      <CardContent className="hub-content">
        <div className="card-title">
          <Link
            notranslate="yes"
            to={`/company/${hub.handle}`}
          >
            {hub.title}
          </Link>
        </div>
        <div className="hub-causes">
          <div className="causes-container">
            {hub.groups_involved &&
              hub.groups_involved.map((group, index) => {
                if (index < 4) {
                  return (
                    <Portrait
                      size={30}
                      key={index}
                      currentImage={group.profile_image_url}
                    />
                  );
                }

                return <Fragment key={index} />;
              })}
            {hub.groups_involved && hub.groups_involved.length > 4 && (
              <span className="empty-portrait">
                <var data-var="groups_involved_length">+{hub.groups_involved.length - 4}</var>
              </span>
            )}
            {hub.groups_involved?.length === 0 &&
              [1, 2, 3, 4, 5].map((e) => {
                return <div className="no-causes" />;
              })}
          </div>
          <span className="title">
            {hub.groups_involved?.length !== 0 ? 'Causes' : 'No causes'}
          </span>
        </div>
      </CardContent>
    );
  };

  const renderManageContent = () => {
    return (
      <CardContent className={`hub-content ${EStyleTypeCardCommonHub[props.styleType]}`}>
        <div className="card-title">
          <Link
            notranslate="yes"
            to={`/company/${hub.handle}`}
          >
            {hub.title}
          </Link>
        </div>
        {(props.canEdit || props.canManage) && (
          <div className="header-menu">
            {props.canEdit && (
              <div
                onClick={() =>
                  history.replace({ pathname: `/company/${hub.handle}`, state: 'editTrue' })
                }
              >
                <img src={editImg}></img>
              </div>
            )}
            {props.canManage && (
              <div onClick={() => history.replace({ pathname: `/company/${hub.handle}/admin` })}>
                <img src={settingsImg}></img>
              </div>
            )}
          </div>
        )}
        <PopupMenu
          showMenuConfig={{
            showMenu: showMenu,
            setShowMenu: setShowMenu,
            position: {
              type: 'bottom',
            },
          }}
          menuItems={menuItems}
          popupMenuClass="PopupMenu-Volunteer-Card"
          className={`menu ${showMenu ? 'show' : ''}`}
          onClick={() => setShowMenu(!showMenu)}
          onSelect={() => setShowMenu(false)}
        >
          <i className="fal fa-ellipsis-v" />
        </PopupMenu>
        <div className="created-date">
          Created at{' '}
          <var data-var="created_at">
            {localizeHelpers.formatDate(
              `${hub?.created_at}`,
              LocaleDateFormats.ll,
              props.locale ? props.locale : '',
            )}
          </var>
        </div>
        <span className={`state-label ${hub.status}`}>
          {capitalizeString(`${hub.status}`)?.replace('_', ' ')}
        </span>
        <div className="hub-causes">
          <div className="causes-container">
            {hub.groups_involved &&
              hub.groups_involved.map((group, index) => {
                if (index < 4) {
                  return (
                    <Portrait
                      size={30}
                      key={index}
                      currentImage={group.profile_image_url}
                    />
                  );
                }

                return <Fragment key={index} />;
              })}
            {hub.groups_involved && hub.groups_involved.length > 4 && (
              <span className="empty-portrait">
                <var data-var="groups_involved_length">+{hub.groups_involved.length - 4}</var>
              </span>
            )}
          </div>
        </div>
        <div className="one-line"></div>
      </CardContent>
    );
  };

  return (
    <React.Fragment>
      {!props.loading ? (
        <CardCommon
          className={`hub-container ${EStyleTypeCardCommonHub[props.styleType]} ${EViewTypeCardCommonHub[props.viewType]}`}
        >
          <CardHeader
            profileImg={hub.profile_image_url}
            coverImg={hub.banner?.image}
          />

          {props.styleType === EStyleTypeCardCommonHub.DISCOVER && renderDiscoverContent()}
          {props.styleType === EStyleTypeCardCommonHub.MANAGE && renderManageContent()}
          {props.styleType === EStyleTypeCardCommonHub.DISCOVER && (
            <CardActions
              className={`hub-actions ${userLanguage}`}
              actions={[
                {
                  label: `${isFollowing ? 'Unfollow' : 'Follow'}`,
                  icon: 'far fa-level-down-alt',
                  visibility: true,
                  onClick: () => toggleFollow(`${hub.id}`),
                },
                {
                  label: 'Share',
                  icon: 'fas fa-share-alt',
                  visibility: true,
                  onClick: () => handleShare(),
                },
              ]}
            />
          )}
        </CardCommon>
      ) : (
        <CardCommonHubSkeleton
          viewType={props.viewType}
          styleType={props.styleType}
        />
      )}

      <Modal
        class="share-modal"
        show={showShareModal}
        onClose={() => setShowShareModal(false)}
      >
        <Share
          owner_object={typeHelpers.createOwnerObject(uiConstants.ownerType.hub as OwnerType, hub)}
          url={`${Config.web.REACT_APP_BASE_URL}/${uiConstants.ownerType.hub}/${hub.handle}`}
        />
      </Modal>
    </React.Fragment>
  );
};
