import React, { ReactElement } from 'react';
import './LandingDiscoverHeader.scss';
import { Link } from 'react-router-dom';
import {
  HorizontalScroller,
  IHorizontalScrollerRenderProps,
} from '../shared/HorizontalScroller/HorizontalScroller';

interface IProps {
  pathname: string;
  isUserLoggedIn: boolean;
}

const LandingDiscoverHeader: React.FC<IProps> = (props) => {
  const isHeaderActive = (value: string): boolean => {
    return (
      props.pathname === value ||
      (value === '/discover/events' && props.pathname === '/' && !props.isUserLoggedIn)
    );
  };

  const links = [
    {
      to: '/',
      label: 'Home',
      show: props.isUserLoggedIn,
    },
    {
      to: '/discover/events',
      label: 'Events & Fundraisers',
      show: true,
    },
    {
      to: '/discover/volunteers',
      label: 'Volunteering',
      show: true,
    },
    {
      to: '/discover/groups',
      label: 'Causes',
      show: true,
    },
    {
      to: '/discover/companies',
      label: 'Companies',
      show: true,
    },
  ] as { to: string; label: string; show: boolean }[];

  const renderSingleLink = (index: number, to: string, label: string) => {
    return (
      <li key={index}>
        <Link
          className={`subheader-item ${isHeaderActive(to) ? 'active' : ''}`}
          to={to}
        >
          {label}
        </Link>
      </li>
    );
  };

  const renderItems = (scrollerProps: IHorizontalScrollerRenderProps): ReactElement[] => {
    return links.map((link, index) => {
      if (link.show) {
        return renderSingleLink(index, link.to, link.label);
      }

      return (
        <li
          key={index}
          style={{ display: 'none' }}
        />
      );
    });
  };

  return (
    <div className="LandingDiscoverHeader">
      <div className="discover-subheader">
        <div className="subheader-section">
          {links.map((link, index) => {
            if (link.show) {
              return renderSingleLink(index, link.to, link.label);
            }
          })}
        </div>
        <div className="subheader-section-mobile">
          <HorizontalScroller>{(scrollerProps) => renderItems(scrollerProps)}</HorizontalScroller>
        </div>
      </div>
    </div>
  );
};

export default LandingDiscoverHeader;
