import React, { FC, useState } from 'react';
import './../../../../Scheduler/Scheduler.scss';
import {
  IAttendingEventSummary,
  IShiftUserSummary,
  IGigSummaryFE,
  IApplication,
  IUser,
} from '@gigit/interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../../../localizeHelpers';
import { IAppState } from '../../../../../store';
import { userSelectors } from '../../../../../selectors/user';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Constants } from '@gigit/constants';
import eventIcon from '../../../../../assets/event-icon-masks.svg';
import groupIcon from '../../../../../assets/people-logo-dark.svg';

import { uiConstants } from '../../../../../constants';
import QuillTextEditor from '../../../../QuillTextEditor/QuillTextEditor';
import PopupMenu, { IPopupMenuItem } from '../../../../shared/PopupMenu/PopupMenu';
import { gigRequestActions } from '../../../../../requestActions';
import Button from '../../../../Button/Button';
import ReportHoursModal from '../../../../ReportHoursModal/ReportHoursModal';
import { errorHelpers, toastError, typeHelpers } from '../../../../../helpers';
import useToastDispatcher from '../../../../../hooks/useToaster';

interface IProps {
  selected: IGigSummaryFE | null;
  applicants: IApplication[];
  onUpdate(): void;
}

// TODO: Add the agenda types in constants and use the constants in checks instead of strings.
interface IAgendaShiftSummary extends IShiftUserSummary {
  type: string;
}

interface IAgendaEventSummary extends IAttendingEventSummary {
  type: string;
}

interface ScheduleListSummary {
  day: string;
  date: string;
  dateMs: number;
  list: (IAgendaEventSummary | IAgendaShiftSummary)[];
}

interface IDropdownOption {
  label: string;
  value: string;
}

interface ITypeDropdownOptions {
  all: IDropdownOption;
  shifts: IDropdownOption;
  events: IDropdownOption;
}

interface ContactData {
  phone: string;
  email: string;
}

const Interested: FC<IProps> = (props: IProps) => {
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const [showVolunteerMenu, setShowVolunteerMenu] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [showLogHourModal, setShowLogHourModal] = useState<boolean>(false);
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const history = useHistory();

  const owner = props.selected?.event
    ? {
        title: props.selected.event.title,
        handle: props.selected.event.handle,
        icon: eventIcon,
        type: Constants.object_type.event,
      }
    : ({
        title: props.selected?.group?.title || props.selected?.group_title,
        handle: props.selected?.group?.handle || props.selected?.group_handle,
        icon: groupIcon,
        type: Constants.object_type.group,
      } as {
        title: string;
        handle: string;
        icon: string;
        type: string;
      });

  const getApplicationCard = (application: IApplication, index: number) => {
    const volunteerMenuItems: IPopupMenuItem[] = [
      {
        id: '0',
        label: 'Mark as Interested',
        hide: application.is_interested && !application.is_confirmed,
        isSelected: false,
        onClick: async () => {
          await gigRequestActions.setVolunteerFlag(
            props.selected?.id || '',
            application.user?.id || '',
            'is_confirmed',
            false,
          );
          props.onUpdate();
        },
      },
      /*
       *Log hours modal should match in manage page and in modal but hide this one for now until we see how users are using what we have 
      {
        id: "1",
        label: "Log Hours",
        isSelected: false,
        onClick: () => {
          setSelectedUser(application.user)
          setShowLogHourModal(true)          
        },
      },
      */
      {
        id: '2',
        label: 'Message',
        isSelected: false,
        onClick: () => {
          history.push(`/dashboard?tab=network&contact=${application.user_id}&networkTab=messages`);
        },
      },
    ];

    const handleConfirm = async () => {
      try {
        typeHelpers.assertNotNullOrUndefined(
          application?.gig_id,
          'Expected Volunteer Opportunity ID',
        );
        typeHelpers.assertNotNullOrUndefined(application.user_id, 'Expected User ID');
        await gigRequestActions.setVolunteerFlag(
          application?.gig_id,
          application.user_id,
          'is_confirmed',
          true,
        );
        props.onUpdate();
      } catch (error) {
        dispatchToastError(error, 'Volunteer Confirm');
      }
    };

    return (
      <div
        key={index}
        className="application-card"
      >
        <div className="card-label">
          <div className="icon">
            {
              <a href="/gig/1000012">
                <img
                  src={application?.user?.profile_image_url}
                  alt="profile avatar"
                  className="logo"
                />
              </a>
            }
          </div>
          <div className="labels">
            <span className="table-link">
              {application.user?.first_name} {application.user?.last_name}
            </span>
          </div>
        </div>
        <span
          className={`status ${application?.is_confirmed ? 'Confirmed' : application?.is_interested ? 'Interested' : ''}`}
        >
          {application?.is_interested && !application?.is_confirmed
            ? 'Interested'
            : application?.is_confirmed
              ? 'Confirmed'
              : ''}
        </span>

        <div className="vol-button">
          {application?.is_interested && !application?.is_confirmed ? (
            <Button
              text="Confirm"
              onClick={handleConfirm}
            />
          ) : (
            ''
          )}
        </div>

        <PopupMenu
          key={index}
          showMenuConfig={{
            showMenu: showVolunteerMenu === application?._id,
            setShowMenu: (isTrue) => {
              setShowVolunteerMenu(isTrue ? application?._id || '' : '');
            },
            position: {
              type: 'bottom',
              alternate: 'start',
              additionalLeft: 50,
            },
          }}
          menuItems={volunteerMenuItems}
          popupMenuClass="volunteer-menu"
          className={`${showVolunteerMenu === application?._id ? 'show' : ''}`}
          onClick={(event: { stopPropagation: () => void }) => {
            event.stopPropagation();
            setShowVolunteerMenu(application?._id || '');
          }}
        >
          <div className="menu">
            <i className="fas fa-ellipsis-v" />
          </div>
        </PopupMenu>
      </div>
    );
  };
  return (
    <div>
      {showLogHourModal && (
        <ReportHoursModal
          byAdmin={true}
          objectType={Constants.object_type.user}
          selectedGig={props.selected}
          selectedUser={selectedUser}
          show={showLogHourModal}
          onClose={() => {
            setShowLogHourModal(!showLogHourModal);
          }}
          onSubmit={() => {}}
        />
      )}
      <div className="list-view">
        {props.applicants?.map((application, index) => getApplicationCard(application, index))}
      </div>
    </div>
  );
};

export default Interested;
