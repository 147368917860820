import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ICampaign, IPage, IPageComponent, IEventIndividual, IEventTeam } from '@gigit/interfaces';

import { IStringMap, handleInputChange, toastError, handleDebounce } from '../../../helpers';
import { IEventState } from '../../../reducers/event';

import { createToast } from '../../../actions/toaster';
import {
  createEventTeam,
  getEventIndividuals,
  getEventTeams,
  searchEventTeams,
  searchEventIndividuals,
  updateEventPageComponent,
} from '../../../actions/event';

import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import TextField from '../../TextField/TextField';
import FundraiserItem from '../FundraiserItem/FundraiserItem';

import './Fundraiser.scss';
import PageComponentPaginator from '../shared/PageComponentPaginator/PageComponentPaginator';
import { ReduxActionType } from '../../../interfaces';
import { IOwnerObject } from '../../../interfaces';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  owner: IOwnerObject;
  component: IPageComponent;
  page: IPage;
  eventState: IEventState;
  permissions?: IStringMap;
  searchEventTeams: ReduxActionType<typeof searchEventTeams>;
  searchEventIndividuals: ReduxActionType<typeof searchEventIndividuals>;
  createEventTeam: ReduxActionType<typeof createEventTeam>;
  getEventIndividuals: ReduxActionType<typeof getEventIndividuals>;
  getEventTeams: ReduxActionType<typeof getEventTeams>;
  createToast: ReduxActionType<typeof createToast>;
  edit: boolean;
  updateEventPageComponent: ReduxActionType<typeof updateEventPageComponent>;
  hasEditPermissions?: boolean;
  campaign?: ICampaign | null;
}

interface IState {
  showCreateTeamModal: boolean;
  teamName: string;
  teamGoal: string;
  searchValue: string;
  limit: number;
  title: string;
  teamPageInfo: string;
  individualPageInfo: string;
}

class Fundraiser extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showCreateTeamModal: false,
      teamName: '',
      teamGoal: '0',
      searchValue: '',
      limit: 8,
      teamPageInfo: '',
      individualPageInfo: '',
      title: this.props.component.title || 'Fundraiser Leaderboard',
    };

    this.toggleCreateTeaModal = this.toggleCreateTeaModal.bind(this);
    this.createTeam = this.createTeam.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.props.getEventTeams(this.props.owner.ownerId);
    this.props.getEventIndividuals(this.props.owner.ownerId);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.eventState.isCreatingTeam && !this.props.eventState.isCreatingTeam) {
      if (this.props.eventState.teamSuccess && this.props.eventState.teamError === '') {
        this.toggleCreateTeaModal(false);
      } else {
        const toast = toastError(this.props.eventState.teamError, 'Fundraiser Error');
        this.props.createToast(toast);
      }
    }

    if (prevProps.edit && !this.props.edit) {
      this.update();
    }
  }

  update() {
    const payload = this.props.component;

    payload.title = this.state.title;

    if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
      this.props.updateEventPageComponent(
        this.props.eventState.event.id,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    }
  }

  toggleCreateTeaModal(value: boolean) {
    this.setState({
      showCreateTeamModal: value !== undefined ? value : !this.state.showCreateTeamModal,
      teamName: !this.state.showCreateTeamModal ? '' : this.state.teamName,
      teamGoal: !this.state.showCreateTeamModal ? '0' : this.state.teamGoal,
    });
  }

  createTeam() {
    this.props.createEventTeam(this.props.owner.ownerId, {
      name: this.state.teamName,
      goal: parseFloat(this.state.teamGoal),
    });
  }

  search() {
    this.props.getEventTeams(this.props.owner.ownerId, 0, 0, this.state.searchValue);
    this.props.getEventIndividuals(this.props.owner.ownerId, 0, 0, this.state.searchValue);
  }

  render() {
    return (
      <div className="Fundraiser">
        <div className="title">
          {this.props.edit && (
            <TextField
              value={this.state.title}
              name="title"
              type="text"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
          )}
          {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}
        </div>
        <div className="search">
          <TextField
            icon="fa fa-search"
            required={true}
            value={this.state.searchValue}
            name="searchValue"
            type="text"
            onChange={(e) => {
              handleInputChange(e, this, false, () => {
                handleDebounce(this.state.searchValue).then((res) => {
                  this.search();
                });
              });
            }}
          />
        </div>
        <div className="columns">
          <div className="column individuals">
            <div className="top-wrap">
              <div className="title-container">
                <span className="title">Top Individuals</span>
                <span
                  className="page-info"
                  notranslate="yes"
                >
                  {this.state.individualPageInfo}
                </span>
              </div>
              {this.props.eventState.individuals.length === 0 && this.state.searchValue === '' && (
                <div className="empty">This event doesn't have any individuals at the moment.</div>
              )}
              {this.props.eventState.individuals.length === 0 && this.state.searchValue !== '' && (
                <div className="empty">
                  Search term "{this.state.searchValue}" returned 0 results for individuals.
                </div>
              )}
              <PageComponentPaginator
                itemsPerPage={this.state.limit}
                items={this.props.eventState.individuals}
              >
                {(itemsToRender, pageIndexOffset, currentPageString) => {
                  // TODO: Should not be calling `this.setState({  })` inside a render loop...
                  if (this.state.individualPageInfo !== currentPageString) {
                    this.setState({ individualPageInfo: currentPageString });
                  }
                  return itemsToRender.map((item: IEventIndividual, index: number) => {
                    return (
                      <FundraiserItem
                        key={item.id}
                        {...this.props}
                        fundraiserItemType="individual"
                        fundraiserItem={item}
                      />
                    );
                  });
                }}
              </PageComponentPaginator>
            </div>
          </div>
          <div className="column teams">
            <div className="top-wrap">
              <div className="title-container">
                <span className="title">Top Teams</span>
                <span
                  className="page-info"
                  notranslate="yes"
                >
                  {this.state.teamPageInfo}
                </span>
              </div>
              {this.props.eventState.teams.length === 0 && this.state.searchValue === '' && (
                <div className="empty">This event doesn't have any teams at the moment.</div>
              )}
              {this.props.eventState.teams.length === 0 && this.state.searchValue !== '' && (
                <div className="empty">
                  Search term "{this.state.searchValue}" returned 0 results for teams.
                </div>
              )}
              <PageComponentPaginator
                itemsPerPage={this.state.limit}
                items={this.props.eventState.teams}
              >
                {(itemsToRender, pageIndexOffset, currentPageString) => {
                  if (this.state.teamPageInfo !== currentPageString) {
                    this.setState({ teamPageInfo: currentPageString });
                  }
                  return itemsToRender.map((item: IEventTeam, index: number) => {
                    return (
                      <FundraiserItem
                        key={item.id}
                        {...this.props}
                        fundraiserItemType="team"
                        fundraiserItem={item}
                      />
                    );
                  });
                }}
              </PageComponentPaginator>
            </div>
          </div>
        </div>

        {/* TODO: Not used? should probably be removed? */}
        <Modal
          show={this.state.showCreateTeamModal}
          onClose={() => {
            this.toggleCreateTeaModal(false);
          }}
          title="Create a Team"
        >
          <div className="create-modal">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.createTeam();
              }}
            >
              <div className="row">
                <TextField
                  required={true}
                  label="Team Name"
                  value={this.state.teamName}
                  name="teamName"
                  type="text"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
              </div>
              <div className="row">
                <TextField
                  required={true}
                  label="Team Goal ($)"
                  value={this.state.teamGoal}
                  name="teamGoal"
                  type="number"
                  min="1"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
              </div>
              <div className="form-actions">
                <Button
                  type="submit"
                  text="Create"
                />
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {
  createEventTeam,
  getEventTeams,
  getEventIndividuals,
  createToast,
  searchEventTeams,
  searchEventIndividuals,
  updateEventPageComponent,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(Fundraiser),
);
