import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import {
  defaultCurrency,
  handleInputChange,
  uploadImage,
  sortColumn,
  formatQuery,
} from '../../helpers';
import SortableTable from '../SortableTable/SortableTable';
import moment from 'moment';

import TextField from '../TextField/TextField';
import ContextMenu from '../ContextMenu/ContextMenu';

import { IGroupState } from '../../reducers/group';

import { getGroupSponsorshipSettings, updateGroupSponsorshipSettings } from '../../actions/group';
import { ISponsor, ISponsorshipSettings, ISponsorshipTierSettings } from '@gigit/interfaces';
import Portrait from '../Portrait/Portrait';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';

import './SponsorshipSettingsManagement.scss';
import { formatCurrency } from '../../helpers';
import { Constants } from '@gigit/constants';
import { userSelectors } from '../../selectors/user';
import { ISortSettings, ReduxActionType } from '../../interfaces';

const tierDefaultNames: { [index: number]: string } = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Fourth',
  5: 'Fifth',
};

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  locale: string;

  getGroupSponsorshipSettings: ReduxActionType<typeof getGroupSponsorshipSettings>;
  updateGroupSponsorshipSettings: ReduxActionType<typeof updateGroupSponsorshipSettings>;
}

interface IState {
  showEditTier: boolean;
  editTier: ISponsorshipTierSettings | null;
  sort: ISortSettings[];
}

class SponsorshipSettingsManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showEditTier: false,
      editTier: null,
      sort: [],
    };
  }

  componentDidMount() {
    this.fetchSponsorshipSettings();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.sort !== this.state.sort) {
      this.fetchSponsorshipSettings();
    }
  }

  saveTier() {
    if (this.state.editTier) {
      const updatePayload: Partial<ISponsorshipSettings> = {
        ...(this.props.groupState.groupSponsorshipSettings ?? {}),
      };
      const index =
        updatePayload.tiers?.findIndex((tier) => tier.tier === this.state.editTier!.tier) ?? -1;

      if (updatePayload.tiers && index !== -1) {
        updatePayload.tiers[index] = this.state.editTier;
        this.props.updateGroupSponsorshipSettings(this.props.groupState.group.id, updatePayload);
      }
    }

    this.setState({
      showEditTier: false,
    });
  }

  handleTierInputChange(fieldName: string, value: any) {
    this.setState({
      editTier: {
        ...this.state.editTier!,
        [fieldName]: value,
      },
    });
  }

  fetchSponsorshipSettings() {
    this.props.getGroupSponsorshipSettings(
      this.props.groupState.group.id,
      formatQuery({
        sort: this.state.sort,
      }),
    );
  }

  render() {
    const { t } = this.props;

    const currency = this.props.groupState.group.account?.currency ?? defaultCurrency;

    return (
      <div className="section-wrap SponsorshipSettingsManagement">
        <div className="section-title">
          <div className="title-wrap">{t('Sponsors')}</div>
          <div className="SponsorshipSettingsManagement-title-actions">
            {/* TODO: Add actions...? */}
          </div>
        </div>
        <div className="section-inner">
          <div className="SponsorshipSettingsManagement">
            <div className="SponsorshipSettingsManagement-list">
              <div className="list">
                <div className="list-inner">
                  <div className="list-rows">
                    {this.props.groupState.groupSponsorshipSettings &&
                      this.props.groupState.groupSponsorshipSettings.tiers && (
                        <SortableTable
                          {...this.props}
                          onSort={(sortSettings) => this.setState({ sort: sortSettings })}
                          columns={[
                            { id: 'tier', label: 'Tier No.', sortable: false },
                            { id: 'tier_name', label: 'Tier Name', sortable: false },
                            { id: 'tier_price', label: 'Tier Price', sortable: false },
                            { id: 'actions', label: 'Actions', sortable: false },
                          ]}
                          data={this.props.groupState.groupSponsorshipSettings?.tiers?.map(
                            (item, index) => {
                              return {
                                row: [
                                  {
                                    id: 'tier',
                                    content: <span>{tierDefaultNames[item.tier]}</span>,
                                  },
                                  {
                                    id: 'tier_name',
                                    content: <span notranslate="yes">{item.tier_name}</span>,
                                  },
                                  {
                                    id: 'tier_price',
                                    content: (
                                      <span notranslate="yes">
                                        {formatCurrency(
                                          item.tier_price,
                                          currency,
                                          this.props.locale,
                                        )}
                                      </span>
                                    ),
                                  },
                                  {
                                    id: 'actions',
                                    menu: [
                                      {
                                        label: 'Edit',
                                        icon: 'fal fa-edit',
                                        onClick: () =>
                                          this.setState({ showEditTier: true, editTier: item }),
                                      },
                                    ],
                                  },
                                ],
                              };
                            },
                          )}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showEditTier && this.state.editTier && (
          <Modal
            title="Edit Sponsorship"
            show={this.state.showEditTier}
            onClose={() => this.setState({ showEditTier: false })}
          >
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                this.saveTier();
              }}
              className="edit-sponsorship-modal"
            >
              <div className="form-inputs">
                <div className="row">
                  <TextField
                    type="text"
                    onChange={(e: any) => {
                      this.handleTierInputChange(e.target.name, e.target.value);
                    }}
                    name="tier_name"
                    value={`${this.state.editTier.tier_name}`}
                    required={true}
                    label={t('Tier Name')}
                  />
                </div>

                <div className="row">
                  <TextField
                    type="number"
                    onChange={(e: any) => {
                      this.handleTierInputChange(e.target.name, e.target.value);
                    }}
                    name="tier_price"
                    value={`${this.state.editTier.tier_price}`}
                    required={true}
                    label={t('Tier Price')}
                  />
                </div>
              </div>
              <div className="form-actions">
                <Button
                  type="button"
                  buttonClass="cancel-button"
                  text="Cancel"
                  onClick={() => this.setState({ showEditTier: false })}
                />
                <Button
                  type="submit"
                  className="save-button"
                  text="Apply Changes"
                />
              </div>
            </form>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getGroupSponsorshipSettings,
  updateGroupSponsorshipSettings,
};

export default withRouter(
  withTranslation('translations')(
    connect(mapStateToProps, mapDispatchToProps)(SponsorshipSettingsManagement),
  ),
);
