import axios from 'axios';
import { Dispatch } from 'redux';
import { IUserSearchState } from '../reducers/userSearch';
import { routes, swapRouteParams } from '../helpers';

export enum UserSearchActionTypes {
  SEARCH_USER = 'SEARCH USER',
  RESET_USER_SEARCH = 'RESET_USER_SEARCH',
}

export interface IUserSearchAction extends IUserSearchState {
  type: UserSearchActionTypes.SEARCH_USER;
  isUpdateLoading: boolean;
}

export interface IResetUserSearchAction extends IUserSearchState {
  type: UserSearchActionTypes.RESET_USER_SEARCH;
}

export type UserSearchActions = IUserSearchAction | IResetUserSearchAction;

export const searchUser = (query: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(swapRouteParams(routes.SEARCH_USER, { search: query })).then((response) => {
      dispatch({
        userList: response.data,
        type: UserSearchActionTypes.SEARCH_USER,
      });
    });
  };
};

export const resetUserSearch = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: UserSearchActionTypes.RESET_USER_SEARCH,
    });
  };
};
