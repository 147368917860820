import moment from 'moment';
import { Moment } from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { capitalizeString } from '../../../../helpers';
import useToastDispatcher from '../../../../hooks/useToaster';
import { localizeHelpers } from '../../../../localizeHelpers';
import { userSelectors } from '../../../../selectors/user';
import { IAppState } from '../../../../store';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';

import './DateSelection.scss';

interface IProps {
  startDate: string | Moment;
  endDate: string | Moment;
  onStartChange(e: string | Moment): void;
  onEndChange(e: string | Moment): void;
}

export const DateSelection: React.FC<IProps> = (props: IProps) => {
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const { dispatchToastError } = useToastDispatcher();

  useEffect(() => {
    verifyDate(props.startDate, 'start');
  }, [props.startDate]);

  useEffect(() => {
    verifyDate(props.endDate, 'end');
  }, [props.endDate]);

  function verifyDate(date: string | moment.Moment, type: 'start' | 'end') {
    const isValid =
      type === 'start'
        ? moment(date).isBefore(props.endDate)
        : moment(date).isAfter(props.startDate);
    const dateMsg =
      type === 'start'
        ? 'The start date should be before the end date.'
        : 'The end date should be after the start date.';
    if (!isValid && props.startDate && props.endDate)
      dispatchToastError(localizeHelpers.translate(dateMsg), `${capitalizeString(type)} Date`);
  }

  const setDate = (date: string | moment.Moment, type: string) => {
    if (type === 'start') {
      props.onStartChange(date);
    } else {
      // Sets end date one hour later if user selects the same day
      const updatedEndDate = moment(date).isSame(props.startDate)
        ? moment(date).add(1, 'hours')
        : date;
      props.onEndChange(updatedEndDate);
    }
  };

  return (
    <div className="DateSelection">
      <div className="date-row">
        <div className="date-container">
          <label>Start Date</label>
          <div notranslate="yes">
            <DateTimePicker
              inputProps={{ placeholder: 'Start Date', readOnly: true }}
              className="custom-dtp"
              value={props.startDate}
              onChange={(date) => setDate(date, 'start')}
              locale={locale}
            />
          </div>
        </div>
        <div className="date-container">
          <label>End Date</label>
          <div notranslate="yes">
            <DateTimePicker
              inputProps={{ placeholder: 'End Date', readOnly: true }}
              className="custom-dtp"
              value={props.endDate}
              disableDatesBefore={props.startDate}
              onChange={(date) => setDate(date, 'end')}
              locale={locale}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
