import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { handleInputChange, IStringMap, toastError, errorHelpers } from '../../../helpers';
import { createToast } from '../../../actions/toaster';
import { IPage } from '@gigit/interfaces';
import { IUserState } from '../../../reducers/user';
import { IProfileState } from '../../../reducers/profile';
import { ISkill } from '@gigit/interfaces';

import TextField from '../../../components/TextField/TextField';
import Button from '../../../components/Button/Button';

import { skillRequestActions } from '../../../requestActions/skills';
import { updateUser, updateUserPageComponent } from '../../../actions/user';

import './Skills.scss';
import { updateGigPageComponent, updateGigSkills } from '../../../actions/gig';
import { IGigState } from '../../../reducers/gig';
import { localizeHelpers } from '../../../localizeHelpers';
import { IOwnerObject } from '../../../interfaces/ownerObject';
import { ISkillsComponent, ReduxActionType } from '../../../interfaces';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  owner: IOwnerObject;
  page: IPage;
  component: ISkillsComponent;
  permissions?: IStringMap;
  userState: IUserState;
  profileState: IProfileState;
  gigState: IGigState;
  updateUserPageComponent: ReduxActionType<typeof updateUserPageComponent>;
  updateGigPageComponent: ReduxActionType<typeof updateGigPageComponent>;
  updateGigSkills: ReduxActionType<typeof updateGigSkills>;
  updateUser: ReduxActionType<typeof updateUser>;
  edit: boolean;
  hasEditPermissions?: boolean;
}

interface IState {
  skillValue: string;
  title: string;
  skills: ISkill[];
}

class Skills extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      skillValue: '',
      title: this.props.component.title || 'Skills',
      skills: [],
    };

    this.removeSkill = this.removeSkill.bind(this);
    this.removeMetaSkill = this.removeMetaSkill.bind(this);
    this.addSkill = this.addSkill.bind(this);
  }

  componentDidMount() {
    this.syncSkills();
  }

  async syncSkills() {
    try {
      const skills = await skillRequestActions.getSkills();
      this.setState({ skills });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Skills');
      createToast(toast);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      this.props.component.content_references?.object_ids !==
      prevProps.component.content_references?.object_ids
    ) {
      this.setState({
        skillValue: '',
      });
    }

    if (prevProps.edit && !this.props.edit) {
      this.update();
    }
  }

  addSkill() {
    // TODO: Refactor to avoid duplication.

    let gigId = this.props.gigState.gig.id;
    let _skills = [...(this.props.component.content_references?.object_ids ?? [])];
    let _skillExists = false;

    for (let s = 0; s < this.state.skills.length; s++) {
      let _skill = this.state.skills[s];

      if (_skill.skill === this.state.skillValue) {
        _skills.push(_skill.id ?? '');
        _skillExists = true;
      }
    }

    if (_skillExists) {
      if (this.props.owner.ownerType === 'user') {
        this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, {
          content_references: {
            object_type: 'skill',
            object_ids: _skills,
          },
        });

        this.props.updateUser({ skills: _skills });
      } else if (this.props.owner.ownerType === 'gig') {
        this.props.updateGigPageComponent(gigId!, this.props.page.id, this.props.component.id, {
          content_references: {
            object_type: 'skill',
            object_ids: _skills,
          },
        });

        this.props.updateGigSkills(gigId!, _skills);
      }
    } else {
      let _meta_skills: string[] = [];

      if (this.props.component.meta_data) {
        _meta_skills = [...this.props.component.meta_data.skills];
      }

      _meta_skills.push(this.state.skillValue);

      if (this.props.owner.ownerType === 'user') {
        this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, {
          meta_data: {
            skills: _meta_skills,
          },
        });

        this.props.updateUser({ skills: _meta_skills });
      } else if (this.props.owner.ownerType === 'gig') {
        this.props.updateGigPageComponent(gigId!, this.props.page.id, this.props.component.id, {
          meta_data: {
            skills: _meta_skills,
          },
        });
      }
    }
  }

  removeSkill(_id: string) {
    // TODO: Refactor to avoid duplication.

    let _skills = [...(this.props.component.content_references?.object_ids ?? [])];
    _skills = _skills.filter((e) => e !== _id);

    if (this.props.owner.ownerType === 'user') {
      this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, {
        content_references: {
          object_type: 'skill',
          object_ids: _skills,
        },
      });

      this.props.updateUser({ skills: _skills });
    } else if (this.props.owner.ownerType === 'gig') {
      this.props.updateGigPageComponent(
        this.props.gigState.gig.id!,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'skill',
            object_ids: _skills,
          },
        },
      );
    }
  }

  removeMetaSkill(_skill: string) {
    // TODO: Refactor to avoid duplication.

    let _metaSkills = [...(this.props.component.meta_data?.skills ?? [])];
    _metaSkills = _metaSkills.filter((e) => e !== _skill);

    if (this.props.owner.ownerType === 'user') {
      this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, {
        meta_data: {
          skills: _metaSkills,
        },
      });

      this.props.updateUser({ skills: _metaSkills });
    } else if (this.props.owner.ownerType === 'gig') {
      this.props.updateGigPageComponent(
        this.props.gigState.gig.id!,
        this.props.page.id,
        this.props.component.id,
        {
          meta_data: {
            skills: _metaSkills,
          },
        },
      );
    }
  }

  update() {
    const payload = this.props.component;
    payload.title = this.state.title;

    if (
      this.props.owner.ownerType === 'user' &&
      this.props.profileState.user.handle === this.props.userState.user.handle
    ) {
      this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, payload);
    } else if (this.props.hasEditPermissions) {
      this.props.updateGigPageComponent(
        this.props.gigState.gig.id!,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    }
  }

  render() {
    return (
      <div className="Skills">
        <div className="title">
          {this.props.edit && (
            <TextField
              value={this.state.title}
              name="title"
              type="text"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
          )}
          {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}
        </div>
        {this.props.hasEditPermissions && (
          <form
            className="add-skill"
            onSubmit={(e) => {
              e.preventDefault();
              this.addSkill();
            }}
          >
            <TextField
              list="gigit-skills"
              placeholder="Add a skill (Organization, Teamwork, Communication, etc)"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
              value={this.state.skillValue}
              name="skillValue"
              type="text"
            />
            <datalist
              id="gigit-skills"
              notranslate="yes"
            >
              {this.state.skills.map((item, index) => {
                const translated = localizeHelpers.translate(item.skill);
                return (
                  <option
                    key={index}
                    id={item.id}
                    value={translated}
                  />
                );
              })}
            </datalist>
            <Button
              isDisabled={!this.state.skillValue || !/\S/.test(this.state.skillValue)}
              text="Add Skill"
              type="submit"
            />
          </form>
        )}
        <ul className="user-skills">
          {this.props.component.content_objects?.map((item: any, index: number) => {
            return (
              <li
                key={index}
                className="skill no-select"
              >
                <span notranslate="yes">{item.skill}</span>
                {this.props.hasEditPermissions && (
                  <i
                    onClick={() => {
                      this.removeSkill(item.id);
                    }}
                    className="fa fa-times-circle"
                  />
                )}
              </li>
            );
          })}
          {this.props.component.meta_data &&
            this.props.component.meta_data.skills &&
            this.props.component.meta_data.skills.map((item: any, index: number) => {
              return (
                <li
                  key={index}
                  className="skill no-select"
                >
                  <span notranslate="yes">{item}</span>
                  {this.props.hasEditPermissions && (
                    <i
                      onClick={() => {
                        this.removeMetaSkill(item);
                      }}
                      className="fa fa-times-circle"
                    />
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    profileState: store.profileState,
    gigState: store.gigState,
  };
};

const mapDispatchToProps = {
  updateUserPageComponent,
  updateGigPageComponent: updateGigPageComponent,
  updateGigSkills,
  updateUser,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(Skills),
);
