import './HubBannerManage.scss';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import Modal from '../../../Modal/Modal';
import Button from '../../../Button/Button';
import BannerLayout1 from '../../../../assets/hub/banner_layout_1.png';
import BannerLayout2 from '../../../../assets/hub/banner_layout_2.png';
import BannerLayout3 from '../../../../assets/hub/banner_layout_3.png';
import BannerLayout4 from '../../../../assets/hub/banner_layout_4.png';
import BannerLayout5 from '../../../../assets/hub/banner_layout_5.png';
import BannerLayout6 from '../../../../assets/hub/banner_layout_6.png';
import Checkbox from '../../../Checkbox/Checkbox';
import TextField from '../../../TextField/TextField';
import TextArea from '../../../TextArea/TextArea';
import { IHub } from '@gigit/interfaces';
import { hubRequestActions } from '../../../../requestActions';
import { uploadImage } from '../../../../helpers';
import CoverImage from '../../../CoverImage/CoverImage';

interface IProps {
  show: boolean;
  onClose(): void;
  onChange(): void;
  hub?: IHub | null;
  isHubLoading?: boolean;
}

const FUNDRAISE = 'Fundraise';
const DONATE = 'Donate';
const VOLUNTEER = 'Volunteer';

const HubBannerManage: FC<IProps> = (props: IProps) => {
  const bannerLayouts = [
    {
      layout: 'center default',
      preview: BannerLayout1,
    },
    {
      layout: 'center dark',
      preview: BannerLayout2,
    },
    {
      layout: 'left default',
      preview: BannerLayout3,
    },
    {
      layout: 'right default',
      preview: BannerLayout4,
    },
    {
      layout: 'left light',
      preview: BannerLayout5,
    },
    {
      layout: 'right light',
      preview: BannerLayout6,
    },
  ];

  const [showLayoutSelection, setShowLayoutSelection] = useState<boolean>(false);
  const [showImageCrop, setShowImageCrop] = useState<boolean>(false);
  const [showBannerInfo, setShowBannerInfo] = useState<boolean>(false);
  const [showCallToAction, setShowCallToAction] = useState<boolean>(false);
  const [selectedLayoutIndex, setSelectedLayoutIndex] = useState<number>(0);
  const [bannerTitle, setBannerTitle] = useState<string>('');
  const [bannerDescription, setBannerDescription] = useState<string>('');
  const [fundraiseButton, setFundraiseButton] = useState<string>('');
  const [donateButton, setDonateButton] = useState<string>('');
  const [volunteerButton, setVolunteerButton] = useState<string>('');
  const [hubBannerImage, setHubBannerImage] = useState<string>('');

  useEffect(() => {
    let layoutIndex = bannerLayouts.findIndex(
      (layout) => layout.layout === props.hub?.banner?.layout,
    );
    if (layoutIndex > -1) {
      setSelectedLayoutIndex(layoutIndex);
    }
    setBannerTitle(props.hub?.banner?.banner_info?.title || '');
    setBannerDescription(props.hub?.banner?.banner_info?.description || '');
    setHubBannerImage(props.hub?.banner?.image || '');
    setFundraiseButton(props.hub?.call_to_action_text?.fundraise || '');
    setDonateButton(props.hub?.call_to_action_text?.donate || '');
    setVolunteerButton(props.hub?.call_to_action_text?.volunteer || '');
  }, [props.hub]);

  function getUpdateParams(img?: string) {
    const layout = bannerLayouts[selectedLayoutIndex];

    const params = {
      id: props.hub?.id,
      banner: {
        layout: layout.layout,
        image: img ? img : hubBannerImage,
        banner_info: {
          title: bannerTitle,
          description: bannerDescription,
        },
      },
      call_to_action_text: {
        fundraise: fundraiseButton,
        donate: donateButton,
        volunteer: volunteerButton,
      },
    };

    return params;
  }

  async function handleBannerUpdate(img?: string) {
    let params = getUpdateParams(img);

    await hubRequestActions.updateHub(params);
    props.onChange();
  }

  async function handleCropBannerImage(imgSrc: string) {
    const uploadedBannerImageURL = await uploadImage(imgSrc);
    setHubBannerImage(uploadedBannerImageURL);
    handleBannerUpdate(uploadedBannerImageURL);
  }

  return (
    <Modal
      key="hub-modal-manage"
      class="HubModalManage share-modal"
      title="Banner Settings"
      show={props.show}
      onClose={props.onClose}
      closeIcon="fas fa-times"
    >
      <div className="hub-banner-content">
        <div className="hub-banner-row">
          <div className="row-content-section">
            <div className="row-title">Banner Layout</div>
            <div>
              <img src={bannerLayouts[selectedLayoutIndex].preview} />
            </div>
          </div>
          <div className="row-action-section">
            <Button
              onClick={() => {
                setShowLayoutSelection(true);
              }}
            >
              <i className="fas fa-pen" /> Edit
            </Button>
          </div>
        </div>
        <div className="hub-banner-row">
          <div className="row-content-section">
            <div className="row-title">
              Banner Image
              <div className="row-sub-title">
                Supports JPG, JPEG, PNG. with max dimensions of 1280 x 450
              </div>
            </div>
            <div>
              <CoverImage
                currentImage={hubBannerImage ? hubBannerImage : BannerLayout1}
                onChange={(param) => handleCropBannerImage(param.file)}
                overlay={true}
                height={454}
                type="hub"
              />
            </div>
          </div>
        </div>
        <div className="hub-banner-row">
          <div className="row-content-section">
            <div className="row-title">
              Banner Information
              <div className="row-sub-title">This information will be shared with the public</div>
            </div>
            <div>
              <div className="row-content-title">Banner Title*</div>
              <p>{bannerTitle ? bannerTitle : 'This information will be shared with the public'}</p>

              <div className="row-content-title">About*</div>
              <p className="banner-description">
                {bannerDescription
                  ? bannerDescription
                  : 'This information will be shared with the public'}
              </p>
            </div>
          </div>
          <div className="row-action-section">
            <Button onClick={() => setShowBannerInfo(true)}>
              <i className="fas fa-pen" /> Edit
            </Button>
          </div>
        </div>
        <div className="hub-banner-row">
          <div className="row-content-section">
            <div className="row-title">
              Call to Action Buttons
              <div className="row-sub-title">This information will be shared with the public</div>
            </div>
            <div className="call-to-action">
              <div>
                <Checkbox
                  name={'fundraise'}
                  onChange={(e) => {
                    if (!fundraiseButton) {
                      setFundraiseButton(FUNDRAISE);
                    } else {
                      setFundraiseButton('');
                    }
                  }}
                  checked={fundraiseButton ? true : false}
                  value="fundraise"
                  disabled={true}
                  color="default"
                  label={'Fundraise'}
                />
              </div>
              <div>
                <Checkbox
                  name={'fundraise'}
                  onChange={(e) => {
                    if (!donateButton) {
                      setDonateButton(DONATE);
                    } else {
                      setDonateButton('');
                    }
                  }}
                  checked={donateButton ? true : false}
                  value="donate"
                  disabled={true}
                  color="default"
                  label={'Donate'}
                />
              </div>
              <div>
                <Checkbox
                  name={'fundraise'}
                  onChange={(e) => {
                    if (!volunteerButton) {
                      setVolunteerButton(VOLUNTEER);
                    } else {
                      setVolunteerButton('');
                    }
                  }}
                  checked={volunteerButton ? true : false}
                  value="volunteer"
                  disabled={true}
                  color="default"
                  label={'Volunteer'}
                />
              </div>
            </div>
          </div>
          <div className="row-action-section">
            <Button
              onClick={() => {
                setShowCallToAction(true);
              }}
            >
              <i className="fas fa-pen" /> Edit
            </Button>
          </div>
        </div>
      </div>

      <Modal
        show={showLayoutSelection}
        title="Banner Layout"
        onClose={() => {
          setShowLayoutSelection(false);
        }}
      >
        <div className="layout-selection">
          {bannerLayouts.map((layout, index) => {
            return (
              <div
                key={index}
                onClick={(e) => setSelectedLayoutIndex(index)}
                className={`layout-container ${index % 2 ? 'left-push' : 'right-push'}`}
              >
                <img src={layout.preview} />
                <Checkbox
                  name={`layout-${layout.layout}`}
                  onChange={(e) => {
                    setSelectedLayoutIndex(index);
                  }}
                  checked={index == selectedLayoutIndex}
                  value="layout"
                  color="hub-banner"
                  label={''}
                />
              </div>
            );
          })}
          <div className="action-container">
            <Button
              onClick={() => {
                let layoutIndex = bannerLayouts.findIndex(
                  (layout) => layout.layout === props.hub?.banner?.layout,
                );
                if (layoutIndex > -1) {
                  setSelectedLayoutIndex(layoutIndex);
                }
                setShowLayoutSelection(false);
              }}
              className="cancel-button"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleBannerUpdate();
                setShowLayoutSelection(false);
              }}
              className="save-button"
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        show={showCallToAction}
        title="Call to Action Buttons"
        onClose={() => {
          setShowCallToAction(false);
        }}
      >
        <div className="banner-info">
          <div className="row">
            <div className="call-to-action">
              <div>
                <Checkbox
                  name={'fundraise'}
                  onChange={(e) => {
                    if (!fundraiseButton) {
                      setFundraiseButton(FUNDRAISE);
                    } else {
                      setFundraiseButton('');
                    }
                  }}
                  checked={fundraiseButton ? true : false}
                  value="fundraise"
                  color="hub-banner"
                  label={'Fundraise'}
                />
              </div>
              <div>
                <Checkbox
                  name={'fundraise'}
                  onChange={(e) => {
                    if (!donateButton) {
                      setDonateButton(DONATE);
                    } else {
                      setDonateButton('');
                    }
                  }}
                  checked={donateButton ? true : false}
                  value="donate"
                  color="hub-banner"
                  label={'Donate'}
                />
              </div>
              <div>
                <Checkbox
                  name={'fundraise'}
                  onChange={(e) => {
                    if (!volunteerButton) {
                      setVolunteerButton(VOLUNTEER);
                    } else {
                      setVolunteerButton('');
                    }
                  }}
                  checked={volunteerButton ? true : false}
                  value="volunteer"
                  color="hub-banner"
                  label={'Volunteer'}
                />
              </div>
            </div>
          </div>
          <div className="action-container">
            <Button
              onClick={() => {
                setFundraiseButton(props.hub?.call_to_action_text?.fundraise || '');
                setDonateButton(props.hub?.call_to_action_text?.donate || '');
                setVolunteerButton(props.hub?.call_to_action_text?.volunteer || '');
                setShowCallToAction(false);
              }}
              className="cancel-button"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleBannerUpdate();
                setShowCallToAction(false);
              }}
              className="save-button"
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        title="Banner Info"
        show={showBannerInfo}
        onClose={() => {
          setShowBannerInfo(false);
        }}
      >
        <div className="banner-info">
          <div className="row">
            <TextField
              value={bannerTitle}
              placeholder="Banner Title"
              label="Banner Title"
              name="title"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setBannerTitle(e.target.value);
              }}
            />
          </div>
          <div className="row">
            <TextArea
              className="description-text-field"
              label={`Description`}
              type="text"
              value={bannerDescription}
              name="description"
              charLength={300}
              maxLength={300}
              spellCheck={false}
              placeholder="Enter Description"
              onChange={(e) => {
                setBannerDescription(e.target.value.replace(/<\/?[^>]+(>|$)/g, ''));
              }}
            />
          </div>
          <div className="action-container">
            <Button
              onClick={() => {
                setBannerDescription(props.hub?.banner?.banner_info?.description || '');
                setBannerTitle(props.hub?.banner?.banner_info?.title || '');
                setShowBannerInfo(false);
              }}
              className="cancel-button"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleBannerUpdate();
                setShowBannerInfo(false);
              }}
              className="save-button"
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};

export default HubBannerManage;
