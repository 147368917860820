import { useState } from 'react';
import { IHubBaseInfo } from '../interfaces';

interface IUseHubInfo {
  companyInfo: IHubBaseInfo;
  handleCompanyInfo(update: Partial<IHubBaseInfo>): void;
}

export function useHubInfo<T>(): IUseHubInfo {
  const [companyInfo, setCompanyInfo] = useState<IHubBaseInfo>({
    name: '',
    description: '',
    url: '',
    numberOfEmployees: { type: '1-10', min_value: 1, max_value: 10 },
  });

  function handleCompanyInfo(update: IHubBaseInfo) {
    setCompanyInfo(update);
  }
  return { companyInfo, handleCompanyInfo };
}
