import React, { ChangeEvent } from 'react';

import './ImageUpload.scss';
import FileUpload from '../FileUpload/FileUpload';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IProps {
  onImgSelect(fileData: { src: string; type?: string; name?: string; size?: string }): void;
  label?: string;
  icon?: string;
}

// TODO: should be a more robust solution when we revisit this
const defaultAccepts = '.png,image/gif,image/jpeg,video/*';
const acceptedVideoFormats = ['mp4', 'webm', 'ogg'];

interface IState {
  src: string; // We know this is a string (as per: https://developer.mozilla.org/en-US/docs/Web/API/FileReader/result#value)
}

class ImageUpload extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.onSelectImage = this.onSelectImage.bind(this);

    this.state = {
      src: '',
    };
  }

  onSelectImage(e: ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const extension = file.type.split('/').pop();
      // TODO hacky: is a video type
      if (extension && acceptedVideoFormats.includes(extension)) {
        reader.addEventListener('load', () => {
          this.setState({
            src: reader.result as string, // We know this is a string (as per: https://developer.mozilla.org/en-US/docs/Web/API/FileReader/result#value)
          });

          this.props.onImgSelect({
            src: this.state.src,
            type: 'video',
            name: file.name,
            size: (file.size / 1024 ** 2).toString(),
          });
        });

        reader.readAsDataURL(e.target.files[0]);
        return;
      }

      reader.addEventListener('load', () => {
        this.setState({
          src: reader.result as string, // We know this is a string (as per: https://developer.mozilla.org/en-US/docs/Web/API/FileReader/result#value)
        });

        this.props.onImgSelect({
          ...this.state,
          type: file.type,
          name: file.name,
          size: (file.size / 1024 ** 2).toString(),
        });
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  render() {
    return (
      <div className="ImageUpload">
        <FileUpload
          accept={defaultAccepts}
          icon="fas fa-camera"
          text={this.props.label ? this.props.label : 'Upload Image'}
          onSelectFile={this.onSelectImage}
        />
        {this.props.icon && <i className={this.props.icon}></i>}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {};

export default ImageUpload;
