import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { ConversationInput } from './ConversationInput/ConversationInput';
import {
  IConversation,
  IConversationMessage,
  IConversationSummary,
  IEmbeddedUser,
} from '@gigit/interfaces';
import { ConversationHeader } from './ConversationHeader/ConversationHeader';
import { ConversationActions } from './ConversationActions/ConversationActions';
import { chatRequestActions } from '../../requestActions/chat';
import useToastDispatcher from '../../hooks/useToaster';
import { userSelectors } from '../../selectors/user';
import { Messages } from './Messages/Messages';
import './Conversation.scss';
import { formatQuery, getUnreadChatCount } from '../../helpers';
import { MessageContext } from '../../contexts/MessageContext';
import { setAsRead } from '../../actions/chat';
import { userRequestActions } from '../../requestActions';

interface IConversationProps {
  conversation: IConversationSummary;
  onClose(conversation?: IConversationSummary): void;
}

const perPage = '10';

const Conversation: React.FC<IConversationProps> = (props: IConversationProps) => {
  //  State
  const [messages, setMessages] = useState<IConversationMessage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typingUser, setTypingUser] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [lastMessageId, setLastMessageId] = useState<string>();
  const dispatch = useDispatch();
  // Selectors
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  const chatState = useSelector((state: IAppState) => state.chatState);
  // Custom Hooks
  const { dispatchToastError } = useToastDispatcher();
  const messageContext = useContext(MessageContext);
  // Effects
  useEffect(() => {
    // set socket handlers
    setSocketHandlers();

    if (props.conversation.id !== 'new') {
      getConversationMessages(true);
    }

    return () => {
      if (messageContext?.state?.socket) {
        messageContext.state.socket.off('user_typing', handleUserTyping);
        messageContext.state.socket.off('user_stopped_typing', handleUserStoppedTyping);
      }
    };
  }, []);

  useEffect(() => {
    const tmpConversation = chatState.conversations.find(
      (conversation) => conversation.id === props.conversation.id,
    );
    if (tmpConversation) {
      if (user?.id && tmpConversation?.id && getUnreadChatCount([tmpConversation], user.id) > 0) {
        dispatch(setAsRead(tmpConversation, user.id));
        userRequestActions.markConversationAsRead(tmpConversation.id);
      } else {
        handleNewMessage(tmpConversation);
      }
    }
  }, [chatState.conversations]);

  // Using this infinite scroll pattern during beta push until we discuss common patterns
  let observer: React.MutableRefObject<IntersectionObserver | undefined> =
    useRef<IntersectionObserver>();
  const lastItem = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && hasMore && messages.length >= parseInt(perPage)) {
          setPage((prevPageNumber) => prevPageNumber + 1);
          getConversationMessages();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore],
  );

  function handleUserTyping(data: { userId: string; conversationId: string }) {
    if (data.userId === user.id) return;
    setTypingUser(data.userId);
    scrollToBottom();
  }

  function handleUserStoppedTyping(data: { userId: string; conversationId: string }) {
    if (data.userId === user.id) return;
    setTypingUser('');
    scrollToBottom();
  }

  function setSocketHandlers() {
    if (messageContext.state?.socket) {
      messageContext.state.socket.on('user_typing', handleUserTyping);
      messageContext.state.socket.on('user_stopped_typing', handleUserStoppedTyping);
    }
  }

  function handleNewMessage(conversation: IConversation) {
    setMessages((prev) => {
      let exists = prev.find((message) => message.id == conversation.last_message.id);
      if (!exists) {
        return [conversation.last_message, ...prev];
      } else {
        return prev;
      }
    });
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  async function getConversationMessages(shouldScrollToBottom?: boolean) {
    setIsLoading(true);
    try {
      const params = formatQuery({
        sort: [{ id: 'updated_at', order: 'desc' }],
        limit: perPage,
        skip: (page * parseInt(perPage)).toString(),
      });
      const messageList = await chatRequestActions.getConversationMessages(
        props.conversation.id!,
        params,
      );
      setHasMore(messageList.length > 0);

      if (page === 0) {
        setMessages([...messageList]);
      } else {
        setMessages((prevMessages) => [...prevMessages, ...messageList]);
      }

      if (shouldScrollToBottom) {
        scrollToBottom();
      }
    } catch (error) {
      dispatchToastError(error, 'Conversation Messages');
    } finally {
      setIsLoading(false);
    }
  }

  function scrollToBottom() {
    // use a Ref for this
    const container = document.getElementById('chat-box');
    if (container) {
      container.scrollTop = container.scrollHeight - container.clientHeight;
    }
  }

  async function onSendMessage(message: IConversationMessage) {
    try {
      if (props.conversation?.id === 'new') {
        const payload = {
          conversation_display_name: '',
          message: message,
          to_user_ids: props.conversation.user_ids.filter((id) => id !== user.id), // shouldn't include currUserId
          is_group: props.conversation.is_group || false,
        };
        const convo = await chatRequestActions.startConversation(payload);
        let updatedConversation = {
          ...convo,
          users: props.conversation.users,
          tmp_users: props.conversation.tmp_users,
        };
        props.onClose(updatedConversation);
      } else {
        await chatRequestActions.sendMessage(message);
      }
    } catch (error) {
      dispatchToastError(error, 'Send Message');
    }
  }

  function onTyping(status: string) {
    if (props.conversation.id === 'new') {
      return;
    }

    if (messageContext.state?.socket) {
      if (status === 'started') {
        messageContext.state.socket.emit('user_typing', {
          userId: user.id,
          conversationId: props.conversation.id,
        });
        scrollToBottom();
      } else {
        messageContext.state.socket.emit('user_stopped_typing', {
          userId: user.id,
          conversationId: props.conversation.id,
        });
        scrollToBottom();
      }
    }
  }

  return (
    <div className="Conversation">
      <ConversationHeader
        conversation={props.conversation}
        currentUserId={user.id || ''}
      />

      <ConversationActions
        conversation={props.conversation}
        currentUserId={user.id}
        onClose={props.onClose}
      />

      {messages.length > 0 ? (
        <Messages
          isLoading={isLoading}
          userId={user.id!}
          messages={[...messages]}
          isUserTyping={typingUser}
          lastItemRef={lastItem}
        />
      ) : (
        !isLoading && <div className="new-convo" />
      )}

      <ConversationInput
        onTyping={onTyping}
        user={user}
        conversationId={props.conversation.id || ''}
        sendMessage={onSendMessage}
      />
    </div>
  );
};

export default Conversation;
