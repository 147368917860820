import React from 'react';
import paidGigSrc from '../../../../assets/paid-gig-coming-soon.svg';
import { IEventState } from '../../../../reducers/event';

interface IEntityType {
  value: string;
  label: string;
  icon: string;
}

interface IProps {
  eventState: IEventState;
  flow: string;
  type: 'group' | 'event' | 'gig' | 'fundraise';
  entity: any;
  hasFundraiseTeams?: boolean;

  updateEntity(key: string, val: string): void;
}

interface IState {
  type: string;
  groupTypes: Array<IEntityType>;
  eventTypes: Array<IEntityType>;
  gigTypes: Array<IEntityType>;
  fundraiseTypes: Array<IEntityType>;
}

class EntityTypeSelection extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      type: '',
      groupTypes: [
        { value: 'non-profit', label: 'Non-Profit', icon: 'fal fa-heart' },
        { value: 'business', label: 'Business', icon: 'fa fa-briefcase' },
        { value: 'high school', label: 'High School', icon: 'fa fa-backpack' },
        { value: 'university/college', label: 'University/College', icon: 'fal fa-graduation-cap' },
        { value: 'serviceclub', label: 'Service Club', icon: 'fa fa-handshake' },
        { value: 'communitygroup', label: 'Community Group', icon: 'fa fa-city' },
        { value: 'individual', label: 'Individual', icon: 'fal fa-head-side' },
      ],
      eventTypes: [
        { value: 'crowdfunding', label: 'Crowdfunding', icon: 'fal fa-users' },
        { value: 'Peer-to-Peer', label: 'Peer-to-Peer', icon: 'fal fa-people-arrows' },
        { value: 'Free Registration', label: 'Free Registration', icon: 'fal fa-lock-open-alt' },
        { value: 'Paid Ticketed', label: 'Paid Ticketed', icon: 'far fa-ticket-alt' },
        { value: 'Online Auction', label: 'Online Auction', icon: 'far fa-gavel' },
        { value: 'custom', label: 'Custom', icon: 'far fa-paint-brush-alt' },
      ],
      gigTypes: [
        { value: 'volunteer', label: 'Volunteer', icon: 'fal fa-users' },
        { value: 'paid', label: 'Paid', icon: 'fal fa-people-arrows' },
      ],
      fundraiseTypes: [
        { value: 'individual', label: 'Individual', icon: 'fal fa-user' },
        { value: 'create', label: 'Create a Team', icon: 'fal fa-users-crown' },
        { value: 'join', label: 'Join a Team', icon: 'fal fa-users-medical' },
      ],
    };
  }

  componentDidMount() {
    if (this.props.entity.type) {
      this.setState({
        type: this.props.entity.type,
      });
    }
  }

  renderHeaderTxt() {
    const eventDefaultTxt = 'What type of event are you creating?';
    switch (this.props.flow) {
      case 'donations': {
        return 'What type of cause would you like to accept donations?';
      }
      case 'createStore': {
        if (this.props.type === 'group') {
          return 'What type of cause is this store for?';
        } else {
          return eventDefaultTxt;
        }
      }
      case 'createEvent': {
        if (this.props.type === 'group') {
          return 'What type of cause is this event for?';
        } else {
          return eventDefaultTxt;
        }
      }
      case 'createAuction': {
        if (this.props.type === 'group') {
          return 'What type of cause is this auction for?';
        } else {
          return eventDefaultTxt;
        }
      }
      case 'createGig': {
        if (this.props.type === 'group') {
          return 'What type of cause is this volunteer opportunity for?';
        } else {
          return 'What type of volunteer opportunity is this?';
        }
      }
      case 'createGroup': {
        return 'What type of cause are you creating?';
      }
      case 'fundraise': {
        return 'How would you like to fundraise?';
      }
      default: {
        return '';
      }
    }
  }

  renderTypes() {
    let types: Array<IEntityType>;

    if (this.props.type === 'group') {
      types = this.state.groupTypes;
    } else if (this.props.type === 'event') {
      types = this.state.eventTypes;
    } else if (this.props.type === 'fundraise') {
      let filterJoinButton = (obj: IEntityType) =>
        !this.props.hasFundraiseTeams ? obj.value !== 'join' : true;
      types = this.state.fundraiseTypes.filter(filterJoinButton);
    } else {
      types = this.state.gigTypes;
    }

    return types;
  }

  handleClick(type: IEntityType) {
    if (type.value === 'paid') {
      return;
    }

    this.setState({ type: type.value }, () => this.props.updateEntity('type', type.value));
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} ${this.props.type}-selection`}>
        <h2>{this.renderHeaderTxt()}</h2>
        <ul>
          {this.renderTypes().map((type: IEntityType, idx: number) => {
            return (
              <li
                key={idx}
                onClick={() => this.handleClick(type)}
              >
                <span
                  className={this.state.type === type.value ? 'list-circle active' : 'list-circle'}
                >
                  <i className={type.icon}></i>
                  {this.props.type === 'gig' && type.value === 'paid' && (
                    <img
                      alt="paid gigs"
                      src={paidGigSrc}
                    />
                  )}
                </span>
                {type.label}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default EntityTypeSelection;
