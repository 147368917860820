import React, { Fragment, useState } from 'react';
import './ToggleSwitch.scss';

interface Option {
  value: string;
  label: string;
}

interface ToggleSwitchProps {
  options: Option[];
  defaultValue: string;
  label?: string;
  onChange: (value: string) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ options, defaultValue, label, onChange }) => {
  const [selectedOption, setSelectedOption] = useState<string>(defaultValue);

  const handleToggle = (optionValue: string) => {
    setSelectedOption(optionValue);
    onChange(optionValue);
  };

  return (
    <div className="ToggleSwitch">
      {label && <label>{label}</label>}
      <div className="options-container">
        {options.map((option) => (
          <span
            key={option.value}
            className={`option ${selectedOption === option.value ? 'active' : ''}`}
            onClick={() => handleToggle(option.value)}
          >
            {option.label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ToggleSwitch;
