import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { handleInputChange } from '../../../../helpers';
import Button from '../../../Button/Button';
import TextField from '../../../TextField/TextField';

import BackIcon from '../../../../assets/icon-back.svg';
import './ModalManageField.scss';

interface IProps extends WithTranslation {
  fieldLabel: string;
  fieldValue: string;
  verifyFieldValue?: (newValue: string) => void;
  onSave(fieldValue: string): void;
  goBack(): void;
}

interface IState {
  fieldValue: string;
}
/** Screen to go in a modal that manages and updates a field */
class ModalManageField extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      fieldValue: this.props.fieldValue,
    };
  }

  render() {
    const t = this.props.t;
    const { fieldLabel, fieldValue } = this.props;
    const { goBack } = this.props;

    return (
      <div className="ModalManageField">
        <div className="header">
          <img
            src={BackIcon}
            onClick={goBack}
            className="icon"
            alt="back"
          />
          <span className="header-label">{`${t('Manage')} ${fieldLabel}`}</span>
        </div>
        <div className="inner-container">
          <TextField
            required={true}
            label={fieldLabel}
            value={this.state.fieldValue}
            name="fieldValue"
            type="text"
            onChange={(e: any) => {
              handleInputChange(e, this);
            }}
          />
        </div>
        <Button
          className="button-save"
          onClick={() => this.props.onSave(this.state.fieldValue)}
          text={t('Save')}
        />
      </div>
    );
  }
}

export default withTranslation('translations')(ModalManageField);
