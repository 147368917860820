import React from 'react';
import {
  HorizontalScroller,
  IHorizontalScrollerRenderProps,
} from '../../../shared/HorizontalScroller/HorizontalScroller';

import './CategoriesFilterBar.scss';

interface ICategoryObject {
  /** This field tells us the category of the object (null means no category). */
  category?: string | null;
}

interface ICategoryFilter {
  label: string;
  value: string | null;
}

interface IProps<TItem extends ICategoryObject> {
  setFilterToParent?: (filter: null | string) => void;

  /** This is the original list of items. */
  items: TItem[];

  /** Passed as children should be a function that takes the fitlered item list and renders it.
   * Note: The items will be rendered below the categories bar.
   */
  children: (itemsToRender: TItem[]) => React.ReactNode;
}

interface IState {
  currentCategory: string | null;
}

/** Provides a header of Categories that can be used to filter a list of items. */
class CategoriesFilterBar<TItem extends ICategoryObject> extends React.Component<
  IProps<TItem>,
  IState
> {
  constructor(props: IProps<TItem>) {
    super(props);

    this.state = {
      currentCategory: null,
    };
  }

  getCategoryCount(category: string | null) {
    if (category === null) {
      return this.props.items.length;
    }

    return this.props.items.filter((item) => item.category === category).length;
  }

  /** Returns categories from the source item list. */
  getCategories(): ICategoryFilter[] {
    let categories: ICategoryFilter[] = [];

    for (const item of this.props.items) {
      if (item.category && !categories.find((cat) => item.category === cat.value)) {
        categories.push({ label: item.category, value: item.category });
      }
    }

    return categories;
  }

  renderFilter(
    category: ICategoryFilter,
    index: number,
    scrollerProps: IHorizontalScrollerRenderProps,
  ) {
    const { label, value } = category;
    const isSelected = value === this.state.currentCategory;
    const onClick = () => {
      this.props?.setFilterToParent?.(value);
      this.setState({ currentCategory: value });
      scrollerProps.moveItemIntoFocus(index);
    };

    return (
      <li
        key={value}
        className={isSelected ? 'filter-item selected' : 'filter-item'}
        onClick={onClick}
      >
        {label} ({this.getCategoryCount(value)})
      </li>
    );
  }

  getFilteredItems() {
    if (this.state.currentCategory === null) {
      return this.props.items;
    }

    return this.props.items.filter((item) => item.category === this.state.currentCategory);
  }

  render() {
    const categories = [{ label: 'All', value: null }, ...this.getCategories()];

    return (
      <React.Fragment>
        <div className="CategoriesFilterBar">
          <HorizontalScroller>
            {(scrollerProps) =>
              categories.map((category, index) => this.renderFilter(category, index, scrollerProps))
            }
          </HorizontalScroller>
        </div>

        {this.props.children(this.getFilteredItems())}
      </React.Fragment>
    );
  }
}

export default CategoriesFilterBar;
