import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Button from '../Button/Button';

import './ImageCropper.scss';

export interface IImageCropperState {
  src: string;
  imageRef: HTMLImageElement | undefined;
  fileUrl: string;
  crop: ReactCrop.Crop;
  croppedImageUrl: string;
  imageOrientation: string;
  isCropping: boolean;
}

interface IProps {
  onSave(croppedState: IImageCropperState): void;
  ratio?: number;
  src: string;
  minWidth: number;
  recSize?: string;
  isLoading?: boolean;
}

interface IState extends IImageCropperState {}

class ImageCropper extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      src: this.props.src,
      imageRef: undefined,
      fileUrl: '',
      crop: {
        unit: '%',
        width: 60,
        aspect: this.props.ratio || 1,
      },
      croppedImageUrl: '',
      imageOrientation: 'landscape',
      isCropping: false,
    };
  }

  onImageLoaded = (image: HTMLImageElement) => {
    let _orientation = 'landscape';

    if (image.height > image.width) {
      _orientation = 'portrait';
    }

    this.setState(
      {
        imageRef: image,
        imageOrientation: _orientation,
      },
      () => {
        this.makeClientCrop(this.state.crop);
      },
    );
  };

  onCropChange = (crop: ReactCrop.Crop) => {
    this.setState({
      crop: crop,
    });
  };

  async makeClientCrop(crop: ReactCrop.Crop) {
    if (this.state.imageRef && crop.width && crop.height) {
      let roundedCrop = crop;
      roundedCrop.height = Math.round(crop.height);
      roundedCrop.width = Math.round(crop.width);

      const _croppedImageUrl = await this.getCroppedImg(this.state.imageRef, roundedCrop);

      this.setState({
        croppedImageUrl: _croppedImageUrl,
      });
    }
  }

  getCroppedImg(image: HTMLImageElement, crop: ReactCrop.Crop): Promise<string> {
    // gig-1751 - users can click save before img done crop, resulting in incorrect crop
    this.setState({
      isCropping: true,
    });
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // packpage interface has all optional
    if (crop && (crop.x || crop.x === 0) && (crop.y || crop.y === 0) && crop.width && crop.height) {
      canvas.width = crop.width * scaleX;
      canvas.height = crop.height * scaleY;

      const ctx = canvas.getContext('2d');

      if (ctx && ctx.drawImage) {
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY,
        );
      }
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }

        window.URL.revokeObjectURL(this.state.fileUrl);

        this.setState(
          {
            fileUrl: canvas.toDataURL('image/jpeg', 1),
          },
          () => {
            this.setState({
              isCropping: false,
            });
          },
        );

        resolve(this.state.fileUrl);
      }, 'image/png');
    });
  }

  render() {
    return (
      <div className="ImageCropper">
        <div className="title-wrap">
          <div className="ImageCropper-title">Upload Your Image</div>
          {this.props.recSize && (
            <div className="rec-size">Recommended Size {this.props.recSize}</div>
          )}
        </div>
        <div className={'no-image ' + this.state.imageOrientation}>
          {this.state.src && (
            <ReactCrop
              src={this.state.src}
              crop={this.state.crop}
              onChange={this.onCropChange}
              onImageLoaded={(image) => {
                this.onImageLoaded(image);
              }}
              onComplete={(crop) => {
                this.makeClientCrop(crop);
              }}
            />
          )}
          {!this.state.src && <span>No Image Selected</span>}
        </div>
        <div className="row">
          <Button
            loading={this.state.isCropping || this.props.isLoading}
            isDisabled={this.state.isCropping}
            onClick={() => this.props.onSave(this.state)}
            text="Save"
            icon="fad fa-save"
          />
        </div>
      </div>
    );
  }
}

export default ImageCropper;
