import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { combineClassNames } from '../../helpers';

import Modal from '../Modal/Modal';
import ImageUpload from '../ImageUpload/ImageUpload';
import ImageCropper from '../ImageCropper/ImageCropper';

import './CoverImage.scss';

interface ICoverImageSize {
  recommendedSize: string;
  ratio: number;
}
interface IProps {
  width?: number;
  height?: number;
  overlay?: boolean;
  onClick?(e: any): void;
  onChange?(file: { file: string; type: string }): void;
  currentImage?: string;
  className?: string;
  type?: 'group' | 'event' | 'profile' | 'hub';
  background?: boolean;
}

interface IState {
  src: string;
  fileUrl: string;
  showCropModal: boolean;
}

class CoverImage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      src: '',
      fileUrl: '',
      showCropModal: false,
    };

    this.onImgSelect = this.onImgSelect.bind(this);
    this.onCrop = this.onCrop.bind(this);
    this.close = this.close.bind(this);
  }

  onImgSelect(file: any) {
    this.setState({
      src: file.src,
      showCropModal: true,
    });
  }

  onCrop(newImgData: any) {
    this.setState({
      showCropModal: false,
      fileUrl: newImgData.fileUrl,
    });

    if (this.props.onChange) {
      this.props.onChange({
        file: newImgData.fileUrl,
        type: 'cover_image_url',
      });
    }
  }

  close() {
    this.setState({
      showCropModal: false,
    });
  }

  //type = hub / group / event / etc
  getImageSize = (type?: string): ICoverImageSize => {
    switch (type) {
      case 'event':
      case 'group':
        return {
          recommendedSize: '1400 x 400',
          ratio: 3.5,
        };
      case 'hub':
        return {
          recommendedSize: '1280 x 450',
          ratio: 2.844,
        };
      default:
        return {
          recommendedSize: '785 x 380',
          ratio: 2.065,
        };
    }
  };

  render() {
    const { recommendedSize, ratio } = this.getImageSize(this.props.type);

    return (
      <div
        className={combineClassNames(
          'CoverImage',
          this.props.currentImage || this.state.fileUrl ? 'transparent' : '',
          this.props.className,
        )}
      >
        <Modal
          show={this.state.showCropModal}
          onClose={() => {
            this.close();
          }}
        >
          <ImageCropper
            recSize={recommendedSize}
            onSave={this.onCrop}
            src={this.state.src}
            ratio={ratio}
            minWidth={780}
          />
        </Modal>
        {this.props.overlay && (
          <div className="overlay">
            <i className="fad fa-camera" />
            <ImageUpload onImgSelect={this.onImgSelect} />
          </div>
        )}
        {this.props.background && (
          <div
            className="img-bg"
            style={{
              backgroundImage: `url(${this.state.fileUrl !== '' ? this.state.fileUrl : this.props.currentImage})`,
            }}
          />
        )}
        {!this.props.background && (this.props.currentImage || this.state.fileUrl) && (
          <LazyLoadImage
            effect="opacity"
            alt="Cover Image"
            src={this.state.fileUrl !== '' ? this.state.fileUrl : this.props.currentImage}
          />
        )}
      </div>
    );
  }
}

export default CoverImage;
