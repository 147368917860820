import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../store';
import { capitalizeString, handleDebounce, handleInputChange } from '../../helpers';

import { IGroupState } from '../../reducers/group';

import {
  getDiscountCodes,
  deleteDiscountCode,
  StoreItemOwnerType,
  activateDiscountCode,
} from '../../actions/store';

import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import ContextMenu from '../ContextMenu/ContextMenu';
import DiscountCodeForm from '../DiscountCode/DiscountCodeForm';

import './DiscountCodeManagement.scss';
import { IStoreState } from '../../reducers/store';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import { IOwnerObject } from '../../interfaces';

interface IProps extends WithTranslation {
  owner: IOwnerObject;
  groupState: IGroupState;
  storeState: IStoreState;
  locale: string;
  getDiscountCodes(ownerType: StoreItemOwnerType, ownerId: string, _search?: string): void;
  deleteDiscountCode(ownerType: StoreItemOwnerType, ownerId: string, discountId: string): void;
  activateDiscountCode(ownerType: StoreItemOwnerType, ownerId: string, discountId: string): void;
}
interface IState {
  showAddForm: boolean;
  searchValue: string;
  discount_edit: any;
}

class DiscountCodeManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showAddForm: false,
      searchValue: '',
      discount_edit: {},
    };

    this.toggleAddForm = this.toggleAddForm.bind(this);
  }

  componentDidMount() {
    this.props.getDiscountCodes(
      this.props.owner.ownerType as StoreItemOwnerType,
      this.props.owner.ownerId,
    );
  }

  toggleAddForm(value?: boolean) {
    this.setState({
      showAddForm: value ? value : !this.state.showAddForm,
    });
  }

  deleteDiscount(discount: any) {
    this.props.deleteDiscountCode(
      this.props.owner.ownerType as StoreItemOwnerType,
      this.props.owner.ownerId,
      discount.id,
    );
  }

  activateDiscount(discount: any) {
    this.props.activateDiscountCode(
      this.props.owner.ownerType as StoreItemOwnerType,
      this.props.owner.ownerId,
      discount.id,
    );
  }

  discountTypePrefix(discount: any) {
    if (discount.discount_type === 'fixed') {
      return `$${discount.amount}`;
    } else {
      return `${discount.amount}%`;
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="DiscountCodeManagement section-wrap">
        <div className="section-title">
          <div className="section-inner-title">{t('Discount Codes')}</div>
          <div className="section-create">
            <Button
              icon="fal fa-plus"
              onClick={() => {
                this.setState({ discount_edit: {} });
                this.toggleAddForm(true);
              }}
              text="Add Discount"
            />
          </div>
        </div>
        <div className="section-inner">
          <div className="DiscountManagement-list">
            <div className="search">
              <TextField
                icon="fas fa-search"
                placeholder="Search discounts..."
                value={this.state.searchValue}
                type="text"
                name="searchValue"
                onChange={(e: any) => {
                  handleInputChange(e, this, false, () => {
                    handleDebounce(this.state.searchValue).then((res) => {
                      this.props.getDiscountCodes(
                        this.props.owner.ownerType as StoreItemOwnerType,
                        this.props.owner.ownerId,
                        this.state.searchValue,
                      );
                    });
                  });
                }}
              />
            </div>
            <div className="list">
              <div className="headers">
                <div className="col code">Code</div>
                <div className="col status">Status</div>
                <div className="col amount">Amount</div>
                <div className="col created">Date Created</div>
                <div className="col actions"></div>
              </div>
              <div className="list-inner">
                <div className="list-rows">
                  {this.props.storeState.discountCodes &&
                    this.props.storeState.discountCodes.map((item, index) => {
                      let _menuItems = [
                        {
                          icon: 'fas fa-pencil',
                          onClick: () => {
                            this.setState({ discount_edit: item });
                            this.toggleAddForm(true);
                          },
                          label: t('Edit'),
                        },
                        item.status.code === 'active'
                          ? {
                              icon: 'fas fa-times',
                              onClick: () => {
                                this.deleteDiscount(item);
                              },
                              label: t('Disable'),
                            }
                          : {
                              icon: 'fas fa-check',
                              onClick: () => {
                                this.activateDiscount(item);
                              },
                              label: t('Activate'),
                            },
                      ];

                      return (
                        <div
                          key={index}
                          className="row"
                        >
                          <div
                            className="col code"
                            notranslate="yes"
                          >
                            {item.code}
                          </div>
                          <div className="col status">
                            <span className={`${item.status.code}`}>
                              {capitalizeString(item.status.code)}
                            </span>
                          </div>
                          <div
                            className="col amount"
                            notranslate="yes"
                          >
                            {this.discountTypePrefix(item)}
                          </div>
                          <div
                            className="col created"
                            notranslate="yes"
                          >
                            {localizeHelpers.formatDate(
                              item.created_at ?? new Date(),
                              LocaleDateFormats.LL,
                              this.props.locale,
                            )}
                          </div>
                          <div className="col actions">
                            <i className="fal fa-ellipsis-h-alt"></i>
                            <ContextMenu
                              onMouseLeave={() => {}}
                              showMenu={true}
                              menuItems={_menuItems}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {(!this.props.storeState.discountCodes ||
                    (this.props.storeState.discountCodes &&
                      this.props.storeState.discountCodes.length === 0)) && (
                    <div className="empty">{t("You haven't added any discount codes, yet.")}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={this.state.showAddForm}
            onClose={() => {
              this.toggleAddForm(false);
            }}
          >
            <DiscountCodeForm
              ownerType={this.props.owner.ownerType}
              ownerId={this.props.owner.ownerId}
              discount={this.state.discount_edit}
              onClose={() => this.toggleAddForm(false)}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    storeState: store.storeState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getDiscountCodes: getDiscountCodes,
  deleteDiscountCode: deleteDiscountCode,
  activateDiscountCode: activateDiscountCode,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(DiscountCodeManagement),
);
