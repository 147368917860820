import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { QRCode } from 'react-qr-svg';
import { userSelectors } from '../../selectors/user';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import './Share.scss';
import { IOwnerObject } from '../../interfaces';
import { typeHelpers } from '../../helpers';
import useToastDispatcher from '../../hooks/useToaster';
import { localizeHelpers } from '../../localizeHelpers';
import { IAppState } from '../../store';
import KambeoShare from './../../assets/share-icons/kambeo.png';
import Twitter from './../../assets/share-icons/twitter.png';
import Embed from './../../assets/share-icons/embed.png';
import QR from './../../assets/share-icons/qr.png';
import Facebook from './../../assets/share-icons/fb.png';
import Instagram from './../../assets/share-icons/ig.png';
import Twitch from './../../assets/share-icons/twitch.png';
import { uiConstants } from '../../constants';
import PostShareModal from '../shared/PostShareModal/PostShareModal';
import Dropdown from '../Dropdown/Dropdown';
import { ColorResult, SketchPicker } from 'react-color';
import { Config } from '@gigit/config';

interface IProps {
  url: string;
  owner_object?: IOwnerObject;
  title?: string;
}

const INITIAL_SHARE = 1;
const SOCIAL_MEDIA = 2;
const COMMUNITY_FEED_POST = 3;
const EMBED_OPTIONS = 4;
const EMBED_BUTTON = 5;
const EMBED_FORM = 6;

const Share = (props: IProps) => {
  const locale = useSelector((store: IAppState) => userSelectors.getCurrentLocale(store));
  const { dispatchToastSuccess, dispatchToastError } = useToastDispatcher();

  const lang = locale ? `?lang=${locale}` : '';
  const urlWithLang = props.url + lang;

  const buttonTypes = [
    { label: 'Donate', value: 'Donate' },
    { label: 'Volunteer', value: 'Volunteer' },
    { label: 'Register', value: 'Register' },
    { label: 'Fundraise', value: 'Fundraise' },
    { label: 'Find us on Kambeo', value: 'Find us on Kambeo' },
  ];

  const formTypes =
    props.owner_object?.ownerType === uiConstants.ownerType.event
      ? [
          { label: 'Event Donate Form', value: 'Event Donate Form' },
          { label: 'Cause Donate Form', value: 'Cause Donate Form' },
        ]
      : props.owner_object?.ownerType === uiConstants.ownerType.group
        ? [{ label: 'Cause Donate Form', value: 'Cause Donate Form' }]
        : [{ label: 'Hub Donate Form', value: 'Hub Donate Form' }];

  const supportedEmbedTypes: string[] = [
    uiConstants.ownerType.group,
    uiConstants.ownerType.event,
    uiConstants.ownerType.hub,
  ];

  const supportedPostTypes: string[] = [
    uiConstants.ownerType.group,
    uiConstants.ownerType.event,
    uiConstants.ownerType.gig,
    uiConstants.ownerType.hub,
  ];

  const [showQr, setShowQr] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(INITIAL_SHARE);

  const [selectedButtonType, setSelectedButtonType] = useState<string>(buttonTypes[0].value);
  const [selectedFromType, setSelectedFromType] = useState<string>(formTypes[0].value);

  const [buttonOption, setButtonOption] = useState<number>(-1);
  const [buttonColours, setButtonColours] = useState<string[]>(['#5E51AB', '#FDFCFB', '#5E51AB']);

  const [buttonEmbed, setButtonEmbed] = useState<string>('');
  const [formEmbed, setFormEmbed] = useState<string>('');

  const formColours = ['#5E51AB', '#FDFCFB', '#5E51AB'];

  useEffect(() => {
    setButtonEmbedUrl();
  }, [buttonColours, selectedButtonType]);

  useEffect(() => {
    setFormEmbedUrl();
  }, [selectedFromType]);

  function getColorLabel(index: number) {
    let _label: string = 'Border Color';

    if (index === 1) {
      _label = 'Font Color';
    }

    if (index === 2) {
      _label = 'Background Color';
    }

    return _label;
  }

  function handleColorChange(color: ColorResult, index: number) {
    let tmpButtonColors = [...buttonColours];
    tmpButtonColors[index] = color.hex;
    setButtonColours([...tmpButtonColors]);
    setButtonEmbedUrl();
  }

  function setFormEmbedUrl() {
    let url: string =
      Config.web.REACT_APP_BASE_URL + '/event/' + props.owner_object?.ownerHandle + '/donate';

    if (selectedFromType === 'Cause Donate Form') {
      if (props.owner_object?.ownerType === uiConstants.ownerType.event) {
        url =
          Config.web.REACT_APP_BASE_URL +
          '/group/' +
          typeHelpers.getGroupHandleFromOwner(props.owner_object) +
          '/donate';
      } else {
        url =
          Config.web.REACT_APP_BASE_URL + '/group/' + props.owner_object?.ownerHandle + '/donate';
      }
    } else {
      url =
        Config.web.REACT_APP_BASE_URL + '/company/' + props.owner_object?.ownerHandle + '/donate';
    }
    setFormEmbed(
      '<iframe src="' +
        url +
        '?embed=1&default=' +
        formColours
          .map((item, index) => {
            item = item.substring(1);
            return item;
          })
          .join(',') +
        '&hover=' +
        formColours
          .map((item, index) => {
            item = item.substring(1);
            return item;
          })
          .join(',') +
        '" style="height:100%;width:100%;min-height:700px;border:0px" />',
    );
  }

  function setButtonEmbedUrl() {
    let url: string =
      Config.web.REACT_APP_BASE_URL + '/embed/' + props.owner_object?.ownerHandle + '/event';

    if (props.owner_object?.ownerType === uiConstants.ownerType.group) {
      url = Config.web.REACT_APP_BASE_URL + '/embed/' + props.owner_object?.ownerHandle + '/group';
    } else if (props.owner_object?.ownerType === uiConstants.ownerType.hub) {
      url =
        Config.web.REACT_APP_BASE_URL + '/embed/' + props.owner_object?.ownerHandle + '/company';
    }

    let tmpHandle: string | undefined = '';
    if (props.owner_object && props.owner_object?.ownerType === uiConstants.ownerType.group) {
      tmpHandle = typeHelpers.getGroupHandleFromOwner(props.owner_object);
    } else if (props.owner_object?.ownerType === uiConstants.ownerType.hub) {
      tmpHandle = props.owner_object?.ownerHandle;
    }
    setButtonEmbed(
      '<iframe src="' +
        url +
        '?type=button&default=' +
        buttonColours
          .map((item, index) => {
            item = item.substring(1);
            return item;
          })
          .join(',') +
        '&hover=' +
        buttonColours
          .map((item, index) => {
            item = item.substring(1);
            return item;
          })
          .join(',') +
        '&text=' +
        encodeURI(selectedButtonType) +
        '&group=' +
        tmpHandle +
        '" width="240px" height="80px" style="border:0px;" />',
    );
  }

  function copyEmbed(type: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(type === 'button' ? buttonEmbed : formEmbed).then(
        () => {
          dispatchToastSuccess(
            localizeHelpers.translate('Embed successfully copied.'),
            'Embed Button',
          );
        },
        (err) => {
          dispatchToastError(
            localizeHelpers.translate('Unable to copy Embed code.'),
            'Embed Button',
          );
        },
      );
    }
  }

  function copyLink() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(urlWithLang).then(
        () => {
          dispatchToastSuccess(localizeHelpers.translate('Link Copied'), 'Copy Link');
        },
        (error) => {
          dispatchToastError(localizeHelpers.translate('Unable to Copy link'), 'Copy Link');
        },
      );
    }
  }

  return (
    <div className="Share">
      <div className="share-head">
        <span onClick={() => setCurrentStep(INITIAL_SHARE)}>
          {props?.title ? props.title : 'Share this page'}
        </span>
        {currentStep == SOCIAL_MEDIA ? <span> &gt; Social Media</span> : <span></span>}
        {currentStep == COMMUNITY_FEED_POST ? <span> &gt; Create Post</span> : <span></span>}
        {currentStep == EMBED_OPTIONS ? <span> &gt; Embed</span> : <span></span>}
        {currentStep == EMBED_OPTIONS || currentStep == EMBED_BUTTON ? (
          <span> &gt; Buttons</span>
        ) : (
          <span></span>
        )}
        {currentStep == EMBED_OPTIONS || currentStep == EMBED_FORM ? (
          <span> &gt; Forms</span>
        ) : (
          <span></span>
        )}
      </div>
      {currentStep == INITIAL_SHARE && (
        <div className="share-content">
          {supportedPostTypes.indexOf(
            props.owner_object?.ownerType ? props.owner_object?.ownerType : '',
          ) !== -1 && (
            <div
              className="share-type"
              onClick={() => setCurrentStep(COMMUNITY_FEED_POST)}
            >
              <img src={KambeoShare} />
              <div className="share-label">on Kambeo</div>
            </div>
          )}
          <div
            className="share-type"
            onClick={() => setCurrentStep(SOCIAL_MEDIA)}
          >
            <div className="icons-grouped">
              <img src={Twitch} />
              <img src={Facebook} />
              <img src={Instagram} />
              <img src={Twitter} />
            </div>
            <div className="share-label">Social Media</div>
          </div>
        </div>
      )}
      {currentStep == SOCIAL_MEDIA && (
        <div className="share-content social-media">
          {showQr && (
            <div className="qr-code-section">
              <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                style={{ width: 256 }}
                value={urlWithLang}
              />
            </div>
          )}
          {supportedEmbedTypes.indexOf(
            props.owner_object?.ownerType ? props.owner_object?.ownerType : '',
          ) !== -1 && (
            <div
              onClick={() => setCurrentStep(EMBED_OPTIONS)}
              className="share-type embed"
            >
              <img src={Embed} />
              <div className="share-label">Embed</div>
            </div>
          )}
          <div
            onClick={() => setShowQr(true)}
            className="share-type"
          >
            <img src={QR} />
            <div className="share-label">QR Code</div>
          </div>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={'https://www.facebook.com/sharer/sharer.php?u=' + urlWithLang}
            className="share-type"
          >
            <img src={Facebook} />
            <div className="share-label">Facebook</div>
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={'https://twitter.com/intent/tweet?url=' + urlWithLang}
            className="share-type"
          >
            <img src={Twitter} />
            <div className="share-label">Twitter</div>
          </a>
          <div className="share-type url">
            <div className="share-label">Page URL</div>
            <div className="copy-link">
              <TextField
                type="text"
                name="share_url"
                value={urlWithLang}
                onChange={() => {}}
              />
              <Button
                text="Copy"
                onClick={() => {
                  copyLink();
                }}
              />
            </div>
          </div>
        </div>
      )}
      {currentStep == COMMUNITY_FEED_POST && (
        <PostShareModal
          onPostCreated={() => setCurrentStep(INITIAL_SHARE)}
          owner_object={props.owner_object}
        />
      )}
      {currentStep === EMBED_OPTIONS && (
        <div className="share-content social-media">
          <div
            onClick={() => setCurrentStep(EMBED_BUTTON)}
            className="share-type"
          >
            <i className="far fa-hand-pointer" />
            <div className="share-label">Buttons</div>
          </div>
          <div
            onClick={() => setCurrentStep(EMBED_FORM)}
            className="share-type"
          >
            <i className="fal fa-file-alt" />
            <div className="share-label">Forms</div>
          </div>
        </div>
      )}
      {currentStep === EMBED_BUTTON && (
        <div className="share-content embed">
          <Dropdown
            value={selectedButtonType}
            options={buttonTypes}
            className="embed-selection"
            name="button-type"
            label="Button"
            onChange={(e) => {
              setSelectedButtonType(e.target.value);
            }}
          />
          <div className="color-options">
            {buttonColours.map((color, index) => {
              return (
                <div
                  key={index}
                  className="color-option"
                >
                  <div
                    onMouseLeave={() => {
                      setButtonOption(-1);
                    }}
                    onMouseEnter={() => {
                      setButtonOption(index);
                    }}
                    className="color-swatch"
                    style={{ backgroundColor: buttonColours[index] }}
                  >
                    {buttonOption === index && (
                      <div className="picker-wrap">
                        <SketchPicker
                          disableAlpha={true}
                          color={buttonColours[index]}
                          onChange={(color: ColorResult) => handleColorChange(color, index)}
                          onChangeComplete={(color: ColorResult) => handleColorChange(color, index)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="color-label">
                    <div className="color-name">{getColorLabel(index)}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="embed-code">
            <TextField
              type="text"
              name="buttonEmbed"
              value={buttonEmbed}
              onChange={() => {
                /* do nothing */
              }}
            />
            <Button
              text="Copy"
              onClick={() => {
                copyEmbed('button');
              }}
            />
          </div>
        </div>
      )}
      {currentStep === EMBED_FORM && (
        <div className="share-content embed">
          <Dropdown
            value={selectedFromType}
            options={formTypes}
            className="embed-selection"
            name="form-type"
            label="Form"
            onChange={(e) => {
              setSelectedFromType(e.target.value);
            }}
          />
          <div className="embed-code">
            <TextField
              type="text"
              name="formEmbed"
              value={formEmbed}
              onChange={() => {
                /* do nothing */
              }}
            />
            <Button
              text="Copy"
              onClick={() => {
                copyEmbed('form');
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Share;
