import React from 'react';
import { connect } from 'react-redux';
import { Config } from '@gigit/config';
import { IPage, IAddress } from '@gigit/interfaces';
import { IStringMap, formatAddressLine, handleInputChange } from '../../../helpers';
import { Link, RouteComponentProps } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';

import { updateGroupPageComponent } from '../../../actions/group';
import { updateEventPageComponent } from '../../../actions/event';
import { updateGigPageComponent } from '../../../actions/gig';

import Modal from '../../Modal/Modal';
import ComponentPlaceholder from '../ComponentPlaceholder/ComponentPlaceholder';

import './Locations.scss';

import TextField from '../../TextField/TextField';
import Marker from '../../MapComponents/Marker/Marker';
import Popup from '../../MapComponents/Popup/Popup';
import { IOwnerObject } from '../../../interfaces/ownerObject';
import { ILocationListComponent, IMapViewport } from '../../../interfaces';

interface IProps extends RouteComponentProps<any> {
  owner: IOwnerObject;
  edit: boolean;
  component: ILocationListComponent;
  permissions?: IStringMap;
  page: IPage;
  hasEditPermissions?: boolean;
  locations: IAddress[];
  updateGroupPageComponent(
    groupId: string,
    _pageId: string,
    _componentId: string,
    _payload: any,
    callback?: { (): void },
  ): void;
  updateEventPageComponent(
    eventId: string,
    _pageId: string,
    _componentId: string,
    _payload: any,
    callback?: { (): void },
  ): void;
  updateGigPageComponent(
    gigId: string,
    _pageId: string,
    _componentId: string,
    _component: any,
  ): void;
  onCreateLocationClicked?: () => void;
  onComponentUpdate?: (disableLoad?: boolean) => void;
}

interface IState {
  viewport: IMapViewport;
  mounted: boolean;
  forceRepaint: boolean;
  showEditLocations: boolean;
  currentPopup: number;
  title: string;
}

class Locations extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      viewport: {
        latitude: 0,
        longitude: 0,
        zoom: 10,
      },
      mounted: false,
      forceRepaint: false,
      showEditLocations: false,
      currentPopup: -1,
      title: this.props.component.title || 'Locations',
    };

    this.forceRepaint = this.forceRepaint.bind(this);
    this.updateViewport = this.updateViewport.bind(this);
    this.removeLocationFromMap = this.removeLocationFromMap.bind(this);
    this.addLocationToMap = this.addLocationToMap.bind(this);
    this.setDefaultPosition = this.setDefaultPosition.bind(this);
    this.setCurrentPopup = this.setCurrentPopup.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        mounted: true,
      },
      () => {
        window.addEventListener('resize', this.forceRepaint);
      },
    );

    if (
      this.props.component.meta_data !== undefined &&
      this.props.component.meta_data.viewport !== undefined
    ) {
      const { latitude, longitude, zoom } = this.props.component.meta_data.viewport;
      this.setState({
        viewport: {
          latitude: latitude,
          longitude: longitude,
          zoom: zoom,
        },
      });
    } else {
      if (
        this.props.component.content_objects !== undefined &&
        this.props.component.content_objects.length > 0
      ) {
        this.setState({
          viewport: {
            latitude: this.props.component.content_objects[0].location?.coordinates[1] ?? 0,
            longitude: this.props.component.content_objects[0].location?.coordinates[0] ?? 0,
            zoom: 10,
          },
        });
      }
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevProps.component.meta_data !== this.props.component.meta_data &&
      this.props.component.meta_data !== undefined
    ) {
      const { latitude, longitude, zoom } = this.props.component.meta_data.viewport;

      this.setState({
        viewport: {
          latitude: latitude,
          longitude: longitude,
          zoom: zoom,
        },
      });
    }

    // If viewport is not defined and we have try to default viewport to the first address.
    const contentObjects = this.props.component.content_objects;
    if (
      contentObjects?.length &&
      this.state.viewport?.latitude === 0 &&
      this.state.viewport?.longitude === 0
    ) {
      const latitude = contentObjects[0].location?.coordinates[1];
      const longitude = contentObjects[0].location?.coordinates[0];

      if (latitude && longitude) {
        this.setState(
          {
            viewport: {
              latitude: latitude,
              longitude: longitude,
              zoom: 10,
            },
          },
          () => {
            this.setDefaultPosition();
          },
        );
      }
    }

    if (prevProps.edit && !this.props.edit) {
      this.update();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.forceRepaint);
  }

  update = () => {
    const payload = this.props.component;

    payload.title = this.state.title;

    if (this.props.page) {
      if (
        this.props.permissions &&
        this.props.permissions['EDIT_GROUP_PAGES'] &&
        this.props.owner.ownerType !== 'gig'
      ) {
        if (this.props.updateGroupPageComponent) {
          this.props.updateGroupPageComponent(
            this.props.owner.ownerId,
            this.props.page.id,
            this.props.component.id,
            payload,
          );
        }
      } else if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
        this.props.updateEventPageComponent(
          this.props.owner.ownerId,
          this.props.page.id,
          this.props.component.id,
          payload,
          this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
        );
      }
    }
  };

  updateViewport(viewport: any) {
    if (this.state.mounted) {
      this.setState({
        viewport: viewport,
      });
    }
  }

  forceRepaint() {
    this.setState(
      {
        forceRepaint: true,
      },
      () => {
        this.setState({
          forceRepaint: false,
        });
      },
    );
  }

  removeLocationFromMap(itemId: string) {
    let _object_ids = [...(this.props.component.content_references?.object_ids ?? [])];

    for (let o in _object_ids) {
      if (itemId === _object_ids[o]) {
        _object_ids.splice(parseInt(o)!, 1);
      }
    }

    // TODO: Cleanup up duplication
    if (this.props.owner.ownerType === 'group') {
      this.props.updateGroupPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'location',
            object_ids: _object_ids,
          },
        },
        this.props.onComponentUpdate ? () => this.props.onComponentUpdate?.(true) : undefined,
      );
    } else if (this.props.owner.ownerType === 'event') {
      this.props.updateEventPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'location',
            object_ids: _object_ids,
          },
        },
        this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
      );
    } else if (this.props.owner.ownerType === 'gig') {
      this.props.updateGigPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'location',
            object_ids: _object_ids,
          },
        },
      );
    }
  }

  addLocationToMap(itemId: string) {
    // TODO: Cleanup up duplication

    let _object_ids = [...(this.props.component.content_references?.object_ids ?? [])];
    _object_ids.push(itemId);

    if (this.props.owner.ownerType === 'group') {
      this.props.updateGroupPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'location',
            object_ids: _object_ids,
          },
        },
        this.props.onComponentUpdate ? () => this.props.onComponentUpdate?.(true) : undefined,
      );
    } else if (this.props.owner.ownerType === 'event') {
      this.props.updateEventPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'location',
            object_ids: _object_ids,
          },
        },
        this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
      );
    } else if (this.props.owner.ownerType === 'gig') {
      this.props.updateGigPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'location',
            object_ids: _object_ids,
          },
        },
      );
    }
  }

  setDefaultPosition() {
    // TODO: Cleanup up duplication

    if (this.props.owner.ownerType === 'group') {
      this.props.updateGroupPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          meta_data: {
            viewport: this.state.viewport,
          },
        },
      );
    } else if (this.props.owner.ownerType === 'event') {
      this.props.updateEventPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          meta_data: {
            viewport: this.state.viewport,
          },
        },
      );
    } else if (this.props.owner.ownerType === 'gig') {
      this.props.updateGigPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          meta_data: {
            viewport: this.state.viewport,
          },
        },
      );
    }
  }

  setCurrentPopup(index: number) {
    this.setState({
      currentPopup: index,
    });
  }

  userHasEditPermissions() {
    return (
      this.props.permissions !== undefined &&
      ((this.props.owner.ownerType === 'group' && this.props.permissions['EDIT_GROUP_PAGES']) ||
        (this.props.owner.ownerType === 'event' && this.props.permissions['EDIT_EVENT_PAGES']) ||
        (this.props.owner.ownerType === 'gig' && this.props.permissions['MANAGE_GIGS'])) &&
      this.props.hasEditPermissions
    );
  }

  render() {
    const currentPopup =
      this.state.currentPopup != -1
        ? this.props.component.content_objects?.[this.state.currentPopup]
        : undefined;

    return (
      <div className="Locations">
        <div className="title">
          {this.props.edit && (
            <TextField
              value={this.state.title}
              name="title"
              type="text"
              onChange={(e: any) => {
                handleInputChange(e, this);
              }}
            />
          )}
          {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}
        </div>

        {this.props.onCreateLocationClicked &&
          this.userHasEditPermissions() &&
          this.props.locations.length === 0 && (
            <ComponentPlaceholder
              title="Add location information about a cause, event or volunteer opportunity"
              message="Create and show multiple locations"
              actions={[
                {
                  label: 'Create Location',
                  iconClassName: 'fas fa-plus-circle',
                  action: this.props.onCreateLocationClicked,
                },
              ]}
            />
          )}

        <div className="options">
          {this.props.locations.length > 0 && (
            <div className={this.userHasEditPermissions() ? 'map admin' : 'map'}>
              {this.props.component.content_objects &&
                this.props.component.content_objects.length > 0 && (
                  <div
                    style={{ width: '100%', height: '100%' }}
                    notranslate="yes"
                  >
                    <GoogleMapReact
                      center={{
                        lat: this.state.viewport.latitude,
                        lng: this.state.viewport.longitude,
                      }}
                      zoom={this.state.viewport.zoom}
                      bootstrapURLKeys={{ key: Config.web.REACT_APP_GOOGLE_API_KEY }}
                      onChange={(mapInfo) => {
                        this.setState({
                          viewport: {
                            latitude: mapInfo.center.lat,
                            longitude: mapInfo.center.lng,
                            zoom: mapInfo.zoom,
                          },
                        });
                      }}
                    >
                      {this.props.component.content_objects.map((item: any, index: number) => {
                        if (item !== null && item.location?.coordinates) {
                          return (
                            <Marker
                              key={index}
                              lat={item.location?.coordinates[1]}
                              lng={item.location?.coordinates[0]}
                              offsetLeft={-8}
                              offsetTop={-12}
                            >
                              <i
                                onClick={() => this.setCurrentPopup(index)}
                                className="fas fa-map-marker-alt"
                              />
                            </Marker>
                          );
                        } else {
                          return null;
                        }
                      })}
                      {currentPopup && (
                        <Popup
                          lat={currentPopup.location?.coordinates[1] ?? 0}
                          lng={currentPopup.location?.coordinates[0] ?? 0}
                          onClose={() => {
                            this.setCurrentPopup(-1);
                          }}
                          translateX="-50%"
                          translateY="-100%"
                          offsetTop="-10px"
                        >
                          <div className="location-popup">
                            <div className="title">{currentPopup.title}</div>
                            <div className="address">{formatAddressLine(currentPopup)}</div>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={
                                'https://www.google.ca/maps/dir//' +
                                formatAddressLine(currentPopup) +
                                '/@' +
                                currentPopup.location?.coordinates[1] +
                                ',' +
                                currentPopup.location?.coordinates[0] +
                                ',17z/'
                              }
                            >
                              Directions
                            </a>
                          </div>
                        </Popup>
                      )}
                    </GoogleMapReact>
                  </div>
                )}
              {(!this.props.component.content_objects ||
                this.props.component.content_objects.length === 0) && (
                <div
                  className="overlay"
                  style={{ transitionProperty: 'none' }}
                >
                  <p>
                    This map doesn't have any locations visible.
                    {this.userHasEditPermissions() ? (
                      <>
                        <span
                          onClick={() => {
                            this.setState({ showEditLocations: true });
                          }}
                        >
                          {' '}
                          Select one
                        </span>{' '}
                        now.
                      </>
                    ) : null}
                  </p>
                </div>
              )}
              <div className="list-admin-actions">
                <ul>
                  {(this.props.component.meta_data === undefined ||
                    this.props.component.meta_data.viewport !== this.state.viewport) && (
                    <li
                      onClick={() => {
                        this.setDefaultPosition();
                      }}
                    >
                      <i className="far fa-arrows-alt" />
                      <span>Set Default Position</span>
                    </li>
                  )}
                  <li
                    onClick={() => {
                      this.setState({ showEditLocations: true });
                    }}
                  >
                    <i className="fad fa-pencil" />
                    <span>Edit</span>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <Modal
          show={this.state.showEditLocations}
          onClose={() => {
            this.setState({ showEditLocations: false });
          }}
          title="Edit Locations"
        >
          <div className="locations-modal">
            <ul className="admin-locations">
              {this.props.locations && this.props.locations.length > 0 && (
                <li className="headers">
                  <span className="address">Location</span>
                  <span className="show">Visible</span>
                </li>
              )}
              {this.props.locations &&
                this.props.locations.map((item, index) => {
                  return (
                    <li key={index}>
                      <span
                        className="address"
                        notranslate="yes"
                      >
                        {formatAddressLine(item)}
                      </span>
                      {this.props.component.content_references !== undefined && (
                        <span className="show">
                          {this.props.component.content_references.object_ids.includes(
                            item.id ?? '',
                          ) ? (
                            <i
                              onClick={() => {
                                this.removeLocationFromMap(item.id!);
                                this.setState({ showEditLocations: false });
                              }}
                              className="far fa-eye"
                            />
                          ) : (
                            <i
                              onClick={() => {
                                this.addLocationToMap(item.id!);
                                this.setState({ showEditLocations: false });
                              }}
                              className="fad fa-eye-slash"
                            />
                          )}
                        </span>
                      )}
                      <span className="actions" />
                    </li>
                  );
                })}
              {(this.props.locations === undefined || this.props.locations.length === 0) && (
                <div className="no-locations">You haven't added any locations yet.</div>
              )}
              <li className="manage">
                <Link
                  to={`/${this.props.owner.ownerType}/${this.props.owner.ownerHandle}/admin?id=${this.props.owner.ownerId}&t=locations`}
                >
                  <i className="far fa-pencil" />
                  <span>Manage Locations</span>
                </Link>
              </li>
            </ul>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateGroupPageComponent,
  updateEventPageComponent,
  updateGigPageComponent,
};

export default connect(null, mapDispatchToProps)(Locations);
