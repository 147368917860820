import { Dispatch } from 'redux';
import axios from 'axios';
import { routes, swapRouteParams, toastError, uploadImageToStore } from '../helpers';
import {
  UserActionTypes,
  createUserPage,
  updateUserProfileImage,
  updateUserCoverImage,
  updateLocalizeLanguageFromState,
  updateUser,
  createUserPageComponent,
  updateUserPageComponent,
} from './user';
import { getUnreadNotifications } from './notifications';
import { IFlowStateConfig } from '../reducers/register';
import { createToast } from './toaster';
import errorHelpers from '../helpers/errorHelpers';
import { IPage, ISimpleValue, IUser, IUserToken } from '../../../../lib/gigit-interfaces/dist';
import { IAppError } from '../interfaces';
import { ChatActionTypes } from './chat';
import { localizeHelpers } from '../localizeHelpers';

export enum RegisterActionTypes {
  REGISTER_USER = 'REGISTER_USER',
  GET_GROUP_CLASSIFICATIONS = 'GET_GROUP_CLASSIFICATIONS',
  SET_FLOW = 'SET_FLOW',
  SET_FLOW_AS_EVENT = 'SET_FLOW_AS_EVENT',
  RESET_FLOW_STATE = 'RESET_FLOW_STATE',
}

export interface IRegisterAction {
  type: RegisterActionTypes.REGISTER_USER;
  success: boolean;
  error: IAppError | null;
  handleError: IAppError | null;
  isRegisterLoading: boolean;
  isHandleLoading: boolean;
}

export interface IRegisterFlowTypeAction {
  flowConfig: IFlowStateConfig;
  type: RegisterActionTypes.SET_FLOW;
  success: boolean;
  error: IAppError | null;
  handleError: IAppError | null;
  isRegisterLoading: boolean;
  isHandleLoading: boolean;
}

export interface IResetFlowState {
  flowConfig: IFlowStateConfig;
  type: RegisterActionTypes.RESET_FLOW_STATE;
}

export interface IRegisterFlowGetClassifications {
  groupClassifications: Array<any>;
  type: RegisterActionTypes.GET_GROUP_CLASSIFICATIONS;
}

export type RegisterActions =
  | IRegisterAction
  | IRegisterFlowTypeAction
  | IRegisterFlowGetClassifications
  | IResetFlowState;

export const checkForDuplicateEmail = (email: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(swapRouteParams(routes.CHECK_DUPLICATE_EMAIL, { email: email })).then((response) => {
      if (response.data.user_exists) {
        dispatch({
          success: false,
          error: {
            errorCode: 'ERROR.REGISTRATION.USER_EMAIL_ALREADY_EXISTS',
            messageTemplate: 'Email already exists',
            translatedMessage: localizeHelpers.translate('Email already exists'),
          },
          type: RegisterActionTypes.REGISTER_USER,
        });
      } else {
        dispatch({
          success: true,
          type: RegisterActionTypes.REGISTER_USER,
        });
      }
    });
  };
};
export const registerUser = (payload: any, callback?: () => void) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isRegisterLoading: true,
      type: RegisterActionTypes.REGISTER_USER,
    });

    // Default the new account language from the loggedout language dropdown.
    const language = getState().userState.language ?? 'en';
    let responseObj: IUserToken;
    payload.language = language;

    axios
      .post(routes.REGISTER, payload)
      .then(async (response) => {
        if (response.data.tokens.access_token) {
          // axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.tokens.access_token}`;
          getUnreadNotifications()(dispatch, getState);
        }

        responseObj = response.data;

        try {
          // Always create an About tab
          const currentPage = await axios.post(
            swapRouteParams(routes.GET_PROFILE_PAGES, { userId: 'current' }),
            {
              label: 'About',
              icon: 'fad fa-info-circle',
            },
          );
        } catch (error) {
          const errorObj = errorHelpers.getErrorObject(error);
          let toast = toastError(errorObj.translatedMessage, 'Profile/Cover Image Upload');
          createToast(toast)(dispatch, getState);
        } finally {
          // Even if profile pic/cover image upload fails, we still want user to be able to use the app.
          dispatch({
            type: ChatActionTypes.RECONNECT_CHAT,
          });

          dispatch({
            success: true,
            error: null,
            type: RegisterActionTypes.REGISTER_USER,
          });

          dispatch({
            error: null,
            ...responseObj,
            isLoggedIn: true,
            type: UserActionTypes.UPDATE_USER,
          });
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          success: false,
          error: errorObj,
          type: RegisterActionTypes.REGISTER_USER,
        });
      })
      .finally(() => {
        dispatch({
          isRegisterLoading: false,
          type: RegisterActionTypes.REGISTER_USER,
        });

        updateLocalizeLanguageFromState();

        if (callback) {
          callback();
        }
      });
  };
};

export const checkUserHandle = (_handle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isHandleLoading: true,
      type: RegisterActionTypes.REGISTER_USER,
    });

    axios
      .get<ISimpleValue<boolean>>(swapRouteParams(routes.USER_UNIQUE_HANDLE, { handle: _handle }))
      .then((response) => {
        if (response.data && response.data.value === false) {
          const errorObj = errorHelpers.getErrorObjectFromSimpleValue(response.data);
          dispatch({
            success: false,
            handleError: errorObj,
            type: RegisterActionTypes.REGISTER_USER,
          });
        } else {
          dispatch({
            success: false,
            handleError: null,
            type: RegisterActionTypes.REGISTER_USER,
          });
        }
      })
      .finally(() => {
        dispatch({
          isHandleLoading: false,
          type: RegisterActionTypes.REGISTER_USER,
        });
      });
  };
};

export const updateRegisterError = (_error: IAppError) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      success: false,
      error: _error,
      type: RegisterActionTypes.REGISTER_USER,
    });
  };
};

export const getGroupClassifications = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_GROUP_CLASSIFICATIONS).then((response) => {
      dispatch({
        groupClassifications: response.data,
        type: RegisterActionTypes.GET_GROUP_CLASSIFICATIONS,
      });
    });
  };
};

export const setFlow = (flowConfig: IFlowStateConfig) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      flowConfig: { ...flowConfig },
      type: RegisterActionTypes.SET_FLOW,
    });
  };
};

export const resetFlowState = (isComplete?: boolean) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      flowConfig: {
        group: null,
        event: null,
        gig: null,
        fundraiser: null,
        type: '',
        currentStep: '',
        groupType: '',
        eventType: '',
        isCharity: false,
        isEvent: false,
        isComplete: isComplete ? isComplete : false,
      },
      type: RegisterActionTypes.RESET_FLOW_STATE,
    });
  };
};
