import { Constants } from '@gigit/constants';
import {
  IDonationMatchingProgram,
  IDonationMatchingProgramCreateParams,
  IDonationMatchingProgramUpdateParams,
  IHub,
} from '@gigit/interfaces';
import moment from 'moment';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { uiConstants } from '../../../../../constants';
import { formatQuery, typeHelpers } from '../../../../../helpers';
import useToastDispatcher from '../../../../../hooks/useToaster';
import { groupRequestActions, hubRequestActions } from '../../../../../requestActions';
import { userSelectors } from '../../../../../selectors/user';
import { IAppState } from '../../../../../store';
import { IOptions } from '../../../../Dropdown/Dropdown';
import CauseSelectionModal, {
  ISelectedGroup,
} from '../../../../shared/CauseSelectionModal/CauseSelectionModal';
import DateTimePicker from '../../../../shared/DateTimePicker/DateTimePicker';
import { ISelectOption } from '../../../../shared/forms/select/types';
import PopupMenu, {
  IPopupMenuItem,
  IShowPopupConfig,
} from '../../../../shared/PopupMenu/PopupMenu';
import TextField from '../../../../TextField/TextField';
import './DonationMatchingProgramForm.scss';
import Checkbox from '../../../../Checkbox/Checkbox';
import ToggleButton from '../../../../shared/ToggleButton/ToggleButton';

interface IProps {
  hub?: IHub;
  donationMatchingProgram: IDonationMatchingProgram | null;
  onSubmit: (isSubmitting: boolean) => void;
  onValidateForm: (isValid: boolean) => void;
}

export const MATCHING_PROGRAM_FORM_ID = 'MATCHING_PROGRAM_FORM_ID';
const initDonationMatchingForm = {
  program_type: Constants.donation_matching_program_type.donation_match,
  name: '',
  start_date: moment().toDate(),
  end_date: moment().add(1, 'days').toDate(),
  allowed_groups: [],
  // donation program
  ratio: 1,
  max_amount: 0,
  employee_max_amount: 0,
  donation_min_amount: 0,
  donation_max_amount: 0,
  auto_approve: false,
  // volunteer for dollars
  allowed_volunteer_groups: [],
  invert_allowed_groups: false,
} as IDonationMatchingProgramCreateParams;

const DonationMatchingProgramForm: FC<IProps> = (props) => {
  const { donationMatchingProgram, hub, onSubmit, onValidateForm } = props;
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const [showProgramTypeContextMenu, setShowProgramTypeContextMenu] = useState<boolean>(false);
  const [allowedGroupsToggle, setAllowedGroupsToggle] = useState<boolean>(true);
  const [showRatioContextMenu, setShowRatioContextMenu] = useState<boolean>(false);
  const [selectedCauses, setSelectedCauses] = useState<ISelectedGroup[]>([]);
  const [selectedCausesForVolunteer, setSelectedCausesForVolunteer] = useState<ISelectedGroup[]>(
    [],
  );
  const [donationMatchingForm, setDonationMatchingForm] =
    useState<IDonationMatchingProgramCreateParams>(initDonationMatchingForm);
  const [showAddCausesModal, setShowAddCausesModal] = useState<boolean>(false);
  const [showAddCausesModalForVolunteer, setShowAddCausesModalForVolunteer] =
    useState<boolean>(false);

  const isDonationMatchingProgram =
    donationMatchingForm.program_type === Constants.donation_matching_program_type.donation_match;
  const isVolunteerForDollarsProgram =
    donationMatchingForm.program_type ===
    Constants.donation_matching_program_type.volunteer_for_dollars;

  useEffect(() => {
    isFormValid();
  }, [donationMatchingForm]);

  useEffect(() => {
    if (donationMatchingProgram) {
      setDonationMatchingForm({
        program_type: donationMatchingProgram.program_type,
        name: donationMatchingProgram.name,
        start_date: donationMatchingProgram.start_date,
        end_date: donationMatchingProgram.end_date,
        allowed_groups: donationMatchingProgram.allowed_groups,
        ratio: donationMatchingProgram.ratio,
        max_amount: donationMatchingProgram.max_amount,
        employee_max_amount: donationMatchingProgram.employee_max_amount,
        donation_min_amount: donationMatchingProgram.donation_min_amount,
        donation_max_amount: donationMatchingProgram.donation_max_amount,
        auto_approve: donationMatchingProgram.auto_approve,
        allowed_volunteer_groups: donationMatchingProgram.allowed_volunteer_groups,
        invert_allowed_groups: donationMatchingProgram.invert_allowed_groups,
      });

      setAllowedGroupsToggle(!donationMatchingProgram.invert_allowed_groups ?? true);
      if (
        donationMatchingProgram.allowed_groups_group &&
        donationMatchingProgram.allowed_groups_group?.length > 0
      ) {
        let selectedCauses: { id: string; value: string; label: string }[] =
          donationMatchingProgram.allowed_groups_group
            ?.map((group) => {
              return {
                id: group._id ?? '',
                label: group.title ?? '',
                value: group._id ?? '',
              };
            })
            .filter(function (element) {
              return element !== undefined;
            });

        if (selectedCauses && selectedCauses.length > 0) {
          setSelectedCauses(selectedCauses);
        }
      }

      if (
        donationMatchingProgram.allowed_volunteer_groups_group &&
        donationMatchingProgram.allowed_volunteer_groups_group?.length > 0
      ) {
        let selectedCausesForVolunteer: { id: string; value: string; label: string }[] =
          donationMatchingProgram.allowed_volunteer_groups_group
            ?.map((group) => {
              return {
                id: group._id ?? '',
                label: group.title ?? '',
                value: group._id ?? '',
              };
            })
            .filter(function (element) {
              return element !== undefined;
            });

        if (selectedCausesForVolunteer && selectedCausesForVolunteer.length > 0) {
          setSelectedCausesForVolunteer(selectedCausesForVolunteer);
        }
      }
    } else {
      resetValues();
    }
  }, [donationMatchingProgram]);

  function resetValues() {
    setDonationMatchingForm(initDonationMatchingForm);
  }

  function isFormValid() {
    let isValid = true;
    const { start_date, end_date } = donationMatchingForm;

    if (moment(start_date) >= moment(end_date)) {
      isValid = false;
    }

    const { max_amount, donation_min_amount, donation_max_amount } = donationMatchingForm;

    if (max_amount && max_amount <= 0) {
      isValid = false;
    }

    if (isDonationMatchingProgram) {
      if (donation_min_amount && donation_min_amount <= 0) {
        isValid = false;
      }

      if (donation_max_amount && donation_max_amount <= 0) {
        isValid = false;
      }

      if ((donation_min_amount ?? 0) >= (donation_max_amount ?? 0)) {
        isValid = false;
      }
    }

    onValidateForm(isValid);
  }

  async function handleSubmitProgramForm(e: FormEvent) {
    e.preventDefault();

    try {
      typeHelpers.assertNotNullOrUndefined(hub?.id, 'Expected Company ID');
      onSubmit(true);

      if (donationMatchingProgram) {
        typeHelpers.assertNotNullOrUndefined(donationMatchingProgram?._id, 'Expected Program ID');
        const donationMatchingFields: IDonationMatchingProgramUpdateParams = {
          ...donationMatchingForm,
          visibility: Constants.donation_matching_program_visibility.private,
        };
        await hubRequestActions.updateDonationMatchingProgram(
          hub?.id,
          donationMatchingProgram._id.toString(),
          donationMatchingFields,
        );
        dispatchToastSuccess('Successfully edited.', 'Edit Program');
      } else {
        let donationMatchingFields: IDonationMatchingProgramCreateParams = {
          ...donationMatchingForm,
          visibility: Constants.donation_matching_program_visibility.private,
        };

        if (
          donationMatchingForm.program_type ===
          Constants.donation_matching_program_type.volunteer_for_dollars
        ) {
          donationMatchingFields = {
            ...donationMatchingFields,
            donation_min_amount: 0,
            donation_max_amount: 9999999,
          };
        }

        await hubRequestActions.createDonationMatchingProgram(hub?.id, donationMatchingFields);
        dispatchToastSuccess('Successfully added.', 'Add program');
      }
    } catch (error) {
      dispatchToastError(error, 'Error Matching Program');
    } finally {
      onSubmit(false);
    }
  }

  const isEditMode = !!donationMatchingProgram;
  const isStarted =
    isEditMode &&
    donationMatchingProgram.status.code === Constants.donation_matching_program_status.running;
  const isDonationMade =
    isEditMode &&
    !donationMatchingProgram.amount_payed_out &&
    !donationMatchingProgram.amount_matched;

  const donationMatchingOptions: IOptions[] = [
    {
      label: 'Donation Match',
      value: Constants.donation_matching_program_type.donation_match,
    },
    {
      label: 'Volunteer for Dollars',
      value: Constants.donation_matching_program_type.volunteer_for_dollars,
    },
  ];

  const donationMatchingRatios: IOptions[] = uiConstants.donationMatchingRatioOptions;

  const programTypePopupMenuConfig: IShowPopupConfig = {
    showMenu: showProgramTypeContextMenu,
    setShowMenu: setShowProgramTypeContextMenu,
    position: {
      type: 'bottom',
    },
  };
  const programTypeContextMenuItems: IPopupMenuItem[] = donationMatchingOptions.map((option) => ({
    id: option.value,
    label: option.label,
    isSelected: donationMatchingForm.program_type === option.value,
    onClick: () => {
      setDonationMatchingForm({
        ...donationMatchingForm,
        program_type: option.value,
        ratio: option.value === Constants.donation_matching_program_type.donation_match ? 1 : 0,
      });
    },
  }));

  const ratioPopupMenuConfig: IShowPopupConfig = {
    showMenu: showRatioContextMenu,
    setShowMenu: setShowRatioContextMenu,
    position: {
      type: 'bottom',
    },
  };
  const ratioContextMenuItems: IPopupMenuItem[] = donationMatchingRatios.map((option) => ({
    id: option.value,
    label: option.label,
    isSelected: donationMatchingForm.ratio === +option.value,
    onClick: () => {
      setDonationMatchingForm({ ...donationMatchingForm, ratio: +option.value });
    },
  }));

  const handleRemoveCause = (e: React.MouseEvent, causeToRemove: ISelectOption) => {
    e.stopPropagation();
    let newSelectedCauses = selectedCauses;
    for (let i = 0; i < newSelectedCauses.length; i++) {
      if (newSelectedCauses[i].value === causeToRemove.value) {
        newSelectedCauses.splice(i, 1);
      }
    }
    setSelectedCauses(newSelectedCauses);
    setDonationMatchingForm((prev) => {
      return { ...prev, allowed_groups: newSelectedCauses.map((newCause) => newCause.value) };
    });
  };

  const handleAddCauses = (causes: ISelectedGroup[]) => {
    setSelectedCauses(causes);
    setDonationMatchingForm((prev) => {
      return { ...prev, allowed_groups: causes.map((cause) => cause.value) };
    });
    setShowAddCausesModal(false);
  };

  const handleAddCausesForVolunteer = (causes: ISelectedGroup[]) => {
    setSelectedCausesForVolunteer(causes);
    setDonationMatchingForm((prev) => {
      return { ...prev, allowed_volunteer_groups: causes.map((cause) => cause.value) };
    });
    setShowAddCausesModalForVolunteer(false);
  };

  const handleRemoveCauseForVolunteer = (e: React.MouseEvent, causeToRemove: ISelectedGroup) => {
    e.stopPropagation();
    let newSelectedCauses = selectedCausesForVolunteer;
    for (let i = 0; i < newSelectedCauses.length; i++) {
      if (newSelectedCauses[i].value === causeToRemove.value) {
        newSelectedCauses.splice(i, 1);
      }
    }

    setSelectedCausesForVolunteer(newSelectedCauses);
    setDonationMatchingForm((prev) => {
      return {
        ...prev,
        allowed_volunteer_groups: newSelectedCauses.map((newCause) => newCause.value),
      };
    });
  };

  const handleShowAddCauseModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowAddCausesModal(true);
  };

  const handleShowAddCauseModalForVolunteer = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowAddCausesModalForVolunteer(true);
  };

  return (
    <>
      <form
        className="DonationMatchingForm"
        onSubmit={handleSubmitProgramForm}
        id={MATCHING_PROGRAM_FORM_ID}
      >
        <p className="desc">
          {`${isEditMode ? 'Edit' : 'Create'} a program to match your volunteers donations or volunteer hours.`}
        </p>
        <div className="form-row">
          <label className="popupmenu-container col">
            <span className="label-title">Program Type</span>
            <PopupMenu
              className={`menu-container ${isEditMode ? 'disabled' : ''}`}
              popupMenuClass="PopupMenu-DonationMatchingForm-programType"
              showMenuConfig={programTypePopupMenuConfig}
              menuItems={programTypeContextMenuItems}
              onClick={() => {
                if (!isEditMode) {
                  setShowProgramTypeContextMenu(!showProgramTypeContextMenu);
                }
              }}
              onSelect={() => {
                setShowProgramTypeContextMenu(false);
              }}
            >
              <span className="title">
                {
                  programTypeContextMenuItems.find(
                    (opt) => opt.id === donationMatchingForm.program_type,
                  )?.label
                }
              </span>
              <i
                className={`fas fa-chevron-down menu-btn ${showProgramTypeContextMenu ? 'show' : ''}`}
              />
            </PopupMenu>
          </label>
        </div>
        <div className="form-row">
          <TextField
            label="Program Name"
            required={true}
            value={donationMatchingForm.name ?? ''}
            onChange={(e) =>
              setDonationMatchingForm({ ...donationMatchingForm, name: e.target.value })
            }
            name="name"
            type="text"
          />
        </div>
        <div className="form-row">
          <div className="col date-container">
            <label>Start Date</label>
            <DateTimePicker
              inputProps={{ placeholder: 'MM/DD/YYYY', readOnly: true, disabled: isStarted }}
              className={`custom-dtp ${isStarted ? 'no-drop' : ''}`}
              value={moment(donationMatchingForm.start_date)}
              onChange={(date) =>
                setDonationMatchingForm({
                  ...donationMatchingForm,
                  start_date: moment(date).toDate(),
                })
              }
              locale={locale}
              timeFormat={false}
            />
          </div>
          <div className="col date-container">
            <label>End Date</label>
            <DateTimePicker
              inputProps={{ placeholder: 'MM/DD/YYYY', readOnly: true }}
              className="custom-dtp"
              value={moment(donationMatchingForm.end_date)}
              onChange={(date) =>
                setDonationMatchingForm({
                  ...donationMatchingForm,
                  end_date: moment(date).toDate(),
                })
              }
              locale={locale}
              timeFormat={false}
              disableDatesBefore={moment(donationMatchingForm.start_date)}
            />
          </div>
        </div>
        <div className="form-row">
          <TextField
            label="Funding Amount"
            required={true}
            value={donationMatchingForm.max_amount || ''}
            onChange={(e) =>
              setDonationMatchingForm({
                ...donationMatchingForm,
                max_amount: parseFloat(e.target.value) || 0,
              })
            }
            error={
              donationMatchingForm.max_amount && donationMatchingForm.max_amount <= 0
                ? 'Funding Amount must be a positive value'
                : undefined
            }
            name="max_amount"
            type="number"
          />
        </div>
        {isVolunteerForDollarsProgram && (
          <div className="form-row">
            <TextField
              label="Dollars per Hour"
              required={true}
              value={donationMatchingForm.ratio || ''}
              onChange={(e) =>
                setDonationMatchingForm({
                  ...donationMatchingForm,
                  ratio: parseFloat(e.target.value) || 0,
                })
              }
              name="ratio"
              type="number"
            />
          </div>
        )}
        {isDonationMatchingProgram && (
          <div className="form-row">
            <label className="popupmenu-container col">
              <span className="label-title">Matching Ratio</span>
              <PopupMenu
                className={`menu-container ${isDonationMade ? 'disabled' : ''}`}
                popupMenuClass="PopupMenu-DonationMatchingForm-ratio"
                showMenuConfig={ratioPopupMenuConfig}
                menuItems={ratioContextMenuItems}
                onClick={() => {
                  if (!isDonationMade) {
                    setShowRatioContextMenu(!showRatioContextMenu);
                  }
                }}
                onSelect={() => setShowRatioContextMenu(false)}
              >
                <span className="title">
                  {
                    ratioContextMenuItems.find((opt) => +opt.id === donationMatchingForm.ratio)
                      ?.label
                  }
                </span>
                <i
                  className={`fas fa-chevron-down menu-btn ${showRatioContextMenu ? 'show' : ''}`}
                />
              </PopupMenu>
            </label>
          </div>
        )}

        {isVolunteerForDollarsProgram && (
          <div className="form-row allowed-causes">
            <div className="col">
              <div
                className="allowed-causes-container"
                onClick={handleShowAddCauseModalForVolunteer}
              >
                <label>Allowed Causes for employees to volunteer for</label>
                <div className="allowed-causes-empty">Select...</div>
                {selectedCausesForVolunteer.length > 0 &&
                  selectedCausesForVolunteer.map((cause, index) => {
                    return (
                      <div
                        className="allowed-cause"
                        key={index}
                      >
                        <span className="allowed-cause-name">
                          {cause.label}{' '}
                          <i
                            className="fas fa-times"
                            onClick={(e) => {
                              handleRemoveCauseForVolunteer(e, cause);
                            }}
                          ></i>
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}

        {isVolunteerForDollarsProgram && (
          <div className="form-row">
            <ToggleButton
              isActive={allowedGroupsToggle}
              onToggleClick={() => {
                setAllowedGroupsToggle(!allowedGroupsToggle);
                setDonationMatchingForm({
                  ...donationMatchingForm,
                  invert_allowed_groups: !allowedGroupsToggle,
                });
                setDonationMatchingForm((prev) => {
                  return { ...prev, invert_allowed_groups: !prev.invert_allowed_groups };
                });
              }}
              label="Allow / Deny Causes for employees to donate to"
            />
          </div>
        )}

        <div className="form-row allowed-causes">
          <div className="col">
            <div
              className="allowed-causes-container"
              onClick={handleShowAddCauseModal}
            >
              <label>
                {allowedGroupsToggle
                  ? 'Allowed Causes for employees to donate to'
                  : 'Denied Causes for employees to donate to'}
              </label>
              <div className="allowed-causes-empty">Select...</div>
              {selectedCauses.length > 0 &&
                selectedCauses.map((cause, index) => {
                  return (
                    <div
                      className="allowed-cause"
                      key={index}
                    >
                      <span className="allowed-cause-name">
                        {cause.label}{' '}
                        <i
                          className="fas fa-times"
                          onClick={(e) => {
                            handleRemoveCause(e, cause);
                          }}
                        ></i>
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="form-row">
          <TextField
            label="Employee Maximum for Program"
            required={true}
            value={donationMatchingForm.employee_max_amount || ''}
            onChange={(e) =>
              setDonationMatchingForm({
                ...donationMatchingForm,
                employee_max_amount: parseFloat(e.target.value) || 0,
              })
            }
            name="employee_max_amount"
            type="number"
            error={
              donationMatchingForm.employee_max_amount &&
              donationMatchingForm.employee_max_amount <= 0
                ? 'Employee Maximum must be a positive value'
                : undefined
            }
          />
        </div>
        {isDonationMatchingProgram && (
          <div className="form-row">
            <TextField
              label="Minimum amount a donation needs to be"
              required={true}
              value={donationMatchingForm.donation_min_amount || ''}
              onChange={(e) =>
                setDonationMatchingForm({
                  ...donationMatchingForm,
                  donation_min_amount: parseFloat(e.target.value) || 0,
                })
              }
              name="donation_min_amount"
              type="number"
              error={
                donationMatchingForm.donation_min_amount &&
                donationMatchingForm.donation_min_amount <= 0
                  ? 'Donation Minimum must be a positive value'
                  : undefined
              }
            />
          </div>
        )}

        {isDonationMatchingProgram && (
          <div className="form-row">
            <TextField
              label="Maximum allowed amount on a donation"
              required={true}
              value={donationMatchingForm.donation_max_amount || ''}
              onChange={(e) =>
                setDonationMatchingForm({
                  ...donationMatchingForm,
                  donation_max_amount: parseFloat(e.target.value) || 0,
                })
              }
              name="donation_max_amount"
              type="number"
              error={
                donationMatchingForm.donation_max_amount &&
                donationMatchingForm.donation_max_amount <= 0
                  ? 'Donation Maximum must be a positive value'
                  : (donationMatchingForm.donation_max_amount ?? 0) <
                      (donationMatchingForm.donation_min_amount ?? 0)
                    ? 'Donation Maximum must be greater than Minimum'
                    : undefined
              }
            />
          </div>
        )}
        <div className="form-row">
          <Checkbox
            name="auto_approve"
            label={`Enable Auto Approval For ${isDonationMatchingProgram ? 'Donation Matching' : 'Volunteer Credit Donations'}?`}
            value="auto_approve"
            checked={donationMatchingForm.auto_approve ?? false}
            onChange={() =>
              setDonationMatchingForm({
                ...donationMatchingForm,
                auto_approve: !donationMatchingForm.auto_approve,
              })
            }
          />
        </div>
      </form>
      <CauseSelectionModal
        showCauseSelectionModal={showAddCausesModal}
        onClose={() => {
          setShowAddCausesModal(false);
        }}
        onAddCause={handleAddCauses}
        selected={selectedCauses}
        hubId={hub?.id}
      />
      <CauseSelectionModal
        showCauseSelectionModal={showAddCausesModalForVolunteer}
        onClose={() => {
          setShowAddCausesModalForVolunteer(false);
        }}
        onAddCause={handleAddCausesForVolunteer}
        selected={selectedCausesForVolunteer}
        hubId={hub?.id}
      />
    </>
  );
};

export default DonationMatchingProgramForm;
