import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Dropdown, { IOptions } from '../../../Dropdown/Dropdown';
import {
  handleInputChange,
  alphabetizeOptionsList,
  errorHelpers,
  toastError,
  formatQuery,
} from '../../../../helpers';
import Button from '../../../Button/Button';
import TextField from '../../../TextField/TextField';
import { ICause } from '@gigit/interfaces';
import { IAppState } from '../../../../store';
import { IRegisterState } from '../../../../reducers/register';
import { causeRequestActions } from '../../../../requestActions/causes';
import { createToast } from '../../../../actions/toaster';

interface IProps extends WithTranslation, RouteComponentProps {
  registerState: IRegisterState;
  flow: string;
  group: any;
  classificationOptions: Array<IOptions>;

  updateEntity(key: string, val: any): void;
}

interface IState {
  causeValue: string;
  groupClassification: string;
  groupSubClassification: string;
  classifications: Array<any>;
  classificationOptions: Array<IOptions>;
  subClassificationOptions: Array<IOptions>;
  selectedCauses: Array<string>;
  causes: ICause[];
}

class GroupClassifications extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      causeValue: '',
      groupClassification: '',
      groupSubClassification: '',
      classifications: this.props.classificationOptions,
      classificationOptions: this.groupClassificationOptions(),
      subClassificationOptions: this.groupHasSubClass(),
      selectedCauses: [],
      causes: [],
    };

    this.handleGroupClassification = this.handleGroupClassification.bind(this);
    this.handleGroupSubClassification = this.handleGroupSubClassification.bind(this);
    this.getSubClassificationOptions = this.getSubClassificationOptions.bind(this);
  }

  componentDidMount() {
    if (this.props.group.causes) {
      this.setState({
        selectedCauses: [...this.props.group.causes],
      });
    }

    const syncCauses = async () => {
      try {
        const gotCauses = await causeRequestActions.getCauses();
        this.setState({ causes: gotCauses });
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Add Focus Area');
        createToast(toast);
      }
    };
    syncCauses();
  }

  handleGroupClassification(type: string) {
    this.getSubClassificationOptions(type);
    this.props.updateEntity('group_class', this.state.groupClassification);
  }

  handleGroupSubClassification(type: string) {
    this.props.updateEntity('group_sub_class', this.state.groupSubClassification);
  }

  groupClassificationOptions() {
    let opts = this.props.registerState?.groupClassifications.map((c: any) => {
      return { value: c.id, label: c.class_name };
    });

    opts = alphabetizeOptionsList(opts);

    if (opts) {
      opts.unshift({ value: '', label: 'Select An Option' });
    } else {
      opts = [];
    }

    return opts;
  }

  getSubClassificationOptions(value: string) {
    let opts: Array<IOptions> = [];
    let idx = this.props.registerState?.groupClassifications.findIndex((c: any) => {
      return c.id === value;
    });

    if (idx || idx === 0) {
      opts = this.props.registerState.groupClassifications[idx]?.sub_classes.map((s: any) => {
        return { value: s, label: s };
      });

      if (opts) {
        opts = alphabetizeOptionsList(opts);
        opts.unshift({ value: '', label: 'Select An Option' });
      } else {
        opts = [];
      }
    }

    this.setState({
      subClassificationOptions: opts,
    });
  }

  groupHasSubClass() {
    if (this.props.group.group_class) {
      let idx = this.props.registerState?.groupClassifications.findIndex((c: any) => {
        return c.id === this.props.group.group_class;
      });

      if (idx) {
        let opts = this.props.registerState?.groupClassifications[idx]?.sub_classes.map(
          (s: any) => {
            return { value: s, label: s };
          },
        );

        opts = alphabetizeOptionsList(opts);

        return opts;
      }
    } else {
      let opts = this.props.registerState?.groupClassifications[0]?.sub_classes.map((s: any) => {
        return { value: s, label: s };
      });

      if (opts) {
        opts.unshift({ value: '', label: 'Select An Option' });
      } else {
        opts = [];
      }

      opts = alphabetizeOptionsList(opts);

      return opts;
    }
  }

  addCause() {
    let causes = [...this.state.selectedCauses];
    causes.push(this.state.causeValue);
    this.setState(
      {
        selectedCauses: causes,
        causeValue: '',
      },
      () => {
        let c = this.state.selectedCauses;
        this.props.updateEntity('causes', c);
      },
    );
  }

  removeCause(_id: string) {
    let causes = [...this.state.selectedCauses];
    causes = causes.filter((e) => e !== _id);

    this.setState(
      {
        selectedCauses: causes,
      },
      () => {
        let c = this.state.selectedCauses;
        this.props.updateEntity('causes', c);
      },
    );
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow}`}>
        <h2>Help potential donors find your cause</h2>
        <div className="details-row">
          <Dropdown
            options={this.state.classificationOptions}
            shouldSort={true}
            value={this.props.group?.group_class}
            label="Select a cause classification"
            name="groupClassification"
            onChange={(e) => {
              handleInputChange(e, this, false, this.handleGroupClassification, true);
            }}
          />
        </div>
        {this.state.subClassificationOptions && (
          <div className="details-row">
            <Dropdown
              options={this.state.subClassificationOptions}
              shouldSort={true}
              value={this.props.group?.group_sub_class}
              label="Select a cause sub-classification"
              name="groupSubClassification"
              onChange={(e) => {
                handleInputChange(e, this, false, this.handleGroupSubClassification, true);
              }}
            />
          </div>
        )}
        <div className="details-row">
          <span>What focus areas does your cause support?</span>
          <form
            className="add-cause"
            onSubmit={(e) => {
              e.preventDefault();
              this.addCause();
            }}
          >
            <TextField
              list="gigit-causes"
              placeholder="Add a focus area (Cancer Research, COVID-19, etc)"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
              value={this.state.causeValue}
              name="causeValue"
              type="text"
            />
            <datalist id="gigit-causes">
              {this.state.causes.map((item, index) => {
                return (
                  <option
                    key={index}
                    id={item.id}
                    value={item.cause}
                  />
                );
              })}
            </datalist>
            <Button
              isDisabled={this.state.causeValue === ''}
              icon={'fa fa-plus'}
              text="Add Focus Area"
              type="submit"
              leaveOnlyIconOnSmallScreen={true}
            />
          </form>
          <ul className="user-causes">
            {this.state.selectedCauses.map((item: any, index: number) => {
              return (
                <li
                  key={index}
                  className="cause no-select"
                >
                  <span>{item}</span>
                  {
                    <i
                      onClick={() => {
                        this.removeCause(item);
                      }}
                      className="fa fa-times-circle"
                    />
                  }
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    registerState: store.registerState,
  };
};

export default withTranslation('translations')(connect(mapStateToProps)(GroupClassifications));
