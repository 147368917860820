import React, {
  ChangeEvent,
  HTMLAttributes,
  LabelHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { combineClassNames } from '../../helpers';
import './TextField.scss';

/** Function that handles determining if an error is occuring. */
type ErrorCheckHandler = (isBlurred: boolean) => string | null;

//TODO: Implement textfield states
interface ITextFieldErrorState {
  type: 'ERROR' | 'SUCCESS' | 'WARNING' | 'INFO';
  message?: string;
}

// TODO [HOTFIX/3.4.4]: Fix these `any` types.
export interface ITextFieldProps {
  className?: string;
  value: string | number;
  name: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  icon?: string;
  type?: string;
  required?: boolean;
  min?: string;
  max?: string;
  maxLength?: number;
  minLength?: number;
  step?: string;
  helper?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onInputClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;

  id?: string;
  list?: string;
  pattern?: string;
  dataList?: string[];
  optionalText?: string;

  bottomLabel?: string;

  /** Shows an error message and outlines the textfield in red. */
  error?: string | ErrorCheckHandler;

  /** Render prop that is renderered before the input in the text input. */
  preTextRenderer?: () => ReactNode;

  /** Controls if we want to translate the placeholder or not. Defaults to true. */
  translatePlaceholder?: boolean;

  /** Controls if we want to translate the label or not. Defaults to true. */
  translateLabel?: boolean;

  /** Controls if we want to translate the helper or not. Defaults to true. */
  translatedHelper?: boolean;

  /** Render prop that is rendered after the input in the text input. */
  postTextRenderer?: () => ReactNode;

  /** Allows a function be called when clicking on inputs icon */
  onIconClick?: () => void;

  readOnly?: boolean;

  /** Adds an 'X' icon so an input can be cleared via a click */
  onClearInputValue?: () => void;

  /** Cleanup these types */
  onFocus?: any;
  blurCB?: any;
  reference?: any;
  autoComplete?: any;
  autoFill?: any;
  style?: any;
}

function TextField(props: ITextFieldProps) {
  const [isBlurred, setIsBlurred] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const inputRef = useRef(null);
  const className = props.className ?? '';
  const isDisabledClassName = props.disabled ? 'disabled' : '';
  const isInputFocusedClassName = isInputFocused ? 'focused' : '';

  let _props = { ...props };
  // Remove non-native <input/> props, otherwise we get a DOM error.
  delete _props.className;
  delete _props.dataList;
  delete _props.optionalText;
  delete _props.preTextRenderer;
  delete _props.blurCB;
  delete _props.translateLabel;
  delete _props.translatedHelper;
  delete _props.translatePlaceholder;
  delete _props.onIconClick;
  delete _props.postTextRenderer;
  delete _props.onClearInputValue;
  delete _props.onInputClick;
  delete _props.onFocus;

  const error = getError();
  let placeholder = props.placeholder;

  let labelProps: Partial<LabelHTMLAttributes<any>> = {};
  let helperProps: Partial<HTMLAttributes<any>> = {};

  // Manually translate placeholder, since we add notranslate to the <input> tag.
  if (placeholder && (props.translatePlaceholder ?? true)) {
    Localize.phrase(placeholder);
    placeholder = Localize.translate(placeholder);
  }

  if (props.translateLabel === false) {
    labelProps.notranslate = 'yes';
  }

  if (props.translatedHelper === false) {
    helperProps.notranslate = 'yes';
  }

  useEffect(() => {
    if (isBlurred && !!props.blurCB) {
      props.blurCB();
    }
  }, [isBlurred]);

  function getError() {
    switch (typeof props.error) {
      case 'string':
        return props.error;
      case 'function':
        return props.error(isBlurred);
      default:
        return null;
    }
  }

  function handleInputBlur() {
    setIsBlurred(true);
    setIsInputFocused(false);
  }

  function handleInputClick(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    if (props.disabled) {
      e.stopPropagation();
      e.preventDefault();

      return;
    }
    setIsInputFocused(true);
    props.onInputClick?.(e);
  }

  function handleInputFocus(e: React.FocusEvent<HTMLInputElement, Element>) {
    if (props.disabled) {
      e.stopPropagation();
      e.preventDefault();

      return;
    }
    setIsInputFocused(true);

    props.onFocus?.();
  }

  function handleClearInputValue(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.stopPropagation();
    props.onClearInputValue?.();
  }

  return (
    <div className={combineClassNames(className, 'TextField', isDisabledClassName)}>
      {props.label && (
        <label {...labelProps}>
          <span>
            {props.label}
            {props.required && <span className="required">*</span>}
          </span>
          {props.optionalText && <span className="opt-text">{props.optionalText}</span>}
          {props.helper !== undefined && (
            <div className="helper">
              <i className="fas fa-question-circle" />
              <span {...helperProps}>{props.helper}</span>
            </div>
          )}
        </label>
      )}
      <div
        className={combineClassNames(
          'input-wrap',
          error !== null ? 'error' : undefined,
          isDisabledClassName,
          isInputFocusedClassName,
        )}
      >
        {!!props.icon && (
          <i
            onClick={props.onIconClick}
            className={`main-icon ${props.icon} ${props?.onIconClick ? 'hoverable' : ''}`.trim()}
          />
        )}
        {props.dataList && (
          <datalist id={props.id}>
            {props.dataList.map((item, index) => (
              <option
                key={index}
                value={item}
              />
            ))}
          </datalist>
        )}
        {props.preTextRenderer?.()}
        <input
          ref={props.reference ? props.reference : inputRef}
          className={props.icon === undefined ? 'no-icon' : ''}
          notranslate="yes"
          {..._props}
          readOnly={props.readOnly}
          placeholder={placeholder}
          onBlur={handleInputBlur}
          onClick={handleInputClick}
          onFocus={handleInputFocus}
        />
        {props.onClearInputValue && !!props.value && !props.disabled && (
          <i
            onClick={(e) => handleClearInputValue(e)}
            className="clear-input-icon fas fa-times"
          />
        )}
        {props.postTextRenderer && props.postTextRenderer()}
      </div>
      {error !== null && <span className="error_text">{error}</span>}
      {!!props.bottomLabel && <span className="bottom-label">{props.bottomLabel}</span>}
    </div>
  );
}

export default TextField;
