import './HubStoreDetailItemModal.scss';
import React, { useEffect, useState } from 'react';
import Modal from '../../../Modal/Modal';
import { IGroup, IStoreItem, IEventSummaryFE, IHub } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import { connect } from 'react-redux';
import { addItem } from '../../../../actions/cart';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { ReduxActionType } from '../../../../interfaces';
import { defaultCurrency, formatCurrency } from '../../../../helpers';
import { Constants } from '@gigit/constants';
import useToastDispatcher from '../../../../hooks/useToaster';
import { eventRequestActions, groupRequestActions } from '../../../../requestActions';
import { Link } from 'react-router-dom';
import Carousel from '../../../Carousel/Carousel';
import auctionItemHelpers from '../../../PageComponent/AuctionItem/auctionItemHelpers';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';

interface IProps {
  addItem: ReduxActionType<typeof addItem>;
  show: boolean;
  onClose(): void;
  storeItem: IStoreItem;
  hubName?: string;
  hubLogoUrl?: string;
  locale: string;
  hub?: IHub;
}

const HubStoreDetailItemModal: React.FC<IProps> = (props) => {
  const { dispatchToastError } = useToastDispatcher();

  const [currency, setCurrency] = useState<string>(props.hub?.account?.currency || defaultCurrency);
  const [ownerInfo, setOwnerInfo] = useState<IGroup | IEventSummaryFE | null>(null);
  const [activeImg, setActiveImg] = useState<string>(
    props.storeItem.media.length > 0 ? props.storeItem.media[0]?.url || '' : '',
  );

  // TODO: ask BE to add all owner data to this interface not only owner_type and owner_id
  function getGroupInfo() {
    if (props.storeItem?.owner_id) {
      groupRequestActions
        .getGroupByHandleOrId(props.storeItem.owner_id)
        .then((res) => {
          setOwnerInfo(res);

          if (res?.account?.currency) {
            setCurrency(res.account.currency);
          }
        })
        .catch((error) => dispatchToastError(error, 'Get Cause Info'));
    }
  }

  function getEventInfo() {
    if (props.storeItem?.owner_id) {
      eventRequestActions
        .getEventByHandleOrId(props.storeItem.owner_id)
        .then((res) => {
          setOwnerInfo(res);

          if (res?.campaign?.account?.currency) {
            setCurrency(res.campaign.account.currency);
          }
        })
        .catch((error) => dispatchToastError(error, 'Get Cause Info'));
    }
  }

  function addToCart() {
    props.addItem(
      {
        ...props.storeItem,
        quantity: 1,
      },
      props.storeItem.owner_type,
    );

    props.onClose();
  }

  useEffect(() => {
    if (props.storeItem?.owner_type === Constants.object_type.group) {
      getGroupInfo();
    } else {
      getEventInfo();
    }
  }, [props.storeItem]);

  function getCarouselSlides() {
    return props.storeItem.media.map((img, index) => (
      <img
        src={img.url}
        alt={`store item picture ${index}`}
        key={index}
        onClick={() => setActiveImg(img.url)}
      />
    ));
  }

  return (
    <Modal
      class="HubStoreDetailItemModal"
      show={props.show}
      onClose={props.onClose}
      closeIcon="fas fa-times"
    >
      <div className="header">
        <img
          src={props.hubLogoUrl}
          alt="hub logo"
          className="hub-logo"
        />
        <div className="head-content">
          <div className="title">
            <h3>{props?.hubName}</h3>
            {/* <span className="checked">
                        <img src={CheckDecagram} alt="check mark" width="24px" height="24px"/>
                    </span> */}
          </div>
          <span className="category">
            {`Store > ${props.storeItem.owner_type === Constants.object_type.group ? 'Partner' : 'Company'} Items`}
          </span>
        </div>
      </div>
      <div className="main-container">
        <div className="media-container">
          {/* <img src={activeImg} alt="main, store item" className='main'/> */}
          <div className="pick-img-container">
            <Carousel
              infinite={false}
              slidesToScroll={1}
              slidesToShow={1}
              showArrows={true}
              nextArrow={
                <span>
                  <i className="fas fa-chevron-right" />
                </span>
              }
              prevArrow={
                <span>
                  <i className="fas fa-chevron-left" />
                </span>
              }
              showImagePagination={true}
              slides={getCarouselSlides()}
            />
          </div>
        </div>
        <div className="desc-container">
          <h4 notranslate="yes">{props.storeItem.name}</h4>
          <span
            className="owner"
            notranslate="yes"
          >
            <Link
              to={`/${props.storeItem.owner_type === 'hub' ? 'company' : props.storeItem.owner_type}/${ownerInfo?.handle || props.hub?.handle}`}
            >
              {ownerInfo?.title || props.hubName || 'N/A'}
            </Link>
          </span>
          <div className="tags-container">{/*TODO: add tags after BE is done*/}</div>
          <span
            className="price"
            notranslate="yes"
          >
            {formatCurrency(Number(props.storeItem.price || 0), currency, props.locale)}
          </span>
          <hr />
          <span className="desc-title">Description</span>
          <QuillTextEditor
            value={props.storeItem.description}
            readOnly={true}
            preserveWhitespace={true}
            theme={'bubble'}
            modules={auctionItemHelpers.quillModules}
            formats={auctionItemHelpers.quillFormats}
          />
        </div>
      </div>
      <div className="btn-container">
        <Button
          text="Cancel"
          type="button"
          buttonType="outline-dark"
          onClick={props.onClose}
        />
        <Button
          text="Add to Cart"
          type="button"
          buttonType="dark"
          onClick={() => addToCart()}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  addItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(HubStoreDetailItemModal);
