import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import { defaultCurrency, formatCurrency } from '../../helpers';
import { ICampaignFund } from '@gigit/interfaces';
import {
  IActiveFilter,
  IKpi,
  IOwnerObject,
  ReduxActionType,
  StatisticType,
} from '../../interfaces';
import { IGroupState } from '../../reducers/group';
import { IEventState } from '../../reducers/event';
import { IAuctionState } from '../../reducers/auction';
import { createGroupDonationIntent, getGroupStats } from '../../actions/group';
import { createEventDonationIntent, getEventStats } from '../../actions/event';
import { clearCart } from '../../actions/cart';
import DonationList from '../DonationList/DonationList';
import StorePurchases from '../StorePurchasesList/StorePurchases';
import GigPayments from '../GigPayments/GigPayments';
import AuctionTransactions from '../AuctionTransactions/AuctionTransactions';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import KpiDisplay from '../KpiDisplay/KpiDisplay';
import Checkout from '../../routes/Checkout/Checkout';
import ContextMenu from '../ContextMenu/ContextMenu';
import './TransactionManagement.scss';
import { userSelectors } from '../../selectors/user';
import { localizeHelpers } from '../../localizeHelpers';
import { createToast } from '../../actions/toaster';
import { uiConstants } from '../../constants';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  eventState: IEventState;
  auctionState: IAuctionState;
  owner: IOwnerObject;
  transactionsType: string;
  locale: string;
  createGroupDonationIntent: ReduxActionType<typeof createGroupDonationIntent>;
  createEventDonationIntent: ReduxActionType<typeof createEventDonationIntent>;
  clearCart(): void;
  getEventStats: ReduxActionType<typeof getEventStats>;
  getGroupStats: ReduxActionType<typeof getGroupStats>;
  createToast: ReduxActionType<typeof createToast>;
}

interface IState {
  searchValue: string;
  activeTab: string;
  donations: any[];
  showRefundModal: boolean;
  currency: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  apartment: string;
  city: string;
  selectedProvince: string;
  country: string;
  postal: string;
  otherAmount: string;
  donationAmount: number;
  formRef: any;
  selectedPayment: string;
  dedicate: boolean;
  refund_amount: number | string;
  order: any;
  showThankYouModal: boolean;
  thankYouMessage: string;
  currentTransaction: string;
  modules: any;
  formats: Array<any>;
  donationType: string;
  campaignFunds: any[];
  comments: string;
  anonymous: boolean;
  selectedDedicate: string;
  hFirst: string;
  hLast: string;
  rFirst: string;
  rLast: string;
  rEmail: string;
  rMessage: string;
  dPrefix: string;
  selectedDonor: any;
  genders: Array<any>;
  gender: string;
  provinces: Array<any>;
  dob: any;
  donateTo: string;
  selectedTeam: string;
  selectedIndividual: string;
  teams: any[];
  individuals: any[];
  isFundraiser: boolean;
  showAddStorePurchase: boolean;
  isCompany: boolean;
  companyName: string;
  filters: IActiveFilter[];
  showFilterModal: boolean;
  sendTaxReceipt: boolean;
  // Used to force tables to refresh after manually adding record.
  // TODO: We should move the Add modals into the List components themselves.
  refreshHandle: object | null;
}

class TransactionManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: '',
      activeTab: 'donation',
      donationType: 'once',
      donations: [],
      showRefundModal: false,
      currency: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      refund_amount: 0,
      order: {},
      showThankYouModal: false,
      thankYouMessage: '',
      currentTransaction: '',
      modules: {
        toolbar: [
          ['bold', 'italic'],
          [{ indent: '-1' }, { indent: '+1' }],
          ['link', 'clean'],
        ],
        clipboard: {
          matchVisual: false,
        },
      },
      formats: [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
      ],
      genders: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Other', value: 'other' },
      ],
      gender: 'male',
      address: '',
      apartment: '',
      city: '',
      provinces: [],
      selectedProvince: '',
      dob: moment(),
      country: '',
      postal: '',
      campaignFunds:
        this.props.owner.ownerType === uiConstants.ownerType.group
          ? this.getFundOptions(
              (this.props.groupState.currentCampaign &&
                this.props.groupState.currentCampaign.funds) ||
                [],
            )
          : this.getFundOptions(
              (this.props.eventState.currentCampaign &&
                this.props.eventState.currentCampaign.funds) ||
                [],
            ),
      donationAmount: 25,
      comments: '',
      otherAmount: '0',
      anonymous: false,
      dedicate: false,
      selectedDedicate: '',
      hFirst: '',
      hLast: '',
      rFirst: '',
      rLast: '',
      rEmail: '',
      rMessage: '',
      dPrefix: 'memory',
      selectedPayment: 'cash',
      formRef: React.createRef(),
      selectedDonor: {},
      donateTo: '',
      selectedTeam: '',
      selectedIndividual: '',
      teams: [],
      individuals: [],
      isFundraiser: false,
      showAddStorePurchase: false,
      isCompany: false,
      companyName: '',
      filters: [],
      showFilterModal: false,
      sendTaxReceipt: false,
      refreshHandle: null,
    };

    this.closeAddDonation = this.closeAddDonation.bind(this);
    this.getFundOptions = this.getFundOptions.bind(this);
    this.requestStats = this.requestStats.bind(this);
  }

  componentDidMount() {
    if (
      this.props.owner.ownerType === uiConstants.ownerType.event &&
      this.props.eventState.componentCount.length
    ) {
      this.props.eventState.componentCount.forEach((c: any, index: number) => {
        if (c.component_type === 'fundraising') {
          this.setState({
            isFundraiser: true,
          });
        }
        if (index === this.props.eventState.componentCount.length - 1) {
          this.setState({
            donateTo: uiConstants.ownerType.event,
          });
        }
      });
    } else {
      this.setState({ donateTo: uiConstants.ownerType.event });
    }
    this.requestStats();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.owner.ownerType === uiConstants.ownerType.event) {
      if (prevProps.eventState.teams !== this.props.eventState.teams) {
        let teams = this.props.eventState.teams.map((team) => {
          return { label: team.name, value: team.handle };
        });

        teams.unshift({ label: 'None', value: '' });

        this.setState(
          {
            teams: teams,
          },
          () => {
            if (this.state.teams[0] && this.state.selectedTeam === '') {
              this.setState({
                selectedTeam: teams[0].value,
              });
            }
          },
        );
      }

      if (prevProps.eventState.individuals !== this.props.eventState.individuals) {
        let individuals = this.props.eventState.individuals.map((individual) => {
          return {
            label: `${individual.user?.first_name} ${individual.user?.last_name}`,
            value: individual.user?.handle ?? '',
          };
        });

        individuals.unshift({ label: 'None', value: '' });

        this.setState(
          {
            individuals: individuals,
          },
          () => {
            if (this.state.individuals[0] && this.state.selectedIndividual === '') {
              this.setState({
                selectedIndividual: individuals[0].value,
              });
            }
          },
        );
      }
    }
  }

  requestStats() {
    if (this.props.owner.ownerType === uiConstants.ownerType.event) {
      switch (this.props.transactionsType) {
        case 'donationsPayments':
          this.props.getEventStats(this.props.owner.ownerId, StatisticType.DONATIONS);
          break;
        case 'storePayments':
          this.props.getEventStats(this.props.owner.ownerId, StatisticType.STORE_PUSRCHASES);
          break;
        case 'auctionsPayments':
          this.props.getEventStats(this.props.owner.ownerId, StatisticType.AUCTION_PURCHASES);
          break;
      }
    } else {
      switch (this.props.transactionsType) {
        case 'donationsPayments':
          this.props.getGroupStats(this.props.owner.ownerId, StatisticType.DONATIONS);
          break;
        case 'storePayments':
          this.props.getGroupStats(this.props.owner.ownerId, StatisticType.STORE_PUSRCHASES);
          break;
        case 'auctionsPayments':
          this.props.getGroupStats(this.props.owner.ownerId, StatisticType.AUCTION_PURCHASES);
          break;
      }
    }
  }

  setActiveTab(tab: string) {
    this.setState({
      activeTab: tab,
    });
  }

  getFundOptions(funds: any[]) {
    return funds.map((fund: ICampaignFund) => {
      return { label: fund.name, value: fund.name };
    });
  }

  closeAddDonation() {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      apartment: '',
      city: '',
      selectedProvince: '',
      country: '',
      postal: '',
      otherAmount: '',
      donationAmount: 25,
      anonymous: false,
      sendTaxReceipt: false,
      selectedDonor: {},
    });

    this.setState({
      refreshHandle: new Object(),
    });
  }

  getKpi() {
    let data: IKpi[] = [];
    const currency = this.props.owner.localization?.currency ?? defaultCurrency;

    switch (this.props.transactionsType) {
      case 'donationsPayments':
        const donationStats =
          this.props.owner.ownerType === uiConstants.ownerType.event
            ? this.props.eventState.donationsStats
            : this.props.groupState.donationsStats;
        if (donationStats) {
          data.push(
            {
              value: `${formatCurrency(donationStats.total_value_of_donations, currency, this.props.locale)}`,
              text: 'Total Donations',
            },
            {
              value: localizeHelpers.formatNumber(
                donationStats.total_number_of_donations || 0,
                this.props.locale,
              ),
              text: 'Number of Donations',
            },
            {
              value: `${formatCurrency(donationStats.average_donation_value, currency, this.props.locale)}`,
              text: 'Average Donation',
            },
            {
              value: localizeHelpers.formatNumber(
                donationStats.number_of_donors || 0,
                this.props.locale,
              ),
              text: 'Total Donors',
            },
          );
        }
        break;

      case 'storePayments':
        const storePurchasesStats =
          this.props.owner.ownerType === uiConstants.ownerType.event
            ? this.props.eventState.storePurchasesStats
            : this.props.groupState.storePurchasesStats;
        if (storePurchasesStats) {
          data.push(
            {
              value: `${formatCurrency(storePurchasesStats.total_value_of_purchases, currency, this.props.locale)}`,
              text: 'Total Sales',
            },
            {
              value: `${formatCurrency(storePurchasesStats.total_value_of_ticket_purchases, currency, this.props.locale)}`,
              text: 'Total Ticket Sales',
            },
            {
              value: `${formatCurrency(storePurchasesStats.total_value_of_merchandise_purchases, currency, this.props.locale)}`,
              text: 'Total Merchandise Sales',
            },
            {
              value: `${formatCurrency(storePurchasesStats.average_purchase_value, currency, this.props.locale)}`,
              text: 'Average Purchase Amount',
            },
            {
              value: storePurchasesStats.total_number_of_purchases.toString(),
              text: 'Total Number of Purchases',
            },
          );
        }
        break;

      case 'auctionsPayments':
        const auctionPurchasesStats =
          this.props.owner.ownerType === uiConstants.ownerType.event
            ? this.props.eventState.auctionPurchasesStats
            : this.props.groupState.auctionPurchasesStats;
        if (auctionPurchasesStats) {
          data.push(
            {
              value: `${formatCurrency(auctionPurchasesStats.total_winning_bid_amount, currency, this.props.locale)}`,
              text: 'Total Winning Bid Amount',
            },
            {
              value: localizeHelpers.formatNumber(
                auctionPurchasesStats.total_number_of_paid || 0,
                this.props.locale,
              ),
              text: 'Number of Paid',
            },
            {
              value: localizeHelpers.formatNumber(
                auctionPurchasesStats.total_number_of_unpaid || 0,
                this.props.locale,
              ),
              text: 'Number of Unpaid',
            },
            {
              value: `${formatCurrency(auctionPurchasesStats.average_winning_bid_amount, currency, this.props.locale)}`,
              text: 'Average Winning Bid Amount',
            },
          );
        }
        break;
    }

    if (data.length && !this.props.eventState.isStatsLoading) {
      return (
        <div className="section-kpi">
          <KpiDisplay kpiDataList={data} />
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="TransactionManagement section-wrap">
        {this.props.transactionsType === 'gigsPayments' && (
          <div className="section-title">
            <div className="forms-create">
              <div className="forms-title">Volunteer Opportunities Payments</div>
              <div className="forms-controls">
                <Button
                  className="filter-button"
                  icon="fal fa-filter"
                  onClick={() => {
                    this.setState({ showFilterModal: true });
                  }}
                  text={
                    this.state.filters.length > 0
                      ? 'Filter (' + this.state.filters.length + ')'
                      : 'Filter'
                  }
                  buttonType="outline-dark"
                />
              </div>
            </div>
          </div>
        )}

        {this.getKpi()}

        <div className="management-section-inner">
          <div className="TransactionManagement-list">
            {this.props.transactionsType === 'donationsPayments' && (
              <DonationList
                {...this.props}
                refreshHandle={this.state.refreshHandle}
              />
            )}
            {this.props.transactionsType === 'storePayments' && <StorePurchases {...this.props} />}
            {this.props.transactionsType === 'gigsPayments' && (
              <GigPayments owner={this.props.owner} />
            )}
            {this.props.transactionsType === 'auctionsPayments' && (
              <AuctionTransactions
                owner={this.props.owner}
                transactionItemUpdated={this.requestStats}
              />
            )}
          </div>
        </div>

        <Modal
          class="add-store-purchase-modal"
          show={this.state.showAddStorePurchase}
          onClose={() => {
            this.setState({ showAddStorePurchase: false }, () => {
              this.props.clearCart();
            });
          }}
        >
          <Checkout
            {...this.props}
            ownerId={this.props.owner.ownerId}
            adminPurchase={true}
            adminParentType={this.props.owner.ownerType}
            onAdminPurchase={() => {
              this.setState({ showAddStorePurchase: false });
            }}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    auctionState: store.auctionState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createGroupDonationIntent,
  createEventDonationIntent,
  clearCart,
  getEventStats,
  getGroupStats,
  createToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionManagement));
