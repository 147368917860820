import React, { ChangeEvent } from 'react';

import { IPage } from '@gigit/interfaces';
import { localizeHelpers } from '../../../../localizeHelpers';

import Modal from '../../../Modal/Modal';

import './ManageTabsModal.scss';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd';
import TextField from '../../../../components/TextField/TextField';
import { Prompt } from '../../../../components/Prompt/Prompt';
import { withTranslation, WithTranslation } from 'react-i18next';
import Loader from '../../../Loader/Loader';
import Button from '../../../Button/Button';

interface IProps extends WithTranslation {
  pages: IPage[];
  isLoading: boolean;
  show?: boolean;
  onClose: () => void;
  onReorderPage(sourceIndex: number, destinationIndex: number | null): void;
  onCreatePage(page: Partial<IPage>): void;
  onUpdatePage(pageId: string, page: IPage): void;
  onDeletePage(pageId: string): void;
}

interface IState {
  editPageNameIndex: number | null;
  editPageName: string | null;
  tabsList: React.RefObject<HTMLDivElement>;
  inputRef: React.RefObject<HTMLInputElement>;

  pageToDelete: { index: number; name: string } | null;
}

/** Modal for managing Pages. User can create, edit and delete page tabs. */
export class ManageTabsModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tabsList: React.createRef(),
      inputRef: React.createRef(),
      editPageNameIndex: null,
      editPageName: null,
      pageToDelete: null,
    };

    this.getRenderItem = this.getRenderItem.bind(this);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.pages.length === prevProps.pages.length + 1) {
      //when new tab added
      this.scrollTabsListToTheBottom();
    }

    if (this.state.editPageNameIndex !== null) {
      this.state.inputRef.current?.focus();
    }

    if (prevProps.show !== this.props.show && !this.props.show) {
      this.setState({
        editPageNameIndex: null,
        editPageName: null,
      });
    }
  }

  render() {
    const t = this.props.t;

    return (
      <Modal
        show={this.props.show != null ? this.props.show : true}
        onClose={this.props.onClose}
        title={t('Manage Tabs')}
        description={t(
          'Add, sort, edit and delete tabs. Deleting a tab will also delete its components.',
        )}
      >
        <div className="ManageTabsModal">
          <DragDropContext
            onDragEnd={(result) => {
              this.props.onReorderPage(
                result.source.index,
                result.destination != null ? result.destination.index : null,
              );
            }}
          >
            <Droppable
              droppableId="pages"
              renderClone={this.getRenderItem}
            >
              {(provided, snapshot) => (
                <>
                  <div
                    className={`tab-scroller no-select ${this.state.editPageNameIndex !== null ? 'edit-name-enabled' : ''}`}
                    ref={this.state.tabsList}
                  >
                    <ul
                      className="tab-container"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {this.props.pages.map((page, index) => this.renderTab(page, index))}
                      {provided.placeholder}
                    </ul>
                  </div>
                </>
              )}
            </Droppable>
          </DragDropContext>
          <Loader loading={this.props.isLoading} />
          <div className="actions">
            <Button
              icon="far fa-plus"
              className="add-tab-button"
              text={t('Add Tab')}
              onClick={this.createNewPage}
            />
            <Button
              text={t('Close')}
              onClick={this.props.onClose}
            />
          </div>
        </div>

        <Prompt
          show={!!this.state.pageToDelete}
          title="Delete Page"
          message={localizeHelpers.translate('Are you sure you want to delete page {{name}}?', {
            name: this.state.pageToDelete?.name,
          })}
          yesClass="far fa-trash"
          yesMessage="Delete"
          cancelMessage="Cancel"
          onYes={() => this.deletePage(this.state.pageToDelete!.index)}
          onClose={() => this.setState({ pageToDelete: null })}
        />
      </Modal>
    );
  }

  getRenderItem(
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric,
  ) {
    return (
      <div
        key={rubric.source.index}
        className="ManageTabsModal-tab"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        notranslate="yes"
      >
        <i className="far fa-grip-lines drag-indicator" />

        {this.state.editPageNameIndex === rubric.source.index ? (
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              this.savePageName();
            }}
            className="editPageName-form"
          >
            <TextField
              reference={this.state.inputRef}
              name="tempPageName"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                this.updatePageName(e.target.value);
              }}
              value={this.state.editPageName || ''}
            />
          </form>
        ) : (
          <div className="tab-name">{this.props.pages[rubric.source.index].label}</div>
        )}

        <button
          className={`edit-button ${this.state.editPageNameIndex === rubric.source.index ? 'far fa-save' : 'far fa-pencil-alt'}`}
          onClick={() => this.toggleEditPageName(rubric.source.index)}
        />
        {this.props.pages.length > 1 && (
          <button
            className="far fa-trash-alt delete-button"
            onClick={() =>
              this.setState({
                pageToDelete: {
                  index: rubric.source.index,
                  name: this.props.pages[rubric.source.index].label,
                },
              })
            }
          />
        )}
      </div>
    );
  }

  renderTab(page: IPage, index: number) {
    return (
      <Draggable
        key={page.id}
        draggableId={'page-' + page.id}
        index={index}
      >
        {(provided, snapshot, rubric) => this.getRenderItem(provided, snapshot, rubric)}
      </Draggable>
    );
  }

  scrollTabsListToTheBottom = () => {
    const container = this.state.tabsList.current;
    if (container) {
      container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
    }
  };

  createNewPage = () => {
    let _newPage = {
      id: '',
      owner_type: '',
      owner_id: '',
      owner_handle: '',
      sequence: 0,
      created_at: new Date(),
      updated_at: new Date(),
      label: 'New Page',
      icon: 'fad fa-info-circle',
    };

    this.props.onCreatePage(_newPage);
  };

  updatePageName = (newName: string) => {
    this.setState({
      editPageName: newName,
    });
  };

  toggleEditPageName = (index: number) => {
    if (this.state.editPageNameIndex === index) {
      this.savePageName();
    } else {
      this.setState({
        editPageNameIndex: index,
        editPageName: this.props.pages[index].label,
      });
    }
  };

  savePageName = () => {
    if (this.state.editPageName && this.state.editPageNameIndex !== null) {
      let _updatedPage = { ...this.props.pages[this.state.editPageNameIndex] };
      _updatedPage.label = this.state.editPageName;

      this.props.onUpdatePage(_updatedPage.id, _updatedPage);

      this.setState({
        editPageName: null,
        editPageNameIndex: null,
      });
    }
  };

  deletePage = (index: number) => {
    this.props.onDeletePage(this.props.pages[index].id);
    this.setState({
      pageToDelete: null,
      editPageNameIndex: null,
    });
  };
}

export default withTranslation('translations')(ManageTabsModal);
