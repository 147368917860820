import React, { FC, useRef, useState, useEffect } from 'react';
import { Config } from '@gigit/config';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../../shared/PopupMenu/PopupMenu';
import { userRequestActions } from '../../../../requestActions';
import { Link } from 'react-router-dom';
import './MyTransactions.scss';
import {
  capitalizeString,
  defaultCurrency,
  formatCurrency,
  typeHelpers,
} from '../../../../helpers';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import {
  IAuctionItem,
  IAuctionItemSummary,
  IGroup,
  ITransaction,
  ITransactionItemSummary,
  ITransactionSummary,
} from '@gigit/interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { Constants } from '@gigit/constants';
import { IUserState } from '../../../../reducers/user';
import auctionItemHelpers from '../../../PageComponent/AuctionItem/auctionItemHelpers';
import useToastDispatcher from '../../../../hooks/useToaster';
import Table, { ITableProps } from '../../../shared/Table/Table';
import StatusAllIcon from '../../../../assets/states_all_icon.svg';
import StatusRefundedIcon from '../../../../assets/states_pending_icon.svg';
import StatusPaidIcon from '../../../../assets/states_approved_icon.svg';
import StatusUnpaidIcon from '../../../../assets/states_not_accepted_icon.svg';
import AuctionItemPayModal from '../../../PageComponent/AuctionItem/AuctionItemPayModal/AuctionItemPayModal';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Prompt } from '../../../Prompt/Prompt';
import { uiConstants } from '../../../../constants';
import RequestMatchModal from './RequestMatchModal/RequestMatchModal';
import { DonationListExpandedCell } from '../../../DonationList/DonationListExpandedCell/DonationListExpandedCell';
import CommunityFeedDonationModal, {
  IDonationItem,
} from '../CommunityFeed/CommunityFeedDonationModal/CommunityFeedDonationModal';
import Modal from '../../../Modal/Modal';
import AddDonation from '../../../AddDonation/AddDonation';

type SectionID = 'donations' | 'purchases' | 'tickets' | 'auction_items';
type TDonationType = 'Once' | 'Recurring';
interface ISectionConfig {
  title: string;
}

type SectionConfigs = {
  [key in SectionID]: ISectionConfig;
};

const statusOptions = {
  paid: {
    value: Constants.application_status.pending,
    label: 'Paid',
    icon: StatusPaidIcon,
  },
  unpaid: {
    value: Constants.application_status.approved,
    label: 'Unpaid',
    icon: StatusUnpaidIcon,
  },
  refunded: {
    value: Constants.application_status.rejected,
    label: 'Refunded',
    icon: StatusRefundedIcon,
  },
  all: {
    value: 'all',
    label: 'All',
    icon: StatusAllIcon,
  },
};

const MyTransactions: FC = () => {
  const statusDropdownRef = useRef<HTMLDivElement>(null);
  const userState: IUserState = useSelector((state: IAppState) => state.userState);
  const [currentSectionId, setCurrentSectionId] = useState<SectionID>('donations');
  const [showSectionContextMenu, setShowSectionContextMenu] = useState<boolean>(false);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(statusOptions.all);
  const [auctionItemToPay, setAuctionItemToPay] = useState<IAuctionItem | null>(null);
  const [showFilterMobile, setShowFilterMobile] = useState<boolean>(false);
  const [recurringPaymentToCancel, setRecurringPaymentToCancel] = useState<boolean>(false);
  const [paymentToCancel, setPaymentToCancel] = useState<ITransactionSummary>();
  const [userSubscriptions, setUserSubscriptions] = useState<ITransactionSummary[]>();
  const [loadingUserSubscriptions, setLoadingUserSubscriptions] = useState<boolean>(false);

  const [showMakeADonationModal, setShowMakeADonationModal] = useState<boolean>(false);
  const [showOfflineDonationModal, setShowOfflineDonationModal] = useState<boolean>(false);
  const [showOfflineDonationOptionsModal, setShowOfflineDonationOptionsModal] =
    useState<boolean>(false);
  const [selectedDonationItem, setSelectedDonationItem] = useState<IDonationItem>();

  const [requestMatchData, setRequestMatchData] = useState<{
    hub_id: string;
    hub_title: string;
    transaction_id: string;
    currency: string;
    donation_amount: number;
    group_id: string;
    donation_status: string;
  } | null>(null);

  const [donationType, setDonationType] = useState<TDonationType>('Once');
  const [donationRecurringrefreshTableIncrementor, setDonationRecurringrefreshTableIncrementor] =
    useState<number>(0);
  const [donationOnceRefreshTableIncrementor, setDonationOnceRefreshTableIncrementor] =
    useState<number>(0);
  const [editOfflineDonation, setEditOfflineDonation] = useState<boolean>(false);

  const { dispatchToastError, dispatchToastInfo, dispatchToastSuccess } = useToastDispatcher();
  const sectionPopupMenuConfig: IShowPopupConfig = {
    showMenu: showSectionContextMenu,
    setShowMenu: setShowSectionContextMenu,
    position: {
      type: 'bottom',
    },
  };
  const getShowSectionMenuClass = showSectionContextMenu ? 'show' : '';

  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const location = useLocation();
  const urlParams = queryString.parse(location.search);

  useEffect(() => {
    const getAuctionItem = async (auctionId: string) => {
      const userAuctionItems = await userRequestActions.getUserAuctionItems();
      const findAuctionItemToPay = userAuctionItems.find((item) => item.id === auctionId);
      if (findAuctionItemToPay) {
        setAuctionItemToPay(findAuctionItemToPay);
      }
    };

    if (
      urlParams.section == 'activity' &&
      urlParams.tab == 'transactions' &&
      urlParams.type == 'auction'
    ) {
      setCurrentSectionId('auction_items');
      if (urlParams.auction) {
        getAuctionItem(urlParams.auction.toString());
      }
    }
  }, [urlParams.auction]);

  const sectionConfig: SectionConfigs = {
    donations: {
      title: 'Donations',
    },
    tickets: {
      title: 'Tickets',
    },
    purchases: {
      title: 'Purchases',
    },
    auction_items: {
      title: 'Auction Items',
    },
  };

  const currentSectionConfig = sectionConfig[currentSectionId];

  const sectionContextMenuItems: IPopupMenuItem[] = [
    {
      id: 'donations',
      label: sectionConfig.donations.title,
      isSelected: currentSectionConfig === sectionConfig.donations,
      onClick: () => {
        setCurrentSectionId('donations');
      },
    },
    {
      id: 'tickets',
      label: sectionConfig.tickets.title,
      isSelected: currentSectionConfig === sectionConfig.tickets,
      onClick: () => {
        setCurrentSectionId('tickets');
      },
    },
    {
      id: 'purchases',
      label: sectionConfig.purchases.title,
      isSelected: currentSectionConfig === sectionConfig.purchases,
      onClick: () => {
        setCurrentSectionId('purchases');
      },
    },
    {
      id: 'auction_items',
      label: sectionConfig.auction_items.title,
      isSelected: currentSectionConfig === sectionConfig.auction_items,
      onClick: () => {
        setCurrentSectionId('auction_items');
      },
    },
  ];

  function getPaymentStatus(transaction?: ITransactionSummary | ITransaction): string {
    if (transaction?.amounts.is_refund) {
      return 'refunded';
    }

    if (transaction?.amounts.payment_status) {
      return transaction.amounts.payment_status.code;
    }

    return 'unpaid';
  }

  function getTotalTransactionItemValue(ticket: ITransactionItemSummary) {
    const { transaction, purchase, donation } = ticket;
    const amount =
      transaction?.amounts.total! * purchase.amount_multiplier! +
      donation.total * donation.amount_multiplier!;
    return formatCurrency(amount, ticket.purchase.currency, locale);
  }

  function getTotalAuctionItemValue(auction: IAuctionItemSummary) {
    let amount = auction.amounts?.amount ?? 0;

    // Include fees in total when group covers fees. Otherwise the amount displayed here will be incorrect.
    const groupCoversGigitFees = auction.fee_control?.covers_gigit_fees ?? false;
    if (groupCoversGigitFees) {
      amount += auction.amounts?.gigit_fee ?? 0;
    }

    const groupCoversPlatformFees = auction.fee_control?.covers_processing_fees ?? false;
    if (groupCoversPlatformFees) {
      amount += auction.amounts?.payment_platform_fee ?? 0;
    }

    return formatCurrency(amount, auction.amounts?.currency ?? defaultCurrency, locale);
  }

  function getAuctionStatus(item: IAuctionItem) {
    const paymentMethod = item.amounts?.payment_method || '';
    const paymentStatus = item.payment_status?.code || '';

    if (!paymentStatus || !paymentMethod) {
      return 'None';
    }

    if (item.status?.code === Constants.auction_item_status.running) {
      //if auction still going
      if (paymentStatus === 'paid' || paymentMethod === 'invoice') {
        if (item.current_bid && item.current_bid.user_id === userState.user.id) {
          //if current user last bidder
          return paymentStatus;
        } else {
          return 'outbid';
        }
      } else {
        return 'in-progress';
      }
    } else {
      //if auction has finished
      if (item.current_bid && item.current_bid.user_id === userState.user.id) {
        //if current user last bidder
        if (paymentStatus === 'paid') {
          return 'paid';
        } else {
          if (paymentMethod === 'invoice') {
            return paymentStatus;
          } else {
            return 'won';
          }
        }
      } else {
        return 'outbid';
      }
    }
  }

  function getSubscriptionStatus(donation: ITransactionSummary) {
    const subscription = userSubscriptions?.find(
      (sub) => sub.account?.subscription?.last_donation?.transaction_id === donation.id,
    );
    if (subscription?.account?.subscription?.status?.code === 'active') {
      return 'active';
    }

    return 'canceled';
  }

  async function handleCancelReoccurringDonation(donationId: string) {
    const subIdToCancel = userSubscriptions?.find(
      (sub) => sub.account?.subscription?.last_donation?.transaction_id === donationId,
    )?.id;

    try {
      await userRequestActions.cancelUserSubscription(subIdToCancel ?? donationId);
      dispatchToastSuccess(
        localizeHelpers.translate('Your donation Subscription has been cancelled.'),
        'Cancel Donation Subscription',
      );
      setDonationRecurringrefreshTableIncrementor(donationRecurringrefreshTableIncrementor + 1);
    } catch (error) {
      dispatchToastError(error, 'Cancel Donation Subscription');
    }
  }

  async function handleGetUserDonations(query?: URLSearchParams) {
    let donationsData: ITransactionSummary[] = [];

    try {
      if (donationType === 'Once') {
        const result = await userRequestActions.getUserDonations(query);
        donationsData = result.filter((donation) => !donation.account?.subscription);
      } else {
        donationsData = await userRequestActions.getUserSubscriptions();
      }
    } catch (error) {
      dispatchToastError(error, 'Get User donations');
    }

    return donationsData;
  }

  const statusOptionsArray = [
    {
      value: statusOptions.paid.value,
      label: statusOptions.paid.label,
    },
    {
      value: statusOptions.unpaid.value,
      label: statusOptions.unpaid.label,
    },
    {
      value: statusOptions.refunded.value,
      label: statusOptions.refunded.label,
    },
    {
      value: statusOptions.all.value,
      label: statusOptions.all.label,
    },
  ];

  const donationRecurringSectionTableProps: ITableProps<ITransactionSummary> = {
    columns: [
      {
        id: 'amounts.amount',
        Header: 'Donation Amount',
        accessor: ({ amounts }) => amounts.amount * amounts.amount_multiplier!,
        Cell: ({ amounts }) => (
          <div>
            {formatCurrency(amounts.amount * amounts.amount_multiplier!, amounts.currency, locale)}
          </div>
        ),
      },
      {
        id: 'recipient_title',
        Header: 'Recipient',
        accessor: (item) => item.recipient_title,
        sortable: true,
        notranslate: 'yes',
        Cell: (item) => (
          <Link
            className="table-link"
            to={
              item?.hub_handle
                ? `/${uiConstants.ownerType.company}/${item.hub_handle}`
                : `/${uiConstants.ownerType.group}/${item.group_handle}`
            }
            notranslate="yes"
          >
            {item.recipient_title}
          </Link>
        ),
      },
      {
        sortable: true,
        id: 'created_at',
        Header: 'Start Date',
        accessor: (item) =>
          localizeHelpers.formatDate(item.created_at ?? new Date(), LocaleDateFormats.ll, locale),
        notranslate: 'yes',
      },
      {
        id: 'account.subscription.last_donation.created_at',
        Header: 'Next Donation (Date)',
        accessor: (item) => {
          if (
            item.account?.subscription?.status?.code === 'active' &&
            item.account.subscription.last_donation?.created_at
          ) {
            const lastDate = item.account.subscription.last_donation.created_at;
            const nextDate = new Date(lastDate);
            nextDate.setMonth(nextDate.getMonth() + 1);
            return localizeHelpers.formatDate(nextDate, LocaleDateFormats.ll, locale);
          } else {
            return 'N/A';
          }
        },
        notranslate: 'yes',
      },
      {
        id: 'account.subscription.status.code',
        Header: 'Status',
        sortable: true,
        predefinedColumnType: {
          type: 'STATUS',
          columnObject: () => ({
            canceled: {
              label: 'Canceled',
              color: 'RED',
            },
            pending: {
              label: 'Pending',
              color: 'YELLOW',
            },
            active: {
              label: 'Active',
              color: 'GREEN',
            },
            inProgress: {
              label: 'In progress',
              color: 'GREY',
            },
          }),
        },
        accessor: (item) => item.account?.subscription?.status?.code,
      },
    ],
    pagination: {
      pageSizeOptions: [10],
      queryAction: handleGetUserDonations,
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any donations",
    },
    tableActionOptions: {
      enableRowContextMenuActions: true,
      enableRowButtonActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Cancel Reccurring Donation',
          icon: 'fal fa-trash',
          onClick: async (e, donationTransactionSummary) => {
            setRecurringPaymentToCancel(true);
            setPaymentToCancel(donationTransactionSummary);
          },
        },
        {
          type: 'HEADER',
          label: 'Export',
          buttonType: 'secondary',
          onClick: async () =>
            await userRequestActions.getDonationsExport(userState.user.id!, userState.user.handle),
        },
      ],
    },
    filterOptions: {
      enableTableSearch: true,
      enableColumnSorting: true,
    },
  };

  const donationOnceSectionTableProps: ITableProps<ITransactionSummary> = {
    columns: [
      {
        id: 'amounts.amount',
        Header: 'Amount',
        sortable: true,
        accessor: ({ amounts }) => amounts.amount * amounts.amount_multiplier!,
        Cell: ({ amounts }) => (
          <div>
            {formatCurrency(amounts.amount * amounts.amount_multiplier!, amounts.currency, locale)}
          </div>
        ),
      },
      {
        id: 'recipient_title',
        Header: 'Recipient',
        accessor: (item) => item.recipient_title,
        sortable: true,
        notranslate: 'yes',
        Cell: (item) => (
          <Link
            className="table-link"
            to={
              item?.hub_handle
                ? `/${uiConstants.ownerType.company}/${item.hub_handle}`
                : `/${uiConstants.ownerType.group}/${item.group_handle}`
            }
            notranslate="yes"
          >
            {item.recipient_title}
          </Link>
        ),
      },
      {
        id: 'created_at',
        Header: 'Date',
        sortable: true,
        accessor: (item) =>
          localizeHelpers.formatDate(item.created_at ?? new Date(), LocaleDateFormats.ll, locale),
        notranslate: 'yes',
      },
      {
        id: 'amounts.payment_status.code',
        Header: 'Status',
        sortable: true,
        predefinedColumnType: {
          type: 'STATUS',
          columnObject: () => ({
            refunded: {
              label: 'Refunded',
              color: 'RED',
            },
            canceled: {
              label: 'Canceled',
              color: 'RED',
            },
            pending: {
              label: 'Pending',
              color: 'YELLOW',
            },
            actice: {
              label: 'Active',
              color: 'GREEN',
            },
            paid: {
              label: 'Paid',
              color: 'GREEN',
            },
            unpaid: {
              label: 'Unpaid',
              color: 'GREY',
            },
            inProgress: {
              label: 'In progress',
              color: 'GREY',
            },
          }),
        },
        accessor: (item) => getPaymentStatus(item),
      },
      {
        id: 'donation_match.match_status.code',
        Header: 'Match Status',
        sortable: true,
        accessor: (item) => item.donation_match?.match_status.code || 'not_applicable',
        predefinedColumnType: {
          type: 'STATUS',
          columnObject: () => ({
            pending: {
              label: 'Pending',
              color: 'YELLOW',
            },
            declined: {
              label: 'Declined',
              color: 'RED',
            },
            not_applicable: {
              label: 'Not Applicable',
              color: 'GREY',
            },
            pay_out: {
              label: 'Not Applicable',
              color: 'GREY',
            },
            matched: {
              label: 'Matched',
              color: 'GREEN',
            },
          }),
        },
      },
      {
        id: 'donation_match.amount_matched',
        Header: 'Matched Amt.',
        sortable: true,
        accessor: (item) =>
          item?.donation_match
            ? formatCurrency(
                item.donation_match?.amount_matched,
                item.amounts.currency || defaultCurrency,
                locale,
              )
            : '',
        Cell: (item) => (
          <div>
            {item?.donation_match
              ? formatCurrency(
                  item.donation_match?.amount_matched,
                  item.amounts.currency || defaultCurrency,
                  locale,
                )
              : ''}
          </div>
        ),
      },
    ],
    pagination: {
      pageSizeOptions: [10],
      queryAction: handleGetUserDonations,
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any donations",
    },
    tableActionOptions: {
      tableActions: [
        {
          type: 'ROW_BUTTON',
          onClick: (e, rowData) => {
            e.stopPropagation();
            if ((rowData?.donation_match?.hub_id && rowData?.group_id && rowData?.id) || true) {
              setRequestMatchData({
                hub_id: rowData?.donation_match?.hub_id!,
                hub_title: rowData?.hub_title || '',
                transaction_id: rowData?.id!,
                currency: rowData.amounts.currency,
                donation_amount: rowData.amounts.amount,
                group_id: rowData.group_id,
                donation_status: getPaymentStatus(rowData),
              });
            }
          },
          label: 'Request Match',
          buttonType: 'dark',
          hideIf: (rowData) => true, //hidden for now, Chris confirmed
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Edit',
          icon: 'fal fa-pencil',
          hideIf: (rowData) =>
            rowData.donation_match?.match_status.code ===
            Constants.donation_matching_transaction_status.matched,
          onClick: async (e, donationTransactionSummary) => {
            setEditOfflineDonation(true);
            setShowOfflineDonationModal(true);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Download Receipt',
          icon: 'fal fa-download',
          onClick: async (e, donationTransactionSummary) => {
            if (getPaymentStatus(donationTransactionSummary) === Constants.payment_status.unpaid) {
              dispatchToastInfo(
                localizeHelpers.translate(
                  "Can't download, reason: The donation is not processed yet!",
                ),
                'Downloading Donation Receipt',
              );
            } else {
              if (!donationTransactionSummary.group_id) {
                dispatchToastInfo(
                  localizeHelpers.translate(
                    "Can't download, reason: Can only download Donation receipt from a Cause!",
                  ),
                  'Downloading Donation Receipt',
                );
              } else if (
                donationTransactionSummary.amounts.payment_method ===
                Constants.payment_method.global_giving
              ) {
                dispatchToastInfo(
                  localizeHelpers.translate("Can't download, reason: Global giving donation!"),
                  'Downloading Donation Receipt',
                );
              } else {
                try {
                  await userRequestActions.downloadDonationReceipt(donationTransactionSummary);
                } catch (error) {
                  dispatchToastError(error, 'Error Downloading Donation Receipt');
                }
              }
            }
          },
        },

        {
          type: 'HEADER',
          label: 'Make Donation',
          buttonType: 'dark',
          onClick: () => setShowMakeADonationModal(true),
        },
        {
          type: 'HEADER',
          label: 'Offline Donation',
          buttonType: 'dark',
          onClick: () => setShowOfflineDonationOptionsModal(true),
        },
        {
          type: 'HEADER',
          label: 'Export',
          buttonType: 'secondary',
          onClick: async () =>
            await userRequestActions.getDonationsExport(userState.user.id!, userState.user.handle),
        },
      ],
      enableRowContextMenuActions: true,
      enableRowButtonActions: true,
      enableMultiSelectActions: true,
      rowDetailDrawerOptions: (row) => {
        return {
          title: 'Donation Matching Details',
          renderContent: () => <DonationListExpandedCell donation={row} />,
        };
      },
    },
    filterOptions: {
      enableTableSearch: true,
      enableColumnSorting: true,
      enableFilterDrawer: true,
      filterDrawerOptions: [
        {
          type: 'selectField',
          fieldId: 'amounts.payment_status.code',
          options: {
            label: 'Status',
            options: statusOptionsArray,
          },
        },
      ],
    },
  };

  const ticketSectionTableProps: ITableProps<ITransactionItemSummary> = {
    columns: [
      {
        id: 'name',
        Header: 'Item Name',
        accessor: ({ name }) => name,
        notranslate: 'yes',
      },
      {
        id: 'amounts.amount',
        Header: 'Total',
        accessor: (item) => getTotalTransactionItemValue(item),
        Cell: (item) => <span notranslate="yes">{getTotalTransactionItemValue(item)}</span>,
      },
      {
        id: 'event_title',
        Header: 'Event',
        accessor: (item) => item.event_title,
        sortable: true,
        notranslate: 'yes',
        Cell: (item) => (
          <Link
            className="table-link"
            to={`/${uiConstants.ownerType.event}/${item.event_handle}`}
            notranslate="yes"
          >
            {item.event_title}
          </Link>
        ),
      },
      {
        id: 'recipient_title',
        Header: 'Recipient',
        accessor: (item) => item.recipient_title,
        sortable: true,
        notranslate: 'yes',
        Cell: (item) => (
          <Link
            className="table-link"
            to={
              item?.hub_handle
                ? `/${uiConstants.ownerType.company}/${item.hub_handle}`
                : `/${uiConstants.ownerType.group}/${item.group_handle}`
            }
            notranslate="yes"
          >
            {item.recipient_title}
          </Link>
        ),
      },
      {
        id: 'amounts.payment_method',
        Header: 'Payment',
        accessor: (item) => capitalizeString(item?.transaction?.amounts.payment_method),
        sortable: true,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: (item) => getPaymentStatus(item.transaction),
        Cell: (item) => (
          <span className={`payment-status ${getPaymentStatus(item.transaction)}`}>
            {capitalizeString(getPaymentStatus(item.transaction))}
          </span>
        ),
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Download Receipt',
          icon: 'fal fa-download',
          onClick: async (_, item) => {
            if (getPaymentStatus(item.transaction) === Constants.payment_status.unpaid) {
              dispatchToastInfo(
                localizeHelpers.translate("Can't download, reason: The purchase is not paid yet!"),
                'Downloading Purchase Receipt',
              );
            } else {
              if (item.transaction_id) {
                try {
                  await userRequestActions.downloadPurchaseReceipt(item.transaction_id);
                } catch (error) {
                  dispatchToastError(error, 'Error Downloading Purchase Receipt');
                }
              } else {
                dispatchToastError(
                  localizeHelpers.translate('Transaction ID is undefined!'),
                  'Downloading Purchase Receipt',
                );
              }
            }
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Download Ticket',
          icon: 'fal fa-ticket',
          onClick: async (_, item) => {
            if (getPaymentStatus(item.transaction) === Constants.payment_status.unpaid) {
              dispatchToastInfo(
                localizeHelpers.translate("Can't download, reason: The ticket is not paid yet!"),
                'Downloading Ticket',
              );
            } else if (item.id) {
              try {
                await userRequestActions.downloadTicket(item.id);
              } catch (error) {
                dispatchToastError(error, 'Error Downloading Ticket');
              }
            } else {
              dispatchToastError(
                localizeHelpers.translate('Item ID is undefined!'),
                'Error Downloading Ticket',
              );
            }
          },
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: userRequestActions.getUserTickets,
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any ticket transactions",
    },
    filterOptions: {
      enableTableSearch: true,
    },
  };

  const purchaseSectionTableProps: ITableProps<ITransactionItemSummary> = {
    columns: [
      {
        id: 'name',
        Header: 'Item Name',
        accessor: ({ name }) => name,
        notranslate: 'yes',
      },
      {
        id: 'total',
        Header: 'Total',
        accessor: (item) => getTotalTransactionItemValue(item),
        Cell: (item) => <span notranslate="yes">{getTotalTransactionItemValue(item)}</span>,
      },
      {
        id: 'date',
        Header: 'Date',
        accessor: (item) =>
          localizeHelpers.formatDate(
            item.transaction?.created_at ?? new Date(),
            LocaleDateFormats.ll,
            locale,
          ),
        Cell: (item) => (
          <span notranslate="yes">
            {localizeHelpers.formatDate(
              item.transaction?.created_at ?? new Date(),
              LocaleDateFormats.ll,
              locale,
            )}
          </span>
        ),
        sortable: true,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: (item) => getPaymentStatus(item.transaction),
        Cell: (item) => (
          <span className={`payment-status ${getPaymentStatus(item.transaction)}`}>
            {capitalizeString(getPaymentStatus(item.transaction))}
          </span>
        ),
      },
      {
        id: 'recipient_title',
        Header: 'Recipient',
        notranslate: 'yes',
        accessor: (item) => item.recipient_title,
        sortable: true,
        Cell: (item) => (
          <Link
            className="table-link"
            to={
              item?.hub_handle
                ? `/${uiConstants.ownerType.company}/${item.hub_handle}`
                : `/${uiConstants.ownerType.group}/${item.group_handle}`
            }
            notranslate="yes"
          >
            {item.recipient_title}
          </Link>
        ),
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      enableRowButtonActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Download Receipt',
          icon: 'fal fa-download',
          onClick: async (_, item) => {
            if (getPaymentStatus(item.transaction) === Constants.payment_status.unpaid) {
              dispatchToastInfo(
                localizeHelpers.translate(
                  "Can't download, reason: The purchase has not been paid yet!",
                ),
                'Downloading Purchase Receipt',
              );
            } else if (item.transaction_id) {
              try {
                await userRequestActions.downloadPurchaseReceipt(item.transaction_id);
              } catch (error) {
                dispatchToastError(error, 'Error Downloading Purchase Receipt');
              }
            } else {
              dispatchToastError(
                localizeHelpers.translate('Item Transaction ID is undefined!'),
                'Error Downloading Purchase Receipt',
              );
            }
          },
        },
        {
          type: 'HEADER',
          icon: 'far fa-file-download',
          label: 'Export',
          buttonType: 'dark',
          onClick: async () =>
            await userRequestActions.getPurchasesExport(userState.user.id!, userState.user.handle),
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: userRequestActions.getUserPurchases,
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any purchases",
    },
    filterOptions: {
      enableTableSearch: true,
    },
  };

  const auctionItemSectionTableProps: ITableProps<IAuctionItemSummary> = {
    columns: [
      {
        id: 'name',
        Header: 'Item Name',
        accessor: ({ name }) => name,
        notranslate: 'yes',
      },
      {
        id: 'last_bid',
        Header: 'Last Bid',
        accessor: (item) => getTotalAuctionItemValue(item),
        Cell: ({ current_bid }) => (
          <span notranslate="yes">
            {current_bid &&
              current_bid.bid_date &&
              localizeHelpers.formatDate(current_bid.bid_date, LocaleDateFormats.LLL, locale)}
          </span>
        ),
      },
      {
        id: 'total',
        Header: 'Total',
        accessor: (item) => getTotalAuctionItemValue(item),
        notranslate: 'yes',
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: (item) => capitalizeString(getAuctionStatus(item)),
        Cell: (item) => (
          <span
            className={`payment-status ${getAuctionStatus(item)}`}
            onClick={
              getAuctionStatus(item) === 'won'
                ? () => {
                    payAuctionItem(item);
                  }
                : undefined
            }
          >
            {getAuctionStatus(item) === 'won'
              ? 'Pay Now'
              : capitalizeString(getAuctionStatus(item))}
          </span>
        ),
      },
      {
        // Changed Header title from "Cause" to "Event" (GIG-6587)
        id: 'owner_title',
        Header: 'Event',
        accessor: (item) => item.owner_title,
        sortable: true,
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      enableRowButtonActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Download Receipt',
          icon: 'fal fa-download',
          onClick: (_, { id, payment_status }) => {
            if (
              payment_status?.code === null &&
              !auctionItemHelpers.validPaymentStatuses.includes(payment_status.code)
            ) {
              dispatchToastInfo(
                localizeHelpers.translate("Can't download, reason: Auction has not been paid yet!"),
                'Downloading Auction Receipt',
              );
            } else {
              userRequestActions
                .downloadAuctionReceipt(id || '')
                .catch((error) => dispatchToastError(error, 'Error Downloading Auction Receipt'));
            }
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          onClick: (_, rowData) => {
            window.location.href = Config.web.REACT_APP_BASE_URL + '/event/' + rowData.owner_handle;
          },
          icon: 'fas fa-ribbon',
          label: 'View Event',
        },
        {
          type: 'ROW_CONTEXT_MENU',
          onClick: () =>
            (window.location.href =
              Config.web.REACT_APP_BASE_URL + '/dashboard?section=settings&tab=payment'),
          hideIf: ({ payment_status }) => payment_status?.code === 'paid',
          icon: 'far fa-credit-card',
          label: 'View Payment Methods',
        },
        {
          type: 'HEADER',
          icon: 'far fa-file-download',
          label: 'Export',
          buttonType: 'dark',
          onClick: async () =>
            await userRequestActions.getAuctionPaymentsExport(
              userState.user.id!,
              userState.user.handle,
            ),
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: userRequestActions.getUserAuctionItems,
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any auctions",
    },
    filterOptions: {
      enableTableSearch: true,
    },
  };

  const payAuctionItem = (item: IAuctionItem) => {
    setAuctionItemToPay(item);
  };

  return (
    <div className="MyTransactions">
      <div className="title-container">
        <PopupMenu
          showMenuConfig={sectionPopupMenuConfig}
          menuItems={sectionContextMenuItems}
          className={`context-menu-container ${getShowSectionMenuClass}`}
          onClick={() => setShowSectionContextMenu(!showSectionContextMenu)}
          onSelect={() => setShowSectionContextMenu(false)}
        >
          <span className="title">{currentSectionConfig.title}</span>
          <i className={`fas fa-sort-down menu-btn ${getShowSectionMenuClass}`} />
        </PopupMenu>
        <div className="donation-type-switch">
          <div
            className={`btn ${donationType === 'Once' ? 'active' : ''}`}
            onClick={() => setDonationType('Once')}
          >
            One-Time <span>Donations</span>
          </div>
          <div
            className={`btn ${donationType === 'Recurring' ? 'active' : ''}`}
            onClick={() => setDonationType('Recurring')}
          >
            Recurring <span>Donations</span>
          </div>
        </div>
        <div className="mobile-filter-button">
          <span
            onClick={() => {
              setShowFilterMobile(true);
            }}
          >
            <i className="far fa-filter" />
          </span>
        </div>
      </div>
      <div className="content-container">
        {currentSectionId === 'donations' && donationType === 'Once' && (
          <Table
            {...donationOnceSectionTableProps}
            refreshTableIncrementor={donationOnceRefreshTableIncrementor}
          />
        )}
        {currentSectionId === 'donations' && donationType === 'Recurring' && (
          <Table
            {...donationRecurringSectionTableProps}
            refreshTableIncrementor={donationRecurringrefreshTableIncrementor}
          />
        )}
        {currentSectionId === 'tickets' && <Table {...ticketSectionTableProps} />}
        {currentSectionId === 'purchases' && <Table {...purchaseSectionTableProps} />}
        {currentSectionId === 'auction_items' && <Table {...auctionItemSectionTableProps} />}
      </div>
      {auctionItemToPay && (
        <AuctionItemPayModal
          item={auctionItemToPay}
          onClose={() => setAuctionItemToPay(null)}
        />
      )}

      <CommunityFeedDonationModal
        showDonateModal={showMakeADonationModal}
        onClose={() => setShowMakeADonationModal(false)}
      />
      <CommunityFeedDonationModal
        showDonateModal={showOfflineDonationOptionsModal}
        onSelect={(item) => {
          setSelectedDonationItem(item);
          setShowOfflineDonationModal(true);
          setShowOfflineDonationOptionsModal(false);
        }}
        onClose={() => setShowOfflineDonationOptionsModal(false)}
      />
      <Modal
        show={showOfflineDonationModal}
        onClose={() => {
          setShowOfflineDonationModal(false);
          setEditOfflineDonation(false);
        }}
        closeIcon="fas fa-times"
        title="Make an Offline Donation"
        contentClassName="HubManagementDonations-offline-donation"
      >
        <AddDonation
          uiStateType={'offline-donation-from-user'}
          contact={{
            user: userState.user,
          }}
          editMode={editOfflineDonation}
          onClose={() => {
            setDonationOnceRefreshTableIncrementor(donationRecurringrefreshTableIncrementor + 1);
            setShowOfflineDonationModal(false);
            setEditOfflineDonation(false);
          }}
          donationItem={selectedDonationItem}
        />
      </Modal>

      <Prompt
        show={recurringPaymentToCancel}
        title="Cancel Recurring Payment"
        message="Are you sure you want to cancel this recurring payment?"
        yesMessage="Yes"
        yesStyle="delete"
        cancelMessage="No"
        onYes={async () =>
          paymentToCancel &&
          paymentToCancel.id !== undefined &&
          (await handleCancelReoccurringDonation(paymentToCancel.id))
        }
        onClose={() => setRecurringPaymentToCancel(false)}
      />

      {!!requestMatchData && (
        <RequestMatchModal
          donation_status={requestMatchData.donation_status}
          currency={requestMatchData.currency}
          hub_id={requestMatchData.hub_id}
          hub_title={requestMatchData.hub_title}
          group_id={requestMatchData.group_id}
          transaction_id={requestMatchData.transaction_id}
          donation_amount={requestMatchData.donation_amount}
          show={!!requestMatchData}
          onClose={() => setRequestMatchData(null)}
        />
      )}
    </div>
  );
};

export default MyTransactions;
