import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import { IUserState } from '../../reducers/user';
import { IRegisterState } from '../../reducers/register';
import { IGroupState } from '../../reducers/group';
import { IAppState } from '../../store';
import { handleInputChange, toastError } from '../../helpers';

import { createToast } from '../../actions/toaster';
import { registerUser, updateRegisterError } from '../../actions/register';
import { createGroup, updateGroupError, clearGroup } from '../../actions/group';

import { IOptions } from '../../components/Dropdown/Dropdown';

import FormSteps from '../../components/FormSteps/FormSteps';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import Dropdown from '../../components/Dropdown/Dropdown';
import DatePicker from '../../components/DatePicker/DatePicker';
import Loader from '../../components/Loader/Loader';

import './CreateGroup.scss';
import { localizeHelpers } from '../../localizeHelpers';
import { IAppError, IToast } from '../../interfaces';
import { uiConstants } from '../../constants/uiConstants';

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  registerState: IRegisterState;
  groupState: IGroupState;
  updateRegisterError(error: IAppError | null): void;
  updateGroupError(error: string): void;
  clearGroup(): void;
  createToast(toast: IToast): void;
  registerUser(payload: any): void;
  createGroup(payload: any): void;
}

interface IState {
  currentStep: number;
  steps: string[];
  groupName: string;
  groupType: string;
  charityId: string;
  groupTypes: IOptions[];
  firstName: string;
  lastName: string;
  displayName: string;
  dob: any;
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
  groupRef: any;
  profileRef: any;
  accountRef: any;
  type: string;
  redirect: string;
  fn: string;
  showPwd: boolean;
  showPwdConf: boolean;
  phone: string;
}

// TODO: Remove this component (Not being used anymore).
class CreateGroup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let _urlParams = queryString.parse(window.location.search);
    let steps =
      _urlParams.skipGroup !== undefined
        ? ['Profile', 'Account', 'Finish']
        : ['Group', 'Profile', 'Account', 'Finish'];

    this.state = {
      currentStep: _urlParams.skipGroup !== undefined ? 1 : 0,
      steps: steps,
      type: _urlParams.skipGroup !== undefined ? 'individual' : 'organization',
      groupName: _urlParams.groupName !== undefined ? _urlParams.groupName + '' : '',
      groupType: _urlParams.groupType !== undefined ? _urlParams.groupType + '' : 'non-profit',
      charityId: '',
      groupTypes: [
        { label: 'Non-Profit', value: 'non-profit' },
        { label: 'Business', value: 'business' },
        { label: 'High School', value: 'high school' },
        { label: 'University/College', value: 'university/college' },
        { label: 'Service Club', value: 'serviceclub' },
        { label: 'Community Group', value: 'communitygroup' },
      ],
      firstName: _urlParams.firstName !== undefined ? _urlParams.firstName + '' : '',
      lastName: '',
      displayName: '',
      dob: moment().year(moment().year() - 17),
      email: _urlParams.email !== undefined ? _urlParams.email + '' : '',
      username: '',
      password: '',
      confirmPassword: '',
      groupRef: React.createRef(),
      profileRef: React.createRef(),
      accountRef: React.createRef(),
      redirect: _urlParams.redirect?.toString() || '',
      fn: _urlParams.fn?.toString() || '',
      showPwd: false,
      showPwdConf: false,
      phone: '',
    };

    this.movePage = this.movePage.bind(this);
    this.updateDOB = this.updateDOB.bind(this);
    this.updateDisplayName = this.updateDisplayName.bind(this);
    this.updateDefaultHandle = this.updateDefaultHandle.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.createGroup = this.createGroup.bind(this);
  }

  movePage(_increment: number) {
    this.props.updateRegisterError(null);
    this.props.updateGroupError('');

    if (
      this.state.currentStep + _increment >= 0 &&
      this.state.currentStep + _increment <= this.state.steps.length
    ) {
      let _newStep: number = this.state.currentStep + _increment;

      if (this.props.userState.isLoggedIn) {
        _newStep = this.state.steps.length - 1;
      }

      this.setState(
        {
          currentStep: _newStep,
        },
        () => {
          switch (this.state.currentStep) {
            case 0:
              this.state.groupRef.current.focus();
              break;
            case 1:
              this.state.profileRef.current.focus();
              break;
            case 2:
              this.state.accountRef.current.focus();
              break;
            default:
              //
              break;
          }

          if (
            this.state.currentStep === this.state.steps.length - 1 &&
            this.state.type !== 'individual' &&
            this.props.userState.isLoggedIn === false
          ) {
            let _newAccount = {
              email: this.state.email,
              password: this.state.password,
              first_name: this.state.firstName,
              last_name: this.state.lastName,
              display_name: this.state.displayName,
              dob: moment(this.state.dob).format(uiConstants.backendDateFormat),
              handle: this.state.username,
              referral: '',
              phone: this.state.phone,
            };

            this.props.registerUser(_newAccount);
          } else if (
            this.state.currentStep === this.state.steps.length &&
            this.state.type === 'individual' &&
            this.props.userState.isLoggedIn === false
          ) {
            let _newAccount = {
              email: this.state.email,
              password: this.state.password,
              first_name: this.state.firstName,
              last_name: this.state.lastName,
              display_name: this.state.displayName,
              dob: moment(this.state.dob).format(uiConstants.backendDateFormat),
              handle: this.state.username,
              referral: '',
              phone: this.state.phone,
            };

            this.props.registerUser(_newAccount);
          } else if (
            this.state.currentStep === this.state.steps.length - 1 &&
            this.props.userState.isLoggedIn &&
            this.state.type !== 'individual'
          ) {
            this.createGroup();
          }
        },
      );
    }
  }

  updateDOB(_date: any) {
    this.setState({
      dob: _date,
    });
  }

  updateDisplayName() {
    const handle =
      this.state.firstName.trim().toLowerCase() + this.state.lastName.trim().toLowerCase();
    this.setState({
      displayName: this.state.firstName + ' ' + this.state.lastName,
      username: handle,
    });
  }

  validateForm(_pageNumber: number) {
    switch (_pageNumber) {
      case 1:
        if (
          moment()
            .year(moment().year() - 13)
            .year() < moment(this.state.dob).year()
        ) {
          const toast = toastError(
            localizeHelpers.translate(
              'You must be at least 13 years of age to register for Kambeo.',
            ),
            'Registration Error',
          );
          this.props.createToast(toast);

          return false;
        }
        break;
      case 2:
        if (this.state.password !== this.state.confirmPassword) {
          const toast = toastError(
            localizeHelpers.translate('The passwords you entered do not match.'),
            'Registration Error',
          );
          this.props.createToast(toast);

          return false;
        }
        break;
      default:
        //
        break;
    }

    return true;
  }

  createGroup() {
    let _newGroup = {
      group: {
        title: this.state.groupName,
        group_type: this.state.groupType,
        charity_id: this.state.charityId,
        accepting_volunteers: false,
        accepting_donations: false,
        auto_tax_receipts: false,
        auto_confirmations: false,
      },
      groupPages: [
        {
          label: 'My First Page',
          icon: 'fas fa-info-circle',
        },
      ],
    };

    this.props.createGroup(_newGroup);
  }

  componentDidMount() {
    this.props.clearGroup();
    this.props.updateRegisterError(null);
    this.props.updateGroupError('');
  }

  updateDefaultHandle() {
    this.setState({
      username: this.state.displayName,
    });
  }

  togglePwd() {
    this.setState({
      showPwd: !this.state.showPwd,
    });
  }

  togglePwdConf() {
    this.setState({
      showPwdConf: !this.state.showPwdConf,
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      !this.props.registerState.success &&
      this.props.registerState.error !== prevProps.registerState.error &&
      this.props.registerState.error !== null &&
      this.props.userState.isLoggedIn === false
    ) {
      if (
        this.props.registerState.error.errorCode ===
          'ERROR.REGISTRATION.USER_EMAIL_ALREADY_EXISTS' ||
        this.props.registerState.error.errorCode ===
          'ERROR.REGISTRATION.USER_HANDLE_ALREADY_EXISTS' ||
        this.props.registerState.error.errorCode === 'ERROR.HANDLE.NOT_MATCHES_ALLOWED_PATTERN'
      ) {
        this.setState({
          currentStep: 2,
        });
      }
    } else if (
      this.props.registerState.success &&
      this.props.userState.isLoggedIn &&
      this.props.registerState.error === null &&
      !this.props.groupState.isGroupLoading &&
      this.state.type !== 'individual'
    ) {
      this.createGroup();
    }

    if (
      this.props.userState.isLoggedIn &&
      this.props.groupState.error !== prevProps.groupState.error &&
      this.props.groupState.error !== ''
    ) {
      this.setState({
        currentStep: 0,
      });
    }

    if (this.props.userState.isLoggedIn) {
      let _url = '';

      if (this.props.groupState.error === '' && this.props.groupState.group.handle !== '') {
        _url = '/group/' + this.props.groupState.group.handle + '?help=1';
      }

      if (
        this.state.type === 'individual' &&
        this.props.userState.error === '' &&
        this.props.userState.user.handle !== ''
      ) {
        _url = '/user/' + this.props.userState.user.handle + '?help=1';
      }

      if (this.state.redirect !== '') {
        _url = this.state.redirect;

        if (this.state.fn !== '') {
          _url += '?fn=' + this.state.fn;
        }
      }

      if (_url !== '') {
        this.props.history.push(_url);
      }
    }
  }

  render() {
    return (
      <div className="CreateGroup">
        <div className="curve-wrap">
          <div className="curve" />
          <div className="bottom-fill" />
        </div>
        <div className="CreateGroup-inner">
          <h1>{this.state.type === 'individual' ? 'Create Your Profile' : 'Create your cause'}</h1>
          {this.props.userState.isLoggedIn === false && (
            <FormSteps
              current={this.state.currentStep}
              steps={this.state.steps}
            />
          )}
          <div className="form-wrap">
            {this.state.currentStep === 0 && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (this.validateForm(0)) {
                    this.movePage(1);
                  }
                }}
                className="form-page"
              >
                <h2>Cause</h2>
                <div className="row">
                  <TextField
                    reference={this.state.groupRef}
                    required={true}
                    label="Group Name"
                    value={this.state.groupName}
                    name="groupName"
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                </div>
                <div className="row">
                  <Dropdown
                    label="Cause Type"
                    shouldSort={true}
                    value={this.state.groupType}
                    name="groupType"
                    options={this.state.groupTypes}
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                </div>
                <div className="row">
                  <TextField
                    label="Charity Registration Number"
                    value={this.state.charityId}
                    name="charityId"
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                </div>
                <div className="actions">
                  <Button
                    type="submit"
                    text={this.props.userState.isLoggedIn === false ? 'Next' : 'Finish'}
                  />
                </div>
              </form>
            )}
            {this.state.currentStep === 1 && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (this.validateForm(1)) {
                    this.movePage(1);
                  }
                }}
                className="form-page"
              >
                <h2>Profile</h2>
                <div className="row names">
                  <TextField
                    reference={this.state.profileRef}
                    required={true}
                    label="First Name"
                    value={this.state.firstName}
                    name="firstName"
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e, this, false, this.updateDisplayName);
                    }}
                  />
                  <TextField
                    required={true}
                    label="Last Name"
                    value={this.state.lastName}
                    name="lastName"
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e, this, false, this.updateDisplayName);
                    }}
                  />
                </div>
                <div className="row">
                  <TextField
                    required={true}
                    label="Phone Number"
                    value={this.state.phone}
                    name="phone"
                    type="tel"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                </div>
                <div className="row">
                  <TextField
                    required={true}
                    label="Display Name"
                    value={this.state.displayName}
                    name="displayName"
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e, this, false, this.updateDefaultHandle);
                    }}
                  />
                </div>
                <div className="row">
                  <DatePicker
                    maxYear={moment().year().toString()}
                    date={this.state.dob}
                    label="Date of Birth"
                    name="dob"
                    onChange={this.updateDOB}
                  />
                </div>
                <div className="actions">
                  <Button
                    type="submit"
                    text="Next"
                  />
                  {this.state.type !== 'individual' && (
                    <Button
                      text="Back"
                      onClick={() => {
                        this.movePage(-1);
                      }}
                    />
                  )}
                </div>
              </form>
            )}
            {this.state.currentStep === 2 && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (this.validateForm(2)) {
                    this.movePage(1);
                  }
                }}
                className="form-page"
              >
                <h2>Account</h2>
                <div className="row">
                  <TextField
                    reference={this.state.accountRef}
                    required={true}
                    label="Email"
                    value={this.state.email}
                    name="email"
                    type="email"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                </div>
                <div className="row info-row">
                  <TextField
                    required={true}
                    label="Handle"
                    value={this.state.username}
                    name="username"
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                  <div className="info-section">
                    <i className="fas fa-question-circle" />
                    <div className="info-text">This is your custom URL</div>
                  </div>
                </div>
                <div className="row pwd-row">
                  <TextField
                    required={true}
                    label="Password"
                    value={this.state.password}
                    autoComplete="new-password"
                    name="password"
                    type={this.state.showPwd ? 'text' : 'password'}
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                  {this.state.showPwd && (
                    <i
                      className="fa fa-eye-slash"
                      onClick={() => this.togglePwd()}
                    />
                  )}
                  {!this.state.showPwd && (
                    <i
                      className="fa fa-eye"
                      onClick={() => this.togglePwd()}
                    />
                  )}
                </div>
                <div className="row pwd-row">
                  <TextField
                    required={true}
                    label="Confirm Password"
                    value={this.state.confirmPassword}
                    name="confirmPassword"
                    type={this.state.showPwdConf ? 'text' : 'password'}
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                  {this.state.showPwdConf && (
                    <i
                      className="fa fa-eye-slash"
                      onClick={() => this.togglePwdConf()}
                    />
                  )}
                  {!this.state.showPwdConf && (
                    <i
                      className="fa fa-eye"
                      onClick={() => this.togglePwdConf()}
                    />
                  )}
                </div>
                <div className="row pwd-row">
                  <span className="disclaimer"></span>
                </div>
                <div className="actions">
                  <Button
                    type="submit"
                    text="Finish"
                  />
                  <Button
                    text="Back"
                    onClick={() => {
                      this.movePage(-1);
                    }}
                  />
                </div>
              </form>
            )}
            {this.state.currentStep === 3 && (
              <form className="form-page">
                <div className="loading">
                  <Loader loading={true} />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    registerState: store.registerState,
    groupState: store.groupState,
  };
};

const mapDispatchToProps = {
  createToast,
  registerUser,
  updateRegisterError,
  updateGroupError,
  createGroup,
  clearGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
