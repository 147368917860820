import { IDonationMatchingProgram, IHub, ITransactionSummary } from '@gigit/interfaces';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { defaultCurrency, downloadFile, formatCurrency, typeHelpers } from '../../../../../helpers';
import useToastDispatcher from '../../../../../hooks/useToaster';
import { userSelectors } from '../../../../../selectors/user';
import { IAppState } from '../../../../../store';
import Button from '../../../../Button/Button';
import Modal from '../../../../Modal/Modal';
import ModalFooterActionContainer from '../../../../Modal/ModalFooterActions/ModalFooterActionContainer';
import ModalScrollContainer from '../../../../Modal/ModalScrollContainer/ModalScrollContainer';
import Portrait from '../../../../Portrait/Portrait';
import DefaultLink from '../../../../shared/Link/Link';
import Table, { ITableProps } from '../../../../shared/Table/Table';
import './DonationMatchingDetailsModal.scss';
import { localizeHelpers, LocaleDateFormats } from '../../../../../localizeHelpers';
import { uiConstants } from '../../../../../constants';
import { hubRequestActions } from '../../../../../requestActions';

interface IProps {
  hub?: IHub;
  donationMatchingProgram: IDonationMatchingProgram | null;
  showModal: boolean;
  onClose: () => void;
}

function DonationMatchingDetailsModal(props: IProps) {
  const { showModal, onClose } = props;
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const currency = props.hub?.account?.currency ?? defaultCurrency;
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const tableProps: ITableProps<ITransactionSummary> = {
    columns: [
      {
        id: 'user.display_name',
        Header: 'Name',
        accessor: ({ user }) => user.display_name,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ user }) => {
          return (
            <div
              className="item-name-col"
              notranslate="yes"
            >
              <Portrait
                size={60}
                className="image-container"
                currentImage={user.profile_image_url}
              />
              <span>{user.display_name}</span>
            </div>
          );
        },
      },
      {
        id: 'intended_amount',
        Header: 'Donated',
        accessor: ({ intended_amount }) => formatCurrency(intended_amount, currency, locale),
        sortable: true,
      },
      {
        id: 'donation_match.amount_matched',
        Header: 'Matched',
        accessor: ({ donation_match }) =>
          formatCurrency(donation_match?.amount_matched, currency, locale),
        sortable: true,
      },
      {
        id: 'created_at',
        Header: 'Date',
        accessor: ({ created_at }) => created_at?.toString(),
        sortable: true,
        notranslate: 'yes',
        Cell: ({ created_at }) => {
          return (
            <div
              className="col start-date"
              notranslate="yes"
            >
              <span className="date">
                {localizeHelpers.formatDate(created_at || '', LocaleDateFormats.LL, locale)}
              </span>
            </div>
          );
        },
      },
      {
        id: 'group_title',
        Header: 'Cause',
        accessor: ({ group_title }) => group_title,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ group_title, group_profile_image_url, group_handle }) => (
          <DefaultLink
            to={`/${uiConstants.ownerType.group}/${group_handle}`}
            className="item-name-col"
            notranslate="yes"
          >
            <Portrait
              size={60}
              className="image-container"
              currentImage={group_profile_image_url}
            />
            <span>{group_title}</span>
          </DefaultLink>
        ),
      },
    ],
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => await handleGetSummaryData(queryParams),
    },
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'HEADER',
          label: 'Export',
          icon: 'fal fa-download',
          onClick: async () => await handleExportSummaryData(),
          buttonType: 'secondary',
        },
      ],
    },
    filterOptions: {
      enableTableSearch: true,
      searchPlaceholderValue: 'Search for a Charity or Non-Profit',
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any Users",
    },
  };

  async function handleExportSummaryData() {
    try {
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      typeHelpers.assertNotNullOrUndefined(
        props?.donationMatchingProgram?.id,
        'Expected Program ID',
      );

      const result = await hubRequestActions.exportMatchingProgramSummary(
        props.hub.id,
        props.donationMatchingProgram.id,
      );
      downloadFile('Export_Matching_Program_Summary.csv', result);
    } catch (error) {
      dispatchToastError(error, 'Export Summary Data');
    }
  }

  async function handleGetSummaryData(queryParams: URLSearchParams) {
    let data = [] as ITransactionSummary[];

    try {
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      typeHelpers.assertNotNullOrUndefined(
        props?.donationMatchingProgram?.id,
        'Expected Program ID',
      );

      data = await hubRequestActions.getDonationsForDonationMatchingProgram(
        props.hub.id,
        props.donationMatchingProgram.id,
        queryParams,
      );
    } catch (error) {
      dispatchToastError(error, 'Get User Match Details');
    }

    return data;
  }

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  return (
    <Modal
      show={showModal}
      onClose={onClose}
      closeIcon="fas fa-times"
      title={props.donationMatchingProgram?.name}
      class="DonationMatchingDetailsModal-Modal-Container"
    >
      <ModalScrollContainer className="DonationMatchingDetailsModal">
        <Table
          {...tableProps}
          refreshTableIncrementor={refreshTableIncrementor}
        />
      </ModalScrollContainer>
      <ModalFooterActionContainer>
        <Button
          buttonType="secondary"
          onClick={onClose}
          text="Cancel"
        />
      </ModalFooterActionContainer>
    </Modal>
  );
}

export default DonationMatchingDetailsModal;
