import React, { FC, useEffect, useState } from 'react';
import Joyride, { CallBackProps } from 'react-joyride';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { joyRideStyles, setSEOMetatags, typeHelpers } from '../../helpers';
import { IUserState } from '../../reducers/user';
import { IAppState } from '../../store';
import SideBar, { ISidebarTab } from './SideBar/SideBar';
import activityIcon from '../../assets/dashboard/activity_icon-dark.svg';
import profileIcon from '../../assets/dashboard/profile_icon-dark.svg';
import pagesIcon from '../../assets/dashboard/pages_icon-dark.svg';
import settingsIcon from '../../assets/dashboard/settings_icon-dark.svg';
import './UserDashboard.scss';
import MyActivity from './MyActivity/MyActivity';
import MyPages from './MyPages/MyPages';
import Achievements from './Achievements/Achievements';
import Settings from './Settings/Settings';
import MyProfile from './MyProfile/MyProfile';
import { localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';

interface IProps extends RouteComponentProps<any> {}

const tabs: ISidebarTab[] = [
  { id: 'activity', title: 'My Activity', icon: activityIcon },
  { id: 'pages', title: 'My Pages', icon: pagesIcon },
  { id: 'profile', title: 'My Profile', icon: profileIcon },
  // { id: "achievement", title: "Achievements", icon: achievementsIcon }, GIG-5590 Hiding achievements for now until supported
  { id: 'settings', title: 'Settings', icon: settingsIcon },
];

const helpSteps = [
  {
    disableBeacon: true,
    target: '.activity',
    content:
      'See all your activity in one place. Download tax receipts and tickets, or see upcoming volunteer opportunities and log your hours. Everything you need is here in your Dashboard.',
  },
  {
    disableBeacon: true,
    target: '.pages',
    content:
      'My Pages shows you all of the pages you have created, followed, or supported so you can easily find what you are looking for.',
  },
  {
    disableBeacon: true,
    target: '.tabs .profile',
    content: 'Update your profile and public information.',
  },
  {
    disableBeacon: true,
    target: '.tabs .settings',
    content: 'Update your application settings and defaults.',
  },
];

/**
 *  Root user dashboard component
 */
const UserDashboard: FC<IProps> = (props: IProps) => {
  const params = queryString.parse(props.location.search);
  const sectionId = tabs.find((tab) => tab.id === params.section)?.id ?? 'activity';

  const passedTabId = (params?.tab as string) || (params?.t as string);
  const [activeTabId, setActiveTabId] = useState<string>(sectionId);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
  const userState: IUserState = useSelector((state: IAppState) => state.userState);
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const ownerObj = typeHelpers.createOwnerObject('user', userState.user);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setActiveTabId(sectionId || 'activity');
  }, [sectionId]);

  useEffect(() => {
    setSEOMetatags({
      title: localizeHelpers.translate('Dashboard | Kambeo'),
      urlPath: 'dashboard',
    });
  });

  useEffect(() => {
    if (!isUserLoggedIn) {
      props.history.push(
        `/login?redirect=${encodeURIComponent(`/dashboard?${queryString.stringify(params)}`)}`,
      );
    }
  }, [isUserLoggedIn]);

  return (
    <div className="UserDashboard">
      {params.help !== undefined && (
        <Joyride
          locale={{ back: 'Back', close: 'Close', last: 'Finish', next: 'Next', skip: 'Skip' }}
          continuous={true}
          disableScrolling={true}
          styles={joyRideStyles}
          steps={helpSteps}
          callback={(joyrideState: CallBackProps) => {
            if (joyrideState.status === 'finished') history.replace({ search: '' });
          }}
        />
      )}
      <SideBar
        owner={ownerObj}
        tabConfig={{
          activeTabId: sectionId,
          onTabClick: (v) => history.push({ pathname: location.pathname, search: `section=${v}` }),
          tabs,
        }}
        collapsible={{
          isCollapsed: isSidebarCollapsed,
          onCollapseClick: () => setIsSidebarCollapsed(!isSidebarCollapsed),
        }}
      />
      <div className={`main ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        {activeTabId === 'activity' && (
          <MyActivity
            activeTabId={passedTabId}
            userState={userState}
          />
        )}
        {activeTabId === 'pages' && (
          <MyPages
            userState={userState}
            activeTab={passedTabId}
          />
        )}
        {activeTabId === 'profile' && <MyProfile />}
        {activeTabId === 'achievement' && <Achievements />}
        {activeTabId === 'settings' && <Settings activeTabId={passedTabId} />}
      </div>
    </div>
  );
};

export default UserDashboard;
