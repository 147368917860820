import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import './FloatingActionButton.scss';

interface IPassedProps extends WithTranslation, RouteComponentProps<any> {
  icon: string;
  onClick: () => void;
}

class FloatingActionButton extends React.Component<IPassedProps> {
  render() {
    const { t, icon, onClick } = this.props;

    return (
      <div
        className="FloatingActionButton"
        onClick={onClick}
      >
        <i className={icon} />
      </div>
    );
  }
}

export default withRouter(withTranslation('translations')(FloatingActionButton));
