import { Elements, ElementsConsumer, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Stripe, PaymentRequest } from '@stripe/stripe-js';
import React from 'react';
import Button from '../../../Button/Button';

import './FormBottomBar.scss';

interface IProps {
  nextBtnTxt: string;
  isDisabled: boolean;
  showSkip?: boolean;
  showBack: boolean;
  loading: boolean;
  showAltPaymentButton?: boolean;
  stripePromise?: Stripe | null;
  paymentRequest?: PaymentRequest;
  disableSkip?: boolean;
  /**
   * TODO: Optional button should be used in the future to pass in an extra button
   * we should change the stripe button and skip button to be implemented as so to keep this component clean
   */
  optionalButton?: React.ReactNode;
  onBack(): void;
  onNext(): void;
}

export const FormBottomBar: React.FC<IProps> = (props: IProps) => {
  return (
    <div className={props.showBack ? 'FormBottomBar' : 'FormBottomBar end'}>
      {props.showBack && (
        <Button
          className="transitions"
          type="button"
          text="Back"
          icon={'fal fa-angle-left'}
          onClick={() => props.onBack()}
        />
      )}
      <div className="right-action-wrapper">
        {props.showAltPaymentButton && props.stripePromise ? (
          <>
            <Elements stripe={props.stripePromise}>
              <ElementsConsumer>
                {({ elements, stripe }) => (
                  <PaymentRequestButtonElement
                    className="Button"
                    options={{
                      style: { paymentRequestButton: { type: 'default' } },
                      paymentRequest: props.paymentRequest!,
                    }}
                  />
                )}
              </ElementsConsumer>
            </Elements>
          </>
        ) : (
          <>
            {props.showSkip && (
              <Button
                isDisabled={props.disableSkip}
                onClick={() => props.onNext()}
                className="transitions skip-button"
              >
                Skip
              </Button>
            )}
            {props?.optionalButton && props.optionalButton}
            <Button
              className="transitions submit-btn"
              type="submit"
              loading={props.loading}
              isDisabled={props.isDisabled}
              text={props.nextBtnTxt}
              onClick={() => props.onNext()}
            />
          </>
        )}
      </div>
    </div>
  );
};
