import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { CardElement } from '@stripe/react-stripe-js';
import { routes, handleInputChange, isMobileScreen, toastError } from '../../../helpers';
import TextField from '../../TextField/TextField';
import Button from '../../Button/Button';
import Dropdown from '../../Dropdown/Dropdown';
import { IUserState } from '../../../reducers/user';
import { createToast } from '../../../actions/toaster';
import './PaymentMethodsForm.scss';
import { IToast } from '../../../interfaces';
import { localizeHelpers } from '../../../localizeHelpers';
import { localeConstants } from '../../../constants';
import { PaymentMethodResult, SetupIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';
import { ISetupIntentResponse } from '@gigit/interfaces';

interface IProps {
  createToast(toast: IToast): void;
  onClose(): void;
  stripe: Stripe | null;
  elements: StripeElements | null;
  userState: IUserState;
  showHeader?: boolean;
  useMobileFormat?: boolean;
  onTryAddPaymentMethod?: () => void;
  onAddPaymentMethodComplete?: () => void;
}

interface IState {
  cardName: string;
  streetAddress: string;
  apt: string;
  city: string;
  province: string;
  country: string;
  postal: string;
  loading: boolean;
}

class PaymentMethodsForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      cardName: '',
      streetAddress: '',
      apt: '',
      city: '',
      province: '',
      country: 'CA',
      postal: '',
      loading: false,
    };
  }

  addPaymentMethod = () => {
    const card = this.props.elements?.getElement('card');

    this.setState(
      {
        loading: true,
      },
      () => {
        if (card) {
          this.props.stripe
            ?.createPaymentMethod({
              type: 'card',
              card: card,
              billing_details: {
                email: this.props.userState.user.email,
                name: this.state.cardName,
                address: {
                  city: this.state.city,
                  country: this.state.country,
                  line1: this.state.streetAddress,
                  line2: this.state.apt,
                  postal_code: this.state.postal,
                  state: this.state.province,
                },
              },
            })
            .then((response: PaymentMethodResult) => {
              if (response.error) {
                const toast = toastError(
                  localizeHelpers.translate(response.error.message),
                  'Error Adding Payment Method',
                );
                this.props.createToast(toast);

                this.setState({
                  loading: false,
                });
              } else {
                let payload = {
                  payment_method_id: response.paymentMethod?.id,
                };

                axios
                  .post<ISetupIntentResponse>(routes.GET_PAYMENT_METHODS, payload)
                  .then((_response) => {
                    this.props.stripe
                      ?.confirmCardSetup(_response.data.client_secret as string)
                      .then((__response: SetupIntentResult) => {
                        if (__response.error) {
                          const toast = toastError(
                            localizeHelpers.translate(__response.error.message),
                            'Add Payment Method',
                          );
                          this.props.createToast(toast);

                          this.setState({
                            loading: false,
                          });
                        } else {
                          this.props.onClose();
                        }
                      });
                  });
              }
              this.props.onAddPaymentMethodComplete?.();
            });
        }
      },
    );
  };

  renderMobileForm = () => {
    return (
      <form
        onSubmit={this.handleSubmitForm}
        className="PaymentMethodsForm"
      >
        <div className="sub-title">Credit Card</div>
        <div className="row">
          <TextField
            required={true}
            label="Name on Card"
            value={this.state.cardName}
            name="cardName"
            type="text"
            onChange={(e) => {
              handleInputChange(e, this);
            }}
          />
        </div>
        <div className="card-wrap">
          <label>Credit Card</label>
          <div
            className="card-inner"
            notranslate="yes"
          >
            <CardElement options={{ hidePostalCode: true }} />
          </div>
        </div>
        <div className="sub-title">Billing Information</div>
        <div className="row">
          <TextField
            required={true}
            label="Street Address"
            value={this.state.streetAddress}
            name="streetAddress"
            type="text"
            onChange={(e) => {
              handleInputChange(e, this);
            }}
          />
        </div>
        <div className="row">
          <TextField
            label="Apartment / Suite #"
            value={this.state.apt}
            name="apt"
            type="text"
            onChange={(e) => {
              handleInputChange(e, this);
            }}
          />
        </div>
        <div className="row">
          <Dropdown
            value={this.state.country}
            label="Country"
            onChange={(e) => {
              handleInputChange(e, this);
            }}
            name="country"
            shouldSort={true}
            options={localeConstants.allCountryOptions}
          />
        </div>
        <div className="row">
          {(this.state.country === 'CA' || this.state.country === 'US') && (
            <Dropdown
              label="Province/State"
              value={this.state.province}
              onChange={(e) => {
                handleInputChange(e, this);
              }}
              name="province"
              options={
                this.state.country === 'CA'
                  ? localeConstants.canadianProvinceOptions
                  : this.state.country === 'US'
                    ? localeConstants.usStateOptions
                    : [{ value: 'N/A', label: 'N/A' }]
              }
            />
          )}
          {this.state.country !== 'CA' && this.state.country !== 'US' && <div>&nbsp;</div>}
        </div>
        <div className="row">
          <TextField
            required={true}
            label="City"
            value={this.state.city}
            name="city"
            type="text"
            onChange={(e) => {
              handleInputChange(e, this);
            }}
          />
        </div>
        <div className="row">
          <TextField
            required={true}
            label="Postal/Zip Code"
            value={this.state.postal}
            name="postal"
            type="text"
            onChange={(e) => {
              handleInputChange(e, this);
            }}
          />
        </div>
        <div className="actions">
          <Button
            loading={this.state.loading}
            text="Add"
            buttonType="dark"
          />
        </div>
      </form>
    );
  };

  handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.onTryAddPaymentMethod?.();
    this.addPaymentMethod();
  };

  render() {
    const isMobile = isMobileScreen() || this.props.useMobileFormat;

    if (isMobile) {
      return this.renderMobileForm();
    } else {
      return (
        <form
          onSubmit={this.handleSubmitForm}
          className="PaymentMethodsForm"
        >
          {this.props.showHeader && <div className="title">Add Payment Method</div>}
          <div className="sub-title">Credit Card</div>
          <div className="row">
            <TextField
              required={true}
              label="Name on Card"
              value={this.state.cardName}
              name="cardName"
              type="text"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
          </div>
          <div className="card-wrap">
            <label>Credit Card</label>
            <div
              className="card-inner"
              notranslate="yes"
            >
              <CardElement options={{ hidePostalCode: true }} />
            </div>
          </div>
          <div className="sub-title">Billing Information</div>
          <div className="row dbl">
            <TextField
              required={true}
              label="Street Address"
              value={this.state.streetAddress}
              name="streetAddress"
              type="text"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
            <TextField
              label="Apartment / Suite #"
              value={this.state.apt}
              name="apt"
              type="text"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
          </div>
          <div className="row dbl">
            <Dropdown
              value={this.state.country}
              label="Country"
              shouldSort={true}
              onChange={(e) => {
                handleInputChange(e, this);
              }}
              name="country"
              options={localeConstants.allCountryOptions}
            />

            {(this.state.country === 'CA' || this.state.country === 'US') && (
              <Dropdown
                label="Province/State"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
                value={this.state.province}
                name="province"
                options={
                  this.state.country === 'CA'
                    ? localeConstants.canadianProvinceOptions
                    : this.state.country === 'US'
                      ? localeConstants.usStateOptions
                      : [{ value: 'N/A', label: 'N/A' }]
                }
              />
            )}

            {this.state.country !== 'CA' && this.state.country !== 'US' && <div>&nbsp;</div>}
          </div>
          <div className="row dbl">
            <TextField
              required={true}
              label="City"
              value={this.state.city}
              name="city"
              type="text"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
            <TextField
              required={true}
              label="Postal/Zip Code"
              value={this.state.postal}
              name="postal"
              type="text"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
          </div>
          <div className="actions">
            <Button
              loading={this.state.loading}
              text="Add"
              buttonType="dark"
            />
          </div>
        </form>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsForm);
