import React from 'react';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import { logError } from '../../actions/user';

import './ErrorBoundry.scss';

interface IProps extends WithTranslation {
  logError(payload: any): void;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundry extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const _payload = {
      user_agent: navigator.userAgent,
      message: error.message,
      trace: errorInfo.componentStack,
    };

    this.props.logError(_payload);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="ErrorBoundry">
          <div className="error-inner">
            An error has occured, if the issue persists, please try clearing your cache and logging
            in again.
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    user: store.userState,
  };
};

const mapDispatchToProps = {
  logError: logError,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ErrorBoundry),
);
