import React from 'react';
import { connect } from 'react-redux';
import { IUser } from '@gigit/interfaces';
import Axios from 'axios';
import { Config } from '@gigit/config';
import { routes, toastError } from '../../../../helpers';
import { IToast } from '../../../../interfaces';
import errorHelpers from '../../../../helpers/errorHelpers';
import { IAppState } from '../../../../store';
import { createToast } from '../../../../actions/toaster';

interface IProps {
  flow: string;
  fundraiser: any;
  user: IUser;

  updateFundraiser(field: string, val: boolean): void;
  createToast(toast: IToast): void;
}

interface IState {
  isContentCreator: boolean;
  twitchAuthLink: string;
}

class ContentCreator extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isContentCreator: false,
      twitchAuthLink: '',
    };

    this.handleContentCreator = this.handleContentCreator.bind(this);
  }

  componentDidMount() {
    this.intializeData();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.fundraiser !== this.props.fundraiser) {
      this.setState({
        isContentCreator: this.props.fundraiser?.content_creator,
      });
    }
  }

  intializeData() {
    this.setState(
      {
        isContentCreator: this.props.fundraiser?.content_creator,
      },
      () => {
        Axios.get(
          routes.GET_TWITCH_AUTH_LINK +
            '?redirect=' +
            Config.web.REACT_APP_BASE_URL +
            '/twitch/connect',
        )
          .then((response) => {
            if (response.data) {
              this.setState({
                twitchAuthLink: response.data.value,
              });
            }
          })
          .catch((error) => {
            const errorObj = errorHelpers.getErrorObject(error);
            let toast = toastError(errorObj.translatedMessage, 'Twitch Connect');
            this.props.createToast(toast);
          });
      },
    );
  }

  handleContentCreator() {
    this.setState(
      {
        isContentCreator: !this.state.isContentCreator,
      },
      () => {
        let c = this.state.isContentCreator;
        this.props.updateFundraiser('content_creator', c);
      },
    );
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} charity`}>
        <div className="details-row">
          <div className="details">
            <p>Are you a content creator?</p>
            <span>This allows you to host Livestream events.</span>
          </div>
          <div className="settings-control">
            {this.state.isContentCreator && <span>Yes</span>}
            <i
              onClick={this.handleContentCreator}
              className={this.state.isContentCreator ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}
            ></i>
          </div>
        </div>
        <div className="integrations">
          {this.state.twitchAuthLink !== '' &&
            !this.props.user.twitch &&
            this.state.isContentCreator && (
              <div className="charity-wrap">
                <a
                  rel="noopener noreferrer"
                  href={this.state.twitchAuthLink}
                >
                  <span className="fab fa-twitch"></span>Connect Twitch
                </a>
              </div>
            )}
          {this.state.twitchAuthLink !== '' &&
            this.props.user.twitch &&
            this.state.isContentCreator && (
              <div className="charity-wrap">
                <div className="connected">Twitch Connected</div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentCreator);
