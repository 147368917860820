export const FlowConfigComponents: any = (causes: Array<string>) => {
  return {
    components: {
      descriptionPayload: {
        visibility: 'public',
        sequence: 1,
        title: 'Description',
        component_type: 'description',
      },
      storePayload: {
        visibility: 'public',
        sequence: 2,
        title: 'Store',
        component_type: 'store_item',
        content_references: {
          object_ids: [],
          object_type: 'store_item',
        },
      },
      eventsPayload: {
        visibility: 'public',
        sequence: 3,
        title: 'Events',
        component_type: 'event',
        content_references: {
          object_ids: [],
          object_type: 'event',
        },
      },
      gigsPayload: {
        visibility: 'public',
        sequence: 4,
        title: 'Volunteer Opportunity',
        component_type: 'gig',
        content_references: {
          object_ids: [],
          object_type: 'gig',
        },
      },
      causesPayload: {
        visibility: 'public',
        sequence: 5,
        title: 'Focus Areas',
        component_type: 'cause',
        content_references: {
          object_ids: [...causes],
          object_type: 'cause',
        },
        meta_data: {
          causes: [],
        },
      },
      locationsPayload: {
        visibility: 'public',
        sequence: 6,
        title: 'Locations',
        component_type: 'location',
        content_references: {
          object_ids: [],
          object_type: `location`,
        },
      },
      auctionsPayload: {
        visibility: 'public',
        sequence: 1,
        title: 'Auction',
        component_type: 'auction',
        content_references: {
          object_ids: [],
          object_type: 'auction_item',
        },
      },
      donationsPayload: {
        component_type: 'donations',
        visibility: 'public',
        sequence: 1,
        title: 'Donations',
      },
      FundraiserPayload: {
        visibility: 'public',
        sequence: 1,
        title: 'Fundraising',
        component_type: 'fundraising',
      },
    },
  };
};

export const FlowConfigPages: any = (FlowConfigComponents: any) => {
  return {
    acceptDonations: {
      pages: [
        {
          label: 'About Us',
          icon: 'fas fa-info-circle',
          components: [
            FlowConfigComponents.components.descriptionPayload,
            FlowConfigComponents.components.causesPayload,
          ],
        },
        {
          label: 'Events',
          icon: 'fas fa-info-circle',
          components: [
            FlowConfigComponents.components.descriptionPayload,
            FlowConfigComponents.components.eventsPayload,
          ],
        },
        {
          label: 'Volunteer Opportunities',
          icon: 'fas fa-info-circle',
          components: [
            FlowConfigComponents.components.descriptionPayload,
            FlowConfigComponents.components.gigsPayload,
          ],
        },
        {
          label: 'Store',
          icon: 'fas fa-info-circle',
          components: [
            FlowConfigComponents.components.descriptionPayload,
            FlowConfigComponents.components.storePayload,
          ],
        },
        {
          label: 'Contact Us',
          icon: 'fas fa-info-circle',
          components: [
            FlowConfigComponents.components.descriptionPayload,
            FlowConfigComponents.components.locationsPayload,
          ],
        },
      ],
    },
    createStore: {
      pages: [
        {
          label: 'Store',
          icon: 'fas fa-info-circle',
          components: [
            FlowConfigComponents.components.descriptionPayload,
            FlowConfigComponents.components.storePayload,
          ],
        },
        {
          label: 'Locations',
          icon: 'fas fa-info-circle',
          components: [FlowConfigComponents.components.locationsPayload],
        },
      ],
    },
    createAuction: {
      pages: [
        {
          label: 'Auction',
          icon: 'fas fa-info-circle',
          components: [FlowConfigComponents.components.auctionsPayload],
        },
        {
          label: 'Locations',
          icon: 'fas fa-info-circle',
          components: [FlowConfigComponents.components.locationsPayload],
        },
      ],
    },
    event: {
      crowdfunding: {
        pages: [
          {
            label: 'Story',
            icon: 'fas fa-info-circle',
            components: [
              FlowConfigComponents.components.causesPayload,
              FlowConfigComponents.components.descriptionPayload,
            ],
          },
          {
            label: 'Locations',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.locationsPayload],
          },
        ],
      },
      crowdfunding_fundraiser: {
        pages: [
          {
            label: 'Story',
            icon: 'fas fa-info-circle',
            components: [
              FlowConfigComponents.components.causesPayload,
              FlowConfigComponents.components.descriptionPayload,
            ],
          },
          {
            label: 'Locations',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.locationsPayload],
          },
        ],
      },
      p2p: {
        pages: [
          {
            label: 'About',
            icon: 'fas fa-info-circle',
            components: [
              FlowConfigComponents.components.causesPayload,
              FlowConfigComponents.components.descriptionPayload,
            ],
          },
          {
            label: 'Leaderboard',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.FundraiserPayload],
          },
          {
            label: 'Donations',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.donationsPayload],
          },
          {
            label: 'Locations',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.locationsPayload],
          },
        ],
      },
      p2p_fundraiser: {
        pages: [
          {
            label: 'Story',
            icon: 'fas fa-info-circle',
            components: [
              FlowConfigComponents.components.causesPayload,
              FlowConfigComponents.components.descriptionPayload,
            ],
          },
          {
            label: 'Leaderboard',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.FundraiserPayload],
          },
          {
            label: 'Donations',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.donationsPayload],
          },
        ],
      },
      free: {
        pages: [
          {
            label: 'About',
            icon: 'fas fa-info-circle',
            components: [
              FlowConfigComponents.components.causesPayload,
              FlowConfigComponents.components.descriptionPayload,
            ],
          },
          {
            label: 'Tickets',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.storePayload],
          },
          {
            label: 'Locations',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.locationsPayload],
          },
        ],
      },
      paid: {
        pages: [
          {
            label: 'About',
            icon: 'fas fa-info-circle',
            components: [
              FlowConfigComponents.components.causesPayload,
              FlowConfigComponents.components.descriptionPayload,
            ],
          },
          {
            label: 'Tickets',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.storePayload],
          },
          {
            label: 'Locations',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.locationsPayload],
          },
        ],
      },
      paid_fundraiser: {
        pages: [
          {
            label: 'Story',
            icon: 'fas fa-info-circle',
            components: [
              FlowConfigComponents.components.causesPayload,
              FlowConfigComponents.components.descriptionPayload,
            ],
          },
          {
            label: 'Tickets',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.storePayload],
          },
        ],
      },
      online: {
        pages: [
          {
            label: 'About',
            icon: 'fas fa-info-circle',
            components: [
              FlowConfigComponents.components.causesPayload,
              FlowConfigComponents.components.descriptionPayload,
            ],
          },
          {
            label: 'Auction',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.auctionsPayload],
          },
          {
            label: 'Locations',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.locationsPayload],
          },
        ],
      },
      online_fundraiser: {
        pages: [
          {
            label: 'Details',
            icon: 'fas fa-info-circle',
            components: [
              FlowConfigComponents.components.causesPayload,
              FlowConfigComponents.components.descriptionPayload,
            ],
          },
          {
            label: 'Auction',
            icon: 'fas fa-info-circle',
            components: [FlowConfigComponents.components.auctionsPayload],
          },
        ],
      },
      custom: {
        pages: [
          {
            label: 'My First Tab',
            icon: 'fas fa-info-circle',
            components: [],
          },
        ],
      },
    },
  };
};
