import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import {
  IStringMap,
  handleInputChange,
  swapRouteParams,
  routes,
  errorHelpers,
  toastError,
} from '../../../helpers';
import { Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  IPage,
  ICampaign,
  IStoreItem,
  IAccountPublicSummary,
  IEventSummaryFE,
} from '@gigit/interfaces';
import StoreItem from '../StoreItem/StoreItem';

import { requestHubAssociation, updateGroupPageComponent } from '../../../actions/group';
import { updateEventPageComponent } from '../../../actions/event';
import { getStoreItems, StoreItemOwnerType } from '../../../actions/store';

import './Store.scss';
import TextField from '../../TextField/TextField';
import { IStoreState } from '../../../reducers/store';
import { IEventState } from '../../../reducers/event';
import ComponentPlaceholder from '../ComponentPlaceholder/ComponentPlaceholder';
import { getNoCampaignOrNonMonetizedErrorMessage } from '../ComponentPlaceholder/helpers/helpers';
import { IOwnerObject } from '../../../interfaces';
import { IStoreListComponent } from '../../../interfaces';
import typeHelpers from '../../../helpers/typeHelpers';
import AccountSettings from '../../AccountSettings/AccountSettings';
import { accountRequestActions } from '../../../requestActions';
import axios from 'axios';
import { createToast } from '../../../actions/toaster';
import { uiConstants } from '../../../constants';
import Loader from '../../Loader/Loader';

interface IPassedProps extends WithTranslation {
  permissions?: IStringMap;
  component: IStoreListComponent;
  /** Component Page reference object where this store appears. Can be ommitted if edit is set to false. */
  page?: IPage;
  isTicketsOnly?: boolean;
  isSponsorshipsOnly?: boolean;
  addToCartCallback?: () => void;
  edit: boolean;
  hasEditPermissions?: boolean;
  onManageStoreClicked?: () => void;
  onCreateStoreClicked?: () => void;
  onComponentUpdate?: () => void;
  owner: IOwnerObject;
}

interface IPropsFromState {
  storeState: IStoreState;
  eventState: IEventState;
}

interface IPropsFromDispatch {
  getStoreItems(params: {
    ownerType: StoreItemOwnerType;
    ownerId: string;
    query?: URLSearchParams;
  }): void;
  updateGroupPageComponent?(
    groupId: string,
    pageId: string,
    componentId: string,
    payload: any,
    callback?: { (): void },
  ): void;
  updateEventPageComponent(
    eventId: string,
    _pageId: string,
    _componentId: string,
    _component: any,
    callback?: { (): void },
  ): void;
}

type IProps = IPassedProps & IPropsFromState & IPropsFromDispatch;
interface IState {
  storeItems: IStoreItem[];
  title: string;
  chargesEnabled: boolean;
  isLoadingAccountInfo: boolean;
}

class Store extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      storeItems: this.props.component.content_objects || [],
      title: this.props.component.title || 'Store',
      chargesEnabled: false,
      isLoadingAccountInfo: false,
    };

    this.renderComponentPlaceHolder = this.renderComponentPlaceHolder.bind(this);
    this.handleComponentDrag = this.handleComponentDrag.bind(this);
    this.renderErrorMessageComponent = this.renderErrorMessageComponent.bind(this);
    this.isParentMonetized = this.isParentMonetized.bind(this);
  }

  componentDidMount() {
    if (
      this.props.permissions &&
      ((this.props.permissions['EDIT_GROUP_PAGES'] && this.props.owner.ownerType === 'group') ||
        (this.props.permissions['EDIT_EVENT_PAGES'] && this.props.owner.ownerType === 'event'))
    ) {
      this.props.getStoreItems({
        ownerType: this.props.owner.ownerType as StoreItemOwnerType,
        ownerId: this.props.owner.ownerId,
      });
    }

    this.isParentMonetized();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.component.content_objects !== this.props.component.content_objects) {
      this.setState({
        storeItems: this.props.component.content_objects ?? [],
      });
    }

    if (prevProps.edit && !this.props.edit) {
      this.update();
    }
  }

  update() {
    const payload = this.props.component;

    payload.title = this.state.title;

    if (this.props.page) {
      if (
        this.props.permissions &&
        this.props.permissions['EDIT_GROUP_PAGES'] &&
        this.props.owner.ownerType !== 'gig'
      ) {
        if (this.props.updateGroupPageComponent) {
          this.props.updateGroupPageComponent(
            this.props.owner.ownerId,
            this.props.page.id,
            this.props.component.id,
            payload,
          );
        }
      } else if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
        this.props.updateEventPageComponent(
          this.props.owner.ownerId,
          this.props.page.id,
          this.props.component.id,
          payload,
          this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
        );
      }
    }
  }

  handleComponentDrag(result: any, provided: any) {
    if (result.destination !== null) {
      let _storeItems = this.state.storeItems;

      _storeItems.splice(
        result.destination.index,
        0,
        _storeItems.splice(result.source.index, 1)[0],
      );

      this.setState(
        {
          storeItems: _storeItems,
        },
        () => {
          if (this.props.page) {
            let _object_ids: string[] = [];

            for (let i in _storeItems) {
              const storeId = _storeItems[i].id;
              typeHelpers.assertNotNullOrUndefined(storeId, 'Expected store id');

              _object_ids.push(storeId);
            }
            if (
              this.props.permissions &&
              this.props.permissions['EDIT_GROUP_PAGES'] &&
              this.props.owner.ownerType !== 'gig'
            ) {
              if (this.props.updateGroupPageComponent) {
                this.props.updateGroupPageComponent(
                  this.props.page.owner_id,
                  this.props.page.id,
                  this.props.component.id,
                  {
                    content_references: {
                      object_type: 'store_item',
                      object_ids: _object_ids,
                    },
                  },
                );
              }
            } else if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
              this.props.updateEventPageComponent(
                this.props.page.owner_id,
                this.props.page.id,
                this.props.component.id,
                {
                  content_references: {
                    object_type: 'store_item',
                    object_ids: _object_ids,
                  },
                },
                this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
              );
            }
          }
        },
      );
    }
  }

  isParentMonetized() {
    const isHub = this.props.owner.parentOwnerType === uiConstants.ownerType.hub;
    const isGroup = this.props.owner.ownerType === uiConstants.ownerType.group;
    const ownerId = isHub
      ? typeHelpers.getHubIdFromOwner(this.props.owner)
      : isGroup
        ? this.props.owner?.ownerId
        : typeHelpers.getEventFromOwner(this.props.owner)?.group_id;
    const ownerType = isGroup ? this.props.owner.ownerType : this.props.owner.parentOwnerType;

    this.setState({
      isLoadingAccountInfo: true,
    });

    axios
      .get(
        swapRouteParams(routes.ACCOUNTS.GET_OBJECT_CURRENT_ACCOUNT_PUBLIC_SUMMARY, {
          object_type: ownerType,
          object_id: ownerId,
        }),
      )
      .then((response) => {
        this.setState({
          chargesEnabled: response.data.charges_enabled,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Fetching Account');
        createToast(toast);
        this.setState({
          chargesEnabled: false,
        });
      })
      .finally(() => {
        this.setState({
          isLoadingAccountInfo: false,
        });
      });
  }

  renderErrorMessageComponent() {
    if (this.state.isLoadingAccountInfo) {
      return null;
    }

    const entityType = this.props.owner.parentOwnerType === 'hub' ? 'Company' : 'Cause';
    const { errorMessage, linkText } = getNoCampaignOrNonMonetizedErrorMessage(
      this.state.chargesEnabled,
      true,
      entityType,
    );

    if (errorMessage !== null && typeHelpers.isOwnerObjectOfType(this.props.owner, 'group')) {
      return (
        <>
          {errorMessage}{' '}
          <Link to={`/group/${this.props.owner.object.handle}/admin?t=campaigns`}>{linkText}</Link>
        </>
      );
    } else if (
      errorMessage !== null &&
      typeHelpers.isOwnerObjectOfType(this.props.owner, 'event')
    ) {
      return (
        <>
          {errorMessage}{' '}
          <Link to={`/event/${this.props.owner.object.handle}/admin?t=settings`}>{linkText}</Link>
        </>
      );
    }

    return null;
  }

  renderComponentPlaceHolder() {
    if (
      this.props.hasEditPermissions &&
      this.state.storeItems &&
      this.state.storeItems.length === 0 &&
      !this.props.edit &&
      this.props.onCreateStoreClicked &&
      this.props.onManageStoreClicked
    ) {
      return (
        <ComponentPlaceholder
          title="Welcome to your new Store component!"
          message="Add items to your store to start selling tickets & merchandise"
          actions={[
            {
              label: 'Add Item',
              iconClassName: 'fas fa-plus-circle',
              action: this.props.onCreateStoreClicked,
            },
            {
              label: 'Manage',
              iconClassName: 'fas fa-cog',
              action: this.props.onManageStoreClicked,
            },
          ]}
          errorMessageComponent={this.renderErrorMessageComponent()}
        />
      );
    }
  }

  render() {
    const { permissions } = this.props;

    return (
      <div className="Store">
        {this.props.edit && (
          <TextField
            value={this.state.title}
            name="title"
            type="text"
            onChange={(e: any) => {
              handleInputChange(e, this);
            }}
          />
        )}
        {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}

        {this.state.isLoadingAccountInfo && (
          <div className="loader">
            <Loader loading={true} />
          </div>
        )}

        {this.renderComponentPlaceHolder()}
        <div className="store-item-wrap">
          <DragDropContext onDragEnd={this.handleComponentDrag}>
            {/* TODO: Refactor this to avoid duplication... */}
            <Droppable droppableId="components">
              {(provided, snapshot) => (
                <div
                  className={
                    snapshot.isDraggingOver ? 'components-wrap dragging' : 'components-wrap'
                  }
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {this.props.owner.ownerType === 'group' &&
                    permissions &&
                    permissions['EDIT_GROUP_PAGES'] &&
                    this.state.storeItems.map((item: any, index: number) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={'sitem-' + item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <StoreItem
                                key={index}
                                {...this.props}
                                item={item}
                                dragProps={provided.dragHandleProps}
                                chargesEnabled={this.state.chargesEnabled}
                                isLoadingAccountInfo={this.state.isLoadingAccountInfo}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {this.props.owner.ownerType === 'event' &&
                    permissions &&
                    permissions['EDIT_EVENT_PAGES'] &&
                    this.state.storeItems &&
                    this.state.storeItems.map((item: any, index: number) => {
                      if (this.props.isTicketsOnly === true && item.item_type === 'ticket') {
                        return (
                          <Draggable
                            key={index}
                            draggableId={'sitem-' + item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <StoreItem
                                  addToCartCallback={this.props.addToCartCallback}
                                  key={index}
                                  {...this.props}
                                  item={item}
                                  isTicketsOnly={true}
                                  dragProps={provided.dragHandleProps}
                                  chargesEnabled={this.state.chargesEnabled}
                                  isLoadingAccountInfo={this.state.isLoadingAccountInfo}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      } else if (
                        this.props.isSponsorshipsOnly === true &&
                        item.item_type === 'sponsorship'
                      ) {
                        return (
                          <Draggable
                            key={index}
                            draggableId={'sitem-' + item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <StoreItem
                                  addToCartCallback={this.props.addToCartCallback}
                                  key={index}
                                  {...this.props}
                                  item={item}
                                  isTicketsOnly={true}
                                  dragProps={provided.dragHandleProps}
                                  chargesEnabled={this.state.chargesEnabled}
                                  isLoadingAccountInfo={this.state.isLoadingAccountInfo}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      } else if (
                        this.props.isTicketsOnly !== true &&
                        this.props.isSponsorshipsOnly !== true
                      ) {
                        return (
                          <Draggable
                            key={index}
                            draggableId={'sitem-' + item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <StoreItem
                                  key={index}
                                  {...this.props}
                                  item={item}
                                  dragProps={provided.dragHandleProps}
                                  chargesEnabled={this.state.chargesEnabled}
                                  isLoadingAccountInfo={this.state.isLoadingAccountInfo}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      } else {
                        return null;
                      }
                    })}
                  {this.props.owner.ownerType === 'group' &&
                    (!permissions ||
                      permissions['EDIT_GROUP_PAGES'] === undefined ||
                      !permissions['EDIT_GROUP_PAGES']) &&
                    this.state.storeItems.map((item: any, index: number) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={'sitem-' + item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <StoreItem
                                key={index}
                                {...this.props}
                                item={item}
                                dragProps={provided.dragHandleProps}
                                chargesEnabled={this.state.chargesEnabled}
                                isLoadingAccountInfo={this.state.isLoadingAccountInfo}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {this.props.owner.ownerType === 'event' &&
                    (!permissions ||
                      permissions['EDIT_EVENT_PAGES'] === undefined ||
                      !permissions['EDIT_EVENT_PAGES']) &&
                    this.state.storeItems.map((item, index: number) => {
                      if (this.props.isTicketsOnly && item.item_type === 'ticket') {
                        return (
                          <Draggable
                            key={index}
                            draggableId={'sitem-' + item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <StoreItem
                                  addToCartCallback={this.props.addToCartCallback}
                                  key={index}
                                  {...this.props}
                                  item={item}
                                  permissions={
                                    this.props.permissions ? this.props.permissions : undefined
                                  }
                                  isTicketsOnly={true}
                                  dragProps={provided.dragHandleProps}
                                  chargesEnabled={this.state.chargesEnabled}
                                  isLoadingAccountInfo={this.state.isLoadingAccountInfo}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      } else if (
                        this.props.isSponsorshipsOnly &&
                        item.item_type === 'sponsorship'
                      ) {
                        return (
                          <Draggable
                            key={index}
                            draggableId={'sitem-' + item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <StoreItem
                                  addToCartCallback={this.props.addToCartCallback}
                                  key={index}
                                  {...this.props}
                                  item={item}
                                  permissions={
                                    this.props.permissions ? this.props.permissions : undefined
                                  }
                                  isTicketsOnly={true}
                                  dragProps={provided.dragHandleProps}
                                  chargesEnabled={this.state.chargesEnabled}
                                  isLoadingAccountInfo={this.state.isLoadingAccountInfo}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      } else if (!this.props.isTicketsOnly && !this.props.isSponsorshipsOnly) {
                        return (
                          <Draggable
                            key={index}
                            draggableId={'sitem-' + item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <StoreItem
                                  key={index}
                                  {...this.props}
                                  item={item}
                                  dragProps={provided.dragHandleProps}
                                  permissions={this.props.permissions}
                                  chargesEnabled={this.state.chargesEnabled}
                                  isLoadingAccountInfo={this.state.isLoadingAccountInfo}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      } else {
                        return null;
                      }
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    eventState: store.eventState,
    storeState: store.storeState,
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  getStoreItems: getStoreItems,
  updateEventPageComponent,
  updateGroupPageComponent,
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Store));
