import React, { FC, useState, MouseEvent } from 'react';
import Button from '../../../Button/Button';
import './ReconcilePayment.scss';
import useToastDispatcher from '../../../../hooks/useToaster';
import TextField from '../../../TextField/TextField';
import { ReactComponent as ReconcilePaymentIcon } from '../../../../assets/reconcile_payment_icon.svg';
import { ReactComponent as ReconcilePaymentEmptyIcon } from '../../../../assets/empty_search_icon.svg';
import Table, { ITableProps } from '../../../shared/Table/Table';
import { ITransactionSummary } from '@gigit/interfaces';
import Portrait from '../../../Portrait/Portrait';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { useLocale } from '../../../../hooks';
import {
  appendFilterToUrlParmas,
  defaultCurrency,
  formatCurrency,
  typeHelpers,
} from '../../../../helpers';
import { donationRequestActions } from '../../../../requestActions';

interface IProps {
  onClose?: () => void;
  groupId?: string;
}

enum EView {
  SEARCH_VIEW,
  TABLE_VIEW,
}

const ReconcilePayment: FC<IProps> = (props) => {
  const { onClose, groupId } = props;
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const [view, setView] = useState<EView>(EView.SEARCH_VIEW);
  const [searchValue, setSearchValue] = useState<string>('');
  const [reconcilePaymentData, setReconcilePaymentData] = useState<ITransactionSummary[] | null>(
    null,
  );
  const [searching, setSearching] = useState<boolean>(false);
  const [dataProcessing, setDataProcessing] = useState<boolean>(false);
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const locale = useLocale();

  const tableConfig: ITableProps<ITransactionSummary> = {
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (query) => await getTableReconcilePaymentData(query),
      additionalQueryParams: { search: searchValue },
    },
    emptyStateConfig: {
      title: 'Empty',
    },
    columns: [
      {
        id: 'user.display_name',
        Header: 'Donor',
        accessor: (row) => row.user.display_name,
        sortable: false,
        Cell: (row) => (
          <div
            notranslate="yes"
            className="donor-cell"
          >
            <Portrait
              size={30}
              currentImage={row.user.profile_image_url}
            />{' '}
            {row.user.display_name}
          </div>
        ),
      },
      {
        id: 'created_at',
        Header: 'Donation Date',
        accessor: (row) => row.created_at?.toString(),
        sortable: false,
        Cell: (row) => (
          <span notranslate="yes">
            {localizeHelpers.formatDate(
              row.created_at ?? new Date(),
              LocaleDateFormats.LL,
              locale.currentLocale,
            )}
          </span>
        ),
      },
      {
        id: 'amounts.amount',
        Header: 'Donation Amount',
        accessor: (row) => row.amounts.amount,
        sortable: false,
        Cell: (row) => {
          const exchangeRate = row.reporting_exchange_rate ?? 1;
          const currency = row.reporting_currency ?? defaultCurrency;
          const amount = row.amounts.amount * (row.amounts?.amount_multiplier ?? 1) * exchangeRate;
          return (
            <var data-var="donation_amounts_amount_2">
              {formatCurrency(amount, currency, locale.currentLocale)}
            </var>
          );
        },
      },
      {
        id: 'donation_match.match_status.code',
        Header: 'Match Status',
        accessor: (row) => row.donation_match?.match_status.code,
        sortable: false,
        predefinedColumnType: {
          type: 'STATUS',
          columnObject: () => ({
            declined: {
              label: 'Declined',
              color: 'RED',
            },
            not_applicable: {
              label: 'Not Applicable',
              color: 'GREY',
            },
            matched: {
              label: 'Matched',
              color: 'GREEN',
            },
            pending: {
              label: 'Pending',
              color: 'YELLOW',
            },
          }),
        },
      },
    ],
  };

  async function handleReconcilePayment() {
    if (reconcilePaymentData && reconcilePaymentData?.length > 0) {
      try {
        setDataProcessing(true);
        typeHelpers.assertNotNullOrUndefined(
          reconcilePaymentData[0]?.donation_match?.matching_program_id,
          'Expected Mathing Program ID',
        );
        typeHelpers.assertNotNullOrUndefined(
          reconcilePaymentData[0]?.donation_match?.package_id,
          'Expected Package ID',
        );
        typeHelpers.assertNotNullOrUndefined(groupId, 'Expected Group ID');
        await donationRequestActions.reconcileMatchingProgramPayout(
          groupId,
          reconcilePaymentData[0].donation_match?.matching_program_id,
          {
            package_id: reconcilePaymentData[0].donation_match.package_id,
            payment_method: reconcilePaymentData[0].amounts.payment_method,
          },
        );
        dispatchToastSuccess('Successfully reconciled.', 'Reconcile Payment');
        onClose?.();
      } catch (error) {
        dispatchToastError(error, 'Reconcile Payment');
      } finally {
        setDataProcessing(false);
      }
    }
  }

  async function getTableReconcilePaymentData(query: URLSearchParams) {
    let donationsData: ITransactionSummary[] = [];
    let tmpQuery = query;

    if (tmpQuery && searchValue) {
      tmpQuery = appendFilterToUrlParmas(tmpQuery, [
        {
          value: searchValue,
          id: 'donation_match.package_id',
          type: 'text',
          label: 'donation_match.package_id',
        },
      ]);
    }

    try {
      donationsData = await donationRequestActions.getDonations({
        groupId,
        query: tmpQuery,
      });
    } catch (error) {
      dispatchToastError(error, 'Reconcile Payment Donations');
    }

    return donationsData;
  }

  async function search(e: MouseEvent<HTMLButtonElement>) {
    try {
      setSearching(true);
      const result = await getTableReconcilePaymentData(new URLSearchParams());

      if (result?.length > 0) {
        setView(EView.TABLE_VIEW);
      }

      setReconcilePaymentData(result);
    } catch (error) {
      dispatchToastError(error, 'Search Reconcile Payment');
    } finally {
      setSearching(false);
    }
  }

  return (
    <div className="ReconcilePayment">
      <div className="ReconcilePayment-head">
        <h2>{EView.SEARCH_VIEW === view ? 'Reconcile Payment' : 'Track Donation Package'}</h2>
        {EView.TABLE_VIEW === view && (
          <div className="ReconcilePayment-head--container">
            <div
              className="back-part"
              onClick={() => {
                setView(EView.SEARCH_VIEW);
                setReconcilePaymentData(null);
              }}
            >
              <span>Enter Package ID</span>
              <i className="fal fa-chevron-right" />
            </div>
            <span className="package-id">
              Package Id: <var data-var="package_id">{searchValue}</var>
            </span>
          </div>
        )}
        {EView.SEARCH_VIEW === view && (
          <div className="ReconcilePayment-head--container bottom-border">
            <TextField
              placeholder="Enter a Package ID"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              name="package_id"
              type=""
            />
            <Button
              text="Find ID"
              buttonType="dark"
              onClick={search}
              type="button"
              isDisabled={searching || searchValue?.length < 1}
            />
          </div>
        )}
      </div>
      <div className="ReconcilePayment-main">
        {EView.SEARCH_VIEW === view && !reconcilePaymentData && (
          <div className="ReconcilePayment-main--search">
            <div className="icon-container">
              <ReconcilePaymentIcon />
            </div>
            <p>Enter the Package Id that you want to reconcile.</p>
          </div>
        )}
        {EView.SEARCH_VIEW === view && reconcilePaymentData?.length === 0 && (
          <div className="ReconcilePayment-main--search">
            <div className="icon-container">
              <ReconcilePaymentEmptyIcon />
            </div>
            <p>Sorry, we couldn't find the record you are looking for.</p>
          </div>
        )}
        {EView.TABLE_VIEW === view && (
          <div className="ReconcilePayment-main--table">
            <Table
              {...tableConfig}
              refreshTableIncrementor={refreshTableIncrementor}
            />
          </div>
        )}
      </div>
      <div className="ReconcilePayment-actions">
        <Button
          text="Cancel"
          buttonType="outline-dark"
          type="button"
          onClick={() => {
            onClose?.();
          }}
        />
        {EView.TABLE_VIEW === view && (
          <Button
            isDisabled={dataProcessing}
            text="Confirm Payment"
            buttonType="dark"
            type="button"
            onClick={handleReconcilePayment}
          />
        )}
      </div>
    </div>
  );
};

export default ReconcilePayment;
