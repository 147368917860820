import React, { useRef, useEffect, useState } from 'react';
import StatesAllIcon from '../../../assets/states_all_icon.svg';
import StatesPendingIcon from '../../../assets/states_pending_icon.svg';
import StatesApprovedIcon from '../../../assets/states_approved_icon.svg';
import StatesNotAcceptedIcon from '../../../assets/states_not_accepted_icon.svg';
import Button from '../../Button/Button';
import { IApplication, IEvent, IGroup, IGig } from '@gigit/interfaces';
import {
  userRequestActions,
  gigRequestActions,
  eventRequestActions,
  groupRequestActions,
  applicationRequestActions,
} from '../../../requestActions';
import useToastDispatcher from '../../../hooks/useToaster';
import './Applications.scss';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../shared/PopupMenu/PopupMenu';
import { ApplicationItemGrid, ApplicationItemList } from './ApplicationItem/ApplicationItem';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { userSelectors } from '../../../selectors/user';
import { Constants } from '@gigit/constants';
import ApplicationsDetailView from './ApplicationsDetailView/ApplicationsDetailView';
import Modal from '../../Modal/Modal';
import VolunteerApplicationModal from '../../VolunteerApplicationModal/VolunteerApplicationModal';
import { IOwnerObject } from '../../../interfaces';
import { typeHelpers } from '../../../helpers';
import { uiConstants } from '../../../constants';

export interface IDropdownOption {
  label: string;
  value: string;
  icon?: string;
}

interface IStatesOptions {
  pending: IDropdownOption;
  approved: IDropdownOption;
  rejected: IDropdownOption;
  cancelled: IDropdownOption;
  all: IDropdownOption;
}

export interface ISortByOptions {
  lastUpdated: IDropdownOption;
  appliedOn: IDropdownOption;
  name: IDropdownOption;
  status: IDropdownOption;
}

const Applications: React.FC = (props) => {
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));

  const statesDropdownRef = useRef<HTMLDivElement>(null);
  const { dispatchToastError } = useToastDispatcher();

  const statesOptions: IStatesOptions = {
    pending: {
      value: Constants.application_status.pending,
      label: 'Pending',
      icon: StatesPendingIcon,
    },
    approved: {
      value: Constants.application_status.approved,
      label: 'Approved',
      icon: StatesApprovedIcon,
    },
    rejected: {
      value: Constants.application_status.rejected,
      label: 'Not Accepted',
      icon: StatesNotAcceptedIcon,
    },
    cancelled: {
      value: Constants.application_status.cancelled,
      label: 'Cancelled',
      icon: StatesNotAcceptedIcon,
    },
    all: { value: 'all', label: 'All', icon: StatesAllIcon },
  };

  const sortByOptions: ISortByOptions = {
    lastUpdated: { value: 'updated_at', label: 'Last Updated' },
    appliedOn: { value: 'created_at', label: 'Applied On' },
    name: { value: 'gig.title', label: 'Name' },
    status: { value: 'status.code', label: 'Status' },
  };
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const [showFilterMobile, setShowFilterMobile] = useState<boolean>(false);
  const [view, setView] = useState<string>('Grid');
  const [applications, setApplications] = useState<IApplication[]>([]);
  const [states, setStates] = useState<IDropdownOption>(statesOptions.all);
  const [sortBy, setSortBy] = useState<IDropdownOption>(sortByOptions.lastUpdated);
  const [activeApplication, setActiveApplication] = useState<IApplication>();
  const [showContextMenuStates, setShowContextMenuStates] = useState<boolean>(false);
  const [showContextMenuSortBy, setShowContextMenuSortBy] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [showApplicationModal, setShowApplicationModal] = useState<boolean>(false);
  const [activeModalApplication, setActiveModalApplication] = useState<any>();
  const [applicationOwner, setApplicationOwner] = useState<IOwnerObject | null>(null);
  const getShowMenuClassStates = showContextMenuStates ? 'show' : '';
  const getShowMenuClassSortBy = showContextMenuSortBy ? 'show' : '';

  const contactOrganizerModalValues = async (application: IApplication): Promise<void> => {
    const gig =
      application.gig_id && (await gigRequestActions.getGigByHandle(`${application.gig_id}`));

    if (gig && gig.external_id?.VolunteerMatch) {
      setActiveModalApplication(gig);
    } else {
      const modalItem = application.event_id
        ? await eventRequestActions.getEventByHandleOrId(`${application.event_id}`)
        : await groupRequestActions.getGroupByHandleOrId(`${application.group_id}`);
      setActiveModalApplication(modalItem);
    }

    setShowContactModal(true);
  };

  const getUserApplications = (): void => {
    userRequestActions
      .getUserApplications({
        sortBy: sortBy.value,
        type: 'all',
        states: states.value,
      })
      .then((res) => {
        setApplications(res);
      })
      .catch((error) => dispatchToastError(error, 'Get User Applications'));
  };

  const contextMenuItems: IPopupMenuItem[] = [
    {
      id: '0',
      label: 'View Application',
      isSelected: false,
      onClick: (application: IApplication) => {
        if (application.gig_id) {
          let appOwner = typeHelpers.createOwnerObject('gig', application.gig as IGig);
          setApplicationOwner(appOwner);
        } else {
          let appOwner = typeHelpers.createOwnerObject('group', application.group as IGroup);
          setApplicationOwner(appOwner);
        }
      },
    },
    {
      id: '1',
      label: 'Withdraw Application',
      isSelected: false,
      onClick: (application: IApplication) => {
        if (application?.id) {
          userRequestActions
            .cancelUserApplication(application.user_id, application.id)
            .then(async () => {
              await getUserApplications();
            })
            .catch((error) => dispatchToastError(error, 'Cancel User Applications'));
        }
      },
    },
    {
      id: '2',
      label: 'Contact Organizer',
      isSelected: false,
      onClick: (application: IApplication) => {
        contactOrganizerModalValues(application);
      },
    },
  ];

  useEffect(() => {
    if (!showFilterMobile) {
      getUserApplications();
    }
  }, [states, sortBy]);

  useEffect(() => {
    if (applicationOwner) {
      setShowApplicationModal(true);
    }
  }, [applicationOwner]);

  const popupMenuConfigStates: IShowPopupConfig = {
    showMenu: showContextMenuStates,
    setShowMenu: setShowContextMenuStates,
    position: {
      type: 'bottom',
    },
  };
  const contextMenuItemsStates: IPopupMenuItem[] = [
    {
      id: statesOptions.pending.value,
      label: statesOptions.pending.label,
      iconImg: statesOptions.pending.icon,
      isSelected: states.value === statesOptions.pending.value,
      onClick: () => {
        setStates(statesOptions.pending);
        setShowContextMenuStates(false);
      },
    },
    {
      id: statesOptions.approved.value,
      label: statesOptions.approved.label,
      iconImg: statesOptions.approved.icon,
      isSelected: states.value === statesOptions.approved.value,
      onClick: () => {
        setStates(statesOptions.approved);
        setShowContextMenuStates(false);
      },
    },
    {
      id: statesOptions.rejected.value,
      label: statesOptions.rejected.label,
      iconImg: statesOptions.rejected.icon,
      isSelected: states.value === statesOptions.rejected.value,
      onClick: () => {
        setStates(statesOptions.rejected);
        setShowContextMenuStates(false);
      },
    },
    {
      id: statesOptions.cancelled.value,
      label: statesOptions.cancelled.label,
      iconImg: statesOptions.cancelled.icon,
      isSelected: states.value === statesOptions.cancelled.value,
      onClick: () => {
        setStates(statesOptions.cancelled);
        setShowContextMenuStates(false);
      },
    },
    {
      id: statesOptions.all.value,
      label: statesOptions.all.label,
      iconImg: statesOptions.all.icon,
      isSelected: states.value === statesOptions.all.value,
      onClick: () => {
        setStates(statesOptions.all);
        setShowContextMenuStates(false);
      },
    },
  ];

  const popupMenuConfigSortBy: IShowPopupConfig = {
    showMenu: showContextMenuSortBy,
    setShowMenu: setShowContextMenuSortBy,
    position: {
      type: 'bottom',
    },
  };
  const contextMenuItemsSortBy: IPopupMenuItem[] = [
    {
      id: sortByOptions.lastUpdated.value,
      label: sortByOptions.lastUpdated.label,
      isSelected: sortBy.value === sortByOptions.lastUpdated.value,
      onClick: () => {
        setSortBy(sortByOptions.lastUpdated);
        setShowContextMenuSortBy(false);
      },
    },
    {
      id: sortByOptions.appliedOn.value,
      label: sortByOptions.appliedOn.label,
      isSelected: sortBy.value === sortByOptions.appliedOn.value,
      onClick: () => {
        setSortBy(sortByOptions.appliedOn);
        setShowContextMenuSortBy(false);
      },
    },
    {
      id: sortByOptions.name.value,
      label: sortByOptions.name.label,
      isSelected: sortBy.value === sortByOptions.name.value,
      onClick: () => {
        setSortBy(sortByOptions.name);
        setShowContextMenuSortBy(false);
      },
    },
    {
      id: sortByOptions.status.value,
      label: sortByOptions.status.label,
      isSelected: sortBy.value === sortByOptions.status.value,
      onClick: () => {
        setSortBy(sortByOptions.status);
        setShowContextMenuSortBy(false);
      },
    },
  ];

  const archiveApplication = async (appId?: string) => {
    if (appId) {
      try {
        await applicationRequestActions.archiveApplication(appId);
        getUserApplications();
      } catch (error) {
        dispatchToastError(error, 'Archive application');
      }
    }
  };

  return (
    <>
      <div
        className="Applications"
        style={{ overflow: activeApplication ? 'hidden' : undefined }}
      >
        <div className={`mobile-filter-container ${showFilterMobile ? 'active' : ''}`}>
          <div className="filter-header">
            <i className="far fa-filter" />
            <span>Filter</span>
          </div>
          <div className="filter-content">
            <span>Sort By</span>
            {contextMenuItemsSortBy.map((item, index) => (
              <div
                className={`filter-item ${sortBy.value === item.id ? 'active' : ''}`}
                key={index}
                onClick={() => {
                  setSortBy({
                    value: item.id,
                    label: item.label || '',
                    icon: item.icon,
                  });
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
          <div className="btn-container">
            <button
              onClick={() => {
                setShowFilterMobile(false);
              }}
            >
              Cancel
            </button>
            <button
              className="submit"
              onClick={async () => {
                setShowFilterMobile(false);
                await getUserApplications();
              }}
            >
              Apply
            </button>
          </div>
        </div>
        <div className="mobile-filter-button">
          <span
            onClick={() => {
              setShowFilterMobile(true);
            }}
          >
            <i className="far fa-filter" />
          </span>
        </div>
        <div className="section-wrap">
          <div className="filter-container">
            <PopupMenu
              showMenuConfig={popupMenuConfigStates}
              menuItems={contextMenuItemsStates}
              popupMenuClass="applications-popupMenu-states"
              className={`Dropdown filter-dropdown states ${getShowMenuClassStates}`}
              onClick={() => setShowContextMenuStates(!showContextMenuStates)}
              width={
                statesDropdownRef.current?.clientWidth
                  ? statesDropdownRef.current.clientWidth - 38
                  : undefined
              }
            >
              <label>
                <span>States</span>
              </label>
              <div
                className="input-wrap"
                ref={statesDropdownRef}
              >
                <span className="label">
                  <img
                    src={states?.icon}
                    alt="states icon"
                  />
                  {states.label}
                </span>
                <i className={`fas fa-caret-down menu-btn ${getShowMenuClassStates}`} />
              </div>
            </PopupMenu>
            <PopupMenu
              showMenuConfig={popupMenuConfigSortBy}
              menuItems={contextMenuItemsSortBy}
              popupMenuClass="applications-popupMenu-sortBy"
              className={`Dropdown filter-dropdown sortBy ${getShowMenuClassSortBy}`}
              onClick={() => setShowContextMenuSortBy(!showContextMenuSortBy)}
            >
              <label>
                <span>Sort By</span>
              </label>
              <div className="input-wrap">
                <span className="label">{sortBy.label}</span>
                <i className={`fas fa-caret-down menu-btn ${getShowMenuClassSortBy}`} />
              </div>
            </PopupMenu>
          </div>
          <div className="view-icons-container">
            <Button
              onClick={() => setView('List')}
              icon="fas fa-list-ul"
              buttonClass={view === 'List' ? 'active' : undefined}
            />
            <Button
              onClick={() => setView('Grid')}
              icon="fas fa-th-large"
              buttonClass={view === 'Grid' ? 'active' : undefined}
            />
          </div>
        </div>
        <div className="grid-view">
          {view === 'Grid' &&
            applications.map((application, index) => {
              // Disassociate new context from the contextMenuItems
              let newContextMenu = Array.from(contextMenuItems);
              if (application.status?.code === 'rejected') {
                newContextMenu.push({
                  id: '3',
                  label: 'Archive Application',
                  isSelected: false,
                  onClick: (application: IApplication) => {
                    archiveApplication(application.id);
                  },
                });
              }
              return (
                <ApplicationItemGrid
                  application={application}
                  keyValue={`grid-item-${index}`}
                  setActive={() => {
                    setActiveApplication(undefined);
                  }}
                  locale={locale}
                  key={index}
                  contextMenuItems={newContextMenu.map((item) => {
                    return {
                      ...item,
                      onClickParam: application,
                    };
                  })}
                />
              );
            })}
        </div>

        <div className="list-view">
          {view === 'List' &&
            applications.map((application, index) => (
              <ApplicationItemList
                application={application}
                keyValue={`list-item-${index}`}
                key={index}
                locale={locale}
                setActive={() => {
                  setActiveApplication(undefined);
                }}
                contextMenuItems={contextMenuItems.map((item) => {
                  return {
                    ...item,
                    onClickParam: application,
                  };
                })}
              />
            ))}
        </div>
        <Modal
          show={showContactModal}
          onClose={() => setShowContactModal(false)}
          title="Contact Organizer"
        >
          <div
            className="contact-options"
            notranslate="yes"
          >
            {activeModalApplication?.external_id?.volunteerMatch ? (
              <p>The organizer has not added any contact information</p>
            ) : (
              <React.Fragment>
                {activeModalApplication?.contact_details?.email && (
                  <a href={`mailto:${activeModalApplication.contact_details.email}`}>
                    {activeModalApplication.contact_details.email}
                  </a>
                )}
                {activeModalApplication?.contact_details?.phone && (
                  <a href={`tel:${activeModalApplication.contact_details.phone}`}>
                    {activeModalApplication.contact_details.phone}
                  </a>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="actions">
            <Button onClick={() => setShowContactModal(false)}>Close</Button>
          </div>
        </Modal>
      </div>
      <div className={`detail-view-container ${activeApplication ? 'active' : ''}`}>
        {activeApplication && (
          <ApplicationsDetailView
            application={activeApplication}
            locale={locale}
            hideDetailView={() => {
              setActiveApplication(undefined);
            }}
          />
        )}
      </div>

      <VolunteerApplicationModal
        readOnly={true}
        closeModal={() => setShowApplicationModal(false)}
        owner={applicationOwner!}
        user={userState.user}
        show={showApplicationModal}
      />
    </>
  );
};

export default Applications;
