import React, { FC } from 'react';
import './../../../../Scheduler/Scheduler.scss';
import { IAttendingEventSummary, IShiftUserSummary, IGigSummaryFE } from '@gigit/interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../../../localizeHelpers';
import { IAppState } from '../../../../../store';
import { userSelectors } from '../../../../../selectors/user';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Constants } from '@gigit/constants';
import eventIcon from '../../../../../assets/event-icon-masks.svg';
import groupIcon from '../../../../../assets/people-logo-dark.svg';

import { uiConstants } from '../../../../../constants';
import QuillTextEditor from '../../../../QuillTextEditor/QuillTextEditor';

interface IProps {
  selected: IGigSummaryFE | null;
  totalApplicants: number;
}

// TODO: Add the agenda types in constants and use the constants in checks instead of strings.
interface IAgendaShiftSummary extends IShiftUserSummary {
  type: string;
}

interface IAgendaEventSummary extends IAttendingEventSummary {
  type: string;
}

interface ScheduleListSummary {
  day: string;
  date: string;
  dateMs: number;
  list: (IAgendaEventSummary | IAgendaShiftSummary)[];
}

interface IDropdownOption {
  label: string;
  value: string;
}

interface ITypeDropdownOptions {
  all: IDropdownOption;
  shifts: IDropdownOption;
  events: IDropdownOption;
}

interface ContactData {
  phone: string;
  email: string;
}

const OpportunityDetails: FC<IProps> = (props: IProps) => {
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const owner = props.selected?.event
    ? {
        title: props.selected.event.title,
        handle: props.selected.event.handle,
        icon: eventIcon,
        type: Constants.object_type.event,
      }
    : ({
        title: props.selected?.group?.title || props.selected?.group_title,
        handle: props.selected?.group?.handle || props.selected?.group_handle,
        icon: groupIcon,
        type: Constants.object_type.group,
      } as {
        title: string;
        handle: string;
        icon: string;
        type: string;
      });
  return (
    <div>
      <div className="volunteer-info">
        <div className="volunteer-main-info">
          <div className="basic">
            <span className="location item">
              <span className="icon">
                <i className="fal fa-map-marker-alt" />
              </span>
              {props.selected?.locations && props.selected.locations.length > 0
                ? `${props.selected.locations[0].city}${
                    props.selected.locations[0].state && props.selected.locations[0].state !== ''
                      ? ', ' + props.selected.locations[0].state
                      : ''
                  }`
                : 'Online'}
            </span>
            <span
              className="date item"
              notranslate="yes"
            >
              <span className="icon">
                <i className="fa fa-clock-o" />
              </span>
              {props.selected?.start_date
                ? localizeHelpers.formatDate(props.selected.start_date, LocaleDateFormats.LL, 'en')
                : 'n/a'}
            </span>
          </div>
          <div className="basic">
            {owner.title && (
              <span
                className="owner item"
                notranslate="yes"
              >
                <span className="icon">
                  <i className="fa fa-users" />
                </span>
                <Link to={`/${owner.type}/${owner.handle}`}>{owner.title}</Link>
              </span>
            )}
            <span
              className="interested item"
              notranslate="yes"
            >
              <span className="icon">
                <i className="fa fa-id-badge" />
              </span>
              {`${props.totalApplicants || 0} Interested`}
            </span>
          </div>
        </div>
        <div className="volunteer-about">
          <div className="section-title">About Opportunity</div>
          <div className="about-content">
            <QuillTextEditor
              value={props.selected?.description}
              preserveWhitespace={true}
              readOnly={true}
              theme={uiConstants.quill.readOnlyTheme}
            />
          </div>
        </div>
        {owner.title && (
          <div className="benefitting-charity">
            <div className="section-title">Benefitting Charity</div>
            <div className="charity avatar">
              <a href={`/${owner.type}/${owner.handle}`}>
                {props.selected?.group?.profile_image_url ? (
                  <img
                    src={props.selected?.group?.profile_image_url}
                    alt="charity avatar"
                    className="logo"
                  />
                ) : (
                  <div className="empty-img" />
                )}
              </a>

              <div className="charity-name">
                <Link
                  to={`/${owner.type}/${owner.handle}`}
                  className="charity-title"
                >
                  {owner.title}
                </Link>
                <div className="charity-id">
                  {`Charity ID: ${props.selected?.group?.charity_id}`}
                </div>
              </div>
            </div>
            <div className="benefitting-charity-content">{props.selected?.group?.description}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OpportunityDetails;
