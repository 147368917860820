import React from 'react';

import { IGroup } from '@gigit/interfaces';
import Portrait from '../Portrait/Portrait';
import QuillTextEditor from '../QuillTextEditor/QuillTextEditor';

import './BenefitingCharityTile.scss';
import { uiConstants } from '../../constants/uiConstants';

interface IProps {
  group: IGroup;
}

export class BenefitingCharityTile extends React.Component<IProps> {
  render() {
    return (
      <div className="Benefiting-Charity-Tile">
        <div className="BC-title">Benefiting Charity</div>
        <div
          notranslate="yes"
          className="BC-wrap"
        >
          <div className="info">
            <Portrait
              size={80}
              currentImage={this.props.group.profile_image_url}
            />
            <div className="group-title"> {this.props.group.title}</div>
          </div>
          <div className="description">
            <QuillTextEditor
              value={this.props.group.description}
              readOnly={true}
              preserveWhitespace={true}
              theme={uiConstants.quill.readOnlyTheme}
              modules={{
                toolbar: uiConstants.quill.defaultToolbar,
                clipboard: {
                  matchVisual: false,
                },
              }}
              formats={uiConstants.quill.defaultFormats}
              onChange={() => {}}
            />
          </div>
        </div>
      </div>
    );
  }
}
