import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  swapRouteParams,
  routes,
  handleInputChange,
  formatCurrency,
  defaultCurrency,
  toastError,
} from '../../helpers';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as CTooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { IGroupState } from '../../reducers/group';
import './DonorStatusDashboard.scss';
import axios from 'axios';
import { IDonor, IDonorClassificationReportOptions, IUser } from '@gigit/interfaces';
import 'chartjs-plugin-labels';
import ContextPopup from '../shared/ContextPopup/ContextPopup';
import Portrait from '../Portrait/Portrait';
import Dropdown, { IOptions } from '../Dropdown/Dropdown';
import moment from 'moment';
import { Tooltip } from '../shared/Tooltip/Tooltip';
import { createToast } from '../../actions/toaster';
import { getGroupDonorClassificationExport } from '../../actions/group';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ContactDetails from '../ContactDetails/ContactDetails';
import errorHelpers from '../../helpers/errorHelpers';
import { IToast } from '../../interfaces';
import { userSelectors } from '../../selectors/user';
import { localizeHelpers } from '../../localizeHelpers';

ChartJS.register(ArcElement, CTooltip, Legend);

interface IPassedProps extends RouteComponentProps<any> {}
interface IPropsFromDispatch {
  createToast(toast: IToast): void;
  getGroupDonorClassificationExport: (
    groupId: string,
    groupHandle: string,
    options: IDonorClassificationReportOptions,
  ) => void;
}
interface IPropsFromState {
  groupState: IGroupState;
  locale: string;
}

type DonorStatusDashboardProps = IPassedProps & IPropsFromDispatch & IPropsFromState;
interface IChartRefState {
  [chartName: string]: React.RefObject<typeof Doughnut>;
}

interface IState {
  chartRefState: IChartRefState;
  donors: IDonor[];
  defaultChartOptions: ChartOptions;
  newDonors: IDonor[];
  lapsingDonors: IDonor[];
  recoveredDonors: IDonor[];
  lapsedDonors: IDonor[];
  renewedDonors: IDonor[];
  startYear: string;
  endYear: string;
  years: IOptions[];
  mounted: boolean;
  contextPopup: {
    cursorX: number;
    cursorY: number;
    show: boolean;
  };
  donorContextList: {
    headerColor: string;
    headerChartName: string;
    headerFilterName: string;
    donors: IDonor[];
  };
  showContactDetailsModal: boolean;
  selectedUser: IUser | null;
}

const chartConfig: {
  [index: string]: {
    labels: string[];
    filters: Array<number | string>;
    colors: string[];
    chartName?: string;
    propertyToFilterOn: keyof IDonor;
  };
} = {
  newDonors: {
    labels: ['Regular', 'Mid', 'Major', 'Undefined'],
    filters: ['regular', 'middle', 'major', 'unmatched'],
    colors: ['#76D5AC', '#1BB975', '#18945E', '#126F46'],
    chartName: 'New',
    propertyToFilterOn: 'donor_level' as keyof IDonor,
  },
  lapsingDonors: {
    labels: ['Regular', 'Mid', 'Major', 'Undefined'],
    filters: ['regular', 'middle', 'major', 'unmatched'],
    colors: ['#9393E0', '#6565D3', '#5152A9', '#3E3D7F'],
    propertyToFilterOn: 'donor_level' as keyof IDonor,
  },
  recoveredDonors: {
    labels: ['Regular', 'Mid', 'Major', 'Undefined'],
    filters: ['regular', 'middle', 'major', 'unmatched'],
    colors: ['#E3C58A', '#D3A140', '#AA8133', '#966809'],
    propertyToFilterOn: 'donor_level' as keyof IDonor,
  },
  lapsedDonorsRegular: {
    labels: ['1 Yr', '2 Yrs', '3 Yrs'],
    filters: [1, 2, 3],
    colors: ['#80D1DD', '#0F9FB5', '#097383'],
    propertyToFilterOn: 'number_of_years_lapsing' as keyof IDonor,
  },
  lapsedDonorsMid: {
    labels: ['1 Yr', '2 Yrs', '3 Yrs'],
    filters: [1, 2, 3],
    colors: ['#80D1DD', '#0F9FB5', '#097383'],
    propertyToFilterOn: 'number_of_years_lapsing' as keyof IDonor,
  },
  lapsedDonorsMajor: {
    labels: ['1 Yr', '2 Yrs', '3 Yrs'],
    filters: [1, 2, 3],
    colors: ['#80D1DD', '#0F9FB5', '#097383'],
    propertyToFilterOn: 'number_of_years_lapsing' as keyof IDonor,
  },
  renewedDonorsRegular: {
    labels: ['Same', 'More', 'Less'],
    filters: ['same', 'more', 'less'],
    colors: ['#80D1DD', '#0F9FB5', '#097383'],
    propertyToFilterOn: 'donor_change' as keyof IDonor,
  },
  renewedDonorsMid: {
    labels: ['Same', 'More', 'Less'],
    filters: ['same', 'more', 'less'],
    colors: ['#80D1DD', '#0F9FB5', '#097383'],
    propertyToFilterOn: 'donor_change' as keyof IDonor,
  },
  renewedDonorsMajor: {
    labels: ['Same', 'More', 'Less'],
    filters: ['same', 'more', 'less'],
    colors: ['#80D1DD', '#0F9FB5', '#097383'],
    propertyToFilterOn: 'donor_change' as keyof IDonor,
  },
};

class DonorStatusDashboard extends React.Component<DonorStatusDashboardProps, IState> {
  constructor(props: DonorStatusDashboardProps) {
    super(props);

    this.state = {
      showContactDetailsModal: false,
      selectedUser: null,
      defaultChartOptions: {
        maintainAspectRatio: true,
        responsive: true,
        // legend: {
        //     display: false
        // },
        // tooltips: {
        //     enabled: false
        // },
        plugins: {
          // labels: [
          //     {
          //         position: 'outside',
          //         textMargin: 8,
          //         render: ({ label, value }: { label: string, value: number }) => {
          //             return `${label} - ${value}`
          //         }
          //     }
          // ]
        },
      },
      chartRefState: {
        newDonors: React.createRef(),
        lapsingDonors: React.createRef(),
        recoveredDonors: React.createRef(),
        renewedDonorsRegular: React.createRef(),
        renewedDonorsMid: React.createRef(),
        renewedDonorsMajor: React.createRef(),
        lapsedDonorsRegular: React.createRef(),
        lapsedDonorsMid: React.createRef(),
        lapsedDonorsMajor: React.createRef(),
      },
      donors: [],
      newDonors: [],
      lapsingDonors: [],
      recoveredDonors: [],
      lapsedDonors: [],
      renewedDonors: [],
      startYear: moment().format('YYYY'),
      endYear: moment().format('YYYY'),
      years: [],
      mounted: false,
      donorContextList: {
        headerColor: 'white',
        headerChartName: '',
        headerFilterName: '',
        donors: [],
      },
      contextPopup: {
        show: false,
        cursorX: 0,
        cursorY: 0,
      },
    };
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    });
  }

  componentDidUpdate(prevProps: DonorStatusDashboardProps, prevState: IState) {
    if (!prevState.mounted && this.state.mounted && this.props.groupState?.group) {
      this.getDonorStatusData();
      const { groupState } = this.props;
      const years: IOptions[] = this.getNumberRange(
        parseInt(moment(groupState.group.created_at).format('YYYY')),
        parseInt(moment().format('YYYY')),
      ).map((year) => ({ label: year.toString(), value: year.toString() }));

      this.setState({
        years,
      });
    }
  }

  getNumberRange = (min: number, max: number) => {
    return Array.from({ length: max - min + 1 }, (_, i) => min + i);
  };

  getDonorStatusData = async () => {
    const { groupState } = this.props;
    const { startYear, endYear } = this.state;

    try {
      const { data: donors } = await axios.get<IDonor[]>(
        swapRouteParams(routes.GET_DONOR_CLASSIFICATION_REPORT, { groupId: groupState.group.id }),
        {
          params: {
            start_fiscal_year: parseInt(startYear),
            end_fiscal_year: parseInt(endYear),
          },
        },
      );

      this.setState({
        donors,
        newDonors: donors.filter(({ donor_status }) => donor_status === 'new'),
        lapsingDonors: donors.filter(({ donor_status }) => donor_status === 'lapsing'),
        recoveredDonors: donors.filter(({ donor_status }) => donor_status === 'recovered'),
        lapsedDonors: donors.filter(({ donor_status }) => donor_status === 'lapsed'),
        renewedDonors: donors.filter(({ donor_status }) => donor_status === 'renewed'),
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObj.translatedMessage, 'Get Donor Status Dashboard Report');
      this.props.createToast(toast);
    }
  };

  getChartDataset = (donors: IDonor[], chartName: keyof typeof chartConfig) => {
    const { labels, colors, filters, propertyToFilterOn } = chartConfig[chartName];

    //TODO: any for now until you can use union types on array methods https://github.com/microsoft/TypeScript/issues/36390
    let data = filters.map((filter: string | number) => {
      return donors.filter((donor) => donor[propertyToFilterOn] === filter).length;
    });

    return {
      labels: labels.map((label) => localizeHelpers.translate(label)),
      datasets: [
        {
          data,
          backgroundColor: colors,
          borderWidth: 0,
        },
      ],
    } as ChartData<'doughnut'>;
  };

  renderContextPopup = () => {
    const { contextPopup } = this.state;
    const { donors, headerChartName, headerColor, headerFilterName } = this.state.donorContextList;
    const { groupState } = this.props;

    return (
      <ContextPopup {...contextPopup}>
        <div className="DonorListPopup">
          <div
            className="header"
            style={{ backgroundColor: headerColor }}
          >
            <span
              className="title"
              notranslate="yes"
            >
              {localizeHelpers.translate(headerChartName)} - {donors.length}{' '}
              {localizeHelpers.translate(headerFilterName)}
            </span>
            <div className="action">
              <i
                onClick={(evt) => this.toggleContextPopup(evt)}
                className="fal fa-times"
              />
            </div>
          </div>
          <div className="donor-list-container">
            {donors.map((donor) => {
              const { user } = donor;
              return (
                <div
                  key={user?.id}
                  className="donor-row"
                >
                  <Portrait
                    size={25}
                    currentImage={user?.profile_image_url}
                  />
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ showContactDetailsModal: true, selectedUser: user || null });
                    }}
                    className="donor-name"
                    notranslate="yes"
                  >
                    {`${user?.first_name} ${user?.last_name}`}
                  </span>

                  <span
                    className="donor-contribution"
                    notranslate="yes"
                  >
                    {formatCurrency(
                      donor.total_donated,
                      groupState.group.localization?.currency ?? defaultCurrency,
                      this.props.locale,
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </ContextPopup>
    );
  };

  toggleContextPopup = (event: any) => {
    const { clientX, clientY } = event;
    const { contextPopup } = this.state;
    this.setState({
      contextPopup: {
        show: !contextPopup.show,
        cursorX: clientX,
        cursorY: clientY,
      },
    });
  };

  handleElementOnClick = (
    chartName: string,
    event: any,
    headerChartName: string,
    chartDonorState: IDonor[],
  ) => {
    // const { chartRefState } = this.state;
    //
    // if (chartRefState[chartName] == null) return;
    // const chartInstance = chartRefState[chartName].current;
    // const element = chartInstance?.getElementAtEvent(event) as any;
    // const chartConfigKey = chartName as keyof typeof chartConfig;
    //
    // if(element?.length) {
    //     this.setState({
    //         donorContextList: {
    //             headerChartName,
    //             headerFilterName: chartConfig[chartConfigKey].labels[element[0]._index],
    //             headerColor: chartConfig[chartConfigKey].colors[element[0]._index],
    //             donors: chartDonorState.filter(
    //                 (donor: IDonor) => donor[chartConfig[chartConfigKey].propertyToFilterOn] === chartConfig[chartConfigKey].filters[element[0]._index]
    //             )
    //         }
    //     });
    //     this.toggleContextPopup(event!);
    // }
  };

  getFilteredStateByDonorLevel = (chartState: IDonor[], donorLevel: string) => {
    return chartState.filter(({ donor_level }) => donor_level === donorLevel);
  };

  exportDonors() {
    const { startYear, endYear } = this.state;

    this.props.getGroupDonorClassificationExport(
      this.props.groupState.group.id,
      this.props.groupState.group.handle,
      {
        start_fiscal_year: parseInt(startYear),
        end_fiscal_year: parseInt(endYear),
      },
    );
  }

  render() {
    const { chartRefState, defaultChartOptions, contextPopup } = this.state;
    const { newDonors, lapsingDonors, recoveredDonors, lapsedDonors, renewedDonors } = this.state;

    return (
      <div className="DonorStatusDashboard section-wrap">
        <div className="section-title">
          <div className="section-inner-title">Donor Status</div>

          <Button
            className="export-button"
            icon="far fa-file-download"
            onClick={() => this.exportDonors()}
            text={'Export'}
          />
        </div>
        <div className="section-inner">
          <div className="row">
            <div className="date-container">
              <div className="startYear">
                <Dropdown
                  value={this.state.startYear}
                  onChange={(e) => {
                    handleInputChange(e, this, false, () => {
                      this.getDonorStatusData();
                    });
                  }}
                  name="startYear"
                  options={this.state.years}
                  notranslate="yes"
                />
              </div>
              <span className="divider">-</span>
              <div className="endYear">
                <Dropdown
                  value={this.state.endYear}
                  onChange={(e) => {
                    handleInputChange(e, this, false, () => {
                      this.getDonorStatusData();
                    });
                  }}
                  name="endYear"
                  options={this.state.years}
                  notranslate="yes"
                />
              </div>
            </div>
            <div className="actions">
              <Tooltip message="Refresh">
                {(ref) => (
                  <div
                    ref={ref}
                    className="hover-action"
                  >
                    <i
                      className="fa fa-sync"
                      onClick={this.getDonorStatusData}
                    />
                  </div>
                )}
              </Tooltip>
            </div>
          </div>
          <div className="row">
            <div className="panel">
              <div className="chart-title">New donors</div>
              <div className="chart-container">
                <div
                  className="doughnut-chart-container"
                  notranslate="yes"
                >
                  <Doughnut
                    // ref={chartRefState["newDonors"]}
                    data={this.getChartDataset(newDonors, 'newDonors')}
                    // options={{
                    //     ...defaultChartOptions,
                    //     onClick: (event) => this.handleElementOnClick("newDonors", event, localizeHelpers.translate("New"), newDonors),
                    //     hover: {
                    //         onHover: (event: any, chartElement) => {
                    //             event.target!.style.cursor = chartElement[0] ? 'pointer' : 'default';
                    //         }
                    //     },
                    // }}
                  />
                  <div className="doughnut-center-info">
                    <span className="info">
                      {localizeHelpers.formatNumber(newDonors.length || 0, this.props.locale)}
                    </span>
                    <span
                      className="title"
                      notranslate="yes"
                    >
                      {localizeHelpers.translate('TOTAL')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel">
              <div className="chart-title">Lapsing donors</div>
              <div className="chart-container">
                <div
                  className="doughnut-chart-container"
                  notranslate="yes"
                >
                  <Doughnut
                    // ref={chartRefState["lapsingDonors"]}
                    data={this.getChartDataset(lapsingDonors, 'lapsingDonors')}
                    // options={{
                    //     ...defaultChartOptions,
                    //     onClick: (event) => this.handleElementOnClick("lapsingDonors", event, "Lapsing", lapsingDonors),
                    //     hover: {
                    //         onHover: (event: any, chartElement) => {
                    //             event.target!.style.cursor = chartElement[0] ? 'pointer' : 'default';
                    //         }
                    //     },
                    // }}
                  />
                  <div className="doughnut-center-info">
                    <span className="info">
                      {localizeHelpers.formatNumber(lapsingDonors.length || 0, this.props.locale)}
                    </span>
                    <span
                      className="title"
                      notranslate="yes"
                    >
                      {localizeHelpers.translate('TOTAL')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel">
              <div className="chart-title">
                <span>Recovered donors</span>
                <Tooltip message="When a donor makes a gift after 2 or more years.">
                  {(ref) => (
                    <div
                      ref={ref}
                      className="hover-action"
                    >
                      <i className="fas fa-question-circle" />
                    </div>
                  )}
                </Tooltip>
              </div>
              <div className="chart-container">
                <div
                  className="doughnut-chart-container"
                  notranslate="yes"
                >
                  <Doughnut
                    // ref={chartRefState["recoveredDonors"]}
                    data={this.getChartDataset(recoveredDonors, 'recoveredDonors')}
                    // options={{
                    //     ...defaultChartOptions,
                    //     onClick: (event) => this.handleElementOnClick("recoveredDonors", event, "New", recoveredDonors),
                    //     hover: {
                    //         onHover: (event: any, chartElement) => {
                    //             event.target!.style.cursor = chartElement[0] ? 'pointer' : 'default';
                    //         }
                    //     },
                    // }}
                  />
                  <div className="doughnut-center-info">
                    <span className="info">{recoveredDonors.length}</span>
                    <span
                      className="title"
                      notranslate="yes"
                    >
                      {localizeHelpers.translate('TOTAL')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="panel">
              <div className="chart-title">
                <span className="total-lapsed">
                  <var data-var="number_of_donors">
                    {localizeHelpers.formatNumber(
                      this.state.lapsedDonors.length || 0,
                      this.props.locale,
                    )}
                  </var>{' '}
                  total
                </span>
                <span>Lapsed donors</span>
                <Tooltip message="It has been 1 to 3 years since the donors last gift.">
                  {(ref) => (
                    <div
                      ref={ref}
                      className="hover-action"
                    >
                      <i className="fas fa-question-circle" />
                    </div>
                  )}
                </Tooltip>
              </div>
              <div className="chart-container">
                <div
                  className="doughnut-chart-container"
                  notranslate="yes"
                >
                  <Doughnut
                    // ref={chartRefState["lapsedDonorsRegular"]}
                    data={this.getChartDataset(
                      this.getFilteredStateByDonorLevel(lapsedDonors, 'regular'),
                      'lapsedDonorsRegular',
                    )}
                    // options={{
                    //     ...defaultChartOptions,
                    //     onClick: (event) => this.handleElementOnClick("lapsedDonorsRegular", event, "Regular", this.getFilteredStateByDonorLevel(lapsedDonors, "regular")),
                    // }}
                  />
                  <div className="doughnut-center-info">
                    <span
                      className="info"
                      notranslate="yes"
                    >
                      {localizeHelpers.formatNumber(
                        this.getFilteredStateByDonorLevel(lapsedDonors, 'regular').length || 0,
                        this.props.locale,
                      )}
                    </span>
                    <span className="title">{localizeHelpers.translate('REGULAR')}</span>
                  </div>
                </div>
                <div
                  className="doughnut-chart-container"
                  notranslate="yes"
                >
                  <Doughnut
                    // ref={chartRefState["lapsedDonorsMid"]}
                    data={this.getChartDataset(
                      this.getFilteredStateByDonorLevel(lapsedDonors, 'middle'),
                      'lapsedDonorsMid',
                    )}
                    // options={{
                    //     ...defaultChartOptions,
                    //     onClick: (event) => this.handleElementOnClick("lapsedDonorsMid", event, "Mid", this.getFilteredStateByDonorLevel(lapsedDonors, "middle")),
                    // }}
                  />
                  <div className="doughnut-center-info">
                    <span
                      className="info"
                      notranslate="yes"
                    >
                      {localizeHelpers.formatNumber(
                        this.getFilteredStateByDonorLevel(lapsedDonors, 'middle').length || 0,
                        this.props.locale,
                      )}
                    </span>
                    <span className="title">{localizeHelpers.translate('MID')}</span>
                  </div>
                </div>
                <div
                  className="doughnut-chart-container"
                  notranslate="yes"
                >
                  <Doughnut
                    // ref={chartRefState["lapsedDonorsMajor"]}
                    data={this.getChartDataset(
                      this.getFilteredStateByDonorLevel(lapsedDonors, 'major'),
                      'lapsedDonorsMajor',
                    )}
                    // options={{
                    //     ...defaultChartOptions,
                    //     onClick: (event) => this.handleElementOnClick("lapsedDonorsMajor", event, "Major", this.getFilteredStateByDonorLevel(lapsedDonors, "major")),
                    // }}
                  />
                  <div className="doughnut-center-info">
                    <span
                      className="info"
                      notranslate="yes"
                    >
                      {localizeHelpers.formatNumber(
                        lapsedDonors.filter(({ donor_level }) => donor_level === 'major').length ||
                          0,
                        this.props.locale,
                      )}
                    </span>
                    <span className="title">{localizeHelpers.translate('MAJOR')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="panel">
              <div className="chart-title">
                <span className="total-renewed">
                  <var data-var="number_of_donors">{this.state.renewedDonors.length}</var> total
                </span>
                <span>Renewed donors</span>
                <Tooltip message="When an active donor makes another gift.">
                  {(ref) => (
                    <div
                      ref={ref}
                      className="hover-action"
                    >
                      <i className="fas fa-question-circle" />
                    </div>
                  )}
                </Tooltip>
              </div>
              <div className="chart-container">
                <div
                  className="doughnut-chart-container"
                  notranslate="yes"
                >
                  <Doughnut
                    // ref={chartRefState["renewedDonorsRegular"]}
                    data={this.getChartDataset(
                      this.getFilteredStateByDonorLevel(renewedDonors, 'regular'),
                      'renewedDonorsRegular',
                    )}
                    // options={{
                    //     ...defaultChartOptions,
                    //     onClick: (event) => this.handleElementOnClick("renewedDonorsRegular", event, "Regular", this.getFilteredStateByDonorLevel(renewedDonors, "regular")),
                    // }}
                  />
                  <div className="doughnut-center-info">
                    <span
                      className="info"
                      notranslate="yes"
                    >
                      {localizeHelpers.formatNumber(
                        this.getFilteredStateByDonorLevel(renewedDonors, 'regular').length || 0,
                        this.props.locale,
                      )}
                    </span>
                    <span className="title">{localizeHelpers.translate('REGULAR')}</span>
                  </div>
                </div>
                <div
                  className="doughnut-chart-container"
                  notranslate="yes"
                >
                  <Doughnut
                    // ref={chartRefState["renewedDonorsMid"]}
                    data={this.getChartDataset(
                      this.getFilteredStateByDonorLevel(renewedDonors, 'middle'),
                      'renewedDonorsMid',
                    )}
                    // options={{
                    //     ...defaultChartOptions,
                    //     onClick: (event) => this.handleElementOnClick("renewedDonorsMid", event, "Mid", this.getFilteredStateByDonorLevel(renewedDonors, "middle")),
                    // }}
                  />
                  <div className="doughnut-center-info">
                    <span
                      className="info"
                      notranslate="yes"
                    >
                      {localizeHelpers.formatNumber(
                        renewedDonors.filter(({ donor_level }) => donor_level === 'middle')
                          .length || 0,
                        this.props.locale,
                      )}
                    </span>
                    <span className="title">{localizeHelpers.translate('MID')}</span>
                  </div>
                </div>
                <div
                  className="doughnut-chart-container"
                  notranslate="yes"
                >
                  <Doughnut
                    // ref={chartRefState["renewedDonorsMajor"]}
                    data={this.getChartDataset(
                      this.getFilteredStateByDonorLevel(renewedDonors, 'major'),
                      'renewedDonorsMajor',
                    )}
                    // options={{
                    //     ...defaultChartOptions,
                    //     onClick: (event) => this.handleElementOnClick("renewedDonorsMajor", event, "Major",  this.getFilteredStateByDonorLevel(renewedDonors, "major")),
                    // }}
                  />
                  <div className="doughnut-center-info">
                    <span
                      className="info"
                      notranslate="yes"
                    >
                      {localizeHelpers.formatNumber(
                        this.getFilteredStateByDonorLevel(renewedDonors, 'major').length || 0,
                        this.props.locale,
                      )}
                    </span>
                    <span className="title">{localizeHelpers.translate('MAJOR')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {contextPopup.show && this.renderContextPopup()}
        <Modal
          class="ContactDetailsModal"
          show={this.state.showContactDetailsModal}
          onClose={() => {
            this.setState({ showContactDetailsModal: false });
          }}
          title="Contact Details"
        >
          <ContactDetails
            userId={this.state.selectedUser?.id!}
            {...this.props}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  createToast,
  getGroupDonorClassificationExport,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DonorStatusDashboard));
