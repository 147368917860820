import React from 'react';
import { Fragment, useRef, useState } from 'react';
import './StoryActions.scss';

export const EVENT_TYPE = Object.freeze({
  MOUSE_DOWN: 'mouseDown',
  MOUSE_UP: 'mouseUp',
  TOUCH_START: 'touchStart',
  TOUCH_END: 'touchEnd',
});

export const EVENT_REGION = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right',
});
interface IActionsProps {
  onNextClick: () => void;
  onPrevClick: () => void;
  onPause: () => void;
  onResume: () => void;
}

type IActionEvent = React.MouseEvent | React.TouchEvent;

export function StoryActions({ onNextClick, onPrevClick, onPause, onResume }: IActionsProps) {
  const [isStoryPaused, setIsStoryPaused] = useState(false);
  // debouce pause interaction
  // because mouse down is called with mouse up immediately
  const pauseTimerRef = useRef<any>(null);
  function handlePause(event: IActionEvent) {
    event.stopPropagation();
    event.preventDefault();
    clearTimeout(pauseTimerRef.current);

    // delay this transaction
    pauseTimerRef.current = setTimeout(() => {
      onPause();
      setIsStoryPaused(true);
    }, 200);
  }

  function handleInteractions(region: string, event: IActionEvent) {
    event.stopPropagation();
    event.preventDefault();

    //clear any pending timeout
    clearTimeout(pauseTimerRef.current);
    if (isStoryPaused) {
      onResume();
      setIsStoryPaused(false);
      return;
    }
    onResume();
    if (region === EVENT_REGION.LEFT) {
      onPrevClick();
      return;
    }
    onNextClick();
  }

  function getEvents(region: string) {
    return {
      onMouseUp: (e: React.MouseEvent) => handleInteractions(region, e),
      onTouchEnd: (e: React.TouchEvent) => handleInteractions(region, e),
      onTouchStart: (e: React.TouchEvent) => handlePause(e),
      onMouseDown: (e: React.MouseEvent) => handlePause(e),
    };
  }

  return (
    <Fragment>
      <div
        className={'left'}
        {...getEvents(EVENT_REGION.LEFT)}
      >
        <i className="fa fa-chevron-left" />
      </div>
      <div
        className={'right'}
        {...getEvents(EVENT_REGION.RIGHT)}
      >
        <i className="fa fa-chevron-right" />
      </div>
    </Fragment>
  );
}
