import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { IRole, IUserRole } from '@gigit/interfaces';
import { IAppState } from '../../store';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import queryString from 'query-string';
import {
  routes,
  swapRouteParams,
  formatFilters,
  capitalizeString,
  IStringMap,
} from '../../helpers';
import { Constants } from '@gigit/constants';
import { IGroupState } from '../../reducers/group';
import { IEventState } from '../../reducers/event';
import { ISortableTableColumn, ISortableTableRow } from '../SortableTable/SortableTable';
import {
  getGroupMembers,
  getGroupRoles,
  assignRoleToUser,
  getGroupMembersExport,
} from '../../actions/group';
import {
  getEventMembers,
  getEventRoles,
  assignEventRoleToUser,
  getEventMembersExport,
} from '../../actions/event';
import Portrait from '../Portrait/Portrait';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ContactDetails from '../ContactDetails/ContactDetails';
import AddMemberModal from '../AddMemberModal/AddMemberModal';
import FilterData from '../FilterData/FilterData';
import './MemberManagement.scss';
import { IActiveFilter, IOwnerObject } from '../../interfaces';
import { uiConstants } from '../../constants';
import Table, { ITableProps } from '../shared/Table/Table';
import { eventRequestActions, groupRequestActions } from '../../requestActions';

interface IPropsFromState extends RouteComponentProps<any> {
  eventState: IEventState;
  groupState: IGroupState;
}

interface IPassedProps {
  owner: IOwnerObject;
  permissions: IStringMap;
}

interface IPropsFromDispatch {
  assignRoleToUser(groupId: string, roleId: string, userId: string, callback?: () => void): void;
  getGroupMembers(groupId: string, search?: string): void;
  getGroupRoles(groupId: string): void;

  getEventMembers(eventId: string, _search?: string): void;
  getEventRoles(eventId: string): void;
  assignEventRoleToUser(
    eventId: string,
    roleId: string,
    userId: string,
    callback?: () => void,
  ): void;

  getGroupMembersExport(groupId: string, groupHandle: string): void;
  getEventMembersExport(eventId: string, eventHandle: string): void;
}

type IProps = IPropsFromDispatch & IPropsFromState & IPassedProps;

interface IState {
  searchValue: string;
  roles: any[];
  members: any[];
  showDetailsModal: boolean;
  currentMember: IUserRole | null;
  columns: ISortableTableColumn[];
  data: ISortableTableRow[][];
  membersLoading: boolean;
  showAddMemberModal: boolean;
  searchConnectionsValue: string;
  showSearchMemberList: boolean;
  showFilterModal: boolean;
  filters: IActiveFilter[];
  sort: string;
  page: number;
  hasMore: boolean;
  membersTableConfig: ITableProps<IUserRole>;
  previousQueryParams: URLSearchParams | null;
  refreshTableIncrementor: number;
}
class MemberManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: '',
      roles: [],
      members: [],
      showDetailsModal: false,
      currentMember: null,
      columns: [
        { id: 'portrait', label: 'Portrait', sortable: false },
        { id: 'user.display_name', label: 'Contact Name' },
        { id: 'role_name', label: 'Role' },
        { id: 'user_capacity', label: 'Capacity' },
        { id: 'actions', label: 'Actions', sortable: false },
      ],
      data: [],
      membersLoading: false,
      showAddMemberModal: false,
      searchConnectionsValue: '',
      showSearchMemberList: false,
      showFilterModal: false,
      filters: [],
      sort: '',
      page: 0,
      hasMore: true,
      membersTableConfig: {
        columns: [
          {
            id: 'profileImage',
            Header: 'Portrait',
            Cell: (item) => {
              return (
                <Portrait
                  currentImage={item.user?.profile_image_url}
                  size={40}
                />
              );
            },
          },
          {
            id: 'user.display_name',
            Header: 'Display Name',
            Cell: (item) => {
              const isOnlyFollower =
                item.user_capacity.includes(Constants.user_capacity.follower) &&
                item.user_capacity.length === 1;
              return isOnlyFollower || this.props.eventState.event?.hub_id ? (
                <Link
                  notranslate="yes"
                  className="user-name-link"
                  to={`/user/${item.user?.handle}`}
                >
                  {item.user?.display_name}
                </Link>
              ) : (
                <a
                  onClick={() => {
                    this.setState({
                      showDetailsModal: true,
                      currentMember: item,
                    });
                  }}
                  notranslate="yes"
                >
                  {item.user?.display_name}
                </a>
              );
            },
          },
          {
            id: 'role_name',
            Header: 'Role',
            Cell: (item) => {
              const hasEditRolesPermission =
                this.props.owner.ownerType === 'event'
                  ? !!this.props.permissions['EDIT_EVENT_ROLES']
                  : !!this.props.permissions['EDIT_GROUP_ROLES'];
              return hasEditRolesPermission && !item.user?.is_dummy_user ? (
                <Dropdown
                  value={item.role_id || ''}
                  onChange={async (e) => {
                    await this.handleRoleChange(e, item);
                  }}
                  options={this.state.roles}
                  name={'role' + this.state.members.map((member) => member.id).indexOf(item.id)}
                  notranslate="yes"
                />
              ) : (
                <div>{item.role_name}</div>
              );
            },
          },
        ],
        tableActionOptions: {
          enableRowContextMenuActions: true,
          tableActions: [
            {
              type: 'ROW_CONTEXT_MENU',
              label: 'Email',
              icon: 'fas fa-envelope',
              hideIf: (role) => !!role.user?.is_dummy_user,
              onClick: (_, role) => {
                window.location.href = 'mailto:' + role.user?.email;
              },
            },
            {
              type: 'ROW_CONTEXT_MENU',
              label: 'View Profile',
              icon: 'fas fa-user',
              hideIf: (item) => !!item.user?.is_dummy_user,
              onClick: (_, item) => this.props.history.push('/user/' + item.user?.handle),
            },
            {
              type: 'ROW_CONTEXT_MENU',
              label: 'View Details',
              icon: 'fas fa-user',
              hideIf: (role) =>
                Boolean(this.isOnlyFollower(role) || this.props.eventState.event?.hub_id),
              onClick: (_, role) => this.setState({ showDetailsModal: true, currentMember: role }),
            },
          ],
        },
        pagination: {
          pageSizeOptions: [10],
          queryAction: async (params) => await this.getData(params),
        },
        filterOptions: {
          enableTableSearch: true,
        },
        emptyStateConfig: {
          title: 'No data',
          description: "We couldn't find any donations",
        },
      },
      refreshTableIncrementor: 0,
      previousQueryParams: null,
    };

    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.formatMemberCapacities = this.formatMemberCapacities.bind(this);
    this.sortCallback = this.sortCallback.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.fetchMembers();
    this.fetchRoles();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.getRolesFromProps(this.props) !== this.getRolesFromProps(prevProps)) {
      let _roles: any[] = [];
      const ownerRoles = this.getRolesFromProps(this.props);

      for (let r in ownerRoles) {
        _roles.push({
          label: ownerRoles[r].role_name,
          value: ownerRoles[r].id,
        });
      }

      this.setState({
        roles: _roles,
      });
    }

    if (
      prevState.membersLoading !== this.state.membersLoading &&
      !this.state.membersLoading &&
      this.props.location.search.includes('user')
    ) {
      let _params = queryString.parse(this.props.location.search);

      this.setState({
        currentMember: this.state.members.find((member) => member.user?.handle === _params.user),
        showDetailsModal: true,
      });
    }
  }

  isOnlyFollower = (userRole: IUserRole) =>
    userRole.user_capacity.includes(Constants.user_capacity.follower) &&
    userRole.user_capacity.length === 1;

  async getData(params: URLSearchParams) {
    if (
      this.state.previousQueryParams &&
      params.get('field_filters[user.display_name]') !==
        this.state.previousQueryParams.get('field_filters[user.display_name]')
    ) {
      params.set('skip', '0');
      params.set('limit', `${this.state.membersTableConfig.pagination.pageSizeOptions[0] + 1}`);
    }
    this.setState({
      previousQueryParams: params,
    });
    if (this.props.owner.ownerType === uiConstants.ownerType.event) {
      return eventRequestActions.getEventMembers(this.props.owner.ownerId, params);
    } else {
      return groupRequestActions.getGroupMembers(this.props.owner.ownerId, params);
    }
  }

  fetchRoles() {
    switch (this.props.owner.ownerType) {
      case 'group':
        this.props.getGroupRoles(this.props.owner.ownerId);
        break;
      case 'event':
        this.props.getEventRoles(this.props.owner.ownerId);
        break;
    }
  }

  setHasMore(results: IUserRole[]) {
    if (this.state.page === 0) {
      this.setState({
        hasMore: results.length >= parseInt(uiConstants.adminListRows),
      });
    } else {
      this.setState({
        hasMore: results.length > parseInt(uiConstants.adminListRows),
      });
    }
  }

  fetchMembers(search?: string, sort?: string, filter?: string) {
    this.setState(
      {
        membersLoading: true,
      },
      () => {
        let _route: string =
          swapRouteParams(routes.GET_GROUP_MEMBERS, { groupId: this.props.owner.ownerId }) + '?';

        if (this.props.owner.ownerType === 'event') {
          _route =
            swapRouteParams(routes.GET_EVENT_MEMBERS, { eventId: this.props.owner.ownerId }) + '?';
        }

        _route += `limit=${parseInt(uiConstants.adminListRows) + 1}&skip=${
          this.state.page * parseInt(uiConstants.adminListRows)
        }`;

        if (search) {
          _route = _route + '&search=' + search;
        }

        if (sort) {
          _route = _route + '&sort=' + sort;
        }

        if (filter) {
          _route += '&';
          _route += filter;
        }

        axios
          .get(_route)
          .then((response) => {
            let originalResponse = [...response.data];
            if (originalResponse.length > parseInt(uiConstants.adminListRows)) {
              response.data.pop();
            }
            this.setState(
              {
                members: response.data,
              },
              () => {
                this.setHasMore(originalResponse);
              },
            );
          })
          .finally(() => {
            this.setState({
              membersLoading: false,
            });
          });
      },
    );
  }

  onNext() {
    if (!this.state.hasMore) return;
    const page = this.state.page + 1;
    this.setState(
      {
        page,
      },
      () => {
        this.fetchMembers(this.state.searchValue, this.state.sort);
      },
    );
  }

  onPrev() {
    if (this.state.page === 0) {
      return;
    }
    const page = this.state.page - 1;
    this.setState(
      {
        page,
      },
      () => {
        this.fetchMembers(this.state.searchValue, this.state.sort);
      },
    );
  }

  getRolesFromProps(props: IProps): IRole[] {
    switch (this.props.owner.ownerType) {
      case 'group':
        return props.groupState.groupRoles;
      case 'event':
        return props.eventState.eventRoles;
      default:
        throw new Error(`Unsupported owner type ${props.owner.ownerType}`);
    }
  }

  async handleRoleChange(event: any, member: any, prevent?: boolean) {
    if (prevent === true) {
      event.preventDefault();
    }

    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;

    switch (this.props.owner.ownerType) {
      case 'group':
        this.props.assignRoleToUser(this.props.owner.ownerId, value, member.user_id, () => {
          this.setState({
            refreshTableIncrementor: this.state.refreshTableIncrementor + 1,
          });
        });
        break;
      case 'event':
        this.props.assignEventRoleToUser(this.props.owner.ownerId, value, member.user_id, () => {
          this.setState({
            refreshTableIncrementor: this.state.refreshTableIncrementor + 1,
          });
        });
        break;
    }
  }

  exportGroupMembers() {
    switch (this.props.owner.ownerType) {
      case 'group':
        this.props.getGroupMembersExport(
          this.props.groupState.group.id,
          this.props.groupState.group.handle,
        );
        break;
      case 'event':
        this.props.getEventMembersExport(
          this.props.eventState.event.id,
          this.props.eventState.event.handle,
        );
        break;
    }
  }

  formatMemberCapacities(member: IUserRole) {
    if (member.user_capacity.length === 0) {
      return <span className="none">None</span>;
    }

    return member.user_capacity?.map((capacity: any, cIndex: any) => {
      return (
        <span
          className={capacity}
          key={cIndex}
        >
          {capitalizeString(capacity)}
        </span>
      );
    });
  }

  sortCallback(_sort: string) {
    this.setState(
      {
        sort: _sort,
      },
      () => {
        this.fetchMembers(
          this.state.searchValue,
          this.state.sort,
          formatFilters(this.state.filters),
        );
      },
    );
  }

  toggleShowAddMemberModal = (showAddMemberModal: boolean) => {
    this.setState({ showAddMemberModal });
  };

  render() {
    const hasEditRolesPermission =
      this.props.owner.ownerType === 'event'
        ? !!this.props.permissions['EDIT_EVENT_ROLES']
        : !!this.props.permissions['EDIT_GROUP_ROLES'];

    return (
      <div className="MemberManagement section-wrap">
        <div className="section-title">
          <div className="forms-title">Contacts</div>
          <Button
            className="filter-button"
            icon="fal fa-filter"
            onClick={() => {
              this.setState({ showFilterModal: true });
            }}
            text={`Filter${this.state.filters.length > 0 ? ` (${this.state.filters.length})` : ''}`}
          />
          <Button
            className="invite-button"
            icon="fal fa-plus"
            onClick={() => this.toggleShowAddMemberModal(true)}
            text="Invite Contacts"
          />
          <Button
            className="export-button"
            icon="far fa-file-download"
            onClick={() => this.exportGroupMembers()}
            text={'Export'}
          />
        </div>
        <div className="section-inner">
          <div className="MemberManagement-list">
            <div className="search">
              <Table
                {...this.state.membersTableConfig}
                refreshTableIncrementor={this.state.refreshTableIncrementor}
              />
            </div>
          </div>
        </div>
        <Modal
          class="filter-modal"
          show={this.state.showFilterModal}
          onClose={() => {
            this.setState({ showFilterModal: false });
          }}
        >
          <FilterData
            {...this.props}
            currentFilters={this.state.filters}
            filterOptions={[
              { id: 'user.display_name', label: 'Display Name', type: 'text' },
              { id: 'role_id', label: 'Role', type: 'dropdown', options: this.state.roles },
              {
                id: 'user_capacity',
                label: 'Capacity',
                type: 'dropdown',
                options: [
                  { label: 'Volunteer', value: '=volunteer' },
                  { label: 'Donor', value: '=donor' },
                  { label: 'Supporter', value: '=supporter' },
                  { label: 'Fundraiser', value: '=fundraiser' },
                  { label: 'Sponsor', value: '=sponsor' },
                ],
              },
            ]}
            onApply={(appliedFilters: IActiveFilter[]) => {
              this.setState({ filters: appliedFilters, showFilterModal: false }, () => {
                this.fetchMembers(
                  this.state.searchValue,
                  this.state.sort,
                  formatFilters(this.state.filters),
                );
              });
            }}
          />
        </Modal>
        <Modal
          class="ContactDetailsModal"
          show={this.state.showDetailsModal}
          onClose={() => {
            this.setState({ showDetailsModal: false });
          }}
          title="Contact Details"
        >
          <ContactDetails
            contact={this.state.currentMember ?? undefined}
            {...this.props}
          />
        </Modal>
        <AddMemberModal
          owner={this.props.owner}
          showAddMemberModal={this.state.showAddMemberModal}
          toggleShowAddMemberModal={this.toggleShowAddMemberModal}
          objectType={this.props.owner.ownerType as 'group' | 'event'}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  getGroupMembers,
  getGroupRoles,
  assignRoleToUser,
  getEventMembers,
  getEventRoles,
  assignEventRoleToUser,
  getGroupMembersExport,
  getEventMembersExport,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberManagement));
