import { Reducer } from 'redux';
import { ModalActions, ModalActionTypes } from '../actions/modals';

export interface IModalState {
  depth: number;
}

export const initialModalState: IModalState = {
  depth: 0,
};

export const modalsReducer: Reducer<IModalState, ModalActions> = (
  state = initialModalState,
  action,
) => {
  switch (action.type) {
    case ModalActionTypes.UPDATE_MODAL: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
