import { ICause } from '@gigit/interfaces';
import { useEffect, useState } from 'react';
import { causeRequestActions } from '../requestActions';
import useToastDispatcher from './useToaster';

interface IUseFocusAreas {
  focusAreas: ICause[];
  isLoading: Boolean;
}
/**
 * Fetches master list of focus areas
 */
export function useFocusAreas(): IUseFocusAreas {
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [focusAreas, setFocusAreas] = useState<ICause[]>([]);

  const { dispatchToastError } = useToastDispatcher();

  useEffect(() => {
    const fetchFocusAreas = async () => {
      setIsLoading(true);
      try {
        const results = await causeRequestActions.getCauses();
        setFocusAreas(results);
      } catch (e) {
        dispatchToastError(e, 'Fetching Focus Areas');
      } finally {
        setIsLoading(false);
      }
    };

    fetchFocusAreas();
  }, []);

  return { isLoading, focusAreas };
}
