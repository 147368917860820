import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IAppState } from '../../store';
import { handleInputChange, setSEOMetatags } from '../../helpers';

import { clearGroup } from '../../actions/group';

import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';

import { IOptions } from '../../components/Dropdown/Dropdown';

import Teamwork3 from '../../assets/teamwork_3.png';
import Cleanup from '../../assets/cleanup.png';

import './CreateProfile.scss';
import { localizeHelpers } from '../../localizeHelpers';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  clearGroup(): void;
}

interface IState {
  groupTypes: IOptions[];
  firstName: string;
  email: string;
}

// TODO: Remove this component (Not being used anymore).
class CreateProfile extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      firstName: '',
      email: '',
      groupTypes: [
        { label: 'Non-Profit', value: 'non-profit' },
        { label: 'Business', value: 'business' },
        { label: 'High School', value: 'high school' },
        { label: 'University/College', value: 'university/college' },
        { label: 'Service Club', value: 'serviceclub' },
      ],
    };
  }

  componentDidMount() {
    this.props.clearGroup();
    setSEOMetatags({
      title: localizeHelpers.translate('Create Profile | Kambeo'),
      urlPath: 'create-profile',
    });
  }

  render() {
    let { t } = this.props;

    return (
      <div className="CreateProfile">
        <div className="CreateProfile-hero">
          <div className="curve"></div>
          <form
            action="/create/group"
            className="inner"
          >
            <h1>{t('Make meaningful connections')}</h1>
            <div className="row">
              <TextField
                icon="fa fa-user"
                placeholder="First Name"
                required={true}
                value={this.state.firstName}
                name="firstName"
                type="text"
                onChange={(e: any) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="row">
              <TextField
                icon="fa fa-envelope"
                placeholder="Email"
                required={true}
                value={this.state.email}
                name="email"
                type="email"
                onChange={(e: any) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div>
              <input
                name="skipGroup"
                value="true"
                type="hidden"
              />
            </div>
            <div className="actions">
              <Button
                type="submit"
                text="Get Started"
              />
            </div>
          </form>
        </div>
        <div className="metrics">
          <h2>
            {t(
              'The first social media platform built to help people give back to their community and promote positive change',
            )}
          </h2>
          <p>Engage with non-profits and highlight your validated community impact</p>
        </div>
        <div className="why small">
          <h3>{t('Why use the Kambeo Platform?')}</h3>
          <div className="why-inner">
            <div className="half">
              <div className="circle"></div>
              <div className="half-inner">
                <div className="title">{t('A meaningful social media profile')}</div>
                <p>
                  {t(
                    'It doesn’t matter if your organization is big or small, Kambeo makes it simple to manage your organizations fundraising, volunteers and donors all in one place.',
                  )}
                </p>
              </div>
            </div>
            <div className="half">
              <div className="circle"></div>
              <div className="half-inner">
                <div className="title">{t('We celebrate people who make a difference')}</div>
                <p>
                  {t(
                    'Kambeo highlights and celebrates individual’s successes and contribution to the community. These people are the heartbeat of a vibrant community.',
                  )}
                </p>
              </div>
            </div>
            <div className="half">
              <div className="circle"></div>
              <div className="half-inner">
                <div className="title">{t('Increase your job application success')}</div>
                <p>
                  {t(
                    'When applying for a job, people attach their LinkedIn profile to provide a job history overview. Now you can also attach a Kambeo Profile to provide an overview of your positive community impact.',
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="why-inner">
            <div className="half">
              <div className="circle"></div>
              <div className="half-inner">
                <div className="title">{t('Validated actions')}</div>
                <p>
                  {t(
                    'All your work is validated by the organization you contributed to through volunteering, donating, working, fundraising, or attending events.',
                  )}
                </p>
              </div>
            </div>
            <div className="half">
              <div className="circle"></div>
              <div className="half-inner">
                <div className="title">{t('Centralized hub')}</div>
                <p>
                  {t(
                    'No more going site to site to search for ways to connect with community organizations. Kambeo is a single place to find out how you can make a positive impact in the community.',
                  )}
                </p>
              </div>
            </div>
            <div className="half">
              <div className="circle"></div>
              <div className="half-inner">
                <div className="title">{t('Mobile friendly')}</div>
                <p>
                  {t(
                    'Connect with community organizations from any device. Our mobile friendly web pages make it easy to search, find and track your impact.',
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="banner">
          <img
            alt="Teamwork"
            src={Teamwork3}
          />
        </div>
        <div className="why large">
          <h3>{t('About Kambeo')}</h3>
          <div className="why-inner">
            <div className="half">
              <div className="circle"></div>
              <div className="half-inner">
                <div className="title">{t('Volunteer your TIME')}</div>
                <p>
                  {t(
                    'A centralized platform to find volunteer opportunities. Track your hours, skills and experiences gained along the way. Everything you do is validated by the organization you work with, including valuable reviews.',
                  )}
                </p>
              </div>
            </div>
            <div className="half">
              <div className="circle"></div>
              <div className="half-inner">
                <div className="title">{t('Donate your TREASURE')}</div>
                <p>
                  {t(
                    'Find organizations, focus areas, and fundraising events that you are passionate about. Easily donate to the focus areas that matter to you and track your contribution to positive change!',
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="why-inner">
            <div className="half">
              <div className="circle"></div>
              <div className="half-inner">
                <div className="title">{t('Mobilize your TEAM')}</div>
                <p>
                  {t(
                    'Sign up for a peer-to-peer fundraising event, invite your friends to join your team and raise money on behalf of an organization.',
                  )}
                </p>
              </div>
            </div>
            <div className="half">
              <div className="circle"></div>
              <div className="half-inner">
                <div className="title">{t('Offer your TALENT')}</div>
                <p>
                  {t(
                    'Provide a paid service to the community and earn additional revenue outside your regular job. Earn extra cash while still supporting the focus areas you care about.',
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="community">
          <div className="community-inner">
            <div className="community-image">
              <img
                alt="Cleanup"
                src={Cleanup}
              />
            </div>
            <div className="community-content">
              <h3>{t('One Community')}</h3>
              <p>
                {t(
                  'Bringing together all parts of the community to work together and bring more awareness to our focus areas. Why use multiple platforms and spend hard earned fundraising dollars on software? Kambeo is on a mission to bring our communities closer together and engage people more than ever!',
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="footer-cta">
          <div className="footer-cta-inner">
            <h3>Ready to join the Kambeo community and start making an impact?</h3>
            <Button text="Get started today" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {
  clearGroup,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(CreateProfile),
);
