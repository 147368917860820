import React, { createRef, MutableRefObject } from 'react';
import { combineClassNames } from '../../../helpers';

import './HorizontalScroller.scss';

export interface IHorizontalScrollerRenderProps {
  /** This can be called in render function to force the scroller to focus on a specific tab. */
  moveItemIntoFocus: (index: number) => void;
}

interface IProps {
  className?: string;
  /** Children should be a function that returns a list of react nodes (One react node for each tab). */
  children: (props: IHorizontalScrollerRenderProps) => React.ReactElement[];
}

interface IState {
  isLeftScroll: boolean;
  isRightScroll: boolean;
  isScrolling: boolean;
  clientX: number;
}

/** Extra padding for detecting if a element is in full view of scrollview.
 * Needed because some browsers don't 100% bring the element into view.
 */
const IN_VIEW_DETECTION_PADDING = 10;

/** Used to provide scroll behaviour to a horizontal list of clickable tab items.
 *
 * Scroll indicators are used to show there is more scrollable content.
 *
 * Note: By default HorizontalScroller will try to fit 100% the width of it's parent.
 */
export class HorizontalScroller extends React.Component<IProps, IState> {
  timeout = createRef<NodeJS.Timeout>() as MutableRefObject<NodeJS.Timeout | null>;
  scrollContainerRef = createRef<HTMLDivElement>();
  tabContainerRef = createRef<HTMLUListElement>();
  tabRefs = createRef<Array<HTMLLIElement | null>>() as MutableRefObject<
    Array<HTMLLIElement | null>
  >;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isLeftScroll: false,
      isRightScroll: false,
      isScrolling: false,
      clientX: 0,
    };

    this.tabRefs.current = new Array<HTMLLIElement>(0);
  }

  componentDidMount() {
    this.checkScrollDetectors();

    // GIG-1124/GIG-1125: When we initially load in, scroll detection can sometimes not work and scroll arrows don't appear until user manually scrolls.
    // This is a Hacky fix so scroll arrows properly appear when page is loaded in.
    this.timeout.current = setTimeout(() => {
      this.checkScrollDetectors();
      this.timeout.current = null;
    }, 3000);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.children !== this.props.children) {
      // wait for animation to finish
      this.timeout.current = setTimeout(() => {
        this.checkScrollDetectors();
        this.timeout.current = null;
      }, 250);
    }

    if (this.scrollContainerRef?.current)
      this.scrollContainerRef?.current.addEventListener('wheel', this.handleWheel);
  }

  componentWillUnmount() {
    // Cleanup timeout
    if (this.timeout.current) {
      clearTimeout(this.timeout.current);
      this.timeout.current = null;
    }

    if (this.scrollContainerRef?.current)
      this.scrollContainerRef?.current.removeEventListener('wheel', this.handleWheel);
  }

  render() {
    const isLeftScroll = this.state.isLeftScroll;
    const isRightScroll = this.state.isRightScroll;

    const children = this.props.children({
      moveItemIntoFocus: (index: number) => this.moveItemIntoFocus(index, 'center'),
    });

    if (children.length !== this.tabRefs.current.length) {
      this.tabRefs.current = new Array<HTMLLIElement>(children.length);
    }

    // We use cloneElement() to apply a ref to each scrollable item.
    let renderChildren: React.ReactElement[] = [];
    for (let index = 0; index < children.length; index++) {
      const renderChild = React.cloneElement(children[index], {
        ref: (el: HTMLLIElement) => (this.tabRefs.current[index] = el),
      });
      renderChildren.push(renderChild);
    }

    return (
      <div className={combineClassNames('HorizontalScroller', this.props.className)}>
        <i
          className={combineClassNames(
            'left-scroll-indicator fas fa-chevron-left',
            isLeftScroll ? 'scroll-indicator-visible' : null,
          )}
          onClick={this.moveBackward}
        />

        <div
          onMouseUp={this.onMouseUp}
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          className="page-scroller"
          ref={this.scrollContainerRef}
          onScroll={(e) => {
            e.preventDefault();
            this.checkScrollDetectors();
          }}
        >
          <ul
            className="page-tab-container"
            ref={this.tabContainerRef}
          >
            {renderChildren}
          </ul>
        </div>
        <i
          className={combineClassNames(
            'right-scroll-indicator fas fa-chevron-right',
            isRightScroll ? 'scroll-indicator-visible' : null,
          )}
          onClick={this.moveForward}
        />
      </div>
    );
  }

  onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ ...this.state, isScrolling: true, clientX: e.clientX });
  };

  onMouseUp = () => {
    this.setState({ ...this.state, isScrolling: false, clientX: 0 });
  };

  onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const currentPos = this.state.clientX;
    if (this.state.isScrolling) {
      if (e.clientX > currentPos) {
        this.moveForward();
      }
      if (e.clientX < currentPos) {
        this.moveBackward();
      }
    }
  };

  handleWheel = (e: WheelEvent) => {
    if (this.state.isLeftScroll || this.state.isRightScroll) {
      const currentPos = 0;
      e.preventDefault();
      if (e.deltaY > currentPos) {
        this.moveForward();
      }
      if (e.deltaY < currentPos) {
        this.moveBackward();
      }
    }
  };

  /** Checks if left/right scroll detectors need to be visible. */
  checkScrollDetectors = () => {
    const getElementWidth = (tab: HTMLLIElement) => {
      const { marginLeft, marginRight } = this.getElementMargins(tab);

      return tab.getBoundingClientRect().width + marginLeft + marginRight;
    };

    const scrollContainerWidth =
      this.scrollContainerRef.current?.getBoundingClientRect().width ?? 0;
    const tabsWidth = this.tabRefs.current.reduce(
      (prev, cur) => prev + (cur ? getElementWidth(cur) : 0),
      0,
    );

    if (this.tabRefs.current.length !== 0 && scrollContainerWidth < tabsWidth) {
      const isLeftScroll = !this.checkInFullView(
        this.scrollContainerRef.current,
        this.tabRefs.current[0],
        IN_VIEW_DETECTION_PADDING,
      );
      const isRightScroll = !this.checkInFullView(
        this.scrollContainerRef.current,
        this.tabRefs.current[this.tabRefs.current.length - 1],
        IN_VIEW_DETECTION_PADDING,
      );
      this.setState({
        isLeftScroll,
        isRightScroll,
      });
    } else {
      this.setState({
        isLeftScroll: false,
        isRightScroll: false,
      });
    }
  };

  /** Computes the margins of the provided element. Note: Only works for px units. */
  getElementMargins(element: HTMLElement) {
    const numberRegex = /^\d+/;

    const computedStyle = window.getComputedStyle(element);

    // Attempt to add margin left/right onto width.
    // Only px units are supported.
    const marginLeftMatch = computedStyle.marginLeft.match(numberRegex);
    const marginRightMatch = computedStyle.marginRight.match(numberRegex);

    let marginLeft = 0,
      marginRight = 0;

    if (marginLeftMatch?.length === 1) {
      const margin = parseInt(marginLeftMatch[0]);
      if (!isNaN(margin)) {
        marginLeft = margin;
      }
    }

    if (marginRightMatch?.length === 1) {
      const margin = parseInt(marginRightMatch[0]);
      if (!isNaN(margin)) {
        marginRight = margin;
      }
    }

    return { marginLeft, marginRight };
  }

  /** Moves a page item into view of the scroll view.
   * @param index
   * @param position Controls where in the scrollview to show the element.
   */
  moveItemIntoFocus(index: number, position: 'start' | 'center' | 'end') {
    const getElementWidth = (tab: HTMLLIElement) => {
      const { marginLeft, marginRight } = this.getElementMargins(tab);

      return tab.offsetWidth + marginLeft + marginRight;
    };

    const targetElement = this.tabRefs.current[index];
    if (targetElement && this.scrollContainerRef.current) {
      // Determine the offset in the scrollview
      const leftScreenOffset =
        position === 'center'
          ? (this.scrollContainerRef.current.offsetWidth - getElementWidth(targetElement)) / 2
          : position === 'start'
            ? 0
            : this.scrollContainerRef.current.offsetWidth - getElementWidth(targetElement);

      // Determine distance from first element to current.
      let leftSiblingOffset = 0;
      for (let i = 0; i < index; i++) {
        const tab = this.tabRefs.current[i];
        if (tab) {
          leftSiblingOffset += getElementWidth(tab);
        }
      }

      let scrollValue = leftSiblingOffset - leftScreenOffset;

      // To get the element positioned at the end properly, we subtract the scroll-container width from target element end position.
      if (position === 'end') {
        const targetElementEnd = leftSiblingOffset + getElementWidth(targetElement);
        scrollValue = targetElementEnd - this.scrollContainerRef.current.offsetWidth;
      }

      this.scrollContainerRef.current.scrollLeft = Math.max(0, scrollValue);
    }
  }

  /** Moves backwards in the scroll view */
  moveBackward = () => {
    // Here we start by finding first tab that is visible and then find the first tab that is not fully visible, that will be the tab we scroll to.
    let isInView = false;
    for (let i = this.tabRefs.current.length - 1; i >= 0; i--) {
      if (!isInView) {
        if (this.checkInFullView(this.scrollContainerRef.current, this.tabRefs.current[i])) {
          isInView = true;
        }
      } else {
        if (
          !this.checkInFullView(
            this.scrollContainerRef.current,
            this.tabRefs.current[i],
            IN_VIEW_DETECTION_PADDING,
          )
        ) {
          this.moveItemIntoFocus(i, 'start');
          break;
        }
      }
    }
  };

  /** Moves forwards in the scrollview. */
  moveForward = () => {
    // Here we start by finding first tab that is visible and then find the first tab that is not fully visible, that will be the tab we scroll to.
    let isInView = false;
    for (let i = 0; i < this.tabRefs.current.length; i++) {
      if (!isInView) {
        if (this.checkInFullView(this.scrollContainerRef.current, this.tabRefs.current[i])) {
          isInView = true;
        }
      } else {
        if (
          !this.checkInFullView(
            this.scrollContainerRef.current,
            this.tabRefs.current[i],
            IN_VIEW_DETECTION_PADDING,
          )
        ) {
          this.moveItemIntoFocus(i, 'end');
          break;
        }
      }
    }
  };

  /** Returns true if the given element is in full view of the given parent scroll container.
   * @param container
   * @param element
   * @param container
   * @param element
   * @param detectionPadding Padding for detection
   */
  checkInFullView(
    container: HTMLElement | null,
    element: HTMLElement | null,
    detectionPadding?: number,
  ) {
    if (!element || !container) {
      return false;
    }

    const childRect = element.getBoundingClientRect();
    const left = childRect.left,
      right = childRect.right;
    // Check if right of the element is off the page
    if (childRect.right < 0) {
      return false;
    }

    // Check its within the document viewport
    if (left > document.documentElement.clientWidth) {
      return false;
    }

    const parentRect = container.getBoundingClientRect();
    const minOffset = detectionPadding || 0;

    // Check right side of element is inside parent rect
    if (right - minOffset > parentRect.right) {
      return false;
    }

    // Check left side of element is inside parent rect.
    return left + minOffset >= parentRect.left;
  }
}
