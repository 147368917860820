import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { IPage, IRole } from '@gigit/interfaces';
import { Link } from 'react-router-dom';
import { swapRouteParams, routes, hasPermission, mapPermissions } from '../../../../helpers';
import { EventManagePermissions } from '@gigit/constants';
import { IAppState } from '../../../../store';
import ManageTabsModal from '../../../../components/ProfilePageComponents/PageSelector/ManageTabsModal/ManageTabsModal';
import { IOwnerObject } from '../../../../interfaces/ownerObject';
import typeHelpers from '../../../../helpers/typeHelpers';
import { Location } from 'history';
import './Navigation.scss';

interface IProps {
  loading: boolean;
  owner: IOwnerObject | null;
  location: Location;
  role: IRole | null;
  onTabClick(page: IPage, pageIndex: number): void;
  onGetPages?: (pages: IPage[]) => void;
}

interface IState {
  pages: IPage[];
  pagesLoading: boolean;
  activePage: number;
  showManageModal: boolean;
  navRef: any;
  isScrolling: boolean;
  scrollLeft: number;
  scrollTop: number;
  clientX: number;
  clientY: number;
  showAddIcon: boolean;
}

class Navigation extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      pages: [],
      pagesLoading: true,
      activePage: 0,
      showManageModal: false,
      navRef: React.createRef(),
      isScrolling: false,
      scrollLeft: 0,
      scrollTop: 0,
      clientX: 0,
      clientY: 0,
      showAddIcon: true,
    };

    this.handlePageReorder = this.handlePageReorder.bind(this);
    this.createNewPage = this.createNewPage.bind(this);
    this.updatePage = this.updatePage.bind(this);
    this.deletePage = this.deletePage.bind(this);
    this.dragNavStart = this.dragNavStart.bind(this);
    this.dragNavEnd = this.dragNavEnd.bind(this);
    this.onDrag = this.onDrag.bind(this);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.owner?.object !== prevProps.owner?.object && this.props.owner) {
      switch (this.props.owner.ownerType) {
        case 'event':
          if (
            (prevProps.owner === null && this.props.owner.object !== null) ||
            this.props.owner?.ownerId !== prevProps.owner?.ownerId
          ) {
            axios
              .get(
                swapRouteParams(routes.GET_EVENT_PAGES, { eventId: this.props.owner.ownerId }) +
                  '?flags[include_component_objects]=true',
              )
              .then((response) => {
                this.setState(
                  {
                    pages: response.data,
                    pagesLoading: false,
                  },
                  () => {
                    if (this.state.pages.length > 0) {
                      let _params = queryString.parse(this.props.location.search);
                      const tabIndex = _params.tab
                        ? Math.min(
                            Math.max(Number(_params.tab) - 1, 0),
                            this.state.pages.length - 1,
                          )
                        : 0;

                      this.setState({
                        activePage: tabIndex,
                      });

                      this.props.onTabClick(this.state.pages[tabIndex], tabIndex);
                    }

                    if (this.props.onGetPages) {
                      this.props.onGetPages(response.data);
                    }
                  },
                );
              });
          }
          break;
      }
    }

    if (
      this.state.navRef.current &&
      this.state.navRef.current.getAttribute('listener') !== 'true'
    ) {
      this.state.navRef.current.addEventListener('mousedown', this.dragNavStart, false);
      this.state.navRef.current.addEventListener('mouseup', this.dragNavEnd, false);
      this.state.navRef.current.addEventListener('mouseleave', this.dragNavEnd, false);
      this.state.navRef.current.addEventListener('mousemove', this.onDrag, false);
      this.state.navRef.current.setAttribute('listener', 'true');
    }

    this.showAddIcon();
  }

  showAddIcon() {
    const navInner: Element = document.getElementsByClassName('nav-inner')[0];

    const navItems: Element = document.getElementsByClassName('nav-items')[0];

    if (navItems.scrollWidth >= navInner.scrollWidth - 50 && this.state.showAddIcon) {
      this.setState({
        showAddIcon: false,
      });
    } else if (navItems.scrollWidth + 70 < navInner.scrollWidth - 50 && !this.state.showAddIcon) {
      this.setState({
        showAddIcon: true,
      });
    }
  }

  dragNavStart(e: any) {
    const { scrollLeft, scrollTop } = this.state.navRef.current;

    this.setState({
      isScrolling: true,
      scrollLeft: scrollLeft,
      scrollTop: scrollTop,
      clientX: e.clientX,
      clientY: e.clientY,
    });
  }

  dragNavEnd(e: any) {
    this.setState({
      isScrolling: false,
      scrollLeft: 0,
      scrollTop: 0,
      clientX: 0,
      clientY: 0,
    });
  }

  onDrag(e: any) {
    if (this.state.isScrolling) {
      const { clientX, scrollLeft, scrollTop, clientY } = this.state;
      this.state.navRef.current.scrollLeft = scrollLeft + clientX - e.clientX;
    }
  }

  handlePageReorder(sourceIndex: number, destIndex: number | null) {
    if (destIndex !== null) {
      let _pages = [...this.state.pages];

      _pages[sourceIndex].sequence = destIndex + 1;
      const pageId = _pages[sourceIndex].id;
      const pagePayload = { ..._pages[sourceIndex] };

      _pages.splice(destIndex, 0, _pages.splice(sourceIndex, 1)[0]);

      for (let n in _pages) {
        _pages[n].sequence = parseInt(n) + 1;
      }

      this.setState(
        {
          pages: _pages,
          activePage: 0,
        },
        () => {
          this.updatePage(this.state.pages[destIndex].id, this.state.pages[destIndex]);
          this.updatePage(this.state.pages[sourceIndex].id, this.state.pages[sourceIndex]);
        },
      );
    }
  }

  createNewPage(newPage: Partial<IPage>) {
    typeHelpers.assertNotNullOrUndefined(this.props.owner, 'Expected owner');

    axios
      .post(
        swapRouteParams(routes.CREATE_EVENT_PAGE, { eventId: this.props.owner.ownerId }),
        newPage,
      )
      .then((response) => {
        this.setState({
          pages: [...this.state.pages, response.data],
        });
      });
  }

  updatePage(pageId: string, page: IPage) {
    typeHelpers.assertNotNullOrUndefined(this.props.owner, 'Expected owner');

    axios
      .put(
        swapRouteParams(routes.UPDATE_EVENT_PAGE, {
          eventId: this.props.owner.ownerId,
          pageId: pageId,
        }),
        page,
      )
      .then((response) => {
        let _pages = [...this.state.pages];

        for (let p in _pages) {
          if (_pages[p].id === response.data.id) {
            _pages[p] = response.data;
          }
        }

        this.setState({
          pages: _pages,
        });
      });
  }

  deletePage(pageId: string) {
    typeHelpers.assertNotNullOrUndefined(this.props.owner, 'Expected owner');

    axios
      .delete(
        swapRouteParams(routes.DELETE_EVENT_PAGE, {
          eventId: this.props.owner.ownerId,
          pageId: pageId,
        }),
      )
      .then((response) => {
        let _pages = [...this.state.pages];

        for (let p in _pages) {
          if (_pages[parseInt(p)].id === pageId) {
            _pages.splice(parseInt(p), 1);
            break;
          }
        }

        this.setState({
          pages: _pages,
        });
      });
  }

  hasManagePermission() {
    const permissions = mapPermissions(this.props.role?.permissions);
    const allowedPermissions = Object.keys(permissions).filter((k) => permissions[k]);
    return allowedPermissions.some((allowed) => EventManagePermissions.includes(allowed));
  }

  getManageLink() {
    return `/${this.props.owner?.ownerType}/${this.props?.owner?.ownerHandle}/admin`;
  }

  render() {
    return (
      <div
        className={
          this.props.loading
            ? 'Navigation loading'
            : hasPermission('EDIT_EVENT_PAGES', this.props.role) && !this.state.showAddIcon
              ? 'Navigation edit-event'
              : 'Navigation'
        }
      >
        <div
          className={
            this.state.navRef.current && this.state.navRef.current.scrollLeft > 0
              ? 'nav-inner scrolled'
              : 'nav-inner'
          }
        >
          {this.state.pagesLoading && (
            <ul className="nav-items">
              <li className="no-select">
                <span></span>
              </li>
            </ul>
          )}
          {!this.state.pagesLoading && (
            <ul
              ref={this.state.navRef}
              className="nav-items"
            >
              {this.state.pages.map((page: IPage, index: number) => {
                return (
                  <li
                    notranslate="yes"
                    onClick={(e: any) => {
                      this.setState({ activePage: index });
                      this.props.onTabClick(page, index);
                    }}
                    key={index}
                    className={index === this.state.activePage ? 'no-select active' : 'no-select'}
                  >
                    <span>{page.label}</span>
                  </li>
                );
              })}
              {this.state.showAddIcon && hasPermission('EDIT_EVENT_PAGES', this.props.role) && (
                <li
                  className="add-page"
                  onClick={() => {
                    this.setState({ showManageModal: true });
                  }}
                >
                  <i className="far fa-plus" />
                </li>
              )}
            </ul>
          )}
          {!this.state.pagesLoading && !this.state.showAddIcon && (
            <ul className="add-item">
              {hasPermission('EDIT_EVENT_PAGES', this.props.role) && (
                <li
                  className="add-page"
                  onClick={() => {
                    this.setState({ showManageModal: true });
                  }}
                >
                  <i className="far fa-plus" />
                </li>
              )}
            </ul>
          )}
          {this.hasManagePermission() && (
            <div className="admin-action">
              <Link
                to={this.getManageLink()}
                className="manage-parent"
              >
                <i className="far fa-cog" />
              </Link>
            </div>
          )}
        </div>
        <ManageTabsModal
          show={this.state.showManageModal}
          isLoading={false}
          pages={this.state.pages}
          onClose={() => {
            this.setState({ showManageModal: false });
          }}
          onReorderPage={this.handlePageReorder}
          onCreatePage={this.createNewPage}
          onUpdatePage={this.updatePage}
          onDeletePage={this.deletePage}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

export default connect(mapStateToProps)(Navigation);
