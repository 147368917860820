import React from 'react';
import momentTZ from 'moment-timezone';
import { Countries, Provinces } from 'country-and-province';

import './LocaleSelect.scss';
import Dropdown, { IOptions } from '../../../Dropdown/Dropdown';
import { ILocalization } from '@gigit/interfaces';
import { FormSectionHeader } from '../FormSectionHeader/FormSectionHeader';
import { localeConstants, uiConstants } from '../../../../constants';

interface IProps {
  localization: Partial<ILocalization>;
  updateLocalization(update: Partial<ILocalization>): void;
  usageType?: string;
}

export const LocaleSelect: React.FC<IProps> = (props: IProps) => {
  function updateLocalization(update: Partial<ILocalization>) {
    let localization = { ...props.localization, ...update };
    props.updateLocalization(localization);
  }

  return (
    <div className="LocaleSelect">
      <FormSectionHeader text="Localization Settings" />
      <div className="row">
        <Dropdown
          shouldSort={true}
          label="Country*"
          onChange={(e) => updateLocalization({ country: e.target.value })}
          value={props.localization?.country}
          name="country"
          options={localeConstants.supportedCountryOptions}
          placeholder="Select a Country"
        />
      </div>
      <div className="row">
        <Dropdown
          shouldSort={true}
          label={
            props.localization.country === 'CA'
              ? 'Province*'
              : props.localization.country === 'GB'
                ? 'County*'
                : 'State*'
          }
          onChange={(e) => updateLocalization({ state: e.target.value })}
          value={props.localization?.state}
          name="state"
          options={localeConstants.provinceOptions.filter(
            (province) => province.countryCode === props.localization?.country,
          )}
          placeholder={
            props.localization.country === 'CA'
              ? 'Select a Province'
              : props.localization.country === 'GB'
                ? 'Select a County'
                : 'Select a State'
          }
        />
      </div>
      {props.usageType !== uiConstants.ownerType.group && (
        <div className="row">
          <Dropdown
            label="Default Timezone"
            onChange={(e) => updateLocalization({ defaultTimezone: e.target.value })}
            value={props.localization?.defaultTimezone}
            name="defaultTimezone"
            options={localeConstants.timezoneOptions}
            placeholder="Select a Timezone"
            required={props.usageType === 'cause' ? true : false}
          />
        </div>
      )}
    </div>
  );
};
