import React, { useEffect, useState } from 'react';

type ScrollDirectionState = 'DOWN' | 'UP' | null;

/** Used to detect what direction was scrolled */
function useScrollEvent(
  /** Set this to offset the trigger of "UP" | "DOWN" by a certain amount of pixels*/
  scrollDetectionBuffer?: number,
) {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirectionState>(null);
  const [scrollY, setScrollY] = useState<number>(0);
  const scrollDetectionBufferAmount = scrollDetectionBuffer || 1;

  useEffect(() => {
    let lastScrollY = window.scrollY;
    // function to run on scroll
    const updateScrollState = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? 'DOWN' : 'UP';
      const scrollDetection =
        scrollY - lastScrollY > scrollDetectionBufferAmount ||
        scrollY - lastScrollY < -scrollDetectionBufferAmount;
      if (direction !== scrollDirection && scrollDetection) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
      setScrollY(scrollY);
    };
    window.addEventListener('scroll', updateScrollState); // add event listener

    return () => {
      window.removeEventListener('scroll', updateScrollState); // clean up
    };
  }, [scrollDirection]); // run when scroll direction changes

  return {
    scrollDirection,
    scrollY,
  };
}

export default useScrollEvent;
