import React, { useEffect, useState } from 'react';
import { IEventSummaryFE, IGroup, ISpotlight, ISpotlightList } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import useToastDispatcher from '../../../../hooks/useToaster';
import { hubRequestActions } from '../../../../requestActions';
import Modal from '../../../Modal/Modal';

import './HubManagementSpotlights.scss';
import HubManagementSpotlightItem from './HubManagementSpotlightItem';
import { capitalizeString } from '../../../../helpers';
import { Prompt } from '../../../Prompt/Prompt';
import SpotlightForm from './SpotlightForm/SpotlightForm';

type spotlightTypes = 'group' | 'event' | 'matching';
interface ISpotlightListConfig {
  /** Type of spotlight to display (group, event, matching) */
  type: spotlightTypes;
  hubId: string;
  /** The list of benefitting causes/company events depending on type */
  typeEntities: IGroup[] | IEventSummaryFE[];
}
interface IHubManagementSpotlights {
  spotlightListConfig: ISpotlightListConfig;
}
interface ISpotlightListItem extends ISpotlightList {
  selectedAction?: 'edit' | 'delete' | 'none';
}
const groupMessage = 'Flag Benefiting Causes as spotlight. Select up to 3 causes.';
const eventMessage = 'Flag Events as spotlight. Select up to 3 events.';
const matchingMessage = 'Flag a Matching Program as a spotlight.';
const messages = { group: groupMessage, event: eventMessage, matching: matchingMessage };
/** List of current Company/hub Spotlights (ISpotlightReturn)
 * - A 'Spotlight' is a way to highlight a cause (benefitting causes), event (hub owned), or company/hub matching program
 * - Members of the hub should see spotlight items on their feed.
 */
export default function HubManagementSpotlights(props: IHubManagementSpotlights) {
  /** Destructure Props */
  const { type, hubId } = props.spotlightListConfig;
  /** State */
  const [spotlights, setSpotlights] = useState<ISpotlightList[]>([]);
  const [spotlightsUnfiltered, setSpotlightsUnfiltered] = useState<ISpotlightList[]>([]);
  const [selectedSpotlightItem, setSelectedSpotlightItem] = useState<
    ISpotlightListItem | undefined
  >(undefined);
  const [spotlightsLoading, setSpotlightsLoading] = useState<boolean>(true);
  const [showAddSpotlight, setShowAddSpotlight] = useState<boolean>(false);
  const [typeEntities, setTypeEntities] = useState<IGroup[] | IEventSummaryFE[]>([]);
  /**  */
  const applySpotlightTypeLogic = (
    type: spotlightTypes,
    operation: 'message' | 'filter',
    spotlights?: ISpotlightList[],
  ): ISpotlightList[] | string | undefined => {
    const handleSpotlightType = () => {
      return operation === 'filter'
        ? spotlights?.filter((spotlight) => spotlight.object_type === type)
        : messages[type];
    };
    const spotlightTypeLogic = new Map([
      ['group', handleSpotlightType],
      ['event', handleSpotlightType],
      ['matching', handleSpotlightType],
    ]);
    const spotlightTypeLogicFunction = spotlightTypeLogic.get(type);
    if (spotlightTypeLogicFunction) {
      return spotlightTypeLogicFunction();
    }
  };
  /** Custom Hooks */
  const { dispatchToastError } = useToastDispatcher();
  /** On First Load */
  useEffect(() => {
    fetchSpotlights();
  }, []);
  /** On Type Entities Change */
  useEffect(() => {
    setTypeEntities(props.spotlightListConfig.typeEntities);
  }, [props.spotlightListConfig.typeEntities]);
  /** On Spotlight Item Action */
  useEffect(() => {
    if (!!selectedSpotlightItem) {
      switch (selectedSpotlightItem.selectedAction) {
        case 'edit':
          setShowAddSpotlight(true);
          break;
        case 'delete':
          setShowAddSpotlight(false);
          break;
        default:
          break;
      }
    }
  }, [selectedSpotlightItem]);
  /** Fetch Spotlights */
  const fetchSpotlights = async () => {
    try {
      let hubSpotlights = await hubRequestActions.getHubSpotlights(hubId);
      setSpotlightsUnfiltered(hubSpotlights);
      let filteredSpotlights = applySpotlightTypeLogic(type, 'filter', hubSpotlights);
      if (!!filteredSpotlights?.length) {
        hubSpotlights = filteredSpotlights as ISpotlightList[];
        setSpotlights(hubSpotlights);
      } else {
        setSpotlights([]);
      }
    } catch (error) {
      dispatchToastError(error, `Fetching Company ${capitalizeString(type)} Spotlights`);
    } finally {
      setSpotlightsLoading(false);
    }
  };
  /** Render Empty State */
  const renderEmptyState = () => {
    return (
      <div className="no-spotlights">
        <p>
          There are no spotlights to display. Would you like to{' '}
          <span onClick={() => setShowAddSpotlight(!showAddSpotlight)}> add a spotlight</span>?
        </p>
      </div>
    );
  };
  /** Delete Spotlight */
  const deleteSpotlight = async (item: ISpotlightListItem) => {
    try {
      const hubSpotlights = spotlightsUnfiltered.filter(
        (spotlight) => spotlight.object_id !== item.object_id,
      );
      await hubRequestActions.deleteHubSpotlight(hubId, hubSpotlights);
      await fetchSpotlights();
    } catch (error) {
      dispatchToastError(error, `Deleting Company ${capitalizeString(type)} Spotlight`);
    } finally {
      setSelectedSpotlightItem(undefined);
    }
  };
  /** Add Spotlight */
  const addSpotlight = async (spotlightItem: ISpotlight) => {
    if (!spotlightItem) return;
    const payload = {
      object_type: type,
      object_id: spotlightItem.object_id,
      spotlight_start: spotlightItem?.spotlight_start,
      spotlight_end: spotlightItem?.spotlight_end,
    };
    try {
      const existingSpotlightIndex = spotlightsUnfiltered.findIndex(
        (spotlight) => spotlight?.id === spotlightItem?.id,
      );
      let hubSpotlights = [...spotlightsUnfiltered];
      if (existingSpotlightIndex >= 0) {
        hubSpotlights.splice(existingSpotlightIndex, 1, payload);
      } else {
        hubSpotlights.push(payload);
      }
      await hubRequestActions.addSpotlight(hubSpotlights, hubId);
      await fetchSpotlights();
      setSelectedSpotlightItem(undefined);
      setShowAddSpotlight(!showAddSpotlight);
    } catch (error) {
      dispatchToastError(error, `Adding Company ${capitalizeString(type)} Spotlight`);
    }
  };
  /** Render the component */
  return (
    <div className="HubManagementSpotlights">
      <div className="add-spotlight">
        <div className="title">
          <p>Spotlight</p>
          <span> {applySpotlightTypeLogic(type, 'message')} </span>
        </div>
        <Button
          text="Add Spotlight"
          onClick={() => setShowAddSpotlight(!showAddSpotlight)}
        />
      </div>
      <div className="spotlight-causes">
        {spotlights.map((item) => {
          return (
            <HubManagementSpotlightItem
              key={item?.object_id as unknown as string}
              onDelete={(item) => setSelectedSpotlightItem({ ...item, selectedAction: 'delete' })}
              onEdit={(item) => setSelectedSpotlightItem({ ...item, selectedAction: 'edit' })}
              item={item}
            />
          );
        })}
      </div>
      {!spotlights.length && !spotlightsLoading && renderEmptyState()}
      {showAddSpotlight && (
        <Modal
          contentClassName="add-spotlight-modal"
          show={showAddSpotlight}
          onClose={() => setShowAddSpotlight(!showAddSpotlight)}
        >
          <SpotlightForm
            typeEntities={typeEntities}
            spotlight={selectedSpotlightItem}
            selectedEntityId={(selectedSpotlightItem?.object_id as unknown as string) ?? ''}
            setShowAddSpotlight={() => {
              setShowAddSpotlight(!showAddSpotlight);
            }}
            addSpotlight={(item) => addSpotlight(item)}
          />
        </Modal>
      )}

      {selectedSpotlightItem && (
        <Prompt
          show={selectedSpotlightItem?.selectedAction === 'delete'}
          title="Delete Spotlight"
          message={`Are you sure you want to delete the spotlight for '${selectedSpotlightItem.title || ''}'?`}
          yesMessage="Yes"
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage="Cancel"
          onYes={async () => deleteSpotlight(selectedSpotlightItem)}
          onClose={() => setSelectedSpotlightItem(undefined)}
        />
      )}
    </div>
  );
}
