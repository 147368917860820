import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleInputChange, formatAddressLine, toastError } from '../../helpers';
import {
  IForm,
  IAddress,
  IPage,
  IPageComponent,
  IStoreItemSummary,
  IStoreItem,
  IEventSummaryFE,
} from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { IAppState } from '../../store';
import {
  createStoreItem,
  IUpdateAuctionItemParams,
  StoreItemOwnerType,
  updateStoreItem,
  getDiscountCodes,
  IUpdateComponentParams,
} from '../../actions/store';
import { createToast } from '../../actions/toaster';
import { IEventState } from '../../reducers/event';
import { IGroupState } from '../../reducers/group';
import { IStoreState } from '../../reducers/store';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import TextField from '../TextField/TextField';
import ImageUpload from '../ImageUpload/ImageUpload';
import Dropdown, { IOptions } from '../Dropdown/Dropdown';
import QuillTextEditor from '../QuillTextEditor/QuillTextEditor';
import { getEventStats, getEvent } from '../../actions/event';
import { getGroupStats } from '../../actions/group';
import { IToast, StatisticType } from '../../interfaces';
import './StoreItemForm.scss';
import { localizeHelpers } from '../../localizeHelpers';
import { IOwnerObject } from '../../interfaces';
import typeHelpers from '../../helpers/typeHelpers';
import { uiConstants } from '../../constants';
import { hubRequestActions } from '../../requestActions';
import { IHubState } from '../../reducers/hub';
import Modal from '../Modal/Modal';
import FormManagement from '../FormManagement/FormManagement';

const freeShipping = 'Free';
const dollarAmount = {
  label: 'Dollar Amount',
  value: 'Amount',
};
const TBD = {
  label: 'To Be Determined',
  value: 'TBD',
};

interface IProps {
  createToast(toast: IToast): void;
  getEventStats(eventId: string, statisticType: StatisticType): void;
  getGroupStats(eventId: string, statisticType: StatisticType): void;
  getDiscountCodes(ownerType: StoreItemOwnerType, ownerId: string, _search?: string): void;
  createStoreItem(
    ownerType: StoreItemOwnerType,
    ownerId: string,
    _payload: IUpdateAuctionItemParams,
    medias?: string[],
    _updateComponent?: IUpdateComponentParams,
    onComponentUpdate?: () => void,
    options?: { callback?: () => void },
  ): void;
  updateStoreItem(
    ownerType: StoreItemOwnerType,
    ownerId: string,
    _storeItemId: string,
    _payload: IUpdateAuctionItemParams,
    medias?: string[],
    options?: { callback?: () => void },
  ): void;
  getEvent(id: string): void;
  onClose(): void;
  groupState: IGroupState;
  eventState: IEventState;
  hubState: IHubState;
  storeState: IStoreState;
  storeItem?: IStoreItemSummary;
  owner: IOwnerObject;
  component?: IPageComponent;
  page?: IPage;
  onComponentUpdate?: () => void;
}

interface IState {
  storeItemContentModalHideScroll: boolean;
  name: string;
  description: string;
  item_type: string;
  item_types: Array<IOptions>;
  price: string;
  discount: string;
  form?: IForm;
  showFormModal: boolean;
  discountCodes: Array<any>;
  selectedDiscounts: Array<any>;
  modules: any;
  formats: Array<any>;
  discountSearchValue: string;
  showDiscountError: boolean;
  receiptMessage: string;
  itemImages: string[];
  maxPerPurchase: number | null;
  inventory: number | null;
  shippingAvailable: boolean;
  shippingType: string;
  shippingPrice: string;
  pickupAvailable: boolean;
  taxReceipt: string;
  taxAmount: string;
  locations: any[];
  selectedLocations: any[];
  location: string;
  errors: { [index: string]: string };
  isValid: boolean;
  storeItemContentModalRef: React.RefObject<HTMLDivElement>;
}

class StoreItemForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const tmpStoreItemContentModalRef = createRef<HTMLDivElement>();

    let _media = [];
    const storeItem = this.props.storeItem;

    if (storeItem) {
      for (let m in storeItem.media) {
        if (storeItem.media[m].url !== '') {
          _media.push(storeItem.media[m].url);
        }
      }
    }

    this.state = {
      storeItemContentModalHideScroll: false,
      storeItemContentModalRef: tmpStoreItemContentModalRef,
      showFormModal: false,
      name: (storeItem && storeItem.name) || '',
      description: (storeItem && storeItem.description) || '',
      item_type: (storeItem && storeItem.item_type) || 'ticket',
      item_types: [
        ...[
          { value: 'ticket', label: 'Ticket' },
          { value: 'merchandise', label: 'Merchandise' },
        ],
        ...(this.props.owner.ownerType !== Constants.object_type.group &&
        this.props.owner.ownerType !== Constants.object_type.hub &&
        !this.props.eventState.event.hub_id
          ? [{ value: 'sponsorship', label: 'Sponsorship' }]
          : []),
      ],
      price: String(storeItem?.price || 0),
      discount: '',
      form: storeItem?.form,
      taxAmount: String(storeItem?.donation_part?.amount || 0),
      taxReceipt: storeItem?.donation_part?.amount_type || 'fixed',
      discountCodes: this.props.storeState.discountCodes.map((c) => c.code),
      selectedDiscounts: (storeItem && storeItem.discount_codes) || [],
      modules: {
        toolbar: [
          ['bold', 'italic'],
          [{ indent: '-1' }, { indent: '+1' }],
          ['link', 'clean'],
        ],
        clipboard: {
          matchVisual: false,
        },
      },
      formats: [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
      ],
      discountSearchValue: '',
      showDiscountError: false,
      receiptMessage: (storeItem && storeItem.receipt_message) || '',
      itemImages: _media,
      maxPerPurchase: storeItem?.inventory?.max_purchase_quantity || null,
      inventory: storeItem?.inventory?.available_quantity || null,
      shippingAvailable: !!storeItem?.is_shipping_available,
      shippingType: storeItem?.shipping_price_type ? storeItem.shipping_price_type : freeShipping,
      shippingPrice: storeItem?.shipping_price ? storeItem.shipping_price.toString() : '',
      pickupAvailable: (storeItem?.pickup_locations?.length ?? 0) > 0,
      locations: [],
      selectedLocations: storeItem && storeItem.pickup_locations ? storeItem.pickup_locations : [],
      location: '',
      errors: {},
      isValid: false,
    };

    this.addDiscount = this.addDiscount.bind(this);
    this.removeDiscountItem = this.removeDiscountItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.preventSubmit = this.preventSubmit.bind(this);
    this.checkDiscountExists = this.checkDiscountExists.bind(this);
    this.onMediaItemSelect = this.onMediaItemSelect.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.setLocationDropdown = this.setLocationDropdown.bind(this);
  }

  /** Depending on the owner, return the list of available locations. */
  getLocations() {
    switch (this.props.owner.ownerType) {
      case uiConstants.ownerType.group:
        return this.props.groupState.groupLocations;
      case uiConstants.ownerType.event:
        return this.props.eventState.locations;
      case uiConstants.ownerType.hub:
        let locations: IAddress[] = [];
        if (this.props.hubState.current?.groups) {
          this.props.hubState.current.groups.forEach((g) => {
            if (g?.locations) {
              locations = [...locations, ...g.locations];
            }
          });
        }

        return locations;
      default:
        typeHelpers.assertNotReachable();
    }
  }

  componentDidMount() {
    if (this.props?.page) this.props.getEvent(this.props.owner.ownerId);

    this.props.getDiscountCodes(
      this.props.owner.ownerType as StoreItemOwnerType,
      this.props.owner.ownerId,
    );

    this.setLocationDropdown();

    this.validateInputs();
    const group = this.props.owner && typeHelpers.getGroupFromOwner(this.props.owner);
  }

  setLocationDropdown() {
    let _locations: any[] = [];

    _locations.push({
      value: '',
      label: 'Select Location',
    });

    for (let l in this.getLocations()) {
      let _location = this.getLocations()[l];

      _locations.push({
        value: _location.id,
        label: _location.title + ' (' + formatAddressLine(_location) + ')',
      });
    }

    this.setState({
      locations: _locations,
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      (prevProps.storeState.isCreatingStoreItem && !this.props.storeState.isCreatingStoreItem) ||
      this.props.storeState.storeItems !== prevProps.storeState.storeItems
    ) {
      this.props.onClose();
    }

    if (
      this.state.price !== prevState.price ||
      this.state.taxReceipt !== prevState.taxReceipt ||
      this.state.taxAmount !== prevState.taxAmount
    ) {
      this.validateInputs();
    }

    if (prevProps.storeState.discountCodes !== this.props.storeState.discountCodes) {
      this.setState({
        discountCodes: this.props.storeState.discountCodes.map((c) => c.code),
      });
    }
  }

  /** Validates all inputs and checks for errors. */
  validateInputs() {
    const newErrors: { [index: string]: string } = {};

    const donationPart = parseFloat(this.state.taxAmount);
    const price = parseFloat(this.state.price);

    if (donationPart < 0) {
      newErrors['taxAmount'] = 'Donation amount cannot be a negative number';
    } else if (this.state.taxReceipt === 'fixed') {
      if (donationPart > price) {
        newErrors['taxAmount'] = 'Fixed donation part must not be greater than item price';
      }
    } else if (this.state.taxReceipt === 'percentage') {
      if (donationPart > 100) {
        newErrors['taxAmount'] = 'Percentage donation part must not be greater than 100%';
      }
    }

    for (const selectedDiscount of this.state.selectedDiscounts) {
      const discountCode = this.props.storeState.discountCodes.find(
        (discountCode) => discountCode.code === selectedDiscount,
      );
      if (discountCode) {
        // Show error to user if any discount code exceeds item price.
        if (discountCode.discount_type === 'fixed' && discountCode.amount > price) {
          newErrors['selectedDiscounts'] =
            `Discount Code '${selectedDiscount}' amount of $${discountCode.amount} exceeds price of item`;
          break;
        }

        // Show error to user if any discount code allows discounted price to fall below donation price.
        const priceAfterDiscount =
          discountCode.discount_type === 'fixed'
            ? price - discountCode.amount
            : price - price * (discountCode.amount / 100);
        const donationAmount =
          this.state.taxReceipt === 'fixed' ? donationPart : price * (donationPart / 100);

        if (donationAmount > priceAfterDiscount) {
          newErrors['selectedDiscounts'] =
            `Discount Code '${selectedDiscount}' discounted price $${priceAfterDiscount} is less than donation amount $${donationAmount}`;
        }
      }
    }

    this.setState({
      errors: newErrors,
      isValid: Object.keys(newErrors).length === 0,
    });
  }

  createStoreItem() {
    if (this.state.pickupAvailable) {
      if (this.state.selectedLocations.length <= 0) {
        const toast = toastError(
          localizeHelpers.translate("Please select a pickup location and click 'Add Location'."),
          'Auction Item',
        );
        this.props.createToast(toast);

        return false;
      }
    }

    const payload: IUpdateAuctionItemParams = {
      name: this.state.name,
      description: this.state.description,
      price: parseFloat(this.state.price),
      item_type: this.state.item_type,
      discount_codes: this.state.selectedDiscounts,
      receipt_message: this.state.receiptMessage,
      inventory: {},
      is_shipping_available: this.state.shippingAvailable,
      form: this.state.form,
    };

    if (this.state.shippingAvailable) {
      payload.shipping_price_type = this.state.shippingType;
      if (payload.shipping_price_type !== freeShipping) {
        payload.shipping_price = parseFloat(this.state.shippingPrice);
      } else {
        payload.shipping_price = 0;
      }
    }

    if (this.state.pickupAvailable) {
      payload.pickup_locations = this.state.selectedLocations;
    } else {
      payload.pickup_locations = [];
    }

    if (payload.form_id === '') {
      payload['form_id'] = undefined;
    }

    const donationAmount = parseFloat(this.state.taxAmount);
    if (donationAmount > 0) {
      payload.donation_part = {
        amount: parseFloat(this.state.taxAmount),
        amount_type: this.state.taxReceipt,
      };
    } else if (donationAmount === 0 || isNaN(donationAmount)) {
      payload.donation_part = null;
    }

    if (this.state.maxPerPurchase) {
      payload.inventory!.max_purchase_quantity = this.state.maxPerPurchase;
    }

    if (this.state.inventory || this.state.inventory === 0) {
      payload.inventory!.available_quantity = this.state.inventory;
    }

    if (!this.state.inventory && this.state.inventory !== 0 && !this.state.maxPerPurchase) {
      delete payload['inventory'];
    }

    if (this.props.storeItem) {
      typeHelpers.assertNotNullOrUndefined(this.props.storeItem.id, 'Expected store item id');

      if (this.props.owner.ownerType === Constants.object_type.hub) {
        hubRequestActions
          .updateStoreItem(
            this.props.owner.ownerId,
            this.props.storeItem.id,
            payload as IStoreItem,
            this.state.itemImages,
          )
          .then(() => this.props.onClose())
          .catch((error) => {
            const toast = toastError(localizeHelpers.translate(error), 'Update Store Item');
            this.props.createToast(toast);
          });
      } else {
        this.props.updateStoreItem(
          this.props.owner.ownerType as StoreItemOwnerType,
          this.props.owner.ownerId,
          this.props.storeItem.id,
          payload,
          this.state.itemImages,
          {
            callback: () => {
              this.props.owner.ownerType === 'group'
                ? this.props.getGroupStats(this.props.owner.ownerId, StatisticType.STORE)
                : this.props.getEventStats(this.props.owner.ownerId, StatisticType.STORE);
            },
          },
        );
      }
    } else {
      if (this.props.owner.ownerType === Constants.object_type.hub) {
        hubRequestActions
          .createStoreItem(this.props.owner.ownerId, payload, this.state.itemImages)
          .then(() => {
            this.props.onClose();
          })
          .catch((error) => {
            const toast = toastError(localizeHelpers.translate(error), 'Create Store Item');
            this.props.createToast(toast);
          });
      } else {
        if (this.props.component) {
          typeHelpers.assertNotNullOrUndefined(this.props.page, 'Expected a page');

          this.props.createStoreItem(
            this.props.owner.ownerType as StoreItemOwnerType,
            this.props.owner.ownerId,
            payload,
            this.state.itemImages,
            {
              component_id: this.props.component.id,
              page_id: this.props.page.id,
              object_ids: this.props.component.content_references?.object_ids ?? [],
            },
            this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
            {
              callback: () => {
                this.props.owner.ownerType === 'group'
                  ? this.props.getGroupStats(this.props.owner.ownerId, StatisticType.STORE)
                  : this.props.getEventStats(this.props.owner.ownerId, StatisticType.STORE);
              },
            },
          );
        } else {
          this.props.createStoreItem(
            this.props.owner.ownerType as StoreItemOwnerType,
            this.props.owner.ownerId,
            payload,
            this.state.itemImages,
            undefined,
            undefined,
            {
              callback: () => {
                this.props.owner.ownerType === 'group'
                  ? this.props.getGroupStats(this.props.owner.ownerId, StatisticType.STORE)
                  : this.props.getEventStats(this.props.owner.ownerId, StatisticType.STORE);
              },
            },
          );
        }
      }
    }
  }

  addDiscount(suggestion: any) {
    let temp = this.state.selectedDiscounts;
    temp.push(suggestion);

    this.setState(
      {
        selectedDiscounts: temp,
        discountSearchValue: '',
      },
      () => this.validateInputs(),
    );
  }

  removeDiscountItem(i: number) {
    let temp = [...this.state.selectedDiscounts];
    temp.splice(i, 1);

    this.setState(
      {
        selectedDiscounts: temp,
      },
      () => this.validateInputs(),
    );
  }

  handleChange(content: any, delta: any, source: any, editor: any) {
    this.setState({
      description: content,
    });
  }

  handleChangeMessage(content: any, delta: any, source: any, editor: any) {
    this.setState({
      receiptMessage: content,
    });
  }

  preventSubmit(e: any) {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  }

  checkDiscountExists() {
    this.setState({
      showDiscountError: false,
    });

    if (!this.state.discountCodes.includes(this.state.discountSearchValue)) {
      this.setState({
        showDiscountError: true,
      });
    }

    if (this.state.selectedDiscounts.includes(this.state.discountSearchValue)) {
      this.setState({
        discountSearchValue: '',
      });

      return false;
    }

    return this.state.discountCodes.includes(this.state.discountSearchValue);
  }

  onMediaItemSelect(file: any) {
    let _images = [...this.state.itemImages];
    _images.push(file.src);

    this.setState({
      itemImages: _images,
    });
  }

  removeImage(index: number) {
    let _images = [...this.state.itemImages];
    _images.splice(index, 1);

    this.setState({
      itemImages: _images,
    });
  }

  addLocation() {
    if (this.state.location !== '') {
      let _selected = [...this.state.selectedLocations];

      if (_selected.find((i) => i.id === this.state.location)) {
        //if location already added
        return;
      }

      for (let l in this.getLocations()) {
        if (this.getLocations()[l].id === this.state.location) {
          _selected.push(this.getLocations()[l]);
          break;
        }
      }

      this.setState({
        selectedLocations: _selected,
      });
    }
  }

  removeLocation(index: number) {
    let _selected = [...this.state.selectedLocations];

    _selected.splice(index, 1);

    this.setState({
      selectedLocations: _selected,
    });
  }

  handleFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    const ownerAccount = { ...(this.props.owner.object as IEventSummaryFE).hub?.account };
    const account =
      this.props.owner?.parentOwnerType === 'hub' ? ownerAccount : this.props.owner.account;
    if (this.state.price !== '0' && !account) {
      let toast: IToast;

      if (this.props.owner.ownerType === Constants.object_type.group) {
        toast = toastError(
          localizeHelpers.translate("Please connect a bank account to set the item's price"),
          'Connect account',
          [
            {
              link: {
                link: `/${this.props.owner.ownerType}/${this.props.owner.ownerHandle}/admin?t=balance_and_payouts`,
                text: 'Balance & Payouts',
              },
            },
          ],
        );
      } else {
        toast = toastError(
          localizeHelpers.translate(
            "Please connect your cause with bank account to set the item's price",
          ),
          'Connect account',
        );
      }

      this.props.createToast(toast);

      this.props.onClose();
    } else {
      this.createStoreItem();
    }
  }

  render() {
    const locationsLink = `/${this.props.owner.ownerType === 'hub' ? 'company' : this.props.owner.ownerType}/${this.props.owner.ownerHandle}/admin?t=locations`;

    return (
      <>
        <div className="StoreItemForm">
          <form
            onSubmit={(e) => {
              this.handleFormSubmit(e);
            }}
            className="store-item-inner"
          >
            <div className="store-item">
              <div className="sub-title">Images</div>
              <div className="row image-upload">
                {this.state.itemImages && this.state.itemImages.length === 0 && (
                  <p>You haven't added any images, yet.</p>
                )}
                <div className="images">
                  {this.state.itemImages &&
                    this.state.itemImages.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="image-thumb"
                        >
                          <img
                            alt={'Image ' + index}
                            src={item}
                          />
                          <i
                            onClick={() => {
                              this.removeImage(index);
                            }}
                            className="fa fa-times"
                          />
                        </div>
                      );
                    })}
                </div>
                <ImageUpload onImgSelect={this.onMediaItemSelect} />
              </div>
              <div className="sub-title">Details</div>
              <div className="row">
                <TextField
                  type="text"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                  name="name"
                  value={this.state.name}
                  required={true}
                  label="Item Name"
                />
              </div>
              <div className="row type">
                <Dropdown
                  label="Type"
                  shouldSort={true}
                  value={this.state.item_type}
                  name="item_type"
                  options={this.state.item_types}
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
              </div>
              <div className="row form-container">
                <div className="texts">
                  <span className="title">Form</span>
                  <span className="desc">Item order form</span>
                </div>
                <Button
                  buttonType="outline-dark"
                  type="button"
                  text="Edit"
                  icon="far fa-pencil"
                  onClick={() => this.setState({ showFormModal: true })}
                />
              </div>
              <div className="row price">
                <TextField
                  icon="far fa-dollar-sign"
                  type="number"
                  min="0"
                  step="any"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                  name="price"
                  value={`${this.state.price}`}
                  required={true}
                  label="Price"
                />
              </div>
              <div className="row dbl inventory">
                <TextField
                  icon="far fa-hashtag"
                  type="number"
                  min="0"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                  name="inventory"
                  value={`${this.state.inventory}`}
                  label="Inventory Quantity"
                  helper="How many times can this item be purchased? If no quantity is specified then there is no limit on purchases."
                />
                <TextField
                  icon="far fa-hashtag"
                  type="number"
                  min="1"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                  name="maxPerPurchase"
                  value={`${this.state.maxPerPurchase}`}
                  label="Per Purchase Limit"
                  helper="How many times can this item be purchased by a given user? If no quantity is specified there is no limit on purchases."
                />
              </div>
              <div className="row description">
                <label>Description</label>
                <QuillTextEditor
                  value={this.state.description}
                  preserveWhitespace={true}
                  theme="snow"
                  modules={this.state.modules}
                  formats={this.state.formats}
                  onChange={this.handleChange}
                />
              </div>
              <div className="row taxes">
                <label>Donation Amount or Percent</label>
                <div className="tax-eligible">
                  <Checkbox
                    name="taxReceiptDollar"
                    onChange={(e) => {
                      handleInputChange(e, this);
                      this.setState({ taxReceipt: 'fixed' });
                    }}
                    checked={this.state.taxReceipt === 'fixed'}
                    value={'fixed'}
                    label="Dollar"
                    disabled={
                      this.props.eventState?.event.event_type ===
                      Constants.event_type.obo_fundraiser
                    }
                  />
                  <Checkbox
                    name="taxReceiptPercent"
                    onChange={(e) => {
                      handleInputChange(e, this);
                      this.setState({ taxReceipt: 'percentage' });
                    }}
                    checked={this.state.taxReceipt === 'percentage'}
                    value={'percent'}
                    label="Percent"
                    disabled={
                      this.props.eventState?.event.event_type ===
                      Constants.event_type.obo_fundraiser
                    }
                  />
                  <TextField
                    error={this.state.errors['taxAmount']}
                    type="number"
                    min="0"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                    name="taxAmount"
                    value={this.state.taxAmount}
                    placeholder={this.state.taxReceipt === 'percentage' ? '% Amount' : '$ Amount'}
                    disabled={
                      this.props.eventState?.event.event_type ===
                      Constants.event_type.obo_fundraiser
                    }
                  />
                </div>
                {this.props.eventState?.event.event_type ===
                  Constants.event_type.obo_fundraiser && (
                  <div className="warning-msg">
                    Partial receipts are disabled for 3rd part events. Please contact the cause you
                    are supporting directly if you believe this is required for your event.
                  </div>
                )}
                <div className="warning-msg">
                  Kambeo will automatically issue a tax-deductible donation receipt upon purchase
                  where applicable
                </div>
              </div>
              <div className="row discounts">
                <div className="search-discounts">
                  <TextField
                    icon="far fa-search"
                    label="Apply a Discount Code"
                    list="discount-codes"
                    placeholder="Search Discount Codes..."
                    onChange={(e) => {
                      this.preventSubmit(e);
                      handleInputChange(e, this);
                    }}
                    value={this.state.discountSearchValue}
                    name="discountSearchValue"
                    type="text"
                  />
                  <Button
                    isDisabled={this.state.discountSearchValue.length === 0}
                    onClick={(e) => {
                      e.preventDefault();
                      if (this.checkDiscountExists()) {
                        this.addDiscount(this.state.discountSearchValue);
                      }
                    }}
                    icon="far fa-plus"
                    text="Apply"
                  />
                  <datalist id="discount-codes">
                    {this.state.discountCodes.map((item, index) => {
                      return (
                        <option
                          key={index}
                          id={item}
                          value={item}
                          notranslate="yes"
                        />
                      );
                    })}
                  </datalist>
                </div>
                {this.state.showDiscountError && (
                  <div className="discount-error">
                    <span>This discount doesn't exist on your {this.props.owner.ownerType}. </span>
                    <Link
                      to={`/${this.props.owner.ownerType}/${this.props.owner.ownerHandle}/admin?t=discounts`}
                    >
                      Add a new discount code.
                    </Link>
                  </div>
                )}
                <ul className="applied-discounts">
                  {this.state.selectedDiscounts &&
                    this.state.selectedDiscounts.length > 0 &&
                    this.state.selectedDiscounts.map((item: any, index: number) => {
                      return (
                        <li
                          key={index}
                          className="discount"
                        >
                          <span>{item}</span>
                          <i
                            onClick={() => {
                              this.removeDiscountItem(index);
                            }}
                            className="far fa-times"
                            notranslate="yes"
                          />
                        </li>
                      );
                    })}
                </ul>

                {this.state.errors['selectedDiscounts'] && (
                  <p className="error">{this.state.errors['selectedDiscounts']}</p>
                )}
              </div>
              <div className="row description">
                <label>Purchase Receipt Message</label>
                <QuillTextEditor
                  value={this.state.receiptMessage}
                  preserveWhitespace={true}
                  theme="snow"
                  modules={this.state.modules}
                  formats={this.state.formats}
                  onChange={this.handleChangeMessage}
                />
              </div>
              <div className="sub-title">Shipping</div>
              <div className="row toggle">
                <p>Is shipping available for this item?</p>
                <i
                  onClick={() => {
                    this.setState({ shippingAvailable: !this.state.shippingAvailable });
                  }}
                  className={
                    this.state.shippingAvailable ? 'fad fa-toggle-on' : 'fad fa-toggle-off'
                  }
                />
              </div>
              {this.state.shippingAvailable && (
                <div className="shippingAvailable no-select">
                  <div className="row">
                    <Dropdown
                      label="Shipping Price"
                      name="shippingType"
                      value={this.state.shippingType}
                      options={[
                        { label: freeShipping, value: freeShipping },
                        { label: dollarAmount.label, value: dollarAmount.value },
                        { label: TBD.label, value: TBD.value },
                      ]}
                      onChange={(e) => {
                        handleInputChange(e, this, false);
                      }}
                    />
                  </div>
                  {this.state.shippingType === 'Amount' && (
                    <div className="row">
                      <TextField
                        required={true}
                        label="Shipping Amount ($)"
                        value={this.state.shippingPrice}
                        name="shippingPrice"
                        type="number"
                        min="0"
                        step="any"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="pickupAvailable">
                <div className="sub-title">Pick-Up</div>
                <div className="row toggle">
                  <p>Is this item available for pick-up?</p>
                  <i
                    onClick={() => {
                      this.setState({ pickupAvailable: !this.state.pickupAvailable }, () => {
                        if (!this.state.pickupAvailable) {
                          this.setState({ selectedLocations: [] });
                        }
                      });
                    }}
                    className={
                      this.state.pickupAvailable ? 'fad fa-toggle-on' : 'fad fa-toggle-off'
                    }
                  />
                </div>
                {this.state.pickupAvailable && (
                  <div className="row add-location">
                    <Link to={locationsLink}>Manage Locations</Link>
                    <Dropdown
                      label="Pick-up Locations"
                      name="location"
                      value={this.state.location}
                      options={this.state.locations}
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                      notranslate="yes"
                    />
                    {this.state.selectedLocations.length === 0 && (
                      <div className="empty">"You haven't selected any pick-up locations</div>
                    )}
                    {this.state.selectedLocations.length !== 0 && (
                      <ul className="selected-locations">
                        {this.state.selectedLocations.map((item, index) => {
                          return (
                            <li
                              key={index}
                              notranslate="yes"
                            >
                              {item.title}
                              <span>({formatAddressLine(item)})</span>
                              <i
                                onClick={() => {
                                  this.removeLocation(index);
                                }}
                                className="fa fa-times"
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        this.addLocation();
                      }}
                      icon="fal fa-plus"
                      text="Add Location"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="store-item-actions">
              <Button
                isDisabled={!this.state.isValid}
                loading={this.props.storeState.isCreatingStoreItem}
                text={this.props.storeItem ? 'Save' : 'Create'}
                type="submit"
              />
            </div>
          </form>
        </div>
        <Modal
          title="Form"
          contentClassName="FormModal-content"
          class="Store-FormModal"
          show={this.state.showFormModal}
          contentRef={this.state.storeItemContentModalRef}
          closeIcon="fas fa-times"
          contentStyle={{
            overflowY: this.state.storeItemContentModalHideScroll ? 'hidden' : 'auto',
          }}
          onClose={() => this.setState({ showFormModal: false })}
          dontToggleBodyOverflow={true}
        >
          <FormManagement
            ownerId={this.props?.storeItem?.id}
            ownerType={Constants.object_type.store_item}
            storeItemData={{
              onSave: (form: IForm) => {
                this.setState({ form, showFormModal: false });
              },
              initForm: this.state.form,
              storeItemContentModalRef: this.state.storeItemContentModalRef,
              storeItemContentModalHideScroll: (storeItemContentModalHideScroll) => {
                if (storeItemContentModalHideScroll) {
                  this.state.storeItemContentModalRef?.current?.scrollTo(0, 0);
                }
                this.setState({ storeItemContentModalHideScroll });
              },
            }}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    hubState: store.hubState,
    storeState: store.storeState,
  };
};

const mapDispatchToProps = {
  createToast,
  createStoreItem,
  updateStoreItem,
  getDiscountCodes,
  getEventStats,
  getEvent,
  getGroupStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreItemForm);
