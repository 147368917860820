import React from 'react';
import { connect } from 'react-redux';
import { Config } from '@gigit/config';
import {
  ICampaign,
  IGroup,
  IEventSummaryFE,
  IEventIndividual,
  IEventTeam,
  IUserRoleJoin,
  IPaymentMethod,
} from '@gigit/interfaces';
import queryString from 'query-string';
import { RouteComponentProps, Link, Redirect } from 'react-router-dom';
import { IAppState } from '../../store';
import {
  routes,
  defaultCurrency,
  swapRouteParams,
  toastError,
  IStringMap,
  mapPermissions,
  setSEOMetatags,
} from '../../helpers';
import axios from 'axios';
import { CheckoutLocale, loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { IGroupState } from '../../reducers/group';
import { IUserState } from '../../reducers/user';
import { IEventState } from '../../reducers/event';
import { createToast } from '../../actions/toaster';
import Share from '../../components/Share/Share';
import Modal from '../../components/Modal/Modal';
import Portrait from '../../components/Portrait/Portrait';
import Loader from '../../components/Loader/Loader';
import QuillTextEditor from '../../components/QuillTextEditor/QuillTextEditor';
import DonateForm from './DonateForm/DonateForm';
import './Donate.scss';
import { formatCurrency } from '../../helpers';
import { Constants } from '@gigit/constants';
import { userSelectors } from '../../selectors/user';
import errorHelpers from '../../helpers/errorHelpers';
import { IOwnerObject, IToast } from '../../interfaces';
import { uiConstants } from '../../constants';
import typeHelpers from '../../helpers/typeHelpers';
import {
  IFloatingAction,
  ProfileFloatingActions,
} from '../../components/ProfilePageComponents/ProfileFloatingActions/ProfileFloatingActions';
import { eventRequestActions, groupRequestActions } from '../../requestActions';
import DonationSettingsModal from '../../components/DonationSettingsModal/DonationSettingsModal';
import { localizeHelpers } from '../../localizeHelpers';
import GlobalGivingDonateForm from './GlobalGivingDonateForm/GlobalGivingDonateForm';

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  groupState: IGroupState;
  eventState: IEventState;
  locale: string;
  isLoggedIn: boolean;
  createToast(toast: IToast): void;
}

interface IState {
  loading: boolean;
  owner: IOwnerObject | null;
  hubOwnerGroup: IGroup | null;
  stripePromise: Promise<Stripe | null> | null;
  paymentMethods: any[];
  campaign: ICampaign | null;
  isP2P: boolean;
  showShareModal: boolean;
  isEmbed: boolean;
  userPermissions: IStringMap;
  showDonationSettingsModal: boolean;
  isGlobalGiving: boolean;
  canDonate: boolean;
  cantDonateText: string;
  eventAllowsDonationsRedirect: string;
}

class Donate extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let params = queryString.parse(this.props.location.search);

    this.state = {
      cantDonateText: '',
      loading: true,
      owner: null,
      hubOwnerGroup: null,
      stripePromise: null,
      paymentMethods: [],
      campaign: null,
      isP2P: false,
      showShareModal: false,
      isEmbed: !!params.embed,
      userPermissions: {},
      showDonationSettingsModal: false,
      isGlobalGiving: false,
      canDonate: true,
      eventAllowsDonationsRedirect: '',
    };

    this.getParentCoverImage = this.getParentCoverImage.bind(this);
    this.getParentProfileImage = this.getParentProfileImage.bind(this);
    this.getParentLink = this.getParentLink.bind(this);
    this.getProgressWidth = this.getProgressWidth.bind(this);
    this.getCurrency = this.getCurrency.bind(this);
    this.fetchOwnerData = this.fetchOwnerData.bind(this);
  }

  componentDidMount() {
    this.fetchOwnerData();
    setSEOMetatags({
      title: localizeHelpers.translate('Donate | Kambeo'),
      urlPath: `${this.props.match.params.type}/${this.props.match.params.handle}/donate`,
    });
  }

  getUserPermissions(groupOrEventId: string) {
    const getUserRoleAction =
      this.props.match.params.type === Constants.object_type.group
        ? groupRequestActions.getUserRole
        : eventRequestActions.getUserRole;

    if (this.props.isLoggedIn) {
      getUserRoleAction(groupOrEventId)
        .then((userRoleObject: IUserRoleJoin) => {
          const permissions: IStringMap = mapPermissions(userRoleObject.permissions);
          this.setState({ userPermissions: permissions });
        })
        .catch((err) => {
          const errorObj = errorHelpers.getErrorObject(err);
          const toast = toastError(errorObj.translatedMessage, 'Donation settings');
          this.props.createToast(toast);
        });
    }
  }

  fetchOwnerData() {
    switch (this.props.match.params.type) {
      case 'group':
        {
          axios
            .get<IGroup>(
              swapRouteParams(routes.GET_GROUP, { handleOrId: this.props.match.params.handle }),
            )
            .then((groupResponse) => {
              const group = groupResponse.data;
              if (group.external_id?.VolunteerMatch && group.unclaimed) {
                this.setState({
                  canDonate: false,
                  cantDonateText: localizeHelpers.translate(
                    'VolunteerMatch causes cannot accept donations',
                  ),
                });

                return;
              }

              if (
                group.external_id?.GlobalGiving &&
                !group.gg_active_projects_count &&
                group.unclaimed
              ) {
                this.setState({
                  canDonate: false,
                  cantDonateText: localizeHelpers.translate(
                    'No active projects found for this cause.',
                  ),
                });
              }
              this.getUserPermissions(group.id);

              const isGlobalGiving = group.external_id?.GlobalGiving && group.unclaimed;

              this.setState({ isGlobalGiving: !!isGlobalGiving });
              this.fetchHubOwnerGroup(group);

              if (!isGlobalGiving) {
                this.setState({
                  stripePromise: loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY, {
                    stripeAccount: group.account?.account_number,
                    locale: this.props.locale as CheckoutLocale,
                  }),
                });

                if (group.campaign_id) {
                  axios
                    .get(
                      swapRouteParams(routes.GET_GROUP_CAMPAIGN, {
                        groupId: group.id,
                        id: group.campaign_id,
                      }),
                    )
                    .then((response) => {
                      this.setState({
                        campaign: response.data,
                      });
                    })
                    .catch((error) => {
                      const errorObj = errorHelpers.getErrorObject(error);
                      let toast = toastError(errorObj.translatedMessage, 'Get campaign');
                      createToast(toast);
                    });
                }
              }

              this.setState({ owner: typeHelpers.createOwnerObject('group', group) });
            })
            .catch((error) => {
              this.props.history.push('/not-found');
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        }
        break;
      case 'event':
        {
          axios
            .get<IEventSummaryFE>(
              swapRouteParams(routes.GET_EVENT, { handleOrId: this.props.match.params.handle }),
            )
            .then((eventResponse) => {
              const event = eventResponse.data;
              this.getUserPermissions(event.id);

              if (!event.accepting_donations) {
                let toast = toastError(
                  localizeHelpers.translate(`Event is not currently accepting donations`),
                  'Event Donate',
                );
                this.props.createToast(toast);
                this.setState({
                  eventAllowsDonationsRedirect: `/event/${event.handle}`,
                });
              }

              this.setState({
                stripePromise: loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY, {
                  stripeAccount: event?.hub_id
                    ? event?.hub?.account?.account_number
                    : event.group!.account?.account_number,
                  locale: this.props.locale as CheckoutLocale,
                }),
              });

              if (event?.group && event?.group?.hubs && event.group?.hubs?.length > 0)
                this.fetchHubOwnerGroup(event.group as IGroup);
              if (event.campaign_id) {
                axios
                  .get(
                    swapRouteParams(routes.GET_GROUP_CAMPAIGN, {
                      groupId: event.group_id,
                      id: event.campaign_id,
                    }),
                  )
                  .then((response) => {
                    this.setState({
                      campaign: response.data,
                    });
                  })
                  .catch((error) => {
                    const errorObj = errorHelpers.getErrorObject(error);
                    let toast = toastError(errorObj.translatedMessage, 'Get campaign');
                    createToast(toast);
                  });
              }

              if (!this.props.match.params.subType) {
                axios
                  .get(swapRouteParams(routes.GET_EVENT_COMPONENT_COUNT, { eventId: event.id }))
                  .then((components) => {
                    for (let c in components.data) {
                      if (components.data[c].component_type === 'fundraising') {
                        this.setState({
                          isP2P: true,
                        });

                        break;
                      }
                    }
                  });
              }

              if (this.props.match.params.subType) {
                if (this.props.match.params.subType === 'individual') {
                  axios
                    .get<IEventIndividual>(
                      swapRouteParams(routes.GET_EVENT_INDIVIDUAL, {
                        eventId: event.id,
                        userHandleOrId: this.props.match.params.subHandle,
                      }),
                    )
                    .then((individualResponse) => {
                      this.setState({
                        owner: typeHelpers.createOwnerObject('event', event, {
                          child: { ownerType: 'individual', ownerObj: individualResponse.data },
                        }),
                      });
                    })
                    .catch(() => {
                      this.props.history.push('/not-found');
                    });
                } else if (this.props.match.params.subType === 'team') {
                  axios
                    .get<IEventTeam>(
                      swapRouteParams(routes.GET_EVENT_TEAM, {
                        eventId: event.id,
                        teamHandleOrId: this.props.match.params.subHandle,
                      }),
                    )
                    .then((teamResponse) => {
                      axios
                        .get<
                          IEventIndividual[]
                        >(swapRouteParams(routes.GET_TEAM_MEMBERS, { eventId: event.id, teamId: teamResponse.data.id }))
                        .then((teamMembersResponse) => {
                          this.setState({
                            owner: typeHelpers.createOwnerObject('event', event, {
                              child: { ownerType: 'team', ownerObj: teamResponse.data },
                              isContentCreator: teamMembersResponse.data.some(
                                (member) => member.content_creator,
                              ),
                            }),
                          });
                        });
                    })
                    .catch(() => {
                      this.props.history.push('/not-found');
                    });
                }
              } else {
                this.setState({ owner: typeHelpers.createOwnerObject('event', event) });
              }
            })
            .catch(() => {
              this.props.history.push('/not-found');
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        }
        break;
    }

    if (this.props.userState.isLoggedIn) {
      axios
        .get(routes.GET_PAYMENT_METHODS)
        .then((response: { data: IPaymentMethod[] }) => {
          this.setState({
            paymentMethods: response.data,
          });
        })
        .catch((err) => {
          const errorObj = errorHelpers.getErrorObject(err);
          const toast = toastError(errorObj.translatedMessage, 'Get Payment Methods');
          this.props.createToast(toast);
        });
    }
  }

  fetchHubOwnerGroup(group: IGroup) {
    axios
      .get<{ ownerGroup: IGroup | null }>(
        swapRouteParams(routes.GET_GROUP_HUB_OWNER_GROUP, { groupId: group.id }),
      )
      .then((response) => {
        this.setState({
          hubOwnerGroup: response.data.ownerGroup,
        });
      })
      .catch((err) => {
        const errorObj = errorHelpers.getErrorObject(err);
        const toast = toastError(errorObj.translatedMessage, 'Fetch Hub Owner Cause');
        this.props.createToast(toast);
      });
  }

  getProgressWidth() {
    if (!this.state.owner) {
      return '0%';
    }

    let raised = 0;
    let goal = 0;

    if (this.state.owner.ownerType === uiConstants.ownerType.group) {
      raised = this.state.campaign?.raised ?? 0;
      goal = this.state.campaign?.goal ?? 0;
    } else {
      raised = this.state.owner.child?.raised ?? this.state.owner.raised ?? 0;
      goal = this.state.owner.child?.goal ?? this.state.owner.goal ?? 0;
    }

    if (goal === 0) {
      return '0%';
    }

    return (raised / goal) * 100 + '%';
  }

  getParentCoverImage() {
    return this.state.owner?.child?.ownerCoverImageUrl ?? this.state.owner?.ownerCoverImageUrl;
  }

  getParentProfileImage() {
    return this.state.owner?.child?.ownerProfileImageUrl ?? this.state.owner?.ownerProfileImageUrl;
  }

  getParentLink() {
    switch (this.props.match.params.type) {
      case 'group':
        return '/group/' + this.props.match.params.handle;
      case 'event':
        if (this.props.match.params.subType === 'individual') {
          return (
            '/event/' +
            this.props.match.params.handle +
            '/individual/' +
            this.props.match.params.subHandle
          );
        } else if (this.props.match.params.subType === 'team') {
          return (
            '/event/' +
            this.props.match.params.handle +
            '/' +
            this.props.match.params.subType +
            '/' +
            this.props.match.params.subHandle
          );
        } else {
          return '/event/' + this.props.match.params.handle;
        }
      default:
        typeHelpers.assertNotReachable();
        break;
    }
  }

  getCurrency() {
    let currency = defaultCurrency;

    if (this.state.owner?.ownerType === 'group' && !this.state.owner?.account) {
      const group: IGroup = this.state.owner.object as IGroup;
      if (group.external_id?.Change) {
        currency = 'usd';
      } else {
        currency = this.state.owner?.account?.currency ?? defaultCurrency;
      }
    } else {
      currency = this.state.owner?.account?.currency ?? defaultCurrency;
    }

    return currency;
  }

  renderFormInner(owner: IOwnerObject) {
    const groupOrEvent = typeHelpers.tryGetOwnerObjectAs(owner, 'event', 'group');
    const group = typeHelpers.tryGetOwnerObjectAs(owner, 'group');
    const account =
      owner?.parentOwnerType === 'hub'
        ? (owner?.object as IEventSummaryFE).hub?.account
        : this.state.owner?.account;
    const isChange = !group?.account && !!group?.external_id?.Change;
    return (
      <div className="form-inner">
        {this.state.stripePromise !== null && !this.state.isGlobalGiving && (
          <Elements stripe={this.state.stripePromise}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <DonateForm
                  {...this.props}
                  owner={owner}
                  isP2P={this.state.isP2P}
                  useDonationDescriptions={groupOrEvent?.use_donation_amount_descriptions ?? false}
                  elements={elements}
                  stripe={stripe}
                  campaign={this.state.campaign}
                  autoSendTaxReceipts={group?.auto_tax_receipts || undefined}
                  minimumTaxReceiptAmount={group?.minimum_tax_receipt_dollar_amount || undefined}
                  donationCheckoutMessage={groupOrEvent?.donation_checkout_message}
                  groupDelegations={this.props.groupState.groupDelegations}
                  allowDonationDelegation={
                    this.state.hubOwnerGroup?.allow_donation_delegation ?? false
                  }
                  hubOwnerGroup={this.state.hubOwnerGroup}
                  userPermissions={this.state.userPermissions}
                  warning={{
                    show: !this.state.canDonate,
                    text: this.state.cantDonateText,
                  }}
                />
              )}
            </ElementsConsumer>
          </Elements>
        )}
        {this.state.isGlobalGiving && (
          <GlobalGivingDonateForm
            {...this.props}
            owner={owner}
            hubOwnerGroup={this.state.hubOwnerGroup}
            warning={{
              show: !this.state.canDonate,
              text: this.state.cantDonateText,
            }}
          />
        )}
        {!account && !this.state.isGlobalGiving && !isChange && (
          <div className="form-inner">
            <div className="campaign-error">
              <i className="far fa-exclamation-circle" />
              <span>Connect a bank account to begin accepting payments for donations</span>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderSidebar(owner: IOwnerObject) {
    const group = typeHelpers.getGroupFromOwner(owner);

    const raised = owner.child?.raised ?? owner.raised ?? this.state.campaign?.raised ?? 0;
    const goal = owner.child?.goal ?? owner.goal ?? this.state.campaign?.goal ?? 0;

    const title = owner.child?.ownerTitle ?? owner.ownerTitle;
    const description =
      owner.child?.ownerDescription || owner.ownerDescription || 'No description available.';

    const donationSettingsAction = {
      icon: 'far fa-cog',
      onClick: () => this.setState({ showDonationSettingsModal: true }),
      title: 'Donation Settings',
    } as IFloatingAction;

    return (
      <div className="side">
        <div className="cover">
          {this.getParentCoverImage() && (
            <img
              className="cover-img"
              alt="Cover"
              src={this.getParentCoverImage()}
            />
          )}
          <Portrait
            size={120}
            currentImage={this.getParentProfileImage()}
          />
          <ProfileFloatingActions
            className="floating-actions"
            onShareClick={() => this.setState({ showShareModal: true })}
            showChat={false}
            extraActions={
              this.state.userPermissions['MANAGE_DONATIONS'] && [donationSettingsAction]
            }
          />
        </div>
        <div className="details">
          <div className="type">
            <i className="fal fa-users" />
            <span>
              {this.props.match.params.type === Constants.object_type.group
                ? Constants.object_type.cause.toUpperCase()
                : this.props.match.params.subType
                  ? this.props.match.params.subType.toUpperCase()
                  : this.props.match.params.type.toUpperCase()}
            </span>
          </div>
          <div
            className="title"
            notranslate="yes"
          >
            <Link to={this.getParentLink()}> {title} </Link>
          </div>
          {group?.charity_id && (
            <div className="charity">
              <div className="verified">
                <span>Registered Charity</span>
              </div>
              <div className="charity-id">
                Charity ID: <var data-var="charity_id">{group.charity_id}</var>
              </div>
            </div>
          )}
          {typeHelpers.isOwnerObjectOfType(owner, 'group') && group?.allow_pledges && (
            <div className="pledge">
              <Link to={'/group/' + this.props.match.params.handle + '/pledge'}>Make a Pledge</Link>
            </div>
          )}
          {typeHelpers.isOwnerObjectOfType(owner, 'event') &&
            owner.object.show_campaign_progress && (
              <React.Fragment>
                {this.state.campaign !== null && (
                  <div className="progress-bar-wrap">
                    <div className="progress-bar-bg">
                      <div
                        className="progress-bar"
                        style={{ width: this.getProgressWidth() }}
                      />
                      <div
                        className="amt"
                        notranslate="yes"
                      >
                        {formatCurrency(raised, this.getCurrency(), this.props.locale)}
                      </div>
                      <div
                        className="goal"
                        notranslate="yes"
                      >
                        {formatCurrency(goal, this.getCurrency(), this.props.locale)}
                      </div>
                    </div>
                  </div>
                )}

                {owner.object?.donations_matching?.ratio_description && (
                  <div className="donation-matching">
                    <div className="matching-title">Donation Matching</div>
                    <div className="matching-description">
                      {owner.object.donations_matching.ratio_description}
                    </div>
                    {(owner.object.donations_matching.matched_amount ?? 0) > 0 && (
                      <div className="matched-to-date">
                        <var data-var="matched_amount">
                          {formatCurrency(
                            owner.object.donations_matching.matched_amount,
                            this.getCurrency(),
                            this.props.locale,
                          )}
                        </var>{' '}
                        Matched to Date
                      </div>
                    )}
                  </div>
                )}

                {this.props.eventState.event.event_type === Constants.event_type.default &&
                  group && (
                    <div className="created-by">
                      <Portrait
                        size={50}
                        currentImage={group.profile_image_url}
                      />
                      <div className="created-info">
                        <div className="created-title">CAUSE</div>
                        <div
                          className="created-name"
                          notranslate="yes"
                        >
                          {group.title}
                        </div>
                      </div>
                    </div>
                  )}

                {this.props.eventState.event.event_type === Constants.event_type.obo_fundraiser &&
                  !this.props.match.params.subType &&
                  owner.object?.created_by && (
                    <div className="created-by">
                      <Portrait
                        size={50}
                        currentImage={owner.object?.created_by.profile_image_url}
                      />
                      <div className="created-info">
                        <div className="created-title">CREATED BY</div>
                        <div
                          className="created-name"
                          notranslate="yes"
                        >
                          {owner.object?.created_by.display_name}
                        </div>
                      </div>
                    </div>
                  )}

                {typeHelpers.isOwnerObjectOfType(owner, 'team') && owner.object.owner && (
                  <div className="created-by">
                    <Portrait
                      size={50}
                      currentImage={owner.object.owner.profile_image_url}
                    />
                    <div className="created-info">
                      <div className="created-title">CREATED BY</div>
                      <div
                        className="created-name"
                        notranslate="yes"
                      >
                        {owner.object.owner.display_name}
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          {typeHelpers.isOwnerObjectOfType(owner, 'group', 'event') && (
            <div className="description">
              <QuillTextEditor
                value={description}
                readOnly={true}
                preserveWhitespace={true}
                theme={uiConstants.quill.readOnlyTheme}
              />
            </div>
          )}
          {this.state.showDonationSettingsModal && this.state.owner !== null && (
            <DonationSettingsModal
              owner={this.state.owner}
              onClose={() => {
                this.setState({ showDonationSettingsModal: false });
                this.fetchOwnerData();
              }}
            />
          )}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.eventAllowsDonationsRedirect) {
      return <Redirect to={this.state.eventAllowsDonationsRedirect} />;
    }

    return (
      <div
        className={
          this.state.loading ? 'Donate loading' : this.state.isEmbed ? 'Donate embedded' : 'Donate'
        }
      >
        <Loader loading={this.state.loading} />
        <div className="back-splash">
          {this.state.owner !== null && this.getParentCoverImage() && (
            <img
              alt="Cover Image Blacksplash"
              src={this.getParentCoverImage()}
            />
          )}
          <div className="curve" />
        </div>
        <div className="Donate-content">
          <div className="main">
            <div className="main-inner">
              <div className="title">
                <h1>Donate</h1>
              </div>
              <div className="main-form">
                {this.state.owner !== null && this.renderFormInner(this.state.owner)}
              </div>
            </div>
          </div>
          {this.state.owner !== null && this.renderSidebar(this.state.owner)}
        </div>
        <Modal
          class="share-modal"
          show={this.state.showShareModal}
          onClose={() => this.setState({ showShareModal: false })}
        >
          {this.state.owner !== null && (
            <Share
              {...this.props}
              url={Config.web.REACT_APP_BASE_URL + this.props.match.url}
            />
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
    isLoggedIn: userSelectors.isUserAuthenticated(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Donate);
