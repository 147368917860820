import React, { FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { capitalizeString, handleInputChange } from '../../../../helpers';

import Dropdown, { IOptions } from '../../../Dropdown/Dropdown';
import TextField from '../../../TextField/TextField';
import TextArea from '../../../TextArea/TextArea';

import 'rc-slider/assets/index.css';
import { localizeHelpers } from '../../../../localizeHelpers';

const Slider = require('rc-slider');
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const entityGoalDefault: number = 1000;
const textAreaMax: number = 300;

interface IAgeRange {
  min: number;
  max: number;
  currentMin: number;
  currentMax: number;
}

interface IProps extends RouteComponentProps {
  flow: string;
  type: 'group' | 'event' | 'gig';
  entity: any;
  // used to hide/show/set event organizer
  isOnBehalfOf?: boolean;

  updateEntity(key: string, val: any): void;
}

interface IState {
  description: string;
  title: string;
  type: string;
  fundraisingOptions: Array<IOptions>;
  fundraisingOption: string;
  ageRange: IAgeRange;
  goal: number;
  eventOrganizerName: string;
}

class EntityInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      title: '',
      type: '',
      description: '',
      fundraisingOptions: [
        { label: 'Less than 10k', value: '10000' },
        { label: '10k - 50k', value: '10k - 50k' },
        { label: '50k - 100k', value: '50k - 100k' },
        { label: '100k - 200k', value: '100k - 200k' },
        { label: '200k - 500k', value: '200k - 500k' },
        { label: '500k - 1M', value: '500k - 1m' },
        { label: 'Over 1M', value: 'Over 1M' },
      ],
      fundraisingOption: '',
      ageRange: {
        min: 13,
        max: 100,
        currentMin: 13,
        currentMax: 100,
      },
      goal: entityGoalDefault,
      eventOrganizerName: '',
    };

    this.handleName = this.handleName.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleFundraiseAmount = this.handleFundraiseAmount.bind(this);
    this.updateAgeRange = this.updateAgeRange.bind(this);
    this.onGoalUpdate = this.onGoalUpdate.bind(this);
    this.handleOrganizer = this.handleOrganizer.bind(this);
  }

  componentDidMount() {
    this.onGoalUpdate();
    this.initializeData();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.entity.fundraiseAmount !== this.props.entity.fundraiseAmount) {
      this.setState({
        fundraisingOption: this.props.entity.fundraiseAmount,
      });
    }
  }

  initializeData() {
    if (this.props.entity) {
      this.setState(
        {
          type: this.props.entity.type,
          description: this.props.entity.shortDescription || this.props.entity.description,
          title: this.props.entity.title,
          fundraisingOption: this.props.entity.fundraiseAmount,
          goal: this.props.entity.goal || entityGoalDefault,
          eventOrganizerName: this.props.entity?.eventOrganizerName || '',
        },
        () => {
          if (this.props.type === 'gig' && this.props.entity.ageRange) {
            this.setState({
              ageRange: {
                min: 13,
                max: 100,
                currentMin: this.props.entity?.ageRange?.min,
                currentMax: this.props.entity?.ageRange?.max,
              },
            });
          }
        },
      );
    }
  }

  renderHeaderTxt() {
    return (
      <span notranslate="yes">
        {localizeHelpers.translate('Tell us a little bit about your {{type}}', {
          type: capitalizeString(this.props.type),
        })}
      </span>
    );
  }

  handleName() {
    this.props.updateEntity('title', this.state.title);
  }

  handleDescription() {
    this.props.updateEntity('shortDescription', this.state.description);
  }

  handleFundraiseAmount() {
    this.props.updateEntity('fundraiseAmount', this.state.fundraisingOption);
  }

  handleOrganizer() {
    this.props.updateEntity('eventOrganizerName', this.state.eventOrganizerName);
  }

  updateAgeRange(val: Array<number>) {
    let range = { ...this.state.ageRange, currentMin: val[0], currentMax: val[1] };
    this.setState(
      {
        ageRange: {
          ...range,
        },
      },
      () => {
        this.props.updateEntity('ageRange', {
          min: this.state.ageRange.currentMin,
          max: this.state.ageRange.currentMax,
        });
      },
    );
  }

  onGoalUpdate() {
    this.props.updateEntity('goal', this.state.goal);
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} ${this.props.type}-info`}>
        {this.renderHeaderTxt()}
        {this.props.isOnBehalfOf && this.props.type === 'event' && (
          <div className="details-row">
            <TextField
              value={this.state.eventOrganizerName}
              name="eventOrganizerName"
              label={'Event Organizer'}
              type="text"
              onChange={(e) => {
                handleInputChange(e, this, false, this.handleOrganizer);
              }}
            />
          </div>
        )}
        {this.props.type === 'group' && (
          <div className="details-row">
            <Dropdown
              options={this.state.fundraisingOptions}
              label="Annual Revenue Range"
              value={this.props.entity.fundraiseAmount || this.state.fundraisingOption}
              name="fundraisingOption"
              onChange={(e) => {
                handleInputChange(e, this, false, this.handleFundraiseAmount);
              }}
            />
          </div>
        )}
        {this.props.type === 'event' && (
          <div className="details-row">
            <TextField
              value={this.state.goal}
              name="goal"
              label="Event Revenue Goal"
              type="number"
              onChange={(e) => {
                handleInputChange(e, this, false, this.onGoalUpdate);
              }}
            />
          </div>
        )}
        <div className="details-row">
          <TextField
            value={this.state.title}
            name="title"
            label={`${this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1)} Name`}
            type="text"
            onChange={(e) => {
              handleInputChange(e, this, false, this.handleName);
            }}
          />
        </div>
        <div className="details-row margin-top">
          <TextArea
            className="description-text-field"
            label="Short Description"
            type="text"
            value={this.state.description}
            name="description"
            maxLength={textAreaMax}
            onChange={(e) => {
              handleInputChange(e, this, false, this.handleDescription);
            }}
          />
        </div>
        {this.props.type === 'gig' && (
          <div>
            <label>Age Range</label>
            <Range
              className="RangeSlider"
              min={this.state.ageRange.min}
              max={this.state.ageRange.max}
              tipFormatter={(value: number) => (
                <var data-var="entity_info_tip_formatter_value">{value}</var>
              )}
              onAfterChange={this.updateAgeRange}
              tipProps={{ visible: true, placement: 'bottom' }}
              defaultValue={[16, 100]}
            />
          </div>
        )}
      </div>
    );
  }
}

export default EntityInfo;
