import React, { ChangeEventHandler, Fragment, useEffect, useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';
import './DatePickerField.scss';
import ReactDOM from 'react-dom';
import { LocaleDateFormats, localizeHelpers } from '../../../../../localizeHelpers';
import moment from 'moment';
import TextField from '../../../../TextField/TextField';
import { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';

interface IDatePickerFieldProps {
  value: string | undefined;
  label?: string;
  name: string;
  locale?: string;
  placeholder?: string;
  disabled?: boolean;
  disabledDayOptions?: {
    from: string;
    to: string;
  };
  onChange: (selectedDate: string) => void;
}

//TODO: Separate date popup dialog into its own component
export default function DatePickerField(props: IDatePickerFieldProps) {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popperRef = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const longDateFormat = localizeHelpers.getLongDateFormat(
    LocaleDateFormats.LL,
    props.locale ?? 'en',
  );

  const isFieldDisabledClass = props.disabled ? 'disabled' : '';

  const offsetModifier = React.useMemo<Partial<OffsetModifier>>(
    () => ({
      name: 'offset',
      options: {
        offset: ({ placement, reference, popper }) => {
          return [0, 10];
        },
      },
    }),
    [],
  );

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [offsetModifier],
  });

  function closePopper() {
    setIsPopperOpen(false);
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const longDateFormat = localizeHelpers.getLongDateFormat(
      LocaleDateFormats.LL,
      props.locale ?? 'en',
    );

    const date = moment(e.currentTarget.value).format(longDateFormat);
    if (moment(date).isValid()) {
      setSelectedDate(moment(date).toDate());
    } else {
      setSelectedDate(undefined);
    }
  };

  function handleFieldClick() {
    if (props.disabled) {
      return;
    }

    setIsPopperOpen(true);
  }

  function handleDaySelect(date?: Date) {
    setSelectedDate(date);
    if (date) {
      closePopper();
    } else {
      props.onChange('');
    }
  }

  function handleClearInputValue() {
    setSelectedDate(undefined);
  }

  useEffect(() => {
    props.onChange(selectedDate ? moment(selectedDate).format(longDateFormat) : '');
  }, [selectedDate]);

  return (
    <Fragment>
      <div
        ref={popperRef}
        className={`DatePickerFieldContainer ${isFieldDisabledClass}`}
      >
        <TextField
          label={props.label}
          name={props.name}
          type="text"
          icon="fal fa-calendar-alt"
          placeholder={props.placeholder}
          value={props.value ?? ''}
          onChange={handleInputChange}
          onInputClick={handleFieldClick}
          onClearInputValue={handleClearInputValue}
          disabled={props.disabled}
        />
      </div>
      {isPopperOpen &&
        ReactDOM.createPortal(
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: true,
              onDeactivate: closePopper,
              onActivate: () => handleFieldClick(),
            }}
          >
            <div
              tabIndex={-1}
              style={popper.styles.popper}
              className="DatePickerDialogPortal"
              {...popper.attributes.popper}
              ref={setPopperElement}
              role="dialog"
            >
              <DayPicker
                initialFocus={isPopperOpen}
                mode="single"
                defaultMonth={selectedDate}
                selected={selectedDate}
                onSelect={handleDaySelect}
                modifiersClassNames={{
                  selected: 'date-picker-selected',
                }}
                disabled={props.disabled}
              />
            </div>
          </FocusTrap>,
          document.querySelector('#context-menus')!,
        )}
    </Fragment>
  );
}
