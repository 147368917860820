import React from 'react';
import Joyride from 'react-joyride';
import { connect } from 'react-redux';
import {
  IAddress,
  IApplication,
  IGigSummary,
  IPageComponent,
  IShift,
  IUserRole,
} from '@gigit/interfaces';
import queryString from 'query-string';
import CharityIcon from '../../assets/charity-icon-dark.svg';
import { RouteComponentProps } from 'react-router-dom';
import CalendarIcon from '../../assets/calendar-icon.svg';
import HandIcon from '../../assets/hand-icon-grey.svg';
import {
  errorHelpers,
  isPageActive,
  IStringMap,
  mapPermissions,
  routes,
  swapRouteParams,
  setSEOMetatags,
  toastError,
  toastInfo,
  getAddressParts,
} from '../../helpers';
import { Config } from '@gigit/config';
import { Constants } from '@gigit/constants';
import { IAppState } from '../../store';
import { IUserState } from '../../reducers/user';
import { IGigState } from '../../reducers/gig';
import { IGroupState } from '../../reducers/group';
import { IEventState } from '../../reducers/event';
import Login from '../Login/Login';
import Share from '../../components/Share/Share';
import Modal from '../../components/Modal/Modal';
import { getUserGroupApplications } from '../../actions/user';
import { getEvent } from '../../actions/event';
import { resetVolunteerForGroup, getGroup } from '../../actions/group';
import { createToast } from '../../actions/toaster';
import {
  getGig,
  updateGigCoverImage,
  updateGigProfileImage,
  updateGig,
  getGigPages,
  resetGigState,
  resetVolunteerForGig,
  updateGigPageComponent,
  changeGigStatus,
} from '../../actions/gig';
import './Gig.scss';
import { BackSplash } from '../../components/ProfilePageComponents/BackSplash/BackSplash';
import { PageSidebar } from '../../components/ProfilePageComponents/PageSidebar/PageSidebar';
import PageHeader, {
  IHeaderLink,
} from '../../components/ProfilePageComponents/PageHeader/PageHeader';
import { PageBody } from '../../components/ProfilePageComponents/PageBody/PageBody';
import PageCoverImage from '../../components/ProfilePageComponents/PageCoverImage/PageCoverImage';
import { PageContainer } from '../../components/ProfilePageComponents/PageContainer/PageContainer';
import PageContent from '../../components/ProfilePageComponents/PageContent/PageContent';
import { IToast, IStep } from '../../interfaces';
import typeHelpers from '../../helpers/typeHelpers';
import Button from '../../components/Button/Button';
import ExternalVolunteerApplicationWarning from '../../components/shared/ExternalVolunteerApplicationWarning/ExternalVolunteerApplicationWarning';
import VolunteerApplicationForm from '../../components/VolunteerApplicationForm/VolunteerApplicationForm';
import AddToCalendarModal from '../../components/shared/AddToCalendarModal/AddToCalendarModal';
import axios from 'axios';
import { localizeHelpers } from '../../localizeHelpers';
import { uiConstants } from '../../constants';
import ContactOrganizerModal from '../../components/ContactOrganizerModal/ContactOrganizerModal';
import {
  eventRequestActions,
  groupRequestActions,
  gigRequestActions,
  userRequestActions,
  volunteerRequestActions,
} from '../../requestActions';
import PopupMenu, { IPopupMenuItem } from '../../components/shared/PopupMenu/PopupMenu';

interface IProps extends RouteComponentProps<any> {
  getGroup(handle: string): void;
  getEvent(handle: string): void;
  userState: IUserState;
  gigState: IGigState;
  groupState: IGroupState;
  eventState: IEventState;
  createToast(toast: IToast): void;
  getGig(handleOrId: string): void;
  getGigPages(handle: string): void;
  updateGigPageComponent(
    gigId: string,
    _pageId: string,
    _componentId: string,
    _component: any,
  ): void;
  resetGigState(): void;
  updateGig(gig: any): void;
  updateGigCoverImage(img: string): void;
  updateGigProfileImage(img: string): void;
  getUserGroupApplications(groupId: string): void;
  resetVolunteerForGroup(): void;
  resetVolunteerForGig(): void;
  changeGigStatus(
    gigId: string,
    status: string,
    callback?: (updatedGig: IGigSummary) => void,
  ): void;
}

interface IState {
  mounted: boolean;
  gig: IGigSummary;
  steps: IStep[];
  sidebarRef: any;
  sidebarClass: string;
  pageComponents: any;
  gigPages: any;
  redirect: boolean;
  showApply: boolean;
  shifts: Array<IShift>;
  showLoginModal: boolean;
  userPermissions: IStringMap;
  showShare: boolean;
  alreadyAppliedToGig: boolean;
  alreadyAppliedToGroup: boolean;
  params: any;
  showGigOnboard: boolean;
  showExternalVolunteerApplicationWarning: boolean;
  showVolunteerFormModal: boolean;
  hasAppliedToCompanyGig: boolean;
  showAddToCalendarModal: boolean;
  showContactUsModal: boolean;
  showMenuCompanyCard: boolean;
  companyCardMenuItems: IPopupMenuItem[];
  isInterested: boolean | null;
}

type IsInterestedType = 'interested' | 'not_interested';
class Gig extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let _params = queryString.parse(this.props.location.search);

    this.state = {
      isInterested: null,
      companyCardMenuItems: [
        {
          id: 'interested',
          icon: this?.state?.isInterested ? 'fad fa-circle' : 'far fa-circle',
          label: 'Interested',
          onClick: async () => {
            await this.changeVolunteerOpportunityUserStatus(true);
          },
        },
        {
          id: 'not_interested',
          icon: this?.state?.isInterested === false ? 'fad fa-circle' : 'far fa-circle',
          label: 'Not Interested',
          onClick: async () => {
            await this.changeVolunteerOpportunityUserStatus(false);
          },
        },
      ],
      showMenuCompanyCard: false,
      mounted: false,
      gig: {
        title: this.props.gigState.gig.title,
        description: this.props.gigState.gig.description,
        handle: this.props.gigState.gig.handle,
        is_virtual: this.props.gigState.gig.is_virtual,
      },
      steps: [
        {
          disableBeacon: true,
          target: '.PageSidebar .Portrait .inner',
          content:
            'Customize your Volunteer Opportunities profile picture to make your posting stand out.',
        },
        {
          disableBeacon: true,
          target: '.CoverImage',
          content: 'Give your Volunteer Opportunity a cover image to make it exciting!',
        },
        {
          disableBeacon: true,
          target: '.PageBody .float',
          content: 'Manage your Volunteer Opportunity details at any time using the Manage button.',
        },
        {
          disableBeacon: true,
          target: '.Description',
          content:
            'Fill out a detailed description of your Volunteer Opportunity. Volunteer Opportunities with more complete descriptions get more applicants.',
        },
      ],
      sidebarRef: React.createRef(),
      sidebarClass: '',
      pageComponents: [],
      gigPages: [],
      redirect: false,
      showApply: false,
      shifts: [],
      showLoginModal: false,
      userPermissions: {},
      showShare: false,
      alreadyAppliedToGig: false,
      alreadyAppliedToGroup: false,
      params: _params,
      showGigOnboard: false,
      showExternalVolunteerApplicationWarning: false,
      showVolunteerFormModal: false,
      hasAppliedToCompanyGig: false,
      showAddToCalendarModal: false,
      showContactUsModal: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.toggleApplyModal = this.toggleApplyModal.bind(this);
  }

  componentDidMount() {
    (window as any).prerenderReady = false;
    this.props.getGig(this.props.match.params.id);
    if (this.props.gigState.gig.event_id) {
      this.props.getEvent(this.props.gigState.gig.event_id);
    } else if (this.props.gigState.gig.group_id) {
      this.props.getGroup(this.props.gigState.gig.group_id);
    }
    this.setPermissions();

    if (this.props.userState.isLoggedIn && this.props.groupState.group.id) {
      this.props.getUserGroupApplications(this.props.groupState.group.id);
    }

    const _params = queryString.parse(this.props.location.search);

    if (_params.reapply) {
      this.handleApply();
    }

    this.setState({
      mounted: true,
      showGigOnboard: !!_params.help,
    });

    if (this.props.gigState.gig?.hub_id) {
      this.checkUserVolunteerOpportunityFlag();
    }
  }

  checkIsIntersted(value: boolean, name: IsInterestedType) {
    return (value && name === 'interested') || (!value && name === 'not_interested');
  }

  updateIsInterested(isInterested: boolean | null) {
    this.setState({
      isInterested,
      companyCardMenuItems: this.state.companyCardMenuItems.map((menuItem) => {
        const tmp = menuItem;
        if (
          isInterested !== null &&
          this.checkIsIntersted(isInterested, menuItem.id as IsInterestedType)
        ) {
          tmp.icon = 'fad fa-circle';
        } else {
          tmp.icon = 'far fa-circle';
        }

        return tmp;
      }),
    });
  }

  async checkUserVolunteerOpportunityFlag() {
    try {
      typeHelpers.assertNotNullOrUndefined(
        this.props.gigState.gig.id,
        'Expected Volunteer Opportunity ID',
      );
      typeHelpers.assertNotNullOrUndefined(this.props.userState.user.id, 'Expected User ID');
      const result = await gigRequestActions.getGigVolunteer(
        this.props.gigState.gig.id,
        this.props.userState?.user?.id || '',
      );
      this.updateIsInterested(result.is_interested ?? null);
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Get Volunteer Flag');
      this.props.createToast(toast);
    }
  }

  async changeVolunteerOpportunityUserStatus(flag_value: boolean) {
    if (this.props.userState.isLoggedIn) {
      try {
        typeHelpers.assertNotNullOrUndefined(this.props.userState?.user?.id, 'Expected User ID');
        typeHelpers.assertNotNullOrUndefined(this.props.gigState?.gig?.id, 'Expected Gig ID');

        await volunteerRequestActions.setVolunteerFlag(
          this.props.gigState.gig.id,
          this.props.userState.user.id,
          {
            flag: 'is_interested',
            flag_value,
          },
        );

        this.updateIsInterested(flag_value);
      } catch (error) {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Set Volunteer Flag');
        this.props.createToast(toast);
      }
    } else {
      this.props.history.push(`/login?redirect=/gig/${this.props.gigState.gig.handle}`);
    }
  }

  async setPermissions() {
    let _permissions: IStringMap;
    if (this.props.gigState.gig?.event_id) {
      _permissions = mapPermissions(this.props.eventState.currentUserRole.permissions);
    } else if (this.props.gigState.gig?.hub_id) {
      try {
        const role = await userRequestActions.getUserHubRole(this.props.gigState.gig.hub_id);
        _permissions = mapPermissions(role.permissions);
      } catch (error) {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Get User Company Role');
        this.props.createToast(toast);
        _permissions = [];
      }
    } else {
      _permissions = mapPermissions(this.props.groupState.currentUserRole.permissions);
    }

    this.setState({
      userPermissions: _permissions,
    });
  }

  async handleGetGroupDataMembers(groupId: string) {
    let result: IUserRole[] = [];
    try {
      result = await groupRequestActions.getGroupMembers(groupId);
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Get Cause Members');
      this.props.createToast(toast);
    }
    return result;
  }

  async handleGetEventDataMembers(eventId: string) {
    let result: IUserRole[] = [];
    try {
      result = await eventRequestActions.getEventMembers(eventId);
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Get Event Members');
      this.props.createToast(toast);
    }
    return result;
  }

  showNotActivePageWarning() {
    this.props.createToast(
      toastInfo(localizeHelpers.translate(uiConstants.warning.page_not_active), 'Warning'),
    );
    this.props.history.push('/');
  }

  async getGigMembers(params: { groupId?: string; eventId?: string }) {
    let data: IUserRole[] = [];
    if (params?.eventId) {
      data = await this.handleGetEventDataMembers(params.eventId);
    } else if (params?.groupId) {
      data = await this.handleGetGroupDataMembers(params.groupId);
    }

    return data;
  }

  async checkIsPageActive(
    status_code?: string,
    groupId?: string,
    eventId?: string,
    hubId?: string,
  ) {
    let isDraft = false;
    /**
     * Here we check to see if the user can access a 'draft' page.
     * a user can access a draft page if they have the proper admin/on-behalf-of role
     * if a user doesn't have one of those roles or is logged out, we redirect them to the home page
     */
    if (this.props.userState.isLoggedIn === false && status_code === Constants.gig_status.draft) {
      this.showNotActivePageWarning();
    }
    if (this.props.userState.isLoggedIn && status_code === Constants.gig_status.draft) {
      try {
        let userRoleName;
        if (eventId) {
          userRoleName = await userRequestActions.getUserEventRole(eventId);
        } else if (groupId) {
          userRoleName = await userRequestActions.getUserGroupRole(groupId);
        } else if (hubId) {
          userRoleName = await userRequestActions.getUserHubRole(hubId);
        }

        if (
          !isPageActive({
            status: status_code,
            isUserLoggedIn: this.props.userState.isLoggedIn,
            roleName: userRoleName?.role_name ? userRoleName.role_name : '',
          })
        ) {
          isDraft = true;
        }
      } catch (err) {
        const errorObj = errorHelpers.getErrorObject(err);

        if (
          errorObj.errorCode !== 'ERROR.ROLES.NO_OBJECT_PERMISSIONS' &&
          errorObj.errorCode !== 'ERROR.AUTH.UNAUTHORIZED'
        ) {
          this.props.createToast(
            toastError(errorObj.translatedMessage, 'Volunteer Opportunity Members'),
          );
        }
      }
    }

    return isDraft;
  }

  componentWillUnmount() {
    this.props.resetGigState();
  }

  async componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevProps.userState.isLoggedIn === false &&
      this.props.gigState.gig?.status?.code === Constants.gig_status.draft
    )
      this.showNotActivePageWarning();
    if (this.props.groupState.group !== prevProps.groupState.group) {
      if (this.props.userState.isLoggedIn && this.props.groupState.group.id) {
        if (!this.props.gigState.gig.hub_id)
          this.props.getUserGroupApplications(this.props.groupState.group.id);
      }
    }
    if (this.props.gigState.gig !== prevProps.gigState.gig) {
      const {
        id,
        title,
        profile_image_url,
        cover_image_url,
        description,
        status,
        group,
        group_id,
        event_id,
        event,
        hub_id,
      } = this.props.gigState.gig;
      const shouldTriggerWarning = await this.checkIsPageActive(
        status?.code,
        group?.id || group_id,
        event?.id || event_id,
        hub_id,
      );
      if (shouldTriggerWarning) {
        this.showNotActivePageWarning();
        return;
      }

      setSEOMetatags({
        urlPath: `gig/${id}`,
        title,
        description,
        imageURL: profile_image_url,
        coverImageURL: cover_image_url,
      });

      this.setState(
        {
          gig: this.props.gigState.gig,
        },
        () => {
          if (this.props.gigState.gig.event_id) {
            this.props.getEvent(this.props.gigState.gig.event_id);
          }

          if (this.state.gig?.hub_id && this.isUserStillLoggedIn()) {
            axios
              .get(
                swapRouteParams(routes.GET_USER_COMPANY_GIG_APPLICATIONS, {
                  hubId: this.props.gigState.gig?.hub_id,
                }),
              )
              .then((response) => {
                this.setState({
                  hasAppliedToCompanyGig: !!response.data.filter(
                    (app: IApplication) => app?.gig_id === this.state.gig?.id,
                  )?.length,
                });
              })
              .catch((error) => {
                const errorObj = errorHelpers.getErrorObject(error);
                const toast = toastError(
                  errorObj.translatedMessage,
                  'Fetching User Gig Application',
                );
                this.props.createToast(toast);
              })
              .finally(() => {
                if (
                  !this.state.hasAppliedToCompanyGig &&
                  this.state.params.fn &&
                  this[this.state.params.fn as keyof Gig]
                ) {
                  this[this.state.params.fn as keyof Gig]();
                  this.props.history.replace({
                    pathname: this.props.location?.pathname,
                    search: '',
                  });
                } else {
                  this.props.history.replace({
                    pathname: this.props.location?.pathname,
                    search: '',
                  });
                }
              });
          }
        },
      );

      if (!this.props.gigState.gig.hub_id) this.props.getGroup(this.props.gigState.gig.group_id!);
    }

    if (
      this.props.match.params.id !== prevProps.match.params.id ||
      (this.props.gigState.gig?.id && this.props.gigState.gig.id !== prevProps.gigState.gig?.id)
    ) {
      this.setPermissions();
      if (this.props.gigState.gig.hub_id) {
        this.checkUserVolunteerOpportunityFlag();
      }
    }

    if (this.state.isInterested !== prevState.isInterested) {
      this.updateIsInterested(this.state.isInterested);
    }

    if (prevProps.userState.isApplicationLoading && !this.props.userState.isApplicationLoading) {
      for (let g in this.props.userState.currentApplications.gigs) {
        if (
          this.props.userState.currentApplications.gigs[g].gig?.handle ===
          this.props.match.params.id
        ) {
          this.setState({
            alreadyAppliedToGig: true,
          });
        }
      }

      if (this.props.userState.currentApplications.group?.group?.id !== '') {
        this.setState({
          alreadyAppliedToGroup: true,
        });
      }
    }

    if (this.props.gigState.gigPages !== prevProps.gigState.gigPages) {
      this.setState({
        gigPages: this.props.gigState.gigPages,
      });
    }

    if (this.props.gigState.currentPageComponents !== prevProps.gigState.currentPageComponents) {
      this.setState({
        pageComponents: this.props.gigState.currentPageComponents,
      });
    }

    if (
      this.props.groupState.currentUserRole !== prevProps.groupState.currentUserRole ||
      this.props.eventState.currentUserRole !== prevProps.eventState.currentUserRole
    ) {
      this.setPermissions();
    }

    if (this.props.groupState.volunteerError === '' && this.props.groupState.volunteerSuccess) {
      this.props.resetVolunteerForGroup();
    }

    if (this.props.gigState.volunteerError === '' && this.props.gigState.volunteerSuccess) {
      this.props.resetVolunteerForGig();
    }

    if (prevProps.groupState.volunteerError === '' && this.props.groupState.volunteerError !== '') {
      const toast = toastError(this.props.groupState.volunteerError, 'Application Form');
      this.props.createToast(toast);
    }

    if (prevProps.gigState.volunteerError === '' && this.props.gigState.volunteerError !== '') {
      const toast = toastError(this.props.gigState.volunteerError, 'Application Form');
      this.props.createToast(toast);
    }

    if (this.props.userState.currentApplications !== prevProps.userState.currentApplications) {
      if (
        this.props.userState.isLoggedIn &&
        this.props.gigState.gig?.group_id &&
        this.state.params.fn === 'handleApply'
      ) {
        if (!this.hasApplied()) {
          this.setState({ showVolunteerFormModal: true }, () => {
            this.props.history.replace({ pathname: this.props.location?.pathname, search: '' });
          });
        } else {
          this.props.history.replace({ pathname: this.props.location?.pathname, search: '' });
        }
      }
    }
  }

  handleScroll() {
    if (window.scrollY >= 40 && this.state.sidebarClass !== 'fixed') {
      this.setState({
        sidebarClass: 'fixed',
      });
    } else if (window.scrollY < 40 && this.state.sidebarClass !== '') {
      this.setState({
        sidebarClass: '',
      });
    }
  }

  saveGig() {
    const gig = { ...this.props.gigState.gig, ...this.state.gig };
    this.props.updateGig(gig);
  }

  canEditGig() {
    if (this.props.userState.isLoggedIn && this.state.userPermissions['MANAGE_GIGS']) {
      return true;
    }

    return false;
  }

  onImageChange(fileData: any) {
    switch (fileData.type) {
      case 'cover_image_url':
        this.props.updateGigCoverImage(fileData.file);
        break;
      case 'profile_image_url':
        this.props.updateGigProfileImage(fileData.file);
        break;
    }
  }

  handleGigInputChange(event: any, self: any, prevent?: boolean, callback?: any) {
    if (prevent === true) {
      event.preventDefault();
    }

    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;
    const name = target.name;

    self.setState(
      {
        gig: {
          ...this.state.gig,
          [name]: value,
        },
      },
      () => {
        if (callback !== undefined) {
          callback();
        }
      },
    );
  }

  toggleApplyModal(value: boolean) {
    this.setState({
      showApply: value,
    });
  }

  activateGig() {
    let _gig = { ...this.state.gig };

    if (_gig.status) {
      _gig.status.code = 'active';
    }

    this.props.updateGig(_gig);
  }

  share() {
    this.setState({
      showShare: true,
    });
  }

  hasApplied() {
    if (this.isUserStillLoggedIn()) {
      return this.props.gigState.gig?.hub_id
        ? this.state.hasAppliedToCompanyGig
        : !!this.props.userState.currentApplications.gigs.find(
            (gigApplication) => gigApplication.gig?.id === this.props.gigState.gig.id,
          );
    } else {
      return false;
    }
  }

  handleApply() {
    if (this.props.userState.isLoggedIn) {
      this.setState({ showVolunteerFormModal: !this.state.showVolunteerFormModal });
    } else {
      this.props.history.push(
        `/login?redirect=/gig/${this.props.gigState.gig.handle}?fn=handleApply`,
      );
    }
  }

  onUpdateComponent = (pageId: string, componentId: string, payload: Partial<IPageComponent>) => {
    this.props.updateGigPageComponent(this.props.gigState.gig.id!, pageId, componentId, payload);
  };

  isUserStillLoggedIn(): boolean {
    return this.props.userState.isLoggedIn;
  }

  hasAdminPermission(): boolean {
    return !!this.state.userPermissions['MANAGE_GIGS'];
  }

  hasEditPermissions(): boolean {
    return this.hasAdminPermission() && this.isUserStillLoggedIn();
  }

  getLocationAddress(data: IAddress) {
    return `${data.line1} ${data.line2}, ${data.city}, ${data.country}`;
  }

  render() {
    let _joyrideStyles = {
      options: {
        arrowColor: '#FFF',
        backgroundColor: '#FFF',
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: '#5E51AB',
        textColor: '#505050',
      },
    };

    const isAcceptingApplications =
      this.state.gig.status?.code !== 'inactive' &&
      !!this.props.gigState.gig.accepting_applications;

    const links: IHeaderLink[] = [];

    if (this.props.gigState.gig.event_id && this.props.eventState.event.title) {
      links.push({
        url: `/${uiConstants.ownerType.event}/${this.props.eventState.event.handle}`,
        label: this.props.eventState.event.title,
        iconClassName: 'fal fa-ticket-alt',
        ownerType: uiConstants.ownerType.event,
      });
    }

    if (this.props.gigState.gig.group_id && this.props.groupState.group.title) {
      links.push({
        url: `/${uiConstants.ownerType.group}/${this.props.groupState.group.handle}`,
        label: this.props.groupState.group.title,
        imgIcon: {
          src: CharityIcon,
          width: '18px',
          height: '13px',
        },
        ownerType: uiConstants.ownerType.group,
      });
    }

    if (this.props.gigState.gig.hub_id) {
      links.push({
        url: `/${uiConstants.ownerType.company}/${this.props.gigState.gig?.hub?.handle}`,
        label: this.props.gigState.gig?.hub?.title ?? '',
        iconClassName: 'fal fa-hands-heart',
        ownerType: uiConstants.ownerType.company,
      });
    }

    return (
      <div className="Gig">
        {this.state.showGigOnboard && (
          <Joyride
            locale={{ back: 'Back', close: 'Close', last: 'Last', next: 'Next', skip: 'Skip' }}
            continuous={true}
            disableScrolling={true}
            styles={_joyrideStyles}
            steps={this.state.steps}
            callback={(state) => {
              if (state.status === 'finished') {
                this.props.history.replace(
                  `/${uiConstants.ownerType.gig}/${this.props.gigState.gig.handle}`,
                );
                this.setState({ showGigOnboard: false });
              }
            }}
          />
        )}
        <BackSplash
          coverImageUrl={this.props.gigState.gig.cover_image_url}
          onShareClick={() => this.setState({ showShare: true })}
          showChat={true}
          onChatClick={() => this.setState({ showContactUsModal: true })}
          onImageChange={this.onImageChange}
          showEditCover={this.hasEditPermissions()}
        />
        <div className="gig-content">
          <PageSidebar>
            <PageHeader
              location={
                this.props.gigState.gig.is_virtual
                  ? 'Virtual Event'
                  : this.props.gigState.gig?.locations
                    ? getAddressParts(this.props.gigState.gig.locations[0], [
                        'line1',
                        'city',
                        'country',
                      ])
                    : undefined
              }
              postedOn={this.props.gigState.gig.created_at}
              fromDate={this.props.gigState.gig.start_date}
              toDate={this.props.gigState.gig.end_date}
              ownerType={uiConstants.ownerType.gig}
              group={this.props.groupState.group}
              history={this.props.history}
              isLoading={this.props.gigState.isGigLoading}
              showEditPortrait={this.hasEditPermissions()}
              portraitImageUrl={this.props.gigState.gig.profile_image_url || ''}
              typeName="VOLUNTEER OPPORTUNITY"
              isExternalVM={
                !!(
                  this.props.gigState.gig.external_id &&
                  this.props.gigState.gig.external_id.VolunteerMatch
                )
              }
              showChangeStatus={this.hasEditPermissions()}
              availableStatuses={Object.values(Constants.gig_status)}
              currentStatus={this.props.gigState.gig.status?.code}
              onStatusChanged={(status: string, callback) => {
                this.props.changeGigStatus(this.props.gigState.gig.id || '', status, callback);
              }}
              typeImgIcon={{
                src: HandIcon,
                height: '18px',
                width: '14px',
              }}
              displayName={this.state.gig.title}
              onDisplayNameChange={(e) =>
                this.setState({ gig: { ...this.state.gig, title: e.target.value } })
              }
              onSubmitDisplayNameChange={() => this.saveGig()}
              showEditDisplayName={this.hasEditPermissions()}
              onImageChange={(e) => this.onImageChange(e)}
              links={links}
              onShareClick={() => this.setState({ showShare: true })}
              showChat={true}
              onChatClick={() => this.setState({ showContactUsModal: true })}
            >
              <div className="actions-column">
                {!this.hasApplied() &&
                  isAcceptingApplications &&
                  !this.props.gigState.gig?.hub_id &&
                  !this.props.gigState.gig.accepting_external_application && (
                    <Button
                      text="Apply to Volunteer"
                      onClick={() => this.handleApply()}
                      buttonType="dark"
                    />
                  )}
                {isAcceptingApplications &&
                  this.props.gigState.gig?.hub_id &&
                  !this.props.gigState.gig.accepting_external_application && (
                    <PopupMenu
                      showMenuConfig={{
                        showMenu: this.state.showMenuCompanyCard,
                        setShowMenu: (showMenuCompanyCard) =>
                          this.setState({ showMenuCompanyCard }),
                        position: {
                          type: 'bottom',
                        },
                      }}
                      menuItems={this.state.companyCardMenuItems}
                      popupMenuClass="PopupMenu-Volunteer-Card-Company"
                      className={`menu ${this.state.showMenuCompanyCard ? 'show' : ''}`}
                      onClick={(e) => {
                        e?.stopPropagation?.();
                        this.setState({ showMenuCompanyCard: true });
                      }}
                      onSelect={(e) => {
                        e?.stopPropagation?.();
                        this.setState({ showMenuCompanyCard: false });
                      }}
                    >
                      <div className="interested-container">
                        {this.state.isInterested && (
                          <>
                            <i className="fas fa-star" /> I'm Interested
                          </>
                        )}
                        {this.state.isInterested === false && (
                          <>
                            <i className="far fa-star" /> I'm Not Interested
                          </>
                        )}
                        {this.state.isInterested === null && 'Are you interested?'}
                      </div>
                    </PopupMenu>
                  )}
                {this.props.gigState.gig.accepting_external_application && (
                  <Button
                    onClick={() => this.setState({ showExternalVolunteerApplicationWarning: true })}
                    text="Apply to Volunteer"
                    buttonType="dark"
                  />
                )}
                {
                  <Button
                    onClick={() => this.setState({ showAddToCalendarModal: true })}
                    text="Add To Calendar"
                    buttonType="dark"
                    imgIcon={{
                      src: CalendarIcon,
                      width: '16px',
                      height: '18px',
                      alt: 'calendar',
                    }}
                  />
                }
              </div>
            </PageHeader>
            <ContactOrganizerModal
              object_id={this.state.gig.id || ''}
              object_type={uiConstants.ownerType.gig}
              show={this.state.showContactUsModal}
              email={this.state.gig.contact_details?.email}
              phone={this.state.gig.contact_details?.phone}
              onClose={() => this.setState({ showContactUsModal: false })}
            />
          </PageSidebar>
          <PageBody>
            <PageCoverImage
              ownerType={uiConstants.ownerType.gig}
              handle={this.props.gigState.gig.handle}
              coverImageUrl={this.props.gigState.gig.cover_image_url}
              onImageChange={this.onImageChange}
              ownerObject={typeHelpers.createOwnerObject('gig', this.props.gigState.gig)}
              ShowEditCover={this.hasEditPermissions()}
              showManageButton={this.hasEditPermissions()}
              manageUrl={`/${uiConstants.ownerType.gig}/${this.props.gigState.gig.handle}/admin`}
            />

            <PageContainer isLoading={this.props.gigState.isGigPagesLoading}>
              {!this.props.gigState.gig.accepting_applications && (
                <div className="inactive-status-bar">
                  This gig is currently not accepting applications.
                </div>
              )}
              {this.state.gig.status?.code === 'inactive' &&
                this.state.userPermissions['MANAGE_GIGS'] && (
                  <div className="inactive-admin">
                    This gig is currently inactive.{' '}
                    <span
                      onClick={() => {
                        this.activateGig();
                      }}
                    >
                      Activate it now.
                    </span>
                  </div>
                )}
              <PageContent
                owner={typeHelpers.createOwnerObject('gig', this.props.gigState.gig)}
                currentPageIndex={0}
                pages={this.state.gigPages}
                pageComponents={this.props.gigState.currentPageComponents}
                permissions={this.state.userPermissions}
                isAdmin={this.hasEditPermissions()}
                routeProps={this.props}
                onUpdateComponent={this.onUpdateComponent}
                isLoading={this.props.gigState.isComponentsLoading}
                isUserStillLoggedIn={this.isUserStillLoggedIn()}
              />
            </PageContainer>
          </PageBody>
        </div>
        <Modal
          show={this.state.showLoginModal}
          onClose={() => {
            this.setState({ showLoginModal: false });
          }}
        >
          <Login
            {...this.props}
            onClose={() => {
              this.setState({ showLoginModal: false });
            }}
          />
        </Modal>
        <Modal
          class="share-modal"
          show={this.state.showShare}
          onClose={() => {
            this.setState({ showShare: false });
          }}
        >
          <Share
            owner_object={typeHelpers.createOwnerObject('gig', this.props.gigState.gig)}
            {...this.props}
            url={
              Config.web.REACT_APP_BASE_URL +
              `/${uiConstants.ownerType.gig}/` +
              this.props.gigState.gig.handle
            }
          />
        </Modal>
        <Modal
          class="modal-volunteer-form"
          show={this.state.showVolunteerFormModal}
          onClose={() =>
            this.setState({ showVolunteerFormModal: !this.state.showVolunteerFormModal })
          }
        >
          <VolunteerApplicationForm
            group={this.props.gigState?.gig?.group}
            gig={this.props.gigState?.gig}
            onClose={() =>
              this.setState({ showVolunteerFormModal: false }, () =>
                this.props.getGig(this.props.gigState.gig.id ?? ''),
              )
            }
          />
        </Modal>
        <ExternalVolunteerApplicationWarning
          show={this.state.showExternalVolunteerApplicationWarning}
          onClose={() => this.setState({ showExternalVolunteerApplicationWarning: false })}
          url={this.props.gigState.gig.external_application_url}
          isAvailable={true}
        />
        <AddToCalendarModal
          showModal={this.state.showAddToCalendarModal}
          onCloseModal={() => {
            this.setState({ showAddToCalendarModal: false });
          }}
          eventToAdd={{
            title: this.props.gigState.gig.title,
            start: this.props?.gigState?.gig?.start_date
              ? this.props.gigState.gig.start_date
              : new Date(),
            end: this.props?.gigState?.gig?.end_date
              ? this.props.gigState.gig.end_date
              : new Date(),
            location: this.props?.gigState?.gig?.locations
              ? this.props.gigState.gig.locations[0].line1 +
                ', ' +
                this.props.gigState.gig.locations[0].city +
                ', ' +
                this.props.gigState.gig.locations[0].country
              : 'N/A',
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    gigState: store.gigState,
    userState: store.userState,
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {
  getGroup,
  resetVolunteerForGroup,
  resetVolunteerForGig,
  createToast,
  getGig,
  getGigPages,
  updateGigPageComponent,
  resetGigState,
  updateGigCoverImage,
  updateGigProfileImage,
  updateGig,
  getUserGroupApplications,
  getEvent,
  changeGigStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Gig);
