import axios from 'axios';
import { Dispatch } from 'redux';
import { routes, swapRouteParams } from '../helpers';
import { IUserNotification } from '@gigit/interfaces';

export enum NotificationActionTypes {
  GET_UNREAD_NOTIFICATIONS = 'GET_UNREAD_NOTIFICATIONS',
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
  MARK_READ = 'MARK_READ',
  MARK_MULTIPLE_READ = 'MARK_MULTIPLE_READ',
  RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS',
  GET_UNREAD_QTY = 'GET_UNREAD_QTY',
}

export interface IGetNotificationsAction {
  // look to see if these interfaces exist? LOADING state too
  notifications: Array<any>;
  type: NotificationActionTypes.GET_NOTIFICATIONS;
}

export interface IGetUnreadNotificationsAction {
  unread: Array<any>;
  type: NotificationActionTypes.GET_UNREAD_NOTIFICATIONS;
}

export interface IMarkRead {
  unread: Array<any>;
  type: NotificationActionTypes.MARK_READ;
}

export interface IMarkMultipleRead {
  unread: Array<any>;
  type: NotificationActionTypes.MARK_MULTIPLE_READ;
}

export interface IResetNotifications {
  type: NotificationActionTypes.RESET_NOTIFICATIONS;
}

export interface IGetUnreadQty {
  total_unread_count: number;
  type: NotificationActionTypes.GET_UNREAD_QTY;
}

export type NotificationActions =
  | IGetNotificationsAction
  | IGetUnreadNotificationsAction
  | IMarkRead
  | IMarkMultipleRead
  | IResetNotifications
  | IGetUnreadQty;

export const getUnreadNotifications = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(routes.GET_UNREAD_NOTIFICATIONS)
      .then((response) => {
        dispatch({
          unread: response.data,
          type: NotificationActionTypes.GET_UNREAD_NOTIFICATIONS,
        });
      })
      .finally(() => {
        getUnreadQty()(dispatch, getState);
      });
  };
};

export const getNotifications = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_NOTIFICATIONS).then((response) => {
      dispatch({
        notifications: response.data,
        type: NotificationActionTypes.GET_NOTIFICATIONS,
      });
    });
  };
};

export const markRead = (notificationId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.MARK_READ, { notificationId }), { read: true })
      .then((response) => {
        let _currState = getState().notificationsState.unread as IUserNotification[];
        _currState.forEach((noti: any, index: number) => {
          if (noti.id === notificationId) {
            noti.read = true;
          }
        });
        dispatch({
          total_unread_count: _currState.filter((cs) => !cs.read).length,
          unread: _currState,
          type: NotificationActionTypes.MARK_READ,
        });
      });
  };
};

export const markMultipleRead = () => {
  return async (dispatch: Dispatch, getState: any) => {
    const payload = getState().notificationsState.unread.map((noti: any) => {
      return { id: noti.id, read: true };
    });
    axios.put(routes.MARK_MULTIPLE_READ, payload).then((response) => {});
    dispatch({
      unread: [],
      type: NotificationActionTypes.MARK_MULTIPLE_READ,
    });

    getUnreadQty()(dispatch, getState);
  };
};

export const resetNotifications = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: NotificationActionTypes.RESET_NOTIFICATIONS,
    });
  };
};

export const getUnreadQty = () => {
  return async (dispatch: Dispatch<IGetUnreadQty>, getState: any) => {
    axios.get(routes.GET_UNREAD_QTY).then((response) => {
      dispatch({
        total_unread_count: response.data.value,
        type: NotificationActionTypes.GET_UNREAD_QTY,
      });
    });
  };
};
