import React from 'react';
import TextField from '../../../TextField/TextField';
import { handleInputChange } from '../../../../helpers';

interface IProps {
  flow: string;
  user: any;

  updateUserInfo(key: string, val: string): void;
}

interface IState {
  fullName: string;
  phone: string;
}

class EmergencyContact extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      fullName: '',
      phone: '',
    };

    this.updatePhone = this.updatePhone.bind(this);
    this.updateEmergencyName = this.updateEmergencyName.bind(this);
  }

  componentDidMount() {
    this.initializeData();
  }

  initializeData() {
    if (this.props.user.emergName) {
      this.setState({
        fullName: this.props.user.emergName,
        phone: this.props.user.emergPhone,
      });
    }
  }

  updatePhone(val: string) {
    this.props.updateUserInfo('emergPhone', val);
  }

  updateEmergencyName(val: string) {
    this.props.updateUserInfo('emergName', val);
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow}`}>
        <div>
          {/* render functions */}
          <h2>Who is your emergency contact?</h2>
          <div className="details-row">
            <TextField
              value={this.state.fullName}
              name="fullName"
              label="Emergency Contact Name"
              type="text"
              onChange={(e: any) => {
                handleInputChange(e, this, false, this.updateEmergencyName, true);
              }}
            />
          </div>
          <div className="details-row">
            <TextField
              label="Phone Number (ex. 123-456-7890)"
              required
              value={this.state.phone}
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              name="phone"
              onChange={(e: any) => {
                handleInputChange(e, this, false, this.updatePhone, true);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EmergencyContact;
