import { IActivityFeedPost } from '@gigit/interfaces';
import axios from 'axios';
import React, { useState, ChangeEvent } from 'react';
import { uiConstants } from '../../../constants/uiConstants';
import { routes, swapRouteParams } from '../../../helpers';
import useToastDispatcher from '../../../hooks/useToaster';
import { IOwnerObject } from '../../../interfaces';
import { localizeHelpers } from '../../../localizeHelpers';
import { communityFeedRequestActions } from '../../../requestActions';
import Button from '../../Button/Button';
import TextArea from '../../TextArea/TextArea';
import './PostShareModal.scss';

interface IProps {
  owner_object?: IOwnerObject;
  onPostCreated: () => void;
}

const PostShareModal = (props: IProps) => {
  const { dispatchToastSuccess, dispatchToastError } = useToastDispatcher();

  const [postDescription, setPostDescription] = useState<string>('');

  function returnOwnerType() {
    switch (props.owner_object?.ownerType) {
      case uiConstants.ownerType.group:
        return 'Cause';
      case uiConstants.ownerType.event:
        return 'Event';
      case uiConstants.ownerType.gig:
        return 'Volunteer';
      case uiConstants.ownerType.hub:
        return 'Hub';
    }
  }

  function returnIcon() {
    switch (props.owner_object?.ownerType) {
      case uiConstants.ownerType.group:
        return <i className="fas fa-hands-heart" />;
      case uiConstants.ownerType.event:
        return <i className="fas fa-calendar-star" />;
      case uiConstants.ownerType.gig:
        return <i className="fas fa-hands-helping" />;
      case uiConstants.ownerType.hub:
        return <i className="fas fa-hands-heart" />;
    }
  }

  async function createPost() {
    const params = { content: postDescription };
    communityFeedRequestActions
      .createActivitySharePost(
        props.owner_object?.ownerId || props.owner_object?.object?.ownerId || '',
        props.owner_object?.ownerType,
        params,
      )
      .then((response: IActivityFeedPost[]) => {
        dispatchToastSuccess(
          localizeHelpers.translate('Post created successfully'),
          'Create a post',
        );
        props.onPostCreated();
      })
      .catch((error) => {
        dispatchToastError(error, 'Create a post');
      });
  }

  return (
    <div className="share-content create-post">
      <div className="post-preview">
        <div className="owner-details">
          {props.owner_object?.ownerProfileImageUrl ? (
            <img
              className="owner-img"
              src={props.owner_object?.ownerProfileImageUrl}
            />
          ) : (
            <div className="owner-img placeholder" />
          )}
          <div className="owner-title">
            <span
              notranslate="yes"
              className="main-title"
            >
              {props.owner_object?.ownerTitle}
            </span>
          </div>
          <div className="owner-type-badge">
            {returnIcon()}
            {returnOwnerType()}
          </div>
        </div>
        <div className="owner-cover-image">
          {props.owner_object?.ownerCoverImageUrl ? (
            <img src={props.owner_object?.ownerCoverImageUrl} />
          ) : (
            <div className="cover-placeholder" />
          )}
        </div>
      </div>
      <div className="post-description">
        <TextArea
          className="description-text-field"
          label="Post Description"
          type="text"
          value={postDescription}
          name="description"
          charLength={200}
          required={false}
          spellCheck={false}
          placeholder="Type here..."
          onChange={(e) => {
            setPostDescription(e.target.value.replace(/<\/?[^>]+(>|$)/g, ''));
          }}
        />
        <Button onClick={() => createPost()}>Post</Button>
      </div>
    </div>
  );
};

export default PostShareModal;
