import React, { FC, Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './CardCommonEvent.scss';
import { CardCommon, CardHeader, CardContent, CardActions } from '../../components/CardCommon';
import { IAddress, IEventSummaryFE, IEvent } from '@gigit/interfaces';
import { IAppState } from '../../store';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../selectors/user';
import {
  typeHelpers,
  formatCurrency,
  defaultCurrency,
  capitalizeString,
  stripHtml,
  round,
} from '../../helpers';
import { uiConstants } from '../../constants';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import PopupMenu, { IPopupMenuItem } from '../shared/PopupMenu/PopupMenu';
import { CardCommonEventSkeleton } from './CardCommonEventSkeleton';
import useSharedModal from '../../hooks/useSharedModal';
import { encodeDateTime } from 'serialize-query-params/dist/serialize';

export enum EViewTypeCardCommonEvent {
  GRID,
  LIST,
}

export enum EStyleTypeCardCommonEvent {
  MANAGE,
  DISCOVER,
  SCHEDULE,
  HUB,
}

interface IEventCommonCard extends IEventSummaryFE {
  event?: IEvent;
  item_type?: string;
  url?: string;
}
interface IProps {
  event: IEventCommonCard;
  isUserLoggedIn?: boolean;
  viewType: EViewTypeCardCommonEvent;
  styleType: EStyleTypeCardCommonEvent;
  locale?: string;
  scheduleMenuItems?: IPopupMenuItem[];
  canManage?: boolean;
  canEdit?: boolean;
  locations?: IAddress[];
  loading?: boolean;
  groupTitle?: string;
  groupHandle?: string;
  getIsFollowing?: () => Promise<boolean>;
  followItem?: () => void;
  deleteItem?: () => void;
  openDetailModal?: (event_id: string) => void;
}

export const CardCommonEvent: FC<IProps> = (props: IProps) => {
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { event } = props;
  const sharedModals = useSharedModal();
  const history = useHistory();

  function makeEventHandle(isAdmin?: boolean) {
    if (
      event.item_type === uiConstants.ownerType.individual ||
      event.item_type === uiConstants.ownerType.team
    ) {
      return `${event.url}`;
    } else if (event?.event && event.type !== uiConstants.ownerType.event) {
      const isAdminPart = isAdmin ? '/manage' : '';
      return `/${uiConstants.ownerType.event}/${event.event.handle}/${event.type}/${event.handle}${isAdminPart}`;
    } else {
      const isAdminPart = isAdmin ? '/admin' : '';
      return `/${uiConstants.ownerType.event}/${event.handle}${isAdminPart}`;
    }
  }

  function makeCardTitle() {
    if (event.item_type !== uiConstants.ownerType.individual) {
      return event.title;
    } else {
      const urlSplit = event.url?.split('/');
      if (urlSplit !== undefined) {
        const individualName = urlSplit[4];
        return capitalizeString(individualName.split('.').join(' '));
      }
    }
  }

  function showShareModal() {
    sharedModals.showShareModal({
      ownerType: uiConstants.ownerType.event as keyof typeof uiConstants.ownerType,
      ownerObject: typeHelpers.createOwnerObject(
        uiConstants.ownerType.event as keyof typeof uiConstants.ownerType,
        event,
      ),
      handle: makeEventHandle(),
      title: 'Share Event',
    });
  }

  const menuItems: IPopupMenuItem[] =
    props.styleType === EStyleTypeCardCommonEvent.MANAGE
      ? [
          {
            id: 'share',
            label: 'Share',
            onClick: (e) => {
              handleShare(e);
            },
          },
          {
            id: 'delete',
            label: 'Delete',
            onClick: () => {
              props?.deleteItem?.();
            },
          },
        ]
      : props.scheduleMenuItems || [];

  useEffect(() => {
    props.canEdit &&
      menuItems.splice(1, 0, {
        id: 'edit',
        label: 'Edit',
        onClick: () => {
          history.replace({ pathname: makeEventHandle() });
        },
      });
    props.canManage &&
      menuItems.splice(menuItems[1].id !== 'edit' ? 1 : 2, 0, {
        id: 'manage',
        label: 'Manage',
        onClick: () => {
          history.replace({ pathname: makeEventHandle(true) });
        },
      });
  });

  const showCampaignProgress = () => {
    return event?.show_campaign_progress && (event?.goal ?? 0) > 0;
  };

  const handleShare = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    user?.id ? showShareModal() : history.push('/login');
  };

  const getProgressWidth = () => {
    if (event.raised && event.goal) {
      return (event.raised / event.goal) * 100 + '%';
    }
  };

  const calcProgressPercentage = (goal: number, raised: number) => {
    if (goal && raised) {
      let percentageDecimal = raised / goal;

      let percentage = round(percentageDecimal * 100, 2);

      return percentage;
    }

    return 0;
  };

  const renderDiscoverContent = () => {
    const currency =
      event.group?.account?.currency ?? event.hub?.account?.currency ?? defaultCurrency;

    return (
      <CardContent className="event-content">
        <div className="card-title">
          <Link
            notranslate="yes"
            to={makeEventHandle()}
          >
            {event.title}
          </Link>
        </div>

        {!showCampaignProgress() && (
          <Fragment>
            {event?.description ? (
              <p className="event-description">{stripHtml(event.description)}</p>
            ) : (
              <p className="no-desc">{'No description available'}</p>
            )}
          </Fragment>
        )}
        {showCampaignProgress() && (
          <div className="progress-bar-wrap">
            <div className="progress-bar-bg">
              <div
                className="progress-bar"
                style={{ width: getProgressWidth() }}
              />
              {event.show_progress_in_percentage ? (
                <>
                  <div
                    className="amt"
                    notranslate="yes"
                  >
                    {calcProgressPercentage(event.goal || 0, event.raised || 0)}%<span>Raised</span>
                  </div>
                  <div
                    className="goal"
                    notranslate="yes"
                  >
                    100%
                    <span>Goal</span>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="amt"
                    notranslate="yes"
                  >
                    {formatCurrency(event.raised, currency, locale)}
                    <span>Raised</span>
                  </div>
                  <div
                    className="goal"
                    notranslate="yes"
                  >
                    {formatCurrency(event.goal, currency, locale)}
                    <span>Goal</span>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {renderLocation()}
      </CardContent>
    );
  };

  const renderLocation = () => {
    return (
      <div className="date-location">
        <span
          className="location"
          notranslate="yes"
        >
          <span className="icon">
            <i className="fal fa-map-marker-alt" />
          </span>
          {event.locations && event.locations[0]
            ? `${event.locations[0].city}${
                event.locations[0].state && event.locations[0].state !== ''
                  ? ', ' + event.locations[0].state
                  : ''
              }`
            : 'Online'}
        </span>
        <span
          className="date"
          notranslate="yes"
        >
          <span className="icon">
            <i className="fal fa-clock" />
          </span>
          {localizeHelpers.formatDate(event.start_date, LocaleDateFormats.LL, locale)}
        </span>
        {event.group !== undefined && (
          <span
            className="group"
            notranslate="yes"
          >
            <span className="icon">
              <i className="fal fa-users" />
            </span>
            {event.group && (
              <Link
                notranslate="yes"
                to={`/${uiConstants.ownerType.group}/${event.group.handle}`}
              >
                {event.group.title}
              </Link>
            )}
            {event?.hub && (
              <Link
                notranslate="yes"
                to={`/${uiConstants.ownerType.company}/${event.hub.handle}`}
              >
                {event.hub.title}
              </Link>
            )}
          </span>
        )}
      </div>
    );
  };

  const renderManageContent = () => {
    return (
      <CardContent className="event-content">
        <div className="card-title">
          <Link
            notranslate="yes"
            to={makeEventHandle()}
          >
            {makeCardTitle()}
          </Link>
        </div>

        {renderLocation()}

        <div className={`state-label ${event.status}`}>{capitalizeString(`${event.status}`)}</div>

        <PopupMenu
          showMenuConfig={{
            showMenu: showMenu,
            setShowMenu: setShowMenu,
            position: {
              type: 'bottom',
            },
          }}
          menuItems={menuItems}
          popupMenuClass="PopupMenu-Volunteer-Card"
          className={`menu ${showMenu ? 'show' : ''}`}
          onClick={() => setShowMenu(!showMenu)}
          onSelect={() => setShowMenu(false)}
        >
          <i className="fal fa-ellipsis-v" />
        </PopupMenu>

        <div className="one-line"></div>
      </CardContent>
    );
  };

  return (
    <React.Fragment>
      {!props.loading ? (
        <CardCommon
          className={`event-container ${EStyleTypeCardCommonEvent[props.styleType]} ${EViewTypeCardCommonEvent[props.viewType]}`}
          onClick={() => {
            if (props?.openDetailModal) props.openDetailModal(event._id);
          }}
          style={{ cursor: props?.openDetailModal ? 'pointer' : undefined }}
        >
          <CardHeader
            profileImg={event.profile_image_url}
            coverImg={event.cover_image_url}
            cardType={
              props.styleType === EStyleTypeCardCommonEvent.HUB
                ? `${localizeHelpers.translate(capitalizeString(event.type))}`
                : ''
            }
          />
          {(props.styleType === EStyleTypeCardCommonEvent.DISCOVER ||
            props.styleType === EStyleTypeCardCommonEvent.HUB ||
            props.styleType === EStyleTypeCardCommonEvent.SCHEDULE) &&
            renderDiscoverContent()}
          {props.styleType === EStyleTypeCardCommonEvent.MANAGE && renderManageContent()}
          {(props.styleType === EStyleTypeCardCommonEvent.DISCOVER ||
            props.styleType === EStyleTypeCardCommonEvent.HUB ||
            props.styleType === EStyleTypeCardCommonEvent.SCHEDULE) && (
            <CardActions
              className="event-actions"
              actions={[
                {
                  label: 'Share',
                  icon: 'fas fa-share-alt',
                  visibility: true,
                  onClick: (e) => handleShare(e),
                },
              ]}
            />
          )}
        </CardCommon>
      ) : (
        <CardCommonEventSkeleton
          styleType={props.styleType}
          viewType={props.viewType}
        />
      )}
    </React.Fragment>
  );
};
