import { Reducer } from 'redux';

import { IUser, IPage, IPageComponent, IUserStats, IMediaAlbum } from '@gigit/interfaces';

import { ProfileActions, ProfileActionTypes } from '../actions/profile';

export interface IProfileState {
  isProfileLoading: boolean;
  isProfilePagesLoading: boolean;
  isProfileStatsLoading: boolean;
  isComponentsLoading: boolean;
  user: IUser;
  pages: IPage[];
  currentPageComponents: IPageComponent[];
  mediaAlbums: IMediaAlbum[];
  profileStats: IUserStats & { [index: string]: number };
}

export const initialProfileState: IProfileState = {
  isProfileLoading: false,
  isProfilePagesLoading: false,
  isProfileStatsLoading: false,
  isComponentsLoading: false,
  user: {
    id: '',
    locations: [],
    profile_image_url: '',
    cover_image_url: '',
    gender: '',
    created_at: new Date(),
    updated_at: new Date(),
    email: '',
    first_name: '',
    last_name: '',
    display_name: '',
    dob: new Date(),
    handle: '',
  },
  pages: [],
  currentPageComponents: [],
  mediaAlbums: [],
  profileStats: {
    dollars_earned: 0,
    donations_made: 0,
    paid_gigs: 0,
    volunteer_gigs: 0,
    volunteer_hours: 0,
  },
};

export const profileReducer: Reducer<IProfileState, ProfileActions> = (
  state = initialProfileState,
  action,
) => {
  switch (action.type) {
    case ProfileActionTypes.UPDATE_PROFILE: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
