import { HubPermissions } from '@gigit/constants';
import { IHub } from '@gigit/interfaces';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from '../../../../hooks/useDebounce';
import useToastDispatcher from '../../../../hooks/useToaster';
import { userRequestActions } from '../../../../requestActions';
import Portrait from '../../../Portrait/Portrait';
import TextField from '../../../TextField/TextField';
import { FormSectionHeader } from '../FormSectionHeader/FormSectionHeader';

import './HubSelection.scss';

interface IProps {
  selectedHub: IHub | null;
  navigate(url: string): void;
  setHub(hub: IHub): void;
}

export const HubSelection: React.FC<IProps> = (props: IProps) => {
  const { selectedHub, navigate, setHub } = props;
  const [hubs, setHubs] = useState<IHub[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const { dispatchToastError } = useToastDispatcher();
  const searchValue = useDebounce(inputValue);

  const history = useHistory();

  useEffect(() => {
    getHubs();
  }, []);

  async function getHubs(params?: URLSearchParams) {
    try {
      const hubs = await userRequestActions.getUserHubsForPermission(
        HubPermissions.MANAGE_HUB_EVENTS,
      );
      setHubs(hubs);
    } catch (error) {
      dispatchToastError(error, 'Hubs');
    }
  }

  function renderHubTemplate(hub: IHub) {
    return (
      <div
        key={hub.id}
        className={`hub-selection-item ${selectedHub && hub.id === selectedHub?.id ? 'selected' : ''}`}
        onClick={() => setHub(hub)}
      >
        <Portrait
          source={hub.profile_image_url}
          size={40}
        />
        <div className="title-section">
          <div
            notranslate="yes"
            className="title"
          >
            {hub.title}
          </div>
        </div>
      </div>
    );
  }

  const hubsList = hubs.filter((hub) =>
    hub.title?.toUpperCase().includes(searchValue.toUpperCase()),
  );
  return (
    <div className="HubSelection">
      <FormSectionHeader text="Select Company" />
      <div className="filter-wrapper">
        <TextField
          placeholder="Search"
          value={inputValue}
          name="name"
          type="text"
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
      </div>
      <div className="hubs-selection">
        {hubsList.map((hub) => {
          return renderHubTemplate(hub);
        })}
        {hubsList.length === 0 && (
          <div className="empty-hub-list">
            No companies found.
            <span
              className="empty-cta"
              onClick={() => history.push('/onboarding/company')}
            >
              {' '}
              Get started by creating a company page.
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
