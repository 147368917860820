import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { IUserRole, IPledge } from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { IGroupState } from '../../../reducers/group';
import { IAppState } from '../../../store';
import { createToast } from '../../../actions/toaster';
import { routes, swapRouteParams, handleInputChange, toastError } from '../../../helpers';

import TextField from '../../TextField/TextField';
import Button from '../../Button/Button';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';
import { userSelectors } from '../../../selectors/user';
import DateTimePicker from '../../shared/DateTimePicker/DateTimePicker';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  pledge?: IPledge;
  locale: string;

  onClose(data: any | null): void;
  createToast(toast: IToast): void;
}

interface IState {
  start_date: any;
  end_date: any;
  amt: string;
}

class AddPledge extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      start_date: moment(),
      end_date: moment(),
      amt: '',
    };

    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
  }

  componentDidMount() {
    if (this.props.pledge) {
      this.setState({
        start_date: this.props.pledge?.start_date,
        end_date: this.props.pledge?.end_date,
        amt: this.props.pledge?.total_yearly_amount?.toString() || '',
      });
    }
  }

  handlePledge() {
    if (this.props.pledge?.id) {
      this.editPledge(this.props.pledge.id);
    } else {
      this.createPledge();
    }
  }

  createPledge() {
    let payload = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      total_yearly_amount: this.state.amt,
    };

    axios
      .post(
        swapRouteParams(routes.CREATE_GROUP_PLEDGE, {
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user?.id,
        }),
        payload,
      )
      .then((response) => {
        this.props.onClose(response);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Create Pledge');
        this.props.createToast(toast);
      });
  }

  editPledge(id: string) {
    let payload = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      total_yearly_amount: this.state.amt,
    };

    axios
      .put(
        swapRouteParams(routes.DELETE_PLEDGE, {
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user?.id,
          pledgeId: id,
        }),
        payload,
      )
      .then((response) => {
        this.props.onClose(response);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Update Pledge');
        this.props.createToast(toast);
      });
  }

  handleStartDate(value: any) {
    this.setState({
      start_date: value,
    });
  }

  handleEndDate(value: any) {
    this.setState({
      end_date: value,
    });
  }

  render() {
    let { t } = this.props;

    return (
      <div className="AddPledge">
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
            this.handlePledge();
          }}
          className="af"
        >
          <div className="af-title">{t('Add Pledge')}</div>
          <div className="af-subtitle">{t('Pledge Information')}</div>
          <label>{t('Start Date & Time')}</label>
          <DateTimePicker
            inputProps={{ placeholder: t('Communication Date'), readOnly: true }}
            className="custom-dtp"
            value={this.state.start_date}
            onChange={this.handleStartDate}
            locale={this.props.locale}
          />
          <label>{t('End Date & Time')}</label>
          <DateTimePicker
            inputProps={{ placeholder: t('Communication Date'), readOnly: true }}
            className="custom-dtp"
            value={this.state.end_date}
            onChange={this.handleEndDate}
            locale={this.props.locale}
          />
          <TextField
            label={t('Pledge Amount')}
            type="text"
            value={this.state.amt}
            name="amt"
            onChange={(e: any) => {
              handleInputChange(e, this, false);
            }}
          />
          <div className="af-actions">
            <Button text={t('Save')} />
            <Button
              text={t('Cancel')}
              onClick={(e: any) => {
                e.preventDefault();
                this.props.onClose(null);
              }}
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(AddPledge),
);
