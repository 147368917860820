import React, { useState } from 'react';
import { IThemeStyleObjectsByType } from '../../../../Hub/Hub';
import './CommunityFeedAsideCard.scss';
import Loader from '../../../../Loader/Loader';
import CommunityFeedAsideCardSkeleton from './CommunityFeedAsideCardSkeleton';

interface IProps {
  title: string;
  initialCountToShow: number;
  showViewMore: boolean;
  flexDirection: 'row' | 'column';
  contentData: React.ReactNode[];
  emptyStateData?: React.ReactNode;
  goToData?: React.ReactNode;
  theme?: IThemeStyleObjectsByType;
  forceEmptyState?: boolean;
  loading?: boolean;
}

const CommunityFeedAsideCard: React.FC<IProps> = (props) => {
  const [toShow, setToShow] = useState<number>(props.initialCountToShow);

  const renderContent = (content: React.ReactNode[]): React.ReactNode[] => {
    const tmp: React.ReactNode[] = [];
    for (let i = 0; i < toShow; i++) {
      tmp.push(props.contentData[i]);
    }

    return tmp;
  };

  if (props?.loading) {
    return <CommunityFeedAsideCardSkeleton />;
  } else
    return (
      <section className="CommunityFeedAsideCard">
        <h4 className="title">{props.title}</h4>
        <div
          className="content"
          style={{ flexDirection: props.flexDirection }}
        >
          {!props?.loading &&
            (props.contentData.length < 1 || props.forceEmptyState) &&
            props.emptyStateData}

          {!props?.loading &&
            props.contentData.length > 0 &&
            !props?.forceEmptyState &&
            renderContent(props.contentData)}

          {(!props?.loading && props?.goToData) || null}
        </div>
        {props.showViewMore && (
          <span
            className="view-more"
            //style={props.theme?.secondaryText}
            onClick={() => {
              setToShow(
                toShow === props.initialCountToShow
                  ? props.contentData.length
                  : props.initialCountToShow,
              );
            }}
          >
            {toShow === props.initialCountToShow ? 'View More' : 'View Less'}
          </span>
        )}
      </section>
    );
};

export default CommunityFeedAsideCard;
