import { Config } from '@gigit/config';
import { Constants } from '@gigit/constants';
import { IAttachmentPutReturn } from '@gigit/interfaces';
import _ from 'lodash';
import { localizeHelpers } from '../localizeHelpers';
import * as mimeHelper from 'mime-types';

type FileUnitType = 'byte' | 'kilobyte' | 'megabyte' | 'gigabyte';

type ByteUnitConversionDictionary = Record<
  FileUnitType,
  {
    title: string;
    size: number;
  }
>;

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

/** Creates a valid MIME extension type based on our `Constants.attachment_types` @example "jpg" -> ".jpg" */
export type GlobalAcceptedFileType = `.${keyof typeof Constants.attachment_types}`;

type FileValidationOptions = {
  /** Specify the file size, as long as it's under our global limit */
  maxFileSizeInBytes?: number;
  /** Accepts an array of any of our global supported types */
  acceptedTypes?: Array<GlobalAcceptedFileType>;
};

type FileValidationReturnType = { isValid: boolean; file: File; error: string | null };

type AttachmentWithFile = IAttachmentPutReturn & { file: File | undefined };

const bytesDictionary: ByteUnitConversionDictionary = {
  byte: {
    title: 'B',
    size: 1,
  },
  kilobyte: {
    title: 'KB',
    size: 1000,
  },
  megabyte: {
    title: 'MB',
    size: 1000 ** 2,
  },
  gigabyte: {
    title: 'GB',
    size: 1000 ** 3,
  },
};

export namespace fileHelpers {
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
  export const FILE_TYPE_DESCRIPTIONS: Record<GlobalAcceptedFileType, string> = {
    '.jpeg': 'JPEG image',
    '.jpg': 'JPG image',
    '.png': 'PNG image',
    '.pdf': 'PDF document',
    // ".gif": "GIF image",
    // ".doc": "Microsoft Word document (.doc)",
    // ".docx": "Microsoft Word document (.docx)",
    // ".txt": "Unformatted text file (.txt)",
    // ".rtf": "Rich Text Format file (.rtf)",
    // ".xls": "Microsoft Excel spreadsheet (.xls)",
    // ".xlsx": "Microsoft Excel spreadsheet (.xlsx)",
    // ".ppt": "Microsoft PowerPoint presentation (.ppt)",
    // ".pptx": "Microsoft PowerPoint presentation (.pptx)",
  };

  export const GLOBAL_MAX_FILE_SIZE_IN_BYTES =
    convertFileSizeToBytes(Config.api_storage.max_file_size_mb, 'megabyte') ||
    convertFileSizeToBytes(50, 'megabyte');
  /** An array of valid MIME extension types based on Constants.attachment_types */
  export const GLOBAL_ACCEPTED_FILE_TYPES = Object.keys(Constants.attachment_types).map(
    (type) => `.${type}`,
  ) as Array<GlobalAcceptedFileType>;

  /**
   * @param fileTypes Accepts an array of file types E.g: [".jpg", ".pdf", ...]
   * @returns Comma delimitated descriptions based on fileTypeDescriptions
   */
  export function getAcceptedFileTypesMessage(
    acceptedTypes: Array<GlobalAcceptedFileType>,
  ): string {
    const descriptions = acceptedTypes.map((fileType) => FILE_TYPE_DESCRIPTIONS[fileType]);

    return descriptions.join(', ');
  }

  export function convertFileSizeFromBytes(bytes: number, unitType: FileUnitType) {
    return Math.round(bytes / bytesDictionary[unitType].size);
  }

  export function convertFileSizeToBytes(size: number, fromUnitType: FileUnitType) {
    if (fromUnitType === 'byte') return size;

    return Math.round(size * bytesDictionary[fromUnitType].size);
  }

  export function getUnitTitle(unitType: FileUnitType) {
    return bytesDictionary[unitType].title;
  }

  /**
   * Builds a valid GlobalAcceptedFileType string from a 'accepts' string.
   *
   * @example getValidAcceptsString(".invalidExt,.jpg,.jpeg") -> ".jpg,.jpeg"
   * */
  export function getValidAcceptsString(accepts?: string) {
    const acceptedTypes = GLOBAL_ACCEPTED_FILE_TYPES as Array<string>;

    if (accepts) {
      return accepts
        .split(',')
        .map((type) => type.trim())
        .filter((type) => acceptedTypes.includes(type))
        .join(',');
    } else {
      return acceptedTypes.join(',');
    }
  }

  /**
   * Builds a valid GlobalAcceptedFileType array from a 'accepts' string.
   *
   * @example getValidAcceptsArray(".invalidExt,.jpg,.jpeg") -> [".jpg", ".jpeg"]
   */
  export function getValidAcceptsArray(accepts?: string) {
    const validAcceptsString = getValidAcceptsString(accepts);
    return validAcceptsString.split(',') as Array<GlobalAcceptedFileType>;
  }

  /**
   * Validates a given file and returns it, else it returns the error message.
   *
   * @param validationOptions
   * @returns Error message if not valid, else returns File.
   */
  export function validateFile(
    file: File,
    validationOptions?: FileValidationOptions,
  ): FileValidationReturnType {
    const acceptedTypes = validationOptions?.acceptedTypes || GLOBAL_ACCEPTED_FILE_TYPES;
    const acceptedFileSize =
      !!validationOptions?.maxFileSizeInBytes &&
      validationOptions.maxFileSizeInBytes <= GLOBAL_MAX_FILE_SIZE_IN_BYTES
        ? validationOptions.maxFileSizeInBytes
        : GLOBAL_MAX_FILE_SIZE_IN_BYTES;

    if (file.size > acceptedFileSize) {
      return {
        file,
        isValid: false,
        error: localizeHelpers.translate(
          `File {{file}} is too large. Maximum file size is {{maxSize}}`,
          {
            file: file.name,
            maxSize: convertFileSizeFromBytes(acceptedFileSize, 'megabyte') + 'MB',
          },
        ),
      };
    }

    //If input file type is not in acceptedTypes
    const mimeToExtension = `.${file.type.split('/')[1]}`;
    if (!(acceptedTypes as string[]).includes(mimeToExtension)) {
      return {
        file,
        isValid: false,
        error: localizeHelpers.translate(
          `File {{file}} is not a valid file type. Valid file types are {{validTypes}}`,
          {
            file: file.name,
            validTypes: getAcceptedFileTypesMessage(acceptedTypes),
          },
        ),
      };
    }

    return {
      file,
      isValid: true,
      error: null,
    };
  }

  export async function uploadFileAttachment(attachment: AttachmentWithFile) {
    const { file, type, s3_key, presigned_put_url } = attachment;

    if (!file) throw new Error('File is not defined');

    const response = await fetch(presigned_put_url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': type,
      },
    });
    // TODO: Figure out how to bypass our axios interceptor
    // const response = await axios.put(presigned_put_url, file, {
    //     transformRequest: (data, headers) => {
    //         delete headers.common['Authorization'];
    //         delete headers.common['Content-Type'];
    //         delete headers.common['Access-Control-Allow-Origin'];
    //         delete headers.common['Accept'];

    //         return data;
    //     },
    //     headers: {
    //         "Content-Type": type,
    //     }
    // });

    return response;
  }

  /**
   * Get a mime type from extention.
   */
  export function getMimeFromExtention(extention: string): string {
    return mimeHelper.lookup(extention) as string;
  }

  export async function uploadFileAttachments(
    attachments: IAttachmentPutReturn[],
    filesToUpload: File[],
  ) {
    const attachmentWithFile = attachments.map((attachment) => {
      const file = filesToUpload.find((file) => file.name === attachment.name);

      return {
        ...attachment,
        file,
      };
    });

    return await Promise.all(
      attachmentWithFile.map(async (attachment) => await uploadFileAttachment(attachment)),
    );
  }
}
