import { useContext } from 'react';
import {
  ISharedModalShareData,
  SharedModalContext,
  ESharedModalType,
  ISharedModalVolunteerApplicationFormData,
} from '../contexts/SharedModalContext';

const useSharedModal = () => {
  const context = useContext(SharedModalContext);

  function showShareModal(data: ISharedModalShareData) {
    context.setState?.({
      ...context.state,
      shareModalContent: data,
      className: 'share-modal ' + data.modalClassName,
      modalType: ESharedModalType.SHARE,
      showModal: true,
    });
  }

  function showVolunteerApplicationForm(data: ISharedModalVolunteerApplicationFormData) {
    context.setState?.({
      ...context.state,
      title: 'Applicant Form',
      className: 'volunteer-application-form-modal',
      volunteerApplicationFormModalContent: data,
      modalType: ESharedModalType.VOLUNTEER_APPLICATION_FORM,
      showModal: true,
    });
  }

  return {
    showVolunteerApplicationForm,
    showShareModal,
    context,
  };
};

export default useSharedModal;
