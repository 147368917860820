import React, { ChangeEvent, FC, useState } from 'react';
import './HubSeatPurchaseModal.scss';
import { localizeHelpers } from '../../../../../localizeHelpers';
import useToastDispatcher from '../../../../../hooks/useToaster';
import Button from '../../../../Button/Button';
import Loader from '../../../../Loader/Loader';
import Modal from '../../../../Modal/Modal';
import TextField from '../../../../TextField/TextField';
import { IAvailablePremiumFeature, IHub, IOrgPremiumFeaturesSummary } from '@gigit/interfaces';
import { useEffect } from 'react';
import { Constants } from '@gigit/constants';
import { routes, swapRouteParams } from '../../../../../helpers';
import axios from 'axios';
import { PaymentComponent } from '../../../../shared/PaymentComponent/PaymentComponent';
import { usePaymnetInfo } from '../../../../shared/Onboarding/hooks/usePaymentInfo';
import { billingRequestActions } from '../../../../../requestActions';

interface IProps {
  show: boolean;
  onClose(): void;
  onPurchase(id: string, seats?: number): void;
  hub?: IHub;
  pricing: IAvailablePremiumFeature | null;
  defaultPaymentMethodId: string;
  currentPackage: string;
  subscriptions?: IOrgPremiumFeaturesSummary;
  isSubscription?: boolean;
  newSubscriptionPackage?: string;
}

const SHARED_PLAN = Constants.billing.subscriptions.share_package.code;
const DO_YEARLY = Constants.billing.subscriptions.hub_seating_do.code;
const HUB_SEATING_GIVE = Constants.billing.subscriptions.hub_seating.code;

const HubSeatPurchaseModal: FC<IProps> = (props: IProps) => {
  const { paymentInfo, handlePaymentInfo } = usePaymnetInfo();
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const [purchaseLoading, setPurchaseLoading] = useState<boolean>(false);
  const [seatCounter, setSeatCounter] = useState<number>(1);
  const [minSeats, setMinSeats] = useState<number>(1);
  const [seatsUsed, setSeatsUsed] = useState<number>(0);
  const [totalSeats, setTotalSeats] = useState<number>(0);
  const [payedSeats, setPayedSeats] = useState<number>(0);
  const [triggerPaymentPrep, setTriggerPaymentPrep] = useState<boolean>(false);

  useEffect(() => {
    if (props.currentPackage === SHARED_PLAN) {
      setSeatCounter(20);
      setMinSeats(20);
    }
  }, [props.currentPackage, props.newSubscriptionPackage]);

  useEffect(() => {
    if (props.subscriptions) {
      const seatsFeature = props.subscriptions.features.find((feature) =>
        [
          Constants.billing.subscriptions.hub_seating.code,
          Constants.billing.subscriptions.hub_seating_do.code,
        ].includes(feature.feature_code),
      );
      setSeatsUsed(seatsFeature?.seats?.used || 0);
      setPayedSeats(seatsFeature?.seats?.payed || 0);
      setTotalSeats(seatsFeature?.seats?.total || 0);
    }
  }, [props.subscriptions]);

  async function purchase() {
    setPurchaseLoading(true);
    try {
      const payload = {
        allocate_quantity: seatCounter,
      };

      let resp = await axios.post(
        swapRouteParams(routes.PURCAHSE_HUB_SEATS, {
          hub_id: props.hub?.id,
          subscription_code: props.pricing?.product_code,
        }),
        payload,
      );
      if (props.hub?.id) {
        props.onPurchase(props.hub.id, payload.allocate_quantity);
        setSeatCounter(1);
        props.onClose();
      }
    } catch (err) {
      setPurchaseLoading(false);
      if (minSeats == seatCounter) {
        dispatchToastError(
          localizeHelpers.translate(
            `You haven't purchased any new seats, you already own {{seats}} seats.`,
            {
              seats: seatCounter,
            },
          ),
          'Purchase Seats',
        );
      } else {
        dispatchToastError(err, 'Purchase Seats');
      }
    }
    setPurchaseLoading(false);
  }

  function getSeatCounterPrice(seatCount: number, price: number) {
    if (isNaN(seatCount * price)) {
      return 0;
    } else {
      return seatCount * price;
    }
  }

  async function handlePaymentMethodId(id: string) {
    if (id) {
      if (props.isSubscription && props.pricing) {
        if (id == props.defaultPaymentMethodId) {
          await purchase();
          return;
        }
        try {
          if (paymentInfo.isNewMethod) {
            let payload = {
              payment_method_id: id,
            };

            const response = await axios.post(routes.GET_PAYMENT_METHODS, payload);
            let confirm = await paymentInfo.stripe?.confirmCardSetup(response.data.client_secret);

            if (confirm && confirm.error) {
              dispatchToastError(
                localizeHelpers.translate(confirm.error.message),
                'Payment Method',
              );
              return;
            }
          }

          if (props.newSubscriptionPackage) {
            if (!props.defaultPaymentMethodId) {
              const payload = {
                pricing_tier: (Constants.billing.subscriptions as any)[props.newSubscriptionPackage]
                  .tier,
                payment_method_id: id,
              };
              await billingRequestActions.updateHubSubscription(
                props.hub?.id!,
                props.currentPackage,
                payload,
              );
              if (props.hub?.id) {
                props.onPurchase(props.hub.id, seatCounter);
                // setSeatCounter(1);
                props.onClose();
              }
            } else {
              const payload = {
                pricing_tier: (Constants.billing.subscriptions as any)[props.newSubscriptionPackage]
                  .tier,
                payment_method_id: id,
              };
              await billingRequestActions.updateHubSubscription(
                props.hub?.id!,
                props.currentPackage,
                payload,
              );
              dispatchToastSuccess(
                localizeHelpers.translate('Payment method changed successfully.'),
                'Payment Method',
              );
              // await purchase()
            }
          }
        } catch (error) {
          dispatchToastError(error, 'Payment Method');
        }
      } else {
        if (id == props.defaultPaymentMethodId) {
          await purchase();
          return;
        }

        try {
          if (paymentInfo.isNewMethod) {
            let payload = {
              payment_method_id: id,
            };

            const response = await axios.post(routes.GET_PAYMENT_METHODS, payload);
            let confirm = await paymentInfo.stripe?.confirmCardSetup(response.data.client_secret);

            if (confirm && confirm.error) {
              dispatchToastError(
                localizeHelpers.translate(confirm.error.message),
                'Payment Method',
              );
              return;
            }
          }

          if (
            !props.defaultPaymentMethodId &&
            props.pricing &&
            props.currentPackage == Constants.billing.subscriptions.share_package.code
          ) {
            const payload = {
              subscription_code: props.pricing?.product_code,
              payment_method_id: id,
              number_of_seats: seatCounter,
            };
            await billingRequestActions.createHubSeatSubscription(
              props.hub?.id!,
              props.pricing.product_code,
              payload,
            );
            if (props.hub?.id) {
              props.onPurchase(props.hub.id, seatCounter);
              setSeatCounter(1);
              props.onClose();
            }
          } else {
            await billingRequestActions.updateHubSubscription(
              props.hub?.id!,
              props.currentPackage,
              { payment_method_id: id },
            );
            dispatchToastSuccess(
              localizeHelpers.translate('Payment method changed successfully.'),
              'Payment Method',
            );
            await purchase();
          }
        } catch (error) {
          dispatchToastError(error, 'Payment Method');
        }
      }
    }
  }

  function getSeatsValue(): number {
    return totalSeats > 1 ? totalSeats - 1 : 0;
  }

  return (
    <Modal
      show={props.show}
      onClose={() => {
        setSeatCounter(1);
        props.onClose();
      }}
      class="SeatPurchaseModal"
    >
      <div className="seat-purchase-content">
        <div className="seat-purchase-details">
          <div className="details-title">
            {props.isSubscription ? 'Upgrade Package' : 'Purchase Seats'}
          </div>
          {props.isSubscription ? (
            <div className="details-payment">
              <div className="details-sub-title">Subscription Package</div>
              <div className="">
                <div className="details-row sub-change">
                  <div className="title">
                    {props.newSubscriptionPackage === DO_YEARLY ? 'Do Package' : 'Give Package'}
                  </div>
                  <div className="pricing">
                    <span className="price-number">
                      $
                      {props.newSubscriptionPackage === DO_YEARLY
                        ? Constants.billing.subscriptions.hub_seating_do.price_in_cents / 100
                        : Constants.billing.subscriptions.hub_seating.price_in_cents / 100}
                    </span>
                    <span className="price-description">/mo per user</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="details-payment">
                <div className="details-sub-title">Existing Seats</div>
                <div className="details-row">
                  <div className="month-price">
                    <span
                      notranslate="yes"
                      className="price-amount"
                    >
                      {!isNaN(totalSeats) ? totalSeats || 1 : 'n/a'}
                    </span>{' '}
                    / Seats
                  </div>
                  <div className="month-price">
                    <span
                      notranslate="yes"
                      className="price-amount"
                    >
                      {props.pricing
                        ? `$${getSeatCounterPrice(getSeatsValue(), props.pricing.monthly_price)}`
                        : 'n/a'}
                    </span>{' '}
                    / Month
                  </div>
                  <div className="annual-price">
                    <span
                      notranslate="yes"
                      className="price-amount"
                    >
                      {props.pricing
                        ? `$${getSeatCounterPrice(getSeatsValue(), props.pricing.annual_price)}`
                        : 'n/a'}
                    </span>{' '}
                    / Billed Annually
                  </div>
                </div>
              </div>
              <div className="details-payment counter">
                <div className="details-sub-title">Add Additional Seats</div>
                <div className="details-row">
                  <div className="counter">
                    <div
                      onClick={() => {
                        if (seatCounter > minSeats) {
                          setSeatCounter(seatCounter - 1);
                        } else {
                          return;
                        }
                      }}
                      className={`counter-button sub ${seatCounter == minSeats ? 'disabled' : ''}`}
                    >
                      <i className="fa fa-minus" />
                    </div>
                    <TextField
                      className="counter-input"
                      value={seatCounter}
                      name={'seat-counter'}
                      type={'number'}
                      onKeyDown={(e) => {
                        if (e.key == 'ArrowDown' || e.key == 'ArrowUp') {
                          e.preventDefault();
                          e.stopPropagation();
                          return;
                        }
                      }}
                      onChange={function (e: ChangeEvent<HTMLInputElement>): void {
                        if (parseInt(e.target.value) > 1000000) {
                          setSeatCounter(1000000);
                        } else {
                          if (parseInt(e.target.value) < minSeats) {
                            dispatchToastError(
                              localizeHelpers.translate(
                                'Seat amount cannot be less than already purchased',
                              ),
                              'Purchase Seats',
                            );
                            setSeatCounter(minSeats);
                          } else {
                            setSeatCounter(parseInt(e.target.value));
                          }
                        }
                      }}
                    />
                    <div
                      onClick={() => {
                        if (seatCounter <= 1000000) {
                          setSeatCounter(seatCounter + 1);
                        } else {
                          return;
                        }
                      }}
                      className={`counter-button add ${seatCounter == 1000000 ? 'disabled' : ''}`}
                    >
                      <i className="fa fa-plus" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="details-payment">
                <div className="details-row">
                  <div className="month-price">
                    <span
                      notranslate="yes"
                      className="price-amount"
                    >
                      {seatCounter}
                    </span>{' '}
                    / Seats
                  </div>
                  <div className="month-price">
                    <span
                      notranslate="yes"
                      className="price-amount"
                    >
                      {props.pricing
                        ? `$${getSeatCounterPrice(seatCounter, props.pricing.monthly_price)}`
                        : 'n/a'}
                    </span>{' '}
                    / Month
                  </div>
                  <div className="annual-price">
                    <span
                      notranslate="yes"
                      className="price-amount"
                    >
                      {props.pricing
                        ? `$${getSeatCounterPrice(seatCounter, props.pricing.annual_price)}`
                        : 'n/a'}
                    </span>{' '}
                    / Billed Annually
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="details-payment last">
            <PaymentComponent
              title=""
              showBillingForm={true}
              priceDetails={[]}
              defaultPaymentMethodId={
                props.defaultPaymentMethodId ? props.defaultPaymentMethodId : ''
              }
              notCreationFlow={true}
              selectDefaultMethod={true}
              paymentInfo={paymentInfo}
              onPaymentInfoUpdated={handlePaymentInfo}
              onPaymentMethodReady={handlePaymentMethodId}
              onMethodChange={() => {}}
              preparePaymentMethod={triggerPaymentPrep}
            />
          </div>
        </div>
        <div className="hub-seats-actions">
          <Button
            onClick={() => {
              setSeatCounter(1);
              props.onClose();
            }}
            className="seat-cancel"
          >
            Cancel
          </Button>
          {props.isSubscription ? (
            <Button
              className="seat-purchase"
              onClick={async () => {
                if (
                  (!purchaseLoading && !props.defaultPaymentMethodId) ||
                  paymentInfo.isNewMethod
                ) {
                  setTriggerPaymentPrep(!triggerPaymentPrep);
                }

                // If subscription doesn't have payment method, then we can't allocate seats.
                // If a payment method is provided, it should be handled by `handlePaymentMethodId()`
                if (props.defaultPaymentMethodId) {
                  await purchase();
                }
              }}
            >
              {purchaseLoading ? (
                <div className="payment-loading">
                  <Loader loading={purchaseLoading} />
                </div>
              ) : (
                `Purchase Upgrade`
              )}
            </Button>
          ) : (
            <Button
              className="seat-purchase"
              onClick={async () => {
                if (
                  (!purchaseLoading && !props.defaultPaymentMethodId) ||
                  paymentInfo.isNewMethod
                ) {
                  setTriggerPaymentPrep(!triggerPaymentPrep);
                }

                // If subscription doesn't have payment method, then we can't allocate seats.
                // If a payment method is provided, it should be handled by `handlePaymentMethodId()`
                if (props.defaultPaymentMethodId) {
                  await purchase();
                }
              }}
            >
              {purchaseLoading ? (
                <div className="payment-loading">
                  <Loader loading={purchaseLoading} />
                </div>
              ) : (
                ` ${seatCounter} ${seatCounter > 1 ? 'Seats' : 'Seat'}`
              )}
            </Button>
          )}
        </div>
        <div className="seat-purchase-summary">
          <div className="hub-banner">
            <img
              src={props.hub?.banner?.image || ''}
              alt="hub-banner"
            />
            <span
              onClick={() => {
                setSeatCounter(1);
                props.onClose();
              }}
              className="close-icon"
            >
              <i className="fas fa-times" />
            </span>
          </div>
          <div className="hub-profile-img">
            <img
              src={props.hub?.profile_image_url}
              alt="hub-profile-img"
            />
          </div>
          <div className="hub-summary-details">
            <span
              title={props.hub?.title}
              notranslate="yes"
              className="summary-details-title"
            >
              {props.hub?.title}
            </span>
            <span
              className="summary-details-description"
              notranslate="yes"
            >
              {props.hub?.banner?.banner_info?.description
                ? props.hub?.banner.banner_info.description
                : 'No description available.'}
            </span>
          </div>
          {props.isSubscription && props.newSubscriptionPackage ? (
            <>
              <div className="hub-summary-price">
                <div className="hub-summary-item">
                  <div>Prorated Cost For Upgrade</div>
                  <div notranslate="yes">
                    $
                    {props.pricing
                      ? getSeatCounterPrice(
                          getSeatsValue(),
                          (Constants.billing.subscriptions as any)[props.newSubscriptionPackage]
                            .price_in_cents,
                        )
                      : 'n/a'}
                  </div>
                </div>
                <div className="hub-summary-item">
                  <div>New Annual Cost</div>
                  <div notranslate="yes">
                    $
                    {props.pricing
                      ? getSeatCounterPrice(seatCounter, props.pricing.annual_price)
                      : 'n/a'}
                  </div>
                </div>
              </div>
              <div className="hub-summary-total">
                <div>Total Amount Due</div>
                <div notranslate="yes">
                  $
                  {props.pricing
                    ? getSeatCounterPrice(seatCounter, props.pricing.annual_price)
                    : 'n/a'}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="hub-summary-price">
                <div className="hub-summary-item">
                  <div>
                    {totalSeats || 1} Existing {totalSeats > 1 ? 'Seats' : 'Seat'}
                  </div>
                  <div notranslate="yes">
                    $
                    {props.pricing
                      ? getSeatCounterPrice(getSeatsValue(), props.pricing.annual_price)
                      : 'n/a'}
                  </div>
                </div>
                <div className="hub-summary-item">
                  <div>
                    {isNaN(seatCounter) ? 0 : seatCounter} Additional{' '}
                    {seatCounter > 1 ? 'Seats' : 'Seat'}
                  </div>
                  <div notranslate="yes">
                    $
                    {props.pricing
                      ? getSeatCounterPrice(seatCounter, props.pricing.annual_price)
                      : 'n/a'}
                  </div>
                </div>
              </div>
              <div className="hub-summary-total">
                <div>Revised Annual Billing Amount</div>
                <div notranslate="yes">
                  $
                  {props.pricing
                    ? getSeatCounterPrice(getSeatsValue(), props.pricing.annual_price) +
                      getSeatCounterPrice(seatCounter, props.pricing.annual_price)
                    : 'n/a'}
                </div>
              </div>
              <div className="hub-summary-total">
                <div>Total Costs</div>
                <div notranslate="yes">
                  $
                  {props.pricing
                    ? getSeatCounterPrice(seatCounter, props.pricing.annual_price)
                    : 'n/a'}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default HubSeatPurchaseModal;
