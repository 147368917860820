import React, { ChangeEvent, Fragment } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import queryString from 'query-string';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {
  handleInputChange,
  toastError,
  capitalizeString,
  typeHelpers,
  swapRouteParams,
  routes,
  combineClassNames,
} from '../../helpers';
import errorHelpers from '../../helpers/errorHelpers';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IGroupState } from '../../reducers/group';
import { IEventState } from '../../reducers/event';
import { IGigState } from '../../reducers/gig';
import { createToast } from '../../actions/toaster';
import { getEventGigs } from '../../actions/event';
import { getGroupGigs } from '../../actions/group';
import {
  removeShift,
  createShift,
  getShifts,
  assignUserToShift,
  removeUserFromShift,
  getGig,
} from '../../actions/gig';
import {
  getGroup,
  getGroupApplications,
  approveGroupApplicationManagement,
  rejectGroupApplicationManagement,
} from '../../actions/group';
import ContextMenu from '../../components/ContextMenu/ContextMenu';
import Portrait from '../../components/Portrait/Portrait';
import Button from '../../components/Button/Button';
import Dropdown, { IOptions } from '../../components/Dropdown/Dropdown';
import Modal from '../../components/Modal/Modal';
import Loader from '../../components/Loader/Loader';
import Checkbox from '../../components/Checkbox/Checkbox';
import './Scheduler.scss';
import {
  IApplication,
  IShiftRecurrence,
  IShift,
  IShiftUser,
  IUser,
  IVolunteer,
} from '@gigit/interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { IOwnerObject, IToast } from '../../interfaces';
import { userSelectors } from '../../selectors/user';
import { Prompt } from '../Prompt/Prompt';
import { uiConstants } from '../../constants/uiConstants';
import { AssignedUsersModal } from './AssignedUsersModal/AssignedUsersModal';
import ShiftManage from '../ShiftManage/ShiftManage';
import TextField from '../TextField/TextField';
import DatePicker from '../DatePicker/DatePicker';
import { IUserState } from '../../reducers/user';
import { groupRequestActions, hubRequestActions } from '../../requestActions';

interface IProps extends RouteComponentProps<any> {
  getGig(handleOrId: string): void;
  removeShift(gigId: string, shiftId: string, occurrence?: string): void;
  removeUserFromShift(gigId: string, shiftId: string, userId: string, callback?: () => void): void;
  approveGroupApplicationManagement(
    groupId: string,
    id: string,
    userId: string,
    callback?: () => void,
  ): void;
  rejectGroupApplicationManagement(groupId: string, id: string, userId: string): void;
  getGroup(handle: string): void;
  getGroupApplications(groupId: string): void;
  createShift(gigId: string, payload: IShiftRecurrence): void;
  assignUserToShift(gigId: string, shiftId: string, payload: string[], callback?: () => void): void;
  getShifts(gigId: string): void;
  createToast(toast: IToast): void;
  userState: IUserState;
  groupState: IGroupState;
  gigState: IGigState;
  eventState: IEventState;
  getEventGigs(eventId: string): void;
  getGroupGigs(groupId: string): void;
  owner: IOwnerObject;
  locale: string;
}

interface IListItem {
  label?: string;
  image?: string;
  id: string;
}

interface IState {
  hourRows: number[];
  currentWeek: any;
  currentGigId: string;
  currentApplicant: any;
  gigs: IOptions[];
  showShiftModal: boolean;
  shiftDate: any;
  shifts: { [index: string]: IShift };
  expandVolunteerForm: boolean;
  params: any;
  reinitDropdown: boolean;
  confirmDelete: any;
  workerList: IListItem[];
  openOverCapacityPrompt: boolean;
  overrideCapacityCheck: boolean;
  currentCompmonentDrag: DropResult | null;
  shiftChangesLoading: boolean;
  collapseStates: { [index: string]: boolean };
  applicationSearch: string;
  showExportSubMenu: boolean;
  showDailyExportModal: boolean;
  scheduleExports: Array<any>;
  seeAssignedShift: IShift | null;
  shiftToEdit: IShift | null;
  sideBarOpened: boolean;
  gigApplicants: IVolunteer[];
}

class Scheduler extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hourRows: [
        6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 1, 2, 3, 4, 5,
      ],
      currentGigId: '',
      currentApplicant: null,
      currentWeek: moment().startOf('week'),
      gigs: [],
      showShiftModal: false,
      shiftDate: moment(),
      shifts: {},
      expandVolunteerForm: false,
      params: {},
      reinitDropdown: false,
      confirmDelete: null,
      workerList: [],
      openOverCapacityPrompt: false,
      overrideCapacityCheck: false,
      currentCompmonentDrag: null,
      shiftChangesLoading: false,
      collapseStates: {
        gig_applications: false,
        group_applications: false,
        assigned_applications: false,
      },
      applicationSearch: '',
      showExportSubMenu: false,
      showDailyExportModal: false,
      scheduleExports: [
        {
          icon: 'far fa-file-download',
          onClick: () => {
            this.showDailyExportModal();
          },
          label: 'Export Daily Shift (PDF)',
        },
      ],
      seeAssignedShift: null,
      shiftToEdit: null,
      sideBarOpened: false,
      gigApplicants: [],
    };

    this.getShifts = this.getShifts.bind(this);
    this.handleComponentDrag = this.handleComponentDrag.bind(this);
    this.setGigs = this.setGigs.bind(this);
    this.handleShiftChange = this.handleShiftChange.bind(this);
    this.selectAllShifts = this.selectAllShifts.bind(this);
    this.updateApplicantShifts = this.updateApplicantShifts.bind(this);
    this.setApplicant = this.setApplicant.bind(this);
    this.updateShiftDate = this.updateShiftDate.bind(this);
    this.showExportSubMenu = this.showExportSubMenu.bind(this);
    this.showDailyExportModal = this.showDailyExportModal.bind(this);
    this.exportDailyShiftPDF = this.exportDailyShiftPDF.bind(this);
  }

  componentDidMount() {
    if (
      this.props.owner.ownerType === uiConstants.ownerType.event ||
      (this.props.owner.ownerType === uiConstants.ownerType.gig &&
        this.props.owner.parentOwnerType === uiConstants.ownerType.event)
    ) {
      this.props.getEventGigs(this.props.eventState.event.id);
    } else if (
      this.props.owner.ownerType === uiConstants.ownerType.group ||
      (this.props.owner.ownerType === uiConstants.ownerType.gig &&
        this.props.owner.parentOwnerType === uiConstants.ownerType.group)
    ) {
      this.props.getGroupGigs(this.props.groupState.group.id);
    }

    let _params = queryString.parse(this.props.location.search);

    this.setState({
      params: _params,
    });
  }

  setGigs(type: string) {
    let _gigs: any = [];
    let _stateGigs = this.props.eventState.gigs;

    if (type === uiConstants.ownerType.group) {
      _stateGigs = this.props.groupState.groupGigs;
    }

    for (let g in _stateGigs) {
      let _gig = _stateGigs[g];

      if (
        this.props.owner.ownerType === uiConstants.ownerType.gig &&
        this.props.owner.parentOwnerType === uiConstants.ownerType.event &&
        _gig.id === this.props.owner.ownerId
      ) {
        _gigs.push({ label: _gig.title, value: _gig.id });
      } else {
        _gigs.push({ label: _gig.title, value: _gig.id });
      }
    }

    this.setState(
      {
        gigs: _gigs,
        reinitDropdown: true,
      },
      () => {
        const defaultGigId =
          this.props.owner.ownerType === uiConstants.ownerType.gig &&
          this.props.owner.parentOwnerType === uiConstants.ownerType.event
            ? _stateGigs.find((gig) => gig.id === this.props.owner.ownerId)?.id
            : this.state.params.gig
              ? _stateGigs.find((gig) => gig.id === this.state.params.gig)?.id
              : null;
        if (defaultGigId) {
          this.setState(
            {
              currentGigId:
                this.props.owner.ownerType === uiConstants.ownerType.gig
                  ? this.props.owner.ownerId
                  : defaultGigId,
              reinitDropdown: false,
            },
            () => {
              this.getApplications();
              this.getShifts();
            },
          );
        } else {
          this.setState(
            {
              currentGigId:
                this.props.owner.ownerType === uiConstants.ownerType.gig
                  ? this.props.owner.ownerId
                  : _gigs[0]?.value || '',
              reinitDropdown: false,
            },
            () => {
              if (this.state.currentGigId !== '') {
                this.getApplications();
                this.getShifts();
              }
            },
          );
        }
      },
    );
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.owner.ownerType === uiConstants.ownerType.gig &&
      this.props.owner.parentOwnerType === uiConstants.ownerType.event &&
      prevProps.eventState.gigs !== this.props.eventState.gigs
    ) {
      this.setGigs(uiConstants.ownerType.gig);
    }

    if (
      this.props.owner.ownerType === uiConstants.ownerType.event &&
      prevProps.eventState.gigs !== this.props.eventState.gigs
    ) {
      this.setGigs(uiConstants.ownerType.event);
    }

    if (
      (this.props.owner.ownerType === uiConstants.ownerType.group ||
        (this.props.owner.ownerType === uiConstants.ownerType.gig &&
          this.props.owner.parentOwnerType == uiConstants.ownerType.group)) &&
      prevProps.groupState.groupGigs !== this.props.groupState.groupGigs
    ) {
      this.setGigs(uiConstants.ownerType.group);
    }

    if (prevProps.gigState.shifts !== this.props.gigState.shifts) {
      let _formattedShifts: any = {};

      for (let s in this.props.gigState.shifts) {
        let _shift = this.props.gigState.shifts[s];
        _formattedShifts[moment(_shift.start_date).local().format()] = _shift;
      }

      this.setState({
        shifts: _formattedShifts,
      });
    }

    if (
      this.props.groupState.groupApplications !== prevProps.groupState.groupApplications ||
      (this.state.workerList.length === 0 && this.props.groupState.groupApplications.length !== 0)
    ) {
      let _applicants: any[] = [];

      for (let a in this.props.groupState.groupApplications) {
        let _applicant = this.props.groupState.groupApplications[a] as IApplication;

        _applicants = _applicants.filter((app) => {
          return app.id !== _applicant.user_id;
        });

        _applicants.push({
          label: _applicant.user?.display_name || '',
          image: _applicant.user?.profile_image_url || '',
          id: _applicant.user_id,
        });
      }

      this.setState({
        workerList: _applicants,
      });
    }
  }

  getApplications() {
    const gigId =
      this.props.owner.ownerType === 'gig' ? this.props.owner.ownerId : this.state.currentGigId;
    // Needs to support event AND gig page schedulers for now
    if (
      (this.props.owner.ownerType === 'gig' || this.props.owner.ownerType === 'event') &&
      typeHelpers.getHubIdFromOwner(this.props.owner)
    ) {
      let route = swapRouteParams(routes.GET_GIG_VOLUNTEERS, { gigId: gigId });

      axios
        .get(route)
        .then((response) => {
          this.setState({ gigApplicants: response.data }, () => this.getShifts());
        })
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          let toast = toastError(errorObj.translatedMessage, 'Gig Applicants');
          this.props.createToast(toast);
        });
    } else {
      const ownerGroup = typeHelpers.getGroupFromOwner(this.props.owner);
      if (ownerGroup) this.props.getGroupApplications(ownerGroup.id);
    }
  }

  updateShiftDate(date: any) {
    this.setState({
      shiftDate: date,
    });
  }

  getShifts() {
    if (this.props.gigState.gig.id !== this.state.currentGigId) {
      this.props.getGig(this.state.currentGigId);
    }
    this.props.getShifts(this.state.currentGigId);
  }

  handleComponentDrag(result: DropResult) {
    // Convert associative array to index array and find the shift where volunteer is being dropped
    let shift = Object.values(this.state.shifts).find(
      (shift) => shift.id === result.destination?.droppableId,
    );

    // Set the flag to break the flow if shift is over capacity and not being overriden by the prompt.
    let overCapacity =
      !this.state.overrideCapacityCheck &&
      shift &&
      shift.num_people === shift.assigned_users!?.length;

    if (overCapacity && !this.state.overrideCapacityCheck) {
      this.setState({ openOverCapacityPrompt: true, currentCompmonentDrag: result });
    } else {
      if (
        result.destination !== null &&
        result.destination?.droppableId !== null &&
        result.draggableId !== null
      ) {
        let _payload: any = { value: [result.draggableId.split('::')[0]] };
        this.props.assignUserToShift(
          this.state.currentGigId,
          result.destination?.droppableId!?.split('::')[0],
          _payload,
          () => {
            this.getApplications();
          },
        );
        this.setState({ overrideCapacityCheck: false, currentCompmonentDrag: null });
      }
    }
  }

  handleShiftChange(event: any, self: any, prevent?: boolean, callback?: any, passCbVal?: boolean) {
    if (prevent === true) {
      event.preventDefault();
    }

    const target = event.target;
    let value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;

    const name = target.name;

    let _currentApplicant: any = { ...this.state.currentApplicant };

    if (this.state.currentApplicant) {
      let _shifts = [..._currentApplicant.shifts];
      for (let s in _shifts) {
        let _shift = _shifts[s];
        if (_shift?.id === name || _shift._id === name) {
          _shift.is_assigned = !_shift.is_assigned;

          if (_shift.hasChanged) {
            delete _shift.hasChanged;
          } else {
            _shift.hasChanged = true;
          }
          _shift[s] = _shift;
        }
      }

      _currentApplicant.shifts = _shifts;
    }

    self.setState(
      {
        currentApplicant: _currentApplicant,
      },
      () => {
        if (callback !== undefined) {
          if (passCbVal) {
            callback(value);
          } else {
            callback();
          }
        }
      },
    );
  }

  selectAllShifts() {
    let _currentApplicant: any = { ...this.state.currentApplicant };

    if (this.state.currentApplicant) {
      let _shifts = [..._currentApplicant.shifts];

      for (let s in _shifts) {
        let _shift = _shifts[s];
        _shift.is_assigned = !_shift.is_assigned;

        if (_shift.hasChanged) {
          delete _shift.hasChanged;
        } else {
          _shift.hasChanged = true;
        }
        _shift[s] = _shift;
      }

      _currentApplicant.shifts = _shifts;
    }

    this.setState({
      currentApplicant: _currentApplicant,
    });
  }

  onEditShift(shift: IShift) {
    this.setState(
      {
        shiftToEdit: shift,
      },
      () => {
        this.setState({
          showShiftModal: true,
        });
      },
    );
  }

  onClose() {
    this.setState(
      {
        shiftToEdit: null,
        showShiftModal: false,
      },
      () => {
        this.getShifts();
      },
    );
  }

  updateApplicantShifts() {
    if (this.state.currentApplicant && this.state.currentApplicant.shifts?.length > 0) {
      this.setState(
        {
          shiftChangesLoading: true,
        },
        () => {
          let _payload: any = { shifts: [] };

          for (let s in this.state.currentApplicant.shifts) {
            let shift = this.state.currentApplicant.shifts[s];

            if (shift.hasChanged) {
              _payload.shifts.push({
                shift_id: shift._id ?? shift?.id,
                action: shift.is_assigned ? 'assign' : 'unassign',
              });
            }
          }
          axios
            .post(
              swapRouteParams(routes.CHANGE_SHIFT_ASSIGNMENTS, {
                gigId: this.state.currentApplicant.gig?.id ?? this.state.currentApplicant.gig_id,
                userId: this.state.currentApplicant.user.id,
              }),
              _payload,
            )
            .finally(() => {
              this.setState({
                currentApplicant: null,
                shiftChangesLoading: false,
              });
              this.getApplications();
            })
            .catch((error) => {
              const errorObj = errorHelpers.getErrorObject(error);
              const toast = toastError(errorObj.translatedMessage, 'Change Shift Error');
              this.props.createToast(toast);
            })
            .finally(() => {
              this.getShifts();
            });
        },
      );
    } else {
      this.setState({
        currentApplicant: null,
        shiftChangesLoading: false,
      });
    }
  }

  setApplicant(applicant: any) {
    let _applicant = { ...applicant };
    for (let s in _applicant.shifts) {
      delete _applicant.shifts[s].hasChanged;
    }

    this.setState({ currentApplicant: _applicant }, () => {
      const applicantShifts = typeHelpers.getHubIdFromOwner(this.props.owner)
        ? this.getShiftFromShiftIds(this.state.currentApplicant?.shift_ids ?? [])
        : this.state.currentApplicant?.shifts ?? [];

      this.setState({
        currentApplicant: { ...this.state.currentApplicant, shifts: applicantShifts },
      });
    });
  }

  getCapacityLabel(shift: any) {
    const addedShift = shift.is_assigned && shift.hasChanged;
    const removedShift = !shift.is_assigned && shift.hasChanged;

    if (addedShift) {
      return `${shift.assigned_users.length + 1}/${shift.num_people}`;
    } else if (removedShift) {
      return `${shift.assigned_users.length - 1}/${shift.num_people}`;
    } else {
      return `${shift.assigned_users.length}/${shift.num_people}`;
    }
  }

  renderCollapseIndicator(key: string) {
    const isCollapsed = this.state.collapseStates[key] ?? false;
    return (
      <i
        className={combineClassNames(
          'collapse-indicator',
          isCollapsed ? 'fas fa-chevron-right' : 'fas fa-chevron-down',
        )}
        onClick={() => this.toggleIsCollapsed(key)}
      />
    );
  }

  toggleIsCollapsed(key: string) {
    const isCollapsed = this.state.collapseStates[key] ?? false;
    const collapsedStatesCopy = { ...this.state.collapseStates };

    collapsedStatesCopy[key] = !isCollapsed;

    this.setState({
      collapseStates: collapsedStatesCopy,
    });
  }

  applicationMatchesSearch(application: IApplication): boolean {
    const name = application.user?.display_name ?? '';

    return (
      !this.state.applicationSearch ||
      name.toLowerCase().includes(this.state.applicationSearch.toLowerCase())
    );
  }

  showExportSubMenu() {
    this.setState({
      showExportSubMenu: !this.state.showExportSubMenu,
    });
  }

  showDailyExportModal() {
    this.setState({
      showDailyExportModal: !this.state.showDailyExportModal,
    });
  }

  async exportDailyShiftPDF() {
    if (this.state.currentGigId && this.state.shiftDate) {
      try {
        const response = await axios.get<string>(
          swapRouteParams(routes.GIG_SHIFT_DAILY_EXPORT, { gigId: this.state.currentGigId }),
          { params: { date: this.state.shiftDate.toDate() }, responseType: 'blob' },
        );

        let blob = new Blob([response.data], { type: 'application/pdf' });

        let file = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = file;
        a.download = `DayShiftExport_${new Date(this.state.shiftDate).toLocaleDateString()}.pdf`;
        document.body.appendChild(a);
        a.click();
      } catch (err) {
        const errorObj = errorHelpers.getErrorObject(err);
        const toast = toastError(errorObj.translatedMessage, 'Export Error');
        this.props.createToast(toast);
      }
    }
  }

  hasShift(applicantId: string) {
    let hasShift = false;
    for (let i = 0; i < this.props.gigState.shifts?.length; i++) {
      if (
        !!this.props.gigState.shifts[i].assigned_users?.filter((user) => user.id === applicantId)
          ?.length
      ) {
        hasShift = true;
        break;
      }
    }

    return hasShift;
  }

  getShiftFromShiftIds(shiftIds: Array<string>) {
    let applicantShifts: IShift[] = [];
    for (let i = 0; i < this.props.gigState.shifts?.length; i++) {
      if (shiftIds.includes(this.props.gigState.shifts[i].id ?? '')) {
        applicantShifts.push(this.props.gigState.shifts[i]);
      }
    }

    return applicantShifts;
  }

  async onApproveApplication(ownerId: string, isHub: boolean) {
    try {
      if (isHub) {
        await hubRequestActions.approveHubApplication(ownerId, this.state.currentApplicant.id);
      } else {
        this.props.approveGroupApplicationManagement(
          ownerId,
          this.state.currentApplicant.id,
          this.state.currentApplicant.user.id,
        );

        this.props.getGroupApplications(ownerId);
      }

      this.updateApplicantShifts();
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObj.translatedMessage, 'Approve Application');
      this.props?.createToast(toast);
    }
  }

  async onRejectApplication(ownerId: string, isHub: boolean) {
    try {
      if (isHub) {
        await hubRequestActions.rejectHubApplication(ownerId, this.state.currentApplicant.id);
      } else {
        //TODO: check what is rejectReason
        await groupRequestActions.rejectGroupApplicant(ownerId, this.state.currentApplicant.id, {
          rejectReason: undefined,
        });
        this.props.getGroupApplications(ownerId);
      }

      this.updateApplicantShifts();
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObj.translatedMessage, 'Reject Application');
      this.props?.createToast(toast);
    }
  }

  render() {
    let _hasGigApplicants = false;
    let _hasGroupApplicants = false;
    let hasAssignedApplicants = false;
    const hubId = typeHelpers.getHubIdFromOwner(this.props.owner);
    const groupId = typeHelpers.getGroupIdFromOwner(this.props.owner);
    const gigApplicants = hubId
      ? this.state.gigApplicants
      : this.props.groupState.groupApplications;
    const ownerId = hubId || groupId;

    return (
      <DragDropContext onDragEnd={this.handleComponentDrag}>
        <div className={`Scheduler section-wrap ${this.state.sideBarOpened ? 'open' : ''}`}>
          <div className="second-sidebar">
            <div className="hamburger">
              <i
                className="fad fa-arrow-from-left"
                onClick={() => {
                  this.setState({ sideBarOpened: !this.state.sideBarOpened });
                }}
              />
            </div>
            <div className="gig-selection">
              {!this.state.reinitDropdown && this.state.gigs.length > 0 && (
                <Dropdown
                  label="Current Gig"
                  value={
                    this.props.owner.ownerType === uiConstants.ownerType.gig
                      ? this.props.owner.ownerId
                      : this.state.currentGigId || ''
                  }
                  name="currentGigId"
                  options={
                    this.props.owner.ownerType === uiConstants.ownerType.gig
                      ? this.state.gigs.filter((gig) => gig.value == this.props.owner.ownerId)
                      : this.state.gigs
                  }
                  onChange={(e) => {
                    handleInputChange(e, this, false, () => {
                      this.setState({ reinitDropdown: true }, () => {
                        this.setState({ reinitDropdown: false }, () => {
                          this.getShifts();
                        });
                      });
                    });
                  }}
                  notranslate="yes"
                />
              )}
            </div>

            {this.state.sideBarOpened && (
              <TextField
                className="application-search"
                icon="fas fa-search"
                name="applicationSearch"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  this.setState({ applicationSearch: e.target.value })
                }
                type="text"
                value={this.state.applicationSearch}
              />
            )}

            <div
              className={combineClassNames(
                'applicant-wrap first',
                this.state.collapseStates['gig_applications'] ? 'collapsed' : '',
              )}
            >
              {/* Gig Applications */}
              <div
                className="applicants-title"
                onClick={() => this.toggleIsCollapsed('gig_applications')}
              >
                Gig Applicants {this.renderCollapseIndicator('gig_applications')}
              </div>
              {!this.state.collapseStates['gig_applications'] && (
                <Droppable
                  droppableId="gig_applications"
                  isDropDisabled={true}
                >
                  {(provided, snapshot) => (
                    <ul
                      className={
                        snapshot.isDraggingOver ? 'group-applicants dragging' : 'group-applicants'
                      }
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {gigApplicants.map((applicant, index) => {
                        this.hasShift(applicant.user_id);
                        if (!applicant) {
                          return null;
                        }

                        if (
                          applicant?.gig_id &&
                          applicant?.gig_id === this.state.currentGigId &&
                          !this.hasShift(applicant?.user?.id ?? '') &&
                          this.applicationMatchesSearch(applicant)
                        ) {
                          _hasGigApplicants = true;

                          return (
                            <Draggable
                              isDragDisabled={!this.state.sideBarOpened}
                              key={index}
                              draggableId={applicant.user?.id + '::gig_applications'}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <li
                                  onClick={() => {
                                    if (this.state.sideBarOpened) {
                                      this.setApplicant(applicant);
                                    }
                                  }}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  key={index}
                                >
                                  <div className="gigiteer">
                                    <Portrait
                                      currentImage={applicant.user?.profile_image_url}
                                      size={40}
                                    />
                                    <div className="gigiteer-info">
                                      <div className={'status ' + applicant.status?.code}>
                                        <span className="status-inner">
                                          {applicant.status?.code}
                                        </span>
                                      </div>
                                      <span
                                        className="displayName"
                                        notranslate="yes"
                                      >
                                        {applicant.user?.display_name}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          );
                        } else {
                          return null;
                        }
                      })}
                      {!_hasGigApplicants && (
                        <li className="empty">You don't have any gig applicants.</li>
                      )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              )}
            </div>

            {/* Group Applications */}
            {!hubId && (
              <div
                className={combineClassNames(
                  'applicant-wrap',
                  this.state.collapseStates['group_applications'] ? 'collapsed' : '',
                )}
              >
                <div
                  className="applicants-title"
                  onClick={() => this.toggleIsCollapsed('group_applications')}
                >
                  Cause Applicants {this.renderCollapseIndicator('group_applications')}
                </div>

                {!this.state.collapseStates['group_applications'] && (
                  <Droppable
                    droppableId="group_applications"
                    isDropDisabled={true}
                  >
                    {(provided, snapshot) => (
                      <ul
                        className={
                          snapshot.isDraggingOver ? 'group-applicants dragging' : 'group-applicants'
                        }
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {this.props.groupState.groupApplications.map((applicant, index) => {
                          if (!applicant) {
                            return null;
                          }

                          if (!applicant.gig?.id && this.applicationMatchesSearch(applicant)) {
                            _hasGroupApplicants = true;

                            return (
                              <Draggable
                                isDragDisabled={!this.state.sideBarOpened}
                                key={index}
                                draggableId={applicant.user?.id + '::group_applications'}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    onClick={() => {
                                      if (this.state.sideBarOpened) {
                                        this.setApplicant(applicant);
                                      }
                                    }}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    key={index}
                                  >
                                    <div className="gigiteer">
                                      <Portrait
                                        currentImage={applicant.user?.profile_image_url}
                                        size={40}
                                      />
                                      <div className="gigiteer-info">
                                        <div className={'status ' + applicant.status?.code}>
                                          <span className="status-inner">
                                            {applicant.status?.code}
                                          </span>
                                        </div>
                                        <span
                                          className="displayName"
                                          notranslate="yes"
                                        >
                                          {applicant.user?.display_name}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            );
                          }
                        })}
                        {!_hasGroupApplicants && (
                          <li className="empty">You don't have any cause applicants.</li>
                        )}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                )}
              </div>
            )}

            {/* Assigned Applications */}
            <div
              className={combineClassNames(
                'applicant-wrap',
                this.state.collapseStates['assigned_applications'] ? 'collapsed' : '',
              )}
            >
              <div
                className="applicants-title"
                onClick={() => this.toggleIsCollapsed('assigned_applications')}
              >
                Assigned Applicants {this.renderCollapseIndicator('assigned_applications')}
              </div>

              {!this.state.collapseStates['assigned_applications'] && (
                <Droppable
                  droppableId="assigned_applications"
                  isDropDisabled={true}
                >
                  {(provided, snapshot) => (
                    <ul
                      className={
                        snapshot.isDraggingOver ? 'group-applicants dragging' : 'group-applicants'
                      }
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {gigApplicants.map((applicant: any, index) => {
                        if (!applicant) {
                          return null;
                        }

                        // hack for hotfix 4.1.5
                        const shiftCheck = hubId
                          ? this.hasShift(applicant?.user?.id ?? '')
                          : applicant?.has_gig_shift;

                        if (shiftCheck && this.applicationMatchesSearch(applicant)) {
                          hasAssignedApplicants = true;

                          return (
                            <Draggable
                              isDragDisabled={!this.state.sideBarOpened}
                              key={index}
                              draggableId={applicant.user?.id + '::assigned_applications'}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <li
                                  onClick={() => {
                                    if (this.state.sideBarOpened) {
                                      this.setApplicant(applicant);
                                    }
                                  }}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  key={index}
                                >
                                  <div className="gigiteer">
                                    <Portrait
                                      currentImage={applicant.user?.profile_image_url}
                                      size={40}
                                    />
                                    <div className="gigiteer-info">
                                      <div className={'status ' + applicant.status?.code}>
                                        <span className="status-inner">
                                          {applicant.status?.code}
                                        </span>
                                      </div>
                                      <span
                                        className="displayName"
                                        notranslate="yes"
                                      >
                                        {applicant.user?.display_name}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          );
                        }
                      })}
                      {!hasAssignedApplicants && (
                        <li className="empty">You don't have any assigned applicants.</li>
                      )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              )}
            </div>
          </div>
          <div className="inner-section-wrap">
            <div className="section-title">
              <span className="title-wrap">Schedule</span>
              <div className="action">
                {this.state.gigs.length > 0 && (
                  <Button
                    icon="fal fa-plus"
                    onClick={() => {
                      this.setState({ showShiftModal: true });
                    }}
                    text="Create Shift"
                  />
                )}
                <div className="exports-list">
                  {this.state.gigs.length > 0 && (
                    <Button
                      icon="far fa-file-download"
                      onClick={() => {
                        this.showExportSubMenu();
                      }}
                      text="Export"
                    />
                  )}
                  <ContextMenu
                    onMouseLeave={() => {
                      this.showExportSubMenu();
                    }}
                    showMenu={this.state.showExportSubMenu}
                    menuItems={this.state.scheduleExports}
                  />
                </div>
              </div>
            </div>
            {this.state.gigs.length > 0 && (
              <div className="section-inner">
                <div className="date">
                  <span notranslate="yes">
                    {localizeHelpers.formatDate(
                      this.state.currentWeek,
                      LocaleDateFormats.XLL,
                      this.props.locale,
                    )}
                  </span>
                  <div className="actions">
                    <i
                      onClick={() => {
                        this.setState({ currentWeek: this.state.currentWeek.add(-7, 'days') });
                      }}
                      className="fal fa-angle-left"
                    />
                    <span>
                      Week of{' '}
                      <var data-var="week_of_date">
                        {localizeHelpers.formatDate(
                          this.state.currentWeek,
                          LocaleDateFormats.XLL,
                          this.props.locale,
                        )}
                      </var>
                    </span>
                    <i
                      onClick={() => {
                        this.setState({ currentWeek: this.state.currentWeek.add(7, 'days') });
                      }}
                      className="fal fa-angle-right"
                    />
                  </div>
                </div>
                <div className="calendar">
                  <div className="headers">
                    <div className="time">Time</div>
                    {localizeHelpers.getWeekDays().map((dayofWeek, index) => {
                      return (
                        <div
                          key={index}
                          className="day"
                          notranslate="yes"
                        >
                          {localizeHelpers.formatDate(
                            moment(this.state.currentWeek).add(index, 'days'),
                            LocaleDateFormats.XDD,
                            this.props.locale,
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="timeslots">
                    <div className="timeslots-inner">
                      {this.state.hourRows.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={'timeslot ' + item}
                          >
                            <div
                              className="time"
                              notranslate="yes"
                            >
                              {localizeHelpers.formatTime({ hour: item }, this.props.locale)}
                            </div>
                            {localizeHelpers.getWeekDays().map((day, dayIndex) => {
                              let _timeslot1 = moment(
                                moment(this.state.currentWeek).add(dayIndex, 'days').format(),
                              )
                                .hours(item)
                                .minutes(0)
                                .seconds(0)
                                .format();
                              let _timeslot2 = moment(
                                moment(this.state.currentWeek).add(dayIndex, 'days').format(),
                              )
                                .hours(item)
                                .minutes(15)
                                .seconds(0)
                                .format();
                              let _timeslot3 = moment(
                                moment(this.state.currentWeek).add(dayIndex, 'days').format(),
                              )
                                .hours(item)
                                .minutes(30)
                                .seconds(0)
                                .format();
                              let _timeslot4 = moment(
                                moment(this.state.currentWeek).add(dayIndex, 'days').format(),
                              )
                                .hours(item)
                                .minutes(45)
                                .seconds(0)
                                .format();

                              let _shift =
                                this.state.shifts[_timeslot1] ||
                                this.state.shifts[_timeslot2] ||
                                this.state.shifts[_timeslot3] ||
                                this.state.shifts[_timeslot4];

                              if (_shift !== undefined) {
                                const totalHours = _shift.total_hours ?? 0;
                                const height = Math.max(totalHours * 100, 80); // Each "Hour" is 100px height. 80 is minimum height (very short shifts are too short)
                                const headerHeight = 36;
                                const assignedUserHeight = 20;
                                const contentHeight = height - headerHeight;
                                const numberOfFittableNames =
                                  Math.floor(contentHeight / assignedUserHeight) - 1; // -1 because we need to be able to see "Assigned Users" link
                                const numberOfOverflowNames =
                                  (_shift.assigned_users?.length ?? 0) - numberOfFittableNames;

                                let _msg_users: string[] = [];

                                if (_shift.assigned_users) {
                                  for (const a of _shift.assigned_users) {
                                    typeHelpers.assertNotNullOrUndefined(a.id);
                                    _msg_users.push(a.id);
                                  }
                                }

                                const numberOfCapacity = _shift.num_people ?? 0;
                                const numberOfAssigned = _shift.assigned_users?.length ?? 0;
                                const isAtCapacity = numberOfAssigned === numberOfCapacity;
                                const isOverCapacity = numberOfAssigned > numberOfCapacity;
                                const capacityClassName = isOverCapacity
                                  ? 'over-capacity'
                                  : isAtCapacity
                                    ? 'at-capacity'
                                    : '';

                                return (
                                  <div
                                    key={dayIndex}
                                    className={'day ' + day.toLowerCase()}
                                  >
                                    <div
                                      style={{ height: `${height}px` }}
                                      className={combineClassNames(
                                        'shift m' + moment(_shift.start_date).format('mm'),
                                        capacityClassName,
                                      )}
                                    >
                                      <div
                                        className={combineClassNames('sidebar', capacityClassName)}
                                      >
                                        <div className="capacity">
                                          <span>
                                            <var data-var="capacity">
                                              {numberOfAssigned}/{numberOfCapacity}
                                            </var>{' '}
                                            Capacity
                                          </span>
                                        </div>
                                      </div>
                                      <div className="hover">
                                        <div
                                          className={combineClassNames(
                                            'hover-inner',
                                            capacityClassName,
                                          )}
                                        >
                                          <div className="hover-actions">
                                            <i
                                              onClick={() => {
                                                this.onEditShift(_shift);
                                              }}
                                              className="far fa-pencil"
                                            />
                                            <i
                                              onClick={() => {
                                                this.setState({ confirmDelete: _shift });
                                              }}
                                              className="far fa-trash-alt"
                                            />
                                            <Link
                                              to={'/gig/' + this.props.gigState.gig.handle}
                                            ></Link>
                                          </div>
                                          <div className="hover-content">
                                            <div
                                              className="title"
                                              notranslate="yes"
                                            >
                                              {this.props.gigState.gig.title}
                                            </div>
                                            <div className="type">
                                              {this.props.gigState.gig.gig_type === 'volunteer'
                                                ? 'Volunteer Gig'
                                                : 'Paid Gig'}
                                            </div>
                                            <div className="hover-inner-content">
                                              <div className="hic date">
                                                <i className="fal fa-calendar" />
                                                <div>
                                                  <var data-var="shift_start_date">
                                                    {localizeHelpers.formatDate(
                                                      _shift.start_date,
                                                      LocaleDateFormats.LLL,
                                                      this.props.locale,
                                                    )}
                                                  </var>
                                                  {' to '}
                                                  <var data-var="shift_start_date">
                                                    {localizeHelpers.formatDate(
                                                      _shift.end_date,
                                                      LocaleDateFormats.LT,
                                                      this.props.locale,
                                                    )}
                                                  </var>
                                                </div>
                                              </div>
                                              <div className="hic capacity">
                                                <i className="fal fa-users" />
                                                <div>
                                                  <div>
                                                    <var data-var="capacity">
                                                      {localizeHelpers.formatNumber(
                                                        (_shift.assigned_users || []).length || 0,
                                                        this.props.locale,
                                                      )}
                                                      /
                                                      {localizeHelpers.formatNumber(
                                                        _shift.num_people || 0,
                                                        this.props.locale,
                                                      )}
                                                    </var>{' '}
                                                    Capacity
                                                  </div>
                                                  {(_shift.assigned_users?.length ?? 0) > 0 && (
                                                    <div
                                                      className="see-assigned-link"
                                                      onClick={() =>
                                                        this.setState({ seeAssignedShift: _shift })
                                                      }
                                                    >
                                                      See All{' '}
                                                      <var data-var="assigned_count">
                                                        {_shift.assigned_users?.length}
                                                      </var>{' '}
                                                      Assigned
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <Droppable droppableId={_shift?.id!}>
                                        {(provided, snapshot) => (
                                          <ul
                                            className={
                                              snapshot.isDraggingOver
                                                ? 'assigned-users drag-over'
                                                : 'assigned-users'
                                            }
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                          >
                                            {(_shift.assigned_users?.length ?? 0) > 0 && (
                                              <Fragment>
                                                {(
                                                  _shift?.assigned_users as Array<
                                                    IShiftUser & IUser
                                                  >
                                                )?.map((user, index) => {
                                                  if (index >= numberOfFittableNames) {
                                                    return null;
                                                  }
                                                  return (
                                                    <li
                                                      key={index}
                                                      className="gigiteer"
                                                      notranslate="yes"
                                                    >
                                                      {user.display_name}
                                                    </li>
                                                  );
                                                })}
                                                <div
                                                  className="see-assigned-link"
                                                  onClick={() =>
                                                    this.setState({ seeAssignedShift: _shift })
                                                  }
                                                  notranslate="yes"
                                                >
                                                  {numberOfOverflowNames > 0
                                                    ? // Display +x display if there's names that didn't make it into the tile
                                                      localizeHelpers.translate(
                                                        '+{{overflow_count}} More',
                                                        { overflow_count: numberOfOverflowNames },
                                                      )
                                                    : 'See All Assigned'}
                                                </div>
                                              </Fragment>
                                            )}
                                            {provided.placeholder}
                                          </ul>
                                        )}
                                      </Droppable>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    key={dayIndex}
                                    className={'day ' + day.toLowerCase()}
                                  />
                                );
                              }
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {this.props.gigState.isShiftsLoading && (
                  <div className="loader-overlay">
                    <Loader loading={this.props.gigState.isShiftsLoading} />
                  </div>
                )}
              </div>
            )}
            {this.state.gigs.length === 0 && (
              <div className="section-inner">
                <div className="empty">
                  You don't have any volunteer opportunities to create shifts for, create volunteer
                  opportunities on your cause page by adding a volunteer opportunity component.
                </div>
              </div>
            )}
          </div>

          {/* TODO: Switch to <Prompt/> component */}
          <Modal
            show={this.state.confirmDelete !== null}
            onClose={() => {
              this.setState({ confirmDelete: null });
            }}
            title="Are you sure?"
          >
            <div className="confirm-delete">
              <p>Do you really want to delete this shift?</p>
              <p>This process cannot be undone.</p>
              <div className="confirm-delete-actions">
                <Button
                  onClick={() => {
                    this.setState({ confirmDelete: null });
                  }}
                  text="Cancel"
                />
                <Button
                  onClick={() => {
                    this.props.removeShift(this.state.currentGigId, this.state.confirmDelete.id);
                    this.setState({ confirmDelete: null });
                  }}
                  text="Delete"
                />
                {this.state.confirmDelete !== null && this.state.confirmDelete.recurrence_id && (
                  <Button
                    onClick={() => {
                      this.props.removeShift(
                        this.state.currentGigId,
                        this.state.confirmDelete.id,
                        'all',
                      );
                      this.setState({ confirmDelete: null });
                    }}
                    text="Delete Reoccurring Shifts"
                  />
                )}
              </div>
            </div>
          </Modal>
          <Modal
            show={this.state.currentApplicant !== null}
            onClose={() => {
              this.setState({ currentApplicant: null });
            }}
            title="Volunteer Application Status"
          >
            {this.state.currentApplicant !== null && (
              <div className="applicant-inner-modal">
                <div className="applicant">
                  <Portrait
                    currentImage={this.state.currentApplicant.user.profile_image_url}
                    size={80}
                  />
                  <div className="applicant-details">
                    <div className="applicant-cols">
                      <div className="col">
                        <span>Name</span>
                        <span notranslate="yes">
                          {this.state.currentApplicant.user.display_name}
                        </span>
                      </div>
                      <div className="col">
                        <span>Application Date</span>
                        <span notranslate="yes">
                          {localizeHelpers.formatDate(
                            this.state.currentApplicant.created_at,
                            LocaleDateFormats.XLL,
                            this.props.locale,
                          )}
                        </span>
                      </div>
                      <div className="col">
                        <span>Status</span>
                        <span className="status">
                          {capitalizeString(this.state.currentApplicant.status.code)}
                        </span>
                      </div>
                    </div>
                    {this.state.currentApplicant.user.age_privacy_agreement === false && (
                      <div className="age-check">
                        <i className="fas fa-exclamation-circle" />
                        <span>This volunteer is under the age of 18</span>
                      </div>
                    )}
                  </div>
                </div>
                {!!this.state.currentApplicant.shifts?.length && (
                  <div className="shifts">
                    <div className="shifts-title">
                      Selected Shifts{' '}
                      <Button
                        onClick={() => {
                          this.selectAllShifts();
                        }}
                        text="Select All"
                      />
                    </div>
                    {this.state.currentApplicant?.shifts?.length > 0 && (
                      <ul>
                        {this.state.currentApplicant?.shifts?.map((shift: any, index: number) => {
                          return (
                            <li key={index}>
                              <Checkbox
                                name={shift._id ?? shift?.id}
                                onChange={(e) => {
                                  this.handleShiftChange(e, this);
                                }}
                                checked={shift.is_assigned}
                                value={shift._id ?? shift?.id}
                                color="work"
                                label={
                                  this.getCapacityLabel(shift) +
                                  ' ' +
                                  moment(shift.start_date).format('LLL') +
                                  ' - ' +
                                  moment(shift.end_date).format('LLL')
                                }
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                    {this.state.currentApplicant?.shifts?.length === 0 && (
                      <div className="no-shifts">None</div>
                    )}
                  </div>
                )}
                {this.state.currentApplicant.application_form &&
                  this.state.currentApplicant.application_form.length > 0 && (
                    <div
                      className={
                        this.state.expandVolunteerForm ? 'form-answers' : 'form-answers closed'
                      }
                    >
                      <div className="title">
                        <span>Application Form</span>
                        <i
                          onClick={() => {
                            this.setState({ expandVolunteerForm: !this.state.expandVolunteerForm });
                          }}
                          className={
                            this.state.expandVolunteerForm ? 'fal fa-minus' : 'fal fa-plus'
                          }
                        />
                      </div>
                      {this.state.currentApplicant.application_form.map(
                        (question: any, index: any) => {
                          return (
                            <div
                              key={index}
                              className="question"
                            >
                              <div
                                notranslate="yes"
                                className="label"
                              >
                                {question.question_label}
                              </div>
                              <div
                                notranslate="yes"
                                className="answer"
                              >
                                {question.answer}
                              </div>
                            </div>
                          );
                        },
                      )}
                    </div>
                  )}
                {this.state.currentApplicant.status.code === 'pending' && (
                  <div className="applicant-actions">
                    <Button
                      loading={this.state.shiftChangesLoading}
                      onClick={() => this.onRejectApplication(ownerId!, !!hubId)}
                      text="Deny"
                    />
                    <Button
                      loading={this.state.shiftChangesLoading}
                      onClick={() => this.onApproveApplication(ownerId!, !!hubId)}
                      text={
                        this.state.currentApplicant?.shifts?.length > 0
                          ? 'Approve & Assign Shifts'
                          : 'Approve'
                      }
                    />
                  </div>
                )}
                {this.state.currentApplicant.status.code === 'approved' && (
                  <div className="applicant-actions">
                    <Button
                      loading={this.state.shiftChangesLoading}
                      onClick={() => {
                        this.updateApplicantShifts();
                      }}
                      text="Update Shifts"
                    />
                  </div>
                )}
              </div>
            )}
          </Modal>
          <Modal
            show={this.state.showShiftModal}
            onClose={() => {
              this.setState({ showShiftModal: false, shiftToEdit: null });
            }}
            title={this.state.shiftToEdit?.id ? 'Edit Shift' : 'Add Shift'}
          >
            <ShiftManage
              onClose={() => this.onClose()}
              gigId={this.state.currentGigId}
              workers={this.state.workerList}
              shift={this.state.shiftToEdit}
            />
          </Modal>

          <Modal
            show={this.state.showDailyExportModal}
            onClose={() => {
              this.showDailyExportModal();
            }}
            title="Export Daily Schedule"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.exportDailyShiftPDF();
              }}
              className="create-shift-modal"
            >
              <div className="form-inner">
                <div className="form-row date-row">
                  <i className="fal fa-calendar" />
                  <DatePicker
                    name="shiftDate"
                    label="Shift Date"
                    date={this.state.shiftDate}
                    onChange={this.updateShiftDate}
                  />
                </div>
              </div>

              <div className="actions">
                <Button
                  buttonType="secondary"
                  onClick={() => {
                    this.showDailyExportModal();
                  }}
                  text="Cancel"
                  style={{ marginRight: '10px' }}
                />
                <Button
                  type="submit"
                  text="Generate Schedule"
                />
              </div>
            </form>
          </Modal>

          <AssignedUsersModal
            show={this.state.seeAssignedShift != null}
            onClose={() => this.setState({ seeAssignedShift: null })}
            shift={this.state.seeAssignedShift}
            users={(this.state.seeAssignedShift?.assigned_users as (IShiftUser & IUser)[]) ?? []}
            getContextMenu={(user) => [
              {
                label: 'Remove',
                icon: 'far fa-times-circle',
                onClick: () => {
                  if (this.state.seeAssignedShift) {
                    // Update local shift copy.
                    const seeAssignedShiftCopy = {
                      ...this.state.seeAssignedShift,
                      assigned_users: [...(this.state.seeAssignedShift?.assigned_users ?? [])],
                    };

                    seeAssignedShiftCopy.assigned_users =
                      seeAssignedShiftCopy.assigned_users.filter(
                        (assignedUser) => assignedUser.id !== user.id,
                      );

                    this.setState({
                      seeAssignedShift: seeAssignedShiftCopy,
                    });
                  }

                  this.props.removeUserFromShift(
                    this.state.currentGigId,
                    this.state.seeAssignedShift?.id ?? '',
                    user.id!,
                    () => {
                      this.getApplications();
                    },
                  );
                },
              },
            ]}
          />

          <Prompt
            show={this.state.openOverCapacityPrompt}
            title="Shift over capacity"
            message="This shift is already full, would you like to increase the capacity of the shift and assign this person?"
            yesMessage="Yes, extend the shift capacity"
            yesClass="fa fa-check"
            yesStyle="normal"
            cancelMessage="No"
            onYes={() => {
              this.setState({ openOverCapacityPrompt: false, overrideCapacityCheck: true }, () => {
                this.handleComponentDrag(this.state.currentCompmonentDrag!);
              });
            }}
            onClose={() => {
              setTimeout(() =>
                this.setState({ openOverCapacityPrompt: false, currentCompmonentDrag: null }),
              );
            }}
          />
        </div>
      </DragDropContext>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    gigState: store.gigState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getGig,
  getGroupGigs,
  removeShift,
  removeUserFromShift,
  assignUserToShift,
  approveGroupApplicationManagement,
  rejectGroupApplicationManagement,
  getGroup,
  getGroupApplications,
  getEventGigs,
  createToast,
  createShift,
  getShifts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Scheduler));
