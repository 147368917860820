import { IPageContentBlockItem } from '@gigit/interfaces';
import React, { ChangeEvent, useEffect, useState } from 'react';
import TextField from '../../../../TextField/TextField';
import EditModal from '../../shared/EditModal/EditModal';
import { IPageComponent } from '@gigit/interfaces';
import './ManageTextBlockModal.scss';
import { uiConstants } from '../../../../../constants';
import QuillTextEditor from '../../../../QuillTextEditor/QuillTextEditor';

interface IManageTextBlockModalProps {
  content: IPageContentBlockItem[] | null;
  showModal: boolean;
  onSave: (title: string, description: string) => void;
  onClose: () => void;
  pageComponent?: IPageComponent;
}

const FORM_ID = 'manage-text-block-form';
function ManageTextBlockModal(props: IManageTextBlockModalProps) {
  const { showModal, onClose } = props;

  const [title, setTitle] = useState<string>(
    props.pageComponent?.content?.find((part) => 'title' in part)?.title || '',
  );
  const [description, setDescription] = useState<string>(
    props.pageComponent?.content?.find((part) => 'text' in part)?.text || '',
  );
  const [trySubmitForm, setTrySubmitForm] = useState<boolean>(false);

  useEffect(() => {
    if (props.content?.length) {
      const text = props.content.find((item) => item.type == 'text');
      setTitle(text?.title || '');
      setDescription(text?.text || '');
    }
  }, [props.content]);

  function handleSubmitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    props.onSave(title, description);
  }

  return (
    <EditModal
      title="Content Block"
      showModal={showModal}
      modalClassName={'ManageTextBlockModal'}
      onSave={() => setTrySubmitForm(true)}
      onClose={onClose}
      modalSize="medium"
      formId={FORM_ID}
    >
      <form
        className="content-block-container"
        onSubmit={handleSubmitForm}
        id={FORM_ID}
      >
        <TextField
          placeholder="Type a title here"
          value={title}
          name="title"
          label="Title"
          type="text"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <div className="text-area-container">
          <label>Description</label>
          <QuillTextEditor
            value={description}
            onChange={setDescription}
            preserveWhitespace={true}
            modules={{
              toolbar: uiConstants.quill.basicToolbar,
              clipboard: { matchVisual: false },
            }}
            theme={uiConstants.quill.editableTheme}
            formats={uiConstants.quill.defaultFormats}
            maxLength={600}
            placeholder={'Enter Description'}
          />
        </div>
      </form>
    </EditModal>
  );
}

export default ManageTextBlockModal;
