import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IMediaAlbum, IPage, IPageComponent } from '@gigit/interfaces';

import { deleteGroupMediaAlbum, updateGroupPageComponent } from '../../../actions/group';
import { deleteEventMediaAlbum, updateEventPageComponent } from '../../../actions/event';
import { deleteGigMediaAlbum, updateGigPageComponent } from '../../../actions/gig';
import { updateUserPageComponent } from '../../../actions/user';
import { deleteProfileMediaAlbum } from '../../../actions/profile';

import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';

import './MediaAlbumItem.scss';
import { IOwnerObject } from '../../../interfaces/ownerObject';
import typeHelpers from '../../../helpers/typeHelpers';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  owner: IOwnerObject;
  page: IPage;
  item: IMediaAlbum;
  component: IPageComponent;
  onClick?(e: MouseEvent<HTMLElement>): void;
  editable: boolean;
  deleteGroupMediaAlbum(groupId: string, albumId: string): void;
  updateGroupPageComponent(
    groupId: string,
    _pageId: string,
    _componentId: string,
    _payload: any,
    callback?: () => void,
  ): void;
  updateGigPageComponent(gigId: string, _pageId: string, _componentId: string, _payload: any): void;
  deleteEventMediaAlbum(eventId: string, albumId: string): void;
  updateEventPageComponent(
    eventId: string,
    _pageId: string,
    _componentId: string,
    _payload: any,
    callback?: () => void,
  ): void;
  deleteProfileMediaAlbum(userId: string, albumId: string): void;
  updateUserPageComponent(_pageId: string, _componentId: string, _payload: any): void;
  deleteGigMediaAlbum(gigId: string, albumId: string): void;
  onComponentUpdate?(): void;
}

interface IState {
  confirmDelete: boolean;
}

class MediaAlbumItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      confirmDelete: false,
    };

    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  toggleVisibility() {
    typeHelpers.assertNotNullOrUndefined(this.props.item.id, 'Expected item id');

    let _albums = [...(this.props.component.content_references?.object_ids ?? [])];

    if (_albums.includes(this.props.item.id)) {
      for (let a in _albums) {
        if (_albums[a] === this.props.item.id) {
          _albums.splice(parseInt(a), 1);
        }
      }
    } else {
      _albums.push(this.props.item.id);
    }

    if (this.props.owner.ownerType === 'group') {
      this.props.updateGroupPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'media_album',
            object_ids: _albums,
          },
        },
        this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
      );
    } else if (this.props.owner.ownerType === 'event') {
      this.props.updateEventPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'media_album',
            object_ids: _albums,
          },
        },
        this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
      );
    } else if (this.props.owner.ownerType === 'user') {
      this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, {
        content_references: {
          object_type: 'media_album',
          object_ids: _albums,
        },
      });
    } else if (this.props.owner.ownerType === 'gig') {
      this.props.updateGigPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'media_album',
            object_ids: _albums,
          },
        },
      );
    }
  }

  render() {
    const mediaItems = this.props.item.media_items ?? [];
    const isVisible = !!this.props.component.content_references?.object_ids.includes(
      this.props.item.id ?? '',
    );
    const itemId = this.props.item.id ?? '';

    return (
      <div
        onClick={(e: any) => {
          if (this.props.onClick !== undefined) {
            this.props.onClick(e);
          }
        }}
        className={isVisible ? 'MediaAlbumItem no-select' : 'MediaAlbumItem hidden no-select'}
      >
        <div className="count">{mediaItems.length} images</div>
        <div className="title">{this.props.item.title}</div>
        <div className="cover">
          {mediaItems.length > 0 && (
            <img
              alt={this.props.item.title + ' Album Cover'}
              src={mediaItems[0].url}
            />
          )}
          {this.props.editable && (
            <div className="admin-overlay">
              <ul>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ confirmDelete: true });
                  }}
                >
                  <i className="fa fa-trash-alt"></i>
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    this.toggleVisibility();
                  }}
                >
                  <i className={isVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* TODO: Switch this to using <Prompt/> component */}
        <Modal
          show={this.state.confirmDelete}
          onClose={(e: any) => {
            e.stopPropagation();
            this.setState({ confirmDelete: false });
          }}
          title={`Delete ${this.props.item.title}`}
        >
          <div className="delete-album">
            <p>Are you sure you want to remove this album from your page?</p>
            <div className="actions">
              <Button
                text="Cancel"
                onClick={(e: any) => {
                  e.stopPropagation();
                  this.setState({ confirmDelete: false });
                }}
              />
              {this.props.owner.ownerType === 'gig' && (
                <Button
                  text="Delete"
                  onClick={() => {
                    this.props.deleteGigMediaAlbum(this.props.owner.ownerId, itemId);
                  }}
                />
              )}
              {this.props.owner.ownerType === 'group' && (
                <Button
                  text="Delete"
                  onClick={() => {
                    this.props.deleteGroupMediaAlbum(this.props.owner.ownerId, itemId);
                  }}
                />
              )}
              {this.props.owner.ownerType === 'event' && (
                <Button
                  text="Delete"
                  onClick={() => {
                    this.props.deleteEventMediaAlbum(this.props.owner.ownerId, itemId);
                  }}
                />
              )}
              {this.props.owner.ownerType === 'user' && (
                <Button
                  text="Delete"
                  onClick={() => {
                    this.props.deleteProfileMediaAlbum('current', itemId);
                  }}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {
  deleteGroupMediaAlbum,
  deleteEventMediaAlbum,
  deleteProfileMediaAlbum,
  updateGroupPageComponent,
  updateEventPageComponent,
  updateUserPageComponent,
  updateGigPageComponent,
  deleteGigMediaAlbum,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(MediaAlbumItem),
);
