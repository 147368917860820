import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  handleInputChange,
  routes,
  swapRouteParams,
  toastError,
  typeHelpers,
  errorHelpers,
} from '../../helpers';

import {
  IOrgPremiumFeaturesAdminSummaryFE,
  IPremiumFeatureFE,
  IHub,
  ICreateHub,
  IPremiumFeatureCreateTrialParamsFE,
  IPremiumFeatureAdminUpdateParams,
} from '@gigit/interfaces';
import { IToast } from '../../interfaces';
import { createToast } from '../../actions/toaster';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import { Prompt } from '../Prompt/Prompt';
import SortableTable, {
  ISortableTableActions,
  ISortableTableColumn,
} from '../SortableTable/SortableTable';
import Modal from '../Modal/Modal';
import ModalHeader from '../Modal/ModalHeader/ModalHeader';
import './Hubs.scss';
import { billingRequestActions } from '../../requestActions';
import moment, { Moment } from 'moment';
import DateTimePicker from '../shared/DateTimePicker/DateTimePicker';
import { Constants } from '@gigit/constants';
import { localizeHelpers } from '../../localizeHelpers';

interface IProps {
  createToast(toast: IToast): void;
}

interface IState {
  hubsRecords: Array<IHub>;
  hubsColumns: ISortableTableColumn[];
  dataLoading: boolean;
  searchValue: string;
  hubToDelete: IHub | null;
  hubToEdit: IHub | null;
  hubTitle: string;
  hubHandle: string;
  trialEndDate: Moment;
  maxSeats: number;
  numberOfSeats: number;
  doesTrialExist: boolean;
  groupHandle: string;
  showEditHub: boolean;
  showManageTrialModal: boolean;
  manageTrialModalType: 'edit' | 'add';
  /** Set this when doing actions from the context menu */
  selectedHub: IHub | null;
  selectedHubSeatingFeature: IPremiumFeatureFE | null;
  allHubSubscriptions: IOrgPremiumFeaturesAdminSummaryFE[];
}

class Hubs extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      searchValue: '',
      dataLoading: false,
      hubToDelete: null,
      hubToEdit: null,
      hubTitle: '',
      hubHandle: '',
      groupHandle: '',
      showEditHub: false,
      selectedHub: null,
      trialEndDate: moment().add({ days: 1 }),
      maxSeats: 0,
      numberOfSeats: 0,
      showManageTrialModal: false,
      manageTrialModalType: 'edit',
      doesTrialExist: false,
      allHubSubscriptions: [],
      selectedHubSeatingFeature: null,
      hubsRecords: [],
      hubsColumns: [
        { id: 'title', label: 'Hub Title', sortable: false },
        { id: 'hub-handle', label: 'Hub Handle', sortable: false },
        { id: 'groups-qty', label: 'No. of Causes', sortable: false },
        { id: 'hub-seating-status', label: 'Hub Seating Status', sortable: false },
        { id: 'hub-seating-subscription-id', label: 'Subscription ID', sortable: false },
        { id: 'hub-seating-payed-seats', label: 'Payed Seats', sortable: false },
        { id: 'hub-seating-free-seats', label: 'Free Seats', sortable: false },
        { id: 'hub-seating-trial-end-date', label: 'Trial End Date', sortable: false },
        { id: 'hub-seating-trial-max-seats', label: 'Max Trial Seats', sortable: false },
        { id: 'actions', label: 'Actions', sortable: false },
      ],
    };
  }

  componentDidMount() {
    this.getHubs();
    this.getAllHubSubscriptions();
  }

  getAllHubSubscriptions = async () => {
    try {
      const subscriptionsResponse = await billingRequestActions.getAllSubscriptionsForObjectType(
        Constants.object_type.hub,
      );

      this.setState({
        allHubSubscriptions: subscriptionsResponse,
      });
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Get All Hub Subscriptions');
      this.props.createToast(toast);
    }
  };

  findHubSeatingSubscription = (hubId: string) => {
    const selectedHubFeatures = this.state.allHubSubscriptions.find(
      (sub) => sub.object_id === hubId,
    )?.features;

    return (
      selectedHubFeatures?.find(
        (feature) => feature.feature_code === Constants.billing.subscriptions.hub_seating.code,
      ) || null
    );
  };

  getHubs() {
    this.setState({ dataLoading: true });
    axios
      .get(routes.GET_HUBS + '?sort=-created_at')
      .then((response) => {
        this.setState({ hubsRecords: response.data });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Get Hubs');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  deleteHub(hubId: string) {
    //NOTE: delete route does exist on BE but no logic implemented. Delete HUB on BE should be reviewed in the future
    this.setState({ dataLoading: true });
    axios
      .delete(swapRouteParams(routes.DELETE_HUB, { hubId }))
      .then((response) => {
        const deletedHub = response.data as IHub;
        const updatedHubsList = this.state.hubsRecords.filter((h) => h.id !== deletedHub.id);
        this.setState({
          hubsRecords: updatedHubsList,
        });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Delete Hub');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  editHub() {
    typeHelpers.assertNotNullOrUndefined(this.state.hubToEdit);

    const payload: Partial<IHub> = {
      title: this.state.hubTitle.trim(),
      handle: this.state.hubHandle.trim(),
    };

    axios
      .put<IHub>(swapRouteParams(routes.UPDATE_HUB, { hubId: this.state.hubToEdit.id }), payload)
      .then((response) => {
        const updatedHub: IHub = response.data;
        const updatedHubsRecords = this.state.hubsRecords.map((hub) =>
          hub.id === updatedHub.id ? updatedHub : hub,
        );
        this.setState({
          hubsRecords: updatedHubsRecords,
          showEditHub: false,
        });
        this.resetHubData();
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Edit Hub');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  async createHubTrial() {
    const payload: IPremiumFeatureCreateTrialParamsFE = {
      trial_end_date: this.state.trialEndDate.toISOString(),
      max_trial_seats: Number(this.state.maxSeats),
      number_of_seats: Number(this.state.numberOfSeats),
    };

    if (!payload.max_trial_seats) {
      const toast = toastError(localizeHelpers.translate('Max seats is required'), 'Manage Trial');
      this.props.createToast(toast);
      return;
    }

    if (!payload.number_of_seats || payload.number_of_seats > 1000000) {
      const toast = toastError(
        localizeHelpers.translate('Seat count of 0 is invalid. Must be between 1 and 1000000'),
        'Manage Trial',
      );
      this.props.createToast(toast);
      return;
    }

    try {
      const trialResponse = await billingRequestActions.startHubTrial(
        this.state.selectedHub?.id!,
        payload,
      );

      this.getHubs();
      this.getAllHubSubscriptions();
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Manage Trial');
      this.props.createToast(toast);
    } finally {
      this.setState({
        showManageTrialModal: false,
        dataLoading: false,
      });
    }
  }

  async editHubTrial() {
    const payload: IPremiumFeatureAdminUpdateParams = {
      trial_end_date: this.state.trialEndDate.toISOString(),
      seats: {
        max_trial_seats: Number(this.state.maxSeats),
      },
    };

    if (!payload.seats?.max_trial_seats) {
      const toast = toastError(localizeHelpers.translate('Max seats is required'), 'Manage Trial');
      this.props.createToast(toast);
      return;
    }

    try {
      const trialResponse = await billingRequestActions.updateHubSubscriptionAdmin(
        this.state.selectedHub?.id!,
        Constants.billing.subscriptions.hub_seating.code,
        payload,
      );

      this.getHubs();
      this.getAllHubSubscriptions();
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Edit Hub Trial');
      this.props.createToast(toast);
    } finally {
      this.setState({
        showManageTrialModal: false,
        dataLoading: false,
      });
    }
  }

  resetHubData() {
    this.setState({
      hubTitle: '',
      hubHandle: '',
      groupHandle: '',
      selectedHub: null,
      selectedHubSeatingFeature: null,
    });
  }

  getHubsTableData() {
    const searchValue = this.state.searchValue.toLowerCase();
    const hubsList = searchValue
      ? this.state.hubsRecords?.filter(
          (hub) =>
            hub.title?.toLowerCase().includes(searchValue) ||
            hub.handle?.toLowerCase().includes(searchValue) ||
            hub.group_handle?.toLowerCase().includes(searchValue),
        )
      : this.state.hubsRecords;

    return hubsList.map((hub: IHub) => {
      typeHelpers.assertNotNullOrUndefined(hub.id);
      const hubSeatingFeature = this.findHubSeatingSubscription(hub.id);

      const menuItems: ISortableTableActions[] = [
        {
          icon: 'far fa-pencil-alt',
          label: 'Edit',
          onClick: () => {
            this.setState({
              showEditHub: true,
              hubToEdit: hub,
              hubTitle: hub.title || '',
              hubHandle: hub.handle || '',
              groupHandle: hub.group_handle || '',
            });
          },
        },
      ];

      if (hubSeatingFeature?.trial_end_date && hubSeatingFeature?.is_trial) {
        menuItems.push({
          icon: 'far fa-clock',
          label: 'Edit Trial',
          onClick: () => {
            this.setState({
              showManageTrialModal: true,
              manageTrialModalType: 'edit',
              trialEndDate: hubSeatingFeature?.trial_end_date
                ? moment(hubSeatingFeature?.trial_end_date)
                : moment().add({ days: 1 }),
              numberOfSeats: hubSeatingFeature?.seats?.payed ?? 0,
              maxSeats: hubSeatingFeature?.seats?.max_trial_seats
                ? hubSeatingFeature?.seats?.max_trial_seats
                : 0,
              selectedHub: hub,
              selectedHubSeatingFeature: hubSeatingFeature,
            });
          },
        });
      } else if (!hubSeatingFeature?.subscription_id) {
        menuItems.push({
          icon: 'far fa-clock',
          label: 'Add Trial',
          onClick: () => {
            this.setState({
              showManageTrialModal: true,
              manageTrialModalType: 'add',
              trialEndDate: hubSeatingFeature?.trial_end_date
                ? moment(hubSeatingFeature?.trial_end_date)
                : moment().add({ days: 1 }),
              numberOfSeats: hubSeatingFeature?.seats?.payed ?? 0,
              maxSeats: hubSeatingFeature?.seats?.max_trial_seats
                ? hubSeatingFeature?.seats?.max_trial_seats
                : 0,
              selectedHub: hub,
              selectedHubSeatingFeature: hubSeatingFeature,
            });
          },
        });
      }

      /**
 *                 { id: 'hub-seating-payed-seats', label: 'Payed Seats', sortable: false },
                { id: 'hub-seating-free-seats', label: 'Free Seats', sortable: false },
                { id: 'hub-seating-trial-end-date', label: 'Trial End Date', sortable: false },
 * 
 */

      return {
        row: [
          { content: hub.title, id: 'title' },
          { content: <Link to={`/company/${hub.handle}`}>{hub.handle}</Link>, id: 'hub-handle' },
          {
            content: <Link to={`/group/${hub.group_handle}`}>{hub.group_handle}</Link>,
            id: 'grour-gandle',
          },
          { content: hub.groups_involved?.length, id: 'groups-qty' },
          { content: hubSeatingFeature?.status?.code ?? 'N/A', id: 'hub-seating-status' },
          {
            content:
              hubSeatingFeature?.subscription_id ??
              (hubSeatingFeature?.is_virtual ? 'Virtual' : 'N/A'),
            id: 'hub-seating-subscription-id',
          },
          { content: hubSeatingFeature?.seats?.payed, id: 'hub-seating-payed-seats' },
          { content: hubSeatingFeature?.seats?.free, id: 'hub-seating-free-seats' },
          { content: hubSeatingFeature?.trial_end_date, id: 'hub-seating-trial-end-date' },
          { content: hubSeatingFeature?.seats?.max_trial_seats, id: 'hub-seating-trial-max-seats' },
          {
            id: 'actions',
            menu: menuItems,
          },
        ],
      };
    });
  }

  handleSubmitTrial = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.selectedHubSeatingFeature?.is_trial) {
      this.editHubTrial();
    } else {
      this.createHubTrial();
    }
  };

  render() {
    return (
      <div className="Hubs">
        <div className="section-title">
          <div className="forms-title">Companies</div>
          <div className="forms-controls"></div>
        </div>

        <div className="section-inner">
          <div className="search">
            <TextField
              icon="fas fa-search"
              placeholder="Search hubs..."
              value={this.state.searchValue}
              type="text"
              name="searchValue"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e, this);
              }}
            />
            <div className="filters">{/* Filters buttons here if needed */}</div>
          </div>

          <div className="items-list">
            <SortableTable
              {...this.props}
              columns={this.state.hubsColumns}
              data={this.getHubsTableData()}
              itemsPerPage={5}
              loading={this.state.dataLoading}
            />
          </div>

          {!this.state.dataLoading && (
            <div className={`no-items-found ${this.getHubsTableData().length ? '' : 'show'}`}>
              No items found
            </div>
          )}
        </div>

        <Prompt
          show={!!this.state.hubToDelete}
          title="Delete hub"
          message={`Are you sure you want to delete hub ${this.state.hubToDelete?.title}?`}
          yesMessage="Yes"
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage="Cancel"
          onYes={() => {
            this.deleteHub(this.state.hubToDelete?.id || '');
          }}
          onClose={() => {
            this.setState({ hubToDelete: null });
          }}
        />

        <Modal
          class="edit-hub-record"
          show={this.state.showEditHub}
          onClose={(e) => {
            this.setState({ showEditHub: false });
            this.resetHubData();
          }}
        >
          <ModalHeader
            className="header"
            title={'Edit Hub'}
            onClose={(e) => {}}
          />

          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.editHub();
            }}
          >
            <div className="inner">
              <TextField
                label="Hub Title"
                required={true}
                value={this.state.hubTitle}
                name="hubTitle"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
              <TextField
                label="Hub Handle"
                required={true}
                value={this.state.hubHandle}
                name="hubHandle"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="controls">
              <Button
                buttonClass="cancel-button"
                type="button"
                onClick={(e) => {
                  this.setState({ showEditHub: false });
                  this.resetHubData();
                }}
                text="Cancel"
              />
              <Button
                buttonClass="update-button"
                type="submit"
                text="Update"
              />
            </div>
          </form>
        </Modal>

        {/* Used to Add or Edit a seating trial  */}
        <Modal
          class="manage-trial-modal"
          show={this.state.showManageTrialModal}
          onClose={(e) => {
            this.setState({ showManageTrialModal: false });
            this.resetHubData();
          }}
        >
          <ModalHeader
            className="header"
            title={`${this.state.selectedHubSeatingFeature?.is_trial ? 'Edit' : 'Add'} Trial`}
          />

          <form onSubmit={this.handleSubmitTrial}>
            <div className="inner">
              <label>Trial End Date</label>
              <DateTimePicker
                shouldValidate={true}
                inputProps={{ placeholder: 'Date', readOnly: true }}
                className="custom-dtp"
                value={this.state.trialEndDate}
                onChange={(date) => {
                  this.setState({ trialEndDate: moment(date) });
                }}
                timeFormat={false}
                disableDatesBefore={moment()}
              />

              {this.state.manageTrialModalType === 'add' && (
                <TextField
                  label="Number of Seats"
                  value={this.state.numberOfSeats}
                  name="numberOfSeats"
                  type="number"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
              )}

              <TextField
                label="Max Seats"
                value={this.state.maxSeats}
                name="maxSeats"
                type="number"
                required={true}
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="controls">
              <Button
                buttonClass="cancel-button"
                type="button"
                onClick={(e) => {
                  this.setState({ showManageTrialModal: false });
                  this.resetHubData();
                }}
                text="Cancel"
              />
              <Button
                buttonClass="update-button"
                type="submit"
                text="Update"
              />
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  createToast,
};

export default connect(null, mapDispatchToProps)(Hubs);
