import React, { useState } from 'react';
import EditModal from '../../shared/EditModal/EditModal';
import './ContentBlockLayoutModal.scss';
import { Constants } from '@gigit/constants';
import ContentBlock, { IContentBlockProps } from '../../../ContentBlock/ContentBlock';
import { IPageContentBlockItem } from '@gigit/interfaces';

/** This type is meant to be a tuple array of IPageContentBlockItem [text, image], [image, text], etc */
export type PageContentBlockItemLayout = IPageContentBlockItem[][];
interface IContentBlockLayoutModalProps {
  showModal: boolean;
  onSave: (contentBlock: IPageContentBlockItem[]) => void;
  onClose: () => void;
}

const LOREM_PLACEHOLDER =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';

function getPredefinedLayoutOptions(): IPageContentBlockItem[][] {
  const { image, metrics, partners, text } = Constants.page_content_block_item_type;

  return [
    [
      {
        type: text,
        text: LOREM_PLACEHOLDER,
        title: 'Title',
      },
      {
        type: image,
        media_url: '',
      },
    ],
    [
      {
        type: image,
        media_url: '',
      },
      {
        type: text,
        text: LOREM_PLACEHOLDER,
        title: 'Title',
      },
    ],
  ];
}

function ContentBlockLayoutModal(props: IContentBlockLayoutModalProps) {
  const { showModal, onSave, onClose } = props;
  const [selectedContentBlockIndex, setSelectedContentBlockIndex] = useState<number>(0);
  const [layoutOptions, setLayoutOptions] = useState<IPageContentBlockItem[][]>(
    getPredefinedLayoutOptions(),
  );

  function renderContentBlocksOptions(contentBlocks: IPageContentBlockItem[][]) {
    return contentBlocks.map((content, idx) => {
      const isSelected = selectedContentBlockIndex === idx;
      const getIsContentBlockSelectedClass = isSelected ? 'selected' : '';

      return (
        <div
          key={idx}
          className={`content-block-option ${getIsContentBlockSelectedClass}`}
          onClick={() => setSelectedContentBlockIndex(idx)}
        >
          {isSelected && <i className="fas fa-check selected" />}
          <ContentBlock
            isPreviewMode={true}
            contentBlocks={content}
          />
        </div>
      );
    });
  }

  return (
    <EditModal
      title="Component Layout"
      showModal={props.showModal}
      onSave={() => onSave(layoutOptions[selectedContentBlockIndex])}
      onClose={onClose}
      modalSize={'medium'}
      modalClassName={'ContentBlockLayoutModal'}
    >
      <div className="content-block-list-container">
        {renderContentBlocksOptions(layoutOptions)}
      </div>
    </EditModal>
  );
}

export default ContentBlockLayoutModal;
