import React, { FC, useEffect, useState } from 'react';
import './Timer.scss';

interface ITimerState {
  color: string;
  threshold?: number;
}

interface IProps {
  onEnd(): void;
  time_limit: number;
  color_codes: {
    info: ITimerState;
    warning?: ITimerState;
    alert?: ITimerState;
  };
}

const Timer: FC<IProps> = (props) => {
  const [timeLeft, setTimeLeft] = useState<number>(props.time_limit);
  const [timePassed, setTimePassed] = useState<number>(0);
  const [timerInterval, setTimerInterval] = useState<null | NodeJS.Timeout>(null);
  const [circleDasharray, setCircleDasharray] = useState<string>('');
  const [remainingPathColor, setRemainingPathColor] = useState<string>(
    props.color_codes.info.color,
  );

  const FULL_DASH_ARRAY = 283;

  function onTimesUp() {
    if (timerInterval) {
      clearInterval(timerInterval);
    }

    props.onEnd();
  }

  useEffect(() => {
    handleSetCircleDasharray();
    handleSetRemainingPathColor();

    if (timeLeft === 0) {
      onTimesUp();
    }
  }, [timeLeft]);

  useEffect(() => {
    setTimeLeft(props.time_limit - timePassed);
  }, [timePassed]);

  function startTimer() {
    setTimerInterval(
      setInterval(() => {
        setTimePassed((timePassed) => timePassed + 1);
      }, 1000),
    );
  }

  function handleSetRemainingPathColor() {
    const { alert, warning } = props.color_codes;
    if (alert?.threshold && timeLeft <= alert.threshold) {
      setRemainingPathColor(alert.color);
    } else if (warning?.threshold && timeLeft <= warning.threshold) {
      setRemainingPathColor(warning.color);
    }
  }

  function formatTime(time: number) {
    const minutes = Math.floor(time / 60);
    let seconds = `${time % 60}`;

    if (+seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  }

  function calculateTimeFraction() {
    const rawTimeFraction = timeLeft / props.time_limit;
    return rawTimeFraction - (1 / props.time_limit) * (1 - rawTimeFraction);
  }

  function handleSetCircleDasharray() {
    setCircleDasharray(
      `${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0)} ${FULL_DASH_ARRAY}`,
    );
  }

  useEffect(() => {
    startTimer();

    return () => onTimesUp();
  }, []);

  return (
    <div className="Timer">
      <div className="base-timer">
        <svg
          className="base-timer__svg"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g className="base-timer__circle">
            <circle
              className="base-timer__path-elapsed"
              cx="50"
              cy="50"
              r="45"
            />
            <path
              id="base-timer-path-remaining"
              strokeDasharray={circleDasharray}
              style={{ color: remainingPathColor }}
              className={`base-timer__path-remaining`}
              d="
                          M 50, 50
                          m -45, 0
                          a 45,45 0 1,0 90,0
                          a 45,45 0 1,0 -90,0
                        "
            />
          </g>
        </svg>
        <span
          id="base-timer-label"
          className="base-timer__label"
        >
          {formatTime(timeLeft)}
        </span>
      </div>
    </div>
  );
};

export default Timer;
