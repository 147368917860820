import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { IUserRole, IPledge } from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { IGroupState } from '../../../reducers/group';
import { IAppState } from '../../../store';
import { createToast } from '../../../actions/toaster';
import SortableTable, { ISortableTableColumn } from '../../SortableTable/SortableTable';
import {
  routes,
  swapRouteParams,
  formatCurrency,
  defaultCurrency,
  toastError,
} from '../../../helpers';
import Button from '../../Button/Button';
import Checkbox from '../../Checkbox/Checkbox';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { userSelectors } from '../../../selectors/user';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  donationId?: string;
  pledges: Array<IPledge>;
  locale: string;

  onClose(): void;
  createToast(toast: IToast): void;
}

interface IState {
  columns: ISortableTableColumn[];
  data: Array<IPledge>;
  pledgeId: string;
}

class ApplyPledge extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      columns: [
        { label: '', id: 'checkboxes', sortable: false },
        { label: 'Start Date', id: 'startDate', sortable: false },
        { label: 'End Date', id: 'endDate', sortable: false },
        { label: 'Pledge amount', id: 'pledgeAmt', sortable: false },
        { label: 'Donations Applied', id: 'applied', sortable: false },
      ],
      data: this.props.pledges,
      pledgeId: '',
    };
  }

  convertTableData() {
    let currency = this.props.groupState.group.account?.currency || defaultCurrency;
    let isSelectedPledge = (id: string) => {
      return this.state.pledgeId === id;
    };

    return this.state.data.map((d: IPledge) => {
      const startDate = d?.start_date ?? d?.recurrence_start_date ?? new Date();
      const startDateFormatted = localizeHelpers.formatDate(
        startDate,
        LocaleDateFormats.ll,
        this.props.locale,
      );
      const endDateFormatted = d?.end_date
        ? localizeHelpers.formatDate(d?.end_date, LocaleDateFormats.ll, this.props.locale)
        : 'N/A';
      let id = d?.id || '';

      let checkbox = (
        <Checkbox
          name="pledgeId"
          onChange={(e: any) => {
            this.state.pledgeId === id
              ? this.setState({ pledgeId: '' })
              : this.setState({ pledgeId: id });
          }}
          checked={isSelectedPledge(id)}
          value={'selectedPledge'}
          color="work"
          label={this.props.t('')}
        />
      );

      return {
        row: [
          { content: checkbox, id: 'checkboxes' },
          { content: `${startDateFormatted}`, id: 'startDate', notranslate: 'yes' },
          { content: `${endDateFormatted}`, id: 'endDate', notranslate: 'yes' },
          {
            content: `${formatCurrency(d?.total_yearly_amount || 0, currency, this.props.locale)}`,
            id: 'pledgeAmt',
            notranslate: 'yes',
          },
          {
            content: `${localizeHelpers.formatNumber(d?.number_of_donations_applied || 0, this.props.locale)}`,
            id: 'applied',
            notranslate: 'yes',
          },
        ],
      };
    });
  }

  applyToPledge() {
    axios
      .put(
        swapRouteParams(routes.APPLY_DONATION_TO_PLEDGE, {
          groupId: this.props.groupState.group.id,
          donorId: this.props.contact.user?.id,
          donationId: this.props.donationId,
          pledgeId: this.state.pledgeId,
        }),
      )
      .then(() => {
        this.props.onClose();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Apply Pledge To Donation');
        this.props.createToast(toast);
      });
  }

  render() {
    let { t } = this.props;

    return (
      <div className="ApplyPledge">
        <div className="af">
          <div className="af-title reduce-pad-btm">{t('Apply Donation to Pledge')}</div>
          <div className="af-blurb">
            {t('Select a pledge that will be applied to your donation')}
          </div>

          <SortableTable
            {...this.props}
            columns={this.state.columns}
            data={this.convertTableData()}
          />

          <div className="af-actions">
            <Button
              text={t('Apply Donation')}
              onClick={(e: any) => {
                e.preventDefault();
                this.applyToPledge();
              }}
            />
            <Button
              text={t('Cancel')}
              onClick={(e: any) => {
                e.preventDefault();
                this.props.onClose();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ApplyPledge),
);
