import { IGroup } from '@gigit/interfaces';
import React from 'react';
import { IOnboardStep } from '../interfaces';
import Portrait from '../../../Portrait/Portrait';

import './InfoSection.scss';
import { Link } from 'react-router-dom';

const claimCharityTextUpper = "Not a registered charity or can't find your organization?";
const claimCharityTextLowerFirst = 'Click ';
const claimCharityTextLowerSecond =
  ' and we will review your submission and help you get your organization on Kambeo';

interface IProps {
  title: string;
  type: string;
  currentStep: string;
  steps: Array<IOnboardStep>;
  hasImage?: boolean;
  selectedGroup?: IGroup;
}

export const InfoSection: React.FC<IProps> = (props: IProps) => {
  function getActiveStep() {
    return props.steps.find((step) => {
      if (step.id === props.currentStep) {
        return step;
      } else {
        return null;
      }
    });
  }

  function renderSteps() {
    return props.steps.map((step) => (
      <i
        key={step.id}
        className={step.id === props.currentStep ? 'fas fa-circle active' : 'fas fa-circle'}
      />
    ));
  }

  /**
   * Special Handling For dynamic string replacement. Dynamic text is sometimes required based on a users form choices
   * @param text the infoSection text in which requires dynamic replacement
   * */
  function getStepText(text: string) {
    if (props.currentStep === 'groupInfo') {
      const replaceWith = props.type?.toLowerCase();
      return text.replace('{{type}}', replaceWith);
    } else if (props.currentStep === 'imageSelection') {
      return text.replace('{{firstName}}', props.type);
    } else {
      return text;
    }
  }

  const activeStep = getActiveStep();
  const stepText = activeStep?.text || '';
  const stepNumber = activeStep ? props.steps.indexOf(activeStep) + 1 : null;

  return (
    <div className="InfoSection">
      <div className="info-wrapper">
        <div className="title">{props.title}</div>
        <div
          className="title mobile"
          key={stepNumber}
        >
          {props.title}:{' '}
          <span className="mobile">
            Step {stepNumber} of {props.steps.length}
          </span>
        </div>
        <div className="text">{getStepText(stepText)}</div>
        <div className="step-indicator">{renderSteps()}</div>
        {props.selectedGroup?.id && (
          <div className="group">
            {' '}
            <Portrait
              size={80}
              source={props.selectedGroup.profile_image_url}
            />{' '}
          </div>
        )}
        {/* TODO: Using tips class for now, make into own subText component */}
        {getActiveStep()?.subText && getActiveStep()?.id !== 'charitiesSelectionGroup' && (
          <div className="tips">{getActiveStep()?.subText}</div>
        )}
        {getActiveStep()?.tip && getActiveStep()?.id !== 'charitiesSelectionGroup' && (
          <div className="tips">Tip: {getActiveStep()?.tip}</div>
        )}
        {getActiveStep()?.id === 'baseUserInfo' && (
          <div className="login">
            <span>
              Already a member? &nbsp;&nbsp;
              <Link
                className="login-link"
                to={'/login?redirect='}
              >
                Sign in
              </Link>
            </span>{' '}
          </div>
        )}
        {getActiveStep()?.id === 'charitiesSelectionGroup' && (
          <div className="claim-charities-info">
            <div>{claimCharityTextUpper}</div>
            <div>
              {claimCharityTextLowerFirst} <span style={{ fontWeight: 900 }}>skip</span>{' '}
              {claimCharityTextLowerSecond}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
