import {
  IGetMetricParams,
  IGetMetricParamsBulk,
  IGetMetricValue,
  IGetKPIResult,
  IGetMetricValuesBulk,
  IGetKPIValuesBulk,
  IMetricDefinition,
  IGetKPIParams,
  IGetKPIParamsBulk,
} from '@gigit/interfaces';
import axios from 'axios';
import { routes, swapRouteParams } from '../helpers';

/** Here we omit the types because they're of Type.ObjectId which FE can't use */
export interface IGetMetricParamsFE
  extends Omit<IGetMetricParams, 'object_id' | 'event_id' | 'sub_filters'> {
  object_id: string;
  sub_filters?: {
    cause?: string;
    event_id?: string;
    hub_id?: string;
  };
}

export interface IGetMetricParamsBulkFE extends Omit<IGetMetricParamsBulk, 'metrics'> {
  metrics: IGetMetricParamsFE[];
}

export interface IGetMetricValueFE extends Omit<IGetMetricValue, 'object_id'> {
  object_id: string;
}

export interface IGetMetricValuesBulkFE extends Omit<IGetMetricValuesBulk, 'metrics'> {
  metrics: IGetMetricValueFE[];
}

export interface metricLabels {
  volunteer_hours_submitted: number;
  volunteer_hours_approved: number;
  volunteer_hours_completed: number;
  volunteer_hours_total: number;
  amount_donated: number;
  amount_fundraised: number;
  amount_raised: number;
  num_fundraisers: number;
  num_auction_items_sold: number;
  num_groups_supported: number;
  num_events_supported: number;
}

export namespace metricRequestActions {
  export async function getAvailableMetricsForObjectType(objectType: string) {
    const response = await axios.get<IMetricDefinition[]>(
      swapRouteParams(routes.GET_METRICS_FOR_OBJECT_TYPE, { objectType }),
    );
    return response.data;
  }

  export async function getMetric(metricInfo: IGetMetricParamsFE) {
    const response = await axios.post<IGetMetricValueFE>(routes.GET_METRIC, metricInfo);
    return response.data;
  }

  export async function getMetricsBulk(bulkMetricInfo: IGetMetricParamsBulkFE) {
    const response = await axios.post<IGetMetricValuesBulkFE>(
      routes.GET_METRIC_BULK,
      bulkMetricInfo,
    );
    return response.data;
  }

  export async function getMetricsKpi(param: IGetKPIParams) {
    const response = await axios.post<IGetKPIResult>(routes.GET_METRICS_KPI, param);
    return response.data;
  }

  export async function getMetricsKpiBulk(param: IGetKPIParamsBulk) {
    const response = await axios.post<IGetKPIValuesBulk>(routes.GET_METRICS_KPI_BULK, param);
    return response.data;
  }
}
