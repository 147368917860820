import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { IUserRole, ISponsor, IEventSponsor } from '@gigit/interfaces';
import {
  routes,
  swapRouteParams,
  defaultCurrency,
  formatCurrency,
  toastError,
} from '../../../helpers';
import { IGroupState } from '../../../reducers/group';
import { createToast } from '../../../actions/toaster';
import SortableTable, { ISortableTableColumn } from '../../SortableTable/SortableTable';
import Modal from '../../Modal/Modal';
import ActivityLog from '../ActivityLog/ActivityLog';
import { IAppState } from '../../../store';
import ContactCard from '../ContactCard/ContactCard';
import './Sponsor.scss';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { userSelectors } from '../../../selectors/user';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  locale: string;

  createToast(toast: IToast): void;
}

interface IState {
  tabs: Array<string>;
  activeTab: number;
  sponsorships: Array<IEventSponsor>;
  sponsor: ISponsor | null;
  columns: ISortableTableColumn[];
  showActivityLog: boolean;
  loading: boolean;
}

class Sponsor extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tabs: ['Sponsorships'],
      activeTab: 0,
      sponsorships: [],
      sponsor: null,
      columns: [
        { label: 'Event', id: 'event', sortable: false },
        { label: 'Name of Sponsor', id: 'sponsor-name', sortable: false },
        { label: 'Sponsorship', id: 'sponsorship', sortable: false },
        { label: 'Amount', id: 'amount', sortable: false },
        { label: 'URL', id: 'url', sortable: false },
        { label: 'Actions', id: 'actions', sortable: false },
      ],
      showActivityLog: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.getSponsorships();
    this.getSponsorData();
  }

  getSponsorships() {
    this.setState({
      loading: true,
    });
    axios
      .get(
        swapRouteParams(routes.GET_SPONSORSHIPS_FOR_USER, {
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user?.id,
        }),
      )
      .then((response) => {
        this.setState({
          sponsorships: response.data,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Contact Sponsorships');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getSponsorData() {
    axios
      .get(
        swapRouteParams(routes.GET_SPONSOR_INFO_FOR_USER, {
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user?.id,
        }),
      )
      .then((response) => {
        this.setState({
          sponsor: response.data,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Sponsor Summary');
        this.props.createToast(toast);
      });
  }

  convertTableData() {
    let currency = this.props.groupState.group.account?.currency || defaultCurrency;
    return this.state.sponsorships.map((s: IEventSponsor) => {
      return {
        row: [
          {
            content: <Link to={`/event/${s.event_handle}`}>{s.event_handle}</Link>,
            id: 'event',
            notranslate: 'yes',
          },
          { content: `${s.name}`, id: 'sponsor-name', notranslate: 'yes' },
          { content: `${s.tier_name}`, id: 'sponsorship', notranslate: 'yes' },
          {
            content: `${formatCurrency(s.sponsor_value, currency, this.props.locale)}`,
            id: 'amount',
            notranslate: 'yes',
          },
          {
            content: (
              <a href={`//${s.website}`}>
                <span>{s.website}</span>
              </a>
            ),
            id: 'status',
            notranslate: 'yes',
          },
          {
            id: 'actions',
            menu: [
              {
                icon: 'far fa-file-alt',
                onClick: (e: any) => {
                  this.setState({ showActivityLog: true });
                },
                label: 'Activity Log',
              },
            ],
          },
        ],
      };
    });
  }

  render() {
    let currency = this.props.groupState.group?.account?.currency || defaultCurrency;
    let data = this.convertTableData();
    let isDataEmpty = data.length === 0;
    let isSponsorTab = this.state.activeTab === 0;

    return (
      <div className="Sponsor">
        <ul className="Contact-menu">
          {this.state.tabs.map((item, index) => {
            return (
              <li
                onClick={() => {
                  this.setState({ activeTab: index });
                }}
                className={this.state.activeTab === index ? 'active no-select' : 'no-select'}
                key={index}
              >
                <span>{item}</span>
              </li>
            );
          })}
        </ul>
        <div className="Contact-content">
          <div className="Contact-side">
            <ContactCard
              groupId={this.props.groupState.group.id}
              {...this.props}
            />
          </div>
          <div className="contact-main">
            {isSponsorTab && (
              <div className="stat-row">
                <div className="cd-stat-row">
                  <div className="cd-stat-box">
                    <div
                      notranslate="yes"
                      className="stat"
                    >
                      {formatCurrency(
                        this.state.sponsor?.total_value || 0,
                        currency,
                        this.props.locale,
                      )}
                    </div>
                    <div className="stat-label">Total Sponsored</div>
                  </div>
                  <div className="cd-stat-box">
                    <div
                      notranslate="yes"
                      className="stat"
                    >
                      {localizeHelpers.formatNumber(
                        this.state.sponsor?.number_of_sponsorships || 0,
                        this.props.locale,
                      )}
                    </div>
                    <div className="stat-label">Sponsorships</div>
                  </div>
                  <div className="cd-stat-box">
                    <div
                      notranslate="yes"
                      className="stat"
                    >
                      {formatCurrency(
                        this.state.sponsor?.average_value || 0,
                        currency,
                        this.props.locale,
                      )}
                    </div>
                    <div className="stat-label">Average Amount</div>
                  </div>
                  <div className="cd-stat-box">
                    <div
                      notranslate="yes"
                      className="stat"
                    >
                      {`${localizeHelpers.formatDate(this.state.sponsor?.updated_at ?? new Date(), LocaleDateFormats.XLL, this.props.locale)}`}
                    </div>
                    <div className="stat-label">Last Sponsorship</div>
                  </div>
                </div>
              </div>
            )}
            {isSponsorTab && (
              <div className="data">
                <SortableTable
                  columns={this.state.columns}
                  data={data}
                  {...this.props}
                />

                {isDataEmpty && (
                  <div className="empty-data">This user has no sponsorships, yet.</div>
                )}
              </div>
            )}
            <Modal
              class="activity-log-modal"
              show={this.state.showActivityLog}
              onClose={() => this.setState({ showActivityLog: false })}
            >
              <ActivityLog {...this.props} />
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sponsor);
