import { IForm, IFormQuestion, IFormQuestionOption } from '@gigit/interfaces';
import React, { useState, useEffect, useRef } from 'react';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import TextField from '../../TextField/TextField';
import './ApplicationFormBuilder.scss';
import { FormPreview } from './FormPreview';
import { capitalizeString } from '../../../helpers';
import { Constants } from '@gigit/constants';

interface IProps {
  ownerType?: string;
  ownerId?: string;
  form?: IForm;
  updateFormData(formData: Partial<IForm>): void;
  storeItemContentModalRef?: React.RefObject<HTMLDivElement>;
  storeItemContentModalHideScroll?(v: boolean): void;
}

const defaultFormName = 'New Custom Form';

export const ApplicationFormBuilder: React.FC<IProps> = (props: IProps) => {
  const [formData, setFormData] = useState<IForm>({
    form_name: defaultFormName,
    owner_id: props?.ownerId || '',
    owner_type: props?.ownerType || '',
    questions: [],
  });
  const [optionValue, setOptionValue] = useState<string>('');
  const [optionIndex, setOptionIndex] = useState<number>(-1);
  const [questionIndex, setQuestionIndex] = useState<number>(-1);
  const [showOption, setShowOption] = useState<boolean>(false);
  const [scrollToY, setScrollToY] = useState<number>(0);
  const [showContent, setShowContent] = useState<boolean>(false);

  const addFormOptionModalInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props?.form) {
      setFormData(props.form);
    }
  }, []);

  useEffect(() => {
    props.updateFormData(formData);
  }, [formData]);

  useEffect(() => {
    if (addFormOptionModalInputRef?.current && showOption) {
      addFormOptionModalInputRef.current.focus();
    } else {
      props?.storeItemContentModalRef?.current?.scrollTo(0, scrollToY);
    }
  }, [showOption]);

  function addOption() {
    let currentFormData = { ...formData };
    const hasOptions = handleHasOption(currentFormData, questionIndex);

    hasOptions?.push({
      id: '',
      label: optionValue,
      value: optionValue,
    });

    setFormData(currentFormData);
    props?.storeItemContentModalHideScroll?.(false);
    setShowOption(false);
    setOptionIndex(-1);
    setQuestionIndex(-1);
    setOptionValue('');
  }

  function handleHasOption(form: IForm, questionPosition: number) {
    return (
      form.questions &&
      form.questions[questionPosition] &&
      form.questions[questionPosition]?.options
    );
  }

  function removeOption(questionPosition: number, optionPosition: number) {
    let currentFormData = { ...formData };
    const hasOptions = handleHasOption(currentFormData, questionPosition);
    hasOptions?.splice(optionPosition, 1);

    setFormData(currentFormData);
  }

  function handleTitleUpdate(title: string) {
    setFormData({ ...formData, form_name: title });
  }

  function handleQuestionUpdate(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;

    const newFormData: IForm = { ...formData };
    let question = newFormData.questions && newFormData.questions[index];

    if (question) {
      question.label = value as string;
    }

    setFormData(newFormData);
  }

  function triggerEditOption(index: number, value: string, optIndex: number) {
    setOptionIndex(optIndex);
    setQuestionIndex(index);
    setOptionValue(value);
    props?.storeItemContentModalHideScroll?.(true);
    setShowOption(true);
  }

  function editOption() {
    let currentFormData = { ...formData };
    const hasOptions = handleHasOption(currentFormData, questionIndex);

    if (hasOptions && hasOptions[optionIndex]) {
      hasOptions[optionIndex].value = optionValue;
      hasOptions[optionIndex].label = optionValue;
    }

    setFormData(currentFormData);
    props?.storeItemContentModalHideScroll?.(false);
    setShowOption(false);
    setOptionIndex(-1);
    setQuestionIndex(-1);
    setOptionValue('');
  }

  function saveOption() {
    if (optionIndex !== -1) {
      editOption();
    } else {
      addOption();
    }
  }

  function handleNewOption(index: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setShowOption(true);
    setOptionValue('');
    setOptionIndex(-1);
    setQuestionIndex(index);
    setScrollToY(e.currentTarget.offsetTop);
    props?.storeItemContentModalHideScroll?.(true);
  }

  function removeQuestion(index: number) {
    let newFormData = { ...formData };
    newFormData.questions && newFormData.questions.splice(index, 1);
    setFormData(newFormData);
  }

  function getQuestionViewTemplate(question: IFormQuestion, index: number) {
    return (
      <div
        className={'question ' + question.question_type}
        key={index}
      >
        <div className="question-type-container">
          <span className="question-type">{capitalizeString(question.question_type)} Question</span>
          <div
            className="actions"
            onClick={() => removeQuestion(index)}
          >
            <i className="far fa-trash-alt" />
          </div>
        </div>
        <div className="row">
          <TextField
            autoComplete="off"
            label="Question"
            placeholder="Type your question here"
            value={question.label}
            name="label"
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleQuestionUpdate(e, index)}
          />
        </div>
        {(question.question_type === 'dropdown' || question.question_type === 'checkbox') && (
          <div className="options">
            <span className="opt-title">Options</span>
            <div className="options-inner">
              {question?.options?.map((option: IFormQuestionOption, optIndex: number) => {
                return (
                  <div
                    key={'option-' + optIndex}
                    className="option"
                    notranslate="yes"
                  >
                    <span>{option.label}</span>
                    <div className="opt-actions">
                      <i
                        onClick={() => triggerEditOption(index, option.value, optIndex)}
                        className="far fa-pencil"
                      />
                      <i
                        onClick={() => removeOption(index, optIndex)}
                        className="far fa-trash-alt"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              onClick={(e) => handleNewOption(index, e)}
              className="option add-opt"
            >
              <i className="far fa-plus" /> Add Option
            </div>
          </div>
        )}
      </div>
    );
  }

  function renderAddOption() {
    return (
      <Modal
        dontToggleBodyOverflow={true}
        closeIcon="fas fa-times"
        class="AddFormOptionModal"
        show={showOption}
        onClose={() => {
          props?.storeItemContentModalHideScroll?.(false);
          setShowOption(false);
        }}
      >
        <form
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            saveOption();
          }}
          className="add-option-modal"
        >
          <div className="title">Option</div>
          <TextField
            reference={addFormOptionModalInputRef}
            autoComplete="off"
            label="Option Value"
            required={true}
            value={optionValue}
            type="text"
            name="optionValue"
            onChange={(e) => {
              setOptionValue(e.target.value);
            }}
          />
          <div className="actions">
            <Button
              buttonType="dark"
              type="submit"
              text={optionIndex !== -1 ? 'Save' : 'Add'}
            />
          </div>
        </form>
      </Modal>
    );
  }

  function addQuestion(type: string) {
    let _newQuestion = {
      label: '',
      id: '',
      form_id: '',
      owner_type: props.ownerType,
      owner_id: props.ownerId,
      question_type: type,
      required: false,
      options: [],
      created_at: new Date(),
      updated_at: new Date(),
    };

    let newFormData: IForm = { ...props.form, ...formData };
    if (props.form?.form_name) {
      newFormData.form_name = props.form.form_name;
    }

    if (newFormData?.questions) {
      newFormData.questions.push(_newQuestion);
    }

    setFormData(newFormData);
  }

  function renderCustomFieldMode() {
    return (
      <>
        <div className="questions">
          <div className="form-title">
            <div className="title-inner">
              <TextField
                label="Form Name"
                value={formData.form_name}
                name="form_name"
                type="text"
                onChange={(e) => {
                  handleTitleUpdate(e.target.value);
                }}
              />
            </div>
          </div>
          {(props.ownerType === Constants.object_type.gig ||
            props.ownerType === Constants.object_type.group) &&
            renderDefaultPreview()}
          {formData.questions?.map((question: IFormQuestion, index: number) => {
            return getQuestionViewTemplate(question, index);
          })}
        </div>
        <div className="add">
          <div className="action">
            <i className="far fa-plus" /> Add Question
          </div>
          <div className="options">
            <ul>
              <li onClick={() => addQuestion('text')}>Text Question</li>
              <li onClick={() => addQuestion('dropdown')}>Dropdown Question</li>
              <li onClick={() => addQuestion('checkbox')}>Checkbox Question</li>
            </ul>
          </div>
        </div>
        {renderAddOption()}
      </>
    );
  }

  function renderDefaultPreview() {
    return (
      <div className="question">
        <div className={`question-type-container ${!showContent ? 'no-border' : ''}`}>
          <span className="question-type">Default Application Information (Required)</span>
          <div
            className="actions"
            onClick={() => setShowContent(!showContent)}
          >
            <i className={showContent ? 'fas fa-chevron-up' : 'fas fa-chevron-down'} />
          </div>
        </div>
        {showContent && (
          <div className="FormPreview-container">
            <FormPreview />
          </div>
        )}
      </div>
    );
  }

  return <div className="ApplicationFormBuilder">{renderCustomFieldMode()}</div>;
};
