import {
  ILoggedVolunteerHoursCreateParamsFE,
  ILoggedVolunteerHoursSummary,
} from '@gigit/interfaces';
import React, { useEffect, useState } from 'react';
import { useLocale } from '../../hooks';
import useToastDispatcher from '../../hooks/useToaster';
import { localizeHelpers, LocaleDateFormats } from '../../localizeHelpers';
import { userRequestActions } from '../../requestActions';
import Button from '../Button/Button';
import DatePicker from '../shared/forms/DatePicker/DatePickerField/DatePickerField';
import SelectField from '../shared/forms/select/SelectField/SelectField';
import { ISelectOption } from '../shared/forms/select/types';
import KambeoTabs, { IKambeoTab } from '../shared/Tabs/Tabs';
import TextField from '../TextField/TextField';
import HistoryTable from './HistoryTable';
import HoursTable from './HoursTable';
import './LogVolunteerHours.scss';
import CauseSelectionModal, {
  ISelectedGroup,
} from '../shared/CauseSelectionModal/CauseSelectionModal';
import Portrait from '../Portrait/Portrait';
import ToggleSwitch from '../shared/forms/ToggleSwitch/ToggleSwitch';

interface ISelectedGig extends ISelectOption {
  id: string;
  group_id?: string;
  hub_id?: string;
  event_id?: string;
}
export interface ILogVolunteerHoursProps {
  onHoursSubmitted?: () => void;
  isEditing?: boolean;
  hoursToEdit?: ILoggedVolunteerHoursSummary;
}
export default function LogVolunteerHours(props: ILogVolunteerHoursProps) {
  const [gigOptions, setGigOptions] = useState<ISelectedGig[]>([]);
  const [selectedGig, setSelectedGig] = useState<ISelectedGig | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedHours, setSelectedHours] = useState<string>('');
  const [selectedMinutes, setSelectedMinutes] = useState<string>('');
  const [hoursToLog, setHoursToLog] = useState<ILoggedVolunteerHoursCreateParamsFE[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<ISelectedGroup | undefined>(undefined);
  const [showCauseSelectionModal, setShowCauseSelectionModal] = useState<boolean>(false);
  const [selectedDonationType, setSelectedDonationType] = useState<string>('on-platform');
  const [customCauseName, setCustomCauseName] = useState<string>('');

  const locale = useLocale();
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  useEffect(() => {
    getUserApplications();
  }, []);

  useEffect(() => {
    if (props?.hoursToEdit) {
      const hoursToEdit = props.hoursToEdit;
      const hoursDate = localizeHelpers.formatDate(
        hoursToEdit?.start_date,
        LocaleDateFormats.LL,
        locale.currentLocale,
      );
      setSelectedGig({
        label: hoursToEdit?.gig?.title ?? '',
        value: hoursToEdit.gig_id ?? '',
        imageURL: hoursToEdit?.gig?.profile_image_url,
        id: hoursToEdit?.gig?.id!,
        group_id: hoursToEdit?.gig?.group_id,
        event_id: hoursToEdit?.gig?.event_id,
        hub_id: hoursToEdit?.gig?.hub_id,
      });
      setSelectedHours(hoursToEdit.hours.toString());
      setSelectedMinutes(hoursToEdit?.minutes ? hoursToEdit.minutes.toString() : '0');
      setSelectedDate(hoursDate);
    }
  }, [props.hoursToEdit]);

  const isDateRowFieldDisabled = () => {
    if (isCustomCause()) {
      return !customCauseName;
    }

    return !selectedGig && !selectedGroup;
  };

  const isAddHoursButtonDisabled = () => {
    const isCustomCause = selectedDonationType === 'custom';

    if (isCustomCause) {
      return !selectedDate || !selectedHours || !customCauseName;
    }

    return !selectedDate || !selectedHours || (!selectedGig && !selectedGroup);
  };

  const getUserApplications = async () => {
    try {
      let gigs = await userRequestActions.getUserGigsForLoggingHours();
      const userGigOptions = gigs.map((gig) => {
        return {
          label: gig!.title!,
          value: gig!.id!, //should be defined
          imageURL: gig!.profile_image_url,
          id: gig?.id!,
          group_id: gig?.group_id,
          event_id: gig?.event_id,
          hub_id: gig?.hub_id,
        };
      });
      setGigOptions(userGigOptions);
    } catch (e) {
      dispatchToastError(e, 'Error getting user applications');
    }
  };

  const onAddHourRow = () => {
    const newHourBlock = (): ILoggedVolunteerHoursCreateParamsFE => {
      if (isCustomCause()) {
        return {
          hours: parseInt(selectedHours),
          minutes: parseInt(selectedMinutes || '0'),
          start_date: selectedDate,
          custom: true,
          custom_cause_name: customCauseName,
        };
      } else {
        return {
          gig_id: selectedGig?.value,
          hours: parseInt(selectedHours),
          minutes: parseInt(selectedMinutes || '0'),
          start_date: selectedDate,
          group_id: selectedGig?.value ? selectedGig?.group_id : selectedGroup?.id,
          event_id: selectedGig?.value ? selectedGig?.event_id : undefined,
        };
      }
    };

    setHoursToLog([...hoursToLog, newHourBlock()]);
    clearDateAndHours();
  };

  const onRemoveHourRow = (index: number) => {
    let newHours = [...hoursToLog];
    newHours.splice(index, 1);
    setHoursToLog(newHours);
  };

  const onReportHours = async () => {
    // loop through hoursToLog and create a payload for each
    const payload = hoursToLog.map((hour) => {
      if (hour.custom) {
        return {
          start_date: hour.start_date,
          hours: hour.hours,
          minutes: hour.minutes,
          custom: true,
          custom_cause_name: customCauseName,
        };
      }

      return {
        gig_id: hour.gig_id,
        group_id: hour.group_id,
        hub_id: hour.hub_id,
        start_date: hour.start_date,
        hours: hour.hours,
        minutes: hour.minutes,
      };
    });

    try {
      await userRequestActions.logVolunteeringHoursBulk(payload);
      dispatchToastSuccess(
        localizeHelpers.translate(`You've successfully logged your hours!`),
        'Hours Logged',
      );
      props.onHoursSubmitted?.();
    } catch (error) {
      dispatchToastError(error, 'Error logging hours');
    }
  };

  const onEditHours = async () => {
    const payload = {
      gig_id: selectedGig?.value,
      group_id: selectedGig?.group_id,
      hub_id: selectedGig?.hub_id,
      event_id: selectedGig?.event_id,
      start_date: selectedDate,
      hours: parseInt(selectedHours),
      minutes: parseInt(selectedMinutes),
    };

    try {
      const id = props?.hoursToEdit?.id ?? '';
      await userRequestActions.editVolunteerHour(id, payload);
      dispatchToastSuccess(
        localizeHelpers.translate(`You've successfully logged your hours!`),
        'Hours Logged',
      );
      if (props.onHoursSubmitted) props.onHoursSubmitted();
    } catch (error) {
      dispatchToastError(error, 'Error logging hours');
    }
  };

  const handleRemoveSelected = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedGroup(undefined);
  };

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const minutes = event.target.value;

    if (parseInt(minutes) < 0 || parseInt(minutes) > 59 || parseInt(selectedHours) === 24) {
      return;
    } else {
      setSelectedMinutes(minutes);
    }
  };

  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hours = event.target.value;
    if (parseInt(hours) < 0 || parseInt(hours) > 24) return;

    setSelectedHours(hours);
    if (parseInt(hours) === 24) setSelectedMinutes('');
  };

  const isCustomCause = () => {
    return selectedDonationType === 'custom';
  };

  const handleDonationTypeChange = (value: string) => {
    setSelectedGig(null);
    setSelectedGroup(undefined);
    setCustomCauseName('');
    setSelectedDonationType(value);
  };

  const generateLogHoursContent = (): React.ReactNode => {
    return (
      <div className="LogHoursContent">
        {!props.isEditing && (
          <>
            <h2>Who did you volunteer for?</h2>
            <div className="row">
              <ToggleSwitch
                defaultValue="on-platform"
                options={[
                  { value: 'on-platform', label: 'Select from Dropdown' },
                  { value: 'custom', label: 'Custom Cause' },
                ]}
                onChange={handleDonationTypeChange}
              />
            </div>
            <div className="row">
              <CauseSelectionModal
                showCauseSelectionModal={showCauseSelectionModal}
                onClose={() => {
                  setShowCauseSelectionModal(false);
                }}
                onAddCause={(cause) => {
                  setSelectedGroup(cause[0]);
                  setShowCauseSelectionModal(false);
                  setSelectedGig(null);
                }}
                modalType="log_hours"
                selected={selectedGroup !== undefined ? Array(selectedGroup) : undefined}
              />
              {isCustomCause() && (
                <TextField
                  name="Custom Cause Name"
                  label="Custom Cause Name"
                  value={customCauseName}
                  onChange={(e) => setCustomCauseName(e.target.value)}
                />
              )}
              {!isCustomCause() && (
                <div
                  className={`select-cause-container ${props.isEditing ? 'disabled' : ''}`}
                  onClick={(e) => {
                    setShowCauseSelectionModal(true);
                  }}
                >
                  <label className="select-cause-label">Select A Cause</label>
                  {selectedGroup === undefined && (
                    <span className="select-cause-empty">Select...</span>
                  )}
                  {selectedGroup !== undefined && (
                    <span className={`select-cause-selected ${props.isEditing ? 'disabled' : ''}`}>
                      <Portrait
                        size={24}
                        currentImage={selectedGroup.imageURL}
                      />
                      <span className="selected-cause-name">{selectedGroup.label}</span>
                      <i
                        className="fal fa-times"
                        onClick={handleRemoveSelected}
                      />
                    </span>
                  )}
                </div>
              )}
            </div>

            {!isCustomCause() && (
              <div className="row">
                <SelectField
                  defaultValue={selectedGig}
                  isDisabled={props.isEditing}
                  selectedOption={!selectedGig ? { label: 'Select...', value: '' } : undefined}
                  label="Select A Volunteer Opportunity"
                  onChange={(option) => {
                    setSelectedGig((option as ISelectedGig) || null);
                  }}
                  options={
                    selectedGroup
                      ? gigOptions.filter((gig) => gig.group_id === selectedGroup.id)
                      : gigOptions
                  }
                />
              </div>
            )}
          </>
        )}

        <h2>When did you volunteer?</h2>
        <div className="row date">
          <DatePicker
            disabled={isDateRowFieldDisabled()}
            name="hoursDate"
            label="Date"
            value={selectedDate}
            onChange={(updatedDate) => {
              if (updatedDate) {
                setSelectedDate(updatedDate);
              } else if (selectedDate) {
                setSelectedDate(selectedDate);
              }
            }}
          />
          <TextField
            disabled={isDateRowFieldDisabled()}
            name="Hours"
            type="number"
            label="Hours"
            value={selectedHours}
            min="1"
            max="24"
            className="time-input"
            onChange={handleHoursChange}
          />
          <TextField
            disabled={isDateRowFieldDisabled()}
            name="Minutes"
            type="number"
            label="Minutes"
            value={selectedMinutes}
            min="0"
            max="59"
            className="time-input"
            onChange={handleMinutesChange}
          />
          {!props.isEditing && (
            <Button
              isDisabled={isAddHoursButtonDisabled()}
              text="Add"
              onClick={onAddHourRow}
            />
          )}
        </div>
        {!props.isEditing && !!hoursToLog.length && (
          <div className="row">
            <HoursTable
              hours={hoursToLog}
              onRemove={(index) => onRemoveHourRow(index)}
            />
          </div>
        )}
      </div>
    );
  };
  const generateTabContent = (): IKambeoTab[] => {
    return [
      { id: 'Log', label: 'Log Hours', content: generateLogHoursContent() },
      { id: 'History', label: 'History', content: <HistoryTable /> },
    ];
  };
  const clearDateAndHours = () => {
    setSelectedDate('');
    setSelectedHours('');
    setSelectedMinutes('');
  };

  return (
    <div className={`LogVolunteerHours ${props.isEditing ? 'editing' : ''}`}>
      {!props.isEditing && <h2>Log Hours</h2>}
      {props.isEditing && <h2>Edit Log Entry</h2>}
      {!props.isEditing && <KambeoTabs tabs={generateTabContent()} />}
      {props.isEditing && generateLogHoursContent()}
      <div className="row actions">
        <Button
          text="Cancel"
          onClick={() => props?.onHoursSubmitted && props.onHoursSubmitted()}
          buttonType="outline-dark"
        />
        <Button
          isDisabled={!hoursToLog.length && !props.isEditing}
          text={props.isEditing ? `Save` : 'Log Hours'}
          onClick={() => (props.isEditing ? onEditHours() : onReportHours())}
          buttonType="dark"
        />
      </div>
    </div>
  );
}
