import React from 'react';
import { connect } from 'react-redux';
import Dropdown from '../../../Dropdown/Dropdown';
import { errorHelpers, handleInputChange, toastError } from '../../../../helpers';
import Button from '../../../Button/Button';
import TextField from '../../../TextField/TextField';
import { IAppState } from '../../../../store';
import { IGigState } from '../../../../reducers/gig';
import { ICause } from '@gigit/interfaces';
import { causeRequestActions } from '../../../../requestActions/causes';
import { createToast } from '../../../../actions/toaster';

interface IProps {
  gigState: IGigState;
  flow: string;
  gig: any;

  updateEntity(key: any, val: any): void;
}

interface IState {
  selectedCauses: Array<string>;
  causeValue: string;
  categoryOptions: Array<any>;
  subCategoryOptions: Array<any>;
  categories: Array<any>;
  gigCategory: string;
  gigSubCategory: string;
  hasPoliceCheck: boolean;
  isOSSDEligible: boolean;
  causes: ICause[];
}

class GigCategory extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedCauses: [],
      causeValue: '',
      gigCategory: '',
      gigSubCategory: '',
      categories: this.props.gigState?.gigCategories || [],
      categoryOptions: this.getCategories(),
      subCategoryOptions: this.hasSubCategory() ?? [],
      hasPoliceCheck: false,
      isOSSDEligible: false,
      causes: [],
    };

    this.handleGigCategory = this.handleGigCategory.bind(this);
    this.handleGigSubCategory = this.handleGigSubCategory.bind(this);
    this.getSubCategories = this.getSubCategories.bind(this);
  }

  componentDidMount() {
    this.initializeData();
    this.syncCauses();
  }

  initializeData() {
    this.setState({
      isOSSDEligible: this.props.gig.isOSSDEligible,
      hasPoliceCheck: this.props.gig.hasPoliceCheck,
      selectedCauses: [...this.props.gig.causes],
      gigCategory: this.props.gig.category,
      gigSubCategory: this.props.gig.subCategory,
    });
  }

  async syncCauses() {
    try {
      const causes = await causeRequestActions.getCauses();
      this.setState({ causes });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Add Cause');
      createToast(toast);
    }
  }

  getCategories() {
    let opts = this.props.gigState.gigCategories?.map((category: any) => {
      return { value: category.id, label: category.category };
    });

    if (opts) {
      opts.unshift({ value: '', label: 'Select A Category' });
    } else {
      opts = [];
    }

    return opts;
  }

  hasSubCategory() {
    if (this.props.gig.category) {
      let idx = this.props.gigState.gigCategories.findIndex((c: any) => {
        return c.id === this.props.gig.category;
      });

      if (idx) {
        let opts = (this.props.gigState.gigCategories[idx].sub_category ?? []).map((s: any) => {
          return { value: s, label: s };
        });

        return opts;
      }
    } else {
      let opts = (this.props.gigState.gigCategories[0]?.sub_category ?? []).map((s: any) => {
        return { value: s, label: s };
      });

      if (opts) {
        opts.unshift({ value: '', label: 'Select An Option' });
      } else {
        opts = [];
      }

      return opts;
    }
  }

  addCause() {
    let causes = [...this.state.selectedCauses];
    causes.push(this.state.causeValue);
    this.setState(
      {
        selectedCauses: causes,
        causeValue: '',
      },
      () => {
        let c = this.state.selectedCauses;
        this.props.updateEntity('causes', c);
      },
    );
  }

  removeCause(_id: string) {
    let causes = [...this.state.selectedCauses];
    causes = causes.filter((e) => e !== _id);

    this.setState(
      {
        selectedCauses: causes,
      },
      () => {
        let c = this.state.selectedCauses;
        this.props.updateEntity('causes', c);
      },
    );
  }

  handleGigCategory(type: string) {
    this.getSubCategories(type);
    this.props.updateEntity('category', this.state.gigCategory);
  }

  handleGigSubCategory(type: string) {
    this.props.updateEntity('sub_category', this.state.gigSubCategory);
  }

  getSubCategories(value: string) {
    let opts;
    let idx = this.state.categories.findIndex((c: any) => {
      return c.id === value;
    });

    if (idx || idx === 0) {
      opts = this.state.categories[idx].sub_category.map((s: any) => {
        return { value: s, label: s };
      });

      if (opts) {
        opts.unshift({ value: '', label: 'Select An Option' });
      } else {
        opts = [];
      }
    }

    this.setState({
      subCategoryOptions: opts,
    });
  }

  handlePoliceCheck() {
    this.setState(
      {
        hasPoliceCheck: !this.state.hasPoliceCheck,
      },
      () => {
        this.props.updateEntity('hasPoliceCheck', this.state.hasPoliceCheck);
      },
    );
  }

  handleOSSD() {
    this.setState(
      {
        isOSSDEligible: !this.state.isOSSDEligible,
      },
      () => {
        this.props.updateEntity('isOSSDEligible', this.state.isOSSDEligible);
      },
    );
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow}`}>
        <h2>Help potential applicants find your volunteer opportunity</h2>
        <div className="details-row">
          <Dropdown
            options={this.state.categoryOptions}
            shouldSort={true}
            value={this.state.gigCategory}
            label="Select a volunteer opportunity category"
            name="gigCategory"
            onChange={(e: any) => {
              handleInputChange(e, this, false, this.handleGigCategory, true);
            }}
          />
        </div>
        {this.state.subCategoryOptions && (
          <div className="details-row">
            <Dropdown
              options={this.state.subCategoryOptions}
              shouldSort={true}
              value={this.state.gigSubCategory}
              label="Select a volunteer opportunity sub-category"
              name="gigSubCategory"
              onChange={(e: any) => {
                handleInputChange(e, this, false, this.handleGigSubCategory, true);
              }}
            />
          </div>
        )}
        <div className="details-row">
          <span>What focus areas does your volunteer opportunity support?</span>
          <form
            className="add-cause"
            onSubmit={(e: any) => {
              e.preventDefault();
              this.addCause();
            }}
          >
            <TextField
              list="gigit-causes"
              placeholder="Add a focus area (Cancer Research, COVID-19, etc)"
              onChange={(e: any) => {
                handleInputChange(e, this);
              }}
              value={this.state.causeValue}
              name="causeValue"
              type="text"
            />
            <datalist id="gigit-causes">
              {this.state.causes.map((item, index) => {
                return (
                  <option
                    key={index}
                    id={item.id}
                    value={item.cause}
                  />
                );
              })}
            </datalist>
            <Button
              isDisabled={this.state.causeValue === ''}
              icon={'fa fa-plus'}
              text="Add Cause"
              type="submit"
            />
          </form>
          <ul className="user-causes">
            {this.state.selectedCauses.map((item: any, index: number) => {
              return (
                <li
                  key={index}
                  className="cause no-select"
                >
                  <span>{item}</span>
                  {
                    <i
                      onClick={() => {
                        this.removeCause(item);
                      }}
                      className="fa fa-times-circle"
                    />
                  }
                </li>
              );
            })}
          </ul>
        </div>
        <div className="details-row-alt">
          <div className="details">
            <p>Is a police check required?</p>
          </div>
          <div className="settings-control">
            <i
              onClick={() => {
                this.handlePoliceCheck();
              }}
              className={this.state.hasPoliceCheck ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}
            />
          </div>
        </div>
        <div className="details-row-alt">
          <div className="details">
            <p>Is this volunteer opportunity OSSD eligible?</p>
          </div>
          <div className="settings-control">
            <i
              onClick={() => {
                this.handleOSSD();
              }}
              className={this.state.isOSSDEligible ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    registerState: store.registerState,
  };
};

export default connect(mapStateToProps)(GigCategory);
