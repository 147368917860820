import { formatCurrency } from '../../../../helpers';
import { IAuctionItemSummary, ICampaign } from '@gigit/interfaces';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { defaultCurrency } from '../../../../helpers';
import { IEventState } from '../../../../reducers/event';
import { IGroupState } from '../../../../reducers/group';
import { IAppState } from '../../../../store';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import Portrait from '../../../Portrait/Portrait';
import { localizeHelpers } from '../../../../localizeHelpers';
import { IOwnerObject } from '../../../../interfaces';

import './AuctionItemLeaderboardModal.scss';
import { userSelectors } from '../../../../selectors/user';

interface IProps {
  showLeaderModal: boolean;
  item: IAuctionItemSummary;
  owner?: IOwnerObject;
  groupState: IGroupState;
  eventState: IEventState;
  locale: string;
  campaign?: ICampaign | null;

  onClose(): void;
  onPlaceBidClicked(): void;
  canPlaceBid?: boolean;
}

/** Modal for displaying auction item leaderboard. */
class AuctionItemLeaderboardModal extends Component<IProps> {
  render() {
    const currency = this.props?.owner?.account?.currency ?? defaultCurrency;

    return (
      <Modal
        show={this.props.showLeaderModal}
        onClose={this.props.onClose}
        title="Auction Leaderboard"
        description={this.props.item.name}
        notranslateDecription="yes"
      >
        <div className="AuctionItemLeaderboardModal">
          <div className="leaders">
            {this.props.item.bidders &&
              this.props.item.bidders.map((item: any, index: number) => {
                let _class = '';

                if (
                  index === this.props.item.bidders!.length - 1 ||
                  (index === this.props.item.bidders!.length - 2 &&
                    this.props.item.bidders!.length % 2 === 0)
                ) {
                  _class = 'no-border';
                }

                return (
                  <div
                    key={index}
                    className={_class !== '' ? 'leader ' + _class : 'leader'}
                  >
                    <Portrait
                      size={60}
                      currentImage={item.profile_image_url || ''}
                    />
                    <div className="leader-details">
                      <div
                        className="name"
                        notranslate="yes"
                      >
                        {item.display_name}
                      </div>
                      <div
                        className="bid"
                        notranslate="yes"
                      >
                        {formatCurrency(item.highest_bid_amount, currency, this.props.locale)}
                      </div>
                      <div className="count">
                        <var data-var="number_of_bids">
                          {localizeHelpers.formatNumber(
                            item.number_of_bids || 0,
                            this.props.locale,
                          )}
                        </var>{' '}
                        bid(s)
                      </div>
                    </div>
                    <div className="place">
                      <var data-var="place_index">{index + 1}</var>
                    </div>
                  </div>
                );
              })}
            {this.props.item.bidders && this.props.item.bidders.length === 0 && (
              <div className="no-bids">No one has bid on this item yet.</div>
            )}
          </div>
          {moment(new Date()).format('X') < moment(this.props.item.end_date).format('X') &&
            this.props.canPlaceBid !== false && (
              <div className="leader-actions">
                <Button
                  text="Place Bid"
                  onClick={this.props.onPlaceBidClicked}
                />
              </div>
            )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuctionItemLeaderboardModal);
