import React, { Fragment } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Config } from '@gigit/config';
import { IAddress, ICampaign, IEventSummaryFE, IPageComponent, IRole } from '@gigit/interfaces';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  assignableEventStatuses,
  dataURItoBlob,
  defaultCurrency,
  formatCurrency,
  getAddressParts,
  handleInputChange,
  hasPermission,
  routes,
  swapRouteParams,
  toastError,
  toastSuccess,
} from '../../../../helpers';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import errorHelpers from '../../../../helpers/errorHelpers';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { IOwnerObject, IToast } from '../../../../interfaces';
import { Constants } from '@gigit/constants';
import { History } from 'history';
import { IAppState } from '../../../../store';
import { createToast } from '../../../../actions/toaster';
import { IUserState } from '../../../../reducers/user';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import Share from '../../../../components/Share/Share';
import Store from '../../../../components/PageComponent/Store/Store';
import Portrait from '../../../../components/Portrait/Portrait';
import CoverImage from '../../../../components/CoverImage/CoverImage';
import TextField from '../../../../components/TextField/TextField';
import ChangeStatusMenu from '../../../../components/ChangeStatusMenu/ChangeStatusMenu';
import ContactUsModal from '../../../../components/ContactUsModal/ContactUsModal';
import donationMatchingImg from '../../../../assets/donation-matching.svg';
import './PageDetails.scss';
import { userSelectors } from '../../../../selectors/user';
import typeHelpers from '../../../../helpers/typeHelpers';
import { uiConstants } from '../../../../constants';
import { changeEventStatus } from '../../../../actions/event';
import { getGroupEvents } from '../../../../actions/group';
import { IGroupState } from '../../../../reducers/group';
import AddToCalendarModal from '../../AddToCalendarModal/AddToCalendarModal';
import ContactOrganizerModal from '../../../ContactOrganizerModal/ContactOrganizerModal';

interface IPageDetailActions {
  id: string;
  text: string;
  url?: string;
  onClick?(): void;
  active: boolean;
}

interface IProps extends RouteComponentProps<any> {
  loading: boolean;
  owner: IOwnerObject | null;
  campaign: ICampaign | null;
  role: IRole | null;
  onImageChange?(): void;
  onStatusChange?(): void;
  groupState: IGroupState;
  componentMetrics: IPageComponent[];
  userState: IUserState;
  ticketComponents: IPageComponent[];
  sponsorComponents: IPageComponent[];
  createToast(toast: IToast): void;
  changeEventStatus(
    eventId: string,
    status: string,
    callback?: (updatedEvent: IEventSummaryFE) => void,
  ): void;
  getGroupEvents(groupId: string, search?: string): void;
  locale: string;
  history: History;
}

interface IState {
  showMoreActions: boolean;
  editName: boolean;
  tempName: string;
  actions: IPageDetailActions[];
  showTickets: boolean;
  showSponsors: boolean;
  showContact: boolean;
  showShare: boolean;
  currentStatus: string;
  managers: string[];
  showAddToCalendarModal: boolean;
}

class PageDetails extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const event = this.props.owner
      ? typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'event')
      : null;

    this.state = {
      showMoreActions: false,
      editName: false,
      tempName: '',
      actions: [
        { id: 'donate', text: 'Donate', url: '/donate', active: false },
        {
          id: 'tickets',
          text: event?.register_action_text ?? 'Buy Tickets',
          onClick: () => {
            this.setState({ showTickets: true });
          },
          active: false,
        },
        { id: 'fundraise', text: event?.fundraise_action_text ?? 'Fundraise', active: false },
        {
          id: 'sponsor',
          text: event?.sponsorship_action_text ?? 'Sponsor',
          onClick: () => {
            this.setState({ showSponsors: true });
          },
          active: false,
        },
      ],
      showTickets: false,
      showSponsors: false,
      showContact: false,
      showShare: false,
      currentStatus: this.props.owner?.ownerStatus?.code || uiConstants.unknownStatus,
      managers: [],
      showAddToCalendarModal: false,
    };

    this.onImageChange = this.onImageChange.bind(this);
    this.getConditionalButtons = this.getConditionalButtons.bind(this);
    this.updateActions = this.updateActions.bind(this);
    this.getUserFundraiseStatus = this.getUserFundraiseStatus.bind(this);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.state.currentStatus !== prevState.currentStatus ||
      this.props.owner?.isActive !== prevProps.owner?.isActive ||
      this.props.ticketComponents !== prevProps.ticketComponents ||
      this.props.sponsorComponents !== prevProps.sponsorComponents ||
      this.props.owner?.object !== prevProps.owner?.object ||
      (this.props.owner !== null &&
        (this.props.userState.isLoggedIn !== prevProps.userState.isLoggedIn || //TODO: GIG-7896 - figure out what this does
          prevProps.componentMetrics !== this.props.componentMetrics))
    ) {
      const event = this.props.owner
        ? typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'event')
        : null;

      if (
        this.props.owner &&
        typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'group', 'event')?.accepting_donations
      ) {
        this.updateActions('donate', { active: this.checkIsActive() });
      }

      if (event) {
        if (
          this.props.owner &&
          this.props.owner.ownerStatus?.code !== this.state.currentStatus &&
          this.props.owner.ownerStatus?.code
        ) {
          this.setState({
            currentStatus:
              this.props.owner.ownerStatus.code === Constants.event_status.completed && event
                ? Constants.event_status.closed
                : this.props.owner.ownerStatus.code,
          });
        }

        this.updateActions('tickets', {
          text: event?.register_action_text ?? 'Buy Tickets',
          active: this.props.ticketComponents.length > 0 && this.checkIsActive(),
        });
        this.updateActions('sponsor', {
          text: event?.sponsorship_action_text ?? 'Sponsor',
          active: this.props.sponsorComponents.length > 0 && this.checkIsActive(),
        });
        if (
          this.props.owner &&
          typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'event')?.accepting_donations
        ) {
          this.updateActions('donate', {
            url: `/${this.props.owner?.ownerType}/${this.props.owner?.ownerHandle}/donate`,
            active: this.props.owner?.isActive,
          });
        }
        this.updateActions('fundraise', { text: event?.fundraise_action_text ?? 'Fundraise' });
        this.getUserFundraiseStatus();
      }
    }

    if (prevProps.owner === null && this.props.owner !== null) {
      if (this.props.userState.isLoggedIn) {
        axios
          .get(
            swapRouteParams(routes.GET_EVENT_CONVERSATION_MEMBERS, {
              eventId: this.props.owner.ownerId,
            }),
          )
          .then((response) => {
            let managers: string[] = [];

            for (let c in response.data) {
              managers.push(response.data[c].id);
            }

            this.setState({
              managers: managers,
            });
          });
      }
    }

    if (this.state.editName && !prevState.editName && this.state.tempName === '') {
      this.setState({
        tempName: this.props.owner?.ownerTitle ?? '',
      });
    }

    if (
      this.props.owner &&
      !this.state.editName &&
      prevState.editName &&
      this.state.tempName !== ''
    ) {
      axios.put(swapRouteParams(routes.UPDATE_EVENT, { eventId: this.props.owner.ownerId }), {
        title: this.state.tempName,
      });
    }

    if (this.props.owner) {
      if (this.props.ticketComponents.length > 0) {
        let params = queryString.parse(this.props.location.search);
        if (params.tickets) {
          this.setState(
            {
              showTickets: true,
            },
            () => {
              window.history.pushState(
                {},
                '',
                Config.web.REACT_APP_BASE_URL + this.props.location.pathname,
              );
            },
          );
        }
      }
    }
  }

  fetchGroupEvents() {
    this.props.getGroupEvents(this.props.groupState.group.id);
  }

  checkIsActive() {
    return Boolean(
      this.props.owner &&
        this.props.owner.isActive &&
        (this.state.currentStatus !== Constants.event_status.closed ||
          this.state.currentStatus !== Constants.event_status.completed),
    );
  }

  updateStatus(status: string) {
    let route: string = '';
    let toast: IToast;
    let tabId = 'group';
    let objectStatus = status;

    switch (this.props.owner?.ownerType) {
      case Constants.object_type.event:
        tabId = 'fundraiser';
        route = swapRouteParams(routes.CHANGE_EVENT_STATUS, { eventId: this.props.owner.ownerId });
        break;
      case Constants.object_type.group:
        tabId = 'group';
        route = swapRouteParams(routes.CHANGE_GROUP_STATUS, { groupId: this.props.owner.ownerId });
        break;
      case Constants.object_type.gig:
        tabId = 'gig';
        route = swapRouteParams(routes.CHANGE_GIG_STATUS, { gigId: this.props.owner.ownerId });
        break;
    }

    if (
      this.props.owner?.ownerType === Constants.object_type.event &&
      status === Constants.event_status.active
    ) {
      try {
        this.props.changeEventStatus(this.props.owner.ownerId, status, () =>
          this.fetchGroupEvents(),
        );
        this.setState(
          {
            currentStatus: status,
          },
          () => {
            this.props.createToast(
              toastSuccess(
                localizeHelpers.translate(`Status has been changed to ${status}`),
                'Change status',
              ),
            );
          },
        );
      } catch (error) {
        const errorObject = errorHelpers.getErrorObject(error);
        this.props.createToast(toastError(errorObject.translatedMessage, 'Change Status'));
      } finally {
        this.props?.onStatusChange?.();
      }
    } else {
      axios
        .post(route, { status })
        .then((response) => {
          objectStatus = response.data?.status?.code;

          if (objectStatus) {
            this.setState(
              {
                currentStatus: objectStatus,
              },
              () => {
                toast = toastSuccess(
                  localizeHelpers.translate(`Status has been changed to ${status}`),
                  'Change status',
                );
              },
            );
          }

          if (objectStatus === 'archived') {
            this.props.history.replace(`/dashboard?section=pages&tab=${tabId}`);
          }
        })
        .catch((error) => {
          const errorObject = errorHelpers.getErrorObject(error);
          toast = toastError(errorObject.translatedMessage, 'Change Status');
        })
        .finally(() => {
          this.props.createToast(toast);
          this.props?.onStatusChange?.();
        });
    }
  }

  updateActions(id: string, values: Partial<IPageDetailActions>) {
    this.setState((prevState) => {
      let _actions: IPageDetailActions[] = [...prevState.actions];
      let index: number = _actions.findIndex((a) => a.id === id);

      if (index >= 0) {
        _actions[index] = {
          ..._actions[index],
          ...values,
        };
      }

      return {
        ...prevState,
        actions: _actions,
      };
    });
  }

  getUserFundraiseStatus() {
    const isActive =
      this.props.owner !== null &&
      this.props.componentMetrics.find((c) => c.component_type === 'fundraising') &&
      this.checkIsActive();

    if (this.props.userState.isLoggedIn) {
      axios
        .get(
          swapRouteParams(routes.CHECK_FUNDRAISE_STATUS, {
            eventId: this.props.owner?.ownerId,
            email: this.props.userState.user.email,
          }),
        )
        .then((response) => {
          if (response.data.isIndividual) {
            this.updateActions('fundraise', {
              url:
                '/event/' +
                this.props.owner?.ownerHandle +
                '/individual/' +
                this.props.userState.user.handle,
              active: isActive,
            });
          } else {
            this.updateActions('fundraise', {
              url:
                '/login?redirect=' +
                encodeURIComponent('/setup/fundraise?event=' + this.props.owner?.ownerId ?? ''),
              active: isActive,
            });
          }
        });
    } else {
      this.updateActions('fundraise', {
        url:
          '/login?redirect=' +
          encodeURIComponent('/setup/fundraise?event=' + this.props.owner?.ownerId ?? ''),
        active: isActive,
      });
    }
  }

  onImageChange(fileData: any) {
    const blob = dataURItoBlob(fileData.file);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let payload: any = { cover_image_url: response.data.value };

        if (fileData.type === 'profile_image_url') {
          payload = { profile_image_url: response.data.value };
        }

        if (this.props.owner?.ownerType === 'event') {
          axios
            .put(
              swapRouteParams(routes.UPDATE_EVENT, { eventId: this.props.owner?.ownerId }),
              payload,
            )
            .then((response) => {
              if (this.props.onImageChange) {
                this.props.onImageChange();
              }
            });
        }
      });
  }

  getConditionalButtons(skip: number, limit: number) {
    let buttons: any[] = [];
    let amt: number = 0;
    let skipped: number = 0;

    for (let b in this.state.actions) {
      let button = this.state.actions[b] as IPageDetailActions;

      if (button.active && skipped >= skip && button.text != 'Hide') {
        if (button.url) {
          buttons.push(
            <Link
              key={button.text}
              to={button.url}
            >
              {button.text}
            </Link>,
          );
        } else if (button.onClick) {
          buttons.push(
            <Button
              key={button.text}
              text={button.text}
              onClick={() => {
                button?.onClick?.();
                this.setState({
                  showMoreActions: false,
                });
              }}
            />,
          );
        }

        amt++;
      } else if (button.active && skipped < skip) {
        skipped++;
      }

      if (amt >= limit) {
        return buttons;
      }
    }

    return buttons;
  }

  renderModals(owner: IOwnerObject) {
    const contactDetails = typeHelpers.tryGetOwnerObjectAs(
      owner,
      'group',
      'event',
    )?.contact_details;
    const event = owner && typeHelpers.tryGetOwnerObjectAs(owner, 'event');

    return (
      <Fragment>
        <Modal
          class="more-actions-modal"
          show={this.state.showMoreActions}
          onClose={() => {
            this.setState({ showMoreActions: false });
          }}
        >
          {!this.props.loading &&
            this.getConditionalButtons(2, 5).map((button, index) => {
              return button;
            })}
        </Modal>
        <Modal
          class="tickets-modal"
          show={this.state.showTickets}
          onClose={() => {
            this.setState({ showTickets: false });
          }}
          title={'Tickets'}
        >
          <div className="ticket-store-wrap">
            {this.props.ticketComponents.map((ticketComponent, index) => {
              return (
                <Store
                  owner={owner}
                  key={index}
                  edit={false}
                  isTicketsOnly={true}
                  component={ticketComponent}
                  addToCartCallback={() => {
                    this.setState({ showTickets: false });
                  }}
                />
              );
            })}
          </div>
        </Modal>
        <Modal
          class="tickets-modal"
          show={this.state.showSponsors}
          onClose={() => {
            this.setState({ showSponsors: false });
          }}
          title={'Sponsorship'}
        >
          <div className="ticket-store-wrap">
            {this.props.sponsorComponents.map((sponsorComponent, index) => {
              return (
                <Store
                  owner={owner}
                  key={index}
                  edit={false}
                  isSponsorshipsOnly={true}
                  component={sponsorComponent}
                  addToCartCallback={() => {
                    this.setState({ showSponsors: false });
                  }}
                />
              );
            })}
          </div>
        </Modal>
        <Modal
          class="share-modal"
          show={this.state.showShare}
          onClose={() => {
            this.setState({ showShare: false });
          }}
        >
          {owner.object !== null && (
            <Share
              owner_object={owner}
              {...this.props}
              url={Config.web.REACT_APP_BASE_URL + this.props.match.url}
            />
          )}
        </Modal>
        <ContactOrganizerModal
          object_id={event ? event.id : ''}
          object_type={uiConstants.ownerType.event}
          show={this.state.showContact}
          phone={contactDetails?.phone}
          email={contactDetails?.email}
          onClose={() => this.setState({ showContact: false })}
        />
        {event && (
          <AddToCalendarModal
            showModal={this.state.showAddToCalendarModal}
            onCloseModal={() => {
              this.setState({ showAddToCalendarModal: false });
            }}
            eventToAdd={{
              title: event.title,
              start: new Date(event.start_date),
              end: new Date(event.end_date),
              location: !!event.locations?.length
                ? getAddressParts(event.locations[0], ['line1', 'city', 'country'])
                : 'N/A',
            }}
          />
        )}
      </Fragment>
    );
  }

  getOrganizer() {
    const parentOwnerType =
      this.props.owner?.parentOwnerType === uiConstants.ownerType.hub
        ? 'company'
        : this.props.owner?.parentOwnerType;
    let handle =
      parentOwnerType === uiConstants.ownerType.group
        ? this.props.owner && typeHelpers.getGroupHandleFromOwner(this.props.owner)
        : this.props.owner && typeHelpers.getHubHandleFromOwner(this.props.owner);
    let title =
      parentOwnerType === uiConstants.ownerType.group
        ? this.props.owner && typeHelpers.getGroupTitleFromOwner(this.props.owner)
        : this.props.owner && typeHelpers.getHubTitleFromOwner(this.props.owner);

    let isOnBehalfOf =
      this.props.owner &&
      typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'event')?.event_type ===
        Constants.event_type.obo_fundraiser;

    const parentLink = (
      <Link to={`/${parentOwnerType}/${handle}`}>
        {' '}
        <span
          className="organizer-spacing"
          title={title ?? undefined}
        >
          {' '}
          {title ?? undefined}{' '}
        </span>{' '}
      </Link>
    );
    const host = this.props.owner && typeHelpers.isOwnerObjectOfType(this.props.owner, 'event') && (
      <span className="organizer-spacing">
        <var data-var="organizer_name">{this.props.owner.ownerOrganizer}</var>
      </span>
    );

    if (
      this.props.owner &&
      typeHelpers.isOwnerObjectOfType(this.props.owner, 'event') &&
      host &&
      isOnBehalfOf
    ) {
      return (
        <React.Fragment>
          <i className="fal fa-users" /> {'Hosted by'} {host} {' for '}{' '}
          <var data-var="parent_url">{parentLink}</var>
        </React.Fragment>
      );
    } else {
      return (
        <span
          className="parent"
          notranslate="yes"
        >
          <i className="fal fa-users" />
          {parentLink}
        </span>
      );
    }
  }

  render() {
    const currency = this.props.owner?.account?.currency ?? defaultCurrency;
    const event = this.props.owner && typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'event');
    const donationMatching = event?.donations_matching ?? null;
    const group = this.props.owner && typeHelpers.getGroupFromOwner(this.props.owner);
    return (
      <div className={this.props.loading ? 'PageDetails loading' : 'PageDetails'}>
        <div className="details">
          <div className="contact-share">
            <Button
              text=""
              type="button"
              className="add-to-calendar"
              icon="far fa-calendar-plus"
              onClick={() => {
                this.setState({ showAddToCalendarModal: true });
              }}
            />
            <Button
              text=""
              type="button"
              className="contact-us"
              icon="far fa-comment-dots"
              onClick={() => {
                this.setState({ showContact: true });
              }}
            />
            <Button
              text=""
              type="button"
              className="share"
              icon="fal fa-share-alt"
              onClick={() => {
                this.setState({ showShare: true });
              }}
            />
          </div>
          <div className="cover-image">
            <CoverImage
              type="event"
              onChange={this.onImageChange}
              currentImage={this.props.owner?.ownerCoverImageUrl ?? undefined}
              overlay={hasPermission('EDIT_EVENT_INFO', this.props.role)}
            />
            {donationMatching?.ratio_description && (
              <div className="donation-matching">
                <img
                  className="icon"
                  src={donationMatchingImg}
                />
                <div className="content">
                  <span className="label">Donation Matching:&nbsp;</span>
                  <span notranslate="yes">{donationMatching.ratio_description}</span>
                  {(donationMatching?.matched_amount ?? 0) > 0 && (
                    <span className="matched-to-date">
                      &nbsp;
                      <var data-var="matched_to_date">
                        {formatCurrency(
                          donationMatching.matched_amount,
                          currency,
                          this.props.locale,
                        )}
                      </var>{' '}
                      Matched-to-Date
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="details-info">
            <div className="details-title">
              <Portrait
                size={120}
                onChange={this.onImageChange}
                currentImage={this.props.owner?.ownerProfileImageUrl ?? undefined}
                overlay={hasPermission('EDIT_EVENT_INFO', this.props.role)}
              />
              <div className="details-title-inner">
                <div className="page-type">
                  <i className="fal fa-ticket-alt" />
                  <span>Event</span>
                </div>
                <div className="page-title">
                  {!this.state.editName && (
                    <React.Fragment>
                      <h1
                        notranslate="yes"
                        title={
                          this.state.tempName
                            ? this.state.tempName
                            : this.props.owner?.ownerTitle ?? ''
                        }
                      >
                        {this.state.tempName
                          ? this.state.tempName
                          : this.props.owner?.ownerTitle ?? ''}
                      </h1>
                      {hasPermission('EDIT_EVENT_INFO', this.props.role) && (
                        <i
                          onClick={() => {
                            this.setState({ editName: true });
                          }}
                          className="far fa-edit"
                        ></i>
                      )}
                    </React.Fragment>
                  )}
                  {this.state.editName && (
                    <form
                      onSubmit={() => {
                        this.setState({ editName: false });
                      }}
                    >
                      <TextField
                        required={true}
                        value={this.state.tempName}
                        name="tempName"
                        type="text"
                        onChange={(e: any) => handleInputChange(e, this)}
                      />
                      <Button icon="far fa-save"></Button>
                    </form>
                  )}
                </div>
                {!this.props.loading && hasPermission('EDIT_EVENT_INFO', this.props.role) && (
                  <ChangeStatusMenu
                    event={event as IEventSummaryFE}
                    group={group}
                    handle={this.props.owner?.ownerHandle ?? ''}
                    currentStatus={this.state.currentStatus}
                    listOfStatuses={assignableEventStatuses}
                    onStatusChanged={(status: string) => this.updateStatus(status)}
                    itemType="EVENT"
                  />
                )}
                <ul className="details-group">
                  <li className="when">
                    <i className="fal fa-calendar" />
                    {event?.start_date && (
                      <div className="when-inner">
                        <div className="two-rows">
                          <var data-var="start_date">
                            {localizeHelpers.formatDate(
                              event?.start_date ?? null,
                              LocaleDateFormats.LL,
                              this.props.locale,
                            )}
                          </var>
                          <var data-var="start_date_time">
                            {` ${localizeHelpers.formatTime(
                              {
                                hour: new Date(event?.start_date).getHours(),
                                minute: new Date(event?.start_date).getMinutes(),
                              },
                              this.props.locale,
                            )}`}
                          </var>
                        </div>
                        to
                        <div className="two-rows">
                          <var data-var="end_date">
                            {localizeHelpers.formatDate(
                              event?.end_date ?? null,
                              LocaleDateFormats.LL,
                              this.props.locale,
                            )}
                          </var>
                          <var data-var="end_date_time">
                            {` ${localizeHelpers.formatTime(
                              {
                                hour: new Date(event?.end_date).getHours(),
                                minute: new Date(event?.end_date).getMinutes(),
                              },
                              this.props.locale,
                            )}`}
                          </var>
                        </div>
                      </div>
                    )}
                  </li>
                  <li>{this.getOrganizer()}</li>
                </ul>
              </div>
            </div>

            <div className="details-actions">
              {!this.props.loading &&
                this.getConditionalButtons(
                  0,
                  this.state.actions.filter((a) => {
                    return a.active;
                  }).length > Constants.maxPrimaryButtons
                    ? Constants.minPrimaryButtons
                    : Constants.maxPrimaryButtons,
                ).map((button) => {
                  return button;
                })}
              {!this.props.loading &&
                this.state.actions.filter((a) => {
                  return a.active;
                }).length > Constants.maxPrimaryButtons && (
                  <Button
                    className="more"
                    icon="far fa-ellipsis-h"
                    text="More"
                    onClick={() => {
                      this.setState({ showMoreActions: true });
                    }}
                  />
                )}
            </div>
          </div>
        </div>

        {this.props.owner && this.renderModals(this.props.owner)}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    userState: store.userState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
  changeEventStatus,
  getGroupEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageDetails);
