import React, { ChangeEvent } from 'react';

import './FileUpload.scss';

interface IProps {
  icon: string;
  text: string;
  accept: string;

  /** Called when file(s) are selected. `e.target.files` will contain the files.  */
  onSelectFile: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface IState {
  fileRef: any;
}

class FileUpload extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      fileRef: React.createRef(),
    };

    this.onFileChange = this.onFileChange.bind(this);
  }

  onFileChange(e: ChangeEvent<HTMLInputElement>) {
    this.props.onSelectFile(e);

    this.setState({
      fileRef: {
        ...this.state.fileRef,
        current: {
          ...this.state.fileRef.current,
          value: '',
        },
      },
    });
  }

  render() {
    return (
      <div className="FileUpload">
        <label className="custom-file-upload">
          <input
            ref={this.state.fileRef}
            type="file"
            accept={this.props.accept}
            onChange={this.onFileChange}
          />
          <span className="icon">
            <i className={`${this.props.icon}`}></i>
          </span>{' '}
          {this.props.text}
        </label>
      </div>
    );
  }
}

export default FileUpload;
