import { Constants } from '@gigit/constants';
import { IPremiumFeatureSummary } from '@gigit/interfaces';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { uiConstants } from '../../../../constants';
import { formatCardIcon, routes } from '../../../../helpers';
import useToastDispatcher from '../../../../hooks/useToaster';
import { localizeHelpers } from '../../../../localizeHelpers';
import { billingRequestActions } from '../../../../requestActions';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import { usePaymnetInfo } from '../../../shared/Onboarding/hooks/usePaymentInfo';
import { PaymentComponent } from '../../../shared/PaymentComponent/PaymentComponent';
import './HubPaymentModal.scss';

interface IProps {
  ownerId: string;
  showModal: boolean;
  description: string;
  title: string;
  confirmButton: string;
  onConfirmation: (pmId: string, createSub?: boolean) => void;
  toggleCloseModal: () => void;
  toggleButtonLoading?: boolean;
  hidePaymentMethod?: boolean;
}

const HubPaymentModal = (props: IProps) => {
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const { paymentInfo, handlePaymentInfo } = usePaymnetInfo();

  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<string>('');
  const [lastDigits, setLastDigits] = useState<string>('');
  const [cardBrand, setCardBrand] = useState<string>('');
  const [showDefaultView, setShowDefaultView] = useState<boolean>(true);
  const [disablePaymentMethodSave, setDisablePaymentMethodSave] = useState<boolean>(false);
  const [triggerPaymentPrep, setTriggerPaymentPrep] = useState<boolean>(false);
  const [isSubscriptionVirtual, setIsSubscriptionVirtual] = useState<boolean>(false);
  const [buttonLoading, setIsButtonLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsButtonLoading(false);
  }, [props.toggleButtonLoading]);

  useEffect(() => {
    if (props.ownerId) {
      billingRequestActions.getHubSubscriptionSummary(props.ownerId).then((response) => {
        let hubFeature: IPremiumFeatureSummary | null = null;
        for (let feature of response.features) {
          if (
            feature.period_end_date &&
            new Date(feature.period_end_date).getTime() > new Date().getTime() &&
            feature.feature_code == Constants.billing.subscriptions.hub_seating.code
          ) {
            hubFeature = feature;
            break;
          }
        }

        if (hubFeature) {
          let summary = hubFeature as IPremiumFeatureSummary;
          if (hubFeature.is_virtual) {
            setIsSubscriptionVirtual(hubFeature.is_virtual);
            return;
          }
          setDefaultPaymentMethodId(
            summary.payment_method_details ? summary.payment_method_details.payment_method_id : '',
          );
          setLastDigits(
            summary.payment_method_details
              ? summary.payment_method_details.credit_card_last_4_digits
              : '',
          );
          setCardBrand(
            summary.payment_method_details ? summary.payment_method_details.credit_card_brand : '',
          );

          setShowDefaultView(true);

          if (!summary.payment_method_details?.payment_method_id) {
            if (props.hidePaymentMethod) {
              setDisablePaymentMethodSave(false);
              setShowDefaultView(true);
            } else {
              setDisablePaymentMethodSave(true);
              setShowDefaultView(false);
            }
          }
        } else {
          if (props.hidePaymentMethod) {
            setDisablePaymentMethodSave(false);
            setShowDefaultView(true);
          } else {
            setIsSubscriptionVirtual(true);
            setShowDefaultView(false);
          }
        }
      });
    }

    if (!props.showModal) {
      setIsButtonLoading(false);
    }
  }, [props.ownerId, props.showModal]);

  const handlePaymentMethodId = async (id: string) => {
    if (id == defaultPaymentMethodId) {
      props.onConfirmation(id, false);
      return;
    }

    if (id) {
      try {
        setDisablePaymentMethodSave(false);
        if (paymentInfo.isNewMethod) {
          let payload = {
            payment_method_id: id,
          };

          const response = await axios.post(routes.GET_PAYMENT_METHODS, payload);
          let confirm = await paymentInfo.stripe?.confirmCardSetup(response.data.client_secret);

          if (confirm && confirm.error) {
            dispatchToastError(localizeHelpers.translate(confirm.error.message), 'Payment Method');
            return;
          }
        }

        if (isSubscriptionVirtual) {
          props.onConfirmation(id, true);
        } else {
          await billingRequestActions.updateHubSubscription(
            props.ownerId!,
            Constants.billing.subscriptions.hub_seating.code,
            { payment_method_id: id },
          );
          props.onConfirmation(id, false);
        }

        dispatchToastSuccess('Payment method updated successfully.', 'Payment Method');
      } catch (error) {
        dispatchToastError(error, 'Payment Method');
      }
    }
  };

  function handleConfirmPayment() {
    setIsButtonLoading(true);
    if (showDefaultView) {
      props.onConfirmation(defaultPaymentMethodId);
    } else {
      if (defaultPaymentMethodId && !paymentInfo.paymentMethod) {
        props.onConfirmation(defaultPaymentMethodId);
      } else if (paymentInfo.paymentMethod) {
        setTriggerPaymentPrep(!triggerPaymentPrep);
      }
    }
  }

  return (
    <>
      <Modal
        show={props.showModal}
        onClose={() => {
          props.toggleCloseModal();
        }}
        closeIcon="fas fa-times"
        class="HubPayment-Modal"
      >
        <div className="HubPaymentModal">
          <div className="header-container">
            <div className="title">{props.title}</div>
          </div>
          <div className="description-container">
            <QuillTextEditor
              value={props.description}
              preserveWhitespace={true}
              readOnly={true}
              theme={uiConstants.quill.readOnlyTheme}
            />
          </div>
          <div className="default-payment-method">
            {!showDefaultView && (
              <PaymentComponent
                title=""
                showBillingForm={true}
                priceDetails={[]}
                defaultPaymentMethodId={defaultPaymentMethodId ? defaultPaymentMethodId : ''}
                notCreationFlow={true}
                selectDefaultMethod={true}
                paymentInfo={paymentInfo}
                onPaymentInfoUpdated={handlePaymentInfo}
                onPaymentMethodReady={handlePaymentMethodId}
                onMethodChange={() => setDisablePaymentMethodSave(false)}
                preparePaymentMethod={triggerPaymentPrep}
              />
            )}
          </div>
          <div className="action-conatiner">
            <Button
              className="action-button cancel"
              buttonType="outline-dark"
              isDisabled={false}
              onClick={() => props.toggleCloseModal()}
              text="Cancel"
            />
            <Button
              className="action-button"
              isDisabled={disablePaymentMethodSave}
              loading={buttonLoading}
              onClick={handleConfirmPayment}
              text={props.confirmButton}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HubPaymentModal;
