import React, { FC, Fragment, useEffect, useState } from 'react';
import TitleSection, { ITabConfig, ITitleSectionTab } from '../TitleSection/TitleSection';
import './MyActivity.scss';
import MyTransactions from './Transactions/MyTransactions';
import MyAgenda from './Agenda/MyAgenda';
import Applications from '../Applications/Applications';
import { IUserState } from '../../../reducers/user';
import Network from '../Network/Network';
import { useHistory, useLocation } from 'react-router-dom';

const tabs: ITitleSectionTab[] = [
  { id: 'agenda', title: 'Agenda' },
  { id: 'applications', title: 'Applications' },
  { id: 'transactions', title: 'Transactions' },
  { id: 'network', title: 'Network' },
];

interface IProps {
  userState: IUserState;
  activeTabId?: string;
}

const MyActivity: FC<IProps> = (props: IProps) => {
  const [activeTabId, setActiveTabId] = useState<string>('agenda');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setActiveTabId(props.activeTabId || 'agenda');
  }, [props.activeTabId]);

  const tabConfig: ITabConfig = {
    tabs: tabs,
    activeTabId: activeTabId,
    onTabClick: (v) =>
      history.push({ pathname: location.pathname, search: `section=activity&tab=${v}` }),
  };

  return (
    <Fragment>
      <TitleSection
        title="My Activity"
        tabConfig={tabConfig}
      />
      <div className="MyActivity">
        {activeTabId === 'agenda' && <MyAgenda />}
        {activeTabId === 'transactions' && <MyTransactions />}
        {activeTabId === 'applications' && <Applications />}
        {activeTabId === 'network' && <Network />}
      </div>
    </Fragment>
  );
};

export default MyActivity;
