import React from 'react';

import './Toast.scss';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { removeToast } from '../../actions/toaster';
import { WithTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import { IToastAction } from '../../interfaces';

interface IProps extends WithTranslation {
  removeToast(index?: number): void;
  toastType: string;
  message: string;
  icon?: string;
  index?: number;
  title: string;
  actionList?: Array<IToastAction>;
}

interface IState {}

class Toast extends React.Component<IProps, IState> {
  render() {
    let { t } = this.props;
    return (
      <div className={`Toast ${this.props.toastType}`}>
        <div className="inner">
          <div className="icon-block">
            {this.props.icon && <i className={`${this.props.icon} ${this.props.toastType}`}></i>}
          </div>
          <div className="message">
            <div className="title">{this.props.title}</div>

            {/* Messages should be translated at the source. */}
            <div notranslate="yes">
              <ReactMarkdown source={this.props.message} />
            </div>

            <i
              onClick={() => this.props.removeToast(this.props.index)}
              className={`fal fa-times ${this.props.toastType}`}
            ></i>
            {this.props.actionList && (
              <div className="actions">
                {this.props.actionList &&
                  this.props.actionList.map((action, index) => {
                    if (action.link) {
                      return (
                        <Link
                          key={index}
                          to={action.link.link}
                        >
                          {action.link.text}
                        </Link>
                      );
                    }
                    return (
                      <Button
                        key={index}
                        text={action.text}
                        onClick={() => {
                          action.onClick && action.onClick();
                          this.props.removeToast(this.props.index);
                        }}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  removeToast: removeToast,
};

const mapStateToProps = (store: IAppState) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
