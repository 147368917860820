import React, { useEffect, useState } from 'react';
import { IGigCategory } from '@gigit/interfaces';
import { IOptions } from '../../../CreateFlows/Steps/FundraiseTeamSearch/FundraiseTeamSearch';
import Dropdown from '../../../Dropdown/Dropdown';
import { FormSectionHeader } from '../FormSectionHeader/FormSectionHeader';

import { IGigCategoryInfo } from '../interfaces';

interface IProps {
  categoryInfo: IGigCategoryInfo;

  onCategoryChange(update: IGigCategoryInfo): void;
}

export const GigCategory: React.FC<IProps> = (props: IProps) => {
  const [categoryOptions] = useState<IOptions[]>(
    convertCategoryOptions(props.categoryInfo.categories),
  );
  const [subCategoryOptions, setSubCategoryOptions] = useState<IOptions[]>(
    getSubCategoryOptions(props.categoryInfo.subCategory),
  );

  useEffect(() => {
    setSubCategoryOptions(getSubCategoryOptions(props.categoryInfo.category));
  }, [categoryOptions]);

  function convertCategoryOptions(categories: Array<IGigCategory>): Array<IOptions> {
    return categories.map((category: IGigCategory) => {
      return { label: category?.category || '', value: category.id! };
    });
  }

  function handleCategoryUpdate(value: string) {
    const newData = { ...props.categoryInfo, category: value };
    props.onCategoryChange(newData);
    setSubCategoryOptions(getSubCategoryOptions(value));
  }

  function handleSubCategoryUpdate(value: string) {
    const newData = { ...props.categoryInfo, subCategory: value };
    props.onCategoryChange(newData);
  }

  function getSubCategoryOptions(value: string) {
    const { categories } = props.categoryInfo;
    let opts: Array<IOptions> = [];
    let idx = categories.findIndex((classification) => {
      return classification.id === value;
    });

    if (idx >= 0) {
      let subCatgegories = categories[idx].sub_category || [];
      opts = subCatgegories.map((subClass: string) => {
        return { value: subClass, label: subClass };
      });
    }

    return opts;
  }

  return (
    <div className="GigCategory">
      <FormSectionHeader text="Category Settings" />
      <div className="row">
        <Dropdown
          placeholder="Select a Category"
          options={categoryOptions}
          shouldSort={true}
          value={props.categoryInfo.category}
          label="Volunteer Opportunity Category"
          name="Gig Category"
          onChange={(e) => handleCategoryUpdate(e.target.value)}
        />
      </div>
      <div className="row">
        {subCategoryOptions && (
          <Dropdown
            options={subCategoryOptions}
            placeholder="Select Sub-Category"
            shouldSort={true}
            value={props.categoryInfo.subCategory}
            label="Volunteer Opportunity Sub-Category"
            name="sub-category"
            onChange={(e) => handleSubCategoryUpdate(e.target.value)}
          />
        )}
      </div>
    </div>
  );
};
