import './VolunteerCreditsSummaryModal.scss';
import { Constants } from '@gigit/constants';
import {
  IHub,
  IUserCreditPaymentPackageFE,
  IUserCreditPaymentPackageGroupSummaryFE,
} from '@gigit/interfaces';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { defaultCurrency, downloadFile, formatCurrency, typeHelpers } from '../../../../../helpers';
import useToastDispatcher from '../../../../../hooks/useToaster';
import { IKpi } from '../../../../../interfaces';
import { localizeHelpers } from '../../../../../localizeHelpers';
import { hubRequestActions } from '../../../../../requestActions';
import { userSelectors } from '../../../../../selectors/user';
import { IAppState } from '../../../../../store';
import Button from '../../../../Button/Button';
import Modal from '../../../../Modal/Modal';
import ModalFooterActionContainer from '../../../../Modal/ModalFooterActions/ModalFooterActionContainer';
import ModalScrollContainer from '../../../../Modal/ModalScrollContainer/ModalScrollContainer';
import Table, { ITableProps } from '../../../../shared/Table/Table';
import HubManagementMetricContainer from '../HubManagementMetricContainer/HubManagementMetricContainer';
import { uiConstants } from '../../../../../constants';
import DefaultLink from '../../../../shared/Link/Link';
import Portrait from '../../../../Portrait/Portrait';
import PayoutModal from '../PayoutModal/PayoutModal';

interface IProps {
  hub?: IHub;
  paymentPackage: IUserCreditPaymentPackageFE | null;
  showModal: boolean;
  onClose: () => void;
}

const initialKPIs: IKpi[] = [
  {
    text: 'Total Donated',
    value: '-',
  },
  {
    text: 'Donations Fulfilled',
    value: '-',
  },
  {
    text: 'Causes Supported',
    value: '-',
  },
  {
    text: 'Number of Donations',
    value: '-',
  },
];

// const tabs: ITab[] = [
//   { label: 'Credit Donations', value: 'volunteer-records' },
//   { label: 'Payment Details', value: 'payment-details' },
// ];

function VolunteerCreditsSummaryModal(props: IProps) {
  const { showModal, onClose } = props;

  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const currency = props.hub?.account?.currency ?? defaultCurrency;
  const { dispatchToastError } = useToastDispatcher();
  const [selectedGroupToPay, setSelectedGroupToPay] =
    useState<IUserCreditPaymentPackageGroupSummaryFE | null>(null);
  const [KPIs, setKPIs] = useState<IKpi[]>(initialKPIs);
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  // const [activeTab, setActiveTab] = useState<string>('volunteer-records');

  const volunteerRecordsTableProps: ITableProps<IUserCreditPaymentPackageGroupSummaryFE> = {
    columns: [
      {
        id: 'name',
        Header: 'Cause',
        accessor: ({ name }) => name,
        notranslate: 'yes',
        Cell: ({ name, handle, profile_image_url }) => (
          <DefaultLink
            to={`/${uiConstants.ownerType.group}/${handle}`}
            className="item-name-col"
            notranslate="yes"
          >
            <div className="name-portrait">
              <Portrait
                size={30}
                className="image-container"
                currentImage={profile_image_url}
              />
              <span>{name}</span>
            </div>
          </DefaultLink>
        ),
      },
      {
        id: 'amount',
        Header: 'Donated',
        notranslate: 'yes',
        accessor: ({ amount }) => formatCurrency(amount, currency, locale),
      },
      {
        id: 'amount_paid',
        Header: 'Paid',
        notranslate: 'yes',
        accessor: ({ amount_paid }) => formatCurrency(amount_paid, currency, locale),
        sortable: true,
      },
      {
        id: 'payment_status.code',
        Header: 'Status',
        accessor: ({ payment_status }) => payment_status.code,
        predefinedColumnType: {
          type: 'STATUS',
          columnObject: () => ({
            [Constants.donation_matching_group_payment_status.paid]: {
              label: 'Paid',
              color: 'GREEN',
            },
            [Constants.donation_matching_group_payment_status.unpaid]: {
              label: 'Unpaid',
              color: 'RED',
            },
            [Constants.donation_matching_group_payment_status.unconfirmed_payment]: {
              label: 'Awaiting Confirmation',
              color: 'GREY',
            },
          }),
        },
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'HEADER',
          icon: 'far fa-file-download',
          label: 'Export All',
          buttonType: 'outline-dark',
          onClick: async () => {
            try {
              typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
              typeHelpers.assertNotNullOrUndefined(
                props.paymentPackage?.id,
                'Expected Payment Package ID',
              );

              const result = await hubRequestActions.exportVolunteerMatchingSummary(
                props.hub.id,
                props.paymentPackage?.id,
              );
              downloadFile('Export_Matching_Program_Summary.csv', result);
            } catch (error) {
              dispatchToastError(error, 'Export Summary Data');
            }
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Pay Now',
          onClick: (_, row) => {
            setSelectedGroupToPay(row);
          },
          hideIf: (row) => row?.payment_status.code !== 'unpaid',
        },
      ],
    },
    pagination: {
      pageSizeOptions: [100],
      queryAction: async (queryParams) => {
        try {
          typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Hub Id');
          typeHelpers.assertNotNullOrUndefined(
            props.paymentPackage?.id,
            'Expected Payment Package Id',
          );

          const paymentPackage = await hubRequestActions.getPaymentPackage(
            props.hub.id,
            props.paymentPackage.id,
          );
          return paymentPackage.groups ?? [];
        } catch (error) {
          dispatchToastError(error, 'Get Causes');
          return [];
        }
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any Causes",
    },
  };

  useEffect(() => {
    getKPIs();
  }, []);

  useEffect(() => {
    getKPIs();
  }, [refreshTableIncrementor]);

  async function getKPIs() {
    const currency = props.hub?.account?.currency ?? defaultCurrency;
    const volunteerMatchStats = await hubRequestActions.getPaymentPackageStats(
      props.hub?.id!,
      props.paymentPackage?.id!,
    );

    const convertToKPI: IKpi[] = [
      {
        text: 'Credits Spent',
        value: formatCurrency(volunteerMatchStats.total_donated, currency, locale),
      },
      {
        text: 'Donations Fulfilled',
        value: formatCurrency(volunteerMatchStats.total_paid, currency, locale),
      },
      {
        text: 'Total Donations',
        value: localizeHelpers.formatNumber(volunteerMatchStats.total_donations, locale),
      },
      {
        text: 'Causes Supported',
        value: localizeHelpers.formatNumber(volunteerMatchStats.total_causes, locale),
      },
    ];

    setKPIs(convertToKPI);
  }

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  // function onTabChange(tab: string) {
  //   setActiveTab(tab);
  // }

  function renderVolunteerRecordsTab() {
    return (
      <Table
        {...volunteerRecordsTableProps}
        refreshTableIncrementor={refreshTableIncrementor}
      />
    );
  }

  return (
    <Fragment>
      <Modal
        show={showModal}
        onClose={onClose}
        class="MatchingProgramSummaryModal"
        closeIcon="fas fa-times"
        title={`Payout Summary - ${props.paymentPackage?.title}`}
      >
        <ModalScrollContainer className="MatchingProgramSummaryModal-Container">
          <HubManagementMetricContainer metrics={KPIs} />
          {/* <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={onTabChange}
          /> */}
          {/* {activeTab === 'volunteer-records' && renderVolunteerRecordsTab()} */}
          {/* {activeTab === 'payment-details' && renderPaymentDetailsTab()} */}
          {renderVolunteerRecordsTab()}
        </ModalScrollContainer>
        <ModalFooterActionContainer>
          <Button
            buttonType="secondary"
            onClick={onClose}
            text="Cancel"
          />
        </ModalFooterActionContainer>
      </Modal>
      {props.hub && props.paymentPackage !== null && selectedGroupToPay != null && (
        <PayoutModal
          hub={props.hub}
          groupToPay={selectedGroupToPay}
          paymentPackage={props.paymentPackage}
          showModal={true}
          onPayoutFinish={() => {
            refreshTableData();
            setSelectedGroupToPay(null);
          }}
          onClose={() => setSelectedGroupToPay(null)}
        />
      )}
    </Fragment>
  );
}

export default VolunteerCreditsSummaryModal;
