import React from 'react';
import Joyride from 'react-joyride';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Config } from '@gigit/config';
import { IUser, IPage, IPageComponent } from '@gigit/interfaces';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { IAppState } from '../../store';
import { IUserState } from '../../reducers/user';
import { IProfileState } from '../../reducers/profile';
import {
  updateUser,
  updateUserCoverImage,
  updateUserProfileImage,
  createUserPage,
  deleteUserPage,
  updateUserPage,
  createUserPageComponent,
  updateUserPageComponent,
} from '../../actions/user';
import { getProfileByHandle, getProfilePageComponents } from '../../actions/profile';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import Share from '../../components/Share/Share';
import './User.scss';
import PageHeader from '../../components/ProfilePageComponents/PageHeader/PageHeader';
import { PageBody } from '../../components/ProfilePageComponents/PageBody/PageBody';
import { PageContainer } from '../../components/ProfilePageComponents/PageContainer/PageContainer';
import { PageSidebar } from '../../components/ProfilePageComponents/PageSidebar/PageSidebar';
import { PageSelector } from '../../components/ProfilePageComponents/PageSelector/PageSelector';
import { BackSplash } from '../../components/ProfilePageComponents/BackSplash/BackSplash';
import PageContent from '../../components/ProfilePageComponents/PageContent/PageContent';
import PageCoverImage from '../../components/ProfilePageComponents/PageCoverImage/PageCoverImage';
import ManageTabsModal from '../../components/ProfilePageComponents/PageSelector/ManageTabsModal/ManageTabsModal';
import FloatingActionButton from '../../components/shared/FloatingActionButton/FloatingActionButton';
import { setSEOMetatags } from '../../helpers';
import typeHelpers from '../../helpers/typeHelpers';
import { ContactButton } from '../../components/Hub/Shared/ContactButton/ContactButton';
import MetricsList from '../../components/shared/MetricsList/MetricsList';
import { uiConstants } from '../../constants';

interface IProps extends RouteComponentProps<any> {
  profileState: IProfileState;
  userState: IUserState;
  getProfileByHandle(handleOrId: string): void;
  updateUser(payload: any, updateProfile?: boolean): void;
  updateUserProfileImage(payload: any): void;
  updateUserCoverImage(payload: any): void;
  getProfilePageComponents(_userId: string, _pageId: string): void;
  deleteUserPage(_pageId: string): void;
  createUserPage(_payload: any): void;
  updateUserPage(_payload: any): void;
  createUserPageComponent(_pageId: string, _payload: any): void;
  updateUserPageComponent(_pageId: string, _componentId: string, _payload: any): void;
}

interface IStep {
  target: string;
  content: string;
  disableBeacon?: boolean;
}

interface IState {
  currentPage: number;
  params: any;
  steps: IStep[];
  mounted: boolean;
  profile: IUser;
  pages: IPage[];
  showShareModal: boolean;
  showUserOnboard: boolean;
  userOnboardStep: number;
  showManageModal: boolean;
  showUserProfileModal: boolean;
}

class User extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentPage: 0,
      params: {},
      steps: [
        {
          disableBeacon: true,
          target: '.PageHeader .Portrait .inner',
          content: 'Customize your profile image.',
        },
        {
          disableBeacon: true,
          target: '.CoverImage',
          content: 'Change your cover image to make your page more customized.',
        },
        {
          disableBeacon: true,
          target: '.CoverImage .float',
          content: 'Manage your account settings.',
        },
        {
          disableBeacon: true,
          target: '.PageSelector .page-item',
          content: 'Add custom pages to your profile page.',
        },
        {
          disableBeacon: true,
          target: '.add-component',
          content: 'Add custom components from our library to display your impact to others.',
        },
        {
          disableBeacon: true,
          target: '.MetricsList',
          content:
            'Watch your metrics grow and show the world your commitment to positive social impact by engaging with causes, events, volunteer opportunities and company hubs.',
        },
      ],
      mounted: false,
      profile: {
        id: '',
        cover_image_url: this.props.userState.user.cover_image_url || '',
        profile_image_url: this.props.userState.user.profile_image_url || '',
        locations: [],
        gender: '',
        created_at: new Date(),
        updated_at: new Date(),
        email: '',
        first_name: '',
        last_name: '',
        display_name: '',
        dob: new Date(),
        handle: '',
      },
      pages: this.props.profileState.pages,
      showShareModal: false,
      showUserOnboard: false,
      userOnboardStep: 1,
      showManageModal: false,
      showUserProfileModal: false,
    };

    this.onImageChange = this.onImageChange.bind(this);
    this.isCurrentUser = this.isCurrentUser.bind(this);
    this.handleProfileInputChange = this.handleProfileInputChange.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.setPage = this.setPage.bind(this);
    this.onAddComponent = this.onAddComponent.bind(this);
    this.createUserPageComponent = this.createUserPageComponent.bind(this);
  }

  componentDidMount() {
    (window as any).prerenderReady = false;
    this.props.getProfileByHandle(this.props.match.params.username);

    this.setState({
      mounted: true,
    });

    let _params = queryString.parse(this.props.location.search);

    this.setState(
      {
        params: _params,
      },
      () => {
        if (this.state.params.help) {
          this.setState({
            showUserOnboard: true,
          });
        }
      },
    );

    this.setPage(0);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    /** If navigation param change then call getProfileByHandle
     * @example /user/usernameA -> /user/usernameB
     */
    if (this.props.match.params.username !== prevProps.match.params.username)
      this.props.getProfileByHandle(this.props.match.params.username);

    /**
     * Change page content and profile state after navigation param changed.
     * maybe we could connect these actions to trigger together
     */
    if (
      this.props.profileState.pages[this.state.currentPage]?.id !==
        prevProps.profileState.pages[this.state.currentPage]?.id &&
      this.props.profileState.user.id &&
      this.props.profileState.pages[this.state.currentPage] !== undefined
    ) {
      this.setState({
        profile: this.props.profileState.user,
      });
      this.props.getProfilePageComponents(
        this.props.profileState.user.id,
        this.props.profileState.pages[this.state.currentPage].id,
      );
    }

    if (prevState.pages.length !== this.state.pages.length) {
      this.setPage(0);
    }

    if (
      this.props.profileState.user.id !== this.state.profile.id &&
      this.state.profile.id === '' &&
      !this.props.profileState.isProfileLoading
    ) {
      const { handle, first_name, last_name, profile_image_url, cover_image_url } =
        this.props.userState.user;

      setSEOMetatags({
        urlPath: `user/${handle}`,
        title: `${first_name} ${last_name}`,
        description: undefined,
        imageURL: profile_image_url,
        coverImageURL: cover_image_url,
      });
      this.setState({
        profile: this.props.profileState.user,
      });

      this.setPage(0);
    }

    if (this.props.profileState.pages !== prevProps.profileState.pages) {
      if (
        this.state.currentPage === this.props.profileState.pages.length &&
        this.state.currentPage !== 0
      ) {
        this.setState({
          pages: this.props.profileState.pages,
          currentPage: this.state.currentPage - 1,
        });
      } else if (
        this.props.profileState.pages.length > prevProps.profileState.pages.length &&
        prevProps.profileState.pages.length !== 0
      ) {
        this.setState({
          pages: this.props.profileState.pages,
          currentPage: this.props.profileState.pages.length - 1,
        });
      } else {
        this.setState({
          pages: this.props.profileState.pages,
        });
      }
    }

    if (
      prevState.showUserProfileModal !== this.state.showUserProfileModal &&
      this.state.showUserProfileModal
    ) {
      this.props.history.push('/account');
    }
  }

  onAddComponent(type: string) {
    this.createUserPageComponent(type);
  }

  createUserPageComponent(type: string) {
    if (
      this.props.profileState.pages[this.state.currentPage].id &&
      this.props.profileState.user.handle
    ) {
      this.props.createUserPageComponent(this.props.profileState.pages[this.state.currentPage].id, {
        visibility: 'public',
        sequence: 1,
        title: '',
        component_type: type,
      });
    }
  }

  onImageChange(fileData: any) {
    switch (fileData.type) {
      case 'cover_image_url':
        this.props.updateUserCoverImage(fileData.file);
        break;
      case 'profile_image_url':
        this.props.updateUserProfileImage(fileData.file);
        break;
    }
  }

  isCurrentUser() {
    return (
      this.props.profileState.user.handle === this.props.userState.user.handle &&
      this.props.userState.user.id !== '' &&
      this.isUserStillLoggedIn()
    );
  }

  handleProfileInputChange(event: any, self: any, prevent?: boolean, callback?: any) {
    if (prevent === true) {
      event.preventDefault();
    }

    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;
    const name = target.name;

    self.setState(
      {
        profile: {
          ...this.state.profile,
          [name]: value,
        },
      },
      () => {
        if (callback !== undefined) {
          callback();
        }
      },
    );
  }

  saveProfile() {
    this.props.updateUser(this.state.profile);
  }

  setPage(index: number) {
    this.setState(
      {
        currentPage: index,
      },
      () => {
        if (
          this.props.profileState.user.id &&
          this.props.profileState.pages[this.state.currentPage] !== undefined
        ) {
          this.props.getProfilePageComponents(
            this.props.profileState.user.id,
            this.props.profileState.pages[this.state.currentPage].id,
          );
        }
      },
    );
  }

  handlePageReorder = (sourceIndex: number, destIndex: number | null) => {
    if (destIndex !== null) {
      let _pages = this.state.pages;

      _pages[sourceIndex].sequence = destIndex + 1;
      _pages.splice(destIndex, 0, _pages.splice(sourceIndex, 1)[0]);

      this.setState(
        {
          pages: _pages,
          currentPage: destIndex,
        },
        () => {
          this.props.updateUserPage(this.state.pages[destIndex]);
        },
      );
    }
  };

  createNewPage = (_newPage: Partial<IPage>) => {
    this.props.createUserPage(_newPage);
  };

  updatePage = (pageId: string, page: IPage) => {
    this.props.updateUserPage(page);
  };

  deletePage = (pageId: string) => {
    this.props.deleteUserPage(pageId);
  };

  onUpdateComponent = (pageId: string, componentId: string, payload: Partial<IPageComponent>) => {
    this.props.updateUserPageComponent(pageId, componentId, payload);
  };

  toggleShowUserProfile = () => {
    this.setState({ showUserProfileModal: !this.state.showUserProfileModal });
  };

  isUserStillLoggedIn(): boolean {
    return this.props.userState.isLoggedIn;
  }

  renderPageHeaderElements() {
    if (
      this.props.profileState.user?.id !== this.props.userState.user?.id &&
      this.props.userState.isLoggedIn
    ) {
      return (
        <>
          <ContactButton contact={this.props.profileState.user?.id || ''} />
          <MetricsList
            objectType="userProfile"
            userId={this.props.profileState.user.id || ''}
          />
        </>
      );
    } else {
      return (
        <MetricsList
          objectType="userProfile"
          userId={this.props.profileState.user.id || ''}
        />
      );
    }
  }

  render() {
    let _joyrideStyles = {
      options: {
        arrowColor: '#FFF',
        backgroundColor: '#FFF',
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: '#5E51AB',
        textColor: '#505050',
      },
    };

    if (
      !this.props.profileState.isProfileLoading &&
      this.props.profileState.user.id === '' &&
      this.state.mounted &&
      !this.props.userState.awaitingToken
    ) {
      return <Redirect to="/not-found" />;
    } else {
      return (
        <div className="User">
          {this.state.params.help !== undefined &&
            !this.state.showUserOnboard &&
            !this.props.userState.isUserLoading && (
              <Joyride
                locale={{ back: 'Back', close: 'Close', last: 'Last', next: 'Next', skip: 'Skip' }}
                continuous={true}
                disableScrolling={true}
                styles={_joyrideStyles}
                steps={this.state.steps}
                callback={(state: any) => {
                  if (state.action === 'reset') {
                    window.history.pushState({}, '', '/user/' + this.props.userState.user.handle);
                  }
                }}
              />
            )}
          <BackSplash
            coverImageUrl={this.props.profileState.user.cover_image_url}
            onShareClick={() => this.setState({ showShareModal: true })}
            showChat={false}
            onChatClick={() => {}}
            onImageChange={this.onImageChange}
            showEditCover={this.isCurrentUser()}
          />
          <div className="user-inner">
            <PageSidebar>
              <PageHeader
                ownerType={uiConstants.ownerType.user}
                history={this.props.history}
                isLoading={this.props.profileState.isProfileLoading}
                showEditPortrait={this.isCurrentUser()}
                portraitImageUrl={this.props.profileState.user.profile_image_url}
                typeName="PROFILE"
                typeIconClass="fal fa-star"
                displayName={this.state.profile.display_name}
                onDisplayNameChange={(e) => this.handleProfileInputChange(e, this)}
                onImageChange={(e) => this.onImageChange(e)}
                onSubmitDisplayNameChange={() => this.saveProfile()}
                showEditDisplayName={this.isCurrentUser()}
                handle={this.props.match.params.username}
                onShareClick={() => this.setState({ showShareModal: true })}
                showChat={false}
                onChatClick={() => {}}
                children={this.renderPageHeaderElements()}
                userId={this.state.profile.id}
              />
            </PageSidebar>
            <PageBody>
              <PageCoverImage
                coverImageUrl={this.props.profileState.user.cover_image_url}
                onImageChange={this.onImageChange}
                ShowEditCover={this.isCurrentUser()}
                showManageButton={this.isCurrentUser()}
                manageUrl="/account"
              />

              <PageContainer isLoading={this.props.profileState.isProfilePagesLoading}>
                <PageSelector
                  currentPageIndex={this.state.currentPage}
                  isLoading={this.props.profileState.isProfilePagesLoading}
                  pages={this.state.pages}
                  editable={this.isCurrentUser()}
                  onPageClick={(index) => this.setPage(index)}
                  onOpenManageModal={() => this.setState({ showManageModal: true })}
                />
                <PageContent
                  owner={typeHelpers.createOwnerObject('user', this.props.profileState.user)}
                  currentPageIndex={this.state.currentPage}
                  pages={this.state.pages}
                  pageComponents={this.props.profileState.currentPageComponents}
                  isAdmin={this.isCurrentUser()}
                  createPageComponent={this.onAddComponent}
                  routeProps={this.props}
                  onUpdateComponent={this.onUpdateComponent}
                  isLoading={this.props.profileState.isComponentsLoading}
                  isUserStillLoggedIn={this.isUserStillLoggedIn()}
                />
              </PageContainer>
            </PageBody>
          </div>
          <ManageTabsModal
            show={this.state.showManageModal}
            isLoading={this.props.profileState.isProfilePagesLoading}
            pages={this.state.pages}
            onClose={() => this.setState({ showManageModal: false })}
            onReorderPage={this.handlePageReorder}
            onCreatePage={this.createNewPage}
            onUpdatePage={this.updatePage}
            onDeletePage={this.deletePage}
          />
          <Modal
            class="share-modal"
            show={this.state.showShareModal}
            onClose={() => {
              this.setState({ showShareModal: false });
            }}
          >
            <Share
              {...this.props}
              url={Config.web.REACT_APP_BASE_URL + '/user/' + this.props.profileState.user.handle}
            />
          </Modal>
          <Modal
            show={this.state.showUserOnboard}
            onClose={() => {
              this.setState({ showUserOnboard: false });
            }}
          >
            <div className="onboarding-container user-onboard">
              {this.state.userOnboardStep === 1 && (
                <div className="wrapper find">
                  <div className="onboard-img step1"></div>
                  <div className="onboard-title">Find</div>
                  <div className="onboard-info">
                    Find what you're looking for - causes, events, volunteer and paid opportunities.
                  </div>
                  <div className="steps-select">
                    <i className="fas fa-circle"></i>
                    <i
                      onClick={() => this.setState({ userOnboardStep: 2 })}
                      className="far fa-circle"
                    ></i>
                    <i
                      onClick={() => this.setState({ userOnboardStep: 3 })}
                      className="far fa-circle"
                    ></i>
                  </div>
                </div>
              )}
              {this.state.userOnboardStep === 2 && (
                <div className="wrapper connect">
                  <div className="onboard-img step1"></div>
                  <div className="onboard-title">Connect</div>
                  <div className="onboard-info">
                    Connect and communicate with your peers, causes and community all in one place.
                  </div>
                  <div className="steps-select">
                    <i
                      onClick={() => this.setState({ userOnboardStep: 1 })}
                      className="far fa-circle"
                    ></i>
                    <i className="fas fa-circle"></i>
                    <i
                      onClick={() => this.setState({ userOnboardStep: 3 })}
                      className="far fa-circle"
                    ></i>
                  </div>
                </div>
              )}
              {this.state.userOnboardStep === 3 && (
                <div className="wrapper giveback">
                  <div className="onboard-img step1"></div>
                  <div className="onboard-title">Giveback</div>
                  <div className="onboard-info">
                    Giveback to the focus areas you care about - donate, volunteer, fundraise and
                    support your community!
                  </div>
                  <div className="steps-select">
                    <i
                      onClick={() => this.setState({ userOnboardStep: 1 })}
                      className="far fa-circle"
                    ></i>
                    <i
                      onClick={() => this.setState({ userOnboardStep: 2 })}
                      className="far fa-circle"
                    ></i>
                    <i className="fas fa-circle"></i>
                  </div>
                </div>
              )}
              {this.state.userOnboardStep === 1 && (
                <div className="onboard-actions">
                  <Button
                    onClick={() => this.setState({ userOnboardStep: 2 })}
                    text="Next"
                  />
                  <span onClick={() => this.setState({ showUserOnboard: false })}>Skip</span>
                </div>
              )}
              {this.state.userOnboardStep === 2 && (
                <div className="onboard-actions">
                  <Button
                    onClick={() => this.setState({ userOnboardStep: 3 })}
                    text="Next"
                  />
                  <span onClick={() => this.setState({ showUserOnboard: false })}>Skip</span>
                </div>
              )}
              {this.state.userOnboardStep === 3 && (
                <div className="onboard-actions">
                  <Button
                    onClick={() => this.setState({ showUserOnboard: false, userOnboardStep: 0 })}
                    text="Setup Your Profile"
                  />
                  <span onClick={() => this.setState({ showUserOnboard: false })}>Skip</span>
                </div>
              )}
            </div>
          </Modal>
          <FloatingActionButton
            onClick={this.toggleShowUserProfile}
            icon="fa fa-bars"
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    profileState: store.profileState,
  };
};

const mapDispatchToProps = {
  getProfileByHandle,
  updateUser,
  updateUserCoverImage,
  updateUserProfileImage,
  getProfilePageComponents,
  createUserPage,
  updateUserPage,
  deleteUserPage,
  createUserPageComponent,
  updateUserPageComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
