import { IHub, ISocialImpactBenefit, ISocialImpactBenefits } from '@gigit/interfaces';
import React from 'react';
import './SocialImpactBenefitsComponent.scss';
import emptyStateIcon from '../../../assets/social-impact-assets/thumb_up_off.svg';
import { defaultCurrency, formatCurrency } from '../../../helpers';
import { useLocale } from '../../../hooks';
import { Constants } from '@gigit/constants';
import paidVolunteerDaysIcon from '../../../assets/social-impact-assets/business_center.svg';
import expensesPaidIcon from '../../../assets/social-impact-assets/payments.svg';
import donationMatchingIcon from '../../../assets/social-impact-assets/redeem.svg';
import remoteWorkIcon from '../../../assets/social-impact-assets/cottage.svg';
import mentalHealthIcon from '../../../assets/social-impact-assets/health_and_safety.svg';
import { localizeHelpers } from '../../../localizeHelpers';

export type BenefitType = keyof ISocialImpactBenefits;

type BenefitConfig = {
  [key: string]: {
    icon: string;
    name: string;
    value?: number;
    getDescription?: (value: number) => string;
  };
};

interface IProps {
  hub: IHub | null;
  toggleShowMangeSocialBenefitsModal: (value: boolean) => void;
  userHasEditPermissions: boolean;
}

function SocialImpactBenefitsComponent(props: IProps) {
  const locale = useLocale();
  const benefitConfig: BenefitConfig = {
    paid_volunteer_days: {
      icon: paidVolunteerDaysIcon,
      name: 'Paid Volunteer Days',
      getDescription: (value) => {
        const message = localizeHelpers.translate('Up to {{value}} Days Annually', { value });

        return message;
      },
    },
    expenses_paid_for_volunteer_days: {
      icon: expensesPaidIcon,
      name: 'Paid Expenses for Volunteer Days',
      getDescription: (value) => {
        const formattedCurrency = formatCurrency(
          value,
          props.hub?.account?.currency || defaultCurrency,
          locale.currentLocale,
          {
            numberFormatOptions: {
              notation: 'standard',
              minimumFractionDigits: 0,
            },
          },
        );

        const message = localizeHelpers.translate('Up to {{formattedCurrency}} a day', {
          formattedCurrency,
        });

        return message;
      },
    },
    donation_matching: {
      icon: donationMatchingIcon,
      name: 'Donation Matching Programs',
    },
    remote_or_hybrid_working_options: {
      icon: remoteWorkIcon,
      name: 'Remote / Hybrid Work Options',
    },
    mental_health_benefits: {
      icon: mentalHealthIcon,
      name: 'Mental Health Benefits',
    },
  };

  const socialImpactBenefitsOffered = Object.entries(props.hub?.social_impact_benefits || {})
    .filter((benefit) => benefit[1].is_offered)
    .map((benefit) => ({
      ...benefit[1],
      ...benefitConfig[benefit[0]],
    }));

  function renderEmptyState() {
    return (
      <div className="empty-state">
        <div className="icon">
          <img src={emptyStateIcon} />
        </div>
        <span className="title">There isn't any benefits here yet.</span>
        {props.userHasEditPermissions && (
          <span
            className="link"
            onClick={() => props.toggleShowMangeSocialBenefitsModal(true)}
          >
            Add Benefits
          </span>
        )}
      </div>
    );
  }

  return (
    <ul className="SocialImpactBenefitsComponent">
      {socialImpactBenefitsOffered.length > 0 &&
        socialImpactBenefitsOffered?.map((benefit, idx) => (
          <li
            className="list-item"
            key={idx}
          >
            <img
              className="icon"
              src={benefit.icon}
            />
            <div className="description-container">
              <span className="title">{benefit.name}</span>
              {benefit.value ? (
                <span
                  className="description"
                  notranslate="yes"
                >
                  {benefit.getDescription?.(benefit.value)}
                </span>
              ) : (
                <span className="description">Available</span>
              )}
            </div>
          </li>
        ))}
      {socialImpactBenefitsOffered.length === 0 && renderEmptyState()}
    </ul>
  );
}

export default SocialImpactBenefitsComponent;
