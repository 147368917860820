import React, { useEffect, useState } from 'react';
import './HubStoreSimplifiedView.scss';
import { IHub, IStoreItem } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import Carousel from '../../../Carousel/Carousel';
import HubStoreSingleItem from '../HubStoreSingleItem/HubStoreSingleItem';
import HubStoreEmptyState from '../HubStoreEmptyState/HubStoreEmptyState';
import HubStoreDetailItemModal from '../HubStoreDetailItemModal/HubStoreDetailItemModal';
import useToastDispatcher from '../../../../hooks/useToaster';
import { hubRequestActions } from '../../../../requestActions';
import { formatQuery } from '../../../../helpers';
import { uiConstants } from '../../../../constants';
import { IThemeStyleObjectsByType } from '../../Hub';

interface IProps {
  hub: IHub | null;
  theme: IThemeStyleObjectsByType;
  userHasEditPermissions: boolean;
}

const HubStoreSimplifiedView: React.FC<IProps> = (props) => {
  const [storeItems, setStoreItems] = useState<IStoreItem[]>([]);
  const [activeStoreItem, setActiveStoreItem] = useState<IStoreItem | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { dispatchToastError } = useToastDispatcher();

  function getHubStoreItems() {
    if (props?.hub?.id) {
      hubRequestActions
        .getHubStoreItems(
          props.hub.id,
          formatQuery({
            limit: uiConstants.hubs.homePageStoreItemsLimit,
          }),
        )
        .then((res) => setStoreItems(res))
        .catch((error) => dispatchToastError(error, 'Get Hub Store Items'));
    }
  }

  useEffect(() => {
    getHubStoreItems();
  }, [props.hub]);

  function getCarouselSlides() {
    return storeItems.map((item: IStoreItem, index: number) => (
      <HubStoreSingleItem
        key={index}
        storeItem={item}
        openStoreItemDetailModal={() => {
          setActiveStoreItem(item);
          setShowModal(true);
        }}
      />
    ));
  }

  return (
    <div className="HubStoreSimplifiedView">
      <div className="section-content">
        {storeItems.length > 0 && (
          <Carousel
            infinite={false}
            slidesToScroll={4}
            slidesToShow={4}
            showArrows={true}
            showDots={true}
            customDotColor={props.theme.secondaryBg.backgroundColor}
            nextArrow={
              <span>
                <i className="fas fa-chevron-right" />
              </span>
            }
            prevArrow={
              <span>
                <i className="fas fa-chevron-left" />
              </span>
            }
            slides={getCarouselSlides()}
            slideWidth={'270'}
          />
        )}

        {storeItems.length < 1 && (
          <HubStoreEmptyState
            hub={props.hub}
            userHasEditPermissions={props.userHasEditPermissions}
          />
        )}

        {activeStoreItem && (
          <HubStoreDetailItemModal
            show={showModal}
            onClose={() => {
              setShowModal(false);
              setActiveStoreItem(null);
            }}
            storeItem={activeStoreItem}
            hubName={props.hub?.title}
            hubLogoUrl={props.hub?.profile_image_url}
          />
        )}
      </div>
    </div>
  );
};

export default HubStoreSimplifiedView;
