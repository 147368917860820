import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import {
  handleInputChange,
  routes,
  swapRouteParams,
  toastError,
  typeHelpers,
  errorHelpers,
} from '../../helpers';

import { ICharityVerificationRequest, IGroup } from '@gigit/interfaces';
import { IToast } from '../../interfaces';
import { createToast } from '../../actions/toaster';

import TextField from '../../components/TextField/TextField';
import { Prompt } from '../Prompt/Prompt';
import SortableTable, { ISortableTableColumn } from '../SortableTable/SortableTable';
import './PendingCharityGroups.scss';

interface IProps {
  createToast(toast: IToast): void;
}

interface IState {
  pendingCharityGroups: Array<ICharityVerificationRequest>;
  searchValue: string;
  dataLoading: boolean;
  groupColumns: ISortableTableColumn[];
  activeRequest: { pendingRequest: ICharityVerificationRequest; intent: 'approve' | 'deny' } | null;
}

class PendingCharityGroups extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      pendingCharityGroups: [],
      searchValue: '',
      dataLoading: false,
      activeRequest: null,
      groupColumns: [
        { id: 'title', label: 'Name', sortable: false },
        { id: 'handle', label: 'Handle', sortable: false },
        { id: 'charity_id', label: 'Charity Id', sortable: false },
        { id: 'email', label: 'Contact Email', sortable: false },
        { id: 'phone', label: 'Contact Phone', sortable: false },
        { id: 'website_url', label: 'Website', sortable: false },
        { id: 'actions', label: '', sortable: false },
      ],
    };
  }

  componentDidMount() {
    this.getPendingCharityGroups();
  }

  getPendingCharityGroups() {
    this.setState({ dataLoading: true });
    axios
      .get<ICharityVerificationRequest[]>(routes.PENDING_CHARITY_GROUPS)
      .then((response) => {
        this.setState({
          pendingCharityGroups: response.data,
        });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Get Pending Charity Causes');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  processPendingCharity(approved: boolean, pendingRequestId: string) {
    const route = approved
      ? routes.APPROVE_CHARITY_VERIFICATION
      : routes.DECLINE_CHARITY_VERIFICATION;
    axios
      .post<IGroup>(swapRouteParams(route, { id: pendingRequestId }))
      .then((response) => {
        this.getPendingCharityGroups();
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Pending Charity Cause');
        this.props.createToast(toast);
      });
  }

  getGropsTableData() {
    const searchValue = this.state.searchValue.toLowerCase();
    const pendingCharityGroupsList = searchValue
      ? this.state.pendingCharityGroups?.filter(
          (group) =>
            group.group_payload?.group.title.toLowerCase().includes(searchValue) ||
            group.group_payload?.group.handle?.toLowerCase().includes(searchValue),
        )
      : this.state.pendingCharityGroups;

    return pendingCharityGroupsList.map((pendingRequest: ICharityVerificationRequest) => {
      return {
        row: [
          {
            content: pendingRequest.group_payload?.group.title,
            id: 'title',
            hoverText: pendingRequest.group_payload?.group.title,
          },
          {
            content: pendingRequest.group_payload?.group.handle,
            id: 'handle',
            hoverText: pendingRequest.group_payload?.group.handle,
          },
          {
            content: pendingRequest.group_payload?.group.charity_id,
            id: 'charity_id',
            hoverText: pendingRequest.group_payload?.group.charity_id,
          },
          {
            content: pendingRequest.group_payload?.group.contact_details?.email,
            id: 'email',
            hoverText: pendingRequest.group_payload?.group.contact_details?.email,
          },
          {
            content: pendingRequest.group_payload?.group.contact_details?.phone,
            id: 'phone',
            hoverText: pendingRequest.group_payload?.group.contact_details?.phone,
          },
          {
            content: pendingRequest.group_payload?.group.website_url,
            id: 'website_url',
            hoverText: pendingRequest.group_payload?.group.website_url,
          },
          {
            id: 'actions',
            menu: [
              {
                icon: 'far fa-pencil-alt',
                onClick: () => {
                  this.setState({ activeRequest: { pendingRequest, intent: 'approve' } });
                },
                label: 'Approve',
              },
              {
                icon: 'fa fa-ban',
                onClick: () => {
                  this.setState({ activeRequest: { pendingRequest, intent: 'deny' } });
                },
                label: 'Deny',
              },
            ],
          },
        ],
      };
    });
  }

  render() {
    return (
      <div className="Charities">
        <div className="section-title">
          <div className="forms-title">Pending Charity Causes</div>
          <div className="forms-controls">{/* Actions buttons here if needed */}</div>
        </div>

        <div className="section-inner">
          <div className="search">
            <TextField
              icon="fas fa-search"
              placeholder="Search cause..."
              value={this.state.searchValue}
              type="text"
              name="searchValue"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e, this);
              }}
            />
            <div className="filters">{/* Filter buttons here if needed */}</div>
          </div>

          <div className="items-list">
            <SortableTable
              {...this.props}
              columns={this.state.groupColumns}
              data={this.getGropsTableData()}
              itemsPerPage={5}
              loading={this.state.dataLoading}
            />
          </div>

          {!this.state.dataLoading && (
            <div className={`no-items-found ${this.getGropsTableData().length ? '' : 'show'}`}>
              No items found
            </div>
          )}
        </div>

        <Prompt
          show={!!this.state.activeRequest}
          title={this.state.activeRequest?.intent === 'approve' ? 'Approve Cause' : 'Deny Cause'}
          message={`Are you sure you want to ${this.state.activeRequest?.intent} cause ${this.state.activeRequest?.pendingRequest.group_payload?.group.title || ''}?`}
          yesMessage={this.state.activeRequest?.intent === 'approve' ? 'Yes' : 'Deny'}
          yesClass={this.state.activeRequest?.intent === 'approve' ? 'fas fa-check' : 'fa fa-ban'}
          yesStyle={this.state.activeRequest?.intent === 'approve' ? 'normal' : 'delete'}
          cancelMessage="Cancel"
          onYes={() => {
            this.state.activeRequest &&
              this.processPendingCharity(
                this.state.activeRequest.intent === 'approve',
                this.state.activeRequest.pendingRequest.id,
              );
          }}
          onClose={() => {
            this.setState({ activeRequest: null });
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  createToast,
};

export default connect(null, mapDispatchToProps)(PendingCharityGroups);
