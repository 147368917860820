import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import ContextMenu from '../../components/ContextMenu/ContextMenu';

import { IGroupState } from '../../reducers/group';
import { IEventState } from '../../reducers/event';

import { getGroupGigPayments } from '../../actions/group';
import { getEventGigPayments } from '../../actions/event';

import './GigPayments.scss';
import moment from 'moment';
import TextField from '../TextField/TextField';
import { defaultCurrency, handleDebounce, handleInputChange } from '../../helpers';
import { formatCurrency } from '../../helpers';
import { Constants } from '@gigit/constants';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import { ITransactionSummary } from '@gigit/interfaces';
import { IOwnerObject } from '../../interfaces';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  eventState: IEventState;
  getGroupGigPayments(groupId: string, _payload?: any): void;
  getEventGigPayments(eventId: string, _payload?: any): void;
  owner: IOwnerObject;
  locale: string;
}

interface IState {
  searchValue: string;
  payments: ITransactionSummary[];
}

class GigPayments extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: '',
      payments: [],
    };

    this.searchGigPayments = this.searchGigPayments.bind(this);
  }

  componentDidMount() {
    if (this.props.owner.ownerType === Constants.object_type.group) {
      this.props.getGroupGigPayments(this.props.groupState.group.id);
    } else {
      this.props.getEventGigPayments(this.props.eventState.event.id);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.groupState.groupGigPayments !== prevProps.groupState.groupGigPayments ||
      this.props.eventState.eventGigPayments !== prevProps.eventState.eventGigPayments
    ) {
      this.setState({
        payments:
          this.props.owner.ownerType === Constants.object_type.group
            ? this.props.groupState.groupGigPayments
            : this.props.eventState.eventGigPayments,
      });
    }
  }

  searchGigPayments() {
    this.props.getGroupGigPayments(this.props.match.params.id, this.state.searchValue);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="GigPayments">
        <div className="search">
          <TextField
            icon="fas fa-search"
            placeholder={t('Search volunteer opportunity payments...')}
            value={this.state.searchValue}
            type="text"
            name="searchValue"
            onChange={(e: any) => {
              handleInputChange(e, this, false, () => {
                handleDebounce(this.state.searchValue).then((res) => {
                  this.searchGigPayments();
                });
              });
            }}
          />
        </div>
        <div className="list">
          <div className="headers">
            <div className="col payer-name">Payer Name</div>
            <div className="col event-group-name">Event/Cause Name</div>
            {/* Commented out for now since transaction doesn't have a "Gig Title" field. 
                                Gig Payments aren't used currently, so it's not a big deal. In future, this will need to be fixed. */}
            {/* <div className="col gig-title">Gig Title</div> */}
            <div className="col paid-total">Total Amount</div>
            <div className="col payment-date">Payment Date</div>
            <div className="col actions"></div>
          </div>
          <div className="list-inner">
            <div className="list-rows">
              {this.state.payments?.map((gigPayment, index) => {
                const exchangeRate = gigPayment.reporting_exchange_rate ?? 1;
                const currency = gigPayment.reporting_currency ?? defaultCurrency;

                let _menuItems = [{ icon: 'far fa-dollar-sign', label: t('View Statement') }];

                return (
                  <div
                    className="row"
                    key={index}
                  >
                    <div
                      notranslate="yes"
                      className="col"
                    >
                      {gigPayment.user.display_name}
                    </div>
                    <div
                      notranslate="yes"
                      className="col"
                    >
                      {gigPayment.group_handle || gigPayment.event_handle}
                    </div>
                    {/* Commented out for now since transaction doesn't have a "Gig Title" field. 
                                        Gig Payments aren't used currently, so it's not a big deal. In future, this will need to be fixed. */}
                    {/* <div notranslate="yes" className="col">{gigPayment.gig_title}</div> */}
                    <div
                      notranslate="yes"
                      className="col"
                    >
                      {formatCurrency(
                        (gigPayment.amounts.total / 100) * exchangeRate,
                        currency,
                        this.props.locale,
                      )}
                    </div>
                    <div
                      notranslate="yes"
                      className="col"
                    >
                      {gigPayment.created_at
                        ? localizeHelpers.formatDate(
                            gigPayment.created_at,
                            LocaleDateFormats.LLL,
                            this.props.locale,
                          )
                        : ''}
                    </div>
                    <div className="col actions">
                      <i className="fal fa-ellipsis-h-alt"></i>
                      <ContextMenu
                        onMouseLeave={() => {}}
                        showMenu={true}
                        menuItems={_menuItems}
                      />
                    </div>
                  </div>
                );
              })}

              {this.state.payments?.length === 0 && (
                <div className="empty">
                  {t("You haven't issued any volunteer opportunity payments, yet.")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getGroupGigPayments,
  getEventGigPayments,
};

export default withRouter(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(GigPayments)),
);
