import axios from 'axios';
import {
  IAttachmentCreateParams,
  IAttachmentCreateReturn,
  IAttachmentGetReturn,
  IChangeDonationDestinationRequest,
  ICreateOfflineDonationReturnType,
  ICreditDonationRequest,
  IDonationMatchingPayoutReconcileParams,
  IDonationMatchingProgram,
  IOfflineDonationRequest,
  IOfflineDonationUpdateParams,
  ITransaction,
  IUserCreditPaymentRequestFE,
} from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { IOwnerObject } from '../interfaces';
import { routes, swapRouteParams } from '../helpers';
import { ITransactionSummary } from '@gigit/interfaces';

export namespace donationRequestActions {
  export async function getNextDefaultReceiptNumber(payload: {
    groupId: string;
    campaignId: string;
  }): Promise<string> {
    const response = await axios.get<string>(
      swapRouteParams(routes.GET_DONATION_NEXT_DEFAULT_TAX_RECEIPT_NUMBER, {
        groupId: payload.groupId,
        campaignId: payload.campaignId,
      }),
    );
    return response.data;
  }

  export async function getCurrentTaxReceiptInfo(payload: {
    groupId: string;
    campaignId: string;
  }): Promise<{
    prefix: string;
    padding: number;
    sequence_number: number;
    receiptNumber: string;
  }> {
    const response = await axios.get<{
      prefix: string;
      padding: number;
      sequence_number: number;
      receiptNumber: string;
    }>(
      swapRouteParams(routes.GET_GROUP_RECEIPT_NUMBER_INFO_NEXT_AVAILABLE, {
        groupId: payload?.groupId,
        campaign_id: payload.campaignId,
      }),
    );

    return response.data;
  }

  export async function isUsedTaxReceiptNumber(payload: {
    sequenceNumber: string;
    campaignId: string;
    groupId: string;
  }): Promise<{ isUsed: boolean; taxReceiptNumber: string }> {
    const response = await axios.get<{ isUsed: boolean; taxReceiptNumber: string }>(
      swapRouteParams(routes.GET_IS_USED_TAX_RECEIPT_NUMBER, {
        groupId: payload.groupId,
        campaignId: payload.campaignId,
        sequence_number: payload.sequenceNumber,
      }),
    );
    return response.data;
  }

  export async function sendTaxReceipt(payload: {
    groupId?: string;
    eventId?: string;
    donationId: string;
  }): Promise<void> {
    let routeValue = '';
    if (payload?.groupId) {
      routeValue = routes.SEND_TAX_RECEIPT;
    } else if (payload?.eventId) {
      routeValue = routes.SEND_EVENT_TAX_RECEIPT;
    }
    await axios.post<void>(
      swapRouteParams(routeValue, {
        groupId: payload?.groupId,
        eventId: payload?.eventId,
        donationId: payload.donationId,
      }),
    );
  }

  export async function generateTaxReceipt(payload: {
    groupId?: string;
    eventId?: string;
    donationId: string;
    sequenceNumber?: number;
    amount?: number;
    language?: string;
    send?: boolean;
    edit?: boolean;
    regenerate?: boolean;
    override_next_sequence_number?: boolean;
  }): Promise<void> {
    const requestParams = {
      groupId: payload?.groupId,
      eventId: payload?.eventId,
      donationId: payload.donationId,
    };

    const requestBody = {
      language: payload?.language,
      sequenceNumber: payload.sequenceNumber,
      amount: payload?.amount,
      send: payload?.send,
      regenerate: payload?.regenerate,
      override_next_sequence_number: payload?.override_next_sequence_number,
    };

    let routeValue = '';

    if (payload?.edit) {
      if (payload?.groupId) {
        routeValue = routes.GROUP_EDIT_TAX_RECEIPT;
      } else if (payload?.eventId) {
        routeValue = routes.EVENT_EDIT_TAX_RECEIPT;
      }

      await axios.put<void>(swapRouteParams(routeValue, requestParams), {
        body: requestBody,
      });
    } else {
      if (payload?.groupId) {
        routeValue = routes.GROUP_GENERATE_TAX_RECEIPT;
      } else if (payload?.eventId) {
        routeValue = routes.EVENT_GENERATE_TAX_RECEIPT;
      }

      await axios.post<void>(swapRouteParams(routeValue, requestParams), {
        body: requestBody,
      });
    }
  }

  export async function getDonations(payload: {
    groupId?: string;
    eventId?: string;
    subscription?: boolean;
    query?: URLSearchParams;
  }): Promise<ITransactionSummary[]> {
    let routeValue = '';
    if (payload?.groupId) {
      if (payload?.subscription) {
        routeValue = routes.GET_GROUP_SUBSCRIPTIONS;
      } else {
        routeValue = routes.GET_GROUP_DONATIONS;
      }
    } else if (payload?.eventId) {
      routeValue = routes.GET_EVENT_DONATIONS;
    }

    let _route = swapRouteParams(routeValue, {
      groupId: payload?.groupId,
      eventId: payload?.eventId,
    });

    const queryString = payload.query?.toString();
    if (queryString) {
      _route = _route + `?${queryString}`;
    }
    const response = await axios.get<ITransactionSummary[]>(_route);
    return response.data;
  }

  export async function updateTransactionPayment(payload: {
    groupId: string;
    transactionId: string;
    params: { paymentMethod: string };
  }): Promise<void> {
    await axios.post(
      swapRouteParams(routes.UPDATE_TRANSACTION_PAYMENT_STATUS, {
        groupId: payload.groupId,
        transaction_id: payload.transactionId,
      }),
      payload.params,
    );
  }

  export async function refundDonation(payload: {
    groupId?: string;
    eventId?: string;
    donationId: string;
    params: {
      amount: number | string;
      group_title: string;
    };
  }): Promise<void> {
    let routerValue = '';
    if (payload?.groupId) {
      routerValue = routes.REFUND_DONATION;
      await axios.post(
        swapRouteParams(routerValue, { groupId: payload.groupId, donationId: payload.donationId }),
        payload.params,
      );
    } else if (payload?.eventId) {
      routerValue = routes.REFUND_EVENT_DONATION;
      await axios.post(
        swapRouteParams(routerValue, { eventId: payload.eventId, donationId: payload.donationId }),
        payload.params,
      );
    }
  }

  export async function updateThankYouMessage(payload: {
    groupId?: string;
    eventId?: string;
    transaction_id: string;
    body: {
      value: string;
    };
  }): Promise<void> {
    let route = '';
    if (payload?.groupId) {
      route = routes.UPDATE_THANK_YOU_MESSAGE;
    } else if (payload?.eventId) {
      route = routes.UPDATE_THANK_YOU_MESSAGE;
    }

    await axios.put<void>(
      swapRouteParams(route, {
        groupId: payload?.groupId,
        eventId: payload?.eventId,
        transaction_id: payload.transaction_id,
      }),
      payload.body,
    );
  }

  export async function reconcileMatchingProgramPayout(
    object_id: string,
    program_id: string,
    payload: IDonationMatchingPayoutReconcileParams,
  ) {
    const url = routes.RECONCILE_MATCHING_PROGRAM_PAYOUT;
    const response = await axios.post<IDonationMatchingProgram>(
      swapRouteParams(url, { object_id, program_id }),
      payload,
    );
    return response.data;
  }

  export async function changeDonationDestination(
    owner: IOwnerObject,
    transaction: ITransaction,
    payload: IChangeDonationDestinationRequest,
  ): Promise<ITransaction> {
    const url =
      owner.ownerType === Constants.object_type.group
        ? routes.GROUP_CHANGE_DONATION_DESTINATION
        : routes.EVENT_CHANGE_DONATION_DESTINATION;
    const response = await axios.put<ITransaction>(
      swapRouteParams(url, { id: owner.ownerId, transaction_id: transaction.id }),
      payload,
    );
    return response.data;
  }

  export async function createOfflineUserDonation(
    payload: IOfflineDonationRequest,
  ): Promise<ICreateOfflineDonationReturnType> {
    const response = await axios.post<ICreateOfflineDonationReturnType>(
      routes.CREATE_OFFLINE_USER_DONATION,
      payload,
    );
    return response.data;
  }

  export async function createHubOfflineCorporateDonation(
    hubId: string,
    payload: IOfflineDonationRequest,
  ): Promise<ICreateOfflineDonationReturnType> {
    const response = await axios.post<ICreateOfflineDonationReturnType>(
      swapRouteParams(routes.CREATE_OFFLINE_HUB_DONATION, {
        object_id: hubId,
      }),
      payload,
    );
    return response.data;
  }

  export async function updateOfflineDonation(
    transactionId: string,
    payload: IOfflineDonationUpdateParams,
  ): Promise<ITransaction> {
    const response = await axios.put<ITransaction>(
      swapRouteParams(routes.UPDATE_OFFLINE_DONATION, {
        transaction_id: transactionId,
      }),
      payload,
    );

    return response.data;
  }

  export async function uploadOfflineDonationAttachment(
    payload: IAttachmentCreateParams,
  ): Promise<IAttachmentCreateReturn> {
    const response = await axios.post<IAttachmentCreateReturn>(
      routes.ATTACHMENTS.CREATE_OFFLINE_DONATION_ATTACHMENT_URL,
      payload,
    );
    return response.data;
  }

  export async function deleteOfflineDonationAttachment(
    transactionId: string,
    attachmentId: string,
  ): Promise<ITransaction> {
    const response = await axios.delete<ITransaction>(
      swapRouteParams(routes.ATTACHMENTS.DELETE_OFFLINE_DONATION_ATTACHMENT_URL, {
        transaction_id: transactionId,
        attachment_id: attachmentId,
      }),
    );

    return response.data;
  }

  export async function getOfflineDonationAttachment(
    transactionId: string,
    attachmentId: string,
  ): Promise<IAttachmentGetReturn> {
    const response = await axios.get<IAttachmentGetReturn>(
      swapRouteParams(routes.ATTACHMENTS.GET_OFFLINE_DONATION_TRANSACTION_ATTACHMENT_URL, {
        transaction_id: transactionId,
        attachment_id: attachmentId,
      }),
    );
    return response.data;
  }

  export async function donateWithCredits(groupId: string, payload: ICreditDonationRequest) {
    const response = await axios.post<IUserCreditPaymentRequestFE>(
      swapRouteParams(routes.DONATE_WITH_CREDITS, { group_id: groupId }),
      payload,
    );
    return response.data;
  }
}
