import { IAPIErrorMessageArgs } from '@gigit/interfaces';

export interface IStep {
  target: string;
  content: string;
  disableBeacon?: boolean;
  event?: string;
}

export interface IKpi {
  value: string;
  text: string;
}

export enum StatisticType {
  DONATIONS = 'donationsStats',
  STORE = 'storeStats',
  STORE_PUSRCHASES = 'storePurchasesStats',
  AUCTION = 'auctionStats',
  AUCTION_PURCHASES = 'auctionPurchasesStats',
}

/** Standard error object. */
export interface IAppError {
  /** Standard error code. Should only be used for error checking in Frontend. (@see gigit.exception.ts for all error codes) */
  errorCode: string | null;

  /** Message template that can be used for translating errors. Variables are in format: {{var_name}} */
  messageTemplate: string;

  /** Message template that can be used for translating errors. Variables are in format: {{var_name}} */
  messageArgs?: IAPIErrorMessageArgs;

  /** Message that has been translated to the user language. */
  translatedMessage: string;
}

export interface IToastAction {
  text?: string;
  onClick?: () => void;
  link?: {
    link: string;
    text: string;
  };
}

export interface IToast {
  toastType: string;
  message: string;
  icon?: string;
  index?: number;
  title: string;
  actionList?: Array<IToastAction>;
}
