import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { IAppState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import {
  formatQuery,
  handleDebounce,
  handleInputChange,
  routes,
  swapRouteParams,
  toastError,
} from '../../helpers';
import { errorHelpers } from '../../helpers/errorHelpers';
import { IOptions } from '../Dropdown/Dropdown';
import TextField from '../../components/TextField/TextField';
import { Constants } from '@gigit/constants';
import { IOwnerObject, IToast } from '../../interfaces';
import { createToast } from '../../actions/toaster';
import { IGroupState } from '../../reducers/group';
import { IEventState } from '../../reducers/event';
import Portrait from '../Portrait/Portrait';
import Loader from '../Loader/Loader';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import ContextMenu from '../ContextMenu/ContextMenu';
import './HourManagement.scss';
import { IGig, IGigFE, ILoggedVolunteerHoursSummary, IShiftUserSummary } from '@gigit/interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import { IGigState } from '../../reducers/gig';
import ReportHoursModal from '../ReportHoursModal/ReportHoursModal';
import { userRequestActions } from '../../requestActions';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  eventState: IEventState;
  gigState: IGigState;
  locale: string;
  owner: IOwnerObject;
  createToast(toast: IToast): void;
}

interface IState {
  searchValue: string;
  gigId: string;
  eventId: string;
  selectedVolunteerHourItems: { gigId: string; id: string }[];
  selectedVolunteerHourGigs: string[];
  editRow: number;
  editHourValue: number;
  gigsListDropdown: IOptions[];
  eventListDropdown: IOptions[];
  volunteerHourItems: ILoggedVolunteerHoursSummary[];
  dataLoading: boolean;
  showReportHoursModal: boolean;
  gigs: IGigFE[];
}

class HourManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: '',
      gigId: '',
      eventId: '',
      selectedVolunteerHourItems: [],
      selectedVolunteerHourGigs: [],
      volunteerHourItems: [],
      editRow: -1,
      editHourValue: -1,
      gigsListDropdown: [],
      eventListDropdown: [{ label: 'No event', value: '' }],
      dataLoading: false,
      showReportHoursModal: false,
      gigs: [],
    };

    this.toggleAll = this.toggleAll.bind(this);
    this.getShiftId = this.getShiftId.bind(this);
    // this.modifyRow = this.modifyRow.bind(this);
    this.saveNewHourValue = this.saveNewHourValue.bind(this);
    this.bulkVerifyHours = this.bulkVerifyHours.bind(this);
    this.handleSelectedGig = this.handleSelectedGig.bind(this);
    this.handleSelectedEvent = this.handleSelectedEvent.bind(this);
    this.getShiftUsers = this.getShiftUsers.bind(this);
    this.getGigs = this.getGigs.bind(this);
  }

  componentDidMount() {
    this.getGigs();
  }

  removeDuplicates(arr: IGigFE[]): { label: string; value: string }[] {
    let unique = [] as { label: string; value: string }[];

    arr.forEach((element) => {
      if (!unique.find((u) => u.value === element.event_id)) {
        unique.push({ label: element?.event?.title || '', value: element?.event_id || '' });
      }
    });

    return unique;
  }

  afterGetGigs(data: IGigFE[]): void {
    const gigs: IGigFE[] = data;
    const eventGigsListTmp = this.removeDuplicates(
      gigs.filter((g, index) => g?.event && g?.event_id),
    );

    this.setState(
      {
        gigId: gigs[0]?.id || '',
        eventId: gigs[0]?.event_id || '',
        gigs,
        gigsListDropdown: [
          ...this.state.gigsListDropdown,
          ...gigs
            .filter((g) => g.event_id === gigs[0]?.event_id)
            .map((g) => {
              return { label: g.title, value: g.id || '' };
            }),
        ],
        eventListDropdown: [...this.state.eventListDropdown, ...eventGigsListTmp],
      },
      async () => {
        await this.getVolunteerHourItems();
      },
    );
  }

  getGroupGigs(): void {
    axios
      .get<IGigFE[]>(
        swapRouteParams(routes.GET_GROUP_GIGS, { groupId: this.props.groupState.group.id }),
      )
      .then((response) => {
        this.afterGetGigs(response.data);
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(
          errorObject.translatedMessage,
          'Get cause volunteer opportunities',
        );
        this.props.createToast(toast);
      });
  }

  getEventGigs(): void {
    axios
      .get<IGigFE[]>(
        swapRouteParams(routes.GET_EVENT_GIGS, { eventId: this.props.eventState.event.id }),
      )
      .then((response) => {
        this.afterGetGigs(response.data);
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(
          errorObject.translatedMessage,
          'Get event volunteer opportunities',
        );
        this.props.createToast(toast);
      });
  }

  getGigs(): void {
    if (this.props.owner.ownerType === Constants.object_type.group) this.getGroupGigs();
    else this.getEventGigs();
  }

  makeRouteForGetShiftUsers(route: string): string {
    let searchParams = new URLSearchParams();
    let tmpRoute = route;

    if (this.state.gigId) searchParams.append('gig', this.state.gigId);

    if (this.state.eventId) searchParams.append('event', this.state.eventId);

    if (this.state.searchValue) searchParams.append('search', this.state.searchValue);

    if (searchParams.toString().length) tmpRoute += '?' + searchParams.toString();

    return tmpRoute;
  }

  getGroupShiftUsers(): void {
    let route = this.makeRouteForGetShiftUsers(routes.GET_GROUP_SHIFT_USERS);
    this.setState({ dataLoading: true });

    axios
      .get(swapRouteParams(route, { groupId: this.props.groupState.group.id }))
      .then((response) => {
        const shUsers: IShiftUserSummary[] = response.data;
        // this.setState({shiftUsers: shUsers})
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Get Cause Volunteers');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  getEventShiftUsers(): void {
    let route = this.makeRouteForGetShiftUsers(routes.GET_EVENT_SHIFT_USERS);
    this.setState({ dataLoading: true });

    axios
      .get(swapRouteParams(route, { eventId: this.props.eventState.event.id }))
      .then((response) => {
        const shUsers: IShiftUserSummary[] = response.data;
        // this.setState({shiftUsers: shUsers})
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Get Event Volunteers');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  async getVolunteerHourItems() {
    try {
      if (this.state.gigId !== '') {
        const result = await userRequestActions.getUserVolunteeringHoursForGig(
          this.state.gigId,
          formatQuery({ search: this.state.searchValue }),
        );
        this.setState({ volunteerHourItems: result });
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Get User Volunteer Hours Items For Gig');
      this.props.createToast(toast);
    }
  }

  getShiftUsers(): void {
    if (this.props.owner.ownerType === Constants.object_type.group) this.getGroupShiftUsers();
    else this.getEventShiftUsers();
  }

  toggleAll() {
    if (this.state.selectedVolunteerHourItems.length === this.state.volunteerHourItems.length) {
      this.setState({
        selectedVolunteerHourItems: [],
        selectedVolunteerHourGigs: [],
      });
    } else {
      let _selected = [];
      let _selectedGigIds = [] as string[];

      for (let item of this.state.volunteerHourItems) {
        const gigId = this.state.gigs.find((g) => g.handle === item.gig?.handle)?.id;

        if (gigId && item.status && item.status.code === 'pending') {
          _selected.push({ id: item.id, gigId });

          if (!_selectedGigIds.includes(gigId)) {
            _selectedGigIds.push(gigId);
          }
        }
      }

      this.setState({
        selectedVolunteerHourItems: _selected,
        selectedVolunteerHourGigs: _selectedGigIds,
      });
    }
  }

  toggleSelectionVolunteerItem(gigId: string, id: string) {
    let _selected = [...this.state.selectedVolunteerHourItems];

    if (_selected.find((s) => s.id === id)) {
      _selected = this.state.selectedVolunteerHourItems.filter((e) => e.id !== id);
    } else {
      _selected.push({ id, gigId });
    }

    this.setState({
      selectedVolunteerHourItems: _selected,
    });
  }

  toggleSelectionGigId(id: string) {
    let _selected = [...this.state.selectedVolunteerHourGigs];

    if (_selected.includes(id)) {
      _selected = this.state.selectedVolunteerHourGigs.filter((e) => e !== id);
    } else {
      _selected.push(id);
    }

    this.setState({
      selectedVolunteerHourGigs: _selected,
    });
  }

  getShiftId(item: IShiftUserSummary) {
    return item.gig_id + '::' + item.shift_id + '::' + item.id + '::' + item.group_handle;
  }

  // modifyRow(index: number) {
  //     this.setState({
  //         editRow: index,
  //         editHourValue: this.state.shiftUsers[index].hours || 0
  //     });
  // }

  saveNewHourValue(param: { hours: string; shift: IShiftUserSummary }) {
    axios
      .post(
        swapRouteParams(routes.UPDATE_SHIFT_USER_HOURS, {
          gigId: param.shift.gig_id,
          shift_id: param.shift.shift_id,
          user_id: param.shift.id,
        }),
        { value: Number(param.hours) },
      )
      .then(() => {
        this.getShiftUsers();
        this.setState({
          editRow: -1,
          editHourValue: -1,
        });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Update hours');
        this.props.createToast(toast);
      });
  }

  async bulkVerifyHours(gigId: string, ids: string[]) {
    try {
      await userRequestActions.verifyVolunteerHours(gigId, ids);
      await this.getVolunteerHourItems();
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Bulk Verify hours');
      this.props.createToast(toast);
    }
  }

  async verifyHours(gigId: string, id: string) {
    try {
      await userRequestActions.verifyVolunteerHours(gigId, [id]);
      await this.getVolunteerHourItems();
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Verify hours');
      this.props.createToast(toast);
    }
  }

  async prepareBulkVerifyHours() {
    for (let gigId of this.state.selectedVolunteerHourGigs) {
      await this.bulkVerifyHours(
        gigId,
        this.state.selectedVolunteerHourItems.filter((s) => s.gigId === gigId).map((s) => s.id),
      );
    }

    this.setState({
      selectedVolunteerHourItems: [],
      selectedVolunteerHourGigs: [],
    });
  }

  handleSelectedGig(gigId: string) {
    this.setState(
      {
        gigId: gigId,
      },
      async () => {
        await this.getVolunteerHourItems();
      },
    );
  }

  handleSelectedEvent(eventId: string) {
    const tmpGig = this.state.gigs.find((g) => g?.event && g?.event_id === eventId);

    if (tmpGig?.event_id && tmpGig?.id) {
      this.setState(
        {
          gigId: tmpGig.id,
          eventId: tmpGig.event_id,
          gigsListDropdown: [
            ...this.state.gigs
              .filter((g) => g.event_id === tmpGig.event_id)
              .map((g) => {
                return { label: g.title, value: g.id || '' };
              }),
          ],
        },
        async () => {
          await this.getVolunteerHourItems();
        },
      );
    }
  }

  render() {
    return (
      <div className="HourManagement section-wrap">
        <div className="section-title">
          <div className="section-inner-title">Volunteer Hours</div>
          <Button
            onClick={() => {
              this.setState({ showReportHoursModal: true });
            }}
            className="report-hours-button"
            text="Report Hours"
          />
        </div>
        <div className="section-inner">
          <div className="HourManagement-list">
            <div className="search">
              {this.state.gigsListDropdown.length > 0 && (
                <Dropdown
                  label="Volunteer Opportunity"
                  name="gig"
                  shouldSort={true}
                  value={this.state.gigId}
                  options={this.state.gigsListDropdown}
                  onChange={(e) => {
                    handleInputChange(
                      e,
                      this,
                      false,
                      (gigId: string) => this.handleSelectedGig(gigId),
                      true,
                    );
                  }}
                  notranslate="yes"
                />
              )}

              {this.state.gigsListDropdown.length < 1 && (
                <span className="no-opportunities">No Volunteer Opportunities</span>
              )}

              {this.props.owner.ownerType === Constants.object_type.group && (
                <Dropdown
                  label="Event"
                  name="event"
                  className="ml-10"
                  shouldSort={true}
                  value={this.state.eventId}
                  options={this.state.eventListDropdown}
                  onChange={(e) => {
                    handleInputChange(
                      e,
                      this,
                      false,
                      (eventId: string) => this.handleSelectedEvent(eventId),
                      true,
                    );
                  }}
                  notranslate="yes"
                />
              )}
              <TextField
                autoComplete="off"
                icon="fas fa-search"
                placeholder="Search volunteers..."
                value={this.state.searchValue}
                type="text"
                name="searchValue"
                onChange={(e) => {
                  handleInputChange(e, this, false, () => {
                    handleDebounce(this.state.searchValue).then((res) => {
                      this.getVolunteerHourItems();
                    });
                  });
                }}
              />
              {this.state.selectedVolunteerHourItems.length > 0 && (
                <div className="approve-selected">
                  <Button
                    onClick={async () => {
                      await this.prepareBulkVerifyHours();
                    }}
                    icon="far fa-check-square"
                    text="Approve Selected"
                  />
                </div>
              )}
            </div>
            <div className="list">
              <div className="headers">
                <div className="col select">
                  <i
                    onClick={() => {
                      this.toggleAll();
                    }}
                    className={
                      this.state.selectedVolunteerHourItems.length ===
                        this.state.volunteerHourItems.length &&
                      this.state.volunteerHourItems.length > 0
                        ? 'fas fa-check-square'
                        : 'fas fa-square'
                    }
                  />
                </div>
                <div className="col name">Name</div>
                <div className="col title">Opportunity</div>
                <div className="col event">Event</div>
                <div className="col date">Date</div>
                <div className="col time">Time</div>
                <div className="col hours">Hours</div>
                <div className="col verify-hours">Verify Hours</div>
                <div className="col actions" />
              </div>
              <div className="list-inner">
                <div className="list-rows">
                  {this.state.volunteerHourItems.length > 0 &&
                    this.state.volunteerHourItems.map((item, index: number) => {
                      let _menuItems = [
                        {
                          icon: 'fas fa-envelope',
                          link: 'mailto:' + item?.user?.email || '',
                          label: 'Email',
                        },
                      ];

                      if (item.user) {
                        return (
                          <div
                            key={index}
                            className="row"
                          >
                            <div className="col select">
                              <i
                                onClick={() => {
                                  const gigId = this.state.gigs.find(
                                    (g) => g.handle === item.gig?.handle,
                                  )?.id;
                                  if (gigId && item.status && item.status.code === 'pending') {
                                    this.toggleSelectionVolunteerItem(gigId, item.id);
                                    this.toggleSelectionGigId(gigId);
                                  }
                                }}
                                className={`
                                                                   ${item?.status?.code === 'approved' ? 'no-drop-cursor' : ''}
                                                                   ${
                                                                     this.state.selectedVolunteerHourItems.find(
                                                                       (s) => s.id === item.id,
                                                                     )
                                                                       ? 'fas fa-check-square'
                                                                       : 'fas fa-square'
                                                                   }
                                                               `}
                              />
                            </div>
                            <div
                              className="col name"
                              notranslate="yes"
                            >
                              <Portrait
                                currentImage={item?.user?.profile_image_url}
                                size={30}
                              />
                              {item.user.display_name}
                            </div>
                            <div
                              className="col title"
                              notranslate="yes"
                            >
                              {item.gig?.title}
                            </div>
                            <div
                              className="col event"
                              notranslate="yes"
                            >
                              {item?.event?.handle || 'N/A'}
                            </div>
                            <div
                              className="col date"
                              notranslate="yes"
                            >
                              {localizeHelpers.formatDate(
                                item.start_date ?? new Date(),
                                LocaleDateFormats.LL,
                                this.props.locale,
                              )}
                            </div>
                            <div
                              className="col time"
                              notranslate="yes"
                            >
                              {localizeHelpers.formatDate(
                                item.start_date ?? new Date(),
                                LocaleDateFormats.LT,
                                this.props.locale,
                              )}
                              {' - '}
                              {localizeHelpers.formatDate(
                                item.end_date ?? new Date(),
                                LocaleDateFormats.LT,
                                this.props.locale,
                              )}
                            </div>
                            {/*<QuickEditField*/}
                            {/*    value={item?.hours?.toString() || '0'}*/}
                            {/*    type="number"*/}
                            {/*    name="editHourValue"*/}
                            {/*    className='hours-container'*/}
                            {/*    onSave={(hours)=>{this.saveNewHourValue({hours, shift: item} )}}*/}
                            {/*/>*/}
                            <div
                              className="col hours"
                              notranslate="yes"
                            >
                              {item.hours} h
                            </div>
                            <div className="col verify-hours">
                              <i
                                onClick={async () => {
                                  const gigId = this.state.gigs.find(
                                    (g) => g.handle === item.gig?.handle,
                                  )?.id;
                                  if (gigId && item.status && item.status.code === 'pending') {
                                    await this.verifyHours(gigId, item.id);
                                  }
                                }}
                                className={
                                  item.status && item.status.code === 'pending'
                                    ? 'fad fa-toggle-off'
                                    : 'fad fa-toggle-on'
                                }
                              />
                            </div>
                            <div className="col actions">
                              <i className="fal fa-ellipsis-h-alt" />
                              <ContextMenu
                                onMouseLeave={() => {}}
                                showMenu={true}
                                menuItems={_menuItems}
                              />
                            </div>
                          </div>
                        );
                      }
                    })}
                  {this.state.volunteerHourItems.length === 0 && (
                    <div className="empty">You don't have any logger hours.</div>
                  )}
                  {this.state.dataLoading && <Loader loading={true} />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReportHoursModal
          byAdmin={true}
          objectType={this.props.owner.ownerType}
          gigs={this.state.gigs}
          show={this.state.showReportHoursModal}
          onClose={() => this.setState({ showReportHoursModal: false })}
          onSubmit={() => this.getVolunteerHourItems()}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    gigState: store.gigState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(HourManagement);
