import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { IAppState } from '../../store';
import { IUserState } from '../../reducers/user';

import logoKdark from '../../assets/logo-K-dark.svg';

import Construction from '../../components/Construction/Construction';
import Portrait from '../../components/Portrait/Portrait';
import Hubs from '../../components/SuperAdminTabs/Hubs';
import Skills from '../../components/SuperAdminTabs/Skills';
import Causes from '../../components/SuperAdminTabs/Causes';
import PendingCharityGroups from '../../components/SuperAdminTabs/PendingCharityGroups';
import './Admin.scss';
import { setSEOMetatags } from '../../helpers';
import { localizeHelpers } from '../../localizeHelpers';
import { InfoAdminTab } from '../../components/SuperAdminTabs/InfoAdminTab';
import RecommendedCauses from '../../components/SuperAdminTabs/RecommendedCauses';

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
}

interface IState {
  activeTab: string;
}

/** This is only for "Super Admins". This page can be visited by Super Admins to manage various parts of our App. */
class Admin extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: 'info',
    };
  }

  componentDidUpdate() {
    if (this.props.userState.user.is_super_admin && this.props.userState.isLoggedIn) {
      setSEOMetatags({
        title: localizeHelpers.translate('Admin | Kambeo'),
        urlPath: 'admin',
      });
    }
  }

  isTabActive(tabName: string): string {
    return this.state.activeTab === tabName ? 'active' : '';
  }

  render() {
    if (this.props.userState.isLoggedIn && this.props.userState.user.is_super_admin) {
      return (
        <div className="Admin">
          <div className="admin-panel">
            <Portrait
              source={logoKdark}
              size={120}
            />
            <div className="sub-header">Cause Admin</div>
            <div
              className="user-name"
              notranslate="yes"
            >
              {this.props.userState.user.display_name}
            </div>
            <div className="user-role">ROLE: superadmin</div>
            <div className="tabs">
              <ul>
                <li className="tab-title">
                  <i className="fas fa-users"></i>
                  <span>Manage</span>
                </li>
                <li
                  className={this.isTabActive('info')}
                  onClick={() => this.setState({ activeTab: 'info' })}
                >
                  info
                </li>
                <li
                  className={this.isTabActive('hubs')}
                  onClick={() => this.setState({ activeTab: 'hubs' })}
                >
                  companies
                </li>
                <li
                  className={this.isTabActive('skills')}
                  onClick={() => this.setState({ activeTab: 'skills' })}
                >
                  skills
                </li>
                <li
                  className={this.isTabActive('causes')}
                  onClick={() => this.setState({ activeTab: 'causes' })}
                >
                  focus areas
                </li>
                <li
                  className={this.isTabActive('charities')}
                  onClick={() => this.setState({ activeTab: 'charities' })}
                >
                  Pending Charity Causes
                </li>
                <li
                  className={this.isTabActive('recommended')}
                  onClick={() => this.setState({ activeTab: 'recommended' })}
                >
                  Recommended Causes
                </li>
              </ul>
            </div>
          </div>

          <div
            className={
              'section ' +
              this.state.activeTab.charAt(0).toUpperCase() +
              this.state.activeTab.slice(1)
            }
          >
            {this.state.activeTab === 'info' && <InfoAdminTab />}
            {this.state.activeTab === 'hubs' && <Hubs />}
            {this.state.activeTab === 'skills' && <Skills />}
            {this.state.activeTab === 'causes' && <Causes />}
            {this.state.activeTab === 'charities' && <PendingCharityGroups />}
            {this.state.activeTab === 'recommended' && <RecommendedCauses />}
          </div>

          <Construction {...this.props} />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

export default connect(mapStateToProps)(Admin);
