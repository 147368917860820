import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { IUserRole, IContactMoment } from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { IGroupState } from '../../../reducers/group';
import { IAppState } from '../../../store';
import { createToast } from '../../../actions/toaster';
import { routes, swapRouteParams, handleInputChange, toastError } from '../../../helpers';

import { Constants } from '@gigit/constants';

import Dropdown, { IOptions } from '../../Dropdown/Dropdown';
import Button from '../../Button/Button';
import TextArea from '../../TextArea/TextArea';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';
import { userSelectors } from '../../../selectors/user';
import DateTimePicker from '../../shared/DateTimePicker/DateTimePicker';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  moment: IContactMoment | null;
  locale: string;
  onClose(): void;
  createToast(toast: IToast): void;
}

interface IState {
  lifeMoment: string;
  options: Array<IOptions>;
  date: any;
  notes: string;
}

class AddMoment extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      lifeMoment: Constants.moment_type.new_child,
      options: [
        { label: 'Newborn', value: Constants.moment_type.new_child },
        { label: 'Graduation', value: Constants.moment_type.graduation },
        { label: 'Married', value: Constants.moment_type.wedding },
        { label: 'Promotion', value: Constants.moment_type.promotion },
        { label: 'Recent Passing', value: Constants.moment_type.family_passing },
        { label: 'Other', value: Constants.moment_type.other },
      ],
      date: moment(),
      notes: '',
    };

    this.handleDate = this.handleDate.bind(this);
  }

  componentDidMount() {
    if (this.props.moment) {
      this.setState({
        notes: this.props.moment?.comment || '',
        lifeMoment: this.props.moment?.type,
        date: this.props.moment?.start_date,
      });
    }
  }

  handleMoment() {
    if (this.props.moment?.id) {
      this.editMoment(this.props.moment.id);
    } else {
      this.createMoment();
    }
  }

  createMoment() {
    let payload = {
      type: this.state.lifeMoment,
      start_date: this.state.date,
      comment: this.state.notes,
    };

    axios
      .post(
        swapRouteParams(routes.CREATE_MOMENT, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
        }),
        payload,
      )
      .then((response) => {
        this.resetMoment();
        this.props.onClose();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Create Moment');
        this.props.createToast(toast);
      });
  }

  editMoment(id: string) {
    let payload = {
      type: this.state.lifeMoment,
      start_date: this.state.date,
      comment: this.state.notes,
    };

    axios
      .put(
        swapRouteParams(routes.UPDATE_MOMENT, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
          momentId: id,
        }),
        payload,
      )
      .then((response) => {
        this.resetMoment();
        this.props.onClose();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Update Moment');
        this.props.createToast(toast);
      });
  }

  deleteMoment(id: string) {
    axios
      .delete(
        swapRouteParams(routes.UPDATE_MOMENT, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
          momentId: id,
        }),
      )
      .then((response) => {
        this.resetMoment();
        this.props.onClose();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Delete Moment');
        this.props.createToast(toast);
      });
  }

  resetMoment() {
    this.setState({
      notes: '',
      lifeMoment: '',
      date: moment(),
    });
  }

  isValidDate() {
    let yesterday = moment().subtract(1, 'day');
    let isValid = (current: any) => {
      return current.isAfter(yesterday);
    };

    return isValid;
  }

  handleDate(value: any) {
    this.setState({
      date: value,
    });
  }

  render() {
    return (
      <div className="AddSocial">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleMoment();
          }}
          className="af"
        >
          <div className="af-title">Add Moment</div>
          <div className="af-subtitle">Moment Information</div>
          <label>Start Date & Time</label>
          <DateTimePicker
            inputProps={{ placeholder: 'Moment Date', readOnly: true }}
            className="custom-dtp"
            value={this.state.date}
            onChange={this.handleDate}
            locale={this.props.locale}
          />
          <Dropdown
            label="Life Moment"
            name="lifeMoment"
            shouldSort={true}
            value={this.state.lifeMoment}
            options={this.state.options}
            onChange={(e) => {
              handleInputChange(e, this, false);
            }}
          />
          <label className="textarea-label">Notes</label>
          <TextArea
            type="text"
            value={this.state.notes}
            name="notes"
            onChange={(e: any) => {
              handleInputChange(e, this, false);
            }}
          />
          <div className="af-actions">
            <Button text="Save" />
            <Button
              text="Cancel"
              onClick={(e) => {
                e.preventDefault();
                this.props.onClose();
              }}
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(AddMoment),
);
