import React, { ChangeEvent } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { IAppState } from '../../../store';
import { RouteComponentProps } from 'react-router-dom';
import {
  routes,
  swapRouteParams,
  handleInputChange,
  toastError,
  formatCurrency,
  defaultCurrency,
  capitalizeString,
} from '../../../helpers';
import {
  IUserRole,
  ICommunicationRecordCreateInfo,
  ICommunicationRecord,
  ICustomField,
  CustomFieldValueType,
} from '@gigit/interfaces';
import { IGroupState } from '../../../reducers/group';
import { createToast } from '../../../actions/toaster';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { Prompt } from '../../Prompt/Prompt';
import TextArea from '../../TextArea/TextArea';
import TextField from '../../TextField/TextField';
import ContactCard from '../ContactCard/ContactCard';
import ActivityLog from '../ActivityLog/ActivityLog';
import DatePicker from '../../DatePicker/DatePicker';
import Dropdown, { IOptions } from '../../Dropdown/Dropdown';
import AddCustomField from './AddCustomField/AddCustomField';
import ContactPersonalInfo from './ContactPersonalInfo/ContactPersonalInfo';
import SortableTable, {
  ISortableTableColumn,
  ISortableTableActions,
} from '../../SortableTable/SortableTable';
import {
  SearchableDropdown,
  ISearchableDropdownListItem,
} from '../../SearchableDropdown/SearchableDropdown';
import './Contact.scss';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { userSelectors } from '../../../selectors/user';
import DateTimePicker from '../../shared/DateTimePicker/DateTimePicker';
import { Constants } from '@gigit/constants';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  users: Array<IUserRole>;
  locale: string;
  createToast(toast: IToast): void;
}

interface ICustomFieldWithValue extends ICustomField {
  value: CustomFieldValueType;
}

interface IState {
  tabs: string[];
  activeTab: number;
  customFieldOptions: { value: string; icon: string }[];
  communicationTypeOptions: Array<IOptions>;
  showAddFieldModal: boolean;
  showCommModal: boolean;
  fieldType: string;
  fieldName: string;
  date: any;
  medium: string;
  notes: string;
  toUser: ISearchableDropdownListItem | null;
  userList: Array<ISearchableDropdownListItem>;
  communicationRecords: Array<ICommunicationRecord>;
  communicationColumns: ISortableTableColumn[];
  editId: string;
  showSocialsModal: boolean;
  showPersonalModal: boolean;
  commLogToDelete: string;
  isCommDataLoading: boolean;
  showCfModal: boolean;
  customFields: ICustomFieldWithValue[];
  customFieldsLoading: boolean;
  editCustomField: number;
  editCfValue: string;
  editCfDate: any;
  editCfLabels: { value: string; label: string }[];
  confirmDelete: number;
}

class Contact extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tabs: ['Communication', 'Notes', 'Personal', 'Custom Fields'],
      activeTab: 0,
      customFieldOptions: [
        { value: 'Text', icon: 'fa fa-font-case' },
        { value: 'Checkbox', icon: 'fa fa-check-square' },
        { value: 'Dropdown', icon: 'fa fa-caret-down' },
        { value: 'Number', icon: 'fa fa-hashtag' },
        { value: 'Currency', icon: 'fa fa-dollar' },
        { value: 'Date', icon: 'fa fa-calendar' },
        { value: 'Labels', icon: 'fa fa-tags' },
        { value: 'Boolean Flag', icon: 'fa fa-flag' },
      ],
      communicationTypeOptions: [
        { label: 'Text', value: Constants.communication_medium.text },
        { label: 'Call', value: Constants.communication_medium.call },
        { label: 'Meeting', value: Constants.communication_medium.meeting },
        { label: 'Email', value: Constants.communication_medium.email },
      ],
      communicationColumns: [
        { id: 'date', label: 'Date', sortable: false },
        { id: 'time', label: 'Time', sortable: false },
        { id: 'group-contact', label: 'Group Contact', sortable: false },
        { id: 'medium', label: 'Medium', sortable: false },
        { id: 'notes', label: 'Notes', sortable: false },
        { id: 'actions', label: 'Actions', sortable: false },
      ],
      showAddFieldModal: false,
      showCommModal: false,
      fieldType: 'Text',
      fieldName: '',
      date: moment(),
      medium: 'text',
      notes: '',
      toUser: { label: '', id: '' },
      userList: this.convertUserList(),
      communicationRecords: [],
      editId: '',
      showPersonalModal: false,
      showSocialsModal: false,
      commLogToDelete: '',
      isCommDataLoading: false,
      showCfModal: false,
      customFields: [],
      customFieldsLoading: false,
      editCustomField: -1,
      editCfValue: '',
      editCfDate: moment(),
      editCfLabels: [],
      confirmDelete: -1,
    };

    this.handleDate = this.handleDate.bind(this);
    this.editCommLog = this.editCommLog.bind(this);
    this.deleteCommLog = this.deleteCommLog.bind(this);
    this.toggleSocialModal = this.toggleSocialModal.bind(this);
    this.getCustomFields = this.getCustomFields.bind(this);
    this.getCustomFieldIcon = this.getCustomFieldIcon.bind(this);
    this.saveUserCustomField = this.saveUserCustomField.bind(this);
    this.deleteUserCustomField = this.deleteUserCustomField.bind(this);
    this.deleteCustomField = this.deleteCustomField.bind(this);
    this.removeCheckboxValue = this.removeCheckboxValue.bind(this);
    this.addCheckboxValue = this.addCheckboxValue.bind(this);
  }

  componentDidMount() {
    this.getCommunicationRecords();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.state.activeTab !== prevState.activeTab) {
      if (this.state.activeTab === 3) {
        this.getCustomFields();
      }
    }

    if (prevProps.users !== this.props.users) {
      this.setState({
        userList: this.convertUserList(),
      });
    }
  }

  convertUserList(): Array<ISearchableDropdownListItem> {
    return this.props.users.map((c: IUserRole) => {
      let option: ISearchableDropdownListItem = {
        label: `${c?.user?.first_name} ${c?.user?.last_name}`,
        id: c?.user?.id || '',
        image: c?.user?.profile_image_url,
      };
      return option;
    });
  }

  editCommLog(commId: string) {
    let cr: ICommunicationRecord | undefined = this.state.communicationRecords.find(
      (r: ICommunicationRecord) => {
        return r.id === commId;
      },
    );

    if (cr) {
      this.setState(
        {
          editId: cr.id || '',
          date: cr.start_date,
          medium: cr.medium,
          notes: cr.notes || '',
          toUser: {
            label: `${cr.communicator?.first_name} ${cr.communicator?.last_name}`,
            id: cr.communicator?.id || '',
          },
        },
        () => {
          this.setState({
            showCommModal: true,
          });
        },
      );
    }
  }

  resetEdit() {
    this.setState({
      editId: '',
      date: moment(),
      medium: '',
      notes: '',
      toUser: { label: '', id: '' },
    });
  }

  deleteCommLog(commId: string) {
    axios
      .delete(
        swapRouteParams(routes.DELETE_COMMUNICATION_RECORD, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
          recordId: commId,
        }),
      )
      .then(() => {
        this.getCommunicationRecords();
        this.setState({
          showCommModal: false,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Delete Communication');
        this.props.createToast(toast);
      });
  }

  createCommunication() {
    let payload: ICommunicationRecordCreateInfo = {
      communicator_user_id: this.state.toUser?.id,
      start_date: this.state.date,
      medium: this.state.medium,
      notes: this.state.notes,
    };

    axios
      .post(
        swapRouteParams(routes.CREATE_COMMUNICATION_RECORD, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
        }),
        payload,
      )
      .then(() => {
        this.getCommunicationRecords();
        this.setState(
          {
            showCommModal: false,
          },
          () => {
            this.setState({
              toUser: { label: '', id: '' },
              date: moment(),
              medium: '',
              notes: '',
            });
          },
        );
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Create Communication');
        this.props.createToast(toast);
      });
  }

  editCommunication() {
    let payload: ICommunicationRecordCreateInfo = {
      communicator_user_id: this.state.toUser?.id,
      start_date: this.state.date,
      medium: this.state.medium,
      notes: this.state.notes,
    };

    axios
      .put(
        swapRouteParams(routes.DELETE_COMMUNICATION_RECORD, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
          recordId: this.state.editId,
        }),
        payload,
      )
      .then(() => {
        this.getCommunicationRecords();
        this.setState({
          showCommModal: false,
        });

        this.resetEdit();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Create Pledge');
        this.props.createToast(toast);
      });
  }

  getCommunicationRecords() {
    this.setState({
      isCommDataLoading: true,
    });

    axios
      .get(
        swapRouteParams(routes.CREATE_COMMUNICATION_RECORD, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
        }),
      )
      .then((response) => {
        this.setState({
          communicationRecords: response.data,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Communication Records');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({
          isCommDataLoading: false,
        });
      });
  }

  handleDate(value: any) {
    this.setState({
      date: value,
    });
  }

  getCommTableData() {
    return this.state.communicationRecords.map((cr: ICommunicationRecord) => {
      let time = localizeHelpers.formatDate(cr.start_date, LocaleDateFormats.LT, this.props.locale);

      return {
        row: [
          {
            content: `${localizeHelpers.formatDate(cr.start_date, LocaleDateFormats.ll, this.props.locale)}`,
            id: 'Date',
            notranslate: 'yes',
          },
          { content: `${time}`, id: 'Time', notranslate: 'yes' },
          {
            content: `${cr.communicator?.first_name} ${cr.communicator?.last_name}`,
            id: 'group-contact',
            notranslate: 'yes',
          },
          { content: capitalizeString(cr.medium), id: 'Medium' },
          { content: cr.notes, id: 'Notes', notranslate: 'yes' },
          {
            id: 'actions',
            menu: [
              {
                icon: 'far fa-pencil-alt',
                onClick: () => {
                  if (cr.id) {
                    this.editCommLog(cr.id);
                  }
                },
                label: 'Edit',
              },
              {
                icon: 'far fa-trash-alt',
                onClick: () => {
                  if (cr.id) {
                    this.setState({ commLogToDelete: cr.id });
                  }
                },
                label: 'Delete',
              },
            ],
          },
        ],
      };
    });
  }

  handleCommunication() {
    if (this.state.editId) {
      this.editCommunication();
    } else {
      this.createCommunication();
    }
  }

  toggleSocialModal(type: string) {
    if (type === 'social') {
      this.setState({
        showSocialsModal: !this.state.showSocialsModal,
      });
    } else {
      this.setState({
        showPersonalModal: !this.state.showPersonalModal,
      });
    }
  }

  onPersonalClose() {
    if (this.state.showSocialsModal) {
      this.setState({
        showSocialsModal: false,
      });
    } else {
      this.setState({
        showPersonalModal: false,
      });
    }
  }

  getCustomFields() {
    this.setState(
      {
        customFieldsLoading: true,
      },
      () => {
        axios
          .get(
            swapRouteParams(routes.GET_GROUP_CUSTOM_FIELDS, {
              groupId: this.props.contact.object_id,
            }),
          )
          .then((response) => {
            let _customFields: ICustomFieldWithValue[] = response.data;

            axios
              .get(
                swapRouteParams(routes.GET_USER_CUSTOM_FIELDS, {
                  groupId: this.props.contact.object_id,
                  userId: this.props.contact.user_id,
                }),
              )
              .then((response) => {
                let _cfs: ICustomFieldWithValue[] = [..._customFields];

                for (let c in _cfs) {
                  for (let r in response.data.custom_fields) {
                    if (_cfs[c].name === r) {
                      _cfs[c].value = response.data.custom_fields[r];
                    }
                  }

                  if (_cfs[c].value === undefined) {
                    delete _cfs[c];
                  }
                }

                let _filtered: ICustomFieldWithValue[] = _cfs.filter((c) => {
                  return c != null;
                });

                this.setState({
                  customFields: _filtered,
                });
              })
              .finally(() => {
                this.setState({
                  customFieldsLoading: false,
                });
              });
          });
      },
    );
  }

  getCustomFieldIcon(type: string) {
    switch (type) {
      case 'text':
        return 'fa fa-font-case';
      case 'checkbox':
        return 'fa fa-check-square';
      case 'dropdown':
        return 'fa fa-caret-down';
      case 'number':
        return 'fa fa-hashtag';
      case 'money':
        return 'fa fa-dollar';
      case 'date':
        return 'fa fa-calendar';
      case 'labels':
        return 'fa fa-tags';
      case 'flag':
        return 'fa fa-flag';
        break;
    }

    return 'fa fa-user';
  }

  saveUserCustomField() {
    let _name: string = this.state.customFields[this.state.editCustomField].name;
    let _type: string = this.state.customFields[this.state.editCustomField].type;

    axios
      .put(
        swapRouteParams(routes.SET_USER_CUSTOM_FIELDS, {
          groupId: this.props.contact.object_id,
          userId: this.props.contact.user_id,
        }),
        { [_name]: this.formatCfValue(_type) },
      )
      .then((response) => {
        //
      })
      .finally(() => {
        this.setState(
          {
            editCustomField: -1,
          },
          () => {
            this.getCustomFields();
          },
        );
      });
  }

  formatCfValue(type: string) {
    switch (type) {
      case 'money':
      case 'number':
        return parseFloat(this.state.editCfValue);
      case 'flag':
        return this.state.editCfValue === 'true';
      case 'date':
        return moment(this.state.editCfDate);
      default:
        return this.state.editCfValue;
    }
  }

  deleteUserCustomField(payload: any) {
    axios
      .put(
        swapRouteParams(routes.DELETE_USER_CUSTOM_FIELD, {
          groupId: this.props.contact.object_id,
          userId: this.props.contact.user_id,
        }),
        payload,
      )
      .then((response) => {
        this.getCustomFields();
      })
      .finally(() => {
        this.setState({ confirmDelete: -1 });
      });
  }

  deleteCustomField(id: string) {
    axios
      .delete(
        swapRouteParams(routes.DELETE_CUSTOM_FIELD, {
          groupId: this.props.contact.object_id,
          cfId: id,
        }),
      )
      .then((response) => {
        this.getCustomFields();
      });
  }

  getCustomFieldValue(item: ICustomFieldWithValue) {
    let _value: string = '(none)';
    let currency = this.props.groupState.group.account?.currency || defaultCurrency;

    if (item.value !== undefined) {
      switch (item.type) {
        case 'money':
          _value = formatCurrency(item.value as number, currency, this.props.locale);
          break;
        case 'flag':
          if (!item.value) {
            _value = 'false';
          } else {
            _value = 'true';
          }
          break;
        case 'date':
          _value = localizeHelpers.formatDate(
            item.value as string,
            LocaleDateFormats.LL,
            this.props.locale,
          );
          break;
        case 'number':
          _value = localizeHelpers.formatNumber(item.value as number, this.props.locale);
          break;
        default:
          _value = item.value as string;
          break;
      }
    }

    return _value;
  }

  removeCheckboxValue(option: IOptions, item: ICustomFieldWithValue) {
    let _value: string[] = [...(item.value as string[])];

    for (let i = 0; i < _value.length; i++) {
      if (_value[i] === option.value) {
        _value.splice(i, 1);
        break;
      }
    }

    axios
      .put(
        swapRouteParams(routes.SET_USER_CUSTOM_FIELDS, {
          groupId: this.props.contact.object_id,
          userId: this.props.contact.user_id,
        }),
        { [item.name]: _value },
      )
      .then((response) => {
        //
      })
      .finally(() => {
        this.getCustomFields();
      });
  }

  addCheckboxValue(option: IOptions, item: ICustomFieldWithValue) {
    let _value: string[] = [...(item.value as string[])];

    _value.push(option.value);

    axios
      .put(
        swapRouteParams(routes.SET_USER_CUSTOM_FIELDS, {
          groupId: this.props.contact.object_id,
          userId: this.props.contact.user_id,
        }),
        { [item.name]: _value },
      )
      .then((response) => {
        //
      })
      .finally(() => {
        this.getCustomFields();
      });
  }

  render() {
    return (
      <div className="Contact">
        <ul className="Contact-menu">
          {this.state.tabs.map((item, index) => {
            return (
              <li
                onClick={() => {
                  this.setState({ activeTab: index });
                }}
                className={this.state.activeTab === index ? 'active no-select' : 'no-select'}
                key={index}
              >
                <span>{item}</span>
              </li>
            );
          })}
          <li className="add">
            {/* <Button icon="fas fa-filter" text={t("Filter")} /> */}
            {this.state.activeTab === 3 && (
              <AddCustomField
                {...this.props}
                contact={this.props.contact}
                onCreateCustomField={() => {
                  this.getCustomFields();
                }}
              />
            )}
            {this.state.activeTab === 0 && (
              <Button
                onClick={() => this.setState({ showCommModal: true })}
                className="add-field"
                icon="far fa-plus"
                text="Communication"
              />
            )}
            {this.state.activeTab === 2 && (
              <>
                <Button
                  onClick={() => this.toggleSocialModal('moment')}
                  className="add-field"
                  icon="far fa-plus"
                  text="Moment"
                />
                <Button
                  onClick={() => this.toggleSocialModal('social')}
                  className="add-field"
                  icon="far fa-plus"
                  text="Account"
                />
              </>
            )}
          </li>
        </ul>
        <div className="Contact-content">
          <div className="Contact-side">
            <ContactCard
              groupId={this.props.groupState.group.id}
              {...this.props}
            />
          </div>
          <div className="Contact-main">
            {this.state.activeTab === 0 && (
              <div className="comm-log">
                <SortableTable
                  {...this.props}
                  columns={this.state.communicationColumns}
                  data={this.getCommTableData()}
                  loading={this.state.isCommDataLoading}
                />
              </div>
            )}
            {this.state.activeTab === 1 && (
              <div className="activity-log">
                <ActivityLog {...this.props} />
              </div>
            )}
            {this.state.activeTab === 2 && (
              <div className="personal-info">
                <ContactPersonalInfo
                  toggleModal={this.toggleSocialModal}
                  onClose={() => this.onPersonalClose()}
                  showSocialsModal={this.state.showSocialsModal}
                  showMomentModal={this.state.showPersonalModal}
                  {...this.props}
                />
              </div>
            )}
            {this.state.activeTab === 3 && (
              <div className="custom-fields">
                <SortableTable
                  {...this.props}
                  loading={this.state.customFieldsLoading}
                  columns={[
                    { label: 'Field Name', id: 'field_name', sortable: false },
                    { label: 'Value', id: 'value', sortable: false },
                    { label: 'Actions', id: 'actions', sortable: false },
                  ]}
                  data={
                    this.state.customFields &&
                    this.state.customFields.map((item, index) => {
                      let _menu: ISortableTableActions[] = [
                        {
                          label: 'Edit',
                          icon: 'far fa-pencil-alt',
                          onClick: () => {
                            if (item.type !== 'date') {
                              this.setState({
                                editCfValue: item.value !== undefined ? item.value.toString() : '',
                                editCfDate: moment(),
                                editCustomField: index,
                              });
                            } else {
                              this.setState({
                                editCfValue: '',
                                editCfDate: moment(item.value as string),
                                editCustomField: index,
                              });
                            }
                          },
                        },
                        {
                          label: 'Delete',
                          icon: 'far fa-trash-alt',
                          onClick: () => {
                            this.setState({ confirmDelete: index });
                          },
                        },
                      ];

                      if (item.type === 'checkbox' || item.type === 'labels') {
                        _menu = [
                          {
                            label: 'Delete',
                            icon: 'far fa-trash-alt',
                            onClick: () => {
                              this.setState({ confirmDelete: index });
                            },
                          },
                        ];
                      }

                      const isOptionMatch = (option: string) =>
                        (item.value as string).includes(option);

                      return {
                        row: [
                          {
                            id: 'field_name',
                            content: (
                              <span>
                                <i className={this.getCustomFieldIcon(item.type)}></i>
                                <span>{item.name}</span>
                              </span>
                            ),
                            notranslate: 'yes',
                          },
                          {
                            id: 'value',
                            content: (
                              <div
                                className={
                                  item.value !== undefined
                                    ? item.type === 'flag'
                                      ? item.value.toString()
                                      : 'value-cell'
                                    : 'none'
                                }
                              >
                                {item.type !== 'checkbox' &&
                                  item.type !== 'labels' &&
                                  this.getCustomFieldValue(item)}
                                {item.type === 'checkbox' &&
                                  item.options?.map((opt, i: number) => {
                                    return (
                                      <div
                                        key={i}
                                        className="checkbox-value"
                                        onClick={(e: any) => {
                                          isOptionMatch(opt.value)
                                            ? this.removeCheckboxValue(opt, item)
                                            : this.addCheckboxValue(opt, item);
                                        }}
                                      >
                                        {isOptionMatch(opt.value) ? (
                                          <i className="fas fa-check-square"></i>
                                        ) : (
                                          <i className="fas fa-square"></i>
                                        )}
                                        <span>{opt.value}</span>
                                      </div>
                                    );
                                  })}
                                {item.type === 'labels' &&
                                  item.options?.map((opt, i: number) => {
                                    return (
                                      <div
                                        key={i}
                                        className={
                                          isOptionMatch(opt.value)
                                            ? 'label-value'
                                            : 'label-value not-selected'
                                        }
                                        style={{ backgroundColor: opt.color }}
                                        onClick={(e: any) => {
                                          isOptionMatch(opt.value)
                                            ? this.removeCheckboxValue(opt, item)
                                            : this.addCheckboxValue(opt, item);
                                        }}
                                      >
                                        {!isOptionMatch(opt.value) && (
                                          <i className="far fa-plus"></i>
                                        )}
                                        <span>{opt.value}</span>
                                        {isOptionMatch(opt.value) && (
                                          <i className="far fa-times"></i>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            ),
                            notranslate: 'yes',
                          },
                          { id: 'actions', menu: _menu },
                        ],
                      };
                    })
                  }
                />
                {this.state.customFields && this.state.customFields.length === 0 && (
                  <div className="empty">You haven't added any custom fields yet.</div>
                )}
              </div>
            )}
          </div>
        </div>

        <Prompt
          show={this.state.commLogToDelete.length > 0}
          title="Delete Record"
          message=""
          yesMessage="Delete"
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage="Cancel"
          onClose={() => this.setState({ commLogToDelete: '' })}
          onYes={() => this.deleteCommLog(this.state.commLogToDelete)}
        />

        <Modal
          class="delete-field"
          show={this.state.confirmDelete >= 0}
          onClose={() => {
            this.setState({ confirmDelete: -1 });
          }}
        >
          {this.state.confirmDelete !== -1 && (
            <React.Fragment>
              <div className="title">
                Delete {this.state.customFields[this.state.confirmDelete].name}
              </div>
              <div className="inner-content">
                <span className="msg">
                  Are you sure you want to delete the user value for '
                  {this.state.customFields[this.state.confirmDelete].name}'?
                </span>
              </div>
              <div className="actions">
                <Button
                  className="no"
                  text="No"
                  onClick={() => {
                    this.setState({ confirmDelete: -1 });
                  }}
                />
                <Button
                  className="yes"
                  text="Yes"
                  onClick={() => {
                    this.deleteUserCustomField({
                      custom_field_names: [this.state.customFields[this.state.confirmDelete].name],
                    });
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </Modal>
        <Modal
          class="edit-field"
          show={this.state.editCustomField >= 0}
          onClose={() => {
            this.setState({ editCustomField: -1 });
          }}
        >
          {this.state.editCustomField !== -1 && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.saveUserCustomField();
              }}
            >
              <div className="title">
                Edit {this.state.customFields[this.state.editCustomField].name}
              </div>
              <div className="inner-content">
                {(this.state.customFields[this.state.editCustomField].type === 'number' ||
                  this.state.customFields[this.state.editCustomField].type === 'money') && (
                  <TextField
                    required={true}
                    label="Value"
                    type="number"
                    step="0.01"
                    value={this.state.editCfValue}
                    name="editCfValue"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(e, this);
                    }}
                  />
                )}
                {this.state.customFields[this.state.editCustomField].type === 'text' && (
                  <TextField
                    required={true}
                    label="Value"
                    type="text"
                    value={this.state.editCfValue}
                    name="editCfValue"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(e, this);
                    }}
                  />
                )}
                {(this.state.customFields[this.state.editCustomField].type === 'dropdown' ||
                  this.state.customFields[this.state.editCustomField].type === 'checkbox') && (
                  <Dropdown
                    shouldSort={true}
                    label="Value"
                    name="editCfValue"
                    value={this.state.editCfValue}
                    options={this.state.customFields[this.state.editCustomField].options ?? []}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      handleInputChange(e, this, false);
                    }}
                  />
                )}
                {this.state.customFields[this.state.editCustomField].type === 'flag' && (
                  <Dropdown
                    label="Value"
                    shouldSort={true}
                    name="editCfValue"
                    value={this.state.editCfValue}
                    options={[
                      { label: 'True', value: 'true' },
                      { label: 'False', value: 'false' },
                    ]}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      handleInputChange(e, this, false);
                    }}
                  />
                )}
                {this.state.customFields[this.state.editCustomField].type === 'date' && (
                  <React.Fragment>
                    <DatePicker
                      label="Value"
                      date={this.state.editCfDate}
                      showTime={false}
                      name="editCfDate"
                      onChange={(d: any) => {
                        this.setState({ editCfDate: d });
                      }}
                    />
                  </React.Fragment>
                )}
              </div>
              <div className="actions">
                <Button
                  text="Apply Changes"
                  type="submit"
                />
              </div>
            </form>
          )}
        </Modal>
        <Modal
          class="add-field"
          show={this.state.showCommModal}
          onClose={() => {
            this.setState({ showCommModal: false }, () => {
              if (this.state.editId) {
                this.resetEdit();
              }
            });
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.handleCommunication();
            }}
            className="af"
          >
            <div className="af-title">Add Communication</div>
            <div className="af-subtitle">Communication Information</div>
            <label>{'Start Date & Time'}</label>
            <DateTimePicker
              inputProps={{ placeholder: 'Communication Date', readOnly: true }}
              className="custom-dtp"
              value={this.state.date}
              onChange={this.handleDate}
              locale={this.props.locale}
            />

            <Dropdown
              label="Medium"
              name="medium"
              shouldSort={true}
              value={this.state.medium}
              options={this.state.communicationTypeOptions}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                handleInputChange(e, this, false);
              }}
              required={true}
            />
            <SearchableDropdown
              label="Group Contact"
              list={this.state.userList}
              onSearch={(newSearch) => () => {}}
              selectedItem={this.state.toUser?.id ? this.state.toUser : null}
              setSelectedItem={(item) => this.setState({ toUser: item })}
              isRequired={true}
            />
            <label className="textarea-label">Notes</label>
            <TextArea
              type="text"
              value={this.state.notes}
              name="notes"
              onChange={(e) => {
                handleInputChange(e, this, false);
              }}
              required={true}
            />
            <div className="af-actions">
              <Button text="Save" />
              <Button
                text="Cancel"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showCommModal: false });
                }}
              />
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
