import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { IUserRole, IContactAccount, IContactMoment } from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { Constants } from '@gigit/constants';

import { IGroupState } from '../../../../reducers/group';
import { IAppState } from '../../../../store';
import { createToast } from '../../../../actions/toaster';
import Loader from '../../../Loader/Loader';
import SortableTable, { ISortableTableColumn } from '../../../SortableTable/SortableTable';
import AddSocial from '../../AddSocial/AddSocial';
import Modal from '../../../Modal/Modal';
import AddMoment from '../../AddSocial/AddMoment';
import { routes, swapRouteParams, toastError } from '../../../../helpers';
import { Prompt } from '../../../Prompt/Prompt';

import './ContactPersonalInfo.scss';
import errorHelpers from '../../../../helpers/errorHelpers';
import { IToast } from '../../../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { userSelectors } from '../../../../selectors/user';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  showSocialsModal: boolean;
  showMomentModal: boolean;
  locale: string;

  onClose(type: string): void;
  toggleModal(type: string): void;
  createToast(toast: IToast): void;
}

interface IState {
  moments: Array<IContactMoment>;
  socials: Array<IContactAccount>;
  socialColumns: ISortableTableColumn[];
  momentsColumns: ISortableTableColumn[];
  selectedSocial: IContactAccount | null;
  selectedMoment: IContactMoment | null;
  socialToDelete: string;
  momentToDelete: string;
  momentsLoading: boolean;
  socialLoading: boolean;
}

class ContactPersonalInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      moments: [],
      socials: [],
      momentsColumns: [
        { label: 'Life Moments', id: 'moments', sortable: false },
        { label: 'Date', id: 'date', sortable: false },
        { label: 'Time', id: 'time', sortable: false },
        { label: 'Notes', id: 'notes', sortable: false },
        { label: 'Actions', id: 'actions', sortable: false },
      ],
      socialColumns: [
        { label: 'Online Account', id: 'account', sortable: false },
        { label: 'Handle', id: 'handle', sortable: false },
        { label: 'Actions', id: 'actions', sortable: false },
      ],
      selectedSocial: null,
      selectedMoment: null,
      socialToDelete: '',
      momentToDelete: '',
      socialLoading: false,
      momentsLoading: false,
    };

    this.getSocials = this.getSocials.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onEditSocial = this.onEditSocial.bind(this);
    this.deleteSocial = this.deleteSocial.bind(this);
    this.onEditMoment = this.onEditMoment.bind(this);
    this.deleteMoment = this.deleteMoment.bind(this);
  }

  componentDidMount() {
    this.initialize();
  }

  initialize() {
    this.getMoments();
    this.getSocials();
  }

  getSocials() {
    this.setState(
      {
        socialLoading: true,
      },
      () => {
        axios
          .get(
            swapRouteParams(routes.GET_SOCIALS, {
              groupId: this.props.groupState.group.id,
              memberId: this.props.contact.user?.id,
            }),
          )
          .then((response) => {
            this.setState({
              socials: response.data,
            });
          })
          .catch((error) => {
            const errorObj = errorHelpers.getErrorObject(error);
            const toast = toastError(errorObj.translatedMessage, 'Social Accounts');
            this.props.createToast(toast);
          })
          .finally(() => {
            this.setState({
              socialLoading: false,
            });
          });
      },
    );
  }

  getSocialTableData() {
    return this.state.socials.map((social: IContactAccount) => {
      let content = (
        <React.Fragment>
          <i className={this.getSocialIcon(social.platform)}></i>
          <span>{social.platform}</span>
        </React.Fragment>
      );

      return {
        row: [
          { content: content, id: 'account', notranslate: 'yes' },
          { content: `@${social?.handle}`, id: 'handle', notranslate: 'yes' },
          {
            id: 'actions',
            menu: [
              {
                icon: 'far fa-pencil',
                onClick: (e: any) => {
                  if (social.id) {
                    this.onEditSocial(social.id);
                  }
                },
                label: this.props.t('Edit'),
              },
              {
                icon: 'fa fa-trash',
                onClick: (e: any) => {
                  if (social.id) {
                    this.setState({ socialToDelete: social.id });
                  }
                },
                label: this.props.t('Delete'),
              },
            ],
          },
        ],
      };
    });
  }

  getMomentTableData() {
    return this.state.moments.map((m: IContactMoment) => {
      let time = localizeHelpers.formatDate(m.start_date, LocaleDateFormats.LT, this.props.locale);

      let content = (
        <React.Fragment>
          <i className={this.getMomentIcon(m?.type)}></i>
          <span>{this.getMomentLabel(m?.type)}</span>
        </React.Fragment>
      );

      return {
        row: [
          { content: content, id: 'moments' },
          {
            content: `${localizeHelpers.formatDate(m.start_date, LocaleDateFormats.ll, this.props.locale)}`,
            id: 'date',
            notranslate: 'yes',
          },
          { content: time, id: 'time', notranslate: 'yes' },
          { content: m?.comment, id: 'notes', notranslate: 'yes' },
          {
            id: 'actions',
            menu: [
              {
                icon: 'far fa-pencil',
                onClick: () => {
                  if (m.id) {
                    this.onEditMoment(m.id);
                  }
                },
                label: this.props.t('Edit'),
              },
              {
                icon: 'fa fa-trash',
                onClick: () => {
                  if (m.id) {
                    this.setState({ momentToDelete: m.id });
                  }
                },
                label: this.props.t('Delete'),
              },
            ],
          },
        ],
      };
    });
  }

  getSocialIcon(social: string) {
    switch (social) {
      case Constants.contact_account_type.facebook:
        return 'fab fa-facebook-f';
      case Constants.contact_account_type.twitter:
        return 'fab fa-twitter';
      case Constants.contact_account_type.instagram:
        return 'fab fa-instagram';
      case Constants.contact_account_type.linkedin:
        return 'fab fa-linkedin';
      case Constants.contact_account_type.twitch:
        return 'fab fa-twitch';
      case Constants.contact_account_type.youtube:
        return 'fab fa-youtube';
      default:
        return '';
    }
  }

  getMomentIcon(moment: string) {
    switch (moment) {
      case Constants.moment_type.new_child:
        return 'fal fa-baby';
      case Constants.moment_type.graduation:
        return 'fal fa-graduation-cap';
      case Constants.moment_type.wedding:
        return 'fal fa-rings-wedding';
      case Constants.moment_type.promotion:
        return 'fal fa-briefcase';
      case Constants.moment_type.family_passing:
        return 'fal fa-tombstone-alt';
      case Constants.moment_type.other:
        return 'fa fa-question-circle';
      default:
        return '';
    }
  }

  getMomentLabel(moment: string) {
    switch (moment) {
      case 'new_child':
        return 'New Child';
      case 'family_passing':
        return 'Recent Passing';
      default:
        return moment;
    }
  }

  getMoments() {
    this.setState(
      {
        momentsLoading: true,
      },
      () => {
        axios
          .get(
            swapRouteParams(routes.GET_MOMENTS, {
              groupId: this.props.groupState.group.id,
              memberId: this.props.contact.user?.id,
            }),
          )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.setState({
                moments: response.data,
              });
            }
          })
          .catch((error) => {
            const errorObj = errorHelpers.getErrorObject(error);
            const toast = toastError(errorObj.translatedMessage, 'Life Moments');
            this.props.createToast(toast);
          })
          .finally(() => {
            this.setState({
              momentsLoading: false,
            });
          });
      },
    );
  }

  onClose(type: string) {
    this.props.onClose(type);

    if (type === 'social') {
      this.getSocials();
      this.setState({
        selectedSocial: null,
      });
    } else {
      this.getMoments();
      this.setState({
        selectedMoment: null,
      });
    }
  }

  onEditSocial(id: string) {
    let info = this.state.socials.find((s, idx) => {
      return s.id === id;
    });

    if (info) {
      this.setState(
        {
          selectedSocial: info,
        },
        () => {
          this.props.toggleModal('social');
        },
      );
    }
  }

  deleteSocial() {
    axios
      .delete(
        swapRouteParams(routes.UPDATE_SOCIAL, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
          socialId: this.state.socialToDelete,
        }),
      )
      .then(() => {
        this.getSocials();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Delete Social');
        this.props.createToast(toast);
      });
  }

  onEditMoment(id: string) {
    let m = this.state.moments.find((m, idx) => {
      return m.id === id;
    });

    if (m) {
      this.setState(
        {
          selectedMoment: m,
        },
        () => {
          this.props.toggleModal('moment');
        },
      );
    }
  }

  deleteMoment() {
    axios
      .delete(
        swapRouteParams(routes.UPDATE_MOMENT, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
          momentId: this.state.momentToDelete,
        }),
      )
      .then(() => {
        this.getMoments();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Delete Moment');
        this.props.createToast(toast);
      });
  }

  render() {
    let { t } = this.props;

    return (
      <div className="ContactPersonalInfo">
        <div className="data-container">
          <SortableTable
            {...this.props}
            columns={this.state.momentsColumns}
            data={this.getMomentTableData()}
            loading={this.state.momentsLoading}
          />
        </div>
        <div className="data-container">
          <SortableTable
            {...this.props}
            columns={this.state.socialColumns}
            data={this.getSocialTableData()}
            loading={this.state.socialLoading}
          />
        </div>

        <Prompt
          show={this.state.momentToDelete.length > 0}
          title={t('Delete Moment')}
          message={t('')}
          yesMessage={t('Delete Moment')}
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage={t('Cancel')}
          onClose={() => this.setState({ momentToDelete: '' })}
          onYes={() => this.deleteMoment()}
        />

        <Prompt
          show={this.state.socialToDelete.length > 0}
          title={t('Delete Social')}
          message={t('')}
          yesMessage={t('Delete Social')}
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage={t('Cancel')}
          onClose={() => this.setState({ socialToDelete: '' })}
          onYes={() => this.deleteSocial()}
        />

        <Modal
          class="add-field"
          show={this.props.showSocialsModal}
          onClose={() => {
            this.props.toggleModal('social');
          }}
        >
          <AddSocial
            social={this.state.selectedSocial}
            {...this.props}
            onClose={() => this.onClose('social')}
          />
        </Modal>

        <Modal
          class="add-field"
          show={this.props.showMomentModal}
          onClose={() => {
            this.props.toggleModal('moment');
          }}
        >
          <AddMoment
            moment={this.state.selectedMoment}
            {...this.props}
            onClose={() => this.onClose('moment')}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ContactPersonalInfo),
);
