import './HubModalManageComponents.scss';
import React, { ReactNode, useState } from 'react';
import Modal from '../../../Modal/Modal';
import Button from '../../../Button/Button';
import { IPageComponent } from '@gigit/interfaces';
import {
  DragDropContext,
  Draggable,
  DraggableStateSnapshot,
  DraggingStyle,
  Droppable,
  DroppableStateSnapshot,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import TextField from '../../../TextField/TextField';
import { Prompt } from '../../../Prompt/Prompt';
import { hubRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import { localizeHelpers } from '../../../../localizeHelpers';

interface IProps {
  hubId: string | null;
  pageId: string;
  show: boolean;
  onClose(): void;
  pageComponents: IPageComponent[];
  isHubPagesLoading: boolean;
  fetchPagesAfterSave(): void;
}
const DRAGGABLE_ID_PREFIX = 'MODAL_MANAGE_COMPONENTS_DRAGGABLE_';

const HubModalManageComponents: React.FC<IProps> = (props) => {
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const [componentIdToEdit, setComponentIdToEdit] = useState<string>('');
  const [componentIdToDelete, setComponentIdToDelete] = useState<string>('');
  const [componentName, setComponentName] = useState<string>('');
  const [isPageComponentSaving, setIsPageComponentSaving] = useState<boolean>(false);

  async function savePageComponentName() {
    const tmpPageComponent = props.pageComponents.find(
      (component) => component.id === componentIdToEdit,
    );
    if (tmpPageComponent && props.hubId) {
      setIsPageComponentSaving(true);
      try {
        await hubRequestActions.updateHubPageComponent(
          props.hubId,
          props.pageId,
          componentIdToEdit,
          {
            ...tmpPageComponent,
            title: componentName,
          },
        );

        props.fetchPagesAfterSave();
        dispatchToastSuccess(
          localizeHelpers.translate('Successfully saved page component name '),
          'Edit Page Component Name',
        );
        setIsPageComponentSaving(false);
        setComponentIdToEdit('');
      } catch (error) {
        dispatchToastError(error, 'Error Update Hub Page Component Title');
      }
    }
  }

  async function handleComponentDrag(result: DropResult, provided: ResponderProvided) {
    const destination = (result.destination?.index || 0) + 1;
    const source = result.source.index + 1;
    const tmpPageComponentDragged = props.pageComponents.find(
      (component) => component.sequence === source,
    );
    const tmpPageComponentToReplace = props.pageComponents.find(
      (component) => component.sequence === destination,
    );
    let errorOccurred = false;

    if (tmpPageComponentDragged && tmpPageComponentToReplace && props.hubId) {
      let componentsToUpdate = [] as IPageComponent[];

      props.pageComponents.forEach((component) => {
        if (component.sequence === destination) {
          componentsToUpdate.push(
            {
              ...tmpPageComponentDragged,
              sequence: destination,
            },
            {
              ...tmpPageComponentToReplace,
              sequence: tmpPageComponentToReplace.sequence + 1,
            },
          );
        } else if (
          component.sequence > destination &&
          tmpPageComponentDragged.id !== component.id &&
          tmpPageComponentToReplace.id !== component.id
        ) {
          componentsToUpdate.push({
            ...component,
            sequence: component.sequence + 1,
          });
        }
      });

      // TODO: transfer to bulk action
      for (let componentToUpdate of componentsToUpdate) {
        try {
          await hubRequestActions.updateHubPageComponent(
            props.hubId,
            props.pageId,
            componentToUpdate.id,
            {
              ...componentToUpdate,
            },
          );
        } catch (error) {
          dispatchToastError(error, 'Error Update Hub Page Component Sequence');
          errorOccurred = true;
        }
      }

      if (!errorOccurred) {
        props.fetchPagesAfterSave();
      }
    }
  }

  async function deletePageComponent() {
    if (props.hubId) {
      try {
        await hubRequestActions.deleteHubPageComponent(
          props.hubId,
          props.pageId,
          componentIdToDelete,
        );
        dispatchToastSuccess(
          localizeHelpers.translate('Successfully deleted page component'),
          'Delete Page Component',
        );
        props.fetchPagesAfterSave();
      } catch (error) {
        dispatchToastError(error, 'Error Delete Hub Page Component');
      }
    }
  }

  async function toggleVisibility(pageComponentId: string, visibility: 'public' | 'private') {
    const tmpPageComponent = props.pageComponents.find(
      (component) => component.id === pageComponentId,
    );
    if (props.hubId && props.pageId && tmpPageComponent) {
      try {
        await hubRequestActions.updateHubPageComponent(props.hubId, props.pageId, pageComponentId, {
          ...tmpPageComponent,
          visibility,
        });

        props.fetchPagesAfterSave();
      } catch (error) {
        dispatchToastError(error, 'Error Update Hub Page Component Visibility');
      }
    }
  }

  function isComponentVisible(component: IPageComponent) {
    return component.visibility === 'public';
  }

  return (
    <>
      <Modal
        key="hub-modal-manage-components"
        class="HubModalManageComponents"
        show={props.show}
        onClose={props.onClose}
        closeIcon=" "
      >
        <h2>Manage Components</h2>

        <DragDropContext onDragEnd={handleComponentDrag}>
          <Droppable
            type="HUB_ITEM_DROPPABLE"
            droppableId="ModalManageComponents-droppable"
          >
            {(provided, snapshot) => (
              <div
                className={snapshot.isDraggingOver ? 'components-wrap dragging' : 'components-wrap'}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {props.pageComponents?.map((component, index) => (
                  <Draggable
                    isDragDisabled={false}
                    key={DRAGGABLE_ID_PREFIX + component.id}
                    draggableId={DRAGGABLE_ID_PREFIX + component.id}
                    index={index}
                  >
                    {(provided, snapshot) => {
                      const style = {
                        position: 'static',
                      };
                      //We need to override draggable style because it doesn't work nicely in portals
                      if (snapshot.isDragging) {
                        provided.draggableProps.style = {
                          ...provided.draggableProps.style,
                          ...style,
                        } as DraggingStyle;
                      }
                      return (
                        <div
                          className={`item ${snapshot.isDragging ? 'dragging' : ''}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <span className="drag">
                            <i className="fas fa-grip-lines" />
                          </span>
                          <span className="name">{component.title}</span>
                          <div className="mini-actions">
                            <i
                              className="far fa-pen"
                              onClick={() => {
                                setComponentName(component.title);
                                setComponentIdToEdit(component.id);
                              }}
                            />
                            <i
                              className="far fa-trash-alt"
                              onClick={() => setComponentIdToDelete(component.id)}
                            />
                            <i
                              className={`far ${isComponentVisible(component) ? 'fa-eye' : 'fa-eye-slash'}`}
                              onClick={() => {
                                toggleVisibility(
                                  component.id,
                                  component.visibility === 'public' ? 'private' : 'public',
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {/* <Loader loading={props.isHubPagesLoading}/> */}

        <div className="actions">
          <Button
            text="Close"
            type="button"
            buttonType="outline-dark"
            onClick={props.onClose}
          />
        </div>
      </Modal>

      <Modal
        class="Modal-edit-content-block-name"
        show={componentIdToEdit !== ''}
        onClose={() => setComponentIdToEdit('')}
        closeIcon=" "
      >
        <h2>Edit Content Block Name</h2>
        <form>
          <TextField
            value={componentName}
            name="content-block-name"
            type="text"
            onChange={(e) => setComponentName(e.target.value)}
          />

          <div className="actions">
            <Button
              text="Cancel"
              type="button"
              buttonType="outline-dark"
              onClick={() => {
                setComponentIdToEdit('');
                setComponentName('');
              }}
            />
            <Button
              text="Save"
              type="button"
              buttonType="dark"
              onClick={savePageComponentName}
              loading={isPageComponentSaving}
            />
          </div>
        </form>
      </Modal>

      <Prompt
        show={componentIdToDelete !== ''}
        title="Delete Hub Content Block"
        message="Are you sure you want to delete the Hub Content Block?"
        yesMessage="Yes"
        yesClass="fa fa-trash"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={async () => deletePageComponent()}
        onClose={() => setComponentIdToDelete('')}
      />
    </>
  );
};

export default HubModalManageComponents;
