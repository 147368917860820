import React, { ChangeEvent, useState } from 'react';

/** Hook that can be used to styore form data. */
export function useForm<T>(initialValues: T): [T, (e: ChangeEvent<HTMLInputElement>) => void] {
  const [values, setValue] = useState<T>(initialValues);

  return [
    values,
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { name, value, type } = e.target;

      if (type === 'tel') {
        validatePhoneNumber(value) && setValue({ ...values, [name]: value });
        return;
      }

      setValue({
        ...values,
        [name]: value,
      });
    },
  ];
}

const validatePhoneNumber = (numberToValidate: string) => {
  return new RegExp(
    '^(\\+)?(\\d{1,2})?([\\s.-])?\\(?\\d{0,3}\\)?([\\s.-])?(\\d{0,3})?([\\s.-])?(\\d{0,4})?$',
  ).test(numberToValidate);
  //Mathces the following formats
  // 123-456-7890
  // +1-123-456-7890
  // 4169875522
  // (123) 456-7890
  // 123 456 7890
  // 123.456.7890
  // +91 (123) 456-7890
};
