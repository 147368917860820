import { ICause, IGroup } from '@gigit/interfaces';
import React, { Fragment, useEffect, useState } from 'react';
import { IGetMetricValueFE } from '../../../../../../requestActions/metrics';
import Modal from '../../../../../Modal/Modal';
import Portrait from '../../../../../Portrait/Portrait';
import './CausesFocusAreas.scss';

interface ICausesConsolidatedList extends IGroup {
  metrics: IGetMetricValueFE[];
}

interface IProps {
  show: boolean;
  triggerShowModal: (show: boolean) => void;
  selectedItem: ICausesConsolidatedList | null;
  allCauses: ICause[];
}

const CausesFocusAreas = (props: IProps) => {
  return (
    <Modal
      show={props.show}
      onClose={() => props.triggerShowModal(false)}
      class="FocusAreaModal"
    >
      <div className="header">
        <Portrait
          currentImage={props.selectedItem?.profile_image_url}
          size={60}
        />
        <div
          notranslate="yes"
          className="header-title"
        >
          {props.selectedItem?.title}
        </div>
      </div>
      <div className="modal-content">
        {props.selectedItem?.causes?.map((cause: string, index: number) => {
          return (
            <div className="cause-item">
              <img
                src={
                  props.allCauses.find((partnerCause: ICause) => partnerCause.id === cause)
                    ?.icon_url
                }
              />
              <div>
                {props.allCauses.find((partnerCause: ICause) => partnerCause.id === cause)?.cause}
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default CausesFocusAreas;
