import React from 'react';
import { Config } from '@gigit/config';

import './InfoAdminTab.scss';

/** Super admin tab for showing debug info. */
export function InfoAdminTab() {
  return (
    <div className="InfoAdminTab">
      <div className="section-title">
        <div className="forms-title">Info</div>
        <div className="forms-controls"></div>
      </div>

      <div className="section-inner">
        <div className="forms-sub-title">General</div>
        <div className="sub-section">
          <div>Version: {Config.version ?? 'local'}</div>
        </div>

        <div className="forms-sub-title">Feature Flags</div>

        <div className="sub-section">
          {Object.keys(Config.feature_flags).map((flag) => (
            <div>
              {flag}: {(Config.feature_flags as Record<string, boolean>)[flag].toString()}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
