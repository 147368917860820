import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiConstants } from '../../constants/uiConstants';
import useMediaQuery from '../../hooks/useMediaQuery';
import { userSelectors } from '../../selectors/user';
import { IAppState } from '../../store';
import {
  getSearchLabelFromCategory,
  getSearchRouteFromCategory,
  mapStyleOptions,
  searchCategoryOptions,
  SearchContext,
} from './Config';
import SearchControls from './SearchControls/SearchControls';
import { BBox } from 'geojson';
import turf from 'turf';
import { updateGeolocation } from '../../actions/geolocation';
import { geolocationSelectors } from '../../selectors/geolocation';
import { searchRequestActions } from '../../requestActions';
import {
  IEvent,
  IEventSearch,
  IEventSearch_v2,
  IEventSummaryFE,
  IGig,
  IGigSearch,
  IGigSearch_v2,
  IGigSummaryFE,
  IGroup,
  IGroupSearch,
  IGroupSearch_v2,
  ISearchResultObject,
  ISkill,
} from '@gigit/interfaces';
import SearchResultCard from '../../components/SearchResultCard/SearchResultCard';
import { Config } from '@gigit/config';
import GoogleMapReact from 'google-map-react';
import { ReactComponent as MapMarkerIcon } from '../../assets/search/map-marker.svg';
import { ReactComponent as MapSearchIcon } from '../../assets/search/search-icon.svg';
// EXPERIMENTAL
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';

import './KambeoSearch.scss';
import 'react-spring-bottom-sheet/dist/style.css';
import useSupercluster from 'use-supercluster';
import Marker from '../../components/MapComponents/Marker/Marker';
import Modal from '../../components/Modal/Modal';
import { FormSectionHeader } from '../../components/shared/Onboarding/FormSectionHeader/FormSectionHeader';
import SearchEntitySelect from './SearchEntitySelect/searchEntitySelect';
import ToggleButton from '../../components/shared/ToggleButton/ToggleButton';
import SearchResultPreview from './SearchResultPreview/SearchResultPreview';
import { useDebounce } from '../../hooks/useDebounce';
import SearchResultSkeleton from '../../components/SearchResultCard/SearchResultSkeleton';
import Portrait from '../../components/Portrait/Portrait';
import {
  ArrayParam,
  BooleanParam,
  JsonParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParam,
} from 'use-query-params';
import { useFocusAreas } from '../../hooks/useFocusAreas';
import CollapsibleSection from '../../components/CollapsibleSection/CollapsibleSection';
import useToastDispatcher from '../../hooks/useToaster';
import { localizeHelpers } from '../../localizeHelpers';
import locationHelpers, { IGeocodeResponse } from '../../components/Location/locationHelpers';
import { useGroupClassifications } from '../../hooks/useGroupClassifications';
import Checkbox from '../../components/Checkbox/Checkbox';
import { useSkills } from '../../hooks/useSkills';
import Slider from 'rc-slider';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { errorHelpers } from '../../helpers/errorHelpers';
import { toastError } from '../../helpers';
import { IGeolocationState } from '../../reducers/geolocation';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

interface ICheckboxFilterSection {
  label: string;
  selections: Array<any>;
  masterList: Array<any>;
  onClick: (e: any) => void;
  onClear: () => void;
}
interface IToggleFilterSection {
  label: string;
  value: boolean;
  count: number;
  onToggle: () => void;
  onClear: () => void;
}
interface IMapMarker {
  properties: {
    cluster: boolean;
    result:
      | ISearchResultObject<IGroup>
      | ISearchResultObject<IEventSummaryFE>
      | ISearchResultObject<IGigSummaryFE>;
  };
  type: 'Feature';
  geometry: {
    type: 'Point';
    coordinates: number[];
  };
}
interface IMapViewport {
  latitude: number;
  longitude: number;
  zoom: number;
  height: string;
  width: string;
  onEventZoom?: boolean;
}

const searchCategories = [...searchCategoryOptions].filter((category) => category.value);
const clusterMaxZoom = 14;
const clusterDefaultRadius = 75;
/** 24 Is a little excessive but we want to cover the grid on multiple desktop screen */
const loadingCards = Array.from(Array(24).keys());
/*
 *   Main Search Container
 */
export default function KambeoSearch() {
  // SELECTORS
  const geolocationState: IGeolocationState = useSelector(
    (state: IAppState) => state.geolocationState,
  );
  const defaultCoords = useSelector((state: IAppState) =>
    geolocationSelectors.getGeocoordinates(state),
  );
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));
  const userInfo = useSelector((state: IAppState) => userSelectors.getUser(state));
  // CONTEXT
  const searchContext = SearchContext;
  // COMPONENT STATE
  const [searchTerms, setSearchTerms] = useQueryParam('query', StringParam);
  const [keyword, setKeyword] = useState<string>(searchTerms || '');
  const [isMapView, setIsMapView] = useState<boolean>(true);
  const [searchCategory, setSearchCategory] = useState<string>('groups');
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<
    | ISearchResultObject<IGroup>[]
    | ISearchResultObject<IEventSummaryFE>[]
    | ISearchResultObject<IGigSummaryFE>[]
  >([]);
  const [searchResult, setSearchResult] = useState<
    | ISearchResultObject<IGroup>
    | ISearchResultObject<IEventSummaryFE>
    | ISearchResultObject<IGigSummaryFE>
    | undefined
  >(undefined);
  const [isVirtual, setIsVirtual] = useState<boolean>(false);
  const [viewport, setViewport] = useState<IMapViewport | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true); // Start true to avoid empty state flash
  const [points, setPoints] = useState<IMapMarker[]>([]);
  const [bounds, setBounds] = useState<BBox>();
  const [zoom, setZoom] = useState<number>(uiConstants.search.defaultMapZoom);
  const [bottomSheetOpen, setBottomSheetOpen] = useState<boolean>(true);
  const [testSnapPoint, setTestSnapPoint] = useState<boolean>(false);
  const [acceptingVolunteers, setAcceptingVolunteers] = useState<boolean>(false);
  const [OSSDRequired, setOSSDRequired] = useState<boolean>(false);
  const [policeCheckRequired, setPoliceCheckRequired] = useState<boolean>(false);

  const { focusAreas, isLoading: focusAreasLoading } = useFocusAreas();
  const [selectedFocusAreas, setFocusAreas] = useState<string[]>([]);

  const {
    classifications,
    isLoading: groupClassificationsLoading,
    subClassifications,
  } = useGroupClassifications();
  const [selectedClassifications, setSelectedClassifications] = useState<string[]>([]);
  const [selectedSubClassifications, setSelectedSubClassifications] = useState<string[]>([]);

  const { skills } = useSkills();
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);

  const [ageRange, setAgeRange] = useState<number[]>([]);
  const [searchDistance, setSearchDistance] = useState<number>(200);

  const [bboxCoordinates, setBboxCoordinates] = useState<{ sw: number[]; ne: number[] }>();

  // VIEW HELPERS
  const searchGridRef = useRef<HTMLDivElement>(null);
  const searchControlsRef = useRef<HTMLDivElement>(null);
  const isMobileDimensions = useMediaQuery(`(max-width: ${uiConstants.search.mobileBreakPoint}px)`);
  const secondMobileBreakpoint = useMediaQuery(`(max-width: 500px)`);
  const dispatch = useDispatch();
  const sheetRef = useRef<any>();

  // Search Params
  const [acceptingVolunteersParam, setAcceptingVolunteersParam] = useQueryParam(
    'accepting_volunteers',
    BooleanParam,
  );
  const [focusAreasParam, setFocusAreasParam] = useQueryParam('cause_ids', ArrayParam);
  const [classificationsParam, setClassificationsParam] = useQueryParam(
    'classification_ids',
    ArrayParam,
  );
  const [subClassificationsParam, setSelectedSubClassificationsParam] = useQueryParam(
    'sub_classifications',
    ArrayParam,
  );
  const [typeParam, setTypeParam] = useQueryParam('type', StringParam);
  const [zoomParam, setZoomParam] = useQueryParam('zoom', StringParam);
  const [locationParam, setLocationParam] = useQueryParam('location', StringParam);
  const [skillsParam, setSkillsParam] = useQueryParam('skill_ids', ArrayParam);
  const [OSSDParam, setOSSDParam] = useQueryParam('is_ossd_eligible', BooleanParam);
  const [policeCheckParam, setPoliceCheckParam] = useQueryParam('has_police_check', BooleanParam);
  const [ageRangeParam, setAgeRangeParam] = useQueryParam('eligible_for_my_age', JsonParam);
  const [isVirtualParam, setIsVirtualParam] = useQueryParam('is_virtual', BooleanParam);
  const [showFiltersParam, setShowFiltersParam] = useQueryParam('filters', BooleanParam);

  const history = useHistory();

  //Toast Dispatcher
  const { dispatchToastError } = useToastDispatcher();

  // Search Debounce
  const debouncedKeyword = useDebounce(keyword);

  // MAP REF (NEEDS TYPE)
  const mapRef = useRef<any>(null);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: clusterDefaultRadius, maxZoom: clusterMaxZoom },
  });

  async function getUserCoords() {
    let coords: number[] | null = null;

    if (isUserLoggedIn && userState.locations && userState.locations[0]) {
      const userLocation =
        userState.locations[0].city +
        ' ' +
        userState.locations[0].state +
        ', ' +
        userState.locations[0].country;
      try {
        coords = await fetch(
          'https://maps.googleapis.com/maps/api/geocode/json?address=' +
            userLocation +
            '&key=' +
            Config.web.REACT_APP_GOOGLE_API_KEY,
        )
          .then((response) => response.json())
          .then((data) => {
            return [
              data.results[0].geometry.location.lng,
              data.results[0].geometry.location.lat,
            ] as number[];
          });
      } catch (error) {
        const errObj = errorHelpers.getErrorObject(error);
        toastError(errObj.translatedMessage, 'Google Maps');
      }
    } else if (geolocationState) {
      coords = [geolocationState.lng, geolocationState.lat];
    }

    setViewport({
      zoom: 8, // or params, also fix magic num
      height: '100%',
      width: '100%',
      longitude: coords ? coords[0] : defaultCoords[0],
      latitude: coords ? coords[1] : defaultCoords[1],
    });
  }
  // EFFECTS
  useEffect(() => {
    resetFilters();
  }, [searchCategory]);

  useEffect(() => {
    getUserCoords();
    dispatch(updateGeolocation());

    handleParams();

    checkPreselectedType();
  }, []);

  useEffect(() => {
    generateMapMarkers();

    searchGridRef?.current?.scrollTo(0, 0);
  }, [searchResults]);

  useEffect(() => {
    if (acceptingVolunteers) {
      setAcceptingVolunteersParam(true);
    } else {
      setAcceptingVolunteersParam(null);
    }

    if (!!selectedFocusAreas.length) {
      setFocusAreasParam(selectedFocusAreas);
      shuffleSelectedFocusAreas();
    } else {
      setFocusAreasParam(null);
      shuffleSelectedFocusAreas();
    }

    if (!!selectedClassifications.length) {
      setClassificationsParam(selectedClassifications);
    } else {
      setClassificationsParam(null);
    }

    if (!!selectedSubClassifications.length) {
      setSelectedSubClassificationsParam(selectedSubClassifications);
    } else {
      setSelectedSubClassificationsParam(null);
    }

    if (!!selectedSkills.length) {
      setSkillsParam(selectedSkills);
    } else {
      setSkillsParam(null);
    }

    if (policeCheckRequired) {
      setPoliceCheckParam(true);
    } else {
      setPoliceCheckParam(null);
    }

    if (OSSDRequired) {
      setOSSDParam(true);
    } else {
      setOSSDParam(null);
    }

    if (!!ageRange.length) {
      let obj = { min: ageRange[0], max: ageRange[1] };
      setAgeRangeParam(obj);
    } else {
      setAgeRangeParam(null);
    }

    if (isVirtual) {
      setIsVirtualParam(isVirtual);
    } else {
      setIsVirtualParam(null);
    }

    if (showFiltersParam) {
      setShowFilterModal(true);
      setShowFiltersParam(null);
    }
    if (keyword) setSearchTerms(keyword);
    else setSearchTerms(null);

    search();
  }, [
    selectedFocusAreas,
    searchCategory,
    debouncedKeyword,
    acceptingVolunteers,
    selectedClassifications,
    selectedSubClassifications,
    isVirtual,
    selectedSkills,
    policeCheckRequired,
    OSSDRequired,
    ageRange,
    searchDistance,
    keyword,
  ]);

  useEffect(() => {
    search();
  }, [isMapView]);
  // End Effects

  // COMPONENT FUNCTIONS
  const handleParams = async () => {
    if (typeParam) {
      setSearchCategory(typeParam);
    }
    // Handle Filters From Params
    if (acceptingVolunteersParam) setAcceptingVolunteers(true);
    if (classificationsParam) {
      const fromUrl = classificationsParam.map((id) => id as string);
      setSelectedClassifications(fromUrl);
    }
    if (subClassificationsParam) {
      const fromUrl = subClassificationsParam.map((id) => id as string);
      setSelectedSubClassifications(fromUrl);
    }
    if (focusAreasParam) {
      const fromUrl = focusAreasParam.map((id) => id as string);
      setFocusAreas(fromUrl);
    }
    if (isVirtualParam) setIsVirtual(true);

    if (skillsParam) {
      const fromUrl = skillsParam.map((id) => id as string);
      setSelectedSkills(fromUrl);
    }

    if (ageRangeParam) setAgeRange([ageRangeParam.min, ageRangeParam.max]);
    if (policeCheckParam) setPoliceCheckRequired(true);
    if (OSSDParam) setOSSDRequired(true);
  };
  const search = async () => {
    if (viewport) {
      // TODO: FIX ANY
      let basePayload: any = {
        wildcard_search: keyword,
        location: {
          coordinates: [Number(viewport.longitude), Number(viewport.latitude)],
          max_distance: searchDistance,
        },
        cause_ids: !!selectedFocusAreas.length ? selectedFocusAreas : undefined,
        sub_classifications: !!selectedSubClassifications.length
          ? selectedSubClassifications
          : undefined,
        classification_ids: !!selectedClassifications.length ? selectedClassifications : undefined,
      };

      if (!isMapView) {
        delete basePayload.location;
      }
      // TODO - TRY/CATCH
      setIsLoading(true);
      if (searchCategory === 'groups') {
        setIsLoading(true);

        let params: IGroupSearch_v2 = {
          query: keyword ?? '',
          options: {
            search_fields: ['title', 'description', 'causes'],
            include_shadow: true,
            results_limit: 100,
            accepting_volunteers: acceptingVolunteers,
            causes: selectedFocusAreas,
            return_location: true,
          },
        };

        if (isMapView) {
          params.options.bbox = bboxCoordinates;
        }

        const results = await searchRequestActions.searchGroupsV2(params);
        setSearchResults(results);
      }

      if (searchCategory === 'events') {
        let params: IEventSearch_v2 = {
          query: keyword ?? '',
          options: {
            search_fields: ['title', 'description', 'causes'],
            results_limit: 100,
            causes: selectedFocusAreas,
            return_location: true,
          },
        };

        if (isMapView) {
          params.options.bbox = bboxCoordinates;
        }

        const results = await searchRequestActions.searchEventsV2(params);
        setSearchResults(results);
      }

      if (searchCategory === 'volunteer') {
        let params: IGigSearch_v2 = {
          query: keyword ?? '',
          options: {
            search_fields: ['title', 'description', 'causes'],
            results_limit: 100,
            causes: selectedFocusAreas,
            return_location: true,
          },
        };

        if (isMapView) {
          params.options.bbox = bboxCoordinates;
        }

        const results = await searchRequestActions.searchGigsV2(params);
        setSearchResults(results);
      }

      setIsLoading(false);
    }
  };

  const prepareEventSearch = (basePayload: Partial<IEventSearch>): IEventSearch => ({
    ...basePayload,
    is_virtual: isVirtual,
  });

  const prepareGroupSearch = (basePayload: Partial<IGroupSearch>): IGroupSearch => ({
    ...basePayload,
    // we avoid passing false because we want all the causes or causes who are accepting volunteers
    accepting_volunteers: acceptingVolunteers ? acceptingVolunteers : undefined,
  });

  const prepareVolunteerSearch = (basePayload: Partial<IGigSearch>): IGigSearch => ({
    ...basePayload,
    gig_type: 'volunteer',
    is_virtual: isVirtual,
    skill_ids: !!selectedSkills.length ? selectedSkills : undefined,
    has_police_check: policeCheckRequired ? policeCheckRequired : undefined,
    is_ossd_eligible: OSSDRequired ? OSSDRequired : undefined,
    eligible_for_my_age: !!ageRange.length ? { min: ageRange[0], max: ageRange[1] } : undefined,
  });

  const generateMapMarkers = () => {
    const markers = [...searchResults].reduce(
      (
        prev: IMapMarker[],
        searchResult:
          | ISearchResultObject<IGroup>
          | ISearchResultObject<IEventSummaryFE>
          | ISearchResultObject<IGigSummaryFE>,
      ) => {
        if (searchResult.locations) {
          for (const address of searchResult.locations) {
            const longitude = address?.location?.coordinates[0];
            const latitude = address?.location?.coordinates[1];
            const coords = longitude && latitude ? [longitude, latitude] : [];
            prev.push({
              type: 'Feature',
              properties: { cluster: false, result: searchResult },
              geometry: {
                type: 'Point',
                coordinates: coords,
              },
            });
          }
        }
        return prev;
      },
      [],
    );

    setPoints(markers);
  };

  const onMapChange = (mapChanges: GoogleMapReact.ChangeEventValue) => {
    const { zoom: mapZoom, bounds: mapBounds, center } = mapChanges;
    const newZoom = zoomParam ? parseInt(zoomParam) : mapZoom;

    setZoom(newZoom);

    console.log(mapBounds, center);

    setBboxCoordinates({
      sw: [mapBounds.sw.lng, mapBounds.sw.lat],
      ne: [mapBounds.ne.lng, mapBounds.ne.lat],
    });
    setBounds([mapBounds.nw.lng, mapBounds.se.lat, mapBounds.se.lng, mapBounds.nw.lat]);

    if (viewport) {
      setViewport({
        ...viewport,
        latitude: center.lat,
        longitude: center.lng,
        zoom: newZoom,
      });
    }

    let p1 = turf.point([mapBounds.ne.lng, mapBounds.ne.lat]);
    let p2 = turf.point([center.lng, center.lat]);
    let range = turf.distance(p1, p2, 'kilometers');

    range = Math.floor(range) !== 0 ? range : 1;
    setSearchDistance(range);
  };

  const onSetSearchResult = (
    searchResult: ISearchResultObject<IGroup | IEventSummaryFE | IGigSummaryFE>,
    coords: Array<number>,
  ) => {
    // TODO: remember to reset if going to list view?
    // Can Probably consider if this is a cluster and zoom further if needed
    const lat = coords[0];
    const lng = coords[1];
    setSearchResult(searchResult);
    mapRef?.current?.panTo({ lat, lng });
    if (isMapView) setBottomSheetOpen(true);
  };

  const checkPreselectedType = () => {
    if (history.location.state) {
      setSearchCategory(`${history.location.state}`);
      setIsMapView(false);
    }
  };

  const renderMapChildren = () => {
    return clusters.map((cluster, index) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const { cluster: isCluster, point_count: pointCount, result } = cluster.properties;
      const size = `${10 + (pointCount / points.length) * 20}px`;
      if (isCluster) {
        return (
          <Marker
            key={`cluster-${cluster.id}`}
            lat={latitude}
            lng={longitude}
          >
            <div
              className="cluster-marker"
              style={{
                width: size,
                height: size,
              }}
              onClick={() => {
                const expansionZoom = Math.min(
                  supercluster.getClusterExpansionZoom(cluster.id),
                  30, // fixme: magic num
                );
                mapRef?.current?.setZoom(expansionZoom);
                mapRef?.current?.panTo({ lat: latitude, lng: longitude });
              }}
            >
              {pointCount}
            </div>
          </Marker>
        );
      } else {
        return (
          <Marker
            key={index}
            lat={latitude}
            lng={longitude}
          >
            <div
              // set currentPopUp equiv
              onClick={() => onSetSearchResult(result, [latitude, longitude])}
              className="marker"
              style={{ width: size, height: size }}
            >
              <MapMarkerIcon
                className={`map-marker ${result?.id === searchResult?.id ? 'active' : ''}`.trim()}
              />
            </div>
          </Marker>
        );
      }
    });
  };

  const renderEmptyState = () => (
    <div className="search-empty-state">
      <div className="empty-state-icon-container">
        <MapSearchIcon />
      </div>
      <p> Sorry, no results found. </p>
    </div>
  );

  const onSelectFocusArea = (id: string) => {
    const focusAreaIndex = selectedFocusAreas.indexOf(id);
    let currentlySelectedFocusAreas = [...selectedFocusAreas];
    if (focusAreaIndex !== -1) {
      currentlySelectedFocusAreas.splice(focusAreaIndex, 1);
    } else {
      currentlySelectedFocusAreas.push(id);
    }

    setFocusAreas(currentlySelectedFocusAreas);
  };

  const applyUserFocusAreas = () => {
    if (userInfo && !!userInfo.causes?.length) {
      setFocusAreas([...selectedFocusAreas, ...userInfo.causes]);
    }
  };

  const applyUserSkills = () => {
    if (userInfo && !!userInfo.skills?.length) {
      setSelectedSkills([...selectedSkills, ...userInfo.skills]);
    }
  };

  // Puts Selected Focus Areas first - if none selected, we arrange alphabetically
  const shuffleSelectedFocusAreas = () => {
    focusAreas.sort((a, b) => {
      if (!!selectedFocusAreas.length) {
        if (selectedFocusAreas.includes(a.id!) && !selectedFocusAreas.includes(b.id!)) {
          return -1;
        } else {
          return 0;
        }
      } else {
        return a?.cause.localeCompare(b?.cause);
      }
    });
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const userPosition = { lat: position.coords.latitude, lng: position.coords.longitude };
        mapRef.current?.panTo(userPosition);
      },
      () => {
        dispatchToastError(
          localizeHelpers.translate('This browser does not support geolocation.'),
          'Use Current Location',
        );
      },
    );
  };

  const handleClassificationUpdate = (e: any) => {
    const id = e.target.value;
    const classIndex = selectedClassifications.indexOf(id);
    let currentlySelectedClassifications = [...selectedClassifications];
    if (classIndex !== -1) {
      currentlySelectedClassifications.splice(classIndex, 1);
    } else {
      currentlySelectedClassifications.push(id);
    }

    setSelectedClassifications(currentlySelectedClassifications);
  };

  const handleSubClassificationUpdate = (e: any) => {
    const id = e.target.value;
    const classIndex = selectedSubClassifications.indexOf(id);
    let currentlySelectedSubClassifications = [...selectedSubClassifications];
    if (classIndex !== -1) {
      currentlySelectedSubClassifications.splice(classIndex, 1);
    } else {
      currentlySelectedSubClassifications.push(id);
    }

    setSelectedSubClassifications(currentlySelectedSubClassifications);
  };

  const handleSkillsUpdate = (e: any) => {
    const id = e?.target?.value ?? e;
    const index = selectedSkills.indexOf(id);
    let currentlySelectedSkills = [...selectedSkills];
    if (index !== -1) {
      currentlySelectedSkills.splice(index, 1);
    } else {
      currentlySelectedSkills.push(id);
    }

    setSelectedSkills(currentlySelectedSkills);
  };

  const renderToggleFilterSection = (toggleFilterSectionOptions: IToggleFilterSection) => {
    return (
      <div className="filter-section">
        {toggleFilterSectionOptions.count > 0 && (
          <span className="toggle-count"> {toggleFilterSectionOptions.count} </span>
        )}
        {toggleFilterSectionOptions.value && (
          <span
            className="clear-filter-section"
            onClick={() => toggleFilterSectionOptions.onClear()}
          >
            <i className="fa fa-times-circle" />
          </span>
        )}
        <CollapsibleSection label={toggleFilterSectionOptions.label}>
          <div className="filter-content column">
            <ToggleButton
              isActive={toggleFilterSectionOptions.value}
              onToggleClick={() => {
                toggleFilterSectionOptions.onToggle();
              }}
            />
          </div>
        </CollapsibleSection>
      </div>
    );
  };

  const updateAgeRange = (range: number[]) => {
    setAgeRange(range);
  };

  const getFilterCount = () => {
    let count = 0;

    if (acceptingVolunteers) count += 1;
    if (!!selectedClassifications.length) count += selectedClassifications.length;
    if (!!selectedSubClassifications.length) count += selectedSubClassifications.length;
    if (!!focusAreas.length) count += selectedFocusAreas.length;
    if (isVirtual) count += 1;
    if (policeCheckRequired) count += 1;
    if (OSSDRequired) count += 1;
    if (!!selectedSkills.length) count += selectedSkills.length;

    return count;
  };

  const resetFilters = () => {
    setAcceptingVolunteers(false);
    setSelectedClassifications([]);
    setSelectedSubClassifications([]);
    setFocusAreas([]);
    setIsVirtual(false);
    setPoliceCheckRequired(false);
    setOSSDRequired(false);
    setSelectedSkills([]);
  };

  const areSearchResultsEmpty = !searchResults.length;

  return (
    <div className="KambeoSearch Search">
      <div
        ref={searchControlsRef}
        className="search-top"
      >
        <SearchControls
          keyword={keyword}
          category={searchCategory}
          isMapView={isMapView}
          toggleAdvancedFilter={() => setShowFilterModal(!showFilterModal)}
          toggleMap={() => setIsMapView(!isMapView)}
          setSearchCategory={(category) => setSearchCategory(category)}
          setKeywordValue={(value) => setKeyword(value)}
          filterCount={getFilterCount()}
        />
      </div>
      {/* Previously search-inner / make dynamic */}
      <div
        style={{ maxHeight: secondMobileBreakpoint && !isMapView ? '' : `calc(100vh - 166px)` }}
        className="search-views-container"
      >
        {/*
					We use elements height to hardcode height in pixels from dynamic value to ensure scroll
				*/}
        {
          <div
            ref={searchGridRef}
            className={`search-grid-view ${isMapView ? `column ${secondMobileBreakpoint ? `mobile` : ``} ${searchResult ? 'search-result-open' : ''}` : `full ${secondMobileBreakpoint ? `mobile` : ``} ${areSearchResultsEmpty ? 'empty' : ''}`}`.trim()}
          >
            {!searchResult &&
              !isLoading &&
              (keyword ? (
                <span className="results-amount">
                  {' '}
                  {`${searchResults.length} ${getSearchLabelFromCategory(searchCategory)} for ${keyword}`}
                </span>
              ) : (
                <span className="results-amount">{`${searchResults.length === 1 ? `1 ${getSearchLabelFromCategory(searchCategory)}` : `${searchResults.length} ${getSearchLabelFromCategory(searchCategory)}`}`}</span>
              ))}
            {!isLoading &&
              searchResults.map((result) => {
                // TODO: update interface so we can avoid this
                const vmCheck: any = { ...result };
                const resultCoords =
                  points.find((point) => point.properties?.result?.id === result.id)?.geometry
                    .coordinates || [];
                return (
                  <SearchResultCard
                    mapActive={isMapView}
                    result={result}
                    type="group" // TODO: hardcoded fix
                    isFollowing={result?.is_following || false} // todo fix
                    isVirtual={false}
                    isVM={vmCheck?.external_id && vmCheck?.external_id.VolunteerMatch}
                    onShareClick={() => {}}
                    onResultClick={() => {
                      if (isMapView || (!isMapView && secondMobileBreakpoint)) {
                        onSetSearchResult(result, [...resultCoords].reverse());
                      } else {
                        history.push(
                          `/${getSearchRouteFromCategory(searchCategory)}/${result.handle}`,
                        );
                      }
                    }}
                    isVisible={!searchResult?.id}
                  />
                );
              })}

            {isLoading && !searchResult && loadingCards.map(() => <SearchResultSkeleton />)}

            {/* Empty State */}
            {!isLoading && areSearchResultsEmpty && renderEmptyState()}

            {/* Floating Mobile Action */}
            {!isLoading && !searchResult && secondMobileBreakpoint && (
              <div
                onClick={() => setIsMapView(!isMapView)}
                className="icon-container"
              >
                <i className="fas fa-map" />
              </div>
            )}

            {/* Preview Panel */}
            {searchResult && (
              <SearchResultPreview
                showBack={true}
                previewData={{
                  title: searchResult.title,
                  handle: searchResult.handle ?? '',
                  description: searchResult.description || '',
                  cover_image_url: searchResult?.cover_image_url,
                  profile_image_url: searchResult.profile_image_url,
                  accepting_donations:
                    searchCategory === 'groups' || searchCategory === 'events'
                      ? ({ ...searchResult } as IGroup).accepting_donations
                      : undefined,
                  accepting_volunteers:
                    searchCategory === 'groups' || searchCategory === 'events'
                      ? ({ ...searchResult } as IGroup).accepting_volunteers
                      : undefined,
                  start_date:
                    searchCategory === 'events'
                      ? ({ ...searchResult } as IEventSummaryFE).start_date
                      : undefined,
                }}
                type={searchCategory}
                onClose={() => {
                  // RETAINS SCROLL POSITION WHEN GOING BACK TO LIST
                  // NEEDS TO CONSIDER WHICH CONTAIN TO SCROLLER TOO
                  // ON MOBILE IT DIFFERS FROM DESKTOP.
                  const result = { ...searchResult };
                  setSearchResult(undefined);
                  const element = document.getElementById(result?.id || '');
                  if (element) {
                    setTimeout(() => {
                      const top = Math.ceil(element.getBoundingClientRect().top) - 200;
                      searchGridRef.current?.scrollTo({ top });
                    }, 200);
                  }
                }}
              />
            )}
          </div>
        }

        {/* Responsible For Rendering Map View */}
        <div className={`map-wrapper ${isMapView ? '' : 'offset'}`.trim()}>
          {viewport && (
            <GoogleMapReact
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map }) => {
                mapRef.current = map;
                if (locationParam) {
                  locationHelpers
                    .getGeocodeInfoFromSearch(locationParam)
                    .then((value: IGeocodeResponse) => {
                      const { lat, lng } = value?.results[0]?.geometry?.location;
                      mapRef?.current?.panTo({ lat, lng });
                    });
                }
              }}
              bootstrapURLKeys={{ key: Config.web.REACT_APP_GOOGLE_API_KEY, libraries: ['places'] }}
              defaultCenter={{ lat: viewport.latitude, lng: viewport.longitude }}
              defaultZoom={uiConstants.search.defaultMapZoom}
              options={mapStyleOptions}
              onChange={onMapChange}
              zoom={zoom}
            >
              {renderMapChildren()}
            </GoogleMapReact>
          )}

          {/* MOBILE BOTTOM SHEET */}
          {isMobileDimensions && (
            <BottomSheet
              ref={sheetRef}
              open={isMapView && bottomSheetOpen}
              defaultSnap={({ lastSnap, snapPoints }) => snapPoints[0]}
              initialFocusRef={undefined}
              snapPoints={({ maxHeight }) => [maxHeight * 0.125, maxHeight - maxHeight / 4]}
              expandOnContentDrag={true}
              blocking={false}
              onSpringEnd={(event: any) => {
                if (sheetRef?.current?.height) {
                  const currentHeight = sheetRef.current.height;
                  const isOpen = currentHeight >= 200;
                  if (isOpen) {
                    if (searchResult) {
                      setTestSnapPoint(isOpen);
                    } else {
                      setTestSnapPoint(false);
                      setIsMapView(false);
                    }
                  } else {
                    setTestSnapPoint(false);
                  }
                }
              }}
            >
              <div className="bottom-sheet-container">
                {/* Has Search Result Bottom Sheet */}
                {!testSnapPoint && searchResult && (
                  <div className="simple-preview-wrapper">
                    <div className="simple-preview">
                      <Portrait
                        size={40}
                        source={searchResult?.profile_image_url}
                      />
                      <p>{searchResult?.title}</p>
                      <i
                        className="fa fa-times"
                        onClick={() => setSearchResult(undefined)}
                      ></i>
                    </div>{' '}
                  </div>
                )}
                {testSnapPoint && searchResult && (
                  <SearchResultPreview
                    showBack={false}
                    previewData={{
                      title: searchResult.title,
                      handle: searchResult.handle ?? '',
                      description: searchResult.description || '',
                      cover_image_url: searchResult?.cover_image_url,
                      profile_image_url: searchResult.profile_image_url,
                      accepting_donations:
                        searchCategory === 'groups' || searchCategory === 'events'
                          ? ({ ...searchResult } as IGroup).accepting_donations
                          : undefined,
                      accepting_volunteers:
                        searchCategory === 'groups' || searchCategory === 'events'
                          ? ({ ...searchResult } as IGroup).accepting_volunteers
                          : undefined,
                      start_date:
                        searchCategory === 'events'
                          ? ({ ...searchResult } as IEventSummaryFE).start_date
                          : undefined,
                    }}
                    type={searchCategory}
                    onClose={() => {
                      setSearchResult(undefined);
                      setBottomSheetOpen(false);
                    }}
                  />
                )}

                {/* No Search Result Bottom Sheet */}
                {!testSnapPoint && !searchResult && (
                  <div className="bottom-sheet-unselected">
                    {!isLoading &&
                      (keyword
                        ? `${searchResults.length} ${getSearchLabelFromCategory(searchCategory)} for ${keyword}`
                        : `${searchResults.length === 1 ? `1 ${getSearchLabelFromCategory(searchCategory)}` : `${searchResults.length} ${getSearchLabelFromCategory(searchCategory)}`}`)}
                    {isLoading && <span className="skeleton-loader"></span>}
                  </div>
                )}
              </div>
            </BottomSheet>
          )}

          {/* Use Current Location Button */}
          <div
            onClick={() => {
              getCurrentLocation();
            }}
            className="use-current-location"
          >
            <i className="fa fa-location"></i>
          </div>
        </div>
      </div>
      {/* Break this into component that renders by category TODO: THIS SHOULD BE BROKEN DOWN */}
      <Modal
        class="Search-Filter-Modal"
        show={showFilterModal}
        onClose={() => setShowFilterModal(!showFilterModal)}
      >
        <div className="search-filters">
          <FormSectionHeader text="Filters" />

          {/* Change Search Category */}
          <div className="filter-section">
            <div className="filter-title">Search Category</div>
            <div className="filter-content">
              <SearchEntitySelect
                searchCategory={searchCategory}
                setSearchCategory={setSearchCategory}
              />
            </div>
          </div>

          {searchCategory === 'groups' &&
            renderToggleFilterSection({
              label: 'Accepting Volunteers?',
              value: acceptingVolunteers,
              count: acceptingVolunteers ? 1 : 0,
              onToggle: () => {
                setAcceptingVolunteers(!acceptingVolunteers);
              },
              onClear: () => {
                setAcceptingVolunteers(false);
              },
            })}

          {(searchCategory === 'events' || searchCategory === 'volunteer') &&
            renderToggleFilterSection({
              label: searchCategory === 'events' ? 'Virtual Events' : 'Virtual',
              value: isVirtual,
              count: isVirtual ? 1 : 0,
              onToggle: () => {
                setIsVirtual(!isVirtual);
              },
              onClear: () => {
                setIsVirtual(false);
              },
            })}

          {searchCategory === 'events' && (
            <div className="filter-section">
              {selectedClassifications.length > 0 && (
                <span className="toggle-count"> {selectedClassifications.length} </span>
              )}
              {!!selectedClassifications.length && (
                <span
                  className="clear-filter-section"
                  onClick={() => setSelectedClassifications([])}
                >
                  <i className="fa fa-times-circle" />
                </span>
              )}
              <CollapsibleSection label="Cause Categories">
                <div className="filter-content column">
                  {classifications.map((classification) => (
                    <Checkbox
                      className=""
                      name=""
                      onChange={handleClassificationUpdate}
                      label={classification?.class_name || ''}
                      checked={selectedClassifications.includes(classification.id!)}
                      value={classification.id}
                    />
                  ))}
                </div>
              </CollapsibleSection>
            </div>
          )}
          {searchCategory === 'events' && (
            <div className="filter-section">
              {selectedSubClassifications.length > 0 && (
                <span className="toggle-count">{selectedSubClassifications.length} </span>
              )}
              {!!selectedSubClassifications.length && (
                <span
                  className="clear-filter-section"
                  onClick={() => setSelectedSubClassifications([])}
                >
                  <i className="fa fa-times-circle" />
                </span>
              )}
              <CollapsibleSection label="Cause Sub-Categories">
                <div className="filter-content column">
                  {subClassifications.map((subClass) => (
                    <Checkbox
                      className=""
                      name=""
                      onChange={handleSubClassificationUpdate}
                      label={subClass}
                      checked={selectedSubClassifications.includes(subClass)}
                      value={subClass}
                    />
                  ))}
                </div>
              </CollapsibleSection>
            </div>
          )}
          {searchCategory === 'volunteer' && (
            <div className="filter-section">
              {selectedSkills.length > 0 && (
                <span className="toggle-count"> {selectedSkills.length} </span>
              )}
              {!!selectedSkills.length && (
                <span
                  className="clear-filter-section"
                  onClick={() => setSelectedSkills([])}
                >
                  <i className="fa fa-times-circle" />
                </span>
              )}
              <CollapsibleSection label="Skills">
                <div className="filter-title">
                  <span
                    onClick={() => {
                      if (!isUserLoggedIn) return;
                      applyUserSkills();
                    }}
                  >
                    {' '}
                    {isUserLoggedIn ? 'Apply my skills' : ''}
                  </span>
                </div>
                <div className="filter-content column">
                  {skills.map((skill) => (
                    <Checkbox
                      className=""
                      name=""
                      onChange={handleSkillsUpdate}
                      label={skill.skill}
                      checked={selectedSkills.includes(skill.id!)}
                      value={skill.id!}
                    />
                  ))}
                </div>
              </CollapsibleSection>
            </div>
          )}
          <div className="filter-section">
            {selectedFocusAreas.length > 0 && (
              <span className="toggle-count"> {selectedFocusAreas.length} </span>
            )}
            {!!selectedFocusAreas.length && (
              <span
                className="clear-filter-section"
                onClick={() => setFocusAreas([])}
              >
                <i className="fa fa-times-circle" />
              </span>
            )}
            <CollapsibleSection label="Focus Areas">
              <div className="filter-title">
                <span
                  onClick={() => {
                    if (!isUserLoggedIn) return;
                    applyUserFocusAreas();
                  }}
                >
                  {' '}
                  {isUserLoggedIn ? 'Apply my focus areas' : ''}
                </span>
              </div>
              <div className="filter-content">
                {focusAreas.map((focusArea) => (
                  <div
                    className={
                      selectedFocusAreas.includes(focusArea.id!)
                        ? 'focus-area-chip selected'
                        : 'focus-area-chip'
                    }
                    onClick={() => {
                      onSelectFocusArea(focusArea.id || '');
                    }}
                  >
                    {selectedFocusAreas.includes(focusArea.id!) && (
                      <span>
                        <i className="fa fa-check"></i>
                      </span>
                    )}
                    {focusArea.cause}
                  </div>
                ))}
              </div>
            </CollapsibleSection>
          </div>
          {searchCategory === 'volunteer' && (
            <div className="filter-section">
              <CollapsibleSection label="Age Range">
                <div className="filter-content">
                  <div className="range-filter">
                    <Range
                      className="RangeSlider"
                      min={10}
                      max={100}
                      tipFormatter={(value: number) => (
                        <var data-var="search_tip_formatter_value">{value}</var>
                      )}
                      onAfterChange={updateAgeRange}
                      tipProps={{ visible: true, placement: 'bottom' }}
                      defaultValue={!!ageRange.length ? ageRange : [16, 80]}
                    />
                  </div>
                </div>
              </CollapsibleSection>
            </div>
          )}
          {searchCategory === 'volunteer' &&
            renderToggleFilterSection({
              label: 'Police Check Required',
              value: policeCheckRequired,
              count: policeCheckRequired ? 1 : 0,
              onToggle: () => {
                setPoliceCheckRequired(!policeCheckRequired);
              },
              onClear: () => setPoliceCheckRequired(false),
            })}
          {/* TODO WHAT DOES THIS MEAN IT MIGHT GET TRANSLATED WEIRD */}
          {searchCategory === 'volunteer' &&
            renderToggleFilterSection({
              label: 'OSSD Required',
              value: OSSDRequired,
              count: OSSDRequired ? 1 : 0,
              onToggle: () => {
                setOSSDRequired(!OSSDRequired);
              },
              onClear: () => setOSSDRequired(false),
            })}
        </div>
        <div className="search-filter-actions">
          <Button
            text="Clear All"
            onClick={() => resetFilters()}
          />
          <Button
            text={`Show Results (${searchResults.length})`}
            onClick={() => {
              setShowFilterModal(false);
            }}
          />
        </div>
      </Modal>
      {/* END FILTERS MODAL */}
    </div>
  );
}
