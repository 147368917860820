export namespace arrayHelpers {
  /**
   * Creates a dictionary type based on a provided object array and identifier.
   *
   * E.g.
   * Input: array - [{id: "a", field: "field"}, {id: "b", field: "field2"}]
   * Output: {
   *  a: {
   *    id: "a",
   *    field: "field"
   *  }
   *  b: {
   *    id: "b",
   *    field: "field2"
   *  }
   * }
   */
  export function createHashMap<T>(
    array: T[],
    key: string,
    initialValues?: Record<string, T>,
  ): Record<string, T> {
    return array.reduce(
      (obj, item) => ((obj[key] = item), obj),
      initialValues ?? ({} as Record<string, T>),
    );
  }

  /**
   * This is used as a custom type guard for filtering out null values
   *
   * Reason: TypeScript doesn't narrow the type after filtering
   * [1, null, 3].filter(o => o != null) -> (number | null)[]
   * [1, null, 3].filter(arrayHelpers.notEmpty) -> (number)[]
   */
  export function notEmpty<TValue>(value: TValue): value is NonNullable<TValue> {
    return value !== null && value !== undefined;
  }
}
