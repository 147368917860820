import React from 'react';
import { ICollapseItem } from '../ForOrganizations';
import { Link } from 'react-router-dom';
import './ForOrganizationsCollapseItem.scss';

export const ForOrganizationsCollapseItem = (props: ICollapseItem) => {
  const upLine = props?.keyValue && props.keyValue > 0 ? <hr /> : null;

  return (
    <article
      className="ForOrganizationsCollapseItem"
      key={props.keyValue}
    >
      {upLine}

      <h3>{props.title}</h3>
      <div className="description-container">
        <p>{props.description}</p>
        {props?.additionalLink && (
          <span className="link">
            <Link
              to={props.additionalLink.href}
              target={props.additionalLink?.linkNewTab ? '_blank' : undefined}
            >
              {props.additionalLink.title}
              <i className="far fa-angle-right" />
            </Link>
          </span>
        )}
      </div>
    </article>
  );
};
