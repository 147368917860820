import './SelectField.scss';

import React from 'react';
import Select, { ActionMeta } from 'react-select';
import { ISelectOption } from '../types';
import SelectOption from '../shared/SelectOption/SelectOption';

export interface ISelectFieldProps {
  options: Array<ISelectOption>;
  onChange: (option: ISelectOption | null) => void;
  name?: string;
  required?: boolean;
  selectedOption?: ISelectOption;
  label?: string;
  placeholder?: string;
  isMultiSelect?: boolean;
  /** If you want the context menu to appear outside of a modal */
  addContextMenuToPortal?: boolean;
  /** Lets user clear value via 'X' icon */
  isClearable?: boolean;
  /** Let's user disable the field */
  isDisabled?: boolean;
  /** The default selected value */
  defaultValue?: ISelectOption | null;
}

function SelectField(props: ISelectFieldProps) {
  const {
    defaultValue,
    label,
    selectedOption,
    placeholder,
    addContextMenuToPortal,
    options,
    isDisabled,
  } = props;
  const doesListHaveIconOrImage = !!props.options.find(
    (option) => !!option.icon || !!option.imageURL,
  );

  function handleOnSelectChange(
    option: ISelectOption | null,
    actionMeta: ActionMeta<ISelectOption>,
  ) {
    props.onChange(option || null);
  }

  return (
    <div className="SelectFieldContainer">
      {!!label && <label>{label}</label>}
      <Select
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        className="SelectField"
        options={options}
        menuPortalTarget={addContextMenuToPortal ? document.body : undefined}
        menuPosition={addContextMenuToPortal ? 'fixed' : undefined}
        isClearable={true}
        placeholder={placeholder}
        onChange={handleOnSelectChange}
        value={selectedOption}
        required={props.required}
        name={props.name}
        components={{
          Option: (props) => (
            <SelectOption
              {...props}
              hasIconPadding={doesListHaveIconOrImage}
            />
          ),
        }}
        styles={{
          menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999,
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#3A3A3A' : '#D0D1D1',
            boxShadow: 'none',
            '&:hover': {
              borderColor: '#A9AAAC',
            },
          }),
        }}
      />
    </div>
  );
}

export default SelectField;
