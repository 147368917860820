import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { userSelectors } from '../../selectors/user';

import './Notification.scss';
import { markRead } from '../../actions/notifications';
import Portrait from '../Portrait/Portrait';
import { timeSince } from '../../helpers';
import { localizeHelpers } from '../../localizeHelpers';
import { IUserNotification } from '@gigit/interfaces';

interface IProps extends WithTranslation {
  notification: IUserNotification;
  markRead(id: string): void;
  locale: string;
}

interface IState {}

class Notification extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      show: false,
    };
  }

  composeTitle() {
    const type = this.props.notification.message;
    return type;
  }

  composeIcon() {
    const type = this.props.notification.notification_type;

    switch (type) {
      case 'chat_message':
        return 'fas fa-comment-alt';
      case 'user_registered':
        return 'fas fa-info';
      case 'team_created':
        return 'fas fa-user';
      default:
    }
  }

  renderTimeSince(time: Date) {
    let t = new Date(time);
    const getTime = t.getTime() / 1000;
    let timeSinceResult = timeSince(getTime);

    return (
      <div className="time">
        <var
          data-var="time_since_interval"
          pluralize={timeSinceResult.interval.toString()}
        >
          {localizeHelpers.formatNumber(timeSinceResult.interval || 0, this.props.locale)}
        </var>
        {timeSinceResult.interval > 1
          ? ` ${timeSinceResult.unit}s ago`
          : ` ${timeSinceResult.unit} ago`}
      </div>
    );
  }

  handleMarkAsRead = () => {
    if (this.props.notification.id) {
      this.props.markRead(this.props.notification.id);
    } else {
      return;
    }
  };

  render() {
    return (
      <div
        onClick={this.handleMarkAsRead}
        className={this.props.notification.read ? 'Notification read' : 'Notification unread'}
      >
        <div className="notification-portrait">
          {this.props.notification.from_user &&
            this.props.notification.from_user.profile_image_url && (
              <Portrait
                currentImage={this.props.notification.from_user.profile_image_url}
                size={50}
              />
            )}
          {this.props.notification.from_user &&
            !this.props.notification.from_user.profile_image_url && <Portrait size={50} />}
        </div>
        <div className="info">
          <p className="title">{this.composeTitle()}</p>
          <span className="timestamp">
            {this.props.notification.created_at &&
              this.renderTimeSince(this.props.notification.created_at)}
          </span>
        </div>
        <div className={this.props.notification.read ? 'status read' : 'status unread'}>
          <i
            id="font-icon"
            className="fas fa-circle"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    notificationsState: store.notificationsState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  markRead: markRead,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(Notification),
);
