import './HubAuction.scss';
import React, { useEffect, useState } from 'react';
import { IAuctionItemSummary, IHub } from '@gigit/interfaces';
import { IActiveFilter, ISortSettings } from '../../../interfaces';
import { uiConstants } from '../../../constants';
import { IPopupMenuItem, IShowPopupConfig } from '../../shared/PopupMenu/PopupMenu';
import useToastDispatcher from '../../../hooks/useToaster';
import { hubRequestActions, userRequestActions } from '../../../requestActions';
import HubFilter from '../Shared/HubFilterBar/HubFilterBar';
import HubToggleButtons from '../Shared/HubToggleButtons/HubToggleButtons';
import Button from '../../Button/Button';
import Loader from '../../Loader/Loader';
import { formatQuery } from '../../../helpers';
import HubAuctionEmptyState from './HubAuctionEmptyState/HubAuctionEmptyState';
import HubAuctionSingleItem from './HubAuctionSingleItem/HubAuctionSingleItem';
import HubAuctionDetailItemModal from './HubAuctionDetailItemModal/HubAuctionDetailItemModal';
import HubAuctionSettingsModal from './HubAuctionSettingsModal/HubAuctionSettingsModal';
import { IThemeStyleObjectsByType } from '../Hub';
import { Constants } from '@gigit/constants';
import queryString from 'query-string';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

interface IProps {
  adminMode: boolean;
  hub: IHub | null;
  theme?: IThemeStyleObjectsByType;
  userHasEditPermissions: boolean;
}

const sortByNew = { label: 'Newest', value: 'desc' } as { label: string; value: 'asc' | 'desc' };
const sortByOld = { label: 'Oldest', value: 'asc' } as { label: string; value: 'asc' | 'desc' };

const HubAuction: React.FC<IProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchDebounce, setSearchDebounce] = useState<ReturnType<typeof setTimeout>>();
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string>('');
  const [showContextMenuSelectCauses, setShowContextMenuSelectCauses] = useState<boolean>(false);
  const [selectCauses, setSelectCauses] = useState<string | null>(null);
  const [showContextMenuSortBy, setShowContextMenuSortBy] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<{ label: string; value: 'asc' | 'desc' }>(sortByNew);
  const [page, setPage] = useState<number>(0);
  const [sortValue, setSortValue] = useState<ISortSettings[]>([
    { id: 'created_at', order: sortByNew.value },
  ]);
  const [contextMenuItemsSelectCauses, setContextMenuItemsSelectCauses] = useState<
    IPopupMenuItem[]
  >([
    {
      id: '',
      label: 'Select a Cause',
      onClick: () => {
        setSelectCauses(null);
      },
    },
  ]);
  const [itemFilter, setItemFilter] = useState<'all' | 'hub' | 'partner_groups'>('all');
  const [showDetailItemModal, setShowDetailItemModal] = useState<boolean>(false);
  const [activeAuctionItem, setActiveAuctionItem] = useState<IAuctionItemSummary | null>(null);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [auctionItems, setAuctionItems] = useState<IAuctionItemSummary[]>([]);

  const location = useLocation();
  const history = useHistory();

  const { dispatchToastError } = useToastDispatcher();

  const allowedRoles = [uiConstants.role.admin, uiConstants.role.member];

  useEffect(() => {
    const locationSearchParams = queryString.parse(location.search);

    if (locationSearchParams.item) {
      if (auctionItems.length) {
        const selectedAuctionItem = auctionItems.find(
          (auctionItem) => auctionItem.id == locationSearchParams.item,
        );
        if (selectedAuctionItem) {
          setActiveAuctionItem(selectedAuctionItem);
          setShowDetailItemModal(true);
          window.history.pushState({ path: location.pathname }, '', location.pathname);
        }
      }
    }
  }, [location.search, auctionItems]);

  function getHubAuctionItems(append = false) {
    if (props?.hub?.id) {
      setIsLoading(true);

      let filters = [
        {
          value: Constants.auction_item_status.running,
          id: 'status.code',
          type: 'text',
          label: 'status',
        },
      ] as IActiveFilter[];

      if (searchValue !== '' && searchValue !== null) {
        filters.push({
          label: 'name',
          id: 'name',
          type: 'text',
          value: searchValue,
        });
      }

      if (selectCauses !== null) {
        filters.push({
          label: 'group_title',
          id: 'group_title',
          type: 'text',
          value: selectCauses,
        });
      }

      hubRequestActions
        .getHubAuctionItems(
          props.hub.id,
          formatQuery({
            limit: uiConstants.hubs.listViewPageSize,
            skip: String(page * +uiConstants.hubs.listViewPageSize),
            filters,
            sort: sortValue,
            type: itemFilter,
          }),
        )
        .then((res) => {
          if (append && res && res?.length > 0) {
            setAuctionItems([...auctionItems, ...res]);
          } else {
            setAuctionItems(res);
          }
        })
        .catch((error) => dispatchToastError(error, 'Get Hub Auction Items'))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  function loadMoreItems() {
    setPage(page + 1);
    getHubAuctionItems(true);
  }

  function getHubPartners() {
    if (props?.hub?.id) {
      hubRequestActions
        .getHubPartners(props.hub.id)
        .then((partners) => {
          setContextMenuItemsSelectCauses([
            ...contextMenuItemsSelectCauses,
            ...partners.map((partner) => {
              return {
                id: partner.id,
                label: partner.title,
                onClick: () => {
                  setSelectCauses(partner.title);
                },
              };
            }),
          ]);
        })
        .catch((error) => dispatchToastError(error, 'Get Hub Partners'));
    }
  }

  function handleSortBy() {
    setPage(0);
    setSortValue([{ id: 'created_at', order: sortBy.value }]);
  }

  // Config
  const popupMenuConfigSortBy: IShowPopupConfig = {
    showMenu: showContextMenuSortBy,
    setShowMenu: setShowContextMenuSortBy,
    position: {
      type: 'bottom',
    },
  };
  const contextMenuItemsSortBy: IPopupMenuItem[] = [
    {
      id: uiConstants.sortOptions.createdAtAscending,
      label: sortByNew.label,
      isSelected: sortBy.value === sortByNew.value,
      onClick: () => {
        setSortBy(sortByNew);
        handleSortBy();
      },
    },
    {
      id: uiConstants.sortOptions.createdAtDescending,
      label: sortByOld.label,
      isSelected: sortBy.value === sortByOld.value,
      onClick: () => {
        setSortBy(sortByOld);
        handleSortBy();
      },
    },
  ];

  const popupMenuConfigSelectCauses: IShowPopupConfig = {
    showMenu: showContextMenuSelectCauses,
    setShowMenu: setShowContextMenuSelectCauses,
    position: {
      type: 'bottom',
    },
  };

  async function getHubRoles() {
    const userRoleName = await userRequestActions.getUserHubRole(props.hub?.id || '');
    setUserRole(userRoleName.role_name);
  }

  useEffect(() => {
    getHubRoles();
    getHubPartners();
  }, []);

  useEffect(() => {
    setPage(0);
    getHubAuctionItems();
  }, [searchValue]);

  useEffect(() => {
    setPage(0);
    getHubAuctionItems();
  }, [itemFilter, sortValue, selectCauses]);

  return (
    <div className="HubAuction">
      <HubFilter
        onClickEdit={() => setShowSettingsModal(true)}
        adminMode={props.adminMode}
        header="Auction"
        searchValue={searchValue || ''}
        onSearch={setSearchValue}
        searchPlaceholder="Search items by name"
        sortByConfig={[
          {
            menuItems: contextMenuItemsSelectCauses,
            showMenuConfig: popupMenuConfigSelectCauses,
            className: 'selectCauses',
            label: 'Causes',
            valueLabel: selectCauses === null ? 'Select a Cause' : selectCauses,
            popupMenuClass: 'HubAuction-popupMenu-selectCauses',
            showMenu: showContextMenuSelectCauses,
            onClick: () => {
              setShowContextMenuSelectCauses(true);
            },
            onSelect: () => {
              setShowContextMenuSelectCauses(false);
            },
          },
          {
            menuItems: contextMenuItemsSortBy,
            showMenuConfig: popupMenuConfigSortBy,
            className: 'sortBy',
            label: 'Sort By',
            valueLabelIcon: 'far fa-clock',
            valueLabel: sortBy.label,
            popupMenuClass: 'HubAuction-popupMenu-sortBy',
            showMenu: showContextMenuSortBy,
            onClick: () => {
              setShowContextMenuSortBy(true);
            },
            onSelect: () => {
              setShowContextMenuSortBy(false);
            },
          },
        ]}
      />
      <hr />

      <HubToggleButtons
        activeStyle={props.theme?.secondaryBgWithText}
        buttons={[
          {
            text: 'All Items',
            active: 'all' === itemFilter,
            onClick: () => {
              setItemFilter('all');
            },
          },
          ...(allowedRoles.includes(userRole)
            ? [
                {
                  text: 'Company Items',
                  active: 'hub' === itemFilter,
                  onClick: () => {
                    setItemFilter('hub');
                  },
                },
              ]
            : []),
          {
            text: 'Partner Items',
            active: 'partner_groups' === itemFilter,
            onClick: () => {
              setItemFilter('partner_groups');
            },
          },
        ]}
      />

      {!isLoading && auctionItems.length > 0 && (
        <>
          <div className="list-container">
            {auctionItems.map((item, index) => (
              <HubAuctionSingleItem
                openAuctionItemDetailModal={() => {
                  setActiveAuctionItem(item);
                  setShowDetailItemModal(true);
                }}
                auctionItem={item}
                key={index}
              />
            ))}
          </div>
          {auctionItems.length === +uiConstants.hubs.listViewPageSize * (page || 1) && (
            <div className="load-more-container">
              <Button
                text="Load more"
                type="button"
                buttonType="outline-dark"
                onClick={() => loadMoreItems()}
              />
            </div>
          )}
        </>
      )}

      {!isLoading && auctionItems.length < 1 && (
        <HubAuctionEmptyState
          userHasEditPermissions={props.userHasEditPermissions}
          hub={props.hub}
          onlyActive={true}
        />
      )}

      {isLoading && <Loader loading={isLoading} />}

      {activeAuctionItem && (
        <HubAuctionDetailItemModal
          show={showDetailItemModal}
          onCloseDetailModal={() => {
            setShowDetailItemModal(false);
            setActiveAuctionItem(null);
          }}
          hubHandle={props.hub?.handle || null}
          auctionItem={activeAuctionItem}
          getHubAuctionItems={() => getHubAuctionItems()}
          hubName={props.hub?.title}
          hubLogoUrl={props.hub?.profile_image_url}
        />
      )}

      {props.adminMode && (
        <HubAuctionSettingsModal
          hub={props.hub}
          onCloseSettingsModal={() => setShowSettingsModal(false)}
          showSettingsModal={showSettingsModal}
        />
      )}
    </div>
  );
};

export default HubAuction;
