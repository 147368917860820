import { Reducer } from 'redux';
import { ToasterActions, ToasterActionTypes } from '../actions/toaster';
import { IToast } from '../interfaces';

export interface IToasterState {
  toasts: Array<IToast>;
}

export const initialToasterState: IToasterState = {
  toasts: [],
};

export const toasterReducer: Reducer<IToasterState, ToasterActions> = (
  state = initialToasterState,
  action,
) => {
  switch (action.type) {
    case ToasterActionTypes.UPDATE_TOAST: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
