import React, { FC, Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './CardCommonGroup.scss';
import { CardCommon, CardHeader, CardContent, CardActions, ICardAction } from '../CardCommon';
import { IAddress, IGroup, IEventSummaryFE } from '@gigit/interfaces';
import { IAppState } from '../../store';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../selectors/user';
import useToastDispatcher from '../../hooks/useToaster';
import Modal from '../Modal/Modal';
import Share from '../Share/Share';
import {
  typeHelpers,
  formatCurrency,
  defaultCurrency,
  capitalizeString,
  OwnerType,
} from '../../helpers';
import { Config } from '@gigit/config';
import { uiConstants } from '../../constants';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import PopupMenu, { IPopupMenuItem } from '../shared/PopupMenu/PopupMenu';
import { CardCommonGroupSkeleton } from './CardCommonGroupSkeleton';
import { groupRequestActions, userRequestActions } from '../../requestActions';
import { Constants } from '@gigit/constants';
import editImg from '../../assets/edit.svg';
import settingsImg from '../../assets/settings.svg';
import Portrait from '../Portrait/Portrait';

export enum EViewTypeCardCommonGroup {
  GRID,
  LIST,
}

export enum EStyleTypeCardCommonGroup {
  MANAGE,
  DISCOVER,
  SCHEDULE,
  HUB,
}

interface IProps {
  group: IGroup;
  viewType: EViewTypeCardCommonGroup;
  styleType: EStyleTypeCardCommonGroup;
  isUserLoggedIn?: boolean;
  locale?: string;
  scheduleMenuItems?: IPopupMenuItem[];
  canManage?: boolean;
  canEdit?: boolean;
  locations?: IAddress[];
  loading?: boolean;
  getIsFollowing?: () => Promise<boolean>;
  deleteItem?: () => void;
  openDetailModal?: (group_id: string) => void;
  myPagesCard?: boolean;
}

export const CardCommonGroup: FC<IProps> = (props: IProps) => {
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { group } = props;

  const history = useHistory();
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const menuItems: IPopupMenuItem[] =
    props.styleType === EStyleTypeCardCommonGroup.MANAGE
      ? [
          {
            id: 'share',
            label: 'Share',
            onClick: () => {
              handleShare();
            },
          },
          {
            id: 'delete',
            label: 'Delete',
            onClick: () => {
              props?.deleteItem?.();
            },
          },
        ]
      : props.scheduleMenuItems || [];

  useEffect(() => {
    if (isUserLoggedIn && !props.myPagesCard) {
      doesUserFollowGroup(`${group.id}`).then((res) => setIsFollowing(res));
    }
  }, [props.group]);

  const handleShare = () => {
    user?.id ? setShowShareModal(true) : history.push('/login');
  };

  async function doesUserFollowGroup(groupId: string): Promise<boolean> {
    if (user?.id) {
      try {
        const result = await userRequestActions.getUserGroupRole(groupId);

        return result?.user_capacity
          ? result?.user_capacity.includes(Constants.user_capacity.follower)
          : false;
      } catch (error) {
        dispatchToastError(error, 'Does User Follow Group');
      }
    }

    return false;
  }

  async function toggleFollow(groupId: string) {
    if (user?.id) {
      const isFollowing = await doesUserFollowGroup(groupId);

      try {
        if (isFollowing) {
          await groupRequestActions.unfollowGroup(groupId);
          setIsFollowing(false);

          dispatchToastSuccess('Successfully unfollowed Group', 'Unfollow Group');
        } else {
          await groupRequestActions.followGroup(groupId);
          setIsFollowing(true);

          dispatchToastSuccess('Successfully followed Group', 'Follow Group');
        }
      } catch (error) {
        dispatchToastError(error, `${isFollowing ? 'Unfollow Group' : 'Follow Group'}`);
      }
    } else {
      history.push('/login');
    }
  }

  const renderDiscoverContent = () => {
    return (
      <CardContent className="group-content">
        <div className="card-title">
          <Link
            notranslate="yes"
            to={`/group/${group.handle}`}
          >
            {group.title}
          </Link>
        </div>
        <div className="card-info">
          {group?.locations && group?.locations[0] ? (
            <>
              {group?.locations[0].city && (
                <React.Fragment>
                  <span>{group?.locations[0].city ? group?.locations[0].city : ''}</span>
                  <span className="dot" />
                </React.Fragment>
              )}
              <span>{group?.locations[0].country ? group?.locations[0].country : ''}</span>
            </>
          ) : (
            'No location available'
          )}
        </div>
        <div className="group-causes">
          <div className="causes-container">
            {group.focus_areas &&
              group.focus_areas.map((focusArea, index) => {
                if (index < 4) {
                  return (
                    <Portrait
                      size={30}
                      hideMask={true}
                      key={index}
                      currentImage={focusArea.icon_url ? `${focusArea.icon_url}` : ''}
                    />
                  );
                }

                return <Fragment key={index} />;
              })}
            {group.focus_areas && group.focus_areas.length > 4 && (
              <span className="empty-portrait">
                <var data-var="groups_involved_length">+{group.focus_areas.length - 4}</var>
              </span>
            )}
            {group.focus_areas?.length === 0 &&
              [1, 2, 3, 4, 5].map((e, i) => {
                return (
                  <div
                    key={i}
                    className="no-causes"
                  />
                );
              })}
          </div>
          {group.focus_areas && (
            <span className={group.focus_areas?.length > 0 ? 'areas' : 'no-areas'}>
              {group.focus_areas?.length > 0 ? 'Focus Areas' : 'No Focus Areas'}
            </span>
          )}
        </div>
      </CardContent>
    );
  };

  const renderManageContent = () => {
    return (
      <CardContent className={`group-content`}>
        <div className="card-title">
          <Link
            notranslate="yes"
            to={`/group/${group.handle}`}
          >
            {group.title}
          </Link>
        </div>
        {group.created_at && (
          <div className="card-info">
            Created on&nbsp;
            {localizeHelpers.formatDate(group.created_at, LocaleDateFormats.LL, locale)}
          </div>
        )}

        <div className={`state-label ${group.status}`}>{capitalizeString(`${group.status}`)}</div>

        {(props.canEdit || props.canManage) && (
          <div className="header-menu">
            {props.canEdit && (
              <div onClick={() => history.replace({ pathname: `/group/${group.handle}` })}>
                <img src={editImg}></img>
              </div>
            )}
            {props.canManage && (
              <div
                key={1}
                onClick={() => history.replace({ pathname: `/group/${group.handle}/admin` })}
              >
                <img src={settingsImg}></img>
              </div>
            )}
          </div>
        )}

        <PopupMenu
          showMenuConfig={{
            showMenu: showMenu,
            setShowMenu: setShowMenu,
            position: {
              type: 'bottom',
            },
          }}
          menuItems={menuItems}
          popupMenuClass="PopupMenu-Volunteer-Card"
          className={`menu ${showMenu ? 'show' : ''}`}
          onClick={() => setShowMenu(!showMenu)}
          onSelect={() => setShowMenu(false)}
        >
          <i className="fal fa-ellipsis-v" />
        </PopupMenu>

        <div className="one-line"></div>
      </CardContent>
    );
  };

  return (
    <React.Fragment>
      {!props.loading ? (
        <CardCommon
          className={`group-container ${EStyleTypeCardCommonGroup[props.styleType]} ${EViewTypeCardCommonGroup[props.viewType]}`}
          onClick={() => {
            props?.openDetailModal?.(group.id);
          }}
          style={{ cursor: props?.openDetailModal ? 'pointer' : undefined }}
        >
          <CardHeader
            profileImg={group.profile_image_url}
            coverImg={group.cover_image_url}
          />
          {(props.styleType === EStyleTypeCardCommonGroup.DISCOVER ||
            props.styleType === EStyleTypeCardCommonGroup.HUB) &&
            renderDiscoverContent()}
          {props.styleType === EStyleTypeCardCommonGroup.MANAGE && renderManageContent()}
          {(props.styleType === EStyleTypeCardCommonGroup.DISCOVER ||
            props.styleType === EStyleTypeCardCommonGroup.SCHEDULE) && (
            <CardActions
              className="group-actions"
              actions={[
                {
                  label: `${isFollowing ? 'Unfollow' : 'Follow'}`,
                  icon: 'far fa-level-down-alt',
                  visibility: !props.group.external_id?.GlobalGiving,
                  onClick: () => toggleFollow(`${group.id}`),
                },
                {
                  label: 'Share',
                  icon: 'fas fa-share-alt',
                  visibility: true,
                  onClick: () => handleShare(),
                },
              ]}
            />
          )}
        </CardCommon>
      ) : (
        <CardCommonGroupSkeleton
          styleType={props.styleType}
          viewType={props.viewType}
        />
      )}
      <Modal
        class="share-modal"
        show={showShareModal}
        onClose={() => setShowShareModal(false)}
      >
        <Share
          owner_object={typeHelpers.createOwnerObject(
            uiConstants.ownerType.group as OwnerType,
            group,
          )}
          url={`${Config.web.REACT_APP_BASE_URL}/${uiConstants.ownerType.group}/${group.handle}`}
        />
      </Modal>
    </React.Fragment>
  );
};
