import { Dispatch } from 'redux';
import { Config } from '@gigit/config';
import { IGeolocationState } from '../reducers/geolocation';

export enum GeolocationActionTypes {
  UPDATE_GEOLOCATION = 'UPDATE_GEOLOCATION',
}

export interface IUpdateGeolocationAction {
  type: GeolocationActionTypes.UPDATE_GEOLOCATION;
}

export type GeolocationActions = IUpdateGeolocationAction;

const GEOLOCATION_EXPIRATION_TIME = 60 * 60 * 1000; // 1 hour

export const updateGeolocation = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGeolocationLoading: true,
      type: GeolocationActionTypes.UPDATE_GEOLOCATION,
    });
    const geoLocationState: IGeolocationState = getState().geolocationState;

    const geolocation = navigator.geolocation;

    if (!geolocation) {
      dispatch({
        isGeolocationLoading: false,
        error: 'ERROR.GEOLOCATION.NOT_AVAILABLE',
        type: GeolocationActionTypes.UPDATE_GEOLOCATION,
      });
    } else {
      geolocation.getCurrentPosition(
        (response) => {
          dispatch({
            lat: response.coords.latitude,
            lng: response.coords.longitude,
            error: '',
            type: GeolocationActionTypes.UPDATE_GEOLOCATION,
          });

          const isGeolocationCacheExpired =
            new Date().getTime() > geoLocationState.lastUpdated + GEOLOCATION_EXPIRATION_TIME;

          if (geoLocationState.isCached && !isGeolocationCacheExpired) {
            return;
          }

          fetch(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
              response.coords.latitude +
              ',' +
              response.coords.longitude +
              '&key=' +
              Config.web.REACT_APP_GOOGLE_API_KEY,
          )
            .then((res) => res.json())
            .then((response) => {
              let apiLocality =
                response.results.find((i: any) => i.types.includes('locality')) || '';

              if (
                apiLocality &&
                apiLocality.address_components[0]?.long_name &&
                apiLocality.address_components[2]?.short_name
              ) {
                apiLocality =
                  `${apiLocality.address_components[0]?.long_name || ''}, ${apiLocality.address_components[2]?.short_name}` ||
                  '';
              } else {
                apiLocality = '';
              }

              if (response.results.length > 0) {
                dispatch({
                  locality: apiLocality,
                  isGeolocationLoading: false,
                  formattedAddress: response.results[6]?.formatted_address,
                  isCached: true,
                  lastUpdated: new Date().getTime(),
                  type: GeolocationActionTypes.UPDATE_GEOLOCATION,
                });
              } else {
                dispatch({
                  isGeolocationLoading: false,
                  isCached: false,
                  lastUpdated: 0,
                  error: 'ERROR.GEOLOCATION.LOCATION_NOT_FOUND',
                  type: GeolocationActionTypes.UPDATE_GEOLOCATION,
                });
              }
            });
        },
        () => {
          dispatch({
            isGeolocationLoading: false,
            type: GeolocationActionTypes.UPDATE_GEOLOCATION,
          });
        },
      );
    }
  };
};
