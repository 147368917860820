import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

import './Prompt.scss';

interface IProps {
  title: string;
  message: string;

  link?: string;
  linkText?: string;
  externalLink?: boolean;

  yesMessage: string;

  /** Called when the "Yes" button is clicked. */
  onYes(): void;

  /** Class to apply to the yes button icon. This should be a fontawesome class. */
  yesClass?: string;

  /** How should the yes button be displayed? */
  yesStyle?: 'normal' | 'delete';

  cancelMessage: string;

  /** Called when the prompt is closed, either by clicking 'X', cancel or also when the "yes" button is clicked. */
  onClose(): void;
  show?: boolean;

  notranslateMessage?: 'yes';
}

interface IState {}

/** A generic prompt component for displaying prompts to the user. */
export class Prompt extends React.Component<IProps, IState> {
  render() {
    const yesButtonClass = `prompt-button-${this.props.yesStyle}`;

    return (
      <Modal
        class="prompt-modal"
        onClose={this.props.onClose}
        show={this.props.show !== undefined ? this.props.show : true}
      >
        <div className="prompt">
          <div className="prompt-title">
            <div className="prompt-inner">{this.props.title}</div>
            <div
              className="prompt-message"
              notranslate={this.props.notranslateMessage}
            >
              {this.props.message}
              {this.props.externalLink
                ? this.props.link && (
                    <span>
                      {' '}
                      <a
                        target="_blank"
                        href={this.props.link}
                      >
                        {this.props.linkText}
                      </a>
                    </span>
                  )
                : this.props.link && (
                    <span>
                      {' '}
                      <Link to={this.props.link}>{this.props.linkText}</Link>
                    </span>
                  )}
            </div>
          </div>
          <div className="prompt-info">
            <div className="prompt-actions">
              <div className="prompt-button-cancel">
                <Button
                  onClick={() => this.props.onClose()}
                  text={this.props.cancelMessage}
                />
              </div>
              <div className={yesButtonClass}>
                <Button
                  onClick={() => {
                    this.props.onYes();
                    this.props.onClose();
                  }}
                  text={this.props.yesMessage}
                  icon={this.props.yesClass}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
