import React from 'react';
import { combineClassNames } from '../../helpers';

import './Loader.scss';

interface IProps {
  loading: boolean;
  className?: string;
}

interface IState {}

class Loader extends React.Component<IProps, IState> {
  render() {
    if (!this.props.loading) {
      return null;
    } else {
      return (
        <div className={combineClassNames('Loader', this.props.className)}>
          <i className="fad fa-spinner fa-spin" />
        </div>
      );
    }
  }
}

export default Loader;
