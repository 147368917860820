import React, { FC, useEffect, useState } from 'react';
import './HubSharedDetailsModal.scss';
import { IEvent, IEventSummaryFE, IGroup, IHub, IStoreItem } from '@gigit/interfaces';
import Modal from '../../../Modal/Modal';
import Portrait from '../../../Portrait/Portrait';
import Button from '../../../Button/Button';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import { uiConstants } from '../../../../constants';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { groupRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import { localizeHelpers } from '../../../../localizeHelpers';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { IUserState } from '../../../../reducers/user';
import Login from '../../../../routes/Login/Login';
import axios from 'axios';
import { routes, swapRouteParams } from '../../../../helpers';

interface IProps {
  hub: IHub | null;
  show: boolean;
  type: string;
  selectedItem: IGroup | IEventSummaryFE | null;
  onClose(): void;
}

type TCustomEvent = IEventSummaryFE & { event?: IEvent };

const HubSharedDetailsModal: FC<IProps> = (props: IProps) => {
  const userState: IUserState = useSelector((state: IAppState) => state.userState);
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const [item, setItem] = useState<IEventSummaryFE | IGroup>();
  const [isExternal, setIsExternal] = useState<boolean>(false);
  const [externalLink, setExternalLink] = useState<string>('');
  const [isVolunteerMatch, setIsVolunteerMatch] = useState<boolean>(false);
  const [updatedProjectCount, setUpdatedProjectCount] = useState<number>(0);
  const [donationLoading, setDonationLoading] = useState<boolean>(false);
  const [eventComponentMetrics, setEventComponentMetrics] = useState<any[]>([]);
  const [storeItems, setStoreItems] = useState<IStoreItem[]>([]);
  const [fundraiseURL, setFundraiseURL] = useState<string>('');
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  useEffect(() => {
    if (!props.show) {
      setIsExternal(false);
      setIsVolunteerMatch(false);
    }
  }, [props.show]);

  useEffect(() => {
    if (
      eventComponentMetrics &&
      props.type === uiConstants.ownerType.event &&
      props.type &&
      props.selectedItem
    ) {
      checkEventStoreItems(props.selectedItem as IEventSummaryFE);
      getUserFundraiseStatus(props.selectedItem as IEventSummaryFE);
    }
  }, [eventComponentMetrics]);

  useEffect(() => {
    setDonationLoading(true);
    if (props.type && props.selectedItem) {
      if (props.type === uiConstants.ownerType.event) {
        setItem(props.selectedItem as IEventSummaryFE);
        getEventComponentMetrics(props.selectedItem as IEventSummaryFE);
      } else if (props.type === uiConstants.ownerType.group) {
        setItem(props.selectedItem as IGroup);

        const selected = props.selectedItem as IGroup;
        if (!!selected.external_id?.GlobalGiving || !!selected.external_id?.VolunteerMatch) {
          setIsExternal(true);
          if (!!selected.external_id?.VolunteerMatch) {
            setIsVolunteerMatch(true);
            setDonationLoading(false);
          }

          if (selected.gg_external_url) {
            setExternalLink(selected.gg_external_url);
          }
        } else {
          setDonationLoading(false);
        }
      }
    }

    return () => {
      setIsExternal(false);
      setIsVolunteerMatch(false);
      setExternalLink('');
    };
  }, [props.type, props.selectedItem]);

  useEffect(() => {
    if (!isVolunteerMatch && isExternal) {
      if (props.type === uiConstants.ownerType.group) {
        const selected = props.selectedItem as IGroup;
        groupRequestActions
          .updateGGProjects(selected.id)
          .then((res) => {
            setUpdatedProjectCount(res.gg_active_projects_count);
            setDonationLoading(false);
          })
          .catch((error) => {
            dispatchToastError(error, 'Cause Projects');
            setUpdatedProjectCount(selected.gg_active_projects_count || 0);
            setDonationLoading(false);
          });
      }
    }
  }, [isExternal, isVolunteerMatch]);

  function getEventHandle(event: TCustomEvent) {
    if (event?.event && event.type !== uiConstants.ownerType.event) {
      return `/${uiConstants.ownerType.event}/${event.event.handle}/${event.type}/${event.handle}`;
    } else {
      return `/${uiConstants.ownerType.event}/${event.handle}`;
    }
  }

  function getCauseLink(item: IGroup | TCustomEvent | null) {
    if (props.type == uiConstants.ownerType.group) {
      const selected = item as IGroup;
      let a = document.createElement('a');
      if (isExternal && externalLink) {
        a.href = externalLink;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
      } else if (!isExternal) {
        a.href = `/${uiConstants.ownerType.group}/${selected.handle}`;
        document.body.appendChild(a);
        a.click();
      }
    } else {
      const selected = item as TCustomEvent;
      let a = document.createElement('a');
      a.href = getEventHandle(selected);
      document.body.appendChild(a);
      a.click();
    }
  }

  function handleGroupFollow() {
    let item = props.selectedItem as IGroup;
    groupRequestActions
      .followGroup(item.id)
      .then((resp) => {
        dispatchToastSuccess(
          localizeHelpers.translate('You are now following {{name}}.', { name: item.title }),
          'Follow a Cause',
        );
      })
      .catch((err) => {
        if (err.response.status == 401) {
          setShowLoginModal(true);
        } else {
          dispatchToastError(err, 'Follow a Cause');
        }
      });
  }

  function getFundraiseUrl(id: string) {
    if (userState.isLoggedIn) {
      return `/onboarding/${uiConstants.ownerType.event}/eventTypeSelection?groupId=${id}&type=custom&createdFor=obo_fundraiser`;
    } else {
      return (
        '/login?redirect=' +
        encodeURIComponent(
          `/onboarding/${uiConstants.ownerType.event}/eventTypeSelection?groupId=${id}&type=custom&createdFor=obo_fundraiser`,
        )
      );
    }
  }

  function renderDonateButton() {
    if (donationLoading) {
      return (
        <Button
          className="cause-action full donation-loading"
          loading={donationLoading}
        />
      );
    }

    if (item && item.accepting_donations && !isVolunteerMatch) {
      return (
        <Link
          className="cause-action full"
          to={`/${uiConstants.ownerType.group}/${item.handle}/donate`}
        >
          Donate
        </Link>
      );
    }

    return <></>;
  }

  function getUserFundraiseStatus(event: IEventSummaryFE) {
    if (eventComponentMetrics.find((c) => c.component_type === 'fundraising') != null) {
      if (userState.isLoggedIn) {
        axios
          .get(
            swapRouteParams(routes.CHECK_FUNDRAISE_STATUS, {
              eventId: event.id,
              email: userState.user.email,
            }),
          )
          .then((response) => {
            response.data.isIndividual
              ? setFundraiseURL(
                  `/${uiConstants.ownerType.event}/${event.handle}/${uiConstants.ownerType.individual}/${userState.user.handle}`,
                )
              : setFundraiseURL(`/setup/fundraise?event=${event.id}`);
          });
      } else {
        setFundraiseURL(`/login?redirect=/`);
      }
    } else {
      setFundraiseURL('');
    }
  }

  function getEventComponentMetrics(selectedItem: IEventSummaryFE) {
    axios
      .get(swapRouteParams(routes.GET_EVENT_COMPONENT_COUNT, { eventId: selectedItem?.id }))
      .then((response) => {
        setEventComponentMetrics(response.data);
      });
  }

  function checkEventStoreItems(event: IEventSummaryFE) {
    axios
      .get(swapRouteParams(routes.GET_EVENT_STORE_ITEMS, { eventId: event.id }))
      .then((response) => {
        setStoreItems(response.data);
      });
  }

  return (
    <Modal
      class="HubSharedDetails"
      show={props.show}
      onClose={() => {
        props.onClose();
      }}
    >
      <div className="HubSharedDetailsModal">
        {item && (
          <>
            <div className="hub-cause-cover">
              <div className="cover-img-container">
                <img src={item.cover_image_url} />
              </div>
              <Portrait
                className="cause-profile"
                source={item.profile_image_url}
                size={60}
              />
            </div>
            <div className="cause-title-details">
              <div className="cause-title-charity">
                <div
                  className="cause-title"
                  title={item.title}
                >
                  <span notranslate="yes">{item.title}</span>
                </div>
                {props.type == uiConstants.ownerType.group && (
                  <div
                    className="cause-charity"
                    notranslate="yes"
                  >
                    Charity ID: {(item as IGroup).charity_id}
                  </div>
                )}
              </div>
              <div className="learn-more-action">
                {(isExternal && externalLink) || !isExternal ? (
                  <Button onClick={() => getCauseLink(item)}>Learn More</Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="cause-actions">
              {props.type === uiConstants.ownerType.group && (
                <>
                  {renderDonateButton()}

                  {!isExternal && (
                    <Button
                      className="cause-action"
                      onClick={(e) => handleGroupFollow()}
                    >
                      Follow Cause
                    </Button>
                  )}

                  {(item as IGroup)?.allow_obo_fundraisers && (
                    <Link
                      className="cause-action"
                      to={getFundraiseUrl(item.id)}
                    >
                      Fundraise
                    </Link>
                  )}
                </>
              )}

              {props.type === uiConstants.ownerType.event && (
                <>
                  {item.accepting_donations && (
                    <Link
                      className="cause-action full"
                      to={`/${uiConstants.ownerType.event}/${item.handle}/donate`}
                    >
                      Donate
                    </Link>
                  )}

                  {storeItems &&
                    storeItems.find((storeItem) => storeItem.item_type === 'ticket') && (
                      <Link
                        className="cause-action"
                        to={`/${uiConstants.ownerType.event}/${item.handle}`}
                      >
                        Buy Tickets
                      </Link>
                    )}

                  {fundraiseURL && (
                    <Link
                      className="cause-action"
                      to={fundraiseURL}
                    >
                      Fundraise
                    </Link>
                  )}
                </>
              )}
            </div>
            <div className="cause-description">
              {props.type == uiConstants.ownerType.group && (
                <div className="cause-description-title">About Cause</div>
              )}
              {props.type == uiConstants.ownerType.event && (
                <div className="cause-description-title">About Event</div>
              )}

              <div className="cause-description-content">
                <QuillTextEditor
                  value={item.description}
                  preserveWhitespace={true}
                  readOnly={true}
                  theme={uiConstants.quill.readOnlyTheme}
                />
              </div>
            </div>
          </>
        )}
        <Modal
          show={showLoginModal}
          onClose={() => setShowLoginModal(false)}
        >
          <Login
            registerRedirectUrl={`/${uiConstants.ownerType.company}/${props.hub?.handle}`}
            registerFunction="followGroup"
            {...props}
            location={useLocation()}
            match={useRouteMatch()}
            history={useHistory()}
          />
        </Modal>
      </div>
    </Modal>
  );
};

export default HubSharedDetailsModal;
