import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Config } from '@gigit/config';
import { IAppState } from '../../store';
import { localizeHelpers } from '../../localizeHelpers';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { handleInputChange, isMobileScreen, toastError, toastSuccess } from '../../helpers';
import { IEventSummaryFE, IGroup } from '@gigit/interfaces';
import { ColorResult, SketchPicker } from 'react-color';

import { createToast } from '../../actions/toaster';

import Dropdown, { IOptions } from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';

import './EmbedManagement.scss';
import { IToast } from '../../interfaces';
import typeHelpers from '../../helpers/typeHelpers';
import { IOwnerObject } from '../../interfaces/ownerObject';
import { uiConstants } from '../../constants/uiConstants';

interface IColorOptions {
  default: any[];
  hover: any[];
}

interface IProps extends WithTranslation, RouteComponentProps<any> {
  createToast(toast: IToast): void;
  owner: IOwnerObject;
}

interface IState {
  buttonStates: IOptions[];
  buttonColours: IColorOptions;
  buttonState: keyof IColorOptions;
  buttonEmbed: string;
  buttonType: string;
  buttonTypes: IOptions[];
  formTypes: IOptions[];
  formType: string;
  formEmbed: string;
  formColours: IColorOptions;
  buttonOption: number;
  formOption: number;
  formState: keyof IColorOptions;
}

class EmbedManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      buttonStates: [
        { label: 'Default', value: 'default' },
        { label: 'On Hover', value: 'hover' },
      ],
      buttonColours: {
        default: ['#5E51AB', '#FDFCFB', '#5E51AB'],
        hover: ['#484385', '#FDFCFB', '#484385'],
      },
      buttonTypes: [
        { label: 'Donate', value: 'Donate' },
        { label: 'Volunteer', value: 'Volunteer' },
        { label: 'Register', value: 'Register' },
        { label: 'Fundraise', value: 'Fundraise' },
        { label: 'Find us on Kambeo', value: 'Find us on Kambeo' },
      ],
      buttonType: 'Donate',
      buttonState: 'default',
      buttonOption: -1,
      buttonEmbed: '',
      formTypes:
        this.props.owner.ownerType === uiConstants.ownerType.event
          ? [
              { label: 'Event Donate Form', value: 'Event Donate Form' },
              { label: 'Group Donate Form', value: 'Group Donate Form' },
            ]
          : [{ label: 'Group Donate Form', value: 'Group Donate Form' }],
      formType:
        this.props.owner.ownerType === uiConstants.ownerType.event
          ? 'Event Donate Form'
          : 'Group Donate Form',
      formEmbed: '',
      formColours: {
        default: ['#5E51AB', '#FDFCFB', '#5E51AB'],
        hover: ['#484385', '#FDFCFB', '#484385'],
      },
      formState: 'default',
      formOption: -1,
    };

    this.handleButtonComplete = this.handleButtonComplete.bind(this);
    this.handleFormComplete = this.handleFormComplete.bind(this);
    this.getColorLabel = this.getColorLabel.bind(this);
    this.setButtonEmbedUrl = this.setButtonEmbedUrl.bind(this);
    this.setFormEmbedUrl = this.setFormEmbedUrl.bind(this);
    this.copyEmbed = this.copyEmbed.bind(this);
  }

  componentDidMount() {
    this.setButtonEmbedUrl();
    this.setFormEmbedUrl();
  }

  setButtonEmbedUrl() {
    let url: string =
      Config.web.REACT_APP_BASE_URL + '/embed/' + this.props.owner.ownerHandle + '/event';

    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      url = Config.web.REACT_APP_BASE_URL + '/embed/' + this.props.owner.ownerHandle + '/group';
    }

    const groupHandle = typeHelpers.getGroupHandleFromOwner(this.props.owner);
    this.setState({
      buttonEmbed:
        '<iframe src="' +
        url +
        '?type=button&default=' +
        this.state.buttonColours['default']
          .map((item, index) => {
            item = item.substring(1);
            return item;
          })
          .join(',') +
        '&hover=' +
        this.state.buttonColours['hover']
          .map((item, index) => {
            item = item.substring(1);
            return item;
          })
          .join(',') +
        '&text=' +
        encodeURI(this.state.buttonType) +
        '&group=' +
        groupHandle +
        '" width="240px" height="80px" style="border:0px;" />',
    });
  }

  setFormEmbedUrl() {
    let url: string =
      Config.web.REACT_APP_BASE_URL + '/event/' + this.props.owner.ownerHandle + '/donate';

    if (this.state.formType === 'Group Donate Form') {
      if (this.props.owner.ownerType === uiConstants.ownerType.event) {
        url =
          Config.web.REACT_APP_BASE_URL +
          '/group/' +
          typeHelpers.getGroupHandleFromOwner(this.props.owner) +
          '/donate';
      } else {
        url = Config.web.REACT_APP_BASE_URL + '/group/' + this.props.owner.ownerHandle + '/donate';
      }
    }

    this.setState({
      formEmbed:
        '<iframe src="' +
        url +
        '?embed=1&default=' +
        this.state.formColours['default']
          .map((item, index) => {
            item = item.substring(1);
            return item;
          })
          .join(',') +
        '&hover=' +
        this.state.formColours['hover']
          .map((item, index) => {
            item = item.substring(1);
            return item;
          })
          .join(',') +
        '" style="height:100%;width:100%;min-height:700px;border:0px" />',
    });
  }

  handleButtonComplete(color: ColorResult) {
    let _buttonColours: IColorOptions = { ...this.state.buttonColours };
    _buttonColours[this.state.buttonState][this.state.buttonOption] = color.hex;

    this.setState(
      {
        buttonColours: _buttonColours,
      },
      () => {
        this.setButtonEmbedUrl();
      },
    );
  }

  handleFormComplete(color: ColorResult) {
    let _formColours: IColorOptions = { ...this.state.formColours };
    _formColours[this.state.formState][this.state.formOption] = color.hex;

    this.setState(
      {
        formColours: _formColours,
      },
      () => {
        this.setFormEmbedUrl();
      },
    );
  }

  getColorLabel(index: number) {
    let _label: string = 'Border Color';

    if (index === 1) {
      _label = 'Font Color';
    }

    if (index === 2) {
      _label = 'Background Color';
    }

    return _label;
  }

  copyEmbed(type: string) {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(type === 'button' ? this.state.buttonEmbed : this.state.formEmbed)
        .then(
          () => {
            const toast = toastSuccess(
              localizeHelpers.translate('Embed successfully copied.'),
              'Embed Button',
            );
            this.props.createToast(toast);
          },
          (err: any) => {
            const toast = toastError(
              localizeHelpers.translate('Unable to copy Embed code.'),
              'Embed Button',
            );
            this.props.createToast(toast);
          },
        );
    }
  }

  render() {
    return (
      <div className="SettingsManagement EmbedManagement section-wrap">
        <div className="section-title">
          <div className="section-inner-title">Embed Tools</div>
        </div>
        <div className="section-inner">
          <div className="settings-title">Buttons</div>
          <div className="settings-item no-select">
            <div className="details">
              <p>Button</p>
              <span className="detail">Select a button to embed.</span>
              <Dropdown
                className="button-type"
                label="Button Type"
                name="buttonType"
                shouldSort={true}
                value={this.state.buttonType}
                options={this.state.buttonTypes}
                onChange={(e) => {
                  handleInputChange(e, this, false, this.setButtonEmbedUrl);
                }}
              />
              <div className="button-custom">
                <div className="preview-wrap">
                  <Dropdown
                    name="buttonState"
                    value={this.state.buttonState}
                    shouldSort={true}
                    options={this.state.buttonStates}
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                  <div className="preview">
                    <Button
                      text={this.state.buttonType}
                      style={{
                        border: '2px solid ' + this.state.buttonColours[this.state.buttonState][0],
                        color: this.state.buttonColours[this.state.buttonState][1],
                        backgroundColor: this.state.buttonColours[this.state.buttonState][2],
                      }}
                    />
                  </div>
                </div>
                <div className="color-options">
                  {this.state.buttonColours[this.state.buttonState].map((color, index) => {
                    return (
                      <div
                        key={index}
                        className="color-option"
                      >
                        <div className="color-label">
                          <div className="color-name">{this.getColorLabel(index)}</div>
                          <div className="color-hex">
                            {this.state.buttonColours[this.state.buttonState][index]}
                          </div>
                        </div>
                        <div
                          onMouseLeave={() => {
                            this.setState({ buttonOption: -1 });
                          }}
                          onMouseEnter={() => {
                            this.setState({ buttonOption: index });
                          }}
                          className="color-swatch"
                          style={{
                            backgroundColor:
                              this.state.buttonColours[this.state.buttonState][index],
                          }}
                        >
                          {this.state.buttonOption === index && (
                            <div className="picker-wrap">
                              <SketchPicker
                                disableAlpha={true}
                                color={this.state.buttonColours[this.state.buttonState][index]}
                                onChangeComplete={this.handleButtonComplete}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="embed-code">
                <TextField
                  type="text"
                  name="buttonEmbed"
                  value={this.state.buttonEmbed}
                  onChange={() => {
                    /* do nothing */
                  }}
                />
                <Button
                  text="Copy"
                  onClick={() => {
                    this.copyEmbed('button');
                  }}
                />
              </div>
            </div>
          </div>
          <div className="settings-title">Forms</div>
          <div className="settings-item no-select">
            <div className="details">
              <p>Form</p>
              <span className="detail">Select a form to embed.</span>
              <Dropdown
                className="button-type"
                label="Form Type"
                name="formType"
                value={this.state.formType}
                shouldSort={true}
                options={this.state.formTypes}
                onChange={(e) => {
                  handleInputChange(e, this, false, this.setFormEmbedUrl);
                }}
              />
              <div className="button-custom form-custom">
                <div className="preview-wrap">
                  <Dropdown
                    name="formState"
                    shouldSort={true}
                    value={this.state.formState}
                    options={this.state.buttonStates}
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                  <div className="preview">
                    <Button
                      text="Example Button"
                      style={{
                        border: '2px solid ' + this.state.formColours[this.state.formState][0],
                        color: this.state.formColours[this.state.formState][1],
                        backgroundColor: this.state.formColours[this.state.formState][2],
                      }}
                    />
                  </div>
                </div>
                <div className="color-options">
                  {this.state.formColours[this.state.formState].map((color, index) => {
                    return (
                      <div
                        key={index}
                        className="color-option"
                      >
                        <div className="color-label">
                          <div className="color-name">{this.getColorLabel(index)}</div>
                          <div className="color-hex">
                            {this.state.formColours[this.state.formState][index]}
                          </div>
                        </div>
                        <div
                          onMouseLeave={() => {
                            this.setState({ formOption: -1 });
                          }}
                          onMouseEnter={() => {
                            this.setState({ formOption: index });
                          }}
                          className="color-swatch"
                          style={{
                            backgroundColor: this.state.formColours[this.state.formState][index],
                          }}
                        >
                          {this.state.formOption === index && (
                            <div className="picker-wrap">
                              <SketchPicker
                                disableAlpha={true}
                                color={this.state.formColours[this.state.formState][index]}
                                onChangeComplete={this.handleFormComplete}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="embed-code">
                <TextField
                  type="text"
                  name="formEmbed"
                  value={this.state.formEmbed}
                  onChange={() => {
                    /* do nothing */
                  }}
                />
                <Button
                  text="Copy"
                  onClick={() => {
                    this.copyEmbed('form');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {
  createToast,
};

export default withRouter(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(EmbedManagement)),
);
