import moment, { Locale, LongDateFormatKey, Moment } from 'moment';
import { round } from './helpers';

/** MomentJS Date time formats that are locale aware. */
export enum LocaleDateFormats {
  /** Month (number), day of month, year */
  L = 'L',
  /** Month name, day of month, year */
  LL = 'LL',

  /** Month name, day of month, year (Short) */
  ll = 'll',

  /** Time */
  LT = 'LT',

  /** Month name, day of month, year, time */
  LLL = 'LLL',

  /** Month name, day of month, year, time (Short) */
  lll = 'lll',

  /** Month name, day of month, day of week, year, time */
  LLLL = 'LLLL',

  /** Year */
  YYYY = 'YYYY',

  /** Custom date format. Like LL but without year. Month name, day of month. */
  XLL = 'XLL',

  /** Custom date format. Displays day of week, day of month */
  XDD = 'XDD',
}

export namespace localizeHelpers {
  /** Translates an input text/html. */
  export function translate(
    input: string | React.ReactNode,
    variables?: { [index: string]: any },
    callback?: (translation: string) => void,
  ) {
    if (typeof input === 'string') {
      Localize.phrase(input);
    }
    let translated = Localize.translate(input, variables, callback);

    // Mnaually replace variables.
    // We do this because sometimes Localize decides it doesn't want to replace the variables itself.
    if (variables) {
      for (const variable in variables) {
        translated = replacePlaceholder(translated, `{{${variable}}}`, variables[variable]);
      }
    }

    return translated;
  }

  function replacePlaceholder(str: string, placeholder: string, replacement: string): string {
    return str.split(placeholder).join(replacement);
  }

  /** Returns an array of weekday names. When locale isn't provided, it will default to 'en' */
  export function getWeekDays(locale?: string) {
    // Have to switch global moment object locale, since we can't call months() on an instance.
    moment.locale(locale || 'en');

    const weekdays = moment.weekdays();

    moment.locale('en');

    return weekdays;
  }

  /** Returns an array of month names. When locale isn't provided, it will default to 'en' */
  export function getMonths(locale?: string) {
    // Have to switch global moment object locale, since we can't call months() on an instance.
    moment.locale(locale || 'en');

    const months = moment.months();

    moment.locale('en');

    return months;
  }

  /** Formats a time using a locale. */
  export function formatTime(time: string | { hour: number; minute?: number }, locale: string) {
    let momentObj = moment();

    if (typeof time === 'string') {
      momentObj = moment(time, 'TIME');
    } else if (typeof time === 'object') {
      momentObj.hour(time.hour);

      if (time.minute) {
        momentObj.minute(time.minute);
      } else {
        momentObj.minute(0);
        momentObj.second(0);
        momentObj.millisecond(0);
      }
    } else {
      throw new Error(`Unsupported type ${typeof time}, only string/object supported`);
    }

    momentObj = momentObj.locale(locale);

    return momentObj.format(LocaleDateFormats.LT);
  }

  /** Returns a long date format based on the provided locale. */
  export function getLongDateFormat(formatSpecifier: LocaleDateFormats, locale: string) {
    let momentObj = moment();

    momentObj = momentObj.locale(locale);

    var localeData = momentObj.localeData();
    var longFormat = localeData.longDateFormat(formatSpecifier as LongDateFormatKey);

    return longFormat;
  }

  /** Formats a date using a locale. */
  export function formatDate(
    date: Date | string | Moment,
    formatSpecifier: LocaleDateFormats,
    locale: string,
  ) {
    let format: string = formatSpecifier;
    let momentObj = moment(date);

    momentObj = momentObj.locale(locale);

    if (format === LocaleDateFormats.XLL) {
      // Here we handle custom format XLL, which is just month, day of month.
      let localeData = momentObj.localeData();
      let longFormat = localeData.longDateFormat('LL');

      // Each language formats these differently, so we need to handle them specifically.
      switch (locale) {
        case 'en':
          longFormat = longFormat.replace(/, YYYY/, '');
          break;
        case 'fr':
          longFormat = longFormat.replace(/ YYYY/, '');
          break;
        case 'es':
          longFormat = longFormat.replace(/ \[de\] YYYY/, '');
          break;
        default:
          throw new Error(`Unsupported format ${format} for locale ${locale}`);
          break;
      }

      format = longFormat as LocaleDateFormats;
    } else if (format === LocaleDateFormats.XDD) {
      format = 'dddd DD' as LocaleDateFormats;
    }

    return momentObj.format(format);
  }

  /** Formats a number using a locale. */
  export function formatNumber(
    amount: number,
    locale: string,
    options?: { decimal_places?: number },
  ) {
    var formatter = new Intl.NumberFormat(locale, {});

    return formatter.format(round(amount, options?.decimal_places ?? 2));
  }
}
