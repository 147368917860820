import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
  routes,
  swapRouteParams,
  defaultCurrency,
  formatCurrency,
  toastError,
} from '../../../helpers';
import { IUserRole, IEventIndividual, IFundraiserSummary } from '@gigit/interfaces';
import { IGroupState } from '../../../reducers/group';
import SortableTable, { ISortableTableColumn } from '../../SortableTable/SortableTable';
import Modal from '../../Modal/Modal';
import ActivityLog from '../ActivityLog/ActivityLog';
import { IAppState } from '../../../store';
import ContactCard from '../ContactCard/ContactCard';
import './ContactFundraisers.scss';
import { createToast } from '../../../actions/toaster';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { userSelectors } from '../../../selectors/user';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  locale: string;

  createToast(toast: IToast): void;
}

interface IState {
  tabs: Array<string>;
  activeTab: number;
  fundraisers: Array<IEventIndividual>;
  fundraiseData: IFundraiserSummary | null;
  columns: ISortableTableColumn[];
  showActivityLog: boolean;
  loading: boolean;
}

class ContactFundraisers extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tabs: ['Fundraisers'],
      activeTab: 0,
      fundraisers: [],
      fundraiseData: null,
      columns: [
        { label: 'Event', id: 'event', sortable: false },
        { label: 'Type', id: 'type', sortable: false },
        { label: 'Team Name', id: 'team-name', sortable: false },
        { label: 'Fundraising Goal', id: 'progress', sortable: false },
        { label: 'Actions', id: 'actions', sortable: false },
      ],
      showActivityLog: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.getFundraisers();
    this.getFundraiseData();
  }

  getFundraisers() {
    this.setState({
      loading: true,
    });
    axios
      .get(
        swapRouteParams(routes.GET_EVENT_FUNDRAISERS_FOR_USER, {
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user?.id,
        }),
      )
      .then((response) => {
        this.setState({
          fundraisers: response.data,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Contact Fundraisers');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getFundraiseData() {
    axios
      .get(
        swapRouteParams(routes.GET_FUNDRAISERS_FOR_USER, {
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user?.id,
        }),
      )
      .then((response) => {
        this.setState({
          fundraiseData: response.data,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Contact Fundraisers Summary');
        this.props.createToast(toast);
      });
  }

  getProgressWidth(a: number, b: number) {
    if (a && b) {
      return (a / b) * 100 + '%';
    }

    return '0%';
  }

  convertTableData() {
    let currency = this.props.groupState.group.account?.currency || defaultCurrency;

    return this.state.fundraisers.map((f: IEventIndividual) => {
      let raised = f?.raised || 0;
      let goal = f.goal || 0;

      return {
        row: [
          {
            content: <Link to={`/event/${f.event?.handle}`}>{f.event?.title}</Link>,
            id: 'title',
            notranslate: 'yes',
          },
          { content: `${f.team_id ? 'Team' : 'Individual'}`, id: 'type', notranslate: 'yes' },
          { content: `${f.team?.name || 'N/A'}`, id: 'team-name', notranslate: 'yes' },
          {
            content: (
              <div className="progress-bar-wrap">
                <div className="progress-bar-bg">
                  <div
                    notranslate="yes"
                    className="progress-bar"
                    style={{ width: this.getProgressWidth(raised, goal) }}
                  />
                  <div
                    notranslate="yes"
                    className="amt"
                  >
                    {formatCurrency(raised, currency, this.props.locale)}
                  </div>
                  <div
                    notranslate="yes"
                    className="goal"
                  >
                    {formatCurrency(goal, currency, this.props.locale)}
                  </div>
                </div>
                <span>
                  <var data-var="total_raised">
                    {this.getProgressWidth(f?.raised || 0, f?.goal || 0)}
                  </var>{' '}
                  Raised
                </span>
              </div>
            ),
            id: 'progress',
          },
          {
            id: 'actions',
            menu: [
              {
                icon: 'far fa-file-alt',
                onClick: () => {
                  this.setState({ showActivityLog: true });
                },
                label: 'Activity Log',
              },
            ],
          },
        ],
      };
    });
  }

  render() {
    let currency = this.props.groupState.group.account?.currency || defaultCurrency;
    let data = this.convertTableData();
    let isDataEmpty = data.length === 0;
    let isFundraiserTab = this.state.activeTab === 0;

    return (
      <div className="Fundraisers">
        <ul className="Contact-menu">
          {this.state.tabs.map((item, index) => {
            return (
              <li
                onClick={() => {
                  this.setState({ activeTab: index });
                }}
                className={this.state.activeTab === index ? 'active no-select' : 'no-select'}
                key={index}
              >
                <span>{item}</span>
              </li>
            );
          })}
        </ul>
        <div className="Contact-content">
          <div className="Contact-side">
            <ContactCard
              groupId={this.props.groupState.group.id}
              {...this.props}
            />
          </div>
          <div className="contact-main">
            {isFundraiserTab && (
              <div className="stat-row">
                <div className="cd-stat-row">
                  <div className="cd-stat-box">
                    <div className="stat">
                      <var data-var="total_raised">
                        {' '}
                        {formatCurrency(
                          this.state.fundraiseData?.total_raised || 0,
                          currency,
                          this.props.locale,
                        )}
                      </var>
                    </div>
                    <div className="stat-label">Total Raised</div>
                  </div>
                  <div className="cd-stat-box">
                    <div
                      notranslate="yes"
                      className="stat"
                    >
                      {localizeHelpers.formatNumber(
                        this.state.fundraiseData?.number_of_individual_fundraisers_created || 0,
                        this.props.locale,
                      )}
                    </div>
                    <div className="stat-label">Individual Fundraisers</div>
                  </div>
                  <div className="cd-stat-box">
                    <div
                      notranslate="yes"
                      className="stat"
                    >
                      {localizeHelpers.formatNumber(
                        this.state.fundraiseData?.number_of_team_fundraisers_created || 0,
                        this.props.locale,
                      )}
                    </div>
                    <div className="stat-label">Team Fundraisers</div>
                  </div>
                  <div className="cd-stat-box">
                    <div
                      notranslate="yes"
                      className="stat"
                    >
                      {`${localizeHelpers.formatDate(this.state.fundraiseData?.last_fundraiser_created_date ?? new Date(), LocaleDateFormats.XLL, this.props.locale)}`}
                    </div>
                    <div className="stat-label">Last Fundraiser</div>
                  </div>
                </div>
              </div>
            )}
            {isFundraiserTab && (
              <div className="data">
                <SortableTable
                  columns={this.state.columns}
                  data={data}
                  {...this.props}
                  loading={this.state.loading}
                />

                {isDataEmpty && (
                  <div className="empty-data">This user has no fundraisers, yet.</div>
                )}
              </div>
            )}
            <Modal
              class="activity-log-modal"
              show={this.state.showActivityLog}
              onClose={() => this.setState({ showActivityLog: false })}
            >
              <ActivityLog {...this.props} />
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactFundraisers);
