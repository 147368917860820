import React from 'react';
import BannerLayoutSettings from '../../Shared/BannerLayoutSettings/BannerLayoutSettings';

interface IPropsBanner {
  bannerLayout: string;
  setBannerLayout(newBannerLayout: string): void;
}

function BannerStep(props: IPropsBanner) {
  return (
    <div className="BannerStep">
      <section>
        <h4>Banner Layout</h4>
        <p>Set alignment for your company banner.</p>

        <BannerLayoutSettings
          bannerLayout={props.bannerLayout}
          setBannerLayout={(v) => props.setBannerLayout(v)}
        />
      </section>
    </div>
  );
}

export default BannerStep;
