import axios from 'axios';
import {
  IGroup,
  IEventSummaryFE,
  IApplication,
  IAuctionItemSummary,
  ITransaction,
  ITransactionItemSummary,
  ITransactionSummary,
  IHub,
  IActivityUserFeedItemSummaryFE,
  IStoryResultFE,
  ISearchResultObject,
  IUser,
  IUserMyPageItem,
  IPaymentMethod,
  IFollowingObject,
  IInviteSummary,
  IUserRole,
  IShiftUserSummary,
  IUserRoleJoin,
  IFollowingObjectFE,
  ILoggedVolunteerHoursSummary,
  ILoggedVolunteerHoursCreateParamsFE,
  IUserContactStatusSummary,
  IUserContactSummary,
  IUserContact,
  IConversation,
  IPage,
  IUserObjectWithRole,
  IGigSummaryFE,
  IUserPublicResult,
  ILoggedVolunteerHoursEditParams,
  IUserCreditBalance,
} from '@gigit/interfaces';
import { swapRouteParams, routes, downloadFile, formatQuery, IStringMap } from '../helpers';
import { localizeHelpers } from '../localizeHelpers';
import { IActiveFilter } from '../interfaces';
import { Constants } from '@gigit/constants';
import { Config } from '@gigit/config';

export namespace userRequestActions {
  export async function getAllUsers(query?: URLSearchParams) {
    const response = await axios.get<IUserPublicResult[]>(routes.GET_USER_ALL, { params: query });
    return response.data;
  }

  export async function getUserDonations(query?: URLSearchParams) {
    const response = await axios.get<ITransactionSummary[]>(routes.GET_USER_DONATIONS, {
      params: query,
    });
    return response.data;
  }

  export async function getUserTickets(query?: URLSearchParams) {
    const response = await axios.get<ITransactionItemSummary[]>(routes.GET_USER_TICKETS, {
      params: query,
    });
    return response.data;
  }

  export async function getUserPurchases(query?: URLSearchParams) {
    const response = await axios.get<ITransactionItemSummary[]>(routes.GET_USER_PURCHASES, {
      params: query,
    });
    return response.data;
  }

  export async function getUserAuctionItems(query?: URLSearchParams) {
    const response = await axios.get<IAuctionItemSummary[]>(routes.GET_USER_AUCTION_ITEMS, {
      params: query,
    });
    return response.data;
  }

  export async function getUserPagesFollowing(
    params: URLSearchParams,
  ): Promise<IFollowingObject[]> {
    const response = await axios.get<IFollowingObject[]>(routes.GET_USER_PAGES_FOLLOWING, {
      params: params,
    });
    return response.data;
  }

  export async function getUserObjectFollowing(
    objectType: string,
    params?: URLSearchParams,
  ): Promise<IUserObjectWithRole<IGroup | IUser>[]> {
    const response = await axios.get<IUserObjectWithRole<IGroup | IUser>[]>(
      swapRouteParams(routes.GET_USER_OBJECT_FOLLOWING, { objectType }),
      { params: params },
    );
    return response.data;
  }

  export async function getUserHubObjects(
    objectType: string,
    params?: URLSearchParams,
  ): Promise<IGroup[] | IEventSummaryFE[]> {
    const response = await axios.get<IGroup[] | IEventSummaryFE[]>(
      swapRouteParams(routes.GET_USER_HUB_OBJECTS, { objectType }),
      { params: params },
    );
    return response.data;
  }

  export async function getUserMyPageItems2(
    params: URLSearchParams,
    type: string,
  ): Promise<IUserMyPageItem[]> {
    const response = await axios.get<IUserMyPageItem[]>(
      swapRouteParams(routes.GET_USER_MYPAGE_ITEMS, { type: type }),
      { params: params },
    );
    return response.data;
  }

  export async function getUserMyPageItems(
    params: URLSearchParams,
    type: string,
  ): Promise<IUserMyPageItem[]> {
    const response = await axios.get<IUserMyPageItem[]>(
      swapRouteParams(routes.GET_USER_MYPAGE_ITEMS, { type: type }),
      { params: params },
    );
    return response.data;
  }

  export async function getUserGigShifts(query?: URLSearchParams) {
    const response = await axios.get<IShiftUserSummary[]>(routes.GET_USER_GIG_SHIFTS, {
      params: query,
    });
    return response.data;
  }

  export async function getUserGigs(query?: URLSearchParams) {
    const response = await axios.get<IGigSummaryFE[]>(routes.GET_USER_GIGS, { params: query });
    return response.data;
  }

  export async function getUserGigsForLoggingHours(query?: URLSearchParams) {
    const response = await axios.get<IGigSummaryFE[]>(routes.GET_USER_GIGS_FOR_LOGGING_HOURS, {
      params: query,
    });
    return response.data;
  }

  export async function getUserApplications(params: {
    sortDir?: 'asc' | 'desc';
    sortBy: string;
    type: string;
    states: string;
  }): Promise<IApplication[]> {
    let filters: IActiveFilter[] = [];
    if (params.states !== 'all') {
      filters.push({ value: params.states, id: 'status', type: 'text', label: 'Status Code' });
    }

    // if(params.type !== 'all') {
    //     filters.push({value: params.states, id: 'status.code', type:'text', label:'status.code'})
    // }

    let query;

    if (params.sortDir) {
      query = formatQuery({
        sort: [{ id: params.sortBy, order: params.sortDir }],
      });
    } else {
      query = formatQuery({
        sort: [{ id: params.sortBy, order: 'asc' }],
      });
    }

    const response = await axios.get<IApplication[]>(routes.GET_USER_APPLICATIONS, {
      params: query,
    });
    return response.data;
  }

  export async function cancelUserApplication(
    userId: string,
    applicationId: string,
  ): Promise<IApplication> {
    const response = await axios.post<IApplication>(
      swapRouteParams(routes.POST_USER_CANCEL_APPLICATION, {
        userId: userId,
        application_id: applicationId,
      }),
    );
    return response.data;
  }

  export async function getUserByHandleOrId(
    handleOrId: string,
  ): Promise<ISearchResultObject<IUser>> {
    const response = await axios.get<ISearchResultObject<IUser>>(
      swapRouteParams(routes.GET_USER_BY_HANDLE, { handleOrId }),
    );
    return response.data;
  }

  export async function getUserByEmail(email: string): Promise<IUser> {
    const response = await axios.get<IUser>(swapRouteParams(routes.GET_USER_BY_EMAIL, { email }));
    return response.data;
  }

  export async function getActivityFeedItemsSummaryForObjects(
    objectType: string,
    objectId: string,
    query?: URLSearchParams,
  ): Promise<IActivityUserFeedItemSummaryFE[]> {
    const response = await axios.get<IActivityUserFeedItemSummaryFE[]>(
      swapRouteParams(routes.GET_OBJECTS_ACTIVITY_FEED_ITEMS_SUMMERY, { objectType, objectId }),
      { params: query },
    );
    return response.data;
  }

  export async function getActivityFeedItemsSummary(
    query?: URLSearchParams,
  ): Promise<IActivityUserFeedItemSummaryFE[]> {
    const response = await axios.get<IActivityUserFeedItemSummaryFE[]>(
      routes.GET_USER_ACTIVITY_FEED_ITEMS_SUMMERY,
      { params: query },
    );
    return response.data;
  }

  export async function getPaymentMethods() {
    const response = await axios.get<IPaymentMethod[]>(routes.GET_PAYMENT_METHODS);
    return response.data;
  }

  export async function deletePaymentMethods(id: string) {
    const response = await axios.delete<IPaymentMethod>(
      swapRouteParams(routes.DELETE_PAYMENT_METHOD, { id }),
    );
    return response.data;
  }
  export async function getUserInvites(sortBy?: string) {
    let urlParams = new URLSearchParams();
    if (sortBy) urlParams.append('sort', sortBy);

    const response = await axios.get<IInviteSummary[]>(routes.GET_USER_INVITES, {
      params: urlParams,
    });
    return response.data;
  }

  export async function getDonationItems(
    route: string,
    query?: URLSearchParams,
  ): Promise<IGroup[] | IEventSummaryFE[]> {
    const response = await axios.get<IGroup[] | IEventSummaryFE[]>(route, { params: query });
    return response.data;
  }

  export async function getUserGroupRole(groupId: string): Promise<IUserRoleJoin> {
    const response = await axios.get<IUserRoleJoin>(
      swapRouteParams(routes.GET_CURRENT_USER_GROUP_ROLE, { groupId }),
    );
    return response.data;
  }

  export async function getUserEventRole(eventId: string): Promise<IUserRoleJoin> {
    const response = await axios.get<IUserRoleJoin>(
      swapRouteParams(routes.GET_CURRENT_USER_EVENT_ROLE, { eventId }),
    );
    return response.data;
  }

  export async function getUserHubRole(hubId: string): Promise<IUserRoleJoin> {
    const response = await axios.get<IUserRoleJoin>(
      swapRouteParams(routes.GET_CURRENT_USER_HUB_ROLE, { hubId }),
    );
    return response.data;
  }

  export async function getUserVolunteeringHours(
    query?: URLSearchParams,
  ): Promise<ILoggedVolunteerHoursSummary[]> {
    const response = await axios.get<ILoggedVolunteerHoursSummary[]>(
      routes.USER_VOLUNTEERING_HOURS,
      { params: query },
    );
    return response.data;
  }

  export async function getUserVolunteeringHoursForGig(
    gigId: string,
    query?: URLSearchParams,
  ): Promise<ILoggedVolunteerHoursSummary[]> {
    const response = await axios.get<ILoggedVolunteerHoursSummary[]>(
      swapRouteParams(routes.USER_VOLUNTEERING_HOURS_FOR_GIG, { gigId }),
      { params: query },
    );
    return response.data;
  }

  export async function downloadDonationReceipt(donation: ITransaction) {
    const { receipt_number, tax_receipt_prefix, id } = donation;

    axios
      .get(swapRouteParams(routes.DOWNLOAD_DONATION_RECEIPT, { donationId: id }), {
        responseType: 'blob',
      })
      .then((response) => {
        const fileName =
          (tax_receipt_prefix ? tax_receipt_prefix + ' ' : '') +
          localizeHelpers.translate('Donation receipt') +
          ' ' +
          (receipt_number ? '#' + receipt_number : id);

        downloadFile(fileName, response.data, 'application/pdf');
      });
  }

  export async function downloadPurchaseReceipt(transactionId: string) {
    axios
      .get(
        swapRouteParams(routes.DOWNLOAD_USER_PURCHASE_RECEIPT, { transaction_id: transactionId }),
        { responseType: 'blob' },
      )
      .then((response) => {
        downloadFile(`purchase_${transactionId}`, response.data, 'application/pdf');
      });
  }

  export async function downloadAuctionReceipt(transactionId: string) {
    await axios
      .get(
        swapRouteParams(routes.DOWNLOAD_USER_AUCTION_RECEIPT, { transaction_id: transactionId }),
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        downloadFile(`auction_${transactionId}`, response.data, 'application/pdf');
      });
  }

  export async function downloadTicket(ticketId: string): Promise<void> {
    await axios
      .get(swapRouteParams(routes.DOWNLOAD_TICKET, { ticketId: ticketId }), {
        responseType: 'blob',
      })
      .then((response) => {
        downloadFile(`ticket_${ticketId}`, response.data, 'application/pdf');
      });
  }

  export async function getDonationsExport(userId: string, userHandle: string) {
    await axios
      .get(swapRouteParams(routes.GET_USER_DONATIONS_EXPORT, { userId: userId }), {
        responseType: 'blob',
      })
      .then((response) => {
        downloadFile(`donations_for_${userHandle}.csv`, response.data, 'text/csv');
      });
  }

  export async function getPurchasesExport(userId: string, userHandle: string) {
    await axios
      .get(swapRouteParams(routes.GET_USER_PURCHASES_EXPORT, { userId: userId }), {
        responseType: 'blob',
      })
      .then((response) => {
        downloadFile(`purchases_for_${userHandle}.csv`, response.data, 'text/csv');
      });
  }

  export async function getAuctionPaymentsExport(userId: string, userHandle: string) {
    await axios
      .get(swapRouteParams(routes.GET_USER_AUCTION_ITEMS_EXPORT, { userId: userId }), {
        responseType: 'blob',
      })
      .then((response) => {
        downloadFile(`auctions_for_${userHandle}.csv`, response.data, 'text/csv');
      });
  }

  export async function getUserHubs(query?: URLSearchParams) {
    const response = await axios.get<IHub[]>(routes.GET_USER_HUBS, { params: query });
    return response.data;
  }

  export async function getActiveUserHubs(query?: URLSearchParams) {
    const response = await axios.get<IHub[]>(routes.GET_ACTIVE_USER_HUBS, { params: query });
    return response.data;
  }

  export async function isUserInAHub() {
    const response = await axios.get<IHub[]>(routes.GET_USER_HUBS);

    return response.data.length > 0;
  }

  export async function getUserGroupsForPermission(permission: string, query?: URLSearchParams) {
    const response = await axios.get<IGroup[]>(
      swapRouteParams(routes.GET_USER_GROUPS_FOR_PERMISSION, { permission }),
      { params: query },
    );
    return response.data;
  }

  export async function getUserGroups() {
    const response = await axios.get<IGroup[]>(routes.GET_USER_GROUPS);
    return response.data;
  }

  export async function getStoriesOfFollowed(query?: URLSearchParams) {
    const response = await axios.get<IStoryResultFE[]>(routes.GET_STORIES, { params: query });
    return response.data;
  }

  export async function getStories(id: string, query?: URLSearchParams) {
    const response = await axios.get<IStoryResultFE>(
      swapRouteParams(routes.GET_USER_STORIES, { id }),
      { params: query },
    );
    return response.data;
  }

  /**
   *
   * @returns {Promise<boolean>} - Returns if the user a part of a hub with volunteer for dollars enabled
   */
  export async function hasVolunteerForDollarsProgram() {
    const response = await axios.get<boolean>(routes.GET_USER_HAS_VOLUNTEER_FOR_DOLLARS);

    return response.data;
  }
  /**
   * Determines if the current logged in user is a contact of the passed userId
   * @param userId: string
   */
  export async function getContactSummary(userId: string): Promise<IUserContactStatusSummary> {
    const response = await axios.get(
      swapRouteParams(routes.GET_CONTACT_SUMMARY, { contactId: userId }),
    );
    return response.data;
  }

  /**
   * Retreives logged in users contacts list
   */
  export async function getMyContacts(
    queryParams?: URLSearchParams,
  ): Promise<IUserContactSummary[]> {
    const response = await axios.get(routes.GET_MY_CONTACTS, { params: queryParams });
    return response.data;
  }

  /**
   * Sends a request to become 'contacts' with each other
   * @param userId: string
   */
  export async function sendContactRequest(userId: string): Promise<IUserContact> {
    const response = await axios.post(routes.REQUEST_CONTACT, { contact_user_id: userId });
    return response.data;
  }

  /**
   * Accepts a Contact Request
   * @param userId: string
   */
  export async function acceptContactRequest(userId: string): Promise<IUserContact> {
    const response = await axios.post(
      swapRouteParams(routes.ACCEPT_CONTACT_REQUEST, { contactId: userId }),
    );
    return response.data;
  }

  /**
   * Rejects a Contact Request
   * @param userId: string
   */
  export async function rejectContactRequest(userId: string): Promise<IUserContact> {
    const response = await axios.post(
      swapRouteParams(routes.REJECT_CONTACT_REQUEST, { contactId: userId }),
    );
    return response.data;
  }

  /**
   * Removes a user contact from logged in users contact list
   * @param userId: string
   */
  export async function removeContact(userId: string): Promise<IUserContact> {
    const response = await axios.delete(
      swapRouteParams(routes.REMOVE_CONTACT, { contactId: userId }),
    );
    return response.data;
  }

  /**
   * Retreives Converation Summary - Used for unread counts
   */
  export async function getUnreadConversationsSumary(): Promise<
    { conversation_id: string; read: boolean; unread_count: number }[]
  > {
    const response = await axios.get(
      Config.web.REACT_APP_CHAT + routes.GET_UNREAD_CONVERSATIONS_SUMMARY,
    );
    return response.data;
  }

  /**
   * Marks Conversation As read
   */
  export async function markConversationAsRead(conversationId: string): Promise<IConversation> {
    const response = await axios.put(
      swapRouteParams(Config.web.REACT_APP_CHAT + routes.MARK_CONVERSATION_AS_READ, {
        id: conversationId,
      }),
    );
    return response.data;
  }

  export async function logVolunteeringHours(
    createHoursParams: ILoggedVolunteerHoursCreateParamsFE,
  ) {
    const response = await axios.post<ILoggedVolunteerHoursSummary[]>(
      routes.USER_VOLUNTEERING_HOURS,
      { ...createHoursParams },
    );
    return response.data;
  }

  //log hours bulk
  export async function logVolunteeringHoursBulk(
    createHoursParams: ILoggedVolunteerHoursCreateParamsFE[],
  ) {
    const response = await axios.post<ILoggedVolunteerHoursSummary[]>(
      routes.USER_VOLUNTEERING_HOURS_BULK,
      createHoursParams,
    );
    return response.data;
  }

  export async function editVolunteerHour(
    logged_hour_id: string,
    update: ILoggedVolunteerHoursEditParams,
  ) {
    const response = await axios.put(
      swapRouteParams(routes.USER_VOLUNTEERING_HOURS_EDIT, { logged_hour_id }),
      update,
    );
    return response.data;
  }

  export async function logVolunteeringHoursSelected(
    createHoursParams: ILoggedVolunteerHoursCreateParamsFE,
  ) {
    const response = await axios.post<ILoggedVolunteerHoursSummary>(
      swapRouteParams(routes.USER_VOLUNTEERING_HOURS_ADD, { object_id: createHoursParams.gig_id }),
      { ...createHoursParams },
    );
    return response.data;
  }

  export async function logVolunteeringHoursSelectedBulk(
    object_id: string,
    createHoursParams: ILoggedVolunteerHoursCreateParamsFE[],
  ) {
    const response = await axios.post<ILoggedVolunteerHoursSummary[]>(
      swapRouteParams(routes.USER_VOLUNTEERING_HOURS_ADD_BULK, { object_id }),
      { createHoursParams },
    );
    return response.data;
  }

  export async function verifyVolunteerHours(gigId: string, volunteer_hour_ids: string[]) {
    const response = await axios.post<ILoggedVolunteerHoursSummary[]>(
      swapRouteParams(routes.USER_VOLUNTEERING_HOURS_VERIFY, { gigId }),
      { volunteer_hour_ids },
    );
    return response.data;
  }

  export async function getUserHubsForPermission(permission: string, params?: URLSearchParams) {
    const response = await axios.get<IGroup[]>(
      swapRouteParams(routes.GET_USER_HUBS_FOR_PERMISSION, { permission }),
      { params },
    );
    return response.data;
  }

  export async function getUserPages(id: string) {
    const response = await axios.get<IPage[]>(
      swapRouteParams(routes.GET_USER_PROFILE_PAGES, { id }),
    );
    return response.data;
  }

  export async function cancelRecurringDonation(_id: string) {
    const response = await axios.post(
      swapRouteParams(routes.CANCEL_USER_SUBSCRIPTION, { id: _id }),
    );
    return response.data;
  }

  export async function getUserSubscriptions() {
    const response = await axios.get<ITransactionSummary[]>(routes.GET_USER_SUBSCRIPTIONS);
    return response.data;
  }
  export async function getMySpotlights() {
    const response = await axios.get<any[]>(routes.GET_USER_SPOTLIGHTS);
    return response.data;
  }

  export async function followUser(id: string) {
    const response = await axios.post(swapRouteParams(routes.FOLLOW_USER, { id }));
    return response.data;
  }

  export async function unfollowUser(id: string) {
    const response = await axios.post(swapRouteParams(routes.UNFOLLOW_USER, { id }));
    return response.data;
  }

  export async function cancelUserSubscription(id: string) {
    const response = await axios.post(swapRouteParams(routes.CANCEL_USER_SUBSCRIPTION, { id }));
  }

  /**
   * Adds user as organizer contact
   * @param userId: string
   */
  export async function addOrganizerContact(userId: string): Promise<IUserContact> {
    const response = await axios.post(routes.ADD_ORGANIZER_CONTACT, { contact_user_id: userId });
    return response.data;
  }

  /**
   * Gets organizer contacts
   * @param objectType: string
   * @param objectId: string
   */
  export async function getOrganizerContacts(
    objectType: string,
    objectId: string,
  ): Promise<IUserContact[]> {
    const response = await axios.get(
      swapRouteParams(routes.GET_ORGANIZER_CONTACTS, {
        object_type: objectType,
        object_id: objectId,
      }),
    );
    return response.data;
  }

  /**
   * Gets available organizer contacts
   * @param objectType: string
   * @param objectId: string
   */
  export async function getAvailableOrganizerContacts(
    objectType: string,
    objectId: string,
  ): Promise<IUserContact[]> {
    const response = await axios.get(
      swapRouteParams(routes.GET_AVAILABLE_ORGANIZER_CONTACTS, {
        object_type: objectType,
        object_id: objectId,
      }),
    );
    return response.data;
  }

  export async function getCreditBalance() {
    const response = await axios.get<IUserCreditBalance>(routes.GET_USER_CREDIT_BALANCE);
    return response.data;
  }

  export async function getUserCreditBalanceForGroup(groupId: string) {
    const response = await axios.get<IUserCreditBalance>(
      swapRouteParams(routes.GET_USER_CREDIT_BALANCE_FOR_GROUP, { group_id: groupId }),
    );
    return response.data;
  }
}
