import React, { useState, useEffect } from 'react';
import { IDonationMatchingProgram, ITransactionSummary } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import {
  formatCardIcon,
  formatCurrency,
  defaultCurrency,
  capitalizeString,
} from '../../../helpers';
import { uiConstants } from '../../../constants/uiConstants';
import Modal from '../../Modal/Modal';
import './DonationListExpandedCell.scss';
import { useLocale } from '../../../hooks';
import useToastDispatcher from '../../../hooks/useToaster';
import { hubRequestActions, accountRequestActions } from '../../../requestActions';
import Loader from '../../Loader/Loader';

interface IProps {
  donation: ITransactionSummary;
}

enum EMainTypes {
  DONOR,
  DONATION,
  BILLING,
  MATCHING,
}

export interface IExpandedDetailsDonationListDataItem {
  icon?: string;
  iconClass?: string;
  img?: string;
  imgClass?: string;
  className?: string;
  content?: React.ReactNode;
  contentNoTranslate?: boolean;
}

export interface IExpandedDetailsDonationListData {
  type: EMainTypes;
  title: string;
  items?: IExpandedDetailsDonationListDataItem[];
}

interface ICardInfo {
  cardBrand: string;
  last4Digits: string;
}

interface IShowModalMore {
  visible: boolean;
  text: string;
}

export const DonationListExpandedCell = (props: IProps) => {
  const locale = useLocale();
  const [cardInfo, setCardInfo] = useState<ICardInfo | null>();
  const [donationMatchingProgram, setDonationMatchingProgram] =
    useState<IDonationMatchingProgram | null>(null);
  const [showModalMore, setShowModalMore] = useState<IShowModalMore>({ visible: false, text: '' });
  const { dispatchToastError } = useToastDispatcher();
  const [donationData, setDonationData] = useState<IExpandedDetailsDonationListData[]>();
  const { donation } = props;

  async function handleGetDonaitonMathingProgram() {
    if (!!donation?.donation_match) {
      try {
        const result = await hubRequestActions.getHubDonationMatchingProgramById(
          donation.donation_match.hub_id,
          donation.donation_match.matching_program_id!,
        );
        setDonationMatchingProgram(result);
      } catch (error) {
        dispatchToastError(error, 'Get Program Info');
      }
    }
  }

  async function handleGetPaymentMethodDetails(params: {
    object_type: string;
    object_id: string;
    transactionId: string;
    hub_id?: string;
  }) {
    try {
      // const result = params?.hub_id
      //   ? await hubRequestActions.getPaymentMethodDetails(
      //       params.hub_id,
      //       params.object_type,
      //       params.object_id,
      //       params.transactionId,
      //     )
      const result = await accountRequestActions.getPaymentMethodDetails(
        params.object_type,
        params.object_id,
        params.transactionId,
      );

      setCardInfo({
        cardBrand: result.card_brand,
        last4Digits: result.last_4_digits,
      });
    } catch (error) {
      dispatchToastError(error, 'Payment Method Details');
    }
  }

  function makeExpendedDataItem(edInner: IExpandedDetailsDonationListData, inIndex: number) {
    const putContent = (item: IExpandedDetailsDonationListDataItem) => {
      return item?.contentNoTranslate ? (
        <div notranslate="yes">{item.content}</div>
      ) : (
        <div>{item.content}</div>
      );
    };

    return (
      <div
        className="item-container"
        key={inIndex}
      >
        <span className="title">{edInner.title}</span>
        <div className="items">
          {edInner.items?.map((item, i: number) => {
            if (item?.content || item?.icon || item?.img)
              return (
                <div
                  className={`item ${item?.className || ''}`}
                  key={i}
                >
                  {item?.img && (
                    <img
                      src={item.img}
                      className={`${item?.imgClass}`}
                    />
                  )}
                  {item?.icon && <i className={`${item.icon} ${item?.iconClass}`} />}
                  {item?.content && putContent(item)}
                </div>
              );
            return null;
          })}
        </div>
      </div>
    );
  }

  function makeExpandedDetailsData(): IExpandedDetailsDonationListData[] {
    const exchangeRate = donation.reporting_exchange_rate ?? 1;
    const currency = donation.reporting_currency ?? defaultCurrency;
    return [
      {
        type: EMainTypes.DONOR,
        title: 'Contact Details',
        items: [
          {
            content: donation?.user.email,
            contentNoTranslate: true,
          },
          {
            content: donation?.user.phone,
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.DONOR,
        title: 'Fund Selected',
        items: [
          {
            content: donation?.fund || 'None',
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.DONOR,
        title: 'Event',
        items: [
          {
            img: donation?.event_profile_image_url,
            imgClass: 'small',
            content: donation?.event_title || '-',
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.DONOR,
        title: 'Team',
        items: [
          {
            img: donation?.team?.profile_image_url,
            imgClass: 'small',
            content: donation?.team?.name || '-',
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.DONOR,
        title: 'Individual',
        items: [
          {
            img: donation?.individual?.user?.profile_image_url,
            imgClass: 'small',
            content: donation?.individual?.user?.display_name || '-',
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.BILLING,
        title: 'Billing Address',
        items: [
          {
            content: donation.user?.billing_address?.line1,
            contentNoTranslate: true,
          },
          {
            content: donation.user?.billing_address?.line2,
            contentNoTranslate: true,
          },
          {
            content: donation.user?.billing_address?.city,
            contentNoTranslate: true,
          },
          {
            content: donation.user?.billing_address?.postal_code,
            contentNoTranslate: true,
          },
          {
            content: donation.user?.billing_address?.state,
            contentNoTranslate: true,
          },
          {
            content: donation.user?.billing_address?.country,
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.BILLING,
        title: 'Tax Receipt Address',
        items: [
          {
            content: donation.user?.address?.line1,
            contentNoTranslate: true,
          },
          {
            content: donation.user?.address?.line2,
            contentNoTranslate: true,
          },
          {
            content: donation.user?.address?.city,
            contentNoTranslate: true,
          },
          {
            content: donation.user?.address?.postal_code,
            contentNoTranslate: true,
          },
          {
            content: donation.user?.address?.state,
            contentNoTranslate: true,
          },
          {
            content: donation.user?.address?.country,
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.BILLING,
        title: 'Tax Receipt No.',
        items: [
          {
            content: donation.receipt_number || '-',
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.BILLING,
        title: 'Payment Type',
        items: [
          {
            icon:
              donation.amounts?.payment_method === Constants.payment_method.card &&
              cardInfo?.cardBrand
                ? formatCardIcon(cardInfo.cardBrand)
                : undefined,
            iconClass: `card ${cardInfo?.cardBrand}`,
            content:
              donation.amounts?.payment_method === Constants.payment_method.card &&
              cardInfo?.cardBrand &&
              cardInfo?.last4Digits ? (
                <span>
                  <var data-var="credit_card_brand">{cardInfo.cardBrand}</var> ending in{' '}
                  <var data-var="credit_card_last_4">{cardInfo.last4Digits}</var>
                </span>
              ) : (
                donation.amounts?.payment_method
              ),
          },
        ],
      },
      {
        type: EMainTypes.BILLING,
        title: 'Tax Receipt Amount',
        items: [
          {
            content: formatCurrency(
              (donation.tax_receipt_amount ?? 0) * exchangeRate,
              currency,
              locale.currentLocale,
            ),
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.BILLING,
        title: 'Tax Receipt Status',
        items: [
          {
            content: capitalizeString(
              String(donation.tax_receipt_status?.code || '-')
                .split('_')
                .join(' '),
            ),
            className: `status ${donation.tax_receipt_status?.code || ''}`,
          },
        ],
      },
      {
        type: EMainTypes.DONATION,
        title: 'Dedicate My Donation',
        items: [
          {
            content: String(donation?.dedicate?.type || '-')
              .split('_')
              .join(' '),
          },
          {
            content: (
              <span notranslate="yes">
                {donation?.dedicate?.recepient?.last_name || ''}{' '}
                {donation?.dedicate?.recepient?.first_name || ''}
              </span>
            ),
          },
          {
            content: donation?.dedicate?.recepient?.email,
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.DONATION,
        title: 'Dedication Message',
        items: [
          {
            content: donation?.comments ? (
              donation?.comments?.length < uiConstants.expanded_details.text.max_length ? (
                donation?.comments
              ) : (
                <>
                  {donation?.comments
                    ?.split(' ')
                    .slice(0, 5)
                    .map((word) => word)}
                  ...
                  <span
                    className="more"
                    onClick={() => {
                      setShowModalMore({ visible: true, text: donation?.comments || '' });
                    }}
                  >
                    more
                  </span>
                </>
              )
            ) : (
              '-'
            ),
          },
        ],
      },
      {
        type: EMainTypes.DONATION,
        title: 'Message to Recipient',
        items: [
          {
            content: donation?.dedicate?.recepient?.message ? (
              donation?.dedicate?.recepient?.message?.length <
              uiConstants.expanded_details.text.max_length ? (
                donation?.dedicate?.recepient?.message
              ) : (
                <>
                  {donation?.dedicate?.recepient?.message
                    ?.split(' ')
                    .slice(0, 5)
                    .map((word) => word)}
                  ...
                  <span
                    className="more"
                    onClick={() => {
                      setShowModalMore({
                        visible: true,
                        text: donation?.dedicate?.recepient?.message || '',
                      });
                    }}
                  >
                    more
                  </span>
                </>
              )
            ) : (
              '-'
            ),
          },
        ],
      },
      {
        type: EMainTypes.MATCHING,
        title: 'Matching Company Name',
        items: [
          {
            content: donation.hub_title || '-',
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.MATCHING,
        title: 'Donation Match Ratio',
        items: [
          {
            content: donationMatchingProgram?.ratio || '-',
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.MATCHING,
        title: 'Matched Date',
        items: [
          {
            content: donationMatchingProgram?.created_at || '-',
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.MATCHING,
        title: 'Match Payment Status',
        items: [
          {
            content: donation.donation_match?.match_status.code || '-',
            className: `status ${donation.donation_match?.match_status.code || ''}`,
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.MATCHING,
        title: 'Match Payment Date',
        items: [
          {
            content: donationMatchingProgram?.created_at || '-',
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.MATCHING,
        title: 'Requested Match Amount',
        items: [
          {
            content: donationMatchingProgram?.amount_matched || '-',
            contentNoTranslate: true,
          },
        ],
      },
      {
        type: EMainTypes.MATCHING,
        title: 'Actual Amount Matched',
        items: [
          {
            content: donationMatchingProgram?.amount_payed_out || '-',
            contentNoTranslate: true,
          },
        ],
      },
    ];
  }

  function handleGetMainData(type: EMainTypes) {
    return donationData
      ?.filter((data) => data.type === type)
      .map((dataInner, inIndex) => {
        return makeExpendedDataItem(dataInner, inIndex);
      });
  }

  function getOwnerTypeAndId(donaiton: ITransactionSummary) {
    let object_id = '',
      object_type = '';

    if (donaiton?.event_id) {
      object_id = donaiton.event_id;
      object_type = uiConstants.ownerType.event;
    } else if (donaiton?.group_id) {
      object_id = donaiton.group_id;
      object_type = uiConstants.ownerType.group;
    } else if (donaiton?.hub_id) {
      object_id = donaiton.hub_id;
      object_type = uiConstants.ownerType.hub;
    }

    return {
      object_id,
      object_type,
    };
  }

  useEffect(() => {
    if (donation?.id && !donation?.account?.offline) {
      handleGetPaymentMethodDetails({
        ...getOwnerTypeAndId(donation),
        hub_id: donation?.hub_id,
        transactionId: donation.id,
      });
    }

    setDonationData(makeExpandedDetailsData());
  }, []);

  useEffect(() => {
    handleGetDonaitonMathingProgram();
  }, []);

  return (
    <>
      <div className="DonationListExpandedCell">
        {donation &&
          ((donation.amounts?.payment_method === Constants.payment_method.card &&
            !!cardInfo?.cardBrand &&
            !!cardInfo?.last4Digits) ||
            donation.amounts?.payment_method !== Constants.payment_method.card) && (
            <>
              <div className="main-sec">
                <span className="main-title">Donor Details</span>
                {handleGetMainData(EMainTypes.DONOR)}
              </div>
              <div className="main-sec">
                <span className="main-title">Donation Details</span>
                {handleGetMainData(EMainTypes.DONATION)}
              </div>
              <div className="main-sec">
                <span className="main-title">Billing Details</span>
                {handleGetMainData(EMainTypes.BILLING)}
              </div>
              <div className="main-sec">
                <span className="main-title">Donation Matching Details</span>
                {handleGetMainData(EMainTypes.MATCHING)}
              </div>
            </>
          )}
        {(!donation ||
          !(
            (donation.amounts?.payment_method === Constants.payment_method.card &&
              cardInfo?.cardBrand &&
              cardInfo?.last4Digits) ||
            donation.amounts?.payment_method !== Constants.payment_method.card
          )) && <Loader loading={!donation || !cardInfo} />}
      </div>
      <Modal
        show={showModalMore.visible}
        onClose={() => setShowModalMore({ visible: false, text: '' })}
      >
        <p>{showModalMore.text}</p>
      </Modal>
    </>
  );
};
