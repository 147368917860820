import React, { FC, Fragment, useState, useEffect, useRef } from 'react';
import './MyAgenda.scss';
import './../../../Scheduler/Scheduler.scss';
import axios from 'axios';
import { errorHelpers, formatQuery, routes, swapRouteParams } from '../../../../helpers';
import moment, { Moment } from 'moment';
import DateDayPicker from '../../../shared/DateDayPicker/DateDayPicker';
import {
  IAttendingEventSummary,
  IShiftUserSummary,
  IGigSummaryFE,
  IEventSummaryFE,
} from '@gigit/interfaces';
import queryString from 'query-string';
import { localizeHelpers } from '../../../../localizeHelpers';
import Loader from '../../../Loader/Loader';
import { IAppState } from '../../../../store';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../../shared/PopupMenu/PopupMenu';
import { userSelectors } from '../../../../selectors/user';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../Modal/Modal';
import { Prompt } from '../../../Prompt/Prompt';
import useToastDispatcher from '../../../../hooks/useToaster';
import Button from '../../../Button/Button';
import EmptyState from './../../../../assets/empty-agenda.png';
import { useHistory } from 'react-router-dom';
import VolunteerModal from '../../../VolunteerModal/VolunteerModal';
import { getGigsExport } from '../../../../actions/user';
import ReportHoursModal from '../../../ReportHoursModal/ReportHoursModal';
import { Constants } from '@gigit/constants';
import { userRequestActions } from '../../../../requestActions';
import VolunteerCard, {
  EStyleTypeVolunteerCard,
  EViewTypeVolunteerCard,
  IBasicInfoVolunteerCard,
} from '../../../VolunteerCard/VolunteerCard';
import {
  CardCommonEvent,
  EStyleTypeCardCommonEvent,
  EViewTypeCardCommonEvent,
} from '../../../CardCommonEvent/CardCommonEvent';
import { uiConstants } from '../../../../constants';
import AddToCalendarModal from '../../../shared/AddToCalendarModal/AddToCalendarModal';
import LogVolunteerHours from '../../../LogVolunteerHours/LogVolunteerHours';

interface IProps {}

// TODO: Add the agenda types in constants and use the constants in checks instead of strings.
interface IAgendaShiftSummary extends IShiftUserSummary {
  type: string;
}

interface IAgendaEventSummary extends IAttendingEventSummary {
  type: string;
}

interface ScheduleListSummary {
  day: string;
  date: string;
  dateMs: number;
  list: (IAgendaEventSummary | IAgendaShiftSummary)[];
}

interface IDropdownOption {
  label: string;
  value: string;
}

interface ITypeDropdownOptions {
  all: IDropdownOption;
  shifts: IDropdownOption;
  events: IDropdownOption;
}

interface ContactData {
  phone: string;
  email: string;
}

const MyAgenda: FC<IProps> = () => {
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const { dispatchToastError } = useToastDispatcher();
  const dispatch = useDispatch();

  const [showReportHoursModal, setShowReportHoursModal] = useState<boolean>(false);
  const [volunteerModalType, setVolunteerModalType] = useState<string | null>(null);
  const [showVolunteerModal, setShowVolunteerModal] = useState<boolean>(false);
  const [agendaType, setAgendaType] = useState<string>('all');
  const [scheduleList, setScheduleList] = useState<ScheduleListSummary[]>([]);
  const [startDate, setStartDate] = useState<Moment>(moment());
  const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);
  const [detailsIndex, setDetailsIndex] = useState<number | null>(0);
  const [detailsParentIndex, setDetailsParentIndex] = useState<number | null>(0);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [showContextMenuType, setShowContextMenuType] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [showDeclineShiftPrompt, setShowDeclineShiftPrompt] = useState<boolean>(false);
  const [currentShift, setCurrentShift] = useState<IAgendaShiftSummary | null>(null);
  const [contactData, setContactData] = useState<ContactData | null>(null);
  const [showFilterMobile, setShowFilterMobile] = useState<boolean>(false);
  const [activeShiftItem, setActiveShiftItem] = useState<IAgendaShiftSummary | null>(null);
  const [showAddToCalendarModal, setShowAddToCalendarModal] = useState<boolean>(false);

  const getShowMenuClassType = showContextMenuType ? 'show' : '';

  const detailsContextRef = useRef<HTMLDivElement | null>(null);
  const detailsRef = useRef<HTMLDivElement | null>(null);

  const history = useHistory();

  const typeOptions: ITypeDropdownOptions = {
    all: { value: 'all', label: 'All' },
    shifts: { value: 'shifts', label: 'Shifts' },
    events: { value: 'events', label: 'Events' },
  };

  const contextMenuItemsType: IPopupMenuItem[] = [
    {
      id: typeOptions.all.value,
      label: typeOptions.all.label,
      isSelected: agendaType === typeOptions.all.value,
      onClick: () => {
        setAgendaType(typeOptions.all.value);
        setShowContextMenuType(false);
      },
    },
    {
      id: typeOptions.shifts.value,
      label: typeOptions.shifts.label,
      isSelected: agendaType === typeOptions.shifts.value,
      onClick: () => {
        setAgendaType(typeOptions.shifts.value);
        setShowContextMenuType(false);
      },
    },
    {
      id: typeOptions.events.value,
      label: typeOptions.events.label,
      isSelected: agendaType === typeOptions.events.value,
      onClick: () => {
        setAgendaType(typeOptions.events.value);
        setShowContextMenuType(false);
      },
    },
  ];

  const urlGigs = routes.GET_USER_GIG_SHIFTS;
  const urlAttending = routes.GET_USER_ATTENDING;

  const popupMenuConfigSortBy: IShowPopupConfig = {
    showMenu: showContextMenuType,
    setShowMenu: setShowContextMenuType,
    position: {
      type: 'bottom',
    },
  };

  useEffect(() => {
    setIsDataLoading(true);
    getScheduleList();
  }, [agendaType, startDate]);

  useEffect(() => {
    setIsDataLoading(true);
    getScheduleList();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (detailsRef.current) {
        if (!detailsRef.current.contains(event.target as Node)) {
          setTimeout(() => {
            setDetailsExpanded(false);
          }, 100);
        }
      }
    };
    if (detailsExpanded) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [detailsExpanded]);

  const getScheduleList = () => {
    let query = {
      start_date: startDate.toDate().toISOString(),
    };
    let shiftRequest = axios.get(urlGigs + `?${queryString.stringify(query)}`);
    let eventRequest = axios.get(urlAttending + `?${queryString.stringify(query)}`);
    if (agendaType === 'shifts') {
      shiftRequest
        .then((resp) => {
          setScheduleGrouping(resp.data);
        })
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          dispatchToastError(errorObj, 'My Agenda');
        });
    } else if (agendaType === 'events') {
      eventRequest
        .then((resp) => {
          setScheduleGrouping(resp.data);
        })
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          dispatchToastError(errorObj, 'My Agenda');
        });
    } else {
      axios
        .all([shiftRequest, eventRequest])
        .then(
          axios.spread(function (...responses) {
            let respData = responses[0].data;

            respData = respData.concat(responses[1].data);
            setScheduleGrouping(respData);
          }),
        )
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          dispatchToastError(errorObj, 'My Agenda');
        });
    }
  };

  const setScheduleGrouping = (data: (IAgendaShiftSummary | IAgendaEventSummary)[]) => {
    let dates: (string | undefined)[] = data.map(
      (summary) => summary.start_date?.toString().split('T')[0],
    );

    let uniqueDates: (string | undefined)[] = Array.from(new Set(dates));

    let groupedData: ScheduleListSummary[] = [];

    data.forEach((element: IAgendaShiftSummary | IAgendaEventSummary) => {
      if ('shift_id' in element || 'gig' in element) {
        element.type = 'shift';
      } else {
        element.type = 'event';
      }
    });

    const weekDays = localizeHelpers.getWeekDays();
    const weekDaysShort = weekDays.map((day) => day.substring(0, 3));

    uniqueDates.forEach((startDate: string | undefined) => {
      groupedData.push({
        day: `${weekDaysShort[moment(startDate).day()]}`,
        date: `${startDate?.split('-')[2]}`,
        dateMs: new Date(moment(startDate).toDate()).getTime(),
        list: data.filter((item) =>
          item.start_date?.toString().includes(startDate ? startDate : ''),
        ),
      });
    });

    groupedData = groupedData.sort((a, b) => {
      if (a.dateMs > b.dateMs) {
        return 1;
      } else {
        return -1;
      }
    });

    setScheduleList(groupedData);
    setIsDataLoading(false);
  };

  const handleDateChange = (date: Moment) => {
    setStartDate(date);
  };

  const handleCancelShift = (shift: IAgendaShiftSummary | null) => {
    if (shift) {
      const route = swapRouteParams(routes.DECLINE_SHIFT_USER, {
        gig_id: shift.gig_id,
        shift_id: shift.shift_id,
      });

      axios
        .delete(route)
        .then((response) => {
          setIsDataLoading(true);
          getScheduleList();
        })
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          dispatchToastError(errorObj, 'My Agenda');
        });
    }
  };

  const handleContactModal = (data: IAgendaEventSummary | IAgendaShiftSummary) => {
    if (!data.contact_details?.phone && !data.contact_details?.email) {
      const errorObj = localizeHelpers.translate(
        `This organizer hasn't setup any contact info yet.`,
      );
      dispatchToastError(errorObj, 'Contact organizer');
    } else {
      setContactData(data.contact_details);
      setShowContactModal(true);
    }
  };

  const gigDetailsComponent = (data: IAgendaShiftSummary | IAgendaEventSummary) => {
    let gigBasic: IBasicInfoVolunteerCard;
    if (data.type === Constants.object_type.event) {
      const temporaryData = data as IAgendaEventSummary;
      gigBasic = {
        profile_image_url: '',
        title: temporaryData.event_title,
        description: '',
        locations: [temporaryData.location],
        start_date: temporaryData.start_date,
        owner_title: temporaryData.group_title || '',
        owner_type: Constants.object_type.group,
        owner_handle: temporaryData.group_handle || '',
        handle: temporaryData.event_handle,
        id: temporaryData.event_id,
      };
    } else {
      const temporaryData = data as IAgendaShiftSummary;
      gigBasic = {
        profile_image_url:
          temporaryData.profile_image_url || temporaryData?.gig?.profile_image_url || '',
        title: temporaryData.gig_title || temporaryData?.gig?.title || '',
        description: temporaryData.gig_description || temporaryData?.gig?.description,
        locations: temporaryData.location
          ? [temporaryData.location]
          : temporaryData?.gig?.locations,
        start_date:
          temporaryData.start_date?.toString() || temporaryData?.gig?.start_date?.toString() || '',
        owner_title: temporaryData.group_title || '',
        owner_type: Constants.object_type.group,
        owner_handle: temporaryData.group_handle || '',
        handle: temporaryData.gig_handle || temporaryData?.gig?.handle || '',
        id: temporaryData.gig_id || temporaryData?.gig?.id || '',
        is_volunteer_match: !!temporaryData?.gig?.external_id?.VolunteerMatch,
        is_virtual: temporaryData?.gig?.is_virtual,
      };
    }

    function renderPropsForVolunteerCard() {
      return {
        viewType: EViewTypeVolunteerCard.GRID,
        styleType: EStyleTypeVolunteerCard.SCHEDULE,
        logHourClick: () => {
          setVolunteerModalType(uiConstants.volunteer_modal_types.log_single);
          setShowVolunteerModal(true);
          setDetailsExpanded(false);
        },
        scheduleMenuItems:
          data.type === Constants.object_type.event
            ? [
                {
                  id: 'contact_organizer',
                  label: 'Contact organizer',
                  onClick: () => {
                    handleContactModal(data);
                  },
                },
                {
                  id: 'view_event',
                  label: 'View event',
                  onClick: () => {
                    history.push(`/event/${(data as IAgendaEventSummary).event_handle}`);
                  },
                },
              ]
            : [
                {
                  id: 'contact_organizer',
                  label: 'Contact organizer',
                  onClick: () => {
                    handleContactModal(data);
                  },
                },
                {
                  id: 'view_volunteer_hours',
                  label: 'View Volunteer Hours',
                  onClick: () => {
                    setActiveShiftItem(data as IAgendaShiftSummary);
                    setVolunteerModalType(uiConstants.volunteer_modal_types.volunteer_hr_single);
                    setShowVolunteerModal(true);
                  },
                },
                {
                  id: 'add_to_calendar',
                  label: 'Add To Calendar',
                  onClick: () => {
                    setActiveShiftItem(data as IAgendaShiftSummary);
                    setShowAddToCalendarModal(true);
                  },
                },
                {
                  id: 'cancel_shift',
                  label: 'Cancel shift',
                  onClick: () => {
                    setShowDeclineShiftPrompt(true);
                    setCurrentShift(data as IAgendaShiftSummary);
                  },
                },
              ],
        gigBasic,
      };
    }

    return (
      <Fragment>
        <div
          ref={detailsRef}
          className="details-container"
        >
          <div
            className={`details-point ${data.type === Constants.object_type.event ? 'events' : 'shifts'}`}
          />
          <div
            className={
              data.type === Constants.object_type.event
                ? 'details-header events'
                : 'details-header shifts'
            }
          >
            {data.type === Constants.object_type.event ? 'Event' : 'Shift'}
            <span
              onClick={() => {
                setDetailsExpanded(false);
                setDetailsIndex(null);
              }}
              className="mobile-close-details"
            >
              <i className="fas fa-times" />
            </span>
          </div>
          <VolunteerCard {...renderPropsForVolunteerCard()} />
        </div>
      </Fragment>
    );
  };

  const eventDetailsComponent = (data: IAgendaShiftSummary | IAgendaEventSummary) => {
    const tempEventData = data as IAgendaEventSummary;

    return (
      <Fragment>
        <div
          ref={detailsRef}
          className="details-container"
        >
          <div
            className={`details-point ${data.type === Constants.object_type.event ? 'events' : 'shifts'}`}
          />
          <div
            className={
              data.type === Constants.object_type.event
                ? 'details-header events'
                : 'details-header shifts'
            }
          >
            {data.type === Constants.object_type.event ? 'Event' : 'Shift'}
            <span
              onClick={() => {
                setDetailsExpanded(false);
                setDetailsIndex(null);
              }}
              className="mobile-close-details"
            >
              <i className="fas fa-times" />
            </span>
          </div>
          <CardCommonEvent
            event={tempEventData.attending_event as any}
            viewType={EViewTypeCardCommonEvent.GRID}
            styleType={EStyleTypeCardCommonEvent.SCHEDULE}
          />
        </div>
      </Fragment>
    );
  };

  return (
    <div className={isDataLoading ? 'MyAgenda fixed' : 'MyAgenda'}>
      <div className="agenda-loader">
        <Loader loading={isDataLoading} />
      </div>
      <div className={`mobile-filter-container ${showFilterMobile ? 'active' : ''}`}>
        <div className="filter-header">
          <i className="far fa-filter" />
          <span>Filter</span>
        </div>
        <div className="filter-content">
          <span>Type</span>
          {contextMenuItemsType.map((item, index) => (
            <div
              className={`filter-item ${agendaType === item.id ? 'active' : ''}`}
              key={index}
              onClick={() => {
                setAgendaType(item.id);
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className="btn-container">
          <button
            onClick={() => {
              setShowFilterMobile(false);
            }}
          >
            Cancel
          </button>
          <button
            className="submit"
            onClick={async () => {
              setShowFilterMobile(false);
              await getScheduleList();
            }}
          >
            Apply
          </button>
        </div>
      </div>
      <div className="mobile-filter-button">
        <span
          onClick={() => {
            setShowFilterMobile(true);
          }}
        >
          <i className="far fa-filter" />
        </span>
      </div>
      <div className="header-btn-container">
        <Button
          onClick={() => setShowReportHoursModal(true)}
          icon="far fa-clock"
          text="Log Hours"
          buttonType="dark"
        />
        <Button
          onClick={() => dispatch(getGigsExport())}
          icon="far fa-file-download"
          text="Export"
          buttonType="dark"
        />
      </div>
      <div className="agenda-subheader">
        <div className="AgendaDatePicker">
          <DateDayPicker
            label="Date"
            variant="floating"
            value={startDate}
            locale={locale}
            onDateChange={handleDateChange}
          />
        </div>
        <PopupMenu
          menuItems={contextMenuItemsType}
          showMenuConfig={popupMenuConfigSortBy}
          popupMenuClass="applications-popupMenu-type"
          className={`Dropdown filter-dropdown type ${getShowMenuClassType}`}
          onClick={() => setShowContextMenuType(!showContextMenuType)}
        >
          <label>
            <span>Type</span>
          </label>
          <div className="input-wrap">
            <span className="label">{typeOptions.all.label}</span>
            <i className={`fas fa-caret-down menu-btn ${getShowMenuClassType}`} />
          </div>
        </PopupMenu>
      </div>
      <div className="agenda-content-wrapper">
        <div>
          {scheduleList.length ? (
            <div className="list-separator">
              <div className="sep-circle" />
              <div className="sep-line" />
            </div>
          ) : (
            ''
          )}
          {scheduleList.length ? (
            scheduleList.map((listItem: ScheduleListSummary, listItemIndex: number) => {
              return (
                <div
                  key={listItemIndex}
                  className="date-group"
                >
                  <div className="group-day">
                    <span>{listItem.day}</span>
                    <br />
                    <span
                      notranslate="yes"
                      className="schedule-date"
                    >
                      {listItem.date}
                    </span>
                  </div>
                  <div className="group-list">
                    {listItem.list.map(
                      (item: IAgendaEventSummary | IAgendaShiftSummary, itemIndex: number) => {
                        return (
                          <div
                            key={itemIndex}
                            className={
                              item.type === 'event'
                                ? `schedule-view event ${
                                    detailsExpanded &&
                                    detailsIndex === itemIndex &&
                                    detailsParentIndex === listItemIndex
                                      ? 'active'
                                      : ''
                                  }`
                                : `schedule-view shift ${
                                    detailsExpanded &&
                                    detailsIndex === itemIndex &&
                                    detailsParentIndex === listItemIndex
                                      ? 'active'
                                      : ''
                                  }`
                            }
                            onClick={() => {
                              if (!detailsExpanded) {
                                if (item.type === 'shift') {
                                  setDetailsExpanded(true);
                                  setDetailsIndex(itemIndex);
                                  setDetailsParentIndex(listItemIndex);
                                  setActiveShiftItem(item as IAgendaShiftSummary);
                                } else {
                                  setDetailsExpanded(true);
                                  setDetailsIndex(itemIndex);
                                  setDetailsParentIndex(listItemIndex);
                                }
                              }
                            }}
                          >
                            <div className="schedule-title">
                              <span
                                notranslate="yes"
                                title={item.event_title || (item as IAgendaShiftSummary).gig?.title}
                                className="title-line"
                              >
                                {item.event_title || (item as IAgendaShiftSummary).gig?.title}
                              </span>
                              <span notranslate="yes">{item.group_title}</span>
                            </div>
                            <div
                              notranslate="yes"
                              className="schedule-time"
                            >
                              {moment(item.start_date).format('h:mm A')} -{' '}
                              {moment(item.end_date).format('h:mm A')}
                            </div>
                            {item.location && (
                              <div
                                notranslate="yes"
                                className="schedule-location"
                              >
                                <i className="fas fa-map-marker-alt" />{' '}
                                {`${item.location.line1}${
                                  item.location.city ? ', ' + item.location.city : ''
                                }${item.location.state ? ', ' + item.location.state : ''}`}
                              </div>
                            )}
                            {detailsExpanded &&
                              item.type !== 'event' &&
                              detailsIndex === itemIndex &&
                              detailsParentIndex === listItemIndex &&
                              gigDetailsComponent(item)}
                            {detailsExpanded &&
                              item.type === 'event' &&
                              detailsIndex === itemIndex &&
                              detailsParentIndex === listItemIndex &&
                              eventDetailsComponent(item)}
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="agenda-empty-state">
              <img src={EmptyState} />
              <span>Your agenda is empty</span>
            </div>
          )}
        </div>
      </div>
      <Prompt
        show={showDeclineShiftPrompt}
        title="Delete Shift"
        message=""
        yesMessage="Yes"
        yesClass="fa fa-ban"
        yesStyle="delete"
        cancelMessage="Cancel"
        onClose={() => {
          setShowDeclineShiftPrompt(false);
          setCurrentShift(null);
        }}
        onYes={() => handleCancelShift(currentShift)}
      />
      <Modal
        show={showContactModal}
        onClose={() => setShowContactModal(false)}
        title="Contact Organizer"
      >
        <div
          className="contact-options"
          notranslate="yes"
        >
          {contactData?.email && <a href={`mailto:${contactData?.email}`}>{contactData?.email}</a>}

          {contactData?.phone && <a href={`tel:${contactData?.phone}`}>{contactData?.phone}</a>}
        </div>
        <div className="actions">
          <Button onClick={() => setShowContactModal(false)}>Close</Button>
        </div>
      </Modal>

      {volunteerModalType && activeShiftItem?.gig_id && (
        <VolunteerModal
          showModal={showVolunteerModal}
          onCloseModal={() => {
            setShowVolunteerModal(false);
            setVolunteerModalType(null);
          }}
          type={volunteerModalType}
          gigId={activeShiftItem.gig_id}
          start_date={activeShiftItem.start_date}
          end_date={activeShiftItem.end_date}
          onLogHours={() => getScheduleList()}
        />
      )}

      <Modal
        class="LogVolunteerHoursModal"
        show={showReportHoursModal}
        onClose={() => setShowReportHoursModal(false)}
      >
        <LogVolunteerHours onHoursSubmitted={() => setShowReportHoursModal(false)} />
      </Modal>

      {activeShiftItem && (
        <AddToCalendarModal
          showModal={showAddToCalendarModal}
          onCloseModal={() => {
            setShowAddToCalendarModal(false);
          }}
          eventToAdd={{
            title: 'Volunteer Shift',
            start: activeShiftItem.start_date ? activeShiftItem.start_date : new Date(),
            end: activeShiftItem.end_date ? activeShiftItem.end_date : new Date(),
          }}
        />
      )}
    </div>
  );
};

export default MyAgenda;
