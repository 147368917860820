import React, { ReactNode } from 'react';

import './PageSidebar.scss';

interface IProps {
  children?: ReactNode;
}

interface IState {}

/** Renders a sidebar. */
export class PageSidebar extends React.Component<IProps, IState> {
  render() {
    return <div className="PageSidebar">{this.props.children}</div>;
  }
}
