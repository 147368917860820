import { QueryClient, useQuery } from '@tanstack/react-query';
import React, { useMemo, useReducer } from 'react';
import { useEffect } from 'react';
import { formatQuery, IQueryParams } from '../helpers';

type Error = unknown | null;

// const queryClient = new QueryClient({
//     defaultOptions: {
//       queries: {
//         queryFn: defaultQueryFn,
//       },
//     },
//   })

function defaultQueryFunction() {}

function useListQueryAction() {
  // const { } = useQuery({ queryKey: ['whatever']})
}

interface IQueryClientConfig {
  /** Number of items you wish to get back from the request */
  pageSize: number;
  /** Additional queryParams you want to apply to the provided queryAction */
  queryParams?: IQueryParams;
}

interface IPage<T> {
  data: T[];
  isLoading: boolean;
  error: Error;
}

interface IState<T> {
  currentPageIndex: number;
  pages: Array<IPage<T>>;
  error: Error;
  pageCount: number;
  hasMore: boolean;
}

interface IQueryClientReturnType<T> {
  /** Provides data, and pagination meta data for the current page */
  currentPage: IPage<T>;
  currentPageIndex: number;
  /** Current page size */
  pageSize: number;
  /** A list of all pages */
  pages: IPage<T>[];
  /** Error returned from requestAction */
  error: unknown;
  /** @see {pages} current length */
  pageCount: number;
  hasMore: boolean;
  canGoNext: boolean;
  canGoPrev: boolean;
  /** Only fired if @see {canGoNext} is true */
  goNextPage: () => void;
  /** Only fired if @see {canGoPrev} is true */
  goPrevPage: () => void;
  /** Runs the action provided, and refreshes the current page data */
  refreshCurrentPage: () => void;
}

//Action types implementing discriminating unions
type Action<T> =
  | {
      type: 'FETCH_PAGE';
      payload: { index: number };
    }
  | {
      type: 'FETCH_PAGE_COMPLETE';
      payload: {
        index: number;
        pageData: T[];
        hasMore: boolean;
      };
    }
  | {
      type: 'FETCH_PAGE_ERROR';
      payload: {
        index: number;
        error: Error;
      };
    }
  | {
      type: 'NEXT_PAGE';
    }
  | {
      type: 'PREV_PAGE';
    };

/** Returns a new array with the target index replaced, if no element is found at the index,
 * the new element is pushed on */
function addOrReplaceElementAtIndex<T>(array: T[], index: number, newElement: T): T[] {
  const existingElementToReplace = array[index] || null;
  let newArray = [...array];

  if (existingElementToReplace != null) {
    newArray[index] = newElement;
  } else {
    newArray = [...array, newElement];
  }
  return newArray;
}

//TODO: Implement optimistic/pre fetching 1+ from current index
//      This will allow a quicker pagination experience for the user
//TODO: Implement hydration of existing pages based on currentIndex and time ?
//TODO: Implement infinite scroll helper (Nice to have, but not really worth the effort)
//TODO: Implement export utility method for current page data? Not sure.

/**
 * This hook manages pagination for the provided queryAction. The queryAction
 * is ran when the component first mounts, returning state required for
 * rendering error state, empty states, loading states, or data to render the
 * appropriate UI elements.
 *
 * @param requestAction - An action that follows our "requestAction" pattern, the action
 *                      needs a URLSearchParams param in order for the pagination
 *                      to work.
 *
 * @param config - Config object which takes in the intended pageSize and any additional
 *                 IQueryParams required for the action.
 *
 * @returns {IQueryClientReturnType}
 */
function usePaginatedQuery<T = unknown>(
  // queryKey: string,
  requestAction: (search: URLSearchParams, ...args: any[]) => Promise<T[]>,
  config: IQueryClientConfig,
): IQueryClientReturnType<T> {
  //Here we increase the request limit by 1 to see if we can load more data
  const requestLimit = config.pageSize + 1;
  const initialPageState: IPage<T> = {
    data: [],
    isLoading: false,
    error: null,
  };

  const queryClientReducer = (state: IState<T>, action: Action<T>): IState<T> => {
    switch (action.type) {
      case 'FETCH_PAGE': {
        const updatedPage: IPage<T> = {
          ...initialPageState,
          isLoading: true,
        };
        const newPages = addOrReplaceElementAtIndex(state.pages, action.payload.index, updatedPage);

        return {
          ...state,
          pages: newPages,
          pageCount: newPages.length,
        };
      }
      case 'FETCH_PAGE_COMPLETE': {
        const updatedPage: IPage<T> = {
          data: action.payload.pageData,
          error: null,
          isLoading: false,
        };
        const newPages = addOrReplaceElementAtIndex(state.pages, action.payload.index, updatedPage);

        return {
          ...state,
          pages: newPages,
          hasMore: action.payload.hasMore,
          error: null,
        };
      }
      case 'FETCH_PAGE_ERROR': {
        const newPage: IPage<T> = {
          ...initialPageState,
          error: action.payload.error,
        };

        const updatedPages = addOrReplaceElementAtIndex(state.pages, action.payload.index, newPage);

        return {
          ...state,
          pages: updatedPages,
          pageCount: updatedPages.length,
          error: action.payload.error,
        };
      }
      case 'NEXT_PAGE': {
        return {
          ...state,
          currentPageIndex: state.currentPageIndex + 1,
        };
      }
      case 'PREV_PAGE': {
        return {
          ...state,
          currentPageIndex: state.currentPageIndex - 1,
        };
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(queryClientReducer, {
    currentPageIndex: 0,
    pages: [],
    pageCount: 0,
    error: null,
    hasMore: false,
  });

  const currentPage = useMemo(
    () => state.pages[state.currentPageIndex] || initialPageState,
    [state.pages],
  );
  const canGoNext = state.hasMore;
  const canGoPrev = state.currentPageIndex > 0;

  useEffect(() => {
    fetchPage(state.currentPageIndex);
  }, [
    state.currentPageIndex,
    config.pageSize,
    config.queryParams?.filters,
    config.queryParams?.search,
    config.queryParams?.sort,
  ]);

  // async function fetchData(pageIndex: number) {
  //     const {
  //         isLoading,
  //         isFetching,
  //         isError,
  //         error,
  //         data,
  //         isPreviousData,
  //     } = useQuery({
  //         queryKey: [
  //             'yep'
  //         ]
  //     })
  // }

  /**
   * Fetches a page with a provided index, this index is used in conjunction with
   * pageSize to manage SKIP and LIMIT passed to the queryAction.
   *
   * @param index Index of page wanted to be fetched
   * @param shouldPreFetch - WIP: not implemented yet.
   */
  const fetchPage = async (index: number, shouldPreFetch?: boolean) => {
    //TODO: WIP: Implement pre-fetching 1+ page from index
    dispatch({ type: 'FETCH_PAGE', payload: { index } });
    try {
      const pageIndex = shouldPreFetch ? index + 1 : index;
      const skip = pageIndex > 0 ? pageIndex * config.pageSize : 0;
      const search = formatQuery({
        ...config.queryParams,
        limit: requestLimit.toString(),
        skip: skip.toString(),
      });
      let pageData = (await requestAction(search)) as T[];
      const hasMore = pageData.length > config.pageSize;

      if (hasMore) {
        //We need to remove the extra item returned from our action
        pageData = pageData.filter((_, idx) => idx !== pageData.length - 1);
      }

      dispatch({
        type: 'FETCH_PAGE_COMPLETE',
        payload: {
          index,
          pageData,
          hasMore,
        },
      });
    } catch (error) {
      dispatch({
        type: 'FETCH_PAGE_ERROR',
        payload: {
          index,
          error,
        },
      });
    }
  };

  const handleGoToPrevPage = () => {
    if (canGoPrev) {
      dispatch({ type: 'PREV_PAGE' });
    }
  };

  const handleGoToNextPage = () => {
    if (canGoNext) {
      dispatch({ type: 'NEXT_PAGE' });
    }
  };

  const handleRefreshCurrentPage = () => {
    fetchPage(state.currentPageIndex);
  };

  return {
    currentPage,
    canGoNext,
    canGoPrev,
    refreshCurrentPage: handleRefreshCurrentPage,
    goNextPage: handleGoToNextPage,
    goPrevPage: handleGoToPrevPage,
    pageSize: config.pageSize,
    ...state,
  };
}

export default usePaginatedQuery;
