import './ChangeStripeAccount.scss';
import React, { FC, useState, useEffect } from 'react';
import Button from '../../Button/Button';
import { typeHelpers } from '../../../helpers';
import { IAccountDetails } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';

interface IProps {
  onClose(): void;
  accounts: IAccountDetails[];
  selectedAccountId: string | null;
  onSave(accountId: string): void;
  addNewAccount(): void;
  objectType: string;
}

const ChangeStripeAccount: FC<IProps> = (props) => {
  const { onClose, addNewAccount, onSave, selectedAccountId, accounts, objectType } = props;
  const [selectedAccId, setSelectedAccId] = useState<string | null>(null);

  useEffect(() => {
    if (selectedAccountId) {
      setSelectedAccId(selectedAccountId);
    }
  }, [selectedAccountId]);

  return (
    <div className="ChangeStripeAccount">
      <h2>Select a Stripe Account</h2>
      <div className="ChangeStripeAccount-list">
        {accounts.map((acc, index) => (
          <div
            key={index}
            className="ChangeStripeAccount-list--item"
            onClick={() => setSelectedAccId(acc.id)}
          >
            <span className="value">
              <i className={`${selectedAccId === acc.id ? 'fas' : 'fal'} fa-circle`} />
              {acc.account_number}
            </span>

            {selectedAccountId === acc.id && <span className="status">Active</span>}
          </div>
        ))}
        {objectType !== Constants.object_type.hub && (
          <div
            className="ChangeStripeAccount-list--add"
            onClick={addNewAccount}
          >
            <span>
              <i className="fal fa-plus" />
              Add New Account
            </span>
          </div>
        )}
      </div>
      <div className="ChangeStripeAccount-actions">
        <Button
          buttonType="outline-dark"
          text="Cancel"
          type="button"
          onClick={onClose}
        />
        <Button
          buttonType="dark"
          text="Save Changes"
          isDisabled={!selectedAccId}
          type="button"
          onClick={() => {
            typeHelpers.assertNotNullOrUndefined(selectedAccId);
            onSave(selectedAccId);
          }}
        />
      </div>
    </div>
  );
};

export default ChangeStripeAccount;
