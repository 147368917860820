import { Config } from '@gigit/config';
import { IConversationSummary, IUser, IUserBase } from '@gigit/interfaces';
import axios from 'axios';
import React from 'react';
import { routes, swapRouteParams, toastError, toastSuccess } from '../../../helpers';
import { IToast } from '../../../interfaces';
import { localizeHelpers } from '../../../localizeHelpers';
import { BlockSettings } from '../../Chat/Settings/BlockSettings';
import ContextMenu, { IContextMenuItem } from '../../ContextMenu/ContextMenu';
import Modal from '../../Modal/Modal';
import { Prompt } from '../../Prompt/Prompt';

import './ConversationActions.scss';

interface IProps {
  conversation: IConversationSummary;
  currentUserId: string | undefined;
  onClose(): void;
}

interface IState {
  actions: Array<IContextMenuItem>;
  showBlockModal: boolean;
  showConversationToDelete: boolean;
}

export class ConversationActions extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      actions: [
        {
          icon: 'far fa-ban',
          onClick: () => {
            this.onBlockUser();
          },
          label: 'Block User',
        },
        {
          icon: 'far fa-trash',
          onClick: () => {
            this.onArchiveConversation();
          },
          label: 'Delete Conversation',
        },
      ],
      showBlockModal: false,
      showConversationToDelete: false,
    };
  }

  archiveConversation() {
    axios
      .put(
        Config.web.REACT_APP_CHAT_GATEWAY +
          swapRouteParams(routes.ARCHIVE_CONVERSATION, { id: this.props.conversation.id }),
      )
      .then((response) => {
        this.props.onClose();
      })
      .catch((error) => {
        // localize
        const toast = toastError(error?.message, 'Archive Error');
        //this.props.createToast(toast);
      });
  }

  onArchiveConversation() {
    this.setState({
      showConversationToDelete: true,
    });
  }

  onBlockUser() {
    if (this.props.conversation?.users?.length) {
      if (this.props.conversation.users.length > 2) {
        this.setState({
          showBlockModal: true,
        });
      } else {
        let user = this.props.conversation.users.find(
          (user: IUserBase) => user.id !== this.props.currentUserId,
        );
        if (user && user?.id) {
          this.blockUser(user.id);
        }
      }
    }
  }

  blockUser(userId: string) {
    let user = this.props.conversation.users?.find((user: IUserBase) => user.id === userId);

    axios
      .post(Config.web.REACT_APP_CHAT_GATEWAY + swapRouteParams(routes.BLOCK_CHAT_USER, { userId }))
      .then((response) => {
        //toast success close conversation recall list
        const toast = toastSuccess(
          localizeHelpers.translate(
            `You've blocked ${user?.display_name} from contacting you further.`,
          ),
          'Block User',
        );
        //this.props.createToast(toast);
      })
      .catch((error) => {
        // localize
        const toast = toastError(error?.message, 'Block Error.');
        //this.props.createToast(toast);
      });
  }

  addUserToChat(user: IUserBase) {
    // TODO: needs implemented
  }

  removeUserFromChat(user: IUserBase) {
    // TODO: needs implemented
  }

  render() {
    return (
      <div className="ConversationActions">
        <div className="actions">
          <i className="fa fa-ellipsis-vertical"></i>
          <ContextMenu
            onMouseLeave={() => {}}
            showMenu={true}
            menuItems={this.state.actions}
          />
        </div>

        <Prompt
          show={this.state.showConversationToDelete}
          message=""
          title="Delete Conversation"
          yesMessage="Delete Conversation"
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage="Cancel"
          onClose={() => this.setState({ showConversationToDelete: false })}
          onYes={() => this.archiveConversation()}
        />

        <Modal
          show={this.state.showBlockModal}
          onClose={() => {
            this.setState({ showBlockModal: false });
          }}
        >
          <BlockSettings />
        </Modal>
      </div>
    );
  }
}
