import React from 'react';
import io from 'socket.io-client';
import { Config } from '@gigit/config';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  IBroadcastMessage,
  IEventIndividual,
  IFundraiserDonation,
  IGroup,
} from '@gigit/interfaces';
import { IAppState } from '../../../store';
import { defaultCurrency, errorHelpers, formatCurrency, toastError } from '../../../helpers';
import { userSelectors } from '../../../selectors/user';
import { IUserState } from '../../../reducers/user';
import { IEventState } from '../../../reducers/event';
import { BenefitingCharityTile } from '../../../components/BenefitingCharityTile/BenefitingCharityTile';
import { individualRequestActions } from '../../../requestActions/individual';
import { createToast } from '../../../actions/toaster';
import { uiConstants } from '../../../constants/uiConstants';
import Portrait from '../../../components/Portrait/Portrait';
import QuillTextEditor from '../../../components/QuillTextEditor/QuillTextEditor';
import StravaSection from '../../../components/StravaSection/StravaSection';
import './IndividualStreamer.scss';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  userState: IUserState;
  eventState: IEventState;
  individual: any;
  locale: string;
}

interface IState {
  editStory: boolean;
  eventIndividual: IEventIndividual | null;
  individualDonations: IFundraiserDonation[];
  isIndividualLoading: boolean;
  donations: any[];
  raised: number;
  goal: number;
  innerWidth: number;
  selectedTab: string;
}

class IndividualStreamer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      editStory: false,
      eventIndividual: null,
      individualDonations: [],
      isIndividualLoading: false,
      donations: [],
      raised: this.props.individual.raised || 0,
      goal: this.props.individual.goal || 0,
      innerWidth: window.innerWidth,
      selectedTab: 'details',
    };

    this.handleStoryChange = this.handleStoryChange.bind(this);
    this.saveIndividual = this.saveIndividual.bind(this);
    this.addDonation = this.addDonation.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    let socket = io(Config.web.REACT_APP_CHAT_GATEWAY + '/chat');

    socket.on('broadcast', (msg: IBroadcastMessage<any>) => {
      switch (msg.message_type) {
        case 'donation':
          if (this.props.individual.user_id === msg.content.individual_user_id) {
            this.addDonation(msg);
          }
          break;
      }
    });
    this.getIndividual(this.props.eventState.event.id, this.props.match.params.individualHandle);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(e: any) {
    this.setState({
      innerWidth: window.innerWidth,
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.eventState.event !== prevProps.eventState.event &&
      this.props.eventState.event.id !== ''
    ) {
      this.getIndividual(this.props.eventState.event.id, this.props.match.params.individualHandle);
    }

    if (this.state.individualDonations !== prevState.individualDonations) {
      this.setState({
        donations: this.state.individualDonations,
      });
    }
  }

  handleStoryChange(content: string) {
    this.setState({
      eventIndividual: {
        ...this.state.eventIndividual!,
        story: content,
      },
    });
  }

  saveIndividual() {
    this.updateIndividual(this.props.eventState.event.id, this.props.userState.user.id!, {
      story: this.state.eventIndividual?.story,
    });
  }

  async updateIndividual(eventId: string, userId: string, payload: Partial<IEventIndividual>) {
    try {
      await individualRequestActions.updateIndividual(eventId, payload);
      this.getIndividual(eventId, userId);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Individual');
      createToast(toast);
    }
  }

  async getIndividual(eventId: string, userId: string) {
    try {
      this.setState({ isIndividualLoading: true });
      const eventIndividual = await individualRequestActions.getIndividual(eventId, userId);
      this.setState({ eventIndividual });
      this.getIndividualDonations(eventId, eventIndividual.user_id);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Individual');
      createToast(toast);
    }
    this.setState({ isIndividualLoading: false });
  }

  async getIndividualDonations(eventId: string, userId: string) {
    try {
      const individualDonations = await individualRequestActions.getIndividualDonations(
        eventId,
        userId,
      );
      this.setState({ individualDonations });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Individual Donations');
      createToast(toast);
    }
  }

  getProgressWidth() {
    if (this.state.raised && this.state.goal) {
      return (this.state.raised / this.state.goal) * 100 + '%';
    }

    return '0%';
  }

  addDonation(msg: IBroadcastMessage<any>) {
    let _donations = [...this.state.donations];

    _donations.unshift({
      amount: msg.content.amount,
      display_name: msg.content.display_name,
      profile_image_url: msg.content.profile_image_url,
      socket: true,
    });

    this.setState({
      donations: _donations,
      raised: this.state.raised + msg.content.amount,
    });
  }

  render() {
    const { t } = this.props;

    let _mobileBreakpoint = 1240;

    const currency = this.props.eventState.event.group?.account?.currency ?? defaultCurrency;

    return (
      <div className="IndividualStreamer">
        <div className="Individual-content">
          <div className="progress-bar-wrap">
            <div className="progress-bar-bg">
              <div
                className="progress-bar"
                style={{ width: this.getProgressWidth() }}
              ></div>
              <div
                notranslate="yes"
                className="amt"
              >
                {formatCurrency(this.state.raised || 0, currency, this.props.locale)}
              </div>
              <div
                notranslate="yes"
                className="goal"
              >
                {formatCurrency(this.state.goal || 0, currency, this.props.locale)}
              </div>
            </div>
          </div>
          <ul className="mobile-tabs">
            <li
              onClick={(e: any) => {
                this.setState({ selectedTab: 'details' });
              }}
              className={this.state.selectedTab === 'details' ? 'active' : ''}
            >
              {t('Details')}
            </li>
            <li
              onClick={(e: any) => {
                this.setState({ selectedTab: 'donations' });
              }}
              className={this.state.selectedTab === 'donations' ? 'active' : ''}
            >
              {t('Donations')}
            </li>
          </ul>
          <div className="main">
            {((this.state.selectedTab === 'details' &&
              this.state.innerWidth <= _mobileBreakpoint) ||
              this.state.innerWidth > _mobileBreakpoint) && (
              <div className="details">
                <div className="details-inner">
                  <div className="title">Details</div>
                  <div className="detail-items">
                    {this.props.individual?.team?.handle && (
                      <div className="detail">
                        <div className="icon">
                          <i className="fal fa-hands-helping"></i>
                        </div>
                        <div className="detail-item">
                          <span>TEAM</span>
                          <div
                            className="detail-val"
                            notranslate="yes"
                          >
                            <Link
                              to={
                                '/event/' +
                                this.props.eventState.event?.handle +
                                '/team/' +
                                this.state.eventIndividual?.team?.handle
                              }
                            >
                              {this.state.eventIndividual?.team?.name}
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="detail">
                      <div className="icon">
                        <i className="far fa-ticket-alt"></i>
                      </div>
                      <div className="detail-item">
                        <span>EVENT</span>
                        <div
                          className="detail-val"
                          notranslate="yes"
                        >
                          <Link to={'/event/' + this.props.eventState.event?.handle}>
                            {this.props.eventState.event?.title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="about">
                  <div className="title">
                    <span>Story</span>
                    {this.state.eventIndividual?.user?.handle ===
                      this.props.userState.user.handle && (
                      <i
                        onClick={() => {
                          this.setState({ editStory: !this.state.editStory }, () => {
                            if (!this.state.editStory) {
                              this.saveIndividual();
                            }
                          });
                        }}
                        className={this.state.editStory ? 'fa fa-save' : 'fa fa-edit'}
                      ></i>
                    )}
                  </div>
                  {!this.state.editStory &&
                    (this.props.individual.story === '' ||
                      this.props.individual.story === '<p><br></p>') && (
                      <p>{t("This individual doesn't have a story yet.")}</p>
                    )}
                  {!this.state.isIndividualLoading &&
                    (this.state.editStory ||
                      (!this.state.editStory &&
                        this.props.individual.story !== '' &&
                        this.props.individual.story !== '<p><br></p>')) && (
                      <QuillTextEditor
                        value={this.state.eventIndividual?.story}
                        readOnly={!this.state.editStory}
                        preserveWhitespace={true}
                        theme={
                          this.state.editStory
                            ? uiConstants.quill.editableTheme
                            : uiConstants.quill.readOnlyTheme
                        }
                        modules={{
                          toolbar: uiConstants.quill.defaultToolbar,
                          clipboard: {
                            matchVisual: false,
                          },
                        }}
                        formats={uiConstants.quill.defaultFormats}
                        onChange={this.handleStoryChange}
                      />
                    )}
                </div>
                {this.props.eventState.event.group && (
                  <BenefitingCharityTile group={this.props.eventState.event.group as IGroup} />
                )}
                <StravaSection type="individual" />
              </div>
            )}
            {((this.state.selectedTab === 'donations' &&
              this.state.innerWidth <= _mobileBreakpoint) ||
              this.state.innerWidth > _mobileBreakpoint) && (
              <div className="donations">
                <div className="title">{t('Donations')}</div>
                <ul>
                  {this.state.donations.map((donation, index) => {
                    return (
                      <li
                        key={index}
                        className={donation.socket ? 'donation new' : 'donation'}
                      >
                        <div className="info">
                          <div className="portrait">
                            <Portrait
                              size={48}
                              currentImage={donation.profile_image_url}
                            />
                          </div>
                          <div className="name">
                            <span>{donation.display_name || donation.user_handle}</span>
                            {t('donated')}
                          </div>
                          <div className="amt">
                            {formatCurrency(
                              donation.amount || donation.amounts.amount / 100 || 0,
                              currency,
                              this.props.locale,
                            )}
                          </div>
                        </div>
                        <div className="msg">{donation.comments}</div>
                      </li>
                    );
                  })}
                  {this.state.donations.length === 0 && (
                    <li className="empty">{t('No donations have been received.')}</li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="mobile-donate">
          <Link
            to={
              '/event/' +
              this.state.eventIndividual?.event?.handle +
              '/individual/' +
              this.state.eventIndividual?.user?.handle +
              '/donate'
            }
            className="donate"
          >
            {t('Donate')}
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

export default withTranslation('translations')(connect(mapStateToProps)(IndividualStreamer));
