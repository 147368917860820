import axios from 'axios';
import { Dispatch } from 'redux';
import { routes } from '../helpers';
import { IHub } from '@gigit/interfaces';
import { swapRouteParams } from '../helpers';
import errorHelpers from '../helpers/errorHelpers';
import { hubRequestActions } from '../requestActions';
import { ICurrentHub } from '../reducers/hub';

export enum HubActionTypes {
  UPDATE_HUBS = 'UPDATE_HUBS',
}

export interface IUpdateHubsAction {
  isHubsLoading: boolean;
  hubs: IHub[];
  current: ICurrentHub;
  type: HubActionTypes.UPDATE_HUBS;
}

export type HubActions = IUpdateHubsAction;

export const resetCurrentHub = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      current: null,
      type: HubActionTypes.UPDATE_HUBS,
    });
  };
};

export const setHub = (hub: IHub) => {
  return (dispatch: Dispatch) => {
    dispatch({
      current: hub,
      type: HubActionTypes.UPDATE_HUBS,
    });
  };
};

export const getHub = (handle: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      isHubsLoading: true,
      type: HubActionTypes.UPDATE_HUBS,
    });

    const data = await hubRequestActions.getHub(handle);

    dispatch({
      current: data,
      isHubsLoading: false,
      type: HubActionTypes.UPDATE_HUBS,
    });
  };
};

export const getHubs = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isHubsLoading: true,
      type: HubActionTypes.UPDATE_HUBS,
    });

    axios
      .get(routes.GET_HUBS)
      .then((response) => {
        dispatch({
          hubs: response.data,
          type: HubActionTypes.UPDATE_HUBS,
        });
      })
      .finally(() => {
        dispatch({
          isHubsLoading: false,
          type: HubActionTypes.UPDATE_HUBS,
        });
      });
  };
};

export const createHub = (_payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.post(routes.CREATE_HUB, _payload).then((response) => {
      let _hubs = [...getState().hubState.hubs];

      dispatch({
        hubs: [..._hubs, ...[response.data]],
        type: HubActionTypes.UPDATE_HUBS,
      });
    });
  };
};

export const getHubGroups = (hubId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isHubsLoading: true,
      type: HubActionTypes.UPDATE_HUBS,
    });

    let _route = routes.GET_APPROVED_HUB_GROUPS as string;

    for (let p in getState().groupState.currentUserRole.permissions) {
      let _permission = getState().groupState.currentUserRole.permissions[p];

      if (_permission.permission === 'MANAGE_HUB_ASSOCIATIONS' && _permission.value === true) {
        _route = routes.GET_HUB_GROUPS;
      }
    }

    axios
      .get(swapRouteParams(_route, { hubId: hubId }))
      .then((response) => {
        dispatch({
          current: {
            groups: response.data,
            id: hubId,
          },
          type: HubActionTypes.UPDATE_HUBS,
        });
      })
      .finally(() => {
        dispatch({
          isHubsLoading: true,
          type: HubActionTypes.UPDATE_HUBS,
        });
      });
  };
};

export const applyToHub = (groupId: string, hubId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isHubsLoading: true,
      type: HubActionTypes.UPDATE_HUBS,
    });

    axios
      .post(swapRouteParams(routes.APPLY_TO_HUB, { groupId: groupId, hubId: hubId }))
      .then((response) => {
        dispatch({
          error: '',
          success: true,
          type: HubActionTypes.UPDATE_HUBS,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          error: errorObj.translatedMessage,
          type: HubActionTypes.UPDATE_HUBS,
        });
      })
      .finally(() => {
        dispatch({
          isHubsLoading: false,
          type: HubActionTypes.UPDATE_HUBS,
        });
      });
  };
};

export const clearHubError = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      error: '',
      success: false,
      type: HubActionTypes.UPDATE_HUBS,
    });
  };
};

export const approveHubGroup = (groupId: string, hubId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.APPROVE_HUB_GROUP, { groupId: groupId, hubId: hubId }))
      .then((response) => {
        getHubGroups(hubId)(dispatch, getState);
      });
  };
};

export const rejectHubGroup = (groupId: string, hubId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.REJECT_HUB_GROUP, { groupId: groupId, hubId: hubId }))
      .then((response) => {
        getHubGroups(hubId)(dispatch, getState);
      });
  };
};
