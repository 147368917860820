import { IUser, IEmbeddedAccount, IGroup } from '@gigit/interfaces';
import { CheckoutLocale } from '@stripe/stripe-js';
import { IAppState } from '../store';
import { IUserState } from '../reducers/user';
import { IGeolocationState } from '../reducers/geolocation';
import { IGroupState } from '../reducers/group';
//TODO: Maybe start using redux selectors & reselect for memoization? Examples and reasoning here. https://daveceddia.com/redux-selectors/

export namespace userSelectors {
  export function isUserAuthenticated(state: IAppState): boolean {
    return state.userState.isLoggedIn;
  }

  export function getUserState(state: IAppState): IUserState {
    return state.userState;
  }

  export function getGeolocationState(state: IAppState): IGeolocationState {
    return state.geolocationState;
  }

  export function getUser(state: IAppState): IUser {
    return state.userState.user;
  }

  export function getCurrentLocale(state: IAppState): string {
    const isLoggedIn = isUserAuthenticated(state);
    const userLanguage = state.userState.user.language || 'en';
    const loggedOutLanguage = state.userState.loggedOutLanguage;

    const currentLocale = isLoggedIn ? userLanguage : loggedOutLanguage;
    return currentLocale || 'en';
  }
  export function getCurrentAccount(state: IAppState): IEmbeddedAccount | undefined | null {
    const isLoggedIn = isUserAuthenticated(state);

    const userAccount = isLoggedIn ? state.userState.user.account : null;
    return userAccount;
  }
  export function getProfileImage(state: IAppState): string {
    return state.userState.user.profile_image_url;
  }

  // export function getUserToken(state: IAppState): string {
  //     return state.userState.tokens.access_token;
  // }
}
