import React, { FC, Fragment, useState } from 'react';
import TitleSection, { ITabConfig, ITitleSectionTab } from '../TitleSection/TitleSection';
import './Achievements.scss';

const tabs: ITitleSectionTab[] = [
  { id: 'volunteer', title: 'Volunteer' },
  { id: 'donor', title: 'Donor' },
  { id: 'supporter', title: 'Supporter' },
  { id: 'fundraiser', title: 'Fundraiser' },
  { id: 'sponsor', title: 'Sponsor' },
  { id: 'kambeo', title: 'Kambeo' },
  { id: 'admin', title: 'Admin' },
];

interface IProps {}

const Achievements: FC<IProps> = (props: IProps) => {
  const [activeTabId, setActiveTabId] = useState<string>('volunteer');

  const tabConfig: ITabConfig = {
    tabs: tabs,
    activeTabId: activeTabId,
    onTabClick: setActiveTabId,
  };

  return (
    <Fragment>
      <TitleSection
        title="Achievements"
        tabConfig={tabConfig}
      />
      <div className="Achievements">
        {activeTabId === 'volunteer' && <div>REPLACE ME: volunteer Component </div>}
        {activeTabId === 'donor' && <div>REPLACE ME: donor Component</div>}
        {activeTabId === 'supporter' && <div>REPLACE ME: supporter Component</div>}
        {activeTabId === 'fundraiser' && <div>REPLACE ME: fundraiser Component</div>}
        {activeTabId === 'sponsor' && <div>REPLACE ME: sponsor Component</div>}
        {activeTabId === 'kambeo' && <div>REPLACE ME: kambeo Component</div>}
        {activeTabId === 'admin' && <div>REPLACE ME: admin Component</div>}
      </div>
    </Fragment>
  );
};

export default Achievements;
