import './CreatePaymentPackageModal.scss';
import React, { useState } from 'react';
import Modal from '../../../Modal/Modal';
import { IUserCreditPaymentPackageFE } from '@gigit/interfaces';
import ModalScrollContainer from '../../../Modal/ModalScrollContainer/ModalScrollContainer';
import ModalFooterActionContainer from '../../../Modal/ModalFooterActions/ModalFooterActionContainer';
import Button from '../../../Button/Button';
import useToastDispatcher from '../../../../hooks/useToaster';
import { hubRequestActions } from '../../../../requestActions';
import DateTimePicker from '../../../shared/DateTimePicker/DateTimePicker';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import TextField from '../../../TextField/TextField';

interface IProps {
  show: boolean;
  hubId: string;
  onCreatePaymentPackage: (payoutPackage: IUserCreditPaymentPackageFE) => void;
  onClose: () => void;
}

const getStartOfDay = (date: Date) => moment(date).startOf('day').toDate();
const getEndOfDay = (date: Date) => moment(date).endOf('day').toDate();

const CreatePaymentPackageModal: React.FC<IProps> = (props) => {
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState<Date>(moment().startOf('day').toDate());
  const [endDate, setEndDate] = useState<Date>(moment().endOf('day').toDate());
  const [loading, setLoading] = useState(false);
  const { dispatchToastError } = useToastDispatcher();
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const handleCreatePaymentPackage = async () => {
    try {
      setLoading(true);
      const paymentPackage = await hubRequestActions.createPaymentPackage(props.hubId, {
        title: title,
        startDate: getStartOfDay(startDate),
        endDate: getEndOfDay(endDate),
      });

      props.onCreatePaymentPackage(paymentPackage);
    } catch (error) {
      dispatchToastError(error, 'Error creating payment package');
    } finally {
      setLoading(false);
      props.onClose();
    }
  };

  const handleSubmitProgramForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleCreatePaymentPackage();
  };

  return (
    <Modal
      show={props.show}
      title="Generate Payment Package"
      closeIcon="fas fa-times"
      onClose={props.onClose}
    >
      <form
        onSubmit={handleSubmitProgramForm}
        id={'CreatePaymentPackageModal__form'}
      >
        <ModalScrollContainer className="CreatePaymentPackageModal">
          <p className="desc">
            Create a payment package to generate a payout for the selected date range.
          </p>
          <div className="form-row">
            <div className="col">
              <TextField
                label="Title"
                required={true}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                name="name"
                type="text"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col date-container">
              <label>Start Date</label>
              <DateTimePicker
                inputProps={{ placeholder: 'MM/DD/YYYY', readOnly: true, disabled: false }}
                className={`custom-dtp`}
                value={moment(startDate)}
                onChange={(date) => setStartDate(moment(date).toDate())}
                locale={locale}
                timeFormat={false}
                disableDatesBefore={moment().subtract(1, 'year').startOf('day')}
              />
            </div>
            <div className="col date-container">
              <label>End Date</label>
              <DateTimePicker
                inputProps={{ placeholder: 'MM/DD/YYYY', readOnly: true }}
                className="custom-dtp"
                value={moment(endDate)}
                onChange={(date) => setEndDate(moment(date).toDate())}
                locale={locale}
                timeFormat={false}
                disableDatesBefore={moment(startDate)}
              />
            </div>
          </div>
        </ModalScrollContainer>
        <ModalFooterActionContainer>
          <Button
            buttonType="secondary"
            onClick={props.onClose}
            text="Cancel"
          />
          <Button
            buttonType="primary"
            isDisabled={!title || !startDate || !endDate}
            text="Generate Payout"
            loading={loading}
          />
        </ModalFooterActionContainer>
      </form>
    </Modal>
  );
};

export default CreatePaymentPackageModal;
