import React from 'react';
import './FormSlider.scss';

interface IProps {
  label: string | null;
  defaultValue: number | undefined;
  min: number;
  max: number;
  step: number;
  onChange(e: number): void;
}

export const FormSlider: React.FC<IProps> = (props: IProps) => {
  return (
    <div className="FormSlider">
      <input
        className="slider-custom"
        onChange={(e) => props.onChange(parseInt(e.target.value))}
        type="range"
        step={props.step}
        min={props.min}
        max={props.max}
        value={props.defaultValue ?? 0}
      />
    </div>
  );
};
