import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  IAuctionItem,
  IAuctionItemChangeOrder,
  IAuctionPayment,
  ITransactionAmounts,
} from '@gigit/interfaces';
import { Constants } from '@gigit/constants';

import { IAppState } from '../../../store';
import Modal from '../../Modal/Modal';
import TextField from '../../TextField/TextField';
import axios from 'axios';
import {
  defaultCurrency,
  formatCurrency,
  routes,
  swapRouteParams,
  toastError,
  toastSuccess,
} from '../../../helpers';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';
import Button from '../../Button/Button';

import { createToast } from '../../../actions/toaster';
import { IGroupState } from '../../../reducers/group';
import { userSelectors } from '../../../selectors/user';
import { IEventState } from '../../../reducers/event';

import './AuctionEditOrderModal.scss';
import { localizeHelpers } from '../../../localizeHelpers';
import { Prompt } from '../../Prompt/Prompt';
import { IOwnerObject } from '../../../interfaces/ownerObject';

interface IProps {
  groupState: IGroupState;
  eventState: IEventState;

  owner: IOwnerObject;
  auctionItem: IAuctionItem | null;
  show: boolean;
  locale: string;

  onClose: () => void;
  onSave: () => void;

  createToast(toast: IToast): void;
}

interface IState {
  shippingPrice: string;
  amount: string;
  paymentAmounts: ITransactionAmounts | null;
  isAmountsLoading: boolean;
  showConfirmationPrompt: boolean;
}

/** Modal component for editing the final price details of an auction item. */
class AuctionEditOrderModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      shippingPrice: '0',
      amount: '0',
      paymentAmounts: null,
      isAmountsLoading: false,
      showConfirmationPrompt: false,
    };
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.auctionItem !== prevProps.auctionItem) {
      this.setStateFromItem();
      this.fetchAuctionPaymentSummary();
    }

    if (
      this.state.amount !== prevState.amount ||
      this.state.shippingPrice !== prevState.shippingPrice
    ) {
      this.fetchAuctionPaymentSummary();
    }
  }

  setStateFromItem() {
    const amount =
      this.props.auctionItem?.override_amount ??
      this.props.auctionItem?.current_bid?.bid_amount ??
      0;

    this.setState({
      shippingPrice: String(this.props.auctionItem?.shipping_price ?? 0),
      amount: String(amount),
    });
  }

  async fetchAuctionPaymentSummary() {
    if (!this.props.auctionItem) {
      return;
    }

    const paylaod: IAuctionPayment = {
      payment_method: 'card',
      amount: this.state.amount
        ? parseFloat(this.state.amount)
        : this.props.auctionItem?.current_bid?.bid_amount,
      shipping_price:
        this.state.shippingPrice && this.props.auctionItem?.is_shipping_available
          ? parseFloat(this.state.shippingPrice)
          : undefined,
      ship_by_vendor: this.props.auctionItem?.is_shipping_available,
    };

    if (!paylaod.amount) {
      this.setState({
        paymentAmounts: null,
      });
      return;
    }

    try {
      this.setState({
        isAmountsLoading: true,
      });

      const route = swapRouteParams(routes.EVENT_BID_SUMMARY, {
        eventId: this.props.owner.ownerId,
        item_id: this.props.auctionItem.id,
      });
      const response = await axios.post<ITransactionAmounts>(route, paylaod);

      this.setState({
        paymentAmounts: response.data,
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      this.props.createToast(
        toastError(errorObj.translatedMessage, 'Fetch Auction Payment Summary'),
      );
    } finally {
      this.setState({
        isAmountsLoading: false,
      });
    }
  }

  isShippingChanged() {
    const shippingAmount = parseFloat(this.state.shippingPrice);
    return !isNaN(shippingAmount) && shippingAmount !== this.props.auctionItem?.shipping_price;
  }

  getCurrentAmount() {
    return (
      this.props.auctionItem?.override_amount ??
      this.props.auctionItem?.current_bid?.bid_amount ??
      0
    );
  }

  isAmountChanged() {
    const overrideAmount = parseFloat(this.state.amount);
    return !isNaN(overrideAmount) && overrideAmount !== this.getCurrentAmount();
  }

  isChanged() {
    return this.isAmountChanged() || this.isShippingChanged();
  }

  getShippingPriceType() {
    return this.isShippingChanged()
      ? Constants.shipping_price_type.Amount
      : this.props.auctionItem?.shipping_price_type ?? Constants.shipping_price_type.Free;
  }

  async saveOrder() {
    if (!this.props.auctionItem) {
      throw new Error('Expected Auction item');
    }

    try {
      const url = swapRouteParams(routes.UPDATE_EVENT_AUCTION_ITEM_ORDER, {
        eventId: this.props.owner.ownerId,
        id: this.props.auctionItem.id,
      });

      const payload: IAuctionItemChangeOrder = {
        shipping_price:
          this.isShippingChanged() && this.props.auctionItem?.is_shipping_available
            ? parseFloat(this.state.shippingPrice)
            : undefined,
        shipping_price_type:
          this.isShippingChanged() && this.props.auctionItem?.is_shipping_available
            ? Constants.shipping_price_type.Amount
            : undefined,
        override_amount: this.isAmountChanged() ? parseFloat(this.state.amount) : undefined,
      };
      const response = await axios.put<IAuctionItem>(url, payload);

      this.props.createToast(
        toastSuccess(localizeHelpers.translate('Order successfully saved'), 'Save Order'),
      );

      this.props.onSave();
      this.props.onClose();
    } catch (err) {
      const errorObj = errorHelpers.getErrorObject(err);
      this.props.createToast(toastError(errorObj.translatedMessage, 'Save Order'));
    }
  }

  render() {
    const feeControl = this.props.eventState.event.fee_control;
    const currency = this.props.owner.account?.currency ?? defaultCurrency;

    const shippingPrice = formatCurrency(
      this.state.paymentAmounts?.shipping || 0,
      currency,
      this.props.locale,
    );

    return (
      <Modal
        title="Edit Order"
        onClose={this.props.onClose}
        show={this.props.show}
        class="AuctionEditOrderModal"
      >
        <div className="edit-order-content">
          <div className="details">
            <div
              notranslate="yes"
              className="title"
            >
              {this.props.auctionItem?.name}
            </div>

            <div className="current-bid">
              Current Bid:{' '}
              <var data-var="bid_amount">
                {formatCurrency(
                  this.props.auctionItem?.current_bid?.bid_amount ?? 0,
                  currency,
                  this.props.locale,
                )}
              </var>
            </div>

            <div className="input-row">
              {this.props.auctionItem?.is_shipping_available && (
                <Fragment>
                  <TextField
                    label="Shipping Price"
                    type="number"
                    value={this.state.shippingPrice}
                    name="shippingPrice"
                    onChange={(e) => this.setState({ shippingPrice: e.target.value })}
                  />

                  {this.props.auctionItem?.shipping_price_type !==
                    Constants.shipping_price_type.Amount &&
                    this.isShippingChanged() && (
                      <div className="shipping-price-type-message">
                        Shipping Price type is currently{' '}
                        <var data-var="price_type">
                          {this.props.auctionItem?.shipping_price_type}
                        </var>
                        , this will be changed to {Constants.shipping_price_type.Amount}
                      </div>
                    )}
                </Fragment>
              )}
            </div>

            <div className="input-row">
              <TextField
                label="Final Price"
                type="number"
                value={this.state.amount}
                name="finalAmount"
                onChange={(e) => this.setState({ amount: e.target.value })}
              />
            </div>
          </div>

          <div className="summary">
            <div className="summary-inner">
              {this.state.paymentAmounts && (
                <div className="summary-wrap">
                  <div className="title">Summary</div>

                  <div className="sub-price">
                    <span>Final Price</span>
                    <span>
                      {formatCurrency(
                        this.state.paymentAmounts.amount,
                        currency,
                        this.props.locale,
                      )}
                    </span>
                  </div>

                  <div className="sub-price">
                    <span>Shipping</span>
                    <span>
                      {this.getShippingPriceType() === Constants.shipping_price_type.Amount
                        ? shippingPrice
                        : this.getShippingPriceType()}
                    </span>
                  </div>

                  {!feeControl?.covers_processing_fees && (
                    <div className="sub-price">
                      <span>Processing Fee</span>
                      <span>
                        {formatCurrency(
                          this.state.paymentAmounts.payment_platform_fee || 0,
                          currency,
                          this.props.locale,
                        )}
                      </span>
                    </div>
                  )}

                  {!feeControl?.covers_gigit_fees && (
                    <div className="sub-price">
                      <span>Platform Fee</span>
                      <span>
                        {formatCurrency(
                          this.state.paymentAmounts.gigit_fee || 0,
                          currency,
                          this.props.locale,
                        )}
                      </span>
                    </div>
                  )}

                  <div className="total">
                    <span>Total</span>
                    <span>
                      {formatCurrency(this.state.paymentAmounts.total, currency, this.props.locale)}
                    </span>
                  </div>
                  <div className="actions">
                    <Button
                      type="button"
                      className="save-button"
                      loading={this.state.isAmountsLoading}
                      isDisabled={!this.isChanged()}
                      onClick={() => this.setState({ showConfirmationPrompt: true })}
                      text="Save"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {this.state.showConfirmationPrompt && (
          <Prompt
            title="Save Order"
            message="Changing the Final Auction price will notify the user. Additionally, The Owner of the Auction is responsible for ensuring the winner is aware of all costs being charged."
            yesMessage="Save"
            cancelMessage="Cancel"
            onClose={() => this.setState({ showConfirmationPrompt: false })}
            onYes={() => this.saveOrder()}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuctionEditOrderModal);
