import React from 'react';

interface IProps {
  flow: string;
  isEvent: boolean;

  updateIsEvent(val: boolean): void;
}

interface IState {
  isEvent: boolean;
}

class IsEvent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isEvent: false,
    };
  }

  componentDidMount() {
    this.intializeData();
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.isEvent !== prevProps.isEvent) {
      this.setState({
        isEvent: this.props.isEvent,
      });
    }
  }

  intializeData() {
    this.setState({
      isEvent: this.props.isEvent,
    });
  }

  renderIsEventTxt() {
    if (this.props.flow === 'createAuction') {
      return <p>Is this auction for an event?</p>;
    } else {
      return <p>Is this store part of an event?</p>;
    }
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} charity`}>
        <div className="details-row">
          <div className="details">{this.renderIsEventTxt()}</div>
          <div className="settings-control">
            {this.state.isEvent && <span>Yes</span>}
            <i
              onClick={() => {
                this.setState({ isEvent: !this.state.isEvent }, () =>
                  this.props.updateIsEvent(this.state.isEvent),
                );
              }}
              className={this.state.isEvent ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}
            ></i>
          </div>
        </div>
        <div>
          {' '}
          {this.props.flow === 'createStore' && (
            <span>If the store is not part of an event it will be created on your Cause page</span>
          )}
        </div>
      </div>
    );
  }
}

export default IsEvent;
