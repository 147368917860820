import React from 'react';
import Axios from 'axios';
import { Config } from '@gigit/config';
import { IUser } from '@gigit/interfaces';
import { errorHelpers, routes, toastError } from '../../../../helpers';
import { IToast } from '../../../../interfaces';

interface IProps {
  flow: string;
  fundraiser: any;
  user: IUser;

  updateFundraiser(field: string, val: boolean): void;
  createToast(toast: IToast): void;
}

interface IState {
  stravaAuthLink: string;
  hasFitnessTrackingEnabled: boolean;
}

class ConnectStravaAccount extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      stravaAuthLink: '',
      hasFitnessTrackingEnabled: false,
    };
  }

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.fundraiser !== this.props.fundraiser) {
      this.setState({
        hasFitnessTrackingEnabled: this.props.fundraiser?.fitness_tracking_enabled,
      });
    }
  }

  initializeData = () => {
    this.setState(
      {
        hasFitnessTrackingEnabled: this.props.fundraiser?.fitness_tracking_enabled,
      },
      () => {
        Axios.get(
          routes.GET_STRAVA_AUTH_LINK +
            '?redirect=' +
            Config.web.REACT_APP_BASE_URL +
            '/strava/connect',
        )
          .then((response) => {
            if (response.data) {
              this.setState({
                stravaAuthLink: response.data.value,
              });
            }
          })
          .catch((error) => {
            const errorObject = errorHelpers.getErrorObject(error);
            const toast = toastError(
              errorObject.translatedMessage,
              'Error getting Strava auth link',
            );
            this.props.createToast(toast);
          });
      },
    );
  };

  handleStrava = () => {
    this.setState(
      {
        hasFitnessTrackingEnabled: !this.state.hasFitnessTrackingEnabled,
      },
      () => {
        this.props.updateFundraiser(
          'fitness_tracking_enabled',
          this.state.hasFitnessTrackingEnabled,
        );
      },
    );
  };

  render() {
    return (
      <div className={`form-step ${this.props.flow} charity`}>
        <div className="details-row strava">
          <div className="details">
            <p>Would you like to integrate your fitness activity?</p>
            <span>This allows you to track activities like walk duration, distance, etc</span>
          </div>
          <div className="settings-control">
            {this.state.hasFitnessTrackingEnabled && <span>Yes</span>}
            <i
              onClick={this.handleStrava}
              className={
                this.state.hasFitnessTrackingEnabled ? 'fad fa-toggle-on' : 'fad fa-toggle-off'
              }
            ></i>
          </div>
        </div>
        <div className="integrations">
          {this.state.stravaAuthLink !== '' &&
            !this.props.user.strava &&
            this.state.hasFitnessTrackingEnabled && (
              <div className="charity-wrap">
                <a
                  rel="noopener noreferrer"
                  href={this.state.stravaAuthLink}
                >
                  <span className="fab fa-strava"></span> Connect Strava
                </a>
              </div>
            )}
          {this.state.stravaAuthLink !== '' &&
            this.props.user.strava &&
            this.state.hasFitnessTrackingEnabled && (
              <div className="charity-wrap">
                <div className="connected">Strava Connected</div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default ConnectStravaAccount;
