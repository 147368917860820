import 'es5-shim';
import 'es6-shim';

/** Moment locale imports for supported langauges. */
import 'moment/locale/fr';
import 'moment/locale/es';

import React from 'react';
import ReactDOM from 'react-dom';
import { Config } from '@gigit/config';
import { BrowserRouter } from 'react-router-dom';

import { Store } from 'redux';
import { Provider } from 'react-redux';
import configureStore, { IAppState } from './store';
import { PersistGate } from 'redux-persist/integration/react';

import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

import App from './App';
import * as serviceWorker from './serviceWorker';

import axios from 'axios';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'react-day-picker/dist/style.css';

if (Config.web.REACT_APP_ENV === 'prod') {
  datadogRum.init({
    applicationId: Config.web.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: Config.web.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    service: 'web',
    version: Config.version,
    env: Config.web.REACT_APP_ENV,
    sampleRate: 100, // This means 100% of visitors will be sampled for RUM monitoring.
    premiumSampleRate: 0, // This means 0%, of the sampleRate, of the RUM samples are upgraded to premium sessions allowing replay recording, "long task" and "resource" monitoring.
    trackInteractions: true, // This means that the RUM will track all interactions with the page.
    trackFrustrations: true, // This means that the RUM will track all "frustration events" (ex. spam clicking) with the page.
    defaultPrivacyLevel: 'mask-user-input', // This means that the RUM will mask all user input (ex. emails, passwords) in the page.
  });

  datadogLogs.init({
    clientToken: Config.web.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });
}

axios.defaults.baseURL = Config.web.REACT_APP_API;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.withCredentials = true;

interface Props {
  store: Store<IAppState>;
  persistor: any;
}

const configuration = configureStore();

const Root: React.SFC<Props> = (props) => {
  const queryClient = new QueryClient();

  return (
    <Provider store={props.store}>
      <PersistGate
        loading={null}
        persistor={props.persistor}
      >
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </I18nextProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(
  <Root
    store={configuration.store}
    persistor={configuration.persistor}
  />,
  document.getElementById('root'),
);
serviceWorker.unregister();
