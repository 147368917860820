import React, { ReactNode } from 'react';
import Loader from '../../Loader/Loader';

import './PageContainer.scss';

interface IProps {
  children?: ReactNode;
  isLoading: boolean;
}

interface IState {}

/** Renders a page container box for content to be put inside of. */
export class PageContainer extends React.Component<IProps, IState> {
  render() {
    return (
      <div className="PageContainer">
        {this.props.isLoading ? (
          <div className="loading">
            <Loader loading={true} />
          </div>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}
