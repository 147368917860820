import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IAppState } from '../../store';
import { IUserState } from '../../reducers/user';
import { IRegisterState } from '../../reducers/register';
import { ReactComponent as PiggyBankImg } from '../../assets/landing-page/piggy-bank.svg';
import { ReactComponent as TicketImg } from '../../assets/landing-page/ticket.svg';
import { ReactComponent as HandshakeImg } from '../../assets/landing-page/handshake.svg';
import { ReactComponent as StoreImg } from '../../assets/landing-page/store.svg';
import { ReactComponent as AuctionImg } from '../../assets/landing-page/gavel.svg';
import { ReactComponent as VolunteerImg } from '../../assets/landing-page/hand.svg';
import { ReactComponent as DonateImg } from '../../assets/landing-page/donate.svg';
import { ReactComponent as EventImg } from '../../assets/landing-page/calendar.svg';

import './Setup.scss';
import { localizeHelpers } from '../../localizeHelpers';
import { setSEOMetatags } from '../../helpers';

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  registerState: IRegisterState;
}

interface IState {}

class Register extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    setSEOMetatags({
      title: localizeHelpers.translate('Setup | Kambeo'),
      urlPath: `setup`,
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {}

  render() {
    return (
      <div className="Setup">
        <div className="content-wrap">
          <div className="header-wrap">
            <h2>Setup</h2>
            <span className="tagline">
              We'll use this to personalize your experience, you can always make changes later.
            </span>
          </div>
          <div className="actions-wrap">
            <ul>
              <li onClick={() => this.props.history.push('/onboarding/event')}>
                <div>
                  <span className="list-circle event-fundraiser">
                    <i className="shine"></i>
                    <TicketImg />
                  </span>
                  <span>Run event or fundraiser</span>
                  <p>Run an event for your organization or pick one to support</p>
                </div>
              </li>
              <li onClick={() => this.props.history.push('/onboarding/gig')}>
                <div>
                  <span className="list-circle volunteer-paid">
                    <i className="shine"></i>
                    <HandshakeImg />
                  </span>
                  <span>Post Volunteer Opportunities</span>
                  <p>Post volunteer or paid opportunities</p>
                </div>
              </li>
              <li onClick={() => this.props.history.push('/setup/volunteer')}>
                <div>
                  <span className="list-circle volunteer">
                    <i className="shine"></i>
                    <VolunteerImg />
                  </span>
                  <span>Volunteer</span>
                  <p>Setup your volunteer profile</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="profile-cta">
            <span className="tagline">
              Not sure what to do yet? Check out your{' '}
              <Link to={`/user/${this.props.userState?.user?.handle}`}> profile page. </Link>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    registerState: store.registerState,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
