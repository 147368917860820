import axios from 'axios';
import { ICampaign } from '@gigit/interfaces';
import { routes, swapRouteParams } from '../helpers';

export namespace campaignRequestActions {
  export async function createCampaign(groupId: string, payload: ICampaign): Promise<ICampaign> {
    const response = await axios.post<ICampaign>(
      swapRouteParams(routes.CREATE_GROUP_CAMPAIGN, { groupId: groupId }),
      payload,
    );
    return response.data;
  }

  export async function updateCampaign(
    groupId: string,
    campaignId: string,
    payload: ICampaign,
  ): Promise<void> {
    await axios.put<void>(
      swapRouteParams(routes.UPDATE_GROUP_CAMPAIGN, { groupId, campaignId }),
      payload,
    );
  }

  export async function getCampaigns(groupId: string): Promise<ICampaign[]> {
    const response = await axios.get<ICampaign[]>(
      swapRouteParams(routes.GET_GROUP_CAMPAIGNS, { groupId: groupId }),
    );
    return response.data;
  }

  export async function isPrefixUsed(groupId: string, prefix: string): Promise<boolean> {
    const response = await axios.get<boolean>(
      swapRouteParams(routes.GET_CAMPAIGNS_IS_PREFIX_USED, { groupId: groupId, prefix: prefix }),
    );
    return response.data;
  }

  export async function getCurrentReceiptNumber(
    groupId: string,
    campaignId: string,
  ): Promise<{ value: number }> {
    const response = await axios.get<{ value: number }>(
      swapRouteParams(routes.GET_RECEIPT_NUMBER, { groupId, campaign_id: campaignId }),
    );
    return response.data;
  }

  export async function updateReceiptNumber(
    groupId: string,
    campaignId: string,
    receiptNumber: number,
  ): Promise<void> {
    await axios.post<void>(
      swapRouteParams(routes.UPDATE_RECEIPT_NUMBER, { groupId, campaign_id: campaignId }),
      { value: receiptNumber },
    );
  }

  export async function receiptNumberHasBeenUsed(
    groupId: string,
    campaignId: string,
  ): Promise<boolean> {
    const response = await axios.get<boolean>(
      swapRouteParams(routes.GET_CAMPAIGNS_HAS_BEEN_USED, { groupId, campaignId }),
    );
    return response.data;
  }

  export async function closeGroupCampaign(groupId: string, campaignId: string): Promise<void> {
    await axios.post(swapRouteParams(routes.CLOSE_GROUP_CAMPAIGN, { groupId, campaignId }));
  }
}
