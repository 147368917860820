import React, { useState } from 'react';
import TextField from '../../../../TextField/TextField';
import { FieldOption, FieldInstance, FieldValueMap, FieldFilterValue } from '../../tableState';
import TableSideDrawer from '../TableSideDrawer/TableSideDrawer';
import './FilterDrawer.scss';
import DatePicker from '../../../forms/DatePicker/DatePickerField/DatePickerField';
import DateRangePicker from '../../../forms/DatePicker/DateRangePicker/DateRangePickerField';
import SelectField from '../../../forms/select/SelectField/SelectField';
import MultiSelectField from '../../../forms/select/MultiSelectField/MultiSelectField';
import AsyncMultiSelectField from '../../../forms/select/AsyncMultiSelectField/AsyncMultiSelectField';
import AsyncSelectField from '../../../forms/select/AsyncSelectField/AsyncSelectField';
import Button from '../../../../Button/Button';
import { QueryHelpers } from '../../../../../helpers';

const defaultFieldValues: FieldValueMap = {
  textField: {
    type: 'text',
    value: '',
  },
  numberField: {
    type: 'text',
    value: '',
  },
  selectField: {
    type: 'select',
    value: '',
    selectedOption: undefined,
  },
  multiSelectField: {
    type: 'multi-select',
    selectedOptions: [],
    selectedValues: [],
  },
  asyncSelectField: {
    type: 'select',
    value: '',
    selectedOption: undefined,
  },
  asyncMultiSelectField: {
    type: 'multi-select',
    selectedOptions: [],
    selectedValues: [],
  },
  dateRangeField: {
    type: 'range',
    valueRange: {
      from: '',
      to: '',
    },
  },
  dateField: {
    type: 'text',
    value: '',
  },
};

interface IFilterDrawerProps {
  showDrawer: boolean;
  availableFields: Array<FieldOption>;
  onApplyFilters: (appliedFilters: Array<FieldInstance>) => void;
  onClearFilters: () => void;
  onCloseDrawer: () => void;
}

//TODO: Implement field errors
//TODO: Implement async fields
function FilterDrawer(props: IFilterDrawerProps) {
  const { showDrawer } = props;
  const [fields, setFields] = useState<FieldInstance[]>(getDefaultFilterFieldInstances);
  const [appliedFields, setAppliedFields] = useState<Array<FieldInstance>>([]);

  function getDefaultFilterFieldInstances(): FieldInstance[] {
    const { availableFields } = props;

    return availableFields.map((field) => {
      return {
        type: field.type,
        value: defaultFieldValues[field.type],
        options: field.options,
        fieldId: field.fieldId,
      } as FieldInstance;
    });
  }

  function modifyFilterField(updatedField: FieldInstance) {
    const fieldFound = fields.find((field) => field.fieldId === updatedField.fieldId);

    if (fieldFound) {
      setFields([
        ...fields.map((field) => {
          if (field.fieldId === updatedField.fieldId) {
            return updatedField;
          }

          return field;
        }),
      ]);
    }
  }

  function renderField(fieldInstance: FieldInstance, idx: number) {
    switch (fieldInstance.type) {
      case 'textField': {
        return (
          <TextField
            label={fieldInstance.options.label}
            value={fieldInstance.value.value}
            type="text"
            placeholder={fieldInstance.options.placeholder}
            name={fieldInstance.fieldId}
            onChange={(e) =>
              modifyFilterField({
                ...fieldInstance,
                type: 'textField',
                value: {
                  type: 'text',
                  value: e.target.value,
                },
              })
            }
          />
        );
      }
      case 'numberField': {
        return (
          <TextField
            label={fieldInstance.options.label}
            value={fieldInstance.value.value}
            type="number"
            placeholder={fieldInstance.options.placeholder}
            name={fieldInstance.fieldId}
            icon="far fa-dollar-sign"
            step=".01"
            min={'1'}
            onChange={(e) =>
              modifyFilterField({
                ...fieldInstance,
                type: 'numberField',
                value: {
                  type: 'text',
                  value: e.target.value,
                },
              })
            }
          />
        );
      }
      case 'dateField': {
        return (
          <DatePicker
            name={fieldInstance.fieldId + '_date-picker'}
            label={fieldInstance.options.label}
            value={fieldInstance.value.value}
            onChange={(selectedDate) =>
              modifyFilterField({
                ...fieldInstance,
                value: {
                  type: 'text',
                  value: selectedDate,
                },
              })
            }
          />
        );
      }
      case 'dateRangeField': {
        return (
          <DateRangePicker
            locale={fieldInstance.options.locale}
            fromFieldOptions={{
              label: 'From',
              name: `${fieldInstance.fieldId}_date-from`,
              placeholder: 'Date From',
            }}
            toFieldOptions={{
              label: 'To',
              name: `${fieldInstance.fieldId}_date-to`,
              placeholder: 'Date To',
            }}
            value={fieldInstance.value.valueRange}
            onChange={({ formattedRange }) =>
              modifyFilterField({
                ...fieldInstance,
                value: {
                  type: 'range',
                  valueRange: formattedRange,
                },
              })
            }
          />
        );
      }
      case 'selectField': {
        return (
          <SelectField
            label={fieldInstance.options.label}
            onChange={(selectedOption) =>
              modifyFilterField({
                ...fieldInstance,
                value: {
                  type: 'select',
                  value: selectedOption?.value || '',
                  selectedOption: selectedOption || undefined,
                },
              })
            }
            selectedOption={fieldInstance.value.selectedOption}
            options={fieldInstance.options.options}
            placeholder={fieldInstance.options.placeholder}
          />
        );
      }
      case 'multiSelectField': {
        return (
          <MultiSelectField
            label={fieldInstance.options.label}
            onChange={(selectedOptions) =>
              modifyFilterField({
                ...fieldInstance,
                value: {
                  type: 'multi-select',
                  selectedOptions: selectedOptions,
                  selectedValues: selectedOptions.map((s) => s.value),
                },
              })
            }
            selectedOptions={fieldInstance.value.selectedOptions}
            options={fieldInstance.options.options}
          />
        );
      }
      // case "ASYNC-SELECT-FILTER": {
      //     return (
      //         <AsyncSelectField
      //             label={filter.options.label}
      //             onChange={() => {}}
      //             onSearchRequest={async () => []}
      //         />
      //     )
      // }
      // case "ASYNC-MULTI-SELECT-FILTER": {
      //     return (
      //         <AsyncMultiSelectField
      //             label={filter.options.label}
      //             onChange={() => {}}
      //             onSearchRequest={async () => []}
      //         />
      //     )
      // }
      default:
        break;
    }
  }

  function handleApplyFilters() {
    const validatedFields = fields.filter((field) => isFilterValid(field.value));

    setAppliedFields(validatedFields);
    props.onApplyFilters(validatedFields);
  }

  function handleClearAllFilters() {
    props.onClearFilters();
    setFields(getDefaultFilterFieldInstances);
    setAppliedFields([]);
  }

  function isFilterValid(filter: FieldFilterValue): boolean {
    switch (filter.type) {
      case 'text':
        return filter.value.length >= 3;
      case 'select':
        return filter.value.length >= 1;
      case 'multi-select':
        return filter.selectedOptions.length >= 1;
      case 'range':
        return !!filter.valueRange.from && !!filter.valueRange.to;
      default:
        return false;
    }
  }

  return (
    <TableSideDrawer
      title="Filters"
      showDrawer={showDrawer}
      onClose={props.onCloseDrawer}
    >
      <div className="FilterDrawerContent">
        <div className="applied-filters">
          <i className="fal fa-filter" />
          <span className="filters-applied-label">{appliedFields.length} Filters Applied</span>
          <span
            className="clear-all"
            onClick={handleClearAllFilters}
          >
            Clear All
          </span>
          <Button
            text="Apply"
            buttonType="primary"
            className="button-apply-filter"
            onClick={handleApplyFilters}
          />
        </div>
        <div className="filters-container">
          {fields.map((field, idx) => (
            <div
              className="drawer-row"
              key={idx}
            >
              {renderField(field, idx)}
            </div>
          ))}
        </div>
      </div>
    </TableSideDrawer>
  );
}

export default FilterDrawer;
