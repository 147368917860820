import axios from 'axios';
import { Dispatch } from 'redux';
import { IGigSummary, IPage, IShiftRecurrence } from '@gigit/interfaces';
import { routes, swapRouteParams, dataURItoBlob, toastError, toastSuccess } from '../helpers';
import {
  getGroupGigs,
  updateGroupPageComponent,
  getCurrentGroupPageComponents,
  createGroupLocationFlow,
} from './group';
import { getEventGigs, updateEventPageComponent, getCurrentEventPageComponents } from './event';
import { Config } from '@gigit/config';
import { initialGigState } from '../reducers/gig';
import { getUserGroupApplications } from './user';
import { createToast } from './toaster';
import errorHelpers from '../helpers/errorHelpers';
import { localizeHelpers } from '../localizeHelpers';

export enum GigActionTypes {
  UPDATE_GIG = 'UPDATE_GIG',
  UPDATE_GIG_PAGES = 'UPDATE_GIG_PAGES',
  GET_GIG_CATEGORIES = 'GET_GIG_CATEGORIES',
}

export interface IUpdateGigAction {
  gig: IGigSummary;
  isShiftsLoading: boolean;
  type: GigActionTypes.UPDATE_GIG;
}

export interface IUpdateGigPagesAction {
  gigPages: any[];
  type: GigActionTypes.UPDATE_GIG_PAGES;
}

export interface IGetGigCategories {
  gigCategories: any[];
  type: GigActionTypes.GET_GIG_CATEGORIES;
}

export type GigActions = IUpdateGigAction | IUpdateGigPagesAction;

export const createGroupGig = (
  groupId: string,
  _payload: any,
  options?: {
    _updateComponent?: any;
    shouldGetGig?: boolean;
    callback?: (gig: IGigSummary) => void;
  },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_GROUP_GIG, { groupId: groupId }), _payload)
      .then((response) => {
        const gigId = response.data.id;
        const location =
          _payload.locations && _payload.locations.length > 0 ? _payload.locations[0] : null;

        getGroupGigs(groupId)(dispatch, getState);

        if (location) {
          let cb = (locationId: string) => {
            createGigPage(gigId, _payload?.gigCauses, locationId)(dispatch, getState);
          };
          createGigLocation(
            gigId,
            location,
            'Volunteer Opportunity Location',
            cb,
          )(dispatch, getState);
        } else {
          createGigPage(response.data.id, _payload?.gigCauses)(dispatch, getState);
        }

        if (options?._updateComponent !== undefined) {
          updateGroupPageComponent(
            groupId,
            options?._updateComponent.page_id,
            options?._updateComponent.component_id,
            {
              content_references: {
                object_type: 'gig',
                object_ids: [...options?._updateComponent.object_ids, response.data.id],
              },
            },
          )(dispatch, getState);
        }

        if (options?.shouldGetGig) {
          getGig(response.data.id)(dispatch, getState);
        }
        options?.callback?.(response.data);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Volunteer Opportunity');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createEventGig = (
  eventId: string,
  _payload: any,
  options?: { _updateComponent?: any; callback?: (gig: IGigSummary) => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_EVENT_GIG, { eventId: eventId }), _payload)
      .then((response) => {
        getEventGigs(eventId)(dispatch, getState);
        createGigPage(response.data.id)(dispatch, getState);

        if (options?._updateComponent !== undefined) {
          updateEventPageComponent(
            eventId,
            options?._updateComponent.page_id,
            options?._updateComponent.component_id,
            {
              content_references: {
                object_type: 'gig',
                object_ids: [...options?._updateComponent.object_ids, response.data.id],
              },
            },
          )(dispatch, getState);
        }
        options?.callback?.(response.data);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Volunteer Opportunity');
        createToast(toast)(dispatch, getState);
      });
  };
};

/** Fetches a gig by handle or by id.
 * @param handleOrId Either the handle or the id of the gig.
 */
export const getGig = (handleOrId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGigLoading: true,
      type: GigActionTypes.UPDATE_GIG,
    });

    axios
      .get(swapRouteParams(routes.GET_GIG_BY_HANDLE, { handleOrId: handleOrId }))
      .then((response) => {
        dispatch({
          gig: response.data,
          shifts: [],
          volunteerError: '',
          volunteerSuccess: false,
          type: GigActionTypes.UPDATE_GIG,
        });

        const gigId: string = response.data.id;

        getGigPages(gigId)(dispatch, getState);
        getShifts(gigId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Volunteer Opportunity');
        createToast(toast)(dispatch, getState);

        if (errorObj.errorCode === 'ERROR.GIGS.GIG_DOES_NOT_EXIST') {
          dispatch({
            gig: initialGigState.gig,
            type: GigActionTypes.UPDATE_GIG,
          });
        }

        dispatch({
          isGigLoading: false,
          shifts: [],
          type: GigActionTypes.UPDATE_GIG,
        });
      })
      .finally(() => {
        dispatch({
          isGigLoading: false,
          type: GigActionTypes.UPDATE_GIG,
        });
      });
  };
};

export const volunteerForGig = (gigId: string, _payload: any, callback?: () => void) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      volunteerError: '',
      volunteerSuccess: false,
      type: GigActionTypes.UPDATE_GIG,
    });

    axios
      .post(swapRouteParams(routes.VOLUNTEER_FOR_GIG, { gigId }), _payload)
      .then((response) => {
        getUserGroupApplications(_payload.groupId)(dispatch, getState);

        callback?.();

        dispatch({
          volunteerError: '',
          volunteerSuccess: true,
          type: GigActionTypes.UPDATE_GIG,
        });

        const toast = toastSuccess(
          localizeHelpers.translate(
            'Application submitted successfully. Someone will contact you shortly. Keep an eye on your email for shift assignments and notifications.',
          ),
          'Application Form',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Volunteer for gig');
        createToast(toast)(dispatch, getState);
        dispatch({
          volunteerError: errorObj.translatedMessage,
          type: GigActionTypes.UPDATE_GIG,
        });
      });
  };
};

export const resetVolunteerForGig = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      volunteerError: '',
      volunteerSuccess: false,
      type: GigActionTypes.UPDATE_GIG,
    });
  };
};

export const resetGigState = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      gig: initialGigState,
      type: GigActionTypes.UPDATE_GIG,
    });
  };
};

export const updateGig = (gig: IGigSummary) => {
  return async (dispatch: Dispatch, getState: any) => {
    if (gig.group_id || gig.hub_id || gig?.event_id) {
      axios
        .put(swapRouteParams(routes.UPDATE_GIG, { gigId: gig.id }), gig)
        .then((response) => {
          dispatch({
            gig: response.data,
            type: GigActionTypes.UPDATE_GIG,
          });
        })
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          let toast = toastError(errorObj.translatedMessage, 'Update Volunteer Opportunity');
          createToast(toast)(dispatch, getState);
        });
    } else {
      let toast = toastError(
        localizeHelpers.translate('Opportunity owner not found.'),
        'Update Volunteer Opportunity',
      );
      createToast(toast)(dispatch, getState);
    }
  };
};

export const updateGigCauses = (gigId: string, causes: any[]) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.GET_GIG_BY_HANDLE, { handleOrId: gigId }), { causes: causes })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(
          errorObj.translatedMessage,
          'Update Volunteer Opportunity Focus Areas',
        );
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGigSkills = (gigId: string, skills: any[]) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.GET_GIG_BY_HANDLE, { handleOrId: gigId }), { skills: skills })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Volunteer Opportunity Skills');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGigCoverImage = (img: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const blob = dataURItoBlob(img);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let _gig = { ...getState().gigState.gig, ...{ cover_image_url: response.data.value } };
        updateGig(_gig)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(
          errorObj.translatedMessage,
          'Update Volunteer Opportunity Cover Image',
        );
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGigProfileImage = (img: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const blob = dataURItoBlob(img);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let _gig = { ...getState().gigState.gig, ...{ profile_image_url: response.data.value } };
        updateGig(_gig)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(
          errorObj.translatedMessage,
          'Update Volunteer Opportunity Profile Image',
        );
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getCurrentPageComponents = (gigId: string, _pageId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isComponentsLoading: true,
      type: GigActionTypes.UPDATE_GIG_PAGES,
    });

    axios
      .get(swapRouteParams(routes.GET_GIG_PAGE_COMPONENTS, { gigId: gigId, pageId: _pageId }))
      .then((response) => {
        dispatch({
          currentPageComponents: response.data.components,
          type: GigActionTypes.UPDATE_GIG,
        });
      })
      .finally(() => {
        dispatch({
          isComponentsLoading: false,
          type: GigActionTypes.UPDATE_GIG_PAGES,
        });
      });
  };
};

export const createGigPage = (gigId: string, causes?: Array<string>, LocationId?: string) => {
  let c = causes ? [...causes] : [];

  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGigPagesLoading: true,
      type: GigActionTypes.UPDATE_GIG_PAGES,
    });

    const descriptionPayload = {
      visibility: 'public',
      sequence: 1,
      title: 'Description',
      component_type: 'description',
    };

    const mediaAlbumPayload = {
      visibility: 'public',
      sequence: 5,
      title: 'Media',
      component_type: 'media_album',
      content_references: {
        object_ids: [],
        object_type: 'media_album',
      },
    };

    let locationsPayload;

    if (LocationId) {
      locationsPayload = {
        visibility: 'public',
        sequence: 4,
        title: 'Locations',
        component_type: 'location',
        content_references: {
          object_ids: [LocationId],
          object_type: 'location',
        },
      };
    } else {
      locationsPayload = {
        visibility: 'public',
        sequence: 4,
        title: 'Locations',
        component_type: 'location',
        content_references: {
          object_ids: [],
          object_type: 'location',
        },
      };
    }

    const causesPayload = {
      visibility: 'public',
      sequence: 3,
      title: 'Focus Areas',
      component_type: 'cause',
      content_references: {
        object_ids: [],
        object_type: 'cause',
      },
      meta_data: {
        causes: [...c],
      },
    };

    const skillsPayload = {
      visibility: 'public',
      sequence: 2,
      title: 'Skills',
      component_type: 'skill',
      content_references: {
        object_ids: [],
        object_type: 'skill',
      },
    };

    const components = [
      descriptionPayload,
      skillsPayload,
      causesPayload,
      locationsPayload,
      mediaAlbumPayload,
    ];

    const _payload = {
      label: 'default gig page',
      components,
    };

    axios
      .post(swapRouteParams(routes.CREATE_GIG_PAGE, { gigId: gigId }), _payload)
      .then((response) => {
        let _pages = getState().gigState.gigPages;

        dispatch({
          gigPages: [..._pages, ...[response.data]],
          type: GigActionTypes.UPDATE_GIG_PAGES,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Volunteer Opportunity Pages');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isGigPagesLoading: false,
          type: GigActionTypes.UPDATE_GIG_PAGES,
        });
      });
  };
};

export const createGigLocation = (
  gigId: string,
  _payload: any,
  _locationName: string,
  cb: (locationId: string) => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    if (_payload.location.type) {
      delete _payload.location.type;
    }

    _payload.title = _locationName;

    axios
      .post(swapRouteParams(routes.CREATE_GIG_LOCATION, { gigId: gigId }), _payload)
      .then((response) => {
        getGig(gigId)(dispatch, getState);

        if (cb) {
          cb(response.data.id);
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Volunteer Opportunity Location');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createGigPageComponent = (gigId: string, _pageId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.CREATE_GIG_PAGE_COMPONENT, { gigId: gigId, pageId: _pageId }),
        _payload,
      )
      .then((response) => {
        getCurrentPageComponents(gigId, _pageId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(
          errorObj.translatedMessage,
          'Create Volunteer Opportunity Page Component',
        );
        createToast(toast)(dispatch, getState);
      });
  };
};

// TODO: This seems to be a duplicate of getGroupForm() in actions/group.tsx
export const getGigForm = (groupId: string, _form_id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGigLoading: true,
      type: GigActionTypes.UPDATE_GIG,
    });

    axios
      .get(swapRouteParams(routes.GET_GROUP_FORM, { groupId: groupId, form_id: _form_id }))
      .then((response) => {
        if (response.data !== '') {
          dispatch({
            currentGigForm: response.data,
            type: GigActionTypes.UPDATE_GIG,
          });
        }

        dispatch({
          isGigLoading: false,
          type: GigActionTypes.UPDATE_GIG,
        });
      })
      .catch((error) => {
        dispatch({
          isGigLoading: false,
          type: GigActionTypes.UPDATE_GIG,
        });
      });
  };
};

export const updateGigPageComponent = (
  gigId: string,
  _pageId: string,
  _componentId: string,
  _payload: any,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(
        swapRouteParams(routes.UPDATE_GIG_PAGE_COMPONENT, {
          gigId: gigId,
          pageId: _pageId,
          componentId: _componentId,
        }),
        _payload,
      )
      .then((response) => {
        getCurrentGigPageComponents(gigId, _pageId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(
          errorObj.translatedMessage,
          'Update Volunteer Opportunity Page Component',
        );
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createGigMediaAlbum = (gigId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_GIG_MEDIA_ALBUM, { gigId: gigId }), _payload)
      .then((response) => {
        getGigMediaAlbums(gigId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Media Album');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getGigMediaAlbums = (gigId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(swapRouteParams(routes.GET_GIG_MEDIA_ALBUMS, { gigId: gigId })).then((response) => {
      dispatch({
        mediaAlbums: response.data,
        type: GigActionTypes.UPDATE_GIG,
      });
    });
  };
};

export const deleteGigMediaAlbum = (gigId: string, _albumId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(swapRouteParams(routes.DELETE_GIG_MEDIA_ALBUM, { gigId: gigId, albumId: _albumId }))
      .then((response) => {
        getGigMediaAlbums(gigId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(
          errorObj.translatedMessage,
          'Delete Volunteer Opportunity Media Album',
        );
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getCurrentGigPageComponents = (gigId: string, _pageId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_GIG_PAGE_COMPONENTS, { gigId: gigId, pageId: _pageId }))
      .then((response) => {
        dispatch({
          currentPageComponents: response.data.components,
          type: GigActionTypes.UPDATE_GIG,
        });
      });
  };
};

export const getGigPages = (gigId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGigPagesLoading: true,
      type: GigActionTypes.UPDATE_GIG_PAGES,
    });

    axios
      .get(swapRouteParams(routes.GET_GIG_PAGES, { gigId: gigId }))
      .then((response) => {
        if (response.data.length > 0) {
          getCurrentPageComponents(gigId, response.data[0].id)(dispatch, getState);
        }

        dispatch({
          gigPages: response.data,
          type: GigActionTypes.UPDATE_GIG_PAGES,
        });
      })
      .finally(() => {
        dispatch({
          isGigPagesLoading: false,
          type: GigActionTypes.UPDATE_GIG_PAGES,
        });
      });
  };
};

export const getShifts = (gigId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isShiftsLoading: true,
      type: GigActionTypes.UPDATE_GIG,
    });

    axios
      .get(swapRouteParams(routes.GET_SHIFTS, { gigId: gigId }))
      .then((response) => {
        dispatch({
          shifts: response.data,
          type: GigActionTypes.UPDATE_GIG,
        });
      })
      .finally(() => {
        dispatch({
          isShiftsLoading: false,
          type: GigActionTypes.UPDATE_GIG,
        });
      });
  };
};

export const createShift = (gigId: string, payload: IShiftRecurrence) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_SHIFT, { gigId: gigId }), payload)
      .then((response) => {
        getShifts(gigId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Shift');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const removeShift = (gigId: string, _shiftId: string, occurrence?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _route: string = routes.REMOVE_SHIFT;

    if (occurrence) {
      _route += '?options=' + occurrence;
    }

    axios
      .delete(swapRouteParams(_route, { gigId: gigId, shiftId: _shiftId }))
      .then((response) => {
        getShifts(gigId)(dispatch, getShifts);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Remove Shift');
        createToast(toast)(dispatch, getState);
      });
  };
};

/**
 *
 * @param gigId
 * @param _shiftId
 * @param _payload a string array of userIds
 */
export const assignUserToShift = (
  gigId: string,
  _shiftId: string,
  _payload: string[],
  callback?: () => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.ASSIGN_USER_TO_SHIFT, { gigId: gigId, shiftId: _shiftId }),
        _payload,
      )
      .then((response) => {
        getShifts(gigId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Assign User to Shift');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        if (callback) {
          callback?.();
        }
      });
  };
};

export const removeUserFromShift = (
  gigId: string,
  _shiftId: string,
  _userId: string,
  callback?: () => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(
        swapRouteParams(routes.REMOVE_USER_FROM_SHIFT, {
          gigId: gigId,
          shiftId: _shiftId,
          userId: _userId,
        }),
      )
      .then((response) => {
        getShifts(gigId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Remove User from Shift');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        if (callback) {
          callback?.();
        }
      });
  };
};

export const getGigLocations = (gigId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(swapRouteParams(routes.GET_GIG_LOCATIONS, { gigId: gigId })).then((response) => {
      dispatch({
        gigLocations: response.data,
        type: GigActionTypes.UPDATE_GIG,
      });
    });
  };
};

export const changeGigStatus = (
  gigId: string,
  status: string,
  callback?: (updatedGig: IGigSummary) => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGigLoading: true,
      type: GigActionTypes.UPDATE_GIG,
    });

    axios
      .post(swapRouteParams(routes.CHANGE_GIG_STATUS, { gigId: gigId }), { status: status })
      .then((response) => {
        dispatch({
          gig: response.data,
          type: GigActionTypes.UPDATE_GIG,
        });
        callback?.(response.data);

        const toast = toastSuccess(
          localizeHelpers.translate('Volunteer Opportunity status has been changed to {{status}}', {
            status: status,
          }),
          'Change volunteer opportunity status',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Change volunteer opportunity status');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isGigLoading: false,
          type: GigActionTypes.UPDATE_GIG,
        });
      });
  };
};

export const getGigCategories = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_GIG_CATEGORIES).then((response) => {
      dispatch({
        gigCategories: response.data,
        type: GigActionTypes.UPDATE_GIG,
      });
    });
  };
};

export const getGigVolunteers = (gigId: string, query?: URLSearchParams) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _route = swapRouteParams(routes.GET_GIG_VOLUNTEERS, { gigId });

    if (query) {
      _route += query;
    }
    axios
      .get(_route)
      .then((response) => {
        dispatch({
          gigVolunteers: response.data,
          type: GigActionTypes.UPDATE_GIG,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Opportunity Volunteers');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          volunteersLoading: false,
          type: GigActionTypes.UPDATE_GIG,
        });
      });
  };
};
