import React, { Component } from 'react';
import './Popup.scss';

interface IProps {
  lat: number;
  lng: number;
  offsetTop?: string;
  offsetLeft?: string;
  translateX?: string;
  translateY?: string;
  /** TODO: Implement dynamic anchor positioning */
  anchorPosition?: 'up' | 'down';
  /** Optional: When passed, renders a close button */
  onClose?(): void;
}

interface IState {
  showPopup: boolean;
}

export default class Popup extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showPopup: true,
    };
  }

  onClose = (e: any) => {
    this.props.onClose?.();
  };

  render() {
    const { showPopup } = this.state;
    const { offsetTop, offsetLeft, anchorPosition, translateX, translateY } = this.props;

    if (showPopup) {
      return (
        <div
          className="Popup"
          style={{
            transform: `translate(${translateX}, ${translateY})`,
            top: offsetTop || 0,
            left: offsetLeft || 0,
          }}
        >
          <i
            className="fa fa-times close"
            onClick={this.onClose}
          />
          <div className="popup-content">{this.props.children}</div>
          <div className={`popup-anchor down`}></div>
        </div>
      );
    }
  }
}
