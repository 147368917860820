import './SocialImpactBenefitsForm.scss';
import React, { useEffect } from 'react';
import { IHub, ISocialImpactBenefit, ISocialImpactBenefits } from '@gigit/interfaces';
import ToggleButton from '../../../shared/ToggleButton/ToggleButton';
import TextField from '../../../TextField/TextField';
import { BenefitType } from '../../SocialImpactComponent/SocialImpactBenefitsComponent';

interface IProps {
  hub: IHub | null;
  socialImpactBenefits: ISocialImpactBenefits;
  setSocialImpactBenefits: (benefits: ISocialImpactBenefits) => void;
}
interface SocialImpactBenefitWithType extends ISocialImpactBenefit {
  type: BenefitType;
}

function SocialImpactBenefitsForm(props: IProps) {
  const socialImpactBenefits: SocialImpactBenefitWithType[] = Object.entries(
    props.hub?.social_impact_benefits || {},
  )
    .filter((benefit) => benefit[1].is_offered != null)
    .map((benefit) => ({
      type: benefit[0],
      ...benefit[1],
    }));

  const benefitConfig = {
    paid_volunteer_days: {
      name: 'Paid Volunteer Days',
    },
    expenses_paid_for_volunteer_days: {
      name: 'Paid Expenses for Volunteer Days',
    },
    donation_matching: {
      name: 'Donation Matching Programs',
    },
    remote_or_hybrid_working_options: {
      name: 'Remote / Hybrid Work Options',
    },
    mental_health_benefits: {
      name: 'Mental Health Benefits',
    },
  };

  useEffect(() => {
    if (props.hub != null && props.hub.social_impact_benefits) {
      props.setSocialImpactBenefits(props.hub.social_impact_benefits);
    }
  }, [props.hub]);

  function toggleIsBenefitOffered(type: BenefitType) {
    let newSocialBenefits = {
      ...props.socialImpactBenefits,
    };

    if (newSocialBenefits[type] != null)
      newSocialBenefits[type].is_offered = !props.socialImpactBenefits[type]?.is_offered;

    props.setSocialImpactBenefits(newSocialBenefits);
  }

  function changeBenefitValue(type: BenefitType, value: number) {
    let newSocialBenefits = {
      ...props.socialImpactBenefits,
    };

    newSocialBenefits[type].value = value;

    props.setSocialImpactBenefits(newSocialBenefits);
  }

  function renderInputRow(benefit: SocialImpactBenefitWithType) {
    const { type, value } = benefit;

    switch (type) {
      case 'paid_volunteer_days': {
        return (
          <div className="input-row">
            <TextField
              placeholder="Enter number of days"
              value={value || ''}
              name="title"
              type="number"
              onChange={(e) => {
                changeBenefitValue(type, Number(e.target.value));
              }}
            />
            <span>/ Annually</span>
          </div>
        );
      }
      case 'expenses_paid_for_volunteer_days': {
        return (
          <div className="input-row">
            <TextField
              placeholder="Enter dollar amount"
              value={value || ''}
              name="title"
              type="number"
              onChange={(e) => {
                changeBenefitValue(type, Number(e.target.value));
              }}
            />
            <span>/ Day</span>
          </div>
        );
      }
      default: {
      }
    }
  }
  return (
    <div className="SocialImpactBenefitsForm">
      <span className="description">
        Select the social impact benefits that are relevant to your company.
      </span>
      {socialImpactBenefits.map((benefit, idx) => {
        return (
          <div
            className="benefit-container"
            key={idx}
          >
            <div className="toggle-row">
              <span className="label">{benefitConfig[benefit.type].name}</span>
              <ToggleButton
                onToggleClick={() => toggleIsBenefitOffered(benefit.type)}
                isActive={benefit.is_offered}
              />
            </div>
            {benefit.is_offered && renderInputRow(benefit)}
          </div>
        );
      })}
    </div>
  );
}

export default SocialImpactBenefitsForm;
