import './Volunteer.scss';
import React from 'react';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { RouteComponentProps } from 'react-router-dom';
import { IGroupState } from '../../../reducers/group';
import {
  IGigSummary,
  IShift,
  IShiftUserBasic,
  IShiftUserSummary,
  IUserRole,
  IVolunteer,
} from '@gigit/interfaces';
import { IToast } from '../../../interfaces';
import SortableTable, { ISortableTableColumn } from '../../SortableTable/SortableTable';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { userSelectors } from '../../../selectors/user';
import { createToast } from '../../../actions/toaster';
import Button from '../../Button/Button';
import ContactCard from '../ContactCard/ContactCard';
import { errorHelpers, toastError } from '../../../helpers';
import Checkbox from '../../Checkbox/Checkbox';
import { Prompt } from '../../Prompt/Prompt';
import QuickEditField from '../../QuickEditField/QuickEditField';
import Modal from '../../Modal/Modal';
import Dropdown, { IOptions } from '../../Dropdown/Dropdown';
import { Constants } from '@gigit/constants';
import { volunteerRequestActions, gigRequestActions } from '../../../requestActions';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  locale: string;
  createToast(toast: IToast): void;
}

interface IState {
  tabs: Array<string>;
  activeTab: number;
  shiftUsers: IShiftUserSummary[];
  volunteer: IVolunteer | null;
  checkAll: boolean;
  showAddShift: boolean;
  loading: boolean;
  selectedShifts: IShiftUserSummary[];
  showRemoveFromShiftPrompt: boolean;
  removeFromShift: IShiftUserSummary | null;
  addShift: string | null;
  gigShifts: IShift[];
  addGig: string | null;
  groupGigs: IGigSummary[];
  completedShifts: number;
}

class Volunteer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      completedShifts: 0,
      checkAll: false,
      removeFromShift: null,
      showRemoveFromShiftPrompt: false,
      selectedShifts: [],
      tabs: [
        'Shifts',
        //"Custom Fields", // https://app.clickup.com/t/fywyku Custom fields (same as donor panel) - Ignore this tab for now
      ],
      activeTab: 0,
      shiftUsers: [],
      volunteer: null,
      showAddShift: false,
      loading: false,
      addShift: null,
      gigShifts: [],
      addGig: null,
      groupGigs: [],
    };
  }

  componentDidMount() {
    this.refreshContent();
  }

  async removeUserFromShift(): Promise<void> {
    this.setState({ loading: true });
    try {
      if (
        this.state.removeFromShift?.gig_id &&
        this.state.removeFromShift?.shift_id &&
        this.props.contact.user?.id
      ) {
        await volunteerRequestActions.removeUserFromShift({
          gigId: this.state.removeFromShift.gig_id,
          shiftId: this.state.removeFromShift.shift_id,
          userId: this.props.contact.user.id,
        });

        await this.refreshContent();
      }
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Get Roles');
      this.props.createToast(toast);
    } finally {
      this.setState({ loading: false });
    }
  }

  countCompletedShifts(shifts: IShiftUserSummary[]): void {
    let count = 0;
    shifts.forEach((s) => {
      if (s.status?.code === Constants.gig_shift_hours_status.approved) {
        count++;
      }
    });

    this.setState({ completedShifts: count });
  }

  async getShiftsData(): Promise<void> {
    this.setState({ loading: true });
    try {
      if (this.props.contact.user?.id) {
        const shiftUser = await volunteerRequestActions.getShiftsForUser({
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user.id,
        });

        this.setState({ shiftUsers: shiftUser });
      }
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Volunteer shifts');
      this.props.createToast(toast);
    } finally {
      this.setState({ loading: false });
    }
  }

  async getVolunteerData(): Promise<void> {
    try {
      if (this.props.contact.user?.id) {
        const volunteerData = await volunteerRequestActions.getVolunteerForUser({
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user.id,
        });

        this.setState({ volunteer: volunteerData });
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObj.translatedMessage, 'Volunteer Summary');
      this.props.createToast(toast);
    }
  }

  getOnlyNotVerified(): IShiftUserSummary[] {
    return this.state.shiftUsers.filter(
      (s) => s.status?.code === Constants.gig_shift_hours_status.pending,
    );
  }

  toggleAllShifts(): void {
    if (!this.state.checkAll && this.state.selectedShifts.length === 0) {
      this.setState({
        checkAll: true,
        selectedShifts: this.getOnlyNotVerified(),
      });
    } else {
      this.setState({
        checkAll: false,
        selectedShifts: [],
      });
    }
  }

  async refreshContent(): Promise<void> {
    await this.getVolunteerData();
    await this.getShiftsData();
  }

  async verifySelected(): Promise<void> {
    let gigs: Map<string, IShiftUserBasic[]> = new Map();

    this.state.selectedShifts.forEach((s) => {
      if (s.gig_id) {
        const tmp = gigs.get(s.gig_id) as IShiftUserBasic[];
        const newEntry = {
          user_id: this.props.contact.user?.id || '',
          shift_id: s?.shift_id || '',
        } as IShiftUserBasic;

        if (tmp) gigs.set(s.gig_id, [newEntry, ...tmp]);
        else gigs.set(s.gig_id, [newEntry]);
      }
    });

    this.setState({ loading: true });
    for (const gig of Array.from(gigs, ([gigId, shiftUsers]) => ({ gigId, shiftUsers }))) {
      try {
        await volunteerRequestActions.bulkVerifyShiftHours({
          gigId: gig.gigId,
          shiftUsers: gig.shiftUsers,
        });
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Verify selected');
        this.props.createToast(toast);
      } finally {
        this.setState({
          loading: false,
          selectedShifts: [],
        });
      }
    }

    await this.refreshContent();
  }

  async verifySingle(shift: IShiftUserSummary): Promise<void> {
    this.setState({ loading: true });
    try {
      if (shift?.gig_id && shift?.shift_id && this.props.contact.user?.id && shift?.hours) {
        await volunteerRequestActions.verifyShiftHours({
          gigId: shift.gig_id,
          shift_id: shift.shift_id,
          userId: this.props.contact.user.id,
          hours: shift.hours,
        });

        await this.refreshContent();
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObj.translatedMessage, 'Verify shift hours');
      this.props.createToast(toast);
    } finally {
      this.setState({ loading: false });
    }
  }

  toggleShift(shift: IShiftUserSummary): void {
    if (
      !!this.state.selectedShifts.find((selectedShift) => selectedShift.shift_id === shift.shift_id)
    ) {
      this.setState({
        selectedShifts: this.state.selectedShifts.filter((s) => s.shift_id !== shift.shift_id),
        checkAll: false,
      });
    } else
      this.setState({
        selectedShifts: [...this.state.selectedShifts, shift],
        checkAll: false,
      });
  }

  async updateShiftHours(updateShiftHoursParam: {
    shift: IShiftUserSummary;
    hours: number;
  }): Promise<void> {
    this.setState({ loading: true });
    try {
      if (
        updateShiftHoursParam.shift?.gig_id &&
        updateShiftHoursParam.shift?.shift_id &&
        this.props.contact.user?.id
      ) {
        await volunteerRequestActions.updateUserShiftHours({
          gigId: updateShiftHoursParam.shift?.gig_id,
          shift_id: updateShiftHoursParam.shift?.shift_id,
          user_id: this.props.contact.user?.id,
          hours: updateShiftHoursParam.hours,
        });

        await this.refreshContent();
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObj.translatedMessage, 'Update shift hours');
      this.props.createToast(toast);
    } finally {
      this.setState({ loading: false });
    }
  }

  convertVolunteerShiftData() {
    return this.state.shiftUsers.map((shift: IShiftUserSummary, index) => {
      return {
        row: [
          {
            content: (
              <Checkbox
                name={`check${index}`}
                label=""
                value={`check${index}`}
                checked={
                  !!this.state.selectedShifts.find(
                    (selectedShift) => selectedShift.shift_id === shift.shift_id,
                  )
                }
                onChange={() => {
                  this.toggleShift(shift);
                }}
                disabled={shift.status?.code !== Constants.gig_shift_hours_status.pending}
                className={
                  shift.status?.code !== Constants.gig_shift_hours_status.pending ? 'disabled' : ''
                }
              />
            ),
            id: 'check',
          },
          {
            content: <div notranslate="yes">{shift.gig_title}</div>,
            id: 'gig_title',
          },
          {
            content: (
              <div notranslate="yes">
                {shift?.start_date
                  ? localizeHelpers.formatDate(
                      shift.start_date,
                      LocaleDateFormats.ll,
                      this.props.locale,
                    )
                  : '/'}
              </div>
            ),
            id: 'shift_date',
          },
          {
            content: (
              <div className="time">
                <div notranslate="yes">
                  {shift?.start_date
                    ? localizeHelpers.formatTime(
                        {
                          hour: new Date(shift.start_date).getHours(),
                          minute: new Date(shift.start_date).getMinutes(),
                        },
                        this.props.locale,
                      )
                    : '/'}
                  -
                  {shift?.end_date
                    ? localizeHelpers.formatTime(
                        {
                          hour: new Date(shift.end_date).getHours(),
                          minute: new Date(shift.end_date).getMinutes(),
                        },
                        this.props.locale,
                      )
                    : '/'}
                </div>
              </div>
            ),
            id: 'shift_time',
          },
          {
            content: (
              <QuickEditField
                name={`hours${index}`}
                className="hours-container"
                editIcon={
                  shift.status?.code !== Constants.gig_shift_hours_status.pending
                    ? 'fa fa-lock'
                    : ''
                }
                onSave={async (hours) => {
                  await this.updateShiftHours({
                    shift,
                    hours: +hours,
                  });
                }}
                type="number"
                value={shift?.hours ? shift.hours.toString() : '0'}
                min={0}
                disabled={shift.status?.code !== Constants.gig_shift_hours_status.pending}
              />
            ),
            id: 'hours',
          },
          {
            content: (
              <span
                className={
                  shift.status?.code === Constants.gig_shift_hours_status.pending
                    ? 'pending-tag'
                    : 'verified-tag'
                }
              >
                {shift.status?.code}
              </span>
            ),
            id: 'status',
          },
          {
            content:
              shift.status?.code === Constants.gig_shift_hours_status.pending ? (
                <Button
                  text="Verify"
                  buttonClass="verify-btn"
                  onClick={async () => {
                    await this.verifySingle(shift);
                  }}
                />
              ) : null,
            id: 'verify',
          },
          {
            menu: [
              {
                icon: 'fa fa-trash',
                onClick: () => {
                  this.setState({ removeFromShift: shift, showRemoveFromShiftPrompt: true });
                },
                label: 'Remove from shift',
              },
            ],
            id: 'actions',
          },
        ],
      };
    });
  }

  async getGigShifts(): Promise<void> {
    try {
      if (this.state.addGig) {
        const shiftsData = await gigRequestActions.getShifts({ gigId: this.state.addGig });
        this.setState({ gigShifts: shiftsData });
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObj.translatedMessage, 'Get gig shifts');
      this.props.createToast(toast);
    } finally {
      this.setState({ showAddShift: true });
    }
  }

  async submitAddShift(): Promise<void> {
    this.setState({ loading: true });
    const selectedShift: IShift = this.state.gigShifts.filter(
      (s) => s.id === this.state.addShift,
    )[0];

    try {
      if (selectedShift?.gig_id && selectedShift?.id && this.props.contact.user?.id) {
        await volunteerRequestActions.assignUserToShift({
          gigId: selectedShift.gig_id,
          shiftId: selectedShift.id,
          userId: this.props.contact.user.id,
        });

        await this.refreshContent();
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObj.translatedMessage, 'Add shift to user');
      this.props.createToast(toast);
    } finally {
      this.setState({ loading: false, showAddShift: false });
    }
  }

  async getGroupGigs(): Promise<void> {
    try {
      const groupGigsData = await gigRequestActions.getGroupGigs({
        groupId: this.props.groupState.group.id,
      });
      this.setState({ groupGigs: groupGigsData });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObj.translatedMessage, 'Get cause gigs');
      this.props.createToast(toast);
    } finally {
      this.setState({ showAddShift: true });
    }
  }

  render() {
    const columns: ISortableTableColumn[] = [
      {
        label: '',
        id: 'check',
        sortable: false,
        content: (
          <Checkbox
            name="check_all"
            label=""
            checked={this.state.checkAll || this.state?.selectedShifts.length > 0}
            onChange={() => {
              this.toggleAllShifts();
            }}
            value="check_all"
            className="light"
            disabled={this.state.shiftUsers.length === 0}
          />
        ),
      },
      { label: 'Gig Title', id: 'gig_title', sortable: false },
      { label: 'Shift Date', id: 'shift_date', sortable: false },
      { label: 'Shift Time', id: 'shift_time', sortable: false },
      { label: 'Hours', id: 'hours', sortable: false },
      { label: 'Status', id: 'status', sortable: false },
      { label: '', id: 'verify', sortable: false },
      { label: '', id: 'actions', sortable: false },
    ];
    const KPIs = [
      {
        title: 'TOTAL HOURS',
        value: this.state.volunteer?.total_hours || 0,
      },
      {
        title: 'VERIFIED HOURS',
        value: this.state.volunteer?.approved_hours || 0,
      },
      {
        title: 'UNVERIFIED HOURS',
        value: this.state.volunteer?.pending_hours || 0,
      },
      {
        title: 'SHIFTS COMPLETED',
        value: this.state.completedShifts,
      },
      /*{
                title: 'KEY SKILLS',
                value:this.state.volunteer?.user?.skills ?
                    <>
                        {this.state.volunteer?.user?.skills.map(s => {
                            return (<span className={'key-skill'}>{s}</span>)
                        })}
                    </> : '/'
            },*/ // GIG-4481: https://app.clickup.com/t/1xjgzzv
      {
        title: 'LAST SHIFT',
        value: this.state.volunteer?.last_shift_date
          ? localizeHelpers.formatDate(
              this.state.volunteer.last_shift_date,
              LocaleDateFormats.ll,
              this.props.locale,
            )
          : '/',
      },
    ] as { title: string; value: string | number | Date }[];

    return (
      <div className="ContactVolunteer">
        <ul className="Contact-menu">
          {this.state.tabs.map((item, index) => {
            return (
              <li
                onClick={() => {
                  this.setState({ activeTab: index });
                }}
                className={this.state.activeTab === index ? 'active no-select' : 'no-select'}
                key={index}
              >
                <span>{item}</span>
              </li>
            );
          })}
          <li className="add">
            {this.state.activeTab === 0 && (
              <Button
                onClick={async () => {
                  await this.getGroupGigs();
                }}
                className="add-field"
                icon="far fa-plus"
                text="Shifts"
              />
            )}
          </li>
        </ul>
        <div className="Contact-content">
          <div className="Contact-side">
            <ContactCard
              groupId={this.props.groupState.group.id}
              {...this.props}
            />
          </div>
          <div className="contact-main">
            {this.state.activeTab === 0 && (
              <div className="stat-row">
                <div className="cd-stat-row">
                  {KPIs.map((kpi, index) => {
                    return (
                      <div
                        className="cd-stat-box"
                        key={index}
                      >
                        <div className="stat">{kpi.value}</div>
                        <div className="stat-label">{kpi.title}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {this.state.selectedShifts.length > 0 && (
              <div className="selected-shifts-container">
                <i className="fa fa-check-circle" />
                <span notranslate="yes">
                  {`${this.state.selectedShifts.length} `}
                  {localizeHelpers.translate(
                    `Shift${this.state.selectedShifts.length > 1 ? 's' : ''} Selected`,
                  )}
                </span>
                <Button
                  text="Verify Selected"
                  onClick={async () => {
                    await this.verifySelected();
                  }}
                />
              </div>
            )}
            {this.state.activeTab === 0 && (
              <div className="data">
                <SortableTable
                  {...this.props}
                  columns={columns}
                  data={this.convertVolunteerShiftData()}
                  loading={this.state.loading}
                />
              </div>
            )}
          </div>
        </div>
        <Modal
          show={this.state.showAddShift}
          onClose={() => this.setState({ showAddShift: false })}
          contentClassName="add-shift-form-container"
        >
          <form
            className="add-shift-form"
            onSubmit={async (e) => {
              e.preventDefault();
              await this.submitAddShift();
            }}
          >
            <div className="title">Add Shift</div>
            <div className="sub-title">Available Volunteer Opportunities</div>
            <div className="dropdown-select">
              <Dropdown
                name="add-gig"
                onChange={(gig) => {
                  this.setState({ addGig: gig.target.value }, async () => {
                    await this.getGigShifts();
                  });
                }}
                value={this.state.addGig}
                placeholder="Select a volunteer opportunity"
                options={this.state.groupGigs.map((g) => {
                  return {
                    label: g.title,
                    value: g.id,
                  } as IOptions;
                })}
              />
            </div>
            <div className="sub-title">Available Shifts</div>
            <div className="dropdown-select">
              <Dropdown
                name="add-shift"
                onChange={(shift) => {
                  this.setState({ addShift: shift.target.value });
                }}
                value={this.state.addShift}
                placeholder="First select a volunteer opportunity"
                options={this.state.gigShifts.map((gs) => {
                  return {
                    // @example label: `${status}, ${start_time} - ${end_time} ${start_date}`
                    label: `${gs.status.code}, ${
                      gs?.start_date
                        ? localizeHelpers.formatTime(
                            {
                              hour: new Date(gs.start_date).getHours(),
                              minute: new Date(gs.start_date).getMinutes(),
                            },
                            this.props.locale,
                          )
                        : '/'
                    } - ${
                      gs?.end_date
                        ? localizeHelpers.formatTime(
                            {
                              hour: new Date(gs.end_date).getHours(),
                              minute: new Date(gs.end_date).getMinutes(),
                            },
                            this.props.locale,
                          )
                        : '/'
                    } ${
                      gs?.start_date
                        ? localizeHelpers.formatDate(
                            gs.start_date,
                            LocaleDateFormats.ll,
                            this.props.locale,
                          )
                        : '/'
                    }`,
                    value: gs.id,
                  } as IOptions;
                })}
              />
            </div>
            <div className="action">
              <Button
                type="submit"
                text="Add shift"
                isDisabled={this.state.addShift === null}
              />
            </div>
          </form>
        </Modal>
        <Prompt
          show={this.state.showRemoveFromShiftPrompt}
          title="Remove from shift"
          message="Are you sure?"
          yesMessage="Remove"
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage="Cancel"
          onClose={() =>
            this.setState({
              showRemoveFromShiftPrompt: false,
              removeFromShift: null,
            })
          }
          onYes={() => this.removeUserFromShift()}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Volunteer);
