import React, { FC, useState, useRef, useEffect } from 'react';
import './HubManagementAnalyticsSupportedCauses.scss';
import moment, { Moment } from 'moment';
import DateTimePicker from '../../../../shared/DateTimePicker/DateTimePicker';
import { useLocale } from '../../../../../hooks';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
  Legend,
} from 'chart.js';
import {
  Line,
  Bar,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from 'react-chartjs-2';
import { IHub } from '@gigit/interfaces';
import { metricRequestActions } from '../../../../../requestActions/metrics';
import { uiConstants } from '../../../../../constants';
import {
  defaultCurrency,
  formatCurrency,
  metricsHelpers,
  focusOnInputField,
} from '../../../../../helpers';
import useToastDispatcher from '../../../../../hooks/useToaster';
import PopupMenu, {
  IPopupMenuItem,
  IShowPopupConfig,
} from '../../../../shared/PopupMenu/PopupMenu';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
);

interface IProps {
  hub: IHub;
}

interface ISelectedCause {
  id: string;
  title: string;
}

interface IActiveConfig {
  kpi_list_metric: string;
  selected_kpi: string;
  label: string;
}

const _SESSION_STORAGE_FROM = 'hubAnalyticsDonations_supportedCausesDateFrom',
  _SESSION_STORAGE_TO = 'hubAnalyticsDonations_supportedCausesDateTo';

const HubManagementAnalyticsSupportedCauses: FC<IProps> = (props) => {
  const { hub } = props;
  const locale = useLocale();
  const { dispatchToastError } = useToastDispatcher();
  const [supportedCausesKpi, setSupportedCausesKpi] = useState<metricsHelpers.IChartKpi | null>(
    null,
  );
  const [selectedCauseKpi, setSelectedCauseKpi] = useState<metricsHelpers.IChartKpi | null>(null);
  const [selectedCause, setSelectedCause] = useState<ISelectedCause | null>(null);
  const [supportedCausesDateFrom, setSupportedCausesDateFrom] = useState<Moment>(
    moment(localStorage.getItem(_SESSION_STORAGE_FROM) || `${new Date().getFullYear()}-01-01`),
  );
  const [supportedCausesDateTo, setSupportedCausesDateTo] = useState<Moment>(
    moment(localStorage.getItem(_SESSION_STORAGE_TO) || `${new Date().getFullYear()}-12-31`),
  );
  const barRef = useRef(null);
  const supportedCausesDateFromRef = useRef<HTMLInputElement>(null);
  const supportedCausesDateToRef = useRef<HTMLInputElement>(null);
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [activeConfig, setActiveConfig] = useState<IActiveConfig>({
    kpi_list_metric: 'amount_donated',
    label: 'Amount Donated',
    selected_kpi: 'donation_amounts_trend',
  });

  async function handleGetSupportedCausesKpi() {
    try {
      const kpiData = (
        await metricRequestActions.getMetricsKpi({
          object_type: uiConstants.ownerType.hub,
          object_id: hub.id!,
          kpi: 'hub_partner_groups_list',
          kpi_list_metric: activeConfig.kpi_list_metric,
          start_date: supportedCausesDateFrom.toDate(),
          end_date: supportedCausesDateTo.toDate(),
          currency: hub.account?.currency ?? defaultCurrency,
        })
      ).kpi_data;
      let chartDate = [] as metricsHelpers.IChartKpiData[];
      kpiData?.label_metadata?.forEach((meta, index) => {
        chartDate.push({
          y: meta.title,
          value: kpiData.datasets[0].data[index],
          meta: {
            title: meta.title!,
            id: kpiData?.labels[index],
          },
        });
      });

      setSupportedCausesKpi({
        data: chartDate,
        labels: kpiData?.label_metadata?.map((meta) => meta.title!) || [],
      });
    } catch (error) {
      dispatchToastError(error, 'Get Supported Causes KPI');
    }
  }

  async function handleGetselectedCauseKpi() {
    if (selectedCause?.id) {
      try {
        const kpiData = (
          await metricRequestActions.getMetricsKpi({
            object_type: uiConstants.ownerType.hub,
            object_id: hub.id!,
            kpi: activeConfig.selected_kpi,
            start_date: supportedCausesDateFrom.toDate(),
            end_date: supportedCausesDateTo.toDate(),
            currency: hub.account?.currency ?? defaultCurrency,
            sub_filters: {
              group: selectedCause?.id,
            },
          })
        ).kpi_data;
        let chartDate = [] as metricsHelpers.IChartKpiData[];
        kpiData?.labels?.forEach((label, index) => {
          chartDate.push({
            x: label,
            value: kpiData.datasets[0].data[index],
          });
        });

        setSelectedCauseKpi({ data: chartDate, labels: kpiData?.labels });
      } catch (error) {
        dispatchToastError(error, 'Get Selected Cause KPI');
      }
    }
  }

  useEffect(() => {
    handleGetSupportedCausesKpi();
  }, [supportedCausesDateFrom, supportedCausesDateTo, activeConfig.kpi_list_metric]);

  useEffect(() => {
    handleGetselectedCauseKpi();
  }, [selectedCause, activeConfig.selected_kpi]);

  const optionsBar = {
    indexAxis: 'y' as const,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback:
            activeConfig.kpi_list_metric !== 'volunteer_hours_total'
              ? function (value: any) {
                  return formatCurrency(
                    Number(value),
                    hub.account?.currency ?? defaultCurrency,
                    locale.currentLocale,
                  );
                }
              : undefined,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label:
            activeConfig.kpi_list_metric !== 'volunteer_hours_total'
              ? function (context: any) {
                  return formatCurrency(
                    Number(context.parsed.x),
                    hub.account?.currency ?? defaultCurrency,
                    locale.currentLocale,
                  );
                }
              : undefined,
        },
        backgroundColor: '#ffffff',
        borderColor: '#333333',
        borderWidth: 1,
        bodyColor: '#000000',
        titleColor: '#000000',
        padding: 16,
        titleFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
        bodyFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
      },
    },
  };
  const chartDataBar = {
    labels: supportedCausesKpi?.labels || [],
    datasets: [
      {
        label: activeConfig.label,
        data: supportedCausesKpi?.data || [],
        borderColor: '#5E51AB',
        backgroundColor: '#5E51AB',
        parsing: {
          xAxisKey: 'value',
        },
      },
    ],
  };

  const optionsLine = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback:
            activeConfig.kpi_list_metric !== 'volunteer_hours_total'
              ? function (value: any) {
                  return formatCurrency(
                    Number(value),
                    hub.account?.currency ?? defaultCurrency,
                    locale.currentLocale,
                  );
                }
              : undefined,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label:
            activeConfig.kpi_list_metric !== 'volunteer_hours_total'
              ? function (context: any) {
                  return formatCurrency(
                    Number(context.parsed.y),
                    hub.account?.currency ?? defaultCurrency,
                    locale.currentLocale,
                  );
                }
              : undefined,
        },
      },
    },
  };
  const chartDataLine = {
    labels: selectedCauseKpi?.labels || [],
    datasets: [
      {
        label: selectedCause?.title || 'Cause not selected',
        data: selectedCauseKpi?.data || [],
        borderColor: '#5E51AB',
        backgroundColor: '#5E51AB',
        parsing: {
          yAxisKey: 'value',
        },
      },
    ],
  };

  const popupMenuConfig: IShowPopupConfig = {
    showMenu: showContextMenu,
    setShowMenu: setShowContextMenu,
    position: {
      type: 'bottom',
    },
  };
  const getShowMenuClass = showContextMenu ? 'show' : '';
  const contextMenuItems: IPopupMenuItem[] = [
    {
      id: 'amount_donated',
      label: 'Amount Donated',
      isSelected: activeConfig.kpi_list_metric === 'amount_donated',
      onClick: () => {
        setActiveConfig({
          kpi_list_metric: 'amount_donated',
          label: 'Amount Donated',
          selected_kpi: 'donation_amounts_trend',
        });
      },
    },
    {
      id: 'amount_fundraised',
      label: 'Amount Fundraised',
      isSelected: activeConfig.kpi_list_metric === 'amount_fundraised',
      onClick: () => {
        setActiveConfig({
          kpi_list_metric: 'amount_fundraised',
          label: 'Amount Fundraised',
          selected_kpi: 'fundraised_amounts_trend',
        });
      },
    },
    {
      id: 'volunteer_hours_total',
      label: 'Volunteer Hours Total',
      isSelected: activeConfig.kpi_list_metric === 'volunteer_hours_total',
      onClick: () => {
        setActiveConfig({
          kpi_list_metric: 'volunteer_hours_total',
          label: 'Volunteer Hours Total',
          selected_kpi: 'volunteer_hours_trend',
        });
      },
    },
  ];

  return (
    <div className="HubManagementAnalyticsSupportedCauses">
      <section className="chart-container">
        <h4>Supported Causes Analytics</h4>
        <div className="date-container">
          <div className="date">
            <label htmlFor="supportedCausesDateFrom">From:</label>
            <DateTimePicker
              shouldValidate={false}
              inputProps={{
                ref: supportedCausesDateFromRef,
                name: 'supportedCausesDateFrom',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              value={supportedCausesDateFrom}
              onChange={(value) => {
                setSupportedCausesDateFrom(moment(value));
                localStorage.setItem(_SESSION_STORAGE_FROM, value as string);
              }}
              locale={locale.currentLocale}
              className="dPicker"
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(supportedCausesDateFromRef)}
            />
          </div>
          <div className="date">
            <label htmlFor="supportedCausesDateTo">To:</label>
            <DateTimePicker
              inputProps={{
                ref: supportedCausesDateToRef,
                name: 'supportedCausesDateTo',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              value={supportedCausesDateTo}
              onChange={(value) => {
                setSupportedCausesDateTo(moment(value));
                localStorage.setItem(_SESSION_STORAGE_TO, value as string);
              }}
              locale={locale.currentLocale}
              className="dPicker"
              disableDatesBefore={supportedCausesDateFrom}
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(supportedCausesDateToRef)}
            />
          </div>
          <PopupMenu
            showMenuConfig={popupMenuConfig}
            menuItems={contextMenuItems}
            className={`context-menu-container ${getShowMenuClass}`}
            onClick={() => setShowContextMenu(!showContextMenu)}
            onSelect={() => setShowContextMenu(false)}
          >
            <span className="title">{activeConfig.label}</span>
            <i className={`fas fa-chevron-down menu-btn ${getShowMenuClass}`} />
          </PopupMenu>
        </div>

        <Bar
          options={optionsBar}
          data={chartDataBar}
          className="chart Bar"
          ref={barRef}
          onClick={(event) => {
            if (barRef?.current) {
              const dataset = getDatasetAtEvent(barRef.current, event);
              const element = getElementAtEvent(barRef.current, event);
              const elements = getElementsAtEvent(barRef.current, event);

              if (
                element[0]?.index !== undefined &&
                element[0]?.index !== null &&
                supportedCausesKpi?.data[element[0].index]?.meta?.id &&
                supportedCausesKpi?.data[element[0].index]?.meta?.title
              ) {
                setSelectedCause({
                  id: supportedCausesKpi.data[element[0].index].meta!.id!,
                  title: supportedCausesKpi.data[element[0].index].meta!.title!,
                });
              }
            }
          }}
        />

        <Line
          options={optionsLine}
          data={chartDataLine}
          className="chart Line"
        />
      </section>
    </div>
  );
};

export default HubManagementAnalyticsSupportedCauses;
