import React, { Component } from 'react';
import moment from 'moment';
import { IAuctionItemSummary, ICampaign } from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';

import auctionItemHelpers from '../auctionItemHelpers';
import AuctionitemLeaderDisplay from '../AuctionItemLeaderDisplay/AuctionitemLeaderDisplay';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import ModalHeader from '../../../Modal/ModalHeader/ModalHeader';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';

import './AuctionItemDetailsModal.scss';
import { IEventState } from '../../../../reducers/event';
import { IGroupState } from '../../../../reducers/group';
import { connect } from 'react-redux';
import { IAppState } from '../../../../store';
import { defaultCurrency, formatCurrency } from '../../../../helpers';
import { Constants } from '@gigit/constants';
import { userSelectors } from '../../../../selectors/user';
import { IOwnerObject } from '../../../../interfaces';

interface IProps extends WithTranslation {
  showDetailModal: boolean;
  item: IAuctionItemSummary;
  owner: IOwnerObject;
  groupState: IGroupState;
  eventState: IEventState;
  locale: string;
  onClose(): void;
  onPlaceBidClicked(): void;
  onBuyNowClicked(): void;
  canPlaceBid?: boolean;
}

interface IState {
  currentImageIndex: number;
}

/** Modal for displaying auction item details. Mainly used in mobile view. */
class AuctionItemDetailsModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      currentImageIndex: 0,
    };
  }

  render() {
    const t = this.props.t;

    const currency = this.props.owner.account?.currency ?? defaultCurrency;

    return (
      <>
        <Modal
          show={this.props.showDetailModal}
          onClose={this.props.onClose}
        >
          <div className="AuctionItemDetailsModal">
            <ModalHeader
              title={t('Auction Item details')}
              onClose={this.props.onClose}
            />
            <div className="modal-scollcontainer">
              <div className="modal-content">
                {this.props.item.media && this.props.item.media.length > 0 && (
                  <img
                    className="cover-image"
                    alt={'cover image ' + this.props.item.name}
                    src={this.props.item.media[this.state.currentImageIndex].url}
                  />
                )}
                <div className="details-content">
                  <div className="album-controls">
                    {this.props.item.media.map((mediaItem, index) => (
                      <div
                        key={index}
                        className={
                          this.state.currentImageIndex === index ? 'notch-filled' : 'notch-empty'
                        }
                        onClick={() => this.setState({ currentImageIndex: index })}
                      ></div>
                    ))}
                  </div>

                  {moment(new Date()).format('X') <
                    moment(this.props.item.end_date).format('X') && (
                    <div className="status">
                      <i className="fa fa-check-circle"></i>
                      <span>{t('OPEN')}</span>
                    </div>
                  )}
                  {moment(new Date()).format('X') >
                    moment(this.props.item.end_date).format('X') && (
                    <div className="status">
                      <i className="fa fa-times-circle"></i>
                      <span>{t('CLOSED')}</span>
                    </div>
                  )}
                  <div
                    className="name"
                    notranslate="yes"
                  >
                    {this.props.item.name}
                  </div>
                  <div className="current-bid">
                    {t('Current Bid')}:{' '}
                    <var data-var="current_bid">
                      {formatCurrency(
                        this.props.item.current_bid?.bid_amount ||
                          this.props.item.minimum_start_bid ||
                          0,
                        currency,
                        this.props.locale,
                      )}
                    </var>
                  </div>

                  <AuctionitemLeaderDisplay
                    owner={this.props.owner}
                    locale={this.props.locale}
                    item={this.props.item}
                    onPlaceBidClicked={this.props.onPlaceBidClicked}
                    canPlaceBid={this.props.canPlaceBid}
                  />

                  <p className="desc-label">{t('Description')}</p>
                  <QuillTextEditor
                    className="description"
                    value={this.props.item.description || t('This item has no description.')}
                    readOnly={true}
                    preserveWhitespace={true}
                    theme={'bubble'}
                    modules={auctionItemHelpers.quillModules}
                    formats={auctionItemHelpers.quillFormats}
                    onChange={(content: any, delta: any, source: any, editor: any) => {}}
                  />

                  <div className="price-and-date-details">
                    <div className="minimum">
                      <div className="icon-wrap">
                        <i className="fas fa-dollar-sign"></i>
                      </div>
                      <div className="details-inner">
                        <div className="label">{t('Minimum Increment')}</div>
                        <div
                          className="info"
                          notranslate="yes"
                        >
                          {formatCurrency(
                            this.props.item.minimum_bid_increment || 0,
                            currency,
                            this.props.locale,
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="retail">
                      <div className="icon-wrap">
                        <i className="fas fa-shopping-bag"></i>
                      </div>
                      <div className="details-inner">
                        <div className="label">{t('Retail Price')}</div>
                        <div
                          className="info"
                          notranslate="yes"
                        >
                          {formatCurrency(
                            this.props.item.retail_price || 0,
                            currency,
                            this.props.locale,
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="close">
                      <div className="icon-wrap">
                        <i className="fas fa-calendar-day"></i>
                      </div>
                      <div className="details-inner">
                        <div className="label">{t('Close Date')}</div>
                        <div
                          className="info"
                          notranslate="yes"
                        >
                          {moment(this.props.item.end_date).format('MMMM D, YYYY h:mmA')}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="action-buttons">
                    {auctionItemHelpers.canPlaceBid(this.props.item) &&
                      this.props.canPlaceBid !== false && (
                        <>
                          <Button
                            buttonClass="action-button"
                            onClick={this.props.onPlaceBidClicked}
                            text={t('Place Bid')}
                          />
                          {this.props.item.buy_now_price && this.props.item.buy_now_price > 0 && (
                            <Button
                              className="buy-now-button"
                              buttonClass="action-button"
                              onClick={this.props.onBuyNowClicked}
                              text={t('Buy Now')}
                            />
                          )}
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(AuctionItemDetailsModal),
);
