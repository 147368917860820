import React from 'react';

import './Toaster.scss';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IToasterState } from '../../reducers/toaster';
import { removeToast } from '../../actions/toaster';
import Toast from '../Toast/Toast';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  toasterState: IToasterState;
  removeToast(index?: number): void;
}

interface IState {
  toastInterval: any;
}

class Toaster extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      toastInterval: false,
    };
  }

  componentDidMount() {
    this.props.removeToast(-1);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.toasterState.toasts.length > 0 && this.state.toastInterval === false) {
      this.setState({
        toastInterval: setInterval(() => {
          this.props.removeToast();
        }, 5000),
      });
    }

    if (this.props.toasterState.toasts.length <= 0 && this.state.toastInterval !== false) {
      clearInterval(this.state.toastInterval);

      this.setState({
        toastInterval: false,
      });
    }
  }

  render() {
    if (this.props.toasterState.toasts.length > 0) {
      return (
        <div className="Toaster">
          {this.props.toasterState.toasts.map((item, index) => {
            return (
              <Toast
                key={index}
                index={index}
                toastType={item.toastType}
                message={item.message}
                title={item.title}
                icon={item.icon || 'fas fa-info-circle'}
                actionList={item?.actionList || []}
                {...this.props}
              />
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    toasterState: store.toasterState,
  };
};

const mapDispatchToProps = {
  removeToast,
};

export default withRouter(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Toaster)),
);
