import React, { FC, ReactNode, Reducer, useMemo, useReducer, useRef, useState } from 'react';
import { useEffect } from 'react';
import './Table.scss';
import { ReactComponent as EmptyStatePlaceholderSVG } from '../../../assets/placeholders/table-default-state-placeholder.svg';
import PopupMenu, { IPopupMenuItem } from '../PopupMenu/PopupMenu';
import usePaginatedQuery from '../../../hooks/useQueryClient';
import Loader from '../../Loader/Loader';
import { IActiveFilter, IFilter } from '../../../interfaces';
import TextField from '../../TextField/TextField';
import { IQueryParams, QueryHelpers } from '../../../helpers';
import Checkbox from '../../Checkbox/Checkbox';
import { useDebounce } from '../../../hooks/useDebounce';
import Button from '../../Button/Button';
import TableSideDrawer from './shared/TableSideDrawer/TableSideDrawer';
import {
  createRowInstances,
  getCurrentColumnSortDirection,
  ICellInstance,
  IColumnInstance,
  createTableState,
  IRowInstance,
  ITableAction,
  ITableState,
  TableAction,
  tableReducer,
  Renderer,
  Accessor,
  PredefinedColumnType,
  FieldOption,
  createHeaderActionInstances,
  FieldInstance,
  createActiveFilterInstance,
} from './tableState';
import FilterDrawer from './shared/FilterDrawer/FilterDrawer';
import { arrayHelpers } from '../../../helpers/arrayHelpers';

export interface IColumnOptions<T, TableMeta = any> {
  /** Comma delimitated path to the object properties value, or a unique string, used for sorting & filtering */
  id: string;
  Header?: Renderer<T> | string | undefined;
  /** Sets the initial value of a columns visibility */
  showColumn?: boolean;
  /** Sets the value of the column cell, should return a primitive type */
  accessor?: Accessor<T>;
  /** Provide if you want column cell on click to open the details panel.
   * @deprecated This isn't needed anymore, to be removed.
   *  Requires {@link {IProps.tableDrawerRenderer}. */
  showSelectedRowDrawerOnCellClick?: boolean;
  /** Provide if you want column to be sortable */
  sortable?: boolean | null;
  /** Provide this if you want to render a predefined column cell like Currency, Links, Dates, Avatars or Statuses
   * NOTE: Only statuses are available right now
   */
  predefinedColumnType?: PredefinedColumnType<T>;
  /** Provide if you want to have a custom cell renderer */
  Cell?: Renderer<T, TableMeta>;
  /** Should we translate this column accessor data? */
  notranslate?: 'yes';
}

interface ITableFilterOptions<T> {
  /** Searchable columns must be indexed on the backend */
  enableTableSearch?: boolean;
  enableColumnSorting?: boolean;
  enableFilterDrawer?: boolean;
  filterDrawerOptions?: Array<FieldOption>;
  searchPlaceholderValue?: string;
}

interface ITableQueryOptions<T> {
  /** Renders a pagesize selector on table, if you want a static page size only pass 1 number*/
  pageSizeOptions: number[];
  /** Provide a query from our 'requestAction' pattern. */
  queryAction: (search: URLSearchParams, ...args: any[]) => Promise<T[]>;
  /** These will be applied to the queryAction */
  additionalQueryParams?: IQueryParams;
}

export interface ITableActionOptions<T> {
  /**
   * An array of actions to be used for row actions (context menus / buttons), or
   * header actions (export, batch requests, etc).
   */
  tableActions: Array<ITableAction<T>>;
  /** When enabled, and a row is selected, it displays the batch actions provided to 'tableActions'
   * in the table header */
  enableMultiSelectActions?: boolean;

  enableRowContextMenuActions?: boolean;
  enableRowButtonActions?: boolean;

  /** A drawer to provide additional details for a row */
  rowDetailDrawerOptions?: (rowData: T) => {
    title: string;
    /** Let's you conditionally show the context menu item for enabling the expand row detail action */
    hideIf?: boolean;
    /** Inner content of the drawer */
    renderContent: () => ReactNode;
  };
}

export type RowSelectionMode = 'single' | 'multi-select';
export interface IRowSelectionOptions<T> {
  /** Provide a unique ID field. Example: (row) => typeHelpers.tryGetAssertNotNullOrUndefinedValue(row.id) */
  rowId: (rowData: T) => string;
  enableRowSelectionMode?: 'single' | 'multi-select';
  /** If row isn't selectable, it'll render in a disabled state. DEFAULT: true */
  isRowSelectable?: (rowData: T) => boolean;
  onRowSelectionChange?: (selectedRowData: T[]) => void;
}

export interface ITableProps<T, TableMeta = any> {
  columns: Array<IColumnOptions<T, TableMeta>>;
  pagination: ITableQueryOptions<T>;
  filterOptions?: ITableFilterOptions<T>;

  tableActionOptions?: ITableActionOptions<T>;

  /** Provide if you want to show empty state when there's no table data */
  emptyStateConfig?: {
    title: string;
    description?: string;
    SVGComponent?: FC;
  };

  /** Used to enable row selection with a checkbox, can be used as multi row or single row selection */
  rowSelectionOptions?: IRowSelectionOptions<T>;
  /**
   * Used to re-run the query when an action is ran outside of the Tables scope. Simply increment the counter by 1.
   */
  refreshTableIncrementor?: number;
  /**
   * This property is used to pass arbitrary context, state or functions to be used with your table. A usecase
   * for this would be editable columns or loading external data into a column to render.
   *
   */
  meta?: TableMeta;

  /** Provide if you want custom subtext */
  subtext?: string;
}

/**
 * Accepts two generic types 'T' and 'TableMeta':
 * - 'T' is the main data type, for example 'IGroup'.
 * - 'TableMeta' - This property is used to pass arbitrary context, state or functions to be used with your table. A usecase
 *   for this would be editable columns or loading external data into a column to render.
 *
 * TODO:
 *
 * - FilterDrawer
 *   - Finish adding support for async filters
 *   - Apply active filters from FilterDrawer to the query
 *   - Backend: Add support for range filters
 * - Global multi-select toggle for rows (select-all, deselect-all)
 * - Multi-Select
 *   - Global multi-select toggle for rows (select-all, deselect-all)
 *   - Add disabled state for checkbox UI
 * - Disabled state for row selection - Prevent rows from being selected by provided condition
 *   - Add disabled styles to Checkbox
 * - Finish converting local state to tableState
 * - Add tooltip functionality for truncated columns
 */
function Table<T, TableMeta>(props: ITableProps<T, TableMeta>) {
  const [tableState, dispatch] = useReducer<
    Reducer<ITableState<T>, TableAction<T>>,
    ITableProps<T, TableMeta>
  >(tableReducer, props, createTableState);

  //Cell State TODO: Transfer to tableReducer
  // const [cellClicked, setCellClicked] = useState<ICellInstance<T> | null>(null);
  // const [showFullCellById, setShowFullCellById] = useState<string | null>(null);
  // const [showTableDrawerById, setShowTableDrawerById] = useState<string | null>(null);

  const debouncedSearch = useDebounce(tableState.filterState.tableSearchValue);

  const headers = tableState.columnState.columnInstances.filter(
    (column) => column.showColumn !== true,
  );
  const tableWrapRef = useRef<HTMLDivElement>(null);
  const queryClient = usePaginatedQuery(props.pagination.queryAction, {
    pageSize: props.pagination.pageSizeOptions[0],
    queryParams: {
      ...props.pagination.additionalQueryParams,
      search: debouncedSearch,
      filters: tableState.filterState.activeColumnFilters,
      sort: tableState.filterState.sortedColumnFilters,
    },
  });

  useEffect(() => {
    buildRowInstances();
  }, [queryClient.currentPage.data]);

  useEffect(() => {
    if (props.refreshTableIncrementor) {
      queryClient.refreshCurrentPage();
    }
  }, [props.refreshTableIncrementor]);

  useEffect(() => {
    props.rowSelectionOptions?.onRowSelectionChange?.(tableState.rowState.selectedRowData);
  }, [tableState.rowState.selectedRowData]);

  useEffect(() => {
    const { headerActions, headerSelectedRowActions, headerActionsPopupMenu } =
      createHeaderActionInstances(props, dispatch);

    dispatch({
      type: 'build-header-action-instances',
      payload: { headerActions, headerSelectedRowActions, headerActionsPopupMenu },
    });
  }, [props.filterOptions?.filterDrawerOptions]);

  function buildRowInstances() {
    const rowData = queryClient.currentPage.data;
    const rowInstances = createRowInstances<T>({
      columnInstances: tableState.columnState.columnInstances.filter(
        (column) => column.showColumn !== true,
      ),
      rowData: rowData,
      dispatch,
      tableOptions: props,
    });

    dispatch({ type: 'build-row-instances', payload: { rowInstances } });
  }

  //TODO: Implement hover timer before rendering expanded cell
  //TODO: WIP - Use useReducer state instead of local state.
  function handleCellHover(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, cellId: string) {
    const cellWidth = e.currentTarget.getBoundingClientRect().width;
    const childWidth = e.currentTarget.firstElementChild?.getBoundingClientRect().width;

    // if ((childWidth ?? 0) > cellWidth) {
    //     setShowFullCellById(cellId);
    // } else {
    //     setShowFullCellById(null);
    // }
  }

  function renderColumnHeaders() {
    function handleSortClick(columnId: string) {
      dispatch({
        type: 'apply-column-sort',
        payload: {
          columnId,
        },
      });
    }

    function handleHeaderClick(
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      shouldSortOnClick: boolean,
      column: IColumnInstance<T>,
    ) {
      if (shouldSortOnClick) {
        handleSortClick(column.id);
      }
    }

    return headers.map((column) => {
      const columnSortSetting = getCurrentColumnSortDirection(
        column.id,
        tableState.filterState.sortedColumnFilters,
      );
      const shouldSortOnClick = column.sortable === true;

      if (!column.shouldRender) return;

      return (
        <th
          key={`header_${column.id}`}
          onClick={(e) => handleHeaderClick(e, shouldSortOnClick, column)}
          className={`${column.sortable ? 'sortable' : ''}`}
        >
          {column.Header}
          {!!column.sortable && !!columnSortSetting?.order && (
            <i className={`fa fa-chevron-${columnSortSetting.order === 'desc' ? 'down' : 'up'}`} />
          )}
        </th>
      );
    });
  }

  function renderRows() {
    function handleSelectRowClick(rowInstance: IRowInstance<T>) {
      dispatch({
        type: 'toggle-select-row',
        payload: {
          rowId: rowInstance.id,
          mode: props.rowSelectionOptions?.enableRowSelectionMode ?? 'multi-select',
        },
      });
    }

    //TODO: Implement cell popups
    return tableState.rowState.rowInstances.map((row) => {
      return (
        <tr key={row.id}>
          {row.cells.map((cell, cellIdx) => {
            const cellId = cell.id;
            // const shouldShowCellPopup = cellId === showFullCellById && shouldRenderCell;

            if (!cell.column.shouldRender) return;

            switch (cell.column.type) {
              case 'DEFAULT': {
                return (
                  <td
                    key={cellId}
                    onMouseOver={(e) => handleCellHover(e, cellId)}
                    // className={`${shouldShowCellPopup ? "show-full-cell" : ""}`}
                  >
                    {renderCellContent(cell)}
                    {/* {shouldShowCellPopup && renderFullCellPopup(cell)} */}
                  </td>
                );
              }
              case 'SELECTABLE': {
                return (
                  <td
                    key={cellId}
                    onMouseOver={(e) => handleCellHover(e, cellId)}
                    className={`selectable-column`}
                  >
                    <Checkbox
                      name="select"
                      disabled={!props.rowSelectionOptions?.isRowSelectable?.(row.rowData) ?? false}
                      onChange={() => handleSelectRowClick(row)}
                      checked={tableState.rowState.selectedRowInstanceMap?.[row.id] != null}
                      value="selectable"
                    />
                  </td>
                );
              }
              case 'ACTION_CONTEXT_MENU': {
                return (
                  <td
                    key={cellId}
                    onMouseOver={(e) => handleCellHover(e, cellId)}
                    className="action-menu-column"
                  >
                    {renderActionContextMenu(row, cell.id)}
                  </td>
                );
              }
              case 'ACTION_BUTTONS': {
                return (
                  <td
                    key={cellId}
                    className={`action-menu-button-column`}
                  >
                    {renderActionMenuAsButtons(row)}
                  </td>
                );
              }
              default:
                return null;
            }
          })}
        </tr>
      );
    });
  }

  /**
   * Builds a predefined cell based on column props, this avoids the need to
   * create custom 'Cells' for common column types like links, avatars, dates, currency, etc
   * @param cell
   */
  function renderPredefinedCell(cell: ICellInstance<T>) {
    const { column, row, index } = cell;

    switch (cell.column.predefinedColumnType?.type) {
      case 'STATUS': {
        const mappedStatus = cell.column.predefinedColumnType.columnObject?.(row.rowData);
        const statusAccessor = cell.column.accessor?.(row.rowData, index);
        if (statusAccessor) {
          const { label, color } = mappedStatus[statusAccessor as string];

          return (
            <div className={`cell-status ${color}`}>
              <span className="label">{label}</span>
            </div>
          );
        }

        return '';
      }
      default:
        return '';
    }
  }

  function renderCellContent(cell: ICellInstance<T>) {
    const { column, row, index } = cell;
    const accessor = column.accessor;
    const shouldRenderPredefinedColumn = column.predefinedColumnType != null;
    const shouldRenderFromCell = column.Cell != null && !shouldRenderPredefinedColumn;
    const shouldRenderFromAccessor = !shouldRenderFromCell && !shouldRenderPredefinedColumn;

    if (shouldRenderPredefinedColumn) {
      return renderPredefinedCell(cell);
    } else if (shouldRenderFromCell) {
      return column.Cell?.(row.rowData, props.meta);
    } else if (shouldRenderFromAccessor) {
      return (
        <div
          // className={`${getLinkClass}`}
          notranslate={column.notranslate}
        >
          {accessor?.(row.rowData, index)}
        </div>
      );
    }
  }

  function renderActionContextMenu(row: IRowInstance<T>, cellId: string) {
    const menuActions = row.getRowContextMenuActions();
    const popupMenuItems: IPopupMenuItem[] =
      menuActions
        /** Doesn't render hidden context menu items */
        ?.filter((action) => !action.hideIf?.(row.rowData))
        ?.map((action) => ({
          id: cellId,
          label: action?.label ?? '',
          icon: action?.icon,
          onClick: (e) => {
            action?.onClick(e, row.rowData);
            dispatch({
              type: 'toggle-cell-context-menu',
              payload: { cellId, showColumnContextMenu: false },
            });
          },
        })) || [];

    const showContextMenu = tableState.rowState.showColumnContextMenuById === cellId;
    return (
      <PopupMenu
        showMenuConfig={{
          position: {
            type: 'bottom',
            alternate: 'start',
          },
          showMenu: showContextMenu,
          setShowMenu: (showMenu) => {
            dispatch({
              type: 'toggle-cell-context-menu',
              payload: { cellId, showColumnContextMenu: showMenu },
            });
          },
        }}
        menuItems={popupMenuItems}
        className={`action-menu-target`}
        onClick={() =>
          dispatch({
            type: 'toggle-cell-context-menu',
            payload: { cellId, showColumnContextMenu: true },
          })
        }
      >
        <i className={`fa fa-ellipsis-v ${showContextMenu ? 'show-menu' : ''}`} />
      </PopupMenu>
    );
  }

  function renderActionMenuAsButtons(row: IRowInstance<T>) {
    const buttonActions = row.getRowButtonActions();

    return (
      <div className="button-action-container">
        {buttonActions
          /** Doesn't render hidden action button items */
          ?.filter((button) => !button.hideIf?.(row.rowData))
          ?.map((button, idx) => (
            <Button
              buttonType={button.buttonType || 'primary'}
              text={button.label}
              icon={button.icon}
              key={idx}
              onClick={(e) => button.onClick?.(e, row.rowData)}
            />
          ))}
      </div>
    );
  }

  const renderFullCellPopup = (cell: ICellInstance<T>) => (
    <div className="show-full-cell">{renderCellContent(cell)}</div>
  );

  const renderLoadingState = (isLoading: boolean) => <Loader loading={isLoading} />;

  function renderFilterDrawer() {
    function handleOnApplyFilters(filterFields: FieldInstance[]) {
      dispatch({
        type: 'update-all-filter-instances',
        payload: {
          filters: filterFields
            .flatMap((field) => createActiveFilterInstance(field))
            .filter(arrayHelpers.notEmpty),
        },
      });
    }

    if (
      tableWrapRef.current != null &&
      props.filterOptions?.enableFilterDrawer &&
      props.filterOptions.filterDrawerOptions != null
    ) {
      const tableWrapRect = tableWrapRef.current.getBoundingClientRect();
      const popupMenuItems: IPopupMenuItem[] = [];

      return (
        <FilterDrawer
          showDrawer={tableState.drawerState.activeDrawer?.type === 'FILTER_DRAWER'}
          availableFields={props.filterOptions.filterDrawerOptions}
          onCloseDrawer={() => dispatch({ type: 'close-active-drawer' })}
          onClearFilters={() => dispatch({ type: 'clear-filters' })}
          onApplyFilters={handleOnApplyFilters}
        />
      );
    }
  }

  function renderRowDetailDrawer() {
    if (
      tableWrapRef.current != null &&
      props.tableActionOptions?.rowDetailDrawerOptions &&
      tableState.drawerState.activeDrawer?.type === 'SELECTED_ROW_DRAWER'
    ) {
      const selectedRowDrawerState = tableState.drawerState.activeDrawer;
      const row = selectedRowDrawerState.selectedRow;
      const menuItems = row.actionMenu?.(row.rowData);
      const popupMenuItems: IPopupMenuItem[] =
        menuItems?.map((item, idx) => ({
          id: idx.toString(),
          label: item?.label ?? '',
          icon: item?.icon,
          onClick: (e) => {
            item?.onClick(e, row.rowData);
          },
        })) || [];

      const { title, renderContent } = props.tableActionOptions.rowDetailDrawerOptions?.(
        row.rowData,
      );

      return (
        <TableSideDrawer
          title={title}
          showDrawer={selectedRowDrawerState.type === 'SELECTED_ROW_DRAWER'}
          headerContextMenuItems={popupMenuItems}
          onClose={() => dispatch({ type: 'close-active-drawer' })}
        >
          {renderContent()}
        </TableSideDrawer>
      );
    }
  }

  function renderPagination() {
    const {
      goNextPage,
      goPrevPage,
      canGoNext,
      canGoPrev,
      currentPageIndex,
      hasMore,
      currentPage,
      pageSize,
    } = queryClient;
    const showingFrom = currentPageIndex > 0 ? currentPageIndex * pageSize : 1;
    const showingTo = currentPageIndex > 0 ? showingFrom + pageSize : pageSize;

    return (
      <div className="pagination-container">
        <div className="pagination-metrics-container">
          {currentPage.data.length > 0 && props.subtext ? props.subtext : ``}

          {currentPage.data.length > 0 && !props.subtext
            ? `Showing ${showingFrom} - ${showingTo} records ${hasMore && !currentPage.isLoading ? '+' : ''}`
            : ``}

          {renderLoadingState(currentPage.isLoading)}
        </div>
        <div className="buttons-container">
          <i
            className={`fa fa-chevron-left ${canGoPrev ? '' : 'disabled'}`}
            onClick={goPrevPage}
          />
          <i
            className={`fa fa-chevron-right ${canGoNext ? '' : 'disabled'}`}
            onClick={goNextPage}
          />
        </div>
      </div>
    );
  }

  function renderTableHeader() {
    const shouldRenderSearchField = props.filterOptions?.enableTableSearch;

    function handleSearch(value: string) {
      dispatch({
        type: 'search-table',
        payload: {
          tableSearchValue: value,
        },
      });
    }

    return (
      <div className="table-header-container">
        {shouldRenderSearchField && (
          <TextField
            autoComplete="off"
            placeholder={
              props.filterOptions?.searchPlaceholderValue
                ? props.filterOptions?.searchPlaceholderValue
                : 'Search...'
            }
            icon="fal fa-search"
            value={tableState.filterState.tableSearchValue}
            name="search"
            type="text"
            className="searchField"
            onChange={(e) => handleSearch(e.target.value)}
          />
        )}
        <div className="header-actions-container">
          {!tableState.headerState.showRowSelectActions &&
            tableState.headerState.headerActions.map((action, idx) => (
              <Button
                buttonType={action.buttonType || 'dark'}
                text={action.label}
                icon={action.icon}
                key={idx}
                onClick={(e) => action.onClick(e)}
              />
            ))}
          {tableState.headerState.showRowSelectActions &&
            tableState.headerState.headerSelectedRowActions.map((action, idx) => (
              <Button
                buttonType="dark"
                text={action.label}
                icon={action.icon}
                isDisabled={action.disableIf?.(tableState.rowState.selectedRowData) ?? false}
                key={idx}
                onClick={(e) => action.onClick(e, tableState.rowState.selectedRowData)}
              />
            ))}
          {tableState.headerState.headerActionsPopupMenu.map((action, id) => (
            <PopupMenu
              key={id}
              menuItems={action.menuItems}
              showMenuConfig={{
                position: { type: 'bottom' },
                setShowMenu: (isActive) => {
                  dispatch({ type: 'toggle-active-popup-menu', payload: { isActive, id } });
                },
                showMenu:
                  tableState.headerState.headerActionsPopupMenuShowActiveMenu &&
                  tableState.headerState.headerActionsPopupMenuActiveMenuId === id,
              }}
              className={action?.className}
              popupMenuClass={action?.popupMenuClass}
              onClick={() => {
                dispatch({
                  type: 'toggle-active-popup-menu',
                  payload: {
                    isActive: !tableState.headerState.headerActionsPopupMenuShowActiveMenu,
                    id,
                  },
                });
              }}
              onSelect={() => {
                dispatch({ type: 'toggle-active-popup-menu', payload: { isActive: false, id } });
              }}
            >
              {action.renderPopupMenuView()}
            </PopupMenu>
          ))}
        </div>
      </div>
    );
  }

  function renderEmptyTableState() {
    const { emptyStateConfig } = props;
    const shouldRenderEmptyState =
      emptyStateConfig != null &&
      (queryClient.currentPage.data.length === 0 || queryClient.pageCount === 0) &&
      !queryClient.currentPage.isLoading;

    if (shouldRenderEmptyState) {
      return (
        <div className="empty-state">
          <div className="icon">
            <EmptyStatePlaceholderSVG />
          </div>
          <span className="title">{emptyStateConfig?.title}</span>
          <div className="description">{emptyStateConfig?.description}</div>
        </div>
      );
    }
  }

  return (
    <div className="TableComponent">
      {renderTableHeader()}
      <div
        ref={tableWrapRef}
        className="table-wrap"
      >
        <table
        // onMouseLeave={() => setShowFullCellById(null)} - TODO: Implement with useReducer
        >
          <thead>
            <tr>{renderColumnHeaders()}</tr>
          </thead>
          <tbody>
            {renderRows()}
            <tr className="table-filler">
              <td colSpan={10000}>
                {renderEmptyTableState()}
                {renderLoadingState(queryClient.currentPage.isLoading)}
              </td>
            </tr>
          </tbody>
        </table>
        {renderRowDetailDrawer()}
        {renderFilterDrawer()}
      </div>
      {renderPagination()}
    </div>
  );
}
export default Table;
