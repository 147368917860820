import React from 'react';

import './SearchResultSkeleton.scss';

export default function SearchResultSkeleton() {
  return (
    <div className="SearchResultSkeleton">
      {/* Cover Image Outline */}
      <div className="top-section">
        {/* Profile Image outline */}
        <div></div>
        <div className="loading-dots-container">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
      <div className="bottom-section">
        {/* Title Outline */}
        <span></span>
        {/* Description Outline */}
        <span></span>
      </div>
    </div>
  );
}
