import { IPage, IPageFE } from '@gigit/interfaces';
import _ from 'lodash';
import { createToast } from '../../../actions/toaster';
import { errorHelpers, toastError, formatQuery } from '../../../helpers';
import { causeRequestActions } from '../../../requestActions';
import { ICausesSelection, IEntityBaseInfo, IOnboardStep, IOnboardStepData } from './interfaces';
import { ICreateGroupFormData } from '../../../routes/CreateAGroup/CreateAGroup';
import { ICreateEventFormData } from '../../../routes/CreateAnEvent/CreateAnEvent';
import { ICreateGigFormData } from '../../../routes/CreateAGig/CreateAGig';
import { ICreateHubFormData } from '../../../routes/CreateAHub/CreateAHub';
import { IRegisterFormData } from '../../../routes/Register/Register';
import { isMoment } from 'moment';
import { isString } from 'util';
import { DiversityType, passwordStrength } from 'check-password-strength';
import { registerPasswordRules } from '../../../routes/Register/Config';

export const makePageSequence = (pages: Array<IPageFE>): Array<IPageFE> => {
  return pages.map((page: IPageFE, idx: number) => {
    let sequence = idx + 1;
    return { ...page, sequence: sequence };
  });
};

export const getBaseInfo = (data: any): IEntityBaseInfo => {
  return data.baseInfo;
};

export const isValid = (
  formData:
    | ICreateGroupFormData
    | ICreateEventFormData
    | ICreateGigFormData
    | ICreateHubFormData
    | IRegisterFormData,
  step: IOnboardStepData,
): boolean => {
  let value = _.get(formData, step.path);
  if (Array.isArray(value)) {
    return value.length > 0;
  } else {
    let valid = step.required ? value : null;

    switch (step.path) {
      case 'baseInfo.address.address':
        return value?.formatted_address.length > 1;
      case 'userInfo.password':
        const userData = formData as IRegisterFormData;
        const passwordData = passwordStrength(userData.userInfo.password);
        const isPasswordValid = registerPasswordRules.every((rule: DiversityType) => {
          return passwordData.contains.includes(rule);
        });
        return userData.userInfo.password ? isPasswordValid : false;
      case 'userInfo.email':
        const userDataEmail = formData as IRegisterFormData;
        return new RegExp('[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z]{2,3}').test(
          userDataEmail.userInfo.email,
        );
      case 'baseInfo.startDate' || step.path === 'baseInfo.endDate':
        const baseInfo = getBaseInfo(formData);
        const startDate = baseInfo.startDate;
        const endDate = baseInfo.endDate;
        if (isMoment(startDate) && isMoment(endDate)) {
          return startDate.isBefore(endDate);
        } else if (isString(startDate) && isString(endDate)) {
          return Date.parse(startDate) < Date.parse(endDate);
        }
        return false;
      default:
        return valid;
    }
  }
};

export const hasAllRequiredFields = (
  steps: Array<IOnboardStep>,
  currentStep: string,
  formData: ICreateGroupFormData | ICreateEventFormData | ICreateHubFormData | IRegisterFormData,
): boolean => {
  const index = steps.findIndex((step) => step.id === currentStep);
  const isRequired = steps[index]?.data.filter((stepData: IOnboardStepData) => stepData.required);
  const hasAllRequiredFields = steps[index]?.data.filter((stepData: IOnboardStepData) =>
    isValid(formData, stepData),
  );

  return hasAllRequiredFields?.length >= isRequired?.length;
};

export const getCauses = async (causeInfo: ICausesSelection) => {
  try {
    const causes = await causeRequestActions.getCauses(
      formatQuery({
        sort: [{ id: 'name', order: 'desc' }],
      }),
    );
    return { ...causeInfo, causes };
  } catch (error) {
    const errorObj = errorHelpers.getErrorObject(error);
    let toast = toastError(errorObj.translatedMessage, 'Get Focus Areas');
    createToast(toast);
  }
};

export const isSelectedCause = (causes: Array<string>, causeId: string): number => {
  let causeIndex = causes.findIndex((cause: string) => cause === causeId);

  if (causeIndex >= 0) {
    return causeIndex;
  } else {
    return -1;
  }
};

export async function setCauses(
  causeInfo: ICausesSelection,
  handleCauseInfo: (updatedCauseInfo: ICausesSelection) => void,
) {
  let populatedCauseInfo = await getCauses(causeInfo);
  if (populatedCauseInfo) handleCauseInfo(populatedCauseInfo);
}

export function onSelectCause(
  causeInfo: ICausesSelection,
  causeId: string,
  handleCauseInfo: (updatedCauseInfo: ICausesSelection) => void,
) {
  let causeIndex = isSelectedCause(causeInfo.selectedCauses, causeId);
  let selectedCauses = [...causeInfo.selectedCauses];

  if (causeIndex > -1) {
    selectedCauses.splice(causeIndex, 1);
    let causeData = { ...causeInfo, selectedCauses };
    handleCauseInfo(causeData);
  } else {
    selectedCauses.push(causeId);
    let causeData = { ...causeInfo, selectedCauses };
    handleCauseInfo(causeData);
  }
}

export function onBack(
  steps: IOnboardStep[],
  currentStep: string,
  setCurrentStep: (step: string) => void,
  additionalSkip?: boolean,
) {
  const index = steps.findIndex((step) => step.id === currentStep);
  let newStep: string;

  if (additionalSkip) {
    newStep = steps[index - 2]?.id;
  } else {
    newStep = steps[index - 1]?.id;
  }

  if (newStep) {
    setCurrentStep(newStep);
  }
}
