import React from 'react';
import { Config } from '@gigit/config';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { IAppState } from '../../../store';
import { defaultCurrency, errorHelpers, formatCurrency, toastError } from '../../../helpers';
import { IUserState } from '../../../reducers/user';
import { IEventState } from '../../../reducers/event';
import { userSelectors } from '../../../selectors/user';
import { updateUserCoverImage, updateUserProfileImage } from '../../../actions/user';
import CoverImage from '../../../components/CoverImage/CoverImage';
import Portrait from '../../../components/Portrait/Portrait';
import Button from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';
import Share from '../../../components/Share/Share';
import Modal from '../../../components/Modal/Modal';
import QuillTextEditor from '../../../components/QuillTextEditor/QuillTextEditor';
import { BenefitingCharityTile } from '../../../components/BenefitingCharityTile/BenefitingCharityTile';
import StravaSection from '../../../components/StravaSection/StravaSection';
import './IndividualDefault.scss';
import { uiConstants } from '../../../constants/uiConstants';
import { IEventIndividual, IEventTeam, IFundraiserDonation, IGroup } from '@gigit/interfaces';
import { individualRequestActions } from '../../../requestActions/individual';
import { createToast } from '../../../actions/toaster';
import { teamRequestActions } from '../../../requestActions/team';
import { Constants } from '@gigit/constants';

interface IPassedProps extends RouteComponentProps<any> {
  individual: any;
}

interface IPropsFromState {
  userState: IUserState;
  eventState: IEventState;
  locale: string;
}

interface IPropsFromDispatch {
  updateUserProfileImage(payload: any): void;
  updateUserCoverImage(payload: any): void;
}

type IProps = IPassedProps & IPropsFromDispatch & IPropsFromState;

interface IState {
  editStory: boolean;
  showShareModal: boolean;
  eventIndividual: IEventIndividual | null;
  isIndividualLoading: boolean;
  isTeamLoading: boolean;
  donations: IFundraiserDonation[];
}

class IndividualDefault extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      editStory: false,
      showShareModal: false,
      eventIndividual: null,
      isIndividualLoading: false,
      isTeamLoading: false,
      donations: [],
    };

    this.handleStoryChange = this.handleStoryChange.bind(this);
    this.checkFees = this.checkFees.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      this.props.eventState.event !== prevProps.eventState.event &&
      this.props.eventState.event.id !== ''
    ) {
      this.getIndividual(this.props.eventState.event.id, this.props.match.params.individualHandle);
    }
  }

  handleStoryChange(content: string) {
    this.setState({
      eventIndividual: {
        ...this.state.eventIndividual!,
        story: content,
      },
    });
  }

  saveIndividual() {
    this.updateIndividual(this.props.eventState.event.id, this.props.userState.user.id!, {
      story: this.state.eventIndividual?.story,
    });
  }

  async updateIndividual(eventId: string, userId: string, payload: Partial<IEventIndividual>) {
    try {
      await individualRequestActions.updateIndividual(eventId, payload);
      this.getIndividual(eventId, userId);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Individual');
      createToast(toast);
    }
  }

  async getIndividual(eventId: string, userId: string) {
    try {
      const eventIndividual = await individualRequestActions.getIndividual(eventId, userId);
      this.setState({ eventIndividual });
      this.getIndividualDonations(eventId, eventIndividual.user_id);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Individual');
      createToast(toast);
    }
  }

  async getIndividualDonations(eventId: string, userId: string) {
    try {
      const donations = await individualRequestActions.getIndividualDonations(eventId, userId);
      this.setState({ donations });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Individual Donations');
      createToast(toast);
    }
  }

  hasDonationMatching() {
    return (
      this.props.eventState.event?.donations_matching &&
      this.props.eventState.event.donations_matching?.organization &&
      this.props.eventState.event.donations_matching?.max_amount &&
      this.props.eventState.event.donations_matching?.ratio &&
      this.props.eventState.event.donations_matching?.ratio_description
    );
  }

  checkFees(donation: any) {
    const { amount, payment_platform_fee } = donation.amounts;
    const { covers_processing_fees } = donation.fee_control;

    return covers_processing_fees ? amount + payment_platform_fee : amount;
  }

  onImageChange = (fileData: any) => {
    switch (fileData.type) {
      case 'cover_image_url':
        this.props.updateUserCoverImage(fileData.file);
        break;
      case 'profile_image_url':
        this.props.updateUserProfileImage(fileData.file);
        break;
    }
  };

  isIndividualCurrentUser = () => {
    const { userState, individual } = this.props;

    return userState.user.id === individual.user_id;
  };

  render() {
    const currency = this.props.eventState.event.group?.account?.currency ?? defaultCurrency;

    return (
      <div className="IndividualDefault">
        <div className="Individual-content">
          <div className="sidebar">
            <div className="sidebar-inner">
              {!this.state.isIndividualLoading && (
                <Portrait
                  size={240}
                  currentImage={this.state.eventIndividual?.user?.profile_image_url}
                  onChange={this.onImageChange}
                  overlay={this.isIndividualCurrentUser()}
                />
              )}
              {!this.state.isIndividualLoading && (
                <div className="sidebar-content">
                  <div className="sidebar-info">
                    <div className="type team">
                      <i className="far fa-user" />
                      <span>INDIVIDUAL</span>
                    </div>
                    <h1
                      className="name"
                      notranslate="yes"
                    >
                      <span>{this.state.eventIndividual?.user?.display_name}</span>
                    </h1>
                    <div
                      className="handle"
                      notranslate="yes"
                    >
                      <Link to={'/event/' + this.state.eventIndividual?.event?.handle}>
                        {this.props.eventState.event.title}
                      </Link>
                    </div>
                    {this.state.eventIndividual?.team_id && this.renderTeam()}
                  </div>
                  {this.hasDonationMatching() && (
                    <div className="donation-matching-message">
                      <h3>Donation Matching</h3>
                      <p notranslate="yes">
                        {this.props.eventState.event?.donations_matching?.ratio_description}
                      </p>
                      {this.props.eventState.event?.donations_matching?.matched_amount !== 0 && (
                        <span>
                          <var data-var="matched_so_far">
                            {formatCurrency(
                              this.props.eventState.event?.donations_matching?.matched_amount || 0,
                              currency,
                              this.props.locale,
                            )}
                          </var>{' '}
                          Matched to Date
                        </span>
                      )}
                    </div>
                  )}
                  <div className="sidebar-actions">
                    {this.props.userState.user.handle ===
                      this.props.match.params.individualHandle && (
                      <Link
                        className="manage"
                        to={
                          '/event/' +
                          this.state.eventIndividual?.event?.handle +
                          '/individual/' +
                          this.state.eventIndividual?.user?.handle +
                          '/manage'
                        }
                      >
                        Manage
                      </Link>
                    )}
                    {uiConstants.activeEventStatuses.includes(
                      this.props.eventState.event?.status?.code ?? '',
                    ) && (
                      <Link
                        to={
                          '/event/' +
                          this.state.eventIndividual?.event?.handle +
                          '/individual/' +
                          this.state.eventIndividual?.user?.handle +
                          '/donate'
                        }
                      >
                        Donate Now
                      </Link>
                    )}
                    <Button
                      onClick={() => {
                        this.setState({ showShareModal: true });
                      }}
                      text="Share"
                    />
                  </div>
                </div>
              )}
              {this.state.isIndividualLoading && (
                <div className="load-wrap">
                  <Loader loading={this.state.isIndividualLoading} />
                </div>
              )}
            </div>
          </div>
          <div className={this.props.individual.content_creator ? 'main cc' : 'main'}>
            {!this.props.individual.content_creator && (
              <CoverImage
                currentImage={this.state.eventIndividual?.user?.cover_image_url}
                onChange={this.onImageChange}
                overlay={this.isIndividualCurrentUser()}
              />
            )}
            <div className="Individual-main-content">
              {!this.state.isIndividualLoading && (
                <div className="inner-content">
                  <div className="fundraise-bar">
                    <div className="details">
                      <div className="amount">
                        <span notranslate="yes">
                          {formatCurrency(
                            this.state.eventIndividual?.raised || 0,
                            currency,
                            this.props.locale,
                          )}
                        </span>
                        <span>
                          raised of{' '}
                          <var data-var="goal">
                            {formatCurrency(
                              this.state.eventIndividual?.goal || 0,
                              currency,
                              this.props.locale,
                            )}
                          </var>
                        </span>
                      </div>
                      <div className="percentage">
                        <div className="circle">
                          <span notranslate="yes">
                            {Math.min(
                              ((this.state.eventIndividual?.raised || 0) /
                                (this.state.eventIndividual?.goal || 1)) *
                                100,
                              100,
                            ).toFixed(2)}
                            %
                          </span>
                          <span>achieved</span>
                        </div>
                      </div>
                    </div>
                    <div className="bar-wrap">
                      <div className="bar">
                        <div
                          style={{
                            width:
                              Math.min(
                                99,
                                Math.max(
                                  3,
                                  Math.round(
                                    ((this.state.eventIndividual?.raised || 0) /
                                      (this.state.eventIndividual?.goal || 1)) *
                                      100,
                                  ),
                                ),
                              ) + '%',
                          }}
                          className="bar-inner"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="story">
                    <div className="story-title">
                      <span>Story</span>
                      {this.state.eventIndividual?.user?.handle ===
                        this.props.userState.user.handle && (
                        <i
                          onClick={() => {
                            this.setState({ editStory: !this.state.editStory }, () => {
                              if (!this.state.editStory) {
                                this.saveIndividual();
                              }
                            });
                          }}
                          className={this.state.editStory ? 'fa fa-save' : 'fa fa-edit'}
                        />
                      )}
                    </div>
                    {!this.state.editStory &&
                      (this.props.individual.story === '' ||
                        this.props.individual.story === '<p><br></p>') && (
                        <p>This individual doesn't have a story yet.</p>
                      )}
                    {!this.state.isIndividualLoading &&
                      (this.state.editStory ||
                        (!this.state.editStory &&
                          this.props.individual.story !== '' &&
                          this.props.individual.story !== '<p><br></p>')) && (
                        <QuillTextEditor
                          value={this.state.eventIndividual?.story}
                          readOnly={!this.state.editStory}
                          preserveWhitespace={true}
                          theme={
                            this.state.editStory
                              ? uiConstants.quill.editableTheme
                              : uiConstants.quill.readOnlyTheme
                          }
                          modules={{
                            toolbar: uiConstants.quill.defaultToolbar,
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          formats={uiConstants.quill.defaultFormats}
                          onChange={this.handleStoryChange}
                        />
                      )}
                  </div>
                  {this.props.eventState.event.group && (
                    <BenefitingCharityTile group={this.props.eventState.event.group as IGroup} />
                  )}
                  <div className="donations">
                    <div className="donations-title">Donations</div>
                    {this.state.donations.length === 0 && (
                      <div className="empty">
                        This individual hasn't received any donations yet.
                      </div>
                    )}
                    <div className="donations-wrap">
                      {this.state.donations.map((donation, index) => {
                        return (
                          <div
                            className="donation"
                            key={index}
                          >
                            <Portrait
                              size={50}
                              currentImage={donation.profile_image_url}
                            />
                            <div className="donation-info">
                              <span
                                className="displayName"
                                notranslate="yes"
                              >
                                {donation.display_name}
                              </span>
                              <span className="donated">
                                <var data-var="amount_donated">
                                  {formatCurrency(
                                    this.checkFees(donation) *
                                      (donation.amounts.amount_multiplier ?? 1),
                                    currency,
                                    this.props.locale,
                                  ) || 0}
                                </var>{' '}
                                donated
                              </span>
                              {donation.comments && (
                                <div className="note">{donation?.comments}</div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <StravaSection type="individual" />
                </div>
              )}
              {this.state.isIndividualLoading && (
                <div className="load-wrap">
                  <Loader loading={this.state.isIndividualLoading} />
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          class="share-modal"
          show={this.state.showShareModal}
          onClose={() => {
            this.setState({ showShareModal: false });
          }}
        >
          <Share
            {...this.props}
            url={
              Config.web.REACT_APP_BASE_URL +
              '/event/' +
              this.state.eventIndividual?.event?.handle +
              '/individual/' +
              this.state.eventIndividual?.user?.handle
            }
          />
        </Modal>
      </div>
    );
  }

  renderTeam() {
    const team = this.state.eventIndividual?.team;
    const teamUrl = '/event/' + this.state.eventIndividual?.event?.handle + '/team/' + team?.handle;
    return (
      <div
        notranslate="yes"
        className="team"
      >
        <i className="far fa-users" />
        {!this.state.isTeamLoading && team?.handle && <Link to={teamUrl}>{team.name}</Link>}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    userState: store.userState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  updateUserCoverImage,
  updateUserProfileImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualDefault);
