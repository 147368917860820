import React from 'react';
import { Link } from 'react-router-dom';
import './CommunityFeedPostComments.scss';
import { IActivityFeedCommentSummaryFE } from '@gigit/interfaces';
import { localizeHelpers } from '../../../../../localizeHelpers';

interface IProps {
  comments: IActivityFeedCommentSummaryFE[];
  locale: string;
  setReplyTo(v: { commentId: string; user_display_name: string } | null): void;
  focusOnInput(): void;
  setShowReplyCommentModal?: () => void;
  shortPreviewMode?: boolean;
}

const CommunityFeedPostComments: React.FC<IProps> = (props) => {
  const onClickReply = (comment: IActivityFeedCommentSummaryFE): void => {
    props.setReplyTo({
      commentId: comment.id,
      user_display_name: comment.created_by?.display_name || '',
    });
    if (props.shortPreviewMode && props?.setShowReplyCommentModal) {
      props.setShowReplyCommentModal();
    }
    props.focusOnInput();
  };

  return (
    <div className="CommunityFeedPostComments">
      {props.comments.map((comment, indexComm) => {
        return (
          <div
            className="parent-comment-container"
            key={indexComm}
          >
            <Link to={'/user/' + comment.created_by?.handle}>
              {comment.created_by?.profile_image_url && (
                <img
                  src={comment.created_by.profile_image_url}
                  alt="user avatar"
                  height="40px"
                  width="40px"
                />
              )}
              {!comment.created_by?.profile_image_url && (
                <span className="user-avatar">
                  <i className="fas fa-user" />
                </span>
              )}
            </Link>
            <div className="comment-content-container">
              <div className="content-container">
                <span
                  className="comment-title"
                  notranslate="yes"
                >
                  <Link to={'/user/' + comment.created_by?.handle}>
                    {comment.created_by?.display_name}
                  </Link>
                </span>
                <p
                  className="comment-content"
                  notranslate="yes"
                >
                  {comment.content}
                </p>
              </div>
              <div className="reply-container">
                <span
                  className="reply"
                  onClick={() => {
                    onClickReply(comment);
                  }}
                >
                  REPLY
                </span>
                {comment?.created_at && (
                  <var data-var="comment_created_at">
                    {` ${localizeHelpers.formatTime(
                      {
                        hour: new Date(comment.created_at).getHours(),
                        minute: new Date(comment.created_at).getMinutes(),
                      },
                      props.locale,
                    )}`}
                  </var>
                )}
              </div>
              <div className="child-comments-container">
                {comment.child_comment_count > 0 &&
                  comment.child_comments.map((childComment, index) => {
                    if (index > 0 && !props?.shortPreviewMode) return null;
                    else
                      return (
                        <div
                          className="parent-comment-container"
                          key={index}
                        >
                          <Link to={'/user/' + childComment.created_by?.handle}>
                            {childComment.created_by?.profile_image_url && (
                              <img
                                src={childComment.created_by.profile_image_url}
                                alt="user avatar"
                                height="40px"
                                width="40px"
                              />
                            )}
                            {!childComment.created_by?.profile_image_url && (
                              <span className="user-avatar">
                                <i className="fas fa-user" />
                              </span>
                            )}
                          </Link>
                          <div className="comment-content-container">
                            <div className="content-container">
                              <span
                                className="comment-title"
                                notranslate="yes"
                              >
                                <Link to={'/user/' + childComment.created_by?.handle}>
                                  {childComment.created_by?.display_name}
                                </Link>
                              </span>
                              <p
                                className="comment-content"
                                notranslate="yes"
                              >
                                {childComment.content}
                              </p>
                            </div>
                            <div className="reply-container">
                              <span
                                className="reply"
                                onClick={() => {
                                  props.setReplyTo({
                                    commentId: comment.id,
                                    user_display_name: comment.created_by?.display_name || '',
                                  });
                                  if (props.shortPreviewMode && props?.setShowReplyCommentModal) {
                                    props.setShowReplyCommentModal();
                                  }
                                  props.focusOnInput();
                                }}
                              >
                                REPLY
                              </span>
                              {childComment?.created_at && (
                                <var data-var="comment_created_at">
                                  {` ${localizeHelpers.formatTime(
                                    {
                                      hour: new Date(childComment.created_at).getHours(),
                                      minute: new Date(childComment.created_at).getMinutes(),
                                    },
                                    props.locale,
                                  )}`}
                                </var>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                  })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CommunityFeedPostComments;
