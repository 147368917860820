import './CreateStory.scss';
import React, { useRef, useState } from 'react';
import { FormTopBar } from '../../components/shared/Onboarding/FormTopBar/FormTopBar';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import addStory from '../../assets/add-story.svg';
import TextField from '../../components/TextField/TextField';
import { CheckboxCard } from '../../components/shared/Onboarding/CheckboxCard/CheckboxCard';
import { FormBottomBar } from '../../components/shared/Onboarding/FormBottomBar/FormBottomBar';
import { SketchPicker } from 'react-color';
import Button from '../../components/Button/Button';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import {
  IEmbeddedAccount,
  IEventSummaryFE,
  IGroup,
  IHub,
  IMetricDefinition,
  IStoryCreateParamsFE,
  IStoryForegroundContent,
} from '@gigit/interfaces';
import { userRequestActions } from '../../requestActions/user';
import useToastDispatcher from '../../hooks/useToaster';
import Dropdown, { IOptions } from '../../components/Dropdown/Dropdown';
import { CustomDropdown } from '../../components/shared/Dropdown/Dropdown';
import { ReactComponent as AddBackground } from '../../assets/hub-add-background.svg';
import { ReactComponent as AddCTA } from '../../assets/hub-add-cta.svg';
import { ReactComponent as AddMetrics } from '../../assets/hub-add-metrics.svg';
import { ReactComponent as AddText } from '../../assets/hub-add-text.svg';
import Portrait from '../../components/Portrait/Portrait';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { userSelectors } from '../../selectors/user';
import Modal from '../../components/Modal/Modal';
import { IGetMetricParamsFE, metricRequestActions } from '../../requestActions/metrics';
import {
  capitalizeString,
  formatCurrency,
  formatQuery,
  isMobileScreen,
  uploadImageToStore,
} from '../../helpers';
import { cleanupDragLogic, configureDragLogic } from '../../components/Stories/dragHelpers';
import { storyRequestActions } from '../../requestActions/Stories';
import { IPopupMenuItem, IPopupPosition } from '../../components/shared/PopupMenu/PopupMenu';
import { groupRequestActions, hubRequestActions } from '../../requestActions';
import getCroppedImg from './crop';
import { useDebounce } from '../../hooks/useDebounce';
import { uiConstants } from '../../constants';

interface IStoryEntityItem {
  title: string;
  profile_image_url?: string;
  id: string;
  handle: string;
  account?: IEmbeddedAccount;
}

export interface IDraggableBox {
  x: string; // absolute x position
  y: string; // absolute y position
  color: string; // hex
  text: string;
  fontSize: string;
}

export interface metricLabels {
  volunteer_hours_submitted: string;
  volunteer_hours_approved: string;
  volunteer_hours_completed: string;
  amount_donated: string;
  amount_fundraised: string;
  amount_raised: string;
  num_fundraisers: string;
  num_auction_items_sold: string;
}

export const currencyMetrics = ['amount_donated', 'amount_fundraised', 'amount_raised'];

interface IProps extends RouteComponentProps {}

// Configuration Data
const backgroundTypes = {
  image: 'image',
  video: 'video',
  color: 'color',
};
const foregroundTypes = {
  text: 'text',
  metric: 'metric',
  cta: 'cta',
};
export const metricLabels: metricLabels = {
  volunteer_hours_submitted: 'Hours Volunteered',
  volunteer_hours_approved: 'Approved Volunteer Hours',
  volunteer_hours_completed: 'Completed Volunteer Hours',
  amount_donated: 'Total Donated',
  amount_fundraised: 'Total Fundraised',
  amount_raised: 'Amount Raised',
  num_fundraisers: 'Total Fundraisers',
  num_auction_items_sold: 'Auction Items Sold',
};
const storyTypes = [
  { type: 'user', label: 'Me', step: 'build' },
  { type: 'cause', label: 'Cause', step: 'entitySelection' },
  { type: 'company', label: 'Company', step: 'entitySelection' },
];

const storyActions = [
  { icon: <AddBackground />, text: 'Add Background', id: 'background' },
  // GIG-7322
  // { icon: <AddText />, text: 'Add Text', id: 'text' },
  // { icon: <AddMetrics />, text: 'Add Metrics', id: 'metric' },
  { icon: <AddCTA />, text: 'Add Call to Action', id: 'cta' },
];

const fontSizeOptions: IOptions[] = [
  { label: 'Very Small - 14 PT', value: '14' },
  { label: 'Small - 16 PT', value: '16' },
  { label: 'Medium - 18 PT', value: '18' },
  { label: 'Large - 20 PT', value: '20' },
];

const CallToActionOptions: IOptions[] = [
  { label: 'Donation', value: 'donate' },
  { label: 'Fundraise', value: 'fundraise' },
];

const defaultStoryEntityItem = {
  title: '',
  profile_image_url: '',
  id: '',
  handle: '',
  account: {} as IEmbeddedAccount,
};

const userSteps = ['selection', 'build', 'save'];
const defaultSteps = ['selection', 'entitySelection', 'build', 'save'];
const kambeoPurpleDark = '#5E51AB';
const kambeoPurpleDarker = '#484385';
const aspectRatio = 2 / 3;
const acceptedVideoFormats = ['mp4'];
// End Configuration Data

const CreateStory: React.FC<IProps> = (props) => {
  const navigateHome = () => props.history.push('/');
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  const [steps, setSteps] = useState<string[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<string>('selection');
  const [searchValue, setSearchValue] = useState<string>('');
  const [editMode, setEditMode] = useState<string>('');
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState<string | undefined>(
    kambeoPurpleDark,
  );
  const [temporaryBackgroundColor, setTemporaryBackgroundColor] =
    useState<string>(kambeoPurpleDark);
  const [imageSource, setImageSource] = useState<string>('');
  const [videoSource, setVideoSource] = useState<string>('');
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [showCrop, setShowCrop] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [croppedImageSource, setCroppedImageSource] = useState<string>('');
  const [temporaryCropped, setTemporaryCropped] = useState<string>('');
  const [textElements, setTextElements] = useState<any[]>([]);
  const [userGroups, setUserGroups] = useState<IGroup[]>([]);
  const [userHubs, setUserHubs] = useState<IHub[]>([]);
  const [entity, setEntity] = useState<IStoryEntityItem>(defaultStoryEntityItem);
  const [fileSize, setFileSize] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [fontColor, setFontColor] = useState<string>('');
  const [fontSize, setFontSize] = useState<string>('16');
  const [callToAction, setCallToAction] = useState<string>('');
  const [temporaryCallToAction, setTemporaryCallToAction] = useState<string>('');
  const [callToActionText, setCallToActionText] = useState<string>('');
  const [temporaryCallToActionText, setTemporaryCallToActionText] = useState<string>('');
  const [callToActionButton, setCallToActionButton] = useState<any>(undefined);
  const [buttonColor, setButtonColor] = useState<string>(kambeoPurpleDarker);
  const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);
  const [metrics, setMetrics] = useState<any[]>([]);
  const [selectedMetric, setSelectedMetric] = useState<string>('');
  const [event, setEvent] = useState<IEventSummaryFE | null>(null);
  const [events, setEvents] = useState<IEventSummaryFE[]>([]);
  const [metricElements, setMetricElements] = useState<any[]>([]);
  const [showMenu, setShowMenu] = useState<boolean>(true);
  const [showEventsMenu, setShowEventsMenu] = useState<boolean>(false);
  const [showMobileDrawer, setShowMobileDrawer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isLoggedIn = useSelector((state: IAppState) => userSelectors.isUserAuthenticated(state));

  const search = useDebounce(searchValue);
  const objectType = selectedEntity === 'cause' ? 'group' : selectedEntity;
  const history = useHistory();
  /**
   * View Refs
   */
  const storyCardRef = useRef<any>();
  const videoPlayerRef = useRef<any>();
  const callToActionButtonRef = useRef<any>();

  const textBoxRefs = useRef<any>([]);
  const metricBoxRefs = useRef<any>([]);

  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  /**
   * UseEffects
   */
  useEffect(() => {
    configureDragLogic();

    return () => {
      cleanupDragLogic();
    };
  }, []);
  useEffect(() => {
    if (entity?.id) {
      if (objectType === uiConstants.ownerType.group) {
        getGroupEvents();
      } else if (objectType === uiConstants.ownerType.company) {
        getHubEvents();
      }
    }
  }, [entity]);
  useEffect(() => {
    // getDrawerBody();
    // getDrawerTitle();

    if (editMode === 'text') {
      let textbox = { id: `${textElements.length}` };
      setTextElements([...textElements, textbox]);
    }

    if (editMode === 'metric') {
      const newMetric = getMetricBox({ label: 'Select A Metric', value: ' - ' });
      setMetricElements([...metricElements, newMetric]);
    }

    if (editMode === 'cta') {
      if (!callToActionButton) {
        const button = getCallToActionButton();
        setCallToActionButton(button);
      }
    }

    if (videoPlayerRef.current) {
      if (videoPlayerRef.current?.paused) {
        videoPlayerRef?.current?.play();
      } else {
        videoPlayerRef?.current?.pause();
      }
    }
  }, [editMode]);
  useEffect(() => {
    setSteps(defaultSteps);
    if (selectedEntity === uiConstants.ownerType.company) {
      getHubs();
      getMetrics();
    } else if (selectedEntity === 'cause') {
      getGroups();
      getMetrics();
    } else {
      setSteps(userSteps);
      if (selectedEntity) {
        getMetrics();
      }
    }
  }, [selectedEntity]);
  useEffect(() => {
    if (textBoxRefs?.current && textElements.length && editMode === 'text') {
      textBoxRefs.current[textElements.length - 1].style.color = fontColor;
    }

    if (metricBoxRefs?.current && metricElements.length && editMode === 'metric') {
      metricBoxRefs.current[metricElements.length - 1].style.color = fontColor;
    }
  }, [fontColor]);
  useEffect(() => {
    if (callToActionButtonRef?.current) {
      callToActionButtonRef.current.style.backgroundColor = buttonColor;
    }
  }, [buttonColor]);
  useEffect(() => {
    if (textBoxRefs?.current && textElements.length && editMode === 'text') {
      textBoxRefs.current[textElements.length - 1].style.fontSize = `${fontSize}px`;
    }

    if (metricBoxRefs?.current && metricElements.length && editMode === 'metric') {
      metricBoxRefs.current[metricElements.length - 1].style.fontSize = `${fontSize}px`;
    }
  }, [fontSize]);
  useEffect(() => {
    if (temporaryCallToActionText) setCallToActionButton(getCallToActionButton());
    if (buttonColor) setButtonColor(buttonColor);
  }, [temporaryCallToActionText, buttonColor]);
  useEffect(() => {
    if (selectedMetric) getMetric();
  }, [selectedMetric]);
  useEffect(() => {
    const params = formatQuery({ search: search });
    if (objectType === uiConstants.ownerType.company) {
      getHubs(params);
    } else {
      getGroups(params);
    }
  }, [search]);
  /**
   * Requests
   */
  async function getHubs(params?: URLSearchParams) {
    try {
      const hubs = await userRequestActions.getUserHubsForPermission('CREATE_STORIES', params);
      setUserHubs(hubs);
    } catch (error) {
      dispatchToastError(error, 'Hubs');
    }
  }
  async function getHubEvents() {
    try {
      const events = await hubRequestActions.getHubPartnerEvents(entity?.id || '');
      setEvents(events);
    } catch (error) {
      dispatchToastError(error, 'Events');
    }
  }
  async function getGroups(query?: URLSearchParams) {
    try {
      const groups = await userRequestActions.getUserGroupsForPermission('CREATE_STORIES', query);
      setUserGroups(groups);
    } catch (error) {
      dispatchToastError(error, 'Groups');
    }
  }
  async function getGroupEvents() {
    try {
      const events = await groupRequestActions.getGroupEvents(entity?.id || '', {});
      setEvents(events);
    } catch (error) {
      dispatchToastError(error, 'Events');
    }
  }
  async function getMetrics() {
    try {
      let metrics = await metricRequestActions.getAvailableMetricsForObjectType(objectType);
      metrics = metrics.filter((metric) => metric.object_types.includes(objectType));
      setMetrics(metrics);
    } catch (error) {
      dispatchToastError(error, 'User Metrics');
    }
  }
  async function getMetric() {
    // get Metric Value to build getMetric Payload
    const metric = metrics.find((m: IMetricDefinition) => m.metric === selectedMetric);
    const entityType = selectedEntity;
    let payload = {} as IGetMetricParamsFE;
    // Set Object Type and Object Type Ids
    if (metric) {
      payload.object_type = objectType;
      payload.metric = metric.metric;
      payload.object_id = entityType === 'user' ? user.id! : entity.id!;

      if (event) {
        payload.sub_filters = {
          event_id: event.id,
        };
      }

      if (metric.unit_type === 'currency') {
        payload.currency = entity?.account?.currency || 'usd';
      }
    }

    try {
      const metricInfo = await metricRequestActions.getMetric(payload);
      if (metricBoxRefs?.current) {
        const element = metricBoxRefs?.current[metricElements.length - 1];
        element.setAttribute('data-metric-value', selectedMetric);
        const textInner = element.children[0];
        const metricLabel = textInner.children[1];
        const metricValue = textInner.children[0];
        const formattedMetricValue =
          metric.unit_type === 'currency'
            ? formatCurrency(metricInfo.value, metricInfo.unit, 'cad')
            : metricInfo.value;

        metricLabel.innerText = metricLabels[metricInfo.metric as keyof metricLabels];
        metricValue.innerText = formattedMetricValue;
      }
    } catch (error) {
      dispatchToastError(error, 'User Metrics');
    }
  }

  /**
   * View Render functions and helpers
   */
  function renderDropdownOptions() {
    let entityDropdown = {
      contextMenuItemsType: [], //IPopupMenuItem[];
      popupMenuConfigType: {
        showMenu,
        setShowMenu,
        position: { type: 'bottom' } as IPopupPosition,
      }, //IShowPopupConfig;,
      options: getResults().map((e) => {
        const popupItem: IPopupMenuItem = {
          id: e.id || '',
          label: e.title || '',
          iconImg: e.profile_image_url,
          isSelected: e.id === entity.id,
          onClick: () => setEntity(e as IStoryEntityItem),
        };

        return popupItem;
      }),
      showMenuClassType: false,
      isSearchable: false,
      value: entity.title,
      icon: entity.profile_image_url, // where icon is actually imgSource
      name: 'test',
      label: '',
      required: false,
      disabled: false,
      placeholder: 'Select A Group',
      shouldSort: true,
      onClick: () => {
        setShowMenu(!showMenu);
      },
      containerWrapClassNames: 'avatar-dropdown',
      popupMenuClass: 'avatar-menu-dropdown',
    };

    let eventsDropdown = {
      contextMenuItemsType: [], //IPopupMenuItem[];
      popupMenuConfigType: {
        showMenu: showEventsMenu,
        setShowMenu: setShowEventsMenu,
        position: { type: 'bottom' } as IPopupPosition,
      }, //IShowPopupConfig;,
      options: events.map((e) => {
        const popupItem: IPopupMenuItem = {
          id: e.id || '',
          label: e.title || '',
          iconImg: e.profile_image_url,
          isSelected: e.id === event?.id,
          onClick: () => setEvent(e),
        };

        return popupItem;
      }),
      showMenuClassType: false,
      isSearchable: false,
      value: event?.title || '',
      icon: event?.profile_image_url, // where icon is actually imgSource
      name: 'test',
      label: '',
      required: false,
      disabled: false,
      placeholder: 'Select an Event',
      shouldSort: true,
      onClick: () => {
        setShowEventsMenu(!showEventsMenu);
      },
      containerWrapClassNames: 'avatar-dropdown',
      popupMenuClass: 'avatar-menu-dropdown',
    };

    return { entityOptions: entityDropdown, eventOptions: eventsDropdown };
  }
  function renderStep() {
    switch (currentStep) {
      case 'selection': {
        return renderSelection();
      }
      case 'entitySelection': {
        return renderEntitySelection();
      }
      case 'build': {
        return renderBuildStory();
      }
      default:
        break;
    }
  }

  function renderSelection() {
    return (
      <div className="selection">
        <div className="selections">
          {storyTypes.map((type) => {
            return (
              <div
                onClick={() => {
                  setSelectedEntity(type.type);
                  goToStep(type.step);
                }}
                className={`story-select-card ${selectedEntity === type.type ? 'selected' : ''}`}
              >
                <div
                  className={`image-section ${type.label === 'Company' ? 'story-hub-selection' : type.label}`}
                >
                  <div className="overlay"></div>
                  <img src={addStory}></img>
                </div>
                <p className="type">{type.label}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function onSearch(value: string) {
    setSearchValue(value);
  }

  function getResults(): Array<IGroup | IHub> {
    return selectedEntity === 'cause' ? userGroups : userHubs;
  }

  function renderEntitySelection() {
    return (
      <div className="entity-selection">
        <div className="search-input-container">
          <TextField
            type="text"
            icon="fa fa-search"
            placeholder={
              objectType === uiConstants.ownerType.company
                ? 'Search for a Company'
                : 'Search for a Charity or Non-Profit'
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearch(e.target.value)}
            name="searchValue"
            value={searchValue}
          />
          <div>{/* dropdown goes here */}</div>
        </div>
        <div className="results">
          {getResults().map((listItem) => {
            return (
              <CheckboxCard
                title={listItem?.title || ''}
                subtext={`@${listItem.handle}`}
                id={listItem.id || ''}
                showCheckbox={true}
                checked={listItem.id === entity.id}
                onChange={(e) => {
                  const selected = getResults().find((listItem) => e === listItem.id);
                  setEntity(selected as IStoryEntityItem);
                }}
                imageOptions={{ imageSource: listItem.profile_image_url || '' }}
              />
            );
          })}
        </div>
      </div>
    );
  }

  function getDrawerTitle() {
    switch (editMode) {
      case 'background': {
        return 'Add Background';
      }
      case 'text': {
        return 'Add Text';
      }
      case 'metric': {
        return 'Add Metrics';
      }
      case 'cta': {
        return 'Add Call To Action';
      }
      case '':
      default:
        if (selectedEntity !== 'user') {
          return null;
        } else {
          return (
            <>
              <Portrait
                currentImage={user.profile_image_url}
                size={40}
              />
              {`${user.display_name}`}
            </>
          );
        }
        break;
    }
  }

  function handleMedia(fileData: { src: string; type?: string; name?: string; size?: string }) {
    const setFileData = () => {
      if (fileData?.name && fileData?.size) {
        setFileName(fileData.name);
        setFileSize(fileData.size);
      }
    };
    if (fileData?.type === 'video') {
      setVideoSource(fileData.src);
      setFileData();
      setImageSource('');

      return;
    }

    setImageSource(fileData.src);
    setFileData();
    setShowCrop(true);
  }

  function convertMetricLabel(metric: string): string {
    return capitalizeString(metric.split('_').join(' ')) || '';
  }

  function getDrawerBody() {
    switch (editMode) {
      case 'background': {
        return (
          <div className="add-background">
            <form>
              <div className="upload-media-container">
                <ImageUpload
                  label="Upload Media"
                  onImgSelect={(fileData) => handleMedia(fileData)}
                />
                {/* TODO CLEAN THIS UP DUPLICATION */}
                {croppedImageSource && (
                  <div className="media-preview">
                    <div className="file-info">
                      <div className="name">{fileName}</div>
                      <div className="size">{`${fileSize} MB`}</div>
                    </div>
                    <div className="action">
                      <i
                        className="fa fa-trash"
                        onClick={() => {
                          setCroppedImageSource('');
                          setTemporaryCropped('');
                        }}
                      />
                    </div>
                  </div>
                )}

                {videoSource && (
                  <div className="media-preview">
                    <div className="file-info">
                      <div className="name">{fileName}</div>
                      <div className="size">{`${fileSize} MB`}</div>
                    </div>
                    <div className="action">
                      <i
                        className="fa fa-trash"
                        onClick={() => {
                          setVideoSource('');
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {renderColorPicker()}
              <div className="actions">
                <Button
                  className="transitions"
                  type="button"
                  text={'Cancel'}
                  onClick={() => {
                    onCancelAddBackground();
                  }}
                />
                <Button
                  className="transitions"
                  type="button"
                  text={'Save'}
                  onClick={() => {
                    onSaveAddBackground();
                  }}
                />
              </div>
            </form>
          </div>
        );
      }
      case 'text': {
        return (
          <div className="add-text">
            <form>
              <div className="row">
                <Dropdown
                  placeholder="Select a Font Size"
                  options={fontSizeOptions}
                  shouldSort={false}
                  value={fontSize}
                  label="Text Size"
                  name="textSize"
                  onChange={(e) => setFontSize(e.target.value)}
                />
              </div>
              {renderColorPicker('text')}
              <div className="actions">
                <Button
                  className="transitions"
                  type="button"
                  text={'Cancel'}
                  onClick={() => {
                    onCancelAddText();
                  }}
                />
                <Button
                  className="transitions"
                  type="button"
                  text={'Save'}
                  onClick={() => {
                    onSaveAddText();
                  }}
                />
              </div>
            </form>
          </div>
        );
      }
      case 'metric': {
        return (
          <div className="add-metric">
            <form>
              <div className="row">
                <Dropdown
                  placeholder="Select Metric"
                  options={metrics.map((metric) => {
                    return { label: convertMetricLabel(metric.metric), value: metric.metric };
                  })}
                  shouldSort={false}
                  value={selectedMetric}
                  label="Metric"
                  name="metric"
                  onChange={(e) => setSelectedMetric(e.target.value)}
                />
              </div>
              <div className="row">
                <Dropdown
                  placeholder="Select a Font Size"
                  options={fontSizeOptions}
                  shouldSort={false}
                  value={fontSize}
                  label="Text Size"
                  name="textSize"
                  onChange={(e) => setFontSize(e.target.value)}
                />
              </div>
              {renderColorPicker('text')}
              <div className="actions">
                <Button
                  className="transitions"
                  type="button"
                  text={'Cancel'}
                  onClick={() => {
                    onCancelAddMetric();
                  }}
                />
                <Button
                  className="transitions"
                  type="button"
                  text={'Save'}
                  onClick={() => {
                    onSaveAddMetric();
                  }}
                />
              </div>
            </form>
          </div>
        );
      }
      case 'cta': {
        return (
          <div className="add-cta">
            <form>
              <div className="row">
                <Dropdown
                  placeholder="Select an Action"
                  options={CallToActionOptions}
                  shouldSort={false}
                  value={callToAction}
                  label="Action"
                  name="action"
                  onChange={(e) => setCallToAction(e.target.value)}
                />
              </div>
              <div className="row">
                <TextField
                  placeholder={'Add Button Text'}
                  value={temporaryCallToActionText}
                  name="temporaryCallToActionText"
                  label={'Button Text'}
                  type="text"
                  onChange={(e) => {
                    setTemporaryCallToActionText(e.target.value);
                  }}
                />
              </div>
              {renderColorPicker('button')}
              <div className="actions">
                <Button
                  className="transitions"
                  type="button"
                  text={'Cancel'}
                  onClick={() => {
                    onCancelCallToAction();
                  }}
                />
                <Button
                  className="transitions"
                  type="button"
                  text={'Save'}
                  onClick={() => {
                    onSaveCallToAction();
                  }}
                />
              </div>
            </form>
          </div>
        );
      }
      case '':
      default:
        return (
          <ul>
            {storyActions.map((storyAction) => {
              // TODO: temporary CTA removal until some fixes
              if (storyAction.id === 'cta') return;
              return (
                <li
                  onClick={() => {
                    setEditMode(storyAction.id);
                  }}
                >
                  {' '}
                  {storyAction.icon} {storyAction.text}
                </li>
              );
            })}
          </ul>
        );
        break;
    }
  }

  function renderBuildStory() {
    const dropdownOptions = renderDropdownOptions();
    const isUser = objectType === 'user';
    return (
      <div className="build-story">
        {isMobileScreen() && (
          <div className="story-actions-mobile">
            <ul>
              {storyActions.map((storyAction) => {
                if (storyAction.id === 'cta' && selectedEntity === 'user') return;
                return (
                  <li
                    onClick={() => {
                      setEditMode(storyAction.id);
                      setShowMobileDrawer(!setShowMobileDrawer);
                    }}
                  >
                    {' '}
                    {storyAction.icon}{' '}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {isMobileScreen() && editMode !== '' && !showCrop && (
          <div className="story-mobile-drawer">
            <div className="notch"></div>
            <div className="drawer-title">{getDrawerTitle()}</div>
            {getDrawerBody()}
          </div>
        )}
        <div className="story-drawer">
          {(editMode || (!editMode && isUser)) && (
            <div className="drawer-title">{getDrawerTitle()}</div>
          )}
          {!editMode && !isUser && <CustomDropdown {...dropdownOptions.entityOptions} />}
          {!editMode && !isUser && <CustomDropdown {...dropdownOptions.eventOptions} />}
          {getDrawerBody()}
        </div>
        <div className="story-edit-area">
          {renderStoryCard()}
          {showCrop && renderCropContainer()}
        </div>
      </div>
    );
  }

  // Draggable Item Renders and Actions
  const onRemoveDraggable = (element: any, type: string, index: number) => {
    if (type === 'text') {
      const updatedTextElements = textElements.filter((el, idx) => idx !== index);
      setTextElements([...updatedTextElements]);
    } else {
      let currentElements = [...metricElements];
      currentElements.splice(index, 1);
      const updatedMetricElements = currentElements;
      setMetricElements(updatedMetricElements);
    }
  };
  function renderDraggableActions(type: string, index: number) {
    return (
      <>
        <div className="action drag-handle">
          <i className="fa fa-arrows-alt"></i>
        </div>
        <div
          onClick={(e) => onRemoveDraggable(e, type, index)}
          className="action delete"
        >
          <i className="fa fa-trash"></i>
        </div>
      </>
    );
  }
  function getTextBox(index: number) {
    return (
      <div
        data-index={index}
        className="textbox is-draggable"
        ref={(el) => {
          if (el) return (textBoxRefs.current[index] = el);
        }}
      >
        <div
          className="text-inner"
          contentEditable
        >
          <p>Start Typing Here...</p>
        </div>
        {renderDraggableActions('text', index)}
      </div>
    );
  }
  function getMetricBox(metric: { label: string; value: string }) {
    const index = metricElements.length;
    return (
      <div
        className="metric-box is-draggable"
        ref={(el) => (metricBoxRefs.current[index] = el)}
      >
        <div className="text-inner">
          <p className="metric-value">{metric.value}</p>
          <p className="metric-label">{metric.label}</p>
        </div>
        {renderDraggableActions('metric', index)}
      </div>
    );
  }
  function getCallToActionButton() {
    const onRemoveButton = () => {
      setCallToActionButton(undefined);
    };

    return (
      <div className="call-to-action-container is-draggable">
        <button
          ref={callToActionButtonRef}
          style={{ backgroundColor: buttonColor }}
          className={`cta-btn ${callToActionText}`}
          notranslate="yes"
        >
          {temporaryCallToActionText || 'Button Text Here'}
        </button>
        <div className="action drag-handle">
          <i className="fa fa-arrows-alt"></i>
        </div>
        <div
          onClick={() => onRemoveButton()}
          className="action delete"
        >
          <i className="fa fa-trash"></i>
        </div>
      </div>
    );
  }
  // end draggable item renders

  function openPhotoCrop(e: any) {
    if (videoSource || !croppedImageSource || !e.target?.className?.includes('story-card')) return;
    setEditMode('background');
    setTemporaryCropped(croppedImageSource);
    setShowCrop(true);
  }

  function renderStoryCard() {
    const getViewTemplate = (isEditable: boolean) => {
      if (isEditable) {
        if (croppedImageSource) {
          return (
            <div
              onClick={(e) => openPhotoCrop(e)}
              className="story-card editable"
              style={{
                backgroundColor: temporaryBackgroundColor,
                backgroundImage: `url(${croppedImageSource})`,
                backgroundSize: `auto 100%`,
              }}
              ref={storyCardRef}
            >
              <div className="fg-content">
                {textElements.map((element, index) => getTextBox(index))}
              </div>
              <div className="fg-content">{metricElements.map((element) => element)}</div>
              {videoSource && (
                <video
                  loop
                  controls
                  ref={videoPlayerRef}
                >
                  <source
                    src={videoSource}
                    type="video/mp4"
                  ></source>
                </video>
              )}
              {callToActionButton}
            </div>
          );
        } else {
          return (
            <div
              className="story-card editable"
              style={{ backgroundColor: temporaryBackgroundColor }}
              ref={storyCardRef}
            >
              <div className="fg-content">
                {textElements.map((element, index) => getTextBox(index))}
              </div>
              <div className="fg-content">{metricElements.map((element) => element)}</div>
              {videoSource && (
                <video
                  loop
                  controls
                  ref={videoPlayerRef}
                >
                  <source
                    src={videoSource}
                    type="video/mp4"
                  ></source>
                </video>
              )}
              {callToActionButton}
            </div>
          );
        }
      } else {
        return (
          <div
            onClick={(e) => openPhotoCrop(e)}
            className="story-card"
            style={{
              backgroundColor: selectedBackgroundColor,
              backgroundImage: `url(${croppedImageSource})`,
              backgroundSize: `auto 100%`,
            }}
            ref={storyCardRef}
          >
            <div className="fg-content">
              {textElements.map((element, index) => getTextBox(index))}
            </div>
            <div className="fg-content">{metricElements.map((element) => element)}</div>
            {videoSource && (
              <video
                loop
                controls
                ref={videoPlayerRef}
              >
                <source
                  src={videoSource}
                  type="video/mp4"
                ></source>
              </video>
            )}
            {callToActionButton}
          </div>
        );
      }
    };
    return editMode ? getViewTemplate(true) : getViewTemplate(false);
  }

  function renderCropContainer() {
    return (
      <div className="crop-container">
        <Cropper
          video={videoSource}
          image={imageSource}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={2 / 3}
          objectFit="vertical-cover"
          cropSize={{ width: 288, height: 500 }}
          cropShape={'rect'}
          showGrid={false}
          onCropChange={handleCropChange}
          onCropComplete={handleCropComplete}
          onZoomChange={setZoom}
        />
        <div className="zoom-container">
          <input
            type="range"
            value={zoom}
            min={1}
            max={4}
            step={1}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              setZoom(parseInt(e.target.value));
            }}
          />
          <input
            type="range"
            value={rotation}
            min={0}
            max={360}
            step={1}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRotation(parseInt(e.target.value));
            }}
          />
          <Button
            text="Save"
            onClick={() => onSaveAddBackground()}
          ></Button>
        </div>
      </div>
    );
  }

  function goToStep(step: string) {
    setCurrentStep(step);
  }

  function handleCropChange(location: Point) {
    setCrop(location);
  }

  async function handleCropComplete(croppedArea: Area, croppedAreaPixels: Area) {
    let croppedImg = await getCroppedImg(imageSource, croppedAreaPixels, rotation);
    setTemporaryCropped(croppedImg || '');
  }

  function renderHeading() {
    const defaultMessage = 'Select what type of story you would like to create';
    const getViewTemplate = (type?: string) => {
      if (type === 'user') {
        return (
          <div className={`heading ${type ? type : ''}`}>
            <h1>Create a Story</h1>
            <p>{defaultMessage}</p>
          </div>
        );
      } else {
        return (
          <div className={`heading ${type}`}>
            <h1>Create a Story</h1>
            <p>{type ? `Select a ${type} for your story.` : defaultMessage}</p>
          </div>
        );
      }
    };

    return selectedEntity ? getViewTemplate(selectedEntity) : getViewTemplate();
  }

  function renderDiscardButton() {
    return (
      <Button
        onClick={() => setShowDiscardModal(true)}
        buttonType="outline-dark"
        className="transitions discard"
        text="Discard"
      />
    );
  }

  function renderDiscardModal() {
    return (
      <Modal
        onClose={() => setShowDiscardModal(false)}
        show={showDiscardModal}
        class="discard-modal-container"
        contentClassName="discard-modal"
      >
        <h1>Discard Story?</h1>
        <p>Are you sure you want to discard this story? This cannot be undone.</p>
        <div className="actions">
          <Button
            onClick={() => setShowDiscardModal(false)}
            text="Cancel"
          />
          <Button
            onClick={() => history.goBack()}
            text="Discard story"
          />
        </div>
      </Modal>
    );
  }

  /**
   * Abstraction for setting colors
   * @param label
   * @param color
   * @param setColor
   */
  function getColorPickerTemplate(
    label: string,
    color: string,
    setColor: React.Dispatch<React.SetStateAction<string>>,
  ) {
    return (
      <div className="custom-color-picker">
        <div className="color-container">
          <label>{label}</label>
          <div
            className="color"
            onClick={() => {
              setShowColorPicker(!showColorPicker);
            }}
          >
            <div
              className="inner"
              style={{ backgroundColor: color }}
            />
          </div>
        </div>
        {showColorPicker && (
          <SketchPicker
            className="custom-picker-container"
            color={fontColor}
            onChange={(colorChangeHandler) => {
              setColor(colorChangeHandler.hex);
            }}
            onChangeComplete={(colorChangeHandler) => {
              setColor(colorChangeHandler.hex);
            }}
          />
        )}
      </div>
    );
  }

  function renderColorPicker(type?: string) {
    if (type === 'text') {
      return getColorPickerTemplate('Text Color', fontColor, setFontColor);
    } else if (type === 'button') {
      return getColorPickerTemplate('Button Color', buttonColor, setButtonColor);
    } else {
      return getColorPickerTemplate(
        'Background Color',
        temporaryBackgroundColor,
        setTemporaryBackgroundColor,
      );
    }
  }

  /**
   * Story Drawer Save and Cancel Actions
   *
   * Add Background Actions
   */
  function onCancelAddBackground() {
    setSelectedBackgroundColor(selectedBackgroundColor);
    setCroppedImageSource(croppedImageSource);

    setEditMode('');
    setShowCrop(false);
  }

  function onSaveAddBackground() {
    setSelectedBackgroundColor(temporaryBackgroundColor);
    setCroppedImageSource(temporaryCropped);

    setEditMode('');
    setShowCrop(false);
  }

  /**
   * Text Actions
   */
  function onSaveAddText() {
    setEditMode('');
  }

  function onCancelAddText() {
    if (textElements.length) {
      let currentElements = [...textElements];
      // Remove the most recently added text
      currentElements.splice(currentElements.length - 1, 1);
      const updatedTextElements = currentElements;
      setTextElements(updatedTextElements);
    }
    setEditMode('');
  }

  /**
   * Call To Action...actions
   */
  function onSaveCallToAction() {
    setEditMode('');
    setCallToAction(temporaryCallToAction);
  }

  function onCancelCallToAction() {
    // Should reset to empty string or the value before temp value
    setCallToAction(callToAction);
    // Should reset to undefined or the value before the temp value - no longer true since we add one on toggle to cta
    setCallToActionButton(callToActionButton);
    // Should reset to default button text if they haven't yet changed it
    setTemporaryCallToActionText(callToActionText);
    // Should reset to default string if they haven't yet changed it
    setTemporaryCallToAction('');

    // Toggle Edit mode
    setEditMode('');
  }

  /**
   * Metrics Actions
   */
  function onSaveAddMetric() {
    setEditMode('');
  }

  function onCancelAddMetric() {
    if (metricElements.length) {
      let currentElements = [...metricElements];
      currentElements.splice(metricElements.length - 1, 1);
      const updatedMetricElements = currentElements;
      setMetricElements(updatedMetricElements);
    }
    setEditMode('');
  }
  // end save and cancel
  // payload helpers
  function generateCallToActionPayload() {
    if (!callToActionButtonRef?.current) {
      return null;
    } else {
      const storyHeight = storyCardRef?.current?.offsetHeight || 480;
      const storyWidth = storyCardRef?.current.offsetWidth || 260;
      const cta = callToActionButtonRef.current;
      const element = cta?.parentNode;
      const xPercentage = (parseFloat(element.getAttribute('data-x') || 10) / storyWidth) * 100;
      const yPercentage = (parseFloat(element.getAttribute('data-y') || 10) / storyHeight) * 100;
      const ctaForegroundContent: IStoryForegroundContent = {
        foreground_type: foregroundTypes.cta,
        text: temporaryCallToActionText,
        style: {
          foreground_color: cta.style.backgroundColor,
          font_size: '14px',
          position_x: xPercentage.toString(),
          position_y: yPercentage.toString(),
        },
      };

      return ctaForegroundContent;
    }
  }
  function generateTextboxPayload() {
    const storyHeight = storyCardRef?.current?.offsetHeight || 480;
    const storyWidth = storyCardRef?.current.offsetWidth || 260;
    const content = textBoxRefs?.current
      .filter((val: any) => Boolean(val))
      .map((element: any) => {
        const xPercentage = (parseFloat(element.getAttribute('data-x') || 10) / storyWidth) * 100;
        const yPercentage = (parseFloat(element.getAttribute('data-y') || 10) / storyHeight) * 100;
        let foregroundContent: IStoryForegroundContent = {
          foreground_type: foregroundTypes.text,
          //HACK: Pass HTML directly to story with proper formatting
          text: element.innerHTML
            .replace(
              '<div class="action drag-handle"><i class="fa fa-arrows-alt" aria-hidden="true"></i></div><div class="action delete"><i class="fa fa-trash" aria-hidden="true"></i></div>',
              '',
            )
            .replace('contenteditable="true"', ''),
          style: {
            foreground_color: element.style.color || '#fff',
            font_size: element.style.fontSize || '14px',
            position_x: xPercentage.toString(),
            position_y: yPercentage.toString(),
          },
        };

        return foregroundContent;
      });

    return content;
  }
  function generateMetricboxPayload() {
    const storyHeight = storyCardRef?.current?.offsetHeight || 480;
    const storyWidth = storyCardRef?.current.offsetWidth || 260;
    const content = metricBoxRefs?.current
      .filter((val: any) => Boolean(val))
      .map((element: any) => {
        const xPercentage = (parseFloat(element.getAttribute('data-x') || 10) / storyWidth) * 100;
        const yPercentage = (parseFloat(element.getAttribute('data-y') || 10) / storyHeight) * 100;
        let foregroundContent: IStoryForegroundContent = {
          foreground_type: foregroundTypes.metric,
          metric: element.getAttribute('data-metric-value'),
          style: {
            foreground_color: element.style.color || '#fff',
            font_size: element.style.fontSize || '14px',
            position_x: xPercentage.toString(),
            position_y: yPercentage.toString(),
          },
        };

        return foregroundContent;
      });

    return content;
  }
  // Create Story
  async function onCreateStory() {
    setIsLoading(true);
    const backgroundType = imageSource
      ? backgroundTypes.image
      : videoSource
        ? backgroundTypes.video
        : backgroundTypes.color;
    const ctaForegroundContent = generateCallToActionPayload();
    const textboxForegroundContent = generateTextboxPayload();
    const metricBoxForegroundContent = generateMetricboxPayload();

    let foregroundContents = [];
    if (ctaForegroundContent) {
      foregroundContents.push(ctaForegroundContent);
    }
    if (textboxForegroundContent.length > 0) {
      textboxForegroundContent.forEach((FG: IStoryForegroundContent) => {
        foregroundContents.push(FG);
      });
    }
    if (metricBoxForegroundContent.length > 0) {
      metricBoxForegroundContent.forEach((FG: IStoryForegroundContent) => {
        foregroundContents.push(FG);
      });
    }

    try {
      let media_url = '';
      let url: string =
        backgroundType === backgroundTypes.image
          ? imageSource
          : backgroundType === backgroundTypes.video
            ? videoSource
            : '';
      if (backgroundType === backgroundTypes.video) {
        url = url.replace('blob:', '');
      }

      if (url) {
        media_url = await uploadImageToStore(url);
      }
      let payload: IStoryCreateParamsFE = {
        owner_type: objectType === 'company' ? 'hub' : objectType,
        owner_id: (objectType === 'user' ? user.id : entity.id) || '',
        foreground_contents: foregroundContents,
        background_type: backgroundType,
        media_url: media_url,
        background_color: selectedBackgroundColor,
      };
      await storyRequestActions.createStory(payload);
      dispatchToastSuccess('Story created.', 'Create Story');
      history.goBack();
    } catch (error) {
      dispatchToastError(error, 'Create Story');
    } finally {
      setIsLoading(false);
    }
  }

  function isDisabled() {
    switch (currentStep) {
      case 'entitySelection': {
        if (
          (selectedEntity === uiConstants.ownerType.company || selectedEntity === 'cause') &&
          entity.id
        ) {
          return false;
        } else {
          return true;
        }
      }
      case 'build': {
        return false;
      }
      default:
        return false;
        break;
    }
  }

  if (!isLoggedIn) {
    return <Redirect to={'/login?redirect=/story/create'} />;
  } else {
    return (
      <div className="CreateStory">
        <FormTopBar
          logoVariant="dark"
          onLogoClick={() => setShowDiscardModal(true)}
          onClose={() => setShowDiscardModal(true)}
        />
        {currentStep !== 'build' && renderHeading()}
        {renderStep()}
        {currentStep !== 'selection' && (!isMobileScreen() || (isMobileScreen() && !showCrop)) && (
          <FormBottomBar
            nextBtnTxt={currentStep !== 'build' ? 'Next' : 'Add to Story'}
            isDisabled={isLoading || isDisabled()}
            showBack={currentStep === 'entitySelection'}
            loading={isLoading}
            onBack={() => goToStep('selection')}
            onNext={() => {
              if (currentStep === 'build') {
                onCreateStory();
              } else {
                goToStep('build');
              }
            }}
            optionalButton={currentStep === 'build' ? renderDiscardButton() : null}
          />
        )}
        {showDiscardModal && renderDiscardModal()}
      </div>
    );
  }
};

export default CreateStory;
