import './HubVolunteering.scss';
import React, { useEffect, useState } from 'react';
import {
  IAddress,
  IApplication,
  ICause,
  IGigSummary,
  IGigSummaryFE,
  IHub,
} from '@gigit/interfaces';
import HubFilter from '../Shared/HubFilterBar/HubFilterBar';
import { IPopupMenuItem, IShowPopupConfig } from '../../shared/PopupMenu/PopupMenu';
import { uiConstants } from '../../../constants';
import useToastDispatcher from '../../../hooks/useToaster';
import Button from '../../Button/Button';
import HubVolunteerEmptyState from './HubVolunteerEmptyState/HubVolunteerEmptyState';
import {
  causeRequestActions,
  gigRequestActions,
  hubRequestActions,
  userRequestActions,
} from '../../../requestActions';
import { formatQuery, generateEmptyCards, getAddressParts, typeHelpers } from '../../../helpers';
import CausesFilter from '../Shared/CausesFilter/CausesFilter';
import HubVolunteerModal from '../HubModals/HubVolunteerModal/HubVolunteerModal';
import CardLoader from '../../shared/CardLoader/CardLoader';
import { ECardLoaderStyleType } from '../../shared/CardLoader/CardLoaderItem/CardLoaderItem';
import { Constants } from '@gigit/constants';

import VolunteerCard, {
  EStyleTypeVolunteerCard,
  EViewTypeVolunteerCard,
} from '../../VolunteerCard/VolunteerCard';
import HubToggleButtons from '../Shared/HubToggleButtons/HubToggleButtons';
import { IThemeStyleObjectsByType } from '../Hub';
import AddToCalendarModal from '../../shared/AddToCalendarModal/AddToCalendarModal';
import { userSelectors } from '../../../selectors/user';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { IActiveFilter } from '../../../interfaces';

interface IProps {
  hub: IHub | null;
  adminMode: boolean;
  userHasEditPermissions: boolean;
  isUserLoggedIn: boolean;
  theme: IThemeStyleObjectsByType;
}

type TabType = 'company' | 'partner';

const HubVolunteering: React.FC<IProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<'start_date' | 'title'>('start_date');
  const [sortByLabel, setSortByLabel] = useState<string>('Start Date');
  const [page, setPage] = useState<number>(0);
  const [showContextMenuSortBy, setShowContextMenuSortBy] = useState<boolean>(false);
  const [volunteerItems, setVolunteerItems] = useState<IGigSummaryFE[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [userRole, setUserRole] = useState<string>('');
  const [partnerCauses, setPartnerCauses] = useState<string[]>([]);
  const [causesMaster, setCausesMaster] = useState<ICause[]>([]);
  const [showOpportunityModal, setShowOpportunityModal] = useState<boolean>(false);
  const [currentVolunteerDetails, setCurrentVolunteerDetails] = useState<IGigSummaryFE | null>(
    null,
  );
  const allowedRoles = [uiConstants.role.admin, uiConstants.role.member];
  const [showAddToCalendarModal, setShowAddToCalendarModal] = useState<boolean>(false);
  const [activeGig, setActiveGig] = useState<IGigSummaryFE | null>(null);
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));
  const [userApplications, setUserApplications] = useState<IApplication[]>([]);
  const [type, setType] = useState<TabType>(
    allowedRoles.includes(userRole) ? 'company' : 'partner',
  );

  const { dispatchToastError } = useToastDispatcher();

  async function getHubPartnersCauses() {
    try {
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      const result = await hubRequestActions.getHubPartnersCauses(props.hub.id);
      setPartnerCauses(result.map((causeObj) => causeObj.cause));
    } catch (error) {
      dispatchToastError(error, 'Get Company Partners Focus Areas');
    }
  }

  async function getHubVolunteerItems() {
    try {
      setIsLoading(true);
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      let filters: IActiveFilter[] = [
        {
          label: 'status',
          value: Constants.gig_status.published,
          id: 'status.code',
          type: 'text',
        },
      ];

      if (searchValue !== null && searchValue !== '') {
        filters.push({
          label: 'title',
          value: searchValue,
          id: 'title',
          type: 'text',
        });
      }

      const query = formatQuery({
        limit: uiConstants.hubs.listViewPageSize,
        skip: String(page * +uiConstants.hubs.listViewPageSize),
        common_filters: {
          causes:
            causesMaster
              .filter((causeM) => selectedTags.includes(causeM.cause))
              .map((c) => c.id || '') || '',
        },
        filters,
        sort: [{ id: sortBy, order: 'desc' }],
      });

      const res =
        type === 'company'
          ? await hubRequestActions.getVolunteerOpportunities(props.hub.id, query)
          : await hubRequestActions.getGigsFromHubPartnerGroups(props.hub.id, query);

      if (page === 0) {
        setVolunteerItems(res);
      } else {
        setVolunteerItems((volunteerItems) => [...volunteerItems, ...res]);
      }
    } catch (error) {
      dispatchToastError(error, 'Get Volunteer Opportunities');
    } finally {
      setIsLoading(false);
    }
  }

  async function getUserVolunteerApplications() {
    if (userState) {
      let params = {
        sortDir: 'desc' as const,
        sortBy: 'created_at',
        type: 'all',
        states: 'all',
      };

      try {
        const results = await userRequestActions.getUserApplications(params);
        setUserApplications(results);
      } catch (error) {
        dispatchToastError(error, 'Get User Volunteer Applications');
      }
    }
  }

  async function getCauses() {
    try {
      const result = await causeRequestActions.getCauses();
      setCausesMaster(result);
    } catch (error) {
      dispatchToastError(error, 'Get Focus Areas');
    }
  }

  function loadMoreItems() {
    setPage(page + 1);
  }

  const popupMenuConfigSortBy: IShowPopupConfig = {
    showMenu: showContextMenuSortBy,
    setShowMenu: setShowContextMenuSortBy,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItemsSortBy: IPopupMenuItem[] = [
    {
      id: uiConstants.sortOptions.createdAtAscending,
      label: 'Start Date',
      isSelected: sortBy === 'start_date',
      onClick: () => {
        setSortBy('start_date');
        setSortByLabel('Start Date');
      },
    },
    {
      id: uiConstants.sortOptions.createdAtDescending,
      label: 'Name',
      isSelected: sortBy === 'title',
      onClick: () => {
        setSortBy('title');
        setSortByLabel('Name');
      },
    },
  ];

  async function handleOnUpdate() {
    await getHubVolunteerItems();
    const query = formatQuery({
      limit: uiConstants.hubs.listViewPageSize,
      skip: String(page * +uiConstants.hubs.listViewPageSize),
      common_filters: {
        causes:
          causesMaster
            .filter((causeM) => selectedTags.includes(causeM.cause))
            .map((c) => c.id || '') || '',
      },
      filters:
        searchValue !== null && searchValue !== ''
          ? [
              {
                label: 'title',
                value: searchValue,
                id: 'title',
                type: 'text',
              },
            ]
          : undefined,
      sort: [{ id: sortBy, order: 'desc' }],
    });

    try {
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      const volunteerItemsList =
        type === uiConstants.ownerType.company
          ? await hubRequestActions.getVolunteerOpportunities(props.hub.id || '', query)
          : await hubRequestActions.getGigsFromHubPartnerGroups(props.hub.id || '', query);

      const volunteer =
        volunteerItemsList.find((gig) => gig.id === currentVolunteerDetails?.id || '') || null;
      setCurrentVolunteerDetails(volunteer);
    } catch (error) {
      dispatchToastError(error, 'Get Company Partners Focus Areas');
    }
  }

  const handleDeleteOpportunity = async () => {
    try {
      await gigRequestActions.changeGigStatus(
        currentVolunteerDetails?.id || '',
        Constants.group_status.archived,
      );
      await getHubVolunteerItems();
      setShowOpportunityModal(false);
    } catch (error) {
      dispatchToastError(error, 'Delete Volunteer Opportunity');
    }
  };

  async function getHubRoles() {
    const userRoleName = await userRequestActions.getUserHubRole(props.hub?.id || '');
    setUserRole(userRoleName.role_name);
  }

  useEffect(() => {
    getHubRoles();
    getCauses();
    getHubPartnersCauses();
    getUserVolunteerApplications();
  }, []);

  useEffect(() => {
    setPage(0);
    getHubVolunteerItems();
  }, [sortBy, selectedTags, type, searchValue]);

  return (
    <div className="HubVolunteering">
      <HubFilter
        header="Volunteer Opportunities"
        searchValue={searchValue || ''}
        onSearch={setSearchValue}
        searchPlaceholder="Search opportunities by name"
        sortByConfig={[
          {
            menuItems: contextMenuItemsSortBy,
            showMenuConfig: popupMenuConfigSortBy,
            className: 'sortBy',
            label: 'Sort By',
            valueLabel: sortByLabel,
            popupMenuClass: 'HubVolunteering-popupMenu-sortBy',
            showMenu: showContextMenuSortBy,
            onClick: () => {
              setShowContextMenuSortBy(true);
            },
            onSelect: () => {
              setShowContextMenuSortBy(false);
            },
          },
        ]}
      />

      <CausesFilter
        causes={partnerCauses}
        selectedCauses={selectedTags}
        causesMaster={causesMaster}
        onSelectCause={(v) => setSelectedTags(v)}
      />

      <hr />

      <HubToggleButtons
        activeStyle={props.theme.secondaryBgWithText}
        buttons={[
          {
            text: 'Cause Posting',
            active: 'partner' === type,
            onClick: () => {
              setType('partner');
            },
          },
          ...(allowedRoles.includes(userRole) || props.hub?.public_access_to_events_and_gigs
            ? [
                {
                  text: 'Company Posting',
                  active: 'company' === type,
                  onClick: () => {
                    setType('company');
                  },
                },
              ]
            : []),
        ]}
      />

      <div className="result-count">
        <b>{volunteerItems.length}</b>
        {` Search Results for `}
        {selectedTags.map((tag, index) => (
          <span
            key={index}
            className="tag"
          >
            {`${tag}${index < selectedTags.length - 1 ? ', ' : ''}`}
          </span>
        ))}
      </div>

      {!isLoading && volunteerItems.length > 0 && (
        <>
          <div className="list-container">
            {volunteerItems.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {index <= +uiConstants.hubs.listViewPageSize * (page || 1) && (
                    <VolunteerCard
                      key={index}
                      gigBasic={{
                        id: item.id || '',
                        profile_image_url: item.profile_image_url,
                        title: item.title,
                        description: item.description,
                        locations: item.locations as IAddress[],
                        start_date: item?.start_date?.toString() || '',
                        owner_title: item?.event
                          ? item.event.title
                          : item?.hub
                            ? item.hub.title || ''
                            : item?.group_title || item?.group?.title || '',
                        owner_type: item?.event
                          ? uiConstants.ownerType.event
                          : item?.hub
                            ? uiConstants.ownerType.company
                            : uiConstants.ownerType.group,
                        owner_handle: item?.event
                          ? item.event.handle
                          : item?.hub
                            ? item?.hub?.handle || ''
                            : item?.group_handle || item?.group?.handle || '',
                        handle: item.handle || '',
                        is_volunteer_match: !!item?.external_id?.VolunteerMatch,
                        is_virtual: item.is_virtual,
                        accepting_external_application: item.accepting_external_application,
                        external_application_url: item.external_application_url,
                      }}
                      openCalendarModal={(gig_id) => {
                        setActiveGig(volunteerItems.find((gig) => gig.id === gig_id) || null);
                        setShowAddToCalendarModal(true);
                      }}
                      openDetailModal={(gig_id) => {
                        if (
                          type === uiConstants.ownerType.company ||
                          type === uiConstants.ownerType.hub
                        ) {
                          setCurrentVolunteerDetails(
                            volunteerItems.find((gig) => gig.id === gig_id) || null,
                          );
                          setShowOpportunityModal(true);
                        }
                      }}
                      showSocialButtons={true}
                      viewType={EViewTypeVolunteerCard.GRID}
                      styleType={
                        type === 'company'
                          ? EStyleTypeVolunteerCard.HUB_ONLY
                          : EStyleTypeVolunteerCard.HUB_PARTNER
                      }
                      userApplications={userApplications}
                    />
                  )}
                </React.Fragment>
              );
            })}

            {volunteerItems.length % 3 !== 0 &&
              generateEmptyCards(3 - (volunteerItems.length % 3), Constants.object_type.gig)}
          </div>

          {volunteerItems.length === +uiConstants.hubs.listViewPageSize * (page || 1) && (
            <div className="load-more-container">
              <Button
                text="Load more"
                type="button"
                buttonType="outline-dark"
                onClick={() => loadMoreItems()}
              />
            </div>
          )}
        </>
      )}

      {isLoading && (
        <div className="list-container">
          <CardLoader
            loaderItemClass="gig-loader-card-item"
            loading={isLoading}
            numberOfRows={1}
            numberOfCols={3}
            styleTypes={[
              ECardLoaderStyleType.PROFILE_IMG,
              ECardLoaderStyleType.LONG_TEXT,
              ECardLoaderStyleType.TWO_BUTTONS,
            ]}
          />
        </div>
      )}

      {!isLoading && volunteerItems.length < 1 && (
        <HubVolunteerEmptyState
          isPartner={type === 'partner'}
          hub={props.hub}
          userHasEditPermissions={props.userHasEditPermissions}
        />
      )}

      {showOpportunityModal && (
        <HubVolunteerModal
          show={showOpportunityModal}
          selected={currentVolunteerDetails}
          userState={userState}
          hideInterested={true}
          isCausePosting={type === 'partner'}
          onClose={() => setShowOpportunityModal(false)}
          styleType={
            type === uiConstants.ownerType.company
              ? EStyleTypeVolunteerCard.HUB_ONLY
              : EStyleTypeVolunteerCard.HUB_PARTNER
          }
          onUpdate={handleOnUpdate}
          onDeleteVolunteerOpportunity={handleDeleteOpportunity}
        />
      )}

      {activeGig && (
        <AddToCalendarModal
          showModal={showAddToCalendarModal}
          onCloseModal={() => setShowAddToCalendarModal(false)}
          eventToAdd={{
            title: activeGig.title,
            start: activeGig?.start_date ? new Date(activeGig.start_date) : new Date(),
            end: activeGig?.end_date ? new Date(activeGig.end_date) : new Date(),
            location: activeGig?.locations
              ? getAddressParts(activeGig.locations[0], ['line1', 'line2', 'city', 'country'])
              : 'N/A',
          }}
        />
      )}
    </div>
  );
};

export default HubVolunteering;
