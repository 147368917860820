import React, { FC, Fragment, useEffect, useState } from 'react';
import TitleSection, { ICtaConfig } from '../TitleSection/TitleSection';
import './MyProfile.scss';
import Portrait from '../../Portrait/Portrait';
import { IAppState } from '../../../store';
import { updateUser, updateUserCoverImage, updateUserProfileImage } from '../../../actions/user';
import { connect } from 'react-redux';
import { IUserState } from '../../../reducers/user';
import CoverImage from '../../CoverImage/CoverImage';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import TextField from '../../TextField/TextField';
import DatePicker from '../../DatePicker/DatePicker';
import moment, { Moment } from 'moment';
import { uiConstants } from '../../../constants';

interface IProps {
  userState: IUserState;
  updateUser(payload: any, updateProfile?: boolean, options?: { callback?: () => void }): void;
  updateUserProfileImage(payload: any): void;
  updateUserCoverImage(payload: any): void;
}

const MyProfile: FC<IProps> = (props: IProps) => {
  const [activeTabId, setActiveTabId] = useState<string>('basic');
  const [showEditProfileInfoModal, setShowEditProfileInfoModal] = useState<boolean>(false);
  const [profileInfoFirstName, setProfileInfoFirstName] = useState<string>();
  const [profileInfoLastName, setProfileInfoLastName] = useState<string>();
  const [profileInfoDisplayName, setProfileInfoDisplayName] = useState<string>();
  const [profileInfoDob, setProfileInfoDob] = useState<Moment>();
  const [profileInfoGender, setProfileInfoGender] = useState<string>();

  const ctaConfig: ICtaConfig = {
    buttonText: 'View my profile',
    onClick: () => {},
    linkTo: `user/${props.userState.user.handle}`,
  };

  useEffect(() => {
    clearUserChanges();
  }, []);

  function clearUserChanges() {
    const _user = props.userState.user;
    setProfileInfoFirstName(_user.first_name);
    setProfileInfoLastName(_user.last_name);
    setProfileInfoDisplayName(_user.display_name);
    setProfileInfoDob(moment(_user.dob).utc());
    setProfileInfoGender(_user.gender);
  }

  function updateUser() {
    const payload = {
      first_name: profileInfoFirstName,
      last_name: profileInfoLastName,
      display_name: profileInfoDisplayName,
      dob: moment(profileInfoDob).format(uiConstants.backendDateFormat),
      gender: profileInfoGender,
    };

    props.updateUser(payload);
    setShowEditProfileInfoModal(false);
  }

  function isProfileInfoChanged(): boolean {
    const _user = props.userState.user;
    const oldDob = moment(_user.dob).utc();

    return (
      _user.first_name !== profileInfoFirstName ||
      _user.last_name !== profileInfoLastName ||
      _user.display_name !== profileInfoDisplayName ||
      oldDob.day() !== profileInfoDob?.day() ||
      oldDob.month() !== profileInfoDob?.month() ||
      oldDob.year() !== profileInfoDob?.year() ||
      _user.gender !== profileInfoGender
    );
  }

  return (
    <Fragment>
      <TitleSection
        title="My Profile"
        ctaConfig={ctaConfig}
      />
      <div className="MyProfile">
        {activeTabId === 'basic' && (
          <div className="tab basic">
            <section className="avatar">
              <div className="header">
                <div className="title-section">
                  <h2>Profile Avatar</h2>
                  <span className="header-desc">Edit your profile photo.</span>
                </div>
              </div>
              <div className="section-content">
                <Portrait
                  overlay={true}
                  onChange={(file) => {
                    props.updateUserProfileImage(file.file);
                  }}
                  currentImage={props.userState?.user?.profile_image_url || ''}
                  size={120}
                />
              </div>
            </section>

            <hr />

            <section className="cover-img">
              <div className="header">
                <div className="title-section">
                  <h2>Profile Cover</h2>
                  <span className="header-desc">Edit your cover photo.</span>
                </div>
              </div>
              <div className="section-content">
                <CoverImage
                  overlay={true}
                  onChange={(file) => {
                    props.updateUserCoverImage(file.file);
                  }}
                  currentImage={props.userState?.user?.cover_image_url || ''}
                />
              </div>
            </section>

            <hr />

            <section className="profile-info">
              <div className="header">
                <div className="title-section">
                  <h2>Profile Information</h2>
                  <span className="header-desc">
                    The information will be shared with the public.
                  </span>
                </div>
                <Button
                  text="Edit"
                  icon="fas fa-pen"
                  buttonType="outline-dark"
                  onClick={() => setShowEditProfileInfoModal(true)}
                />
              </div>
            </section>
          </div>
        )}
      </div>

      <Modal
        class="UserProfileInfoModal"
        show={showEditProfileInfoModal}
        onClose={() => setShowEditProfileInfoModal(false)}
        closeIcon=" "
      >
        <div className="header-custom">
          <h2>Edit profile information</h2>
          <span>The information will be shared with the public.</span>
        </div>

        <hr />

        <div className="form-container">
          <form>
            <div className="col medium-input-size">
              <TextField
                type="text"
                onChange={(e) => {
                  setProfileInfoFirstName(e.target.value);
                }}
                name="first_name"
                value={profileInfoFirstName || ''}
                required={true}
                label="First Name"
              />
            </div>
            <div className="col medium-input-size">
              <TextField
                type="text"
                onChange={(e) => {
                  setProfileInfoLastName(e.target.value);
                }}
                name="last_name"
                value={profileInfoLastName || ''}
                required={true}
                label="Last Name"
              />
            </div>
            <div className="col">
              <label className="gender-label">Gender</label>
              <div className="gender-selector">
                <div onClick={() => setProfileInfoGender('male')}>
                  <i
                    className={
                      profileInfoGender === 'male' ? 'far fa-scrubber selected' : 'fal fa-circle'
                    }
                  />
                  <span>Male</span>
                </div>
                <div onClick={() => setProfileInfoGender('female')}>
                  <i
                    className={
                      profileInfoGender === 'female' ? 'far fa-scrubber selected' : 'fal fa-circle'
                    }
                  />
                  <span>Female</span>
                </div>
                <div onClick={() => setProfileInfoGender('other')}>
                  <i
                    className={
                      profileInfoGender === 'other' ? 'far fa-scrubber selected' : 'fal fa-circle'
                    }
                  />
                  <span>Other</span>
                </div>
                <div onClick={() => setProfileInfoGender('rather_not_say')}>
                  <i
                    className={
                      profileInfoGender === 'rather_not_say'
                        ? 'far fa-scrubber selected'
                        : 'fal fa-circle'
                    }
                  />
                  <span>Rather not say</span>
                </div>
              </div>
            </div>
            <div className="col">
              <DatePicker
                yearClass="date-picker-input"
                monthClass="date-picker-input"
                dayClass="date-picker-input"
                maxYear={moment().year().toString()}
                name="dob"
                label="Date of Birth"
                date={profileInfoDob || moment()}
                onChange={(e) => setProfileInfoDob(e)}
              />
              {moment(profileInfoDob).isAfter(new Date()) && (
                <span className="dob-error">The date of birth can not be in the future.</span>
              )}
            </div>

            <div className="col medium-input-size">
              <TextField
                type="text"
                onChange={(e) => {
                  setProfileInfoDisplayName(e.target.value);
                }}
                name="display_name"
                value={profileInfoDisplayName || ''}
                required={true}
                label="Display Name"
              />
            </div>

            <div className="actions">
              <Button
                buttonClass="cancel-button"
                loading={props.userState.isUserLoading}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setShowEditProfileInfoModal(false);
                  clearUserChanges();
                }}
                text="Cancel"
                buttonType="outline-dark"
              />
              <Button
                buttonClass="save-button"
                loading={props.userState.isUserLoading}
                isDisabled={!isProfileInfoChanged() || moment(profileInfoDob).isAfter(new Date())}
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  updateUser();
                }}
                text="Save"
                buttonType="dark"
              />
            </div>
          </form>
        </div>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

const mapDispatchToProps = {
  updateUser,
  updateUserProfileImage,
  updateUserCoverImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
