import React, { Component, ReactNode } from 'react';
import { combineClassNames } from '../../../helpers';

import './ModalScrollContainer.scss';

interface IProps {
  children?: ReactNode;
  className?: string;
}

/** Container for scrollable content that can be used in modals. */
class ModalScrollContainer extends Component<IProps> {
  render() {
    return (
      <div className={combineClassNames('ModalScrollContainer', this.props.className)}>
        <div className="modal-content">{this.props.children}</div>
      </div>
    );
  }
}

export default ModalScrollContainer;
