import { Constants } from '@gigit/constants';
import { IApplication, IGig, IUser, IUserContact } from '@gigit/interfaces';
import moment from 'moment';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { uiConstants } from '../../constants';
import { capitalizeString, errorHelpers, typeHelpers } from '../../helpers';
import useToastDispatcher from '../../hooks/useToaster';
import { IOwnerObject } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import {
  eventRequestActions,
  gigRequestActions,
  groupRequestActions,
  hubRequestActions,
  userRequestActions,
} from '../../requestActions';
import AvailabilitySettings from '../AvailabilitySettings/AvailabilitySettings';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import Modal from '../Modal/Modal';
import Portrait from '../Portrait/Portrait';
import TextArea from '../TextArea/TextArea';
import './VolunteerApplicationModal.scss';

interface IProps {
  readOnly?: boolean;
  show: boolean;
  owner: IOwnerObject;
  user: IUser;
  closeModal: () => void;
}

interface IOrganizerContact {
  email?: string;
  phone?: string;
  type: string;
  id: string;
  contacts?: IUserContact[];
}

const ACTION_REJECT = 'reject';
const ACTION_APPROVE = 'approve';

const VolunteerApplicationModal: FC<IProps> = (props: IProps) => {
  const history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showRejectReason, setShowRejectReason] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState<string>('');

  const [gigApplication, setGigApplication] = useState<IApplication | null>(null);
  const [causeApplication, setCauseApplication] = useState<IApplication | null>(null);

  const [suggestOnRejection, setSuggestOnRejection] = useState<boolean>(false);
  const [applicationStatus, setApplicationStatus] = useState<string>();
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [triggerShowContactModal, setTriggerShowContactModal] = useState<boolean>(false);

  const [activeModalApplication, setActiveModalApplication] = useState<any>();

  const [organizerDetails, setOrganizerDetails] = useState<IOrganizerContact | null>();

  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  useEffect(() => {
    if (props.show && props.owner && props.user) {
      getApplications();
    }
    setShowModal(props.show);
  }, [props.show, props.owner, props.user]);

  useEffect(() => {
    if (triggerShowContactModal) {
      handleOrganizerContactsInfo();
    }
  }, [triggerShowContactModal]);

  const handleOrganizerContactsInfo = async () => {
    let contactInfo: IOrganizerContact | null = null;

    if (gigApplication && gigApplication.group_id) {
      const gig =
        gigApplication.gig_id &&
        (await gigRequestActions.getGigByHandle(`${gigApplication.gig_id}`));

      if (gig && gig.external_id?.VolunteerMatch) {
        contactInfo = {
          email: gig.contact_details?.email,
          phone: gig.contact_details?.phone,
          type: uiConstants.ownerType.gig,
          id: gig.id!,
        };
      } else {
        if (gigApplication.event_id) {
          let eventOrganizer = await eventRequestActions.getEventByHandleOrId(
            `${gigApplication.event_id}`,
          );
          let organzierData = await userRequestActions.getAvailableOrganizerContacts(
            uiConstants.ownerType.event,
            gigApplication.event_id,
          );

          contactInfo = {
            email: eventOrganizer.contact_details?.email,
            phone: eventOrganizer.contact_details?.phone,
            type: uiConstants.ownerType.event,
            id: eventOrganizer.id!,
            contacts: organzierData,
          };

          setOrganizerDetails(contactInfo);
        } else {
          let groupOrganizer = await groupRequestActions.getGroupByHandleOrId(
            `${gigApplication.group_id}`,
          );
          let organzierData = await userRequestActions.getAvailableOrganizerContacts(
            uiConstants.ownerType.group,
            gigApplication.group_id,
          );

          contactInfo = {
            email: groupOrganizer.contact_details?.email,
            phone: groupOrganizer.contact_details?.phone,
            type: uiConstants.ownerType.group,
            id: groupOrganizer.id!,
            contacts: organzierData,
          };

          setOrganizerDetails(contactInfo);
        }
      }
    } else {
      typeHelpers.assertNotNullOrUndefined(causeApplication);
      if (causeApplication.group_id) {
        let groupOrganizer = await groupRequestActions.getGroupByHandleOrId(
          `${causeApplication.group_id}`,
        );
        let organzierData = await userRequestActions.getAvailableOrganizerContacts(
          uiConstants.ownerType.group,
          causeApplication.group_id,
        );

        contactInfo = {
          email: groupOrganizer.contact_details?.email,
          phone: groupOrganizer.contact_details?.phone,
          type: uiConstants.ownerType.group,
          id: groupOrganizer.id!,
          contacts: organzierData,
        };

        setOrganizerDetails(contactInfo);
      }
    }

    setShowContactModal(true);
  };

  const contactOrganizerModalValues = async (application: IApplication): Promise<void> => {
    const gig =
      application.gig_id && (await gigRequestActions.getGigByHandle(`${application.gig_id}`));

    if (gig && gig.external_id?.VolunteerMatch) {
      setActiveModalApplication(gig);
    } else {
      const modalItem = application.event_id
        ? await eventRequestActions.getEventByHandleOrId(`${application.event_id}`)
        : await groupRequestActions.getGroupByHandleOrId(`${application.group_id}`);
      setActiveModalApplication(modalItem);
    }

    setShowContactModal(true);
  };

  const getApplications = async () => {
    if (props.owner && props.user && props.user.id) {
      try {
        if (props.owner.ownerType === uiConstants.ownerType.gig) {
          let gig = props.owner.object as IGig;
          let response: IApplication[] = [];

          typeHelpers.assertNotNullOrUndefined(props.user.id, 'Expected User ID');

          if (gig?.group_id) {
            response = await groupRequestActions.getGroupApplicationsByUser(
              gig.group_id,
              props.user.id,
            );
          } else {
            typeHelpers.assertNotNullOrUndefined(gig.hub_id, 'Expected Company ID');
            response = await hubRequestActions.getHubApplicationsByUser(gig.hub_id, props.user.id);
          }

          let gigApp = response.find((app) => app.gig_id === gig.id);
          if (gigApp) {
            setGigApplication(gigApp);
            setApplicationStatus(gigApp.status?.code);
            setRejectReason(gigApp.status?.status_reason || '');
          }

          let causeApp = response.find((app) => !app.gig_id);
          if (causeApp) {
            setCauseApplication(causeApp);
            if (!gigApp) {
              setApplicationStatus(causeApp.status?.code);
            }
          }
        } else {
          typeHelpers.assertNotNullOrUndefined(props.owner.ownerId);
          typeHelpers.assertNotNullOrUndefined(props.user.id);
          groupRequestActions
            .getGroupApplicationsByUser(props.owner.ownerId, props.user.id)
            .then((response) => {
              let causeApp = response.find(
                (app) => app.group_id === props.owner.ownerId && !app.gig_id,
              );
              if (causeApp) {
                setCauseApplication(causeApp);
                setApplicationStatus(causeApp.status?.code);
                setRejectReason(causeApp.status?.status_reason || '');
              }
            });
        }
      } catch (error) {
        dispatchToastError(error, 'Get Applications');
      }
    }
  };

  const handleAction = async (action: string) => {
    switch (action) {
      case ACTION_REJECT:
        if (props.owner?.ownerType === uiConstants.ownerType.gig && gigApplication) {
          try {
            if (suggestOnRejection) {
              typeHelpers.assertNotNullOrUndefined(causeApplication?.id, 'Expected Application ID');
              if (causeApplication?.group_id) {
                await groupRequestActions.approveGroupApplicant(
                  causeApplication.group_id,
                  causeApplication.id,
                );
              } else {
                typeHelpers.assertNotNullOrUndefined(causeApplication?.hub_id);
                await hubRequestActions.approveHubApplication(
                  causeApplication.hub_id,
                  causeApplication.id,
                );
              }

              dispatchToastSuccess(
                localizeHelpers.translate('User suggested for future volunteer applications.'),
                'Application',
              );
            }

            if (gigApplication.status?.code !== Constants.application_status.rejected) {
              typeHelpers.assertNotNullOrUndefined(gigApplication?.id, 'Expected Application ID');
              if (gigApplication?.group_id) {
                await groupRequestActions.rejectGroupApplicant(
                  gigApplication.group_id,
                  gigApplication.id,
                  {
                    rejectReason: rejectReason,
                  },
                );
              } else {
                typeHelpers.assertNotNullOrUndefined(gigApplication?.hub_id);
                await hubRequestActions.rejectHubApplication(
                  gigApplication.hub_id,
                  gigApplication.id,
                );
              }

              dispatchToastSuccess(
                localizeHelpers.translate('Application successfully rejected.'),
                'Application',
              );
            }

            setShowRejectReason(false);
            await getApplications();
          } catch (error) {
            dispatchToastError(error, 'Application');
          }
        } else {
          try {
            typeHelpers.assertNotNullOrUndefined(causeApplication?.id);
            if (causeApplication?.group_id) {
              await groupRequestActions.rejectGroupApplicant(
                causeApplication?.group_id,
                causeApplication.id,
                {
                  rejectReason: rejectReason,
                },
              );
            } else {
              typeHelpers.assertNotNullOrUndefined(causeApplication?.hub_id);
              await hubRequestActions.rejectHubApplication(
                causeApplication?.hub_id,
                causeApplication.id,
              );
            }

            dispatchToastSuccess(
              localizeHelpers.translate('Application successfully rejected.'),
              'Application',
            );
            await getApplications();
          } catch (error) {
            dispatchToastError(error, 'Application');
          }
        }
        break;
      case ACTION_APPROVE:
        try {
          if (causeApplication) {
            if (causeApplication.status?.code === Constants.application_status.pending) {
              typeHelpers.assertNotNullOrUndefined(causeApplication?.id);
              if (causeApplication?.group_id) {
                await groupRequestActions.approveGroupApplicant(
                  causeApplication.group_id,
                  causeApplication.id,
                );
              } else {
                typeHelpers.assertNotNullOrUndefined(causeApplication?.hub_id);
                await hubRequestActions.approveHubApplication(
                  causeApplication.hub_id,
                  causeApplication.id,
                );
              }
            }
          }

          if (gigApplication) {
            typeHelpers.assertNotNullOrUndefined(gigApplication?.id);

            if (gigApplication?.group_id) {
              await groupRequestActions.approveGroupApplicant(
                gigApplication?.group_id,
                gigApplication.id,
              );
            } else {
              typeHelpers.assertNotNullOrUndefined(gigApplication?.hub_id);
              await hubRequestActions.approveHubApplication(
                gigApplication.hub_id,
                gigApplication.id,
              );
            }
          }

          dispatchToastSuccess(
            localizeHelpers.translate('Application successfully approved.'),
            'Application',
          );
          await getApplications();
        } catch (error) {
          dispatchToastError(error, 'Application');
        }
        break;
    }
  };

  const resetState = () => {
    setShowRejectReason(false);
    setCauseApplication(null);
    setGigApplication(null);
    setApplicationStatus('');
    props.closeModal();
  };

  const renderRejectReason = () => {
    if (!showRejectReason) {
      if (props.owner?.ownerType === uiConstants.ownerType.gig) {
        if (gigApplication && gigApplication.status?.code === 'rejected') {
          return (
            <>
              <div className="reject-reason-title">Reason for rejection</div>
              <div className="reject-reason">{gigApplication.status?.status_reason}</div>
            </>
          );
        } else {
          if (causeApplication && causeApplication.status?.code === 'rejected') {
            return (
              <>
                <div className="reject-reason-title">Reason for rejection</div>
                <div className="reject-reason">{causeApplication?.status?.status_reason}</div>
              </>
            );
          }
        }
      } else {
        if (causeApplication && causeApplication.status?.code === 'rejected') {
          return (
            <>
              <div className="reject-reason-title">Reason for rejection</div>
              <div className="reject-reason">{causeApplication?.status?.status_reason}</div>
            </>
          );
        }
      }
    }
    return;
  };

  const startAGroupChat = async (orgDetails: IOrganizerContact) => {
    try {
      let added = await userRequestActions.getOrganizerContacts(orgDetails.type, orgDetails.id);

      if (organizerDetails?.contacts && organizerDetails?.contacts.length) {
        for (let organizer of organizerDetails?.contacts) {
          let exists = added.find(
            (contact) =>
              contact.contact_user_id.toString() == organizer.id ||
              contact.user_id.toString() == organizer.id,
          );
          if (!exists) {
            await userRequestActions.addOrganizerContact(organizer.id);
          }
        }
        history.push(
          `/dashboard?section=activity&tab=network&networkTab=messages&organizer_type=${orgDetails.type}&organizer_id=${orgDetails.id}`,
        );
      }
    } catch (error) {
      let errorObj = errorHelpers.getErrorObject(error);
      if (errorObj.messageTemplate !== 'Contact already exists') {
        dispatchToastError(error, 'Application');
      }
    }
  };

  const startApplicantConversation = async () => {
    if (props.user.id) {
      let contacts = await userRequestActions.getMyContacts();
      let exists = contacts.find(
        (contact) =>
          contact.contact_user_id.toString() == props.user.id ||
          contact.user_id.toString() == props.user.id,
      );

      if (!exists) {
        await userRequestActions.addOrganizerContact(props.user.id as string);
      }
      history.push(
        `/dashboard?section=activity&tab=network&networkTab=messages&contact=${props.user.id}`,
      );
    }
  };

  return (
    <Modal
      class="VolunteerApplicationModal"
      show={showModal}
      onClose={() => {
        resetState();
      }}
    >
      <div className="application-modal-wrapper">
        <div className="title-wrapper">
          <Portrait
            source={props.owner?.ownerProfileImageUrl || ''}
            size={40}
          />
          <div className="title">
            {gigApplication ? gigApplication.gig?.title : causeApplication?.group?.title}
            <span className="application-date">
              Applied on{' '}
              {gigApplication
                ? moment(gigApplication.created_at).format('DD/MM/YYYY')
                : moment(causeApplication?.created_at).format('DD/MM/YYYY')}
            </span>
          </div>
        </div>
        {!props.readOnly && (
          <div className="approval-actions">
            <Button
              onClick={() => handleAction(ACTION_APPROVE)}
              className={`approved-button ${applicationStatus === 'approved' && !showRejectReason ? 'active' : ''}`}
            >
              Approve
            </Button>
            <Button
              className="pending-button"
              isDisabled={applicationStatus !== ''}
            >
              Pending
            </Button>
            <Button
              onClick={() => setShowRejectReason(true)}
              className={`rejected-button ${applicationStatus === 'rejected' || showRejectReason ? 'active' : ''}`}
            >
              Reject
            </Button>
          </div>
        )}
        <div className="reject-reason-read-only">{renderRejectReason()}</div>
        {props.readOnly && (
          <div className="approval-actions">
            {applicationStatus === 'approved' && (
              <Button
                onClick={() => history.push('dashboard?section=activity&tab=agenda')}
                buttonType="outline-dark"
              >
                View Schedule
              </Button>
            )}
            <Button
              onClick={() => setTriggerShowContactModal(true)}
              buttonType="outline-dark"
            >
              Contact Organizer
            </Button>
            {applicationStatus === 'rejected' && (
              <Button
                onClick={() => history.push(`/gig/${gigApplication?.gig?.handle}?reapply=true`)}
                buttonType="outline-dark"
              >
                Reapply
              </Button>
            )}
          </div>
        )}
        {showRejectReason && (
          <div className="reject-reason">
            <TextArea
              className="description-text-field"
              label="Reason for Rejection"
              type="text"
              value={rejectReason}
              name="rejectionReason"
              required={false}
              spellCheck={false}
              placeholder="Enter Description"
              charLength={200}
              max={'200'}
              maxLength={200}
              onChange={(e) => {
                setRejectReason(e.target.value.replace(/<\/?[^>]+(>|$)/g, ''));
              }}
            />
            <div className="reject-actions">
              <Button
                onClick={() => setShowRejectReason(false)}
                className="cancel-button"
                buttonType="outline-dark"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleAction(ACTION_REJECT)}
                buttonType="dark"
              >
                Save
              </Button>
            </div>
            {props.owner?.ownerType == uiConstants.ownerType.gig && gigApplication && (
              <Checkbox
                className="sugges-applicant-checkbox"
                color="plum-dark"
                name="suggest"
                value={suggestOnRejection}
                checked={suggestOnRejection}
                onChange={() => {
                  setSuggestOnRejection(!suggestOnRejection);
                }}
                label={`Suggest **${props.user.display_name}** to me for future volunteer applications`}
              />
            )}
          </div>
        )}
        <div className="user-applicant-info">
          <div className="user-title">
            <div className="user-info-details">
              <Portrait
                source={props.user.profile_image_url || ''}
                size={40}
              />
              <div className="title">{props.user.display_name}</div>
              <span className={`status-code ${applicationStatus}`}>
                {capitalizeString(applicationStatus)}
              </span>
            </div>
            {!props.readOnly && (
              <div>
                <Button
                  onClick={() => startApplicantConversation()}
                  buttonType="outline-dark"
                >
                  Message Applicant
                </Button>
              </div>
            )}
          </div>
          <div className="user-info">
            <div className="user-info-item">{props.user.email}</div>
            <div className="user-info-item">{props.user.phone}</div>
            <div className="user-info-item">Address Here</div>

            <div className="user-info-subheading">Emergency Contact</div>
            {props.user?.emergency_contacts?.length && (
              <>
                <div className="user-info-item">{props.user?.emergency_contacts[0].name}</div>
                <div className="user-info-item">{props.user?.emergency_contacts[0].phone}</div>
              </>
            )}
            <div className="user-info-subheading">Availability</div>
            <div className="availability-wrapper">
              <div className="availability-table">
                <AvailabilitySettings
                  settingsTheme={true}
                  isCustomUser={true}
                  customUserAvailabilityData={gigApplication?.volunteer_availability}
                />
              </div>
            </div>
            {gigApplication &&
            gigApplication?.application_form &&
            gigApplication?.application_form.length ? (
              <>
                <div className="user-info-subheading">Form Responses</div>
                {gigApplication?.application_form?.map((question) => {
                  return (
                    <>
                      <div className="user-info-item">{question.question_label}</div>
                      <div className="user-info-item">{question.answer}</div>
                    </>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        contentClassName="contact-options-modal"
        show={showContactModal}
        onClose={() => {
          setShowContactModal(false);
          setTriggerShowContactModal(false);
        }}
        title="Contact Organizer"
      >
        <div
          className="contact-options"
          notranslate="yes"
        >
          {activeModalApplication?.external_id?.volunteerMatch ? (
            <p>The organizer has not added any contact information</p>
          ) : (
            <React.Fragment>
              {organizerDetails?.email && (
                <div>
                  <i className="fa fa-envelope-o" />
                  <a href={`mailto:${organizerDetails.email}`}>{organizerDetails.email}</a>
                </div>
              )}
              {organizerDetails?.phone && (
                <div>
                  <i className="fa fa-phone" />
                  <a href={`tel:${organizerDetails.phone}`}>{organizerDetails.phone}</a>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="actions">
          {organizerDetails?.contacts && organizerDetails.contacts.length && (
            <Button onClick={() => startAGroupChat(organizerDetails)}>Message</Button>
          )}
          <Button
            buttonType="outline-dark"
            onClick={() => {
              setShowContactModal(false);
              setTriggerShowContactModal(false);
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </Modal>
  );
};

export default VolunteerApplicationModal;
