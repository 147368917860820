import React from 'react';
import { connect } from 'react-redux';
import { Config } from '@gigit/config';
import { RouteComponentProps, Link } from 'react-router-dom';
import Axios from 'axios';
import queryString from 'query-string';
import { userSelectors } from '../../../selectors/user';
import { IAppState } from '../../../store';
import { IToast } from '../../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import {
  defaultCurrency,
  formatCurrency,
  swapRouteParams,
  routes,
  errorHelpers,
  toastError,
} from '../../../helpers';
import Portrait from '../../../components/Portrait/Portrait';
import Button from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';
import TextField from '../../../components/TextField/TextField';
import Share from '../../../components/Share/Share';
import Modal from '../../../components/Modal/Modal';
import CoverImage from '../../../components/CoverImage/CoverImage';
import QuillTextEditor from '../../../components/QuillTextEditor/QuillTextEditor';
import { BenefitingCharityTile } from '../../../components/BenefitingCharityTile/BenefitingCharityTile';
import StravaSection from '../../../components/StravaSection/StravaSection';
import { IEventState } from '../../../reducers/event';
import { IUserState } from '../../../reducers/user';
import { IFlowStateConfig } from '../../../reducers/register';
import { joinEventTeam } from '../../../actions/event';
import { addItem } from '../../../actions/cart';
import { createToast } from '../../../actions/toaster';
import { setFlow } from '../../../actions/register';
import './TeamDefault.scss';
import { uiConstants } from '../../../constants/uiConstants';
import typeHelpers from '../../../helpers/typeHelpers';
import { IEventIndividual, IEventTeam, IFundraiserDonation, IGroup } from '@gigit/interfaces';
import { teamRequestActions } from '../../../requestActions/team';

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  eventState: IEventState;
  locale: string;
  createToast(toast: IToast): void;
  joinEventTeam(event: string, team: string, payload: any): void;
  addItem(item: any, parent_type: string): void;
  setFlow(flowConig: IFlowStateConfig): void;
}

interface IState {
  showShareModal: boolean;
  showConfirmModal: boolean;
  team: any;
  eventTeam: IEventTeam | null;
  members: IEventIndividual[];
  donations: IFundraiserDonation[];
  isTeamLoading: boolean;
  fundraisingTicketQty: string;
  teamOwner: any;
  params: any;
  editStory: boolean;
  editTeamName: boolean;
  formats: Array<any>;
  modules: any;
}

class TeamDefault extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let _params = queryString.parse(this.props.location.search);

    this.state = {
      showShareModal: false,
      showConfirmModal: false,
      team: {},
      eventTeam: null,
      members: [],
      donations: [],
      isTeamLoading: false,
      fundraisingTicketQty: '1',
      teamOwner: null,
      params: _params,
      editStory: false,
      editTeamName: false,
      modules: {
        toolbar: uiConstants.quill.defaultToolbar,
        clipboard: {
          matchVisual: false,
        },
      },
      formats: uiConstants.quill.defaultFormats,
    };

    this.hasDonationMatching = this.hasDonationMatching.bind(this);
    this.joinDefaultTeam = this.joinDefaultTeam.bind(this);
    this.handleTeamInputChange = this.handleTeamInputChange.bind(this);
    this.handleStoryChange = this.handleStoryChange.bind(this);
    this.saveTeam = this.saveTeam.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.checkFees = this.checkFees.bind(this);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.eventState.event !== prevProps.eventState.event &&
      this.props.eventState.event.id !== ''
    ) {
      this.getTeam(this.props.eventState.event.id, this.props.match.params.id);
    }

    if (
      prevState.eventTeam !== this.state.eventTeam &&
      this.state.eventTeam?.handle === this.props.match.params.id
    ) {
      this.setState(
        {
          team: this.state.eventTeam,
        },
        () => {
          if (this.state.params.fn && this[this.state.params.fn as keyof TeamDefault]) {
            this[this.state.params.fn as keyof TeamDefault]();
          }
        },
      );

      for (let m in this.state.members) {
        let _member = this.state.members[m];

        if (_member.user_id === this.state.eventTeam?.owner_id) {
          this.setState({
            teamOwner: _member,
          });
        }
      }
    }

    if (prevState.members !== this.state.members) {
      this.setState({
        teamOwner: this.state.members.find(
          (member) => member.user_id === this.state.eventTeam?.owner_id,
        ),
      });
    }
  }

  hasDonationMatching() {
    return (
      this.props.eventState.event?.donations_matching &&
      this.props.eventState.event.donations_matching?.organization &&
      this.props.eventState.event.donations_matching?.max_amount &&
      this.props.eventState.event.donations_matching?.ratio &&
      this.props.eventState.event.donations_matching?.ratio_description
    );
  }

  joinDefaultTeam() {
    const userAlreadyJoined = this.state.members.find(
      (m) => m.user_id === this.props.userState.user.id,
    );

    if (userAlreadyJoined) {
      this.props.history.push(
        `/event/${this.props.eventState.event.handle}/individual/${this.props.userState.user.handle}`,
      );
    }

    if (this.props.userState.isLoggedIn) {
      //check if user has an Individual page for this event
      Axios.get(
        swapRouteParams(routes.CHECK_FUNDRAISE_STATUS, {
          eventId: this.props.eventState.event.id,
          email: this.props.userState.user.email,
        }),
      ).then((response) => {
        if (response?.data?.isIndividual) {
          this.props.joinEventTeam(
            this.props.eventState.event.id,
            this.state.eventTeam?.id || '',
            {},
          );
        } else {
          if (this.state.params.fn) {
            //for better browser goBack() flow
            this.props.history.replace(
              `/event/${this.props.eventState.event.handle}/team/${this.state.eventTeam?.handle}`,
            );
          }

          let config: IFlowStateConfig = {
            group: null,
            event: null,
            gig: null,
            type: 'fundraise',
            currentStep: 'goalIndividual',
            groupType: '',
            eventType: '',
            isCharity: false,
            isEvent: false,
            isComplete: false,
            fundraiser: {
              participants: [],
              hasParticipants: false,
              type: 'join',
              teamId: this.state.eventTeam?.id,
            },
          };
          this.props.setFlow(config);
          this.props.history.push(
            `/setup/fundraise?event=${this.props.eventState.event.id}&currentStep=goalIndividual&joinTeam=${this.state.eventTeam?.id}`,
          );
        }
      });
    } else {
      this.setState({ showConfirmModal: false });
      this.props.history.push(
        '/login?redirect=' +
          encodeURIComponent(
            `/event/${this.props.eventState.event.handle}/team/${this.state.eventTeam?.handle}?fn=joinDefaultTeam`,
          ),
      );
    }
  }

  handleTeamInputChange(event: any, self: any, prevent?: boolean, callback?: any) {
    if (prevent === true) {
      event.preventDefault();
    }

    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;
    const name = target.name;

    self.setState(
      {
        team: {
          ...this.state.team,
          [name]: value,
        },
      },
      () => {
        if (callback !== undefined) {
          callback();
        }
      },
    );
  }

  handleStoryChange(content: string) {
    this.setState({
      team: {
        ...this.state.team,
        story: content,
      },
    });
  }

  saveTeam() {
    this.updateTeam(this.props.eventState.event.id, this.state.team.id, this.state.team);
  }

  onImageChange(fileData: any) {
    switch (fileData.type) {
      case 'cover_image_url':
        this.updateTeamCoverImage(fileData.file);
        break;
      case 'profile_image_url':
        this.updateTeamProfileImage(fileData.file);
        break;
    }
  }

  async updateTeamCoverImage(fileAddress: string) {
    try {
      const newTeamCoverImage = await teamRequestActions.updateTeamCoverImage(fileAddress);
      if (this.state.eventTeam) {
        let updatedTeam = { ...this.state.eventTeam, ...{ cover_image_url: newTeamCoverImage } };
        if (updatedTeam.id) {
          this.updateTeam(updatedTeam.event_id, updatedTeam.id, updatedTeam);
        }
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Team Cover Image');
      createToast(toast);
    }
  }

  async updateTeamProfileImage(fileAddress: string) {
    try {
      const newTeamProfileImage = await teamRequestActions.updateTeamProfileImage(fileAddress);
      if (this.state.eventTeam) {
        let updatedTeam = {
          ...this.state.eventTeam,
          ...{ profile_image_url: newTeamProfileImage },
        };
        if (updatedTeam.id) {
          this.updateTeam(updatedTeam.event_id, updatedTeam.id, updatedTeam);
        }
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Team Profile Image');
      createToast(toast);
    }
  }

  async updateTeam(eventId: string, teamId: string, payload: IEventTeam) {
    try {
      await teamRequestActions.updateTeam(eventId, teamId, payload);
      this.getTeam(eventId, teamId);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Team');
      createToast(toast);
    }
  }

  async getTeam(eventId: string, teamId: string) {
    try {
      const eventTeam = await teamRequestActions.getTeam(eventId, teamId);
      this.setState({ eventTeam });
      if (eventTeam.id) {
        this.getTeamMembers(eventId, eventTeam.id);
        this.getTeamDonations(eventId, eventTeam.id);
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Team');
      createToast(toast);
    }
  }

  async getTeamMembers(eventId: string, teamId: string) {
    try {
      const members = await teamRequestActions.getTeamMembers(eventId, teamId);
      this.setState({ members });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Team Members');
      createToast(toast);
    }
  }

  async getTeamDonations(eventId: string, teamId: string) {
    try {
      const donations = await teamRequestActions.getTeamDonations(eventId, teamId);
      this.setState({ donations });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Team Donations');
      createToast(toast);
    }
  }

  checkFees(donation: any) {
    const { amount, payment_platform_fee } = donation.amounts;
    const { covers_processing_fees } = donation.fee_control;

    return covers_processing_fees ? amount + payment_platform_fee : amount;
  }

  render() {
    const currency = this.props.eventState.event.group?.account?.currency ?? defaultCurrency;

    return (
      <div className={`TeamDefault ${this.state.showConfirmModal ? 'allow-action' : ''}`}>
        <div className="Team-content">
          <div className="sidebar">
            <div className="sidebar-inner">
              <Portrait
                currentImage={this.state.eventTeam?.profile_image_url}
                onChange={this.onImageChange}
                overlay={this.state.eventTeam?.owner_id === this.props.userState.user.id}
                size={240}
              />
              {!this.state.isTeamLoading && (
                <div className="sidebar-content">
                  <div className="sidebar-info">
                    <div className="type team">
                      <i className="far fa-users" />
                      <span>Team</span>
                    </div>
                    {!this.state.editTeamName && (
                      <h1
                        className="name"
                        notranslate="yes"
                      >
                        <span>{this.state.eventTeam?.name}</span>
                        {this.props.userState?.user.id === this.state.eventTeam?.owner_id && (
                          <i
                            onClick={() => {
                              this.setState({ editTeamName: !this.state.editTeamName });
                            }}
                            className="fa fa-edit"
                          />
                        )}
                      </h1>
                    )}
                    {this.state.editTeamName && (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.setState({ editTeamName: !this.state.editTeamName }, () => {
                            if (this.state.editTeamName === false) {
                              this.saveTeam();
                            }
                          });
                        }}
                        className="team-title"
                      >
                        <TextField
                          required={true}
                          type="text"
                          name="name"
                          value={this.state.team.name}
                          onChange={(e) => {
                            this.handleTeamInputChange(e, this);
                          }}
                        />
                      </form>
                    )}
                    <div
                      className="handle"
                      notranslate="yes"
                    >
                      <Link to={'/event/' + this.props.match.params.handle}>
                        {this.props.eventState.event.title}
                      </Link>
                    </div>
                  </div>
                  {this.hasDonationMatching() && (
                    <div className="donation-matching-message">
                      <h3>Donation Matching</h3>
                      <p notranslate="yes">
                        {this.props.eventState.event?.donations_matching?.ratio_description}
                      </p>
                      {this.props.eventState.event?.donations_matching?.matched_amount !== 0 && (
                        <span>
                          <var data-var="matched_so_far">
                            {formatCurrency(
                              this.props.eventState.event?.donations_matching?.matched_amount || 0,
                              currency,
                              this.props.locale,
                            )}
                          </var>{' '}
                          Matched to Date
                        </span>
                      )}
                    </div>
                  )}
                  <div className="sidebar-actions">
                    {uiConstants.activeEventStatuses.includes(
                      this.props.eventState.event?.status?.code ?? '',
                    ) && (
                      <Link
                        to={
                          '/event/' +
                          this.props.match.params.handle +
                          '/team/' +
                          this.state.eventTeam?.handle +
                          '/donate'
                        }
                      >
                        Donate Now
                      </Link>
                    )}
                    <Button
                      onClick={() => {
                        this.state.members.find((m) => m.user_id === this.props.userState.user.id)
                          ? this.joinDefaultTeam()
                          : this.setState({ showConfirmModal: true });
                      }}
                      text={
                        this.state.members.find((m) => m.user_id === this.props.userState.user.id)
                          ? 'View My Page'
                          : 'Join Team'
                      }
                    />
                    <Button
                      onClick={() => {
                        this.setState({ showShareModal: true });
                      }}
                      text="Share"
                    />
                    {this.state.eventTeam?.owner_id === this.props.userState.user.id && (
                      <Link
                        to={
                          '/event/' +
                          this.props.match.params.handle +
                          '/team/' +
                          this.state.eventTeam?.handle +
                          '/manage'
                        }
                        className="manage"
                      >
                        Manage
                      </Link>
                    )}
                  </div>
                </div>
              )}
              {this.state.isTeamLoading && (
                <div className="load-wrap">
                  <Loader loading={this.state.isTeamLoading} />
                </div>
              )}
            </div>
          </div>
          <div className="main">
            <CoverImage
              currentImage={this.state.eventTeam?.cover_image_url}
              onChange={this.onImageChange}
              overlay={this.state.eventTeam?.owner_id === this.props.userState.user.id}
            />
            <div className="Team-main-content">
              {!this.state.isTeamLoading && (
                <div className="inner-content">
                  <div className="fundraise-bar">
                    <div className="details">
                      <div className="amount">
                        <span notranslate="yes">
                          {formatCurrency(
                            this.state.eventTeam?.raised || 0,
                            currency,
                            this.props.locale,
                          )}
                        </span>
                        <span>
                          raised of{' '}
                          <var data-var="goal">
                            {formatCurrency(
                              this.state.eventTeam?.goal || 0,
                              currency,
                              this.props.locale,
                            )}
                          </var>
                        </span>
                      </div>
                      <div className="percentage">
                        {this.state.eventTeam?.raised !== undefined &&
                          this.state.eventTeam.goal !== undefined && (
                            <div className="circle">
                              <span notranslate="yes">
                                {Math.min(
                                  ((this.state.eventTeam.raised || 0) /
                                    (this.state.eventTeam.goal || 1)) *
                                    100,
                                  100,
                                ).toFixed(2)}
                                %
                              </span>
                              <span>achieved</span>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="bar-wrap">
                      <div className="bar">
                        <div
                          style={{
                            width:
                              Math.min(
                                99,
                                Math.max(
                                  3,
                                  Math.round(
                                    ((this.state.eventTeam?.raised || 0) /
                                      (this.state.eventTeam?.goal || 1)) *
                                      100,
                                  ),
                                ),
                              ) + '%',
                          }}
                          className="bar-inner"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="team-info">
                    <div className="sect lead">
                      <div className="circle-wrap">
                        <Portrait
                          size={40}
                          currentImage={
                            this.state.teamOwner !== null
                              ? this.state.teamOwner.user.profile_image_url
                              : ''
                          }
                        />
                      </div>
                      <div className="team-content lead-name">
                        <span>Team Leader</span>
                        <span notranslate="yes">
                          {this.state.teamOwner !== null
                            ? this.state.teamOwner.user.display_name
                            : ''}
                        </span>
                      </div>
                    </div>
                    <div className="sect participants">
                      <div className="circle-wrap">
                        <div className="circle">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                      <div className="team-content participants-amt">
                        <span>Participants</span>
                        <span>
                          <var
                            data-var="member_count"
                            pluralize={this.state.members.length.toString()}
                          >
                            {localizeHelpers.formatNumber(
                              this.state.members.length || 0,
                              this.props.locale,
                            )}
                          </var>{' '}
                          member
                        </span>
                      </div>
                    </div>
                    <div className="sect created">
                      <div className="circle-wrap">
                        <div className="circle">
                          <i className="fa fa-calendar"></i>
                        </div>
                      </div>
                      <div className="team-content created-at">
                        <span>Created</span>
                        <span notranslate="yes">
                          {localizeHelpers.formatDate(
                            this.state.eventTeam?.created_at ?? new Date(),
                            LocaleDateFormats.LL,
                            this.props.locale,
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="story">
                    <div className="story-title">
                      <span>Story</span>
                      {this.props.userState.user.id &&
                        this.props.userState.user.id === this.state.eventTeam?.owner_id && (
                          <i
                            onClick={() => {
                              this.setState({ editStory: !this.state.editStory }, () => {
                                if (!this.state.editStory) {
                                  this.saveTeam();
                                }
                              });
                            }}
                            className={this.state.editStory ? 'fa fa-save' : 'fa fa-edit'}
                          ></i>
                        )}
                    </div>
                    {!this.state.editStory &&
                      (this.state.team.story === '' || this.state.team.story === '<p><br></p>') && (
                        <p>This team doesn't have a story yet.</p>
                      )}
                    {(this.state.editStory ||
                      (!this.state.editStory &&
                        this.state.team.story &&
                        this.state.team.story !== '<p><br></p>')) && (
                      <QuillTextEditor
                        value={this.state.team.story}
                        readOnly={!this.state.editStory}
                        preserveWhitespace={true}
                        theme={
                          this.state.editStory
                            ? uiConstants.quill.editableTheme
                            : uiConstants.quill.readOnlyTheme
                        }
                        modules={this.state.modules}
                        formats={this.state.formats}
                        onChange={this.handleStoryChange}
                      />
                    )}
                  </div>
                  <div className="members">
                    <div className="members-title">Members</div>
                    <div className="members-wrap">
                      {this.state.members.map((member, index) => {
                        typeHelpers.assertNotNullOrUndefined(member.user);

                        return (
                          <div
                            className="member"
                            key={index}
                          >
                            <Portrait
                              size={50}
                              currentImage={member.user.profile_image_url}
                            />
                            <div className="member-info">
                              <span
                                className="displayName"
                                notranslate="yes"
                              >
                                <Link
                                  to={
                                    '/event/' +
                                    this.props.match.params.handle +
                                    '/individual/' +
                                    member.user.handle
                                  }
                                >
                                  {member.user.display_name}
                                </Link>
                              </span>
                              <span className="raised">
                                <var data-var="amount_raised">
                                  {formatCurrency(member.raised || 0, currency, this.props.locale)}
                                </var>{' '}
                                raised
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {this.props.eventState.event.group && (
                    <BenefitingCharityTile group={this.props.eventState.event.group as IGroup} />
                  )}
                  <div className="donations">
                    <div className="donations-title">Donations</div>
                    {this.state.donations.length === 0 && (
                      <div className="empty">This team doesn't have any donations yet.</div>
                    )}
                    <div className="donations-wrap">
                      {this.state.donations.map((donation, index) => {
                        return (
                          <div
                            className="donation"
                            key={index}
                          >
                            <Portrait
                              size={50}
                              currentImage={donation.profile_image_url}
                            />
                            <div className="donation-info">
                              <span
                                className="displayName"
                                notranslate="yes"
                              >
                                {donation.display_name}
                              </span>
                              <span className="donated">
                                <var data-var="amount_donated">
                                  {formatCurrency(
                                    this.checkFees(donation) *
                                      (donation.amounts.amount_multiplier ?? 1),
                                    currency,
                                    this.props.locale,
                                  ) || 0}
                                </var>{' '}
                                donated
                              </span>
                              {donation.comments && (
                                <div className="note">{donation?.comments}</div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <StravaSection type="team" />
                </div>
              )}
              {this.state.isTeamLoading && (
                <div className="load-wrap">
                  <Loader loading={this.state.isTeamLoading} />
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          class="share-modal"
          show={this.state.showShareModal}
          onClose={() => {
            this.setState({ showShareModal: false });
          }}
        >
          <Share
            {...this.props}
            url={
              Config.web.REACT_APP_BASE_URL +
              '/event/' +
              this.props.match.params.handle +
              '/team/' +
              this.props.match.params.id
            }
          />
        </Modal>
        <Modal
          show={this.state.showConfirmModal}
          onClose={() => {
            this.setState({ showConfirmModal: false });
          }}
        >
          <div className="confirm">
            <p>Are you sure you want to join {this.state.eventTeam?.name}?</p>
            <div className="confirm-actions">
              <Button
                text="No"
                onClick={() => {
                  this.setState({ showConfirmModal: false });
                }}
              />
              <Button
                text="Yes"
                onClick={() => {
                  this.setState({ showConfirmModal: false });
                  this.joinDefaultTeam();
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  joinEventTeam,
  addItem,
  createToast,
  setFlow,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamDefault);
