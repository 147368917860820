import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps, Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IUserState } from '../../reducers/user';

import './ApplicationSuccess.scss';

import Portrait from '../../components/Portrait/Portrait';
import QuillTextEditor from '../../components/QuillTextEditor/QuillTextEditor';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';

import { uiConstants } from '../../constants/uiConstants';
import { FC } from 'react';
import { IAppState, useAppDispatch, useAppSelector } from '../../store';

import axios from 'axios';
import {
  errorHelpers,
  formatQuery,
  routes,
  setSEOMetatags,
  swapRouteParams,
  toastError,
  toastSuccess,
} from '../../helpers';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import CoverImage from '../../components/CoverImage/CoverImage';
import { IGigSummary, IGroup } from '@gigit/interfaces';
import { createToast } from '../../actions/toaster';
import Modal from '../../components/Modal/Modal';
import Share from '../../components/Share/Share';
import { Config } from '@gigit/config';

interface IProps extends RouteComponentProps<{ groupId: string; gigId?: string }> {}

const ApplicationSuccess: FC<IProps> = (props: IProps) => {
  const userState = useAppSelector((state) => state.userState);

  // Component mounted flag to prevent re-render race between async calls and state update
  const componentIsMounted = useRef(true);

  const [redirectToGig, setRedirectToGig] = useState<boolean>(false);
  const [redirectToGroup, setRedirectToGroup] = useState<boolean>(false);
  const [gigList, setGigList] = useState<Array<IGigSummary>>([]);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [group, setGroup] = useState<IGroup | null>(null);
  const [groupLoading, setGroupLoading] = useState<boolean>(true);
  const [gigsLoading, setGigsLoading] = useState<boolean>(true);
  const history = useHistory();

  const dispatch = useAppDispatch();

  // Init useEffect for component mount flag
  useEffect(() => {
    return () => {
      componentIsMounted.current = true;
    };
  }, []);

  // Call group data API | Dependency on group id parameter
  useEffect(() => {
    getGroupData();
  }, [props.match.params.groupId]);

  // Call gig list API | Dependency on group data API response
  useEffect(() => {
    getGigList();
  }, [group]);

  useEffect(() => {
    if (group) {
      setSEOMetatags({
        urlPath: `group/${group.handle}`,
        title: group.title,
        description: group.description,
        imageURL: group.profile_image_url,
        coverImageURL: group.cover_image_url,
      });
    }
  }, [group]);

  // Get group data
  const getGroupData = async () => {
    let _route = swapRouteParams(routes.GET_GROUP, { handleOrId: props.match.params.groupId });
    try {
      let response = await axios.get<IGroup>(_route);
      if (componentIsMounted.current) {
        setGroupLoading(false);
        setGroup(response.data);

        const toast = toastSuccess(
          localizeHelpers.translate(
            'Application submitted successfully. Someone from {{group_name}} will contact you shortly. Keep an eye on your email for shift assignments and notifications.',
            {
              group_name: response.data?.title,
            },
          ),
          'Application Form',
        );
        dispatch(createToast(toast));
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Application Success');
      dispatch(createToast(toast));
    }
  };

  // Get gig list
  const getGigList = async () => {
    let query = formatQuery({
      sort: [
        {
          id: 'created_at',
          order: 'desc',
        },
      ],
    });
    let _route = swapRouteParams(routes.GET_GROUP_GIGS_PUBLIC, { groupId: group?.id });

    _route += `?${query.toString()}`;

    if (componentIsMounted.current && group) {
      try {
        let response = await axios.get<IGigSummary[]>(_route);
        let components = response.data;
        // Filter out the gig the user just applied to from the suggested gigs
        if (props.match.params.gigId) {
          components = response.data.filter((e: IGigSummary) => e.id !== props.match.params.gigId);
        }
        let truncated = components.slice(0, 3);
        setGigList(truncated);
        setGigsLoading(false);
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Application Success');
        dispatch(createToast(toast));
        setGigsLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      {redirectToGig ? <Redirect to={'/gig/' + props.match.params.gigId} /> : ''}
      {redirectToGroup ? <Redirect to={'/group/' + props.match.params.groupId} /> : ''}
      <div className="Volunteer">
        <div className="back-splash">
          {group?.cover_image_url !== '' && (
            <img
              alt="Cover"
              src={group?.cover_image_url}
            />
          )}
          <div className="curve" />
        </div>
        <div className="Volunteer-content">
          <div className="sidebar">
            <div className="sidebar-inner">
              {group && (
                <Portrait
                  currentImage={group?.profile_image_url}
                  size={180}
                />
              )}
              {group && (
                <div className="sidebar-content">
                  <div className="sidebar-info">
                    <div className="type team">
                      <i className="far fa-users" />
                      <span>Volunteer</span>
                    </div>
                    <h1
                      className="name"
                      notranslate="yes"
                    >
                      <Link to={'/group/' + group?.handle}>{group?.title}</Link>
                    </h1>
                    <div
                      className="handle"
                      notranslate="yes"
                    >
                      @{group?.handle}
                    </div>
                    {group?.description && group?.description !== '<p><br></p>' && (
                      <div className="description">
                        <QuillTextEditor
                          value={group?.description}
                          preserveWhitespace={true}
                          readOnly={true}
                          theme={uiConstants.quill.readOnlyTheme}
                        />
                      </div>
                    )}
                  </div>
                  <div className="sidebar-actions">
                    <Button
                      onClick={() => {
                        setShowShareModal(true);
                      }}
                      text="Share"
                    />
                  </div>
                </div>
              )}
              {!group && (
                <div className="load-wrap">
                  <Loader loading={groupLoading} />
                </div>
              )}
            </div>
          </div>
          <div className="main">
            <div className="Volunteer-main-content">
              <div className="success-block">
                <div className="success-title">Thank You!</div>
                <div className="succes-check">
                  <i className="fa fa-check" />
                </div>
                <div className="success-state">Your application was successful!</div>
                <div className="success-options">
                  <Button
                    onClick={() => {
                      history.push('/dashboard?section=activity&tab=applications');
                    }}
                    text="View Applications"
                  />
                  <Button
                    onClick={() => {
                      props.match.params.gigId !== 'undefined'
                        ? setRedirectToGig(true)
                        : setRedirectToGroup(true);
                    }}
                    text="Close"
                  />
                </div>
              </div>
              <div className="gig-list">
                <div className="gig-list-title">
                  See more volunteer opportunities from this cause
                </div>
                {!gigsLoading && gigList.length == 0 && (
                  <div className="no-active-gigs">
                    There are no active volunteer opportunities for this cause.
                  </div>
                )}
                {gigList.length > 0 &&
                  gigList.map((item: IGigSummary, index: number) => {
                    return (
                      <div
                        key={index}
                        className="GigListItem"
                      >
                        <div className="content">
                          <Portrait
                            size={80}
                            currentImage={item.profile_image_url}
                          />
                          <div className="info">
                            <div
                              className="start-date"
                              notranslate="yes"
                            >
                              {localizeHelpers.formatDate(
                                item.start_date ?? new Date(),
                                LocaleDateFormats.LL,
                                userState.user?.language || 'en',
                              )}
                            </div>
                            <div
                              className="title"
                              notranslate="yes"
                            >
                              {item.title}
                            </div>
                            <div
                              className="group-name"
                              notranslate="yes"
                            >
                              {group?.title}
                            </div>
                          </div>
                          <div className="action">
                            <Link to={'/gig/' + item.handle}>View Volunteer Opportunity</Link>
                          </div>
                        </div>
                        <div className="cover">
                          <CoverImage currentImage={item.cover_image_url} />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        class="share-modal"
        show={showShareModal}
        onClose={() => {
          setShowShareModal(false);
        }}
      >
        <Share
          {...props}
          url={Config.web.REACT_APP_BASE_URL + '/group/' + group?.handle + '/volunteer'}
        />
      </Modal>
    </React.Fragment>
  );
};

export default ApplicationSuccess;
