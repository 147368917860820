import React, { useEffect, useState } from 'react';
import { IHub, ITransactionSummary } from '@gigit/interfaces';
import './ProgramDonations.scss';
import { formatCurrency, typeHelpers } from '../../../../helpers';
import Table, { ITableProps } from '../../../shared/Table/Table';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { Constants } from '@gigit/constants';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import useToastDispatcher from '../../../../hooks/useToaster';
import Button from '../../../Button/Button';
import DatePickerField from '../../../shared/forms/DatePicker/DatePickerField/DatePickerField';
import { hubRequestActions } from '../../../../requestActions';

interface IProps {
  hub?: IHub;
}

function ProgramDonations(props: IProps) {
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');

  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const { dispatchToastError } = useToastDispatcher();

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  useEffect(() => {
    if (dateFrom.length === 0 && dateTo.length === 0) {
      refreshTableData();
    }
  }, [dateFrom, dateTo]);

  async function handleExportDonations(transaction: ITransactionSummary) {
    try {
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      typeHelpers.assertNotNullOrUndefined(transaction.id, 'Expected Transaction ID');
      await hubRequestActions.getDonationReceipt(props.hub.id, transaction.id);
    } catch (error) {
      dispatchToastError(error, 'Export Donation Receipt');
    }
  }

  const tableProps: ITableProps<ITransactionSummary> = {
    columns: [
      {
        id: 'title',
        Header: 'Cause/Charity Cause',
        accessor: ({ group_title }) => group_title,
        sortable: true,
        notranslate: 'yes',
      },
      {
        id: 'amount',
        Header: 'Donation Amount',
        accessor: ({ currency_dollar_amount }) => currency_dollar_amount,
        sortable: true,
        Cell: ({ amounts, currency_dollar_amount }) => {
          return (
            <div
              className="col donation-amount"
              notranslate="yes"
            >
              <span className="amount">
                {formatCurrency(currency_dollar_amount, amounts.currency, locale)}
              </span>
            </div>
          );
        },
      },

      {
        id: 'created_at',
        Header: 'Donation Date',
        accessor: ({ created_at }) => created_at?.toString(),
        sortable: true,
        Cell: ({ created_at }) => {
          return (
            <div
              className="col donation-date"
              notranslate="yes"
            >
              <span className="date">
                {localizeHelpers.formatDate(created_at ?? new Date(), LocaleDateFormats.LL, locale)}
              </span>
            </div>
          );
        },
      },
      {
        id: 'amounts.payment_status.code',
        Header: 'Payment Status',
        accessor: ({ amounts }) =>
          amounts.payment_status?.code ? amounts.payment_status.code : 'not_applicable',
        predefinedColumnType: {
          type: 'STATUS',
          columnObject: () => ({
            [Constants.payment_status.unpaid]: {
              label: 'Unpaid',
              color: 'RED',
            },
            [Constants.payment_status.paid]: {
              label: 'Paid',
              color: 'GREEN',
            },
            not_applicable: {
              label: 'Not Applicable',
              color: 'GREY',
            },
          }),
        },
      },
    ],
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        typeHelpers.assertNotNullOrUndefined(props.hub?.id);
        if (dateFrom && dateTo) {
          return await hubRequestActions.getDonationsBetween(
            props.hub?.id,
            dateFrom,
            dateTo,
            queryParams,
          );
        } else {
          return await hubRequestActions.getDonations(props.hub?.id, queryParams);
        }
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any Donation Transactions",
    },
    tableActionOptions: {
      enableRowContextMenuActions: true,
      enableRowButtonActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Export',
          onClick: (_, transactionSummary) => handleExportDonations(transactionSummary),
        },
      ],
    },
  };

  function handleSearchDonations() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  async function handleExportBulkReceipts() {
    try {
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      if (!dateFrom || !dateTo) {
        await hubRequestActions.getBulkDonationReceipt(
          props.hub.id,
          new Date(0).toDateString(),
          new Date().toDateString(),
        );
      } else {
        await hubRequestActions.getBulkDonationReceipt(props.hub.id, dateFrom, dateTo);
      }
    } catch (error) {
      dispatchToastError(error, 'Export Donation Receipts');
    }
  }

  return (
    <>
      <div
        className="ProgramDonations"
        id="donation-transactions"
      >
        <div className="header-section">
          <h2>Donation Tax Receipts</h2>
          <div className="header-actions">
            <DatePickerField
              name="from"
              label="Filter by Date Range: From"
              value={dateFrom}
              onChange={setDateFrom}
            />
            <DatePickerField
              name="to"
              label="To"
              value={dateTo}
              onChange={setDateTo}
            />
            <Button
              onClick={handleSearchDonations}
              isDisabled={!dateFrom || !dateTo}
            >
              Search
            </Button>
            <Button
              onClick={handleExportBulkReceipts}
              buttonType="secondary"
              className="export-button"
            >
              Export
            </Button>
          </div>
        </div>
        <div className="table-section">
          <Table
            {...tableProps}
            refreshTableIncrementor={refreshTableIncrementor}
          />
        </div>
      </div>
    </>
  );
}

export default ProgramDonations;
