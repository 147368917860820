import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../store';
import { handleDebounce, handleInputChange } from '../../helpers';

import { IGroupState } from '../../reducers/group';

import {
  StoreItemOwnerType,
  findStoreCategories,
  deleteStoreCategory,
  createStoreCategory,
  updateStoreCategory,
} from '../../actions/store';

import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import ContextMenu from '../ContextMenu/ContextMenu';

import './CategoriesManagement.scss';
import { IStoreState } from '../../reducers/store';
import { IStoreCategory } from '../../../../../lib/gigit-interfaces/dist';
import { ChangeEvent } from 'react';
import { Prompt } from '../Prompt/Prompt';
import { IOwnerObject } from '../../interfaces';

interface IProps extends WithTranslation {
  owner: IOwnerObject;
  groupState: IGroupState;
  storeState: IStoreState;
  findStoreCategories(
    ownerType: StoreItemOwnerType,
    ownerId: string,
    _search?: string | undefined,
  ): void;
  createStoreCategory(
    ownerType: StoreItemOwnerType,
    ownerId: string,
    _payload: Partial<IStoreCategory>,
  ): void;
  deleteStoreCategory(
    ownerType: StoreItemOwnerType,
    ownerId: string,
    storeCategoryId: string,
  ): void;
  updateStoreCategory(
    ownerType: StoreItemOwnerType,
    ownerId: string,
    storeCategoryId: string,
    _payload: Partial<IStoreCategory>,
  ): void;
}

interface IState {
  showAddForm: boolean;
  searchValue: string;
  newCategory: Partial<IStoreCategory>;
  categoryToDelete: IStoreCategory | null;
  updatingCategory: IStoreCategory | null;
}

/** Page for Managing Store Categories, which are used to categorize store items and auction items. */
class CategoriesManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showAddForm: false,
      searchValue: '',
      newCategory: {},
      categoryToDelete: null,
      updatingCategory: null,
    };

    this.toggleAddForm = this.toggleAddForm.bind(this);
  }

  componentDidMount() {
    this.findStoreCategories();
  }

  toggleAddForm(value?: boolean) {
    this.setState({
      showAddForm: value ? value : !this.state.showAddForm,
      newCategory: {},
    });
  }

  findStoreCategories() {
    this.props.findStoreCategories(
      this.props.owner.ownerType as StoreItemOwnerType,
      this.props.owner.ownerId,
      this.state.searchValue,
    );
  }

  deleteCategory(categoryId: string) {
    this.props.deleteStoreCategory(
      this.props.owner.ownerType as StoreItemOwnerType,
      this.props.owner.ownerId,
      categoryId,
    );
  }

  createCategory(newCategory: Partial<IStoreCategory>) {
    this.props.createStoreCategory(
      this.props.owner.ownerType as StoreItemOwnerType,
      this.props.owner.ownerId,
      newCategory,
    );

    this.toggleAddForm(false);
  }

  updateCategory() {
    if (this.state.updatingCategory) {
      this.props.updateStoreCategory(
        this.props.owner.ownerType as StoreItemOwnerType,
        this.props.owner.ownerId,
        this.state.updatingCategory.id!,
        this.state.updatingCategory,
      );
    }

    this.setState({ updatingCategory: null });
  }

  changeNewCategory(change: Partial<IStoreCategory>) {
    this.setState({
      newCategory: {
        ...this.state.newCategory,
        ...change,
      },
    });
  }

  changeUpdateCategory(change: Partial<IStoreCategory>) {
    this.setState({
      updatingCategory: {
        ...(this.state.updatingCategory ?? {}),
        ...(change as IStoreCategory),
      },
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="CategoriesManagement section-wrap">
        <div className="section-title">
          <div className="section-inner-title">{t('Categories')}</div>
          <div className="section-create">
            <Button
              icon="fal fa-plus"
              onClick={() => this.toggleAddForm(true)}
              text="Add Category"
            />
          </div>
        </div>
        <div className="section-inner">
          <div className="CategoriesManagement-list">
            <div className="search">
              <TextField
                icon="fas fa-search"
                placeholder="Search Categories..."
                value={this.state.searchValue}
                type="text"
                name="searchValue"
                onChange={(e) =>
                  handleInputChange(e, this, false, () => {
                    handleDebounce(this.state.searchValue).then((res) => {
                      this.findStoreCategories();
                    });
                  })
                }
              />
            </div>
            <div className="list">
              <div className="headers">
                <div className="col code">Name</div>
                <div className="col actions"></div>
              </div>
              <div className="list-inner">
                <div className="list-rows">
                  {this.props.storeState.storeCategories &&
                    this.props.storeState.storeCategories.map((item, index) => {
                      let _menuItems = [
                        {
                          icon: 'fas fa-edit',
                          onClick: () => this.setState({ updatingCategory: item }),
                          label: t('Edit'),
                        },
                        {
                          icon: 'fas fa-trash',
                          onClick: () => this.setState({ categoryToDelete: item }),
                          label: t('Delete'),
                        },
                      ];

                      return (
                        <div
                          key={index}
                          className="row"
                        >
                          <div
                            className="col code"
                            notranslate="yes"
                          >
                            {item.name}
                          </div>
                          <div className="col actions">
                            <i className="fal fa-ellipsis-h-alt"></i>
                            <ContextMenu
                              onMouseLeave={() => {}}
                              showMenu={true}
                              menuItems={_menuItems}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {(!this.props.storeState.storeCategories ||
                    (this.props.storeState.storeCategories &&
                      this.props.storeState.storeCategories.length === 0)) && (
                    <div className="empty">{t("You haven't added any Categories, yet.")}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Modal
            title={t('Add Category')}
            show={this.state.showAddForm}
            onClose={() => {
              this.toggleAddForm(false);
            }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.createCategory(this.state.newCategory);
              }}
              className="add-category-modal"
            >
              <TextField
                name="categoryName"
                type="text"
                value={this.state.newCategory.name ?? ''}
                required={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  this.changeNewCategory({ name: e.target.value })
                }
              />

              <div className="action-buttons">
                <Button
                  className="add-button"
                  text={t('Add')}
                  type="submit"
                />
              </div>
            </form>
          </Modal>

          <Modal
            title={t('Edit Category')}
            show={this.state.updatingCategory != null}
            onClose={() => this.setState({ updatingCategory: null })}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.updateCategory();
              }}
              className="add-category-modal"
            >
              <TextField
                name="categoryName"
                type="text"
                value={this.state.updatingCategory?.name ?? ''}
                required={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  this.changeUpdateCategory({ name: e.target.value })
                }
              />

              <div className="action-buttons">
                <Button
                  className="add-button"
                  text={t('Save')}
                  type="submit"
                />
              </div>
            </form>
          </Modal>

          <Prompt
            show={this.state.categoryToDelete !== null}
            title={t('Delete Category')}
            message={`Are you sure you want to delete the Category ${this.state.categoryToDelete?.name}?`}
            yesMessage={t('Yes')}
            yesClass="fa fa-trash"
            yesStyle={t('delete')}
            cancelMessage={t('Cancel')}
            onYes={() => this.deleteCategory(this.state.categoryToDelete!.id!)}
            onClose={() => this.setState({ categoryToDelete: null })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    storeState: store.storeState,
  };
};

const mapDispatchToProps = {
  findStoreCategories: findStoreCategories,
  deleteStoreCategory: deleteStoreCategory,
  createStoreCategory: createStoreCategory,
  updateStoreCategory: updateStoreCategory,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(CategoriesManagement),
);
