import { Constants } from '@gigit/constants';
import {
  IGetMetricParams,
  IGetMetricValue,
  IHub,
  IMetricDefinition,
  MetricUOMType,
} from '@gigit/interfaces';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { defaultCurrency, formatCurrency, formatNumberCompactNotation } from '../../../helpers';
import { useLocale } from '../../../hooks';
import useToastDispatcher from '../../../hooks/useToaster';
import {
  IGetMetricParamsFE,
  IGetMetricValueFE,
  metricRequestActions,
} from '../../../requestActions/metrics';
import { userSelectors } from '../../../selectors/user';
import { IAppState } from '../../../store';
import './HubMetrics.scss';

const { metrics } = Constants;
const metricConfiguration = [
  {
    metric_id: metrics.amount_raised,
    label: 'Total Raised',
  },
  {
    metric_id: metrics.volunteer_hours_total,
    label: 'Volunteer Hours',
  },
  {
    metric_id: metrics.num_hub_groups,
    label: 'Causes',
  },
  {
    metric_id: metrics.num_hub_seat_members,
    label: 'Members',
  },
];

interface IHubMetricsProps {
  hub: IHub | null;
}

function HubMetricsComponent(props: IHubMetricsProps) {
  const userInfo = useSelector((state: IAppState) => userSelectors.getUser(state));
  const locale = useLocale();
  const { dispatchToastError } = useToastDispatcher();

  const [availableMetrics, setAvailableMetrics] = useState<IGetMetricValueFE[]>([]);

  useEffect(() => {
    if (props.hub?.id) {
      getMetrics(
        props.hub?.id,
        Constants.object_type.hub,
        props.hub?.localization?.currency || Constants.default_group_reporting_currency,
      );
    }
  }, [props.hub?.id, userInfo.account?.currency]);

  async function getMetrics(objectId: string, objectType: string, currency?: string) {
    try {
      const availableMetrics =
        await metricRequestActions.getAvailableMetricsForObjectType(objectType);
      const filteredMetrics = availableMetrics.filter(({ metric }) =>
        metricConfiguration.find((metricConfig) => metricConfig.metric_id === metric),
      );
      const bulkMetricsRequestObjects = filteredMetrics.map((metric) => {
        let metricParams: IGetMetricParamsFE = {
          object_id: objectId,
          object_type: objectType,
          metric: metric.metric,
        };

        if (currency) {
          metricParams.currency = currency;
        }

        return metricParams;
      });

      const metricsBulkResponse = await metricRequestActions.getMetricsBulk({
        metrics: bulkMetricsRequestObjects,
      });

      setAvailableMetrics(metricsBulkResponse.metrics);
    } catch (error) {
      // dispatchToastError(error, 'Hub Metrics');
    }
  }

  function formatMetricValue(metric: IGetMetricValueFE) {
    switch (metric.unit_type as MetricUOMType) {
      case 'currency':
        const formattedVal = formatCurrency(
          metric.value,
          props.hub?.localization?.currency || defaultCurrency,
          '',
          // Commented out locale as a param because based on language the
          // currency designation is either on start or the end of a value
          // which messes with the substring function below
          //locale.currentLocale,
          { numberFormatOptions: { notation: 'standard', minimumFractionDigits: 0 } },
        );
        return formattedVal.substring(formattedVal.indexOf('$'));
      case 'count':
      case 'hours':
        return formatNumberCompactNotation(metric.value);
    }
  }

  function renderMetric(metric: IGetMetricValueFE, key: number) {
    const metricConfig = metricConfiguration.find((config) => config.metric_id === metric.metric);

    return (
      <div
        key={key}
        className="Metric"
      >
        <span className="data-label">{formatMetricValue(metric)}</span>
        <span className="title">
          {metricConfig?.label}
          {metric.unit_type === 'currency' && (
            <span>
              {' '}
              (<var data-var="currency_code">{metric.unit.toUpperCase()}</var>)
            </span>
          )}
        </span>
      </div>
    );
  }

  return (
    <div className="HubMetrics">
      {availableMetrics.map((metric, idx) => renderMetric(metric, idx))}
    </div>
  );
}

export default HubMetricsComponent;
