import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../store';
import { componentConfig } from './config';

import { IGroupState } from '../../reducers/group';

import Button from '../Button/Button';

import './ComponentLibrary.scss';
import Modal from '../Modal/Modal';
import { IOwnerObject } from '../../interfaces/ownerObject';
import { IEventSummaryFE } from '@gigit/interfaces';

interface IProps extends WithTranslation {
  groupState: IGroupState;
  event?: IEventSummaryFE | null;
  onContinue(type: string): void;
  onCancel(): void;
  owner: IOwnerObject;

  show: boolean;
  onClose(): void;
}

interface IState {
  components: Array<any>;
  selected: string;
}

class ComponentLibrary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      components: componentConfig,
      selected: '',
    };

    this.setSelected = this.setSelected.bind(this);
  }

  setSelected(_type: string) {
    this.setState({
      selected: _type,
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onClose={this.props.onClose}
        title="Component Library"
      >
        <div className="Component-Library">
          <div className="inner">
            {componentConfig.map((config, index) => {
              if (config.type === 'sponsors' && this.props.event?.hub_id) {
                return null;
              } else if (
                (config.type === 'auction' && this.props.event?.status?.code === 'completed') ||
                (config.type === 'auction' && this.props.event?.status?.code === 'closed')
              ) {
                return null;
              } else if (config.requires.includes(this.props.owner.ownerType)) {
                return (
                  <div
                    key={index}
                    className="component-wrap"
                  >
                    <div
                      onClick={() => this.setSelected(config.type)}
                      className={
                        this.state.selected === config.type
                          ? 'component-item active'
                          : 'component-item'
                      }
                    >
                      <div className="icon-slot">
                        <i className={config.icon}></i>
                      </div>
                      <div className="info">
                        <div className="component-title">{config.title}</div>
                        <div className="desc">{config.description}</div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className="actions">
            <Button
              onClick={() => this.props.onCancel()}
              text="Cancel"
            />
            <Button
              isDisabled={this.state.selected ? false : true}
              onClick={() => this.props.onContinue(this.state.selected)}
              text="Continue"
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
  };
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ComponentLibrary),
);
