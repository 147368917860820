import React, { ChangeEvent, useState } from 'react';
import { capitalizeString } from '../../../../helpers';
import { IEntityBaseInfo } from '../interfaces';
import { uiConstants } from '../../../../constants';
import Dropdown from '../../../Dropdown/Dropdown';
import TextArea from '../../../TextArea/TextArea';
import TextField from '../../../TextField/TextField';
import { passwordStrength } from 'check-password-strength';

import './UserInfo.scss';
import { IBaseUserInfo } from '../../../../routes/Register/Register';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Checkbox from '../../../Checkbox/Checkbox';

interface IProps {
  userInfo: IBaseUserInfo;

  onUserInfoUpdated(update: IBaseUserInfo): void;
}

export const UserInfo: React.FC<IProps> = (props: IProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [passwordStrengthValue, setPasswordStrengthValue] = useState<string>('');
  const [passwordContains, setPasswordContains] = useState<string[]>([]);
  const [changingDisplayNameDirectly, setChangingDisplayNameDirectly] = useState<boolean>(false);

  useEffect(() => {
    if (props.userInfo.password) {
      handlePassword(props.userInfo.password);
    }
  }, []);

  function updateUserInfo(update: Partial<IBaseUserInfo>) {
    const newData = { ...props.userInfo, ...update };
    props.onUserInfoUpdated(newData);
  }

  function getDisplayNameValue() {
    if (props.userInfo.displayName) {
      return props.userInfo.displayName;
    } else if (
      props.userInfo.firstName &&
      props.userInfo.lastName &&
      !changingDisplayNameDirectly
    ) {
      return `${props.userInfo.firstName} ${props.userInfo.lastName}`;
    } else {
      return '';
    }
  }

  function isPasswordValid(value: string): boolean {
    const password = passwordStrength(value);
    const strength = password?.value;
    const contains = password?.contains;

    setPasswordContains(contains);
    setPasswordStrengthValue(strength);
    return strength !== 'Too weak';
  }

  function handlePassword(password: string) {
    isPasswordValid(password);
    updateUserInfo({ password: password });
  }

  function doesPasswordContain(rule: string) {
    return passwordContains.find((value) => value === rule);
  }

  return (
    <div className="UserInfo">
      <div className="row">
        <TextField
          required={true}
          label="First Name"
          value={props.userInfo.firstName}
          name="firstName"
          type="text"
          onChange={(e) => {
            updateUserInfo({ firstName: e.target.value });
            setChangingDisplayNameDirectly(false);
          }}
        />
      </div>
      <div className="row">
        <TextField
          required={true}
          label="Last Name"
          value={props.userInfo.lastName}
          name="lastName"
          type="text"
          onChange={(e) => {
            updateUserInfo({ lastName: e.target.value });
            setChangingDisplayNameDirectly(false);
          }}
        />
      </div>
      <div className="row">
        <TextField
          required={true}
          label="Display Name"
          value={getDisplayNameValue()}
          name="displayName"
          type="text"
          onChange={(e) => {
            updateUserInfo({ displayName: e.target.value });
            setChangingDisplayNameDirectly(true);
          }}
        />
      </div>
      <div className="row">
        <TextField
          required={true}
          label="Email"
          value={props.userInfo.email}
          name="email"
          type="email"
          onChange={(e) => {
            updateUserInfo({ email: e.target.value });
          }}
        />
      </div>
      <div className={`row pwd-row`}>
        <TextField
          className={passwordStrengthValue}
          required={true}
          label="Password"
          value={props.userInfo.password}
          autoComplete="new-password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => {
            handlePassword(e.target.value);
          }}
        />
        {showPassword && (
          <i
            className="fa fa-eye-slash"
            onClick={() => setShowPassword(false)}
          />
        )}
        {!showPassword && (
          <i
            className="fa fa-eye"
            onClick={() => setShowPassword(true)}
          />
        )}
      </div>
      <span>Password Criteria: </span>
      <ul>
        <li className={doesPasswordContain('uppercase') ? 'valid' : 'invalid'}>
          An uppercase character
        </li>
        <li className={doesPasswordContain('lowercase') ? 'valid' : 'invalid'}>
          A lowercase character
        </li>
        <li className={doesPasswordContain('number') ? 'valid' : 'invalid'}>A number</li>
        <li className={doesPasswordContain('symbol') ? 'valid' : 'invalid'}>A special character</li>
      </ul>
      <div className="age-verify-row">
        <Checkbox
          name="is18yearsOld"
          label={'I am 18 years of age or older.*'}
          value={props.userInfo.isOfAge ? 'yes' : 'no'}
          checked={props.userInfo.isOfAge ? true : false}
          onChange={() => {
            props.userInfo.isOfAge
              ? updateUserInfo({ isOfAge: false })
              : updateUserInfo({ isOfAge: true });
          }}
        />
      </div>
      <div className="user-agreement">
        <span className="eula">
          By completing this registration, you are agreeing to the{' '}
          <a
            href={uiConstants.urls.serviceAgreement}
            target="_blank"
          >
            Service Agreement
          </a>{' '}
          and the{' '}
          <a
            href={uiConstants.urls.eula}
            target="_blank"
          >
            End-User Licenses.
          </a>{' '}
          This includes the requirement that you are 13 years old or older, or have parental consent
          to use the Kambeo platform.{' '}
        </span>
      </div>
    </div>
  );
};
