import React from 'react';
import Joyride from 'react-joyride';
import queryString from 'query-string';
import { Config } from '@gigit/config';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { TwitchEmbed } from 'react-twitch-embed';
import { IAppState } from '../../store';
import { IUserState } from '../../reducers/user';
import { IEventState } from '../../reducers/event';
import { getEvent } from '../../actions/event';
import { IStep, IToast } from '../../interfaces';
import Portrait from '../../components/Portrait/Portrait';
import Button from '../../components/Button/Button';
import IndividualDefault from './IndividualDefault/IndividualDefault';
import IndividualStreamer from './IndividualStreamer/IndividualStreamer';
import Modal from '../../components/Modal/Modal';
import Share from '../../components/Share/Share';
import './Individual.scss';
import {
  errorHelpers,
  IStringMap,
  mapPermissions,
  routes,
  setSEOMetatags,
  swapRouteParams,
  toastError,
} from '../../helpers';
import { uiConstants } from '../../constants/uiConstants';
import axios from 'axios';
import { createToast } from '../../actions/toaster';
import { IEventIndividual, IEventTeam } from '@gigit/interfaces';
import { individualRequestActions } from '../../requestActions/individual';
import { teamRequestActions } from '../../requestActions/team';
import { BackSplash } from '../../components/ProfilePageComponents/BackSplash/BackSplash';
import { updateUserCoverImage } from '../../actions/user';

interface IPassedProps extends RouteComponentProps<any> {
  userState: IUserState;
  eventState: IEventState;
  getEvent(_handle: string): void;
  createToast(toast: IToast): void;
}

interface IPropsFromDispatch {
  updateUserCoverImage(img: string): void;
}

type IProps = IPassedProps & IPropsFromDispatch;

interface IState {
  headerClass: string;
  formats: Array<any>;
  modules: any;
  showShareModal: boolean;
  editStory: boolean;
  individual: any;
  notFound: boolean;
  subHeaderRef: any;
  subHeaderInitialPosition: number;
  userPermissions: IStringMap;
  showTwitchChat: boolean;
  innerWidth: number;
  steps: IStep[];
  needATour: boolean;
  team: IEventTeam | null;
  isTeamLoading: boolean;
  eventIndividual: IEventIndividual | null;
  isIndividualLoading: boolean;
}

const StreamEmbed = React.memo((props: any) => {
  return <TwitchEmbed {...props} />;
});

class Team extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      headerClass: '',
      modules: {
        toolbar: uiConstants.quill.defaultToolbar,
        clipboard: {
          matchVisual: false,
        },
      },
      userPermissions: {},
      formats: uiConstants.quill.defaultFormats,
      showShareModal: false,
      editStory: false,
      individual: {
        story: '',
      },
      notFound: false,
      subHeaderRef: React.createRef(),
      subHeaderInitialPosition: -1,
      showTwitchChat: true,
      innerWidth: window.innerWidth,
      steps: [
        {
          disableBeacon: true,
          target: '.main .story',
          content: 'Tell people why you are raising money to attract more donations.',
        },
        {
          disableBeacon: true,
          target: '.manage',
          content: 'Manage your fundraising goal and other settings for your fundraiser.',
        },
      ],
      needATour: false,
      team: null,
      isTeamLoading: false,
      eventIndividual: null,
      isIndividualLoading: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleStoryChange = this.handleStoryChange.bind(this);
    this.isContentCreator = this.isContentCreator.bind(this);
    this.isContentCreatorSuspended = this.isContentCreatorSuspended.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.suspendContentCreator = this.suspendContentCreator.bind(this);
  }

  componentDidMount() {
    (window as any).prerenderReady = false;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);

    this.props.getEvent(this.props.match.params.eventHandle);
    this.handleResize();

    let _params = queryString.parse(this.props.location.search);

    this.setState({
      needATour: !!_params.help,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    if (window.innerWidth <= 1240 && this.state.showTwitchChat) {
      this.setState({
        showTwitchChat: false,
      });
    } else if (window.innerWidth > 1240 && !this.state.showTwitchChat) {
      this.setState({
        showTwitchChat: true,
      });
    }

    this.setState({
      innerWidth: window.innerWidth,
    });
  }

  handleScroll() {
    if (
      window.scrollY >= this.state.subHeaderInitialPosition &&
      this.state.headerClass !== 'fixed'
    ) {
      this.setState({
        headerClass: 'fixed',
      });
    } else if (
      window.scrollY < this.state.subHeaderInitialPosition &&
      this.state.headerClass !== ''
    ) {
      this.setState({
        headerClass: '',
      });
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.state.subHeaderRef.current !== null && this.state.subHeaderInitialPosition === -1) {
      this.setState({
        subHeaderInitialPosition: this.state.subHeaderRef.current.offsetTop,
      });
    }

    if (
      this.props.eventState.event !== prevProps.eventState.event &&
      this.props.eventState.event.id !== ''
    ) {
      this.getIndividual(this.props.eventState.event.id, this.props.match.params.individualHandle);
    }

    if (
      this.props.eventState.teams[0] !== prevProps.eventState.teams[0] &&
      typeof this.props.eventState.teams[0] !== 'undefined'
    ) {
      this.getTeam(this.props.eventState.event.id, this.props.eventState.teams[0].handle);
    }

    if (prevProps.eventState.isCurrentRoleLoading !== this.props.eventState.isCurrentRoleLoading) {
      let permissions: IStringMap = mapPermissions(
        this.props.eventState.currentUserRole.permissions,
      );
      this.setState({
        userPermissions: permissions,
      });
    }

    if (
      this.props.eventState.event.id !== prevProps.eventState.event.id &&
      this.props.eventState.event.id
    ) {
      this.getIndividual(this.props.eventState.event.id, this.props.match.params.individualHandle);
    }

    if (
      prevState.isIndividualLoading &&
      !this.state.isIndividualLoading &&
      this.state.eventIndividual?.id === '' &&
      !this.props.userState.awaitingToken
    ) {
      this.setState({
        notFound: true,
      });
    }

    if (
      this.state.eventIndividual !== prevState.eventIndividual &&
      this.state.eventIndividual?.id !== ''
    ) {
      if (this.state.eventIndividual?.user != null) {
        const {
          handle: userHandle,
          first_name,
          last_name,
          profile_image_url,
          cover_image_url,
        } = this.state.eventIndividual.user;
        const { handle: eventHandle } = this.props.eventState.event;

        setSEOMetatags({
          urlPath: `event/${eventHandle}/individual/${userHandle}`,
          title: `${first_name} ${last_name}`,
          description: undefined,
          imageURL: profile_image_url,
          coverImageURL: cover_image_url,
        });
      }

      this.setState({
        individual: this.state.eventIndividual,
        headerClass: '',
      });
    }
  }

  handleStoryChange(content: string) {
    this.setState({
      individual: {
        ...this.state.individual,
        story: content,
      },
    });
  }

  saveIndividual() {
    this.updateIndividual(this.props.eventState.event.id, this.props.userState.user.id!, {
      story: this.state.individual.story,
    });
  }

  hasDonationMatching() {
    return (
      this.props.eventState.event?.donations_matching &&
      this.props.eventState.event.donations_matching?.organization &&
      this.props.eventState.event.donations_matching?.max_amount &&
      this.props.eventState.event.donations_matching?.ratio &&
      this.props.eventState.event.donations_matching?.ratio_description
    );
  }

  suspendContentCreator(suspend: boolean) {
    let _route = swapRouteParams(routes.CHANGE_SUSPEND_CONTENT_CREATOR, {
      eventId: this.props.eventState.event.id,
      userId: this.state.eventIndividual?.user_id,
    });

    let _payload = {
      suspend: {
        value: suspend,
      },
    };
    axios
      .put(_route, _payload)
      .then((response) => {
        this.updateIndividual(this.props.eventState.event.id, this.props.userState.user.id!, {
          story: this.state.individual.story,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        this.props.createToast(toastError(errorObj.translatedMessage, 'Individual'));
      });
  }

  async updateIndividual(eventId: string, userId: string, payload: Partial<IEventIndividual>) {
    try {
      await individualRequestActions.updateIndividual(eventId, payload);
      this.getIndividual(eventId, userId);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Individual');
      createToast(toast);
    }
  }

  async getIndividual(eventId: string, userId: string) {
    try {
      const eventIndividual = await individualRequestActions.getIndividual(eventId, userId);
      this.setState({ eventIndividual });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Individual');
      createToast(toast);
    }
  }

  async getTeam(eventId: string, teamId: string) {
    try {
      const team = await teamRequestActions.getTeam(eventId, teamId);
      this.setState({ team });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Team');
      createToast(toast);
    }
  }

  isContentCreatorSuspended() {
    return this.state.eventIndividual?.suspended;
  }

  isContentCreator() {
    return this.state.individual.content_creator && this.state.individual.user.twitch;
  }

  onImageChange = (fileData: { file: string; type: string }) => {
    if (fileData.type === 'cover_image_url') {
      this.props.updateUserCoverImage(fileData.file);
    }
  };

  render() {
    let _joyrideStyles = {
      options: {
        arrowColor: '#FFF',
        backgroundColor: '#FFF',
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: '#5E51AB',
        textColor: '#505050',
      },
    };

    if (this.state.notFound) {
      return <Redirect to="/not-found" />;
    } else {
      return (
        <div className={this.isContentCreator() ? 'Individual content-creator' : 'Individual'}>
          {this.state.needATour &&
            !this.isContentCreator() &&
            this.state.individual.user_id &&
            !this.state.isIndividualLoading && (
              <Joyride
                locale={{ back: 'Back', close: 'Close', last: 'Last', next: 'Next', skip: 'Skip' }}
                continuous={true}
                disableScrolling={true}
                styles={_joyrideStyles}
                steps={this.state.steps}
                callback={(state: any) => {
                  if (state.status === 'finished') {
                    this.props.history.replace(
                      '/event/' +
                        this.props.eventState.event.handle +
                        '/individual/' +
                        this.state.eventIndividual?.user?.handle,
                    );
                    this.setState({ needATour: false });
                  }
                }}
              />
            )}
          <BackSplash
            coverImageUrl={this.state.eventIndividual?.user?.cover_image_url}
            showChat={false}
            onImageChange={this.onImageChange}
            showEditCover={this.state.userPermissions['EDIT_EVENT_PAGES']}
          />
          {this.isContentCreator() && (
            <div
              ref={this.state.subHeaderRef}
              className={'sub-header ' + this.state.headerClass}
            >
              <div className="sub-header-inner">
                <div className="row left">
                  <Portrait
                    size={60}
                    currentImage={this.state.eventIndividual?.user?.profile_image_url}
                  />
                  <div className="streamer-details">
                    <div className="user-type">
                      <i className="fas fa-user" />
                      <span>INDIVIDUAL</span>
                    </div>
                    <h1
                      className="name"
                      notranslate="yes"
                    >
                      {this.state.eventIndividual?.user?.display_name}
                    </h1>
                  </div>
                </div>
                <div className="row right">
                  <Button
                    className="share"
                    icon="fal fa-share-alt"
                    text=""
                    onClick={() => {
                      this.setState({ showShareModal: true });
                    }}
                  />
                  <div className="text-buttons">
                    {this.state.eventIndividual?.content_creator &&
                      !this.isContentCreatorSuspended() &&
                      this.state.userPermissions['MANAGE_ATTENDEES'] && (
                        <Button
                          className="suspend"
                          text="Suspend Stream"
                          onClick={() => {
                            this.suspendContentCreator(true);
                          }}
                        />
                      )}
                    {this.state.eventIndividual?.content_creator &&
                      this.isContentCreatorSuspended() &&
                      this.state.userPermissions['MANAGE_ATTENDEES'] && (
                        <Button
                          className="suspend"
                          text="Un-Suspend Stream"
                          onClick={() => {
                            this.suspendContentCreator(false);
                          }}
                        />
                      )}
                    <Link
                      to={
                        '/event/' +
                        this.state.eventIndividual?.event?.handle +
                        '/individual/' +
                        this.state.eventIndividual?.user?.handle +
                        '/donate'
                      }
                      className="donate"
                    >
                      Donate
                    </Link>
                    {this.props.userState.user.handle ===
                      this.props.match.params.individualHandle && (
                      <Link
                        className="manage"
                        to={
                          '/event/' +
                          this.state.eventIndividual?.event?.handle +
                          '/individual/' +
                          this.state.eventIndividual?.user?.handle +
                          '/manage'
                        }
                      >
                        Manage
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.isContentCreator() && !this.isContentCreatorSuspended() && (
            <div className="stream-embed">
              <StreamEmbed
                withChat={this.state.showTwitchChat}
                width="100%"
                height={this.state.showTwitchChat ? 484 : this.state.innerWidth * 0.5625}
                channel={this.state.individual.user.twitch.login}
                id={this.state.individual.user.twitch.login}
                theme="light"
                muted
              />
            </div>
          )}
          {this.isContentCreator() && this.isContentCreatorSuspended() && (
            <div className="stream-embed">
              <div
                className="suspended-stream"
                style={{
                  backgroundImage: `url(${this.state.eventIndividual?.user?.cover_image_url})`,
                }}
              >
                <div
                  notranslate="yes"
                  className="suspend-heading"
                >
                  Sorry this content is currently unavailable.
                </div>
                <div
                  notranslate="yes"
                  className="suspend-sub-heading"
                >
                  Thank you for your patience
                </div>
                <Link
                  notranslate="yes"
                  className="suspend-link"
                  to={'/event/' + this.state.eventIndividual?.event?.handle}
                >
                  Return to Event
                </Link>
              </div>
            </div>
          )}
          {!this.isContentCreator() && (
            <IndividualDefault
              {...this.props}
              individual={this.state.individual}
            />
          )}
          {this.isContentCreator() && (
            <IndividualStreamer
              {...this.props}
              individual={this.state.individual}
            />
          )}
          <Modal
            class="share-modal"
            show={this.state.showShareModal}
            onClose={() => {
              this.setState({ showShareModal: false });
            }}
          >
            <Share
              {...this.props}
              url={
                Config.web.REACT_APP_BASE_URL +
                '/event/' +
                this.state.eventIndividual?.event?.handle +
                '/individual/' +
                this.state.eventIndividual?.user?.handle
              }
            />
          </Modal>
        </div>
      );
    }
  }

  renderTeam() {
    const teamUrl =
      '/event/' + this.state.eventIndividual?.event?.handle + '/team/' + this.state.team?.handle;
    return (
      <div className="team">
        <i className="far fa-users" />
        {!this.state.isTeamLoading && this.state.team?.handle && (
          <Link to={teamUrl}>{this.state.team.name}</Link>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {
  getEvent,
  createToast,
  updateUserCoverImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
