import './HubModalAddComponents.scss';
import React from 'react';
import Modal from '../../../Modal/Modal';

interface IProps {
  show: boolean;
  onClose(): void;
}

const HubModalAddComponents: React.FC<IProps> = (props) => {
  return (
    <Modal
      key="hub-modal-add-components"
      class="HubModalAddComponents"
      title="Add Components"
      show={props.show}
      onClose={props.onClose}
    >
      add content ...
    </Modal>
  );
};

export default HubModalAddComponents;
