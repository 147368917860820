import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  handleInputChange,
  defaultCurrency,
  toastSuccess,
  routes,
  swapRouteParams,
  toastError,
  formatQuery,
  capitalizeString,
  toastInfo,
  handleDebounce,
} from '../../helpers';

import TextField from '../../components/TextField/TextField';
import Portrait from '../../components/Portrait/Portrait';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';

import { IGroupState } from '../../reducers/group';
import { getGroupStats } from '../../actions/group';
import { getEventStats } from '../../actions/event';

import { createToast } from '../../actions/toaster';

import './AuctionManagement.scss';
import { IEventState } from '../../reducers/event';
import { IAuctionItem, IAuctionItemSummary } from '@gigit/interfaces';
import AuctionItemForm from '../AuctionItemForm/AuctionItemForm';
import auctionItemHelpers from '../PageComponent/AuctionItem/auctionItemHelpers';
import { Constants } from '@gigit/constants';
import KpiDisplay from '../KpiDisplay/KpiDisplay';

import { formatCurrency } from '../../helpers';
import { IActiveFilter, IKpi, ISortSettings, IToast, StatisticType } from '../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import SortableTable, { ISortableTableActions } from '../SortableTable/SortableTable';
import axios from 'axios';
import errorHelpers from '../../helpers/errorHelpers';
import FilterData from '../FilterData/FilterData';
import { Prompt } from '../Prompt/Prompt';
import AuctionEditOrderModal from './AuctionEditOrderModal/AuctionEditOrderModal';
import AuctionAdminLeaderboardModal from './AuctionAdminLeaderboardModal/AuctionAdminLeaderboardModal';
import { IOwnerObject } from '../../interfaces';

interface IPassedProps extends WithTranslation, RouteComponentProps<any, any, any> {
  owner: IOwnerObject;
}
interface IPropsFromDispatch {
  createToast(toast: IToast): void;
  getEventStats(eventId: string, statisticType: StatisticType): void;
  getGroupStats(groupId: string, statisticType: StatisticType): void;
}

interface IPropsFromState {
  groupState: IGroupState;
  eventState: IEventState;
  locale: string;
}

type IProps = IPassedProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  searchValue: string;
  filters: IActiveFilter[];
  showFilterModal: boolean;
  sort: ISortSettings[];
  auctionItems: IAuctionItemSummary[];
  selectedRowIndices: number[];
  auctionItemsLoading: boolean;

  showAddModal: boolean;
  showModalContextMenu: boolean;
  copyItem: number;
  itemName: string;
  editItem: number;
  confirmDeleteModal: boolean;
  deleteItem: number;
  showLeaderModal: boolean;
  leaderItem: IAuctionItemSummary | null;
  errors: { [index: string]: string };
  isValid: boolean;

  pendingItems: string[];
  pendingBidders: string[];
  purchaseTimeout: any;
  auctionItemsToCharge: IAuctionItem[];
  auctionItemsBeingCharged: IAuctionItem[];
  auctionItemChargeProgressIndex: number;

  auctionItemSelectedForError: IAuctionItem | null;
  editOrderAuctionItem: IAuctionItem | null;
}

class AuctionManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      filters: [],
      showFilterModal: false,
      sort: [{ id: 'created_at', order: 'desc' }],
      searchValue: '',
      auctionItems: [],
      selectedRowIndices: [],
      auctionItemsLoading: false,

      copyItem: -1,
      showAddModal: false,
      showModalContextMenu: false,
      itemName: '',
      editItem: -1,
      confirmDeleteModal: false,
      deleteItem: -1,
      showLeaderModal: false,
      leaderItem: null,
      errors: {},
      isValid: false,
      pendingItems: [],
      pendingBidders: [],
      purchaseTimeout: false,
      auctionItemsToCharge: [],
      auctionItemsBeingCharged: [],
      auctionItemChargeProgressIndex: 0,
      auctionItemSelectedForError: null,
      editOrderAuctionItem: null,
    };

    this.handleSuccessfulPayment = this.handleSuccessfulPayment.bind(this);
  }

  componentDidMount() {
    this.fetchAuctionItems();

    if (this.props.owner.ownerType === 'event') {
      this.props.getEventStats(this.props.eventState.event.id, StatisticType.AUCTION);
    } else {
      this.props.getGroupStats(this.props.groupState.group.id, StatisticType.AUCTION);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      !this.state.purchaseTimeout &&
      this.state.pendingItems.length > 0 &&
      this.state.pendingBidders.length > 0
    ) {
      this.setState({
        purchaseTimeout: setTimeout(() => {
          this.fetchAuctionItems({
            callback: () => {
              this.setState({
                pendingItems: [],
                pendingBidders: [],
                purchaseTimeout: false,
              });
            },
          });
        }, 8000),
      });
    }

    if (this.state.filters !== prevState.filters || this.state.sort !== prevState.sort) {
      this.fetchAuctionItems();
    }

    if (this.state.auctionItems !== prevState.auctionItems) {
      this.setState({
        selectedRowIndices: [],
      });
    }
  }

  componentWillUnmount() {
    if (this.state.purchaseTimeout) {
      clearTimeout(this.state.purchaseTimeout);
    }
  }

  getKpi() {
    let data: IKpi[] = [];
    const auctionStats =
      this.props.owner.ownerType === 'event'
        ? this.props.eventState.auctionStats
        : this.props.groupState.auctionStats;
    const currency = this.props.owner.account?.currency ?? defaultCurrency;

    if (auctionStats) {
      data.push(
        {
          value: localizeHelpers.formatNumber(
            auctionStats.total_number_of_items || 0,
            this.props.locale,
          ),
          text: 'Number of Items',
        },
        {
          value: `${formatCurrency(auctionStats.total_retail_value, currency, this.props.locale)}`,
          text: 'Total Retail Value',
        },
        {
          value: `${formatCurrency(auctionStats.average_retail_value, currency, this.props.locale)}`,
          text: 'Average Retail Value',
        },
        {
          value: `${formatCurrency(auctionStats.total_minimum_bid_value, currency, this.props.locale)}`,
          text: 'Total Minimum Bid Value',
        },
      );
    }

    if (data.length && !this.props.eventState.isStatsLoading) {
      return (
        <div className="section-kpi">
          <KpiDisplay kpiDataList={data} />
        </div>
      );
    } else {
      return null;
    }
  }

  getOwnerId() {
    if (this.props.owner.ownerType === 'group') {
      return this.props.groupState.group.id;
    } else {
      return this.props.eventState.event.id;
    }
  }

  copy(copyItem: number) {
    this.setState({ copyItem, showAddModal: true });
  }

  handleSuccessfulPayment(userId: string, itemId: string) {
    let _pendingItems: string[] = [...this.state.pendingItems];
    let _pendingBidders: string[] = [...this.state.pendingBidders];

    if (this.state.leaderItem) {
      _pendingItems.push(itemId);
      _pendingBidders.push(userId);
    }

    this.setState({
      pendingItems: _pendingItems,
      pendingBidders: _pendingBidders,
    });
  }

  fetchAuctionItems(options?: { callback?: () => void }) {
    const route =
      this.props.owner.ownerType === 'group'
        ? routes.GET_GROUP_AUCTION_ITEMS_ADMIN
        : routes.GET_EVENT_AUCTION_ITEMS_ADMIN;
    const idField = this.props.owner.ownerType === 'group' ? 'groupId' : 'eventId';

    let _route = swapRouteParams(route, { [idField]: this.props.owner.ownerId });

    const params = formatQuery({
      filters: this.state.filters,
      sort: this.state.sort,
      search: this.state.searchValue,
    });

    const paramsString = params.toString();
    if (paramsString) {
      _route = `${_route}?${paramsString}`;
    }

    this.setState({
      auctionItemsLoading: true,
    });

    axios
      .get<IAuctionItemSummary[]>(_route)
      .then((response) => {
        this.setState(
          {
            auctionItems: response.data,
          },
          () => {
            if (this.state.leaderItem) {
              this.setState({
                leaderItem:
                  this.state.auctionItems.find((item) => item.id === this.state.leaderItem?.id) ??
                  null,
              });
            }
            options?.callback?.();
          },
        );
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        this.props.createToast(toastError(errorObj.translatedMessage, 'Fetch Auction Items'));
      })
      .finally(() => {
        this.setState({
          auctionItemsLoading: false,
        });
      });
  }

  async deleteAuctionItem(deleteAuctionItem: IAuctionItem) {
    const route =
      this.props.owner.ownerType === 'group'
        ? routes.UPDATE_GROUP_AUCTION_ITEM
        : routes.UPDATE_EVENT_AUCTION_ITEM;
    const idField = this.props.owner.ownerType === 'group' ? 'groupId' : 'eventId';

    try {
      await axios.delete(
        swapRouteParams(route, { [idField]: this.props.owner.ownerId, id: deleteAuctionItem.id }),
      );

      this.props.createToast(
        toastSuccess(
          localizeHelpers.translate('Auction Item Deleted successfully'),
          'Delete Auction Item',
        ),
      );

      this.props.owner.ownerType === 'group'
        ? this.props.getGroupStats(this.getOwnerId(), StatisticType.AUCTION)
        : this.props.getEventStats(this.props.eventState.event.id, StatisticType.AUCTION);

      this.fetchAuctionItems();
    } catch (err) {
      const errorObj = errorHelpers.getErrorObject(err);
      this.props.createToast(toastError(errorObj.translatedMessage, 'Delete Auction Item'));
    }
  }

  async chargeCards() {
    const auctionItemsToCharge = this.state.auctionItemsToCharge;
    let failedCardCount = 0;
    let skippedCardCount = 0;

    this.setState({
      auctionItemsBeingCharged: auctionItemsToCharge,
      auctionItemChargeProgressIndex: 0,
    });

    for (const auctionItem of auctionItemsToCharge) {
      try {
        if (!this.canItemBeCharged(auctionItem)) {
          skippedCardCount++;
          continue;
        }

        const url = swapRouteParams(routes.CHARGE_EVENT_AUCTION_ITEM_WINNER, {
          eventId: this.props.owner.ownerId,
          itemId: auctionItem.id,
        });
        await axios.post(url);
      } catch (err) {
        const errorObj = errorHelpers.getErrorObject(err);
        const toast = toastError(errorObj.translatedMessage, 'Charge Auction Item');
        this.props.createToast(toast);

        failedCardCount++;
      } finally {
        this.setState({
          auctionItemChargeProgressIndex: this.state.auctionItemChargeProgressIndex + 1,
        });
      }
    }

    this.setState({
      auctionItemsBeingCharged: [],
      auctionItemChargeProgressIndex: 0,
    });

    const successCount = auctionItemsToCharge.length - failedCardCount;
    const toast = toastInfo(
      localizeHelpers.translate(
        'Charging Cards completed. {{success_count}} cards were successfully charged. {{skipped_count}} cards were skipped (Not Eligible for direct charges). {{failure_count}} cards failed to charge.',
        {
          success_count: successCount,
          skipped_count: skippedCardCount,
          failure_count: failedCardCount,
        },
      ),
      'Charge Auction Items',
    );
    this.props.createToast(toast);

    this.fetchAuctionItems();
  }

  toggleSelectRow(toggleRowIndex: number) {
    const isSelected =
      this.state.selectedRowIndices.findIndex((rowIndex) => rowIndex === toggleRowIndex) !== -1;

    this.setState({
      selectedRowIndices: isSelected
        ? this.state.selectedRowIndices.filter((rowIndex) => rowIndex !== toggleRowIndex)
        : [...this.state.selectedRowIndices, toggleRowIndex],
    });
  }

  toggleAll(toggleAll: boolean) {
    this.setState({
      selectedRowIndices: toggleAll ? this.state.auctionItems.map((row, index) => index) : [],
    });
  }

  chargeSelectedCards() {
    this.setState({
      auctionItemsToCharge: this.state.selectedRowIndices.map(
        (rowIndex) => this.state.auctionItems[rowIndex],
      ),
      selectedRowIndices: [],
    });
  }

  canItemBeCharged(auctionItem: IAuctionItem) {
    const allowedPaymentStatuses = [
      Constants.auction_item_payment_status.awaiting_payment,
      Constants.auction_item_payment_status.failed,
    ];

    return (
      auctionItem.bids_require_payment_method === true &&
      auctionItem.status?.code === Constants.auction_item_status.closed &&
      allowedPaymentStatuses.includes(auctionItem.payment_status?.code ?? '') &&
      auctionItem.current_bid?.user_id
    );
  }

  getChargeCardsProgress() {
    const cur = this.state.auctionItemChargeProgressIndex;
    const total = this.state.auctionItemsBeingCharged.length;

    return (cur / (total || 1)) * 100;
  }

  render() {
    const currency = this.props.owner.account?.currency ?? defaultCurrency;

    const isAllSelected =
      this.state.auctionItems.length > 0 &&
      this.state.selectedRowIndices.length === this.state.auctionItems.length;

    return (
      <div className="AuctionManagement section-wrap">
        <div className="section-title">
          <div className="title">Auction</div>
          <div className="action">
            <Button
              onClick={() => {
                this.setState({ showAddModal: true });
              }}
              icon="fal fa-plus"
              text="Create Item"
            />
          </div>

          <div className="action">
            <Button
              onClick={() => this.chargeSelectedCards()}
              isDisabled={this.state.selectedRowIndices.length === 0}
              text="Charge Selected Cards"
            />
          </div>

          <div className="action">
            <Button
              className="filter-button"
              icon="fal fa-filter"
              onClick={() => {
                this.setState({ showFilterModal: true });
              }}
              text={
                this.state.filters.length > 0
                  ? 'Filter (' + this.state.filters.length + ')'
                  : 'Filter'
              }
            />
          </div>
        </div>

        {this.getKpi()}

        <div className="section-inner">
          <div className="AuctionManagement-list">
            <div className="search">
              <TextField
                icon="fas fa-search"
                placeholder="Search auction..."
                value={this.state.searchValue}
                type="text"
                name="searchValue"
                onChange={(e: any) =>
                  handleInputChange(e, this, false, () => {
                    handleDebounce(this.state.searchValue).then((res) => {
                      this.fetchAuctionItems();
                    });
                  })
                }
              />
            </div>
            <SortableTable
              loading={this.state.auctionItemsLoading}
              columns={[
                {
                  id: 'is_selected',
                  label: '',
                  sortable: false,
                  content: (
                    <div
                      className={isAllSelected ? 'selected' : ''}
                      onClick={() => this.toggleAll(!isAllSelected)}
                    />
                  ),
                },
                { id: 'portrait', label: 'Portrait', sortable: false },
                { id: 'name', label: 'Name' },
                { id: 'status.code', label: 'Auction Status' },
                { id: 'minimum_bid_increment', label: 'Increments' },
                { id: 'buy_now_price', label: 'Buy Now' },
                { id: 'end_date', label: 'Close Date' },
                { id: 'payment_status.code', label: 'Payment Status' },
                { id: 'actions', label: 'Actions', sortable: false },
              ]}
              onSort={(sortSettings) => this.setState({ sort: sortSettings })}
              data={this.state.auctionItems.map((auctionItem, index) => {
                const status = auctionItem.status?.code ?? Constants.auction_item_status.new;
                const paymentStatus =
                  auctionItem.payment_status?.code ??
                  Constants.auction_item_payment_status.awaiting_payment;
                const isSelected =
                  this.state.selectedRowIndices.findIndex((rowIndex) => rowIndex === index) !== -1;

                const actions: ISortableTableActions[] = [
                  {
                    onClick: () => {
                      this.setState({ editItem: index }, () => {
                        this.setState({ showAddModal: true });
                      });
                    },
                    icon: 'fas fa-pencil',
                    label: 'Edit',
                  },
                ];

                if (this.canItemBeCharged(auctionItem)) {
                  actions.push({
                    onClick: () => {
                      this.setState({ auctionItemsToCharge: [auctionItem] });
                    },
                    icon: 'fas fa-dollar-sign',
                    label: 'Charge Card',
                  });
                }

                actions.push(
                  {
                    onClick: () => {
                      this.setState({ showLeaderModal: true, leaderItem: auctionItem });
                    },
                    icon: 'fas fa-star',
                    label: 'Leaderboard',
                  },
                  {
                    onClick: () => {
                      this.copy(index);
                    },
                    icon: 'fas fa-clone',
                    label: 'Copy',
                  },
                  {
                    onClick: () => {
                      this.setState({ confirmDeleteModal: true, deleteItem: index });
                    },
                    icon: 'fas fa-trash',
                    label: 'Delete',
                  },
                );

                if (this.canItemBeCharged(auctionItem)) {
                  actions.push({
                    onClick: () => {
                      this.setState({ editOrderAuctionItem: auctionItem });
                    },
                    icon: 'fal fa-file-alt',
                    label: 'Edit Order',
                  });
                }

                return {
                  row: [
                    {
                      id: 'is_selected',
                      content: (
                        <div
                          className={isSelected ? 'selected' : ''}
                          onClick={() => this.toggleSelectRow(index)}
                        />
                      ),
                    },
                    {
                      id: 'portrait',
                      content: (
                        <Portrait
                          currentImage={auctionItem.media[0]?.url}
                          size={40}
                        />
                      ),
                    },
                    { id: 'name', content: <div notranslate="yes">{auctionItem.name}</div> },
                    {
                      id: 'status.code',
                      content: (
                        <div className={status}>{capitalizeString(auctionItem.status?.code)}</div>
                      ),
                    },
                    {
                      id: 'minimum_bid_increment',
                      content: (
                        <div notranslate="yes">
                          {formatCurrency(
                            auctionItem.minimum_bid_increment,
                            currency,
                            this.props.locale,
                          )}
                        </div>
                      ),
                    },
                    {
                      id: 'buy_now_price',
                      content: (
                        <div notranslate="yes">
                          {auctionItem.buy_now_price
                            ? formatCurrency(auctionItem.buy_now_price, currency, this.props.locale)
                            : 'N/A'}
                        </div>
                      ),
                    },
                    {
                      id: 'end_date',
                      content: (
                        <div notranslate="yes">
                          {localizeHelpers.formatDate(
                            auctionItem.end_date ?? new Date(),
                            LocaleDateFormats.lll,
                            this.props.locale,
                          )}
                        </div>
                      ),
                    },
                    {
                      id: 'payment_status.code',
                      content: (
                        <div className={paymentStatus}>
                          {auctionItemHelpers.getAuctionItemPaymentStatusString(paymentStatus)}
                        </div>
                      ),
                    },
                    { id: 'actions', menu: actions },
                  ],
                };
              })}
            />
          </div>
        </div>

        {/* Charge cards prompt */}
        {this.state.auctionItemsToCharge.length > 0 && (
          <Prompt
            message={localizeHelpers.translate(
              'Are you sure you want to charge {{num_of_items_to_charge}} Auction items?',
              {
                num_of_items_to_charge: this.state.auctionItemsToCharge.length,
              },
            )}
            notranslateMessage="yes"
            title="Charge Auction Items"
            yesMessage="Confirm"
            cancelMessage="Cancel"
            onClose={() => this.setState({ auctionItemsToCharge: [] })}
            onYes={() => this.chargeCards()}
          />
        )}

        {/* Charge cards progress Modal */}
        <Modal
          show={this.state.auctionItemsBeingCharged.length > 0}
          title="Changing Cards"
          class="charge-cards-progress-modal"
        >
          <div className="content-container">
            <div className="please-wait">Please Wait...</div>
            <div>
              <var data-var="current_item">{this.state.auctionItemChargeProgressIndex}</var>
              &nbsp;of&nbsp;
              <var data-var="current_item">{this.state.auctionItemsBeingCharged.length}</var>{' '}
              Auction Items Processed
            </div>

            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${this.getChargeCardsProgress()}%` }}
              />
            </div>
          </div>
        </Modal>

        <AuctionEditOrderModal
          show={this.state.editOrderAuctionItem != null}
          onClose={() => this.setState({ editOrderAuctionItem: null })}
          onSave={() => this.fetchAuctionItems()}
          auctionItem={this.state.editOrderAuctionItem}
          owner={this.props.owner}
        />

        {/* Auction Payment failure message Modal. */}
        <Modal
          show={this.state.auctionItemSelectedForError != null}
          title="Transaction Failed"
          onClose={() => this.setState({ auctionItemSelectedForError: null })}
          class="charge-error-modal"
        >
          <div className="content-container">
            <i className="x-icon fal fa-times-circle" />

            <div
              notranslate="yes"
              className="title"
            >
              Transaction for{' '}
              <var data-var="auction_item_name">{this.state.auctionItemSelectedForError?.name}</var>
            </div>

            <div
              notranslate="yes"
              className="description"
            >
              {this.state.auctionItemSelectedForError?.payment_status?.status_reason}
            </div>
          </div>
        </Modal>

        {/* Filters Modal */}
        <Modal
          class="filter-modal"
          show={this.state.showFilterModal}
          onClose={() => {
            this.setState({ showFilterModal: false });
          }}
        >
          <FilterData
            currentFilters={this.state.filters}
            filterOptions={[
              { id: 'name', label: 'Name', type: 'text' },
              {
                id: 'status.code',
                label: 'Status',
                type: 'dropdown',
                options: Object.values(Constants.auction_item_status).map((status) => ({
                  label: capitalizeString(status) ?? '',
                  value: `=${status}`,
                })),
              },
              { id: 'minimum_bid_increment', label: 'Increments', type: 'number' },
              { id: 'buy_now_price', label: 'Buy Now', type: 'number' },
              { id: 'start_date', label: 'Start Date', type: 'date' },
              { id: 'end_date', label: 'Close Date', type: 'date' },
              {
                id: 'payment_status.code',
                label: 'Payment Status',
                type: 'dropdown',
                options: Object.values(Constants.auction_item_payment_status).map((status) => ({
                  label: capitalizeString(status?.replace(/_/g, ' ')) ?? '',
                  value: `=${status}`,
                })),
              },
            ]}
            onApply={(appliedFilters) =>
              this.setState({ filters: appliedFilters, showFilterModal: false })
            }
          />
        </Modal>

        <AuctionAdminLeaderboardModal
          owner={this.props.owner}
          onClose={() => this.setState({ showLeaderModal: false, leaderItem: null })}
          onManualAdminPayment={this.handleSuccessfulPayment}
          onWinnerSet={() => this.fetchAuctionItems()}
          showLeaderModal={this.state.showLeaderModal}
          leaderItem={this.state.leaderItem}
          isPaymentPendingForBidder={(bidder) =>
            this.state.pendingItems.includes(this.state.leaderItem?.id ?? '') &&
            this.state.pendingBidders.includes(bidder.user_id)
          }
        />

        <Modal
          show={this.state.showAddModal}
          onClose={() => {
            this.setState({ showAddModal: false, editItem: -1, copyItem: -1 });
          }}
          title={this.state.editItem === -1 ? 'Add Auction Item' : 'Edit Auction Item'}
        >
          <AuctionItemForm
            owner={this.props.owner}
            onAuctionSave={() => {
              this.fetchAuctionItems();
            }}
            editItem={this.state.editItem}
            copyItem={this.state.copyItem}
            onClose={() => this.setState({ showAddModal: false, copyItem: -1, editItem: -1 })}
            auctionItems={this.state.auctionItems}
          />
        </Modal>
        <Modal
          show={this.state.confirmDeleteModal}
          onClose={() => {
            this.setState({ confirmDeleteModal: false });
          }}
          title={this.state.auctionItems[this.state.deleteItem]?.name}
          description="Are you sure you want to delete this item?"
        >
          <div className="delete-auction-item">
            <div className="actions">
              <Button
                text="No"
                onClick={() => {
                  this.setState({ confirmDeleteModal: false, deleteItem: -1 });
                }}
              />
              <Button
                text="Yes"
                onClick={() => {
                  this.deleteAuctionItem(this.state.auctionItems[this.state.deleteItem]!);
                  this.setState({ confirmDeleteModal: false, deleteItem: -1 });
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  createToast,
  getEventStats,
  getGroupStats,
};

export default withRouter(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(AuctionManagement)),
);
