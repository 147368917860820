import React, { ChangeEvent, useEffect, useState } from 'react';
import './HubManagementSettings.scss';
import TextField from '../../../TextField/TextField';
import Button from '../../../Button/Button';
import { IAddress, IHub, IPage, ISocialImpactBenefits } from '@gigit/interfaces';
import { hubRequestActions, userRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import Portrait from '../../../Portrait/Portrait';
import CoverImage from '../../../CoverImage/CoverImage';
import TextArea from '../../../TextArea/TextArea';
import Checkbox from '../../../Checkbox/Checkbox';
import {
  dataURItoBlob,
  errorHelpers,
  routes,
  typeHelpers,
  ValidateHandleStatus,
} from '../../../../helpers';
import axios from 'axios';
import { Config } from '@gigit/config';
import BannerLayoutSettings from '../../Shared/BannerLayoutSettings/BannerLayoutSettings';
import { uploadImage } from '../../../../helpers';
import ColorPickerInput from '../../../shared/ColorPickerInput/ColorPickerInput';
import Dropdown, { IOptions } from '../../../Dropdown/Dropdown';
import { localeConstants, uiConstants } from '../../../../constants';
import { useHistory } from 'react-router-dom';
import SocialImpactBenefitsForm from '../../Shared/SocialImpactBenefitsForm/SocialImpactBenefitsForm';
import OrganizerContacts from '../../../OrganizerContacts/OrganizerContacts';
import { Constants } from '@gigit/constants';

interface IProps {
  hub?: IHub;
}

interface IFormInputConfigBase {
  value: string;
  name: string;
  type: string;
  onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void;
  placeholder: string;
  label: string;
  inputType: 'textField' | 'textArea' | 'checkbox' | 'dropdown' | 'hubUrlInput';
  maxLength?: number;
  checked?: boolean;
  options?: Array<IOptions>;
  shouldSort?: boolean;
  disabled?: boolean;
  required?: boolean;
}

const INITIAL_COMPANY_ADDRESS: IAddress = {
  line1: '',
  line2: '',
  city: '',
  province: '',
  postal_code: '',
};

const HubManagementSettings: React.FC<IProps> = (props) => {
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const history = useHistory();

  const [tmpHub, setTmpHub] = useState<IHub | undefined>(props.hub);

  // Hub Info
  const [hubInfoName, setHubInfoName] = useState<string>('');
  const [hubInfoHandle, setHubInfoHandle] = useState<string>('');
  const [hubInfoEmail, setHubInfoEmail] = useState<string>('');
  const [hubInfoPhone, setHubInfoPhone] = useState<string>('');
  const [hubInfoCountry, setHubInfoCountry] = useState<string>('');
  const [validateHandleStatus, setValidateHandleStatus] = useState<ValidateHandleStatus>(
    ValidateHandleStatus.VALIDATED,
  );
  const [hubHandleError, setHubHandleError] = useState<string>('');
  const [hubProcessingFee, setHubProcessingFee] = useState<boolean>(false);
  const [hubPlatformFee, setHubPlatformFee] = useState<boolean>(false);

  // Hub Theme
  const [hubThemePrimary, setHubThemePrimary] = useState<string>('#2C73D6');
  const [hubThemeSecondary, setHubThemeSecondary] = useState<string>('#FFFFFF');

  // Hub Logo
  const [hubLogo, setHubLogo] = useState<string>('');

  // Hub Social Impact Benefits
  const [socialImpactBenefits, setSocialImpactBenefits] = useState<ISocialImpactBenefits>();
  const [isSavingSocialImpactBenefits, setIsSavingSocialImpactBenefits] = useState<boolean>(false);

  //Hub Banner Layout
  const [hubBannerLayout, setHubBannerLayout] = useState<string>('center');

  //Hub Banner Image
  const [hubBannerImage, setHubBannerImage] = useState<string>('');

  // Hub Banner Info
  const [hubBannerInfoTitle, setHubBannerInfoTitle] = useState<string>('');
  const [hubBannerInfoDescription, setHubBannerInfoDescription] = useState<string>('');

  // Company Tax Information
  const [companyName, setCompanyName] = useState<string>('');
  const [companyAddress, setCompanyAddress] = useState<IAddress>(INITIAL_COMPANY_ADDRESS);
  const [companyPhone, setCompanyPhone] = useState<string>('');
  const [emailNotificationRecipients, setEmailNotificationRecipients] = useState<string[]>([]);
  const [notificationInput, setNotificationInput] = useState<string>('');

  // Hub Call To Action
  const [hubCallToActionDonate, setHubCallToActionDonate] = useState<string>('');
  const [hubCallToActionFundraise, setHubCallToActionFundraise] = useState<string>('');
  const [hubCallToActionVolunteer, setHubCallToActionVolunteer] = useState<string>('');

  const [pages, setPages] = useState<IPage[]>([]);
  const [originalPages, setOriginalPages] = useState<IPage[]>([]);

  // Hub Visibility Settings
  const [hubVisibilityAllowNonMembers, setHubVisibilityAllowNonMembers] = useState<boolean>(true);
  const setHubValues = (hub: IHub): void => {
    setHubVisibilityAllowNonMembers(hub?.public_access_to_events_and_gigs ? true : false);
    if (hub?.title) {
      setHubInfoName(hub.title);
    }
    if (hub?.handle) {
      setHubInfoHandle(hub.handle);
    }
    if (hub?.contact_details?.email) {
      setHubInfoEmail(hub.contact_details.email);
    }
    if (hub?.contact_details?.phone) {
      setHubInfoPhone(hub.contact_details.phone);
    }
    if (hub?.localization?.country) {
      setHubInfoCountry(hub.localization.country);
    }
    if (hub?.theme?.primary_color) {
      setHubThemePrimary(hub.theme.primary_color);
    }
    if (hub?.theme?.secondary_color) {
      setHubThemeSecondary(hub.theme.secondary_color);
    }
    if (hub?.profile_image_url) {
      setHubLogo(hub.profile_image_url);
    }
    if (hub?.banner?.layout) {
      setHubBannerLayout(hub.banner.layout);
    }
    if (hub?.banner?.image) {
      setHubBannerImage(hub.banner.image);
    }
    if (hub?.banner?.banner_info?.title) {
      setHubBannerInfoTitle(hub.banner.banner_info.title);
    }
    if (hub?.banner?.banner_info?.description) {
      setHubBannerInfoDescription(hub.banner.banner_info.description);
    }
    if (hub?.call_to_action_text?.donate) {
      setHubCallToActionDonate(hub.call_to_action_text.donate);
    }
    if (hub?.call_to_action_text?.fundraise) {
      setHubCallToActionFundraise(hub.call_to_action_text.fundraise);
    }
    if (hub?.call_to_action_text?.volunteer) {
      setHubCallToActionVolunteer(hub.call_to_action_text.volunteer);
    }

    if (hub?.fee_control?.covers_gigit_fees) {
      setHubPlatformFee(hub.fee_control.covers_gigit_fees);
    }
    if (hub.fee_control?.covers_processing_fees) {
      setHubProcessingFee(hub.fee_control.covers_processing_fees);
    }
    if (hub?.tax_info?.company_name) {
      setCompanyName(hub.tax_info.company_name);
    }
    if (hub?.tax_info?.company_phone) {
      setCompanyPhone(hub.tax_info.company_phone);
    }
    if (hub?.tax_info?.company_address) {
      setCompanyAddress(hub.tax_info.company_address);
    }
    if (hub?.tax_info?.email_notificaitons) {
      setEmailNotificationRecipients(hub.tax_info.email_notificaitons);
    }
  };

  async function fetchHubPages(hubId: string) {
    try {
      const query = new URLSearchParams('flags[hub_settings_pages]=true');
      let pages = await hubRequestActions.getHubPages(hubId, query);
      pages = pages.filter((page) => page.label !== 'Store'); //GIG-7653: Filtering to hide store page for now
      setPages(pages);
      setOriginalPages(JSON.parse(JSON.stringify(pages)));
    } catch (error) {
      dispatchToastError(error, 'Error Getting Hub Pages');
    }
  }

  useEffect(() => {
    if (props?.hub) {
      setHubValues(props.hub);
      fetchHubPages(props?.hub?.id || '');
      setTmpHub(props.hub);
    }
  }, [props.hub]);

  const isDisabledSaveHubInfoBtn = (): boolean => {
    return Boolean(
      ((tmpHub?.title || '') === hubInfoName &&
        (tmpHub?.handle || '') === hubInfoHandle &&
        (tmpHub?.localization?.country || '') === hubInfoCountry) ||
        validateHandleStatus !== ValidateHandleStatus.VALIDATED,
    );
  };

  const isDisabledSaveHubContactBtn = (): boolean => {
    return Boolean(
      (tmpHub?.contact_details?.email || '') === hubInfoEmail &&
        (tmpHub?.contact_details?.phone || '') === hubInfoPhone,
    );
  };

  const isDisabledSaveHubLogoBtn = (): boolean => {
    return Boolean(tmpHub?.profile_image_url === hubLogo);
  };

  const isDisabledSaveHubThemeBtn = (): boolean => {
    return Boolean(
      (tmpHub?.theme?.primary_color || '#2C73D6') === hubThemePrimary &&
        (tmpHub?.theme?.secondary_color || '#FFFFFF') === hubThemeSecondary,
    );
  };

  const isDisabledSaveSocialBenefitsBtn = (): boolean => {
    return socialImpactBenefits === tmpHub?.social_impact_benefits;
  };

  const isDisabledSaveHubBannerLayout = (): boolean => {
    return Boolean((tmpHub?.banner?.layout || 'center') === hubBannerLayout);
  };

  const isDisabledSaveHubBannerImage = (): boolean => {
    return Boolean((tmpHub?.banner?.image || '') === hubBannerImage);
  };

  const isDisabledSaveHubBannerInfo = (): boolean => {
    return Boolean(
      (tmpHub?.banner?.banner_info?.title || '') === hubBannerInfoTitle &&
        (tmpHub?.banner?.banner_info?.description || '') === hubBannerInfoDescription,
    );
  };

  const isDisabledSaveCompanyTaxInformation = (): boolean => {
    const isDifference =
      (tmpHub?.tax_info?.company_name || '') === companyName &&
      (tmpHub?.tax_info?.company_phone || '') === companyPhone &&
      (tmpHub?.tax_info?.company_address?.line1 || '') === companyAddress?.line1 &&
      (tmpHub?.tax_info?.company_address?.line2 || '') === companyAddress?.line2 &&
      (tmpHub?.tax_info?.company_address?.city || '') === companyAddress?.city &&
      (tmpHub?.tax_info?.company_address?.state || '') === companyAddress?.state &&
      (tmpHub?.tax_info?.company_address?.postal_code || '') === companyAddress?.postal_code;
    const isEmpty =
      companyName === '' ||
      companyPhone === '' ||
      companyAddress?.line1 === '' ||
      companyAddress?.city === '' ||
      companyAddress?.state === '' ||
      companyAddress?.postal_code === '';

    return isEmpty || isDifference;
  };

  const isDisabledSaveHubCallToAction = (): boolean => {
    return Boolean(
      (tmpHub?.call_to_action_text?.donate || '') === hubCallToActionDonate &&
        (tmpHub?.call_to_action_text?.fundraise || '') === hubCallToActionFundraise &&
        (tmpHub?.call_to_action_text?.volunteer || '') === hubCallToActionVolunteer,
    );
  };

  const isDisabledSavePageVisibility = (): boolean => {
    return JSON.stringify(originalPages) === JSON.stringify(pages);
  };

  const isDisabledSaveHubVisibilitySettings = (): boolean => {
    return Boolean(tmpHub?.public_access_to_events_and_gigs === hubVisibilityAllowNonMembers);
  };

  const saveHubHandle = async (hub: IHub, newHandle: string) => {
    try {
      await hubRequestActions.changeHubHandle(hub.id!, newHandle);
      history.push(`/company/${hubInfoHandle}/admin`);
    } catch (error) {
      dispatchToastError(error, 'Error Saving Hub Handle');
    }
  };

  const saveHubCallToAction = async () => {
    if (tmpHub) {
      const payload: Partial<IHub> = {
        call_to_action_text: {
          donate: hubCallToActionDonate,
          volunteer: hubCallToActionVolunteer,
          fundraise: hubCallToActionFundraise,
        },
      };

      try {
        const result = await hubRequestActions.updateHub(payload, props.hub?.id);
        setHubValues(result);
        setTmpHub(result);
        dispatchToastSuccess(
          'Successfully Updated Hub Call To Action',
          'Update Hub Call To Action',
        );
      } catch (error) {
        dispatchToastError(error, 'Update Hub Call To Action');
      }
    }
  };

  const savePageVisibility = async (hubId: string) => {
    try {
      typeHelpers.assertNotNullOrUndefined(hubId, 'Hub ID');
      await hubRequestActions.updateBulkHubPages(hubId, pages);
      fetchHubPages(hubId);
      dispatchToastSuccess('Successfully Updated Page Visibility', 'Update Page Visibility');
    } catch (error) {
      dispatchToastError(error, 'Update Page Visibility');
    }
  };

  const saveHubVisibilitySettings = async () => {
    if (tmpHub) {
      const payload: Partial<IHub> = {
        public_access_to_events_and_gigs: hubVisibilityAllowNonMembers,
      };

      try {
        typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Hub ID');
        const result = await hubRequestActions.updateHub(payload, props.hub.id);
        setHubValues(result);
        setTmpHub(result);
        dispatchToastSuccess(
          'Successfully Updated Hub Visibility Settings',
          'Update Hub Visibility Settings',
        );
      } catch (error) {
        dispatchToastError(error, 'Update Hub Visibility Settings');
      }
    }
  };

  const saveHubBannerInfo = async () => {
    if (tmpHub) {
      const payload: Partial<IHub> = {
        banner: {
          ...tmpHub.banner,
          banner_info: {
            title: hubBannerInfoTitle,
            description: hubBannerInfoDescription,
          },
        },
      };

      try {
        const result = await hubRequestActions.updateHub(payload, props.hub?.id);
        setHubValues(result);
        setTmpHub(result);
        dispatchToastSuccess('Successfully Updated Hub Banner Info', 'Update Hub Banner Info');
      } catch (error) {
        dispatchToastError(error, 'Update Hub Banner Info');
      }
    }
  };

  const saveCompanyTaxInformation = async () => {
    if (tmpHub) {
      const addressObj: IAddress = {
        owner_id: tmpHub.id,
        owner_type: Constants.page_owner_object_type.hub,
        line1: companyAddress?.line1,
        line2: companyAddress?.line2,
        city: companyAddress?.city,
        state: companyAddress?.state,
        postal_code: companyAddress?.postal_code,
      };

      const taxInfoObj: IHub['tax_info'] = {
        company_name: companyName,
        company_phone: companyPhone,
        company_address: addressObj,
        email_notificaitons: emailNotificationRecipients,
      };

      const payload: Partial<IHub> = {
        tax_info: taxInfoObj,
      };

      try {
        const result = await hubRequestActions.updateHub(payload, props.hub?.id);
        setHubValues(result);
        setTmpHub(result);
        dispatchToastSuccess(
          'Successfully Updated Company Tax Information',
          'Update Company Tax Information',
        );
      } catch (error) {
        dispatchToastError(error, 'Update Company Tax Information');
      }
    }
  };

  const updateEmailNotificationRecipients = async (email: string) => {
    if (tmpHub) {
      const addressObj: IAddress = {
        owner_id: tmpHub.id,
        owner_type: Constants.page_owner_object_type.hub,
        line1: companyAddress?.line1,
        line2: companyAddress?.line2,
        city: companyAddress?.city,
        state: companyAddress?.state,
        postal_code: companyAddress?.postal_code,
      };

      let taxInfoObj: IHub['tax_info'] = {
        company_name: companyName,
        company_phone: companyPhone,
        company_address: addressObj,
        email_notificaitons: [...emailNotificationRecipients, email],
      };

      const payload: Partial<IHub> = {
        tax_info: taxInfoObj,
      };

      try {
        const result = await hubRequestActions.updateHub(payload, props.hub?.id);
        setHubValues(result);
        setTmpHub(result);
        dispatchToastSuccess(
          'Successfully Updated Company Tax Information',
          'Update Company Tax Information',
        );
      } catch (error) {
        dispatchToastError(error, 'Update Company Tax Information');
      }
    }
  };

  const removeEmailNotificationRecipient = async (email: string) => {
    if (tmpHub) {
      const addressObj: IAddress = {
        owner_id: tmpHub.id,
        owner_type: Constants.page_owner_object_type.hub,
        line1: companyAddress?.line1,
        line2: companyAddress?.line2,
        city: companyAddress?.city,
        state: companyAddress?.state,
        postal_code: companyAddress?.postal_code,
      };

      let taxInfoObj: IHub['tax_info'] = {
        company_name: companyName,
        company_phone: companyPhone,
        company_address: addressObj,
        email_notificaitons: emailNotificationRecipients.filter((recipient) => recipient !== email),
      };

      console.log('taxInfoObj', taxInfoObj);

      const payload: Partial<IHub> = {
        tax_info: taxInfoObj,
      };

      try {
        const result = await hubRequestActions.updateHub(payload, props.hub?.id);
        setHubValues(result);
        setTmpHub(result);
        dispatchToastSuccess(
          'Successfully Updated Company Tax Information',
          'Update Company Tax Information',
        );
      } catch (error) {
        dispatchToastError(error, 'Update Company Tax Information');
      }
    }
  };

  const saveHubBannerImage = async () => {
    if (tmpHub) {
      const uploadedBannerURL = await uploadImage(hubBannerImage);
      const payload: Partial<IHub> = {
        banner: {
          ...tmpHub.banner,
          image: uploadedBannerURL,
        },
      };

      try {
        const result = await hubRequestActions.updateHub(payload, props.hub?.id);
        setHubValues(result);
        setTmpHub(result);
        dispatchToastSuccess('Successfully Updated Hub Banner Image', 'Update Hub Banner Image');
      } catch (error) {
        dispatchToastError(error, 'Update Hub Banner Image');
      }
    }
  };

  const saveHubBannerLayout = async () => {
    if (tmpHub) {
      const payload: Partial<IHub> = {
        banner: {
          ...tmpHub.banner,
          layout: hubBannerLayout,
        },
      };

      try {
        const result = await hubRequestActions.updateHub(payload, props.hub?.id);
        setHubValues(result);
        setTmpHub(result);
        dispatchToastSuccess('Successfully Updated Hub Banner Layout', 'Updated Hub Banner Layout');
      } catch (error) {
        dispatchToastError(error, 'Update Hub Banner Layout');
      }
    }
  };

  const saveHubLogo = async () => {
    if (tmpHub) {
      const uploadedHubLogoURL = await uploadImage(hubLogo);

      const payload: Partial<IHub> = {
        profile_image_url: uploadedHubLogoURL,
      };

      if (hubLogo.includes('base64')) {
        const blob = dataURItoBlob(hubLogo);
        let formData = new FormData();
        formData.append('file', blob);

        let imageUrl = await axios.post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setHubLogo(imageUrl.data.value);
        payload.profile_image_url = imageUrl.data.value;
      } else {
        payload.profile_image_url = hubLogo;
      }

      try {
        const result = await hubRequestActions.updateHub(payload, props.hub?.id);
        setHubValues(result);
        setTmpHub(result);
        dispatchToastSuccess('Successfully Updated Hub Logo', 'Update Hub Logo');
      } catch (error) {
        dispatchToastError(error, 'Update Update Hub Logo');
      }
    }
  };

  const setValidHandle = () => {
    setValidateHandleStatus(ValidateHandleStatus.VALIDATED);
    setHubHandleError('');
  };

  const onHandleChanged = async (e: ChangeEvent<HTMLInputElement>) => {
    const handle = e.target.value.replace(/\s+/g, '');
    setHubInfoHandle(handle);
    if (tmpHub?.handle === handle) {
      setValidHandle();
    } else {
      await checkHandle(handle);
    }
  };

  const checkHandle = async (handle: string) => {
    setValidateHandleStatus(ValidateHandleStatus.VALIDATING);
    setHubHandleError('');

    try {
      const response = await hubRequestActions.validateHubHandle(handle);
      if (response.value) {
        setValidHandle();
      } else {
        setValidateHandleStatus(ValidateHandleStatus.ERROR);
        setHubHandleError(errorHelpers.getErrorObjectFromSimpleValue(response).translatedMessage);
      }
    } catch (error) {
      setValidateHandleStatus(ValidateHandleStatus.ERROR);
      setHubHandleError('Unknown Error');
    }
  };

  const saveHubInfo = async () => {
    if (tmpHub) {
      const payload: Partial<IHub> = {
        title: hubInfoName,
        contact_details: {
          email: hubInfoEmail,
          phone: hubInfoPhone,
        },
        localization: {
          ...tmpHub.localization,
          country: hubInfoCountry,
        },
      };

      try {
        const result = await hubRequestActions.updateHub(payload, props.hub?.id);
        setHubValues(result);
        setTmpHub(result);

        if (hubInfoHandle !== tmpHub.handle) {
          await saveHubHandle(tmpHub, hubInfoHandle);
        }
        dispatchToastSuccess('Successfully Updated Hub Info', 'Update Hub Info');
      } catch (error) {
        dispatchToastError(error, 'Update Hub Info');
      }
    }
  };

  const saveHubTheme = async () => {
    if (tmpHub) {
      const payload: Partial<IHub> = {
        theme: {
          primary_color: hubThemePrimary,
          secondary_color: hubThemeSecondary,
        },
      };

      try {
        const result = await hubRequestActions.updateHub(payload, props.hub?.id);
        setHubValues(result);
        setTmpHub(result);
        dispatchToastSuccess('Successfully Updated Hub Theme', 'Update Hub Theme');
      } catch (error) {
        dispatchToastError(error, 'Update Hub Theme');
      }
    }
  };

  async function handleSaveSocialImpactBenefits() {
    setIsSavingSocialImpactBenefits(true);

    const payload = {
      social_impact_benefits: socialImpactBenefits,
    };

    try {
      const result = await hubRequestActions.updateHub(payload, props.hub?.id);

      if (result.social_impact_benefits) {
        setSocialImpactBenefits(result.social_impact_benefits);
      }

      setTmpHub(result);
      dispatchToastSuccess(
        'Successfully Updated Social Impact Benefits',
        'Update Social Impact Benefits',
      );
    } catch (error) {
      dispatchToastError(error, 'Update Social Impact Benefits');
    } finally {
      setIsSavingSocialImpactBenefits(false);
    }
  }

  const hubVisibilityMenu: IOptions[] = [
    {
      label: 'Public',
      value: uiConstants.visibility.public,
    },
    {
      label: 'Members Only',
      value: uiConstants.visibility.membersOnly,
    },
  ];

  const hubInfoFormConfig = [
    {
      value: hubInfoName,
      name: 'hub_name',
      type: 'text',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setHubInfoName(e.target.value);
      },
      placeholder: 'Enter a company name',
      label: 'Name',
      inputType: 'textField',
    },
    {
      value: hubInfoHandle,
      name: 'hub_handle',
      type: 'text',
      onChange: onHandleChanged,
      placeholder: 'Enter a company handle',
      label: 'Personalize your Company Web Address',
      inputType: 'hubUrlInput',
    },
    {
      value: hubInfoCountry,
      name: 'hub_country',
      type: 'dropdown',
      onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
        setHubInfoCountry(e.target.value);
      },
      placeholder: 'Enter a company country',
      label: 'Country',
      inputType: 'dropdown',
      shouldSort: true,
      options: localeConstants.supportedCountryOptions,
      disabled: props.hub?.account?.country ? true : false,
    },
  ] as IFormInputConfigBase[];

  const hubBannerInfoFormConfig = [
    {
      value: hubBannerInfoTitle,
      name: 'hub_banner_title',
      type: 'text',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setHubBannerInfoTitle(e.target.value);
      },
      placeholder: 'Enter a banner title',
      label: 'Title',
      inputType: 'textField',
    },
    {
      value: hubBannerInfoDescription,
      name: 'hub_banner_description',
      type: 'text',
      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setHubBannerInfoDescription(e.target.value);
      },
      placeholder: 'Type here...',
      label: 'Description',
      inputType: 'textArea',
      maxLength: 200,
    },
  ] as IFormInputConfigBase[];

  const hubTaxInformationForm = [
    {
      value: companyName,
      name: 'company_name',
      type: 'text',
      placeholder: 'Enter a company name',
      label: 'Company Name',
      inputType: 'textField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyName(e.target.value);
      },
    },
    {
      value: companyPhone,
      name: 'company_phone',
      type: 'tel',
      placeholder: 'Enter a phone number',
      label: 'Company Phone Number',
      inputType: 'textField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyPhone(e.target.value);
      },
    },
    {
      value: companyAddress?.line1 || '',
      name: 'line1',
      type: 'text',
      placeholder: 'Enter a company address',
      label: 'Address Line 1',
      inputType: 'textField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyAddress({
          ...companyAddress,
          line1: e.target.value,
        });
      },
    },
    {
      value: companyAddress?.line2 || '',
      name: 'line2',
      type: 'text',
      placeholder: 'Enter a address',
      label: 'Address Line 2',
      inputType: 'textField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyAddress({
          ...companyAddress,
          line2: e.target.value,
        });
      },
    },
    {
      value: companyAddress?.city || '',
      name: 'company_city',
      type: 'text',
      placeholder: 'Enter a city',
      label: 'City',
      inputType: 'textField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyAddress({
          ...companyAddress,
          city: e.target.value,
        });
      },
    },
    {
      value: companyAddress?.state || '',
      name: 'state',
      type: 'text',
      placeholder: 'Enter a state or province',
      label: 'State/Province',
      inputType: 'textField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyAddress({
          ...companyAddress,
          state: e.target.value,
        });
      },
    },
    {
      value: companyAddress?.postal_code || '',
      name: 'postal_code',
      type: 'text',
      placeholder: 'Enter a company zip/postal code',
      label: 'Zip Code',
      inputType: 'textField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyAddress({
          ...companyAddress,
          postal_code: e.target.value,
        });
      },
    },
  ] as IFormInputConfigBase[];

  const hubCallToActionFormConfig = [
    {
      value: 'Fundraise',
      name: 'hub_call_to_action_fundraise',
      type: 'checkbox',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
          setHubCallToActionFundraise('Fundraise');
        } else {
          setHubCallToActionFundraise('');
        }
      },
      placeholder: '',
      label: 'Fundraise',
      inputType: 'checkbox',
      checked: hubCallToActionFundraise !== '',
    },
    {
      value: 'Donate',
      name: 'hub_call_to_action_donate',
      type: 'checkbox',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
          setHubCallToActionDonate('Fundraise');
        } else {
          setHubCallToActionDonate('');
        }
      },
      placeholder: '',
      label: 'Donate',
      inputType: 'checkbox',
      checked: hubCallToActionDonate,
    },
    {
      value: 'Volunteer',
      name: 'hub_call_to_action_volunteer',
      type: 'checkbox',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
          setHubCallToActionVolunteer('Volunteer');
        } else {
          setHubCallToActionVolunteer('');
        }
      },
      placeholder: '',
      label: 'Volunteer',
      inputType: 'checkbox',
      checked: hubCallToActionVolunteer !== '',
    },
  ] as IFormInputConfigBase[];

  const hubVisibilitySettingsFormConfig = [
    {
      value: 'Members',
      name: 'hub_visibility_settings',
      type: 'checkbox',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
          setHubVisibilityAllowNonMembers(true);
        } else {
          setHubVisibilityAllowNonMembers(false);
        }
      },
      placeholder: '',
      label: 'Allow Non-Members to see Company Events and Volunteer Opportunities?',
      inputType: 'checkbox',
      checked: hubVisibilityAllowNonMembers,
    },
  ] as IFormInputConfigBase[];

  const hubContactFormConfig = [
    {
      value: hubInfoPhone,
      name: 'hub_phone',
      type: 'tel',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setHubInfoPhone(e.target.value);
      },
      placeholder: 'Enter a phone number',
      label: 'Contact Phone Number',
      inputType: 'textField',
    },
    {
      value: hubInfoEmail,
      name: 'hub_email',
      type: 'email',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setHubInfoEmail(e.target.value);
      },
      placeholder: 'Enter an email',
      label: 'Contact Email',
      inputType: 'textField',
    },
  ] as IFormInputConfigBase[];

  const updateHubProcessingFee = async (val: boolean) => {
    if (val !== hubProcessingFee) {
      if (tmpHub) {
        const payload: Partial<IHub> = {
          fee_control: {
            covers_processing_fees: val,
            covers_gigit_fees: hubPlatformFee,
            show_tip: true,
          },
        };

        try {
          const result = await hubRequestActions.updateHub(payload, props.hub?.id);
          setHubValues(result);
          setTmpHub(result);
          dispatchToastSuccess(
            'Succesfully updated Hub Processing Fee',
            'Update Hub Processing Fee',
          );
        } catch (error) {
          dispatchToastError(error, 'Update Hub Processing Fee');
        }
      }
    }
  };

  const updateHubPlatformFee = async (val: boolean) => {
    if (val !== hubPlatformFee) {
      if (tmpHub) {
        const payload: Partial<IHub> = {
          fee_control: {
            covers_gigit_fees: val,
            covers_processing_fees: hubProcessingFee,
            show_tip: true,
          },
        };

        try {
          const result = await hubRequestActions.updateHub(payload, props.hub?.id);
          setHubValues(result);
          setTmpHub(result);
          dispatchToastSuccess('Succesfully updated Hub Platform Fee', 'Update Hub Platform Fee');
        } catch (error) {
          dispatchToastError(error, 'Update Hub Platform Fee');
        }
      }
    }
  };

  return (
    <>
      <div
        className="HubManagementSettings"
        id="settings"
      >
        <h2>Settings</h2>
        <h3 className="section-title">Basic Information</h3>
        <div className="edit-content-container">
          <div className="section-subtitle">
            <h4>Company Information</h4>
            <span>Manage your company information.</span>
          </div>
          <div className="section-form">
            <form>
              {hubInfoFormConfig.map((config, index) => (
                <React.Fragment key={index}>
                  {config.inputType === 'textField' && (
                    <TextField
                      key={index}
                      value={config.value}
                      name={config.name}
                      type={config.type}
                      onChange={config.onChange}
                      placeholder={config.placeholder}
                      label={config.label}
                    />
                  )}
                  {config.inputType === 'hubUrlInput' && (
                    <div className="web-address-container">
                      <label>{config.label}</label>
                      <div className="web-address-line">
                        <p
                          className="web-address"
                          notranslate="yes"
                        >{`${Config.web.REACT_APP_BASE_URL}/company/`}</p>
                        <TextField
                          key={index}
                          value={config.value}
                          name={config.name}
                          type={config.type}
                          onChange={config.onChange}
                          placeholder={config.value}
                          error={hubHandleError}
                        />
                      </div>
                    </div>
                  )}

                  {config.inputType === 'dropdown' && (
                    <Dropdown
                      label={config.label}
                      onChange={config.onChange}
                      value={config.value}
                      shouldSort={true}
                      name={config.name}
                      options={config.options || []}
                      disabled={config.disabled || false}
                    />
                  )}
                </React.Fragment>
              ))}

              <Button
                type="button"
                onClick={async () => {
                  await saveHubInfo();
                }}
                isDisabled={isDisabledSaveHubInfoBtn()}
                text="Save Changes"
              />
            </form>
          </div>
        </div>
        <div className="edit-content-container">
          <div className="section-subtitle">
            <h4>Contact Information</h4>
            <span>Add contact details to allow easy communication with your company members</span>
          </div>
          <div className="section-form">
            <form>
              {hubContactFormConfig.map((config, index) => (
                <React.Fragment key={index}>
                  {config.inputType === 'textField' && (
                    <TextField
                      key={index}
                      value={config.value}
                      name={config.name}
                      type={config.type}
                      onChange={config.onChange}
                      placeholder={config.placeholder}
                      label={config.label}
                    />
                  )}
                </React.Fragment>
              ))}

              <Button
                type="button"
                onClick={async () => {
                  await saveHubInfo();
                }}
                isDisabled={isDisabledSaveHubContactBtn()}
                text="Save Changes"
              />
            </form>
          </div>
        </div>
        <div className="edit-content-container no-border">
          <div className="section-subtitle">
            <h4>Company Logo</h4>
            <span>Manage your company logo.</span>
          </div>
          <div className="section-form">
            <form>
              <Portrait
                className="logo-container"
                size={120}
                overlay={true}
                currentImage={hubLogo}
                onChange={(param) => {
                  setHubLogo(param.file);
                }}
              />

              <Button
                type="button"
                onClick={async () => {
                  await saveHubLogo();
                }}
                isDisabled={isDisabledSaveHubLogoBtn()}
                text="Save Changes"
              />
            </form>
          </div>
        </div>

        <h3 className="section-title">Tax Information</h3>
        <div className="edit-content-container">
          <div className="section-subtitle">
            <h4>Company Information</h4>
            <span>This information will be shared with the public.</span>
          </div>
          <div className="section-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                saveCompanyTaxInformation();
              }}
            >
              {hubTaxInformationForm.map((config, index) => (
                <React.Fragment key={index}>
                  {config.inputType === 'textField' && (
                    <TextField
                      key={index}
                      value={config.value}
                      name={config.name}
                      type={config.type}
                      placeholder={config.placeholder}
                      label={config.label}
                      required={config.required}
                      onChange={config.onChange}
                    />
                  )}
                </React.Fragment>
              ))}

              <Button
                type="submit"
                onClick={async () => {
                  await saveCompanyTaxInformation();
                }}
                isDisabled={isDisabledSaveCompanyTaxInformation()}
                text="Save Changes"
              />
            </form>
          </div>
        </div>
        <div className="edit-content-container no-border">
          <div className="section-subtitle">
            <h4>Notifications</h4>
            <span>Manage which administrators will receive notifications</span>
          </div>

          <div className="section-form">
            <div className="notification-email-input">
              <TextField
                onChange={(e) => {
                  setNotificationInput(e.target.value);
                }}
                name="notificationInput"
                value={notificationInput}
                label="Email"
              />
              <Button
                onClick={() => updateEmailNotificationRecipients(notificationInput)}
                text="Add"
                type="submit"
              />
            </div>

            <div className="notification-list">
              {emailNotificationRecipients.map((notification_email, index) => {
                return (
                  <div
                    className="notification-item"
                    key={index}
                  >
                    <div>{notification_email}</div>{' '}
                    <Button
                      text="Remove"
                      buttonType="secondary"
                      onClick={() => {
                        removeEmailNotificationRecipient(notification_email);
                      }}
                      type="submit"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <h3 className="section-title">Theme</h3>
        <div className="edit-content-container no-border">
          <div className="section-subtitle">
            <h4>Color Palette</h4>
            <span>Select a theme for your company page.</span>
          </div>
          <div className="section-form">
            <form>
              <div className="color-container">
                <ColorPickerInput
                  color={hubThemePrimary}
                  onChange={setHubThemePrimary}
                  label="Primary"
                />
                <ColorPickerInput
                  color={hubThemeSecondary}
                  onChange={setHubThemeSecondary}
                  label="Secondary"
                />
              </div>

              <Button
                type="button"
                onClick={async () => {
                  await saveHubTheme();
                }}
                isDisabled={isDisabledSaveHubThemeBtn()}
                text="Save Changes"
              />
            </form>
          </div>
        </div>

        <h3 className="section-title">Social Impact Benefits</h3>
        <div className="edit-content-container no-border">
          <div className="section-form">
            <SocialImpactBenefitsForm
              hub={props.hub || null}
              socialImpactBenefits={socialImpactBenefits!}
              setSocialImpactBenefits={setSocialImpactBenefits}
            />
            <Button
              type="button"
              onClick={handleSaveSocialImpactBenefits}
              text="Save Changes"
              isDisabled={isDisabledSaveSocialBenefitsBtn()}
              loading={isSavingSocialImpactBenefits}
            />
          </div>
        </div>

        <h3 className="section-title">Banner Settings</h3>
        <div className="edit-content-container">
          <div className="section-subtitle">
            <h4>Banner Layout</h4>
            <span>Set alignment for your company banner.</span>
          </div>
          <div className="section-form">
            <form>
              <BannerLayoutSettings
                bannerLayout={hubBannerLayout}
                setBannerLayout={(v) => setHubBannerLayout(v)}
              />

              <Button
                type="button"
                onClick={async () => {
                  await saveHubBannerLayout();
                }}
                isDisabled={isDisabledSaveHubBannerLayout()}
                text="Save Changes"
              />
            </form>
          </div>
        </div>

        <div className="edit-content-container">
          <div className="section-subtitle">
            <h4>Banner Image</h4>
            <span>Supports JPG, JPEG, PNG. with max dimensions of 1280 x 450.</span>
          </div>
          <div className="section-form">
            <form>
              <div className={`cover-image-container ${!hubBannerImage ? 'settings' : ''}`}>
                <CoverImage
                  currentImage={hubBannerImage}
                  onChange={(param) => {
                    setHubBannerImage(param.file);
                  }}
                  overlay={true}
                  width={160}
                  height={454}
                />
              </div>
              <Button
                type="button"
                onClick={async () => {
                  await saveHubBannerImage();
                }}
                isDisabled={isDisabledSaveHubBannerImage()}
                text="Save Changes"
              />
            </form>
          </div>
        </div>

        <div className="edit-content-container">
          <div className="section-subtitle">
            <h4>Banner Information</h4>
            <span>This information will be shared with the public.</span>
          </div>
          <div className="section-form">
            <form>
              {hubBannerInfoFormConfig.map((config, index) => (
                <React.Fragment key={index}>
                  {config.inputType === 'textField' && (
                    <TextField
                      key={index}
                      value={config.value}
                      name={config.name}
                      type={config.type}
                      onChange={config.onChange}
                      placeholder={config.placeholder}
                      label={config.label}
                    />
                  )}

                  {config.inputType === 'textArea' && (
                    <TextArea
                      key={index}
                      value={config.value}
                      name={config.name}
                      type={config.type}
                      onChange={config.onChange}
                      placeholder={config.placeholder}
                      label={config.label}
                      maxLength={300}
                      charLength={300}
                    />
                  )}
                </React.Fragment>
              ))}
              <Button
                type="button"
                onClick={async () => {
                  await saveHubBannerInfo();
                }}
                isDisabled={isDisabledSaveHubBannerInfo()}
                text="Save Changes"
              />
            </form>
          </div>
        </div>

        <div className="edit-content-container no-border">
          <div className="section-subtitle">
            <h4>Call to Action Buttons</h4>
            <span>This information will be shared with the public.</span>
          </div>
          <div className="section-form">
            <form>
              {hubCallToActionFormConfig.map((config, index) => (
                <React.Fragment key={index}>
                  {config.inputType === 'checkbox' && (
                    <Checkbox
                      key={index}
                      checked={Boolean(config.checked)}
                      value={config.value}
                      name={config.name}
                      onChange={config.onChange}
                      label={config.label}
                    />
                  )}
                </React.Fragment>
              ))}

              <Button
                type="button"
                onClick={async () => {
                  await saveHubCallToAction();
                }}
                isDisabled={isDisabledSaveHubCallToAction()}
                text="Save Changes"
              />
            </form>
          </div>
        </div>

        <h3 className="section-title">Tab Configuration</h3>
        <div className="edit-content-container no-border">
          <div className="section-subtitle">
            <span>Set tab names and visibility for your company page.</span>
          </div>
          <div className="section-form">
            <form>
              {pages.map((config, index) => (
                <div
                  className="tab-configuration"
                  key={index}
                >
                  <TextField
                    key={index}
                    value={config.label}
                    name={config.label}
                    onChange={(text) => {
                      config.label = text.target.value;
                      pages[index] = config;
                      setPages([...pages]);
                    }}
                  />
                  <Dropdown
                    onChange={(value) => {
                      config.visibility = value.target.value;
                      pages[index] = config;
                      setPages([...pages]);
                    }}
                    value={config.visibility}
                    shouldSort={true}
                    name={config.label}
                    options={hubVisibilityMenu || []}
                  />
                </div>
              ))}
              <Button
                type="button"
                onClick={async () => {
                  await savePageVisibility(props?.hub?.id || '');
                }}
                isDisabled={isDisabledSavePageVisibility()}
                text="Save Changes"
              />
            </form>
          </div>
        </div>

        <h3 className="section-title">Visibility Settings</h3>
        <div className="edit-content-container no-border">
          <div className="section-form">
            <form>
              {hubVisibilitySettingsFormConfig.map((config, index) => (
                <React.Fragment key={index}>
                  {config.inputType === 'checkbox' && (
                    <Checkbox
                      key={index}
                      checked={Boolean(config.checked)}
                      value={config.value}
                      name={config.name}
                      onChange={config.onChange}
                      label={config.label}
                    />
                  )}
                </React.Fragment>
              ))}
              <Button
                type="button"
                onClick={async () => {
                  await saveHubVisibilitySettings();
                }}
                isDisabled={isDisabledSaveHubVisibilitySettings()}
                text="Save Changes"
              />
            </form>
          </div>
        </div>

        <h3 className="section-title">Fee Payment</h3>
        <div className="edit-content-container">
          <div className="section-subtitle">
            <h4>Processing Fee</h4>
            <span>This is a standard third-party fee for processing online transactions.</span>
          </div>
          <div className="section-form">
            <div
              className="fee-option"
              onClick={() => {
                updateHubProcessingFee(false);
                setHubProcessingFee(false);
              }}
            >
              <div className="fee-radio">
                <i className={!hubProcessingFee ? 'fas fa-circle' : 'far fa-circle'} />
              </div>
              <div className="fee-content">
                <div className="fee-title">User pay fee</div>
                <div className="fee-description">
                  Fee is added to the users transaction (donations, auction winnings, store and
                  ticket purchases).
                </div>
              </div>
            </div>
            <div
              className="fee-option"
              onClick={() => {
                updateHubProcessingFee(true);
                setHubProcessingFee(true);
              }}
            >
              <div className="fee-radio">
                <i className={hubProcessingFee ? 'fas fa-circle' : 'far fa-circle'} />
              </div>
              <div className="fee-content">
                <div className="fee-title">Company pays fee</div>
                <div className="fee-description">
                  Fee is charged to your company per transaction (user does not see the fee).
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="edit-content-container">
          <div className="section-subtitle">
            <h4>Platform Fee</h4>
            <span>
              This small fee added onto transactions helps Kambeo cover basic operating expenses.
            </span>
          </div>
          <div className="section-form">
            <div
              className="fee-option"
              onClick={() => {
                updateHubPlatformFee(false);
                setHubPlatformFee(false);
              }}
            >
              <div className="fee-radio">
                <i className={!hubPlatformFee ? 'fas fa-circle' : 'far fa-circle'} />
              </div>
              <div className="fee-content">
                <div className="fee-title">User pays fee</div>
                <div className="fee-description">
                  Fee is added to the users transaction (donations, auction winnings, store and
                  ticket purchases).
                </div>
              </div>
            </div>
            <div
              className="fee-option"
              onClick={() => {
                updateHubPlatformFee(true);
                setHubPlatformFee(true);
              }}
            >
              <div className="fee-radio">
                <i className={hubPlatformFee ? 'fas fa-circle' : 'far fa-circle'} />
              </div>
              <div className="fee-content">
                <div className="fee-title">Company pays fee</div>
                <div className="fee-description">
                  Fee is charged to your company per transaction (user does not see the fee).
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HubManagementSettings;
