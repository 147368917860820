import React, { useState, useEffect, FC } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DiscoverCard from '../../components/DiscoverCard/DiscoverCard';
import OnBehalf from '../../assets/discover-event-on-behalf.png';
import Crowdfund from '../../assets/discover-event-crowdfund.png';
import axios from 'axios';
import { IDiscoverItem, IEvent, IEventSummaryFE } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import './DiscoverStyles.scss';
import Button from '../../components/Button/Button';
import {
  errorHelpers,
  formatQuery,
  generateEmptyCards,
  IQueryParams,
  routes,
  setSEOMetatags,
  swapRouteParams,
  toastError,
} from '../../helpers';
import { IGeolocationState } from '../../reducers/geolocation';
import { IAppState } from '../../store';
import { updateGeolocation } from '../../actions/geolocation';
import { uiConstants } from '../../constants';
import { createToast } from '../../actions/toaster';
import LandingDiscoverHeader from '../../components/LandingDiscoverHeader/LandingDiscoverHeader';
import { IUserState } from '../../reducers/user';
import { localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import DiscoverPagesFilter from './DiscoverPagesFilter/DiscoverPagesFilter';
import { IPopupMenuItem } from '../../components/shared/PopupMenu/PopupMenu';
import CardLoader from '../../components/shared/CardLoader/CardLoader';
import { ECardLoaderStyleType } from '../../components/shared/CardLoader/CardLoaderItem/CardLoaderItem';
import EmptyImg from '../../assets/no-cover-img.svg';
import { IDiscoverFilters } from '@gigit/interfaces/src/common/discover-filter.interface';
import { Config } from '@gigit/config';
import {
  CardCommonEvent,
  EStyleTypeCardCommonEvent,
  EViewTypeCardCommonEvent,
} from '../../components/CardCommonEvent/CardCommonEvent';
import { CardCommonEventSkeleton } from '../../components/CardCommonEvent/CardCommonEventSkeleton';

interface IProps extends RouteComponentProps {
  userState: IUserState;
}

const DiscoverEvents: FC<IProps> = (props: IProps) => {
  const geolocationState: IGeolocationState = useSelector(
    (state: IAppState) => state.geolocationState,
  );
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const loadDataLimit = 6;
  const dispatch = useDispatch();
  const [eventList, setEventList] = useState<IDiscoverItem[]>([]);
  const [skip, setSkip] = useState<number>(0);
  const [lastResponseCount, setLastResponseCount] = useState<number>(6);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [activeSortBy, setActiveSortBy] = useState<{ label: string; value: string }>({
    label: 'Happening Soon',
    value: uiConstants.discoverFilters.happeningSoon,
  });
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<string[]>([]);

  const sortByItems: IPopupMenuItem[] = [
    {
      id: uiConstants.discoverFilters.happeningSoon,
      label: 'Happening Soon',
      icon: 'far fa-clock',
      isSelected: activeSortBy?.value === uiConstants.discoverFilters.happeningSoon,
      onClick: async () => {
        setActiveSortBy({
          label: 'Happening Soon',
          value: uiConstants.discoverFilters.happeningSoon,
        });
      },
    },
    {
      id: uiConstants.discoverFilters.trending,
      label: 'Trending',
      icon: 'far fa-fire',
      isSelected: activeSortBy?.value === uiConstants.discoverFilters.trending,
      onClick: async () => {
        setActiveSortBy({ label: 'Trending', value: uiConstants.discoverFilters.trending });
      },
    },
    {
      id: uiConstants.discoverFilters.recent,
      label: 'Recently Added',
      icon: 'far fa-clock',
      isSelected: activeSortBy?.value === uiConstants.discoverFilters.recent,
      onClick: async () => {
        setActiveSortBy({ label: 'Recently Added', value: uiConstants.discoverFilters.recent });
      },
    },
  ];

  useEffect(() => {
    setSEOMetatags({
      title: localizeHelpers.translate('Discover Events | Kambeo'),
      urlPath: '',
    });
    dispatch(updateGeolocation());
  }, []);

  useEffect(() => {
    getEvents();
  }, [selectedFocusAreas, activeSortBy.value]);

  const getEvents = async (loadMore = false) => {
    setDataLoading(true);
    let filterData: any = {
      start_date: '',
    };

    let query = {} as IQueryParams;

    switch (activeSortBy.value) {
      case uiConstants.discoverFilters.happeningSoon:
        filterData.start_date = new Date(Date.now());
        query.sort = [{ id: 'start_date', order: 'asc' }];
        break;
      case uiConstants.discoverFilters.trending:
        filterData.trending = true;
        break;
      case uiConstants.discoverFilters.recent:
        query.sort = [{ id: 'created_at', order: 'desc' }];
        break;
    }

    const defaultCoords = uiConstants.defaultCoords;
    let location = {};
    let userLocation: string = '';

    if (props.userState.locations[0]) {
      userLocation =
        props.userState.locations[0].city +
        ' ' +
        props.userState.locations[0].state +
        ', ' +
        props.userState.locations[0].country;
    }

    if (isUserLoggedIn && userLocation !== '') {
      try {
        await fetch(
          'https://maps.googleapis.com/maps/api/geocode/json?address=' +
            userLocation +
            '&key=' +
            Config.web.REACT_APP_GOOGLE_API_KEY,
        )
          .then((response) => response.json())
          .then((data) => {
            location = {
              coordinates: [
                data.results[0].geometry.location.lng,
                data.results[0].geometry.location.lat,
              ],
              max_distance: 100,
              distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
            };
          });
      } catch (error) {
        const errObj = errorHelpers.getErrorObject(error);
        toastError(errObj.translatedMessage, 'Google Maps');
      }
    } else if (geolocationState) {
      location = {
        coordinates: [geolocationState.lng, geolocationState.lat],
        max_distance: 100,
        distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
      };
    } else {
      location = {
        coordinates: [defaultCoords.lng, defaultCoords.lat],
        max_distance: 100,
        distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
      };
    }

    try {
      let _payload = {
        location,
        filter: filterData,
        common: {
          causes: selectedFocusAreas.length ? selectedFocusAreas : undefined,
        },
      } as IDiscoverFilters;

      let route = swapRouteParams(routes.DISCOVER_EVENTS, {
        limit: loadDataLimit,
        skip: loadMore ? skip + loadDataLimit : skip,
      });

      setSkip(loadMore ? skip + loadDataLimit : skip);

      if (query) {
        route += `&${formatQuery(query).toString()}`;
      }

      let response = await axios.post(route, _payload);

      if (loadMore) {
        setEventList([...eventList, ...response.data]);
      } else {
        setEventList(response.data);
      }

      setLastResponseCount(response.data.length);

      setDataLoading(false);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Discover Events & Fundraisers');
      dispatch(createToast(toast));
    }
  };

  async function toggleFocusArea(value: string) {
    if (selectedFocusAreas.indexOf(value) === -1) {
      setSelectedFocusAreas([...selectedFocusAreas, value]);
    } else {
      setSelectedFocusAreas(selectedFocusAreas.filter((fa) => fa !== value));
    }
  }

  // NOTE: Set the Discover Events page to landing route, to be revisited when new landing page is implemented

  return (
    <React.Fragment>
      <div className="Discover">
        <LandingDiscoverHeader
          pathname={props.location.pathname}
          isUserLoggedIn={isUserLoggedIn}
        />
        <div className="discover-title">
          <div className="title">
            Explore Events in{' '}
            <u>
              {props.userState.locations && props.userState.locations[0] && isUserLoggedIn
                ? props.userState.locations[0].city +
                  ', ' +
                  props.userState.locations[0].state?.substring(0, 2).toUpperCase()
                : geolocationState.locality}
            </u>
          </div>
          <div className="mob-title">
            Explore Events in{' '}
            <u>
              {props.userState.locations && props.userState.locations[0] && isUserLoggedIn
                ? props.userState.locations[0].city +
                  ', ' +
                  props.userState.locations[0].state?.substring(0, 2).toUpperCase()
                : geolocationState.locality}
            </u>
          </div>
        </div>
        <div className="discover-filters">
          <DiscoverPagesFilter
            sortByItems={sortByItems}
            activeSortBy={activeSortBy}
            selectedFocusAreas={selectedFocusAreas}
            toggleFocusArea={toggleFocusArea}
          />
        </div>
        <div className={eventList.length === 0 ? 'discover-content empty' : 'discover-content'}>
          {!dataLoading && eventList.length > 0 && <div className="cards-background" />}

          {
            <div className="discover-list">
              {eventList.length > 0 && (
                <>
                  {eventList.map((event, index) => {
                    return (
                      <CardCommonEvent
                        styleType={EStyleTypeCardCommonEvent.DISCOVER}
                        viewType={EViewTypeCardCommonEvent.GRID}
                        event={event as unknown as IEventSummaryFE}
                        key={index}
                        locations={event.locations}
                        loading={dataLoading}
                        groupHandle={event.group_handle}
                        groupTitle={event.group_title}
                      />
                    );
                  })}

                  {eventList.length % 3 !== 0 &&
                    generateEmptyCards(3 - (eventList.length % 3), 'event')}

                  {lastResponseCount === 6 && (
                    <div className="load-section">
                      <Button
                        text="Load More"
                        buttonClass="explore-button"
                        type="button"
                        onClick={async () => {
                          await getEvents(true);
                        }}
                      />
                    </div>
                  )}
                </>
              )}

              {dataLoading && (
                <React.Fragment>
                  <CardCommonEventSkeleton
                    viewType={EViewTypeCardCommonEvent.GRID}
                    styleType={EStyleTypeCardCommonEvent.DISCOVER}
                  />
                  <CardCommonEventSkeleton
                    viewType={EViewTypeCardCommonEvent.GRID}
                    styleType={EStyleTypeCardCommonEvent.DISCOVER}
                  />
                  <CardCommonEventSkeleton
                    viewType={EViewTypeCardCommonEvent.GRID}
                    styleType={EStyleTypeCardCommonEvent.DISCOVER}
                  />
                </React.Fragment>
              )}
            </div>
          }

          {!dataLoading && eventList.length === 0 && (
            <div className="discover-list">{generateEmptyCards(3, 'event')}</div>
          )}
        </div>

        {/*TODO: All discover shortcut items can go into shared components if their role gets more complicated*/}
        <div className="discover-shortcuts-list">
          <div className="discover-shortcut-item light">
            <div className="discover-shortcut">
              <div className="shortcut-image">
                <img src={OnBehalf} />
              </div>
              <div className="shortcut-text">
                <div className="shortcut-title">Raise funds on behalf of organizations</div>
                <div className="shortcut-description">
                  Join as individuals and/or teams. Each individual and team receives their own
                  fundraising page to customize and track on the event leaderboard.
                </div>
                <div className="shortcut-link">
                  <Link
                    to={`${
                      isUserLoggedIn
                        ? '/onboarding/event/eventTypeSelection?type=p2p'
                        : '/register?redirect=onboarding/event/eventTypeSelection&type=p2p'
                    }`}
                  >
                    Create P2P Fundraiser
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="discover-shortcut-item alt dark">
            <div className="discover-shortcut">
              <div className="shortcut-text">
                <div className="shortcut-title">
                  Run an event or crowdfund for your organization!
                </div>
                <div className="shortcut-description">
                  Kambeo helps organizations raise funds from their community.
                </div>
                <div className="shortcut-link">
                  <Link
                    to={`${
                      isUserLoggedIn
                        ? '/onboarding/event/eventTypeSelection?type=crowdfunding'
                        : '/register?redirect=onboarding/event/eventTypeSelection&type=crowdfunding'
                    }`}
                  >
                    Create an Event
                  </Link>
                </div>
              </div>
              <div className="shortcut-image">
                <img src={Crowdfund} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiscoverEvents;
