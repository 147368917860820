import axios from 'axios';
import { Dispatch } from 'redux';
import { IUserState, initialUserState } from '../reducers/user';
import {
  routes,
  swapRouteParams,
  dataURItoBlob,
  toastError,
  toastSuccess,
  downloadFile,
} from '../helpers';
import { ChatActionTypes, getCurrentConversations, resetChatState } from './chat';
import { resetUserSearch } from './userSearch';
import { getProfileByHandle } from './profile';
import { resetGroupPermissions, resetGroupStateLocations } from './group';
import { Config } from '@gigit/config';
import { ProfileActionTypes, getProfilePageComponents } from './profile';
import { resetNotifications, getUnreadNotifications } from './notifications';
import { createToast } from './toaster';
import { localizeHelpers } from '../localizeHelpers';
import errorHelpers from '../helpers/errorHelpers';
import { ITransaction, IUser } from '@gigit/interfaces';

export enum UserActionTypes {
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_ERROR = 'UPDATE_ERROR',
  IS_UPDATE_LOADING = 'IS_UPDATE_LOADING',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export interface IUserUpdateAction extends IUserState {
  type: UserActionTypes.UPDATE_USER;
  isUserLoading: boolean;
}

export interface IUserUpdateErrorAction {
  type: UserActionTypes.UPDATE_ERROR;
  error: string;
  isLoginLoading: boolean;
}

export type UserActions = IUserUpdateAction | IUserUpdateErrorAction;

export const login = (payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isLoginLoading: true,
      type: UserActionTypes.UPDATE_USER,
    });

    axios
      .post(routes.LOGIN, payload)
      .then((response) => {
        // if (response.data.tokens.access_token) {
        //     axios.defaults.headers.common["Authorization"] = "Bearer "+response.data.tokens.access_token;
        // }

        getUserGroups()(dispatch, getState);
        getUserLocations()(dispatch, getState);
        dispatch({
          error: '',
          ...response.data,
          awaitingToken: false,
          isLoggedIn: true,
          type: UserActionTypes.UPDATE_USER,
        });

        dispatch({
          type: UserActionTypes.LOGIN,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          error: errorObj.translatedMessage,
          isLoggedIn: false,
          type: UserActionTypes.UPDATE_ERROR,
        });
      })
      .finally(() => {
        dispatch({
          isLoginLoading: false,
          type: UserActionTypes.UPDATE_USER,
        });

        updateLocalizeLanguageFromState()(dispatch, getState);
        getUnreadNotifications()(dispatch, getState);
      });
  };
};

export const loginWithSSO = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isLoginLoading: true,
      type: UserActionTypes.UPDATE_USER,
    });

    axios
      .get<IUser>(routes.GET_CURRENT_USER)
      .then((response) => {
        dispatch({
          error: '',
          user: response.data,
          awaitingToken: false,
          isLoggedIn: true,
          type: UserActionTypes.UPDATE_USER,
        });

        dispatch({
          type: UserActionTypes.LOGIN,
        });

        getUserGroups()(dispatch, getState);
        getUserLocations()(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          error: errorObj.translatedMessage,
          isLoggedIn: false,
          type: UserActionTypes.UPDATE_ERROR,
        });
      })
      .finally(() => {
        dispatch({
          isLoginLoading: false,
          type: UserActionTypes.UPDATE_USER,
        });

        updateLocalizeLanguageFromState()(dispatch, getState);
        getUnreadNotifications()(dispatch, getState);
      });
  };
};

export const verifyEmail = (_token: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.post(routes.VERIFY_EMAIL, { value: _token }).then((response) => {
      dispatch({
        error: '',
        user: { ...response.data },
        type: UserActionTypes.UPDATE_USER,
      });
    });
  };
};

export const getUserInfo = (handleOrId: string, cb?: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_USER_BY_HANDLE, { handleOrId: handleOrId }))
      .then((response) => {
        dispatch({
          error: '',
          user: { ...response.data },
          type: UserActionTypes.UPDATE_USER,
        });
      })
      .finally(() => {
        if (cb) {
          cb();
        }
      });
  };
};

export const getGigsExport = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(routes.GET_GIGS_EXPORT, { responseType: 'blob' })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'Gig_Hours.pdf';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Gigs Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

/** Refreshes the access token in the store. See App.tsx for how this is called.
 * @param _token This should be the current refesh token. TODO: remove this param and just get from current store.
 * @param handler Callback that is called when new access token is retreived
 */
export const refreshToken = (handler: (err?: any, token?: string) => void) => {
  return async (dispatch: Dispatch, getState: any) => {
    if (!getState().userState.awaitingToken) {
      dispatch({
        awaitingToken: true,
        type: UserActionTypes.UPDATE_USER,
      });

      axios
        .post(routes.REFRESH_TOKEN)
        .then((response) => {
          dispatch({
            error: '',
            ...response.data,
            awaitingToken: false,
            isLoggedIn: true,
            type: UserActionTypes.UPDATE_USER,
          });

          // reconnect chat
          dispatch({
            type: ChatActionTypes.RECONNECT_CHAT,
          });

          handler(null, response.data.tokens.access_token);
        })
        .catch((error) => {
          // Instead of showing generic error, we tell the user to login again.
          if (
            error.response &&
            error.response.data.gigitErrorCode === 'ERROR.AUTH.REFRESH_TOKEN_EXPIRED'
          ) {
            // Sometimes we end up sending an empty refresh token, in that error the error code is null.
            // We can safely ignore the error because of the handler above. These calls seem to be getting sent after the refresh token is expired.
            // TODO: We should handle this properly.
          } else if (
            (error.response && error.response.data.gigitErrorCode !== null) ||
            (error.response && error.response.data.message !== '')
          ) {
            const errorObj = errorHelpers.getErrorObject(error);
            let toast = toastError(
              errorObj.translatedMessage,
              'Your session has expired, please sign back in.',
            );
            createToast(toast)(dispatch, getState);
          }

          handler(error);
        });
    }
  };
};

export const logout = () => {
  return async (dispatch: Dispatch, getState: any) => {
    // delete axios.defaults.headers.common["Authorization"];

    dispatch({
      ...initialUserState,
      groups: [],
      tickets: [],
      purchases: [],
      donations: [],
      applications: [],
      auctionItems: [],
      gigs: [],
      error: '',
      type: UserActionTypes.UPDATE_USER,
    });

    // used to d/c the socket
    dispatch({
      type: UserActionTypes.LOGOUT,
    });

    resetGroupPermissions()(dispatch, getState);
    resetChatState()(dispatch);
    resetUserSearch()(dispatch, getState);
    resetNotifications()(dispatch, getState);
    resetGroupStateLocations()(dispatch, getState);

    updateLocalizeLanguageFromState();

    axios.get(routes.LOGOUT);

    if (getState().userState.awaitingToken) {
      dispatch({
        awaitingToken: false,
        type: UserActionTypes.UPDATE_USER,
      });

      window.location.reload();
    }
  };
};

export const resetLogin = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      error: '',
      isLoginLoading: false,
      type: UserActionTypes.UPDATE_ERROR,
    });
  };
};

export const updateUser = (
  payload: any,
  updateProfile?: boolean,
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(routes.UPDATE_USER, payload)
      .then((response) => {
        dispatch({
          user: response.data,
          isUserLoading: true,
          type: UserActionTypes.UPDATE_USER,
        });

        if (updateProfile) {
          getProfileByHandle(payload.handle, { callback: options?.callback })(dispatch, getState);
        } else {
          options?.callback?.();
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          error: errorObj.translatedMessage,
          type: UserActionTypes.UPDATE_ERROR,
        });
      })
      .finally(() => {
        dispatch({
          isUserLoading: false,
          type: UserActionTypes.UPDATE_USER,
        });
      });
  };
};

/** Changes the handle of a User, which changes the URL of the group.
 * @param onChanged Callback that is invoked once the handle is changed.
 */
export const changeUserHandle = (newHandle: string, onChanged: () => void) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(routes.CHANGE_USER_HANDLE, { value: newHandle })
      .then((response) => {
        // Update Redux state with new handle
        dispatch({
          user: { ...getState().userState.user, handle: newHandle },
          type: UserActionTypes.UPDATE_USER,
        });

        onChanged();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Cause');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateUserEmail = (_payload: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(routes.UPDATE_USER_EMAIL, _payload)
      .then((response) => {
        let _user = { ...getState().userState.user, ...{ email: response.data.value } };

        updateUser(_user, true)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Email update error.');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getUserGroups = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isLoginLoading: true,
      type: UserActionTypes.UPDATE_USER,
    });

    axios
      .get(swapRouteParams(routes.GET_USER_GROUPS, {}))
      .then((response) => {
        dispatch({
          groups: response.data,
          type: UserActionTypes.UPDATE_USER,
        });
      })
      .finally(() => {
        dispatch({
          isLoginLoading: false,
          type: UserActionTypes.UPDATE_USER,
        });
      });
  };
};

export const getUserGroupsMonetized = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isLoginLoading: true,
      type: UserActionTypes.UPDATE_USER,
    });
    axios
      .get(routes.GET_USER_GROUPS_MONETIZED)
      .then((response) => {
        dispatch({
          monetizedGroups: response.data,
          type: UserActionTypes.UPDATE_USER,
        });
      })
      .finally(() => {
        dispatch({
          isLoginLoading: false,
          type: UserActionTypes.UPDATE_USER,
        });
      });
  };
};

export const addUserGroup = (_group: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _groups = getState().userState.groups;
    _groups.push(_group);

    dispatch({
      groups: _groups,
      type: UserActionTypes.UPDATE_USER,
    });
  };
};

export const updateUserCoverImage = (img: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const blob = dataURItoBlob(img);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let _user = { ...getState().userState.user, ...{ cover_image_url: response.data.value } };

        updateUser(_user, true)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cover image upload error.');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateUserProfileImage = (img: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const blob = dataURItoBlob(img);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let _user = { ...getState().userState.user, ...{ profile_image_url: response.data.value } };

        updateUser(_user, true)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Profile image upload error.');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createUserPage = (_payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isProfilePagesLoading: true,
      type: ProfileActionTypes.UPDATE_PROFILE,
    });

    axios
      .post(swapRouteParams(routes.GET_PROFILE_PAGES, { userId: 'current' }), _payload)
      .then((response) => {
        dispatch({
          pages: [...getState().profileState.pages, ...[response.data]],
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'User profile page.');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isProfilePagesLoading: false,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      });
  };
};

export const updateUserPage = (_payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isProfilePagesLoading: true,
      type: ProfileActionTypes.UPDATE_PROFILE,
    });

    axios
      .put(swapRouteParams(routes.UPDATE_PROFILE_PAGE, { pageId: _payload.id }), _payload)
      .then((response) => {
        let _pages = [...getState().profileState.pages];

        for (let p in _pages) {
          if (_pages[parseInt(p)].id === _payload.id) {
            _pages[parseInt(p)] = response.data;
          }
        }

        dispatch({
          pages: _pages,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      })
      .finally(() => {
        dispatch({
          isProfilePagesLoading: false,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      });
  };
};

export const deleteUserPage = (_pageId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isProfilePagesLoading: true,
      type: ProfileActionTypes.UPDATE_PROFILE,
    });

    axios
      .delete(swapRouteParams(routes.DELETE_PROFILE_PAGE, { pageId: _pageId }))
      .then((response) => {
        let _pages = [...getState().profileState.pages];

        for (let p in _pages) {
          if (_pages[parseInt(p)].id === _pageId) {
            _pages.splice(parseInt(p), 1);
            break;
          }
        }

        dispatch({
          pages: _pages,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      })
      .finally(() => {
        dispatch({
          isProfilePagesLoading: false,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      });
  };
};

export const createUserPageComponent = (_pageId: string, _payload: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_USER_PAGE_COMPONENT, { pageId: _pageId }), _payload)
      .then((response) => {
        let userId: string = getState().profileState.user.id;

        getProfilePageComponents(userId, _pageId)(dispatch, getState);
      });
  };
};

export const deleteUserPageComponent = (_pageId: string, _componentId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(
        swapRouteParams(routes.DELETE_USER_PAGE_COMPONENT, {
          pageId: _pageId,
          componentId: _componentId,
        }),
      )
      .then((response) => {
        let userId: string = getState().profileState.user.id;

        getProfilePageComponents(userId, _pageId)(dispatch, getState);
      });
  };
};

export const updateUserPageComponent = (_pageId: string, _componentId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(
        swapRouteParams(routes.UPDATE_USER_PAGE_COMPONENT, {
          pageId: _pageId,
          componentId: _componentId,
        }),
        _payload,
      )
      .then((response) => {
        let userId: string = getState().profileState.user.id;

        getProfilePageComponents(userId, _pageId)(dispatch, getState);
      });
  };
};

export const getUserGroupApplications = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isApplicationLoading: true,
      type: UserActionTypes.UPDATE_USER,
    });

    axios
      .get(swapRouteParams(routes.GET_USER_GROUP_APPLICATION, { groupId: groupId }))
      .then((r1) => {
        let _applications = { ...getState().userState.currentApplications };

        if (r1.data.length > 0) {
          _applications.group = r1.data[0];
        } else {
          _applications.group = initialUserState.currentApplications.group;
        }

        axios
          .get(swapRouteParams(routes.GET_USER_GIG_APPLICATIONS, { groupId: groupId }))
          .then((r2) => {
            _applications.gigs = r2.data;
          })
          .finally(() => {
            dispatch({
              currentApplications: _applications,
              isApplicationLoading: false,
              type: UserActionTypes.UPDATE_USER,
            });
          });
      });
  };
};

export const getUserPurchases = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_USER_PURCHASES).then((response) => {
      dispatch({
        purchases: response.data,
        type: UserActionTypes.UPDATE_USER,
      });
    });
  };
};

export const getUserTickets = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_USER_TICKETS).then((response) => {
      dispatch({
        tickets: response.data,
        type: UserActionTypes.UPDATE_USER,
      });
    });
  };
};

export const getUserSponsorships = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_USER_SPONSORSHIPS).then((response) => {
      dispatch({
        sponsorships: response.data,
        type: UserActionTypes.UPDATE_USER,
      });
    });
  };
};

export const getUserDonations = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_USER_DONATIONS).then((response) => {
      dispatch({
        donations: response.data,
        type: UserActionTypes.UPDATE_USER,
      });
    });
  };
};

export const getUserApplications = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_USER_APPLICATIONS).then((response) => {
      dispatch({
        applications: response.data,
        type: UserActionTypes.UPDATE_USER,
      });
    });
  };
};

export const getUserGigs = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_USER_GIG_SHIFTS).then((response) => {
      dispatch({
        gigs: response.data,
        type: UserActionTypes.UPDATE_USER,
      });
    });
  };
};

export const sendResetPasswordEmail = (_email: string, _msg: string, _title: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(routes.RESET_PASSWORD_EMAIL, { value: _email })
      .then((response) => {
        if (response.data.value === 'ok') {
          const toast = toastSuccess(localizeHelpers.translate(_msg), _title);
          createToast(toast)(dispatch, getState);
        } else {
        }
      })
      .catch((err) => {
        const errObj = errorHelpers.getErrorObject(err);
        const toast = toastError(errObj.translatedMessage, _title);
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updatePassword = (_password: string, _token: string, callback?: () => void) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(routes.RESET_PASSWORD, { token: _token, password: _password })
      .then((response) => {
        dispatch({
          error: '',
          ...response.data,
          type: UserActionTypes.UPDATE_USER,
        });
        callback?.();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Set Password');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const downloadDonationReceipt = (donation: ITransaction) => {
  return async (dispatch: Dispatch, getState: any) => {
    const { receipt_number, tax_receipt_prefix, id } = donation;
    axios
      .get(swapRouteParams(routes.DOWNLOAD_DONATION_RECEIPT, { donationId: id }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'application/pdf' });
        const fileName = localizeHelpers.translate('Donation receipt');
        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download =
          (tax_receipt_prefix ? tax_receipt_prefix + ' ' : '') +
          fileName +
          ' ' +
          (receipt_number ? '#' + receipt_number : id);
        document.body.appendChild(_a);
        _a.click();
      });
  };
};

export const downloadPurchaseReceipt = (_transactionId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(
        swapRouteParams(routes.DOWNLOAD_USER_PURCHASE_RECEIPT, { transaction_id: _transactionId }),
        { responseType: 'blob' },
      )
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'application/pdf' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'purchase_' + _transactionId;
        document.body.appendChild(_a);
        _a.click();
      });
  };
};

export const downloadAuctionReceipt = (transactionId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(
        swapRouteParams(routes.DOWNLOAD_USER_AUCTION_RECEIPT, { transaction_id: transactionId }),
        { responseType: 'blob' },
      )
      .then((response) => {
        downloadFile(`auction_${transactionId}`, response.data, 'application/pdf');
      });
  };
};

export const downloadTicket = (_ticketId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.DOWNLOAD_TICKET, { ticketId: _ticketId }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'application/pdf' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'ticket_' + _ticketId;
        document.body.appendChild(_a);
        _a.click();
      });
  };
};

export const getUserLocations = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_USER_LOCATIONS).then((response) => {
      dispatch({
        locations: response.data,
        type: UserActionTypes.UPDATE_USER,
      });
    });
  };
};

export const updateUserLocation = (_locationId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.UPDATE_USER_LOCATION, { location_id: _locationId }), _payload)
      .then((response) => {
        getUserLocations()(dispatch, getState);
      });
  };
};

export const deleteUserLocation = (_locationId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(swapRouteParams(routes.UPDATE_USER_LOCATION, { location_id: _locationId }))
      .then((response) => {
        getUserLocations()(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete User Locations');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createUserLocation = (_payload: any, showToast?: boolean) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(routes.CREATE_USER_LOCATION, _payload)
      .then((response) => {
        getUserLocations()(dispatch, getState);
      })
      .then(() => {
        if ((showToast ?? true) === true) {
          const toast = toastSuccess(localizeHelpers.translate('Location Created'), 'Location');
          createToast(toast)(dispatch, getState);
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create User Locations');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getUserSubscriptions = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_USER_SUBSCRIPTIONS).then((response) => {
      dispatch({
        donationSubscriptions: response.data,
        type: UserActionTypes.UPDATE_USER,
      });
    });
  };
};

export const cancelUserSubscription = (_id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CANCEL_USER_SUBSCRIPTION, { id: _id }))
      .then((response) => {
        const toast = toastSuccess(
          localizeHelpers.translate('Your donation Subscription has been cancelled.'),
          'Donation Subscription',
        );
        createToast(toast)(dispatch, getState);
        getUserSubscriptions()(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, '"Subscription"');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const logError = (_payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.post(Config.web.REACT_APP_LOGGER_API + '/logs', _payload).then((response) => {});
  };
};

export const getUserAuctionItems = (search?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      auctionItemsLoading: true,
      type: UserActionTypes.UPDATE_USER,
    });

    let _route: string = routes.GET_USER_AUCTION_ITEMS;

    if (search) {
      _route = _route + '?search=' + search;
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          auctionItems: response.data,
          type: UserActionTypes.UPDATE_USER,
        });
      })
      .finally(() => {
        dispatch({
          auctionItemsLoading: false,
          type: UserActionTypes.UPDATE_USER,
        });
      });
  };
};

export const resendEmailVerification = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(routes.RESEND_EMAIL_VERIFICATION)
      .then((response) => {
        const toast = toastSuccess(
          localizeHelpers.translate(
            'Your verification email has been sent to the associated email.',
          ),
          'Email Verification',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Email Verification');
        createToast(toast)(dispatch, getState);
      });
  };
};

/** This updates the "Logged out" language used when a user is logged out of the app.
 */
export const updateLoggedOutLanguage = (
  newLanguage: string,
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      loggedOutLanguage: newLanguage,
      type: UserActionTypes.UPDATE_USER,
    });

    options?.callback?.();
  };
};

export const updateIsUserSessionConnectedToDataDog = (value: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      isUserSessionConnectedToDataDog: value,
      type: UserActionTypes.UPDATE_USER,
    });
  };
};

/** Updates the Localize language based on redux state.
 */
export const updateLocalizeLanguageFromState = () => {
  return (dispatch: Dispatch, getState: any) => {
    updateLocalizeLanguageFromUserState(getState().userState);
  };
};

/** Updates the localize language based on the provided userState directly.
 * This can be used when initial load of the App (Calling updateLocalizeLanguageFromState() can have a delay from redux so localize language may not be set).
 */
export function updateLocalizeLanguageFromUserState(userState: IUserState) {
  const isLoggedIn = userState.isLoggedIn;
  const userLanguage = userState.user.language || 'en';
  const loggedOutLanguage = userState.loggedOutLanguage;

  Localize.setLanguage(isLoggedIn ? userLanguage : loggedOutLanguage);
}

export const updateLocalizeLanguage = (newLanguage: string) => {
  return (dispatch: Dispatch, getState: any) => {
    Localize.setLanguage(newLanguage);
  };
};

export const getUserMyPageItems = (search?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      userMyPagesItemsLoading: true,
      type: UserActionTypes.UPDATE_USER,
    });

    let _route: string = routes.GET_USER_MYPAGE_ITEMS;

    if (search) {
      _route = _route + '?pages_type=' + search;
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          userMyPagesItems: response.data,
          type: UserActionTypes.UPDATE_USER,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, '"My Pages List"');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          userMyPagesItemsLoading: false,
          type: UserActionTypes.UPDATE_USER,
        });
      });
  };
};
