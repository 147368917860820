import React from 'react';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import './BulkErrors.scss';

interface IProps {
  showBulkErrors: boolean;
  hideModal(): void;
  bulkErrorMessages: string[];
}

export const BulkErrors: React.FC<IProps> = (props) => {
  return (
    <Modal
      contentClassName="BulkErrors"
      show={props.showBulkErrors}
      onClose={() => {
        props.hideModal();
      }}
    >
      <div className="error-messages-modal">
        <div className="title">Bulk Action Errors</div>
        <div className="error-messages">
          {props.bulkErrorMessages.map((message) => {
            return <div className="bulk-error-message">{message}</div>;
          })}
        </div>
        <div className="error-actions">
          <Button
            text="Close"
            onClick={() => {
              props.hideModal();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
