import React, { useEffect, useState } from 'react';

import { PresenceIndicator } from '../PresenceIndicator/PresenceIndicator';
import Portrait from '../../Portrait/Portrait';

import './ConversationTile.scss';
import { IConversationSummary, IEmbeddedUser, IUserBase } from '@gigit/interfaces';
import moment from 'moment';
import { getOnlinePresence } from '../ChatHelpers';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { chatSelectors } from '../../../selectors/chat';
import { useHistory } from 'react-router-dom';
import { userRequestActions } from '../../../requestActions';
import useToastDispatcher from '../../../hooks/useToaster';
import PopupMenu, { IPopupMenuItem } from '../../shared/PopupMenu/PopupMenu';

interface IConversationTileProps {
  conversation: IConversationSummary;
  userId: string;
  type: string;
  unreadCount: number;
  showContext?: boolean;
}

export const ConversationTile: React.FC<IConversationTileProps> = (
  props: IConversationTileProps,
) => {
  const user = props.conversation?.users
    ? props.conversation?.users?.find((u: any) => u.id !== props.userId)
    : props.conversation?.tmp_users?.find((u: any) => u.id !== props.userId);

  const currentUser = useSelector((state: IAppState) => state.userState);
  const onlineUsers = useSelector((state: IAppState) => chatSelectors.getOnlineUsers(state));
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const history = useHistory();
  const { dispatchToastError } = useToastDispatcher();
  // helper for this?
  function formatPreview(msg: string) {
    return msg.replace(/(<([^>]+)>)/gi, ' ');
  }
  function getPreview(userId: string) {
    if (props.type === 'message') {
      return props.conversation &&
        props.conversation.last_message &&
        props.conversation.last_message.content
        ? formatPreview(props.conversation.last_message.content)
        : '';
    } else if (props.type === 'contact') {
      return getOnlinePresence(userId, onlineUsers) ? 'Online' : 'Offline';
    }
  }

  function getMenuConfig(user: IUserBase | IEmbeddedUser) {
    const popupMenuItems: IPopupMenuItem[] = [
      {
        id: 'profile',
        label: 'View Profile',
        icon: '',
        onClick: () => {
          history.push(`/user/${user.handle}`);
        },
      },
      {
        id: 'report',
        label: 'Report',
        icon: '',
        onClick: () => {},
      },
      {
        id: 'remove_connection',
        label: 'Remove Connection',
        icon: '',
        onClick: async () => {
          try {
            await userRequestActions.removeContact(user.id || '');
          } catch (error) {
            dispatchToastError(error, 'Remove Connection');
          }
        },
      },
    ];

    return popupMenuItems;
  }

  return (
    <div className="ConversationTile">
      <div className="convo-info-wrapper">
        {user && (
          <React.Fragment>
            {props.conversation.is_group ? (
              <>
                {props.conversation.users
                  ? props.conversation.users?.map((convoUser, index) => {
                      let groupedTitle = [];
                      if (index < 5) {
                        return (
                          <>
                            <Portrait
                              key={index}
                              title={convoUser.display_name}
                              className="group-convo-users"
                              onClick={() => history.push(`/user/${convoUser?.handle}`)}
                              source={convoUser?.profile_image_url}
                              size={40}
                            />
                          </>
                        );
                      } else {
                        groupedTitle.push(convoUser.display_name);
                      }

                      if (
                        props.conversation.users &&
                        index == props.conversation.users?.length - 1
                      ) {
                        return (
                          <>
                            {props.conversation.users?.length && (
                              <div
                                key={index}
                                title={groupedTitle.join(',')}
                                className="group-conter-icon"
                              >
                                + {props.conversation.users?.length - 5}
                              </div>
                            )}
                          </>
                        );
                      }
                    })
                  : props.conversation.tmp_users?.map((convoUser, index) => {
                      let groupedTitle = [];
                      if (index < 5) {
                        return (
                          <>
                            <Portrait
                              key={index}
                              title={convoUser.display_name}
                              className="group-convo-users"
                              onClick={() => history.push(`/user/${convoUser?.handle}`)}
                              source={convoUser?.profile_image_url}
                              size={40}
                            />
                          </>
                        );
                      } else {
                        groupedTitle.push(convoUser.display_name);
                      }

                      if (
                        props.conversation.tmp_users &&
                        index == props.conversation.tmp_users?.length - 1
                      ) {
                        return (
                          <>
                            {props.conversation.tmp_users?.length && (
                              <div
                                key={index}
                                title={groupedTitle.join(',')}
                                className="group-conter-icon"
                              >
                                + {props.conversation.tmp_users?.length - 5}
                              </div>
                            )}
                          </>
                        );
                      }
                    })}
              </>
            ) : (
              <>
                <Portrait
                  onClick={() => history.push(`/user/${user?.handle}`)}
                  source={user?.profile_image_url}
                  size={40}
                />
                <PresenceIndicator isOnline={Boolean(getOnlinePresence(user.id, onlineUsers))} />
              </>
            )}
          </React.Fragment>
        )}
        <div className={`convo-li ${props.conversation.is_group ? 'expanded' : ''}`}>
          <div
            className="convo-li-title"
            notranslate="yes"
          >
            {props.conversation.is_group
              ? `${user?.display_name} + ${props.conversation.users ? props.conversation.users?.length : props.conversation.tmp_users && props.conversation.tmp_users?.length - 1} Others`
              : user?.display_name}
          </div>
          <div
            className="convo-li-preview"
            notranslate="yes"
          >
            {getPreview(user?.id || '')}
          </div>
        </div>
      </div>
      {!props.conversation.is_group && user && props.showContext && (
        <PopupMenu
          showMenuConfig={{
            position: {
              type: 'bottom',
              alternate: 'start',
            },
            showMenu: showMenu,
            setShowMenu: () => setShowMenu(!showMenu),
          }}
          menuItems={getMenuConfig(user)}
          className={`action-menu-target`}
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <i className={`fa fa-ellipsis-v ${showMenu ? 'show-menu' : ''}`} />
        </PopupMenu>
      )}
      {props.type === 'message' && (
        <div className="message-time">
          {moment(props.conversation.last_message.updated_at).fromNow()}
          {props.unreadCount > 0 && <span>{props.unreadCount}</span>}
        </div>
      )}
    </div>
  );
};
