import { DiversityType } from 'check-password-strength';
import { IOnboardStep } from '../../components/shared/Onboarding/interfaces';

export const registerPasswordRules: DiversityType[] = [
  'uppercase',
  'lowercase',
  'number',
  'symbol',
];
export const registerStepsMaster: Array<IOnboardStep> = [
  {
    id: 'baseUserInfo',
    text: 'Welcome to Kambeo! The first step is to create your personal account.',
    tip: 'Once you have a personal account you can immediately start engaging in the community, claim a charity page or create a company Hub',
    summaryTitle: '',
    data: [
      { path: 'userInfo.firstName', required: true },
      { path: 'userInfo.lastName', required: true },
      { path: 'userInfo.password', required: true },
      { path: 'userInfo.email', required: true },
      { path: 'userInfo.isOfAge', required: false },
    ],
  },
  {
    id: 'verification',
    text: "Almost there! Let's verify that it's you.",
    tip: null,
    summaryTitle: '',
    data: [
      { path: 'userInfo.verification.hasGeneratedCode', required: true },
      { path: 'userInfo.verification.code', required: true },
      { path: 'userInfo.verification.isValid', required: true },
    ],
  },
  {
    id: 'imageSelection',
    text: "Hi {{firstName}}, let's customize your profile!",
    tip: 'These settings can be updated at any time.',
    summaryTitle: '',
    data: [
      { path: 'profileImage', required: false },
      { path: 'coverImage', required: true },
    ],
  },
  {
    id: 'causesSelection',
    text: 'Choose focus areas that best describe your support',
    tip: "Let's understand what you stand for. You can always change them later.",
    summaryTitle: 'Focus Areas',
    data: [{ path: 'causeInfo.selectedCauses', required: false }],
  },
];
