import './DiscoverPagesFilter.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PopupMenu, {
  IPopupMenuItem,
  IShowPopupConfig,
} from '../../../components/shared/PopupMenu/PopupMenu';
import { causeRequestActions } from '../../../requestActions';
import { ICause } from '@gigit/interfaces';
import useToastDispatcher from '../../../hooks/useToaster';
import { Constants } from '@gigit/constants';

interface IProps {
  sortByItems: IPopupMenuItem[];
  activeSortBy: { label: string; value: string };
  selectedFocusAreas: string[];
  toggleFocusArea(v: string): void;
}

const DiscoverPagesFilter: React.FC<IProps> = (props) => {
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [focusAreas, setFocusAreas] = useState<ICause[]>([]);
  const { dispatchToastError } = useToastDispatcher();
  const focusAreaWrapper = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  let timer: NodeJS.Timeout;

  async function getCauses() {
    try {
      const result = await causeRequestActions.getCauses();
      setFocusAreas(result);
    } catch (error) {
      dispatchToastError(error, 'Get Focus Areas');
    }
  }

  useEffect(() => {
    getCauses();
  }, []);

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  const popupMenuConfig: IShowPopupConfig = {
    showMenu: showContextMenu,
    setShowMenu: setShowContextMenu,
    position: {
      type: 'bottom',
    },
  };

  let pos = { top: 0, left: 0, x: 0, y: 0 };

  const mouseMoveHandler = function (e?: MouseEvent) {
    if (focusAreaWrapper?.current && e) {
      const dx = e.clientX - pos.x;

      focusAreaWrapper.current.scrollTop = pos.top - dx;
      setIsDragging(true);
    }
  };

  const mouseUpHandler = function () {
    if (focusAreaWrapper?.current) {
      focusAreaWrapper.current.style.removeProperty('user-select');

      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
      timer = setTimeout(() => setIsDragging(false), 200);
    }
  };

  const mouseDownHandler = function (e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (focusAreaWrapper?.current) {
      focusAreaWrapper.current.style.userSelect = 'none';

      pos = {
        left: focusAreaWrapper.current.scrollLeft,
        top: focusAreaWrapper.current.scrollTop,

        x: e.clientX,
        y: e.clientY,
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }
  };

  const getActiveSearchType = () => {
    const activePage = history.location.pathname.includes(Constants.object_type.event)
      ? `${Constants.object_type.event}s`
      : history.location.pathname.includes(Constants.object_type.group)
        ? `${Constants.object_type.group}s`
        : history.location.pathname.includes('companies')
          ? 'companies'
          : 'volunteer';
    return activePage;
  };

  return (
    <div className="DiscoverPagesFilter">
      <PopupMenu
        showMenuConfig={popupMenuConfig}
        menuItems={props.sortByItems}
        popupMenuClass="DiscoverPagesFilter-popupMenu"
        className={`DiscoverPagesFilter-Dropdown filter-dropdown ${showContextMenu ? 'active' : ''}`}
        onClick={() => setShowContextMenu(!showContextMenu)}
        onSelect={() => setShowContextMenu(false)}
      >
        <label>
          <span>Sort by</span>
        </label>
        <div className="input-wrap">
          <span className="label">{props.activeSortBy.label}</span>
          <i className={`fas fa-caret-down menu-btn ${showContextMenu ? 'active' : ''}`} />
        </div>
      </PopupMenu>
      <div className="focus-area-container">
        <div
          className="wrapper"
          onMouseDown={(e) => mouseDownHandler(e)}
          ref={focusAreaWrapper}
        >
          {getActiveSearchType() !== 'companies' && (
            <div>
              <li
                onClick={() =>
                  history.replace({ pathname: `/search`, state: getActiveSearchType() })
                }
              >
                <span className="inner">
                  <i className="fa fa-search" />
                  {'Search All'}
                </span>
              </li>

              <li className="vertical-line" />
            </div>
          )}
          {focusAreas.map((item, index) => (
            <li
              key={index}
              className={`${props.selectedFocusAreas.find((fa) => fa === item.id) ? 'active' : ''}`}
              onClick={() => {
                if (item?.id && !isDragging) {
                  props.toggleFocusArea(item.id);
                }
              }}
            >
              <span className="inner">
                <i className="fas fa-check" />
                {item.cause}
              </span>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DiscoverPagesFilter;
