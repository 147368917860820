import React from 'react';
import Joyride from 'react-joyride';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Constants, GroupManagePermissions } from '@gigit/constants';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { IStringMap, mapPermissions, setSEOMetatags, toastError } from '../../helpers';
import { IToast } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import { IAppState } from '../../store';
import { IGroupState } from '../../reducers/group';
import { IUserState } from '../../reducers/user';
import { IEventState } from '../../reducers/event';
import { getGroup } from '../../actions/group';
import { getGroupPermissions } from '../../actions/settings';
import { createToast } from '../../actions/toaster';
import { groupSelectors } from '../../selectors/group';
import Login from '../Login/Login';
import LocationManagement from '../../components/LocationManagement/LocationManagement';
import StoreItemManagement from '../../components/StoreItemManagement/StoreItemManagement';
import DiscountCodeManagement from '../../components/DiscountCodeManagement/DiscountCodeManagement';
import CampaignManagement from '../../components/CampaignManagement/CampaignManagement';
import FormManagement from '../../components/FormManagement/FormManagement';
import RoleManagement from '../../components/RoleManagement/RoleManagement';
import SettingsManagement from '../../components/SettingsManagement/SettingsManagement';
import DonorManagement from '../../components/DonorManagement/DonorManagement';
import MemberManagement from '../../components/MemberManagement/MemberManagement';
import VolunteerManagement from '../../components/VolunteerManagement/VolunteerManagement';
import TransactionManagement from '../../components/TransactionManagement/TransactionManagement';
import Scheduler from '../../components/Scheduler/Scheduler';
import Construction from '../../components/Construction/Construction';
import GuidedSetup from '../../components/GuidedSetup/GuidedSetup';
import HourManagement from '../../components/HourManagement/HourManagement';
import CommunityImpact from '../../components/CommunityImpact/CommunityImpact';
import SponsorManagement from '../../components/SponsorManagement/SponsorManagement';
import DonorStatusDashboard from '../../components/DonorStatusDashboard/DonorStatusDashboard';
import GigManagement from '../../components/GigManagement/GigManagement';
import EmbedManagement from '../../components/EmbedManagement/EmbedManagement';
import GroupSummary from '../../components/GroupSummary/GroupSummary';
import HubManagement from '../../components/HubManagement/HubManagement';
import Modal from '../../components/Modal/Modal';
import SponsorshipsManagement from '../../components/SponsorshipSettingsManagement/SponsorshipSettingsManagement';
import EventManagement from '../../components/EventManagement/EventManagement';
import CategoriesManagement from '../../components/CategoriesManagement/CategoriesManagement';
import GroupFundraiserManage from '../GroupFundraiserManage/GroupFundraiserManage';
import ManagementSidebar, {
  IMenuSection,
} from '../../components/ManagementSidebar/ManagementSidebar';
import './GroupAdmin.scss';
import typeHelpers from '../../helpers/typeHelpers';
import { BalanceAndPayoutsManage } from '../../components/BalanceAndPayoutsManage/BalanceAndPayoutsManage';
import PlanManagement from '../../components/PlanManagement/PlanManagement';
import ExternalVolunteerApplicationWarning from '../../components/shared/ExternalVolunteerApplicationWarning/ExternalVolunteerApplicationWarning';

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  groupState: IGroupState;
  eventState: IEventState;
  getGroup(_handle: string): void;
  getGroupPermissions(): void;
  createToast(toast: IToast): void;
  groupPrimaryHubId: string | null;
}

interface IState {
  showExternalVolunteerApplicationWarning: boolean;
  mounted: boolean;
  userPermissions: IStringMap;
  redirect: string | null;
  activeTab: string;
  steps: IStep[];
  params: any;
  showLoginModal: boolean;
  showReportsMenu: boolean;
  setupMenuRef: React.RefObject<HTMLUListElement>;
}

interface IStep {
  target: string;
  content: string;
  disableBeacon?: boolean;
  event?: string;
}

class GroupAdmin extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showExternalVolunteerApplicationWarning: false,
      mounted: false,
      showLoginModal: false,
      userPermissions: {},
      redirect: null,
      activeTab: 'setup',
      steps: [
        {
          disableBeacon: true,
          target: '.GroupAdmin .settings',
          content:
            'Manage your cause setting here for Volunteer Applications, Donations, Tax Receipts and Active Campaigns.',
        },
        {
          disableBeacon: true,
          target: '.campaigns',
          content:
            'Create campaigns and attach your bank account so you can receive donations and other financial transactions.  You must have an active Campaign to accept donations, sell tickets or any other financial transaction.',
        },
        {
          disableBeacon: true,
          target: '.locations',
          content:
            ' Add a Location for your cause page so it will show up on map searches more effectively.',
        },
        {
          disableBeacon: true,
          target: '.store',
          content:
            'Setup tickets and other store items so they can be used in your Store component on your cause or events.',
        },
        {
          disableBeacon: true,
          target: '.tab-title.manage',
          content:
            'Manage your members, assign roles, view and manage volunteer applications, communicate with donors, view transactions and more in the Manage section.',
        },
      ],
      params: {},
      showReportsMenu: false,
      setupMenuRef: React.createRef(),
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.checkTab = this.checkTab.bind(this);
    this.hasManagePermission = this.hasManagePermission.bind(this);
  }

  componentDidMount() {
    this.props.getGroupPermissions();
    this.props.getGroup(this.props.match.params.handle);

    this.checkTab();
    this.setState({
      mounted: true,
    });

    let _params = queryString.parse(this.props.location.search);

    this.setState({
      params: _params,
    });

    this.props.history.listen((location, action) => {
      this.checkTab(location);
    });

    // GIG-740: Show login when user isn't logged in.
    this.setState({
      showLoginModal: this.props.userState.isLoggedIn === false,
    });
  }

  checkTab(location?: any) {
    let _params = queryString.parse(this.props.location.search);

    if (location) {
      _params = queryString.parse(location.search);
    }

    if (_params.t) {
      this.setState({
        activeTab: _params.t as string,
      });
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.groupState.isCurrentRoleLoading && !this.props.groupState.isCurrentRoleLoading) {
      let _permissions: IStringMap = mapPermissions(
        this.props.groupState.currentUserRole.permissions,
      );

      this.setState(
        {
          userPermissions: _permissions,
        },
        () => {
          // If user doesn't have permissions to access page, we boot them to the group page.
          if (!this.hasManagePermission() && !this.props.userState.awaitingToken) {
            this.setState({
              redirect: '/group/' + this.props.groupState.group.handle,
            });
            const toast = toastError(
              localizeHelpers.translate('You must be an admin of the cause to view admin page'),
              'Insufficient Permissions',
            );
            this.props.createToast(toast);
          }
        },
      );
    }

    // If group doesn't exist, then visit not found page.
    if (
      prevProps.groupState.isGroupLoading &&
      !this.props.groupState.isGroupLoading &&
      !this.props.userState.awaitingToken
    ) {
      if (
        this.props.groupState.group.handle.length === 0 ||
        (this.props.groupState.group.status &&
          this.props.groupState.group.status.code === 'archived')
      ) {
        this.setState({
          redirect: '/not-found',
        });
      }
    }

    if (
      this.props.userState.isLoggedIn !== prevProps.userState.isLoggedIn &&
      !this.props.userState.awaitingToken
    ) {
      // GIG-740: Show login when user isn't logged in.
      this.setState(
        {
          showLoginModal: this.props.userState.isLoggedIn === false,
        },
        () => {
          if (!this.state.showLoginModal && this.props.userState.isLoggedIn) {
            setSEOMetatags({
              urlPath: `group/${this.props.groupState.group.handle}`,
              title: this.props.groupState.group.title,
              description: this.props.groupState.group.description,
              imageURL: this.props.groupState.group.profile_image_url,
              coverImageURL: this.props.groupState.group.cover_image_url,
            });
          }
        },
      );

      // Refetch group and group permission data after login. This ensures user auth is up to date with current login
      if (this.props.userState.isLoggedIn) {
        this.props.getGroupPermissions();

        this.props.getGroup(this.props.match.params.handle);
      }
    }
  }

  hasManagePermission() {
    const allowedPermissions = Object.keys(this.state.userPermissions).filter(
      (k) => this.state.userPermissions[k],
    );
    return allowedPermissions.some((allowed) => GroupManagePermissions.includes(allowed));
  }

  isActiveTab(tab: string) {
    return tab === this.state.activeTab ? `tab active ${tab}` : `tab ${tab}`;
  }

  setActiveTab(tab: string) {
    let setUrl = this.state.activeTab !== tab;
    this.setState({
      activeTab: tab,
    });

    if (setUrl) {
      window.history.pushState({}, '', '/group/' + this.props.groupState.group.handle + '/admin');
    }

    // Reset scroll position when changing tabs. Makes UX better.
    window.scrollTo(0, 0);
  }

  getSidebarMenuOptions(): IMenuSection[] {
    return [
      {
        id: 'setup',
        sectionTitle: 'Setup',
        sectionIcon: 'fal fa-sliders-v',
        collapsible: true,
        menuOptions: [
          { id: 'settings', title: 'settings', permission: 'EDIT_GROUP_INFO' },
          { id: 'campaigns', title: 'campaigns', permission: 'MANAGE_CAMPAIGNS' },
          { id: 'roles', title: 'roles', permission: 'VIEW_GROUP_ROLES' },
          { id: 'locations', title: 'locations', permission: 'EDIT_GROUP_INFO' },
          {
            id: 'form',
            title: 'Application Form',
            permission: 'EDIT_GROUP_INFO',
            preventClick: this.props.groupState.group.accepting_external_application
              ? () => this.setState({ showExternalVolunteerApplicationWarning: true })
              : undefined,
          },
          { id: 'store', title: 'store', permission: 'MANAGE_STORE_ITEMS' },
          // Temporary disabling categories tab. Due to https://app.clickup.com/t/1m93jh3
          // { id: 'categories', title: "categories", permission: "MANAGE_STORE_ITEMS" },
          { id: 'discounts', title: 'discounts', permission: 'MANAGE_STORE_ITEMS' },
          { id: 'sponsorships', title: 'sponsorships', permission: 'MANAGE_GROUP_EVENTS' },
          { id: 'events', title: 'events', permission: 'MANAGE_GROUP_EVENTS' },
          { id: 'gigs', title: 'volunteer opportunities', permission: 'MANAGE_GIGS' },
          ...(this.props.groupPrimaryHubId
            ? [{ id: 'hub', title: 'hub', permission: 'MANAGE_HUB_ASSOCIATIONS' }]
            : []),
        ],
      },
      {
        id: 'manage',
        sectionTitle: 'Manage',
        sectionIcon: 'fal fa-user-cog',
        collapsible: true,
        menuOptions: [
          {
            id: 'schedule',
            title: 'schedule',
            permission: 'MANAGE_GIGS',
            preventClick: this.props.groupState.group.accepting_external_application
              ? () => this.setState({ showExternalVolunteerApplicationWarning: true })
              : undefined,
          },
          {
            id: 'hours',
            title: 'volunteer hours',
            permission: 'MANAGE_APPLICATIONS',
            preventClick: this.props.groupState.group.accepting_external_application
              ? () => this.setState({ showExternalVolunteerApplicationWarning: true })
              : undefined,
          },
          {
            id: 'reports',
            title: 'reports',
            submenuOptions: [
              { id: 'donationsPayments', title: 'donations', permission: 'MANAGE_PAYMENTS' },
              { id: 'storePayments', title: 'Store Purchases', permission: 'MANAGE_PAYMENTS' },
              { id: 'auctionsPayments', title: 'Auction Payments', permission: 'MANAGE_PAYMENTS' },
            ],
          },
          { id: 'balance_and_payouts', title: 'Balance & Payouts', permission: 'EDIT_GROUP_INFO' },
        ],
      },
      {
        id: 'lists',
        sectionTitle: 'Lists',
        sectionIcon: 'fal fa-list-ul',
        collapsible: true,
        menuOptions: [
          { id: 'members', title: 'Contacts', permission: 'EDIT_MEMBER_ACCESS' },
          { id: 'donors', title: 'donors', permission: 'MANAGE_DONATIONS' },
          { id: 'volunteers', title: 'volunteers', permission: 'MANAGE_APPLICATIONS' },
          { id: 'sponsors', title: 'sponsors', permission: 'MANAGE_GROUP_EVENTS' },
          { id: 'fundraisers', title: 'fundraisers', permission: 'MANAGE_FUNDRAISERS' },
        ],
      },
      {
        id: 'billing',
        sectionTitle: 'Billing',
        sectionIcon: 'fal fa-list-ul',
        collapsible: true,
        menuOptions: [{ id: 'plans', title: 'Plans' }],
      },
      {
        id: 'analytics',
        sectionTitle: 'Analytics',
        sectionIcon: 'fal fa-chart-bar',
        collapsible: true,
        menuOptions: [
          { id: 'summary', title: 'summary', permission: 'EDIT_GROUP_INFO' },
          { id: 'impact', title: 'community impact', permission: 'EDIT_GROUP_INFO' },
          { id: 'donorStatus', title: 'donor status', permission: 'EDIT_GROUP_INFO' },
        ],
      },
      {
        id: 'embedSection',
        sectionTitle: 'Embeds Sharing',
        sectionIcon: 'fas fa-code',
        collapsible: true,
        menuOptions: [{ id: 'embeds', title: 'embed tools' }],
      },
    ];
  }

  render() {
    let _joyrideStyles = {
      options: {
        arrowColor: '#FFF',
        backgroundColor: '#FFF',
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: '#5E51AB',
        textColor: '#505050',
      },
    };

    // Only display content once group data is loaded and we logged in.
    const isLoaded = !this.props.groupState.isGroupLoading && this.props.userState.isLoggedIn;

    const ownerObj = typeHelpers.createOwnerObject('group', this.props.groupState.group);

    if (this.state.mounted && this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    } else {
      return (
        <div className="GroupAdmin">
          {this.state.params.help !== undefined &&
            isLoaded &&
            this.state.setupMenuRef.current &&
            this.state.setupMenuRef.current.childElementCount > 1 && (
              <Joyride
                locale={{ back: 'Back', close: 'Close', last: 'Last', next: 'Next', skip: 'Skip' }}
                continuous={true}
                disableScrolling={true}
                styles={_joyrideStyles}
                steps={this.state.steps}
                callback={(state: any) => {
                  if (state.action === 'reset') {
                    window.history.pushState(
                      {},
                      '',
                      '/group/' + this.props.groupState.group.handle + '/admin',
                    );

                    this.setState({
                      params: {},
                    });
                  }
                }}
              />
            )}

          <ManagementSidebar
            logo={this.props.groupState.group.profile_image_url}
            title="Cause Admin"
            targetLink={{
              url: '/group/' + this.props.groupState.group.handle,
              title: this.props.groupState.group.title,
            }}
            menuSections={this.getSidebarMenuOptions()}
            activeOptionId={this.state.activeTab}
            permissions={this.state.userPermissions}
            onMenuOptionClicked={(menuOptionId: string) =>
              this.setState({ activeTab: menuOptionId })
            }
          />

          {/* TODO: THe tabs below should be rendered automatically instead of needing separate checks.
                        We should add a `component` field to IMenuOption and add a new component called `ManagementPage` (Better name TBD) that renders the active tab component.
                     */}
          <div className={'section ' + this.state.activeTab + '-wrap'}>
            {isLoaded && this.state.activeTab === 'campaigns' && (
              <CampaignManagement
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'store' && (
              <StoreItemManagement
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'form' && (
              <FormManagement
                ownerId={ownerObj.ownerId}
                ownerType={ownerObj.ownerType}
              />
            )}
            {isLoaded && this.state.activeTab === 'roles' && <RoleManagement owner={ownerObj} />}
            {isLoaded && this.state.activeTab === 'locations' && (
              <LocationManagement owner={ownerObj} />
            )}
            {isLoaded && this.state.activeTab === 'categories' && (
              <CategoriesManagement owner={ownerObj} />
            )}
            {isLoaded && this.state.activeTab === 'discounts' && (
              <DiscountCodeManagement
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'volunteers' && (
              <VolunteerManagement
                owner={typeHelpers.createOwnerObject('group', this.props.groupState.group)}
                changeAdminTab={this.setActiveTab}
              />
            )}
            {isLoaded && this.state.activeTab === 'sponsors' && <SponsorManagement />}
            {isLoaded && this.state.activeTab === 'fundraisers' && (
              <GroupFundraiserManage
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'sponsorships' && <SponsorshipsManagement />}
            {isLoaded && this.state.activeTab === 'settings' && (
              <SettingsManagement
                createAction={this.setActiveTab}
                groupId={this.props.groupState.group.id}
                onChangeAdminTab={(newTab) => this.setActiveTab(newTab)}
              />
            )}
            {isLoaded && this.state.activeTab === 'events' && (
              <EventManagement
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'hub' && this.props.groupPrimaryHubId && (
              <HubManagement
                groupId={this.props.groupState.group.id}
                {...this.props}
              />
            )}

            {isLoaded && this.state.activeTab === 'setup' && <GuidedSetup />}
            {isLoaded && this.state.activeTab === 'donors' && <DonorManagement owner={ownerObj} />}

            {isLoaded && this.state.activeTab === 'members' && (
              <MemberManagement
                owner={ownerObj}
                {...this.props}
                permissions={this.state.userPermissions}
              />
            )}
            {isLoaded && this.state.activeTab === 'balance_and_payouts' && (
              <BalanceAndPayoutsManage
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'schedule' && (
              <Scheduler
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'donationsPayments' && (
              <TransactionManagement
                owner={ownerObj}
                transactionsType="donationsPayments"
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'storePayments' && (
              <TransactionManagement
                owner={ownerObj}
                transactionsType="storePayments"
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'gigsPayments' && (
              <TransactionManagement
                owner={ownerObj}
                transactionsType="gigsPayments"
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'auctionsPayments' && (
              <TransactionManagement
                owner={ownerObj}
                transactionsType="auctionsPayments"
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'hours' && (
              <HourManagement
                owner={ownerObj}
                {...this.props}
              />
            )}

            {isLoaded && this.state.activeTab === 'plans' && <PlanManagement owner={ownerObj} />}

            {isLoaded && this.state.activeTab === 'summary' && (
              <GroupSummary
                setupCharity={() => {
                  this.setState({ activeTab: 'settings' });
                }}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'impact' && <CommunityImpact {...this.props} />}
            {isLoaded && this.state.activeTab === 'donorStatus' && (
              <DonorStatusDashboard {...this.props} />
            )}
            {isLoaded && this.state.activeTab === 'gigs' && (
              <GigManagement
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'embeds' && (
              <EmbedManagement
                {...this.props}
                owner={ownerObj}
              />
            )}
          </div>
          <Construction {...this.props} />

          <Modal
            show={this.state.showLoginModal}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ showLoginModal: false });
            }}
          >
            <Login
              registerRedirectUrl={'/group/' + this.props.groupState.group.handle + '/admin'}
              {...this.props}
            />
          </Modal>
          <ExternalVolunteerApplicationWarning
            show={this.state.showExternalVolunteerApplicationWarning}
            onClose={() => this.setState({ showExternalVolunteerApplicationWarning: false })}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    userState: store.userState,
    groupPrimaryHubId: groupSelectors.GetGroupMainHubId(store),
  };
};

const mapDispatchToProps = {
  getGroup,
  getGroupPermissions,
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdmin);
