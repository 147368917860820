import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IConversationMessage, IConversationSummary, IEmbeddedUser } from '@gigit/interfaces';
import { IAppState } from '../../store';
import Conversation from '../Conversation/Conversation';
import { ConversationTile } from './ConversationTile/ConversationTile';
import { ChatSettings } from './Settings/ChatSettings';
import Modal from '../Modal/Modal';
import { userRequestActions } from '../../requestActions';
import { userSelectors } from '../../selectors/user';
import { chatRequestActions } from '../../requestActions/chat';
import useToastDispatcher from '../../hooks/useToaster';
import { ReactComponent as MessagesIcon } from '../../assets/messages-icon.svg';

import './Chat.scss';
import { useHistory } from 'react-router-dom';
import { getCurrentConversations } from '../../actions/chat';

// Move these interfaces to a types file with the rest of chat updates
interface IChatProps {
  contactId?: string;
  conversationSummary?: any[];
  getSummary(): void;
  openOrganizerChat?: boolean;
  orgConvoStartInfo?: {
    type?: string;
    id?: string;
  };
}

const Chat: React.FC<IChatProps> = (props: IChatProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // State
  const [currentConversation, setCurrentConversation] = useState<IConversationSummary | null>(null);
  const [showConversations, setShowConversations] = useState<boolean>(false);
  const [conversations, setConversations] = useState<IConversationSummary[] | null>(null);
  const [showChatSettings, setShowChatSettings] = useState<boolean>(false);
  // Selectors
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  // Custom Hooks
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  // Effects
  useEffect(() => {
    getConversations();
  }, []);

  useEffect(() => {
    if (props.openOrganizerChat) {
      openNewOrgConvo();
    }
  }, [props.openOrganizerChat]);

  useEffect(() => {
    if (props.contactId) {
      setShowConversations(true);
    }
  }, [props.contactId]);

  useEffect(() => {
    // Grab conversations list on close of existing convo and refetch summary for updates
    if (!currentConversation) {
      getConversations();
      props.getSummary();
    }
  }, [currentConversation]);

  useEffect(() => {
    if (props?.contactId && conversations && showConversations) {
      handleParams();
    }
  }, [conversations]);

  async function getConversations() {
    try {
      const conversationList = await chatRequestActions.getUserConversations();
      setConversations(conversationList);

      if (conversationList.length === 0) {
        openNewConvo();
      }
    } catch (error) {
      dispatchToastError(error, 'Get Conversations');
    }
  }

  async function openNewOrgConvo() {
    if (
      user.id &&
      props.orgConvoStartInfo &&
      props.orgConvoStartInfo.type &&
      props.orgConvoStartInfo.id
    ) {
      const contactUsers = await userRequestActions.getOrganizerContacts(
        props.orgConvoStartInfo.type,
        props.orgConvoStartInfo.id,
      );
      const allUsersTmp = await userRequestActions.getMyContacts();
      const contactUserIds = contactUsers.map((contact) => {
        if (contact.contact_user_id.toString() !== user.id) {
          return contact.contact_user_id.toString();
        } else if (contact.user_id.toString() !== user.id) {
          return contact.user_id.toString();
        }
      });

      let currentUser: IEmbeddedUser = user;

      // Using temporary user detials from contacts so we can display portraits and display names until the conversation is saved in DB
      let tmpUsers = allUsersTmp
        .filter((user) => contactUserIds.indexOf(user.other_user_id.toString()) !== -1)
        .map((user) => user.other_user);

      tmpUsers.push(currentUser);

      contactUserIds.push(user.id);
      setCurrentConversation({
        id: 'new',
        conversation_display_name: '',
        is_archived: false,
        is_group: true,
        last_message: {} as IConversationMessage,
        user_ids: contactUserIds as string[],
        tmp_users: tmpUsers,
      });
    }
  }

  async function openNewConvo() {
    if (props.contactId && user.id) {
      const contactUser = await userRequestActions.getUserByHandleOrId(props.contactId);
      setCurrentConversation({
        id: 'new',
        conversation_display_name: '',
        is_archived: false,
        last_message: {} as IConversationMessage,
        user_ids: [user.id, props.contactId || ''],
        users: [user, contactUser],
      });
    }
  }

  async function handleParams() {
    // check if convo exists -
    // TODO: List should be paginated but since we have no way to fetch conversation by ID Atm will leave all here
    // navigating back should recall the conversations thusly updating the list to include the new/updated conversation
    let existingConversation = conversations?.filter((conversation: IConversationSummary) => {
      let toIds = conversation.user_ids.filter((id: string) => id !== user.id);
      return isEqualArray([props?.contactId || ''], toIds);
    })[0];

    if (existingConversation) {
      await openConvo(existingConversation);
    } else {
      if (user?.id && props.contactId) {
        const contactUser = await userRequestActions.getUserByHandleOrId(props.contactId);
        setCurrentConversation({
          id: 'new',
          conversation_display_name: '',
          is_archived: false,
          last_message: {} as IConversationMessage,
          user_ids: [user.id, props.contactId || ''],
          users: [user, contactUser],
        });
      }
    }
    history.push({ pathname: '/dashboard', search: 'tab=network' });
  }

  // I think lodash has a helper for this
  function isEqualArray(a: Array<string>, b: Array<string>) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  async function openConvo(conversation: IConversationSummary) {
    setShowConversations(true);
    await userRequestActions.markConversationAsRead(conversation.id || '');
    setCurrentConversation(conversation);
    dispatch(getCurrentConversations());
  }

  function isOpenConvo() {
    return currentConversation;
  }

  async function onCloseConversation(conversation?: IConversationSummary) {
    // Need to mark convo as read on close

    try {
      await userRequestActions.markConversationAsRead(currentConversation?.id || '');
      dispatch(getCurrentConversations());
    } catch (error) {
      // TODO: Letting this fail silently for the use case of starting a new conversation. Should probably fix it.
    } finally {
      if (conversation) {
        setCurrentConversation(null);
        setCurrentConversation(conversation);
        setShowConversations(false);
        return;
      }
      setCurrentConversation(null);
      // setShowConversations(false);
    }
  }

  function getUnreadCount(conversationId: string) {
    const summaries = props?.conversationSummary || [];
    const summary = summaries.find((sum: any) => sum.conversation_id === conversationId);

    if (summary) {
      return summary.unread_count;
    } else {
      return 0;
    }
  }

  return (
    <div className="Chat">
      <React.Fragment>
        {isOpenConvo() && (
          <div
            className={isOpenConvo() ? 'messages open no-select' : 'messages no-select'}
            onClick={() => onCloseConversation()}
          >
            <i className="far fa-angle-left close-convos" />
            <p>Back to Messages</p>
          </div>
        )}
        {currentConversation && (
          <Conversation
            conversation={currentConversation}
            onClose={(conversation?) => onCloseConversation(conversation)}
          />
        )}
        {!isOpenConvo() && (
          <div className="current-conversations-list">
            <div
              className={
                conversations && conversations.length > 0
                  ? 'convo-list no-select'
                  : 'convo-list no-select empty'
              }
            >
              {conversations?.length === 0 && (
                <div className="no-convos">
                  <MessagesIcon />
                  <p>You have no messages.</p>
                </div>
              )}
              <ul>
                {conversations?.map((convo: IConversationSummary) => {
                  return (
                    <li
                      key={convo.id}
                      onClick={() => openConvo(convo)}
                    >
                      <ConversationTile
                        conversation={convo}
                        userId={user.id || ''}
                        type={'message'}
                        unreadCount={getUnreadCount(convo.id || '')}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </React.Fragment>
      <Modal
        show={showChatSettings}
        onClose={() => setShowChatSettings(false)}
      >
        <ChatSettings />
      </Modal>
    </div>
  );
};

export default Chat;
