import React, { FC, useState, useEffect, useRef } from 'react';
import './HubManagementAnalyticsVolunteering.scss';
import HubManagementMetricContainer from '../../shared/HubManagementMetricContainer/HubManagementMetricContainer';
import { IKpi } from '../../../../../interfaces';
import moment, { Moment } from 'moment';
import DateTimePicker from '../../../../shared/DateTimePicker/DateTimePicker';
import { useLocale } from '../../../../../hooks';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IHub } from '@gigit/interfaces';
import useToastDispatcher from '../../../../../hooks/useToaster';
import { uiConstants } from '../../../../../constants';
import { metricRequestActions } from '../../../../../requestActions/metrics';
import {
  defaultCurrency,
  metricsHelpers,
  formatNumberCompactNotation,
  focusOnInputField,
} from '../../../../../helpers';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface IProps {
  hub: IHub;
}

const _SESSION_STORAGE_VOLUNTEER_HOURS_DATE_FROM = 'hubAnalyticsDonations_volunteerHoursDateFrom',
  _SESSION_STORAGE_VOLUNTEER_HOURS_DATE_TO = 'hubAnalyticsDonations_volunteerHoursDateTo',
  _SESSION_STORAGE_VOLUNTEER_APPLICATIONS_DATE_FROM =
    'hubAnalyticsDonations_volunteerApplicationsDateFrom',
  _SESSION_STORAGE_VOLUNTEER_APPLICATIONS_DATE_TO =
    'hubAnalyticsDonations_volunteerApplicationsDateTo';

const HubManagementAnalyticsVolunteering: FC<IProps> = (props) => {
  const { hub } = props;
  const locale = useLocale();
  const { dispatchToastError } = useToastDispatcher();
  const [metrics, setMetrics] = useState<IKpi[]>([]);
  const [volunteerHoursKpi, setVolunteerHoursKpi] = useState<metricsHelpers.IChartKpi | null>(null);
  const [volunteerApplicationsKpi, setVolunteerApplicationsKpi] =
    useState<metricsHelpers.IChartKpi | null>(null);

  const volunteerHoursDateFromRef = useRef<HTMLInputElement>(null);
  const volunteerHoursDateToRef = useRef<HTMLInputElement>(null);
  const volunteerApplicationsDateFromRef = useRef<HTMLInputElement>(null);
  const volunteerApplicationsDateToRef = useRef<HTMLInputElement>(null);

  const [volunteerHoursDateFrom, setVolunteerHoursDateFrom] = useState<Moment>(
    moment(
      localStorage.getItem(_SESSION_STORAGE_VOLUNTEER_HOURS_DATE_FROM) ||
        `${new Date().getFullYear()}-01-01`,
    ),
  );
  const [volunteerHoursDateTo, setVolunteerHoursDateTo] = useState<Moment>(
    moment(
      localStorage.getItem(_SESSION_STORAGE_VOLUNTEER_HOURS_DATE_TO) ||
        `${new Date().getFullYear()}-12-31`,
    ),
  );
  const [volunteerApplicationsDateFrom, setVolunteerApplicationsDateFrom] = useState<Moment>(
    moment(
      localStorage.getItem(_SESSION_STORAGE_VOLUNTEER_APPLICATIONS_DATE_FROM) ||
        `${new Date().getFullYear()}-01-01`,
    ),
  );
  const [volunteerApplicationsDateTo, setVolunteerApplicationsDateTo] = useState<Moment>(
    moment(
      localStorage.getItem(_SESSION_STORAGE_VOLUNTEER_APPLICATIONS_DATE_TO) ||
        `${new Date().getFullYear()}-12-31`,
    ),
  );

  async function handleGetMetrics() {
    try {
      const metricNames = [
        'volunteer_hours_total',
        'volunteer_hours_percent_approved',
        'num_volunteers',
        'volunteer_hours_approved',
        'volunteer_hours_pending',
      ];
      const result = (
        await metricRequestActions.getMetricsBulk({
          metrics: metricNames.map((metric) => ({
            metric,
            object_type: uiConstants.ownerType.hub,
            object_id: hub.id!,
            currency: hub.account?.currency ?? defaultCurrency,
          })),
        })
      ).metrics;

      setMetrics([
        {
          value: formatNumberCompactNotation(result[0].value, locale.currentLocale),
          text: 'Total Volunteer Hours',
        },
        {
          value: `${result[1].value}%`,
          text: 'Hours Verified',
        },
        {
          value: formatNumberCompactNotation(result[2].value, locale.currentLocale),
          text: 'Total Applications',
        },
        {
          value: formatNumberCompactNotation(result[3].value, locale.currentLocale),
          text: 'Approved hours',
        },
        {
          value: formatNumberCompactNotation(result[4].value, locale.currentLocale),
          text: 'Pending hours',
        },
      ]);
    } catch (error) {
      dispatchToastError(error, 'Get Metrics');
    }
  }

  async function handleGetVolunteerHoursKpi() {
    try {
      const kpiData = (
        await metricRequestActions.getMetricsKpi({
          object_type: uiConstants.ownerType.hub,
          object_id: hub.id!,
          kpi: 'volunteer_hours_trend',
          start_date: volunteerHoursDateFrom.toDate(),
          end_date: volunteerHoursDateTo.toDate(),
        })
      ).kpi_data;
      let chartDate = [] as metricsHelpers.IChartKpiData[];
      kpiData.labels.forEach((label, index) => {
        chartDate.push({
          x: label,
          value: kpiData.datasets[0].data[index],
        });
      });

      setVolunteerHoursKpi({ data: chartDate, labels: kpiData.labels });
    } catch (error) {
      dispatchToastError(error, 'Get Volunteer Hours KPI');
    }
  }

  async function handleGetVolunteerApplicationsKpi() {
    try {
      const kpiData = (
        await metricRequestActions.getMetricsKpi({
          object_type: uiConstants.ownerType.hub,
          object_id: hub.id!,
          kpi: 'volunteer_applications_trend',
          start_date: volunteerApplicationsDateFrom.toDate(),
          end_date: volunteerApplicationsDateTo.toDate(),
        })
      ).kpi_data;
      let chartDate = [] as metricsHelpers.IChartKpiData[];
      kpiData.labels.forEach((label, index) => {
        chartDate.push({
          x: label,
          value: kpiData.datasets[0].data[index],
        });
      });

      setVolunteerApplicationsKpi({ data: chartDate, labels: kpiData.labels });
    } catch (error) {
      dispatchToastError(error, 'Get Volunteer Applications KPI');
    }
  }

  useEffect(() => {
    handleGetMetrics();
  }, []);

  useEffect(() => {
    handleGetVolunteerHoursKpi();
  }, [volunteerHoursDateFrom, volunteerHoursDateTo]);

  useEffect(() => {
    handleGetVolunteerApplicationsKpi();
  }, [volunteerApplicationsDateFrom, volunteerApplicationsDateTo]);

  const optionsVolunteerHours = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderColor: '#333333',
        borderWidth: 1,
        bodyColor: '#000000',
        titleColor: '#000000',
        padding: 16,
        titleFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
        bodyFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
      },
    },
  };
  const chartDataVolunteerHours = {
    labels: volunteerHoursKpi?.labels || [],
    datasets: [
      {
        label: 'Volunteer Hours',
        data: volunteerHoursKpi?.data || [],
        borderColor: '#5E51AB',
        backgroundColor: '#5E51AB',
        parsing: {
          yAxisKey: 'value',
        },
      },
    ],
  };

  const optionsVolunteerApplications = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderColor: '#333333',
        borderWidth: 1,
        bodyColor: '#000000',
        titleColor: '#000000',
        padding: 16,
        titleFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
        bodyFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
      },
    },
  };
  const chartDataVolunteerApplications = {
    labels: volunteerApplicationsKpi?.labels || [],
    datasets: [
      {
        label: 'Volunteer Applications',
        data: volunteerApplicationsKpi?.data || [],
        borderColor: '#EB645E',
        backgroundColor: '#EB645E',
        parsing: {
          yAxisKey: 'value',
        },
      },
    ],
  };

  return (
    <div className="HubManagementAnalyticsVolunteering">
      <h3>Volunteering Analytics</h3>
      <HubManagementMetricContainer metrics={metrics} />

      <section className="chart-container">
        <h4>Monthly Volunteer Hours Trend</h4>
        <div className="date-container">
          <div className="date">
            <label htmlFor="volunteerHoursDateFrom">From:</label>
            <DateTimePicker
              shouldValidate={false}
              inputProps={{
                ref: volunteerHoursDateFromRef,
                name: 'volunteerHoursDateFrom',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              value={volunteerHoursDateFrom}
              onChange={(value) => {
                setVolunteerHoursDateFrom(moment(value));
                localStorage.setItem(_SESSION_STORAGE_VOLUNTEER_HOURS_DATE_FROM, value as string);
              }}
              locale={locale.currentLocale}
              className="dPicker"
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(volunteerHoursDateFromRef)}
            />
          </div>
          <div className="date">
            <label htmlFor="volunteerHoursDateTo">From:</label>
            <DateTimePicker
              inputProps={{
                ref: volunteerHoursDateToRef,
                name: 'volunteerHoursDateTo',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              value={volunteerHoursDateTo}
              onChange={(value) => {
                setVolunteerHoursDateTo(moment(value));
                localStorage.setItem(_SESSION_STORAGE_VOLUNTEER_HOURS_DATE_TO, value as string);
              }}
              locale={locale.currentLocale}
              className="dPicker"
              disableDatesBefore={volunteerHoursDateFrom}
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(volunteerHoursDateToRef)}
            />
          </div>
        </div>
        <Line
          options={optionsVolunteerHours}
          data={chartDataVolunteerHours}
          className="chart"
        />
      </section>

      <section className="chart-container">
        <h4>Monthly Volunteer Applications Trend</h4>
        <div className="date-container">
          <div className="date">
            <label htmlFor="volunteerApplicationsDateFrom">From:</label>
            <DateTimePicker
              shouldValidate={false}
              inputProps={{
                ref: volunteerApplicationsDateFromRef,
                name: 'volunteerApplicationsDateFrom',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              className="dPicker"
              value={volunteerApplicationsDateFrom}
              onChange={(value) => {
                setVolunteerApplicationsDateFrom(moment(value));
                localStorage.setItem(
                  _SESSION_STORAGE_VOLUNTEER_APPLICATIONS_DATE_FROM,
                  value as string,
                );
              }}
              locale={locale.currentLocale}
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(volunteerApplicationsDateFromRef)}
            />
          </div>
          <div className="date">
            <label htmlFor="volunteerApplicationsDateTo">To:</label>
            <DateTimePicker
              inputProps={{
                ref: volunteerApplicationsDateToRef,
                name: 'volunteerApplicationsDateTo',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              className="dPicker"
              value={volunteerApplicationsDateTo}
              onChange={(value) => {
                setVolunteerApplicationsDateTo(moment(value));
                localStorage.setItem(
                  _SESSION_STORAGE_VOLUNTEER_APPLICATIONS_DATE_TO,
                  value as string,
                );
              }}
              locale={locale.currentLocale}
              disableDatesBefore={volunteerApplicationsDateFrom}
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(volunteerApplicationsDateToRef)}
            />
          </div>
        </div>
        <Line
          options={optionsVolunteerApplications}
          data={chartDataVolunteerApplications}
          className="chart"
        />
      </section>
    </div>
  );
};

export default HubManagementAnalyticsVolunteering;
