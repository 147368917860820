import React from 'react';
import { handleInputChange } from '../../../../helpers';
import TextField from '../../../TextField/TextField';

interface IProps {
  flow: string;
  group: any;
  isCharity: boolean;

  updateIsCharity(val: boolean): void;
  updateCharityId(id: string): void;
}

interface IState {
  charityId: string;
  isCharity: boolean;
}

class IsCharity extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      charityId: '',
      isCharity: false,
    };

    this.handleIsCharity = this.handleIsCharity.bind(this);
  }

  componentDidMount() {
    this.intializeData();
  }

  intializeData() {
    this.setState({
      charityId: this.props.group.charity_id || '',
      isCharity: this.props.group.charity_id,
    });
  }

  handleIsCharity() {
    this.setState(
      {
        isCharity: !this.state.isCharity,
      },
      () => {
        let c = this.state.isCharity;
        this.props.updateIsCharity(c);
      },
    );
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} charity`}>
        <div className="details-row">
          <div className="details">
            <p>Is your cause a registered charity?</p>
          </div>
          <div className="settings-control">
            {this.state.isCharity && <span>Yes</span>}
            <i
              onClick={this.handleIsCharity}
              className={this.state.isCharity ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}
            ></i>
          </div>
        </div>
        <div className="charity-wrap">
          {this.state.isCharity && (
            <TextField
              required={this.state.isCharity}
              value={this.state.charityId}
              type="text"
              name="charityId"
              label="Charity ID"
              onChange={(e: any) => {
                handleInputChange(e, this, false, this.props.updateCharityId, true);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default IsCharity;
