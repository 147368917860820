import interact from 'interactjs';

// TODO: PASS REF TO MAKE DYNAMIC FOR RESPONSIVE VIEWS
export const configureDragLogic = () => {
  // target elements with the provided class
  interact('.is-draggable').draggable({
    // enable inertial throwing
    inertia: true,
    // keep the element within the area of it's parent
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: 'parent',
      }),
    ],
    // enable autoScroll
    autoScroll: false,
    // element that allows drag, if none the whole .textbox
    allowFrom: '.textbox, .metric-box',
    listeners: {
      // call this function on every dragmove event
      move: dragMoveListener,
    },
  });

  interact('.call-to-action-container').draggable({
    // enable inertial throwing
    inertia: true,
    // keep the element within the area of it's parent
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: 'parent',
      }),
    ],
    // enable autoScroll
    autoScroll: false,
    // element that allows drag, if none the whole .textbox
    allowFrom: '.drag-handle',
    listeners: {
      // call this function on every dragmove event
      move: dragMoveListener,
    },
  });
};

const dragMoveListener = (event: any) => {
  event.stopPropagation();
  let target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
  let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
  // translate the element
  target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
  // update the position attributes
  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y);
};

export const cleanupDragLogic = () => {
  interact('.is-draggable').unset();
  interact('.call-to-action-container').unset();
};
