import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { errorHelpers, formatQuery, typeHelpers } from '../../../../helpers';
import { userSelectors } from '../../../../selectors/user';
import TextField from '../../../TextField/TextField';
import Portrait from '../../../Portrait/Portrait';
import { localizeHelpers } from '../../../../localizeHelpers';
import './HubInviteMembers.scss';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import { IInvite, IInviteCreateInfo, IRole, IUser } from '@gigit/interfaces';
import { IOwnerObject } from '../../../../interfaces';
import Dropdown from '../../../Dropdown/Dropdown';
import {
  billingRequestActions,
  inviteRequestActions,
  roleRequestActions,
  userRequestActions,
} from '../../../../requestActions';
import { uiConstants } from '../../../../constants';
import useToastDispatcher from '../../../../hooks/useToaster';
import HubPaymentModal from '../HubPaymentModal/HubPaymentModal';
import { Constants } from '@gigit/constants';

interface IProps {
  owner: IOwnerObject;
  showAddMemberModal: boolean;
  toggleShowAddMemberModal: (newToggleValue: boolean) => void;
  objectType: 'event' | 'group' | 'hub';
  noModal?: boolean;
  isHubOnboarding?: boolean;
  hubSeatsAvailable?: number;
}

interface ISelectedUsers {
  user: Partial<IUser>;
  role_id: string | null;
}

const HubInviteMembers = (props: IProps) => {
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const [seatsAvailable, setSeatsAvailable] = useState<number>(0);
  const [invites, setInvites] = useState<IInvite[]>([]);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
  const [searchConnectionsValue, setSearchConnectionsValue] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pendingInviteEmailList, setPendingInviteEmailList] = useState<string[]>([]);
  const [pendingInviteUserList, setPendingInviteUserList] = useState<ISelectedUsers[]>([]);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<boolean>(false);
  const [paymentDescription, setPaymentDescription] = useState<string>('');
  const [togglePaymentButtonLoading, setTogglePaymentButtonLoading] = useState<boolean>(false);
  const [shouldHidePaymentMethod, setShouldHidePaymentMethod] = useState<boolean>(false);

  useEffect(() => {
    getInvites();
    fetchRoles();
  }, []);

  useEffect(() => {
    if (props.owner.ownerType === uiConstants.ownerType.hub) {
      if (props.hubSeatsAvailable !== undefined) {
        setSeatsAvailable(props.hubSeatsAvailable);
      }
    }
  }, [props.hubSeatsAvailable, props.owner]);

  useEffect(() => {
    setShowModal(props.showAddMemberModal);
  }, [props.showAddMemberModal]);

  useEffect(() => {
    // Get user info for the list
    let emailList = pendingInviteEmailList.map((e) => {
      return e.trim();
    });
    if (emailList.length === 0) {
      setPendingInviteUserList([]);
      return;
    }
    emailList = Array.from(new Set(emailList));
    let userList: ISelectedUsers[] = [];

    const fetchUsers = async (email: string) => {
      let user = await userRequestActions.getUserByEmail(email);

      if (user) {
        userList.push({
          role_id: selectedRoleId,
          user: user,
        });
      } else {
        userList.push({
          role_id: selectedRoleId,
          user: {
            email,
            first_name: 'Email Invite',
          },
        });
      }
      setPendingInviteUserList([...userList.concat(pendingInviteUserList)]);
    };

    for (let email of emailList) {
      let userExists = pendingInviteUserList.find((userItem) => userItem.user.email === email);
      if (!userExists) {
        fetchUsers(email);
      }
    }
  }, [pendingInviteEmailList]);

  const getInvites = async () => {
    try {
      const inviteResponse = await inviteRequestActions.getInvites(
        props.owner.ownerType,
        props.owner.ownerId,
      );
      setInvites(inviteResponse?.data || []);
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      dispatchToastError(errObj.translatedMessage, 'Get Roles');
    }
  };

  const fetchRoles = async () => {
    try {
      const roleResponse = await roleRequestActions.getRoles(
        props.owner.ownerType,
        props.owner.ownerId,
      );
      setRoles(roleResponse?.data || []);
      setSelectedRoleId(
        roleResponse && roleResponse.data.length > 0 ? roleResponse.data[0].id! : null,
      );
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      dispatchToastError(errObj.translatedMessage, 'Get Roles');
    }
  };

  const addInviteMember = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateEmailInput()) {
      let tmpList = pendingInviteEmailList;

      if (searchConnectionsValue.indexOf(';') !== -1) {
        const emailArray = searchConnectionsValue.split(';');
        tmpList.concat(emailArray);
      } else {
        tmpList.push(searchConnectionsValue);
      }
      //Filter out duplicates
      tmpList = tmpList.filter((item, index) => tmpList.indexOf(item) === index);
      setPendingInviteEmailList([...tmpList]);
      setSearchConnectionsValue('');
    }
  };

  const validateEmailInput = () => {
    let emailArray: string[] = [];

    let emailValidate = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');

    if (searchConnectionsValue.indexOf(';') !== -1) {
      emailArray = searchConnectionsValue.split(';');
      for (let email of emailArray) {
        email = email.trim();
        if (!emailValidate.test(email)) {
          const error = localizeHelpers.translate('{{email}} is not a valid email', { email });
          dispatchToastError(error, 'Invite');
          return false;
        } else {
          return true;
        }
      }
    } else {
      if (!emailValidate.test(searchConnectionsValue)) {
        const error = localizeHelpers.translate('{{email}} is not a valid email', {
          email: searchConnectionsValue,
        });
        dispatchToastError(error, 'Invite');

        return false;
      } else {
        return true;
      }
    }
  };

  const cancelInvite = async (inviteId: string) => {
    try {
      await inviteRequestActions.cancelInvite(props.owner.ownerType, props.owner.ownerId, inviteId);
      await getInvites();
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      dispatchToastError(errObj.translatedMessage, 'Cancel Invite');
    }
  };

  const getInviteCount = (filter: 'pending' | 'all'): number => {
    return invites.filter((invite) => {
      return filter === 'pending' ? invite.status?.code === filter : true;
    }).length;
  };

  const sendInviteReminder = async (invite: IInvite) => {
    typeHelpers.assertNotNullOrUndefined(invite.id);

    try {
      await inviteRequestActions.sendEventInviteReminder(
        props.owner.ownerType,
        props.owner.ownerId,
        invite.id,
      );
      await getInvites();
      dispatchToastSuccess(
        localizeHelpers.translate('Invitation Reminder Successfully Sent'),
        'Invitation Reminder',
      );
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      dispatchToastError(errObj.translatedMessage, 'Send Invitation Reminder');
    }
  };

  const sendInvites = async (pmId: string, createSub?: boolean) => {
    try {
      if (
        props.hubSeatsAvailable !== undefined &&
        pendingInviteEmailList.length > props.hubSeatsAvailable
      ) {
        let currentFeature = await billingRequestActions.getHubSubscriptionSummary(
          props.owner.ownerId,
        );

        const payload = {
          subscription_code: createSub
            ? Constants.billing.subscriptions.hub_seating.code
            : currentFeature.features[0].feature_code,
          payment_method_id: pmId,
          number_of_seats: pendingInviteEmailList.length - props.hubSeatsAvailable,
        };

        if (createSub) {
          await billingRequestActions.createHubSeatSubscription(
            props.owner.ownerId,
            Constants.billing.subscriptions.hub_seating.code,
            payload,
          );
        } else {
          const payload = {
            allocate_quantity: pendingInviteEmailList.length - props.hubSeatsAvailable,
          };
          await billingRequestActions.purchaseHubSeats(props.owner.ownerId, payload);
        }
      }

      let payload: IInviteCreateInfo[] = [];
      if (pendingInviteUserList.length) {
        for (let pendingUser of pendingInviteUserList) {
          if (pendingUser && pendingUser.user.email) {
            payload.push({
              email: pendingUser.user.email,
              role_id: pendingUser.role_id ?? undefined,
            });
          }
        }
      } else {
        return;
      }

      await inviteRequestActions.createInvite(props.owner.ownerType, props.owner.ownerId, {
        invites: payload,
      });

      dispatchToastSuccess(localizeHelpers.translate('Invite sent successfully.'), 'Invites');

      setTogglePaymentButtonLoading(!togglePaymentButtonLoading);
      setPendingInviteUserList([]);
      setPendingInviteEmailList([]);
      setShowPaymentMethodModal(false);

      await getInvites();
      setSearchConnectionsValue('');
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      dispatchToastError(errObj.translatedMessage, 'Get Roles');
    }
  };

  const generatePaymentMessage = () => {
    if (props.hubSeatsAvailable !== undefined) {
      let paymentMessage = `You are ${pendingInviteEmailList.length > props.hubSeatsAvailable ? 'trying' : 'about'} to invite <strong>${pendingInviteEmailList.length} user(s)</strong> to your company`;

      // TODO: CHECK IF WE NEED THIS
      // if (props.hubSeatsAvailable > 0) {
      //     paymentMessage += `For ${pendingInviteEmailList.length > props.hubSeatsAvailable? props.hubSeatsAvailable: pendingInviteEmailList.length} seat(s) no charges.\n`
      // }

      if (pendingInviteEmailList.length > props.hubSeatsAvailable) {
        paymentMessage += ` but have <strong>${props.hubSeatsAvailable} seat(s)</strong> available.\n <strong>$${
          (Constants.billing.subscriptions.hub_seating.price_in_cents / 100) *
          (pendingInviteEmailList.length - props.hubSeatsAvailable) *
          12
        }</strong> will be added to your annual billing cycle for each new seat.  You will be charged a pro rata amount for the remainder of the current billing cycle for each seat required to send your invites.\n`;
        setShouldHidePaymentMethod(false);
      } else {
        paymentMessage += `. There are currently <strong>${props.hubSeatsAvailable} seat(s)</strong> available.\n`;
        setShouldHidePaymentMethod(true);
      }

      paymentMessage += 'Do you wish to continue?';
      setPaymentDescription(localizeHelpers.translate(paymentMessage));
    } else {
      setPaymentDescription('');
    }

    setShowPaymentMethodModal(true);
  };

  const renderContactRow = (invite?: IInvite | ISelectedUsers, isPendingUsers?: boolean) => {
    if (isPendingUsers) {
      invite = invite as ISelectedUsers;
    }

    const { user } = invite || {};

    if (invite) {
      return (
        <div className="contact-row">
          <div className="portrait">
            <Portrait
              currentImage={user?.profile_image_url}
              size={40}
            />
          </div>
          <div className="contact-info">
            <span
              className="name"
              notranslate="yes"
            >
              {user?.display_name || localizeHelpers.translate('Email Invite')}
            </span>
            <div className="description">
              <p className="email">{isPendingUsers ? user?.email : (invite as IInvite).email} - </p>
              <p className="status">
                {!isPendingUsers ? (invite as IInvite)?.status?.code : 'Not Sent'}
              </p>
              {!isPendingUsers && (invite as IInvite)?.status?.code !== 'accepted' && (
                <a
                  className="reminder"
                  onClick={() => sendInviteReminder(invite as IInvite)}
                >
                  {` - Send a reminder`}
                </a>
              )}
            </div>
          </div>
          {!isPendingUsers && (invite as IInvite)?.status?.code === 'pending' && (
            <Button
              className="action-button"
              buttonType="secondary"
              onClick={async () => {
                await cancelInvite((invite as IInvite).id!);
              }}
              text="Cancel"
            />
          )}
          {isPendingUsers && (
            <Button
              className="action-button"
              buttonType="secondary"
              onClick={() => {
                setPendingInviteEmailList(
                  pendingInviteEmailList.filter((e) => e !== (invite as ISelectedUsers).user.email),
                );
                setPendingInviteUserList(
                  pendingInviteUserList.filter(
                    (e) => e.user.email !== (invite as ISelectedUsers).user.email,
                  ),
                );
              }}
              text="Cancel"
            />
          )}
        </div>
      );
    }
  };

  async function handleOnHubPaymentConfirmation(paymentMethodId: string, createSub?: boolean) {
    await sendInvites(paymentMethodId, createSub);
  }

  return (
    <Modal
      class="HubInviteMembers-Con"
      show={showModal}
      onClose={() => props.toggleShowAddMemberModal(false)}
      closeIcon="fas fa-times"
    >
      <div className="HubInviteMembers">
        <div className="header-container">
          <span className="title">Invite Members</span>
          <div className="confirmed-invites-container margin-top">
            {props.owner.ownerType === uiConstants.ownerType.hub ? (
              <>
                <span notranslate="yes">{seatsAvailable}</span> seats Available
              </>
            ) : (
              <>
                <var data-var="send_count">
                  {localizeHelpers.formatNumber(getInviteCount('all') || 0, locale)}
                </var>{' '}
                Sent |{' '}
                <var data-var="pending_count">
                  {localizeHelpers.formatNumber(getInviteCount('pending') || 0, locale)}
                </var>{' '}
                Pending
              </>
            )}
          </div>
          <form
            className="search-container"
            onSubmit={(e) => addInviteMember(e)}
          >
            <TextField
              icon="fas fa-search"
              placeholder="Search connections / invite by email..."
              value={searchConnectionsValue}
              type="email"
              name="searchConnectionsValue"
              onChange={(e) => setSearchConnectionsValue(e.target.value)}
              postTextRenderer={() => {
                return (
                  <div className="role-dropdown-container">
                    <span className="invite-as-label">Invite as</span>
                    <Dropdown
                      inputWrapClassName="role-dropdown"
                      name="role"
                      options={roles.map((role) => ({
                        label: role.role_name,
                        value: role.id ?? '',
                      }))}
                      value={selectedRoleId}
                      onChange={(e) => setSelectedRoleId(e.target.value)}
                    />
                  </div>
                );
              }}
            />
            <Button
              className="action-button"
              buttonType="primary"
              isDisabled={false}
              type="submit"
              text="Add"
            />
          </form>
        </div>
        <div className="contact-list">
          {pendingInviteUserList.map((row, index) => (
            <React.Fragment key={index}>{renderContactRow(row, true)}</React.Fragment>
          ))}
          {invites.map((row, index) => (
            <React.Fragment key={index}>{renderContactRow(row)}</React.Fragment>
          ))}
        </div>
        <div className="action-conatiner">
          <Button
            className="action-button cancel"
            buttonType="secondary"
            isDisabled={false}
            onClick={() => props.toggleShowAddMemberModal(false)}
            text="Cancel"
          />
          <Button
            className="action-button"
            buttonType="primary"
            isDisabled={pendingInviteEmailList.length === 0}
            onClick={generatePaymentMessage}
            text="Send"
          />
        </div>
      </div>
      <HubPaymentModal
        ownerId={props.owner.ownerId}
        showModal={showPaymentMethodModal}
        description={paymentDescription}
        hidePaymentMethod={shouldHidePaymentMethod}
        title="Invite Confirmation"
        confirmButton="Send Invites"
        onConfirmation={handleOnHubPaymentConfirmation}
        toggleCloseModal={() => setShowPaymentMethodModal(false)}
        toggleButtonLoading={togglePaymentButtonLoading}
      />
    </Modal>
  );
};

export default HubInviteMembers;
