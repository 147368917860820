import './HubModalManage.scss';
import React from 'react';
import Modal from '../../../Modal/Modal';

interface IProps {
  show: boolean;
  onClose(): void;
}

const HubModalManage: React.FC<IProps> = (props) => {
  return (
    <Modal
      key="hub-modal-manage"
      class="HubModalManage"
      title="Manage"
      show={props.show}
      onClose={props.onClose}
    >
      add content ...
    </Modal>
  );
};

export default HubModalManage;
