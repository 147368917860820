import moment, { Moment } from 'moment';
import React, { FC, Fragment, useState, useEffect } from 'react';
import Datetime, {
  DatetimepickerProps,
  DatetimepickerState,
  TimeConstraints,
} from 'react-datetime';
import './DateDayPicker.scss';

interface IProps extends DatetimepickerProps {
  value: Moment;
  locale: string;
  label: string;
  variant?: string;
  onDateChange: (date: Moment) => void;
}

/**
 * Datepicker with date change controls
 *
 * @prop value: Moment | Initial value
 * @prop.string locale: string | Locale (defaults to `en`)
 * @prop label: string | Datepicker label
 * @prop variant: string | Style variant for label `(standard|floating)`. Defaults to `standard`
 * @prop onChange(): Moment | Returns a `Moment` object when value changes
 */
const DateDayPicker = (props: IProps) => {
  const dateFormat = 'MMM DD, YYYY';

  const [selectedDate, setSelectedDate] = useState<Moment>(props.value);

  useEffect(() => {
    if (props.value) {
      setSelectedDate(props.value);
    }
  }, []);

  useEffect(() => {
    props.onDateChange(selectedDate);
  }, [selectedDate]);

  // Using any as the output props for renderInput are defined as any as well.
  const renderInput = (props: any) => {
    const nextDay = () => {
      setSelectedDate(moment(selectedDate).add(1, 'days'));
    };
    const prevDay = () => {
      setSelectedDate(moment(selectedDate).add(-1, 'days'));
    };
    return (
      <div className="date-input-container">
        <button onClick={prevDay}>
          <i className="fas fa-caret-left"></i>
        </button>
        <input {...props} />
        <button onClick={nextDay}>
          <i className="fas fa-caret-right"></i>
        </button>
      </div>
    );
  };

  return (
    <div
      className={
        props.variant && props.variant === 'floating'
          ? 'DateDayPickerContainer floating-label'
          : 'DateDayPickerContainer'
      }
      notranslate="yes"
    >
      <label>{props.label}</label>
      <Datetime
        renderInput={renderInput}
        dateFormat={dateFormat}
        timeFormat={false}
        locale={props.locale}
        value={selectedDate}
        onChange={(event: Moment | string) => {
          setSelectedDate(event as Moment);
        }}
      />
    </div>
  );
};

export default DateDayPicker;
