import React, { useState } from 'react';
import defaultRegisterAssets from '../../../../assets/register-assets';

import './imageSelect.scss';
import ImageUpload from '../../../ImageUpload/ImageUpload';
import Modal from '../../../Modal/Modal';
import ImageCropper, { IImageCropperState } from '../../../ImageCropper/ImageCropper';
import Loader from '../../../Loader/Loader';
import { useEffect } from 'react';
import _ from 'lodash';
import { capitalizeString } from '../../../../helpers';

const DEFAULT_AVATARS = defaultRegisterAssets.avatars;
const DEFAULT_COVERIMAGES = defaultRegisterAssets.coverImages;

const IMAGE_CROPPER_PROPS = {
  cover_image_url: {
    recSize: '785 x 380',
    minWidth: 780,
    ratio: 2.065,
  },
  profile_image_url: {
    recSize: '150 x 150',
    minWidth: 80,
  },
};

interface IProps {
  // function to handle image upload on parent
  onChange(src: string): void;

  // Event/Gig...
  flowObjectName?: string;

  // profile | cover
  type: string;

  // Used to show preselection sections of profile/cover image
  showPreselection: boolean;

  // url for existing cover image
  coverImage?: string;

  // url for existing profile image
  profileImage?: string;
}

export const ImageSelect: React.FC<IProps> = (props: IProps) => {
  const [coverImages, setCoverImages] = useState<string[]>([]);
  const [avatarImages, setAvatarImages] = useState<string[]>([]);
  const [selectedAvatar, setSelectedAvatar] = useState<number>(0);
  const [selectedCoverImage, setSelectedCoverImage] = useState<number>(0);
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [fileUploadSource, setFileUploadSource] = useState<string>('');
  const [isLoadingImageLibrary, setIsLoadingImageLibrary] = useState<boolean>(true);

  useEffect(() => {
    loadDefaultImages();
  }, []);

  // Update Default Cover Image (incase user doesn't change from original default selection)
  useEffect(() => {
    props.onChange(coverImages[0]);
  }, [coverImages]);

  // Update Default Profile Image (incase user doesn't change from original default selection)
  useEffect(() => {
    props.onChange(avatarImages[0]);
  }, [avatarImages]);

  // Set Cover Image default Selection on Parent
  useEffect(() => {
    props.onChange(coverImages[selectedCoverImage]);
  }, [selectedCoverImage]);

  // Set Cover Image default Selection on Parent
  useEffect(() => {
    props.onChange(avatarImages[selectedAvatar]);
  }, [selectedAvatar]);

  // Send Updated Image Selection
  useEffect(() => {
    props.onChange(fileUploadSource);
  }, [fileUploadSource]);

  /**
   * Here we need to convert the imported default images to dataURLs so they're prepared to be used in the updateUserCoverImage & updateUserProfileImage
   * actions inside our registerUser action.
   */
  async function loadDefaultImages() {
    // make arrays unique incase they chose an already existing default & load only what is applicable per component type
    if (props.type === 'profile' && props.showPreselection) {
      const defaultAvatarImages = await Promise.all(
        DEFAULT_AVATARS.map(async (url) => convertImageToDataURL(url)),
      );
      let profileImages = props.profileImage
        ? _.uniq([props.profileImage, ...defaultAvatarImages])
        : defaultAvatarImages;

      setAvatarImages(profileImages);
      setIsLoadingImageLibrary(false);
    } else if (props.type === 'profile' && !props.showPreselection) {
      props.profileImage ? setAvatarImages([props.profileImage]) : setAvatarImages([]);
      setIsLoadingImageLibrary(false);
    } else {
      // cover image defaults load for all types currently
      const defaultCoverImages = await Promise.all(
        DEFAULT_COVERIMAGES.map(async (url) => convertImageToDataURL(url)),
      );
      let coverImages = props.coverImage
        ? _.uniq([props.coverImage, ...defaultCoverImages])
        : defaultCoverImages;

      setCoverImages(coverImages);
      setIsLoadingImageLibrary(false);
    }
  }

  async function convertImageToDataURL(url: string) {
    let blob = await fetch(url).then((r) => r.blob());

    return await new Promise((resolve: (value: string) => void) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result as string); // We know this is a string (as per: https://developer.mozilla.org/en-US/docs/Web/API/FileReader/result#value)
      reader.readAsDataURL(blob);
    });
  }

  function onImageSelect(fileData: { src: string }) {
    setFileUploadSource(fileData.src);
    setShowCropModal(true);
  }

  function renderCropModal() {
    const cropProps =
      props.type === 'profile'
        ? { ...IMAGE_CROPPER_PROPS.profile_image_url }
        : { ...IMAGE_CROPPER_PROPS.cover_image_url };
    return (
      <Modal
        show={showCropModal}
        onClose={() => setShowCropModal(false)}
      >
        <ImageCropper
          onSave={(croppedState) => onCrop(croppedState)}
          src={fileUploadSource}
          {...cropProps}
        />
      </Modal>
    );
  }

  function onCrop(newImgData: IImageCropperState) {
    const fileURL = newImgData.fileUrl;

    if (props.type === 'profile') {
      setAvatarImages([...avatarImages, fileURL]);
      setSelectedAvatar(avatarImages.length);
      setShowCropModal(false);
    } else {
      setCoverImages([fileURL, ...coverImages]);
      setSelectedCoverImage(0);
      setShowCropModal(false);
    }
  }

  function getDimensions() {
    if (props.type === 'profile') {
      return '1000 x 1000';
    } else {
      return '1000 x 500';
    }
  }

  function getTitleText() {
    return props.type === 'profile'
      ? props?.flowObjectName
        ? `${capitalizeString(props.flowObjectName)} Logo`
        : 'Update your Profile Image'
      : props?.flowObjectName
        ? `${capitalizeString(props.flowObjectName)} Banner Image`
        : 'Update your Banner Image';
  }

  function renderProfileSelect() {
    return (
      <ul className="avatar-list">
        {avatarImages.map((avatar, index) => {
          return (
            <li
              // trim the base64 string used as key
              key={avatar.substring(0, 20)}
              className={`avatar-wrapper ${selectedAvatar === index ? 'selected' : ''}`}
              onClick={() => setSelectedAvatar(index)}
            >
              <img
                src={avatar}
                className="avatar"
                alt="avatar"
              />
              <i className={`fa fa-check ${selectedAvatar === index ? 'show' : 'hide'}`} />
            </li>
          );
        })}
      </ul>
    );
  }

  function renderCoverImageSelect() {
    return (
      <ul className="cover-image-list">
        {coverImages.map((image, index) => {
          return (
            <li
              key={image}
              className={`cover-image-wrapper ${selectedCoverImage === index ? 'selected' : ''}`}
              onClick={() => setSelectedCoverImage(index)}
            >
              <img
                src={image}
                className="cover-image"
                alt="cover-image"
              />
              <i className={`fa fa-check ${selectedCoverImage === index ? 'show' : 'hide'}`} />
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <div className="ImageSelect">
      <div className="header">
        <div className="info">
          <div className="header-txt">{getTitleText()}</div>
          <div className="header-sub-txt">
            Supports JPG, JPEG, PNG, with max dimensions of {getDimensions()}
          </div>
        </div>
        <div className="header-action">
          <div className="image-selector">
            <ImageUpload onImgSelect={(fileData) => onImageSelect(fileData)} />
            <span>Upload</span>
          </div>
        </div>
      </div>

      {isLoadingImageLibrary && <Loader loading={isLoadingImageLibrary} />}

      {/* Renders a Profile Image Section */}
      {props.type === 'profile' && renderProfileSelect()}

      {/* Renders a Cover Image Section */}
      {props.type === 'cover' && renderCoverImageSelect()}

      {renderCropModal()}
    </div>
  );
};
