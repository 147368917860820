import React, { useState } from 'react';
import ModalSectionRow from '../../shared/ModalSectionRow/ModalSectionRow';
import EditModal from '../../shared/EditModal/EditModal';
import ContentBlockLayoutModal from '../ContentBlockLayoutModal/ContentBlockLayoutModal';
import './ContentBlockManageModal.scss';
import { IPageComponent, IPageContentBlockItem } from '@gigit/interfaces';
import ContentBlock from '../../../ContentBlock/ContentBlock';
import ManageTextBlockModal from '../ManageTextBlockModal/ManageTextBlockModal';
import { Constants } from '@gigit/constants';
import useToastDispatcher from '../../../../../hooks/useToaster';
import CoverImage from '../../../../CoverImage/CoverImage';
import { lorumIpsumSamples, uploadImage } from '../../../../../helpers';
import QuillTextEditor from '../../../../QuillTextEditor/QuillTextEditor';
import { uiConstants } from '../../../../../constants';

interface IContentBlockManageModalProps {
  pageComponent: IPageComponent;
  showModal: boolean;
  isSaving: boolean;
  handleSavePageComponent: (updatedComponent: IPageComponent) => void;
  onClose: () => void;
}

/** TODO: HUB - Implement partners and metrics */
function ContentBlockManageModal(props: IContentBlockManageModalProps) {
  const { showModal, onClose } = props;
  const { dispatchToastError } = useToastDispatcher();

  const [selectedContentLayout, setSelectedContentLayout] = useState<
    IPageContentBlockItem[] | null
  >(props.pageComponent.content || null);
  //ImageBLock state
  const [imageBlockURL, setImageBlockURL] = useState<string>('');
  //ModalState
  const [showEditComponentLayoutModal, setShowEditComponentLayoutModal] = useState(false);
  const [showEditTextBlockModal, setShowEditTextBlockModal] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  function toggleShowEditComponentLayoutModal(value?: boolean) {
    setShowEditComponentLayoutModal(value ? value : !showEditComponentLayoutModal);
  }

  function toggleShowEditTextBlockModal(value?: boolean) {
    setShowEditTextBlockModal(value ? value : !showEditTextBlockModal);
  }

  function updateSelectedContentByType(
    type: string,
    updatedContent: Partial<IPageContentBlockItem>,
  ) {
    if (selectedContentLayout) {
      const updateContent = selectedContentLayout.map((content) => {
        if (content.type === type) {
          return {
            ...content,
            ...updatedContent,
          };
        } else {
          return content;
        }
      });

      setSelectedContentLayout(updateContent);
    }

    if (type === 'image') {
      setDisableSave(false);
    }
  }

  function handleSaveComponentLayout(changedLayout: IPageContentBlockItem[]) {
    const newLayout: IPageContentBlockItem[] = [];

    changedLayout.forEach((layout, index) => {
      let content = selectedContentLayout?.find((content) => content.type === layout.type);
      newLayout.push(content as IPageContentBlockItem);
    });

    setSelectedContentLayout(newLayout);
    toggleShowEditComponentLayoutModal(false);
  }

  function handleSaveEditTextBlock(title: string, description: string) {
    updateSelectedContentByType(Constants.page_content_block_item_type.text, {
      title,
      text: description,
    });

    toggleShowEditTextBlockModal(false);
  }

  function handleSaveContentBlock() {
    if (selectedContentLayout) {
      const updatedPageComponent: IPageComponent = {
        ...props.pageComponent,
        content: selectedContentLayout || [],
      };
      props.handleSavePageComponent(updatedPageComponent);
    }
  }

  function renderContentLayoutSection() {
    return (
      <ModalSectionRow
        title="Component Layout"
        description="Select the layout for your Content Block"
        headerActionButtonProps={{
          text: 'Edit',
          buttonType: 'tertiary',
          onClick: () => toggleShowEditComponentLayoutModal(true),
        }}
      >
        <div className="component-layout-container">
          <ContentBlock
            contentBlocks={selectedContentLayout || []}
            isPreviewMode={true}
          />
        </div>
      </ModalSectionRow>
    );
  }

  function renderManageTextBlockSection() {
    const contentBlockComponent = selectedContentLayout?.find(
      (content) => content.type === Constants.page_content_block_item_type.text,
    );
    return (
      <ModalSectionRow
        title="About Details"
        description="Add and Edit the details for your Content Block"
        headerActionButtonProps={{
          text: 'Edit',
          buttonType: 'tertiary',
          onClick: () => toggleShowEditTextBlockModal(true),
        }}
      >
        <div className="manage-text-block-container">
          <div className="edit-text-container">
            <span className="label">Title</span>
            <span className="value">
              {contentBlockComponent?.title || 'Enter a Title for the About Component'}
            </span>
          </div>
          <div className="edit-text-container">
            <span className="label">Description</span>
            <span className="value">
              <QuillTextEditor
                value={contentBlockComponent?.text || lorumIpsumSamples.standard}
                preserveWhitespace={true}
                readOnly={true}
                theme={uiConstants.quill.readOnlyTheme}
              />
            </span>
          </div>
        </div>
      </ModalSectionRow>
    );
  }

  function renderManageImageBlockSection() {
    const contentBlockComponent = selectedContentLayout?.find(
      (content) => content.type === Constants.page_content_block_item_type.image,
    );

    async function onImageSelect(fileData: { file: string; type: string }) {
      try {
        setDisableSave(true);
        const imageURL = await uploadImage(fileData.file);
        setImageBlockURL(imageURL);
        updateSelectedContentByType('image', { media_url: imageURL });
      } catch (error) {
        dispatchToastError(error, 'Error Uploading Image');
      }
    }

    return (
      <ModalSectionRow
        title="Image"
        description="Select a image for your content block"
      >
        <div className="manage-image-block-container">
          <CoverImage
            currentImage={contentBlockComponent?.media_url}
            onChange={onImageSelect}
            overlay={true}
          />
        </div>
      </ModalSectionRow>
    );
  }
  return (
    <EditModal
      title="Content Block Settings"
      showModal={showModal}
      modalClassName={'ContentBlockManageModal'}
      isSaving={props.isSaving}
      onSave={handleSaveContentBlock}
      onClose={onClose}
      disableSave={disableSave}
    >
      {renderContentLayoutSection()}
      {/* TODO: Render different sections based on contentLayoutSelection */}
      {renderManageTextBlockSection()}
      {renderManageImageBlockSection()}

      {/* Modals */}
      <ContentBlockLayoutModal
        showModal={showEditComponentLayoutModal}
        onSave={handleSaveComponentLayout}
        onClose={() => toggleShowEditComponentLayoutModal(false)}
      />
      <ManageTextBlockModal
        content={selectedContentLayout}
        showModal={showEditTextBlockModal}
        onSave={handleSaveEditTextBlock}
        onClose={() => toggleShowEditTextBlockModal(false)}
        pageComponent={props.pageComponent}
      />
    </EditModal>
  );
}

export default ContentBlockManageModal;
