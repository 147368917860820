import axios from 'axios';
import { Dispatch } from 'redux';
import { IUser } from '@gigit/interfaces';
import { routes, swapRouteParams } from '../helpers';
import { initialProfileState } from '../reducers/profile';

export enum ProfileActionTypes {
  UPDATE_PROFILE = 'UPDATE_PROFILE',
}

export interface IUpdateProfileAction {
  isProfileLoading: boolean;
  isProfilePagesLoading: boolean;
  user: IUser;
  type: ProfileActionTypes.UPDATE_PROFILE;
}

export type ProfileActions = IUpdateProfileAction;

export const getProfileByHandle = (handleOrId: string, options?: { callback?: () => void }) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isProfileLoading: true,
      type: ProfileActionTypes.UPDATE_PROFILE,
    });

    axios
      .get(swapRouteParams(routes.GET_PROFILE, { handleOrId: handleOrId }))
      .then((response) => {
        if (response.data !== '') {
          dispatch({
            user: response.data,
            type: ProfileActionTypes.UPDATE_PROFILE,
          });

          options?.callback?.();

          getProfilePages(response.data.id)(dispatch, getState);
        } else {
          dispatch({
            user: initialProfileState.user,
            type: ProfileActionTypes.UPDATE_PROFILE,
          });
        }
      })
      .finally(() => {
        dispatch({
          isProfileLoading: false,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      });
  };
};

export const getProfilePageComponents = (_userId: string, _pageId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isComponentsLoading: true,
      type: ProfileActionTypes.UPDATE_PROFILE,
    });

    axios
      .get(
        swapRouteParams(routes.GET_PROFILE_PAGE_COMPONENTS, { userId: _userId, pageId: _pageId }),
      )
      .then((response) => {
        dispatch({
          currentPageComponents: response.data.components,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      })
      .finally(() => {
        dispatch({
          isComponentsLoading: false,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      });
  };
};

export const getProfilePages = (userId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isProfilePagesLoading: true,
      type: ProfileActionTypes.UPDATE_PROFILE,
    });

    axios
      .get(swapRouteParams(routes.GET_PROFILE_PAGES, { userId: userId }))
      .then((response) => {
        dispatch({
          pages: response.data,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      })
      .finally(() => {
        dispatch({
          isProfilePagesLoading: false,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      });
  };
};

export const createProfileMediaAlbum = (userId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_USER_MEDIA_ALBUM, { userId: userId }), _payload)
      .then((response) => {
        getProfileMediaAlbums(userId)(dispatch, getState);
      });
  };
};

export const deleteProfileMediaAlbum = (userId: string, _albumId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(
        swapRouteParams(routes.DELETE_USER_MEDIA_ALBUM, { userId: userId, albumId: _albumId }),
      )
      .then((response) => {
        getProfileMediaAlbums(userId)(dispatch, getState);
      });
  };
};

export const updateProfileMediaAlbum = (userId: string, _albumId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(
        swapRouteParams(routes.UPDATE_USER_MEDIA_ALBUM, { userId: userId, albumId: _albumId }),
        _payload,
      )
      .then((response) => {
        getProfileMediaAlbums(userId)(dispatch, getState);
      });
  };
};

export const getProfileMediaAlbums = (userId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_USER_MEDIA_ALBUMS, { userId: userId }))
      .then((response) => {
        dispatch({
          mediaAlbums: response.data,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      });
  };
};

export const getProfileStats = (userId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isProfileStatsLoading: true,
      type: ProfileActionTypes.UPDATE_PROFILE,
    });

    axios
      .get(swapRouteParams(routes.GET_PROFILE_STATS, { userId: userId }))
      .then((response) => {
        if (response.data !== '') {
          dispatch({
            profileStats: response.data,
            type: ProfileActionTypes.UPDATE_PROFILE,
          });
        } else {
          dispatch({
            profileStats: initialProfileState.profileStats,
            type: ProfileActionTypes.UPDATE_PROFILE,
          });
        }
      })
      .finally(() => {
        dispatch({
          isProfileStatsLoading: false,
          type: ProfileActionTypes.UPDATE_PROFILE,
        });
      });
  };
};
