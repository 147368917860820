import './HubFilterBar.scss';
import React, { useEffect, useState } from 'react';
import TextField from '../../../TextField/TextField';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../../shared/PopupMenu/PopupMenu';
import Button from '../../../Button/Button';
import { useDebounce } from '../../../../hooks/useDebounce';
import { localizeHelpers } from '../../../../localizeHelpers';

interface IHubFilterBar {
  showMenu: boolean;
  showMenuConfig: IShowPopupConfig;
  menuItems: IPopupMenuItem[];
  popupMenuClass: string;
  className: string;
  onClick(): void;
  onSelect?: () => void;
  label: string;
  valueLabel: string;
  valueLabelIcon?: string;
}

interface IProps {
  header: string;
  searchValue: string | undefined;
  searchPlaceholder?: string;
  onSearch: (value: string) => void;
  sortByConfig?: IHubFilterBar[];
  filterByFundraiserConfig?: IHubFilterBar[];
  adminMode?: boolean;
  onClickEdit?: () => void;
}

const HubFilterBar: React.FC<IProps> = (props) => {
  const [searchValue, setSearchValue] = useState<string>(props.searchValue || '');
  const search = useDebounce(searchValue);

  useEffect(() => {
    if (search) {
      props.onSearch(search);
    } else {
      props.onSearch('');
    }
  }, [search]);

  return (
    <div className="HubFilterBar">
      <div className="header">
        <h1 notranslate="yes">{localizeHelpers.translate(props.header)}</h1>
        {props.adminMode && (
          <Button
            icon="fas fa-pen"
            text="Edit"
            buttonType="outline-dark"
            type="button"
            onClick={() => props.onClickEdit?.()}
          />
        )}
      </div>
      <div className="controls">
        <div className="search-input-container">
          <TextField
            type="text"
            icon="fa fa-search"
            placeholder={props.searchPlaceholder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            name="searchValue"
            autoComplete="off"
            value={searchValue}
          />
        </div>
        <div className="additional-filters">
          {props?.filterByFundraiserConfig &&
            props.filterByFundraiserConfig.map((fundraiseBy, index) => (
              <PopupMenu
                key={index}
                showMenuConfig={fundraiseBy.showMenuConfig}
                menuItems={fundraiseBy.menuItems}
                popupMenuClass={fundraiseBy.popupMenuClass}
                className={`Dropdown filter-dropdown ${fundraiseBy.className} ${fundraiseBy.showMenu ? 'show' : ''}`}
                onClick={() => fundraiseBy.onClick()}
                onSelect={() => {
                  if (fundraiseBy?.onSelect) {
                    fundraiseBy.onSelect();
                  }
                }}
              >
                <label>
                  <span>{fundraiseBy.label}</span>
                </label>
                <div className="input-wrap">
                  <span className="label">
                    {fundraiseBy?.valueLabelIcon && <i className={fundraiseBy.valueLabelIcon} />}
                    {fundraiseBy.valueLabel}
                  </span>
                  <i
                    className={`fas fa-caret-down menu-btn ${fundraiseBy.showMenu ? 'show' : ''}`}
                  />
                </div>
              </PopupMenu>
            ))}
          {props?.sortByConfig &&
            props.sortByConfig.map((sortBy, index) => (
              <PopupMenu
                key={index}
                showMenuConfig={sortBy.showMenuConfig}
                menuItems={sortBy.menuItems}
                popupMenuClass={sortBy.popupMenuClass}
                className={`Dropdown filter-dropdown ${sortBy.className} ${sortBy.showMenu ? 'show' : ''}`}
                onClick={() => sortBy.onClick()}
                onSelect={() => {
                  if (sortBy?.onSelect) {
                    sortBy.onSelect();
                  }
                }}
              >
                <label>
                  <span>{sortBy.label}</span>
                </label>
                <div className="input-wrap">
                  <span className="label">
                    {sortBy?.valueLabelIcon && <i className={sortBy.valueLabelIcon} />}
                    {sortBy.valueLabel}
                  </span>
                  <i className={`fas fa-caret-down menu-btn ${sortBy.showMenu ? 'show' : ''}`} />
                </div>
              </PopupMenu>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HubFilterBar;
