import React, { FC, Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Chat from '../../Chat/Chat';
import './Network.scss';
import ContactsList from '../../ContactsList/ContactsList';
import { useEffect } from 'react';

import { ReactComponent as ContactsIcon } from '../../../assets/contacts-icon.svg';
import { ReactComponent as MessagesIcon } from '../../../assets/messages-icon.svg';
import { userRequestActions } from '../../../requestActions/user';
import useToastDispatcher from '../../../hooks/useToaster';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { getUnreadChatCount } from '../../../helpers';

const CONTACTS = 'contacts';
const MESSAGES = 'messages';

const Network: FC = () => {
  const [activeTab, setActiveTab] = useState<string>('messages');
  const [conversationsSummary, setConversationsSummary] = useState<any>([]);
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const { contact, networkTab, organizer_id, organizer_type, contactId } = params;
  const { dispatchToastError } = useToastDispatcher();
  const chatState = useSelector((state: IAppState) => state.chatState);
  const userState = useSelector((state: IAppState) => state.userState);

  const [startOrgContactChat, setStartOrgContactChat] = useState<boolean>(false);

  const [isContacts, setIsContacts] = useState<boolean>(true);
  const [isMessages, setIsMessages] = useState<boolean>(false);

  useEffect(() => {
    getUnreadConversationsSummary();
    if (networkTab && networkTab !== activeTab) {
      setActiveTab(String(networkTab));
      if (networkTab === CONTACTS) {
        setIsContacts(true);
        setIsMessages(false);
      } else {
        setIsContacts(false);
        setIsMessages(true);
        if (organizer_id && organizer_type) {
          setStartOrgContactChat(true);
        }
      }
    } else {
      if (organizer_id && organizer_type) {
        setStartOrgContactChat(true);
      }
    }
  }, [networkTab]);

  useEffect(() => {
    if (activeTab) {
      if (activeTab === CONTACTS) {
        setIsContacts(true);
        setIsMessages(false);
      } else {
        setIsContacts(false);
        setIsMessages(true);
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (startOrgContactChat) {
      resetUrl();
    }
  }, [startOrgContactChat]);

  async function resetUrl() {
    // Removing the query params so we don't start a new conversation if user reloads the page
    if (params.organizer_id && params.organizer_type) {
      delete params.organizer_id;
      delete params.organizer_type;

      history.replace({
        search: queryString.stringify(params),
      });
    }
  }

  async function getUnreadConversationsSummary() {
    try {
      const summary = await userRequestActions.getUnreadConversationsSumary();
      setConversationsSummary(summary);
    } catch (error) {
      dispatchToastError(error, 'User Contacts');
    }
  }

  function switchActiveTab(type: string) {
    setActiveTab(type);
    if (type === CONTACTS) {
      setIsContacts(true);
      setIsMessages(false);
    } else {
      setIsContacts(false);
      setIsMessages(true);
    }
  }

  const unreadCount = getUnreadChatCount(chatState.conversations, userState.user?.id || '');

  return (
    <div className="Network">
      <section className="network-list-selection-container">
        <ul>
          <li
            onClick={() => switchActiveTab(CONTACTS)}
            className={activeTab === CONTACTS ? 'active' : ''}
          >
            <ContactsIcon /> Contacts
          </li>
          <li
            onClick={() => switchActiveTab(MESSAGES)}
            className={activeTab === MESSAGES ? 'active' : ''}
          >
            <MessagesIcon />
            Messages{unreadCount > 0 && <span>{unreadCount}</span>}
          </li>
        </ul>
      </section>
      <section className="network-list-container">
        {isContacts && <ContactsList />}
        {isMessages && (
          <Chat
            openOrganizerChat={startOrgContactChat}
            orgConvoStartInfo={{ id: organizer_id as string, type: organizer_type as string }}
            contactId={contact ? (contact as string) : ''}
            conversationSummary={conversationsSummary}
            getSummary={() => getUnreadConversationsSummary()}
          />
        )}
      </section>
    </div>
  );
};

export default Network;
