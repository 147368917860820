import React, { useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import { IAddress, IForm, IGigSummary, IGroup, IHub } from '@gigit/interfaces';
import { uiConstants } from '../../constants';
import {
  hasAllRequiredFields,
  onBack,
  onSelectCause,
  setCauses,
} from '../../components/shared/Onboarding/helpers';
import { InfoSection } from '../../components/shared/Onboarding/InfoSection/InfoSection';
import { FormSection } from '../../components/shared/Onboarding/FormSection/FormSection';
import { CheckboxCard } from '../../components/shared/Onboarding/CheckboxCard/CheckboxCard';
import { CauseSelection } from '../../components/shared/Onboarding/CausesSelection/CauseSelection';
import { ImageSelect } from '../../components/shared/Onboarding/imageSelect/ImageSelect';
import { EntityBaseInfo } from '../../components/shared/Onboarding/EntityBaseInfo/EntityBaseInfo';
import { getGigPageForCreate, gigForOptions, gigStepsMaster } from './Config';
import { SummaryPage } from '../../components/shared/Onboarding/SummaryPage/SummaryPage';
import { FormTopBar } from '../../components/shared/Onboarding/FormTopBar/FormTopBar';
import { FormBottomBar } from '../../components/shared/Onboarding/FormBottomBar/FormBottomBar';
import OnboardingWrapper from '../../components/shared/Onboarding/OnboardingWrapper';
import { gigRequestActions, hubRequestActions } from '../../requestActions';
import {
  IAddressFromGoogle,
  IAgeRange,
  ICausesSelection,
  IEntityBaseInfo,
  IGigCategoryInfo,
  IOnboardStep,
} from '../../components/shared/Onboarding/interfaces';
import { GroupSelection } from '../../components/shared/Onboarding/GroupSelection/GroupSelection';
import './CreateAGig.scss';
import { useCauseInfo } from '../../components/shared/Onboarding/hooks/useCauseInfo';
import { useBaseInfo } from '../../components/shared/Onboarding/hooks/useBaseInfo';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../selectors/user';
import { IAppState } from '../../store';
import useToastDispatcher from '../../hooks/useToaster';
import { GigCategory } from '../../components/shared/Onboarding/GigCategories/GigCategory';
import ToggleButton from '../../components/shared/ToggleButton/ToggleButton';
import { setSEOMetatags, typeHelpers, uploadImageToStore } from '../../helpers';
import { CustomApplication } from '../../components/shared/Onboarding/CustomApplication/CustomApplication';
import { localizeHelpers } from '../../localizeHelpers';
import { Constants } from '@gigit/constants';
import { HubSelection } from '../../components/shared/Onboarding/HubSelection/HubSelection';
const createAGigLoginRedirect = `/login?redirect=/onboarding/gig`;
const Slider = require('rc-slider');
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

interface IProps extends RouteComponentProps<{ step: string }> {}

export interface ICreateGigFormData {
  baseInfo: IEntityBaseInfo;
  causeInfo: ICausesSelection;
  createdFor: string;
  type: string;
  steps: Array<IOnboardStep>;
  profileImage: string;
  coverImage: string;
  loading: boolean;
  selectedGroup: IGroup | null;
  selectedHub: IHub | null;
  gigCategories: IGigCategoryInfo;
  requiresPoliceCheck: boolean;
  ageRange: { min: number; max: number };
  customForm: IForm;
}

const CreateAGig: React.FC<IProps> = (props: IProps) => {
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const { baseInfo, handleBaseInfo } = useBaseInfo();
  const { causeInfo, handleCauseInfo } = useCauseInfo();
  const [createdFor, setCreatedFor] = useState<string>('group');
  const [type, setType] = useState<string>('');
  const [steps, setSteps] = useState<IOnboardStep[]>(gigStepsMaster);
  const [profileImage, setProfileImage] = useState<string>('');
  const [coverImage, setCoverImage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null);
  const [selectedHub, setSelectedHub] = useState<IHub | null>(null);
  const [gigCategories, setGigCategories] = useState<IGigCategoryInfo>({
    categories: [],
    category: '',
    subCategory: '',
  });
  const [requiresPoliceCheck, setRequiresPoliceCheck] = useState<boolean>(false);
  const [ageRange, setAgeRange] = useState<IAgeRange>({ min: 16, max: 100 });
  const [customForm, setCustomForm] = useState<IForm>({} as IForm);
  const { dispatchToastError } = useToastDispatcher();
  // View Helper Variables
  const nxtBtnText = getCurrentStep() === 'summary' ? 'Create Volunteer Opportunity' : 'Next';
  const showBack = steps.findIndex((step) => step.id === getCurrentStep());
  const navigateHome = () => props.history.push('/');
  const location = useLocation();
  // Sets causeInfo.causes list and step mounting logic
  useEffect(() => {
    setSEOMetatags({
      title: localizeHelpers.translate('Create A Volunteer Opportunity | Kambeo'),
      urlPath: '/onboarding/gig',
    });

    initialize();
  }, []);

  useEffect(() => {
    setAddressRequiredState();
  }, [baseInfo?.locationType]);

  function setSelectedRequiredState(value: string) {
    let newSteps;
    if (value === 'company') {
      newSteps = gigStepsMaster
        .filter((step) => step.id !== 'groupSelection' && step.id !== 'customApplication')
        .map((step) => {
          if (step.id === 'hubSelection') {
            step.data.forEach((singleStep) => {
              singleStep.required = true;
            });
          }

          return step;
        });
      setType(uiConstants.ownerType.company);
    } else {
      newSteps = gigStepsMaster
        .filter((step) => step.id !== 'hubSelection')
        .map((step) => {
          if (step.id === 'groupSelection') {
            step.data.forEach((singleStep) => {
              singleStep.required = true;
            });
          }

          return step;
        });
      setType(uiConstants.ownerType.group);
      setSelectedHub(null);
    }

    setSteps(newSteps);
  }

  async function initialize() {
    // If we re-mount, move back to first step.
    const firstStepId = gigStepsMaster[0]?.id;
    if (getCurrentStep() !== firstStepId) {
      setCurrentStep(firstStepId);
    }
    // This ensure the flow never breaks if navigating to a specific step.
    await setCauses(causeInfo, handleCauseInfo);

    await getCategories();

    handleParams();
  }

  function getCurrentStep() {
    return props.match.params.step;
  }

  function handleParams() {
    let params = queryString.parse(location.search);

    params?.createdFor && handleForSelectionChange(params.createdFor as string);
    params?.hubId && handleHub(params?.hubId as string);
  }

  async function handleHub(hubId: string) {
    try {
      typeHelpers.assertNotNullOrUndefined(hubId, 'Expected Company ID');
      const hubFromParams = await hubRequestActions.getHubByID(hubId as string);
      setSelectedHub(hubFromParams);
      setCurrentStep('baseInfo');
    } catch (error) {
      dispatchToastError(error, 'Get Company Info');
    }
  }

  function renderStep() {
    switch (getCurrentStep()) {
      case 'gigCreatedFor': {
        return renderCreatedForSelection();
      }
      case 'groupSelection': {
        return renderGroupSelection();
      }
      case 'hubSelection': {
        return renderCompanySelection();
      }
      case 'baseInfo': {
        return renderBasicInfo();
      }
      case 'customApplication': {
        return renderCustomApplication();
      }
      case 'causesSelection': {
        return renderCausesSelection();
      }
      case 'imageSelection': {
        return renderImageSelection();
      }
      case 'summary': {
        return renderSummary();
      }
      default:
        break;
    }

    return null;
  }

  function handleForSelectionChange(value: string) {
    setCreatedFor(value);
    setSelectedRequiredState(value);
  }

  function renderCreatedForSelection() {
    return (
      <div className="form-page column">
        {gigForOptions.map((option) => {
          return (
            <CheckboxCard
              showCheckbox={false}
              key={option.id}
              id={option.id}
              title={option.text}
              checked={createdFor === option.id}
              subtext={option?.subtext}
              onChange={(value) => {
                handleForSelectionChange(value);
              }}
            />
          );
        })}
      </div>
    );
  }

  function renderCompanySelection() {
    return (
      <div className="form-page column">
        <HubSelection
          navigate={(value) => props.history.push(value)}
          setHub={setSelectedHub}
          selectedHub={selectedHub}
        />
      </div>
    );
  }

  function renderGroupSelection() {
    return (
      <div className="form-page column">
        <GroupSelection
          type="gig"
          navigate={(value) => props.history.push(value)}
          setGroup={setSelectedGroup}
          selectedGroup={selectedGroup}
        />
      </div>
    );
  }

  function renderCausesSelection() {
    return (
      <CauseSelection
        causeInfo={causeInfo}
        onChange={(value: string) => onSelectCause(causeInfo, value, handleCauseInfo)}
      />
    );
  }

  function renderImageSelection() {
    return (
      <div className="form-page">
        <ImageSelect
          flowObjectName="volunteer opportunity"
          type="profile"
          showPreselection={false}
          profileImage={profileImage || selectedGroup?.profile_image_url}
          onChange={setProfileImage}
        />

        <ImageSelect
          flowObjectName="volunteer opportunity"
          type="cover"
          showPreselection={true}
          coverImage={coverImage}
          onChange={setCoverImage}
        />
      </div>
    );
  }

  function renderBasicInfo() {
    return (
      <div className="form-page column">
        <EntityBaseInfo
          type={uiConstants.ownerType.gig}
          subType={type}
          baseInfo={baseInfo}
          onBaseInfoUpdated={handleBaseInfo}
        />
        <div className="additional-gig-info">
          <div className="range-slider-container">
            <label>Age Range</label>
            <Range
              className="RangeSlider"
              min={16}
              max={100}
              tipFormatter={(value: number) => (
                <var data-var="entity_info_tip_formatter_value">{value}</var>
              )}
              onAfterChange={updateAgeRange}
              tipProps={{ visible: true, placement: 'bottom' }}
              defaultValue={[ageRange.min, ageRange.max]}
            />
          </div>
          <GigCategory
            categoryInfo={gigCategories}
            onCategoryChange={setGigCategories}
          />
          <div className="row toggle-row">
            <span className="toggle-label">Police Check Required</span>
            <ToggleButton
              isActive={requiresPoliceCheck}
              onToggleClick={() => setRequiresPoliceCheck(!requiresPoliceCheck)}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderCustomApplication() {
    return (
      <div className="form-page">
        <CustomApplication
          form={customForm}
          ownerType={Constants.object_type.gig}
          updateFormData={(data) => setCustomForm({ ...customForm, ...data })}
        />
      </div>
    );
  }

  function updateAgeRange(value: number[]) {
    const updatedAgeRange = { min: value[0], max: value[1] };
    setAgeRange(updatedAgeRange);
  }

  function getFormData() {
    const formData: ICreateGigFormData = {
      baseInfo,
      causeInfo,
      createdFor,
      gigCategories,
      type,
      steps,
      profileImage,
      coverImage,
      loading,
      selectedGroup,
      requiresPoliceCheck,
      ageRange,
      customForm,
      selectedHub,
    };

    return formData;
  }

  function renderSummary() {
    return (
      <div className="form-page column">
        <SummaryPage
          locale={locale}
          formData={getFormData()}
          goToStep={(stepId: string) => {
            setCurrentStep(stepId);
          }}
          type="volunteer_opportunity"
        />
      </div>
    );
  }

  function setCurrentStep(stepId: string) {
    let isExistingStep = steps.filter((step) => step.id === stepId);

    if (isExistingStep.length > 0) {
      props.history.replace({
        pathname: `/onboarding/gig/${stepId}`,
      });
    }
  }

  async function onNext() {
    const index = steps.findIndex((step) => step.id === getCurrentStep());
    const nextStep = steps[index + 1]?.id;

    if (hasAllRequiredFields(steps, getCurrentStep(), getFormData())) {
      if (index + 1 > steps.length) {
        return;
      } else if (index + 1 === steps.length) {
        await createGig();
      } else {
        setCurrentStep(nextStep);
      }
    } else {
      return;
    }
  }

  async function getCategories() {
    try {
      const populatedCategories = await gigRequestActions.getCategories();
      let newData = { ...gigCategories, categories: populatedCategories };
      setGigCategories(newData);
    } catch (error) {
      dispatchToastError(error, 'Volunteer Opportunity Categories');
    }
  }

  async function createGig() {
    const isVirtual = baseInfo.locationType === 'Online';
    const address = formatAddressForPayload();
    const onSuccess = (gig: IGigSummary) => {
      let successLink = `/setup/gig/${gig.id}/success`;
      setLoading(false);
      props.history.push(successLink);
    };
    const handleError = (error: any, errorTitle: string) => {
      dispatchToastError(error, errorTitle);
      setLoading(false);
    };
    const handleLocationCreation = async (gig: IGigSummary, location: IAddress) => {
      try {
        let gigLocation = await gigRequestActions.createGigLocation(gig?.id || '', location);

        if (gig.id) {
          await gigRequestActions.updateGig(gig.id, {
            default_location: { ...gigLocation, location_id: gigLocation.id },
          });
          onSuccess(gig);
        }
      } catch (error) {
        handleError(error, 'Create Volunteer Opportunity Location');
      }
    };

    async function isBase64(img: string) {
      if (img === '' || img.trim() === '') {
        return false;
      }
      try {
        const base = img.split(',')[1];
        return atob(base);
      } catch (err) {
        return false;
      }
    }

    try {
      setLoading(true);
      const coverImageUploaded = await uploadImageToStore(coverImage);
      const isProfileBase64 = await isBase64(profileImage);
      let profileImageUploaded;
      if (isProfileBase64) {
        profileImageUploaded = await uploadImageToStore(profileImage);
      } else {
        profileImageUploaded = profileImage;
      }

      let gig: Partial<IGigSummary> = {
        title: baseInfo.name,
        description: baseInfo.description.replace(/<\/?[^>]+(>|$)/g, ''),
        start_date: moment(baseInfo.startDate).toDate(),
        end_date: moment(baseInfo.endDate).toDate(),
        gig_category: gigCategories.category,
        gig_subcategory: gigCategories.subCategory,
        gig_type: 'volunteer', // volunteer, paid
        causes: causeInfo.selectedCauses,
        has_police_check: requiresPoliceCheck,
        is_virtual: isVirtual,
        cover_image_url: coverImageUploaded,
        profile_image_url: profileImageUploaded,
        form: customForm,
        number_ranges: [
          {
            name: 'age_range',
            min_value: ageRange.min,
            max_value: ageRange.max,
          },
        ],
        price: {
          amount_type: 'hourly',
          amount: 0,
        },
      };

      let newGig: IGigSummary | null = null;
      if (selectedGroup?.id) {
        newGig = await gigRequestActions.createGigForGroup(selectedGroup.id, gig);
      } else {
        typeHelpers.assertNotNullOrUndefined(selectedHub?.id, 'Expected Company ID');
        newGig = await gigRequestActions.createGigForHub(selectedHub.id, gig);
      }

      typeHelpers.assertNotNullOrUndefined(newGig?.id, 'Expected New Gig ID');
      const page = getGigPageForCreate(causeInfo.selectedCauses, newGig?.id || '');
      const gigPagesCreated = await gigRequestActions.createGigPages(newGig.id || '', page);
      if (newGig && gigPagesCreated) {
        await handleLocationCreation(newGig, address);
      }
    } catch (error) {
      handleError(error, 'Create Volunteer Opportunity');
    }
  }

  function setAddressRequiredState() {
    const locationType = baseInfo?.locationType;
    const newSteps = steps.map((step) => {
      if (step.id === 'baseInfo') {
        step.data.map((stepData) => {
          if (stepData.path === 'baseInfo.address.address') {
            return locationType && locationType !== 'Venue'
              ? (stepData.required = false)
              : (stepData.required = true);
          } else if (
            stepData.path === 'baseInfo.country' ||
            stepData.path === 'baseInfo.postalCode'
          ) {
            return locationType && locationType === 'Venue'
              ? (stepData.required = false)
              : (stepData.required = true);
          } else {
            return stepData;
          }
        });

        return step;
      } else {
        return step;
      }
    });

    setSteps(newSteps);
  }

  function formatAddressForPayload() {
    if (baseInfo.locationType === 'Venue' && baseInfo.address) {
      const street_number = getAddressParts(baseInfo.address, 'street_number');
      const route = getAddressParts(baseInfo.address, 'route');
      const neighborhood = getAddressParts(baseInfo.address, 'neighborhood');
      const city = getAddressParts(baseInfo.address, 'locality');
      const stateOrProvince = getAddressParts(baseInfo.address, 'administrative_area_level_1');
      const country = getAddressParts(baseInfo.address, 'country');
      const postal_code = getAddressParts(baseInfo.address, 'postal_code');
      const address: IAddress = {
        line1:
          street_number !== undefined && route !== undefined
            ? street_number + ' ' + route
            : undefined,
        line2: neighborhood,
        city,
        state: stateOrProvince,
        country: country,
        postal_code: postal_code,
        searchable: true,
        location: {
          coordinates: [
            baseInfo.address?.coordinates.longitude || 0,
            baseInfo.address?.coordinates.latitude || 0,
          ],
        },
      };

      return address;
    } else {
      const onlineAddress: IAddress = {
        country: baseInfo.country,
        postal_code: baseInfo?.postalCode,
        searchable: true,
        title: 'Default',
        line1: 'N/A',
        line2: 'N/A',
        city: 'N/A',
        state: 'N/A',
      };

      return onlineAddress;
    }
  }

  function getAddressParts(googleAddress: IAddressFromGoogle, type: string) {
    const addressComponent = googleAddress.address.address_components.find(
      (addressComponent: google.maps.GeocoderAddressComponent) =>
        addressComponent.types.includes(type),
    );
    return addressComponent?.long_name;
  }

  return (
    <OnboardingWrapper
      requiresAuth={true}
      redirect={createAGigLoginRedirect}
    >
      <form
        className="CreateAGig"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormTopBar
          onLogoClick={() => navigateHome()}
          onClose={() => props.history.goBack()}
        />
        <InfoSection
          type={type}
          title="Create Volunteer Opportunity"
          steps={steps}
          currentStep={getCurrentStep()}
          selectedGroup={selectedGroup || undefined}
        />
        <FormSection>{renderStep()}</FormSection>
        <FormBottomBar
          isDisabled={!hasAllRequiredFields(steps, getCurrentStep(), getFormData())}
          showBack={showBack !== 0}
          nextBtnTxt={nxtBtnText}
          onBack={() => onBack(steps, getCurrentStep(), setCurrentStep)}
          onNext={() => onNext()}
          loading={loading}
        />
      </form>
    </OnboardingWrapper>
  );
};

export default CreateAGig;
