import { IHub } from '@gigit/interfaces';
import React from 'react';
import Link from '../../../shared/Link/Link';
import './HubStoreEmptyState.scss';

interface IProps {
  userHasEditPermissions: boolean;
  hub?: IHub | null;
}
function HubStoreEmptyState(props: IProps) {
  function getEmptyStateDescription() {
    if (props.userHasEditPermissions) {
      return 'Try adding additional Causes to see more items';
    }

    return 'There are no Store items yet!';
  }
  return (
    <>
      <div className="HubStoreEmptyState">
        <div className="blank-circle">
          <div className="empty-cart-container">
            <div className="empty-cart">
              <span className="i-container">
                <i className="fas fa-shopping-bag" />
              </span>
              <span className="empty1">&nbsp;</span>
              <span className="empty2">&nbsp;</span>
            </div>
            <div className="empty-cart">
              <span className="i-container">
                <i className="fas fa-shopping-bag" />
              </span>
              <span className="empty1">&nbsp;</span>
              <span className="empty2">&nbsp;</span>
            </div>
            <div className="empty-cart">
              <span className="i-container">
                <i className="fas fa-shopping-bag" />
              </span>
              <span className="empty1">&nbsp;</span>
              <span className="empty2">&nbsp;</span>
            </div>
          </div>
        </div>
        <span className="no-items-txt">We didn't find any results.</span>
        <span className="check-later">{getEmptyStateDescription()}</span>
        {props.hub && props.userHasEditPermissions && (
          <Link
            to={`/company/${props.hub?.handle}/admin?t=benefiting-causes&showAddCauses=true`}
            styleType={'grey'}
            hasUnderline={true}
          >
            Add Causes
          </Link>
        )}
      </div>
    </>
  );
}

export default HubStoreEmptyState;
