import React from 'react';

import { IAppState } from '../../../store';
import { connect } from 'react-redux';

import './FlowInfo.scss';
import { flowInfoConfig } from './FlowInfoConfig';

import checkmarkImg from '../../../assets/flows/checkmark.svg';

interface IProps {
  flowType: string;
  currentStep: string;
}

interface IState {}

class FlowInfo extends React.Component<IProps, IState> {
  componentDidUpdate(prevProps: IProps) {
    if (prevProps.currentStep !== this.props.currentStep) {
      this.renderFlowInfo();
    }
  }

  stepsWithoutDetails() {
    return (
      this.props.currentStep !== 'eventInfo' && this.props.currentStep !== 'eventTimeAndLocation'
    );
  }

  renderFlowInfo(): any {
    return flowInfoConfig.map((fc: flowInfoConfig, idx: number) => {
      if (
        this.props.currentStep === fc.step &&
        (this.props.flowType === fc.flowType || fc.flowType === 'any')
      ) {
        return (
          <React.Fragment key={idx}>
            <div
              key={idx}
              className={`section-img ${fc.flowType}`}
            ></div>
            <div
              className={`features ${!fc.flowType || fc.flowType == '' || fc.flowType == 'any' ? 'mt-unset' : ''}`}
            >
              {this.stepsWithoutDetails() && <h2>{fc.headerTxt}</h2>}
              {this.stepsWithoutDetails() && (
                <ul>
                  {fc.features.map((f: string, idx: number) => {
                    return (
                      <React.Fragment key={idx}>
                        <li key={idx}>
                          <img
                            src={checkmarkImg}
                            className="checkmark"
                          />{' '}
                          <div className="label">{f}</div>
                        </li>
                        {fc.subpoint && fc.subpointIdx === idx && (
                          <span className="subpoint">{fc.subpoint}</span>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>
              )}
            </div>
          </React.Fragment>
        );
      } else {
        return null;
      }
    });
  }

  render() {
    return <div className="FlowInfo">{this.renderFlowInfo()}</div>;
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    user: store.userState,
  };
};

export default connect(mapStateToProps)(FlowInfo);
