import React, { useEffect, useState } from 'react';
import { IAuctionItemSummary, IHub } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import { hubRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import './HubManagementAuction.scss';
import { defaultCurrency, formatCurrency, typeHelpers } from '../../../../helpers';
import { Prompt } from '../../../Prompt/Prompt';
import Table, { ITableProps } from '../../../shared/Table/Table';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IKpi } from '../../../../interfaces';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import AuctionItemForm from '../../../AuctionItemForm/AuctionItemForm';

interface IProps {
  hub?: IHub;
}

function HubManagementAuction(props: IProps) {
  const [auctionItemToDelete, setAuctionItemToDelete] = useState<IAuctionItemSummary | null>(null);
  const [activeAuctionItem, setActiveAuctionItem] = useState<IAuctionItemSummary | null>(null);
  const [showCreateItemModal, setShowCreateItemModal] = useState<boolean>(false);
  const [auctionKPIs, setAuctionKPIs] = useState<IKpi[]>([]);
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const currency = props.hub?.account?.currency ?? defaultCurrency;

  useEffect(() => {
    getAuctionKPIs();
  }, []);

  async function getAuctionKPIs() {
    typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected hub');
    const auctionStatsResponse = await hubRequestActions.getHubAuctionStats(props.hub?.id);
    const convertToKPI: IKpi[] = [
      {
        text: 'Number of Items',
        value: localizeHelpers.formatNumber(
          auctionStatsResponse.total_number_of_items || 0,
          locale,
        ),
      },
      {
        text: 'Total Retail Value',
        value: formatCurrency(auctionStatsResponse.total_retail_value, currency, locale),
      },
      {
        text: 'Average Retail Value',
        value: formatCurrency(auctionStatsResponse.average_retail_value, currency, locale),
      },
      {
        text: 'Total Minimum Bid',
        value: formatCurrency(auctionStatsResponse.total_minimum_bid_value, currency, locale),
      },
    ];

    setAuctionKPIs(convertToKPI);
  }

  const hubManagementAuctionTableProps: ITableProps<IAuctionItemSummary> = {
    columns: [
      {
        id: 'name',
        Header: 'Item Name',
        accessor: ({ name }) => name,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ media, name }) => {
          return (
            <div
              className="item-name-col"
              notranslate="yes"
            >
              {media.length > 0 && (
                <LazyLoadImage
                  style={{ width: '60px', height: '60px' }}
                  className="image-container"
                  effect="opacity"
                  alt="profile"
                  src={media[0].url}
                />
              )}
              <span>{name}</span>
            </div>
          );
        },
      },
      {
        id: 'increments',
        Header: 'Increments',
        accessor: ({ minimum_bid_increment }) => minimum_bid_increment,
        Cell: ({ minimum_bid_increment }) => (
          <span notranslate="yes">{formatCurrency(minimum_bid_increment, currency, locale)}</span>
        ),
        sortable: true,
      },
      {
        id: 'buy_now',
        Header: 'Buy Now',
        accessor: ({ buy_now_price }) => buy_now_price,
        Cell: ({ buy_now_price }) => (
          <span notranslate="yes">{formatCurrency(buy_now_price, currency, locale)}</span>
        ),
        sortable: true,
      },
      {
        id: 'close_date',
        Header: 'Close Date',
        accessor: ({ end_date }) =>
          localizeHelpers.formatDate(end_date ?? new Date(), LocaleDateFormats.ll, locale),
        Cell: ({ end_date }) => (
          <span notranslate="yes">
            {localizeHelpers.formatDate(end_date ?? new Date(), LocaleDateFormats.ll, locale)}
          </span>
        ),
        sortable: true,
      },
    ],
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        typeHelpers.assertNotNullOrUndefined(props.hub?.id);
        return await hubRequestActions.getHubAuctionItems(props.hub?.id, queryParams);
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any auction items",
    },
    filterOptions: {
      enableTableSearch: true,
    },
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Edit Item',
          icon: 'fa fa-pencil',
          onClick: (_, auctionItem) => {
            setActiveAuctionItem(auctionItem);
            setShowCreateItemModal(true);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Delete Auction Item',
          icon: 'fal fa-download',
          onClick: (_, auctionItem) => {
            setAuctionItemToDelete(auctionItem);
          },
        },
      ],
    },
  };

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  function refreshAllPageState() {
    getAuctionKPIs();
    refreshTableData();
  }

  async function handleDeleteStoreItem() {
    typeHelpers.assertNotNullOrUndefined(props.hub?.id);
    typeHelpers.assertNotNullOrUndefined(auctionItemToDelete?.id);

    await hubRequestActions.deleteAuctionItem(props.hub.id, auctionItemToDelete?.id);
    refreshAllPageState();
  }

  return (
    <>
      <div
        className="HubManagementAuction"
        id="auction"
      >
        <div className="header-section">
          <h2>Auctions</h2>
          <div className="header-actions">
            <Button
              type="button"
              text="Create Item"
              icon="fal fa-plus"
              onClick={() => {
                setShowCreateItemModal(true);
              }}
            />
          </div>
        </div>
        <div className="metrics-container">
          {auctionKPIs.map((kpi, idx) => (
            <div
              className="metric"
              notranslate="yes"
              key={idx}
            >
              <span className="value">{kpi.value}</span>
              <span className="label">{kpi.text}</span>
            </div>
          ))}
        </div>
        <div className="table-section">
          <Table
            {...hubManagementAuctionTableProps}
            refreshTableIncrementor={refreshTableIncrementor}
          />
        </div>
      </div>

      <Modal
        show={showCreateItemModal}
        onClose={() => {
          setShowCreateItemModal(false);
          setActiveAuctionItem(null);
        }}
        closeIcon="fas fa-times"
        contentClassName="HubAuctionSettingsModal-add-edit-item"
        title={activeAuctionItem ? 'Edit Auction Item' : 'Add Auction Item'}
      >
        {props.hub?.id && (
          <AuctionItemForm
            owner={typeHelpers.createOwnerObject('hub', props.hub)}
            onAuctionSave={refreshAllPageState}
            editItem={activeAuctionItem ? 0 : -1}
            copyItem={-1}
            onClose={() => {
              setShowCreateItemModal(false);
            }}
            auctionItems={activeAuctionItem ? [activeAuctionItem] : []}
          />
        )}
      </Modal>

      <Prompt
        show={auctionItemToDelete !== null}
        title="Delete Auction Item"
        message={`Are you sure you want to delete ${auctionItemToDelete?.name || ''}?`}
        yesMessage="Yes"
        yesClass="fa fa-trash"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={handleDeleteStoreItem}
        onClose={() => setAuctionItemToDelete(null)}
      />
    </>
  );
}

export default HubManagementAuction;
