import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IPageComponent, IPage, ICampaign } from '@gigit/interfaces';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import PageComponent from '../../PageComponent/PageComponent';
import { RouteComponentProps } from 'react-router-dom';
import ComponentLibrary from '../../ComponentLibrary/ComponentLibrary';

import './PageContent.scss';
import ComponentPlaceholder from '../../PageComponent/ComponentPlaceholder/ComponentPlaceholder';
import { IStringMap } from '../../../helpers';
import Loader from '../../Loader/Loader';
import { IOwnerObject } from '../../../interfaces';

interface IProps extends WithTranslation {
  currentPageIndex: number;
  pages: IPage[];
  routeProps: RouteComponentProps<any>;
  isAdmin: boolean;
  pageComponents: IPageComponent[];
  permissions?: IStringMap;
  isLoading: boolean;
  owner: IOwnerObject;
  campaign?: ICampaign | null;
  isUserStillLoggedIn: boolean;
  createPageComponent?(type: string): void;
  onDeleteComponent?(id: string): void;
  onComponentUpdate?(disableLoad?: boolean): void;
  onUpdateComponent(pageId: string, componentId: string, payload: Partial<IPageComponent>): void;
}

interface IState {
  showAddComponent: boolean;
  pageComponents: IPageComponent[];
}

/** Renders the dynamic content of a single page. */
export class PageContent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showAddComponent: false,
      pageComponents: [],
    };
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.pageComponents !== this.state.pageComponents) {
      this.setState({
        pageComponents: this.props.pageComponents,
      });
    }
  }

  handleComponentDrag = (result: DropResult) => {
    if (result.destination) {
      const pageId = this.props.pages[this.props.currentPageIndex].id;

      let _pageComponents = this.props.pageComponents;

      // TODO: Fix id field not being sent
      const componentId = _pageComponents[result.source.index].id;
      const componentToUpdate = _pageComponents[result.source.index];

      _pageComponents[result.source.index].sequence = result.destination.index + 1;
      _pageComponents.splice(
        result.destination.index,
        0,
        _pageComponents.splice(result.source.index, 1)[0],
      );

      this.setState(
        {
          pageComponents: _pageComponents,
        },
        () => {
          this.props.onUpdateComponent(pageId, componentId, componentToUpdate);
        },
      );
    }
  };

  render() {
    let _page = this.props.pages[this.props.currentPageIndex];

    return (
      <Fragment>
        {_page !== undefined && (
          <div className="PageContent">
            {this.props.isLoading && (
              <div className="loading">
                <Loader loading={true} />
              </div>
            )}

            {!this.props.isLoading && (
              <Fragment>
                {this.props.isAdmin && this.props.pageComponents.length === 0 && (
                  <ComponentPlaceholder
                    title="Welcome to your new page!"
                    message="Customize your page with components"
                    actions={[
                      {
                        label: 'Add New Component',
                        iconClassName: 'far fa-plus',
                        action: () => this.toggleAddComponent(true),
                      },
                    ]}
                  />
                )}

                {this.props.isAdmin &&
                  this.props.pageComponents.length > 0 &&
                  this.props.createPageComponent && (
                    <button
                      onClick={() => {
                        this.toggleAddComponent(true);
                      }}
                      className="add-component"
                    >
                      <i className="far fa-plus-circle" />
                      <span>Add New Component</span>
                    </button>
                  )}

                <DragDropContext onDragEnd={this.handleComponentDrag}>
                  <Droppable droppableId="components">
                    {(provided, snapshot) => (
                      <div
                        className={
                          snapshot.isDraggingOver ? 'components-wrap dragging' : 'components-wrap'
                        }
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {this.props.pageComponents.map((item, index) => {
                          return (
                            <Draggable
                              isDragDisabled={
                                !this.props.isAdmin || this.props.owner.ownerType === 'gig'
                              }
                              key={index}
                              draggableId={'component-' + index}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className={snapshot.isDragging ? 'dragging' : ''}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <PageComponent
                                    page={this.props.pages[this.props.currentPageIndex]}
                                    dragProps={provided.dragHandleProps}
                                    {...this.props.routeProps}
                                    owner={this.props.owner}
                                    component={item}
                                    permissions={this.props.permissions}
                                    currentPageIndex={this.props.currentPageIndex}
                                    onDelete={() => {
                                      this.props.onDeleteComponent?.(item.id);
                                    }}
                                    onComponentUpdate={this.props.onComponentUpdate}
                                    campaign={this.props.campaign}
                                    isUserStillLoggedIn={this.props.isUserStillLoggedIn}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Fragment>
            )}
          </div>
        )}

        <ComponentLibrary
          show={this.state.showAddComponent}
          onClose={() => this.toggleAddComponent(false)}
          owner={this.props.owner}
          onCancel={this.onCancelAdd}
          onContinue={this.onAddComponent}
        />
      </Fragment>
    );
  }

  toggleAddComponent(value: boolean) {
    this.setState({
      showAddComponent: value,
    });
  }

  onCancelAdd = () => {
    this.toggleAddComponent(false);
  };

  onAddComponent = (type: string) => {
    this.props.createPageComponent?.(type);
    this.toggleAddComponent(false);
  };
}

export default withTranslation('translations')(PageContent);
