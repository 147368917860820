import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import Portrait from '../../../components/Portrait/Portrait';
import { IOwnerObject } from '../../../interfaces';
import './SideBar.scss';

export interface ISidebarTab {
  id: string;
  title: string;
  icon: string;
  notificationCount?: number;
}

interface ICollapsible {
  isCollapsed: boolean;
  onCollapseClick: () => void;
}

export interface ISidebarTabConfig {
  tabs: ISidebarTab[];
  activeTabId: string;
  onTabClick: (activeTabId: string) => void;
}

interface IProps {
  owner: IOwnerObject;
  tabConfig: ISidebarTabConfig;
  /** Pass if you want sidebar to be collapsible */
  collapsible?: ICollapsible;
}

const SideBar: FC<IProps> = (props: IProps) => {
  const { owner, collapsible } = props;
  const { tabs, activeTabId, onTabClick } = props.tabConfig;

  const renderMenuTabs = (tabs: ISidebarTab[]) => {
    return tabs.map(({ id, icon, title, notificationCount }) => {
      const getActiveClass = activeTabId === id ? 'active' : '';

      return (
        <li
          key={id}
          className={`tab ${getActiveClass} ${id}`}
          onClick={() => onTabClick(id)}
        >
          <div className="icon-wrap">
            <img
              src={icon}
              className="icon"
            />
          </div>
          <div className="text-wrap">
            <span className="title">{title}</span>
            {notificationCount && <div className="notification-dot">{notificationCount}</div>}
          </div>
        </li>
      );
    });
  };

  return (
    <div className={`SideBar ${collapsible?.isCollapsed ? 'collapsed' : ''}`}>
      <div className="owner-container">
        <Portrait
          source={owner.ownerProfileImageUrl}
          size={30}
        />
        <div className="description-container">
          <span className="title">{owner.ownerTitle}</span>
          <Link
            className="link"
            to={`${owner.ownerType}/${owner.ownerHandle}`}
          >
            View Profile
          </Link>
        </div>
      </div>
      <ul className="tabs">{renderMenuTabs(tabs)}</ul>
      {collapsible && (
        <i
          onClick={collapsible.onCollapseClick}
          className={`fa fa-chevron-double-left collapse-btn ${collapsible.isCollapsed ? 'collapsed' : ''}`}
        />
      )}
    </div>
  );
};

export default SideBar;
