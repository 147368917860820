import axios from 'axios';
import {
  IAddress,
  IAddressBase,
  IGigSummary,
  IGigCategory,
  IPage,
  IShift,
  IGig,
  IGigUpdateParams,
  IGigCreateParams,
  IApplication,
  IVolunteer,
  IGigVolunteerSummary,
} from '@gigit/interfaces';
import { routes, swapRouteParams } from '../helpers';

export namespace gigRequestActions {
  export async function getShifts(payload: { gigId: string }): Promise<IShift[]> {
    const response = await axios.get<IShift[]>(
      swapRouteParams(routes.GET_SHIFTS, { gigId: payload.gigId }),
      { params: { sort: 'start_date' } },
    );

    return response.data;
  }
  export async function getGroupGigs(payload: { groupId: string }): Promise<IGigSummary[]> {
    const response = await axios.get<IGigSummary[]>(
      swapRouteParams(routes.GET_GROUP_GIGS, { groupId: payload.groupId }),
    );

    return response.data;
  }

  export async function getCategories(): Promise<IGigCategory[]> {
    const response = await axios.get(routes.GET_GIG_CATEGORIES);
    return response.data;
  }

  export async function getLocations(gigId: string): Promise<IAddress[]> {
    const response = await axios.get(swapRouteParams(routes.GET_GIG_LOCATIONS, { gigId }));
    return response.data;
  }

  export async function createGigForGroup(
    groupId: string,
    gig: IGigCreateParams,
  ): Promise<IGigSummary> {
    const response = await axios.post(swapRouteParams(routes.CREATE_GROUP_GIG, { groupId }), gig);
    return response.data;
  }

  export async function createGigForHub(
    hubId: string,
    gig: IGigCreateParams,
  ): Promise<IGigSummary> {
    const response = await axios.post(swapRouteParams(routes.CREATE_HUB_GIG, { hubId }), gig);
    return response.data;
  }

  export async function createGigPages(gigId: string, page: IPage): Promise<IPage[]> {
    const response = await axios.post(swapRouteParams(routes.CREATE_GIG_PAGE, { gigId }), page);
    return response.data;
  }

  export async function createGigLocation(
    gigId: string,
    location: IAddressBase,
  ): Promise<IAddress> {
    const response = await axios.post(
      swapRouteParams(routes.CREATE_GIG_LOCATION, { gigId }),
      location,
    );
    return response.data;
  }

  export async function getGigByHandle(gigHandle: string): Promise<IGigSummary> {
    const response = await axios.get<IGigSummary>(
      swapRouteParams(routes.GET_GIG_BY_HANDLE, { handleOrId: gigHandle }),
    );
    return response.data;
  }

  export async function getVolunteersExport(gigId: string): Promise<string> {
    const response = await axios.get(swapRouteParams(routes.GET_GIG_VOLUNTEERS_EXPORT, { gigId }), {
      responseType: 'blob',
    });
    return response.data;
  }

  export async function getVolunteersList(gigId: string, query?: URLSearchParams) {
    const response = await axios.get<IVolunteer[]>(
      swapRouteParams(routes.GET_GIG_VOLUNTEERS_VOLUNTEERING, { gig_id: gigId }),
      { params: query },
    );
    return response.data;
  }

  export async function getGigVolunteer(gigId: string, userId: string) {
    const response = await axios.get<IGigVolunteerSummary>(
      swapRouteParams(routes.GET_GIG_VOLUNTEER, { gigId, userId }),
    );
    return response.data;
  }

  export async function changeGigStatus(gigId: string, status: string): Promise<IGigSummary> {
    const response = await axios.post(swapRouteParams(routes.CHANGE_GIG_STATUS, { gigId }), {
      status: status,
    });
    return response.data;
  }

  export async function volunteerMatchApplication(gigId: string, payload: any) {
    const response = await axios.post(
      swapRouteParams(routes.VOLUNTEER_FOR_GIG_VM, { gigId }),
      payload,
    );
    return response.data;
  }

  export async function updateGig(gigId: string, payload: IGigUpdateParams) {
    const response = await axios.put<IGig>(swapRouteParams(routes.UPDATE_GIG, { gigId }), payload);
    return response.data;
  }

  export async function setVolunteerFlag(
    gigId: string,
    userId: string,
    flag: string,
    flag_value: boolean,
  ) {
    const response = await axios.post<IGig>(
      swapRouteParams(routes.SET_VOLUNTEER_FLAG, { gigId, userId }),
      { flag_value, flag },
    );
    return response.data;
  }
}
