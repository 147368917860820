import { IStoryComponentProps } from '../../Types/types';
import { StoryImage } from '../StoryImage/StoryImage';
import { StoryVideo } from '../StoryVideo/StoryVideo';
import React from 'react';
import { Progress } from '../Progress/Progress';
import { IStoryForegroundContent, IStoryMetricsData } from '@gigit/interfaces';

import './Story.scss';
import { currencyMetrics, metricLabels } from '../../../../routes/CreateStory/CreateStory';
import { formatCurrency } from '../../../../helpers';

export function Story(props: IStoryComponentProps) {
  function getForegroundElement(
    type: string,
    content: IStoryForegroundContent & IStoryMetricsData,
    index: number,
  ) {
    var text = new DOMParser().parseFromString(content.text || '', 'text/html');
    switch (type) {
      case 'text': {
        return (
          <div
            key={index}
            className="textbox"
            style={{
              position: 'absolute',
              zIndex: '11',
              color: content.style.foreground_color,
              top: `${content.style.position_y}%`,
              left: `${content.style.position_x}%`,
            }}
            ref={(ref) =>
              ref?.appendChild(
                text.body.firstChild || document.createTextNode('Error rendering story'),
              )
            }
          ></div>
        );
      }
      case 'metric': {
        const metricData = content?.metrics_data;
        const formattedMetricValue =
          metricData?.metric && currencyMetrics.includes(metricData.metric)
            ? formatCurrency(metricData.value, metricData.unit, 'cad')
            : metricData?.value;
        const metricLabel = metricLabels[metricData?.metric as keyof metricLabels];

        return (
          <div
            key={index}
            className="metric-box"
            style={{
              position: 'absolute',
              zIndex: '11',
              color: content.style.foreground_color,
              top: `${content.style.position_y}%`,
              left: `${content.style.position_x}%`,
            }}
          >
            <div className="text-inner">
              <p className="metric-value">{formattedMetricValue}</p>
              <p className="metric-label">{metricLabel}</p>
            </div>
          </div>
        );
      }
      case 'cta': {
        return (
          <div
            key={index}
            className="call-to-action-container"
            style={{
              position: 'absolute',
              zIndex: '11',
              top: `${content.style.position_y}%`,
              left: `${content.style.position_x}%`,
            }}
          >
            <button
              className={`cta-btn`}
              notranslate="yes"
              style={{ backgroundColor: content?.style?.foreground_color }}
            >
              {content?.text || 'Do it! Do The thing.'}
            </button>
          </div>
        );
      }
      default:
        break;
    }
  }

  function renderStory() {
    if (props.story.background_type === 'image') {
      return (
        <>
          <StoryImage {...props} />;
          {props.story.foreground_contents?.map((content, index) => {
            return getForegroundElement(content.foreground_type, content, index);
          })}
        </>
      );
    }
    if (props.story.background_type === 'video') {
      return (
        <>
          <StoryVideo
            {...props}
            showSoundControls={true}
          />
          {props.story.foreground_contents?.map((content, index) => {
            return getForegroundElement(content.foreground_type, content, index);
          })}
        </>
      );
    }
    if (props.story.background_type === 'color') {
      return (
        <div
          className="story-color"
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: props.story.background_color,
            borderRadius: '10px',
          }}
        >
          {props.story.foreground_contents?.map((content, index) => {
            return getForegroundElement(content.foreground_type, content, index);
          })}
        </div>
      );
    }
    return null;
  }

  return (
    <div className={`StoryContainer`}>
      <Progress
        activeStoryIndex={props?.story?.index || 0}
        isPaused={props.isPaused}
      />
      {renderStory()}
      <div className="panel-actions">
        <div
          onClick={props.handlePrevClick}
          className="panel-left"
        ></div>
        <div
          onClick={props.handleNextClick}
          className="panel-right"
        ></div>
      </div>
    </div>
  );
}
