import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  handleInputChange,
  formatAddressLine,
  toastError,
  toastSuccess,
  uploadMedias,
  swapRouteParams,
  routes,
  typeHelpers,
} from '../../helpers';
import { IAppState } from '../../store';
import Button from '../Button/Button';
import { getGroupStats } from '../../actions/group';
import { getEventStats } from '../../actions/event';
import TextField from '../TextField/TextField';
import ImageUpload from '../ImageUpload/ImageUpload';
import Dropdown, { IOptions } from '../Dropdown/Dropdown';
import QuillTextEditor from '../QuillTextEditor/QuillTextEditor';
import './AuctionItemForm.scss';
import moment, { Moment } from 'moment';
import { IAuctionState } from '../../reducers/auction';
import { IEventState } from '../../reducers/event';
import { IGroupState } from '../../reducers/group';
import DatePicker from '../../components/DatePicker/DatePicker';
import { createToast } from '../../actions/toaster';
import { IUpdateAuctionItemParams } from '../../actions/auction';
import { findStoreCategories, StoreItemOwnerType } from '../../actions/store';
import { IStoreState } from '../../reducers/store';
import { IToast, StatisticType } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import { Constants } from '@gigit/constants';
import { IAddress, IAuctionItem, IAuctionItemSummary } from '@gigit/interfaces';
import axios from 'axios';
import errorHelpers from '../../helpers/errorHelpers';
import { IOwnerObject } from '../../interfaces';
import { uiConstants } from '../../constants';
import { hubRequestActions } from '../../requestActions';
import { IHubState } from '../../reducers/hub';

interface IPropsFromDispatch {
  createToast(toast: IToast): void;
  getGroupStats(eventId: string, statisticType: StatisticType): void;
  getEventStats(eventId: string, statisticType: StatisticType): void;
  findStoreCategories(
    ownerType: StoreItemOwnerType,
    ownerId: string,
    _search?: string | undefined,
  ): void;
}

interface IPropsFromState {
  groupState: IGroupState;
  eventState: IEventState;
  hubState: IHubState;
  auctionState: IAuctionState;
  storeState: IStoreState;
}

interface IPassedProps {
  owner: IOwnerObject;
  auctionItems?: IAuctionItemSummary[];
  editItem?: number;
  copyItem?: number;
  onClose: () => void;
  onAuctionSave: (id: string) => void;
}

interface IState {
  editItem: number;
  itemName: string;
  category: string | null;
  description: string;
  retailPrice: string;
  buyNowPrice: string;
  minimumStartBid: string;
  minimumIncrement: string;
  shippingPrice: string;
  startTime: Moment;
  endTime: Moment;
  shippingAvailable: any;
  pickupAvailable: any;
  itemImages: string[];
  shippingType: string;
  taxReceipt: string;
  taxAmount: string;
  bidsRequirePaymentMethod: boolean;

  selectedLocations: any[];
  location: string;
  locations: IOptions[];
  locationsLoading: boolean;
  isLoading: boolean;
  modules: any;
  formats: any;
  errors: { [index: string]: string };
  isValid: boolean;
}

type IProps = IPassedProps & IPropsFromState & IPropsFromDispatch;

class AuctionItemForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      editItem: -1,
      itemName: '',
      category: '',
      description: '',
      retailPrice: '',
      buyNowPrice: '',
      minimumStartBid: '',
      minimumIncrement: '',
      shippingPrice: '',
      startTime: moment(),
      endTime: moment(),
      shippingAvailable: false,
      pickupAvailable: false,
      selectedLocations: [],
      taxReceipt: 'fixed',
      location: '',
      taxAmount: '0',
      bidsRequirePaymentMethod: false,

      locationsLoading: true,
      locations: [],
      isLoading: false,
      itemImages: [],
      shippingType: 'Free',
      modules: {
        toolbar: [
          ['bold', 'italic'],
          [{ indent: '-1' }, { indent: '+1' }],
          ['link', 'clean'],
        ],
        clipboard: {
          matchVisual: false,
        },
      },
      formats: [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
      ],
      errors: {},
      isValid: false,
    };

    this.createAuctionItem = this.createAuctionItem.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.onMediaItemSelect = this.onMediaItemSelect.bind(this);
    this.clearNewAuctionItem = this.clearNewAuctionItem.bind(this);
  }

  /** Depending on the owner, return the list of available locations. */
  getLocations() {
    switch (this.props.owner.ownerType) {
      case uiConstants.ownerType.group:
        return this.props.groupState.groupLocations;
      case uiConstants.ownerType.event:
        return this.props.eventState.locations;
      case uiConstants.ownerType.hub:
        let locations: IAddress[] = [];
        if (this.props.hubState.current?.groups) {
          this.props.hubState.current.groups.forEach((g) => {
            if (g?.locations) {
              locations = [...locations, ...g.locations];
            }
          });
        }

        return locations;
      default:
        typeHelpers.assertNotReachable();
    }
  }

  componentDidMount() {
    let _locations: IOptions[] = [];

    _locations.push({
      value: '',
      label: 'Select Location',
    });

    // We always pull locations from Group for both Group/Event Auction items.
    const ownerLocations = this.getLocations();

    for (let l in ownerLocations) {
      let _location = ownerLocations[l];

      _locations.push({
        value: _location.id!,
        label: _location.title + ' (' + formatAddressLine(_location) + ')',
      });
    }

    this.setState(
      {
        locations: _locations,
      },
      () => {
        this.setState({
          locationsLoading: false,
        });
      },
    );

    if (this.props.copyItem != null && this.props.copyItem !== -1) {
      this.copy(this.props.copyItem);
    }

    if (this.props.editItem !== -1) {
      this.setState({
        editItem: this.props.editItem != null ? this.props.editItem : -1,
      });
    }

    this.validateInputs();
    this.props.findStoreCategories(
      this.props.owner.ownerType as StoreItemOwnerType,
      this.props.owner.ownerId,
    );
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevState.editItem === -1 &&
      this.state.editItem !== -1 &&
      this.getAuctionItems()[this.state.editItem]
    ) {
      let _auctionItem = this.getAuctionItems()[this.state.editItem];
      let _media = [];

      for (let m in _auctionItem.media) {
        _media.push(_auctionItem.media[m].url);
      }
      let js = moment(_auctionItem.start_date);
      this.setState({
        itemName: _auctionItem.name || '',
        category: _auctionItem.category ?? '',
        description: _auctionItem.description ?? '',
        retailPrice: String(_auctionItem.retail_price ?? 0),
        buyNowPrice: String(_auctionItem.buy_now_price ?? 0),
        minimumStartBid: String(_auctionItem.minimum_start_bid ?? 0),
        minimumIncrement: String(_auctionItem.minimum_bid_increment ?? 0),
        shippingType: _auctionItem.shipping_price_type ?? Constants.shipping_price_type.Free,
        shippingPrice: String(_auctionItem.shipping_price),
        startTime: moment(_auctionItem.start_date),
        endTime: moment(_auctionItem.end_date),
        bidsRequirePaymentMethod: _auctionItem.bids_require_payment_method ?? false,
        shippingAvailable: _auctionItem.is_shipping_available,
        pickupAvailable: _auctionItem.is_pickup_available,
        selectedLocations: _auctionItem.pickup_locations || [],
        location: '',
        itemImages: _media,
        // GIG-993: Disable donation part for auction items for now. need to research better how to do this.
        //taxReceipt: _auctionItem.donation_part ? _auctionItem.donation_part.amount_type : "fixed",
        //taxAmount:_auctionItem.donation_part ?  _auctionItem.donation_part.amount.toString() : "0"
      });
    }

    if (
      this.state.minimumStartBid !== prevState.minimumStartBid ||
      this.state.taxReceipt !== prevState.taxReceipt ||
      this.state.taxAmount !== prevState.taxAmount
    ) {
      this.validateInputs();
    }
  }

  copy(copyItem: number) {
    this.clearNewAuctionItem();
    const item = this.getAuctionItems()[copyItem];
    this.setState({
      startTime: moment(item.start_date),
      endTime: moment(item.end_date),
      shippingAvailable: item.is_shipping_available,
      pickupAvailable: item.is_pickup_available,
      shippingPrice: String(item.shipping_price),
    });
  }

  /** Validates all inputs and checks for errors. */
  validateInputs() {
    const newErrors: { [index: string]: string } = {};

    if (parseFloat(this.state.taxAmount) < 0) {
      newErrors['taxAmount'] = 'Donation amount cannot be a negative number';
    } else if (this.state.taxReceipt === 'fixed') {
      if (parseFloat(this.state.taxAmount) > parseFloat(this.state.minimumStartBid)) {
        newErrors['taxAmount'] = 'Fixed donation part must not be greater than minimum start bid';
      }
    } else if (this.state.taxReceipt === 'percentage') {
      if (parseFloat(this.state.taxAmount) > 100) {
        newErrors['taxAmount'] = 'Percentage donation part must not be greater than 100%';
      }
    }

    this.setState({
      errors: newErrors,
      isValid: Object.keys(newErrors).length === 0,
    });
  }

  removeImage(index: number) {
    let _images = [...this.state.itemImages];
    _images.splice(index, 1);

    this.setState({
      itemImages: _images,
    });
  }

  saveAuctionItem() {
    if (this.state.startTime.isAfter(this.state.endTime)) {
      const toast = toastError(
        localizeHelpers.translate('Start date cannot be greater than end date'),
        'Auction Item',
      );
      this.props.createToast(toast);

      return;
    }

    if (!this.state.shippingAvailable && !this.state.pickupAvailable) {
      const toast = toastError(
        localizeHelpers.translate('Auction items require either shipping or pickup availability.'),
        'Auction Item',
      );
      this.props.createToast(toast);

      return false;
    }

    if (this.state.pickupAvailable) {
      if (this.state.selectedLocations.length <= 0) {
        const toast = toastError(
          localizeHelpers.translate("Please select a pickup location and click 'Add Location'."),
          'Auction Item',
        );
        this.props.createToast(toast);

        return false;
      }
    }

    let _payload: IUpdateAuctionItemParams = {
      name: this.state.itemName,
      category: this.state.category,
      description: this.state.description,
      retail_price: this.state.retailPrice as any as number,
      buy_now_price: this.state.buyNowPrice as any as number,
      minimum_start_bid: this.state.minimumStartBid as any as number,
      minimum_bid_increment: this.state.minimumIncrement as any as number,
      start_date: moment(this.state.startTime).utc().format(),
      end_date: moment(this.state.endTime).utc().format(),
      is_shipping_available: this.state.shippingAvailable,
      is_pickup_available: this.state.pickupAvailable,
      donation_part: {
        amount_type: this.state.taxReceipt,
        amount: parseFloat(this.state.taxAmount) || 0,
      },
      bids_require_payment_method: this.state.bidsRequirePaymentMethod,
    };

    if (this.state.shippingAvailable) {
      _payload.shipping_price_type = this.state.shippingType;
      _payload.shipping_price =
        this.state.shippingType === 'Free' || this.state.shippingType === 'TBD'
          ? 0
          : (this.state.shippingPrice as any as number);
    } else {
      _payload.shipping_price_type = 'TBD';
      _payload.shipping_price = 0;
    }

    if (this.state.pickupAvailable) {
      _payload.pickup_locations = this.state.selectedLocations as any;
    }

    if (this.state.editItem === -1) {
      this.createAuctionItem(_payload, this.state.itemImages);
    } else {
      if (this.getAuctionItems()[this.state.editItem]) {
        let _auctionItem = this.getAuctionItems()[this.state.editItem];
        this.updateAuctionItem(_auctionItem.id!, _payload, this.state.itemImages);
      }
    }
  }

  getAuctionItems() {
    return this.props.auctionItems ?? [];
  }

  clearNewAuctionItem() {
    this.setState({
      editItem: -1,
      itemName: '',
      category: '',
      description: '',
      retailPrice: '',
      buyNowPrice: '',
      minimumStartBid: '',
      minimumIncrement: '',
      shippingPrice: '',
      startTime: moment(),
      endTime: moment(),
      shippingAvailable: false,
      pickupAvailable: false,
      selectedLocations: [],
      location: '',
      itemImages: [],
    });
  }

  async createAuctionItem(payload: IUpdateAuctionItemParams, medias?: string[]) {
    try {
      this.setState({
        isLoading: true,
      });

      if (medias && medias.length > 0) {
        payload.media = await uploadMedias(medias);
      }

      if (this.props.owner.ownerType === Constants.object_type.hub) {
        hubRequestActions
          .createAuctionItem(this.props.owner.ownerId, payload)
          .then((res) => {
            const toast = toastSuccess(
              localizeHelpers.translate('Auction item created successfully.'),
              'Auction Item',
            );
            this.props.createToast(toast);

            this.handleOnClose();
            this.props.onAuctionSave(res.id!);
          })
          .catch((error) => {
            const toast = toastError(localizeHelpers.translate(error), 'Create Auction Item');
            this.props.createToast(toast);
          });
      } else {
        const route =
          this.props.owner.ownerType === 'group'
            ? routes.CREATE_GROUP_AUCTION_ITEM
            : routes.CREATE_EVENT_AUCTION_ITEM;
        const idField = this.props.owner.ownerType === 'group' ? 'groupId' : 'eventId';

        const response = await axios.post<IAuctionItem>(
          swapRouteParams(route, { [idField]: this.props.owner.ownerId }),
          payload,
        );

        const toast = toastSuccess(
          localizeHelpers.translate('Auction item created successfully.'),
          'Auction Item',
        );
        this.props.createToast(toast);

        this.handleOnClose();

        this.props.owner.ownerType === 'group'
          ? this.props.getGroupStats(this.props.owner.ownerId, StatisticType.AUCTION)
          : this.props.getEventStats(this.props.owner.ownerId, StatisticType.AUCTION);

        this.props.onAuctionSave(response.data.id!);
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      this.props.createToast(toastError(errorObj.translatedMessage, 'Create Auction Item'));
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async updateAuctionItem(itemId: string, payload: IUpdateAuctionItemParams, medias?: string[]) {
    try {
      this.setState({
        isLoading: true,
      });

      if (medias) {
        if (medias.length > 0) {
          payload.media = await uploadMedias(medias);
        } else {
          payload.media = [];
        }
      }

      if (this.props.owner.ownerType === Constants.object_type.hub) {
        let _auctionItem = this.getAuctionItems()[this.state.editItem];
        typeHelpers.assertNotNullOrUndefined(_auctionItem.id, 'Expected Auction Item');

        hubRequestActions
          .updateAuctionItem(this.props.owner.ownerId, _auctionItem.id, payload)
          .then((res) => {
            const toast = toastSuccess(
              localizeHelpers.translate('Auction item updated successfully.'),
              'Auction Item',
            );
            this.props.createToast(toast);

            this.handleOnClose();
            this.props.onAuctionSave(itemId);
          })
          .catch((error) => {
            const toast = toastError(localizeHelpers.translate(error), 'Update Auction Item');
            this.props.createToast(toast);
          });
      } else {
        const route =
          this.props.owner.ownerType === 'group'
            ? routes.UPDATE_GROUP_AUCTION_ITEM
            : routes.UPDATE_EVENT_AUCTION_ITEM;
        const idField = this.props.owner.ownerType === 'group' ? 'groupId' : 'eventId';

        await axios.put(
          swapRouteParams(route, { [idField]: this.props.owner.ownerId, id: itemId }),
          payload,
        );

        const toast = toastSuccess(
          localizeHelpers.translate('Auction item updated successfully.'),
          'Auction Item',
        );
        this.props.createToast(toast);

        this.handleOnClose();

        this.props.owner.ownerType === 'group'
          ? this.props.getGroupStats(this.props.owner.ownerId, StatisticType.AUCTION)
          : this.props.getEventStats(this.props.owner.ownerId, StatisticType.AUCTION);

        this.props.onAuctionSave(itemId);
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      this.props.createToast(toastError(errorObj.translatedMessage, 'Update Auction Item'));
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  addLocation() {
    if (this.state.location !== '') {
      let _selected = [...this.state.selectedLocations];

      const ownerLocations = this.getLocations();

      if (_selected.find((i) => i.id === this.state.location)) {
        //if location already added
        return;
      }

      for (let l in ownerLocations) {
        if (ownerLocations[l].id === this.state.location) {
          _selected.push(ownerLocations[l]);
          break;
        }
      }

      this.setState({
        selectedLocations: _selected,
      });
    }
  }

  removeLocation(index: number) {
    let _selected = [...this.state.selectedLocations];

    _selected.splice(index, 1);

    this.setState({
      selectedLocations: _selected,
    });
  }

  onMediaItemSelect(file: any) {
    let _images = [...this.state.itemImages];
    _images.push(file.src);

    this.setState({
      itemImages: _images,
    });
  }

  handleOnClose() {
    this.props.onClose();
    this.setState({
      editItem: -1,
    });
  }

  getCategoryOptions() {
    const categoryOptions = this.props.storeState.storeCategories.map((cat) => ({
      label: cat.name,
      value: cat.name,
    }));

    categoryOptions.unshift({ label: '', value: '' });

    return categoryOptions;
  }

  render() {
    const locationsLink = `/${this.props.owner.ownerType}/${this.props.owner.ownerHandle}/admin?t=locations`;

    return (
      <div className="AuctionItemForm">
        <form
          className="add-auction-item"
          onSubmit={(e) => {
            e.preventDefault();
            this.saveAuctionItem();
          }}
        >
          <div className="sub-title">Details</div>
          <div className="row">
            <TextField
              required={true}
              label="Item Name"
              value={this.state.itemName}
              name="itemName"
              type="text"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
          </div>
          <div className="row">
            <Dropdown
              notranslate="yes"
              name="category"
              shouldSort={true}
              label="Category"
              value={this.state.category}
              options={this.getCategoryOptions()}
              onChange={(e) => this.setState({ category: e.target.value })}
            />
          </div>
          <div className="row quill-wrap">
            <label>Description</label>
            <QuillTextEditor
              value={this.state.description}
              preserveWhitespace={true}
              theme="snow"
              modules={this.state.modules}
              formats={this.state.formats}
              onChange={(content: any) => {
                this.setState({ description: content });
              }}
            />
          </div>
          {/* GIG-993: Disable donation part for auction items for now. need to research better how to do this. */}
          {/* <div className="row taxes">
                            <label>{t("Donation Amount or Percent")}</label>
                            <div className="tax-eligible">
                                <Checkbox name="taxReceiptDollar" onChange={(e) => { handleInputChange(e, this); this.setState({ taxReceipt: "fixed" }); }} checked={this.state.taxReceipt === "fixed"} value={"fixed"} label={t("Dollar")} />
                                <Checkbox name="taxReceiptPercent" onChange={(e) => { handleInputChange(e, this); this.setState({ taxReceipt: "percentage" }); }} checked={this.state.taxReceipt === "percentage"} value={"percent"} label={t("Percent")} />
                                <TextField error={this.state.errors['taxAmount']} type="number" min="0" onChange={(e) => { handleInputChange(e, this); }} name="taxAmount" value={this.state.taxAmount} placeholder={this.state.taxReceipt === 'percentage' ? t("% Amount") : t("$ Amount")} />
                            </div>
                            <div className="warning-msg">{t("Kambeo will automatically issue a tax-deductable donation receipt upon purchase where applicable.")}</div>
                        </div> */}
          <div className="row dbl">
            <TextField
              required={true}
              label="Retail Price"
              value={this.state.retailPrice}
              name="retailPrice"
              type="number"
              min="0"
              step="any"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
            <TextField
              label="Buy Now Price"
              value={this.state.buyNowPrice}
              name="buyNowPrice"
              type="number"
              min="0"
              step="any"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
          </div>
          <div className="row dbl">
            <TextField
              required={true}
              label="Minimum Start Bid"
              value={this.state.minimumStartBid}
              name="minimumStartBid"
              type="number"
              min="0"
              step="any"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
            <TextField
              required={true}
              label="Minimum Increment"
              value={this.state.minimumIncrement}
              name="minimumIncrement"
              type="number"
              min="0"
              step="any"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
          </div>
          <div className="row">
            <p>Does this Item require payment method to place a bid?</p>
            <i
              onClick={() => {
                this.setState({ bidsRequirePaymentMethod: !this.state.bidsRequirePaymentMethod });
              }}
              className={
                this.state.bidsRequirePaymentMethod ? 'fad fa-toggle-on' : 'fad fa-toggle-off'
              }
            />
          </div>
          <div className="row">
            <DatePicker
              label="Start Time"
              date={this.state.startTime}
              showTime={true}
              name="startTime"
              error={this.state.startTime.isAfter(this.state.endTime)}
              onChange={(d: any) => {
                this.setState({ startTime: moment(d).second(0).millisecond(0) });
              }}
            />
          </div>
          <div className="row">
            <DatePicker
              label="End Time"
              date={this.state.endTime}
              showTime={true}
              name="endTime"
              error={this.state.startTime.isAfter(this.state.endTime)}
              onChange={(d: any) => {
                this.setState({ endTime: moment(d).second(0).millisecond(0) });
              }}
            />
          </div>
          <div className="sub-title">Shipping</div>
          <div className="row">
            <p>Is shipping available for this item?</p>
            <i
              onClick={() => {
                this.setState({ shippingAvailable: !this.state.shippingAvailable });
              }}
              className={this.state.shippingAvailable ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}
            />
          </div>
          {this.state.shippingAvailable && (
            <div className="shippingAvailable no-select">
              <div className="row">
                <Dropdown
                  label="Shipping Price"
                  name="shippingType"
                  value={this.state.shippingType}
                  options={[
                    { label: 'Free', value: 'Free' },
                    { label: 'Dollar Amount', value: 'Amount' },
                    { label: 'To Be Determined', value: 'TBD' },
                  ]}
                  onChange={(e) => {
                    handleInputChange(e, this, false);
                  }}
                />
              </div>
              {this.state.shippingType === 'Amount' && (
                <div className="row">
                  <TextField
                    required={true}
                    label="Shipping Amount ($)"
                    value={this.state.shippingPrice}
                    name="shippingPrice"
                    type="number"
                    min="0"
                    step="any"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div className="sub-title">Pick-Up</div>
          <div className="row">
            <p>Is this item available for pick-up?</p>
            <i
              onClick={() => {
                this.setState({ pickupAvailable: !this.state.pickupAvailable });
              }}
              className={this.state.pickupAvailable ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}
            />
          </div>
          {this.state.pickupAvailable && (
            <div className="pickupAvailable no-select">
              <div className="row add-location">
                <Link to={locationsLink}>Manage Locations</Link>
                {!this.state.locationsLoading && (
                  <Dropdown
                    shouldSort={true}
                    label={'Pick-up Locations'}
                    name="location"
                    value={this.state.location}
                    options={this.state.locations}
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                    notranslate="yes"
                  />
                )}
                {this.state.selectedLocations.length === 0 && (
                  <div className="empty">You haven't selected any pick-up locations</div>
                )}
                {this.state.selectedLocations.length !== 0 && (
                  <ul className="selected-locations">
                    {this.state.selectedLocations.map((item, index) => {
                      return (
                        <li
                          key={index}
                          notranslate="yes"
                        >
                          {item.title}
                          <span>({formatAddressLine(item)})</span>
                          <i
                            onClick={() => {
                              this.removeLocation(index);
                            }}
                            className="fa fa-times"
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    this.addLocation();
                  }}
                  icon="fa fa-plus"
                  text="Add Location"
                />
              </div>
            </div>
          )}
          <div className="sub-title">Images</div>
          <div className="tip">Recommended minumum size: 500px by 300px</div>
          <div className="row image-upload">
            {this.state.itemImages && this.state.itemImages.length === 0 && (
              <p>You haven't added any images, yet.</p>
            )}
            <div className="images">
              {this.state.itemImages &&
                this.state.itemImages.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="image-thumb"
                    >
                      <img
                        alt={'Image ' + index}
                        src={item}
                      />
                      <i
                        onClick={() => {
                          this.removeImage(index);
                        }}
                        className="fa fa-times"
                      />
                    </div>
                  );
                })}
            </div>
            <ImageUpload onImgSelect={this.onMediaItemSelect} />
          </div>
          <div className="actions">
            <Button
              isDisabled={!this.state.isValid}
              loading={this.state.isLoading}
              type="submit"
              text={this.state.editItem === -1 ? 'Create' : 'Update'}
            />
            <Button
              onClick={() => {
                this.handleOnClose();
              }}
              text="Cancel"
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    auctionState: store.auctionState,
    storeState: store.storeState,
    hubState: store.hubState,
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  findStoreCategories,
  getGroupStats,
  createToast,
  getEventStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuctionItemForm);
