import React, { useEffect, useState } from 'react';
import { ForOrganizationCard } from './ForOrganizationCard/ForOrganizationCard';
import { ForOrganizationsCollapseItem } from './ForOrganizationsCollapseItem/ForOrganizationsCollapseItem';
import './ForOrganizations.scss';
import { UniversitiesAndColleges } from './ForOrganizationsChildComponentConstants/universitiesAndColleges';
import { NonProfits } from './ForOrganizationsChildComponentConstants/nonProfits';
import { Businesses } from './ForOrganizationsChildComponentConstants/businesses';
import { Link, RouteComponentProps } from 'react-router-dom';

export interface IImage {
  src: string;
  alt?: string;
}

export interface ICardComponentProps {
  keyValue?: number;
  image: IImage;
  link: string;
  linkNewTab?: boolean;
  title: string;
  iconNode?: React.ReactNode;
}

export interface ICollapseItem {
  keyValue?: number;
  title: string;
  description?: string;
  additionalLink?: {
    href: string;
    title: string;
    linkNewTab?: boolean;
  };
}

export interface IChildComponent {
  keyValue: string;
  menu_title: string;
  main_title: string;
  main_subtitle: string;
  main_image: IImage;
  main_image_mobile: IImage;
  cards: ICardComponentProps[];
  collapseItems: ICollapseItem[];
}

interface IProps extends RouteComponentProps<any> {}

export const ForOrganizations = (props: IProps) => {
  const [active, setActive] = useState<number>();
  const childComponents: IChildComponent[] = [UniversitiesAndColleges, NonProfits, Businesses];

  useEffect(() => {
    switch (props.match.params.section) {
      case 'schools':
        setActive(0);
        break;
      case 'non-profits':
        setActive(1);
        break;
      case 'businesses':
        setActive(2);
        break;
      default:
        setActive(0);
        break;
    }
  }, [props.match.params.section]);

  return (
    <>
      {active !== undefined && (
        <div className="ForOrganizations">
          <section className="menu-items">
            {childComponents.map((childComponent, index: number) => {
              return (
                <Link
                  to={`/for-organizations/${childComponent.keyValue}`}
                  key={index}
                  className={`item ${active === index ? 'active' : ''}`}
                >
                  {childComponent.menu_title}
                </Link>
              );
            })}
          </section>

          <section
            className="main-image-bg"
            style={{ backgroundImage: `url(${childComponents[active].main_image.src})` }}
          />
          <img
            src={`${childComponents[active].main_image_mobile.src}`}
            alt={
              childComponents[active].main_image_mobile?.alt ||
              `main image, ${childComponents[active].menu_title}`
            }
            className="main-image-img"
          />

          <div className="main-container">
            <section className="header-container">
              <h1>{childComponents[active].main_title}</h1>
              <h2>{childComponents[active].main_subtitle}</h2>

              <div className="submenu-items">
                <div className="item highlighted">
                  <Link to="/onboarding/group">
                    <span>Get my organization on Kambeo</span>
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to="/book-a-demo/"
                    target="_blank"
                  >
                    <span>Request a Demo</span>
                  </Link>
                </div>
              </div>
            </section>

            <section className="cards-container">
              {childComponents[active].cards.map((card, index: number) => {
                const icon = card?.iconNode ? card.iconNode : <i className="fas fa-arrow-right" />;

                return (
                  <ForOrganizationCard
                    key={index}
                    iconNode={icon}
                    keyValue={index}
                    image={card.image}
                    link={card.link}
                    title={card.title}
                  />
                );
              })}
            </section>
          </div>

          <section className="collapse-container">
            {childComponents[active].collapseItems.map((item, index: number) => {
              return (
                <ForOrganizationsCollapseItem
                  key={index}
                  keyValue={index}
                  title={item.title}
                  additionalLink={item.additionalLink}
                  description={item.description}
                />
              );
            })}
          </section>
        </div>
      )}
    </>
  );
};
