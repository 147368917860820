import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps, matchPath } from 'react-router-dom';
import { IAppState } from '../../store';
import { capitalizeString, combineClassNames, isMobileScreen } from '../../helpers';
import { updateLoggedOutLanguage, updateLocalizeLanguageFromState } from '../../actions/user';
import { ISettingsState } from '../../reducers/settings';
import { IUserState } from '../../reducers/user';
import IWantToMenu from './IWantToMenu/IWantToMenu';
import logoDark from '../../assets/logo-dark-bg.svg';
import homeIcon from '../../assets/footer/home.svg';
import searchIcon from '../../assets/footer/search.svg';
import pagesIcon from '../../assets/footer/pages.svg';
import cartIcon from '../../assets/footer/cart.svg';
import './Footer.scss';
import { ICartState } from '../../reducers/cart';
import { uiConstants } from '../../constants/uiConstants';
import { IFooterState } from '../../reducers/footer';
import { toggleFooterVisibility } from '../../actions/footer';
import { ReduxActionType } from '../../interfaces';
import ContextMenu from '../ContextMenu/ContextMenu';
import { localeConstants } from '../../constants';

interface IProps extends RouteComponentProps<any> {
  navigation: any;
  settingsState: ISettingsState;
  userState: IUserState;
  cartState: ICartState;
  footerState: IFooterState;
  toggleFooterVisibility: ReduxActionType<typeof toggleFooterVisibility>;
  updateLoggedOutLanguage: ReduxActionType<typeof updateLoggedOutLanguage>;
  updateLocalizeLanguageFromState: ReduxActionType<typeof updateLocalizeLanguageFromState>;
}

interface IState {
  resizeListener: any;
  width: any;
  mobileContext: string;
  mobileContextItems: Array<string>;
  toggleSetup: boolean;
  activeTab: string;
  activeSection: string;
  showLanguageContextMenu: boolean;
}

interface link {
  label: string;
  link: string;
}

const baseItems = ['home', 'search', 'setup'];
const mobileItemsLoggedOut = [...baseItems, 'login', 'cart'];
const mobileItemsLoggedIn = [...baseItems, ...['mypages', 'chat', 'cart']];
const footerLinks = {
  general: [
    { label: 'About Us', link: uiConstants.urls.kambeoAboutUs },
    { label: 'Pricing', link: uiConstants.urls.kambeoPricing },
    { label: 'Book a Demo', link: uiConstants.urls.kambeoBookADemo },
    { label: 'Resources', link: uiConstants.urls.kambeoResources },
    { label: 'Careers', link: uiConstants.urls.kambeoCareers },
  ],
  discover: [
    //TODO: Implement proper links from discover implementation
    { label: 'Volunteer', link: '/discover/volunteers' },
    // { label: "Work", link: ""},
    { label: 'Causes to Support', link: '/discover/groups' },
    // { label: "Community Hubs", link: ""},
    // { label: "Community Shops", link: ""},
    // NOTE: Temporary change until new landing page is created
    { label: 'Events & Fundraising', link: '/' },
  ],
  forOrganizations: [
    { label: 'Kambeo for Non-Profits', link: '/for-organizations/non-profits' },
    { label: 'Kambeo for Schools', link: '/for-organizations/schools' },
    { label: 'Kambeo for Business', link: '/for-organizations/businesses' },
  ],
  iWantTo: [
    { label: 'Post Volunteer Opportunity', link: '/onboarding/gig' },
    { label: 'Post Work Opportunity', link: '/onboarding/gig' },
    { label: 'Create Cause', link: '/onboarding/group' },
    { label: 'Create Event', link: '/onboarding/event' },
    { label: 'Make a Donation', link: '/setup/donations' },
  ],
  support: [
    { label: 'Help Centre', link: uiConstants.urls.kambeoHelpCentre },
    { label: 'Contact Us', link: uiConstants.urls.kambeoContactUs },
    { label: 'Technical Support ', link: uiConstants.urls.kambeoTechnicalSupport },
  ],
};

const footerEnabledRoutes = [
  { route: '/for-organizations/:section', exact: true },
  { route: '/discover', exact: false },
  { route: '/group/:handle', exact: true },
  { route: '/event/:handle', exact: true },
  { route: '/gig/:id', exact: true },
  { route: '/user/:username', exact: true },
];
const footerBreakpoint = 820;
class Footer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      resizeListener: null,
      width: window?.innerWidth,
      mobileContext: this.props.location.pathname,
      mobileContextItems: mobileItemsLoggedOut,
      toggleSetup: false,
      activeTab: '',
      activeSection: '',
      showLanguageContextMenu: false,
    };

    this.toggleSetup = this.toggleSetup.bind(this);
  }

  componentDidMount() {
    this.initResize();
    this.setMobileMenuItems();
    this.toggleFooterForRoutes();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.onRouteChange();
    }

    if (prevProps.userState.isLoggedIn !== this.props.userState.isLoggedIn) {
      this.setMobileMenuItems();
    }
  }

  onRouteChange = () => {
    this.setState({
      mobileContext: this.props.location.pathname,
    });

    this.toggleFooterForRoutes();

    if (this.state.toggleSetup && !this.props.location.pathname.includes('setup')) {
      this.setState({
        toggleSetup: false,
      });
    }
  };
  /**
   * Matches current pathname using provided params
   * @param route Route to match against current pathname
   * @param matchExact Match exact route provided if  true, or all sub routes if false
   * @returns Bool - if the current route matches provided route
   */
  doesCurrentLocationMatchRoute = (route: string, matchExact: boolean) => {
    return (
      matchPath(this.props.location.pathname, {
        path: route,
        exact: matchExact,
        strict: false,
      }) != null
    );
  };

  //Toggles footer for specific routes
  toggleFooterForRoutes = () => {
    const showFooter =
      footerEnabledRoutes.find(({ route, exact }) =>
        this.doesCurrentLocationMatchRoute(route, exact),
      ) != null;

    this.props.toggleFooterVisibility(showFooter);
  };

  //might be a good thing to have a listener for app wide, more research needed
  initResize() {
    let widthListener = (e: any) => {
      this.setState({
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', widthListener);
  }

  toggleSetup(val: boolean) {
    this.setState({
      toggleSetup: val,
    });
  }

  setMobileMenuItems() {
    if (this.props.userState.isLoggedIn) {
      this.setState({
        mobileContextItems: mobileItemsLoggedIn,
      });
    } else {
      this.setState({
        mobileContextItems: mobileItemsLoggedOut,
      });
    }
  }

  isActiveTab(val: string, className?: string): string {
    if (val === this.state.activeTab) {
      return combineClassNames(className, 'active') || '';
    } else {
      return className || '';
    }
  }

  setActiveSection = (section: string) => {
    this.setState({
      activeSection: section === this.state.activeSection ? '' : section,
    });
  };

  getSectionActiveClassName = (section: string) => {
    return section === this.state.activeSection ? 'active' : '';
  };

  renderMobileActionBar = () => {
    return (
      <div
        className={
          this.state.toggleSetup ? 'mobile-footer-wrapper' : 'mobile-footer-wrapper sticky-footer'
        }
      >
        {this.state.toggleSetup && (
          <IWantToMenu
            showMenu={this.state.toggleSetup}
            toggleShowMenu={this.toggleSetup}
          />
        )}
        <div className="mobile-footer">
          <ul>
            {this.state.mobileContextItems.map((navItem: string, idx) => {
              switch (navItem) {
                case 'home': {
                  return (
                    <li
                      key={idx}
                      onClick={() => {
                        this.props.history.push('/');
                        this.setState({ activeTab: 'home' });
                      }}
                    >
                      <div className={this.isActiveTab('home')}>
                        <img src={homeIcon} />
                      </div>
                    </li>
                  );
                }
                case 'search': {
                  return (
                    <li
                      key={idx}
                      onClick={() => {
                        this.props.history.push('/search');
                        this.setState({ activeTab: 'search' });
                      }}
                    >
                      <div className={this.isActiveTab('search')}>
                        <img src={searchIcon} />
                      </div>
                    </li>
                  );
                }
                case 'setup': {
                  return (
                    <li
                      key={idx}
                      onClick={() =>
                        this.setState({ toggleSetup: !this.state.toggleSetup, activeTab: 'setup' })
                      }
                    >
                      <div
                        className={`${this.state.toggleSetup && this.state.activeTab ? 'active' : ''} i-want-to`}
                      >
                        <i
                          className={
                            this.state.toggleSetup
                              ? this.state.activeTab === 'setup'
                                ? 'fa fa-times active'
                                : 'fa fa-times'
                              : 'fa fa-plus'
                          }
                        ></i>
                      </div>
                    </li>
                  );
                }
                case 'login': {
                  return (
                    <li
                      key={idx}
                      onClick={() =>
                        this.setState({ activeTab: 'login' }, () => {
                          this.props.history.push('/login');
                        })
                      }
                    >
                      <div className={this.isActiveTab('login')}>
                        <i className={this.isActiveTab('login', 'fal fa-sign-in')}></i>
                      </div>
                    </li>
                  );
                }
                case 'mypages': {
                  return (
                    <li
                      key={idx}
                      onClick={() =>
                        this.setState({ activeTab: 'mypages' }, () => {
                          this.props.history.push('/dashboard?section=pages');
                        })
                      }
                    >
                      <div className={this.isActiveTab('mypages')}>
                        <img src={pagesIcon} />
                      </div>
                    </li>
                  );
                }
                case 'cart': {
                  return (
                    <li
                      key={idx}
                      onClick={() =>
                        this.setState({ activeTab: 'cart' }, () =>
                          this.props.history.push('/checkout'),
                        )
                      }
                    >
                      <div className={`cart ${this.isActiveTab('cart')}`}>
                        {this.props.cartState.totalItems > 0 && (
                          <div className="notification-dot" />
                        )}
                        <img src={cartIcon} />
                      </div>
                    </li>
                  );
                }
                default: {
                  return null;
                }
              }
            })}
          </ul>
        </div>
      </div>
    );
  };

  updateLanguage = (newLanguage: string) => {
    this.props.updateLoggedOutLanguage(newLanguage, {
      callback: () => this.props.updateLocalizeLanguageFromState(),
    });
    this.setState({
      showLanguageContextMenu: false,
    });
  };

  renderLanguageSelector = () => {
    return (
      <li
        className="language-menu"
        onClick={() =>
          this.setState({ showLanguageContextMenu: !this.state.showLanguageContextMenu })
        }
      >
        <span
          className={`language-selected ${this.state.showLanguageContextMenu ? 'show' : ''}`}
          notranslate="yes"
        >
          {capitalizeString(this.props.userState.loggedOutLanguage ?? 'en')}
          <i className={`fal fa-angle-right ${this.state.showLanguageContextMenu ? 'show' : ''}`} />
        </span>

        <ContextMenu
          onMouseLeave={() => this.setState({ showLanguageContextMenu: false })}
          showMenu={this.state.showLanguageContextMenu}
          menuItems={localeConstants.languageOptions.map((language) => ({
            ...language,
            onClick: () => this.updateLanguage(language.value),
          }))}
        />
      </li>
    );
  };

  render() {
    const footerClassName = `Footer ${!this.props.footerState.isVisible ? 'hidden' : ''}`;

    return (
      <Fragment>
        <footer className={footerClassName}>
          <div className="inner">
            <div className="row top-footer">
              <div className="link-container">
                <div
                  className="title-container"
                  onClick={() => this.setActiveSection('general')}
                >
                  <span className="title">General</span>
                  <i
                    className={`fal fa-angle-right ${this.getSectionActiveClassName('general')}`}
                  />
                </div>

                <ul
                  className={`links-list dropdown-section ${this.getSectionActiveClassName('general')}`}
                >
                  {footerLinks.general.map((item: link, index: number) => {
                    return (
                      <li key={index}>
                        <a
                          href={item.link}
                          target="_blank"
                        >
                          {item.label}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="link-container">
                <div
                  className="title-container"
                  onClick={() => this.setActiveSection('discover')}
                >
                  <span className="title">Discover</span>
                  <i
                    className={`fal fa-angle-right ${this.getSectionActiveClassName('discover')}`}
                  />
                </div>
                <ul
                  className={`links-list dropdown-section ${this.getSectionActiveClassName('discover')}`}
                >
                  {footerLinks.discover.map((item: link, index: number) => {
                    return (
                      <li key={index}>
                        <a href={item.link}>{item.label}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="link-container">
                <div
                  className="title-container"
                  onClick={() => this.setActiveSection('organization')}
                >
                  <span className="title">Grow your Organization</span>
                  <i
                    className={`fal fa-angle-right ${this.getSectionActiveClassName('organization')}`}
                  />
                </div>
                <ul
                  className={`links-list dropdown-section ${this.getSectionActiveClassName('organization')}`}
                >
                  {footerLinks.forOrganizations.map((item: link, index: number) => {
                    return (
                      <li key={index}>
                        <a href={item.link}>{item.label}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="link-container">
                <div
                  className="title-container"
                  onClick={() => this.setActiveSection('iwantto')}
                >
                  <span className="title">I Want To</span>
                  <i
                    className={`fal fa-angle-right ${this.getSectionActiveClassName('iwantto')}`}
                  />
                </div>
                <ul
                  className={`links-list dropdown-section ${this.getSectionActiveClassName('iwantto')}`}
                >
                  {footerLinks.iWantTo.map((item: link, index: number) => {
                    return (
                      <li key={index}>
                        <a href={item.link}>{item.label}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="link-container">
                <div
                  className="title-container"
                  onClick={() => this.setActiveSection('support')}
                >
                  <span className="title">Support</span>
                  <i
                    className={`fal fa-angle-right ${this.getSectionActiveClassName('support')}`}
                  />
                </div>
                <ul
                  className={`links-list dropdown-section ${this.getSectionActiveClassName('support')}`}
                >
                  {footerLinks.support.map((item: link, index: number) => {
                    return (
                      <li key={index}>
                        <a href={item.link}>{item.label}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="documents-container-mobile no-separator">
              <ul className={`links-list mobile`}>
                {this.props.navigation.map((item: link, index: number) => {
                  return (
                    <li key={index}>
                      <a
                        href={item.link}
                        target="_blank"
                      >
                        {item.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
              {this.renderLanguageSelector()}
            </div>
            <div className="row bottom-footer">
              <Link
                className="gigit-logo"
                to="/"
              >
                <img
                  alt="Kambeo"
                  src={logoDark}
                />
              </Link>
              <ul className={`links-list non-mobile`}>
                {this.props.navigation.map((item: link, index: number) => {
                  return (
                    <li key={index}>
                      <a
                        href={item.link}
                        target="_blank"
                      >
                        {item.label}
                      </a>
                    </li>
                  );
                })}
                {this.renderLanguageSelector()}
              </ul>
              <ul className="socials">
                <li>
                  <Link
                    to={{ pathname: uiConstants.urls.kambeoFacebook }}
                    target="_blank"
                    notranslate="yes"
                  >
                    <i className="icon facebook" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={{ pathname: uiConstants.urls.kambeoTwitter }}
                    target="_blank"
                    notranslate="yes"
                  >
                    <i className="icon twitter" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={{ pathname: uiConstants.urls.kambeoInstagram }}
                    target="_blank"
                    notranslate="yes"
                  >
                    <i className="icon instagram" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={{ pathname: uiConstants.urls.kambeoLinkedIn }}
                    target="_blank"
                    notranslate="yes"
                  >
                    <i className="icon linkedin" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </footer>
        {isMobileScreen(footerBreakpoint) && this.renderMobileActionBar()}
      </Fragment>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    settingsState: store.settingsState,
    userState: store.userState,
    cartState: store.cartState,
    footerState: store.footerState,
  };
};

const mapDispatchToProps = {
  toggleFooterVisibility,
  updateLoggedOutLanguage,
  updateLocalizeLanguageFromState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
