import { Constants } from '@gigit/constants';

/** Holds constants for the Frontend App. */
export const uiConstants = {
  appName: 'Kambeo',
  backendDateFormat: 'YYYY-MM-DD',

  donationList: {
    itemsPerPage: 10,
  },

  adminPaymentMethods: [
    { label: 'Cash', value: 'cash' },
    { label: 'Cheque ', value: 'cheque' },
    { label: 'E-Transfer', value: 'e-transfer' },
  ],

  // TODO: This isn't used anymore so should be removed.

  groupTypes: {
    group: 'group',
    gig: 'gig',
    event: 'event',
  },

  // TODO: Combine these into 1 constant.
  /** These are the wallets that we support. See: https://stripe.com/docs/js/payment_request/create#stripe_payment_request-options-disableWallets */
  altPaymentWallets: ['googlePay', 'applePay'],

  /** Alternative payment methods we support. */
  altPaymentMethods: {
    googlePay: 'google_pay',
    applePay: 'apple_pay',
  },

  /** Constants for types of objects that can be "owner". Which generally means they have a page. */
  ownerType: {
    group: 'group',
    event: 'event',
    gig: 'gig',
    user: 'user',
    team: 'team',
    individual: 'individual',
    hub: 'hub',
    story: 'story',
    company: 'company',
  },

  discoverFilters: {
    happeningSoon: 'happeningSoon',
    trending: 'trending',
    recent: 'recent',
  },

  unknownStatus: 'unknown',

  urls: {
    serviceAgreement: 'https://kambeo.io/service-agreement',
    eula: 'https://kambeo.io/eula',
    privacyPolicy: 'https://kambeo.io/privacy-policy',
    //Kambeo Socials
    kambeoFacebook: 'https://www.facebook.com/kambeo/',
    kambeoTwitter: 'https://twitter.com/kambeo_',
    kambeoLinkedIn: 'https://www.linkedin.com/company/kambeo',
    kambeoInstagram: 'https://www.instagram.com/kambeo.io',
    //General Kambeo links
    kambeoAboutUs: 'https://kambeo.io/about-us/',
    kambeoPricing: 'https://kambeo.io/fees/',
    kambeoBookADemo: 'https://kambeo.io/book-a-demo/',
    kambeoResources: 'https://kambeo.io/resource-center/',
    kambeoCareers: 'https://kambeo.io/careers/',
    kambeoGetHelp: 'https://kambeo.io/resource-center/',
    //Kambeo Support
    kambeoHelpCentre:
      'https://help.kambeo.io/?__hstc=170912442.acae496261ac63e1fc3f114b47a831da.1641495416941.1641495416941.1641495416941.1&__hssc=170912442.3.1641495416941&__hsfp=1169807605',
    kambeoContactUs: 'https://kambeo.io/contact-us/',
    kambeoTechnicalSupport: 'https://kambeo.io/technical-support/',
  },

  // Different page "active" statuses. "Active" means end-users can interact with it. (ie: purchase, donate, fundraise, etc...)
  activeGroupStatuses: [Constants.group_status.active, Constants.group_status.draft],
  activeEventStatuses: [
    Constants.event_status.draft,
    Constants.event_status.published,
    Constants.event_status.active,
  ],
  activeGigStatuses: [Constants.gig_status.draft, Constants.gig_status.published],
  activeHubStatuses: [Constants.hub_status.draft, Constants.gig_status.published],

  donationPaymentTypes: [
    { label: 'Cash', value: 'cash' },
    { label: 'Cheque', value: 'cheque' },
    { label: 'E-Transfer', value: 'e-transfer' },
    { label: 'In-Kind', value: 'in-kind' },
    { label: 'Other', value: 'other' },
  ],

  quill: {
    defaultToolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'clean'],
    ],
    basicToolbar: [
      ['bold', 'italic'],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link', 'clean'],
    ],
    chatToolbar: [['bold', 'italic'], ['link']],
    defaultFormats: [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
    ],

    // controls styling and disables toolbar for editable and non-editable quill editors
    readOnlyTheme: 'bubble',
    editableTheme: 'snow',

    /** In Quill Text Editor, the editor can be empty but still have some markup. */
    emptyText: '<p><br></p>',
  },
  expanded_details: {
    text: {
      max_length: 300,
    },
  },
  storeItemItemsPerPage: 10,
  maxShiftCapacity: '100',
  minShiftCapacity: '1',
  minimum_tax_receipt_amount: 5,
  //** Generic Sort Options to use with IQuery */
  sortOptions: {
    createdAtAscending: 'created_at',
    createdAtDescending: '-created_at',
  },
  hubs: {
    homePageStoreItemsLimit: '12',
    listViewPageSize: '12',
    homePageAuctionItemsLimit: '20',
  },
  paymentMethodsItemPerPage: '18',
  showAllUsersLimit: '30',
  showGroupsInList: '24',
  premiumFeatures: {
    hub_seating: 'hub_seating',
    hub_seating_do: 'hub_seating_do',
  },

  // Default Toronto coordinates
  defaultCoords: {
    lng: -79.347015,
    lat: 43.65107,
  },

  // Taken from Stripe docs of possible invoice statuses
  invoiceStatus: {
    draft: 'Draft',
    open: 'Open',
    paid: 'Paid',
    uncollectible: 'Uncollectible',
    void: 'Void',
  },
  user_search_limit: '5',
  volunteer_modal_types: {
    log_all: 'log_all',
    volunteer_hr_all: 'volunteer_hr_all',
    log_single: 'log_single',
    volunteer_hr_single: 'volunteer_hr_single',
  },
  volunteer_hours_history_items_per_page: 10,
  volunteer_hours_all_items_per_page: 10,
  volunteer_hours_single_gig_items_per_page: 10,
  story_duration: 20000,
  adminListRows: '10',
  hubsEmployeeRange: [
    {
      label: '1-10',
      value: '1-10',
    },
    {
      label: '11-50',
      value: '11-50',
    },
    {
      label: '51-200',
      value: '51-200',
    },
    {
      label: '201-1000',
      value: '201-1000',
    },
    {
      label: '1001-5000',
      value: '1001-5000',
    },
    {
      label: '5000+',
      value: '5000',
    },
  ],
  search: {
    defaultMapZoom: 9,
    mobileBreakPoint: 1128,
  },
  communityFeed: {
    focusAreaListDefault: 8,
  },
  overNotificationLimitTxt: '99+',
  notificationLimit: 99,
  role: {
    admin: 'Admin',
    onBehalfOf: 'On-Behalf-Of Fundraiser',
    member: 'Member',
  },
  visibility: {
    public: 'public',
    membersOnly: 'members_only',
  },
  warning: {
    page_not_active: 'The page you are trying to view is not currently active.',
  },
  donationType: {
    once: 'once',
    recurring: 'recurring',
  },
  defaultMatchAmount: 25,
  maxAmountMatchable: 50,
  donationMatchingRatioOptions: [
    {
      label: '1:1',
      value: '1',
    },
    {
      label: '2:1',
      value: '2',
    },
    {
      label: '3:1',
      value: '3',
    },
  ],
};
