interface IError {
  errorMessage: string | null;
  linkText: string | null;
}
export function getNoCampaignOrNonMonetizedErrorMessage(
  isMonetized: boolean,
  isAdmin: boolean,
  entity?: string,
): IError {
  const entityType = entity ?? 'Cause';
  if (isMonetized === false) {
    return isAdmin
      ? {
          errorMessage: `The ${entityType} is not monetized.`,
          linkText: 'Monetize your payment account now!',
        }
      : {
          errorMessage: `The ${entityType} is not monetized. Please contact the ${entityType} administrator.`,
          linkText: null,
        };
  }

  return {
    errorMessage: null,
    linkText: null,
  };
}
