import { Constants } from '@gigit/constants';

//TODO: Move other query helpers to here
export namespace QueryHelpers {
  export type FilterOperator = Exclude<keyof typeof Constants.field_filter_operator, 'unknown'>;

  export const FILTER_TYPE_OPERATOR_MAP: Record<Exclude<FilterOperator, 'includes'>, string> = {
    equal: '=',
    not_equal: '<>',
    greater_than: '>',
    less_than: '<',
    greater_than_or_equal: '>=',
    less_than_or_equal: '<=',
  };
}
