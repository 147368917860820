import React, { FC } from 'react';

import './CardCommonGroupSkeleton.scss';
import { EViewTypeCardCommonGroup, EStyleTypeCardCommonGroup } from './CardCommonGroup';

interface IProps {
  viewType: EViewTypeCardCommonGroup;
  styleType: EStyleTypeCardCommonGroup;
}

export const CardCommonGroupSkeleton: FC<IProps> = (props: IProps) => {
  return (
    <div
      className={`CardCommonGroupSkeleton ${EStyleTypeCardCommonGroup[props.styleType]} ${EViewTypeCardCommonGroup[props.viewType]}`}
    >
      <div className="card-header">
        <div className="cover-image-holder"> </div>
        <div className="profile-img"></div>
      </div>
      <div className="card-content">
        <div className="title"></div>
        <div className="title"></div>
        <div className="description"></div>
        <div className="description"></div>
      </div>
      <div className="card-actions">
        <div className="button"></div>
        <div className="button"></div>
      </div>
    </div>
  );
};
