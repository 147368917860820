import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import {
  IAccountPublicSummary,
  IAuctionItemSummary,
  IEventSummaryFE,
  IPage,
} from '@gigit/interfaces';
import { RouteComponentProps } from 'react-router-dom';
import { IStringMap, toastError, defaultCurrency, typeHelpers } from '../../../helpers';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import Loader from '../../Loader/Loader';
import QuillTextEditor from '../../QuillTextEditor/QuillTextEditor';
import { IAuctionState } from '../../../reducers/auction';
import { IGroupState } from '../../../reducers/group';
import { IUserState } from '../../../reducers/user';
import { IEventState } from '../../../reducers/event';
import { getUserInfo } from '../../../actions/user';
import { getCurrentGroupPageComponents } from '../../../actions/group';
import { createToast } from '../../../actions/toaster';
import AuctionItemDetailsModal from './AuctionItemDetailsModal/AuctionItemDetailsModal';
import auctionItemHelpers from './auctionItemHelpers';
import AuctionitemLeaderDisplay from './AuctionItemLeaderDisplay/AuctionitemLeaderDisplay';
import AuctionItemBuyNowModal from './AuctionItemBuyNowModal/AuctionItemBuyNowModal';
import { getNoCampaignOrNonMonetizedErrorMessage } from '../ComponentPlaceholder/helpers/helpers';
import { formatCurrency } from '../../../helpers';
import { Constants } from '@gigit/constants';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { IToast } from '../../../interfaces';
import { userSelectors } from '../../../selectors/user';
import './AuctionItem.scss';
import { uiConstants } from '../../../constants';
import AuctionItemPlaceBidModal, {
  BidModalTab,
} from './AuctionItemPlaceBidModal/AuctionItemPlaceBidModal';
import { IOwnerObject } from '../../../interfaces';
import { ReactComponent as AuctionPlaceholder } from './../../../assets/placeholders/auction-placeholder.svg';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

interface IPassedProps extends RouteComponentProps<any, any, any> {
  page: IPage;
  component: any;
  permissions?: IStringMap;
  item: IAuctionItemSummary;
  owner: IOwnerObject;
  currentPageIndex: number;
  dragProps?: DraggableProvidedDragHandleProps;
  accountInfo: IAccountPublicSummary | undefined;
  isLoadingAccountInfo: boolean;
}
interface IPropsFromState {
  groupState: IGroupState;
  userState: IUserState;
  eventState: IEventState;
  auctionState: IAuctionState;
  locale: string;
}

interface IPropsFromDispatch {
  getCurrentGroupPageComponents(groupId: string, pageId: string): void;

  createToast(toast: IToast): void;
  getUserInfo(handleOrId: string, cb?: any): void;

  onComponentUpdate?(): void;
}
type IProps = IPropsFromState & IPropsFromDispatch & IPassedProps;
interface IState {
  showImageModal: boolean;
  showDetailModal: boolean;
  bidModal: boolean;
  showmore: boolean;
  currentImage: number;
  imageRef: any;
  imageStyle: string;

  height: number;
  width: number;
  loadingImage: boolean;
  showLoginModal: boolean;
  queueBid: boolean;
  activeTab: BidModalTab;
  buyNowModal: boolean;
  currentImageUrl: string | null;
}

class AuctionItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showImageModal: false,
      showDetailModal: false,
      bidModal: false,
      showmore: false,
      currentImage: 0,
      imageRef: React.createRef(),
      imageStyle: 'landscape',
      height: window.innerHeight,
      width: window.innerWidth,
      loadingImage: false,
      showLoginModal: false,
      queueBid: false,
      activeTab: 'bid',
      buyNowModal: false,
      currentImageUrl: null,
    };

    this.isAdmin = this.isAdmin.bind(this);
    this.toggleImageModal = this.toggleImageModal.bind(this);
    this.setImageStyle = this.setImageStyle.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.userCanBid = this.userCanBid.bind(this);
    this.onSuccessfulSubmitPayment = this.onSuccessfulSubmitPayment.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.placeBidAuctionId === this.props.item.id
    ) {
      this.setState({
        bidModal: true,
      });
    }

    const currentImageUrl = this.props.item.media[this.state.currentImage]?.url;
    const imageLoader = new Image();
    imageLoader.src = currentImageUrl;

    imageLoader.onload = () => {
      this.setState({ currentImageUrl });
    };
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  toggleImageModal() {
    this.setState(
      {
        showImageModal: true,
      },
      () => {
        this.setImageStyle();
      },
    );
  }

  setImageStyle() {
    if (this.state.imageRef && this.state.imageRef.current) {
      if (this.state.imageRef.current.naturalWidth < this.state.imageRef.current.naturalHeight) {
        this.setState({
          imageStyle: 'portrait',
        });
      } else if (
        this.state.imageRef.current.naturalWidth === this.state.imageRef.current.naturalHeight
      ) {
        this.setState({
          imageStyle: 'square',
        });
      } else {
        this.setState({
          imageStyle: 'landscape',
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      (this.state.bidModal !== prevState.bidModal ||
        this.state.showDetailModal !== prevState.showDetailModal) &&
      !this.state.bidModal &&
      !this.state.buyNowModal &&
      !this.state.showDetailModal
    ) {
      this.fetchData();
    }

    if (prevState.currentImage !== this.state.currentImage) {
      setTimeout(() => {
        this.setImageStyle();

        this.setState({
          loadingImage: false,
        });
      }, 100);
    }

    if (
      prevState.bidModal !== this.state.bidModal &&
      !this.state.bidModal &&
      !this.state.buyNowModal &&
      this.props.location?.state?.placeBidAuctionId
    ) {
      this.props.history.replace(`${this.props.location.pathname}`, null);
    }

    if (
      prevState.buyNowModal !== this.state.buyNowModal &&
      !this.state.buyNowModal &&
      this.props.location.state?.placeBidAuctionId
    ) {
      this.props.history.replace(`${this.props.location.pathname}`, null);
    }
  }

  onSuccessfulSubmitPayment() {
    this.setState({
      buyNowModal: false,
    });

    this.fetchData();
  }

  fetchData() {
    if (this.props.owner.ownerType === 'group') {
      this.props.getCurrentGroupPageComponents(this.props.page.owner_id, this.props.page.id);
    } else if (this.props.owner.ownerType === 'event') {
      this.props.onComponentUpdate?.();
    }
  }

  isAdmin() {
    const { permissions } = this.props;

    return !!(
      permissions != null &&
      (permissions['EDIT_EVENT_PAGES'] || permissions['EDIT_GROUP_PAGES'])
    );
  }

  userCanBid() {
    if (!this.props.accountInfo?.charges_enabled) {
      const isHub = this.props.owner?.parentOwnerType === 'hub';
      const entityType = isHub ? 'Company' : 'Cause';
      const { errorMessage, linkText } = getNoCampaignOrNonMonetizedErrorMessage(
        this.props.accountInfo?.charges_enabled ?? false,
        this.isAdmin(),
        entityType,
      );
      const handle = isHub
        ? typeHelpers.getHubHandleFromOwner(this.props.owner)
        : typeHelpers.getGroupHandleFromOwner(this.props.owner);
      const route = isHub ? 'company' : 'group';
      const toast = toastError(localizeHelpers.translate(errorMessage), 'Auction', [
        {
          link: {
            link: `/${route}/${handle}/admin?t=balance_and_payouts`,
            text: linkText ?? '',
          },
        },
      ]);
      this.props.createToast(toast);

      return;
    }
    if (!this.props.userState.isLoggedIn) {
      this.props.history.push(
        `/login?redirect=${this.props.location.pathname}?tab=${this.props.currentPageIndex + 1}`,
        {
          placeBidAuctionId: this.props.item.id,
          loginRedirectMessage: 'To continue placing a bid, please sign in.',
        },
      );

      // GIG-1757: Quick fix for bug where scrolling stops working when going to login from Place bid and navigating back.
      // TODO: Probably find a better fix for this...
      const body = document.querySelector('body') as HTMLBodyElement;
      body.style.overflow = 'unset';

      return;
    }
    this.setState({ bidModal: true, activeTab: 'bid' });
  }

  onAuctionItemClicked = () => {
    if (!this.state.showDetailModal) {
      if (
        this.state.width <= 730 &&
        moment(new Date()).format('X') < moment(this.props.item.end_date).format('X')
      ) {
        if (this.props.userState.isLoggedIn) {
          this.props.getUserInfo(this.props.userState.user.handle, () =>
            this.setState({ showDetailModal: true }),
          );
        } else {
          this.setState({ showDetailModal: true, queueBid: true });
        }
      }
    }
  };

  getAuctionStatusIndicator() {
    switch (this.props.item.status?.code) {
      case Constants.auction_item_status.running:
        return { label: 'OPEN', iconClass: 'fa fa-check-circle' };
      case Constants.auction_item_status.new:
        return { label: 'NOT STARTED', iconClass: 'fa fa-times-circle' };
      case Constants.auction_item_status.closed:
        return { label: 'CLOSED', iconClass: 'fa fa-times-circle' };
      default:
        throw new Error(`Unsupported auction item status ${this.props.item.status?.code}`);
    }
  }
  getDescription = () => {
    if (this.state.showmore) return this.props.item.description;
    return (this.props.item.description || '').slice(0, 150) || 'This item has no description.';
  };
  render() {
    const currency = this.props.owner.account?.currency ?? defaultCurrency;
    const auctionPortraitStyle = this.state.currentImageUrl
      ? { backgroundImage: `url(${this.state.currentImageUrl})` }
      : { backgroundColor: 'white' };

    return (
      <div
        onClick={this.onAuctionItemClicked}
        className={this.isAdmin() ? 'AuctionItem admin' : 'AuctionItem'}
      >
        {this.props.item.media && this.props.item.media.length > 0 ? (
          <div className="images">
            <div
              onClick={() => {
                if (this.state.width > 730) {
                  this.toggleImageModal();
                }
              }}
              className="main"
              style={{
                backgroundImage: 'url(' + this.props.item.media[this.state.currentImage].url + ')',
              }}
            ></div>
            <ul className="media-nav">
              {this.props.item.media.map((item: any, index: number) => {
                return (
                  <li
                    key={index}
                    className={this.state.currentImage === index ? 'active' : ''}
                    onClick={() => {
                      this.setState({ currentImage: index });
                    }}
                  >
                    <i
                      className={
                        this.state.currentImage === index ? 'fas fa-circle' : 'far fa-circle'
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className="images">
            <div className="image-placeholder">
              <AuctionPlaceholder />
            </div>
          </div>
        )}
        <div className="content auction-item-content">
          {this.props.item.media && this.props.item.media.length > 0 ? (
            <div
              style={auctionPortraitStyle}
              className="auction-portrait"
            />
          ) : (
            <div className="placeholder-portrait">
              <i className="fal fa-gavel" />
            </div>
          )}

          <div className="status">
            <i className={this.getAuctionStatusIndicator().iconClass} />
            <span>{this.getAuctionStatusIndicator().label}</span>
          </div>

          <div
            className="name"
            notranslate="yes"
          >
            {this.props.item.name}
          </div>
          <div className="current-bid">
            Current Bid:{' '}
            <var data-var="current_bid">
              {formatCurrency(
                this.props.item.current_bid?.bid_amount || this.props.item.minimum_start_bid || 0,
                currency,
                this.props.locale,
              )}
            </var>
          </div>
          <div className="action">
            {auctionItemHelpers.canPlaceBid(this.props.item) &&
              this.props.owner.isActive &&
              !this.props.isLoadingAccountInfo && (
                <div className="action-wrap">
                  <Button
                    onClick={this.userCanBid}
                    text="Place Bid"
                  />
                </div>
              )}
            {!auctionItemHelpers.canPlaceBid(this.props.item) && (
              <div className="action-wrap closed">
                <Button
                  isDisabled={true}
                  text={
                    this.props.item.status?.code === Constants.auction_item_status.new
                      ? 'Not Started'
                      : 'Closed'
                  }
                />
              </div>
            )}
          </div>

          <AuctionitemLeaderDisplay
            owner={this.props.owner}
            className="AuctionItemLeaderDisplay-hide"
            item={this.props.item}
            locale={this.props.locale}
            onPlaceBidClicked={this.userCanBid}
            canPlaceBid={this.props.isLoadingAccountInfo === false}
          />

          <div className="description">
            <QuillTextEditor
              value={this.getDescription()}
              readOnly={true}
              preserveWhitespace={true}
              theme={uiConstants.quill.readOnlyTheme}
              modules={auctionItemHelpers.quillModules}
              formats={auctionItemHelpers.quillFormats}
            />
            <div className="view-more-less">
              {(this.props.item.description || '').length > 150 && !this.state.showmore && (
                <span onClick={() => this.setState({ showmore: true })}>View more</span>
              )}
              {this.state.showmore && (
                <span onClick={() => this.setState({ showmore: !this.state.showmore })}>
                  View less
                </span>
              )}
            </div>
          </div>
          <div className="details">
            <div className="minimum">
              <div className="icon-wrap">
                <i className="fas fa-dollar-sign" />
              </div>
              <div className="details-inner">
                <div className="label">Minimum Increment</div>
                <div
                  className="info"
                  notranslate="yes"
                >
                  {formatCurrency(
                    this.props.item.minimum_bid_increment || 0,
                    currency,
                    this.props.locale,
                  )}
                </div>
              </div>
            </div>
            <div className="retail">
              <div className="icon-wrap">
                <i className="fas fa-shopping-bag" />
              </div>
              <div className="details-inner">
                <div className="label">Retail Price</div>
                <div
                  className="info"
                  notranslate="yes"
                >
                  {formatCurrency(this.props.item.retail_price || 0, currency, this.props.locale)}
                </div>
              </div>
            </div>
            <div className="close">
              <div className="icon-wrap">
                <i className="fas fa-calendar-day" />
              </div>
              <div className="details-inner">
                <div className="label">Close Date</div>
                <div
                  className="info"
                  notranslate="yes"
                >
                  {localizeHelpers.formatDate(
                    this.props.item.end_date,
                    LocaleDateFormats.LLL,
                    this.props.locale,
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="list-admin-actions">
          <ul>
            <li {...this.props.dragProps}>
              <i className="fas fa-arrows-alt" />
              <span>Drag</span>
            </li>
          </ul>
        </div>
        <AuctionItemDetailsModal
          item={this.props.item}
          owner={this.props.owner}
          showDetailModal={this.state.showDetailModal}
          onPlaceBidClicked={this.userCanBid}
          onClose={() => this.setState({ showDetailModal: false })}
          onBuyNowClicked={() => this.setState({ bidModal: true, activeTab: 'buy_now' })}
        />

        <Modal
          class="auction-images"
          show={this.state.showImageModal}
          onClose={() => {
            this.setState({ showImageModal: false });
          }}
        >
          <div className="auction-image-gallery">
            <div className="image-title">
              <div className="total">
                Image {this.state.currentImage + 1} of {this.props.item.media.length}
              </div>
              <div className="title">{this.props.item.name}</div>
            </div>
            {this.state.loadingImage && <Loader loading={this.state.loadingImage} />}
            {this.props.item.media &&
              this.props.item.media[this.state.currentImage] &&
              !this.state.loadingImage && (
                <img
                  alt={'media item ' + this.state.currentImage}
                  className={this.state.imageStyle}
                  ref={this.state.imageRef}
                  src={this.props.item.media[this.state.currentImage].url}
                />
              )}
            {this.state.currentImage > 0 && (
              <div
                onClick={() => {
                  if (this.state.currentImage > 0) {
                    this.setState({
                      currentImage: this.state.currentImage - 1,
                      loadingImage: true,
                    });
                  }
                }}
                className="left"
              >
                <i className="fas fa-arrow-left"></i>
              </div>
            )}
            {this.props.item.media.length &&
              this.state.currentImage + 1 < this.props.item.media.length && (
                <div
                  onClick={() => {
                    if (
                      this.props.item.media.length &&
                      this.state.currentImage + 1 < this.props.item.media.length
                    ) {
                      this.setState({
                        currentImage: this.state.currentImage + 1,
                        loadingImage: true,
                      });
                    }
                  }}
                  className="right"
                >
                  <i className="fas fa-arrow-right"></i>
                </div>
              )}
          </div>
        </Modal>

        <AuctionItemPlaceBidModal
          owner={this.props.owner}
          item={this.props.item}
          currentImage={this.state.currentImage}
          setCurrentImage={(index) => this.setState({ currentImage: index })}
          showBidModal={this.state.bidModal}
          activeTab={this.state.activeTab}
          setActiveTab={(tab) => this.setState({ activeTab: tab })}
          onClose={() => this.setState({ bidModal: false, activeTab: 'bid' })}
          onBidPlaced={() => {
            this.fetchData();
          }}
          onBuyNowClicked={() => {
            this.setState({
              bidModal: false,
              buyNowModal: true,
            });
          }}
          onFinish={() => {
            this.setState({
              bidModal: false,
              showDetailModal: false,
            });
          }}
        />

        <AuctionItemBuyNowModal
          showBuyNowModal={this.state.buyNowModal}
          handleOnSuccessfulSubmitPayment={this.onSuccessfulSubmitPayment}
          onClose={() => this.setState({ buyNowModal: false })}
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    groupState: store.groupState,
    userState: store.userState,
    eventState: store.eventState,
    auctionState: store.auctionState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  getCurrentGroupPageComponents,
  createToast,
  getUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuctionItem);
