import axios from 'axios';
import { Dispatch } from 'redux';
import { routes } from '../helpers';
import { dataURItoBlob } from '../helpers';
import { IMediaAlbumState, IMediaItem } from '../reducers/mediaAlbum';
import { Config } from '@gigit/config';

import { createGroupMediaAlbum } from './group';
import { createEventMediaAlbum } from './event';
import { createProfileMediaAlbum } from './profile';
import { createGigMediaAlbum } from './gig';
import { IAppState } from '../store';

export enum MediaAlbumActionTypes {
  UPDATE_MEDIA_ALBUM = 'UPDATE_MEDIA_ALBUM',
}

export interface IUpdateMediaAlbumAction {
  parent: string;
  isCreated: boolean;
  totalItems: number;
  itemsLoaded: number;
  albumName: string;
  albumItems: IMediaItem[];
  isUploading: boolean;
  type: MediaAlbumActionTypes.UPDATE_MEDIA_ALBUM;
}

export type MediaAlbumActions = IUpdateMediaAlbumAction;

export const updateMediaAlbum = (_payload: Partial<IMediaAlbumState>) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      ..._payload,
      type: MediaAlbumActionTypes.UPDATE_MEDIA_ALBUM,
    });
  };
};

export const uploadMediaAlbum = () => {
  return async (dispatch: Dispatch, getState: any) => {
    let _albumItems = [...getState().mediaAlbumState.albumItems];

    dispatch({
      isUploading: true,
      type: MediaAlbumActionTypes.UPDATE_MEDIA_ALBUM,
    });

    for (let i in _albumItems) {
      if (!_albumItems[i].uploaded) {
        uploadMediaItem(_albumItems[i], parseInt(i))(dispatch, getState);
      }
    }
  };
};

export const uploadMediaItem = (item: any, index: number) => {
  return async (dispatch: Dispatch, getState: any) => {
    const blob = dataURItoBlob(item.url);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let _albumItems = [...getState().mediaAlbumState.albumItems];
        _albumItems[index].url = response.data.value;
        _albumItems[index].uploaded = true;

        dispatch({
          albumItems: [..._albumItems],
          itemsLoaded: parseInt(getState().mediaAlbumState.itemsLoaded) + 1,
          type: MediaAlbumActionTypes.UPDATE_MEDIA_ALBUM,
        });
      });
  };
};

export const createMediaAlbum = (_parentType: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      isUploading: false,
      isCreated: true,
      type: MediaAlbumActionTypes.UPDATE_MEDIA_ALBUM,
    });

    let _mediaAlbumState = { ...getState().mediaAlbumState };

    let _payload: any = {
      title: _mediaAlbumState.albumName,
      media_items: [],
    };

    for (let i in _mediaAlbumState.albumItems) {
      _payload.media_items.push({
        url: _mediaAlbumState.albumItems[i].url,
        media_type: 'image',
        sequence: parseInt(i) + 1,
      });
    }

    if (_parentType === 'group') {
      createGroupMediaAlbum(_mediaAlbumState.parentId, _payload)(dispatch, getState);
    }

    if (_parentType === 'event') {
      createEventMediaAlbum(_mediaAlbumState.parentId, _payload)(dispatch, getState);
    }

    if (_parentType === 'gig') {
      createGigMediaAlbum(_mediaAlbumState.parentId, _payload)(dispatch, getState);
    }

    if (_parentType === 'user') {
      createProfileMediaAlbum('current', _payload)(dispatch, getState);
    }
  };
};
