import React, { useState } from 'react';
import { IGigSummaryFE, IHub } from '@gigit/interfaces';
import { gigRequestActions, hubRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import './HubManagementVolunteerOpportunities.scss';
import { typeHelpers } from '../../../../helpers';
import { Prompt } from '../../../Prompt/Prompt';
import Table, { ITableProps } from '../../../shared/Table/Table';
import { Constants } from '@gigit/constants';
import { IAppState, useAppSelector } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { uiConstants } from '../../../../constants';
import { Link, useHistory } from 'react-router-dom';
import Modal from '../../../Modal/Modal';
import EditVolunteerOpportunity from '../shared/EditVolunteerOpportunity/EditVolunteerOpportunity';
import HubVolunteerModal from '../../HubModals/HubVolunteerModal/HubVolunteerModal';

interface IProps {
  hub?: IHub;
}

function HubManagementVolunteerOpportunities(props: IProps) {
  const [itemToDelete, setItemToDelete] = useState<IGigSummaryFE | null>(null);
  const [activeItem, setActiveItem] = useState<IGigSummaryFE | null>(null);
  const [showOpportunityModal, setShowOpportunityModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const userState = useAppSelector((state) => state.userState);

  const { dispatchToastError } = useToastDispatcher();
  const history = useHistory();
  const { hub } = props;

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  function goToCreateFlow() {
    try {
      typeHelpers.assertNotNullOrUndefined(hub?.id);
      history.push({
        pathname: `/onboarding/${uiConstants.ownerType.gig}/hubSelection`,
        search: `?hubId=${hub.id}&createdFor=company`,
      });
    } catch (error) {
      dispatchToastError(error, 'Create Gig Flow');
    }
  }

  const tableProps: ITableProps<IGigSummaryFE> = {
    columns: [
      {
        id: 'title',
        Header: 'Volunteer Opportunity',
        accessor: ({ title }) => title,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ handle, title }) => (
          <Link
            to={`/${uiConstants.ownerType.gig}/${handle}`}
            notranslate="yes"
          >
            {title}
          </Link>
        ),
      },
      {
        id: 'start_date',
        Header: 'Date',
        accessor: ({ start_date }) => start_date,
        Cell: ({ start_date }) =>
          localizeHelpers.formatDate(start_date ?? new Date(), LocaleDateFormats.LL, locale),
        sortable: true,
      },
      {
        id: 'number_of_interested',
        Header: 'Interested',
        accessor: ({ number_of_interested }) =>
          localizeHelpers.formatNumber(number_of_interested || 0, locale),
        sortable: true,
      },
      {
        id: 'number_of_confirmed',
        Header: 'Confirmed',
        accessor: ({ number_of_confirmed }) =>
          localizeHelpers.formatNumber(number_of_confirmed || 0, locale),
        sortable: true,
      },
      {
        id: 'status.code',
        Header: 'Status',
        accessor: ({ status }) => status?.code || Constants.gig_status.draft,
        sortable: true,
        predefinedColumnType: {
          type: 'STATUS',
          columnObject: () => ({
            [Constants.gig_status.archived]: {
              label: 'archived',
              color: 'GREY',
            },
            [Constants.gig_status.draft]: {
              label: 'Draft',
              color: 'YELLOW',
            },
            [Constants.gig_status.published]: {
              label: 'Published',
              color: 'GREEN',
            },
          }),
        },
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      enableRowButtonActions: true,
      tableActions: [
        {
          type: 'HEADER',
          label: 'Create Opportunity',
          onClick: goToCreateFlow,
          buttonType: 'dark',
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'View Details',
          onClick: (_, item) => {
            setActiveItem(item);
            setShowOpportunityModal(true);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Edit',
          icon: 'fa fa-pencil',
          onClick: (_, item) => {
            setActiveItem(item);
            setShowEditModal(true);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Delete',
          icon: 'fal fa-trash',
          onClick: (_, item) => setItemToDelete(item),
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      additionalQueryParams: {
        type: Constants.object_type.hub,
      },
      queryAction: async (queryParams) => {
        let data: IGigSummaryFE[] = [];
        try {
          typeHelpers.assertNotNullOrUndefined(hub?.id);
          data = await hubRequestActions.getVolunteerOpportunities(hub.id, queryParams);
          if (showOpportunityModal) {
            const selectedItem = data.find((d) => d.id === activeItem?.id);
            setActiveItem(selectedItem || null);
          }
        } catch (error) {
          dispatchToastError(error, 'Get Company Volunteer Opportunities');
        }

        return data;
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any opportunity",
    },
    filterOptions: {
      enableTableSearch: true,
      enableColumnSorting: true,
      enableFilterDrawer: true,
      filterDrawerOptions: [
        {
          type: 'dateField',
          fieldId: 'createdAt',
          options: { label: 'Date' },
        },
        {
          type: 'textField',
          fieldId: 'number_of_interested',
          options: { label: 'Interested' },
        },
        {
          type: 'textField',
          fieldId: 'number_of_confirmed',
          options: { label: 'Confirmed' },
        },
      ],
    },
  };

  async function handleDeleteItem() {
    try {
      typeHelpers.assertNotNullOrUndefined(itemToDelete?.id);

      await gigRequestActions.changeGigStatus(itemToDelete.id, Constants.gig_status.archived);
      refreshTableData();
    } catch (error) {
      dispatchToastError(error, 'Delete Volunteer Opportunity');
    }
  }

  return (
    <>
      <div
        className="HubManagementVolunteerOpportunities"
        id="volunteer-opportunities"
      >
        <div className="header-section">
          <h2>Volunteer Opportunities</h2>
        </div>
        <div className="table-section">
          <Table
            {...tableProps}
            refreshTableIncrementor={refreshTableIncrementor}
          />
        </div>
      </div>

      {showOpportunityModal && (
        <HubVolunteerModal
          show={showOpportunityModal}
          selected={activeItem}
          hideInterested={false}
          userState={userState}
          onClose={() => setShowOpportunityModal(false)}
          onUpdate={refreshTableData}
          onDeleteVolunteerOpportunity={refreshTableData}
        />
      )}

      {activeItem && hub?.id && (
        <Modal
          show={showEditModal}
          onClose={() => setShowEditModal(false)}
        >
          <EditVolunteerOpportunity
            volunteerOpportunity={activeItem}
            onCancel={() => setShowEditModal(false)}
            onSaveCallback={() => {
              refreshTableData();
              setShowEditModal(false);
            }}
            hub_id={hub.id}
          />
        </Modal>
      )}

      <Prompt
        show={itemToDelete !== null}
        title="Delete Volunteer Opportunity Item"
        message={`Are you sure you want to delete ${itemToDelete?.title || ''}?`}
        yesMessage="Yes"
        yesClass="fa fa-trash"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={handleDeleteItem}
        onClose={() => setItemToDelete(null)}
      />
    </>
  );
}

export default HubManagementVolunteerOpportunities;
