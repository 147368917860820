import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import { IEventState } from '../../reducers/event';

import { resetCheckIn } from '../../actions/event';
import { getEvent } from '../../actions/event';

import { IAppState } from '../../store';

import './CheckIn.scss';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  eventState: IEventState;
  getEvent(_handle: string): void;
}

interface IState {
  event: string;
  token: string;
}

class CheckIn extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let _params = queryString.parse(this.props.location.search);

    this.state = {
      event: _params.event ? _params.event.toString() : '',
      token: _params.token ? _params.token.toString() : '',
    };
  }

  componentDidMount() {
    this.props.getEvent(this.state.event);
  }

  render() {
    //const { t } = this.props;

    return <div className="CheckIn"></div>;
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {
  resetCheckIn,
  getEvent,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(CheckIn),
);
