import axios from 'axios';
import type Stripe from 'stripe';

import {
  IPayout,
  IChargeTransaction,
  IPayoutKpi,
  ISimpleValue,
  IAccount,
  IAccountDetails,
  IAccountLinkCreateParams,
  IAccountLinkResponse,
  IGroup,
  IStripeOAuthResponse,
  IAccountPublicSummary,
  IPaymentMethod,
  IQuery,
  ISetupIntentResponse,
} from '@gigit/interfaces';

import { swapRouteParams, routes } from '../helpers';

export namespace accountRequestActions {
  /** Connects a Stripe account with our platform account.
   * see: https://stripe.com/docs/connect/oauth-reference#get-authorize-response
   */
  export async function connectAccount(params: IStripeOAuthResponse) {
    const response = await axios.post<ISimpleValue<string>>(
      routes.ACCOUNTS.CONNECT_ACCOUNT,
      params,
    );
    return response.data;
  }

  export async function getPayouts(
    objectType: string,
    objectId: string,
    accountId: string,
    queryParams: URLSearchParams,
  ) {
    let route = swapRouteParams(routes.ACCOUNTS.GET_OBJECT_ACCOUNT_PAYOUTS, {
      object_type: objectType,
      object_id: objectId,
      accountId: accountId,
    });

    const response = await axios.get<IPayout[]>(route, { params: queryParams });
    return response.data;
  }

  export async function getPaymentMethodDetails(
    objectType: string,
    objectId: string,
    transactionId: string,
  ) {
    let route = swapRouteParams(routes.ACCOUNTS.GET_OBJECT_DONATION_PAYMENT_METHOD_DETAILS, {
      object_type: objectType,
      object_id: objectId,
      transaction_id: transactionId,
    });

    const response = await axios.get<{ last_4_digits: string; card_brand: string }>(route);
    return response.data;
  }

  export async function getPayoutTransactions(
    objectType: string,
    objectId: string,
    accountId: string,
    payoutId: string,
    queryParams: URLSearchParams,
  ) {
    let routeTransactions = swapRouteParams(
      routes.ACCOUNTS.GET_OBJECT_ACCOUNT_PAYOUT_TRANSACTIONS,
      { object_type: objectType, object_id: objectId, accountId: accountId, payoutId: payoutId },
    );

    const response = await axios.get<IChargeTransaction[]>(routeTransactions, {
      params: queryParams,
    });
    return response.data;
  }

  export async function getPayoutTransactionKpi(
    objectType: string,
    objectId: string,
    accountId: string,
    payoutId: string,
  ) {
    const route = swapRouteParams(routes.ACCOUNTS.GET_OBJECT_ACCOUNT_PAYOUT_KPI, {
      object_type: objectType,
      object_id: objectId,
      accountId: accountId,
      payoutId: payoutId,
    });
    const response = await axios.get<IPayoutKpi>(route);
    return response.data;
  }

  export async function getAccountAuthorizationUrl(objectType: string, objectId: string) {
    const response = await axios.post<ISimpleValue<string>>(
      swapRouteParams(routes.ACCOUNTS.GET_OBJECT_STRIPE_ACCOUNT_AUTHORIZE_LINK, {
        object_type: objectType,
        object_id: objectId,
      }),
    );
    return response.data;
  }

  export async function getAccounts(objectType: string, objectId: string) {
    const response = await axios.get<IAccount[]>(
      swapRouteParams(routes.ACCOUNTS.GET_OBJECT_ACCOUNTS, {
        object_type: objectType,
        object_id: objectId,
      }),
    );
    return response.data;
  }

  export async function getCurrentAccountPublicSummary(objectType: string, objectId: string) {
    const response = await axios.get<IAccountPublicSummary>(
      swapRouteParams(routes.ACCOUNTS.GET_OBJECT_CURRENT_ACCOUNT_PUBLIC_SUMMARY, {
        object_type: objectType,
        object_id: objectId,
      }),
    );
    return response.data;
  }

  export async function getAccountsWithDetails(objectType: string, objectId: string) {
    const response = await axios.get<IAccountDetails[]>(
      swapRouteParams(routes.ACCOUNTS.GET_OBJECT_ACCOUNTS_WITH_DETAILS, {
        object_type: objectType,
        object_id: objectId,
      }),
    );
    return response.data;
  }

  export async function getAccountLink(
    objectType: string,
    objectId: string,
    accountId: string,
    createParams: IAccountLinkCreateParams,
  ) {
    const url = swapRouteParams(routes.ACCOUNTS.GET_OBJECT_STRIPE_ACCOUNT_LINK, {
      object_type: objectType,
      object_id: objectId,
      accountId,
    });

    const response = await axios.post<IAccountLinkResponse>(url, createParams);
    return response.data;
  }

  export async function createExternalAccount(
    objectType: string,
    objectId: string,
    accountId: string,
    createParams: { externalAccountToken: string },
  ) {
    const url = swapRouteParams(routes.ACCOUNTS.CREATE_OBJECT_EXTERNAL_ACCOUNT, {
      object_type: objectType,
      object_id: objectId,
      accountId,
    });

    const response = await axios.post<Stripe.BankAccount>(url, createParams);
    return response.data;
  }

  export async function getExternalAccountDefault(
    objectType: string,
    objectId: string,
    accountId: string,
  ) {
    const url = swapRouteParams(routes.ACCOUNTS.GET_OBJECT_EXTERNAL_ACCOUNT_DEFAULT, {
      object_type: objectType,
      object_id: objectId,
      accountId,
    });

    const response = await axios.get<Stripe.BankAccount>(url);
    return response.data;
  }

  export async function setCurrentAccount(objectType: string, objectId: string, accountId: string) {
    const url = swapRouteParams(routes.ACCOUNTS.SET_OBJECT_ACCOUNT_CURRENT, {
      object_type: objectType,
      object_id: objectId,
      accountId,
    });

    const response = await axios.put<IGroup>(url);
    return response.data;
  }

  export async function getPaymetMethods() {
    const response = await axios.get<IPaymentMethod[]>(routes.GET_PAYMENT_METHODS);
    return response.data;
  }

  export async function createPaymentMethodRequest(payload: IPaymentMethod) {
    const response = await axios.post<ISetupIntentResponse>(routes.GET_PAYMENT_METHODS, payload);
    return response.data;
  }
}
