import { ICause } from '@gigit/interfaces';
import React from 'react';
import { HorizontalScroller } from '../../../shared/HorizontalScroller/HorizontalScroller';
import './CausesFilter.scss';

interface IProps {
  causes: string[];
  selectedCauses: string[];
  causesMaster: ICause[];
  onSelectCause(updatedSelections: string[]): void;
}

const CausesFilter: React.FC<IProps> = (props) => {
  const { selectedCauses, causes, causesMaster } = props;

  function isSelected(value: string) {
    return selectedCauses.includes(value);
  }

  function handleCauseSelection(cause: string) {
    let existingCauses = [...selectedCauses];
    if (isSelected(cause)) {
      const updatedCauseSelect = existingCauses.filter(
        (existingCause: string) => existingCause !== cause,
      );
      props.onSelectCause(updatedCauseSelect);
    } else {
      existingCauses.push(cause);
      props.onSelectCause(existingCauses);
    }
  }

  function renderCauses() {
    return causes.map((cause: string, index) => {
      const isSelectedCause = isSelected(cause);
      return (
        <span
          key={index}
          onClick={() => handleCauseSelection(cause)}
          className={isSelectedCause ? 'chip selected' : 'chip'}
        >
          {isSelectedCause && <i className="fa fa-check" />}
          {cause}
        </span>
      );
    });
  }

  return (
    <div className="CausesFilter">
      <HorizontalScroller>{() => renderCauses()}</HorizontalScroller>
    </div>
  );
};

export default CausesFilter;
