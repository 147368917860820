import React, { FC } from 'react';

import './CardCommonHubSkeleton.scss';
import { EViewTypeCardCommonHub, EStyleTypeCardCommonHub } from './CardCommonHub';

interface IProps {
  viewType: EViewTypeCardCommonHub;
  styleType: EStyleTypeCardCommonHub;
}

export const CardCommonHubSkeleton: FC<IProps> = (props: IProps) => {
  return (
    <div
      className={`CardCommonHubSkeleton ${EStyleTypeCardCommonHub[props.styleType]} ${EViewTypeCardCommonHub[props.viewType]}`}
    >
      <div className="card-header">
        <div className="cover-image-holder"> </div>
        <div className="profile-img"></div>
      </div>
      <div className="card-content">
        <div className="title"></div>
        <div className="title"></div>
        <div className="description"></div>
        <div className="description"></div>
      </div>
      <div className="card-actions">
        <div className="button"></div>
        <div className="button"></div>
      </div>
    </div>
  );
};
