import { Constants } from '@gigit/constants';
import { ICause, IHub, IPageComponent } from '@gigit/interfaces';
import React, { ReactNode, useEffect, useState } from 'react';
import useToastDispatcher from '../../../../../../hooks/useToaster';
import { localizeHelpers } from '../../../../../../localizeHelpers';
import { causeRequestActions, hubRequestActions } from '../../../../../../requestActions';
import Button from '../../../../../Button/Button';
import Modal from '../../../../../Modal/Modal';
import Portrait from '../../../../../Portrait/Portrait';
import './FocusAreaSettings.scss';

interface IProps {
  hub?: IHub | null;
  pageComponent: IPageComponent;
  showModal: boolean;
  isSaving: boolean;
  handleSavePageComponent: (updatedComponent: IPageComponent) => void;
  onClose: () => void;
}

interface FocusAreaSettingsList extends ICause {
  visible: boolean;
}

const FocusAreaSettings = (props: IProps) => {
  const { dispatchToastError } = useToastDispatcher();

  const [focusAreaList, setFocusAreaList] = useState<FocusAreaSettingsList[]>([]);

  useEffect(() => {
    if (props.hub && props.hub.id) {
      getHubFocusAreas(props.hub.id);
    }
  }, [props.hub]);

  async function getHubFocusAreas(hubId: string) {
    const allFocusAreas = await causeRequestActions.getCauses();

    let focusAreas: FocusAreaSettingsList[] = [];
    let componentAreas = props.pageComponent.content_references?.object_ids;

    for (let focusArea of allFocusAreas) {
      if (componentAreas?.indexOf(focusArea.id || '') !== -1) {
        focusAreas.push({ ...focusArea, visible: true });
      } else {
        focusAreas.push({ ...focusArea, visible: false });
      }
    }

    setFocusAreaList(focusAreas);
  }

  function toggleFocusAreaVisibility(index: number) {
    let tmpList = focusAreaList;

    let visibleCount = tmpList.filter((item) => item.visible).length;

    if (visibleCount === 8 && !tmpList[index].visible) {
      dispatchToastError(
        localizeHelpers.translate('Maximum number of visible areas is 8'),
        'Focus Areas',
      );
    } else {
      tmpList[index].visible = !tmpList[index].visible;
      setFocusAreaList([...tmpList]);
    }
  }

  async function savePageComponentChanges() {
    let tmpPageComponent = props.pageComponent;
    let visibleAreas = focusAreaList.filter((item) => item.visible);
    let visibleIds = visibleAreas.map((item) => item.id);

    const causeDiff = visibleIds.filter((id) => !props.hub?.causes?.includes(id!));

    if (causeDiff.length) {
      let payload = {
        causes: props.hub?.causes?.length
          ? props.hub?.causes?.concat(causeDiff as string[])
          : (causeDiff as string[]),
      };

      await hubRequestActions.updateHub(payload, props.hub?.id);
    } else {
      if (!visibleIds.length && props.hub?.causes?.length) {
        let payload = {
          causes: [],
        };

        await hubRequestActions.updateHub(payload, props.hub?.id);
      }
    }

    tmpPageComponent.content_references = {
      object_ids: visibleIds as string[],
      object_type: Constants.object_type.cause,
    };

    props.handleSavePageComponent(tmpPageComponent);
  }

  return (
    <Modal
      key="hub-focus-area-settings"
      class="FocusAreaSettings"
      show={props.showModal}
      onClose={props.onClose}
    >
      <h2>Focus Areas</h2>
      <span className="modal-description">Select up to a maximum of 8 focus areas to show</span>

      <div className="focus-area-list">
        {focusAreaList.map((focusArea: FocusAreaSettingsList, index: number) => {
          return (
            <div
              className={`focus-area-item ${!focusArea.visible ? 'hidden' : ''}`}
              key={index}
            >
              <div className="item-info">
                <Portrait
                  overlay={false}
                  currentImage={focusArea.icon_url}
                  size={30}
                  hideMask={true}
                />
                <div className="item-title">{focusArea.cause}</div>
              </div>
              <div
                onClick={() => toggleFocusAreaVisibility(index)}
                className="item-action"
              >
                <i className={focusArea.visible ? 'far fa-eye' : 'far fa-eye-slash'} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="action-section">
        <Button
          onClick={() => props.onClose()}
          className="secondary"
        >
          Cancel
        </Button>

        <Button
          onClick={() => savePageComponentChanges()}
          className="primary"
        >
          Save Changes
        </Button>
      </div>
    </Modal>
  );
};

export default FocusAreaSettings;
