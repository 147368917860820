import React from 'react';

import './StoryCarouselSkeleton.scss';

export default function StoryCarouselSkeleton() {
  return (
    <div className="StoryCarouselSkeleton">
      <div className="stories-container">
        <div className="story">
          <div className="background-img"></div>
          <div className="profile-img"></div>
        </div>
        <div className="story">
          <div className="background-img"></div>
          <div className="profile-img"></div>
        </div>
        <div className="story">
          <div className="background-img"></div>
          <div className="profile-img"></div>
        </div>
        <div className="story">
          <div className="background-img"></div>
          <div className="profile-img"></div>
        </div>
        <div className="story">
          <div className="background-img"></div>
          <div className="profile-img"></div>
        </div>
      </div>
    </div>
  );
}
