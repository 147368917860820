import React from 'react';
import { connect } from 'react-redux';
import {
  handleInputChange,
  swapRouteParams,
  routes,
  toastError,
  formatCurrency,
  defaultCurrency,
  toastInfo,
} from '../../../../helpers';
import TextField from '../../../TextField/TextField';
import Button from '../../../Button/Button';
import { addItem, removeItem } from '../../../../actions/cart';
import { IAppState } from '../../../../store';
import { ICartState } from '../../../../reducers/cart';
import Axios from 'axios';
import { IEventState } from '../../../../reducers/event';
import { createToast } from '../../../../actions/toaster';
import { IUserState } from '../../../../reducers/user';
import Dropdown, { IOptions } from '../../../Dropdown/Dropdown';
import { IStoreState } from '../../../../reducers/store';
import { localizeHelpers } from '../../../../localizeHelpers';
import { ICampaign } from '@gigit/interfaces';
import { IToast } from '../../../../interfaces';
import { userSelectors } from '../../../../selectors/user';

interface IProps {
  cartState: ICartState;
  eventState: IEventState;
  userState: IUserState;
  storeState: IStoreState;

  flow: string;
  fundraiser: any;
  registrationId: string | null;
  locale: string;

  updateFundraiser(field: string, val: any): void;
  addItem(item: any, parent_type: string, options?: { callback?: () => void }): void;
  removeItem(index: number): void;
  createToast(toast: IToast): void;
}

interface IState {
  addParticipant: boolean;
  goal: number | string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isEdit: boolean;
  isNew: boolean;
  participants: Array<any>;
  showToast: boolean;
  fundraisingTicket: string;
  ticketOptions: Array<IOptions>;
  isFundraising: boolean;
}

class AddParticipant extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      addParticipant: false,
      goal: '',
      fullName: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      isEdit: false,
      participants: [],
      isNew: false,
      showToast: false,
      fundraisingTicket: '',
      ticketOptions: [],
      isFundraising: false,
    };

    this.handleAddParticipant = this.handleAddParticipant.bind(this);
    this.getFirstAndLastName = this.getFirstAndLastName.bind(this);
    this.onGoalUpdate = this.onGoalUpdate.bind(this);
    this.handleParticipant = this.handleParticipant.bind(this);
    this.clearParticipantData = this.clearParticipantData.bind(this);
    this.checkFundraiseStatus = this.checkFundraiseStatus.bind(this);
  }

  componentDidMount() {
    this.intializeData();
    this.setTickets();
  }

  setTickets() {
    const currency = this.props.eventState.event.group?.account?.currency ?? defaultCurrency;

    let items = this.props.eventState?.fundraisingTickets
      .filter((item) => item)
      .map((item) => {
        return {
          label: `${item.name} ${formatCurrency(item.price, currency, this.props.locale)}`,
          value: item.id || '',
        };
      });

    this.setState(
      {
        ticketOptions: items,
      },
      () => {
        this.setState({
          fundraisingTicket: items[0]?.value || '',
        });
      },
    );
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (!prevState.showToast && this.state.showToast) {
      const toast = toastInfo(
        localizeHelpers.translate('An individual with this email has already registered.'),
        'Participant email exists.',
      );
      this.props.createToast(toast);

      this.setState({
        showToast: false,
      });
    }

    if (prevProps.fundraiser !== this.props.fundraiser) {
      this.setState({
        addParticipant: this.props.fundraiser?.hasParticipants,
        participants: this.props.fundraiser?.participants,
      });
    }
  }

  intializeData() {
    if (this.props.fundraiser?.participants) {
      this.setState({
        addParticipant: this.props.fundraiser?.hasParticipants,
        participants: this.props.fundraiser?.participants,
      });
    }
  }

  handleAddParticipant() {
    this.setState(
      {
        addParticipant: !this.state.addParticipant,
      },
      () => {
        let p = this.state.addParticipant;
        this.props.updateFundraiser('hasParticipants', p);
      },
    );
  }

  onGoalUpdate(goal: number) {
    this.setState({
      goal: goal,
    });
  }

  getFirstAndLastName() {
    let names = this.parseName();
    if (names) {
      this.setState({
        firstName: names.first_name,
        lastName: names.last_name,
      });
    }
  }

  parseName() {
    let names = this.state.fullName.split(' ');
    if (names.length === 2) {
      return {
        first_name: names[0],
        last_name: names[1],
      };
    } else if (names.length > 2) {
      let last = names.slice(1).join('');
      return {
        first_name: names[0],
        last_name: last,
      };
    } else {
      return false;
    }
  }

  handleParticipant(index?: number) {
    let p = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      goal: this.state.goal,
      registration_item_id: this.state.fundraisingTicket,
      isFundraising: this.state.isFundraising,
    };

    if (!index && index !== 0) {
      if (!this.state.firstName || !this.state.lastName) {
        const toast = toastInfo(
          localizeHelpers.translate('Please enter both first and last names.'),
          'Participant Name',
        );
        this.props.createToast(toast);

        return;
      }

      let f = {
        ...this.props.fundraiser,
        participants: [...this.props.fundraiser?.participants, p],
      };
      this.setState(
        {
          participants: f.participants,
        },
        () => {
          // filter this by the ticket
          let participants = [...this.state.participants];
          let filteredParticipants = participants.filter(
            (p) => p?.registration_item_id === this.state.fundraisingTicket,
          );
          this.props.updateFundraiser('participants', participants);
          let ticket = this.props.eventState?.fundraisingTickets?.filter(
            (i) => i && i.id === this.state.fundraisingTicket,
          )[0];

          if (this.state.fundraisingTicket && ticket) {
            this.props.addItem(
              { ...ticket, ...{ quantity: 1, participants: filteredParticipants, participant: p } },
              'event',
            );
          }
          this.clearParticipantData();
        },
      );
    } else {
      let pIndex = this.state.participants.findIndex((pt: any, idx: number) => {
        return idx === index;
      });

      if (pIndex || pIndex === 0) {
        let newParticipants = [...this.state.participants];
        newParticipants.splice(pIndex, 1);

        this.setState(
          {
            participants: newParticipants,
          },
          () => {
            let participants = [...this.state.participants];
            // base remove idx off selected ticket instead
            let removeIdx = this.props.cartState.items.findIndex((item: any, index: number) => {
              return item.id === this.state.fundraisingTicket;
            });

            this.props.updateFundraiser('participants', participants);

            if (this.state.fundraisingTicket) {
              this.props.removeItem(removeIdx);
            }
          },
        );
      }
    }
  }

  clearParticipantData() {
    this.setState({
      goal: '',
      fullName: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      isFundraising: false,
    });
  }

  checkFundraiseStatus() {
    if (this.props.userState.isLoggedIn) {
      if (this.state.isFundraising) {
        Axios.get(
          swapRouteParams(routes.CHECK_FUNDRAISE_STATUS, {
            eventId: this.props.eventState.event.id,
            email: this.state.email,
          }),
        ).then((response) => {
          if (response?.data?.isIndividual) {
            this.setState({
              showToast: true,
            });
          } else if (response?.data.isIndividual === false) {
            this.handleParticipant();
          }
        });
      } else {
        this.handleParticipant();
      }
    } else {
      this.handleParticipant();
    }
  }

  checkCanAddParticipant() {
    const emailError = this.state.isFundraising && this.getEmailError(this.state.email);

    if (!emailError) {
      this.setState({ isEdit: false, isNew: false }, () => {
        this.checkFundraiseStatus();
      });
    } else {
      const toast = toastError(localizeHelpers.translate(emailError), 'Add Participant');
      this.props.createToast(toast);
    }
  }

  getEmailError(email: string) {
    if (this.props.userState.user.email === email) {
      return 'Email is the same as the logged in user. Additional participants need their own email to have a personal page.';
    }

    if (this.state.participants.find((participant) => participant.email === email)) {
      return 'Email matches another added participant. Additional participants need their own email to have a personal page.';
    }

    return undefined;
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} charity`}>
        <div className="details-row">
          <div className="details">
            <p>Would you like to add participants?</p>
          </div>
          <div className="settings-control">
            {this.state.addParticipant && <span>Yes</span>}
            <i
              onClick={this.handleAddParticipant}
              className={this.state.addParticipant ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}
            />
          </div>
        </div>
        {this.state.addParticipant &&
          (this.state.isNew || this.state.isEdit || this.state.participants.length <= 0) && (
            <div className="participant-form">
              <div className="details-row">
                <span>Create a fundraising page for this participant?</span>
                <div className="settings-control">
                  <i
                    onClick={() => this.setState({ isFundraising: !this.state.isFundraising })}
                    className={this.state.isFundraising ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}
                  />
                </div>
              </div>
              <TextField
                value={this.state.fullName}
                name="fullName"
                label="Full Name"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this, false, this.getFirstAndLastName);
                }}
              />
              {this.state.isFundraising && (
                <React.Fragment>
                  <TextField
                    value={this.state.goal}
                    name="goal"
                    label="Goal Amount"
                    type="number"
                    onChange={(e) => {
                      handleInputChange(e, this, false, this.onGoalUpdate, true);
                    }}
                  />
                  <div className="detail-row">
                    <TextField
                      required={true}
                      label="Email"
                      value={this.state.email}
                      name="email"
                      type="email"
                      onChange={(e) => {
                        handleInputChange(e, this, false);
                      }}
                    />
                    <TextField
                      label="Phone Number"
                      required
                      value={this.state.phone}
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      name="phone"
                      onChange={(e) => {
                        handleInputChange(e, this, false);
                      }}
                    />
                  </div>
                </React.Fragment>
              )}
              {this.props.eventState?.fundraisingTickets?.length > 0 && (
                <Dropdown
                  label="Ticket"
                  value={this.state.fundraisingTicket}
                  shouldSort={true}
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                  name="fundraisingTicket"
                  options={this.state.ticketOptions}
                  notranslate="yes"
                />
              )}
              <div className="actions participants">
                <Button
                  onClick={() => this.checkCanAddParticipant()}
                  text={this.state.isEdit ? 'Edit' : 'Save'}
                />
              </div>
            </div>
          )}
        {this.state.participants.length > 0 &&
          this.state.addParticipant &&
          !this.state.isNew &&
          !this.state.isEdit && (
            <div>
              {this.state.participants.map((p: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="details-row"
                  >
                    <p notranslate="yes">{`${p.first_name} ${p.last_name}`}</p>
                    <i
                      onClick={() =>
                        this.setState({ isEdit: false, isNew: false }, () => {
                          this.handleParticipant(index);
                        })
                      }
                      className="fa fa-trash"
                    />
                  </div>
                );
              })}
              <div className="actions">
                <Button
                  text="Add"
                  icon={'fa fa-plus'}
                  onClick={() => this.setState({ isNew: true, isEdit: false })}
                />
              </div>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    cartState: store.cartState,
    userState: store.userState,
    storeState: store.storeState,
    locale: userSelectors.getCurrentLocale(store),
  };
};
const mapDispatchToProps = {
  addItem,
  removeItem,
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddParticipant);
