import { IGroup, IQuery } from '@gigit/interfaces';
import React, { useEffect, useState } from 'react';
import { formatQuery } from '../../../helpers';
import useToastDispatcher from '../../../hooks/useToaster';
import { groupRequestActions, searchRequestActions } from '../../../requestActions';
import Button from '../../Button/Button';
import Loader from '../../Loader/Loader';
import Modal from '../../Modal/Modal';
import Portrait from '../../Portrait/Portrait';
import TextField from '../../TextField/TextField';
import { IDropdownOption } from '../../UserDashboard/Applications/Applications';
import { ISelectOption } from '../forms/select/types';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../PopupMenu/PopupMenu';
import './CauseSelectionModal.scss';
import { localizeHelpers } from '../../../localizeHelpers';

export interface ISelectedGroup extends ISelectOption {
  id: string;
}
interface IProps {
  showCauseSelectionModal: boolean;
  onClose(): void;
  onAddCause: (selectedCause: ISelectedGroup[]) => void;
  modalType?: string;
  selected?: ISelectedGroup[];
  hubId?: string;
}

interface IFilterByOptions {
  recommended: IDropdownOption;
  newest: IDropdownOption;
}

const filterByOptions: IFilterByOptions = {
  recommended: { value: 'recommended', label: 'Recommended' },
  newest: { value: 'newest', label: 'Newest' },
};

const CauseSelectionModal: React.FC<IProps> = (props) => {
  const [causes, setCauses] = useState<IGroup[]>([]);
  const [selectedCauses, setSelectedCauses] = useState<ISelectedGroup[]>(
    props.selected ? props.selected : [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchDebounce, setSearchDebounce] = useState<ReturnType<typeof setTimeout>>();
  const [showContextMenuFilterBy, setShowContextMenuFilterBy] = useState<boolean>(false);
  const [filterBy, setFilterBy] = useState<IDropdownOption>(filterByOptions.recommended);

  const getShowMenuClassFilterBy = showContextMenuFilterBy ? 'show' : '';

  const { dispatchToastError, dispatchToastInfo } = useToastDispatcher();

  const popupMenuConfigFilterBy: IShowPopupConfig = {
    showMenu: showContextMenuFilterBy,
    setShowMenu: setShowContextMenuFilterBy,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItemsFilterBy: IPopupMenuItem[] = [
    {
      id: filterByOptions.recommended.value,
      label: filterByOptions.recommended.label,
      isSelected: filterBy.value === filterByOptions.recommended.value,
      onClick: () => {
        setFilterBy(filterByOptions.recommended);
        setShowContextMenuFilterBy(false);
      },
    },
    {
      id: filterByOptions.newest.value,
      label: filterByOptions.newest.label,
      isSelected: filterBy.value === filterByOptions.newest.value,
      onClick: () => {
        setFilterBy(filterByOptions.newest);
        setShowContextMenuFilterBy(false);
      },
    },
  ];

  async function getCauses() {
    let result;
    try {
      setLoading(true);
      if (searchValue === '') {
        switch (filterBy.value) {
          case 'recommended':
            result = await groupRequestActions.getRecommendedCauses(props.hubId);
            break;
          case 'newest':
            result = await groupRequestActions.getGroups(
              formatQuery({
                limit: '10',
                sort: [{ id: 'created_at', order: 'desc' }],
              }),
            );
            break;
        }
      } else {
        result = await searchRequestActions.searchGroupsV2({
          query: searchValue ?? '',
          options: {
            include_shadow: false,
            results_limit: 100,
          },
        });
      }

      result = result?.filter(
        (group) =>
          (group?.external_id?.GlobalGiving &&
            group.gg_active_projects_count !== undefined &&
            group.gg_active_projects_count > 0) ||
          !group.external_id,
      );
      setCauses(result ? result : []);
    } catch (error) {
      dispatchToastError(error, 'Get Causes');
    } finally {
      setLoading(false);
    }
  }

  function handleSelectCause(cause: IGroup) {
    const selectedCauseToOption: ISelectedGroup = {
      label: cause?.title ?? '',
      value: cause?.id ?? '',
      imageURL: cause?.profile_image_url ?? '',
      id: cause?.id ?? '',
    };

    const isCauseSelected = !!selectedCauses.find(
      (selectedCause) => selectedCause.value === cause.id,
    );
    if (isCauseSelected) {
      setSelectedCauses((existingCauses) =>
        existingCauses.filter((existingCause) => existingCause.value !== cause.id),
      );
      return;
    }

    if (props.modalType === 'log_hours') {
      setSelectedCauses(Array(selectedCauseToOption));
    } else {
      setSelectedCauses((existingCauses) => [selectedCauseToOption, ...existingCauses]);
    }
  }

  function isSelected(cause: IGroup) {
    let isCauseSelected = selectedCauses.find((selected) => selected.value === cause.id);
    return isCauseSelected;
  }

  function renderListItems(causes: Array<IGroup>) {
    return causes.map((cause, index) => (
      <div
        className="list-item-container"
        onClick={() => handleSelectCause(cause)}
        key={index}
      >
        <div className={isSelected(cause) ? `item selected` : 'item'}>
          <Portrait
            size={40}
            currentImage={cause.profile_image_url}
          />
          <div className="info">
            <span
              className="title"
              notranslate="yes"
            >
              {cause.title}
            </span>
            {cause.charity_id && (
              <span className="charity-id">
                {`Charity ID : `}
                <var data-var="charity_id">{cause.charity_id}</var>
              </span>
            )}
          </div>
        </div>
      </div>
    ));
  }

  useEffect(() => {
    if (searchValue !== undefined && searchValue !== '') {
      setLoading(true);
      if (searchDebounce) {
        clearTimeout(searchDebounce);
      }
      setSearchDebounce(
        setTimeout(() => {
          getCauses();
        }, 500),
      );
    }
  }, [searchValue]);

  useEffect(() => {
    getCauses();
  }, [filterBy]);

  return (
    <Modal
      class="CauseSelectionModal"
      show={props.showCauseSelectionModal}
      onClose={() => {
        props.onClose();
      }}
      onBack={() => {
        props.onClose();
      }}
      closeIcon="fas fa-times"
      title={props.modalType === 'log_hours' ? `Select A Cause` : 'Select Allowed Causes'}
    >
      <div className="search-container">
        <TextField
          autoComplete="off"
          placeholder="Keyword"
          icon="fal fa-search"
          value={searchValue}
          name="search"
          type="text"
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />

        <PopupMenu
          showMenuConfig={popupMenuConfigFilterBy}
          menuItems={contextMenuItemsFilterBy}
          popupMenuClass="cause-selection-popupMenu-filter-by"
          className={`Dropdown filter-dropdown filter-by ${getShowMenuClassFilterBy}`}
          onClick={() => setShowContextMenuFilterBy(!showContextMenuFilterBy)}
        >
          <label>Filter</label>
          <div className="input-wrap">
            <span className="label">{filterBy.label}</span>
            <i className={`fas fa-caret-down menu-btn ${getShowMenuClassFilterBy}`} />
          </div>
        </PopupMenu>
      </div>
      <div className="list">
        {!loading && renderListItems(causes)}
        {loading && (
          <Loader
            loading={loading}
            className="Loader"
          />
        )}
        {!loading && causes.length < 1 && (
          <div className="empty-list">
            <p>
              There are no results for {searchValue === '' ? filterBy.label : `"${searchValue}"`}
            </p>
          </div>
        )}
      </div>
      <div className="actions">
        <Button
          text="Cancel"
          onClick={() => props.onClose()}
          buttonClass="cancel"
          buttonType="outline-dark"
        />
        <Button
          text="Add"
          onClick={() => {
            selectedCauses && props.onAddCause(selectedCauses);
          }}
          buttonType="dark"
        />
      </div>
    </Modal>
  );
};

export default CauseSelectionModal;
