import React, { FC, useEffect, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Portrait from '../../components/Portrait/Portrait';
import { combineClassNames, IStringMap } from '../../helpers';
import { ISettingsState } from '../../reducers/settings';
import { updateSettings } from '../../actions/settings';
import { IAppState } from '../../store';
import './ManagementSidebar.scss';

export interface IMenuSection {
  id: string;
  sectionTitle: string;
  sectionIcon?: string;
  customIcon?: ReactNode;
  menuOptions: IMenuOption[];
  collapsible?: boolean;
}

export interface IMenuOption {
  id: string;
  title: string;
  /** Provide this property if you want to have submenu options */
  submenuOptions?: IMenuOption[];
  /** Provide this property if you want to show this option when menu collapsed */
  iconWhenCollapsed?: string;
  permission?: string;
  hide?: boolean;
  preventClick?: () => void;
}

interface IMenuVisibility {
  id: string;
  show: boolean;
}

interface IProps {
  logo: string;
  title: string;
  role?: string;
  targetLink: { url: string; title: string };
  menuSections: IMenuSection[];
  /** Provide this property if you want to be option highlighted when lands on the page */
  activeOptionId?: string;
  permissions?: IStringMap;
  onMenuOptionClicked(optionId: string): void;
  containerClass?: string;
}

const ManagementSidebar: FC<IProps> = (props: IProps) => {
  const [showSubmenues, setShowSubmenues] = useState<IMenuVisibility[]>([]);
  const [showSectionMenus, setShowSectionMenus] = useState<IMenuVisibility[]>([]);
  const [activeMenuOption, setActiveMenuOption] = useState<string>('');
  const history = useHistory();
  const settingsState: ISettingsState = useSelector((state: IAppState) => state.settingsState);
  const sidebarCollapsed = settingsState.collapsedSidebars?.some(
    (sidebar) => sidebar === props.title,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setShowSectionMenus([
      ...props.menuSections
        .filter((menu) => menu.collapsible)
        .map((menuOption) => ({ id: menuOption.id, show: true })),
    ]);
  }, []);

  useEffect(() => {
    const menuOptionsWithSubmenues = deepSearchMenuOptionsWithParameter('submenuOptions');
    const collapsedSubmenues: IMenuVisibility[] = menuOptionsWithSubmenues.map((menuOption) => ({
      id: menuOption.id,
      show: false,
    }));

    !!props.activeOptionId && setActiveMenuOption(props.activeOptionId); //highlight active menu option if passed as a props
    !activeMenuOption && setShowSubmenues(collapsedSubmenues); //avoid collapse submenus when clicking different menu options

    //expand submenu if its child option is active
    const submenuWithActiveMenuOption: IMenuOption | undefined = menuOptionsWithSubmenues.find(
      (smo) => smo.submenuOptions?.find((so) => so.id === props.activeOptionId),
    );
    if (submenuWithActiveMenuOption) {
      const updated = showSubmenues.map((sub) =>
        sub.id === submenuWithActiveMenuOption.id ? { ...sub, show: true } : sub,
      );
      setShowSubmenues(updated);
    }
  }, [props.menuSections]);

  const showHideSidebar = () => {
    let sidebars: string[] = [];
    if (sidebarCollapsed) {
      sidebars = settingsState.collapsedSidebars.filter((cs) => cs !== props.title);
    } else {
      sidebars = settingsState.collapsedSidebars.concat(props.title);
    }
    dispatch(updateSettings({ collapsedSidebars: sidebars }));
  };

  const showHideSubmenu = (submenuId: string) => {
    const updatedSubmenuVisibility = showSubmenues.map((sm) =>
      sm.id === submenuId ? { ...sm, show: !sm.show } : sm,
    );
    setShowSubmenues(updatedSubmenuVisibility);
  };

  const toggleSectionMenu = (sectionMenuId: string) => {
    const updatedSectionMenu = showSectionMenus.map((sm) =>
      sm.id === sectionMenuId ? { ...sm, show: !sm.show } : sm,
    );
    setShowSectionMenus(updatedSectionMenu);
  };

  const deepSearchMenuOptionsWithParameter = (menuOptionParameter: string): IMenuOption[] => {
    function findAllByKey(obj: IMenuSection[] | IMenuOption, keyToFind: string): IMenuOption[] {
      return Object.entries(obj).reduce(
        (acc: IMenuOption[], [key, value]) =>
          key === keyToFind
            ? acc.concat(obj as IMenuOption)
            : typeof value === 'object'
              ? acc.concat(findAllByKey(value, keyToFind))
              : acc,
        [],
      );
    }
    return findAllByKey(props.menuSections, menuOptionParameter);
  };

  return (
    <div
      className={`ManagementSidebar ${sidebarCollapsed ? 'collapse' : ''} ${props?.containerClass ? props.containerClass : ''}`}
    >
      <div
        className="show-hide-toggle no-select"
        onClick={() => showHideSidebar()}
      >
        <i className={`fas fa-chevron-left ${sidebarCollapsed ? 'collapse' : ''}`} />
      </div>
      <Portrait
        onClick={() => {
          if (sidebarCollapsed) history.push(props.targetLink.url);
        }}
        source={props.logo}
        size={sidebarCollapsed ? 50 : 120}
      />

      <div className="title">{props.title}</div>
      <div className="target-link">
        <Link
          to={props.targetLink.url}
          notranslate="yes"
        >
          {props.targetLink.title}
        </Link>
      </div>
      {props?.role && <span className="role">Role: {props.role}</span>}

      <div className="menu-sections">
        {props.menuSections.map((section, index) => {
          const isMenuSectionExpanded = showSectionMenus.find((ms) => ms.id === section.id)?.show;
          const menuOptionsToDisplay = section.menuOptions.filter(
            (o) => props.permissions?.[o.permission || ''] !== false,
          );

          if (menuOptionsToDisplay.length === 0) {
            return;
          }

          return (
            <div
              className="menu-section"
              key={index}
            >
              <ul>
                <li
                  className={`sidebar-section-title ${section.collapsible ? 'collapsible' : ''}`}
                  key={index}
                  onClick={(e) => {
                    if (section.collapsible) {
                      toggleSectionMenu(section.id);
                    }
                  }}
                >
                  {section?.sectionIcon && <i className={section.sectionIcon} />}
                  {section?.customIcon}
                  <span>{section.sectionTitle}</span>
                  {section.collapsible && (
                    <i
                      className={`fa fa-chevron-right expand-menu-icon ${isMenuSectionExpanded ? 'turn-down' : ''}`}
                    />
                  )}
                </li>
                {menuOptionsToDisplay.map((option, index) => {
                  if (option.hide) {
                    return;
                  }
                  const submenu = option.submenuOptions && option.submenuOptions?.length > 0;
                  const isMenuOptionActive = activeMenuOption === option.id;
                  const isSubmenuExpanded = showSubmenues.find((sm) => sm.id === option.id)?.show;

                  return (
                    <ul
                      key={index}
                      className={`section-menu-list ${isMenuSectionExpanded || !section.collapsible ? 'show' : ''}`}
                    >
                      <li
                        className={`menu-option ${isMenuOptionActive ? 'active' : ''}`}
                        onClick={() => {
                          if (option?.preventClick) {
                            option.preventClick();
                          } else {
                            if (submenu) {
                              showHideSubmenu(option.id);
                            } else {
                              setActiveMenuOption(option.id);
                              props.onMenuOptionClicked(option.id);
                            }
                          }
                        }}
                      >
                        {option.title}
                        {submenu && (
                          <i
                            className={`fa fa-chevron-right expand-menu-icon ${isSubmenuExpanded ? 'turn-down' : ''}`}
                          />
                        )}
                      </li>
                      {submenu && (
                        <ul className={`submenu-options-list ${isSubmenuExpanded ? 'show' : ''}`}>
                          {option.submenuOptions?.map((subOption, index) => {
                            const isMenuOptionActive = activeMenuOption === subOption.id;
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  setActiveMenuOption(subOption.id);
                                  props.onMenuOptionClicked(subOption.id);
                                }}
                                className={`submenu-option ${isMenuOptionActive ? 'active' : ''}`}
                              >
                                {/*HACK: This works because menu titles are hardcoded and always have one space in between words*/}
                                {subOption.title.length < 21
                                  ? subOption.title
                                  : subOption.title.replace(
                                      /(?![^\n]{1,21}$)([^\n]{1,21})\s/g,
                                      '$1             ',
                                    )}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </ul>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>

      <div className="collapsed-menu">
        {deepSearchMenuOptionsWithParameter('iconWhenCollapsed').map((menuOption, index) => {
          return props.permissions?.[menuOption.permission || ''] === false ? null : (
            <i
              title={menuOption.title.charAt(0).toUpperCase() + menuOption.title.slice(1)}
              key={index}
              onClick={() => props.onMenuOptionClicked(menuOption.id)}
              className={combineClassNames(
                menuOption.iconWhenCollapsed,
                'no-select',
                props.activeOptionId === menuOption.id ? ' active' : '',
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ManagementSidebar;
