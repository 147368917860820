import React from 'react';

import LockIcon from '../../../assets/lock-icon.svg';
import Button from '../../Button/Button';
import { useHistory } from 'react-router-dom';

import './AccessDenied.scss';

const AccessDenied = ({ title, handle }: { title: string; handle: string }) => {
  const history = useHistory();
  return (
    <div className="access-denied">
      <div className="content">
        <img
          src={LockIcon}
          alt="403 Access Denied"
        />
        <div className="title">This content is for members only!</div>
        <p>You must be a member of the {title} page to view this event!</p>
        <Button
          type="button"
          onClick={() => {
            history.push(`/company/${handle}`);
          }}
          text="View Company Page"
        />
      </div>
    </div>
  );
};

export default AccessDenied;
