import React from 'react';
import ToggleButton from '../../shared/ToggleButton/ToggleButton';
import { BlockSettings } from './BlockSettings';

import './ChatSettings.scss';

interface IProps {}

interface IState {
  showOnlineStatus: boolean;
}

export class ChatSettings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showOnlineStatus: false,
    };
  }

  render() {
    return (
      <div className="ChatSettings no-select">
        <h1>Chat Settings</h1>
        <div className="settings-list">
          <div className="setting">
            <span>Show active status to other users?</span>
            <ToggleButton
              onToggleClick={() =>
                this.setState({ showOnlineStatus: !this.state.showOnlineStatus })
              }
              isActive={this.state.showOnlineStatus}
            />
          </div>
        </div>
        <BlockSettings />
      </div>
    );
  }
}
