import React, { Component } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { combineClassNames } from '../../../helpers';

import './LinkButton.scss';

/** A react-router-dom link that has default styling to look like a button. */
export default class LinkButton extends Component<LinkProps> {
  render() {
    return (
      <Link
        {...this.props}
        className={combineClassNames('LinkButton', this.props.className)}
      />
    );
  }
}
