import React from 'react';
import { StoriesContext } from '../../Contexts/StoryContext';
import { IStoryProps, IStoryIndexedObject, IStoryContext } from '../../Types/types';
import { useEffect, useMemo, useState } from 'react';
import * as hooks from '../../Hooks/StoryHooks';
import { Story } from '../Story/Story';
import { StoryActions } from '../StoryActions/StoryActions';
import { transformStories } from '../../StoryHelpers';

import './Stories.scss';

export default function Stories({
  stories = [],
  width = '100%',
  height = '100%',
  onStoryChange = () => {},
  defaultDuration = 5000,
  currentIndex = 0,
  onAllStoriesEnd = () => {},
  onStoriesStart = () => {},
  getPreviousStory = () => {},
  pauseStoryWhenInActiveWindow = false,
}: IStoryProps): JSX.Element | null {
  const storiesWithIndex: IStoryIndexedObject[] = useMemo(() => {
    return transformStories(stories, defaultDuration);
  }, [stories, defaultDuration]);

  const [selectedStory, setSelectedStory] = useState<IStoryIndexedObject | undefined>();
  const firstStoryIndex = 0;
  const lastStoryIndex = stories.length - 1;
  const [isPaused, setIsPaused] = useState<boolean>(false);

  useEffect(() => {
    const story = storiesWithIndex[currentIndex];
    if (story) {
      setSelectedStory(story);
    }
  }, [stories]);

  function handleNextClick() {
    if (selectedStory?.index === lastStoryIndex) {
      onAllStoriesEnd();
      // setSelectedStory(storiesWithIndex[0]);
      return;
    }
    setSelectedStory((prev) => {
      if (!prev) {
        onStoryChange(0);
        return storiesWithIndex[0];
      }
      const newIndex = (prev?.index || 0) + 1;
      onStoryChange(newIndex);
      return storiesWithIndex[newIndex];
    });
  }
  function handlePrevClick() {
    if (selectedStory?.index === firstStoryIndex) {
      getPreviousStory();
    }
    setSelectedStory((prev) => {
      if (!prev) {
        return storiesWithIndex[0];
      }
      const newIndex = (prev?.index || 0) - 1;
      return storiesWithIndex[newIndex] ? storiesWithIndex[newIndex] : prev;
    });
  }

  function handlePause() {
    setIsPaused(true);
  }
  function handleResume() {
    setIsPaused(false);
  }

  useEffect(() => {
    if (selectedStory) {
      onStoryChange(selectedStory.index || 0);
    }
  }, [selectedStory]);

  hooks.usePausableTimeout(
    () => {
      handleNextClick();
    },
    selectedStory?.calculatedDuration ?? null,
    isPaused,
  );

  hooks.useWindowVisibility((isWindowInFocus) => {
    if (pauseStoryWhenInActiveWindow) {
      setIsPaused(!isWindowInFocus);
    }
  });

  const contextValue: IStoryContext = {
    stories: storiesWithIndex,
    width,
    height,
    defaultDuration,
    isPaused,
    handlePause,
    handleResume,
  };

  function renderStoryBackground() {
    if (selectedStory?.background_type === 'image') {
      return (
        <div
          className="story-bg image"
          style={{ background: `url(${selectedStory.media_url}) 100% 100%`, filter: 'blur(20px)' }}
        >
          <div
            className="gradient-cover"
            style={{
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0.7) 100%)`,
            }}
          ></div>
        </div>
      );
    } else if (selectedStory?.background_type === 'video') {
      return (
        <div
          className="story-bg video"
          style={{ filter: 'blur(40px)' }}
        >
          <video
            controls={false}
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          >
            <source src={selectedStory.media_url} />
          </video>
          <div
            className="gradient-cover"
            style={{
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0.7) 100%)`,
            }}
          ></div>
        </div>
      );
    } else {
      return (
        <div
          className="story-bg color"
          style={{ backgroundColor: selectedStory?.background_color }}
        >
          <div
            className="gradient-cover"
            style={{
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0.7) 100%)`,
            }}
          ></div>
        </div>
      );
    }
  }

  if (!selectedStory) {
    return null;
  }
  return (
    <StoriesContext.Provider value={contextValue}>
      <div className="StoriesMain">
        {renderStoryBackground()}
        <Story
          key={selectedStory.index}
          onPause={handlePause}
          onResume={handleResume}
          handleNextClick={handleNextClick}
          handlePrevClick={handlePrevClick}
          story={selectedStory}
          isPaused={isPaused}
        />
        <StoryActions
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          onPause={handlePause}
          onResume={handleResume}
        />
      </div>
    </StoriesContext.Provider>
  );
}
