import { Config } from '@gigit/config';
import { IUser } from '@gigit/interfaces';
import axios from 'axios';
import React from 'react';
import { routes, swapRouteParams } from '../../../helpers';
import Button from '../../Button/Button';

import './BlockSettings.scss';

interface IProps {}

interface IState {
  blockedUsers: Array<IUser>;
}

export class BlockSettings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      blockedUsers: [],
    };
  }

  componentDidMount() {
    this.getBlockedUsers();
  }

  getBlockedUsers() {
    axios
      .get<IUser[]>(Config.web.REACT_APP_CHAT_GATEWAY + routes.GET_BLOCKED_USERS)
      .then((response) => {
        this.setState({
          blockedUsers: response.data,
        });
      })
      .catch((error) => {});
  }

  unblockUser(userId: string) {
    axios
      .post(
        Config.web.REACT_APP_CHAT_GATEWAY +
          swapRouteParams(routes.UNBLOCK_CHAT_USER, { userId: userId }),
      )
      .then((response) => {
        this.getBlockedUsers();
      })
      .catch((error) => {});
  }

  renderBlockedUsers() {
    let list = this.state.blockedUsers.map((user) => {
      if (user) {
        return (
          <div className="blocked-user">
            <div notranslate="yes"> {user?.display_name} </div>
            <div className="unblock-user">
              {/* @TODO fix _id */}
              <Button
                onClick={() => this.unblockUser(user?.id || '')}
                text="Unblock User"
              />
            </div>
          </div>
        );
      }
    });

    let emptyState = <div className="no-blocked-users">Your block list is currently empty.</div>;

    return list.length > 0 ? list : emptyState;
  }

  render() {
    return (
      <div className="BlockSettings">
        <h3>Review Blocked Users</h3>
        {this.renderBlockedUsers()}
      </div>
    );
  }
}
