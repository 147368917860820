import React from 'react';

import './FormSectionHeader.scss';

interface IFormHeaderAction {
  actionText: string;
  action(): void;
}

interface IProps {
  text: string;
  subtext?: string;
  formAction?: IFormHeaderAction;
}

export const FormSectionHeader: React.FC<IProps> = (props: IProps) => {
  const { text, subtext, formAction } = props;
  return (
    <div className="FormSectionHeader">
      <div className="info">
        <div className="header-txt"> {text} </div>
        {subtext && <p className="subtext"> {subtext} </p>}
      </div>
      {formAction && (
        <div
          className="form-header-action"
          onClick={() => formAction.action()}
        >
          {formAction.actionText}
        </div>
      )}
    </div>
  );
};
