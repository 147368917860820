import {
  IGigSummaryFE,
  IGroup,
  ILoggedVolunteerHoursCreateParamsFE,
  ILoggedVolunteerHoursSummary,
  IUser,
  IVolunteer,
} from '@gigit/interfaces';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { createToast } from '../../actions/toaster';
import {
  capitalizeString,
  handleInputChange,
  routes,
  swapRouteParams,
  toastError,
} from '../../helpers';
import errorHelpers from '../../helpers/errorHelpers';
import { IToast } from '../../interfaces';
import { IEventState } from '../../reducers/event';
import { IGroupState } from '../../reducers/group';
import { userSelectors } from '../../selectors/user';
import { IAppState } from '../../store';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Portrait from '../Portrait/Portrait';
import SearchList from '../SearchList/SearchList';
import DateTimePicker from '../shared/DateTimePicker/DateTimePicker';
import TextField from '../TextField/TextField';
import { Constants } from '@gigit/constants';
import './ReportHoursModal.scss';
import { IUserState } from '../../reducers/user';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import Table, { ITableProps } from '../shared/Table/Table';
import { uiConstants } from '../../constants';
import { searchRequestActions, userRequestActions } from '../../requestActions';
import { SearchableDropdown } from '../SearchableDropdown/SearchableDropdown';

export interface IPassedProps {
  objectType: string;
  gigs?: IGigSummaryFE[];
  show: boolean;
  onSubmit?(): void;
  onClose(): void;
  byAdmin?: boolean;
  showCausesFilter?: boolean;
  selectedGig?: IGigSummaryFE | null;
  selectedUser?: IUser | null;
}
interface IPropsFromState {
  groupState: IGroupState;
  eventState: IEventState;
  userState: IUserState;
  locale: string;
}

interface IPropsFromDispatch {
  createToast(toast: IToast): void;
}

type Props = IPassedProps & IPropsFromState & IPropsFromDispatch;

export interface IState {
  selectedGig: IGigSummaryFE | null;
  selectedUser: IUser | null;
  reportHoursStartDate: string | moment.Moment | Date | undefined;
  reportHoursEndDate: string | moment.Moment | Date | undefined;
  reportHours: number;
  reportMinutes: number;
  volunteerSearchDisabled: boolean;
  volunteers: IVolunteer[];
  gigs: IGigSummaryFE[];
  groups: IGroup[];
  selectedGroup: IGroup | null;
  activeTab: number;
  volunteerHrAllTableProps: ITableProps<ILoggedVolunteerHoursSummary>;
}

/** Modal to report hours for gigs*/
class ReportHoursModal extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedGig: props.selectedGig || null,
      selectedUser: props.selectedUser || null,
      reportHoursStartDate: '',
      reportHoursEndDate: '',
      reportHours: 0,
      reportMinutes: 0,
      volunteerSearchDisabled: false,
      volunteers: [],
      groups: [],
      gigs: [],
      selectedGroup: null,
      activeTab: 0,
      volunteerHrAllTableProps: {
        columns: [
          {
            id: 'end_date',
            Header: 'Date',
            accessor: ({ end_date }) => {
              if (end_date) {
                return localizeHelpers.formatDate(
                  end_date,
                  LocaleDateFormats.ll,
                  this.props.locale,
                );
              } else return '';
            },
            sortable: true,
            Cell: ({ end_date }) => (
              <var data-var="end_date">
                {end_date
                  ? localizeHelpers.formatDate(end_date, LocaleDateFormats.ll, this.props.locale)
                  : ''}
              </var>
            ),
          },
          {
            id: 'gig.title',
            Header: 'Opportunity Name',
            accessor: ({ gig }) => localizeHelpers.translate(gig?.title),
            sortable: true,
            Cell: ({ gig }) => <var data-var="opportunity_name">{gig?.title}</var>,
          },
          {
            id: 'group.title',
            Header: 'Organization',
            accessor: ({ group }) => group?.title || '',
            Cell: ({ group }) => <var data-var="organization">{group?.title || ''}</var>,
            sortable: true,
          },
          {
            id: 'hours',
            Header: 'Hours Logged',
            accessor: ({ hours }) => hours,
            Cell: ({ hours }) => <var data-var="hours">{hours}h</var>,
            sortable: true,
          },
          {
            id: 'status.code',
            Header: 'Status',
            accessor: ({ status }) => capitalizeString(this.getStatus(status?.code || '')),
            Cell: ({ status }) => (
              <span className={`status ${this.getStatus(status?.code || '')}`}>
                {capitalizeString(this.getStatus(status?.code || ''))}
              </span>
            ),
            sortable: true,
          },
        ],
        pagination: {
          pageSizeOptions: [uiConstants.volunteer_hours_history_items_per_page],
          queryAction: this.getVolunteerHoursHistory,
        },
        emptyStateConfig: {
          title: 'No data',
          description: "We couldn't find any volunteer hours",
        },
      },
    };
  }

  componentDidMount() {
    if (this.props.show) {
      this.searchGroups();
      this.searchGigs();
    }

    const { objectType, groupState } = this.props;
    /** We should not trigger on user's account page (component is shared, bug task GIG-3861) */
    if (objectType !== Constants.object_type.user && groupState.group.id) {
      this.getGroupVolunteers(groupState.group.id);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: IState) {
    if (
      prevProps.show !== this.props.show &&
      this.props.show &&
      this.props.objectType === Constants.object_type.user
    ) {
      this.setState({
        selectedUser: this.props.userState.user,
        volunteerSearchDisabled: true,
      });
    }
  }

  async getVolunteerHoursHistory(query: URLSearchParams) {
    let result = [] as ILoggedVolunteerHoursSummary[];

    try {
      result = await userRequestActions.getUserVolunteeringHours(query);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Get User Volunteer Hours Items');
      this.props.createToast(toast);
    }

    return result;
  }

  getStatus(statusCode: string): string {
    if (statusCode === 'approved') {
      return 'Verified';
    }

    return 'Unverified';
  }

  getGroupVolunteers = async (groupId: string, query?: URLSearchParams) => {
    try {
      let _route = swapRouteParams(routes.GET_GROUP_VOLUNTEERS, { groupId: groupId });
      const queryString = query?.toString();
      if (queryString) {
        _route += `?${queryString}`;
      }

      const volunteersResponse = await axios.get<IVolunteer[]>(_route);

      this.setState({
        volunteers: volunteersResponse.data,
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Opportunity Volunteers');
      this.props.createToast(toast);
    }
  };

  searchGroups = async (searchValue?: string) => {
    if (!searchValue || !searchValue?.trim()) {
      this.setState({ groups: [] });
      return;
    }
    try {
      const groups = await searchRequestActions.searchGroupsV2({
        query: searchValue ?? '',
        options: {
          include_shadow: true,
          results_limit: 50,
        },
      });

      this.setState({
        groups,
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Find Causes');
      this.props.createToast(toast);
    }
  };

  searchGigs = async (searchValue?: string) => {
    try {
      const gigs =
        this.props.gigs ||
        (await searchRequestActions.searchGigs(
          {
            filter_title: searchValue,
          },
          {
            limit: '50',
            search: searchValue,
          },
        ));

      this.setState({
        gigs,
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Find Causes');
      this.props.createToast(toast);
    }
  };

  //Builds dropdown list
  getGigDropdownList = () => {
    const gigs = this.props.gigs || this.state.gigs;

    let gigList = gigs.filter((gig) =>
      this.state.selectedGroup != null ? gig.group_id === this.state.selectedGroup?.id : true,
    );

    return gigList.map((gig) => ({
      id: gig.id || '',
      label: gig.title || '',
      image: gig.profile_image_url || '',
    }));
  };

  clearState = () => {
    this.setState({
      selectedGig: null,
      selectedUser: null,
      reportHoursStartDate: '',
      reportHoursEndDate: '',
      reportHours: 0,
      volunteerSearchDisabled: false,
    });
  };

  onClose = () => {
    this.clearState();
    this.props.onClose();
  };

  canReportHours = () => {
    const {
      reportHoursStartDate,
      reportHoursEndDate,
      selectedGig,
      selectedUser,
      reportHours,
      selectedGroup,
    } = this.state;
    return (
      reportHoursStartDate !== '' &&
      reportHoursEndDate !== '' &&
      moment(reportHoursStartDate).isSameOrBefore(reportHoursEndDate) &&
      selectedUser != null &&
      (selectedGig != null || selectedGroup != null) &&
      reportHours > 0
    );
  };

  handleReportHoursSubmit = async () => {
    let startDate = moment(this.state.reportHoursStartDate)
      .set({
        second: 0,
      })
      .utc()
      .format();

    let endDate = moment(this.state.reportHoursEndDate)
      .set({
        second: 0,
      })
      .utc()
      .format();

    let payload: ILoggedVolunteerHoursCreateParamsFE = {
      gig_id: this.state.selectedGig?.id,
      group_id: this.state.selectedGroup?.id,
      start_date: startDate,
      hours: Number(this.state.reportHours),
      minutes: Number(this.state.reportMinutes),
    };

    try {
      if (this.props?.byAdmin) {
        if (this.state.selectedUser?.id) {
          payload.user_id = this.state.selectedUser?.id as string;
          await userRequestActions.logVolunteeringHoursSelected(payload);
        } else {
          const toast = toastError(localizeHelpers.translate('User is required'), 'Log Hours');
          this.props.createToast(toast);
        }
      } else {
        await userRequestActions.logVolunteeringHours(payload);
      }
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Log Hours');
      this.props.createToast(toast);
    }

    this.props.onSubmit?.();
    this.onClose();
  };

  handleStartDateChange = (_startDate: string | moment.Moment | Date) => {
    const startDate = moment(_startDate);

    if (this.state.reportHoursEndDate) {
      const endDate = moment(this.state.reportHoursEndDate);
      const hoursDiff = endDate.diff(startDate, 'hours');

      this.setState({
        reportHours: hoursDiff < 0 ? 0 : hoursDiff,
      });
    }

    this.setState({
      reportHoursStartDate: startDate,
    });
  };

  handleEndDateChange = (_endDate: string | moment.Moment | Date) => {
    const startDate = moment(this.state.reportHoursStartDate);
    const endDate = moment(_endDate);

    if (this.state.reportHoursStartDate) {
      const hoursDiff = endDate.diff(startDate, 'hours');

      this.setState({
        reportHours: hoursDiff < 0 ? 0 : hoursDiff,
      });
    }

    this.setState({
      reportHoursEndDate: endDate,
    });
  };

  render() {
    return (
      <Modal
        class="ReportHoursModal"
        show={this.props.show}
        onClose={this.onClose}
        title="Log Hours"
        closeIcon="fas fa-times"
      >
        <div className="tabs">
          <span
            className={`${this.state.activeTab === 0 ? 'active' : ''}`}
            onClick={() => this.setState({ activeTab: 0 })}
          >
            Log
          </span>
          {this.state.volunteerSearchDisabled && (
            <span
              className={`${this.state.activeTab === 1 ? 'active' : ''}`}
              onClick={() => this.setState({ activeTab: 1 })}
            >
              History
            </span>
          )}
        </div>
        {this.state.activeTab === 0 && (
          <form
            className="report-hours-form"
            onSubmit={async (e) => {
              e.preventDefault();
              await this.handleReportHoursSubmit();
            }}
          >
            <div className="form-inner">
              {this.props.showCausesFilter && (
                <div className="form-row">
                  <SearchableDropdown
                    label={localizeHelpers.translate('Select a Cause')}
                    notranslate="yes"
                    showOnlySearchValue={true}
                    selectedItem={{
                      id: this.state.selectedGroup?.id || '',
                      label: this.state.selectedGroup?.title || '',
                      image: this.state.selectedGroup?.profile_image_url || '',
                    }}
                    list={this.state.groups.map((group) => ({
                      id: group.id ?? '',
                      label: group.title || '',
                      image: group.profile_image_url || '',
                    }))}
                    setSelectedItem={(group) =>
                      this.setState({
                        selectedGroup: this.state.groups.find((g) => g.id === group?.id) || null,
                        selectedGig: null,
                      })
                    }
                    onSearch={(searchValue) => {
                      this.searchGroups(searchValue);
                    }}
                  />
                  {this.state.selectedGroup && (
                    <div className="selected-item">
                      <Portrait
                        size={30}
                        currentImage={this.state.selectedGroup?.profile_image_url || ''}
                      />
                      <span
                        className="title"
                        notranslate="yes"
                      >
                        {this.state.selectedGroup?.title}
                      </span>
                      <i
                        onClick={(e) => this.setState({ selectedGroup: null })}
                        className="fal fa-times close"
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="form-row">
                <SearchableDropdown
                  label={localizeHelpers.translate('Select a Volunteer Opportunity or Shift')}
                  notranslate="yes"
                  showOnlySearchValue={true}
                  selectedItem={{
                    id: this.state.selectedGig?.id || '',
                    label: this.state.selectedGig?.title || '',
                    image: this.state.selectedGig?.profile_image_url || '',
                  }}
                  list={this.getGigDropdownList()}
                  setSelectedItem={(gig) => {
                    this.setState({
                      selectedGig: this.state.gigs.length
                        ? this.state.gigs.find((g) => g.id === gig?.id) || null
                        : this.props.gigs?.find((g) => g.id === gig?.id) || null,
                    });
                  }}
                  onSearch={(searchValue) => this.searchGigs(searchValue)}
                />
                {this.state.selectedGig && (
                  <div className="selected-item">
                    <Portrait
                      size={30}
                      currentImage={this.state.selectedGig?.profile_image_url || ''}
                    />
                    <span
                      className="title"
                      notranslate="yes"
                    >
                      {this.state.selectedGig?.title}
                    </span>
                    <i
                      onClick={(e) => this.setState({ selectedGig: null })}
                      className="fal fa-times close"
                    />
                  </div>
                )}
              </div>
              <div className="form-row">
                <SearchList
                  onItemClick={(user: any) => {
                    this.setState({ selectedUser: user });
                  }}
                  list={this.state.volunteers.map(({ user }) => ({
                    label: `${user?.first_name} ${user?.last_name}`,
                    image: user?.profile_image_url,
                    ...user,
                  }))}
                  disabled={this.state.volunteerSearchDisabled}
                  label={'Search for a volunteer *'}
                  notranslate="yes"
                />
                {this.state.selectedUser && (
                  <div className="selected-item">
                    <Portrait
                      size={30}
                      currentImage={this.state.selectedUser?.profile_image_url || ''}
                    />
                    <span
                      className="title"
                      notranslate="yes"
                    >
                      {`${this.state.selectedUser?.first_name} ${this.state.selectedUser?.last_name}`}
                    </span>
                    {!this.state.volunteerSearchDisabled && (
                      <i
                        onClick={(e) => this.setState({ selectedUser: null })}
                        className="fal fa-times close"
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="form-row">
                <label>Start Date & Time *</label>
                <DateTimePicker
                  inputProps={{ placeholder: '', readOnly: true }}
                  className="custom-dtp"
                  value={this.state.reportHoursStartDate}
                  shouldValidate={false}
                  onChange={this.handleStartDateChange}
                  locale={this.props.locale}
                />
              </div>
              <div className="form-row">
                <label>End Date & Time *</label>
                <DateTimePicker
                  inputProps={{ readOnly: true, placeholder: '' }}
                  className="custom-dtp"
                  value={this.state.reportHoursEndDate}
                  onChange={this.handleEndDateChange}
                  locale={this.props.locale}
                  shouldValidate={false}
                />
              </div>
              <div className="form-row">
                <TextField
                  label="Hours"
                  placeholder="Enter hours"
                  min="1"
                  value={this.state.reportHours}
                  name="reportHours"
                  type="number"
                  onChange={(e) => {
                    handleInputChange(e, this);
                    this.setState({
                      reportHoursEndDate: moment(this.state.reportHoursStartDate).add(
                        e.target.value,
                        'hours',
                      ),
                    });
                  }}
                />
              </div>
              <div className="form-row">
                <TextField
                  label="Minutes"
                  placeholder="Enter Minutes"
                  min="1"
                  value={this.state.reportMinutes}
                  name="reportMinutes"
                  type="number"
                  onChange={(e) => {
                    handleInputChange(e, this);
                    this.setState({
                      reportHoursEndDate: moment(this.state.reportHoursStartDate).add(
                        e.target.value,
                        'minutes',
                      ),
                    });
                  }}
                />
              </div>
            </div>
            <div className="btn-actions">
              <Button
                type="button"
                buttonType="outline-dark"
                text="Cancel"
                onClick={this.onClose}
              />
              <Button
                type="submit"
                text="Log Hours"
                isDisabled={!this.canReportHours()}
              />
            </div>
          </form>
        )}

        {this.state.activeTab === 1 && this.state.volunteerSearchDisabled && (
          <div className="history">
            <Table {...this.state.volunteerHrAllTableProps} />
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    userState: store.userState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportHoursModal);
