import axios from 'axios';

import { Constants } from '@gigit/constants';
import { IInvite, IInvitesCreateInfo } from '@gigit/interfaces';

import { routes, swapRouteParams } from '../helpers';

export namespace inviteRequestActions {
  export async function acceptInvite(ownerType: string, ownerId: string, inviteId: string) {
    let url;
    let idField;
    switch (ownerType) {
      case Constants.object_type.group:
        url = routes.ACCEPT_INVITE_TO_GROUP;
        idField = 'groupId';
        break;
      case Constants.object_type.event:
        url = routes.ACCEPT_INVITE_TO_EVENT;
        idField = 'eventId';
        break;
      case Constants.object_type.hub:
        url = routes.ACCEPT_INVITE_TO_HUB;
        idField = 'id';
        break;
      default:
        return;
    }
    // const url = ownerType === Constants.object_type.group ? routes.ACCEPT_INVITE_TO_GROUP : routes.ACCEPT_INVITE_TO_EVENT;
    // const idField = ownerType === Constants.object_type.group ? 'groupId' : 'eventId';

    return axios.post<IInvite>(
      swapRouteParams(url, { [idField]: ownerId, inviteId: inviteId }),
      {},
    );
  }

  export async function rejectInvite(ownerType: string, ownerId: string, inviteId: string) {
    let url;
    let idField;
    switch (ownerType) {
      case Constants.object_type.group:
        url = routes.REJECT_INVITE_TO_GROUP;
        idField = 'groupId';
        break;
      case Constants.object_type.event:
        url = routes.REJECT_INVITE_TO_EVENT;
        idField = 'eventId';
        break;
      case Constants.object_type.hub:
        url = routes.REJECT_INVITE_TO_HUB;
        idField = 'id';
        break;
      default:
        return;
    }
    // const url = ownerType === Constants.object_type.group ? routes.REJECT_INVITE_TO_GROUP : routes.REJECT_INVITE_TO_EVENT;
    // const idField = ownerType === Constants.object_type.group ? 'groupId' : 'eventId';

    return axios.post<IInvite>(
      swapRouteParams(url, { [idField]: ownerId, inviteId: inviteId }),
      {},
    );
  }

  export async function createInvite(
    ownerType: string,
    ownerId: string,
    payload: IInvitesCreateInfo,
  ) {
    let url;
    let idField;
    switch (ownerType) {
      case Constants.object_type.group:
        url = routes.INVITE_TO_GROUP;
        idField = 'groupId';
        break;
      case Constants.object_type.event:
        url = routes.INVITE_TO_EVENT;
        idField = 'eventId';
        break;
      case Constants.object_type.hub:
        url = routes.HUB_INVITE_USERS;
        idField = 'id';
        break;
      default:
        return;
    }

    // const url = ownerType === Constants.object_type.group ? routes.INVITE_TO_GROUP : routes.INVITE_TO_EVENT;
    // const idField = ownerType === Constants.object_type.group ? 'groupId' : 'eventId';

    return axios.post<IInvite[]>(swapRouteParams(url, { [idField]: ownerId }), payload);
  }

  export async function getInvites(ownerType: string, ownerId: string) {
    let url;
    let idField;
    switch (ownerType) {
      case Constants.object_type.group:
        url = routes.GET_GROUP_INVITES;
        idField = 'groupId';
        break;
      case Constants.object_type.event:
        url = routes.GET_EVENT_INVITES;
        idField = 'eventId';
        break;
      case Constants.object_type.hub:
        url = routes.HUB_INVITE_USERS;
        idField = 'id';
        break;
      default:
        return;
    }

    // const url = ownerType === Constants.object_type.group ? routes.GET_GROUP_INVITES : routes.GET_EVENT_INVITES;
    // const idField = ownerType === Constants.object_type.group ? 'groupId' : 'eventId';

    return axios.get<IInvite[]>(swapRouteParams(url, { [idField]: ownerId }));
  }

  export async function cancelInvite(ownerType: string, ownerId: string, inviteId: string) {
    let url;
    let idField;
    switch (ownerType) {
      case Constants.object_type.group:
        url = routes.CANCEL_INVITE_TO_GROUP;
        idField = 'groupId';
        break;
      case Constants.object_type.event:
        url = routes.CANCEL_INVITE_TO_EVENT;
        idField = 'eventId';
        break;
      case Constants.object_type.hub:
        url = routes.HUB_CANCEL_INVITE;
        idField = 'id';
        break;
      default:
        return;
    }

    // const url = ownerType === Constants.object_type.group ? routes.CANCEL_INVITE_TO_GROUP : routes.CANCEL_INVITE_TO_EVENT;
    // const idField = ownerType === Constants.object_type.group ? 'groupId' : 'eventId';

    return axios.post<IInvite>(swapRouteParams(url, { [idField]: ownerId, inviteId: inviteId }));
  }

  export async function sendEventInviteReminder(
    ownerType: string,
    ownerId: string,
    inviteId: string,
  ) {
    let url;
    let idField;
    switch (ownerType) {
      case Constants.object_type.group:
        url = routes.SEND_GROUP_INVITE_REMINDER;
        idField = 'groupId';
        break;
      case Constants.object_type.event:
        url = routes.SEND_EVENT_INVITE_REMINDER;
        idField = 'eventId';
        break;
      case Constants.object_type.hub:
        url = routes.HUB_INVITE_REMINDER;
        idField = 'id';
        break;
      default:
        return;
    }

    // const url = ownerType === Constants.object_type.group ? routes.SEND_GROUP_INVITE_REMINDER : routes.SEND_EVENT_INVITE_REMINDER;
    // const idField = ownerType === Constants.object_type.group ? 'groupId' : 'eventId';

    return axios.post<IInvite>(swapRouteParams(url, { [idField]: ownerId, inviteId: inviteId }));
  }

  export async function findInviteFromEmailToken(inviteId: string, emailToken: string) {
    const response = await axios.get<IInvite>(
      swapRouteParams(routes.FIND_INVITE_FROM_EMAIL_TOKEN, { inviteId, emailToken }),
    );
    return response.data;
  }
}
