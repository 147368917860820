import { IUserContactStatusSummary } from '@gigit/interfaces';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { userRequestActions } from '../../../../requestActions/user';

import Button from '../../../Button/Button';
import './ContactButton.scss';
import { IContactButtonProps } from './Types/types';

/** Represents the overall status of contact between 2 users.
 * - `pending` = User has sent a request to the provided contact.
 * - `accepted` = Users are contacts.
 * - `not_connected` = Users are not connected.
 * - `requested` = User has a request pending from the contact.
 */

const summaryToButtonLabel = {
  pending: 'Pending',
  accepted: 'Message',
  not_connected: 'Add as Contact',
  requested: 'Requested',
};
/**
 *
 * @param IContactButtonProps
 * @returns ContactButton element used for displaying various
 * invite requests and a message button when users are contacts
 */
export function ContactButton(props: IContactButtonProps) {
  const [summary, setSummary] = useState<IUserContactStatusSummary>(
    {} as IUserContactStatusSummary,
  );
  const history = useHistory();

  useEffect(() => {
    getContactSummary();
  }, [props.contact]);

  async function getContactSummary() {
    const contactSummary = await userRequestActions.getContactSummary(props.contact || '');
    setSummary(contactSummary);
  }

  async function onAddContact() {
    await userRequestActions.sendContactRequest(props.contact || '');
    getContactSummary();
  }

  function message() {
    history.push(`/dashboard?tab=network&contact=${props.contact}&networkTab=messages`);
  }

  function summaryAction() {
    switch (summary.status) {
      case 'pending': {
        return () => {};
      }
      case 'accepted': {
        return message();
      }
      case 'not_connected': {
        return onAddContact();
      }
      case 'requested': {
        return () => {};
      }
    }
  }

  return (
    <div className="ContactButton">
      <Button
        text={summaryToButtonLabel[summary.status]}
        onClick={() => summaryAction()}
      />
    </div>
  );
}
