import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { combineClassNames } from '../../helpers';
import { IAppState } from '../../store';
import { openModal, closeModal } from '../../actions/modals';
import './Modal.scss';
import ModalHeader from './ModalHeader/ModalHeader';
import { IModalState } from '../../reducers/modals';
import { ReactComponent as CloseIcon } from '../../assets/close-modal.svg';

interface IPassedProps {
  title?: string;
  description?: string;
  notranslateDecription?: 'yes';
  closeIcon?: string;
  children?: any;
  show: boolean;
  onClose?: (e: MouseEvent<Element>) => void;
  pushUrl?: string;
  class?: string;
  contentClassName?: string;
  contentRef?: React.RefObject<HTMLDivElement>;
  contentStyle?: React.CSSProperties;
  onBack?: (e: MouseEvent<Element>) => void;
  dontToggleBodyOverflow?: boolean;
}

interface IPropsFromState {
  modalState: IModalState;
}

interface IPropsFromDispatch {
  openModal: () => void;
  closeModal: () => void;
}

interface IState {
  prevUrl: string;
}

type IProps = IPassedProps & IPropsFromState & IPropsFromDispatch;

class Modal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      prevUrl: '/',
    };
  }

  componentDidMount() {
    if (this.props.show) {
      this.props.openModal();
      this.toggleBodyOverflow(true);
    }
  }

  componentWillUnmount() {
    if (this.props.show) {
      this.props.closeModal();
      this.toggleBodyOverflow(false);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.pushUrl !== undefined) {
      if (this.props.show !== prevProps.show && this.props.show) {
        this.setState(
          {
            prevUrl: window.location.href,
          },
          () => {
            window.history.pushState({}, '', this.props.pushUrl);
          },
        );
      }

      if (this.props.show !== prevProps.show && !this.props.show) {
        window.history.pushState({}, '', this.state.prevUrl);
      }
    }

    if (this.props.show !== prevProps.show && this.props.show) {
      this.props.openModal();
      this.toggleBodyOverflow(this.props.show);
    }

    if (this.props.show !== prevProps.show && !this.props.show) {
      this.props.closeModal();
      this.toggleBodyOverflow(this.props.show);
    }
  }

  render() {
    const { title, description, contentClassName, children, show, onClose, onBack } = this.props;
    let className = this.props.class ? `Modal ${this.props.class}` : 'Modal';

    if (show) {
      return (
        <div className={className}>
          <div
            className={combineClassNames('content', contentClassName)}
            style={this.props?.contentStyle}
            ref={this.props?.contentRef}
          >
            <ModalHeader
              title={this.props.title}
              description={this.props.description}
              onBack={onBack}
              onClose={this.props.onClose}
              closeIcon={this.props?.closeIcon}
            />

            {children}

            {onClose && !this.props?.closeIcon && (
              <CloseIcon
                className="modal-close-circle"
                onClick={onClose}
              />
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  toggleBodyOverflow(show: boolean) {
    if (!this.props?.dontToggleBodyOverflow) {
      const body = document.querySelector('body') as HTMLBodyElement;
      body.style.overflow = show ? 'hidden' : 'unset';
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    modalState: store.modalState,
  };
};

const mapDispatchToProps = {
  openModal,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
