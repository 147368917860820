import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import CoverImage from '../../../CoverImage/CoverImage';
import Portrait from '../../../Portrait/Portrait';
import ColorPickerInput from '../../../shared/ColorPickerInput/ColorPickerInput';

export interface IPropsAddBrandingStep {
  themePrimary: string;
  setThemePrimary(newThemePrimary: string): void;
  themeSecondary: string;
  setThemeSecondary(newThemeSecondary: string): void;
  logo: string;
  setLogoFile(newLogoFile: string): void;
  bannerImage: string;
  setBannerImage(newBannerImage: string): void;
}

function AddBrandingStep(props: IPropsAddBrandingStep) {
  const [showPickPrimaryColor, setShowPickPrimaryColor] = useState<boolean>(false);
  const [showPickSecondaryColor, setShowPickSecondaryColor] = useState<boolean>(false);

  function handleToggleShowPickPrimaryColor(newValue?: boolean) {
    setShowPickPrimaryColor(newValue || !showPickPrimaryColor);
  }

  function handleToggleShowPickSecondaryColor(newValue?: boolean) {
    setShowPickSecondaryColor(newValue || !showPickSecondaryColor);
  }

  return (
    <div className="AddBrandingStep">
      <section>
        <h4>Color Palette</h4>
        <p>Add your brand colors to fully customize your company page</p>

        <form>
          <div className="color-container">
            <ColorPickerInput
              color={props.themePrimary}
              onChange={props.setThemePrimary}
              label="Primary"
            />
            <ColorPickerInput
              color={props.themeSecondary}
              onChange={props.setThemeSecondary}
              label="Secondary"
            />
          </div>
        </form>
      </section>

      <section>
        <h4>Company Logo</h4>
        <p>Add your company logo.</p>

        <form className="form-logo">
          <Portrait
            className="logo-container"
            size={120}
            overlay={true}
            currentImage={props.logo}
            onChange={(param) => props.setLogoFile(param.file)}
          />
        </form>
      </section>

      <section>
        <h4>Company Banner</h4>
        <p>Supports JPG, JPEG, PNG. with max dimensions of 1280 x 450.</p>

        <form className="form-logo">
          <div className={`cover-image-container ${!props.bannerImage ? 'empty' : ''}`}>
            <CoverImage
              currentImage={props.bannerImage}
              onChange={(param) => props.setBannerImage(param.file)}
              overlay={true}
              height={454}
            />
          </div>
        </form>
      </section>
    </div>
  );
}

export default AddBrandingStep;
