import React, { ReactNode, useRef } from 'react';
import { useState } from 'react';
import { combineClassNames } from '../../../helpers';
import { Tooltip, TooltipDirection } from '../Tooltip/Tooltip';
import './TooltipTextOverflow.scss';

interface TooltipOverflow {
  text: string;
  className?: string;
  /** Used to determine overflow threshold. Default: 100px*/
  maxWidth?: number;
  direction?: TooltipDirection;
}

/** Renders a tooltip component for overflowed text ("Overflowed te...") */
function TooltipTextOverflow(props: TooltipOverflow) {
  const {
    text,
    maxWidth = 100, //sets default to 100
  } = props;
  const fileDropZoneRef = useRef<HTMLParagraphElement>(null);
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  function isTextOverflowed() {
    const isOverflowed =
      (fileDropZoneRef.current?.scrollWidth ?? 0) > (fileDropZoneRef.current?.clientWidth ?? 0);

    setIsOverflowed(isOverflowed);
  }

  return (
    <Tooltip
      direction="bottom"
      message={text}
      className="TooltipOverflow"
      disableRenderTooltip={!isOverflowed}
    >
      {(ref) => (
        <div ref={ref}>
          <p
            ref={fileDropZoneRef}
            className={combineClassNames(props.className, 'tooltip-overflow-container')}
            style={{ maxWidth }}
            onMouseEnter={() => isTextOverflowed()}
          >
            {text}
          </p>
        </div>
      )}
    </Tooltip>
  );
}

export default TooltipTextOverflow;
