import './HubTrailWarningBanner.scss';
import React from 'react';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { useLocale } from '../../../../hooks';
import { useHistory } from 'react-router-dom';

interface IProps {
  trial_end_date: Date;
  handle: string;
}

const HubTrailWarningBanner: React.FC<IProps> = (props) => {
  const locale = useLocale();
  const history = useHistory();

  const trialEndDate = localizeHelpers.formatDate(
    props.trial_end_date,
    LocaleDateFormats.LL,
    locale.currentLocale,
  );

  function shouldShowTrailBanner() {
    let dateDiff = new Date(props.trial_end_date).getTime() - new Date().getTime();
    return dateDiff / (1000 * 60 * 60 * 24) < 11;
  }

  function onMangePayment() {
    history.replace({
      pathname: `/company/${props.handle}/admin`,
      search: 't=seats&modal=addPaymentMethod',
    });
  }

  if (shouldShowTrailBanner()) {
    return (
      <div className="HubTrailWarningBanner">
        <div className="content">
          <i className="fas fa-times-circle x-icon" />
          <p>
            <b>Your trial period is coming to an end on {trialEndDate}</b> Add a payment method to
            continue using your company page.
          </p>
          <span
            className="upgrade"
            onClick={onMangePayment}
          >
            Manage Payment Method
          </span>
        </div>
      </div>
    );
  }

  return <></>;
};

export default HubTrailWarningBanner;
