import { IOnboardStep } from '../../components/shared/Onboarding/interfaces';

export const eventTypeOptions = [
  {
    text: 'Crowdfunding',
    subtext: 'Create a fundraising page for an organization or a personal focus area.',
    id: 'crowdfunding',
  },
  {
    text: 'Peer-to-Peer',
    subtext:
      'An event where people join as individuals and/or teams. Each individual and team receives their own fundraising page to customize and track on the event leaderboard.',
    id: 'p2p',
  },
  {
    text: 'Online Auction',
    subtext: 'Raise money through an online auction with real-time bidding and notifications.',
    id: 'online',
  },
  {
    text: 'Paid Ticket',
    subtext: 'Create multiple ticket types for your registration and track attendees.',
    id: 'paid',
  },
  {
    text: 'Free Registration',
    subtext: 'A simple registration event that allows you track attendees.',
    id: 'free',
  },
  {
    text: 'Custom',
    subtext:
      'Use our comprehensive component library to easily build any type of event. (eg. Golf Tournamnents, Galas, etc.)',
    id: 'custom',
  },
];

export const eventCreatedForOptions = [
  {
    text: 'On behalf of a cause',
    subtext: 'Proceeds will go directly to the account connected by the cause.',
    id: 'obo_fundraiser',
  },
  {
    text: 'For a cause I own or help administer',
    subtext: 'Proceeds will go directly to the account connected by the cause.',
    id: 'A group or organization',
  },
  {
    text: 'For a company or organization I own or help administer',
    subtext: 'Proceeds will go directly to the account connected by the organization.',
    id: 'company',
  },
];

export const eventStepsMaster: Array<IOnboardStep> = [
  {
    id: 'eventTypeSelection',
    text: 'What type of event or fundraiser are you looking to create?',
    tip: '',
    summaryTitle: 'Type of Event',
    data: [{ path: 'type', required: true }],
  },
  {
    id: 'eventCreatedFor',
    text: 'Who are you creating this event for?',
    tip: '',
    summaryTitle: 'Created For',
    data: [{ path: 'createdFor', required: true }],
  },
  {
    id: 'groupSelection',
    text: 'Select a cause to create an event for.',
    tip: '',
    summaryTitle: 'Cause Name',
    data: [{ path: 'selectedGroup', required: true }],
  },
  {
    id: 'hubSelection',
    text: 'Select an organization to create an event for.',
    tip: '',
    summaryTitle: 'Company Name',
    data: [{ path: 'selectedHub', required: false }],
  },
  {
    id: 'baseInfo',
    text: 'Tell us about your event.',
    tip: 'Including more detail can help increase engagement and revenue.',
    summaryTitle: 'Event Details',
    data: [
      { path: 'baseInfo.event_organizer', required: true },
      { path: 'baseInfo.name', required: true },
      { path: 'baseInfo.description', required: true },
      { path: 'baseInfo.revenueGoal', required: true },
      { path: 'baseInfo.startDate', required: true },
      { path: 'baseInfo.endDate', required: true },
      { path: 'baseInfo.country', required: true },
      { path: 'baseInfo.address.address', required: true },
      { path: 'baseInfo.locationType', required: true },
      { path: 'baseInfo.postalCode', required: true },
    ],
  },
  {
    id: 'causesSelection',
    text: 'Choose focus areas that best describe your event',
    tip: 'Selecting focus areas will increase the likelihood of your posts being noticed!',
    summaryTitle: 'Focus Areas',
    data: [{ path: 'causeInfo.selectedCauses', required: true }],
  },
  {
    id: 'imageSelection',
    text: 'Customize your event by adding a logo and cover image.',
    tip: 'Event pages with custom images that connect with community members are more likely to be engaged with.',
    summaryTitle: 'Event Customization',
    data: [
      { path: 'profileImage', required: true },
      { path: 'coverImage', required: true },
    ],
  },
  {
    id: 'summary',
    text: 'Review the details for your event.',
    tip: null,
    summaryTitle: null,
    data: [],
  },
];

export const getEventTypeLabel = (type: string) => {
  return eventTypeOptions.find((typeOption) => typeOption.id === type)?.text ?? type;
};
