import React from 'react';
import { ICausesSelection } from '../interfaces';
import { CheckboxCard } from '../CheckboxCard/CheckboxCard';
import './CauseSelection.scss';

interface IProps {
  causeInfo: ICausesSelection;
  onChange(e: string): void;
}

export const CauseSelection: React.FC<IProps> = (props: IProps) => {
  function renderCauses() {
    props.causeInfo.causes.sort((a, b) =>
      a.cause.toUpperCase() > b.cause.toUpperCase()
        ? 1
        : b.cause.toUpperCase() > a.cause.toUpperCase()
          ? -1
          : 0,
    );
    return props.causeInfo.causes.map((cause) => {
      return (
        <CheckboxCard
          showCheckbox={true}
          key={cause.id}
          title={cause.cause}
          id={cause.id || ''}
          checked={isSelectedCause(cause.id)}
          onChange={(e) => props.onChange(e)}
        />
      );
    });
  }

  function isSelectedCause(causeId: string | undefined) {
    let causeIndex = props.causeInfo.selectedCauses.findIndex((cause) => cause === causeId);
    return causeIndex !== -1;
  }

  return (
    <div className="CauseSelection">
      <div className="form-page column">
        <div className="heading">Select all that apply</div>
        <div className="causes-container">{renderCauses()}</div>
      </div>
    </div>
  );
};
