import React, { useState, useEffect, FC } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import EmptyImg from '../../assets/people-logo.svg';
import CreateGroup from '../../assets/discover-group-create2.png';
import './DiscoverStyles.scss';
import Button from '../../components/Button/Button';
import {
  combineClassNames,
  errorHelpers,
  formatQuery,
  IQueryParams,
  routes,
  setSEOMetatags,
  swapRouteParams,
  toastError,
  generateEmptyCards,
} from '../../helpers';
import { uiConstants } from '../../constants';
import { updateGeolocation } from '../../actions/geolocation';
import { IGeolocationState } from '../../reducers/geolocation';
import { IAppState } from '../../store';
import axios from 'axios';
import DiscoverCard from '../../components/DiscoverCard/DiscoverCard';
import { createToast } from '../../actions/toaster';
import { IHorizontalScrollerRenderProps } from '../../components/shared/HorizontalScroller/HorizontalScroller';
import { IUserState } from '../../reducers/user';
import LandingDiscoverHeader from '../../components/LandingDiscoverHeader/LandingDiscoverHeader';
import { userSelectors } from '../../selectors/user';
import { localizeHelpers } from '../../localizeHelpers';
import { getUserGroups } from '../../actions/user';
import { getCurrentUserGroupRole } from '../../actions/group';
import useToastDispatcher from '../../hooks/useToaster';
import { Constants } from '@gigit/constants';
import { userRequestActions } from '../../requestActions';
import DiscoverPagesFilter from './DiscoverPagesFilter/DiscoverPagesFilter';
import { IPopupMenuItem } from '../../components/shared/PopupMenu/PopupMenu';
import CardLoader from '../../components/shared/CardLoader/CardLoader';
import { ECardLoaderStyleType } from '../../components/shared/CardLoader/CardLoaderItem/CardLoaderItem';
import { IDiscoverFilters } from '@gigit/interfaces';
import { Config } from '@gigit/config';
import {
  CardCommonGroup,
  EStyleTypeCardCommonGroup,
  EViewTypeCardCommonGroup,
} from '../../components/CardCommonGroup/CardCommonGroup';
import { CardCommonGroupSkeleton } from '../../components/CardCommonGroup/CardCommonGroupSkeleton';

interface FilterItem {
  icon: string;
  title: string;
  onFilter: () => void;
}

interface IProps extends RouteComponentProps {
  userState: IUserState;
}

const DiscoverGroups: FC<IProps> = (props: IProps) => {
  const geolocationState: IGeolocationState = useSelector(
    (state: IAppState) => state.geolocationState,
  );
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const dispatch = useDispatch();
  const loadDataLimit = 6;
  const [groupList, setGroupList] = useState<any[]>([]);
  const [activeFilter, setActiveFilter] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [lastResponseCount, setLastResponseCount] = useState<number>(6);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [activeSortBy, setActiveSortBy] = useState<{ label: string; value: string }>({
    label: 'Newest',
    value: uiConstants.discoverFilters.recent,
  });
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<string[]>([]);

  const sortByItems: IPopupMenuItem[] = [
    {
      id: uiConstants.discoverFilters.recent,
      label: 'Newest',
      icon: 'far fa-clock',
      isSelected: activeSortBy?.value === uiConstants.discoverFilters.recent,
      onClick: async () => {
        setActiveSortBy({ label: 'Newest', value: uiConstants.discoverFilters.recent });
      },
    },
    {
      id: uiConstants.discoverFilters.trending,
      label: 'Trending',
      icon: 'far fa-fire',
      isSelected: activeSortBy?.value === uiConstants.discoverFilters.trending,
      onClick: async () => {
        setActiveSortBy({ label: 'Trending', value: uiConstants.discoverFilters.trending });
      },
    },
  ];

  useEffect(() => {
    setSEOMetatags({
      title: localizeHelpers.translate('Discover Causes | Kambeo'),
      urlPath: 'discover/groups',
    });
    dispatch(updateGeolocation());
  }, []);

  useEffect(() => {
    getGroups();
  }, [selectedFocusAreas, activeSortBy.value]);

  async function toggleFocusArea(value: string) {
    if (selectedFocusAreas.indexOf(value) === -1) {
      setSelectedFocusAreas([...selectedFocusAreas, value]);
    } else {
      setSelectedFocusAreas(selectedFocusAreas.filter((fa) => fa !== value));
    }
  }

  const renderPageItem = (
    filter: FilterItem,
    index: number,
    scrollerProps: IHorizontalScrollerRenderProps,
  ) => {
    const onClick = () => {
      scrollerProps.moveItemIntoFocus(index);
      filter.onFilter();
      setActiveFilter(index);
    };

    return (
      <li
        key={index}
        className={combineClassNames(
          `page-item ${filter.icon}`,
          activeFilter === index ? 'fas selected' : 'far',
        )}
        onClick={onClick}
      >
        {filter.title}
      </li>
    );
  };

  let getGroups = async (loadMore = false) => {
    setDataLoading(true);
    let filterData: any = {
      start_date: '',
    };
    let query = {} as IQueryParams;
    switch (activeSortBy.value) {
      case uiConstants.discoverFilters.trending:
        filterData.trending = true;
        break;
      case uiConstants.discoverFilters.recent:
        query.sort = [{ id: 'created_at', order: 'desc' }];
        break;
    }
    const defaultCoords = uiConstants.defaultCoords;

    let location = {};
    let userLocation: string = '';

    if (props.userState && props.userState.locations[0]) {
      userLocation =
        props.userState.locations[0].city +
        ' ' +
        props.userState.locations[0].state +
        ', ' +
        props.userState.locations[0].country;
    }

    if (isUserLoggedIn && userLocation !== '') {
      try {
        await fetch(
          'https://maps.googleapis.com/maps/api/geocode/json?address=' +
            userLocation +
            '&key=' +
            Config.web.REACT_APP_GOOGLE_API_KEY,
        )
          .then((response) => response.json())
          .then((data) => {
            location = {
              coordinates: [
                data.results[0].geometry.location.lng,
                data.results[0].geometry.location.lat,
              ],
              max_distance: 100,
              distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
            };
          });
      } catch (error) {
        const errObj = errorHelpers.getErrorObject(error);
        toastError(errObj.translatedMessage, 'Google Maps');
      }
    } else if (geolocationState) {
      location = {
        coordinates: [geolocationState.lng, geolocationState.lat],
        max_distance: 100,
        distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
      };
    } else {
      location = {
        coordinates: [defaultCoords.lng, defaultCoords.lat],
        max_distance: 100,
        distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
      };
    }

    try {
      let _payload = {
        location,
        filter: filterData,
        common: {
          causes: selectedFocusAreas.length ? selectedFocusAreas : undefined,
        },
      } as IDiscoverFilters;

      let route = swapRouteParams(routes.DISCOVER_GROUPS, {
        limit: loadDataLimit,
        skip: loadMore ? skip + loadDataLimit : skip,
      });

      setSkip(loadMore ? skip + loadDataLimit : skip);

      if (query) {
        route += `&${formatQuery(query).toString()}`;
      }

      let response = await axios.post(route, _payload);

      if (loadMore) {
        setGroupList([...groupList, ...response.data]);
      } else {
        setGroupList(response.data);
      }

      setLastResponseCount(response.data.length);

      setDataLoading(false);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Discover Community Causes');
      dispatch(createToast(toast));
    }
  };

  async function doesUserFollowGroup(groupId: string): Promise<boolean> {
    if (user?.id) {
      try {
        const result = await userRequestActions.getUserGroupRole(groupId);

        return result?.user_capacity
          ? result?.user_capacity.includes(Constants.user_capacity.follower)
          : false;
      } catch (error) {
        dispatchToastError(error, 'Does User Follow Hub');
      }
    }
    return false;
  }

  async function toggleFollow(groupId: string): Promise<void> {
    if (isUserLoggedIn) {
      const isFollowing = await doesUserFollowGroup(groupId);

      if (!isFollowing) {
        try {
          await axios.post(swapRouteParams(routes.FOLLOW_GROUP, { groupId }));
          getUserGroups();
          getCurrentUserGroupRole(groupId);

          dispatchToastSuccess('Successfully followed group', 'Follow Group');
        } catch (error) {
          dispatchToastError(error, 'Follow Group');
        }
      } else {
        try {
          await axios.post(swapRouteParams(routes.UNFOLLOW_GROUP, { groupId }));
          getUserGroups();
          getCurrentUserGroupRole(groupId);

          dispatchToastSuccess('Successfully unfollowed group', 'Unfollow Group');
        } catch (error) {
          dispatchToastError(error, 'Unfollow Group');
        }
      }
    }
  }
  // NOTE: Set the Discover Events page to landing route, to be revisited when new landing page is implemented

  return (
    <React.Fragment>
      <div className="Discover">
        <LandingDiscoverHeader
          pathname={props.location.pathname}
          isUserLoggedIn={isUserLoggedIn}
        />
        <div className="discover-title">
          <div className="title">
            Explore Causes in{' '}
            <u>
              {props.userState.locations && props.userState.locations[0] && isUserLoggedIn
                ? props.userState.locations[0].city +
                  ', ' +
                  props.userState.locations[0].state?.substring(0, 2).toUpperCase()
                : geolocationState.locality}
            </u>
          </div>
          <div className="mob-title">
            Explore Causes in{' '}
            <u>
              {props.userState.locations && props.userState.locations[0] && isUserLoggedIn
                ? props.userState.locations[0].city +
                  ', ' +
                  props.userState.locations[0].state?.substring(0, 2).toUpperCase()
                : geolocationState.locality}
            </u>
          </div>
        </div>
        <div className="discover-filters">
          <DiscoverPagesFilter
            sortByItems={sortByItems}
            activeSortBy={activeSortBy}
            selectedFocusAreas={selectedFocusAreas}
            toggleFocusArea={toggleFocusArea}
          />
        </div>
        <div className={groupList.length === 0 ? 'discover-content empty' : 'discover-content'}>
          {!dataLoading && groupList.length > 0 && <div className="cards-background" />}

          {
            <div className="discover-list">
              {!dataLoading && groupList.length > 0 && (
                <>
                  {groupList.map((group, index) => {
                    return (
                      <React.Fragment key={index}>
                        <CardCommonGroup
                          group={group}
                          viewType={EViewTypeCardCommonGroup.GRID}
                          styleType={EStyleTypeCardCommonGroup.DISCOVER}
                        />
                      </React.Fragment>
                    );
                  })}

                  {groupList.length % 3 !== 0 &&
                    generateEmptyCards(3 - (groupList.length % 3), 'group')}

                  {lastResponseCount === loadDataLimit && (
                    <div className="load-section">
                      <Button
                        text="Load More"
                        buttonClass="explore-button"
                        type="button"
                        onClick={async () => {
                          await getGroups(true);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              {dataLoading && (
                <React.Fragment>
                  <CardCommonGroupSkeleton
                    styleType={EStyleTypeCardCommonGroup.DISCOVER}
                    viewType={EViewTypeCardCommonGroup.GRID}
                  />
                  <CardCommonGroupSkeleton
                    styleType={EStyleTypeCardCommonGroup.DISCOVER}
                    viewType={EViewTypeCardCommonGroup.GRID}
                  />
                  <CardCommonGroupSkeleton
                    styleType={EStyleTypeCardCommonGroup.DISCOVER}
                    viewType={EViewTypeCardCommonGroup.GRID}
                  />
                </React.Fragment>
              )}

              {/* {
                                dataLoading &&
                                <CardLoader
                                    loaderItemClass='group-loader-card-item'
                                    loading={dataLoading}
                                    numberOfRows={1}
                                    numberOfCols={3}
                                    styleTypes={[
                                        ECardLoaderStyleType.COVER_IMG,
                                        ECardLoaderStyleType.PROFILE_IMG,
                                        ECardLoaderStyleType.SHORT_TEXT,
                                        ECardLoaderStyleType.TWO_BUTTONS,
                                    ]}
                                />
                            } */}
            </div>
          }

          {!dataLoading && groupList.length === 0 && (
            <div className="discover-list">{generateEmptyCards(3, 'group')}</div>
          )}
        </div>
        <div className="discover-shortcuts-list">
          <div className="discover-shortcut-item alt light">
            <div className="discover-shortcut">
              <div className="shortcut-text">
                <div className="shortcut-title">Get your organization on Kambeo</div>
                <div className="shortcut-description">
                  Kambeo helps organizations raise funds from their community.
                </div>
                <div className="shortcut-link">
                  <Link
                    to={`${
                      isUserLoggedIn ? '/onboarding/group' : '/register?redirect=/onboarding/group'
                    }`}
                  >
                    Create a Cause
                  </Link>
                  <Link
                    to={`${
                      isUserLoggedIn ? '/onboarding/group' : '/register?redirect=/onboarding/group'
                    }`}
                  >
                    Claim your Charity
                  </Link>
                </div>
              </div>
              <div className="shortcut-image">
                <img src={CreateGroup} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (store: IAppState) => {
  return { userState: store.userState };
};

export default connect(mapStateToProps)(DiscoverGroups);
