import React, { useState, useEffect, ReactElement, useRef } from 'react';
import './CommunityFeedPost.scss';
import { Link } from 'react-router-dom';
import {
  IActivityPostFE,
  ICause,
  IEmbeddedGroupDetail,
  IEmbeddedGigDetail,
  IEmbeddedEventDetail,
  IEmbeddedUser,
  IActivityFeedCommentSummaryFE,
  IStoryResultFE,
  IStoryWithContentFE,
  IEmbeddedHub,
  IUser,
  IGroup,
  IEventTeam,
  IEventSummaryFE,
} from '@gigit/interfaces';
import {
  groupRequestActions,
  communityFeedRequestActions,
  eventRequestActions,
  gigRequestActions,
} from '../../../../../requestActions';
import useToastDispatcher from '../../../../../hooks/useToaster';
import CommunityFeedPostComments from '../CommunityFeedPostComments/CommunityFeedPostComments';
import TextField from '../../../../TextField/TextField';
import { Constants } from '@gigit/constants';
import { LocaleDateFormats, localizeHelpers } from '../../../../../localizeHelpers';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { userSelectors } from '../../../../../selectors/user';
import {
  HorizontalScroller,
  IHorizontalScrollerRenderProps,
} from '../../../../shared/HorizontalScroller/HorizontalScroller';
import Modal from '../../../../Modal/Modal';
import typeHelpers, { OwnerType } from '../../../../../helpers/typeHelpers';
import { IOwnerObject } from '../../../../../interfaces';
import { Prompt } from '../../../../Prompt/Prompt';
import { stripHtml } from '../../../../../helpers';
import { IThemeStyleObjectsByType } from '../../../../Hub/Hub';
import { storyRequestActions } from '../../../../../requestActions/Stories';
import KambeoShare from '../../../../../assets/share-icons/kambeo.png';

interface IProps {
  owner?: {
    object_type: string;
    object_id: string;
    title: string;
    handle: string;
    description?: string;
    profile_image_url?: string;
    cover_image_url?: string;
  };
  keyValue: string;
  initPost: Omit<IActivityPostFE, 'feed_events' | 'comments'>;
  event?: IEmbeddedEventDetail;
  group?: IEmbeddedGroupDetail;
  gig?: IEmbeddedGigDetail;
  hub?: IEmbeddedHub;
  story?: IStoryWithContentFE;
  team?: IEventTeam;
  comment?: IActivityFeedCommentSummaryFE;
  causes: ICause[];
  loggedInUser: IUser;
  createdFeedUserData: IEmbeddedUser;
  postUserData: IEmbeddedUser;
  feedEventType: string; // Constants.activity_feed_events
  postType: string; // Constants.activity_feed_post_types
  createdAt?: string;
  removePostFromArray?(post: Omit<IActivityPostFE, 'feed_events' | 'comments'>): void;
  removeGroupPosts?(group: IEmbeddedGroupDetail): void;
  setActiveStory?(story: IStoryResultFE): void;
  theme?: IThemeStyleObjectsByType;
}

const CommunityFeedPost: React.FC<IProps> = (props) => {
  const [post, setPost] = useState<Omit<IActivityPostFE, 'feed_events' | 'comments'>>(
    props.initPost,
  );
  const [postComments, setPostComments] = useState<IActivityFeedCommentSummaryFE[]>([]);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showReplyCommentModal, setShowReplyCommentModal] = useState<boolean>(false);
  const [showArchivedTypeModal, setShowArchivedTypeModal] = useState<boolean>(false);
  const [commentValue, setCommentValue] = useState<string>('');
  const [replyTo, setReplyTo] = useState<{ commentId: string; user_display_name: string } | null>(
    null,
  );
  const [activeObject, setActiveObject] = useState<
    IEmbeddedGroupDetail | IEmbeddedGigDetail | IEmbeddedEventDetail
  >();
  const [activeObjectHub, setActiveObjectHub] = useState<IEmbeddedHub>();
  const [activeObjectStory, setActiveObjectStory] = useState<IStoryResultFE>();
  const [activeObjectName, setActiveObjectName] = useState<string>();
  const [typeIcon, setTypeIcon] = useState<React.ReactNode>(null);
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [ownerObject, setOwnerObject] = useState<IOwnerObject | null>(null);
  const [showOptionsModal, setShowOptionsModal] = useState<boolean>(false);
  const [currentOptionModalType, setCurrentOptionModalType] = useState<string>('');
  const [eventGroup, setEventGroup] = useState<IGroup>();
  const [teamEvent, setTeamEvent] = useState<IEventSummaryFE | undefined>(undefined);
  const [showMoreDescription, setShowMoreDescription] = useState<boolean>(false);
  const [activeGigOwner, setActiveGigOwner] = useState<IGroup | IEventSummaryFE | undefined>(
    undefined,
  );
  const [activeGigOwnerType, setActiveGigOwnerType] = useState<string>('');
  const [eventOrganizerName, setEventOrganizerName] = useState<string | null>(null);

  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const commentTextFieldRef = useRef<HTMLInputElement>(null);

  const getPostComments = async (): Promise<void> => {
    try {
      const result = await communityFeedRequestActions.getPostComments(post.id);
      setPostComments(result);
    } catch (error) {
      dispatchToastError(error, 'Get Post Comments');
    }
  };

  const getStory = async (): Promise<void> => {
    if (props?.story?.created_by?.id) {
      try {
        const result = await storyRequestActions.getStoryForEntity(
          Constants.object_type.user,
          props.story.created_by.id,
        );
        setActiveObjectStory(result);
      } catch (error) {
        dispatchToastError(error, 'Get Story');
      }
    }
  };

  const getGroup = async (eventId: string): Promise<void> => {
    try {
      const event = await eventRequestActions.getEventByHandleOrId(eventId);
      setEventGroup(event.group as IGroup);
    } catch (error) {
      dispatchToastError(error, 'Get Event Group');
    }
  };

  const getGigGroup = async (gigId: string): Promise<void> => {
    try {
      const gig = await gigRequestActions.getGigByHandle(`${gigId}`);
      gig.event_id
        ? setActiveGigOwnerType(Constants.object_type.event)
        : setActiveGigOwnerType(Constants.object_type.group);

      const gigOwner = gig.event_id
        ? await eventRequestActions.getEventByHandleOrId(`${gig.event_id}`)
        : await groupRequestActions.getGroupByHandleOrId(`${gig.group_id}`);
      setActiveGigOwner(gigOwner);
    } catch (error) {
      dispatchToastError(error, 'Get Gig Owner');
    }
  };

  const checkEventOrganizer = async (eventId: string) => {
    try {
      const event = await eventRequestActions.getEventByHandleOrId(eventId);
      setEventOrganizerName(event?.event_organizer_name ?? null);
    } catch (error) {
      dispatchToastError(error, 'Check event organizer');
    }
  };

  const getTeamEvent = async (eventId: string, callBack?: (event: IEventSummaryFE) => void) => {
    try {
      const event = await eventRequestActions.getEventByHandleOrId(eventId);
      setTeamEvent(event);
      if (callBack) {
        callBack(event);
      }
    } catch (error) {
      dispatchToastError(error, 'Get Team Event');
    }
  };

  useEffect(() => {
    if (post.object_type) {
      switch (post.object_type) {
        case Constants.object_type.group:
          setActiveObject(props.group);
          setActiveObjectName('Cause');
          setTypeIcon(<i className="fas fa-hands-heart" />);
          if (props.group?.handle) {
            setShareUrl(`/group/${props.group.handle}`);
            setOwnerObject(typeHelpers.createOwnerObject('group', props.group));
          }
          break;
        case Constants.object_type.event:
          setActiveObject(props.event);
          setActiveObjectName('Event');
          setTypeIcon(<i className="fas fa-calendar-star" />);
          if (props.event?.handle) {
            setShareUrl(`/event/${props.event?.handle}`);
            setOwnerObject(typeHelpers.createOwnerObject('event', props.event));
          }
          if (post.type === Constants.activity_feed_post_types.on_fundraiser_signup) {
            setEventOrganizerName(post?.created_by?.display_name ?? null);
          } else {
            checkEventOrganizer(props.event?.id || '');
          }
          getGroup(props.event?.id || '');
          break;
        case Constants.object_type.gig:
          setActiveObject(props.gig);
          setActiveObjectName('Volunteer Opportunity');
          setTypeIcon(<i className="fas fa-hands-helping" />);
          if (props.gig?.handle) {
            setShareUrl(`/gig/${props.gig.handle}`);
            setOwnerObject(typeHelpers.createOwnerObject('gig', props.gig));
            getGigGroup(props.gig?.handle || '');
          }
          break;
        case Constants.object_type.hub:
          if (props?.hub) {
            setActiveObjectHub(props.hub);
            setOwnerObject(typeHelpers.createOwnerObject('hub', props.hub));
          }
          setActiveObjectName('Company');
          setTypeIcon(<i className="fas fa-hands-heart" />);
          if (props?.hub?.handle) {
            setShareUrl(`/company/${props.hub.handle}`);
          }
          break;
        case Constants.object_type.story:
          if (props?.story) {
            getStory();

            if (props?.owner) {
              setOwnerObject(
                typeHelpers.createOwnerObject(props.story.owner_type as OwnerType, props.owner),
              );
            }
          }
          setActiveObjectName('Story');
          setTypeIcon(<i className="fad fa-rectangle-portrait" />);
          break;

        case Constants.object_type.team:
          getTeamEvent(props.team?.event_id || '', (event) => {
            setActiveObject(event as IEmbeddedEventDetail);
            setActiveObjectName('Event');
            setTypeIcon(<i className="fas fa-calendar-star" />);
            if (event?.handle) {
              setShareUrl(`/event/${event?.handle}`);
              setOwnerObject(typeHelpers.createOwnerObject('event', event));
            }
          });
          break;
      }
    }
  }, [post]);

  function isAlreadyLiked() {
    return (
      post?.liked_user_ids &&
      post.liked_user_ids.find((id) => id === props?.loggedInUser?.id) !== undefined
    );
  }

  function isPostCommented() {
    return (
      post?.comment_user_ids &&
      post.comment_user_ids.find((id) => id === props?.loggedInUser?.id) !== undefined
    );
  }

  async function toggleLikePost() {
    if (isAlreadyLiked()) {
      try {
        const result = await communityFeedRequestActions.unlikePost(post.id);
        setPost(result);
      } catch (error) {
        dispatchToastError(error, 'Error Unlike Post');
      }
    } else {
      try {
        const result = await communityFeedRequestActions.likePost(post.id);
        setPost(result);
      } catch (error) {
        dispatchToastError(error, 'Error Like Post');
      }
    }
  }

  const sharePost = (): void => {
    setShowShareModal(true);
  };

  const savePost = (): void => {};

  const turnOnNotifications = (): void => {};

  const hidePost = (): void => {
    communityFeedRequestActions
      .hidePost(post.id)
      .then(() => {
        props.removePostFromArray && props.removePostFromArray(post);
      })
      .catch((error) => dispatchToastError(error, 'Error Hide Post'));
  };

  /**
   * @description Unfollow group
   */
  const unfollow = (): void => {
    if (props.group?.id && post.object_type === Constants.object_type.group) {
      groupRequestActions
        .unfollowGroup(props.group.id)
        .then((res) => {
          props.removeGroupPosts && props.removeGroupPosts(props.group!);
        })
        .catch((error) => dispatchToastError(error, 'Error Unfollow Cause'));
    }
  };

  const addComment = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.keyCode === 13 || e?.which === 13) {
      if (replyTo) {
        try {
          const res = await communityFeedRequestActions.commentOnPostParentComment(
            post.id,
            replyTo.commentId,
            { content: commentValue },
          );
          await getPostComments();
          setPost(res);
        } catch (error) {
          dispatchToastError(error, 'Error Post Comment on Parent');
        }
      } else {
        try {
          const res = await communityFeedRequestActions.commentOnPost(post.id, {
            content: commentValue,
          });
          await getPostComments();
          setPost(res);
        } catch (error) {
          dispatchToastError(error, 'Error Post Comment');
        }
      }

      setCommentValue('');
      setReplyTo(null);
    }
  };

  const handleSharePost = async (postId: string) => {
    setShowShareModal(false);

    try {
      await communityFeedRequestActions.sharePost(postId);

      dispatchToastSuccess('Successfully shared post', 'Share post');
    } catch (error) {
      dispatchToastError(error, 'Share Post Error');
    }
  };

  const handleConfirmAction = () => {
    currentOptionModalType === 'hide' ? hidePost() : unfollow();
    setShowOptionsModal(false);
  };

  const renderCauses = (scrollerProps: IHorizontalScrollerRenderProps): ReactElement[] => {
    let tmpCausesIds: string[] = activeObject?.causes || [];
    let causesNames: string[] = [];

    for (let cause of props.causes) {
      tmpCausesIds.forEach((tmpCauseId) => {
        if (cause.id === tmpCauseId) {
          causesNames.push(cause.cause);
        }
      });
    }

    return causesNames.map((tag, index) => (
      <span
        key={index}
        className={`tag ${tag === 'new' ? 'active ' : ''}`}
      >
        {tag}
      </span>
    ));
  };

  const renderUserLikedAction = (): React.ReactNode => {
    return (
      <>
        <p>
          <i className="fas fa-thumbs-up" />
          <Link to={`/user/${props.createdFeedUserData?.handle}`}>
            <var data-var="user_display_name">{props.createdFeedUserData?.display_name}</var>
          </Link>
          {` liked a post.`}
        </p>
      </>
    );
  };

  const renderUserSharedAction = (): React.ReactNode => {
    return (
      <>
        <p>
          <i className="fas fa-share" />
          <Link to={`/user/${props.postUserData?.handle}`}>
            <var data-var="user_display_name">{props.postUserData?.display_name}</var>
          </Link>
          {` shared a post.`}
        </p>
        <p className="share-content">{post.content}</p>
      </>
    );
  };

  const renderUserCommentedAction = (): React.ReactNode => {
    return (
      <>
        <p>
          <i className="fas fa-comment-alt-lines" />
          <Link to={`/user/${props.createdFeedUserData?.handle}`}>
            <var data-var="user_display_name"> {props.createdFeedUserData?.display_name} </var>
          </Link>
          {` commented on a post.`}
        </p>
      </>
    );
  };

  const renderTriggeredByAction = (): React.ReactNode => {
    switch (props.feedEventType) {
      case Constants.activity_feed_events.user_liked:
        return renderUserLikedAction();
      case Constants.activity_feed_events.user_shared:
        return renderUserSharedAction();
      case Constants.activity_feed_events.user_commented:
        return renderUserCommentedAction();
    }
  };

  const renderCommentTextField = () => (
    <TextField
      reference={commentTextFieldRef}
      value={commentValue}
      name="comment-value"
      translatePlaceholder={true}
      placeholder="Type a new message"
      preTextRenderer={() => {
        return replyTo ? (
          <span className="reply-to">
            Reply to: <var data-var="reply_to_user_name">{replyTo.user_display_name}</var>
          </span>
        ) : null;
      }}
      type="text"
      onChange={(e) => {
        setCommentValue(e.target.value);
      }}
      onKeyPress={async (e) => {
        await addComment(e);
      }}
    />
  );

  const renderMenuComponent = () => (
    <>
      <span
        className="dots"
        onClick={() => {
          setShowMenu((v) => !v);
        }}
      >
        <i className="fas fa-ellipsis-v" />
      </span>
      <ul className={`menu ${showMenu ? 'show' : ''}`}>
        {/* <li className="item" onClick={()=>{savePost()}}>
                <i className="fas fa-bookmark"/> Save Post
            </li>
            <li className="item" onClick={()=>{turnOnNotifications()}}>
                <i className="fas fa-bell"/> Turn On Post Notifications
            </li> */}
        {/* <li className="hr"/> */}
        <li
          className="item"
          onClick={() => {
            setShowOptionsModal(true);
            setCurrentOptionModalType('hide');
          }}
        >
          <i className="fas fa-times-circle" /> Hide Post
        </li>
        <li
          className="item"
          onClick={() => {
            setShowOptionsModal(true);
            setCurrentOptionModalType('unfollow');
          }}
        >
          <i className="fas fa-minus-circle" /> Unfollow{' '}
          <var data-var="display_name">{activeObject?.title}</var>
        </li>
      </ul>
    </>
  );

  const renderCommentAtBottom = () => {
    return props.comment ? (
      <>
        <div className="show-comment at-bottom">
          <CommunityFeedPostComments
            comments={[props.comment]}
            locale={locale}
            setReplyTo={setReplyTo}
            shortPreviewMode={true}
            setShowReplyCommentModal={async () => {
              await getPostComments();
              setShowReplyCommentModal(true);
            }}
            focusOnInput={() => commentTextFieldRef?.current?.focus()}
          />
        </div>
      </>
    ) : null;
  };

  const renderPostHeader = (showMenuComponent = true) => (
    <>
      <div className="heading-post">
        <div className="min-container">
          {activeObjectName !== 'Company' && (
            <Link
              to={
                activeObject?.status_code !== 'archived'
                  ? `/${post.object_type}/${activeObject?.handle}`
                  : ''
              }
              onClick={() =>
                activeObject?.status_code === 'archived' && setShowArchivedTypeModal(true)
              }
            >
              {activeObject?.profile_image_url && (
                <img
                  src={activeObject.profile_image_url || ''}
                  alt="profile avatar"
                  className="logo"
                />
              )}

              {!activeObject?.profile_image_url && (
                <span className="user-avatar">
                  <i className="fas fa-user" />
                </span>
              )}
            </Link>
          )}

          {activeObjectName === 'Company' && (
            <Link
              to={
                activeObjectHub?.status_code !== 'archived'
                  ? `/company/${activeObjectHub?.handle}`
                  : ''
              }
              onClick={() =>
                activeObjectHub?.status_code === 'archived' && setShowArchivedTypeModal(true)
              }
            >
              {activeObjectHub?.profile_image_url && (
                <img
                  src={activeObjectHub.profile_image_url || ''}
                  alt="profile avatar"
                  className="logo"
                />
              )}

              {!activeObjectHub?.profile_image_url && (
                <span className="user-avatar">
                  <i className="fas fa-user" />
                </span>
              )}
            </Link>
          )}

          <div
            className="owner-info-container"
            notranslate="yes"
          >
            {activeObjectName === 'Story' && (
              <h5>
                <Link to={`/user/${props.story?.created_by?.handle}`}>
                  {props.story?.created_by?.display_name || props.story?.created_by?.handle}
                </Link>
              </h5>
            )}

            {activeObjectName !== 'Company' && (
              <h5>
                <Link
                  to={
                    activeObject?.status_code !== 'archived'
                      ? `/${post.object_type}/${activeObject?.handle}`
                      : ''
                  }
                  onClick={() =>
                    activeObject?.status_code === 'archived' && setShowArchivedTypeModal(true)
                  }
                >
                  {activeObject?.title}
                </Link>
              </h5>
            )}

            {activeObjectName === 'Company' && (
              <h5>
                <Link
                  to={
                    activeObjectHub?.status_code !== 'archived'
                      ? `/company/${activeObjectHub?.handle}`
                      : ''
                  }
                  onClick={() =>
                    activeObjectHub?.status_code === 'archived' && setShowArchivedTypeModal(true)
                  }
                >
                  {activeObjectHub?.title}
                </Link>
              </h5>
            )}

            {props?.event && props?.event?.event_type === Constants.event_type.obo_fundraiser && (
              <span>
                on behalf of
                <b>
                  <Link
                    to={`/group/${eventGroup?.id}`}
                    style={{ textDecoration: 'none', color: '#6B64AE' }}
                  >
                    {eventGroup?.title}
                  </Link>
                </b>
              </span>
            )}

            {props?.createdAt && (
              <span>
                <var data-var="created-at">
                  {localizeHelpers.formatDate(props.createdAt, LocaleDateFormats.LL, locale)}
                </var>
              </span>
            )}
          </div>
        </div>
        <div className="min-container">
          <span className="type">
            {typeIcon}
            {activeObjectName}
          </span>
          {showMenuComponent && renderMenuComponent()}
        </div>
      </div>
    </>
  );

  const getMainPostContentTypeSentence = () => {
    switch (post.type) {
      case Constants.activity_feed_post_types.on_page_published:
        return post.object_type === Constants.object_type.gig
          ? ' posted a new Volunteer Opportunity '
          : post.object_type === Constants.object_type.event
            ? ' created a new event '
            : ' created ';
      case Constants.activity_feed_post_types.on_fundraiser_signup:
        return ' signed up as a fundraiser for ';
      case Constants.activity_feed_post_types.on_fundraiser_signup_team:
        return ' created a team page ';
      case Constants.activity_feed_post_types.story_created:
        return ' posted a ';
    }
  };

  const renderMainPostContent = () => (
    <>
      {activeObjectName !== 'Company' && activeObjectName !== 'Story' && (
        <>
          <p notranslate="yes">
            {eventOrganizerName ? (
              <span notranslate="yes">{eventOrganizerName}</span>
            ) : (
              <Link
                to={
                  post.object_type === Constants.object_type.gig
                    ? `/${activeGigOwnerType === Constants.object_type.group ? Constants.object_type.group : Constants.object_type.event}/${activeGigOwner?.handle}`
                    : post.object_type === Constants.object_type.event
                      ? `/group/${eventGroup?.handle}`
                      : `/user/${props.postUserData?.handle}`
                }
                // style={props.theme?.secondaryText} // issue due to same color as a background-color
              >
                <var data-var="user_display_name">
                  {post.object_type === Constants.object_type.gig
                    ? activeGigOwner?.title
                    : post.object_type === Constants.object_type.event
                      ? eventGroup?.title
                      : props.postUserData?.display_name}
                </var>
              </Link>
            )}
            {localizeHelpers.translate(getMainPostContentTypeSentence())}
            {post.type !== Constants.activity_feed_post_types.on_fundraiser_signup_team && (
              <Link
                to={`${activeObject?.status_code !== 'archived' ? `/${post.object_type}/${activeObject?.handle}` : ''}`}
                onClick={() =>
                  activeObject?.status_code === 'archived' && setShowArchivedTypeModal(true)
                }
              >
                <var data-var="title">{activeObject?.title}</var>
              </Link>
            )}
            {post.type === Constants.activity_feed_post_types.on_fundraiser_signup &&
              localizeHelpers.translate(
                `. Support them by visiting their individual fundraising page `,
              )}
            {post.type === Constants.activity_feed_post_types.on_fundraiser_signup && (
              <Link
                to={`/event/${props.event?.handle}/individual/${post.created_by?.handle}`}
                style={{ textDecoration: 'none', color: '#6B64AE' }}
              >
                here.
              </Link>
            )}

            {post.type === Constants.activity_feed_post_types.on_fundraiser_signup_team && (
              <Link
                to={`/event/${teamEvent?.handle}/team/${props.team?.handle}`}
                style={{ textDecoration: 'none', color: '#6B64AE' }}
              >
                <var data-var="team_name">{props.team?.name}</var>{' '}
              </Link>
            )}
            {post.type === Constants.activity_feed_post_types.on_fundraiser_signup_team && (
              <>
                {localizeHelpers.translate(` for `)}
                <Link
                  to={`/event/${teamEvent?.handle}`}
                  style={{ textDecoration: 'none', color: '#6B64AE' }}
                >
                  <var data-var="event_title">{teamEvent?.title || 'Event'}</var>
                </Link>
                {localizeHelpers.translate(` Visit their team page to join or support them!`)}
              </>
            )}
          </p>
          {activeObject?.description && (
            <p notranslate="yes">
              {stripHtml(
                showMoreDescription
                  ? activeObject.description
                  : activeObject.description.substring(0, 300) || '',
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {activeObject.description.length > 300 && (
                <span
                  className="view-more-description"
                  onClick={() => setShowMoreDescription(!showMoreDescription)}
                >
                  {showMoreDescription ? 'View Less' : 'View More'}
                </span>
              )}
            </p>
          )}
          {!activeObject?.description && <p>&nbsp;</p>}
        </>
      )}

      {activeObjectName === 'Company' && (
        <>
          <p>
            <Link
              to={`/user/${props.postUserData?.handle}`}
              // style={props.theme?.secondaryText} // issue due to same color as a background-color
            >
              <var data-var="user_display_name">{props.postUserData?.display_name}</var>
            </Link>
            {localizeHelpers.translate(getMainPostContentTypeSentence())}
            <Link
              to={
                activeObjectHub?.status_code !== 'archived'
                  ? `/company/${activeObjectHub?.handle}`
                  : ''
              }
              onClick={() =>
                activeObjectHub?.status_code === 'archived' && setShowArchivedTypeModal(true)
              }
            >
              <var data-var="title">{activeObjectHub?.title || 'a Company'}</var>
            </Link>
          </p>
          {activeObjectHub?.description && (
            <p notranslate="yes">{stripHtml(activeObjectHub.description || '')}</p>
          )}

          {!activeObjectHub?.description && <p>&nbsp;</p>}
        </>
      )}

      {activeObjectName === 'Story' && (
        <>
          <p>
            <Link
              to={`/user/${props.postUserData?.handle}`}
              //style={props.theme?.secondaryText} // on hub, issue if color is white
            >
              <var data-var="user_display_name">{props.postUserData?.display_name}</var>
            </Link>
            {localizeHelpers.translate(getMainPostContentTypeSentence())}
            <span
              className="story"
              onClick={() => {
                if (activeObjectStory && props?.story) {
                  props?.setActiveStory?.(activeObjectStory);
                } else {
                  dispatchToastError(
                    localizeHelpers.translate('This story has expired and can no longer be viewed'),
                    'Expired Story',
                  );
                }
              }}
              title={!props?.story ? 'Expired' : undefined}
            >
              Story
            </span>
            .
          </p>
          <p>&nbsp;</p>
        </>
      )}
    </>
  );

  return (
    <>
      <article
        className={`CommunityFeedPost ${props?.story ? 'story' : ''}`}
        key={props.keyValue}
      >
        {Constants.activity_feed_events.post_created !== props.feedEventType && (
          <div className="triggered-by-action">{renderTriggeredByAction()}</div>
        )}

        {Constants.activity_feed_post_types.object_shared === props.postType && (
          <div className="triggered-by-action">{renderUserSharedAction()}</div>
        )}

        {renderPostHeader()}
        <div className="content">
          {post.type !== Constants.activity_feed_post_types.object_shared &&
            renderMainPostContent()}
          {activeObject?.cover_image_url && activeObjectName !== 'Company' && (
            <img
              src={activeObject.cover_image_url}
              alt="content"
              className="content-img"
            />
          )}

          {activeObjectHub?.cover_image_url && activeObjectName === 'Company' && (
            <img
              src={activeObjectHub.cover_image_url}
              alt="content"
              className="content-img"
            />
          )}
        </div>

        <div className="tags-container">
          <HorizontalScroller>{(scrollerProps) => renderCauses(scrollerProps)}</HorizontalScroller>
        </div>

        {renderCommentAtBottom()}

        {!props?.story && (
          <div className="social-container">
            <div className="social-buttons">
              <span
                onClick={async () => {
                  await toggleLikePost();
                }}
                className={`like ${isAlreadyLiked() ? 'liked' : ''}`}
                // style={isAlreadyLiked() ? props.theme?.secondaryText : {}} // issue due to same color as a background-color
              >
                <i className={`${isAlreadyLiked() ? 'fas' : 'far'} fa-thumbs-up`} />
                <var data-var="like_count">{post.liked_count}</var>
                <span className="txt">Likes</span>
              </span>
              <span
                onClick={async () => {
                  await getPostComments();
                  setShowReplyCommentModal(true);
                  setReplyTo(null);
                  commentTextFieldRef?.current?.focus();
                }}
                className={`comment ${isPostCommented() ? 'commented' : ''}`}
                // style={isPostCommented() ? props.theme?.secondaryText : {}} // issue due to same color as a background-color
              >
                <i className={`${isPostCommented() ? 'fas' : 'fal'} fa-comment-alt-lines`} />
                <var data-var="comment_count">{post.comment_count}</var>
                <span className="txt">Comments</span>
              </span>
              <span
                onClick={() => {
                  sharePost();
                }}
              >
                <i className="fal fa-share" /> Share
              </span>
            </div>
          </div>
        )}

        <Modal
          contentClassName="CommunityFeedPost modal"
          show={showReplyCommentModal}
        >
          <div className="modal-back-section">
            <span
              onClick={() => {
                setShowReplyCommentModal(false);
                setReplyTo(null);
              }}
            >
              <i className="fas fa-chevron-left" /> Post
            </span>
          </div>
          {renderPostHeader(false)}
          <div className="post-content">{renderMainPostContent()}</div>
          <div className="comment-input-container">{renderCommentTextField()}</div>
          <div className="social-container">
            <div className="comments-container">
              <CommunityFeedPostComments
                comments={postComments}
                locale={locale}
                setReplyTo={setReplyTo}
                focusOnInput={() => commentTextFieldRef?.current?.focus()}
              />
            </div>
          </div>
        </Modal>

        <Modal
          contentClassName="CommunityFeedPost modal archived"
          show={showArchivedTypeModal}
        >
          <div className="modal-back-section">
            <span
              onClick={() => {
                setShowArchivedTypeModal(false);
              }}
            >
              <i className="fas fa-chevron-left" /> Posts
            </span>
          </div>
          <p>{`The ${post.object_type} you are trying to view has been archived and can no longer be viewed`}</p>
        </Modal>

        <Prompt
          show={showOptionsModal}
          title={currentOptionModalType === 'hide' ? 'Hide post' : 'Unfollow group'}
          yesMessage="Yes"
          yesClass="fas fa-check"
          yesStyle="normal"
          cancelMessage="Cancel"
          message={
            currentOptionModalType === 'hide'
              ? 'Are you sure you want to hide post?'
              : 'Are you sure you want to unfollow ?'
          }
          onYes={() => handleConfirmAction()}
          onClose={() => setShowOptionsModal(false)}
        />
        <Prompt
          show={showOptionsModal}
          title={currentOptionModalType === 'hide' ? 'Hide post' : 'Unfollow group'}
          yesMessage="Yes"
          yesClass="fas fa-check"
          yesStyle="normal"
          cancelMessage="Cancel"
          message={
            currentOptionModalType === 'hide'
              ? 'Are you sure you want to hide post?'
              : 'Are you sure you want to unfollow ?'
          }
          onYes={() => handleConfirmAction()}
          onClose={() => setShowOptionsModal(false)}
        />

        {ownerObject && (
          <Modal
            contentClassName="share-modal"
            show={showShareModal}
            onClose={() => {
              setShowShareModal(false);
            }}
          >
            <div className="Share">
              <div className="share-head">Share this post</div>
              <div
                className="share-content"
                onClick={() => handleSharePost(post._id)}
              >
                <div className="share-type">
                  <img src={KambeoShare} />
                  <div className="share-label">on Kambeo</div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </article>
    </>
  );
};

export default CommunityFeedPost;
