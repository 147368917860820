import { formatCurrency } from '../../helpers';
import React, { Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { defaultCurrency } from '../../helpers';

import Portrait from '../Portrait/Portrait';

import './ContextMenu.scss';
import { IAppState } from '../../store';
import { userSelectors } from '../../selectors/user';
import { connect } from 'react-redux';

export interface IContextMenuItem {
  label: string;
  icon?: string;
  toggle?: boolean;
  link?: string;
  subMenu?: IContextMenuItem[];
  onClick?(e?: React.MouseEvent): void;
  class?: string;
  target?: string;
  profile_image_url?: string;
  owner_handle?: string;
  price?: number;
  quantity?: number;
  notranslate?: 'yes';
}

interface IProps {
  title?: string;
  onMouseLeave(e?: any): void;
  onClose?(): void;
  menuItems?: IContextMenuItem[];
  showMenu: boolean;
  cart?: number;
  currency?: string;
  locale: string;
  children?: ReactNode;
  className?: string;
  currentItemIndex?: number;
  totalItemCount?: number;
}

interface IState {
  menuTimeout: any;
  delayedShowMenu: boolean;
  showMenu: boolean;
  menuPositionTop?: boolean;
}

class ContextMenu extends React.Component<IProps, IState> {
  private readonly menuRef = React.createRef<HTMLUListElement>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      menuTimeout: null,
      delayedShowMenu: false,
      showMenu: this.props.showMenu,
    };

    this.startTimeout = this.startTimeout.bind(this);
    this.clearMenuTimeout = this.clearMenuTimeout.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.props.totalItemCount && this.props.currentItemIndex) {
      // Item count set to 5 arbitrarily with how the tables are placed in the viewport
      if (this.props.totalItemCount > 5) {
        if (this.props.currentItemIndex + 1 >= this.props.totalItemCount - 2) {
          this.setState({ menuPositionTop: true });
        }
      } else {
        this.setState({ menuPositionTop: false });
      }
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.showMenu !== this.props.showMenu) {
      this.setState({ showMenu: this.props.showMenu });
    }

    if (prevState.showMenu !== this.state.showMenu && this.state.showMenu) {
      this.setState({ delayedShowMenu: this.state.showMenu });
    }
    //We need to delay the menu close so the animation can play out before the component is unmounted
    if (prevState.showMenu !== this.state.showMenu && !this.state.showMenu) {
      this.setState({
        menuTimeout: setTimeout(() => {
          this.setState({
            delayedShowMenu: false,
          });
        }, 150),
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    if (this.props.showMenu && !this.menuRef?.current?.contains(event.target)) {
      this.props.onClose?.();
    }
  }

  startTimeout = () => {
    this.setState({
      menuTimeout: setTimeout(this.props.onMouseLeave, 150),
    });
  };

  clearMenuTimeout() {
    clearTimeout(this.state.menuTimeout);
  }

  render() {
    return (
      <Fragment>
        {(this.state.delayedShowMenu || this.state.showMenu) && (
          <div
            onMouseEnter={this.clearMenuTimeout}
            onMouseLeave={this.startTimeout}
            className={`${this.props.showMenu ? (this.props.title ? 'ContextMenu show' : 'ContextMenu no-title show') : 'ContextMenu hidden'} ${this.props?.className ? this.props.className : ''} ${this.props?.locale ? this.props.locale : ''} ${this.state.menuPositionTop ? 'top' : ''}`}
          >
            {this.props.cart === undefined && this.props.title && (
              <div className="title">{this.props.title}</div>
            )}
            {this.props.cart !== undefined && this.props.title && (
              <div className="title title-cart">
                <span>{this.props.title}</span>
                <span notranslate="yes">
                  {formatCurrency(
                    this.props.cart,
                    this.props.currency ?? defaultCurrency,
                    this.props.locale,
                  )}
                </span>
              </div>
            )}
            {this.props.children == null && (
              <ul
                ref={this.menuRef}
                className="ContextMenu-ul"
              >
                {this.props.menuItems?.map((item, index) => {
                  if (item.link !== undefined) {
                    return (
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          if (item.onClick !== undefined) {
                            item.onClick(e);
                          }
                        }}
                        className={item.class ? item.class + ' no-select' : 'no-select'}
                        key={index}
                        notranslate={item.notranslate}
                      >
                        {item.link[0] === '/' && (
                          <Link
                            target={item.target ? item.target : ''}
                            to={item.link}
                          >
                            {item.icon !== undefined && <i className={item.icon} />}
                            <span>{item.label}</span>
                          </Link>
                        )}
                        {item.link[0] !== '/' && (
                          <a
                            href={item.link}
                            target={item.target ? item.target : ''}
                          >
                            {item.icon !== undefined && <i className={item.icon} />}
                            <span>{item.label}</span>
                          </a>
                        )}
                      </li>
                    );
                  } else if (item.subMenu !== undefined) {
                    return (
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          if (item.onClick !== undefined) {
                            e.preventDefault();
                            item.onClick(e);
                          }
                        }}
                        className={
                          item.class
                            ? item.class + ' no-select sub-menu-wrap'
                            : 'no-select sub-menu-wrap'
                        }
                        key={index}
                        notranslate={item.notranslate}
                      >
                        <div className="sub-menu-parent">
                          {item.icon !== undefined && <i className={item.icon} />}
                          <span>{item.label}</span>
                        </div>
                        <div className="sub-menu">
                          <ul>
                            {item.subMenu.map((subMenuItem, index) => {
                              if (subMenuItem.link !== undefined) {
                                return (
                                  <li key={index}>
                                    <Link to={subMenuItem.link}>
                                      {subMenuItem.icon !== undefined && (
                                        <i className={subMenuItem.icon} />
                                      )}
                                      <span>
                                        <span>{subMenuItem.label}</span>
                                      </span>
                                    </Link>
                                  </li>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </ul>
                        </div>
                      </li>
                    );
                  } else if (item.class !== undefined && item.class === 'store-item') {
                    return (
                      <li
                        className={
                          item.class
                            ? item.class + ' no-select toggle-container'
                            : 'no-select toggle-container'
                        }
                        key={index}
                        notranslate={item.notranslate}
                      >
                        <Portrait
                          size={40}
                          currentImage={item.profile_image_url}
                        />
                        <div className="store-item-content">
                          <span notranslate="yes">{item.label}</span>
                          <span notranslate="yes">{item.owner_handle}</span>
                        </div>
                        <div className="store-item-price">
                          <span notranslate="yes">
                            {formatCurrency(
                              item.price || 0,
                              this.props.currency ?? defaultCurrency,
                              this.props.locale,
                            )}
                          </span>
                          {item.quantity && (
                            <span className="quantity">
                              Qty: <var data-var="item_quantity">{item.quantity}</var>
                            </span>
                          )}
                        </div>
                        {item.toggle !== undefined && (
                          <i className={item.toggle ? 'fad fa-toggle-on' : 'fad fa-toggle-off'} />
                        )}
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item.onClick) {
                              item.onClick(e);
                            }
                          }}
                          className="remove-item"
                        >
                          <i className="fal fa-times" />
                        </div>
                      </li>
                    );
                  } else {
                    return (
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          if (item.onClick !== undefined) {
                            item.onClick(e);
                          }
                        }}
                        className={
                          item.class
                            ? item.class + ' no-select toggle-container'
                            : 'no-select toggle-container'
                        }
                        key={index}
                        notranslate={item.notranslate}
                      >
                        {item.icon !== undefined && <i className={item.icon} />}
                        <span>{item.label}</span>
                        {item.toggle !== undefined && (
                          <i className={item.toggle ? 'fad fa-toggle-on' : 'fad fa-toggle-off'} />
                        )}
                      </li>
                    );
                  }
                })}
              </ul>
            )}
            {this.props.children && this.props.children}
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

export default connect(mapStateToProps)(ContextMenu);
