import { IConversationMessage, IConversationSummary } from '@gigit/interfaces';
import { Reducer } from 'redux';
import { ChatActions, ChatActionTypes } from '../actions/chat';

export interface IChatState {
  conversations: Array<IConversationSummary>;
  onlineUsers: Array<string>;
  isOpen: boolean;
  newMessage: IConversationMessage | null;
  composeMessageUsers: Array<string>;
}

export const initialChatState: IChatState = {
  conversations: [],
  onlineUsers: [],
  isOpen: false,
  newMessage: null,
  composeMessageUsers: [],
};

export const chatReducer: Reducer<IChatState, ChatActions> = (state = initialChatState, action) => {
  switch (action.type) {
    case ChatActionTypes.GET_CONVERSATIONS: {
      let newState = { ...state, ...action };

      return {
        ...newState,
        ...action,
      };
    }
    case ChatActionTypes.UPDATE_CONVERSATION_VISIBILITY: {
      let newState = { ...state };

      newState.conversations = newState.conversations.map((convo: any) => {
        if (convo.id === action.config.id) {
          convo.messages = action.config.messages;
          convo.isOpen = true;
        }

        return convo;
      });

      return {
        ...newState,
        ...action,
      };
    }
    case ChatActionTypes.SET_AS_READ: {
      return {
        ...state,
        conversations: state.conversations.map((convo) => {
          if (convo.id === action.conversation.id) {
            return {
              ...convo,
              read_states: convo?.read_states?.map((rs) => {
                if (rs.user_id === action.user_id) {
                  return {
                    ...rs,
                    read: true,
                    unread_count: 0,
                  };
                }
                return rs;
              }),
            };
          }
          return convo;
        }),
      };
    }
    case ChatActionTypes.RECEIVE_MESSAGE: {
      return {
        ...state,
        conversations: state.conversations.map((convo) => {
          if (convo.id === action.conversation.id) {
            return action.conversation;
          }
          return convo;
        }),
      };
    }
    case ChatActionTypes.ADD_ONLINE_USER: {
      return {
        ...state,
        ...action,
      };
    }
    case ChatActionTypes.REMOVE_ONLINE_USER: {
      return {
        ...state,
        ...action,
      };
    }
    case ChatActionTypes.CLOSE_ALL: {
      return {
        ...state,
        ...action,
      };
    }
    case ChatActionTypes.TOGGLE_CHAT_VISIBILITY: {
      let newState = state;
      newState.isOpen = !newState.isOpen;
      return {
        ...newState,
        ...action,
      };
    }
    case ChatActionTypes.OPEN_CHAT_CONTAINER: {
      let newState = state;
      newState.isOpen = true;
      return {
        ...newState,
        ...action,
      };
    }
    case ChatActionTypes.COMPOSE_MESSAGE: {
      return {
        ...state,
        ...action,
      };
    }
    case ChatActionTypes.RESET_CHAT: {
      return {
        ...initialChatState,
      };
    }
    default:
      return state;
  }
};
