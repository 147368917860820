import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { handleInputChange, IStringMap } from '../../../helpers';
import { IPage } from '@gigit/interfaces';
import typeHelpers from '../../../helpers/typeHelpers';

import TextField from '../../TextField/TextField';

import { IUserState } from '../../../reducers/user';
import { IProfileState } from '../../../reducers/profile';

import QuillTextEditor, { ReactQuillModules } from '../../QuillTextEditor/QuillTextEditor';

import { updateGroupPageComponent } from '../../../actions/group';
import { updateEventPageComponent } from '../../../actions/event';
import { updateUserPageComponent } from '../../../actions/user';

import './Description.scss';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { updateGigPageComponent } from '../../../actions/gig';
import { IGigState } from '../../../reducers/gig';
import ComponentPlaceholder from '../ComponentPlaceholder/ComponentPlaceholder';
import { uiConstants } from '../../../constants/uiConstants';
import { IOwnerObject } from '../../../interfaces';
import { IContentBlockComponent, ReduxActionType } from '../../../interfaces';

const desc = 'Content Block';

interface IProps {
  page: IPage;
  edit: boolean;
  component: IContentBlockComponent;
  permissions?: IStringMap;
  userState: IUserState;
  gigState: IGigState;
  profileState: IProfileState;
  updateGroupPageComponent: ReduxActionType<typeof updateGroupPageComponent>;
  updateEventPageComponent: ReduxActionType<typeof updateEventPageComponent>;
  updateUserPageComponent: ReduxActionType<typeof updateUserPageComponent>;
  updateGigPageComponent: ReduxActionType<typeof updateGigPageComponent>;
  showEdit(): void;
  hasEditPermissions?: boolean;
  owner: IOwnerObject;
  alternativeDescriptionValue?: string;
}

interface IState {
  value: string;
  modules: ReactQuillModules;
  formats: Array<any>;
  title: string;
}

//Using Description until ContentBlock is built, ContentBlock is replacing Description component
class Description extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      value:
        (this.props.component.meta_data && this.props.component.meta_data.value) ||
        this.props?.alternativeDescriptionValue ||
        '',
      title: this.getTitle(),
      modules: {
        toolbar: uiConstants.quill.defaultToolbar,
        clipboard: {
          matchVisual: false,
        },
      },
      formats: uiConstants.quill.defaultFormats,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    let metaDataChanged = prevProps.component.meta_data !== this.props.component.meta_data;

    if (metaDataChanged) {
      if (this.props.component?.meta_data?.value || this.props.component?.meta_data?.title) {
        this.setState({
          value: this.props.component.meta_data?.value || '',
          title: this.props.component.meta_data?.title || desc,
        });
      } else {
        this.setState({
          value: '',
          title: desc,
        });
      }
    }

    if (prevProps.edit && !this.props.edit) {
      this.update();
    }
  }

  handleChange(content: any, delta: any, source: any, editor: any) {
    let val = editor.getHTML();
    if (val) {
      this.setState({ value: val });
    }
  }

  getTitle() {
    return this.props.component.meta_data?.title || this.props.component.title || desc;
  }

  update() {
    const payload = this.props.component;

    payload.meta_data = {
      title: this.state.title || desc,
      value: this.state.value,
    };

    if (
      this.props.permissions &&
      this.props.permissions['EDIT_GROUP_PAGES'] &&
      !typeHelpers.isOwnerObjectOfType(this.props.owner, 'gig')
    ) {
      this.props.updateGroupPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    } else if (
      this.props.owner.ownerType !== 'gig' &&
      this.props.permissions &&
      this.props.permissions['EDIT_EVENT_PAGES']
    ) {
      this.props.updateEventPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    } else if (
      this.props.owner.ownerType === 'user' &&
      this.props.profileState.user.handle === this.props.userState.user.handle
    ) {
      this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, payload);
    } else if (
      this.props.owner.ownerType === 'gig' &&
      this.props.permissions &&
      this.props.permissions['MANAGE_GIGS']
    ) {
      this.props.updateGigPageComponent(
        this.props.gigState.gig.id!,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    }
  }

  render() {
    return (
      <div className="Description">
        {this.props.edit && (
          <TextField
            value={this.state.title}
            name="title"
            type="text"
            onChange={(e: any) => {
              handleInputChange(e, this);
            }}
          />
        )}
        {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}

        {this.props.hasEditPermissions && this.state.value === '' && !this.props.edit && (
          <ComponentPlaceholder
            title="Add static web content to your page"
            message="Use content block components to add text and images around other components"
            actions={[
              {
                label: 'Edit Component',
                iconClassName: 'fas fa-pencil',
                action: this.props.showEdit,
              },
            ]}
          />
        )}

        {(this.props.edit ||
          (!this.props.edit && this.state.value !== '' && this.state.value !== '<p><br></p>')) && (
          <QuillTextEditor
            value={this.state.value}
            readOnly={!this.props.edit}
            preserveWhitespace={true}
            theme={
              this.props.edit ? uiConstants.quill.editableTheme : uiConstants.quill.readOnlyTheme
            }
            modules={this.state.modules}
            formats={this.state.formats}
            onChange={this.handleChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    gigState: store.gigState,
  };
};

const mapDispatchToProps = {
  updateGroupPageComponent: updateGroupPageComponent,
  updateGigPageComponent: updateGigPageComponent,
  updateEventPageComponent: updateEventPageComponent,
  updateUserPageComponent: updateUserPageComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Description);
