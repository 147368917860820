import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { userSelectors } from '../../../selectors/user';

import {
  routes,
  swapRouteParams,
  handleInputChange,
  timeSince,
  toastError,
  toastSuccess,
} from '../../../helpers';
import { IUserRole, IActivity } from '@gigit/interfaces';
import { IGroupState } from '../../../reducers/group';
import { IAppState } from '../../../store';
import QuillTextEditor from '../../QuillTextEditor/QuillTextEditor';
import ContextMenu from '../../ContextMenu/ContextMenu';
import Button from '../../Button/Button';
import Portrait from '../../Portrait/Portrait';
import { createToast } from '../../../actions/toaster';
import Loader from '../../Loader/Loader';
import { Prompt } from '../../Prompt/Prompt';

import './ActivityLog.scss';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';
import { localizeHelpers } from '../../../localizeHelpers';
import moment from 'moment';
import { uiConstants } from '../../../constants/uiConstants';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  locale: string;
  createToast(toast: IToast): void;
}

interface IState {
  message: string;
  activityRecords: Array<IActivity>;
  modules: any;
  formats: Array<string>;
  isLoading: boolean;
  isEdit: boolean;
  editId: string;
  activityToDelete: string;
  editorFocused: boolean;
}

class ActivityLog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      message: '',
      activityRecords: [],
      modules: {
        toolbar: uiConstants.quill.basicToolbar,
        clipboard: {
          matchVisual: false,
        },
      },
      formats: uiConstants.quill.defaultFormats,
      isLoading: false,
      isEdit: false,
      editId: '',
      activityToDelete: '',
      editorFocused: false,
    };

    this.create = this.create.bind(this);
    this.delete = this.delete.bind(this);
    this.handleMsg = this.handleMsg.bind(this);
  }

  componentDidMount() {
    this.getActivityLogs();
  }

  delete() {
    axios
      .delete(
        swapRouteParams(routes.DELETE_ACTIVITY_LOG, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
          activityId: this.state.activityToDelete,
        }),
      )
      .then((response) => {
        if (response.status === 200) {
          this.getActivityLogs();
          const toast = toastSuccess(
            localizeHelpers.translate(`Successfully archived activity.`),
            'Activity Deleted',
          );
          this.props.createToast(toast);
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Delete Activity');
        this.props.createToast(toast);
      });
  }

  create() {
    let payload = {
      comment: this.state.message,
    };

    axios
      .post(
        swapRouteParams(routes.CREATE_ACTIVITY_LOG, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
        }),
        payload,
      )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.getActivityLogs();
          this.setState({
            message: '',
          });
          const toast = toastSuccess(
            localizeHelpers.translate(`Successfully created notes.`),
            'Notes Created',
          );
          this.props.createToast(toast);
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Create Notes');
        this.props.createToast(toast);
      });
  }

  getActivityLogs() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        axios
          .get(
            swapRouteParams(routes.GET_ACTIVITY_LOG, {
              groupId: this.props.groupState.group.id,
              memberId: this.props.contact.user?.id,
            }),
          )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.setState({
                activityRecords: response.data,
              });
            }
          })
          .catch((error) => {
            const errorObj = errorHelpers.getErrorObject(error);
            const toast = toastError(errorObj.translatedMessage, 'Activity Logs');
            this.props.createToast(toast);
          })
          .finally(() => {
            this.setState({
              isLoading: false,
            });
          });
      },
    );
  }

  handleMsg(content: any, delta: any, source: any, editor: any) {
    this.setState({
      message: content,
    });
  }

  editMode(id: string, comment: string) {
    this.setState({
      isEdit: true,
      editId: id,
      message: comment,
    });
  }

  resetEdit() {
    this.setState({
      isEdit: false,
      editId: '',
      message: '',
    });
  }

  getTime(time: string) {
    let t: any = new Date(time);
    t = t.getTime() / 1000;
    let r = timeSince(t);
    return r;
  }

  renderTimeSince(dateString: string | Date | undefined) {
    const date = moment(dateString).toDate();
    let t = date.getTime() / 1000;
    let timeSinceResult = timeSince(t);
    return (
      <div className="time">
        <var
          data-var="time_since_interval"
          pluralize={timeSinceResult.interval.toString()}
        >
          {localizeHelpers.formatNumber(timeSinceResult.interval || 0, this.props.locale)}
        </var>
        {timeSinceResult.interval > 1
          ? ` ${timeSinceResult.unit}s ago`
          : ` ${timeSinceResult.unit} ago`}
      </div>
    );
  }
  edit() {
    axios
      .put(
        swapRouteParams(routes.DELETE_ACTIVITY_LOG, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
          activityId: this.state.editId,
        }),
        { comment: this.state.message },
      )
      .then((response) => {
        if (response.status === 200) {
          this.getActivityLogs();
          const toast = toastSuccess(
            localizeHelpers.translate(`Successfully updated Activity.`),
            'Activity Updated',
          );
          this.props.createToast(toast);

          this.resetEdit();
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Update Activity');
        this.props.createToast(toast);
      });
  }

  render() {
    let isEditMode = this.state.isEdit;

    return (
      <div className="ActivityLog">
        <Loader loading={this.state.isLoading} />
        <div className="logs">
          {!this.state.isLoading &&
            this.state.activityRecords.map((a) => {
              let actions = [
                {
                  label: 'Edit',
                  icon: 'fa fa-pencil',
                  onClick: () => {
                    this.editMode(a.id ?? '', a.comment ?? '');
                  },
                },
                {
                  label: 'Delete',
                  icon: 'fa fa-trash',
                  onClick: () => {
                    this.setState({ activityToDelete: a.id || '' });
                  },
                },
              ];
              return (
                <div
                  key={a.id}
                  className="log"
                >
                  <div className="portrait">
                    <Portrait
                      source={a.created_by?.profile_image_url}
                      size={60}
                    />
                  </div>
                  <div className="created-by">
                    <div
                      notranslate="yes"
                      className="name"
                    >
                      {a?.created_by?.display_name}
                    </div>
                    <div className="data">
                      <QuillTextEditor
                        value={a?.comment}
                        readOnly={true}
                        preserveWhitespace={true}
                        theme={uiConstants.quill.readOnlyTheme}
                        modules={this.state.modules}
                        formats={this.state.formats}
                        onChange={(content: any, delta: any, source: any, editor: any) => {}}
                      />
                    </div>
                    <div className="time">
                      {this.renderTimeSince(a.updated_at ?? a.created_at ?? '')}
                    </div>
                  </div>
                  <div className="actions">
                    <i className="fal fa-ellipsis-h-alt" />
                    <ContextMenu
                      onMouseLeave={() => {}}
                      showMenu={true}
                      menuItems={actions}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className={this.state.editorFocused ? 'al' : 'al hide-toolbar'}
          id={'AL'}
        >
          <QuillTextEditor
            placeholder={'Enter a comment'}
            value={this.state.message}
            preserveWhitespace={true}
            theme={uiConstants.quill.editableTheme}
            modules={this.state.modules}
            formats={this.state.formats}
            onChange={this.handleMsg}
            scrollingContainer={'#AL'}
            onFocus={() => {
              this.setState({
                editorFocused: true,
              });
            }}
            onBlur={() => {
              this.setState({
                editorFocused: false,
              });
            }}
          />
          {isEditMode && (
            <span
              onClick={() => {
                this.resetEdit();
              }}
              className="cancel-edit"
            >
              <i className="fa fa-pencil" /> Editing <i className="fa fa-caret-down" />
            </span>
          )}
          <Button
            icon="far fa-save"
            onClick={() => (isEditMode ? this.edit() : this.create())}
            className="activity-log-save"
            text={isEditMode ? 'Update' : 'Save'}
          />
        </div>

        <Prompt
          show={this.state.activityToDelete.length > 0}
          title="Delete Activity"
          message=""
          yesMessage="Delete Activity"
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage="Cancel"
          onClose={() => this.setState({ activityToDelete: '' })}
          onYes={() => this.delete()}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ActivityLog),
);
