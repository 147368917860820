import avatar_01 from './avatar_01.png';
import avatar_02 from './avatar_02.png';
import avatar_03 from './avatar_03.png';
import avatar_04 from './avatar_04.png';
import avatar_05 from './avatar_05.png';
import avatar_06 from './avatar_06.png';
import avatar_07 from './avatar_07.png';
import avatar_08 from './avatar_08.png';
import avatar_09 from './avatar_09.png';
import avatar_10 from './avatar_10.png';
import coverimage_01 from './background1.png';
import coverimage_02 from './background2.png';
import coverimage_03 from './background3.png';
import coverimage_04 from './background4.png';
import coverimage_05 from './background5.png';
import coverimage_06 from './background6.png';
import coverimage_07 from './background7.png';
import coverimage_08 from './background8.png';
import coverimage_09 from './background9.png';

export default {
  avatars: [
    avatar_01,
    avatar_02,
    avatar_03,
    avatar_04,
    avatar_05,
    avatar_06,
    avatar_07,
    avatar_08,
    avatar_09,
    avatar_10,
  ],
  coverImages: [
    coverimage_02,
    coverimage_01,
    coverimage_04,
    coverimage_03,
    coverimage_07,
    coverimage_05,
    coverimage_08,
    coverimage_06,
    coverimage_09,
  ],
};
