import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IPage, IMediaAlbum } from '@gigit/interfaces';

import { IStringMap, handleInputChange } from '../../../helpers';
import { IGroupState } from '../../../reducers/group';
import { IEventState } from '../../../reducers/event';
import { IProfileState } from '../../../reducers/profile';
import { IMediaAlbumState } from '../../../reducers/mediaAlbum';
import { IUserState } from '../../../reducers/user';
import { getGroupMediaAlbums, updateGroupPageComponent } from '../../../actions/group';
import { getEventMediaAlbums, updateEventPageComponent } from '../../../actions/event';
import { updateUserPageComponent } from '../../../actions/user';
import { getProfileMediaAlbums } from '../../../actions/profile';

import MediaAlbumItem from '../MediaAlbumItem/MediaAlbumItem';
import Modal from '../../Modal/Modal';

import './MediaAlbum.scss';
import { updateGigPageComponent, getGigMediaAlbums } from '../../../actions/gig';
import { IGigState } from '../../../reducers/gig';
import TextField from '../../TextField/TextField';
import ComponentPlaceholder from '../ComponentPlaceholder/ComponentPlaceholder';
import { IOwnerObject } from '../../../interfaces';
import { IMediaAlbumComponent } from '../../../interfaces';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  component: IMediaAlbumComponent;
  page: IPage;
  profileState: IProfileState;
  groupState: IGroupState;
  eventState: IEventState;
  mediaAlbumState: IMediaAlbumState;
  userState: IUserState;
  gigState: IGigState;
  permissions?: IStringMap;
  getGroupMediaAlbums(groupId: string): void;
  updateGroupPageComponent(
    groupId: string,
    _pageId: string,
    _componentId: string,
    _payload: any,
    callback?: { (): void },
  ): void;
  getEventMediaAlbums(eventId: string): void;
  getProfileMediaAlbums(userId: string): void;
  getGigMediaAlbums(gigId: string): void;
  updateEventPageComponent(
    eventId: string,
    _pageId: string,
    _componentId: string,
    _payload: any,
    callback?: { (): void },
  ): void;
  updateUserPageComponent(_pageId: string, _componentId: string, _payload: any): void;
  updateGigPageComponent(
    gigId: string,
    _pageId: string,
    _componentId: string,
    _component: any,
  ): void;
  edit: boolean;
  onCreateAlbumClicked(): void;
  onComponentUpdate?(disableReload?: boolean): void;
  owner: IOwnerObject;
  hasEditPermissions?: boolean;
}

interface IState {
  showGallery: boolean;
  currentAlbum: number;
  currentAlbumItem: number;
  imageStyle: 'portrait' | 'landscape' | 'square';
  title: string;
}

class MediaAlbum extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showGallery: false,
      currentAlbum: -1,
      currentAlbumItem: 0,
      imageStyle: 'landscape',
      title: this.props.component.title || 'Media',
    };

    this.toggleAlbum = this.toggleAlbum.bind(this);
    this.toggleGallery = this.toggleGallery.bind(this);
    this.hasPagePermission = this.hasPagePermission.bind(this);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevProps.groupState.groupMediaAlbums !== this.props.groupState.groupMediaAlbums ||
      prevProps.eventState.mediaAlbums !== this.props.eventState.mediaAlbums ||
      prevProps.profileState.mediaAlbums !== this.props.profileState.mediaAlbums ||
      prevProps.gigState.mediaAlbums !== this.props.gigState.mediaAlbums
    ) {
      this.setState({
        currentAlbum: -1,
      });
    }

    if (
      prevProps.groupState.groupMediaAlbums.length <
        this.props.groupState.groupMediaAlbums.length ||
      prevProps.eventState.mediaAlbums.length < this.props.eventState.mediaAlbums.length ||
      prevProps.profileState.mediaAlbums.length < this.props.profileState.mediaAlbums.length ||
      prevProps.gigState.mediaAlbums.length < this.props.gigState.mediaAlbums.length
    ) {
      if (this.props.mediaAlbumState.componentId === this.props.component.id) {
        let _albums = [...(this.props.component.content_references?.object_ids ?? [])];

        if (this.props.owner.ownerType === 'group') {
          _albums.push(
            this.props.groupState.groupMediaAlbums[
              this.props.groupState.groupMediaAlbums.length - 1
            ].id ?? '',
          );

          this.props.updateGroupPageComponent(
            this.props.owner.ownerId,
            this.props.page.id,
            this.props.component.id,
            {
              content_references: {
                object_type: 'media_album',
                object_ids: _albums,
              },
            },
            this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
          );
        } else if (this.props.owner.ownerType === 'event') {
          _albums.push(
            this.props.eventState.mediaAlbums[this.props.eventState.mediaAlbums.length - 1].id ??
              '',
          );

          this.props.updateEventPageComponent(
            this.props.owner.ownerId,
            this.props.page.id,
            this.props.component.id,
            {
              content_references: {
                object_type: 'media_album',
                object_ids: _albums,
              },
            },
            this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
          );
        } else if (this.props.owner.ownerType === 'user') {
          _albums.push(
            this.props.profileState.mediaAlbums[this.props.profileState.mediaAlbums.length - 1]
              .id ?? '',
          );

          this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, {
            content_references: {
              object_type: 'media_album',
              object_ids: _albums,
            },
          });
        } else if (this.props.owner.ownerType === 'gig') {
          let id: any;
          if (this.props.gigState.mediaAlbums[this.props.gigState.mediaAlbums.length - 1]) {
            id = this.props.gigState.mediaAlbums[this.props.gigState.mediaAlbums.length - 1].id;
          }

          if (id) {
            _albums.push(id);
          }

          this.props.updateGigPageComponent(
            this.props.owner.ownerId,
            this.props.page.id,
            this.props.component.id,
            {
              content_references: {
                object_type: 'media_album',
                object_ids: _albums,
              },
            },
          );
        }
      }
    }

    if (prevProps.edit && !this.props.edit) {
      this.update();
    }
  }

  toggleGallery(_value: boolean, _index?: number) {
    if (_index !== undefined && this.state.currentAlbum >= 0) {
      var _albumItem = 0;
      const length = this.getMediaAlbums()?.[this.state.currentAlbum].media_items?.length ?? 0;

      _albumItem = Math.min(Math.max(_index, 0), length - 1);

      this.setState(
        {
          currentAlbumItem: _albumItem,
        },
        () => {
          let _mediaAlbums = this.getMediaAlbums();
          const currentAlbum = _mediaAlbums[this.state.currentAlbum];
          if (currentAlbum.media_items) {
            this.updateImageStyleBasedOnSize(
              currentAlbum.media_items[this.state.currentAlbumItem].url,
            );
          }
        },
      );
    }

    this.setState({
      showGallery: _value,
    });
  }

  /** Updates the image styling based on the size of the image */
  updateImageStyleBasedOnSize(url: string) {
    var img = new Image();

    const self = this;

    // GIG-1666: We load the image and measure the size to figure out if square, landscape or portrait.
    img.addEventListener('load', function () {
      let imageStyle: 'square' | 'landscape' | 'portrait' = 'square';

      if (this.naturalWidth < this.naturalHeight) {
        imageStyle = 'portrait';
      } else if (this.naturalWidth === this.naturalHeight) {
        imageStyle = 'square';
      } else {
        imageStyle = 'landscape';
      }

      self.setState({
        imageStyle: imageStyle,
      });
    });
    img.src = url;
  }

  toggleAlbum(_index: number) {
    this.setState({
      currentAlbum: _index,
    });
  }

  update() {
    const payload = this.props.component;

    payload.title = this.state.title;

    if (
      this.props.permissions &&
      this.props.permissions['EDIT_GROUP_PAGES'] &&
      this.props.owner.ownerType !== 'gig'
    ) {
      if (this.props.updateGroupPageComponent) {
        this.props.updateGroupPageComponent(
          this.props.groupState.group.id,
          this.props.page.id,
          this.props.component.id,
          payload,
          this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
        );
      }
    } else if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
      this.props.updateEventPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        payload,
        this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
      );
    } else if (
      this.props.owner.ownerType === 'user' &&
      this.props.profileState.user.handle === this.props.userState.user.handle
    ) {
      this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, payload);
    } else if (
      this.props.owner.ownerType === 'gig' &&
      this.props.permissions &&
      this.props.permissions['MANAGE_GIGS']
    ) {
      this.props.updateGigPageComponent(
        this.props.gigState.gig.id!,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    }
  }

  componentDidMount() {
    switch (this.props.owner.ownerType) {
      case 'group':
        this.props.getGroupMediaAlbums(this.props.owner.ownerId);
        break;
      case 'event':
        this.props.getEventMediaAlbums(this.props.owner.ownerId);
        break;
      case 'user':
        this.props.getProfileMediaAlbums('current');
        break;
      case 'gig':
        this.props.getGigMediaAlbums(this.props.owner.ownerId);
        break;
    }
  }

  hasPagePermission() {
    if (this.props.permissions) {
      switch (this.props.owner.ownerType) {
        case 'group':
          return this.props.permissions['EDIT_GROUP_PAGES'];
        case 'event':
          return this.props.permissions['EDIT_EVENT_PAGES'];
        case 'gig':
          return this.props.permissions['MANAGE_GIGS'];
        case 'user':
          return false;
      }
    }

    return false;
  }

  isLoggedInUser() {
    return this.props.profileState.user.handle === this.props.userState.user.handle;
  }

  getMediaAlbums() {
    const isEditable = !!(
      (this.props.permissions && this.hasPagePermission()) ||
      (this.props.owner.ownerType === 'user' && this.isLoggedInUser())
    );
    let _mediaAlbums: IMediaAlbum[] = [];

    switch (this.props.owner.ownerType) {
      case 'group':
        _mediaAlbums = this.props.groupState.groupMediaAlbums;
        break;
      case 'event':
        _mediaAlbums = this.props.eventState.mediaAlbums;
        break;
      case 'user':
        _mediaAlbums = this.props.profileState.mediaAlbums;
        break;
      case 'gig':
        _mediaAlbums = this.props.gigState.mediaAlbums;
        break;
    }

    if (isEditable) {
      return _mediaAlbums;
    } else {
      // Because of the index based implementation if user is not admin we must filter out hidden albums to maintain index integrity. IMO Might be better to swap this to an id based implementation
      let object_ids = this.props.component.content_references?.object_ids ?? [];
      let visibleAlbums = _mediaAlbums?.filter((album: IMediaAlbum) => {
        return object_ids.includes(album.id ?? '');
      });

      return visibleAlbums;
    }
  }

  render() {
    let _mediaAlbums = this.getMediaAlbums();
    const isEditable = !!(
      (this.props.permissions && this.hasPagePermission()) ||
      (this.props.owner.ownerType === 'user' && this.isLoggedInUser())
    );
    const currentAlbum: IMediaAlbum | undefined = _mediaAlbums[this.state.currentAlbum];
    const currentAlbumItem = currentAlbum?.media_items?.[this.state.currentAlbumItem];

    return (
      <div className="MediaAlbum">
        <div className="title">
          {this.props.edit && (
            <TextField
              value={this.state.title}
              name="title"
              type="text"
              onChange={(e: any) => {
                handleInputChange(e, this);
              }}
            />
          )}
          {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}

          {isEditable && _mediaAlbums.length === 0 && this.props.hasEditPermissions && (
            <ComponentPlaceholder
              title="Welcome to your new Media component!"
              message="Add multimedia to your page"
              actions={[
                {
                  label: 'Create Album',
                  iconClassName: 'fas fa-plus-circle',
                  action: () => this.props.onCreateAlbumClicked(),
                },
              ]}
            />
          )}

          {!currentAlbum && (
            <div className="albums">
              {((this.props.permissions && this.hasPagePermission()) ||
                (this.props.owner.ownerType === 'user' && this.isLoggedInUser())) &&
                _mediaAlbums.map((item: any, index: number) => {
                  return (
                    <MediaAlbumItem
                      onComponentUpdate={() => {
                        if (this.props.onComponentUpdate) {
                          this.props.onComponentUpdate();
                        }
                      }}
                      editable={
                        !!(
                          (this.props.permissions && this.hasPagePermission()) ||
                          (this.props.owner.ownerType === 'user' && this.isLoggedInUser())
                        )
                      }
                      onClick={() => {
                        this.toggleAlbum(index);
                      }}
                      key={index}
                      {...this.props}
                      item={item}
                    />
                  );
                })}
              {((this.props.permissions && !this.hasPagePermission()) ||
                (this.props.owner.ownerType === 'user' && !this.isLoggedInUser())) &&
                _mediaAlbums.map((item: any, index: number) => {
                  return (
                    <MediaAlbumItem
                      onComponentUpdate={() => {
                        if (this.props.onComponentUpdate) {
                          this.props.onComponentUpdate();
                        }
                      }}
                      editable={false}
                      onClick={() => {
                        this.toggleAlbum(index);
                      }}
                      key={index}
                      {...this.props}
                      item={item}
                    />
                  );
                })}
            </div>
          )}
          {currentAlbum && (
            <div className="album">
              <div className="top-bar">
                <div
                  onClick={() => {
                    this.toggleAlbum(-1);
                  }}
                  className="back"
                >
                  <i className="fas fa-angle-left" />
                </div>
                <div className="info">
                  <div className="count">{currentAlbum.media_items?.length ?? 0} images</div>
                  <div className="title">{currentAlbum.title}</div>
                </div>
              </div>
              <div className="media-items">
                {currentAlbum?.media_items &&
                  currentAlbum.media_items.map((item: any, index: number) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          this.toggleGallery(true, index);
                        }}
                        className="media-item"
                      >
                        <img
                          alt={'Cover for Album ' + index}
                          src={item.url}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        <Modal
          show={this.state.showGallery}
          onClose={() => {
            this.toggleGallery(false);
          }}
        >
          {currentAlbum && (
            <div className="gallery">
              <div className="info">
                <div className="count">{currentAlbum.media_items?.length ?? 0} images</div>
                <div className="title">{currentAlbum.title}</div>
              </div>
              <div className="items">
                {this.state.currentAlbumItem !== 0 && (
                  <div
                    onClick={() => {
                      this.toggleGallery(true, this.state.currentAlbumItem - 1);
                    }}
                    className="media-action left"
                  >
                    <i className="fas fa-angle-left"></i>
                  </div>
                )}
                {currentAlbumItem && (
                  <img
                    alt={
                      'Gallery Image ' +
                      (this.state.currentAlbumItem + 1) +
                      ' of ' +
                      currentAlbum.media_items?.length
                    }
                    className={this.state.imageStyle + ' no-select'}
                    src={currentAlbumItem.url}
                  />
                )}
                {this.state.currentAlbumItem < (currentAlbum?.media_items?.length ?? 0 - 1) && (
                  <div
                    onClick={() => {
                      this.toggleGallery(true, this.state.currentAlbumItem + 1);
                    }}
                    className="media-action right"
                  >
                    <i className="fas fa-angle-right"></i>
                  </div>
                )}
              </div>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    profileState: store.profileState,
    userState: store.userState,
    gigState: store.gigState,
    mediaAlbumState: store.mediaAlbumState,
  };
};

const mapDispatchToProps = {
  getGroupMediaAlbums,
  updateGroupPageComponent,
  getEventMediaAlbums,
  updateEventPageComponent,
  updateUserPageComponent,
  updateGigPageComponent,
  getProfileMediaAlbums,
  getGigMediaAlbums: getGigMediaAlbums,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(MediaAlbum),
);
