import React, { FC } from 'react';

import './CardCommonEventSkeleton.scss';
import { EViewTypeCardCommonEvent, EStyleTypeCardCommonEvent } from './CardCommonEvent';

interface IProps {
  viewType: EViewTypeCardCommonEvent;
  styleType: EStyleTypeCardCommonEvent;
}

export const CardCommonEventSkeleton: FC<IProps> = (props: IProps) => {
  return (
    <div
      className={`CardCommonEventSkeleton ${EStyleTypeCardCommonEvent[props.styleType]} ${EViewTypeCardCommonEvent[props.viewType]}`}
    >
      <div className="card-header">
        <div className="cover-image-holder"> </div>
        <div className="profile-img"></div>
      </div>
      <div className="card-content">
        <div className="title"></div>
        <div className="title"></div>
        <div className="description"></div>
        <div className="description"></div>
        <div className="location"></div>
        <div className="location"></div>
        <div className="location"></div>
      </div>
      <div className="card-actions">
        <div className="button"></div>
      </div>
    </div>
  );
};
