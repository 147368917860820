import React, { useState, useEffect } from 'react';
import './EditThankYouMessage.scss';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import { uiConstants } from '../../../../constants/uiConstants';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import {
  errorHelpers,
  routes,
  swapRouteParams,
  toastError,
  toastSuccess,
} from '../../../../helpers';
import axios from 'axios';
import { localizeHelpers } from '../../../../localizeHelpers';
import { IBulkActionError, ITransactionSummary } from '@gigit/interfaces';
import { IEventState } from '../../../../reducers/event';
import { IOwnerObject, IToast } from '../../../../interfaces';
import { IGroupState } from '../../../../reducers/group';
import { donationRequestActions } from '../../../../requestActions';

interface IProps {
  donation: ITransactionSummary | null;
  showThankYouModal: boolean;
  hideModal(): void;
  selectedItems: string[];
  owner: IOwnerObject;
  eventState: IEventState;
  groupState: IGroupState;
  createToast(toast: IToast): void;
  setBulkActionLoading(v: boolean): void;
  setBulkMsgAndError(messages: string[], showError: boolean): void;
  setActionAndThankYouModal(bulkActionLoading: boolean, showThankYouModal: boolean): void;
  resetThankYouModalAndCurrentTransaction(): void;
  getDonations(params: { groupId?: string; eventId?: string }): void;
}

export const EditThankYouMessage: React.FC<IProps> = (props) => {
  const [thankYouMessage, setThankYouMessage] = useState<string>(
    props.donation?.confirmation_message || '',
  );
  const [currentTransaction, setCurrentTransaction] = useState<string>(props.donation?.id ?? '');

  useEffect(() => {
    if (props.showThankYouModal === false) {
      return () => {
        setThankYouMessage('');
      };
    }
  }, [props.showThankYouModal]);

  const saveThankYouMessage = async () => {
    if ((!currentTransaction || currentTransaction === '') && props.selectedItems.length > 0) {
      let groupId =
        props.owner.ownerType === 'group' ? props.owner.ownerId : props.eventState.event.group_id;

      let _payload = {
        message: { value: thankYouMessage },
        selected: props.selectedItems,
      };

      props.setBulkActionLoading(true);

      const route = swapRouteParams(routes.UPDATE_THANK_YOU_MESSAGE_BULK, { groupId });
      axios
        .put(route, _payload)
        .then((response) => {
          if (response.data.success_count) {
            const toast = toastSuccess(
              localizeHelpers.translate(
                'Successfully set "Thank you" message for {{success_count}} donations.',
                { success_count: response.data.success_count },
              ),
              'Transaction Record',
            );
            props.createToast(toast);
          }
          if (response.data.failed_count) {
            let messages: string[] = [];
            response.data.errors.forEach((error: IBulkActionError) => {
              const errObj = errorHelpers.getErrorObject(error);
              messages.push(errObj.translatedMessage);
            });
            props.setBulkMsgAndError(messages, true);
          }
          props.setActionAndThankYouModal(false, false);
        })
        .catch((error) => {
          const errObj = errorHelpers.getErrorObject(error);
          const toast = toastError(errObj.translatedMessage, 'Edit "Thank you" Message');
          props.createToast(toast);
          props.setActionAndThankYouModal(false, false);
        });
    } else {
      try {
        await donationRequestActions.updateThankYouMessage({
          groupId:
            props.owner.ownerType === 'group'
              ? props.groupState.group.id
              : props.eventState.event.group_id,
          transaction_id: currentTransaction,
          body: { value: thankYouMessage },
        });

        props.getDonations({
          groupId: props.owner.ownerType === 'group' ? props.groupState.group.id : undefined,
          eventId: props.owner.ownerType === 'event' ? props.eventState.event.id : undefined,
        });
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Thank You Message');
        props.createToast(toast);
      }

      props.resetThankYouModalAndCurrentTransaction();
    }
  };

  return (
    <Modal
      show={props.showThankYouModal}
      onClose={() => {
        props.hideModal();
      }}
      contentClassName="EditThankYouMessage"
    >
      <div className="thank-you-modal">
        <div className="title">Thank You Message</div>
        <QuillTextEditor
          value={thankYouMessage}
          preserveWhitespace={true}
          theme={uiConstants.quill.editableTheme}
          modules={{
            toolbar: uiConstants.quill.basicToolbar,
            clipboard: { matchVisual: false },
          }}
          formats={uiConstants.quill.defaultFormats}
          onChange={(content) => setThankYouMessage(content)}
        />
        <div className="thank-you-actions">
          <Button
            icon="fa fa-save"
            text="Save"
            onClick={async () => {
              await saveThankYouMessage();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
