import { IPage } from '@gigit/interfaces';
import { IOnboardStep } from '../../components/shared/Onboarding/interfaces';

export const gigForOptions = [
  {
    text: 'For a cause I help manage',
    subtext: 'Choose this option if you need volunteers for an organization.',
    id: 'group',
  },
  {
    text: 'For a personal project',
    subtext: 'Choose this option if you need volunteers for personal work.',
    id: 'personal',
  },
  {
    text: 'For a company or organization I own or help administer',
    subtext: 'Proceeds will go directly to the account connected by the organization.',
    id: 'company',
  },
];

export const gigStepsMaster: Array<IOnboardStep> = [
  {
    id: 'gigCreatedFor',
    text: 'Who are you creating this volunteer for?',
    tip: '',
    summaryTitle: 'Created For',
    data: [{ path: 'createdFor', required: true }],
  },
  {
    id: 'groupSelection',
    text: 'Which one of your causes needs volunteer help?',
    tip: null,
    summaryTitle: 'Cause Name',
    data: [{ path: 'selectedGroup', required: true }],
  },
  {
    id: 'hubSelection',
    text: 'Which company are you creating an opportunity for?',
    tip: null,
    summaryTitle: 'Company Name',
    data: [{ path: 'selectedHub', required: false }],
  },
  {
    id: 'baseInfo',
    text: 'Tell us about the volunteer work you need.',
    tip: null,
    summaryTitle: 'Volunteer Opportunity Details',
    data: [
      { path: 'baseInfo.name', required: true },
      { path: 'baseInfo.description', required: true },
      { path: 'baseInfo.revenueGoal', required: false },
      { path: 'baseInfo.startDate', required: true },
      { path: 'baseInfo.endDate', required: true },
      { path: 'baseInfo.country', required: true },
      { path: 'baseInfo.address.address', required: true },
      { path: 'baseInfo.locationType', required: true },
      { path: 'baseInfo.postalCode', required: true },
      { path: 'baseInfo.gigCategory', required: false },
      { path: 'baseInfo.gigSubCategory', required: false },
      { path: 'baseInfo.policeCheck', required: false },
      { path: 'baseInfo.OSSD', required: false },
    ],
  },
  {
    id: 'customApplication',
    text: 'Customize your application form',
    tip: 'This is the form volunteers will fill out when applying to your volunteer opportunity.',
    summaryTitle: null,
    data: [{ path: 'customFormData', required: false }],
  },
  {
    id: 'causesSelection',
    text: 'Choose focus areas that best describe your volunteer opportunity.',
    tip: 'Selecting focus areas will increase the likelihood of your posts being noticed!',
    summaryTitle: 'Focus Areas',
    data: [{ path: 'causeInfo.selectedCauses', required: true }],
  },
  {
    id: 'imageSelection',
    text: 'Customize your volunteer opportunity by adding a logo and cover image.',
    tip: 'Volunteer opportunity pages with custom images that connect with community members are more likely to be engaged with.',
    summaryTitle: 'Volunteer Opportunity Customization',
    data: [
      { path: 'profileImage', required: true },
      { path: 'coverImage', required: true },
    ],
  },
  {
    id: 'summary',
    text: 'Review the details for your volunteer opportunity.',
    tip: null,
    summaryTitle: null,
    data: [],
  },
];

export const getGigPageForCreate = (causes: string[], gigId: string) => {
  const descriptionPayload = {
    id: '',
    visibility: 'public',
    sequence: 1,
    title: 'Description',
    component_type: 'description',
  };
  const mediaAlbumPayload = {
    id: '',
    visibility: 'public',
    sequence: 5,
    title: 'Media',
    component_type: 'media_album',
    content_references: {
      object_ids: [],
      object_type: 'media_album',
    },
  };
  const locationsPayload = {
    id: '',
    visibility: 'public',
    sequence: 4,
    title: 'Locations',
    component_type: 'location',
    content_references: {
      object_ids: [],
      object_type: 'location',
    },
  };
  const causesPayload = {
    id: '',
    visibility: 'public',
    sequence: 3,
    title: 'Focus Areas',
    component_type: 'cause',
    content_references: {
      object_ids: [...causes],
      object_type: 'cause',
    },
    meta_data: {
      causes: [],
    },
  };
  const skillsPayload = {
    id: '',
    visibility: 'public',
    sequence: 2,
    title: 'Skills',
    component_type: 'skill',
    content_references: {
      object_ids: [],
      object_type: 'skill',
    },
  };
  const components = [
    descriptionPayload,
    skillsPayload,
    causesPayload,
    locationsPayload,
    mediaAlbumPayload,
  ];
  const payload: IPage = {
    id: '',
    visibility: 'public',
    owner_type: 'gig',
    owner_id: gigId,
    icon: '',
    sequence: 0,
    label: 'default volunteer opportunity page',
    components,
  };

  return payload;
};
