import React, { useState, useEffect, useRef, Props, Fragment } from 'react';
import { RouteComponentProps, Link, Redirect, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IUserState } from '../../reducers/user';

import './FlowSuccess.scss';

import Portrait from '../../components/Portrait/Portrait';
import Button from '../../components/Button/Button';
import queryString from 'query-string';

import { uiConstants } from '../../constants/uiConstants';
import { FC } from 'react';
import { IAppState } from '../../store';

import axios from 'axios';
import {
  errorHelpers,
  OwnerType,
  routes,
  swapRouteParams,
  toastError,
  typeHelpers,
} from '../../helpers';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import CoverImage from '../../components/CoverImage/CoverImage';
import { IGroup, IUser } from '@gigit/interfaces';
import { createToast } from '../../actions/toaster';
import Modal from '../../components/Modal/Modal';
import Share from '../../components/Share/Share';
import { Config } from '@gigit/config';
import { IOwnerObject } from '../../interfaces';
import { userSelectors } from '../../selectors/user';

type MatchState = {
  ownerId: string;
  ownerType: string;
};
interface IProps extends RouteComponentProps<MatchState> {}
const submissionLongText =
  'Thank you for submitting your cause. Check your email for review requirements and instructions. You will be contacted within 48 hours by one of our team members to process your review.';
const FlowSuccess: FC<IProps> = (props: IProps) => {
  // Component mounted flag to prevent re-render race between async calls and state update
  const componentIsMounted = useRef(true);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [group, setGroup] = useState<IGroup | null>(null);

  const [owner, setOwner] = useState<IOwnerObject | null>(null);
  const [isOwnerLoading, setIsOwnerLoading] = useState<boolean>(true);
  const [isShareable, setIsShareable] = useState<boolean>(false);
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const params = queryString.parse(props.location.search);
  const isSubmission = params?.isSubmission;
  let groupCallToActionList = isSubmission
    ? [
        { label: 'Go to Dashboard', link: `/dashboard` },
        { label: 'View My Pages', link: '/dashboard?section=pages' },
      ]
    : [
        { label: 'View My Group', link: `/group/${owner?.ownerHandle}` },
        { label: 'View My Donation Page', link: `/` },
        { label: 'View My Pages', link: '/dashboard?section=pages' },
      ];

  //TODO: Look into proper links for the following with just a home route
  //An object to hold different page state based on the owner type (Cause, Event, Gig)
  const flowSuccessContext = {
    group: {
      title: isSubmission ? 'Your submission is under review' : 'Your cause has been created!',
      subTitle: isSubmission
        ? submissionLongText
        : "Your cause can be viewed in the 'My Pages' section within your dashboard",
      callToActions: groupCallToActionList,
      shareUrl: `/group/${owner?.ownerHandle}`,
    },
    event: {
      title: 'Your event has been created!',
      subTitle:
        'You can make your event visible to the public by publishing it on your event page.',
      callToActions: [
        { label: 'View My Event', link: `/event/${owner?.ownerHandle}` },
        { label: 'View My Pages', link: '/dashboard?section=pages&tab=fundraiser' },
        { label: 'Discover Kambeo', link: '/' },
      ],
      shareUrl: `/event/${owner?.ownerHandle}`,
    },
    gig: {
      title: 'Your volunteer opportunity has been created!',
      subTitle:
        "Your volunteer opportunities can be viewed in the 'My Pages' section within your dashboard",
      callToActions: [
        { label: 'View My Volunteer Opportunity', link: `/gig/${owner?.ownerHandle}` },
        { label: 'View My Pages', link: '/dashboard?section=pages&tab=gig' },
        { label: 'Discover Kambeo', link: '/discover/volunteers' },
      ],
      shareUrl: `/gig/${owner?.ownerHandle}`,
    },
    user: {
      title: `Welcome to Kambeo, ${owner?.ownerTitle}!`,
      subTitle: "You're all set to start making change!",
      callToActions: [
        { label: 'Discover Kambeo', link: `/?help=1` },
        { label: 'View My Profile', link: `/user/${owner?.ownerHandle}?help=1` },
        { label: 'Visit your Dashboard', link: `/dashboard?help=1` },
      ],
      shareUrl: `/user/${owner?.ownerHandle}`,
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      componentIsMounted.current = true;
    };
  }, []);

  //Call group, event, gig API, requires both ownerId & ownerType
  useEffect(() => {
    if (!isSubmission) {
      setIsOwnerLoading(true);
      getOwnerData(props.match.params.ownerId, props.match.params.ownerType);
    }
  }, [props.match.params.ownerId && props.match.params.ownerType]);

  //Check for shareable query param and set local state if true
  useEffect(() => {
    const _params = queryString.parse(props.location.search);

    if (_params.shareable === 'true') {
      setIsShareable(true);
    }
  }, [props.location.search]);

  const getOwnerData = async (ownerId: string, ownerType: string) => {
    const ownerRoute =
      ownerType === 'group'
        ? routes.GET_GROUP
        : ownerType === 'event'
          ? routes.GET_EVENT
          : ownerType === 'gig'
            ? routes.GET_GIG_BY_HANDLE
            : ownerType === 'user'
              ? routes.GET_USER_BY_HANDLE
              : null;

    let _route = swapRouteParams(ownerRoute || routes.GET_GROUP, { handleOrId: ownerId });

    try {
      let response = await axios.get(_route);
      if (componentIsMounted.current) {
        setOwner(typeHelpers.createOwnerObject(ownerType as OwnerType, response.data));
        setIsOwnerLoading(false);
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Setup Success');
      dispatch(createToast(toast));
    }
  };

  const user = owner?.ownerType === 'user' ? (owner.object as IUser) : null;
  const ownerTitle = owner?.ownerTitle;
  const ownerStatusDate = localizeHelpers.formatDate(
    owner?.ownerStatus?.status_date || '',
    LocaleDateFormats.LL,
    locale,
  );
  const ownerPortrait = owner?.ownerProfileImageUrl;
  const ownerCoverImage = owner?.ownerCoverImageUrl;
  let context = !isOwnerLoading
    ? flowSuccessContext[owner?.ownerType as keyof typeof flowSuccessContext]
    : null;
  if (isSubmission) {
    context = flowSuccessContext[props.match.params.ownerType as keyof typeof flowSuccessContext];
  }

  return (
    <div className="FlowSuccess">
      <div className="bg-capsule-outer left"></div>
      <div className="bg-capsule-inner left"></div>
      <div className="bg-capsule-outer right"></div>
      <div className="bg-capsule-inner right"></div>
      <div className="FlowSuccess-content">
        <div className="title-container">
          <span className="title">{context?.title}</span>
          <span className="subtitle">{context?.subTitle}</span>
        </div>
        <div className="main">
          {owner?.ownerType !== 'user' && !isSubmission && (
            <div className="card">
              <div className="cover-image">
                <Portrait
                  size={50}
                  currentImage={ownerPortrait}
                  className="portrait"
                />
                <CoverImage
                  currentImage={ownerCoverImage}
                  height={100}
                  width={342}
                />
              </div>
              <div className="body">
                <var
                  className="title"
                  notranslate="yes"
                >
                  {ownerTitle}
                </var>
                <var
                  className="description"
                  notranslate="yes"
                >
                  {owner?.ownerDescription}
                </var>
                <var
                  className="date"
                  notranslate="yes"
                >
                  {ownerStatusDate}
                </var>
              </div>
            </div>
          )}
          {owner?.ownerType === 'user' && (
            <div className="user-section">
              <Portrait
                size={100}
                currentImage={ownerPortrait}
                className="portrait"
              />
              <div>Name: {owner?.ownerTitle}</div>
              <div>Display Name: {user?.display_name}</div>
              <div>Email: {user?.email}</div>
            </div>
          )}
          <div className="cta-container">
            {context?.callToActions.map(({ label, link }) => {
              return (
                <Link
                  to={link}
                  className="link-container"
                >
                  <span className="text">{label}</span>
                  <i className="fa fa-arrow-right icon" />
                </Link>
              );
            })}
            {isShareable && (
              <Button
                className="shareable-button"
                icon="fa fa-share-alt"
                buttonType="secondary"
                text="Share"
                onClick={() => {
                  setShareUrl(context?.shareUrl || '');
                  setShowShareModal(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showShareModal}
        onClose={() => {
          setShowShareModal(false);
        }}
        title="Share"
      >
        <Share
          {...props}
          url={Config.web.REACT_APP_BASE_URL + shareUrl}
        />
      </Modal>
    </div>
  );
};

export default FlowSuccess;
