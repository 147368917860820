import React, { Component } from 'react';
import CoverImage from '../../CoverImage/CoverImage';
import {
  IFloatingAction,
  ProfileFloatingActions,
} from '../ProfileFloatingActions/ProfileFloatingActions';

import './BackSplash.scss';

interface IProps {
  coverImageUrl: string | undefined | null;

  showChat: boolean;
  extraFloatingActions?: IFloatingAction[];
  onShareClick?: () => void;
  onChatClick?: () => void;

  showEditCover?: boolean;
  onImageChange?(file: any): void;
}

/** Creates a background for a page using a cover image. */
export class BackSplash extends Component<IProps> {
  render() {
    return (
      <div className="BackSplash">
        <CoverImage
          currentImage={this.props.coverImageUrl ? this.props.coverImageUrl : undefined}
          overlay={this.props.showEditCover}
          onChange={this.props.onImageChange}
        />
        <div className="curve"></div>

        <ProfileFloatingActions
          className="floating-actions"
          showChat={this.props.showChat}
          extraActions={this.props.extraFloatingActions}
          onChatClick={this.props.onChatClick}
          onShareClick={this.props.onShareClick}
        />
      </div>
    );
  }
}
