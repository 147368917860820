import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { IPage, IStoreItem } from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  defaultCurrency,
  handleInputChange,
  IStringMap,
  mapPermissions,
  toastError,
  formatCurrency,
  swapRouteParams,
  routes,
} from '../../../helpers';
import { IAppState } from '../../../store';
import { createToast } from '../../../actions/toaster';
import Button from '../../Button/Button';
import Portrait from '../../Portrait/Portrait';
import TextField from '../../TextField/TextField';
import Loader from '../../Loader/Loader';
import Modal from '../../Modal/Modal';
import QuillTextEditor from '../../QuillTextEditor/QuillTextEditor';
import { IGroupState } from '../../../reducers/group';
import { updateGroupPageComponent } from '../../../actions/group';
import { updateEventPageComponent } from '../../../actions/event';
import { addItem } from '../../../actions/cart';
import './StoreItem.scss';
import { getNoCampaignOrNonMonetizedErrorMessage } from '../ComponentPlaceholder/helpers/helpers';
import { IEventState } from '../../../reducers/event';
import { IStoreListComponent, IToast, ReduxActionType } from '../../../interfaces';
import { localizeHelpers } from '../../../localizeHelpers';
import { userSelectors } from '../../../selectors/user';
import { uiConstants } from '../../../constants/uiConstants';
import { IOwnerObject } from '../../../interfaces/ownerObject';
import typeHelpers from '../../../helpers/typeHelpers';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { ReactComponent as StorePlaceholder } from './../../../assets/placeholders/store-placeholder.svg';

interface IProps extends WithTranslation {
  addItem: ReduxActionType<typeof addItem>;
  item: IStoreItem;
  groupState: IGroupState;
  eventState: IEventState;
  component: IStoreListComponent;
  locale: string;
  // Used in place of old isMonetized function
  chargesEnabled: boolean;

  /** Component Page reference object where this store appears. Can be ommitted if edit is set to false.  */
  page?: IPage;
  updateGroupPageComponent: ReduxActionType<typeof updateGroupPageComponent>;
  updateEventPageComponent: ReduxActionType<typeof updateEventPageComponent>;
  createToast(toast: IToast): void;
  isTicketsOnly?: boolean;
  addToCartCallback?: () => void;
  dragProps?: DraggableProvidedDragHandleProps;
  onComponentUpdate?: () => void;
  permissions?: IStringMap;
  owner: IOwnerObject;
  isLoadingAccountInfo: boolean;
}

interface IState {
  quantities: Array<any>;
  qty: string;
  userPermissions: any;
  showMobileModal: boolean;
  height: number;
  width: number;
  currentImage: number;
  showImageModal: boolean;
  loadingImage: boolean;
  imageStyle: string;
  imageRef: any;
  formats: any;
}

class StoreItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      imageRef: React.createRef(),
      imageStyle: 'landscape',
      quantities: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
      ],
      qty: '1',
      userPermissions: this.props.permissions ? this.props.permissions : false,
      showMobileModal: false,
      height: window.innerHeight,
      width: window.innerWidth,
      currentImage: 0,
      showImageModal: false,
      loadingImage: false,
      formats: [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
      ],
    };

    this.showMobileModal = this.showMobileModal.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.showMobileModal = this.showMobileModal.bind(this);
    this.toggleImageModal = this.toggleImageModal.bind(this);
    this.setImageStyle = this.setImageStyle.bind(this);
    this.handleAddToCart = this.handleAddToCart.bind(this);
    this.setPermissions = this.setPermissions.bind(this);
  }

  componentDidMount() {
    if (!this.state.userPermissions) {
      this.setPermissions();
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState(
      {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      () => {
        if (this.state.width > 930) {
          this.setState({
            showMobileModal: false,
          });
        }
      },
    );
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.currentImage !== this.state.currentImage) {
      setTimeout(() => {
        this.setImageStyle();

        this.setState({
          loadingImage: false,
        });
      }, 10);
    }
  }

  setPermissions() {
    let _permissions: IStringMap = {};

    if (this.props.owner) {
      const ownerId = this.props.page ? this.props.page.owner_id : this.props.owner.ownerId;
      let _route: string = swapRouteParams(routes.GET_CURRENT_USER_GROUP_ROLE, {
        groupId: ownerId,
      });

      if (this.props.owner.ownerType === 'event') {
        _route = swapRouteParams(routes.GET_CURRENT_USER_EVENT_ROLE, { eventId: ownerId });
      }

      axios.get(_route).then((response) => {
        _permissions = mapPermissions(response.data.permissions);
        this.setState({
          userPermissions: _permissions,
        });
      });
    }
  }

  toggleVisibility() {
    typeHelpers.assertNotNullOrUndefined(this.props.item.id, 'Expected item id');

    let _items = [...(this.props.component.content_references?.object_ids ?? [])];

    if (_items.includes(this.props.item.id)) {
      for (let e in _items) {
        if (this.props.item.id === _items[e]) {
          _items.splice(parseInt(e), 1);
        }
      }
    } else {
      _items.push(this.props.item.id);
    }

    if (this.props.owner.ownerType === 'group' && this.props.page) {
      this.props.updateGroupPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'store_item',
            object_ids: _items,
          },
        },
      );
    } else if (this.props.owner.ownerType === 'event' && this.props.page) {
      this.props.updateEventPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: 'store_item',
            object_ids: _items,
          },
        },
        this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
      );
    }
  }

  showMobileModal() {
    if (this.state.width <= 930) {
      this.setState({
        showMobileModal: true,
      });
    }
  }

  toggleImageModal() {
    this.setState(
      {
        showImageModal: true,
      },
      () => {
        this.setImageStyle();
      },
    );
  }

  setImageStyle() {
    if (this.state.imageRef && this.state.imageRef.current) {
      if (this.state.imageRef.current.naturalWidth < this.state.imageRef.current.naturalHeight) {
        this.setState({
          imageStyle: 'portrait',
        });
      } else if (
        this.state.imageRef.current.naturalWidth === this.state.imageRef.current.naturalHeight
      ) {
        this.setState({
          imageStyle: 'square',
        });
      } else {
        this.setState({
          imageStyle: 'landscape',
        });
      }
    }
  }

  getMaxQty() {
    let value: number = 999;
    const inventory = this.props.item.inventory;
    if (inventory) {
      if (
        (inventory.available_quantity || inventory.available_quantity === 0) &&
        inventory.max_purchase_quantity
      ) {
        value =
          inventory.available_quantity >= inventory.max_purchase_quantity
            ? inventory.max_purchase_quantity
            : inventory.available_quantity;
      } else if (inventory.available_quantity || inventory.available_quantity === 0) {
        value = inventory.available_quantity;
      } else if (inventory.max_purchase_quantity) {
        value = inventory.max_purchase_quantity;
      }
    }

    return String(value);
  }

  isAdmin(): boolean {
    const { userPermissions } = this.state;

    return !!(
      userPermissions != null &&
      (userPermissions['EDIT_EVENT_PAGES'] || userPermissions['EDIT_GROUP_PAGES'])
    );
  }

  handleAddToCart() {
    if (this.props.item.price !== 0 && this.props.chargesEnabled === false) {
      const entityType = this.props.owner.parentOwnerType === 'hub' ? 'Company' : 'Cause';
      const { errorMessage } = getNoCampaignOrNonMonetizedErrorMessage(
        this.props.chargesEnabled,
        this.isAdmin(),
        entityType,
      );

      const toast = toastError(localizeHelpers.translate(errorMessage), 'Store');
      this.props.createToast(toast);

      return;
    }

    this.props.addItem(
      { ...this.props.item, ...{ quantity: parseInt(this.state.qty) } },
      this.props.owner.ownerType,
    );
    if (this.props.addToCartCallback) {
      this.props.addToCartCallback();
    }
  }

  render() {
    const currency = this.props.owner?.account?.currency ?? defaultCurrency;

    const isActive = this.props.owner.isActive;
    const isVisible = !!this.props.component.content_references?.object_ids?.includes(
      this.props.item.id ?? '',
    );

    return (
      <div
        className={
          (this.state.userPermissions['EDIT_GROUP_PAGES'] ||
            this.state.userPermissions['EDIT_EVENT_PAGES']) &&
          !this.props.isTicketsOnly
            ? isVisible
              ? 'StoreItem admin'
              : 'StoreItem admin hidden'
            : 'StoreItem'
        }
      >
        <div
          onClick={() => {
            if (!this.props.isTicketsOnly) {
              this.showMobileModal();
            }
          }}
          className="store-item-inner"
        >
          <div
            className={
              this.props.item.media && this.props.item.media.length > 0
                ? 'images'
                : 'images no-images'
            }
          >
            <div
              onClick={() => {
                if (
                  this.state.width > 930 &&
                  this.props.item.media &&
                  this.props.item.media.length > 0
                ) {
                  this.toggleImageModal();
                }
              }}
              className="main"
              style={{
                backgroundImage: this.props.item.media[this.state.currentImage]
                  ? 'url(' + this.props.item.media[this.state.currentImage]?.url + ')'
                  : 'none',
              }}
            >
              {this.props.item.media && this.props.item.media.length > 0 ? (
                ''
              ) : (
                <StorePlaceholder />
              )}
            </div>
            <ul className="media-nav">
              {this.props.item.media.map((item: any, index: number) => {
                return (
                  <li
                    key={index}
                    className={this.state.currentImage === index ? 'active' : ''}
                    onClick={() => {
                      this.setState({ currentImage: index });
                    }}
                  >
                    <i
                      className={
                        this.state.currentImage === index ? 'fas fa-circle' : 'far fa-circle'
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="store-item-details">
            <div className="item-top-row">
              <div className="title-section">
                <div
                  className="title"
                  notranslate="yes"
                >
                  {this.props.item.name}
                </div>
                <div
                  className="item-price"
                  notranslate="yes"
                >
                  {formatCurrency(this.props.item.price, currency, this.props.locale)}
                </div>
                {this.props.item.inventory &&
                  this.props.item.inventory.available_quantity &&
                  this.props.item.inventory.available_quantity <= 10 && (
                    <span className="quantity">{`Only ${this.props.item.inventory.available_quantity} available`}</span>
                  )}
                {this.props.item.inventory &&
                  this.props.item.inventory.available_quantity === 0 && (
                    <span className="quantity">Sold Out</span>
                  )}
              </div>
              <div className="qty">
                <TextField
                  value={this.state.qty}
                  label="qty"
                  name="qty"
                  min="1"
                  max={this.getMaxQty()}
                  type="number"
                  onChange={(e: any) => {
                    handleInputChange(e, this);
                  }}
                />
                <Button
                  isDisabled={
                    (this.props.item.inventory &&
                      this.props.item.inventory.available_quantity === 0) ||
                    !isActive ||
                    this.props.isLoadingAccountInfo
                  }
                  onClick={this.handleAddToCart}
                  text="Add To Cart"
                />
              </div>
            </div>
            <div className="item-bottom-row">
              <QuillTextEditor
                value={this.props.item.description || 'This item has no description.'}
                readOnly={true}
                preserveWhitespace={true}
                theme={uiConstants.quill.readOnlyTheme}
                modules={{
                  toolbar: [[], [], []],
                  clipboard: {
                    matchVisual: false,
                  },
                }}
                formats={this.state.formats}
              />
            </div>
          </div>
        </div>
        <div className="list-admin-actions">
          <ul>
            <li {...this.props.dragProps}>
              <i className="fas fa-arrows-alt" />
              <span>Drag</span>
            </li>
          </ul>
        </div>
        <Modal
          show={this.state.showMobileModal}
          onClose={() => {
            this.setState({ showMobileModal: false });
          }}
          title={this.props.item.name}
        >
          <div className="mobile-store-item">
            <div className="portrait-wrap">
              {this.props.item.media.length > 0 && (
                <img
                  alt="store item backdrop"
                  className="backdrop"
                  src={this.props.item.media[0].url}
                />
              )}
              <Portrait
                source={this.props.item.media.length > 0 ? this.props.item.media[0].url : ''}
                size={120}
              />
            </div>
            <div className="details">
              <div className="title">{this.props.item.name}</div>
              <div className="price">
                {formatCurrency(this.props.item.price, currency, this.props.locale)}
              </div>
              {this.props.item.inventory &&
                this.props.item.inventory.available_quantity &&
                this.props.item.inventory.available_quantity <= 10 &&
                this.props.item.inventory.available_quantity !== 0 && (
                  <span>{this.props.item.inventory.available_quantity}</span>
                )}
            </div>
            <div className="description">
              <div className="title">Description</div>
              <QuillTextEditor
                value={this.props.item.description || 'This item has no description.'}
                readOnly={true}
                preserveWhitespace={true}
                theme={uiConstants.quill.readOnlyTheme}
                modules={{
                  toolbar: [[], [], []],
                  clipboard: {
                    matchVisual: false,
                  },
                }}
                formats={this.state.formats}
              />
            </div>
            <div className="store-item-actions safari_only">
              <TextField
                value={this.state.qty}
                name="qty"
                min="1"
                max={this.getMaxQty()}
                type="number"
                onChange={(e: any) => {
                  handleInputChange(e, this);
                }}
              />
              <Button
                isDisabled={
                  (this.props.item.inventory &&
                    this.props.item.inventory.available_quantity === 0) ||
                  !isActive
                }
                onClick={() => {
                  this.props.addItem(
                    { ...this.props.item, ...{ quantity: parseInt(this.state.qty) } },
                    this.props.owner.ownerType,
                  );
                  this.setState({ showMobileModal: false });
                }}
                text="Add To Cart"
              />
            </div>
          </div>
        </Modal>
        <Modal
          class="store-images"
          show={this.state.showImageModal}
          onClose={() => {
            this.setState({ showImageModal: false });
          }}
        >
          <div className="store-image-gallery">
            <div className="image-title">
              <div className="total">
                Image {this.state.currentImage + 1} of {this.props.item.media.length}
              </div>
              <div className="title">{this.props.item.name}</div>
            </div>
            {this.state.loadingImage && <Loader loading={this.state.loadingImage} />}
            {this.props.item.media &&
              this.props.item.media[this.state.currentImage] &&
              !this.state.loadingImage && (
                <img
                  alt={'media item ' + this.state.currentImage}
                  className={this.state.imageStyle}
                  ref={this.state.imageRef}
                  src={this.props.item.media[this.state.currentImage].url}
                />
              )}
            {this.state.currentImage > 0 && (
              <div
                onClick={() => {
                  if (this.state.currentImage > 0) {
                    this.setState({
                      currentImage: this.state.currentImage - 1,
                      loadingImage: true,
                    });
                  }
                }}
                className="left"
              >
                <i className="fas fa-arrow-left" />
              </div>
            )}
            {this.props.item.media.length &&
              this.state.currentImage + 1 < this.props.item.media.length && (
                <div
                  onClick={() => {
                    if (
                      this.props.item.media.length &&
                      this.state.currentImage + 1 < this.props.item.media.length
                    ) {
                      this.setState({
                        currentImage: this.state.currentImage + 1,
                        loadingImage: true,
                      });
                    }
                  }}
                  className="right"
                >
                  <i className="fas fa-arrow-right"></i>
                </div>
              )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  updateGroupPageComponent,
  updateEventPageComponent,
  createToast,
  addItem,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(StoreItem),
);
