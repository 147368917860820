import React, { Component, MouseEvent } from 'react';
import { ReactElement } from 'react';
import { combineClassNames } from '../../../helpers';

import './ModalHeader.scss';

interface IProps {
  title?: string;
  description?: string | ReactElement;
  className?: string;
  onClose?: (e: MouseEvent<Element>) => void;
  onBack?: (e: MouseEvent<Element>) => void;
  closeIcon?: string;
}

/** Renders a Modal header. In mobile view this will appear like a native mobile title view. */
export default class ModalHeader extends Component<IProps> {
  render() {
    const { className, onClose, onBack, title, description } = this.props;

    return (
      <div className={combineClassNames('ModalHeader', className)}>
        {onClose && (
          <i
            onClick={onClose}
            className={`fal fa-times close-button ${onBack ? 'right' : 'left'}`}
          />
        )}
        {onBack && (
          <i
            onClick={onBack}
            className={`fal fa-chevron-left back-button left`}
          />
        )}
        {(title || description) && (
          <div className="header-container">
            {title && <h1 className="header-title">{title}</h1>}
            {description && <p className="header-description">{description}</p>}
          </div>
        )}

        {this.props?.closeIcon && (
          <span
            className="modal-close-icon"
            onClick={this.props.onClose}
          >
            <i className={this.props.closeIcon} />
          </span>
        )}
      </div>
    );
  }
}
