import React, { FC, ReactNode, MouseEvent } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import './CardActions.scss';

export interface ICardAction {
  icon?: string;
  label: string;
  visibility?: boolean;
  onClick(e: MouseEvent<HTMLButtonElement>): void;
}

interface IProps {
  actions: ICardAction[];
  children?: ReactNode;
  loading?: boolean;
  className?: string;
}

export const CardActions: FC<IProps> = (props: IProps) => {
  const getContent = () => {
    const { actions, className } = props;

    return (
      <div className="actions-container">
        {actions.map((item, index) => (
          <React.Fragment key={index}>
            {item.visibility && (
              <div key={index}>
                <button
                  className={className}
                  onClick={(e) => item.onClick(e)}
                >
                  <i className={item.icon} />
                  {item.label}
                </button>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={`card-actions ${props.className}`}>
        {props.loading ? 'Loading css' : getContent()}
      </div>
    </React.Fragment>
  );
};
