import React from 'react';

import Modal from '../Modal/Modal';
import ImageUpload from '../ImageUpload/ImageUpload';
import ImageCropper from '../ImageCropper/ImageCropper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import './Portrait.scss';
import { combineClassNames } from '../../helpers';

interface PortraitStyle {
  width: string;
  height: string;
}

interface IProps {
  className?: string;
  size: number;
  overlay?: boolean;
  source?: string;
  onClick?(e: any): void;
  onChange?(file: { file: string; type: string }): void;
  onMouseEnter?(e?: any): void;
  title?: string;
  currentImage?: string;
  hasNotification?: boolean;
  hideMask?: boolean;
}

interface IState {
  style?: PortraitStyle;
  showCropModal: boolean;
  src: string;
  fileUrl: string;
}

class Portrait extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    if (this.props.size !== undefined) {
      this.state = {
        fileUrl: this.props.source || '',
        showCropModal: false,
        src: '',
        style: {
          width: this.props.size + 'px',
          height: this.props.size + 'px',
        },
      };
    } else {
      this.state = {
        fileUrl: this.props.source || '',
        showCropModal: false,
        src: '',
        style: {
          width: '80px',
          height: '80px',
        },
      };
    }

    this.onCrop = this.onCrop.bind(this);
    this.onImgSelect = this.onImgSelect.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.source !== prevProps.source) {
      this.setState({
        fileUrl: this.props.source || '',
      });
    }

    if (this.props.size !== prevProps.size) {
      this.setState({
        style: {
          width: this.props.size + 'px',
          height: this.props.size + 'px',
        },
      });
    }
  }

  onCrop(newImgData: any) {
    const fileURL = newImgData.fileUrl;
    const type = 'profile_image_url';

    this.setState({
      fileUrl: fileURL,
      showCropModal: false,
    });

    if (this.props.onChange) {
      this.props.onChange({
        file: fileURL,
        type: type,
      });
    }
  }

  onImgSelect(file: any) {
    this.setState({
      src: file.src,
      showCropModal: true,
    });
  }

  close() {
    this.setState({
      showCropModal: false,
    });
  }

  render() {
    return (
      <div
        title={this.props.title}
        onMouseEnter={() => {
          if (this.props.onMouseEnter !== undefined) {
            this.props.onMouseEnter();
          }
        }}
        className={combineClassNames(
          this.props.className,
          this.props.overlay !== undefined && this.props.overlay === true
            ? 'Portrait hide-icon'
            : 'Portrait',
        )}
        onClick={(e: any) => {
          if (this.props.onClick !== undefined) {
            this.props.onClick(e);
          }
        }}
      >
        {this.props.hasNotification && <div className="notification-dot" />}
        <Modal
          show={this.state.showCropModal}
          onClose={this.close}
        >
          <ImageCropper
            recSize={'150 x 150'}
            onSave={this.onCrop}
            src={this.state.src}
            minWidth={80}
          />
        </Modal>
        <div
          className={this.state.fileUrl || this.props.currentImage ? 'inner transparent' : 'inner'}
          style={this.state ? this.state.style : { width: '80px', height: '80px' }}
        >
          {!this.props.hideMask && <div className="profile"></div>}
          {this.props.overlay && (
            <div className="overlay">
              <i className="fad fa-camera"></i>
              <ImageUpload onImgSelect={this.onImgSelect} />
            </div>
          )}
          {(this.state.fileUrl || this.props.currentImage) && (
            <LazyLoadImage
              style={this.state ? this.state.style : { width: '30px', height: '30px' }}
              effect="opacity"
              alt="profile"
              src={this.state.fileUrl !== '' ? this.state.fileUrl : this.props.currentImage}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Portrait;
