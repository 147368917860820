import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { IPage, ISimpleValue, ITwitchAccountStatus } from '@gigit/interfaces';
import { TwitchEmbed } from 'react-twitch-embed';
import { RouteComponentProps } from 'react-router-dom';
import { defaultCurrency, IStringMap, mapPermissions, routes } from '../../../helpers';
import { WithTranslation, withTranslation } from 'react-i18next';
import { localizeHelpers } from '../../../localizeHelpers';
import Portrait from '../../Portrait/Portrait';
import { IEventState } from '../../../reducers/event';
import './Livestream.scss';
import Button from '../../Button/Button';
import { userSelectors } from '../../../selectors/user';
import { ILiveStreamComponent, IStreamerMetadataObj } from '../../../interfaces';

type ILiveStreamActiveMetadata = IStreamerMetadataObj & { is_online?: boolean };

interface IProps extends WithTranslation, RouteComponentProps<any> {
  eventState: IEventState;
  component: ILiveStreamComponent;
  page: IPage;
  locale: string;
  hasEditPermissions?: boolean;
}

interface IState {
  userPermissions: any;
  containerRef: any;
  activeStream: string;
  showStream: boolean;
  isStreamMuted: boolean;
  meta: ILiveStreamActiveMetadata[];
  mounted: boolean;
  handle: string;
}

const StreamEmbed = React.memo((props: any) => {
  return <TwitchEmbed {...props} />;
});

class Livestream extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      userPermissions: {},
      containerRef: React.createRef(),
      activeStream: '',
      showStream: true,
      isStreamMuted: true,
      meta: this.props.component.meta_data || [],
      mounted: false,
      handle: this.props.match.params.handle,
    };

    this.setPermissions = this.setPermissions.bind(this);
    this.getTwitchStatuses = this.getTwitchStatuses.bind(this);
    this.getFirstLiveStream = this.getFirstLiveStream.bind(this);
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    });

    if (this.props.page.owner_type === 'event') {
      this.setPermissions();
    }

    this.getTwitchStatuses(true);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.eventState.currentUserRole !== prevProps.eventState.currentUserRole &&
      this.props.page.owner_type === 'event'
    ) {
      this.setPermissions();
    }

    if (prevProps.component !== this.props.component) {
      this.getTwitchStatuses(true);
    }
  }

  getTwitchStatuses(findFirstLiveStreamer?: boolean) {
    if (!this.props.component.meta_data) {
      return;
    }

    let payload: ISimpleValue<string[]> = { value: [] };

    for (const streamer of this.props.component.meta_data) {
      payload.value.push(streamer.twitch.toLowerCase());
    }

    axios.post<ITwitchAccountStatus[]>(routes.TWITCH_STATUS, payload).then((response) => {
      let _meta: IStreamerMetadataObj[] = [];

      for (let m in this.state.meta) {
        for (let r in response.data) {
          if (response.data[r].login === this.state.meta[m].twitch) {
            _meta.push({
              ...response.data[r],
              ...this.state.meta[m],
              profile_image_url:
                this.state.meta[m].profile_image_url !== ''
                  ? this.state.meta[m].profile_image_url
                  : response.data[r].profile_image_url,
            });
            break;
          }
        }
      }

      this.setState(
        {
          meta: _meta,
        },
        () => {
          if (findFirstLiveStreamer) {
            this.setState({
              activeStream: this.getFirstLiveStream(),
              isStreamMuted: false,
            });
          }
        },
      );
    });
  }

  setPermissions() {
    let _permissions: IStringMap = mapPermissions(
      this.props.eventState.currentUserRole.permissions,
    );

    this.setState({
      userPermissions: _permissions,
    });
  }

  isOnline(index: number) {
    if (this.state.meta[index] && this.state.meta[index].is_online) {
      return true;
    }

    return false;
  }

  getTotalOnline() {
    let streamersOnline: number = 0;

    for (let o in this.state.meta) {
      if (this.state.meta[o].is_online && this.props.component.meta_data?.[o]?.visible) {
        streamersOnline++;
      }
    }

    return streamersOnline;
  }

  getTotalVisible() {
    let streamersVisible: number = 0;

    if (this.props.component.meta_data) {
      for (const streamer of this.props.component.meta_data) {
        if (streamer.visible) {
          streamersVisible++;
        }
      }
    }

    return streamersVisible;
  }

  getFirstLiveStream() {
    let _stream: string = '';

    if (this.props.component.meta_data) {
      for (let o in this.state.meta) {
        if (
          this.state.meta[o].is_online &&
          this.props.component.meta_data[o] &&
          this.props.component.meta_data[o].visible
        ) {
          _stream = this.props.component.meta_data[o].twitch;
          break;
        }
      }
    }

    return _stream;
  }

  getProfileImage(item: ILiveStreamActiveMetadata, index: number) {
    let _image = item.profile_image_url;

    if (_image === '' && this.state.meta && this.state.meta[index]) {
      _image = this.state.meta[index].profile_image_url;
    }

    return _image;
  }

  render() {
    const currency = this.props.eventState.event.group?.account?.currency ?? defaultCurrency;

    return (
      <div
        ref={this.state.containerRef}
        className={
          this.state.userPermissions['EDIT_EVENT_PAGES'] && this.props.hasEditPermissions
            ? 'Livestream admin'
            : 'Livestream'
        }
      >
        <div className="title">
          <h3>Livestream</h3>
        </div>
        {this.state.activeStream === '' && (
          <div
            style={{
              height: this.state.containerRef.current
                ? this.state.containerRef.current.offsetWidth * 0.5625
                : 798 * 0.5625,
            }}
            className="livestream-placeholder"
          >
            <i className="far fa-video" />
            <div className="details">
              Livestream is currently not available. Please check back later.
            </div>
          </div>
        )}
        {this.state.activeStream !== '' && (
          <StreamEmbed
            withChat={false}
            width="100%"
            height={
              this.state.containerRef.current
                ? this.state.containerRef.current.offsetWidth * 0.5625
                : 798 * 0.5625
            }
            channel={this.state.activeStream}
            id="livestream-embed"
            theme="light"
            muted={this.state.isStreamMuted}
          />
        )}
        <div className="subtitle">Hosts</div>
        <div className="total-online">
          <var data-var="total_online">
            {localizeHelpers.formatNumber(this.getTotalOnline() || 0, this.props.locale)}
          </var>
          /
          <var data-var="total_visible">
            {localizeHelpers.formatNumber(this.getTotalVisible() || 0, this.props.locale)}
          </var>{' '}
          Online
        </div>
        <ul className="livestream-streamers">
          {this.state.meta &&
            this.state.meta.map((item, index) => {
              if (item.visible) {
                return (
                  <li
                    onClick={() => {
                      this.setState({ activeStream: item.twitch, isStreamMuted: false });
                    }}
                    key={index}
                    className={this.isOnline(index) ? 'live no-select' : 'no-select'}
                  >
                    <div
                      className={
                        this.state.activeStream === item.twitch ? 'play is-watching' : 'play'
                      }
                    >
                      <i className="fas fa-play" />
                    </div>
                    <div className="streamer-info">
                      <Portrait
                        size={50}
                        currentImage={this.getProfileImage(item, index)}
                      />
                      <div className="streamer-text">
                        <div className="streamer-name">
                          <div className="name-inner">{item.display_name}</div>
                          {this.isOnline(index) && (
                            <div className="status">
                              <i className="fa fa-circle" />
                              LIVE
                            </div>
                          )}
                        </div>
                        {
                          <React.Fragment>
                            <div className="streamer-raised">Twitch Stream</div>
                          </React.Fragment>
                        }
                      </div>
                      {item.user_handle && (
                        <div className="streamer-actions">
                          <Button
                            className="view"
                            buttonType="secondary"
                            text="View"
                            onClick={() =>
                              this.props.history.push(
                                `/event/${this.state.handle}/individual/${item.user_handle}`,
                              )
                            }
                          />
                          <Button
                            className="donate"
                            buttonType="primary"
                            text="Donate"
                            onClick={() =>
                              this.props.history.push(
                                `/event/${this.state.handle}/individual/${item.user_handle}/donate`,
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  </li>
                );
              }
              return null;
            })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(Livestream),
);
