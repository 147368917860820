import React from 'react';
import logoKDark from '../../../../assets/logo-K-dark-all-white.svg';
import logoKLight from '../../../../assets/logo-K-light-all-black.svg';
import logoDark from '../../../../assets/logo-dark-all-white.svg';
import logoLight from '../../../../assets/logo-light-all-black.svg';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../store';

import './FormTopBar.scss';

interface IProps {
  logoVariant?: 'light' | 'dark';
  onLogoClick(): void;
  onClose(): void;
  step?: string;
}

export const FormTopBar: React.FC<IProps> = (props: IProps) => {
  const { logoVariant } = props;
  const isDarkVariant = logoVariant === 'dark';
  const userState = useAppSelector((state) => state.userState);
  return (
    <div className="FormTopBar">
      <div
        onClick={() => props.onLogoClick()}
        className="logo-container"
      >
        <img
          className="desktop"
          src={!isDarkVariant ? logoDark : logoLight}
          alt="Kambeo Logo"
        />
        <img
          className="mobile"
          src={!isDarkVariant ? logoKDark : logoKLight}
          alt="Kambeo Logo"
        />
      </div>
      {props.step !== 'baseUserInfo' && userState.isLoggedIn === false && (
        <div className="login">
          <Link
            className="login-link"
            to={'/login?redirect='}
          >
            Sign in
          </Link>
        </div>
      )}
      <div className="header-wrap">
        <i
          onClick={() => props.onClose()}
          className="fa fa-times"
        />
      </div>
    </div>
  );
};
