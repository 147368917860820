import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends WithTranslation, RouteComponentProps {
  flow: string;
  params: any;

  updateCTA(type: string): void;
}

interface IState {
  cta: string;
}

class CallToAction extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      cta: '',
    };
  }

  componentDidMount() {}

  updateCTA(type: string) {
    this.setState(
      {
        cta: type,
      },
      () => {
        this.props.updateCTA(type);
      },
    );
  }

  render() {
    const t = this.props.t;
    return (
      <div className={`form-step ${this.props.flow}`}>
        <h2>
          {this.props.t(
            'Congratulations! You are now ready to start making a difference in your community.',
          )}
        </h2>
        <ul>
          {this.props.flow !== 'volunteer' && (
            <li onClick={() => this.updateCTA('search')}>
              <span className={this.state.cta === 'search' ? 'list-circle active' : 'list-circle'}>
                <i
                  className={this.props.flow === 'findGroups' ? 'fal fa-users' : 'fa fa-ticket'}
                ></i>
              </span>
              {this.props.flow === 'findGroups'
                ? t('Find Groups (donate or fundraise)')
                : t('Find Events or Fundraisers')}
            </li>
          )}
          <li onClick={() => this.updateCTA('profile')}>
            <span className={this.state.cta === 'profile' ? 'list-circle active' : 'list-circle'}>
              <i className="fal fa-star"></i>
            </span>
            {t('Go to My Profile')}
          </li>
          <li onClick={() => this.updateCTA('find')}>
            <span className={this.state.cta === 'find' ? 'list-circle active' : 'list-circle'}>
              <i className="fa fa-plus"></i>
            </span>
            {t('Find Volunteer Opportunities')}
          </li>
          {this.props.params.isRedirect && (
            <li onClick={() => this.updateCTA('backToApply')}>
              <span
                className={this.state.cta === 'backToApply' ? 'list-circle active' : 'list-circle'}
              >
                <i className="fa fa-align-left"></i>
              </span>
              {t('Complete application')}
            </li>
          )}
        </ul>
      </div>
    );
  }
}

export default withTranslation('translations')(connect()(CallToAction));
