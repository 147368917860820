import React, { useState } from 'react';
import { useEffect } from 'react';
import useToastDispatcher from '../../../../hooks/useToaster';
import { localizeHelpers } from '../../../../localizeHelpers';
import { authRequestActions } from '../../../../requestActions';
import { IBaseUserInfo } from '../../../../routes/Register/Register';
import TextField from '../../../TextField/TextField';
import './VerifyUser.scss';
import Timer from '../Timer/Timer';

interface IProps {
  userInfo: IBaseUserInfo;
  updateVerificationCode(update: IBaseUserInfo): void;
}

const VerifyUser: React.FC<IProps> = (props: IProps) => {
  const { email } = props.userInfo;
  const { dispatchToastError } = useToastDispatcher();
  const { dispatchToastSuccess } = useToastDispatcher();
  const verificationExplainer = (
    <p>{`Verification code sent to ${email}. Check your email/messages to complete the verification process and account setup`}</p>
  );
  const [activeResetVerificationTimeout, setActiveResetVerificationTimeout] =
    useState<boolean>(false);

  useEffect(() => {
    //only call to generate code if we haven't already
    if (!props.userInfo.verification.hasGeneratedCode) {
      generateVerificationCode();
    }
  }, []);

  async function handleVerificationCode(code: string) {
    if (code.length !== 6) {
      const updatedVerification = {
        ...props.userInfo,
        verification: { ...props.userInfo.verification, code: code, isValid: false },
      };
      props.updateVerificationCode({ ...updatedVerification });
    } else {
      try {
        const isValid = await authRequestActions.validateVerificationcode(
          props.userInfo.email,
          code,
        );
        if (isValid?.is_valid) {
          const updatedVerification = {
            ...props.userInfo,
            verification: { ...props.userInfo.verification, code: code, isValid: true },
          };
          props.updateVerificationCode({ ...updatedVerification });
        } else {
          const updatedVerification = {
            ...props.userInfo,
            verification: { ...props.userInfo.verification, code: code, isValid: false },
          };
          props.updateVerificationCode({ ...updatedVerification });
        }
      } catch (error) {
        dispatchToastError(error, 'Invalid Verification Code');
      }
    }
  }

  async function generateVerificationCode() {
    if (!activeResetVerificationTimeout) {
      const updatedVerification = {
        ...props.userInfo,
        verification: { ...props.userInfo.verification, hasGeneratedCode: true },
      };
      try {
        const wasGenerated = await authRequestActions.generateVerificationCode(
          props.userInfo.email,
        );
        if (wasGenerated?.was_generated) {
          props.updateVerificationCode({ ...updatedVerification });
          dispatchToastSuccess(
            localizeHelpers.translate('Verification Code Sent Successfully'),
            'Generate Verification Code',
          );
        }
      } catch (error) {
        dispatchToastError(error, 'Generate Verification Code');
      }
    }
  }

  const hasAttemptedVerify =
    props.userInfo.verification?.code?.length === 6 && props.userInfo.verification.hasGeneratedCode;

  return (
    <div className="VerifyUser">
      <h1>Enter the code you received</h1>
      {verificationExplainer}
      <div className="verify-input">
        <TextField
          className={
            hasAttemptedVerify ? (props.userInfo.verification.isValid ? 'valid' : 'invalid') : ''
          }
          placeholder="Type 6 digit code here"
          required={true}
          label=""
          value={props.userInfo.verification.code}
          name="verificationCode"
          type="text"
          onChange={(e) => {
            handleVerificationCode(e.target.value);
          }}
        />
      </div>
      <div className="actions">
        <span className={`${activeResetVerificationTimeout ? 'disabled' : ''}`}>
          Didn't receive an email?{' '}
          <a
            onClick={async () => {
              await generateVerificationCode();
              setActiveResetVerificationTimeout(true);
            }}
          >
            Resend verification code
          </a>
          .
        </span>
        {activeResetVerificationTimeout && (
          <Timer
            onEnd={() => setActiveResetVerificationTimeout(false)}
            time_limit={30}
            color_codes={{
              info: {
                color: '#5E51AB',
              },
              alert: {
                color: '#dddddd',
                threshold: 3,
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default VerifyUser;
