import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import './ToggleButton.scss';

interface IPassedProps extends WithTranslation, RouteComponentProps<any> {
  isActive: boolean;
  onToggleClick: () => void;
  label?: string;
}

class ToggleButton extends React.Component<IPassedProps> {
  render() {
    const { t, isActive, label, onToggleClick } = this.props;

    return (
      <div
        onClick={onToggleClick}
        className="ToggleButton"
      >
        {label && <span className="label">{label}</span>}
        <i className={isActive ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}></i>
      </div>
    );
  }
}

export default withRouter(withTranslation('translations')(ToggleButton));
