import { useStoriesContext } from '../../Hooks/StoryHooks';
import { ProgressBar } from '../StoryProgress/StoryProgress';
import { IStoryIndexedObject } from '../../Types/types';
import './Progress.scss';
import React from 'react';

interface IProgressProps {
  activeStoryIndex: number;
  isPaused: boolean;
}

export function Progress(props: IProgressProps) {
  const { stories, classNames } = useStoriesContext();
  return (
    <div
      className={`StoryProgressWrapper ${classNames?.progressContainer || ''}`}
      style={{ gridTemplateColumns: `repeat(${stories.length},1fr)` }}
    >
      {stories.map((story: IStoryIndexedObject) => (
        <ProgressBar
          key={story?.index}
          hasStoryPassed={(story?.index || 0) < props.activeStoryIndex}
          isActive={story.index === props.activeStoryIndex}
          story={story}
          isPaused={story?.index === props.activeStoryIndex && props.isPaused}
        />
      ))}
    </div>
  );
}
