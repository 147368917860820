import React, { useState } from 'react';
import { IUserContact } from '@gigit/interfaces';
import useToastDispatcher from '../../../hooks/useToaster';
import './OrganizerContact.scss';
import Portrait from '../../Portrait/Portrait';
import { userRequestActions } from '../../../requestActions';
import { typeHelpers } from '../../../helpers';

interface IProps {
  contact: IUserContact;
}

const OrganizerContact: React.FC<IProps> = (props: IProps) => {
  const { contact } = props;
  const [isContactAdded, setIsContactAdded] = useState<boolean>(false);

  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const handleAddContact = async () => {
    try {
      typeHelpers.assertNotNullOrUndefined(contact.id, 'Expected contact id');

      await userRequestActions.addOrganizerContact(contact.id);
      setIsContactAdded(true);
      dispatchToastSuccess('Successfully added organizer contact', 'Add organizer contact');
    } catch (error) {
      dispatchToastError(error, 'Add Contact');
    }
  };

  const handleRemoveContact = async () => {
    try {
      typeHelpers.assertNotNullOrUndefined(contact.id, 'Expected contact id');

      await userRequestActions.removeContact(contact.id);
      setIsContactAdded(false);
      dispatchToastSuccess('Successfully removed organizer contact', 'Remove organizer contact');
    } catch (error) {
      dispatchToastError(error, 'Remove Contact');
    }
  };

  return (
    <div className="OrganizerContact-listElement">
      <div className="contact-profile">
        <Portrait
          size={40}
          className="image-container"
          currentImage={contact.profile_image_url}
        />
        <p>{`${contact?.display_name}`}</p>
      </div>
      <p>{contact?.phone}</p>
      <p>{contact?.email}</p>
      <button
        className={`btn-action ${isContactAdded ? 'remove' : 'add'}`}
        onClick={isContactAdded ? handleRemoveContact : handleAddContact}
      >
        {isContactAdded ? '-' : '+'}
      </button>
    </div>
  );
};

export default OrganizerContact;
