import { Constants } from '@gigit/constants';
import { IEventIndividual, IEventTeam, IIndividualUserReturn } from '@gigit/interfaces';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Portrait from '../../components/Portrait/Portrait';
import SortableTable from '../../components/SortableTable/SortableTable';
import TextField from '../../components/TextField/TextField';

import { createToast } from '../../actions/toaster';
import {
  defaultCurrency,
  errorHelpers,
  formatCurrency,
  formatQuery,
  handleDebounce,
  handleInputChange,
  routes,
  swapRouteParams,
  toastError,
} from '../../helpers';
import {
  IActiveFilter,
  IOwnerObject,
  ISortSettings,
  IToast,
  ReduxActionType,
} from '../../interfaces';
import { IEventState } from '../../reducers/event';
import { IGroupState } from '../../reducers/group';
import { IAppState } from '../../store';

import axios from 'axios';
import './EventFundraiserManage.scss';
import ProgressBar from '../../components/shared/ProgressBar/ProgressBar';
import { userSelectors } from '../../selectors/user';
import Button from '../../components/Button/Button';
import { getEventIndividualsExport } from '../../actions/event';

interface IFundraiserRow {
  suspended: boolean | null;
  ownerId: string;
  name?: string;
  raised: number;
  goal: number;
  isContentCreator?: boolean;
  linkToIndividualProfile?: string;
  team: IEventTeam;
  user: IIndividualUserReturn;
  type: keyof typeof Constants.object_type;
}
interface IPassedProps {
  owner: IOwnerObject;
}
interface IPropsFromState {
  groupState: IGroupState;
  eventState: IEventState;
  locale: string;
}
interface IPropsFromDispatch {
  createToast: ReduxActionType<typeof createToast>;
  getEventIndividualsExport: ReduxActionType<typeof getEventIndividualsExport>;
}
type Props = IPassedProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  fundraisers: IFundraiserRow[];
  isFundraisersLoading: boolean;
  searchFundraiserValue: string;
  showFundraiserModal: boolean;
  sort: ISortSettings[];
  filters: IActiveFilter[];
  showOnlyContentCreators: boolean;
}
class EventFundraiserManage extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fundraisers: [],
      isFundraisersLoading: false,
      searchFundraiserValue: '',
      showFundraiserModal: false,
      sort: [],
      filters: [],
      showOnlyContentCreators: false,
    };

    this.suspendStream = this.suspendStream.bind(this);
  }

  /**
   * @param showModal Toggle value to show or hide the showFundraiser modal,
   * if it's left out it toggles the value.
   */
  toggleAddFundraiserModal = (showModal?: boolean) => {
    this.setState({
      showFundraiserModal: showModal ? showModal : !showModal,
    });
  };

  getFundraisers = async () => {
    try {
      this.setState({ isFundraisersLoading: true });

      let query = formatQuery({
        search: this.state.searchFundraiserValue,
        sort: this.state.sort,
        filters: this.state.filters,
      });

      let _eventIndividualsRoute = swapRouteParams(routes.GET_EVENT_INDIVIDUALS, {
        eventId: this.props.owner.ownerId,
      });
      let _eventTeamsRoute = swapRouteParams(routes.GET_EVENT_TEAMS, {
        eventId: this.props.owner.ownerId,
      });

      _eventIndividualsRoute += `?${query}`;
      _eventTeamsRoute += `?${query}`;

      const eventIndividualsResponse = await axios.get<IEventIndividual[]>(_eventIndividualsRoute);
      const eventTeamsResponse = await axios.get<IEventTeam[]>(_eventTeamsRoute);

      const fundraisers = [
        ...eventIndividualsResponse.data.map((individual) => {
          const { user, content_creator } = individual;
          const userUrl = `/event/${this.props.owner.ownerHandle}/individual/${user?.handle}`;

          return {
            suspended: individual.suspended,
            ownerId: user?.id,
            name: user?.display_name,
            raised: individual.raised,
            goal: individual.goal,
            isContentCreator: content_creator,
            linkToIndividualProfile: userUrl,
            user: user,
            type: Constants.object_type.user,
          } as IFundraiserRow;
        }),
        ...(!this.state.showOnlyContentCreators
          ? eventTeamsResponse.data.map((team) => {
              const teamUrl = `/event/${this.props.owner.ownerHandle}/team/${team?.handle}`;

              return {
                ownerId: team.id,
                name: team.name,
                raised: team.raised,
                goal: team.goal,
                isContentCreator: false,
                linkToIndividualProfile: teamUrl,
                team: team,
                type: Constants.object_type.team,
              } as IFundraiserRow;
            })
          : []),
      ];

      this.setState({
        fundraisers: fundraisers,
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      this.props.createToast(toastError(errorObj.translatedMessage, 'Get Fundraisers'));
    } finally {
      this.setState({ isFundraisersLoading: false });
    }
  };

  formatCurrency = (amount: number) => {
    let currency = this.props.owner.account?.currency || defaultCurrency;

    return formatCurrency(amount, currency, this.props.locale);
  };

  exportIndividuals = () => {
    this.props.getEventIndividualsExport(
      this.props.eventState.event.id,
      this.props.eventState.event.handle,
    );
  };

  toggleContentCreatorFilter = (onlyContentCreators: 'true' | 'false') => {
    if (onlyContentCreators === 'true') {
      this.setState({
        showOnlyContentCreators: true,
        filters: [
          ...this.state.filters,
          { id: 'content_creator', label: 'Name', type: 'flag', value: 'true' },
        ],
      });
    } else {
      this.setState({
        showOnlyContentCreators: false,
        filters: this.state.filters.filter((filter) => filter.id !== 'content_creator'),
      });
    }
  };

  componentDidMount() {
    this.getFundraisers();
  }

  componentDidUpdate(prevProps: Props, prevState: IState) {
    if (this.state.sort !== prevState.sort || this.state.filters !== prevState.filters) {
      this.getFundraisers();
    }
  }

  async suspendStream(id: string, suspended: boolean | null) {
    let _route = swapRouteParams(routes.CHANGE_SUSPEND_CONTENT_CREATOR, {
      eventId: this.props.eventState.event.id,
      userId: id,
    });

    try {
      let _payload = {
        suspend: {
          value: !suspended,
        },
      };
      await axios.put<IEventIndividual>(_route, _payload);
      await this.getFundraisers();
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      this.props.createToast(toastError(errorObj.translatedMessage, 'Get Fundraisers'));
    }
  }

  render() {
    return (
      <div className="EventFundraiserManage section-wrap">
        <div className="section-title">
          <div className="section-inner-title">Fundraisers</div>
          <Button
            className="export-button"
            icon="far fa-file-download"
            onClick={() => this.exportIndividuals()}
            text={'Export Fundraisers'}
          />
        </div>
        <div className="section-inner">
          <div className="EventFundraiserManagement-list">
            <div className="search">
              <TextField
                icon="fas fa-search"
                placeholder="Search fundraisers..."
                value={this.state.searchFundraiserValue}
                type="text"
                name="searchFundraiserValue"
                onChange={(e) => {
                  handleInputChange(e, this, false, () => {
                    handleDebounce(this.state.searchFundraiserValue).then((res) => {
                      this.getFundraisers();
                    });
                  });
                }}
              />
              <div className="quick-filter-container">
                <span
                  className={`quick-filter ${!this.state.showOnlyContentCreators && 'active'}`}
                  onClick={() => this.toggleContentCreatorFilter('false')}
                >
                  All Fundraisers
                </span>
                <span
                  className={`quick-filter ${this.state.showOnlyContentCreators && 'active'}`}
                  onClick={() => this.toggleContentCreatorFilter('true')}
                >
                  Content Creators
                </span>
              </div>
            </div>
            <div className="list">
              <div className="list-inner">
                <div className="list-rows">
                  <SortableTable
                    loading={this.state.isFundraisersLoading}
                    onSort={(sortSettings) => {
                      this.setState({ sort: sortSettings });
                    }}
                    columns={[
                      { id: 'portrait', label: 'Portrait', sortable: false },
                      { id: 'user.display_name', label: 'Fundraiser Name' },
                      { id: 'fundraiser_type', label: 'Fundraiser Type', sortable: false },
                      {
                        id: 'fundraiser_progress',
                        label: 'Fundraiser Progress',
                        sortable: false,
                      },
                      { id: 'content_creator', label: 'Content Creator' },
                      { id: 'actions', label: 'Actions' },
                    ]}
                    data={this.state.fundraisers.map((fundraiser) => {
                      const _menuItems = [
                        ...(fundraiser.type === 'user'
                          ? [
                              {
                                icon: 'fas fa-envelope',
                                link: 'mailto:' + fundraiser.user?.id,
                                label: 'Email',
                              },
                            ]
                          : []),
                        {
                          icon: 'fas fa-user',
                          link: fundraiser.linkToIndividualProfile,
                          label: 'View Profile',
                        },
                        ...(fundraiser.isContentCreator
                          ? [
                              {
                                onClick: () => {
                                  this.suspendStream(fundraiser.ownerId, fundraiser.suspended);
                                },
                                icon: 'fal fa-times-circle',
                                label: fundraiser.suspended
                                  ? 'Un-Suspend Stream'
                                  : 'Suspend Stream',
                              },
                            ]
                          : []),
                      ];

                      return {
                        row: [
                          {
                            id: 'portrait',
                            content: (
                              <Portrait
                                source={
                                  fundraiser.type === 'team'
                                    ? fundraiser.team.profile_image_url
                                    : fundraiser.user?.profile_image_url
                                }
                                size={40}
                              />
                            ),
                          },
                          {
                            id: 'user.display_name',
                            content: (
                              <Link
                                to={fundraiser.linkToIndividualProfile || ''}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                className="fundraiser-name-link"
                                notranslate="yes"
                              >
                                {fundraiser.name}
                              </Link>
                            ),
                          },
                          {
                            id: 'fundraiser_type',
                            content: (
                              <span>{fundraiser.type === 'team' ? 'Team' : 'Individual'}</span>
                            ),
                          },
                          {
                            id: 'fundraiser_progress',
                            content: (
                              <ProgressBar
                                amountRaised={fundraiser.raised}
                                goal={fundraiser.goal}
                                formatCurrency={(number) => this.formatCurrency(number)}
                              />
                            ),
                          },
                          {
                            id: 'content_creator',
                            content: (
                              <Fragment>
                                {fundraiser.isContentCreator && (
                                  <div className="is-creator-tag">Content Creator</div>
                                )}
                              </Fragment>
                            ),
                          },
                          { id: 'actions', menu: _menuItems },
                        ],
                      };
                    })}
                  />
                  {this.state.fundraisers.length === 0 && (
                    <div className="empty">
                      {this.state.searchFundraiserValue === '' ? (
                        <span>You don't have any fundraisers, yet.</span>
                      ) : (
                        <span>Your search returned 0 results.</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    eventState: store.eventState,
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  createToast,
  getEventIndividualsExport,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventFundraiserManage);
