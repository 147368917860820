import {
  IAuctionPayment,
  IPaymentIntentResponse,
  ITransactionAmounts,
  ITransactionItemSummary,
} from '@gigit/interfaces';
import axios from 'axios';
import { AuctionItemOwnerType } from '../actions/auction';
import { swapRouteParams, routes } from '../helpers';
import { groupRequestActions } from './groups';

export namespace auctionRequestActions {
  export async function getAuctionPaymentSummary(
    auctionItemId: string,
    payload: Partial<IAuctionPayment>,
  ): Promise<ITransactionAmounts> {
    const response = await axios.post<ITransactionAmounts>(
      swapRouteParams(routes.GET_AUCTION_SUMMARY, { id: auctionItemId }),
      payload,
    );
    return response.data;
  }

  export async function createAuctionPaymentIntent(
    groupId: string,
    id: string,
    payload: any,
  ): Promise<IPaymentIntentResponse> {
    const res = await axios
      .post<IPaymentIntentResponse>(
        swapRouteParams(routes.CREATE_AUCTION_PAYMENT_INTENT, { id }),
        payload,
      )
      .then(async (response) => {
        if (response.data.is_virtual) {
          if (response.data.client_secret) {
            let secrets = [];
            if (typeof response.data.client_secret === 'string') {
              secrets.push(response.data.client_secret);
            } else {
              secrets = response.data.client_secret;
            }
            for (const secret of secrets) {
              await groupRequestActions.registerItem(groupId, secret);
            }
          }
        }
        return response.data;
      });
    return res;
  }

  export async function getAuctionTransactions(
    ownerType: AuctionItemOwnerType,
    ownerId: string,
    query?: URLSearchParams,
  ) {
    let route: string =
      ownerType === 'group'
        ? routes.GET_GROUP_AUCTION_TRANSACTIONS
        : routes.GET_EVENT_AUCTION_TRANSACTIONS;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    if (query) {
      route += `?${query}`;
    }

    const response = await axios.get<ITransactionItemSummary[]>(
      swapRouteParams(route, { [idField]: ownerId }),
    );
    return response.data;
  }
}
