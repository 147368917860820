import React, { ChangeEvent } from 'react';
import { combineClassNames } from '../../helpers';

import './TextArea.scss';

// TODO: split the props between textarea props and component props - fix implementations
export interface ITextAreaProps {
  value: string;
  name: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  icon?: string;
  style?: any;
  type?: string;
  required?: boolean;
  min?: string;
  max?: string;
  maxLength?: number;
  minLength?: number;
  helper?: string;
  onChange(e: ChangeEvent<HTMLTextAreaElement>): void;
  onKeyDown?: any;
  onKeyUp?: any;
  autoComplete?: any;
  id?: string;
  className?: string;
  spellCheck?: boolean;

  // Used for counter to not enforce the limit but let the user know so they can adjust accordingly. This is a better U.I. experience IMO.
  charLength?: number;
}

const TextArea: React.FC<ITextAreaProps> = (props: ITextAreaProps) => {
  const charLengthExceeded = props.charLength
    ? props.value?.length <= props.charLength
      ? 'in-range'
      : 'exceeded'
    : null;
  const counterClass = `remaining-characters ${charLengthExceeded}`;
  let textareaProps = { ...props };

  if (textareaProps.charLength) {
    delete textareaProps['charLength'];
  }

  return (
    <div className={combineClassNames('TextArea', props.className)}>
      {props.icon !== undefined && <i className={'main-icon ' + props.icon}></i>}
      <label>
        <textarea
          {...textareaProps}
          className={combineClassNames(props.icon, charLengthExceeded)}
        ></textarea>
        <span className="placeholder">
          {props.label}
          {props.required ? '*' : ''}
        </span>
      </label>
      {props.helper !== undefined && (
        <div className="helper">
          <i className="fas fa-question-circle"></i>
          <span>{props.helper}</span>
        </div>
      )}
      {props?.charLength && (
        <div
          className={counterClass}
          notranslate="yes"
        >
          {' '}
          {props.value?.length} / {props.charLength}{' '}
        </div>
      )}
    </div>
  );
};

export default TextArea;
