import React, { FC, useEffect, useState, useRef } from 'react';
import './HubManagementAnalyticsDonations.scss';
import HubManagementMetricContainer from '../../shared/HubManagementMetricContainer/HubManagementMetricContainer';
import { IKpi } from '../../../../../interfaces';
import moment, { Moment } from 'moment';
import DateTimePicker from '../../../../shared/DateTimePicker/DateTimePicker';
import { useLocale } from '../../../../../hooks';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { metricRequestActions } from '../../../../../requestActions/metrics';
import { uiConstants } from '../../../../../constants';
import {
  defaultCurrency,
  formatCurrency,
  metricsHelpers,
  formatNumberCompactNotation,
  focusOnInputField,
} from '../../../../../helpers';
import { IHub } from '@gigit/interfaces';
import useToastDispatcher from '../../../../../hooks/useToaster';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface IProps {
  hub: IHub;
}

const _SESSION_STORAGE_DONATION_AMOUNT_DATE_FROM = 'hubAnalyticsDonations_donationAmountDateFrom',
  _SESSION_STORAGE_DONATION_AMOUNT_DATE_TO = 'hubAnalyticsDonations_donationAmountDateTo',
  _SESSION_STORAGE_NUM_OF_DONATION_DATE_FROM = 'hubAnalyticsDonations_numOfDonationDateFrom',
  _SESSION_STORAGE_NUM_OF_DONATION_DATE_TO = 'hubAnalyticsDonations_numOfDonationDateTo';

const HubManagementAnalyticsDonations: FC<IProps> = (props) => {
  const { hub } = props;
  const locale = useLocale();
  const { dispatchToastError } = useToastDispatcher();
  const [metrics, setMetrics] = useState<IKpi[]>([]);
  const [numOfDonationKpi, setNumOfDonationKpi] = useState<metricsHelpers.IChartKpi | null>(null);
  const [donationAmountKpi, setDonationAmountKpi] = useState<metricsHelpers.IChartKpi | null>(null);

  const [numOfDonationDateFrom, setNumOfDonationDateFrom] = useState<Moment>(
    moment(
      sessionStorage.getItem(_SESSION_STORAGE_NUM_OF_DONATION_DATE_FROM) ||
        `${new Date().getFullYear()}-01-01`,
    ),
  );
  const [numOfDonationDateTo, setNumOfDonationDateTo] = useState<Moment>(
    moment(
      sessionStorage.getItem(_SESSION_STORAGE_NUM_OF_DONATION_DATE_TO) ||
        `${new Date().getFullYear()}-12-31`,
    ),
  );
  const [donationAmountDateFrom, setDonationAmountDateFrom] = useState<Moment>(
    moment(
      sessionStorage.getItem(_SESSION_STORAGE_DONATION_AMOUNT_DATE_FROM) ||
        `${new Date().getFullYear()}-01-01`,
    ),
  );
  const [donationAmountDateTo, setDonationAmountDateTo] = useState<Moment>(
    moment(
      sessionStorage.getItem(_SESSION_STORAGE_DONATION_AMOUNT_DATE_TO) ||
        `${new Date().getFullYear()}-12-31`,
    ),
  );

  const donationAmountDateFromRef = useRef<HTMLInputElement>(null);
  const donationAmountDateToRef = useRef<HTMLInputElement>(null);
  const numOfDonationDateFromRef = useRef<HTMLInputElement>(null);
  const numOfDonationDateToRef = useRef<HTMLInputElement>(null);

  async function handleGetMetrics() {
    try {
      const metricNames = [
        'amount_donated',
        'percent_donations_verified',
        'num_donation_groups',
        'amount_donation_matched',
      ];
      const result = (
        await metricRequestActions.getMetricsBulk({
          metrics: metricNames.map((metric) => ({
            metric,
            object_type: uiConstants.ownerType.hub,
            object_id: hub.id!,
            currency: hub.account?.currency ?? defaultCurrency,
          })),
        })
      ).metrics;

      setMetrics([
        {
          value: formatCurrency(result[0].value, result[0].unit, locale.currentLocale),
          text: 'Total Amount Donated',
        },
        {
          value: `${result[1].value}%`,
          text: 'Donations Verified',
        },
        {
          value: formatNumberCompactNotation(result[2].value, locale.currentLocale),
          text: 'Number of Causes',
        },
        {
          value: formatCurrency(result[3].value, result[3].unit, locale.currentLocale),
          text: 'Total Matched Amount',
        },
      ]);
    } catch (error) {
      dispatchToastError(error, 'Get Metrics');
    }
  }

  async function handleGetKpi(kpi: string, start_date: Date, end_date: Date) {
    return metricRequestActions.getMetricsKpi({
      object_type: uiConstants.ownerType.hub,
      object_id: hub.id!,
      kpi,
      start_date,
      end_date,
      currency: hub.account?.currency ?? defaultCurrency,
    });
  }

  async function handleGetNumOfDonationsKpi() {
    try {
      const kpiData = (
        await handleGetKpi(
          'donation_counts_trend',
          numOfDonationDateFrom.toDate(),
          numOfDonationDateTo.toDate(),
        )
      ).kpi_data;
      let chartDate = [] as metricsHelpers.IChartKpiData[];
      kpiData.labels.forEach((label, index) => {
        chartDate.push({
          x: label,
          value: kpiData.datasets[0].data[index],
        });
      });

      setNumOfDonationKpi({ data: chartDate, labels: kpiData.labels });
    } catch (error) {
      dispatchToastError(error, 'Get Donation Count KPI');
    }
  }

  async function handleGetDonationAmountsKpi() {
    try {
      const kpiData = (
        await handleGetKpi(
          'donation_amounts_trend',
          donationAmountDateFrom.toDate(),
          donationAmountDateTo.toDate(),
        )
      ).kpi_data;
      let chartDate = [] as metricsHelpers.IChartKpiData[];
      kpiData.labels.forEach((label, index) => {
        chartDate.push({
          x: label,
          value: kpiData.datasets[0].data[index],
        });
      });

      setDonationAmountKpi({ data: chartDate, labels: kpiData.labels });
    } catch (error) {
      dispatchToastError(error, 'Get Donation Amount KPI');
    }
  }

  useEffect(() => {
    handleGetMetrics();
  }, []);

  useEffect(() => {
    handleGetNumOfDonationsKpi();
  }, [numOfDonationDateFrom, numOfDonationDateTo]);

  useEffect(() => {
    handleGetDonationAmountsKpi();
  }, [donationAmountDateFrom, donationAmountDateTo]);

  const optionsDonationAmount = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value: any) {
            return formatCurrency(
              Number(value),
              hub.account?.currency ?? defaultCurrency,
              locale.currentLocale,
            );
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return formatCurrency(
              Number(context.parsed.y),
              hub.account?.currency ?? defaultCurrency,
              locale.currentLocale,
            );
          },
        },
        backgroundColor: '#ffffff',
        borderColor: '#333333',
        borderWidth: 1,
        bodyColor: '#000000',
        titleColor: '#000000',
        padding: 16,
        titleFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
        bodyFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
      },
    },
  };
  const chartDataDonationAmount = {
    labels: donationAmountKpi?.labels || [],
    datasets: [
      {
        label: 'Donation Amount',
        data: donationAmountKpi?.data || [],
        borderColor: '#5E51AB',
        backgroundColor: '#5E51AB',
        parsing: {
          yAxisKey: 'value',
        },
      },
    ],
  };

  const optionsNumOfDonations = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderColor: '#333333',
        borderWidth: 1,
        bodyColor: '#000000',
        titleColor: '#000000',
        padding: 16,
        titleFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
        bodyFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
      },
    },
  };
  const chartDataNumOfDonations = {
    labels: numOfDonationKpi?.labels || [],
    datasets: [
      {
        label: 'Number of Donations',
        data: numOfDonationKpi?.data || [],
        borderColor: '#EB645E',
        backgroundColor: '#EB645E',
        parsing: {
          yAxisKey: 'value',
        },
      },
    ],
  };

  return (
    <div className="HubManagementAnalyticsDonations">
      <h3>Donation Analytics</h3>
      <HubManagementMetricContainer metrics={metrics} />

      <section className="chart-container">
        <h4>Donation Amount Over Time</h4>
        <div className="date-container">
          <div className="date">
            <label htmlFor="donationAmountDateFrom">From:</label>
            <DateTimePicker
              shouldValidate={false}
              inputProps={{
                ref: donationAmountDateFromRef,
                name: 'donationAmountDateFrom',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              value={donationAmountDateFrom}
              onChange={(value) => {
                setDonationAmountDateFrom(moment(value));
                sessionStorage.setItem(_SESSION_STORAGE_DONATION_AMOUNT_DATE_FROM, value as string);
              }}
              locale={locale.currentLocale}
              className="dPicker "
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(donationAmountDateFromRef)}
            />
          </div>
          <div className="date">
            <label htmlFor="donationAmountDateTo">To:</label>
            <DateTimePicker
              inputProps={{
                ref: donationAmountDateToRef,
                name: 'donationAmountDateTo',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              value={donationAmountDateTo}
              onChange={(value) => {
                setDonationAmountDateTo(moment(value));
                sessionStorage.setItem(_SESSION_STORAGE_DONATION_AMOUNT_DATE_TO, value as string);
              }}
              locale={locale.currentLocale}
              className="dPicker"
              disableDatesBefore={donationAmountDateFrom}
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(donationAmountDateToRef)}
            />
          </div>
        </div>
        <Line
          options={optionsDonationAmount}
          data={chartDataDonationAmount}
          className="chart"
        />
      </section>

      <section className="chart-container">
        <h4>Number of Donations Over Time</h4>
        <div className="date-container">
          <div className="date">
            <label htmlFor="numOfDonationDateFrom">From:</label>
            <DateTimePicker
              shouldValidate={false}
              inputProps={{
                ref: numOfDonationDateFromRef,
                name: 'numOfDonationDateFrom',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              className="dPicker"
              value={numOfDonationDateFrom}
              onChange={(value) => {
                setNumOfDonationDateFrom(moment(value));
                sessionStorage.setItem(_SESSION_STORAGE_NUM_OF_DONATION_DATE_FROM, value as string);
              }}
              locale={locale.currentLocale}
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(numOfDonationDateFromRef)}
            />
          </div>
          <div className="date">
            <label htmlFor="numOfDonationDateTo">To:</label>
            <DateTimePicker
              inputProps={{
                ref: numOfDonationDateToRef,
                name: 'numOfDonationDateTo',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              className="dPicker"
              value={numOfDonationDateTo}
              onChange={(value) => {
                setNumOfDonationDateTo(moment(value));
                sessionStorage.setItem(_SESSION_STORAGE_NUM_OF_DONATION_DATE_TO, value as string);
              }}
              locale={locale.currentLocale}
              disableDatesBefore={numOfDonationDateFrom}
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(numOfDonationDateToRef)}
            />
          </div>
        </div>
        <Line
          options={optionsNumOfDonations}
          data={chartDataNumOfDonations}
          className="chart"
        />
      </section>
    </div>
  );
};

export default HubManagementAnalyticsDonations;
