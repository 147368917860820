import React, { ChangeEvent, useEffect, useState } from 'react';
import TextField from '../TextField/TextField';
import Dropdown, { IOptions } from '../Dropdown/Dropdown';
import TextArea from '../TextArea/TextArea';
import Checkbox from '../Checkbox/Checkbox';
import AvailabilitySettings from '../AvailabilitySettings/AvailabilitySettings';
import {
  IGigSummary,
  IGroupSummary,
  IShift,
  IVolunteerMatchShiftRequest,
  IVolunteerSimpleAvailability,
  IVolunteerSimpleAvailabilityDay,
} from '@gigit/interfaces';
import moment from 'moment';
import Button from '../Button/Button';
import { gigRequestActions } from '../../requestActions';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { userSelectors } from '../../selectors/user';
import { updateUser, updateUserLocation, createUserLocation } from '../../actions/user';
import useToastDispatcher from '../../hooks/useToaster';
import { Redirect, useHistory } from 'react-router-dom';
import { volunteerForGroup } from '../../actions/group';
import { volunteerForGig } from '../../actions/gig';
import queryString from 'query-string';
import { Constants } from '@gigit/constants';
import './VolunteerApplicationForm.scss';
import Portrait from '../Portrait/Portrait';

interface IApplication {
  user_id: string;
  user_handle: string;
  group: string;
  user_capacity: string;
  application_form: IFormReply[];
  volunteer_availability: { days: IVolunteerSimpleAvailabilityDay[] } | undefined;
  shifts?: IShift[];
}

interface IFormReply {
  question_type: string;
  question_id: string;
  question_label: string;
  form_id: string;
  answer: string;
}

interface IGigApplication {
  user_id: string;
  user_handle: string;
  group: string;
  groupId: string;
  user_capacity: string;
  application_form?: IFormReply[];
  volunteer_availability: { days: IVolunteerSimpleAvailabilityDay[] } | undefined;
  comment?: string;
  vm_shifts?: IVolunteerMatchShiftRequest[];
  vm_acceptTAC?: boolean;
  gig: {
    handle: string;
    title: string;
    id: string;
  };
  shift_ids?: string[];
}

interface IProps {
  gig?: IGigSummary;
  group?: IGroupSummary;
  onClose?: () => void;
}

const VolunteerApplicationForm: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { dispatchToastError } = useToastDispatcher();
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));

  // details
  const [lastName, setLastName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [streetAddress, setStreetAddress] = useState<string>('');
  const [apt, setApt] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [postal, setPostal] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [ageCheck, setAgeCheck] = useState<boolean>(false);

  // EC
  const [ECFullName, setECFullName] = useState<string>('');
  const [ECPhone, setECPhone] = useState<string>('');

  // other
  const [volunteerAvailabilityFirstTime, setVolunteerAvailabilityFirstTime] = useState<boolean>(
    !user?.volunteer_availability,
  );
  const [volunteerAvailability, setVolunteerAvailability] = useState<
    { days: IVolunteerSimpleAvailabilityDay[] } | undefined
  >();
  const [locationId, setLocationId] = useState<string>();
  const [applicationSubmitted, setApplicationSubmitted] = useState<boolean>(false);
  const [shifts, setShifts] = useState<IShift[]>([]);
  const [redirectTo, setRedirectTo] = useState<'gig' | 'group' | undefined>();
  const [vmTac, setVmTac] = useState<boolean>(false);
  const [gigShifts, setGigShifts] = useState<IShift[]>([]);

  // TODO: add proper type
  const [responses, setResponses] = useState<any[]>([]);
  const [gigResponses, setGigResponses] = useState<any[]>([]);
  const [sortedShifts, setSortedShifts] = useState<any>({});

  useEffect(() => {
    setUserData();
  }, [userState.user.id, userState.locations]);

  useEffect(() => {
    if (props?.group?.form?.questions) {
      setFormResponse(props?.group?.form?.questions, setResponses);
    }

    if (props.gig?.form?.questions) {
      setFormResponse(props?.gig?.form?.questions, setGigResponses);
    }

    getGigShifts();
  }, [props?.group?.form, props.gig?.form]);

  function setFormResponse(formQuestions: any, setFormResponses: (responses: Array<any>) => void) {
    if (formQuestions) {
      let _responses = [];

      for (let i = 0; i < formQuestions?.length; i++) {
        if (formQuestions[i].question_type === 'checkbox' && formQuestions[i].options!.length > 0) {
          _responses.push([]);
        } else {
          _responses.push('');
        }
      }
      setFormResponses(_responses);
    }
  }

  function getCapacity(shift: IShift) {
    const assigned = shift?.assigned_users || null;
    const capacity = shift?.num_people || null;

    if (capacity && assigned) {
      const amt = capacity - assigned.length;
      const text = amt > 1 ? 'spots' : 'spot';
      return amt <= 0 ? `No spots available.` : `${amt} ${text} available.`;
    }
  }

  function isSelectedShift(shift: IShift): boolean {
    let isShiftSelected = false;
    for (let i = 0; i < shifts?.length; i++) {
      if (shifts[i].id === shift.id) isShiftSelected = true;
    }
    return isShiftSelected;
  }

  function setUserData() {
    const locationsExist = userState.locations && userState.locations[0];
    const ECexists = userState.user.emergency_contacts && userState.user.emergency_contacts[0];
    let _params = queryString.parse(history.location.search);

    setLastName(user.last_name || '');
    setFirstName(user.first_name || '');
    setEmail(user.email || '');
    setPhone(user.phone || '');
    setStreetAddress(
      locationsExist && userState.locations[0].line1 ? userState.locations[0].line1 : '',
    );
    setApt(locationsExist && userState.locations[0].line2 ? userState.locations[0].line2 : '');
    setCity(locationsExist && userState.locations[0].city ? userState.locations[0].city : '');
    setProvince(locationsExist && userState.locations[0].state ? userState.locations[0].state : '');
    setPostal(
      locationsExist && userState.locations[0].postal_code
        ? userState.locations[0].postal_code
        : '',
    );
    setCountry(
      locationsExist && userState.locations[0].country ? userState.locations[0].country : 'Canada',
    );
    setLocationId(locationsExist && (userState.locations[0].id || ''));
    setECFullName((ECexists && ECexists.name) || '');
    setECPhone((ECexists && ECexists.phone) || '');
    setVolunteerAvailabilityFirstTime(!user?.volunteer_availability);
  }

  function sortShifts(shifts: IShift[]) {
    // TODO make proper interface
    let sorted: any = {};

    for (let s in shifts) {
      if (moment(shifts[s].start_date).format('X') >= moment(new Date()).format('X')) {
        if (sorted[moment(shifts[s].start_date).format('MMMM YYYY')] === undefined) {
          sorted[moment(shifts[s].start_date).format('MMMM YYYY')] = {};
        }

        if (
          sorted[moment(shifts[s].start_date).format('MMMM YYYY')][
            moment(shifts[s].start_date).format('dddd, Do')
          ] === undefined
        ) {
          sorted[moment(shifts[s].start_date).format('MMMM YYYY')][
            moment(shifts[s].start_date).format('dddd, Do')
          ] = [];
        }

        sorted[moment(shifts[s].start_date).format('MMMM YYYY')][
          moment(shifts[s].start_date).format('dddd, Do')
        ].push(shifts[s]);
      }
    }

    setSortedShifts(sorted);
  }

  async function getGigShifts() {
    if (props.gig?.id) {
      try {
        const result = await gigRequestActions.getShifts({ gigId: props.gig.id });
        setGigShifts(result);
        sortShifts(result);
      } catch (error) {
        dispatchToastError(error, 'Gig Shifts');
      }
    }
  }

  function filterAvailability() {
    let filteredAvailability: { days: IVolunteerSimpleAvailabilityDay[] } = { days: [] };
    volunteerAvailability?.days.forEach((el: IVolunteerSimpleAvailabilityDay) => {
      let times = el;
      times.times = times.times.filter((time) => time.is_available);

      if (times.times.length) {
        filteredAvailability.days.push(times);
      }
    });

    if (filteredAvailability.days.length) {
      return filteredAvailability;
    }

    return;
  }

  async function apply() {
    let ecs;
    if (Array.isArray(user.emergency_contacts)) {
      ecs = [...user.emergency_contacts];
      ecs.splice(0, 1);
      ecs.unshift({ name: ECFullName, phone: ECPhone });
    } else {
      ecs = [{ name: ECFullName, phone: ECPhone }];
    }

    let _address = {
      line1: streetAddress,
      line2: apt,
      city: city,
      state: province,
      country: country,
      postal_code: postal,
    };

    let _userPayload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      emergency_contacts: ecs,
      volunteer_availability: volunteerAvailabilityFirstTime
        ? volunteerAvailability
        : user.volunteer_availability,
    };

    dispatch(updateUser(_userPayload));

    if (locationId) {
      dispatch(updateUserLocation(locationId, _address));
    } else {
      dispatch(createUserLocation(_address, false));
    }

    let _application = {
      user_id: user.id,
      user_handle: user.handle,
      group: props?.group?.handle || '',
      user_capacity: 'volunteer',
      application_form: [],
      volunteer_availability: filterAvailability(),
    } as IApplication;

    if (props?.group?.form?.questions) {
      for (let q in props.group?.form?.questions) {
        let _question = props.group?.form?.questions[q];

        let _form_reply = {
          question_type: _question.question_type,
          question_id: _question.id,
          question_label: _question.label,
          form_id: props?.group.form.id,
          answer: responses[q],
        } as IFormReply;

        if (_form_reply.question_type === 'dropdown') {
          if (responses[q] === '' && _question.options && _question.options.length > 0) {
            _form_reply.answer = _question.options[0].value;
          }
        }

        _application.application_form.push(_form_reply);
      }
    }

    if (shifts.length > 0) {
      _application.shifts = shifts;
    }

    if (props.gig?.id) {
      let _gig_application = {
        user_id: user.id,
        user_handle: user.handle,
        group: props.gig?.group?.handle || props.gig.group_handle,
        groupId: props.gig?.group?.id || props.gig.group_id,
        user_capacity: 'volunteer',
        application_form: [],
        volunteer_availability: volunteerAvailability,
        gig: {
          handle: props.gig.handle,
          title: props.gig.title,
          id: props.gig.id,
        },
      } as IGigApplication;

      if (props.gig.external_id && props.gig.external_id.VolunteerMatch) {
        if (props.gig.id) {
          if (comment) {
            _gig_application.comment = comment;
          }

          if (!vmTac) {
            return;
          } else {
            _gig_application.vm_acceptTAC = vmTac;
          }

          if (shifts.length > 0) {
            let shiftList: IVolunteerMatchShiftRequest[] = [];
            shifts.forEach((shift) => {
              if (shift.external_id) {
                shiftList.push({
                  id: shift.external_id,
                });
              }
            });
            _gig_application.vm_shifts = shiftList;
          }
          try {
            await gigRequestActions.volunteerMatchApplication(props.gig.id, _gig_application);
            setRedirectTo('gig');
          } catch (error) {
            dispatchToastError(error, 'Gig Shifts');
          } finally {
            props?.onClose?.();
          }
        }

        return;
      }

      if (props.gig?.form?.questions) {
        for (let q in props.gig.form.questions) {
          let _question = props.gig.form.questions[q];

          let _form_reply = {
            question_type: _question.question_type,
            question_id: _question.id,
            question_label: _question.label,
            form_id: props.gig.form.id,
            answer: gigResponses[q],
          } as IFormReply;

          if (_form_reply.question_type === 'dropdown') {
            if (
              (gigResponses[q] === '' || undefined) &&
              _question.options &&
              _question.options.length > 0
            ) {
              _form_reply.answer = _question.options[0].value;
            }
          }

          _gig_application.application_form?.push(_form_reply);
        }
      }

      if (shifts.length > 0) {
        _gig_application.shift_ids = shifts.map((shift) => shift.id ?? '');
      }

      if (!props.gig.form_id) {
        delete _gig_application.application_form;
      }

      // TODO: Volunteering also for group should be handled by BE likely?
      if (userState.currentApplications.group.group?.id !== props?.group?.id && props?.group?.id) {
        dispatch(
          volunteerForGroup(props.group.id, _application, _gig_application, () => {
            setRedirectTo('group');
            props?.onClose?.();
          }),
        );
      } else {
        dispatch(
          volunteerForGig(props.gig.id!, _gig_application, () => {
            //pop success message
            props?.onClose?.();
          }),
        );
      }
    } else if (props?.group?.id) {
      dispatch(
        volunteerForGroup(props.group.id, _application, {}, () => {
          setRedirectTo('group');
          props?.onClose?.();
        }),
      );
    }
  }

  function handleResponseChange(
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
    isGig?: boolean,
  ) {
    const target = event.target;
    const value = target.value;
    let _responses;

    if (!isGig) {
      _responses = [...responses];

      if (target.type === 'checkbox') {
        if (!responses[index].includes(value)) {
          _responses[index] = [..._responses[index], value];
        } else {
          let r = _responses[index];
          let idx = r.findIndex((i: any) => {
            return i === value;
          });
          r.splice(idx, 1);
          _responses[index] = r;
        }
      } else {
        _responses[index] = value;
      }
    } else {
      _responses = [...gigResponses];

      if (target.type === 'checkbox') {
        if (!gigResponses[index].includes(value)) {
          _responses[index] = [..._responses[index], value];
        } else {
          let r = _responses[index];
          let idx = r.findIndex((i: any) => {
            return i === value;
          });
          r.splice(idx, 1);
          _responses[index] = r;
        }
      } else {
        _responses[index] = value;
      }
    }

    if (!isGig) {
      setResponses(_responses);
    } else {
      setGigResponses(_responses);
    }
  }

  async function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setApplicationSubmitted(true);
    await apply();
    // props?.onClose?.()
  }

  function selectShift(shift: IShift) {
    let temp = [...shifts];
    if (props?.gig?.external_id?.VolunteerMatch) {
      if (!isSelectedShift(shift)) {
        if (shift?.external_id) temp.push(shift);
        setShifts(temp);
      } else {
        let filtered = shifts.filter((_shift) => {
          return shift.external_id !== _shift.external_id;
        });
        setShifts(filtered);
      }
    } else {
      if (!isSelectedShift(shift)) {
        temp.push(shift);
        setShifts(temp);
      } else {
        const indexToRemove = temp.findIndex((tempShift) => tempShift.id === shift.id);
        temp.splice(indexToRemove, 1);
        setShifts([...temp]);
      }
    }
  }

  /** used to check if we're applying to a gig opp (instead of strictly group level) */
  function isGigApplication() {
    return Boolean(props?.gig);
  }

  /** used to get parent profile image for gig, either Hub/Company or Group/Cause currently. */
  function getGigParentProfileImage() {
    if (isGigApplication()) {
      return props?.gig?.group_id
        ? props?.group?.profile_image_url
        : props?.gig?.hub?.profile_image_url;
    } else {
      return props?.group?.profile_image_url;
    }
  }

  /** TODO Should be used to grab application title. (instead of entity title) */
  function getApplicationEntityTitle() {
    return isGigApplication() ? props?.gig?.title : props?.group?.title;
  }

  /** TODO Should be used to grab application title. (instead of entity title) */
  function getApplicationFormTitle() {
    return isGigApplication() ? props?.gig?.title : props?.group?.title;
  }

  const is_volunteer_match = props?.gig?.external_id && props?.gig.external_id.VolunteerMatch;

  return (
    <div className="VolunteerApplicationForm">
      <form
        onSubmit={submitForm}
        className="inner-content"
      >
        <div className="application-form-header">
          <div className="portrait-container">
            <Portrait
              source={getGigParentProfileImage()}
              size={48}
            />
          </div>
          <div className="title-container">
            <h1 notranslate="yes">{getApplicationEntityTitle()}</h1>
            {props?.gig?.group && (
              <div>
                Charity ID: <var data-var="application_charity_id">{props?.group?.charity_id}</var>
              </div>
            )}
          </div>
        </div>
        {/* TODO: USER PROPER FORM NAME IF POSSIBLE */}
        <h2>
          Application Form: <span notranslate="yes"> {getApplicationFormTitle()} </span>
        </h2>
        <div className="details-row">
          <div className="details-col">
            <TextField
              label="First Name"
              placeholder="e.g. Jane Smith"
              required={true}
              value={firstName}
              name="firstName"
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="details-col">
            <TextField
              label="Last Name"
              placeholder="e.g. Jane Smith"
              required={true}
              value={lastName}
              name="lastName"
              type="text"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="details-row">
          <div className="details-col">
            <TextField
              label="Email"
              placeholder="e.g. name@email.com"
              required={true}
              disabled={!!user.id}
              value={email}
              name="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="details-col">
            <TextField
              label="Phone"
              placeholder="+1-000-000-0000"
              required={true}
              value={phone}
              name="phone"
              type="tel"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="details-row">
          <div className="details-col">
            <TextField
              label="Street Address"
              placeholder="e.g. 123 Main St"
              required={true}
              value={streetAddress}
              name="streetAddress"
              type="text"
              onChange={(e) => setStreetAddress(e.target.value)}
            />
          </div>
          <div className="details-col">
            <TextField
              label="Apartment / Suite #"
              placeholder="e.g. Suite 302"
              required={false}
              value={apt}
              name="apt"
              type="text"
              onChange={(e) => setApt(e.target.value)}
            />
          </div>
        </div>
        <div className="details-row">
          <div className="details-col">
            <TextField
              label="City"
              placeholder="e.g. Hamilton"
              required={true}
              value={city}
              name="city"
              type="text"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div className="details-col">
            <TextField
              label="Province / State"
              placeholder="e.g. Ontario"
              required={true}
              value={province}
              name="province"
              type="text"
              onChange={(e) => setProvince(e.target.value)}
            />
          </div>
        </div>
        <div className="details-row">
          <div className="details-col">
            <Dropdown
              name="country"
              label="Country"
              value={country}
              options={[
                { label: 'Canada', value: 'Canada' },
                { label: 'United States of America', value: 'USA' },
              ]}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <div className="details-col">
            <TextField
              label="Postal"
              placeholder="e.g. 000 000"
              required={true}
              value={postal}
              name="postal"
              type="text"
              onChange={(e) => setPostal(e.target.value)}
            />
          </div>
        </div>
        <div className="details-row">
          <TextArea
            className="comment-text-field"
            label="Comment"
            type="text"
            value={comment}
            name="description"
            charLength={300}
            required={false}
            spellCheck={false}
            placeholder="Enter Description"
            onChange={(e) => setComment(e.target.value.replace(/<\/?[^>]+(>|$)/g, ''))}
          />
        </div>
        {is_volunteer_match ? (
          <div className="details-row">
            <Checkbox
              name="vm_acceptTAC"
              onChange={() => setVmTac(!vmTac)}
              checked={vmTac}
              value="vmTac"
              color="plum-dark"
              label="I agree to VolunteerMatch"
            />
            <a
              className="tac-link"
              href="https://about.volunteermatch.org/legal#Terms"
              target="_blank"
            >
              Terms and Conditions
            </a>
          </div>
        ) : (
          <div className="details-row">
            <Checkbox
              name="ageCheck"
              onChange={() => {
                const updatedAgeCheck = !ageCheck;
                setAgeCheck(updatedAgeCheck);
                dispatch(updateUser({ age_privacy_agreement: updatedAgeCheck }));
              }}
              checked={ageCheck}
              value="ageCheck"
              color="plum-dark"
              label="Are you over the age of 18?"
            />
          </div>
        )}
        {!is_volunteer_match && (
          <>
            <h2>Emergency Contact (Optional)</h2>
            <div className="details-row">
              <div className="details-col">
                <TextField
                  label="Emergency Full Name"
                  placeholder="e.g. Jane Smith"
                  // required={true}
                  value={ECFullName}
                  name="ECFullName"
                  type="text"
                  onChange={(e) => setECFullName(e.target.value)}
                />
              </div>
              <div className="details-col">
                <TextField
                  label="Phone"
                  placeholder="+1-000-000-0000"
                  // required={true}
                  value={ECPhone}
                  name="ECPhone"
                  type="tel"
                  onChange={(e) => setECPhone(e.target.value)}
                />
              </div>
            </div>
            <h2>Availability (Optional)</h2>
            <div className="details-row availability">
              <AvailabilitySettings
                settingsTheme={true}
                onAvailabilityChange={(data) => {
                  setVolunteerAvailability({ days: data });
                }}
              />
            </div>
          </>
        )}
        {responses &&
          props?.group?.form &&
          responses.length > 0 &&
          responses.length === props?.group?.form?.questions?.length && (
            <h2>{props?.group.form.form_name}</h2>
          )}
        {responses &&
          props?.group?.form &&
          responses.length === props?.group?.form?.questions?.length &&
          props?.group?.form?.questions.map((item, index) => {
            switch (item.question_type) {
              case 'text':
                return (
                  <div
                    className="question"
                    key={index}
                  >
                    <div className="label">{item.label}</div>
                    <TextField
                      required={true}
                      value={responses[index]}
                      name=""
                      type="text"
                      onChange={(e) => {
                        handleResponseChange(e, index);
                      }}
                    />
                  </div>
                );
              case 'dropdown':
                let _options: IOptions[] = [];

                if (item?.options) {
                  for (let i of item.options) {
                    _options.push({ label: i.label, value: i.label });
                  }
                }

                return (
                  <div
                    className="question"
                    key={index}
                  >
                    <div className="label">{item.label}</div>
                    <Dropdown
                      shouldSort={true}
                      value={responses[index]}
                      name={item.label}
                      onChange={(e) => {
                        handleResponseChange(e, index);
                      }}
                      options={_options}
                    />
                  </div>
                );
              default:
                return (
                  <div
                    className="question"
                    key={index}
                  >
                    <div className="label">{item.label}</div>
                    {item.options &&
                      item.options.map((option, optionIndex) => {
                        return (
                          <Checkbox
                            key={optionIndex}
                            name={option.label}
                            onChange={(e) => handleResponseChange(e, index)}
                            checked={responses[index].includes(option.label)}
                            value={option.label}
                            color="work"
                            label={option.label}
                          />
                        );
                      })}
                  </div>
                );
            }
          })}
        {props?.gig?.id &&
          props.gig?.form?.questions &&
          gigResponses.length > 0 &&
          gigResponses.length === props.gig.form.questions.length && (
            <h2>{props.gig.form.form_name}</h2>
          )}
        {props.gig?.form?.questions &&
          gigResponses.length > 0 &&
          gigResponses.length === props.gig.form.questions.length &&
          props.gig.form.questions.map((item, index) => {
            switch (item.question_type) {
              case 'text':
                return (
                  <div
                    className="question"
                    key={index}
                  >
                    <div className="label">{item.label}</div>
                    <TextField
                      required={true}
                      value={gigResponses[index]}
                      name=""
                      type="text"
                      onChange={(e) => handleResponseChange(e, index, true)}
                    />
                  </div>
                );
              case 'dropdown':
                let _options: IOptions[] = [];

                if (item?.options) {
                  for (let i of item.options) {
                    _options.push({ label: i.label, value: i.label });
                  }
                }

                return (
                  <div
                    className="question"
                    key={index}
                  >
                    <div className="label">{item.label}</div>
                    <Dropdown
                      shouldSort={true}
                      value={gigResponses[index]}
                      name={item.label}
                      onChange={(e) => handleResponseChange(e, index, true)}
                      options={_options}
                    />
                  </div>
                );
              default:
                return (
                  <div
                    className="question"
                    key={index}
                  >
                    <div className="label">{item.label}</div>
                    {item.options &&
                      item.options.map((option, optionIndex) => {
                        return (
                          <Checkbox
                            key={optionIndex}
                            name={option.label}
                            onChange={(e) => handleResponseChange(e, index, true)}
                            checked={gigResponses[index].includes(option.label)}
                            value={option.label}
                            color="work"
                            label={option.label}
                          />
                        );
                      })}
                  </div>
                );
            }
          })}
        {gigShifts && gigShifts.length > 0 && Object.keys(sortedShifts)?.length > 0 && (
          <div className="shift-wrap">
            <h1>Shifts</h1>
            <span>
              Please select the shifts you are available for. You can still select a shift with 'No
              spots available.' and we will let you know if a position becomes available.
            </span>
            <div className="shifts">
              {Object.keys(sortedShifts).map((month: string, mIndex: number) => {
                return (
                  <div
                    className="month"
                    key={mIndex}
                  >
                    <span className="month-name">{month}</span>
                    <ul>
                      {Object.keys(sortedShifts[month]).map((day: string, dIndex: number) => {
                        return (
                          <li
                            className="day"
                            key={dIndex}
                          >
                            <span className="day-name">{day}</span>
                            {sortedShifts[month][day].map((shift: IShift, sIndex: number) => {
                              return (
                                <div
                                  className="shift-summary"
                                  key={shift.id}
                                >
                                  <Checkbox
                                    checked={isSelectedShift(shift)}
                                    name="shifts"
                                    onChange={() => {
                                      selectShift(shift);
                                    }}
                                    key={sIndex}
                                    value={shift.id}
                                    color="work"
                                    label={
                                      moment(shift.start_date).format('h:mm A') +
                                      ' - ' +
                                      moment(shift.end_date).format('h:mm A')
                                    }
                                  />
                                  <span>{getCapacity(shift)}</span>
                                </div>
                              );
                            })}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="apply-actions">
          <Button
            isDisabled={applicationSubmitted}
            text="Submit"
            type="submit"
            buttonType="dark"
            onClick={() => {
              if (!isUserLoggedIn) {
                history.replace({
                  pathname: '/login',
                  search: `?redirect=${encodeURIComponent(`${history.location.pathname}`)}`,
                });
              }
            }}
          />
          {props?.onClose && (
            <Button
              buttonType="outline-dark"
              isDisabled={applicationSubmitted}
              text="Cancel"
              type="button"
              onClick={() => props.onClose?.()}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default VolunteerApplicationForm;
