import React from 'react';
import './AddToCalendarModal.scss';
import GmailLogo from '../../../assets/gmail_logo.png';
import AppleLogo from '../../../assets/apple_logo.png';
import OutlookLogo from '../../../assets/outlook_logo.png';
import AndroidLogo from '../../../assets/android_logo.png';
import { google, outlook, ics } from 'calendar-link';
import Modal from '../../Modal/Modal';

interface IProps {
  showModal: boolean;
  onCloseModal(): void;
  eventToAdd: IEventToAdd;
}

interface IEventToAdd {
  title: string;
  start: Date;
  end: Date;
  location?: string;
}

interface IAddToCalendarButton {
  link: string;
  label: string;
  img: string;
}

const AddToCalendarModal = (props: IProps) => {
  const calendarTypes = ['Gmail', 'iPhone', 'Outlook', 'Android'];

  const addToCalendarButtons: IAddToCalendarButton[] = [
    {
      link: google(props.eventToAdd),
      label: calendarTypes[0],
      img: GmailLogo,
    },
    {
      link: ics(props.eventToAdd),
      label: calendarTypes[1],
      img: AppleLogo,
    },
    {
      link: outlook(props.eventToAdd),
      label: calendarTypes[2],
      img: OutlookLogo,
    },
    {
      link: ics(props.eventToAdd),
      label: calendarTypes[3],
      img: AndroidLogo,
    },
  ];

  return (
    <Modal
      show={props.showModal}
      onClose={props.onCloseModal}
    >
      <div className="AddToCalendarModal">
        <div className="add-to-calendar-head">Add To Calendar</div>
        <div
          className="calendar-options"
          notranslate="yes"
        >
          {addToCalendarButtons.map((button, index) => {
            return (
              <div
                className={`button-container ${button.label}`}
                key={index}
              >
                <a
                  href={button.link}
                  target="_blank"
                >
                  <img
                    className="button-img"
                    src={button.img}
                  ></img>
                  <div className="button-label">{button.label}</div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default AddToCalendarModal;
