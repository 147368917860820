import {
  IAuctionItemSummary,
  ICampaign,
  IFeeControl,
  IPaymentIntentResponse,
  ITransactionAmounts,
  ITransactionItemSummary,
} from '@gigit/interfaces';
import { Reducer } from 'redux';
import { AuctionActions, AuctionActionTypes } from '../actions/auction';

export interface IAuctionState {
  buyNowSummary: any;
  paymentSummary: ITransactionAmounts | null;
  paymentSummaryLoading: boolean;
  paymentIntent: IPaymentIntentResponse | null;
  buyNowIntent: IPaymentIntentResponse | null;
  buyNowError: string | null;
  buyNowLocked: boolean | false;
  feeControl: IFeeControl | null;

  auctionPayments: ITransactionItemSummary[];
  auctionPaymentsLoading: boolean;
}

export const initialAuctionState: IAuctionState = {
  paymentSummary: null,
  paymentSummaryLoading: false,
  paymentIntent: null,
  buyNowIntent: null,
  buyNowError: null,
  buyNowLocked: false,
  feeControl: null,
  buyNowSummary: null,
  auctionPayments: [],
  auctionPaymentsLoading: false,
};

export const auctionReducer: Reducer<IAuctionState, AuctionActions> = (
  state = initialAuctionState,
  action,
) => {
  switch (action.type) {
    case AuctionActionTypes.UPDATE_AUCTION: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
