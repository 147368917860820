import { Constants } from '@gigit/constants';
import { IHub, IPage, IPageComponent } from '@gigit/interfaces';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DraggableProvidedDragHandleProps, DraggableStateSnapshot } from 'react-beautiful-dnd';
import useToastDispatcher from '../../../../hooks/useToaster';
import { IHubComponent, IOwnerObject } from '../../../../interfaces';
import { hubRequestActions } from '../../../../requestActions';
import Button from '../../../Button/Button';
import ContentBlock from '../../ContentBlock/ContentBlock';
import { EditOverlayParams, IHubState, IThemeStyleObjectsByType } from '../../Hub';
import ContentBlockManageModal from '../../HubModals/ContentBlockModals/ContentBlockManageModal/ContentBlockManageModal';
import './PageComponent.scss';
import HubAuctionSimplifiedView from '../../HubAuction/HubAuctionSimplifiedView/HubAuctionSimplifiedView';
import HubAuction from '../../HubAuction/HubAuction';
import HubStoreSimplifiedView from '../../HubStore/HubStoreSimplifiedView/HubStoreSimplifiedView';
import { HubStore } from '../../HubStore/HubStore';
import HubVolunteering from '../../HubVolunteering/HubVolunteering';
import HubEventsCarousel from '../../HubStoriesEvents/HubEventsCarousel/HubEventsCarousel';
import HubActivityFeed from '../../HubActivityFeed/HubActivityFeed';
import HubMetricsComponent from '../../HubMetrics/HubMetrics';
import { combineClassNames } from '../../../../helpers';
import HubEvents from '../../HubEvents/HubEvents';
import HubCauses from '../../HubCauses/HubCauses';
import { userSelectors } from '../../../../selectors/user';
import { IAppState } from '../../../../store';
import SocialImpactBenefitsComponent from '../../SocialImpactComponent/SocialImpactBenefitsComponent';
import SocialImpactBenefitsModal from '../../HubModals/SocialImpactBenefitsModal/SocialImpactBenefitsModal';
import ConsolidatedListView from '../ConsolidatedListView/ConsolidatedListView';
import FocusAreaSettings from '../ConsolidatedListView/ConsolidatedListModals/CausesFocusAreas/FocusAreaSettings';

type HubPageComponentTypes = keyof typeof Constants.hub_page_component_types;
type IndexablePageComponentConfiguration = {
  [key: string]: {
    /** Header properties for renderPageComponentHeader */
    header?: {
      showComponentTitle: boolean;
      action?: {
        label: string;
        onClick: () => void;
      };
    };
    /** Used on pages with multiple components */
    simplifiedComponentRenderer?: () => JSX.Element;
    /** Used on pages with one component */
    detailedComponentRenderer?: () => JSX.Element;
    manageComponentModalRenderer?: () => JSX.Element;
  };
};

interface IPageContainerProps {
  hub: IHub | null;
  ownerObject: IOwnerObject;
  page: IPage | null;
  isPageLoading: boolean;
  /** Has a meta_data.render_detailed_version type */
  pageComponent: IHubComponent;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  draggableSnapshot?: DraggableStateSnapshot;
  showEditMode: boolean;
  theme: IThemeStyleObjectsByType;
  onPageComponentSave: () => void;
  userHasEditPermissions: boolean;
  /** Component header CTA Button */
  onHeaderActionClick: (index: number) => void;
  dispatchToggleModalByKey(key: keyof IHubState, value: boolean): void;
  tryRefreshHub(): void;
}

function PageComponent(props: IPageContainerProps) {
  const { dispatchToastError } = useToastDispatcher();
  const [showEditModalByType, setShowEditModalByType] = useState<HubPageComponentTypes | null>(
    null,
  );
  const [isContentBlockManageModalSaving, setIsContentBlockModalSaving] = useState(false);
  const [pageComponent, setPageComponent] = useState<IHubComponent>(props.pageComponent);
  const [currentComponentType, setCurrentComponentType] = useState<HubPageComponentTypes>(
    props.pageComponent.component_type as HubPageComponentTypes,
  );
  const [isComponentDragging, setIsComponentDragging] = useState(false);
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );

  const { dragHandleProps, draggableSnapshot, showEditMode, ownerObject } = props;
  const showComponentEditOverlay =
    pageComponent.meta_data?.render_detailed_version == null && showEditMode;

  useEffect(() => {
    setCurrentComponentType(props.pageComponent.component_type as HubPageComponentTypes);
  }, [props.pageComponent.component_type]);

  const pageComponentsConfigurationByType: IndexablePageComponentConfiguration = {
    auction: {
      header: {
        showComponentTitle: true,
        action: {
          label: 'View All',
          onClick: () => props.onHeaderActionClick(6),
        },
      },
      simplifiedComponentRenderer: () => (
        <HubAuctionSimplifiedView
          hub={props.hub}
          userHasEditPermissions={props.userHasEditPermissions}
        />
      ),
      detailedComponentRenderer: () => (
        <HubAuction
          adminMode={showEditMode}
          hub={props.hub}
          theme={props.theme}
          userHasEditPermissions={props.userHasEditPermissions}
        />
      ),
    },
    causes: {
      simplifiedComponentRenderer: () => (
        <ConsolidatedListView
          component_type={Constants.hub_page_component_types.causes}
          owner_type={Constants.page_owner_object_type.hub}
          userHasEditPermissions={props.userHasEditPermissions}
          hub={props.hub}
          owner_id={props.hub?.id}
        />
      ),
      detailedComponentRenderer: () => (
        <HubCauses
          page={props.page}
          pageComponent={pageComponent}
          userHasEditPermissions={props.userHasEditPermissions}
          hub={props.hub}
        />
      ),
    },
    focus_areas: {
      simplifiedComponentRenderer: () => (
        <ConsolidatedListView
          page={props.page}
          component_type={Constants.hub_page_component_types.focus_areas}
          owner_type={Constants.page_owner_object_type.hub}
          userHasEditPermissions={props.userHasEditPermissions}
          handleSavePageComponent={handleSavePageComponent}
          isContentBlockManageModalSaving={isContentBlockManageModalSaving}
          hub={props.hub}
          owner_id={props.hub?.id}
        />
      ),
      manageComponentModalRenderer: () => (
        <FocusAreaSettings
          hub={props.hub}
          showModal={showEditModalByType === 'focus_areas'}
          pageComponent={pageComponent}
          isSaving={isContentBlockManageModalSaving}
          handleSavePageComponent={handleSavePageComponent}
          onClose={closeActiveManageModal}
        />
      ),
    },
    content_block: {
      simplifiedComponentRenderer: () => (
        <ContentBlock contentBlocks={pageComponent.content || []} />
      ),
      manageComponentModalRenderer: () => (
        <ContentBlockManageModal
          showModal={showEditModalByType === 'content_block'}
          pageComponent={pageComponent}
          isSaving={isContentBlockManageModalSaving}
          handleSavePageComponent={handleSavePageComponent}
          onClose={closeActiveManageModal}
        />
      ),
    },
    livestream: {
      simplifiedComponentRenderer: () => <div>TODO: Component Here</div>,
      detailedComponentRenderer: () => <div>TODO: Component Here</div>,
    },
    metrics: {
      simplifiedComponentRenderer: () => <HubMetricsComponent hub={props.hub} />,
      detailedComponentRenderer: () => <div>TODO: Component Here</div>,
    },
    store: {
      header: {
        showComponentTitle: true,
        action: {
          label: 'View All',
          onClick: () => props.onHeaderActionClick(5),
        },
      },
      simplifiedComponentRenderer: () => (
        <HubStoreSimplifiedView
          hub={props.hub}
          theme={props.theme}
          userHasEditPermissions={props.userHasEditPermissions}
        />
      ),
      detailedComponentRenderer: () => (
        <HubStore
          adminMode={showEditMode}
          hub={props.hub}
          theme={props.theme}
          userHasEditPermissions={props.userHasEditPermissions}
        />
      ),
    },
    stories_and_events: {
      header: {
        showComponentTitle: true,
        action: {
          label: 'View All',
          onClick: () => props.onHeaderActionClick(3),
        },
      },
      simplifiedComponentRenderer: () => (
        <HubEventsCarousel
          hub={props.hub}
          theme={props.theme}
        />
      ),
      detailedComponentRenderer: () => <div>TODO: Component Here</div>,
    },
    volunteering: {
      simplifiedComponentRenderer: () => <></>,
      detailedComponentRenderer: () => (
        <HubVolunteering
          adminMode={showEditMode}
          hub={props.hub}
          userHasEditPermissions={props.userHasEditPermissions}
          isUserLoggedIn={isUserLoggedIn}
          theme={props.theme}
        />
      ),
    },
    activity: {
      simplifiedComponentRenderer: () => <></>,
      detailedComponentRenderer: () => (
        <HubActivityFeed
          adminMode={showEditMode}
          hub={props.hub}
          theme={props.theme}
        />
      ),
    },
    events: {
      simplifiedComponentRenderer: () => <></>,
      detailedComponentRenderer: () => (
        <HubEvents
          hub={props.hub}
          page={props.page}
          userHasEditPermissions={props.userHasEditPermissions}
          theme={props.theme}
        />
      ),
    },
    social_impact_component: {
      header: {
        showComponentTitle: true,
      },
      simplifiedComponentRenderer: () => (
        <SocialImpactBenefitsComponent
          hub={props.hub}
          toggleShowMangeSocialBenefitsModal={(showModal) => {
            props.dispatchToggleModalByKey('showSocialImpactBenefitsModal', showModal);
          }}
          userHasEditPermissions={props.userHasEditPermissions}
        />
      ),
      manageComponentModalRenderer: () => (
        <SocialImpactBenefitsModal
          hub={props.hub}
          showModal={showEditModalByType === 'social_impact_component'}
          tryRefreshHub={props.tryRefreshHub}
          onClose={closeActiveManageModal}
        />
      ),
    },
  };
  const currentComponentConfig = getComponentConfig(currentComponentType);

  async function handleSavePageComponent(pageComponent: IPageComponent) {
    if (props.page) {
      setIsContentBlockModalSaving(true);
      try {
        const page = await hubRequestActions.updateHubPageComponent(
          ownerObject.ownerId,
          props.page.id,
          pageComponent.id,
          pageComponent,
        );
        const newComponent = page.components?.find(
          (component) => component.id === pageComponent.id,
        );

        if (newComponent) {
          setPageComponent(newComponent);
        }
        props.onPageComponentSave();
        closeActiveManageModal();
      } catch (error) {
        dispatchToastError(error, 'Error Saving Component');
      } finally {
        setIsContentBlockModalSaving(false);
      }
    }
  }

  function closeActiveManageModal() {
    setShowEditModalByType(null);
  }

  function getComponentConfig(componentType: string) {
    if (componentType in pageComponentsConfigurationByType) {
      return pageComponentsConfigurationByType[componentType];
    }

    return null;
  }

  function renderPageComponentHeader() {
    return (
      <div className="page-component-header">
        <h2>{pageComponent?.title}</h2>

        {currentComponentConfig?.header?.action && (
          <Button onClick={currentComponentConfig?.header?.action?.onClick}>
            {currentComponentConfig?.header?.action?.label}
          </Button>
        )}
      </div>
    );
  }

  function renderPageComponent() {
    //TODO: this is a temporary solution for Hubs Beta. In the future, all pages will be able to render multiple components. Not just Home.
    if (props?.page?.label === 'Home') {
      return (
        <Fragment>
          {currentComponentConfig?.header && renderPageComponentHeader()}
          {currentComponentConfig?.simplifiedComponentRenderer?.()}
        </Fragment>
      );
    }

    return currentComponentConfig?.detailedComponentRenderer?.();
  }

  //TODO: Properly implement draggable props
  function renderEditOverlay(param?: EditOverlayParams) {
    return (
      <>
        <div className="edit-mode-overlay">
          {param?.draggable && (
            <i
              className="far fa-grip-lines icon-button drag"
              {...dragHandleProps}
            />
          )}
          <div className="custom-actions-container">
            {/* { param?.onDelete &&
                        <Button icon='far fa-trash-alt' onClick={param.onDelete}/>
                    } */}
            {param?.onEdit && (
              <Button
                icon="fas fa-pen"
                onClick={param.onEdit}
              />
            )}
          </div>
        </div>
      </>
    );
  }

  function renderCurrentComponentManageModal() {
    return currentComponentConfig?.manageComponentModalRenderer?.();
  }

  const editOverContainerClassName = showComponentEditOverlay ? 'edit-overlay-container' : '';
  const detailedOrSimplifiedBaseClassName = props.pageComponent.meta_data?.render_detailed_version
    ? 'detailed-component'
    : 'simplified-component';
  const combinedClassNames = combineClassNames(
    editOverContainerClassName,
    detailedOrSimplifiedBaseClassName,
  );
  return (
    <section className={`PageComponentContainer ${combinedClassNames}`}>
      {currentComponentType === Constants.hub_page_component_types.causes
        ? renderEditOverlay({
            draggable: true,
            onDelete: () => {},
          })
        : renderEditOverlay({
            draggable: true,
            onDelete: () => {},
            onEdit: () => {
              setShowEditModalByType(currentComponentType);
            },
          })}

      {renderPageComponent()}

      {/* Manage Component Modals */}
      {renderCurrentComponentManageModal()}
    </section>
  );
}

export default PageComponent;
