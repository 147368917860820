import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';

import Button from '../Button/Button';

import noMobile from '../../assets/no-mobile.png';

import './Construction.scss';

interface IProps extends WithTranslation, RouteComponentProps<any> {}

interface IState {}

class Construction extends React.Component<IProps, IState> {
  render() {
    let { t } = this.props;

    return (
      <div className="Construction">
        <div className="image-wrap">
          <img
            alt="Not optimized for mobile screens"
            src={noMobile}
          />
        </div>
        <div className="Construction-content">
          <div className="title">
            {t('This feature is currently not optimized for mobile devices.')}
          </div>
          <div className="sub-title">
            {t(
              'We are working diligently to provide you with the best possible user experience. Check back soon for updates!',
            )}
          </div>
          <Button
            text={t('Go Back')}
            onClick={() => {
              this.props.history.goBack();
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(Construction),
);
