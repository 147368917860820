import { Constants } from '@gigit/constants';
import { IAppState } from '../store';
import { IGroupState } from '../reducers/group';
//TODO: Maybe start using redux selectors & reselect for memoization? Examples and reasoning here. https://daveceddia.com/redux-selectors/

export namespace groupSelectors {
  //Selector for checking if group has a campaign
  export function getDoesGroupHaveCampaign(state: IAppState): boolean {
    const { groupState } = state;
    return groupState.currentCampaign != null;
  }

  //Selector for checking if store is monetized
  export function getIsGroupMonetized(state: IAppState): boolean {
    const { groupState } = state;
    return !!groupState.group.account;
  }

  export function getGroupState(state: IAppState): IGroupState {
    return state.groupState;
  }

  /** Returns the primary hub id if the current group is the main hub group. */
  export function GetGroupMainHubId(state: IAppState) {
    const primaryHub = state.groupState.group.hubs?.find(
      (hub) => hub.is_primary && hub.group_status?.code === Constants.hub_group_status.main,
    );

    return primaryHub?.hub_id || null;
  }
}
