import React, { FC, useState, useEffect } from 'react';
import Button from '../../components/Button/Button';
import './FormManagement.scss';
import { ApplicationFormBuilder } from '../shared/ApplicationFormBuilder/ApplicationFormBuilder';
import { IForm, IGigSummary, IGroupSummary } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { gigRequestActions, groupRequestActions } from '../../requestActions';
import useToastDispatcher from '../../hooks/useToaster';
import Loader from '../Loader/Loader';
import { localizeHelpers } from '../../localizeHelpers';
import { uiConstants } from '../../constants';

interface IProps {
  ownerId?: string;
  ownerType: string;
  storeItemData?: {
    initForm?: IForm;
    onSave(formData: IForm): void;
    storeItemContentModalRef: React.RefObject<HTMLDivElement>;
    storeItemContentModalHideScroll(v: boolean): void;
  };
}

const FormManagement: FC<IProps> = (props) => {
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const [loading, setLoading] = useState<boolean>(true);
  const [enableSave, setEnableSave] = useState<boolean>(true);
  const [form, setForm] = useState<IForm | undefined>();
  const [ownerObj, setOwnerObj] = useState<IGigSummary | IGroupSummary | null>(null);

  async function getGroup() {
    if (props?.ownerId) {
      try {
        setLoading(true);
        let result = await groupRequestActions.getGroupByHandleOrId(props.ownerId);
        setOwnerObj(result);
      } catch (err) {
        dispatchToastError(err, 'Get Cause');
      } finally {
        setLoading(false);
      }
    }
  }

  async function updateGroupFrom() {
    if (form && props?.ownerId) {
      try {
        await groupRequestActions.updateGroup(props.ownerId, { form });
        await getForm();
        dispatchToastSuccess(
          localizeHelpers.translate('Form updated successfully.'),
          'Update Cause Form',
        );
      } catch (err) {
        dispatchToastError(err, 'Update Cause Form');
      }
    }
  }

  async function getVolunteerOpportunity() {
    if (props?.ownerId) {
      try {
        setLoading(true);
        let result = await gigRequestActions.getGigByHandle(props.ownerId);
        setOwnerObj(result);
      } catch (err) {
        dispatchToastError(err, 'Get Volunteer Opportunity');
      } finally {
        setLoading(false);
      }
    }
  }

  async function updateVolunteerOpportunityForm() {
    if (form && props?.ownerId) {
      try {
        const tmpGigObj = ownerObj as IGigSummary;
        await gigRequestActions.updateGig(props.ownerId, {
          group_id: tmpGigObj?.group_id,
          event_id: tmpGigObj?.event_id,
          form,
        });
        dispatchToastSuccess(
          localizeHelpers.translate('Form updated successfully.'),
          'Update Volunteer Opportunity Form',
        );
      } catch (err) {
        dispatchToastError(err, 'Update Volunteer Opportunity From');
      }
    }
  }

  async function getForm() {
    switch (props.ownerType) {
      case Constants.object_type.group:
        await getGroup();
        break;
      case Constants.object_type.gig:
      case uiConstants.ownerType.company:
        await getVolunteerOpportunity();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (ownerObj && ownerObj?.form) {
      setForm(ownerObj.form);
    }
  }, [ownerObj]);

  useEffect(() => {
    if (props?.storeItemData) {
      if (props.storeItemData?.initForm) {
        setForm(props.storeItemData.initForm);
      }
      setLoading(false);
    }
  }, [props.storeItemData]);

  useEffect(() => {
    getForm();
  }, []);

  async function saveForm() {
    setEnableSave(false);
    switch (props.ownerType) {
      case Constants.object_type.group:
        await updateGroupFrom();
        break;
      case Constants.object_type.gig:
      case uiConstants.ownerType.company:
        await updateVolunteerOpportunityForm();
        break;
      default:
        break;
    }
    setEnableSave(true);
  }

  function updateCurrentFormData(updatedFormData: Partial<IForm>) {
    const newFormData = { ...form, ...updatedFormData } as IForm;
    setForm(newFormData);
  }

  return (
    <div className="FormManagement section-wrap">
      <div className="section-inner">
        {!props?.storeItemData && <div className="title">Form</div>}

        {props.ownerType && !loading && (
          <>
            <ApplicationFormBuilder
              ownerId={props.ownerId}
              ownerType={props.ownerType}
              updateFormData={(formData) => updateCurrentFormData(formData)}
              form={form}
              storeItemContentModalRef={props?.storeItemData?.storeItemContentModalRef}
              storeItemContentModalHideScroll={(v) =>
                props?.storeItemData?.storeItemContentModalHideScroll?.(v)
              }
            />
            <div className="form-actions">
              <Button
                type="button"
                isDisabled={!enableSave}
                onClick={async () => {
                  if (props?.storeItemData?.onSave && form) {
                    props.storeItemData.onSave(form);
                  } else {
                    await saveForm();
                  }
                }}
                text="Save Changes"
                buttonType="dark"
                icon="fa fa-save"
              />
            </div>
          </>
        )}
        {loading && <Loader loading={loading} />}
      </div>
    </div>
  );
};

export default FormManagement;
