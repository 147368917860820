import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import Search from './routes/Search/Search';
import User from './routes/User/User';
import Group from './routes/Group/Group';
import GroupAdmin from './routes/GroupAdmin/GroupAdmin';
import Event from './routes/Event/Event';
import EventAdmin from './routes/EventAdmin/EventAdmin';
import Login from './routes/Login/Login';
import CreateProfile from './routes/CreateProfile/CreateProfile';
import CreateGroup from './routes/CreateGroup/CreateGroup';
import NotFound from './routes/NotFound/NotFound';
import NotificationCenter from './routes/NotificationCenter/NotificationCenter';
import Admin from './routes/Admin/Admin';
import GetStarted from './routes/GetStarted/GetStarted';
import Gig from './routes/Gig/Gig';
import Team from './routes/Team/Team';
import Volunteer from './routes/Volunteer/Volunteer';
import StripeRedirect from './routes/StripeRedirect/StripeRedirect';
import Donate from './routes/Donate/Donate';
import Checkout from './routes/Checkout/Checkout';
import ResetPassword from './routes/ResetPassword/ResetPassword';
import VerifyEmail from './routes/VerifyEmail/VerifyEmail';
import CheckIn from './routes/CheckIn/CheckIn';
import Individual from './routes/Individual/Individual';
import ForgotPassword from './routes/ForgotPassword/ForgotPassword';
import GigAdmin from './routes/GigAdmin/GigAdmin';
import ErrorBoundry from './components/ErrorBoundry/ErrorBoundry';
import Pledge from './routes/Pledge/Pledge';
import Register from './routes/Register/Register';
import Setup from './routes/Setup/Setup';
import Flow from './routes/Flows/Flow';
import ProgressBar from './routes/Widgets/ProgressBar/ProgressBar';
import AlertBox from './routes/Widgets/AlertBox/AlertBox';
import TwitchConnect from './routes/TwitchConnect/TwitchConnect';
import IndividualManage from './routes/IndividualManage/IndividualManage';
import TeamManage from './routes/TeamManage/TeamManage';
import MyPages from './routes/MyPages/MyPages';
import StravaConnect from './routes/StravaConnect/StravaConnect';
import Embed from './routes/Embed/Embed';
import SetPassword from './routes/SetPassword/SetPassword';
import ApplicationSuccess from './routes/ApplicationSuccess/ApplicationSuccess';
import CreateAGroup from './routes/CreateAGroup/CreateAGroup';
import { InviteAcceptOrReject } from './components/inviteAcceptOrReject/inviteAcceptOrReject';
import { ForOrganizations } from './components/ForOrganizations/ForOrganizations';
import Landing from './routes/Landing/Landing';
import DiscoverGroups from './routes/DiscoverPages/DiscoverGroups';
import DiscoverVolunteers from './routes/DiscoverPages/DiscoverVolunteers';
import FlowSuccess from './routes/FlowSuccess/FlowSuccess';
import UserDashboard from './components/UserDashboard/UserDashboard';
import Hub from './components/Hub/Hub';
import HubManagement from './components/Hub/HubManagement/HubManagement';
import CreateAnEvent from './routes/CreateAnEvent/CreateAnEvent';
import CreateAGig from './routes/CreateAGig/CreateAGig';
import CreateStory from './routes/CreateStory/CreateStory';
import CreateAHub from './routes/CreateAHub/CreateAHub';
import DiscoverHubs from './routes/DiscoverPages/DiscoverHubs';
import KambeoSearch from './routes/Search/KambeoSearch';
import { SharedModalContextProvider } from './contexts/SharedModalContext';

interface Props extends RouteComponentProps<any> {}
interface State {}

class Router extends React.Component<Props, State> {
  render() {
    return (
      <main
        className={`${
          this.props.location.pathname.includes('/company/') &&
          !this.props.location.pathname.includes('/admin')
            ? 'no-padding-top'
            : ''
        }`}
      >
        <SharedModalContextProvider>
          <ErrorBoundry>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Landing {...props} />}
              />
              {/* Account connecting */}
              <Route
                exact
                path="/stripe/connect"
                render={(props) => <StripeRedirect {...props} />}
              />
              <Route
                exact
                path="/twitch/connect"
                render={(props) => <TwitchConnect {...props} />}
              />
              <Route
                exact
                path="/strava/connect"
                render={(props) => <StravaConnect {...props} />}
              />
              {/* User */}
              <Route
                exact
                path="/login"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/reset-password"
                render={(props) => <ResetPassword {...props} />}
              />
              <Route
                exact
                path="/set-password"
                render={(props) => <SetPassword {...props} />}
              />
              <Route
                exact
                path="/forgot-password"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path="/verify-email"
                render={(props) => <VerifyEmail {...props} />}
              />
              <Route
                exact
                path="/register/:step"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/register"
                render={(props) => (
                  <Redirect
                    to={{ pathname: '/register/baseUserInfo', search: props.location.search }}
                  />
                )}
              />
              <Route
                exact
                path="/account"
                render={(props) => <Redirect to="/dashboard?section=settings" />}
              />
              <Route
                exact
                path="/user/:username"
                render={(props) => <User {...props} />}
              />
              <Route
                exact
                path="/my-pages"
                render={(props) => <MyPages {...props} />}
              />
              <Route
                exact
                path="/notifications"
                render={(props) => <NotificationCenter {...props} />}
              />
              <Route
                exact
                path="/admin"
                render={(props) => <Admin {...props} />}
              />
              <Route
                exact
                path="/get-started"
                render={(props) => <GetStarted {...props} />}
              />
              <Route
                exact
                path="/create-profile"
                render={(props) => <CreateProfile {...props} />}
              />
              <Route
                exact
                path="/dashboard"
                render={(props) => <UserDashboard {...props} />}
              />
              <Route
                exact
                path="/search"
                render={(props) => <KambeoSearch />}
              />
              {/* Discover routes */}
              <Route
                exact
                path="/discover/events"
                render={(props) => <Landing {...props} />}
              />
              <Route
                exact
                path="/discover/groups"
                render={(props) => <DiscoverGroups {...props} />}
              />
              <Route
                exact
                path="/discover/volunteers"
                render={(props) => <DiscoverVolunteers {...props} />}
              />
              <Route
                exact
                path="/discover/companies"
                render={(props) => <DiscoverHubs {...props} />}
              />
              {/* Group routes */}
              <Route
                exact
                path="/group/:handle"
                render={(props) => <Group {...props} />}
              />
              <Route
                exact
                path="/group/:handle/accept_invite"
                render={(props) => (
                  <Group {...props}>
                    {(ownerObj) => (
                      <InviteAcceptOrReject
                        {...props}
                        owner={ownerObj}
                        action="accept"
                      />
                    )}
                  </Group>
                )}
              />
              <Route
                exact
                path="/group/:handle/cancel_invite"
                render={(props) => (
                  <Group {...props}>
                    {(ownerObj) => (
                      <InviteAcceptOrReject
                        {...props}
                        owner={ownerObj}
                        action="reject"
                      />
                    )}
                  </Group>
                )}
              />
              <Route
                exact
                path="/group/:handle/admin"
                render={(props) => <GroupAdmin {...props} />}
              />
              <Route
                exact
                path="/group/:handle/admin/stripe_reconciliation/:campaignId"
                render={(props) => <GroupAdmin {...props} />}
              />
              <Route
                exact
                path="/group/:groupId/gig/:gigId/application_success"
                render={(props) => <ApplicationSuccess {...props} />}
              />
              <Route
                exact
                path="/group/:groupId/application_success"
                render={(props) => <ApplicationSuccess {...props} />}
              />
              <Route
                exact
                path="/group/:handle/pledge"
                render={(props) => <Pledge {...props} />}
              />
              {/* Event routes */}
              <Route
                exact
                path="/event/:handle"
                render={(props) => (
                  <Event
                    {...props}
                    key={props.location.key}
                  />
                )}
              />{' '}
              {/* key used to force re-render when navigating to child events. */}
              <Route
                exact
                path="/event/:handle/accept_invite"
                render={(props) => (
                  <Event {...props}>
                    {(ownerObj) => (
                      <InviteAcceptOrReject
                        {...props}
                        owner={ownerObj}
                        action="accept"
                      />
                    )}
                  </Event>
                )}
              />
              <Route
                exact
                path="/event/:handle/cancel_invite"
                render={(props) => (
                  <Event {...props}>
                    {(ownerObj) => (
                      <InviteAcceptOrReject
                        {...props}
                        owner={ownerObj}
                        action="reject"
                      />
                    )}
                  </Event>
                )}
              />
              <Route
                exact
                path="/event/:handle/admin"
                render={(props) => (
                  <EventAdmin
                    {...props}
                    key={props.location.key}
                  />
                )}
              />
              <Route
                exact
                path="/event/:handle/team/:id"
                render={(props) => <Team {...props} />}
              />
              <Route
                exact
                path="/event/:eventHandle/team/:teamHandle/manage"
                render={(props) => <TeamManage {...props} />}
              />
              <Route
                exact
                path="/event/:eventHandle/individual/:individualHandle"
                render={(props) => <Individual {...props} />}
              />
              <Route
                exact
                path="/event/:eventHandle/individual/:individualHandle/manage"
                render={(props) => <IndividualManage {...props} />}
              />
              {/* Gig Routes */}
              <Route
                exact
                path="/gig/:id"
                render={(props) => <Gig {...props} />}
              />
              <Route
                exact
                path="/gig/:id/admin"
                render={(props) => <GigAdmin {...props} />}
              />
              {/* Donate / Checkout / Checkin */}
              <Route
                exact
                path="/:type/:handle/donate"
                render={(props) => <Donate {...props} />}
              />
              <Route
                exact
                path="/:type/:handle/:subType/:subHandle/donate"
                render={(props) => <Donate {...props} />}
              />
              <Route
                exact
                path="/checkout"
                render={(props) => (
                  <Checkout
                    {...props}
                    key={props.location.key}
                  />
                )}
              />
              <Route
                exact
                path="/check-in"
                render={(props) => <CheckIn {...props} />}
              />
              {/* Widgets */}
              <Route
                exact
                path="/widget/progress_bar/:id"
                render={(props) => <ProgressBar {...props} />}
              />
              <Route
                exact
                path="/widget/alert_box/:id"
                render={(props) => <AlertBox {...props} />}
              />
              {/* Setup "Flow" routes */}
              <Route
                exact
                path="/setup"
                render={(props) => <Setup {...props} />}
              />
              <Route
                exact
                path="/setup/donations"
                render={(props) => (
                  <Flow
                    key="donations"
                    flowType="donations"
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/setup/store"
                render={(props) => (
                  <Flow
                    key="createStore"
                    flowType="createStore"
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/setup/auction"
                render={(props) => (
                  <Flow
                    key="createAuction"
                    flowType="createAuction"
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/setup/donate"
                render={(props) => (
                  <Flow
                    key="findGroups"
                    flowType="findGroups"
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/setup/findEvents"
                render={(props) => (
                  <Flow
                    key="findEvents"
                    flowType="findEvents"
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/setup/volunteer"
                render={(props) => (
                  <Flow
                    key="volunteer"
                    flowType="volunteer"
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/setup/fundraise"
                render={(props) => (
                  <Flow
                    key="fundraise"
                    flowType="fundraise"
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/setup/:ownerType/:ownerId/success"
                render={(props) => (
                  <FlowSuccess
                    key="flowSuccess"
                    {...props}
                  />
                )}
              />
              {/* Onboarding "Flows". */}
              <Route
                exact
                path="/onboarding/group/:step"
                render={(props) => <CreateAGroup {...props} />}
              />
              <Route
                exact
                path="/onboarding/group"
                render={(props) => <Redirect to="/onboarding/group/groupTypeSelection" />}
              />
              <Route
                exact
                path="/onboarding/event/:step"
                render={(props) => <CreateAnEvent {...props} />}
              />
              <Route
                exact
                path="/onboarding/event"
                render={(props) => <Redirect to="/onboarding/event/eventTypeSelection" />}
              />
              <Route
                exact
                path="/onboarding/gig/:step"
                render={(props) => <CreateAGig {...props} />}
              />
              <Route
                exact
                path="/onboarding/gig"
                render={(props) => <Redirect to="/onboarding/gig/groupSelection" />}
              />
              <Route
                exact
                path="/create/group"
                render={(props) => <CreateGroup {...props} />}
              />
              {/* Embed */}
              <Route
                exact
                path="/embed/:handle/:type"
                render={(props) => <Embed {...props} />}
              />
              <Route
                exact
                path="/widget/progress_bar/:id"
                render={(props) => <ProgressBar {...props} />}
              />
              <Route
                exact
                path="/widget/alert_box/:id"
                render={(props) => <AlertBox {...props} />}
              />
              <Route
                exact
                path="/twitch/connect"
                render={(props) => <TwitchConnect {...props} />}
              />
              <Route
                exact
                path="/strava/connect"
                render={(props) => <StravaConnect {...props} />}
              />
              <Route
                exact
                path="/my-pages"
                render={(props) => <MyPages {...props} />}
              />
              {/* HUB */}
              <Route
                exact
                path="/company/:handle/admin"
                render={(props) => <HubManagement />}
              />
              <Route
                exact
                path="/company/:handle"
                render={(props) => <Hub {...props} />}
              />
              <Route
                exact
                path="/company/:handle/accept_invite"
                render={(props) => (
                  <Hub {...props}>
                    {(ownerObj) => (
                      <InviteAcceptOrReject
                        {...props}
                        owner={ownerObj}
                        action="accept"
                      />
                    )}
                  </Hub>
                )}
              />
              <Route
                exact
                path="/company/:handle/cancel_invite"
                render={(props) => (
                  <Hub {...props}>
                    {(ownerObj) => (
                      <InviteAcceptOrReject
                        {...props}
                        owner={ownerObj}
                        action="reject"
                      />
                    )}
                  </Hub>
                )}
              />
              <Route
                exact
                path="/onboarding/company/:step"
                render={(props) => <CreateAHub {...props} />}
              />
              <Route
                exact
                path="/onboarding/company"
                render={(props) => <Redirect to="/onboarding/company/companyInfo" />}
              />
              <Route
                exact
                path="/for-organizations/:section"
                render={(props) => <ForOrganizations {...props} />}
              />
              <Route
                exact
                path="/book-a-demo/"
                render={() => {
                  window.location.href = 'https://kambeo.io/book-a-demo/';
                  return <></>;
                }}
              />
              <Route
                exact
                path="/help/"
                render={() => {
                  window.location.href = 'https://help.kambeo.io/';
                  return <></>;
                }}
              />
              <Route
                exact
                path="/contact_support/"
                render={() => {
                  window.location.href = 'https://kambeo.io/contact-us/';
                  return <></>;
                }}
              />
              <Route
                exact
                path="/story/create"
                render={(props) => <CreateStory {...props} />}
              />
              <Route render={(props) => <NotFound {...props} />} />
            </Switch>
          </ErrorBoundry>
        </SharedModalContextProvider>
      </main>
    );
  }
}

export default withRouter(Router);
