import axios from 'axios';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {
  errorHelpers,
  extractLettersFromQuillMarkup,
  IStringMap,
  mapPermissions,
  routes,
  setSEOMetatags,
  swapRouteParams,
  toastError,
  toastSuccess,
  ValidateHandleStatus,
} from '../../helpers';
import { handleInputChange } from '../../helpers';
import moment, { Moment } from 'moment';
import { Config } from '@gigit/config';
import { Constants, EventManagePermissions } from '@gigit/constants';

import { createToast } from '../../actions/toaster';
import {
  getEvent,
  getEventPageComponents,
  IUpdateEventParams,
  updateEvent,
  changeEventHandle,
  updateEventRaisedAmount,
  toggleEventFitness,
  toggleEventContentCreator,
} from '../../actions/event';
import { getEventPermissions } from '../../actions/settings';
import { getStoreItems, StoreItemOwnerType } from '../../actions/store';

import { IUserState } from '../../reducers/user';
import { IEventState } from '../../reducers/event';
import { IGroupState } from '../../reducers/group';
import { IStoreState } from '../../reducers/store';
import { IAppState } from '../../store';

import { IOptions } from '../../components/Dropdown/Dropdown';
import StravaIcon from '../../assets/strava-icon.png';

import Dropdown from '../../components/Dropdown/Dropdown';
import Scheduler from '../../components/Scheduler/Scheduler';
import RoleManagement from '../../components/RoleManagement/RoleManagement';
import TransactionManagement from '../../components/TransactionManagement/TransactionManagement';
import AttendeeManagement from '../../components/AttendeeManagement/AttendeeManagement';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import DatePicker from '../../components/DatePicker/DatePicker';
import Construction from '../../components/Construction/Construction';
import QuillTextEditor from '../../components/QuillTextEditor/QuillTextEditor';
import Modal from '../../components/Modal/Modal';
import Login from '../Login/Login';
import AuctionManagement from '../../components/AuctionManagement/AuctionManagement';
import StoreItemManagement from '../../components/StoreItemManagement/StoreItemManagement';
import DiscountCodeManagement from '../../components/DiscountCodeManagement/DiscountCodeManagement';
import MemberManagement from '../../components/MemberManagement/MemberManagement';
import HourManagement from '../../components/HourManagement/HourManagement';
import EmbedManagement from '../../components/EmbedManagement/EmbedManagement';

import './EventAdmin.scss';
import Checkbox from '../../components/Checkbox/Checkbox';
import { localizeHelpers } from '../../localizeHelpers';
import ToggleButton from '../../components/shared/ToggleButton/ToggleButton';
import CategoriesManagement from '../../components/CategoriesManagement/CategoriesManagement';
import { IToast } from '../../interfaces';
import { IEventSummaryFE } from '../../../../../lib/gigit-interfaces/dist';
import typeHelpers from '../../helpers/typeHelpers';
import EventFundraiserManage from '../EventFundraiserManage/EventFundraiserManage';
import ManagementSidebar, {
  IMenuSection,
} from '../../components/ManagementSidebar/ManagementSidebar';
import SponsorManagement from '../../components/SponsorManagement/SponsorManagement';
import DonorManagement from '../../components/DonorManagement/DonorManagement';
import LocationManagement from '../../components/LocationManagement/LocationManagement';
import { billingRequestActions } from '../../requestActions';
import OrganizerContacts from '../../components/OrganizerContacts/OrganizerContacts';

interface IProps extends RouteComponentProps<any> {
  createToast(toast: IToast): void;
  getEventPageComponents(
    eventId: string,
    components: { pageId: string; componentId: string }[],
  ): void;
  updateEvent(event: IUpdateEventParams, callback?: (event: IEventSummaryFE) => void): void;
  toggleEventFitness(eventId: string): void;
  toggleEventContentCreator(eventId: string): void;
  getEvent(handle: string): void;
  getEventPermissions(): void;
  changeEventHandle(eventId: string, newHandle: string, onChanged: () => void): void;
  getStoreItems(params: {
    ownerType: StoreItemOwnerType;
    ownerId: string;
    query?: URLSearchParams;
  }): void;
  updateEventRaisedAmount(eventId: string): void;
  userState: IUserState;
  eventState: IEventState;
  groupState: IGroupState;
  storeState: IStoreState;
}

const registerActionOptions: IOptions[] = ([] = [
  { label: 'Tickets', value: 'Tickets' },
  { label: 'Register', value: 'Register' },
  { label: 'Sign-Up', value: 'Sign-Up' },
  { label: 'Hide Action', value: 'Hide' },
]);
const fundraiseActionOptions: IOptions[] = [
  { label: 'Fundraise', value: 'Fundraise' },
  { label: 'Register', value: 'Register' },
  { label: 'Hide Action', value: 'Hide' },
];
const sponsorshipActionOptions: IOptions[] = [
  { label: 'Sponsor', value: 'Sponsor' },
  { label: 'Hide Action', value: 'Hide' },
];
const volunteerActionOptions: IOptions[] = [
  { label: 'Volunteer', value: 'Volunteer' },
  { label: 'Apply', value: 'Apply' },
  { label: 'Hide Action', value: 'Hide' },
];

interface IState {
  handle: string;
  validateHandleStatus: ValidateHandleStatus;
  changeHandleError: string | null;
  userPermissions: IStringMap;
  redirect: string | null;
  showLoginModal: boolean;
  mounted: boolean;
  activeTab: string;
  activeRow: string;
  ticketOptions: any[];
  fundraisingTicket: string;
  fundraisingGoal: string;
  startDate: Moment;
  endDate: Moment;
  activeCampaign: string;
  campaigns: IOptions[];
  funds: IOptions[];
  feeControl: any;
  useDonationDescriptions: boolean;
  donationAmounts: { amount: number; description?: string }[] | undefined;
  modules: any;
  formats: any;
  receiptHeader: string;
  receiptFooter: string;
  maximum_matched: number;
  orgName: string;
  matchRatio: number;
  ratioDescription: string;
  defaultFundraiseStory: string;
  showTickets: boolean;
  fundraisingTickets: Array<string>;
  showReportsMenu: boolean;
  contactPhone: string;
  contactEmail: string;
  allow_checkout_donations: boolean;
  registerActionText?: string;
  fundraiseActionText?: string;
  sponsorshipActionText?: string;
  volunteerActionText?: string;
  defaultFund: string;
  donationCheckoutMessage: string;
  eventDescription: string;
  eventOrganizerName: string;
  isPurplePlus: boolean;
  notification_email_input: string;
}

class EventAdmin extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let isRatio =
      this.props.eventState.event.donations_matching &&
      this.props.eventState.event.donations_matching.ratio;
    let matchRatio;

    if (isRatio) {
      matchRatio = 100 / (isRatio * 100);
      matchRatio = Math.round(matchRatio);
    } else {
      matchRatio = 0;
    }

    this.state = {
      handle: '',
      validateHandleStatus: ValidateHandleStatus.NORMAL,
      changeHandleError: null,
      mounted: false,
      userPermissions: {},
      activeTab: 'settings',
      showLoginModal: false,
      redirect: null,
      activeRow: '',
      ticketOptions: [{ label: localizeHelpers.translate('None'), value: '' }],
      fundraisingTicket: this.props.eventState.event.registration_item_id || '',
      fundraisingTickets: this.props.eventState.event?.required_item_ids || [],
      fundraisingGoal: this.props.eventState.event.goal
        ? this.props.eventState.event.goal.toString()
        : '0' || '0',
      startDate: moment(this.props.eventState.event.start_date),
      endDate: moment(this.props.eventState.event.end_date),
      activeCampaign: this.props.eventState.event.campaign_id
        ? this.props.eventState.event.campaign_id
        : '' || '',
      defaultFund: this.props.eventState.event.default_fund_name || '',
      campaigns: [{ label: localizeHelpers.translate('None'), value: '' }],
      funds: [{ label: localizeHelpers.translate('None'), value: '' }],
      feeControl: {
        processingFees: this.props.eventState.event.fee_control?.covers_processing_fees || false,
        gigitFees: this.props.eventState.event.fee_control?.covers_gigit_fees || false,
        allowTips: this.props.eventState.event.fee_control?.show_tip || false,
      },
      useDonationDescriptions:
        this.props.eventState.event.use_donation_amount_descriptions || false,
      donationAmounts: this.setDonationAmounts(),
      modules: {
        toolbar: [
          ['bold', 'italic'],
          ['link', 'clean'],
        ],
        clipboard: {
          matchVisual: false,
        },
      },
      formats: ['bold', 'italic', 'link'],
      receiptHeader: this.props.eventState.event.receipt_header_message || '',
      receiptFooter: this.props.eventState.event.receipt_footer_message || '',
      maximum_matched: this.props.eventState.event?.donations_matching?.max_amount || 0,
      orgName: this.props.eventState.event?.donations_matching?.organization || '',
      matchRatio: matchRatio,
      ratioDescription: this.props.eventState.event?.donations_matching?.ratio_description || '',
      defaultFundraiseStory: this.props.eventState.event.default_fundraising_story ?? '',
      showTickets: false,
      showReportsMenu: false,
      contactPhone: this.props.eventState.event.contact_details?.phone || '',
      contactEmail: this.props.eventState.event.contact_details?.email || '',
      allow_checkout_donations: this.props.eventState.event.allow_checkout_donations ?? true,
      registerActionText: this.props.eventState.event.register_action_text || 'Tickets',
      fundraiseActionText: this.props.eventState.event.fundraise_action_text || 'Fundraise',
      sponsorshipActionText: this.props.eventState.event.sponsorship_action_text || 'Sponsor',
      volunteerActionText: this.props.eventState.event.volunteer_action_text || 'Volunteer',
      donationCheckoutMessage: this.props.eventState.event.donation_checkout_message ?? '',
      eventDescription: this.props.eventState.event.description || '',
      eventOrganizerName: this.props.eventState.event.event_organizer?.title || '',
      isPurplePlus: false,
      notification_email_input: '',
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.toggleActiveRow = this.toggleActiveRow.bind(this);
    this.saveHeader = this.saveHeader.bind(this);
    this.saveFooter = this.saveFooter.bind(this);
    this.handleReceiptFooterChange = this.handleReceiptFooterChange.bind(this);
    this.handleReceiptHeaderChange = this.handleReceiptHeaderChange.bind(this);
    this.setFunds = this.setFunds.bind(this);
    this.updateEventDescription = this.updateEventDescription.bind(this);
    this.updateNotificationEmails = this.updateNotificationEmails.bind(this);
    this.removeExternalNotificationEmail = this.removeExternalNotificationEmail.bind(this);
  }

  componentDidMount() {
    if (this.props.userState.isLoggedIn) {
      this.props.getEventPermissions();

      this.props.getEvent(this.props.match.params.handle);
      let _params = queryString.parse(this.props.location.search);

      if (_params.t) {
        this.setState({
          activeTab: _params.t as string,
        });
      }
      if (_params.s) {
        let sectionName = _params.s as string;
        let section = document.getElementById(sectionName);
        let topOffset = section?.offsetTop || 0;
        if (section && topOffset) {
          // Prevent section being scrolled under
          window.scrollTo({ top: topOffset - 90, behavior: 'smooth' });
        }
      }
    }

    this.setState({
      mounted: true,
    });

    // GIG-740: Show login when user isn't logged in.
    this.setState({
      showLoginModal: this.props.userState.isLoggedIn === false,
    });

    this.setFunds();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.groupState.group !== prevProps.groupState.group) {
      billingRequestActions
        .getPremiumFeatureSummary(Constants.object_type.event, this.props.eventState.event.id)
        .then((resp) => {
          if (resp) {
            const feature = resp.features.find(
              (feature) =>
                feature.is_active &&
                feature.feature_code === Constants.billing.subscriptions.purple_plus.code,
            );
            if (feature) {
              this.setState({
                isPurplePlus: true,
              });
            }
          }
        })
        .catch((err) => {
          const errorObj = errorHelpers.getErrorObject(err);
          const toast = toastError(errorObj.messageTemplate, 'Group Subscription');
          this.props.createToast(toast);
        });
    }

    if (prevProps.eventState.event !== this.props.eventState.event) {
      if (this.props.eventState.event.id) {
        this.props.getStoreItems({ ownerType: 'event', ownerId: this.props.eventState.event.id });
      }

      this.setState({
        handle: this.props.eventState.event.handle,
        receiptHeader: this.props.eventState.event.receipt_header_message || '',
        receiptFooter: this.props.eventState.event.receipt_footer_message || '',
      });

      if (
        this.props.eventState.event.use_donation_amount_descriptions !==
          prevProps.eventState.event.use_donation_amount_descriptions &&
        this.props.eventState.event.use_donation_amount_descriptions !== undefined
      ) {
        this.setState({
          useDonationDescriptions: this.props.eventState.event.use_donation_amount_descriptions,
        });
      }

      if (this.props.eventState.event.campaign_id !== prevProps.eventState.event.campaign_id) {
        this.setState({
          activeCampaign: this.props.eventState.event.campaign_id || '',
        });
      }

      if (
        this.props.eventState.event.default_fund_name !==
        prevProps.eventState.event.default_fund_name
      ) {
        this.setState({
          defaultFund: this.props.eventState.event.default_fund_name || '',
        });
      }

      if (this.props.eventState.event.start_date !== prevProps.eventState.event.start_date) {
        this.setState({
          startDate: moment(this.props.eventState.event.start_date),
        });
      }

      if (this.props.eventState.event.end_date !== prevProps.eventState.event.end_date) {
        this.setState({
          endDate: moment(this.props.eventState.event.end_date),
        });
      }

      if (this.props.eventState.event.fee_control !== prevProps.eventState.event.fee_control) {
        this.setState({
          feeControl: {
            processingFees:
              this.props.eventState.event.fee_control?.covers_processing_fees || false,
            gigitFees: this.props.eventState.event.fee_control?.covers_gigit_fees || false,
            allowTips: this.props.eventState.event.fee_control?.show_tip || false,
          },
        });
      }

      if (
        this.props.eventState.event.donation_amounts !== prevProps.eventState.event.donation_amounts
      ) {
        this.setState({
          donationAmounts: this.setDonationAmounts(),
        });
      }

      if (
        this.props.eventState.event.allow_checkout_donations !==
        prevProps.eventState.event.allow_checkout_donations
      ) {
        this.setState({
          allow_checkout_donations: this.props.eventState.event.allow_checkout_donations ?? true,
        });
      }

      if (
        this.props.eventState.event.donation_checkout_message !=
        prevProps.eventState.event.donation_checkout_message
      ) {
        this.setState({
          donationCheckoutMessage: this.props.eventState.event.donation_checkout_message || '',
        });
      }

      if (this.props.eventState.event.id) {
        this.updateProgressBarSettings(this.props.eventState, prevProps.eventState);
      }
    }

    if (prevProps.storeState.storeItems !== this.props.storeState.storeItems) {
      let _tickets = [{ label: localizeHelpers.translate('None'), value: '' }];
      const ticketsInStore = this.props.storeState.storeItems
        .filter((i) => i.item_type === 'ticket')
        .map((a) => {
          return { label: a.name, value: a.id || '' };
        });

      _tickets = ticketsInStore ? [..._tickets, ...ticketsInStore] : _tickets;

      this.setState({
        ticketOptions: _tickets,
      });
    }

    if (prevProps.groupState.groupCampaigns !== this.props.groupState.groupCampaigns) {
      let _campaigns = [{ label: localizeHelpers.translate('None'), value: '' }];

      for (let c in this.props.groupState.groupCampaigns) {
        let _campaign = this.props.groupState.groupCampaigns[c];
        _campaigns.push({ label: _campaign.title || '', value: _campaign.id || '' });
      }

      this.setState({
        campaigns: _campaigns,
      });
    }

    if (prevProps.eventState.currentCampaign !== this.props.eventState.currentCampaign) {
      this.setFunds();
    }

    if (
      this.props.userState.isLoggedIn !== prevProps.userState.isLoggedIn &&
      !this.props.userState.awaitingToken
    ) {
      //TODO: GIG-7896 - test this, unsure
      // GIG-740: Show login when user isn't logged in.
      this.setState(
        {
          showLoginModal: this.props.userState.isLoggedIn === false,
        },
        () => {
          if (!this.state.showLoginModal && this.props.userState.isLoggedIn) {
            //TODO: GIG-7896 - test this, unsure
            setSEOMetatags({
              urlPath: 'event/' + this.props.eventState.event.handle,
              title: this.props.eventState.event.title,
              description: this.props.eventState.event.description,
              imageURL: this.props.eventState.event.profile_image_url,
              coverImageURL: this.props.eventState.event.cover_image_url,
            });
          }
        },
      );

      // Refetch event and event permission data after login. This ensures user auth is up to date with current login
      if (this.props.userState.isLoggedIn) {
        this.props.getEventPermissions();

        this.props.getEvent(this.props.match.params.handle);
      }
    }

    if (prevProps.eventState.isCurrentRoleLoading && !this.props.eventState.isCurrentRoleLoading) {
      let _permissions: IStringMap = mapPermissions(
        this.props.eventState.currentUserRole.permissions,
      );

      this.setState(
        {
          userPermissions: _permissions,
        },
        () => {
          // If user doesn't have permissions to access page, we boot them to the event page.
          if (
            !this.props.eventState.isEventLoading &&
            !this.hasManagePermission() &&
            !this.props.userState.awaitingToken
          ) {
            this.setState({
              //redirect: true
              redirect: '/event/' + this.props.eventState.event.handle,
            });
            const toast = toastError(
              localizeHelpers.translate('You must be an admin of the event to view admin page'),
              'Insufficient Permissions',
            );
            this.props.createToast(toast);
          }
        },
      );
    }

    // If group doesn't exist or is archived, then visit not found page.
    if (
      prevProps.eventState.isEventLoading &&
      !this.props.eventState.isEventLoading &&
      !this.props.userState.awaitingToken
    ) {
      if (
        this.props.eventState.event.handle.length === 0 ||
        (this.props.eventState.event.status &&
          this.props.eventState.event.status.code === 'archived')
      ) {
        this.setState({
          redirect: '/not-found',
        });
      }
    }

    if (
      prevProps.eventState.event?.required_item_ids !==
      this.props.eventState.event?.required_item_ids
    ) {
      this.setState({
        fundraisingTickets: this.props.eventState.event?.required_item_ids || [],
      });
    }

    if (
      this.state.maximum_matched !== prevState.maximum_matched ||
      this.state.orgName !== prevState.orgName ||
      this.state.matchRatio !== prevState.matchRatio
    ) {
      this.setState({
        ratioDescription: this.getMatchingDescription(),
      });
    }
  }

  setDonationAmounts() {
    let _amounts: { amount: number; description?: string }[] = [
      { amount: 25, description: '' },
      { amount: 50, description: '' },
      { amount: 100, description: '' },
      { amount: 250, description: '' },
      { amount: 500, description: '' },
    ];

    if (
      this.props.eventState.event.donation_amounts &&
      this.props.eventState.event.donation_amounts.length > 0
    ) {
      _amounts = [];

      for (const amount of this.props.eventState.event.donation_amounts) {
        if (typeof amount === 'object') {
          _amounts.push({ amount: amount.amount, description: amount.description || '' });
        } else {
          _amounts.push({ amount: amount, description: '' });
        }
      }
    }

    return _amounts;
  }

  saveNewHandle = () => {
    this.props.changeEventHandle(this.props.eventState.event.id, this.state.handle, () => {
      const toast = toastSuccess(
        localizeHelpers.translate('Event handle was successfully changed'),
        'Event handle changed',
      );
      this.props.createToast(toast);

      // Redirect to new Url and refresh the page (Reloads all state).
      this.props.history.push(`/event/${this.state.handle}/admin`);
      window.location.reload();
    });
  };

  onHandleChanged = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        handle: e.target.value.replace(/\s+/g, ''),
      },
      this.checkHandle,
    );
  };

  checkHandle = () => {
    this.setState({
      validateHandleStatus: ValidateHandleStatus.VALIDATING,
      changeHandleError: '',
    });

    axios
      .get(swapRouteParams(routes.EVENT_UNIQUE_HANDLE, { handle: this.state.handle }))
      .then((response) => {
        if (response.data && response.data.value === false) {
          const errorObj = errorHelpers.getErrorObjectFromSimpleValue(response.data);
          this.setState({
            validateHandleStatus: ValidateHandleStatus.ERROR,
            changeHandleError: errorObj.translatedMessage,
          });
        } else {
          this.setState({
            validateHandleStatus: ValidateHandleStatus.VALIDATED,
            changeHandleError: null,
          });
        }
      })
      .catch(() => {
        this.setState({
          validateHandleStatus: ValidateHandleStatus.ERROR,
          changeHandleError: 'Unknown error',
        });
      });
  };

  setFunds() {
    if (this.props.eventState.currentCampaign !== null) {
      let _funds: IOptions[] = [{ value: '', label: localizeHelpers.translate('None') }];

      for (const f of this.props.eventState.currentCampaign.funds) {
        typeHelpers.assertNotNullOrUndefined(f.id);
        let _fund = { value: f.id, label: f.name };

        _funds.push(_fund);
      }

      this.setState({
        funds: _funds,
      });
    }
  }

  isActiveTab(tab: string) {
    return tab === this.state.activeTab ? 'tab active' : 'tab';
  }

  setActiveTab(tab: string) {
    this.setState({
      activeTab: tab,
    });
  }

  toggleActiveRow(row: string) {
    if (row === this.state.activeRow) {
      this.setState({
        activeRow: '',
      });
    } else {
      this.setState({
        activeRow: row,
      });
    }
  }

  updateProcessingFee(val: boolean) {
    this.props.updateEvent({
      fee_control: {
        covers_gigit_fees: this.state.feeControl.gigitFees,
        covers_processing_fees: val,
      },
      id: this.props.eventState.event.id,
    });
  }

  saveEventContactDetails() {
    this.props.updateEvent(
      {
        contact_details: {
          phone: this.state.contactPhone,
          email: this.state.contactEmail,
        },
        id: this.props.eventState.event.id,
      },
      () => {
        const toast = toastSuccess(
          localizeHelpers.translate('Event Contact Details updated successfully.'),
          'Event Settings',
        );
        this.props.createToast(toast);
      },
    );
  }

  updateEventDescription() {
    this.props.updateEvent(
      {
        description: this.state.eventDescription,
        id: this.props.eventState.event.id,
      },
      () => {
        const toast = toastSuccess(
          localizeHelpers.translate('Event description updated successfully.'),
          'Event Settings',
        );
        this.props.createToast(toast);
      },
    );
  }

  updateGigitFee(val: boolean) {
    this.props.updateEvent({
      fee_control: {
        covers_gigit_fees: val,
        covers_processing_fees: this.state.feeControl.processingFees,
      },
      id: this.props.eventState.event.id,
    });
  }

  handleDonationAmountInputChange(event: any, self: any, prevent?: boolean, callback?: any) {
    if (prevent === true) {
      event.preventDefault();
    }

    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;
    const index = target.name.split('-')[1];

    let _donationAmounts: { amount: number; description?: string }[] | undefined = [];

    if (this.state.donationAmounts) {
      _donationAmounts = [...this.state.donationAmounts];
    }

    if (target.type === 'number') {
      _donationAmounts[index] = {
        amount: parseInt(value),
        description: _donationAmounts[index].description || '',
      };
    } else {
      _donationAmounts[index] = { amount: _donationAmounts[index].amount, description: value };
    }

    self.setState(
      {
        donationAmounts: _donationAmounts,
      },
      () => {
        if (callback !== undefined) {
          callback();
        }
      },
    );
  }

  handleReceiptHeaderChange(content: any) {
    this.setState({ receiptHeader: content });
  }

  handleReceiptFooterChange(content: any) {
    this.setState({ receiptFooter: content });
  }

  saveHeader() {
    this.props.updateEvent({
      receipt_header_message: this.state.receiptHeader,
      id: this.props.eventState.event.id,
    });

    const toast = toastSuccess(
      localizeHelpers.translate('Receipt header updated successfully.'),
      'Event Settings',
    );
    this.props.createToast(toast);
  }

  saveFooter() {
    this.props.updateEvent({
      receipt_footer_message: this.state.receiptFooter,
      id: this.props.eventState.event.id,
    });

    const toast = toastSuccess(
      localizeHelpers.translate('Receipt footer updated successfully.'),
      'Event Settings',
    );
    this.props.createToast(toast);
  }

  saveDefaultFundraiseStory() {
    this.props.updateEvent({
      default_fundraising_story: this.state.defaultFundraiseStory,
      id: this.props.eventState.event.id,
    });

    const toast = toastSuccess(
      localizeHelpers.translate('Default Fundraise Description updated successfully.'),
      'Event Settings',
    );
    this.props.createToast(toast);
  }

  saveDonationCheckoutMessage() {
    if (extractLettersFromQuillMarkup(this.state.donationCheckoutMessage).trim().length === 0) {
      this.setState({
        donationCheckoutMessage: '',
      });
      this.props.updateEvent({
        id: this.props.eventState.event.id,
        donation_checkout_message: this.state.donationCheckoutMessage,
      });

      const toast = toastSuccess(
        localizeHelpers.translate('Custom Donation Checkout Donation saved successfully.'),
        'Event Settings',
      );
      this.props.createToast(toast);
    } else {
      this.props.updateEvent({
        id: this.props.eventState.event.id,
        donation_checkout_message: this.state.donationCheckoutMessage,
      });

      const toast = toastSuccess(
        localizeHelpers.translate('Custom Donation Checkout Donation saved successfully.'),
        'Event Settings',
      );
      this.props.createToast(toast);
    }
  }

  hasMatchingChanged() {
    return (
      this.props.eventState.event?.donations_matching?.organization !== this.state.orgName ||
      this.props.eventState.event?.donations_matching?.max_amount !== this.state.maximum_matched ||
      this.props.eventState.event?.donations_matching?.ratio !== this.state.matchRatio ||
      this.props.eventState.event?.donations_matching?.ratio_description !==
        this.state.ratioDescription
    );
  }

  resetMatching() {
    this.setState({
      orgName: this.props.eventState.event?.donations_matching?.organization || '',
      maximum_matched: this.props.eventState.event?.donations_matching?.max_amount || 0,
      matchRatio: this.props.eventState.event?.donations_matching?.ratio || 0,
      ratioDescription: this.props.eventState.event?.donations_matching?.ratio_description || '',
    });
  }

  getMatchingDescription() {
    return localizeHelpers.translate(
      `For every {{match_ratio}} donated, $1 will be donated by {{org_name}} up to {{maximum_matched}}`,
      {
        match_ratio: this.state.matchRatio,
        org_name: this.state.orgName || '[Organization Name]',
        maximum_matched: this.state.maximum_matched,
      },
    );
  }

  removeMatching = () => {
    this.setState(
      {
        orgName: '',
        maximum_matched: 0,
        matchRatio: 0,
        ratioDescription: '',
      },
      () => {
        this.props.updateEvent(
          {
            id: this.props.eventState.event.id,
            donations_matching: {
              organization: '',
              max_amount: 0,
              ratio: 0,
              ratio_description: '',
            },
          },
          (event: IEventSummaryFE) => {
            const toast = toastSuccess(
              localizeHelpers.translate('Event Donation Matching has been deleted.'),
              'Event Management',
            );
            this.props.createToast(toast);
          },
        );
      },
    );
  };

  hasManagePermission() {
    const allowedPermissions = Object.keys(this.state.userPermissions).filter(
      (k) => this.state.userPermissions[k],
    );
    return allowedPermissions.some((allowed) => EventManagePermissions.includes(allowed));
  }

  getTicketFromId(id: string) {
    let ticket = this.props.storeState.storeItems.filter((i) => i.id === id);

    if (ticket.length > 0) {
      let t = ticket[0];
      return (
        <div
          key={t.id}
          onClick={() => this.updateEventRequiredItems(id)}
          className="sel-ticket"
        >
          {t.name}
          <i className="fa fa-times" />
        </div>
      );
    }
  }

  isSelectedTicket(id: string) {
    return this.state.fundraisingTickets.indexOf(id) !== -1;
  }

  updateEventRequiredItems(id: string) {
    if (this.isSelectedTicket(id)) {
      let idx = this.state.fundraisingTickets.findIndex((t) => t === id);
      let reqItems = [...this.state.fundraisingTickets];
      reqItems.splice(idx, 1);

      this.props.updateEvent({ id: this.props.eventState.event.id, required_item_ids: reqItems });
    } else {
      let reqItems = [...this.state.fundraisingTickets];
      reqItems.push(id);

      this.props.updateEvent({ id: this.props.eventState.event.id, required_item_ids: reqItems });
    }
  }

  updateProgressBarSettings = (currentEventState: IEventState, prevEventState: IEventState) => {
    const { updateEventRaisedAmount } = this.props;

    if (
      currentEventState.event.show_donations_progress !==
        prevEventState.event.show_donations_progress ||
      currentEventState.event.show_merchandise_progress !==
        prevEventState.event.show_merchandise_progress ||
      currentEventState.event.show_auction_progress !==
        prevEventState.event.show_auction_progress ||
      currentEventState.event.show_campaign_progress !==
        prevEventState.event.show_campaign_progress ||
      currentEventState.event.show_sponsor_progress !== prevEventState.event.show_sponsor_progress
    ) {
      updateEventRaisedAmount(currentEventState.event.id);
    }
  };

  toggleAllowCheckoutDonations() {
    if (!this.state.allow_checkout_donations && !this.props.eventState.event.accepting_donations) {
      const toast = toastError(
        localizeHelpers.translate('This event is not configured to allow donations.'),
        'Donations',
      );
      this.props.createToast(toast);
    } else {
      this.props.updateEvent({
        id: this.props.eventState.event.id,
        allow_checkout_donations: !this.state.allow_checkout_donations,
      });
    }
  }

  saveDonationAmounts(e: any) {
    e.preventDefault();
    this.props.updateEvent({
      id: this.props.eventState.event.id,
      donation_amounts: this.state.donationAmounts,
    });
    const toast = toastSuccess('Preset donation amounts updated.', 'Event Settings');
    this.props.createToast(toast);
  }

  updateNotificationEmails(email: string) {
    let existingEmails = this.props.eventState.event.notification_external_emails || [];
    existingEmails.push(email);
    this.props.updateEvent(
      { id: this.props.eventState.event.id, notification_external_emails: existingEmails },
      () => {
        this.setState({ notification_email_input: '' });
        const toast = toastSuccess('External email notification list updated.', 'Event Settings');
        this.props.createToast(toast);
      },
    );
  }

  removeExternalNotificationEmail(index: number) {
    let existingEmails = this.props.eventState.event.notification_external_emails || [];
    if (existingEmails) {
      existingEmails.splice(index, 1);
    }
    this.props.updateEvent(
      { id: this.props.eventState.event.id, notification_external_emails: existingEmails },
      () => {
        const toast = toastSuccess('External email notification list updated.', 'Event Settings');
        this.props.createToast(toast);
      },
    );
  }

  getSidebarMenuOptions(): IMenuSection[] {
    let options = [
      {
        id: 'setup',
        sectionTitle: 'Setup',
        sectionIcon: 'fas fa-sliders-v',
        collapsible: true,
        menuOptions: [
          { id: 'settings', title: 'settings', permission: 'EDIT_EVENT_INFO' },
          { id: 'roles', title: 'roles', permission: 'EDIT_EVENT_ROLES' },
          { id: 'locations', title: 'locations', permission: 'EDIT_EVENT_INFO' },
          { id: 'store', title: 'store', permission: 'MANAGE_STORE_ITEMS' },
          { id: 'auction', title: 'auction', permission: 'MANAGE_AUCTIONS' },
          { id: 'categories', title: 'categories', permission: 'MANAGE_STORE_ITEMS' },
          { id: 'discounts', title: 'discounts', permission: 'MANAGE_STORE_ITEMS' },
        ],
      },
      {
        id: 'manage',
        sectionTitle: 'Manage',
        sectionIcon: 'fas fa-users',
        collapsible: true,
        menuOptions: [
          { id: 'schedule', title: 'schedule', permission: 'MANAGE_GIGS' },
          { id: 'attendees', title: 'Attendees (Check-In)', permission: 'MANAGE_ATTENDEES' },
          {
            id: 'reports',
            title: 'reports',
            submenuOptions: [
              { id: 'donationsPayments', title: 'donations', permission: 'MANAGE_DONATIONS' },
              { id: 'storePayments', title: 'Store Purchases', permission: 'MANAGE_STORE_ITEMS' },
              {
                id: 'gigsPayments',
                title: 'Volunteer Opportunity Payments',
                permission: 'MANAGE_GIGS',
              },
              { id: 'auctionsPayments', title: 'Auction Payments', permission: 'MANAGE_AUCTIONS' },
            ],
          },
        ],
      },
      {
        id: 'lists',
        sectionTitle: 'Lists',
        sectionIcon: 'fal fa-list-ul',
        collapsible: true,
        menuOptions: [
          { id: 'members', title: 'contacts', permission: 'MANAGE_EVENT_USERS' },
          //TODO: Implement backend to support donors and sponsors on events.
          // { id: "donors", title: "donors", permission: "MANAGE_GROUP_EVENTS" },
          // { id: "sponsors", title: "sponsors", permission: "MANAGE_GROUP_EVENTS" },
          { id: 'hours', title: 'Verify Volunteer Hours', permission: 'MANAGE_GIGS' },
          { id: 'fundraisers', title: 'fundraisers', permission: 'MANAGE_FUNDRAISERS' },
        ],
      },
      {
        id: 'embedSection',
        sectionTitle: 'Embeds Sharing',
        sectionIcon: 'fas fa-code',
        collapsible: true,
        menuOptions: [{ id: 'embeds', title: 'embed tools' }],
      },
    ];

    if (this.props.eventState.event.event_type === 'obo_fundraiser') {
      options = options.filter((section) => {
        if (section.id === 'lists') {
          let filtered = section.menuOptions.filter((option) => option.id !== 'hours');
          section.menuOptions = filtered;
          return section;
        } else {
          return section;
        }
      });
    }

    return options;
  }

  render() {
    let { eventState, updateEvent } = this.props;
    // Only display content once group data is loaded and we logged in.
    const isLoaded = !this.props.eventState.isEventLoading && this.props.userState.isLoggedIn;

    const ownerObj = typeHelpers.createOwnerObject('event', this.props.eventState.event);

    if (this.state.mounted && this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    } else {
      return (
        <div className="EventAdmin">
          <ManagementSidebar
            logo={this.props.eventState.event.profile_image_url}
            title="Event Management"
            targetLink={{
              url: '/event/' + this.props.eventState.event.handle,
              title: this.props.eventState.event.title,
            }}
            menuSections={this.getSidebarMenuOptions()}
            activeOptionId={this.state.activeTab}
            permissions={this.state.userPermissions}
            onMenuOptionClicked={(menuOptionId: string) =>
              this.setState({ activeTab: menuOptionId })
            }
          />

          <div className={'section ' + this.state.activeTab + '-wrap'}>
            {this.state.activeTab === 'settings' && (
              <div className="SettingsManagement section-wrap settings">
                <div className="section-title">Settings</div>
                <div className="section-inner">
                  <div className="settings-title">Event Details</div>

                  {this.props.eventState.event.event_type ===
                    Constants.event_type.obo_fundraiser && (
                    <div
                      className="settings-item hover-item no-select"
                      onClick={() => {
                        this.toggleActiveRow('eventOrganizerName');
                      }}
                    >
                      <div className="details">
                        <p>Event Organizer</p>
                        <span className="detail">Set the visible organizer of the event.</span>
                      </div>

                      {this.state.activeRow === 'eventOrganizerName' && (
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            this.props.updateEvent(
                              {
                                id: this.props.eventState.event.id,
                                event_organizer_name: this.state.eventOrganizerName,
                              },
                              () => {
                                const toast = toastSuccess(
                                  localizeHelpers.translate(
                                    'Event organizer updated successfully.',
                                  ),
                                  'Event Management',
                                );
                                this.props.createToast(toast);
                              },
                            );
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="expansion"
                        >
                          <div className="web-address-line">
                            <TextField
                              name="eventOrganizerName"
                              type="text"
                              label=""
                              value={this.state.eventOrganizerName}
                              onChange={(e) => {
                                handleInputChange(e, this);
                              }}
                            />
                          </div>

                          <div className="expansion-actions">
                            <Button
                              text="Save"
                              type="submit"
                              isDisabled={
                                this.state.eventOrganizerName ===
                                this.props.eventState.event.event_organizer?.title
                              }
                            />
                          </div>
                        </form>
                      )}
                    </div>
                  )}

                  <div
                    className="settings-item hover-item no-select"
                    onClick={() => {
                      this.toggleActiveRow('details');
                    }}
                  >
                    <div className="details">
                      <p>Date &amp; Time</p>
                      <span className="detail">
                        Manage your event’s start to end dates and times.
                      </span>
                    </div>
                    {this.state.activeRow === 'details' && (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();

                          if (this.state.startDate.isAfter(this.state.endDate)) {
                            const toast = toastError(
                              localizeHelpers.translate(
                                'Start date cannot be greater than end date',
                              ),
                              'Update Event',
                            );
                            this.props.createToast(toast);

                            return;
                          }

                          this.props.updateEvent({
                            id: this.props.eventState.event.id,
                            start_date: moment(this.state.startDate).utc().format(),
                            end_date: moment(this.state.endDate).utc().format(),
                          });

                          const toast = toastSuccess(
                            localizeHelpers.translate('Event start/end date updated successfully.'),
                            'Event Management',
                          );
                          this.props.createToast(toast);
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion"
                      >
                        <DatePicker
                          showTime={true}
                          date={moment(this.state.startDate).second(0).millisecond(0)}
                          name="startDate"
                          label="Start Date"
                          error={this.state.startDate.isAfter(this.state.endDate)}
                          onChange={(date: any) => {
                            this.setState({ startDate: date });
                          }}
                        />
                        <DatePicker
                          showTime={true}
                          date={moment(this.state.endDate).second(0).millisecond(0)}
                          name="endDate"
                          label="End Date"
                          error={this.state.startDate.isAfter(this.state.endDate)}
                          onChange={(date: any) => {
                            this.setState({ endDate: date });
                          }}
                        />
                        <div className="expansion-actions">
                          <Button
                            text="Save Start/End Date"
                            type="submit"
                          />
                        </div>
                      </form>
                    )}
                  </div>

                  <div
                    className="settings-item hover-item no-select"
                    onClick={() => {
                      this.toggleActiveRow('webAddress');
                    }}
                  >
                    <div className="details">
                      <p>Web Address</p>
                      <span className="detail">
                        Personalize your Kambeo web address to easily direct people to your page.
                      </span>
                    </div>

                    {this.state.activeRow === 'webAddress' && (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.saveNewHandle();
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion"
                      >
                        <div className="web-address-line">
                          <p
                            className="web-address"
                            notranslate="yes"
                          >{`${Config.web.REACT_APP_BASE_URL}/event/`}</p>
                          <TextField
                            name="webAddress"
                            type="text"
                            label=""
                            value={this.state.handle}
                            onChange={this.onHandleChanged}
                            error={
                              this.state.changeHandleError
                                ? localizeHelpers.translate(this.state.changeHandleError)
                                : undefined
                            }
                          />
                        </div>

                        {this.state.validateHandleStatus === ValidateHandleStatus.VALIDATED && (
                          <div className="success">This web address is available.</div>
                        )}

                        <div className="expansion-actions">
                          <Button
                            text="Save"
                            type="submit"
                            isDisabled={
                              this.state.handle === this.props.eventState.event.handle ||
                              this.state.validateHandleStatus !== ValidateHandleStatus.VALIDATED
                            }
                          />
                        </div>
                      </form>
                    )}
                  </div>

                  <div className="settings-item hover-item">
                    <div
                      className="details"
                      onClick={() => this.toggleActiveRow('event-description')}
                    >
                      <p>Event Description</p>
                      <span className="detail">Update your event's short description.</span>
                    </div>
                    {this.state.activeRow === 'event-description' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion message"
                      >
                        <QuillTextEditor
                          value={this.state.eventDescription}
                          readOnly={false}
                          preserveWhitespace={true}
                          theme="snow"
                          modules={this.state.modules}
                          formats={this.state.formats}
                          onChange={(content: string) =>
                            this.setState({ eventDescription: content })
                          }
                        />
                        <div className="message-actions">
                          <Button
                            text="Update Description"
                            onClick={() => {
                              this.updateEventDescription();
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="settings-item hover-item no-select">
                    <div
                      className="details"
                      onClick={() => this.toggleActiveRow('event-location')}
                    >
                      <p>Event Location</p>
                      <span className="detail">Set whether or not this an online event.</span>
                    </div>
                    {this.state.activeRow === 'event-location' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion progress-bar"
                      >
                        <div className="toggle-row">
                          <span className="toggle-label">This is an online event.</span>
                          <ToggleButton
                            onToggleClick={() =>
                              this.props.updateEvent({
                                id: this.props.eventState.event.id,
                                is_virtual: !eventState.event.is_virtual,
                              })
                            }
                            isActive={eventState.event.is_virtual}
                            label={eventState.event.is_virtual ? 'Yes' : 'No'}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="settings-title">Fundraising Details</div>
                  <div
                    className="settings-item hover-item no-select"
                    onClick={() => {
                      this.toggleActiveRow('goal');
                    }}
                  >
                    <div className="details">
                      <p>Fundraising Goal</p>
                      <span className="detail">Manage your fundraising goal for this event.</span>
                    </div>
                    {this.state.activeRow === 'goal' && (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.props.updateEvent({
                            id: this.props.eventState.event.id,
                            goal: parseInt(this.state.fundraisingGoal),
                          });

                          const toast = toastSuccess(
                            localizeHelpers.translate('Fundraising goal updated successfully.'),
                            'Event Management',
                          );
                          this.props.createToast(toast);
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion"
                      >
                        <TextField
                          min="1"
                          type="number"
                          value={this.state.fundraisingGoal}
                          name="fundraisingGoal"
                          onChange={(e) => {
                            handleInputChange(e, this);
                          }}
                        />
                        <div className="expansion-actions">
                          <Button
                            text="Save Fundraising Goal"
                            type="submit"
                          />
                        </div>
                      </form>
                    )}
                  </div>
                  <div
                    className="settings-item hover-item no-select"
                    onClick={() => {
                      this.toggleActiveRow('ticket');
                    }}
                  >
                    <div className="details">
                      <p>Fundraising Registration Ticket</p>
                      <span className="detail">
                        Setup a ticket requirement for event fundraising.
                      </span>
                    </div>
                    {this.state.activeRow === 'ticket' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion"
                      >
                        <div className="fundraising-ticket-selection">
                          <div className="ticket-list">
                            <div
                              className="select-wrap"
                              onClick={() =>
                                this.setState({ showTickets: !this.state.showTickets })
                              }
                            >
                              <div>
                                <var data-var="num_items_selected">
                                  {this.state.fundraisingTickets.length}
                                </var>{' '}
                                items selected
                              </div>
                              <div>
                                <i className="fa fa-caret-down" />
                              </div>
                            </div>
                            {this.state.showTickets && (
                              <div className="ticket-options">
                                {this.state.ticketOptions.map((ticket) => {
                                  if (ticket.label !== 'None') {
                                    return (
                                      <div
                                        key={ticket?.value}
                                        className={
                                          this.isSelectedTicket(ticket.value)
                                            ? 'ticket-option selected'
                                            : 'ticket-option'
                                        }
                                      >
                                        <Checkbox
                                          name="ticket"
                                          onChange={(e) => {
                                            this.updateEventRequiredItems(ticket.value);
                                          }}
                                          checked={this.isSelectedTicket(ticket.value)}
                                          value={'ticket'}
                                          color="groups"
                                          label={ticket.label}
                                          notranslate="yes"
                                        />
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            )}
                          </div>
                          <div className="selected-tickets">
                            {this.state.fundraisingTickets.map((id) => this.getTicketFromId(id))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="settings-item">
                    <div className="details">
                      <p>Show Fundraising Progress Bar</p>
                      <span className="detail">
                        Do you want users to see your default fundraising progress bar?
                      </span>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          this.props.updateEvent({
                            id: this.props.eventState.event.id,
                            show_campaign_progress:
                              !this.props.eventState.event.show_campaign_progress,
                          });
                        }}
                        className={
                          this.props.eventState.event.show_campaign_progress
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="settings-item hover-item no-select"
                    onClick={() => this.toggleActiveRow('progress-bar')}
                  >
                    <div className="details">
                      <p>Fundraising Progress Bar Parameters</p>
                      <span className="detail">
                        Choose which capacities to include in your progress bar.
                      </span>
                    </div>
                    {this.state.activeRow === 'progress-bar' && (
                      <div
                        className="expansion progress-bar"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="toggle-row">
                          <span className="toggle-label">Include Donations</span>
                          <ToggleButton
                            onToggleClick={() =>
                              updateEvent({
                                id: this.props.eventState.event.id,
                                show_donations_progress: !eventState.event.show_donations_progress,
                              })
                            }
                            isActive={eventState.event.show_donations_progress!}
                            label={eventState.event.show_donations_progress ? 'Yes' : 'No'}
                          />
                        </div>
                        <div className="toggle-row">
                          <span className="toggle-label">Include Auction Items</span>
                          <ToggleButton
                            onToggleClick={() =>
                              updateEvent({
                                id: this.props.eventState.event.id,
                                show_auction_progress: !eventState.event.show_auction_progress,
                              })
                            }
                            isActive={eventState.event.show_auction_progress!}
                            label={eventState.event.show_auction_progress ? 'Yes' : 'No'}
                          />
                        </div>
                        <div className="toggle-row">
                          <span className="toggle-label">Include Merchandise & Tickets</span>
                          <ToggleButton
                            onToggleClick={() =>
                              updateEvent({
                                id: this.props.eventState.event.id,
                                show_merchandise_progress:
                                  !eventState.event.show_merchandise_progress,
                              })
                            }
                            isActive={eventState.event.show_merchandise_progress!}
                            label={eventState.event.show_merchandise_progress ? 'Yes' : 'No'}
                          />
                        </div>
                        <div className="toggle-row">
                          <span className="toggle-label">Include Sponsors</span>
                          <ToggleButton
                            onToggleClick={() =>
                              updateEvent({
                                id: this.props.eventState.event.id,
                                show_sponsor_progress: !eventState.event.show_sponsor_progress,
                              })
                            }
                            isActive={eventState.event.show_sponsor_progress!}
                            label={eventState.event.show_sponsor_progress ? 'Yes' : 'No'}
                          />
                        </div>
                        <div className="toggle-row">
                          <span className="toggle-label">Show progress in percentage?</span>
                          <ToggleButton
                            onToggleClick={() =>
                              updateEvent({
                                id: this.props.eventState.event.id,
                                show_progress_in_percentage:
                                  !eventState.event.show_progress_in_percentage,
                              })
                            }
                            isActive={eventState.event.show_progress_in_percentage!}
                            label={eventState.event.show_progress_in_percentage ? 'Yes' : 'No'}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="settings-title">Donations</div>
                  <div className="settings-item">
                    <div className="details">
                      <p>Accepting Donations</p>
                      <span className="detail">Are you accepting donations at this time?</span>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          this.props.updateEvent({
                            id: this.props.eventState.event.id,
                            accepting_donations: !this.props.eventState.event.accepting_donations,
                          });
                        }}
                        className={
                          this.props.eventState.event.accepting_donations
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      ></i>
                    </div>
                  </div>

                  <div className="settings-item">
                    <div className="details">
                      <p>Allow Checkout Donations</p>
                      <span className="detail">
                        Do you want to allow users to make a donation upon checkout?
                      </span>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          this.toggleAllowCheckoutDonations();
                        }}
                        className={
                          this.state.allow_checkout_donations
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      ></i>
                    </div>
                  </div>
                  {/* GIG-1534: Disable "Allow Monthly Donations" on Event for now (since not implemented fully yet), will need to be added back later. */}
                  {/* <div className="settings-item">
                                    <div className="details">
                                        <p>{t("Allow Monthly Donations")}</p>
                                        <span className="detail">{t("Do you want donors to have the option to donate monthly?")}</span>
                                    </div>
                                    <div className="settings-control">
                                        <i onClick={() => { this.props.updateEvent({ id: this.props.eventState.event.id, allow_subscriptions: !this.props.eventState.event.allow_subscriptions }); }} className={this.props.eventState.event.allow_subscriptions ? "fad fa-toggle-on" : "fad fa-toggle-off"}></i>
                                    </div>
                                </div> */}
                  <div className="settings-item">
                    <div className="details">
                      <p>Use Donation Descriptions</p>
                      <span className="detail">
                        Would you like to include descriptions for each of your preset donation
                        amounts?
                      </span>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          this.props.updateEvent({
                            id: this.props.eventState.event.id,
                            use_donation_amount_descriptions: !this.state.useDonationDescriptions,
                          });
                        }}
                        className={
                          this.props.eventState.event.use_donation_amount_descriptions
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      ></i>
                    </div>
                  </div>
                  <div className={`settings-item ${!this.state.isPurplePlus ? 'disabled' : ''}`}>
                    <div className="details">
                      <p>Show tip option to donors</p>
                      <span className="detail">
                        Help support the Kambeo platform to continue providing free solutions to
                        charities who need it.
                      </span>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          if (this.state.isPurplePlus) {
                            this.props.updateEvent({
                              id: this.props.eventState.event.id,
                              fee_control: { show_tip: !this.state.feeControl.allowTips },
                            });
                          }
                        }}
                        className={
                          this.props.eventState.event.fee_control?.show_tip
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      ></i>
                    </div>
                  </div>
                  <div
                    className="settings-item hover-item no-select donation-amounts"
                    onClick={() => {
                      this.toggleActiveRow('amounts');
                    }}
                  >
                    <div className="details">
                      <p>Preset Donation Amounts</p>
                      <span className="detail">
                        Edit the five preset donation amounts that visitors see as suggested amounts
                        on your donation page.
                      </span>
                    </div>
                    {this.state.activeRow === 'amounts' && (
                      <form
                        onSubmit={(e) => this.saveDonationAmounts(e)}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion amounts"
                      >
                        {this.state.donationAmounts &&
                          this.state.donationAmounts.map((amount, index) => {
                            return (
                              <div
                                key={index}
                                className="donation-amount"
                              >
                                <div className="index">{index + 1}</div>
                                <div className="donation-amount-text">
                                  <TextField
                                    required={true}
                                    onChange={(e) => {
                                      this.handleDonationAmountInputChange(e, this);
                                    }}
                                    value={amount.amount.toString()}
                                    name={'donationAmount-' + index}
                                    type="number"
                                    min="5"
                                  />
                                </div>
                                {this.state.useDonationDescriptions && (
                                  <div className="donation-description">
                                    <TextField
                                      placeholder={'Donation description'}
                                      required={true}
                                      onChange={(e) => {
                                        this.handleDonationAmountInputChange(e, this);
                                      }}
                                      value={amount.description ?? ''}
                                      name={'donationDescription-' + index}
                                      type="text"
                                      maxLength={80}
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        <div className="amounts-actions">
                          <Button
                            isDisabled={
                              this.state.donationAmounts ===
                              this.props.eventState.event.donation_amounts
                            }
                            text="Update"
                          />
                          <Button
                            text="Reset to default"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  donationAmounts: [
                                    { amount: 25, description: '' },
                                    { amount: 50, description: '' },
                                    { amount: 100, description: '' },
                                    { amount: 250, description: '' },
                                    { amount: 500, description: '' },
                                  ],
                                },
                                () => {
                                  this.props.updateEvent({
                                    id: this.props.eventState.event.id,
                                    donation_amounts: this.state.donationAmounts,
                                  });
                                },
                              );
                            }}
                          />
                        </div>
                      </form>
                    )}
                  </div>
                  <div
                    className="settings-item hover-item"
                    onClick={() => {
                      this.toggleActiveRow('donationCheckoutMessage');
                    }}
                  >
                    <div className="details">
                      <p>Custom Checkout Message</p>
                      <span className="detail">
                        This will be the default checkout message on all donation checkout pages.
                      </span>
                    </div>
                    {this.state.activeRow === 'donationCheckoutMessage' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion message"
                      >
                        <QuillTextEditor
                          value={this.state.donationCheckoutMessage}
                          readOnly={false}
                          preserveWhitespace={true}
                          theme="snow"
                          modules={this.state.modules}
                          formats={this.state.formats}
                          onChange={(msg) => this.setState({ donationCheckoutMessage: msg })}
                          maxLength={300}
                        />
                        <div className="message-actions">
                          <Button
                            text="Save Message"
                            isDisabled={
                              extractLettersFromQuillMarkup(this.state.donationCheckoutMessage)
                                .length > 300
                            }
                            onClick={() => {
                              this.saveDonationCheckoutMessage();
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="settings-title">Donation Matching</div>
                  <div
                    className="settings-item hover-item no-select"
                    onClick={() => {
                      this.toggleActiveRow('matching');
                    }}
                  >
                    <div className="details">
                      <p>Donation Matching</p>
                      <span className="detail">
                        Setup a giving initiative in which an organization matches contributions,
                        increasing the overall gift.
                      </span>
                    </div>
                    {this.state.activeRow === 'matching' && (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.props.updateEvent(
                            {
                              id: this.props.eventState.event.id,
                              donations_matching: {
                                organization: this.state.orgName,
                                max_amount: this.state.maximum_matched,
                                ratio: parseFloat((1 / this.state.matchRatio).toFixed(3)),
                                ratio_description: this.state.ratioDescription,
                              },
                            },
                            (event: IEventSummaryFE) => {
                              const toast = toastSuccess(
                                localizeHelpers.translate(
                                  'Event Donation Matching updated successfully.',
                                ),
                                'Event Management',
                              );
                              this.props.createToast(toast);
                            },
                          );
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion donation-matching"
                      >
                        <TextField
                          label="Maximum Amount Matched"
                          required={true}
                          onChange={(e) => {
                            handleInputChange(e, this);
                          }}
                          value={this.state.maximum_matched}
                          name={'maximum_matched'}
                          type="number"
                        />
                        <TextField
                          label="Organization Name"
                          required={true}
                          onChange={(e) => {
                            handleInputChange(e, this);
                          }}
                          value={this.state.orgName}
                          name={'orgName'}
                          type="text"
                        />
                        <div
                          className="inline"
                          data-isolate="yes"
                        >
                          <p>$1 will be matched for every </p>
                          <TextField
                            required={true}
                            onChange={(e) => {
                              handleInputChange(e, this);
                            }}
                            value={this.state.matchRatio}
                            name={'matchRatio'}
                            type="number"
                          />
                          <p>donated.</p>
                        </div>
                        <TextField
                          placeholder={this.getMatchingDescription()}
                          translatePlaceholder={false}
                          label="Description"
                          required={true}
                          onChange={(e) => {
                            handleInputChange(e, this);
                          }}
                          value={this.state.ratioDescription}
                          name={'ratioDescription'}
                          type="text"
                        />
                        <div className="matching-actions">
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              this.removeMatching();
                            }}
                            text={'Remove'}
                          />
                          <Button
                            onClick={() => this.resetMatching()}
                            text={'Reset'}
                          />
                          <Button
                            isDisabled={!this.hasMatchingChanged()}
                            text={'Update'}
                          />
                        </div>
                      </form>
                    )}
                  </div>
                  <div
                    id="campaign"
                    className="settings-title"
                  >
                    Campaign
                  </div>
                  <div
                    className="settings-item hover-item no-select"
                    onClick={() => {
                      this.toggleActiveRow('campaign');
                    }}
                  >
                    <div className="details">
                      <p>Active Campaign</p>
                      <span className="detail">
                        You must have an active campaign in order to accept any types of payment.
                      </span>
                    </div>
                    {this.state.activeRow === 'campaign' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion"
                      >
                        <Dropdown
                          label={
                            this.props.eventState.event.event_type ===
                            Constants.event_type.obo_fundraiser
                              ? 'You cannot change Active campaign for event that has been created on behalf of cause'
                              : ''
                          }
                          disabled={
                            this.props.eventState.event.event_type ===
                            Constants.event_type.obo_fundraiser
                          }
                          value={this.state.activeCampaign}
                          shouldSort={true}
                          name="activeCampaign"
                          options={this.state.campaigns}
                          notranslate="yes"
                          onChange={(e) => {
                            handleInputChange(e, this, false, () => {
                              this.props.updateEvent({
                                id: this.props.eventState.event.id,
                                campaign_id:
                                  this.state.activeCampaign !== ''
                                    ? this.state.activeCampaign
                                    : null,
                              });

                              const toast = toastSuccess(
                                localizeHelpers.translate('Active campaign updated successfully.'),
                                'Event Management',
                              );
                              this.props.createToast(toast);
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="settings-item hover-item no-select"
                    onClick={() => {
                      this.toggleActiveRow('fund');
                    }}
                  >
                    <div className="details">
                      <p>Default Fund</p>
                      <span className="detail">
                        Set a default fund for campaigns and donations.
                      </span>
                    </div>
                    {this.state.activeRow === 'fund' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion"
                      >
                        <Dropdown
                          shouldSort={true}
                          value={this.state.defaultFund}
                          name="defaultFund"
                          options={this.state.funds}
                          notranslate="yes"
                          onChange={(e) => {
                            handleInputChange(e, this, false, () => {
                              this.props.updateEvent({
                                id: this.props.eventState.event.id,
                                default_fund_name:
                                  this.state.defaultFund !== '' ? this.state.defaultFund : null,
                              });

                              const toast = toastSuccess(
                                localizeHelpers.translate('Default fund updated successfully.'),
                                'Event Management',
                              );
                              this.props.createToast(toast);
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="settings-item">
                    <div className="details">
                      <p>Fund Selection for Donors</p>
                      <span className="detail">
                        Allow donors to choose which fund they prefer their donation to be directed
                        to.
                      </span>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          this.props.updateEvent({
                            id: this.props.eventState.event.id,
                            allow_donation_fund_selection:
                              !this.props.eventState.event.allow_donation_fund_selection,
                          });
                        }}
                        className={
                          this.props.eventState.event.allow_donation_fund_selection
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      />
                    </div>
                  </div>
                  <div className="settings-title">Receipts</div>
                  <div
                    className="settings-item hover-item"
                    onClick={() => {
                      this.toggleActiveRow('receipt-header');
                    }}
                  >
                    <div className="details">
                      <p>Header</p>
                      <span className="detail">
                        This will be the default header message sent on all store and auction
                        purchase receipts.
                      </span>
                    </div>
                    {this.state.activeRow === 'receipt-header' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion message"
                      >
                        <QuillTextEditor
                          value={this.state.receiptHeader}
                          readOnly={false}
                          preserveWhitespace={true}
                          theme="snow"
                          modules={this.state.modules}
                          formats={this.state.formats}
                          onChange={this.handleReceiptHeaderChange}
                        />
                        <div className="message-actions">
                          <Button
                            text="Update Message"
                            onClick={() => {
                              this.saveHeader();
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="settings-item hover-item"
                    onClick={() => {
                      this.toggleActiveRow('receipt-footer');
                    }}
                  >
                    <div className="details">
                      <p>Footer</p>
                      <span className="detail">
                        This will be the default footer message sent on all store and auction
                        purchase receipts.
                      </span>
                    </div>
                    {this.state.activeRow === 'receipt-footer' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion message"
                      >
                        <QuillTextEditor
                          value={this.state.receiptFooter}
                          readOnly={false}
                          preserveWhitespace={true}
                          theme="snow"
                          modules={this.state.modules}
                          formats={this.state.formats}
                          onChange={this.handleReceiptFooterChange}
                        />
                        <div className="message-actions">
                          <Button
                            text="Update Message"
                            onClick={() => {
                              this.saveFooter();
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="settings-title">Invoicing</div>

                  <div className="settings-item">
                    <div className="details">
                      <p>Allow Invoicing</p>
                      <span className="detail">
                        Allow users to request an invoice upon checkout for payment at a later date.
                      </span>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() =>
                          this.props.updateEvent({
                            id: this.props.eventState.event.id,
                            allow_invoicing: !this.props.eventState.event.allow_invoicing,
                          })
                        }
                        className={
                          this.props.eventState.event.allow_invoicing
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      ></i>
                    </div>
                  </div>

                  <div className="settings-title">Fee Payment</div>
                  <div className="settings-item hover-item no-select">
                    <div className="details">
                      <p>Processing Fee</p>
                      <span className="detail">
                        This is a standard third-party fee for processing online transactions.
                      </span>
                    </div>
                    <div className="expansion fees">
                      <div
                        onClick={() => {
                          this.updateProcessingFee(false);
                        }}
                        className="fee-option"
                      >
                        <div className="fee-radio">
                          <i
                            className={
                              !this.state.feeControl.processingFees
                                ? 'fas fa-circle'
                                : 'far fa-circle'
                            }
                          />
                        </div>
                        <div className="fee-content">
                          <div className="fee-title">User pays fee</div>
                          <div className="fee-description">
                            Fee is added to the users transaction (donations, auction winnings,
                            store and ticket purchases).
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          this.updateProcessingFee(true);
                        }}
                        className="fee-option"
                      >
                        <div className="fee-radio">
                          <i
                            className={
                              this.state.feeControl.processingFees
                                ? 'fas fa-circle'
                                : 'far fa-circle'
                            }
                          />
                        </div>
                        <div className="fee-content">
                          <div className="fee-title">Cause pays fee</div>
                          <div className="fee-description">
                            Fee is charged to your cause per transaction (user does not see the
                            fee).
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="settings-item hover-item no-select">
                    <div className="details">
                      <p>Platform Fee</p>
                      <span className="detail">
                        This small fee added onto transactions helps Kambeo cover basic operating
                        expenses.
                      </span>
                    </div>
                    <div className="expansion fees">
                      <div
                        onClick={() => {
                          this.updateGigitFee(false);
                        }}
                        className="fee-option"
                      >
                        <div className="fee-radio">
                          <i
                            className={
                              !this.state.feeControl.gigitFees ? 'fas fa-circle' : 'far fa-circle'
                            }
                          />
                        </div>
                        <div className="fee-content">
                          <div className="fee-title">User pays fee</div>
                          <div className="fee-description">
                            Fee is added to the users transaction (donations, auction winnings,
                            store and ticket purchases).
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          this.updateGigitFee(true);
                        }}
                        className="fee-option"
                      >
                        <div className="fee-radio">
                          <i
                            className={
                              this.state.feeControl.gigitFees ? 'fas fa-circle' : 'far fa-circle'
                            }
                          />
                        </div>
                        <div className="fee-content">
                          <div className="fee-title">Cause pays fee</div>
                          <div className="fee-description">
                            Fee is charged to your cause per transaction (user does not see the
                            fee).
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="settings-title">Fundraiser Details</div>
                  <div className="settings-item hover-item">
                    <div
                      className="details"
                      onClick={() => this.toggleActiveRow('fundraser-default-story')}
                    >
                      <p>Default Fundraise Description</p>
                      <span className="detail">
                        Allow fundraiser pages to have a custom default description.
                      </span>
                    </div>
                    {this.state.activeRow === 'fundraser-default-story' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion message"
                      >
                        <QuillTextEditor
                          value={this.state.defaultFundraiseStory}
                          readOnly={false}
                          preserveWhitespace={true}
                          theme="snow"
                          modules={this.state.modules}
                          formats={this.state.formats}
                          onChange={(content: string) =>
                            this.setState({ defaultFundraiseStory: content })
                          }
                        />
                        <div className="message-actions">
                          <Button
                            text="Update Message"
                            onClick={() => {
                              this.saveDefaultFundraiseStory();
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="settings-title">Notifications</div>
                  <div className="settings-item hover-item no-select">
                    <div className="details">
                      <span className="detail">
                        Manage which administrators will receive messages from users
                      </span>
                    </div>

                    <div className="expansion contact">
                      <div className="notification-email-input">
                        <TextField
                          onChange={(e) => {
                            this.setState({ notification_email_input: e.target.value });
                          }}
                          name="externalNotificationEmail"
                          value={this.state.notification_email_input}
                          label="Email"
                        />
                        <Button
                          onClick={() =>
                            this.updateNotificationEmails(this.state.notification_email_input)
                          }
                          text="Add"
                          type="submit"
                        />
                      </div>

                      <div className="notification-list">
                        {this.props.eventState.event.notification_external_emails?.map(
                          (notification_email, index) => {
                            return (
                              <>
                                <div className="notification-item">
                                  <div>{notification_email}</div>{' '}
                                  <Button
                                    text="Remove"
                                    onClick={() => {
                                      this.removeExternalNotificationEmail(index);
                                    }}
                                    type="submit"
                                  />
                                </div>
                              </>
                            );
                          },
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="settings-title">Contact</div>
                  <div className="settings-item hover-item no-select">
                    <div className="details">
                      <p>Contact Details</p>
                      <span className="detail">Manage how users can get in touch with you.</span>
                    </div>

                    <div className="expansion contact">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.saveEventContactDetails();
                        }}
                      >
                        <TextField
                          label="Phone (ex. +1-234-567-8900)"
                          value={this.state.contactPhone}
                          name="contactPhone"
                          type="tel"
                          onChange={(e) => handleInputChange(e, this)}
                        />
                        <TextField
                          label="Email"
                          value={this.state.contactEmail}
                          name="contactEmail"
                          type="email"
                          onChange={(e) => handleInputChange(e, this)}
                        />

                        <div className="actions">
                          <Button
                            buttonClass="cancel-button"
                            text="Cancel"
                            type="button"
                            onClick={() => {
                              this.setState({
                                contactPhone:
                                  this.props.eventState.event.contact_details?.phone || '',
                                contactEmail:
                                  this.props.eventState.event.contact_details?.email || '',
                              });
                            }}
                          />
                          <Button
                            buttonClass="save-button"
                            text="Save"
                            type="submit"
                          />
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="settings-title">Organizer Contacts</div>
                  <div className="settings-item hover-item no-select">
                    <div className="details">
                      <span className="detail">
                        Manage which administrators will receive messages from users
                      </span>
                    </div>

                    <OrganizerContacts
                      objectType={Constants.object_type.event}
                      objectId={this.props.eventState.event._id}
                    />
                  </div>

                  <div className="settings-title">Action Buttons</div>
                  <div className="settings-item hover-item no-select">
                    <div className="details">
                      <p>Call to Action Button Text</p>
                      <span className="detail">
                        Select the text that will appear on your event's action buttons
                      </span>
                    </div>

                    <div className="expansion container-half">
                      <Dropdown
                        value={this.state.registerActionText}
                        label="Register Action"
                        shouldSort={true}
                        onChange={(e) => {
                          handleInputChange(e, this, false, () => {
                            this.props.updateEvent({
                              id: eventState.event.id,
                              register_action_text: this.state.registerActionText,
                            });
                          });
                        }}
                        name="registerActionText"
                        options={registerActionOptions}
                      />
                      <Dropdown
                        value={this.state.fundraiseActionText}
                        shouldSort={true}
                        label="Fundraise Action"
                        onChange={(e) => {
                          handleInputChange(e, this, false, () => {
                            this.props.updateEvent({
                              id: eventState.event.id,
                              fundraise_action_text: this.state.fundraiseActionText,
                            });
                          });
                        }}
                        name="fundraiseActionText"
                        options={fundraiseActionOptions}
                      />
                      <Dropdown
                        value={this.state.sponsorshipActionText}
                        shouldSort={true}
                        label="Sponsor Action"
                        onChange={(e) => {
                          handleInputChange(e, this, false, () => {
                            this.props.updateEvent({
                              id: eventState.event.id,
                              sponsorship_action_text: this.state.sponsorshipActionText,
                            });
                          });
                        }}
                        name="sponsorshipActionText"
                        options={sponsorshipActionOptions}
                      />
                      <Dropdown
                        value={this.state.volunteerActionText}
                        shouldSort={true}
                        label="Volunteer Action"
                        onChange={(e) => {
                          handleInputChange(e, this, false, () => {
                            this.props.updateEvent({
                              id: eventState.event.id,
                              volunteer_action_text: this.state.volunteerActionText,
                            });
                          });
                        }}
                        name="volunteerActionText"
                        options={volunteerActionOptions}
                      />
                    </div>
                  </div>

                  <div className="settings-title">Integrations</div>
                  <div className="settings-item integrations">
                    <div className="icon">
                      <i className="fab fa-twitch" />
                    </div>
                    <div className="details">
                      <div
                        notranslate="yes"
                        className="details-title"
                      >
                        Twitch
                      </div>
                      <div className="details-subtitle">
                        Allow participants to connect their Twitch account and embed a livestream in
                        their fundraising pages
                      </div>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          this.props.toggleEventContentCreator(eventState.event.id);
                        }}
                        className={
                          eventState.event.content_creator_enabled
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      ></i>
                    </div>
                  </div>
                  <div className="settings-item integrations">
                    <div className="icon">
                      <img
                        src={StravaIcon}
                        alt="strava-icon"
                      />
                    </div>
                    <div className="details">
                      <div
                        notranslate="yes"
                        className="details-title"
                      >
                        Strava
                      </div>
                      <div className="details-subtitle">
                        Allow participants to connect their Strava account and display their
                        activity on fundraising pages
                      </div>
                      <p></p>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          this.props.toggleEventFitness(eventState.event.id);
                        }}
                        className={
                          eventState.event.fitness_tracking_enabled
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isLoaded && this.state.activeTab === 'roles' && <RoleManagement owner={ownerObj} />}
            {isLoaded && this.state.activeTab === 'schedule' && (
              <Scheduler
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'hours' && (
              <HourManagement
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'attendees' && (
              <AttendeeManagement
                eventId={this.props.eventState.event.id}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'fundraisers' && (
              <EventFundraiserManage owner={ownerObj} />
            )}
            {isLoaded && this.state.activeTab === 'locations' && (
              <LocationManagement owner={ownerObj} />
            )}

            {isLoaded && this.state.activeTab === 'donationsPayments' && (
              <TransactionManagement
                owner={ownerObj}
                transactionsType="donationsPayments"
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'storePayments' && (
              <TransactionManagement
                owner={ownerObj}
                transactionsType="storePayments"
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'gigsPayments' && (
              <TransactionManagement
                owner={ownerObj}
                transactionsType="gigsPayments"
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'auctionsPayments' && (
              <TransactionManagement
                owner={ownerObj}
                transactionsType="auctionsPayments"
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'sponsors' && <SponsorManagement />}
            {isLoaded && this.state.activeTab === 'donors' && <DonorManagement owner={ownerObj} />}

            {isLoaded && this.state.activeTab === 'members' && (
              <MemberManagement
                owner={ownerObj}
                {...this.props}
                permissions={this.state.userPermissions}
              />
            )}
            {isLoaded && this.state.activeTab === 'store' && (
              <StoreItemManagement
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'auction' && (
              <AuctionManagement
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'categories' && (
              <CategoriesManagement owner={ownerObj} />
            )}
            {isLoaded && this.state.activeTab === 'discounts' && (
              <DiscountCodeManagement
                owner={ownerObj}
                {...this.props}
              />
            )}
            {isLoaded && this.state.activeTab === 'embeds' && (
              <EmbedManagement
                {...this.props}
                owner={ownerObj}
              />
            )}
            <Modal
              show={this.state.showLoginModal}
              onClose={(e) => {
                e.stopPropagation();
                this.setState({ showLoginModal: false });
              }}
            >
              <Login
                registerRedirectUrl={'/event/' + this.props.eventState.event.handle + '/admin'}
                {...this.props}
              />
            </Modal>
          </div>
          <Construction {...this.props} />
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    eventState: store.eventState,
    groupState: store.groupState,
    storeState: store.storeState,
  };
};

const mapDispatchToProps = {
  createToast,
  updateEvent,
  toggleEventFitness,
  toggleEventContentCreator,
  //getEventPageComponent,
  getEventPageComponents,
  getEvent,
  getEventPermissions,
  changeEventHandle,
  getStoreItems,
  updateEventRaisedAmount,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventAdmin);
