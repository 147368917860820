import { IAuctionItemSummary } from '@gigit/interfaces';
import React, { Component } from 'react';
import { combineClassNames } from '../../../../helpers';
import Portrait from '../../../Portrait/Portrait';
import auctionItemHelpers from '../auctionItemHelpers';
import AuctionitemLeaderboardModal from '../AuctionItemLeaderboardModal/AuctionitemLeaderboardModal';
import { localizeHelpers } from '../../../../localizeHelpers';
import { IOwnerObject } from '../../../../interfaces';

import './AuctionItemLeaderDisplay.scss';

interface IProps {
  owner?: IOwnerObject;
  className?: string;
  item: IAuctionItemSummary;
  locale: string;
  onPlaceBidClicked(): void;
  canPlaceBid?: boolean;
}

interface IState {
  showLeaderModal: boolean;
}

/** Component for showing an overview of a auction item leaderboard. */
class AuctionItemLeaderDisplay extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showLeaderModal: false,
    };
  }

  render() {
    const stack = auctionItemHelpers.getLeaderboardProfileImageStack(this.props.item);

    return (
      <>
        <div className={combineClassNames('AuctionItemLeaderDisplay', this.props.className)}>
          <div className="lead">
            <Portrait
              size={30}
              currentImage={
                this.props.item.current_bid &&
                !this.props.item.current_bid?.anonymous &&
                this.props.item.current_bid.profile_image_url
                  ? this.props.item.current_bid.profile_image_url
                  : ''
              }
            />
            <div className="leaders-inner">
              <p>Lead Bidder</p>
              <div
                className="user-name"
                notranslate="yes"
              >
                {this.props.item.current_bid?.display_name || 'No one'}
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              if (auctionItemHelpers.canPlaceBid(this.props.item)) {
                this.setState({ showLeaderModal: true });
              }
            }}
            className="leaderboard"
          >
            <div className="stack">
              {stack.map((item, index) => {
                return (
                  <Portrait
                    key={index}
                    size={30}
                    currentImage={item}
                  />
                );
              })}
            </div>
            <div className="leaders-inner">
              <p>Leaderboard</p>
              <div className="user-name">
                <var data-var="number_of_bidders">
                  {localizeHelpers.formatNumber(
                    this.props.item.bidders?.length || 0,
                    this.props.locale,
                  )}
                </var>{' '}
                bidder(s)
              </div>
            </div>
          </div>
        </div>

        <AuctionitemLeaderboardModal
          owner={this.props.owner}
          item={this.props.item}
          showLeaderModal={this.state.showLeaderModal}
          onClose={() => this.setState({ showLeaderModal: false })}
          onPlaceBidClicked={this.props.onPlaceBidClicked}
          canPlaceBid={this.props.canPlaceBid}
        />
      </>
    );
  }
}

export default AuctionItemLeaderDisplay;
