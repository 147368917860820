import React, { useEffect, useState } from 'react';
import { IHub, IVolunteerHoursMatchList, IVolunteerMatchStats } from '@gigit/interfaces';
import './HubManagementVolunteerHours.scss';
import { defaultCurrency, formatCurrency, typeHelpers } from '../../../../helpers';
import Table, { ITableProps } from '../../../shared/Table/Table';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { IKpi } from '../../../../interfaces';
import HubManagementMetricContainer from '../shared/HubManagementMetricContainer/HubManagementMetricContainer';
import { Constants } from '@gigit/constants';
import { hubRequestActions } from '../../../../requestActions';
import AsyncSelectField from '../../../shared/forms/select/AsyncSelectField/AsyncSelectField';
import { ISelectOption } from '../../../shared/forms/select/types';
import useToastDispatcher from '../../../../hooks/useToaster';

interface IProps {
  hub?: IHub;
}

const initialKPIs: IKpi[] = [
  {
    text: 'Total Hours Logged',
    value: '-',
  },
  {
    text: 'Charities Supported',
    value: '-',
  },
  {
    text: 'Hours Awaiting Approval',
    value: '-',
  },
  {
    text: 'Total Credits Approved ($)',
    value: '-',
  },
];

const initialMatchingProgram: ISelectOption = {
  label: 'All',
  value: 'all',
};

function HubManagementVolunteerHours(props: IProps) {
  const [selectedMatchingProgram, setSelectedMatchingProgram] = useState<ISelectOption | null>(
    initialMatchingProgram,
  );

  const [KPIs, setKPIs] = useState<IKpi[]>(initialKPIs);

  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const { dispatchToastError } = useToastDispatcher();

  useEffect(() => {
    getKPIs();
  }, []);

  async function getKPIs(program?: string) {
    const currency = props.hub?.account?.currency ?? defaultCurrency;
    let volunteerMatchStats: IVolunteerMatchStats | null = null;
    if (program !== 'all' && program != null) {
      volunteerMatchStats = await hubRequestActions.getVolunteerMatchStatsForHubAndProgram(
        props.hub?.id!,
        program,
      );
    } else {
      volunteerMatchStats = await hubRequestActions.getVolunteerMatchStatsForHub(props.hub?.id!);
    }

    const convertToKPI: IKpi[] = [
      {
        text: 'Total Hours Logged',
        value: localizeHelpers.formatNumber(volunteerMatchStats.total_hours_logged, locale),
      },
      {
        text: 'Charities Supported',
        value: localizeHelpers.formatNumber(volunteerMatchStats.total_groups, locale),
      },
      {
        text: 'Hours Awaiting Approval',
        value: localizeHelpers.formatNumber(volunteerMatchStats.total_awaiting_approval, locale),
      },
      {
        text: 'Total Credits Approved ($)',
        value: formatCurrency(volunteerMatchStats.total_value_approved, currency, locale),
      },
    ];

    setKPIs(volunteerMatchStats ? convertToKPI : initialKPIs);
  }

  // async function refreshAllPageState() {
  //   await refreshTableData();
  //   await getKPIs(selectedMatchingProgram?.value);
  // }

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  const emptyTableStateLabel =
    selectedMatchingProgram?.value === 'all'
      ? 'Select a Matching Program to view and manage Volunteer records'
      : "We couldn't find any Volunteer Hours";

  const tableProps: ITableProps<IVolunteerHoursMatchList> = {
    columns: [
      {
        id: 'user.display_name',
        Header: 'Name',
        accessor: ({ user }) => user.display_name,
        sortable: true,
        notranslate: 'yes',
      },
      {
        id: 'hours',
        Header: 'Hours',
        accessor: ({ volunteer_hours }) => volunteer_hours,
        sortable: true,
      },
      {
        id: 'full_match_value',
        Header: 'Amount',
        accessor: ({ full_match_value }) =>
          formatCurrency(full_match_value, props.hub?.account?.currency!, locale),
        sortable: true,
      },
      {
        id: 'matched_value',
        Header: 'Approved Amount',
        accessor: ({ matched_value }) =>
          formatCurrency(matched_value, props.hub?.account?.currency!, locale),
        sortable: true,
      },
      {
        id: 'start_date',
        Header: 'Date of hours logged',
        accessor: ({ start_date }) =>
          localizeHelpers.formatDate(start_date ?? new Date(), LocaleDateFormats.ll, locale),
        sortable: true,
      },
      {
        id: 'match_status',
        Header: 'Match Status',
        accessor: ({ donation_match_status }) => donation_match_status?.code,
        predefinedColumnType: {
          type: 'STATUS',
          columnObject: () => ({
            [Constants.donation_matching_transaction_status.pending]: {
              label: 'Pending',
              color: 'YELLOW',
            },
            [Constants.donation_matching_transaction_status.declined]: {
              label: 'Declined',
              color: 'RED',
            },
            [Constants.donation_matching_transaction_status.matched]: {
              label: 'Matched',
              color: 'GREEN',
            },
          }),
        },
      },
      {
        id: 'recipient_cause',
        Header: 'Recipient Cause',
        accessor: ({ group, custom_cause_name }) => group.title || custom_cause_name,
        sortable: true,
        notranslate: 'yes',
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Approve',
          hideIf: (item) =>
            item.donation_match_status?.code ===
              Constants.donation_matching_transaction_status.matched ||
            item.donation_match_status?.code ===
              Constants.donation_matching_transaction_status.declined ||
            selectedMatchingProgram?.value === 'all',
          onClick: (_, item) => {
            approveDonationMatch(item);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Decline',
          hideIf: (item) =>
            item.donation_match_status?.code ===
              Constants.donation_matching_transaction_status.matched ||
            item.donation_match_status?.code ===
              Constants.donation_matching_transaction_status.declined ||
            selectedMatchingProgram?.value === 'all',
          onClick: (_, item) => {
            rejectDonationMatch(item);
          },
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        typeHelpers.assertNotNullOrUndefined(props.hub?.id);
        if (selectedMatchingProgram != null && selectedMatchingProgram.value !== 'all') {
          const response = await hubRequestActions.getVolunteerMatchList(
            props.hub.id,
            selectedMatchingProgram.value,
            queryParams,
          );
          return response;
        } else if (selectedMatchingProgram?.value === 'all') {
          const response = await hubRequestActions.getAllVolunteerMatchList(
            props.hub.id,
            queryParams,
          );
          return response;
        }

        return [];
      },
    },
    filterOptions: {
      enableTableSearch: true,
    },
    emptyStateConfig: {
      title: 'No data',
      description: emptyTableStateLabel,
    },
  };

  async function getDonationMatchingProgramOptions(): Promise<ISelectOption[]> {
    let donationMatchingPrograms = await hubRequestActions.getHubDonationMatchingPrograms(
      props.hub?.id!,
    );

    donationMatchingPrograms = donationMatchingPrograms.filter(
      (program) =>
        program.program_type === Constants.donation_matching_program_type.volunteer_for_dollars,
    );

    return [
      {
        label: 'All',
        value: 'all',
      },
      ...donationMatchingPrograms.map((program) => ({
        label: program.name,
        value: program.id,
      })),
    ];
  }

  async function approveDonationMatch(item: IVolunteerHoursMatchList) {
    try {
      await hubRequestActions.approveVolunteerMatch({
        hubId: props.hub?.id!,
        programId: selectedMatchingProgram?.value!,
        loggedHoursId: item._id,
      });
    } catch (error) {
      dispatchToastError(error, 'Approve Volunteer Match Error');
    } finally {
      refreshTableData();
      getKPIs(selectedMatchingProgram?.value);
    }
  }

  async function rejectDonationMatch(item: IVolunteerHoursMatchList) {
    try {
      await hubRequestActions.rejectVolunteerMatch({
        hubId: props.hub?.id!,
        programId: selectedMatchingProgram?.value!,
        loggedHoursId: item._id,
      });
    } catch (error) {
      dispatchToastError(error, 'Reject Volunteer Match Error');
    } finally {
      refreshTableData();
      getKPIs(selectedMatchingProgram?.value);
    }
  }

  return (
    <>
      <div
        className="HubManagementVolunteerHours"
        id="volunteer-hours"
      >
        <div className="header-section">
          <h2>Hours for Dollars</h2>
          <AsyncSelectField
            onSearchRequest={getDonationMatchingProgramOptions}
            label="Select a Matching Program to view and manage Volunteer Credits:"
            name="selectedDonationMatchingProgram"
            defaultValue={initialMatchingProgram}
            onChange={(selectedOption) => {
              setSelectedMatchingProgram(selectedOption ?? null);
              refreshTableData();
              getKPIs(selectedOption?.value);
            }}
          />
          <div className="header-actions"></div>
        </div>
        <HubManagementMetricContainer metrics={KPIs} />
        <div className="table-section">
          <Table
            {...tableProps}
            refreshTableIncrementor={refreshTableIncrementor}
          />
        </div>
      </div>
    </>
  );
}

export default HubManagementVolunteerHours;
