export const groupRolePermissionTranslations: { [key: string]: { title: string; text: string } } = {
  VIEW_GROUP_ROLES: {
    title: 'View Cause Roles',
    text: 'Access to view cause roles and permissions',
  },
  EDIT_GROUP_INFO: {
    title: 'Edit Cause Information',
    text: 'Access to edit cause name, cover picture, and profile image',
  },
  EDIT_GROUP_ROLES: {
    title: 'Edit Cause Roles',
    text: 'Access to edit cause roles and permissions',
  },
  EDIT_MEMBER_ACCESS: {
    title: 'Edit Member Access',
    text: 'Access to change a users role in a cause',
  },
  EDIT_GROUP_PAGES: {
    title: 'Edit Cause Pages',
    text: 'Access to add/edit/remove pages from a cause page',
  },
  VIEW_PRIVATE_COMPONENTS: {
    title: 'View Private Components',
    text: 'Access to view private components on a cause page',
  },
  MANAGE_GROUP_EVENTS: {
    title: 'Manage Cause Events',
    text: 'Access to edit/add/remove events from a cause page. Each user with this permission will be an admin on newly created events',
  },
  MANAGE_HUB_ASSOCIATIONS: {
    title: 'Manage Hub Causes',
    text: 'Access to approve/deny causes applying for a cause hub',
  },
  MANAGE_STORE_ITEMS: {
    title: 'Manage Store Items',
    text: 'Access to edit/add/remove store items from a cause',
  },
  MANAGE_APPLICATIONS: {
    title: 'Manage Applications',
    text: 'Access to approve/deny applicants to a cause',
  },
  MANAGE_CAMPAIGNS: {
    title: 'Manage Campaigns',
    text: 'Access to edit/add/remove campaigns from a cause',
  },
  MANAGE_GIGS: {
    title: 'Manage Volunteer Opportunities',
    text: 'Access to edit/add/remove volunteer opportunities from a cause',
  },
  MANAGE_DONATIONS: {
    title: 'Manage Donations',
    text: 'Access to view/manage donations to a cause',
  },
  MANAGE_PAYMENTS: { title: 'Manage Payments', text: 'Access to view/manage payments to a cause' },
  MANAGE_FUNDRAISERS: { title: 'Manage Fundraisers', text: 'Access to manage fundraisers' },
  GROUP_MESSAGE_NOTIFICATIONS: {
    title: 'Receive Cause Messages',
    text: 'Receive messages from users when attempting to contact the cause administrators',
  },
  GIG_NOTIFICATIONS: {
    title: 'Receive Volunteer Opportunity Notifications',
    text: 'Receive notifications about volunteer opportunities from the cause',
  },
  DONATION_NOTIFICATIONS: {
    title: 'Receive Donation Notifications',
    text: 'Receive notifications about donations to the cause',
  },
  PURCHASE_NOTIFICATIONS: {
    title: 'Receive Purchase Notifications',
    text: 'Receive notifications about purchases from the cause',
  },
  MEMBER_ACCESS_NOTIFICATIONS: {
    title: 'Receive Member Access Notifications',
    text: 'Receive notifications about member access changes',
  },
  HUB_ASSOCIATION_NOTIFICATIONS: {
    title: 'Receive Hub Association Notifications',
    text: 'Receive notifications about changes within a cause hub',
  },
  MANAGE_AUCTIONS: {
    title: 'Manage Auctions',
    text: 'Manage auction components and auction items',
  },
  MANAGE_INTEGRATIONS: {
    title: 'Manage Integrations',
    text: 'Manage third-party integrations that are connected to the cause',
  },
  MANAGE_BALANCE_AND_PAYOUTS: {
    title: 'Manage Balance & Payouts',
    text: 'Manage balance and payout accounts and information for the cause',
  },
  MANAGE_ACTIVITY_FEED_POSTS: {
    title: 'Manage Activity Feed Posts',
    text: 'Manage activity feed posts for the cause',
  },
  MANAGE_BILLING: { title: 'Manage Billing', text: 'Manage billing for the cause' },
  MANAGE_STORIES: { title: 'Manage Stories', text: 'Manage Stories for the cause' },
  CREATE_STORIES: { title: 'Create Stories', text: 'Create Stories for the cause' },
};

export const eventRolePermissionTranslations: { [key: string]: { title: string; text: string } } = {
  EDIT_EVENT_INFO: {
    title: 'Edit Event Information',
    text: 'Edit event name, cover picture, and profile picture',
  },
  EDIT_EVENT_ROLES: { title: 'Edit Event Roles', text: 'Edit event role permissions' },
  EDIT_EVENT_PAGES: {
    title: 'Edit Event Pages',
    text: 'Access to edit/add/remove event pages and page components',
  },
  VIEW_PRIVATE_COMPONENTS: {
    title: 'View Private Components',
    text: 'Access to view private components on an event page',
  },
  MANAGE_EVENT_USERS: { title: 'Manage Event Users', text: 'Access to modify user roles' },
  MANAGE_STORE_ITEMS: {
    title: 'Manage Store Items',
    text: 'Access to add/edit/remove store items within the event',
  },
  MANAGE_GIGS: {
    title: 'Manage Volunteer Opportunities',
    text: 'Access to add/edit/remove volunteer opportunities within the event',
  },
  MANAGE_ATTENDEES: {
    title: 'Manage Event Attendees',
    text: 'Access to check in/out users from the event',
  },
  EVENT_MESSAGE_NOTIFICATIONS: {
    title: 'Receive Event Messages',
    text: 'Receive notifications about the event',
  },
  MANAGE_AUCTIONS: {
    title: 'Manage Auctions',
    text: 'Manage auction components and auction items',
  },
  MANAGE_DONATIONS: { title: 'Manage Donations', text: 'Access and manage event donations' },
  MANAGE_PAYMENTS: {
    title: 'Manage Payments',
    text: 'Access and manage event volunteer opportunity payments',
  },
  MANAGE_FUNDRAISERS: { title: 'Manage Fundraisers', text: 'Access to manage fundraisers' },
  AUCTION_NOTIFICATIONS: { title: 'Auction Notifications', text: 'Receive auction notifications' },
  MANAGE_ACTIVITY_FEED_POSTS: {
    title: 'Manage Activity Feed Posts',
    text: 'Manage activity feed posts for the event',
  },
  MANAGE_STORIES: { title: 'Manage Stories', text: 'Manage Stories for the event' },
  CREATE_STORIES: { title: 'Create Stories', text: 'Create Stories for the event' },
};
