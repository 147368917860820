import React from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Redirect } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { routes, toastError, toastSuccess } from '../../helpers';
import { IAppState } from '../../store';
import { IToast } from '../../interfaces';

import { IUserState } from '../../reducers/user';
import { IRegisterState } from '../../reducers/register';
import { IEventState } from '../../reducers/event';

import { createToast } from '../../actions/toaster';
import { getUserInfo } from '../../actions/user';

import Loader from '../../components/Loader/Loader';

import './StravaConnect.scss';
import errorHelpers from '../../helpers/errorHelpers';
import { localizeHelpers } from '../../localizeHelpers';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  createToast(toast: IToast): void;
  getUserInfo(handle: string, cb?: any): void;
  userState: IUserState;
  registerState: IRegisterState;
  eventState: IEventState;
}

interface IState {
  redirect: boolean;
}

class StravaConnect extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    let _params = queryString.parse(this.props.location.search);

    if (_params.code && _params.scope && _params.state) {
      Axios.get(routes.ACCOUNTS.AUTHORIZE_STRAVA + this.props.location.search)
        .then((response) => {
          if (response.data.value === 'ok') {
            this.showSuccess();

            this.props.getUserInfo(this.props.userState.user.handle);

            this.setState({
              redirect: true,
            });
          }
        })
        .catch((error) => {
          const errorObject = errorHelpers.getErrorObject(error);
          this.showError(errorObject.translatedMessage);

          this.setState({
            redirect: true,
          });
        });
    } else {
      this.showError();

      this.setState({
        redirect: true,
      });
    }
  }

  showError = (error?: string) => {
    const errorMessage = error
      ? error
      : localizeHelpers.translate(
          'Authorization failed, Strava account was not connected. Please try again.',
        );
    const toast = toastError(errorMessage, 'Strava Account');

    this.props.createToast(toast);
  };

  showSuccess = () => {
    const toast = toastSuccess(
      localizeHelpers.translate('Strava account successfully connected.'),
      'Strava Account',
    );

    this.props.createToast(toast);
  };

  render() {
    if (this.state.redirect) {
      if (this.props.registerState?.flowConfig.type) {
        let event = this.props.eventState.event.handle;
        return <Redirect to={`/setup/fundraise?event=${event}&currentStep=connectStravaAccount`} />;
      } else {
        return <Redirect to="/dashboard?section=settings" />;
      }
    } else {
      return (
        <div className="StravaConnect">
          <Loader loading={true} />
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    registerState: store.registerState,
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {
  createToast,
  getUserInfo,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(StravaConnect),
);
