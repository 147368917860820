import { IConversationMessage } from '@gigit/interfaces';
import moment from 'moment';
import React, { useState } from 'react';
import { uiConstants } from '../../../constants';
import QuillTextEditor from '../../QuillTextEditor/QuillTextEditor';

import './ConversationInput.scss';

interface IConversationInputProps {
  user: any;
  conversationId: string;

  sendMessage(message: IConversationMessage): void;
  onTyping(status: string): void;
}

export const ConversationInput: React.FC<IConversationInputProps> = (
  props: IConversationInputProps,
) => {
  const [message, setMessage] = useState<string>('');

  function handleMsg(content: string) {
    setMessage(content);
  }

  function onKeyUp(event: React.KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        if (event.shiftKey) {
          return;
        } else {
          sendChatMessage();
          if (props.onTyping) props?.onTyping('stopped');
        }
        break;
      default:
        if (message === '') {
          props.onTyping('stopped');
        } else {
          props.onTyping('started');
        }
        break;
    }
  }

  function sendChatMessage() {
    if (message.length === 0) {
      return;
    }

    const payload: IConversationMessage = {
      content: message,
      id: props.conversationId === 'new' ? '' : props.conversationId,
      created_by: props.user,
      created_at: new Date(),
      meta_data: {},
    };

    props.sendMessage(payload);

    setMessage('');
  }

  return (
    <div
      className="conversation-input"
      id="convo-input"
    >
      <QuillTextEditor
        onBlur={() => props.onTyping('stopped')}
        placeholder={'Type a message...'}
        value={message}
        preserveWhitespace={true}
        theme={uiConstants.quill.editableTheme}
        modules={{
          toolbar: uiConstants.quill.chatToolbar,
          clipboard: {
            matchVisual: false,
          },
        }}
        formats={uiConstants.quill.defaultFormats}
        onChange={handleMsg}
        scrollingContainer={'#convo-input'}
        onKeyUp={(e: React.KeyboardEvent) => {
          onKeyUp(e);
        }}
      />
    </div>
  );
};
