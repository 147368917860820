import './HubVolunteerEmptyState.scss';
import React from 'react';
import Link from '../../../shared/Link/Link';
import { IHub } from '@gigit/interfaces';

interface IProps {
  isPartner: boolean;
  hub?: IHub | null;
  userHasEditPermissions?: boolean;
}

const HubVolunteerEmptyState: React.FC<IProps> = (props) => {
  return (
    <>
      <div className="HubVolunteerEmptyState">
        <div className="blank-circle">
          <div className="empty-cart-container">
            <div className="empty-cart">
              <span className="i-container">
                <i className="fas fa-hands-helping" />
              </span>
              <span className="empty1">&nbsp;</span>
              <span className="empty2">&nbsp;</span>
            </div>
            <div className="empty-cart">
              <span className="i-container">
                <i className="fas fa-hands-helping" />
              </span>
              <span className="empty1">&nbsp;</span>
              <span className="empty2">&nbsp;</span>
            </div>
            <div className="empty-cart">
              <span className="i-container">
                <i className="fas fa-hands-helping" />
              </span>
              <span className="empty1">&nbsp;</span>
              <span className="empty2">&nbsp;</span>
            </div>
          </div>
        </div>
        <span className="no-items-txt">We didn't find any results</span>
        {props.isPartner && (
          <>
            <span className="check-later">
              Try adding additional Causes to see more volunteer opportunities
            </span>
            {props.hub && props.userHasEditPermissions && (
              <Link
                to={`/company/${props.hub?.handle}/admin?t=benefiting-causes&showAddCauses=true`}
                styleType={'grey'}
                hasUnderline={true}
              >
                Add Causes
              </Link>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default HubVolunteerEmptyState;
