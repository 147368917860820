import React from 'react';
import { useEffect } from 'react';
import { IStoryComponentProps } from '../../Types/types';
import './StoryImage.scss';

export function StoryImage(props: IStoryComponentProps) {
  useEffect(() => {
    props.onPause();
  }, []);

  function handleLoadImage() {
    //HACK: set timeout is done because there is an inconsistency in safari and other browsers when useEffect is called
    setTimeout(() => {
      props.onResume();
    }, 4);
  }

  return (
    <img
      className={'StoryImage'}
      src={props.story.media_url}
      alt="story"
      onLoad={handleLoadImage}
    />
  );
}
