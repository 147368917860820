import React from 'react';
import moment from 'moment';
import Axios from 'axios';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { swapRouteParams, routes, handleInputChange, defaultCurrency } from '../../helpers';
import { Line } from 'react-chartjs-2';

import { IGroupState } from '../../reducers/group';
import { localizeHelpers } from '../../localizeHelpers';

import Dropdown, { IOptions } from '../Dropdown/Dropdown';

import './CommunityImpact.scss';
import { formatCurrency } from '../../helpers';
import { userSelectors } from '../../selectors/user';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  locale: string;
}

interface IState {
  chartRef: any;
  chartOptions: any;
  donationTrends: any;
  fundraiseTrends: any;
  volunteerTrends: any;
  donorsCount: number;
  fundraisersCount: number;
  membersCount: number;
  volunteersCount: number;
  totalDonatedAmount: number;
  totalFundraisedAmount: number;
  totalVolunteerHours: number;
  mounted: boolean;
  months: IOptions[];
  years: IOptions[];
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
}

const months: IOptions[] = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
];

class CommunityImpact extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    // Determine current month to use as end month.
    const monthIndex = moment().month();
    const monthOption = months[monthIndex] ?? months[0];

    this.state = {
      chartRef: React.createRef(),
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
      },
      donationTrends: null,
      fundraiseTrends: null,
      volunteerTrends: null,
      donorsCount: 0,
      fundraisersCount: 0,
      membersCount: 0,
      volunteersCount: 0,
      totalDonatedAmount: 0,
      totalFundraisedAmount: 0,
      totalVolunteerHours: 0,
      mounted: false,
      months: months,
      years: [],
      startMonth: 'January',
      startYear: moment().format('YYYY'),
      endMonth: monthOption.value,
      endYear: moment().format('YYYY'),
    };

    this.getCommunityImpactData = this.getCommunityImpactData.bind(this);
    this.getMonthNumber = this.getMonthNumber.bind(this);
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (!prevState.mounted && this.state.mounted) {
      this.getCommunityImpactData();

      let _years: IOptions[] = [];

      if (this.props.groupState && this.props.groupState.group) {
        for (
          let y = parseInt(moment(this.props.groupState.group.created_at).format('YYYY'));
          y <= parseInt(moment().format('YYYY'));
          y++
        ) {
          _years.push({ value: y.toString(), label: y.toString() });
        }

        this.setState({
          years: _years,
        });
      }
    }
  }

  getCommunityImpactData() {
    let _start: string = moment(
      this.state.startYear + '-' + this.getMonthNumber(this.state.startMonth) + '-01',
    )
      .startOf('month')
      .utc()
      .format();
    let _end: string = moment(
      this.state.endYear + '-' + this.getMonthNumber(this.state.endMonth) + '-01',
    )
      .endOf('month')
      .utc()
      .format();

    Axios.post(
      swapRouteParams(routes.GET_COMMUNITY_IMPACT_SUMMARY, {
        groupId: this.props.groupState.group.id,
      }),
      {
        start_date: _start,
        end_date: _end,
      },
    ).then((response) => {
      let _donationTrends: any = null;
      let _fundraiseTrends: any = null;
      let _hourTrends: any = null;

      if (response.data) {
        if (
          response.data.donation_amounts_trend &&
          response.data.donation_amounts_trend.datasets &&
          response.data.donation_amounts_trend.datasets.length > 0
        ) {
          _donationTrends = { ...response.data.donation_amounts_trend };

          for (let s in _donationTrends.datasets) {
            _donationTrends.datasets[s] = {
              fill: false,
              backgroundColor: '#0C9FB5',
              borderColor: '#0C9FB5',
              ..._donationTrends.datasets[s],
            };
          }
        }

        if (
          response.data.fundraised_amounts_trend &&
          response.data.fundraised_amounts_trend.datasets &&
          response.data.fundraised_amounts_trend.datasets.length > 0
        ) {
          _fundraiseTrends = { ...response.data.fundraised_amounts_trend };

          for (let s in _fundraiseTrends.datasets) {
            _fundraiseTrends.datasets[s] = {
              fill: false,
              backgroundColor: '#ED418A',
              borderColor: '#ED418A',
              ..._fundraiseTrends.datasets[s],
            };
          }
        }

        if (
          response.data.volunteer_hours_trend &&
          response.data.volunteer_hours_trend.datasets &&
          response.data.volunteer_hours_trend.datasets.length > 0
        ) {
          _hourTrends = { ...response.data.volunteer_hours_trend };

          for (let s in _hourTrends.datasets) {
            _hourTrends.datasets[s] = {
              fill: false,
              backgroundColor: '#6468CC',
              borderColor: '#6468CC',
              ..._hourTrends.datasets[s],
            };
          }
        }
      }

      this.setState({
        donationTrends: _donationTrends,
        fundraiseTrends: _fundraiseTrends,
        volunteerTrends: _hourTrends,
        donorsCount: response.data.donors_count,
        fundraisersCount: response.data.fundraisers_count,
        membersCount: response.data.members_count,
        volunteersCount: response.data.volunteers_count,
        totalDonatedAmount: response.data.total_donated_amount,
        totalFundraisedAmount: response.data.total_fundraised_amount,
        totalVolunteerHours: response.data.total_volunteer_hours,
      });
    });
  }

  getMonthNumber(month: string) {
    switch (month) {
      case 'January':
        return '01';
      case 'February':
        return '02';
      case 'March':
        return '03';
      case 'April':
        return '04';
      case 'May':
        return '05';
      case 'June':
        return '06';
      case 'July':
        return '07';
      case 'August':
        return '08';
      case 'September':
        return '09';
      case 'October':
        return '10';
      case 'November':
        return '11';
      case 'December':
        return '12';
      default:
        return '01';
    }
  }

  render() {
    const currency = this.props.groupState.group.account?.currency ?? defaultCurrency;

    return (
      <div className="CommunityImpact section-wrap">
        <div className="section-title">
          <div className="section-inner-title">Community Impact</div>
        </div>
        <div className="section-inner">
          <div className="date-range">
            <div className="date-wrap">
              <div className="start">
                <Dropdown
                  value={this.state.startMonth}
                  onChange={(e) => {
                    handleInputChange(e, this, false, () => {
                      this.getCommunityImpactData();
                    });
                  }}
                  name="startMonth"
                  options={this.state.months}
                />
                <Dropdown
                  value={this.state.startYear}
                  onChange={(e) => {
                    handleInputChange(e, this, false, () => {
                      this.getCommunityImpactData();
                    });
                  }}
                  name="startYear"
                  options={this.state.years}
                  notranslate="yes"
                />
              </div>
              <span className="divider">-</span>
              <div className="end">
                <Dropdown
                  value={this.state.endMonth}
                  onChange={(e) => {
                    handleInputChange(e, this, false, () => {
                      this.getCommunityImpactData();
                    });
                  }}
                  name="endMonth"
                  options={this.state.months}
                />
                <Dropdown
                  value={this.state.endYear}
                  onChange={(e) => {
                    handleInputChange(e, this, false, () => {
                      this.getCommunityImpactData();
                    });
                  }}
                  name="endYear"
                  options={this.state.years}
                  notranslate="yes"
                />
              </div>
            </div>
          </div>
          <div className="data-wrap">
            <div className="charts">
              <div className="chart-wrap">
                <div className="chart-title">DONATION TRENDS</div>
                <div className="chart">
                  {this.state.donationTrends !== null && (
                    <Line
                      ref={this.state.chartRef}
                      data={this.state.donationTrends}
                      options={this.state.chartOptions}
                    />
                  )}
                </div>
              </div>
              <div className="chart-wrap">
                <div className="chart-title">FUNDRAISER TRENDS</div>
                <div className="chart">
                  {this.state.fundraiseTrends !== null && (
                    <Line
                      ref={this.state.chartRef}
                      data={this.state.fundraiseTrends}
                      options={this.state.chartOptions}
                    />
                  )}
                </div>
              </div>
              <div className="chart-wrap">
                <div className="chart-title">VOLUNTEER HOUR TRENDS</div>
                <div className="chart">
                  {this.state.volunteerTrends !== null && (
                    <Line
                      ref={this.state.chartRef}
                      data={this.state.volunteerTrends}
                      options={this.state.chartOptions}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="metrics">
              <div className="metric">
                <div
                  className="number"
                  notranslate="yes"
                >
                  {localizeHelpers.formatNumber(this.state.membersCount, this.props.locale)}
                </div>
                <div className="label">MEMBERS</div>
              </div>
              <div className="metric">
                <div
                  className="number"
                  notranslate="yes"
                >
                  {localizeHelpers.formatNumber(this.state.volunteersCount, this.props.locale)}
                </div>
                <div className="label">VOLUNTEERS</div>
              </div>
              <div className="metric">
                <div
                  className="number"
                  notranslate="yes"
                >
                  {localizeHelpers.formatNumber(this.state.donorsCount, this.props.locale)}
                </div>
                <div className="label">DONORS</div>
              </div>
              <div className="metric">
                <div
                  className="number"
                  notranslate="yes"
                >
                  {localizeHelpers.formatNumber(this.state.fundraisersCount, this.props.locale)}
                </div>
                <div className="label">FUNDRAISERS</div>
              </div>
              <div className="metric">
                <div
                  className="number"
                  notranslate="yes"
                >
                  {localizeHelpers.formatNumber(this.state.totalVolunteerHours, this.props.locale)}
                </div>
                <div className="label">VOLUNTEER HOURS</div>
              </div>
              <div className="metric">
                <div
                  className="number"
                  notranslate="yes"
                >
                  {formatCurrency(this.state.totalDonatedAmount, currency, this.props.locale)}
                </div>
                <div className="label">TOTAL DONATED</div>
              </div>
              <div className="metric">
                <div
                  className="number"
                  notranslate="yes"
                >
                  {formatCurrency(this.state.totalFundraisedAmount, currency, this.props.locale)}
                </div>
                <div className="label">TOTAL FUNDRAISED</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default withRouter(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(CommunityImpact)),
);
