import { Constants } from '@gigit/constants';
import {
  IAvailablePremiumFeature,
  IHub,
  IOrgPremiumFeaturesSummary,
  IRole,
} from '@gigit/interfaces';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { uiConstants } from '../../../../constants';
import {
  combineClassNames,
  defaultCurrency,
  formatCurrency,
  routes,
  typeHelpers,
} from '../../../../helpers';
import { useLocale } from '../../../../hooks';
import useToastDispatcher from '../../../../hooks/useToaster';
import { IOwnerObject } from '../../../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { billingRequestActions } from '../../../../requestActions';
import { userSelectors } from '../../../../selectors/user';
import { IAppState } from '../../../../store';
import Button from '../../../Button/Button';
import { Prompt } from '../../../Prompt/Prompt';
import { usePaymnetInfo } from '../../../shared/Onboarding/hooks/usePaymentInfo';
import { PaymentComponent } from '../../../shared/PaymentComponent/PaymentComponent';
import HubInviteMembers from '../../HubModals/HubInviteMembers/HubInviteMembers';
import HubPaymentModal from '../../HubModals/HubPaymentModal/HubPaymentModal';
import HubManagementSeats from '../HubManagementSeats/HubManagementSeats';
import HubSeatPurchaseModal from '../HubManagementSeats/HubSeatPurchaseModal/HubSeatPurchaseModal';
import './HubManagementBilling.scss';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Config } from '@gigit/config';

interface IProps {
  hub?: IHub;
  hubRoles: IRole[];
  onSubscriptionChange: () => void;
}

interface ITab {
  label: string;
  value: string;
}

const tabs: ITab[] = [
  {
    label: 'Seats & Invoices',
    value: 'seats-invoices',
  },
  {
    label: 'Subscriptions',
    value: 'subscriptions',
  },
  {
    label: 'Payment Method',
    value: 'payment-method',
  },
];

const SHARED_PLAN = Constants.billing.subscriptions.share_package.code;
const DO_YEARLY = Constants.billing.subscriptions.hub_seating_do.code;
const HUB_SEATING_GIVE = Constants.billing.subscriptions.hub_seating.code;

const HubManagementBilling: React.FC<IProps> = (props: IProps) => {
  const local = useLocale();

  const location = useLocation();
  const history = useHistory();

  const { dispatchToastError, dispatchToastSuccess, dispatchToastInfo } = useToastDispatcher();

  const [owner, setOwner] = useState<IOwnerObject>();
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const [availableSubscriptions, setAvailableSubscriptions] = useState<
    IAvailablePremiumFeature[] | null
  >();

  const [selectedTab, setSelectedTab] = useState<string>('seats-invoices');
  const [isVirtual, setIsVirtual] = useState<boolean>(false);
  const [showPaymentMethods, setShowPaymentMethods] = useState<boolean>(false);
  const { paymentInfo, handlePaymentInfo } = usePaymnetInfo();
  const [triggerShowExitingMethods, setTriggerShowExitingMethods] = useState<boolean>(false);
  const [triggerPaymentPrep, setTriggerPaymentPrep] = useState<boolean>(false);
  const [disablePaymentMethodSave, setDisablePaymentMethodSave] = useState<boolean>(true);
  const [subscriptionSummary, setSubscriptionSummary] = useState<IOrgPremiumFeaturesSummary>();
  const [showAfterSavePayment, setShowAfterSavePayment] = useState<'invite' | 'seats' | null>(null);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<boolean>(false);
  const [seatPricing, setSeatPricing] = useState<IAvailablePremiumFeature | null>(null);
  const [packagesInfo, setPackagesInfo] = useState<IAvailablePremiumFeature[]>([]);
  const [seatsUsed, setSeatsUsed] = useState<number>(0);
  const [totalSeats, setTotalSeats] = useState<number>(0);
  const [invoiceAmount, setInvoiceAmount] = useState<number>(0);
  const [showPurchaseSeats, setShowPurchaseSeats] = useState<boolean>(false);
  const [invoiceCurrency, setInvoiceCurrency] = useState<string>(defaultCurrency);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(null);
  const [cardLast4Digits, setCardLast4Digits] = useState<string>('');
  const [cardName, setCardName] = useState<string>('');
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<string>('');
  const [togglePaymentButtonLoading, setTogglePaymentButtonLoading] = useState<boolean>(false);

  const [showUpgradeFromShared, setShowUpgradeFromShared] = useState<boolean>(false);
  const [selectedPlanChange, setSelectedPlanChange] = useState<string>('');
  const [isSharedUpgrade, setIsSharedUpgrade] = useState<boolean>(false);
  const [isSubscription, setIsSubscription] = useState<boolean>(false);

  const [hubSeatsAvailable, setHubSeatsAvailable] = useState<number>(0);

  const [currentPackage, setCurrentPackage] = useState<string>('');

  const [showPurchaseModal, setShowPurchaseModal] = useState<boolean>(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState<boolean>(false);
  const [currentPremiumFeatureSummary, setCurrentPremiumFeatureSummary] =
    useState<IOrgPremiumFeaturesSummary>();

  const [showFreeDowngradePrompt, setShowFreeDowngradePrompt] = useState<boolean>(false);

  const hubSeatFeature = subscriptionSummary?.features?.find(
    (feature) => feature.feature_code === HUB_SEATING_GIVE,
  );

  const trialFeature = subscriptionSummary?.features.find((feature) => feature.is_trial);

  useEffect(() => {
    const _searchParams = queryString.parse(location.search);
    if (_searchParams.st) {
      checkSubTabRedirect();
    }
  }, [location]);

  useEffect(() => {
    setPrices();
    if (props?.hub?.id) {
      let ownerObj = typeHelpers.createOwnerObject('hub', props.hub);
      setOwner(ownerObj);
      setMinSeatCounter(props.hub.id);
    }
  }, [props.hub]);

  useEffect(() => {
    if (owner) {
      checkForAddPaymentMethodParam();
    }

    if (location.search && subscriptionSummary) {
      const queryParams = new URLSearchParams(location.search);
      const shouldOpenPurchaseModal = queryParams.get('purchaseModal');
      const shouldOpenInvitesModal = queryParams.get('invitesModal');

      if (shouldOpenPurchaseModal) {
        attemptOpenPurchaseSeatsModal();
        history.replace({ search: '' });
      }

      if (shouldOpenInvitesModal) {
        attemptOpenInviteMembersModal();
        history.replace({ search: '' });
      }
    }
  }, [location.search, owner, subscriptionSummary]);

  function checkSubTabRedirect() {
    const _searchParams = queryString.parse(location.search);
    const targetTab = tabs.find((tab) => tab.value == _searchParams.st);
    if (targetTab) {
      setSelectedTab(targetTab.value);
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('st');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }

  function checkForAddPaymentMethodParam() {
    const _searchParams = queryString.parse(location.search);
    if (_searchParams?.modal && _searchParams.modal === 'addPaymentMethod') {
      setShowPaymentMethodModal(true);
      history.replace({ search: '' });
    }
  }

  const setPrices = async () => {
    if (props.hub?.id) {
      let prices = await billingRequestActions.getAvailableSubscriptions(
        Constants.billing.subscriptions.hub_seating.object_type,
      );
      setPackagesInfo(prices);
      let info = await billingRequestActions.getPremiumFeatureSummary(
        uiConstants.ownerType.hub,
        props.hub.id,
      );
      setCurrentPremiumFeatureSummary(info);
      let givePackage = info.features.find((feature) => feature.feature_code === HUB_SEATING_GIVE);
      let doPackage = info.features.find((feature) => feature.feature_code === DO_YEARLY);
      let sharePackage = info.features.find((feature) => feature.feature_code === SHARED_PLAN);

      if (givePackage) {
        setCurrentPackage(givePackage.feature_code);
      } else if (doPackage) {
        setCurrentPackage(doPackage.feature_code);
      } else if (sharePackage) {
        setCurrentPackage(sharePackage.feature_code);
      }
    }
  };

  const renderTabOption = (tab: ITab) => {
    const isSelected = selectedTab === tab.value;

    return (
      <div
        className={combineClassNames('tab', isSelected ? 'selected' : '')}
        key={tab.value}
        onClick={() => setSelectedTab(tab.value)}
      >
        {tab.label}
      </div>
    );
  };

  const renderSeats = () => {
    return (
      <>
        <HubManagementSeats
          hubRoles={props.hubRoles}
          hub={props.hub}
          key="seats"
        />
      </>
    );
  };

  async function handlePaymentMethodId(id: string) {
    if (id) {
      try {
        setDisablePaymentMethodSave(false);
        if (paymentInfo.isNewMethod) {
          let payload = {
            payment_method_id: id,
          };

          const response = await axios.post(routes.GET_PAYMENT_METHODS, payload);
          let confirm = await paymentInfo.stripe?.confirmCardSetup(response.data.client_secret);
          if (confirm && confirm.error) {
            dispatchToastError(localizeHelpers.translate(confirm.error.message), 'Payment Method');
            return;
          }
        }

        const premiumFeaturesSummary = await billingRequestActions.updateHubSubscription(
          props.hub?.id!,
          currentPackage,
          { payment_method_id: id },
        );
        setSubscriptionSummary(premiumFeaturesSummary);

        setShowPaymentMethods(false);

        dispatchToastSuccess('Payment method changed successfully.', 'Payment Method');
      } catch (error) {
        if (currentPackage === SHARED_PLAN) {
          dispatchToastInfo(
            'You must upgrade to a paid package to set or update payment method',
            'Update Payment Method',
            [
              {
                link: {
                  link: `/company/${props.hub?.handle}/admin?t=billing&st=subscriptions`,
                  text: 'Subscriptions',
                },
              },
            ],
          );
        } else {
          dispatchToastError(error, 'Payment Method');
        }
      }
    }
  }

  function handlePlanChange(planChange: string, skipPrompt?: boolean) {
    if (planChange !== currentPackage && planChange === SHARED_PLAN) {
      setShowFreeDowngradePrompt(true);
    }
    if (planChange !== currentPackage) {
      if (currentPackage === SHARED_PLAN && !skipPrompt) {
        setSelectedPlanChange(planChange);
        setShowUpgradeFromShared(true);
      } else if (currentPackage === SHARED_PLAN && skipPrompt) {
        let selectedPricing = packagesInfo.find(
          (subPackage) => subPackage.product_code === planChange,
        );
        if (selectedPricing) {
          setSeatPricing(selectedPricing);
        }
        setIsSubscription(false);
        setIsSharedUpgrade(true);
        setShowPurchaseModal(true);
      }

      if (currentPackage == DO_YEARLY && planChange === HUB_SEATING_GIVE) {
        setSelectedPlanChange(planChange);
        let selectedPricing = packagesInfo.find(
          (subPackage) => subPackage.product_code === planChange,
        );
        if (selectedPricing) {
          setSeatPricing(selectedPricing);
        }
        setIsSubscription(true);
        setIsSharedUpgrade(false);
        setShowPurchaseModal(true);
      }

      if (Config.feature_flags.HUB_SEATING_DO) {
        if (currentPackage == HUB_SEATING_GIVE && planChange === DO_YEARLY) {
          let selectedPricing = packagesInfo.find(
            (subPackage) => subPackage.product_code === planChange,
          );
          if (selectedPricing) {
            setSeatPricing(selectedPricing);
          }
          setShowDowngradeModal(true);
        }
      }
    }
  }

  const renderSubscriptions = () => {
    return (
      <>
        <div className="subscriptions-wrapper">
          <h3 className="section-title">Subscription Package</h3>
          <div className="edit-content-container">
            <div className="section-subtitle">
              <h4>Select a Subscription Package</h4>
              <span>
                Select a subscription package that best fits the needs of your organization.
              </span>
            </div>
          </div>
          <div className="subscription-cards">
            <div className="sub-card">
              <div className="card-title">Share Package</div>
              <div className="card-subtitle">Social Sharing</div>

              <div className="price">$0</div>
              <div className="price-sub">/ Always free</div>

              <div className="benefits">
                <div>
                  <i className="fal fa-check-circle"></i>Custom branded company Page
                </div>
                <div>
                  <i className="fal fa-check-circle"></i>Share your mission & Values
                </div>
                <div>
                  <i className="fal fa-check-circle"></i>Share your Social Impact Stories
                </div>
              </div>
              {currentPackage === SHARED_PLAN ? (
                <Button
                  isDisabled={true}
                  className="sub-card-button"
                >
                  Current Plan
                </Button>
              ) : (
                <Button
                  onClick={() => handlePlanChange(SHARED_PLAN)}
                  className="sub-card-button"
                >
                  Downgrade Plan
                </Button>
              )}
            </div>
            {Config.feature_flags.HUB_SEATING_DO && (
              <div className="sub-card">
                <div className="card-title">Do Package</div>
                <div className="card-subtitle">Corporate Sharing</div>

                <div className="price">$2</div>
                <div className="price-sub">/ a user per month</div>

                <div className="benefits">
                  <div>
                    <i className="fal fa-check-circle"></i>Thousands of volunteer opportunities
                  </div>
                  <div>
                    <i className="fal fa-check-circle"></i>Create volunteer events
                  </div>
                  <div>
                    <i className="fal fa-check-circle"></i>Measure employee participation
                  </div>
                </div>
                {currentPackage === DO_YEARLY ? (
                  <Button
                    isDisabled={true}
                    className="sub-card-button"
                  >
                    Current Plan
                  </Button>
                ) : currentPackage === SHARED_PLAN ? (
                  <Button
                    onClick={() => handlePlanChange(DO_YEARLY)}
                    className="sub-card-button"
                  >
                    Upgrade Plan
                  </Button>
                ) : (
                  <Button
                    onClick={() => handlePlanChange(DO_YEARLY)}
                    className="sub-card-button"
                  >
                    Downgrade Plan
                  </Button>
                )}
              </div>
            )}
            <div className="sub-card">
              <div className="card-title">Give Package Package</div>
              <div className="card-subtitle">Events & Donations</div>

              <div className="price">$5</div>
              <div className="price-sub">/ a user per month</div>

              <div className="benefits">
                <div>
                  <i className="fal fa-check-circle"></i>Everything in the Do Package
                </div>
                <div>
                  <i className="fal fa-check-circle"></i>Donation & Volunteer matching
                </div>
                <div>
                  <i className="fal fa-check-circle"></i>Custom events & fundraisers
                </div>
              </div>
              {currentPackage === HUB_SEATING_GIVE ? (
                <Button
                  isDisabled={true}
                  className="sub-card-button"
                >
                  Current Plan
                </Button>
              ) : (
                <Button
                  onClick={() => handlePlanChange(HUB_SEATING_GIVE)}
                  className="sub-card-button"
                >
                  Upgrade Plan
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderPaymentMethod = () => {
    return (
      <>
        {!isVirtual && (
          <>
            <h3 className="section-title">Payment Method</h3>
            <div
              onClick={() => setShowPaymentMethods(!showPaymentMethods)}
              className={`edit-content-container ${!showPaymentMethods ? 'collapsed' : ''}`}
            >
              <div className="section-subtitle">
                <h4>Update Payment Method</h4>
                <span>
                  Select your method of payment that will be billed annually for seats attached to
                  your company.
                </span>
              </div>
            </div>
            {showPaymentMethods && (
              <>
                <div className="payment-method-container">
                  <PaymentComponent
                    title=""
                    notCreationFlow={true}
                    priceDetails={[]}
                    showBillingForm={true}
                    selectDefaultMethod={true}
                    paymentInfo={paymentInfo}
                    showExistingMethods={triggerShowExitingMethods}
                    preparePaymentMethod={triggerPaymentPrep}
                    onPaymentInfoUpdated={handlePaymentInfo}
                    onPaymentMethodReady={handlePaymentMethodId}
                    onMethodChange={() => setDisablePaymentMethodSave(false)}
                    defaultPaymentMethodId={
                      hubSeatFeature?.payment_method_details?.payment_method_id
                    }
                  />
                </div>
                <div className="payment-action">
                  <Button
                    isDisabled={disablePaymentMethodSave}
                    onClick={() => setTriggerPaymentPrep(!triggerPaymentPrep)}
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  };

  function attemptOpenPurchaseSeatsModal() {
    let selectedPricing = packagesInfo.find(
      (subPackage) => subPackage.product_code === currentPackage,
    );
    if (selectedPricing?.product_code == SHARED_PLAN) {
      dispatchToastInfo('You must upgrade to a paid package to purchase seats', 'Purchase Seats', [
        {
          link: {
            link: `/company/${props.hub?.handle}/admin?t=billing&st=subscriptions`,
            text: 'Subscriptions',
          },
        },
      ]);

      return;
    }
    if (selectedPricing) {
      setSeatPricing(selectedPricing);
    }
    if (trialFeature) {
      setShowAfterSavePayment('seats');
      setShowPaymentMethodModal(true);
    } else {
      setShowPurchaseSeats(true);
    }
  }

  function attemptOpenInviteMembersModal() {
    if (trialFeature && trialFeature.seats.used >= trialFeature.seats.total) {
      setShowAfterSavePayment('invite');
      // setShowWarningTrialModal(true)
    } else {
      if (
        hubSeatFeature?.payment_method_details?.payment_method_id ||
        subscriptionSummary ||
        trialFeature
      ) {
        setShowInviteModal(true);
      } else {
        dispatchToastError(
          localizeHelpers.translate(
            "Your hub doesn't have a default payment method setup.  Before you can invite additional members you must have a valid payment method. ",
          ),
          'Invite Members',
          [
            {
              text: 'Click here to add a payment method',
              onClick: () => setShowPaymentMethods(true),
            },
          ],
        );
      }
    }
  }

  function getPaymentDescription() {
    if (trialFeature && trialFeature?.trial_end_date) {
      const price =
        (Constants.billing.subscriptions.hub_seating.price_in_cents / 100) *
        12 *
        trialFeature.seats.payed;
      const endTrialDate = localizeHelpers.formatDate(
        trialFeature.trial_end_date,
        LocaleDateFormats.LL,
        local.currentLocale,
      );
      const renewDate = localizeHelpers.formatDate(
        new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        LocaleDateFormats.LL,
        local.currentLocale,
      );

      if (new Date(trialFeature.trial_end_date).getTime() > new Date().getTime()) {
        return `<p>You are attempting to update your company payment method.</p>

                        <p>Your trial is currently set to end on <b>${endTrialDate}</b>. If you upgrade now, you will be charged <b>${formatCurrency(price, trialFeature.currency || defaultCurrency, locale)}</b> today for the <b>${trialFeature.seats.payed}</b> trial seats already in use and you will be able to start purchasing additional seats.</p>
                        
                        <p>Your new subscription amount will be <b>${formatCurrency(price, trialFeature.currency || defaultCurrency, locale)}</b> and renew on <b>${renewDate}</b></p>
                        
                        <p>Do you wish to continue?</p>`;
      }
      return `<p>You are attempting to update your company payment method.</p>

                    <p>You will be charged <b>${formatCurrency(price, trialFeature.currency || defaultCurrency, locale)}</b> immediately for the <b>${trialFeature.seats.payed}</b> seats that have already been added.</p> 
                    
                    <p>Your new subscription amount will be <b>${formatCurrency(price, trialFeature.currency || defaultCurrency, locale)}</b> and will renew on <b>${renewDate}</b>.</p> 
                    
                    <p>Do you wish to continue?</p>`;
    }

    return '';
  }

  function getDowngradeDescription() {
    const currentFeatureSummary = currentPremiumFeatureSummary?.features.find(
      (feature) => feature.feature_code === currentPackage,
    );
    if (
      seatPricing?.annual_price &&
      currentFeatureSummary?.seats.total &&
      currentFeatureSummary.period_end_date
    ) {
      return `Your new billing amount <b>${formatCurrency(seatPricing?.annual_price * currentFeatureSummary?.seats.total, seatPricing.currency || defaultCurrency, locale)}</b> will take effect when your next billing cycle starts on ${localizeHelpers.formatDate(currentFeatureSummary.period_end_date, LocaleDateFormats.LL, local.currentLocale)}`;
    } else {
      return '';
    }
  }

  function setMinSeatCounter(hubId: string) {
    billingRequestActions
      .getHubSubscriptionSummary(hubId)
      .then((response) => {
        setSubscriptionSummary(response);
        const seatsFeature = response.features.find(
          (feature) => feature.feature_code === HUB_SEATING_GIVE,
        );

        if (seatsFeature && seatsFeature.seats) {
          setHubSeatsAvailable(seatsFeature.seats?.total - seatsFeature.seats?.used);
        }

        const isVirtualSub = response.features.find((feature) => feature.is_virtual);

        setIsVirtual(!!isVirtualSub);

        if (
          seatsFeature?.payment_method_details &&
          seatsFeature?.payment_method_details.payment_method_id
        ) {
          setDefaultPaymentMethodId(seatsFeature?.payment_method_details?.payment_method_id);
          setCardLast4Digits(seatsFeature?.payment_method_details?.credit_card_last_4_digits);
          setCardName(seatsFeature?.payment_method_details?.credit_card_brand);
        }
        setSeatsUsed(seatsFeature?.seats?.used || 0);
        setTotalSeats(seatsFeature?.seats?.total || 0);
        setInvoiceAmount(seatsFeature?.next_invoice_cost || 0);
        setInvoiceDate(seatsFeature?.period_end_date || null);
        setInvoiceCurrency(seatsFeature?.currency || defaultCurrency);
      })
      .catch((err) => {
        dispatchToastError(err, 'Purchase Seats');
      })
      .catch((error) => {
        dispatchToastError(error, 'Subscriptions');
      });
  }

  async function onSaveAddPaymentMethod(payment_method_id: string) {
    if (trialFeature?.seats?.total && owner?.ownerId) {
      const payload = {
        payment_method_id,
      };

      const updated = await billingRequestActions.createHubSeatSubscriptionEndTrial(
        owner.ownerId,
        HUB_SEATING_GIVE,
        payload,
      );
      setSubscriptionSummary(updated.premiumFeatures);

      if (props.hub?.id) {
        setMinSeatCounter(props.hub?.id);
      }

      setShowPaymentMethodModal(false);

      if (showAfterSavePayment === 'invite') {
        setShowInviteModal(true);
      } else if (showAfterSavePayment === 'seats') {
        setShowPurchaseSeats(true);
      }

      setShowAfterSavePayment(null);
    }
  }

  async function downgradeSubscriptionPackage(id: string) {
    if (seatPricing) {
      const premiumFeaturesSummary = await billingRequestActions.updateHubSubscription(
        props.hub?.id!,
        currentPackage,
        { pricing_tier: seatPricing.tier?.toString() },
      );

      setShowDowngradeModal(false);
    }
  }

  function displayPurchaseSuccess(seats: number) {
    dispatchToastSuccess(
      localizeHelpers.translate('{{seats}} seats purchased successfully', {
        seats,
      }),
      'Purchase Seats',
    );
  }

  function refreshFeatureData() {
    setPrices();
    props.onSubscriptionChange();
  }

  return (
    <>
      <div className="HubManagementBilling">
        <div className="header-section">
          <div className="container-header">
            <h2>Billing</h2>
            <div className="header-actions">
              <Button onClick={attemptOpenPurchaseSeatsModal}>Purchase Seats</Button>
              <Button
                className="invite-button"
                onClick={attemptOpenInviteMembersModal}
              >
                + Invite Members
              </Button>
            </div>
          </div>
          <div className="tab-selector">{tabs.map((tab) => renderTabOption(tab))}</div>
          <div className={combineClassNames('tab-content', selectedTab)}>
            {selectedTab === 'seats-invoices' && renderSeats()}

            {selectedTab === 'subscriptions' && renderSubscriptions()}

            {selectedTab === 'payment-method' && renderPaymentMethod()}
          </div>
        </div>
        {owner && (
          <HubPaymentModal
            ownerId={owner?.ownerId || ''}
            showModal={showDowngradeModal}
            description={getDowngradeDescription()}
            title="Downgrade subscription package"
            confirmButton="Save changes"
            onConfirmation={async (paymentMethodId: string) => {
              downgradeSubscriptionPackage(paymentMethodId);
              refreshFeatureData();
              //await onSaveAddPaymentMethod(paymentMethodId);
            }}
            toggleCloseModal={() => setShowDowngradeModal(false)}
            toggleButtonLoading={togglePaymentButtonLoading}
          />
        )}
        {owner && (
          <>
            <HubInviteMembers
              owner={owner}
              showAddMemberModal={showInviteModal}
              toggleShowAddMemberModal={function (newToggleValue: boolean): void {
                setShowInviteModal(!showInviteModal);
              }}
              objectType="hub"
              hubSeatsAvailable={hubSeatsAvailable}
            />
            <HubPaymentModal
              ownerId={owner?.ownerId || ''}
              showModal={showPaymentMethodModal}
              description={getPaymentDescription()}
              title="Add Payment Method"
              confirmButton="Save changes"
              onConfirmation={async (paymentMethodId: string) => {
                await onSaveAddPaymentMethod(paymentMethodId);
              }}
              toggleCloseModal={() => setShowPaymentMethodModal(false)}
              toggleButtonLoading={togglePaymentButtonLoading}
            />
          </>
        )}
        <HubSeatPurchaseModal
          defaultPaymentMethodId={defaultPaymentMethodId}
          onPurchase={(id: string, seats: number) => {
            displayPurchaseSuccess(seats);
            setMinSeatCounter(id);
          }}
          show={showPurchaseSeats}
          currentPackage={currentPackage}
          onClose={() => setShowPurchaseSeats(false)}
          hub={props.hub}
          pricing={seatPricing}
          subscriptions={subscriptionSummary}
        />
        <HubSeatPurchaseModal
          hub={props.hub}
          newSubscriptionPackage={selectedPlanChange}
          currentPackage={currentPackage}
          isSubscription={isSubscription}
          show={showPurchaseModal}
          onClose={() => {
            refreshFeatureData();
            setShowPurchaseModal(false);
          }}
          onPurchase={() => {
            console.log('test');
          }}
          defaultPaymentMethodId=""
          pricing={seatPricing}
        />

        <Prompt
          show={showUpgradeFromShared}
          title="Upgrade Subscription Package"
          message="You will be charged for the minimum of 20 seats for your first order to upgrade your plan, are you sure?"
          yesMessage="Yes"
          yesClass="fa fa-check"
          yesStyle="normal"
          cancelMessage="Cancel"
          onYes={() => {
            handlePlanChange(selectedPlanChange, true);
          }}
          onClose={() => {
            setSelectedPlanChange('');
            setShowUpgradeFromShared(false);
          }}
        />
        <Prompt
          show={showFreeDowngradePrompt}
          title="Downgrade Subscription Package"
          message="Contact support if you want to downgrade from a paid plan to a non-paid plan?"
          link="https://help.kambeo.io/"
          linkText="Kambeo Help Center"
          externalLink={true}
          yesMessage="Ok"
          yesClass="fa fa-check"
          yesStyle="normal"
          cancelMessage="Close"
          onYes={() => {
            setShowFreeDowngradePrompt(false);
          }}
          onClose={() => {
            setShowFreeDowngradePrompt(false);
          }}
        />
      </div>
    </>
  );
};

export default HubManagementBilling;
