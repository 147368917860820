import { Reducer } from 'redux';
import { RegisterActions, RegisterActionTypes } from '../actions/register';
import { IAppError } from '../interfaces';

export interface IFlowStateConfig {
  group: any;
  event: any;
  gig: any;
  type: string;
  currentStep: string;
  groupType: string;
  eventType: string;
  isCharity: boolean;
  isEvent: boolean;
  isComplete: boolean;
  fundraiser: any;
}

export interface IRegisterState {
  success: boolean;
  error: IAppError | null;
  handleError: IAppError | null;
  isRegisterLoading: boolean;
  isHandleLoading: boolean;
  groupClassifications: any;
  flowConfig: IFlowStateConfig;
  entityType: string;
}

export const initialRegisterState: IRegisterState = {
  success: false,
  error: null,
  handleError: null,
  isRegisterLoading: false,
  isHandleLoading: false,
  groupClassifications: [],
  entityType: '',
  flowConfig: {
    group: null,
    event: null,
    gig: null,
    type: '',
    currentStep: '',
    groupType: '',
    eventType: '',
    isCharity: false,
    isEvent: false,
    isComplete: false,
    fundraiser: null,
  },
};

export const registerReducer: Reducer<IRegisterState, RegisterActions> = (
  state = initialRegisterState,
  action,
) => {
  switch (action.type) {
    case RegisterActionTypes.REGISTER_USER: {
      return {
        ...state,
        ...action,
      };
    }
    case RegisterActionTypes.SET_FLOW: {
      return {
        ...state,
        ...action,
      };
    }
    case RegisterActionTypes.GET_GROUP_CLASSIFICATIONS: {
      return {
        ...state,
        ...action,
      };
    }
    case RegisterActionTypes.RESET_FLOW_STATE: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
