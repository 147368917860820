import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../TextField/TextField';
import { handleInputChange } from '../../../../helpers';
import { IAppState } from '../../../../store';
import { getUserInfo } from '../../../../actions/user';
import { IUserState } from '../../../../reducers/user';

interface IProps {
  userState: IUserState;
  flow: string;
  user: any;

  updateUserInfo(key: string, val: string): void;
  getUserInfo(id: string): void;
}

interface IState {
  fullName: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
}

class VolunteerInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      fullName: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    };

    this.updateEmail = this.updateEmail.bind(this);
    this.updatePhone = this.updatePhone.bind(this);
    this.getFirstAndLastName = this.getFirstAndLastName.bind(this);
  }

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.userState.user !== prevProps.userState.user) {
      this.setState({
        fullName: `${this.props.user.first_name} ${this.props.user.last_name}`,
        email: this.props.userState.user.email,
        phone: this.props.user.phone
          ? this.props.user.phone
          : this.props.userState.user.phone || '',
        firstName: this.props.userState.user.first_name,
        lastName: this.props.userState.user.last_name,
      });
    }
  }

  initializeData() {
    if (this.props.userState.user?.id) {
      this.props.getUserInfo(this.props.userState.user.id);
    }
    if (this.props.userState.user.email) {
      this.setState({
        fullName: `${this.props.user.first_name} ${this.props.user.last_name}`,
        email: this.props.userState.user.email,
        phone: this.props.user.phone
          ? this.props.user.phone
          : this.props.userState.user.phone || '',
        firstName: this.props.userState.user.first_name,
        lastName: this.props.userState.user.last_name,
      });
    }
  }

  updateEmail(val: string) {
    this.props.updateUserInfo('email', val);
  }

  updatePhone(val: string) {
    this.props.updateUserInfo('phone', val);
  }

  getFirstAndLastName() {
    let names = this.parseName();
    if (names) {
      this.setState(
        {
          firstName: names.first_name,
          lastName: names.last_name,
        },
        () => {
          if (names) {
            this.props.updateUserInfo('first_name', this.state.firstName);
            this.props.updateUserInfo('last_name', this.state.lastName);
          }
        },
      );
    }
  }

  parseName() {
    let names = this.state.fullName.split(' ');
    if (names.length === 2) {
      return {
        first_name: names[0],
        last_name: names[1],
      };
    } else if (names.length > 2) {
      let last = names.slice(1).join('');
      return {
        first_name: names[0],
        last_name: last,
      };
    } else {
      return false;
    }
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow}`}>
        <h2>Let's get your volunteer profile ready to go!</h2>
        <div className="details-row">
          <TextField
            value={this.state.fullName}
            name="fullName"
            label="Full Name"
            type="text"
            onChange={(e: any) => {
              handleInputChange(e, this, false, this.getFirstAndLastName);
            }}
          />
        </div>
        <div className="details-row">
          <TextField
            disabled={this.props.user.email !== ''}
            required={true}
            label="Email"
            value={this.state.email}
            name="email"
            type="email"
            onChange={(e: any) => {
              handleInputChange(e, this, false, this.updateEmail, true);
            }}
          />
        </div>
        <div className="details-row">
          <TextField
            label="Phone Number (ex. 123-456-7890)"
            required
            value={this.state.phone}
            type="tel"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            name="phone"
            onChange={(e: any) => {
              handleInputChange(e, this, false, this.updatePhone, true);
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

const mapDispatchToProps = {
  getUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(VolunteerInfo);
