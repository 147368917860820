import React from 'react';
import Dropdown from '../../../Dropdown/Dropdown';
import { handleInputChange } from '../../../../helpers';
import {
  SearchableDropdown,
  ISearchableDropdownListItem,
} from '../../../SearchableDropdown/SearchableDropdown';

export interface IOptions {
  label: string;
  value: string;
}

interface IProps {
  flow: string;
  monetizedGroups: Array<any>;
  selectedGroup: string;
  groupType: string;
  groupFundraisers: Array<ISearchableDropdownListItem>;

  hasMonetizedGroups(): boolean;
  updateEntityType(type: string, value: string): void;
  setExistingGroup(id: string): void;
  resetGroup(): void;
  updateIsOnBehalfOf(val: boolean): void;
}

interface IState {
  showGroupList: boolean;
  groupType: string;
  selectedGroup: any;
  showFundraiserGroupList: boolean;
}

class GroupSelection extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showGroupList: false,
      groupType: '',
      selectedGroup: null,
      showFundraiserGroupList: false,
    };

    this.handleGroupType = this.handleGroupType.bind(this);
  }

  componentDidMount() {
    if (this.props.selectedGroup) {
      this.setState({
        selectedGroup: this.props.selectedGroup,
      });
    }

    if (this.props.groupType) {
      this.setState({
        groupType: this.props.groupType,
      });
    }
  }

  renderHeaderTxt() {
    switch (this.props.flow) {
      case 'donations': {
        return 'What cause would you like to accept donations?';
      }
      case 'createStore': {
        return 'What cause is this store for?';
      }
      case 'createEvent': {
        return 'What cause is this event for?';
      }
      case 'createGig': {
        return 'What cause is this volunteer opportunity for?';
      }
      case 'createAuction': {
        return 'What cause is this auction for?';
      }
      case 'createGroup': {
        return 'Create Cause';
      }
      default: {
        return '';
      }
    }
  }

  handleGroupType() {
    this.props.resetGroup();
    if (this.state.groupType !== 'fundraiser') {
      this.props.updateEntityType('groupType', this.state.groupType);
      this.props.updateIsOnBehalfOf(false);
    } else {
      this.props.updateEntityType('groupType', 'existing');
      this.props.updateIsOnBehalfOf(true);
    }
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} group-selection`}>
        <h2>{this.renderHeaderTxt()}</h2>
        <ul>
          {this.props.hasMonetizedGroups() && (
            <li
              onClick={() =>
                this.setState(
                  {
                    showGroupList: true,
                    showFundraiserGroupList: false,
                    groupType: 'existing',
                    selectedGroup: '',
                  },
                  () => this.handleGroupType(),
                )
              }
            >
              <span
                className={
                  this.state.groupType === 'existing' ? 'list-circle active' : 'list-circle'
                }
              >
                <i className="fal fa-users" />
              </span>
              Existing Cause
            </li>
          )}
          {this.props.flow === 'createEvent' && this.props.groupFundraisers.length > 0 && (
            <li
              onClick={() =>
                this.setState(
                  {
                    showFundraiserGroupList: true,
                    groupType: 'fundraiser',
                    selectedGroup: '',
                    showGroupList: false,
                  },
                  () => this.handleGroupType(),
                )
              }
            >
              <span
                className={
                  this.state.groupType === 'fundraiser' ? 'list-circle active' : 'list-circle'
                }
              >
                <i className="fal fa-users" />
              </span>
              On Behalf Of Cause
            </li>
          )}
          <li
            onClick={() => {
              this.setState(
                { showGroupList: false, showFundraiserGroupList: false, groupType: 'new' },
                () => this.handleGroupType(),
              );
              this.props.resetGroup();
            }}
          >
            <span className={this.state.groupType === 'new' ? 'list-circle active' : 'list-circle'}>
              <i className="fa fa-plus" />
            </span>
            Create New Cause
          </li>
        </ul>

        {this.state.showGroupList && (
          <Dropdown
            name="selectedGroup"
            label="Select an existing cause"
            shouldSort={true}
            value={this.state.selectedGroup}
            options={this.props.monetizedGroups}
            onChange={(e) => {
              handleInputChange(e, this, false, this.props.setExistingGroup, true);
            }}
            notranslate="yes"
          />
        )}
        {this.state.showFundraiserGroupList && (
          <div className="details-row">
            <SearchableDropdown
              label="What cause is this event or fundraiser for?"
              list={this.props.groupFundraisers}
              onSearch={(newSearch) => () => {}}
              selectedItem={this.state.selectedGroup}
              setSelectedItem={(item) =>
                this.setState({ selectedGroup: item }, () =>
                  item?.id ? this.props.setExistingGroup(item.id) : false,
                )
              }
              notranslate="yes"
            />
          </div>
        )}
      </div>
    );
  }
}

export default GroupSelection;
