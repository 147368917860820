import React from 'react';

import './PresenceIndicator.scss';

interface IProps {
  isOnline: boolean;
}

export class PresenceIndicator extends React.Component<IProps> {
  render() {
    return (
      <span
        className={this.props.isOnline ? 'PresenceIndicator online' : 'PresenceIndicator offline'}
      ></span>
    );
  }
}
