import React from 'react';
import { useState } from 'react';
import { ReactNode } from 'react';
import Slider, { ResponsiveObject, Settings as SliderSettings } from 'react-slick'; //https://www.npmjs.com/package/react-slick

import './Carousel.scss';

interface IProps {
  slides: ReactNode[];
  title?: string;
  autoplay?: boolean;
  autoplaySpeed?: number;
  infinite?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  speed?: number;
  showArrows?: boolean;
  showDots?: boolean;
  customDotColor?: string;
  nextArrow?: JSX.Element;
  prevArrow?: JSX.Element;
  /** Show's image preview at the bottom instead of dots */
  showImagePagination?: boolean;
  /** Disables specific amount of cards per slide */
  variableWidth?: boolean;
  /** Determines what responsive layout to use for the carousel */
  slideWidth?: widths;
}

type widths = '270';
type ResponsiveSettingByWidth = {
  [width in widths]: ResponsiveObject[];
};

const responsiveSlideSettingsByWidth: ResponsiveSettingByWidth = {
  '270': [
    {
      breakpoint: 1140,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 930,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Carousel(props: IProps) {
  let settings: SliderSettings = {
    nextArrow: props.nextArrow,
    prevArrow: props.prevArrow,
    infinite: props.infinite || false,
    speed: props.speed || 300,
    slidesToShow: props?.slidesToShow || 3,
    slidesToScroll: props?.slidesToScroll || 3,
    adaptiveHeight: true,
    arrows: props.showArrows || false,
    dots: props.showDots || true,
    beforeChange: (currentSlide, nextSlide) => {
      if (nextSlide > currentSlide) {
        setActivePageIndex(activePageIndex + 1);
      } else {
        setActivePageIndex(activePageIndex - 1);
      }
    },
    appendDots: (dots) => <ul className="slick-dots">{dots}</ul>,
    autoplay: props.autoplay || false,
    autoplaySpeed: props.autoplay ? props.autoplaySpeed || 10000 : undefined,
    variableWidth: props.variableWidth || false,
  };
  const [activePageIndex, setActivePageIndex] = useState<number>(0);

  if (props.customDotColor) {
    settings.customPaging = (index) => {
      const customActiveDotColor = { backgroundColor: props.customDotColor };
      const normalDotColor = { backgroundColor: '#E5E5E5' };
      const showCustomDotColor = index === activePageIndex;

      return (
        <button
          key={index}
          style={showCustomDotColor ? customActiveDotColor : normalDotColor}
        />
      );
    };
  }

  if (props.showImagePagination) {
    settings.customPaging = (index) => {
      return <div className="pagination-image-container">{props.slides[index]}</div>;
    };
  }

  if (props.slideWidth) {
    settings.responsive = responsiveSlideSettingsByWidth[props.slideWidth];
  }

  function getPaginationClassNames() {
    const { showImagePagination, showDots } = props;

    if (showImagePagination) {
      return 'use-image-tabs';
    } else if (showDots) {
      return 'use-dot-tabs';
    }

    return '';
  }

  return (
    <div className="Carousel">
      {props.title && <div className="carousel-title">{props.title}</div>}

      <div className={`carousel-container ${getPaginationClassNames()}`}>
        <Slider {...settings}>{props.slides.map((slide) => slide)}</Slider>
      </div>
    </div>
  );
}

export default Carousel;
