import { IChildComponent } from '../ForOrganizations';
import HostEvent from '../../../assets/for_organizations_host_event.png';
import ManageDonations from '../../../assets/for_organizations_manage_donations.png';
import Raise from '../../../assets/for_organizations_raise_funds_for_school_events.png';
import ManageVolunteers from '../../../assets/for_organizations_manage_volunteers.png';
import SellMerchandise from '../../../assets/for_organizations_sell_merchandise.png';
import Main from '../../../assets/for_organizations_universities_and_colleged_main.png';
import MainMobile from '../../../assets/for_organizations_universities_main_mobile.png';

// TODO: This file should be 4 tab spaces.
export const UniversitiesAndColleges = {
  keyValue: 'schools',
  menu_title: 'Universities & Colleges',
  main_title: 'Kambeo for Universities & Colleges',
  main_subtitle: 'Encourage community involvement & earn recognition',
  main_image: {
    src: Main,
  },
  main_image_mobile: {
    src: MainMobile,
    alt: 'Main image businesses',
  },
  cards: [
    {
      image: {
        src: HostEvent,
      },
      link: '/onboarding/event',
      title: 'Host Events',
    },
    {
      image: {
        src: ManageDonations,
      },
      link: '/setup/donations',
      title: 'Manage Donations',
    },
    {
      image: {
        src: Raise,
      },
      link: '/onboarding/event?type=crowdfunding',
      title: 'Raise Funds for School Events',
    },
    {
      image: {
        src: ManageVolunteers,
      },
      link: '/onboarding/gig',
      title: 'Manage Volunteers',
    },
    {
      image: {
        src: SellMerchandise,
      },
      link: '/onboarding/group',
      title: 'Sell Merchandise',
    },
  ],
  collapseItems: [
    {
      title: 'Request a Demo',
      description: 'Kambeo helps organizations raise funds from their community.',
      additionalLink: {
        href: '/book-a-demo/',
        title: 'Contact Us',
        linkNewTab: true,
      },
    },
    {
      title: 'Reach more people!',
      description:
        'With Kambeo’s online tools, you can easily create and share a number of different events to your current marketing channels to help raise awareness and money for your causes.',
    },
    {
      title: 'Save your time!',
      description:
        'By having one centralized hub to create fundraisers, run an auction, manage volunteers, and collect donations, Kambeo eliminates the need of juggling multiple software.',
    },
    {
      title: 'Eliminate your risk!',
      description:
        'Completely free to get started with no monthly fee, Kambeo lets you create what you need immediately so you can start raising awareness and money for the causes that matter to you.',
    },
  ],
} as IChildComponent;
