import React from 'react';
import './BannerLayoutSettings.scss';

interface IProps {
  bannerLayout: string;
  setBannerLayout(v: string): void;
}

const BannerLayoutSettings: React.FC<IProps> = (props) => {
  return (
    <div className="BannerLayoutSettings">
      <div className={`banner-layout-container ${props.bannerLayout}`}>
        <span className="title">Helping Canadians Live Life Well</span>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam. Learn More.
        </p>
        <div className="btn-container">
          <span className="btn">&nbsp;</span>
          <span className="btn">&nbsp;</span>
        </div>
      </div>

      <div className="options-container">
        <span
          className={`option ${props.bannerLayout === 'left' ? 'active' : ''}`}
          onClick={() => props.setBannerLayout('left')}
        >
          <i className="far fa-align-left" />
        </span>
        <span
          className={`option ${props.bannerLayout === 'center' ? 'active' : ''}`}
          onClick={() => props.setBannerLayout('center')}
        >
          <i className="far fa-align-center" />
        </span>
        <span
          className={`option ${props.bannerLayout === 'right' ? 'active' : ''}`}
          onClick={() => props.setBannerLayout('right')}
        >
          <i className="far fa-align-right" />
        </span>
      </div>
    </div>
  );
};

export default BannerLayoutSettings;
