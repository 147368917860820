import React, { useEffect, useState } from 'react';
import './HubManagement.scss';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { IHub, IUserRole, IRole } from '@gigit/interfaces';
import ManagementSidebar, { IMenuSection } from '../../ManagementSidebar/ManagementSidebar';
import { IAppState } from '../../../store';
import { useSelector } from 'react-redux';
import HubManagementSettings from './HubManagementSettings/HubManagementSettings';
import queryString from 'query-string';
import {
  billingRequestActions,
  hubRequestActions,
  roleRequestActions,
} from '../../../requestActions';
import useToastDispatcher from '../../../hooks/useToaster';
import HubManagementBenefitingCauses from './HubManagementBenefitingCauses/HubManagementBenefitingCauses';
import { userSelectors } from '../../../selectors/user';
import { setSEOMetatags, typeHelpers } from '../../../helpers';
import HubManagementSeats from './HubManagementSeats/HubManagementSeats';
import HubManagementMembers from './HubManagementMembers/HubManagementMembers';
import { uiConstants } from '../../../constants';
import HubManagementFocusAreas from './HubManagementFocusAreas/HubManagementFocusAreas';
import { BalanceAndPayoutsManage } from '../../BalanceAndPayoutsManage/BalanceAndPayoutsManage';
import HubManagementStore from './HubManagementStore/HubManagementStore';
import HubManagementAuction from './HubManagementAuction/HubManagementAuction';
import HubManagementDonationMatching from './HubManagementDonationMatching/HubManagementDonationMatching';
import HubManagementDonations from './HubManagementDonations/HubManagementDonations';
import HubManagementVolunteerHours from './HubManagementVolunteerHours/HubManagementVolunteerHours';
import { Config } from '@gigit/config';
import HubManagementLocations from './HubManagementLocations/HubManagementLocations';
import HubManagementEvents from './HubManagementEvents/HubManagementEvents';
import Loader from '../../Loader/Loader';
import HubManagementAnalytics from './HubManagementAnalytics/HubManagementAnalytics';
import HubManagementVolunteerOpportunities from './HubManagementVolunteerOpportunities/HubManagementVolunteerOpportunities';
import HubManagementVolunteers from './HubManagementVolunteers/HubManagementVolunteers';
import HubManagementBilling from './HubManagementBilling/HubManagementBilling';
import { Constants } from '@gigit/constants';
import ProgramDonations from './HubManagementProgramDonations/ProgramDonations';

const HubManagement: React.FC = () => {
  const [hub, setHub] = useState<IHub>();
  const [hubRoles, setHubRoles] = useState<IRole[]>([]);
  const [activeTab, setActiveTab] = useState<string>('settings');
  const [userRole, setUserRole] = useState<IUserRole>();
  const [currentPackage, setCurrentPackage] = useState<string>('');
  const { dispatchToastError } = useToastDispatcher();
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));
  const history = useHistory();
  const location = useLocation();
  const { handle } = useParams<{ handle?: string }>();

  async function getHub(hubHandle: string) {
    try {
      const res = await hubRequestActions.getHub(hubHandle);

      if (res.id) {
        let info = await billingRequestActions.getPremiumFeatureSummary(
          uiConstants.ownerType.hub,
          res?.id,
        );

        if (info.features.length) {
          setCurrentPackage(info.features[0].feature_code);
        }
      }

      setHub(res);
    } catch (error) {
      dispatchToastError(error, 'Get Hub');
    }
  }

  async function getUserRole() {
    if (hub?.id && userState?.user?.id) {
      try {
        const role = await hubRequestActions.getHubUserRole(hub.id, userState.user.id);
        if (role?.role_name !== uiConstants.role.admin) history.push(`/company/${hub.handle}`);
        setUserRole(role);
      } catch (error) {
        dispatchToastError(error, 'Get Hub User Role');
        history.push(`/company/${hub.handle}`);
      }
    }
  }

  async function getRoles(id: string) {
    try {
      const result = await roleRequestActions.getRoles(uiConstants.ownerType.hub, id);
      if (result) {
        setHubRoles(result.data);
      }
    } catch (error) {
      dispatchToastError(error, 'Hub Roles');
    }
  }

  const renderActiveSection = (): React.ReactNode => {
    switch (activeTab) {
      case 'settings':
        return (
          <HubManagementSettings
            hub={hub}
            key="settings"
          />
        );
      case 'benefiting-causes':
        return (
          <HubManagementBenefitingCauses
            hub={hub}
            key="benefiting-causes"
          />
        );
      case 'seats':
        return (
          <HubManagementSeats
            hubRoles={hubRoles}
            hub={hub}
            key="seats"
          />
        );
      case 'balance-and-payouts':
        return hub ? (
          <div className="balance_and_payouts-wrap">
            <BalanceAndPayoutsManage
              owner={typeHelpers.createOwnerObject('hub', hub)}
              hub={hub}
              key="balance-and-payouts"
            />
          </div>
        ) : null;
      case 'billing':
        return hub ? (
          <HubManagementBilling
            hubRoles={hubRoles}
            hub={hub}
            onSubscriptionChange={() => {
              if (handle) {
                getHub(handle);
              }
            }}
          />
        ) : null;
      case 'members':
        return (
          <HubManagementMembers
            hub={hub}
            key="members"
          />
        );
      case 'focus-areas':
        return (
          <HubManagementFocusAreas
            hub={hub}
            key="focus-areas"
          />
        );
      case 'store':
        return (
          <HubManagementStore
            hub={hub}
            key="store"
          />
        );
      case 'auction':
        return (
          <HubManagementAuction
            hub={hub}
            key="auction"
          />
        );
      case 'donation-matching':
        return (
          <HubManagementDonationMatching
            hub={hub}
            key="donation-matching"
          />
        );
      case 'donation-transactions':
        return (
          <HubManagementDonations
            hub={hub}
            key="donations"
          />
        );

      case 'volunteer-for-dollars-transactions':
        return (
          <HubManagementVolunteerHours
            hub={hub}
            key="volunteer-for-dollars-transactions"
          />
        );
      case 'volunteer-hours':
        return (
          <HubManagementVolunteerHours
            hub={hub}
            key="volunteer-hours"
          />
        );
      case 'volunteer-opportunities':
        return (
          <HubManagementVolunteerOpportunities
            hub={hub}
            key="volunteer-opportunities"
          />
        );
      case 'volunteers':
        return (
          <HubManagementVolunteers
            hub={hub}
            key="volunteers"
          />
        );
      case 'program-donations':
        return (
          <ProgramDonations
            hub={hub}
            key="program-donations"
          />
        );
      case 'locations':
        return (
          <HubManagementLocations
            hub={hub}
            key="locations"
          />
        );
      case 'events':
        return (
          <HubManagementEvents
            hub={hub}
            key="events"
          />
        );
      case 'summary':
        return (
          <HubManagementAnalytics
            hub={hub!}
            key="summary"
          />
        );
    }
  };

  useEffect(() => {
    if (handle) {
      getHub(handle);
    }
  }, [handle]);

  useEffect(() => {
    if (hub) {
      setSEOMetatags({
        urlPath: `company/${hub.handle}`,
        title: `${hub.title} | Kambeo`,
        description: undefined,
        imageURL: hub.profile_image_url,
        coverImageURL: hub.banner?.image || '',
      });

      if (hub?.id) {
        getRoles(hub.id);
      }
      getUserRole();
    }
  }, [hub]);

  useEffect(() => {
    const _params = queryString.parse(location.search);

    if (_params.t) {
      setActiveTab(_params.t as string);
    }

    if (_params.s) {
      let sectionName = _params.s as string;
      let section = document.getElementById(sectionName);
      let topOffset = section?.offsetTop || 0;
      if (section && topOffset) {
        // Prevent section being scrolled under
        window.scrollTo({ top: topOffset - 90, behavior: 'smooth' });
      }
    }
  }, [location.search]);

  // TODO: uncomment permission part after adding a proper logic for getting permissions for the current user
  const getSidebarMenuOptions = (): IMenuSection[] => {
    return [
      {
        id: 'setup',
        sectionTitle: 'Setup',
        sectionIcon: 'fal fa-sliders-v',
        collapsible: false,
        menuOptions: [
          { id: 'settings', title: 'Settings' /*permission: "EDIT_HUB_INFO" */ },
          { id: 'locations', title: 'Locations' },
          {
            id: 'benefiting-causes',
            title: 'Benefiting Causes' /*permission: "MANAGE_BENEFITING_CAUSES" */,
          },
          { id: 'focus-areas', title: 'Focus Areas' },
        ],
      },
      {
        id: 'manage',
        sectionTitle: 'Manage',
        sectionIcon: 'fal fa-user-cog',
        collapsible: false,
        menuOptions: [
          { id: 'billing', title: 'Billing' /*permission: "MANAGE_BALANCE_AND_PAYOUTS" */ },
          {
            id: 'events',
            title: 'Events',
            hide: currentPackage !== Constants.billing.subscriptions.hub_seating.code,
          },
          // { id: 'store', title: "Store Items", /*permission: "MANAGE_SEATS" */}, GIG-7653: Hiding store item tab for now
          {
            id: 'balance-and-payouts',
            title: 'Balance & Payouts' /*permission: "MANAGE_BALANCE_AND_PAYOUTS" */,
          },
          { id: 'seats', title: 'Seats' /*permission: "MANAGE_SEATS" */ },
          // { id: 'store', title: "Store", /*permission: "MANAGE_STORE" */},
          /** We're hiding auctions for now */
          // { id: 'auction', title: "Auction", /*permission: "MANAGE_AUCTION" */},
          {
            id: 'volunteer-opportunities',
            title: 'Volunteer Opportunities',
            hide: currentPackage == Constants.billing.subscriptions.share_package.code,
          },
          {
            id: 'reports',
            title: 'reports',
            submenuOptions: [
              {
                id: 'program-donations',
                title: 'Donations',
              },
            ],
          },
        ],
      },
      ...(Config.feature_flags.HUB_DONATION_MATCHING
        ? [
            {
              id: 'transactions',
              sectionTitle: 'Transactions',
              sectionIcon: 'fal fa-usd-circle',
              collapsible: false,
              menuOptions: [
                {
                  id: 'donation-matching',
                  title: 'Matching Programs',
                  hide: currentPackage !== Constants.billing.subscriptions.hub_seating.code,
                },
                {
                  id: 'donation-transactions',
                  title: 'Donations',
                  hide: currentPackage !== Constants.billing.subscriptions.hub_seating.code,
                },
                {
                  id: 'volunteer-for-dollars-transactions',
                  title: 'Hours for Dollars',
                  hide: currentPackage !== Constants.billing.subscriptions.hub_seating.code,
                },
              ],
            },
          ]
        : []),
      {
        id: 'lists',
        sectionTitle: 'Lists',
        sectionIcon: 'fal fa-list',
        collapsible: false,
        menuOptions: [
          { id: 'members', title: 'Members' /*permission: "MANAGE_MEMBERS" */ },
          {
            id: 'volunteers',
            title: 'Volunteers',
            hide:
              currentPackage ==
              Constants.billing.subscriptions.share_package.code /*permission: "MANAGE_MEMBERS" */,
          },
        ],
      },
      {
        id: 'analytics',
        sectionTitle: 'Analytics',
        sectionIcon: 'fal fa-chart-bar',
        collapsible: false,
        menuOptions: [
          {
            id: 'summary',
            title: 'Summary',
            hide:
              currentPackage ==
              Constants.billing.subscriptions.share_package.code /*permission: "MANAGE_SEATS" */,
          },
        ],
      },
    ];
  };

  return (
    <>
      {!isUserLoggedIn && <Redirect to="/login" />}

      {isUserLoggedIn && (
        <div className="HubManagement">
          <ManagementSidebar
            logo={hub?.profile_image_url || ''}
            role={userRole?.role_name || 'Loading...'}
            containerClass="HubManagementSidebar"
            title="Company Management"
            targetLink={{ url: '/company/' + hub?.handle, title: hub?.title || '' }}
            menuSections={getSidebarMenuOptions()}
            activeOptionId={activeTab}
            permissions={[]} //TODO: added proper permissions
            onMenuOptionClicked={(menuOptionId: string) => {
              if (hub?.handle) {
                history.replace({
                  pathname: `/company/${hub.handle}/admin`,
                  search: `t=${menuOptionId}`,
                });
              }
            }}
          />
          <section className="main-container">
            {hub && renderActiveSection()}
            {!hub && <Loader loading={!hub} />}
          </section>
        </div>
      )}
    </>
  );
};

export default HubManagement;
