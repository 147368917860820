import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import { uiConstants } from '../../constants';
import Button from '../Button/Button';
import { userRequestActions } from '../../requestActions';
import { ILoggedVolunteerHoursSummary, IShiftUserSummary } from '@gigit/interfaces';
import './VolunteerModal.scss';
import useToastDispatcher from '../../hooks/useToaster';
import Loader from '../Loader/Loader';
import Portrait from '../Portrait/Portrait';
import { Link } from 'react-router-dom';
import Table, { ITableProps } from '../shared/Table/Table';
import { capitalizeString } from '../../helpers';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { userSelectors } from '../../selectors/user';
import moment, { Moment } from 'moment';
import TextField from '../TextField/TextField';
import DateTimePicker from '../shared/DateTimePicker/DateTimePicker';

interface IProps {
  showModal: boolean;
  onCloseModal(): void;
  onLogHours?: () => void;
  type: string; // uiConstants.volunteer_modal_types
  gigId?: string;
  start_date?: Date;
  end_date?: Date;
}

const VolunteerModal: React.FC<IProps> = (props) => {
  const { dispatchToastError } = useToastDispatcher();
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const [activeType, setActiveType] = useState<string>(props.type);
  const [loading, setLoading] = useState<boolean>(false);

  // log_all
  const [volunteerOpportunities, setVolunteerOpportunities] = useState<IShiftUserSummary[]>([]);
  const [activeVolunteerOpportunity, setActiveVolunteerOpportunity] =
    useState<IShiftUserSummary | null>(null);

  // volunteer_hr_all
  const volunteerHrAllTableProps: ITableProps<ILoggedVolunteerHoursSummary> = {
    columns: [
      {
        id: 'created_at',
        Header: 'Date',
        accessor: ({ created_at }) => {
          if (created_at) {
            return localizeHelpers.formatDate(created_at, LocaleDateFormats.ll, locale);
          } else return '';
        },
        sortable: true,
        Cell: ({ created_at }) => (
          <var data-var="created_at">
            {created_at ? localizeHelpers.formatDate(created_at, LocaleDateFormats.ll, locale) : ''}
          </var>
        ),
      },
      {
        id: 'gig.title',
        Header: 'Opportunity Name',
        accessor: ({ gig }) => localizeHelpers.translate(gig?.title),
        sortable: true,
        Cell: ({ gig }) => <var data-var="opportunity_name">{gig?.title}</var>,
      },
      {
        id: 'group.title',
        Header: 'Organization',
        accessor: ({ group }) => group?.title || '',
        Cell: ({ group }) => <var data-var="organization">{group?.title || ''}</var>,
        sortable: true,
      },
      {
        id: 'hours',
        Header: 'Hours Logged',
        accessor: ({ hours }) => hours,
        Cell: ({ hours }) => <var data-var="hours">{hours}h</var>,
        sortable: true,
      },
      {
        id: 'status.code',
        Header: 'Status',
        accessor: ({ status }) => capitalizeString(getStatus(status?.code || '')),
        Cell: ({ status }) => (
          <span className={`status ${getStatus(status?.code || '')}`}>
            {capitalizeString(getStatus(status?.code || ''))}
          </span>
        ),
      },
    ],
    pagination: {
      pageSizeOptions: [uiConstants.volunteer_hours_all_items_per_page],
      queryAction: getVolunteerHoursAll,
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any volunteer hours",
    },
  };

  // volunteer_hr_single
  const volunteerHrSingleTableProps: ITableProps<ILoggedVolunteerHoursSummary> = {
    columns: [
      {
        id: 'end_date',
        Header: 'Date Completed',
        accessor: ({ end_date }) => {
          if (end_date) {
            return localizeHelpers.formatDate(end_date, LocaleDateFormats.ll, locale);
          } else return '';
        },
        sortable: true,
        Cell: ({ end_date }) => (
          <var data-var="end_date">
            {end_date ? localizeHelpers.formatDate(end_date, LocaleDateFormats.ll, locale) : ''}
          </var>
        ),
      },
      {
        id: 'created_at',
        Header: 'Date Submitted',
        accessor: ({ created_at }) => {
          if (created_at) {
            return localizeHelpers.formatDate(created_at, LocaleDateFormats.ll, locale);
          } else return '';
        },
        sortable: true,
        Cell: ({ created_at }) => (
          <var data-var="created_at">
            {created_at ? localizeHelpers.formatDate(created_at, LocaleDateFormats.ll, locale) : ''}
          </var>
        ),
      },
      {
        id: 'hours',
        Header: 'Hours Logged',
        accessor: ({ hours }) => hours,
        Cell: ({ hours }) => <var data-var="hours">{hours}h</var>,
        sortable: true,
      },
      {
        id: 'status.code',
        Header: 'Status',
        accessor: ({ status }) => capitalizeString(getStatus(status?.code || '')),
        Cell: ({ status }) => (
          <span className={`status ${getStatus(status?.code || '')}`}>
            {capitalizeString(getStatus(status?.code || ''))}
          </span>
        ),
        sortable: true,
      },
    ],
    pagination: {
      pageSizeOptions: [uiConstants.volunteer_hours_single_gig_items_per_page],
      queryAction: getVolunteerHoursForGig,
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any volunteer hours",
    },
  };

  // log_single
  const [logStartDate, setLogStartDate] = useState<Moment>(moment(props.start_date));
  const [logEndDate, setLogEndDate] = useState<Moment>(moment(props.end_date));
  const [logHour, setLogHour] = useState<string>();
  // const [logMin, setLogMin] = useState<string>()

  function getStatus(statusCode: string): string {
    if (statusCode === 'approved') {
      return 'Verified';
    }

    return 'Unverified';
  }

  async function getVolunteerHoursAll(query: URLSearchParams) {
    let result = [] as ILoggedVolunteerHoursSummary[];

    try {
      result = await userRequestActions.getUserVolunteeringHours(query);
    } catch (error) {
      dispatchToastError(error, 'Get User Volunteer Hours Items');
    }

    return result;
  }

  async function getVolunteerHoursForGig(query: URLSearchParams) {
    let result = [] as ILoggedVolunteerHoursSummary[];

    if (activeVolunteerOpportunity?.gig_id) {
      try {
        result = await userRequestActions.getUserVolunteeringHoursForGig(
          activeVolunteerOpportunity.gig_id,
          query,
        );
      } catch (error) {
        dispatchToastError(error, 'Get User Volunteer Hours Items');
      }
    }

    return result;
  }

  async function getUserVolunteerOpportunities() {
    setLoading(true);
    try {
      const result = await userRequestActions.getUserGigShifts();
      setVolunteerOpportunities(result);

      if (props?.gigId) {
        setActiveVolunteerOpportunity(result.find((res) => res.gig_id === props.gigId) || null);
      }
    } catch (error) {
      dispatchToastError(error, 'Get User Volunteer opportunities');
    } finally {
      setLoading(false);
    }
  }

  async function log() {
    if (activeVolunteerOpportunity?.gig_id) {
      try {
        let startDate = moment(logStartDate)
          .set({
            second: 0,
          })
          .utc()
          .format();

        let endDate = moment(logEndDate)
          .set({
            second: 0,
          })
          .utc()
          .format();

        const payload = {
          gig_id: activeVolunteerOpportunity.gig_id,
          start_date: startDate,
          end_date: endDate,
          hours: Number(logHour),
          //minutes: Number(logMin),
        };

        await userRequestActions.logVolunteeringHours(payload);

        props.onCloseModal();
        props?.onLogHours?.();
      } catch (error) {
        dispatchToastError(error, 'Log Volunteer Hours');
      }
    }
  }

  useEffect(() => {
    setActiveType(props.type);
  }, [props.type]);

  useEffect(() => {
    if (props?.gigId) {
      setActiveVolunteerOpportunity(
        volunteerOpportunities.find((res) => res.gig_id === props.gigId) || null,
      );
    }
  }, [props.gigId]);

  useEffect(() => {
    if (props.showModal) {
      getUserVolunteerOpportunities();
    }
  }, [props.showModal]);

  return (
    <Modal
      class="VolunteerModal"
      show={props.showModal}
      onClose={props.onCloseModal}
    >
      {activeType === uiConstants.volunteer_modal_types.log_all && (
        <section className="LogHoursAll">
          <div className="header">
            <div className="left">
              <h2>Log Hours</h2>
              <span>Select an opportunity to log hours for</span>
            </div>
            <Button
              text="View Volunteer Hours"
              onClick={() => setActiveType(uiConstants.volunteer_modal_types.volunteer_hr_all)}
              type="button"
              buttonType="dark"
            />
          </div>
          <div className="list">
            {loading && <Loader loading={loading} />}

            {!loading &&
              volunteerOpportunities.length > 0 &&
              volunteerOpportunities.map((volunteerOpportunity, index) => (
                <div
                  className="item"
                  key={index}
                  onClick={() => {
                    setActiveVolunteerOpportunity(volunteerOpportunity);
                    setActiveType(uiConstants.volunteer_modal_types.log_single);
                  }}
                >
                  <Portrait
                    size={35}
                    currentImage={volunteerOpportunity?.gig?.profile_image_url || ''}
                  />
                  <div className="name-con">
                    <Link to={`/gig/${volunteerOpportunity?.gig_handle}`}>
                      <span
                        className="name"
                        notranslate="yes"
                        title={volunteerOpportunity?.gig_title}
                      >
                        {volunteerOpportunity?.gig_title}
                      </span>
                    </Link>
                    <Link to={`/group/${volunteerOpportunity?.group_handle}`}>
                      <span className="under-name">
                        By the <var data-var="group_name">{volunteerOpportunity?.group_title}</var>
                      </span>
                    </Link>
                  </div>
                  <div className="applied-on">
                    <span>Applied on</span>
                    <span>
                      <var data-var="applied_on">
                        {volunteerOpportunity?.volunteer_total_hours?.created_at
                          ? localizeHelpers.formatDate(
                              volunteerOpportunity.volunteer_total_hours.created_at,
                              LocaleDateFormats.ll,
                              locale,
                            )
                          : ''}
                      </var>
                    </span>
                  </div>
                </div>
              ))}
          </div>
          <div className="btn-container">
            <Button
              text="Close"
              type="button"
              onClick={() => props.onCloseModal()}
              buttonType="outline-dark"
            />
          </div>
        </section>
      )}

      {activeType === uiConstants.volunteer_modal_types.volunteer_hr_all && (
        <section className="VolunteerHrAll">
          <div className="header">
            <div className="left">
              <h2>Volunteer Hours</h2>
              <span>History of volunteer hours logged</span>
            </div>
            <Button
              text="Log Hours"
              onClick={() => setActiveType(uiConstants.volunteer_modal_types.log_all)}
              type="button"
              buttonType="dark"
            />
          </div>
          <div className="list">
            <Table {...volunteerHrAllTableProps} />
          </div>
          <div className="btn-container">
            <Button
              text="Close"
              type="button"
              onClick={() => props.onCloseModal()}
              buttonType="outline-dark"
            />
          </div>
        </section>
      )}

      {activeType === uiConstants.volunteer_modal_types.volunteer_hr_single && (
        <section className="VolunteerHrSingle single">
          <div className="header">
            <div className="left with-img">
              <Portrait
                size={60}
                currentImage={activeVolunteerOpportunity?.gig?.profile_image_url}
              />
              <div className="info">
                <h2>
                  <Link to={`/gig/${activeVolunteerOpportunity?.gig_handle}`}>
                    <var data-var="gig_title">{activeVolunteerOpportunity?.gig_title}</var>
                  </Link>
                </h2>
                <span>
                  By the{' '}
                  <Link to={`/group/${activeVolunteerOpportunity?.group_handle}`}>
                    <var data-var="group_name">{activeVolunteerOpportunity?.group_title}</var>
                  </Link>
                </span>
              </div>
            </div>
            <Button
              text="Log Hours"
              onClick={() => setActiveType(uiConstants.volunteer_modal_types.log_single)}
              type="button"
              buttonType="dark"
            />
          </div>
          <div className="list">
            {activeVolunteerOpportunity?.gig_id && <Table {...volunteerHrSingleTableProps} />}
          </div>
          <div className="btn-container">
            <span
              className="back"
              onClick={() => {
                setActiveType(uiConstants.volunteer_modal_types.log_all);
                setActiveVolunteerOpportunity(null);
              }}
            >
              <i className="far fa-chevron-left" /> Back
            </span>
            <div className="right">
              <Button
                text="Close"
                type="button"
                onClick={() => props.onCloseModal()}
                buttonType="outline-dark"
              />
              <Button
                text="Log"
                type="button"
                onClick={() => {}}
                isDisabled={true}
                buttonType="dark"
              />
            </div>
          </div>
        </section>
      )}

      {activeType === uiConstants.volunteer_modal_types.log_single && (
        <section className="LogSingle single">
          <div className="header">
            <div className="left with-img">
              <Portrait
                size={60}
                currentImage={activeVolunteerOpportunity?.gig?.profile_image_url}
              />
              <div className="info">
                <h2>
                  <Link to={`/gig/${activeVolunteerOpportunity?.gig_handle}`}>
                    <var data-var="gig_title">{activeVolunteerOpportunity?.gig_title}</var>
                  </Link>
                </h2>
                <span>
                  By the{' '}
                  <Link to={`/group/${activeVolunteerOpportunity?.group_handle}`}>
                    <var data-var="group_name">{activeVolunteerOpportunity?.group_title}</var>
                  </Link>
                </span>
              </div>
            </div>
            <Button
              text="View Volunteer Hours"
              onClick={() => setActiveType(uiConstants.volunteer_modal_types.volunteer_hr_single)}
              type="button"
              buttonType="dark"
            />
          </div>
          <div className="subtext">
            <span className="title">Log Hours</span>
            <span className="desc">Select a date to log volunteer hours</span>
          </div>
          <form>
            <div className="row">
              <div className="col date-container">
                <label>Start Date</label>
                <div notranslate="yes">
                  <DateTimePicker
                    shouldValidate={false}
                    inputProps={{ placeholder: 'Date', readOnly: true }}
                    className="custom-dtp"
                    value={logStartDate}
                    onChange={(date) => setLogStartDate(moment(date))}
                    locale={locale}
                    timeFormat={false}
                  />
                </div>
              </div>
              <div className="col date-container">
                <label>End Date</label>
                <div notranslate="yes">
                  <DateTimePicker
                    timeFormat={false}
                    shouldValidate={false}
                    inputProps={{ placeholder: 'Date', readOnly: true }}
                    className="custom-dtp"
                    value={logEndDate}
                    onChange={(date) => setLogEndDate(moment(date))}
                    locale={locale}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {/*<div className="col">*/}
              <TextField
                placeholder="Type here"
                value={logHour || ''}
                label="Hours"
                name="hour"
                min="1"
                type="number"
                onChange={(e) => setLogHour(e.target.value)}
              />
              {/*</div>*/}
              {/*<div className="col">*/}
              {/*    <TextField placeholder='Type here' value={logMin || ''} name='min' label='Minutes' min='1' type='number' onChange={(e)=>setLogMin(e.target.value)} />*/}
              {/*</div>*/}
            </div>
          </form>
          <div className="btn-container">
            <span
              className="back"
              onClick={() => {
                setActiveType(uiConstants.volunteer_modal_types.log_all);
                setActiveVolunteerOpportunity(null);
              }}
            >
              <i className="far fa-chevron-left" /> Back
            </span>
            <div className="right">
              <Button
                text="Close"
                type="button"
                onClick={() => props.onCloseModal()}
                buttonType="outline-dark"
              />
              <Button
                text="Log"
                type="button"
                onClick={() => log()}
                buttonType="dark"
                isDisabled={
                  Number(logHour) < 1 ||
                  logHour === '' ||
                  logEndDate === null ||
                  logStartDate === null
                }
              />
            </div>
          </div>
        </section>
      )}
    </Modal>
  );
};

export default VolunteerModal;
