import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IAppState } from '../../store';
import {
  combineClassNames,
  handleInputChange,
  toastSuccess,
  toastError,
  errorHelpers,
  handleDebounce,
} from '../../helpers';

import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import ContextMenu from '../../components/ContextMenu/ContextMenu';

import { IUserState } from '../../reducers/user';
import { IGroupState } from '../../reducers/group';

import {
  requestHubAssociation,
  approveHubAssociation,
  rejectHubAssociation,
  getGroupHubMemberGroups,
  updateHubGroupPostalCodePatterns,
  updateGroup,
  getGroupHubs,
} from '../../actions/group';
import { createToast } from '../../actions/toaster';

import './HubManagement.scss';
import { IGroup, IHubGroupDetails } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import Modal from '../Modal/Modal';
import ModalScrollContainer from '../Modal/ModalScrollContainer/ModalScrollContainer';
import { groupSelectors } from '../../selectors/group';
import { searchRequestActions } from '../../requestActions';

interface IProps extends WithTranslation, RouteComponentProps {
  userState: IUserState;
  groupState: IGroupState;

  requestHubAssociation(groupId: string, hubId: string, options?: { callback?: () => void }): void;
  approveHubAssociation(
    groupId: string,
    candidateGroupId: string,
    options?: { callback?: () => void },
  ): void;
  rejectHubAssociation(groupId: string, candidateGroupId: string): void;
  getGroupHubMemberGroups(groupId: string, query?: string): void;
  updateHubGroupPostalCodePatterns(
    groupId: string,
    postalCodeUpdates: { candidateGroupId: string; postalCodePatterns: string[] }[],
    options?: { callback?: () => void },
  ): void;
  createToast(toast: any): void;
  updateGroup(_payload: any, groupId: string, _pages?: any, isFlow?: boolean): void;
  getGroupHubs(groupId: string, query?: string): void;
  groupId: string;
  primaryHubId: string;
}

type IGroupPostalCodeMap = { [index: string]: string[] };

interface IState {
  redirect: string | null;
  searchValue: string;
  showAddGroup: boolean;
  addGroupSearch: string;
  showEditPostalCodesModal: boolean;
  postalCodes: IGroupPostalCodeMap;
  showGroupHubSettings: boolean;
  searchedGroups: IGroup[];
}

/** Admin Page for managing the primary hub of a group. */
/**
 * @deprecated Use Hub/HubManagement component, this component should be removed after we start to use Hub/HubManagement
 */
class HubManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      redirect: '',
      searchValue: '',
      showAddGroup: false,
      addGroupSearch: '',
      showEditPostalCodesModal: false,
      postalCodes: {},
      showGroupHubSettings: false,
      searchedGroups: [],
    };
  }

  componentDidMount() {
    this.fetchHubGroups();
    this.props.getGroupHubs(this.props.groupState.group.id);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.searchValue !== this.state.searchValue) {
      handleDebounce(this.state.searchValue).then((res) => {
        this.fetchHubGroups();
      });
    }

    if (
      prevState.addGroupSearch !== this.state.addGroupSearch ||
      prevState.showAddGroup !== this.state.showAddGroup
    ) {
      this.fetchAddGroupCandidates();
    }
  }

  fetchHubGroups() {
    this.props.getGroupHubMemberGroups(this.props.groupId, this.state.searchValue);
  }

  async fetchAddGroupCandidates() {
    try {
      const groups = await searchRequestActions.searchGroups(
        {
          wildcard_search: this.state.addGroupSearch,
        },
        { limit: '500' },
      );

      this.setState({
        searchedGroups: groups,
      });
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Fetch Causes');
      this.props.createToast(toast);
    }
  }

  getPrimaryHub() {
    return this.props.groupState.groupHubs?.find((hub) => hub.id === this.props.primaryHubId);
  }

  openEditPostalCodesModal() {
    this.setState({
      showEditPostalCodesModal: true,
      postalCodes: this.props.groupState.hubMemberGroups.reduce<IGroupPostalCodeMap>(
        (prev, curValue) => {
          prev[curValue.id] = curValue.postal_codes ?? [];
          return prev;
        },
        {},
      ),
    });
  }

  getHubGroupsWithChangedPostalCodes() {
    const changedHubGroupPostalCodes: { hubGroup: IHubGroupDetails; postalCodes: string[] }[] = [];

    for (const hubGroup of this.props.groupState.hubMemberGroups) {
      const oldPostalCodes = hubGroup.postal_codes ?? [];
      if (oldPostalCodes.length === 0) {
        oldPostalCodes.push('');
      }

      const newPostalCodes = this.state.postalCodes[hubGroup.id] ?? [];
      if (newPostalCodes.length === 0) {
        newPostalCodes.push('');
      }

      if (
        oldPostalCodes.length !== newPostalCodes.length ||
        oldPostalCodes.some((oldPostalCode, index) => oldPostalCode !== newPostalCodes[index])
      ) {
        changedHubGroupPostalCodes.push({
          hubGroup,
          postalCodes: newPostalCodes,
        });
      }
    }

    return changedHubGroupPostalCodes;
  }

  savePostalCodes() {
    const changedHubGroups = this.getHubGroupsWithChangedPostalCodes();

    this.props.updateHubGroupPostalCodePatterns(
      this.props.groupId,
      changedHubGroups.map(({ hubGroup, postalCodes }) => ({
        candidateGroupId: hubGroup.id,
        postalCodePatterns: postalCodes,
      })),
      {
        callback: () => {
          this.setState({
            showEditPostalCodesModal: false,
          });

          this.props.createToast(
            toastSuccess('Updated Postal Codes successfully.', 'Edit Hub Cause Postal Codes'),
          );

          this.fetchHubGroups();
        },
      },
    );
  }

  toggleAllowDelegations() {
    this.props.updateGroup(
      {
        allow_donation_delegation: !(
          this.props.groupState.group.allow_donation_delegation ?? false
        ),
      },
      this.props.groupState.group.id,
    );
  }

  render() {
    let { t } = this.props;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className="HubManagement section-wrap">
        <div className="section-title">
          <div className="forms-title">{t('Hub')}</div>
          <div className="forms-create">
            <Button
              icon="fal fa-plus"
              onClick={() => this.setState({ showAddGroup: true })}
              text={t('Add Cause')}
            />

            <div className="context-menu">
              <i className="fal fa-ellipsis-h-alt"></i>
              <ContextMenu
                onMouseLeave={() => {}}
                showMenu={true}
                menuItems={[
                  {
                    label: 'Add Postal Codes',
                    icon: 'fal fa-map-marker-alt',
                    onClick: () => this.openEditPostalCodesModal(),
                  },
                  {
                    label: 'Settings',
                    icon: 'fal fa-cog',
                    onClick: () => this.setState({ showGroupHubSettings: true }),
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div className="section-inner">
          <div className="EventManagement-list">
            <div className="search">
              <TextField
                icon="fas fa-search"
                placeholder="Search Causes..."
                value={this.state.searchValue}
                type="text"
                name="searchValue"
                onChange={(e: any) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="list">
              <div className="headers">
                <div className="col title">Cause Name</div>
                <div className="col handle">Cause Page Handle</div>
                <div className="col is-primary"></div>
                <div className="col approval-actions"></div>
                <div className="col actions"></div>
              </div>
              <div className="list-inner">
                <div className="list-rows">
                  {this.props.groupState.hubMemberGroups.map((group, index) =>
                    this.renderGroup(group, index),
                  )}

                  {this.props.groupState.hubMemberGroups.length === 0 && (
                    <div className="empty">Your search returned 0 results.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          class="add-group-modal"
          contentClassName="add-group-modal-content"
          title="Add Causes"
          description="Select Causes to be Associated with your National Organization"
          show={this.state.showAddGroup}
          onClose={() => this.setState({ showAddGroup: false })}
        >
          <TextField
            icon="fas fa-search"
            placeholder="Search Causes..."
            value={this.state.addGroupSearch}
            type="text"
            name="addGroupSearch"
            onChange={(e: any) => {
              handleInputChange(e, this);
            }}
          />

          <ModalScrollContainer className="add-group-list">
            {this.state.searchedGroups.map((group, index) =>
              this.renderAddGroupListGroup(group, index),
            )}
          </ModalScrollContainer>
        </Modal>

        <Modal
          class="edit-postal-codes-modal"
          contentClassName="edit-postal-codes-modal-content"
          title="Edit Postal Codes"
          show={this.state.showEditPostalCodesModal}
          onClose={() => this.setState({ showEditPostalCodesModal: false })}
        >
          <div className="list">
            <div className="headers">
              <div className="col title">Cause Name</div>
              <div className="col postal-code">Postal Code</div>
            </div>

            <div className="list-inner">
              <div className="list-rows">
                {this.props.groupState.hubMemberGroups.map((group, index) =>
                  this.renderEditPostalCodeGroup(group, index),
                )}
              </div>
            </div>
          </div>

          <div className="actions">
            <Button
              className="cancel-button"
              text="Cancel"
              onClick={() => this.setState({ showEditPostalCodesModal: false })}
            />
            <Button
              text="Save Changes"
              onClick={() => this.savePostalCodes()}
              isDisabled={this.getHubGroupsWithChangedPostalCodes().length === 0}
            />
          </div>
        </Modal>

        <Modal
          class="hub-group-settings"
          contentClassName="hub-group-settings-content"
          title="Hub Settings"
          show={this.state.showGroupHubSettings}
          onClose={() => this.setState({ showGroupHubSettings: false })}
        >
          <div className="hub-group-settings-section">Hub Details</div>

          <div className="hub-title">
            <span className="label">Hub:</span> {this.getPrimaryHub()?.title}
          </div>

          <div className="hub-group-settings-section">Cause Details</div>

          <div className="settings-row">
            <div className="title-area">
              <div className="title">Allow Donation Delegation</div>
              <div className="description">
                Do you want users to have the option to select which cause in your hub their
                donation will be attributed to?
              </div>
            </div>
            <i
              onClick={() => {
                this.toggleAllowDelegations();
              }}
              className={combineClassNames(
                this.props.groupState.group.allow_donation_delegation
                  ? 'fad fa-toggle-on'
                  : 'fad fa-toggle-off',
                'value',
              )}
            ></i>
          </div>
        </Modal>
      </div>
    );
  }

  renderGroup(hubGroup: IHubGroupDetails, index: number) {
    const { t } = this.props;
    const isHubPrimary = hubGroup.is_primary;
    const isApproved = hubGroup.group_status?.code !== Constants.hub_group_status.pending;

    return (
      <div
        key={index}
        className="row"
      >
        <div
          className="col title group-title"
          notranslate="yes"
        >
          <Link to={`/group/${hubGroup.handle}`}>
            <span>{hubGroup.title}</span>
          </Link>
        </div>
        <div
          className="col handle"
          notranslate="yes"
        >
          @{hubGroup.handle}
        </div>
        <div className="col is-primary">{isHubPrimary ? 'Primary' : ''}</div>

        <div className="col approval-actions">
          {!isApproved && (
            <Button
              text="Approve"
              onClick={() =>
                this.props.approveHubAssociation(this.props.groupId, hubGroup.id, {
                  callback: () => this.fetchHubGroups(),
                })
              }
            />
          )}
        </div>
        <div className="col actions">
          <i className="fal fa-ellipsis-h-alt"></i>
          <ContextMenu
            onMouseLeave={() => {}}
            showMenu={true}
            menuItems={[
              { link: `/group/${hubGroup.handle}`, icon: 'fal fa-file-alt', label: t('View') },
              {
                link: `/group/${hubGroup.handle}/admin`,
                icon: 'fal fa-user-cog',
                label: t('Manage'),
              },
            ]}
          />
        </div>
      </div>
    );
  }

  renderAddGroupListGroup(group: IGroup, index: number) {
    const hasHubRequest = group.hubs?.find((hub) => hub.hub_id === this.props.primaryHubId) != null;

    const requestHubAssocation = () => {
      this.props.requestHubAssociation(group.id, this.props.primaryHubId, {
        callback: () => {
          this.fetchAddGroupCandidates();
          this.fetchHubGroups();
        },
      });
    };

    return (
      <div
        className="add-group-list-item"
        key={group.id}
      >
        {group.profile_image_url ? (
          <img
            className="profile-img"
            src={group.profile_image_url}
          />
        ) : (
          <div className="profile-img transparent"></div>
        )}

        <div className="title-area">
          <div className="title">{group.title}</div>
          <div className="handle">@{group.handle}</div>
        </div>

        {!hasHubRequest && (
          <Button
            text="Add Cause"
            className="action-button"
            onClick={requestHubAssocation}
          />
        )}
      </div>
    );
  }

  renderEditPostalCodeGroup(hubGroup: IHubGroupDetails, index: number) {
    const postalCodes = this.state.postalCodes[hubGroup.id] ?? [];
    if (postalCodes.length === 0) {
      postalCodes.push('');
    }

    const updatePostalCode = (index: number, updatedPostalCode: string) => {
      const newPostalCodes = [...postalCodes];
      newPostalCodes[index] = updatedPostalCode;

      this.setState({
        postalCodes: {
          ...this.state.postalCodes,
          [hubGroup.id]: newPostalCodes,
        },
      });
    };

    const addPostalCode = () => {
      const newPostalCodes = [...postalCodes, ''];

      this.setState({
        postalCodes: {
          ...this.state.postalCodes,
          [hubGroup.id]: newPostalCodes,
        },
      });
    };

    const removePostalCode = (index: number) => {
      const newPostalCodes = [...postalCodes];
      newPostalCodes.splice(index, 1);

      this.setState({
        postalCodes: {
          ...this.state.postalCodes,
          [hubGroup.id]: newPostalCodes,
        },
      });
    };

    return (
      <div
        className="row"
        key={hubGroup.id}
      >
        <div
          className="col title group-title"
          notranslate="yes"
        >
          {hubGroup.title}
        </div>
        <div className="col postal-code postal-codes-list">
          {postalCodes.map((postalCode, index) => {
            return (
              <div
                key={index}
                className="postal-code-list-item"
              >
                <TextField
                  name="postalCode"
                  type="text"
                  value={postalCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updatePostalCode(index, e.target.value)
                  }
                />
                {index === 0 ? (
                  <Button
                    className="postal-code-button"
                    text=""
                    icon="fal fa-plus"
                    onClick={() => addPostalCode()}
                  />
                ) : (
                  <Button
                    className="postal-code-button"
                    text=""
                    icon="fal fa-trash-alt"
                    onClick={() => removePostalCode(index)}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    groupState: store.groupState,
    primaryHubId: groupSelectors.GetGroupMainHubId(store) || '',
  };
};

const mapDispatchToProps = {
  createToast,
  requestHubAssociation,
  approveHubAssociation,
  rejectHubAssociation,
  getGroupHubMemberGroups,
  updateHubGroupPostalCodePatterns,
  updateGroup,
  getGroupHubs,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(HubManagement),
);
