import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { IPermission, IRole } from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IStringMap, mapPermissions, handleInputChange, toastSuccess } from '../../helpers';

import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import ContextMenu from '../../components/ContextMenu/ContextMenu';
import Modal from '../../components/Modal/Modal';

import { ISettingsState } from '../../reducers/settings';
import { IGroupState } from '../../reducers/group';
import { IEventState } from '../../reducers/event';
import {
  getGroupRoles,
  updateGroupRole,
  createGroupRole,
  deleteGroupRole,
} from '../../actions/group';
import {
  getEventRoles,
  updateEventRole,
  createEventRole,
  deleteEventRole,
} from '../../actions/event';
import { createToast } from '../../actions/toaster';

import './RoleManagement.scss';
import { IOwnerObject, IToast } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import {
  eventRolePermissionTranslations,
  groupRolePermissionTranslations,
} from '../../locale/rolePermissionTranslations';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  owner: IOwnerObject;
  groupState: IGroupState;
  eventState: IEventState;
  settingsState: ISettingsState;
  getGroupRoles(groupId: string): void;
  getEventRoles(eventId: string): void;
  updateGroupRole(groupId: string, _role: any): void;
  createGroupRole(groupId: string, _role: any): void;
  deleteGroupRole(groupId: string, id: string): void;
  updateEventRole(eventId: string, _role: any): void;
  createEventRole(eventId: string, _role: any): void;
  deleteEventRole(eventId: string, id: string): void;
  createToast(toast: IToast): void;
}

interface IState {
  currentRole: IRole;
  formattedPermissions: IStringMap;
  searchValue: string;
  createNewRole: boolean;
  enableSave: boolean;
  confirmDelete: string;
  editingRole: boolean;
}

class RoleManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      currentRole: {
        role_name: '',
        is_user_defined: false,
        permissions: [],
      },
      formattedPermissions: {},
      searchValue: '',
      createNewRole: false,
      enableSave: false,
      confirmDelete: '',
      editingRole: false,
    };

    this.showRole = this.showRole.bind(this);
    this.clearRole = this.clearRole.bind(this);
    this.handleRoleNameChange = this.handleRoleNameChange.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  componentDidMount() {
    if (this.props.owner.ownerType === 'group') {
      this.props.getGroupRoles(this.props.groupState.group.id);
    } else {
      this.props.getEventRoles(this.props.eventState.event.id);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.owner.ownerType === 'group') {
      if (
        prevProps.groupState.groupRoles !== this.props.groupState.groupRoles &&
        this.state.currentRole.role_name !== ''
      ) {
        this.showRole(this.state.currentRole);
      }
    } else {
      if (
        prevProps.eventState.eventRoles !== this.props.eventState.eventRoles &&
        this.state.currentRole.role_name !== ''
      ) {
        this.showRole(this.state.currentRole);
      }
    }
  }

  showRole(role: IRole) {
    let _permissions: IStringMap = mapPermissions(role.permissions);

    this.setState({
      currentRole: role,
      formattedPermissions: _permissions,
      editingRole: true,
    });
  }

  clearRole() {
    this.setState({
      currentRole: {
        role_name: '',
        is_user_defined: false,
        permissions: [],
      },
      editingRole: false,
    });
  }

  handleRoleNameChange(event: any) {
    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;

    let _roleData = { ...this.state.currentRole };
    _roleData.role_name = value;

    this.setState({
      currentRole: _roleData,
      enableSave: !!value && value.trim() !== '',
    });
  }

  updatePermission(permission: string) {
    let rolePermissions: IPermission[] = this.state.currentRole.permissions;
    rolePermissions.find((p) => p.permission === permission)
      ? (rolePermissions = rolePermissions.map((p) =>
          p.permission === permission ? { ...p, value: !p.value } : p,
        ))
      : rolePermissions.push({ permission: permission, value: true });

    this.setState({
      enableSave:
        !!this.state.currentRole.role_name && this.state.currentRole.role_name.trim() !== '',
      currentRole: {
        ...this.state.currentRole,
        permissions: rolePermissions,
      },
      formattedPermissions: mapPermissions(rolePermissions),
    });
  }

  saveChanges() {
    this.setState(
      {
        enableSave: false,
        createNewRole: false,
      },
      () => {
        if (this.state.currentRole.id) {
          if (this.props.owner.ownerType === 'group') {
            this.props.updateGroupRole(this.props.groupState.group.id, this.state.currentRole);
          } else {
            this.props.updateEventRole(this.props.eventState.event.id, this.state.currentRole);
          }

          const toast = toastSuccess(
            localizeHelpers.translate('Role was updated successfully'),
            'Update Role',
          );
          this.props.createToast(toast);
        } else {
          if (this.props.owner.ownerType === 'group') {
            this.props.createGroupRole(this.props.groupState.group.id, this.state.currentRole);
          } else {
            this.props.createEventRole(this.props.eventState.event.id, this.state.currentRole);
          }

          const toast = toastSuccess(
            localizeHelpers.translate('New Role was created successfully'),
            'Create Role',
          );
          this.props.createToast(toast);
        }

        this.clearRole();
      },
    );
  }

  render() {
    const { t } = this.props;
    let noSearchResults = true;

    return (
      <div className="RoleManagement section-wrap">
        <div className="section-title">
          <div className="section-inner-title">{t('Roles')}</div>
          <div className="section-create">
            {!this.state.createNewRole && !this.state.editingRole && (
              <Button
                icon="fal fa-plus"
                onClick={() => {
                  this.setState({ createNewRole: true });
                }}
                text={t('New Role')}
              />
            )}
          </div>
        </div>
        <div className="section-inner">
          {!this.state.editingRole && !this.state.createNewRole && (
            <div className="RoleManagement-list">
              <div className="search">
                <TextField
                  icon="fas fa-search"
                  placeholder="Search roles..."
                  value={this.state.searchValue}
                  type="text"
                  name="searchValue"
                  onChange={(e: any) => {
                    handleInputChange(e, this);
                  }}
                />
              </div>
              <div className="list">
                <div className="headers">
                  <div className="col title">Role</div>
                  <div className="col actions"></div>
                </div>
                <div className="list-inner">
                  <div className="list-rows">
                    {this.props.owner.ownerType === 'group' &&
                      !this.props.groupState.isGroupRolesLoading &&
                      this.props.groupState.groupRoles.map((item, index) => {
                        let _menuItems = [
                          {
                            onClick: () => {
                              this.showRole(item);
                            },
                            icon: 'fas fa-pencil',
                            label: t('Edit'),
                          },
                          {
                            onClick: (e: any) => {
                              e.preventDefault();
                              this.setState({ confirmDelete: item.id! });
                            },
                            icon: 'fas fa-trash',
                            label: t('Delete'),
                          },
                        ];

                        if (!item.is_user_defined) {
                          _menuItems = [
                            {
                              onClick: () => {
                                this.showRole(item);
                              },
                              icon: 'fas fa-pencil',
                              label: t('Edit'),
                            },
                          ];
                        }
                        if (
                          (this.state.searchValue !== '' &&
                            item.role_name.includes(this.state.searchValue)) ||
                          this.state.searchValue === ''
                        ) {
                          noSearchResults = false;

                          return (
                            <div
                              key={index}
                              className="row"
                            >
                              <div
                                className="col title"
                                notranslate="yes"
                              >
                                {item.role_name}
                              </div>
                              <div className="col actions">
                                <i className="fal fa-ellipsis-h-alt"></i>
                                <ContextMenu
                                  onMouseLeave={() => {}}
                                  showMenu={true}
                                  menuItems={_menuItems}
                                />
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    {this.props.owner.ownerType === 'event' &&
                      !this.props.eventState.isEventRolesLoading &&
                      this.props.eventState.eventRoles &&
                      this.props.eventState.eventRoles.map((item, index) => {
                        let _menuItems = [
                          {
                            onClick: () => {
                              this.showRole(item);
                            },
                            icon: 'fas fa-pencil',
                            label: t('Edit'),
                          },
                          {
                            onClick: (e: any) => {
                              e.preventDefault();
                              this.setState({ confirmDelete: item.id! });
                            },
                            icon: 'fas fa-trash',
                            label: t('Delete'),
                          },
                        ];

                        if (!item.is_user_defined) {
                          _menuItems = [
                            {
                              onClick: () => {
                                this.showRole(item);
                              },
                              icon: 'fas fa-pencil',
                              label: t('Edit'),
                            },
                          ];
                        }
                        if (
                          (this.state.searchValue !== '' &&
                            item.role_name.includes(this.state.searchValue)) ||
                          this.state.searchValue === ''
                        ) {
                          noSearchResults = false;

                          return (
                            <div
                              key={index}
                              className="row"
                            >
                              <div
                                className="col title"
                                notranslate="yes"
                              >
                                {item.role_name}
                              </div>
                              <div className="col actions">
                                <i className="fal fa-ellipsis-h-alt"></i>
                                <ContextMenu
                                  onMouseLeave={() => {}}
                                  showMenu={true}
                                  menuItems={_menuItems}
                                />
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    {noSearchResults && (
                      <div className="empty">Your search returned 0 results.</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.editingRole && !this.state.createNewRole && (
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
              }}
              className="RoleManagement-form"
            >
              <div className="title">
                <i
                  onClick={() => {
                    this.clearRole();
                  }}
                  className="fas fa-arrow-left"
                ></i>
                {!this.state.currentRole.is_user_defined && (
                  <div className="title-inner">
                    <div className="title-wrap">{this.state.currentRole.role_name}</div>
                  </div>
                )}
                {this.state.currentRole.is_user_defined && (
                  <div className="title-inner">
                    <TextField
                      required={true}
                      value={this.state.currentRole.role_name}
                      name="role_name"
                      type="text"
                      onChange={(e: any) => {
                        this.handleRoleNameChange(e);
                      }}
                    />
                  </div>
                )}
              </div>
              {this.props.owner.ownerType === 'group' && (
                <ul className="permissions-wrap">
                  {this.props.settingsState.groupPermissions.map((item, index) => {
                    const permissions =
                      groupRolePermissionTranslations[
                        item as keyof typeof groupRolePermissionTranslations
                      ];
                    return (
                      <li key={index}>
                        <div className="permission-description">
                          <div className="permission-title">{permissions?.title}</div>
                          <p>{permissions?.text}</p>
                        </div>
                        <i
                          onClick={() => {
                            this.updatePermission(item);
                          }}
                          className={
                            this.state.formattedPermissions[item]
                              ? 'fad fa-toggle-on'
                              : 'fad fa-toggle-off'
                          }
                        ></i>
                      </li>
                    );
                  })}
                </ul>
              )}
              {this.props.owner.ownerType === 'event' && (
                <ul className="permissions-wrap">
                  {this.props.settingsState.eventPermissions.map((item, index) => {
                    const permissions =
                      eventRolePermissionTranslations[
                        item as keyof typeof eventRolePermissionTranslations
                      ];
                    return (
                      <li key={index}>
                        <div className="permission-description">
                          <div className="permission-title">{permissions?.title}</div>
                          <p>{permissions?.text}</p>
                        </div>
                        <i
                          onClick={() => {
                            this.updatePermission(item);
                          }}
                          className={
                            this.state.formattedPermissions[item]
                              ? 'fad fa-toggle-on'
                              : 'fad fa-toggle-off'
                          }
                        ></i>
                      </li>
                    );
                  })}
                </ul>
              )}
              <div className="form-actions">
                <Button
                  isDisabled={!this.state.enableSave}
                  icon="fa fa-save"
                  onClick={() => {
                    this.saveChanges();
                  }}
                  text="Save Changes"
                />
              </div>
            </form>
          )}
          {this.state.createNewRole && (
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
              }}
              className="RoleManagement-form"
            >
              <div className="title">
                <i
                  onClick={() => {
                    this.setState({ createNewRole: false }, () => {
                      this.clearRole();
                    });
                  }}
                  className="fas fa-arrow-left"
                ></i>
                <div className="title-inner">
                  <TextField
                    required={true}
                    value={this.state.currentRole.role_name}
                    name="role_name"
                    type="text"
                    onChange={(e: any) => {
                      this.handleRoleNameChange(e);
                    }}
                  />
                </div>
              </div>
              {this.props.owner.ownerType === 'group' && (
                <ul className="permissions-wrap">
                  {this.props.settingsState.groupPermissions.map((item, index) => {
                    const permissions =
                      groupRolePermissionTranslations[
                        item as keyof typeof groupRolePermissionTranslations
                      ];
                    return (
                      <li key={index}>
                        <div className="permission-description">
                          <div className="permission-title">{permissions?.title}</div>
                          <p>{permissions?.text}</p>
                        </div>
                        <i
                          onClick={() => {
                            this.updatePermission(item);
                          }}
                          className={
                            this.state.formattedPermissions[item]
                              ? 'fad fa-toggle-on'
                              : 'fad fa-toggle-off'
                          }
                        ></i>
                      </li>
                    );
                  })}
                </ul>
              )}
              {this.props.owner.ownerType === 'event' && (
                <ul className="permissions-wrap">
                  {this.props.settingsState.eventPermissions.map((item, index) => {
                    const permissions =
                      eventRolePermissionTranslations[
                        item as keyof typeof eventRolePermissionTranslations
                      ];
                    return (
                      <li key={index}>
                        <div className="permission-description">
                          <div className="permission-title">{permissions?.title}</div>
                          <p>{permissions?.text}</p>
                        </div>
                        <i
                          onClick={() => {
                            this.updatePermission(item);
                          }}
                          className={
                            this.state.formattedPermissions[item]
                              ? 'fad fa-toggle-on'
                              : 'fad fa-toggle-off'
                          }
                        ></i>
                      </li>
                    );
                  })}
                </ul>
              )}
              <div className="form-actions">
                <Button
                  isDisabled={!this.state.enableSave}
                  icon="fa fa-save"
                  onClick={() => {
                    this.saveChanges();
                  }}
                  text="Save Changes"
                />
              </div>
            </form>
          )}

          {/* TODO: Switch to <Prompt/> component */}
          <Modal
            contentClassName="confirm-delete-modal"
            show={this.state.confirmDelete !== ''}
            onClose={() => {
              this.setState({ confirmDelete: '' });
            }}
            title={t('Delete Role')}
            description={t(
              'Are you sure you want to delete this role? Any user assigned this role will be set to "Member" role.',
            )}
          >
            <div className="confirm-delete">
              <div className="delete-actions">
                <Button
                  text="Cancel"
                  onClick={() => {
                    this.setState({ confirmDelete: '' });
                  }}
                />
                <Button
                  text="Delete"
                  onClick={() => {
                    this.props.deleteGroupRole(
                      this.props.groupState.group.id!,
                      this.state.confirmDelete,
                    );
                    this.setState({ confirmDelete: '' });
                  }}
                />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    settingsState: store.settingsState,
  };
};

const mapDispatchToProps = {
  getGroupRoles,
  updateGroupRole,
  createGroupRole,
  deleteGroupRole,
  getEventRoles,
  updateEventRole,
  createEventRole,
  deleteEventRole,
  createToast,
};

export default withRouter(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(RoleManagement)),
);
