import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { RouteComponentProps, Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import Portrait from '../../Portrait/Portrait';
import './FundraiserItem.scss';
import { ICampaign, IEventIndividual, IEventTeam } from '@gigit/interfaces';
import { defaultCurrency } from '../../../helpers';
import { IEventState } from '../../../reducers/event';
import { formatCurrency } from '../../../helpers';
import { userSelectors } from '../../../selectors/user';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  fundraiserItem: IEventIndividual | IEventTeam;
  fundraiserItemType: 'team' | 'individual';
  eventState: IEventState;
  locale: string;
}

interface IState {}

class FundraiserItem extends React.Component<IProps, IState> {
  render() {
    const individual = this.props.fundraiserItem as IEventIndividual;
    const team = this.props.fundraiserItem as IEventTeam;

    const currency = this.props.eventState.event.group?.account?.currency ?? defaultCurrency;

    if (this.props.fundraiserItemType === 'team') {
      return (
        <div className="FundraiserItem">
          <div className="item-inner">
            <div className="info">
              <Portrait
                currentImage={team.profile_image_url}
                size={60}
              />
              <div className="info-inner">
                <div
                  className="name"
                  notranslate="yes"
                >
                  <Link to={'/event/' + team.event?.handle + '/team/' + team.handle}>
                    {team.name}
                  </Link>
                </div>
                <div className="raised">
                  <span>
                    <var data-var="raised">
                      {formatCurrency(team.raised!, currency, this.props.locale)}
                    </var>
                  </span>{' '}
                  raised of goal{' '}
                  <var data-var="goal">
                    {formatCurrency(team.goal!, currency, this.props.locale)}
                  </var>
                </div>
              </div>
            </div>
            <div className="bar-wrap">
              <div className="bar">
                <div
                  style={{
                    width:
                      Math.min(
                        99,
                        Math.max(
                          3,
                          Math.round(
                            ((this.props.fundraiserItem.raised || 0) /
                              (this.props.fundraiserItem.goal || 1)) *
                              100,
                          ),
                        ),
                      ) + '%',
                  }}
                  className="bar-inner"
                ></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.props.fundraiserItemType === 'individual') {
      return (
        <div className="FundraiserItem">
          <div className="item-inner">
            <div className="info">
              <Portrait
                currentImage={individual.user?.profile_image_url}
                size={60}
              />
              <div className="info-inner">
                <div
                  className="name"
                  notranslate="yes"
                >
                  <Link
                    to={
                      '/event/' +
                      individual.event?.handle +
                      '/individual/' +
                      individual.user?.handle
                    }
                  >
                    {individual.user?.display_name}
                  </Link>
                </div>
                <div className="raised">
                  <span>
                    <var data-var="raised">
                      {formatCurrency(individual.raised!, currency, this.props.locale)}
                    </var>
                  </span>{' '}
                  raised of goal{' '}
                  <var data-var="goal">
                    {formatCurrency(team.goal!, currency, this.props.locale)}
                  </var>
                </div>
              </div>
            </div>
            <div className="bar-wrap">
              <div className="bar">
                <div
                  style={{
                    width:
                      Math.min(
                        99,
                        Math.max(
                          3,
                          Math.round(
                            ((this.props.fundraiserItem.raised || 0) /
                              (this.props.fundraiserItem.goal || 1)) *
                              100,
                          ),
                        ),
                      ) + '%',
                  }}
                  className="bar-inner"
                ></div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(FundraiserItem),
);
