import './HubManagementInvoiceModal.scss';
import React from 'react';
import Modal from '../../../Modal/Modal';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { userSelectors } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import KambeoLogoWhite from '../../../../assets/kambeo_logo_white.svg';
import KambeoLogoPurple from '../../../../assets/kambeo_logo_purple.svg';
import CardPlainIcon from '../../../../assets/card_plain_icon.svg';
import { capitalizeString } from '../../../../helpers';

export interface IInvoiceModalInfo {
  invoice_number: string;
  date: Date;
  send_to: string;
  product: string;
  status: string;
  card_last_4_digits: string;
  card_name: string;
  num_of_seats: number;
  rate_per_seat: string;
  discount?: number;
  receipt_number: string;
  total: string;
  currency: string;
}

interface IProps {
  show: boolean;
  onClose(): void;
  info: IInvoiceModalInfo;
}

const HubManagementInvoiceModal: React.FC<IProps> = (props) => {
  const { info } = props;
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  return (
    <Modal
      class="HubManagementInvoiceModal"
      show={props.show}
      onClose={props.onClose}
    >
      <div className="container">
        <div className="top">
          <div
            className="logo-info"
            notranslate="yes"
          >
            <div className="logo">
              <img
                src={KambeoLogoWhite}
                alt="kambeo logo"
              />
            </div>
            <div className="info">
              <span>2967 Dundas St. W. #1178</span>
              <span>Toronto, ON</span>
              <span>M6P 1Z2</span>
              <span className="email">support@kambeo.io</span>
            </div>
          </div>
          <div className="invoice-info">
            <div className="invoice">
              <b>Invoice</b>
              <span>
                <var data-var="invoice">{info.invoice_number}</var>
              </span>
            </div>
            <div>
              <b>Date</b>
              <span notranslate="yes">
                {localizeHelpers.formatDate(info.date, LocaleDateFormats.LL, locale)}
              </span>
            </div>
          </div>
        </div>
        <div className="mid">
          <h3>Invoice Details</h3>
          <div className="info">
            <div className="send_to">
              <b>Send to</b>
              <span>
                <var data-var="send_to">{info.send_to}</var>
              </span>
            </div>
            <div className="product">
              <b>Product</b>
              <span>
                <var data-var="send_to">{info.product}</var>
              </span>
            </div>
            <div className="status">
              <b>Status</b>
              <span className={info.status}>{capitalizeString(info.status)}</span>
            </div>
          </div>
          <h5>Payment Method</h5>
          <div className="card-info">
            <img
              src={CardPlainIcon}
              alt="card logo"
            />
            <span>
              <var data-var="card_name">{info.card_name}</var> Ending In ****{' '}
              <var data-var="card_last_4_digits">{info.card_last_4_digits}</var>
            </span>
          </div>
          <table>
            <tr className="head">
              <th>Hubs seats</th>
              <th>Rate</th>
              <th>Period</th>
              <th>Total amount</th>
            </tr>
            <tr>
              <td>
                <span className="item">
                  <var data-var="hubs_seats">{info.num_of_seats}</var> Seats
                </span>
              </td>
              <td>
                <span className="item">
                  <var data-var="rate">{info.rate_per_seat}</var> / Seat
                </span>
              </td>
              <td>
                <span className="item">
                  <var data-var="period">12</var> months
                </span>
              </td>
              <td>
                <span className="item">
                  <var data-var="total_amount">{info.total}</var>
                </span>
              </td>
            </tr>
          </table>
        </div>
        <div
          className="bottom"
          notranslate="yes"
        >
          <img
            src={KambeoLogoPurple}
            alt="kambeo logo purple"
          />
          <div className="info">
            <p>
              © <var data-var="current_year">{new Date().getFullYear()}</var> Kambeo. All rights
              reserved.
              <br />
              No goods or services were provided in exchange for this donation.
            </p>
          </div>
          <span className="page">1/1</span>
        </div>
      </div>
    </Modal>
  );
};

export default HubManagementInvoiceModal;
