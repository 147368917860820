import React, { ReactNode, useEffect } from 'react';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IOwnerObject } from '../../interfaces';
import { authRequestActions, inviteRequestActions } from '../../requestActions';
import { IAppState } from '../../store';
import { errorHelpers, toastError, toastSuccess } from '../../helpers';
import { localizeHelpers } from '../../localizeHelpers';
import { uiConstants } from '../../constants/uiConstants';
import { createToast } from '../../actions/toaster';

interface IProps extends RouteComponentProps {
  action: 'accept' | 'reject';
  owner: IOwnerObject;
}

interface ISearchQuery {
  invite: string;
  email_token: string;
}

/** Component that allows a user to accept or reject an invite.
 * Should be injected into a Group/Event page.
 */
export function InviteAcceptOrReject(props: IProps) {
  const userState = useSelector((state: IAppState) => state.userState);

  const dispatch = useDispatch();

  const handleInvitation = async () => {
    const query = queryString.parse(props.location.search);
    const inviteId = query.invite as string;
    const emailToken = query.email_token as string;
    const owner = props.owner;

    if (!userState.isLoggedIn) {
      // If not logged in, redirect to login page.

      try {
        const isNewUser = query.is_new_user === 'true';
        const redirectUrl = encodeURIComponent(
          `${props.location.pathname}${props.location.search}`,
        );
        props.history.push(`${isNewUser ? '/register' : '/login'}?redirect=${redirectUrl}`);
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, `Redirect to login`);
        dispatch(createToast(toast));
      }

      return;
    }

    if (typeof inviteId !== 'string' || typeof emailToken !== 'string') {
      throw new Error(`Expected inviteid and emailToken to be string`);
    }

    const ownerTypeName =
      props.owner.ownerType === uiConstants.ownerType.group
        ? 'Cause'
        : props.owner.ownerType === uiConstants.ownerType.event
          ? 'Event'
          : props.owner.ownerType === uiConstants.ownerType.hub
            ? 'Hub'
            : 'Unknown';
    const pathParts = props.location.pathname.split('/');
    const finishUrl = pathParts.slice(0, pathParts.length - 1).join('/');

    if (props.action === 'accept') {
      try {
        await inviteRequestActions.acceptInvite(owner.ownerType, owner.ownerId, inviteId);

        const toast = toastSuccess(
          localizeHelpers.translate(`You've been successfully added to the ${ownerTypeName}!`),
          `${ownerTypeName} Invitation`,
        );
        dispatch(createToast(toast));
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, `Accept Invitation To ${ownerTypeName}`);
        dispatch(createToast(toast));
      } finally {
        props.history.push(finishUrl);
      }
    } else if (props.action === 'reject') {
      try {
        await inviteRequestActions.rejectInvite(owner.ownerType, owner.ownerId, inviteId);

        const toast = toastSuccess(
          localizeHelpers.translate(`You've rejected the ${ownerTypeName} invitation.`),
          `${ownerTypeName} Invitation`,
        );
        dispatch(createToast(toast));
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, `Reject Invitation To ${ownerTypeName}`);
        dispatch(createToast(toast));
      } finally {
        props.history.push(finishUrl);
      }
    }
  };

  useEffect(() => {
    handleInvitation();
  }, []);

  return null;
}
