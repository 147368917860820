import React from 'react';
import { handleInputChange } from '../../../../helpers';
import { localizeHelpers } from '../../../../localizeHelpers';
import TextField from '../../../TextField/TextField';

const entityGoalDefault: number = 1000;

interface IProps {
  flow: string;
  type: 'create' | 'join' | 'individual';
  entity: any;

  updateEntity(key: string, val: any): void;
}

interface IState {
  teamName: string;
  goal: number | null;
}

class FundraiseGoal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      teamName: '',
      goal: null,
    };

    this.handleName = this.handleName.bind(this);
    this.onGoalUpdate = this.onGoalUpdate.bind(this);
  }

  componentDidMount() {
    this.initializeData();
  }

  initializeData() {
    if (this.props.entity) {
      this.setState({
        teamName: this.props.entity?.teamName || '',
        goal:
          this.props.type === 'individual'
            ? this.props.entity?.goal || null
            : this.props.entity?.teamGoal || null,
      });
    }
  }

  renderHeaderTxt() {
    let type = this.props.type;

    if (type === 'individual' || type === 'join') {
      return 'What are your individual fundraising goals?';
    } else if (type === 'create') {
      return "What are your team's fundraising goal?";
    }
  }

  handleName() {
    this.props.updateEntity('teamName', this.state.teamName);
  }

  onGoalUpdate() {
    if (this.props.type === 'individual' || this.props.type === 'join') {
      this.props.updateEntity('goal', this.state.goal);
    } else {
      this.props.updateEntity('teamGoal', this.state.goal);
    }
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} ${this.props.type}-info`}>
        {this.renderHeaderTxt()}
        <div className="details-row">
          <TextField
            value={this.state.goal || ''}
            name="goal"
            label="Goal Amount"
            type="number"
            onChange={(e: any) => {
              handleInputChange(e, this, false, this.onGoalUpdate);
            }}
          />
        </div>
        {this.props.type === 'create' && (
          <div className="details-row">
            <TextField
              value={this.state.teamName}
              name="teamName"
              label="Team Name"
              type="text"
              onChange={(e: any) => {
                handleInputChange(e, this, false, this.handleName);
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default FundraiseGoal;
