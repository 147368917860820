import { Reducer } from 'redux';
import { GroupActions, GroupActionTypes } from '../actions/group';
import {
  IGigSummary,
  IRole,
  IPage,
  IPageComponent,
  IEventSummaryFE,
  IForm,
  IFormQuestion,
  IAddress,
  ICampaign,
  IGroupSignature,
  ISponsor,
  ISponsorshipSettings,
  IAuctionPurchaseStats,
  IAuctionStats,
  IDonationStats,
  IStorePurchaseStats,
  IStoreStats,
  IUserRole,
  IGroupDelegation,
  IHub,
  IHubGroupDetails,
  IApplication,
  IVolunteer,
  IDonor,
  ITransactionSummary,
  IDonationSummary,
  IMediaAlbum,
  IUser,
  IPaymentIntentResponse,
  ITransactionItem,
  IGroupSummary,
} from '@gigit/interfaces';

export interface IFullForm extends IForm {
  questions: IFormQuestion[];
}

export interface IGroupPageComponent extends IPageComponent {
  component_objects: any[];
}

export interface IGroupState {
  group: IGroupSummary;
  groupLocations: IAddress[];
  groupCampaigns: ICampaign[];
  groupRoles: IRole[];
  groupPages: IPage[];
  groupEvents: IEventSummaryFE[];
  groupGigs: IGigSummary[];
  groupForms: IFullForm[];
  groupApplications: IApplication[];
  groupVolunteers: IVolunteer[];
  groupDonors: IDonor[];
  groupDonations: ITransactionSummary[];
  groupDonationsPublic: IDonationSummary[];
  groupDonationSubscriptions: ITransactionSummary[];
  groupGigPayments: ITransactionSummary[];
  currentGroupForm: IFullForm;
  groupMediaAlbums: IMediaAlbum[];
  groupSignature: IGroupSignature;
  groupManagers: IUser[];
  groupApplicationsForReview: IApplication[];
  currentPageComponents: IPageComponent[];
  currentUserRole: IRole;
  error: string;
  volunteersLoading: boolean;
  isGroupGigsLoading: boolean;
  isGroupPagesLoading: boolean;
  isGroupRolesLoading: boolean;
  isGroupEventsLoading: boolean;
  isGroupLoading: boolean;
  isCurrentRoleLoading: boolean;
  isHandleLoading: boolean;
  isGroupFormsLoading: boolean;
  isUpdatingGroupForm: boolean;
  isCreatingGroupForm: boolean;
  isGroupApplicationsForReviewLoading: boolean;
  volunteerError: string;
  volunteerSuccess: boolean;
  campaignConnect: any;
  currentCampaign: ICampaign | null;
  donationIntent: IPaymentIntentResponse | null;
  subscriptionSuccess: boolean | null;
  subscriptionError: string;
  donationsLoading: boolean;
  members: IUserRole[];
  isMembersLoading: boolean;
  purchaseSuccess: string | null;
  purchaseError: string;
  purchaseIntent: IPaymentIntentResponse | null;
  groupPurchases: ITransactionSummary[];
  isComponentsLoading: boolean;
  currentTransactionItems: ITransactionItem[];
  receiptNumber: string;
  receiptNumberFormatted: string;
  receiptNumberPreview: string;
  receiptNumberPreviewIsLoading: boolean;
  groupSponsors: Array<ISponsor>;
  groupSponsorshipSettings?: ISponsorshipSettings | null;
  donationsStats: IDonationStats | null;
  storeStats: IStoreStats | null;
  storePurchasesStats: IStorePurchaseStats | null;
  auctionStats: IAuctionStats | null;
  auctionPurchasesStats: IAuctionPurchaseStats | null;
  sponsorsLoading: boolean;
  hubMemberGroups: IHubGroupDetails[];
  groupDelegations: IGroupDelegation[];
  groupHubs: IHub[];
  gigPaymentsLoading: boolean;
  groupPurchasesLoading: boolean;
}

export const initialGroupState: IGroupState = {
  group: {
    id: '',
    title: '',
    handle: '',
    profile_image_url: '',
    cover_image_url: '',
    access_level: '',
    group_type: '',
    charity_id: '',
    created_by: {
      id: '',
      display_name: '',
      handle: '',
      profile_image_url: '',
    },
    updated_by: {
      id: '',
      display_name: '',
      handle: '',
      profile_image_url: '',
    },
    causes: [],
    created_at: new Date(),
    updated_at: new Date(),
  },
  groupLocations: [],
  groupCampaigns: [],
  groupRoles: [],
  groupPages: [],
  groupEvents: [],
  groupGigs: [],
  groupForms: [],
  groupApplications: [],
  groupVolunteers: [],
  groupDonors: [],
  groupDonations: [],
  groupDonationsPublic: [],
  groupDonationSubscriptions: [],
  groupGigPayments: [],
  groupSignature: {
    //group_handle: '',
    group_id: '',
    signature: '',
  },
  groupManagers: [],
  groupApplicationsForReview: [],
  currentGroupForm: {
    questions: [],
    owner_type: '',
    owner_id: '',
    owner_parent_type: '',
    owner_parent_id: '',
    form_name: '',
  },
  groupMediaAlbums: [],
  currentPageComponents: [],
  currentUserRole: {
    id: '',
    role_name: '',
    is_user_defined: false,
    permissions: [],
    created_at: new Date(),
    updated_at: new Date(),
  },
  error: '',
  volunteersLoading: false,
  isGroupGigsLoading: false,
  isGroupPagesLoading: false,
  isGroupRolesLoading: false,
  isGroupEventsLoading: false,
  isGroupLoading: false,
  isCurrentRoleLoading: false,
  isHandleLoading: false,
  isGroupFormsLoading: false,
  isUpdatingGroupForm: false,
  isCreatingGroupForm: false,
  isGroupApplicationsForReviewLoading: false,
  volunteerError: '',
  volunteerSuccess: false,
  campaignConnect: false,
  currentCampaign: null,
  donationIntent: null,
  subscriptionSuccess: null,
  subscriptionError: '',
  donationsLoading: false,
  members: [],
  isMembersLoading: false,
  purchaseSuccess: '',
  purchaseError: '',
  purchaseIntent: null,
  groupPurchases: [],
  isComponentsLoading: false,
  currentTransactionItems: [],
  receiptNumber: '',
  receiptNumberFormatted: '',
  receiptNumberPreview: '',
  receiptNumberPreviewIsLoading: false,
  groupSponsors: [],
  groupSponsorshipSettings: null,
  sponsorsLoading: false,
  hubMemberGroups: [],
  groupDelegations: [],
  groupHubs: [],
  gigPaymentsLoading: false,
  groupPurchasesLoading: false,
  auctionPurchasesStats: null,
  auctionStats: null,
  donationsStats: null,
  storePurchasesStats: null,
  storeStats: null,
};

export const groupReducer: Reducer<IGroupState, GroupActions> = (
  state = initialGroupState,
  action,
) => {
  switch (action.type) {
    case GroupActionTypes.UPDATE_GROUP: {
      return {
        ...state,
        ...action,
      };
    }
    case GroupActionTypes.UPDATE_GROUP_PAGES: {
      return {
        ...state,
        ...action,
      };
    }
    case GroupActionTypes.UPDATE_GROUP_LOCATIONS: {
      return {
        ...state,
        ...action,
      };
    }
    case GroupActionTypes.UPDATE_GROUP_CAMPAIGNS: {
      return {
        ...state,
        ...action,
      };
    }
    case GroupActionTypes.UPDATE_GROUP_APPLICATIONS: {
      return {
        ...state,
        ...action,
      };
    }
    case GroupActionTypes.UPDATE_GROUP_VOLUNTEERS: {
      return {
        ...state,
        ...action,
      };
    }
    case GroupActionTypes.UPDATE_GROUP_EVENTS: {
      return {
        ...state,
        ...action,
      };
    }
    case GroupActionTypes.UPDATE_GROUP_GIGS: {
      return {
        ...state,
        ...action,
      };
    }
    case GroupActionTypes.UPDATE_GROUP_SIGNATURE: {
      return {
        ...state,
        ...action,
      };
    }
    case GroupActionTypes.UPDATE_GROUP_PURCHASES: {
      return {
        ...state,
        ...action,
      };
    }
    case GroupActionTypes.GET_GROUP_SPONSORS: {
      return {
        ...state,
        ...action,
      };
    }
    case GroupActionTypes.UPDATE_GROUP_INVITATIONS: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
