import React from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Redirect } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { routes, toastError, toastSuccess } from '../../helpers';
import { IAppState } from '../../store';

import { IUserState } from '../../reducers/user';
import { IRegisterState } from '../../reducers/register';
import { IEventState } from '../../reducers/event';

import { createToast } from '../../actions/toaster';
import { getUserInfo } from '../../actions/user';

import Loader from '../../components/Loader/Loader';

import './TwitchConnect.scss';
import { IToast } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  createToast(toast: IToast): void;
  getUserInfo(handle: string, cb?: any): void;
  userState: IUserState;
  registerState: IRegisterState;
  eventState: IEventState;
}

interface IState {
  redirect: boolean;
}

class TwitchConnect extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      redirect: false,
    };

    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
  }

  componentDidMount() {
    let _params = queryString.parse(this.props.location.search);

    if (_params.code && _params.scope && _params.state) {
      Axios.get(routes.ACCOUNTS.AUTHORIZE_TWITCH + this.props.location.search)
        .then((response) => {
          if (response.data.value === 'ok') {
            this.showSuccess();

            this.props.getUserInfo(this.props.userState.user.handle);

            this.setState({
              redirect: true,
            });
          }
        })
        .catch((error) => {
          this.showError();

          this.setState({
            redirect: true,
          });
        });
    } else {
      this.showError();

      this.setState({
        redirect: true,
      });
    }
  }

  showError() {
    const toast = toastError(
      localizeHelpers.translate(
        'Authorization failed, Twitch account was not connected. Please try again.',
      ),
      'Twitch Account',
    );
    this.props.createToast(toast);
  }

  showSuccess() {
    const toast = toastSuccess(
      localizeHelpers.translate('Twitch account successfully connected.'),
      'Twitch Account',
    );
    this.props.createToast(toast);
  }

  render() {
    if (this.state.redirect) {
      if (this.props.registerState?.flowConfig.type) {
        let event = this.props.eventState.event.handle;
        return <Redirect to={`/setup/fundraise?event=${event}&currentStep=isContentCreator`} />;
      } else {
        return <Redirect to="/dashboard?section=settings" />;
      }
    } else {
      return (
        <div className="TwitchConnect">
          <Loader loading={true} />
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    registerState: store.registerState,
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {
  createToast,
  getUserInfo,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(TwitchConnect),
);
