import { Reducer } from 'redux';
import { SettingsActions, SettingsActionTypes } from '../actions/settings';
import { Config } from '@gigit/config';

export interface ISettingsState {
  darkMode: boolean;
  groupPermissions: string[];
  eventPermissions: string[];
  cookies: boolean;
  version: string;
  collapsedSidebars: string[];
  showReleaseModal: boolean;
}

export const initialSettingsState: ISettingsState = {
  darkMode: false,
  groupPermissions: [],
  eventPermissions: [],
  cookies: false,
  version: Config.web.REACT_APP_STATE_VERSION,
  collapsedSidebars: [],
  showReleaseModal: false,
};

export const settingsReducer: Reducer<ISettingsState, SettingsActions> = (
  state = initialSettingsState,
  action,
) => {
  switch (action.type) {
    case SettingsActionTypes.UPDATE_SETTINGS: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
