import { useDispatch } from 'react-redux';
import { createToast } from '../actions/toaster';
import { toastError, toastSuccess, toastInfo, errorHelpers } from '../helpers';
import { IToastAction } from '../interfaces';

/**
 * Dispatches toasts with one line of code!
 */
//TODO: Create dispatch actions for other toast types
function useToastDispatcher() {
  const dispatch = useDispatch();

  /**
     * Handles converting unknown error type into IAppError, then dispatches an error toast.
     * @param error Unknown error from our API which will be converted to type IAppError
     * @param untranslatedTitle Untranslated title of the message
     * @param actionList
     *
     * @example
        const toastBread = await actionRequest
            .toastBread()
            .catch((error) => dispatchToastError(error, "Bread un toast-able"));
     */
  function dispatchToastError(
    error: unknown,
    untranslatedTitle: string,
    actionList?: IToastAction[],
  ) {
    const errorObj = errorHelpers.getErrorObject(error);
    const toast = toastError(errorObj.translatedMessage, untranslatedTitle, actionList);

    dispatch(createToast(toast));
  }

  /**
     * Creates and dispatches a success toast
     * @param translatedMessage Translated message
     * @param untranslatedTitle Untranslated title of the message
     * @param actionList
     *
     * @example
        dispatchToastSuccess(localizeHelpers.translate('Hello World'), "Title");
     */
  function dispatchToastSuccess(
    translatedMessage: string,
    untranslatedTitle: string,
    actionList?: IToastAction[],
  ) {
    const toast = toastSuccess(translatedMessage, untranslatedTitle, actionList);

    dispatch(createToast(toast));
  }

  /**
     * Creates and dispatches a success toast
     * @param translatedMessage Translated message
     * @param untranslatedTitle Untranslated title of the message
     * @param actionList
     *
     * @example
     dispatchToastInfo(localizeHelpers.translate('Hello World'), "Title");
     */
  function dispatchToastInfo(
    translatedMessage: string,
    untranslatedTitle: string,
    actionList?: IToastAction[],
  ) {
    const toast = toastInfo(translatedMessage, untranslatedTitle, actionList);

    dispatch(createToast(toast));
  }

  return { dispatchToastError, dispatchToastSuccess, dispatchToastInfo };
}

export default useToastDispatcher;
