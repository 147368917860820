import React, { useState, useEffect } from 'react';
import ListCard from '../../Shared/ListCard/ListCard';
import './HubAuctionSingleItem.scss';
import { IAuctionItemSummary, IGroup } from '@gigit/interfaces';
import { groupRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import { Link } from 'react-router-dom';
import { defaultCurrency, formatCurrency } from '../../../../helpers';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { connect } from 'react-redux';
import moment from 'moment';
import PopupMenu, { IPopupMenuItem } from '../../../shared/PopupMenu/PopupMenu';

interface IProps {
  auctionItem: IAuctionItemSummary;
  openAuctionItemDetailModal?: () => void;
  locale: string;
  editMode?: boolean;
  editAuctionItem?: (item: IAuctionItemSummary) => void;
  onDeleteItemClicked?: (item: IAuctionItemSummary) => void;
}

const HubAuctionSingleItem: React.FC<IProps> = (props) => {
  const [currency, setCurrency] = useState<string>(defaultCurrency);
  const [groupInfo, setGroupInfo] = useState<IGroup | null>(null);
  const [showEditMenu, setShowEditMenu] = useState<boolean>(false);

  //time remaining
  const [isActive, setIsActive] = useState<boolean>(
    new Date(props.auctionItem.end_date).getTime() - new Date().getTime() > 0,
  );
  const secondsInDay = 86400;
  const secondsInHour = 3600;
  const secondsInMinute = 60;
  const hoursInDay = 24;
  const tmpTimer =
    parseInt(moment(props.auctionItem.end_date).format('X')) -
    parseInt(moment(new Date()).format('X'));

  const [tick, setTick] = useState<ReturnType<typeof setInterval> | null>(null);
  const [timeRemainingDays, setTimeRemainingDays] = useState<number | null>(
    isActive ? Math.floor(tmpTimer / secondsInDay) : null,
  );
  const [timeRemainingHours, setTimeRemainingHours] = useState<number | null>(
    isActive
      ? Math.floor(tmpTimer / secondsInHour) - Math.floor(tmpTimer / secondsInDay) * hoursInDay
      : null,
  );
  const [timeRemainingMinutes, setTimeRemainingMinutes] = useState<number | null>(
    isActive
      ? Math.floor(tmpTimer / secondsInMinute) -
          Math.floor(tmpTimer / secondsInHour) * secondsInMinute
      : null,
  );
  const [timeRemainingSeconds, setTimeRemainingSeconds] = useState<number | null>(
    isActive ? tmpTimer - Math.floor(tmpTimer / secondsInMinute) * secondsInMinute : null,
  );
  const { dispatchToastError } = useToastDispatcher();

  function isAuctionItemVisible(): boolean {
    //TODO: missing BE
    return true;
  }

  function isAuctionItemPined(): boolean {
    //TODO: missing BE
    return true;
  }

  function togglePinedItem() {
    //TODO: missing BE
  }

  function toggleItemVisible() {
    //TODO: missing BE
  }

  const editMenuItems: IPopupMenuItem[] = [
    {
      id: 'edit',
      label: 'Edit',
      onClick: () => {
        props?.editAuctionItem?.(props.auctionItem);
      },
    },
    {
      id: 'delete',
      label: 'Delete',
      onClick: () => {
        props?.onDeleteItemClicked?.(props.auctionItem);
      },
    },
    {
      id: 'hide_show_item',
      label: `${isAuctionItemVisible() ? 'Hide' : 'Show'} Item`,
      onClick: () => {
        togglePinedItem();
      },
    },
    {
      id: 'pin_item',
      label: `${isAuctionItemPined() ? 'Unpin' : 'Pin'} Item`,
      onClick: () => {
        togglePinedItem();
      },
    },
  ];

  async function getGroupInfo() {
    try {
      const result = await groupRequestActions.getGroupByHandleOrId(props.auctionItem.group_id);
      setGroupInfo(result);

      if (result?.account?.currency) {
        setCurrency(result.account.currency);
      }
    } catch (error) {
      dispatchToastError(error, 'Get Cause Info');
    }
  }

  useEffect(() => {
    getGroupInfo();
  }, [props.auctionItem]);

  useEffect(() => {
    if (!tick && isActive && !props?.editMode) {
      setTick(
        setInterval(() => {
          const timer =
            parseInt(moment(props.auctionItem.end_date).format('X')) -
            parseInt(moment(new Date()).format('X'));

          setTimeRemainingDays(Math.floor(timer / secondsInDay));
          setTimeRemainingHours(
            Math.floor(timer / secondsInHour) - Math.floor(timer / secondsInDay) * hoursInDay,
          );
          setTimeRemainingMinutes(
            Math.floor(timer / secondsInMinute) -
              Math.floor(timer / secondsInHour) * secondsInMinute,
          );
          setTimeRemainingSeconds(timer - Math.floor(timer / secondsInMinute) * secondsInMinute);

          setIsActive(new Date(props.auctionItem.end_date).getTime() - new Date().getTime() > 0);
        }, 1000),
      );
    }

    return () => {
      if (tick) {
        clearInterval(tick);
        setTick(null);
        setTimeRemainingDays(null);
        setTimeRemainingHours(null);
        setTimeRemainingMinutes(null);
        setTimeRemainingSeconds(null);
      }
    };
  }, [tick]);

  return (
    <>
      <div
        className={`HubAuctionSingleItem ${!isAuctionItemVisible() ? 'low-opacity' : ''}`}
        onClick={() => props.openAuctionItemDetailModal?.()}
      >
        <ListCard
          cardData={{
            cover_image_url: props.auctionItem.media[0]?.url || '',
            profile_image_url: groupInfo?.profile_image_url || '',
          }}
        >
          {props?.editMode && (
            <>
              <div className="added-icons">
                {isAuctionItemPined() && (
                  <span
                    className="pined"
                    onClick={() => togglePinedItem()}
                  >
                    <i className="far fa-thumbtack" />
                  </span>
                )}

                {!isAuctionItemVisible() && (
                  <span
                    className="not-visible"
                    onClick={() => toggleItemVisible()}
                  >
                    <i className="far fa-eye-slash" />
                  </span>
                )}
              </div>

              <PopupMenu
                showMenuConfig={{
                  showMenu: showEditMenu,
                  setShowMenu: setShowEditMenu,
                  position: {
                    type: 'bottom',
                  },
                }}
                menuItems={editMenuItems}
                className={`Dropdown edit-menu ${showEditMenu ? 'show' : ''}`}
                onClick={() => setShowEditMenu(true)}
              >
                <i className="fas fa-ellipsis-v" />
              </PopupMenu>
            </>
          )}

          {!props?.editMode && (
            <span className="time-remaining">
              {isActive && (
                <var data-var="time_remaining">
                  <span className={!timeRemainingDays ? 'passed' : ''}>{timeRemainingDays}</span>:
                  <span className={!timeRemainingHours ? 'passed' : ''}>{timeRemainingHours}</span>:
                  <span className={!timeRemainingMinutes ? 'passed' : ''}>
                    {timeRemainingMinutes}
                  </span>
                  :<span>{timeRemainingSeconds}</span>
                </var>
              )}
              {!isActive && 'Closed'}
            </span>
          )}

          <div className="auction-item-content">
            <span
              className="title"
              notranslate="yes"
            >
              {props.auctionItem.name}
            </span>
            <span
              className="owner"
              notranslate="yes"
            >
              <Link to={`/${props.auctionItem.owner_type}/${props.auctionItem.owner_handle}`}>
                {props.auctionItem.owner_title}
              </Link>
            </span>

            {!props?.editMode && (
              <>
                {/*<span className={`price ${props.auctionItem?.locked? 'locked': ''}`}>*/}
                <span className="price">
                  {`Current bid : `}
                  <span className="red">
                    <var data-var="bid_amount">
                      {formatCurrency(
                        props.auctionItem?.current_bid?.bid_amount || 0,
                        currency,
                        props.locale,
                      )}
                    </var>
                  </span>
                </span>
                <span className="buy-now">
                  {`Buy now : `}
                  <var data-var="buy_now_amount">
                    {formatCurrency(
                      Number(props.auctionItem?.buy_now_price || 0),
                      currency,
                      props.locale,
                    )}
                  </var>
                  {
                    // props.auctionItem?.locked &&
                    // <i className="fas fa-lock-alt"/>
                  }
                </span>
              </>
            )}
          </div>
        </ListCard>
      </div>
    </>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HubAuctionSingleItem);
