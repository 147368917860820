import React, { Fragment } from 'react';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import {
  IAddressBase,
  IAuctionItemBid,
  IAuctionItemSummary,
  IAuctionPayment,
  ICampaign,
  IFeeControl,
  IPaymentMethod,
  ITransactionAmounts,
} from '@gigit/interfaces';
import { Config } from '@gigit/config';
import { AuctionItemOwnerType } from '../../../../actions/auction';
import {
  formatCurrency,
  handleInputChange,
  defaultCurrency,
  formatAddressLine,
  swapRouteParams,
  routes,
  toastError,
  toastSuccess,
  typeHelpers,
  formatCardIcon,
} from '../../../../helpers';
import Button from '../../../Button/Button';
import Loader from '../../../Loader/Loader';
import Modal from '../../../Modal/Modal';
import ModalHeader from '../../../Modal/ModalHeader/ModalHeader';
import PaymentMethodsForm from '../../../PaymentMethods/PaymentMethodsForm/PaymentMethodsForm';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import ToggleButton from '../../../shared/ToggleButton/ToggleButton';
import TextField from '../../../TextField/TextField';
import auctionItemHelpers from '../auctionItemHelpers';
import ModalManageField from '../ModalManageField/ModalManageField';
import { connect } from 'react-redux';
import { userSelectors } from '../../../../selectors/user';
import { IAppState } from '../../../../store';
import Axios from 'axios';
import errorHelpers from '../../../../helpers/errorHelpers';
import { createToast } from '../../../../actions/toaster';
import { updateUser, updateUserEmail } from '../../../../actions/user';
import './AuctionItemPlaceBidModal.scss';
import { IToast } from '../../../../interfaces';
import { localizeHelpers } from '../../../../localizeHelpers';
import moment from 'moment';
import { Tooltip } from '../../../shared/Tooltip/Tooltip';
import { IGroupState } from '../../../../reducers/group';
import { IEventState } from '../../../../reducers/event';
import { CheckoutLocale, loadStripe, Stripe } from '@stripe/stripe-js';
import BidSuccessfulImg from '../../../../assets/place-bid-successful.png';
import EmailIcon from '../../../../assets/icon-email.svg';
import PhoneIcon from '../../../../assets/icon-phone.svg';
import NextIcon from '../../../../assets/Icon-next.svg';
import { IUserState } from '../../../../reducers/user';
import { IOwnerObject } from '../../../../interfaces';

export type BidModalTab = 'bid' | 'buy_now';

interface IProps {
  groupState: IGroupState;
  eventState: IEventState;
  userState: IUserState;
  locale: string;

  owner: IOwnerObject;
  showBidModal: boolean;
  activeTab: BidModalTab;
  item: IAuctionItemSummary;
  currentImage: number;

  onClose(): void;
  setActiveTab(newTab: BidModalTab): void;
  setCurrentImage(newImageIndex: number): void;
  onBidPlaced(): void;
  updateUser(payload: any): void;
  updateUserEmail(payload: any): void;
  onBuyNowClicked(): void;
  onFinish(): void;

  createToast(toast: IToast): void;
}

interface IState {
  userBid: string;
  isBidSumLoading: boolean;
  shipOption: string;
  fees: ITransactionAmounts | null;
  feeControl: IFeeControl | null | undefined;

  timerInterval: any;
  timer: number;

  anonymous: boolean;
  automatic: boolean;
  autoIncrement: string;
  autoMax: string;
  showPlaceBidSuccessScreen: boolean;
  manageField: {
    fieldLabel: string;
    fieldValue: string;
  } | null;
  isPaymentMethodsLoading: boolean;
  paymentMethods: IPaymentMethod[];
  showPlaceBidContent: boolean;
  showSelectACardContent: boolean;
  showNewPaymentMethodForm: boolean;
  showShippingAndPickupOptions: boolean;

  /** This is a platform account stripe instance. */
  stripePromise: Promise<Stripe | null> | null;
  email: string;
  phone: string;
}

/** Modal Component for allowing a user to place a bid on a auction item. */
class AuctionItemPlaceBidModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      // activeTab: 0,
      userBid: this.props.item.current_bid?.bid_amount
        ? (this.props.item.current_bid.bid_amount + (this.props.item.minimum_bid_increment ?? 0))
            .toFixed(2)
            .toString()
        : (this.props.item.minimum_start_bid ?? 0).toFixed(2).toString(),
      isBidSumLoading: false,
      shipOption: this.props.item.is_shipping_available
        ? 'ship'
        : this.props.item.is_pickup_available &&
            this.props.item.pickup_locations &&
            this.props.item.pickup_locations.length > 0
          ? formatAddressLine(this.props.item.pickup_locations[0])
          : '',
      fees: null,
      feeControl: null,
      timerInterval: null,
      timer: -1,
      anonymous: false,
      automatic: false,
      autoIncrement: (this.props.item.minimum_bid_increment ?? 0).toString(),
      autoMax: '0',
      showPlaceBidSuccessScreen: false,
      manageField: null,
      isPaymentMethodsLoading: false,
      paymentMethods: [],
      showPlaceBidContent: true,
      showSelectACardContent: false,
      showNewPaymentMethodForm: false,
      showShippingAndPickupOptions: false,
      stripePromise: null,
      email: this.props.userState.user.email || '',
      phone: this.props.userState.user.phone || '',
    };
  }

  componentDidMount() {
    if (this.props.userState.isLoggedIn) {
      this.getPaymentMethods();
    }

    this.setState({
      feeControl: typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'event', 'group')?.fee_control,
    });

    this.setState({
      // Create Stripe instance. We use the platform account because this is used for creating "shared" saved payment method.
      stripePromise: loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY, {
        locale: this.props.locale as CheckoutLocale,
      }),
    });
  }

  componentWillUnmount() {
    if (this.state.timerInterval !== null) {
      clearInterval(this.state.timerInterval);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.showBidModal !== prevProps.showBidModal ||
      this.props.activeTab !== prevProps.activeTab ||
      this.state.shipOption !== prevState.shipOption
    ) {
      if (this.props.activeTab === 'bid') {
        this.getBidSummary();
      }

      if (this.props.activeTab === 'buy_now') {
        auctionItemHelpers.getBuyNowPaymentSummary(
          this.props.owner.ownerType as AuctionItemOwnerType,
          this.props.owner.ownerId,
          this.props.item.id!,
          {
            ship_by_vendor:
              this.props.item.is_shipping_available && this.state.shipOption === 'ship',
            payment_method: 'card',
          },
        );
      }
    }

    if (this.props.showBidModal && this.state.timerInterval === null) {
      this.setState({
        timer:
          parseInt(moment(this.props.item.end_date).format('X')) -
          parseInt(moment(new Date()).format('X')),
        timerInterval: setInterval(() => {
          this.setState({
            timer:
              parseInt(moment(this.props.item.end_date).format('X')) -
              parseInt(moment(new Date()).format('X')),
          });
        }, 1000),
      });
    }

    if (!this.props.showBidModal && this.state.timerInterval !== null) {
      clearInterval(this.state.timerInterval);

      this.setState({
        timerInterval: null,
        timer: -1,
      });
    }

    if (
      this.props.userState.user.email !== prevProps.userState.user.email &&
      this.props.userState.isLoggedIn
    ) {
      this.setState({
        email: this.props.userState.user.email,
      });

      const toast = toastSuccess(
        localizeHelpers.translate('Email successfully updated.'),
        'Account Settings',
      );
      this.props.createToast(toast);
    }

    if (
      this.props.userState.user.phone !== prevProps.userState.user.phone &&
      this.props.userState.isLoggedIn
    ) {
      this.setState({
        phone: this.props.userState.user.phone || '',
      });

      const toast = toastSuccess(
        localizeHelpers.translate('Phone Number successfully updated.'),
        'Account Settings',
      );
      this.props.createToast(toast);
    }
  }

  hasShippingOptions() {
    return this.props.item.is_pickup_available || this.props.item.is_shipping_available;
  }

  getPickupOptionName() {
    const pickupLocation = this.props.item.pickup_locations?.find(
      (location) => formatAddressLine(location) === this.state.shipOption,
    );

    return pickupLocation?.title ?? formatAddressLine(pickupLocation);
  }

  handleUpdateUserField = (fieldValue: string) => {
    const { manageField } = this.state;

    if (manageField?.fieldLabel === 'Email') {
      this.props.updateUserEmail({ value: fieldValue });
    } else if (manageField?.fieldLabel === 'Phone') {
      this.props.updateUser({ phone: fieldValue });
    }

    this.setState({ manageField: null, showPlaceBidSuccessScreen: true });
  };

  getMinimumBid() {
    if (this.props.item.current_bid && this.props.item.current_bid.bid_amount) {
      return (
        this.props.item.current_bid.bid_amount + (this.props.item.minimum_bid_increment ?? 0)
      ).toString();
    }

    return (this.props.item.minimum_start_bid ?? 0).toString();
  }

  getPaymentMethods = () => {
    this.setState(
      {
        isPaymentMethodsLoading: true,
      },
      () => {
        Axios.get<IPaymentMethod[]>(routes.GET_PAYMENT_METHODS)
          .then((response) => {
            this.setState({
              paymentMethods: response.data,
            });
          })
          .finally(() => {
            this.setState({
              isPaymentMethodsLoading: false,
            });
          });
      },
    );
  };

  getDefaultPaymentMethod = () => {
    const { paymentMethods } = this.state;

    return paymentMethods.find((paymentMethod) => paymentMethod.is_default);
  };

  renderSelectCardHeader = (headerLabel: string, onGoBack: () => void) => {
    return (
      <div className="header">
        <i
          className="fa fa-chevron-left back"
          onClick={onGoBack}
        />
        <span className="header-label">{headerLabel}</span>
        <div className="spacer" />
      </div>
    );
  };

  setPaymentMethodAsDefault = (paymentMethodId: string) => {
    Axios.post(swapRouteParams(routes.SET_PAYMENT_METHOD_AS_DEFAULT, { id: paymentMethodId }))
      .then((response) => {
        this.getPaymentMethods();

        this.props.createToast(
          toastSuccess(
            localizeHelpers.translate('Primary payment method changed'),
            'Primary Payment Method',
          ),
        );
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Set Primary Payment Method');
        this.props.createToast(toast);
      });
  };

  getBidSummary() {
    this.setState({
      isBidSumLoading: true,
    });

    let _payload: IAuctionPayment = {
      payment_method: 'card',
      amount: parseFloat(this.state.userBid),
      ship_by_vendor: this.props.item.is_shipping_available && this.state.shipOption === 'ship',
    };

    let route = routes.BID_SUMMARY as string;
    let idField = 'groupId';

    if (this.props.owner.ownerType === 'event') {
      route = routes.EVENT_BID_SUMMARY;
      idField = 'eventId';
    }

    Axios.post(
      swapRouteParams(route, { [idField]: this.props.owner.ownerId, item_id: this.props.item.id }),
      _payload,
    )
      .then((response) => {
        this.setState(
          {
            fees: response.data,
          },
          () => {
            this.setState({
              isBidSumLoading: false,
            });
          },
        );
      })
      .catch((err) => {
        const errObj = errorHelpers.getErrorObject(err);
        const toast = toastError(errObj.translatedMessage, 'Get Bid Summary');
        this.props.createToast(toast);
      });
  }

  async makeBid() {
    let _payload: IAuctionItemBid = {
      bid_amount: parseFloat(this.state.userBid),
      automatic: this.state.automatic,
      anonymous: this.state.anonymous,
      ship_by_vendor: this.props.item.is_shipping_available && this.state.shipOption === 'ship',
    };

    if (
      this.state.shipOption != 'ship' &&
      this.state.shipOption != '' &&
      this.props.item.is_pickup_available &&
      this.props.item.pickup_locations?.length
    ) {
      const pickupLocation = this.props.item.pickup_locations?.find(
        (location) => formatAddressLine(location) === this.state.shipOption,
      );
      if (!pickupLocation) {
        throw new Error(`Could not find pickup location ${this.state.shipOption} in auction item`);
      }

      _payload.pickup_location = pickupLocation;
    }

    if (this.state.automatic) {
      _payload.automatic_bid_increment = parseFloat(this.state.autoIncrement);
      _payload.maximum_automatic_amount = parseFloat(this.state.autoMax);
    }

    if (!this.props.item.id) {
      throw new Error('Expected a item id');
    }

    try {
      await auctionItemHelpers.bidOnAuctionItem(
        this.props.owner.ownerType as AuctionItemOwnerType,
        this.props.owner.ownerId,
        this.props.item.id,
        _payload,
      );
      this.props.onBidPlaced();

      this.setState({ showPlaceBidSuccessScreen: true });
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      this.props.createToast(toastError(errObj.translatedMessage, 'Place Bid'));
    }
  }

  render() {
    const secondsInDay = 86400;
    const secondsInHour = 3600;
    const secondsInMinute = 60;
    const hoursInDay = 24;

    const currency = this.props.owner.account?.currency ?? defaultCurrency;

    const lockBuyNow =
      this.props.item.buy_now_price && this.props.item.current_bid
        ? this.props.item.buy_now_price <
          this.props.item.current_bid.bid_amount + (this.props.item.minimum_bid_increment || 0)
        : false;

    const t = (s: string) => s;

    if (!this.props.item) {
      return null;
    }

    return (
      <Modal
        class="AuctionItemPlaceBidModal"
        show={this.props.showBidModal}
        onClose={(e) => {
          e.stopPropagation();
          this.props.onClose();
        }}
      >
        <ModalHeader
          title={this.props.activeTab === 'bid' ? 'Place Bid' : 'Buy Now'}
          className="modal-header"
          onClose={() => this.props.onClose()}
        />

        <div className={`place-bid ${this.state.showPlaceBidContent ? 'show' : 'hidden'}`}>
          {/* Tabs only displayed in desktop view. */}
          {this.props.item.buy_now_price && this.props.item.buy_now_price > 0 && (
            <div className="tabs">
              <div
                onClick={() => this.props.setActiveTab('bid')}
                className={
                  this.props.activeTab === 'bid' ? 'no-select tab active' : 'no-select tab'
                }
              >
                <span>Place Bid</span>
              </div>
              {!this.state.showPlaceBidSuccessScreen && !this.state.manageField && (
                <div
                  onClick={() => this.props.setActiveTab('buy_now')}
                  className={
                    this.props.activeTab === 'buy_now' ? 'no-select tab active' : 'no-select tab'
                  }
                >
                  <span>Buy Now</span>
                </div>
              )}
            </div>
          )}

          {this.props.activeTab === 'bid' &&
            !this.state.showPlaceBidSuccessScreen &&
            this.state.manageField == null && (
              <div className="place-bid-inner">
                <div className="cover">
                  {this.props.item.media && this.props.item.media.length > 0 && (
                    <img
                      alt={'cover image ' + this.props.item.name}
                      src={this.props.item.media[this.props.currentImage].url}
                    />
                  )}
                  <div className="curve"></div>
                </div>
                {this.props.item.media && this.props.item.media.length > 0 && (
                  <ul className="media-nav">
                    {this.props.item.media.map((item: any, index: number) => {
                      return (
                        <li
                          key={index}
                          className={this.props.currentImage === index ? 'active' : ''}
                          onClick={() => this.props.setCurrentImage(index)}
                        >
                          <i
                            className={
                              this.props.currentImage === index ? 'fas fa-circle' : 'far fa-circle'
                            }
                          ></i>
                        </li>
                      );
                    })}
                  </ul>
                )}
                {this.props.item.media && this.props.item.media.length > 0 && (
                  <div
                    style={{ backgroundImage: 'url(' + this.props.item.media[0].url + ')' }}
                    className="portrait-auction"
                  ></div>
                )}
                {this.state.timer >= 0 && (
                  <div className="timer">
                    <div className="days">
                      <i className="far fa-clock"></i>
                      <var data-var="days">{Math.floor(this.state.timer / secondsInDay)}</var>{' '}
                      {t('Days')}
                    </div>
                    <div className="hours">
                      <var data-var="hours">
                        {Math.floor(this.state.timer / secondsInHour) -
                          Math.floor(this.state.timer / secondsInDay) * hoursInDay}
                      </var>{' '}
                      {t('Hours')}
                    </div>
                    <div className="minutes">
                      <var data-var="minutes">
                        {Math.floor(this.state.timer / secondsInMinute) -
                          Math.floor(this.state.timer / secondsInHour) * secondsInMinute}
                      </var>{' '}
                      {t('Minutes')}
                    </div>
                    <div className="seconds">
                      <var data-var="seconds">
                        {this.state.timer -
                          Math.floor(this.state.timer / secondsInMinute) * secondsInMinute}
                      </var>{' '}
                      {t('Seconds')}
                    </div>
                  </div>
                )}
                {(this.props.item.is_shipping_available || this.props.item.is_pickup_available) && (
                  <div className="shipping-option-label">
                    <span className="label">{t('Item available for ')}</span>
                    {this.props.item.is_shipping_available && (
                      <div className="option">
                        <i className="fa fa-check available-icon" />
                        <span className="label"> {t('Shipping')} </span>
                      </div>
                    )}
                    {this.props.item.is_pickup_available && (
                      <div className="option">
                        <i className="fa fa-check available-icon" />
                        <span className="label"> {t('Pick-up')} </span>
                      </div>
                    )}
                  </div>
                )}
                <div></div>
                <div className="details">
                  <div
                    className="name"
                    notranslate="yes"
                  >
                    {this.props.item.name}
                  </div>
                  <div className="current">
                    <div className="leading-bid">
                      <div className="label">{t('Current Bid')}: </div>
                      <div
                        className="value"
                        notranslate="yes"
                      >
                        {formatCurrency(
                          this.props.item.current_bid?.bid_amount ||
                            this.props.item.minimum_start_bid ||
                            0,
                          currency,
                          this.props.locale,
                        )}
                      </div>
                    </div>
                    <div className="bid-amt">
                      <div className="value">
                        <var data-var="number_of_bidders">
                          {this.props.item.bidders?.length || 0}
                        </var>
                      </div>
                      <div className="label">{t('Bidders')}</div>
                    </div>
                  </div>
                </div>
                <form
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    this.makeBid();
                  }}
                  className="user-bid"
                >
                  <TextField
                    icon="far fa-dollar-sign"
                    label={t('Your Bid')}
                    type="number"
                    step=".01"
                    min={this.getMinimumBid()}
                    name="userBid"
                    value={this.state.userBid}
                    onChange={(e: any) =>
                      handleInputChange(e, this, false, () => this.getBidSummary())
                    }
                  />
                  <div className="toggle">
                    <div className="label">
                      {t('Make bid anonymous?')}
                      <Tooltip
                        message={t(
                          'Keep your name hidden on the leaderboard so other bidders do not know who you are.',
                        )}
                      >
                        {(ref) => (
                          <div
                            ref={ref}
                            className="hover-action"
                          >
                            <i className="fas fa-question-circle"></i>
                          </div>
                        )}
                      </Tooltip>
                    </div>
                    <ToggleButton
                      onToggleClick={() => this.setState({ anonymous: !this.state.anonymous })}
                      isActive={this.state.anonymous}
                      label={this.state.anonymous ? t('Yes') : t('No')}
                    />
                  </div>
                  <div className="toggle">
                    <div className="label">
                      {t('Setup automatic bidding?')}
                      <Tooltip
                        message={t(
                          'Allow the system to bid on your behalf up to a specified amount.',
                        )}
                      >
                        {(ref) => (
                          <div
                            ref={ref}
                            className="hover-action"
                          >
                            <i className="fas fa-question-circle"></i>
                          </div>
                        )}
                      </Tooltip>
                    </div>
                    <ToggleButton
                      onToggleClick={() => this.setState({ automatic: !this.state.automatic })}
                      isActive={this.state.automatic}
                      label={this.state.automatic ? t('Yes') : t('No')}
                    />
                  </div>
                  {this.state.automatic && (
                    <div className="automatic-bidding">
                      <TextField
                        label={t('Automatic Bid Increment')}
                        icon="far fa-dollar-sign"
                        type="number"
                        step=".01"
                        min={String(this.props.item.minimum_bid_increment)}
                        name="autoIncrement"
                        value={this.state.autoIncrement}
                        onChange={(e: any) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        label={t('Maximum Automatic Bid')}
                        icon="far fa-dollar-sign"
                        type="number"
                        name="autoMax"
                        value={this.state.autoMax}
                        onChange={(e: any) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                  )}
                  <div className="inner-details">
                    <div className="bid-amt">
                      <div className="label">{t('Your Bid')}</div>
                      <div
                        className="value"
                        notranslate="yes"
                      >
                        {' '}
                        {formatCurrency(
                          parseFloat(this.state.userBid) || 0,
                          currency,
                          this.props.locale,
                        )}{' '}
                      </div>
                    </div>
                    {this.props.item.is_shipping_available && (
                      <div className="bid-amt">
                        <div className="label">{t('Shipping')}</div>
                        {!this.state.isBidSumLoading && (
                          <div
                            className="value"
                            notranslate="yes"
                          >
                            {this.props.item.shipping_price_type === 'TBD'
                              ? 'TBD'
                              : this.props.item.shipping_price_type === 'Free'
                                ? 'Free'
                                : formatCurrency(
                                    this.state.fees?.shipping || 0,
                                    currency,
                                    this.props.locale,
                                  )}
                          </div>
                        )}
                        {this.state.isBidSumLoading && (
                          <Loader loading={this.state.isBidSumLoading} />
                        )}
                      </div>
                    )}
                    {!this.state.feeControl?.covers_processing_fees && (
                      <div className="bid-amt">
                        <div className="label">{t('Processing Fee')}</div>
                        {!this.state.isBidSumLoading && (
                          <div
                            className="value"
                            notranslate="yes"
                          >
                            {formatCurrency(
                              this.state.fees?.payment_platform_fee || 0,
                              currency,
                              this.props.locale,
                            )}
                          </div>
                        )}
                        {this.state.isBidSumLoading && (
                          <Loader loading={this.state.isBidSumLoading} />
                        )}
                      </div>
                    )}
                    {!this.state.feeControl?.covers_gigit_fees && (
                      <div className="bid-amt">
                        <div className="label">{t('Platform Fee')}</div>
                        {!this.state.isBidSumLoading && (
                          <div
                            className="value"
                            notranslate="yes"
                          >
                            {formatCurrency(
                              this.state.fees?.gigit_fee || 0,
                              currency,
                              this.props.locale,
                            )}
                          </div>
                        )}
                        {this.state.isBidSumLoading && (
                          <Loader loading={this.state.isBidSumLoading} />
                        )}
                      </div>
                    )}
                    {
                      <div className="bid-amt total">
                        <div className="label total">{t('Total')}</div>
                        {!this.state.isBidSumLoading && (
                          <div
                            className="value total"
                            notranslate="yes"
                          >
                            {formatCurrency(
                              this.state.fees?.total || 0,
                              currency,
                              this.props.locale,
                            )}
                          </div>
                        )}
                        {this.state.isBidSumLoading && (
                          <Loader loading={this.state.isBidSumLoading} />
                        )}
                      </div>
                    }
                  </div>
                  {this.props.item.bids_require_payment_method && (
                    <Fragment>
                      <div
                        className="payment-method"
                        onClick={() =>
                          this.setState({
                            showPlaceBidContent: false,
                            showSelectACardContent: true,
                          })
                        }
                      >
                        <i className="fa fa-credit-card" />
                        <span className="label">{t('Payment Method')}</span>
                        <span className="visa-label">
                          {this.getDefaultPaymentMethod()
                            ? `VISA ****${this.getDefaultPaymentMethod()?.card?.last4}`
                            : 'Select Payment Method'}
                        </span>
                        <i className="fa fa-chevron-right" />
                      </div>
                    </Fragment>
                  )}

                  {this.hasShippingOptions() && (
                    <div
                      className="shipping-pickup"
                      onClick={() =>
                        this.setState({
                          showPlaceBidContent: false,
                          showShippingAndPickupOptions: true,
                        })
                      }
                    >
                      <i className="fas fa-truck" />
                      <span className="label">Shipping/Pick-up:</span>
                      <var
                        data-var="shipping_option"
                        className="value"
                      >
                        {this.state.shipOption === 'ship'
                          ? 'Shipped by Vendor'
                          : `Pickup at: ${this.getPickupOptionName()}`}
                      </var>
                      <i className="fa fa-chevron-right" />
                    </div>
                  )}

                  <div className="disclaimer">
                    <div>{t(`"Total" is the amount you will pay if you win.`)}</div>
                    {this.props.item.is_shipping_available &&
                      this.props.item.shipping_price_type === 'TBD' && (
                        <div>
                          {t('*Shipping is available but costs are to be determined later.')}
                        </div>
                      )}
                  </div>
                  <div className="actions">
                    <Button
                      type="submit"
                      text={t('Place Bid')}
                    />
                  </div>
                </form>
              </div>
            )}
          {this.props.activeTab === 'buy_now' && (
            <div className="place-bid-inner">
              <div className="cover">
                {this.props.item.media && this.props.item.media.length > 0 && (
                  <img
                    alt={'cover image ' + this.props.item.name}
                    src={this.props.item.media[0].url}
                  />
                )}
                <div className="curve"></div>
              </div>
              {this.props.item.media && this.props.item.media.length > 0 && (
                <div
                  style={{ backgroundImage: 'url(' + this.props.item.media[0].url + ')' }}
                  className="portrait-auction"
                ></div>
              )}
              {this.state.timer >= 0 && (
                <div className="timer">
                  <div className="days">
                    <i className="far fa-clock"></i>
                    <var data-var="days">{Math.floor(this.state.timer / secondsInDay)}</var>{' '}
                    {t('Days')}
                  </div>
                  <div className="hours">
                    <var data-var="hours">
                      {Math.floor(this.state.timer / secondsInHour) -
                        Math.floor(this.state.timer / secondsInDay) * hoursInDay}
                    </var>{' '}
                    {t('Hours')}
                  </div>
                  <div className="minutes">
                    <var data-var="minutes">
                      {Math.floor(this.state.timer / secondsInMinute) -
                        Math.floor(this.state.timer / secondsInHour) * secondsInMinute}
                    </var>{' '}
                    {t('Minutes')}
                  </div>
                  <div className="seconds">
                    <var data-var="seconds">
                      {this.state.timer -
                        Math.floor(this.state.timer / secondsInMinute) * secondsInMinute}
                    </var>{' '}
                    {t('Seconds')}
                  </div>
                </div>
              )}
              {(this.props.item.is_shipping_available || this.props.item.is_pickup_available) && (
                <div className="shipping-option-label">
                  <span className="label">{t('Item available for ')}</span>
                  {this.props.item.is_shipping_available && (
                    <div className="option">
                      <i className="fa fa-check available-icon" />
                      <span className="label"> {t('Shipping')} </span>
                    </div>
                  )}
                  {this.props.item.is_pickup_available && (
                    <div className="option">
                      <i className="fa fa-check available-icon" />
                      <span className="label"> {t('Pick-up')} </span>
                    </div>
                  )}
                </div>
              )}
              <div className="details">
                <div
                  className="name"
                  notranslate="yes"
                >
                  {this.props.item.name}
                </div>
                <div className="buy-now-blurb">
                  <span>Stop the bidding and own this item right now!</span>
                </div>
                <div className="buy-now">
                  <span>
                    {t('Buy now')}:{' '}
                    <var data-var="buy_now_price">
                      {formatCurrency(this.props.item.buy_now_price, currency, this.props.locale)}
                    </var>
                  </span>
                </div>
                <div className="description">
                  <div className="label">{t('Description')}</div>
                  <QuillTextEditor
                    value={this.props.item.description || t('This item has no description.')}
                    readOnly={true}
                    preserveWhitespace={true}
                    theme={'bubble'}
                    modules={auctionItemHelpers.quillModules}
                    formats={auctionItemHelpers.quillFormats}
                    onChange={(content: any, delta: any, source: any, editor: any) => {}}
                  />
                </div>
                <div className="buy-now-actions">
                  <Button
                    isDisabled={lockBuyNow}
                    onClick={() => {
                      this.props.onBuyNowClicked();
                    }}
                    text={t('Buy Now')}
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.showPlaceBidSuccessScreen && this.props.activeTab !== 'buy_now' && (
            <div className="place-bid-inner">
              <div className="cover">
                <div className="curve"></div>
              </div>
              <div className="details">
                <div className="name">{t('You have successfully placed your bid!')}</div>
                <div className="description-light">
                  {t('A confirmation email was sent to you.')}
                </div>
                <img
                  className="image"
                  src={BidSuccessfulImg}
                />
                <div className="title-md">Contact Details</div>
                <div className="description-light">
                  Please make sure your email address and phone number are correct. Only winners
                  will be contacted.
                </div>
                <div className="contact-details-list">
                  <div
                    className="contact-detail-item"
                    onClick={() =>
                      this.setState({
                        manageField: { fieldLabel: 'Email', fieldValue: this.state.email },
                        showPlaceBidSuccessScreen: false,
                      })
                    }
                  >
                    <img
                      className="icon"
                      src={EmailIcon}
                    />
                    <div className="label">{t('Email')}</div>
                    <div className="item-text">
                      <var data-var="email">{this.state.email}</var>
                    </div>
                    <img
                      className="icon-next"
                      src={NextIcon}
                    />
                  </div>
                  <div
                    className="contact-detail-item"
                    onClick={() =>
                      this.setState({
                        manageField: { fieldLabel: 'Phone', fieldValue: this.state.phone },
                        showPlaceBidSuccessScreen: false,
                      })
                    }
                  >
                    <img
                      className="icon"
                      src={PhoneIcon}
                    />
                    <div className="label">{t('Phone')}</div>
                    <div className="item-text">
                      <var data-var="phone_number">{this.state.phone}</var>
                    </div>
                    <img
                      className="icon-next"
                      src={NextIcon}
                    />
                  </div>
                </div>
                <Button
                  className="button-finish"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.onFinish();
                  }}
                  text={t('Finish')}
                />
              </div>
            </div>
          )}
          {this.state.manageField && (
            <ModalManageField
              fieldLabel={this.state.manageField.fieldLabel}
              fieldValue={this.state.manageField.fieldValue}
              onSave={(fieldValue) => this.handleUpdateUserField(fieldValue)}
              goBack={() => this.setState({ manageField: null, showPlaceBidSuccessScreen: true })}
            />
          )}
        </div>
        <div
          className={`select-card-content ${this.state.showSelectACardContent ? 'show' : 'hidden'}`}
        >
          {this.renderSelectCardHeader(t('Select Payment Method'), () =>
            this.setState({ showSelectACardContent: false, showPlaceBidContent: true }),
          )}
          <div
            className="row"
            onClick={() =>
              this.setState({ showNewPaymentMethodForm: true, showSelectACardContent: false })
            }
          >
            <span className="label-container">
              <span className="label">{t('New Payment Method')}</span>
            </span>
            <div className="card-action">
              <i className="fa fa-plus" />
            </div>
          </div>
          {this.state.paymentMethods.map((paymentMethod, index: number) => {
            return (
              <div
                className="row"
                key={index}
                onClick={() => this.setPaymentMethodAsDefault(paymentMethod.payment_method_id!)}
              >
                <span className="label-container">
                  {paymentMethod.card?.brand && (
                    <i className={`icon ${formatCardIcon(paymentMethod.card.brand)}`} />
                  )}
                  <span className="label">{`****${paymentMethod.card?.last4}`}</span>
                </span>
                <div className="card-action">
                  <i
                    className={
                      paymentMethod.is_default ? 'far fa-scrubber selected' : 'fal fa-circle'
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className={`new-card-form ${this.state.showNewPaymentMethodForm ? 'show' : 'hidden'}`}>
          {this.renderSelectCardHeader(t('New Payment Method'), () =>
            this.setState({ showSelectACardContent: true, showNewPaymentMethodForm: false }),
          )}
          {this.state.stripePromise !== null && (
            <Elements stripe={this.state.stripePromise}>
              <ElementsConsumer>
                {({ elements, stripe }) => (
                  <PaymentMethodsForm
                    stripe={stripe}
                    elements={elements}
                    onClose={() => {
                      this.setState(
                        { showNewPaymentMethodForm: false, showSelectACardContent: true },
                        () => {
                          this.getPaymentMethods();
                        },
                      );
                    }}
                  />
                )}
              </ElementsConsumer>
            </Elements>
          )}
        </div>
        <div
          className={`pickup-and-shipping-options ${this.state.showShippingAndPickupOptions ? 'show' : 'hidden'}`}
        >
          {this.renderSelectCardHeader(t('Shipping/Pick-up Options'), () =>
            this.setState({ showShippingAndPickupOptions: false, showPlaceBidContent: true }),
          )}
          <div>
            {
              <div className="shipping-options">
                {this.props.item.is_shipping_available && (
                  <div
                    onClick={() => this.setState({ shipOption: 'ship' })}
                    className="option shipping-option no-select"
                  >
                    <span className="ship-title">{t('Shipped by Vendor')}</span>
                    <div className="shipping-label">
                      <i
                        className={
                          this.state.shipOption === 'ship' ? 'fas fa-check-circle' : 'far fa-circle'
                        }
                      ></i>
                      {this.props.item.shipping_price_type === 'Amount'
                        ? formatCurrency(
                            this.props.item.shipping_price || 0,
                            this.props.item?.amounts?.currency ?? defaultCurrency,
                            this.props.locale,
                          )
                        : this.props.item.shipping_price_type === 'Free'
                          ? 'Free'
                          : 'TBD'}
                    </div>
                  </div>
                )}

                {this.props.item.is_pickup_available &&
                  this.props.item.pickup_locations?.length && (
                    <div className="pickup-locations">
                      <span className="ship-title">{t('Pick-Up')}</span>
                      {this.props.item.pickup_locations.map(
                        (location: IAddressBase, index: number) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                this.setState({ shipOption: formatAddressLine(location) })
                              }
                              className="shipping-label"
                            >
                              <i
                                className={
                                  this.state.shipOption === formatAddressLine(location)
                                    ? 'fas fa-check-circle'
                                    : 'far fa-circle'
                                }
                              ></i>
                              <span>{location.title}</span>({formatAddressLine(location)})
                            </div>
                          );
                        },
                      )}
                    </div>
                  )}
              </div>
            }
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    userState: store.userState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
  updateUser,
  updateUserEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuctionItemPlaceBidModal);
