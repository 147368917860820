import './HubModalTheme.scss';
import React from 'react';
import ColorPickerInput from '../../../shared/ColorPickerInput/ColorPickerInput';
import EditModal from '../shared/EditModal/EditModal';
import { useState } from 'react';
import { IHub } from '@gigit/interfaces';
import useToastDispatcher from '../../../../hooks/useToaster';
import { hubRequestActions } from '../../../../requestActions';

interface IProps {
  hub: IHub | null;
  tryRefreshHub: () => void;
  showModal: boolean;
  onClose: () => void;
}

const HubModalTheme: React.FC<IProps> = (props) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [primaryThemeHex, setPrimaryThemeHex] = useState<string>(
    props.hub?.theme?.primary_color || '#2C73D6',
  );
  const [secondaryThemeHex, setSecondaryThemeHex] = useState<string>(
    props.hub?.theme?.secondary_color || '#FFFFFF',
  );
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  async function handleSaveTheme() {
    setIsSaving(true);
    const payload = {
      theme: {
        primary_color: primaryThemeHex,
        secondary_color: secondaryThemeHex,
      },
    };

    try {
      await hubRequestActions.updateHub(payload, props.hub?.id);
      props.tryRefreshHub();
      dispatchToastSuccess('Successfully Updated Hub Theme', 'Update Hub Theme');
    } catch (error) {
      dispatchToastError(error, 'Update Hub Theme');
    } finally {
      setIsSaving(false);
      props.onClose();
    }
  }

  return (
    <EditModal
      key="hub-modal-theme"
      title="Theme"
      showModal={props.showModal}
      onSave={handleSaveTheme}
      isSaving={isSaving}
      onClose={props.onClose}
      modalClassName="HubModalTheme"
      contentClassName="theme-content-container"
    >
      <ColorPickerInput
        color={primaryThemeHex}
        onChange={setPrimaryThemeHex}
        label="Primary"
      />
      <ColorPickerInput
        color={secondaryThemeHex}
        onChange={setSecondaryThemeHex}
        label="Secondary"
      />
    </EditModal>
  );
};

export default HubModalTheme;
