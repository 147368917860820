import React from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import { Config } from '@gigit/config';
import {
  IBroadcastMessage,
  IEventWidgetDetails,
  IIndividualFundraisingDonationBroadcastContent,
} from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { swapRouteParams, routes, defaultCurrency, formatCurrency } from '../../../helpers';
import queryString from 'query-string';
import { IAppState } from '../../../store';
import { IUserState } from '../../../reducers/user';

import './AlertBox.scss';
import { Constants } from '@gigit/constants';
import { userSelectors } from '../../../selectors/user';
import AlertBoxWidget, {
  AlertBoxDisplayState,
} from '../../../components/widgets/AlertBoxWidget/AlertBoxWidget';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  userState: IUserState;
  locale: string;
}

interface IState {
  isTestMode: boolean;
  displayState: AlertBoxDisplayState;
  donations: IIndividualFundraisingDonationBroadcastContent[];
  eventId: string;
  mounted: boolean;
  widget: IEventWidgetDetails;
}

/** Route handler for an embedable donation alert box widget. */
class AlertBox extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let params = queryString.parse(this.props.location.search);

    this.state = {
      isTestMode: params.test === '1',
      displayState: AlertBoxDisplayState.HIDE,
      donations: [],
      eventId: '',
      mounted: false,
      widget: {
        group_id: '',
        group_name: '',
        group_profile_image_url: '',
        event_id: '',
        event_name: '',
        event_profile_image_url: '',
        event_short_url: '',
        user_id: '',
        individual_goal: 0,
        individual_raised: 0,
        individual_short_url: '',
        currency: defaultCurrency,
        widget_theme: Constants.default_widget_theme,
        widget_style: Constants.default_widget_style['alert_box'],
        event_handle: '',
        group_handle: '',
        user_handle: '',
      },
    };
  }

  componentDidMount() {
    let socket = io(Config.web.REACT_APP_CHAT_GATEWAY + '/chat');

    // In test mode we manually add donation.
    if (this.state.isTestMode) {
      setTimeout(() => {
        this.addTestDonation();
      }, 100);
    }

    // When we receive web-socket event, add donation.
    socket.on(
      'broadcast',
      (msg: IBroadcastMessage<IIndividualFundraisingDonationBroadcastContent>) => {
        switch (msg.message_type) {
          case 'donation':
            if (
              msg.content.event_id === this.state.eventId &&
              this.state.eventId &&
              msg.content.individual_user_id === this.state.widget.user_id &&
              this.state.widget.user_id
            ) {
              let _donations = [...this.state.donations];
              _donations.push(msg.content);

              this.setState({
                donations: _donations,
              });
            }
            break;
        }
      },
    );

    axios
      .get(swapRouteParams(routes.GET_WIDGET_DETAILS, { widgetId: this.props.match.params.id }))
      .then((response) => {
        if (response && response.data) {
          this.setState({
            eventId: response.data.event_id,
            widget: response.data,
          });
        }
      })
      .finally(() => {
        this.setState({
          mounted: true,
        });
      });
  }

  addTestDonation() {
    this.setState({
      donations: [
        ...this.state.donations,
        {
          amount: 100,
          display_name: 'Test Donation',
          event_id: '',
          group_id: '',
          individual_user_id: '',
          currency: this.state.widget.currency,
          profile_image_url: '',
        },
      ],
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.state.donations.length !== prevState.donations.length &&
      this.state.donations.length > 0 &&
      this.state.displayState === AlertBoxDisplayState.HIDE
    ) {
      this.handleNextDisplayState();
    }
  }

  /** State machine that handles animation state of alert box. */
  handleNextDisplayState() {
    let nextState: AlertBoxDisplayState | null = null;
    let timeout = 0;

    switch (this.state.displayState) {
      case AlertBoxDisplayState.HIDE:
        nextState = AlertBoxDisplayState.DISPLAY;
        timeout = 8000;
        break;
      case AlertBoxDisplayState.DISPLAY:
        nextState = AlertBoxDisplayState.HIDING;
        timeout = 500;
        break;
    }

    if (nextState) {
      this.setState(
        {
          displayState: nextState,
        },
        () => {
          setTimeout(() => {
            this.handleNextDisplayState();
          }, timeout);
        },
      );
    } else {
      let _donations = [...this.state.donations];
      _donations.shift();

      this.setState(
        {
          displayState: AlertBoxDisplayState.HIDE,
          donations: _donations,
        },
        () => {
          if (this.state.isTestMode) {
            setTimeout(() => {
              this.addTestDonation();
            }, 1500);
          }
        },
      );
    }
  }

  render() {
    let { t } = this.props;

    return (
      <div className="AlertBoxWrap">
        {this.state.eventId === '' && this.state.mounted && (
          <div>{t('Unable to find corresponding event for alert box overlay.')}</div>
        )}
        <AlertBoxWidget
          displayState={this.state.displayState}
          widget={this.state.widget}
          donation={this.state.donations.length ? this.state.donations[0] : null}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(AlertBox),
);
