import React, { CSSProperties, FC, ReactNode } from 'react';
import './CardCommon.scss';

interface IProps {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
  style?: CSSProperties;
}

export const CardCommon: FC<IProps> = (props: IProps) => {
  return (
    <React.Fragment>
      <div
        className={`CardCommon ${props.className && props.className}`}
        onClick={props.onClick}
        style={props.style}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
};
