import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';

import { FlowConfigComponents, FlowConfigPages } from './FlowConfigs';
import { routes, setSEOMetatags, swapRouteParams, toastError, toastSuccess } from '../../helpers';
import {
  ICampaign,
  IEventTeam,
  IGigSummary,
  IGroup,
  IRegisterToFundraiserParticipant,
  ISimpleValue,
  IUserObjectWithRole,
} from '@gigit/interfaces';
import { IOptions } from '../../components/Dropdown/Dropdown';

import {
  createGroup,
  createGroupCampaign,
  createGroupFlow,
  createGroupPage,
  getGroup,
  getGroupCampaign,
  getGroupLocations,
  getGroupSignature,
  resetGroupState,
  setCampaignConnect,
  updateGroup,
} from '../../actions/group';
import {
  createUserPage,
  getUserGroupsMonetized,
  getUserInfo,
  updateUser,
  updateUserPage,
} from '../../actions/user';
import {
  createEvent,
  createEventPage,
  getEvent,
  getEventTeams,
  getFundraisingRegistrationTicket,
  purchaseFundraise,
  resetEvent,
  resetEventState,
  updateEvent,
} from '../../actions/event';
import { getGroupClassifications, resetFlowState, setFlow } from '../../actions/register';
import { createGroupGig, getGigCategories } from '../../actions/gig';
import { createToast } from '../../actions/toaster';

import { IAppState } from '../../store';
import { IEventState } from '../../reducers/event';
import { IGroupState } from '../../reducers/group';
import { IUserState } from '../../reducers/user';
import { IFlowStateConfig, IRegisterState } from '../../reducers/register';
import { IGigState } from '../../reducers/gig';
import { groupRequestActions } from '../../requestActions';

import { ISearchableDropdownListItem } from '../../components/SearchableDropdown/SearchableDropdown';
import FlowInfo from '../../components/CreateFlows/FlowInfo/FlowInfo';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import StepsHeader from '../../components/CreateFlows/Steps/StepsHeader/StepsHeader';
import GroupSelection from '../../components/CreateFlows/Steps/groupSelection/GroupSelection';
import EntityTypeSelection from '../../components/CreateFlows/Steps/EntityTypeSelection/EntityTypeSelection';
import EntityInfo from '../../components/CreateFlows/Steps/EntityInfo/EntityInfo';
import DateAndLocation from '../../components/CreateFlows/Steps/DateAndLocation/DateAndLocation';
import CampaignConnect from '../../components/CreateFlows/Steps/CampaignConnect/CampaignConnect';
import IsCharity from '../../components/CreateFlows/Steps/IsCharity/IsCharity';
import IsEvent from '../../components/CreateFlows/Steps/IsEvent/IsEvent';
import EventSelection from '../../components/CreateFlows/Steps/EventSelection/EventSelection';
import GroupClassifications from '../../components/CreateFlows/Steps/GroupClassifications/GroupClassifications';
import GigCategory from '../../components/CreateFlows/Steps/GigCategory/GigCategory';
import VolunteerInfo from '../../components/CreateFlows/Steps/VolunteerInfo/VolunteerInfo';
import EmergencyContact from '../../components/CreateFlows/Steps/EmergencyContact/EmergencyContact';
import CausesSelection from '../../components/CreateFlows/Steps/CausesSelection/CausesSelection';
import CallToAction from '../../components/CreateFlows/Steps/CTA/CTA';

import { stepConfigurations } from '../../components/CreateFlows/Steps/Config/StepConfigurations';
import './Flow.scss';
import FundraiseGoal from '../../components/CreateFlows/Steps/FundraiseGoal/FundraiseGoal';
import ContentCreator from '../../components/CreateFlows/Steps/ContentCreator/ContentCreator';
import FundraiseTickets from '../../components/CreateFlows/Steps/FundraiseTickets/FundraiseTickets';
import AddParticipant from '../../components/CreateFlows/Steps/AddParticipant/AddParticipant';
import FundraiseTeamSearch from '../../components/CreateFlows/Steps/FundraiseTeamSearch/FundraiseTeamSearch';
import ConnectStravaAccount from '../../components/CreateFlows/Steps/ConnectStravaAccount/ConnectStravaAccount';
import { userSelectors } from '../../selectors/user';
import { IToast, ReduxActionType } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import errorHelpers from '../../helpers/errorHelpers';
import { Constants } from '@gigit/constants';
import axios from 'axios';
import { IProfileState } from '../../reducers/profile';
import { getProfileByHandle } from '../../actions/profile';
import { teamRequestActions } from '../../requestActions/team';

export interface IFlowEventPayload {
  event: any;
  pages: any;
}

const flowRoutes: any = {
  donations: 'donations',
  createGroup: 'group',
  createEvent: 'events',
  createGig: 'gigs',
  createStore: 'store',
  createAuction: 'auction',
  findGroups: 'donate',
  findEvents: 'findEvents',
  volunteer: 'volunteer',
  fundraise: 'fundraise',
};

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  profileState: IProfileState;
  registerState: IRegisterState;
  groupState: IGroupState;
  eventState: IEventState;
  gigState: IGigState;
  flowType: string;
  locale: string;

  getGroupSignature(_handle: string): void;
  getGroupLocations(_handle: string): void;
  getUserGroupsMonetized(): void;
  getGigCategories(): void;
  updateUserPage(_payload: any): void;
  getProfileByHandle(handleOrId: string): void;
  createGroup(_payload: any, isFlow?: boolean): void;
  createGroupFlow(
    _payload: any,
    event?: IFlowEventPayload,
    gig?: any,
    shouldGetGroup?: boolean,
  ): void;
  createEvent(
    groupId: string,
    payload: any,
    options?: { _pages?: any; _updateComponent?: any; isFlow?: boolean },
  ): void;
  createGroupCampaign(groupId: string, payload: any, connect?: boolean, eventId?: string): void;
  getGroupCampaign(groupId: string, id: string): void;
  updateGroup(_payload: any, groupId: string, isPages?: boolean, isFlow?: boolean): void;
  // searchAddress(_payload: any): void,
  resetGroupState(): void;
  resetEventState(): void;
  getGroup(id: string): void;
  getEvent(_handle: string): void;
  resetEvent: ReduxActionType<typeof resetEvent>;
  setFlow(flowConig: IFlowStateConfig): void;
  setCampaignConnect(groupId: string, payload: any): void;
  getGroupClassifications(): void;
  updateUser(payload: any, updateProfile?: boolean, options?: { callback?: () => void }): void;
  createUserPage(payload: any): void;
  createGroupPage(groupId: string, payload: any): void;
  createEventPage(eventId: string, payload: any): void;
  createGroupGig(
    groupId: string,
    _payload: any,
    options?: {
      _updateComponent?: any;
      shouldGetGig?: boolean;
      callback?: (gig: IGigSummary) => void;
    },
  ): void;
  resetFlowState(isComplete?: boolean): void;
  updateEvent(event: any): void;
  getUserInfo(id: string): void;
  createToast(toast: IToast): void;
  getFundraisingRegistrationTicket(eventId: string): void;
  getEventTeams(eventId: string, skip: number, limit: number): void;
  purchaseFundraise: ReduxActionType<typeof purchaseFundraise>;
}

interface IState {
  //@TODO make flow interfaces
  flow: string;
  group: any;
  event: any;
  gig: any;
  steps: Array<string>;
  currentStep: string;
  createGroup: boolean;
  showGroupList: boolean;
  isCharity: boolean;
  charityId: string;
  groupType: string;
  type: string;
  eventType: string;
  isEvent: boolean;
  address: string;
  canvasRef: any;
  autoSendTax: boolean;
  geocoderContainerRef: any;
  formattedAddress: any;
  showTaxSkipModal: boolean;
  showCampaignSkipModal: boolean;
  signature: any;
  resizeListener: any;
  classificationOptions: Array<any>;
  width: any;
  selectedGroup: any;
  selectedEvent: any;
  legalCharityName: string;
  signatureName: string;
  signatureTitle: string;
  selectedCampaign: string;
  defaultCamp: string;
  skipCampaign: boolean;
  skipTax: boolean;
  monetizedGroups: Array<IOptions>;
  gigCategories: Array<IOptions>;
  groupEvents: Array<IOptions>;
  campaignOptions: Array<IOptions>;
  findCauses: Array<string>;
  findCauseValue: string;
  findCTA: string;
  userInfo: any;
  params: any;
  groupFundraisers: Array<ISearchableDropdownListItem>;
  isOnBehalfOf: boolean;
  isCampaignDefault: boolean;
  hasValidGroupHandle: boolean;
  hasValidEventHandle: boolean;
  fundraiser: any;
  fundraiseTeams: Array<ISearchableDropdownListItem>;
  individualRegistrationStatus: { hasRegistrationTicket: boolean; isIndividual: boolean };
  activeGroupHandle?: string;
  team: IEventTeam | null;
}

class Flow extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let params = this.getLocationSearch();
    let type = this.props.flowType;

    this.state = {
      flow: type,
      params: params,
      group: {
        type: '',
        title: '',
        shortDescription: '',
        group_class: '',
        group_sub_class: '',
        fundraiseAmount: '',
        causes: [],
        localization: {
          country: '',
          defaultTimezone: '',
          state: '',
        },
      },
      event: {
        type: '',
        shortDescription: '',
        title: '',
        goal: '',
        causes: [],
        startDate: this.getIntialDatesRounded(),
        endDate: this.getIntialDatesRounded(),
        isVirtual: false,
        isLocation: false,
        eventOrganizerName: this.props.userState.user?.display_name || '',
      },
      gig: {
        type: '',
        title: '',
        category: '',
        subCategory: '',
        causes: [],
        startDate: this.getIntialDatesRounded(),
        endDate: this.getIntialDatesRounded(),
        hasPoliceCheck: false,
        isVirtual: false,
        isOSSDEligible: false,
        ageRange: {
          min: 16,
          max: 100,
        },
      },
      fundraiser: {
        type: '',
        teamName: '',
        teamId: '',
        teamGoal: null,
        goal: null,
        content_creator: false,
        hasParticipants: false,
        participants: [],
        ticket: null,
        fitness_tracking_enabled: false,
        userHasTicket: false,
      },
      selectedGroup: null,
      selectedEvent: null,
      steps: this.getBaseSteps(type), //use type to determine steps,
      currentStep: params?.currentStep?.toString() || this.getBaseSteps(type)[0],
      createGroup: false,
      showGroupList: false,
      isCharity: false,
      charityId: '',
      groupType: '',
      type: '',
      eventType: '',
      isEvent: false,
      address: '',
      canvasRef: React.createRef(),
      autoSendTax: false,
      geocoderContainerRef: React.createRef(),
      formattedAddress: null,
      showTaxSkipModal: false,
      showCampaignSkipModal: false,
      signature: null,
      resizeListener: null,
      classificationOptions: [],
      width: window.innerWidth,
      legalCharityName: '',
      signatureName: '',
      signatureTitle: '',
      selectedCampaign: this.props.groupState.group.campaign_id || '',
      defaultCamp: '',
      skipCampaign: false,
      skipTax: false,
      monetizedGroups: [],
      groupEvents: [],
      gigCategories: [],
      campaignOptions:
        this.props.groupState.groupCampaigns.map((c) => {
          return { value: c.id || '', label: c.title };
        }) || [],
      findCauses: [],
      findCauseValue: '',
      findCTA: '',
      userInfo: {
        first_name: this.props.userState.user.first_name || '',
        last_name: this.props.userState.user.last_name || '',
        email: this.props.userState.user.email || '',
        phone: this.props.userState.user.phone || '',
        emergName: '',
        emergPhone: '',
        emergency_contacts: this.props.userState.user.emergency_contacts || [],
      },
      groupFundraisers: [],
      isOnBehalfOf: false,
      isCampaignDefault: false,
      hasValidEventHandle: false,
      hasValidGroupHandle: false,
      fundraiseTeams: [],
      individualRegistrationStatus: {
        hasRegistrationTicket: false,
        isIndividual: false,
      },
      team: null,
    };
    this.filterFundraiserSteps = this.filterFundraiserSteps.bind(this);
    // this.addressSearch = this.addressSearch.bind(this);
    this.updateGroup = this.updateGroup.bind(this);
    this.updateGroupType = this.updateGroupType.bind(this);
    this.updateEntityType = this.updateEntityType.bind(this);
    this.setExistingGroup = this.setExistingGroup.bind(this);
    this.setExistingEvent = this.setExistingEvent.bind(this);
    this.handleCampaignSelect = this.handleCampaignSelect.bind(this);
    this.resetGroup = this.resetGroup.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.updateSignature = this.updateSignature.bind(this);
    this.uploadSignatureImg = this.uploadSignatureImg.bind(this);
    this.clear = this.clear.bind(this);
    this.handleCampaignSkip = this.handleCampaignSkip.bind(this);
    this.stripeConnect = this.stripeConnect.bind(this);
    this.getCurrentStepIndex = this.getCurrentStepIndex.bind(this);
    this.getPrevStep = this.getPrevStep.bind(this);
    this.getNextStep = this.getNextStep.bind(this);
    this.isStepDisabled = this.isStepDisabled.bind(this);
    this.updateCharityId = this.updateCharityId.bind(this);
    this.updateIsCharity = this.updateIsCharity.bind(this);
    this.updateIsEvent = this.updateIsEvent.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.resetEvent = this.resetEvent.bind(this);
    this.addFindCause = this.addFindCause.bind(this);
    this.removeFindCause = this.removeFindCause.bind(this);
    this.updateFindCauses = this.updateFindCauses.bind(this);
    this.updateCTA = this.updateCTA.bind(this);
    this.getEventPayload = this.getEventPayload.bind(this);
    this.populateUserCauses = this.populateUserCauses.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);
    this.toggleTaxSkip = this.toggleTaxSkip.bind(this);
    this.toggleCampaignSkip = this.toggleCampaignSkip.bind(this);
    this.handleTaxSkip = this.handleTaxSkip.bind(this);
    this.updateGig = this.updateGig.bind(this);
    this.updateIsOnBehalfOf = this.updateIsOnBehalfOf.bind(this);
    this.getEventHandle = this.getEventHandle.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.hasMonetizedGroups = this.hasMonetizedGroups.bind(this);
    this.hasCampaignAccount = this.hasCampaignAccount.bind(this);
    this.getCampaignOptions = this.getCampaignOptions.bind(this);
    this.onGroupInfoNext = this.onGroupInfoNext.bind(this);
    this.onEventInfoNext = this.onEventInfoNext.bind(this);
    this.updateFundraiser = this.updateFundraiser.bind(this);
    this.addTicket = this.addTicket.bind(this);
  }

  componentDidMount() {
    let params = this.getLocationSearch();

    if (params?.groupHandle) this.setActiveGroupHandle(params.groupHandle.toString());

    //when user navigates to the Flow component from group page
    //by clicking "Fundraise For Us"
    if (params.obo) {
      this.setState({
        flow: 'createEvent',
        isOnBehalfOf: true,
        groupType: 'existing',
        group: this.props.groupState.group,
        selectedGroup: this.props.groupState.group.id,
        steps: stepConfigurations.eventOrFundraiserExistingGroup,
      });

      if (params.groupHandle && this.props.groupState.group.id !== params.groupHandle.toString()) {
        // If On behalf of group hasn't been loaded yet into redux we need to load it ourselves.
        this.loadOnBehalfOfGroup(params.groupHandle.toString());
      }
    }

    if (params.joinTeam) {
      teamRequestActions
        .getTeam(this.props.eventState.event.id, params.joinTeam.toString())
        .then((team: IEventTeam) => {
          this.setState({ team }, () => {
            const fundraiser = {
              type: 'join',
              teamName: this.state.team?.name,
              teamId: this.state.team?.id,
            };
            this.setState({
              fundraiser: { ...this.state.fundraiser, ...fundraiser },
            });
          });
        })
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          let toast = toastError(errorObj.translatedMessage, 'Retrieve Team');
          createToast(toast);
        });
    }

    if (this.state.flow === 'createEvent' || this.state.flow === 'createAuction') {
      this.setState({
        isEvent: true,
        eventType: 'new',
      });
    }

    this.onStripeReturn();
    this.initResize();
    this.initializeFlow();

    if (this.props.userState.user.causes) {
      this.setState({
        findCauses: [...this.props.userState.user.causes],
      });
    }

    // Clear existng event if creating a new one. Should fix any bugs relating to "stale" state.
    if (this.props.flowType === 'createEvent') {
      this.props.resetEvent();
    }
  }

  /** Loads and stores the On Behalf of group. */
  async loadOnBehalfOfGroup(groupId: string) {
    try {
      const group = await groupRequestActions.getGroupByHandleOrId(groupId);
      this.setState({
        group: group,
        selectedGroup: group,
      });
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Fetch Cause');
      this.props.createToast(toast);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.userState.isLoggedIn) {
      setSEOMetatags({
        title: 'Kambeo',
      });
    }

    if (this.flowHasEntity()) {
      if (prevProps.gigState.gig.id !== this.props.gigState.gig?.id) {
        if (this.props.gigState.gig?.handle) {
          this.props.history.push(`/gig/${this.props.gigState.gig.handle}?help=1`);
        }
      }
      //donation flow specific dynamic step updates
      if (this.state.flow === 'donations' && prevState.isCharity !== this.state.isCharity) {
        if (this.state.groupType === 'new') {
          if (this.state.isCharity) {
            this.setState({
              steps: stepConfigurations.donationsNewCharityGroup,
            });
          }
        } else {
          if (this.state.isCharity && this.state.flow === 'donations') {
            this.setState({
              steps: stepConfigurations.donationsExistingCharityGroup,
            });
          } else {
            if (this.state.flow === 'donations') {
              this.setState({
                steps: stepConfigurations.donationsExistingGroup,
              });
            }
          }
        }
      }

      //group type new or existing logic for dynamic step updates
      if (prevState.groupType !== this.state.groupType) {
        this.isStepDisabled();
        if (this.state.groupType === 'new') {
          if (this.state.isCharity) {
            if (this.state.flow === 'donations') {
              this.setState({
                steps: stepConfigurations.donationsNewCharityGroup,
              });
            }
          } else {
            if (this.state.flow === 'donations') {
              this.setState({
                steps: stepConfigurations.donationsNewGroup,
              });
            }
          }

          if (this.state.flow === 'createStore') {
            this.setState({
              steps: stepConfigurations.storeNewGroup,
            });
          }

          if (this.state.flow === 'createAuction') {
            this.setState({
              steps: stepConfigurations.auctionNewGroup,
            });
          }

          if (this.state.flow === 'createEvent') {
            this.setState({
              steps: stepConfigurations.eventOrFundraiser,
            });
          }

          if (this.state.flow === 'createGig') {
            this.setState({
              steps: stepConfigurations.gigNewGroup,
            });
          }
        } else {
          if (this.state.flow === 'donations') {
            this.setState({
              steps: stepConfigurations.donationsExistingGroup,
            });
          } else if (this.state.flow === 'createStore') {
            this.setState({
              steps: stepConfigurations.storeExistingGroup,
            });
          } else if (this.state.flow === 'createAuction') {
            this.setState({
              steps: stepConfigurations.auctionExistingGroup,
            });
          } else if (this.state.flow === 'createEvent') {
            this.setState({
              steps: stepConfigurations.eventOrFundraiserExistingGroup,
            });
          } else if (this.state.flow === 'createGig') {
            this.setState({
              steps: stepConfigurations.gigExistingGroup,
            });
          }
        }
      }

      if (prevState.isEvent !== this.state.isEvent) {
        if (this.state.flow === 'createStore') {
          if (this.state.groupType === 'new') {
            if (this.state.isEvent) {
              this.setState({
                steps: stepConfigurations.storeNewGroupExistingEvent,
              });
            } else {
              this.setState({
                steps: stepConfigurations.storeNewGroupNoEvent,
              });
            }
          } else {
            if (this.state.isEvent) {
              this.setState({
                steps: stepConfigurations.storeExistingGroupExistingEvent,
              });
            } else {
              this.setState({
                steps: stepConfigurations.storeExistingNoEvent,
              });
            }
          }
        }
      }

      // new or existing event type and corresponding step updates
      if (prevState.eventType !== this.state.eventType) {
        if (this.state.flow === 'createStore' || this.state.flow === 'createAuction') {
          if (this.state.eventType === 'existing') {
            if (this.state.groupType === 'existing') {
              this.setState({
                steps: stepConfigurations.storeNewGroupExistingEvent,
              });
            } else {
              this.setState({
                steps: stepConfigurations.storeExistingGroupExistingEvent,
              });
            }
          } else if (this.state.eventType === 'new') {
            if (this.state.groupType === 'existing') {
              this.setState({
                steps: stepConfigurations.storeExistingGroupNewEvent,
              });
            } else {
              this.setState({
                steps: stepConfigurations.storeNewGroupNewEvent,
              });
            }
          }
        }
      }

      if (prevState.group.type !== this.state.group.type) {
        this.forceUpdate();
      }

      // setting existing group data
      if (prevState.group !== this.state.group && this.state.group?.id) {
        if (this.state.group.charity_id) {
          this.setState({
            isCharity: true,
            charityId: this.state.group.charity_id,
          });
        }
      }

      if (
        prevProps.groupState.group?.id !==
        (this.props.groupState?.group && this.props.groupState.group?.id)
      ) {
        this.setExistingGroup(this.props.groupState.group.id);
      }

      if (prevProps.userState.monetizedGroups !== this.props.userState.monetizedGroups) {
        this.getOptions();

        if (this.props.userState.monetizedGroups.length === 0 && this.state.flow === 'createGig') {
          this.setState({
            currentStep: this.getBaseSteps(this.props.flowType)[1],
          });
        }
      }

      if (prevProps.groupState.groupEvents !== this.props.groupState.groupEvents) {
        this.getGroupEventOptions();
      }

      if (prevProps.groupState.groupCampaigns !== this.props.groupState.groupCampaigns) {
        this.getCampaignOptions();
      }

      if (prevProps.groupState?.group?.campaign_id !== this.props.groupState?.group?.campaign_id) {
        this.setState({
          selectedCampaign: this.props.groupState.group.campaign_id || '',
        });
      }

      if (prevProps.groupState.currentCampaign !== this.props.groupState.currentCampaign) {
        this.setState({
          selectedCampaign: this.props.groupState?.currentCampaign?.id ?? '',
          isCampaignDefault:
            this.props.groupState?.currentCampaign?.id === this.props.groupState.group.campaign_id,
        });
      }

      if (prevState.event.isVirtual !== this.state.event.isVirtual) {
        if (this.state.event.isVirtual) {
          let event = Object.assign({}, this.state.event);
          event.isLocation = false;
          this.setState({
            event: event,
          });
        }
      }

      if (prevState.event.isLocation !== this.state.event.isLocation) {
        if (this.state.event.isLocation) {
          let event = Object.assign({}, this.state.event);
          event.isVirtual = false;
          this.setState({
            event: event,
          });
        }
      }

      // HACK: We handle onComplete() check for createEvent flow different. We require the event to be loaded.
      // This fixes bug GIG-4321 where we'd land on the previously visited event instead of the new one due to a "race condition" (New event isn't loaded in time).
      if (this.props.flowType === 'createEvent') {
        if (
          (prevProps.eventState.event.id !== this.props.eventState.event.id ||
            prevProps.registerState.flowConfig.isComplete !==
              this.props.registerState.flowConfig.isComplete) &&
          this.props.eventState.event.id &&
          this.props.registerState.flowConfig.isComplete
        ) {
          //race case issue action getting interrupted by route change
          setTimeout(() => {
            this.onComplete();
          }, 100);
        }
      } else if (
        prevProps.registerState.flowConfig.isComplete !==
          this.props.registerState.flowConfig.isComplete &&
        this.props.registerState.flowConfig.isComplete
      ) {
        //race case issue action getting interrupted by route change
        setTimeout(() => {
          this.onComplete();
        }, 100);
      }
    }

    if (prevState.fundraiser?.type !== this.state.fundraiser?.type) {
      this.handleFundraiseTypeUpdate();
    }

    if (prevProps.eventState.isCreatingTeam && !this.props.eventState.isCreatingTeam) {
      if (this.props.eventState.newTeam !== '' && this.state.fundraiser?.type === 'create') {
        const toast = toastSuccess(
          localizeHelpers.translate('You successfully created a fundraising team.'),
          'Fundraiser Success',
        );
        !this.props.eventState.purchaseError && this.props.createToast(toast);

        this.props.history.push(
          '/event/' +
            this.props.eventState.event.handle +
            '/team/' +
            this.props.eventState.newTeam +
            '?help=1',
        );
      } else if (this.state.fundraiser?.type === 'join') {
        const toast = toastSuccess(
          localizeHelpers.translate('You successfully joined a fundraising team.'),
          'Fundraiser Success',
        );
        !this.props.eventState.purchaseError && this.props.createToast(toast);

        this.props.history.push(
          '/event/' + this.props.eventState.event.handle + '/team/' + this.state.team?.handle,
        );
      } else {
        const toast = toastSuccess(
          localizeHelpers.translate('You successfully joined the fundraiser as an individual.'),
          'Fundraiser Success',
        );
        !this.props.eventState.purchaseError && this.props.createToast(toast);

        this.props.history.push(
          '/event/' +
            this.props.eventState.event.handle +
            '/individual/' +
            this.props.userState.user.handle +
            '?help=1',
        );
      }
    }

    if (prevProps.eventState.teams !== this.props.eventState.teams) {
      // fix for when team  sometimes comes back as a string? TODO figure out where that happens
      let isProperFormat = Array.isArray(this.props?.eventState?.teams);
      let teams: Array<ISearchableDropdownListItem>;

      teams = isProperFormat
        ? this.props?.eventState?.teams?.map((t: any) => {
            return { label: t.name, id: t.id, image: t.profile_image_url };
          })
        : [];

      this.setState({
        fundraiseTeams: teams,
      });
    }

    if (
      this.state.flow === 'fundraise' &&
      prevProps.eventState?.event.required_item_ids !==
        this.props.eventState?.event.required_item_ids
    ) {
      this.handleFundraiseTypeUpdate();
    }
  }

  setActiveGroupHandle = (handle: string) => {
    axios
      .get<IGroup>(swapRouteParams(routes.GET_GROUP, { handleOrId: handle }))
      .then((groupResponse) => {
        this.setState({ activeGroupHandle: groupResponse.data.handle });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Get cause data');
        this.props.createToast(toast);
      });
  };

  // 'entity' referring to group/gig/event, if so it listens in componentWillUpdate for changes on the entity.
  flowHasEntity() {
    return (
      this.state.flow === 'donations' ||
      this.state.flow === 'createStore' ||
      this.state.flow === 'createAuction' ||
      this.state.flow === 'createGig' ||
      this.state.flow === 'createEvent' ||
      this.state.flow === 'createGroup'
    );
  }
  filterFundraiserSteps(step: string) {
    if (step === 'connectStravaAccount' && !this.props.eventState.event.fitness_tracking_enabled)
      return false;
    return !(step === 'isContentCreator' && !this.props.eventState.event.content_creator_enabled);
  }

  handleFundraiseTypeUpdate() {
    let hasTickets =
      this.props.eventState?.event?.required_item_ids &&
      this.props.eventState?.event?.required_item_ids?.length > 0;

    if (this.state.fundraiser?.type === 'join') {
      if (
        (hasTickets || this.state.fundraiser?.ticket) &&
        !this.state.individualRegistrationStatus?.hasRegistrationTicket
      ) {
        this.setState({
          steps: stepConfigurations.teamJoin,
        });
      } else {
        this.setState({
          steps: stepConfigurations.teamJoinNoTicket,
        });
      }
    } else if (this.state.fundraiser?.type === 'create') {
      if (
        (hasTickets || this.state.fundraiser?.ticket) &&
        !this.state.individualRegistrationStatus?.hasRegistrationTicket
      ) {
        this.setState({
          steps: stepConfigurations.teamCreate.filter(this.filterFundraiserSteps),
        });
      } else {
        this.setState({
          steps: stepConfigurations.teamCreateNoTicket.filter(this.filterFundraiserSteps),
        });
      }
    } else {
      if (
        (hasTickets || this.state.fundraiser?.ticket) &&
        !this.state.individualRegistrationStatus?.hasRegistrationTicket
      ) {
        this.setState({
          steps: stepConfigurations.individual.filter(this.filterFundraiserSteps),
        });
      } else {
        this.setState({
          steps: stepConfigurations.individualNoTicket.filter(this.filterFundraiserSteps),
        });
      }
    }
  }

  // hydrates data on stripe return incase user wants to go back and forth in form
  onStripeReturn() {
    if (this.state.currentStep === 'connect') {
      if (this.props.groupState.group) {
        this.setState(
          {
            group: {
              ...this.props.groupState.group,
              type: this.props.groupState.group?.group_template_type,
              fundraiseAmount: this.props.groupState.group?.fundraise_amount_range,
            },
            groupType: this.props.registerState.flowConfig.groupType || '', // new or existing
          },
          () => {
            if (this.state.group.charity_id) {
              this.setState({
                isCharity: true,
              });
            }

            if (this.props.eventState.event) {
              this.setState(
                {
                  event: {
                    ...this.props.eventState.event,
                    type: this.props.eventState.event?.event_template_type,
                  },
                  eventType: this.props.registerState.flowConfig.eventType || '',
                },
                () => {
                  if (
                    this.props.registerState.flowConfig.isEvent ||
                    this.state.flow === 'createEvent'
                  ) {
                    this.setState({
                      isEvent: true,
                    });
                  } else {
                    this.setState({
                      isEvent: false,
                    });
                  }
                  if (this.props.registerState.flowConfig.isCharity) {
                    this.setState({
                      isCharity: true,
                    });
                  } else {
                    this.setState({
                      isCharity: false,
                    });
                  }

                  this.props.resetFlowState();
                },
              );
            }
          },
        );
      }
    }
  }

  onComplete() {
    if (this.state.flow === 'donations' || this.state.flow === 'createGroup') {
      let url = `/group/${this.props.groupState.group.handle}`;
      if (this.state.flow === 'createGroup') {
        url += '?help=1';
      }
      this.props.history.push(url);
    } else if (
      this.state.flow === 'createStore' ||
      this.state.flow === 'createAuction' ||
      this.state.flow === 'createEvent'
    ) {
      if (this.state.isEvent) {
        this.props.history.push(`/event/${this.props.eventState.event.handle}?help=1`);
      } else {
        this.props.history.push(`/group/${this.props.groupState.group.handle}`);
      }
    }
  }

  updateUserInfo(field: string, val: any) {
    let u = Object.assign({}, this.state.userInfo);
    let ecs;

    if (Array.isArray(this.props.userState.user.emergency_contacts)) {
      ecs = [...this.props.userState.user.emergency_contacts];
      ecs.unshift({ name: this.state.userInfo.ECFullName, phone: this.state.userInfo.ECPhone });
    } else {
      ecs = [{ name: this.state.userInfo.emergName, phone: this.state.userInfo.emergPhone }];
    }

    u[field] = val;
    u.emergency_contacts = ecs;
    this.setState({
      userInfo: u,
    });
  }

  getPrevStep() {
    let decreaseStepBy = 1;
    if (
      this.state.isEvent &&
      this.state.params?.type &&
      this.state.steps.indexOf(this.state.currentStep) === 2
    ) {
      decreaseStepBy = 2;
    }

    if (this.state.steps.indexOf(this.state.currentStep)) {
      this.setState(
        {
          currentStep:
            this.state.steps[this.state.steps.indexOf(this.state.currentStep) - decreaseStepBy],
        },
        () => {
          let config = this.getFlowStatePayload();
          this.props.setFlow(config);
          this.isStepDisabled();
        },
      );
    }
  }

  getCurrentStepIndex() {
    return this.state.steps.indexOf(this.state.currentStep);
  }

  getFlowStatePayload() {
    let config: IFlowStateConfig = {
      group: this.state.group,
      event: this.state.event,
      gig: this.state.gig,
      type: this.state.flow,
      currentStep: this.state.currentStep,
      groupType: this.state.groupType,
      eventType: this.state.eventType,
      isCharity: this.state.isCharity,
      isEvent: this.state.isEvent,
      isComplete: false,
      fundraiser: this.state.fundraiser,
    };

    return config;
  }

  initializeFlow() {
    this.props.getGroupClassifications();
    this.props.getUserGroupsMonetized();
    this.props.getGigCategories();
    this.getGroupFundraisers();

    if (this.state.flow === 'volunteer') {
      this.initializeVolunteerSteps();
      if (this.props.userState.user.id) {
        this.props.getUserInfo(this.props.userState.user.id);
        this.props.getProfileByHandle(this.props.userState.user.id);
      }
    }

    if (this.state.flow === 'createEvent') {
      this.setState({
        isEvent: true,
      });
    }

    if (this.state.flow === 'createGroup') {
      this.setState(
        {
          groupType: 'new',
        },
        () => {
          // ensure group state clear if not coming back from stripe
          if (this.state.currentStep !== 'connect') this.resetGroup();
        },
      );
    }

    if (this.state.flow === 'fundraise') {
      this.props.getEvent(this.state.params?.event);
      this.props.getEventTeams(this.props.eventState.event.id, 0, 999);
      this.props.getFundraisingRegistrationTicket(this.props.eventState.event.id);
      this.userHasPurchasedRequiredItem();

      if (this.props.registerState?.flowConfig?.fundraiser) {
        this.props.getEvent(this.state.params?.event);
        // (C.H.) if flow is not in progress ensure it's reset
        if (!this.state.params?.currentStep) {
          this.props.resetFlowState();

          return;
        } else {
          let fundraiser = { ...this.props.registerState.flowConfig.fundraiser };

          this.setState(
            {
              fundraiser: { ...fundraiser },
            },
            () => {
              this.userHasPurchasedRequiredItem();
            },
          );
        }
      }
    }
  }

  userHasPurchasedRequiredItem() {
    Axios.get(
      swapRouteParams(routes.CHECK_FUNDRAISE_STATUS, {
        eventId: this.state.params.event,
        email: this.props.userState.user.email,
      }),
    ).then((response) => {
      if (response?.data) {
        this.setState(
          {
            individualRegistrationStatus: response.data,
          },
          () => {
            this.handleFundraiseTypeUpdate();
            if (this.state.individualRegistrationStatus.hasRegistrationTicket) {
              this.setState({
                fundraiser: { ...this.state.fundraiser, userHasTicket: true },
              });
            }
          },
        );
      }
    });
  }

  getGroupFundraisers() {
    Axios.get(routes.GET_GROUPS_OBOF_ALLOWED).then((response) => {
      if (response?.data) {
        let groupFundraisers: Array<ISearchableDropdownListItem> = response.data.map((gf: any) => {
          return { label: gf.title, id: gf.id, image: gf.profile_image_url };
        });
        this.setState({
          groupFundraisers: groupFundraisers,
        });
      }
    });
  }

  getGroupPayload() {
    let components = FlowConfigComponents(this.state.group.causes, 'group');
    let pages = FlowConfigPages(components);

    let newGroup = {
      group: {
        title: this.state.group.title,
        description: this.state.group.shortDescription,
        group_type: this.state.group.type,
        handle: this.state.group.title.replace(/ /g, '').toLowerCase(),
        accepting_volunteers: false,
        charity_id: this.state.charityId,
        group_class: this.state.group.group_class,
        group_sub_class: this.state.group.group_sub_class,
        fundraise_amount_range: this.state.group.fundraiseAmount,
        auto_tax_receipts: false,
        accepting_donations: false,
        group_template_type: this.state.group.type,
        localization: this.state.group.localization,
      },
      groupPages: this.makePageSequence(pages.acceptDonations.pages),
    };

    if (this.state.flow === 'donations') {
      newGroup.group.accepting_donations = true;
    }

    if (this.state.flow === 'createStore' && this.state.groupType === 'new') {
      newGroup.groupPages = this.makePageSequence([
        pages.acceptDonations.pages[0],
        pages.acceptDonations.pages[3],
        pages.acceptDonations.pages[4],
      ]);
    }

    return newGroup;
  }

  getGigPayload() {
    let newGig: any = {
      title: this.state.gig.title,
      description: this.state.gig.shortDescription,
      start_date: this.state.gig.startDate,
      end_date: this.state.gig.endDate,
      category: this.state.gig.category,
      sub_category: this.state.gig.subCategory,
      gig_type: this.state.gig.type,
      gigCauses: this.state.gig.causes,
      is_ossd_eligble: this.state.gig.isOSSDEligible,
      has_police_check: this.state.gig.hasPoliceCheck,
      is_virtual: this.state.gig.isVirtual,
      number_ranges: [
        {
          name: 'age_range',
          min_value: this.state.gig.ageRange.min,
          max_value: this.state.gig.ageRange.max,
        },
      ],
      price: {
        amount_type: 'hourly',
        amount: 0,
      },
      locations: [],
    };

    if (this.state.address) {
      newGig.locations.push(this.state.formattedAddress);
    }

    return newGig;
  }

  getEventPageTemplates(): string {
    //name these the same in view and can refactor this fun
    switch (this.state.event.type) {
      case 'crowdfunding': {
        return 'crowdfunding';
      }
      case 'Peer-to-Peer': {
        return 'p2p';
      }
      case 'Free Registration': {
        return 'free';
      }
      case 'Paid Ticketed': {
        return 'paid';
      }
      case 'Online Auction': {
        return 'online';
      }
      case 'custom': {
        return 'custom';
      }
      default: {
        return ''; //should return an actual default page set
      }
    }
  }

  makePageSequence(pages: Array<any>) {
    return pages.map((page: any, idx: number) => {
      let sequence = idx + 1;
      return { ...page, sequence: sequence };
    });
  }

  updateIsOnBehalfOf(value: boolean) {
    this.setState({
      isOnBehalfOf: value,
    });
  }

  getEventPayload() {
    let components = FlowConfigComponents([], 'event');
    let pageConfig = FlowConfigPages(components);
    let templates = this.getEventPageTemplates();
    let pages: any = pageConfig.event[templates].pages;

    if (this.state.event.isVirtual) {
      //do not create locatins tab for virtual events. GIG-2642
      pages = pages.filter((page: any) => page.label !== 'Locations');
    }

    pages = this.makePageSequence(pages);
    //make interface
    let newEvent: any = {
      title: this.state.event.title,
      description: this.state.event.shortDescription,
      goal: this.state.event.goal,
      start_date: moment(this.state.event.startDate),
      end_date: moment(this.state.event.endDate),
      is_virtual: false,
      locations: [],
      event_template_type: this.state.event.type,
      show_campaign_progress: true,
    };

    if (this.state.isOnBehalfOf) {
      newEvent.event_type = 'obo_fundraiser';
      newEvent.event_organizer_name = this.state.event?.eventOrganizerName;
      newEvent.accepting_donations = true;
    }

    if (this.state.event.isVirtual) {
      newEvent.is_virtual = true;
    }

    if (this.state.address) {
      newEvent.locations.push(this.state.formattedAddress);
    }

    if (this.state.event.type === 'crowdfunding' || this.state.event.type === 'Peer-to-Peer') {
      newEvent.accepting_donations = true;
    }

    if (this.state.flow === 'createStore') {
      // already includes store within templates
      if (
        this.state.event.type !== 'Paid Ticketed' &&
        this.state.event.type !== 'Free Registration'
      ) {
        pages.push({
          label: 'Store',
          icon: 'fas fa-info-circle',
          components: [components.components.storePayload],
        });
      }
    }

    if (this.state.flow === 'createAuction') {
      if (this.state.event.type !== 'Online Auction') {
        pages.push({
          label: 'Auction',
          icon: 'fas fa-info-circle',
          components: [components.components.auctionsPayload],
        });
      }
    }

    let isFlow = true;
    return { event: newEvent, pages, isFlow };
  }

  populateUserCauses(callback?: () => void) {
    let uCauses = {
      visibility: 'public',
      sequence: 2,
      title: 'Focus Areas',
      component_type: 'cause',
      content_references: {
        object_ids: [],
        object_type: 'cause',
      },
      meta_data: {
        causes: [...this.state.findCauses],
      },
    };
    //create page with causes component
    let newPage = {
      sequence: 0,
      label: 'Focus Areas I Support',
      icon: 'fad fa-info-circle',
      components: [uCauses],
    };

    let profilePages = this.props.profileState.pages.find((e) => e.label === newPage.label);

    if (profilePages && profilePages.components) {
      let components = profilePages.components.find((e) => e.component_type === 'cause');
      if (components && this.state.findCauses.length) {
        components?.meta_data?.causes.push(this.state.findCauses);
      }
      this.props.updateUserPage(profilePages);
    } else {
      this.props.createUserPage(newPage);
    }

    if (this.state.flow === 'volunteer') {
      let emergencyContacts = this.props.userState.user.emergency_contacts || [];
      emergencyContacts.push({
        name: this.state.userInfo.emergName,
        phone: this.state.userInfo.emergPhone,
      });
      let payload = {
        first_name: this.state.userInfo.first_name,
        last_name: this.state.userInfo.last_name,
        phone: this.state.userInfo.phone,
        emergency_contacts: emergencyContacts,
      };

      this.props.updateUser(payload, undefined, {
        callback: callback,
      });
    } else {
      this.props.updateUser({ causes: this.state.findCauses }, undefined, {
        callback: callback,
      });
    }
  }

  createNewGroupPage() {
    let newPage;
    let components = FlowConfigComponents(this.state.event.causes);
    let pages = FlowConfigPages(components);

    if (this.state.flow === 'createStore') {
      newPage = pages.createStore.pages[0];
    }

    if (this.state.flow === 'createAuction') {
      newPage = pages.createAuction.pages[0];
    }

    if (this.state.groupType === 'existing') {
      this.props.createGroupPage(this.props.groupState.group.id, newPage);
    }
  }

  createNewEventPage() {
    let newPage;
    let components = FlowConfigComponents(this.state.group.causes);
    let pages = FlowConfigPages(components);

    if (this.state.flow === 'createStore') {
      newPage = pages.createStore.pages[0];
    }

    if (this.state.flow === 'createAuction') {
      newPage = pages.createAuction.pages[0];
    }

    this.props.createEventPage(this.props.eventState.event.id, newPage);
  }

  //abstract to generic state updates? (see other updates too)
  toggleTaxSkip(val: boolean) {
    this.setState({
      showTaxSkipModal: val,
    });
  }

  toggleCampaignSkip(val: boolean) {
    this.setState({
      showCampaignSkipModal: val,
    });
  }

  getEventHandle() {
    return this.props.eventState.event?.handle || '';
  }

  async checkHandle(type: 'event' | 'group') {
    let route =
      type === 'event'
        ? swapRouteParams(routes.VERIFY_EVENT_HANDLE, {
            handle: this.state.event.title.replace(/ /g, '').toLowerCase(),
          })
        : swapRouteParams(routes.VERIFY_GROUP_HANDLE, {
            handle: this.state.group.title.replace(/ /g, '').toLowerCase(),
          });

    return await Axios.get<ISimpleValue<boolean>>(route);
  }

  async onGroupInfoNext() {
    if (this.state.currentStep === 'groupInfo' && !this.props.groupState.group.id) {
      await this.checkHandle('group').then((response) => {
        if (!response.data?.value) {
          const errorObj = errorHelpers.getErrorObjectFromSimpleValue(response.data);
          let toast = toastError(errorObj.translatedMessage, 'Cause Title');
          this.props.createToast(toast);

          this.setState({
            hasValidGroupHandle: false,
          });
        } else if (response.data.value) {
          this.setState({
            hasValidGroupHandle: true,
          });
        }
      });
    } else if (this.state.currentStep === 'groupInfo' && this.props.groupState.group.id) {
      this.setState({
        hasValidGroupHandle: true,
      });
    }
  }

  async onEventInfoNext() {
    if (this.state.currentStep === 'eventInfo' && !this.state.event.id) {
      await this.checkHandle('event').then((response) => {
        if (!response.data?.value) {
          const errorObj = errorHelpers.getErrorObjectFromSimpleValue(response.data);
          let toast = toastError(errorObj.translatedMessage, 'Event Title');
          this.props.createToast(toast);
          this.setState({
            hasValidEventHandle: false,
          });
        } else if (response.data.value) {
          this.setState({
            hasValidEventHandle: true,
          });
        }
      });
    } else if (this.state.currentStep === 'eventInfo' && this.state.event.id) {
      this.setState({
        hasValidEventHandle: true,
      });
    }
  }

  getNextStep() {
    if (this.state.currentStep === 'cta' && this.state.findCTA) {
      this.populateUserCauses();

      if (this.state.findCTA === 'profile') {
        this.props.history.push(`/user/${this.props.userState.user.handle}`);
        return;
      } else if (this.state.findCTA === 'search' && this.state.flow === 'findGroups') {
        this.props.history.push(`/search?type=groups`);
        return;
      } else if (this.state.findCTA === 'search' && this.state.flow === 'findEvents') {
        this.props.history.push(`/search?type=events`);
        return;
      } else if (this.state.findCTA === 'find') {
        this.props.history.push(`/search?type=volunteer`);
        return;
      } else if (this.state.findCTA === 'backToApply') {
        this.props.history.push(this.state.params.isRedirect);
      }
    }

    // If user is coming from a redirect (We assume is the gig url) then we goto that redirect url after user picks cause.
    if (
      this.props.flowType === 'volunteer' &&
      this.state.currentStep === 'causeSelection' &&
      this.state.params.isRedirect
    ) {
      this.populateUserCauses(() => {
        this.props.history.push(this.state.params.isRedirect);
      });

      return;
    }

    if (this.state.currentStep === 'isEvent') {
      if (this.state.isEvent) {
        if (this.state.eventType === 'existing') {
          this.props.history.push(`/event/${this.state.event.handle}`);
        }
      } else {
        //update ExistingGroup With Store Or Auction new page
        if (this.state.groupType === 'existing' && !this.state.isOnBehalfOf) {
          this.createNewGroupPage();
        }
      }
    }

    if (this.state.currentStep === 'eventSelection') {
      if (this.state.eventType === 'existing') {
        //update event with store or auction page
        this.createNewEventPage();
        this.props.history.push(`/event/${this.state.event.handle}`);
        return;
      }
    }

    if (this.state.currentStep === 'gigTimeAndLocation' && this.state.gig.type === 'volunteer') {
      if (this.state.groupType === 'existing') {
        let payload = this.getGigPayload();
        this.props.createGroupGig(this.props.groupState.group.id, payload, { shouldGetGig: true });
      } else {
        let groupPayload = this.getGroupPayload();
        let gigPayload = this.getGigPayload();
        this.props.createGroupFlow(groupPayload, undefined, gigPayload);
      }
      return;
    }

    if (this.state.currentStep === 'tax' && !this.state.skipTax) {
      if (
        !Boolean(this.state.legalCharityName) ||
        !Boolean(this.state.signatureName) ||
        !Boolean(this.state.signatureTitle) ||
        !Boolean(this.state.formattedAddress) ||
        !Boolean(this.state.signature)
      ) {
        this.setState({
          showTaxSkipModal: true,
        });

        return;
      }
    }

    if (this.state.currentStep === 'eventTimeAndLocation' && this.state.isOnBehalfOf) {
      let payload = this.getEventPayload();
      this.props.createEvent(this.state.group?.id, payload?.event, {
        _pages: payload?.pages,
        isFlow: payload?.isFlow,
      });
      return;
    }

    if (
      this.state.currentStep === 'tickets' &&
      this.state.flow === 'fundraise' &&
      !this.state.steps.includes('tickets')
    ) {
      let config = this.getFlowStatePayload();
      this.props.setFlow(config);

      if (this.props.userState.isLoggedIn === false) {
        this.props.history.push({
          pathname: `/login`,
          search:
            '?redirect=' +
            encodeURIComponent(
              `/setup/${flowRoutes[this.state.flow]}?currentStep=isContentCreator&event=${this.props.eventState.event.handle}`,
            ),
        });
      }
    }

    let hasRequiredItems = this.props.eventState?.fundraisingTickets?.length > 0;

    if (
      this.state.currentStep === 'addParticipant' &&
      (this.state.fundraiser?.ticket ||
        (this.state.individualRegistrationStatus.hasRegistrationTicket &&
          this.state.fundraiser.hasParticipants &&
          this.state.fundraiser.participants.find((p: any) => p?.email))) &&
      hasRequiredItems
    ) {
      let config = this.getFlowStatePayload();
      this.props.setFlow(config);

      this.props.history.push({
        pathname: '/checkout',
        search: 'fundraiser=true',
      });
    } else if (
      this.state.currentStep === 'addParticipant' &&
      (!hasRequiredItems ||
        (this.state.individualRegistrationStatus.hasRegistrationTicket &&
          !this.state.fundraiser.hasParticipants)) &&
      (this.props.eventState.event.group_id || this.props.eventState.event.hub != null)
    ) {
      let config = this.getFlowStatePayload();
      this.props.setFlow(config);

      if (this.props.userState.isLoggedIn === false) {
        this.props.history.push({
          pathname: `/login`,
          search:
            '?redirect=' +
            encodeURIComponent(
              `/setup/${flowRoutes[this.state.flow]}?currentStep=addParticipant&event=${this.props.eventState.event.handle}`,
            ),
        });

        return;
      }

      let participants = this.props.registerState.flowConfig.fundraiser?.participants?.filter(
        (p: IRegisterToFundraiserParticipant) => p?.isFundraising,
      );
      let fundraisePayload: any = {
        individual_create: {
          goal: this.state.fundraiser?.goal,
          story: '',
          content_creator: this.state.fundraiser.content_creator,
          fitness_tracking_enabled: this.state.fundraiser.fitness_tracking_enabled,
        },
        team_create: null,
        join_team: null,
        participants: participants,
      };

      if (this.state.fundraiser.type === 'create') {
        fundraisePayload.team_create = {
          name: this.state.fundraiser.teamName,
          goal: this.state.fundraiser.teamGoal,
          personal_goal: this.state.fundraiser?.goal,
        };
      }

      if (this.state.fundraiser.type === 'join') {
        fundraisePayload.join_team = {
          story: '',
          goal: this.state.fundraiser.goal,
          content_creator: false,
          teamId: this.state.fundraiser?.teamId,
          fitness_tracking_enabled: false,
        };
      }

      this.props.purchaseFundraise(
        this.props.eventState.event.group_id,
        this.props.eventState.event.id,
        fundraisePayload,
      );
    }

    //after changing current step
    if (this.state.currentStep !== 'connect') {
      if (
        this.state.steps.indexOf(this.state.currentStep) ||
        this.state.steps.indexOf(this.state.currentStep) === 0
      ) {
        let increaseStepBy = 1;
        let eventTypePayload = undefined as undefined | string;
        if (
          this.state.steps.indexOf(this.state.currentStep) === 0 &&
          this.state.isEvent &&
          this.state.params?.type
        ) {
          increaseStepBy = 2;
          switch (this.state.params.type) {
            case 'online_auction':
              eventTypePayload = 'Online Auction';
              break;
            case 'crowdfunding':
              eventTypePayload = 'crowdfunding';
              break;
          }

          if (eventTypePayload) this.updateEvent('type', eventTypePayload);
        }

        this.setState(
          {
            currentStep:
              this.state.steps[this.state.steps.indexOf(this.state.currentStep) + increaseStepBy],
          },
          () => {
            // we need to create the group so we can create and attach a campaign here if it's groupType === new
            if (
              this.state.currentStep === 'connect' &&
              !this.state.group?.id &&
              this.state.group.title !== this.props.groupState.group.title
            ) {
              let groupPayload = this.getGroupPayload();
              let eventPayload: { event: any; pages: any; isFlow: boolean };
              if (this.state.isEvent && this.state.eventType === 'new') {
                eventPayload = this.getEventPayload();
                this.props.createGroupFlow(groupPayload, eventPayload);
              } else {
                this.props.createGroupFlow(groupPayload);
              }
            } else if (
              this.state.currentStep === 'connect' &&
              this.state.group.id &&
              this.state.group.id === this.props.groupState.group.id
            ) {
              this.updateExistingGroup();
              if (this.state.isEvent && this.state.eventType === 'new' && !this.state.event.id) {
                let payload = this.getEventPayload();
                this.props.createEvent(this.state.group?.id, payload?.event, {
                  _pages: payload?.pages,
                  isFlow: true,
                });
              } else if (
                this.state.isEvent &&
                this.state.eventType === 'new' &&
                this.state.event.id
              ) {
                this.props.updateEvent(this.state.event);
              }
            }

            let config = this.getFlowStatePayload();
            this.props.setFlow(config);
          },
        );
      }
    } else {
      // need to call get group to update account status
      this.props.getGroup(this.props.groupState.group.id);
      if (
        (this.props.groupState.group.account &&
          this.props.groupState.group.campaign_id === this.state.selectedCampaign) ||
        this.state.skipCampaign
      ) {
        if (
          this.state.steps.indexOf(this.state.currentStep) ||
          this.state.steps.indexOf(this.state.currentStep) === 0
        ) {
          if (
            this.state.selectedCampaign === this.props.groupState.group.campaign_id &&
            !!this.props.eventState.event.id
          ) {
            this.props.updateEvent({
              ...this.props.eventState.event,
              accepting_donations:
                this.state.selectedCampaign === this.props.groupState.group.campaign_id,
              campaign_id:
                this.state.selectedCampaign === this.props.groupState.group.campaign_id
                  ? this.state.selectedCampaign
                  : undefined,
            });
          }

          this.setState(
            {
              currentStep: 'connect',
            },
            () => {
              let isComplete = true;
              this.props.resetFlowState(isComplete);
            },
          );
        }
      } else if (
        (this.props.groupState.group.account &&
          this.props.groupState.group.campaign_id !== this.state.selectedCampaign) ||
        this.state.skipCampaign
      ) {
        if (this.state.isEvent) {
          this.props.updateEvent({
            ...this.props.eventState.event,
            campaign_id: this.state.selectedCampaign,
          });
        } else {
          this.props.updateGroup(
            { campaign_id: this.state.selectedCampaign },
            this.props.groupState.group.id,
            undefined,
            true,
          );
        }
      } else {
        this.setState({
          showCampaignSkipModal: true,
        });
      }
    }
  }

  getBaseSteps(type: string) {
    if (type) {
      switch (type) {
        case 'donations': {
          return stepConfigurations.donationsNewGroup;
        }
        case 'createStore': {
          return stepConfigurations.storeNewGroup;
        }
        case 'createAuction': {
          return stepConfigurations.auctionNewGroup;
        }
        case 'findEvents': {
          return stepConfigurations.find;
        }
        case 'findGroups': {
          return stepConfigurations.find;
        }
        case 'volunteer': {
          return stepConfigurations.volunteer;
        }
        case 'createGig': {
          return stepConfigurations.gigNewGroup;
        }
        case 'createEvent': {
          return stepConfigurations.eventOrFundraiser;
        }
        case 'createGroup': {
          return stepConfigurations.groupSteps;
        }
        case 'fundraise': {
          return stepConfigurations.individual;
        }
        default: {
          return [];
        }
      }
    } else {
      return [];
    }
  }

  updateExistingGroup() {
    let group: any = {
      title: this.state.group.title,
      description: this.state.group.shortDescription,
      group_type: this.state.group.type,
      charity_id: this.state.charityId,
    };

    if (this.state.isCharity) {
      group.group_class = this.state.group.group_class;
      group.group_sub_class = this.state.group.group_sub_class;
    }

    let isPages = false;
    let isFlow = true;
    this.props.updateGroup(group, this.state.group.id, isPages, isFlow);
  }

  stripeConnect() {
    let defaultPayload: any = {
      title: 'Default',
      status: { code: 'active' },
      goal: 0,
      funds: [],
    };

    let shouldDefaultEvent = this.state.isEvent && this.props.eventState.event.id;

    if (
      this.props.groupState.group.title === this.state.group.title &&
      (this.state.groupType === 'new' || this.props.registerState.flowConfig.groupType === 'new')
    ) {
      if (this.state.selectedCampaign === 'default') {
        if (shouldDefaultEvent) {
          this.props.createGroupCampaign(
            this.props.groupState.group.id,
            defaultPayload,
            true,
            this.props.eventState.event.id,
          );
        } else {
          this.props.createGroupCampaign(this.props.groupState.group.id, defaultPayload, true);
        }
      }
    } else if (this.state.groupType === 'existing' && !this.state.group.campaign_id) {
      if (shouldDefaultEvent) {
        this.props.createGroupCampaign(
          this.props.groupState.group.id,
          defaultPayload,
          true,
          this.props.eventState.event.id,
        );
      } else {
        this.props.createGroupCampaign(this.props.groupState.group.id, defaultPayload, true);
      }
    }
  }

  getOptions() {
    let opts: Array<IOptions> = [{ value: '', label: localizeHelpers.translate('Select A Cause') }];

    //depends on the flow we need to show in the dropdown
    //the only groups that user has appropriate permissions for. GIG-2089 (bug fix)
    const permissionsMap: { [flow: string]: string } = {
      createGig: 'MANAGE_GIGS',
      createEvent: 'MANAGE_GROUP_EVENTS',
      donations: 'MANAGE_DONATIONS',
      createStore: 'MANAGE_STORE_ITEMS',
      createAuction: 'MANAGE_AUCTIONS',
    };

    const permissionInvolved = permissionsMap[this.state.flow];

    if (permissionInvolved) {
      let params = {
        params: { 'field_filters[status.code]': `<>${Constants.group_status.archived}` },
      };
      Axios.get(
        swapRouteParams(routes.GET_USER_GROUPS_FOR_PERMISSION, { permission: permissionInvolved }),
        params,
      ).then((response) => {
        if (response?.data?.length > 0) {
          opts = opts.concat(
            response.data.map((group: IGroup) => {
              return { value: group.id, label: group.title };
            }),
          );

          this.setState({
            monetizedGroups: opts,
          });
        }
      });
    } else {
      opts = opts.concat(
        this.props.userState.monetizedGroups.map((group: any) => {
          return { value: group.id, label: group.title };
        }),
      );

      this.setState({
        monetizedGroups: opts,
      });
    }
  }

  getGroupEventOptions() {
    let opts = this.props.groupState.groupEvents.map((e: any) => {
      return { value: e.id, label: e.title };
    });

    opts.unshift({ value: '', label: localizeHelpers.translate('Select an Event') });

    this.setState({
      groupEvents: opts,
    });
  }

  getCampaignOptions() {
    const defaultOpts = [
      { value: '', label: localizeHelpers.translate('Select A Campaign') },
      { value: 'default', label: localizeHelpers.translate('Default') },
    ];
    const setDefault = () => {
      this.setState(
        {
          campaignOptions: defaultOpts,
        },
        () => {
          this.setState({
            selectedCampaign: 'default',
          });
        },
      );
    };

    if (this.state.group && this.state.group?.id) {
      if (this.props.groupState.groupCampaigns.length > 0) {
        let opts = this.props.groupState.groupCampaigns.map((c: ICampaign) => {
          return { value: c.id || '', label: c.title || '' };
        });

        opts.unshift({ value: '', label: localizeHelpers.translate('Select A Campaign') });

        this.setState(
          {
            campaignOptions: opts,
          },
          () => {
            if (
              this.props.groupState?.group?.campaign_id ||
              this.props.groupState?.campaignConnect?.campaignId
            ) {
              this.setState({
                selectedCampaign: this.props.groupState?.group?.campaign_id || 'default',
              });
            }
          },
        );
      } else {
        setDefault();
      }
    } else {
      setDefault();
    }
  }

  updateSignature(value?: string) {
    this.setState({
      signature: value,
    });
  }

  uploadSignatureImg(file: any) {
    this.state.canvasRef.current.fromDataURL(file.src, { width: 300, height: 75 });
    this.updateSignature(file.src);
  }

  clear() {
    this.setState({
      signature: null,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.state.resizeListener);
  }

  initResize() {
    let widthListener = () => {
      this.setState({
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', widthListener);
  }

  setLocation(address: any) {
    this.setState({ address: address.result.formatted_address }, () => {
      let newAddress = this.getAddressParts(address.result);

      this.setState({
        address: address.result.formatted_address,
        formattedAddress: {
          line1:
            newAddress.street_number !== undefined && newAddress.route !== undefined
              ? newAddress.street_number + ' ' + newAddress.route
              : undefined,
          line2: newAddress.neighborhood,
          city: newAddress.locality,
          state: newAddress.administrative_area_level_1,
          country: newAddress.country,
          postal_code: newAddress.postal_code,
          searchable: true,
          location: {
            type: 'group',
            coordinates: address.coordinates,
          },
        },
      });
    });
  }

  getAddressParts(object: any) {
    let address: any = {};
    const address_components = object.address_components;

    address_components.forEach((element: any) => {
      address[element.types[0]] = element.long_name;
    });

    return address;
  }

  isStepDisabled() {
    switch (this.state.currentStep) {
      case 'groupSelection': {
        if (this.state.groupType === 'new') {
          return false;
        } else if (this.state.groupType === 'existing') {
          return !Boolean(this.state.selectedGroup);
        } else {
          return true;
        }
      }
      case 'isCharity': {
        if (this.state.isCharity) {
          return !Boolean(this.state.charityId.trim());
        } else {
          return false;
        }
      }
      case 'tax': {
        return false;
      }
      case 'groupType': {
        return !Boolean(this.state.group.type);
      }
      case 'groupInfo': {
        return (
          !Boolean(this.state.group.title?.trim()) ||
          !Boolean(this.state.group.shortDescription?.trim())
        );
      }
      case 'groupLocalization': {
        return (
          !Boolean(this.state.group.localization.country) ||
          !Boolean(this.state.group.localization.state) ||
          !Boolean(this.state.group.localization.defaultTimezone)
        );
      }
      case 'groupClassifications': {
        return !Boolean(this.state.group.group_class) || !Boolean(this.state.group.group_sub_class);
      }
      case 'connect': {
        return this.props.registerState.flowConfig.isComplete;
      }
      case 'isEvent': {
        return false;
      }
      case 'eventSelection': {
        if (this.state.eventType === 'existing') {
          return !Boolean(this.state.event.id);
        } else return this.state.eventType !== 'new';
      }
      case 'eventType': {
        return !Boolean(this.state.event?.type);
      }
      case 'eventInfo': {
        return (
          !Boolean(this.state.event.title?.trim()) ||
          !Boolean(this.state.event.shortDescription?.trim()) ||
          (this.state.isOnBehalfOf && !Boolean(this.state.event.eventOrganizerName?.trim()))
        );
      }
      case 'causeSelection': {
        return false;
      }
      case 'cta': {
        return !Boolean(this.state.findCTA);
      }
      case 'eventTimeAndLocation': {
        let hasLocation = this.state.event.isVirtual || this.state.event.isLocation;
        return (
          !Boolean(this.state.event.startDate) || !Boolean(this.state.event.endDate) || !hasLocation
        );
      }
      case 'gigTimeAndLocation': {
        return !Boolean(this.state.event.startDate) || !Boolean(this.state.event.endDate);
      }
      case 'volunteerInfo': {
        return (
          !Boolean(this.state.userInfo.first_name) ||
          !Boolean(this.state.userInfo.last_name) ||
          !Boolean(this.state.userInfo.email) ||
          !Boolean(this.state.userInfo.phone)
        );
      }
      case 'volunteerEmergency': {
        return !Boolean(this.state.userInfo.emergName) || !Boolean(this.state.userInfo.emergPhone);
      }
      case 'gigType': {
        return !Boolean(this.state.gig.type);
      }
      case 'gigInfo': {
        return !Boolean(this.state.gig.title) || !Boolean(this.state.gig.shortDescription);
      }
      case 'gigCategories': {
        return !Boolean(this.state.gig.category) || !Boolean(this.state.gig.sub_category);
      }
      case 'fundraiseType': {
        return !Boolean(this.state.fundraiser?.type);
      }
      case 'goalIndividual': {
        return !Boolean(this.state.fundraiser?.goal);
      }
      case 'goalTeam': {
        return (
          !Boolean(this.state.fundraiser?.teamName) || !Boolean(this.state.fundraiser?.teamGoal)
        );
      }
      case 'teamSelect': {
        return !this.state.fundraiser.teamId;
      }
      case 'isContentCreator': {
        return false;
      }
      case 'connectStravaAccount': {
        return false;
      }
      case 'tickets': {
        return !Boolean(this.state.fundraiser?.ticket);
      }
      case 'addParticipant': {
        if (this.state.fundraiser.hasParticipants) {
          return this.state.fundraiser?.participants?.length <= 0;
        } else {
          return false;
        }
      }
      default: {
        return true;
      }
    }
  }

  updateGroup(field: string, value: any) {
    let group = Object.assign({}, this.state.group);
    group[field] = value;

    this.setState(
      {
        group: group,
      },
      () => {
        if (field === 'type') {
          this.handleGroupType(value);
        }
      },
    );
  }

  handleGroupType(value: string) {
    let isNotCharityType = value !== 'non-profit' && value !== 'serviceclub';
    let isCharityType = value === 'non-profit' || value === 'serviceclub';

    if (isNotCharityType && this.state.flow === 'donations') {
      this.setState({
        steps: stepConfigurations.donationsNewGroup,
      });
    } else if (isNotCharityType && this.state.flow === 'createStore') {
      this.setState({
        steps: stepConfigurations.storeNewGroup,
      });
    } else if (isNotCharityType && this.state.flow === 'createGroup') {
      this.setState({
        steps: stepConfigurations.groupSteps,
      });
    } else if (isCharityType && this.state.flow === 'createGroup') {
      this.setState({
        steps: stepConfigurations.groupStepsCharity,
      });
    } else if (isCharityType && this.state.flow === 'donations') {
      this.setState({
        steps: stepConfigurations.donationsNewCharityGroup,
      });
    } else if (isCharityType && this.state.flow === 'createStore') {
      this.setState({
        steps: stepConfigurations.storeNewGroupCharity,
      });
    }
  }

  updateEvent(field: string, value: any) {
    let event = Object.assign({}, this.state.event);
    event[field] = value;

    this.setState({
      event: event,
    });
  }

  updateGig(field: string, value: any) {
    let gig = Object.assign({}, this.state.gig);
    gig[field] = value;

    this.setState({
      gig: gig,
    });
  }

  updateGroupType(value: any) {
    this.setState({
      group: {
        ...this.state.group,
        type: value,
      },
    });
  }

  updateEntityType(type: 'groupType' | 'eventType', value: string) {
    if (type === 'groupType') {
      this.setState({
        groupType: value,
      });
    } else if (type === 'eventType') {
      this.setState({
        eventType: value,
      });
    }
  }

  setExistingGroup(id: string) {
    this.setState(
      {
        selectedGroup: id,
      },
      () => {
        let g = this.props.userState.monetizedGroups.find((group) => {
          return group.id === this.state.selectedGroup;
        });

        // FIXME: This seems to be mixing ISearchableDropdownListItem with IGroup?
        if (!g) {
          g = this.state.groupFundraisers.find((group) => {
            return group.id === this.state.selectedGroup;
          }) as any as IUserObjectWithRole<IGroup>;
        }

        if (g) {
          this.setState(
            {
              group: g,
            },
            () => {
              this.props.getGroup(id);
            },
          );
        }
      },
    );
  }

  setExistingEvent(id: string) {
    this.setState(
      {
        selectedEvent: id,
      },
      () => {
        let e = this.props.groupState.groupEvents.find((e) => {
          return e.id === this.state.selectedEvent;
        });
        if (e) {
          this.setState(
            {
              event: e,
            },
            () => {
              if (this.state.event.handle) {
                this.props.getEvent(this.state.event.handle);
              }
            },
          );
        }
      },
    );
  }

  handleCampaignSkip() {
    this.setState({ skipCampaign: true, showCampaignSkipModal: false }, () => this.getNextStep());
  }

  handleTaxSkip() {
    this.setState({ skipTax: true, showTaxSkipModal: false }, () => this.getNextStep());
  }

  handleCampaignSelect(campId: string) {
    this.setState(
      {
        selectedCampaign: campId,
      },
      () => {
        if (this.props.groupState.group.id) {
          this.props.getGroupCampaign(this.props.groupState.group?.id, this.state.selectedCampaign);
        }
      },
    );
  }

  resetGroup() {
    //@TODO interfaces would make this more consistent (what if you forget a value here?)
    this.setState({
      group: {
        type: '',
        title: '',
        shortDescription: '',
        group_class: '',
        group_sub_class: '',
        fundraiseAmount: '',
        causes: [],
        localization: {
          country: '',
          defaultTimezone: '',
          state: '',
        },
      },
      isCharity: false,
      charityId: '',
      selectedGroup: null,
    });

    this.props.resetGroupState();
  }

  resetEvent() {
    this.setState({
      event: {
        type: '',
        title: '',
        goal: '',
        causes: [],
        isVirtual: false,
        isLocation: false,
      },
    });

    this.props.resetEventState();
  }

  updateIsCharity(value: boolean) {
    this.setState(
      {
        isCharity: value,
      },
      () => {
        this.isStepDisabled(); //is this needed
      },
    );
  }

  updateCharityId(value: string) {
    this.setState({
      charityId: value,
    });
  }

  updateIsEvent(value: boolean) {
    this.setState(
      {
        isEvent: value,
      },
      () => {
        this.props.setFlow({ ...this.props.registerState.flowConfig, isEvent: value });
      },
    );
  }

  addFindCause(event: any) {
    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;
    let causes = [...this.state.findCauses];
    causes.push(value);

    this.setState({
      findCauses: causes,
      findCauseValue: '',
    });
  }

  removeFindCause(id: string) {
    let causes = [...this.state.findCauses];
    causes = causes.filter((e) => e !== id);

    this.setState({
      findCauses: causes,
    });
  }

  updateFindCauses(c: string) {
    let causes = [...this.state.findCauses];
    causes.push(c);

    this.setState({
      findCauses: causes,
    });
  }

  updateCTA(val: string) {
    this.setState({
      findCTA: val,
    });
  }

  hasEmergencyContacts() {
    return (
      this.props.userState.user.emergency_contacts &&
      this.props.userState.user.emergency_contacts[0]
    );
  }

  hasCampaignAccount(isDefaultAccount: boolean) {
    if (isDefaultAccount) {
      return (
        !!this.props.groupState.group.account &&
        this.state.selectedCampaign === this.props.groupState?.group.campaign_id
      );
    } else {
      return !!this.props.groupState.group.account;
    }
  }

  initializeVolunteerSteps() {
    if (this.hasEmergencyContacts()) {
      this.setState({
        steps: this.state.params.isRedirect
          ? stepConfigurations.volunteerNoECNoCta
          : stepConfigurations.volunteerNoEC,
      });
    } else {
      this.setState({
        steps: this.state.params.isRedirect
          ? stepConfigurations.volunteerNoCta
          : stepConfigurations.volunteer,
      });
    }
  }

  hasMonetizedGroups() {
    return this.state.monetizedGroups.length > 0;
  }

  getIntialDatesRounded(): moment.Moment {
    const start = moment();
    const remainder = 15 - (start.minute() % 15);

    return moment(start).add(remainder, 'minutes');
  }

  getLocationSearch() {
    return queryString.parse(this.props.location.search);
  }

  isBackButtonDisabled(): boolean {
    const params = this.getLocationSearch();

    return !!(params.joinTeam && this.state.currentStep === 'goalIndividual');
  }

  updateFundraiser(field: string, value: any) {
    let fundraiser = Object.assign({}, this.state.fundraiser);
    fundraiser[field] = value;

    this.setState(
      {
        fundraiser: fundraiser,
      },
      () => {
        let config = this.getFlowStatePayload();
        this.props.setFlow(config);
      },
    );
  }

  updateFundraiseHeader() {
    if (this.state.fundraiser?.type === 'individual') {
      return 'Fundraise as an Individual';
    } else if (this.state.fundraiser?.type === 'create') {
      return 'Create a Fundraising Team';
    } else if (this.state.fundraiser.type === 'join') {
      return 'Join a Fundraising Team';
    }
  }

  addTicket(ticket: any) {
    this.setState({
      fundraiser: {
        ...this.state.fundraiser,
        ticket: ticket,
      },
    });
  }

  render() {
    if (this.props.userState.isLoggedIn === false && this.state.flow !== 'fundraise') {
      return <Redirect to={`/login?redirect=/setup/${flowRoutes[this.state.flow]}`} />;
    } else {
      return (
        <div className="Flow">
          <div className={`${this.state.flow}`}>
            <div className="form-section">
              <StepsHeader
                flow={this.state.flow}
                steps={this.state.steps}
                currentStep={this.state.currentStep}
                currentStepIndex={this.getCurrentStepIndex()}
                locale={this.props.locale}
                entityTitle={
                  this.state.flow === 'fundraise' ? this.props.eventState.event.title : ''
                }
                overrideHeaderTxt={
                  this.state.flow === 'fundraise' ? this.updateFundraiseHeader() : ''
                }
              />

              {this.state.currentStep === 'groupSelection' && (
                <GroupSelection
                  flow={this.state.flow}
                  monetizedGroups={this.state.monetizedGroups}
                  selectedGroup={this.state.selectedGroup}
                  groupType={this.state.groupType}
                  groupFundraisers={this.state.groupFundraisers}
                  updateIsOnBehalfOf={this.updateIsOnBehalfOf}
                  updateEntityType={this.updateEntityType}
                  setExistingGroup={this.setExistingGroup}
                  hasMonetizedGroups={this.hasMonetizedGroups}
                  resetGroup={this.resetGroup}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'groupType' && (
                <EntityTypeSelection
                  entity={this.state.group}
                  type="group"
                  flow={this.state.flow}
                  hasFundraiseTeams={!!this.state.fundraiseTeams.length}
                  updateEntity={this.updateGroup}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'eventType' && (
                <EntityTypeSelection
                  entity={this.state.event}
                  type="event"
                  flow={this.state.flow}
                  hasFundraiseTeams={!!this.state.fundraiseTeams.length}
                  updateEntity={this.updateEvent}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'gigType' && (
                <EntityTypeSelection
                  entity={this.state.gig}
                  type="gig"
                  flow={this.state.flow}
                  hasFundraiseTeams={!!this.state.fundraiseTeams.length}
                  updateEntity={this.updateGig}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'fundraiseType' && (
                <EntityTypeSelection
                  entity={this.state.fundraiser}
                  type="fundraise"
                  flow={this.state.flow}
                  hasFundraiseTeams={!!this.state.fundraiseTeams.length}
                  updateEntity={this.updateFundraiser}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'goalIndividual' && (
                <FundraiseGoal
                  entity={this.state.fundraiser}
                  type="individual"
                  flow={this.state.flow}
                  updateEntity={this.updateFundraiser}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'goalTeam' && (
                <FundraiseGoal
                  entity={this.state.fundraiser}
                  type={this.state.fundraiser.type}
                  flow={this.state.flow}
                  updateEntity={this.updateFundraiser}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'isContentCreator' && (
                <ContentCreator
                  flow={this.state.flow}
                  fundraiser={this.state.fundraiser}
                  updateFundraiser={this.updateFundraiser}
                  user={this.props.userState.user}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'connectStravaAccount' && (
                <ConnectStravaAccount
                  flow={this.state.flow}
                  fundraiser={this.state.fundraiser}
                  updateFundraiser={this.updateFundraiser}
                  user={this.props.userState.user}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'tickets' && (
                <FundraiseTickets
                  flow={this.state.flow}
                  fundraiser={this.state.fundraiser}
                  fundraisingTicket={this.props.eventState.fundraisingTicket}
                  fundraisingTickets={this.props.eventState.event?.required_item_ids || []}
                  addTicket={this.addTicket}
                  updateFundraiser={this.updateFundraiser}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'addParticipant' && (
                <AddParticipant
                  flow={this.state.flow}
                  fundraiser={this.state.fundraiser}
                  registrationId={this.props.eventState.event.registration_item_id ?? null}
                  updateFundraiser={this.updateFundraiser}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'teamSelect' && (
                <FundraiseTeamSearch
                  flow={this.state.flow}
                  fundraiser={this.state.fundraiser}
                  fundraiseTeams={this.state.fundraiseTeams}
                  setTeam={this.updateFundraiser}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'groupInfo' && (
                <EntityInfo
                  flow={this.state.flow}
                  type="group"
                  entity={this.state.group}
                  updateEntity={this.updateGroup}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'eventInfo' && (
                <EntityInfo
                  flow={this.state.flow}
                  type="event"
                  entity={this.state.event}
                  isOnBehalfOf={this.state.isOnBehalfOf}
                  updateEntity={this.updateEvent}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'gigInfo' && (
                <EntityInfo
                  flow={this.state.flow}
                  type="gig"
                  entity={this.state.gig}
                  updateEntity={this.updateGig}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'gigCategories' && (
                <GigCategory
                  flow={this.state.flow}
                  gig={this.state.gig}
                  updateEntity={this.updateGig}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'eventTimeAndLocation' && (
                <DateAndLocation
                  flow={this.state.flow}
                  type="event"
                  entity={this.state.event}
                  address={this.state.address}
                  updateEntity={this.updateEvent}
                  setLocation={this.setLocation}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'gigTimeAndLocation' && (
                <DateAndLocation
                  flow={this.state.flow}
                  type="gig"
                  entity={this.state.gig}
                  address={this.state.address}
                  updateEntity={this.updateGig}
                  setLocation={this.setLocation}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'isCharity' && (
                <IsCharity
                  flow={this.state.flow}
                  group={this.state.group}
                  isCharity={this.state.isCharity}
                  updateCharityId={this.updateCharityId}
                  updateIsCharity={this.updateIsCharity}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'isEvent' && (
                <IsEvent
                  flow={this.state.flow}
                  isEvent={this.state.isEvent}
                  updateIsEvent={this.updateIsEvent}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'eventSelection' && (
                <EventSelection
                  flow={this.state.flow}
                  selectedEvent={this.state.selectedEvent}
                  eventType={this.state.eventType}
                  groupType={this.state.groupType}
                  groupEventOptions={this.state.groupEvents}
                  updateEntityType={this.updateEntityType}
                  setExistingEvent={this.setExistingEvent}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'groupClassifications' && (
                <GroupClassifications
                  flow={this.state.flow}
                  group={this.state.group}
                  classificationOptions={this.state.classificationOptions}
                  updateEntity={this.updateGroup}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'volunteerInfo' && (
                <VolunteerInfo
                  flow={this.state.flow}
                  user={this.state.userInfo}
                  updateUserInfo={this.updateUserInfo}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'volunteerEmergency' && (
                <EmergencyContact
                  flow={this.state.flow}
                  user={this.state.userInfo}
                  updateUserInfo={this.updateUserInfo}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'causeSelection' && (
                <CausesSelection
                  flow={this.state.flow}
                  findCauses={this.state.findCauses}
                  updateFindCauses={this.updateFindCauses}
                  removeFindCause={this.updateFindCauses}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'cta' && (
                <CallToAction
                  flow={this.state.flow}
                  params={this.state.params}
                  updateCTA={this.updateCTA}
                  {...this.props}
                />
              )}

              {this.state.currentStep === 'connect' && (
                <CampaignConnect
                  flow={this.state.flow}
                  type="group"
                  entity={this.state.group}
                  campaignOptions={this.state.campaignOptions}
                  selectedCampaign={this.state.selectedCampaign}
                  isCampaignDefault={this.state.isCampaignDefault}
                  handleCampaignSelect={this.handleCampaignSelect}
                  hasCampaignAccount={this.hasCampaignAccount}
                  stripeConnect={this.stripeConnect}
                  toggleCampaignSkipModal={this.toggleCampaignSkip}
                  getCampaignOptions={this.getCampaignOptions}
                  {...this.props}
                />
              )}

              <div className="actions">
                <Button
                  className="back"
                  isDisabled={this.isBackButtonDisabled()}
                  text="Back"
                  onClick={() => {
                    const params = this.getLocationSearch();
                    if (params.obo && this.state.currentStep === 'eventType') {
                      this.props.history.push(`/group/${this.state.activeGroupHandle}`);
                    } else
                      this.getCurrentStepIndex() === 0
                        ? this.props.history.goBack()
                        : this.getPrevStep();
                  }}
                />
                {this.state.width > 680 && this.state.currentStep && (
                  <Button
                    isDisabled={this.isStepDisabled()}
                    text={this.state.currentStep !== 'connect' ? 'Next' : 'Finish'}
                    onClick={() => {
                      this.getNextStep();
                    }}
                  />
                )}
              </div>
            </div>
            <div className="image-section">
              <FlowInfo
                flowType={this.state.flow}
                currentStep={this.state.currentStep}
              />
            </div>
            <div className="mobile-actions">
              <div className="back">
                <Button
                  isDisabled={this.isBackButtonDisabled()}
                  className="ripple"
                  text="Back"
                  onClick={() => {
                    const params = this.getLocationSearch();
                    if (params.obo && this.state.currentStep === 'eventType') {
                      this.props.history.push(`/group/${this.state.activeGroupHandle}`);
                    } else
                      this.state.currentStep === 'groupSelection'
                        ? this.props.history.goBack()
                        : this.getPrevStep();
                  }}
                />
              </div>
              {this.state.width <= 700 && (
                <Button
                  isDisabled={this.isStepDisabled()}
                  text={this.state.currentStep !== 'connect' ? 'Next' : 'Finish'}
                  onClick={() => {
                    this.getNextStep();
                  }}
                />
              )}
            </div>
            <Modal
              show={this.state.showTaxSkipModal}
              onClose={() => this.toggleTaxSkip(false)}
            >
              <div className="skip-for-later">
                <h1>Are you sure?</h1>
                <p>
                  Before you can accept donations, you will need to set up tax receipts. This can be
                  done later.
                </p>
                <Button
                  onClick={() => this.handleTaxSkip()}
                  text="Yes, Proceed to Next Step"
                />
                <Button
                  onClick={() => this.toggleTaxSkip(false)}
                  text="No, Setup Tax Receipts"
                />
              </div>
            </Modal>
            <Modal
              show={this.state.showCampaignSkipModal}
              onClose={() => {
                this.toggleCampaignSkip(false);
              }}
            >
              <div className="skip-for-later">
                <h1>Are you sure?</h1>
                <p>
                  Before you can accept donations, sell tickets and store items, you will need to
                  connect a Stripe account
                </p>
                <Button
                  onClick={() => this.handleCampaignSkip()}
                  text="Yes, Finish Setup"
                />
                <Button
                  onClick={() => this.toggleCampaignSkip(false)}
                  text="No, Connect Stripe Account"
                />
              </div>
            </Modal>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    profileState: store.profileState,
    userState: store.userState,
    groupState: store.groupState,
    registerState: store.registerState,
    eventState: store.eventState,
    gigState: store.gigState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createGroup,
  createGroupFlow,
  createEvent,
  getEvent,
  createGroupCampaign,
  getUserGroupsMonetized,
  getGroupSignature,
  getGroupLocations,
  updateGroup,
  resetGroupState,
  getGroup,
  setFlow,
  getGroupCampaign,
  setCampaignConnect,
  getGroupClassifications,
  updateUser,
  createUserPage,
  createGroupPage,
  createEventPage,
  getGigCategories,
  updateUserPage,
  getProfileByHandle,
  createGroupGig,
  resetEventState,
  resetFlowState,
  updateEvent,
  getUserInfo,
  createToast,
  getFundraisingRegistrationTicket,
  getEventTeams,
  purchaseFundraise,
  resetEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Flow);
