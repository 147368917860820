import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import useToastDispatcher from '../../../../hooks/useToaster';
import { localizeHelpers } from '../../../../localizeHelpers';
import { fileHelpers, GlobalAcceptedFileType } from '../../../../helpers/fileHelpers';

import './Attachments.scss';
import { errorHelpers } from '../../../../helpers';
import { Constants } from '@gigit/constants';
import { Tooltip } from '../../Tooltip/Tooltip';
import TooltipTextOverflow from '../../TooltipTextOverflow/TooltipTextOverflow';

// const defaultAccepts = '.png,.jpeg,.gif,.pdf,.doc,.docx,.xls,.xlsx';

interface IAttachmentProps {
  label: string;
  isDisabled?: boolean;
  /**
   * This will be compared against GlobalAcceptedFileType
   * The file types that the input should accept. See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers
   * e.g ".jpg,.jpeg,.png"
   */
  accepts?: string;
  updateFiles: (files: File[]) => void;
}
export default function Attachments(props: IAttachmentProps) {
  const { dispatchToastInfo } = useToastDispatcher();
  let fileInputField = useRef(null);
  let fileDropZoneRef = useRef<HTMLDivElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const { label, updateFiles, isDisabled } = props;

  const validAcceptsString = fileHelpers.getValidAcceptsString(props.accepts ?? '');
  const validAcceptsArray = fileHelpers.getValidAcceptsArray(props.accepts);

  useEffect(() => {
    if (files) updateFiles(files);
  }, [files]);

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      let validatedFiles: Array<File> = [];

      if (event.target.files.length + files.length > Constants.max_attachments) {
        const errorMessage = localizeHelpers.translate(
          `Too many attachments, a maximum of {{maxAttachments}} is allowed`,
          {
            maxAttachments: Constants.max_attachments,
          },
        );

        dispatchToastInfo(errorMessage, 'Upload Error');
        return;
      }

      Array.from(event.target.files).forEach((targetFile) => {
        const { file, isValid, error } = fileHelpers.validateFile(targetFile);

        if (isValid) {
          validatedFiles.push(file);
        } else {
          dispatchToastInfo(error || '', 'Upload Error');
          return;
        }
      });

      setFiles([...files, ...validatedFiles]);
    }
  };

  const handleRemoveFile = (index: number) => {
    if (!files) {
      return;
    }

    const newFiles = Array.from(files);
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <div className="Attachments">
      <h2>Attachments</h2>
      <div className="action-row">
        <p>Attachment Documents</p>
        {/* We use label to have clickable connection to input - styled as button */}
        <label
          className={isDisabled ? 'disabled' : 'label'}
          htmlFor="fileInput"
        >
          <span>
            <i className="fa fa-plus" />
          </span>
          {label}
        </label>
        {/* Input is absolutely postioned (behind .files below) and opacity 0 to hide it while retaining native drag and drop functionality */}
        <input
          title=" "
          accept={validAcceptsString}
          id="fileInput"
          disabled={isDisabled}
          type="file"
          ref={fileInputField}
          onChange={handleFileInput}
          multiple={true}
          value=""
        />
      </div>

      <div className="files">
        {files.length > 0 &&
          files.map((file, index) => (
            <div
              ref={fileDropZoneRef}
              className="file"
              key={index}
            >
              <i className="fa fa-paperclip" />
              <TooltipTextOverflow
                text={file.name}
                direction="bottom"
                className="file-name"
                maxWidth={150}
              />
              <span
                className="closeIcon fa fa-times"
                onClick={() => handleRemoveFile(index)}
              />
            </div>
          ))}
        {files.length === 0 && <p className="center">Drop your files here</p>}
      </div>
      <p>
        <span>Accepted File types:</span>{' '}
        {fileHelpers.getAcceptedFileTypesMessage(validAcceptsArray)}{' '}
      </p>
    </div>
  );
}
