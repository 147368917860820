import { Reducer } from 'redux';
import { FooterActions, FooterActionTypes } from '../actions/footer';

export interface IFooterState {
  isVisible: boolean;
}

export const initialFooterState: IFooterState = {
  isVisible: true,
};

export const footerReducer: Reducer<IFooterState, FooterActions> = (
  state = initialFooterState,
  action,
) => {
  switch (action.type) {
    case FooterActionTypes.TOGGLE_FOOTER_VISIBILITY:
      return {
        ...state,
        isVisible: action.isVisible,
      };
    default:
      return state;
  }
};
