import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { IEventSummaryFE, IPage, IPageComponent } from '@gigit/interfaces';
import { Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { assignableEventStatuses, IStringMap, mapPermissions } from '../../../helpers';

import { updateEvent, changeEventStatus } from '../../../actions/event';
import { updateGroupPageComponent, getCurrentGroupPageComponents } from '../../../actions/group';

import CoverImage from '../../CoverImage/CoverImage';
import Portrait from '../../Portrait/Portrait';

import { IGroupState } from '../../../reducers/group';

import './FundraisingEventListItem.scss';
import ChangeStatusMenu from '../../ChangeStatusMenu/ChangeStatusMenu';
import { Constants } from '@gigit/constants';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { userSelectors } from '../../../selectors/user';
import { IOwnerObject } from '../../../interfaces';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { ReduxActionType } from '../../../interfaces';
import { IUserState } from '../../../reducers/user';

interface IProps extends WithTranslation {
  dragProps?: DraggableProvidedDragHandleProps;
  event: IEventSummaryFE;
  groupState: IGroupState;
  userState: IUserState;
  page: IPage;
  component: IPageComponent;
  locale: string;
  getCurrentGroupPageComponents: ReduxActionType<typeof getCurrentGroupPageComponents>;
  updateGroupPageComponent: ReduxActionType<typeof updateGroupPageComponent>;
  updateEvent: ReduxActionType<typeof updateEvent>;
  changeEventStatus: ReduxActionType<typeof changeEventStatus>;
  owner: IOwnerObject;
}

interface IState {
  userPermissions: IStringMap;
}

class FundraisingEventListItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      userPermissions: {},
    };

    this.setPermissions = this.setPermissions.bind(this);
  }

  componentDidMount() {
    this.setPermissions();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.groupState.currentUserRole !== prevProps.groupState.currentUserRole) {
      this.setPermissions();
    }
  }

  setPermissions() {
    let _permissions: IStringMap = mapPermissions(
      this.props.groupState.currentUserRole.permissions,
    );

    this.setState({
      userPermissions: _permissions,
    });
  }

  changeEventStatus(newStatus: string) {
    this.props.changeEventStatus(this.props.event.id, newStatus, () => {
      if (newStatus === Constants.event_status.archived) {
        this.removeEventFromPage();
      } else {
        this.props.getCurrentGroupPageComponents(
          this.props.groupState.group.id,
          this.props.page.id,
        );
      }
    });
  }

  removeEventFromPage() {
    let _events = [...(this.props.component.content_references?.object_ids ?? [])];
    const modifiedEvents = _events.filter((eventId) => eventId !== this.props.event.id);

    this.props.updateGroupPageComponent(
      this.props.owner.ownerId,
      this.props.page.id,
      this.props.component.id,
      {
        content_references: {
          object_type: 'event',
          object_ids: modifiedEvents,
        },
      },
    );
  }

  render() {
    const isVisible = !!this.props.component.content_references?.object_ids?.includes(
      this.props.event.id,
    );

    return (
      <div
        className={
          this.state.userPermissions['EDIT_GROUP_PAGES']
            ? isVisible
              ? 'FundraisingEventListItem admin'
              : 'FundraisingEventListItem admin hidden'
            : 'FundraisingEventListItem'
        }
      >
        <div className="content">
          <Portrait
            currentImage={this.props.event.profile_image_url}
            size={80}
          />
          <div className="info">
            <div
              className="start-date"
              notranslate="yes"
            >
              {localizeHelpers.formatDate(
                this.props.event.start_date,
                LocaleDateFormats.LLLL,
                this.props.locale,
              )}
            </div>
            <div
              className="title"
              notranslate="yes"
            >
              {this.props.event.title}
            </div>
            <div
              className="group-name"
              notranslate="yes"
            >
              {this.props.groupState.group.title}
            </div>

            {this.state.userPermissions['MANAGE_GROUP_EVENTS'] && (
              <ChangeStatusMenu
                event={this.props.event}
                className="change-status-dropdown"
                itemType="EVENT"
                currentStatus={this.props.event.status?.code}
                listOfStatuses={assignableEventStatuses}
                onStatusChanged={(newStatus) => this.changeEventStatus(newStatus)}
              />
            )}
          </div>
          <div className="action">
            <Link to={'/event/' + this.props.event.handle}>View Event</Link>
          </div>
        </div>
        <div className="cover">
          <CoverImage
            background={true}
            currentImage={this.props.event.cover_image_url}
          />
        </div>
        <div className="list-admin-actions">
          <ul>
            <li {...this.props.dragProps}>
              <i className="fas fa-arrows-alt" />
              <span>Drag</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getCurrentGroupPageComponents,
  updateGroupPageComponent,
  updateEvent,
  changeEventStatus,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(FundraisingEventListItem),
);
