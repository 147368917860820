import { IDiscountCode, IStoreCategory, IStoreItem } from '@gigit/interfaces';
import { Reducer } from 'redux';
import { StoreActions, StoreActionTypes } from '../actions/store';

export interface IStoreState {
  storeItems: IStoreItem[];
  isCreatingStoreItem: boolean;
  discountCodes: IDiscountCode[];
  storeCategories: IStoreCategory[];
}

export const initialStoreState: IStoreState = {
  storeItems: [],
  isCreatingStoreItem: false,
  discountCodes: [],
  storeCategories: [],
};

export const storeReducer: Reducer<IStoreState, StoreActions> = (
  state = initialStoreState,
  action,
) => {
  switch (action.type) {
    case StoreActionTypes.UPDATE_STORE: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
