import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { IUserRole } from '@gigit/interfaces';
import Contact from './Contact/Contact';
import { IGroupState } from '../../reducers/group';
import { getGroupMembers } from '../../actions/group';
import ContactDonor from './Donor/ContactDonor';
import Sponsor from './Sponsor/Sponsor';
import ContactFundraisers from './Fundraisers/ContactFundraisers';
import ContactSupport from './ContactSupport/ContactSupport';
import './ContactDetails.scss';
import Volunteer from './Volunteer/Volunteer';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  contact?: IUserRole;
  userId?: string;
  getGroupMembers(groupId: string, search?: string): void;
}

interface IState {
  tabs: string[];
  activeTab: number;
  contact: IUserRole | null;
}

class ContactDetails extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tabs: ['Contact', 'Donor', 'Volunteer', 'Supporter', 'Fundraiser', 'Sponsor'],
      activeTab: 0,
      contact: this.props.contact || null,
    };
  }

  componentDidMount() {
    this.props.getGroupMembers(this.props.groupState.group.id);
    const { members } = this.props.groupState;
    const { userId } = this.props;

    this.setState({
      contact: members.find((member) => member.user?.id === userId) || null,
    });
  }

  render() {
    let { userId } = this.props;
    let { activeTab } = this.state;
    let contact =
      this.props.contact ||
      this.props.groupState.members.find((member) => member.user?.id === userId);

    return (
      <div className="ContactDetails">
        <ul className="cd-main-menu">
          {this.state.tabs.map((item, index) => {
            return (
              <li
                onClick={() => {
                  this.setState({ activeTab: index });
                }}
                className={index === this.state.activeTab ? 'active no-select' : 'no-select'}
                key={item}
              >
                <span>{item}</span>
              </li>
            );
          })}
        </ul>
        <div className="cd-content">
          <div className="cd-content-inner">
            {activeTab === 0 && contact && (
              <Contact
                {...this.props}
                users={this.props.groupState.members}
                contact={contact}
              />
            )}
            {activeTab === 1 && contact && (
              <ContactDonor
                {...this.props}
                contact={contact}
              />
            )}
            {activeTab === 2 && contact && (
              <Volunteer
                {...this.props}
                contact={contact}
              />
            )}
            {activeTab === 3 && contact && (
              <ContactSupport
                {...this.props}
                contact={contact}
              />
            )}
            {activeTab === 4 && contact && (
              <ContactFundraisers
                {...this.props}
                contact={contact}
              />
            )}
            {activeTab === 5 && contact && (
              <Sponsor
                {...this.props}
                contact={contact}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
  };
};

const mapDispatchToProps = {
  getGroupMembers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);
