import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { localizeHelpers } from '../../../../localizeHelpers';

export interface IOptions {
  label: string;
  value: string;
}

interface IProps {
  flow: string;
  steps: Array<string>;
  currentStep: string;
  currentStepIndex: number;
  entityTitle?: string;
  locale: string;
  overrideHeaderTxt?: string;
}

interface IState {}

class StepsHeader extends React.Component<IProps, IState> {
  renderHeaderTxt() {
    switch (this.props.flow) {
      case 'donations': {
        return 'Accept Donations';
      }
      case 'createStore': {
        return 'Create Store';
      }
      case 'createEvent': {
        return 'Create Event';
      }
      case 'createGig': {
        return 'Create Volunteer Opportunity';
      }
      case 'createGroup': {
        return 'Create Cause';
      }
      case 'createAuction': {
        return 'Create Auction';
      }
      case 'volunteer': {
        return 'Volunteer';
      }
      case 'findGroups': {
        return 'Donate';
      }
      case 'findEvents': {
        return 'Find Events';
      }
      case 'fundraise': {
        if (this.props.overrideHeaderTxt) {
          return `${this.props.overrideHeaderTxt}`;
        } else {
          return `Fundraise`;
        }
      }
      default: {
        return '';
      }
    }
  }

  render() {
    return (
      <div className="steps-header">
        {this.props.flow === 'fundraise' && (
          <span
            className="entity-title"
            notranslate="yes"
          >
            {' '}
            {this.props.entityTitle ? this.props.entityTitle : ''}{' '}
          </span>
        )}
        <div className="step-header-title">
          <h2>{`${this.renderHeaderTxt()}`} </h2>
          <span>
            Step&nbsp;
            <var data-var="current_step">
              {localizeHelpers.formatNumber(
                this.props.steps.indexOf(this.props.currentStep) + 1,
                this.props.locale,
              )}
            </var>
            &nbsp;of&nbsp;
            <var data-var="number_of_steps">
              {localizeHelpers.formatNumber(this.props.steps.length, this.props.locale)}
            </var>
          </span>
        </div>
        <div className="step-progress">
          <div className="bar">
            <div
              style={{
                width:
                  Math.min(
                    99,
                    Math.max(
                      3,
                      Math.round(
                        ((this.props.currentStepIndex + 1) / this.props.steps.length) * 100,
                      ),
                    ),
                  ) + '%',
              }}
              className="bar-inner"
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('translations')(connect()(StepsHeader));
