import { Reducer } from 'redux';
import { IUserNotification } from '@gigit/interfaces';
import { NotificationActions, NotificationActionTypes } from '../actions/notifications';

export interface INotificationsState {
  notifications: IUserNotification[];
  unread: IUserNotification[];
  total_unread_count: number;
}

export const initialNotificationsState: INotificationsState = {
  notifications: [],
  unread: [],
  total_unread_count: 0,
};

export const notificationsReducer: Reducer<INotificationsState, NotificationActions> = (
  state = initialNotificationsState,
  action,
) => {
  switch (action.type) {
    case NotificationActionTypes.GET_UNREAD_NOTIFICATIONS: {
      return {
        ...state,
        ...action,
      };
    }
    case NotificationActionTypes.GET_NOTIFICATIONS: {
      return {
        ...state,
        ...action,
      };
    }
    case NotificationActionTypes.MARK_READ: {
      return {
        ...state,
        ...action,
      };
    }
    case NotificationActionTypes.MARK_MULTIPLE_READ: {
      return {
        ...state,
        ...action,
      };
    }
    case NotificationActionTypes.RESET_NOTIFICATIONS: {
      return {
        ...initialNotificationsState,
      };
    }
    case NotificationActionTypes.GET_UNREAD_QTY: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
