import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import './ContactOrganizerModal.scss';
import { Link, useHistory } from 'react-router-dom';
import { createToast } from '../../actions/toaster';
import { errorHelpers, toastInfo } from '../../helpers';
import { localizeHelpers } from '../../localizeHelpers';
import { ReduxActionType } from '../../interfaces';
import { uiConstants } from '../../constants';
import {
  eventRequestActions,
  gigRequestActions,
  groupRequestActions,
  hubRequestActions,
  userRequestActions,
} from '../../requestActions';
import { IUserContact } from '@gigit/interfaces';
import useToastDispatcher from '../../hooks/useToaster';

interface IProps {
  object_id: string;
  object_type: string;
  email?: string;
  phone?: string;
  show: boolean;
  onClose: () => void;
  createToast: ReduxActionType<typeof createToast>;
}

const ContactOrganizerModal: React.FC<IProps> = (props: IProps) => {
  const { email, phone, show, object_id, object_type } = props;
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  let history = useHistory();

  useEffect(() => {
    if (show) {
      if (!email && !phone && (!object_id || !object_type)) {
        showNoContactsWarning();
        props.onClose();
      }
    }
  }, [show, email, phone, object_id, object_type]);

  const showNoContactsWarning = () => {
    const toast = toastInfo(
      localizeHelpers.translate(`This organizer hasn't setup any contact info yet.`),
      `Contact Organizer`,
    );
    props.createToast(toast);
  };

  const handleOrganizerContacts = async () => {
    let organizerDetails: IUserContact[] = [];
    let adjustedObjType = props.object_type;
    let adjustedObjId = props.object_id;
    try {
      if (props.object_type === uiConstants.ownerType.gig) {
        const gig = await gigRequestActions.getGigByHandle(`${props.object_id}`);

        if (gig.event_id) {
          let eventOrganizer = await eventRequestActions.getEventByHandleOrId(`${gig.event_id}`);
          adjustedObjType = uiConstants.ownerType.event;
          adjustedObjId = eventOrganizer.id;

          organizerDetails = await userRequestActions.getAvailableOrganizerContacts(
            adjustedObjType,
            adjustedObjId,
          );
        } else if (gig.hub_id) {
          let hubOrganizer = await hubRequestActions.getHubByID(`${gig.hub_id}`);
          adjustedObjType = uiConstants.ownerType.hub;
          adjustedObjId = hubOrganizer.id || '';

          organizerDetails = await userRequestActions.getAvailableOrganizerContacts(
            adjustedObjType,
            adjustedObjId,
          );
        } else {
          let groupOrganizer = await groupRequestActions.getGroupByHandleOrId(`${gig.group_id}`);
          adjustedObjType = uiConstants.ownerType.group;
          adjustedObjId = groupOrganizer.id;

          organizerDetails = await userRequestActions.getAvailableOrganizerContacts(
            adjustedObjType,
            adjustedObjId,
          );
        }
      } else {
        organizerDetails = await userRequestActions.getAvailableOrganizerContacts(
          props.object_type,
          props.object_id,
        );
      }

      let added = await userRequestActions.getOrganizerContacts(adjustedObjType, adjustedObjId);
      if (organizerDetails.length) {
        for (let organizer of organizerDetails) {
          let exists = added.find(
            (contact) =>
              contact.contact_user_id.toString() == organizer.id ||
              contact.user_id.toString() == organizer.id,
          );
          if (!exists) {
            await userRequestActions.addOrganizerContact(organizer.id);
          }
        }

        history.push(
          `/dashboard?section=activity&tab=network&networkTab=messages&organizer_type=${adjustedObjType}&organizer_id=${adjustedObjId}`,
        );
      }
    } catch (error) {
      let errorObj = errorHelpers.getErrorObject(error);
      if (errorObj.messageTemplate !== 'Contact already exists') {
        dispatchToastError(error, 'Application');
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onClose={() => props.onClose()}
        title="Contact Organizer"
        class="contact-modal"
      >
        <div className="contact-organizer-container">
          {email && (
            <div>
              <i className="fa fa-envelope-o" />
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          )}
          {phone && (
            <div>
              <i className="fa fa-phone" />
              <a href={`tel:${phone}`}>{phone}</a>
            </div>
          )}
        </div>
        <div className="actions">
          <Button onClick={() => handleOrganizerContacts()}>Message</Button>
          {/*<Link to={`/dashboard?section=activity&tab=network`}> Message </Link>*/}
          <Button onClick={() => props.onClose()}>Cancel</Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  createToast,
};

export default connect(null, mapDispatchToProps)(ContactOrganizerModal);
