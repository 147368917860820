import React, { FC } from 'react';
import './ExternalVolunteerApplicationWarning.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';

interface IProps {
  isAvailable?: boolean;
  show: boolean;
  onClose(): void;
  url?: string;
}

const ExternalVolunteerApplicationWarning: FC<IProps> = (props) => {
  function view() {
    if (props?.url) {
      window.open(props.url);
      props.onClose();
    }
  }

  return props?.isAvailable ? (
    <Modal
      class="ExternalVolunteerApplicationWarning"
      show={props.show}
      onClose={props.onClose}
      closeIcon="fas fa-times"
    >
      <div className="container">
        <h2>You’re about to leave Kambeo.</h2>
        <p>
          This Volunteer Opportunity is hosted by an external source.
          <br />
          Are you sure you want to continue?
        </p>
        <div className="actions">
          <Button
            buttonType="dark"
            onClick={view}
            type="button"
            text="View Opportunity"
          />
          <Button
            buttonType="outline-dark"
            onClick={props.onClose}
            type="button"
            text="Close"
          />
        </div>
      </div>
    </Modal>
  ) : (
    <Modal
      class="ExternalVolunteerApplicationWarning unavailable"
      show={props.show}
      onClose={props.onClose}
      closeIcon="fas fa-times"
    >
      <div className="container">
        <h2>Application Form is Unavailable</h2>
        <p>
          This Volunteer Opportunity is currently being hosted externally.
          <br />
          To view Application Form, you must first disable ‘External Application’ in Settings.
        </p>
        <div className="actions">
          <Button
            buttonType="outline-dark"
            onClick={props.onClose}
            type="button"
            text="Close"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ExternalVolunteerApplicationWarning;
