import { Dispatch } from 'redux';
import { IToast } from '../interfaces';
import { AppDispatch, IAppState, GetAppState } from '../store';

export enum ToasterActionTypes {
  UPDATE_TOAST = 'UPDATE_TOAST',
}

export interface IUpdateToastAction {
  toasts: Array<IToast>;
  type: ToasterActionTypes.UPDATE_TOAST;
}

export type ToasterActions = IUpdateToastAction;

export const createToast = (toast: IToast) => {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    //avoid toast duplicates
    if (
      getState().toasterState.toasts.some(
        (t) => t.message === toast.message && t.toastType === toast.toastType,
      )
    )
      return;

    dispatch({
      toasts: [...getState().toasterState.toasts, ...[toast]],
      type: ToasterActionTypes.UPDATE_TOAST,
    });
  };
};

export const removeToast = (index?: number) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _toasts = [...getState().toasterState.toasts];
    let _index = index !== undefined ? index : 0;

    _toasts.splice(_index, 1);

    if (index === -1) {
      _toasts = [];
    }

    dispatch({
      toasts: _toasts,
      type: ToasterActionTypes.UPDATE_TOAST,
    });
  };
};
