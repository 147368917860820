import { IGroupClassification } from '@gigit/interfaces';
import { useEffect, useState } from 'react';
import { causeRequestActions, groupRequestActions } from '../requestActions';
import useToastDispatcher from './useToaster';

interface IUseGroupClassifications {
  classifications: IGroupClassification[];
  subClassifications: string[];
  isLoading: Boolean;
}
/**
 * Fetches master lists of Group Classifications and Sub-Classifications
 */
export function useGroupClassifications(): IUseGroupClassifications {
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [classifications, setClassifications] = useState<IGroupClassification[]>([]);
  const [subClassifications, setSubClassifications] = useState<string[]>([]);

  const { dispatchToastError } = useToastDispatcher();

  useEffect(() => {
    const fetchClassifications = async () => {
      setIsLoading(true);
      try {
        const results = await groupRequestActions.getGroupClassifications();
        const subClasses = results
          .map((groupClassification: IGroupClassification) => {
            return [...groupClassification.sub_classes!];
          })
          .flat()
          .sort((a: string, b: string) => a.localeCompare(b));

        setSubClassifications(subClasses);
        setClassifications(
          [...results].sort((a: IGroupClassification, b: IGroupClassification) =>
            a.class_name!.localeCompare(b.class_name!),
          ),
        );
      } catch (e) {
        dispatchToastError(e, 'Fetching Group Classifications');
      } finally {
        setIsLoading(false);
      }
    };

    fetchClassifications();
  }, []);

  return { isLoading, classifications, subClassifications };
}
