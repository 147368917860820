import React, { FC, useState, useEffect, useRef } from 'react';
import './HubManagementAnalyticsEngagement.scss';
import HubManagementMetricContainer from '../../shared/HubManagementMetricContainer/HubManagementMetricContainer';
import { IKpi } from '../../../../../interfaces';
import moment, { Moment } from 'moment';
import DateTimePicker from '../../../../shared/DateTimePicker/DateTimePicker';
import { useLocale } from '../../../../../hooks';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IHub } from '@gigit/interfaces';
import {
  defaultCurrency,
  metricsHelpers,
  formatNumberCompactNotation,
  focusOnInputField,
} from '../../../../../helpers';
import { metricRequestActions } from '../../../../../requestActions/metrics';
import { uiConstants } from '../../../../../constants';
import useToastDispatcher from '../../../../../hooks/useToaster';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface IProps {
  hub: IHub;
}

const _SESSION_STORAGE_FROM = 'hubAnalyticsDonations_membersTrendDateFrom',
  _SESSION_STORAGE_TO = 'hubAnalyticsDonations_membersTrendDateTo';

const HubManagementAnalyticsEngagement: FC<IProps> = (props) => {
  const { hub } = props;
  const locale = useLocale();
  const [metrics, setMetrics] = useState<IKpi[]>([]);
  const [membersTrendLikesKpi, setMembersTrendLikesKpi] = useState<metricsHelpers.IChartKpi | null>(
    null,
  );
  const [membersTrendSharesKpi, setMembersTrendSharesKpi] =
    useState<metricsHelpers.IChartKpi | null>(null);
  const [membersTrendCommentsKpi, setMembersTrendCommentsKpi] =
    useState<metricsHelpers.IChartKpi | null>(null);

  const [membersTrendDateFrom, setMembersTrendDateFrom] = useState<Moment>(
    moment(localStorage.getItem(_SESSION_STORAGE_FROM) || `${new Date().getFullYear()}-01-01`),
  );
  const [membersTrendDateTo, setMembersTrendDateTo] = useState<Moment>(
    moment(localStorage.getItem(_SESSION_STORAGE_TO) || `${new Date().getFullYear()}-12-31`),
  );
  const { dispatchToastError } = useToastDispatcher();

  const membersTrendDateFromRef = useRef<HTMLInputElement>(null);
  const membersTrendDateToRef = useRef<HTMLInputElement>(null);

  async function handleGetMetrics() {
    try {
      const metricNames = [
        'num_activity_feed_comments',
        'num_activity_feed_likes',
        'num_activity_feed_shares',
      ];
      const result = (
        await metricRequestActions.getMetricsBulk({
          metrics: metricNames.map((metric) => ({
            metric,
            object_type: uiConstants.ownerType.hub,
            object_id: hub.id!,
            currency: hub.account?.currency ?? defaultCurrency,
          })),
        })
      ).metrics;

      setMetrics([
        {
          value: formatNumberCompactNotation(result[0].value, locale.currentLocale),
          text: 'Number of Comments',
        },
        {
          value: formatNumberCompactNotation(result[1].value, locale.currentLocale),
          text: 'Number of Likes',
        },
        {
          value: formatNumberCompactNotation(result[2].value, locale.currentLocale),
          text: 'Number of Shares on Kambeo',
        },
      ]);
    } catch (error) {
      dispatchToastError(error, 'Get Metrics');
    }
  }

  async function handleGetKpi(kpi: string, setValue: (v: metricsHelpers.IChartKpi) => void) {
    try {
      const kpiData = (
        await metricRequestActions.getMetricsKpi({
          object_type: uiConstants.ownerType.hub,
          object_id: hub.id!,
          kpi,
          start_date: membersTrendDateFrom.toDate(),
          end_date: membersTrendDateTo.toDate(),
        })
      ).kpi_data;
      let chartDate = [] as metricsHelpers.IChartKpiData[];
      kpiData.labels.forEach((label, index) => {
        chartDate.push({
          x: label,
          value: kpiData.datasets[0].data[index],
        });
      });

      setValue({ data: chartDate, labels: kpiData.labels });
    } catch (error) {
      dispatchToastError(error, 'Get KPI');
    }
  }

  async function handleGetLikesKpi() {
    await handleGetKpi('likes_trend', (v) => setMembersTrendLikesKpi(v));
  }

  async function handleGetCommentsKpi() {
    await handleGetKpi('comments_trend', (v) => setMembersTrendCommentsKpi(v));
  }

  async function handleGetSharesKpi() {
    await handleGetKpi('shares_trend', (v) => setMembersTrendSharesKpi(v));
  }

  useEffect(() => {
    handleGetMetrics();
  }, []);

  useEffect(() => {
    handleGetLikesKpi();
    handleGetCommentsKpi();
    handleGetSharesKpi();
  }, [membersTrendDateTo, membersTrendDateFrom]);

  const optionsMembersTrend = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderColor: '#333333',
        borderWidth: 1,
        bodyColor: '#000000',
        titleColor: '#000000',
        padding: 16,
        titleFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
        bodyFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
      },
    },
  };
  const chartDataMembersTrend = {
    labels: membersTrendLikesKpi?.labels || [],
    datasets: [
      {
        label: 'Likes',
        data: membersTrendLikesKpi?.data || [],
        borderColor: '#5E51AB',
        backgroundColor: '#5E51AB',
        parsing: {
          yAxisKey: 'value',
        },
      },
      {
        label: 'Comments',
        data: membersTrendCommentsKpi?.data || [],
        borderColor: '#F1B94D',
        backgroundColor: '#F1B94D',
        parsing: {
          yAxisKey: 'value',
        },
      },
      {
        label: 'Shares',
        data: membersTrendSharesKpi?.data || [],
        borderColor: '#EB645E',
        backgroundColor: '#EB645E',
        parsing: {
          yAxisKey: 'value',
        },
      },
    ],
  };

  return (
    <div className="HubManagementAnalyticsEngagement">
      <h3>Engagement Analytics</h3>
      <HubManagementMetricContainer metrics={metrics} />

      <section className="chart-container">
        <h4>New Members Trend</h4>
        <div className="date-container">
          <div className="date">
            <label htmlFor="membersTrendDateFrom">From:</label>
            <DateTimePicker
              shouldValidate={false}
              inputProps={{
                ref: membersTrendDateFromRef,
                name: 'membersTrendDateFrom',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              value={membersTrendDateFrom}
              onChange={(value) => {
                setMembersTrendDateFrom(moment(value));
                localStorage.setItem(_SESSION_STORAGE_FROM, value as string);
              }}
              locale={locale.currentLocale}
              className="dPicker"
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(membersTrendDateFromRef)}
            />
          </div>
          <div className="date">
            <label htmlFor="membersTrendDateTo">To:</label>
            <DateTimePicker
              inputProps={{
                ref: membersTrendDateToRef,
                name: 'membersTrendDateTo',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              value={membersTrendDateTo}
              onChange={(value) => {
                setMembersTrendDateTo(moment(value));
                localStorage.setItem(_SESSION_STORAGE_TO, value as string);
              }}
              locale={locale.currentLocale}
              className="dPicker"
              disableDatesBefore={membersTrendDateFrom}
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(membersTrendDateToRef)}
            />
          </div>
        </div>
        <Line
          options={optionsMembersTrend}
          data={chartDataMembersTrend}
          className="chart"
        />
      </section>
    </div>
  );
};

export default HubManagementAnalyticsEngagement;
