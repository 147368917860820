import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  errorHelpers,
  formatQuery,
  handleInputChange,
  IStringMap,
  toastError,
} from '../../../helpers';
import { IPage } from '@gigit/interfaces';

import { IUserState } from '../../../reducers/user';
import { IProfileState } from '../../../reducers/profile';
import { ICause } from '@gigit/interfaces';

import TextField from '../../../components/TextField/TextField';
import Button from '../../../components/Button/Button';

import { causeRequestActions } from '../../../requestActions/causes';
import { updateGroupPageComponent, updateGroup } from '../../../actions/group';
import { updateEventPageComponent, updateEventCauses } from '../../../actions/event';

import './Causes.scss';
import { updateGigPageComponent, updateGigCauses } from '../../../actions/gig';
import { updateUserPageComponent, updateUser } from '../../../actions/user';
import { localizeHelpers } from '../../../localizeHelpers';
import { IOwnerObject } from '../../../interfaces';
import { ICausesComponent, ReduxActionType } from '../../../interfaces';
import typeHelpers from '../../../helpers/typeHelpers';
import { createToast } from '../../../actions/toaster';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  owner: IOwnerObject;
  page: IPage;
  component: ICausesComponent;
  permissions?: IStringMap;
  userState: IUserState;
  profileState: IProfileState;
  getCauses(): void;
  updateGroupPageComponent: ReduxActionType<typeof updateGroupPageComponent>;
  updateEventPageComponent: ReduxActionType<typeof updateEventPageComponent>;
  updateGigPageComponent: ReduxActionType<typeof updateGigPageComponent>;
  updateUserPageComponent: ReduxActionType<typeof updateUserPageComponent>;
  updateUser: ReduxActionType<typeof updateUser>;
  updateGigCauses: ReduxActionType<typeof updateGigCauses>;
  updateGroup: ReduxActionType<typeof updateGroup>;
  updateEventCauses: ReduxActionType<typeof updateEventCauses>;
  edit: boolean;
  onComponentUpdate?: () => void;
  hasEditPermissions?: boolean;
}

interface IState {
  causeValue: string;
  title: string;
  causes: ICause[];
}

class Causes extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      causeValue: '',
      title: this.props.component.title || 'Focus Areas',
      causes: [],
    };

    this.removeCause = this.removeCause.bind(this);
    this.removeMetaCause = this.removeMetaCause.bind(this);
    this.addCause = this.addCause.bind(this);
  }

  componentDidMount() {
    this.syncCauses();
  }

  async syncCauses() {
    try {
      const causes = await causeRequestActions.getCauses();
      this.setState({ causes });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Add Focus Area');
      createToast(toast);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.component.content_references?.object_ids !==
      prevProps.component.content_references?.object_ids
    ) {
      this.setState({
        causeValue: '',
      });
    }

    if (prevProps.edit && !this.props.edit) {
      this.update();
    }
  }

  update() {
    const payload = this.props.component;

    payload.title = this.state.title;

    if (
      this.props.permissions &&
      this.props.permissions['EDIT_GROUP_PAGES'] &&
      this.props.owner.ownerType !== 'gig'
    ) {
      if (this.props.updateGroupPageComponent) {
        this.props.updateGroupPageComponent(
          this.props.page.owner_id,
          this.props.page.id,
          this.props.component.id,
          payload,
        );
      }
    } else if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
      this.props.updateEventPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    } else if (
      this.props.owner.ownerType === 'gig' &&
      this.props.permissions &&
      this.props.permissions['MANAGE_GIGS']
    ) {
      this.props.updateGigPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    }
  }

  addCause() {
    let _causes = [...(this.props.component.content_references?.object_ids ?? [])];
    let _causeExists = false;

    for (let c = 0; c < this.state.causes.length; c++) {
      let _cause = this.state.causes[c];

      if (_cause.cause === this.state.causeValue) {
        typeHelpers.assertNotNullOrUndefined(_cause.id);

        _causes.push(_cause.id);
        _causeExists = true;
        const _causes_set = new Set(_causes);
        const _unique_causes = Array.from(_causes_set);
        _causes = _unique_causes;
      }
    }

    if (_causeExists) {
      switch (this.props.owner.ownerType) {
        case 'group':
          this.props.updateGroupPageComponent(
            this.props.page.owner_id,
            this.props.page.id,
            this.props.component.id,
            {
              content_references: {
                object_type: 'cause',
                object_ids: _causes,
              },
            },
            this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
          );

          this.props.updateGroup({ causes: _causes }, this.props.page.owner_id);
          break;
        case 'event':
          this.props.updateEventPageComponent(
            this.props.page.owner_id,
            this.props.page.id,
            this.props.component.id,
            {
              content_references: {
                object_type: 'cause',
                object_ids: _causes,
              },
            },
            this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
          );

          this.props.updateEventCauses(this.props.page.owner_id, _causes);
          break;
        case 'gig':
          this.props.updateGigPageComponent(
            this.props.page.owner_id,
            this.props.page.id,
            this.props.component.id,
            {
              content_references: {
                object_type: 'cause',
                object_ids: _causes,
              },
            },
          );

          this.props.updateGigCauses(this.props.page.owner_id, _causes);
          break;
        case 'user':
          this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, {
            content_references: {
              object_type: 'cause',
              object_ids: _causes,
            },
          });

          this.props.updateUser({ causes: _causes });
          break;
      }
    } else {
      let _meta_causes: string[] = [];

      if (this.props.component.meta_data) {
        _meta_causes = [...this.props.component.meta_data.causes];
      }

      _meta_causes.push(this.state.causeValue);
      const _causes_set = new Set(_meta_causes);
      const _unique_causes = Array.from(_causes_set);
      _meta_causes = _unique_causes;

      switch (this.props.owner.ownerType) {
        case 'group':
          this.props.updateGroupPageComponent(
            this.props.page.owner_id,
            this.props.page.id,
            this.props.component.id,
            {
              meta_data: {
                causes: _meta_causes,
              },
            },
            this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
          );

          break;
        case 'event':
          this.props.updateEventPageComponent(
            this.props.page.owner_id,
            this.props.page.id,
            this.props.component.id,
            {
              meta_data: {
                causes: _meta_causes,
              },
            },
            this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
          );
          break;
        case 'gig':
          this.props.updateGigPageComponent(
            this.props.page.owner_id,
            this.props.page.id,
            this.props.component.id,
            {
              meta_data: {
                causes: _meta_causes,
              },
            },
          );

          break;
        case 'user':
          this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, {
            meta_data: {
              causes: _meta_causes,
            },
          });

          break;
      }
    }
  }

  removeCause(_id: string) {
    // TODO: Refactor to avoid duplication

    let _causes = [...(this.props.component.content_references?.object_ids ?? [])];
    _causes = _causes.filter((e) => e !== _id);

    switch (this.props.owner.ownerType) {
      case 'group':
        this.props.updateGroupPageComponent(
          this.props.page.owner_id,
          this.props.page.id,
          this.props.component.id,
          {
            content_references: {
              object_type: 'cause',
              object_ids: _causes,
            },
          },
          this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
        );

        this.props.updateGroup({ causes: _causes }, this.props.page.owner_id);
        break;
      case 'event':
        this.props.updateEventPageComponent(
          this.props.page.owner_id,
          this.props.page.id,
          this.props.component.id,
          {
            content_references: {
              object_type: 'cause',
              object_ids: _causes,
            },
          },
          this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
        );

        this.props.updateEventCauses(this.props.page.owner_id, _causes);
        break;
      case 'gig':
        this.props.updateGigPageComponent(
          this.props.page.owner_id,
          this.props.page.id,
          this.props.component.id,
          {
            content_references: {
              object_type: 'cause',
              object_ids: _causes,
            },
          },
        );

        this.props.updateGigCauses(this.props.page.owner_id, _causes);
        break;
      case 'user':
        this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, {
          content_references: {
            object_type: 'cause',
            object_ids: _causes,
          },
        });

        this.props.updateUser({ causes: _causes });
        break;
    }
  }

  removeMetaCause(_cause: string) {
    // TODO: Refactor to avoid duplication

    let _metaCauses = [...(this.props.component.meta_data?.causes ?? [])];
    _metaCauses = _metaCauses.filter((e) => e !== _cause);

    switch (this.props.owner.ownerType) {
      case 'group':
        this.props.updateGroupPageComponent(
          this.props.page.owner_id,
          this.props.page.id,
          this.props.component.id,
          {
            meta_data: {
              causes: _metaCauses,
            },
          },
          this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
        );

        break;
      case 'event':
        this.props.updateEventPageComponent(
          this.props.page.owner_id,
          this.props.page.id,
          this.props.component.id,
          {
            meta_data: {
              causes: _metaCauses,
            },
          },
          this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
        );
        break;
      case 'gig':
        this.props.updateGigPageComponent(
          this.props.page.owner_id,
          this.props.page.id,
          this.props.component.id,
          {
            meta_data: {
              causes: _metaCauses,
            },
          },
        );
        break;
      case 'user':
        this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, {
          meta_data: {
            causes: _metaCauses,
          },
        });
        break;
    }
  }

  render() {
    return (
      <div className="Causes">
        <div className="title">
          {this.props.edit && (
            <TextField
              value={this.state.title}
              name="title"
              type="text"
              onChange={(e: any) => {
                handleInputChange(e, this);
              }}
            />
          )}
          {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}
        </div>
        {this.props.hasEditPermissions && this.props.hasEditPermissions && (
          <form
            className="add-cause"
            onSubmit={(e: any) => {
              e.preventDefault();
              this.addCause();
            }}
          >
            <TextField
              list="gigit-causes"
              placeholder="Add a focus area (Cancer Research, COVID-19, etc)"
              onChange={(e: any) => {
                handleInputChange(e, this);
              }}
              value={this.state.causeValue}
              name="causeValue"
              type="text"
            />
            <datalist
              id="gigit-causes"
              notranslate="yes"
            >
              {this.state.causes.map((item, index) => {
                const translated = localizeHelpers.translate(item.cause);
                return (
                  <option
                    key={index}
                    id={item.id}
                    value={translated}
                  />
                );
              })}
            </datalist>
            <Button
              isDisabled={!this.state.causeValue || !/\S/.test(this.state.causeValue)}
              text="Add Focus Area"
              type="submit"
            />
          </form>
        )}
        <ul className="user-causes">
          {this.props.component.content_objects &&
            this.props.component.content_objects.map((item: any, index: number) => {
              return (
                <li
                  key={index}
                  className="cause no-select"
                >
                  <span notranslate="yes">{item.cause}</span>
                  {this.props.hasEditPermissions && (
                    <i
                      onClick={() => {
                        this.removeCause(item.id);
                      }}
                      className="fa fa-times-circle"
                    />
                  )}
                </li>
              );
            })}
          {this.props.component.meta_data &&
            this.props.component.meta_data.causes &&
            this.props.component.meta_data.causes.map((item: string, index: number) => {
              return (
                <li
                  key={index}
                  className="cause no-select"
                >
                  <span notranslate="yes">{item}</span>
                  {this.props.hasEditPermissions && (
                    <i
                      onClick={() => {
                        this.removeMetaCause(item);
                      }}
                      className="fa fa-times-circle"
                    />
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    profileState: store.profileState,
  };
};

const getCauses = causeRequestActions.getCauses;

const mapDispatchToProps = {
  getCauses,
  updateGroupPageComponent,
  updateEventPageComponent,
  updateGigPageComponent,
  updateUserPageComponent,
  updateGigCauses,
  updateGroup,
  updateEventCauses,
  updateUser,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(Causes),
);
