import React, { createRef, MutableRefObject } from 'react';
import ReactDOM from 'react-dom';
import { combineClassNames, isMobileScreen } from '../../../helpers';

import './Tooltip.scss';

export type TooltipDirection = 'bottom' | 'right' | 'left';

interface ITooltipProps {
  className?: string;

  /** Provides a static tooltip message. */
  message?: string;

  /** Disables the onMouseEnter mouse event that triggers the Tooltip renderer  */
  disableRenderTooltip?: boolean;

  /** Allows rendering custom tooltip content.  */
  renderTooltip?: () => React.ReactNode;

  /** When not provided, is based on screen size. */
  direction?: TooltipDirection;

  /** Function invoked to render target content. ref param should be applied to element that should contain the tooltip. */
  children: (ref: React.RefCallback<HTMLElement>) => React.ReactNode;
}

interface ITooltipState {
  display: boolean;
  direction: TooltipDirection;
}

/** A generic reusable tooltip using React Portal.
 *
 * Either message or renderTooltip must be provided.
 *
 * @example
 * <Tooltip
 *   message={"This is a tooltip"}>
 *       {(ref) => (
 *           <div ref={ref}>
 *               Hover over me!
 *           </div>
 *       )}
 * </Tooltip>
 */
export class Tooltip extends React.Component<ITooltipProps, ITooltipState> {
  private element: HTMLElement | null; // Element where tooltip will be mounted.
  private tooltipRef = createRef<HTMLDivElement>();
  private targetRef = createRef<HTMLElement | null>() as MutableRefObject<HTMLElement | null>; // Reference to target element (Element that will have the tooltip).

  constructor(props: ITooltipProps) {
    super(props);
    this.state = {
      display: false,
      direction: this.props.direction ?? isMobileScreen() ? 'bottom' : 'right',
    };

    this.element = document.getElementById('tooltips');
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    if (this.state.display) {
      this.calculatePosition();
    }
  };

  /** Called when mouse pointer enters tooltip target element. Here we make the tooltip visible and position it. */
  onMouseEnter = (e: MouseEvent) => {
    this.setState({
      display: true,
    });
    this.calculatePosition();
  };

  getDirection() {
    return this.props.direction ?? isMobileScreen() ? 'bottom' : 'right';
  }

  calculatePosition() {
    if (this.tooltipRef.current && this.targetRef.current) {
      const rect = this.targetRef.current.getBoundingClientRect();
      const tooltipRect = this.tooltipRef.current.getBoundingClientRect();
      let { direction } = this.state;

      //avoids rendering off right side of screen
      if (rect.left + tooltipRect.width + 10 > window.innerWidth) {
        this.setState({ direction: 'left' }); //change state for style to render
        direction = 'left'; //immediately change direction for positional calculation
      }

      if (direction === 'bottom') {
        // Position to the bottom of the target element.
        this.tooltipRef.current.style.top = `${rect.bottom + 10}px`;
        this.tooltipRef.current.style.left = `${rect.left + rect.width * 0.5}px`;
      } else if (direction === 'right') {
        // Position to the right of the target element.
        this.tooltipRef.current.style.top = `${rect.top + rect.height * 0.5}px`;
        this.tooltipRef.current.style.left = `${rect.left + rect.width + 10}px`;
      } else if (direction === 'left') {
        // Position to the left of the target element.
        this.tooltipRef.current.style.top = `${rect.top + rect.height * 0.5}px`;
        this.tooltipRef.current.style.left = `${rect.left - tooltipRect.width - rect.width + 10}px`;
      }
    }
  }

  onMouseLeave = (e: MouseEvent) => {
    this.setState({
      display: false,
    });
  };

  /** Called when the target element is mounted. Here we register event handlers. */
  refCallback = (ref: HTMLElement) => {
    if (this.targetRef.current) {
      this.targetRef.current.removeEventListener('mouseenter', this.onMouseEnter);
      this.targetRef.current.removeEventListener('mousedown', this.onMouseEnter);
      this.targetRef.current.removeEventListener('mouseleave', this.onMouseLeave);
      this.targetRef.current = null;
    }

    if (ref) {
      ref.addEventListener('mouseenter', this.onMouseEnter);
      ref.addEventListener('mousedown', this.onMouseEnter);
      ref.addEventListener('mouseleave', this.onMouseLeave);
      this.targetRef.current = ref;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.children(this.refCallback)}
        {ReactDOM.createPortal(this.renderTooltip(), this.element!)}
      </React.Fragment>
    );
  }

  renderTooltip() {
    const classNames = combineClassNames(
      'Tooltip',
      this.state.display && !this.props.disableRenderTooltip ? 'visible' : '',
      `direction_${this.state.direction}`,
      this.props.className,
    );

    return (
      <div
        ref={this.tooltipRef}
        className={classNames}
      >
        {this.props.renderTooltip ? this.props.renderTooltip() : <span>{this.props.message}</span>}
      </div>
    );
  }
}
