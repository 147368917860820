import { IHub } from '@gigit/interfaces';
import React from 'react';
import useToastDispatcher from '../../../../../hooks/useToaster';
import Button from '../../../../Button/Button';
import Modal from '../../../../Modal/Modal';
import ModalFooterActionContainer from '../../../../Modal/ModalFooterActions/ModalFooterActionContainer';
import ModalScrollContainer from '../../../../Modal/ModalScrollContainer/ModalScrollContainer';
import './GenerateDisbursementPackageModal.scss';

interface IProps {
  hub?: IHub;
  amount_matched: string;
  number_of_cause: string;
  generate_id: string;
  showModal: boolean;
  onClose: () => void;
}

function GenerateDisbursementPackageModal(props: IProps) {
  const { showModal, onClose, generate_id, number_of_cause, amount_matched } = props;
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  function handleOnSave() {
    // TODO: pending BE

    onClose();
  }

  return (
    <Modal
      show={showModal}
      onClose={onClose}
      closeIcon="fas fa-times"
      title="Generate Disbursement Package"
    >
      <ModalScrollContainer className="GenerateDisbursementPackageModal">
        <div className="form-row">
          <p className="description">
            You are about to create a Disbursement Package. Once a Disbursement Package is created,
            your Matching Program will be locked and you will not be able to make payments to causes
            from it. Ensure you have made any payments out to causes before proceeding.
          </p>
        </div>

        <div className="form-row">
          <label>Disbursement Id</label>
          <span className="value">#{generate_id}</span>
        </div>
        <div className="form-row">
          <label>Number of Causes</label>
          <span className="value">{number_of_cause}</span>
        </div>
        <div className="form-row">
          <label>Total Matched Amount</label>
          <span className="value">{amount_matched}</span>
        </div>
      </ModalScrollContainer>
      <ModalFooterActionContainer>
        <Button
          buttonType="outline-dark"
          onClick={onClose}
          text="Cancel"
        />
        <Button
          buttonType="dark"
          onClick={handleOnSave}
          text="Send Payment"
        />
      </ModalFooterActionContainer>
    </Modal>
  );
}

export default GenerateDisbursementPackageModal;
