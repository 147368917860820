import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IOwnerObject } from '../../interfaces';
import { IAddress } from '@gigit/interfaces';
import locationHelpers from '../Location/locationHelpers';
import { errorHelpers, routes, swapRouteParams, toastError } from '../../helpers';

import axios from 'axios';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import Modal from '../Modal/Modal';
import Location from '../Location/Location';
import { createToast } from '../../actions/toaster';
import './AddLocationModal.scss';

interface IProps {
  owner: IOwnerObject;
  onClose: () => void;
  onLocationAdded(location: IAddress): void;
}

const AddLocationModal: FC<IProps> = (props: IProps) => {
  const [isManual, setIsManual] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(true);
  const [newLocationSearchValue, setNewLocationSearchValue] = useState<string>('');
  const [location, setLocation] = useState<IAddress>({ searchable: true });
  const [name, setName] = useState<string>('');
  const [streetAddress, setStreetAddress] = useState<string>('');
  const [apartment, setApartment] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [postal, setPostal] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!showModal) props.onClose();
  }, [showModal]);

  const addLocationManually = () => {
    const manualLocation: IAddress = {
      line1: streetAddress,
      line2: apartment,
      city: city,
      postal_code: postal,
      country: country,
      state: province,
      title: name,
      searchable: true,
      location: {
        coordinates: [0, 0],
      },
    };

    //try to get Point coordinates based on provided address
    locationHelpers
      .getGeocodeInfoFromAddressComponents(manualLocation)
      .then((response) => {
        if (response.results.length > 0) {
          const coordinates = response.results[0].geometry.location;
          manualLocation.location = {
            coordinates: [coordinates.lng, coordinates.lat],
          };
        }
      })
      .finally(() => {
        addLocation(manualLocation);
      });
  };

  const addLocation = (location: IAddress): void => {
    const newLocation = { ...location, title: name };
    const route: { [key: string]: string } = {
      group: swapRouteParams(routes.CREATE_GROUP_LOCATION, { groupId: props.owner.ownerId }),
      event: swapRouteParams(routes.CREATE_EVENT_LOCATION, { eventId: props.owner.ownerId }),
      gig: swapRouteParams(routes.CREATE_GIG_LOCATION, { gigId: props.owner.ownerId }),
      hub: swapRouteParams(routes.CREATE_HUB_LOCATION, { id: props.owner.ownerId }),
      company: swapRouteParams(routes.CREATE_GIG_LOCATION, { gigId: props.owner.ownerId }),
    };

    axios
      .post<IAddress>(route[props.owner.ownerType], newLocation)
      .then((response) => {
        props.onLocationAdded(response.data);
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Cannot add location');
        dispatch(createToast(toast));
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  return (
    <Modal
      show={showModal}
      onClose={() => setShowModal(false)}
      title="Add New Location"
    >
      <div className="AddLocationModal">
        <div className="add-type">
          <Button
            onClick={() => setIsManual(!isManual)}
            text={isManual ? 'Add By Map' : 'Add Manually'}
          />
        </div>
        {isManual ? (
          <div className="manual-add">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addLocationManually();
              }}
            >
              <div className="form-1-1">
                <TextField
                  required={true}
                  label="Name"
                  value={name}
                  type="text"
                  name="name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                />
              </div>
              <div className="form-1-1">
                <TextField
                  required={true}
                  label="Street Address"
                  value={streetAddress}
                  type="text"
                  name="address"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setStreetAddress(e.target.value);
                  }}
                />
                <TextField
                  label="Apartment / Suite"
                  value={apartment}
                  type="text"
                  name="apartment"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setApartment(e.target.value);
                  }}
                />
              </div>
              <div className="form-1-1">
                <TextField
                  required={true}
                  label="City"
                  value={city}
                  type="text"
                  name="city"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCity(e.target.value);
                  }}
                />
                <TextField
                  required={true}
                  label="Province or State"
                  value={province}
                  type="text"
                  name="provinceOrState"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setProvince(e.target.value);
                  }}
                />
              </div>
              <div className="form-1-1">
                <TextField
                  required={true}
                  label="Country"
                  value={country}
                  type="text"
                  name="country"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCountry(e.target.value);
                  }}
                />
                <TextField
                  required={true}
                  label="Postal"
                  value={postal}
                  type="text"
                  name="postal"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPostal(e.target.value);
                  }}
                />
              </div>
              <div className="actions">
                <Button
                  icon="fal fa-plus"
                  text="Add Location"
                />
              </div>
            </form>
          </div>
        ) : (
          <Location
            label="Address"
            locationName="name"
            name="newLocationSearchValue"
            locationNameValue={name}
            searchValue={newLocationSearchValue}
            onSearchChange={(searchValue: string) => setNewLocationSearchValue(searchValue)}
            onNameChange={(name: string) => setName(name)}
            onChange={(address: IAddress) => setLocation(address)}
          />
        )}

        {!isManual && (
          <div className="actions">
            <Button
              icon="fal fa-plus"
              isDisabled={!name || !location}
              text="Add Location"
              onClick={() => {
                addLocation(location);
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddLocationModal;
