import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Redirect } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import Axios from 'axios';
import { routes, setSEOMetatags, toastError, toastSuccess } from '../../helpers';

import { IAppState } from '../../store';

import Loader from '../../components/Loader/Loader';

import { IUserState } from '../../reducers/user';
import { verifyEmail } from '../../actions/user';
import { createToast } from '../../actions/toaster';

import './VerifyEmail.scss';
import { IToast } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  createToast(toast: IToast): void;
  verifyEmail(token: string): void;
  userState: IUserState;
}

interface IState {
  alreadyVerified: boolean;
  loginRedirect: boolean;
}

class VerifyEmail extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      alreadyVerified: false,
      loginRedirect: false,
    };
  }

  componentDidMount() {
    if (this.props.userState.user.is_email_verified) {
      setSEOMetatags({
        title: localizeHelpers.translate('Verify email | Kambeo'),
        urlPath: 'verify-email',
      });
      const toast = toastSuccess(
        localizeHelpers.translate('Your email has already been verified.'),
        'Email Verification',
      );
      this.props.createToast(toast);

      this.setState({
        alreadyVerified: true,
      });
    } else {
      if (this.props.userState.isLoggedIn) {
        this.verify();
      } else {
        this.setState({
          loginRedirect: true,
        });
      }
    }
  }

  verify() {
    let _params = queryString.parse(this.props.location.search);

    Axios.post(routes.VERIFY_EMAIL, { value: _params.token?.toString() || '' })
      .then((response) => {
        if (response.data.is_email_verified) {
          const toast = toastSuccess(
            localizeHelpers.translate('Your email has already been verified.'),
            'Email Verification',
          );
          this.props.createToast(toast);
        } else {
          const toast = toastError(
            localizeHelpers.translate('Verification token has expired.'),
            'Email Verification',
          );
          this.props.createToast(toast);
        }
      })
      .catch((error: any) => {
        let _msg =
          error.response.data.gigitErrorCode || error.response.data.message || 'No error code';
        const toast = toastError(
          localizeHelpers.translate('Error verifying email. (' + _msg + ')'),
          'Email Verification',
        );
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({
          alreadyVerified: true,
        });
      });
  }

  render() {
    if (this.state.alreadyVerified) {
      return <Redirect to="/" />;
    } else if (this.state.loginRedirect) {
      return (
        <Redirect
          to="/login"
          push={true}
        />
      );
    } else {
      return (
        <div className="VerifyEmail">
          <Loader loading={true} />
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

const mapDispatchToProps = {
  createToast,
  verifyEmail,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(VerifyEmail),
);
