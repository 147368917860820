import React, { useEffect, useState, ChangeEvent } from 'react';
import { IHub, IGroup, IHubPartnerInviteCreateParamsFE } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import { hubRequestActions, groupRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import './HubManagementBenefitingCauses.scss';
import TextField from '../../../TextField/TextField';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../../shared/PopupMenu/PopupMenu';
import { useLocation } from 'react-router-dom';
import Loader from '../../../Loader/Loader';
import { formatQuery, typeHelpers } from '../../../../helpers';
import Checkbox from '../../../Checkbox/Checkbox';
import Portrait from '../../../Portrait/Portrait';
import { Prompt } from '../../../Prompt/Prompt';
import queryString from 'query-string';
import Table, { ITableProps } from '../../../shared/Table/Table';
import DefaultLink from '../../../shared/Link/Link';
import { Config } from '@gigit/config';
import HubManagementSpotlights from '../HubManagementSpotlights/HubManagementSpotlights';

export interface ISpotlightItem {
  title: string;
  profileImage: string;
  start_date: Date | string;
  end_date: Date | string;
}
export const demoData: ISpotlightItem[] = [
  {
    title: 'Running for Cancer',
    profileImage: 'https://gigitdev01.s3.ca-central-1.amazonaws.com/1666059092635-blob',
    start_date: '2022/10/19',
    end_date: '2022/11/19',
  },
  {
    title: 'Autism Research Foundation',
    profileImage: 'https://gigitdev01.s3.ca-central-1.amazonaws.com/1666120247894-blob',
    start_date: '2022/11/19',
    end_date: '2022/12/19',
  },
  {
    title: 'Better Water',
    profileImage: 'https://gigitdev01.s3.ca-central-1.amazonaws.com/1666119905327-blob',
    start_date: '2023/01/01',
    end_date: '2023/01/31',
  },
];
interface IProps {
  hub?: IHub;
}

interface IInviteValues {
  firstName: string;
  lastName: string;
  email: string;
  charityName: string;
  charityWebsite: string;
}

const HubManagementBenefitingCauses: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showContextMenuModalSort, setShowContextMenuModalSort] = useState<boolean>(false);
  const [hubPartners, setHubPartners] = useState<IGroup[]>([]);
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const [loadingToAddPartners, setLoadingToAddPartners] = useState<boolean>(false);
  const [selectedPartnersIDs, setSelectedPartnersIDs] = useState<string[]>([]);
  const [toAddPartners, setToAddPartners] = useState<IGroup[]>([]);
  const [modalSearchValue, setModalSearchValue] = useState<string | null>(null);
  const [modalSortBy, setModalSortBy] = useState<'asc' | 'desc'>('asc');
  const [groupToDelete, setGroupToDelete] = useState<{ id: string; name: string } | null>(null);
  const [modalSearchTimeout, setModalSearchTimeout] = useState<ReturnType<typeof setTimeout>>();
  const location = useLocation();
  const [showModalInvite, setShowModalInvite] = useState<boolean>(false);
  const [inviteValues, setInviteValues] = useState<IInviteValues>({
    firstName: '',
    lastName: '',
    email: '',
    charityName: '',
    charityWebsite: '',
  });
  type IInviteValuesKeys = keyof IInviteValues;

  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  async function getGroups() {
    if (modalSearchValue) {
      try {
        setLoadingToAddPartners(true);
        const result = await groupRequestActions.getGroups(
          formatQuery({
            limit: '24',
            sort: [{ id: 'title', order: modalSortBy }],
            filters:
              modalSearchValue !== '' && modalSearchValue !== null
                ? [
                    {
                      id: 'title',
                      label: 'title',
                      type: 'text',
                      value: modalSearchValue,
                    },
                  ]
                : undefined,
          }),
        );
        const tmpIds = hubPartners.map((partner) => partner.id) as string[];
        const filteredGroups = result.filter((group) => tmpIds.indexOf(group.id) === -1);
        setToAddPartners(filteredGroups);
      } catch (error) {
        dispatchToastError(error, 'Get Partners To Add');
      } finally {
        setLoadingToAddPartners(false);
      }
    }
  }

  async function getHubPartners() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  async function removeCause() {
    if (props?.hub?.id && groupToDelete) {
      try {
        await hubRequestActions.removeHubPartner(props.hub.id, groupToDelete.id);
        await getHubPartners();
        dispatchToastSuccess('Successfully removed hub partner', 'Remove Hub Partners');
      } catch (error) {
        dispatchToastError(error, 'Remove Hub Partners');
      }
    }
  }

  async function addCauses() {
    if (props?.hub?.id) {
      if (selectedPartnersIDs.length > 0) {
        for (const id of selectedPartnersIDs) {
          try {
            await hubRequestActions.addHubPartner(props.hub.id, id);
            dispatchToastSuccess('Successfully added hub partner', 'Add Hub Partners');
          } catch (error) {
            dispatchToastError(error, 'Add Hub Partner');
          }
        }

        await getHubPartners();
      }
    }
  }

  function showAddCausesModal() {
    resetModalFilters();
    setShowModal(true);
  }

  async function inviteCauses() {
    if (props?.hub?.id) {
      const payload: IHubPartnerInviteCreateParamsFE = {
        contact_details: {
          first_name: inviteValues.firstName,
          last_name: inviteValues.lastName,
          email: inviteValues.email,
        },
        title: inviteValues.charityName,
        website_url: inviteValues.charityWebsite,
      };
      try {
        await hubRequestActions.inviteGroupToHub(props.hub.id, payload);
        dispatchToastSuccess('Successfully sent an Invite', 'Invite Cause to Hub');
        setShowModalInvite(false);
      } catch (error) {
        dispatchToastError(error, 'Invite Cause to Hub');
      }
    }
  }

  const isAddToPartnerChecked = (partnerId: string): boolean => {
    return selectedPartnersIDs.indexOf(partnerId) !== -1;
  };

  const popupMenuConfigModalSort: IShowPopupConfig = {
    showMenu: showContextMenuModalSort,
    setShowMenu: setShowContextMenuModalSort,
    position: {
      type: 'bottom',
    },
  };
  const contextMenuItemsModalSort: IPopupMenuItem[] = [
    {
      id: 'a_z',
      label: 'A to Z',
      isSelected: 'asc' === modalSortBy,
      onClick: () => {
        setModalSortBy('asc');
        setShowContextMenuModalSort(false);
      },
    },
    {
      id: 'z_a',
      label: 'Z to A',
      isSelected: 'desc' === modalSortBy,
      onClick: () => {
        setModalSortBy('desc');
        setShowContextMenuModalSort(false);
      },
    },
  ];

  useEffect(() => {
    if (modalSearchTimeout) {
      clearTimeout(modalSearchTimeout);
    }

    if (modalSearchValue !== null) {
      setModalSearchTimeout(
        setTimeout(() => {
          getGroups();
        }, 500),
      );
    }

    return () => {
      if (modalSearchTimeout) {
        clearTimeout(modalSearchTimeout);
      }
    };
  }, [modalSearchValue]);

  useEffect(() => {
    if (showModal) {
      getGroups();
    }
  }, [modalSortBy]);

  useEffect(() => {
    let _params = queryString.parse(location.search);

    if (_params.showAddCauses) {
      showAddCausesModal();
    }
  }, [location]);

  const resetModalFilters = (): void => {
    setModalSortBy('asc');
    setModalSearchValue('');
    setSelectedPartnersIDs([]);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInviteValues({ ...inviteValues, [e.target.name]: e.target.value });
  };

  const benefitingCausesTableProps: ITableProps<IGroup> = {
    columns: [
      {
        id: 'title',
        Header: 'Partner Title',
        accessor: ({ title }) => title,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ profile_image_url, title, handle }) => {
          return (
            <DefaultLink
              to={`/group/${handle}`}
              className="item-name-col"
              notranslate="yes"
            >
              <Portrait
                size={30}
                className="image-container"
                currentImage={profile_image_url}
              />
              <span notranslate="yes">{title}</span>
            </DefaultLink>
          );
        },
      },
    ],
    filterOptions: {
      enableTableSearch: true,
    },
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Remove Cause',
          icon: 'fas fa-trash',
          onClick: (e, group) => {
            setGroupToDelete({ id: group.id, name: group.title });
          },
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        try {
          typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Hub Type');
          const result = await hubRequestActions.getHubPartners(props.hub.id, queryParams);
          setHubPartners(result);
          return result;
        } catch (error) {
          dispatchToastError(error, 'Get Benefiting Causes');
          return [];
        }
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any benefiting Causes",
    },
  };

  const clearInviteValues = () => {
    setInviteValues({
      firstName: '',
      lastName: '',
      email: '',
      charityName: '',
      charityWebsite: '',
    });
  };

  const hasRequiredValues = () => {
    return (
      Object.keys(inviteValues).filter((key) => inviteValues[key as IInviteValuesKeys]).length ===
      Object.keys(inviteValues).length
    );
  };

  return (
    <>
      <div
        className="HubManagementBenefitingCauses"
        id="benefiting-causes"
      >
        <div className="header-section">
          <h2>Benefiting Causes</h2>
          <div>
            <Button
              type="button"
              text="Invite Cause"
              icon="fal fa-plus"
              onClick={() => {
                setShowModalInvite(true);
              }}
            />
            <Button
              type="button"
              text="Add Causes"
              icon="fal fa-plus"
              onClick={async () => {
                resetModalFilters();
                setShowModal(true);
              }}
            />
          </div>
        </div>
        <div className="table-section">
          <Table
            {...benefitingCausesTableProps}
            refreshTableIncrementor={refreshTableIncrementor}
          />
        </div>

        <Modal
          onClose={() => setShowModal(false)}
          show={showModal}
          contentClassName="add-causes-modal"
          closeIcon="fas fa-times"
        >
          <div className="modal-header">
            <h2>Add Causes</h2>
            <span>Select the causes that you want to support with your company</span>
            <div className="filter-container">
              <TextField
                icon="fal fa-search"
                placeholder="Search for a Charity or Non-Profit"
                value={modalSearchValue || ''}
                name="modal_search"
                type="text"
                onChange={(e) => {
                  setModalSearchValue(e.target.value);
                }}
              />

              <PopupMenu
                showMenuConfig={popupMenuConfigModalSort}
                menuItems={contextMenuItemsModalSort}
                popupMenuClass="hubManagementBenefitingCauses-popupMenu"
                className={`Dropdown filter-dropdown ${showContextMenuModalSort ? 'show' : ''}`}
                onClick={() => setShowContextMenuModalSort(!showContextMenuModalSort)}
              >
                <label>
                  <span>Sort by</span>
                </label>
                <div className="input-wrap">
                  <span
                    className="label"
                    notranslate="yes"
                  >
                    {modalSortBy === 'asc' ? 'A to Z' : 'Z to A'}
                  </span>
                  <i
                    className={`fas fa-caret-down menu-btn ${showContextMenuModalSort ? 'show' : ''}`}
                  />
                </div>
              </PopupMenu>
            </div>
          </div>

          <div className="modal-main">
            <div className="partners-container">
              <Loader loading={loadingToAddPartners} />
              {!modalSearchValue && (
                <div className="empty-state">
                  <span>
                    Use the search bar to find causes you want to support with your company
                  </span>
                </div>
              )}
              {!loadingToAddPartners && modalSearchValue && toAddPartners.length === 0 && (
                <div className="empty-state">
                  <span>No results found</span>
                </div>
              )}
              {toAddPartners.map((partner, index) => (
                <div
                  className="to-add-partner"
                  key={index}
                >
                  <Portrait
                    size={40}
                    currentImage={partner.profile_image_url}
                  />
                  <div className="info">
                    <span
                      className="title"
                      notranslate="yes"
                    >
                      {partner.title}
                    </span>
                    <span className="charity-id">
                      {`Charity ID : `}
                      <var data-var="charity_id">{partner.charity_id}</var>
                    </span>
                  </div>
                  <Checkbox
                    name={`modal-cb-${index}`}
                    label=""
                    value=""
                    checked={isAddToPartnerChecked(partner.id)}
                    onChange={() => {
                      if (isAddToPartnerChecked(partner.id)) {
                        setSelectedPartnersIDs(
                          selectedPartnersIDs.filter((id) => id !== partner.id),
                        );
                      } else {
                        setSelectedPartnersIDs([...selectedPartnersIDs, partner.id]);
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="btn-container">
            <Button
              text="Cancel"
              type="button"
              onClick={() => setShowModal(false)}
              buttonType="secondary"
            />
            <Button
              text="Save"
              type="button"
              buttonType="dark"
              onClick={async () => {
                await addCauses();
                setShowModal(false);
              }}
            />
          </div>
        </Modal>

        <Modal
          onClose={() => {
            setShowModalInvite(false);
            clearInviteValues();
          }}
          show={showModalInvite}
          contentClassName="invite-causes-modal"
          closeIcon="fas fa-times"
        >
          <div className="modal-header">
            <h2>Invite a Cause or Charity</h2>
            <span>
              Have a Cause or Charity you want to support that isn't on Kambeo yet? Send your
              contact(s) an email invitation to setup their Kambeo Cause Page. It only takes a
              couple of minutes and is completely free for them to do. Your employees will be able
              to support them immediately after they create their page.
            </span>
          </div>

          <div className="modal-main">
            <TextField
              label="Primary Contact First Name"
              value={inviteValues.firstName}
              name="firstName"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e);
              }}
              required={true}
            />

            <TextField
              label="Primary Contact Last Name"
              value={inviteValues.lastName}
              name="lastName"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e);
              }}
              required={true}
            />

            <TextField
              label="Email"
              value={inviteValues.email}
              name="email"
              type="email"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e);
              }}
              required={true}
            />

            <TextField
              label="Charity Name"
              value={inviteValues.charityName}
              name="charityName"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e);
              }}
              required={true}
            />

            <TextField
              label="Charity Website"
              value={inviteValues.charityWebsite}
              name="charityWebsite"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e);
              }}
              required={true}
            />
          </div>

          <div className="btn-container">
            <Button
              text="Cancel"
              type="button"
              onClick={() => {
                setShowModalInvite(false);
                clearInviteValues();
              }}
              buttonType="secondary"
            />
            <Button
              text="Send Invite"
              type="button"
              buttonType="dark"
              onClick={async () => {
                await inviteCauses();
                clearInviteValues();
              }}
              isDisabled={!hasRequiredValues()}
            />
          </div>
        </Modal>

        {/* Spotlight Section - Make Component */}
        {Config.feature_flags.HUB_DONATION_MATCHING && (
          <>
            <hr />
            <HubManagementSpotlights
              spotlightListConfig={{
                type: 'group',
                hubId: props.hub?.id ?? '',
                typeEntities: hubPartners,
              }}
            />
          </>
        )}
      </div>

      <Prompt
        show={groupToDelete !== null}
        title="Delete Partner"
        message={`Are you sure you want to delete a partner ${groupToDelete?.name || ''}?`}
        yesMessage="Yes"
        yesClass="fa fa-trash"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={async () => removeCause()}
        onClose={() => setGroupToDelete(null)}
      />
    </>
  );
};

export default HubManagementBenefitingCauses;
