import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IAppState } from '../../store';

import './DiscountCode.scss';
import {
  createDiscountCode,
  StoreItemOwnerType,
  updateDiscountCode,
  deleteDiscountCode,
  activateDiscountCode,
} from '../../actions/store';
import TextField from '../TextField/TextField';
import { handleInputChange } from '../../helpers';
import Button from '../Button/Button';
import { IOptions } from '../Dropdown/Dropdown';
import { IGroupState } from '../../reducers/group';
import { IDiscountCode } from '@gigit/interfaces';

interface IProps extends WithTranslation {
  ownerType: string;
  ownerId: string;
  createDiscountCode(
    gownerType: StoreItemOwnerType,
    ownerId: string,
    _payload: Partial<IDiscountCode>,
  ): void;
  updateDiscountCode(
    ownerType: StoreItemOwnerType,
    ownerId: string,
    _discountId: string,
    _payload: Partial<IDiscountCode>,
  ): void;
  deleteDiscountCode(ownerType: StoreItemOwnerType, ownerId: string, discountId: string): void;
  activateDiscountCode(ownerType: StoreItemOwnerType, ownerId: string, discountId: string): void;
  onClose(): void;
  groupState: IGroupState;
  discount: IDiscountCode;
}
interface IState {
  code: string;
  discount_type: string;
  types: Array<IOptions>;
  amount: number;
  status: string;
}

class DiscountCodeForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      code: this.props.discount.code || '',
      discount_type: this.props.discount.discount_type || 'fixed',
      types: [
        { value: 'percentage', label: 'Percentage' },
        { value: 'fixed', label: 'Fixed' },
      ],
      amount: this.props.discount.amount || 0,
      status: (this.props.discount.status && this.props.discount.status.code) || 'active',
    };
  }

  isActiveStatus(status: string) {
    return status === this.state.status ? 'status chosen' : 'status';
  }

  isActiveType(type: string) {
    return type === this.state.discount_type ? 'status chosen' : 'status';
  }

  deleteDiscount(discount: any) {
    this.props.deleteDiscountCode(
      this.props.ownerType as StoreItemOwnerType,
      this.props.ownerId,
      discount.id,
    );
    this.setStatus('disabled');
  }

  activateDiscount(discount: any) {
    this.props.activateDiscountCode(
      this.props.ownerType as StoreItemOwnerType,
      this.props.ownerId,
      discount.id,
    );
    this.setStatus('active');
  }

  setType(type: string) {
    this.setState({
      discount_type: type,
    });
  }

  setStatus(status: string) {
    this.setState({
      status: status,
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="DiscountCodeForm">
        <div className="title row center">
          {this.props.discount.id && t('Edit Discount Code')}
          {!this.props.discount.id && t('Add Discount Code')}
        </div>
        <div className="subheader">{t('Discount Information')}</div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.createDiscountCode();
          }}
          className="inner"
        >
          <div className="row">
            <TextField
              type="text"
              onChange={(e: any) => {
                handleInputChange(e, this);
              }}
              name="code"
              value={this.state.code}
              required={true}
              label={t('Code')}
              icon="fas fa-user"
            />
          </div>
          <label>Type</label>
          <div className="row">
            <div className="status-section">
              <div
                className={this.isActiveType('fixed')}
                onClick={() => this.setType('fixed')}
              >
                Dollar
              </div>
              <div
                className={this.isActiveType('percentage')}
                onClick={() => this.setType('percentage')}
              >
                Percentage
              </div>
            </div>
          </div>
          <div className="row">
            <TextField
              type="number"
              onChange={(e: any) => {
                handleInputChange(e, this);
              }}
              name="amount"
              value={`${this.state.amount}`}
              required={true}
              label={t('Amount')}
              icon="fas fa-user"
            />
          </div>
          <label>Status</label>
          <div className="row">
            <div className="status-section">
              <div
                className={this.isActiveStatus('active')}
                onClick={() => this.activateDiscount(this.props.discount)}
              >
                Active
              </div>
              <div
                className={this.isActiveStatus('disabled')}
                onClick={() => this.deleteDiscount(this.props.discount)}
              >
                Disabled
              </div>
            </div>
          </div>
          <div className="row end">
            <Button
              text={this.props.discount.id ? 'Update' : 'Create'}
              type="submit"
            />
          </div>
        </form>
      </div>
    );
  }

  componentDidMount() {}

  createDiscountCode() {
    const payload: Partial<IDiscountCode> = {
      code: this.state.code,
      amount: Number(this.state.amount),
      discount_type: this.state.discount_type,
      status: { code: this.state.status },
    };

    if (this.props.discount.id) {
      this.props.updateDiscountCode(
        this.props.ownerType as StoreItemOwnerType,
        this.props.ownerId,
        this.props.discount.id,
        payload,
      );
    } else {
      this.props.createDiscountCode(
        this.props.ownerType as StoreItemOwnerType,
        this.props.ownerId,
        payload,
      );
    }
    this.props.onClose();
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
  };
};

const mapDispatchToProps = {
  createDiscountCode: createDiscountCode,
  updateDiscountCode: updateDiscountCode,
  activateDiscountCode: activateDiscountCode,
  deleteDiscountCode: deleteDiscountCode,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(DiscountCodeForm),
);
