import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { IFiscalYearDefinition } from '@gigit/interfaces';

import { errorHelpers, routes, swapRouteParams, toastError } from '../../../helpers';
import { IOwnerObject } from '../../../interfaces';
import { createToast } from '../../../actions/toaster';
import Modal from '../../Modal/Modal';
import Dropdown from '../../Dropdown/Dropdown';
import Button from '../../Button/Button';

import './IssuedTaxReceiptsExportModal.scss';

export enum TaxReceiptExportType {
  IssuedTaxReceipts,
  SkippedTaxReceipts,
}

interface IProps {
  show: boolean;
  exportType: TaxReceiptExportType | null;
  onClose: () => void;
  owner: IOwnerObject;
}

//TODO: Taken from downloadFile() in helpers.tsx in develop branch. This should be deleted and use that one instead.
export function downloadFile(fileName: string, blobData: string, mime: string = 'text/csv'): void {
  let blob = new Blob([blobData], { type: mime });

  let file = URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = file;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
}

/** Modal for exporting issued tax receipts. */
export function IssuedTaxReceiptsExportModal(props: IProps) {
  const [fiscalYears, setFiscalYears] = useState<IFiscalYearDefinition[]>([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<number>();

  const dispatch = useDispatch();

  const fetchFiscalYears = async () => {
    try {
      const url = swapRouteParams(routes.GET_FISCAL_YEAR_LIST, {
        groupId: props.owner.ownerId,
      });
      const response = await axios.get<IFiscalYearDefinition[]>(url);

      setFiscalYears(response.data);
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Fetch Fiscal Years');

      dispatch(createToast(toast));
    }
  };

  useEffect(() => {
    if (props.show) {
      fetchFiscalYears();
    }
  }, [props.show]);

  useEffect(() => {
    if (fiscalYears.length > 0) {
      // Select recent year by default
      setSelectedFiscalYear(fiscalYears[fiscalYears.length - 1].fiscal_year);
    } else {
      setSelectedFiscalYear(0);
    }
  }, [fiscalYears]);

  const exportIssuedReceipts = async () => {
    try {
      const url = swapRouteParams(routes.GET_ISSUED_RECEIPTS_EXPORT, {
        groupId: props.owner.ownerId,
        fiscalYear: selectedFiscalYear,
      });
      const response = await axios.get<string>(url);

      downloadFile(
        `Issued_Tax_Receipts_For_${props.owner.ownerHandle}_${selectedFiscalYear}.csv`,
        response.data,
      );

      props.onClose();
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Export Issued Receipts');

      dispatch(createToast(toast));
    }
  };

  const exportSkippedReceipts = async () => {
    try {
      const url = swapRouteParams(routes.GET_SKIPPED_RECEIPTS_EXPORT, {
        groupId: props.owner.ownerId,
        fiscalYear: selectedFiscalYear,
      });
      const response = await axios.get<string>(url);

      downloadFile(
        `Skipped_Tax_Receipts_For_${props.owner.ownerHandle}_${selectedFiscalYear}.csv`,
        response.data,
      );

      props.onClose();
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Export Skipped Receipts');

      dispatch(createToast(toast));
    }
  };

  const doExport = () => {
    switch (props.exportType) {
      case TaxReceiptExportType.IssuedTaxReceipts:
        exportIssuedReceipts();
        break;
      case TaxReceiptExportType.SkippedTaxReceipts:
        exportSkippedReceipts();
        break;
    }
  };

  return (
    <Modal
      title={`Export ${props.exportType === TaxReceiptExportType.IssuedTaxReceipts ? 'Issued' : 'Skipped'} Tax Receipts`}
      show={props.show}
      onClose={props.onClose}
    >
      <div className="IssuedTaxReceiptsExportModal">
        <Dropdown
          className="fiscal-year-dropdown"
          label="Fiscal Year"
          onChange={(e) => setSelectedFiscalYear(parseInt(e.target.value))}
          value={String(selectedFiscalYear)}
          options={fiscalYears.map((fiscalYear) => ({
            label: String(fiscalYear.fiscal_year),
            value: String(fiscalYear.fiscal_year),
          }))}
          name="fiscalYear"
        />

        <Button
          type="button"
          text="Export"
          onClick={doExport}
        />
      </div>
    </Modal>
  );
}
