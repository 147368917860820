import './MultiSelectField.scss';

import React from 'react';
import Select, { ActionMeta } from 'react-select';
import SelectOption from '../shared/SelectOption/SelectOption';
import { ISelectOption } from '../types';

export interface IMultiSelectFieldProps {
  options: Array<ISelectOption>;
  onChange: (options: Array<ISelectOption>) => void;

  /** Pass if you need to control the values */
  selectedOptions?: Array<ISelectOption>;
  label?: string;
  /** If you want the context menu to appear outside of a modal */
  addContextMenuToPortal?: boolean;
  /** Defaults */
  isClearable?: boolean;
}

function MultiSelectField(props: IMultiSelectFieldProps) {
  const doesListHaveIconOrImage = !!props.options.find(
    (option) => !!option.icon || !!option.imageURL,
  );

  function handleOnMultiSelectChange(
    _options: readonly ISelectOption[],
    actionMeta: ActionMeta<ISelectOption>,
  ) {
    const options = [..._options];

    props.onChange(options);
  }

  return (
    <div className="MultiSelectFieldContainer">
      {!!props.label && <label>{props.label}</label>}
      <Select
        className="SelectField"
        options={props.options}
        menuPortalTarget={props.addContextMenuToPortal ? document.body : undefined}
        menuPosition={props.addContextMenuToPortal ? 'fixed' : undefined}
        isMulti={true}
        isClearable={props.isClearable}
        onChange={handleOnMultiSelectChange}
        value={props.selectedOptions}
        components={{
          Option: (props) => (
            <SelectOption
              {...props}
              hasIconPadding={doesListHaveIconOrImage}
            />
          ),
        }}
        styles={{
          menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999,
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#3A3A3A' : '#D0D1D1',
            boxShadow: 'none',
            '&:hover': {
              borderColor: '#A9AAAC',
            },
          }),
        }}
      />
    </div>
  );
}

export default MultiSelectField;
