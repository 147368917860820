import React, { useState, useRef, useEffect, useReducer, Fragment } from 'react';
import Joyride from 'react-joyride';
import queryString from 'query-string';
import './CommunityFeed.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CommunityFeedAsideCard from './CommunityFeedAsideCard/CommunityFeedAsideCard';
import CommunityFeedPost from './CommunityFeedPost/CommunityFeedPost';
import {
  IActivityPostFE,
  IActivityUserFeedItemSummaryFE,
  ICause,
  IEmbeddedGroupDetail,
  IGroup,
  IHub,
  IInviteSummary,
  ILoggedVolunteerHoursSummary,
  IShiftUserSummary,
  IStoryResultFE,
  IUserMyPageItem,
  IEventTeam,
  IGigSummaryFE,
  ISpotlightReturn,
  IApplication,
  IGig,
} from '@gigit/interfaces';
import { IPopupMenuItem, IShowPopupConfig } from '../../../shared/PopupMenu/PopupMenu';
import {
  causeRequestActions,
  hubRequestActions,
  inviteRequestActions,
  userRequestActions,
} from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import { useFetch, useInfiniteScroll } from './CommunityFeedCustomHooks';
import Button from '../../../Button/Button';
import { StoryCarousel } from '../../../Stories/Components/StoryCarousel/StoryCarousel';
import FeedEmptyState from '../../../../assets/community-feed-empty-state.png';
import { useSelector } from 'react-redux';
import { IAppState, useAppDispatch } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import {
  formatQuery,
  IQueryParams,
  routes,
  IRecommendedFocusAreas,
  capitalizeString,
  typeHelpers,
} from '../../../../helpers';
import { uiConstants } from '../../../../constants';
import { Constants } from '@gigit/constants';
import axios from 'axios';
import CommunityFeedDonationModal from './CommunityFeedDonationModal/CommunityFeedDonationModal';
import Portrait from '../../../Portrait/Portrait';
import ReportHoursModal from '../../../ReportHoursModal/ReportHoursModal';
import VolunteerModal from '../../../VolunteerModal/VolunteerModal';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import moment from 'moment';
import MetricsList from '../../../shared/MetricsList/MetricsList';
import { IThemeStyleObjectsByType } from '../../../Hub/Hub';
import Stories from '../../../Stories/Components/Stories/Stories';
import Modal from '../../../Modal/Modal';
import { updateUser } from '../../../../actions/user';
import CommunityFeedPostSkeleton from './CommunityFeedPost/CommunityFeedPostSkeleton';
import CommunityFeedLeftSkeleton from './CommunityFeedLeftSkeleton/CommunityFeedLeftSkeleton';
import CommunityFeedFocusAreas from './CommunityFeedFocusAreas/CommunityFeedFocusAreas';
import { Config } from '@gigit/config';
import HubModalDonate from '../../../Hub/HubModals/HubModalDonate/HubModalDonate';
import { hubDemoData } from './DemoHubData';
import SpotlightTile from '../../../Spotlights/SpotlightTile/SpotlightTile';
import VolunteerApplicationModal from '../../../VolunteerApplicationModal/VolunteerApplicationModal';
import { IOwnerObject } from '../../../../interfaces';
import SpotlightCarousel from '../../../Spotlights/SpotlightTile/SpotlightCarousel';
import LogVolunteerHours from '../../../LogVolunteerHours/LogVolunteerHours';

interface IProps {
  scrollState?: number;
  clientHeight?: number;
  notUserCommunityFeed?: boolean;
  objectId?: string;
  objectType?: string;
  feedTitle?: string;
  theme?: IThemeStyleObjectsByType;
  hubactivity?: boolean;
  hub?: IHub;
}

interface IStep {
  target: string;
  content: string;
  disableBeacon?: boolean;
}

const CommunityFeed: React.FC<IProps> = (props) => {
  const userInfo = useSelector((state: IAppState) => userSelectors.getUser(state));
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));

  const [showReportHoursModal, setShowReportHoursModal] = useState<boolean>(false);
  const [showVolunteerModal, setShowVolunteerModal] = useState<boolean>(false);
  const [loadingVolunteerHours, setLoadingVolunteerHours] = useState<boolean>(true);
  const [loadingVolunteerOpportunities, setLoadingVolunteerOpportunities] = useState<boolean>(true);
  const [volunteerOpportunities, setVolunteerOpportunities] = useState<IGigSummaryFE[]>([]);
  const [volunteerOpportunitiesHours, setVolunteerOpportunitiesHours] = useState<
    ILoggedVolunteerHoursSummary[]
  >([]);
  const [userApplications, setUserApplications] = useState<IApplication[]>([]);
  const [loadingUserApplications, setLoadingUserApplications] = useState<boolean>(true);
  const [steps, setSteps] = useState<IStep[]>([]);

  const [invites, setInvites] = useState<IInviteSummary[]>([]);
  const [shifts, setShifts] = useState<IShiftUserSummary[]>([]);
  const [supportedCharities, setSupportedCharities] = useState<IGroup[]>([]);
  const [recommendedGroups, setRecommendedGroups] = useState<IGroup[]>([]);
  const [causes, setCauses] = useState<ICause[]>([]);
  const [userHubs, setUserHubs] = useState<IUserMyPageItem[]>([]);
  const [userCauses, setUserCauses] = useState<string[]>(userState.user?.causes || []);
  const [loadingSupportedCauses, setLoadingSupportedCauses] = useState<boolean>(false);
  const [supportedCauses, setSupportedCauses] = useState<ICause[]>([]);
  const [recommendedFocusAreas, setRecommendedFocusAreas] = useState<IRecommendedFocusAreas[]>([]);
  const [hubFocusAreas, setHubFocusAreas] = useState<ICause[]>([]);
  const [stories, setStories] = useState<any[]>([]); // TODO add proper interface
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('all');
  const [loadingPosts, setLoadingPosts] = useState<boolean>(true);
  const [loadingCauses, setLoadingCauses] = useState<boolean>(true);
  const [loadingInvites, setLoadingInvites] = useState<boolean>(true);
  const [loadingShifts, setLoadingShifts] = useState<boolean>(true);
  const [loadingSupportedCharities, setLoadingSupportedCharities] = useState<boolean>(false);
  const [loadingRecommendedGroups, setLoadingRecommendedGroups] = useState<boolean>(true);
  const [loadingUserHubs, setLoadingUserHubs] = useState<boolean>(true);
  const [loadingRecommendedFocusAreas, setLoadingRecommendedFocusAreas] = useState<boolean>(true);
  const [loadingHubFocusAreas, setLoadingHubFocusAreas] = useState<boolean>(false);
  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
  const [showMakeADonationModal, setShowMakeADonationModal] = useState<boolean>(false);

  const [showApplicationModal, setShowApplicationModal] = useState<boolean>(false);
  const [applicationOwner, setApplicationOwner] = useState<IOwnerObject | null>(null);

  const [showStoryModal, setShowStoryModal] = useState<boolean>(false);
  const [currentStory, setCurrentStory] = useState<IStoryResultFE>({} as IStoryResultFE);

  const [spotlights, setSpotlights] = useState<ISpotlightReturn[]>([]);

  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );

  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const dispatch = useAppDispatch();

  const userPostsReducer = (
    state: {
      notUserCommunityFeed: boolean;
      userPosts: IActivityUserFeedItemSummaryFE[];
      fetching: boolean;
      out_of_posts: boolean;
    },
    action: {
      type: string;
      fetching: boolean;
      out_of_posts: boolean;
      userPosts: IActivityUserFeedItemSummaryFE[];
      post: Omit<IActivityPostFE, 'feed_events' | 'comments'>;
      group: IEmbeddedGroupDetail;
    },
  ) => {
    switch (action.type) {
      case 'STACK':
        return { ...state, userPosts: state.userPosts.concat(...action.userPosts) };
      case 'FETCHING':
        return { ...state, fetching: action.fetching };
      case 'OUT_OF_POSTS':
        return { ...state, out_of_posts: action.out_of_posts };
      case 'HIDE_POST':
        return { ...state, userPosts: state.userPosts.filter((post) => post.post !== action.post) };
      case 'HIDE_GROUP_POSTS':
        return {
          ...state,
          userPosts: state.userPosts.filter((post) => post.group?.id !== action.group.id),
        };
      default:
        return state;
    }
  };

  const pageReducer = (state: { page: number }, action: { type: string }) => {
    switch (action.type) {
      case 'ADVANCE_PAGE':
        if (userPostsData.userPosts.length > 0 && !userPostsData.out_of_posts) {
          return { ...state, page: state.page + 1 };
        } else return state;

      default:
        return state;
    }
  };

  const limitPosts = 12;
  const { dispatchToastError } = useToastDispatcher();
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const rightAsideRef = useRef<HTMLDivElement>(null);
  const leftAsideRef = useRef<HTMLDivElement>(null);
  const rightAsideContainer = useRef<HTMLDivElement>(null);
  const leftAsideContainer = useRef<HTMLDivElement>(null);
  const bottomBoundaryRef = useRef(null);

  const [userPostsData, userPostsDataDispatch] = useReducer(userPostsReducer, {
    notUserCommunityFeed: props?.notUserCommunityFeed || false,
    userPosts: [],
    fetching: true,
    out_of_posts: false,
  });

  const [pager, pagerDispatch] = useReducer(pageReducer, { page: 0 });
  const [scrollCurrent, setScrollCurrent] = useState<number>(0);
  const [scrollDiffLeftTop, setScrollDiffLeftTop] = useState<number>(0);
  const [scrollDiffRightTop, setScrollDiffRightTop] = useState<number>(0);
  const [scrollDiffLeftBottom, setScrollDiffLeftBottom] = useState<number>(0);
  const [scrollDiffRightBottom, setScrollDiffRightBottom] = useState<number>(0);
  const [lockScrollLeftBottom, setLockScrollLeftBottom] = useState<boolean>(false);
  const [lockScrollLeftTop, setLockScrollLeftTop] = useState<boolean>(false);
  const [lockScrollRightBottom, setLockScrollRightBottom] = useState<boolean>(false);
  const [lockScrollRightTop, setLockScrollRightTop] = useState<boolean>(false);
  const [showDonateModal, setShowDonateModal] = useState<boolean>(false);

  if (steps.length === 0) {
    setSteps([
      {
        disableBeacon: true,
        target: '.discover-subheader',
        content:
          'Find an engage with events, fundraisers, volunteer opportunities, causes and companies who share the same passions you do',
      },
      {
        disableBeacon: true,
        target: '.MetricsList',
        content: 'Kambeo automatically tracks the good you do in your community',
      },
      {
        disableBeacon: true,
        target: '.story-container',
        content:
          "Share the things you're doing and your experiences with others in your network of change-makers",
      },
      {
        disableBeacon: true,
        target: '.posts-container',
        content:
          'See what your friends, causes you support and companies you work for or follow, are doing to make a positive impact in their communities',
      },
    ]);
  }

  useEffect(() => {
    // handleScroll();
  }, [props.clientHeight, props.scrollState, rightAsideRef, leftAsideRef, mainContainerRef]);

  useEffect(() => {
    if (applicationOwner) {
      setShowApplicationModal(true);
    }
  }, [applicationOwner]);

  const prepareApplicationDetails = (item: IGig | IGroup, type: 'gig' | 'group') => {
    const appOwner = typeHelpers.createOwnerObject(type, item);
    setApplicationOwner(appOwner);
  };

  const handleScroll = () => {
    if (props.scrollState && props.clientHeight) {
      const paddingTop = 24;

      const height = props.clientHeight - paddingTop - 70 - 88;

      const scrollAmount = props.scrollState - (paddingTop + 88);

      if (rightAsideRef.current && leftAsideRef.current && mainContainerRef.current) {
        if (scrollAmount > scrollCurrent) {
          setLockScrollLeftTop(false);
          setLockScrollRightTop(false);
          if (leftAsideRef.current?.clientHeight > height) {
            const heightDiff = leftAsideRef.current?.clientHeight - height;
            if (!lockScrollLeftBottom) {
              setScrollDiffLeftBottom(scrollDiffLeftBottom + (scrollAmount - scrollCurrent));
            } else {
              setScrollDiffLeftBottom(0);
            }

            if (scrollDiffLeftBottom > heightDiff || lockScrollLeftBottom) {
              leftAsideRef.current.style.top = `${props.scrollState - heightDiff}px`;
              setLockScrollLeftBottom(true);
              setScrollDiffLeftBottom(0);
            }
          }

          if (rightAsideRef.current?.clientHeight > height) {
            const heightDiff = rightAsideRef.current?.clientHeight - height;
            if (!lockScrollRightBottom) {
              setScrollDiffRightBottom(scrollDiffRightBottom + (scrollAmount - scrollCurrent));
            } else {
              setScrollDiffRightBottom(0);
            }

            if (scrollDiffRightBottom > heightDiff || lockScrollRightBottom) {
              rightAsideRef.current.style.top = `${props.scrollState - heightDiff}px`;
              setLockScrollRightBottom(true);
              setScrollDiffRightBottom(0);
            }
          }
        } else if (scrollAmount < scrollCurrent) {
          setLockScrollLeftBottom(false);
          setLockScrollRightBottom(false);
          if (leftAsideRef.current?.clientHeight > height) {
            const heightDiff = leftAsideRef.current?.clientHeight - height;
            if (!lockScrollLeftTop) {
              setScrollDiffLeftTop(scrollDiffLeftTop + (scrollCurrent - scrollAmount));
            } else {
              setScrollDiffLeftTop(0);
            }

            if (scrollAmount < 0) {
              leftAsideRef.current.style.top = `0px`;
            }
            if (scrollDiffLeftTop > heightDiff || lockScrollLeftTop) {
              if (scrollAmount < 0) {
                leftAsideRef.current.style.top = `0px`;
              } else {
                leftAsideRef.current.style.top = `${props.scrollState - heightDiff}px`;
              }
              setLockScrollLeftTop(true);
              setScrollDiffLeftTop(0);
            }
          }
          if (rightAsideRef.current?.clientHeight > height) {
            const heightDiff = rightAsideRef.current?.clientHeight - height;
            if (!lockScrollRightTop) {
              setScrollDiffRightTop(scrollDiffRightTop + (scrollCurrent - scrollAmount));
            } else {
              setScrollDiffRightTop(0);
            }

            if (scrollAmount < 0) {
              rightAsideRef.current.style.top = `0px`;
            }
            if (scrollDiffRightTop > heightDiff || lockScrollRightTop) {
              if (scrollAmount < 0) {
                rightAsideRef.current.style.top = `0px`;
              } else {
                rightAsideRef.current.style.top = `${scrollAmount}px`;
              }
              setLockScrollRightTop(true);
              setScrollDiffRightTop(0);
            }
          }
        }
      }

      setScrollCurrent(scrollAmount);
    }
  };

  async function getUserVolunteerOpportunities() {
    setLoadingVolunteerOpportunities(true);
    try {
      const result = await userRequestActions.getUserGigs();

      setVolunteerOpportunities(result);
    } catch (error) {
      dispatchToastError(error, 'Get User Volunteer opportunities');
    } finally {
      setLoadingVolunteerOpportunities(false);
    }
  }

  async function getUserVolunteerOpportunitiesHours() {
    setLoadingVolunteerHours(true);

    try {
      const result = await userRequestActions.getUserVolunteeringHours(
        formatQuery({
          limit: '2',
          sort: [
            {
              id: 'created_at',
              order: 'desc',
            },
          ],
        }),
      );

      setVolunteerOpportunitiesHours(result);
    } catch (error) {
      dispatchToastError(error, 'Get User Volunteer opportunities Hours');
    } finally {
      setLoadingVolunteerHours(false);
    }
  }

  async function getUserApplications() {
    setLoadingVolunteerHours(true);

    let params = {
      sortDir: 'desc' as const,
      sortBy: 'created_at',
      type: 'all',
      states: 'all',
    };

    try {
      const result = await userRequestActions.getUserApplications(params);

      setUserApplications(result);
    } catch (error) {
      dispatchToastError(error, 'Get User Volunteer opportunities Hours');
    } finally {
      setLoadingUserApplications(false);
    }
  }

  async function getCauses() {
    try {
      const result = await causeRequestActions.getCauses();
      setCauses(result);
      setLoadingCauses(false);
    } catch (error) {
      dispatchToastError(error, 'Get Focus Areas');
    }
  }

  async function getUserHubs() {
    if (isUserLoggedIn) {
      try {
        let params: IQueryParams = {
          sort: [{ id: 'lastUpdated', order: 'asc' }],
        };
        const formattedParams = formatQuery(params);
        const result = await userRequestActions.getUserMyPageItems2(
          formattedParams,
          Constants.object_type.hub,
        );
        setUserHubs(result);
        setLoadingUserHubs(false);
      } catch (error) {
        dispatchToastError(error, 'Get User Hubs');
      }
    }
  }

  function onPortraitClick(story: IStoryResultFE) {
    history.push(`/${story.entityType}/${story.entityHandle}`);
  }

  function getRecommendedFocusAreas() {
    if (!loadingRecommendedFocusAreas) {
      let tmpArray: IRecommendedFocusAreas[] = [];
      // Used to pick 8 random causes and set it as recommended
      // Commented out for https://app.clickup.com/t/8404472/GIG-6388
      /*
            userCauses.map((userFocusArea)=>{
                const tmpFocusArea = causes.find((cause)=>cause.id === userFocusArea);
                if(tmpFocusArea)tmpArray.push(tmpFocusArea);
            })
            const difArray = causes.filter(x=> !tmpArray.includes(x));
            */

      for (let cause of causes) {
        if (userCauses.indexOf(cause.id as string) !== -1) {
          tmpArray.push({ ...cause, selected: true });
        } else {
          tmpArray.push({ ...cause, selected: false });
        }
      }

      // Used to pick 8 random causes and set it as recommended
      // Commented out for https://app.clickup.com/t/8404472/GIG-6388
      /*
            let tmpRecommendedFocusAreas=[];
            for(let i = 0; i<8; i++){
                const randomIndex = Math.floor(Math.random() * difArray.length);
                const tempElement = difArray[randomIndex];
                difArray.splice(randomIndex,1);
                tmpRecommendedFocusAreas.push(tempElement);
            }
            */
      setRecommendedFocusAreas(tmpArray);
    } else setLoadingRecommendedFocusAreas(false);
  }

  function addFocusArea(focusAreaId: string) {
    setLoadingRecommendedFocusAreas(true);
    let tmpArray = userCauses;
    let tmpRecommendedFocusAreasArray = recommendedFocusAreas;
    const tmpFocusArea = causes.find((cause) => cause.id === focusAreaId);
    const userCheck = userCauses.find((cause) => cause === focusAreaId);

    if (tmpFocusArea && !userCheck) {
      try {
        tmpArray.push(focusAreaId);
        dispatch(updateUser({ causes: tmpArray }));
      } catch (error) {
        dispatchToastError(error, 'Add Focus Area');
      } finally {
        setUserCauses(tmpArray);
        let tmpIndex = tmpRecommendedFocusAreasArray.indexOf(tmpFocusArea);
        tmpRecommendedFocusAreasArray.splice(tmpIndex, 1);
      }
    } else if (tmpFocusArea && userCheck) {
      tmpArray = userCauses.filter((cause) => cause !== focusAreaId);
      try {
        dispatch(updateUser({ causes: tmpArray }));
      } catch (error) {
        dispatchToastError(error, 'Add Focus Area');
      } finally {
        setUserCauses(tmpArray);
        let tmpIndex = tmpRecommendedFocusAreasArray.indexOf(tmpFocusArea);
        tmpRecommendedFocusAreasArray.splice(tmpIndex, 1);
      }
    }

    const tmpFocusArray: IRecommendedFocusAreas[] = [];
    for (let cause of causes) {
      if (userCauses.indexOf(cause.id as string) !== -1) {
        tmpFocusArray.push({ ...cause, selected: true });
      } else {
        tmpFocusArray.push({ ...cause, selected: false });
      }
    }
    setRecommendedFocusAreas(tmpFocusArray);
    setLoadingRecommendedFocusAreas(false);
  }

  useEffect(() => {
    if (props.objectType === Constants.object_type.hub) {
      getSupportedCharities();
      getSupportedCauses();
    }
  }, [props.objectType]);

  useEffect(() => {
    if (isUserLoggedIn) {
      getCauses();
      getUserInvites();
      getUserGigs();
      searchGroupsByCauses();
      getUserVolunteerOpportunitiesHours();
      getUserApplications();
      getUserVolunteerOpportunities();
      getUserHubs();
      getUserSpotlights();
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    getRecommendedFocusAreas();
    if (props.hub) {
      getHubFocusAreas();
    }
  }, [causes, userCauses]);

  useFetch(
    {
      objectId: props.objectId,
      objectType: props.objectType,
      notUserCommunityFeed: props.notUserCommunityFeed,
      page: pager.page,
      limitPosts,
    },
    userPostsDataDispatch,
  );
  useInfiniteScroll(bottomBoundaryRef, pagerDispatch);

  useEffect(() => {
    setLoadingProfile(true);
    const timer = setTimeout(() => {
      setLoadingProfile(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const getShowMenuClass = showContextMenu ? 'show' : '';
  const popupMenuConfig: IShowPopupConfig = {
    showMenu: showContextMenu,
    setShowMenu: setShowContextMenu,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItems: IPopupMenuItem[] = [
    {
      id: 'all',
      label: 'All Categories',
      isSelected: selectedMenuItem === 'all',
      onClick: () => {
        setSelectedMenuItem('all');
        setShowContextMenu(false);
      },
    },
    {
      id: 'groups',
      label: 'Causes',
      isSelected: selectedMenuItem === 'groups',
      onClick: () => {
        setSelectedMenuItem('groups');
        setShowContextMenu(false);
      },
    },
    {
      id: 'events',
      label: 'Events',
      isSelected: selectedMenuItem === 'purchases',
      onClick: () => {
        setSelectedMenuItem('events');
        setShowContextMenu(false);
      },
    },
    {
      id: 'volunteering',
      label: 'Volunteering',
      isSelected: selectedMenuItem === 'volunteering',
      onClick: () => {
        setSelectedMenuItem('volunteering');
        setShowContextMenu(false);
      },
    },
    {
      id: 'paid_work',
      label: 'Paid Work',
      isSelected: selectedMenuItem === 'paid_work',
      onClick: () => {
        setSelectedMenuItem('paid_work');
        setShowContextMenu(false);
      },
    },
  ];

  async function getUserSpotlights() {
    try {
      const result = await userRequestActions.getMySpotlights();
      setSpotlights(result);
    } catch (error) {
      dispatchToastError(error, 'User Spotlights');
    }
  }
  async function getUserInvites() {
    try {
      setLoadingInvites(true);
      let result = await userRequestActions.getUserInvites();
      setInvites(result);
    } catch (error) {
      dispatchToastError(error, 'User Invites');
    } finally {
      setLoadingInvites(false);
    }
  }

  async function getUserGigs() {
    try {
      setLoadingShifts(true);
      let shifts = await userRequestActions.getUserGigShifts(
        formatQuery({
          limit: '6',
          sort: [{ order: 'desc', id: 'start_date' }],
        }),
      );

      setShifts(shifts);
    } catch (error) {
      dispatchToastError(error, 'User Shifts');
    } finally {
      setLoadingShifts(false);
    }
  }

  async function searchGroupsByCauses() {
    let payload = {
      cause_ids: userInfo.causes || [],
    };

    let query = {
      limit: '6',
    };

    try {
      setLoadingRecommendedGroups(true);
      let groups = await axios.post(`${routes.SEARCH_GROUPS}?${formatQuery(query)}`, payload);

      setRecommendedGroups(groups.data);
    } catch (error) {
      dispatchToastError(error, 'User Shifts');
    } finally {
      setLoadingRecommendedGroups(false);
    }
  }

  async function getSupportedCharities() {
    if (
      props?.notUserCommunityFeed &&
      props?.objectId &&
      props?.objectType === Constants.object_type.hub
    ) {
      try {
        setLoadingSupportedCharities(true);
        const result = await hubRequestActions.getHubPartners(
          props.objectId,
          formatQuery({ limit: '6' }),
        );
        setSupportedCharities(result);
      } catch (error) {
        dispatchToastError(error, 'Supported Charities');
      } finally {
        setLoadingSupportedCharities(false);
      }
    }
  }

  async function getSupportedCauses() {
    if (
      props?.notUserCommunityFeed &&
      props?.objectId &&
      props?.objectType === Constants.object_type.hub
    ) {
      try {
        setLoadingSupportedCauses(true);
        const result = await hubRequestActions.getHubPartnersCauses(props.objectId);
        setSupportedCauses(result);
      } catch (error) {
        dispatchToastError(error, 'Supported Causes');
      } finally {
        setLoadingSupportedCauses(false);
      }
    }
  }

  async function getHubFocusAreas() {
    if (
      props?.notUserCommunityFeed &&
      props?.objectId &&
      props?.objectType === Constants.object_type.hub
    ) {
      try {
        setLoadingHubFocusAreas(true);
        const result = await hubRequestActions.getHubByID(props.objectId);
        const tmpFocusAreaArray: string[] = result.causes ? result.causes : [];
        let tmpHubFocusAreasArray: ICause[] = [];
        tmpFocusAreaArray.map((tmpFocusAreaId) => {
          const tmpFocusArea = causes.find((focusArea) => focusArea.id === tmpFocusAreaId);
          if (tmpFocusArea) {
            tmpHubFocusAreasArray.push(tmpFocusArea);
          }
        });
        setHubFocusAreas(tmpHubFocusAreasArray);
      } catch (error) {
        dispatchToastError(error, 'Hub Focus Areas');
      } finally {
        setLoadingHubFocusAreas(false);
      }
    }
  }

  async function handleInvite(invite: IInviteSummary, accept: boolean) {
    const isUserInvite = invite.object_type === 'user';
    const errorTitle = isUserInvite ? 'User Contact Invite' : 'User Invite';

    if (accept) {
      const acceptAction = async () =>
        isUserInvite
          ? await userRequestActions.acceptContactRequest(invite.object_id)
          : await inviteRequestActions.acceptInvite(
              invite.object_type,
              invite.object_id,
              invite.id,
            );
      try {
        await acceptAction();
        await getUserInvites();
      } catch (error) {
        dispatchToastError(error, errorTitle);
      }
    } else {
      const rejectAction = async () =>
        isUserInvite
          ? await userRequestActions.rejectContactRequest(invite.object_id)
          : await inviteRequestActions.rejectInvite(
              invite.object_type,
              invite.object_id,
              invite.id,
            );
      try {
        await rejectAction();
        await getUserInvites();
      } catch (error) {
        dispatchToastError(error, errorTitle);
      }
    }
  }

  function handleRemovePost(
    post: Omit<IActivityPostFE, 'feed_events' | 'comments'>,
    dispatch: React.Dispatch<any>,
  ): void {
    dispatch({ type: 'HIDE_POST', post });
  }

  function handleRemoveGroupPosts(
    group: IEmbeddedGroupDetail,
    dispatch: React.Dispatch<any>,
  ): void {
    dispatch({ type: 'HIDE_GROUP_POSTS', group });
  }

  let _joyrideStyles = {
    options: {
      arrowColor: '#FFF',
      backgroundColor: '#FFF',
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: '#5E51AB',
      textColor: '#505050',
    },
  };

  return (
    <section className={`CommunityFeed ${props?.notUserCommunityFeed ? 'not-user-cf' : ''}`}>
      {params?.help && (
        <Joyride
          locale={{ back: 'Back', close: 'Close', last: 'Last', next: 'Next', skip: 'Skip' }}
          continuous={true}
          disableScrolling={true}
          styles={_joyrideStyles}
          steps={steps}
          callback={(state) => {
            if (state.status === 'finished') {
              history.replace({ search: '' });
            }
          }}
        />
      )}
      {Config.feature_flags.HUB_DONATION_MATCHING && !props.notUserCommunityFeed && (
        <div className="matching-programs-wrap">
          <SpotlightCarousel spotlightItems={spotlights} />
        </div>
      )}
      <div className="heading">
        <h2>
          {props?.feedTitle && props?.notUserCommunityFeed
            ? props.feedTitle
            : 'Discover Stories & Posts'}
        </h2>
      </div>
      {props?.notUserCommunityFeed && (
        <section className={`story-container ${props.objectType || ''}`}>
          <div className="story-inner">
            <StoryCarousel
              ownerId={props.objectId}
              ownerType={props.objectType}
              theme={props?.theme}
            />
          </div>
        </section>
      )}
      <div
        className="main-container"
        ref={mainContainerRef}
      >
        {props.objectType !== Constants.object_type.hub && (
          <div
            ref={leftAsideContainer}
            className="left-aside-container"
          >
            <aside ref={leftAsideRef}>
              {loadingProfile && <CommunityFeedLeftSkeleton />}
              {!loadingProfile && (
                <>
                  <div className="profile-section side-bar">
                    <div
                      className="cover-img"
                      style={{
                        backgroundImage: userState.user?.cover_image_url
                          ? 'url(' + userState.user?.cover_image_url + ')'
                          : undefined,
                        backgroundSize: 'cover',
                      }}
                    />
                    <Link to={`/user/${userState.user.handle}`}>
                      <div className="profile-avatar-container">
                        {userState.user?.profile_image_url && (
                          <img
                            src={userState.user?.profile_image_url}
                            alt="profile avatar"
                          />
                        )}

                        {!userState.user?.profile_image_url && (
                          <span className="no-profile-img">
                            <i className="fas fa-user" />
                          </span>
                        )}
                      </div>
                    </Link>
                    <Link
                      to={`/user/${userState.user.handle}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <span
                        notranslate="yes"
                        className="user-display-name"
                      >
                        {userState.user.display_name}
                      </span>
                    </Link>
                    <div className="metrics-container">
                      <MetricsList
                        setShowVolunteerModal={(v) => setShowVolunteerModal(v)}
                        objectType="communityFeed"
                      />
                    </div>
                  </div>

                  <div className="get-started-section side-bar">
                    <h4>Get Started</h4>
                    <Button
                      text="Make a Donation"
                      onClick={() => setShowMakeADonationModal(true)}
                    />
                    <Button
                      text="Create a Fundraiser"
                      onClick={() => {
                        history.push('/onboarding/event');
                      }}
                    />
                    <Button
                      text="Volunteer"
                      onClick={() => {
                        history.push('/discover/volunteers');
                      }}
                    />
                    <Button
                      text="Explore Companies"
                      onClick={() => {
                        history.push('/discover/companies');
                      }}
                    />
                  </div>
                </>
              )}
            </aside>
          </div>
        )}
        <div className="post-story-container">
          {!props?.notUserCommunityFeed && (
            <section className="story-container">
              <StoryCarousel
                ownerType={Constants.object_type.user}
                ownerId={props.objectId}
              />
            </section>
          )}
          {props.hubactivity && (
            <section className="story-container">
              {/* TODO: make consts for story types */}
              <StoryCarousel
                ownerType="hub"
                ownerId={props.hub?.id}
              />
            </section>
          )}
          <section className="posts-container">
            {userPostsData.userPosts.length > 0 &&
              userPostsData.userPosts.map((userPost, index) => {
                if (userPost?.post && userState?.user && userPost.post?.created_by) {
                  return (
                    <Fragment key={index}>
                      <CommunityFeedPost
                        owner={userPost.owner}
                        setActiveStory={(story) => {
                          setShowStoryModal(true);
                          setCurrentStory(story);
                        }}
                        loggedInUser={userState.user}
                        createdFeedUserData={userPost.created_by_user}
                        postUserData={{
                          ...userPost.post.created_by,
                          id: userPost.post.owner_id,
                        }}
                        key={userPost._id}
                        keyValue={`community-feed-post-${index}`}
                        initPost={userPost.post}
                        group={userPost.group}
                        event={userPost.event}
                        gig={userPost.gig}
                        story={userPost.story}
                        hub={userPost.hub}
                        team={userPost.team as IEventTeam}
                        causes={causes}
                        createdAt={userPost.created_at}
                        comment={userPost.comment}
                        feedEventType={userPost.feed_event.event_type}
                        postType={userPost.post.type}
                        removePostFromArray={(post) =>
                          handleRemovePost(post, userPostsDataDispatch)
                        }
                        removeGroupPosts={(group) =>
                          handleRemoveGroupPosts(group, userPostsDataDispatch)
                        }
                        theme={props.theme}
                      />
                      {index === 2 ? (
                        <CommunityFeedFocusAreas
                          toggleFocusArea={(id?: string) => addFocusArea(id || '')}
                          focusAreas={recommendedFocusAreas}
                        />
                      ) : index < 2 && index === 0 ? (
                        <CommunityFeedFocusAreas
                          toggleFocusArea={(id?: string) => addFocusArea(id || '')}
                          focusAreas={recommendedFocusAreas}
                        />
                      ) : null}
                    </Fragment>
                  );
                }
              })}

            {!userPostsData.userPosts.length && (
              <>
                <CommunityFeedFocusAreas
                  toggleFocusArea={(id?: string) => addFocusArea(id || '')}
                  focusAreas={recommendedFocusAreas}
                />
              </>
            )}

            {userPostsData.fetching && (
              <div className="loading-wrapper">
                <CommunityFeedPostSkeleton />
              </div>
            )}
            {!userPostsData.fetching && userPostsData.userPosts.length === 0 && (
              <div className="community-feed-empty">
                <img
                  src={FeedEmptyState}
                  alt="no posts"
                />
                <div className="empty-label">There aren't any posts yet!</div>
                <div className="empty-description">
                  Follow causes and focus areas you care about to start seeing posts
                </div>
                <Link to={`/discover/events`}>
                  <Button
                    buttonClass="explore-button"
                    type="button"
                    text="Discover Kambeo"
                  />
                </Link>
              </div>
            )}

            <div
              className="bottom-boundary"
              ref={bottomBoundaryRef}
            />
          </section>
        </div>
        <div
          ref={rightAsideContainer}
          className="right-aside-container"
        >
          <aside ref={rightAsideRef}>
            {/*<CommunityFeedLevel />*/}

            {/*<CommunityFeedAsideCard*/}
            {/*    title='People Also Follow'*/}
            {/*    initialCountToShow={3}*/}
            {/*    flexDirection='column'*/}
            {/*    contentData={[]}*/}
            {/*/>*/}

            {props?.notUserCommunityFeed && (
              <CommunityFeedAsideCard
                theme={props.theme}
                loading={loadingSupportedCharities}
                title={supportedCharities.length !== 0 ? 'Supported Causes' : ''}
                initialCountToShow={3}
                flexDirection="column"
                emptyStateData={
                  <div className="empty-state">
                    <i className="far fa-heart" />
                    <span className="title">You haven't selected any Causes.</span>
                    <span
                      className="action"
                      onClick={() =>
                        history.push(`/company/${props.hub?.handle}/admin?t=benefiting-causes`)
                      }
                    >
                      Edit Supported Causes
                    </span>
                  </div>
                }
                showViewMore={supportedCharities.length > 3}
                contentData={supportedCharities.map((supportedCharity, index) => {
                  return (
                    <div
                      className="feed-invites recommended"
                      key={index}
                    >
                      <div className="invite-img">
                        <Portrait
                          size={40}
                          currentImage={supportedCharity.profile_image_url}
                        />
                      </div>
                      <div
                        className="invite-title"
                        notranslate="yes"
                      >
                        <span
                          onClick={() => history.push(`/group/${supportedCharity.handle}`)}
                          title={supportedCharity.title}
                        >
                          {supportedCharity.title}
                        </span>
                      </div>
                    </div>
                  );
                })}
              />
            )}

            {props?.notUserCommunityFeed && (
              <CommunityFeedAsideCard
                loading={loadingHubFocusAreas}
                key="Supported-Focus-Areas"
                theme={props.theme}
                title={hubFocusAreas.length !== 0 ? 'Supported Focus Areas' : ''}
                emptyStateData={
                  <div className="empty-state">
                    <i className="far fa-ribbon" />
                    <span className="title">You haven't selected any Focus Areas.</span>
                    <span
                      className="action"
                      onClick={() =>
                        history.push(`/company/${props.hub?.handle}/admin?t=focus-areas`)
                      }
                    >
                      Edit Focus Areas
                    </span>
                  </div>
                }
                initialCountToShow={3}
                flexDirection="row"
                showViewMore={hubFocusAreas.length > 3}
                contentData={hubFocusAreas.map((supportedFocusArea, index) => (
                  <Link
                    to={`/search?type=groups&cause_ids=${supportedFocusArea.id}`}
                    key={index}
                  >
                    <span className="pill-causes">{supportedFocusArea.cause}</span>
                  </Link>
                ))}
              />
            )}

            {!props?.notUserCommunityFeed && invites.length > 0 && (
              <CommunityFeedAsideCard
                loading={loadingInvites}
                key="Invites"
                theme={props.theme}
                title="Invites"
                initialCountToShow={1}
                flexDirection="row"
                showViewMore={false}
                emptyStateData={
                  <div className="empty-state">
                    <span className="title">No Invites</span>
                    <span className="subtitle">Explore causes and Hubs</span>
                  </div>
                }
                contentData={invites.map((invite, index) => {
                  // show only one by one
                  if (index === 0) {
                    return (
                      <Fragment key={index}>
                        {invites.length && <span className="invite-counter">{invites.length}</span>}
                        <div className="feed-invites">
                          <div className="invite-img">
                            <Portrait
                              size={40}
                              currentImage={invite.object.profile_image_url}
                            />
                          </div>
                          <div
                            className="invite-title"
                            notranslate="yes"
                          >
                            <span title={invite.object.title}>
                              <Link
                                to={
                                  invite.object_type === 'hub'
                                    ? `company/${invite.object.handle}`
                                    : `${invite.object_type}/${invite.object.handle}`
                                }
                              >
                                {invite.object.title}
                              </Link>
                            </span>
                          </div>
                        </div>
                        <div className="invite-actions">
                          <Button
                            onClick={() => handleInvite(invite, true)}
                            buttonType="dark"
                          >
                            Accept
                          </Button>
                          <Button
                            onClick={() => handleInvite(invite, false)}
                            buttonType="outline-dark"
                          >
                            Decline
                          </Button>
                        </div>
                      </Fragment>
                    );
                  }
                })}
              />
            )}
            {!props?.notUserCommunityFeed && (
              <CommunityFeedAsideCard
                loading={loadingUserApplications}
                key="Volunteer-Applications"
                title="Applications"
                initialCountToShow={3}
                flexDirection="row"
                emptyStateData={
                  <div className="empty-state">
                    <span className="title">No applications</span>
                    <span className="subtitle">Explore volunteer opportunities</span>
                  </div>
                }
                showViewMore={false}
                goToData={
                  <div className="invite-actions go-to-btn-container">
                    <Button
                      buttonType="outline-dark"
                      className="alt log-button"
                      onClick={() => {
                        history.push('/dashboard?section=activity&tab=applications');
                      }}
                    >
                      View All
                    </Button>
                  </div>
                }
                contentData={userApplications.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="feed-invites applications">
                        <div className="invite-img">
                          <Portrait
                            size={40}
                            currentImage={
                              item.gig?.profile_image_url || item.group?.profile_image_url
                            }
                          />
                        </div>
                        <div
                          className="invite-title shift"
                          notranslate="yes"
                        >
                          <span title={item?.gig?.title}>
                            {item.gig && (
                              <div
                                onClick={() => {
                                  prepareApplicationDetails(item?.gig as IGig, 'gig');
                                }}
                              >
                                {item?.gig?.title}
                              </div>
                            )}
                            {!item.gig && (
                              <div
                                onClick={() => {
                                  prepareApplicationDetails(item.group as IGroup, 'group');
                                }}
                              >
                                {item.group?.title}
                              </div>
                            )}
                          </span>
                          <span className="shift-details">
                            Submitted {moment(item.created_at).format('MMM D YYYY')}
                          </span>
                          <span className={`tag ${item.status?.code}`}>
                            {item.status?.code === Constants.application_status.rejected
                              ? capitalizeString('not accepted')
                              : capitalizeString(item.status?.code)}
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              />
            )}
            {!props?.notUserCommunityFeed && (
              <CommunityFeedAsideCard
                loading={loadingVolunteerHours}
                key="Volunteer-Hours"
                title="Volunteer Hours"
                initialCountToShow={2}
                flexDirection="row"
                emptyStateData={
                  <div className="empty-state">
                    <span className="title">No opportunities available</span>
                    <span className="subtitle">Explore volunteer opportunities</span>
                  </div>
                }
                showViewMore={false}
                goToData={
                  <div className="invite-actions go-to-btn-container">
                    <Button
                      buttonType="dark"
                      className="alt log-button"
                      onClick={() => {
                        setShowReportHoursModal(true);
                      }}
                    >
                      Log Hours
                    </Button>
                  </div>
                }
                contentData={volunteerOpportunitiesHours.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="feed-invites">
                        <div className="invite-img">
                          <Portrait
                            size={40}
                            currentImage={
                              item.gig?.profile_image_url || item.group?.profile_image_url
                            }
                          />
                        </div>
                        <div
                          className="invite-title shift"
                          notranslate="yes"
                        >
                          <span title={item?.gig?.title}>
                            {item.custom_cause_name && <span>{item.custom_cause_name}</span>}
                            {item.gig != null && (
                              <Link to={`/gig/${item?.gig?.handle}`}>{item?.gig?.title} </Link>
                            )}
                            {item.group != null && (
                              <Link to={`/group/${item?.group?.handle}`}>{item.group?.title}</Link>
                            )}
                          </span>
                          <span className="shift-details">
                            <var data-var="logged_hours">{item?.hours || 0}</var>
                            {`h logged, ${item?.status?.code === 'pending' ? 'unverified' : 'verified'}`}
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              />
            )}

            {!props?.notUserCommunityFeed && (
              <CommunityFeedAsideCard
                loading={loadingShifts}
                key="Upcoming-Shifts"
                theme={props.theme}
                title="Upcoming Shifts"
                initialCountToShow={1}
                flexDirection="row"
                showViewMore={false}
                forceEmptyState={
                  !shifts.find((s) => s?.start_date && moment(s.start_date).isAfter(new Date()))
                }
                emptyStateData={
                  <div className="empty-state">
                    <span className="title">No shifts available</span>
                    <span className="subtitle">Explore volunteer opportunities</span>
                  </div>
                }
                goToData={
                  <div className="invite-actions go-to-btn-container">
                    <Button
                      className="alt log-button"
                      buttonType="outline-dark"
                      onClick={() => history.push('/dashboard?section=activity&tab=agenda')}
                    >
                      View Shifts
                    </Button>
                  </div>
                }
                contentData={shifts.map((shift, index) => {
                  return (
                    <Fragment key={index}>
                      {shift?.start_date && moment(shift.start_date).isAfter(new Date()) && (
                        <div className="feed-invites">
                          <div className="invite-img">
                            <Portrait
                              size={40}
                              currentImage={shift.gig?.profile_image_url}
                            />
                          </div>
                          <div
                            className="invite-title shift"
                            notranslate="yes"
                          >
                            <span title={shift.gig_title}>
                              <Link to={`/gig/${shift?.gig_handle}`}>{shift.gig_title}</Link>
                            </span>
                            <span className="shift-details">
                              {`Starts: `}
                              <var data-var="start_at">
                                {localizeHelpers.formatDate(
                                  shift.start_date,
                                  LocaleDateFormats.LLL,
                                  locale,
                                )}
                              </var>
                            </span>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              />
            )}

            {!props?.notUserCommunityFeed && (
              <CommunityFeedAsideCard
                loading={loadingUserHubs}
                key="My-Hubs"
                theme={props.theme}
                title="My Companies"
                initialCountToShow={3}
                flexDirection="row"
                showViewMore={false}
                goToData={
                  <div className="invite-actions go-to-btn-container">
                    <Button
                      className="alt log-button"
                      buttonType="outline-dark"
                      onClick={() => history.push('/dashboard?section=pages&tab=hub')}
                    >
                      View More
                    </Button>
                  </div>
                }
                emptyStateData={
                  <div className="empty-state">
                    <span className="title">No hubs</span>
                    <span className="subtitle">You haven't joined any hubs</span>
                  </div>
                }
                contentData={userHubs.map((hub, index) => {
                  return (
                    <div
                      className="feed-invites recommended"
                      key={index}
                    >
                      <div className="invite-img">
                        <Portrait
                          size={40}
                          currentImage={hub.profile_image_url}
                        />
                      </div>
                      <div
                        className="invite-title"
                        notranslate="yes"
                      >
                        <span
                          onClick={() => history.push(`/company/${hub.handle}`)}
                          title={hub.title}
                        >
                          {hub.title}
                        </span>
                      </div>
                    </div>
                  );
                })}
              />
            )}

            {!props?.notUserCommunityFeed && (
              <CommunityFeedAsideCard
                loading={loadingRecommendedGroups}
                key="People-also-follow"
                theme={props.theme}
                title="People also follow"
                initialCountToShow={3}
                flexDirection="row"
                showViewMore={false}
                goToData={
                  <div className="invite-actions go-to-btn-container">
                    <Button
                      className="alt log-button"
                      buttonType="outline-dark"
                      onClick={() => history.push('/discover/groups')}
                    >
                      View More
                    </Button>
                  </div>
                }
                emptyStateData={
                  <div className="empty-state">
                    <span className="title">No recommendations</span>
                    <span className="subtitle">Explore causes</span>
                  </div>
                }
                contentData={recommendedGroups.map((group, index) => {
                  return (
                    <div
                      className="feed-invites recommended"
                      key={index}
                    >
                      <div className="invite-img">
                        <Portrait
                          size={40}
                          currentImage={group.profile_image_url}
                        />
                      </div>
                      <div
                        className="invite-title"
                        notranslate="yes"
                      >
                        <span
                          onClick={() => history.push(`/group/${group.handle}`)}
                          title={group.title}
                        >
                          {group.title}
                        </span>
                      </div>
                    </div>
                  );
                })}
              />
            )}
            {/*
                        // Removed for https://app.clickup.com/t/8404472/GIG-6388
                            !props?.notUserCommunityFeed &&
                            <CommunityFeedAsideCard
                                loading={loadingRecommendedFocusAreas}
                                key='Recommended-Causes'
                                theme={props.theme}
                                title="Recommended Focus Areas"
                                initialCountToShow={4}
                                flexDirection="row"
                                showViewMore={true}
                                contentData={recommendedFocusAreas.map((userCause, index) => {
                                    if (userCause) {
                                        return (
                                            <React.Fragment key={index}>
                                                <span className="pill-causes" onClick={()=> addFocusArea(userCause.id? userCause.id : "")}>
                                                    {userCause.cause}
                                                </span>
                                            </React.Fragment>
                                        )
                                    }
                                })}
                            />
                        */}
          </aside>
        </div>
      </div>

      {props.objectType !== Constants.object_type.hub && (
        <>
          <CommunityFeedDonationModal
            showDonateModal={showMakeADonationModal}
            onClose={() => setShowMakeADonationModal(false)}
          />

          <VolunteerModal
            showModal={showVolunteerModal}
            onCloseModal={() => setShowVolunteerModal(false)}
            type={uiConstants.volunteer_modal_types.log_all}
            onLogHours={async () => await getUserGigs()}
          />

          <Modal
            class="LogVolunteerHoursModal"
            show={showReportHoursModal}
            onClose={() => setShowReportHoursModal(false)}
          >
            <LogVolunteerHours onHoursSubmitted={() => setShowReportHoursModal(false)} />
          </Modal>
        </>
      )}

      <VolunteerApplicationModal
        readOnly={true}
        closeModal={() => setShowApplicationModal(false)}
        owner={applicationOwner!}
        user={userState.user}
        show={showApplicationModal}
      />

      {/* TODO: duplicate code */}
      <Modal
        show={showStoryModal}
        onClose={() => setShowStoryModal(false)}
      >
        <div className="StoryModal">
          <div className="story-header">
            <Portrait
              currentImage={currentStory?.entityProfileImg}
              size={30}
              onClick={() => onPortraitClick(currentStory)}
            />
            <div className="entity-title">{currentStory?.entityName}</div>
          </div>
          <div className="story-content">
            <Stories
              width={'100%'}
              height={'100%'}
              stories={
                currentStory?.stories?.map((story) => {
                  return {
                    url: story.media_url || '',
                    duration: uiConstants.story_duration,
                    type: story?.background_type,
                    ...story,
                  };
                }) || []
              }
              onStoryChange={() => {}}
            />
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default CommunityFeed;
