import './HubCauses.scss';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { ICause, IGroup, IHub, IPage, IPageComponent } from '@gigit/interfaces';
import useToastDispatcher from '../../../hooks/useToaster';
import HubFilter from '../Shared/HubFilterBar/HubFilterBar';
import CausesFilter from '../Shared/CausesFilter/CausesFilter';
import Loader from '../../Loader/Loader';
import {
  hubRequestActions,
  causeRequestActions,
  groupRequestActions,
} from '../../../requestActions';
import { useCallback } from 'react';
import { formatQuery, IQueryParams, generateEmptyCards } from '../../../helpers';
import { ISortSettings } from '../../../interfaces';
import { uiConstants } from '../../../constants';
import { IPopupMenuItem, IShowPopupConfig } from '../../shared/PopupMenu/PopupMenu';
import HubSharedDetailsModal from '../HubModals/HubSharedDetailsModal/HubSharedDetailsModal';
import {
  CardCommonGroup,
  EStyleTypeCardCommonGroup,
  EViewTypeCardCommonGroup,
} from '../../CardCommonGroup/CardCommonGroup';
import { Constants } from '@gigit/constants';
import { Link } from 'react-router-dom';
import CausesLogoDefault from '../../../assets/people-logo.svg';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { userSelectors } from '../../../selectors/user';

const sortByNew = { label: 'Newest', value: 'desc' } as { label: string; value: 'asc' | 'desc' };
const sortByOld = { label: 'Oldest', value: 'asc' } as { label: string; value: 'asc' | 'desc' };

interface IHubGroupsProps {
  page: IPage | null;
  /** Has meta_data property called render_detailed_version */
  pageComponent: IPageComponent;
  userHasEditPermissions: boolean;
  hub: IHub | null;
}

/** Also known as Causes, Partners or Groups for Hubs ? */
function HubCauses(props: IHubGroupsProps) {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [partners, setPartners] = useState<IGroup[]>([]);
  const [causes, setCauses] = useState<string[]>([]);
  const [selectedCauses, setSelectedCauses] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { dispatchToastError } = useToastDispatcher();
  const [causesMaster, setCausesMaster] = useState<ICause[]>();
  const [searchDebounce, setSearchDebounce] = useState<ReturnType<typeof setTimeout>>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [showContextMenuSortBy, setShowContextMenuSortBy] = useState<boolean>(false);
  const [showCauseModal, setShowCauseModal] = useState<boolean>(false);
  const [selectedCause, setSelectedCause] = useState<IGroup | null>(null);
  const [sortBy, setSortBy] = useState<{ label: string; value: 'asc' | 'desc' }>(sortByNew);
  const [sortValue, setSortValue] = useState<ISortSettings[] | null>([
    { id: 'created_at', order: sortByNew.value },
  ]);
  const ownerId = props.page?.owner_id;
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );

  let observer: React.MutableRefObject<IntersectionObserver | undefined> =
    useRef<IntersectionObserver>();
  const lastPartnerItem = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0]?.isIntersecting &&
          hasMore &&
          partners.length >= parseInt(uiConstants.hubs.listViewPageSize)
        ) {
          setPage((prevPageNumber) => prevPageNumber + 1);
          const params = getQueryParamsAsURL();
          if (ownerId) getPartners(ownerId, params);
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, ownerId],
  );

  useEffect(() => {
    getCauses();
  }, []);

  useEffect(() => {
    if (ownerId) {
      setPage(0);
      getPartners(ownerId, getQueryParamsAsURL());
      getHubPartnersCauses(ownerId);
    }
  }, [ownerId, selectedCauses, sortValue, searchValue]);

  useEffect(() => {
    handleSortBy();
  }, [sortBy]);

  function handleSortBy() {
    setSortValue([{ id: 'created_at', order: sortBy.value }]);
  }

  async function getPartners(id: string, params?: URLSearchParams) {
    setIsLoading(true);
    try {
      if (params) {
        const partners = await hubRequestActions.getHubPartners(id, params);
        setHasMore(partners.length > 0);
        if (page === 0) {
          setPartners(partners);
        } else {
          setPartners((previousPartners) => [...previousPartners, ...partners]);
        }
      } else {
        const partners = await hubRequestActions.getHubPartners(id);
        setHasMore(partners.length > 0);
        if (page === 0) {
          setPartners(partners);
        } else {
          setPartners((previousPartners) => [...previousPartners, ...partners]);
        }
      }
    } catch (error) {
      dispatchToastError(error, 'Hub Partners');
    } finally {
      setIsLoading(false);
    }
  }

  async function getHubPartnersCauses(id: string) {
    try {
      const partnersCauses = await hubRequestActions.getHubPartnersCauses(id);

      const causes = partnersCauses.map(({ cause }) => cause);
      setCauses(causes);
    } catch (error) {
      dispatchToastError(error, 'Hub Partners Causes');
    }
  }

  function handleCauseSelection(updatedSelections: string[]) {
    setPage(0);
    setSelectedCauses(updatedSelections);
  }

  function getQueryParams(): IQueryParams {
    let queryParams: IQueryParams = {
      sort: sortValue || [],
      limit: uiConstants.hubs.listViewPageSize,
      skip: (page * parseInt(uiConstants.hubs.listViewPageSize)).toString(),
    };
    const filters = selectedCauses.map((cause) => getCauseId(cause) || '');

    if (searchValue) {
      queryParams.search = searchValue;
    }

    if (filters.length > 0) {
      queryParams.common_filters = { causes: filters };
    }

    return queryParams;
  }

  function getQueryParamsAsURL(): URLSearchParams {
    const queryParams = getQueryParams();
    return formatQuery(queryParams);
  }

  async function getCauses() {
    try {
      const causeList = await causeRequestActions.getCauses();
      setCausesMaster(causeList);
    } catch (error) {
      setCausesMaster([]);
      dispatchToastError(error, 'Causes');
    }
  }

  function getCauseId(label: string) {
    return causesMaster?.find((cause: ICause) => cause?.cause === label)?.id;
  }

  // Config
  const popupMenuConfigSortBy: IShowPopupConfig = {
    showMenu: showContextMenuSortBy,
    setShowMenu: setShowContextMenuSortBy,
    position: {
      type: 'bottom',
    },
  };
  const contextMenuItemsSortBy: IPopupMenuItem[] = [
    {
      id: uiConstants.sortOptions.createdAtAscending,
      label: sortByNew.label,
      isSelected: sortBy.value === sortByNew.value,
      onClick: () => setSortBy(sortByNew),
    },
    {
      id: uiConstants.sortOptions.createdAtDescending,
      label: sortByOld.label,
      isSelected: sortBy.value === sortByOld.value,
      onClick: () => setSortBy(sortByOld),
    },
  ];

  async function handleSelectedCard(id: string) {
    try {
      const selectedCause = await groupRequestActions.getGroupByHandleOrId(id);
      setSelectedCause(selectedCause);
      setShowCauseModal(true);
    } catch (error) {
      dispatchToastError(error, 'Get Cause');
    }
  }

  return (
    <div className="HubCauses">
      <HubFilter
        header="Supported Causes"
        searchValue={searchValue}
        onSearch={setSearchValue}
        sortByConfig={[
          {
            menuItems: contextMenuItemsSortBy,
            showMenuConfig: popupMenuConfigSortBy,
            className: 'sortBy',
            label: 'Sort By',
            valueLabelIcon: 'far fa-clock',
            valueLabel: sortBy.label,
            popupMenuClass: 'HubPartners-popupMenu-sortBy',
            showMenu: showContextMenuSortBy,
            onClick: () => {
              setShowContextMenuSortBy(true);
            },
            onSelect: () => {
              setShowContextMenuSortBy(false);
            },
          },
        ]}
        searchPlaceholder="Search By Organization"
      />
      <CausesFilter
        causes={causes}
        selectedCauses={selectedCauses}
        causesMaster={causesMaster || []}
        onSelectCause={handleCauseSelection}
      />
      <hr />
      {!isLoading && partners.length > 0 && (
        <div className="grid-container">
          {partners.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {
                  <CardCommonGroup
                    group={item}
                    styleType={EStyleTypeCardCommonGroup.HUB}
                    viewType={EViewTypeCardCommonGroup.GRID}
                    openDetailModal={async (group_id: string) => {
                      await handleSelectedCard(group_id);
                    }}
                  />
                }
              </React.Fragment>
            );
          })}

          {partners.length % 3 !== 0 &&
            generateEmptyCards(3 - (partners.length % 3), Constants.object_type.group)}
        </div>
      )}

      {!isLoading && partners.length < 1 && (
        <div className="empty-state">
          <div className="img-container">
            <img
              src={CausesLogoDefault}
              alt="causes empty state"
            />
          </div>
          <p>We didn't find any results</p>
          {isUserLoggedIn && props.userHasEditPermissions && (
            <Link to={`/company/${props.hub?.handle}/admin?t=benefiting-causes`}>
              Try adding more causes
            </Link>
          )}
        </div>
      )}

      {isLoading && <Loader loading={isLoading} />}
      <HubSharedDetailsModal
        hub={props.hub}
        type={uiConstants.ownerType.group}
        show={showCauseModal}
        selectedItem={selectedCause}
        onClose={() => setShowCauseModal(false)}
      />
    </div>
  );
}

export default HubCauses;
