import React, { useState } from 'react';
import SearchControlActions from '../SearchControlActions/SearchControlActions';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { uiConstants } from '../../../constants';
import TextField from '../../../components/TextField/TextField';
import SearchEntitySelect from '../SearchEntitySelect/searchEntitySelect';

import './SearchControls.scss';
import { getSearchLabelFromCategory, searchCategoryOptions } from '../Config';

interface IProps {
  isMapView: boolean;
  category: string;
  keyword: string;
  filterCount: number;

  toggleAdvancedFilter: () => void;
  toggleMap: () => void;
  setSearchCategory: (value: string) => void;
  setKeywordValue: (value: string) => void;
}

const SearchControls = (props: IProps) => {
  const isMobileDimensions = useMediaQuery(`(max-width: ${uiConstants.search.mobileBreakPoint}px)`);
  const categoryLabel = getSearchLabelFromCategory(props.category);

  const renderKeywordSearch = () => (
    // Todo: Needs clear
    <div className="search-controls-input keyword-search">
      <TextField
        value={props.keyword}
        placeholder={`Search ${categoryLabel}...`}
        type="text"
        onChange={(e) => {
          props.setKeywordValue(e.target.value);
        }}
        name="KeywordValue"
        label=""
        icon={props.keyword ? 'fa fa-times' : 'fa fa-search'}
        onIconClick={() => props.setKeywordValue('')}
      />
    </div>
  );

  return (
    <>
      <div className={`SearchControls`}>
        <div className="search-controls-row">
          <div className={`search-inputs-container`}>
            {renderKeywordSearch()}
            {!isMobileDimensions && (
              <SearchEntitySelect
                searchCategory={props.category as string}
                setSearchCategory={props.setSearchCategory}
              />
            )}
          </div>
          <SearchControlActions
            filterCount={props.filterCount}
            isMapView={props.isMapView}
            onToggleFilterPanel={() => props.toggleAdvancedFilter()}
            onToggleMap={() => props.toggleMap()}
          />
        </div>
      </div>
    </>
  );
};

export default SearchControls;
