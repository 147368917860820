import { ILoggedVolunteerHoursSummary } from '@gigit/interfaces';
import React, { useState } from 'react';
import { uiConstants } from '../../constants';
import { capitalizeString } from '../../helpers';
import { useLocale } from '../../hooks';
import useToastDispatcher from '../../hooks/useToaster';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userRequestActions } from '../../requestActions';
import Table, { ITableProps } from '../shared/Table/Table';
import LogVolunteerHours from './LogVolunteerHours';
import './HistoryTable.scss';
import Modal from '../Modal/Modal';

export default function HistoryTable() {
  const locale = useLocale();
  const { dispatchToastError } = useToastDispatcher();

  const [selectedHours, setSelectedHours] = useState<ILoggedVolunteerHoursSummary | null>(null);
  let [tableConfig, setTableConfig] = useState<ITableProps<ILoggedVolunteerHoursSummary>>({
    columns: [
      {
        id: 'end_date',
        Header: 'Date',
        accessor: ({ end_date }) => {
          if (end_date) {
            return localizeHelpers.formatDate(end_date, LocaleDateFormats.ll, locale.currentLocale);
          } else return '';
        },
        Cell: ({ end_date }) => (
          <var data-var="end_date">
            {end_date
              ? localizeHelpers.formatDate(end_date, LocaleDateFormats.ll, locale.currentLocale)
              : ''}
          </var>
        ),
      },
      {
        id: 'gig.title',
        Header: 'Opportunity Name',
        accessor: ({ gig }) => gig?.title,
        sortable: true,
        Cell: ({ gig }) => <var data-var="opportunity_name">{gig?.title}</var>,
      },
      {
        id: 'group.title',
        Header: 'Organization',
        accessor: ({ group, hub }) => hub?.title || group?.title || '',
        Cell: ({ group, hub }) => (
          <var data-var="organization">{hub?.title || group?.title || ''}</var>
        ),
        sortable: false,
      },
      {
        id: 'custom_cause_name',
        Header: 'Custom Cause',
        accessor: ({ custom_cause_name }) => custom_cause_name,
        Cell: ({ custom_cause_name }) => (
          <var data-var="custom_cause_name">{custom_cause_name}</var>
        ),
        sortable: false,
      },
      {
        id: 'hours',
        Header: 'Hours Logged',
        accessor: ({ hours }) => hours,
        Cell: ({ hours }) => <var data-var="hours">{hours}h</var>,
        sortable: true,
      },
      {
        id: 'status.code',
        Header: 'Status',
        accessor: ({ status }) => capitalizeString(getStatus(status?.code || '')),
        Cell: ({ status }) => (
          <span className={`status ${getStatus(status?.code || '')}`}>
            {capitalizeString(getStatus(status?.code || ''))}
          </span>
        ),
        sortable: true,
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Edit Item',
          icon: 'fa fa-pencil',
          onClick: (_, item) => {
            if (getStatus(item.status?.code || '') === 'approved') {
              dispatchToastError(
                localizeHelpers.translate('You cannot edit a verified volunteer hour'),
                'Error editing volunteer hours',
              );
            }
            setSelectedHours(item);
          },
        },
      ],
    },
    pagination: {
      pageSizeOptions: [uiConstants.volunteer_hours_history_items_per_page],
      queryAction: getVolunteerHoursHistory,
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any volunteer hours",
    },
  });

  async function getVolunteerHoursHistory(query: URLSearchParams) {
    let result = [] as ILoggedVolunteerHoursSummary[];

    try {
      const sort = query.get('sort');
      const defaultSort = '-end_date';
      query.set('sort', sort ? `${query.get('sort')},${defaultSort}` : defaultSort);
      result = await userRequestActions.getUserVolunteeringHours(query);
    } catch (error) {
      dispatchToastError(error, 'Error getting volunteer hours');
    }

    return result;
  }

  function getStatus(statusCode: string): string {
    return statusCode === 'approved' ? 'Verified' : 'Unverified';
  }

  function onHoursSubmitted() {
    setSelectedHours(null);
  }

  return (
    <div className="history HistoryTable">
      {!selectedHours ? (
        <Table {...tableConfig} />
      ) : (
        <Modal
          class="LogVolunteerHoursModal"
          show={selectedHours ? true : false}
          onClose={onHoursSubmitted}
        >
          <LogVolunteerHours
            isEditing={true}
            hoursToEdit={selectedHours}
            onHoursSubmitted={onHoursSubmitted}
          />
        </Modal>
      )}
    </div>
  );
}
