import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IPage } from '@gigit/interfaces';
import {
  combineClassNames,
  defaultCurrency,
  errorHelpers,
  handleInputChange,
  toastError,
} from '../../../helpers';
import { IProfileState } from '../../../reducers/profile';
import { getProfileStats } from '../../../actions/profile';
import { IAppState } from '../../../store';
import TextField from '../../TextField/TextField';
import { localizeHelpers } from '../../../localizeHelpers';

import './UserStats.scss';
import { IUserStatsComponent, ReduxActionType } from '../../../interfaces';
import { updateUserPageComponent } from '../../../actions/user';
import { createToast } from '../../../actions/toaster';
import { userSelectors } from '../../../selectors/user';
import {
  IGetMetricParamsBulkFE,
  IGetMetricValuesBulkFE,
  metricRequestActions,
} from '../../../requestActions/metrics';
import { Constants } from '@gigit/constants';

interface IProps extends WithTranslation {
  page: IPage;
  userId: string;
  edit: boolean;
  component: IUserStatsComponent;
  profileState: IProfileState;
  locale: string;
  getProfileStats(userId: string): void;
  updateUserPageComponent: ReduxActionType<typeof updateUserPageComponent>;
  createToast: ReduxActionType<typeof createToast>;
  hasEditPermissions?: boolean;
}

interface IState {
  title: string;
  statsList: IStat[];
  metricsInfo: IGetMetricValuesBulkFE | null;
}

interface IStat {
  id: string;
  label: string;
  icon: string;
}

export const stats: IStat[] = [
  {
    id: 'volunteer_hours',
    label: 'Volunteer Hours',
    icon: 'fal fa-hand-heart',
  },
  {
    id: 'volunteer_gigs',
    label: 'Volunteer Opportunities',
    icon: 'fal fa-users',
  },
  {
    id: 'donations_made',
    label: 'Donations Made',
    icon: 'fal fa-box-usd',
  },
  {
    id: 'causes_supported',
    label: 'Causes Supported',
    icon: 'far fa-heart',
  },
  {
    id: 'events_supported',
    label: 'Events Supported',
    icon: 'far fa-calendar',
  },
];

const defaultTitle = 'Stats';

/** Page Component that renders user stats */
export class UserStats extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      title: this.props.component.title || defaultTitle,
      statsList: stats,
      metricsInfo: null,
    };

    this.setMetrics = this.setMetrics.bind(this);
  }

  componentDidMount() {
    /*
        this.props.getProfileStats(this.props.userId);
        */

    let metrics = metricRequestActions
      .getAvailableMetricsForObjectType(Constants.object_type.user)
      .then(async (resp) => {
        let metrics = resp;
        metrics = resp.filter((metric) => metric.object_types.includes(Constants.object_type.user));
        await this.setMetrics({
          metrics: metrics.map((m) => {
            return {
              object_type: Constants.object_type.user,
              metric: m.metric,
              object_id: this.props.profileState.user.id || '',
              currency:
                m.unit_type === 'currency'
                  ? this.props.profileState?.user.account?.currency || defaultCurrency.toLowerCase()
                  : undefined,
            };
          }),
        });
      });
  }

  async setMetrics(metricsList: IGetMetricParamsBulkFE) {
    try {
      const metricInfo = await metricRequestActions.getMetricsBulk(metricsList);
      this.setState({ metricsInfo: metricInfo });
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'User Stats');
      this.props.createToast(toast);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.edit && !this.props.edit) {
      this.saveTitle();
    }
  }

  getStatValue(key: string) {
    let statValue;
    switch (key) {
      case 'volunteer_hours':
        statValue = this.state.metricsInfo?.metrics.find(
          (metric) => metric.metric == 'volunteer_hours_total',
        );
        if (statValue) {
          return localizeHelpers.formatNumber(statValue.value, this.props.locale);
        }
        return '-';
      case 'volunteer_gigs':
        if (this.props.profileState.isProfileStatsLoading) {
          return '-';
        }
        return localizeHelpers.formatNumber(
          this.props.profileState.profileStats[key],
          this.props.locale,
        );
      case 'donations_made':
        statValue = this.state.metricsInfo?.metrics.find(
          (metric) => metric.metric == 'num_donations_made',
        );
        if (statValue) {
          return localizeHelpers.formatNumber(statValue.value, this.props.locale);
        }
        return '-';
      case 'causes_supported':
        statValue = this.state.metricsInfo?.metrics.find(
          (metric) => metric.metric == 'num_groups_supported',
        );
        if (statValue) {
          return localizeHelpers.formatNumber(statValue.value, this.props.locale);
        }
        return '-';
      case 'events_supported':
        statValue = this.state.metricsInfo?.metrics.find(
          (metric) => metric.metric == 'num_events_supported',
        );
        if (statValue) {
          return localizeHelpers.formatNumber(statValue.value, this.props.locale);
        }
        return '-';
    }
  }

  isVisible(id: string) {
    if (this.props.component.meta_data === undefined) {
      return true;
    }
    const value = this.props.component.meta_data[id];
    return value !== undefined ? value : true;
  }

  saveTitle() {
    try {
      const payload = this.props.component;
      payload.title = this.state.title || defaultTitle;

      this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, payload);
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Save Title');
      this.props.createToast(toast);
    }
  }

  render() {
    return (
      <div className="UserStats">
        <div className="title">
          {this.props.edit && (
            <TextField
              value={this.state.title}
              name="title"
              type="text"
              onChange={(e: any) => {
                handleInputChange(e, this);
              }}
            />
          )}
          {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}
        </div>
        <div className="stat-container">
          {stats
            .filter((stat) => this.isVisible(stat.id))
            .map((stat, index) => this.renderStatBox(stat, index))}
        </div>
      </div>
    );
  }

  renderStatBox(stat: IStat, index: number) {
    return (
      <div
        className="stat-box"
        key={stat.id}
      >
        <div className="icon-container">
          <i className={combineClassNames('icon', stat.icon)} />
        </div>
        <p
          className="stat-value"
          notranslate="yes"
        >
          {this.getStatValue(stat.id)}
        </p>
        <p className="stat-label">{stat.label}</p>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    profileState: store.profileState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getProfileStats,
  updateUserPageComponent,
  createToast,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(UserStats),
);
