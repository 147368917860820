import React, { useEffect, useState } from 'react';
import {
  IDonationMatchingProgram,
  IGroup,
  IHub,
  ITransaction,
  ITransactionSummary,
} from '@gigit/interfaces';
import Modal from '../../../Modal/Modal';
import './HubManagementDonations.scss';
import { downloadFile, fileHelpers, formatCurrency, typeHelpers } from '../../../../helpers';
import Table, { ITableProps } from '../../../shared/Table/Table';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { Constants } from '@gigit/constants';
import HubModalDonate from '../../HubModals/HubModalDonate/HubModalDonate';
import AddDonation from '../../../AddDonation/AddDonation';
import { donationRequestActions, hubRequestActions } from '../../../../requestActions';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { Prompt } from '../../../Prompt/Prompt';
import useToastDispatcher from '../../../../hooks/useToaster';
import { DonationListExpandedCell } from '../../../DonationList/DonationListExpandedCell/DonationListExpandedCell';
import Axios from 'axios';

interface IProps {
  hub?: IHub;
}

function HubManagementDonations(props: IProps) {
  const [selectedCause, setSelectedCause] = useState<IGroup>();
  const [isOfflineDonation, setIsOfflineDonation] = useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [showMakeDonationModal, setShowMakeDonationModal] = useState<boolean>(false);
  const [showSelectCauseModal, setShowSelectCauseModal] = useState<boolean>(false);
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const [hubDonations, setHubDonations] = useState<ITransaction[]>();
  const [hubDonationMatchingPrograms, setHubDonationMatchingPrograms] =
    useState<IDonationMatchingProgram[]>();
  const [donationMatchToApprove, setDonationMatchToApprove] = useState<ITransaction | null>(null);
  const [donationMatchToDecline, setDonationMatchToDecline] = useState<ITransaction | null>(null);
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  function refreshAllPageState() {
    refreshTableData();
  }

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  async function getDonationMatchingPrograms() {
    try {
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      const response = await hubRequestActions.getHubDonationMatchingPrograms(props.hub.id);
      setHubDonationMatchingPrograms(response);
    } catch (error) {
      dispatchToastError(error, 'Get Donation Matching Programs');
    }
  }

  async function handleExportDonations() {
    try {
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      const result = await hubRequestActions.exportDonations(props.hub.id);
      downloadFile('Company_Donations_Export.csv', result);
    } catch (error) {
      dispatchToastError(error, 'Export Donations');
    }
  }

  function isDonationMatchingProgramActive(program?: IDonationMatchingProgram) {
    return (
      program &&
      program.status.code !== Constants.donation_matching_program_status.closed &&
      program.status.code !== Constants.donation_matching_program_status.scheduled
    );
  }

  useEffect(() => {
    getDonationMatchingPrograms();
  }, []);

  const tableProps: ITableProps<ITransactionSummary> = {
    columns: [
      {
        id: 'first_name',
        Header: 'First Name',
        accessor: ({ user }) => user.first_name,
        sortable: true,
        notranslate: 'yes',
      },
      {
        id: 'last_name',
        Header: 'Last Name',
        accessor: ({ user }) => user.last_name,
        sortable: true,
        notranslate: 'yes',
      },
      {
        id: 'amount',
        Header: 'Donation Amount',
        accessor: ({ currency_dollar_amount }) => currency_dollar_amount,
        sortable: true,
        Cell: ({ amounts, currency_dollar_amount }) => {
          return (
            <div
              className="col donation-amount"
              notranslate="yes"
            >
              <span className="amount">
                {formatCurrency(currency_dollar_amount, amounts.currency, locale)}
              </span>
            </div>
          );
        },
      },
      {
        id: 'created_at',
        Header: 'Donation Date',
        accessor: ({ created_at }) => created_at?.toString(),
        sortable: true,
        Cell: ({ created_at }) => {
          return (
            <div
              className="col donation-date"
              notranslate="yes"
            >
              <span className="date">
                {localizeHelpers.formatDate(created_at ?? new Date(), LocaleDateFormats.LL, locale)}
              </span>
            </div>
          );
        },
      },
      {
        id: 'donation_match.match_status.code',
        Header: 'Match Status',
        accessor: ({ donation_match }) =>
          donation_match?.match_status?.code ? donation_match.match_status.code : 'not_applicable',
        predefinedColumnType: {
          type: 'STATUS',
          columnObject: () => ({
            [Constants.donation_matching_transaction_status.pending]: {
              label: 'Pending',
              color: 'YELLOW',
            },
            [Constants.donation_matching_transaction_status.declined]: {
              label: 'Declined',
              color: 'RED',
            },
            [Constants.donation_matching_transaction_status.matched]: {
              label: 'Matched',
              color: 'GREEN',
            },
            not_applicable: {
              label: 'Not Applicable',
              color: 'GREY',
            },
          }),
        },
      },
      {
        id: 'title',
        Header: 'Recipient Cause',
        accessor: ({ group_title }) => group_title,
        sortable: true,
        notranslate: 'yes',
      },
    ],
    filterOptions: {
      enableTableSearch: true,
      enableColumnSorting: true,
      enableFilterDrawer: true,
      filterDrawerOptions: [
        {
          type: 'dateRangeField',
          fieldId: 'created_at',
          options: {
            fromFieldOptions: { label: 'From' },
            toFieldOptions: { label: 'To' },
          },
        },
        {
          fieldId: 'user.first_name',
          options: { label: 'First Name' },
          type: 'textField',
        },
        {
          fieldId: 'user.last_name',
          options: { label: 'Last Name' },
          type: 'textField',
        },
        {
          fieldId: 'group_title',
          options: { label: 'Cause Title' },
          type: 'textField',
        },
        {
          fieldId: 'group_handle',
          options: { label: 'Cause Handle' },
          type: 'textField',
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        typeHelpers.assertNotNullOrUndefined(props.hub?.id);
        const response = await hubRequestActions.getHubDonations(props.hub?.id, queryParams);
        const donations = response.filter(
          (transactions) => transactions.transaction_type === 'donation',
        );
        setHubDonations(donations);
        return donations;
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any Donations",
    },
    tableActionOptions: {
      enableRowContextMenuActions: true,
      enableRowButtonActions: true,
      rowDetailDrawerOptions: (donation) => ({
        title: '',
        renderContent: () => <DonationListExpandedCell donation={donation} />,
      }),
      tableActions: [
        {
          type: 'HEADER',
          label: 'Make Donation',
          buttonType: 'dark',
          onClick: (e) => {
            handleMakeDonationClick(false);
          },
        },
        {
          type: 'HEADER',
          label: 'Offline Donation',
          buttonType: 'dark',
          onClick: (e) => {
            handleMakeDonationClick(true);
          },
        },
        {
          type: 'HEADER',
          label: 'Export',
          buttonType: 'outline-dark',
          onClick: handleExportDonations,
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'View Attachments',
          hideIf: (row) => !row.attachments || row.attachments.length == 0,
          onClick: (e, item) => {
            handleViewAttachments(item);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Download Attachments',
          hideIf: (row) => !row.attachments || row.attachments.length == 0,
          onClick: (e, item) => {
            handleAttachmentDownload(item);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Approve Match',
          hideIf: (row) =>
            (hubDonationMatchingPrograms &&
              !isDonationMatchingProgramActive(
                hubDonationMatchingPrograms.find(
                  (program) => program.id === row.donation_match?.matching_program_id,
                ),
              )) ||
            row.donation_match?.match_status.code !==
              Constants.donation_matching_transaction_status.pending,
          onClick: (_, item) => {
            setDonationMatchToApprove(item);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Decline Match',
          hideIf: (row) =>
            (hubDonationMatchingPrograms &&
              !isDonationMatchingProgramActive(
                hubDonationMatchingPrograms.find(
                  (program) => program.id === row.donation_match?.matching_program_id,
                ),
              )) ||
            row.donation_match?.match_status.code !==
              Constants.donation_matching_transaction_status.pending,
          onClick: (_, item) => {
            setDonationMatchToDecline(item);
          },
        },
      ],
    },
  };

  async function handleViewAttachments(data: ITransactionSummary) {
    if (data.attachments && data.attachments.length) {
      for (let attachment of data.attachments) {
        try {
          let url = await donationRequestActions.getOfflineDonationAttachment(
            data.id as string,
            attachment.id as string,
          );
          downloadFileFromUrl(url.name, url.presigned_get_url, url.type);
        } catch (error) {
          dispatchToastError(error, 'Offline Donation Attachment');
        }
      }
    }
  }

  async function handleAttachmentDownload(data: ITransactionSummary) {
    if (data.attachments && data.attachments.length) {
      for (let attachment of data.attachments) {
        try {
          let url = await donationRequestActions.getOfflineDonationAttachment(
            data.id as string,
            attachment.id as string,
          );
          downloadFileFromUrl(url.name, url.presigned_get_url, url.type);
        } catch (error) {
          dispatchToastError(error, 'Offline Donation Attachment');
        }
      }
    }
  }

  async function downloadFileFromUrl(fileName: string, url: string, mime: string = 'text/csv') {
    const response = await fetch(url);

    let blob = await response.blob();

    let file = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = file;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async function approveDonationMatch() {
    try {
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      typeHelpers.assertNotNullOrUndefined(
        donationMatchToApprove?.id,
        'Expected Donation Matching ID',
      );
      typeHelpers.assertNotNullOrUndefined(
        hubDonationMatchingPrograms,
        'Expected Donation Mathicng Programs',
      );
      const params = {
        hub_id: props.hub.id,
        program_id:
          hubDonationMatchingPrograms?.find(
            (program) => program.id === donationMatchToApprove.donation_match?.matching_program_id,
          )?.id ?? hubDonationMatchingPrograms[0].id,
        transaction_id: donationMatchToApprove.id,
      };
      await hubRequestActions.approveDonationMatch(params);
      dispatchToastSuccess('Successfully approved donation match', 'Approve Donation Match');
    } catch (error) {
      dispatchToastError(error, 'Approve Donation Match');
    }
  }

  async function declineDonationMatch() {
    try {
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      typeHelpers.assertNotNullOrUndefined(
        donationMatchToDecline?.id,
        'Expected Donation Matching ID',
      );
      typeHelpers.assertNotNullOrUndefined(
        hubDonationMatchingPrograms,
        'Expected Donation Mathicng Programs',
      );
      let params = {
        hub_id: props.hub.id,
        program_id:
          hubDonationMatchingPrograms?.find(
            (program) => program.id === donationMatchToDecline.donation_match?.matching_program_id,
          )?.id ?? hubDonationMatchingPrograms[0].id,
        transaction_id: donationMatchToDecline.id ?? '',
      };
      await hubRequestActions.declineDonationMatch(params);
      dispatchToastSuccess('Successfully declined donation match', 'Decline Donation Match');
    } catch (error) {
      dispatchToastError(error, 'Decline Donation Match');
    }
  }

  function handleMakeDonationClick(isOffline: boolean) {
    setIsOfflineDonation(isOffline);
    setShowSelectCauseModal(true);
  }

  function handleSelectCause(cause: IGroup) {
    setSelectedCause(cause);
    setShowSelectCauseModal(false);
    setShowMakeDonationModal(true);
  }

  function handleDonationModalClose() {
    setSelectedCause(undefined);
    setShowMakeDonationModal(false);
    setRefreshTableIncrementor(refreshTableIncrementor + 1);
  }

  function handleCloseSelectCauseModal() {
    setShowSelectCauseModal(false);
  }

  return (
    <>
      <div
        className="HubManagementDonations"
        id="donation-transactions"
      >
        <div className="header-section">
          <h2>Donations</h2>
        </div>
        <div className="table-section">
          <Table
            {...tableProps}
            refreshTableIncrementor={refreshTableIncrementor}
          />
        </div>
      </div>
      <Modal
        show={showMakeDonationModal && selectedCause != null}
        onClose={handleDonationModalClose}
        closeIcon="fas fa-times"
        contentClassName="HubManagementDonations-offline-donation"
        title={`Make ${isOfflineDonation ? 'an Offline ' : ''}Donation`}
      >
        <AddDonation
          uiStateType={'offline-donation-from-hub'}
          onClose={handleDonationModalClose}
          owner={typeHelpers.createOwnerObject('hub', props.hub!)}
          donationTo={
            selectedCause ? typeHelpers.createOwnerObject('group', selectedCause) : undefined
          }
        />
      </Modal>

      <HubModalDonate
        showDonateModal={showSelectCauseModal}
        onSelectCause={handleSelectCause}
        onClose={handleCloseSelectCauseModal}
        hub={props.hub!}
      />
      <Modal
        show={showExportModal}
        onClose={() => {
          setShowExportModal(false);
        }}
        closeIcon="fas fa-times"
        contentClassName="HubManagementDonations-export"
        title={'Export'}
      >
        <div>TODO: Export</div>
      </Modal>

      <Prompt
        show={donationMatchToApprove !== null}
        title="Approve Donation Match"
        message={`Are you sure you want to approve donation matching for ${donationMatchToApprove?.group_title}?`}
        yesMessage="Approve"
        yesClass="fas fa-check"
        cancelMessage="Cancel"
        onYes={async () => {
          await approveDonationMatch();
          refreshTableData();
        }}
        onClose={() => {
          setDonationMatchToApprove(null);
          refreshTableData();
        }}
      />

      <Prompt
        show={donationMatchToDecline !== null}
        title="Decline Donation Match"
        message={`Are you sure you want to decline donation matching for ${donationMatchToDecline?.group_title}?`}
        yesMessage="Decline"
        yesClass="fas fa-times"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={() => {
          declineDonationMatch();
          refreshTableData();
        }}
        onClose={() => {
          setDonationMatchToDecline(null);
          refreshTableData();
        }}
      />
    </>
  );
}

export default HubManagementDonations;
