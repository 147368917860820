import axios from 'axios';
import { Dispatch } from 'redux';
import {
  IEventSummaryFE,
  IPageComponent,
  ISponsorEditInfo,
  IRegisterToFundraiserInfo,
  IEventCreateResponse,
  ITransaction,
  IDonationRequest,
  IPage,
  IFeeControl,
  IEventFE,
  ITransactionSummary,
} from '@gigit/interfaces';
import { Config } from '@gigit/config';
import {
  routes,
  swapRouteParams,
  dataURItoBlob,
  toastError,
  uploadImage,
  uploadMedias,
  toastSuccess,
} from '../helpers';
import { StatisticType } from '../interfaces';
import { initialEventState } from '../reducers/event';
import { getGroupEvents, updateGroupPageComponent, getGroup, makeEventVisible } from './group';
import { createToast } from './toaster';
import errorHelpers from '../helpers/errorHelpers';
import { localizeHelpers } from '../localizeHelpers';
import { loggerRequestActions } from '../requestActions';

export enum EventActionTypes {
  UPDATE_EVENT = 'UPDATE_EVENT',
}

export interface IUpdateEventAction {
  event: IEventSummaryFE;
  type: EventActionTypes.UPDATE_EVENT;
}

/** Fields for updating an event.
 * We use Omit<> to change the type of some fields.
 */
export interface IUpdateEventParams
  extends Partial<
    Omit<
      IEventSummaryFE,
      'start_date' | 'end_date' | 'registration_item_id' | 'campaign_id' | 'fee_control'
    >
  > {
  start_date?: string;
  end_date?: string;
  registration_item_id?: string | null;
  campaign_id?: string | null;
  fee_control?: Partial<IFeeControl>;
}

export type EventActions = IUpdateEventAction;

/** Fetches a event by handle or by id.
 * @param handleOrId Either the handle or the id of the event.
 */
export const getEvent = (handleOrId: string, navigate?: Boolean) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      pages: [],
      isEventLoading: true,
      fundraisingTicket: null,
      hasRegistrationItem: false,
      newTeam: '',
      teamSuccess: false,
      teamError: '',
      eventInvitations: [],
      isEventInvitationsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(swapRouteParams(routes.GET_EVENT, { handleOrId: handleOrId }))
      .then((response) => {
        if (response.data !== '') {
          dispatch({
            event: response.data,
            type: EventActionTypes.UPDATE_EVENT,
          });

          const groupId: string = response.data.group_id;
          if (groupId) {
            getGroup(groupId)(dispatch, getState);
          }

          const eventId: string = response.data.id;
          getComponentCount(eventId)(dispatch, getState);
          getEventPages(eventId)(dispatch, getState);
          getEventLocations(eventId)(dispatch, getState);
          getEventTeams(eventId)(dispatch, getState);

          if (getState().userState.isLoggedIn) {
            getCurrentUserEventRole(eventId)(dispatch, getState);
            getEventConversationMembers(eventId)(dispatch, getState);
          }

          if (response.data.required_item_ids && getState().userState.isLoggedIn) {
            let reqItems = response.data?.required_item_ids || [];
            currentEventRegistrationStatus(eventId, reqItems)(dispatch, getState);
          }

          if (response.data.required_item_ids) {
            getFundraisingRegistrationTickets(eventId)(dispatch, getState);
          }

          if (response.data.campaign_id) {
            getEventCampaign(groupId, response.data.campaign_id)(dispatch, getState);
          }

          if (navigate) {
            window.location.href = `/event/${response.data.handle}?help=1`;
          }
        } else {
          dispatch({
            isEventLoading: false,
            event: initialEventState.event,
            type: EventActionTypes.UPDATE_EVENT,
          });
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);

        let toast = toastError(errorObj.translatedMessage, 'Event');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isEventLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const toggleEventFitness = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.TOGGLE_EVENT_FITNESS, { eventId: eventId }))
      .then((response) => {
        dispatch({
          event: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);

        let toast = toastError(errorObj.translatedMessage, 'Update Event');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const toggleEventContentCreator = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.TOGGLE_EVENT_CONTENT_CREATOR, { eventId: eventId }))
      .then((response) => {
        dispatch({
          event: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);

        let toast = toastError(errorObj.translatedMessage, 'Update Event');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const currentEventRegistrationStatus = (eventId: string, _store_item_ids: Array<string>) => {
  return async (dispatch: Dispatch, getState: any) => {
    let hasItems = false;
    dispatch({
      registrationItemLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    _store_item_ids.forEach((id: string) => {
      axios
        .get(
          swapRouteParams(routes.VERIFY_EVENT_FUNDRAISING_PURCHASE, {
            eventId: eventId,
            store_item_id: id,
          }),
        )
        .then((response) => {
          if (response.data !== '') {
            hasItems = true;
          }
        });

      if (hasItems) {
        dispatch({
          hasRegistrationItem: true,
          registrationItemLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
        return;
      } else {
        dispatch({
          hasRegistrationItem: false,
          registrationItemLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      }
    });
  };
};

export const getFundraisingRegistrationTicket = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      fundraisingTicketLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(swapRouteParams(routes.GET_EVENT_FUNDRAISING_REGISTRATION_TICKET, { eventId: eventId }))
      .then((response) => {
        let _ticket = { ...response.data };
        // TODO: Is this needed?
        //_ticket.event_handle = _handle;

        dispatch({
          fundraisingTicket: _ticket,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          fundraisingTicketLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getFundraisingRegistrationTickets = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      fundraisingTicketLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(swapRouteParams(routes.GET_EVENT_FUNDRAISING_REGISTRATION_TICKETS, { eventId: eventId }))
      .then((response) => {
        let tickets = [...response.data];

        dispatch({
          fundraisingTickets: tickets,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          fundraisingTicketLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getComponentCount = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_EVENT_COMPONENT_COUNT, { eventId: eventId }))
      .then((response) => {
        dispatch({
          componentCount: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const clearEventError = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      success: false,
      error: '',
      type: EventActionTypes.UPDATE_EVENT,
    });
  };
};

export const updateEvent = (
  _event: IUpdateEventParams,
  callback?: (event: IEventSummaryFE) => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.UPDATE_EVENT, { eventId: _event.id }), _event)
      .then((response) => {
        dispatch({
          event: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
        callback?.(response.data);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);

        let toast = toastError(errorObj.translatedMessage, 'Update Event');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateEventCauses = (eventId: string, _causes: any[]) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.UPDATE_EVENT, { eventId: eventId }), { causes: _causes })
      .then((response) => {
        dispatch({
          event: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);

        let toast = toastError(errorObj.translatedMessage, 'Update Event Focus Areas');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateEventPage = (eventId: string, _pageId: string, _page: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isEventPagesLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .put(swapRouteParams(routes.UPDATE_EVENT_PAGE, { eventId: eventId, pageId: _pageId }), _page)
      .then((response) => {
        let _pages = [...getState().eventState.pages];

        for (let p in _pages) {
          if (_pages[p].id === response.data.id) {
            _pages[p] = response.data;
          }
        }

        dispatch({
          pages: [..._pages],
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);

        let toast = toastError(errorObj.translatedMessage, 'Update Event Page');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isEventPagesLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getEventLocations = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_EVENT_LOCATIONS, { eventId: eventId }))
      .then((response) => {
        dispatch({
          locations: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const createEventLocation = (
  eventId: string,
  _payload: any,
  _locationName: string,
  pages?: IPage[],
) => {
  return async (dispatch: Dispatch, getState: any) => {
    if (_payload.location.type) {
      delete _payload.location.type;
    }

    _payload.title = _locationName;

    axios
      .post(swapRouteParams(routes.CREATE_EVENT_LOCATION, { eventId: eventId }), _payload)
      .then((response) => {
        if (pages && pages.length > 0) {
          for (let p in pages) {
            const page = pages[p];
            page?.components?.forEach((c: IPageComponent) => {
              if (
                c.title === 'Locations' &&
                c.component_type === 'location' &&
                c.content_references
              ) {
                c.content_references.object_ids.push(response.data.id);
              }
            });

            createEventPage(eventId, page)(dispatch, getState);
          }
        }
        getEvent(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Event Location');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getCurrentUserEventRole = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isCurrentRoleLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(swapRouteParams(routes.GET_CURRENT_USER_EVENT_ROLE, { eventId: eventId }))
      .then((response) => {
        dispatch({
          currentUserRole: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        dispatch({
          currentUserRole: initialEventState.currentUserRole,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isCurrentRoleLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const createEventPageComponent = (eventId: string, _pageId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.CREATE_EVENT_PAGE_COMPONENT, { eventId: eventId, pageId: _pageId }),
        _payload,
      )
      .then((response) => {
        getCurrentEventPageComponents(eventId, _pageId)(dispatch, getState);
        getComponentCount(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Event Page Component');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateEventPageComponent = (
  eventId: string,
  _pageId: string,
  _componentId: string,
  _payload: any,
  _callback?: { (): void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(
        swapRouteParams(routes.UPDATE_EVENT_PAGE_COMPONENT, {
          eventId: eventId,
          pageId: _pageId,
          componentId: _componentId,
        }),
        _payload,
      )
      .then((response) => {
        getCurrentEventPageComponents(eventId, _pageId)(dispatch, getState);
        getComponentCount(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Event Page Component');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        if (_callback !== undefined) {
          _callback();
        }
      });
  };
};

export const deleteEventPageComponent = (
  eventId: string,
  _pageId: string,
  _componentId: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(
        swapRouteParams(routes.DELETE_EVENT_PAGE_COMPONENT, {
          eventId: eventId,
          pageId: _pageId,
          componentId: _componentId,
        }),
      )
      .then((response) => {
        getCurrentEventPageComponents(eventId, _pageId)(dispatch, getState);
        getComponentCount(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Event Page Component');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getCurrentEventPageComponents = (eventId: string, _pageId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isComponentsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(swapRouteParams(routes.GET_EVENT_PAGE_COMPONENTS, { eventId: eventId, pageId: _pageId }))
      .then((response) => {
        let _components = [...response.data.components];

        // TODO: Is this needed?
        // for (let c in _components) {
        //     if (_components[c].component_type === "store_item") {
        //         for (var co in _components[c].content_objects) {
        //             _components[c].content_objects[co].event_handle = _handle;
        //         }
        //     }
        // }

        dispatch({
          components: _components,
          type: EventActionTypes.UPDATE_EVENT,
        });

        getComponentCount(eventId)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isComponentsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const sendTaxReceiptEvent = (eventId: string, _donationId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.SEND_EVENT_TAX_RECEIPT, {
          eventId: eventId,
          donationId: _donationId,
        }),
      )
      .then((response) => {
        getEventDonations(response.data.event_id)(dispatch, getState);

        const toast = toastSuccess(
          localizeHelpers.translate('Donation receipt successfully sent.'),
          'Transaction Record',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error: any) => {
        const errorObj = errorHelpers.getErrorObject(error);

        let toast = toastError(errorObj.translatedMessage, 'Transaction Record');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const regenerateTaxReceiptEvent = (eventId: string, _donationId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.REGENERATE_EVENT_TAX_RECEIPT, {
          eventId: eventId,
          donationId: _donationId,
        }),
      )
      .then((response) => {
        getEventDonations(response.data.event_id)(dispatch, getState);

        const toast = toastSuccess(
          localizeHelpers.translate('Donation receipt successfully Re-generated.'),
          'Transaction Record',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error: any) => {
        const errorObj = errorHelpers.getErrorObject(error);

        let toast = toastError(errorObj.translatedMessage, 'Transaction Record');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createEvent = (
  groupId: string,
  _payload: any,
  options?: {
    _pages?: any;
    _updateComponent?: any;
    isFlow?: boolean;
    callback?: (event: IEventSummaryFE) => void;
  },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isCreatingEvent: true,
      type: EventActionTypes.UPDATE_EVENT,
    });
    let isFundraiser: Boolean = _payload?.event_type === 'obo_fundraiser';
    let route = isFundraiser ? routes.CREATE_OBO_EVENT : routes.CREATE_EVENT;
    axios
      .post<IEventFE>(swapRouteParams(route, { groupId: groupId }), _payload)
      .then((response) => {
        const eventId: string = response.data.id;

        if (!isFundraiser) {
          getGroupEvents(groupId)(dispatch, getState);
        }

        dispatch({
          success: true,
          error: '',
          type: EventActionTypes.UPDATE_EVENT,
        });

        if (options?._pages !== undefined) {
          const address =
            _payload.locations && _payload.locations?.length > 0 ? _payload.locations[0] : null;

          if (options?.isFlow && address) {
            createEventLocation(
              response.data.id,
              address,
              'default',
              options?._pages,
            )(dispatch, getState);
          } else {
            for (let p in options?._pages) {
              createEventPage(response.data.id, options?._pages[p])(dispatch, getState);
            }
          }
        }

        if (options?._updateComponent !== undefined) {
          updateGroupPageComponent(
            groupId,
            options?._updateComponent.page_id,
            options?._updateComponent.component_id,
            {
              content_references: {
                object_type: 'event',
                object_ids: [...options?._updateComponent.object_ids, response.data.id],
              },
            },
          )(dispatch, getState);
        }

        if (options?.isFlow && isFundraiser) {
          let navigate = true;
          getEvent(eventId, navigate)(dispatch, getState);
        } else if (options?.isFlow) {
          makeEventVisible(groupId, eventId)(dispatch, getState);
          getEvent(eventId)(dispatch, getState);
        }

        options?.callback?.(response.data);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          success: false,
          error: errorObj.translatedMessage,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isCreatingEvent: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

const createEventFundraiser = (groupId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isCreatingEvent: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .post(swapRouteParams(routes.CREATE_FUNDRAISER, { groupId: groupId }), _payload)
      .then((response) => {
        getGroupEvents(groupId)(dispatch, getState);

        dispatch({
          success: true,
          error: '',
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          success: false,
          error: errorObj.translatedMessage,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const createEventPage = (eventId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isEventPagesLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .post(swapRouteParams(routes.CREATE_EVENT_PAGE, { eventId: eventId }), _payload)
      .then((response) => {
        let _pages = getState().eventState.pages;

        dispatch({
          pages: [..._pages, ...[response.data]],
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Event Page');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isEventPagesLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const deleteEventPage = (eventId: string, _pageId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isEventPagesLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .delete(swapRouteParams(routes.DELETE_EVENT_PAGE, { eventId: eventId, pageId: _pageId }))
      .then((response) => {
        let _pages = [...getState().eventState.pages];

        for (let p in _pages) {
          if (_pages[parseInt(p)].id === _pageId) {
            _pages.splice(parseInt(p), 1);
            break;
          }
        }

        dispatch({
          pages: _pages,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Event Page');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isEventPagesLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getEventPages = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isEventPagesLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(swapRouteParams(routes.GET_EVENT_PAGES, { eventId: eventId }))
      .then((response) => {
        if (response.data.length > 0) {
          getCurrentEventPageComponents(eventId, response.data[0].id)(dispatch, getState);
        }

        dispatch({
          pages: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isEventPagesLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const updateEventCoverImage = (img: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const blob = dataURItoBlob(img);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let _event = {
          ...getState().eventState.event,
          ...{ cover_image_url: response.data.value },
        };

        updateEvent(_event)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Event Cover Image');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateEventProfileImage = (img: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const blob = dataURItoBlob(img);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let _event = {
          ...getState().eventState.event,
          ...{ profile_image_url: response.data.value },
        };

        updateEvent(_event)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Event Profile Image');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createEventMediaAlbum = (eventId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_EVENT_MEDIA_ALBUM, { eventId: eventId }), _payload)
      .then((response) => {
        getEventMediaAlbums(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Event Media Album');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const deleteEventMediaAlbum = (eventId: string, _albumId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(
        swapRouteParams(routes.DELETE_EVENT_MEDIA_ALBUM, { eventId: eventId, albumId: _albumId }),
      )
      .then((response) => {
        getEventMediaAlbums(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Event Media Album');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateEventMediaAlbum = (eventId: string, _albumId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(
        swapRouteParams(routes.UPDATE_EVENT_MEDIA_ALBUM, { eventId: eventId, albumId: _albumId }),
        _payload,
      )
      .then((response) => {
        getEventMediaAlbums(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Event Media Album');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getEventMediaAlbums = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_EVENT_MEDIA_ALBUMS, { eventId: eventId }))
      .then((response) => {
        dispatch({
          mediaAlbums: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const createEventTeam = (eventId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isCreatingTeam: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .post(swapRouteParams(routes.CREATE_EVENT_TEAM, { eventId: eventId }), _payload)
      .then((response) => {
        const eventId: string = response.data.id;

        dispatch({
          teamSuccess: true,
          teamError: '',
          newTeam: response.data.handle,
          type: EventActionTypes.UPDATE_EVENT,
        });

        getEventTeams(eventId, 0, 3)(dispatch, getState);
        getEventIndividuals(eventId, 0, 3)(dispatch, getState);
      })
      .catch((error: any) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          teamSuccess: false,
          teamError: errorObj.translatedMessage,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isCreatingTeam: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const joinEventTeam = (eventId: string, teamId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isJoiningTeam: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .post(swapRouteParams(routes.JOIN_EVENT_TEAM, { eventId: eventId, teamId: teamId }), _payload)
      .then((response) => {
        dispatch({
          teamSuccess: true,
          teamError: '',
          type: EventActionTypes.UPDATE_EVENT,
        });

        getEventTeams(eventId, 0, 3)(dispatch, getState);
        getEventIndividuals(eventId, 0, 3)(dispatch, getState);
      })
      .catch((error: any) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          teamSuccess: false,
          teamError: errorObj.translatedMessage,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isJoiningTeam: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const createEventIndividual = (eventId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isCreatingTeam: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .post(swapRouteParams(routes.CREATE_EVENT_INDIVIDUAL, { eventId: eventId }), _payload)
      .then((response) => {
        dispatch({
          teamSuccess: true,
          teamError: '',
          newTeam: '',
          type: EventActionTypes.UPDATE_EVENT,
        });

        getEventTeams(eventId, 0, 3)(dispatch, getState);
        getEventIndividuals(eventId, 0, 3)(dispatch, getState);
      })
      .catch((error: any) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          teamSuccess: false,
          teamError: errorObj.translatedMessage,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isCreatingTeam: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const resetTeamError = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      teamSuccess: false,
      teamError: '',
      type: EventActionTypes.UPDATE_EVENT,
    });
  };
};

export const getEventIndividuals = (
  eventId: string,
  _skip?: number,
  _limit?: number,
  _keyword?: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isIndividualsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    let _route = swapRouteParams(routes.GET_EVENT_INDIVIDUALS, { eventId: eventId });

    if (_skip || _limit || _keyword) {
      _route += '?';

      if (_skip) {
        _route += 'skip=' + _skip + '&';
      }

      if (_limit) {
        _route += 'limit=' + _limit + '&';
      }

      if (_keyword) {
        _route += 'search=' + _keyword;
      }
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          individuals: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isIndividualsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getEventTeams = (
  eventId: string,
  _skip?: number,
  _limit?: number,
  _keyword?: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isTeamsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    let _route = swapRouteParams(routes.GET_EVENT_TEAMS, { eventId: eventId });

    if (_skip || _limit || _keyword) {
      _route += '?';

      if (_skip) {
        _route += 'skip=' + _skip + '&';
      }

      if (_limit) {
        _route += 'limit=' + _limit + '&';
      }

      if (_keyword) {
        _route += 'search=' + _keyword;
      }
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          teams: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isTeamsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const searchEventTeams = (eventId: string, _keywords: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isTeamsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(swapRouteParams(routes.GET_EVENT_TEAMS, { eventId: eventId }) + '?search=' + _keywords)
      .then((response) => {
        dispatch({
          teams: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isTeamsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const searchEventIndividuals = (eventId: string, _keywords: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isIndividualsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(
        swapRouteParams(routes.GET_EVENT_INDIVIDUALS, { eventId: eventId }) +
          '?search=' +
          _keywords,
      )
      .then((response) => {
        dispatch({
          individuals: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isIndividualsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getEventGigs = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isEventGigsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(swapRouteParams(routes.GET_EVENT_GIGS, { eventId: eventId }))
      .then((response) => {
        dispatch({
          gigs: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isEventGigsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getEventRoles = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isEventRolesLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(swapRouteParams(routes.GET_EVENT_ROLES, { eventId: eventId }))
      .then((response) => {
        dispatch({
          eventRoles: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          error: errorObj.translatedMessage,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isEventRolesLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const createEventRole = (eventId: string, _role: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_EVENT_ROLE, { eventId: eventId }), _role)
      .then((response) => {
        getEventRoles(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Event Role');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateEventRole = (eventId: string, _role: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.UPDATE_EVENT_ROLE, { eventId: eventId, id: _role.id }), _role)
      .then((response) => {
        getEventRoles(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Event Role');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const deleteEventRole = (eventId: string, _id: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(swapRouteParams(routes.DELETE_EVENT_ROLE, { eventId: eventId, id: _id }))
      .then((response) => {
        getEventRoles(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Event Role');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getEventMembers = (eventId: string, _search?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _route = swapRouteParams(routes.GET_EVENT_MEMBERS, { eventId: eventId });

    if (_search) {
      _route = _route + '?search=' + _search;
    }

    dispatch({
      isMembersLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          members: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          isMembersLoading: true,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getEventMembersExport = (eventId: string, eventHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_EVENT_MEMBERS_EXPORT, { eventId: eventId }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'Members_for_' + eventHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Members Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const assignEventRoleToUser = (
  eventId: string,
  _roleId: string,
  userId: string,
  callback?: () => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.ASSIGN_EVENT_ROLE_TO_USER, {
          eventId: eventId,
          roleId: _roleId,
          userId: userId,
        }),
      )
      .then(() => {
        callback?.();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Role Assignment');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getEventAttendees = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_EVENT_ATTENDEES, { eventId: eventId }))
      .then((response) => {
        const attendees = response.data;

        dispatch({
          attendees,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const purchaseEventItems = (
  eventId: string,
  _payload: any,
  callback?: any,
  groupId?: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    // GIG-8: Upload sponsorship images to S3
    let sponsorImages: string[] = [];

    if (_payload.items) {
      if (getState().userState.isLoggedIn) {
        for (const item of _payload.items) {
          if (item.sponsor?.sponsor_img) {
            const url = await uploadImage(item.sponsor?.sponsor_img);
            item.sponsor.sponsor_img = url;
          }
        }
      } else {
        // GIG-1727: When not logged in, don't upload images.
        for (const item of _payload.items) {
          if (item.sponsor?.sponsor_img) {
            sponsorImages.push(item.sponsor.sponsor_img);
            item.sponsor.sponsor_img = '';
          }
        }
      }
    }

    axios
      .post(swapRouteParams(routes.EVENT_PURCHASE, { eventId: eventId }), _payload)
      .then(async (response) => {
        loggerRequestActions.log({
          message: 'Invoice Purchase Route Called',
          level: 'info',
          extra_options: {
            purchaser_email: _payload.purchaser.email,
          },
        });

        // GIG-1727: When not logged in, upload sponsor images afterwards using temp access token
        // Fixes bug where it errors out when user is not logged in.
        if (
          sponsorImages.length &&
          response.data.temp_auth_token &&
          response.data.sponsor_items &&
          !getState().userState.isLoggedIn
        ) {
          const accessToken = response.data.temp_auth_token;

          for (let i = 0; i < response.data.sponsor_items.length; i++) {
            const sponsorItem = response.data.sponsor_items[i];
            const sponsorImage = sponsorImages[i];

            const imageUrl = await uploadImage(sponsorImage, accessToken);

            updateEventSponsorshipItem(
              eventId,
              sponsorItem.transaction_item_id,
              { sponsor_img: imageUrl },
              undefined,
              accessToken,
            )(dispatch, getState);
          }
        }

        if (response.data.is_virtual) {
          loggerRequestActions.log({
            message: 'Virtual Purchase',
            level: 'info',
            extra_options: {
              purchaser_email: _payload.purchaser.email,
            },
          });

          if (response.data.client_secret) {
            response.data.client_secret.forEach((key: string, i: number) => {
              if (groupId) {
                loggerRequestActions.log({
                  message: 'Register Virtual Purchase',
                  level: 'info',
                  extra_options: {
                    purchaser_email: _payload.purchaser.email,
                    group_id: groupId,
                    key: key,
                  },
                });

                registerItem(groupId, key)(dispatch, getState);
                if (i === response.data.client_secret.length - 1) {
                  if (callback) {
                    callback();
                  }
                }
              }
            });
          }
        }
        dispatch({
          purchaseIntent: response.data,
          purchaseError: '',
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);

        loggerRequestActions.log({
          message: `Invoice Purchase Error: ${errorObj.translatedMessage}`,
          level: 'error',
          extra_options: {
            purchaser_email: _payload.purchaser.email,
            error_obj: JSON.stringify(errorObj),
          },
        });

        let toast = toastError(errorObj.translatedMessage, 'Purchase');
        createToast(toast)(dispatch, getState);
        if (callback) {
          callback();
        }
      });
  };
};

export const registerItem = (groupId: string, _id: string, options?: { callback?: () => void }) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.REGISTER_ITEM_GROUP, { groupId: groupId, id: _id }))
      .then((response) => {
        loggerRequestActions.log({
          message: `Register Virtual Purchase Complete`,
          level: 'info',
          extra_options: {
            group_id: groupId,
            key: _id,
          },
        });

        options?.callback?.();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);

        loggerRequestActions.log({
          message: `Register Virtual Purchase Error: ${errorObj.translatedMessage}`,
          level: 'error',
          extra_options: {
            group_id: groupId,
            key: _id,
          },
        });

        let toast = toastError(errorObj.translatedMessage, 'Register Item');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const checkInEventTicket = (eventId: string, _attendeeId: string, _ticketId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.CHECK_IN_EVENT_TICKET, {
          eventId: eventId,
          attendeeId: _attendeeId,
          ticketId: _ticketId,
        }),
      )
      .then((response) => {
        getEventAttendees(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Check-In');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const resetCheckIn = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      checkInSuccess: false,
      checkInError: '',
      checkInLoading: false,
      type: EventActionTypes.UPDATE_EVENT,
    });
  };
};

// TODO: Maybe merge with getGroupCampaign() in group.tsx?
export const getEventCampaign = (groupId: string, _id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_GROUP_CAMPAIGN, { groupId: groupId, id: _id }))
      .then((response) => {
        dispatch({
          currentCampaign: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Event Campaign');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateEventRaisedAmount = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    return axios
      .put(swapRouteParams(routes.UPDATE_EVENT_CAMPAIGN_RAISED_AMOUNT, { eventId: eventId }))
      .then((response) => {
        dispatch({
          event: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Campaign Raised Amount');
        createToast(toast)(dispatch, getState);
      });
  };
};

/** Fetches page components from the event and stores them ticketComponents field in eventState. */
export const getEventPageComponents = (
  eventId: string,
  components: { pageId: string; componentId: string }[],
) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const ticketComponents: IPageComponent[] = [];

      for (var component of components) {
        const response = await axios.get(
          swapRouteParams(routes.GET_EVENT_PAGE_COMPONENT, {
            eventId: eventId,
            pageId: component.pageId,
            componentId: component.componentId,
          }),
        );
        ticketComponents.push(response.data);
      }

      dispatch({
        ticketComponents: ticketComponents,
        type: EventActionTypes.UPDATE_EVENT,
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Fetch Event Component');
      createToast(toast)(dispatch, getState);
    }
  };
};

export const createEventDonationIntent = (
  eventId: string,
  _payload: IDonationRequest,
  groupId?: string,
  callback?: () => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_EVENT_DONATION_INTENT, { eventId: eventId }), _payload)
      .then((response) => {
        if (response.data.is_virtual) {
          if (response.data.client_secret) {
            if (groupId) {
              registerItem(groupId, response.data.client_secret, { callback: callback })(
                dispatch,
                getState,
              );

              if (callback) {
                getEventDonations(eventId)(dispatch, getState);

                const toast = toastSuccess(
                  localizeHelpers.translate('Donation record added!'),
                  'Donation Record',
                );
                createToast(toast)(dispatch, getState);
              }
            }
          }
        }

        dispatch({
          donationIntent: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Event Donation Intent');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getEventGigPayments = (eventId: string, _query?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      gigPaymentsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    let _route = swapRouteParams(routes.GET_EVENT_GIG_PAYMENTS, { eventId: eventId });

    if (_query !== undefined) {
      _route = _route + '?search=' + _query;
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          eventGigPayments: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          gigPaymentsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getEventDonations = (eventId: string, _query?: URLSearchParams) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      donationsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    let _route = swapRouteParams(routes.GET_EVENT_DONATIONS, { eventId: eventId });

    const queryString = _query?.toString();
    if (queryString) {
      _route = _route + `?${queryString}`;
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          eventDonations: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .finally(() => {
        dispatch({
          donationsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getEventDonationsPublic = (
  eventId: string,
  _paginate: boolean,
  _limit: number,
  _skip: number,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      donationsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    let _route = swapRouteParams(routes.GET_PUBLIC_DONATIONS_EVENT, {
      eventId: eventId,
      limit: _limit,
      skip: _skip,
    });

    axios
      .get(_route)
      .then((response) => {
        if (_skip === 0) {
          dispatch({
            eventDonationsPublic: response.data,
            type: EventActionTypes.UPDATE_EVENT,
          });
        } else {
          dispatch({
            eventDonationsPublic: [...getState().eventState.eventDonationsPublic, ...response.data],
            type: EventActionTypes.UPDATE_EVENT,
          });
        }
      })
      .finally(() => {
        dispatch({
          donationsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const refundEventDonation = (
  eventId: string,
  _donationId: string,
  _payload: any,
  callback?: (transaction: ITransaction) => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.REFUND_EVENT_DONATION, {
          eventId: eventId,
          donationId: _donationId,
        }),
        _payload,
      )
      .then((response) => {
        const toast = toastSuccess(localizeHelpers.translate('Donation Refunded.'), 'Donation');
        createToast(toast)(dispatch, getState);
        getEventDonations(eventId)(dispatch, getState);
        callback?.(response.data);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Refund');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const refundEventPurchase = (eventId: string, _transactionId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.REFUND_EVENT_STORE_PURCHASE, {
          eventId: eventId,
          transaction_id: _transactionId,
        }),
        _payload,
      )
      .then((response) => {
        const toast = toastSuccess(
          localizeHelpers.translate('Purchase Refunded.'),
          'Store Purchase',
        );
        createToast(toast)(dispatch, getState);

        getEventPurchases(eventId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Refund Purchase');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getEventPurchases = (
  eventId: string,
  _query?: URLSearchParams,
  options?: { callback?: (results: ITransactionSummary[]) => void; limit: string },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      eventPurchasesLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    let _route = swapRouteParams(routes.EVENT_PURCHASE, { eventId: eventId });

    const queryString = _query?.toString();
    if (queryString) {
      _route = _route + `?${queryString}`;
    }

    axios
      .get(_route)
      .then((response) => {
        let originalResponse = [...response.data];
        if (options?.limit && originalResponse.length > parseInt(options?.limit)) {
          response.data.pop();
        }
        dispatch({
          eventPurchases: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });

        // https://app.clickup.com/t/8404472/GIG-6167 - used to set hasMore in StorePurchases.tx
        if (options?.callback) {
          options?.callback(originalResponse);
        }
      })
      .finally(() => {
        dispatch({
          eventPurchasesLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const createEventDonationSubscriptionIntent = (eventId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.CREATE_EVENT_DONATION_SUBSCRIPTION_INTENT, { eventId: eventId }),
        _payload,
      )
      .then((response) => {
        dispatch({
          donationIntent: { amounts: { ...response.data } },
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Event Donation Subscription Intent');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const submitEventSubscription = (eventId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.SUBMIT_EVENT_SUBSCRIPTION, { eventId: eventId }), _payload)
      .then((response) => {
        dispatch({
          subscriptionSuccess: true,
          subscriptionError: '',
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          subscriptionSuccess: false,
          subscriptionError: errorObj.translatedMessage,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const resetEventSubscriptionError = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      subscriptionSuccess: false,
      subscriptionError: '',
      type: EventActionTypes.UPDATE_EVENT,
    });
  };
};

export const resetEventState = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      ...initialEventState,
      type: EventActionTypes.UPDATE_EVENT,
    });
  };
};

export const resetEvent = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      event: initialEventState.event,
      type: EventActionTypes.UPDATE_EVENT,
    });
  };
};

export const getEventTransactionItems = (id: string, _transaction_Id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(
        swapRouteParams(routes.GET_PURCHASE_EVENT_ITEMS, {
          eventId: id,
          transaction_id: _transaction_Id,
        }),
      )
      .then((response) => {
        dispatch({
          currentTransactionItems: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const changeEventStatus = (
  eventId: string,
  status: string,
  callback?: (updatedEvent: IEventSummaryFE) => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isEventLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .post(swapRouteParams(routes.CHANGE_EVENT_STATUS, { eventId }), { status })
      .then((response) => {
        dispatch({
          event: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
        callback?.(response.data);

        const toast = toastSuccess(
          localizeHelpers.translate('Event status has been changed to {{status}}', {
            status,
          }),
          'Change event status',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        // const errorMessage = localizeHelpers.translate(messageTemplate, messageArgs);

        let toast = toastError(errorObj.translatedMessage, 'Change event status');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isEventLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getDonationsExportEvent = (eventId: string, eventHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_DONATIONS_EXPORT_EVENT, { eventId: eventId }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'Donations_for_' + eventHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Donations Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getPurchaseExportEvent = (eventId: string, eventHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.EXPORT_PURCHASES_EVENT, { eventId: eventId }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'purchases_for_' + eventHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Purchase Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const downloadEventPurchaseReceipt = (eventId: string, _transaction_Id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(
        swapRouteParams(routes.DOWNLOAD_EVENT_PURCHASE_RECEIPT, {
          eventId: eventId,
          transaction_id: _transaction_Id,
        }),
        { responseType: 'blob' },
      )
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'application/pdf' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'purchase_' + _transaction_Id;
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Purchase Receipt');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getPurchaseExportEventDaily = (eventId: string, eventHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.EVENT_EXPORT_STORE_DAILY, { eventId: eventId }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'purchases_for_' + eventHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Purchase Daily Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

/** Changes the handle of a Event, which changes the URL of the group.
 * @param onChanged Callback that is invoked once the handle is changed.
 */
export const changeEventHandle = (eventId: string, newHandle: string, onChanged: () => void) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.CHANGE_EVENT_HANDLE, { eventId: eventId }), { value: newHandle })
      .then((response) => {
        onChanged();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Event');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getEventSponsors = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isSponsorsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(swapRouteParams(routes.GET_EVENT_SPONSORS, { eventId: eventId }))
      .then((response) => {
        dispatch({
          sponsors: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Get Sponsors');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isSponsorsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const updateEventSponsorshipItem = (
  eventId: string,
  transactionItemId: string,
  payload: Partial<ISponsorEditInfo>,
  imageData?: string | null,
  accessToken?: string,
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    // GIG-8: Upload sponsorship images to S3
    if (imageData) {
      const [url] = await uploadMedias([imageData]);
      payload.sponsor_img = url.url;
    }

    let headers: { [index: string]: string } = {};

    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
    }

    axios
      .put(
        swapRouteParams(routes.UPDATE_EVENT_SPONSORSHIP_ITEM, {
          eventId: eventId,
          transactionItemId: transactionItemId,
        }),
        payload,
        { headers: headers },
      )
      .then((response) => {
        if (getState().userState.isLoggedIn) {
          getEventSponsors(eventId)(dispatch, getState);
        }

        options?.callback?.();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Sponsors');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getEventStats = (eventId: string, statisticType: StatisticType) => {
  let route = '';
  switch (statisticType) {
    case StatisticType.DONATIONS:
      route = routes.GET_EVENT_DONATIONS_STATS;
      break;
    case StatisticType.STORE_PUSRCHASES:
      route = routes.GET_EVENT_STORE_PURCHASES_STATS;
      break;
    case StatisticType.STORE:
      route = routes.GET_EVENT_STORE_STATS;
      break;
    case StatisticType.AUCTION:
      route = routes.GET_EVENT_AUCTION_STATS;
      break;
    case StatisticType.AUCTION_PURCHASES:
      route = routes.GET_EVENT_AUCTION_PURCHASES_STATS;
  }

  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isStatsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    axios
      .get(swapRouteParams(route, { event_id: eventId }))
      .then((response) => {
        dispatch({
          [statisticType]: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Get Statistic');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isStatsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getEventConversationMembers = (eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    if (getState().userState.isLoggedIn) {
      axios
        .get(swapRouteParams(routes.GET_EVENT_CONVERSATION_MEMBERS, { eventId: eventId }))
        .then((response) => {
          dispatch({
            eventManagers: response.data,
            type: EventActionTypes.UPDATE_EVENT,
          });
        })
        .catch((error) => {
          if (
            error.response.data.gigitErrorCode !== 'ERROR.AUTH.REFRESH_TOKEN_EXPIRED' &&
            // For some reason unauthorized error on this route returns null
            (error.response.data.gigitErrorCode !== null || error.response.data.message !== '')
          ) {
            const errorObj = errorHelpers.getErrorObject(error);
            let toast = toastError(errorObj.translatedMessage, 'Get Event Conversation Members');
            createToast(toast)(dispatch, getState);
          }
        });
    }
  };
};

export const downloadEventDonationReceipt = (eventId: string, donation: ITransaction) => {
  return async (dispatch: Dispatch, getState: any) => {
    const { receipt_number, tax_receipt_prefix, id } = donation;
    axios
      .get(
        swapRouteParams(routes.DOWNLOAD_EVENT_DONATION_RECEIPT, {
          eventId: eventId,
          transaction_id: id,
        }),
        { responseType: 'blob' },
      )
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'application/pdf' });
        const fileName = localizeHelpers.translate('Donation receipt');
        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download =
          (tax_receipt_prefix ? tax_receipt_prefix + ' ' : '') +
          fileName +
          ' ' +
          (receipt_number ? '#' + receipt_number : id);
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Donation Receipt');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const purchaseFundraise = (
  groupId: string | undefined,
  eventId: string,
  payload: IRegisterToFundraiserInfo,
  callback?: () => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    if (getState().userState.isLoggedIn) {
      dispatch({
        isCreatingTeam: true,
        purchaseError: '',
        type: EventActionTypes.UPDATE_EVENT,
      });
      axios
        .post(swapRouteParams(routes.FUNDRAISE_CHECKOUT, { eventId: eventId }), payload)
        .then((response) => {
          dispatch({
            purchaseIntent: response.data?.payment_intent,
            purchaseError: '',
            newTeam: response.data?.team?.handle,
            type: EventActionTypes.UPDATE_EVENT,
          });

          const paymentIntent = response.data.payment_intent;
          if (paymentIntent?.is_virtual) {
            if (paymentIntent.client_secret) {
              paymentIntent.client_secret.forEach((key: string, i: number) => {
                if (groupId) {
                  registerItem(groupId, key)(dispatch, getState);
                  if (i === paymentIntent.client_secret.length - 1) {
                    if (callback) {
                      callback();
                    }
                  }
                }
              });
            }
          }
        })
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          dispatch({
            purchaseError: errorObj.translatedMessage,
            type: EventActionTypes.UPDATE_EVENT,
          });
          let toast = toastError(errorObj.translatedMessage, 'Fundraise Purchase');
          createToast(toast)(dispatch, getState);
        })
        .finally(() => {
          dispatch({
            isCreatingTeam: false,
            type: EventActionTypes.UPDATE_EVENT,
          });
          if (callback) {
            callback();
          }
        });
    }
  };
};

export const getChildEvents = (parentEventId: string, search?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isChildEventsLoading: true,
      type: EventActionTypes.UPDATE_EVENT,
    });

    let url = swapRouteParams(routes.GET_EVENT_CHILD_EVENTS, { eventId: parentEventId });

    url += '?sort=title';

    if (search) {
      url += `&search=${search}`;
    }

    axios
      .get(url)
      .then((response) => {
        dispatch({
          childEvents: response.data,
          type: EventActionTypes.UPDATE_EVENT,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Get Child Events');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isChildEventsLoading: false,
          type: EventActionTypes.UPDATE_EVENT,
        });
      });
  };
};

export const getEventIndividualsExport = (eventId: string, eventHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_EVENT_INDIVIDUALS_EXPORT, { eventId: eventId }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'Fundraisers_for_' + eventHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Fundraisers Export');
        createToast(toast)(dispatch, getState);
      });
  };
};
