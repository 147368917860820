import axios from 'axios';
import { routes, swapRouteParams, uploadImage } from '../helpers';
import { IEventIndividual, IEventTeam, IFundraiserDonation } from '@gigit/interfaces';

/** Fetches a Team by its handle or id
 * @param teamHandleOrId
 */

export namespace teamRequestActions {
  export async function getTeam(eventId: string, teamHandleOrId: string): Promise<IEventTeam> {
    const response = await axios.get<IEventTeam>(
      swapRouteParams(routes.GET_EVENT_TEAM, { eventId: eventId, teamHandleOrId: teamHandleOrId }),
    );
    return response.data;
  }

  export async function updateTeamProfileImage(img: string): Promise<string> {
    return await uploadImage(img);
  }

  export async function updateTeamCoverImage(img: string): Promise<string> {
    return await uploadImage(img);
  }

  export async function updateTeam(
    eventId: string,
    teamId: string,
    payload: Partial<IEventTeam>,
  ): Promise<IEventTeam> {
    const response = await axios.put<IEventTeam>(
      swapRouteParams(routes.UPDATE_EVENT_TEAM, { eventId: eventId, teamId: teamId }),
      payload,
    );
    return response.data;
  }

  export async function getTeamDonations(
    eventId: string,
    teamId: string,
  ): Promise<IFundraiserDonation[]> {
    const response = await axios.get<IFundraiserDonation[]>(
      swapRouteParams(routes.GET_TEAM_DONATIONS, { eventId: eventId, teamId: teamId }),
    );
    return response.data;
  }

  export async function getTeamMembers(
    eventId: string,
    teamId: string,
  ): Promise<IEventIndividual[]> {
    const response = await axios.get<IEventIndividual[]>(
      swapRouteParams(routes.GET_TEAM_MEMBERS, { eventId: eventId, teamId: teamId }),
    );
    return response.data;
  }

  export async function toggleEventTeamFitness(
    eventId: string,
    team: IEventTeam,
  ): Promise<IEventTeam> {
    const response = await axios.put<IEventTeam>(
      swapRouteParams(routes.TOGGLE_EVENT_TEAM_FITNESS, { eventId, team_id: team.id }),
    );
    return response.data;
  }
}
