import { useEffect, useCallback } from 'react';
import { formatQuery } from '../../../../helpers';
import { userRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';

export const useFetch = (
  data: {
    objectId?: string;
    objectType?: string;
    notUserCommunityFeed?: boolean;
    page: number;
    limitPosts: number;
  },
  dispatch: React.Dispatch<any>,
) => {
  const { dispatchToastError } = useToastDispatcher();
  useEffect(() => {
    dispatch({ type: 'FETCHING', fetching: true });
    const query = formatQuery({
      sort: [
        { id: 'created_at', order: 'desc' },
        { id: 'relevance', order: 'desc' },
      ],
      skip: String(data.page * data.limitPosts),
      limit: String(data.limitPosts),
    });

    if (data?.notUserCommunityFeed && data?.objectId && data?.objectType) {
      userRequestActions
        .getActivityFeedItemsSummaryForObjects(data.objectType, data.objectId, query)
        .then((res) => {
          if (res?.length > 0) {
            dispatch({ type: 'STACK', userPosts: res });
          } else {
            dispatch({ type: 'OUT_OF_POSTS', out_of_posts: true });
          }
        })
        .catch((error) => {
          dispatchToastError(error, `Get ${data.objectType} Activity Feed`);
        })
        .finally(() => {
          dispatch({ type: 'FETCHING', fetching: false });
        });
    } else {
      userRequestActions
        .getActivityFeedItemsSummary(query)
        .then((res) => {
          if (res?.length > 0) {
            dispatch({ type: 'STACK', userPosts: res });
          } else {
            dispatch({ type: 'OUT_OF_POSTS', out_of_posts: true });
          }
        })
        .catch((error) => {
          dispatchToastError(error, 'Get User Activity Feed');
        })
        .finally(() => {
          dispatch({ type: 'FETCHING', fetching: false });
        });
    }
  }, [dispatch, data.page]);
};

// infinite scrolling with intersection observer
export const useInfiniteScroll = (
  scrollRef: React.MutableRefObject<any>,
  dispatch: React.Dispatch<any>,
) => {
  const scrollObserver = useCallback(
    (node) => {
      new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0) {
            dispatch({ type: 'ADVANCE_PAGE' });
          }
        });
      }).observe(node);
    },
    [dispatch],
  );

  useEffect(() => {
    if (scrollRef.current) {
      scrollObserver(scrollRef.current);
    }
  }, [scrollObserver, scrollRef]);
};
