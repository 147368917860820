import React, { ChangeEvent } from 'react';
import { combineClassNames } from '../../helpers';

import './Dropdown.scss';

export interface IOptions {
  label: string;
  value: string;
}

// TODO: change type to: value: string | null.
// Question?: Why does this have style and classNames? seems we could alter the class either way unless there's a reason.
export interface IDropdownProps {
  icon?: string;
  style?: any;
  value: any | null;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  name: string;
  label?: string;
  options: Array<IOptions>;
  required?: boolean;
  disabled?: boolean;

  /** Class name that can be applied to the entire component. This acts as a wrapper around the dropdown. */
  className?: string;

  /** Class name that can be applied to input wrapper. This has the visual border. */
  inputWrapClassName?: string;

  /** Class name applied to the <select> element. */
  selectClassName?: string;

  notranslate?: 'yes';
  placeholder?: string;
  shouldSort?: boolean;
}

function Dropdown(props: IDropdownProps) {
  // Find placeholder. A placeholder prop or option with empty value can suffice.
  // TODO: We should switch to using explicit placeholder prop.
  let placeholder = props?.placeholder || props.options.filter((opt) => opt.value === '')[0]?.label;

  // Attempt to extract placeholder out (Many usages of Dropdown use empty value as placeholder option).
  let options = placeholder ? props.options.filter((opt) => opt.value !== '') : props.options;

  if (props?.shouldSort && props.options.length > 0) {
    options = options.sort((a, b) => a.label.localeCompare(b.label));
  }

  return (
    <div className={combineClassNames('Dropdown', props.className)}>
      {props.label && (
        <label>
          <span>
            {props.label}
            {props.required && <span className="required">*</span>}
          </span>
        </label>
      )}
      <div className={combineClassNames('input-wrap', props.inputWrapClassName)}>
        <i className={props?.icon ? 'Dropdown-icon ' + props.icon : 'fas fa-angle-down'} />
        <select
          className={props.selectClassName}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          value={props.value || ''}
          onChange={props.onChange}
          notranslate={props.notranslate}
        >
          {placeholder && (
            <option
              value=""
              disabled
            >
              {placeholder}
            </option>
          )}
          {options.map(function (option, index) {
            return (
              <option
                value={option.value}
                key={index}
              >
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}

export default Dropdown;
