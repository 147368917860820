import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { IAppState } from '../../../../store';
import { IUserRole, ICustomFieldValues, ICustomField } from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { handleInputChange, swapRouteParams, routes, toastSuccess } from '../../../../helpers';
import { createToast } from '../../../../actions/toaster';

import Button from '../../../Button/Button';
import TextField from '../../../TextField/TextField';
import Dropdown, { IOptions } from '../../../Dropdown/Dropdown';

import './CustomFieldsLibrary.scss';
import { IToast } from '../../../../interfaces';
import { localizeHelpers } from '../../../../localizeHelpers';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  labels: { value: string; icon: string }[];
  contact: IUserRole;
  onSaveUserCustomField(cf: ICustomField): void;
  onEditCustomField(cf: ICustomField): void;
  onDeleteCustomField(): void;
  createToast(toast: IToast): void;
}

interface IState {
  expandedList: number;
  customFields: ICustomField[];
  fieldCount: { type: string; amount: number }[];
  deleteField: number;
}

class CustomFieldsLibrary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      expandedList: 0,
      customFields: [],
      fieldCount: [],
      deleteField: -1,
    };

    this.getCustomFields = this.getCustomFields.bind(this);
    this.deleteCustomField = this.deleteCustomField.bind(this);
  }

  componentDidMount() {
    this.getCustomFields();
  }

  getCustomFields() {
    axios
      .get(
        swapRouteParams(routes.GET_GROUP_CUSTOM_FIELDS, { groupId: this.props.contact.object_id }),
      )
      .then((response) => {
        this.setState(
          {
            customFields: response.data,
          },
          () => {
            let _fieldCount: { type: string; amount: number }[] = [];

            for (let l in this.props.labels) {
              let _amt: number = 0;

              for (let c in this.state.customFields) {
                if (this.state.customFields[c].type === this.props.labels[l].value) {
                  _amt++;
                }
              }

              _fieldCount.push({ type: this.props.labels[l].value, amount: _amt });
            }

            this.setState({
              fieldCount: _fieldCount,
            });
          },
        );
      });
  }

  deleteCustomField() {
    if (this.state.deleteField !== -1) {
      axios
        .delete(
          swapRouteParams(routes.DELETE_CUSTOM_FIELD, {
            groupId: this.props.contact.object_id,
            cfId: this.state.customFields[this.state.deleteField].id,
          }),
        )
        .then((response) => {
          const toast = toastSuccess(
            localizeHelpers.translate('Custom Field deleted.'),
            'Custom Field Library',
          );
          this.props.createToast(toast);
        })
        .finally(() => {
          this.setState(
            {
              deleteField: -1,
            },
            () => {
              this.getCustomFields();
              this.props.onDeleteCustomField();
            },
          );
        });
    }
  }

  render() {
    return (
      <div className="CustomFieldsLibrary">
        {this.state.deleteField === -1 && (
          <React.Fragment>
            <div className="title">Custom Fields Library</div>
            <div className="inner-content">
              <ul className="main-list">
                {this.props.labels.map((item: { value: string; icon: string }, index: number) => {
                  return (
                    <li
                      notranslate="yes"
                      key={index}
                    >
                      <div
                        onClick={() => {
                          this.setState({ expandedList: index });
                        }}
                        className="label-title no-select"
                      >
                        <i className={item.icon}></i>
                        <span>
                          {item.value} (
                          {this.state.fieldCount[index] !== undefined &&
                            this.state.fieldCount[index].amount}
                          )
                        </span>
                      </div>
                      {this.state.expandedList === index && (
                        <ul className="expanded-list">
                          {this.state.customFields.map((cf: ICustomField, c: number) => {
                            if (cf.type === item.value) {
                              return (
                                <li key={c}>
                                  <div className="field">{cf.name}</div>
                                  <div className="field-actions">
                                    <Button
                                      className="delete"
                                      text="Delete"
                                      onClick={(e: any) => {
                                        this.setState({ deleteField: c });
                                      }}
                                    />
                                    <Button
                                      text="Edit"
                                      onClick={() => {
                                        this.props.onEditCustomField(cf);
                                      }}
                                    />
                                    <Button
                                      text="Add"
                                      onClick={() => {
                                        this.props.onSaveUserCustomField(cf);
                                      }}
                                    />
                                  </div>
                                </li>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </React.Fragment>
        )}
        {this.state.deleteField !== -1 && (
          <React.Fragment>
            <div className="title">
              Delete {this.state.customFields[this.state.deleteField].name}
            </div>
            <div className="inner-content">
              <span className="delete-msg">
                Are you sure you want to delete this custom field? This will delete data values for
                all users.
              </span>
              <div className="delete-actions">
                <Button
                  className="no"
                  text="No"
                  onClick={(e: any) => {
                    this.setState({ deleteField: -1 });
                  }}
                />
                <Button
                  text="Yes"
                  onClick={(e: any) => {
                    this.deleteCustomField();
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {
  createToast,
};

export default withRouter(
  withTranslation('translations')(
    connect(mapStateToProps, mapDispatchToProps)(CustomFieldsLibrary),
  ),
);
