import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { IEventSummaryFE, IGig, IPage, IPageComponent } from '@gigit/interfaces';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IStringMap } from '../../../helpers';
import { Constants } from '@gigit/constants';

import { updateGroupPageComponent, getCurrentGroupPageComponents } from '../../../actions/group';
import { updateEventPageComponent, getCurrentEventPageComponents } from '../../../actions/event';
import { changeGigStatus } from '../../../actions/gig';

import CoverImage from '../../CoverImage/CoverImage';
import Portrait from '../../Portrait/Portrait';
import { Prompt } from '../../../components/Prompt/Prompt';

import { IGroupState } from '../../../reducers/group';
import { IEventState } from '../../../reducers/event';

import './GigListItem.scss';
import ChangeStatusMenu from '../../ChangeStatusMenu/ChangeStatusMenu';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { userSelectors } from '../../../selectors/user';
import { IOwnerObject } from '../../../interfaces/ownerObject';
import typeHelpers from '../../../helpers/typeHelpers';
import { ReduxActionType } from '../../../interfaces';
import { IUserState } from '../../../reducers/user';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { uiConstants } from '../../../constants';

interface IProps extends RouteComponentProps<any> {
  owner: IOwnerObject;
  page: IPage;
  component: IPageComponent;
  gig: IGig;
  groupState: IGroupState;
  eventState: IEventState;
  userState: IUserState;
  permissions?: IStringMap;
  locale: string;
  dragProps?: DraggableProvidedDragHandleProps;
  onComponentUpdate?(): void;
  updateGroupPageComponent: ReduxActionType<typeof updateGroupPageComponent>;
  updateEventPageComponent: ReduxActionType<typeof updateEventPageComponent>;
  getCurrentGroupPageComponents: ReduxActionType<typeof getCurrentGroupPageComponents>;
  getCurrentEventPageComponents: ReduxActionType<typeof getCurrentEventPageComponents>;
  changeGigStatus: ReduxActionType<typeof changeGigStatus>;
}

interface IState {
  showDeletePrompt: boolean;
}

class GigListItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showDeletePrompt: false,
    };
  }

  updateVisibility() {
    let _gigs = [...(this.props.component.content_references?.object_ids ?? [])];

    typeHelpers.assertNotNullOrUndefined(this.props.gig.id, 'Expected a Volunteer Opportunity id');

    if (_gigs.includes(this.props.gig.id)) {
      for (let g in _gigs) {
        if (this.props.gig.id === _gigs[g]) {
          _gigs.splice(parseInt(g), 1);
        }
      }
    } else {
      _gigs.push(this.props.gig.id);
    }

    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      this.props.updateGroupPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: uiConstants.ownerType.gig,
            object_ids: _gigs,
          },
        },
        this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
      );
    } else if (this.props.owner.ownerType === uiConstants.ownerType.event) {
      this.props.updateEventPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: uiConstants.ownerType.gig,
            object_ids: _gigs,
          },
        },
        this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
      );
    }
  }

  changeGigStatus(newStatus: string) {
    this.props.changeGigStatus(this.props.gig.id || '', newStatus, () => {
      if (newStatus === Constants.gig_status.archived) {
        this.updateVisibility();
      } else {
        this.props.owner.ownerType === uiConstants.ownerType.group
          ? this.props.getCurrentGroupPageComponents(this.props.page.owner_id, this.props.page.id)
          : this.props.getCurrentEventPageComponents(this.props.page.owner_id, this.props.page.id);
      }
    });
  }

  deleteGig() {
    this.props.changeGigStatus(this.props.gig.id || '', Constants.gig_status.archived);
    this.setState({ showDeletePrompt: false });

    this.updateVisibility();
  }

  render() {
    const isVisible = !!this.props.component.content_references?.object_ids?.includes(
      this.props.gig.id ?? '',
    );

    return (
      <div
        className={
          this.props.permissions &&
          (this.props.permissions['EDIT_GROUP_PAGES'] || this.props.permissions['EDIT_EVENT_PAGES'])
            ? isVisible
              ? 'GigListItem admin'
              : 'GigListItem admin hidden'
            : 'GigListItem'
        }
      >
        <div className="content">
          <Portrait
            size={80}
            currentImage={this.props.gig.profile_image_url}
          />
          <div className="info">
            <div
              className="start-date"
              notranslate="yes"
            >
              {localizeHelpers.formatDate(
                this.props.gig.start_date ?? new Date(),
                LocaleDateFormats.LL,
                this.props.locale,
              )}
            </div>
            <div
              className="title"
              notranslate="yes"
            >
              {this.props.gig.title}
            </div>
            <div
              className="group-name"
              notranslate="yes"
            >
              {this.props.owner.ownerType === uiConstants.ownerType.group
                ? this.props.groupState.group.title
                : this.props.eventState.event.title}
            </div>

            {this.props.permissions?.['MANAGE_GIGS'] && (
              <ChangeStatusMenu
                event={this.props.gig.event as any as IEventSummaryFE}
                className="change-status-dropdown"
                itemType={uiConstants.ownerType.gig}
                currentStatus={this.props.gig.status?.code}
                listOfStatuses={Object.values(Constants.gig_status)}
                onStatusChanged={(newStatus: string) => this.changeGigStatus(newStatus)}
              />
            )}
          </div>
          <div className="action">
            <Link to={`/${uiConstants.ownerType.gig}/` + this.props.gig.handle}>
              View Volunteer Opportunity
            </Link>
          </div>
        </div>
        <div className="cover">
          <CoverImage currentImage={this.props.gig.cover_image_url} />
        </div>
        <div className="list-admin-actions">
          <ul>
            {isVisible && this.props.gig.status?.code === Constants.gig_status.published && (
              <li
                onClick={() => {
                  this.updateVisibility();
                }}
              >
                <i className="fad fa-eye-slash" />
                <span>Hide</span>
              </li>
            )}
            {!isVisible && this.props.gig.status?.code === Constants.gig_status.published && (
              <li
                onClick={() => {
                  this.updateVisibility();
                }}
              >
                <i className="fad fa-eye" />
                <span>Show</span>
              </li>
            )}
            <li onClick={() => this.setState({ showDeletePrompt: true })}>
              <i className="fad fa-trash" /> <span>Delete</span>
            </li>
            <li {...this.props.dragProps}>
              <i className="fas fa-arrows-alt"></i>
              <span>Drag</span>
            </li>
          </ul>
        </div>

        <Prompt
          show={this.state.showDeletePrompt}
          title={`Delete gig ${this.props.gig.title}`}
          message="Volunteer Opportunity will be put in to an Archived Status. This can not be undone"
          yesMessage="Yes"
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage="Cancel"
          onYes={() => {
            this.deleteGig();
          }}
          onClose={() => {
            this.setState({ showDeletePrompt: false });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  updateGroupPageComponent,
  updateEventPageComponent,
  getCurrentGroupPageComponents,
  getCurrentEventPageComponents,
  changeGigStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(GigListItem);
