import { Reducer } from 'redux';
import { IUser } from '@gigit/interfaces';
import { UserSearchActions, UserSearchActionTypes } from '../actions/userSearch';

export interface IUserSearchState {
  userList: IUser[];
}

export const initialUserSearchState: IUserSearchState = {
  userList: [],
};

export const userSearchReducer: Reducer<IUserSearchState, UserSearchActions> = (
  state = initialUserSearchState,
  action,
) => {
  switch (action.type) {
    case UserSearchActionTypes.SEARCH_USER: {
      return {
        ...state,
        ...action,
      };
    }
    case UserSearchActionTypes.RESET_USER_SEARCH: {
      return {
        ...initialUserSearchState,
      };
    }
    default:
      return state;
  }
};
