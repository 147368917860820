import React, { Fragment, ReactNode, useRef, useState } from 'react';
import TitleSection, {
  ICtaConfig,
  ITabConfig,
  ITitleSectionTab,
} from '../TitleSection/TitleSection';
import './MyPages.scss';
import { IFollowingObject, IInviteSummary, IUserMyPageItem } from '@gigit/interfaces';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../shared/PopupMenu/PopupMenu';
import { useEffect } from 'react';
import { IAppState } from '../../../store';
import { userSelectors } from '../../../selectors/user';
import { useSelector } from 'react-redux';
import useToastDispatcher from '../../../hooks/useToaster';
import {
  eventRequestActions,
  gigRequestActions,
  groupRequestActions,
  hubRequestActions,
  inviteRequestActions,
  userRequestActions,
} from '../../../requestActions';
import {
  MyPagesItemGrid,
  MyPagesFollowedGrid,
  MyPagesItemList,
  MyInvitesGrid,
} from './MyPagesItem/MyPagesItem';
import { IUserState } from '../../../reducers/user';
import { Constants } from '@gigit/constants';
import { Prompt } from '../../Prompt/Prompt';
import EmptyStateImage from '../../../assets/community-feed-empty-state.png';
import { localizeHelpers } from '../../../localizeHelpers';
import { formatQuery, OwnerType, typeHelpers } from '../../../helpers';
import Share from '../../Share/Share';
import { uiConstants } from '../../../constants';
import { Config } from '@gigit/config';
import Modal from '../../Modal/Modal';
import CardLoader from '../../shared/CardLoader/CardLoader';
import {
  ECardLoaderStyleType,
  ECardLoaderViewType,
} from '../../shared/CardLoader/CardLoaderItem/CardLoaderItem';
import { useHistory, useLocation } from 'react-router-dom';

interface IProps {
  activeTab?: string;
  userState: IUserState;
}
interface IDropdownOption {
  label: string;
  value: string;
  icon?: string;
}

interface IEventTypeOptions {
  event: IDropdownOption;
  team: IDropdownOption;
  individual: IDropdownOption;
  all: IDropdownOption;
}

interface IVolunteerTypeOptions {
  volunteer: IDropdownOption;
  paid: IDropdownOption;
  all: IDropdownOption;
}

interface IStatesOptions {
  active: IDropdownOption;
  draft: IDropdownOption;
  all: IDropdownOption;
  pending?: IDropdownOption;
}

interface IEventFollowVolStates {
  published: IDropdownOption;
  draft: IDropdownOption;
  all: IDropdownOption;
}

interface ISortByOptions {
  name: IDropdownOption;
  status: IDropdownOption;
}

interface IFollowingSortByOptions {
  name: IDropdownOption;
  lastUpdated: IDropdownOption;
}

interface ISortInviteOptions {
  createdAt: IDropdownOption;
  name: IDropdownOption;
}

const MyPages: React.FC<IProps> = (props) => {
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const [activeTabId, setActiveTabId] = useState<string | null>();
  const statesDropdownRef = useRef<HTMLDivElement>(null);
  const sortByDropdownRef = useRef<HTMLDivElement>(null);
  const eventTypeDropdownRef = useRef<HTMLDivElement>(null);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const sortByInviteDropdownRef = useRef<HTMLDivElement>(null);
  const followingSortByDropdownRef = useRef<HTMLDivElement>(null);
  const eventFollowVolDropdownRef = useRef<HTMLDivElement>(null);
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const [loader, setLoader] = useState<boolean>(true);

  const eventTypeOptions: IEventTypeOptions = {
    event: { label: 'Event', value: Constants.pages_type.event },
    team: { label: 'Team', value: Constants.pages_type.team },
    individual: { label: 'Individual', value: Constants.pages_type.individual },
    all: { label: 'All', value: Constants.pages_type.all },
  };

  const volunteerTypeOptions: IVolunteerTypeOptions = {
    volunteer: { label: 'Volunteer', value: 'volunteer' },
    paid: { label: 'Paid', value: 'paid' },
    all: { label: 'All', value: 'all' },
  };

  const statesOptions: IStatesOptions = {
    active: { label: 'Active', value: 'active' },
    draft: { label: 'Draft', value: 'draft' },
    all: { label: 'All', value: 'all' },
  };

  const eventFollowVolStateOptions: IEventFollowVolStates = {
    published: { label: 'Published', value: 'published' },
    draft: { label: 'Draft', value: 'draft' },
    all: { label: 'All', value: 'all' },
  };

  const sortByOptions: ISortByOptions = {
    name: { label: 'Name', value: 'title' },
    status: { label: 'Status', value: 'status' },
  };

  const followingSortByOptions: IFollowingSortByOptions = {
    name: { label: 'Name', value: 'title' },
    lastUpdated: { label: 'Last Updated', value: 'updated_at' },
  };

  const sortByInviteOptions: ISortInviteOptions = {
    createdAt: { label: 'Created At', value: 'created_at' },
    name: { label: 'Name', value: 'object.title' },
  };

  const emptyStateMessages = [
    { key: 'group', message: "You haven't created any causes" },
    { key: 'fundraiser', message: "You haven't created any events " },
    { key: 'gig', message: "You haven't created any volunteer opportunities" },
    { key: 'hub', message: "You haven't joined or created any companies" },
    { key: 'pages_followed', message: "You aren't following any pages" },
    { key: 'invites', message: "You haven't received any invites yet" },
  ];

  const history = useHistory();
  const location = useLocation();

  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const [showFilterMobile, setShowFilterMobile] = useState<boolean>(false);
  const [view, setView] = useState<string>('List');
  const [pages_followed, setPagesFollowed] = useState<IFollowingObject[]>([]);
  const [pages, setPages] = useState<IUserMyPageItem[]>([]);
  const [invites, setInvites] = useState<IInviteSummary[]>([]);
  const [states, setStates] = useState<IDropdownOption>(statesOptions.all);
  const [sortBy, setSortBy] = useState<IDropdownOption>(sortByOptions.status);
  const [eventType, setEventType] = useState<IDropdownOption>(eventTypeOptions.all);
  const [volunteerType, setVolunteerType] = useState<IDropdownOption>(volunteerTypeOptions.all);
  const [inviteSortBy, setInviteSortBy] = useState<IDropdownOption>(sortByInviteOptions.createdAt);
  const [followingSortBy, setFollowingSortBy] = useState<IDropdownOption>(
    followingSortByOptions.lastUpdated,
  );
  const [eventState, setEventState] = useState<IDropdownOption>(eventFollowVolStateOptions.all);
  const [showContextMenuStates, setShowContextMenuStates] = useState<boolean>(false);
  const [showContextMenuSortBy, setShowContextMenuSortBy] = useState<boolean>(false);
  const getShowMenuClassStates = showContextMenuStates ? 'show' : '';
  const getShowMenuClassSortBy = showContextMenuSortBy ? 'show' : '';
  const [showContextMenuEventType, setShowContextMenuEventType] = useState<boolean>(false);
  const getShowMenuClassEventType = showContextMenuEventType ? 'show' : '';
  const [showContextMenuVolunteerType, setShowContextMenuVolunteerType] = useState<boolean>(false);

  const [showContextMenuInviteSortBy, setShowContextMenuInviteSortBy] = useState<boolean>(false);
  const getShowMenuInviteSortBy = showContextMenuInviteSortBy ? 'show' : '';

  const [showContextMenuFollowingSortBy, setShowContextMenuFollowingSortBy] =
    useState<boolean>(false);
  const getShowMenuClassFollowingSortBy = showContextMenuFollowingSortBy ? 'show' : '';
  const [showContextMenuEventState, setShowContextMenuEventState] = useState<boolean>(false);
  const getShowMenuEventState = showContextMenuEventState ? 'show' : '';

  let [pageToDelete, setPageToDelete] = useState<IUserMyPageItem>();
  let [pageToShare, setPageToShare] = useState<IUserMyPageItem>();

  const tabs: ITitleSectionTab[] = [
    { id: 'group', title: 'Causes' },
    { id: 'event', title: 'Events' },
    { id: 'gig', title: 'Volunteering' },
    { id: 'hub', title: 'Companies' },
    { id: 'pages_followed', title: 'Following' },
    { id: 'invites', title: 'Invites', notification_amt: invites.length },
  ];

  async function deletePage(pageToDelete: IUserMyPageItem | null) {
    if (pageToDelete) {
      switch (pageToDelete.item_type) {
        case Constants.object_type.group:
          await changeGroupStatus(
            pageToDelete.id,
            Constants.group_status.archived,
            pageToDelete.title,
          );
          break;
        case Constants.object_type.event:
          await changeEventStatus(pageToDelete.id, Constants.event_status.archived);
          break;
        case 'volunteer gig' || 'paid gig':
          await changeGigStatus(pageToDelete.id, Constants.gig_status.archived);
          break;
        case Constants.object_type.hub:
          await changeCompanyStatus(pageToDelete.id, Constants.hub_status.archived);
          break;
      }
    }
  }

  async function changeCompanyStatus(id: string, status: string) {
    try {
      await hubRequestActions.updateHubStatus(id, status);
      dispatchToastSuccess(
        localizeHelpers.translate('Company page successfully deleted'),
        'Company Status',
      );
      getUserMyPageItems();
    } catch (error) {
      dispatchToastError(error, 'Company Status');
    }
  }

  async function changeGroupStatus(id: string, status: string, title: string) {
    try {
      const updatedGroup = await groupRequestActions.changeGroupStatus(id, status, title);
      getUserMyPageItems();
    } catch (error) {
      dispatchToastError(error, 'Cause Status');
    }
  }

  async function changeEventStatus(id: string, status: string) {
    try {
      const updatedGroup = await eventRequestActions.changeEventStatus(id, status);
      getUserMyPageItems();
    } catch (error) {
      dispatchToastError(error, 'Event Status');
    }
  }

  async function changeGigStatus(id: string, status: string) {
    try {
      const updatedGroup = await gigRequestActions.changeGigStatus(id, status);
      getUserMyPageItems();
    } catch (error) {
      dispatchToastError(error, 'Volunteer Opportunity Status');
    }
  }

  function getDeleteMessage(pageToDelete: IUserMyPageItem | null): string {
    if (pageToDelete !== null) {
      let tmpTxt: string;
      switch (pageToDelete.item_type) {
        case Constants.object_type.group:
          tmpTxt =
            'All events and volunteer opportunities for this cause will also be put into an Archived status. This can not be undone.';
          break;
        case Constants.object_type.event:
          tmpTxt =
            'All volunteer opportunities for this even will also be put into an Archived status. This can not be undone.';
          break;
        case 'volunteer gig' || 'paid gig':
          tmpTxt =
            'Volunteer Opportunity will be put into an Archived status. This can not be undone.';
          break;
        case Constants.object_type.hub:
          tmpTxt = 'Company page will be deleted. This can not be undone.';
          break;
        default:
          tmpTxt = 'Page will be archived';
      }
      return tmpTxt;
    } else {
      return '';
    }
  }

  function renderNoDataMessage() {
    const selectedOption = emptyStateMessages.find((message) => message.key === activeTabId);
    if (selectedOption) {
      return (
        <>
          <p>
            {' '}
            <img
              src={EmptyStateImage}
              alt="empty"
            />
          </p>
          <p className="top-message"> {selectedOption.message}</p>
        </>
      );
    } else {
      return <p>No items found</p>;
    }
  }

  const ctaConfig: ICtaConfig = {
    buttonText: 'Create New',
    buttonIcon: 'fa fa-plus',
    onClick: () => {},
  };

  // TODO: We should clean up this func/view when we can
  async function getUserMyPageItems() {
    if (activeTabId) {
      // HANDLE FILTER LOGIC - SHOULD PROBABLY EXTRACT TO FUNC
      setLoader(true);
      let filters: any = [];

      // Handles status on group
      if (activeTabId === 'group' && states.value !== 'all') {
        filters.push({ value: states.value, id: 'status', type: 'text', label: 'status' });
      }
      // handle Fundraiser Type and status On Event MyPages
      if (activeTabId === 'event') {
        const eventTypeVal = eventType.value === 'all' ? '' : eventType.value;
        if (eventTypeVal) {
          filters = [{ value: eventTypeVal, id: 'item_type', type: 'text', label: 'item_type' }];
        }

        if (eventState.value !== 'all') {
          filters.push({ value: eventState.value, id: 'status', type: 'text', label: 'status' });
        }
      }
      // Handle Gig
      if (activeTabId === 'gig') {
        if (eventState.value !== 'all') {
          filters.push({ value: eventState.value, id: 'status', type: 'text', label: 'status' });
        }
      }

      // Handle Hubs
      if (activeTabId === 'hub') {
        if (states.value !== 'all') {
          filters.push({ value: states.value, id: 'status', type: 'text', label: 'status' });
        }
      }
      // always include sort value
      let params: any = {
        sort: [{ id: sortBy.value, order: 'asc' }],
      };
      // set filters if they exist
      if (filters.length >= 1) {
        params.filters = filters;
      }
      //// End Filter handling

      // Format Query For Get Request
      params = formatQuery(params);
      if (isUserLoggedIn) {
        let tabId = activeTabId === 'event' ? 'fundraiser' : activeTabId;
        try {
          const result = await userRequestActions.getUserMyPageItems(params, tabId);
          setPages(result);
        } catch (error) {
          dispatchToastError(error, 'Get User MyPage Items');
        } finally {
          setLoader(false);
        }
      }
    }
  }

  const getUserPagesFollowing = (): void => {
    setLoader(true);
    let filters = [];
    let params: any = {
      sort: [{ id: followingSortBy.value, order: 'asc' }],
    };

    if (eventState.value !== 'all') {
      filters.push({ value: eventState.value, id: 'status', type: 'text', label: 'status' });
    }

    if (filters.length >= 1) {
      params.filters = filters;
    }

    params = formatQuery(params);
    isUserLoggedIn &&
      userRequestActions
        .getUserPagesFollowing(params)
        .then((res) => {
          setPagesFollowed(res);
        })
        .catch((error) => dispatchToastError(error, 'Get User Pages Followed'))
        .finally(() => {
          setLoader(false);
        });
  };

  const getUserInvites = (): void => {
    setLoader(true);
    isUserLoggedIn &&
      userRequestActions
        .getUserInvites(inviteSortBy.value)
        .then((res) => {
          setInvites(res);
        })
        .catch((error) => dispatchToastError(error, 'Get Invites'))
        .finally(() => {
          setLoader(false);
        });
  };

  useEffect(() => {
    getUserInvites();
  }, []);

  useEffect(() => {
    if (isUserLoggedIn) {
      switch (activeTabId) {
        case 'pages_followed':
          getUserPagesFollowing();
          break;
        case 'group':
        case 'event':
        case 'gig':
        case 'hub':
          getUserMyPageItems();
          break;
        case 'invites':
          getUserInvites();
          break;
      }
    }
  }, [
    states,
    activeTabId,
    sortBy,
    eventState,
    volunteerType,
    inviteSortBy,
    followingSortBy,
    eventType.value,
  ]);

  useEffect(() => {
    setActiveTabId(props.activeTab || 'group');
  }, [props.activeTab]);

  const popupMenuConfigStates: IShowPopupConfig = {
    showMenu: showContextMenuStates,
    setShowMenu: setShowContextMenuStates,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItemsStates: IPopupMenuItem[] = [
    {
      id: statesOptions.active.value,
      label: statesOptions.active.label,
      isSelected: states.value === statesOptions.active.value,
      onClick: () => {
        setStates(statesOptions.active);
        setShowContextMenuStates(false);
      },
    },
    {
      id: statesOptions.draft.value,
      label: statesOptions.draft.label,
      isSelected: states.value === statesOptions.draft.value,
      onClick: () => {
        setStates(statesOptions.draft);
        setShowContextMenuStates(false);
      },
    },
    {
      id: statesOptions.all.value,
      label: statesOptions.all.label,
      isSelected: states.value === statesOptions.all.value,
      onClick: () => {
        setStates(statesOptions.all);
        setShowContextMenuStates(false);
      },
    },
  ];

  const contextMenuItemsStatesHub: IPopupMenuItem[] = [...contextMenuItemsStates];

  const popupMenuConfigSortBy: IShowPopupConfig = {
    showMenu: showContextMenuSortBy,
    setShowMenu: setShowContextMenuSortBy,
    position: {
      type: 'bottom',
    },
  };
  const contextMenuItemsSortBy: IPopupMenuItem[] = [
    {
      id: sortByOptions.name.value,
      label: sortByOptions.name.label,
      isSelected: sortBy.value === sortByOptions.name.value,
      onClick: () => {
        setSortBy(sortByOptions.name);
        setShowContextMenuSortBy(false);
      },
    },
    {
      id: sortByOptions.status.value,
      label: sortByOptions.status.label,
      isSelected: sortBy.value === sortByOptions.status.value,
      onClick: () => {
        setSortBy(sortByOptions.status);
        setShowContextMenuSortBy(false);
      },
    },
  ];

  const popupMenuConfigEventType: IShowPopupConfig = {
    showMenu: showContextMenuEventType,
    setShowMenu: setShowContextMenuEventType,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItemsEventType: IPopupMenuItem[] = [
    {
      id: eventTypeOptions.event.value,
      label: eventTypeOptions.event.label,
      isSelected: eventType.value === eventTypeOptions.event.value,
      onClick: () => {
        setEventType(eventTypeOptions.event);
        setShowContextMenuEventType(false);
      },
    },
    {
      id: eventTypeOptions.team.value,
      label: eventTypeOptions.team.label,
      isSelected: eventType.value === eventTypeOptions.team.value,
      onClick: () => {
        setEventType(eventTypeOptions.team);
        setShowContextMenuEventType(false);
      },
    },
    {
      id: eventTypeOptions.individual.value,
      label: eventTypeOptions.individual.label,
      isSelected: eventType.value === eventTypeOptions.individual.value,
      onClick: () => {
        setEventType(eventTypeOptions.individual);
        setShowContextMenuEventType(false);
      },
    },
    {
      id: eventTypeOptions.all.value,
      label: eventTypeOptions.all.label,
      isSelected: eventType.value === eventTypeOptions.all.value,
      onClick: () => {
        setEventType(eventTypeOptions.all);
        setShowContextMenuEventType(false);
      },
    },
  ];

  const popupMenuConfigVolunteerType: IShowPopupConfig = {
    showMenu: showContextMenuVolunteerType,
    setShowMenu: setShowContextMenuVolunteerType,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItemsVolunteerType: IPopupMenuItem[] = [
    {
      id: volunteerTypeOptions.volunteer.value,
      label: volunteerTypeOptions.volunteer.label,
      isSelected: volunteerType.value === volunteerTypeOptions.volunteer.value,
      onClick: () => {
        setVolunteerType(volunteerTypeOptions.volunteer);
        setShowContextMenuVolunteerType(false);
      },
    },
    {
      id: volunteerTypeOptions.paid.value,
      label: volunteerTypeOptions.paid.label,
      isSelected: volunteerType.value === volunteerTypeOptions.paid.value,
      onClick: () => {
        setVolunteerType(volunteerTypeOptions.paid);
        setShowContextMenuVolunteerType(false);
      },
    },
    {
      id: volunteerTypeOptions.all.value,
      label: volunteerTypeOptions.all.label,
      isSelected: volunteerType.value === volunteerTypeOptions.all.value,
      onClick: () => {
        setVolunteerType(volunteerTypeOptions.all);
        setShowContextMenuVolunteerType(false);
      },
    },
  ];

  const popupMenuConfigInviteSortBy: IShowPopupConfig = {
    showMenu: showContextMenuInviteSortBy,
    setShowMenu: setShowContextMenuInviteSortBy,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItemsInviteSortBy: IPopupMenuItem[] = [
    {
      id: sortByInviteOptions.createdAt.value,
      label: sortByInviteOptions.createdAt.label,
      isSelected: inviteSortBy.value === sortByInviteOptions.createdAt.value,
      onClick: () => {
        setInviteSortBy(sortByInviteOptions.createdAt);
        setShowContextMenuInviteSortBy(false);
      },
    },
    {
      id: sortByInviteOptions.name.value,
      label: sortByInviteOptions.name.label,
      isSelected: inviteSortBy.value === sortByInviteOptions.name.value,
      onClick: () => {
        setInviteSortBy(sortByInviteOptions.name);
        setShowContextMenuInviteSortBy(false);
      },
    },
  ];

  const popupMenuConfigFollowingSortBy: IShowPopupConfig = {
    showMenu: showContextMenuFollowingSortBy,
    setShowMenu: setShowContextMenuFollowingSortBy,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItemsFollowingSortBy: IPopupMenuItem[] = [
    {
      id: followingSortByOptions.name.value,
      label: followingSortByOptions.name.label,
      isSelected: followingSortBy.value === followingSortByOptions.name.value,
      onClick: () => {
        setFollowingSortBy(followingSortByOptions.name);
        setShowContextMenuFollowingSortBy(false);
      },
    },
    {
      id: followingSortByOptions.lastUpdated.value,
      label: followingSortByOptions.lastUpdated.label,
      isSelected: followingSortBy.value === followingSortByOptions.lastUpdated.value,
      onClick: () => {
        setFollowingSortBy(followingSortByOptions.lastUpdated);
        setShowContextMenuFollowingSortBy(false);
      },
    },
  ];

  const popupMenuConfigEventState: IShowPopupConfig = {
    showMenu: showContextMenuEventState,
    setShowMenu: setShowContextMenuEventType,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItemsEventState: IPopupMenuItem[] = [
    {
      id: eventFollowVolStateOptions.published.value,
      label: eventFollowVolStateOptions.published.label,
      isSelected: eventState.value === eventFollowVolStateOptions.published.value,
      onClick: () => {
        setEventState(eventFollowVolStateOptions.published);
        setShowContextMenuEventState(false);
      },
    },
    {
      id: eventFollowVolStateOptions.draft.value,
      label: eventFollowVolStateOptions.draft.label,
      isSelected: eventState.value === eventFollowVolStateOptions.draft.value,
      onClick: () => {
        setEventState(eventFollowVolStateOptions.draft);
        setShowContextMenuEventState(false);
      },
    },
    {
      id: eventFollowVolStateOptions.all.value,
      label: eventFollowVolStateOptions.all.label,
      isSelected: eventState.value === eventFollowVolStateOptions.all.value,
      onClick: () => {
        setEventState(eventFollowVolStateOptions.all);
        setShowContextMenuEventState(false);
      },
    },
  ];

  const handleInviteAccept = (invite: IInviteSummary) => {
    const { title } = invite.object;
    if (invite.object_type === 'user') {
      userRequestActions
        .acceptContactRequest(invite.object_id)
        .then(() => {
          dispatchToastSuccess(
            localizeHelpers.translate('Accepted an invite from {{title}}', { title }),
            'Invite',
          );
          getUserInvites();
        })
        .catch((error) => {
          dispatchToastError(error, 'Invite');
        });
    } else {
      inviteRequestActions
        .acceptInvite(invite.object_type, invite.object_id, invite.id)
        .then((response) => {
          dispatchToastSuccess(
            localizeHelpers.translate('Accepted an invite from {{title}}', { title }),
            'Invite',
          );
          getUserInvites();
        })
        .catch((error) => {
          dispatchToastError(error, 'Invite');
        });
    }
  };

  const handleInviteReject = (invite: IInviteSummary) => {
    const { title } = invite.object;
    if (invite.object_type === 'user') {
      userRequestActions
        .rejectContactRequest(invite.object_id)
        .then(() => {
          dispatchToastSuccess(
            localizeHelpers.translate('Rejected an invite from {{title}}', { title }),
            'Invite',
          );
          getUserInvites();
        })
        .catch((error) => {
          dispatchToastError(error, 'Invite');
        });
    } else {
      inviteRequestActions
        .rejectInvite(invite.object_type, invite.object_id, invite.id)
        .then((response) => {
          dispatchToastSuccess(
            localizeHelpers.translate('Rejected an invite from {{title}}', { title }),
            'Invite',
          );
          getUserInvites();
        })
        .catch((error) => {
          dispatchToastError(error, 'Invite');
        });
    }
  };

  // TODO: remove condition after all cards is refactored
  if (
    activeTabId !== 'gig' &&
    activeTabId !== 'hub' &&
    activeTabId !== 'event' &&
    view !== 'Grid'
  ) {
    setView('Grid');
  }

  function renderItem() {
    let toRender = [] as ReactNode[];
    switch (activeTabId) {
      case 'pages_followed':
        pages_followed.map((page, index) => {
          toRender.push(
            view === 'Grid' ? (
              <MyPagesFollowedGrid
                pagesFollowed={page}
                keyValue={`grid-item-${index}`}
                key={index}
                locale={locale}
                refreshPage={getUserPagesFollowing}
              />
            ) : (
              <>{/*TODO*/}</>
            ),
          );
        });
        break;
      case 'invites':
        invites.map((invite, index) => {
          toRender.push(
            view === 'Grid' ? (
              <MyInvitesGrid
                invite={invite}
                keyValue={`grid-item-${index}`}
                key={index}
                locale={locale}
                handleAccept={(invite: IInviteSummary) => handleInviteAccept(invite)}
                handleReject={(invite: IInviteSummary) => handleInviteReject(invite)}
              />
            ) : (
              <>{/*TODO*/}</>
            ),
          );
        });
        break;
      case 'group':
      case 'event':
      case 'gig':
      case 'hub':
      default:
        pages.map((page, index) => {
          toRender.push(
            view === 'Grid' ? (
              <MyPagesItemGrid
                myPageItem={page}
                keyValue={`grid-item-${index}`}
                key={index}
                locale={locale}
                pageType={page.item_type}
                deleteItem={(myPageItem: IUserMyPageItem) => {
                  setPageToDelete(myPageItem);
                }}
                shareItem={(myPageItem: IUserMyPageItem) => {
                  setPageToShare(myPageItem);
                  setShowShareModal(true);
                }}
              />
            ) : (
              <MyPagesItemList
                myPageItem={page}
                keyValue={`list-item-${index}`}
                key={index}
                locale={locale}
                deleteItem={(myPageItem: IUserMyPageItem) => {
                  setPageToDelete(myPageItem);
                }}
                shareItem={(myPageItem: IUserMyPageItem) => {
                  setPageToShare(myPageItem);
                  setShowShareModal(true);
                }}
              />
            ),
          );
        });
        break;
    }

    return toRender;
  }

  return (
    <Fragment>
      {activeTabId && (
        <TitleSection
          title="My Pages"
          tabConfig={{
            tabs: tabs,
            activeTabId: activeTabId,
            onTabClick: (v) =>
              history.push({ pathname: location.pathname, search: `section=pages&tab=${v}` }),
          }}
        />
      )}
      <div className="MyPages">
        <div className={`mobile-filter-container ${showFilterMobile ? 'active' : ''}`}>
          <div className="filter-header">
            <i className="far fa-filter" />
            <span>Filter</span>
          </div>
          <div className="filter-content">
            <span>Sort By</span>
            {contextMenuItemsSortBy.map((item, index) => (
              <div
                className={`filter-item ${sortBy.value === item.id ? 'active' : ''}`}
                key={index}
                onClick={() => {
                  setSortBy({
                    value: item.id,
                    label: item.label || '',
                  });
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
          <div className="filter-content">
            <span>Type</span>
            {contextMenuItemsSortBy.map((item, index) => (
              <div
                className={`filter-item ${sortBy.value === item.id ? 'active' : ''}`}
                key={index}
                onClick={() => {
                  setSortBy({
                    value: item.id,
                    label: item.label || '',
                  });
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
        <div className="mobile-filter-button">
          <span
            onClick={() => {
              setShowFilterMobile(true);
            }}
          >
            <i className="far fa-filter" />
          </span>
        </div>
        <div className="section-wrap">
          {activeTabId === 'group' && (
            <div className="filter-container">
              <PopupMenu
                showMenuConfig={popupMenuConfigStates}
                menuItems={contextMenuItemsStates}
                popupMenuClass="mypages-popupMenu-states"
                className={`Dropdown filter-dropdown states ${getShowMenuClassStates}`}
                onClick={() => setShowContextMenuStates(!showContextMenuStates)}
                width={
                  statesDropdownRef.current?.clientWidth
                    ? statesDropdownRef.current.clientWidth - 38
                    : undefined
                }
              >
                <label>
                  <span>States</span>
                </label>
                <div
                  className="input-wrap"
                  ref={statesDropdownRef}
                >
                  <span className="label">{states.label}</span>
                  <i className={`fas fa-caret-down menu-btn ${getShowMenuClassStates}`} />
                </div>
              </PopupMenu>
              <PopupMenu
                showMenuConfig={popupMenuConfigSortBy}
                menuItems={contextMenuItemsSortBy}
                popupMenuClass="mypages-popupMenu-sortBy"
                className={`Dropdown filter-dropdown sortBy ${getShowMenuClassSortBy}`}
                onClick={() => setShowContextMenuSortBy(!showContextMenuSortBy)}
                width={
                  sortByDropdownRef.current?.clientWidth
                    ? sortByDropdownRef.current.clientWidth - 38
                    : undefined
                }
              >
                <label>
                  <span>Sort By</span>
                </label>
                <div className="input-wrap">
                  <span className="label">{sortBy.label}</span>
                  <i className={`fas fa-caret-down menu-btn ${getShowMenuClassSortBy}`} />
                </div>
              </PopupMenu>
            </div>
          )}

          {activeTabId === 'event' && (
            <div className="filter-container">
              <PopupMenu
                showMenuConfig={popupMenuConfigEventType}
                menuItems={contextMenuItemsEventType}
                popupMenuClass="mypages-popupMenu-eventType"
                className={`Dropdown filter-dropdown eventType ${getShowMenuClassEventType}`}
                onClick={() => setShowContextMenuEventType(!showContextMenuEventType)}
                width={
                  eventTypeDropdownRef.current?.clientWidth
                    ? eventTypeDropdownRef.current.clientWidth - 38
                    : undefined
                }
              >
                <label>
                  <span>Fundraiser Type</span>
                </label>
                <div
                  className="input-wrap"
                  ref={eventTypeDropdownRef}
                >
                  <span className="label">{eventType.label}</span>
                  <i className={`fas fa-caret-down menu-btn ${getShowMenuClassEventType}`} />
                </div>
              </PopupMenu>

              <PopupMenu
                showMenuConfig={popupMenuConfigEventState}
                menuItems={contextMenuItemsEventState}
                popupMenuClass="mypages-popupMenu-eventState"
                className={`Dropdown filter-dropdown eventState ${getShowMenuEventState}`}
                onClick={() => setShowContextMenuEventState(!showContextMenuEventState)}
                width={
                  eventFollowVolDropdownRef.current?.clientWidth
                    ? eventFollowVolDropdownRef.current.clientWidth - 38
                    : undefined
                }
              >
                <label>
                  <span>States</span>
                </label>
                <div
                  className="input-wrap"
                  ref={eventFollowVolDropdownRef}
                >
                  <span className="label">{eventState.label}</span>
                  <i className={`fas fa-caret-down menu-btn ${getShowMenuEventState}`} />
                </div>
              </PopupMenu>
            </div>
          )}
          {activeTabId === 'gig' && (
            <div className="filter-container">
              <PopupMenu
                showMenuConfig={popupMenuConfigEventState}
                menuItems={contextMenuItemsEventState}
                popupMenuClass="mypages-popupMenu-eventState"
                className={`Dropdown filter-dropdown eventState ${getShowMenuEventState}`}
                onClick={() => setShowContextMenuEventState(!showContextMenuEventState)}
                width={
                  eventFollowVolDropdownRef.current?.clientWidth
                    ? eventFollowVolDropdownRef.current.clientWidth - 38
                    : undefined
                }
              >
                <label>
                  <span>States</span>
                </label>
                <div
                  className="input-wrap"
                  ref={eventFollowVolDropdownRef}
                >
                  <span className="label">{eventState.label}</span>
                  <i className={`fas fa-caret-down menu-btn ${getShowMenuEventState}`} />
                </div>
              </PopupMenu>

              <PopupMenu
                showMenuConfig={popupMenuConfigSortBy}
                menuItems={contextMenuItemsSortBy}
                popupMenuClass="mypages-popupMenu-sortBy"
                className={`Dropdown filter-dropdown sortBy ${getShowMenuClassSortBy}`}
                onClick={() => setShowContextMenuSortBy(!showContextMenuSortBy)}
                width={
                  sortByDropdownRef.current?.clientWidth
                    ? sortByDropdownRef.current.clientWidth - 38
                    : undefined
                }
              >
                <label>
                  <span>Sort By</span>
                </label>
                <div className="input-wrap">
                  <span className="label">{sortBy.label}</span>
                  <i className={`fas fa-caret-down menu-btn ${getShowMenuClassSortBy}`} />
                </div>
              </PopupMenu>
            </div>
          )}
          {activeTabId === 'hub' && (
            <div className="filter-container">
              <PopupMenu
                showMenuConfig={popupMenuConfigStates}
                menuItems={contextMenuItemsStatesHub}
                popupMenuClass="mypages-popupMenu-states"
                className={`Dropdown filter-dropdown states ${getShowMenuClassStates}`}
                onClick={() => setShowContextMenuStates(!showContextMenuStates)}
                width={
                  statesDropdownRef.current?.clientWidth
                    ? statesDropdownRef.current.clientWidth - 38
                    : undefined
                }
              >
                <label>
                  <span>States</span>
                </label>
                <div
                  className="input-wrap"
                  ref={statesDropdownRef}
                >
                  <span className="label">{states.label}</span>
                  <i className={`fas fa-caret-down menu-btn ${getShowMenuClassStates}`} />
                </div>
              </PopupMenu>
              <PopupMenu
                showMenuConfig={popupMenuConfigSortBy}
                menuItems={contextMenuItemsSortBy}
                popupMenuClass="mypages-popupMenu-sortBy"
                className={`Dropdown filter-dropdown sortBy ${getShowMenuClassSortBy}`}
                onClick={() => setShowContextMenuSortBy(!showContextMenuSortBy)}
                width={
                  sortByDropdownRef.current?.clientWidth
                    ? sortByDropdownRef.current.clientWidth - 38
                    : undefined
                }
              >
                <label>
                  <span>Sort By</span>
                </label>
                <div className="input-wrap">
                  <span className="label">{sortBy.label}</span>
                  <i className={`fas fa-caret-down menu-btn ${getShowMenuClassSortBy}`} />
                </div>
              </PopupMenu>
            </div>
          )}
          {activeTabId === 'pages_followed' && (
            <div className="filter-container">
              <PopupMenu
                showMenuConfig={popupMenuConfigFollowingSortBy}
                menuItems={contextMenuItemsFollowingSortBy}
                popupMenuClass="mypages-popupMenu-followingSortBy"
                className={`Dropdown filter-dropdown followingSortBy ${getShowMenuClassFollowingSortBy}`}
                onClick={() => setShowContextMenuFollowingSortBy(!showContextMenuFollowingSortBy)}
                width={
                  followingSortByDropdownRef.current?.clientWidth
                    ? followingSortByDropdownRef.current.clientWidth - 38
                    : undefined
                }
              >
                <label>
                  <span>Sort By</span>
                </label>
                <div className="input-wrap">
                  <span className="label">{followingSortBy.label}</span>
                  <i className={`fas fa-caret-down menu-btn ${getShowMenuClassFollowingSortBy}`} />
                </div>
              </PopupMenu>
            </div>
          )}
          {activeTabId === 'invites' && (
            <div className="filter-container">
              <PopupMenu
                showMenuConfig={popupMenuConfigInviteSortBy}
                menuItems={contextMenuItemsInviteSortBy}
                popupMenuClass="mypages-popupMenu-sortBy"
                className={`Dropdown filter-dropdown sortBy ${getShowMenuInviteSortBy}`}
                onClick={() => setShowContextMenuInviteSortBy(!showContextMenuInviteSortBy)}
                width={
                  sortByInviteDropdownRef.current?.clientWidth
                    ? sortByInviteDropdownRef.current.clientWidth - 38
                    : undefined
                }
              >
                <label>
                  <span>Sort By</span>
                </label>
                <div className="input-wrap">
                  <span className="label">{inviteSortBy.label}</span>
                  <i className={`fas fa-caret-down menu-btn ${getShowMenuInviteSortBy}`} />
                </div>
              </PopupMenu>
            </div>
          )}

          <div className="section-inner-no-data">
            {loader && (
              <div className={`loader-view ${activeTabId}`}>
                <CardLoader
                  loaderWrapperClass="loaderItemContainer"
                  loaderItemClass="loaderItem"
                  loading={loader}
                  numberOfRows={1}
                  numberOfCols={4}
                  styleTypes={[
                    ECardLoaderStyleType.PROFILE_IMG,
                    ECardLoaderStyleType.SHORT_TEXT,
                    ECardLoaderStyleType.TAGS,
                  ]}
                  viewType={view === 'Grid' ? ECardLoaderViewType.GRID : ECardLoaderViewType.LIST}
                />
              </div>
            )}

            {!loader &&
              pages.length === 0 &&
              pages_followed.length === 0 &&
              invites.length === 0 && <div className="empty-state">{renderNoDataMessage()}</div>}
          </div>

          {!loader &&
            (pages.length !== 0 || pages_followed.length !== 0 || invites.length !== 0) && (
              <div className="content-container">
                {pages.length !== 0 && (
                  <div className={`${view === 'Grid' ? 'grid-view' : 'list-view'} ${activeTabId}`}>
                    {renderItem()}
                  </div>
                )}

                <Prompt
                  show={pageToDelete != null}
                  title={`Delete ${pageToDelete !== null ? `${pageToDelete?.item_type === 'hub' ? 'Company' : pageToDelete?.item_type}` : ''}`}
                  message={getDeleteMessage(pageToDelete || null)}
                  yesMessage="Yes"
                  yesClass="fa fa-trash"
                  yesStyle="delete"
                  cancelMessage="Cancel"
                  onYes={async () => await deletePage(pageToDelete || null)}
                  onClose={() => {
                    setPageToDelete(undefined);
                  }}
                />
              </div>
            )}
        </div>
      </div>

      {
        <Modal
          class="share-modal"
          show={showShareModal}
          onClose={() => setShowShareModal(false)}
        >
          <Share
            owner_object={typeHelpers.createOwnerObject(pageToShare?.item_type as OwnerType, {})}
            url={`${Config.web.REACT_APP_BASE_URL}/${pageToShare?.item_type}/${pageToShare?.handle}`}
          />
        </Modal>
      }
    </Fragment>
  );
};

export default MyPages;
