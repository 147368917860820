import { Constants } from '@gigit/constants';
import { IPageContentBlockItem } from '@gigit/interfaces';
import React, { useEffect, useRef, useState } from 'react';
import { uiConstants } from '../../../constants';
import { isMobileScreen } from '../../../helpers';
import QuillTextEditor from '../../QuillTextEditor/QuillTextEditor';
import { EditOverlayParams } from '../Hub';
import ContentBlockManageModal from '../HubModals/ContentBlockModals/ContentBlockManageModal/ContentBlockManageModal';
import SingleMetric, { IPropsSingleMetric } from '../SingleMetric/SingleMetric';
import './ContentBlock.scss';

export type EditableArticle = {
  isEditable: boolean;
  onEdit: () => void;
};

export type ContentBlockTypes = keyof typeof Constants.page_content_block_item_type;

export interface IContentBlockProps {
  contentBlocks: IPageContentBlockItem[];
  isEditable?: () => boolean;
  isPreviewMode?: boolean;
}

//Type helper for MetricData
type MetricData = IPageContentBlockItem['metric_data'];

function ContentBlock(props: IContentBlockProps) {
  const { contentBlocks } = props;

  function tryGetContentBlockByType(pageContentBlock: IPageContentBlockItem) {
    const contentBlockType = Constants.page_content_block_item_type;
    const contentBlockStringType = pageContentBlock.type;

    switch (contentBlockStringType) {
      case contentBlockType.text:
        return {
          text: pageContentBlock.text,
          render: () => renderTextBlock(pageContentBlock.text),
        };
      case contentBlockType.image:
        return {
          imageSrc: pageContentBlock.media_url,
          render: () => renderImageBlock(pageContentBlock.media_url),
        };
      case contentBlockType.metrics:
        return {
          metrics: pageContentBlock.metrics,
          render: () => renderMetricsBlock(pageContentBlock.metric_data),
        };
      case contentBlockType.partners: {
        const profileImages = pageContentBlock.partner_data?.map(
          (group) => group.profile_image_url,
        );
        return {
          profileImages: profileImages,
          render: () => renderProfileImagesBlock(profileImages),
        };
      }
      default:
        return null;
    }
  }

  function getIsPreviewModeClass(isPreviewMode?: boolean) {
    return isPreviewMode ? 'preview-mode' : '';
  }

  function getIsMobileViewClass() {
    return isMobileScreen() ? 'mobile' : '';
  }

  function renderImageBlock(imgSrc?: string) {
    function renderImageBlockPlaceholder() {
      return (
        <div className="image-block-placeholder">
          <i className="fas fa-image" />
        </div>
      );
    }

    if (imgSrc) {
      return (
        <div className="image-block">
          <img
            src={imgSrc}
            alt="article image"
          />
        </div>
      );
    } else {
      return renderImageBlockPlaceholder();
    }
  }

  function renderTextBlock(text?: string) {
    return (
      <div className="text-block">
        <QuillTextEditor
          value={text}
          preserveWhitespace={true}
          readOnly={true}
          theme={uiConstants.quill.readOnlyTheme}
        />
      </div>
    );
  }

  function renderProfileImagesBlock(profileImagesUrls?: string[]) {
    function renderProfileImagePlaceholder() {
      return <div className="profile-image-placeholder"></div>;
    }

    return profileImagesUrls?.map((imageURL, idx) => {
      if (imageURL) {
        return (
          <div key={idx}>
            <img
              src={imageURL}
              alt="profile image"
            />
          </div>
        );
      } else {
        return renderProfileImagePlaceholder();
      }
    });
  }

  function renderMetricsBlock(metrics?: MetricData) {
    return (
      <div className="metrics-container">
        {metrics?.map((metric, index) => {
          return (
            <SingleMetric
              key={index}
              value={metric.value.toString()}
              name={metric.metric}
            />
          );
        })}
      </div>
    );
  }

  function renderContentBlocks() {
    let shouldReverseBlocks = false;
    const _contentBlocks = contentBlocks.map((content, idx) => {
      const block = tryGetContentBlockByType(content);
      const getHasTitleClass = content.title ? 'has-title' : '';

      //Reverses the content blocks order if it's a mobile screen
      if (
        content.type === Constants.page_content_block_item_type.image &&
        idx === 0 &&
        isMobileScreen(700)
      ) {
        shouldReverseBlocks = true;
      }

      return (
        <div
          key={idx}
          className={`content-container`}
          notranslate="yes"
        >
          {content.title && <h2 className="title">{content.title}</h2>}
          <div className={`block-content ${getHasTitleClass}`}>{block?.render()}</div>
        </div>
      );
    });

    if (shouldReverseBlocks) {
      _contentBlocks.reverse();
    }

    return _contentBlocks;
  }

  return (
    <article
      className={`ContentBlockContainer ${getIsPreviewModeClass(props.isPreviewMode)} ${getIsMobileViewClass()}`}
    >
      {renderContentBlocks()}
    </article>
  );
}

export default ContentBlock;
