import React, { createContext, useEffect, useRef, useState } from 'react';
import useSocket from '../components/Chat/hooks/useSocket';
import { Config } from '@gigit/config';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../store';
import { userSelectors } from '../selectors/user';
import { IConversation } from '@gigit/interfaces';
import { receiveMessage } from '../actions/chat';
import io from 'socket.io-client';

interface IMessageContextState {
  socket?: SocketIOClient.Socket;
}

interface IMessageContext {
  state: IMessageContextState;
  setState: React.Dispatch<React.SetStateAction<IMessageContextState>>;
}

const initState = {};

const MessageContext = createContext<IMessageContext>({ state: initState, setState: () => {} });
const MessageContextProvider: React.FC = (props) => {
  // const userToken = useSelector((state: IAppState) => userSelectors.getUserToken(state));
  const isLoggedIn = useSelector((state: IAppState) => userSelectors.isUserAuthenticated(state));
  // const [socket] = useSocket(`${Config.web.REACT_APP_CHAT_GATEWAY}/chat?token=${userToken}`, {autoConnect: false});
  const [state, setState] = useState<IMessageContextState>(initState);
  const dispatch = useDispatch();

  function updateChatState(conversation: IConversation) {
    dispatch(receiveMessage(conversation));
  }

  const setupSocket = () => {
    // if (userToken) {
    //     const socket = io(`${Config.web.REACT_APP_CHAT_GATEWAY}/chat?token=${userToken}`, {autoConnect: false}); //TODO: GIG-7896 - this will probably break
    //     return socket
    // } else {
    //     return null
    // }
  };

  useEffect(() => {
    // let socket = setupSocket();
    // if (isLoggedIn && socket && socket.disconnected) {
    //     socket.connect();
    //     socket.on('message', (conversation: IConversation) => updateChatState(conversation));
    //     setState({
    //         ...state,
    //         socket
    //     })
    // } else if (!isLoggedIn && socket && socket.connected) {
    //     socket.disconnect();
    // }
  }, [isLoggedIn]);

  return (
    <MessageContext.Provider value={{ state, setState }}>{props.children}</MessageContext.Provider>
  );
};

export { MessageContext, MessageContextProvider };
