import {
  IGroup,
  IEventSummaryFE,
  IGig,
  IUser,
  IEventTeam,
  IEventIndividual,
  IStatus,
  IHub,
  IGroupSummary,
} from '@gigit/interfaces';
import { IOwnerObject, OwnerObjectTypes } from '../interfaces';
import { uiConstants } from '../constants';

export type OwnerType = keyof typeof uiConstants.ownerType;
/** Utility helper functions for dealing with different types of objects. */
export namespace typeHelpers {
  /** Creates an owner object wrapper object. */
  export function createOwnerObject(
    ownerType: OwnerType,
    ownerObj: OwnerObjectTypes,
    options?: {
      child?: {
        ownerType: OwnerType;
        ownerObj: OwnerObjectTypes;
      };
      isContentCreator?: boolean;
    },
  ): IOwnerObject {
    const { parentType, parentId } = ownerObjectInternalHelpers.getParentOwnerTypeAndId(
      ownerType,
      ownerObj,
    );

    return {
      ownerType: ownerType,
      ownerId: ownerObj.id ?? '',
      ownerHandle: ownerObjectInternalHelpers.getHandle(ownerType, ownerObj) ?? '',
      object: ownerObj,
      ownerStatus: ((ownerObj as IGig | IGroup | IEventSummaryFE)?.status as IStatus) ?? null,
      ownerTitle: ownerObjectInternalHelpers.getTitle(ownerType, ownerObj) ?? '',
      ownerDescription: ownerObjectInternalHelpers.getDescription(ownerType, ownerObj),
      child: options?.child
        ? typeHelpers.createOwnerObject(options.child.ownerType, options.child.ownerObj)
        : undefined,
      isContentCreator: (ownerObj as IEventIndividual).content_creator || options?.isContentCreator,
      goal: ownerObjectInternalHelpers.getGoalAmount(ownerType, ownerObj),
      raised: ownerObjectInternalHelpers.getRaisedAmount(ownerType, ownerObj),
      ownerProfileImageUrl: ownerObjectInternalHelpers.getProfileImageUrl(ownerType, ownerObj),
      ownerCoverImageUrl: ownerObjectInternalHelpers.getCoverImageUrl(ownerType, ownerObj),
      isActive: ownerObjectInternalHelpers.isActive(ownerType, ownerObj),
      parentOwnerType: parentType,
      parentOwnerId: parentId,
      ownerOrganizer:
        (ownerObj as IEventSummaryFE).event_organizer?.title ||
        (ownerObj as IEventSummaryFE).event_organizer_name ||
        '',
      account: ownerObjectInternalHelpers.getAccount(ownerType, ownerObj),
      localization: ownerObjectInternalHelpers.getLocalization(ownerType, ownerObj),
    };
  }

  export function getGroupHandleFromOwner(ownerObj: IOwnerObject): string | undefined {
    const group = getGroupFromOwner(ownerObj);
    return group?.handle;
  }

  export function getGroupIdFromOwner(ownerObj: IOwnerObject): string | undefined {
    const group = getGroupFromOwner(ownerObj);
    return group?.id;
  }

  export function getGroupFromOwner(ownerObj: IOwnerObject): IGroupSummary | undefined {
    if (ownerObj.ownerType === uiConstants.ownerType.group) {
      return ownerObj.object as IGroupSummary;
    } else if (ownerObj.ownerType === uiConstants.ownerType.event) {
      return {
        ...(ownerObj.object as IEventSummaryFE).group,
        id:
          (ownerObj.object as IEventSummaryFE).group?.id ||
          (ownerObj.object as IEventSummaryFE)?.group_id,
      } as IGroupSummary;
    } else if (ownerObj.ownerType === uiConstants.ownerType.gig) {
      return (ownerObj.object as IGig).group as IGroupSummary;
    }
  }

  export function getHubFromOwner(ownerObj: IOwnerObject) {
    return (ownerObj?.object as IEventSummaryFE)?.hub;
  }

  export function getEventFromOwner(ownerObj: IOwnerObject): IEventSummaryFE | undefined {
    if (ownerObj.ownerType === uiConstants.ownerType.event) {
      return ownerObj.object as IEventSummaryFE;
    } else if (ownerObj.ownerType === uiConstants.ownerType.gig) {
      return (ownerObj.object as IGig).event as any as IEventSummaryFE;
    }
  }

  export function getCampaignIdFromOwner(ownerObj: IOwnerObject): string | null | undefined {
    if ('campaign_id' in ownerObj.object) {
      return ownerObj.object.campaign_id;
    }
  }

  export function getGroupTitleFromOwner(ownerObj: IOwnerObject): string | undefined {
    const group = getGroupFromOwner(ownerObj);
    return group?.title;
  }

  export function getHubHandleFromOwner(ownerObj: IOwnerObject): string | undefined {
    const hub = getHubFromOwner(ownerObj);
    return hub?.handle;
  }

  export function getHubTitleFromOwner(ownerObj: IOwnerObject): string | undefined {
    const hub = getHubFromOwner(ownerObj);
    return hub?.title;
  }

  export function getHubIdFromOwner(ownerObj: IOwnerObject): string | undefined {
    if ('hub_id' in ownerObj.object) {
      return ownerObj.object.hub_id;
    }
  }

  /** Used for matching owner type to object interface type. */
  type TOwnerObjectMatcher = {
    group: IGroup;
    event: IEventSummaryFE;
    gig: IGig;
    hub: IHub;
    user: IUser;
    team: IEventTeam;
    individual: IEventIndividual;
  };

  /** Returns and requires that the provided owner object is within the given list of types. */
  export function requireOwnerObjectAs<TTypeCode extends keyof TOwnerObjectMatcher>(
    owner: IOwnerObject,
    ...types: TTypeCode[]
  ): Pick<TOwnerObjectMatcher, TTypeCode>[TTypeCode] {
    if (!types.includes(owner.ownerType as TTypeCode)) {
      throw new Error(`Expected type(s) ${types.join(',')} but got ${owner.ownerType}`);
    }

    return owner.object as any;
  }

  /** Returns owner object if it's within the given list of types. */
  export function tryGetOwnerObjectAs<TTypeCode extends keyof TOwnerObjectMatcher>(
    owner: IOwnerObject,
    ...types: TTypeCode[]
  ): Pick<TOwnerObjectMatcher, TTypeCode>[TTypeCode] | undefined {
    if (!types.includes(owner.ownerType as TTypeCode)) {
      return undefined;
    }

    return owner.object as any;
  }

  /** Validates if owner object is of type. */
  export function isOwnerObjectOfType<TTypeCode extends keyof TOwnerObjectMatcher>(
    owner: IOwnerObject,
    ...types: TTypeCode[]
  ): owner is IOwnerObject<Pick<TOwnerObjectMatcher, TTypeCode>[TTypeCode]> {
    return types.includes(owner.ownerType as TTypeCode);
  }

  /** Assets that the provides value is not null or undefined.
   * - Throws runtime error if it's null or undefined.
   * - This should be used when we're 100% sure the value can't be null but the interface says otherwise.
   */
  export function assertNotNullOrUndefined<T>(
    value?: T | null,
    message?: string,
  ): asserts value is Exclude<T, null | undefined> {
    const isValid = !!value;

    if (!isValid) {
      throw new Error(message || 'Expected value to not be null or undefined.');
    }
  }

  /**
   * This is an extension to assertNotNullOrUndefined().
   *
   * This asserts that a value will be there, and returns it within the function.
   *
   * - This should be used when we're 100% sure the value can't be null but the interface says otherwise.
   */
  export function tryGetAssertNotNullOrUndefinedValue<T>(value?: T | null, message?: string) {
    assertNotNullOrUndefined(value, message);

    return value;
  }

  /** Asserts that this function call is unreachable.
   * - Should be used when we know 100% that a code block/condition should never be reached.
   */
  export function assertNotReachable(message?: string): never {
    throw new Error(message || 'Unexpected code reached');
  }

  export function assertIsNumber(value: any, message?: string): asserts value is number {
    if (isNaN(value)) {
      throw new Error(message || 'Expected value to be a Number');
    }
  }

  /** Removes a field from an object and removes the field from the type.
   * Should be used when the field to delete is required.
   *
   * This is equivalent to:
   * ```
   * delete object.field;
   * ```
   *
   * @param object The object to remove a field from
   * @param field The field to remove
   *
   * @returns The object without the field. The original object is updated as well.
   */
  export function deleteField<Type, Field extends keyof Type>(
    object: Type,
    field: Field,
  ): Omit<Type, Field> {
    const objectWithoutField = object as Omit<Type, Field> & Partial<Pick<Type, Field>>;
    delete objectWithoutField[field];
    return objectWithoutField;
  }

  /** Internal helper functions for fetching specific fields from owner objects. */
  namespace ownerObjectInternalHelpers {
    export function isActive(ownerType: OwnerType, ownerObj: OwnerObjectTypes) {
      let activeStatuses: string[] = [''];

      switch (ownerType) {
        case uiConstants.ownerType.group:
          activeStatuses = uiConstants.activeGroupStatuses;
          break;
        case uiConstants.ownerType.event:
          activeStatuses = uiConstants.activeEventStatuses;
          break;
        case uiConstants.ownerType.gig:
          activeStatuses = uiConstants.activeGigStatuses;
          break;
        case uiConstants.ownerType.hub:
          activeStatuses = uiConstants.activeHubStatuses;
          break;
      }

      const parentStatus = (ownerObj as IGig | IGroup | IEventSummaryFE)?.status?.code ?? '';

      return activeStatuses.includes(parentStatus);
    }

    export function getTitle(ownerType: OwnerType, ownerObj: OwnerObjectTypes) {
      switch (ownerType) {
        case uiConstants.ownerType.group:
        case uiConstants.ownerType.event:
        case uiConstants.ownerType.gig:
        case uiConstants.ownerType.hub:
          return (ownerObj as IGig | IGroup | IEventSummaryFE | IHub).title;
        case uiConstants.ownerType.user:
          return (ownerObj as IUser).display_name;
        case uiConstants.ownerType.individual:
          return (ownerObj as IEventIndividual).user?.display_name;
        case uiConstants.ownerType.team:
          return (ownerObj as IEventTeam).name;
      }
    }

    export function getDescription(ownerType: OwnerType, ownerObj: OwnerObjectTypes) {
      switch (ownerType) {
        case uiConstants.ownerType.group:
        case uiConstants.ownerType.event:
        case uiConstants.ownerType.gig:
        case uiConstants.ownerType.hub:
          return (ownerObj as IGig | IGroup | IEventSummaryFE | IHub).description;
        case uiConstants.ownerType.individual:
        case uiConstants.ownerType.team:
          return (ownerObj as IEventIndividual | IEventTeam).story;
      }
    }

    export function getHandle(ownerType: OwnerType, ownerObj: OwnerObjectTypes) {
      switch (ownerType) {
        case uiConstants.ownerType.group:
        case uiConstants.ownerType.event:
        case uiConstants.ownerType.gig:
        case uiConstants.ownerType.hub:
          return (ownerObj as IGig | IGroup | IEventSummaryFE | IHub).handle;
        case uiConstants.ownerType.user:
          return (ownerObj as IUser).handle;
        case uiConstants.ownerType.individual:
          return (ownerObj as IEventIndividual).user?.handle;
        case uiConstants.ownerType.team:
          return (ownerObj as IEventTeam).handle;
      }
    }

    export function getRaisedAmount(ownerType: OwnerType, ownerObj: OwnerObjectTypes) {
      switch (ownerType) {
        case uiConstants.ownerType.event:
        case uiConstants.ownerType.individual:
        case uiConstants.ownerType.team:
          return (ownerObj as IEventSummaryFE | IEventIndividual | IEventTeam).raised;
      }
    }

    export function getGoalAmount(ownerType: OwnerType, ownerObj: OwnerObjectTypes) {
      switch (ownerType) {
        case uiConstants.ownerType.event:
        case uiConstants.ownerType.individual:
        case uiConstants.ownerType.team:
          return (ownerObj as IEventSummaryFE | IEventIndividual | IEventTeam).goal;
      }
    }

    export function getProfileImageUrl(ownerType: OwnerType, ownerObj: OwnerObjectTypes) {
      switch (ownerType) {
        case uiConstants.ownerType.group:
        case uiConstants.ownerType.event:
        case uiConstants.ownerType.gig:
        case uiConstants.ownerType.team:
        case uiConstants.ownerType.hub:
          return (ownerObj as IGroup | IEventSummaryFE | IGig | IEventTeam | IHub)
            .profile_image_url;
        case uiConstants.ownerType.individual:
          return (ownerObj as IEventIndividual).user?.profile_image_url;
        case uiConstants.ownerType.user:
          return (ownerObj as IUser).profile_image_url;
      }
    }

    export function getCoverImageUrl(ownerType: OwnerType, ownerObj: OwnerObjectTypes) {
      switch (ownerType) {
        case uiConstants.ownerType.group:
        case uiConstants.ownerType.event:
        case uiConstants.ownerType.gig:
        case uiConstants.ownerType.team:
          return (ownerObj as IGroup | IEventSummaryFE | IGig | IEventTeam).cover_image_url;
        case uiConstants.ownerType.individual:
          return (ownerObj as IEventIndividual).user?.cover_image_url;
        case uiConstants.ownerType.user:
          return (ownerObj as IUser).cover_image_url;
        case uiConstants.ownerType.hub:
          return (ownerObj as IHub).banner?.image;
      }
    }

    export function getParentOwnerTypeAndId(
      ownerType: OwnerType,
      ownerObj: OwnerObjectTypes,
    ): { parentId: string | null | undefined; parentType: string | null | undefined } {
      switch (ownerType) {
        case uiConstants.ownerType.event: {
          const owner = ownerObj as IEventSummaryFE;
          if (owner.hub_id) {
            return {
              parentType: uiConstants.ownerType.hub,
              parentId: owner.hub_id,
            };
          } else if (owner.group_id) {
            return {
              parentType: uiConstants.ownerType.group,
              parentId: owner.group_id,
            };
          } else {
            // HACK: Means we don't know the parent. Done since in some places we don't have parent id fields.
            return {
              parentId: undefined,
              parentType: undefined,
            };
          }
        }
        case uiConstants.ownerType.team || uiConstants.ownerType.individual:
          return {
            parentType: uiConstants.ownerType.event,
            parentId: (ownerObj as IEventIndividual | IEventTeam).event_id,
          };
        case uiConstants.ownerType.gig: {
          const owner = ownerObj as IGig;
          if (owner.event_id) {
            return {
              parentType: uiConstants.ownerType.event,
              parentId: owner.event_id,
            };
          } else if (owner.hub_id) {
            return {
              parentType: uiConstants.ownerType.hub,
              parentId: owner.hub_id,
            };
          } else if (owner.group_id) {
            return {
              parentType: uiConstants.ownerType.group,
              parentId: owner.group_id,
            };
          } else {
            // HACK: Means we don't know the parent. Done since in some places we don't have parent id fields.
            return {
              parentId: undefined,
              parentType: undefined,
            };
          }
        }
        default:
          return {
            parentId: null,
            parentType: null,
          };
      }
    }

    export function getAccount(ownerType: OwnerType, ownerObj: OwnerObjectTypes) {
      switch (ownerType) {
        case uiConstants.ownerType.group:
          return (ownerObj as IGroup).account;
        case uiConstants.ownerType.event: {
          const event = ownerObj as IEventSummaryFE;
          return event?.group?.account ?? event?.hub?.account;
        }
        case uiConstants.ownerType.hub:
          return (ownerObj as IHub).account;
        default:
          return undefined;
      }
    }

    export function getLocalization(ownerType: OwnerType, ownerObj: OwnerObjectTypes) {
      switch (ownerType) {
        case uiConstants.ownerType.group:
          return (ownerObj as IGroup).localization;
        case uiConstants.ownerType.event: {
          const event = ownerObj as IEventSummaryFE;
          return event?.group?.localization ?? event?.hub?.localization;
        }
        case uiConstants.ownerType.hub:
          return (ownerObj as IHub).localization;
        default:
          return undefined;
      }
    }
  }
}

export default typeHelpers;
