import { IGigSummaryFE, IGigUpdateParams, IGroup, IGroupFE } from '@gigit/interfaces';
import moment from 'moment';
import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { typeHelpers } from '../../../../../helpers';
import { useLocale } from '../../../../../hooks';
import useToastDispatcher from '../../../../../hooks/useToaster';
import { localizeHelpers } from '../../../../../localizeHelpers';
import { gigRequestActions, hubRequestActions } from '../../../../../requestActions';
import Button from '../../../../Button/Button';
import { SearchableDropdown } from '../../../../SearchableDropdown/SearchableDropdown';
import DateTimePicker from '../../../../shared/DateTimePicker/DateTimePicker';
import ToggleButton from '../../../../shared/ToggleButton/ToggleButton';
import TextArea from '../../../../TextArea/TextArea';
import TextField from '../../../../TextField/TextField';
import './EditVolunteerOpportunity.scss';

interface IProps {
  onCancel?: () => void;
  onSaveCallback?: () => void;
  hub_id: string;
  volunteerOpportunity: IGigSummaryFE;
}

interface ICustomCause {
  id: string;
  image: string;
  label: string;
}

const EditVolunteerOpportunity: FC<IProps> = (props) => {
  const { hub_id, volunteerOpportunity, onSaveCallback, onCancel } = props;
  const [formData, setFormData] = useState<IGigSummaryFE>(volunteerOpportunity);
  const [causesList, setCausesList] = useState<ICustomCause[]>([]);
  const [causesAll, setCausesAll] = useState<ICustomCause[]>([]);
  const [allGroups, setAllGroups] = useState<IGroup[]>([]);
  const [dataProcessing, setDataProcessing] = useState<boolean>(false);
  const [isOnlineEvent, setIsOnlineEvent] = useState<boolean>(false);
  const locale = useLocale();
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  function updateFormDate(data: Partial<IGigSummaryFE>) {
    setFormData({
      ...formData,
      ...data,
    });
  }

  async function handleSubmit() {
    try {
      setDataProcessing(true);
      typeHelpers.assertNotNullOrUndefined(volunteerOpportunity?.id, 'Expected Gig ID');
      const params = formData as IGigUpdateParams;
      await gigRequestActions.updateGig(volunteerOpportunity.id, params);
      onSaveCallback?.();
      dispatchToastSuccess(localizeHelpers.translate('Successfully edited.'), 'Edit Opportunity');
    } catch (error) {
      dispatchToastError(error, 'Edit Opportunity');
    } finally {
      setDataProcessing(false);
    }
  }

  async function handleGetCausesList() {
    try {
      const result = await hubRequestActions.getHubPartners(hub_id);
      setAllGroups(result);
      setCausesAll(
        result.map((group) => ({
          id: group.id,
          image: group.profile_image_url,
          label: group.title,
        })),
      );
      setCausesList(
        result.map((group) => ({
          id: group.id,
          image: group.profile_image_url,
          label: group.title,
        })),
      );
    } catch (error) {
      dispatchToastError(error, 'Error Get Causes List');
    }
  }

  const searchCauses = (searchValue: string) => {
    let searched = causesAll.filter((cause) =>
      cause.label.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setCausesList(searched);
  };

  useEffect(() => {
    handleGetCausesList();
  }, []);

  return (
    <div className="EditVolunteerOpportunity">
      <div className="EditVolunteerOpportunity-head">
        <h2>Edit Opportunity</h2>
        <p>Update the details for your volunteer opportunity.</p>
      </div>
      <form
        onSubmit={handleSubmit}
        className="inner-content"
      >
        <div className="details-row">
          <span className="EditVolunteerOpportunity-from--subtitle">Opportunity Details</span>
        </div>
        <div className="details-row">
          <div className="details-col">
            <TextField
              label="Gig Title"
              placeholder="Gig Name"
              required={true}
              value={formData.title}
              name="Gig name"
              type="text"
              onChange={(e) => {
                updateFormDate({ title: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="details-row">
          <div className="details-col">
            <TextArea
              className="description"
              label="Gig Description"
              type="text"
              value={formData.description}
              name="description"
              required={false}
              spellCheck={false}
              placeholder="Description"
              onChange={(e) => updateFormDate({ description: e.target.value || '' })}
            />
          </div>
        </div>
        <div className="details-row">
          <div className="details-col">
            <label>Start Date</label>
            <DateTimePicker
              inputProps={{ placeholder: 'MM/DD/YYYY', readOnly: false }}
              className="custom-dtp"
              value={moment(formData.start_date)}
              onChange={(start_date) => {
                updateFormDate({ start_date: start_date.toString() });
              }}
              locale={locale.currentLocale}
              timeFormat={true}
            />
          </div>
          <div className="details-col">
            <label>End Date</label>
            <DateTimePicker
              inputProps={{ placeholder: 'MM/DD/YYYY', readOnly: false }}
              className="custom-dtp"
              value={moment(formData.end_date)}
              onChange={(end_date) => {
                updateFormDate({ end_date: end_date.toString() });
              }}
              locale={locale.currentLocale}
              timeFormat={true}
            />
          </div>
        </div>
        <div className="details-row">
          <div className="details-col">
            <TextField
              label="Hours Upon Completion"
              placeholder="Enter a number of hours"
              required={true}
              value={formData?.number_of_hours_per_volunteer || 0}
              name="EditVolunteerOpportunity_hours_upon"
              type="number"
              onChange={(e) => {
                updateFormDate({ number_of_hours_per_volunteer: Number(e.target.value || 0) });
              }}
            />
          </div>
        </div>
        <div className="details-row">
          <span className="EditVolunteerOpportunity-from--subtitle">Cause</span>
        </div>
        <div className="details-row">
          <div className="details-col">
            <SearchableDropdown
              label={localizeHelpers.translate('Select a Cause')}
              notranslate="yes"
              selectedItem={
                {
                  id: formData.group_id || formData.group?.id || '',
                  label: formData.group_title || formData.group?.title || '',
                  image: formData.group?.profile_image_url || '',
                } || null
              }
              list={causesList.map((group) => ({
                id: group.id ?? '',
                label: group.label || '',
                image: group.image || '',
              }))}
              setSelectedItem={(group) => {
                const tmp = allGroups.find((g) => g.id === group?.id) as IGroupFE;
                updateFormDate({
                  group_id: group?.id,
                  group_title: group?.label,
                  group: tmp ? tmp : volunteerOpportunity?.group,
                });
              }}
              onSearch={(searchValue) => {
                searchCauses(searchValue);
              }}
            />
          </div>
        </div>
        <div className="details-row">
          <span className="EditVolunteerOpportunity-from--subtitle">Location</span>
        </div>
        <div className="details-row">
          <ToggleButton
            isActive={isOnlineEvent}
            onToggleClick={() => setIsOnlineEvent(!isOnlineEvent)}
            label="Online Event"
          />
        </div>
        {!isOnlineEvent && (
          <>
            <div className="details-row">
              <div className="details-col">
                <TextField
                  label="Street Address"
                  placeholder="Street Address"
                  required={true}
                  value={(formData?.locations && formData?.locations[0]?.line1) || ''}
                  name="EditVolunteerOpportunity_street_addr"
                  type="text"
                  onChange={(e) => {
                    updateFormDate({
                      locations: [
                        {
                          ...(formData.locations && formData.locations[0]
                            ? formData.locations[0]
                            : {}),
                          line1: e.target.value,
                        },
                      ],
                    });
                  }}
                />
              </div>
              <div className="details-col">
                <TextField
                  label="Apartment/Suite #"
                  placeholder="Apartment/Suite #"
                  required={false}
                  value={(formData?.locations && formData?.locations[0]?.line2) || ''}
                  name="EditVolunteerOpportunity_apartment"
                  type="text"
                  onChange={(e) =>
                    updateFormDate({
                      locations: [
                        {
                          ...(formData.locations && formData.locations[0]
                            ? formData.locations[0]
                            : {}),
                          line2: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>
            <div className="details-row">
              <div className="details-col">
                <TextField
                  label="City"
                  placeholder="e.g. Hamilton"
                  required={true}
                  value={(formData?.locations && formData?.locations[0]?.city) || ''}
                  name="EditVolunteerOpportunity_city"
                  type="text"
                  onChange={(e) =>
                    updateFormDate({
                      locations: [
                        {
                          ...(formData.locations && formData.locations[0]
                            ? formData.locations[0]
                            : {}),
                          city: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="details-col">
                <TextField
                  label="Province/State"
                  placeholder="e.g. Ontario"
                  required={true}
                  value={(formData?.locations && formData?.locations[0]?.province) || ''}
                  name="EditVolunteerOpportunity_province"
                  type="text"
                  onChange={(e) =>
                    updateFormDate({
                      locations: [
                        {
                          ...(formData.locations && formData.locations[0]
                            ? formData.locations[0]
                            : {}),
                          province: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>
            <div className="details-row">
              <div className="details-col">
                <TextField
                  label="Country"
                  placeholder="e.g. Canada"
                  required={true}
                  value={(formData?.locations && formData?.locations[0]?.country) || ''}
                  name="EditVolunteerOpportunity_country"
                  type="text"
                  onChange={(e) =>
                    updateFormDate({
                      locations: [
                        {
                          ...(formData.locations && formData.locations[0]
                            ? formData.locations[0]
                            : {}),
                          country: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="details-col">
                <TextField
                  label="Postal"
                  placeholder="e.g. 000 000"
                  required={true}
                  value={(formData?.locations && formData?.locations[0]?.postal_code) || ''}
                  name="EditVolunteerOpportunity_postal"
                  type="text"
                  onChange={(e) =>
                    updateFormDate({
                      locations: [
                        {
                          ...(formData.locations && formData.locations[0]
                            ? formData.locations[0]
                            : {}),
                          postal_code: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>
          </>
        )}
        {isOnlineEvent && (
          <div className="details-row">
            <TextField
              name="EditVolunteerOpportunity_url"
              placeholder="Enter a URL"
              value={formData?.website_url || ''}
              label="Opportunity URL"
              onChange={(e) => updateFormDate({ website_url: e.target.value })}
            />
          </div>
        )}
        <div className="form-buttons">
          {onCancel && (
            <Button
              text="Cancel"
              buttonType="secondary"
              onClick={onCancel}
            />
          )}
          <Button
            text="Save changes"
            buttonType="dark"
            type="submit"
            isDisabled={dataProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default EditVolunteerOpportunity;
