import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { InfoSection } from '../../components/shared/Onboarding/InfoSection/InfoSection';
import { FormSection } from '../../components/shared/Onboarding/FormSection/FormSection';
import { CauseSelection } from '../../components/shared/Onboarding/CausesSelection/CauseSelection';
import { ImageSelect } from '../../components/shared/Onboarding/imageSelect/ImageSelect';
import { ICausesSelection, IOnboardStep } from '../../components/shared/Onboarding/interfaces';
import { authRequestActions, userRequestActions } from '../../requestActions';

import './Register.scss';
import _ from 'lodash';
import { FormTopBar } from '../../components/shared/Onboarding/FormTopBar/FormTopBar';
import { FormBottomBar } from '../../components/shared/Onboarding/FormBottomBar/FormBottomBar';
import OnboardingWrapper from '../../components/shared/Onboarding/OnboardingWrapper';
import {
  hasAllRequiredFields,
  onBack,
  onSelectCause,
  setCauses,
} from '../../components/shared/Onboarding/helpers';
import { useCauseInfo } from '../../components/shared/Onboarding/hooks/useCauseInfo';
import { useSelector } from 'react-redux';
import { IAppState, useAppDispatch } from '../../store';
import { userSelectors } from '../../selectors/user';
import { registerStepsMaster } from './Config';
import { UserInfo } from '../../components/shared/Onboarding/UserInfo/UserInfo';
import VerifyUser from '../../components/shared/Onboarding/VerifyUser/VerifyUser';
import { registerUser } from '../../actions/register';
import {
  routes,
  setSEOMetatags,
  swapRouteParams,
  toastError,
  uploadImageToStore,
} from '../../helpers';
import { createToast } from '../../actions/toaster';
import { localizeHelpers } from '../../localizeHelpers';
import { registerSelectors } from '../../selectors/register';
import { updateUser, logout } from '../../actions/user';
import useToastDispatcher from '../../hooks/useToaster';
import axios from 'axios';

interface IProps extends RouteComponentProps<{ step: string }> {}

export interface IRegisterFormData {
  userInfo: IBaseUserInfo;
  profileImage: string;
  coverImage: string;
  causeInfo: ICausesSelection;
}

export interface IBaseUserInfo {
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  password: string;
  isOfAge: boolean;
  verification: {
    code: string;
    hasGeneratedCode: boolean;
    isValid: boolean;
  };
}

const defaultBaseUser = {
  firstName: '',
  lastName: '',
  displayName: '',
  email: '',
  password: '',
  isOfAge: false,
  verification: {
    code: '',
    hasGeneratedCode: false,
    isValid: false,
  },
};

const Register: React.FC<IProps> = (props: IProps) => {
  const dispatch = useAppDispatch();
  // TODO: Should just grab userState from state instead of userState and user
  const userInfo = useSelector((state: IAppState) => userSelectors.getUser(state));
  const profileInfo = useSelector((state: IAppState) => state.profileState);
  const isLoading = useSelector((state: IAppState) => registerSelectors.isRegisterLoading(state));
  const isLoggedIn = useSelector((state: IAppState) => userSelectors.isUserAuthenticated(state));
  const history = useHistory();
  const { dispatchToastError } = useToastDispatcher();

  // Handle Register Redirects
  let urlParams = queryString.parse(window.location.search);
  if (urlParams.event) {
    urlParams.redirect += `&event=${urlParams.event}`;
  }
  const redirect = decodeURIComponent(urlParams.redirect?.toString() || '');
  const fn = decodeURIComponent(urlParams.fn?.toString() || '');

  // State
  const [baseUserInfo, setBaseUserInfo] = useState<IBaseUserInfo>(defaultBaseUser);
  const { causeInfo, handleCauseInfo } = useCauseInfo();
  const [steps] = useState<IOnboardStep[]>(registerStepsMaster);
  const [profileImage, setProfileImage] = useState<string>('');
  const [coverImage, setCoverImage] = useState<string>('');
  const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(false);

  // View Helpers
  const navigateHome = () =>
    props.history.push(isLoggedIn ? `/setup/user/${userInfo?.handle}/success` : '/');
  const nxtBtnText = getCurrentStep() === 'causesSelection' ? 'Submit' : 'Next';
  const showBack = steps.findIndex((step) => step.id === getCurrentStep());

  useEffect(() => {
    // If we re-mount, move back to first step.
    // This ensure the flow never breaks if navigating to a specific step.
    const firstStepId = registerStepsMaster[0]?.id;
    if (getCurrentStep() !== firstStepId) {
      setCurrentStep(firstStepId);
    }

    setCauses(causeInfo, handleCauseInfo);

    setSEOMetatags({
      urlPath: `register`,
      title: localizeHelpers.translate('Register | Kambeo'),
    });

    dispatch(logout());
  }, []);

  function getCurrentStep() {
    return props.match.params.step;
  }

  function renderStep() {
    switch (getCurrentStep()) {
      case 'baseUserInfo': {
        return renderBaseUserInfo();
      }
      case 'causesSelection': {
        return renderCausesSelection();
      }
      case 'imageSelection': {
        return renderImageSelection();
      }
      case 'verification': {
        return renderVerifyUser();
      }
      default:
        break;
    }
  }

  function renderBaseUserInfo() {
    return (
      <div className="form-page column">
        <UserInfo
          userInfo={baseUserInfo}
          onUserInfoUpdated={(update: IBaseUserInfo) => onUserInfoUpdated(update)}
        />
      </div>
    );
  }

  function onUserInfoUpdated(update: IBaseUserInfo) {
    setBaseUserInfo(update);
  }

  function renderCausesSelection() {
    return (
      <CauseSelection
        causeInfo={causeInfo}
        onChange={(value: string) => onSelectCause(causeInfo, value, handleCauseInfo)}
      />
    );
  }

  function renderImageSelection() {
    return (
      <div className="form-page">
        <ImageSelect
          type="profile"
          showPreselection={true}
          profileImage={profileImage}
          onChange={setProfileImage}
        />

        <ImageSelect
          type="cover"
          showPreselection={true}
          coverImage={coverImage}
          onChange={setCoverImage}
        />
      </div>
    );
  }

  function renderVerifyUser() {
    return (
      <div className="form-page column">
        <VerifyUser
          userInfo={baseUserInfo}
          updateVerificationCode={(update) => setBaseUserInfo(update)}
        />
      </div>
    );
  }

  function setCurrentStep(stepId: string) {
    let isExistingStep = steps.filter((step) => step.id === stepId);

    if (isExistingStep.length > 0) {
      props.history.replace({
        pathname: `/register/${stepId}`,
        search: props.location.search,
      });
    }
  }

  function getFormData() {
    const registerData: IRegisterFormData = {
      userInfo: baseUserInfo,
      causeInfo,
      profileImage,
      coverImage,
    };

    return registerData;
  }

  async function isBase64(img: string) {
    if (img === '' || img.trim() === '') {
      return false;
    }
    try {
      const base = img.split(',')[1];
      return atob(base);
    } catch (err) {
      return false;
    }
  }

  async function onRegistered() {
    const onSuccess = () => {
      let redirectUrl = '';

      if (redirect) {
        redirectUrl = redirect;

        if (fn) {
          redirectUrl += '?fn=' + fn;
        }
      }

      if (redirectUrl.includes('volunteer')) {
        if (!hasUserInfo()) {
          if (redirectUrl.includes('setup/')) {
            redirectUrl = `/setup/volunteer`;
          } else {
            redirectUrl = `/setup/volunteer?isRedirect=${redirectUrl}`;
          }
        }
      }

      setIsUpdatingUser(false);
      if (redirectUrl === '/' || redirectUrl === '') {
        history.push(`/setup/user/${userInfo?.handle}/success`);
      } else if (redirectUrl) {
        history.push(redirectUrl);
      }
    };

    try {
      setIsUpdatingUser(true);
      // TODO: this section really needs to be thought through better - too many disconnected calls
      const userPages = await userRequestActions.getUserPages(userInfo.id || '');
      const pageId = userPages[0].id;
      const coverImageUploaded = await uploadImageToStore(coverImage);
      const isProfileBase64 = await isBase64(profileImage);
      let profileImageUploaded;
      if (isProfileBase64) {
        profileImageUploaded = await uploadImageToStore(profileImage);
      } else {
        profileImageUploaded = profileImage;
      }

      // Create a causes component and populate with selected causes
      if (causeInfo.selectedCauses && causeInfo.selectedCauses.length) {
        let componentPayload = {
          title: '',
          component_type: 'cause',
          visibility: 'public',
          sequence: 1,
          content_references: {
            object_type: 'cause',
            object_ids: causeInfo.selectedCauses,
          },
        };

        await axios.post(
          swapRouteParams(routes.CREATE_USER_PAGE_COMPONENT, { pageId }),
          componentPayload,
        );
      }

      // update profile image/cover image/focus area selections
      const shouldUpdateProfile = false;
      dispatch(
        updateUser(
          {
            causes: causeInfo.selectedCauses,
            profile_image_url: profileImageUploaded,
            cover_image_url: coverImageUploaded,
          },
          shouldUpdateProfile,
          { callback: onSuccess },
        ),
      );
    } catch (error) {
      dispatchToastError(error, 'Update User');
    }
  }

  const onNext = async () => {
    const index = steps.findIndex((step) => step.id === getCurrentStep());
    const nextStep = steps[index + 1]?.id;
    const currentStep = getCurrentStep();
    const formData = getFormData();

    if (hasAllRequiredFields(steps, currentStep, formData)) {
      if (getCurrentStep() === 'baseUserInfo') {
        const emailExists = await duplicateEmail(baseUserInfo.email);
        if (emailExists) {
          return;
        } else {
          setCurrentStep(nextStep);
        }
      } else if (index + 1 > steps.length) {
        return;
      } else if (index + 1 === steps.length) {
        await onRegistered();
      } else {
        // https://app.clickup.com/t/8404472/GIG-6056 - We sign the user up after verify now...
        if (getCurrentStep() === 'verification' && !isLoggedIn) {
          register();
        }

        setCurrentStep(nextStep);
      }
    } else {
      return;
    }
  };

  function register() {
    // We no longer send the cover/profile/causes to first update
    let newAccount = {
      first_name: baseUserInfo.firstName,
      last_name: baseUserInfo.lastName,
      email: baseUserInfo.email,
      password: baseUserInfo.password,
      display_name: baseUserInfo.displayName,
      is_18_years_old: baseUserInfo.isOfAge,
      verification_code: baseUserInfo.verification.code,
    };

    // TODO: I've added request action but we need to reimplement all the RegisterUser logic
    // so i've opted to use this in the interim (C.H)
    dispatch(registerUser(newAccount));
  }

  function hasUserInfo() {
    return (
      userInfo?.first_name &&
      userInfo?.last_name &&
      userInfo?.phone &&
      userInfo?.email &&
      userInfo?.emergency_contacts?.length &&
      userInfo?.emergency_contacts?.length > 0
    );
  }

  const duplicateEmail = async (email: string): Promise<boolean> => {
    const dupe = await authRequestActions.checkIfEmailExists(baseUserInfo.email);
    if (dupe) {
      let toast = toastError('An account with this email address already exists', 'Email Address');
      dispatch(createToast(toast));
      return dupe;
    }
    return false;
  };

  function showBackButton() {
    if (showBack !== 0) {
      if (getCurrentStep() === 'imageSelection') {
        return false;
      }
      return true;
    } else {
      return true;
    }
  }

  return (
    <OnboardingWrapper
      requiresAuth={false}
      redirect=""
    >
      <form
        className="Register"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormTopBar
          onLogoClick={() => navigateHome()}
          onClose={() => navigateHome()}
          step={getCurrentStep()}
        />
        <InfoSection
          type={baseUserInfo.firstName}
          title="Registration"
          steps={steps}
          currentStep={getCurrentStep()}
        />
        <FormSection>{renderStep()}</FormSection>
        <FormBottomBar
          showSkip={getCurrentStep() === 'causesSelection'}
          isDisabled={
            !hasAllRequiredFields(
              steps,
              getCurrentStep(),
              getFormData() || isLoading || isUpdatingUser,
            )
          }
          disableSkip={isUpdatingUser}
          showBack={showBackButton()}
          nextBtnTxt={nxtBtnText}
          onBack={() => onBack(steps, getCurrentStep(), setCurrentStep)}
          onNext={() => onNext()}
          loading={isLoading || isUpdatingUser}
        />
      </form>
    </OnboardingWrapper>
  );
};

export default Register;
