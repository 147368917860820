import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import Loader from '../../Loader/Loader';
import Portrait from '../../Portrait/Portrait';
import ChangeStatusMenu from '../../ChangeStatusMenu/ChangeStatusMenu';
import TextField from '../../TextField/TextField';
import { combineClassNames } from '../../../helpers';
import { History } from 'history';
import { ICampaign, IGroup } from '@gigit/interfaces';
import './PageHeader.scss';
import {
  IFloatingAction,
  ProfileFloatingActions,
} from '../ProfileFloatingActions/ProfileFloatingActions';
import { Link, useHistory } from 'react-router-dom';
import QuillTextEditor from '../../QuillTextEditor/QuillTextEditor';
import { uiConstants } from '../../../constants';
import VMPoweredBy from './../../../assets/VM_powered_by.png';
import { IEventState } from '../../../reducers/event';
import { IAppState, useAppSelector } from '../../../store';
import { connect } from 'react-redux';
import { userRequestActions } from '../../../../../gigit-ui-web/src/requestActions';
import useToastDispatcher from '../../../hooks/useToaster';
import { userSelectors } from '../../../selectors/user';
import { Constants } from '@gigit/constants';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { useLocale } from '../../../hooks';
import CalendarIconGrey from '../../../assets/calendar-icon-grey.svg';
import VerifiedCharityIcon from '../../../assets/chech-decagram-purple.svg';
export interface IImgIcon {
  src: string;
  width: string;
  height: string;
  alt?: string;
}
export interface IHeaderLink {
  label: string;
  url: string;
  iconClassName?: string;
  imgIcon?: IImgIcon;
  ownerType: string;
}

interface IProps {
  isLoading: boolean;
  history: History;
  showEditPortrait: boolean;
  portraitImageUrl: string;
  typeName: string;
  typeIconClass?: string;
  typeImgIcon?: IImgIcon;
  group?: IGroup;
  eventState: IEventState;
  displayName: string | undefined;
  showEditDisplayName: boolean;
  campaign?: ICampaign | null;
  handle?: string | undefined;
  availableStatuses?: string[];
  currentStatus?: string | undefined;
  userId?: string;
  ownerType: string;

  // gig
  isExternalVM?: boolean;
  location?: string;
  postedOn?: Date;
  fromDate?: Date;
  toDate?: Date;

  /**
   * @param callback This MUST be invoked after the status is changed for redirect after archiving to work.
   */
  onStatusChanged?: (status: string, callback: () => void) => void;
  showChangeStatus?: boolean;

  /** Children are rendered at end of header. This is where action buttons can be placed. */
  children?: ReactNode;

  links?: IHeaderLink[];

  onImageChange?: (file: any) => void;
  onDisplayNameChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmitDisplayNameChange?: () => void;

  showChat: boolean;
  extraFloatingActions?: IFloatingAction[];
  onShareClick?: () => void;
  onChatClick?: () => void;
}

interface IState {
  isEditingDisplayName: boolean;
  isFollowing: boolean;
}

/** Renders a floating header with main details. The header converts to a relative position in mobile view. */
const PageHeader: React.FC<IProps> = (props: IProps) => {
  const [isEditingDisplayName, setIsEditingDisplayName] = useState<boolean>(false);
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const user = useAppSelector((state: IAppState) => userSelectors.getUser(state));
  const history = useHistory();
  const locale = useLocale();

  const onStatusChanged = (newStatus: string) => {
    props.onStatusChanged?.(newStatus, () => {
      if (newStatus === 'archived') {
        let myPagesFilter = props.typeName.toLowerCase();

        if (
          myPagesFilter === 'event' ||
          myPagesFilter === 'individual' ||
          myPagesFilter === 'team'
        ) {
          myPagesFilter = 'fundraiser';
        }

        if (myPagesFilter === 'volunteer opportunity') {
          myPagesFilter = uiConstants.ownerType.gig;
        }
        if (myPagesFilter === 'cause') {
          myPagesFilter = uiConstants.ownerType.group;
        }
        // After archiving, redirect to mypages
        props.history.push(`/dashboard?section=pages&tab=${myPagesFilter}`);
      }
    });
  };

  useEffect(() => {
    doesUserFollowUser();
  }, [props.userId]);

  //Add this when we have BE
  async function doesUserFollowUser() {
    if (props.userId) {
      try {
        let res = await userRequestActions.getUserByHandleOrId(props.userId);
        res && res.user_capacity && res.user_capacity.includes(Constants.user_capacity.follower)
          ? setIsFollowing(true)
          : setIsFollowing(false);
      } catch (error) {
        dispatchToastError(error, 'Does User Follow User');
      }
    }
  }

  const toggleFollow = async () => {
    if (user?.id) {
      if (props.userId) {
        try {
          if (isFollowing) {
            await userRequestActions.followUser(props.userId);
            setIsFollowing(false);

            dispatchToastSuccess('Successfully unfollowed User', 'Unfollow User');
          } else {
            await userRequestActions.unfollowUser(props.userId);
            setIsFollowing(true);

            dispatchToastSuccess('Successfully followed User', 'Follow User');
          }
        } catch (error) {
          dispatchToastError(error, `${isFollowing ? 'Unfollow User' : 'Follow User'}`);
        }
      }
    } else {
      history.push('/login');
    }
  };

  const handleIsEditing = () => {
    setIsEditingDisplayName(false);
    props.onSubmitDisplayNameChange?.();
  };

  return (
    <div className="PageHeader">
      <div
        className={combineClassNames('header-content', props.isLoading ? 'content-loading' : '')}
      >
        <Portrait
          onChange={props.onImageChange}
          currentImage={props.portraitImageUrl}
          size={props.ownerType === uiConstants.ownerType.gig ? 132 : 160}
          overlay={props.showEditPortrait}
        />

        <ProfileFloatingActions
          className="floating-actions"
          onChatClick={props.onChatClick}
          onShareClick={
            props.ownerType !== uiConstants.ownerType.gig ? props.onShareClick : undefined
          }
          showChat={props.showChat}
          extraActions={props.extraFloatingActions}
        />

        {props.ownerType === uiConstants.ownerType.gig && props?.isExternalVM && (
          <img
            className="powered-by-icon"
            src={VMPoweredBy}
            width="130px"
            height="auto"
          />
        )}

        <div className="page-type">
          {props?.typeIconClass && <i className={props.typeIconClass} />}
          {props?.typeImgIcon && (
            <img
              src={props.typeImgIcon.src}
              alt={props.typeImgIcon.alt}
              height={props.typeImgIcon.height}
              width={props.typeImgIcon.width}
            />
          )}
          <span>{props.typeName}</span>
        </div>

        {isEditingDisplayName ? (
          <form
            className="display-name-container"
            onSubmit={(e) => {
              e.preventDefault();
              handleIsEditing();
            }}
          >
            <TextField
              required={true}
              type="text"
              name="display_name"
              value={props.displayName!}
              onChange={(e: ChangeEvent<HTMLInputElement>) => props.onDisplayNameChange?.(e)}
            />
          </form>
        ) : (
          <div
            className="display-name-container"
            notranslate="yes"
          >
            <h1 className="display-name">{props.displayName}</h1>
            {props.showEditDisplayName && (
              <i
                onClick={() => {
                  setIsEditingDisplayName(true);
                }}
                className="edit-display-name fad fa-edit"
              />
            )}
          </div>
        )}
        {props.ownerType === uiConstants.ownerType.gig && props?.postedOn && (
          <span
            className="posted-on"
            notranslate="yes"
          >
            {`Posted on: `}
            {localizeHelpers.formatDate(props.postedOn, LocaleDateFormats.LL, locale.currentLocale)}
          </span>
        )}
        {props.showChangeStatus && (
          <ChangeStatusMenu
            event={props.eventState.event}
            group={props.group}
            handle={props.handle || ''}
            currentStatus={props.currentStatus}
            listOfStatuses={props.availableStatuses}
            onStatusChanged={onStatusChanged}
            itemType={props.typeName}
          />
        )}
        {props.handle && (
          <div className="display-handle-follow">
            <span
              className="handle"
              notranslate="yes"
            >
              @{props.handle}
            </span>
            <br />
            <span>&middot;</span>
            <span
              className="follow-link"
              onClick={() => toggleFollow()}
            >
              {isFollowing ? ' Unfollow' : ' Follow'}
            </span>
          </div>
        )}
        {props.links?.map((link, index) => (
          <Link
            notranslate="yes"
            className="link"
            key={index}
            to={link.url}
          >
            {link?.iconClassName && <i className={link.iconClassName} />}
            {link?.imgIcon && (
              <div className="img-container">
                <img
                  src={link.imgIcon.src}
                  alt={link.imgIcon.alt}
                  width={link.imgIcon.width}
                  height={link.imgIcon.height}
                />
              </div>
            )}
            {link.label}
            {link.ownerType === uiConstants.ownerType.group &&
              props.group &&
              props.group.charity_verification_status?.code === 'verified' && (
                <div className="verified-charity-container">
                  <img
                    width="18px"
                    height="18px"
                    src={VerifiedCharityIcon}
                  />

                  <div className="more-info">
                    <span>Registered Charity</span>
                  </div>
                </div>
              )}
          </Link>
        ))}
        {props?.location && (
          <span className="location">
            <i className="fal fa-map-marker-alt" /> {props.location}
          </span>
        )}
        {props.ownerType === uiConstants.ownerType.gig && props?.fromDate && props?.toDate && (
          <div
            className="from-to-date"
            notranslate="yes"
          >
            <div className="img-container">
              <img
                src={CalendarIconGrey}
                height="16px"
                width="14px"
              />
            </div>
            <p>
              {`From `}{' '}
              {localizeHelpers.formatDate(
                props.fromDate,
                LocaleDateFormats.LLL,
                locale.currentLocale,
              )}
              <br />
              {`To `}{' '}
              {localizeHelpers.formatDate(
                props.toDate,
                LocaleDateFormats.LLL,
                locale.currentLocale,
              )}
            </p>
          </div>
        )}
        {props.group && (
          <div className="charity">
            <div className="charity-id">
              Charity ID:{' '}
              {<var data-var="charity_id">{props.group?.charity_id}</var> || 'Not provided'}
            </div>
          </div>
        )}
        {props.children}
      </div>

      {props.isLoading && (
        <div className="loading">
          <Loader loading={props.isLoading} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
