import axios from 'axios';
import { ICause } from '@gigit/interfaces';
import { routes, swapRouteParams } from '../helpers';

export namespace causeRequestActions {
  export async function getCauses(query?: URLSearchParams): Promise<ICause[]> {
    const response = await axios.get<ICause[]>(routes.GET_CAUSES, { params: query });
    return response.data;
  }

  export async function createCause(cause: Partial<ICause>) {
    const response = await axios.post(routes.CREATE_CAUSE, cause);
    return response.data;
  }

  export async function deleteCause(id: string) {
    const response = await axios.delete(swapRouteParams(routes.DELETE_CAUSE, { id: id }));
    return response.data;
  }
}
