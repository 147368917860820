import './HubToggleButtons.scss';
import React from 'react';
import Button from '../../../Button/Button';

interface IHubToggleButton {
  onClick(): void;
  text: string;
  className?: string;
  active: boolean;
}

interface IProps {
  buttons: IHubToggleButton[];
  activeStyle?: React.CSSProperties;
}

const HubToggleButtons: React.FC<IProps> = (props) => {
  return (
    <div className="HubToggleButtons">
      <div className="container">
        {props.buttons.map((btn, index) => (
          <Button
            key={index}
            className={`${btn?.className ? btn.className : ''} ${btn.active ? 'active' : ''}`}
            text={btn.text}
            type="button"
            onClick={btn.onClick}
            style={btn.active ? props.activeStyle : undefined}
          />
        ))}
      </div>
    </div>
  );
};

export default HubToggleButtons;
