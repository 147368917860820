import React, { Fragment, useState } from 'react';
import { IDonationMatchingProgram, IHub, IUserCreditPaymentPackageFE } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import './HubManagementDonationMatching.scss';
import { typeHelpers } from '../../../../helpers';
import Table, { ITableProps } from '../../../shared/Table/Table';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import ModalScrollContainer from '../../../Modal/ModalScrollContainer/ModalScrollContainer';
import { Constants } from '@gigit/constants';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import ModalFooterActionContainer from '../../../Modal/ModalFooterActions/ModalFooterActionContainer';
import MatchingProgramSummaryModal from '../shared/MatchingProgramSummaryModal/MatchingProgramSummaryModal';
import { hubRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import DonationMatchingProgramForm, {
  MATCHING_PROGRAM_FORM_ID,
} from '../shared/DontationMatchingProgramForm/DonationMatchingProgramForm';
import DonationMatchingDetailsModal from '../shared/DonationMatchingDetailsModal/DonationMatchingDetailsModal';
import { downloadFile } from '../../../TransactionManagement/IssuedTaxReceiptsExportModal/IssuedTaxReceiptsExportModal';
import { ITab, Tabs } from '../shared/Tabs/Tabs';
import VolunteerCreditsSummaryModal from '../shared/VolunteerCreditsSummaryModal/VolunteerCreditsSummaryModal';
import CreatePaymentPackageModal from '../CreatePaymentPackageModal/CreatePaymentPackageModal';

interface IProps {
  hub?: IHub;
}

const tabs: ITab[] = [
  { label: 'Matching Programs', value: 'donation-matching' },
  { label: 'Payouts for Volunteer Dollars', value: 'volunteer-for-dollars' },
];

function HubManagementDonationMatching(props: IProps) {
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const [showSummaryModal, setShowSummaryModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showAddProgramModal, setShowAddProgramModal] = useState<boolean>(false);
  const [showPayoutsSummaryModal, setShowPayoutsSummaryModal] = useState<boolean>(false);
  const [showCreatePayoutModal, setShowCreatePayoutModal] = useState<boolean>(false);

  const [selectedPayout, setSelectedPayout] = useState<IUserCreditPaymentPackageFE | null>(null);
  const [selectedProgram, setSelectedProgram] = useState<IDonationMatchingProgram | null>(null);
  const [isProgramModalSubmitting, setIsProgramModalSubmitting] = useState<boolean>(false);
  const [isSaveProgramDisabled, setIsSaveProgramDisabled] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>('donation-matching');

  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const donationMatchingTableProps: ITableProps<IDonationMatchingProgram> = {
    columns: [
      {
        id: 'name',
        Header: 'Program Title',
        accessor: ({ name }) => name,
        sortable: true,
        notranslate: 'yes',
      },
      {
        id: 'program_type',
        Header: 'Program Type',
        accessor: ({ program_type }) => {
          return program_type === Constants.donation_matching_program_type.donation_match
            ? 'Matching Program'
            : 'Volunteer for Dollars';
        },
        sortable: true,
      },
      {
        id: 'start_date',
        Header: 'Start Date',
        accessor: ({ start_date }) =>
          localizeHelpers.formatDate(start_date ?? new Date(), LocaleDateFormats.ll, locale),
        sortable: true,
      },
      {
        id: 'end_date',
        Header: 'End Date',
        accessor: ({ end_date }) =>
          localizeHelpers.formatDate(end_date ?? new Date(), LocaleDateFormats.ll, locale),
        sortable: true,
      },
      {
        id: 'status.code',
        sortable: true,
        Header: 'Status',
        accessor: ({ status }) => status.code,
        predefinedColumnType: {
          type: 'STATUS',
          columnObject: () => ({
            [Constants.donation_matching_program_status.running]: {
              label: 'Running',
              color: 'YELLOW',
            },
            [Constants.donation_matching_program_status.scheduled]: {
              label: 'Inactive',
              color: 'GREY',
            },
            [Constants.donation_matching_program_status.completed]: {
              label: 'Complete',
              color: 'GREEN',
            },
            [Constants.donation_matching_program_status.closed]: {
              label: 'Paid',
              color: 'GREEN',
            },
          }),
        },
      },
    ],
    filterOptions: {
      enableTableSearch: true,
      enableColumnSorting: true,
    },
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          buttonType: 'dark',
          label: 'Add Program',
          onClick: () => setShowAddProgramModal(true),
          type: 'HEADER',
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Edit',
          hideIf: (row) =>
            row.status.code === Constants.donation_matching_program_status.closed ||
            row.status.code === Constants.donation_matching_program_status.completed,
          onClick: (_, row) => {
            setSelectedProgram(row);
            setShowAddProgramModal(true);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Finish program',
          hideIf: (row) => row.status.code !== Constants.donation_matching_program_status.running,
          onClick: async (_, row) => {
            await handleFinishProgram(row);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Payout Summary',
          hideIf: (row) =>
            row.program_type === Constants.donation_matching_program_type.volunteer_for_dollars,
          onClick: (_, row) => {
            setSelectedProgram(row);
            setShowSummaryModal(true);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Details',
          hideIf: (row) =>
            row.program_type === Constants.donation_matching_program_type.volunteer_for_dollars,
          onClick: (_, row) => {
            setSelectedProgram(row);
            setShowDetailsModal(true);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Export',
          hideIf: (row) =>
            row.program_type === Constants.donation_matching_program_type.volunteer_for_dollars,
          onClick: async (_, row) => {
            await handleExportMatchingProgramSummary(row);
          },
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        try {
          typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
          return await hubRequestActions.getHubDonationMatchingPrograms(props.hub.id, queryParams);
        } catch (error) {
          dispatchToastError(error, 'Get Matching Programs');
          return [];
        }
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any Matching Programs",
    },
  };

  const payoutsForVolunteerDollars: ITableProps<IUserCreditPaymentPackageFE> = {
    columns: [
      {
        id: 'title',
        Header: 'Title',
        accessor: ({ title }) => title,
      },
      {
        id: 'start_date',
        Header: 'Start Date',
        accessor: ({ start_date }) =>
          localizeHelpers.formatDate(start_date ?? new Date(), LocaleDateFormats.ll, locale),
        sortable: true,
      },
      {
        id: 'end_date',
        Header: 'End Date',
        accessor: ({ end_date }) =>
          localizeHelpers.formatDate(end_date ?? new Date(), LocaleDateFormats.ll, locale),
        sortable: true,
      },
    ],
    filterOptions: {
      enableTableSearch: true,
      enableColumnSorting: true,
    },
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          buttonType: 'dark',
          label: 'Generate Credit Payouts',
          onClick: () => setShowCreatePayoutModal(true),
          type: 'HEADER',
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Payout Summary',
          onClick: (_, row) => {
            setSelectedPayout(row);
            setShowPayoutsSummaryModal(true);
          },
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        try {
          typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
          return await hubRequestActions.getPaymentPackages(props.hub.id, queryParams);
        } catch (error) {
          dispatchToastError(error, 'Get Payouts');
          return [];
        }
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any Payouts",
    },
  };

  const onTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  async function handleFinishProgram(program: IDonationMatchingProgram) {
    const end_date = new Date();

    try {
      typeHelpers.assertNotNullOrUndefined(program?.id, 'Expected Program ID');
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      await hubRequestActions.updateDonationMatchingProgram(props.hub.id, program.id, { end_date });
      refreshTableData();
      dispatchToastSuccess(localizeHelpers.translate('Successfully finished.'), 'Finish Program');
    } catch (error) {
      dispatchToastError(error, 'Finish Program');
    }
  }

  function refreshAllPageState() {
    refreshTableData();
    setSelectedProgram(null);
  }

  async function handleExportMatchingProgramSummary(program: IDonationMatchingProgram) {
    try {
      typeHelpers.assertNotNullOrUndefined(program.id, 'Expected Program ID');
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      const result = await hubRequestActions.exportMatchingProgramSummary(props.hub.id, program.id);
      downloadFile('Export_Matching_Program_Summary.csv', result);
    } catch (error) {
      dispatchToastError(error, 'Export Matching Program');
    }
  }

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  function handleSubmitProgramModal(isSubmitting: boolean) {
    setIsProgramModalSubmitting(isSubmitting);

    if (!isSubmitting) {
      refreshTableData();
      setShowAddProgramModal(false);
      setSelectedProgram(null);
    }
  }

  function renderDonationMatchingTabContent() {
    return (
      <Fragment>
        <Table
          {...donationMatchingTableProps}
          refreshTableIncrementor={refreshTableIncrementor}
        />
      </Fragment>
    );
  }

  function renderPayoutsForVolunteerDollarsTabContent() {
    return (
      <Fragment>
        <Table
          {...payoutsForVolunteerDollars}
          refreshTableIncrementor={refreshTableIncrementor}
        />
      </Fragment>
    );
  }

  return (
    <>
      <div
        className="HubManagementDonationMatching"
        id="donation-matching"
      >
        <div className="header-section">
          <h2>Matching Programs</h2>
        </div>
        <div className="donation-matching-content">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={onTabChange}
          />
          {activeTab === 'donation-matching' && renderDonationMatchingTabContent()}
          {activeTab === 'volunteer-for-dollars' && renderPayoutsForVolunteerDollarsTabContent()}
        </div>
      </div>

      {showSummaryModal && (
        <MatchingProgramSummaryModal
          hub={props.hub}
          showModal={showSummaryModal}
          donationMatchingProgram={selectedProgram}
          onClose={() => {
            setShowSummaryModal(false);
            setSelectedProgram(null);
          }}
        />
      )}

      {showDetailsModal && (
        <DonationMatchingDetailsModal
          hub={props.hub}
          showModal={showDetailsModal}
          donationMatchingProgram={selectedProgram}
          onClose={() => {
            setShowDetailsModal(false);
            setSelectedProgram(null);
          }}
        />
      )}

      {showPayoutsSummaryModal && (
        <VolunteerCreditsSummaryModal
          paymentPackage={selectedPayout}
          hub={props.hub}
          showModal={showPayoutsSummaryModal}
          onClose={() => {
            setShowPayoutsSummaryModal(false);
            setSelectedPayout(null);
          }}
        />
      )}

      {showCreatePayoutModal && (
        <CreatePaymentPackageModal
          show={showCreatePayoutModal}
          hubId={props.hub?.id!}
          onClose={() => setShowCreatePayoutModal(false)}
          onCreatePaymentPackage={refreshTableData}
        />
      )}

      <Modal
        show={showAddProgramModal}
        onClose={() => {
          setShowAddProgramModal(false);
          setSelectedProgram(null);
        }}
        closeIcon="fas fa-times"
        class="AddProgramModal"
        title={`${selectedProgram ? 'Edit' : 'Create'} a Program`}
      >
        <ModalScrollContainer>
          <DonationMatchingProgramForm
            hub={props.hub}
            donationMatchingProgram={selectedProgram}
            onSubmit={handleSubmitProgramModal}
            onValidateForm={(isValid) => setIsSaveProgramDisabled(!isValid)}
          />
        </ModalScrollContainer>
        <ModalFooterActionContainer>
          <Button
            buttonType="secondary"
            text="Cancel"
            onClick={() => setShowAddProgramModal(false)}
          />
          <Button
            buttonType="primary"
            formId={MATCHING_PROGRAM_FORM_ID}
            type="submit"
            text="Save"
            isDisabled={isSaveProgramDisabled}
            loading={isProgramModalSubmitting}
          />
        </ModalFooterActionContainer>
      </Modal>
    </>
  );
}

export default HubManagementDonationMatching;
