import React from 'react';
import Dropdown from '../../../Dropdown/Dropdown';
import { handleInputChange } from '../../../../helpers';

export interface IOptions {
  label: string;
  value: string;
}

interface IProps {
  flow: string;
  selectedEvent: string;
  eventType: string;
  groupType: string;
  groupEventOptions: Array<IOptions>;

  updateEntityType(type: string, value: string): void;
  setExistingEvent(id: string): void;
  resetEvent(): void;
}

interface IState {
  showEventList: boolean;
  eventType: string;
  selectedEvent: string;
}

class EventSelection extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showEventList: false,
      eventType: '',
      selectedEvent: '',
    };
  }

  componentDidMount() {
    if (this.props.selectedEvent) {
      this.setState({
        selectedEvent: this.props.selectedEvent,
      });
    }

    if (this.props.eventType) {
      this.setState({
        eventType: this.props.eventType,
      });
    }
  }

  handleEventType() {
    this.props.resetEvent();
    this.props.updateEntityType('eventType', this.state.eventType);
  }

  renderHeaderTxt() {
    switch (this.props.flow) {
      case 'donations': {
        return 'What cause would you like to accept donations?';
      }
      case 'createStore': {
        return 'Create Store';
      }
      case 'createEvent': {
        return 'Create Event';
      }
      case 'createGig': {
        return 'Create Volunteer Opportunity';
      }
      case 'createGroup': {
        return 'Create Cause';
      }
      case 'volunteer': {
        return 'Volunteer';
      }
      case 'donate': {
        return 'Donate';
      }
      case 'findEvents': {
        return 'Find Events';
      }
      default: {
        return '';
      }
    }
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} group-selection`}>
        <h2>What event is this store for?</h2>
        <ul>
          {this.props.groupType !== 'new' && (
            <li
              onClick={() =>
                this.setState(
                  { showEventList: true, eventType: 'existing', selectedEvent: '' },
                  () => this.handleEventType(),
                )
              }
            >
              <span
                className={
                  this.state.eventType === 'existing' ? 'list-circle active' : 'list-circle'
                }
              >
                <i className="fal fa-users" />
              </span>
              Existing Event
            </li>
          )}
          <li
            onClick={() => {
              this.setState({ eventType: 'new', showEventList: false, selectedEvent: '' }, () =>
                this.handleEventType(),
              );
            }}
          >
            <span className={this.state.eventType === 'new' ? 'list-circle active' : 'list-circle'}>
              <i className="fa fa-plus" />
            </span>
            Create Event
          </li>
        </ul>

        {this.state.showEventList && (
          <Dropdown
            name="selectedEvent"
            shouldSort={true}
            label="Select an existing event"
            value={this.state.selectedEvent}
            options={this.props.groupEventOptions}
            onChange={(e) => {
              handleInputChange(e, this, false, this.props.setExistingEvent, true);
            }}
            notranslate="yes"
          />
        )}
      </div>
    );
  }
}

export default EventSelection;
