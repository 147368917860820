import React from 'react';
import { connect } from 'react-redux';
import Button from '../../../Button/Button';
import Portrait from '../../../Portrait/Portrait';
import { addItem, removeItem, clearCart } from '../../../../actions/cart';
import { IAppState } from '../../../../store';
import { ICartState } from '../../../../reducers/cart';
import { IStoreItem } from '@gigit/interfaces';
import { IEventState } from '../../../../reducers/event';
import { Constants } from '@gigit/constants';
import { formatCurrency } from '../../../../helpers';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import { userSelectors } from '../../../../selectors/user';
import { uiConstants } from '../../../../constants/uiConstants';

interface IProps {
  cartState: ICartState;
  eventState: IEventState;
  flow: string;
  fundraisingTicket: any;
  fundraisingTickets: Array<string>;
  fundraiser: any;
  locale: string;

  addTicket(ticket: any): void;
  addItem(item: any, parent_type: string, options?: { callback?: () => void }): void;
  clearCart(): void;
  removeItem(index: number): void;
  updateFundraiser(field: string, value: any): void;
}

interface IState {
  selectedTicket: string;
}

class FundraiseTickets extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedTicket: '',
    };

    this.isSelectedTicket = this.isSelectedTicket.bind(this);
    this.setTicket = this.setTicket.bind(this);
  }

  componentDidMount() {
    if (this.props.fundraiser?.ticket) {
      this.setState({
        selectedTicket: this.props.fundraiser.ticket.id,
      });
    }
  }

  isSelectedTicket(id: string) {
    // check if is in cart instead
    let idx = this.props.cartState.items.findIndex((item: IStoreItem) => {
      return item.id === id;
    });
    return idx !== -1 && id === this.state.selectedTicket;
  }

  clearTicketOnUpdate() {
    this.setState({
      selectedTicket: '',
    });
  }

  setTicket(ticket?: IStoreItem) {
    let ticketId = ticket?.id || '';

    if (this.isSelectedTicket(ticketId)) {
      this.setState(
        {
          selectedTicket: ticketId || '',
        },
        () => {
          this.props.addTicket(null);
          this.props.updateFundraiser('ticket', null);
          let removeIdx = this.props.cartState.items.findIndex((item: any, index: number) => {
            return item.id === ticketId;
          });

          if (this.props.cartState.items.length > 0) {
            this.props.removeItem(removeIdx);
          }
        },
      );
    } else {
      this.props.clearCart();
      this.setState(
        {
          selectedTicket: ticket?.id || '',
        },
        () => {
          if (ticket) {
            this.props.addTicket(ticket);
            let idx = this.props.cartState.items.findIndex((item: any, index: number) => {
              return item.id === this.state.selectedTicket;
            });

            if (idx === -1) {
              this.props.addItem({ ...ticket, ...{ quantity: 1 } }, 'event');
            }
          }
        },
      );
    }
  }

  render() {
    const currency =
      this.props.eventState.event.group?.account?.currency ?? Constants.default_campaign_currency;
    return (
      <div className={`form-step ${this.props.flow} fundraiser-tickets`}>
        <p>
          This event requires a ticketed registration in order to fundraise, please select a ticket.
        </p>
        {this.props.eventState?.fundraisingTickets?.map((item: IStoreItem) => {
          if (this.props.fundraisingTickets.indexOf(item?.id || 'null') !== -1) {
            let hasMedia = item.media?.[0]?.url;
            let ticketId = item?.id || '';
            return (
              <div
                key={ticketId}
                className={
                  this.isSelectedTicket(ticketId) ? 'fundraise-ticket selected' : 'fundraise-ticket'
                }
              >
                <div className="fundraise-ticket-inner">
                  <div className="portrait-wrap">
                    <Portrait
                      source={hasMedia ? hasMedia : ''}
                      size={40}
                    />
                  </div>
                  <div className="fundraise-ticket-info-wrap">
                    <div
                      className="title"
                      notranslate="yes"
                    >
                      {item.name}
                    </div>
                    <div
                      className="item-price"
                      notranslate="yes"
                    >
                      {formatCurrency(item.price, currency, this.props.locale)}
                    </div>
                    <div
                      className="item-desc"
                      notranslate="yes"
                    >
                      <QuillTextEditor
                        value={item.description || 'This item has no description.'}
                        readOnly={true}
                        preserveWhitespace={true}
                        theme={uiConstants.quill.readOnlyTheme}
                        modules={{
                          toolbar: [[], [], []],
                          clipboard: {
                            matchVisual: false,
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      this.isSelectedTicket(ticketId)
                        ? 'fundraise-ticket-add-wrap selected'
                        : 'fundraise-ticket-add-wrap'
                    }
                  >
                    <Button
                      onClick={() => this.setTicket(item)}
                      text={this.isSelectedTicket(ticketId) ? 'Remove' : 'Add'}
                      icon={this.isSelectedTicket(ticketId) ? 'fa fa-times' : 'fa fa-plus'}
                    ></Button>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    cartState: store.cartState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  addItem: addItem,
  removeItem: removeItem,
  clearCart: clearCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(FundraiseTickets);
