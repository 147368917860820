import React, { ChangeEvent, useEffect, useState } from 'react';
import { IAvailablePremiumFeature, IHub, IUserRole } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import { billingRequestActions, hubRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import './HubManagementMembers.scss';
import TextField from '../../../TextField/TextField';
import { useHistory } from 'react-router-dom';
import Loader from '../../../Loader/Loader';
import Portrait from '../../../Portrait/Portrait';
import { formatQuery, routes, swapRouteParams, typeHelpers } from '../../../../helpers';
import { Prompt } from '../../../Prompt/Prompt';
import Dropdown, { IOptions } from '../../../Dropdown/Dropdown';
import { localizeHelpers } from '../../../../localizeHelpers';
import Modal from '../../../Modal/Modal';
import { Constants } from '@gigit/constants';
import axios from 'axios';
import Table, { ITableProps } from '../../../shared/Table/Table';
import DefaultLink from '../../../shared/Link/Link';
import { Config } from '@gigit/config';

interface IProps {
  hub?: IHub;
}

const HubManagementMembers: React.FC<IProps> = (props) => {
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const [showPurchaseSeats, setShowPurchaseSeats] = useState<boolean>(false);
  const [memberToDelete, setMemberToDelete] = useState<{ id: string; name: string } | null>(null);
  const [hubMembers, setHubMembers] = useState<IUserRole[]>([]);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [activeSort, setActiveSort] = useState<boolean>(false);
  const [roleOptions, setRoleOptions] = useState<IOptions[]>([]);
  const [purchaseLoading, setPurchaseLoading] = useState<boolean>(false);
  const [seatCounter, setSeatCounter] = useState<number>(1);
  const [minSeats, setMinSeats] = useState<number>(1);
  const [seatPricing, setSeatPricing] = useState<IAvailablePremiumFeature | null>(null);

  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);

  const [showRoleChangePrompt, setShowRoleChangePrompt] = useState<boolean>(false);

  const [selectedRoleChange, setSelectedRoleChange] = useState<string>('');
  const [selectedMemberChange, setSelectedMemberChange] = useState<string>('');

  const [applyRolechange, setApplyRoleChange] = useState<boolean>(false);

  const history = useHistory();

  async function getHubRoles() {
    if (props?.hub?.id) {
      try {
        const result = await hubRequestActions.getHubRoles(props.hub.id);
        setRoleOptions(
          result.map((role) => {
            return {
              label: role.role_name || '',
              value: role.id || '',
            };
          }),
        );
      } catch (error) {
        dispatchToastError(error, 'Get Hub Roles');
      }
    }
  }

  async function updateMemberRole(roleId: string, memberId: string) {
    if (props?.hub?.id) {
      const nextRole = roleOptions.find((roleOption) => roleOption.value == roleId);
      const member = hubMembers.find((hubMember) => hubMember.user_id == memberId);
      const currentRole = roleOptions.find((roleOption) => roleOption.value == member?.role_id);

      // NOTE: Needs a better way to determine if a role requires a seat.
      const nonSeatRoles = ['supporter', 'follower'];

      if (currentRole?.value && nextRole?.value) {
        if (nonSeatRoles.indexOf(currentRole?.label.toLowerCase()) != -1) {
          if (nonSeatRoles.indexOf(nextRole.label.toLowerCase()) == -1) {
            setSelectedMemberChange(memberId);
            setSelectedRoleChange(roleId);
            setShowRoleChangePrompt(true);
          } else {
            await changeRole(roleId, memberId);
          }
        } else {
          await changeRole(roleId, memberId);
        }
      }
    }
  }

  async function changeRole(roleId: string, memberId: string) {
    if (props?.hub?.id) {
      try {
        await hubRequestActions.updateMemberRole(props.hub.id, roleId, memberId);
        dispatchToastSuccess('Successfully updated member role', 'Update member role');

        await getHubMembers();
        refreshTableData();
      } catch (error) {
        dispatchToastError(error, 'Update member role');
      }
    }
  }

  useEffect(() => {
    if (applyRolechange) {
      changeRole(selectedRoleChange, selectedMemberChange);
      setSelectedMemberChange('');
      setSelectedRoleChange('');
      setApplyRoleChange(false);
    }
  }, [applyRolechange]);

  useEffect(() => {
    billingRequestActions
      .getAvailableSubscriptions(Constants.billing.subscriptions.hub_seating.object_type)
      .then((resp) => {
        setSeatPricing(resp[0]);
      })
      .catch((error) => {
        dispatchToastError(error, 'Subscriptions');
      });
  }, []);

  async function getHubMembers() {
    if (props?.hub?.id) {
      try {
        const result = await hubRequestActions.getHubMembers(
          props.hub.id,
          formatQuery({
            sort: activeSort
              ? [
                  {
                    id: 'user.display_name',
                    order: 'desc',
                  },
                ]
              : undefined,
          }),
        );
        setHubMembers(result);
      } catch (error) {
        dispatchToastError(error, 'Get Hub Members');
      } finally {
        setLoadingMembers(false);
      }
    }
  }

  async function removeMember() {
    if (props?.hub?.id && memberToDelete?.id) {
      try {
        await hubRequestActions.removeHubMember(props.hub.id, memberToDelete.id);
        await getHubMembers();
        refreshTableData();
        dispatchToastSuccess('Successfully removed member', 'Remove Hub Members');
        setMemberToDelete(null);
      } catch (error) {
        dispatchToastError(error, 'Remove Hub Members');
      }
    }
  }

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  useEffect(() => {
    getHubMembers();
  }, [props.hub, activeSort]);

  function setMinSeatCounter(hubId: string) {
    billingRequestActions
      .getHubSubscriptionSummary(hubId)
      .then((response) => {
        const seatsFeature = response.features.find((feature) =>
          [
            Constants.billing.subscriptions.hub_seating.code,
            Constants.billing.subscriptions.hub_seating_do.code,
          ].includes(feature.feature_code),
        );

        setMinSeats(seatsFeature?.seats?.total || 1);
        if (seatsFeature?.seats?.total) {
          setSeatCounter(seatsFeature?.seats?.total);
        }
      })
      .catch((err) => {
        dispatchToastError(err, 'Purchase Seats');
      })
      .catch((error) => {
        dispatchToastError(error, 'Subscriptions');
      });
  }

  async function purchase() {
    setPurchaseLoading(true);
    try {
      const payload = {
        allocate_quantity: seatCounter - minSeats,
      };
      let currentSub;
      if (props.hub?.id) {
        currentSub = await billingRequestActions.getHubSubscriptionSummary(props.hub?.id);
      }

      let resp = await axios.post(
        swapRouteParams(routes.PURCAHSE_HUB_SEATS, {
          hub_id: props.hub?.id,
          subscription_code: Config.feature_flags.HUB_SEATING_DO
            ? currentSub?.features[0].feature_code
            : Constants.billing.subscriptions.hub_seating.code,
        }),
        payload,
      );
      dispatchToastSuccess(
        localizeHelpers.translate('Successfully purchased {{seats}} seats', {
          seats: payload.allocate_quantity,
        }),
        'Purchase Seats',
      );
      if (props.hub?.id) {
        setMinSeatCounter(props.hub?.id);
      }
    } catch (err) {
      setPurchaseLoading(false);
      if (minSeats == seatCounter) {
        dispatchToastError(
          localizeHelpers.translate(
            `You haven't purchased any new seats, you already own {{seats}} seats.`,
            {
              seats: seatCounter,
            },
          ),
          'Purchase Seats',
        );
      } else {
        dispatchToastError(err, 'Purchase Seats');
      }
    }
    setPurchaseLoading(false);
  }

  const membersTableMeta = {
    roleOptions,
    updateMemberRole,
  };

  const membersTableProps: ITableProps<IUserRole, typeof membersTableMeta> = {
    columns: [
      {
        id: 'user.handle',
        Header: 'Members Name',
        accessor: ({ user }) => user?.display_name,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ user }) => (
          <DefaultLink
            to={`/user/${user?.handle}`}
            className="item-name-col"
            notranslate="yes"
          >
            <Portrait
              size={60}
              className="image-container"
              currentImage={user?.profile_image_url}
            />
            <span notranslate="yes">{user?.display_name}</span>
          </DefaultLink>
        ),
      },
      {
        id: 'role_id',
        Header: 'Role',
        accessor: ({ user, role_id }) => role_id,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ user, role_id }, meta) => {
          return (
            <span className="role">
              {role_id ? (
                <>
                  <Dropdown
                    value={role_id}
                    onChange={(e) => {
                      if (user?.id) {
                        meta?.updateMemberRole(e.target.value, user.id);
                      }
                    }}
                    name={`member_role_${user?.id}`}
                    options={meta?.roleOptions || []}
                  />
                </>
              ) : (
                'N/A'
              )}
            </span>
          );
        },
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Remove Member',
          icon: 'fas fa-trash',
          onClick: async (_, member) => {
            if (member?.user?.id) {
              setMemberToDelete({
                id: member.user.id,
                name: member.user.display_name || '',
              });
            }
          },
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        try {
          typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Hub Type');
          if (!roleOptions.length) {
            await getHubRoles();
          }

          const result = await hubRequestActions.getHubMembers(props.hub.id, queryParams);
          setHubMembers(result);

          return result;
        } catch (error) {
          dispatchToastError(error, 'Get Hub Members');
          return [];
        }
      },
    },
    filterOptions: {
      enableTableSearch: true,
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any members",
    },
    meta: membersTableMeta,
  };

  return (
    <>
      <Prompt
        show={showRoleChangePrompt}
        title="Role Change"
        message="By changing the role of this Contact from Supporter to Member or Admin, you will be billed for an additional seat in your hub or an open seat will be consumed. Are you sure you want to change the role for this Contact?"
        yesMessage="Yes"
        cancelMessage="Cancel"
        onYes={() => {
          setApplyRoleChange(true);
          setShowRoleChangePrompt(false);
        }}
        onClose={() => setShowRoleChangePrompt(false)}
      />
      <div
        className="HubManagementMembers"
        id="members"
      >
        <div className="header-section">
          <h2>Members</h2>
          <div className="btn-container">
            <Button
              onClick={() =>
                history.push({
                  pathname: history.location.pathname,
                  search: '?t=seats&purchaseModal=true',
                })
              }
            >
              Purchase Seats
            </Button>
            <Button
              type="button"
              text="Invite Members"
              icon="fal fa-plus"
              onClick={() =>
                history.push({
                  pathname: history.location.pathname,
                  search: '?t=seats&invitesModal=true',
                })
              }
            />
          </div>
        </div>
        <div className="table-section">
          <Table
            {...membersTableProps}
            refreshTableIncrementor={refreshTableIncrementor}
          />
        </div>
      </div>

      <Modal
        show={showPurchaseSeats}
        class="SeatPurchaseModal"
      >
        <div className="seat-purchase-content">
          <div className="seat-purchase-details">
            <div className="details-title">Purchase Seats</div>
            <div className="details-payment">
              <div className="details-sub-title">Hub Seats</div>
              <div className="details-row">
                <div className="counter">
                  <div
                    onClick={() => {
                      if (seatCounter > minSeats) {
                        setSeatCounter(seatCounter - 1);
                      } else {
                        return;
                      }
                    }}
                    className={`counter-button sub ${seatCounter == minSeats ? 'disabled' : ''}`}
                  >
                    <i className="fa fa-minus" />
                  </div>
                  <TextField
                    className="counter-input"
                    value={seatCounter}
                    name={'seat-counter'}
                    type={'number'}
                    onKeyDown={(e) => {
                      if (e.key == 'ArrowDown' || e.key == 'ArrowUp') {
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                      }
                    }}
                    onChange={function (e: ChangeEvent<HTMLInputElement>): void {
                      if (parseInt(e.target.value) > 1000000) {
                        setSeatCounter(1000000);
                      } else {
                        if (parseInt(e.target.value) < minSeats) {
                          dispatchToastError(
                            localizeHelpers.translate(
                              'Seat amount cannot be less than already purchased',
                            ),
                            'Purchase Seats',
                          );
                        } else {
                          setSeatCounter(parseInt(e.target.value));
                        }
                      }
                    }}
                  />
                  <div
                    onClick={() => {
                      if (seatCounter <= 1000000) {
                        setSeatCounter(seatCounter + 1);
                      } else {
                        return;
                      }
                    }}
                    className={`counter-button add ${seatCounter == 1000000 ? 'disabled' : ''}`}
                  >
                    <i className="fa fa-plus" />
                  </div>
                </div>
                <div className="month-price">
                  <span
                    notranslate="yes"
                    className="price-amount"
                  >
                    {seatPricing
                      ? `$${
                          isNaN(seatPricing.monthly_price * seatCounter)
                            ? 0
                            : seatPricing.monthly_price * seatCounter
                        }`
                      : 'n/a'}
                  </span>{' '}
                  / Month
                </div>
                <div className="annual-price">
                  <span
                    notranslate="yes"
                    className="price-amount"
                  >
                    {seatPricing
                      ? `$${
                          isNaN(seatPricing.annual_price * seatCounter)
                            ? 0
                            : seatPricing.annual_price * seatCounter
                        }`
                      : 'n/a'}
                  </span>{' '}
                  / Billed Annually
                </div>
              </div>
            </div>
          </div>
          <div className="seat-purchase-summary">
            <div className="hub-banner">
              <img src={props.hub?.banner?.image || ''} />
              <span
                onClick={() => setShowPurchaseSeats(false)}
                className="close-icon"
              >
                <i className="fas fa-times" />
              </span>
            </div>
            <div className="hub-profile-img">
              <img src={props.hub?.profile_image_url} />
            </div>
            <div className="hub-summary-details">
              <span
                title={props.hub?.title}
                notranslate="yes"
                className="summary-details-title"
              >
                {props.hub?.title}
              </span>
              <span
                className="summary-details-description"
                notranslate="yes"
              >
                {props.hub?.banner?.banner_info?.description
                  ? props.hub?.banner.banner_info.description
                  : 'No description available.'}
              </span>
            </div>
            <div className="hub-summary-price">
              <div>{isNaN(seatCounter) ? 0 : seatCounter} Hub Seats</div>
              <div notranslate="yes">
                $
                {seatPricing
                  ? isNaN(seatPricing.annual_price * seatCounter)
                    ? 0
                    : seatPricing.annual_price * seatCounter
                  : 'n/a'}
              </div>
            </div>
            <div className="hub-summary-total">
              <div>Total Costs</div>
              <div notranslate="yes">
                $
                {seatPricing
                  ? isNaN(seatPricing.annual_price * seatCounter)
                    ? 0
                    : seatPricing.annual_price * seatCounter
                  : 'n/a'}
              </div>
            </div>
            <div className="hub-seats-actions">
              <Button
                onClick={() => setShowPurchaseSeats(false)}
                className="seat-cancel"
              >
                Cancel
              </Button>
              <Button
                className="seat-purchase"
                onClick={async () => {
                  if (!purchaseLoading) {
                    await purchase();
                  } else {
                    return;
                  }
                }}
              >
                {purchaseLoading ? (
                  <div className="payment-loading">
                    <Loader loading={purchaseLoading} />
                  </div>
                ) : (
                  'Purchase'
                )}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Prompt
        show={memberToDelete !== null}
        title="Delete Member"
        message={`Are you sure you want to delete a member ${memberToDelete?.name || ''}?`}
        yesMessage="Yes"
        yesClass="fa fa-trash"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={async () => removeMember()}
        onClose={() => setMemberToDelete(null)}
      />
    </>
  );
};

export default HubManagementMembers;
