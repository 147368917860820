import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { History } from 'history';

import { IEventSummaryFE } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';

import { IAppState } from '../../../../store';
import { combineClassNames, defaultCurrency, formatCurrency, round } from '../../../../helpers';
import { IEventState } from '../../../../reducers/event';

import './ChildEventListItem.scss';
import { Link } from 'react-router-dom';
import { userSelectors } from '../../../../selectors/user';
import Portrait from '../../../Portrait/Portrait';

interface IProps extends WithTranslation {
  event: IEventSummaryFE;
  eventState: IEventState;
  dragProvided?: DraggableProvidedDragHandleProps;
  hasEditPermissions?: boolean;
  history: History;
  locale: string;
}

interface IState {}

/** Component that renders a Child Event. */
class ChildEventListItem extends React.Component<IProps, IState> {
  gotoEvent() {
    this.props.history.push({
      pathname: `/event/${this.props.event.handle}`,
      key: Math.random().toString(),
    });
  }

  render() {
    const currency = this.props.eventState.event.group?.account?.currency ?? defaultCurrency;
    const raisedString = formatCurrency(this.props.event.raised ?? 0, currency, this.props.locale);
    const goalString = formatCurrency(this.props.event.goal ?? 0, currency, this.props.locale);
    const raised = this.props.event.raised ?? 0;
    const goal = this.props.event.goal ?? 0;
    const percent = goal ? Math.min(round((raised / goal) * 100, 0), 100) : 0;

    return (
      <div
        className={combineClassNames(
          'ChildEventListItem',
          this.props.hasEditPermissions ? 'admin' : null,
        )}
      >
        <Portrait
          source={this.props.event.profile_image_url}
          size={65}
        />

        <div className="event-title">{this.props.event.title}</div>
        <div className="event-raised">
          <span className="raised">{raisedString}</span> Raised of {goalString}
        </div>

        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${percent}%` }}
          />
        </div>

        <button
          className="view-event-button"
          onClick={() => this.gotoEvent()}
        >
          {this.props.t('View Event')}
        </button>
        <button
          className="view-event-button-mobile"
          onClick={() => this.gotoEvent()}
        >
          <i className="fal fa-chevron-right"></i>
        </button>

        <div className="list-admin-actions">
          <ul>
            <li {...this.props.dragProvided}>
              <i className="fas fa-arrows-alt"></i>
              <span>Drag</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ChildEventListItem),
);
