import React, { Component, useState } from 'react';
import './ProgressBar.scss';

interface IPassedProps {
  amountRaised: number;
  goal: number;
  formatCurrency: (amount: number) => string;
}

class ProgressBar extends Component<IPassedProps> {
  getProgressWidth = (raised: number, goal: number) => {
    if (raised && goal) {
      return (raised / goal) * 100 + '%';
    }

    return '0%';
  };

  render() {
    const { amountRaised, goal } = this.props;
    const progressWidth = this.getProgressWidth(amountRaised, goal);

    return (
      <div
        className="ProgressBar"
        notranslate="yes"
      >
        <div className="progress-bar-wrapper">
          <div className="progress-bar-bg">
            <div
              className="progress-bar"
              style={{ width: progressWidth }}
            ></div>
            <div
              className="amt"
              notranslate="yes"
            >
              {this.props.formatCurrency(amountRaised)}
            </div>
            <div
              className="goal"
              notranslate="yes"
            >
              {this.props.formatCurrency(goal)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
