import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import {
  defaultCurrency,
  handleInputChange,
  IStringMap,
  timeSince,
  swapRouteParams,
  routes,
  toastError,
} from '../../../helpers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { IPage, ICampaign } from '@gigit/interfaces';
import ComponentPlaceholder from '../ComponentPlaceholder/ComponentPlaceholder';
import { errorHelpers } from '../../../helpers/errorHelpers';
import { formatCurrency } from '../../../helpers';
import { IDonationListComponent, IToast } from '../../../interfaces';
import { userSelectors } from '../../../selectors/user';
import { localizeHelpers } from '../../../localizeHelpers';
import { createToast } from '../../../actions/toaster';
import { updateGroupPageComponent } from '../../../actions/group';
import { updateEventPageComponent } from '../../../actions/event';
import Portrait from '../../Portrait/Portrait';
import Button from '../../Button/Button';
import Loader from '../../Loader/Loader';
import { IGigState } from '../../../reducers/gig';
import TextField from '../../TextField/TextField';
import './Donations.scss';
import { IOwnerObject } from '../../../interfaces';
import typeHelpers from '../../../helpers/typeHelpers';

interface IProps extends RouteComponentProps<any> {
  gigState: IGigState;
  permissions?: IStringMap;
  component?: IDonationListComponent;
  page: IPage;
  locale: string;
  edit: boolean;
  ownerId?: string;
  createToast(toast: IToast): void;
  campaign?: ICampaign | null;
  updateGroupPageComponent(
    groupId: string,
    page_id: string,
    component_id: string,
    payload: any,
  ): void;
  updateEventPageComponent(
    eventId: string,
    page_id: string,
    component_id: string,
    payload: any,
  ): void;
  owner: IOwnerObject;
  hasEditPermissions?: boolean;
}

interface IState {
  donations: any[];
  isLoading: boolean;
  skip: number;
  limit: number;
  title: string;
  canLoadMore: boolean;
}

class Donations extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      donations: [],
      isLoading: false,
      skip: 0,
      limit: 5,
      title:
        (this.props.component?.meta_data && this.props.component?.meta_data.title) || 'Donations',
      canLoadMore: false,
    };

    this.loadMore = this.loadMore.bind(this);
    this.getDonations = this.getDonations.bind(this);
  }

  componentDidMount() {
    this.getDonations();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.edit && !this.props.edit) {
      this.update();
    }
  }

  loadMore() {
    this.setState(
      {
        skip: this.state.skip + this.state.limit,
      },
      () => {
        this.getDonations();
      },
    );
  }

  getDonations() {
    let isEvent = this.props.owner.ownerType === 'event';
    let limit = this.state.limit + 1;
    let route = isEvent ? routes.GET_PUBLIC_DONATIONS_EVENT : routes.GET_PUBLIC_DONATIONS_GROUP;
    let id = this.props.owner.ownerId ? this.props.owner.ownerId : this.props.page.owner_id;

    this.setState({
      isLoading: true,
    });

    axios
      .get(swapRouteParams(route, { id, limit, skip: this.state.skip }))
      .then((response) => {
        if (response.data?.length === 0) {
          this.setState(
            {
              isLoading: false,
            },
            () => {
              return;
            },
          );
        }

        let canLoadMore = response.data.length > this.state.limit;

        if (canLoadMore) {
          response.data.pop();
        }

        this.setState(
          {
            donations: [...this.state.donations, ...response.data],
            isLoading: false,
          },
          () => {
            if (canLoadMore) {
              this.setState({
                canLoadMore: true,
              });
            } else {
              this.setState({
                canLoadMore: false,
              });
            }
          },
        );
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Donations');
        this.props.createToast(toast);
      });
  }

  getTime(time: string) {
    let t: any = new Date(time);
    t = t.getTime() / 1000;
    let r = timeSince(t);
    return r;
  }

  renderTimeSince(time: string) {
    let t: any = new Date(time);
    t = t.getTime() / 1000;
    let timeSinceResult = timeSince(t);
    let pluralizeUnit = '';

    const interval = localizeHelpers.formatNumber(timeSinceResult.interval || 0, this.props.locale);

    if (interval !== '1') {
      pluralizeUnit = 's';
    }

    return (
      <div className="time">
        <var
          data-var="time_since_interval"
          pluralize={interval.toString()}
        >
          {interval}
        </var>{' '}
        {` ${timeSinceResult.unit + pluralizeUnit} ago`}
      </div>
    );
  }

  update() {
    if (this.props.component) {
      const payload = this.props.component;

      payload.meta_data = {
        title: this.state.title,
      };

      if (
        this.props.permissions &&
        this.props.permissions['EDIT_GROUP_PAGES'] &&
        this.props.owner.ownerType !== 'gig'
      ) {
        this.props.updateGroupPageComponent(
          this.props.page.owner_id,
          this.props.page.id,
          this.props.component.id,
          payload,
        );
      } else if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
        this.props.updateEventPageComponent(
          this.props.page.owner_id,
          this.props.page.id,
          this.props.component.id,
          payload,
        );
      }
    }
  }

  render() {
    const ownerHandle = this.props.owner.ownerHandle || this.props.page.owner_id;

    const donationLink = `/${this.props.owner.ownerType}/${ownerHandle}/donate`;

    const isActive = this.props.owner.isActive;
    return (
      <div className="Donations">
        {this.props.edit && (
          <TextField
            value={this.state.title}
            name="title"
            type="text"
            onChange={(e) => {
              handleInputChange(e, this);
            }}
          />
        )}
        {!this.props.edit && <h3>Donations</h3>}

        {this.state.donations.map((donation: any, index: number) => {
          const currency = this.props.owner.account?.currency ?? defaultCurrency;

          return (
            <div
              key={index}
              className="donation"
            >
              {donation?.profile_image_url && (
                <Portrait
                  source={donation.profile_image_url}
                  size={55}
                />
              )}
              {!donation?.profile_image_url && <Portrait size={55} />}
              <div className="donation-details">
                <div className="name">
                  <var data-var="display_name">{donation.display_name}</var> donated{' '}
                  <span>
                    <var data-var="amount">
                      {formatCurrency(donation.intended_amount, currency, this.props.locale)}
                    </var>
                  </span>
                </div>
                <div className="parents">
                  {donation.group_title && (
                    <div
                      className="parent"
                      notranslate="yes"
                    >
                      <Link to={'/group/' + donation.group_handle}>
                        <i className="fa fa-users" />
                        <span>{donation.group_title}</span>
                      </Link>
                    </div>
                  )}
                  {donation.event_title && (
                    <div
                      className="parent"
                      notranslate="yes"
                    >
                      <Link to={'/event/' + donation.event_handle}>
                        <i className="fa fa-ribbon" />
                        <span>{donation.event_title}</span>
                      </Link>
                    </div>
                  )}
                </div>
                {donation.comments && <div className="note">{donation?.comments}</div>}
                {/* <div className="time"><var data-var="time">{this.getTime(donation.created_at)}</var>{t(" ago")}</div> */}
                {this.renderTimeSince(donation.created_at)}
              </div>
            </div>
          );
        })}
        {this.state.isLoading && <Loader loading={true} />}
        <div className="controls">
          {this.state.canLoadMore && this.state.donations.length > 0 && (
            <div className="load-more">
              <Button
                onClick={() => {
                  this.loadMore();
                }}
                text="Load More"
              />
            </div>
          )}
        </div>
        {this.state.donations.length === 0 &&
          !this.state.isLoading &&
          this.props.hasEditPermissions && (
            <ComponentPlaceholder
              title="This group/event doesn't currently have any donations"
              message="This component will fill with information as donations are completed"
              actions={
                isActive &&
                typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'group', 'event')
                  ?.accepting_donations
                  ? [
                      {
                        label: 'Make a Donation',
                        iconClassName: 'fas fa-heart',
                        action: () => {
                          this.props.history.push(donationLink);
                        },
                      },
                    ]
                  : []
              }
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
    gigState: store.gigState,
    userState: store.userState,
    profileState: store.profileState,
  };
};

const mapDispatchToProps = {
  updateGroupPageComponent: updateGroupPageComponent,
  updateEventPageComponent: updateEventPageComponent,
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Donations);
