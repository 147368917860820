import React from 'react';
import { IKpi } from '../../interfaces';
import './KpiDisplay.scss';

interface IProps {
  kpiDataList: IKpi[];
}

class KpiDisplay extends React.Component<IProps> {
  render() {
    return (
      <div className="KpiDisplay">
        {this.props.kpiDataList.map((i, index) => (
          <div
            key={index}
            className="kpi-card"
          >
            <div className="kpi-text">{i.text}</div>
            <div
              className="kpi-value"
              notranslate="yes"
            >
              {i.value}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default KpiDisplay;
