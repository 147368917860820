import { IUserContactSummary } from '@gigit/interfaces';
import React, { FC, useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useToastDispatcher from '../../hooks/useToaster';
import { userRequestActions } from '../../requestActions';
import { chatSelectors } from '../../selectors/chat';
import { IAppState } from '../../store';
import { getOnlinePresence } from '../Chat/ChatHelpers';
import { PresenceIndicator } from '../Chat/PresenceIndicator/PresenceIndicator';
import Loader from '../Loader/Loader';
import Portrait from '../Portrait/Portrait';
import PopupMenu from '../shared/PopupMenu/PopupMenu';
import './ContactsList.scss';
import { ReactComponent as ContactsIcon } from '../../assets/contacts-icon.svg';
import { formatQuery } from '../../helpers';

const perPage = '10';
const ContactsList: FC = () => {
  const [contactsList, setContactsList] = useState<IUserContactSummary[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);

  const onlineUsers = useSelector((state: IAppState) => chatSelectors.getOnlineUsers(state));
  const history = useHistory();
  const { dispatchToastError } = useToastDispatcher();

  // Using this infinite scroll pattern during beta push until we discuss common patterns
  let observer: React.MutableRefObject<IntersectionObserver | undefined> =
    useRef<IntersectionObserver>();
  const lastItem = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && hasMore && contactsList.length >= parseInt(perPage)) {
          setPage((prevPageNumber) => prevPageNumber + 1);
          getMyContacts();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore],
  );

  useEffect(() => {
    getMyContacts();
  }, []);

  async function getMyContacts() {
    setIsLoading(true);
    try {
      const params = formatQuery({
        sort: [{ id: 'other_user.display_name', order: 'asc' }],
        limit: perPage,
        skip: (page * parseInt(perPage)).toString(),
      });
      const contacts = await userRequestActions.getMyContacts(params);
      setHasMore(contacts.length > 0);
      if (page === 0) {
        setContactsList(contacts);
      } else {
        setContactsList((previousContacts) => [...previousContacts, ...contacts]);
      }
    } catch (error) {
      dispatchToastError(error, 'User Contacts');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="ContactsList">
      {isLoading && <Loader loading={isLoading} />}
      <ul>
        {contactsList.map((contact: IUserContactSummary, index: number) => {
          const popupMenuItems: any = [
            {
              id: 'profile',
              label: 'View Profile',
              icon: '',
              onClick: () => {
                history.push(`/user/${contact.other_user.handle}`);
              },
            },
            {
              id: 'message',
              label: 'Message',
              icon: '',
              onClick: () => {
                history.push(
                  `/dashboard?tab=network&contact=${contact.other_user.id}&networkTab=messages`,
                );
              },
            },
            {
              id: 'report',
              label: 'Report',
              icon: '',
              onClick: () => {},
            },
            {
              id: 'remove_connection',
              label: 'Remove Connection',
              icon: '',
              onClick: async () => {
                try {
                  await userRequestActions.removeContact(contact.other_user.id || '');
                  getMyContacts();
                } catch (error) {
                  dispatchToastError(error, 'Remove Connection');
                }
              },
            },
          ];

          return (
            <li key={index}>
              <div className="ConversationTile">
                <Portrait
                  onClick={() => {
                    history.push(`/user/${contact.other_user.handle}`);
                  }}
                  source={contact.other_user.profile_image_url}
                  size={40}
                />
                <PresenceIndicator
                  isOnline={Boolean(getOnlinePresence(contact.other_user.id, onlineUsers))}
                />

                <div className="convo-li">
                  <div
                    className="convo-li-title"
                    notranslate="yes"
                    onClick={() => {
                      history.push(`/user/${contact.other_user.handle}`);
                    }}
                  >
                    {contact.other_user?.display_name}
                  </div>
                  <div
                    className="convo-li-preview"
                    notranslate="yes"
                  >
                    {getOnlinePresence(contact.other_user.id, onlineUsers) ? 'Online' : 'Offline'}
                  </div>
                </div>
                <PopupMenu
                  showMenuConfig={{
                    position: {
                      type: 'bottom',
                      alternate: 'start',
                    },
                    showMenu: showMenu === contact.other_user.id,
                    setShowMenu: () =>
                      showMenu === contact.other_user.id
                        ? setShowMenu('')
                        : setShowMenu(contact.other_user.id || ''),
                  }}
                  menuItems={popupMenuItems}
                  className={`action-menu-target`}
                  onClick={() => {
                    showMenu === contact.other_user.id
                      ? setShowMenu('')
                      : setShowMenu(contact.other_user.id || '');
                  }}
                >
                  <i className={`fa fa-ellipsis-v ${showMenu ? 'show-menu' : ''}`} />
                </PopupMenu>
              </div>
            </li>
          );
        })}
        {contactsList.length < parseInt(perPage) ||
          (!!contactsList && (
            <li
              ref={lastItem}
              className="end-infinite-scroll"
            />
          ))}
      </ul>
      {contactsList.length === 0 && (
        <div className="no-contacts">
          <ContactsIcon />
          <p>You have no contacts.</p>
        </div>
      )}
    </div>
  );
};

export default ContactsList;
