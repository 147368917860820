import React from 'react';

import './CommunityFeedLeftSkeleton.scss';

export default function CommunityFeedLeftSkeleton() {
  return (
    <>
      <div className="CommunityFeedLeftSkeleton">
        <div className="cover-img"></div>
        <div className="profile-img"></div>
        <div className="profile-name"></div>

        <div className="metrics">
          <div className="metric-container">
            <div className="metric-title"></div>
            <div className="metric-amount"></div>
          </div>
          <div className="metric-container">
            <div className="metric-title"></div>
            <div className="metric-amount"></div>
          </div>
          <div className="metric-container">
            <div className="metric-title"></div>
            <div className="metric-amount"></div>
          </div>
          <div className="metric-container">
            <div className="metric-title"></div>
            <div className="metric-amount"></div>
          </div>
          <div className="metric-container">
            <div className="metric-title"></div>
            <div className="metric-amount"></div>
          </div>
        </div>
      </div>

      <div className="CommunityFeedLeftSkeleton StartingButtons">
        <div className="title"></div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
}
