import { IHub, IPage } from '@gigit/interfaces';
import React, { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { IOwnerObject } from '../../../../interfaces';
import PageComponent from '../PageComponent/PageComponent';
import './PageContainer.scss';
import useToastDispatcher from '../../../../hooks/useToaster';
import { hubRequestActions } from '../../../../requestActions';
import { IHubState, IThemeStyleObjectsByType } from '../../Hub';
import { userSelectors } from '../../../../selectors/user';
import { IAppState } from '../../../../store';

const DRAGGABLE_ID_PREFIX = 'PAGE_CONTAINER_DRAGGABLE_';
interface IPageContainerProps {
  ownerObject: IOwnerObject;
  page: IPage | null;
  userHasEditPermissions: boolean;
  isPageLoading: boolean;
  showEditMode: boolean;
  hub: IHub | null;
  theme: IThemeStyleObjectsByType;
  fetchPagesAfterSave(): void;
  updateHubTab(index: number): void;
  dispatchToggleModalByKey(key: keyof IHubState, value: boolean): void;
  tryRefreshHub(): void;
}

//TODO: Permissions
function PageContainer(props: IPageContainerProps) {
  const { dispatchToastError } = useToastDispatcher();
  const pageComponents = props.page?.components;
  const [isComponentDragging, setIsComponentDragging] = useState(false);
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );

  function renderLoadingState() {}

  function renderEmptyState() {}

  function showEditModeComponentOverlay() {
    return (
      props.userHasEditPermissions && isUserLoggedIn && props.showEditMode && !isComponentDragging
    );
  }

  function handleComponentDragStart() {
    setIsComponentDragging(true);
  }

  async function handleComponentDragEnd(result: DropResult, provided: ResponderProvided) {
    setIsComponentDragging(false);

    if (props.page?.components) {
      const tmpPageComponentDragged = props.page.components.find(
        (component) => component.sequence === result.source.index + 1,
      );
      const tmpPageComponentToReplace = props.page.components.find(
        (component) => component.sequence === (result.destination?.index || 0) + 1,
      );
      let errorOccurred = false;

      if (tmpPageComponentDragged && tmpPageComponentToReplace && props.hub?.id) {
        try {
          await hubRequestActions.updateHubPageComponent(
            props.hub.id,
            props.page.id,
            tmpPageComponentDragged.id,
            {
              ...tmpPageComponentDragged,
              sequence: (result.destination?.index || 0) + 1,
            },
          );
        } catch (error) {
          dispatchToastError(error, 'Error Update Hub Page Component Sequence dragged');
          errorOccurred = true;
        }

        try {
          await hubRequestActions.updateHubPageComponent(
            props.hub.id,
            props.page.id,
            tmpPageComponentToReplace.id,
            {
              ...tmpPageComponentToReplace,
              sequence: result.source.index + 1,
            },
          );
        } catch (error) {
          dispatchToastError(error, 'Error Update Hub Page Component Sequence to replace');
          errorOccurred = true;
        }

        if (!errorOccurred) {
          props.fetchPagesAfterSave();
        }
      }
    }
  }

  function renderPageComponents() {
    return pageComponents?.map((component, index) => {
      const shouldRenderComponent = component.visibility === 'public';
      if (shouldRenderComponent) {
        return (
          <Draggable
            isDragDisabled={!props.userHasEditPermissions || !isUserLoggedIn || !props.showEditMode}
            key={DRAGGABLE_ID_PREFIX + component.id}
            draggableId={DRAGGABLE_ID_PREFIX + component.id}
            index={index}
          >
            {(provided, snapshot) => {
              return (
                <div
                  className={snapshot.isDragging ? 'dragging' : ''}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                >
                  <PageComponent
                    page={props.page}
                    isPageLoading={props.isPageLoading}
                    dragHandleProps={provided.dragHandleProps}
                    ownerObject={props.ownerObject}
                    pageComponent={component}
                    hub={props.hub}
                    showEditMode={showEditModeComponentOverlay()}
                    theme={props.theme}
                    onPageComponentSave={props.fetchPagesAfterSave}
                    userHasEditPermissions={props.userHasEditPermissions}
                    onHeaderActionClick={props.updateHubTab}
                    dispatchToggleModalByKey={props.dispatchToggleModalByKey}
                    tryRefreshHub={props.tryRefreshHub}
                    // onDelete={() => { this.props.onDeleteComponent?.(item.id); }}
                  />
                </div>
              );
            }}
          </Draggable>
        );
      }
    });
  }

  return (
    <div className="PageContainerComponent">
      <DragDropContext
        onDragStart={handleComponentDragStart}
        onDragEnd={handleComponentDragEnd}
      >
        <Droppable
          type="HUB_ITEM_DROPPABLE"
          droppableId="PageContainer-components-droppable"
        >
          {(provided, snapshot) => (
            <div
              className={snapshot.isDraggingOver ? 'components-wrap dragging' : 'components-wrap'}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {!props.isPageLoading &&
                pageComponents &&
                pageComponents.length > 0 &&
                renderPageComponents()}
              {!props.isPageLoading &&
                pageComponents &&
                pageComponents.length === 0 &&
                renderEmptyState()}
              {props.isPageLoading && renderLoadingState()}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default PageContainer;
