import { Dispatch } from 'redux';
import axios from 'axios';
import {
  IGroup,
  IGigSummary,
  IRole,
  IPage,
  IEventSummaryFE,
  IStoreItem,
  IAddress,
  IDiscountCode,
  ICampaign,
  IPageComponent,
  ISponsor,
  ISponsorshipSettings,
  IInvitesCreateInfo,
  IInvite,
  IDonorClassificationReportOptions,
  ITransaction,
  IApplication,
  IDonationRequest,
  ITransactionSummary,
} from '@gigit/interfaces';

import { Config } from '@gigit/config';
import {
  routes,
  swapRouteParams,
  dataURItoBlob,
  toastError,
  uploadMedias,
  toastSuccess,
  typeHelpers,
} from '../helpers';
import { IToastAction, StatisticType } from '../interfaces';
import { initialGroupState, IFullForm } from '../reducers/group';
import { getUserGroups, getUserGroupApplications } from './user';
import { volunteerForGig, createGroupGig } from './gig';
import { createToast } from './toaster';
import { createEvent, updateEvent, getEventPurchases } from './event';
import errorHelpers from '../helpers/errorHelpers';
import { localizeHelpers } from '../localizeHelpers';

export enum GroupActionTypes {
  UPDATE_GROUP = 'UPDATE_GROUP',
  UPDATE_GROUP_PAGES = 'UPDATE_GROUP_PAGES',
  UPDATE_GROUP_EVENTS = 'UPDATE_GROUP_EVENTS',
  UPDATE_GROUP_FORMS = 'UPDATE_GROUP_FORMS',
  UPDATE_GROUP_LOCATIONS = 'UPDATE_GROUP_LOCATIONS',
  UPDATE_GROUP_GIGS = 'UPDATE_GROUP_GIGS',
  UPDATE_GROUP_CAMPAIGNS = 'UPDATE_GROUP_CAMPAIGNS',
  CONNECT_ACCOUNT = 'CONNECT_ACCOUNT',
  UPDATE_GROUP_APPLICATIONS = 'UPDATE_APPLICATIONS',
  UPDATE_GROUP_VOLUNTEERS = 'UPDATE_GROUP_VOLUNTEERS',
  UPDATE_GROUP_SIGNATURE = 'UPDATE_GROUP_SIGNATURE',
  GET_GROUP_SIGNATURE = 'GET_GROUP_SIGNATURE',
  UPDATE_GROUP_PURCHASES = 'UPDATE_GROUP_PURCHASES',
  GET_GROUP_SPONSORS = 'GET_GROUP_SPONSORS',
  UPDATE_GROUP_INVITATIONS = 'UPDATE_GROUP_INVITATIONS',
}
export interface IUpdateGroupAction {
  group: IGroup;
  groupRoles: IRole[];
  groupForms: IFullForm[];
  groupStoreItems: IStoreItem[];
  groupDiscountCodes: IDiscountCode[];
  groupApplications: IApplication[];
  groupInvitations: IInvite[];
  error: string;
  isGroupLoading: boolean;
  isHandleLoading: boolean;
  isGroupRolesLoading: boolean;
  isGroupInvitationsLoading: boolean;
  successfulPurchase: any;
  bidSuccess: boolean;
  bidError: string;
  type: GroupActionTypes.UPDATE_GROUP;
}

export interface IUpdateGroupPagesAction {
  isGroupPagesLoading: boolean;
  groupPages: IPage[];
  type: GroupActionTypes.UPDATE_GROUP_PAGES;
}

export interface IUpdateGroupEventsAction {
  isGroupEventsLoading: boolean;
  groupEvents: IEventSummaryFE[];
  type: GroupActionTypes.UPDATE_GROUP_EVENTS;
}

export interface IUpdateGroupGigsAction {
  isGroupGigsLoading: boolean;
  groupGigs: IGigSummary[];
  type: GroupActionTypes.UPDATE_GROUP_GIGS;
}

export interface IUpdateGroupFormsAction {
  isGroupPagesLoading: boolean;
  groupPages: IPage[];
  type: GroupActionTypes.UPDATE_GROUP_FORMS;
}

export interface IUpdateGroupLocationsAction {
  groupLocations: IAddress[];
  type: GroupActionTypes.UPDATE_GROUP_LOCATIONS;
}

export interface IUpdateGroupCampaignsAction {
  groupCampaigns: ICampaign[];
  type: GroupActionTypes.UPDATE_GROUP_CAMPAIGNS;
}

export interface IUpdateGroupApplicationsAction {
  groupApplications: IApplication[];
  type: GroupActionTypes.UPDATE_GROUP_APPLICATIONS;
}

export interface IUpdateGroupVolunteersAction {
  groupVolunteers: any;
  type: GroupActionTypes.UPDATE_GROUP_VOLUNTEERS;
}

export interface IUpdateGroupSignatureAction {
  groupSignature: any;
  type: GroupActionTypes.UPDATE_GROUP_SIGNATURE;
}

export interface IGetGroupSignatureAction {
  groupSignature: any;
  type: GroupActionTypes.GET_GROUP_SIGNATURE;
}

export interface IConnectAccount {
  type: GroupActionTypes.CONNECT_ACCOUNT;
}

export interface IUpdateGroupPurchases {
  groupPurchases: any;
  type: GroupActionTypes.UPDATE_GROUP_PURCHASES;
}

export interface IGetGroupSponsors {
  groupSponsors: Array<ISponsor>;
  type: GroupActionTypes.GET_GROUP_SPONSORS;
}

export interface IUpdateGroupInvitationsAction {
  isGroupInvitationsLoading: boolean;
  groupInvitations: IInvite[];
  type: GroupActionTypes.UPDATE_GROUP_INVITATIONS;
}

export type GroupActions =
  | IUpdateGroupAction
  | IUpdateGroupPagesAction
  | IUpdateGroupEventsAction
  | IUpdateGroupFormsAction
  | IUpdateGroupLocationsAction
  | IUpdateGroupGigsAction
  | IUpdateGroupCampaignsAction
  | IUpdateGroupApplicationsAction
  | IUpdateGroupVolunteersAction
  | IUpdateGroupSignatureAction
  | IGetGroupSignatureAction
  | IUpdateGroupPurchases
  | IGetGroupSponsors
  | IUpdateGroupInvitationsAction;

/** Fetches a group by handle or by id.
 * @param handleOrId Either the handle or the id of the group.
 */ {
}
export const getGroup = (handleOrId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupLoading: true,
      groupPages: [],
      groupRoles: [],
      currentUserRole: initialGroupState.currentUserRole,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    axios
      .get(swapRouteParams(routes.GET_GROUP, { handleOrId: handleOrId }))
      .then((response) => {
        if (response.data !== '') {
          dispatch({
            group: response.data,
            volunteerError: '',
            volunteerSuccess: false,
            error: '',
            donationIntent: null,
            subscriptionSuccess: null,
            subscriptionError: '',
            purchaseSuccess: null,
            purchaseError: '',
            groupShiftUsers: [],
            currentGroupForm: {
              questions: [],
              owner_type: '',
              owner_id: '',
              owner_handle: '',
              form_name: '',
            },
            type: GroupActionTypes.UPDATE_GROUP,
          });

          getGroupPages(response.data.id)(dispatch, getState);

          if (response.data.campaign_id) {
            getGroupCampaign(response.data.id, response.data.campaign_id)(dispatch, getState);
          } else {
            dispatch({
              currentCampaign: null,
              type: GroupActionTypes.UPDATE_GROUP,
            });
          }

          if (getState().userState.isLoggedIn) {
            getCurrentUserGroupRole(response.data.id)(dispatch, getState);
            getGroupLocations(response.data.id)(dispatch, getState);
            getGroupCampaigns(response.data.id)(dispatch, getState);
            getGroupConversationMembers(response.data.id)(dispatch, getState);
            getGroupEvents(response.data.id)(dispatch, getState);
          } else {
            dispatch({
              isGroupLoading: false,
              type: GroupActionTypes.UPDATE_GROUP,
            });
          }
        } else {
          dispatch({
            isGroupLoading: false,
            group: initialGroupState.group,
            type: GroupActionTypes.UPDATE_GROUP,
          });
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause');
        createToast(toast)(dispatch, getState);

        dispatch({
          isGroupLoading: false,
          group: initialGroupState.group,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .finally(() => {
        dispatch({
          isGroupLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const getGroupCampaign = (groupId: string, _id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_GROUP_CAMPAIGN, { groupId: groupId, id: _id }))
      .then((response) => {
        dispatch({
          currentCampaign: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Campaign');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isGroupLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const getGroupLocations = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_GROUP_LOCATIONS, { groupId: groupId }))
      .then((response) => {
        dispatch({
          groupLocations: response.data,
          type: GroupActionTypes.UPDATE_GROUP_LOCATIONS,
        });
      });
  };
};

export const getGroupApplications = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_GROUP_APPLICATIONS, { groupId: groupId }))
      .then((response) => {
        dispatch({
          groupApplications: response.data,
          type: GroupActionTypes.UPDATE_GROUP_APPLICATIONS,
        });
      });
  };
};

export const getGroupVolunteers = (groupId: string, query?: URLSearchParams) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      volunteersLoading: true,
      type: GroupActionTypes.UPDATE_GROUP_VOLUNTEERS,
    });

    let _route = swapRouteParams(routes.GET_GROUP_VOLUNTEERS, { groupId: groupId });

    const queryString = query?.toString();
    if (queryString) {
      _route += `?${queryString}`;
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          groupVolunteers: response.data,
          type: GroupActionTypes.UPDATE_GROUP_VOLUNTEERS,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Volunteers');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          volunteersLoading: false,
          type: GroupActionTypes.UPDATE_GROUP_VOLUNTEERS,
        });
      });
  };
};

export const getGroupCampaigns = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_GROUP_CAMPAIGNS, { groupId: groupId }))
      .then((response) => {
        dispatch({
          groupCampaigns: response.data,
          type: GroupActionTypes.UPDATE_GROUP_CAMPAIGNS,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Campaigns');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGroupRaisedAmount = (groupId: string, campaign_id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    return axios
      .put(swapRouteParams(routes.UPDATE_CAMPAIGN_RAISED_AMOUNT, { groupId, campaign_id }))
      .then((response) => {
        getGroupCampaigns(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Campaign Raised Amount');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createGroupCampaign = (
  groupId: string,
  payload: any,
  connect?: boolean,
  eventId?: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_GROUP_CAMPAIGN, { groupId: groupId }), payload)
      .then((response) => {
        getGroupCampaigns(groupId)(dispatch, getState);

        if (response && response.data && response.data.id && payload.startingNumber) {
          axios.post(
            swapRouteParams(routes.GET_RECEIPT_NUMBER, {
              groupId: groupId,
              campaign_id: response.data.id,
            }),
            { value: payload.startingNumber },
          );
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Cause Campaign');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGroupCampaign = (groupId: string, _campaignId: string, payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(
        swapRouteParams(routes.UPDATE_GROUP_CAMPAIGN, {
          groupId: groupId,
          campaignId: _campaignId,
        }),
        payload,
      )
      .then((response) => {
        getGroupCampaigns(groupId)(dispatch, getState);

        if (payload.startingNumber) {
          axios.post(
            swapRouteParams(routes.GET_RECEIPT_NUMBER, {
              groupId: groupId,
              campaign_id: _campaignId,
            }),
            { value: payload.startingNumber },
          );
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Cause Campaign');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const refundDonation = (
  groupId: string,
  _donationId: string,
  _payload: any,
  callback?: (transaction: ITransaction) => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.REFUND_DONATION, { groupId: groupId, donationId: _donationId }),
        _payload,
      )
      .then((response) => {
        const toast = toastSuccess(localizeHelpers.translate('Donation Refunded.'), 'Donation');
        createToast(toast)(dispatch, getState);
        getGroupDonations(groupId)(dispatch, getState);
        callback?.(response.data);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Refund');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateThankYouMessage = (
  groupId: string,
  _transactionId: string,
  _payload: any,
  callback?: (transaction: ITransaction) => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(
        swapRouteParams(routes.UPDATE_THANK_YOU_MESSAGE, {
          groupId: groupId,
          transaction_id: _transactionId,
        }),
        _payload,
      )
      .then((response) => {
        getGroupDonations(groupId)(dispatch, getState);
        callback?.(response.data);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Thank You Message');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateTransactionPayment = (
  groupId: string,
  _transactionId: string,
  _payload: any,
  callback?: any,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.UPDATE_TRANSACTION_PAYMENT_STATUS, {
          groupId: groupId,
          transaction_id: _transactionId,
        }),
        _payload,
      )
      .then((response) => {
        const toast = toastSuccess(
          localizeHelpers.translate('Purchase successfully marked as payed'),
          'Store Purchase',
        );
        createToast(toast)(dispatch, getState);
        getGroupPurchases(groupId)(dispatch, getState);
        const eventId = response.data.event_id;
        if (eventId) {
          getEventPurchases(eventId)(dispatch, getState);
        }
        callback?.();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Mark as Paid');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const refundPurchase = (groupId: string, _transactionId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.REFUND_GROUP_STORE_PURCHASE, {
          groupId: groupId,
          transaction_id: _transactionId,
        }),
        _payload,
      )
      .then((response) => {
        const toast = toastSuccess(
          localizeHelpers.translate('Purchase Refunded.'),
          'Store Purchase',
        );
        createToast(toast)(dispatch, getState);
        getGroupPurchases(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Refund Purchase');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const sendConfirmationEmail = (groupId: string, _transactionId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.SEND_CONFIRMATION_EMAIL, {
          groupId: groupId,
          transaction_id: _transactionId,
        }),
      )
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Send Confirmation Email');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const setGroupSignature = (groupId: string, payload: any, silenceToast?: boolean) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.SET_GROUP_SIGNATURE, { groupId: groupId }), payload)
      .then((response) => {
        dispatch({
          groupSignature: response.data,
          type: GroupActionTypes.UPDATE_GROUP_SIGNATURE,
        });
        getGroupSignature(groupId)(dispatch, getState);

        if (!silenceToast) {
          const toast = toastSuccess(
            localizeHelpers.translate('Signature successfully updated.'),
            'Cause Signature',
          );
          createToast(toast)(dispatch, getState);
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Signature');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getGroupSignature = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_GROUP_SIGNATURE, { groupId: groupId }))
      .then((response) => {
        dispatch({
          groupSignature: response.data,
          type: GroupActionTypes.UPDATE_GROUP_SIGNATURE,
        });
      });
  };
};

export const getGroupPurchases = (
  groupId: string,
  _query?: URLSearchParams,
  options?: { callback?: (results: ITransactionSummary[]) => void; limit: string },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      groupPurchasesLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    let _route = swapRouteParams(routes.GROUP_PURCHASE, { groupId: groupId });

    const queryString = _query?.toString();
    if (queryString) {
      _route = _route + `?${queryString}`;
    }

    axios
      .get(_route)
      .then((response) => {
        let originalResponse = [...response.data];
        if (options?.limit && originalResponse.length > parseInt(options?.limit)) {
          response.data.pop();
        }
        dispatch({
          groupPurchases: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });

        // https://app.clickup.com/t/8404472/GIG-6167 - used to set hasMore in StorePurchases.tx
        if (options?.callback) {
          options?.callback(originalResponse);
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Purchase');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          groupPurchasesLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const setCampaignConnect = (groupId: string, _campaign: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      ..._campaign,
      type: GroupActionTypes.UPDATE_GROUP,
    });
  };
};

export const closeGroupCampaign = (groupId: string, id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CLOSE_GROUP_CAMPAIGN, { groupId: groupId, campaignId: id }))
      .then((response) => {
        getGroupCampaigns(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Close Campaign');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const resetGroupPermissions = () => {
  return async (dispatch: Dispatch, getState: any) => {
    let _groupState = { ...getState().groupState };

    _groupState.currentUserRole = initialGroupState.currentUserRole;

    dispatch({
      ..._groupState,
      type: GroupActionTypes.UPDATE_GROUP,
    });
  };
};

export const resetGroupState = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      ...initialGroupState,
      type: GroupActionTypes.UPDATE_GROUP,
    });
  };
};

export const resetGroupStateLocations = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      groupLocations: [],
      type: GroupActionTypes.UPDATE_GROUP,
    });
  };
};

export const getGroupForms = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupFormsLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    axios
      .get(swapRouteParams(routes.CREATE_FORM, { groupId: groupId }))
      .then((response) => {
        dispatch({
          groupForms: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .finally(() => {
        dispatch({
          isGroupFormsLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const createGroupForm = (groupId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isCreatingGroupForm: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    axios
      .post(swapRouteParams(routes.CREATE_FORM, { groupId: groupId }), _payload)
      .then((response) => {})
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Cause Form');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isCreatingGroupForm: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const getGroupFormQuestions = (groupId: string, _formId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_FORM_QUESTIONS, { groupId: groupId, formId: _formId }))
      .then((response) => {
        let _forms = [...getState().groupState.groupForms];

        for (let f in _forms) {
          if (_forms[f].id === _formId) {
            let _form = { ..._forms[f] };

            _form.questions = response.data;

            _forms[f] = _form;

            dispatch({
              groupForms: _forms,
              type: GroupActionTypes.UPDATE_GROUP,
            });
          }
        }
      });
  };
};

export const groupApplicationsForUser = (groupId: string, userId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupApplicationsForReviewLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });
    axios
      .get(
        swapRouteParams(routes.GET_GROUP_APPLICATIONS_BY_USER_HANDLE, {
          groupId: groupId,
          userId: userId,
        }),
      )
      .then((response) => {
        const applications = response.data;

        dispatch({
          groupApplicationsForReview: applications,
          type: GroupActionTypes.UPDATE_GROUP,
        });

        dispatch({
          isGroupApplicationsForReviewLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
        getGroupApplications(groupId)(dispatch, getState);
      });
  };
};

export const addVolunteer = (
  groupId: string,
  _payload: any,
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.ADD_VOLUNTEER, { groupId: groupId }), _payload)
      .then((response) => {
        getGroupVolunteers(groupId)(dispatch, getState);
        getGroupApplications(groupId)(dispatch, getState);

        options?.callback?.();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Add Volunteer');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGroupForm = (groupId: string, _formId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isUpdatingGroupForm: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    if (_payload.formName !== undefined) {
      axios
        .put(swapRouteParams(routes.UPDATE_FORM, { groupId: groupId, formId: _formId }), {
          form_name: _payload.formName,
          questions: _payload.questions,
        })
        .then((response) => {
          let _forms = [...getState().groupState.groupForms];

          for (let f in _forms) {
            if (_forms[f].id === _formId) {
              let _form = { ..._forms[f] };

              _form.form_name = _payload.formName;

              _forms[f] = _form;

              dispatch({
                groupForms: _forms,
                type: GroupActionTypes.UPDATE_GROUP,
              });
            }
          }
        })
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          let toast = toastError(errorObj.translatedMessage, 'Update Cause Form');
          createToast(toast)(dispatch, getState);
        })
        .finally(() => {
          dispatch({
            isUpdatingGroupForm: false,
            type: GroupActionTypes.UPDATE_GROUP,
          });
        });
    }
  };
};

/**
 * @deprecated - if you need the form you'll find it under IGroupSummery
 */
export const getGroupForm = (groupId: string, _form_id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_GROUP_FORM, { groupId: groupId, form_id: _form_id }))
      .then((response) => {
        dispatch({
          currentGroupForm: response.data,
          volunteerSuccess: false,
          volunteerError: '',
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Get Cause Form');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const deleteGroupForm = (groupId: string, _formId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(swapRouteParams(routes.DELETE_FORM, { groupId: groupId, formId: _formId }))
      .then((response) => {})
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Cause Form');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const deleteGroupQuestion = (groupId: string, _formId: string, _questionId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(
        swapRouteParams(routes.DELETE_FORM_QUESTION, {
          groupId: groupId,
          formId: _formId,
          questionId: _questionId,
        }),
      )
      .then((response) => {})
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Cause Question');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const joinGroup = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.JOIN_GROUP, { groupId: groupId }))
      .then((response) => {
        getUserGroups()(dispatch, getState);
        getCurrentUserGroupRole(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Join Cause');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getCurrentUserGroupRole = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isCurrentRoleLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    axios
      .get(swapRouteParams(routes.GET_CURRENT_USER_GROUP_ROLE, { groupId: groupId }))
      .then((response) => {
        dispatch({
          currentUserRole: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        dispatch({
          currentUserRole: initialGroupState.currentUserRole,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .finally(() => {
        dispatch({
          isCurrentRoleLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const createGroupPageComponent = (groupId: string, _pageId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.CREATE_GROUP_PAGE_COMPONENT, { groupId: groupId, pageId: _pageId }),
        _payload,
      )
      .then((response) => {
        getCurrentGroupPageComponents(groupId, _pageId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Page Component');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGroupPageComponent = (
  groupId: string,
  _pageId: string,
  _componentId: string,
  _payload: any,
  _callback?: { (): void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(
        swapRouteParams(routes.UPDATE_GROUP_PAGE_COMPONENT, {
          groupId: groupId,
          pageId: _pageId,
          componentId: _componentId,
        }),
        _payload,
      )
      .then((response) => {
        getCurrentGroupPageComponents(groupId, _pageId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Page Component');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        if (_callback !== undefined) {
          _callback();
        }
      });
  };
};

export const deleteGroupPageComponent = (
  groupId: string,
  _pageId: string,
  _componentId: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(
        swapRouteParams(routes.DELETE_GROUP_PAGE_COMPONENT, {
          groupId: groupId,
          pageId: _pageId,
          componentId: _componentId,
        }),
      )
      .then((response) => {
        getCurrentGroupPageComponents(groupId, _pageId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Cause Page Component');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getCurrentGroupPageComponents = (groupId: string, _pageId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isComponentsLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    axios
      .get(swapRouteParams(routes.GET_GROUP_PAGE_COMPONENTS, { groupId: groupId, pageId: _pageId }))
      .then((response) => {
        dispatch({
          currentPageComponents: response.data.components,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .finally(() => {
        dispatch({
          isComponentsLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const getGroupGigs = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupGigsLoading: true,
      type: GroupActionTypes.UPDATE_GROUP_GIGS,
    });

    axios
      .get(swapRouteParams(routes.GET_GROUP_GIGS, { groupId: groupId }))
      .then((response) => {
        dispatch({
          groupGigs: response.data,
          type: GroupActionTypes.UPDATE_GROUP_GIGS,
        });
      })
      .finally(() => {
        dispatch({
          isGroupGigsLoading: false,
          type: GroupActionTypes.UPDATE_GROUP_GIGS,
        });
      });
  };
};

export const getGroupEvents = (groupId: string, search?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupEventsLoading: true,
      type: GroupActionTypes.UPDATE_GROUP_EVENTS,
    });

    let url = swapRouteParams(routes.GET_EVENTS_BY_GROUP, { groupId: groupId });

    if (search) {
      url += `?search=${search}`;
    }

    axios
      .get(url)
      .then((response) => {
        dispatch({
          groupEvents: response.data,
          type: GroupActionTypes.UPDATE_GROUP_EVENTS,
        });
      })
      .finally(() => {
        dispatch({
          isGroupEventsLoading: false,
          type: GroupActionTypes.UPDATE_GROUP_EVENTS,
        });
      });
  };
};

export const getGroupPages = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupPagesLoading: true,
      type: GroupActionTypes.UPDATE_GROUP_PAGES,
    });

    axios
      .get(swapRouteParams(routes.GET_GROUP_PAGES, { groupId: groupId }))
      .then((response) => {
        if (response.data.length > 0) {
          getCurrentGroupPageComponents(groupId, response.data[0].id)(dispatch, getState);
        }

        dispatch({
          groupPages: response.data,
          type: GroupActionTypes.UPDATE_GROUP_PAGES,
        });
      })
      .finally(() => {
        dispatch({
          isGroupPagesLoading: false,
          type: GroupActionTypes.UPDATE_GROUP_PAGES,
        });
      });
  };
};

export const getGroupRoles = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupRolesLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    axios
      .get(swapRouteParams(routes.GET_GROUP_ROLES, { groupId: groupId }))
      .then((response) => {
        dispatch({
          groupRoles: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          error: errorObj.translatedMessage,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .finally(() => {
        dispatch({
          isGroupRolesLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const createGroupRole = (groupId: string, _role: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_GROUP_ROLE, { groupId: groupId }), _role)
      .then((response) => {
        getGroupRoles(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Cause Role');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGroupRole = (groupId: string, _role: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.UPDATE_GROUP_ROLE, { groupId: groupId, id: _role.id }), _role)
      .then((response) => {
        getGroupRoles(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Cause Role');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const deleteGroupRole = (groupId: string, _id: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(swapRouteParams(routes.DELETE_GROUP_ROLE, { groupId: groupId, id: _id }))
      .then((response) => {
        getGroupRoles(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Cause Role');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const clearGroup = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      group: initialGroupState.group,
      type: GroupActionTypes.UPDATE_GROUP,
    });
  };
};

// TODO: Change order of this action params to be consistent
export const updateGroup = (_payload: any, groupId: string, _pages?: any, isFlow?: boolean) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.UPDATE_GROUP, { groupId: groupId }), _payload)
      .then((response) => {
        dispatch({
          group: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });

        getUserGroups()(dispatch, getState);

        if (response.data.campaign_id) {
          getGroupCampaign(response.data.id, response.data.campaign_id)(dispatch, getState);
        } else {
          dispatch({
            currentCampaign: null,
            type: GroupActionTypes.UPDATE_GROUP,
          });
        }

        if (_pages !== undefined) {
          for (let _p in _pages) {
            if (_pages[_p].id.length > 0) {
              updateGroupPage(groupId, _pages[_p].id, _pages[_p])(dispatch, getState);
            } else {
              createGroupPage(groupId, _pages[_p])(dispatch, getState);
            }
          }
        }

        if (isFlow) {
          if (response.data?.campaign_id) {
            getGroupCampaign(response.data.id, response.data.campaign_id)(dispatch, getState);
          }
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Cause');
        createToast(toast)(dispatch, getState);
      });
  };
};

/** Changes the handle of a group, which changes the URL of the group.
 * @param onChanged Callback that is invoked once the handle is changed.
 */
export const changeGroupHandle = (groupId: string, newHandle: string, onChanged: () => void) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.CHANGE_GROUP_HANDLE, { groupId: groupId }), { value: newHandle })
      .then((response) => {
        onChanged();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Cause');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createGroup = (_payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    axios
      .post(routes.CREATE_GROUP_REQUEST, _payload.group)
      .then((response) => {
        dispatch({
          group: response.data,
          error: '',
          type: GroupActionTypes.UPDATE_GROUP,
        });

        getUserGroups()(dispatch, getState);
        getCurrentUserGroupRole(response.data.id)(dispatch, getState);

        if (_payload.groupPages !== undefined) {
          for (let _p in _payload.groupPages) {
            createGroupPage(response.data.id, _payload.groupPages[_p])(dispatch, getState);
          }
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          group: initialGroupState.group,
          error: errorObj.translatedMessage,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .finally(() => {
        dispatch({
          isGroupLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const createGroupFlow = (
  _payload: any,
  event?: any,
  gig?: any,
  shouldGetGroup?: boolean,
) => {
  let res: any;
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    axios
      .post(routes.CREATE_GROUP_REQUEST, _payload.group)
      .then((response) => {
        dispatch({
          group: response.data,
          error: '',
          type: GroupActionTypes.UPDATE_GROUP,
        });

        getUserGroups()(dispatch, getState);
        getCurrentUserGroupRole(response.data.id)(dispatch, getState);

        if (_payload.groupPages !== undefined) {
          for (let _p in _payload.groupPages) {
            createGroupPage(response.data.id, _payload.groupPages[_p])(dispatch, getState);
          }
        }
        res = response;
      })
      .then(() => {
        // check data for what else may need created in a flow state, signature, locations, etc
        // extract handling to function
        if (res.data) {
          if (event) {
            if (_payload.campaign_id) {
              event.event.campaign_id = _payload.campaign_id;
            }
            let updateComp = undefined;
            createEvent(res.data.id, event.event, {
              _pages: event.pages,
              _updateComponent: updateComp,
              isFlow: true,
            })(dispatch, getState);
          }

          if (gig) {
            createGroupGig(res.data.id, gig, { shouldGetGig: true })(dispatch, getState);
          }

          if (shouldGetGroup) {
            getGroup(res.data.id)(dispatch, getState);
          }
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          group: initialGroupState.group,
          error: errorObj.translatedMessage,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .finally(() => {
        dispatch({
          isGroupLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const createGroupPage = (groupId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupPagesLoading: true,
      type: GroupActionTypes.UPDATE_GROUP_PAGES,
    });

    axios
      .post(swapRouteParams(routes.CREATE_GROUP_PAGE, { groupId: groupId }), _payload)
      .then((response) => {
        let _pages = getState().groupState.groupPages;

        dispatch({
          groupPages: [..._pages, ...[response.data]],
          type: GroupActionTypes.UPDATE_GROUP_PAGES,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Cause Page');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isGroupPagesLoading: false,
          type: GroupActionTypes.UPDATE_GROUP_PAGES,
        });
      });
  };
};

export const updateGroupPage = (groupId: string, _id: string, _payload: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupPagesLoading: true,
      type: GroupActionTypes.UPDATE_GROUP_PAGES,
    });

    axios
      .put(swapRouteParams(routes.UPDATE_GROUP_PAGE, { groupId: groupId, id: _id }), _payload)
      .then((response) => {
        let _pages = [...getState().groupState.groupPages];

        for (let p in _pages) {
          if (_pages[p].id === response.data.id) {
            _pages[p] = response.data;
          }
        }

        dispatch({
          groupPages: [..._pages],
          type: GroupActionTypes.UPDATE_GROUP_PAGES,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Cause Page');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isGroupPagesLoading: false,
          type: GroupActionTypes.UPDATE_GROUP_PAGES,
        });
      });
  };
};

export const deleteGroupPage = (groupId: string, _id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupPagesLoading: true,
      type: GroupActionTypes.UPDATE_GROUP_PAGES,
    });

    axios
      .delete(swapRouteParams(routes.DELETE_GROUP_PAGE, { groupId: groupId, id: _id }))
      .then((response) => {
        let _pages = [...getState().groupState.groupPages];

        for (let p in _pages) {
          if (_pages[parseInt(p)].id === _id) {
            _pages.splice(parseInt(p), 1);
            break;
          }
        }

        dispatch({
          groupPages: _pages,
          type: GroupActionTypes.UPDATE_GROUP_PAGES,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Cause Page');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isGroupPagesLoading: false,
          type: GroupActionTypes.UPDATE_GROUP_PAGES,
        });
      });
  };
};

export const updateHandleLoading = (isLoading: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      isHandleLoading: isLoading,
      type: GroupActionTypes.UPDATE_GROUP,
    });
  };
};

export const updateGroupError = (_error: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      success: false,
      error: _error,
      type: GroupActionTypes.UPDATE_GROUP,
    });
  };
};

export const updateGroupReceiptImage = (groupId: string, img: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const blob = dataURItoBlob(img);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let updatedGroup: Partial<IGroup> = { receipt_logo_image_url: response.data.value };
        updateGroup(updatedGroup, groupId)(dispatch, getState);
        let toast = toastSuccess(
          localizeHelpers.translate('Successfully updated Cause Receipt Image'),
          'Cause Receipt Image',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Receipt Image');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGroupCoverImage = (group: IGroup, img: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const blob = dataURItoBlob(img);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let updatedGroup: Partial<IGroup> = { cover_image_url: response.data.value };

        updateGroup(updatedGroup, group.id)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Cover Image');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGroupProfileImage = (group: IGroup, img: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const blob = dataURItoBlob(img);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let updatedGroup: Partial<IGroup> = { profile_image_url: response.data.value };

        updateGroup(updatedGroup, group.id)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Profile Image');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createGroupLocation = (
  groupId: string,
  _payload: any,
  _locationName: string,
  isPageComponent?: boolean,
  _pageId?: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    if (_payload.location?.type) {
      delete _payload.location.type;
    }

    _payload.title = _locationName;

    axios
      .post(swapRouteParams(routes.CREATE_GROUP_LOCATION, { groupId: groupId }), _payload)
      .then((response) => {
        if (!isPageComponent) {
          getGroup(groupId)(dispatch, getState);
        } else {
          if (_pageId) {
            getCurrentGroupPageComponents(groupId, _pageId)(dispatch, getState);
          }
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Location');
        createToast(toast)(dispatch, getState);
      });
  };
};

//interfaces
export const createGroupLocationFlow = (
  groupId: string,
  _payload: any,
  _locationName: string,
  FlowCb?: any,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    if (_payload.location?.type) {
      delete _payload.location.type;
    }

    _payload.title = _locationName;

    axios
      .post(swapRouteParams(routes.CREATE_GROUP_LOCATION, { groupId: groupId }), _payload)
      .then((response) => {
        if (FlowCb && response && response.data) {
          FlowCb.cb(response.data.id);
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Location');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const makeEventVisible = (groupId: string, eventId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(swapRouteParams(routes.GET_GROUP_PAGES, { groupId: groupId })).then((response) => {
      if (response.data.length > 0) {
        let eventsPage: IPage = response.data.find((page: IPage) => page.label === 'Events');
        let eventsComponent = eventsPage?.components?.find(
          (page: IPageComponent) => page.component_type === 'event',
        );

        if (eventsComponent) {
          eventsComponent.content_references = { object_ids: [], object_type: 'event' };
        }

        if (eventsComponent && eventsComponent.content_references) {
          eventsComponent.content_references.object_ids.push(eventId);

          updateGroupPageComponent(
            groupId,
            eventsPage.id,
            eventsComponent.id,
            eventsComponent,
          )(dispatch, getState);
        }
      }
    });
  };
};

export const deleteGroupLocation = (groupId: string, _locationId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(
        swapRouteParams(routes.DELETE_GROUP_LOCATION, {
          groupId: groupId,
          locationId: _locationId,
        }),
      )
      .then((response) => {
        getGroup(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Cause Location');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createGroupMediaAlbum = (groupId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_GROUP_MEDIA_ALBUM, { groupId: groupId }), _payload)
      .then((response) => {
        getGroupMediaAlbums(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Media Album.');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const deleteGroupMediaAlbum = (groupId: string, _albumId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .delete(
        swapRouteParams(routes.DELETE_GROUP_MEDIA_ALBUM, { groupId: groupId, albumId: _albumId }),
      )
      .then((response) => {
        getGroupMediaAlbums(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Media Album');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGroupMediaAlbum = (groupId: string, _albumId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(
        swapRouteParams(routes.UPDATE_GROUP_MEDIA_ALBUM, { groupId: groupId, albumId: _albumId }),
        _payload,
      )
      .then((response) => {
        getGroupMediaAlbums(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Cause Media');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getGroupMediaAlbums = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_GROUP_MEDIA_ALBUMS, { groupId: groupId }))
      .then((response) => {
        dispatch({
          groupMediaAlbums: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const volunteerForGroup = (
  groupId: string,
  _payload: any,
  _gig_payload?: any,
  callback?: () => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      volunteerError: '',
      volunteerSuccess: false,
      type: GroupActionTypes.UPDATE_GROUP,
    });
    axios
      .post(swapRouteParams(routes.VOLUNTEER_FOR_GROUP, { groupId: groupId }), _payload)
      .then((response) => {
        if (_gig_payload?.gig?.id) {
          volunteerForGig(_gig_payload.gig.id, _gig_payload, callback)(dispatch, getState);
        } else {
          callback?.();
          dispatch({
            volunteerError: '',
            volunteerSuccess: true,
            type: GroupActionTypes.UPDATE_GROUP,
          });

          getUserGroupApplications(groupId)(dispatch, getState);
          const toast = toastSuccess(
            localizeHelpers.translate(
              'Application submitted successfully. Someone will contact you shortly. Keep an eye on your email for shift assignments and notifications.',
            ),
            'Application Form',
          );
          createToast(toast)(dispatch, getState);
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Volunteer for group');
        createToast(toast)(dispatch, getState);
        dispatch({
          volunteerError: errorObj.translatedMessage,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const resetVolunteerForGroup = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      volunteerError: '',
      volunteerSuccess: false,
      type: GroupActionTypes.UPDATE_GROUP,
    });
  };
};

export const createGroupDonationIntent = (
  groupId: string,
  _payload: IDonationRequest,
  callback?: () => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_GROUP_DONATION_INTENT, { groupId: groupId }), _payload)
      .then((response) => {
        if (response.data.is_virtual) {
          if (response.data.client_secret) {
            registerItem(groupId, response.data.client_secret, '', callback)(dispatch, getState);
            if (callback) {
              getGroupDonors(groupId)(dispatch, getState);
              const toast = toastSuccess(
                localizeHelpers.translate('Donation record added!'),
                'Donation Record',
              );
              createToast(toast)(dispatch, getState);
            }
          }
        }
        dispatch({
          donationIntent: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Donation Intent');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createGroupDonationSubscriptionIntent = (groupId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.CREATE_GROUP_DONATION_SUBSCRIPTION_INTENT, { groupId: groupId }),
        _payload,
      )
      .then((response) => {
        dispatch({
          donationIntent: { amounts: { ...response.data } },
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Donation Subscription Intent');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const submitGroupSubscription = (groupId: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.SUBMIT_GROUP_SUBSCRIPTION, { groupId: groupId }), _payload)
      .then((response) => {
        dispatch({
          subscriptionSuccess: true,
          subscriptionError: '',
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            subscriptionSuccess: false,
            subscriptionError:
              error.response.data.gigitErrorCode || error.response.data.message || '',
            type: GroupActionTypes.UPDATE_GROUP,
          });
        }
      });
  };
};

export const resetGroupSubscriptionError = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      subscriptionSuccess: false,
      subscriptionError: '',
      type: GroupActionTypes.UPDATE_GROUP,
    });
  };
};

export const getGroupDonors = (groupId: string, _query?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      donationsLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    let _route = swapRouteParams(routes.GET_GROUP_DONORS, { groupId: groupId });

    if (_query !== undefined) {
      _route = _route + '?search=' + _query;
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          groupDonors: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .finally(() => {
        dispatch({
          donationsLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const getGroupDonorsExport = (groupId: string, groupHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_GROUP_DONORS_EXPORT, { groupId: groupId }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'Donors_for_' + groupHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Members Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getGroupDonorClassificationExport = (
  groupId: string,
  groupHandle: string,
  options: IDonorClassificationReportOptions,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_DONOR_CLASSIFICATION_REPORT_EXPORT, { groupId: groupId }), {
        params: options,
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'Donor_Classifications_for_' + groupHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Donor Classification');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getGroupDonations = (groupId: string, _query?: URLSearchParams) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      donationsLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    let _route = swapRouteParams(routes.GET_GROUP_DONATIONS, { groupId: groupId });

    const queryString = _query?.toString();
    if (queryString) {
      _route = _route + `?${queryString}`;
    }

    axios
      .get<ITransactionSummary[]>(_route)
      .then((response) => {
        dispatch({
          groupDonations: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .finally(() => {
        dispatch({
          donationsLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const getGroupDonationsPublic = (
  groupId: string,
  _paginate: boolean,
  _limit: number,
  _skip: number,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      donationsLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    let _route = swapRouteParams(routes.GET_PUBLIC_DONATIONS_GROUP, {
      groupId: groupId,
      limit: _limit,
      skip: _skip,
    });

    axios
      .get(_route)
      .then((response) => {
        if (_skip === 0) {
          dispatch({
            groupDonationsPublic: response.data,
            type: GroupActionTypes.UPDATE_GROUP,
          });
        } else {
          dispatch({
            groupDonationsPublic: [...getState().groupState.groupDonationsPublic, ...response.data],
            type: GroupActionTypes.UPDATE_GROUP,
          });
        }
      })
      .finally(() => {
        dispatch({
          donationsLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const sendTaxReceipt = (groupId: string, _donationId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.SEND_TAX_RECEIPT, { groupId: groupId, donationId: _donationId }))
      .then((response) => {
        getGroupDonations(groupId)(dispatch, getState);
        const toast = toastSuccess(
          localizeHelpers.translate('Donation receipt successfully sent.'),
          'Transaction Record',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error: any) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Transaction Record');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const regenerateTaxReceipt = (groupId: string, _donationId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.REGENERATE_TAX_RECEIPT, {
          groupId: groupId,
          donationId: _donationId,
        }),
      )
      .then((response) => {
        getGroupDonations(groupId)(dispatch, getState);
        const toast = toastSuccess(
          localizeHelpers.translate('Donation receipt successfully Re-generated.'),
          'Transaction Record',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error: any) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Transaction Record');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getGroupGigPayments = (groupId: string, _query?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      gigPaymentsLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    let _route = swapRouteParams(routes.GET_GROUP_GIG_PAYMENTS, { groupId: groupId });

    if (_query !== undefined) {
      _route = _route + '?search=' + _query;
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          groupGigPayments: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .finally(() => {
        dispatch({
          gigPaymentsLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const approveGroupApplication = (groupId: string, _id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.APPROVE_GROUP_APPLICANT, { groupId: groupId, id: _id }))
      .then((response) => {
        getGroupApplications(groupId)(dispatch, getState);
      });
  };
};

export const approveGroupApplicationManagement = (
  groupId: string,
  _id: string,
  userId: string,
  callback?: () => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.APPROVE_GROUP_APPLICANT, { groupId: groupId, id: _id }))
      .then((response) => {
        groupApplicationsForUser(groupId, userId)(dispatch, getState);
      })
      .finally(() => {
        if (callback) {
          callback?.();
        }
      });
  };
};

export const rejectGroupApplicationManagement = (groupId: string, _id: string, userId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.REJECT_GROUP_APPLICANT, { groupId: groupId, id: _id }))
      .then((response) => {
        groupApplicationsForUser(groupId, userId)(dispatch, getState);
      });
  };
};

export const rejectGroupApplication = (groupId: string, _id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.REJECT_GROUP_APPLICANT, { groupId: groupId, id: _id }))
      .then((response) => {
        getGroupApplications(groupId)(dispatch, getState);
      });
  };
};

export const getGroupMembers = (groupId: string, _search?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _route = swapRouteParams(routes.GET_GROUP_MEMBERS, { groupId: groupId });

    if (_search) {
      _route = _route + '?search=' + _search;
    }

    dispatch({
      isMembersLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          members: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .finally(() => {
        dispatch({
          isMembersLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const getGroupMembersExport = (groupId: string, groupHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_GROUP_MEMBERS_EXPORT, { groupId: groupId }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'Members_for_' + groupHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Members Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const assignRoleToUser = (
  groupId: string,
  _roleId: string,
  userId: string,
  callback?: () => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.ASSIGN_GROUP_ROLE_TO_USER, {
          groupId: groupId,
          roleId: _roleId,
          userId: userId,
        }),
      )
      .then(() => {
        callback?.();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Role Assignment');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const purchaseGroupItems = (groupId: string, _payload: any, callback?: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.GROUP_PURCHASE, { groupId: groupId }), _payload)
      .then((response) => {
        if (response.data.is_virtual) {
          if (response.data.client_secret) {
            response.data.client_secret.forEach((key: string, i: number) => {
              registerItem(groupId, key, '', callback)(dispatch, getState);
              if (i === response.data.client_secret.length - 1) {
                if (callback) {
                  callback();
                }
              }
            });
          }
        }

        dispatch({
          purchaseIntent: response.data,
          purchaseError: '',
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        let toast = toastError(error.response.data.message, 'Purchase');
        if (callback) {
          callback();
        }
        createToast(toast)(dispatch, getState);
      });
  };
};

export const registerItem = (groupId: string, _id: string, itemId?: string, callback?: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.REGISTER_ITEM_GROUP, { groupId: groupId, id: _id }), { itemId })
      .then((response) => {
        callback?.();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Register item');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getGroupConversationMembers = (groupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    if (getState().userState.isLoggedIn) {
      axios
        .get(swapRouteParams(routes.GET_GROUP_CONVERSATION_MEMBERS, { groupId: groupId }))
        .then((response) => {
          dispatch({
            groupManagers: response.data,
            type: GroupActionTypes.UPDATE_GROUP,
          });
        })
        .catch((error) => {
          if (
            error.response.data.gigitErrorCode !== 'ERROR.AUTH.REFRESH_TOKEN_EXPIRED' &&
            // For some reason unauthorized error on this route returns null
            (error.response.data.gigitErrorCode !== null || error.response.data.message !== '')
          ) {
            const errorObj = errorHelpers.getErrorObject(error);
            let toast = toastError(errorObj.translatedMessage, 'Get Cause Conversation Members');
            createToast(toast)(dispatch, getState);
          }
        });
    }
  };
};

export const downloadGroupDonationReceipt = (groupId: string, donation: ITransaction) => {
  return async (dispatch: Dispatch, getState: any) => {
    const { receipt_number, tax_receipt_prefix, id } = donation;
    axios
      .get(
        swapRouteParams(routes.DOWNLOAD_GROUP_DONATION_RECEIPT, {
          groupId: groupId,
          transaction_id: id,
        }),
        { responseType: 'blob' },
      )
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'application/pdf' });
        const fileName = localizeHelpers.translate('Donation receipt');
        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download =
          (tax_receipt_prefix ? tax_receipt_prefix + ' ' : '') +
          fileName +
          ' ' +
          (receipt_number ? '#' + receipt_number : id);
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Donation Receipt');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const downloadGroupPurchaseReceipt = (groupId: string, _transaction_Id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(
        swapRouteParams(routes.DOWNLOAD_GROUP_PURCHASE_RECEIPT, {
          groupId: groupId,
          transaction_id: _transaction_Id,
        }),
        { responseType: 'blob' },
      )
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'application/pdf' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'purchase_' + _transaction_Id;
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Purchase Receipt');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getPurchaseExport = (groupId: string, groupHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.EXPORT_PURCHASES, { groupId: groupId }), { responseType: 'blob' })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'purchases_for_' + groupHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Purchase Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getPurchaseExportDaily = (groupId: string, groupHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GROUP_EXPORT_STORE_DAILY, { groupId: groupId }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'purchases_for_' + groupHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Purchase Daily Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getDonationsExport = (groupId: string, groupHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_DONATIONS_EXPORT, { groupId: groupId }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'Donations_for_' + groupHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Donations Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getPledgesExport = (groupId: string, groupHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(swapRouteParams(routes.GET_PLEDGES_EXPORT, { groupId: groupId }), {
        responseType: 'blob',
      })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'Pledges_for_' + groupHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Plegdes Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getTransactionItems = (groupId: string, _transaction_Id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(
        swapRouteParams(routes.GET_PURCHASE_ITEMS, {
          groupId: groupId,
          transaction_id: _transaction_Id,
        }),
      )
      .then((response) => {
        dispatch({
          currentTransactionItems: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const getGroupSubscriptions = (groupId: string, query?: URLSearchParams) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      donationsLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    let route = swapRouteParams(routes.GET_GROUP_SUBSCRIPTIONS, { groupId: groupId });

    const queryString = query?.toString();
    if (queryString) route += `?${queryString}`;

    axios
      .get<ITransactionSummary[]>(route)
      .then((response) => {
        dispatch({
          groupDonationSubscriptions: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Subscriptions');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          donationsLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const addDonor = (groupId: string, payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.ADD_DONOR, { groupId: groupId }), payload)
      .then((response) => {
        getGroupDonors(groupId)(dispatch, getState);
        const toast = toastSuccess(
          localizeHelpers.translate('Your newly created donor has been added to your records'),
          'Donor Created.',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Donor Add');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGroupReceiptPrefix = (groupId: string, payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.UPDATE_GROUP, { groupId: groupId }), payload)
      .then((response) => {
        dispatch({
          receipt_number_prefix: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
        const toast = toastSuccess(
          localizeHelpers.translate('Your cause receipt prefix has been updated.'),
          'Cause Receipt Prefix',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Cause Receipt Prefix');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGroupReceiptNumberLength = (groupId: string, payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(swapRouteParams(routes.UPDATE_GROUP, { groupId: groupId }), payload)
      .then((response) => {
        dispatch({
          receipt_number_length: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
        const toast = toastSuccess(
          localizeHelpers.translate('Your cause receipt prefix has been updated.'),
          'Cause Receipt Prefix',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Cause Receipt Prefix');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getGroupReceiptNumber = (groupId: string, _campaign_id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(
        swapRouteParams(routes.GET_GROUP_RECEIPT_NUMBER, {
          groupId: groupId,
          campaign_id: _campaign_id,
        }),
      )
      .then((response) => {
        dispatch({
          receiptNumber: response.data.value,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const getGroupReceiptNumberFormatted = (groupId: string, _campaign_id: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(
        swapRouteParams(routes.GET_GROUP_RECEIPT_NUMBER_FORMATTED, {
          groupId: groupId,
          campaign_id: _campaign_id,
        }),
      )
      .then((response) => {
        dispatch({
          receiptNumberFormatted: response.data.value,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const getGroupReceiptNumberPreview = (
  groupId: string,
  prefix: string | undefined,
  padding: number | undefined,
  value: number,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      receiptNumberPreviewIsLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    axios
      .get(swapRouteParams(routes.GET_GROUP_RECEIPT_NUMBER_PREVIEW, { groupId: groupId }), {
        params: { prefix, padding, value },
      })
      .then((response) => {
        dispatch({
          receiptNumberPreview: response.data.value,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Get Cause Receipt Number Preview');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          receiptNumberPreviewIsLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const archiveGroup = (groupId: string, groupHandle: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.ARCHIVE_GROUP, { groupId: groupId }))
      .then((response) => {
        getGroup(groupId)(dispatch, getState);
        getUserGroups()(dispatch, getState);
        const toast = toastSuccess(
          localizeHelpers.translate('{{groupHandle}} cause has been archived.', {
            groupHandle: groupHandle,
          }),
          'Group Deleted',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Delete');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const changeGroupStatus = (
  groupId: string,
  status: string,
  title: string,
  handle: string,
  callback?: (updatedGroup: IGroup) => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isGroupLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });
    axios
      .post(swapRouteParams(routes.CHANGE_GROUP_STATUS, { groupId }), { status })
      .then((response) => {
        dispatch({
          group: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
        callback?.(response.data);
        const toast = toastSuccess(
          localizeHelpers.translate('Cause status has been changed to {{status}}', {
            status: status,
          }),
          'Change group status',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let actionList: IToastAction[] | undefined = undefined;
        if (errorObj.errorCode === 'ERROR.ROLES.NO_OBJECT_PERMISSIONS') {
          errorObj.translatedMessage = localizeHelpers.translate(
            `The cause {{title}}  is currently not Published, please contact the group administrator`,
            {
              title: title,
            },
          );
        } else if (errorObj.errorCode === 'ERROR.EXTERNAL.STRIPE_ACCOUNT_CHARGES_DISABLED') {
          errorObj.translatedMessage = localizeHelpers.translate(
            'We require just a few more details before you can start accepting payments. Click here to finish your setup.',
          );
          actionList = [
            {
              link: {
                link: `/group/${handle}/admin?t=balance_and_payouts`,
                text: localizeHelpers.translate('Finish Setup'),
              },
            },
          ];
        }
        let toast = toastError(errorObj.translatedMessage, 'Change cause status', actionList);
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isGroupLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const groupPledge = (
  groupId: string,
  groupHandle: string,
  _payload: any,
  redirect?: any,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.GROUP_PLEDGE, { groupId: groupId }), _payload)
      .then((response) => {
        const toast = toastSuccess(
          localizeHelpers.translate('You have made a pledge to {{groupHandle}}', {
            groupHandle: groupHandle,
          }),
          'Pledge',
        );
        createToast(toast)(dispatch, getState);

        if (redirect) {
          redirect.push(`/group/${groupHandle}/`);
        }
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            error: error.response.data.gigitErrorCode || '',
            type: GroupActionTypes.UPDATE_GROUP,
          });
        }
      });
  };
};

export const getGroupSponsors = (groupId: string, query?: URLSearchParams) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _route: string = swapRouteParams(routes.GET_SPONSORS, { groupId: groupId });

    const queryString = query?.toString();
    if (queryString) {
      _route += `?${queryString}`;
    }

    dispatch({
      sponsorsLoading: true,
      type: GroupActionTypes.GET_GROUP_SPONSORS,
    });

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          groupSponsors: response.data,
          type: GroupActionTypes.GET_GROUP_SPONSORS,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cause Delete');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          sponsorsLoading: false,
          type: GroupActionTypes.GET_GROUP_SPONSORS,
        });
      });
  };
};

export const getGroupSponsorshipSettings = (groupId: string, query?: URLSearchParams) => {
  return async (dispatch: Dispatch, getState: any) => {
    let _route: string = swapRouteParams(routes.GET_GROUP_SPONSORSSHIP_SETTINGS, {
      groupId: groupId,
    });

    const queryString = query?.toString();
    if (query) {
      _route += '?' + queryString;
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          groupSponsorshipSettings: response.data,
          type: GroupActionTypes.GET_GROUP_SPONSORS,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Sponsorship Settings');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateGroupSponsorshipSettings = (
  groupId: string,
  payload: Partial<ISponsorshipSettings>,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .put(
        swapRouteParams(routes.UPDATE_GROUP_SPONSORSSHIP_SETTINGS, { groupId: groupId }),
        payload,
      )
      .then((response) => {
        getGroupSponsorshipSettings(groupId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Sponsorship Settings');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getGroupStats = (groupId: string, statisticType: StatisticType) => {
  let route = '';
  switch (statisticType) {
    case StatisticType.DONATIONS:
      route = routes.GET_GROUP_DONATIONS_STATS;
      break;
    case StatisticType.STORE_PUSRCHASES:
      route = routes.GET_GROUP_STORE_PURCHASES_STATS;
      break;
    case StatisticType.STORE:
      route = routes.GET_GROUP_STORE_STATS;
      break;
    case StatisticType.AUCTION:
      route = routes.GET_GROUP_AUCTION_STATS;
      break;
    case StatisticType.AUCTION_PURCHASES:
      route = routes.GET_GROUP_AUCTION_PURCHASES_STATS;
  }

  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isStatsLoading: true,
      type: GroupActionTypes.UPDATE_GROUP,
    });

    axios
      .get(swapRouteParams(route, { group_id: groupId }))
      .then((response) => {
        dispatch({
          [statisticType]: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Get Statistic');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isStatsLoading: false,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

/** Returns the hub member groups of a group (group must be the main hub group). */
export const getGroupHubMemberGroups = (groupId: string, query?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GroupActionTypes.UPDATE_GROUP,
    });

    let _route = swapRouteParams(routes.GET_GROUP_HUB_MEMBER_GROUPS, { groupId: groupId });

    if (query) {
      _route = _route + '?search=' + query;
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          hubMemberGroups: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        let toast = toastError(error.response.data.gigitErrorCode, 'Get Cause Hub Member Causes');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const requestHubAssociation = (
  groupId: string,
  hubId: string,
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.REQUEST_HUB_ASSOCIATION, { groupId: groupId, hubId: hubId }))
      .then((response) => {
        options?.callback?.();
      })
      .catch((error) => {
        let toast = toastError(
          error.response.data.message || error.response.data.gigitErrorCode,
          'Request Hub Association',
        );
        createToast(toast)(dispatch, getState);
      });
  };
};

/** Approves a hub assocation (groupId must be the main hub group). */
export const approveHubAssociation = (
  groupId: string,
  candidateGroupId: string,
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.APPROVE_HUB_ASSOCIATION, {
          groupId: groupId,
          candidateGroupId: candidateGroupId,
        }),
      )
      .then((response) => {
        options?.callback?.();
      })
      .catch((error) => {
        let toast = toastError(
          error.response.data.message || error.response.data.gigitErrorCode,
          'Approve Hub Association',
        );
        createToast(toast)(dispatch, getState);
      });
  };
};

/** Rejects a hub assocation (groupId must be the main hub group). */
export const rejectHubAssociation = (groupId: string, candidateGroupId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.REJECT_HUB_ASSOCIATION, {
          groupId: groupId,
          candidateGroupId: candidateGroupId,
        }),
      )
      .then((response) => {})
      .catch((error) => {
        let toast = toastError(
          error.response.data.message || error.response.data.gigitErrorCode,
          'Reject Hub Association',
        );
        createToast(toast)(dispatch, getState);
      });
  };
};

/** Updates the postal code patterns of one or more hub groups (groupId must be the main hub group). */
export const updateHubGroupPostalCodePatterns = (
  groupId: string,
  postalCodeUpdates: { candidateGroupId: string; postalCodePatterns: string[] }[],
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      // Need to loop because postal code route only does 1 at a time.
      await Promise.all(
        postalCodeUpdates.map(({ candidateGroupId, postalCodePatterns }) =>
          axios.post(
            swapRouteParams(routes.UPDATE_HUB_GROUP_POSTAL_CODE_PATTERNS, {
              groupId: groupId,
              candidateGroupId: candidateGroupId,
            }),
            { value: postalCodePatterns },
          ),
        ),
      );

      options?.callback?.();
    } catch (error: any) {
      let toast = toastError(
        error.response.data.message || error.response.data.gigitErrorCode,
        'Update Hub Cause Postal Code Patterns',
      );
      createToast(toast)(dispatch, getState);
    }
  };
};

/** Fetches groups that can be delegated from a hub group. */
export const getGroupDelegations = (
  ownerType: string,
  ownerId: string,
  query?: string,
  postalCodeSearch?: string,
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GroupActionTypes.UPDATE_GROUP,
    });

    const baseRoute =
      ownerType === 'group' ? routes.GET_GROUP_DELEGATIONS : routes.GET_EVENT_GROUP_DELEGATIONS;
    const ownerIdField = ownerType === 'group' ? 'groupId' : 'eventId';
    let _route = swapRouteParams(baseRoute, { [ownerIdField]: ownerId });
    let queryString = '';

    if (query !== undefined) {
      queryString = '?search=' + query;
    }

    if (postalCodeSearch) {
      if (queryString) {
        queryString += '&';
      }
      queryString += `postal_code=${postalCodeSearch}`;
    }

    if (queryString) {
      _route += `?${queryString}`;
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          groupDelegations: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
        options?.callback?.();
      })
      .catch((error) => {
        let toast = toastError(error.response.data.gigitErrorCode, 'Get Hub Cause Delegations');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const getGroupHubs = (groupId: string, query?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GroupActionTypes.UPDATE_GROUP,
    });

    let _route = swapRouteParams(routes.GET_GROUP_HUBS, { groupId: groupId });
    let queryString = '';

    if (query !== undefined) {
      queryString = '?search=' + query;
    }

    if (queryString) {
      _route += `?${queryString}`;
    }

    axios
      .get(_route)
      .then((response) => {
        dispatch({
          groupHubs: response.data,
          type: GroupActionTypes.UPDATE_GROUP,
        });
      })
      .catch((error) => {
        let toast = toastError(error.response.data.gigitErrorCode, 'Get Cause Hubs');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          type: GroupActionTypes.UPDATE_GROUP,
        });
      });
  };
};

export const setGroupPrimaryHub = (
  groupId: string,
  hubId: string | null,
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.SET_GROUP_PRIMARY_HUB, { groupId: groupId, hubId: hubId }))
      .then((response) => {
        options?.callback?.();

        getGroup(groupId)(dispatch, getState);
      })
      .catch((error) => {
        let toast = toastError(
          error.response.data.message || error.response.data.gigitErrorCode,
          'Set Cause Primary Hub',
        );
        createToast(toast)(dispatch, getState);
      });
  };
};

export const cancelGroupRecurringDonation = (groupId: string, transactionId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(
        swapRouteParams(routes.CANCEL_RECURRING_PAYMENT, {
          id: groupId,
          transaction_id: transactionId,
        }),
      )
      .then((response) => {
        const toast = toastSuccess(
          localizeHelpers.translate('Recurring Donation Cancelled.'),
          'Recurring Donation',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Recurring Donation');
        createToast(toast)(dispatch, getState);
      });
  };
};
