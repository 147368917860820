import { Dispatch } from 'redux';

export enum FooterActionTypes {
  TOGGLE_FOOTER_VISIBILITY = 'TOGGLE_FOOTER_VISIBILITY',
}

export interface IToggleFooterVisibility {
  isVisible: boolean;
  type: FooterActionTypes.TOGGLE_FOOTER_VISIBILITY;
}

export type FooterActions = IToggleFooterVisibility;

export const toggleFooterVisibility = (isVisible: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      isVisible: isVisible,
      type: FooterActionTypes.TOGGLE_FOOTER_VISIBILITY,
    });
  };
};
