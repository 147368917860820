import React from 'react';
import ListCard from '../../Shared/ListCard/ListCard';
import { IEventFE, IGroup, ICause, IHub } from '@gigit/interfaces';
import Portrait from '../../../Portrait/Portrait';
import { useEffect } from 'react';
import { useState } from 'react';
import useToastDispatcher from '../../../../hooks/useToaster';
import Carousel from '../../../Carousel/Carousel';
import HubEventSingleItem from '../HubEventSingleItem/HubEventSingleItem';
import { hubRequestActions } from '../../../../requestActions';
import { formatQuery } from '../../../../helpers';
import './HubEventsCarousel.scss';
import { StoryCarousel } from '../../../Stories/Components/StoryCarousel/StoryCarousel';
import { IThemeStyleObjectsByType } from '../../Hub';

interface IProps {
  hub?: IHub | null;
  theme?: IThemeStyleObjectsByType;
}

const HubEventsCarousel: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [events, setEvents] = useState<IEventFE[]>([]);
  const [currentGroups, setCurrentGroups] = useState<IGroup[]>([]);
  const [hubCauses, setHubCauses] = useState<string[]>([]);
  const { dispatchToastError } = useToastDispatcher();

  useEffect(() => {
    tryGetHubEvents();
    getHubGroups();
    getHubCauses();
  }, [props.hub]);

  async function tryGetHubEvents() {
    if (props.hub?.id) {
      try {
        const hubEventsResponse = await hubRequestActions.getHubPartnerEvents(
          props.hub.id,
          formatQuery({
            sort: [{ id: 'start_date', order: 'desc' }],
            limit: '5',
          }),
        );

        setEvents(hubEventsResponse);
      } catch (error) {
        dispatchToastError(error, 'Get Hub Events');
      }
    }
  }

  async function getHubGroups() {
    if (props?.hub?.id) {
      try {
        const hubGroupsResponse = await hubRequestActions.getHubPartners(props.hub.id);
        setCurrentGroups(hubGroupsResponse);
      } catch (error) {
        dispatchToastError(error, 'Get Hub Groups');
      }
    }
  }

  // // TODO: uncomment this after we have BE for that
  function getHubCauses() {
    // if (props?.hub?.id) {
    // hubRequestActions.getHubEventCauses(
    //     props.hub.id
    // )
    //     .then(res =>{
    //          setHubCauses(res)
    //     })
    //     .catch(error => dispatchToastError(error, 'Get Hub Causes'))
    // }
  }

  function getEventCarouselSlides() {
    return events.map((item: IEventFE, index: number) => (
      <HubEventSingleItem
        key={index}
        event={item}
        group={currentGroups.filter((group) => group.id === item.group_id?.toString())[0]}
        // // TODO: uncomment this after we have BE for causes
        // causes={hubCauses.filter(cause=> item.causes?.includes(cause))}
      />
    ));
  }

  return (
    <div className="HubEventsCarousel">
      <div className="section-content">
        <StoryCarousel
          ownerType="hub"
          ownerId={props.hub?.id}
        />
        {events.length > 0 && (
          <Carousel
            slidesToScroll={1}
            slidesToShow={1}
            showArrows={true}
            showDots={true}
            customDotColor={props.theme?.secondaryBg.backgroundColor}
            nextArrow={
              <span>
                <i className="fas fa-chevron-right" />
              </span>
            }
            prevArrow={
              <span>
                <i className="fas fa-chevron-left" />
              </span>
            }
            slides={getEventCarouselSlides()}
          />
        )}
      </div>
    </div>
  );
};

export default HubEventsCarousel;
