import React, { FC, useEffect, useState, KeyboardEvent, MouseEvent } from 'react';
import { formatQuery, IQueryParams, routes } from '../../../../helpers';

import InfiniteScroll from 'react-infinite-scroll-component';

import './CharitiesSelection.scss';
import axios from 'axios';
import { IGroup, IGroupSearch } from '@gigit/interfaces';
import TextField from '../../../TextField/TextField';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../PopupMenu/PopupMenu';
import { ChangeEvent } from 'react';
import useToastDispatcher from '../../../../hooks/useToaster';

interface IProps {
  type: 'group' | 'hub';
  onChange: (list: IGroup[]) => void;
  onChangeSingle?: (group: IGroup) => void;
  selected?: IGroup;
  external?: boolean;
}

interface IDropdownOption {
  value: string;
  label: string;
}

interface ITypeDropdownOptions {
  asc: IDropdownOption;
  desc: IDropdownOption;
}

const CharitiesSelection: FC<IProps> = (props: IProps) => {
  const limit = 8;

  const { dispatchToastError } = useToastDispatcher();

  const [charitiesList, setCharitiesList] = useState<IGroup[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [skip, setSkip] = useState(0);
  const [selectedCharitiesIDs, setSelectedCharitiesIDs] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [prevSearch, setPrevSearch] = useState<string>('');
  const [selectedCharitiesList, setSelectedCharitiesList] = useState<IGroup[]>([]);
  const [selectedSort, setSelectedSort] = useState<IDropdownOption>({
    value: 'title|asc',
    label: 'A to Z',
  });
  const [showContextMenuSort, setShowContextMenuSort] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCharities();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const getShowMenuClassType = showContextMenuSort ? 'show' : '';
  const sortOptions: ITypeDropdownOptions = {
    asc: { value: 'title|asc', label: 'A to Z' },
    desc: { value: 'title|desc', label: 'Z to A' },
  };

  const popupMenuConfigSortBy: IShowPopupConfig = {
    showMenu: showContextMenuSort,
    setShowMenu: setShowContextMenuSort,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuSortOptions: IPopupMenuItem[] = [
    {
      id: sortOptions.asc.value,
      label: sortOptions.asc.label,
      isSelected: selectedSort?.value === sortOptions.asc.value,
      onClick: () => {
        setSkip(0);
        setSelectedSort(sortOptions.asc);
        setShowContextMenuSort(false);
      },
    },
    {
      id: sortOptions.desc.value,
      label: sortOptions.desc.label,
      isSelected: selectedSort?.value === sortOptions.desc.value,
      onClick: () => {
        setSkip(0);
        setSelectedSort(sortOptions.desc);
        setShowContextMenuSort(false);
      },
    },
  ];

  // useEffect(() => {
  //     getCharities();
  // }, []);

  useEffect(() => {
    if (props.selected) {
      setSelectedCharitiesList([props.selected]);
      setSelectedCharitiesIDs([props.selected.id]);
    }
  }, [props.selected]);

  // useEffect(() => {
  //     getCharities(searchValue);
  // }, [selectedSort, searchValue]);

  async function getCharities() {
    let sortData = selectedSort.value.split('|');

    let payload: IGroupSearch = {};

    const query = {
      limit: limit.toString(),
      skip: skip.toString(),
      sort: [{ id: sortData[0], order: sortData[1] }],
    } as IQueryParams;

    if (props.external) {
      payload.unclaimed = true;
      payload.include_shadow = true;
    }

    if (searchValue) {
      // Changed from wildcard search because that produces too many irrelevant results
      payload.filter_title = searchValue;
      query.skip = '0';
      setSkip(0);
      setPrevSearch(searchValue);
    } else {
      if (prevSearch !== '') {
        query.skip = '0';
        setSkip(0);
        setPrevSearch('');
      }
    }

    let route = routes.SEARCH_GROUPS + '?' + formatQuery(query);

    try {
      let data = await axios.post(route, payload);

      if (skip && !searchValue) {
        if (prevSearch) {
          setCharitiesList([...data.data]);
        } else {
          setCharitiesList(charitiesList.concat(data.data));
        }
      } else {
        setCharitiesList([...data.data]);
      }

      if (!data.data.length) {
        setHasMore(false);
      }

      setSkip(skip + limit);
    } catch (error) {
      dispatchToastError(error, 'Get Charities');
    }
  }

  function setSelected(e: MouseEvent, value: string) {
    // hacky solution in the name of time
    if (props.type === 'group') {
      const group = charitiesList.find((item) => item.id === value);
      setSelectedCharitiesIDs([value]);
      if (group) setSelectedCharitiesList([group]);
      if (group) props.onChange([group]);
      setCharitiesList([...charitiesList]);

      return;
    }
    e.stopPropagation();
    if (value) {
      let currentlySelectedIDs = selectedCharitiesIDs;

      if (currentlySelectedIDs.indexOf(value) !== -1) {
        let index = currentlySelectedIDs.indexOf(value);
        currentlySelectedIDs.splice(index, 1);
      } else {
        currentlySelectedIDs.push(value);
      }
      setSelectedCharitiesIDs(currentlySelectedIDs);
      const currentlySelectedCharities = [
        ...selectedCharitiesList,
        ...charitiesList.filter((item: IGroup) => item.id === value),
      ].filter((charity) => currentlySelectedIDs.indexOf(charity.id) !== -1);

      setSelectedCharitiesList(currentlySelectedCharities);
      props.onChange(currentlySelectedCharities);
      setCharitiesList([...charitiesList]);
    }
  }

  return (
    <div className={`CharitiesSelection ${props.type}`}>
      {props.type === 'hub' && (
        <div className="charities-tab-wrapper">
          <div
            onClick={() => setActiveTab(0)}
            className={`charities-tab ${activeTab === 0 ? 'active' : ''}`}
          >
            Available Charities
          </div>
          <div
            onClick={() => setActiveTab(1)}
            className={`charities-tab ${activeTab === 1 ? 'active' : ''}`}
          >
            ({selectedCharitiesIDs.length})Selected Charities
          </div>
        </div>
      )}
      {props.type === 'group' && <h2 className="charities-header">Claim your charity on Kambeo</h2>}
      <div
        id="scrollableDiv"
        className="charities-list-wrapper"
      >
        {!activeTab ? (
          <>
            <div className="filter-wrapper">
              <TextField
                placeholder="Search for a Charity or Non-Profit"
                value={searchValue}
                name="name"
                type="text"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <PopupMenu
                menuItems={contextMenuSortOptions}
                showMenuConfig={popupMenuConfigSortBy}
                popupMenuClass="applications-popupMenu-sort"
                className={`Dropdown filter-dropdown sort ${getShowMenuClassType}`}
                onClick={() => setShowContextMenuSort(!showContextMenuSort)}
              >
                <label>
                  <span>Filter</span>
                </label>
                <div className="input-wrap">
                  <span className="label">{selectedSort.label}</span>
                  <i className={`fas fa-caret-down menu-btn ${getShowMenuClassType}`} />
                </div>
              </PopupMenu>
            </div>
            <InfiniteScroll
              dataLength={charitiesList.length}
              next={getCharities}
              loader={
                <p style={{ textAlign: 'center' }}>
                  <b>Loading...</b>
                </p>
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>No more results.</b>
                </p>
              }
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
            >
              <ul>
                {charitiesList.map((item: IGroup, index) => {
                  return (
                    <li
                      key={index}
                      className={`charities-list-item ${
                        selectedCharitiesIDs.indexOf(item.id) !== -1 ? 'selected' : ''
                      }`}
                      onClick={(e: MouseEvent) => setSelected(e, item.id)}
                    >
                      <div className="charities-item-left">
                        <div className="profile-image">
                          <img src={item.profile_image_url} />
                        </div>
                        <div className="group-info">
                          <div
                            notranslate="yes"
                            className="group-title"
                          >
                            {item.title}
                          </div>
                          <div
                            notranslate="yes"
                            className="group-charity-id"
                          >
                            {item.charity_id
                              ? `Charity ID: ${item.charity_id}`
                              : 'No Charity ID Available'}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          selectedCharitiesIDs.indexOf(item.id) !== -1
                            ? 'custom_select selected'
                            : 'custom_select'
                        }
                      >
                        <i
                          className={`fas ${
                            selectedCharitiesIDs.indexOf(item.id) !== -1
                              ? 'fa-check-square'
                              : 'fa-square'
                          }`}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </InfiniteScroll>
          </>
        ) : (
          <ul>
            {selectedCharitiesList.map((item: IGroup, index) => {
              return (
                <li
                  key={index}
                  className={`charities-list-item ${
                    selectedCharitiesIDs.indexOf(item.id) !== -1 ? 'selected' : ''
                  }`}
                  onClick={(e: any) => setSelected(e, item.id)}
                >
                  <div className="charities-item-left">
                    <div className="profile-image">
                      <img src={item.profile_image_url} />
                    </div>
                    <div className="group-info">
                      <div
                        notranslate="yes"
                        className="group-title"
                      >
                        {item.title}
                      </div>
                      <div
                        notranslate="yes"
                        className="group-charity-id"
                      >
                        {item.charity_id
                          ? `Charity ID: ${item.charity_id}`
                          : 'No Charity ID Available'}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      selectedCharitiesIDs.indexOf(item.id) !== -1
                        ? 'custom_select selected'
                        : 'custom_select'
                    }
                  >
                    <i
                      className={`fas ${
                        selectedCharitiesIDs.indexOf(item.id) !== -1
                          ? 'fa-check-square'
                          : 'fa-square'
                      }`}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CharitiesSelection;
