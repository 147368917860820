import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Config } from '@gigit/config';
import { Redirect } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { IAppState } from '../../store';

import Button from '../../components/Button/Button';

import './Embed.scss';

interface IProps extends WithTranslation, RouteComponentProps<any> {}

interface IState {
  type: string;
  default: string[];
  hover: string[];
  text: string;
  group: string;
  isHovering: boolean;
}

class Embed extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let params = queryString.parse(window.location.search);

    this.state = {
      type: this.props.match.params.type || 'none',
      default: typeof params.default === 'string' ? params.default.split(',') : [],
      hover: typeof params.hover === 'string' ? params.hover.split(',') : [],
      text: typeof params.text === 'string' ? params.text : '',
      group: typeof params.group === 'string' ? params.group : '',
      isHovering: false,
    };

    this.getButtonStyle = this.getButtonStyle.bind(this);
    this.getButtonLink = this.getButtonLink.bind(this);
  }

  getButtonStyle() {
    if (!this.state.isHovering) {
      return {
        border: '2px solid #' + this.state.default[0],
        color: '#' + this.state.default[1],
        backgroundColor: '#' + this.state.default[2],
      };
    }

    return {
      border: '2px solid #' + this.state.hover[0],
      color: '#' + this.state.hover[1],
      backgroundColor: '#' + this.state.hover[2],
    };
  }

  getButtonLink() {
    let url: string = '/not-found';

    if (this.state.type === 'event') {
      switch (this.state.text) {
        case 'Volunteer':
          url = Config.web.REACT_APP_BASE_URL + '/group/' + this.state.group + '/volunteer';
          break;
        case 'Register':
          url =
            Config.web.REACT_APP_BASE_URL +
            '/event/' +
            this.props.match.params.handle +
            '?tickets=1';
          break;
        case 'Fundraise':
          url =
            Config.web.REACT_APP_BASE_URL +
            '/setup/fundraise?event=' +
            this.props.match.params.handle;
          break;
        case 'Find us on Kambeo':
          url = Config.web.REACT_APP_BASE_URL + '/event/' + this.props.match.params.handle;
          break;
        default:
          url =
            Config.web.REACT_APP_BASE_URL + '/event/' + this.props.match.params.handle + '/donate';
          break;
      }
    } else if (this.state.type === 'group') {
      switch (this.state.text) {
        case 'Volunteer':
          url =
            Config.web.REACT_APP_BASE_URL +
            '/group/' +
            this.props.match.params.handle +
            '/volunteer';
          break;
        case 'Register':
          url =
            Config.web.REACT_APP_BASE_URL +
            '/group/' +
            this.props.match.params.handle +
            '?tickets=1';
          break;
        case 'Fundraise':
          url =
            Config.web.REACT_APP_BASE_URL +
            '/setup/fundraise?group=' +
            this.props.match.params.handle;
          break;
        case 'Find us on Kambeo':
          url = Config.web.REACT_APP_BASE_URL + '/group/' + this.props.match.params.handle;
          break;
        default:
          url =
            Config.web.REACT_APP_BASE_URL + '/group/' + this.props.match.params.handle + '/donate';
          break;
      }
    }

    return url;
  }

  render() {
    return (
      <div className="Embed">
        {this.state.type !== 'form' &&
          this.state.type !== 'none' &&
          this.state.default.length === 3 &&
          this.state.hover.length === 3 && (
            <a
              href={this.getButtonLink()}
              style={this.getButtonStyle()}
              target="_top"
              onMouseLeave={() => {
                this.setState({ isHovering: false });
              }}
              onMouseEnter={() => {
                this.setState({ isHovering: true });
              }}
            >
              {this.state.text}
            </a>
          )}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Embed));
