import * as Tabs from '@radix-ui/react-tabs';
import React from 'react';
import './Tabs.scss';
export interface IKambeoTab {
  id: string;
  label: string;
  content: React.ReactNode;
}
export interface IKambeoTabs {
  tabs: IKambeoTab[];
}
/** TODO: CORE COMPONENT, needs expanded/groomed to include variants/styles from UI-KIT */
export default function KambeoTabs(props: IKambeoTabs) {
  return (
    <div className="Tabs">
      <Tabs.Root
        className="TabsRoot"
        defaultValue={props?.tabs[0]?.id}
      >
        {/** TODO: Fix ARIA Label, make dynamic */}
        <Tabs.List
          className="TabsList"
          aria-label={'Tab List'}
        >
          {/* Loops through the tabs and outputs it's trigger */}
          {props.tabs.map((tab) => {
            return (
              <Tabs.Trigger
                className="TabsTrigger"
                key={tab.id}
                value={tab.id}
              >
                {tab.label}
              </Tabs.Trigger>
            );
          })}
        </Tabs.List>
        {/* Loops through the tabs and outputs it's content */}
        {props.tabs.map((tab) => {
          return (
            <Tabs.Content
              className="TabsContent"
              key={tab.id}
              value={tab.id}
            >
              {tab.content}
            </Tabs.Content>
          );
        })}
      </Tabs.Root>
    </div>
  );
}
