import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { IStringMap, handleInputChange } from '../../../helpers';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  DragDropContext,
  Droppable,
  Draggable,
  ResponderProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { IEventSummaryFE, IPage } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { getGroupEvents, updateGroupPageComponent } from '../../../actions/group';
import { getChildEvents, updateEventPageComponent } from '../../../actions/event';
import './ChildEventList.scss';
import TextField from '../../TextField/TextField';
import ComponentPlaceholder from '../ComponentPlaceholder/ComponentPlaceholder';
import { IEventState } from '../../../reducers/event';
import ChildEventListItem from './ChildEventListItem/ChildEventListItem';
import Pagination from '../shared/PageComponentPaginator/PageComponentPaginator';
import { IOwnerObject } from '../../../interfaces';
import { IEventListComponent, ReduxActionType } from '../../../interfaces';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  eventState: IEventState;
  permissions?: IStringMap;
  component: IEventListComponent;
  page: IPage;
  edit: boolean;
  hasEditPermissions?: boolean;
  owner: IOwnerObject;
  updateEventPageComponent: ReduxActionType<typeof updateEventPageComponent>;
  getChildEvents: ReduxActionType<typeof getChildEvents>;
  onManageChildEventsClicked(): void;
}

interface IState {
  title: string;
  eventList: IEventSummaryFE[];
  search: string;
}

/** Component that allows an Event to List Child Events tied to this current Event. */
class ChildEventList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      title: this.props.component.title || 'Child Events',
      eventList: this.props.component.content_objects || [],
      search: '',
    };

    this.handleComponentDrag = this.handleComponentDrag.bind(this);
  }

  componentDidMount() {
    if (this.props.hasEditPermissions) {
      this.props.getChildEvents(this.props.owner.ownerId);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.component.content_objects !== this.props.component.content_objects) {
      this.setState({
        eventList: this.props.component.content_objects as IEventSummaryFE[],
      });
    }

    if (prevProps.component.meta_data !== this.props.component.meta_data) {
      this.setState({
        title: this.props.component.title || 'Child Events',
      });
    }

    if (prevProps.edit && !this.props.edit) {
      this.update();
    }

    if (
      this.props.hasEditPermissions !== prevProps.hasEditPermissions &&
      this.props.hasEditPermissions === true
    ) {
      this.props.getChildEvents(this.props.owner.ownerId);
    }
  }

  handleComponentDrag(result: DropResult, provided: ResponderProvided) {
    if (result.destination != null) {
      let _eventList = this.state.eventList;

      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      _eventList.splice(destIndex, 0, _eventList.splice(sourceIndex, 1)[0]);

      this.setState(
        {
          eventList: _eventList,
        },
        () => {
          let _object_ids: string[] = [];

          for (let i in _eventList) {
            _object_ids.push(_eventList[i].id);
          }

          this.props.updateEventPageComponent(
            this.props.page.owner_id,
            this.props.page.id,
            this.props.component.id,
            {
              content_references: {
                object_type: 'event',
                object_ids: _object_ids,
              },
            },
          );
        },
      );
    }
  }

  update() {
    const payload = this.props.component;

    payload.title = this.state.title;

    if (this.props.hasEditPermissions) {
      this.props.updateEventPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    }
  }

  getFilteredEventList() {
    let filteredEvents = this.state.eventList.filter(
      (event) => event.status?.code !== Constants.event_status.archived,
    );

    if (this.state.search) {
      const search = this.state.search.toLowerCase();
      filteredEvents = filteredEvents.filter((event) => event.title.toLowerCase().includes(search));
    }

    return filteredEvents;
  }

  render() {
    return (
      <div className="ChildEventList">
        {this.props.edit && (
          <TextField
            value={this.state.title}
            name="title"
            type="text"
            onChange={(e: any) => {
              handleInputChange(e, this);
            }}
          />
        )}
        {!this.props.edit && <h3>{this.state.title}</h3>}

        <TextField
          className="search-bar"
          icon="fas fa-search"
          value={this.state.search}
          type="text"
          name="search"
          onChange={(e: any) => {
            handleInputChange(e, this);
          }}
        />

        {this.props.hasEditPermissions &&
          this.props.component.content_objects &&
          this.props.component.content_objects.length === 0 && (
            <ComponentPlaceholder
              title="Welcome to your new Child Event component!"
              message="Create and manage all your child events and fundraisers"
              actions={[
                {
                  label: 'Manage Events',
                  iconClassName: 'fas fa-cog',
                  action: () => this.props.onManageChildEventsClicked(),
                },
              ]}
            />
          )}

        <DragDropContext onDragEnd={this.handleComponentDrag}>
          <Droppable droppableId="components">
            {(provided, snapshot) => (
              <div
                className={snapshot.isDraggingOver ? 'components-wrap dragging' : 'components-wrap'}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Pagination
                  itemsPerPage={8}
                  items={this.getFilteredEventList()}
                >
                  {(itemsToRender, pageIndexOffset) =>
                    itemsToRender.map((item: any, index: number) => {
                      return (
                        <Draggable
                          key={'event-' + item.id}
                          draggableId={'event-' + item.id}
                          index={pageIndexOffset + index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <ChildEventListItem
                                key={index}
                                event={item}
                                dragProvided={provided.dragHandleProps}
                                hasEditPermissions={this.props.hasEditPermissions}
                                history={this.props.history}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  }
                </Pagination>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return { eventState: store.eventState };
};

const mapDispatchToProps = {
  getGroupEvents,
  updateEventPageComponent,
  getChildEvents,
  updateGroupPageComponent,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ChildEventList),
);
