import React, { Component } from 'react';
import Button from '../../Button/Button';

import './ComponentPlaceholder.scss';

interface IAction {
  label: string;
  iconClassName: string;
  action(): void;
}
interface IProps {
  title: string;
  message: string;
  actions: IAction[];
  errorMessageComponent?: React.ReactNode | null;
}

/** Renders a placeholder box. Used when a page component doesn't have content. Can have one or more actions. */
class ComponentPlaceholder extends Component<IProps> {
  render() {
    return (
      <div className="ComponentPlaceholder">
        {this.props.errorMessageComponent && (
          <div className="error-message-container">
            <i className="icon fal fa-exclamation-circle"></i>
            <span>{this.props.errorMessageComponent}</span>
          </div>
        )}
        <div className="container">
          <div className="title">{this.props.title}</div>
          <div className="message">{this.props.message}</div>
          <div className="action-container">
            {this.props.actions.map((action, index) => (
              <Button
                className="action-button"
                key={index}
                text={action.label}
                icon={action.iconClassName}
                onClick={action.action}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ComponentPlaceholder;
