import React from 'react';
import Button, { IButtonProps } from '../../../../Button/Button';
import './ModalSectionRow.scss';

interface IModalSectionRowProps {
  title: string;
  description?: string;
  headerActionButtonProps?: IButtonProps;
  /** Pass children to be rendered under the section header*/
  children: React.ReactNode;
}
/**
 * This component is to be used in EditModals children, or other modals.
 * The props control what's displayed in the header, but you can control the section
 * content by providing children to this component.
 */
function ModalSectionRow(props: IModalSectionRowProps) {
  const { title, description, headerActionButtonProps, children } = props;

  return (
    <div className="ModalSectionRow">
      <div className="modal-section-header">
        <div className="title-container">
          <h2 className="title">{title}</h2>
          {description && <p className="description">{description}</p>}
        </div>
        {headerActionButtonProps && (
          <Button
            {...props.headerActionButtonProps}
            className="header-action-button"
            buttonType="secondary"
          />
        )}
      </div>
      <div className="section-content">{children}</div>
    </div>
  );
}

export default ModalSectionRow;
