import {
  IEventSummaryFE,
  IGroup,
  IGroupSummary,
  ISpotlight,
  ISpotlightList,
} from '@gigit/interfaces';
import React, { useEffect, useState } from 'react';
import Button from '../../../../Button/Button';
import Portrait from '../../../../Portrait/Portrait';
import FocusAreas from '../../../../shared/FocusAreas/FocusAreas';
import { DateSelection } from '../../../../shared/Onboarding/DateSelection/DateSelection';
import PopupMenu, {
  IPopupMenuItem,
  IShowPopupConfig,
} from '../../../../shared/PopupMenu/PopupMenu';
import TextField from '../../../../TextField/TextField';

import './SpotlightForm.scss';

interface ISpotlightForm {
  typeEntities: IGroup[] | IEventSummaryFE[];
  spotlight?: ISpotlight;
  selectedEntityId: string;
  setShowAddSpotlight: () => void;
  addSpotlight: (spotlight: ISpotlightList) => void;
}
export default function SpotlightForm(props: ISpotlightForm) {
  // TODO: Fix Types - broken due to mismatch between B.E. and component interfaces, requires refactoring date field
  // also the object_Id believes it should be a ObjectId type instead of string, need to separate FE and BE interfaces
  const [spotlight, setSpotlight] = useState<any | undefined>(props?.spotlight ?? undefined); // ISpotlight
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [typeEntities, setTypeEntities] = useState<IGroup[] | IEventSummaryFE[]>(
    props.typeEntities ?? [],
  );
  const [selectedEntity, setSelectedEntity] = useState<IGroup | IEventSummaryFE | undefined>(
    undefined,
  );
  const [query, setQuery] = useState<string>('');
  const [showContextMenuModalSort, setShowContextMenuModalSort] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<'asc' | 'desc'>('asc');
  const { setShowAddSpotlight, addSpotlight } = props;

  const popupMenuConfigModalSort: IShowPopupConfig = {
    showMenu: showContextMenuModalSort,
    setShowMenu: setShowContextMenuModalSort,
    position: {
      type: 'bottom',
    },
  };
  const contextMenuItemsModalSort: IPopupMenuItem[] = [
    {
      id: 'a_z',
      label: 'A to Z',
      isSelected: 'asc' === sortBy,
      onClick: () => {
        setSortBy('asc');
        setShowContextMenuModalSort(false);
      },
    },
    {
      id: 'z_a',
      label: 'Z to A',
      isSelected: 'desc' === sortBy,
      onClick: () => {
        setSortBy('desc');
        setShowContextMenuModalSort(false);
      },
    },
  ];

  useEffect(() => {
    if (props.selectedEntityId) {
      const selected = [...typeEntities].find(
        (entity: IGroup | IEventSummaryFE) => entity.id === props.selectedEntityId,
      );
      if (selected) setSelectedEntity(selected);
    }
  }, [props.selectedEntityId]);
  useEffect(() => {
    setTypeEntities(props.typeEntities);
  }, [props.typeEntities]);

  useEffect(() => {
    if (selectedEntity) {
      setSpotlight({ ...spotlight, object_id: selectedEntity.id });
    }
  }, [selectedEntity]);

  const onEntitySelect = (entity: IGroup | IEventSummaryFE) => {
    if (selectedEntity?.id === entity.id) {
      setSelectedEntity(undefined);
      return;
    }

    setSelectedEntity(entity);
  };
  const renderFirstStep = () => {
    return (
      <div className="entities-container">
        {typeEntities.map((entity: IGroup | IEventSummaryFE) => {
          const focusAreas = (entity as IGroup)?.focus_areas ?? [];
          return (
            <div
              className={`spotlight-item ${entity.id === selectedEntity?.id ? 'selected' : ''}`}
              key={entity.id}
              onClick={() => onEntitySelect(entity)}
            >
              <div className="portrait-container">
                <Portrait
                  source={entity?.profile_image_url}
                  size={30}
                />
              </div>
              <div className="info-container">
                <p> {entity?.title} </p>
              </div>
              <div className="focus-areas-container">
                <FocusAreas
                  focusAreas={focusAreas}
                  autofill={true}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const renderSecondStep = () => {
    // TODO: Only groups have focus areas returned currently; Should we have for events too
    const focusAreas = (selectedEntity as IGroup)?.focus_areas ?? [];
    return (
      <div className="spotlight-container">
        <div className={`spotlight-item `}>
          <div className="portrait-container">
            <Portrait
              source={selectedEntity?.profile_image_url}
              size={30}
            />
          </div>
          <div className="info-container">
            <p> {selectedEntity?.title} </p>
          </div>
          <div className="focus-areas-container">
            <FocusAreas
              focusAreas={focusAreas}
              autofill={true}
            />
          </div>
        </div>
        <h3> Duration </h3>
        <DateSelection
          startDate={spotlight?.spotlight_start || ''}
          endDate={spotlight?.spotlight_end || ''}
          onStartChange={(e) => setSpotlight({ ...spotlight, spotlight_start: e })}
          onEndChange={(e) => setSpotlight({ ...spotlight, spotlight_end: e })}
        />
      </div>
    );
  };

  const shouldDisableNext = currentStep === 1 && !selectedEntity?.id;
  return (
    <div className="SpotlightForm">
      <div className="modal-header">
        <h2>Add Spotlight</h2>
        {currentStep === 1 && (
          <div className="filter-container">
            <TextField
              icon="fal fa-search"
              placeholder="Search for a Charity or Non-Profit"
              value={query || ''}
              name="modal_search"
              type="text"
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            <PopupMenu
              showMenuConfig={popupMenuConfigModalSort}
              menuItems={contextMenuItemsModalSort}
              popupMenuClass="hubManagementBenefitingCauses-popupMenu"
              className={`Dropdown filter-dropdown ${showContextMenuModalSort ? 'show' : ''}`}
              onClick={() => setShowContextMenuModalSort(!showContextMenuModalSort)}
            >
              <label>
                <span>Sort by</span>
              </label>
              <div className="input-wrap">
                <span
                  className="label"
                  notranslate="yes"
                >
                  {sortBy === 'asc' ? 'A to Z' : 'Z to A'}
                </span>
                <i
                  className={`fas fa-caret-down menu-btn ${showContextMenuModalSort ? 'show' : ''}`}
                />
              </div>
            </PopupMenu>
          </div>
        )}
      </div>
      <div className="modal-main">
        {currentStep === 1 && renderFirstStep()}
        {currentStep === 2 && renderSecondStep()}
      </div>
      <div className="btn-container">
        <Button
          text={currentStep === 1 ? 'Cancel' : 'Back'}
          type="button"
          onClick={() =>
            currentStep === 1 ? setShowAddSpotlight() : setCurrentStep(currentStep - 1)
          }
          buttonType="secondary"
        />
        <Button
          text={currentStep === 1 ? 'Next' : 'Save'}
          type="button"
          buttonType="dark"
          isDisabled={shouldDisableNext}
          onClick={() =>
            currentStep === 1 ? setCurrentStep(currentStep + 1) : addSpotlight(spotlight)
          }
        />
      </div>
    </div>
  );
}
