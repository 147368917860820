import React, { ChangeEventHandler } from 'react';
import ReactMarkdown from 'react-markdown';
import { combineClassNames } from '../../helpers';

import './Checkbox.scss';

export interface ICheckboxProps {
  name: string;
  label?: string;
  value: any;
  checked: boolean;
  color?: string;
  iconChecked?: string;
  iconNoChecked?: string;
  onChange(e: any): void;
  notranslate?: 'yes';
  className?: string;
  disabled?: boolean;
}

interface IState {}

{
  /* TODO: Add disabled state styles from UI kit */
}
class Checkbox extends React.Component<ICheckboxProps, IState> {
  render() {
    return (
      <div
        className={combineClassNames('Checkbox no-select', this.props.className)}
        notranslate={this.props.notranslate}
      >
        <label>
          <input
            name={this.props.name}
            checked={this.props.checked}
            value={this.props.value}
            type="checkbox"
            disabled={this.props?.disabled}
            onChange={(e) => {
              if (!this.props?.disabled) {
                this.props.onChange(e);
              }
            }}
          />
          {this.props?.iconChecked && <i className={this.props.iconChecked} />}
          {!this.props?.iconChecked && <i className="fas fa-square" />}

          {this.props?.iconNoChecked && (
            <i
              className={
                this.props.color === undefined
                  ? this.props.iconNoChecked
                  : this.props.iconNoChecked + ' ' + this.props.color
              }
            />
          )}
          {!this.props?.iconNoChecked && (
            <i
              className={
                this.props.color === undefined
                  ? 'fas fa-check-square'
                  : 'fas fa-check-square ' + this.props.color
              }
            />
          )}
          <ReactMarkdown source={this.props.label} />
        </label>
      </div>
    );
  }
}

export default Checkbox;
