import React, { useEffect, useState } from 'react';
import { IAddress, IHub } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import { hubRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import './HubManagementLocations.scss';
import { formatAddressLine, typeHelpers } from '../../../../helpers';
import Table, { ITableProps } from '../../../shared/Table/Table';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddLocationModal from '../../../AddLocationModal/AddLocationModal';

interface IProps {
  hub?: IHub;
}

function HubManagementLocations(props: IProps) {
  const hub = props.hub;
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const [showAddLocation, setShowAddLocation] = useState<boolean>(false);

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  const hubManagementLocationsTableProps: ITableProps<IAddress> = {
    columns: [
      {
        id: 'title',
        Header: 'Title',
        accessor: ({ title }) => title,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ title }) => {
          return (
            <div
              className="item-name-col"
              notranslate="yes"
            >
              <span>{title}</span>
            </div>
          );
        },
      },
      {
        id: 'address',
        Header: 'Address',
        notranslate: 'yes',
        Cell: (item) => {
          return (
            <div
              className="col address"
              notranslate="yes"
            >
              <span className="date">{formatAddressLine(item)}</span>
            </div>
          );
        },
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Delete Location',
          icon: 'fa fa-trash',
          onClick: async (_, location) => {
            await hubRequestActions.archiveHubLocation(hub?.id ?? '', location.id!);
            refreshTableData();
          },
        },
      ],
    },
    filterOptions: {
      enableTableSearch: true,
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        typeHelpers.assertNotNullOrUndefined(props.hub?.id);
        return await hubRequestActions.getHubLocations(props.hub?.id, queryParams);
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any events",
    },
  };

  return (
    <>
      <div
        className="HubManagementLocations"
        id="locations"
      >
        <div className="header-section">
          <h2>Locations</h2>
          <div className="header-actions">
            <Button
              type="button"
              text="Create Location"
              icon="fal fa-plus"
              onClick={() => setShowAddLocation(true)}
            />
          </div>
        </div>
        <div className="table-section">
          <Table
            {...hubManagementLocationsTableProps}
            refreshTableIncrementor={refreshTableIncrementor}
          />
        </div>
      </div>

      {showAddLocation && (
        <AddLocationModal
          owner={typeHelpers.createOwnerObject('hub', hub!)}
          onClose={() => setShowAddLocation(false)}
          onLocationAdded={() => refreshTableData()}
        />
      )}
    </>
  );
}

export default HubManagementLocations;
