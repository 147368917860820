import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { IStringMap, handleInputChange } from '../../../helpers';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IEventSummaryFE, IPage } from '@gigit/interfaces';
import { getGroupEvents, updateGroupPageComponent } from '../../../actions/group';
import EventListItem from '../EventListItem/EventListItem';
import './EventList.scss';
import TextField from '../../TextField/TextField';
import ComponentPlaceholder from '../ComponentPlaceholder/ComponentPlaceholder';
import { Constants } from '@gigit/constants';
import { IEventListComponent, IOwnerObject, ReduxActionType } from '../../../interfaces';
import { IUserState } from '../../../reducers/user';

interface IProps {
  getGroupEvents: ReduxActionType<typeof getGroupEvents>;
  updateGroupPageComponent: ReduxActionType<typeof updateGroupPageComponent>;
  permissions?: IStringMap;
  component: IEventListComponent;
  page: IPage;
  edit: boolean;
  hasEditPermissions?: boolean;
  onManageEventsClicked(): void;
  onCreateEventClicked(): void;
  owner: IOwnerObject;
  userState: IUserState;
}

interface IState {
  title: string;
  eventList: IEventSummaryFE[];
}

class EventList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      title: this.props.component.title || 'Events',
      eventList: this.props.component.content_objects || [],
    };

    this.handleComponentDrag = this.handleComponentDrag.bind(this);
  }

  componentDidMount() {
    if (this.props.permissions && this.props.permissions['EDIT_GROUP_PAGES']) {
      this.props.getGroupEvents(this.props.owner.ownerId);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.component.content_objects !== this.props.component.content_objects) {
      this.setState({
        eventList: this.props.component.content_objects ?? [],
      });
    }

    if (prevProps.edit && !this.props.edit) {
      this.update();
    }
  }

  handleComponentDrag(result: any, provided: any) {
    if (result.destination !== null) {
      let _eventList = this.state.eventList;

      _eventList.splice(result.destination.index, 0, _eventList.splice(result.source.index, 1)[0]);

      this.setState(
        {
          eventList: _eventList,
        },
        () => {
          let _object_ids: string[] = [];

          for (let i in _eventList) {
            _object_ids.push(_eventList[i].id);
          }

          this.props.updateGroupPageComponent(
            this.props.owner.ownerId,
            this.props.page.id,
            this.props.component.id,
            {
              content_references: {
                object_type: 'event',
                object_ids: _object_ids,
              },
            },
          );
        },
      );
    }
  }

  update() {
    const payload = this.props.component;

    payload.title = this.state.title;

    if (this.props.permissions && this.props.permissions['EDIT_GROUP_PAGES']) {
      this.props.updateGroupPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    }
  }

  render() {
    const activeEventStatuses = [
      Constants.event_status.active,
      Constants.event_status.published,
      Constants.event_status.completed,
    ];
    const eventsToDisplay = this.state.eventList.filter(
      (event) =>
        this.props.hasEditPermissions ||
        activeEventStatuses.find((status) => status === event.status?.code),
    );

    return (
      <div className="EventList">
        {this.props.edit && (
          <TextField
            value={this.state.title}
            name="title"
            type="text"
            onChange={(e: any) => {
              handleInputChange(e, this);
            }}
          />
        )}
        {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}
        <DragDropContext onDragEnd={this.handleComponentDrag}>
          <Droppable droppableId="components">
            {(provided, snapshot) => (
              <div
                className={snapshot.isDraggingOver ? 'components-wrap dragging' : 'components-wrap'}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {eventsToDisplay.map((item: any, index: number) => {
                  if (item.status && item.status.code !== 'archived') {
                    return (
                      <Draggable
                        key={index}
                        draggableId={'event-' + item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <EventListItem
                              key={index}
                              {...this.props}
                              dragProps={provided.dragHandleProps}
                              event={item}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                  return null;
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {this.props.hasEditPermissions &&
          this.props.component.content_objects &&
          this.props.component.content_objects.length === 0 && (
            <ComponentPlaceholder
              title="Welcome to your new Event component!"
              message="Create and manage all your events and fundraisers"
              actions={[
                {
                  label: 'Create Event',
                  iconClassName: 'fas fa-plus-circle',
                  action: () => this.props.onCreateEventClicked(),
                },
                {
                  label: 'Manage Events',
                  iconClassName: 'fas fa-cog',
                  action: () => this.props.onManageEventsClicked(),
                },
              ]}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {
  getGroupEvents,
  updateGroupPageComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
