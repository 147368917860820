import React, { ReactNode, useEffect, useState } from 'react';
import {
  ICause,
  IHub,
  IOrgPremiumFeaturesSummary,
  ISocialImpactBenefits,
  IUser,
} from '@gigit/interfaces';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import './HubOnBoardingGuide.scss';
import { hubRequestActions } from '../../../requestActions';
import { typeHelpers, uploadImage } from '../../../helpers';
import { uiConstants } from '../../../constants';
import useToastDispatcher from '../../../hooks/useToaster';
import AddBrandingStep from './AddBrandingStep/AddBrandingStep';
import BannerStep from './BannerStep/BannerStep';
import { IOwnerObject } from '../../../interfaces';
import { CausesSelectionStep } from './CauseSelectionStep/CauseSelectionStep';
import SocialImpactBenefitsForm from '../Shared/SocialImpactBenefitsForm/SocialImpactBenefitsForm';

interface IProps {
  hub?: IHub;
  hubSubscriptions?: IOrgPremiumFeaturesSummary | null;
  show: boolean;
  onClose(): void;
  fetchHubOnFinish(): void;
}

interface IStepConfig {
  title: string;
  description: string;
  sectionContent: ReactNode;
}

function HubOnBoardingGuide(props: IProps) {
  const { dispatchToastError } = useToastDispatcher();

  const [step, setStep] = useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [owner, setOwner] = useState<IOwnerObject>();
  // Add Branding
  const [themePrimary, setThemePrimary] = useState<string>(
    props?.hub?.theme?.primary_color || '#2C73D6',
  );
  const [themeSecondary, setThemeSecondary] = useState<string>(
    props?.hub?.theme?.secondary_color || '#FFFFFF',
  );
  const [logo, setLogo] = useState<string>(props?.hub?.profile_image_url || '');
  const [bannerImage, setBannerImage] = useState<string>(props?.hub?.banner?.image || '');

  // Banner Layout
  const [bannerLayout, setBannerLayout] = useState<string>(props?.hub?.banner?.layout || 'center');

  const [availableSeats, setAvailableSeats] = useState<number>(0);

  //Add focus areas step
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<ICause[]>([]);

  //Add social impact benefits
  const [socialImpactBenefits, setSocialImpactBenefits] = useState<ISocialImpactBenefits>();

  useEffect(() => {
    if (props.hubSubscriptions) {
      const seatsFeature = props.hubSubscriptions.features.find(
        (feature) => feature.feature_code == uiConstants.premiumFeatures.hub_seating,
      );
      if (seatsFeature && seatsFeature.seats) {
        setAvailableSeats(seatsFeature.seats.total - seatsFeature.seats.used);
      }
    }
  }, [props.hubSubscriptions]);

  useEffect(() => {
    if (props.hub) {
      setOwner(typeHelpers.createOwnerObject('hub', props.hub));
    }
  }, [props.hub]);

  const numberOfSteps = 3; // max steps - 1
  const stepsConfig = [
    {
      title: 'Add Branding',
      description:
        "Customizing your Company Page is the first step to sharing your company's social impact story.  Let's get your Company Page setup!",
      sectionContent: (
        <AddBrandingStep
          themePrimary={themePrimary}
          setThemePrimary={setThemePrimary}
          themeSecondary={themeSecondary}
          setThemeSecondary={setThemeSecondary}
          logo={logo}
          setLogoFile={setLogo}
          bannerImage={bannerImage}
          setBannerImage={setBannerImage}
        />
      ),
    },
    {
      title: 'Customize Company Banner',
      description:
        "Branding your company is an essential step in creating a successful company. Add your brand's colors and imagery below.",
      sectionContent: (
        <BannerStep
          bannerLayout={bannerLayout}
          setBannerLayout={setBannerLayout}
        />
      ),
    },
    {
      title: 'Choose focus areas that best describe your company',
      description: 'Select all that apply',
      sectionContent: (
        <CausesSelectionStep
          handleSelectFocusAreas={(selectedFocusAreas) => setSelectedFocusAreas(selectedFocusAreas)}
        />
      ),
    },
    {
      title: 'Social Impact Benefits',
      sectionContent: (
        <SocialImpactBenefitsForm
          hub={props.hub || null}
          socialImpactBenefits={socialImpactBenefits!}
          setSocialImpactBenefits={setSocialImpactBenefits}
        />
      ),
    },
  ] as IStepConfig[];

  async function saveOnBoarding() {
    try {
      setIsSaving(true);

      if (props?.hub?.id) {
        let uploadedBannerURL,
          uploadedLogoURL = null;

        if (bannerImage && bannerImage !== props?.hub?.banner?.image)
          uploadedBannerURL = await uploadImage(bannerImage);

        if (logo && logo !== props?.hub?.profile_image_url)
          uploadedLogoURL = await uploadImage(logo);

        await hubRequestActions.updateHub({
          ...props.hub,
          theme: {
            primary_color: themePrimary,
            secondary_color: themeSecondary,
          },
          banner: {
            ...props.hub?.banner,
            image: uploadedBannerURL || props.hub.banner?.image,
            layout: bannerLayout,
          },
          profile_image_url: uploadedLogoURL || props.hub.profile_image_url,
          causes: selectedFocusAreas.map((cause) => cause.id!),
        });
        props.fetchHubOnFinish();
        props.onClose();
      }
    } catch (error) {
      dispatchToastError(error, 'Error Onboarding');
    } finally {
      setIsSaving(false);
    }
  }

  function nextStep() {
    if (step < numberOfSteps) {
      setStep(step + 1);
    } else {
      saveOnBoarding();
    }
  }

  function skipGuide() {
    saveOnBoarding();
    props.onClose();
  }

  function backStep() {
    setStep(step - 1);
  }

  const ComponentToRender = stepsConfig[step].sectionContent;

  return (
    <Modal
      class="HubOnBoardingGuide"
      show={props.show}
      onClose={props.onClose}
      title={stepsConfig[step].title}
      description={stepsConfig[step].description}
    >
      <div className="inner-content">{ComponentToRender}</div>
      <div className="bottom-container">
        <div className="back">
          {step > 0 && (
            <Button
              text="Back"
              type="button"
              onClick={backStep}
              buttonType="secondary"
            />
          )}
        </div>
        <div className="step-indicator">
          <span className={`circle ${step === 0 ? 'active' : ''}`} />
          <span className={`circle ${step === 1 ? 'active' : ''}`} />
          <span className={`circle ${step === 2 ? 'active' : ''}`} />
          <span className={`circle ${step === 3 ? 'active' : ''}`} />
        </div>
        <div className="next">
          <Button
            text="Skip"
            type="button"
            onClick={skipGuide}
            buttonType="secondary"
          />
          <Button
            text={`${numberOfSteps === step ? 'Finish' : 'Next'}`}
            type="button"
            loading={isSaving}
            onClick={nextStep}
            buttonType="primary"
          />
        </div>
      </div>
    </Modal>
  );
}

export default HubOnBoardingGuide;
