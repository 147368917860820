import axios from 'axios';
import { routes } from '../helpers';
import {
  IEventSummaryFE,
  IEventSearch,
  IGigSearch,
  IGroup,
  IGroupSearch,
  IQuery,
  ISearchResultObject,
  IGigSummaryFE,
  IGroupSearch_v2,
  IEventSearch_v2,
  IGigSearch_v2,
} from '@gigit/interfaces';

export namespace searchRequestActions {
  export const searchGroups = async (groupSearch: IGroupSearch, queryParams?: IQuery) => {
    const results = await axios.post<ISearchResultObject<IGroup>[]>(
      routes.SEARCH_GROUPS,
      groupSearch,
      { params: { limit: queryParams?.limit, sort: queryParams?.sort } },
    );
    return results.data;
  };

  export const searchEvents = async (eventSearch: IEventSearch, queryParams?: IQuery) => {
    const results = await axios.post<ISearchResultObject<IEventSummaryFE>[]>(
      routes.SEARCH_EVENTS,
      eventSearch,
      { params: { limit: queryParams?.limit, sort: queryParams?.sort } },
    );
    return results.data;
  };

  export const searchGigs = async (gigSearch: IGigSearch, queryParams?: IQuery) => {
    const results = await axios.post<ISearchResultObject<IGigSummaryFE>[]>(
      routes.SEARCH_GIGS,
      gigSearch,
      { params: { limit: queryParams?.limit } },
    );
    return results.data;
  };

  export const searchGroupsV2 = async (groupSearch: IGroupSearch_v2) => {
    const results = await axios.post<ISearchResultObject<IGroup>[]>(
      routes.GROUP_SEARCH_V2,
      groupSearch,
    );
    return results.data;
  };

  export const searchEventsV2 = async (eventSearch: IEventSearch_v2) => {
    const results = await axios.post<ISearchResultObject<IEventSummaryFE>[]>(
      routes.EVENT_SEARCH_V2,
      eventSearch,
    );
    return results.data;
  };

  export const searchGigsV2 = async (gigSearch: IGigSearch_v2) => {
    const results = await axios.post<ISearchResultObject<IGigSummaryFE>[]>(
      routes.GIG_SEARCH_V2,
      gigSearch,
    );
    return results.data;
  };

  // export const searchAddress = (_search: string) => {
  //     return async(dispatch: Dispatch, getState: any) => {
  //         axios.get(swapRouteParams('https://api.mapbox.com/geocoding/v5/mapbox.places/:query.json?access_token=:access_token', {query: _search, access_token: Config.web.REACT_APP_MAPBOX_API_KEY, })).then((response) => {
  //             let results;
  //             if (response.data && response.data.features.length > 0) {
  //                 results = response.data.features;
  //             } else {
  //                 results = [];
  //             }
  //             dispatch({
  //                 searchResults: results,
  //                 type: SearchActionTypes.UPDATE_SEARCH_DEFAULT
  //             })
  //         });
  //     }
  // }

  // export const resetCriteria = () => {
  //     return async(dispatch: Dispatch, getState: any) => {
  //         dispatch({
  //             gigs: [],
  //             groups: [],
  //             events: [],
  //             type: SearchActionTypes.UPDATE_SEARCH
  //         })
  //     };
  // }
}
