import './SocialImpactBenefitsModal.scss';
import React, { useEffect } from 'react';
import EditModal from '../shared/EditModal/EditModal';
import { useState } from 'react';
import { IHub, ISocialImpactBenefits } from '@gigit/interfaces';
import useToastDispatcher from '../../../../hooks/useToaster';
import { hubRequestActions } from '../../../../requestActions';
import SocialImpactBenefitsForm from '../../Shared/SocialImpactBenefitsForm/SocialImpactBenefitsForm';

interface IProps {
  hub: IHub | null;
  tryRefreshHub: () => void;
  showModal: boolean;
  onClose: () => void;
}

function SocialImpactBenefitsModal(props: IProps) {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [socialImpactBenefits, setSocialImpactBenefits] = useState<ISocialImpactBenefits>();
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  useEffect(() => {
    if (props.hub != null && props.hub.social_impact_benefits) {
      setSocialImpactBenefits(props.hub.social_impact_benefits);
    }
  }, [props.hub]);

  async function handleSaveSocialImpactBenefits() {
    setIsSaving(true);
    const payload = {
      social_impact_benefits: socialImpactBenefits,
    };

    try {
      await hubRequestActions.updateHub(payload, props.hub?.id);
      props.tryRefreshHub();
      dispatchToastSuccess(
        'Successfully Updated Social Impact Benefits',
        'Update Social Impact Benefits',
      );
    } catch (error) {
      dispatchToastError(error, 'Update Social Impact Benefits');
    } finally {
      setIsSaving(false);
      props.onClose();
    }
  }

  return (
    <EditModal
      key="hub-modal-social-impact-benefits"
      title="Social Impact Benefits"
      showModal={props.showModal}
      onSave={handleSaveSocialImpactBenefits}
      isSaving={isSaving}
      onClose={props.onClose}
      modalClassName="HubModalSocialImpactBenefits"
      modalSize="medium"
    >
      {socialImpactBenefits && (
        <SocialImpactBenefitsForm
          hub={props.hub}
          socialImpactBenefits={socialImpactBenefits}
          setSocialImpactBenefits={setSocialImpactBenefits}
        />
      )}
    </EditModal>
  );
}

export default SocialImpactBenefitsModal;
