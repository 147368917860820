import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  defaultCurrency,
  formatCurrency,
  extractLettersFromQuillMarkup,
  handleInputChange,
  routes,
  swapRouteParams,
  toastError,
  toastInfo,
  toastSuccess,
  ValidateHandleStatus,
  errorHelpers,
  isURL,
} from '../../helpers';
import SignatureCanvas from 'react-signature-canvas';
import { Config } from '@gigit/config';
import Dropdown, { IOptions } from '../../components/Dropdown/Dropdown';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import ImageUpload from '../ImageUpload/ImageUpload';
import Modal from '../Modal/Modal';
import ImageCropper from '../ImageCropper/ImageCropper';
import QuillTextEditor from '../QuillTextEditor/QuillTextEditor';
import { IGroupState } from '../../reducers/group';
import { createToast } from '../../actions/toaster';
import {
  getGroup,
  updateGroup,
  changeGroupHandle,
  getGroupCampaigns,
  getGroupSignature,
  setGroupSignature,
  getGroupLocations,
  updateGroupReceiptImage,
  getGroupReceiptNumber,
  getGroupReceiptNumberFormatted,
  updateGroupRaisedAmount,
  getGroupHubs,
  setGroupPrimaryHub,
} from '../../actions/group';
import axios from 'axios';
import { Constants } from '@gigit/constants';
import {
  ILocalization,
  ISolution,
  ISolutionInstance,
  IStatus,
  IMailChimpIntegrationResponse,
  IAccountDetails,
} from '@gigit/interfaces';
import ToggleButton from '../shared/ToggleButton/ToggleButton';
import { IToast } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import { groupSelectors } from '../../selectors/group';
import { userSelectors } from '../../selectors/user';
import charityVerification from '../../assets/charity-verification.svg';
import './SettingsManagement.scss';
import { Prompt } from '../Prompt/Prompt';
import { localeConstants } from '../../constants';
import { Banner } from '../shared/Banner/Banner';
import {
  billingRequestActions,
  accountRequestActions,
  groupRequestActions,
} from '../../requestActions';
import ExternalVolunteerApplicationWarning from '../shared/ExternalVolunteerApplicationWarning/ExternalVolunteerApplicationWarning';

const months: IOptions[] = localizeHelpers.getMonths().map((date, idx) => {
  return { label: date, value: String(idx) };
});

interface IProps extends RouteComponentProps<any> {
  groupId: string;
  createAction(tab: string): void;
  updateGroup(_payload: any, groupId: string): Promise<void>;
  updateGroupRaisedAmount(groupId: string, campaignId: string): void;
  getGroupCampaigns(groupId: string): void;
  getGroupSignature(groupId: string): void;
  setGroupSignature(groupId: string, payload: any): void;
  getGroupLocations(groupId: string): void;
  updateGroupReceiptImage(groupdId: string, img: string): void;
  getGroupReceiptNumber(groupId: string, campaign_id: string): void;
  getGroupReceiptNumberFormatted(groupId: string, campaign_id: string): void;
  groupState: IGroupState;
  createToast(toast: IToast): void;
  changeGroupHandle(groupId: string, newHandle: string, onChanged: () => void): void;
  onChangeAdminTab(tab: string): void;
  getGroupHubs(groupId: string, query?: string): void;
  setGroupPrimaryHub(
    groupId: string,
    hubId: string | null,
    options?: { callback?: () => void },
  ): void;
  defaultHubId: string | null;
  locale: string;
  getGroup(groupId: string): void;
}

interface IState {
  handle: string;
  validateHandleStatus: ValidateHandleStatus;
  changeHandleError: string | null;
  forms: any[];
  currentForm: string;
  loaded: boolean;
  campaigns: any[];
  funds: IOptions[];
  active_campaign: string;
  defaultFund: string;
  default_location_id: string;
  canvasRef: any;
  charityID: string;
  currentSignature: string;
  locations: any[];
  localization?: ILocalization;
  activeRow: string;
  groupType: string;
  message: string;
  modules: any;
  formats: Array<any>;
  logoSrc: string;
  logoImageUrl: string;
  showLogoCropModal: boolean;
  description: string;
  feeControl: any;
  useDonationDescriptions: boolean;
  donationAmounts: any[] | undefined;
  receiptHeader: string;
  receiptFooter: string;
  charityName: string;
  signatureName: string;
  signatureTitle: string;
  fiscalYearEndMonth: number;
  fiscalYearEndDay: number;
  contactPhone: string;
  contactEmail: string;
  isProgressBarUpdating: boolean;
  allow_checkout_donations: boolean;
  donorLevelRegMin: string | null;
  donorLevelRegMax: string | null;
  donorLevelMidMin: string | null;
  donorLevelMidMax: string | null;
  donorLevelMajorMin: string | null;
  donorLevelMajorMax: string | null;
  minimumTaxReceiptAmount: string;
  donationCheckoutMessage: string;
  defaultHubId: string | null;
  charityStatus: IStatus | null;
  showCharityVerification: boolean;
  showVerifyEmail: boolean;
  showVerifyPhone: boolean;
  availableIntegrations: ISolution[];
  existingIntegrations: ISolutionInstance[];
  showSolutionModal: boolean;
  solutionFrameURL: string;
  receiptPrefix: string;
  removeConnection: boolean;
  removeIntegration: ISolution | ISolutionInstance | null;
  fetchedAccounts: IAccountDetails[];
  isStripeConnected: boolean;
  canToggleBannerMessage: boolean;
  isPurplePlus: boolean;
  external_application_url: string;
  external_application_url_error: boolean;
  showExternalVolunteerApplicationWarning: boolean;
  canToggleAutoReceipts: boolean;
}

class SettingsManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      handle: '',
      validateHandleStatus: ValidateHandleStatus.NORMAL,
      changeHandleError: null,
      forms: [],
      campaigns: [],
      funds: [],
      currentForm: '',
      active_campaign: this.props.groupState.group.campaign_id || '',
      defaultFund: this.props.groupState.group.default_fund_name || '',
      default_location_id: this.props.groupState.group.default_location_id || '',
      loaded: false,
      canvasRef: React.createRef(),
      charityID: this.props.groupState.group.charity_id || '',
      currentSignature:
        (this.props.groupState.groupSignature && this.props.groupState.groupSignature.signature) ||
        '',
      locations: [],
      localization: this.props.groupState.group.localization || {
        country: '',
        state: '',
        defaultTimezone: '',
      },
      activeRow: '',
      groupType: this.props.groupState.group.group_type,
      message: this.props.groupState.group.confirmation_message || '',
      modules: {
        toolbar: [
          ['bold', 'italic'],
          ['link', 'clean'],
        ],
        clipboard: {
          matchVisual: false,
        },
      },
      formats: ['bold', 'italic', 'link'],
      logoSrc: '',
      logoImageUrl: '',
      showLogoCropModal: false,
      description: this.props.groupState.group.description || '',
      feeControl: {
        processingFees: this.props.groupState.group.fee_control?.covers_processing_fees || false,
        gigitFees: this.props.groupState.group.fee_control?.covers_gigit_fees || false,
        allowTips: this.props.groupState.group.fee_control?.show_tip || false,
      },
      useDonationDescriptions:
        this.props.groupState.group.use_donation_amount_descriptions || false,
      donationAmounts: this.setDonationAmounts(),
      receiptHeader: this.props.groupState.group.receipt_header_message || '',
      receiptFooter: this.props.groupState.group.receipt_footer_message || '',
      charityName: this.props.groupState.group.legal_charity_name || '',
      signatureName: this.props.groupState?.groupSignature?.name || '',
      signatureTitle: this.props.groupState?.groupSignature?.title || '',
      fiscalYearEndMonth: this.props.groupState.group.fiscal_year_end_month ?? 11,
      fiscalYearEndDay: this.props.groupState.group.fiscal_year_end_day ?? 31,
      contactPhone: this.props.groupState.group.contact_details?.phone || '',
      contactEmail: this.props.groupState.group.contact_details?.email || '',
      isProgressBarUpdating: false,
      allow_checkout_donations: this.props.groupState.group.allow_checkout_donations ?? true,
      donorLevelRegMin: this.props.groupState.group.donor_levels?.regular.min?.toString() || null,
      donorLevelRegMax: this.props.groupState.group.donor_levels?.regular.max?.toString() || null,
      donorLevelMidMin: this.props.groupState.group.donor_levels?.middle.min?.toString() || null,
      donorLevelMidMax: this.props.groupState.group.donor_levels?.middle.max?.toString() || null,
      donorLevelMajorMin: this.props.groupState.group.donor_levels?.major.min?.toString() || null,
      donorLevelMajorMax: this.props.groupState.group.donor_levels?.major.max?.toString() || null,
      minimumTaxReceiptAmount:
        String(this.props.groupState.group.minimum_tax_receipt_dollar_amount) || '',
      donationCheckoutMessage: this.props.groupState.group.donation_checkout_message ?? '',
      defaultHubId: this.props.defaultHubId,
      charityStatus: this.props.groupState.group.charity_verification_status || null,
      showCharityVerification: false,
      showVerifyEmail: false,
      showVerifyPhone: false,
      availableIntegrations: [],
      existingIntegrations: [],
      showSolutionModal: false,
      solutionFrameURL: '',
      receiptPrefix: this.props.groupState.group.tax_receipt_prefix || '',
      removeConnection: false,
      removeIntegration: null,
      fetchedAccounts: [],
      isStripeConnected: false,
      canToggleBannerMessage: true,
      isPurplePlus: false,
      external_application_url: this.props.groupState.group.external_application_url || '',
      external_application_url_error: false,
      showExternalVolunteerApplicationWarning: false,
      canToggleAutoReceipts: true,
    };

    this.updateDefaultLocation = this.updateDefaultLocation.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.onLogoSelect = this.onLogoSelect.bind(this);
    this.onLogoCrop = this.onLogoCrop.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.saveDescription = this.saveDescription.bind(this);
    this.updateCampaign = this.updateCampaign.bind(this);
    this.uploadSignatureImg = this.uploadSignatureImg.bind(this);
    this.updateProcessingFee = this.updateProcessingFee.bind(this);
    this.updateGigitFee = this.updateGigitFee.bind(this);
    this.hasPledgesConfigured = this.hasPledgesConfigured.bind(this);
    this.hasSubscriptionsConfigured = this.hasSubscriptionsConfigured.bind(this);
    this.handleDonationAmountInputChange = this.handleDonationAmountInputChange.bind(this);
    this.handleReceiptHeaderChange = this.handleReceiptHeaderChange.bind(this);
    this.handleReceiptFooterChange = this.handleReceiptFooterChange.bind(this);
    this.setFunds = this.setFunds.bind(this);
    this.setDonationAmounts = this.setDonationAmounts.bind(this);
    this.requestCharityVerification = this.requestCharityVerification.bind(this);
    this.invokeTray = this.invokeTray.bind(this);
    this.removeTray = this.removeTray.bind(this);
    this.getAvailableIntegrations = this.getAvailableIntegrations.bind(this);
    this.handleIframeEvents = this.handleIframeEvents.bind(this);
    this.verifySolutionInstance = this.verifySolutionInstance.bind(this);
    this.checkIsPurplePlus = this.checkIsPurplePlus.bind(this);
    this.clearDonorLevels = this.clearDonorLevels.bind(this);
    this.validateDonorLevels = this.validateDonorLevels.bind(this);

    window.addEventListener('message', this.handleIframeEvents);
  }

  componentDidMount() {
    this.setState({
      handle: this.props.groupState.group.handle,
      currentForm: this.props.groupState.group.form_id || '',
      loaded: true,
    });

    this.props.getGroupLocations(this.props.groupState.group.id);
    this.props.getGroupCampaigns(this.props.groupState.group.id);
    this.props.getGroupSignature(this.props.groupState.group.id);

    if (
      this.props.groupState.groupSignature &&
      this.props.groupState.groupSignature.signature &&
      this.state.canvasRef.current !== null
    ) {
      this.state.canvasRef.current.fromDataURL(this.props.groupState.groupSignature.signature);
    }

    if (this.props.groupState.group && this.props.groupState.group.campaign_id) {
      this.props.getGroupReceiptNumber(
        this.props.groupState.group.id,
        this.props.groupState.group.campaign_id,
      );
      this.props.getGroupReceiptNumberFormatted(
        this.props.groupState.group.id,
        this.props.groupState.group.campaign_id,
      );
    }

    this.setFunds();

    this.props.getGroupHubs(this.props.groupId);

    // this.getAvailableIntegrations();
    this.fetchAccounts();

    if (!this.props.groupState.group.confirmation_message) {
      this.props.updateGroup({ auto_confirmations: false }, this.props.groupState.group.id);
    }

    this.checkIsPurplePlus();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.groupState.group !== prevProps.groupState.group) {
      this.setState({
        handle: this.props.groupState.group.handle,
        currentForm: this.props.groupState.group.form_id || '',
        groupType: this.props.groupState.group.group_type,
        feeControl: {
          processingFees: this.props.groupState.group.fee_control?.covers_processing_fees || false,
          gigitFees: this.props.groupState.group.fee_control?.covers_gigit_fees || false,
          allowTips: this.props.groupState.group.fee_control?.show_tip || false,
        },
        donationAmounts: this.setDonationAmounts(),
        fiscalYearEndMonth: this.props.groupState.group.fiscal_year_end_month ?? 11,
        fiscalYearEndDay: this.props.groupState.group.fiscal_year_end_day ?? 31,
        allow_checkout_donations: this.props.groupState.group.allow_checkout_donations ?? true,
        defaultHubId: this.props.defaultHubId,
        charityStatus: this.props.groupState.group.charity_verification_status ?? null,
      });
    }

    if (
      this.props.groupState.group.use_donation_amount_descriptions !==
        prevProps.groupState.group.use_donation_amount_descriptions &&
      this.props.groupState.group.use_donation_amount_descriptions !== undefined
    ) {
      this.setState({
        useDonationDescriptions: this.props.groupState.group.use_donation_amount_descriptions,
      });
    }

    if (
      JSON.stringify(prevProps.groupState.group.donor_levels) !==
      JSON.stringify(this.props.groupState.group.donor_levels)
    ) {
      const toast = toastSuccess(
        localizeHelpers.translate('Donor levels updated.'),
        'Cause Settings',
      );
      this.props.createToast(toast);
    }

    if (prevProps.groupState.currentCampaign !== this.props.groupState.currentCampaign) {
      this.setFunds();
    }

    if (this.props.groupState.groupCampaigns !== prevProps.groupState.groupCampaigns) {
      let _campaigns = [{ label: 'None', value: '' }];

      for (let f in this.props.groupState.groupCampaigns) {
        _campaigns.push({
          label: this.props.groupState.groupCampaigns[f].title!,
          value: this.props.groupState.groupCampaigns[f].id!,
        });
      }

      this.setState({
        campaigns: _campaigns,
      });
    }

    if (this.props.groupState.groupForms !== prevProps.groupState.groupForms) {
      let _forms = [{ label: 'None', value: '' }];

      for (let f in this.props.groupState.groupForms) {
        _forms.push({
          label: this.props.groupState.groupForms[f].form_name!,
          value: this.props.groupState.groupForms[f].id!,
        });
      }

      this.setState({
        forms: _forms,
      });
    }

    if (this.props.groupState.groupLocations !== prevProps.groupState.groupLocations) {
      let _locations = [{ label: 'None', value: '' }];

      for (let f in this.props.groupState.groupLocations) {
        _locations.push({
          label: this.props.groupState.groupLocations[f].title!,
          value: this.props.groupState.groupLocations[f].id!,
        });
      }

      this.setState({
        locations: _locations,
      });
    }

    if (this.props.groupState.groupSignature !== prevProps.groupState.groupSignature) {
      this.setState({
        signatureName: this.props.groupState?.groupSignature?.name || '',
        signatureTitle: this.props.groupState?.groupSignature?.title || '',
      });
    }

    this.updateProgressBarSettings(this.props.groupState, prevProps.groupState);

    if (
      this.state.fetchedAccounts.length > 0 &&
      !this.state.isStripeConnected &&
      !this.props.groupState.group.auto_confirmations &&
      !prevProps.groupState.group.auto_confirmations
    ) {
      this.checkForStripeAccount();
    }

    if (
      this.props.groupState.group.confirmation_message !==
      prevProps.groupState.group.confirmation_message
    ) {
      this.setState({
        canToggleBannerMessage: true,
      });
    }
  }

  checkIsPurplePlus() {
    billingRequestActions
      .getPremiumFeatureSummary(Constants.object_type.group, this.props.groupState.group.id)
      .then((res) => {
        if (res) {
          const feature = res.features.find(
            (feature) =>
              feature.is_active &&
              feature.feature_code === Constants.billing.subscriptions.purple_plus.code,
          );
          if (feature) {
            this.setState({
              isPurplePlus: true,
            });
          }
        }
      })
      .catch((err) => {
        const errorObj = errorHelpers.getErrorObject(err);
        const toast = toastError(errorObj.messageTemplate, 'Group Subscription');
        this.props.createToast(toast);
      });
  }

  setDonationAmounts() {
    let _amounts: any[] = [
      { amount: 25, description: '' },
      { amount: 50, description: '' },
      { amount: 100, description: '' },
      { amount: 250, description: '' },
      { amount: 500, description: '' },
    ];

    if (
      this.props.groupState.group.donation_amounts &&
      this.props.groupState.group.donation_amounts.length > 0
    ) {
      _amounts = [];

      for (const donationAmount of this.props.groupState.group.donation_amounts) {
        if (typeof donationAmount === 'object') {
          _amounts.push({
            amount: donationAmount.amount,
            description: donationAmount.description || '',
          });
        } else {
          _amounts.push({ amount: donationAmount, description: '' });
        }
      }
    }

    return _amounts;
  }

  setFunds() {
    if (this.props.groupState.currentCampaign !== null) {
      let _funds: IOptions[] = [{ value: '', label: 'None' }];

      for (let f in this.props.groupState.currentCampaign.funds) {
        let _f = this.props.groupState.currentCampaign.funds[f];
        let _fund = { value: _f.id!, label: _f.name };

        _funds.push(_fund);
      }

      this.setState({
        funds: _funds,
      });
    }
  }

  updateCampaign() {
    this.props.updateGroup(
      { campaign_id: this.state.active_campaign !== '' ? this.state.active_campaign : null },
      this.props.groupState.group.id,
    );

    const campaign = this.props.groupState.groupCampaigns.find(
      (c) => c.id === this.state.active_campaign,
    );

    if (campaign) {
      // Set accepting donations flag on Group to false if current campaign is set to in-active or isn't monetized
      if (campaign.status.code !== 'active' && this.props.groupState.group.accepting_donations) {
        const toast = toastInfo(
          localizeHelpers.translate(
            'This campaign is not connected for payments. Donations will be disabled until an account is connected.',
          ),
          'Campaigns',
          [
            {
              text: 'Click here to manage your campaigns.',
              onClick: () => this.props.onChangeAdminTab('campaigns'),
            },
          ],
        );
        this.props.createToast(toast);

        this.props.updateGroup({ accepting_donations: false }, this.props.groupState.group.id);
      }
    }
  }

  updateSignature(value?: string) {
    if (this.state.canvasRef.current.isEmpty() && !value) {
      this.props.setGroupSignature(this.props.groupState.group.id, {
        value: 'empty',
        name: this.state.signatureName,
        title: this.state.signatureTitle,
      });
    } else if (value) {
      this.props.setGroupSignature(this.props.groupState.group.id, {
        value: value,
        name: this.state.signatureName,
        title: this.state.signatureTitle,
      });
    } else {
      this.props.setGroupSignature(this.props.groupState.group.id, {
        value: this.state.canvasRef.current.toDataURL(),
        name: this.state.signatureName,
        title: this.state.signatureTitle,
      });
    }
  }

  clear() {
    this.state.canvasRef.current.clear();
  }

  updateDefaultLocation() {
    let _location: any = this.state.default_location_id;

    if (_location === '') {
      _location = null;
    }

    this.props.updateGroup({ default_location_id: _location }, this.props.groupState.group.id);
  }

  toggleActiveRow(row: string) {
    if (row === this.state.activeRow) {
      this.setState({
        activeRow: '',
      });
    } else {
      this.setState(
        {
          activeRow: row,
        },
        () => {
          if (this.state.activeRow === 'signature') {
            if (
              this.props.groupState.groupSignature &&
              this.props.groupState.groupSignature.signature &&
              this.state.canvasRef.current !== null
            ) {
              this.state.canvasRef.current.fromDataURL(
                this.props.groupState.groupSignature.signature,
                { width: 600, height: 300 },
              );
            }
          }
        },
      );
    }
  }

  updateGroupType() {
    this.props.updateGroup({ group_type: this.state.groupType }, this.props.groupState.group.id);
  }

  handleMessageChange(content: any, delta: any, source: any, editor: any) {
    this.setState({ message: content });
  }

  handleDescriptionChange(content: any, delta: any, source: any, editor: any) {
    this.setState({ description: content });
  }

  handleReceiptHeaderChange(content: any, delta: any, source: any, editor: any) {
    this.setState({ receiptHeader: content });
  }

  handleReceiptFooterChange(content: any, delta: any, source: any, editor: any) {
    this.setState({ receiptFooter: content });
  }

  saveHeader() {
    this.props.updateGroup(
      { receipt_header_message: this.state.receiptHeader },
      this.props.groupState.group.id,
    );

    const toast = toastSuccess(
      localizeHelpers.translate('Receipt header updated successfully.'),
      'Cause Settings',
    );
    this.props.createToast(toast);
  }

  saveFooter() {
    this.props.updateGroup(
      { receipt_footer_message: this.state.receiptFooter },
      this.props.groupState.group.id,
    );

    const toast = toastSuccess(
      localizeHelpers.translate('Receipt footer updated successfully.'),
      'Cause Settings',
    );
    this.props.createToast(toast);
  }

  saveMessage() {
    if (extractLettersFromQuillMarkup(this.state.message).trim().length === 0) {
      this.setState({
        message: '',
      });
      this.props.updateGroup(
        { confirmation_message: this.state.message },
        this.props.groupState.group.id,
      );

      const toast = toastSuccess(
        localizeHelpers.translate('Thank you message updated successfully.'),
        'Cause Settings',
      );
      this.props.createToast(toast);
    } else {
      this.props.updateGroup(
        { confirmation_message: this.state.message },
        this.props.groupState.group.id,
      );

      const toast = toastSuccess(
        localizeHelpers.translate('Thank you message updated successfully.'),
        'Cause Settings',
      );
      this.props.createToast(toast);
    }
  }

  saveDonationCheckoutMessage() {
    if (extractLettersFromQuillMarkup(this.state.donationCheckoutMessage).trim().length === 0) {
      this.setState({
        donationCheckoutMessage: '',
      });
      this.props.updateGroup(
        { donation_checkout_message: this.state.donationCheckoutMessage },
        this.props.groupState.group.id,
      );

      const toast = toastSuccess(
        localizeHelpers.translate('Custom Donation Checkout Donation saved successfully.'),
        'Cause Settings',
      );
      this.props.createToast(toast);
    } else {
      this.props.updateGroup(
        { donation_checkout_message: this.state.donationCheckoutMessage },
        this.props.groupState.group.id,
      );

      const toast = toastSuccess(
        localizeHelpers.translate('Custom Donation Checkout Donation saved successfully.'),
        'Cause Settings',
      );
      this.props.createToast(toast);
    }
  }

  saveDescription() {
    this.props.updateGroup({ description: this.state.description }, this.props.groupState.group.id);

    const toast = toastSuccess(
      localizeHelpers.translate('Cause description updated successfully.'),
      'Cause Settings',
    );
    this.props.createToast(toast);
  }

  saveNewHandle = () => {
    this.props.changeGroupHandle(this.props.groupState.group.id, this.state.handle, () => {
      const toast = toastSuccess(
        localizeHelpers.translate('Cause handle was successfully changed'),
        'Cause handle changed',
      );
      this.props.createToast(toast);

      // Redirect to new Url and refresh the page (Reloads all state).
      this.props.history.push(`/group/${this.state.handle}/admin`);
      window.location.reload();
    });
  };

  saveFiscalYearConfiguration = () => {
    this.props.updateGroup(
      {
        fiscal_year_end_month: this.state.fiscalYearEndMonth,
        fiscal_year_end_day: this.state.fiscalYearEndDay,
      },
      this.props.groupState.group.id,
    );

    const toast = toastSuccess(
      localizeHelpers.translate('Cause Fiscal Year Settings updated successfully.'),
      'Cause Settings',
    );
    this.props.createToast(toast);
  };

  saveGroupContactDetails = () => {
    this.props.updateGroup(
      {
        contact_details: {
          phone: this.state.contactPhone,
          email: this.state.contactEmail,
        },
      },
      this.props.groupState.group.id,
    );

    const toast = toastSuccess(
      localizeHelpers.translate('Cause Contact Details updated successfully.'),
      'Cause Settings',
    );
    this.props.createToast(toast);
  };

  onHandleChanged = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        handle: e.target.value.replace(/\s+/g, ''),
      },
      this.checkHandle,
    );
  };

  checkHandle = () => {
    this.setState({
      validateHandleStatus: ValidateHandleStatus.VALIDATING,
      changeHandleError: '',
    });

    axios
      .get(swapRouteParams(routes.GROUP_UNIQUE_HANDLE, { handle: this.state.handle }))
      .then((response) => {
        if (response.data && response.data.value === false) {
          const errorObj = errorHelpers.getErrorObjectFromSimpleValue(response.data);
          this.setState({
            validateHandleStatus: ValidateHandleStatus.ERROR,
            changeHandleError: errorObj.translatedMessage,
          });
        } else {
          this.setState({
            validateHandleStatus: ValidateHandleStatus.VALIDATED,
            changeHandleError: null,
          });
        }
      })
      .catch((err) => {
        this.setState({
          validateHandleStatus: ValidateHandleStatus.ERROR,
          changeHandleError: 'Unknown error',
        });
      });
  };

  onLogoSelect(file: any) {
    this.setState({
      logoSrc: file.src,
      showLogoCropModal: true,
    });
  }

  onLogoCrop(newImgData: any) {
    const fileURL = newImgData.fileUrl;

    this.props.updateGroupReceiptImage(this.props.groupId, fileURL);

    this.setState({
      showLogoCropModal: false,
    });
  }

  uploadSignatureImg(file: any) {
    this.state.canvasRef.current.fromDataURL(file.src, { width: 600, height: 300 });
    this.updateSignature(file.src);
  }

  checkExternalApplicationUrl(v: string) {
    if (v.length > 0 && !isURL(v)) {
      this.setState({ external_application_url_error: true });
    } else {
      this.setState({ external_application_url_error: false });
    }
  }

  async toggleAcceptingExternalApplication() {
    await this.props.updateGroup(
      {
        accepting_external_application: !this.props.groupState.group.accepting_external_application,
      },
      this.props.groupState.group.id,
    );
  }

  async saveExternalApplicationURL() {
    try {
      await this.props.updateGroup(
        { external_application_url: this.state.external_application_url },
        this.props.groupState.group.id,
      );
      const toast = toastSuccess(
        localizeHelpers.translate('External Application URL updated successfully.'),
        'Update Cause',
      );
      this.props.createToast(toast);
    } catch (err) {
      const errorObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errorObj.translatedMessage, 'External Application URL');
      this.props.createToast(toast);
    }
  }

  async toggleAcceptingVolunteers() {
    await this.props.updateGroup(
      { accepting_volunteers: !this.props.groupState.group.accepting_volunteers },
      this.props.groupState.group.id,
    );
  }

  async hasAutoTaxConfigured() {
    if (this.state.isStripeConnected) {
      if (
        !this.props.groupState.group.default_location_id ||
        !this.props.groupState.group.charity_id ||
        !this.props.groupState.groupSignature ||
        !this.props.groupState.group.receipt_logo_image_url
      ) {
        if (this.props.groupState.group.auto_tax_receipts) {
          await this.props.updateGroup(
            { auto_tax_receipts: !this.props.groupState.group.auto_tax_receipts },
            this.props.groupState.group.id,
          );
        } else {
          this.props.updateGroup(
            { auto_tax_receipts: !this.props.groupState.group.auto_tax_receipts },
            this.props.groupState.group.id,
          );
        }
      } else {
        await this.props.updateGroup(
          { auto_tax_receipts: !this.props.groupState.group.auto_tax_receipts },
          this.props.groupState.group.id,
        );
      }
    }
  }

  async fetchAccounts() {
    try {
      const fetchedAccounts = await accountRequestActions.getAccountsWithDetails(
        Constants.object_type.group,
        this.props.groupState.group.id,
      );
      this.setState({
        fetchedAccounts,
      });
    } catch (err) {
      const errorObj = errorHelpers.getErrorObject(err);
      let toast = toastError(errorObj.translatedMessage, 'Fetch Accounts');
      this.props.createToast(toast);
    }
  }

  checkForStripeAccount() {
    const accountNumber = this.props.groupState.group.account?.account_number;

    if (accountNumber) {
      this.state.fetchedAccounts.map((account) => {
        if (accountNumber === account.account_number) {
          if (account.account_sub_type === 'standard') {
            this.setState({
              isStripeConnected: true,
            });
          }
        }
      });
    }
  }

  canToggle() {
    if (!this.props.groupState.group.auto_confirmations) {
      this.checkForStripeAccount();
      if (
        this.props.groupState.group.confirmation_message === '<p><br></p>' ||
        !this.props.groupState.group.confirmation_message
      ) {
        const toast = toastError(
          localizeHelpers.translate('Set a thank you message to enable this feature.'),
          'Auto Thanks',
        );
        this.props.createToast(toast);
      } else if (!this.state.isStripeConnected) {
        this.props.updateGroup(
          { auto_confirmations: this.props.groupState.group.auto_confirmations },
          this.props.groupState.group.id,
        );
      } else {
        this.props.updateGroup(
          { auto_confirmations: !this.props.groupState.group.auto_confirmations },
          this.props.groupState.group.id,
        );
      }
    } else {
      this.props.updateGroup(
        { auto_confirmations: !this.props.groupState.group.auto_confirmations },
        this.props.groupState.group.id,
      );
    }
  }

  hasDonationsConfigured() {
    const group = this.props.groupState.group;

    // If user tries to turn on donations without account show error.
    if (!group.account && !group.accepting_donations) {
      const toast = toastError(
        localizeHelpers.translate(
          'Donations can not be enabled until the active account is setup to accept payments.',
        ),
        'Donations',
      );
      this.props.createToast(toast);
    } else {
      this.props.updateGroup({ accepting_donations: !group.accepting_donations }, group.id);
    }
  }

  toggleAllowCheckoutDonations() {
    if (!this.state.allow_checkout_donations && !this.props.groupState.group.accepting_donations) {
      const toast = toastError(
        localizeHelpers.translate('This cause is not configured to allow donations.'),
        'Donations',
      );
      this.props.createToast(toast);
    } else {
      this.props.updateGroup(
        { allow_checkout_donations: !this.state.allow_checkout_donations },
        this.props.groupState.group.id,
      );
    }
  }

  hasPledgesConfigured() {
    this.props.updateGroup(
      { allow_pledges: !this.props.groupState.group.allow_pledges },
      this.props.groupState.group.id,
    );
  }

  hasSubscriptionsConfigured() {
    if (!this.props.groupState.currentCampaign) {
      if (this.props.groupState.group.accepting_donations) {
        this.props.updateGroup(
          { allow_subscriptions: !this.props.groupState.group.allow_subscriptions },
          this.props.groupState.group.id,
        );
      } else {
        const toast = toastError(
          localizeHelpers.translate(
            'Create an active campaign, connect an account, and set it as your default to enable subscriptions. Refer to the guided setup for more info.',
          ),
          'Donations',
        );
        this.props.createToast(toast);
      }
    } else if (
      this.props.groupState.currentCampaign &&
      this.props.groupState.currentCampaign.status.code !== 'active'
    ) {
      if (this.props.groupState.group.accepting_donations) {
        this.props.updateGroup(
          { allow_subscriptions: !this.props.groupState.group.allow_subscriptions },
          this.props.groupState.group.id,
        );
      } else {
        const toast = toastError(
          localizeHelpers.translate(
            'Create an active campaign, connect an account, and set it as your default to enable subscriptions. Refer to the guided setup for more info.',
          ),
          'Donations',
        );
        this.props.createToast(toast);
      }
    } else {
      this.props.updateGroup(
        { allow_subscriptions: !this.props.groupState.group.allow_subscriptions },
        this.props.groupState.group.id,
      );
    }
  }

  updateProcessingFee(val: boolean) {
    this.props.updateGroup(
      {
        fee_control: {
          covers_gigit_fees: this.state.feeControl.gigitFees,
          covers_processing_fees: val,
        },
      },
      this.props.groupState.group.id,
    );
  }

  updateGigitFee(val: boolean) {
    this.props.updateGroup(
      {
        fee_control: {
          covers_gigit_fees: val,
          covers_processing_fees: this.state.feeControl.processingFees,
        },
      },
      this.props.groupState.group.id,
    );
  }

  handleDonationAmountInputChange(event: any, self: any, prevent?: boolean, callback?: any) {
    if (prevent === true) {
      event.preventDefault();
    }

    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;
    const index = target.name.split('-')[1];

    let _donationAmounts: any[] | undefined = [];

    if (this.state.donationAmounts) {
      _donationAmounts = [...this.state.donationAmounts];
    }

    if (target.type === 'number') {
      _donationAmounts[index] = {
        amount: parseInt(value),
        description: _donationAmounts[index].description || '',
      };
    } else {
      _donationAmounts[index] = { amount: _donationAmounts[index].amount, description: value };
    }

    self.setState(
      {
        donationAmounts: _donationAmounts,
      },
      () => {
        if (callback !== undefined) {
          callback();
        }
      },
    );
  }

  updateLocalization = (update: Partial<ILocalization>) => {
    this.setState({
      localization: { ...this.state.localization, ...update },
    });
  };

  isLocalizationChanged() {
    const oldLocalization = this.props.groupState.group.localization ?? {
      country: '',
      state: '',
      defaultTimezone: '',
    };
    const newLocalization = this.state.localization;

    return (
      oldLocalization.country !== newLocalization?.country ||
      oldLocalization.state !== newLocalization?.state ||
      oldLocalization.defaultTimezone !== newLocalization?.defaultTimezone ||
      oldLocalization.country !== newLocalization?.currency
    );
  }

  saveLocalization = async () => {
    await this.props.updateGroup(
      {
        localization: this.state.localization,
      },
      this.props.groupState.group.id,
    );

    const toast = toastSuccess(
      localizeHelpers.translate('Cause Localization updated successfully.'),
      'Cause Settings',
    );
    this.props.createToast(toast);
  };

  saveDefaultHub() {
    // this.props.updateGroup({

    // }, this.props.groupState.group.id);

    this.props.setGroupPrimaryHub(this.props.groupState.group.id, this.state.defaultHubId, {
      // callback: () => this.props.getGr
    });
  }

  saveMinimumTaxReceiptAmount() {
    this.props.updateGroup(
      {
        minimum_tax_receipt_dollar_amount: parseInt(this.state.minimumTaxReceiptAmount),
      },
      this.props.groupState.group.id,
    );
  }

  canOBOFundraise() {
    if (this.props.groupState.group.account) {
      this.props.updateGroup(
        { allow_obo_fundraisers: !this.props.groupState.group.allow_obo_fundraisers },
        this.props.groupState.group.id,
      );
    } else {
      const toast = toastInfo(
        localizeHelpers.translate(
          `You need to connect and monetize a campaign to allow fundraisers on your behalf`,
        ),
        'Fundraise On Behalf Of',
      );
      this.props.createToast(toast);
    }
  }

  updateProgressBarSettings = (currentGroupState: IGroupState, prevGroupState: IGroupState) => {
    const { updateGroupRaisedAmount } = this.props;
    const campaignId = currentGroupState.groupCampaigns.find(
      (campaign) => campaign.status.code === 'active',
    )?.id;

    if (
      (currentGroupState.group.show_donations_progress !==
        prevGroupState.group.show_donations_progress ||
        currentGroupState.group.show_merchandise_progress !==
          prevGroupState.group.show_merchandise_progress ||
        currentGroupState.group.show_auction_progress !==
          prevGroupState.group.show_auction_progress ||
        currentGroupState.group.show_campaign_progress !==
          prevGroupState.group.show_campaign_progress ||
        currentGroupState.group.show_sponsor_progress !==
          prevGroupState.group.show_sponsor_progress) &&
      campaignId
    ) {
      updateGroupRaisedAmount(currentGroupState.group.id, campaignId);
    }
  };

  updateGroupDonorLevels = async () => {
    const { updateGroup } = this.props;
    const {
      donorLevelRegMin,
      donorLevelRegMax,
      donorLevelMidMin,
      donorLevelMidMax,
      donorLevelMajorMin,
      donorLevelMajorMax,
    } = this.state;

    if (this.validateDonorLevels()) {
      try {
        let response = await updateGroup(
          {
            donor_levels: {
              regular: {
                min: donorLevelRegMin ? parseInt(donorLevelRegMin) : null,
                max: donorLevelRegMax ? parseInt(donorLevelRegMax) : null,
              },
              middle: {
                min: donorLevelMidMin ? parseInt(donorLevelMidMin) : null,
                max: donorLevelMidMax ? parseInt(donorLevelMidMax) : null,
              },
              major: {
                min: donorLevelMajorMin ? parseInt(donorLevelMajorMin) : null,
                max: donorLevelMajorMax ? parseInt(donorLevelMajorMax) : null,
              },
            },
          },
          this.props.groupState.group.id,
        );
      } catch (err) {
        const errObj = errorHelpers.getErrorObject(err);
        const toast = toastError(errObj.translatedMessage, 'Cause Settings');
        this.props.createToast(toast);
      }
    }
  };

  validateDonorLevels() {
    const {
      donorLevelRegMin,
      donorLevelRegMax,
      donorLevelMidMin,
      donorLevelMidMax,
      donorLevelMajorMin,
      donorLevelMajorMax,
    } = this.state;

    const onError = (message: string) => {
      const toast = toastError(localizeHelpers.translate(message), 'Cause Settings');
      this.props.createToast(toast);
      return false;
    };

    if (
      !donorLevelRegMin ||
      !donorLevelRegMax ||
      !donorLevelMidMin ||
      !donorLevelMidMax ||
      !donorLevelMajorMin ||
      !donorLevelMajorMax
    ) {
      if (
        donorLevelRegMin == null &&
        donorLevelRegMax == null &&
        donorLevelMidMin == null &&
        donorLevelMidMax == null &&
        donorLevelMajorMin == null &&
        donorLevelMajorMax == null
      ) {
        return true;
      }
      onError('All donor levels must be provided.');
      return false;
    }

    if (donorLevelRegMin > donorLevelRegMax) {
      onError('Donor level Regular/Maximum must be higher than Regular/Minimum.');
      return false;
    }

    if (donorLevelMidMin > donorLevelMidMax) {
      onError('Donor level Middle/Maximum must be higher than Middle/Minimum.');
      return false;
    }

    if (donorLevelMajorMin > donorLevelMajorMax) {
      onError('Donor level Major/Maximum must be higher than Major/Minimum.');
      return false;
    }

    if (donorLevelRegMax >= donorLevelMidMin) {
      onError('Donor level Middle/Minimum must be higer than Regular/Maximum.');
      return false;
    }

    if (donorLevelMidMax >= donorLevelMajorMin) {
      onError('Donor level Major/Minimum must be higer than Middle/Maximum.');
      return false;
    }

    return true;
  }

  clearDonorLevels() {
    this.setState({
      donorLevelRegMin: null,
      donorLevelRegMax: null,
      donorLevelMidMin: null,
      donorLevelMidMax: null,
      donorLevelMajorMin: null,
      donorLevelMajorMax: null,
    });
  }

  saveDonationAmounts(e: any) {
    e.preventDefault();
    this.props.updateGroup(
      { donation_amounts: this.state.donationAmounts },
      this.props.groupState.group.id,
    );

    const toast = toastSuccess('Preset donation amounts updated.', 'Cause Settings');
    this.props.createToast(toast);
  }

  getDefaultHubOptions() {
    const options = [
      ...this.props.groupState.groupHubs.map((hub) => ({ label: hub.title!, value: hub.id! })),
    ];
    return options;
  }

  requestCharityVerification() {
    axios
      .put(
        swapRouteParams(routes.REQUEST_CHARITY_VERIFICATION, {
          groupId: this.props.groupState.group.id,
        }),
      )
      .then((response) => {
        this.setState({
          showCharityVerification: true,
          showVerifyPhone: false,
          showVerifyEmail: false,
          charityStatus: response.data.charity_verification_status,
        });
      })
      .catch((err) => {
        const errObj = errorHelpers.getErrorObject(err);
        const toast = toastError(errObj.translatedMessage, 'Request Charity Verification');
        this.props.createToast(toast);
      });
  }

  getAvailableIntegrations() {
    axios
      .get(routes.GET_INTEGRATIONS)
      .then((response) => {
        const integrations = response.data;
        this.getGroupIntegrations(integrations);
      })
      .catch((error) => {
        const errObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errObj.translatedMessage, 'Cause Solution');
        this.props.createToast(toast);
      });
  }

  getGroupIntegrations(allIntegrations: ISolution[]) {
    axios
      .get(
        swapRouteParams(routes.GET_GROUP_INTEGRATIONS, { groupId: this.props.groupState.group.id }),
      )
      .then((response) => {
        let connected = response.data;
        let available: ISolution[] = [];
        if (connected.length > 0) {
          allIntegrations.forEach((integration) => {
            if (!connected.find((e: any) => e.solution.id === integration.id)) {
              available.push(integration);
            }
          });
        } else {
          available = allIntegrations;
        }

        this.setState({
          availableIntegrations: available,
          existingIntegrations: connected,
        });
      })
      .catch((error) => {
        const errObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errObj.translatedMessage, 'Cause Solution');
        this.props.createToast(toast);
      });
  }

  handleIframeEvents(e: MessageEvent) {
    // Here we should handle all event types
    if (e.data.type === 'tray.configPopup.error') {
      const toast = toastError(localizeHelpers.translate(e.data.err), 'Cause Solution');
      this.props.createToast(toast);
      this.setState({ showSolutionModal: false, solutionFrameURL: '' });
    }
    if (e.data.type === 'tray.configPopup.cancel') {
      this.setState({ showSolutionModal: false, solutionFrameURL: '' });
    }
    if (e.data.type === 'tray.configPopup.ready') {
    }
    if (e.data.type === 'tray.configPopup.finish') {
      this.setState({ showSolutionModal: false, solutionFrameURL: '' });
      this.verifySolutionInstance(e.data.data);
      // this.getAvailableIntegrations();
    }
  }

  verifySolutionInstance(solutionData: IMailChimpIntegrationResponse) {
    const route = swapRouteParams(routes.GROUP_SOLUTION_HOOK, {
      id: this.props.groupState.group.id,
      solutionId: solutionData.solutionInstanceId,
    });
    axios
      .put(route)
      .then((response) => {
        // There should be no respnse from this.
      })
      .catch((error) => {
        let errObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errObj.translatedMessage, 'Cause Solution');
        this.props.createToast(toast);
      });
  }

  removeTray() {
    let localSolution = this.state.removeIntegration as ISolutionInstance;
    const route = swapRouteParams(routes.REMOVE_GROUP_SOLUTION, {
      groupId: this.props.groupState.group.id,
      solutionId: localSolution.id,
    });

    axios
      .delete(route)
      .then((response) => {
        if (!response.data) {
          const toast = toastError(
            localizeHelpers.translate(
              'Solution instance could not be deleted, please try again later.',
            ),
            'Cause Solution Error',
          );
          this.props.createToast(toast);
        } else {
          // this.getAvailableIntegrations();
          const toast = toastSuccess(
            localizeHelpers.translate('Solution instance removed successfully.'),
            'Cause Solution',
          );
          this.props.createToast(toast);
        }
      })
      .catch((error) => {
        let errObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errObj.translatedMessage, 'Cause Solution Error');
        this.props.createToast(toast);
      });
  }

  invokeTray(solution: ISolution | ISolutionInstance, isInstance: boolean = false) {
    if (isInstance) {
      let localSolution = solution as ISolutionInstance;
      const route = swapRouteParams(routes.EDIT_GROUP_SOLUTION, {
        groupId: this.props.groupState.group.id,
        solutionId: localSolution.id,
      });
      axios
        .get(route)
        .then((response) => {
          let url = response.data;
          this.setState({ solutionFrameURL: url, showSolutionModal: true });
        })
        .catch((error) => {
          let errObj = errorHelpers.getErrorObject(error);
          const toast = toastError(errObj.translatedMessage, 'Cause Solution');
          this.props.createToast(toast);
        });
    } else {
      let localSolution = solution as ISolution;

      const title = `${this.props.groupState.group.title} - ${localSolution.title}`;
      const route = swapRouteParams(routes.REGISTER_GROUP_SOLUTION, {
        groupId: this.props.groupState.group.id,
        solutionId: localSolution.id,
      });
      const payload = {
        title,
      };

      axios
        .post(route, payload)
        .then((response) => {
          let url = response.data;
          this.setState({ solutionFrameURL: url, showSolutionModal: true });
        })
        .catch((error) => {
          const errObj = errorHelpers.getErrorObject(error);
          const toast = toastError(errObj.translatedMessage, 'Cause Solution');
          this.props.createToast(toast);
        });
    }
  }

  async updateReportingCurrency(currency: string) {
    const onError = (errorMessage: string) => {
      const toast = toastError(errorMessage, 'Update Reporting Currency');
      this.props.createToast(toast);
    };
    try {
      const response = await groupRequestActions.groupHasTransactions(
        this.props.groupState?.group?.id,
      );
      if (!response.has_transaction) {
        this.updateLocalization({ currency: currency });
      } else {
        const errorMessage = localizeHelpers.translate(
          'This cause already has transactions. You may only update the reporting currency if your cause has yet to receive transactions.',
        );
        onError(errorMessage);
      }
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      onError(errorObject.translatedMessage);
    }
  }

  handlePreventClick() {
    this.setState({ showExternalVolunteerApplicationWarning: true });
  }

  isTaxReceiptSupported() {
    return Constants.supported_tax_receipt_countries.includes(
      this.state.localization?.country || '',
    );
  }

  render() {
    const { updateGroup, groupState } = this.props;

    let _group_type_options = [
      { label: 'Non-Profit', value: 'non-profit' },
      { label: 'Business', value: 'business' },
      { label: 'High School', value: 'high school' },
      { label: 'University/College', value: 'university/college' },
      { label: 'Service Club', value: 'serviceclub' },
    ];

    const minimumTaxReceiptMessage = this.state.minimumTaxReceiptAmount
      ? localizeHelpers.translate(
          'Tax Receipts will be automatically issued for donations equal to or greater than {{minimum_tax_receipt_amount}}',
          {
            minimum_tax_receipt_amount: formatCurrency(
              parseInt(this.state.minimumTaxReceiptAmount),
              this.props.groupState.group.account?.currency ?? defaultCurrency,
              this.props.locale,
            ),
          },
        )
      : 'Tax Receipts will be automatically issued for all donations';

    return (
      <div className="SettingsManagement section-wrap settings">
        <div className="section-title">Settings</div>
        <div className="section-inner">
          <div className="settings-title">Cause Details</div>
          <div
            className="settings-item hover-item"
            onClick={() => {
              this.toggleActiveRow('description');
            }}
          >
            <div className="details">
              <p>Cause Description</p>
              <span className="detail">
                This short description about your cause will display on your Volunteer Applications,
                Donations and Fundraise On Our Behalf pages.
              </span>
            </div>
            {this.state.activeRow === 'description' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion message"
              >
                <QuillTextEditor
                  value={this.state.description}
                  readOnly={false}
                  preserveWhitespace={true}
                  theme="snow"
                  modules={this.state.modules}
                  formats={this.state.formats}
                  onChange={this.handleDescriptionChange}
                />
                <div className="message-actions">
                  <Button
                    text={'Save Description'}
                    onClick={() => {
                      this.saveDescription();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            className="settings-item hover-item no-select"
            onClick={() => {
              this.toggleActiveRow('webAddress');
            }}
          >
            <div className="details">
              <p>Web Address</p>
              <span className="detail">
                Personalize your Kambeo web address to easily direct people to your page.
              </span>
            </div>
            {this.state.activeRow === 'webAddress' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion message"
              >
                <div className="web-address-line">
                  <p
                    className="web-address"
                    notranslate="yes"
                  >{`${Config.web.REACT_APP_BASE_URL}/group/`}</p>
                  <TextField
                    name="webAddress"
                    type="text"
                    label=""
                    value={this.state.handle}
                    onChange={this.onHandleChanged}
                    error={
                      this.state.changeHandleError
                        ? localizeHelpers.translate(this.state.changeHandleError)
                        : undefined
                    }
                  />
                </div>

                {this.state.validateHandleStatus === ValidateHandleStatus.VALIDATED && (
                  <div className="success">This web address is available.</div>
                )}

                <div className="web-address-actions">
                  <Button
                    isDisabled={
                      this.state.handle === this.props.groupState.group.handle ||
                      this.state.validateHandleStatus !== ValidateHandleStatus.VALIDATED
                    }
                    text={'Save'}
                    onClick={() => {
                      this.saveNewHandle();
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="settings-title">Hubs</div>

          <div
            className="settings-item hover-item no-select"
            onClick={() => {
              this.toggleActiveRow('defaultHub');
            }}
          >
            <div className="details">
              <p>Default Hub</p>
              <span className="detail">Select the default hub for your cause.</span>
              {/* {this.state.activeRow === "volunteer" && <span className="create" onClick={(e) => { this.props.createAction('forms'); }}>{t("Don't have a form? Create one.")}</span>}</span> */}
            </div>
            {this.state.activeRow === 'defaultHub' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion localization"
              >
                <Dropdown
                  label={'Hub'}
                  onChange={(e) => handleInputChange(e, this)}
                  value={this.state.defaultHubId}
                  shouldSort={true}
                  name="defaultHubId"
                  options={this.getDefaultHubOptions()}
                />

                <Button
                  isDisabled={this.state.defaultHubId == this.props.defaultHubId}
                  text="Save"
                  onClick={() => {
                    this.saveDefaultHub();
                  }}
                />
              </div>
            )}
          </div>

          <div className="settings-title">Localization</div>

          <div
            className="settings-item hover-item no-select"
            onClick={() => {
              this.toggleActiveRow('localization');
            }}
          >
            <div className="details">
              <p>Localization</p>
              <span className="detail">Manage your causes localization settings.</span>
              {/* {this.state.activeRow === "volunteer" && <span className="create" onClick={(e) => { this.props.createAction('forms'); }}>{t("Don't have a form? Create one.")}</span>}</span> */}
            </div>
            {this.state.activeRow === 'localization' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion localization"
              >
                <Dropdown
                  label="Country"
                  onChange={(e) => this.updateLocalization({ country: e.target.value })}
                  value={this.state.localization?.country}
                  shouldSort={true}
                  name="country"
                  options={localeConstants.supportedCountryOptions}
                />

                <Dropdown
                  label={
                    this.state.localization?.country === 'CA'
                      ? 'Province*'
                      : this.state.localization?.country === 'GB'
                        ? 'County*'
                        : 'State*'
                  }
                  onChange={(e) => this.updateLocalization({ state: e.target.value })}
                  value={this.state.localization?.state}
                  shouldSort={true}
                  name="state"
                  options={localeConstants.provinceOptions.filter(
                    (province) => province.countryCode === this.state.localization?.country,
                  )}
                />

                <Dropdown
                  label="Default Timezone"
                  onChange={(e) => this.updateLocalization({ defaultTimezone: e.target.value })}
                  value={this.state.localization?.defaultTimezone}
                  shouldSort={true}
                  name="defaultTimezone"
                  options={localeConstants.timezoneOptions}
                />

                <Dropdown
                  label="Currency"
                  onChange={async (e) => await this.updateReportingCurrency(e.target.value)}
                  value={this.state.localization?.currency}
                  shouldSort={true}
                  name="currency"
                  options={localeConstants.supportedReportingCurrencyOptions}
                />

                <Button
                  isDisabled={!this.isLocalizationChanged()}
                  text="Save"
                  onClick={async () => {
                    await this.saveLocalization();
                  }}
                />
              </div>
            )}
          </div>

          <div className="settings-title">Volunteer Application</div>
          <div className="settings-item external-applications">
            <div className="main-item">
              <div className="details">
                <p>External Applications</p>
                <span className="detail">Are you accepting external applications?</span>
              </div>
              <div className="settings-control">
                <i
                  onClick={async () => await this.toggleAcceptingExternalApplication()}
                  className={
                    this.props.groupState.group.accepting_external_application
                      ? 'fad fa-toggle-on'
                      : 'fad fa-toggle-off'
                  }
                />
              </div>
            </div>
            {this.props.groupState.group.accepting_external_application && (
              <div
                onClick={(e) => e.stopPropagation()}
                className="expansion"
              >
                <TextField
                  label="External Applications URL"
                  onChange={(e) => {
                    this.checkExternalApplicationUrl(e.target.value || '');
                    handleInputChange(e, this);
                  }}
                  value={this.state.external_application_url || ''}
                  name="external_application_url"
                  type="text"
                  error={this.state.external_application_url_error ? 'Incorrect value' : undefined}
                />
                <div className="actions">
                  <Button
                    text="Save URL"
                    onClick={async () => await this.saveExternalApplicationURL()}
                    isDisabled={
                      !this.props.groupState.group.accepting_external_application ||
                      this.state.external_application_url_error
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="settings-item">
            <div className="details">
              <p>Accepting Volunteer Applications</p>
              <span className="detail">Are you accepting volunteer applications at this time?</span>
            </div>
            <div className="settings-control">
              <i
                onClick={async () => {
                  await this.toggleAcceptingVolunteers();
                }}
                className={
                  this.props.groupState.group.accepting_volunteers
                    ? 'fad fa-toggle-on'
                    : 'fad fa-toggle-off'
                }
              />
            </div>
          </div>
          <div className="settings-title">Fundraisers</div>
          <div className="settings-item">
            <div className="details">
              <p>Fundraise For Us</p>
              <span className="detail">
                Allow the ability for others to fundraise on our behalf.
              </span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => {
                  this.canOBOFundraise();
                }}
                className={
                  this.props.groupState.group.allow_obo_fundraisers
                    ? 'fad fa-toggle-on'
                    : 'fad fa-toggle-off'
                }
              />
            </div>
          </div>
          <div className="settings-title">Campaign</div>
          <div
            className="settings-item hover-item no-select"
            onClick={() => {
              this.toggleActiveRow('campaign');
            }}
          >
            <div className="details">
              <p>Active Campaign</p>
              <span className="detail">
                You must have an active campaign in order to accept any types of payment.
                {this.state.activeRow === 'campaign' && (
                  <span
                    className="create"
                    onClick={(e) => {
                      this.props.createAction('campaigns');
                    }}
                  >
                    Don't have a campaign? Create one.
                  </span>
                )}
              </span>
            </div>
            {this.state.activeRow === 'campaign' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion"
              >
                <Dropdown
                  label="Current Active Campaign"
                  shouldSort={true}
                  onChange={(e) => {
                    handleInputChange(e, this, false, this.updateCampaign);
                  }}
                  name="active_campaign"
                  options={this.state.campaigns}
                  value={this.state.active_campaign}
                  notranslate="yes"
                />
              </div>
            )}
          </div>
          <div
            className="settings-item hover-item no-select"
            onClick={() => {
              this.toggleActiveRow('fund');
            }}
          >
            <div className="details">
              <p>Default Fund</p>
              <span className="detail">
                You must have an active campaign in order to accept any types of payment.
                {this.state.activeRow === 'campaign' && (
                  <span
                    className="create"
                    onClick={(e) => {
                      this.props.createAction('campaigns');
                    }}
                  >
                    Don't have a campaign? Create one.
                  </span>
                )}
              </span>
            </div>
            {this.state.activeRow === 'fund' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion"
              >
                <Dropdown
                  label="Default Fund"
                  shouldSort={true}
                  onChange={(e) => {
                    handleInputChange(e, this, false, () => {
                      this.props.updateGroup(
                        {
                          default_fund_name:
                            this.state.defaultFund !== '' ? this.state.defaultFund : null,
                        },
                        this.props.groupState.group.id,
                      );
                    });
                  }}
                  name="defaultFund"
                  options={this.state.funds}
                  value={this.state.defaultFund}
                  notranslate="yes"
                />
              </div>
            )}
          </div>
          <div className="settings-item hover-item">
            <div className="toggle-view">
              <div className="details">
                <p>Fund Selection for Donors</p>
                <span className="detail">
                  Allow donors to choose which fund they prefer their donation to be directed to.
                </span>
              </div>
              <div className="settings-control">
                <i
                  onClick={() => {
                    if (this.state.funds.length > 1) {
                      this.props.updateGroup(
                        {
                          allow_donation_fund_selection:
                            !this.props.groupState.group.allow_donation_fund_selection,
                        },
                        this.props.groupState.group.id,
                      );
                    } else {
                      this.toggleActiveRow('defaultFundErrorMessage');
                      this.setState({
                        canToggleBannerMessage: !this.state.canToggleBannerMessage,
                      });
                    }
                  }}
                  className={
                    this.props.groupState.group.allow_donation_fund_selection
                      ? 'fad fa-toggle-on'
                      : 'fad fa-toggle-off'
                  }
                />
              </div>
            </div>
            {this.state.activeRow === 'defaultFundErrorMessage' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion message banner-view"
              >
                <Banner
                  className="banner-view"
                  type="failure"
                  title="Error"
                  content="There must be more than one fund to enable fund selection. Add another fund to enable fund selection for donors."
                  actionButton={{
                    buttonText: 'Add Fund',
                    onClick: () => this.props.createAction('campaigns'),
                  }}
                />
              </div>
            )}
          </div>

          <div className="settings-item">
            <div className="details">
              <p>Show Campaign Progress Bar</p>
              <span className="detail">
                Do you want users to see your default campaign progress bar?
              </span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => {
                  this.props.updateGroup(
                    { show_campaign_progress: !this.props.groupState.group.show_campaign_progress },
                    this.props.groupState.group.id,
                  );
                }}
                className={
                  this.props.groupState.group.show_campaign_progress
                    ? 'fad fa-toggle-on'
                    : 'fad fa-toggle-off'
                }
              />
            </div>
          </div>
          <div
            className="settings-item hover-item no-select"
            onClick={() => this.toggleActiveRow('progress-bar')}
          >
            <div className="details">
              <p>Campaign Progress Bar Parameters</p>
              <span className="detail">
                Choose which capacities to include in your campaign progress bar.
              </span>
            </div>
            {this.state.activeRow === 'progress-bar' && (
              <div
                className="expansion progress-bar"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="toggle-row">
                  <span className="toggle-label">Include Donations</span>
                  <ToggleButton
                    onToggleClick={() =>
                      updateGroup(
                        { show_donations_progress: !groupState.group.show_donations_progress },
                        groupState.group.id,
                      )
                    }
                    isActive={groupState.group.show_donations_progress!}
                    label={groupState.group.show_donations_progress ? 'Yes' : 'No'}
                  />
                </div>
                <div className="toggle-row">
                  <span className="toggle-label">Include Auction Items</span>
                  <ToggleButton
                    onToggleClick={() =>
                      updateGroup(
                        { show_auction_progress: !groupState.group.show_auction_progress },
                        groupState.group.id,
                      )
                    }
                    isActive={groupState.group.show_auction_progress!}
                    label={groupState.group.show_auction_progress ? 'Yes' : 'No'}
                  />
                </div>
                <div className="toggle-row">
                  <span className="toggle-label">Include Merchandise & Tickets</span>
                  <ToggleButton
                    onToggleClick={() =>
                      updateGroup(
                        { show_merchandise_progress: !groupState.group.show_merchandise_progress },
                        groupState.group.id,
                      )
                    }
                    isActive={groupState.group.show_merchandise_progress!}
                    label={groupState.group.show_merchandise_progress ? 'Yes' : 'No'}
                  />
                </div>
                <div className="toggle-row">
                  <span className="toggle-label">Include Sponsors</span>
                  <ToggleButton
                    onToggleClick={() =>
                      updateGroup(
                        { show_sponsor_progress: !groupState.group.show_sponsor_progress },
                        groupState.group.id,
                      )
                    }
                    isActive={groupState.group.show_sponsor_progress!}
                    label={groupState.group.show_sponsor_progress ? 'Yes' : 'No'}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="settings-title">Donations</div>
          <div className="settings-item">
            <div className="details">
              <p>Accepting Donations</p>
              <span className="detail">Are you accepting donations at this time?</span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => {
                  this.hasDonationsConfigured();
                }}
                className={
                  this.props.groupState.group.accepting_donations
                    ? 'fad fa-toggle-on'
                    : 'fad fa-toggle-off'
                }
              ></i>
            </div>
          </div>
          <div className="settings-item">
            <div className="details">
              <p>Allow Monthly Donations</p>
              <span className="detail">
                Do you want donors to have the option to donate monthly?
              </span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => {
                  this.hasSubscriptionsConfigured();
                }}
                className={
                  this.props.groupState.group.allow_subscriptions
                    ? 'fad fa-toggle-on'
                    : 'fad fa-toggle-off'
                }
              ></i>
            </div>
          </div>
          <div className="settings-item">
            <div className="details">
              <p>Allow Checkout Donations</p>
              <span className="detail">
                Do you want to allow users to make a donation upon checkout?
              </span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => {
                  this.toggleAllowCheckoutDonations();
                }}
                className={
                  this.state.allow_checkout_donations ? 'fad fa-toggle-on' : 'fad fa-toggle-off'
                }
              ></i>
            </div>
          </div>
          <div className="settings-item">
            <div className="details">
              <p>Use Donation Descriptions</p>
              <span className="detail">
                Would you like to include descriptions for each of your preset donation amounts?
              </span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => {
                  this.props.updateGroup(
                    { use_donation_amount_descriptions: !this.state.useDonationDescriptions },
                    this.props.groupState.group.id,
                  );
                }}
                className={
                  this.props.groupState.group.use_donation_amount_descriptions
                    ? 'fad fa-toggle-on'
                    : 'fad fa-toggle-off'
                }
              ></i>
            </div>
          </div>
          <div className={`settings-item ${!this.state.isPurplePlus ? 'disabled' : ''}`}>
            <div className="details">
              <p>Show tip option to donors</p>
              <span className="detail">
                Help support the Kambeo platform to continue providing free solutions to charities
                who need it.
              </span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => {
                  if (this.state.isPurplePlus) {
                    this.props.updateGroup(
                      { fee_control: { show_tip: !this.state.feeControl.allowTips } },
                      this.props.groupState.group.id,
                    );
                  }
                }}
                className={
                  this.props.groupState.group.fee_control?.show_tip
                    ? 'fad fa-toggle-on'
                    : 'fad fa-toggle-off'
                }
              ></i>
            </div>
          </div>
          <div
            className="settings-item hover-item no-select donation-amounts"
            onClick={() => {
              this.toggleActiveRow('amounts');
            }}
          >
            <div className="details">
              <p>Preset Donation Amounts</p>
              <span className="detail">
                Edit the five preset donation amounts that visitors see as suggested amounts on your
                donation page.
              </span>
            </div>
            {this.state.activeRow === 'amounts' && (
              <form
                onSubmit={(e) => this.saveDonationAmounts(e)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion amounts"
              >
                {this.state.donationAmounts &&
                  this.state.donationAmounts.map((amount, index) => {
                    return (
                      <div
                        key={index}
                        className="donation-amount"
                      >
                        <div className="index">{index + 1}</div>
                        <div className="donation-amount-text">
                          <TextField
                            required={true}
                            onChange={(e) => {
                              this.handleDonationAmountInputChange(e, this);
                            }}
                            value={amount.amount.toString()}
                            name={'donationAmount-' + index}
                            type="number"
                            min="5"
                          />
                        </div>
                        {this.state.useDonationDescriptions && (
                          <div className="donation-description">
                            <TextField
                              placeholder={'Donation description'}
                              required={true}
                              onChange={(e) => {
                                this.handleDonationAmountInputChange(e, this);
                              }}
                              value={amount.description}
                              name={'donationDescription-' + index}
                              type="text"
                              maxLength={80}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                <div className="amounts-actions">
                  <Button text="Update" />
                  <Button
                    text="Reset to default"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState(
                        {
                          donationAmounts: [
                            { amount: 25, description: '' },
                            { amount: 50, description: '' },
                            { amount: 100, description: '' },
                            { amount: 250, description: '' },
                            { amount: 500, description: '' },
                          ],
                        },
                        () => {
                          this.props.updateGroup(
                            { donation_amounts: this.state.donationAmounts },
                            this.props.groupState.group.id,
                          );
                        },
                      );
                    }}
                  />
                </div>
              </form>
            )}
          </div>
          <div
            className="settings-item hover-item no-select donor-levels"
            onClick={() => {
              this.toggleActiveRow('donorLevels');
            }}
          >
            <div className="details">
              <p>Donor Levels</p>
              <span className="detail">Set donor levels to help track donor giving analytics.</span>
            </div>
            {this.state.activeRow === 'donorLevels' && (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await this.updateGroupDonorLevels();
                }}
                onClick={(e) => e.stopPropagation()}
                className="expansion donor-levels"
              >
                <div className="range-container">
                  <p className="title">Regular Donor Range</p>
                  <div className="field-row">
                    <TextField
                      label="Minimum"
                      onChange={(e) => handleInputChange(e, this)}
                      value={this.state.donorLevelRegMin || ''}
                      name={'donorLevelRegMin'}
                      type="number"
                    />

                    <TextField
                      label="Maximum"
                      onChange={(e) => handleInputChange(e, this)}
                      value={this.state.donorLevelRegMax || ''}
                      name={'donorLevelRegMax'}
                      type="number"
                    />
                  </div>
                </div>
                <div className="range-container">
                  <p className="title">Middle Donor Range</p>
                  <div className="field-row">
                    <TextField
                      label="Minimum"
                      onChange={(e) => handleInputChange(e, this)}
                      value={this.state.donorLevelMidMin || ''}
                      name={'donorLevelMidMin'}
                      type="number"
                    />

                    <TextField
                      label="Maximum"
                      onChange={(e) => handleInputChange(e, this)}
                      value={this.state.donorLevelMidMax || ''}
                      name={'donorLevelMidMax'}
                      type="number"
                    />
                  </div>
                </div>
                <div className="range-container">
                  <p className="title">Major Donor Range</p>
                  <div className="field-row">
                    <TextField
                      label="Minimum"
                      onChange={(e) => handleInputChange(e, this)}
                      value={this.state.donorLevelMajorMin || ''}
                      name={'donorLevelMajorMin'}
                      type="number"
                    />

                    <TextField
                      label="Maximum"
                      onChange={(e) => handleInputChange(e, this)}
                      value={this.state.donorLevelMajorMax || ''}
                      name={'donorLevelMajorMax'}
                      type="number"
                    />
                  </div>
                </div>
                <div className="donor-level-action">
                  <Button text="Save" />
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      this.clearDonorLevels();
                    }}
                    className="donor-level-clear"
                    text="Clear"
                  />
                </div>
              </form>
            )}
          </div>
          <div className="settings-item">
            <div className="details">
              <p>Allow Pledges</p>
              <span className="detail">
                Do you want donors to have the option to make an annual pledge?
              </span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => {
                  this.hasPledgesConfigured();
                }}
                className={
                  this.props.groupState.group.allow_pledges
                    ? 'fad fa-toggle-on'
                    : 'fad fa-toggle-off'
                }
              />
            </div>
          </div>
          <div
            className="settings-item hover-item"
            onClick={() => {
              this.toggleActiveRow('message');
            }}
          >
            <div className="details">
              <p>Thank You Message</p>
              <span className="detail">
                This will be the default thank you message sent on all donation confirmation emails.
              </span>
            </div>
            {this.state.activeRow === 'message' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion message"
              >
                <div className="details">
                  <span className="detail">{`You can now use {{first_name}} to insert the user's first name! You can do the same for {{last_name}} and {{display_name}}.`}</span>
                </div>
                <QuillTextEditor
                  value={this.state.message}
                  readOnly={false}
                  preserveWhitespace={true}
                  maxLength={1500}
                  underMaxText="characters remaining"
                  idealLength={1000}
                  overIdealText="keep your message short for best results"
                  theme="snow"
                  modules={this.state.modules}
                  formats={this.state.formats}
                  onChange={this.handleMessageChange}
                />
                <div className="message-actions">
                  <Button
                    text="Save Message"
                    onClick={() => {
                      this.saveMessage();
                    }}
                    isDisabled={
                      extractLettersFromQuillMarkup(this.state.message.trim()).length > 1500
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div
            className="settings-item hover-item"
            onClick={() => {
              this.toggleActiveRow('donationCheckoutMessage');
            }}
          >
            <div className="details">
              <p>Custom Checkout Message</p>
              <span className="detail">
                This will be the default checkout message on all donation checkout pages.
              </span>
            </div>
            {this.state.activeRow === 'donationCheckoutMessage' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion message"
              >
                <QuillTextEditor
                  value={this.state.donationCheckoutMessage}
                  readOnly={false}
                  preserveWhitespace={true}
                  theme="snow"
                  modules={this.state.modules}
                  formats={this.state.formats}
                  onChange={(msg) => {
                    this.setState({ donationCheckoutMessage: msg });
                  }}
                  maxLength={300}
                />
                <div className="message-actions">
                  <Button
                    text="Save Message"
                    isDisabled={
                      extractLettersFromQuillMarkup(this.state.donationCheckoutMessage).length > 300
                    }
                    onClick={() => {
                      this.saveDonationCheckoutMessage();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={`settings-item hover-item`}>
            <div className="toggle-view">
              <div className="details">
                <p>Auto Send Thank You Messages</p>
                <span className="detail">
                  Would you like thank you messages automatically sent to recipients?
                </span>
              </div>
              <div className={`settings-control`}>
                <i
                  onClick={() => {
                    this.canToggle();
                    if (
                      !this.props.groupState.group.auto_confirmations &&
                      !this.state.isStripeConnected &&
                      this.props.groupState.group.confirmation_message !== '<p><br></p>' &&
                      this.props.groupState.group.confirmation_message &&
                      this.state.canToggleBannerMessage
                    ) {
                      this.toggleActiveRow('errorMessage');
                      this.setState({
                        canToggleBannerMessage: false,
                      });
                    }
                  }}
                  className={`${this.props.groupState.group.auto_confirmations ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}`}
                />
              </div>
            </div>
            {this.state.activeRow === 'errorMessage' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion message banner-view"
              >
                <Banner
                  className="banner-view"
                  type="failure"
                  title="Error"
                  content="Automated Thank You messages are not available when using the Custom Kambeo Stripe account. In order to enable Auto Thank You Messages, please connect to a standard stripe account."
                  actionButton={{
                    buttonText: 'Connect Stripe',
                    onClick: () => this.props.createAction('balance_and_payouts'),
                  }}
                />
              </div>
            )}
          </div>
          <div className={`settings-title ${!this.isTaxReceiptSupported() ? 'disabled' : ''}`}>
            Charity &amp; Tax Receipt Setup{' '}
            {!this.isTaxReceiptSupported() &&
              '(Tax Receipting is not yet available in your country)'}
          </div>
          <div
            className="settings-item hover-item no-select"
            onClick={() => {
              this.toggleActiveRow('charity');
            }}
          >
            <div className="details">
              <p>Charity ID</p>
              <span className="detail">
                For tax receipts to be issued you must have a registered charity ID and valid
                signature.
              </span>
            </div>
            {this.state.activeRow === 'charity' && this.isTaxReceiptSupported() && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion"
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.props.updateGroup(
                      { charity_id: this.state.charityID },
                      this.props.groupState.group.id,
                    );
                  }}
                  className="email-wrap"
                >
                  <TextField
                    label="Charity ID"
                    value={this.state.charityID}
                    name="charityID"
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                  <Button
                    isDisabled={this.state.charityID === this.props.groupState.group.charity_id}
                    type="submit"
                    text="Update"
                  />
                  {this.state.charityStatus !== null &&
                    this.state.charityStatus?.code === 'unverified' && (
                      <Button
                        className="verify"
                        type="button"
                        text="Verify"
                        onClick={() => {
                          this.requestCharityVerification();
                        }}
                      />
                    )}
                  {this.state.charityStatus !== null &&
                    this.state.charityStatus?.code !== 'unverified' && (
                      <div className={'charity-status ' + this.state.charityStatus?.code}>
                        {this.state.charityStatus?.code}
                      </div>
                    )}
                </form>
                {this.state.charityStatus !== null &&
                  this.state.charityStatus?.code === 'declined' && (
                    <div className="charity-error">
                      <i className="fal fa-exclamation-triangle" />
                      <span>There was a problem verifying your Charity ID. Please try again.</span>
                    </div>
                  )}
              </div>
            )}
          </div>
          <div
            className="settings-item hover-item no-select"
            onClick={() => {
              this.toggleActiveRow('legalName');
            }}
          >
            <div className="details">
              <p>Legal Name</p>
              <span className="detail">Add and edit your legal charity name for tax purposes.</span>
            </div>
            {this.state.activeRow === 'legalName' && this.isTaxReceiptSupported() && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion"
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.props.updateGroup(
                      { legal_charity_name: this.state.charityName },
                      this.props.groupState.group.id,
                    );
                  }}
                  className="email-wrap"
                >
                  <TextField
                    label={'Legal Charity Name'}
                    value={this.state.charityName}
                    name="charityName"
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                  />
                  <Button
                    isDisabled={
                      this.state.charityName === this.props.groupState.group.legal_charity_name
                    }
                    type="submit"
                    text="Update"
                  />
                </form>
              </div>
            )}
          </div>
          <div
            className="settings-item hover-item no-select"
            onClick={() => {
              this.toggleActiveRow('receiptPrefix');
            }}
          >
            <div className="details">
              <p>Tax receipt prefix</p>
              <span className="detail">Add and edit your tax receipt prefix</span>
            </div>
            {this.state.activeRow === 'receiptPrefix' && this.isTaxReceiptSupported() && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion"
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.props.updateGroup(
                      { tax_receipt_prefix: this.state.receiptPrefix.trim() },
                      this.props.groupState.group.id,
                    );
                  }}
                  className="prefix-wrap"
                >
                  <TextField
                    label={'Tax receipt prefix'}
                    value={this.state.receiptPrefix}
                    name="receiptPrefix"
                    type="text"
                    bottomLabel={`${this.state.receiptPrefix.length}/10`}
                    onChange={(e) => {
                      e.target.value.length <= 10 && handleInputChange(e, this);
                    }}
                  />
                  <Button
                    isDisabled={
                      (this.state.receiptPrefix.trim() === '' &&
                        !this.props.groupState.group.tax_receipt_prefix) ||
                      this.props.groupState.group.tax_receipt_prefix === this.state.receiptPrefix
                    }
                    type="submit"
                    text="Update"
                  />
                </form>
              </div>
            )}
          </div>
          <div
            className="settings-item hover-item no-select"
            onClick={() => {
              this.toggleActiveRow('locations');
            }}
          >
            <div className="details">
              <p>Location</p>
              <span className="detail">
                Please enter your issuing location.
                {this.state.activeRow === 'locations' && (
                  <span
                    className="create"
                    onClick={(e) => {
                      this.props.createAction('locations');
                    }}
                  >
                    Don't have a location? Create one.
                  </span>
                )}
              </span>
            </div>
            {this.state.activeRow === 'locations' && this.isTaxReceiptSupported() && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion"
              >
                <Dropdown
                  label="Current Location"
                  shouldSort={true}
                  onChange={(e) => {
                    handleInputChange(e, this, false, () => {
                      this.updateDefaultLocation();
                    });
                  }}
                  name="default_location_id"
                  options={this.state.locations}
                  value={this.state.default_location_id}
                  notranslate="yes"
                />
              </div>
            )}
          </div>
          <div
            className="settings-item hover-item no-select"
            onClick={() => {
              this.toggleActiveRow('logo');
            }}
          >
            <div className="details">
              <p>Logo</p>
              <span className="detail">This logo will show on all receipts.</span>
            </div>
            {this.state.activeRow === 'logo' &&
              this.isTaxReceiptSupported() &&
              this.isTaxReceiptSupported() && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="expansion logo"
                >
                  <div className="logo-wrap">
                    {this.props.groupState.group.receipt_logo_image_url &&
                      this.props.groupState.group.receipt_logo_image_url !== '' && (
                        <img
                          src={this.props.groupState.group.receipt_logo_image_url}
                          alt="Tax Receipt Logo"
                        />
                      )}
                  </div>
                  <div className="logo-actions">
                    <ImageUpload
                      label="Change Logo"
                      onImgSelect={this.onLogoSelect}
                    />
                  </div>
                </div>
              )}
          </div>
          <div
            className="settings-item hover-item no-select"
            onClick={() => {
              this.toggleActiveRow('signature');
            }}
          >
            <div className="details">
              <p>Signature</p>
              <span className="detail">
                For tax receipts to be issued you must have a valid signature.
              </span>
            </div>
            {this.state.activeRow === 'signature' && this.isTaxReceiptSupported() && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion signature"
              >
                <SignatureCanvas
                  ref={this.state.canvasRef}
                  penColor="black"
                  canvasProps={{ className: 'sigCanvas', height: 300, width: 600 }}
                  clearOnResize={true}
                />
                <div className="signature-actions">
                  <Button
                    text={'Update Signature'}
                    onClick={() => {
                      this.updateSignature();
                    }}
                  />
                  <ImageUpload
                    onImgSelect={(file: any) => {
                      this.uploadSignatureImg(file);
                    }}
                  />
                  <Button
                    text={'Clear'}
                    onClick={() => {
                      this.clear();
                    }}
                  />
                </div>
                <div className="form-wrap">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.updateSignature();
                    }}
                  >
                    <TextField
                      label="Name"
                      value={this.state.signatureName}
                      name="signatureName"
                      type="text"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                    <TextField
                      label="Title"
                      value={this.state.signatureTitle}
                      name="signatureTitle"
                      type="text"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                    <Button
                      isDisabled={
                        this.state.signatureName === this.props.groupState?.groupSignature?.name &&
                        this.state.signatureTitle === this.props.groupState?.groupSignature?.title
                      }
                      type="submit"
                      text="Update"
                    />
                  </form>
                </div>
              </div>
            )}
          </div>
          <div className="settings-item settings-item-column">
            <div className="settings-item-row">
              <div className="details">
                <p>Auto Send Charitable Tax Receipts</p>
                <span className="detail">
                  Would you like tax receipts to automatically send to recipients?
                </span>
              </div>
              <div className="settings-control">
                <i
                  onClick={() => {
                    this.hasAutoTaxConfigured();
                    if (
                      !this.props.groupState.group.auto_tax_receipts &&
                      !this.state.isStripeConnected &&
                      this.state.canToggleAutoReceipts
                    ) {
                      this.toggleActiveRow('autoTaxReceiptError');
                      this.setState({
                        canToggleAutoReceipts: false,
                      });
                    }
                  }}
                  className={`${this.props.groupState.group.auto_tax_receipts ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}`}
                />
              </div>
            </div>
            {this.props.groupState.group.auto_tax_receipts && (
              <div className="minimum-tax-receipt-amount">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.saveMinimumTaxReceiptAmount();
                  }}
                  className="email-wrap"
                >
                  <TextField
                    label="Minimum Donation Amount"
                    value={this.state.minimumTaxReceiptAmount}
                    name="minimumTaxReceiptAmount"
                    type="number"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                    helper={minimumTaxReceiptMessage}
                  />

                  <div className="actions">
                    <Button
                      isDisabled={
                        this.isTaxReceiptSupported() &&
                        parseInt(this.state.minimumTaxReceiptAmount) ===
                          this.props.groupState.group.minimum_tax_receipt_dollar_amount
                      }
                      type="submit"
                      text="Update"
                    />
                  </div>
                </form>
              </div>
            )}
            {this.state.activeRow === 'autoTaxReceiptError' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion message banner-view"
              >
                <Banner
                  className="banner-view"
                  type="failure"
                  title="Error"
                  content="Automated tax receipts are not available when using the custom Kambeo Stripe account. In order to enable Auto Tax Receipts, please setup and use a standard stripe account."
                  actionButton={{
                    buttonText: 'Connect Stripe',
                    onClick: () => this.props.createAction('balance_and_payouts'),
                  }}
                />
              </div>
            )}
          </div>

          <div className="settings-title">Store & Auction Receipts</div>
          <div
            className="settings-item hover-item"
            onClick={() => {
              this.toggleActiveRow('receipt-header');
            }}
          >
            <div className="details">
              <p>Header</p>
              <span className="detail">
                This will be the default header message sent on all store and auction purchase
                receipts.
              </span>
            </div>
            {this.state.activeRow === 'receipt-header' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion message"
              >
                <QuillTextEditor
                  value={this.state.receiptHeader}
                  readOnly={false}
                  preserveWhitespace={true}
                  theme="snow"
                  modules={this.state.modules}
                  formats={this.state.formats}
                  onChange={this.handleReceiptHeaderChange}
                />
                <div className="message-actions">
                  <Button
                    text={'Update Message'}
                    onClick={() => {
                      this.saveHeader();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            className="settings-item hover-item"
            onClick={() => {
              this.toggleActiveRow('receipt-footer');
            }}
          >
            <div className="details">
              <p>Footer</p>
              <span className="detail">
                This will be the default footer message sent on all store and auction purchase
                receipts.
              </span>
            </div>
            {this.state.activeRow === 'receipt-footer' && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="expansion message"
              >
                <QuillTextEditor
                  value={this.state.receiptFooter}
                  readOnly={false}
                  preserveWhitespace={true}
                  theme="snow"
                  modules={this.state.modules}
                  formats={this.state.formats}
                  onChange={this.handleReceiptFooterChange}
                />
                <div className="message-actions">
                  <Button
                    text="Update Message"
                    onClick={() => {
                      this.saveFooter();
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="settings-title">Invoicing</div>

          <div className="settings-item">
            <div className="details">
              <p>Allow Invoicing</p>
              <span className="detail">
                Allow users to request an invoice upon checkout for payment at a later date.
              </span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => {
                  this.props.updateGroup(
                    { allow_invoicing: !this.props.groupState.group.allow_invoicing },
                    this.props.groupState.group.id,
                  );
                }}
                className={
                  this.props.groupState.group.allow_invoicing
                    ? 'fad fa-toggle-on'
                    : 'fad fa-toggle-off'
                }
              ></i>
            </div>
          </div>

          <div className="settings-title">Fee Payment</div>
          <div className="settings-item hover-item no-select">
            <div className="details">
              <p>Processing Fee</p>
              <span className="detail">
                This is a standard third-party fee for processing online transactions.
              </span>
            </div>
            <div className="expansion fees">
              <div
                onClick={(e) => {
                  this.updateProcessingFee(false);
                }}
                className="fee-option"
              >
                <div className="fee-radio">
                  <i
                    className={
                      !this.state.feeControl.processingFees ? 'fas fa-circle' : 'far fa-circle'
                    }
                  />
                </div>
                <div className="fee-content">
                  <div className="fee-title">User pays fee</div>
                  <div className="fee-description">
                    Fee is added to the users transaction (donations, auction winnings, store and
                    ticket purchases).
                  </div>
                </div>
              </div>
              <div
                onClick={(e) => {
                  this.updateProcessingFee(true);
                }}
                className="fee-option"
              >
                <div className="fee-radio">
                  <i
                    className={
                      this.state.feeControl.processingFees ? 'fas fa-circle' : 'far fa-circle'
                    }
                  />
                </div>
                <div className="fee-content">
                  <div className="fee-title">Cause pays fee</div>
                  <div className="fee-description">
                    Fee is charged to your cause per transaction (user does not see the fee).
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="settings-item hover-item no-select">
            <div className="details">
              <p>Platform Fee</p>
              <span className="detail">
                This small fee added onto transactions helps Kambeo cover basic operating expenses.
              </span>
            </div>
            <div className="expansion fees">
              <div
                onClick={(e) => {
                  this.updateGigitFee(false);
                }}
                className="fee-option"
              >
                <div className="fee-radio">
                  <i
                    className={!this.state.feeControl.gigitFees ? 'fas fa-circle' : 'far fa-circle'}
                  />
                </div>
                <div className="fee-content">
                  <div className="fee-title">User pays fee</div>
                  <div className="fee-description">
                    Fee is added to the users transaction (donations, auction winnings, store and
                    ticket purchases).
                  </div>
                </div>
              </div>
              <div
                onClick={(e) => {
                  this.updateGigitFee(true);
                }}
                className="fee-option"
              >
                <div className="fee-radio">
                  <i
                    className={this.state.feeControl.gigitFees ? 'fas fa-circle' : 'far fa-circle'}
                  />
                </div>
                <div className="fee-content">
                  <div className="fee-title">Cause pays fee</div>
                  <div className="fee-description">
                    Fee is charged to your cause per transaction (user does not see the fee).
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="settings-title">Fiscal Year</div>
          <div className="settings-item hover-item no-select">
            <div className="details">
              <p>Fiscal Year Configuration</p>
              <span className="detail">Set the day your fiscal year will end.</span>
            </div>
            <div className="expansion fiscal-year">
              <div className="fee-title">End Date</div>
              <div className="inputs">
                <div className={'month'}>
                  <Dropdown
                    name="month"
                    options={months}
                    value={this.state.fiscalYearEndMonth}
                    onChange={(e) => {
                      this.setState({ fiscalYearEndMonth: Number(e.target.value) });
                    }}
                  />
                </div>
                <div className={'day'}>
                  <TextField
                    name="day"
                    required={true}
                    value={this.state.fiscalYearEndDay}
                    type="number"
                    min="1"
                    max={'31'}
                    onChange={(e) => {
                      this.setState({ fiscalYearEndDay: Number(e.target.value) });
                    }}
                  />
                </div>
              </div>
              <div className="actions">
                <Button
                  text="Save"
                  onClick={() => {
                    this.saveFiscalYearConfiguration();
                  }}
                />
              </div>
            </div>
          </div>

          <div className="settings-title">Contact</div>
          <div className="settings-item hover-item no-select">
            <div className="details">
              <p>Contact Details</p>
              <span className="detail">Manage how users can get in touch with you.</span>
            </div>

            <div className="expansion contact">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.saveGroupContactDetails();
                }}
              >
                <TextField
                  label="Phone (ex. +1-234-567-8900)"
                  value={this.state.contactPhone}
                  name="contactPhone"
                  type="tel"
                  onChange={(e) => handleInputChange(e, this)}
                />
                <TextField
                  label="Email"
                  value={this.state.contactEmail}
                  name="contactEmail"
                  type="email"
                  onChange={(e) => handleInputChange(e, this)}
                />

                <div className="actions">
                  <Button
                    buttonClass="cancel-button"
                    text="Cancel"
                    type="button"
                    onClick={() => {
                      this.setState({
                        contactPhone: this.props.groupState.group.contact_details?.phone || '',
                        contactEmail: this.props.groupState.group.contact_details?.email || '',
                      });
                    }}
                  />
                  <Button
                    buttonClass="save-button"
                    text="Save"
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>

          <div
            className="settings-title"
            notranslate="yes"
          >
            Connections
          </div>
          {this.state.existingIntegrations.map((integration: ISolutionInstance) => {
            return (
              <div
                className="settings-item connection"
                key={integration.id}
              >
                <div className="icon">
                  <img
                    src={integration.solution.customFields.find((e) => e.key == 'logo_url')?.value}
                  />
                </div>
                <div className="details">
                  <div
                    notranslate="yes"
                    className="details-title"
                  >
                    {integration.name}
                  </div>
                  {/*
                                            NOTE: There is a single description for every avaliable solution in tray and it should not change depending on group which is why it should be localized.
                                        */}
                  <div
                    notranslate="yes"
                    className="details-subtitle"
                  >
                    {localizeHelpers.translate(integration.solution.description)}
                  </div>
                </div>

                <div className="action">
                  <span
                    className="link disconnect"
                    onClick={() => {
                      this.invokeTray(integration, true);
                    }}
                  >
                    {'Reconnect'}
                  </span>
                  {integration.enabled && (
                    <span
                      className="link disconnect remove"
                      onClick={() => {
                        this.setState({ removeConnection: true, removeIntegration: integration });
                      }}
                    >
                      {'Remove'}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
          {this.state.availableIntegrations.map((integration: ISolution) => {
            return (
              <div
                className="settings-item connection"
                key={integration.id}
              >
                <div className="icon">
                  <img src={integration.customFields.find((e) => e.key == 'logo_url')?.value} />
                </div>
                <div className="details">
                  <div
                    notranslate="yes"
                    className="details-title"
                  >
                    {integration.title}
                  </div>
                  {/*
                                            NOTE: There is a single description for every avaliable solution in tray and it does not change depending on group which is why it should be localized.
                                        */}
                  <div
                    notranslate="yes"
                    className="details-subtitle"
                  >
                    {localizeHelpers.translate(integration.description)}
                  </div>
                </div>
                <div className="action">
                  {
                    <span
                      className="link"
                      onClick={() => {
                        this.invokeTray(integration);
                      }}
                    >
                      {'Connect'}
                    </span>
                  }
                </div>
              </div>
            );
          })}
        </div>
        <Prompt
          show={this.state.removeConnection}
          title="Remove Instance"
          message="Are you sure you want to remove this integration instance?"
          yesMessage="Remove"
          yesClass="fa fa-times"
          yesStyle="delete"
          cancelMessage="Cancel"
          onYes={() => this.removeTray()}
          onClose={() => this.setState({ removeConnection: false, removeIntegration: null })}
        />

        <Modal
          show={this.state.showLogoCropModal}
          onClose={() => {
            this.setState({ showLogoCropModal: false });
          }}
        >
          <ImageCropper
            recSize={'300 x 300'}
            onSave={this.onLogoCrop}
            src={this.state.logoSrc}
            minWidth={80}
          />
        </Modal>
        <Modal
          class="charity-verification-modal"
          show={this.state.showCharityVerification}
          onClose={() => {
            this.setState({ showCharityVerification: false });
          }}
        >
          <div
            className={
              this.state.showVerifyEmail || this.state.showVerifyPhone
                ? 'charity-verification show-verify'
                : 'charity-verification'
            }
          >
            <div className="thank-you">
              <div className="c-header">
                <div className="c-title">
                  Thank you for submitting your charity information for verification!
                </div>
                <img
                  alt="Charity ID submitted"
                  src={charityVerification}
                />
                <div className="curve"></div>
              </div>
              <div className="c-msg">
                The process can take up to 10 business days and you may be contacted for additional
                information. Please ensure your contact information is up to date.
              </div>
              <ul className="c-info">
                <li
                  onClick={() => {
                    this.setState({ showVerifyEmail: true });
                  }}
                >
                  <i className="fal fa-envelope"></i>
                  <span>Email</span>
                  <span className="c-info-detail">{this.state.contactEmail}</span>
                  <i
                    className={
                      this.state.contactEmail !== '' ? 'far fa-angle-right' : 'far fa-plus'
                    }
                  ></i>
                </li>
                <li
                  onClick={() => {
                    this.setState({ showVerifyPhone: true });
                  }}
                >
                  <i className="fal fa-phone"></i>
                  <span>Phone</span>
                  <span className="c-info-detail">{this.state.contactPhone}</span>
                  <i
                    className={
                      this.state.contactPhone !== '' ? 'far fa-angle-right' : 'far fa-plus'
                    }
                  ></i>
                </li>
              </ul>
              <div className="c-actions">
                <Button
                  text="Dismiss"
                  onClick={() => {
                    this.setState({ showCharityVerification: false });
                  }}
                />
              </div>
            </div>
            <div className={this.state.showVerifyEmail ? 'c-email active' : 'c-email'}>
              <div className="c-header">
                <i
                  onClick={() => {
                    this.setState({ showVerifyEmail: false });
                  }}
                  className="far fa-angle-left"
                ></i>
                <span>Email</span>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.saveGroupContactDetails();
                  this.setState({ showVerifyEmail: false });
                }}
              >
                <div className="fields">
                  <TextField
                    value={this.state.contactEmail}
                    name="contactEmail"
                    type="email"
                    onChange={(e) => handleInputChange(e, this)}
                  />
                </div>
                <div className="actions">
                  <Button
                    text="Apply Changes"
                    type="submit"
                  />
                </div>
              </form>
            </div>
            <div className={this.state.showVerifyPhone ? 'c-phone active' : 'c-phone'}>
              <div className="c-header">
                <i
                  onClick={() => {
                    this.setState({ showVerifyPhone: false });
                  }}
                  className="far fa-angle-left"
                ></i>
                <span>Phone</span>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.saveGroupContactDetails();
                  this.setState({ showVerifyPhone: false });
                }}
              >
                <div className="fields">
                  <TextField
                    value={this.state.contactPhone}
                    name="contactPhone"
                    type="tel"
                    onChange={(e) => handleInputChange(e, this)}
                  />
                </div>
                <div className="actions">
                  <Button
                    text="Apply Changes"
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <Modal
          class="testModal"
          show={this.state.showSolutionModal}
          onClose={() => {
            this.setState({ showSolutionModal: false });
          }}
        >
          <iframe
            src={this.state.solutionFrameURL}
            height="800px"
            width="600px"
          />
        </Modal>
        <ExternalVolunteerApplicationWarning
          show={this.state.showExternalVolunteerApplicationWarning}
          onClose={() => this.setState({ showExternalVolunteerApplicationWarning: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    defaultHubId: groupSelectors.GetGroupMainHubId(store),
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  updateGroupReceiptImage,
  createToast,
  updateGroup,
  getGroupCampaigns,
  getGroupSignature,
  setGroupSignature,
  getGroupLocations,
  getGroupReceiptNumber,
  getGroupReceiptNumberFormatted,
  changeGroupHandle,
  updateGroupRaisedAmount,
  getGroupHubs,
  setGroupPrimaryHub,
  getGroup,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsManagement));
