import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IAppState } from '../../store';
import { updateGeolocation } from '../../actions/geolocation';
import './Landing.scss';
import { setSEOMetatags } from '../../helpers';
import { userSelectors } from '../../selectors/user';
import CommunityFeed from '../../components/UserDashboard/MyActivity/CommunityFeed/CommunityFeed';
import DiscoverEvents from '../DiscoverPages/DiscoverEvents';
import Loader from '../../components/Loader/Loader';
import LandingDiscoverHeader from '../../components/LandingDiscoverHeader/LandingDiscoverHeader';

interface IProps extends RouteComponentProps<any> {
  updateGeolocation(): void;
}

const Landing: React.FC<IProps> = (props) => {
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));

  useEffect(() => {
    setSEOMetatags({
      urlPath: ``,
      title: 'Kambeo',
    });

    props.updateGeolocation();
  }, []);

  return (
    <div className="Landing">
      <Loader loading={isUserLoggedIn === null} />
      {isUserLoggedIn !== null && (
        <>
          {props.location.pathname === '/' && isUserLoggedIn && (
            <>
              <div className="home-header-container">
                <LandingDiscoverHeader
                  pathname={props.location.pathname}
                  isUserLoggedIn={isUserLoggedIn}
                />
              </div>
              <CommunityFeed />
            </>
          )}

          {(props.location.pathname === '/discover/events' || !isUserLoggedIn) && (
            <DiscoverEvents
              userState={userState}
              history={props.history}
              location={props.location}
              match={props.match}
            />
          )}
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  updateGeolocation,
};

export default connect(() => {
  return {};
}, mapDispatchToProps)(Landing);
