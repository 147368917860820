import React, { useState, useEffect } from 'react';
import {
  IVolunteerSimpleAvailability,
  IVolunteerSimpleAvailabilityDay,
  IVolunteerSimpleAvailabilityTime,
} from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import Checkbox from '../Checkbox/Checkbox';
import './AvailabilitySettings.scss';
import { updateUser } from '../../actions/user';
import { IAppState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { isMobileScreen } from '../../helpers';
import { userSelectors } from '../../selectors/user';

interface IProps {
  isCustomUser?: boolean;
  onAvailabilityChange?(data: IVolunteerSimpleAvailabilityDay[]): void;
  settingsTheme?: boolean;
  customUserAvailabilityData?: IVolunteerSimpleAvailability;
}

const titleCase = (str: string): string => {
  return str
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
};

const AvailabilitySettings = (props: IProps) => {
  const dispatch = useDispatch();
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));
  const [userDays, setUserDays] = useState<IVolunteerSimpleAvailabilityDay[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string>();
  const [selectedDayMorning, setSelectedDayMorning] = useState<boolean>(false);
  const [selectedDayAfternoon, setSelectedDayAfternoon] = useState<boolean>(false);
  const [selectedDayEvening, setSelectedDayEvening] = useState<boolean>(false);
  const [selectedDayNotAvailable, setSelectedDayNotAvailable] = useState<boolean>(false);

  const isMobile = isMobileScreen(300);

  const dayOrder = [
    Constants.volunteer_simple_availability_day.sunday,
    Constants.volunteer_simple_availability_day.monday,
    Constants.volunteer_simple_availability_day.tuesday,
    Constants.volunteer_simple_availability_day.wednesday,
    Constants.volunteer_simple_availability_day.thursday,
    Constants.volunteer_simple_availability_day.friday,
    Constants.volunteer_simple_availability_day.saturday,
  ] as string[];

  const timeOrder = [
    '', // for whitespace at the beginning
    Constants.volunteer_simple_availability_time.morning,
    Constants.volunteer_simple_availability_time.afternoon,
    Constants.volunteer_simple_availability_time.evening,
  ] as string[];

  // set init user availability settings
  useEffect(() => {
    if (userState?.user?.volunteer_availability && !props?.isCustomUser) {
      setUserDays(userState.user.volunteer_availability.days);
    }
  }, [userState, props.isCustomUser]);

  useEffect(() => {
    if (props?.customUserAvailabilityData && props?.isCustomUser) {
      setUserDays(props?.customUserAvailabilityData?.days);
    }
  }, [props.isCustomUser, props.customUserAvailabilityData]);

  // track userDays changes
  useEffect(() => {
    if (props.onAvailabilityChange && userDays && !props?.isCustomUser) {
      props.onAvailabilityChange(userDays);
    }
  }, [userDays, props.isCustomUser]);

  const isDayTimeChecked = (day: string, time: string): boolean => {
    return !!userDays?.find((d) => d.day === day)?.times.find((t) => t.time === time)?.is_available;
  };

  const isAvailable = (day: string): boolean => {
    let tmp = false;
    userDays
      ?.find((d) => d.day === day)
      ?.times.forEach((t) => {
        if (t.is_available) tmp = true;
      });

    return tmp;
  };

  const isTimeInArray = (day: string, time: string): boolean => {
    return !!userDays?.find((d) => d.day === day)?.times.find((t) => t.time === time);
  };

  const isDayInArray = (day: string): boolean => {
    return !!userDays?.find((d) => d.day === day);
  };

  const makeTimesString = (day: string): string => {
    let tmp = '';
    getTimes(day)?.forEach((t) => {
      if (t.is_available) tmp += `${t.time}, `;
    });

    return tmp.slice(0, -2);
  };

  const mobileSave = (): void => {
    dispatch(updateUser({ volunteer_availability: { days: userDays } }));
  };

  const getTimes = (day: string): IVolunteerSimpleAvailabilityTime[] | undefined => {
    return userDays?.find((d) => d.day === day)?.times;
  };

  const selectDay = (day: string): void => {
    setSelectedDay(day);
    let tmp = true;
    getTimes(day)?.forEach((d) => {
      if (d.time === Constants.volunteer_simple_availability_time.morning && d.is_available) {
        setSelectedDayMorning(true);
        tmp = false;
      }
      if (d.time === Constants.volunteer_simple_availability_time.afternoon && d.is_available) {
        setSelectedDayAfternoon(true);
        tmp = false;
      }
      if (d.time === Constants.volunteer_simple_availability_time.evening && d.is_available) {
        setSelectedDayEvening(true);
        tmp = false;
      }
    });

    setSelectedDayNotAvailable(tmp);
    setShowModal(true);
  };

  const saveSelectedDay = (): void => {
    if (isDayInArray(selectedDay || '')) {
      setUserDays(
        userDays?.map((d) => {
          if (d.day === selectedDay)
            return {
              day: selectedDay,
              times: [
                {
                  time: Constants.volunteer_simple_availability_time.morning,
                  is_available: selectedDayMorning,
                },
                {
                  time: Constants.volunteer_simple_availability_time.afternoon,
                  is_available: selectedDayAfternoon,
                },
                {
                  time: Constants.volunteer_simple_availability_time.evening,
                  is_available: selectedDayEvening,
                },
              ],
            };
          return d;
        }),
      );
    } else {
      const tmp = userDays
        ? [
            ...userDays,
            {
              day: selectedDay || '',
              times: [
                {
                  time: Constants.volunteer_simple_availability_time.morning,
                  is_available: selectedDayMorning,
                },
                {
                  time: Constants.volunteer_simple_availability_time.afternoon,
                  is_available: selectedDayAfternoon,
                },
                {
                  time: Constants.volunteer_simple_availability_time.evening,
                  is_available: selectedDayEvening,
                },
              ],
            },
          ]
        : ([
            {
              day: selectedDay,
              times: [
                {
                  time: Constants.volunteer_simple_availability_time.morning,
                  is_available: selectedDayMorning,
                },
                {
                  time: Constants.volunteer_simple_availability_time.afternoon,
                  is_available: selectedDayAfternoon,
                },
                {
                  time: Constants.volunteer_simple_availability_time.evening,
                  is_available: selectedDayEvening,
                },
              ],
            },
          ] as IVolunteerSimpleAvailabilityDay[]);

      setUserDays(tmp);
    }

    setShowModal(false);
    resetSelectedDay();
  };

  const resetSelectedDay = (): void => {
    setSelectedDayMorning(false);
    setSelectedDayAfternoon(false);
    setSelectedDayEvening(false);
    setSelectedDayNotAvailable(false);
    setSelectedDay('');
  };

  const saveAvailabilitySettings = (day: string, time: string): void => {
    let payload: IVolunteerSimpleAvailabilityDay[];
    if (userDays) {
      if (isTimeInArray(day, time)) {
        payload = userDays.map((d: IVolunteerSimpleAvailabilityDay) => {
          if (d.day === day) {
            return {
              day,
              times: d.times.map((t) => {
                if (t.time === time)
                  return {
                    time,
                    is_available: !t.is_available,
                  };
                return t;
              }),
            };
          }
          return d;
        });
      } else if (isDayInArray(day)) {
        payload = userDays.map((d: IVolunteerSimpleAvailabilityDay) => {
          if (d.day === day) {
            return {
              day,
              times: [...d.times, { time, is_available: true }],
            };
          }
          return d;
        });
      } else payload = [...userDays, { day, times: [{ time, is_available: true }] }];
    } else payload = [{ day, times: [{ time, is_available: true }] }];

    if (!props.onAvailabilityChange) {
      dispatch(updateUser({ volunteer_availability: { days: payload } }));
    } else setUserDays(payload);
  };

  function getTimeDesc(index: number): string {
    switch (index) {
      case 0:
        return '';
      case 1:
        return 'Before noon';
      case 2:
        return 'noon - 5pm';
      default:
      case 3:
        return '5pm or later';
    }
  }

  return (
    <div className="AvailabilitySettings section-wrap">
      {!props?.settingsTheme && <div className="section-title">Availability</div>}

      <div className="section-inner">
        <div className="table-container">
          {!props?.settingsTheme && (
            <p className="instruction-text">Select the days and times you are available.</p>
          )}
          <table>
            <tbody>
              {timeOrder.map((time: string, tIndex: number) => {
                return (
                  <tr key={tIndex}>
                    {tIndex === 0 && <th className="empty" />}
                    {tIndex > 0 && (
                      <th className="time-th">
                        {time}
                        <span>{getTimeDesc(tIndex)}</span>
                      </th>
                    )}
                    {dayOrder.map((day: string) => {
                      if (tIndex === 0) {
                        return (
                          <th key={`${tIndex}-${day}`}>
                            {props?.settingsTheme
                              ? isMobile
                                ? day.slice(0, 1)
                                : day.slice(0, 3)
                              : day}
                          </th>
                        );
                      } else {
                        return (
                          <td key={`${tIndex}-${day}`}>
                            <Checkbox
                              color="plum-dark"
                              key={`${day}_${time}`}
                              checked={isDayTimeChecked(day, time)}
                              onChange={() => {
                                saveAvailabilitySettings(day, time);
                              }}
                              disabled={props?.isCustomUser}
                              label=""
                              name={`${day}_${time}`}
                              value={`${day}_${time}`}
                            />
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="mobile-container">
          <span className="mini-title">Per Day Availability</span>
          {dayOrder.map((day: string, index: number) => {
            return (
              <div
                className="day"
                key={index}
                onClick={() => {
                  selectDay(day);
                }}
              >
                <span>
                  {isDayInArray(day) && (
                    <i
                      className={
                        isAvailable(day)
                          ? 'fas fa-check-circle i-available'
                          : 'fas fa-times-circle i-not-available'
                      }
                    />
                  )}
                  {day}
                </span>
                {!isDayInArray(day) && <span className="select">Select Availability</span>}
                {isDayInArray(day) && (
                  <span className={!isAvailable(day) ? 'not-available' : ''}>
                    <i className="fal fa-pen" />
                    {!isAvailable(day) ? 'Not Available' : makeTimesString(day)}
                  </span>
                )}
              </div>
            );
          })}
          {!props.onAvailabilityChange && (
            <Button
              className="save"
              type="button"
              onClick={mobileSave}
              isDisabled={!userDays || props?.isCustomUser}
              text="Save"
            />
          )}
        </div>
      </div>
      <Modal
        show={showModal}
        class="SelectDayMobileModal"
        onClose={() => {
          resetSelectedDay();
          setShowModal(false);
        }}
        title={titleCase(selectedDay || '')}
      >
        <div className="selected-day-container">
          <span className="desc">Select all availability:</span>
          <div className="btn-options-container">
            <Button
              type="button"
              className="secondary"
              buttonClass={selectedDayMorning ? 'active' : ''}
              text="Mornings"
              onClick={() => {
                setSelectedDayNotAvailable(false);
                setSelectedDayMorning((selectedDayMorning) => !selectedDayMorning);
              }}
              isDisabled={props?.isCustomUser}
            />
            <Button
              type="button"
              className="secondary"
              buttonClass={selectedDayAfternoon ? 'active' : ''}
              text="Afternoons"
              onClick={() => {
                setSelectedDayNotAvailable(false);
                setSelectedDayAfternoon((selectedDayAfternoon) => !selectedDayAfternoon);
              }}
              isDisabled={props?.isCustomUser}
            />
            <Button
              type="button"
              className="secondary"
              buttonClass={selectedDayEvening ? 'active' : ''}
              text="Evenings"
              onClick={() => {
                setSelectedDayNotAvailable(false);
                setSelectedDayEvening((selectedDayEvening) => !selectedDayEvening);
              }}
              isDisabled={props?.isCustomUser}
            />
          </div>
          <div>
            <Button
              text="Not Available"
              className="not-available secondary"
              buttonClass={selectedDayNotAvailable ? 'active' : ''}
              isDisabled={props?.isCustomUser}
              onClick={() => {
                setSelectedDayMorning(false);
                setSelectedDayAfternoon(false);
                setSelectedDayEvening(false);
                setSelectedDayNotAvailable((selectedDayNotAvailable) => !selectedDayNotAvailable);
              }}
            />
          </div>
          {!props?.isCustomUser && (
            <>
              <Button
                text="Confirm"
                className="confirm"
                buttonType="dark"
                type="button"
                onClick={saveSelectedDay}
              />
              <Button
                text="Cancel"
                className="cancel"
                buttonType="outline-dark"
                type="button"
                onClick={() => {
                  resetSelectedDay();
                  setShowModal(false);
                }}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AvailabilitySettings;
