import { IOnboardStep } from '../../components/shared/Onboarding/interfaces';

export interface IPlanSelection {
  id: string;
  title: string;
  description: string;
  cost: string;
  benefits: string[];
}

export const groupTypeOptions = [
  {
    text: 'Non-Profit',
    subtext: 'Manage volunteers, donations, events & fundraisers, donors, sponsors, and more.',
    id: 'non-profit',
  },
  {
    text: 'School',
    subtext:
      "Connect your school's community including volunteer opportunities, events & fundraisers.",
    id: 'school',
  },
  {
    text: 'Business',
    subtext:
      'Promote your business, create an online store, find your volunteer help and build community events.',
    id: 'business',
  },
];

export const schoolTypeOptions = [
  {
    text: 'High School',
    subtext: 'Donate to a focus area or create your own donation page.',
    id: 'high school',
  },
  {
    text: 'University or College',
    subtext: 'Donate to a focus area or create your own donation page.',
    id: 'university/college',
  },
];

export const nonProfitTypeOptions = [
  {
    text: 'Registered Charity',
    subtext: 'An organization established and operated for charitable purposes',
    id: 'Registered Charity',
  },
  { text: 'Foundation', subtext: 'Community or Private Foundations', id: 'foundation' },
  {
    text: 'Community Group',
    subtext:
      'An association of individuals from the same community, especially one formed to advance a particular cause or interest',
    id: 'communitygroup',
  },
  {
    text: 'Service Club',
    subtext:
      'An association of business or professional people wit the aims of promoting community welfare and goodwill',
    id: 'serviceclub',
  },
];

export const groupStepsMaster: Array<IOnboardStep> = [
  {
    id: 'charitiesSelectionGroup',
    text: 'Claim or create your Cause page',
    tip: "Can't find your organization or aren't a registered charity?",
    summaryTitle: 'Claimed Charity',
    data: [{ path: 'claimedCharity.title', required: false }],
  },
  // {id: 'groupTypeSelection', text: "What type of group are you looking to create?", tip: 'A group page represents your organization or cause and is the home for all of your data and activity.', summaryTitle: 'Type of Group', data: [ {path: 'groupType', required: true }] },
  // {id: 'nonProfitSelection', text: "What type of non-profit group are you looking to create?", tip: null, summaryTitle: 'Type of Non-Profit', data: [{path: 'subType', required: true}]},
  // {id: 'schoolTypeSelection', text: "What type of school?", tip: null, summaryTitle: 'Type of School', data: [{path: 'subType', required: true}] },
  {
    id: 'groupInfo',
    text: 'Tell us a bit about your organization',
    tip: null,
    summaryTitle: 'Cause Info',
    data: [
      { path: 'subType', required: true },
      { path: 'baseInfo.name', required: true },
      { path: 'baseInfo.description', required: false },
      { path: 'baseInfo.revenueGoal', required: false },
      { path: 'charityId', required: true },
      { path: 'charityUrl', required: false },
      { path: 'charityEmail', required: true },
      { path: 'charityPhone', required: true },
      // {path: 'classificationInfo.groupClass', required: true},
      // {path: 'classificationInfo.groupSubClass', required: true },
      { path: 'localization.country', required: true },
      { path: 'localization.state', required: true },
      { path: 'localization.defaultTimezone', required: false },
    ],
  },
  {
    id: 'causesSelection',
    text: 'Choose focus areas that best describe your cause',
    tip: 'Selecting focus areas will increase the likelihood of your posts being noticed!',
    summaryTitle: 'Focus Areas',
    data: [{ path: 'causeInfo.selectedCauses', required: true }],
  },
  {
    id: 'imageSelection',
    text: 'Add a logo & cover image to your cause',
    tip: 'A more complete page will help attract visitors!',
    summaryTitle: 'Cause Customization',
    data: [
      { path: 'profileImage', required: true },
      { path: 'coverImage', required: true },
    ],
  },
  {
    id: 'planSelection',
    text: 'Start for free or upgrade to Purple Plus+ for a premium experience!',
    tip: null,
    summaryTitle: 'Cause Plan',
    data: [{ path: 'planType', required: true }],
  },
  {
    id: 'paymentInfo',
    text: 'Payment details for Purple+',
    tip: 'Gain access to the support you need to be successful!',
    summaryTitle: 'Payment Info',
    data: [
      { path: 'paymentInfo.address', required: true },
      { path: 'paymentInfo.apartment', required: false },
      { path: 'paymentInfo.city', required: true },
      { path: 'paymentInfo.state', required: true },
      { path: 'paymentInfo.country', required: true },
      { path: 'paymentInfo.zip', required: true },
      { path: 'paymentInfo.paymentMethod', required: true },
    ],
  },
  {
    id: 'summary',
    text: 'Review the details for your cause',
    tip: null,
    summaryTitle: null,
    data: [],
  },
];
export const planSelection: IPlanSelection[] = [
  {
    id: 'starter',
    title: 'Starter',
    cost: '$0',
    description:
      'No restrictions on any functionality!  Get started creating events, fundraisers, auctions, volunteer postings and more for free!',
    benefits: [
      'Built-in CRM',
      'Donation, Event and Volunteer Management',
      'Online Auctions',
      'Sell Merchandise Online',
      'MailChimp, Stripe and Twitch Integrations',
      'Volunteer Postings',
      'Access to Kambeo Knowledge Base',
      'Basic Email Support (within 72 business hours of submission)',
    ],
  },
  {
    id: 'purple_plus',
    title: 'Upgrade to Purple+',
    cost: '$199.99 / Month x 12',
    description: 'No transaction fees and access to premium support',
    benefits: [
      'Everything in the Starter Package',
      'Zero Platform Transaction Fees',
      'Highlight in the Kambeo Newsletter',
      '3 one-hour virtual meetings with a Kambeo expert ($450 value)',
      'Enhanced Email Support (within 24 business hours of submission)',
    ],
  },
];

/**
 * Filtering step lists by possible 'types' inclusive of groupTypeOptions above. (non-profit, school, business)
 */
const npSteps = groupStepsMaster.filter((step) => step.id !== 'schoolTypeSelection');
export const nonProfitSteps = [...npSteps];

const sSteps = groupStepsMaster.filter((step) => step.id !== 'nonProfitSelection');
export const schoolGroupSteps = [...sSteps];

const bSteps = groupStepsMaster.filter(
  (step) =>
    step.id !== 'nonProfitSelection' &&
    step.id !== 'schoolTypeSelection' &&
    step.id !== 'causesSelection',
);
export const businessSteps = [...bSteps];

export const getGroupTypeLabelFromId = (type: string) => {
  return groupTypeOptions.find((typeOption) => typeOption.id === type)?.text ?? type;
};

export const getGroupSubTypeLabelFromId = (type: string) => {
  return type === 'university/college'
    ? schoolTypeOptions.find((typeOption) => typeOption.id === type)?.text ?? type
    : nonProfitTypeOptions.find((typeOption) => typeOption.id === type)?.text ?? type;
};
