import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ITransactionAmounts, ITransactionItem, ITransactionItemSummary } from '@gigit/interfaces';
import {
  defaultCurrency,
  handleInputChange,
  formatCurrency,
  capitalizeString,
  errorHelpers,
  toastError,
  appendFilterToUrlParmas,
} from '../../helpers';
import { IAuctionState } from '../../reducers/auction';
import {
  AuctionItemOwnerType,
  getAuctionsExport,
  getAuctionsExportDaily,
  getAuctionTransactions,
  refundAuctionPayment,
  sendAuctionPaymentReceipt,
} from '../../actions/auction';
import { updateTransactionPayment } from '../../actions/group';
import TextField from '../TextField/TextField';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import ModalHeader from '../Modal/ModalHeader/ModalHeader';
import Dropdown from '../Dropdown/Dropdown';
import './AuctionTransactions.scss';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import typeHelpers from '../../helpers/typeHelpers';
import {
  IActiveFilter,
  IOwnerObject,
  ISortSettings,
  IToast,
  ReduxActionType,
} from '../../interfaces';
import Table, { ITableProps } from '../shared/Table/Table';
import { auctionRequestActions } from '../../requestActions/auction';
import { createToast } from '../../actions/toaster';

interface IProps extends RouteComponentProps<any> {
  owner: IOwnerObject;
  createToast(toast: IToast): void;
  sendAuctionPaymentReceipt: ReduxActionType<typeof sendAuctionPaymentReceipt>;
  auctionState: IAuctionState;
  refundAuctionPayment(
    ownerType: AuctionItemOwnerType,
    ownerId: string,
    id: string,
    payload: any,
    callback?: (transactionItem: ITransactionItem) => void,
  ): void;
  updateTransactionPayment(
    groupId: string,
    transaction_id: string,
    payload: any,
    callback?: any,
  ): void;
  transactionItemUpdated(): void;
  getAuctionsExport: ReduxActionType<typeof getAuctionsExport>;
  getAuctionsExportDaily: ReduxActionType<typeof getAuctionsExportDaily>;
  locale: string;
}

interface IState {
  search: string;
  order: ITransactionItemSummary | null | undefined;
  showRefundModal: boolean;
  refund_amount: number;
  showSelectPaymentModal: boolean;
  selectedPaymentMethod: string;
  searchTimeout: null | ReturnType<typeof setTimeout>;
  sort?: ISortSettings[];
  query?: URLSearchParams;
  page: number;
  hasMore: boolean;
  tableConfig: ITableProps<ITransactionItemSummary>;
  refreshTableIncrementor: number;
}

class AuctionTransactions extends React.Component<IProps, IState> {
  readonly PAYMENT_METHODS = [
    { label: 'Cash', value: 'cash' },
    { label: 'Cheque ', value: 'cheque' },
    { label: 'E-Transfer', value: 'e-transfer' },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      search: '',
      order: null,
      showRefundModal: false,
      refund_amount: 0,
      showSelectPaymentModal: false,
      selectedPaymentMethod: this.PAYMENT_METHODS[0].value,
      searchTimeout: null,
      hasMore: true,
      page: 0,
      tableConfig: {
        filterOptions: {
          enableColumnSorting: true,
          enableTableSearch: true,
          enableFilterDrawer: true,
          filterDrawerOptions: [
            {
              fieldId: 'user.first_name',
              options: { label: 'First Name' },
              type: 'textField',
            },
            {
              fieldId: 'user.last_name',
              options: { label: 'Last Name' },
              type: 'textField',
            },
            {
              fieldId: 'name',
              options: { label: 'Item Name' },
              type: 'textField',
            },
            {
              fieldId: 'purchase.total',
              options: { label: 'Total Amount' },
              type: 'textField',
            },
          ],
        },
        columns: [
          {
            id: 'user.full_name',
            sortable: true,
            Header: 'Purchaser Name',
            Cell: (item) => {
              return (
                <span notranslate="yes">{item.user?.full_name || item.user?.display_name}</span>
              );
            },
            accessor: (item) => item?.user?.display_name,
          },
          {
            id: 'name',
            sortable: true,
            Header: 'Item Name',
            Cell: (item) => {
              return <span notranslate="yes">{item.name || 'N/A'}</span>;
            },
            accessor: (item) => item?.name,
          },
          {
            id: 'purchase.total',
            sortable: true,
            Header: 'Total Amount',
            accessor: (item) => item.purchase.total,
            Cell: (item) => {
              const exchangeRate = item.transaction?.reporting_exchange_rate ?? 1;
              const currency = item.transaction?.reporting_currency ?? defaultCurrency;
              return (
                <var data-var="total">
                  {formatCurrency(
                    ((item.purchase.total + item.donation.total) / 100) * exchangeRate,
                    currency,
                    this.props.locale,
                  )}
                </var>
              );
            },
          },
          {
            id: 'transaction.amounts.payment_method',
            sortable: true,
            Header: 'Payment Method',
            accessor: (item) => item?.transaction?.amounts.payment_method,
            Cell: (item) => {
              return <span>{capitalizeString(item?.transaction?.amounts.payment_method)}</span>;
            },
          },
          {
            id: 'purchase.payment_status.code',
            sortable: true,
            Header: 'Payment Status',
            predefinedColumnType: {
              type: 'STATUS',
              columnObject: () => ({
                refunded: {
                  label: 'Refunded',
                  color: 'RED',
                },
                outbid: {
                  label: 'Outbid',
                  color: 'RED',
                },
                unpaid: {
                  label: 'Unpaid',
                  color: 'GREY',
                },
                pending: {
                  label: 'Pending',
                  color: 'GREY',
                },
                won: {
                  label: 'Won',
                  color: 'GREEN',
                },
                active: {
                  label: 'Active',
                  color: 'GREEN',
                },
                inProgress: {
                  label: 'In progress',
                  color: 'GREEN',
                },
                paid: {
                  label: 'Paid',
                  color: 'GREEN',
                },
              }),
            },
            accessor: (item) => item?.purchase?.payment_status?.code,
          },
          {
            id: 'created_at',
            sortable: true,
            Header: 'Payment Date',
            Cell: (item) => {
              return (
                <span>
                  {localizeHelpers.formatDate(
                    item.created_at ?? new Date(),
                    LocaleDateFormats.LL,
                    this.props.locale,
                  )}
                </span>
              );
            },
            accessor: (item) => item.created_at?.toString(),
          },
        ],
        pagination: {
          pageSizeOptions: [10],
          queryAction: async (params) => await this.getData(params),
        },
        emptyStateConfig: {
          title: 'No data',
          description: "We couldn't find any donations",
        },
        tableActionOptions: {
          enableRowContextMenuActions: true,
          tableActions: [
            {
              type: 'HEADER',
              icon: 'far fa-file-download',
              onClick: () => this.exportAuctionCSV(),
              label: 'Export',
              buttonType: 'outline-dark',
            },
            {
              type: 'HEADER',
              icon: 'far fa-file-download',
              onClick: () => this.exportDailyAuctionWinnersCSV(),
              label: 'Daily Winners',
              buttonType: 'outline-dark',
            },
            {
              type: 'ROW_CONTEXT_MENU',
              icon: 'far fa-dollar-sign',
              onClick: (e, item) => this.initRefund(item),
              label: 'Refund',
              hideIf: (item) =>
                Boolean(
                  !(
                    !item.transaction?.amounts?.refunds?.length &&
                    this.getPurchaseStatus(item.transaction?.amounts) === 'paid'
                  ) || item.purchase.is_refund,
                ),
            },
            {
              type: 'ROW_CONTEXT_MENU',
              icon: 'far fa-ballot-check',
              onClick: (e, item) => this.markAsPaid(item),
              label: 'Mark as Paid',
              hideIf: (item) =>
                Boolean(
                  !(this.getPurchaseStatus(item.transaction?.amounts) === 'unpaid') ||
                    item.purchase.is_refund,
                ),
            },
            {
              type: 'ROW_CONTEXT_MENU',
              icon: 'fa fa-envelope',
              onClick: (e, item) =>
                this.props.sendAuctionPaymentReceipt(
                  this.props.owner.ownerType as AuctionItemOwnerType,
                  this.props.owner.ownerId,
                  item.transaction_id ?? '',
                ),
              label: 'Send Receipt',
              hideIf: (item) => !!item?.purchase?.is_refund,
            },
          ],
        },
      },
      refreshTableIncrementor: 0,
    };
  }

  exportAuctionCSV() {
    this.props.getAuctionsExport(
      this.props.owner.ownerType as AuctionItemOwnerType,
      this.props.owner.ownerId,
      this.props.owner.ownerHandle,
    );
  }

  exportDailyAuctionWinnersCSV() {
    this.props.getAuctionsExportDaily(
      this.props.owner.ownerType as AuctionItemOwnerType,
      this.props.owner.ownerId,
      this.props.owner.ownerHandle,
    );
  }

  async getData(query: URLSearchParams) {
    let data = [] as ITransactionItemSummary[];
    try {
      data = await auctionRequestActions.getAuctionTransactions(
        this.props.owner.ownerType as AuctionItemOwnerType,
        this.props.owner.ownerId,
        query,
      );
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errObj.translatedMessage, 'Get Auction Transactions');
      this.props.createToast(toast);
    }

    return data;
  }

  refund(id: string): void {
    this.toggleRefundModal(true);

    this.props.refundAuctionPayment(
      this.props.owner.ownerType as AuctionItemOwnerType,
      this.props.owner.ownerId,
      id,
      { amount: this.state.refund_amount },
      () => {
        this.props.transactionItemUpdated();
        this.setState({
          refreshTableIncrementor: this.state.refreshTableIncrementor + 1,
        });
      },
    );
    this.toggleRefundModal(false);
  }

  toggleRefundModal(value: boolean): void {
    this.setState({
      showRefundModal: value,
    });
  }

  initRefund(purchase: ITransactionItemSummary | null | undefined): void {
    this.setOrder(purchase);
  }

  setOrder(purchase: ITransactionItemSummary | null | undefined) {
    this.setState(
      {
        order: purchase,
      },
      () => {
        try {
          typeHelpers.assertNotNullOrUndefined(this.state.order, 'Expected an order');
          this.setState({
            showRefundModal: true,
            refund_amount: this.state.order.purchase.total / 100,
          });
        } catch (error) {
          const errObj = errorHelpers.getErrorObject(error);
          const toast = toastError(errObj.translatedMessage, 'Expected an order');
          this.props.createToast(toast);
        }
      },
    );
  }

  getPurchaseStatus(amounts?: ITransactionAmounts) {
    if (amounts?.is_refund) {
      return 'refunded';
    }

    if (amounts?.payment_status) {
      return amounts.payment_status.code;
    }

    if (amounts?.payment_method === 'card') {
      return 'paid';
    }

    return 'unpaid';
  }

  markAsPaid(item: ITransactionItemSummary) {
    this.setState({
      order: item,
      showSelectPaymentModal: true,
    });
  }

  updateTransactionPayment() {
    try {
      typeHelpers.assertNotNullOrUndefined(this.state.order?.transaction, 'Expected an order');

      const transactionId = this.state.order.transaction.id;
      const groupId = this.state.order.transaction.group_id;

      typeHelpers.assertNotNullOrUndefined(transactionId, 'Expected transaction id');

      this.props.updateTransactionPayment(
        groupId,
        transactionId,
        { paymentMethod: this.state.selectedPaymentMethod },
        () => {
          this.setState({ refreshTableIncrementor: this.state.refreshTableIncrementor + 1 });
          this.props.transactionItemUpdated();
        },
      );
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errObj.translatedMessage, 'Update transaction payment');
      this.props.createToast(toast);
    }
  }

  render() {
    return (
      <div className="AuctionTransactions section-wrap">
        <div className="AuctionTransactions-list">
          <div className="list">
            <div className="list-inner">
              <div className="list-rows">
                <Table
                  refreshTableIncrementor={this.state.refreshTableIncrementor}
                  {...this.state.tableConfig}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.order && this.renderRefundModal(this.state.order)}

        <Modal
          show={this.state.showSelectPaymentModal}
          onClose={() => {
            this.setState({ showSelectPaymentModal: false });
          }}
        >
          <ModalHeader
            className="select-payment-header"
            title="Mark as Paid"
            onClose={() => {}}
          />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({ showSelectPaymentModal: false });
              this.updateTransactionPayment();
            }}
          >
            <div className="select-payment">
              <div className="payment-info">Payment Information</div>
              <Dropdown
                value={this.state.selectedPaymentMethod}
                shouldSort={true}
                label="Type of payment"
                onChange={(e) => {
                  this.setState({ selectedPaymentMethod: e.target.value });
                }}
                name="purchase-method"
                options={this.PAYMENT_METHODS}
              />
            </div>

            <div className="controls">
              <Button
                buttonClass="cancel-button"
                type="button"
                onClick={() => {
                  this.setState({ showSelectPaymentModal: false });
                }}
                text="Cancel"
              />
              <Button
                buttonClass="save-button"
                type="submit"
                text="Save"
              />
            </div>
          </form>
        </Modal>
      </div>
    );
  }

  renderRefundModal(order: ITransactionItemSummary) {
    try {
      typeHelpers.assertNotNullOrUndefined(order.transaction, 'Expected transaction');
      typeHelpers.assertNotNullOrUndefined(order.user, 'Expected user');
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errObj.translatedMessage, 'Update transaction payment');
      this.props.createToast(toast);
    }

    const exchangeRate = order?.transaction?.reporting_exchange_rate ?? 1;
    const currency = order?.transaction?.reporting_currency ?? defaultCurrency;

    const refund = () => {
      typeHelpers.assertNotNullOrUndefined(order.transaction_id, 'Render Refund Modal');
      this.refund(order.transaction_id);
    };

    return (
      <Modal
        onClose={() => {
          this.toggleRefundModal(false);
        }}
        show={this.state.showRefundModal}
      >
        <div className="refund">
          <div className="refund-title">
            <h1>Refund Order</h1>
            <div>A copy of the reciept will be emailed to the customer.</div>
          </div>
          <div className="refund-info">
            <div className="sub-header">Order Summary</div>
            <div className="info">
              <div className="info-part">
                <span>Name: </span>
                <var data-var="name">
                  {order?.user?.first_name && order?.user?.last_name
                    ? `${order?.user?.first_name} ${order?.user?.last_name}`
                    : order?.user?.display_name}
                </var>
              </div>
              <div className="info-part">
                <span>Transaction type: </span>
                <var data-var="transaction_type">
                  {this.state.order && this.state.order.transaction_type}
                </var>{' '}
              </div>
              <div className="info-part">
                <span>Transaction #: </span>
                <var data-var="order_number">
                  {this.state.order && this.state.order.transaction?.transaction_number}
                </var>{' '}
              </div>
              <div className="info-part">
                <span>Amount Charged: </span>
                <var data-var="amount_charged">
                  {formatCurrency(
                    (order.purchase.total / 100) * exchangeRate,
                    currency,
                    this.props.locale,
                  )}
                </var>{' '}
              </div>
            </div>
            <div className="sub-header">Refund Details:</div>
            <div className="refund-details">
              <TextField
                label="Refund Amount"
                required={true}
                value={this.state.refund_amount}
                name="refund_amount"
                step="0.01"
                type="number"
                min="1"
                max={(order.purchase.total / 100).toFixed(2).toString()}
                onChange={(e: any) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="refund-actions">
              <Button
                onClick={refund}
                text="Refund"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    auctionState: store.auctionState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getAuctionTransactions,
  createToast,
  refundAuctionPayment,
  updateTransactionPayment,
  sendAuctionPaymentReceipt,
  getAuctionsExport,
  getAuctionsExportDaily,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuctionTransactions));
