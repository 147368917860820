import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { Config } from '@gigit/config';
import { IGroup, IQuery } from '@gigit/interfaces';
import { RouteComponentProps, Link, Redirect } from 'react-router-dom';
import { IAppState } from '../../store';
import { IGroupState } from '../../reducers/group';
import { IUserState } from '../../reducers/user';
import { IEventState } from '../../reducers/event';
import { getGroup, groupPledge, updateGroupError } from '../../actions/group';
import { createToast } from '../../actions/toaster';
import Share from '../../components/Share/Share';
import Modal from '../../components/Modal/Modal';
import Portrait from '../../components/Portrait/Portrait';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import {
  handleInputChange,
  replaceAtIndex,
  toastError,
  errorHelpers,
  setSEOMetatags,
} from '../../helpers';
import Dropdown from '../../components/Dropdown/Dropdown';
import Checkbox from '../../components/Checkbox/Checkbox';
import QuillTextEditor from '../../components/QuillTextEditor/QuillTextEditor';
import './Pledge.scss';
import DatePicker from '../../components/DatePicker/DatePicker';
import moment from 'moment';
import { DetailedToggle } from '../../components/DetailedToggle/DetailedToggle';
import TextArea from '../../components/TextArea/TextArea';
import {
  ISearchableDropdownListItem,
  SearchableDropdown,
} from '../../components/SearchableDropdown/SearchableDropdown';
import { IToast } from '../../interfaces';
import { uiConstants } from '../../constants/uiConstants';
import { groupRequestActions } from '../../requestActions';

const MAX_DONORS = 10;

interface CharityDonor {
  charityName: string;
  charityId: string;
  amount: number;
}

interface IFrequencyOption {
  label: string;
  value: string;
  installmentMultiplier: number;
}

interface IProps extends RouteComponentProps<any> {
  getGroup(handle: string): void;
  groupPledge(groupId: string, groupHandle: string, _payload: any, redirect: any): void;
  updateGroupError(error: string): void;
  createToast(toast: IToast): void;

  groupState: IGroupState;
  eventState: IEventState;
  userState: IUserState;
}

interface IState {
  formRef: any;
  sidebarRef: any;
  sidebarClass: string;
  email: string;
  phone: string;
  streetAddress: string;
  aptNum: string;
  city: string;
  province: string;
  country: string;
  postal: string;
  pledgeTotal: number;
  orgOrSchoolName: string;
  name: string;
  employeeNumber: string;
  donorOption: string;
  showShareModal: boolean;
  isDonorOption: boolean;
  pledgePeriod: string;
  frequencyOptions: Array<IFrequencyOption>;
  frequencyOption: string;
  startDate: any;
  installment: number;
  is_payroll_deduction: boolean | null;
  continue_perpetually: boolean | null;
  errors: { [index: string]: string };
  isValid: boolean;
  notes: string;
  charityGroupSearchList: IGroup[];
  selectedCharityGroup: ISearchableDropdownListItem | null;
  charityDonors: CharityDonor[];
  forwardPersonalInfo: boolean;
}

class Pledge extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      formRef: React.createRef(),
      sidebarRef: React.createRef(),
      sidebarClass: '',
      email: this.props.userState.user.email || '',
      phone: this.props.userState.user.phone || '',
      streetAddress: '',
      aptNum: '',
      city: '',
      province: '',
      country: 'Canada',
      postal: '',
      pledgeTotal: 0,
      orgOrSchoolName: '',
      name: '',
      employeeNumber: '',
      showShareModal: false,
      donorOption: '',
      isDonorOption: false,
      is_payroll_deduction: null,
      continue_perpetually: null,
      pledgePeriod: '',
      frequencyOptions: [
        { label: '', value: '', installmentMultiplier: 1 },
        { label: 'Single Payment', value: 'single', installmentMultiplier: 1 },
        { label: 'Weekly Payments', value: 'weekly', installmentMultiplier: 52 },
        { label: 'Bi-Weekly Payments', value: 'bi_weekly', installmentMultiplier: 26 },
        { label: 'Monthly Payments', value: 'monthly', installmentMultiplier: 12 },
        { label: 'Bi-Monthly Payments', value: 'bi_monthly', installmentMultiplier: 6 },
      ],
      frequencyOption: '',
      startDate: moment(),
      installment: 0,
      errors: {},
      isValid: false,
      notes: '',
      charityGroupSearchList: [],
      selectedCharityGroup: null,
      charityDonors: [],
      forwardPersonalInfo: false,
    };

    this.makePledge = this.makePledge.bind(this);
    this.updateStartDate = this.updateStartDate.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.props.updateGroupError('');
    this.props.getGroup(this.props.match.params.handle);

    this.validateInputs();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.groupState !== this.props.groupState && this.props.groupState) {
      setSEOMetatags({
        urlPath: `group/${this.props.groupState.group.handle}`,
        title: this.props.groupState.group.title,
        description: this.props.groupState.group.description,
        imageURL: this.props.groupState.group.profile_image_url,
        coverImageURL: this.props.groupState.group.cover_image_url,
      });
    }
    if (
      this.props.groupState.error !== prevProps.groupState.error &&
      this.props.groupState.error !== ''
    ) {
      const error = toastError(this.props.groupState.error, 'Create Pledge Error');
      this.props.createToast(error);
    }
  }

  handleScroll() {
    if (window.scrollY >= 40 && this.state.sidebarClass !== 'fixed') {
      this.setState({
        sidebarClass: 'fixed',
      });
    } else if (window.scrollY < 40 && this.state.sidebarClass !== '') {
      this.setState({
        sidebarClass: '',
      });
    }
  }

  makePledge() {
    this.props.updateGroupError('');

    const isRecurring = this.state.frequencyOption !== 'single';
    let names = this.parseName();
    let _payload = {
      donor: {
        first_name: names.first_name,
        last_name: names.last_name,
        email: this.state.email,
        phone: this.state.phone,
        // dob: "1990-01-01"
      },
      address: {
        line1: this.state.streetAddress,
        line2: this.state.aptNum,
        city: this.state.city,
        state: this.state.province,
        country: this.state.country,
        postal_code: this.state.postal,
      },
      pledge: {
        total_yearly_amount: this.state.pledgeTotal,
        organization: this.state.orgOrSchoolName,
        recurrence_type: this.state.frequencyOption,
        recurrence_start_date: isRecurring ? this.state.startDate : undefined,
        installment_amount: isRecurring ? this.state.installment : undefined,
        is_payroll_deduction: this.state.is_payroll_deduction,
        continue_perpetually: this.state.continue_perpetually,
        // Legacy donor option, remove maybe?
        donor_option: '',
        employee_number: this.state.employeeNumber || '',
        comments: this.state.notes,
        donor_options: this.state.isDonorOption
          ? this.state.charityDonors.map((donor) => ({
              charity_name: donor.charityName,
              charity_id: donor.charityId,
              total_yearly_amount: donor.amount,
              forward_personal_info: this.state.forwardPersonalInfo,
            }))
          : undefined,
      },
    };

    this.props.groupPledge(
      this.props.groupState.group.id,
      this.props.groupState.group.handle,
      _payload,
      this.props.history,
    );
  }

  updateStartDate(date: any) {
    this.setState({
      startDate: date,
    });
  }

  parseName() {
    let names = this.state.name.split(' ');
    if (names.length === 2) {
      return {
        first_name: names[0],
        last_name: names[1],
      };
    } else if (names.length > 2) {
      let last = names.slice(1).join(' ');
      return {
        first_name: names[0],
        last_name: last,
      };
    } else {
      return {
        first_name: names[0],
        last_name: '',
      };
    }
  }

  /** Validates all inputs and checks for errors. */
  validateInputs() {
    const newErrors: { [index: string]: string } = {};

    if (this.state.pledgeTotal <= 20) {
      newErrors['pledgeTotal'] = 'Pledge amount must be greater than $20';
    }

    if (this.state.continue_perpetually === null) {
      newErrors['continue_perpetually'] = 'Required';
    }

    if (this.state.is_payroll_deduction === null) {
      newErrors['is_payroll_deduction'] = 'Required';
    }

    const totalCharityDonations = this.state.charityDonors.reduce(
      (prev, cur) => cur.amount + prev,
      0,
    );
    if (totalCharityDonations > this.state.pledgeTotal) {
      newErrors['charityDonors'] =
        'The sum delegated to Charity Donors must not exceed total pledge amount';
    }

    // Validate that each donor amount
    for (var i = 0; i < this.state.charityDonors.length; i++) {
      const donor = this.state.charityDonors[i];
      if (donor.amount <= 0) {
        newErrors[`charityDonors[${i}].amount`] = 'Donor amount must be greater than 0';
      }
    }

    this.setState({
      errors: newErrors,
      isValid: Object.keys(newErrors).length === 0,
    });
  }

  changePledgeTotal = (event: ChangeEvent<HTMLInputElement>) => {
    const newPledgeTotal = event.target.value as unknown as number;
    this.setState(
      {
        pledgeTotal: newPledgeTotal,
        installment: this.calculateInstallment(newPledgeTotal, this.state.frequencyOption),
      },
      this.validateInputs,
    );
  };

  changeFrequency = (event: ChangeEvent<HTMLSelectElement>) => {
    const newFrequency = event.target.value;
    this.setState(
      {
        frequencyOption: newFrequency,
        installment: this.calculateInstallment(this.state.pledgeTotal, newFrequency),
      },
      this.validateInputs,
    );
  };

  changeInstallment = (event: ChangeEvent<HTMLInputElement>) => {
    const newInstallment = event.target.value as unknown as number;
    this.setState(
      {
        installment: newInstallment,
        pledgeTotal: this.calculatePledgeTotal(newInstallment, this.state.frequencyOption),
      },
      this.validateInputs,
    );
  };

  calculateInstallment(pledgeTotal: number, frequencyValue: string) {
    if (frequencyValue !== '') {
      const frequency = this.state.frequencyOptions.find(
        (option) => option.value === frequencyValue,
      );
      if (frequency) {
        return parseFloat((pledgeTotal / frequency.installmentMultiplier).toFixed(2));
      }
    }
    return 0;
  }

  calculatePledgeTotal(installment: number, frequencyValue: string) {
    if (frequencyValue !== '') {
      const frequency = this.state.frequencyOptions.find(
        (option) => option.value === frequencyValue,
      );
      if (frequency) {
        return parseFloat((installment * frequency.installmentMultiplier).toFixed(2));
      }
    }
    return 0;
  }

  /** Used to run this.validateInputs() after any input field is changed. */
  handleInputChange(
    event: ChangeEvent<HTMLInputElement>,
    self: any,
    prevent?: boolean,
    callback?: any,
  ) {
    handleInputChange(event, self, prevent, () => {
      if (callback) {
        callback();
      }

      this.validateInputs();
    });
  }

  onAddCharityClicked = () => {
    if (this.state.selectedCharityGroup) {
      this.setState(
        {
          selectedCharityGroup: null,
          charityDonors: [
            ...this.state.charityDonors,
            {
              charityId: this.state.selectedCharityGroup?.id,
              charityName: this.state.selectedCharityGroup?.label,
              amount: 0,
            },
          ],
        },
        this.validateInputs,
      );
    }
  };

  addEmptyDonor = () => {
    this.setState(
      {
        selectedCharityGroup: null,
        charityDonors: [
          ...this.state.charityDonors,
          {
            charityId: '',
            charityName: '',
            amount: 0,
          },
        ],
      },
      this.validateInputs,
    );
  };

  getCharityGroupSearchList(): ISearchableDropdownListItem[] {
    return (
      this.state.charityGroupSearchList
        .filter((item) => item.charity_id !== null && item.charity_id !== '')
        // Filter out charities that have already been added.
        .filter((item) => !this.state.charityDonors.find((cd) => cd.charityId === item.charity_id))
        .map((item) => ({
          id: item.charity_id || '',
          label: item.title,
          image: item.profile_image_url,
        }))
    );
  }

  async searchGroupsThatHaveCharityId(search: string) {
    try {
      const groups = await groupRequestActions.getGroups({
        search: search,
        charities: true,
        limit: '100',
      } as IQuery); // TODO: Find a cleaner solution to this than using `as IQuery`.

      this.setState({
        charityGroupSearchList: groups,
      });
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Search Charity Causes');
      this.props.createToast(toast);
    }
  }

  render() {
    if (
      this.props.groupState.group.status &&
      this.props.groupState.group.status.code === 'archived'
    ) {
      return <Redirect to="/not-found" />;
    } else {
      return (
        <div className="Pledge">
          <div className="back-splash">
            {this.props.groupState.group.cover_image_url !== '' && (
              <img
                alt="Cover"
                src={this.props.groupState.group.cover_image_url}
              />
            )}
            <div className="curve" />
          </div>
          <div className="Donate-content">
            <div className="sidebar">
              <div
                ref={this.state.sidebarRef}
                className={'sidebar-inner ' + this.state.sidebarClass}
              >
                {!this.props.groupState.isGroupLoading && (
                  <Portrait
                    currentImage={this.props.groupState.group.profile_image_url}
                    size={180}
                  />
                )}
                {!this.props.groupState.isGroupLoading && (
                  <div className="sidebar-content">
                    <div className="sidebar-info">
                      <div className="type group">
                        <i className="far fa-users" />
                        <span>{this.props.match.params.type}</span>
                      </div>
                      <h1 className="name">
                        <Link to={'/group/' + this.props.groupState.group.handle}>
                          {this.props.groupState.group.title}
                        </Link>
                      </h1>
                      <div className="handle">@{this.props.groupState.group.handle}</div>
                      {this.props.groupState.group.description &&
                        this.props.groupState.group.description !== '<p><br></p>' && (
                          <div className="description">
                            <QuillTextEditor
                              value={this.props.groupState.group.description}
                              preserveWhitespace={true}
                              readOnly={true}
                              theme={uiConstants.quill.readOnlyTheme}
                            />
                          </div>
                        )}
                    </div>
                    <div className="sidebar-actions">
                      <Button
                        onClick={() => {
                          this.setState({ showShareModal: true });
                        }}
                        text="Share"
                      />
                    </div>
                  </div>
                )}
                {this.props.groupState.isGroupLoading && (
                  <div className="load-wrap">
                    <Loader loading={this.props.groupState.isGroupLoading} />
                  </div>
                )}
              </div>
            </div>
            <div className="main">
              <div className="Donate-main-content">
                {!this.props.groupState.isGroupLoading && (
                  <div
                    ref={this.state.formRef}
                    className="inner-content"
                  >
                    <h1>Pledge</h1>
                    <div
                      className="make-pledge"
                      onClick={() =>
                        this.props.history.push(
                          `/group/${this.props.groupState.group.handle}/donate`,
                        )
                      }
                    >
                      <i className="fal fa-heart" />
                      <Link to={`/group/${this.props.groupState.group.handle}/donate`}>
                        Make a one-time or monthly donation
                      </Link>
                    </div>
                    <div className="dono-step step-1">
                      <h2>Pledge Information</h2>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.makePledge();
                        }}
                      >
                        <div className="details-row">
                          <div className="details-col">
                            <TextField
                              value={this.state.pledgeTotal}
                              error={this.state.errors['pledgeTotal']}
                              required
                              icon="fa fa-dollar-sign"
                              label="I pledge a total yearly gift of"
                              type="number"
                              step="any"
                              name="pledgeTotal"
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                this.changePledgeTotal(e)
                              }
                            />
                          </div>
                          <div className="details-col">
                            <Dropdown
                              required
                              label="To be given as"
                              value={this.state.frequencyOption}
                              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                                this.changeFrequency(e)
                              }
                              name="frequencyOption"
                              options={this.state.frequencyOptions}
                            />
                          </div>
                        </div>
                        {this.state.frequencyOption !== '' &&
                          this.state.frequencyOption !== 'single' && (
                            <div className="details-row">
                              <div className="details-col">
                                <TextField
                                  value={this.state.installment}
                                  required
                                  icon="fa fa-dollar-sign"
                                  label={`A ${this.state.frequencyOption.replace('_', '-')} gift of `}
                                  type="number"
                                  step="any"
                                  name="installment"
                                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    this.changeInstallment(e);
                                  }}
                                />
                              </div>
                              <div className="details-col date-row">
                                <i className="fal fa-calendar" />
                                <DatePicker
                                  name="startDate"
                                  label="Will be made starting on"
                                  date={this.state.startDate}
                                  onChange={this.updateStartDate}
                                />
                              </div>
                            </div>
                          )}
                        <div className="details-row controls">
                          <div className="is-payroll">
                            <DetailedToggle
                              label="Do you want this pledge to continue indefinitely?"
                              currentValue={this.state.continue_perpetually}
                              yesMessage="Yes, I would like my pledge to restart each year automatically."
                              noMessage="No, I would like my pledge to stop after 1 year."
                              onChange={(e) =>
                                this.setState(
                                  { continue_perpetually: e.newValue },
                                  this.validateInputs,
                                )
                              }
                              required={true}
                              error={this.state.errors['continue_perpetually']}
                            />
                          </div>
                          <div className="is-payroll">
                            <DetailedToggle
                              label="Is this a payroll deduction?"
                              currentValue={this.state.is_payroll_deduction}
                              yesMessage="Yes, I will gift through payroll deduction."
                              noMessage="No, I will gift directly."
                              onChange={(e) =>
                                this.setState(
                                  { is_payroll_deduction: e.newValue },
                                  this.validateInputs,
                                )
                              }
                              required={true}
                              error={this.state.errors['is_payroll_deduction']}
                            />
                          </div>
                        </div>
                        <h2>Details</h2>
                        <div className="details-row">
                          <div className="details-col">
                            <TextField
                              value={this.state.orgOrSchoolName}
                              required
                              label="Organization/School Name"
                              type="text"
                              name="orgOrSchoolName"
                              onChange={(e) => {
                                this.handleInputChange(e, this);
                              }}
                            />
                          </div>
                        </div>
                        <div className="details-row">
                          <div className="details-col">
                            <TextField
                              value={this.state.employeeNumber}
                              label="Employee Number"
                              type="text"
                              name="employeeNumber"
                              onChange={(e) => {
                                this.handleInputChange(e, this);
                              }}
                              optionalText={'(If Applicable)'}
                            />
                          </div>
                        </div>
                        <div className="details-row">
                          <div className="details-col">
                            <TextField
                              value={this.state.name}
                              required
                              label="Employee Name"
                              type="text"
                              name="name"
                              onChange={(e) => {
                                this.handleInputChange(e, this);
                              }}
                              optionalText={
                                '(Please enter your entire name as it appears on your payroll.)'
                              }
                            />
                          </div>
                        </div>
                        <div className="details-row email-phone">
                          <div className="details-col">
                            <TextField
                              required
                              label="Home Email"
                              type="email"
                              value={this.state.email}
                              name="email"
                              onChange={(e) => {
                                this.handleInputChange(e, this);
                              }}
                            />
                          </div>
                          <div className="details-col">
                            <TextField
                              required
                              label="Phone Number"
                              type="tel"
                              value={this.state.phone}
                              name="phone"
                              onChange={(e) => {
                                this.handleInputChange(e, this);
                              }}
                            />
                          </div>
                        </div>
                        <h2>Home Address</h2>
                        <div>
                          <div className="details-row">
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="streetAddress"
                                required
                                onChange={(e) => {
                                  this.handleInputChange(e, this);
                                }}
                                value={this.state.streetAddress}
                                label="Street Address"
                              />
                            </div>
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="aptNum"
                                onChange={(e) => {
                                  this.handleInputChange(e, this);
                                }}
                                value={this.state.aptNum}
                                label="Apartment / Suite #"
                              />
                            </div>
                          </div>
                          <div className="details-row">
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="city"
                                required
                                onChange={(e) => {
                                  this.handleInputChange(e, this);
                                }}
                                value={this.state.city}
                                label="City"
                              />
                            </div>
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="province"
                                required
                                onChange={(e) => {
                                  this.handleInputChange(e, this);
                                }}
                                value={this.state.province}
                                label="Province / State"
                              />
                            </div>
                          </div>
                          <div className="details-row">
                            <div className="details-col">
                              <Dropdown
                                label="Country"
                                value={this.state.country}
                                onChange={(e: any) => {
                                  this.handleInputChange(e, this);
                                }}
                                name="country"
                                options={[
                                  { label: 'Canada', value: 'Canada' },
                                  { label: 'United States of America', value: 'USA' },
                                ]}
                              />
                            </div>
                            <div className="details-col">
                              <TextField
                                type="text"
                                name="postal"
                                required
                                onChange={(e) => {
                                  this.handleInputChange(e, this);
                                }}
                                value={this.state.postal}
                                label="Postal Code / ZIP"
                              />
                            </div>
                          </div>
                        </div>
                        <h2>Additional Details</h2>
                        <TextArea
                          name="notes"
                          type="text"
                          label="Notes / Comments"
                          value={this.state.notes}
                          onChange={(e: any) => this.handleInputChange(e, this)}
                        />

                        <div className="details-row details-margin-top">
                          <Checkbox
                            name="isDonorOption"
                            onChange={(e) => {
                              this.handleInputChange(e, this);
                            }}
                            label="Donor Option (Add up to 10 charities)"
                            checked={this.state.isDonorOption}
                            value={'Donor Option'}
                            color="work"
                          />
                        </div>

                        {this.state.isDonorOption && (
                          <>
                            <div className="details-row">
                              <div className="details-col dedicate donor-option">
                                <SearchableDropdown
                                  specialOption={{
                                    label: 'Manually Add',
                                    image: 'far fa-plus-circle',
                                    onClick: this.addEmptyDonor,
                                    isEnabled: this.state.charityDonors.length < MAX_DONORS,
                                  }}
                                  label="I wish to direct my donation using Donor Option to the following charity:"
                                  list={this.getCharityGroupSearchList()}
                                  onSearch={(newSearch) =>
                                    this.searchGroupsThatHaveCharityId(newSearch)
                                  }
                                  selectedItem={this.state.selectedCharityGroup}
                                  setSelectedItem={(item) =>
                                    this.setState({ selectedCharityGroup: item })
                                  }
                                />
                              </div>
                              <div className="fixed-col">
                                <Button
                                  isDisabled={
                                    this.state.selectedCharityGroup === null ||
                                    this.state.charityDonors.length === MAX_DONORS
                                  }
                                  type="button"
                                  icon="far fa-plus"
                                  text="Add"
                                  onClick={this.onAddCharityClicked}
                                />
                              </div>
                            </div>
                            {this.state.charityDonors.map((charityDonation, index) =>
                              this.renderCharityDonation(charityDonation, index),
                            )}

                            <div className="details-row">
                              <div className="details-col">
                                <TextField
                                  label="Amount Remaining"
                                  value={
                                    this.state.pledgeTotal -
                                    this.state.charityDonors.reduce(
                                      (prev, cur) => cur.amount + prev,
                                      0,
                                    )
                                  }
                                  name="charityRemaining"
                                  type="number"
                                  disabled={true}
                                  onChange={() => {}}
                                />
                              </div>
                            </div>
                            {this.state.errors['charityDonors'] && (
                              <span className="error_text">
                                {this.state.errors['charityDonors']}
                              </span>
                            )}

                            <div className="details-row details-margin-top">
                              <Checkbox
                                name="forwardPersonalInfo"
                                onChange={(e) => {
                                  this.handleInputChange(e, this);
                                }}
                                label="Yes, I agree to sharing this information with my chosen charity(s)."
                                checked={this.state.forwardPersonalInfo}
                                value={'Forward Personal Info'}
                                color="work"
                              />
                            </div>
                          </>
                        )}
                        <div className="step-actions">
                          <Button
                            isDisabled={!this.state.isValid}
                            type="submit"
                            text="Make Pledge"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                {this.props.groupState.isGroupLoading && (
                  <div className="load-wrap">
                    <Loader loading={this.props.groupState.isGroupLoading} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal
            class="share-modal"
            show={this.state.showShareModal}
            onClose={() => {
              this.setState({ showShareModal: false });
            }}
          >
            <Share
              {...this.props}
              url={
                this.props.match.params.type === 'group'
                  ? Config.web.REACT_APP_BASE_URL +
                    '/group/' +
                    this.props.match.params.handle +
                    '/donate'
                  : Config.web.REACT_APP_BASE_URL +
                    '/event/' +
                    this.props.match.params.handle +
                    '/donate'
              }
            />
          </Modal>
        </div>
      );
    }
  }

  renderCharityDonation = (charityDonation: CharityDonor, index: number) => {
    const updateCharityDonation = (update: Partial<CharityDonor>) => {
      const updatedCharityDonation = { ...charityDonation };

      Object.assign(updatedCharityDonation, update);

      this.setState(
        {
          charityDonors: replaceAtIndex(this.state.charityDonors, index, updatedCharityDonation),
        },
        this.validateInputs,
      );
    };

    const removeCharityDonation = () => {
      let newArray = [...this.state.charityDonors];
      newArray.splice(index, 1);

      this.setState(
        {
          charityDonors: newArray,
        },
        this.validateInputs,
      );
    };

    return (
      <div
        className="details-row charityDonation"
        key={index}
      >
        <div className="details-col">
          <TextField
            label="Charity"
            value={charityDonation.charityName}
            name="charityName"
            type="text"
            required
            onChange={(e) => updateCharityDonation({ charityName: e.target.value })}
          />
        </div>
        <div className="details-col charityId">
          <TextField
            helper="Registerd charity id / numbers are listed on Canada.ca"
            label="Charity ID"
            value={charityDonation.charityId}
            name="charityId"
            type="text"
            required
            onChange={(e) => updateCharityDonation({ charityId: e.target.value })}
          />
        </div>
        <div className="details-col">
          <TextField
            label="Amount"
            value={charityDonation.amount}
            name="charityAmount"
            type="number"
            required
            error={this.state.errors[`charityDonors[${index}].amount`]}
            onChange={(e) => updateCharityDonation({ amount: parseInt(e.target.value) })}
          />
        </div>
        <div className="details-col">
          <Button
            type="button"
            icon="fal fa-trash-alt"
            text=""
            onClick={removeCharityDonation}
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    userState: store.userState,
  };
};

const mapDispatchToProps = {
  getGroup,
  groupPledge,
  updateGroupError,
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pledge);
