import React, { Component } from 'react';
import { combineClassNames } from '../../../helpers';
import { localizeHelpers } from '../../../localizeHelpers';
import Button from '../../Button/Button';

import './ProfileFloatingActions.scss';

interface IProps {
  className?: string;

  showChat: boolean;
  extraActions?: IFloatingAction[];

  onShareClick?: () => void;
  onChatClick?: () => void;
}

export interface IFloatingAction {
  icon: string;
  onClick: () => void;
  className?: string;
  title?: string;
}

/** Renders actions that can be displayed within a Profile page. */
export class ProfileFloatingActions extends Component<IProps> {
  render() {
    let actions: IFloatingAction[] = [];

    if (this.props.onChatClick && this.props.showChat) {
      actions.push({
        icon: 'fal fa-comment-dots',
        title: localizeHelpers.translate('Chat'),
        onClick: this.props.onChatClick,
      });
    }

    if (this.props.onShareClick) {
      actions.push({
        icon: 'fas fa-share-alt',
        title: localizeHelpers.translate('Share'),
        onClick: this.props.onShareClick,
      });
    }

    if (this.props.extraActions) {
      actions.push(...this.props.extraActions);
    }

    return (
      <div className={combineClassNames('ProfileFloatingActions', this.props.className)}>
        {actions.map((action, index) => (
          <Button
            key={index}
            title={localizeHelpers.translate(action.title)}
            text=""
            icon={action.icon}
            type="button"
            className={combineClassNames('action-button', action.className)}
            onClick={action.onClick}
          />
        ))}
      </div>
    );
  }
}
