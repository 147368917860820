import { Constants } from '@gigit/constants';
import {
  IEvent,
  IFollowingObject,
  IHub,
  IGroup,
  IInviteSummary,
  IUserMyPageItem,
  IEventSummaryFE,
  IEventIndividual,
  IEventTeam,
} from '@gigit/interfaces';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalizeString } from '../../../../helpers';
import { useOnClickOutside } from '../../../../hooks/useClickOutside';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import Button from '../../../Button/Button';
import {
  CardCommonEvent,
  EStyleTypeCardCommonEvent,
  EViewTypeCardCommonEvent,
} from '../../../../components/CardCommonEvent/CardCommonEvent';
import {
  CardCommonHub,
  EStyleTypeCardCommonHub,
  EViewTypeCardCommonHub,
} from '../../../../components/CardCommonHub/CardCommonHub';
import {
  CardCommonGroup,
  EStyleTypeCardCommonGroup,
  EViewTypeCardCommonGroup,
} from '../../../../components/CardCommonGroup/CardCommonGroup';
import Portrait from '../../../Portrait/Portrait';
import VolunteerCard, {
  EStyleTypeVolunteerCard,
  EViewTypeVolunteerCard,
} from '../../../VolunteerCard/VolunteerCard';
import { hubRequestActions, groupRequestActions } from '../../../../requestActions';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../selectors/user';
import { IAppState } from '../../../../store';
import useToastDispatcher from '../../../../hooks/useToaster';
import { uiConstants } from '../../../../constants';

interface IPropsItemGrid {
  keyValue: string;
  myPageItem: IUserMyPageItem;
  locale: string;
  pageType: string;
  deleteItem: (item: IUserMyPageItem) => void;
  shareItem?: (item: IUserMyPageItem) => void;
}

export const MyPagesItemGrid: React.FC<IPropsItemGrid> = (props) => {
  const menuRef = useRef<any>();
  const menuButtonRef = useRef<any>();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  useOnClickOutside(
    menuRef,
    () => {
      setShowMenu(false);
    },
    [menuButtonRef],
  );

  function displayDeleteOption(item: IUserMyPageItem) {
    if (!item.canEdit) return false;

    switch (item.item_type) {
      case 'group':
      case 'event':
      case 'volunteer gig':
      case 'paid gig':
      case 'individual':
        return true;
      default:
        return false;
    }
  }

  switch (props.pageType) {
    case 'group_request':
      // case "group":
      // case "event":
      return (
        <div className={'MyPagesItemGrid-card-' + props.pageType}>
          <Link to={props.myPageItem.url!}>
            <div
              className="card-top"
              style={{ backgroundImage: `url(${props.myPageItem.cover_image_url})` }}
            >
              <div className="card-image">
                <Portrait
                  size={36}
                  source={props.myPageItem.profile_image_url}
                />
              </div>
            </div>
          </Link>
          <div className="card-bottom">
            <div className="card-info-wrap">
              {props.pageType !== 'group_request' && (
                <>
                  <span
                    ref={menuButtonRef}
                    className={`card-menu-icon ${showMenu ? 'show' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowMenu(!showMenu);
                    }}
                  >
                    <i className="fal fa-ellipsis-v" />
                  </span>

                  <ul
                    ref={menuRef}
                    className={`card-menu ${showMenu ? 'show' : ''}`}
                  >
                    <Link to={props.myPageItem.url!}>
                      <li className="view">View</li>
                    </Link>
                    {(props.myPageItem.canManage ||
                      props.pageType === Constants.object_type.team ||
                      props.pageType === 'individual') && (
                      <Link
                        to={
                          props.myPageItem.url +
                          (props.pageType === 'team' || props.pageType === 'individual'
                            ? '/manage'
                            : '/admin')
                        }
                      >
                        <li className="manage">Manage</li>
                      </Link>
                    )}
                    {displayDeleteOption(props.myPageItem) && (
                      <li
                        className="delete"
                        onClick={(e) => {
                          props.deleteItem(props.myPageItem);
                        }}
                      >
                        Delete
                      </li>
                    )}
                  </ul>
                </>
              )}

              <div
                className="name"
                notranslate="yes"
              >
                {props.pageType !== 'group_request' && (
                  <Link to={props.myPageItem.url!}>{props.myPageItem.title}</Link>
                )}

                {props.pageType === 'group_request' && <span>{props.myPageItem.title}</span>}
              </div>
              <div className="created-date">
                Created at{' '}
                <var data-var="created_at">
                  {localizeHelpers.formatDate(
                    props.myPageItem.created_at,
                    LocaleDateFormats.ll,
                    props.locale,
                  )}
                </var>
              </div>
              {/* {
                                props.pageType === "hub" &&
                                <span className={`state-label active`}>{props.myPageItem.status}</span>
                            } */}
              {
                // props.pageType !== "hub" &&
                props.pageType !== 'group_request' && (
                  <span
                    className={`state-label ${props.myPageItem.item_type.includes('gig') && props.myPageItem.status === 'active' ? 'published' : (props.myPageItem.status as string)}`}
                  >
                    {capitalizeString(props.myPageItem.status as string)}
                  </span>
                )
              }
              {props.pageType === 'group_request' && (
                <span
                  className={`state-label ${props.myPageItem.item_type.includes('gig') && props.myPageItem.status === 'active' ? 'published' : props.myPageItem.status}`}
                >
                  {capitalizeString(props.myPageItem.status)}
                </span>
              )}
              {/* // props.pageType !== "hub" && */}
              <span className="page-type">
                {props.myPageItem.item_type === 'event' && 'Event'}
                {props.myPageItem.item_type === 'individual' && 'Individual'}
                {props.myPageItem.item_type === 'team' && 'Team'}
              </span>
              {/* <div className='focus-area-label'>{/*TODO Add focus areas? </div> */}
            </div>
          </div>
        </div>
      );
    case 'group':
      return (
        <CardCommonGroup
          group={props.myPageItem as unknown as IGroup}
          viewType={EViewTypeCardCommonGroup.GRID}
          styleType={EStyleTypeCardCommonGroup.MANAGE}
          locale={props.locale}
          deleteItem={() => props.deleteItem(props.myPageItem)}
          canEdit={props.myPageItem.canEdit}
          canManage={props.myPageItem.canManage}
          myPagesCard={true}
        />
      );

    case 'hub':
      return (
        <CardCommonHub
          hub={props.myPageItem as unknown as IHub}
          viewType={EViewTypeCardCommonHub.GRID}
          styleType={EStyleTypeCardCommonHub.MANAGE}
          locale={props.locale}
          deleteItem={() => props.deleteItem(props.myPageItem)}
          canEdit={props.myPageItem.canEdit}
          canManage={props.myPageItem.canManage}
        />
      );
    case 'individual':
    case 'team':
    case 'event':
      return (
        <CardCommonEvent
          event={props.myPageItem as unknown as IEventSummaryFE}
          viewType={EViewTypeCardCommonEvent.GRID}
          styleType={EStyleTypeCardCommonEvent.MANAGE}
          locale={props.locale}
          deleteItem={() => props.deleteItem(props.myPageItem)}
          canEdit={props.myPageItem.canEdit}
          canManage={props.myPageItem.canManage}
          groupTitle={props.myPageItem.group_title}
          groupHandle={props.myPageItem.group_handle}
        />
      );
    case 'volunteer gig':
    case 'paid gig':
      return (
        <VolunteerCard
          viewType={EViewTypeVolunteerCard.GRID}
          styleType={EStyleTypeVolunteerCard.MANAGE}
          gigBasic={{
            profile_image_url: props.myPageItem.profile_image_url,
            title: props.myPageItem.title,
            locations: props?.myPageItem?.primary_location
              ? [props?.myPageItem?.primary_location]
              : [],
            start_date: props.myPageItem.start_date || '',
            owner_title: props.myPageItem.owner_org_title || '',
            owner_type: props.myPageItem.owner_org_type || '',
            owner_handle: props.myPageItem.owner_org_handle || '',
            handle: props.myPageItem.handle || '',
            status: props.myPageItem.status,
            item_type: props.myPageItem.item_type,
            id: props.myPageItem.id,
            numOfApplicants: props.myPageItem.number_of_applicants,
            is_volunteer_match: props.myPageItem.is_volunteer_match,
          }}
          deleteItem={() => props.deleteItem(props.myPageItem)}
        />
      );
    default:
      return <div />;
  }
};

interface IPropsPagesFollowedGrid {
  keyValue: string;
  pagesFollowed: IFollowingObject;
  locale: string;
  refreshPage?: () => void;
}

export const MyPagesFollowedGrid: React.FC<IPropsPagesFollowedGrid> = (props) => {
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const unfollowItem = async () => {
    const itemType = props.pagesFollowed.object_type;

    try {
      if (itemType === 'group') {
        await groupRequestActions.unfollowGroup(props.pagesFollowed.object_id);
        dispatchToastSuccess('Successfully unfollowed Cause', 'Unfollow Cause');

        props.refreshPage && props.refreshPage();
      } else {
        await hubRequestActions.userUnfollowHub(user.id!, props.pagesFollowed.object_id);
        dispatchToastSuccess('Successfully unfollowed Hub', 'Unfollow Hub');

        props.refreshPage && props.refreshPage();
      }
    } catch (error) {
      dispatchToastError(error, `Unfollow ${itemType}`);
    }
  };

  //Can only currently follow groups, design shows following users as well
  switch (props.pagesFollowed.object_type) {
    case 'group':
      return (
        <div className="MyPagesFollowedGrid-card">
          <Link to={`${props.pagesFollowed.object_type}/${props.pagesFollowed.title}`}>
            <div
              className="card-top"
              style={{ backgroundImage: `url(${props.pagesFollowed.cover_image_url})` }}
            >
              <div className="card-image">
                <Portrait
                  size={36}
                  source={props.pagesFollowed.profile_image_url}
                />
              </div>
            </div>
          </Link>
          <div className="card-bottom">
            <div className="card-info-wrap">
              <Link to={`${props.pagesFollowed.object_type}/${props.pagesFollowed.handle}`}>
                <div
                  className="name"
                  notranslate="yes"
                >
                  {props.pagesFollowed.title}
                </div>
              </Link>
              <div className="card-stats">
                {/* TODO: Add statistics to the card as per design */}
              </div>
            </div>
            <button
              className="unfollow-btn"
              onClick={() => unfollowItem()}
            >
              <i className={'far fa-level-down-alt'} />
              {'Unfollow'}
            </button>
          </div>
        </div>
      );
    case 'hub':
      return (
        <div className="MyPagesFollowedGrid-card">
          <Link to={`company/${props.pagesFollowed.handle}`}>
            <div
              className="card-top"
              style={{ backgroundImage: `url(${props.pagesFollowed.banner_image})` }}
            >
              <div className="card-image">
                <Portrait
                  size={36}
                  source={props.pagesFollowed.profile_image_url}
                />
              </div>
            </div>
          </Link>
          <div className="card-bottom">
            <div className="card-info-wrap">
              <Link to={`company/${props.pagesFollowed.handle}`}>
                <div
                  className="name"
                  notranslate="yes"
                >
                  {props.pagesFollowed.title}
                </div>
              </Link>

              <div className="card-stats">
                {/* TODO: Add statistics to the card as per design */}
              </div>
            </div>
            <button
              className="unfollow-btn"
              onClick={() => unfollowItem()}
            >
              <i className={'far fa-level-down-alt'} />
              {'Unfollow'}
            </button>
          </div>
        </div>
      );
    default:
      return <div></div>;
  }
};

interface IPropsInvitesGrid {
  keyValue: string;
  invite: IInviteSummary;
  locale: string;
  handleAccept: (invite: IInviteSummary) => void;
  handleReject: (invite: IInviteSummary) => void;
}

export const MyInvitesGrid: React.FC<IPropsInvitesGrid> = (props) => {
  return (
    <div className="MyInvitesGrid-card">
      <div
        className="card-top"
        style={{ backgroundImage: `url(${props.invite.object.cover_image_url})` }}
      >
        <div className="card-image">
          <Portrait
            size={36}
            source={props.invite.object.profile_image_url}
          />
        </div>
      </div>
      <div className="card-bottom">
        <div className="card-info-wrap">
          <div
            notranslate="yes"
            className="name"
          >
            {props.invite.object.title}
          </div>
          <div className="card-stats">
            <span>Sent </span>
            <span notranslate="yes">{moment(props.invite.created_at).format('MMM DD, YYYY')}</span>
          </div>
          <div className="invite-actions">
            <Button onClick={() => props.handleAccept(props.invite)}>Accept</Button>
            <Button
              onClick={() => props.handleReject(props.invite)}
              className="button-alt"
            >
              Decline
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IPropsItemList {
  keyValue: string;
  myPageItem: IUserMyPageItem;
  locale: string;
  deleteItem: (item: IUserMyPageItem) => void;
  shareItem?: (item: IUserMyPageItem) => void;
}

export const MyPagesItemList: React.FC<IPropsItemList> = (props) => {
  const menuRef = useRef<any>();
  const menuButtonRef = useRef<any>();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  useOnClickOutside(
    menuRef,
    () => {
      setShowMenu(false);
    },
    [menuButtonRef],
  );

  function displayDeleteOption(item: IUserMyPageItem) {
    if (!item.canEdit) return false;

    switch (item.item_type) {
      case 'group':
      case 'event':
      case 'volunteer gig':
      case 'paid gig':
      case 'individual':
        return true;
      default:
        return false;
    }
  }

  switch (props.myPageItem.item_type) {
    case 'group':
    case 'individual':
    case 'team':
      // case 'event':
      return (
        <div className={'MyPagesItemList-card-' + props.myPageItem.item_type}>
          <div className="card-left">
            <div
              className="card-image"
              style={{ backgroundImage: `url(${props.myPageItem.cover_image_url})` }}
            >
              <Portrait
                size={36}
                source={props.myPageItem.profile_image_url}
              />
            </div>
          </div>
          <span className="card-right">
            <div className="card-right-top">
              <div className="name-wrap">
                <div
                  className="name"
                  notranslate="yes"
                >
                  <Link to={props.myPageItem.url!}>{props.myPageItem.title}</Link>
                </div>
                <span
                  ref={menuButtonRef}
                  className={`card-menu-icon ${showMenu ? 'show' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowMenu(!showMenu);
                  }}
                >
                  <i className="fal fa-ellipsis-v" />
                </span>
                <ul
                  ref={menuRef}
                  className={`card-menu ${showMenu ? 'show' : ''}`}
                >
                  <Link to={props.myPageItem.url!}>
                    <li className="view">View</li>
                  </Link>
                  {(props.myPageItem.canManage ||
                    props.myPageItem.item_type === Constants.object_type.team ||
                    props.myPageItem.item_type === 'individual') && (
                    <Link
                      to={
                        props.myPageItem.url +
                        (props.myPageItem.item_type === 'team' ||
                        props.myPageItem.item_type === 'individual'
                          ? '/manage'
                          : '/admin')
                      }
                    >
                      <li className="manage">Manage</li>
                    </Link>
                  )}
                  {displayDeleteOption(props.myPageItem) && (
                    <li
                      className="delete"
                      onClick={() => {
                        props.deleteItem(props.myPageItem);
                      }}
                    >
                      Delete
                    </li>
                  )}
                </ul>
              </div>
              <div className="created-date">
                Created on{' '}
                <var data-var="created_at">
                  {localizeHelpers.formatDate(
                    props.myPageItem.created_at,
                    LocaleDateFormats.ll,
                    props.locale,
                  )}
                </var>
              </div>
            </div>
            <div className="card-right-bottom">
              <span
                className={`state-label ${props.myPageItem.item_type.includes('gig') && props.myPageItem.status === 'active' ? 'published' : (props.myPageItem.status as string)}`}
              >
                {capitalizeString(props.myPageItem.status as string)}
              </span>
              <span className="page-type">
                {/* {props.myPageItem.item_type === 'event' &&
                        "Event"
                    } */}
                {props.myPageItem.item_type === 'individual' && 'Individual'}
                {props.myPageItem.item_type === 'team' && 'Team'}
              </span>
            </div>
          </span>
        </div>
      );
    case 'volunteer gig':
    case 'paid gig':
      return (
        <VolunteerCard
          viewType={EViewTypeVolunteerCard.LIST}
          styleType={EStyleTypeVolunteerCard.MANAGE}
          gigBasic={{
            profile_image_url: props.myPageItem.profile_image_url,
            title: props.myPageItem.title,
            locations: props?.myPageItem?.primary_location
              ? [props?.myPageItem?.primary_location]
              : [],
            start_date: props.myPageItem.start_date || '',
            owner_title: props.myPageItem.owner_org_title || '',
            owner_type: props.myPageItem.owner_org_type || '',
            owner_handle: props.myPageItem.owner_org_handle || '',
            handle: props.myPageItem.handle || '',
            status: props.myPageItem.status,
            item_type: props.myPageItem.item_type,
            id: props.myPageItem.id,
            numOfApplicants: props.myPageItem.number_of_applicants,
            is_volunteer_match: props.myPageItem.is_volunteer_match,
          }}
          deleteItem={() => props.deleteItem(props.myPageItem)}
        />
      );
    case 'hub':
      return (
        <CardCommonHub
          hub={props.myPageItem as unknown as IHub}
          viewType={EViewTypeCardCommonHub.LIST}
          styleType={EStyleTypeCardCommonHub.MANAGE}
          locale={props.locale}
          deleteItem={() => props.deleteItem(props.myPageItem)}
          canEdit={props.myPageItem.canEdit}
          canManage={props.myPageItem.canManage}
          getIsFollowing={false}
        />
      );
    case 'event':
      return (
        <CardCommonEvent
          event={props.myPageItem as unknown as IEventSummaryFE}
          viewType={EViewTypeCardCommonEvent.LIST}
          styleType={EStyleTypeCardCommonEvent.MANAGE}
          locale={props.locale}
          deleteItem={() => props.deleteItem(props.myPageItem)}
          canEdit={props.myPageItem.canEdit}
          canManage={props.myPageItem.canManage}
          groupTitle={props.myPageItem.group_title}
          groupHandle={props.myPageItem.group_handle}
        />
      );
    default:
      return <div>Unknown Item Type</div>;
  }
};
