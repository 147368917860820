import axios from 'axios';
import {
  IAddress,
  IAddressBase,
  IEventSummaryFE,
  IEventIndividual,
  IEventTeam,
  IPage,
  IPageCreateParamsFE,
  IPageFE,
  IQuery,
  IUserRoleJoin,
  IUserRole,
  ITransactionSummary,
  ITransactionItemSummary,
} from '@gigit/interfaces';
import { swapRouteParams, routes } from '../helpers';

export namespace eventRequestActions {
  export async function getEventByHandleOrId(handleOrId: string): Promise<IEventSummaryFE> {
    const response = await axios.get<IEventSummaryFE>(
      swapRouteParams(routes.GET_EVENT, { handleOrId: handleOrId }),
    );
    return response.data;
  }

  export async function createEvent(
    groupId: string,
    event: Partial<IEventSummaryFE>,
  ): Promise<IEventSummaryFE> {
    const response = await axios.post(swapRouteParams(routes.CREATE_EVENT, { groupId }), event);
    return response.data;
  }

  export async function getPages(eventId: string) {
    const results = await axios.get<IPage[]>(swapRouteParams(routes.GET_EVENT_PAGES, { eventId }));
    return results.data;
  }

  export async function createHubEvent(
    id: string,
    event: Partial<IEventSummaryFE>,
  ): Promise<IEventSummaryFE> {
    const response = await axios.post(swapRouteParams(routes.CREATE_HUB_EVENT, { id }), event);
    return response.data;
  }

  export async function createOnBehalfOfEvent(
    groupId: string,
    event: Partial<IEventSummaryFE>,
  ): Promise<IEventSummaryFE> {
    const response = await axios.post(swapRouteParams(routes.CREATE_OBO_EVENT, { groupId }), event);
    return response.data;
  }

  export async function getEventTeams(eventId: string, queryParams: IQuery): Promise<IEventTeam[]> {
    const response = await axios.get<IEventTeam[]>(
      swapRouteParams(routes.GET_EVENT_TEAMS, { eventId }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function updateEvent(
    eventId: string,
    payload: Partial<IEventSummaryFE>,
  ): Promise<IEventSummaryFE> {
    const response = await axios.put<IEventSummaryFE>(
      swapRouteParams(routes.UPDATE_EVENT, { eventId }),
      payload,
    );
    return response.data;
  }

  export async function getEventIndividuals(
    eventId: string,
    queryParams: IQuery,
  ): Promise<IEventIndividual[]> {
    const response = await axios.get<IEventIndividual[]>(
      swapRouteParams(routes.GET_EVENT_INDIVIDUALS, { eventId }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getEventPurchases(eventId: string, query?: URLSearchParams) {
    const response = await axios.get<ITransactionSummary[]>(
      swapRouteParams(routes.EVENT_PURCHASE, { eventId }),
      { params: query },
    );
    return response.data;
  }

  export async function getEventPurchaseItems(eventId: string, transaction_id: string) {
    const response = await axios.get<ITransactionItemSummary[]>(
      swapRouteParams(routes.GET_PURCHASE_EVENT_ITEMS, { eventId, transaction_id }),
    );
    return response.data;
  }

  export async function createEventPage(
    eventId: string,
    page: IPageCreateParamsFE,
  ): Promise<IPageFE> {
    const response = await axios.post(swapRouteParams(routes.CREATE_EVENT_PAGE, { eventId }), page);
    return response.data;
  }

  export async function createEventLocation(
    eventId: string,
    location: IAddressBase,
  ): Promise<IAddress> {
    const response = await axios.post(
      swapRouteParams(routes.CREATE_EVENT_LOCATION, { eventId: eventId }),
      location,
    );
    return response.data;
  }
  export async function getUserRole(eventId: string): Promise<IUserRoleJoin> {
    const response = await axios.get<IUserRoleJoin>(
      swapRouteParams(routes.GET_CURRENT_USER_EVENT_ROLE, { eventId }),
    );
    return response.data;
  }

  export async function changeEventStatus(
    eventId: string,
    status: string,
  ): Promise<IEventSummaryFE> {
    const response = await axios.post(
      swapRouteParams(routes.CHANGE_EVENT_STATUS, { eventId: eventId }),
      { status: status },
    );
    return response.data;
  }

  export async function getEventMembers(id: string, query?: URLSearchParams): Promise<IUserRole[]> {
    const response = await axios.get(swapRouteParams(routes.GET_EVENT_MEMBERS, { eventId: id }), {
      params: query,
    });
    return response.data;
  }
}
