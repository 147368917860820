import React, { ChangeEvent, useEffect, useState } from 'react';
import Modal from '../../../Modal/Modal';
import { IAuctionItemSummary, IGroup, IHub } from '@gigit/interfaces';
import TextField from '../../../TextField/TextField';
import './HubAuctionSettingsModal.scss';
import Checkbox from '../../../Checkbox/Checkbox';
import { hubRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import { formatQuery, typeHelpers } from '../../../../helpers';
import Button from '../../../Button/Button';
import { IActiveFilter } from '../../../../interfaces';
import HubAuctionSingleItem from '../HubAuctionSingleItem/HubAuctionSingleItem';
import AuctionItemForm from '../../../AuctionItemForm/AuctionItemForm';
import { Constants } from '@gigit/constants';

interface IProps {
  hub: IHub | null;
  onCloseSettingsModal(): void;
  showSettingsModal: boolean;
}

const HubAuctionSettingsModal: React.FC<IProps> = (props) => {
  const [auctionSettingsFormAuctionTitle, setAuctionSettingsFormAuctionTitle] =
    useState<string>('Auction');
  const [auctionSettingsFormSortBy, setAuctionSettingsFormSortBy] = useState<string>('Newest');
  const [auctionSettingsFormCarouselPages, setAuctionSettingsFormCarouselPages] =
    useState<number>(5);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [searchValuePartners, setSearchValuePartners] = useState<string>('');
  const [searchDebouncePartners, setSearchDebouncePartners] =
    useState<ReturnType<typeof setTimeout>>();
  const [selectedAllGroups, setSelectedAllGroups] = useState<boolean>(false);
  const [partners, setPartners] = useState<IGroup[]>([]);
  const [allGroupsSortByPartners, setAllGroupsSortByPartners] = useState<'asc' | 'desc'>('asc');
  const [auctionItems, setAuctionItems] = useState<IAuctionItemSummary[]>([]);
  const [selectedPartners, setSelectedPartners] = useState<IGroup[]>([]);
  const [searchValueHubItems, setSearchValueHubItems] = useState<string>('');
  const [searchDebounceHubItems, setSearchDebounceHubItems] =
    useState<ReturnType<typeof setTimeout>>();
  const [showAddHubItemModal, setShowAddHubItemModal] = useState<boolean>(false);
  const [activeAuctionItem, setActiveAuctionItem] = useState<IAuctionItemSummary | null>(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [auctionItemToDelete, setAuctionItemToDelete] = useState<IAuctionItemSummary | null>(null);

  const { dispatchToastError } = useToastDispatcher();
  const formFields = [
    {
      label: 'Auction Title',
      type: 'input',
      name: 'auction_title',
      value: auctionSettingsFormAuctionTitle,
      onChange: (v) => setAuctionSettingsFormAuctionTitle(v.target.value),
    },
    {
      label: 'Sort Component Items by',
      type: 'input',
      name: 'sort_by',
      value: auctionSettingsFormSortBy,
      onChange: (v) => setAuctionSettingsFormSortBy(v.target.value),
    },
    {
      label: 'Carousel Pages',
      type: 'input',
      name: 'carousel_pages',
      value: auctionSettingsFormCarouselPages,
      onChange: (v) => setAuctionSettingsFormCarouselPages(+v.target.value),
    },
  ] as {
    label: string;
    type: string;
    name: string;
    value: string;
    onChange(v: ChangeEvent<HTMLInputElement>): void;
  }[];

  function isPartnersItemsVisible(): boolean {
    // TODO: missing BE
    return true;
  }

  function togglePartnersItemsVisible() {
    // TODO: missing BE
  }

  function isPartnerSelected(partner: IGroup): boolean {
    return selectedPartners.map((tmpPartner) => tmpPartner.id).indexOf(partner.id) !== -1;
  }

  function getHubPartners() {
    if (props?.hub?.id) {
      hubRequestActions
        .getHubPartners(
          props.hub.id,
          formatQuery({
            sort: [{ order: allGroupsSortByPartners, id: 'title' }],
          }),
        )
        .then((partners) => {
          setPartners(partners);
        })
        .catch((error) => dispatchToastError(error, 'Get Hub Partners'));
    }
  }

  function toggleSelectedPartner(partner: IGroup) {
    if (isPartnerSelected(partner)) {
      setSelectedPartners(selectedPartners.filter((tmpPartner) => tmpPartner.id !== partner.id));
    } else {
      setSelectedPartners([...selectedPartners, partner]);
    }
  }

  function getAdminHubItems() {
    if (props?.hub?.id) {
      let filters: IActiveFilter[] = [];

      if (searchValueHubItems !== '') {
        filters.push({
          type: 'text',
          value: searchValueHubItems,
          id: 'name',
          label: 'name',
        });
      }

      hubRequestActions
        .getHubAuctionAdminItems(props.hub.id, formatQuery({ filters }))
        .then((res) => setAuctionItems(res))
        .catch((error) => dispatchToastError(error, 'Get Admin Hub Items'));
    }
  }

  function deleteAuctionItem() {
    if (props?.hub?.id && auctionItemToDelete?.id) {
      hubRequestActions
        .deleteAuctionItem(props.hub.id, auctionItemToDelete.id)
        .then(() => getAdminHubItems())
        .catch((error) => dispatchToastError(error, 'Delete Hub Auction Item'));
    }
  }

  useEffect(() => {
    getHubPartners();
  }, [allGroupsSortByPartners]);

  useEffect(() => {
    if (searchValuePartners !== '') {
      if (searchValuePartners !== undefined) {
        if (searchDebouncePartners) {
          clearTimeout(searchDebouncePartners);
        }
        setSearchDebouncePartners(
          setTimeout(() => {
            getHubPartners();
          }, 500),
        );
      }
    }
  }, [searchValuePartners]);

  useEffect(() => {
    if (searchValueHubItems !== '') {
      if (searchValueHubItems !== undefined) {
        if (searchDebounceHubItems) {
          clearTimeout(searchDebounceHubItems);
        }
        setSearchDebounceHubItems(
          setTimeout(() => {
            getAdminHubItems();
          }, 500),
        );
      }
    }
  }, [searchValueHubItems]);

  return (
    <>
      <Modal
        contentClassName="HubAuctionSettingsModal"
        closeIcon="fas fa-times"
        onClose={props.onCloseSettingsModal}
        show={props.showSettingsModal}
        title="Auction Component"
      >
        <hr />
        <ul className="menu">
          <li
            className={`${activeTab === 0 ? 'active' : ''}`}
            onClick={() => setActiveTab(0)}
          >
            Auction Settings
          </li>
          <li
            className={`${activeTab === 1 ? 'active' : ''}`}
            onClick={() => setActiveTab(1)}
          >
            Partner items
          </li>
          <li
            className={`${activeTab === 2 ? 'active' : ''}`}
            onClick={() => {
              getAdminHubItems();
              setActiveTab(2);
            }}
          >
            Company items
          </li>
          <li>&nbsp;</li>
        </ul>

        {activeTab === 0 && (
          <div className="auction-settings">
            <h3>Hub Auction Settings</h3>
            <form>
              {formFields.map((field, index) => {
                switch (field.type) {
                  case 'input':
                  default:
                    return (
                      <TextField
                        key={index}
                        value={field.value}
                        name={field.name}
                        type="input"
                        onChange={field.onChange}
                        label={field.label}
                      />
                    );
                }
              })}
            </form>
          </div>
        )}

        {activeTab === 1 && (
          <div className="partner-items">
            <div className="search-container">
              <TextField
                autoComplete="off"
                value={searchValuePartners}
                name="search"
                type="input"
                icon="fa fa-search"
                onChange={(e) => {
                  setSearchValuePartners(e.target.value);
                }}
                placeholder="Search ..."
              />
            </div>
            <div className="items-container">
              <div className="header">
                <Checkbox
                  name="select-all-groups"
                  label=""
                  value="all"
                  checked={selectedAllGroups}
                  onChange={() => setSelectedAllGroups(!selectedAllGroups)}
                />
                <span
                  onClick={() => {
                    setAllGroupsSortByPartners(allGroupsSortByPartners === 'asc' ? 'desc' : 'asc');
                  }}
                >
                  Cause name{' '}
                  <i
                    className={`fas fa-chevron-down ${allGroupsSortByPartners === 'asc' ? '' : 'rotate'}`}
                  />
                </span>
              </div>

              {partners.map((partner, index) => {
                return (
                  <div
                    className="item"
                    key={index}
                  >
                    <div className="left">
                      <Checkbox
                        key={index}
                        name={`select-partner-${index}`}
                        label=""
                        value={`item-${index}`}
                        checked={isPartnerSelected(partner)}
                        onChange={() => toggleSelectedPartner(partner)}
                      />
                      <span notranslate="yes">{partner.title}</span>
                    </div>
                    <div className="right">
                      <span
                        className="visible-icon"
                        onClick={() => togglePartnersItemsVisible()}
                      >
                        <i
                          className={`far ${isPartnersItemsVisible() ? 'fa-eye' : 'fa-eye-slash'}`}
                        />
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {activeTab === 2 && (
          <div className="hub-items">
            <div className="search-container">
              <TextField
                autoComplete="off"
                value={searchValueHubItems}
                name="search"
                type="input"
                icon="fa fa-search"
                onChange={(e) => {
                  setSearchValueHubItems(e.target.value);
                }}
                placeholder="Search ..."
              />
              {/* Hidden for now https://app.clickup.com/t/8404472/GIG-5743?comment=846878690
                            <Button
                                text='Add item'
                                icon='fas fa-plus'
                                type='button'
                                buttonType='outline-dark'
                                onClick={()=>setShowAddHubItemModal(true)}
                            />
                        */}
            </div>

            {auctionItems.length > 0 && (
              <>
                <div className="list-container">
                  {auctionItems.map((item, index) => (
                    <HubAuctionSingleItem
                      editAuctionItem={(item) => {
                        setActiveAuctionItem(item);
                        setShowAddHubItemModal(true);
                      }}
                      onDeleteItemClicked={(item) => {
                        setAuctionItemToDelete(item);
                        setShowConfirmDeleteModal(true);
                      }}
                      auctionItem={item}
                      key={index}
                      editMode={true}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </Modal>

      <Modal
        show={showAddHubItemModal}
        onClose={() => {
          setShowAddHubItemModal(false);
          setActiveAuctionItem(null);
        }}
        closeIcon="fas fa-times"
        contentClassName="HubAuctionSettingsModal-add-edit-item"
        title={activeAuctionItem ? 'Edit Auction Item' : 'Add Auction Item'}
      >
        {props.hub?.id && props.hub?.handle && props.hub?.title && (
          <AuctionItemForm
            owner={{
              ownerId: props.hub.id,
              ownerType: Constants.object_type.hub,
              ownerHandle: props.hub.handle,
              ownerTitle: props.hub.title,
              ownerStatus: { code: 'active' },
              isActive: true,
              parentOwnerType: null,
              parentOwnerId: null,
              object: typeHelpers.createOwnerObject('hub', props.hub),
            }}
            onAuctionSave={() => getAdminHubItems()}
            editItem={activeAuctionItem ? 1 : -1}
            copyItem={-1}
            onClose={() => {
              getAdminHubItems();
              setShowAddHubItemModal(false);
              setActiveAuctionItem(null);
            }}
            auctionItems={auctionItems}
          />
        )}
      </Modal>

      {auctionItemToDelete && (
        <Modal
          class="HubAuctionSettingsModal-delete-item"
          show={showConfirmDeleteModal}
          onClose={() => setShowConfirmDeleteModal(false)}
          title={auctionItemToDelete?.name}
          description="Are you sure you want to delete this item?"
        >
          <div className="delete-auction-item">
            <div className="actions">
              <Button
                type="button"
                buttonType="outline-dark"
                text="No"
                onClick={() => {
                  setShowConfirmDeleteModal(false);
                  setAuctionItemToDelete(null);
                }}
              />
              <Button
                type="button"
                buttonType="dark"
                text="Yes"
                onClick={() => {
                  deleteAuctionItem();
                  getAdminHubItems();
                  setShowConfirmDeleteModal(false);
                  setAuctionItemToDelete(null);
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default HubAuctionSettingsModal;
