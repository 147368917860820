import React, { Component, createRef, FC, Fragment, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { IOptions } from '../Dropdown/Dropdown';
import './PopupMenu.scss';

type PopupPositionType = 'top' | 'bottom' | 'side';
type PopupAlternatePositionType = 'start' | 'end';
export interface IPopupPosition {
  /** Render PopupMenu on top, bottom or sides  */
  type: PopupPositionType;
  alternate?: PopupAlternatePositionType;
  /** Additional left px to properly align container */
  additionalLeft?: number;
}

export interface IShowPopupConfig {
  showMenu: boolean;
  setShowMenu: (newValue: boolean) => void;
  position?: IPopupPosition;
}

export interface IPopupMenuItem {
  id: string;
  label?: string;
  icon?: string;
  iconImg?: string;
  link?: string;
  isSelected?: boolean;
  /** An SVG imported as a ReactComponent so we can dynamically change the fill, stroke, etc
   * import { ReactComponent as IconName } from "./icon/path"
   * Pass to this parameter like: {SVGComponent: () => <IconName />}
   */
  //TODO: Add support for SVGComponents
  SVGComponent?: FC;
  //TODO: MERGE CONFLICT: Implement proper types for onClick and onClickParam
  onClick?: (e: any) => void;
  onClickParam?: any;
  notranslate?: 'yes';
  customClass?: string;
  notification_count?: number;
  separator?: boolean;
  hide?: boolean;
  subMenuItems?: IOptions[];
  subMenuSelect?: (value: string) => void;
}

export interface IPopupMenuProps {
  shouldSort?: boolean;
  showMenuConfig: IShowPopupConfig;
  menuItems: IPopupMenuItem[];
  width?: number;
  /** Children will be the target element, and will render the PopupMenu relative to it */
  children: ReactNode;
  className?: string;
  popupMenuClass?: string;
  /** Handles click event for target element */
  onClick: (e: any) => void;
  /** Callback for when a menu item is selected, applies to all menu items */
  onSelect?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, MenuItem: IPopupMenuItem) => void;
}

interface IState {
  //Controls rendering menu to the DOM
  shouldRenderMenu: boolean;
  //Toggles CSS Class to trigger animations
  shouldVisuallyDisplayMenu: boolean;
  showSubMenu: boolean;
}

/**
 * Generic PopupMenu component that renders relative to the target (children) provided
 */
class PopupMenu extends Component<IPopupMenuProps, IState> {
  private rootElement: HTMLElement | null = document.getElementById('context-menus');
  private targetRef = createRef<HTMLDivElement>();
  private menuRef = createRef<HTMLUListElement>();
  //Once timeout reaches 0 the menu portal unmounts
  private menuTimeout: NodeJS.Timeout | null = null;

  constructor(props: IPopupMenuProps) {
    super(props);
    this.state = {
      shouldRenderMenu: !window.location.href.includes('?tour')
        ? false
        : this.props.showMenuConfig.showMenu,
      shouldVisuallyDisplayMenu: !window.location.href.includes('?tour')
        ? false
        : this.props.showMenuConfig.showMenu,
      showSubMenu: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideTargetAndMenu);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideTargetAndMenu);
    this.clearMenuTimeout();
  }

  componentDidUpdate(prevProps: IPopupMenuProps, prevState: IState) {
    const { showMenu } = this.props.showMenuConfig;

    if (prevProps.showMenuConfig.showMenu !== showMenu) {
      this.handleShouldRenderMenu(showMenu);
      this.getPopupContentPosition();
    }
  }

  handleClickOutsideTargetAndMenu = (event: any) => {
    const { showMenu, setShowMenu } = this.props.showMenuConfig;

    if (
      showMenu &&
      !this.targetRef?.current?.contains(event.target) &&
      !this.menuRef.current?.contains(event.target) &&
      !window.location.href.includes('?tour')
    ) {
      setShowMenu(!showMenu);
    }
  };

  handleMouseLeaveTargetAndMenu = () => {
    const { setShowMenu } = this.props.showMenuConfig;
    setShowMenu(false);
  };

  startMenuTimeoutOnMouseLeave = () => {
    this.menuTimeout = setTimeout(this.handleMouseLeaveTargetAndMenu, 400);
  };

  clearMenuTimeout = () => {
    if (this.menuTimeout != null) {
      clearTimeout(this.menuTimeout);
    }
  };

  //Delays render to have fade animations
  handleShouldRenderMenu = (shouldRenderMenu: boolean) => {
    if (shouldRenderMenu) {
      this.setState({ shouldRenderMenu: true });
      setTimeout(() => {
        this.setState({ shouldVisuallyDisplayMenu: true });
      }, 1);
    } else {
      this.setState({ shouldVisuallyDisplayMenu: false });
      setTimeout(() => {
        this.setState({ shouldRenderMenu: false });
      }, 100);
    }
  };

  handleTargetClick = (e: any) => {
    const { onClick } = this.props;
    onClick(e);
  };

  getPopupContentPosition = () => {
    const { position } = this.props.showMenuConfig;
    const alternatePosition = position?.alternate;
    const targetRect = this.targetRef.current?.getBoundingClientRect();
    const menuRect = this.menuRef.current?.getBoundingClientRect();

    if (targetRect && menuRect) {
      //get alternate offset for top & bottom (leftOffset, rightOffset)
      const alternateOffset =
        alternatePosition == null
          ? targetRect.left
          : alternatePosition === 'start'
            ? targetRect.left - menuRect.width
            : targetRect.left + targetRect.width;

      //Calculate offscreen offsets, and reposition menu if it goes offscreen
      const bottomScreenOffset = Math.max(
        0,
        targetRect.bottom + menuRect.height - window.innerHeight,
      );
      const rightScreenOffset = Math.max(0, alternateOffset - window.innerWidth);

      switch (position?.type) {
        case 'top':
          return {
            left: alternateOffset - rightScreenOffset + (position?.additionalLeft || 0),
            top: targetRect.top - menuRect.height - bottomScreenOffset,
          };
        case 'bottom':
          return {
            left: alternateOffset - rightScreenOffset + (position?.additionalLeft || 0),
            top: targetRect.top + targetRect.height - bottomScreenOffset,
          };
        case 'side':
        //TODO: Enable rendering to the left or right of target element
        default:
          //Defaults to the bottom of the targetRect
          return {
            left: alternateOffset - rightScreenOffset,
            top: targetRect.top + targetRect.height - bottomScreenOffset,
          };
      }
    }

    return { left: 0, top: 0 };
  };

  renderSubMenuContent = (items: IOptions[], onItemSelect: (value: any) => void) => {
    return (
      <ul className="sub-menu">
        <div className={`lng-items ${this.state.showSubMenu ? 'show' : ''}`}>
          {items.map((option, index) => {
            return (
              <div
                key={index}
                className="lng-item"
                onClick={() => {
                  onItemSelect(option.value);
                  this.setState({ showSubMenu: false });
                }}
              >
                <span style={{ whiteSpace: 'pre' }}>
                  {`${option.value.charAt(0).toUpperCase() + option.value.slice(1)}       ${option.label}`}
                </span>
              </div>
            );
          })}
        </div>
      </ul>
    );
  };

  renderPopupContent = () => {
    const { top, left } = this.getPopupContentPosition();
    let { menuItems, shouldSort } = this.props;
    const { shouldRenderMenu, shouldVisuallyDisplayMenu } = this.state;

    if (shouldSort && menuItems.length > 0) {
      menuItems = menuItems.sort((a, b) => {
        if (a?.label && b?.label) {
          return a.label.localeCompare(b.label);
        } else return 1;
      });
    }

    if (shouldRenderMenu) {
      return (
        <ul
          style={{ top, left, width: this.props?.width ? `${this.props.width}px` : undefined }}
          className={`PopupMenu ${shouldVisuallyDisplayMenu ? 'show' : ''} ${this.props?.popupMenuClass ? this.props.popupMenuClass : ''}`}
          ref={this.menuRef}
          onMouseLeave={this.startMenuTimeoutOnMouseLeave}
          onMouseEnter={this.clearMenuTimeout}
        >
          {menuItems.map((item, index) => {
            const {
              id,
              label,
              icon,
              link,
              isSelected,
              onClick,
              iconImg,
              onClickParam,
              customClass,
              separator,
              notification_count,
              hide,
              subMenuItems,
              subMenuSelect,
            } = item;
            const getIsSelectedClass = isSelected ? 'selected' : '';
            return hide ? (
              <Fragment key={index} />
            ) : (
              <Fragment key={index}>
                <li
                  className={`MenuItem ${getIsSelectedClass} ${customClass || ''}`}
                  onClick={(e) => {
                    this.props.onSelect?.(e, item);
                    if (onClick) {
                      onClick(onClickParam);
                    }

                    if (subMenuItems) {
                      this.setState({ showSubMenu: !this.state.showSubMenu });
                    }
                  }}
                >
                  <div className="inner-main">
                    {icon && <i className={`icon ${icon}`} />}
                    {iconImg && (
                      <img
                        className="iconImg"
                        src={iconImg}
                        alt="icon"
                      />
                    )}
                    {label && <span className="label">{label}</span>}
                    {link && (
                      <Link
                        to={link}
                        className={'label link'}
                      >
                        {label}
                      </Link>
                    )}
                    <i className={`fas fa-check ${getIsSelectedClass}`} />
                    {subMenuItems && (
                      <i
                        className={`fa fa-chevron-right ${this.state.showSubMenu ? 'active' : ''}`}
                      />
                    )}
                  </div>
                  {!!notification_count && (
                    <span className="metric">
                      {notification_count > 99 ? '99+' : notification_count}
                    </span>
                  )}
                </li>
                {subMenuItems &&
                  subMenuSelect &&
                  this.renderSubMenuContent(subMenuItems, subMenuSelect)}
                {separator && <span className="separator" />}
              </Fragment>
            );
          })}
        </ul>
      );
    }

    return null;
  };

  render() {
    const { children, className } = this.props;

    return (
      <Fragment>
        <div
          ref={this.targetRef}
          className={className ? className : ''}
          onClick={this.handleTargetClick}
          onMouseLeave={this.startMenuTimeoutOnMouseLeave}
          onMouseEnter={this.clearMenuTimeout}
        >
          {children}
        </div>
        {ReactDOM.createPortal(this.renderPopupContent(), this.rootElement!)}
      </Fragment>
    );
  }
}

export default PopupMenu;
