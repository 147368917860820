import React from 'react';
import { connect } from 'react-redux';
import {
  SearchableDropdown,
  ISearchableDropdownListItem,
} from '../../../SearchableDropdown/SearchableDropdown';

export interface IOptions {
  label: string;
  value: string;
}

interface IProps {
  flow: string;
  fundraiseTeams: Array<ISearchableDropdownListItem>;
  fundraiser: any;

  setTeam(field: string, value: any): void;
}

interface IState {
  selectedTeam: any;
  teams: Array<ISearchableDropdownListItem>;
}

class FundraiseTeamSearch extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedTeam: '',
      teams: this.props.fundraiseTeams || [],
    };

    this.filterTeams = this.filterTeams.bind(this);
  }

  componentDidMount() {
    if (this.props.fundraiser?.teamName) {
      this.setState({
        selectedTeam: this.props.fundraiser.team,
      });
    } else if (this.props.fundraiser?.teamId) {
      let filtered = this.state.teams.filter((team) => team.id === this.props.fundraiser.teamId);
      let team = filtered[0];

      this.setState({
        selectedTeam: team,
      });
    }
  }

  filterTeams(search: string) {
    if (search === '') {
      this.setState({
        teams: this.props.fundraiseTeams || [],
      });
    } else {
      let filtered = this.state.teams.filter((team) => team.label.includes(search));

      this.setState({
        teams: filtered,
      });
    }
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} team-select`}>
        <div className="details-row">
          <SearchableDropdown
            showFullListOnEmpty={false}
            label="What team would you like to join?"
            list={this.state.teams}
            onSearch={(search) => {}}
            selectedItem={this.state.selectedTeam}
            setSelectedItem={(item) =>
              this.setState({ selectedTeam: item }, () =>
                item?.id
                  ? this.props.setTeam('teamId', item.id)
                  : this.props.setTeam('teamId', undefined),
              )
            }
            notranslate="yes"
          />
        </div>
      </div>
    );
  }
}

export default FundraiseTeamSearch;
