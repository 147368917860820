import React, { Component, MouseEventHandler } from 'react';
import './Marker.scss';

interface IProps {
  lat: number;
  lng: number;
  text?: string;
  offsetLeft?: number;
  offsetTop?: number;
  onClick?: MouseEventHandler;
}
interface IState {}
export default class Marker extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { offsetTop, offsetLeft } = this.props;

    return (
      <div
        onClick={this.props.onClick}
        className="Marker"
        style={{ top: offsetTop, left: offsetLeft }}
      >
        {this.props.children}
      </div>
    );
  }
}
