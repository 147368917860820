import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import { DateRange, DayPicker, SelectRangeEventHandler } from 'react-day-picker';
import { usePopper } from 'react-popper';
import './DateRangePickerField.scss';
import ReactDOM from 'react-dom';
import { LocaleDateFormats, localizeHelpers } from '../../../../../localizeHelpers';
import moment from 'moment';
import TextField from '../../../../TextField/TextField';
import { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';

interface DateOptions {
  label: string;
  name?: string;
  /** Placeholder text for field */
  placeholder?: string;
  /** Makes dates before the provided date un-selectable */
  disableDatesBefore?: string;
  /** Makes dates after the provided date un-selectable */
  disableDatesAfter?: string;
  /** Set's the field to this date on render */
  defaultDate?: string;
}

type RangePickerOnChange = {
  range: DateRange;
  formattedRange: {
    from: string;
    to: string;
  };
};

export type DateRangeValue = {
  from: string | undefined;
  to: string | undefined;
};
export interface IDateRangeProps {
  fromFieldOptions: DateOptions;
  toFieldOptions: DateOptions;
  value: DateRangeValue;
  onChange: (dateRange: RangePickerOnChange) => void;
  /** Disables both fields */
  disabled?: boolean;
  locale?: string;
}

//TODO: Separate dialog into own component
export default function DateRangePickerField(props: IDateRangeProps) {
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  });

  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popperRef = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const isFieldDisabledClass = props.disabled ? 'disabled' : '';

  const longDateFormat = localizeHelpers.getLongDateFormat(
    LocaleDateFormats.LL,
    props.locale ?? 'en',
  );

  const offsetModifier = React.useMemo<Partial<OffsetModifier>>(
    () => ({
      name: 'offset',
      options: {
        offset: ({ placement, reference, popper }) => {
          return [0, 10];
        },
      },
    }),
    [],
  );

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [offsetModifier],
  });

  function closePopper() {
    setIsPopperOpen(false);
  }

  function handleFromInputChange(e: ChangeEvent<HTMLInputElement>) {
    const date = moment(e.currentTarget.value).toDate();

    if (!moment(date).isValid()) {
      return setSelectedRange({ from: undefined, to: undefined });
    }

    if (selectedRange?.to && moment(date).isAfter(selectedRange.to)) {
      setSelectedRange({ from: selectedRange.to, to: date });
    } else {
      setSelectedRange({ from: date, to: selectedRange?.to });
    }
  }

  function handleToInputChange(e: ChangeEvent<HTMLInputElement>) {
    const date = moment(e.currentTarget.value).toDate();
    if (!moment(date).isValid()) {
      return setSelectedRange({ from: selectedRange?.from, to: undefined });
    }
    if (selectedRange?.from && moment(date).isAfter(selectedRange.from)) {
      setSelectedRange({ from: date, to: selectedRange.from });
    } else {
      setSelectedRange({ from: selectedRange?.from, to: date });
    }
  }

  function handleFieldClick() {
    const isFieldDisabled = props.disabled;

    if (isFieldDisabled) {
      return;
    }

    setIsPopperOpen(true);
  }

  const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | undefined) => {
    setSelectedRange(range);
  };

  function handleClearInputValue() {
    setSelectedRange(undefined);
  }

  function validateDateField(dateString: string) {
    const isValid = moment(dateString, longDateFormat).isValid();

    if (!!dateString && !isValid) {
      return 'Not Valid';
    }
  }

  useEffect(() => {
    if (selectedRange) {
      props.onChange({
        range: selectedRange,
        formattedRange: {
          from: moment(selectedRange.from).format(longDateFormat) ?? '',
          to: moment(selectedRange.to).format(longDateFormat) ?? '',
        },
      });
    } else {
      props.onChange({
        range: { from: undefined, to: undefined },
        formattedRange: { from: '', to: '' },
      });
    }
  }, [selectedRange]);

  return (
    <Fragment>
      <div
        ref={popperRef}
        className={`DateRangePickerFieldContainer ${isFieldDisabledClass}`}
      >
        <TextField
          label={props.fromFieldOptions.label}
          name={props.fromFieldOptions.name ?? props.fromFieldOptions.label}
          type="text"
          icon="fal fa-calendar-alt"
          placeholder={props.fromFieldOptions.placeholder}
          value={props.value.from ?? ''}
          onChange={handleFromInputChange}
          onInputClick={handleFieldClick}
          onClearInputValue={handleClearInputValue}
          disabled={props.disabled}
          error={validateDateField(props.value.from ?? '')}
          readOnly
        />
        <TextField
          label={props.toFieldOptions.label}
          name={props.toFieldOptions.name ?? props.fromFieldOptions.label}
          type="text"
          icon="fal fa-calendar-alt"
          placeholder={props.toFieldOptions.placeholder}
          value={props.value.to ?? ''}
          onChange={handleToInputChange}
          onInputClick={handleFieldClick}
          onClearInputValue={handleClearInputValue}
          disabled={props.disabled}
          error={validateDateField(props.value.to ?? '')}
          readOnly
        />
      </div>
      {isPopperOpen &&
        ReactDOM.createPortal(
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: true,
              onDeactivate: closePopper,
              onActivate: () => handleFieldClick(),
            }}
          >
            <div
              tabIndex={-1}
              style={popper.styles.popper}
              className="DateRangePickerDialogPortal"
              {...popper.attributes.popper}
              ref={setPopperElement}
              role="dialog"
            >
              <DayPicker
                initialFocus={isPopperOpen}
                mode="range"
                selected={selectedRange}
                onSelect={handleRangeSelect}
                modifiersClassNames={{
                  selected: 'date-picker-selected',
                }}
              />
            </div>
          </FocusTrap>,
          document.querySelector('#context-menus')!,
        )}
    </Fragment>
  );
}
