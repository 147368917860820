import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { CardElement } from '@stripe/react-stripe-js';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../store';

import { IGroupState } from '../../reducers/group';
import { IEventState } from '../../reducers/event';
import { IAuctionState } from '../../reducers/auction';

import './CreditCard.scss';
import {
  Stripe,
  StripeCardElement,
  PaymentRequest,
  StripeError,
  PaymentMethodCreateParams,
  PaymentRequestPaymentMethodEvent,
  PaymentRequestWallet,
} from '@stripe/stripe-js';
import { IPaymentIntentResponse, IPaymentMethod } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { defaultCurrency } from '../../helpers';
import { ICartState } from '../../reducers/cart';
import { uiConstants } from '../../constants/uiConstants';
import { PaymentMethodOption } from './PaymentMethodOption';
import { localizeHelpers } from '../../localizeHelpers';

// TODO [HOTFIX/3.4.4]: Switch `any` with proper types.
interface IProps extends WithTranslation {
  type: 'once' | 'monthly' | 'purchase' | 'auction';
  hide?: boolean;
  paymentMethod?: any;
  paymentIntent?: IPaymentIntentResponse | null;
  groupState: IGroupState;
  eventState: IEventState;
  auctionState: IAuctionState;
  cartState?: ICartState;
  stripe: Stripe | null;
  elements: any;
  tryCreatePaymentMethod: boolean;
  resetPaymentMethodTry(): void;
  onSuccessfulPaymentMethod(paymentMethod: any): void;
  onFailedPaymentMethod(error: any): void;
  billingDetails: PaymentMethodCreateParams.BillingDetails;
  trySubmitPayment: boolean;
  resetSubmitPayment(): void;
  onFailedSubmitPayment(error: StripeError, isLastTransaction: boolean): void;
  onSuccessfulSubmitPayment(response: any): void;
  onSubmitSubscription?(): void;
  donationType?: 'group' | 'event';
  ownerType: string;
  options?: any;
  notCheckout?: boolean;
  // Alternative payment props. These are only used in areas that want to use them.
  selectedAltMethod?: number;
  setSelectedAltMethod?(index: number): void;

  paymentRequest?: PaymentRequest | null;
  setPaymentRequest?(paymentRequest: PaymentRequest | null): void;
}

interface IState {
  supportedAltMethods: any[];
  altPaymentMethod: IPaymentMethod | null;
  payEvent: PaymentRequestPaymentMethodEvent | null;
  altPayProcessing: boolean;
}

class CreditCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      supportedAltMethods: [],
      altPaymentMethod: null,
      payEvent: null,
      altPayProcessing: false,
    };

    this.setupPayment = this.setupPayment.bind(this);
    this.submitPayment = this.submitPayment.bind(this);
    this.confirmCardPayments = this.confirmCardPayments.bind(this);
    this.getAlternativeMethods = this.getAlternativeMethods.bind(this);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props !== prevProps) {
      // TODO [HOTFIX/3.4.4]: Combine duplicated checks below.
      if (!this.props.paymentRequest && this.props.type === 'purchase' && !this.props.notCheckout) {
        if (this.props.ownerType == uiConstants.ownerType.group) {
          if (this.props.groupState.group.account) {
            this.getAlternativeMethods();
          }
        }

        if (this.props.ownerType == uiConstants.ownerType.event) {
          if (this.props.eventState.event.group?.account) {
            this.getAlternativeMethods();
          }
        }
      }
    }

    if (this.props.cartState?.total.total !== prevProps.cartState?.total.total) {
      this.props.paymentRequest?.update({
        total: {
          // TODO [HOTFIX/3.4.4]: Move this into a constant called `uiConstants.amountMultiplier`
          /* The amount of cents in a dollar. For most currencies this is sufficient
                            uiConstants.amountMultiplier = 100, */
          amount: this.props.cartState?.total.total * 100,
          // TODO [HOTFIX/3.4.4]: Translate text "Checkout"
          label: 'Checkout',
        },
      });
    }
    if (this.props.tryCreatePaymentMethod && !prevProps.tryCreatePaymentMethod) {
      this.setupPayment();
    }

    if (this.props.trySubmitPayment && !prevProps.trySubmitPayment) {
      this.submitPayment();
    }
  }

  /** Called to load what the available alt payment methods are. */
  getAlternativeMethods() {
    if (!this.props.stripe) {
      return;
    }

    let country = 'CA';

    const group =
      this.props.ownerType == uiConstants.ownerType.group
        ? this.props.groupState.group
        : this.props.eventState.event.group;
    const account = group?.account;

    if (!account) {
      throw new Error(
        localizeHelpers.translate(
          'Expected an account to receive payment. Contact administrator if this error persists.',
        ),
      );
    }

    let wallets = uiConstants.altPaymentWallets as PaymentRequestWallet[];

    const pr = (this.props.stripe as Stripe).paymentRequest({
      country: account.country,
      currency: account.currency ?? defaultCurrency,
      total: {
        // TODO [HOTFIX/3.4.4]: Translate text "Checkout"
        label: 'Checkout',
        // TODO [HOTFIX/3.4.4]: Move this into a constant called `uiConstants.amountMultiplier`
        amount: Math.round(this.props.cartState?.total.total * 100),
        pending: true,
      },
      wallets,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        if (
          result.applePay &&
          !this.state.supportedAltMethods.includes(uiConstants.altPaymentMethods.applePay)
        ) {
          let methods = this.state.supportedAltMethods;
          methods.push(uiConstants.altPaymentMethods.applePay);
          this.setState({
            supportedAltMethods: methods,
          });
        }

        if (
          result.googlePay &&
          !this.state.supportedAltMethods.includes(uiConstants.altPaymentMethods.googlePay)
        ) {
          let methods = this.state.supportedAltMethods;
          methods.push(uiConstants.altPaymentMethods.googlePay);
          this.setState({
            supportedAltMethods: methods,
          });
        }

        this.props.setPaymentRequest?.(pr);

        // This will be called when the user finishes the payment in the alt payment model.
        pr.on('paymentmethod', async (ev: PaymentRequestPaymentMethodEvent) => {
          const pm = ev.paymentMethod;

          // TODO [HOTFIX/3.4.4]: Make a helper function for creating our IPaymentMethod from stripe payment method.
          // See: https://github.com/gigitmarket/gigit-platform/blob/6303e1e3cc431f5717802f0f297f55b2085c8791/packages/ui/gigit-ui-web/src/routes/Donate/DonateForm/DonateForm.tsx#L615
          // See: https://github.com/gigitmarket/gigit-platform/blob/6303e1e3cc431f5717802f0f297f55b2085c8791/packages/ui/gigit-ui-web/src/routes/Donate/DonateForm/DonateForm.tsx#L453
          const paymentMethod: IPaymentMethod = {
            payment_method_id: pm.id,
            customer_id: pm.customer ?? undefined,
            type: 'stripe',
            billing_details: {
              address: pm.billing_details?.address,
              name: pm.billing_details?.name,
              email: pm.billing_details?.email,
              phone: pm.billing_details?.phone,
            },
            card: {
              brand: pm.card?.brand,
              country: pm.card?.country,
              exp_month: pm.card?.exp_month,
              exp_year: pm.card?.exp_year,
              last4: pm.card?.last4,
            },
          } as IPaymentMethod;

          this.setState(
            {
              altPaymentMethod: paymentMethod,
              payEvent: ev,
            },
            () => {
              this.props.onSuccessfulPaymentMethod(pm);
            },
          );
        });
      }
    });
  }

  setupPayment() {
    if (this.props.paymentRequest && this.props.selectedAltMethod !== -1) {
      return;
    }

    const cardElement = this.props.elements.getElement('card');

    this.props.stripe
      ?.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: this.props.billingDetails,
      })
      .then((response: any) => {
        if (response.paymentMethod) {
          this.props.onSuccessfulPaymentMethod(response.paymentMethod);
        } else if (response.error) {
          this.props.onFailedPaymentMethod(response.error);
        }

        this.props.resetPaymentMethodTry();
      });
  }

  submitPayment() {
    const cardElement = this.props.elements.getElement('card');

    let _payload: any = { payment_method: { card: cardElement } };

    if (this.props.paymentMethod && this.props.donationType === 'group') {
      _payload = {
        payment_method: this.props.groupState.donationIntent?.payment_method_id,
      };
    }

    if (this.props.paymentMethod && this.props.donationType === 'event') {
      _payload = {
        payment_method: this.props.eventState.donationIntent?.payment_method_id,
      };
    }

    if (this.props.donationType && this.props.donationType === 'event') {
      if (this.props.paymentMethod && this.props.eventState.donationIntent !== null) {
        _payload = {
          payment_method: this.props.eventState.donationIntent.payment_method_id,
        };
      }

      if (this.props.type === 'once') {
        if (typeof this.props.eventState.donationIntent?.client_secret !== 'string') {
          throw new Error(
            `Expected client_secret to be string, but get ${JSON.stringify(this.props.eventState.donationIntent?.client_secret)}`,
          );
        }

        this.props.stripe
          ?.confirmCardPayment(this.props.eventState.donationIntent?.client_secret, _payload)
          .then((response: any) => {
            if (response.error) {
              this.props.onFailedSubmitPayment(response.error, true);
            } else {
              this.props.onSuccessfulSubmitPayment(response);
            }
          });
      } else if (this.props.type === 'monthly') {
        this.props.onSubmitSubscription?.();
      } else if (this.props.type === 'purchase') {
        this.confirmCardPayments(0);
      }
    } else {
      if (this.props.paymentMethod && this.props.groupState.donationIntent !== null) {
        _payload = {
          payment_method: this.props.groupState.donationIntent.payment_method_id,
        };
      }

      if (this.props.type === 'once') {
        if (typeof this.props.groupState.donationIntent?.client_secret !== 'string') {
          throw new Error(
            `Expected client_secret to be a string, but got ${JSON.stringify(this.props.groupState.donationIntent?.client_secret)}`,
          );
        }
        this.props.stripe
          ?.confirmCardPayment(this.props.groupState.donationIntent.client_secret, _payload)
          .then((response: any) => {
            if (response.error) {
              this.props.onFailedSubmitPayment(response.error, true);
            } else {
              this.props.onSuccessfulSubmitPayment(response);
            }
          });
      } else if (this.props.type === 'monthly') {
        this.props.onSubmitSubscription?.();
      } else if (this.props.type === 'purchase' || this.props.type === 'auction') {
        this.confirmCardPayments(0);
      }
    }

    this.props.resetSubmitPayment();
  }

  confirmCardPayments(index: number) {
    const cardElement = this.props.elements.getElement('card');

    let _payload: any = { payment_method: { card: cardElement } };

    if (this.state.altPaymentMethod) {
      _payload.payment_method = this.state.altPaymentMethod.payment_method_id;
    }

    if (this.props.paymentMethod && this.props.donationType === 'group') {
      _payload = {
        payment_method: this.props.groupState.donationIntent?.payment_method_id,
      };
    }

    if (this.props.paymentMethod && this.props.donationType === 'event') {
      _payload = {
        payment_method: this.props.eventState.donationIntent?.payment_method_id,
      };
    }

    let paymentIntent: { client_secret: string[] } | null | IPaymentIntentResponse = {
      client_secret: [],
    };
    if (this.props.type === 'auction') {
      paymentIntent = this.props.paymentIntent || this.props.auctionState.paymentIntent;

      if (paymentIntent === null) {
        paymentIntent = this.props.auctionState.buyNowIntent;
      }
    } else if (this.props.ownerType === Constants.object_type.group) {
      paymentIntent = this.props.groupState.purchaseIntent;
    } else if (this.props.ownerType === Constants.object_type.event) {
      paymentIntent = this.props.eventState.purchaseIntent;
    } else if (this.props.ownerType === Constants.object_type.hub) {
      paymentIntent = this.props.paymentIntent || null;
    }

    this.props.stripe
      ?.confirmCardPayment(paymentIntent?.client_secret?.[index] || '', _payload)
      .then((response: any) => {
        const clientSecretLength = paymentIntent?.client_secret.length || 0;
        const isLastTransaction = index === clientSecretLength - 1;
        if (response.error) {
          this.state.payEvent?.complete('fail');
          this.props.onFailedSubmitPayment(response.error, isLastTransaction);
        } else if (isLastTransaction) {
          this.state.payEvent?.complete('success');
          this.props.onSuccessfulSubmitPayment(response);
        }

        if (index < clientSecretLength - 1) {
          this.confirmCardPayments(index + 1);
        }
      });
  }

  onReady = (element: StripeCardElement) => {};

  render() {
    const isNormalPurchase = (this.props.selectedAltMethod ?? -1) === -1;

    return (
      <div className="CreditCard">
        {/* Hide card payment info when using alternative payment method. */}
        {isNormalPurchase && (
          <Fragment>
            <div className="label">Card Information</div>
            <div
              className="CreditCard-wrap"
              notranslate="yes"
            >
              {!this.props.hide && (
                <CardElement
                  options={this.props.options ? this.props.options : {}}
                  onReady={this.onReady}
                />
              )}
            </div>
          </Fragment>
        )}

        {this.state.supportedAltMethods &&
          this.state.supportedAltMethods.map((item, i) => (
            <PaymentMethodOption
              index={i}
              key={i}
              item={item}
              selectedAltMethod={this.props.selectedAltMethod ?? -1}
              setSelectedAltMethod={(index) => this.props.setSelectedAltMethod?.(index)}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    auctionState: store.auctionState,
  };
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(CreditCard),
);
