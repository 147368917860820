import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  ITransactionItemSummary,
  IUserRole,
  ITransactionItem,
  ISupporter,
} from '@gigit/interfaces';
import { IAppState } from '../../../store';
import {
  downloadGroupPurchaseReceipt,
  refundPurchase,
  getTransactionItems,
} from '../../../actions/group';
import { clearCart } from '../../../actions/cart';
import { createToast } from '../../../actions/toaster';
import { IGroupState } from '../../../reducers/group';
import {
  routes,
  swapRouteParams,
  handleInputChange,
  defaultCurrency,
  formatCurrency,
  toastError,
} from '../../../helpers';
import Button from '../../Button/Button';
import SortableTable, { ISortableTableColumn } from '../../SortableTable/SortableTable';
import Modal from '../../Modal/Modal';
import ContactCard from '../ContactCard/ContactCard';
import Checkout from '../../../routes/Checkout/Checkout';
import ActivityLog from '../ActivityLog/ActivityLog';
import TextField from '../../TextField/TextField';
import { IOptions } from '../../Dropdown/Dropdown';
import Portrait from '../../Portrait/Portrait';
import ContextMenu from '../../ContextMenu/ContextMenu';
import './ContactSupport.scss';
import errorHelpers from '../../../helpers/errorHelpers';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { IToast } from '../../../interfaces';
import { userSelectors } from '../../../selectors/user';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  locale: string;

  clearCart(): void;
  downloadGroupPurchaseReceipt(groupId: string, _transaction_Id: string): void;
  refundPurchase(groupId: string, transaction_id: string, payload: any): void;
  getTransactionItems(groupId: string, transaction_Id: string): void;
  createToast(toast: IToast): void;
}

interface IState {
  tabs: Array<string>;
  activeTab: number;
  purchases: Array<ITransactionItemSummary>;
  supporterInfo: ISupporter | null;
  columns: ISortableTableColumn[];
  showPurchase: boolean;
  showActivityLog: boolean;
  showRefundModal: boolean;
  refund_amount: number;
  order: ITransactionItemSummary | null;
  selectedPurchase: string;
  selectedPurchaseItem: ITransactionItem | null;
  showItemForm: boolean;
  loading: boolean;
}

class ContactSupport extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tabs: ['Purchases'],
      activeTab: 0,
      purchases: [],
      supporterInfo: null,
      columns: [
        { label: 'Order #', id: 'receipt', sortable: false },
        { label: '# of items', id: 'num-items', sortable: false },
        { label: 'Order Total', id: 'total', sortable: false },
        { label: 'Purchase Date', id: 'purchase', sortable: false },
        { label: 'Actions', id: 'actions', sortable: false },
      ],
      showPurchase: false,
      showActivityLog: false,
      showRefundModal: false,
      refund_amount: 0,
      order: null,
      selectedPurchase: '',
      selectedPurchaseItem: null,
      showItemForm: false,
      loading: false,
    };

    this.refund = this.refund.bind(this);
    this.onPurchase = this.onPurchase.bind(this);
    this.toggleRefundModal = this.toggleRefundModal.bind(this);
  }

  componentDidMount() {
    this.getPurchases();
    this.getSupporterInfo();
  }

  getPurchases() {
    this.setState({
      loading: true,
    });
    axios
      .get(
        swapRouteParams(routes.GET_PURCHASES_FOR_USER, {
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user?.id,
        }),
      )
      .then((response) => {
        this.setState({
          purchases: response.data,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Supporter Purchase Info');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getSupporterInfo() {
    axios
      .get(
        swapRouteParams(routes.GET_SUPPORTER_INFO, {
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user?.id,
        }),
      )
      .then((response) => {
        this.setState({
          supporterInfo: (Array.isArray(response.data) && response.data[0]) || response.data,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Supporter Info');
        this.props.createToast(toast);
      });
  }

  toggleRow(id: string) {
    if (this.state.selectedPurchase === id) {
      this.setState({
        selectedPurchase: '',
      });
    } else {
      this.setState(
        {
          selectedPurchase: id,
        },
        () => {
          this.props.getTransactionItems(this.props.groupState.group.id, id);
        },
      );
    }
  }

  setPurchaseItem(item: ITransactionItem) {
    this.setState(
      {
        selectedPurchaseItem: item,
      },
      () => {
        this.toggleFormModal(true);
      },
    );
  }

  toggleFormModal(val: boolean) {
    this.setState({
      showItemForm: val,
    });
  }

  onNoForm() {
    const toast = toastError(
      localizeHelpers.translate(`This store item does not have an associated form.`),
      'No Form',
    );
    this.props.createToast(toast);
  }

  convertTableData() {
    let currency = this.props.groupState.group.account?.currency || defaultCurrency;
    return this.state.purchases.map((p: any) => {
      const exchangeRate = p.reporting_exchange_rate ?? 1;
      let menuItems = [
        {
          icon: 'far fa-file-alt',
          onClick: () => {
            this.setState({ showActivityLog: true });
          },
          label: 'Activity Log',
        },
        {
          icon: 'far fa-dollar-sign',
          onClick: () => {
            this.props.downloadGroupPurchaseReceipt(this.props.groupState.group.id, p.id);
          },
          label: 'View Statement',
        },
        {
          icon: 'far fa-dollar-sign',
          onClick: () => {
            this.setState({ order: p, showRefundModal: true });
          },
          label: 'Refund',
        },
      ];

      if (p.amounts?.is_refund) {
        menuItems.pop();
      }

      return {
        row: [
          { content: `${p?.receipt_number}`, id: 'receipt', notranslate: 'yes' },
          { content: `${p?.number_of_items || 1}`, id: 'num-items', notranslate: 'yes' },
          {
            content: `${formatCurrency(p?.amounts.total * p?.amounts.amount_multiplier, currency, this.props.locale)}`,
            id: 'total',
            notranslate: 'yes',
          },
          {
            content: `${localizeHelpers.formatDate(p?.created_at, LocaleDateFormats.ll, this.props.locale)}`,
            id: 'purchase',
            notranslate: 'yes',
          },
          { id: 'actions', menu: menuItems },
        ],
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          this.toggleRow(p.id);
        },
        details: (
          <React.Fragment>
            <div className="detail-section">
              <div className="detail-title">
                <div className="title">Items</div>
              </div>
              <div className="detail-items">
                {this.props.groupState.currentTransactionItems.map((item) => {
                  let subMenuItems;
                  if (item.form?.length ?? 0 > 0) {
                    subMenuItems = [
                      {
                        icon: 'fa fa-list',
                        onClick: () => {
                          this.setPurchaseItem(item);
                        },
                        label: 'View Form',
                      },
                    ];
                  } else {
                    subMenuItems = [
                      {
                        icon: 'fa fa-list',
                        onClick: () => {
                          this.onNoForm();
                        },
                        label: 'View Form',
                      },
                    ];
                  }
                  return (
                    <div
                      key={item.id}
                      className="item"
                    >
                      <div className="portrait-container">
                        <Portrait
                          source={item.media?.length ?? 0 > 0 ? item.media?.[0].url : ''}
                          size={60}
                        />
                      </div>
                      <div className="item-name">
                        <div> Name </div>
                        <div notranslate="yes"> {item.name} </div>
                      </div>
                      <div className="item-price">
                        <div> Price </div>
                        <div notranslate="yes">
                          {' '}
                          {formatCurrency(
                            item.price * exchangeRate,
                            currency,
                            this.props.locale,
                          )}{' '}
                        </div>
                      </div>
                      <div className="item-qty">
                        <div> Quantity </div>
                        <div notranslate="yes"> {item.quantity} </div>
                      </div>
                      <div className="actions">
                        <i className="fal fa-ellipsis-h-alt" />
                        <ContextMenu
                          onMouseLeave={() => {}}
                          showMenu={true}
                          menuItems={subMenuItems}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="detail-section">
              <div className="title">Contact Details</div>
              <div
                notranslate="yes"
                className="email"
              >
                {p.user.email}
              </div>
              {p.user.phone && (
                <div
                  notranslate="yes"
                  className="phone"
                >
                  {p.user.phone}
                </div>
              )}
              <div className="title">Billing Address</div>
              {p.user.address.city && (
                <div
                  notranslate="yes"
                  className="address"
                >
                  <div>{p.user.address.line1}</div>
                  <div>{p.user.address.line2}</div>
                  <div>
                    {p.user.address.city}, {p.user.address.state} {p.user.address.postal_code}
                  </div>
                  <div>{p.user.address.country}</div>
                </div>
              )}
              {p.user.billing_address && p.user.billing_address.city && (
                <div
                  notranslate="yes"
                  className="address"
                >
                  <div>{p.user.billing_address.line1}</div>
                  <div>{p.user.billing_address.line2}</div>
                  <div>
                    {p.user.billing_address.city}, {p.user.billing_address.state}{' '}
                    {p.user.billing_address.postal_code}
                  </div>
                  <div>{p.user.billing_address.country}</div>
                </div>
              )}
            </div>
          </React.Fragment>
        ),
      };
    });
  }

  onPurchase() {
    this.setState(
      {
        showPurchase: false,
      },
      () => {
        this.getPurchases();
        this.getSupporterInfo();
      },
    );
  }

  toggleRefundModal(value: boolean) {
    this.setState({
      showRefundModal: value,
    });
  }

  refund(id: string) {
    const _payload = {
      amount: this.state.refund_amount,
      group_title: this.props.groupState.group.handle,
    };

    if (typeof _payload.amount === 'string') {
      _payload.amount = parseFloat(_payload.amount);
    }

    this.props.refundPurchase(this.props.groupState.group.id, id, _payload);
    this.toggleRefundModal(false);
    this.setState(
      {
        refund_amount: 0,
      },
      () => {
        this.getPurchases();
        this.getSupporterInfo();
      },
    );
  }

  render() {
    let currency = this.props.groupState.group.account?.currency || defaultCurrency;
    let data = this.convertTableData();
    let isDataEmpty = data.length === 0;
    let isSupportTab = this.state.activeTab === 0;
    const exchangeRate = this.state.order?.transaction?.reporting_exchange_rate ?? 1;
    const orderCurrency = this.state.order?.transaction?.reporting_currency ?? defaultCurrency;
    const total = ((this.state.order?.transaction?.amounts?.total || 0) / 100) * exchangeRate;

    return (
      <div className="ContactSupport">
        <ul className="Contact-menu">
          {this.state.tabs.map((item, index) => {
            return (
              <li
                onClick={() => {
                  this.setState({ activeTab: index });
                }}
                className={this.state.activeTab === index ? 'active no-select' : 'no-select'}
                key={index}
              >
                <span>{item}</span>
              </li>
            );
          })}
          {isSupportTab && (
            <li className="add">
              <Button
                onClick={() => this.setState({ showPurchase: true }, () => this.props.clearCart())}
                className="add-field"
                icon="far fa-plus"
                text={'Store Purchase'}
              />
            </li>
          )}
        </ul>
        <div className="Contact-content">
          <div className="Contact-side">
            <ContactCard
              groupId={this.props.groupState.group.id}
              {...this.props}
            />
          </div>
          <div className="contact-main">
            {isSupportTab && (
              <div className="stat-row">
                <div className="cd-stat-row">
                  <div className="cd-stat-box">
                    <div className="stat">
                      <var data-var="total_value_of_purchases">
                        {' '}
                        {formatCurrency(
                          this.state.supporterInfo?.total_value_of_purchases || 0,
                          currency,
                          this.props.locale,
                        )}
                      </var>
                    </div>
                    <div className="stat-label">TOTAL PURCHASES</div>
                  </div>
                  <div className="cd-stat-box">
                    <div
                      notranslate="yes"
                      className="stat"
                    >
                      {localizeHelpers.formatNumber(
                        this.state.supporterInfo?.number_of_tickets_purchased || 0,
                        this.props.locale,
                      )}
                    </div>
                    <div className="stat-label">TICKETS PURCHASED</div>
                  </div>
                  <div className="cd-stat-box">
                    <div
                      notranslate="yes"
                      className="stat"
                    >
                      {localizeHelpers.formatNumber(
                        this.state.supporterInfo?.number_of_bids || 0,
                        this.props.locale,
                      )}
                    </div>
                    <div className="stat-label">AUCTION BIDS</div>
                  </div>
                  <div className="cd-stat-box">
                    <div className="stat">
                      <var data-var="last_purchase_date">
                        {this.state.supporterInfo?.last_purchase_date
                          ? `${localizeHelpers.formatDate(this.state.supporterInfo?.last_purchase_date, LocaleDateFormats.ll, this.props.locale)}`
                          : 'N/A'}{' '}
                      </var>
                    </div>
                    <div className="stat-label">LAST PURCHASE</div>
                  </div>
                </div>
              </div>
            )}
            {isSupportTab && (
              <div className="data">
                <SortableTable
                  columns={this.state.columns}
                  data={data}
                  {...this.props}
                  loading={this.state.loading}
                />

                {isDataEmpty && (
                  <div className="empty-data">This user has yet to purchase any items.</div>
                )}
              </div>
            )}

            <Modal
              class="purchase-modal"
              show={this.state.showPurchase}
              onClose={this.onPurchase}
            >
              <Checkout
                ownerId={this.props.groupState.group.id}
                userInfo={this.props.contact}
                {...this.props}
                adminPurchase={true}
                adminParentType={'group'}
                type="group"
                onAdminPurchase={this.onPurchase}
              />
            </Modal>

            <Modal
              class="activity-log-modal"
              show={this.state.showActivityLog}
              onClose={() => this.setState({ showActivityLog: false })}
            >
              <ActivityLog {...this.props} />
            </Modal>
            <Modal
              class="refund-modal"
              onClose={() => {
                this.toggleRefundModal(false);
              }}
              show={this.state.showRefundModal}
              title="Transaction type"
              description="A copy of the reciept will be emailed to the customer."
            >
              <div className="refund">
                <div className="refund-title"></div>
                <div className="refund-info">
                  <div className="sub-header">Order Summary</div>
                  <div className="info">
                    <div className="info-part">
                      <span>Name: </span>
                      {this.state.order?.user?.display_name}
                    </div>
                    <div className="info-part">
                      <span>Transaction type:</span>{' '}
                      {this.state.order && this.state.order.transaction_type}{' '}
                    </div>
                    <div className="info-part">
                      <span>Order #:</span> {this.state.order && this.state.order.id}{' '}
                    </div>
                    <div className="info-part">
                      <span>Amount Charged:</span>{' '}
                      {formatCurrency(total, orderCurrency, this.props.locale)}{' '}
                    </div>
                  </div>
                  <div className="sub-header">Refund Details:</div>
                  <div className="refund-details">
                    <TextField
                      label={'Refund Amount'}
                      required={true}
                      value={this.state.refund_amount}
                      name="refund_amount"
                      type="number"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                  </div>
                  <div className="refund-actions">
                    <Button
                      onClick={() => this.refund(this.state.order?.id || '')}
                      text={'Refund'}
                    />
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              onClose={() => {
                this.toggleFormModal(false);
              }}
              show={this.state.showItemForm}
              title={
                (this.state.selectedPurchaseItem && this.state.selectedPurchaseItem.name) ||
                undefined
              }
            >
              <div className="purchase-item-form">
                {this.state.selectedPurchaseItem &&
                  this.state.selectedPurchaseItem?.form?.map((form: any, i: any) => {
                    switch (form.question_type) {
                      case 'text':
                        return (
                          <div
                            className="question"
                            key={i}
                          >
                            <div className="label">{form.question_label}</div>
                            <div className="answer">{form.answer}</div>
                          </div>
                        );
                      case 'dropdown':
                        let _options: IOptions[] = [];

                        for (let i in form.options) {
                          _options.push({
                            label: form.options[i].question_label,
                            value: form.options[i].question_label,
                          });
                        }

                        return (
                          <div
                            className="question"
                            key={i}
                          >
                            <div className="label">{form.question_label}</div>
                            <div className="answer">{form.answer}</div>
                          </div>
                        );
                      default:
                        return (
                          <div
                            className="question"
                            key={i}
                          >
                            <div className="label">{form.question_label}</div>
                            <div className="answer">{form.answer}</div>
                          </div>
                        );
                    }
                  })}
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  clearCart,
  downloadGroupPurchaseReceipt,
  refundPurchase,
  getTransactionItems,
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactSupport);
