import { IConversationMessage } from '@gigit/interfaces';
import moment from 'moment';
import React, { useEffect } from 'react';
import { uiConstants } from '../../../constants';
import Loader from '../../Loader/Loader';
import Portrait from '../../Portrait/Portrait';
import QuillTextEditor from '../../QuillTextEditor/QuillTextEditor';
import './Messages.scss';

interface IMessagesProps {
  userId: string;
  messages: Array<IConversationMessage>;
  isLoading: boolean;
  isUserTyping: string;
  lastItemRef: any;
}

export const Messages: React.FC<IMessagesProps> = (props: IMessagesProps) => {
  function getDateFormat(createdAtDate: Date) {
    const today = new Date();
    if (moment(today).format('ll') === moment(createdAtDate).format('LTS')) {
      return 'LT';
    } else if (moment(today).format('W') === moment(createdAtDate).format('W')) {
      return 'ddd h:mm A';
    } else {
      return 'lll';
    }
  }

  function renderMessage(message: IConversationMessage, index: number) {
    return (
      <React.Fragment key={index}>
        {(index === 0 && props.messages.length < parseInt('10')) ||
          (!!props.messages && (
            <div
              ref={props.lastItemRef}
              className="end-infinite-scroll"
            />
          ))}
        <div
          key={message.id}
          className={
            message?.created_by?.id === props.userId
              ? 'align-right message-container'
              : 'align-left message-container'
          }
          notranslate="yes"
        >
          <div
            className={
              message?.created_by?.id === props.userId ? 'sender message' : 'receiver message'
            }
          >
            <div className="message-header">
              {message?.created_by?.id !== props.userId && message?.created_by?.display_name}{' '}
              {moment(message.updated_at).format(
                getDateFormat(message.created_at || message.updated_at),
              )}
            </div>
            <QuillTextEditor
              value={message.content}
              readOnly={true}
              preserveWhitespace={true}
              theme={uiConstants.quill.readOnlyTheme}
              modules={{
                toolbar: uiConstants.quill.defaultToolbar,
                clipboard: {
                  matchVisual: false,
                },
              }}
              formats={uiConstants.quill.defaultFormats}
            />
          </div>
          <Portrait
            source={message?.created_by?.profile_image_url}
            size={40}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <div
      className="Messages"
      id="chat-box"
    >
      <div className="messages-wrap">
        {props.messages &&
          props.messages.map((message: IConversationMessage, index: number) => {
            return renderMessage(message, index);
          })}
      </div>
      {props.isUserTyping && (
        <div className="typing">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      )}
      {props.isLoading && <Loader loading={true} />}
    </div>
  );
};
