import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import moment, { Moment } from 'moment';
import { IAppState } from '../../store';
import { createToast } from '../../actions/toaster';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  formatCurrency,
  defaultCurrency,
  swapRouteParams,
  routes,
  toastError,
} from '../../helpers';
import { IKpi } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { IGroupState } from '../../reducers/group';
import { userSelectors } from '../../selectors/user';
import Loader from '../Loader/Loader';
import DatePicker from '../DatePicker/DatePicker';
import Button from '../Button/Button';
import './GroupSummary.scss';
import errorHelpers from '../../helpers/errorHelpers';
import { IToast } from '../../interfaces';

interface ISummaryMetric {
  amount?: number;
  currency?: boolean;
  className?: string;
  label: string;
}

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  setupCharity(): void;
  locale: string;
  createToast(toast: IToast): void;
}

interface IState {
  chartRef: React.Ref<typeof Line>;
  summaryMetrics: ISummaryMetric[][];
  graphData: IKpi;
  loading: boolean;
  startTime: Moment;
  endTime: Moment;
  charityStatus: string;
  givebackDate: Moment;
}

class GroupSummary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      chartRef: React.createRef(),
      summaryMetrics: [
        [
          { className: 'give-back', amount: -1, currency: true, label: 'Charity Give Back' },
          { className: 'graph', label: 'Total Transactions' },
        ],
        [
          { amount: -1, label: 'Volunteers' },
          { amount: -1, label: 'Donors' },
          { amount: -1, label: 'Fundraisers' },
        ],
        [
          { amount: -1, label: 'Supporters' },
          { amount: -1, label: 'Sponsors' },
          { amount: -1, label: 'Workers' },
        ],
        [
          { amount: -1, label: 'Total Volunteer Hours' },
          { amount: -1, currency: true, label: 'Total Donations' },
          { amount: -1, currency: true, label: 'Raised by Fundraisers' },
        ],
        [
          { amount: -1, label: 'Volunteer Opportunities' },
          { amount: -1, currency: true, label: 'Total Sponsorships' },
          { amount: -1, label: 'Events Run' },
        ],
      ],
      graphData: {
        labels: [],
        datasets: [],
      },
      loading: true,
      startTime: moment().startOf('month'),
      endTime: moment().endOf('month'),
      charityStatus: Constants.charity_verification_status.unverified,
      givebackDate: moment().set('month', 8).set('date', 1),
    };
  }

  componentDidMount() {
    this.getGroupMetrics();

    if (moment(this.state.givebackDate).format('X') < moment().format('X')) {
      this.setState({
        givebackDate: moment(this.state.givebackDate).add(1, 'year'),
      });
    }
  }

  getGroupMetrics() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios
          .get(
            swapRouteParams(routes.GET_GROUP_SUMMARY, {
              groupId: this.props.groupState.group.id,
              startDate: moment(this.state.startTime).format('YYYY-MM-DD'),
              endDate: moment(this.state.endTime).format('YYYY-MM-DD'),
            }),
          )
          .then((response) => {
            let r = response.data;

            this.setState({
              summaryMetrics: [
                [
                  {
                    className: 'give-back',
                    currency: true,
                    amount: r.give_back_reporting_amount,
                    label: 'Charity Give Back',
                  },
                  { className: 'graph', label: 'Total Transactions' },
                ],
                [
                  { amount: r.number_of_volunteers, label: 'Volunteers' },
                  { amount: r.number_of_donors, label: 'Donors' },
                  { amount: r.number_of_fundraisers, label: 'Fundraisers' },
                ],
                [
                  { amount: r.number_of_supporters, label: 'Supporters' },
                  { amount: r.number_of_sponsors, label: 'Sponsors' },
                  { amount: 0, label: 'Workers' },
                ],
                [
                  { amount: r.total_volunteer_hours, label: 'Total Volunteer Hours' },
                  { amount: r.total_donations_amount, currency: true, label: 'Total Donations' },
                  {
                    amount: r.total_fundraised_amount,
                    currency: true,
                    label: 'Raised by Fundraisers',
                  },
                ],
                [
                  { amount: r.number_of_volunteer_opportunities, label: 'Volunteer Opportunities' },
                  {
                    amount: r.total_sponsorship_amount,
                    currency: true,
                    label: 'Total Sponsorships',
                  },
                  { amount: r.number_of_events_ran, label: 'Events Run' },
                ],
              ],
              graphData: r.transaction_trends,
              charityStatus: r.charity_verification_status.code,
            });
          })
          .catch((error) => {
            const errorObject = errorHelpers.getErrorObject(error);
            const toast = toastError(errorObject.translatedMessage, 'Cause Metrics');
            this.props.createToast(toast);
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      },
    );
  }

  render() {
    let locale = this.props.locale;
    let currency = this.props.groupState.group.account?.currency || defaultCurrency;

    return (
      <div
        className={
          this.state.loading ? 'GroupSummary section-wrap loading' : 'GroupSummary section-wrap'
        }
      >
        <div className="section-title">
          <div className="section-inner-title">Summary</div>
        </div>
        <div className="section-inner">
          <div className="actions">
            <DatePicker
              label="Start Date"
              date={this.state.startTime}
              showTime={false}
              name="startTime"
              error={this.state.startTime.isAfter(this.state.endTime)}
              onChange={(d: any) => {
                this.setState({ startTime: moment(d).second(0).millisecond(0) }, () => {
                  if (!this.state.loading) {
                    this.getGroupMetrics();
                  }
                });
              }}
            />
            <DatePicker
              label="End Date"
              date={this.state.endTime}
              showTime={false}
              name="endTime"
              error={this.state.endTime.isBefore(this.state.startTime)}
              onChange={(d: any) => {
                this.setState({ endTime: moment(d).second(0).millisecond(0) }, () => {
                  if (!this.state.loading) {
                    this.getGroupMetrics();
                  }
                });
              }}
            />
          </div>
          <div className="metric-wrap">
            {this.state.summaryMetrics.map((row, ri) => {
              return (
                <div
                  key={ri}
                  className={ri === 0 ? 'row main-row' : 'row'}
                >
                  {row.map((metric, i) => {
                    if (metric.className === 'give-back') {
                      return null;
                    } else {
                      return (
                        <div
                          key={i}
                          className={metric.className ? 'metric ' + metric.className : 'metric'}
                        >
                          {this.state.loading && <Loader loading={true} />}
                          {!this.state.loading &&
                            metric.amount !== undefined &&
                            metric.className !== 'graph' && (
                              <span
                                className="amt"
                                notranslate="yes"
                              >
                                {metric.currency
                                  ? formatCurrency(metric.amount, currency, locale)
                                  : metric.amount}
                              </span>
                            )}
                          <span className="label">{metric.label}</span>
                          {!this.state.loading && metric.className === 'give-back' && (
                            <div className="give-back-date">
                              {moment(this.state.givebackDate).format('MM / DD / YYYY')}
                            </div>
                          )}
                          {!this.state.loading && metric.className === 'graph' && (
                            <div className="graph-wrap">
                              <Line
                                // ref={this.state.chartRef}
                                data={this.state.graphData}
                                options={{
                                  maintainAspectRatio: false,
                                  responsive: true,
                                  // legend: {
                                  //     display: false
                                  // }
                                }}
                              />
                            </div>
                          )}
                          {!this.state.loading &&
                            metric.className === 'give-back' &&
                            this.state.charityStatus !==
                              Constants.charity_verification_status.verified && (
                              <div className="give-back-overlay">
                                <span className="msg">
                                  Become a verified charity to start earning your Kambeo Give-back
                                </span>
                                <Button
                                  text="Setup Charity"
                                  onClick={() => {
                                    this.props.setupCharity();
                                  }}
                                />
                              </div>
                            )}
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupSummary));
