import { Config } from '@gigit/config';
import {
  IConversation,
  IConversationMessage,
  IConversationStart,
  IConversationSummary,
  IUser,
} from '@gigit/interfaces';
import axios from 'axios';
import { routes, swapRouteParams } from '../helpers';

export namespace chatRequestActions {
  export async function getUserConversations(): Promise<IConversationSummary[]> {
    const response = await axios.get(Config.web.REACT_APP_CHAT + routes.GET_CONVERSATIONS);
    return response.data;
  }

  export async function getConversationMessages(
    conversationId: string,
    queryParams?: URLSearchParams,
  ): Promise<IConversationMessage[]> {
    const response = await axios.get(
      Config.web.REACT_APP_CHAT +
        swapRouteParams(routes.GET_CONVO_MESSAGES, { convoId: conversationId }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function sendMessage(message: IConversationMessage) {
    const response = await axios.post(
      Config.web.REACT_APP_CHAT + swapRouteParams(routes.SEND_MESSAGE, { convoId: message.id }),
      message,
    );
    return response.data;
  }

  export async function startConversation(payload: IConversationStart): Promise<IConversation> {
    const response = await axios.post(
      Config.web.REACT_APP_CHAT + routes.START_CONVERSATION,
      payload,
    );
    return response.data;
  }

  export async function getConversationUsers(conversationId: string): Promise<IUser[]> {
    const response = await axios.get(
      swapRouteParams(Config.web.REACT_APP_CHAT + routes.GET_CONVERSATION_USERS, {
        id: conversationId,
      }),
    );
    return response.data;
  }

  export async function getOnlineUsers(): Promise<string[]> {
    const response = await axios.get(Config.web.REACT_APP_CHAT + routes.GET_ONLINE_USERS);
    return response.data;
  }
}
