import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { Link, RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  IStringMap,
  handleInputChange,
  swapRouteParams,
  routes,
  errorHelpers,
  toastError,
} from '../../../helpers';
import {
  IAccountPublicSummary,
  IAuctionItemSummary,
  ICampaign,
  IEventSummaryFE,
  IGroup,
  IPage,
} from '@gigit/interfaces';
import typeHelpers from '../../../helpers/typeHelpers';

import { updateEventPageComponent } from '../../../actions/event';
import { updateGroupPageComponent } from '../../../actions/group';

import TextField from '../../TextField/TextField';
import AuctionItem from '../AuctionItem/AuctionItem';

import './Auction.scss';
import ComponentPlaceholder from '../ComponentPlaceholder/ComponentPlaceholder';
import { getNoCampaignOrNonMonetizedErrorMessage } from '../ComponentPlaceholder/helpers/helpers';
import PageComponentPaginator from '../shared/PageComponentPaginator/PageComponentPaginator';
import CategoriesFilterBar from '../shared/CategoriesFilterBar/CategoriesFilterBar';
import { IOwnerObject } from '../../../interfaces/ownerObject';
import { IAuctionComponent } from '../../../interfaces';
import { Constants } from '@gigit/constants';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import axios from 'axios';
import { createToast } from '../../../actions/toaster';
import Loader from '../../Loader/Loader';

const AUCTION_ITEM_DROPPABLE_TYPE = 'AUCTION_ITEM_DROPPABLE';
const AUCTION_ITEMS_PER_PAGE = 10;

interface IPassedProps extends WithTranslation, RouteComponentProps<any> {
  owner: IOwnerObject;
  page: IPage;
  component: IAuctionComponent;
  permissions?: IStringMap;
  edit: boolean;
  onManageEventsClicked: () => void;
  hasEditPermissions?: boolean;
  onCreateAuctionItemClicked?: () => void;
  onComponentUpdate?(): void;
  campaign?: ICampaign | null;
  currentPageIndex: number;
}

interface IPropsFromDispatch {
  updateGroupPageComponent(
    groupId: string,
    page_id: string,
    component_id: string,
    payload: any,
  ): void;
  updateEventPageComponent(
    eventId: string,
    _pageId: string,
    _componentId: string,
    _payload: any,
  ): void;
}

type IProps = IPassedProps & IPropsFromDispatch;

interface IState {
  title: string;
  auctionItems: IAuctionItemSummary[];
  droppablePageNumber: number | null;
  filter: string | null;
  accountInfo: IAccountPublicSummary | undefined;
  isLoadingAccountInfo: boolean;
}

class Auction extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      title: this.props.component.title || 'Auction',
      auctionItems: this.props.component.content_objects || [],
      droppablePageNumber: null,
      filter: null,
      accountInfo: undefined,
      isLoadingAccountInfo: false,
    };

    this.renderComponentPlaceholder = this.renderComponentPlaceholder.bind(this);
    this.renderErrorMessageComponent = this.renderErrorMessageComponent.bind(this);
  }

  componentDidMount(): void {
    this.isParentMonetized();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.component.meta_data !== this.props.component.meta_data) {
      this.setState({
        title: this.props.component.title || 'Auction',
      });
    }

    if (prevProps.component.content_objects !== this.props.component.content_objects) {
      this.setState({
        auctionItems: this.props.component.content_objects || [],
      });
    }

    if (prevProps.edit && !this.props.edit) {
      this.update();
    }
  }

  isParentMonetized() {
    const isHub = this.props.owner.parentOwnerType === 'hub';
    const ownerId = isHub
      ? (this.props.owner?.object as IEventSummaryFE)?.hub_id
      : (this.props.owner?.object as IEventSummaryFE).group_id;
    const ownerType = this.props.owner.parentOwnerType;

    this.setState({
      isLoadingAccountInfo: true,
    });

    return axios
      .get<IAccountPublicSummary>(
        swapRouteParams(routes.ACCOUNTS.GET_OBJECT_CURRENT_ACCOUNT_PUBLIC_SUMMARY, {
          object_type: ownerType,
          object_id: ownerId,
        }),
      )
      .then((response) => {
        this.setState({
          accountInfo: response.data,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Fetching Parent Account');
        createToast(toast);
        this.setState({
          accountInfo: undefined,
        });
      })
      .finally(() => {
        this.setState({
          isLoadingAccountInfo: false,
        });
      });
  }

  update() {
    const payload = this.props.component;

    payload.title = this.state.title;

    if (this.props.permissions && this.props.permissions['EDIT_GROUP_PAGES']) {
      this.props.updateGroupPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    } else if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
      this.props.updateEventPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    }
  }

  renderErrorMessageComponent() {
    if (this.state.isLoadingAccountInfo) {
      return null;
    }

    const entityType = this.props.owner.parentOwnerType === 'hub' ? 'Company' : 'Cause';
    const { errorMessage, linkText } = getNoCampaignOrNonMonetizedErrorMessage(
      this.state.accountInfo?.charges_enabled ?? false,
      true,
      entityType,
    );

    if (errorMessage && typeHelpers.isOwnerObjectOfType(this.props.owner, 'group')) {
      return (
        <>
          {errorMessage}{' '}
          <Link to={`/group/${this.props.owner.object.handle}/admin?t=campaigns`}>{linkText}</Link>
        </>
      );
    } else if (errorMessage && typeHelpers.isOwnerObjectOfType(this.props.owner, 'event')) {
      return (
        <>
          {errorMessage}{' '}
          <Link to={`/event/${this.props.owner.object.handle}/admin?t=settings`}>{linkText}</Link>
        </>
      );
    }

    return null;
  }

  handleComponentDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (result.destination !== null) {
      let _auctionItems = this.state.auctionItems;
      const pageOffsetIndex =
        this.state.droppablePageNumber != null
          ? this.state.droppablePageNumber * AUCTION_ITEMS_PER_PAGE - 1
          : AUCTION_ITEMS_PER_PAGE - 1;

      if (!this.state.filter) {
        _auctionItems.splice(
          this.state.droppablePageNumber ? pageOffsetIndex : result.destination?.index || 0,
          0,
          _auctionItems.splice(result.source.index, 1)[0],
        );
      } else {
        let filteredItems = _auctionItems.filter((item) => item.category === this.state.filter);
        let tmp = [] as IAuctionItemSummary[];

        filteredItems.splice(
          this.state.droppablePageNumber ? pageOffsetIndex : result.destination?.index || 0,
          0,
          filteredItems.splice(result.source.index, 1)[0],
        );

        let i = 0;
        _auctionItems.forEach((item, index) => {
          if (filteredItems.map((i) => i.id).includes(item.id)) {
            tmp.push(filteredItems[i++]);
          } else {
            tmp.push(item);
          }
        });

        _auctionItems = tmp;
      }

      this.setState(
        {
          auctionItems: _auctionItems,
        },
        () => {
          if (this.props.page) {
            const _object_ids = _auctionItems.map(({ id }) => {
              typeHelpers.assertNotNullOrUndefined(id, 'Expected Auction ID');
              return id;
            });

            if (
              this.props.permissions &&
              this.props.permissions['EDIT_GROUP_PAGES'] &&
              this.props.owner.ownerType !== 'gig'
            ) {
              if (this.props.updateGroupPageComponent) {
                this.props.updateGroupPageComponent(
                  this.props.page.owner_id,
                  this.props.page.id,
                  this.props.component.id,
                  {
                    content_references: {
                      object_type: Constants.object_type.auction_item,
                      object_ids: _object_ids,
                    },
                  },
                );
              }
            } else if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
              this.props.updateEventPageComponent(
                this.props.page.owner_id,
                this.props.page.id,
                this.props.component.id,
                {
                  content_references: {
                    object_type: Constants.object_type.auction_item,
                    object_ids: _object_ids,
                  },
                },
              );
            }
          }
        },
      );
    }
  };

  renderComponentPlaceholder() {
    const { auctionItems } = this.state;

    if (
      this.props.hasEditPermissions &&
      (auctionItems || []).length === 0 &&
      this.props.onCreateAuctionItemClicked
    ) {
      return (
        <ComponentPlaceholder
          title={'Welcome to your new Auction component!'}
          message={'Add items to your auction to get started'}
          errorMessageComponent={this.renderErrorMessageComponent()}
          actions={[
            {
              label: 'Create Auction Item',
              iconClassName: 'fas fa-plus-circle',
              action: this.props.onCreateAuctionItemClicked,
            },
            {
              label: 'Manage Auctions',
              iconClassName: 'fas fa-cog',
              action: () => this.props.onManageEventsClicked(),
            },
          ]}
        />
      );
    }
  }

  render() {
    const { auctionItems } = this.state;
    return (
      <div className="Auction">
        <div className="title">
          {this.props.edit && (
            <TextField
              value={this.state.title}
              name="title"
              type="text"
              onChange={(e: any) => {
                handleInputChange(e, this);
              }}
            />
          )}
          {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}
        </div>
        {this.state.isLoadingAccountInfo && (
          <div className="loader">
            <Loader loading={true} />
          </div>
        )}
        {auctionItems && auctionItems.length > 0 && (
          <DragDropContext onDragEnd={this.handleComponentDragEnd}>
            <Droppable
              type={AUCTION_ITEM_DROPPABLE_TYPE}
              droppableId="auction-items-droppable"
            >
              {(provided, snapshot) => (
                <div
                  className={
                    snapshot.isDraggingOver ? 'components-wrap dragging' : 'components-wrap'
                  }
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <CategoriesFilterBar
                    items={auctionItems}
                    setFilterToParent={(filter) => {
                      this.setState({ filter });
                    }}
                  >
                    {(filteredItems) => (
                      <PageComponentPaginator
                        itemsPerPage={AUCTION_ITEMS_PER_PAGE}
                        items={filteredItems}
                        droppableItem={{
                          droppableType: AUCTION_ITEM_DROPPABLE_TYPE,
                          onPageDrop: (pageNumber) =>
                            this.setState({ droppablePageNumber: pageNumber }),
                        }}
                      >
                        {(pagedItems, pageStartIndex) => (
                          <div className="auction-item-page-container">
                            {pagedItems.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={'auction-item-' + item.id}
                                index={index + pageStartIndex}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <AuctionItem
                                      {...this.props}
                                      item={item}
                                      key={item.id}
                                      dragProps={provided.dragHandleProps}
                                      accountInfo={this.state.accountInfo}
                                      isLoadingAccountInfo={this.state.isLoadingAccountInfo}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </PageComponentPaginator>
                    )}
                  </CategoriesFilterBar>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {this.renderComponentPlaceholder()}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps: IPropsFromDispatch = {
  updateEventPageComponent,
  updateGroupPageComponent,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(Auction),
);
