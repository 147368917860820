import React from 'react';
import { userSelectors } from '../selectors/user';
import { useAppSelector } from '../store';

/** Hook that returns current locale information. */
export function useLocale() {
  const store = useAppSelector((state) => state);
  const currentLocale = userSelectors.getCurrentLocale(store);

  return {
    /** This is the current locale string. */
    currentLocale,
  };
}
