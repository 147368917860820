import React, { FC, ReactNode } from 'react';
import './CardHeader.scss';
import noCoverImg from '../../../assets/no-cover-img-new.svg';

interface IProps {
  children?: ReactNode;
  loading?: boolean;
  className?: string;
  profileImg?: string;
  coverImg?: string;
  cardType?: string;
}

export const CardHeader: FC<IProps> = (props: IProps) => {
  const { profileImg, coverImg, loading, className, children, cardType } = props;

  const getContent = () => {
    return (
      <React.Fragment>
        <div
          className={`cover-image-holder ${!coverImg ? 'no-cover' : ''}`}
          style={{
            backgroundImage: `url(${coverImg || noCoverImg})`,
          }}
        ></div>
        <div className="profile-img">
          {profileImg !== '' && (
            <img
              src={profileImg}
              alt="empty"
            />
          )}
        </div>
        {cardType && <p className="card-type">{cardType}</p>}

        {children}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className={`card-header ${props.className && props.className}`}>
        {props.loading ? 'Loading css' : getContent()}
      </div>
    </React.Fragment>
  );
};
