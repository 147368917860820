import { IConversationSummary } from '@gigit/interfaces';
import React, { useEffect } from 'react';
import { ConversationTile } from '../../Chat/ConversationTile/ConversationTile';

import './ConversationHeader.scss';

interface IConversationHeaderProps {
  conversation: IConversationSummary;
  currentUserId: string;
}

export const ConversationHeader: React.FC<IConversationHeaderProps> = (
  props: IConversationHeaderProps,
) => {
  const { conversation, currentUserId } = props;

  return (
    <div className="ConversationHeader">
      <ConversationTile
        conversation={conversation}
        type="contact"
        userId={currentUserId}
        unreadCount={0}
        showContext={true}
      />
    </div>
  );
};
