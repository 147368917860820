import React from 'react';
import { ISpotlightList } from '@gigit/interfaces';
import Portrait from '../../../Portrait/Portrait';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
import { useLocale } from '../../../../hooks';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';

interface ISpotlightItem {
  item: ISpotlightList;
  onEdit: (item: ISpotlightList) => void;
  onDelete: (item: ISpotlightList) => void;
}
export default function HubManagementSpotlightItem(props: ISpotlightItem) {
  const locale = useLocale();
  const { item, onEdit, onDelete } = props;
  return (
    <div className="spotlight-cause">
      <div className="cause-info">
        <Portrait
          source={item?.profile_image_url}
          size={30}
        />
        <div
          className="cause-title"
          notranslate="yes"
        >
          {' '}
          <p>{item.title}</p>{' '}
        </div>
      </div>
      <div className="program-start-date">
        {localizeHelpers.formatDate(
          item.spotlight_start,
          LocaleDateFormats.L,
          locale.currentLocale,
        )}
      </div>
      <div className="program-end-date">
        {localizeHelpers.formatDate(item.spotlight_end, LocaleDateFormats.L, locale.currentLocale)}
      </div>
      <div className="spotlight-actions">
        <div
          className="icon-wrapper"
          onClick={() => onEdit(item)}
        >
          {' '}
          <EditIcon />{' '}
        </div>
        <div
          className="icon-wrapper"
          onClick={() => onDelete(item)}
        >
          {' '}
          <DeleteIcon />{' '}
        </div>
      </div>
    </div>
  );
}
