import React from 'react';
import { capitalizeString, combineClassNames } from '../../helpers';
import { changeGroupStatus } from '../../actions/group';
import { connect } from 'react-redux';
import { localizeHelpers } from '../../localizeHelpers';
import './ChangeStatusMenu.scss';
import { Prompt } from '../Prompt/Prompt';
import { Constants } from '@gigit/constants';
import { IEventSummaryFE, IGroup, IHub } from '@gigit/interfaces';
import { ReduxActionType } from '../../interfaces';
import { changeEventStatus } from '../../actions/event';

interface IProps {
  listOfStatuses?: string[];
  currentStatus?: string | undefined;
  onStatusChanged?: (status: string) => void;
  itemType: string;
  handle?: string;
  group?: IGroup | null | undefined;
  hub?: IHub | null;
  event?: IEventSummaryFE | null;
  className?: string;
  changeGroupStatus: ReduxActionType<typeof changeGroupStatus>;
  changeEventStatus: ReduxActionType<typeof changeEventStatus>;
}

interface IState {
  showMenu: boolean;
  yesButtonText: string | null;
  promptMessage: string;
  updatedStatus: string;
  errorlink: string | null;
  errorLinkText: string | null;
  updateGroup: boolean;
  updateEvent: boolean;
}

class ChangeStatusMenu extends React.Component<IProps, IState> {
  private readonly menuRef = React.createRef<HTMLUListElement>();
  private readonly menuButtonRef = React.createRef<HTMLDivElement>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      yesButtonText: null,
      updateGroup: false,
      updateEvent: false,
      showMenu: false,
      promptMessage: '',
      updatedStatus: '',
      errorlink: null,
      errorLinkText: null,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    if (
      this.state.showMenu &&
      !this.menuRef?.current?.contains(event.target) &&
      !this.menuButtonRef?.current?.contains(event.target)
    ) {
      this.setState({
        showMenu: false,
      });
    }
  }

  changeStatus(status: string) {
    this.setState({ showMenu: false });
    let message = '';
    let link = '';
    let yesButtonText = '';
    let updateGroup = false;
    let updateEvent = false;
    const isGroupMonetized = !!(this.props.group?.account || this.props.event?.group?.account);
    const isHubMonetized = !!(this.props.hub?.account || this.props.event?.hub?.account);

    switch (this.props.itemType.toLocaleLowerCase()) {
      case Constants.object_type.group:
        {
          if (status === Constants.group_status.draft) {
            message = localizeHelpers.translate(
              'All events and volunteer opportunities for this cause will also be put in to a Draft Status',
            );
          }
          if (status === Constants.group_status.archived) {
            message = localizeHelpers.translate(
              'All events and volunteer opportunities for this cause will also be put in to an Archived Status. This can not be undone',
            );
          }
          if (status === Constants.group_status.active && !isGroupMonetized) {
            message = localizeHelpers.translate(
              "Your cause isn't currently setup to accept payments. You won't be able to accept donations, sell tickets, and other transactions until you",
            );
            yesButtonText = localizeHelpers.translate('Update Status');
            link = `/group/${this.props.handle}/admin?t=balance_and_payouts`;
          }
        }
        break;
      case Constants.object_type.event:
        {
          if (status === Constants.event_status.draft) {
            message = localizeHelpers.translate(
              'All volunteer opportunities for this event will also be put in to a Draft Status',
            );
          }
          if (status === Constants.event_status.archived) {
            message = localizeHelpers.translate(
              'All volunteer opportunities for this event will also be put in to an Archived Status. This can not be undone',
            );
          }
          if (
            status === Constants.event_status.published &&
            !isGroupMonetized &&
            this.props.group
          ) {
            message = localizeHelpers.translate(
              "Your event isn't currently setup to accept payments. You won't be able to accept donations, sell tickets, and other transactions until you monetize your cause",
            );
            yesButtonText = localizeHelpers.translate('Update Status');
            break;
          }
          if (status === Constants.event_status.published && !isHubMonetized && this.props.hub) {
            message = localizeHelpers.translate(
              "Your event isn't currently setup to accept payments. You won't be able to accept donations, sell tickets, and other transactions until you monetize your company page.",
            );
            yesButtonText = localizeHelpers.translate('Update Status');
            break;
          }
          // TODO: CHECK IF SAME IS TRUE FOR COMPANY
          if (
            status === Constants.gig_status.published &&
            this.props.group &&
            this.props.group?.status?.code !== Constants.group_status.active
          ) {
            message = localizeHelpers.translate(
              `Publishing this event will also set your Cause page {{group_handle}} to Published. Are you sure you wish to continue?`,
              { group_handle: this.props.group?.handle },
            );
            updateGroup = true;
          }
        }
        break;
      case Constants.object_type.gig:
        {
          if (status === Constants.gig_status.draft) {
            message = localizeHelpers.translate('Gig will be put in to a Draft Status');
          }
          if (status === Constants.gig_status.archived) {
            message = localizeHelpers.translate(
              'Gig will be put in to an Archived Status. This can not be undone',
            );
          }

          if (
            status === Constants.gig_status.published &&
            this.props.group &&
            this.props.group?.status?.code !== Constants.group_status.active
          ) {
            message = localizeHelpers.translate(
              `Publishing this gig will also set your Cause page {{group_handle}} to Published. Are you sure you wish to continue?`,
              { group_handle: this.props.group?.handle },
            );
            updateGroup = true;
          }

          if (
            status === Constants.gig_status.published &&
            this.props.event &&
            this.props.event?.status?.code !== Constants.event_status.active
          ) {
            message = localizeHelpers.translate(
              `Publishing this gig will also set your Event page {{event_handle}} to Published. Are you sure you wish to continue?`,
              { event_handle: this.props.event?.handle },
            );
            updateEvent = true;
          }
        }
        break;
    }

    if (message) {
      this.setState({
        //setting data for prompt
        updatedStatus: status,
        promptMessage: message,
        errorlink: link,
        yesButtonText,
        updateGroup: updateGroup,
        updateEvent: updateEvent,
        errorLinkText: 'monetize your page',
      });
    } else {
      this.props.onStatusChanged?.(status);
    }
  }

  async onStatusChangeClicked() {
    // TODO: This is a bad way of updating parent status. We should move this logic to the BE and use a `force` flag to auto update parent if user has the permissions.
    if (this.state.updateGroup && this.props.group?.id) {
      this.props.changeGroupStatus(
        this.props.group.id,
        Constants.group_status.active,
        this.props.group?.title || '',
        this.props.group?.handle || '',
        (updatedGroup) => {
          this.props.onStatusChanged?.(Constants.gig_status.published);
        },
      );
    } else if (this.state.updateEvent && this.props.event?.id) {
      this.props.changeEventStatus(
        this.props.event.id,
        Constants.event_status.active,
        (updatedEvent) => {
          this.props.onStatusChanged?.(Constants.gig_status.published);
        },
      );
    } else {
      this.props.onStatusChanged?.(this.state.updatedStatus);
    }
    this.setState({ promptMessage: '', updatedStatus: '' });
  }

  render() {
    return (
      <div className="ChangeStatusMenu">
        {this.props.currentStatus && (
          <div
            ref={this.menuButtonRef}
            className={combineClassNames(
              'current-status status',
              this.props.itemType === 'GIG' && this.props.currentStatus === 'active'
                ? 'published'
                : this.props.currentStatus,
              this.props.className,
            )}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ showMenu: !this.state.showMenu });
            }}
          >
            {capitalizeString(this.props.currentStatus)}
            {
              <i
                className={combineClassNames(
                  `fa fa-chevron-down ${this.state.showMenu ? 'show' : ''}`,
                )}
              />
            }
            <ul
              ref={this.menuRef}
              className={`status-menu ${this.state.showMenu ? 'show' : ''}`}
            >
              {this.props.listOfStatuses?.map((status: string, index) => {
                return (
                  <li key={index}>
                    <span
                      onClick={() => {
                        this.changeStatus(status);
                      }}
                      className={`status ${status}`}
                    >
                      {capitalizeString(status)}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        <Prompt
          show={this.state.promptMessage.length > 0}
          title="Change status"
          message={this.state.promptMessage}
          yesMessage={this.state.yesButtonText ? this.state.yesButtonText : 'Yes'}
          link={this.state.errorlink ?? undefined}
          linkText={this.state.errorLinkText ?? undefined}
          yesClass="fas fa-check"
          yesStyle="normal"
          cancelMessage="Cancel"
          onYes={() => this.onStatusChangeClicked()}
          onClose={() => {
            this.setState({ promptMessage: '', updatedStatus: '' });
          }}
        />
      </div>
    );
  }
}
const mapDispatchToProps = {
  changeGroupStatus,
  changeEventStatus,
};
export default connect(null, mapDispatchToProps)(ChangeStatusMenu);
