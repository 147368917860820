import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import {
  ICharityVerificationRequest,
  ICreateGroupFE,
  IGroup,
  ILocalization,
  IPage,
  IPageFE,
} from '@gigit/interfaces';

import { InfoSection } from '../../components/shared/Onboarding/InfoSection/InfoSection';
import { FormSection } from '../../components/shared/Onboarding/FormSection/FormSection';
import { CheckboxCard } from '../../components/shared/Onboarding/CheckboxCard/CheckboxCard';
import { CauseSelection } from '../../components/shared/Onboarding/CausesSelection/CauseSelection';
import { ImageSelect } from '../../components/shared/Onboarding/imageSelect/ImageSelect';
import { EntityBaseInfo } from '../../components/shared/Onboarding/EntityBaseInfo/EntityBaseInfo';
import { PaymentComponent } from '../../components/shared/PaymentComponent/PaymentComponent';
import TextField from '../../components/TextField/TextField';
import {
  businessSteps,
  groupStepsMaster,
  groupTypeOptions,
  nonProfitSteps,
  nonProfitTypeOptions,
  schoolGroupSteps,
  schoolTypeOptions,
  planSelection,
} from './Config';
import {
  ICausesSelection,
  IEntityBaseInfo,
  IGroupClassifications,
  IOnboardStep,
  IPaymentInfo,
  IPriceListing,
} from '../../components/shared/Onboarding/interfaces';
import { GroupClassification } from '../../components/shared/Onboarding/GroupClassifications/GroupClassification';
import { LocaleSelect } from '../../components/shared/Onboarding/LocaleSelect/LocaleSelect';

import './CreateAGroup.scss';
import { SummaryPage } from '../../components/shared/Onboarding/SummaryPage/SummaryPage';
import _ from 'lodash';
import { FlowConfigComponents, FlowConfigPages } from '../Flows/FlowConfigs';
import {
  formatCurrency,
  routes,
  setSEOMetatags,
  toastSuccess,
  uploadImageToStore,
} from '../../helpers';
import { FormTopBar } from '../../components/shared/Onboarding/FormTopBar/FormTopBar';
import { FormBottomBar } from '../../components/shared/Onboarding/FormBottomBar/FormBottomBar';
import { uiConstants } from '../../constants/uiConstants';
import OnboardingWrapper from '../../components/shared/Onboarding/OnboardingWrapper';
import { localizeHelpers } from '../../localizeHelpers';
import { groupRequestActions } from '../../requestActions';
import {
  hasAllRequiredFields,
  makePageSequence,
  onBack,
  onSelectCause,
  setCauses,
} from '../../components/shared/Onboarding/helpers';
import { useCauseInfo } from '../../components/shared/Onboarding/hooks/useCauseInfo';
import { useBaseInfo } from '../../components/shared/Onboarding/hooks/useBaseInfo';
import { usePaymnetInfo } from '../../components/shared/Onboarding/hooks/usePaymentInfo';
import useToastDispatcher from '../../hooks/useToaster';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { userSelectors } from '../../selectors/user';
import { Constants } from '@gigit/constants';
import { PaymentRequestPaymentMethodEvent, PaymentRequest } from '@stripe/stripe-js';
import axios from 'axios';
import { billingRequestActions } from '../../requestActions/billing';
import CharitiesSelection from '../../components/shared/Onboarding/CharitiesSelection/CharitiesSelection';
import Dropdown from '../../components/Dropdown/Dropdown';

const skippableSteps = ['charitiesSelectionGroup'];
// set mappings from config
const nonProfit = groupTypeOptions[0].id;
const school = groupTypeOptions[1].id;
const business = groupTypeOptions[2].id;

interface IProps extends RouteComponentProps<{ step: string }> {}

export interface ICreateGroupFormData {
  baseInfo: IEntityBaseInfo;
  paymentInfo: IPaymentInfo;
  causeInfo: ICausesSelection;
  classificationInfo?: IGroupClassifications;
  groupType: string;
  subType: string;
  planType: string;
  redirect: string;
  steps: Array<IOnboardStep>;
  charityId: string;
  localization: ILocalization;
  profileImage: string;
  coverImage: string;
  loading: boolean;
  eventType: string;
  eventCreatedFor: string;
  claimedCharity: IGroup;
  charityUrl: string;
  charityEmail: string;
  charityPhone: string;
}

const CreateAGroup: React.FC<IProps> = (props: IProps) => {
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const isLoggedIn = useSelector((state: IAppState) => userSelectors.isUserAuthenticated(state));
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  // params handling
  const params = queryString.parse(window.location.search);
  const baseRedirectParam = decodeURIComponent(params?.redirect?.toString() || '');
  const eventTypeParam = decodeURIComponent(params?.type?.toString() || '');
  const eventCreatedForParam = decodeURIComponent(params?.createdFor?.toString() || '');
  // State
  const { baseInfo, handleBaseInfo } = useBaseInfo();
  const { causeInfo, handleCauseInfo } = useCauseInfo();
  const { paymentInfo, handlePaymentInfo } = usePaymnetInfo();
  const [classificationInfo, setClassificationInfo] = useState<IGroupClassifications>({
    classifications: [],
    groupClass: '',
    groupSubClass: '',
  });
  const [groupType, setGroupType] = useState<string>('');
  const [subType, setSubType] = useState<string>('');
  const [planType, setPlanType] = useState<string>('');
  const [redirect] = useState<string>(baseRedirectParam);
  const [eventType] = useState<string>(eventTypeParam);
  const [eventCreatedFor] = useState<string>(eventCreatedForParam);
  const [steps, setSteps] = useState<IOnboardStep[]>(nonProfitSteps);
  const [charityId, setCharityId] = useState<string>('');
  const [localization, setLocalization] = useState<ILocalization>({
    country: '',
    defaultTimezone: '',
    state: '',
  });
  const [profileImage, setProfileImage] = useState<string>('');
  const [coverImage, setCoverImage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentMethodId, setPaymentMethodId] = useState<string>('');
  const [paymentRequestEvent, setPaymentRequestEvent] =
    useState<PaymentRequestPaymentMethodEvent>();
  const [altPaymentButton, setAltPaymentButton] = useState<boolean>(false);
  const [triggerPaymentPrep, setTriggerPaymentPrep] = useState<boolean>(false);
  const [pricing, setPricing] = useState<IPriceListing[]>([]);
  const [charityUrl, setCharityUrl] = useState<string>('');
  const [charityPhone, setCharityPhone] = useState<string>(user?.phone || '');
  const [charityEmail, setCharityEmail] = useState<string>(user?.email || '');
  // Claims Charity and hydrates available info on baseInfo step
  const [claimedCharity, setClaimedCharity] = useState<IGroup>({} as IGroup);
  const { dispatchToastError } = useToastDispatcher();

  // View Helpers
  const navigateHome = () => props.history.push('/');
  const showBack = steps.findIndex((step) => step.id === getCurrentStep());

  // Attempt to properly encode the redirect url when redirecting to login page.
  const redirectUrl = window.encodeURIComponent(
    `/onboarding/group/${props.match.params.step}/${window.location.search ? window.location.search : ''}`,
  );
  const createAGroupLoginRedirect = `/login?redirect=${redirectUrl}`;

  useEffect(() => {
    // If we re-mount, move back to first step.
    // This ensure the flow never breaks if navigating to a specific step.

    setSEOMetatags({
      title: localizeHelpers.translate('Create A Cause | Kambeo'),
      urlPath: '/onboarding/group',
    });

    if (!isLoggedIn) {
      return;
    }

    billingRequestActions
      .getAvailableSubscriptions(Constants.billing.subscriptions.purple_plus.object_type)
      .then((response) => {
        //
        // TODO: Pricing should be looked at
        //
        setPricing([
          {
            label: 'Purple+ Fee',
            additionalInfo: `${formatCurrency(
              response[0].monthly_price,
              response[0].currency,
            )} / Month x 12`,
            price: formatCurrency(
              Math.round(response[0].annual_price * 100) / 100,
              response[0].currency,
            ),
          },
          {
            label: 'Total Price',
            price: `${formatCurrency(Math.round(response[0].annual_price * 100) / 100, response[0].currency)}`,
            totalInCents: Math.round(response[0].annual_price * 100),
            isTotal: true,
          },
        ]);
      });
    const firstStepId = groupStepsMaster[0]?.id;
    if (getCurrentStep() !== firstStepId) {
      setCurrentStep(firstStepId);
    }

    setCauses(causeInfo, handleCauseInfo);
  }, []);

  useEffect(() => {
    if (paymentInfo.isAltMethod) {
      createGroup();
    }
  }, [paymentMethodId]);

  useEffect(() => {
    setCharityId(''); // clear charity Id incase user previously set this and decided to change type
    // Do not reset the sub type for claimed charities
    // setSubType(""); // same point as above but in this case the sub tyep.
    switch (groupType) {
      case nonProfit: {
        return setCharityIdRequiredState();
      }
      case school: {
        return setSteps(schoolGroupSteps);
      }
      case business: {
        return setSteps(businessSteps);
      }
      default:
        break;
    }
  }, [groupType]);

  const nxtBtnText = (): string => {
    if (getCurrentStep() === 'summary') {
      return 'Create Cause';
    }

    return 'Next';
  };

  function getCurrentStep() {
    return props.match.params.step;
  }

  function renderStep() {
    switch (getCurrentStep()) {
      case 'charitiesSelectionGroup': {
        return renderCharitiesSelection();
      }
      case 'groupTypeSelection': {
        return renderGroupTypeSelection();
      }
      case 'schoolTypeSelection': {
        return renderSchoolSelection();
      }
      case 'nonProfitSelection': {
        return renderNonProfitSelection();
      }
      case 'groupInfo': {
        return renderBasicInfo();
      }
      case 'causesSelection': {
        return renderCausesSelection();
      }
      case 'imageSelection': {
        return renderImageSelection();
      }
      case 'planSelection': {
        return renderPlanSelection();
      }
      case 'paymentInfo': {
        if (planType && planType === Constants.billing.subscriptions.purple_plus.code) {
          return renderPaymentInfo();
        } else {
          setCurrentStep('summary');
          break;
        }
      }
      case 'summary': {
        return renderSummary();
      }
      default:
        break;
    }
  }

  function renderCharitiesSelection() {
    return (
      <div className="form-page column">
        <CharitiesSelection
          selected={claimedCharity || null}
          external={true}
          type="group"
          onChange={(list: IGroup[]) => setClaimedCharity(list[0])}
        />
      </div>
    );
  }

  function renderGroupTypeSelection() {
    return (
      <div className="form-page column">
        {groupTypeOptions.map((option) => {
          return (
            <CheckboxCard
              showCheckbox={false}
              key={option.id}
              id={option.id}
              title={option.text}
              checked={groupType === option.id}
              subtext={option?.subtext}
              onChange={setGroupType}
            />
          );
        })}
      </div>
    );
  }

  function renderSchoolSelection() {
    return (
      <div className="form-page column">
        {schoolTypeOptions.map((option) => {
          return (
            <CheckboxCard
              showCheckbox={false}
              key={option.id}
              id={option.id}
              title={option.text}
              checked={subType === option.id}
              subtext={option?.subtext}
              onChange={setSubType}
            />
          );
        })}
      </div>
    );
  }

  function renderNonProfitSelection() {
    return (
      <div className="form-page column">
        {nonProfitTypeOptions.map((option) => {
          return (
            <CheckboxCard
              showCheckbox={false}
              key={option.id}
              id={option.id}
              title={option.text}
              checked={subType === option.id}
              subtext={option?.subtext}
              onChange={(value) => {
                setSubType(value);
              }}
            />
          );
        })}
      </div>
    );
  }

  function renderPaymentInfo() {
    return (
      <div className="form-page column">
        <PaymentComponent
          title={'Payment Details'}
          pricingTitle={'Purple Plus fees are billed annually'}
          priceDetails={pricing}
          showBillingForm={true}
          paymentInfo={paymentInfo}
          preparePaymentMethod={triggerPaymentPrep}
          onPaymentInfoUpdated={handlePaymentInfo}
          onPaymentMethodReady={handlePaymentMethodId}
        />
      </div>
    );
  }

  function renderPlanSelection() {
    return (
      <div className="form-page column">
        {planSelection.map((option) => {
          return (
            <CheckboxCard
              showCheckbox={false}
              key={option.id}
              id={option.id}
              title={option.title}
              advanced={true}
              checked={planType === option.id}
              subtext={option?.description}
              list={option.benefits}
              secondaryTitle={option.cost}
              listItemIconClass="far fa-check-circle"
              onChange={(value) => {
                setPlanType(value);
              }}
            />
          );
        })}
      </div>
    );
  }

  function renderCausesSelection() {
    return (
      <CauseSelection
        causeInfo={causeInfo}
        onChange={(value: string) => onSelectCause(causeInfo, value, handleCauseInfo)}
      />
    );
  }

  function renderImageSelection() {
    return (
      <div className="form-page">
        <ImageSelect
          type="profile"
          showPreselection={false}
          profileImage={profileImage}
          onChange={setProfileImage}
        />

        <ImageSelect
          type="cover"
          showPreselection={true}
          coverImage={coverImage}
          onChange={setCoverImage}
        />
      </div>
    );
  }

  function renderBasicInfo() {
    let displayTypeSelection = false;

    if (!groupType) {
      setGroupType('non-profit');
    }

    if (Object.keys(claimedCharity).length !== 0) {
      if (
        (claimedCharity.title || claimedCharity.description) &&
        claimedCharity.title !== baseInfo.name &&
        claimedCharity.description !== baseInfo.description
      ) {
        if (!subType) {
          setSubType('Registered Charity');
        }

        let update = { ...baseInfo, ...claimedCharity } as IEntityBaseInfo;

        // TODO: HANDLE THESE UPDATES BETTER FOR CLAIM CHARITY
        if (claimedCharity.charity_id) setCharityId(claimedCharity.charity_id);
        if (claimedCharity.website_url) setCharityUrl(claimedCharity.website_url);
        update.name = claimedCharity.title;
        handleBaseInfo(update);
        setProfileImage(claimedCharity.profile_image_url);
      }
    } else if (Object.keys(claimedCharity).length === 0) {
      displayTypeSelection = true;
    }

    return (
      <div className="form-page column">
        {displayTypeSelection && (
          <div>
            <Dropdown
              shouldSort={true}
              label="Cause Type"
              onChange={(e) => setSubType(e.target.value)}
              value={subType}
              name="type"
              options={nonProfitTypeOptions.map((type) => {
                return { label: type.text, value: type.id };
              })}
              placeholder="Select a Cause Type"
              required={true}
            />
          </div>
        )}
        <EntityBaseInfo
          type={uiConstants.ownerType.group}
          subType={groupType}
          baseInfo={baseInfo}
          onBaseInfoUpdated={handleBaseInfo}
        />
        {groupType === nonProfit && (
          <div className="row">
            <TextField
              value={charityId}
              name="title"
              label="Registered Charity Number"
              type="text"
              required={true}
              placeholder="Enter your registered charity number"
              onChange={(e) => {
                setCharityId(e.target.value);
              }}
            />
            <br />
            <TextField
              value={charityUrl}
              name="title"
              label="Charity Website"
              type="text"
              placeholder="Enter your Charity website"
              onChange={(e) => {
                setCharityUrl(e.target.value);
              }}
            />
            <br />
            <TextField
              value={charityEmail}
              label="Primary Contact Email"
              name="email"
              type="email"
              required={true}
              placeholder="Enter your Charity email"
              onChange={(e) => {
                setCharityEmail(e.target.value);
              }}
            />
            <br />
            <TextField
              value={charityPhone}
              label="Primary Contact Phone"
              name="telephone"
              type="tel"
              required={true}
              pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
              placeholder={'Enter your Charity phone number'}
              onChange={(e) => {
                const formattedPhoneNumber: string = formatPhoneNumber(e.target.value);
                setCharityPhone(formattedPhoneNumber);
              }}
              minLength={7}
              maxLength={10}
            />
          </div>
        )}
        <div className="more-info">
          {/* {groupType !== business && (
                        <div className="row">
                            <GroupClassification
                                classificationInfo={classificationInfo}
                                onClassificationChange={setClassificationInfo}
                            />
                        </div>
                    )} */}
          <div className="row">
            <LocaleSelect
              localization={localization}
              updateLocalization={setLocalization}
              usageType={uiConstants.ownerType.group}
            />
          </div>
        </div>
      </div>
    );
  }

  function getFormData(): ICreateGroupFormData {
    const formData: ICreateGroupFormData = {
      baseInfo,
      causeInfo,
      paymentInfo,
      groupType,
      subType,
      planType,
      redirect,
      steps,
      charityId,
      localization,
      profileImage,
      coverImage,
      loading,
      eventType,
      eventCreatedFor,
      claimedCharity,
      charityUrl,
      charityEmail,
      charityPhone,
    };

    return formData;
  }

  function renderSummary() {
    return (
      <div className="form-page column">
        <SummaryPage
          locale={locale}
          formData={getFormData()}
          pricingTitle="Purple Plus fees are billed annually"
          priceDetails={pricing}
          totalAmount={pricing.find((e) => e.isTotal)?.totalInCents}
          planType={planType}
          goToStep={(stepId: string) => {
            setCurrentStep(stepId);
          }}
        />
      </div>
    );
  }

  function setCharityIdRequiredState() {
    const isNonProfit = groupType === nonProfit;
    const updatedNonProfitSteps = nonProfitSteps.map((step) => {
      if (step.id === 'groupInfo') {
        step.data.map((stepData) => {
          if (stepData.path === 'charityId' && isNonProfit) {
            stepData.required = true;
          }

          return stepData;
        });

        return step;
      } else {
        return step;
      }
    });

    setSteps(updatedNonProfitSteps);
  }

  function setCurrentStep(stepId: string) {
    let isExistingStep = steps.filter((step) => step.id === stepId);

    if (isExistingStep.length > 0) {
      props.history.replace({
        pathname: `/onboarding/group/${stepId}`,
        search: props.location.search,
      });
    }
  }

  async function handlePaymentMethodId(id: string) {
    try {
      if (!paymentInfo.isAltMethod) {
        let payload = {
          payment_method_id: id,
        };

        const _response = await axios.post(routes.GET_PAYMENT_METHODS, payload);
        await paymentInfo.stripe?.confirmCardSetup(_response.data.client_secret);

        setPaymentMethodId(id);
      } else {
        setAltPaymentButton(true);

        (paymentInfo.paymentMethod as PaymentRequest).on(
          'paymentmethod',
          async (ev: PaymentRequestPaymentMethodEvent) => {
            setPaymentRequestEvent(ev);
            let payload = {
              payment_method_id: ev.paymentMethod?.id,
            };

            axios.post(routes.GET_PAYMENT_METHODS, payload).then((_response: any) => {
              paymentInfo.stripe
                ?.confirmCardSetup(_response.data.client_secret)
                .then((__response: any) => {
                  setPaymentMethodId(ev.paymentMethod?.id);
                });
            });
          },
        );
      }
      setCurrentStep('summary');
    } catch (error) {
      handleError(error, 'Create Cause');
    }
  }

  function onNext() {
    const index = steps.findIndex((step) => step.id === getCurrentStep());
    const nextStep = steps[index + 1]?.id;
    const currentStep = getCurrentStep();
    const formData = getFormData();

    if (
      currentStep === 'paymentInfo' &&
      planType === Constants.billing.subscriptions.purple_plus.code
    ) {
      if (hasAllRequiredFields(steps, currentStep, formData)) {
        // Used to trigger the validation in payment component before continuing
        // Do not set a hardcoded true/false
        setTriggerPaymentPrep(!triggerPaymentPrep);
        return;
      }
    } else {
      if (hasAllRequiredFields(steps, currentStep, formData)) {
        if (index + 1 > steps.length) {
          return;
        } else if (index + 1 === steps.length) {
          createGroup();
        } else {
          setCurrentStep(nextStep);
        }
      } else {
        return;
      }
    }
  }

  const handleError = (error: unknown, errorTitle: string) => {
    dispatchToastError(error, errorTitle);
    setLoading(false);
  };

  async function isBase64(img: string) {
    if (img === '' || img.trim() === '') {
      return false;
    }
    try {
      const base = img.split(',')[1];
      return atob(base);
    } catch (err) {
      return false;
    }
  }

  async function createGroup() {
    setLoading(true);
    const components = FlowConfigComponents(causeInfo.selectedCauses, uiConstants.ownerType.group);
    const pages = FlowConfigPages(components);

    try {
      let coverImageUploaded = await uploadImageToStore(coverImage);
      const isProfileBase64 = await isBase64(profileImage);
      let profileImageUploaded;
      if (isProfileBase64) {
        profileImageUploaded = await uploadImageToStore(profileImage);
      } else {
        profileImageUploaded = profileImage;
      }
      let newGroup: IGroup;
      let groupPayload: ICreateGroupFE = {
        group: {
          title: baseInfo.name,
          description: baseInfo.description,
          group_type: groupType,
          handle: baseInfo.name.replace(/ /g, '').toLowerCase(),
          accepting_volunteers: false,
          charity_id: charityId,
          fundraise_amount_range: baseInfo.revenueGoal.toString(),
          auto_tax_receipts: false,
          accepting_donations: true,
          group_template_type: groupType,
          localization: localization,
          profile_image_url: profileImageUploaded,
          cover_image_url: coverImageUploaded,
          website_url: charityUrl,
          contact_details: { phone: charityPhone, email: charityEmail },
          causes: causeInfo.selectedCauses,
          allow_obo_fundraisers: true,
        },
      };

      if (params.inviteId) {
        groupPayload.hub_partner_invite_id = params.inviteId as string;
      }

      let groupPages: Array<IPageFE> = makePageSequence(pages.acceptDonations.pages);

      if (planType === Constants.billing.subscriptions.purple_plus.code) {
        groupPayload.payments = {
          paymentMethodId: paymentMethodId,
          purchases: [planType],
        };
      }

      groupPayload.pages = groupPages;

      await groupRequestActions.createGroupRequest(groupPayload);

      setLoading(false);
      if (paymentInfo.isAltMethod) {
        paymentRequestEvent?.complete('success');
      }
      let toastMessage = localizeHelpers.translate('Successfully created Cause');
      toastSuccess(toastMessage, 'Create Cause');
      let successLink = `/setup/group/review/success?isSubmission=true`;
      props.history.push(successLink);
    } catch (error) {
      handleError(error, 'Create Cause');
    }
  }

  function getInfoSectionTitle() {
    // show back is equal to curr step index
    return showBack <= 1 ? 'Getting Started' : 'Create a Cause';
  }

  function formatPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) return phoneNumber;
    const formattedNumber = phoneNumber.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return formattedNumber;
    if (phoneNumberLength < 7) {
      return `${formattedNumber.slice(0, 3)}${formattedNumber.slice(3)}`;
    }
    return `${formattedNumber.slice(0, 3)}${formattedNumber.slice(3, 6)}${formattedNumber.slice(6, 10)}`;
  }

  return (
    <OnboardingWrapper
      requiresAuth={true}
      redirect={createAGroupLoginRedirect}
    >
      <form
        className="CreateAGroup"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormTopBar
          onLogoClick={() => navigateHome()}
          onClose={() => props.history.goBack()}
        />
        <InfoSection
          type={groupType}
          title={'Claim or Create a Cause'}
          steps={steps}
          currentStep={getCurrentStep()}
        />
        <FormSection>{renderStep()}</FormSection>
        <FormBottomBar
          isDisabled={!hasAllRequiredFields(steps, getCurrentStep(), getFormData())}
          showBack={showBack !== 0}
          showSkip={skippableSteps.includes(getCurrentStep())}
          showAltPaymentButton={altPaymentButton}
          stripePromise={paymentInfo.stripe}
          paymentRequest={paymentInfo.paymentMethod as PaymentRequest}
          nextBtnTxt={nxtBtnText()}
          onBack={() =>
            onBack(
              steps,
              getCurrentStep(),
              setCurrentStep,
              getCurrentStep() === 'summary' &&
                planType !== Constants.billing.subscriptions.purple_plus.code,
            )
          }
          onNext={() => onNext()}
          loading={loading}
        />
      </form>
    </OnboardingWrapper>
  );
};

export default CreateAGroup;
