import React, { FC, useState, useEffect } from 'react';
import { typeHelpers, errorHelpers, toastError } from '../../../helpers';
import { IEventSummaryFE, IEventIndividual, IEventTeam } from '@gigit/interfaces';

import TextField from '../../../components/TextField/TextField';
import Portrait from '../../../components/Portrait/Portrait';
import Loader from '../../../components/Loader/Loader';

import './P2PSelector.scss';
import { IOwnerObject } from '../../../interfaces';
import { Constants } from '@gigit/constants';
import { eventRequestActions, groupRequestActions } from '../../../requestActions';
import { useDispatch } from 'react-redux';
import { createToast } from '../../../actions/toaster';
import { localizeHelpers } from '../../../localizeHelpers';
import { useDebounce } from '../../../hooks/useDebounce';

interface IP2PSearchState<T> {
  objects: T[];
  search: string;
  isLoading: boolean;
}
interface IProps {
  owner: IOwnerObject;
  onP2PSelect(value: any): void;
  validateP2PValue(error: string | null): void;
  changeDestination?: boolean;
}

type P2PItem = IEventSummaryFE | IEventTeam | IEventIndividual;

/** A selector that allows a user to search and select either an Event, Team or Event.
 * Used to select the "destination" of a donation.
 */

const P2PSelector: FC<IProps> = (props: IProps) => {
  let tabs: { key: string; value: string }[] = [
    ...(props.changeDestination && props.owner.ownerType === Constants.object_type.event
      ? []
      : [{ key: 'event', value: 'Event' }]),
    { key: 'team', value: 'Team' },
    { key: 'individual', value: 'Individual' },
  ];

  const [activeTab, setActiveTab] = useState<string>('event');
  const [selectedItem, setSelectedItem] = useState<P2PItem | null>(null);
  const [limit, setLimit] = useState<number>(5);
  const [skip, setSkip] = useState<number>(0);
  const [titleMessage, setTitleMessage] = useState<{ title: string; desc: string }>({
    title: '',
    desc: '',
  });
  const [currentTabData, setCurrentTabData] = useState<IP2PSearchState<P2PItem>>({
    objects: [],
    search: '',
    isLoading: false,
  });

  const tabSearch = useDebounce(currentTabData.search);

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('resize', () => updateLimit(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => updateLimit(window.innerWidth));
    };
  }, []);

  useEffect(() => {
    if (tabSearch) {
      fetchData();
    }
  }, [tabSearch]);

  useEffect(() => {
    props.onP2PSelect({ type: activeTab, item: selectedItem });
    !selectedItem
      ? props.validateP2PValue(
          localizeHelpers.translate('Please select a {{active_tab_value}} to donate to.', {
            active_tab_value: activeTab,
          }),
        )
      : props.validateP2PValue(null);
  }, [selectedItem]);

  useEffect(() => {
    switch (activeTab) {
      case 'team':
        setTitleMessage({
          title: 'Donate to a team',
          desc: 'Your donation will add to the teams goal in support of the event.',
        });
        break;
      case 'event':
        setTitleMessage({
          title: 'Donate to the event',
          desc: 'Your donation will go directly to the event. This will add to the overall event goal.',
        });
        break;
      case 'individual':
        setTitleMessage({
          title: 'Donate to an individual',
          desc: 'Your donation will add to their personal goal in support of their team and/or event.',
        });
        break;
    }
    setSelectedItem(null);
    fetchData();
    setSkip(0);
  }, [activeTab]);

  const updateLimit = (windowWidth: number) => {
    if (windowWidth > 1160) setLimit(5);
    if (windowWidth <= 1160) setLimit(4);
    if (windowWidth <= 820) setLimit(3);
    if (windowWidth <= 640) setLimit(2);
    if (windowWidth <= 420) setLimit(1);
  };

  const fetchData = async () => {
    setCurrentTabData((prevData) => ({
      ...prevData,
      isLoading: true,
    }));

    let response: P2PItem[] = [];

    try {
      switch (activeTab) {
        case 'team':
          if (props.owner.ownerType === Constants.object_type.group)
            response = await groupRequestActions.getGroupTeams(props.owner.ownerId, {
              search: currentTabData.search,
            });
          else
            response = await eventRequestActions.getEventTeams(props.owner.ownerId, {
              search: currentTabData.search,
            });
          break;
        case 'individual':
          if (props.owner.ownerType === Constants.object_type.group)
            response = await groupRequestActions.getGroupIndividuals(props.owner.ownerId, {
              search: currentTabData.search,
            });
          else
            response = await eventRequestActions.getEventIndividuals(props.owner.ownerId, {
              search: currentTabData.search,
            });
          break;
        default:
          if (props.owner.ownerType === Constants.object_type.group) {
            response = await groupRequestActions.getGroupEvents(props.owner.ownerId, {
              search: currentTabData.search,
            });
          } else {
            const tmpResponse = await eventRequestActions.getEventByHandleOrId(props.owner.ownerId);
            response = [tmpResponse];
          }
      }

      let tmpData = response;
      if (props.owner.ownerType === Constants.object_type.event && activeTab === 'event') {
        tmpData = response.filter((item: P2PItem) => item.id === props.owner.ownerId);
        setSelectedItem(tmpData[0]);
      }

      setCurrentTabData((prevData) => ({
        ...prevData,
        objects: tmpData,
      }));
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, `Get ${activeTab}s`);
      dispatch(createToast(toast));
    } finally {
      setCurrentTabData((prevData) => ({
        ...prevData,
        isLoading: false,
      }));
    }
  };

  const renderItem = (p2pItem: P2PItem) => {
    let image_url = '';
    let name = '';
    let teamName = '';

    switch (activeTab) {
      case 'team':
        p2pItem = p2pItem as IEventTeam;
        image_url = p2pItem.profile_image_url || '';
        name = p2pItem.name;
        break;
      case 'individual':
        p2pItem = p2pItem as IEventIndividual;
        image_url = p2pItem.user?.profile_image_url || '';
        name = p2pItem.user?.display_name
          ? p2pItem.user.display_name
          : p2pItem.user?.first_name + ' ' + p2pItem.user?.last_name;
        teamName = p2pItem.team?.name || '';
        break;
      case 'event':
        p2pItem = p2pItem as IEventSummaryFE;
        image_url = p2pItem.profile_image_url;
        name = p2pItem.title;
        break;
    }

    return (
      <li
        onClick={() => setSelectedItem(p2pItem)}
        className={selectedItem?.id === p2pItem.id ? 'active no-select' : 'no-select'}
        key={p2pItem.id}
      >
        <Portrait
          size={80}
          currentImage={image_url}
        />
        <span
          notranslate="yes"
          className="p2p-name"
        >
          {name}
        </span>
        {props.changeDestination && teamName && (
          <div>
            <span className="p2p-notes">team</span>
            <span
              className="p2p-notes"
              notranslate="yes"
            >
              {teamName}
            </span>
          </div>
        )}
      </li>
    );
  };

  return (
    <div className="P2PSelector">
      <ul className="tabs">
        {tabs.map(({ key, value }, index) => {
          return (
            <li
              key={index}
              onClick={() => {
                setActiveTab(key);
              }}
              className={key === activeTab ? 'active no-select' : 'no-select'}
            >
              {value}
            </li>
          );
        })}
      </ul>
      <div className="P2PSelector-content">
        <div className="content-title">{titleMessage.title}</div>
        <div className="content-desc">{titleMessage.desc}</div>
        {(activeTab !== 'event' || props.owner.ownerType === Constants.object_type.group) && (
          <TextField
            icon="fa fa-search"
            value={currentTabData.search}
            name="search"
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { name, value } = e.target;
              setCurrentTabData((prevData) => ({ ...prevData, [name]: value }));
            }}
          />
        )}
        <div className="item-wrap">
          <div
            onClick={() => {
              if (skip > 0) setSkip(skip - limit);
            }}
            className={skip === 0 ? 'arrow disabled' : 'arrow'}
          >
            <i className="far fa-angle-left"></i>
          </div>
          <ul className="p2p-item">
            {!currentTabData.isLoading &&
              currentTabData.objects
                .slice(skip, skip + limit)
                .map((item: P2PItem) => renderItem(item))}
            {currentTabData.isLoading && (
              <li className="p2p-loading">
                <Loader loading={true} />
              </li>
            )}
          </ul>
          <div
            onClick={() => {
              if (skip + limit < currentTabData.objects.length) setSkip(skip + limit);
            }}
            className={skip + limit < currentTabData.objects.length ? 'arrow' : 'arrow disabled'}
          >
            <i className="far fa-angle-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default P2PSelector;
