import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { IAppState } from '../../store';

import { IUserState } from '../../reducers/user';

import { handleInputChange, setSEOMetatags, toastSuccess } from '../../helpers';

import { createToast } from '../../actions/toaster';
import { sendResetPasswordEmail } from '../../actions/user';

import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';

import './ForgotPassword.scss';
import { IToast } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  userState: IUserState;
  createToast(toast: IToast): void;
  sendResetPasswordEmail(email: string, msg: string, title: string): void;
}

interface IState {
  email: string;
  redirect: boolean;
}

class ForgotPassword extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      email: '',
      redirect: false,
    };
  }

  componentDidMount() {
    setSEOMetatags({
      title: localizeHelpers.translate('Forgot password | Kambeo'),
      urlPath: 'forgot-password',
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.userState.isLoggedIn !== prevProps.userState.isLoggedIn) {
      const toast = toastSuccess(
        localizeHelpers.translate('Check your email to continue the process'),
        'Forgot Password',
      );
      this.props.createToast(toast);

      this.setState({
        redirect: true,
      });
    }
  }

  forgotPassword(e: any) {}

  render() {
    const { t } = this.props;

    if (this.state.redirect) {
      return <Redirect to="/" />;
    } else {
      return (
        <div className="ForgotPassword">
          <div className="curve-wrap"></div>
          <div className="ResetPassword-inner">
            <form onSubmit={this.forgotPassword}>
              <h2>Forgot Password</h2>
              <div className="row">
                <div className="col">
                  <TextField
                    required={true}
                    label="Email Address"
                    name="email"
                    value={this.state.email}
                    onChange={(e: any) => {
                      handleInputChange(e, this);
                    }}
                    type="email"
                  />
                </div>
              </div>
              <div className="actions">
                <Button
                  type="button"
                  onClick={() => {
                    this.props.sendResetPasswordEmail(
                      this.state.email,
                      t('Forgot password email sent.'),
                      t('Reset Password'),
                    );
                  }}
                  text={t('Send Forgot Password Email')}
                />
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

const mapDispatchToProps = {
  createToast,
  sendResetPasswordEmail,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword),
);
