import React, { useEffect, useState } from 'react';
import { IHub, IStoreItem } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import { accountRequestActions, hubRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import './HubManagementStore.scss';
import { defaultCurrency, formatCurrency, typeHelpers } from '../../../../helpers';
import { Prompt } from '../../../Prompt/Prompt';
import Table, { ITableProps } from '../../../shared/Table/Table';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import StoreItemForm from '../../../StoreItemForm/StoreItemForm';
import { Constants } from '@gigit/constants';
import { IKpi } from '../../../../interfaces';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { localizeHelpers } from '../../../../localizeHelpers';
import { uiConstants } from '../../../../constants';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import HubManagementMetricContainer from '../shared/HubManagementMetricContainer/HubManagementMetricContainer';
import { useHistory } from 'react-router-dom';

interface IProps {
  hub?: IHub;
}

function HubManagementStore(props: IProps) {
  const [storeItemToDelete, setStoreItemToDelete] = useState<IStoreItem | null>(null);
  const [activeStoreItem, setActiveStoreItem] = useState<IStoreItem | null>(null);
  const [showCreateStoreItemModal, setShowCreateStoreItemModal] = useState<boolean>(false);
  const [storeKPIs, setStoreKPIs] = useState<IKpi[]>([]);
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const [isCheckLoading, setIsCheckLoading] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    getStoreKPIs();
  }, []);

  async function getStoreKPIs() {
    const storeStatsResponse = await hubRequestActions.getHubStoreStats(props.hub?.id!);
    const currency = props.hub?.account?.currency ?? defaultCurrency;

    const convertToKPI: IKpi[] = [
      {
        text: 'Number of Items',
        value: localizeHelpers.formatNumber(storeStatsResponse.total_number_of_items || 0, locale),
      },
      {
        text: 'Average Ticket Price',
        value: formatCurrency(storeStatsResponse.average_ticket_price, currency, locale),
      },
      {
        text: 'Average Merchandise Price',
        value: formatCurrency(storeStatsResponse.average_merchandise_price, currency, locale),
      },
    ];

    setStoreKPIs(convertToKPI);
  }

  function refreshAllPageState() {
    getStoreKPIs();
    refreshTableData();
  }

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  const hubManagementStoreTableProps: ITableProps<IStoreItem> = {
    columns: [
      {
        id: 'name',
        Header: 'Item Name',
        accessor: ({ name }) => name,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ media, name }) => {
          return (
            <div
              className="item-name-col"
              notranslate="yes"
            >
              {media.length > 0 && (
                <LazyLoadImage
                  style={{ width: '60px', height: '60px' }}
                  className="image-container"
                  effect="opacity"
                  alt="profile"
                  src={media[0].url}
                />
              )}
              <span>{name}</span>
            </div>
          );
        },
      },
      {
        id: 'description',
        Header: 'Description',
        accessor: ({ description }) => description,
        Cell: ({ description }) => (
          <QuillTextEditor
            value={description}
            preserveWhitespace={true}
            readOnly={true}
            theme={uiConstants.quill.readOnlyTheme}
          />
        ),
        sortable: true,
      },
      {
        id: 'category',
        Header: 'Category',
        accessor: ({ category }) => category,
        sortable: true,
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Edit Item',
          icon: 'fa fa-pencil',
          onClick: (_, storeItem) => {
            setActiveStoreItem(storeItem);
            setShowCreateStoreItemModal(true);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Delete Store Item',
          icon: 'fal fa-download',
          onClick: (_, storeItem) => {
            setStoreItemToDelete(storeItem);
          },
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      additionalQueryParams: {
        type: Constants.object_type.hub,
      },
      queryAction: async (queryParams) => {
        typeHelpers.assertNotNullOrUndefined(props.hub?.id);
        return await hubRequestActions.getHubSpecificStoreItems(props.hub?.id, queryParams);
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any store items",
    },
    filterOptions: {
      enableTableSearch: true,
    },
  };

  async function handleDeleteStoreItem() {
    typeHelpers.assertNotNullOrUndefined(props.hub?.id);
    typeHelpers.assertNotNullOrUndefined(storeItemToDelete?.id);

    await hubRequestActions.deleteStoreItem(props.hub.id, storeItemToDelete?.id);
    refreshAllPageState();
  }

  const tryCreateStoreItem = async () => {
    setIsCheckLoading(true);
    if (props.hub?.id) {
      const currentAccount = await accountRequestActions.getCurrentAccountPublicSummary(
        uiConstants.ownerType.hub,
        props.hub?.id,
      );

      const hasStripe = !!currentAccount?.charges_enabled;

      const message = localizeHelpers.translate(
        'In order to create Company store items, you must first configure your account to accept payments, or connect an existing Stripe account. Go to Balance and Payouts to manage your Stripe settings.',
      );

      if (!hasStripe) {
        dispatchToastError(message, 'Company Store', [
          {
            onClick: () => {
              history.push(`/company/${props.hub?.handle}/admin?t=balance-and-payouts`);
            },
            text: 'Balance & Payouts',
          },
        ]);
        setIsCheckLoading(false);
        return;
      }
    } else {
      setIsCheckLoading(false);
      return;
    }
    setIsCheckLoading(false);
    setShowCreateStoreItemModal(true);
  };

  return (
    <>
      <div
        className="HubManagementStore"
        id="store"
      >
        <div className="header-section">
          <h2>Store</h2>
          <div className="header-actions">
            <Button
              type="button"
              text="Create Item"
              icon="fal fa-plus"
              loading={isCheckLoading}
              onClick={() => {
                tryCreateStoreItem();
              }}
            />
          </div>
        </div>
        <HubManagementMetricContainer metrics={storeKPIs} />
        <div className="table-section">
          <Table
            {...hubManagementStoreTableProps}
            refreshTableIncrementor={refreshTableIncrementor}
          />
        </div>
      </div>

      <Modal
        show={showCreateStoreItemModal}
        onClose={() => {
          setShowCreateStoreItemModal(false);
          setActiveStoreItem(null);
        }}
        closeIcon="fas fa-times"
        contentClassName="HubStoreSettingsModal-add-edit-item"
        title={activeStoreItem ? 'Edit Store Item' : 'Add Store Item'}
      >
        {props.hub?.id && (
          <StoreItemForm
            owner={typeHelpers.createOwnerObject('hub', props.hub)}
            onClose={() => {
              setShowCreateStoreItemModal(false);
              setActiveStoreItem(null);
              refreshAllPageState();
            }}
            storeItem={activeStoreItem ? activeStoreItem : undefined}
          />
        )}
      </Modal>

      <Prompt
        show={storeItemToDelete !== null}
        title="Delete Store Item"
        message={`Are you sure you want to delete ${storeItemToDelete?.name || ''}?`}
        yesMessage="Yes"
        yesClass="fa fa-trash"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={handleDeleteStoreItem}
        onClose={() => setStoreItemToDelete(null)}
      />
    </>
  );
}

export default HubManagementStore;
