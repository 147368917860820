import React, { FC, ReactNode } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import './CardContent.scss';

interface IProps {
  children?: ReactNode;
  loading?: boolean;
  className?: string;
}

export const CardContent: FC<IProps> = (props: IProps) => {
  return (
    <React.Fragment>
      <div className={`card-content ${props.className}`}>
        {props.loading ? 'Loading css' : props.children}
      </div>
    </React.Fragment>
  );
};
