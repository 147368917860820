import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IAppState } from '../../store';
import queryString from 'query-string';
import { IUserState } from '../../reducers/user';
import { handleInputChange, setSEOMetatags, toastSuccess } from '../../helpers';
import { createToast } from '../../actions/toaster';
import { updatePassword, getUserInfo } from '../../actions/user';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import './SetPassword.scss';
import { ReduxActionType } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  createToast: ReduxActionType<typeof createToast>;
  updatePassword: ReduxActionType<typeof updatePassword>;
  getUserInfo: ReduxActionType<typeof getUserInfo>;
}

interface IState {
  password: string;
  confirm: string;
  token: string;
  mismatch: boolean;
  noPassProvided: boolean;
  userId: string;
}

class SetPassword extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      password: '',
      confirm: '',
      token: '',
      mismatch: false,
      noPassProvided: false,
      userId: '',
    };

    this.setPassword = this.setPassword.bind(this);
  }

  componentDidMount() {
    setSEOMetatags({
      title: localizeHelpers.translate('Set password | Kambeo'),
      urlPath: 'set-password',
    });

    let params = queryString.parse(this.props.location.search);

    const userId = params.user_id?.toString() || '';
    const urlToken = params.token?.toString() || '';

    if (urlToken) {
      this.props.getUserInfo(userId);

      this.setState({
        token: urlToken,
        userId: userId,
      });
    } else {
      this.props.history.replace('/');
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.userState.isLoggedIn && this.props.userState.user.id !== this.state.userId) {
      this.props.history.replace('/');
    }

    if (
      this.props.userState.user.id === this.state.userId &&
      this.props.userState.user.is_email_verified
    ) {
      this.props.history.replace('/login');
    }

    if (
      (prevState.confirm !== this.state.confirm || prevState.password !== this.state.password) &&
      this.state.noPassProvided
    ) {
      this.setState({
        noPassProvided: false,
      });
    }

    if (
      this.state.confirm !== '' &&
      this.state.password !== this.state.confirm &&
      !this.state.mismatch
    ) {
      this.setState({
        mismatch: true,
      });
    }

    if (this.state.password === this.state.confirm && this.state.mismatch) {
      this.setState({
        mismatch: false,
      });
    }
  }

  setPassword() {
    if (this.state.password.trim() === '' || this.state.confirm.trim() === '') {
      this.setState({
        noPassProvided: true,
        mismatch: false,
      });
      return;
    }

    if (this.state.mismatch) {
      return;
    }

    if (this.state.password === this.state.confirm && this.state.token !== '') {
      this.props.updatePassword(this.state.password.trim(), this.state.token, () => {
        if (this.props.userState.isLoggedIn) {
          const toast = toastSuccess(
            localizeHelpers.translate('Your password has been set successfully'),
            'Set Password',
          );
          this.props.createToast(toast);

          let params = queryString.parse(this.props.location.search);
          const redirect = typeof params.redirect === 'string' ? params.redirect : '/account';
          this.props.history.push(redirect);
        }
      });
    }
  }

  render() {
    const validPassClass =
      this.state.mismatch || this.state.noPassProvided ? 'invalid-password' : '';

    return (
      <div className="SetPassword">
        <div className="SetPassword-inner">
          <h1>Set your password</h1>
          <hr />
          <p>Create your new password for your Kambeo account</p>
          <TextField
            className={validPassClass}
            label="Password"
            name="password"
            value={this.state.password}
            onChange={(e: any) => {
              handleInputChange(e, this);
            }}
            type="password"
            icon="fad fa-key"
          />
          <TextField
            className={validPassClass}
            label="Confirm Password"
            name="confirm"
            value={this.state.confirm}
            onChange={(e: any) => {
              handleInputChange(e, this);
            }}
            type="password"
            icon="fad fa-key"
          />

          <div className="error-message">
            {this.state.mismatch && 'Please make sure your passwords match'}
            {this.state.noPassProvided && 'Please enter password and confirmation'}
          </div>

          <div className="action-buttons">
            <Button
              onClick={() => this.props.history.replace('/')}
              buttonClass="cancel"
              text="Cancel"
            />
            <Button
              onClick={this.setPassword}
              text="Set Password"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

const mapDispatchToProps = {
  createToast,
  updatePassword,
  getUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
