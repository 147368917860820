import { ISkill } from '@gigit/interfaces';
import { useEffect, useState } from 'react';
import {} from '../requestActions';
import { skillRequestActions } from '../requestActions/skills';
import useToastDispatcher from './useToaster';

interface IUseGroupClassifications {
  skills: ISkill[];
  isLoading: Boolean;
}
/**
 * Fetches master lists of Group Classifications and Sub-Classifications
 */
export function useSkills(): IUseGroupClassifications {
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [skills, setSkills] = useState<ISkill[]>([]);
  const { dispatchToastError } = useToastDispatcher();

  useEffect(() => {
    const fetchClassifications = async () => {
      setIsLoading(true);
      try {
        const results = await skillRequestActions.getSkills();
        setSkills([...results].sort((a: ISkill, b: ISkill) => a.skill.localeCompare(b.skill)));
      } catch (e) {
        dispatchToastError(e, 'Fetching Group Classifications');
      } finally {
        setIsLoading(false);
      }
    };

    fetchClassifications();
  }, []);

  return { isLoading, skills };
}
