import axios from 'axios';
import {
  IAvailablePremiumFeature,
  IOrgPremiumFeatures,
  IOrgPremiumFeaturesAdminSummaryFE,
  IOrgPremiumFeaturesSummary,
  IPayment,
  IPremiumFeatureAdminUpdateParams,
  IPremiumFeatureBillingHistoryItem,
  IPremiumFeatureCreateParams,
  IPremiumFeatureCreateResponse,
  IPremiumFeatureCreateTrialParamsFE,
  IPremiumFeatureUpdateParams,
} from '@gigit/interfaces';
import { routes, swapRouteParams } from '../helpers';
import { Constants } from '@gigit/constants';

export namespace billingRequestActions {
  export async function getAvailableSubscriptions(type: string) {
    const response = await axios.get<IAvailablePremiumFeature[]>(
      swapRouteParams(routes.GET_AVAILABLE_SUBSCRIPTIONS, { type }),
    );

    return response.data;
  }

  export async function createSubscriptionForGroup(
    groupId: string,
    subscriptionCode: string,
    payload: IPayment,
  ) {
    await axios.post<IOrgPremiumFeatures | null>(
      swapRouteParams(routes.CREATE_SUBSCRIPTION_FOR_GROUP, {
        group_id: groupId,
        subscription_code: subscriptionCode,
      }),
      payload,
    );
  }

  export async function getSubscriptionsForGroup(groupId: string) {
    const response = await axios.get<IOrgPremiumFeatures>(
      swapRouteParams(routes.GET_SUBSCRIPTIONS_FOR_GROUP, { group_id: groupId }),
    );

    return response.data;
  }

  export async function cancelSubscriptionForGroup(groupId: string, subscriptionCode: string) {
    const response = await axios.delete<IOrgPremiumFeatures | null>(
      swapRouteParams(routes.CANCEL_SUBSCRIPTION_FOR_GROUP, {
        group_id: groupId,
        subscription_code: subscriptionCode,
      }),
    );

    return response.data;
  }

  export async function getGroupSubscriptionHistory(groupId: string, subscriptionCode: string) {
    const response = await axios.get<IPremiumFeatureBillingHistoryItem[]>(
      swapRouteParams(routes.GET_GROUP_SUBSCRIPTION_HISTORY, {
        group_id: groupId,
        subscription_code: subscriptionCode,
      }),
    );

    return response.data;
  }

  export async function getAvailableGroupSubscriptions(type: string) {
    const response = await axios.get<IAvailablePremiumFeature[]>(
      swapRouteParams(routes.GET_AVAILABLE_GROUP_SUBSCRIPTIONS, {
        type: type,
      }),
    );

    return response.data;
  }

  export async function getHubSubscriptionSummary(hubId: string) {
    const response = await axios.get<IOrgPremiumFeaturesSummary>(
      swapRouteParams(routes.GET_HUB_SUBSCRIPTION_SUMMARY, {
        hubId,
      }),
    );

    return response.data;
  }

  export async function createHubSeatSubscription(
    hub_id: string,
    subscription_code: string,
    payload: IPremiumFeatureCreateParams,
  ) {
    const response = await axios.post<IOrgPremiumFeaturesSummary>(
      swapRouteParams(routes.UPDATE_HUB_SUBSCRIPTION, {
        hub_id,
        subscription_code,
      }),
      payload,
    );

    return response.data;
  }

  export async function createHubSeatSubscriptionEndTrial(
    hub_id: string,
    subscription_code: string,
    payload: { payment_method_id: string },
  ) {
    const response = await axios.put<IPremiumFeatureCreateResponse>(
      swapRouteParams(routes.PUT_HUB_SUBSCRIPTION_END_TRIAL, {
        hub_id,
        subscription_code,
      }),
      payload,
    );

    return response.data;
  }

  export async function updateHubSubscription(
    hubId: string,
    subscriptionCode: string,
    updateParams: IPremiumFeatureUpdateParams,
  ) {
    const response = await axios.put<IOrgPremiumFeaturesSummary>(
      swapRouteParams(routes.UPDATE_HUB_SUBSCRIPTION, {
        hub_id: hubId,
        subscription_code: subscriptionCode,
      }),
      updateParams,
    );

    return response.data;
  }

  export async function updateHubSubscriptionAdmin(
    hubId: string,
    subscriptionCode: string,
    updateParams: IPremiumFeatureAdminUpdateParams,
  ) {
    const response = await axios.put<IOrgPremiumFeaturesSummary>(
      swapRouteParams(routes.UPDATE_HUB_SUBSCRIPTION_ADMIN, {
        hub_id: hubId,
        subscription_code: subscriptionCode,
      }),
      updateParams,
    );

    return response.data;
  }

  export async function getHubSubscriptionHistory(hubId: string, subscriptionCode: string) {
    const response = await axios.get<IPremiumFeatureBillingHistoryItem[]>(
      swapRouteParams(routes.GET_HUB_SUBSCRIPTION_HISTORY, {
        hubId,
        subscriptionCode,
      }),
    );

    return response.data;
  }

  export async function purchaseHubSeats(hubId: string, payload: { allocate_quantity: number }) {
    const response = await axios.post<IOrgPremiumFeaturesSummary>(
      swapRouteParams(routes.PURCAHSE_HUB_SEATS, {
        hub_id: hubId,
        subscription_code: Constants.billing.subscriptions.hub_seating.code,
      }),
      payload,
    );
  }

  export async function getPremiumFeatureSummary(objectType: string, objectId: string) {
    const response = await axios.get<IOrgPremiumFeaturesSummary>(
      swapRouteParams(routes.GET_PREMIUM_FEATURE_SUMMARY, {
        object_type: objectType,
        object_id: objectId,
      }),
    );

    return response.data;
  }

  export async function getAllSubscriptionsForObjectType(
    objectType: string,
    query?: URLSearchParams,
  ) {
    const response = await axios.get<IOrgPremiumFeaturesAdminSummaryFE[]>(
      swapRouteParams(routes.GET_ALL_SUBSCRIPTIONS_SUPER_ADMIN, {
        object_type: objectType,
      }),
      { params: query },
    );

    return response.data;
  }

  export async function startHubTrial(hubId: string, payload: IPremiumFeatureCreateTrialParamsFE) {
    const response = await axios.post<IPremiumFeatureCreateResponse>(
      swapRouteParams(routes.START_HUB_TRIAL, {
        hub_id: hubId,
        subscription_code: Constants.billing.subscriptions.hub_seating.code,
      }),
      payload,
    );

    return response.data;
  }
}
