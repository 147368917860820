import React, { FC, Fragment, useState, useEffect } from 'react';
import TitleSection, { ITabConfig, ITitleSectionTab } from '../TitleSection/TitleSection';
import './Settings.scss';
import { localeConstants, uiConstants } from '../../../constants';
import { IUserState } from '../../../reducers/user';
import StravaIcon from '../../../assets/strava-icon.png';
import Axios from 'axios';
import { routes, errorHelpers, swapRouteParams, formatCardIcon } from '../../../helpers';
import {
  changeUserHandle,
  createUserLocation,
  deleteUserLocation,
  getUserInfo,
  sendResetPasswordEmail,
  updateLocalizeLanguageFromState,
  updateUser,
  updateUserCoverImage,
  updateUserEmail,
  updateUserLocation,
  updateUserProfileImage,
} from '../../../actions/user';
import { createToast } from '../../../actions/toaster';
import { resetFlowState } from '../../../actions/register';
import { Link, RouteComponentProps, withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { userSelectors } from '../../../selectors/user';
import { IGeolocationState } from '../../../reducers/geolocation';
import { IToast } from '../../../interfaces';
import { Config } from '@gigit/config';
import { Prompt } from '../../Prompt/Prompt';
import { localizeHelpers } from '../../../localizeHelpers';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../shared/PopupMenu/PopupMenu';
import Button from '../../Button/Button';
import { IAddress, IEventSummaryFE, IPaymentMethod } from '@gigit/interfaces';
import useToastDispatcher from '../../../hooks/useToaster';
import Modal from '../../Modal/Modal';
import TextField from '../../TextField/TextField';
import { IOptions } from '../../Dropdown/Dropdown';
import AvailabilitySettings from '../../AvailabilitySettings/AvailabilitySettings';
import Checkbox from '../../Checkbox/Checkbox';
import { userRequestActions } from '../../../requestActions';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import PaymentMethodsForm from '../../PaymentMethods/PaymentMethodsForm/PaymentMethodsForm';
import { CheckoutLocale, loadStripe, Stripe } from '@stripe/stripe-js';
import axios from 'axios';
import Table, { ITableProps } from '../../shared/Table/Table';
import queryString from 'query-string';
import Loader from '../../Loader/Loader';
import { resendEmailVerification } from '../../../actions/user';

type LocationState = { fromVolunteers: boolean };

const countryList: IOptions[] = [
  { label: 'Canada', value: 'Canada' },
  { label: 'United States of America', value: 'USA' },
];

const tabs: ITitleSectionTab[] = [
  { id: 'general', title: 'General', Icon: () => <i className="far fa-cogs" /> },
  { id: 'volunteering', title: 'Volunteering', Icon: () => <i className="far fa-list" /> },
  { id: 'fundraising', title: 'Fundraising', Icon: () => <i className="far fa-calendar-edit" /> },
  {
    id: 'privacy',
    title: 'Privacy & Security',
    mobileTitle: 'Security',
    Icon: () => <i className="far fa-lock-alt" />,
  },
  { id: 'payment', title: 'Payment Methods', Icon: () => <i className="far fa-credit-card" /> },
];

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  geolocationState: IGeolocationState;
  getUserInfo(handle: string, cb?: any): void;
  createToast(toast: IToast): void;
  sendResetPasswordEmail(email: string, msg: string, title: string): void;
  createUserLocation(_payload: any, showToast?: boolean): void;
  updateUserLocation(_locationId: string, _payload: any): void;
  deleteUserLocation(_locationId: string): void;
  updateUser(payload: any, updateProfile?: boolean, options?: { callback?: () => void }): void;
  updateUserEmail(payload: any): void;
  updateUserCoverImage(payload: any): void;
  updateUserProfileImage(payload: any): void;
  changeUserHandle(newHandle: string, onChanged: () => void): void;
  updateLocalizeLanguageFromState(): void;
  resetFlowState(isComplete?: boolean): void;
  resendEmailVerification(): void;
  locale: string;
  activeTabId?: string;
}

const Settings: FC<IProps> = (props: IProps) => {
  const [activeTabId, setActiveTabId] = useState<string>(props.activeTabId || 'general');
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const history = useHistory();

  function formatCardName(cardBrand?: string): string {
    switch (cardBrand) {
      case 'unionpay':
        return 'Union Pay';
      case 'jcb':
        return 'JCB';
      case 'diners':
        return 'Diners Club';
      case 'discover':
        return 'Discover';
      case 'amex':
        return 'American Express';
      case 'mastercard':
        return 'Mastercard';
      default:
        return 'VISA';
    }
  }

  // General
  const [showLanguagesMenu, setShowLanguagesMenu] = useState<boolean>(false);
  const [currentLanguagesConfig, setCurrentLanguagesConfig] = useState<string>(
    props.userState.user.language ?? localeConstants.defaultLanguage,
  );
  const [twitchAuthLink, setTwitchAuthLink] = useState<string>('');
  const [twitchToDelete, setTwitchToDelete] = useState<boolean>(false);
  const [stravaAuthLink, setStravaAuthLink] = useState<string>('');
  const [stravaToDelete, setStravaToDelete] = useState<boolean>(false);
  const languagesPopupMenuConfig: IShowPopupConfig = {
    showMenu: showLanguagesMenu,
    setShowMenu: setShowLanguagesMenu,
    position: {
      type: 'bottom',
    },
  };
  const languagesContextMenuItems: IPopupMenuItem[] = localeConstants.languageOptions.map(
    (ln, index) => {
      return {
        id: index.toString(),
        label: ln.label,
        isSelected: currentLanguagesConfig === ln.value,
        onClick: () => updateLanguage(ln.value),
      };
    },
  );

  // Applications
  const [userPhone, setUserPhone] = useState<string>(props.userState.user?.phone || '');
  const [showUserPhoneModal, setShowUserPhoneModal] = useState<boolean>(false);
  const [locationToDelete, setLocationToDelete] = useState<string | null>(null);
  const [showLocationFormModal, setShowLocationFormModal] = useState<boolean>(false);
  const [locations, setLocations] = useState<IAddress[]>(props.userState.locations || []);
  const [locationId, setLocationId] = useState<string | null>(null);
  const [locationStreetAddress, setLocationStreetAddress] = useState<string>('');
  const [locationApt, setLocationApt] = useState<string>('');
  const [locationCity, setLocationCity] = useState<string>('');
  const [locationProvince, setLocationProvince] = useState<string>('');
  const [locationPostal, setLocationPostal] = useState<string>('');
  const [locationCountry, setLocationCountry] = useState<string>('Canada');
  const [showEmergencyContactsFormModal, setShowEmergencyContactsFormModal] =
    useState<boolean>(false);
  const [emergencyContacts, setEmergencyContacts] = useState<{ name: string; phone: string }[]>([]);
  const [emerName, setEmerName] = useState<string>('');
  const [emerPhone, setEmerPhone] = useState<string>('');
  const [emerIndex, setEmerIndex] = useState<number | null>(null);
  const [emergencyContactToDelete, setEmergencyContactToDelete] = useState<string | null>(null);
  const [showCountryMenu, setShowCountryMenu] = useState<boolean>(false);
  const countryPopupMenuConfig: IShowPopupConfig = {
    showMenu: showCountryMenu,
    setShowMenu: setShowCountryMenu,
    position: {
      type: 'bottom',
    },
  };
  const countryContextMenuItems: IPopupMenuItem[] = countryList.map((country, index) => {
    return {
      id: index.toString(),
      label: country.label,
      isSelected: locationCountry === country.value,
      onClick: () => setLocationCountry(country.value),
    };
  });

  // Selected Events
  const [fundraisingEvents, setFundraisingEvents] = useState<{ value: string; label: string }[]>(
    [],
  );
  const [showFundraisingEventsMenu, setShowFundraisingEventsMenu] = useState<boolean>(false);
  const [currentFundraisingEventsConfig, setCurrentFundraisingEventsConfig] = useState<string>(
    props.userState.user?.current_fundraising_event_id || '',
  );
  const fundraisingEventsPopupMenuConfig: IShowPopupConfig = {
    showMenu: showFundraisingEventsMenu,
    setShowMenu: setShowFundraisingEventsMenu,
    position: {
      type: 'bottom',
    },
  };
  const fundraisingEventsContextMenuItems: IPopupMenuItem[] = fundraisingEvents.map((fe, index) => {
    return {
      id: index.toString(),
      label: fe.label,
      isSelected: currentFundraisingEventsConfig === fe.value,
      onClick: () => {
        setCurrentFundraisingEventsConfig(fe.value);
        props.updateUser({ current_fundraising_event_id: fe.value });
      },
    };
  });

  // Security
  const [showUserEmailModal, setShowUserEmailModal] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>(props.userState.user?.email || '');

  // Payment Method
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [togglePaymentMethodTable, setTogglePaymentMethodTable] = useState<boolean>(true);
  const [paymentMethodSearch, setPaymentMethodSearch] = useState<string>('');
  const [paymentMethodSearchDebounce, setPaymentMethodSearchDebounce] =
    useState<ReturnType<typeof setTimeout>>();
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<boolean>(false);
  const [paymentMethodIdToDelete, setPaymentMethodIdToDelete] = useState<string | null>(null);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const paymentMethodSectionTableProps: ITableProps<IPaymentMethod> = {
    subtext:
      paymentMethods.length > 1
        ? `Showing ${paymentMethods.length} payment methods`
        : `Showing ${paymentMethods.length} payment method`,
    columns: [
      {
        id: 'card_icon',
        Header: '',
        accessor: (item) => '',
        Cell: (item) => (
          <span className="card-icon">
            {item?.card?.brand && <i className={formatCardIcon(item?.card.brand)} />}
          </span>
        ),
        sortable: false,
      },
      {
        id: 'card.brand',
        Header: 'Credit Card',
        accessor: (item) => item.card?.brand || '',
        Cell: (item) => (
          <span className="card">
            <var data-var="credit_card_brand">{formatCardName(item.card?.brand)}</var>
            &nbsp; ending in &nbsp;
            <var data-var="credit_card_last_4_digits">{item.card?.last4}</var>
          </span>
        ),
        sortable: true,
      },
      {
        id: 'card.exp_year',
        Header: 'Expires',
        accessor: (item) => `${item?.card?.exp_month}/${item?.card?.exp_year}`,
        Cell: (item) => (
          <span>
            <var data-var="expires_date">{`${item?.card?.exp_month}/${item?.card?.exp_year}`}</var>
          </span>
        ),
        sortable: true,
      },
      {
        id: 'is_default',
        Header: '',
        accessor: (item) => item.is_default,
        Cell: (item) => (
          <>
            {item.is_default && (
              <>
                <span className="primary-tag">Primary Card</span>
                <span className="primary-tag-mobile" />
              </>
            )}
          </>
        ),
        sortable: false,
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Set as primary',
          icon: 'fas fa-star',
          onClick: async (_, rowData) => {
            setTogglePaymentMethodTable(false);
            await setPaymentMethodAsPrimary(rowData.payment_method_id || '');
            setTogglePaymentMethodTable(true);
          },
          hideIf: (originalRow) => originalRow?.is_default || false,
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Delete',
          icon: 'fas fa-trash',
          onClick: (_, rowData) => {
            setPaymentMethodIdToDelete(rowData.payment_method_id || '');
          },
        },
      ],
    },
    pagination: {
      pageSizeOptions: [+uiConstants.paymentMethodsItemPerPage],
      queryAction: async (params) => await getPaymentMethods(),
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any payment method",
    },
  };

  function updateLanguage(language: string) {
    props.updateUser(
      {
        handle: props.userState.user.handle,
        language,
      },
      true,
      {
        callback: () => {
          setCurrentLanguagesConfig(language);
          props.updateLocalizeLanguageFromState();
        },
      },
    );
  }

  function getSelectedLanguageLabel(): string {
    return (
      localeConstants.languageOptions.find((ln) => ln.value === currentLanguagesConfig)?.label || ''
    );
  }

  function getSelectedEventLabel(): string {
    return fundraisingEvents.find((fe) => fe.value === currentFundraisingEventsConfig)?.label || '';
  }

  function getSelectedCountryLabel(): string {
    return countryList.find((country) => country.value === locationCountry)?.label || '';
  }

  async function invokeStripe() {
    try {
      const response = await Axios.get(
        swapRouteParams(routes.ACCOUNTS.GET_OBJECT_STRIPE_ACCOUNT_AUTHORIZE_LINK, {
          object_type: 'user',
          object_id: props.userState.user.id,
        }),
      );
      window.open(response.data.value, '_blank');
      dispatchToastSuccess(localizeHelpers.translate('Successfully invoked Stripe.'), 'Stripe');
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      dispatchToastError(errorObj, 'Failed to invoke Stripe');
    }
  }

  async function dissociateTwitch() {
    try {
      await Axios.post(routes.DISSOCIATE_TWITCH);
      dispatchToastSuccess(
        localizeHelpers.translate('Successfully remove Twitch account.'),
        'Twitch',
      );
      setTwitchAuthLink('');
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      dispatchToastError(errorObj, 'Failed to remove Twitch account');
    }
  }

  async function dissociateStrava() {
    try {
      await Axios.post(routes.DISSOCIATE_STRAVA);
      dispatchToastSuccess(
        localizeHelpers.translate('Successfully remove Strava account.'),
        'Strava',
      );
      setStravaAuthLink('');
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      dispatchToastError(errorObj, 'Failed to remove Strava account');
    }
  }

  async function getStravaAuthLink() {
    try {
      const response = await Axios.get(
        routes.GET_STRAVA_AUTH_LINK +
          '?redirect=' +
          Config.web.REACT_APP_BASE_URL +
          '/strava/connect',
      );
      if (response.data) {
        setStravaAuthLink(response.data.value);
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      dispatchToastError(errorObj, 'Failed to get Strava authorization link');
    }
  }

  async function getTwitchAuthLink() {
    try {
      const response = await Axios.get(
        routes.GET_TWITCH_AUTH_LINK +
          '?redirect=' +
          Config.web.REACT_APP_BASE_URL +
          '/twitch/connect',
      );
      if (response.data) {
        setTwitchAuthLink(response.data.value);
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      dispatchToastError(errorObj, 'Get Twitch Auth Link');
    }
  }

  async function getEvents() {
    try {
      const response = await Axios.get<IEventSummaryFE[]>(routes.GET_USER_FUNDRAISING_EVENTS);

      let _fundraisers: IOptions[] = [{ value: '', label: 'None' }];

      for (let r in response.data) {
        _fundraisers.push({ label: response.data[r].title, value: response.data[r].id });
      }

      setFundraisingEvents(_fundraisers);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      dispatchToastError(errorObj, 'Get Events');
    }
  }

  function clearLocationFields() {
    setLocationStreetAddress('');
    setLocationApt('');
    setLocationCity('');
    setLocationProvince('');
    setLocationPostal('');
    setLocationCountry('Canada');
  }

  function updateUserLocation() {
    if (locationId !== null) {
      props.updateUserLocation(locationId, {
        line1: locationStreetAddress,
        line2: locationApt,
        city: locationCity,
        state: locationProvince,
        postal_code: locationPostal,
        country: locationCountry,
      });
    }
  }

  function createUserLocation() {
    props.createUserLocation({
      line1: locationStreetAddress,
      line2: locationApt,
      city: locationCity,
      state: locationProvince,
      postal_code: locationPostal,
      country: locationCountry,
    });

    clearLocationFields();
  }

  function clearEmergencyContactFields() {
    setEmerName('');
    setEmerPhone('');
    setEmerIndex(null);
  }

  function updateEmergencyContact() {
    props.updateUser({
      emergency_contacts: emergencyContacts.map((ec, index) => {
        if (index !== emerIndex) {
          return ec;
        } else {
          return {
            name: emerName,
            phone: emerPhone,
          };
        }
      }),
    });

    clearEmergencyContactFields();
  }

  function addEmergencyContact() {
    props.updateUser({
      emergency_contacts: [...emergencyContacts, { name: emerName, phone: emerPhone }],
    });

    clearEmergencyContactFields();
  }

  function removeEmergencyContact() {
    if (emerIndex !== null) {
      props.updateUser({
        emergency_contacts: emergencyContacts.filter((ec, index) => index !== emerIndex),
      });
    }
  }

  async function setPaymentMethodAsPrimary(id: string) {
    try {
      await axios.post(swapRouteParams(routes.SET_PAYMENT_METHOD_AS_DEFAULT, { id }));
      dispatchToastSuccess(
        localizeHelpers.translate('Primary payment method changed'),
        'Primary Payment Method',
      );
    } catch (error) {
      dispatchToastError(error, 'Error Set Primary Payment Method');
    }
  }

  async function deletePaymentMethod() {
    try {
      if (paymentMethodIdToDelete !== null) {
        setTogglePaymentMethodTable(false);
        await userRequestActions.deletePaymentMethods(paymentMethodIdToDelete);
        dispatchToastSuccess(
          localizeHelpers.translate('Successfully deleted payment method'),
          'Payment method',
        );
        setTogglePaymentMethodTable(true);
      }
    } catch (error) {
      dispatchToastError(error, 'Error Delete Payment Method');
    }
  }

  async function getPaymentMethods(): Promise<IPaymentMethod[]> {
    let tmpResult = [] as IPaymentMethod[];

    try {
      tmpResult = await userRequestActions.getPaymentMethods();
      setPaymentMethods(tmpResult);
    } catch (error) {
      dispatchToastError(error, 'Error Get Payment Methods');
    }

    return tmpResult;
  }

  function redirectToTab() {
    if (props.location && props.location.state) {
      const locationState = props.location.state as LocationState;
      locationState.fromVolunteers && setActiveTabId('volunteering');
    }
  }

  const tabConfig: ITabConfig = {
    tabs: tabs,
    activeTabId: activeTabId,
    onTabClick: setActiveTabId,
  };

  async function onAuthorizeTwitch() {
    const _params = queryString.parse(props.location.search);

    if (_params.code && _params.scope && _params.state) {
      try {
        const response = await Axios.get(routes.ACCOUNTS.AUTHORIZE_TWITCH + props.location.search);

        if (response.data.value === 'ok') {
          dispatchToastSuccess(
            localizeHelpers.translate('Twitch account successfully connected.'),
            'Twitch Account',
          );
          props.getUserInfo(props.userState.user.handle);
        }
      } catch (error) {
        dispatchToastError(error, 'Twitch Account');
      } finally {
        window.history.replaceState({}, document.title, props.location.pathname);
      }
    }
  }

  useEffect(() => {
    setLocations(props.userState.locations);
  }, [props.userState.locations]);

  useEffect(() => {
    if (props.userState.user?.language) {
      setCurrentLanguagesConfig(props.userState.user.language);
    }
  }, [props.userState.user.language]);

  useEffect(() => {
    setEmergencyContacts(props.userState.user?.emergency_contacts || []);
  }, [props.userState.user.emergency_contacts]);

  useEffect(() => {
    props.getUserInfo(props.userState.user.handle);

    onAuthorizeTwitch();

    getStravaAuthLink();
    getTwitchAuthLink();
    getEvents();
    redirectToTab();

    // Create Stripe instance. We use the platform account because this is used for creating "shared" saved payment method.
    setStripePromise(
      loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY, {
        locale: props.locale as CheckoutLocale,
      }),
    );
  }, []);

  useEffect(() => {
    if (paymentMethodSearch !== '') {
      if (paymentMethodSearch !== undefined) {
        if (paymentMethodSearchDebounce) {
          clearTimeout(paymentMethodSearchDebounce);
        }
        setPaymentMethodSearchDebounce(
          setTimeout(() => {
            getPaymentMethods();
          }, 500),
        );
      }
    }
  }, [paymentMethodSearch]);

  return (
    <Fragment>
      <TitleSection
        title="Settings"
        tabConfig={tabConfig}
      />
      <div className="Settings">
        {activeTabId === 'general' && (
          <div className="tab">
            <section className="language">
              <div className="header">
                <div className="title-section">
                  <h2>Default Language</h2>
                  <span className="header-desc">Select the default language for your account</span>
                </div>
              </div>
              <div className="section-content">
                <PopupMenu
                  popupMenuClass="PopupMenu-Settings-General-languages"
                  showMenuConfig={languagesPopupMenuConfig}
                  menuItems={languagesContextMenuItems}
                  className={`languages-context-menu-container ${showLanguagesMenu ? 'show' : ''}`}
                  onClick={() => setShowLanguagesMenu(!showLanguagesMenu)}
                  onSelect={() => setShowLanguagesMenu(false)}
                >
                  <span className="title">{getSelectedLanguageLabel()}</span>
                  <i
                    className={`fas fa-chevron-down menu-btn ${showLanguagesMenu ? 'show' : ''}`}
                  />
                </PopupMenu>
              </div>
            </section>

            <hr />

            <section className="connections">
              <div className="header">
                <div className="title-section">
                  <h2>Connections</h2>
                  <span className="header-desc">Connect an external account with Kambeo</span>
                </div>
              </div>
              <div className="section-content">
                {/* <div className="settings-item connection">
                  <div className="up-container">
                    <div className="icon">
                      <i className="fab fa-stripe-s" />
                    </div>
                    <div className="details">
                      <div
                        notranslate="yes"
                        className="details-title"
                      >
                        Stripe
                      </div>
                      <div className="details-subtitle">Send and receive payments on Kambeo.</div>
                    </div>
                  </div>
                  <div className="action">
                    {!props.userState.user.account && (
                      <span
                        className="link"
                        onClick={async () => {
                          await invokeStripe();
                        }}
                      >
                        Connect
                      </span>
                    )}
                    {props.userState.user.account && (
                      <span
                        className="link disconnect"
                        onClick={async () => {
                          await invokeStripe();
                        }}
                      >
                        Reconnect
                      </span>
                    )}
                  </div>
                </div> */}

                <div className="settings-item connection">
                  <div className="up-container">
                    <div className="icon">
                      <i className="fab fa-twitch" />
                    </div>
                    <div className="details">
                      <div
                        notranslate="yes"
                        className="details-title"
                      >
                        Twitch
                      </div>
                      <div className="details-subtitle">
                        Fundraise for events by live-streaming.
                      </div>
                    </div>
                  </div>
                  <div className="action">
                    {twitchAuthLink !== '' && !props.userState.user.twitch && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={twitchAuthLink}
                      >
                        Connect
                      </a>
                    )}
                    {props.userState.user.twitch && (
                      <span
                        onClick={() => setTwitchToDelete(true)}
                        className="disconnect"
                      >
                        Disconnect
                      </span>
                    )}
                  </div>
                </div>

                <div className="settings-item connection">
                  <div className="up-container">
                    <div className="icon">
                      <img
                        src={StravaIcon}
                        alt="strava-icon"
                      />
                    </div>
                    <div className="details">
                      <div
                        notranslate="yes"
                        className="details-title"
                      >
                        {'Strava'}
                      </div>
                      <div className="details-subtitle">
                        Integrate your fitness activities with your events.
                      </div>
                      <p />
                    </div>
                  </div>
                  <div className="action">
                    {stravaAuthLink !== '' && !props.userState.user.strava && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={stravaAuthLink}
                      >
                        Connect
                      </a>
                    )}
                    {props.userState.user.strava && (
                      <span
                        onClick={() => setStravaToDelete(true)}
                        className="disconnect"
                      >
                        Disconnect
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}

        {activeTabId === 'volunteering' && (
          <div className="tab">
            <section className="user-phone">
              <div className="header">
                <div className="title-section">
                  <h2>Phone Number</h2>
                  <span className="header-desc">
                    Change the phone number bound to your Kambeo Account.
                  </span>
                </div>
                <Button
                  text="Edit"
                  icon="fas fa-pen"
                  buttonType="outline-dark"
                  onClick={() => {
                    setShowUserPhoneModal(true);
                  }}
                />
              </div>
            </section>

            <hr />

            <section className="location">
              <div className="header">
                <div className="title-section">
                  <h2>Location</h2>
                  <span className="header-desc">
                    Add a location to your account to make volunteer applications and checkout
                    easier by automatically filling out your address.
                    <br />
                    This address will also be used to give you more accurate results on the
                    <Link to="/search"> search</Link> and{' '}
                    <Link to="/discover/events">discover </Link>pages.
                  </span>
                </div>
                {locations.length === 0 && (
                  <Button
                    text="Add Location"
                    icon="fas fa-plus"
                    buttonType="outline-dark"
                    onClick={() => {
                      setLocationId(null);
                      setShowLocationFormModal(true);
                    }}
                  />
                )}
              </div>
              <div className="section-content">
                {locations.length === 0 && (
                  <div className="empty">You haven't added any locations yet.</div>
                )}
                {locations.length > 0 && (
                  <div className="wrap">
                    {locations.map((location, index) => (
                      <div
                        className="item"
                        notranslate="yes"
                        key={index}
                      >
                        <span className="address">
                          {location.line1 +
                            ', ' +
                            location.city +
                            ', ' +
                            location.state +
                            ', ' +
                            location.country +
                            ', ' +
                            location.postal_code}
                        </span>
                        <i
                          onClick={() => {
                            setLocationId(location?.id || null);
                            setLocationStreetAddress(location?.line1 || '');
                            setLocationApt(location?.line2 || '');
                            setLocationCity(location?.city || '');
                            setLocationProvince(location?.state || '');
                            setLocationCountry(location?.country || 'Canada');
                            setLocationPostal(location?.postal_code || '');

                            setShowLocationFormModal(true);
                          }}
                          className="fas fa-pen"
                        />
                        <i
                          onClick={() => setLocationToDelete(location.id!)}
                          className="fas fa-trash"
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </section>

            <hr />

            <section className="emergency-contacts">
              <div className="header">
                <div className="title-section">
                  <h2>Emergency Contacts</h2>
                  <span className="header-desc">
                    This information will only be shared when you apply to gigs and volunteer
                    opportunities.
                  </span>
                </div>
                <Button
                  text="Add Contact"
                  icon="fas fa-plus"
                  buttonType="outline-dark"
                  onClick={() => {
                    setEmerIndex(null);
                    setShowEmergencyContactsFormModal(true);
                  }}
                />
              </div>
              <div className="section-content">
                {emergencyContacts.length === 0 && (
                  <div className="empty">You haven't added any emergency contacts yet.</div>
                )}
                {emergencyContacts.length > 0 && (
                  <div className="wrap">
                    {emergencyContacts.map((contact, index) => (
                      <div
                        className="item"
                        notranslate="yes"
                        key={index}
                      >
                        <div>
                          <span className="person">{contact.name}</span>
                          <span className="phone">{contact.phone}</span>
                        </div>
                        <div className="actions">
                          <i
                            onClick={() => {
                              setEmerIndex(index);
                              setEmerPhone(contact.phone);
                              setEmerName(contact.name);

                              setShowEmergencyContactsFormModal(true);
                            }}
                            className="fas fa-pen"
                          />
                          <i
                            onClick={() => {
                              setEmerIndex(index);
                              setEmergencyContactToDelete(contact.name);
                            }}
                            className="fas fa-trash"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </section>

            <hr />

            <section className="availability">
              <div className="header">
                <div className="title-section">
                  <h2>Availability</h2>
                  <span className="header-desc">
                    Set your availability parameters for volunteering
                  </span>
                </div>
              </div>
              <div className="section-content">
                <div className="availability-table">
                  <AvailabilitySettings settingsTheme={true} />
                </div>
              </div>
            </section>
          </div>
        )}
        {activeTabId === 'fundraising' && (
          <div className="tab">
            <section className="selected-event">
              <div className="header">
                <div className="title-section">
                  <h2>Selected Event</h2>
                  <span className="header-desc">
                    Select the event that your content creator widgets will direct donors to.
                  </span>
                </div>
              </div>
              <div className="section-content">
                <form>
                  <div className="settings-control">
                    <PopupMenu
                      shouldSort={true}
                      popupMenuClass="PopupMenu-Selected-Events-fundraising-events"
                      showMenuConfig={fundraisingEventsPopupMenuConfig}
                      menuItems={fundraisingEventsContextMenuItems}
                      className={`fundraising-events-context-menu-container ${showFundraisingEventsMenu ? 'show' : ''}`}
                      onClick={() => setShowFundraisingEventsMenu(!showFundraisingEventsMenu)}
                      onSelect={() => setShowFundraisingEventsMenu(false)}
                    >
                      <span className="title">{getSelectedEventLabel()}</span>
                      <i
                        className={`fas fa-chevron-down menu-btn ${showFundraisingEventsMenu ? 'show' : ''}`}
                      />
                    </PopupMenu>
                  </div>
                </form>
              </div>
            </section>
          </div>
        )}
        {activeTabId === 'privacy' && (
          <div className="tab">
            <section className="change-email">
              <div className="header">
                <div className="title-section">
                  <h2>Email Address</h2>
                  <span className="header-desc">
                    Change the email address bound to your Kambeo account.
                  </span>
                </div>
                <Button
                  text="Edit"
                  icon="fas fa-pen"
                  buttonType="outline-dark"
                  onClick={() => setShowUserEmailModal(true)}
                />
              </div>
            </section>

            <hr />

            <section className="change-password">
              <div className="header">
                <div className="title-section">
                  <h2>Change Password</h2>
                  <span className="header-desc">
                    Select a unique password that will be hard for others to protect your account.
                  </span>
                </div>
                <Button
                  text="Send Reset Email"
                  buttonType="outline-dark"
                  onClick={() =>
                    props.sendResetPasswordEmail(
                      props.userState.user.email,
                      'Reset password email sent',
                      'Reset Password',
                    )
                  }
                />
              </div>
            </section>

            <hr />

            <section className="age">
              <div className="header">
                <div className="title-section">
                  <h2>Age & Privacy</h2>
                  <span className="header-desc">Are you over 18 years old?</span>
                </div>
                <div className="cb-container">
                  <Checkbox
                    name="age-yes"
                    label="Yes"
                    value="yes"
                    iconChecked="fas fa-check-circle"
                    iconNoChecked="far fa-circle"
                    checked={!!props.userState.user.is_18_years_old}
                    onChange={() =>
                      props.updateUser({ is_18_years_old: !props.userState.user.is_18_years_old })
                    }
                  />
                  <Checkbox
                    iconChecked="fas fa-check-circle"
                    iconNoChecked="far fa-circle"
                    name="age-no"
                    label="No"
                    value="no"
                    checked={!props.userState.user.is_18_years_old}
                    onChange={() =>
                      props.updateUser({ is_18_years_old: !props.userState.user.is_18_years_old })
                    }
                  />
                </div>
              </div>
            </section>

            <hr />

            <section className="updates">
              <div className="header">
                <div className="title-section">
                  <h2>Updates & Announcements</h2>
                  <span className="header-desc">
                    Send me updates and annoucements about Kambeo.
                  </span>
                </div>
                <div className="cb-container">
                  <i
                    onClick={() =>
                      props.updateUser({
                        marketing_notifications: !props.userState.user.marketing_notifications,
                      })
                    }
                    className={`pill ${!!props.userState.user.marketing_notifications ? 'fad fa-toggle-on' : 'fad fa-toggle-off'}`}
                  />
                </div>
              </div>
            </section>

            <hr />

            <section className="deactivate-acc">
              <div className="header">
                <div className="title-section">
                  <h2>Deactivate Account</h2>
                  <span className="header-desc">
                    To deactivate your account, contact{' '}
                    <a href="mailto:support@kambeo.io">Kambeo Support</a>.
                  </span>
                </div>
              </div>
            </section>
          </div>
        )}
        {activeTabId === 'payment' && (
          <div className="tab">
            <section className="payment">
              <div className="header">
                <div className="title-section">
                  <h2>Payment Methods</h2>
                </div>
              </div>
              <div className="section-content">
                <div className="filter-container">
                  {/*<PopupMenu*/}
                  {/*    showMenuConfig={}*/}
                  {/*    menuItems={}*/}
                  {/*    onClick={}*/}
                  {/*>*/}

                  {/*</PopupMenu>*/}
                  <div className="right">
                    {/*    <TextField*/}
                    {/*        value={paymentMethodSearch}*/}
                    {/*        name='payment_method_search'*/}
                    {/*        type='text'*/}
                    {/*        onChange={(e)=>setPaymentMethodSearch(e.target.value)}*/}
                    {/*    />*/}
                    <Button
                      text="Payment Method"
                      icon="fas fa-plus"
                      onClick={() => setShowPaymentMethodModal(true)}
                      buttonType="outline-dark"
                    />
                  </div>
                </div>
                <div className="list-container">
                  {togglePaymentMethodTable && <Table {...paymentMethodSectionTableProps} />}

                  {!togglePaymentMethodTable && (
                    <div className="payment-table-loader-con">
                      <Loader loading={true} />
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        )}
      </div>

      {/* Add/Edit Modals*/}
      <Modal
        class="PaymentMethodModal"
        show={showPaymentMethodModal}
        onClose={() => setShowPaymentMethodModal(false)}
        title="Add Payment Method"
        closeIcon="fas fa-times"
      >
        {stripePromise !== null && (
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <PaymentMethodsForm
                  stripe={stripe}
                  elements={elements}
                  onClose={() => {
                    setTogglePaymentMethodTable(false);
                    setShowPaymentMethodModal(false);
                    setTogglePaymentMethodTable(true);
                  }}
                />
              )}
            </ElementsConsumer>
          </Elements>
        )}
      </Modal>

      <Modal
        closeIcon=" "
        class="LocationFormModal"
        onClose={() => setShowLocationFormModal(false)}
        show={showLocationFormModal}
      >
        <div className="header-custom">
          <h2>{locationId !== null ? 'Edit Location' : 'Add Location'}</h2>
          <span>Ensure your location is accurate to fully experience Kambeo.</span>
        </div>
        <hr />
        <div className="form-container">
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (locationId !== null) {
                updateUserLocation();
              } else {
                createUserLocation();
              }

              setShowLocationFormModal(false);
            }}
          >
            <div className="details-row">
              <TextField
                label="Street Address"
                placeholder="e.g. 123 Main St"
                required={true}
                value={locationStreetAddress}
                name="streetAddress"
                type="text"
                onChange={(e) => setLocationStreetAddress(e.target.value)}
              />
            </div>
            <div className="details-row">
              <TextField
                label="Apartment / Suite #"
                placeholder="e.g. Suite 302"
                required={false}
                value={locationApt}
                name="apt"
                type="text"
                onChange={(e) => setLocationApt(e.target.value)}
              />
            </div>
            <div className="details-row">
              <TextField
                label="City"
                placeholder="e.g. Hamilton"
                required={true}
                value={locationCity}
                name="city"
                type="text"
                onChange={(e) => setLocationCity(e.target.value)}
              />
            </div>
            <div className="details-row">
              <TextField
                label="Province / State"
                placeholder="e.g. Ontario"
                required={true}
                value={locationProvince}
                name="province"
                type="text"
                onChange={(e) => setLocationProvince(e.target.value)}
              />
            </div>
            <div className="details-row">
              <PopupMenu
                popupMenuClass="PopupMenu-Location-country"
                showMenuConfig={countryPopupMenuConfig}
                menuItems={countryContextMenuItems}
                className={`country-context-menu-container ${showCountryMenu ? 'show' : ''}`}
                onClick={() => setShowCountryMenu(!showCountryMenu)}
                onSelect={() => setShowCountryMenu(false)}
              >
                <span className="title">{getSelectedCountryLabel()}</span>
                <i className={`fas fa-chevron-down menu-btn ${showCountryMenu ? 'show' : ''}`} />
              </PopupMenu>
            </div>
            <div className="details-row">
              <TextField
                label="Postal"
                placeholder="e.g. 000 000"
                required={true}
                value={locationPostal}
                name="postal"
                type="text"
                onChange={(e) => setLocationPostal(e.target.value)}
              />
            </div>

            <div className="actions">
              <Button
                buttonClass="cancel-button"
                type="button"
                buttonType="outline-dark"
                onClick={(e) => {
                  e.preventDefault();
                  setShowLocationFormModal(false);
                  clearLocationFields();
                }}
                text="Cancel"
              />
              <Button
                buttonClass="save-button"
                type="submit"
                text="Save"
                buttonType="dark"
              />
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        closeIcon=" "
        class="EmergencyContactsFormModal"
        onClose={() => setShowEmergencyContactsFormModal(false)}
        show={showEmergencyContactsFormModal}
      >
        <div className="header-custom">
          <h2>{emerIndex !== null ? 'Edit Emergency Contact' : 'Add Emergency Contact'}</h2>
          <span>
            This information will only be shared when you apply to gigs and volunteer opportunities.
          </span>
        </div>

        <hr />

        <div className="form-container">
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (emerIndex !== null) {
                updateEmergencyContact();
              } else {
                addEmergencyContact();
              }

              setShowEmergencyContactsFormModal(false);
            }}
          >
            <div className="details-row">
              <TextField
                label="Full Name"
                required
                value={emerName}
                type="text"
                name="emerName"
                onChange={(e) => setEmerName(e.target.value)}
              />
            </div>
            <div className="details-row">
              <TextField
                label="Phone Number"
                placeholder="+1-234-567-8900"
                required
                value={emerPhone}
                type="tel"
                name="emerPhone"
                onChange={(e) => setEmerPhone(e.target.value)}
              />
            </div>
            <div className="actions">
              <Button
                buttonType="outline-dark"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setShowEmergencyContactsFormModal(false);
                  clearEmergencyContactFields();
                }}
                text="Cancel"
              />
              <Button
                buttonType="dark"
                type="submit"
                text="Save"
              />
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        class="UserEmailModal"
        show={showUserEmailModal}
        onClose={() => setShowUserEmailModal(false)}
        closeIcon=" "
      >
        <div className="header-custom">
          <h2>Edit email address</h2>
          <span>Change the email address bound to your Kambeo account.</span>
        </div>

        <hr />

        <div className="form-area">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.updateUserEmail({ value: userEmail });
              setShowUserPhoneModal(false);
            }}
          >
            <div className="email-wrap medium-input-size">
              <TextField
                required={true}
                label="Account Email"
                value={userEmail}
                name="email"
                type="email"
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
              />
              {props.userState.user?.is_email_verified && (
                <div className="verified-tag">
                  <i className="far fa-check-circle" />
                  <span>Verified</span>
                </div>
              )}
            </div>
            <div className="actions">
              <Button
                buttonClass="cancel-button"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setUserEmail(props.userState.user?.email || '');
                  setShowUserEmailModal(false);
                }}
                text="Cancel"
                buttonType="outline-dark"
              />
              <Button
                buttonClass="save-button"
                isDisabled={
                  props.userState.user.is_email_verified || userEmail !== props.userState.user.email
                }
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  props.resendEmailVerification();
                  setShowUserEmailModal(false);
                }}
                text="Verify email"
                buttonType="dark"
              />
              <Button
                buttonClass="save-button"
                isDisabled={userEmail === props.userState.user.email}
                type="submit"
                text="Update"
                buttonType="dark"
              />
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        class="UserPhoneModal"
        show={showUserPhoneModal}
        onClose={() => setShowUserPhoneModal(false)}
        closeIcon=" "
      >
        <div className="header-custom">
          <h2>Edit phone number</h2>
          <span>Change the phone number bound to your Kambeo Account.</span>
        </div>

        <hr />

        <div className="form-area">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.updateUser({ phone: userPhone });
              setShowUserPhoneModal(false);
            }}
            className="medium-input-size"
          >
            <TextField
              label="Account Phone Number (ex. +1-234-567-8900)"
              value={userPhone}
              name="phone"
              type="tel"
              onChange={(e) => {
                setUserPhone(e.target.value);
              }}
            />
            <div className="actions">
              <Button
                buttonType="outline-dark"
                buttonClass="cancel-button"
                loading={props.userState.isUserLoading}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setUserPhone(props.userState.user?.phone || '');
                  setShowUserPhoneModal(false);
                }}
                text="Cancel"
              />
              <Button
                buttonType="dark"
                buttonClass="save-button"
                isDisabled={userPhone === props.userState.user.phone}
                type="submit"
                text="Update"
              />
            </div>
          </form>
        </div>
      </Modal>

      {/*Delete prompts*/}
      <Prompt
        show={paymentMethodIdToDelete !== null}
        title="Delete Payment Method"
        message="Are you sure you want to delete the Payment Method?"
        yesMessage="Yes"
        yesClass="fa fa-trash"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={async () => await deletePaymentMethod()}
        onClose={() => setPaymentMethodIdToDelete(null)}
      />

      <Prompt
        show={locationToDelete !== null}
        title="Delete Location"
        message="Are you sure you want to delete the location?"
        yesMessage="Yes"
        yesClass="fa fa-trash"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={() => props.deleteUserLocation(locationToDelete as string)}
        onClose={() => setLocationToDelete(null)}
      />

      <Prompt
        show={emergencyContactToDelete !== null}
        title="Delete Emergency Contact"
        message="Are you sure you want to delete the Emergency Contact?"
        yesMessage="Yes"
        yesClass="fa fa-trash"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={() => removeEmergencyContact()}
        onClose={() => setEmergencyContactToDelete(null)}
      />

      <Prompt
        show={twitchToDelete}
        title="Disconnect Twitch"
        message="Are you sure you want to disconnect your twitch account?"
        yesMessage="Disconnect"
        yesClass="fa fa-times"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={() => dissociateTwitch()}
        onClose={() => setTwitchToDelete(false)}
      />

      <Prompt
        show={stravaToDelete}
        title="Disconnect Strava"
        message="Are you sure you want to disconnect your strava account?"
        yesMessage="Disconnect"
        yesClass="fa fa-times"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={() => dissociateStrava()}
        onClose={() => setStravaToDelete(false)}
      />
    </Fragment>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    geolocationState: store.geolocationState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getUserInfo,
  createToast,
  updateUserLocation,
  sendResetPasswordEmail,
  updateUser,
  createUserLocation,
  deleteUserLocation,
  updateUserEmail,
  updateUserCoverImage,
  updateUserProfileImage,
  changeUserHandle,
  updateLocalizeLanguageFromState,
  resetFlowState,
  resendEmailVerification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
