import axios from 'axios';
import { IApplication } from '@gigit/interfaces';
import { routes, swapRouteParams } from '../helpers';

export namespace applicationRequestActions {
  export async function archiveApplication(applicationId: string): Promise<IApplication> {
    const response = await axios.post<IApplication>(
      swapRouteParams(routes.ARCHIVE_APPLICATION, { applicationId: applicationId }),
    );
    return response.data;
  }
}
