import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { IUserMyPageItem } from '@gigit/interfaces';
import { RouteComponentProps } from 'react-router-dom';
import { Constants } from '@gigit/constants';
import { IAppState } from '../../store';
import { IUserState } from '../../reducers/user';
import { getUserMyPageItems } from '../../actions/user';
import { changeEventStatus } from '../../actions/event';
import { changeGroupStatus } from '../../actions/group';
import { changeGigStatus } from '../../actions/gig';
import MyPagesCard from '../../components/MyPagesCard/MyPagesCard';
import Loader from '../../components/Loader/Loader';
import { Prompt } from '../../components/Prompt/Prompt';
import { capitalizeString, setSEOMetatags } from '../../helpers';
import './MyPages.scss';
import Button from '../../components/Button/Button';
import { localizeHelpers } from '../../localizeHelpers';

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  getUserMyPageItems(search?: string): void;
  changeGroupStatus(groupId: string, status: string, title: string, handle: string): void;
  changeEventStatus(eventId: string, status: string): void;
  changeGigStatus(gigId: string, status: string): void;
}

interface IState {
  data: IUserMyPageItem[];
  filterSelected: string;
  pageToDelete: IUserMyPageItem | null;
}

class MyPages extends React.Component<IProps, IState> {
  filterOptions = ['all', 'group', 'event', 'fundraiser', 'gig'];
  filterOptionsMessage = [
    {
      key: 'group',
      message: 'You currently manage no group pages',
      icon: 'fal fa-users',
      link: '/onboarding/group',
      buttonLabel: 'Create Group',
    },
    {
      key: 'event',
      message: 'You currently manage no event pages',
      icon: 'fal fa-ticket-alt',
      link: '/setup/events',
      buttonLabel: 'Create Event',
    },
    {
      key: 'fundraiser',
      message: 'You currently manage no fundraising pages',
      icon: 'fal fa-piggy-bank',
      link: '/onboarding/event',
      buttonLabel: 'Create Fundraiser',
    },
    {
      key: 'gig',
      message: 'You currently manage no volunteer/work gigs',
      icon: 'fal fa-handshake',
      link: '/onboarding/gig',
      buttonLabel: 'Post a Gig',
    },
  ];

  constructor(props: IProps) {
    super(props);

    // If filter query param is provided, default to that filter.
    let query = queryString.parse(this.props.location.search);
    const filterParam =
      typeof query.filter === 'string' &&
      this.filterOptions.find((option) => option === query.filter)
        ? query.filter
        : null;

    const defaultFilter = localStorage.getItem('userPageFilterSelected') || this.filterOptions[0];

    this.state = {
      data: [],
      pageToDelete: null,
      filterSelected: filterParam || defaultFilter,
    };
  }

  componentDidMount() {
    if (this.props.userState.isLoggedIn) {
      setSEOMetatags({
        title: localizeHelpers.translate('My Pages | Kambeo'),
        urlPath: 'my-pages',
      });
      this.props.getUserMyPageItems(this.state.filterSelected);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.userState.userMyPagesItems !== prevProps.userState.userMyPagesItems) {
      this.setState({
        data: this.props.userState.userMyPagesItems,
      });
    }

    if (this.props.userState.isLoggedIn === false) {
      localStorage.removeItem('userPageFilterSelected');
      this.props.history.push('/login');
    }
  }

  filterData(filter: string) {
    this.setState(
      {
        filterSelected: filter,
      },
      () => {
        if (this.props.userState.isLoggedIn === false) return;
        localStorage.setItem('userPageFilterSelected', filter);
        this.props.getUserMyPageItems(this.state.filterSelected);
      },
    );
  }

  getDeleteMessage(pageToDelete: IUserMyPageItem | null): string {
    if (pageToDelete !== null) {
      let tmpTxt: string;
      switch (pageToDelete.item_type) {
        case Constants.object_type.group:
          tmpTxt =
            'All events and gigs for this group will also be put in to an Archived Status. This can not be undone';
          break;
        case Constants.object_type.event:
          tmpTxt =
            'All gigs for this event will also be put in to an Archived Status. This can not be undone';
          break;
        case 'volunteer gig' || 'paid gig':
          tmpTxt = 'Gig will be put in to an Archived Status. This can not be undone';
          break;
        default:
          tmpTxt = 'Page will be archived';
      }
      return tmpTxt;
    } else {
      return '';
    }
  }

  deletePage(pageToDelete: IUserMyPageItem | null) {
    if (pageToDelete) {
      switch (pageToDelete.item_type) {
        case Constants.object_type.group:
          this.props.changeGroupStatus(
            pageToDelete.id,
            Constants.group_status.archived,
            pageToDelete.title,
            pageToDelete.handle ?? '',
          );
          break;
        case Constants.object_type.event:
          this.props.changeEventStatus(pageToDelete.id, Constants.event_status.archived);
          break;
        case 'volunteer gig' || 'paid gig':
          this.props.changeGigStatus(pageToDelete.id, Constants.gig_status.archived);
          break;
      }
    }
  }

  renderNoDataMessage() {
    const selectedOption = this.filterOptionsMessage.find(
      (message) => message.key === this.state.filterSelected,
    );
    if (selectedOption) {
      return (
        <>
          <p>
            {' '}
            <i
              className={`${selectedOption.icon} fa-5x`}
              aria-hidden="true"
            />{' '}
          </p>
          <p> {selectedOption.message} </p>
          <div className="create-cta-button">
            <Button
              onClick={() => {
                this.props.history.push(selectedOption.link);
              }}
              text={selectedOption.buttonLabel}
            />
          </div>
        </>
      );
    } else {
      return <p>No items found</p>;
    }
  }

  render() {
    return (
      <div className="My-pages">
        <div className="section-title">
          <div className="title-text">
            <span>My Pages</span>
            <p>
              These are all of your pages that you have created, followed or are an administrator
              for.
            </p>
          </div>

          <div className="title-controls">
            <ul>
              {this.filterOptions.map((option, index) => {
                return (
                  <li
                    key={index}
                    className={this.state.filterSelected === option ? 'active' : ''}
                    onClick={() => this.filterData(option)}
                  >
                    {capitalizeString(option)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="section-no-data-inner">
          {this.props.userState.userMyPagesItemsLoading && (
            <div className="loading-in-progress">
              <Loader loading={this.props.userState.userMyPagesItemsLoading} />
            </div>
          )}

          {!this.props.userState.userMyPagesItemsLoading &&
            this.state.data.length === 0 &&
            this.props.userState.isLoggedIn && (
              <div className="page-message">{this.renderNoDataMessage()}</div>
            )}

          {this.props.userState.isLoggedIn === false && (
            <div className="page-message">
              <p>Please Login to see your pages</p>
            </div>
          )}
        </div>
        <div className="section-inner">
          {!this.props.userState.userMyPagesItemsLoading &&
            this.state.data.length !== 0 &&
            this.state.data.map((item, index) => {
              return (
                <MyPagesCard
                  key={index}
                  {...this.props}
                  item={item}
                  deleteItem={(myPageItem: IUserMyPageItem) => {
                    this.setState({ pageToDelete: myPageItem });
                  }}
                />
              );
            })}

          <Prompt
            show={this.state.pageToDelete !== null}
            title={`Delete${this.state.pageToDelete !== null ? ` ${this.state.pageToDelete.item_type}` : ''}`}
            message={this.getDeleteMessage(this.state.pageToDelete)}
            yesMessage="Yes"
            yesClass="fa fa-trash"
            yesStyle="delete"
            cancelMessage="Cancel"
            onYes={() => {
              this.deletePage(this.state.pageToDelete);
              this.setState({
                data: this.state.data.filter((i) => i.id !== this.state.pageToDelete?.id),
                pageToDelete: null,
              });
            }}
            onClose={() => {
              this.setState({ pageToDelete: null });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

const mapDispatchToProps = {
  getUserMyPageItems,
  changeGigStatus,
  changeGroupStatus,
  changeEventStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPages);
