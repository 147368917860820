import { combineClassNames, formatCurrency } from '../../../helpers';
import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import { defaultCurrency } from '../../../helpers';
import Portrait from '../../Portrait/Portrait';
import './TableContextMenu.scss';
import { IAppState } from '../../../store';
import { userSelectors } from '../../../selectors/user';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

export interface IContextMenuItem {
  label: string;
  icon?: string;
  toggle?: boolean;
  link?: string;
  subMenu?: IContextMenuItem[];
  onClick?(e: any): void;
  class?: string;
  target?: string;
  profile_image_url?: string;
  owner_handle?: string;
  price?: number;
  quantity?: number;
  notranslate?: 'yes';
}

interface IProps {
  className?: string;
  title?: string;
  onClose?(): void;
  menuItems: IContextMenuItem[];
  cart?: number;
  currency?: string;
  locale: string;
  menuIcon?: string;
}

interface IState {
  showMenu: boolean;
}

/** Context menu for Table actions.
 * Uses React portals to avoid clipping issues.
 * TODO: We should switch all usages of <ContextMenu> to this component.
 */
class TableContextMenu extends React.Component<IProps, IState> {
  private readonly menuRef = React.createRef<HTMLUListElement>();
  private element: HTMLElement | null; // Element where context-menu will be mounted.
  private targetRef = createRef<HTMLDivElement>(); // Reference to target element (Element that will have the context-menu).
  private mainElement = createRef<HTMLDivElement>(); // TableContextMenu div element

  constructor(props: IProps) {
    super(props);
    this.state = {
      showMenu: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.element = document.getElementById('tooltips');
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('scroll', () => {
      if (this?.state?.showMenu) this?.setState({ showMenu: false });
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('scroll', () => {
      if (this?.state?.showMenu) this?.setState({ showMenu: false });
    });
  }

  handleClickOutside(event: any) {
    if (
      this.state?.showMenu &&
      !this.menuRef?.current?.contains(event.target) &&
      !this.targetRef.current?.contains(event.target)
    ) {
      this.setState({ showMenu: false });
    }
  }

  renderContextMenuContent() {
    const targetTop = this.targetRef.current?.getBoundingClientRect().top ?? 0;
    let top = targetTop + 50; // 50 offset so the context menu appears slightly below the icon
    const left = (this.targetRef.current?.getBoundingClientRect().left ?? 0) - 166;

    const bottom = top + this.props.menuItems.length * 51; // We assume item height is 51px (Should be improved in future)
    const windowHeight = window.innerHeight;
    const offset = Math.max(0, bottom - windowHeight); // How far off the screen is the context menu.

    if (offset > 0) {
      // Offset content menu when offset bottom of screen.
      top = targetTop - offset;
    }

    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }} //avoid table line expanding when table context menu clicked
        ref={this.mainElement}
        onMouseLeave={() => {
          this.setState({ showMenu: false });
        }}
        className={
          this.state.showMenu
            ? this.props.title
              ? 'TableContextMenu'
              : 'TableContextMenu no-title'
            : 'TableContextMenu hidden'
        }
        style={{ left: `${left}px`, top: `${top}px` }}
      >
        {this.props.cart === undefined && this.props.title && (
          <div className="title">{this.props.title}</div>
        )}
        {this.props.cart !== undefined && this.props.title && (
          <div className="title title-cart">
            <span>{this.props.title}</span>
            <span notranslate="yes">
              {formatCurrency(
                this.props.cart,
                this.props.currency ?? defaultCurrency,
                this.props.locale,
              )}
            </span>
          </div>
        )}
        <ul
          ref={this.menuRef}
          className="ContextMenu-ul"
        >
          {this.props.menuItems.map((item, index) => {
            if (item.link !== undefined) {
              return (
                <li
                  onClick={(e) => {
                    if (item.onClick !== undefined) {
                      item.onClick(e);
                    }
                  }}
                  className={item.class ? item.class + ' no-select' : 'no-select'}
                  key={index}
                  notranslate={item.notranslate}
                >
                  {item.link[0] === '/' && (
                    <Link
                      target={item.target ? item.target : ''}
                      to={item.link}
                    >
                      {item.icon !== undefined && <i className={item.icon} />}
                      <span>{item.label}</span>
                    </Link>
                  )}
                  {item.link[0] !== '/' && (
                    <a
                      href={item.link}
                      target={item.target ? item.target : ''}
                    >
                      {item.icon !== undefined && <i className={item.icon} />}
                      <span>{item.label}</span>
                    </a>
                  )}
                </li>
              );
            } else if (item.subMenu !== undefined) {
              return (
                <li
                  onClick={(e) => {
                    if (item.onClick !== undefined) {
                      e.preventDefault();
                      item.onClick(e);
                    }
                  }}
                  className={
                    item.class ? item.class + ' no-select sub-menu-wrap' : 'no-select sub-menu-wrap'
                  }
                  key={index}
                  notranslate={item.notranslate}
                >
                  <div className="sub-menu-parent">
                    {item.icon !== undefined && <i className={item.icon} />}
                    <span>{item.label}</span>
                  </div>
                  <div className="sub-menu">
                    <ul>
                      {item.subMenu.map((subMenuItem, index) => {
                        if (subMenuItem.link !== undefined) {
                          return (
                            <li key={index}>
                              <Link to={subMenuItem.link}>
                                {subMenuItem.icon !== undefined && (
                                  <i className={subMenuItem.icon} />
                                )}
                                <span>
                                  <span>{subMenuItem.label}</span>
                                </span>
                              </Link>
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </ul>
                  </div>
                </li>
              );
            } else if (item.class !== undefined && item.class === 'store-item') {
              return (
                <li
                  className={
                    item.class
                      ? item.class + ' no-select toggle-container'
                      : 'no-select toggle-container'
                  }
                  key={index}
                  notranslate={item.notranslate}
                >
                  <Portrait
                    size={40}
                    currentImage={item.profile_image_url}
                  />
                  <div className="store-item-content">
                    <span notranslate="yes">{item.label}</span>
                    <span notranslate="yes">{item.owner_handle}</span>
                  </div>
                  <div className="store-item-price">
                    <span notranslate="yes">
                      {formatCurrency(
                        item.price || 0,
                        this.props.currency ?? defaultCurrency,
                        this.props.locale,
                      )}
                    </span>
                    {item.quantity && (
                      <span className="quantity">
                        Qty: <var data-var="item_quantity">{item.quantity}</var>
                      </span>
                    )}
                  </div>
                  {item.toggle !== undefined && (
                    <i className={item.toggle ? 'fad fa-toggle-on' : 'fad fa-toggle-off'} />
                  )}
                  <div
                    onClick={(e: any) => {
                      if (item.onClick) {
                        item.onClick(e);
                      }
                    }}
                    className="remove-item"
                  >
                    <i className="fal fa-times" />
                  </div>
                </li>
              );
            } else {
              return (
                <li
                  onClick={(e) => {
                    if (item.onClick !== undefined) {
                      item.onClick(e);
                    }
                  }}
                  className={combineClassNames(
                    item.class
                      ? item.class + ' no-select toggle-container'
                      : 'no-select toggle-container',
                    item.onClick ? '' : 'noClick',
                  )}
                  key={index}
                  notranslate={item.notranslate}
                >
                  {item.icon !== undefined && (
                    <i className={combineClassNames('icon', item.icon)} />
                  )}
                  <span className="label">{item.label}</span>
                  {item.toggle !== undefined && (
                    <i className={item.toggle ? 'fad fa-toggle-on' : 'fad fa-toggle-off'} />
                  )}
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div
        className={combineClassNames('TableContextMenuIcon', this.props.className)}
        onMouseLeave={() => {
          this.setState({ showMenu: false });
        }}
      >
        {ReactDOM.createPortal(this.renderContextMenuContent(), this.element!)}
        <i
          className={combineClassNames(
            `icon ${this.props?.menuIcon ? this.props.menuIcon : 'fal fa-ellipsis-h-alt'}`,
            this.props.menuItems.length ? '' : 'no-items',
          )}
          onMouseEnter={() => {
            this.props.menuItems.length && this.setState({ showMenu: true });
          }}
          ref={this.targetRef}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

export default connect(mapStateToProps)(TableContextMenu);
