import axios from 'axios';
import { Dispatch } from 'redux';
import { routes, toastError } from '../helpers';
import { Config } from '@gigit/config';
import { createToast } from './toaster';
import errorHelpers from '../helpers/errorHelpers';
import {
  IConversation,
  IConversationMessage,
  IConversationSummary,
  IUser,
} from '@gigit/interfaces';
import { chatRequestActions } from '../requestActions/chat';

export interface ISocketInterface {
  chat: any;
}

export enum ChatActionTypes {
  BLOCK_USER = 'BLOCK_USER',
  START_CONVERSATION = 'START_CONVERSATION',
  START_GROUP_CONVERSATION = 'START_GROUP_CONVERSATIONS',
  GET_CONVERSATIONS = 'GET_CONVERSATIONS',
  UPDATE_CONVERSATION_VISIBILITY = 'UPDATE_CONVERSATION_VISIBILITY',
  SEND_MESSAGE = 'SEND_MESSAGE',
  RECEIVE_MESSAGE = 'RECEIVE_MESSAGE',
  RESET_CHAT = 'RESET_CHAT',
  ADD_USER_TO_CHAT = 'ADD_USER_TO_CHAT',
  REMOVE_USER_FROM_CHAT = 'REMOVE_USER_FROM_CHAT',
  UNBLOCK_USER = 'UNBLOCK_USER',
  ADD_ONLINE_USER = 'ADD_ONLINE_USER',
  REMOVE_ONLINE_USER = 'REMOVE_ONLINE_USER',
  CLOSE_ALL = 'CLOSE_ALL',
  TOGGLE_CHAT_VISIBILITY = 'TOGGLE_CHAT_VISIBILITY',
  OPEN_CHAT_CONTAINER = 'OPEN_CHAT_CONTAINER',
  UPDATE_USER_CONNECTED_STATUS = 'UPDATE_USER_CONNECTED_STATUS',
  RECONNECT_CHAT = 'RECONNECT_CHAT',
  COMPOSE_MESSAGE = 'COMPOSE_MESSAGE',
  SET_AS_READ = 'SET_AS_READ',
}

export interface IStartConversationAction {
  users: Array<any>;
  type: ChatActionTypes.START_CONVERSATION;
}

export interface IToggleChatVisibility {
  type: ChatActionTypes.TOGGLE_CHAT_VISIBILITY;
}

export interface IStartGroupConversationAction {
  users: Array<any>;
  type: ChatActionTypes.START_GROUP_CONVERSATION;
}

export interface IGetConversationsAction {
  conversations: Array<any>;
  type: ChatActionTypes.GET_CONVERSATIONS;
}

export interface IUpdateConversationVisibility {
  config: any;
  type: ChatActionTypes.UPDATE_CONVERSATION_VISIBILITY;
}

export interface ISendMessage {
  config: any;
  type: ChatActionTypes.SEND_MESSAGE;
}

export interface IReceiveMessage {
  conversation: IConversation;
  type: ChatActionTypes.RECEIVE_MESSAGE;
}

export interface ISetAsRead {
  conversation: IConversation;
  user_id: string;
  type: ChatActionTypes.SET_AS_READ;
}

export interface IResetChatState {
  type: ChatActionTypes.RESET_CHAT;
}

export interface IAddOnlineUser {
  onlineUsers: Array<string>;
  type: ChatActionTypes.ADD_ONLINE_USER;
}

export interface IRemoveOnlineUser {
  OnlineUsers: Array<string>;
  type: ChatActionTypes.REMOVE_ONLINE_USER;
}

export interface ICloseAll {
  conversations: Array<any>;
  type: ChatActionTypes.CLOSE_ALL;
}

export interface IOpenChatContainer {
  type: ChatActionTypes.OPEN_CHAT_CONTAINER;
}

export interface IReconnectChat {
  type: ChatActionTypes.RECONNECT_CHAT;
}

export interface IComposeMessage {
  type: ChatActionTypes.COMPOSE_MESSAGE;
}

export type ChatActions =
  | IStartConversationAction
  | IStartGroupConversationAction
  | IGetConversationsAction
  | IUpdateConversationVisibility
  | IReceiveMessage
  | IResetChatState
  | IAddOnlineUser
  | IRemoveOnlineUser
  | IToggleChatVisibility
  | IOpenChatContainer
  | IReconnectChat
  | IComposeMessage
  | ICloseAll
  | ISetAsRead;

export const getCurrentConversations = (conversation?: IConversationSummary) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .get(Config.web.REACT_APP_CHAT + routes.GET_CONVERSATIONS)
      .then((response) => {
        dispatch({
          conversations: response.data,
          type: ChatActionTypes.GET_CONVERSATIONS,
        });

        if (conversation) {
          openChatContainer(conversation)(dispatch);
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        if (
          errorObj.errorCode !== 'ERROR.AUTH.REFRESH_TOKEN_EXPIRED' &&
          errorObj.errorCode !== 'ERROR.AUTH.UNAUTHORIZED'
        ) {
          let toast = toastError(errorObj.translatedMessage, 'Get Current Conversations');
          createToast(toast)(dispatch, getState);
        }
      });
  };
};

export const startConversation = (userIds: string[]) => {
  return async (dispatch: Dispatch, getState: any) => {
    // close previous open chat window if existing to allow smooth transition to new chat window
    closeAll()(dispatch, getState);

    const msgPayload = {
      message: {
        content: '',
        'meta-data': null,
      },
      to_user_ids: userIds,
    };

    let isEqualArray = (a: Array<string>, b: Array<string>) => {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      );
    };

    let existingConvos = getState().chatState.conversations;
    let uId = getState().userState.user.id;
    let convo: IConversation | null = null;

    // convo exists
    let ec = existingConvos.filter((conversation: IConversation) => {
      let toIds = conversation.user_ids.filter((id: string) => id !== uId);
      return isEqualArray(userIds, toIds);
    });

    if (ec.length === 0) {
      // start new convo
    } else {
      // set existing convo
    }

    if (convo) {
      openChatContainer(convo)(dispatch);
    }
  };
};

export const toggleChatVisibility = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: ChatActionTypes.TOGGLE_CHAT_VISIBILITY,
    });

    if (getState().chatState.isOpen) {
      getCurrentConversations()(dispatch, getState);
    }
  };
};

export const openChatContainer = (conversation: IConversationSummary) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ChatActionTypes.OPEN_CHAT_CONTAINER,
    });

    updateConversationVisibility(conversation)(dispatch);
  };
};

export const resetChatState = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ChatActionTypes.RESET_CHAT,
    });
  };
};

export const receiveMessage = (conversation: IConversation) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      conversation,
      type: ChatActionTypes.RECEIVE_MESSAGE,
    });
  };
};

export const setAsRead = (conversation: IConversation, user_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      conversation,
      user_id,
      type: ChatActionTypes.SET_AS_READ,
    });
  };
};

export const clearNewMessage = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      conversation: null,
      type: ChatActionTypes.RECEIVE_MESSAGE,
    });
  };
};

export const composeMessage = (toUsers: Array<string>) => {
  // should include to open existing convo too
  return async (dispatch: Dispatch, getState: any) => {
    if (!getState().chatState.isOpen) {
      toggleChatVisibility()(dispatch, getState);
    }

    clearComposeMessage()(dispatch);

    let isEqualArray = (a: Array<string>, b: Array<string>) => {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      );
    };

    let existingConvos = getState().chatState.conversations;
    let uId = getState().userState.user.id;

    // convo exists
    let existingConversation = existingConvos.filter((conversation: IConversationSummary) => {
      let toIds = conversation.user_ids.filter((id: string) => id !== uId);
      return isEqualArray(toUsers, toIds);
    });

    if (existingConversation.length > 0) {
      let ec = existingConversation[0];
      getCurrentConversations(ec)(dispatch, getState);
      dispatch({
        composeMessageUsers: [],
        type: ChatActionTypes.COMPOSE_MESSAGE,
      });
    } else {
      dispatch({
        composeMessageUsers: toUsers,
        type: ChatActionTypes.COMPOSE_MESSAGE,
      });
    }
  };
};

export const clearComposeMessage = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      composeMessageUsers: [],
      type: ChatActionTypes.COMPOSE_MESSAGE,
    });
  };
};

export const updateConversationVisibility = (conversation: IConversationSummary) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      config: { id: conversation.id },
      type: ChatActionTypes.UPDATE_CONVERSATION_VISIBILITY,
    });
  };
};

export const reconnectChat = () => {
  return async (dispatch: Dispatch, getState: any) => {
    const isLoggedIn = getState().userState?.token?.access_token;

    if (isLoggedIn) {
      dispatch({
        type: ChatActionTypes.RECONNECT_CHAT,
      });
    }
  };
};

export const addOnlineUser = (userId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    let onlineUsers = await chatRequestActions.getOnlineUsers();
    const isAlreadyInArray = onlineUsers.find((id: string) => userId === id);

    if (!isAlreadyInArray) {
      onlineUsers.push(userId);
    }
    dispatch({
      onlineUsers,
      type: ChatActionTypes.ADD_ONLINE_USER,
    });
  };
};

export const removeOnlineUser = (userId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    let onlineUsers = await chatRequestActions.getOnlineUsers();
    onlineUsers.forEach((id: string, index: number) => {
      if (userId === id) {
        onlineUsers.splice(index, 1);
      }
    });
    dispatch({
      onlineUsers,
      type: ChatActionTypes.ADD_ONLINE_USER,
    });
  };
};

export const closeAll = () => {
  return async (dispatch: Dispatch, getState: any) => {
    let conversations = getState().chatState.conversations;

    conversations.forEach((conversation: IConversationSummary) => {
      if (conversation.isOpen) {
        conversation.isOpen = false;
        return conversation;
      } else {
        return conversation;
      }
    });

    dispatch({
      conversations: conversations,
      type: ChatActionTypes.CLOSE_ALL,
    });
  };
};
