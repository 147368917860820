import { Config } from '@gigit/config';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import React from 'react';
import { CheckboxCard } from '../CheckboxCard/CheckboxCard';
import pwrdByGoogleImg from '../../../../assets/powered_by_google_on_white_hdpi.png';

import './LocationSelection.scss';
import { IAddressFromGoogle, IEntityBaseInfo } from '../interfaces';
import { IMapData } from '../../../../routes/Search/Search';

const locationTypes = ['Venue', 'Online'];

interface IProps {
  baseInfo: IEntityBaseInfo;
  onChange(e: string): void;
  onChangeAddress(address: IAddressFromGoogle): void;
}

export const LocationSelection: React.FC<IProps> = (props: IProps) => {
  function onAutoCompleteChange(value: IMapData) {
    geocodeByAddress(value.value.description)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        geocodeByPlaceId(value.value.place_id).then((results) => {
          const address = { address: results[0], coordinates: { longitude: lng, latitude: lat } };
          props.onChangeAddress(address);
        });
      });
  }
  return (
    <div className="LocationSelection">
      <label>Location</label>
      <div className="location-container">
        {locationTypes.map((type) => {
          return (
            <CheckboxCard
              showCheckbox={false}
              key={type}
              id={type}
              title={type}
              checked={props.baseInfo.locationType === type}
              onChange={(value) => {
                props.onChange(value);
              }}
            />
          );
        })}
      </div>

      {props.baseInfo.locationType === 'Venue' && (
        <div>
          <label>Address</label>
          <GooglePlacesAutocomplete
            apiKey={Config.web.REACT_APP_GOOGLE_API_KEY}
            selectProps={{
              onChange: (e: IMapData) => onAutoCompleteChange(e),
              defaultInputValue: props.baseInfo.address?.address?.formatted_address,
            }}
          />
          <img
            className="google-attribution"
            src={pwrdByGoogleImg}
            alt="Powered By Google"
          />
        </div>
      )}
    </div>
  );
};
