import React, { ReactNode } from 'react';
import Portrait from '../Portrait/Portrait';
import './SearchResultCard.scss';

import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { errorHelpers, stripHtml } from '../../helpers';
import VMPoweredBy from './../../assets/VM_powered_by.png';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { userSelectors } from '../../selectors/user';
import { IEvent, IEventSummaryFE, IGig, IGigSummaryFE, IGroup } from '@gigit/interfaces';
import { groupRequestActions } from '../../requestActions';

interface ISearchResultCard {
  result: IGroup | IEventSummaryFE | IGigSummaryFE;
  type: string;
  mapActive: Boolean;
  isFollowing: Boolean;
  isVisible: boolean;
  isVirtual?: Boolean;
  isVM?: boolean;
  onShareClick: (handle: string) => void;
  onResultClick: () => void;
}

export const SearchResultCard = (props: ISearchResultCard) => {
  const isLoggedIn = useSelector((state: IAppState) => userSelectors.isUserAuthenticated(state));
  const { result: searchResultItem, onResultClick } = props;

  const renderContentInfo = () => {
    let item;
    switch (props.type) {
      case 'group':
        item = props.result as IGroup;
        return (
          <>
            <div
              className="title"
              notranslate="yes"
            >
              {item.title}
            </div>
            <div
              className="description"
              notranslate="yes"
            >
              {stripHtml(item.description || '')}
            </div>
          </>
        );
      case 'event':
        item = props.result as IEventSummaryFE;
        return (
          <>
            <div
              notranslate="yes"
              className="date"
            >
              {/* TODO: FIX LOCALE */}
              {localizeHelpers.formatDate(item.start_date, LocaleDateFormats.LLLL, 'EN')}
            </div>
            <div
              className="title"
              notranslate="yes"
            >
              {item.title}
            </div>
            <div
              className="organizer"
              notranslate="yes"
            >
              {item.event_organizer}
            </div>
            <div
              className="description event"
              notranslate="yes"
            >
              {stripHtml(item.description || '')}
            </div>
          </>
        );
      case 'gig':
        item = props.result as IGigSummaryFE;
        return (
          <>
            <div className="type">{item.gig_type} Opportunity</div>
            <div
              className="title"
              notranslate="yes"
            >
              {item.title}
            </div>
            <div
              className="organizer"
              notranslate="yes"
            >
              by {item.group_title}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  async function followGroup(id: string) {
    try {
      await groupRequestActions.followGroup(id);
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      // toast dispatcher
    }
  }

  async function unfollowGroup(id: string) {
    try {
      await groupRequestActions.unfollowGroup(id);
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      // toast dispatcher
    }
  }

  return (
    <div
      className={`SearchResultCard ${props.isVisible ? '' : 'hide'}`}
      id={props.result.id}
      onClick={() => onResultClick()}
    >
      <div className="MapListItem-inner">
        {props.isVM && (
          <img
            src={VMPoweredBy}
            className="vm-powered-by"
          />
        )}
        <div className="image-container">
          <div
            className="bg-image-container"
            style={{ backgroundColor: '#5e51ab80' }}
          >
            {searchResultItem?.cover_image_url && (
              <img
                src={searchResultItem.cover_image_url}
                alt={searchResultItem.handle}
                loading="lazy"
                height="100%"
                width="100%"
                style={{ objectFit: 'cover' }}
              />
            )}
            <div className="overlay"></div>
          </div>
          <div className="floating-actions">
            <Portrait
              currentImage={searchResultItem.profile_image_url}
              size={60}
            />
            <div className="actions">
              {!props.mapActive && (
                <>
                  <button
                    type="button"
                    className="share"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.onShareClick(searchResultItem.handle!);
                    }}
                  >
                    <span className="icon">
                      <i
                        className="fas fa-share-alt"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </button>
                  {props.type === 'group' && isLoggedIn && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (searchResultItem.id) {
                          props.isFollowing
                            ? unfollowGroup(searchResultItem.id)
                            : followGroup(searchResultItem.id);
                        }
                      }}
                      className="follow"
                    >
                      <b>{props.isFollowing ? 'Followed' : 'Follow'}</b>
                    </button>
                  )}
                  {props.type === 'event' && props.isVirtual && (
                    <button
                      type="button"
                      className="virtual"
                    >
                      <span className="icon">
                        <i
                          className="fas fa-globe"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <span>Virtual</span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mobile">
          <Portrait
            currentImage={searchResultItem.profile_image_url}
            size={60}
          />
        </div>
        <div className="MapListItem-info">{renderContentInfo()}</div>
      </div>
    </div>
  );
};

export default SearchResultCard;
