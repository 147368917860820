import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import {
  errorHelpers,
  handleInputChange,
  routes,
  swapRouteParams,
  toastError,
  downloadFile,
} from '../../helpers';
import TextField from '../../components/TextField/TextField';

import './AttendeeManagement.scss';
import Portrait from '../Portrait/Portrait';
import {
  getEventAttendees,
  checkInEventTicket,
  getEventIndividualsExport,
} from '../../actions/event';
import { IEventState } from '../../reducers/event';
import Button from '../Button/Button';
import axios from 'axios';
import { ReduxActionType } from '../../interfaces';
import { createToast } from '../../actions/toaster';

interface IProps extends RouteComponentProps<any> {
  eventId: string;
  eventState: IEventState;
  getEventAttendees: ReduxActionType<typeof getEventAttendees>;
  checkInEventTicket: ReduxActionType<typeof checkInEventTicket>;
  getEventIndividualsExport: ReduxActionType<typeof getEventIndividualsExport>;
  createToast: ReduxActionType<typeof createToast>;
}

interface IState {
  searchValue: string;
  detailOpen: string;
  showAdd: boolean;
}

class AttendeeManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: '',
      detailOpen: '',
      showAdd: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.props.getEventAttendees(this.props.eventId);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {}

  toggleModal(value: boolean) {
    this.setState({
      showAdd: value,
    });
  }

  exportIndividuals() {
    this.props.getEventIndividualsExport(
      this.props.eventState.event.id,
      this.props.eventState.event.handle,
    );
  }

  getAttendeesExport() {
    axios
      .get(swapRouteParams(routes.GET_EVENT_ATTENDEES_EXPORT, { eventId: this.props.eventId }), {
        responseType: 'blob',
      })
      .then((response) => {
        downloadFile(`Attendees_for_${this.props.eventState.event.handle}.csv`, response.data);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Attendees Export');
        createToast(toast);
      });
  }

  render() {
    const searchValue = this.state.searchValue.toLowerCase();
    const attendees = searchValue
      ? this.props.eventState.attendees?.filter((attendee) =>
          attendee.user?.display_name!.toLowerCase().includes(searchValue),
        )
      : this.props.eventState.attendees;

    return (
      <div className="AttendeeManagement section-wrap">
        <div className="section-title">
          <div className="forms-title">Attendees</div>
          <div className="forms-controls">
            <Button
              className="export-button"
              icon="far fa-file-download"
              onClick={() => this.exportIndividuals()}
              text="Export Fundraisers"
            />
            <Button
              className="export-button"
              icon="far fa-file-download"
              onClick={() => this.getAttendeesExport()}
              text="Export Attendees"
            />
          </div>
        </div>
        <div className="section-inner">
          <div className="AttendeeManagement-list">
            <div className="search">
              <TextField
                icon="fas fa-search"
                placeholder="Search attendees..."
                value={this.state.searchValue}
                type="text"
                name="searchValue"
                onChange={(e: any) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="list">
              <div className="headers">
                <div className="col portrait-title portrait-td"></div>
                <div className="col name">Attendee Name</div>
                <div className="col check-in">Checked In?</div>
              </div>
              <div className="list-inner">
                <div className="list-rows">
                  {attendees?.map((attendee, index) => {
                    return (
                      <div
                        key={index}
                        className="row"
                      >
                        <div className="col portrait-td">
                          <Portrait
                            source={attendee.user?.profile_image_url}
                            size={40}
                          />
                        </div>
                        <div
                          className="col name"
                          notranslate="yes"
                        >
                          {attendee.user?.display_name}
                        </div>
                        <div className="col check-in">
                          <ul>
                            {attendee.tickets?.map((ticket: any, ticketIndex: any) => {
                              if (ticket.checked_in) {
                                return (
                                  <li
                                    onClick={() => {
                                      this.props.checkInEventTicket(
                                        attendee.event_id,
                                        attendee.id ?? '',
                                        ticket.id,
                                      );
                                    }}
                                    key={ticketIndex}
                                  >
                                    <i className="fad fa-toggle-on"></i>
                                    <span>Yes</span>
                                  </li>
                                );
                              } else {
                                return (
                                  <li
                                    onClick={() => {
                                      this.props.checkInEventTicket(
                                        attendee.event_id,
                                        attendee.id ?? '',
                                        ticket.id,
                                      );
                                    }}
                                    key={ticketIndex}
                                  >
                                    <i className="fad fa-toggle-off"></i>
                                    <span>No</span>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                  {attendees.length === 0 && (
                    <div className="empty">Your search returned 0 results.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    eventState: store.eventState,
    settingsState: store.settingsState,
  };
};

const mapDispatchToProps = {
  getEventAttendees,
  checkInEventTicket,
  getEventIndividualsExport,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendeeManagement);
