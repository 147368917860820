import React from 'react';
import { ICardComponentProps } from '../ForOrganizations';
import './ForOrganizationCard.scss';
import { Link } from 'react-router-dom';

export const ForOrganizationCard = (props: ICardComponentProps) => {
  return (
    <article
      key={props.keyValue}
      className="ForOrganizationCard"
    >
      <div className="image-container">
        <Link
          to={props.link}
          target={props?.linkNewTab ? '_blank' : undefined}
        >
          <div
            className="image-carrier"
            style={{ backgroundImage: `url(${props.image.src})` }}
          />
        </Link>
      </div>

      <Link
        to={props.link}
        target={props?.linkNewTab ? '_blank' : undefined}
      >
        <div className="title-container">
          <span>
            {props.title}
            <span className="icon-container">{props.iconNode}</span>
          </span>
        </div>
      </Link>
    </article>
  );
};
