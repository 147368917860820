import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {
  errorHelpers,
  handleInputChange,
  IStringMap,
  mapPermissions,
  setSEOMetatags,
  toastError,
  toastSuccess,
  isURL,
} from '../../helpers';
import moment, { Moment } from 'moment';
import { createToast } from '../../actions/toaster';
import { getEvent } from '../../actions/event';
import { updateGig, getGig } from '../../actions/gig';
import { getGroup } from '../../actions/group';
import { IGigState } from '../../reducers/gig';
import { IGroupState } from '../../reducers/group';
import { IUserState } from '../../reducers/user';
import { IEventState } from '../../reducers/event';
import { IAppState } from '../../store';
import { IOptions } from '../../components/Dropdown/Dropdown';
import Button from '../../components/Button/Button';
import DatePicker from '../../components/DatePicker/DatePicker';
import Construction from '../../components/Construction/Construction';
import QuillTextEditor from '../../components/QuillTextEditor/QuillTextEditor';
import VolunteerManagement from '../../components/VolunteerManagement/VolunteerManagement';
import LocationManagement from '../../components/LocationManagement/LocationManagement';
import Scheduler from '../../components/Scheduler/Scheduler';
import './GigAdmin.scss';
import Modal from '../../components/Modal/Modal';
import Login from '../Login/Login';
import { IToast } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import ManagementSidebar, {
  IMenuSection,
} from '../../components/ManagementSidebar/ManagementSidebar';
import typeHelpers from '../../helpers/typeHelpers';
import ToggleButton from '../../components/shared/ToggleButton/ToggleButton';
import FormManagement from '../../components/FormManagement/FormManagement';
import TextField from '../../components/TextField/TextField';
import ExternalVolunteerApplicationWarning from '../../components/shared/ExternalVolunteerApplicationWarning/ExternalVolunteerApplicationWarning';
import { userRequestActions } from '../../requestActions/user';
import { IUserRoleJoin } from '@gigit/interfaces';
import { EventPermissions, GroupPermissions, HubPermissions, Constants } from '@gigit/constants';

interface IProps extends RouteComponentProps<any> {
  updateGig(gig: any): void;
  createToast(toast: IToast): void;
  getGig(handleOrId: string): void;
  getGroup(handle: string): void;
  getEvent(handle: string): void;
  userState: IUserState;
  gigState: IGigState;
  groupState: IGroupState;
  eventState: IEventState;
}

interface IState {
  userPermissions: IStringMap;
  redirect: string | null;
  showLoginModal: boolean;
  mounted: boolean;
  activeTab: string;
  activeRow: string;
  startDate: Moment;
  endDate: Moment;
  formOptions: IOptions[];
  gigForm: string;
  description: string;
  external_application_url: string;
  external_application_url_error: boolean;
  showExternalVolunteerApplicationWarning: boolean;
  contactPhone: string;
  contactEmail: string;
}

class GigAdmin extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      mounted: false,
      userPermissions: {},
      activeTab: 'settings',
      redirect: null,
      showLoginModal: false,
      activeRow: '',
      startDate: moment(this.props.gigState.gig.start_date),
      endDate: moment(this.props.gigState.gig.end_date),
      formOptions: [],
      gigForm: this.props.gigState.gig.form_id || '',
      description: this.props.gigState.gig.description || '',
      external_application_url: this.props.gigState.gig.external_application_url || '',
      external_application_url_error: false,
      showExternalVolunteerApplicationWarning: false,
      contactPhone: this.props.gigState.gig.contact_details?.phone || '',
      contactEmail: this.props.gigState.gig.contact_details?.email || '',
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.toggleActiveRow = this.toggleActiveRow.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.saveDescription = this.saveDescription.bind(this);
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.gigState.gig !== this.props.gigState.gig) {
      if (this.props.gigState.gig?.group_id) this.props.getGroup(this.props.gigState.gig.group_id);

      if (this.props.gigState.gig.event_id) {
        this.props.getEvent(this.props.gigState.gig.event_id!);
      }

      this.setState({
        startDate: moment(this.props.gigState.gig.start_date) || '',
        endDate: moment(this.props.gigState.gig.end_date) || '',
        gigForm: this.props.gigState.gig.form_id || '',
      });
    }

    if (this.props.groupState.groupForms !== prevProps.groupState.groupForms) {
      let _options: any = [{ label: 'None', value: '' }];

      for (let f in this.props.groupState.groupForms) {
        let _form = this.props.groupState.groupForms[f];
        _options.push({ label: _form.form_name, value: _form.id });
      }

      this.setState({
        formOptions: _options,
      });
    }

    if (
      this.props.groupState.currentUserRole !== prevProps.groupState.currentUserRole ||
      this.props.eventState.currentUserRole !== prevProps.eventState.currentUserRole
    ) {
      let _permissions: IStringMap = mapPermissions(
        this.props.gigState.gig.event_id
          ? this.props.eventState.currentUserRole.permissions
          : this.props.groupState.currentUserRole.permissions,
      );

      this.setState(
        {
          userPermissions: _permissions,
        },
        () => {
          // If user doesn't have permissions to access page, we boot them to the group page.
          if (
            this.state.mounted &&
            this.state.userPermissions['MANAGE_GIGS'] === false &&
            !this.props.userState.awaitingToken
          ) {
            this.setState({
              redirect: `/gig/${this.props.gigState.gig.handle}`,
            });
            const toast = toastError(
              localizeHelpers.translate(
                'You must be an admin of the Volunteer Opportunity to view admin page',
              ),
              'Insufficient Permissions',
            );
            this.props.createToast(toast);
          }
        },
      );
    }

    // If gig doesn't exist, then visit not found page.
    if (
      prevProps.gigState.isGigLoading &&
      !this.props.gigState.isGigLoading &&
      !this.props.userState.awaitingToken
    ) {
      if (
        this.props.gigState.gig.handle == null ||
        this.props.gigState.gig.handle.length === 0 ||
        (this.props.groupState.group.status &&
          this.props.groupState.group.status.code === 'archived')
      ) {
        this.setState({
          redirect: '/not-found',
        });
      }
    }

    if (
      this.props.userState.isLoggedIn !== prevProps.userState.isLoggedIn &&
      !this.props.userState.awaitingToken
    ) {
      //TODO: GIG-7896 - test this, unsure
      // GIG-740: Show login when user isn't logged in.
      if (this.props.userState.isLoggedIn === false)
        this.props.history.push(`/login?redirect=/gig/${this.props.match.params.id}/admin`);

      // Refetch group and group forms data after login. This ensures user auth is up to date with current login
      if (this.props.userState.isLoggedIn && this.props.gigState.gig.group_id) {
        this.props.getGroup(this.props.gigState.gig.group_id);
      }
    }
  }

  initialize = async () => {
    await this.checkUserRole();
    this.props.getGig(this.props.match.params.id);
    let _params = queryString.parse(this.props.location.search);

    if (_params.t) {
      this.setState({
        activeTab: _params.t as string,
      });
    }

    this.setState(
      {
        mounted: true,
      },
      () => {
        if (this.props.userState.isLoggedIn === false) {
          this.props.history.push(`/login?redirect=/gig/${this.props.match.params.id}/admin`);
        } else {
          setSEOMetatags({
            urlPath: 'gig/' + this.props.gigState.gig.handle,
            title: this.props.gigState.gig.title,
            description: this.props.gigState.gig.description,
            imageURL: this.props.gigState.gig.profile_image_url,
            coverImageURL: this.props.gigState.gig.cover_image_url,
          });
        }
      },
    );
  };

  /*
   * Checks the user's role in the group, event, or hub that the gig is associated with.
   */
  checkUserRole = async () => {
    // If the user isn't logged in, we skip the check and redirect them to the login page.
    if (this.props.userState.isLoggedIn === false) {
      this.props.history.push(`/login?redirect=/gig/${this.props.match.params.id}/admin`);
      return;
    }
    try {
      let getUserRole: (id: string) => Promise<IUserRoleJoin> | Promise<undefined> = (id: string) =>
        Promise.resolve(undefined);
      let requiredPermission: string;
      let id: string | null = null;
      // If the gig is associated with a group, event, or hub, we need to check the user's role in that group, event, or hub.
      if (this.props.gigState.gig?.event_id) {
        getUserRole = userRequestActions.getUserEventRole;
        requiredPermission = EventPermissions.MANAGE_GIGS;
        id = this.props.gigState.gig.event_id;
      } else if (this.props.gigState.gig?.group_id) {
        getUserRole = userRequestActions.getUserGroupRole;
        requiredPermission = GroupPermissions.MANAGE_GIGS;
        id = this.props.gigState.gig.group_id;
      } else if (this.props.gigState.gig?.hub_id) {
        getUserRole = userRequestActions.getUserHubRole;
        requiredPermission = HubPermissions.MANAGE_GIGS;
        id = this.props.gigState.gig.hub_id;
      }
      typeHelpers.assertNotNullOrUndefined(id);
      const role = await getUserRole(id);
      if (
        !role ||
        !role.permissions.find(
          (permission) => permission?.permission === requiredPermission && permission.value,
        )
      )
        this.props.history.push(`/`);
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Fetching User Role');
      this.props.createToast(toast);
    }
  };
  isActiveTab(tab: string) {
    return tab === this.state.activeTab ? 'tab active' : 'tab';
  }

  setActiveTab(tab: string) {
    this.setState({
      activeTab: tab,
    });
  }

  toggleActiveRow(row: string) {
    if (row === this.state.activeRow) {
      this.setState({
        activeRow: '',
      });
    } else {
      this.setState({
        activeRow: row,
      });
    }
  }

  saveDescription() {
    let _payload: any = {
      id: this.props.gigState.gig.id,
      handle: this.props.gigState.gig.handle,
      description: this.state.description,
    };

    if (this.props.gigState.gig.group_id) {
      _payload.group_id = this.props.gigState.gig.group_id;
    }

    if (this.props.gigState.gig.event_id) {
      _payload.event_id = this.props.gigState.gig.event_id;
    }

    if (this.props.gigState.gig.hub_id) {
      _payload.hub_id = this.props.gigState.gig.hub_id;
    }

    this.props.updateGig(_payload);

    const toast = toastSuccess(
      localizeHelpers.translate('Volunteer Opportunity description updated successfully.'),
      'Volunteer Opportunity Management',
    );
    this.props.createToast(toast);
  }

  handleDescriptionChange(content: any, delta: any, source: any, editor: any) {
    this.setState({ description: content });
  }

  getSidebarMenuOptions(): IMenuSection[] {
    return [
      {
        id: 'setup',
        sectionTitle: 'Setup',
        sectionIcon: 'fas fa-sliders-v',
        menuOptions: [
          { id: 'settings', title: 'settings' },
          { id: 'locations', title: 'locations', permission: 'MANAGE_EVENT_INFO' },
          {
            id: 'form',
            title: 'Application Form',
            hide: this.props.gigState.gig.gig_type === 'company',
            permission: 'MANAGE_GIGS',
            preventClick: this.props.gigState.gig.accepting_external_application
              ? () => this.setState({ showExternalVolunteerApplicationWarning: true })
              : undefined,
          },
        ],
      },
      {
        id: 'manage',
        sectionTitle: 'Manage',
        sectionIcon: 'fas fa-users',
        menuOptions: [
          {
            id: 'volunteers',
            title: 'volunteers',
            permission: 'MANAGE_APPLICATIONS',
            preventClick: this.props.gigState.gig.accepting_external_application
              ? () => this.setState({ showExternalVolunteerApplicationWarning: true })
              : undefined,
          },
          {
            id: 'schedule',
            title: 'schedule',
            permission: 'MANAGE_APPLICATIONS',
            preventClick: this.props.gigState.gig.accepting_external_application
              ? () => this.setState({ showExternalVolunteerApplicationWarning: true })
              : undefined,
          },
        ],
      },
    ];
  }

  checkExternalApplicationUrl(v: string) {
    if (v.length > 0 && !isURL(v)) {
      this.setState({ external_application_url_error: true });
    } else {
      this.setState({ external_application_url_error: false });
    }
  }

  async toggleAcceptingExternalApplication() {
    await this.props.updateGig({
      group_id: this.props.gigState.gig.group_id,
      event_id: this.props.gigState.gig.event_id,
      hub_id: this.props.gigState.gig.hub_id,
      id: this.props.gigState.gig.id,
      accepting_external_application: !this.props.gigState.gig.accepting_external_application,
    });
  }

  async saveExternalApplicationURL() {
    try {
      await this.props.updateGig({
        group_id: this.props.gigState.gig.group_id,
        event_id: this.props.gigState.gig.event_id,
        hub_id: this.props.gigState.gig.hub_id,
        id: this.props.gigState.gig.id,
        external_application_url: this.state.external_application_url,
      });
      const toast = toastSuccess(
        localizeHelpers.translate('External Application URL updated successfully.'),
        'Update Volunteer opportunity',
      );
      this.props.createToast(toast);
    } catch (err) {
      const errorObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errorObj.translatedMessage, 'External Application URL');
      this.props.createToast(toast);
    }
  }

  saveGigContactDetail() {
    this.props.updateGig({
      contact_details: {
        phone: this.state.contactPhone,
        email: this.state.contactEmail,
      },
      id: this.props.gigState.gig.id,
    });
  }

  saveGigContactDetails() {
    try {
      this.props.updateGig({
        ...this.props.gigState.gig,
        contact_details: {
          phone: this.state.contactPhone,
          email: this.state.contactEmail,
        },
      });
      const toast = toastSuccess(
        localizeHelpers.translate('Volunteer Contact Details updated successfully.'),
        'Update Volunteer opportunity',
      );
      this.props.createToast(toast);
    } catch (err) {
      const errorObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errorObj.translatedMessage, 'Update Volunteer opportunity');
      this.props.createToast(toast);
    }
  }

  render() {
    const ownerObj = typeHelpers.createOwnerObject(
      this.props.gigState?.gig?.gig_type === 'company' ? 'company' : 'gig',
      this.props.gigState.gig,
    );
    const gigState = this.props.gigState;

    if (this.state.mounted && this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    } else {
      return (
        <div className="GigAdmin">
          <ManagementSidebar
            logo={this.props.gigState.gig.profile_image_url || ''}
            title="Volunteer Opportunity Management"
            targetLink={{
              url: '/gig/' + this.props.gigState.gig.handle,
              title: this.props.gigState.gig.title,
            }}
            menuSections={this.getSidebarMenuOptions()}
            activeOptionId={this.state.activeTab}
            permissions={this.state.userPermissions}
            onMenuOptionClicked={(menuOptionId: string) => {
              this.setState({ activeTab: menuOptionId });
            }}
          />
          <div className={'section ' + this.state.activeTab + '-wrap'}>
            {this.state.activeTab === 'settings' && (
              <div className="SettingsManagement section-wrap settings">
                <div className="section-title">Settings</div>
                <div className="section-inner">
                  <div className="settings-title">Volunteer Opportunity Details</div>
                  <div
                    className="settings-item hover-item no-select"
                    onClick={() => {
                      this.toggleActiveRow('description');
                    }}
                  >
                    <div className="details">
                      <p>Volunteer Opportunity Description</p>
                      <span className="detail">
                        This short description about your volunteer opportunity will display on your
                        volunteer opportunity page.
                      </span>
                    </div>
                    {this.state.activeRow === 'description' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion message"
                      >
                        <QuillTextEditor
                          value={this.state.description}
                          readOnly={false}
                          preserveWhitespace={true}
                          theme="snow"
                          modules={{
                            toolbar: [
                              ['bold', 'italic'],
                              ['link', 'clean'],
                            ],
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          formats={['bold', 'italic', 'link']}
                          onChange={this.handleDescriptionChange}
                        />
                        <div className="message-actions">
                          <Button
                            text="Save Description"
                            onClick={() => {
                              this.saveDescription();
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="settings-item hover-item no-select"
                    onClick={() => {
                      this.toggleActiveRow('details');
                    }}
                  >
                    <div className="details">
                      <p>Date & Time</p>
                      <span className="detail">
                        Manage your volunteer opportunities start to end dates and times.
                      </span>
                    </div>
                    {this.state.activeRow === 'details' && (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();

                          if (this.state.startDate.isAfter(this.state.endDate)) {
                            const toast = toastError(
                              localizeHelpers.translate(
                                'Start date cannot be greater than end date',
                              ),
                              'Update Gig',
                            );
                            this.props.createToast(toast);

                            return;
                          }

                          let _payload: any = {
                            id: this.props.gigState.gig.id,
                            handle: this.props.gigState.gig.handle,
                            start_date: moment(this.state.startDate).utc().format(),
                            end_date: moment(this.state.endDate).utc().format(),
                          };

                          if (this.props.gigState.gig.group_id) {
                            _payload.group_id = this.props.gigState.gig.group_id;
                          }

                          if (this.props.gigState.gig.event_id) {
                            _payload.event_id = this.props.gigState.gig.event_id;
                          }

                          if (this.props.gigState.gig.hub_id) {
                            _payload.hub_id = this.props.gigState.gig.hub_id;
                          }

                          this.props.updateGig(_payload);

                          const toast = toastSuccess(
                            localizeHelpers.translate(
                              'Volunteer Opportunity start/end date updated successfully.',
                            ),
                            'Volunteer Opportunity Management',
                          );
                          this.props.createToast(toast);
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion"
                      >
                        <DatePicker
                          showTime={true}
                          date={moment(this.state.startDate)}
                          name="startDate"
                          label="Start Date"
                          error={this.state.startDate.isAfter(this.state.endDate)}
                          onChange={(date) => {
                            this.setState({ startDate: moment(date).second(0).millisecond(0) });
                          }}
                        />
                        <DatePicker
                          showTime={true}
                          date={moment(this.state.endDate)}
                          name="endDate"
                          label="End Date"
                          error={this.state.startDate.isAfter(this.state.endDate)}
                          onChange={(date) => {
                            this.setState({ endDate: moment(date).second(0).millisecond(0) });
                          }}
                        />
                        <div className="expansion-actions">
                          <Button
                            text="Save Start/End Date"
                            type="submit"
                          />
                        </div>
                      </form>
                    )}
                  </div>
                  <div className="settings-item hover-item no-select">
                    <div
                      className="details"
                      onClick={() => this.toggleActiveRow('gig-location')}
                    >
                      <p>Volunteer Opportunity Location</p>
                      <span className="detail">
                        Set whether or not this an online volunteer opportunity.
                      </span>
                    </div>
                    {this.state.activeRow === 'gig-location' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="expansion progress-bar"
                      >
                        <div className="toggle-row">
                          <span className="toggle-label">
                            This is an online volunteer opportunity.
                          </span>
                          <ToggleButton
                            onToggleClick={() =>
                              this.props.updateGig({
                                ...this.props.gigState.gig,
                                id: gigState.gig.id,
                                is_virtual: !gigState.gig.is_virtual,
                              })
                            }
                            isActive={gigState.gig.is_virtual}
                            label={gigState.gig.is_virtual ? 'Yes' : 'No'}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="settings-title">Volunteer Opportunity Application</div>
                  {this.props.gigState.gig.group && (
                    <div className="settings-item external-applications">
                      <div className="main-item">
                        <div className="details">
                          <p>External Applications</p>
                          <span className="detail">Are you accepting external applications?</span>
                        </div>
                        <div className="settings-control">
                          <i
                            onClick={async () => await this.toggleAcceptingExternalApplication()}
                            className={
                              this.props.gigState.gig.accepting_external_application
                                ? 'fad fa-toggle-on'
                                : 'fad fa-toggle-off'
                            }
                          />
                        </div>
                      </div>
                      {this.props.gigState.gig.accepting_external_application && (
                        <div
                          onClick={(e) => e.stopPropagation()}
                          className="expansion"
                        >
                          <TextField
                            label="External Applications URL"
                            onChange={(e) => {
                              this.checkExternalApplicationUrl(e.target.value || '');
                              handleInputChange(e, this);
                            }}
                            value={this.state.external_application_url || ''}
                            name="external_application_url"
                            type="text"
                            error={
                              this.state.external_application_url_error
                                ? 'Incorrect value'
                                : undefined
                            }
                          />
                          <div className="actions">
                            <Button
                              text="Save URL"
                              onClick={async () => await this.saveExternalApplicationURL()}
                              isDisabled={
                                !this.props.gigState.gig.accepting_external_application ||
                                this.state.external_application_url_error
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="settings-item">
                    <div className="details">
                      <p>Accepting Applications</p>
                      <span className="detail">Are you accepting applications at this time?</span>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          let _payload: any = {
                            id: this.props.gigState.gig.id,
                            handle: this.props.gigState.gig.handle,
                            accepting_applications: !this.props.gigState.gig.accepting_applications,
                          };

                          if (this.props.gigState.gig.group_id) {
                            _payload.group_id = this.props.gigState.gig.group_id;
                          }

                          if (this.props.gigState.gig.event_id) {
                            _payload.event_id = this.props.gigState.gig.event_id;
                          }

                          if (this.props.gigState.gig.hub_id) {
                            _payload.hub_id = this.props.gigState.gig.hub_id;
                          }

                          this.props.updateGig(_payload);
                        }}
                        className={
                          this.props.gigState.gig.accepting_applications
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      />
                    </div>
                  </div>
                  <div className="settings-title">Contact</div>
                  <div className="settings-item hover-item no-select">
                    <div className="details">
                      <p>Contact Details</p>
                      <span className="detail">Manage how users can get in touch with you.</span>
                    </div>

                    <div className="expansion contact">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.saveGigContactDetails();
                        }}
                      >
                        <TextField
                          label="Phone (ex. +1-234-567-8900)"
                          value={this.state.contactPhone}
                          name="contactPhone"
                          type="tel"
                          onChange={(e) => handleInputChange(e, this)}
                        />
                        <TextField
                          label="Email"
                          value={this.state.contactEmail}
                          name="contactEmail"
                          type="email"
                          onChange={(e) => handleInputChange(e, this)}
                        />

                        <div className="actions">
                          <Button
                            buttonClass="cancel-button"
                            text="Cancel"
                            type="button"
                            onClick={() => {
                              this.setState({
                                contactPhone: this.props.gigState.gig.contact_details?.phone || '',
                                contactEmail: this.props.gigState.gig.contact_details?.email || '',
                              });
                            }}
                          />
                          <Button
                            buttonClass="save-button"
                            text="Save"
                            type="submit"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.activeTab === 'volunteers' && (
              <VolunteerManagement
                changeAdminTab={this.setActiveTab}
                owner={ownerObj}
              />
            )}
            {this.state.activeTab === 'schedule' && (
              <Scheduler
                owner={ownerObj}
                {...this.props}
              />
            )}
            {this.state.activeTab === 'locations' && <LocationManagement owner={ownerObj} />}
            {this.state.activeTab === 'form' && (
              <FormManagement
                ownerType={ownerObj.ownerType}
                ownerId={ownerObj.ownerId}
              />
            )}
          </div>
          <Modal
            show={this.state.showLoginModal}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ showLoginModal: false });
            }}
          >
            <Login
              registerRedirectUrl={'/gig/' + this.props.gigState.gig.handle + '/admin'}
              {...this.props}
            />
          </Modal>

          <Construction {...this.props} />

          <ExternalVolunteerApplicationWarning
            show={this.state.showExternalVolunteerApplicationWarning}
            onClose={() => this.setState({ showExternalVolunteerApplicationWarning: false })}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    gigState: store.gigState,
    groupState: store.groupState,
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {
  createToast,
  updateGig,
  getGig,
  getGroup,
  getEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(GigAdmin);
