import React, { Fragment, ReactNode, useState, useMemo, useEffect } from 'react';
import type { Stripe } from 'stripe';
import {
  AccountLinkType,
  AccountOnboardingStatus,
  IAccountDetails,
  IChargeFee,
  IChargeTransaction,
  IEmbeddedAccount,
  IHub,
  IPayout,
  IPayoutKpi,
} from '@gigit/interfaces';
import {
  capitalizeString,
  combineClassNames,
  defaultCurrency,
  formatCurrency,
  typeHelpers,
} from '../../helpers';
import { IOwnerObject, IKpi } from '../../interfaces';
import { accountRequestActions } from '../../requestActions';
import { useAppDispatch } from '../../store';
import Button from '../Button/Button';
import useToastDispatcher from '../../hooks/useToaster';
import KpiDisplay from '../KpiDisplay/KpiDisplay';
import Loader from '../Loader/Loader';
import { Banner, BannerType } from '../shared/Banner/Banner';
import { SetupExternalAccountModal } from './SetupExternalAccountModal/SetupExternalAccountModal';
import { StripeProvider } from '../shared/StripeProvider/StripeProvider';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { useLocale } from '../../hooks';
import { getGroup, updateGroup } from '../../actions/group';
import './BalanceAndPayoutsManage.scss';
import { IGroupState } from '../../reducers/group';
import { Constants } from '@gigit/constants';
import { Link } from 'react-router-dom';
import { resetCurrentHub, setHub } from '../../actions/hub';
import stripeLogo from '../../assets/stripe-logo.png';
import { IOptions } from '../Dropdown/Dropdown';
import ConfusedEmoji from '../../assets/confused_emoji.svg';
import Table, { ITableProps } from '../shared/Table/Table';
import Modal from '../Modal/Modal';
import ChangeStripeAccount from './ChangeStripeAccount/ChangeStripeAccount';
import { uiConstants } from '../../constants';

interface IProps {
  owner: IOwnerObject;
  groupState?: IGroupState;
  hub?: IHub;
}

interface ITab {
  label: string;
  value: string;
}

const tabs: ITab[] = [
  {
    label: 'Overview',
    value: 'overview',
  },
  {
    label: 'Payout History',
    value: 'payout-history',
  },
];

interface IBannerInfo {
  bannerType: BannerType;
  text: string;
  buttonText: string;
  linkType: AccountLinkType;
}

type BannerRecordType = Record<AccountOnboardingStatus, IBannerInfo>;

// Status banners for custom account onboarding.
const onboardingStatusBanners: BannerRecordType = {
  requirements_met: {
    bannerType: 'success',
    text: 'Account is ready for accepting payments',
    buttonText: 'Edit Details',
    linkType: 'custom_account_update',
  },
  currently_due: {
    bannerType: 'warning',
    text: 'Stripe is requesting additional information to confirm your account status. Click on button to complete outstanding requirements',
    buttonText: 'Click Here',
    linkType: 'custom_account_verification',
  },
  past_due: {
    bannerType: 'failure',
    text: 'Stripe is requesting additional information to confirm your account status. Click on button to complete outstanding requirements',
    buttonText: 'Click Here',
    linkType: 'custom_account_verification',
  },
  eventually_due: {
    bannerType: 'info',
    text: 'Stripe is requesting additional information to confirm your account status. Click on button to complete outstanding requirements',
    buttonText: 'Click Here',
    linkType: 'custom_account_verification',
  },
};

// Manage component to manage connected stripe account & corresponding payouts.
export const BalanceAndPayoutsManage: React.FC<IProps> = (props) => {
  const params = new URLSearchParams(window.location.search);
  let accountId = params.get('account_id');
  const locale = useLocale();
  const dispatch = useAppDispatch();
  const { dispatchToastError } = useToastDispatcher();
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].value);
  const [showChangeStripeAccount, setShowChangeStripeAccount] = useState<boolean>(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<IAccountDetails[]>([]);
  const [defaultExternalAccount, setDefaultExternalAccount] = useState<Stripe.BankAccount | null>(
    null,
  );
  const [isExternalAccountLoading, setIsExternalAccountLoading] = useState<boolean>(false);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(accountId);
  const [showSetupExternalAccount, setShowSetupExternalAccount] = useState<boolean>(false);
  const [hasTransactionView, setHasTransactionView] = useState<boolean>(false);
  const [payoutCrumb, setPayoutCrumb] = useState<string>('');
  const [currentPayoutId, setCurrentPayoutId] = useState<string>('');
  const [kpiTransactionData, setKpiTransactionData] = useState<IPayoutKpi | null>(null);
  const [isKpiTransactionDataLoading, setIsKpiTransactionDataLoading] = useState<boolean>(false);
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const [refreshTableIncrementorPayout, setRefreshTableIncrementorPayout] = useState<number>(0);

  const tableConfigPayout: ITableProps<IPayout> = {
    columns: [
      {
        id: 'created_date',
        Header: 'Date',
        accessor: ({ created_date }) => created_date.toString(),
        sortable: true,
        notranslate: 'yes',
        Cell: ({ created_date }) => (
          <span notranslate="yes">
            {localizeHelpers.formatDate(
              created_date ?? new Date(),
              LocaleDateFormats.lll,
              locale.currentLocale,
            )}
          </span>
        ),
      },
      {
        id: 'amount',
        Header: 'Amount',
        Cell: ({ amount, currency }) => (
          <span notranslate="yes">
            {formatCurrency(amount || 0, currency, locale.currentLocale)}
          </span>
        ),
        accessor: ({ amount }) => amount,
        sortable: true,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: ({ status }) => status,
        sortable: true,
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      enableRowButtonActions: true,
      tableActions: [
        {
          type: 'ROW_BUTTON',
          label: 'Show Transactions',
          onClick: async (_, item) => {
            await openPayout(item.payout_id, item.external_account);
          },
          buttonType: 'dark',
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        let data: IPayout[] = [];
        try {
          let tmpObjectId;
          if (props?.groupState) {
            tmpObjectId = props.groupState.group.id;
          } else if (props?.hub?.id) {
            tmpObjectId = props.hub.id;
          }
          typeHelpers.assertNotNullOrUndefined(tmpObjectId, 'Expected Object ID');
          typeHelpers.assertNotNullOrUndefined(selectedAccountId, 'Expected Selected Account ID');
          data = await accountRequestActions.getPayouts(
            props.owner.ownerType,
            tmpObjectId,
            selectedAccountId,
            queryParams,
          );
        } catch (error) {
          dispatchToastError(error, 'Fetch Payouta');
        }

        return data;
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any items",
    },
    filterOptions: {
      enableTableSearch: true,
      enableColumnSorting: true,
    },
  };

  const tableConfig: ITableProps<IChargeTransaction> = {
    columns: [
      {
        id: 'created_date',
        Header: 'Date',
        accessor: ({ created_date }) => created_date.toString(),
        sortable: true,
        notranslate: 'yes',
        Cell: ({ created_date }) => (
          <span
            className="volunteer-name"
            notranslate="yes"
          >
            {localizeHelpers.formatDate(
              created_date ?? new Date(),
              LocaleDateFormats.lll,
              locale.currentLocale,
            )}
          </span>
        ),
      },
      {
        id: 'transaction_type',
        Header: 'Type',
        accessor: ({ transaction_type }) => transaction_type,
        sortable: true,
      },
      {
        id: 'total_amount',
        Header: 'Gross Amount',
        Cell: ({ total_amount, currency }) => (
          <span notranslate="yes">
            {formatCurrency(total_amount || 0, currency, locale.currentLocale)}
          </span>
        ),
        accessor: ({ total_amount }) => total_amount,
        sortable: true,
      },
      {
        id: 'user.email',
        Header: 'Purchaser Email',
        accessor: ({ user }) => user?.email,
        sortable: true,
      },
      {
        id: 'charge_id',
        Header: 'Stripe Payment ID',
        accessor: ({ charge_id }) => charge_id,
        sortable: true,
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      enableRowButtonActions: true,
      rowDetailDrawerOptions: (item) => ({
        title: 'View Details',
        renderContent: () => (
          <React.Fragment>
            <div className="third">
              <div className="title">Stripe Fees</div>
              <div
                notranslate="yes"
                className="sub-title"
              >
                {getFeeType(item.fee_details, Constants.charge_fee_type.stripe_fee)}
              </div>
              <div className="title">Platform Fees</div>
              <div
                notranslate="yes"
                className="sub-title"
              >
                {getFeeType(item.fee_details, Constants.charge_fee_type.application_fee)}
              </div>
              <div className="title">Purchaser Name</div>
              <div
                notranslate="yes"
                className="sub-title"
              >
                {item.user?.display_name}
              </div>
            </div>

            <div className="third">
              <div className="title">Cause Name</div>
              <div
                notranslate="yes"
                className="sub-title"
              >
                {item.group_title}
              </div>
              <div className="title">Cause Handle</div>
              <div className="sub-title">
                <Link
                  className="user-handle"
                  to={'/user/' + item.group_handle}
                  notranslate="yes"
                >
                  @{item.group_handle}
                </Link>
              </div>
              <div className="title">Event Name</div>
              <div
                notranslate="yes"
                className="sub-title"
              >
                {item.event_title}
              </div>
              <div className="title">Event Handle</div>
              <div className="sub-title">
                <Link
                  className="user-handle"
                  to={'/user/' + item.event_handle}
                  notranslate="yes"
                >
                  @{item.event_handle}
                </Link>
              </div>
            </div>

            <div className="third">
              <div className="title">Campaign Name</div>
              <div
                notranslate="yes"
                className="sub-title"
              >
                {item.campaign_title}
              </div>
              <div className="title">Fund Name</div>
              <div className="title">Individual</div>
            </div>
          </React.Fragment>
        ),
      }),
      tableActions: [],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        let data: IChargeTransaction[] = [];
        try {
          typeHelpers.assertNotNullOrUndefined(accountId, 'Expected Account ID');
          typeHelpers.assertNotNullOrUndefined(currentPayoutId, 'Expected Payout ID');
          data = await accountRequestActions.getPayoutTransactions(
            props.owner.ownerType,
            props.owner.ownerId,
            accountId,
            currentPayoutId,
            queryParams,
          );
        } catch (error) {
          dispatchToastError(error, 'Fetch Payout Transactions');
        }

        return data;
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any items",
    },
    filterOptions: {
      enableTableSearch: true,
      enableColumnSorting: true,
    },
  };

  useEffect(() => {
    dispatch(resetCurrentHub());

    fetchAccounts();
  }, []);

  useEffect(() => {
    if (selectedAccountId) {
      fetchDefaultExternalAccount();
    }
  }, [selectedAccountId]);

  async function connectStripeAccount() {
    try {
      // Get the stripe connect url and navigate the user to that url.
      const { value: url } = await accountRequestActions.getAccountAuthorizationUrl(
        props.owner.ownerType,
        props.owner.ownerId,
      );

      if (props?.hub) {
        dispatch(setHub(props.hub));
      }

      window.location.replace(url);
    } catch (err) {
      dispatchToastError(err, 'Get Account Authorization Url');
    }
  }

  async function fetchAccounts() {
    try {
      setIsLoadingAccounts(true);

      const fetchedAccounts = await accountRequestActions.getAccountsWithDetails(
        props.owner.ownerType,
        props.owner.ownerId,
      );
      setAccounts(fetchedAccounts);

      // Default to first account
      if (fetchedAccounts?.length > 0 && !selectedAccountId) {
        setSelectedAccountId(
          fetchedAccounts?.find(
            (acc) =>
              acc?.account_number && acc.account_number === props.owner.account?.account_number,
          )?.id || fetchedAccounts[0].id,
        );
      }
    } catch (err) {
      dispatchToastError(err, 'Fetch Accounts');
    } finally {
      setIsLoadingAccounts(false);
    }
  }

  async function fetchDefaultExternalAccount() {
    try {
      const account = accounts.find((account) => account.id === selectedAccountId);
      typeHelpers.assertNotNullOrUndefined(account);

      if (account.account_sub_type !== Constants.account_sub_type.custom) {
        return;
      }

      setIsExternalAccountLoading(true);

      const fetchedExternalAccount = await accountRequestActions.getExternalAccountDefault(
        props.owner.ownerType,
        props.owner.ownerId,
        account.id,
      );
      setDefaultExternalAccount(fetchedExternalAccount);
    } catch (err) {
      dispatchToastError(err, 'Fetch External Account');
    } finally {
      setIsExternalAccountLoading(false);
    }
  }

  async function getAccountLink(linkType: AccountLinkType) {
    try {
      typeHelpers.assertNotNullOrUndefined(selectedAccountId);

      // Get an account link to the hosted Stripe onboarding
      const link = await accountRequestActions.getAccountLink(
        props.owner.ownerType,
        props.owner.ownerId,
        selectedAccountId,
        {
          link_type: linkType,
          return_url: window.location.href,
          refresh_url: window.location.href,
        },
      );

      window.location.href = link.url;
    } catch (err) {
      dispatchToastError(err, 'Get Account Link');
    }
  }

  async function setAccountAsCurrent(accountId: string) {
    typeHelpers.assertNotNullOrUndefined(accountId);
    let setAsCurrentSuccessful = true;

    try {
      await accountRequestActions.setCurrentAccount(
        props.owner.ownerType,
        props.owner.ownerId,
        accountId,
      );
      setSelectedAccountId(accountId);
      setShowChangeStripeAccount(false);
    } catch (err) {
      setAsCurrentSuccessful = false;
      dispatchToastError(err, 'Set Account Current');
    } finally {
      if (setAsCurrentSuccessful) {
        // Refresh the owner object which tracks current account.
        if (props.owner.ownerType === Constants.object_type.group) {
          await dispatch(getGroup(props.owner.ownerId));

          if (
            accounts?.find((acc) => acc.id === accountId)?.account_sub_type === 'custom' &&
            props.groupState?.group.id !== undefined
          ) {
            dispatch(updateGroup({ auto_tax_receipts: false }, props.groupState.group.id));
          }
        }
      }
    }
  }

  const accountOptions: IOptions[] = useMemo(() => {
    return accounts.map((account) => ({
      label: account.account_number,
      value: account.id,
    }));
  }, [accounts]);

  function renderTabs() {
    return (
      <Fragment>
        <div className="tab-selector">{tabs.map((tab) => renderTabOption(tab))}</div>

        <div className={combineClassNames('tab-content', selectedTab)}>
          {isLoadingAccounts && <Loader loading={isLoadingAccounts} />}

          {!isLoadingAccounts && (
            <Fragment>
              {selectedAccountId == null && (
                <div className="details-card">
                  <div className="card-detail-area">
                    <div className="stripe-details">
                      <p>Add a Stripe Account</p>
                      <p>
                        To accept funds with your Cause page, you'll need to either{' '}
                        <b> Add an Exisiting Stripe Account </b> or{' '}
                        <b> Setup a New Stripe Account </b> below.
                      </p>

                      <Button
                        type="button"
                        buttonType="primary"
                        onClick={connectStripeAccount}
                        text="Add"
                      />
                    </div>
                  </div>
                </div>
              )}

              {selectedAccountId !== null && selectedTab === 'overview' && renderOverview()}

              {selectedAccountId !== null &&
                selectedTab === 'payout-history' &&
                renderPayoutHistory()}
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }

  function renderTabOption(tab: ITab) {
    const isSelected = selectedTab === tab.value;

    return (
      <div
        className={combineClassNames('tab', isSelected ? 'selected' : '')}
        key={tab.value}
        onClick={() => setSelectedTab(tab.value)}
      >
        {tab.label}
      </div>
    );
  }

  function renderOverviewField(label: string, content: ReactNode) {
    return (
      <div className="field">
        <div className="label">{label}</div>
        <div
          className="value"
          notranslate="yes"
        >
          {content ? content : '-'}
        </div>
      </div>
    );
  }

  const renderAccountBanner = (display_name: string) => {
    return (
      <div className="stripe-banner">
        <img src={stripeLogo} />
        <span>{display_name}</span>
        <span
          className="change"
          onClick={() => setShowChangeStripeAccount(true)}
        >
          (change)
        </span>
      </div>
    );
  };

  const openPayout = async (payoutId: string, crumbName: string) => {
    setPayoutCrumb(crumbName);
    setCurrentPayoutId(payoutId);
    setIsKpiTransactionDataLoading(true);
    setHasTransactionView(true);

    if (accountId) {
      try {
        const kpiTransactionData = await accountRequestActions.getPayoutTransactionKpi(
          props.owner.ownerType,
          props.owner.ownerId,
          accountId,
          payoutId,
        );

        setKpiTransactionData(kpiTransactionData);
      } catch (err) {
        dispatchToastError(err, 'Fetch Kpi Data For Payout Transactions');
      } finally {
        setIsKpiTransactionDataLoading(false);
      }
    }
  };

  function getFeeType(data: IChargeFee[], type: string) {
    let fee = data.find((e: IChargeFee) => e.type === type);
    if (fee) {
      return formatCurrency(fee.amount || 0, fee.currency);
    }
  }

  function getKPI() {
    let data: IKpi[] = [];
    const tmpCurrency = props?.groupState
      ? props.groupState.group.localization?.currency || defaultCurrency
      : props.hub
        ? props.hub.localization?.currency || defaultCurrency
        : defaultCurrency;

    if (!hasTransactionView) {
      const account = accounts.find((account) => account.id === selectedAccountId);
      const currency = account?.currency ?? defaultCurrency;
      const balanceStats = account?.tracking_amounts;

      data.push(
        {
          value: `${formatCurrency(balanceStats?.amount_raised, currency, locale.currentLocale)}`,
          text: 'Total Raised',
        },
        {
          value: `${formatCurrency(balanceStats?.total_transaction_amount, currency, locale.currentLocale)}`,
          text: 'Total Transaction Amount',
        },
        {
          value: `${formatCurrency(balanceStats?.application_fees_payed, currency, locale.currentLocale)}`,
          text: 'Total Kambeo Fees Paid',
        },
        {
          value: `${formatCurrency(balanceStats?.payment_processor_fees_payed, currency, locale.currentLocale)}`,
          text: 'Total Payment Processor Fees Paid',
        },
      );
    } else {
      if (kpiTransactionData) {
        data.push(
          {
            value: localizeHelpers.formatNumber(
              kpiTransactionData.transactions_count || 0,
              locale.currentLocale,
            ),
            text: 'No. of Transactions',
          },
          {
            value: `${formatCurrency(kpiTransactionData.total_amount || 0, tmpCurrency, locale.currentLocale)}`,
            text: 'Total Gross Amount',
          },
          {
            value: `${formatCurrency(kpiTransactionData.total_fee_amount || 0, tmpCurrency, locale.currentLocale)}`,
            text: 'Total Fee Amount',
          },
          {
            value: `${formatCurrency(kpiTransactionData.total_event_amount || 0, tmpCurrency, locale.currentLocale)}`,
            text: 'Total Event Amount',
          },
          {
            value: `${formatCurrency(kpiTransactionData.total_donations_amount || 0, tmpCurrency, locale.currentLocale)}`,
            text: 'Total Donations Amount',
          },
          {
            value: `${formatCurrency(kpiTransactionData.total_merchandise_amount || 0, tmpCurrency, locale.currentLocale)}`,
            text: 'Total Merchandise Amount',
          },
        );
      }
    }

    if (data.length) {
      return (
        <div className="section-kpi">
          <KpiDisplay kpiDataList={data} />
        </div>
      );
    } else {
      return null;
    }
  }

  const backToPayouts = () => {
    if (!hasTransactionView) {
      return;
    }
    setKpiTransactionData(null);
    setHasTransactionView(false);
  };

  function renderOverview() {
    const account = accounts.find((account) => account.id === selectedAccountId);

    typeHelpers.assertNotNullOrUndefined(account);

    const payoutSummary = account.payout_summary;
    const onboardingBannerInfo = onboardingStatusBanners[account.onboarding_status];

    const getOwnerName = (objectType: string) => {
      switch (objectType) {
        case uiConstants.ownerType.company:
        case uiConstants.ownerType.hub:
          return 'Company';
        case uiConstants.ownerType.group:
          return 'Cause';
        case uiConstants.ownerType.event:
          return 'Event';
        case uiConstants.ownerType.gig:
          return 'Volunteer opportunity';
      }
    };

    const isEditingAccount = !account || !account?.country || !account?.currency;

    return (
      <div className="details-card">
        <div className="card-info">My accounts</div>
        <div className="card-info--inner">
          <h3>Stripe Account</h3>
          <p>
            To get your <span className="capitalize">{getOwnerName(props.owner.ownerType)}</span>{' '}
            page up and running, you'll first need to provide Stripe account details so you can
            receive payments.
          </p>
        </div>
        {account?.account_sub_type === Constants.account_sub_type.custom &&
          onboardingBannerInfo.bannerType === 'failure' && (
            <Banner
              className="account-status-banner banner"
              title="Account Status"
              content={onboardingBannerInfo.text}
              type={onboardingBannerInfo.bannerType}
              actionButton={{
                buttonText: onboardingBannerInfo.buttonText,
                onClick: () => getAccountLink(onboardingBannerInfo.linkType),
              }}
            />
          )}
        {account?.account_sub_type === Constants.account_sub_type.custom &&
          account.external_account_status !== 'requirements_met' &&
          onboardingBannerInfo.bannerType !== 'failure' && (
            <Banner
              className="external-account-banner banner"
              title="External Account"
              content="Bank Account Information Required - You must configure bank account information for Payouts to be made. Click on button to update your bank account information"
              type="failure"
              actionButton={{
                buttonText: 'Click Here',
                onClick: () => getAccountLink(onboardingBannerInfo.linkType),
              }}
            />
          )}
        {renderAccountBanner(account?.display_name || 'Unnamed Stripe Account')}
        <div className="cards">
          <div className="card-detail-area">
            <div className="details">
              <p>Stripe Account details</p>
              {isEditingAccount && (
                <p className="warning">
                  <i className="fas fa-exclamation-circle" /> Stripe Account Details are required to
                  accept payments.
                </p>
              )}
              <div className="column">
                {renderOverviewField(
                  'Account',
                  <div className="account-number">{account.account_number}</div>,
                )}
                {renderOverviewField('Account Type', capitalizeString(account.account_sub_type))}
              </div>

              <div className="column">{renderOverviewField('Country', account.country)}</div>

              <div className="column">
                {renderOverviewField('Currency', account.currency.toUpperCase())}
              </div>
            </div>
            <div className="stripe-actions">
              {account?.account_sub_type === Constants.account_sub_type.custom && (
                <Button
                  className="add-edit-btn"
                  icon={isEditingAccount ? 'fal fa-plus' : 'fal fa-pencil-alt'}
                  text={isEditingAccount ? 'Add Stripe Details' : 'Edit'}
                  onClick={async () => {
                    await getAccountLink(onboardingBannerInfo.linkType);
                  }}
                  buttonType="secondary"
                />
              )}
              {props.owner.ownerType !== Constants.object_type.hub && (
                <Button
                  className="add-stripe-btn"
                  text="Connect Stripe Account"
                  icon="fab fa-stripe-s"
                  buttonType="primary"
                  onClick={connectStripeAccount}
                />
              )}
            </div>
          </div>

          {account?.account_sub_type === Constants.account_sub_type.custom && (
            <div className="card-detail-area">
              <div className="details">
                <p>Bank Account Details</p>
                {!defaultExternalAccount && (
                  <p className="warning">
                    <i className="fas fa-exclamation-circle" /> Banking Information is required to
                    receive payouts.
                  </p>
                )}
                <div className="column">
                  {isExternalAccountLoading ? (
                    <Loader loading={isExternalAccountLoading} />
                  ) : (
                    <Fragment>
                      {renderOverviewField(
                        'Connected Bank Account',
                        defaultExternalAccount?.last4
                          ? localizeHelpers.translate('Account ending in {{last4}}', {
                              last4: defaultExternalAccount?.last4,
                            })
                          : '',
                      )}

                      {renderOverviewField(
                        'Last Payout Amount',
                        payoutSummary?.last_payout_amount
                          ? formatCurrency(
                              payoutSummary?.last_payout_amount,
                              account.currency,
                              locale.currentLocale,
                            )
                          : '-',
                      )}
                    </Fragment>
                  )}
                </div>

                <div className="column">
                  {renderOverviewField(
                    'Previous Payout Date',
                    payoutSummary?.last_payout_date
                      ? localizeHelpers.formatDate(
                          payoutSummary?.last_payout_date,
                          LocaleDateFormats.LL,
                          locale.currentLocale,
                        )
                      : '-',
                  )}
                  {renderOverviewField(
                    'Next Payout Date (Estimated)',
                    payoutSummary?.next_estimated_payout_date
                      ? localizeHelpers.formatDate(
                          payoutSummary?.next_estimated_payout_date,
                          LocaleDateFormats.LL,
                          locale.currentLocale,
                        )
                      : '-',
                  )}
                </div>
              </div>
              {account?.account_sub_type === Constants.account_sub_type.custom && (
                <Button
                  className="add-edit-btn"
                  icon={!defaultExternalAccount ? 'fal fa-plus' : 'fal fa-pencil-alt'}
                  text={!defaultExternalAccount ? 'Add Banking Information' : 'Edit'}
                  onClick={() => setShowSetupExternalAccount(true)}
                  buttonType="secondary"
                />
              )}
            </div>
          )}
        </div>

        <StripeProvider>
          {showSetupExternalAccount && (
            <SetupExternalAccountModal
              owner={props.owner}
              show={showSetupExternalAccount}
              account={account}
              onClose={() => setShowSetupExternalAccount(false)}
              onSave={() => fetchAccounts()}
            />
          )}
        </StripeProvider>
      </div>
    );
  }

  function renderPayoutCrumb() {
    if (hasTransactionView) {
      return (
        <div className="section-inner-crumb">
          <span
            className="section-crumb-root active-link"
            onClick={backToPayouts}
          >
            Payouts
          </span>
          <span className="section-crumb-next">
            <i className="fa fa-chevron-right" />
            {payoutCrumb}
          </span>
        </div>
      );
    }
  }

  function renderPayoutHistory() {
    return (
      <div className="list">
        {renderPayoutCrumb()}
        <div className="list-rows">
          {!isExternalAccountLoading && !isLoadingAccounts && !hasTransactionView && (
            <Table
              refreshTableIncrementor={refreshTableIncrementorPayout}
              {...tableConfigPayout}
            />
          )}
          {!isKpiTransactionDataLoading &&
            !isExternalAccountLoading &&
            !isLoadingAccounts &&
            hasTransactionView && (
              <Table
                refreshTableIncrementor={refreshTableIncrementor}
                {...tableConfig}
              />
            )}
        </div>
      </div>
    );
  }

  return (
    <div className="BalanceAndPayoutsManage section-wrap">
      <div className="section-title">
        <div className="forms-title">Balances & Payouts</div>
      </div>
      {getKPI()}
      <div className="balance-section-inner">
        {renderTabs()}

        {selectedTab === 'overview' && !isLoadingAccounts && (
          <div className="confused-section">
            <div className="confused-section--text">
              <img
                src={ConfusedEmoji}
                alt="confused emoji"
                width="24px"
                height="24px"
              />
              <p>
                <b>Confused?</b> Don't worry, we've got your back. Check out our Stripe Help Centre.
              </p>
            </div>
            <Button
              text="Learn More"
              buttonType="outline-dark"
              onClick={() => {
                window.open('https://help.kambeo.io/stripe-help-centre', '_blank');
              }}
            />
          </div>
        )}
      </div>

      <Modal
        title="Change Stripe Acccount"
        class="Modal-ChangeStripeAccount"
        show={showChangeStripeAccount}
        onClose={() => setShowChangeStripeAccount(false)}
        closeIcon="fas fa-times"
      >
        <ChangeStripeAccount
          objectType={props.owner.ownerType}
          addNewAccount={connectStripeAccount}
          accounts={accounts}
          selectedAccountId={selectedAccountId}
          onClose={() => setShowChangeStripeAccount(false)}
          onSave={(accountId) => setAccountAsCurrent(accountId)}
        />
      </Modal>
    </div>
  );
};
