import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  errorHelpers,
  handleInputChange,
  setSEOMetatags,
  toastError,
  toastSuccess,
} from '../../helpers';
import { IEventState } from '../../reducers/event';
import { createToast } from '../../actions/toaster';
import { getEvent } from '../../actions/event';
import { IAppState } from '../../store';
import QuillTextEditor from '../../components/QuillTextEditor/QuillTextEditor';
import Portrait from '../../components/Portrait/Portrait';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import './TeamManage.scss';
import { ReduxActionType } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import CoverImage from '../../components/CoverImage/CoverImage';
import { uiConstants } from '../../constants/uiConstants';
import ManagementSidebar, {
  IMenuSection,
} from '../../components/ManagementSidebar/ManagementSidebar';
import { IEventTeam } from '@gigit/interfaces';
import { teamRequestActions } from '../../requestActions/team';

interface IProps extends RouteComponentProps<any> {
  eventState: IEventState;
  createToast: ReduxActionType<typeof createToast>;
  getEvent: ReduxActionType<typeof getEvent>;
}

interface IState {
  activeTab: string;
  goal: string;
  story: string;
  showWidgets: boolean[];
  team: IEventTeam | null;
}

class TeamManage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: 'overview',
      goal: '0',
      story: '',
      showWidgets: [],
      team: null,
    };

    this.isActiveTab = this.isActiveTab.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.saveStory = this.saveStory.bind(this);
    this.handleStoryChange = this.handleStoryChange.bind(this);
  }

  componentDidMount() {
    this.props.getEvent(this.props.match.params.eventHandle);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.eventState.event !== prevProps.eventState.event &&
      this.props.eventState.event.id !== ''
    ) {
      this.getTeam(this.props.eventState.event.id, this.props.match.params.teamHandle);
    }

    if (this.state.team !== prevState.team) {
      this.setState({
        goal: this.state.team?.goal?.toString() || '',
        story: this.state.team?.story || '',
      });
      if (this.state.team) {
        const { id, profile_image_url, cover_image_url, name } = this.state.team;
        const { handle: eventHandle } = this.props.eventState.event;

        setSEOMetatags({
          urlPath: `event/${eventHandle}/team/${id}`,
          title: name,
          description: undefined,
          imageURL: profile_image_url,
          coverImageURL: cover_image_url,
        });
      }
    }
  }

  async updateTeamCoverImage(fileAddress: string) {
    try {
      const newTeamCoverImage = await teamRequestActions.updateTeamCoverImage(fileAddress);
      if (this.state.team) {
        let updatedTeam = { ...this.state.team, ...{ cover_image_url: newTeamCoverImage } };
        if (updatedTeam.id) {
          this.updateTeam(updatedTeam.event_id, updatedTeam.id, updatedTeam);
        }
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Team Cover Image');
      createToast(toast);
    }
  }

  async updateTeamProfileImage(fileAddress: string) {
    try {
      const newTeamProfileImage = await teamRequestActions.updateTeamProfileImage(fileAddress);
      if (this.state.team) {
        let updatedTeam = { ...this.state.team, ...{ profile_image_url: newTeamProfileImage } };
        if (updatedTeam.id) {
          this.updateTeam(updatedTeam.event_id, updatedTeam.id, updatedTeam);
        }
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Team Profile Image');
      createToast(toast);
    }
  }

  async updateTeam(eventId: string, teamId: string, payload: Partial<IEventTeam>) {
    try {
      await teamRequestActions.updateTeam(eventId, teamId, payload);
      this.getTeam(eventId, teamId);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Team');
      createToast(toast);
    }
  }

  async getTeam(eventId: string, teamId: string) {
    try {
      const team = await teamRequestActions.getTeam(eventId, teamId);
      this.setState({ team });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Team');
      createToast(toast);
    }
  }

  async toggleEventTeamFitness(eventId: string, team: IEventTeam) {
    try {
      await teamRequestActions.toggleEventTeamFitness(eventId, team);
      this.getTeam(eventId, team.id!);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Toggle Team Fitness');
      createToast(toast);
    }
  }

  isActiveTab(tab: string) {
    return tab === this.state.activeTab ? `tab active ${tab}` : `tab ${tab}`;
  }

  setActiveTab(tab: string) {
    this.setState({
      activeTab: tab,
    });
  }

  toggleVisibility(index: number) {
    let _settings: boolean[] = [...this.state.showWidgets];

    _settings[index] = !_settings[index];

    this.setState({
      showWidgets: _settings,
    });
  }

  saveStory() {
    this.updateTeam(this.state.team?.event_id!, this.state.team?.id!, { story: this.state.story });

    const toast = toastSuccess(
      localizeHelpers.translate('Story successfully saved.'),
      'Team Management',
    );
    this.props.createToast(toast);
  }

  handleStoryChange(content: string) {
    this.setState({ story: content });
  }

  onImageChange = (fileData: any) => {
    switch (fileData.type) {
      case 'cover_image_url':
        this.updateTeamCoverImage(fileData.file);
        break;
      case 'profile_image_url':
        this.updateTeamProfileImage(fileData.file);
        break;
    }
  };

  getSidebarMenuOptions(): IMenuSection[] {
    return [
      {
        id: 'settings',
        sectionTitle: 'Settings',
        sectionIcon: 'fas fa-sliders-v',
        menuOptions: [{ id: 'overview', title: 'overview' }],
      },
    ];
  }

  render() {
    return (
      <div className="TeamManage">
        <ManagementSidebar
          logo={this.state.team?.profile_image_url || ''}
          title="Team Management"
          targetLink={{
            url:
              '/event/' +
              this.props.match.params.eventHandle +
              '/team/' +
              this.props.match.params.teamHandle,
            title: this.state.team?.name!,
          }}
          menuSections={this.getSidebarMenuOptions()}
          activeOptionId={this.state.activeTab}
          onMenuOptionClicked={(menuOptionId: string) => this.setState({ activeTab: menuOptionId })}
        />
        <div className={'section ' + this.state.activeTab + '-wrap'}>
          {this.state.activeTab === 'overview' && (
            <div className="section-wrap">
              <div className="section-title">Settings</div>
              <div className="section-inner">
                <div className="settings-title">General</div>
                <div className="settings-item hover-item no-select">
                  <div className="details">
                    <p>Fundraising Goal</p>
                    <span className="detail">Set your individual fundraising goal.</span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="expansion"
                  >
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.updateTeam(this.state.team?.event_id!, this.state.team?.id!, {
                          goal: Number(this.state.goal),
                        });
                      }}
                      className="email-wrap"
                    >
                      <TextField
                        label="Goal ($)"
                        value={this.state.goal}
                        name="goal"
                        type="text"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <Button
                        isDisabled={this.state.goal === this.state.team?.goal?.toString()}
                        type="submit"
                        text="Update"
                      />
                    </form>
                  </div>
                </div>
                <div className="settings-item hover-item">
                  <div className="details">
                    <p>Story</p>
                    <span className="detail">Write a short description about yourself.</span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="expansion"
                  >
                    <QuillTextEditor
                      value={this.state.story}
                      readOnly={false}
                      preserveWhitespace={true}
                      theme="snow"
                      modules={uiConstants.quill.defaultToolbar}
                      formats={uiConstants.quill.defaultFormats}
                      onChange={this.handleStoryChange}
                    />
                    <div className="expansion-actions">
                      <Button
                        text="Save Story"
                        onClick={() => {
                          this.saveStory();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="settings-item hover-item no-select">
                  <div className="details">
                    <p>Profile Image</p>
                    <span className="detail">Set your team portrait image.</span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="expansion"
                  >
                    <Portrait
                      size={100}
                      currentImage={this.state.team?.profile_image_url}
                      overlay={true}
                      onChange={this.onImageChange}
                    />
                  </div>
                </div>
                <div className="settings-item hover-item no-select">
                  <div className="details">
                    <p>Cover Image</p>
                    <span className="detail">Set your team cover image.</span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="expansion"
                  >
                    <CoverImage
                      currentImage={this.state.team?.cover_image_url}
                      onChange={this.onImageChange}
                      overlay={true}
                    />
                  </div>
                </div>
                {this.state.team && (
                  <div className="settings-item">
                    <div className="details">
                      <p>Strava</p>
                      <span className="detail">
                        Are you planning on integrating Strava with this fundraiser?
                      </span>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          this.toggleEventTeamFitness(this.state.team?.event_id!, this.state.team!);
                        }}
                        className={
                          this.state.team.fitness_tracking_enabled
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {
  createToast,
  getEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamManage);
