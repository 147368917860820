import { IAddress, IEventSummaryFE, IGroup } from '@gigit/interfaces';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { defaultCurrency, formatCurrency } from '../../../../helpers';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { userSelectors } from '../../../../selectors/user';
import { IAppState } from '../../../../store';
import Portrait from '../../../Portrait/Portrait';
import ProgressBar from '../../../shared/ProgressBar/ProgressBar';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import { uiConstants } from '../../../../constants';

import './ListCard.scss';

interface ICardData {
  title?: string;
  charity_id?: string;
  cover_image_url?: string;
  profile_image_url?: string;
  description?: string;
}

interface IProps {
  //TODO: Eventually these shouldn't be based on hub types, but card types
  cardType?: string;
  cardData: ICardData;
  children?: ReactNode;
  cardSelected?: (id: string) => void;
}

const ListCard: React.FC<IProps> = (props) => {
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  function renderLocationLabel(locations?: IAddress[], isVirtual?: boolean) {
    return (
      <span className="location">
        {isVirtual && 'Online'}
        {!isVirtual && locations && locations?.length > 1 && 'Multiple Locations'}
        {!isVirtual && locations && locations?.length === 1 && locations[0].city}
      </span>
    );
  }

  function showCampaignProgress(event?: IEventSummaryFE) {
    return event?.show_campaign_progress && (event?.goal ?? 0) > 0;
  }

  function renderFundraiserProgressBar(event?: IEventSummaryFE) {
    return (
      <ProgressBar
        amountRaised={event?.raised || 0}
        goal={event?.goal || 0}
        formatCurrency={(number) =>
          formatCurrency(number, event?.group?.account?.currency || defaultCurrency, locale)
        }
      />
    );
  }

  function getCardContent() {
    if (props.cardType === 'partners') {
      const group = props.cardData as IGroup;
      return (
        <>
          <div
            onClick={() => {
              if (props.cardSelected) {
                props.cardSelected(group.id);
              }
            }}
            className="title charity"
            notranslate="yes"
          >
            {group.title}
          </div>
          <div className="charity-id">
            Charity ID: <span notranslate="yes">{group.charity_id || 'N/A'}</span>
          </div>
          {/* The code below is temporary commented out due to  https://app.clickup.com/t/8404472/GIG-5630 */}
          {/* TODO IMPLEMENT METRICS WHEN READY */}
          {/* <div className="row charity">
                        <div><span>$55k</span> <p>Donated</p></div>
                        <div><span>$11.2k</span> <p>Fundraised</p></div>
                    </div> */}
        </>
      );
    } else {
      const event = props.cardData as IEventSummaryFE;
      return (
        <>
          <div className="above-title-container">
            <div className="date">
              {localizeHelpers.formatDate(event?.start_date, LocaleDateFormats.LL, locale)}
            </div>
            <div className="location-container">
              <span className="separator" />
              <span className="label">
                {renderLocationLabel(event.locations as IAddress[], event.is_virtual)}
              </span>
            </div>
          </div>
          <div
            onClick={() => {
              if (props.cardSelected) {
                props.cardSelected(event.id);
              }
            }}
            className="title"
            notranslate="yes"
          >
            {event.title}
          </div>
          <div
            className="content"
            notranslate="yes"
          >
            {!showCampaignProgress(event) && (
              <QuillTextEditor
                value={event.description}
                preserveWhitespace={true}
                theme={uiConstants.quill.readOnlyTheme}
                readOnly={true}
                className="description"
              />
            )}
            {showCampaignProgress(event) && renderFundraiserProgressBar(event)}
          </div>
        </>
      );
    }
  }

  return (
    <div className="ListCard">
      <div className="cover-img">
        <div
          className="cover-image-holder"
          style={{ backgroundImage: `url(${props.cardData.cover_image_url})` }}
        />
        <Portrait
          size={50}
          source={props.cardData?.profile_image_url}
        />
      </div>
      <div className="card-content">{props?.children || getCardContent()}</div>
    </div>
  );
};

export default ListCard;
