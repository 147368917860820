import moment, { Moment } from 'moment';
import React from 'react';
import Datetime, {
  DatetimepickerProps,
  DatetimepickerState,
  TimeConstraints,
} from 'react-datetime';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';

import './DateTimePicker.scss';

interface IProps extends Omit<DatetimepickerProps, 'timeConstraints' | 'isValidDate'> {
  shouldValidate?: boolean;
  /**
   * Allows Optional date to be used in place of current day (for end date field limitations)
   */
  disableDatesBefore?: moment.Moment | string;
}

const timeConstraints: TimeConstraints = {
  minutes: { step: 15, min: 0, max: 60 },
};

// TODO: disableDatesAfter
// TODO: Standardize isValidDate(), since it's duplicated in multiple components.
// TODO: Standardize timeConstraints.
// TODO: Standardize class.

/** Wrapper DateTimePicker for react-datetime. */
export default class DateTimePicker extends React.Component<IProps, DatetimepickerState> {
  isValidDate(disableDatesBefore?: moment.Moment | string) {
    let yesterday = moment().subtract(1, 'day');
    let isValid = (current: Moment) => {
      return disableDatesBefore
        ? moment(current).isSameOrAfter(this.props?.disableDatesBefore)
        : current.isAfter(yesterday);
      // (current.minute() === 0 || current.minute() === 15 || current.minute() === 30 || current.minute() === 45 || current.minute() === 60) ;
    };

    return isValid;
  }

  onChange = (value: Moment | string) => {
    if (moment.isMoment(value)) {
      let date = moment(value);

      // Ensure the time step constraint is enforced (Can be broken when changing minutes down).
      if (date.minute() % 15 !== 0) {
        date = date.minute(Math.round(date.minute() / 15) * 15);
      }

      this.props.onChange?.(date);
    }
  };

  render() {
    const props: DatetimepickerProps = {
      ...this.props,
      timeConstraints: timeConstraints,
      onChange: this.onChange,
      dateFormat: localizeHelpers.getLongDateFormat(
        LocaleDateFormats.LL,
        this.props.locale ?? 'en',
      ),
      ...(this.props.shouldValidate !== false && {
        isValidDate: this.isValidDate(this.props?.disableDatesBefore),
      }),
    };

    if (props.inputProps?.placeholder) {
      props.inputProps = {
        ...props.inputProps,
        placeholder: localizeHelpers.translate(props.inputProps.placeholder),
      };
    }

    return (
      <div
        className="DateTimePickerContainer"
        notranslate="yes"
      >
        <Datetime
          ref={(e) => {}}
          {...props}
        />
      </div>
    );
  }
}
