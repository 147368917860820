import React, { FC, useState, useEffect } from 'react';
import './HubManagementAnalytics.scss';
import queryString from 'query-string';
import { IHub } from '@gigit/interfaces';
import { useHistory, useLocation } from 'react-router-dom';
import HubManagementAnalyticsVolunteering from './HubManagementAnalyticsVolunteering/HubManagementAnalyticsVolunteering';
import HubManagementAnalyticsDonations from './HubManagementAnalyticsDonations/HubManagementAnalyticsDonations';
import HubManagementAnalyticsFundraising from './HubManagementAnalyticsFundraising/HubManagementAnalyticsFundraising';
import HubManagementAnalyticsEngagement from './HubManagementAnalyticsEngagement/HubManagementAnalyticsEngagement';
import HubManagementAnalyticsSupportedCauses from './HubManagementAnalyticsSupportedCauses/HubManagementAnalyticsSupportedCauses';
import HubManagementAnalyticsSupportedFocusAreas from './HubManagementAnalyticsSupportedFocusAreas/HubManagementAnalyticsSupportedFocusAreas';

export enum EHUB_MANAGEMENT_ANALYTICS_TABS {
  DONATIONS = 'donations',
  VOLUNTEERING = 'volunteering',
  FUNDRAISING = 'fundraising',
  ENGAGEMENT = 'engagement',
  SUPPORTED_CAUSES = 'supported_causes',
  SUPPORTED_FOCUS_AREAS = 'supported_focus_areas',
}

interface ITab {
  id: EHUB_MANAGEMENT_ANALYTICS_TABS;
  label: string;
}

interface IProps {
  hub: IHub;
}

const HubManagementAnalytics: FC<IProps> = (props) => {
  const { hub } = props;
  const location = useLocation();
  const history = useHistory();
  const [tab, setTab] = useState<EHUB_MANAGEMENT_ANALYTICS_TABS | null>(null);
  const tabs = [
    {
      id: EHUB_MANAGEMENT_ANALYTICS_TABS.DONATIONS,
      label: 'Donations',
    },
    {
      id: EHUB_MANAGEMENT_ANALYTICS_TABS.VOLUNTEERING,
      label: 'Volunteering',
    },
    {
      id: EHUB_MANAGEMENT_ANALYTICS_TABS.FUNDRAISING,
      label: 'Fundraising',
    },
    {
      id: EHUB_MANAGEMENT_ANALYTICS_TABS.ENGAGEMENT,
      label: 'Engagement',
    },
    {
      id: EHUB_MANAGEMENT_ANALYTICS_TABS.SUPPORTED_CAUSES,
      label: 'Supported Causes',
    },
    {
      id: EHUB_MANAGEMENT_ANALYTICS_TABS.SUPPORTED_FOCUS_AREAS,
      label: 'Supported Focus Areas',
    },
  ] as ITab[];

  useEffect(() => {
    handleLocationParams();
  }, [location.search]);

  function isTabActive(id: EHUB_MANAGEMENT_ANALYTICS_TABS) {
    return tab === id;
  }

  function handleLocationParams() {
    const params = queryString.parse(location.search) as { tab?: EHUB_MANAGEMENT_ANALYTICS_TABS };
    setTab(params?.tab || EHUB_MANAGEMENT_ANALYTICS_TABS.DONATIONS);
  }

  function renderTab() {
    switch (tab) {
      case EHUB_MANAGEMENT_ANALYTICS_TABS.SUPPORTED_FOCUS_AREAS:
        return <HubManagementAnalyticsSupportedFocusAreas hub={hub} />;
      case EHUB_MANAGEMENT_ANALYTICS_TABS.SUPPORTED_CAUSES:
        return <HubManagementAnalyticsSupportedCauses hub={hub} />;
      case EHUB_MANAGEMENT_ANALYTICS_TABS.ENGAGEMENT:
        return <HubManagementAnalyticsEngagement hub={hub} />;
      case EHUB_MANAGEMENT_ANALYTICS_TABS.FUNDRAISING:
        return <HubManagementAnalyticsFundraising hub={hub} />;
      case EHUB_MANAGEMENT_ANALYTICS_TABS.VOLUNTEERING:
        return <HubManagementAnalyticsVolunteering hub={hub} />;
      case EHUB_MANAGEMENT_ANALYTICS_TABS.DONATIONS:
      default:
        return <HubManagementAnalyticsDonations hub={hub} />;
    }
  }

  return (
    <div className="HubManagementAnalytics">
      <h2>Analytics Summary</h2>
      <div className="analytics-tabs">
        <ul>
          {tabs.map((t, index) => (
            <li
              key={index}
              className={`${isTabActive(t.id) ? 'active' : ''}`}
              onClick={() =>
                history.replace({ pathname: location.pathname, search: `t=summary&tab=${t.id}` })
              }
            >
              {t.label}
            </li>
          ))}
        </ul>
      </div>
      <section className="analytics-main">{tab !== null && renderTab()}</section>
    </div>
  );
};

export default HubManagementAnalytics;
