import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Config } from '@gigit/config';
import queryString from 'query-string';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IAppState } from './store';
import { IUserState } from './reducers/user';
import { ISettingsState } from './reducers/settings';
import {
  refreshToken,
  logout,
  updateLocalizeLanguageFromUserState,
  updateIsUserSessionConnectedToDataDog,
  loginWithSSO,
} from './actions/user';
import { updateSettings, resetState } from './actions/settings';
import './App.css';
import 'react-datetime/css/react-datetime.css';
import Router from './router';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Toaster from './components/Toaster/Toaster';
import { reconnectChat } from './actions/chat';
import Chat from './components/Chat/Chat';
import { uiConstants } from './constants/uiConstants';
import Button from './components/Button/Button';
import { userSelectors } from './selectors/user';
import { ReduxActionType } from './interfaces';
import { datadogRum } from '@datadog/browser-rum';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { MessageContextProvider } from './contexts/MessageContext';

interface IProps extends RouteComponentProps<any> {
  user: IUserState;
  isUserLoggedIn: boolean;
  isUserSessionConnectedToDataDog: boolean;
  settings: ISettingsState;
  refreshToken(handler: (err?: any, token?: string) => void): void;
  reconnectChat(): void;
  updateSettings(settings: any): void;
  logout(): void;
  resetState(): void;
  updateIsUserSessionConnectedToDataDog: ReduxActionType<
    typeof updateIsUserSessionConnectedToDataDog
  >;
  loginWithSSO: ReduxActionType<typeof loginWithSSO>;
}

interface IState {
  headerNav: any;
  footerNav: any;
  campaign: string;
  isEmbed: boolean;
}

class Gigit extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let params = queryString.parse(this.props.location.search);

    this.state = {
      headerNav: {
        /*"/": [{
                    label: "Organizations",
                    hideOnLogin: true,
                    url: "/post-on-gigit",
                }, {
                    label: "Individuals",
                    hideOnLogin: true,
                    url: "/create-profile"
                }]*/
      },
      footerNav: {
        '/': [
          {
            label: 'Service Agreement',
            link: uiConstants.urls.serviceAgreement,
          },
          {
            label: 'Terms',
            link: uiConstants.urls.eula,
          },
          {
            label: 'Privacy',
            link: uiConstants.urls.privacyPolicy,
          },
        ],
      },
      campaign: '',
      isEmbed: !!params.embed,
    };

    // Holds a queue of failed requests to be processed once access token is refreshed.
    let failedQueue: Array<{ resolve: (value?: any) => void; reject: (err: any) => void }> = [];

    // Processes any outstanding requests in the failed queue.
    const processQueue = (error: any) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve();
        }
      });
      failedQueue = [];
    };

    // GIG-740: Intercept axios response errors and retry 401 errors after requested a new access token.
    // Using axios interceptor we can avoid issue where multiple requests are sent simultaneously, each requesting a new access token and thus canceling each other.
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (props.user.isLoggedIn) {
          const originalRequest = error.config;
          // These handle the 401 error types, right now two are coming from the B.E., so I'm running a test to see if
          // this hacky solution will solve the issue in the meantime.
          const isTokenExpired =
            error.response?.data.gigitErrorCode === 'ERROR.AUTH.ACCESS_TOKEN_EXPIRED';
          const isAuthExpired =
            error.response?.data.gigitErrorCode === 'ERROR.AUTH.UNAUTHORIZED' &&
            error.response.data.message === 'Access token missing';
          // Non 401 errors error out like normal.
          if (
            (error.response && error.response.status !== 401) ||
            // Only handle token expire errors.
            (error.response && !isTokenExpired && !isAuthExpired) ||
            // We've already retried.
            originalRequest._retry ||
            // Don't retry token refresh requests.
            (error.config.url && error.config.url.includes('/auth/refresh'))
          ) {
            return Promise.reject(error);
          }

          if (this.props.user.awaitingToken) {
            // We're already refreshing access token, so just add to the failed queue to await refresh.
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then(() => {
                return axios(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;

          // This is where we refresh the token.
          return new Promise((resolve, reject) => {
            this.props.refreshToken((err?: any) => {
              if (!err) {
                resolve(axios(originalRequest));

                processQueue(null);
              } else {
                processQueue(err);
                reject(err);

                this.props.logout();
              }
            });
          });
        } else {
          return Promise.reject(error);
        }
      },
    );
    // updateLocalizeLanguageFromUserState(this.props.user);
  }

  refreshPage() {
    this.props.updateSettings({ showReleaseModal: false });
    window.location.reload();
  }

  handleSetDatadogUser = (isUserLoggedIn: boolean, user: IUserState) => {
    if (isUserLoggedIn) {
      const { id, email, display_name } = user.user;
      datadogRum.setUser({
        id,
        name: display_name,
        email,
      });
      this.props.updateIsUserSessionConnectedToDataDog(true);
    } else {
      this.props.updateIsUserSessionConnectedToDataDog(false);
      datadogRum.removeUser();
    }
  };

  handleGetUser = () => {
    if (this.props.isUserLoggedIn) {
      return this.props.user.user;
    }
    return null;
  };

  componentDidMount() {
    this.props.reconnectChat();

    if (Config.web.REACT_APP_STATE_VERSION !== this.props.settings.version) {
      this.props.updateSettings({ showReleaseModal: true });
      this.props.resetState();
      this.props.updateSettings({ version: Config.web.REACT_APP_STATE_VERSION });
    }

    if (this.props.isUserLoggedIn && this.props.isUserSessionConnectedToDataDog === false) {
      this.handleSetDatadogUser(this.props.isUserLoggedIn, this.props.user);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.isUserLoggedIn !== this.props.isUserLoggedIn) {
      this.handleSetDatadogUser(this.props.isUserLoggedIn, this.props.user);
    }
  }

  render() {
    return (
      <div className={this.state.isEmbed ? 'Gigit light embedded' : 'Gigit light'}>
        <MessageContextProvider>
          <Header
            navigation={
              this.state.headerNav[this.props.location.pathname] !== undefined
                ? this.state.headerNav[this.props.location.pathname]
                : this.state.headerNav['/']
            }
          />
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <Router />
          </QueryParamProvider>
          <Footer
            navigation={
              this.state.footerNav[this.props.location.pathname] !== undefined
                ? this.state.footerNav[this.props.location.pathname]
                : this.state.footerNav['/']
            }
          />
          <Toaster />
          {this.props.settings.showReleaseModal && (
            <div className="release-notification">
              New Update Announcement!
              <div className="release-subtext">
                A new version of Kambeo has been released. Check out the release notes for more
                details.
              </div>
              <a
                href="https://help.kambeo.io/release-4.0.0"
                target="_blank"
                className="release-link"
              >
                View Full Release Notes
              </a>
              <div className="release-actions">
                <Button
                  onClick={() => this.refreshPage()}
                  text="Dismiss"
                />
              </div>
            </div>
          )}
        </MessageContextProvider>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    user: store.userState,
    isUserLoggedIn: userSelectors.isUserAuthenticated(store),
    isUserSessionConnectedToDataDog: store.userState.isUserSessionConnectedToDataDog,
    settings: store.settingsState,
    chatState: store.chatState,
  };
};

const mapDispatchToProps = {
  refreshToken,
  reconnectChat,
  logout,
  updateSettings,
  resetState,
  updateIsUserSessionConnectedToDataDog,
  loginWithSSO,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Gigit));
