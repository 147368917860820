import { IForm } from '@gigit/interfaces';
import React from 'react';
import { ApplicationFormBuilder } from '../../ApplicationFormBuilder/ApplicationFormBuilder';
import { FormSectionHeader } from '../FormSectionHeader/FormSectionHeader';

import './CustomApplication.scss';

interface IProps {
  ownerType: string;
  ownerId?: string;
  form?: IForm | null;
  updateFormData(formData: Partial<IForm>): void;
}

const customApplicationInfoTitle = `Customize your application form`;
const customApplicationInfoText = `Additional to the mandatory information on application forms, you are able to add custom fields to your form if you wish.`;

export const CustomApplication: React.FC<IProps> = (props: IProps) => {
  return (
    <div className="CustomApplication">
      <FormSectionHeader
        text={customApplicationInfoTitle}
        subtext={customApplicationInfoText}
      />

      <ApplicationFormBuilder
        form={props?.form || ({} as IForm)}
        ownerType={props.ownerType}
        ownerId={props.ownerId}
        updateFormData={props.updateFormData}
      />
    </div>
  );
};
