import React, { useRef, useEffect, useState, ReactNode, FormEvent } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';

import { IAccountDetails } from '@gigit/interfaces';
import { Config } from '@gigit/config';

import { localeConstants } from '../../../constants';
import Modal from '../../Modal/Modal';
import TextField from '../../TextField/TextField';
import Dropdown from '../../Dropdown/Dropdown';
import Button from '../../Button/Button';

import useToastDispatcher from '../../../hooks/useToaster';
import { accountRequestActions, groupRequestActions } from '../../../requestActions';
import { IOwnerObject } from '../../../interfaces';

import { typeHelpers } from '../../../helpers';
import { localizeHelpers } from '../../../localizeHelpers';

import './SetupExternalAccountModal.scss';

interface IProps {
  owner: IOwnerObject;
  show: boolean;
  account: IAccountDetails;
  onClose: () => void;
  onSave: () => void;
}

type FieldConfig = Partial<
  Record<
    'accountNumber' | 'transitNumber' | 'routingNumber' | 'institutionNumber' | 'iBanNumber',
    boolean
  >
>;

/** Different countries support different fields: https://stripe.com/docs/connect/payouts-bank-accounts */
const countryBankFieldConfig: Record<string, FieldConfig> = {
  CA: {
    transitNumber: true,
    institutionNumber: true,
    accountNumber: true,
  },
  US: {
    routingNumber: true,
    accountNumber: true,
  },
  GB: {
    routingNumber: true,
    accountNumber: true,
  },
  DE: {
    iBanNumber: true,
  },
};

/** Modal Component for setting up a bank account for payouts. */
export const SetupExternalAccountModal: React.FC<IProps> = (props) => {
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  console.log(props.account);

  const account = props.account;

  const stripe = useStripe();

  const [country, setCountry] = useState<string>(account.country);

  const [currency, setCurrency] = useState<string>(account.currency);

  const [accountNumber, setAccountNumber] = useState<string>('');

  const [transitNumber, setTransitNumber] = useState<string>('');

  const [routingNumber, setRoutingNumber] = useState<string>('');

  const [institutionNumber, setInstitutionNumber] = useState<string>('');

  const [iBanNumber, setIBanNumber] = useState<string>('');

  const fieldConfig = countryBankFieldConfig[country] || {};

  /** Returns the routing number for the bank account.
   * - Based on: https://stripe.com/docs/connect/payouts-bank-accounts#formats
   */
  function getRoutingNumber() {
    switch (country) {
      case 'CA':
        return transitNumber + institutionNumber;
      case 'US':
      case 'GB':
        return routingNumber;
      case 'DE':
        // Only account number is used for IBAN.
        return undefined;
      default:
        throw new Error(`getRoutingNumber() not implemented for country ${country}`);
    }
  }

  /** Returns the account number for the bank account.
   * - Based on: https://stripe.com/docs/connect/payouts-bank-accounts#formats
   */
  function getAccountNumber() {
    switch (country) {
      case 'CA':
      case 'US':
      case 'GB':
        return accountNumber;
      case 'DE':
        return iBanNumber;
      default:
        throw new Error(`getAccountNumber() not implemented for country ${country}`);
    }
  }

  async function setupBankDetails(e: FormEvent) {
    try {
      e.preventDefault();

      // Create a token that stores securely the bank details.
      const bankToken = await stripe?.createToken('bank_account', {
        country: country,
        currency: currency,
        account_number: getAccountNumber(),
        routing_number: getRoutingNumber(),
        account_holder_type: 'individual',
      });

      if (bankToken?.error) {
        dispatchToastError(bankToken?.error.message, 'Create External Account');
        return;
      }

      typeHelpers.assertNotNullOrUndefined(bankToken?.token?.id);

      const externalAccount = await accountRequestActions.createExternalAccount(
        props.owner.ownerType,
        props.owner.ownerId,
        props.account.id,
        {
          externalAccountToken: bankToken?.token?.id,
        },
      );

      props.onSave();

      dispatchToastSuccess(
        localizeHelpers.translate('External Account created successfully'),
        'Create External Account',
      );

      props.onClose();
    } catch (err) {
      dispatchToastError(err, 'Create External Account');
    }
  }

  return (
    <Modal
      show={props.show}
      class="SetupExternalAccountModal"
      title="Setup External Account"
      onClose={props.onClose}
    >
      <div className="external-account-content">
        {stripe && (
          <form
            className="form"
            onSubmit={setupBankDetails}
          >
            <Dropdown
              className="form-input-field"
              name="country"
              label="Country"
              disabled={true}
              value={country}
              options={localeConstants.supportedCountryOptions}
              onChange={(e) => setCountry(e.target.value)}
            />

            <Dropdown
              className="form-input-field"
              name="currency"
              label="Currency"
              disabled={true}
              value={currency}
              options={localeConstants.supportedCurrencyOptions}
              onChange={(e) => setCurrency(e.target.value)}
            />

            {/* https://stripe.com/docs/connect/payouts-bank-accounts#formats */}
            {fieldConfig.transitNumber && (
              <TextField
                className="form-input-field"
                name="transitNumber"
                label="Transit Number"
                type="text"
                value={transitNumber}
                onChange={(e) => setTransitNumber(e.target.value)}
              />
            )}

            {fieldConfig.institutionNumber && (
              <TextField
                className="form-input-field"
                name="institutionNumber"
                label="Institution Number"
                type="text"
                value={institutionNumber}
                onChange={(e) => setInstitutionNumber(e.target.value)}
              />
            )}

            {fieldConfig.routingNumber && (
              <TextField
                className="form-input-field"
                name="routingNumber"
                label="Routing Number"
                type="text"
                value={routingNumber}
                onChange={(e) => setRoutingNumber(e.target.value)}
              />
            )}

            {fieldConfig.iBanNumber && (
              <TextField
                className="form-input-field"
                name="iBanNumber"
                label="IBAN"
                type="text"
                value={iBanNumber}
                onChange={(e) => setIBanNumber(e.target.value)}
              />
            )}

            {fieldConfig.accountNumber && (
              <TextField
                className="form-input-field"
                name="accountNumber"
                label="Account Number"
                type="text"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            )}

            <Button
              className="submit-button"
              type="submit"
              text="Setup External Account"
            />
          </form>
        )}
      </div>
    </Modal>
  );
};
