import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IUserMyPageItem } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import Portrait from '../Portrait/Portrait';
import { capitalizeString } from '../../helpers';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { connect } from 'react-redux';
import { userSelectors } from '../../selectors/user';
import { IAppState } from '../../store';
import './MyPagesCard.scss';

interface IProps extends RouteComponentProps {
  item: IUserMyPageItem;
  locale: string;
  deleteItem: (item: IUserMyPageItem) => void;
}
interface IState {
  showMenu: boolean;
}
interface IFaIcon {
  [propertyName: string]: string;
}

class MyPagesCard extends React.Component<IProps, IState> {
  faIcons: IFaIcon = {
    group: 'far fa-users',
    event: 'fal fa-ticket-alt',
    fundraiser: 'fal fa-piggy-bank',
    individual: 'fal fa-user-circle',
    team: 'fal fa-hands-helping',
    'volunteer gig': 'fal fa-hand-paper',
    'paid gig': 'fal fa-hand-paper',
  };
  private readonly menuRef = React.createRef<HTMLUListElement>();
  private readonly menuButtonRef = React.createRef<HTMLSpanElement>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      showMenu: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.displayDeleteOption = this.displayDeleteOption.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    if (
      this.state.showMenu &&
      !this.menuRef?.current?.contains(event.target) &&
      !this.menuButtonRef?.current?.contains(event.target)
    ) {
      this.setState({
        showMenu: false,
      });
    }
  }

  displayDeleteOption(item: IUserMyPageItem) {
    if (!item.canEdit) return false;

    switch (item.item_type) {
      case 'group':
      case 'event':
      case 'volunteer gig':
      case 'paid gig':
        return true;
      default:
        return false;
    }
  }

  render() {
    const item = this.props.item;
    return (
      <div className="MyPages-card">
        <div
          className="card-top"
          style={{ backgroundImage: `url(${item.cover_image_url})` }}
        >
          <span className="card-label">
            <i className={item.item_type in this.faIcons ? this.faIcons[item.item_type] : ''} />
            {capitalizeString(item.item_type)}
          </span>
          <span
            ref={this.menuButtonRef}
            className={`card-menu-icon ${this.state.showMenu ? 'show' : ''}`}
            onClick={(e: any) => {
              e.preventDefault();
              this.setState({ showMenu: !this.state.showMenu });
            }}
          >
            <i className="fal fa-ellipsis-h-alt" />
          </span>
          <ul
            ref={this.menuRef}
            className={`card-menu ${this.state.showMenu ? 'show' : ''}`}
          >
            <li
              className="view"
              onClick={(a: any) => {
                this.props.history.push(item.url!);
              }}
            >
              <i className="fal fa-file-alt" />
              View
            </li>
            {(item.canManage ||
              item.item_type === Constants.object_type.team ||
              item.item_type === 'individual') && (
              <li
                className="manage"
                onClick={(a: any) => {
                  this.props.history.push(
                    item.url +
                      (item.item_type === 'team' || item.item_type === 'individual'
                        ? '/manage'
                        : '/admin'),
                  );
                }}
              >
                <i className="fal fa-user-cog" />
                Manage
              </li>
            )}
            {this.displayDeleteOption(item) && (
              <li
                onClick={() => {
                  this.props.deleteItem(item);
                }}
                className="delete"
              >
                <i className="fal fa-trash-alt" />
                Delete
              </li>
            )}
          </ul>
        </div>

        <div
          className="card-bottom"
          onClick={(a: any) => {
            this.props.history.push(item.url!);
          }}
        >
          <div className="card-image">
            <Portrait
              size={120}
              source={item.profile_image_url}
            />
          </div>
          <div className="card-info">
            <div className="card-info-wrap">
              <span
                className="name"
                notranslate="yes"
              >
                {item.title}
              </span>
              <span className="created-date">
                Created at{' '}
                <var data-var="created_at">
                  {localizeHelpers.formatDate(
                    item.created_at,
                    LocaleDateFormats.ll,
                    this.props.locale,
                  )}
                </var>
              </span>
              <span
                className={`state-label ${item.item_type.includes('gig') && item.status === 'active' ? 'published' : item.status}`}
              >
                {capitalizeString(item.status as string)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyPagesCard);
