import { Constants } from '@gigit/constants';
import { ICause, IGroup, IHub, IPage, IPageComponent } from '@gigit/interfaces';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { defaultCurrency, formatCurrency, formatQuery } from '../../../../helpers';
import useToastDispatcher from '../../../../hooks/useToaster';
import { hubRequestActions } from '../../../../requestActions';
import {
  IGetMetricParamsFE,
  IGetMetricValueFE,
  metricRequestActions,
} from '../../../../requestActions/metrics';
import { userSelectors } from '../../../../selectors/user';
import { IAppState } from '../../../../store';
import Portrait from '../../../Portrait/Portrait';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../../shared/PopupMenu/PopupMenu';
import { IDropdownOption } from '../../../UserDashboard/Applications/Applications';
import CausesFocusAreas from './ConsolidatedListModals/CausesFocusAreas/CausesFocusAreas';
import FocusAreaSettings from './ConsolidatedListModals/CausesFocusAreas/FocusAreaSettings';
import './ConsolidatedListView.scss';

interface IProps {
  component_type: string;
  owner_id?: string;
  owner_type: string;
  hub?: IHub | null;
  page?: IPage | null;
  userHasEditPermissions?: boolean;
  isContentBlockManageModalSaving?: boolean;
  handleSavePageComponent?: (updatedComponent: IPageComponent) => void;
}

interface ICausesConsolidatedList extends IGroup {
  metrics: IGetMetricValueFE[];
}

interface IFocusAreaConsolidatedList extends ICause {
  metrics: IGetMetricValueFE[];
}

const { metrics } = Constants;
const metricConfiguration = [
  {
    metric_id: metrics.amount_raised,
    label: 'Total Raised',
  },
  {
    metric_id: metrics.volunteer_hours_total,
    label: 'Volunteer Hours',
  },
  {
    metric_id: metrics.amount_donated,
    label: 'Amount Donated',
  },
];

const LIST_LIMIT = '8';

const ConsolidatedListView = (props: IProps) => {
  const { dispatchToastError } = useToastDispatcher();

  const userInfo = useSelector((state: IAppState) => userSelectors.getUser(state));
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const [itemList, setItemList] = useState<ICausesConsolidatedList[]>([]);
  const [showContextMenuSort, setShowContextMenuSort] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<IDropdownOption>({
    value: Constants.metrics.volunteer_hours_total,
    label: 'Volunteer Hours',
  });
  const [expandedRows, setExpandedRows] = useState<number[]>([0]);
  const [allPartnerCauses, setAllPartnerCauses] = useState<ICause[]>([]);
  const [focusAreaConsolidatedList, setFocusAreaConsolidatedList] = useState<
    IFocusAreaConsolidatedList[]
  >([]);
  const [highlightStyle, setHighlightStyle] = useState<{ color: string }>();
  const [showFocusAreasModal, setShowFocusAreasModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ICausesConsolidatedList | null>(null);
  const [showManageFocusAreas, setShowManageFocusAreas] = useState<boolean>(false);
  const [focusAreaPageComponent, setFocusAreaPageComponent] = useState<IPageComponent>();

  const popupMenuConfigSortBy: IShowPopupConfig = {
    showMenu: showContextMenuSort,
    setShowMenu: setShowContextMenuSort,
    position: {
      type: 'bottom',
    },
  };

  const sortOptions = {
    volunteer_hours_total: { value: 'volunteer_hours_total', label: 'Volunteer Hours' },
    amount_donated: { value: 'amount_donated', label: 'Amount Donated' },
    amount_raised: { value: 'amount_raised', label: 'Amount Raised' },
  };

  const contextMenuSortOptions: IPopupMenuItem[] = [
    {
      id: Constants.metrics.volunteer_hours_total,
      label: 'Volunteer Hours',
      isSelected: selectedSort?.value === Constants.metrics.volunteer_hours_total,
      onClick: () => {
        setShowContextMenuSort(false);
        setSelectedSort(sortOptions.volunteer_hours_total);
        filterList(sortOptions.volunteer_hours_total.value);
      },
    },
    {
      id: Constants.metrics.amount_donated,
      label: 'Amount Donated',
      isSelected: selectedSort?.value === Constants.metrics.amount_donated,
      onClick: () => {
        setShowContextMenuSort(false);
        setSelectedSort(sortOptions.amount_donated);
        filterList(sortOptions.amount_donated.value);
      },
    },
    {
      id: Constants.metrics.amount_raised,
      label: 'Amount Raised',
      isSelected: selectedSort?.value === Constants.metrics.amount_raised,
      onClick: () => {
        setShowContextMenuSort(false);
        setSelectedSort(sortOptions.amount_raised);
        filterList(sortOptions.amount_raised.value);
      },
    },
  ];

  const getShowMenuClassType = showContextMenuSort ? 'show' : '';

  useEffect(() => {
    if (props.owner_id) {
      if (props.owner_type === Constants.page_owner_object_type.hub) {
        if (props.component_type === Constants.hub_page_component_types.causes) {
          getHubPartners(props.owner_id);
        }
        if (props.component_type === Constants.hub_page_component_types.focus_areas) {
          if (props.page) {
            let pageComponent = props.page.components?.find(
              (page) => page.component_type == Constants.hub_page_component_types.focus_areas,
            );
            setFocusAreaPageComponent(pageComponent);
          }
          getFocusAreas();
        }
      }
    }
  }, [props.owner_id, props.page, isUserLoggedIn]);

  useEffect(() => {
    if (props.hub && props.hub.theme) {
      setHighlightStyle({
        color: props.hub.theme.primary_color || '#2C73D6',
      });
    }
  }, [props.hub]);

  async function getHubPartners(id: string) {
    try {
      const query = {
        limit: LIST_LIMIT,
      };
      let partners = await hubRequestActions.getHubPartners(id, formatQuery(query));
      getMetrics(partners);
    } catch (error) {
      dispatchToastError(error, 'Hub Partners');
    }
  }

  async function getMetrics(causeList: IGroup[] | ICause[], isFocusArea?: boolean) {
    try {
      const availableMetrics = await metricRequestActions.getAvailableMetricsForObjectType(
        Constants.object_type.hub,
      );

      const filteredMetrics = availableMetrics.filter(({ metric }) =>
        metricConfiguration.find((metricConfig) => metricConfig.metric_id === metric),
      );
      let bulkMetrics: IGetMetricParamsFE[] = [];
      for (let cause of causeList) {
        for (let metric of filteredMetrics) {
          if (isFocusArea) {
            bulkMetrics.push({
              object_id: props.hub?.id!,
              object_type: Constants.object_type.hub,
              metric: metric.metric,
              currency:
                props.hub?.localization?.currency ||
                userInfo.account?.currency ||
                defaultCurrency.toLowerCase(),
              sub_filters: {
                cause: cause.id!,
              },
            });
          } else {
            bulkMetrics.push({
              object_id: cause.id!,
              object_type: Constants.object_type.group,
              metric: metric.metric,
              currency:
                props.hub?.localization?.currency ||
                userInfo.account?.currency ||
                defaultCurrency.toLowerCase(),
              sub_filters: {
                hub_id: props.hub?.id,
              },
            });
          }
        }
      }

      const metricsBulkResponse = await metricRequestActions.getMetricsBulk({
        metrics: bulkMetrics,
      });

      if (isFocusArea) {
        let focusAreaMetricList: IFocusAreaConsolidatedList[] = [];

        causeList.map((cause: Partial<IFocusAreaConsolidatedList>) => {
          cause.metrics = metricsBulkResponse.metrics.filter(
            (metric) => metric.sub_filters?.cause == cause.id,
          );
          focusAreaMetricList.push(cause as IFocusAreaConsolidatedList);
        });

        setFocusAreaConsolidatedList(focusAreaMetricList);
      } else {
        let causeMetricList: ICausesConsolidatedList[] = [];

        causeList.map((cause: Partial<ICausesConsolidatedList>) => {
          cause.metrics = metricsBulkResponse.metrics.filter(
            (metric) => metric.object_id == cause.id,
          );
          causeMetricList.push(cause as ICausesConsolidatedList);
        });

        setItemList(causeMetricList);

        const causes = await getHubPartnerFocusAreas();
        setAllPartnerCauses(causes);
      }
      return;
    } catch (error) {
      // dispatchToastError(error, 'Cause Metrics');
    }
  }

  async function getHubPartnerFocusAreas() {
    try {
      return await hubRequestActions.getHubPartnersCauses(props.owner_id!);
    } catch (error) {
      // dispatchToastError(error, 'Cause Metrics');
      return [];
    }
  }

  async function getFocusAreas() {
    let focusAreas = await hubRequestActions.getHubCauses(props.owner_id!);

    if (props.page) {
      let focusAreaComponent = props.page.components?.find(
        (component) => component.component_type === Constants.hub_page_component_types.focus_areas,
      );
      if (
        focusAreaComponent?.content_references?.object_ids &&
        focusAreaComponent?.content_references?.object_ids.length
      ) {
        const visibleAreaIds = focusAreaComponent?.content_references?.object_ids.map(
          (objectId) => objectId,
        );
        let visibleAreas = focusAreas.filter((item) => visibleAreaIds.indexOf(item.id!) !== -1);
        getMetrics(visibleAreas, true);
        // setAllPartnerCauses(visibleAreas);
      } else {
        if (focusAreas.length > 8) {
          focusAreas = focusAreas.slice(0, 8);
          getMetrics(focusAreas, true);
          // setAllPartnerCauses(focusAreas);
        } else {
          getMetrics(focusAreas, true);
          // setAllPartnerCauses(focusAreas);
        }
        if (props.owner_id && focusAreaComponent && props.userHasEditPermissions) {
          focusAreaComponent.content_references = {
            object_ids: focusAreas.map((focusAreaId) => focusAreaId.id) as string[],
            object_type: Constants.object_type.cause,
          };
          await hubRequestActions.updateHubPageComponent(
            props.owner_id,
            props.page.id,
            focusAreaComponent.id,
            focusAreaComponent,
          );
        }
      }
    }
  }

  function expandRow(index: number) {
    let tmpExpandedRows = expandedRows;
    if (expandedRows.indexOf(index) !== -1) {
      tmpExpandedRows = tmpExpandedRows.filter((row) => row !== index);
    } else {
      tmpExpandedRows.push(index);
    }

    setExpandedRows([...tmpExpandedRows]);
  }

  function filterList(filter: string) {
    let tmpList = itemList;

    const sortFilter = (fieldToCompare: string) => {
      tmpList.sort((a: ICausesConsolidatedList, b: ICausesConsolidatedList) => {
        let first = a.metrics.find(
          (metric: IGetMetricValueFE) => metric.metric === fieldToCompare,
        )?.value;
        let second = b.metrics.find(
          (metric: IGetMetricValueFE) => metric.metric === fieldToCompare,
        )?.value;
        if (first && second) {
          if (first > second) {
            return -1;
          } else {
            return 1;
          }
        } else {
          if (!first) {
            return 1;
          } else if (!second) {
            return -1;
          } else {
            return -1;
          }
        }
      });

      setItemList([...tmpList]);
    };

    switch (filter) {
      case sortOptions.volunteer_hours_total.value:
        sortFilter(sortOptions.volunteer_hours_total.value);
        return;
      case sortOptions.amount_donated.value:
        sortFilter(sortOptions.amount_donated.value);
        return;
      case sortOptions.amount_raised.value:
        sortFilter(sortOptions.amount_raised.value);
        return;
    }
  }

  function openAllCauses(item: ICausesConsolidatedList) {
    setSelectedItem(item);
    setShowFocusAreasModal(true);
  }

  function renderItemRow() {
    const renderCausesRow = (item: ICausesConsolidatedList, index: number) => {
      return (
        <div
          onClick={() => expandRow(index)}
          key={item.id}
          className={`list-item ${index == 0 ? 'first' : ''} ${
            expandedRows.indexOf(index) !== -1 ? 'expanded' : ''
          }`}
        >
          <div className="item-section">
            <Portrait
              currentImage={item?.profile_image_url}
              size={30}
            />
            <div
              notranslate="yes"
              className="item-title"
            >
              {item.title}
            </div>
          </div>
          <div className="item-section">
            <div
              notranslate="yes"
              className="item-handle"
            >
              @{item.handle}
            </div>
            <div className="item-title">#{index + 1}</div>
          </div>
        </div>
      );
    };

    const renderFocusAreaRow = (item: ICause, index: number) => {
      return (
        <div
          onClick={() => expandRow(index)}
          key={item.id}
          className={`list-item ${index == 0 ? 'first' : ''} ${
            expandedRows.indexOf(index) !== -1 ? 'expanded' : ''
          }`}
        >
          <div className="item-section">
            <Portrait
              hideMask={true}
              currentImage={item?.icon_url}
              size={30}
            />
            <div className="item-title">{item.cause}</div>
          </div>
          <div className="item-section">
            <div className="item-title">#{index + 1}</div>
          </div>
        </div>
      );
    };

    if (props.component_type === Constants.hub_page_component_types.causes) {
      return itemList.length
        ? itemList.map((item: ICausesConsolidatedList, index: number) => {
            return (
              <Fragment key={index}>
                {renderCausesRow(item, index)}
                {renderExpandedRows(item, index)}
              </Fragment>
            );
          })
        : renderEmptyState();
    }

    if (props.component_type === Constants.hub_page_component_types.focus_areas) {
      return focusAreaConsolidatedList.length
        ? focusAreaConsolidatedList.map((item: IFocusAreaConsolidatedList, index: number) => {
            return (
              <>
                {renderFocusAreaRow(item, index)}
                {renderExpandedRows(item, index)}
              </>
            );
          })
        : renderEmptyState();
    }
  }

  function renderExpandedRows(
    item: ICausesConsolidatedList | IFocusAreaConsolidatedList,
    index: number,
  ) {
    if (props.component_type === Constants.hub_page_component_types.causes) {
      if (expandedRows.indexOf(index) !== -1) {
        return (
          <div className="row-details">
            <div
              className="metric-item"
              style={
                selectedSort.value === Constants.metrics.volunteer_hours_total
                  ? highlightStyle
                  : undefined
              }
            >
              <span notranslate="yes">
                {
                  item.metrics.find(
                    (metric: IGetMetricValueFE) =>
                      metric.metric == Constants.metrics.volunteer_hours_total,
                  )?.value
                }
                h
              </span>
              <div className="metric-label">Volunteer Hours</div>
            </div>
            <div
              className="metric-item"
              style={
                selectedSort.value === Constants.metrics.amount_donated ? highlightStyle : undefined
              }
            >
              <span notranslate="yes">
                {formatCurrency(
                  item.metrics.find(
                    (metric: IGetMetricValueFE) =>
                      metric.metric == Constants.metrics.amount_donated,
                  )?.value,
                  props.hub?.localization?.currency ||
                    userInfo.account?.currency ||
                    defaultCurrency.toLowerCase(),
                  locale,
                  { numberFormatOptions: { compactDisplay: 'short', notation: 'compact' } },
                )}
              </span>
              <div className="metric-label">Amount Donated</div>
            </div>
            <div
              className="metric-item"
              style={
                selectedSort.value === Constants.metrics.amount_raised ? highlightStyle : undefined
              }
            >
              <span notranslate="yes">
                {formatCurrency(
                  item.metrics.find(
                    (metric: IGetMetricValueFE) => metric.metric == Constants.metrics.amount_raised,
                  )?.value,
                  props.hub?.localization?.currency ||
                    userInfo.account?.currency ||
                    defaultCurrency.toLowerCase(),
                  locale,
                  { numberFormatOptions: { compactDisplay: 'short', notation: 'compact' } },
                )}
              </span>
              <div className="metric-label">Amount Raised</div>
            </div>
            <div
              onClick={() => openAllCauses(item as ICausesConsolidatedList)}
              className="metric-item causes"
            >
              {(item as ICausesConsolidatedList).causes?.map((cause: string, index: number) => {
                if (index < 3) {
                  return (
                    <div className={`cause-list ${index == 0 ? 'first' : ''}`}>
                      <img
                        src={
                          allPartnerCauses.find((partnerCause: ICause) => partnerCause.id === cause)
                            ?.icon_url
                        }
                      />
                    </div>
                  );
                }
              })}
              {(item as ICausesConsolidatedList).causes
                ? (item as ICausesConsolidatedList).causes!.length > 4 && (
                    <div className="cause-list">
                      +{(item as ICausesConsolidatedList).causes?.length}
                    </div>
                  )
                : null}
            </div>
          </div>
        );
      }
    }

    if (props.component_type === Constants.hub_page_component_types.focus_areas) {
      if (expandedRows.indexOf(index) !== -1) {
        return (
          <div className="row-details">
            <div
              className="metric-item"
              style={
                selectedSort.value === Constants.metrics.volunteer_hours_total
                  ? highlightStyle
                  : undefined
              }
            >
              <span notranslate="yes">
                {item.metrics.find(
                  (metric: IGetMetricValueFE) =>
                    metric.metric == Constants.metrics.volunteer_hours_total,
                )?.value || 0}
                h
              </span>
              <div className="metric-label">Volunteer Hours</div>
            </div>
            <div
              className="metric-item"
              style={
                selectedSort.value === Constants.metrics.amount_donated ? highlightStyle : undefined
              }
            >
              <span notranslate="yes">
                {formatCurrency(
                  item.metrics.find(
                    (metric: IGetMetricValueFE) =>
                      metric.metric == Constants.metrics.amount_donated,
                  )?.value,
                  props.hub?.localization?.currency ||
                    userInfo.account?.currency ||
                    defaultCurrency.toLowerCase(),
                  locale,
                  { numberFormatOptions: { compactDisplay: 'short', notation: 'compact' } },
                )}
              </span>
              <div className="metric-label">Amount Donated</div>
            </div>
            <div
              className="metric-item"
              style={
                selectedSort.value === Constants.metrics.amount_raised ? highlightStyle : undefined
              }
            >
              <span notranslate="yes">
                {formatCurrency(
                  item.metrics.find(
                    (metric: IGetMetricValueFE) => metric.metric == Constants.metrics.amount_raised,
                  )?.value,
                  props.hub?.localization?.currency ||
                    userInfo.account?.currency ||
                    defaultCurrency.toLowerCase(),
                  locale,
                  { numberFormatOptions: { compactDisplay: 'short', notation: 'compact' } },
                )}
              </span>
              <div className="metric-label">Amount Raised</div>
            </div>
          </div>
        );
      }
    }
  }

  function renderEmptyState() {
    return (
      <>
        <div className="no-content">
          <div className="no-content-icon">
            {props.component_type === Constants.hub_page_component_types.causes && (
              <i className="far fa-heart"></i>
            )}
            {props.component_type === Constants.hub_page_component_types.focus_areas && (
              <i className="fal fa-globe-americas"></i>
            )}
          </div>
          <div className="no-content-text">
            {props.component_type === Constants.hub_page_component_types.causes && (
              <div>This company page isn't currently supporting any causes</div>
            )}
            {props.component_type === Constants.hub_page_component_types.focus_areas && (
              <div>This company page hasn't selected any Focus Areas</div>
            )}
            {props.component_type === Constants.hub_page_component_types.causes &&
              props.userHasEditPermissions && (
                <Link to={`/company/${props.hub?.handle}/admin?t=benefiting-causes`}>
                  Add a Cause
                </Link>
              )}
            {props.component_type === Constants.hub_page_component_types.focus_areas &&
              props.userHasEditPermissions && (
                <span
                  className="focus-area-manage"
                  onClick={() => setShowManageFocusAreas(true)}
                >
                  Manage Focus Areas
                </span>
              )}
          </div>
        </div>
      </>
    );
  }

  function returnTitle() {
    switch (props.component_type) {
      case Constants.hub_page_component_types.causes:
        return 'Supported Causes';
      case Constants.hub_page_component_types.focus_areas:
        return 'Supported Focus Areas';
    }
  }

  return (
    <div className="ConsolidatedListView">
      <div className="header-bar">
        <div className="title">{returnTitle()}</div>
        {itemList.length ? (
          <div className="filters">
            <PopupMenu
              menuItems={contextMenuSortOptions}
              showMenuConfig={popupMenuConfigSortBy}
              popupMenuClass="applications-popupMenu-sort"
              className={`Dropdown filter-dropdown sort ${getShowMenuClassType}`}
              onClick={() => setShowContextMenuSort(!showContextMenuSort)}
            >
              <label>
                <span>Sort By</span>
              </label>
              <div className="input-wrap">
                <span className="label">{selectedSort.label}</span>
                <i className={`fas fa-caret-down menu-btn ${getShowMenuClassType}`} />
              </div>
            </PopupMenu>
          </div>
        ) : null}
      </div>
      <div className="list-view">{renderItemRow()}</div>
      <CausesFocusAreas
        show={showFocusAreasModal}
        triggerShowModal={(show: boolean) => setShowFocusAreasModal(show)}
        selectedItem={selectedItem}
        allCauses={allPartnerCauses}
      />
      {focusAreaPageComponent && (
        <FocusAreaSettings
          hub={props.hub}
          showModal={showManageFocusAreas}
          pageComponent={focusAreaPageComponent!}
          handleSavePageComponent={props.handleSavePageComponent!}
          isSaving={props.isContentBlockManageModalSaving!}
          onClose={() => setShowManageFocusAreas(false)}
        />
      )}
    </div>
  );
};

export default ConsolidatedListView;
