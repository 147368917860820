import React, { ReactNode } from 'react';
import axios from 'axios';
import Joyride from 'react-joyride';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Constants } from '@gigit/constants';
import {
  IEventSummaryFE,
  IPage,
  IPageComponent,
  ICampaign,
  IRole,
  IUserRole,
  IUserRoleJoin,
} from '@gigit/interfaces';
import { RouteComponentProps, Redirect, Link } from 'react-router-dom';
import { localizeHelpers } from '../../localizeHelpers';
import {
  defaultCurrency,
  swapRouteParams,
  routes,
  formatCurrency,
  mapPermissions,
  hasPermission,
  setSEOMetatags,
  toastError,
  dataURItoBlob,
  toastInfo,
  isPageActive,
  round,
} from '../../helpers';
import { IStep, IToast, IOwnerObject } from '../../interfaces';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { IAppState } from '../../store';
import { IUserState } from '../../reducers/user';
import { getGroupLocations } from '../../actions/group';
import { createToast } from '../../actions/toaster';
import { setCartCurrency } from '../../actions/cart';
import { ICartState } from '../../reducers/cart';
import { updateEventCoverImage } from '../../actions/event';
import Button from '../../components/Button/Button';
import Portrait from '../../components/Portrait/Portrait';
import PageComponent from '../../components/PageComponent/PageComponent';
import ComponentLibrary from '../../components/ComponentLibrary/ComponentLibrary';
import ComponentPlaceholder from '../../components/PageComponent/ComponentPlaceholder/ComponentPlaceholder';
import Donations from '../../components/PageComponent/Donations/Donations';
import Navigation from '../../components/shared/pages/Navigation/Navigation';
import PageDetails from '../../components/shared/pages/PageDetails/PageDetails';
import './Event.scss';
import { userSelectors } from '../../selectors/user';
import typeHelpers from '../../helpers/typeHelpers';
import errorHelpers from '../../helpers/errorHelpers';
import { BackSplash } from '../../components/ProfilePageComponents/BackSplash/BackSplash';
import { Config } from '@gigit/config';
import { uiConstants } from '../../constants';
import {
  eventRequestActions,
  groupRequestActions,
  hubRequestActions,
  userRequestActions,
} from '../../requestActions';
import AccessDenied from '../../components/Hub/AccessDenied/AccessDenied';

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  locale: string;
  createToast(toast: IToast): void;
  getGroupLocations(groupId: string): void;
  setCartCurrency(currency: string): void;
  updateEventCoverImage(img: string): void;

  /** Used to render optional child content. */
  children?: (ownerObj: IOwnerObject) => ReactNode;
  cartState: ICartState;
}

interface IState {
  loading: boolean;
  pageLoading: boolean;
  noAccess: boolean;
  showAddComponent: boolean;
  event: IEventSummaryFE | null;
  role: IRole | null;
  page: IPage | null;
  componentMetrics: any[];
  campaign: ICampaign | null;
  ticketComponents: IPageComponent[];
  sponsorComponents: IPageComponent[];
  joyrideSteps: IStep[];
  showJoyride: boolean;
  parentEvent: IEventSummaryFE | null;
  currentPageIndex: number;
  goalProgress: number;
  ownerObject: IOwnerObject | null;
}

class Event extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      ownerObject: null,
      loading: true,
      pageLoading: false,
      showAddComponent: false,
      noAccess: false,
      event: null,
      role: null,
      page: null,
      componentMetrics: [],
      campaign: null,
      ticketComponents: [],
      sponsorComponents: [],
      joyrideSteps: [
        {
          disableBeacon: true,
          target: '.PageDetails .details .details-info .details-title .Portrait .inner',
          content: "Customize your Event's profile picture to make your event stand out.",
        },
        {
          disableBeacon: true,
          target: '.CoverImage',
          content: 'Give your event a cover image to make it exciting!',
        },
        {
          disableBeacon: true,
          target: '.Event .inner-wrap .Navigation .admin-action',
          content: 'Manage your Event details at any time using the Manage button.',
        },
        {
          disableBeacon: true,
          target: '.Event .inner-wrap .Navigation .nav-items',
          content: 'Add pages and re-order them to customize your event page.',
        },
        {
          disableBeacon: true,
          target: '.add-component',
          content: 'Add components from our library to build the perfect event!',
        },
      ],
      showJoyride: false,
      parentEvent: null,
      currentPageIndex: 0,
      goalProgress: 0,
    };

    this.getEvent = this.getEvent.bind(this);
    this.getUserPermissions = this.getUserPermissions.bind(this);
    this.getGoalProgress = this.getGoalProgress.bind(this);
    this.getPageComponents = this.getPageComponents.bind(this);
    this.addComponent = this.addComponent.bind(this);
    this.deleteComponent = this.deleteComponent.bind(this);
    this.getEventCampaign = this.getEventCampaign.bind(this);
    this.updateComponent = this.updateComponent.bind(this);
    this.showCampaignProgress = this.showCampaignProgress.bind(this);
    this.showParentEvent = this.showParentEvent.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.calcProgressPercentage = this.calcProgressPercentage.bind(this);
  }

  componentDidMount() {
    let _params = queryString.parse(this.props.location.search);

    this.setState({
      showJoyride: !!_params.help,
    });

    this.getEvent();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.userState.isLoggedIn !== this.props.userState.isLoggedIn) {
      //TODO: GIG-7896 - inspect this, unsure
      if (this.props.userState.isLoggedIn === false) {
        this.setState({
          role: null,
        });

        if (this.state?.event?.status?.code === Constants.event_status.draft) {
          this.showNotActivePageWarning();
        }
      }
    }

    if (prevState.event !== this.state.event) {
      if (this.state.event) {
        this.setState({
          ownerObject: typeHelpers.createOwnerObject('event', this.state.event) || null,
        });
      }
    }
  }

  showNotActivePageWarning() {
    this.props.createToast(
      toastInfo(localizeHelpers.translate(uiConstants.warning.page_not_active), 'Warning'),
    );
    this.props.history.push('/');
  }

  async handleGetEventDataMembers(eventId: string) {
    let result: IUserRole[] = [];
    try {
      result = await eventRequestActions.getEventMembers(eventId);
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Get Event Members');
      this.props.createToast(toast);
    }
    return result;
  }

  async handleGetEventData() {
    let result: IEventSummaryFE | null = null;
    try {
      result = await eventRequestActions.getEventByHandleOrId(this.props.match.params.handle);
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Get Event');
      this.props.createToast(toast);
    }
    return result;
  }

  getEvent() {
    this.setState(
      {
        loading: true,
        event: null,
        role: null,
      },
      async () => {
        let eventData: IEventSummaryFE | null = null;
        try {
          eventData = await this.handleGetEventData();
        } catch (error) {
          this.props.history.push('/not-found');
        }

        if (eventData) {
          let userRoleName: IUserRoleJoin;
          /**
           * Here we check to see if the user can access a 'draft' page.
           * a user can access a draft page if they have the proper admin/on-behalf-of role
           * if a user doesn't have one of those roles or is logged out, we redirect them to the home page
           */

          let _params = queryString.parse(this.props.location.search);
          if (
            !this.props.location.pathname.includes('accept_invite') &&
            !this.props.location.pathname.includes('cancel_invite') &&
            this.props.userState.isLoggedIn === false &&
            eventData?.status?.code === Constants.event_status.draft
          ) {
            this.showNotActivePageWarning();
          }
          if (eventData?.hub_id) {
            try {
              const pages = await hubRequestActions.getHubPages(eventData?.hub_id);
              // To find event object in page list to edit tab names in company
              const eventPageConfig = pages.find((page) =>
                (page?.components || []).find(
                  (component) =>
                    component.component_type === Constants.hub_page_component_types.events,
                ),
              );
              if (
                this.props.userState.isLoggedIn === false &&
                eventPageConfig?.visibility === uiConstants.visibility.public
              )
                this.setState({ noAccess: false });
              else if (
                this.props.userState.isLoggedIn === false &&
                eventPageConfig?.visibility === uiConstants.visibility.membersOnly
              )
                this.setState({ noAccess: true });
              else {
                const member = await hubRequestActions.getHubUser(eventData?.hub_id);
                const isMember = member.role_name === uiConstants.role.member;
                if (member.role_name === uiConstants.role.admin) this.setState({ noAccess: false });
                else if (
                  eventPageConfig?.visibility === uiConstants.visibility.membersOnly &&
                  !isMember
                )
                  this.setState({ noAccess: true });
              }
            } catch (error) {
              const errorObj = errorHelpers.getErrorObject(error);
              this.props.createToast(
                toastError(errorObj.translatedMessage, 'Visibility Component'),
              );
            }
          }
          try {
            if (
              this.props.userState.isLoggedIn &&
              eventData?.status?.code === Constants.event_status.draft
            ) {
              userRoleName = await userRequestActions.getUserEventRole(eventData.id);
              if (
                !this.props.location.pathname.includes('accept_invite') &&
                !this.props.location.pathname.includes('cancel_invite') &&
                !isPageActive({
                  status: eventData?.status?.code,
                  isUserLoggedIn: this.props.userState.isLoggedIn,
                  roleName: userRoleName?.role_name,
                })
              ) {
                this.showNotActivePageWarning();
              }
            }
          } catch (err) {
            const errorObj = errorHelpers.getErrorObject(err);

            if (
              errorObj.errorCode !== 'ERROR.ROLES.NO_OBJECT_PERMISSIONS' &&
              errorObj.errorCode !== 'ERROR.AUTH.UNAUTHORIZED'
            ) {
              this.props.createToast(toastError(errorObj.translatedMessage, 'Event Members'));
            }
          }

          this.setState(
            {
              event: eventData,
            },
            () => {
              //we need set card currency because modal "Items Added to your card" is part of the <Header>
              this.state.event?.group?.account &&
                this.props.setCartCurrency(this.state.event?.group?.account.currency);

              if (this.state.event) {
                this.getComponentMetrics();

                setSEOMetatags({
                  urlPath: 'event/' + this.state.event.handle,
                  title: this.state.event.title,
                  description: undefined,
                  imageURL: this.state.event.profile_image_url,
                  coverImageURL: this.state.event.cover_image_url,
                });

                if (this.props.userState.isLoggedIn) {
                  this.getUserPermissions(this.state.event.id);
                }

                if (this.state.event.campaign_id && this.state.event.group_id) {
                  this.getEventCampaign(this.state.event.group_id, this.state.event.campaign_id);
                }

                if (this.state.event.parent_event_id) {
                  axios
                    .get(
                      swapRouteParams(routes.GET_EVENT, {
                        handleOrId: this.state.event.parent_event_id,
                      }),
                    )
                    .then((response) => {
                      this.setState({
                        parentEvent: response.data,
                        loading: false,
                      });
                    });
                }

                this.setState({ goalProgress: this.getGoalProgress() });
              }
            },
          );
        } else {
          this.props.history.push('/not-found');
        }
      },
    );
  }

  getEventCampaign(gId: string, cId: string) {
    axios
      .get(swapRouteParams(routes.GET_GROUP_CAMPAIGN, { groupId: gId, id: cId }))
      .then((response) => {
        this.setState({
          campaign: response.data,
        });
      });
  }

  getComponentMetrics() {
    let _state: any = {};

    if (!this.state.event?.parent_event_id) {
      _state.loading = false;
    }

    axios
      .get(swapRouteParams(routes.GET_EVENT_COMPONENT_COUNT, { eventId: this.state.event?.id }))
      .then((response) => {
        _state.componentMetrics = response.data;

        this.setState(_state);
      });
  }

  getUserPermissions(id: string) {
    axios
      .get(swapRouteParams(routes.GET_CURRENT_USER_EVENT_ROLE, { eventId: id }))
      .then((response) => {
        this.setState({
          role: response.data,
        });
      });
  }

  getGoalProgress() {
    let _raised: number = this.state.event?.raised || 0;
    let _goal: number =
      this.state.event && this.state.event.goal && this.state.event.goal !== 0
        ? this.state.event.goal
        : this.state.event?.raised || 1;

    return (_raised / _goal) * 100;
  }

  getPageComponents(page: IPage, pageIndex: number) {
    this.setState({
      page: page,
      currentPageIndex: pageIndex,
    });
  }

  addComponent(type: string) {
    this.setState(
      {
        showAddComponent: false,
      },
      async () => {
        let payload: Partial<IPageComponent> = {
          visibility: 'public',
          sequence: 1,
          title: '',
          component_type: type,
        };

        typeHelpers.assertNotNullOrUndefined(this.state.event, 'Expected an Event');
        typeHelpers.assertNotNullOrUndefined(this.state.page, 'Expected a Page');

        try {
          const url = swapRouteParams(routes.CREATE_EVENT_PAGE_COMPONENT, {
            eventId: this.state.event.id,
            pageId: this.state.page.id,
          });
          const response = await axios.post(url, payload, {
            params: {
              include_component_objects: true,
            },
          });

          let _page: IPage = { ...this.state.page };

          if (!_page.components) {
            _page.components = [];
          }
          _page.components.splice(0, _page.components.length, ...response.data.components);

          this.setState(
            {
              page: _page,
            },
            () => {
              this.getComponentMetrics();
            },
          );
        } catch (err) {
          const errorObj = errorHelpers.getErrorObject(err);
          this.props.createToast(toastError(errorObj.translatedMessage, 'Add Component'));
        }
      },
    );
  }

  async deleteComponent(cId: string) {
    typeHelpers.assertNotNullOrUndefined(this.state.event, 'Expected an Event');
    typeHelpers.assertNotNullOrUndefined(this.state.page, 'Expected a Page');

    try {
      const url = swapRouteParams(routes.DELETE_EVENT_PAGE_COMPONENT, {
        eventId: this.state.event.id,
        pageId: this.state.page.id,
        componentId: cId,
      });
      const response = await axios.delete(url);

      let _page: IPage = { ...this.state.page };

      if (_page.components) {
        for (let c = 0; c < _page.components?.length; c++) {
          if (_page.components[c].id === cId) {
            _page.components.splice(c, 1);

            this.setState({
              ticketComponents: this.state.ticketComponents.filter(
                (component) => component.id !== cId,
              ),
              sponsorComponents: this.state.sponsorComponents.filter(
                (component) => component.id !== cId,
              ),
            });

            break;
          }
        }
      }

      this.setState(
        {
          page: _page,
        },
        () => {
          this.getComponentMetrics();
        },
      );
    } catch (err) {
      const errorObj = errorHelpers.getErrorObject(err);
      this.props.createToast(toastError(errorObj.translatedMessage, 'Delete Component'));
    }
  }

  async getComponent(cId: string) {
    typeHelpers.assertNotNullOrUndefined(this.state.event, 'Expected an Event');
    typeHelpers.assertNotNullOrUndefined(this.state.page, 'Expected a Page');

    try {
      const response = await axios.get(
        swapRouteParams(routes.GET_EVENT_PAGE_COMPONENT, {
          eventId: this.state.event.id,
          pageId: this.state.page.id,
          componentId: cId,
        }),
      );

      let _page = { ...this.state.page };

      if (_page.components) {
        for (let c = 0; c < _page.components.length; c++) {
          if (_page.components[c].id === response.data.id) {
            _page.components[c] = response.data;
            _page.components[c].id = response.data.id;
            break;
          }
        }
      }

      this.setState(
        {
          page: _page,
        },
        () => {
          if (response.data.component_type === 'store_item') {
            this.checkForTicketsAndSponsors([_page]);
          }
          this.getComponentMetrics();
        },
      );
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      this.props.createToast(toastError(errObj.translatedMessage, 'Fetch Event Page Component'));
    }

    axios
      .get(
        swapRouteParams(routes.GET_EVENT_PAGE_COMPONENT, {
          eventId: this.state.event?.id,
          pageId: this.state.page?.id,
          componentId: cId,
        }),
      )
      .then((response) => {});
  }

  updateComponent(pId: string, cId: string, component: IPageComponent) {
    axios.put(
      swapRouteParams(routes.UPDATE_EVENT_PAGE_COMPONENT, {
        eventId: this.state.event?.id,
        pageId: pId,
        componentId: cId,
      }),
      component,
    );
  }

  checkForTicketsAndSponsors(pages: IPage[]) {
    let ticketComponents: IPageComponent[] = [];
    let sponsorComponents: IPageComponent[] = [];

    for (const page of pages) {
      if (page.components) {
        for (const component of page.components) {
          if (component.component_type === 'store_item') {
            if (
              component.content_objects?.find(
                (contentObject) => contentObject.item_type === 'ticket',
              )
            ) {
              ticketComponents.push(component);
            }

            if (
              component.content_objects?.find(
                (contentObject) => contentObject.item_type === 'sponsorship',
              )
            ) {
              sponsorComponents.push(component);
            }
          }
        }
      }
    }

    this.setState({
      ticketComponents: ticketComponents,
      sponsorComponents: sponsorComponents,
    });
  }

  handleComponentDrag = (result: DropResult) => {
    if (result.destination) {
      typeHelpers.assertNotNullOrUndefined(this.state.page, 'Expected a Page');

      const pageId = this.state.page.id;

      const _pageComponents = this.state.page.components ?? [];

      // TODO: Fix id field not being sent
      const componentId =
        (_pageComponents[result.source.index] as any)._id ||
        (_pageComponents[result.source.index] as any).id;
      const componentToUpdate = _pageComponents[result.source.index];

      _pageComponents[result.source.index].sequence = result.destination.index + 1;
      _pageComponents.splice(
        result.destination.index,
        0,
        _pageComponents.splice(result.source.index, 1)[0],
      );

      this.setState(
        {
          page: {
            ...this.state.page,
            components: _pageComponents,
          },
        },
        () => {
          this.updateComponent(pageId, componentId, componentToUpdate);
        },
      );
    }
  };

  showCampaignProgress() {
    return this.state.event?.show_campaign_progress && (this.state.event?.goal ?? 0) > 0;
  }

  showParentEvent() {
    return this.state.parentEvent !== null;
  }

  isUserStillLoggedIn(): boolean {
    return this.props.userState.isLoggedIn;
  }

  onImageChange(fileData: any) {
    const blob = dataURItoBlob(fileData.file);
    let formData = new FormData();
    formData.append('file', blob);

    axios
      .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let payload: any = { cover_image_url: response.data.value };
        axios
          .put(swapRouteParams(routes.UPDATE_EVENT, { eventId: this.state.event?._id }), payload)
          .then((response) => {
            this.getEvent();
          });
      });
  }

  calcProgressPercentage(goal: number, raised: number) {
    if (goal && raised) {
      let percentageDecimal = raised / goal;

      let percentage = round(percentageDecimal * 100, 2);

      return percentage;
    }

    return 0;
  }

  renderPage(event: IEventSummaryFE, page: IPage) {
    const ownerObject = typeHelpers.createOwnerObject('event', event);

    return (
      this.state.event !== null &&
      this.state.page !== null && (
        <DragDropContext onDragEnd={this.handleComponentDrag}>
          <Droppable droppableId="components">
            {(provided, snapshot) => (
              <div
                className={snapshot.isDraggingOver ? 'components-wrap dragging' : 'components-wrap'}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {this.state.page?.components?.map((item, index) => {
                  return (
                    <Draggable
                      isDragDisabled={
                        !hasPermission('EDIT_EVENT_PAGES', this.state.role) ||
                        this.props.match.path === '/gig/:id'
                      }
                      key={index}
                      draggableId={'component-' + index}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={snapshot.isDragging ? 'dragging' : ''}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <PageComponent
                            owner={ownerObject}
                            page={page}
                            dragProps={provided.dragHandleProps}
                            component={item}
                            permissions={
                              this.state.role !== null
                                ? mapPermissions(this.state.role.permissions)
                                : []
                            }
                            onDelete={async () => {
                              await this.deleteComponent(item.id);
                            }}
                            onComponentUpdate={async () => {
                              await this.getComponent(item.id);
                            }}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            campaign={this.state.campaign}
                            currentPageIndex={this.state.currentPageIndex}
                            isUserStillLoggedIn={this.isUserStillLoggedIn()}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )
    );
  }

  render() {
    if (this.state.event?.status?.code === Constants.event_status.archived) {
      const toast = toastError(
        localizeHelpers.translate(`Event ${this.state.event?.title} has been archived`),
        '',
      );
      this.props.createToast(toast);

      return <Redirect to="/not-found" />;
    }

    let _joyrideStyles = {
      options: {
        arrowColor: '#FFF',
        backgroundColor: '#FFF',
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: '#5E51AB',
        textColor: '#505050',
      },
    };

    const donationMatching =
      this.state.event && 'donations_matching' in this.state.event
        ? this.state.event?.donations_matching
        : null;
    const currency = this.state.ownerObject?.account?.currency ?? defaultCurrency;
    const isAllowingDonations =
      this.state.event?.accepting_donations || this.state.event?.allow_checkout_donations;
    if (this.state.noAccess) {
      return (
        <AccessDenied
          handle={this.state.event?.hub?.handle || ''}
          title={this.state.event?.hub?.title?.toString() || ''}
        />
      );
    }
    return (
      <div className="Event">
        {this.state.showJoyride && this.state.event !== null && (
          <Joyride
            locale={{ back: 'Back', close: 'Close', last: 'Finish', next: 'Next', skip: 'Skip' }}
            continuous={true}
            disableScrolling={true}
            styles={_joyrideStyles}
            steps={this.state.joyrideSteps}
            callback={(state) => {
              if (state.status === 'finished') {
                this.props.history.replace('/event/' + this.state.event?.handle);
                this.setState({ showJoyride: false });
              }
            }}
          />
        )}
        <BackSplash
          coverImageUrl={this.state.event?.cover_image_url}
          showChat={false}
          onImageChange={this.onImageChange}
          showEditCover={hasPermission('EDIT_EVENT_ROLES', this.state.role)}
        />
        <div className="inner-wrap">
          <PageDetails
            owner={this.state.ownerObject}
            {...this.props}
            campaign={this.state.campaign}
            componentMetrics={this.state.componentMetrics}
            ticketComponents={this.state.ticketComponents}
            sponsorComponents={this.state.sponsorComponents}
            loading={this.state.loading}
            role={this.state.role}
            onImageChange={this.getEvent}
            onStatusChange={this.getEvent}
            history={this.props.history}
            match={this.props.match}
          />
          <Navigation
            owner={this.state.ownerObject}
            loading={this.state.loading}
            role={this.state.role}
            onGetPages={(pages: IPage[]) => {
              this.checkForTicketsAndSponsors(pages);
            }}
            onTabClick={(page: IPage, index: number) => {
              this.getPageComponents(page, index);
            }}
            location={this.props.location}
          />
          {this.state.event !== null &&
            this.state.page !== null &&
            this.state.page.sequence === 1 &&
            (this.showCampaignProgress() || this.showParentEvent()) && (
              <div className="first-page-widgets">
                {this.showCampaignProgress() && (
                  <div className="progress fp-widget">
                    <div className="progress-title">Fundraising Goal</div>
                    <div
                      className="progress-bar"
                      notranslate="yes"
                    >
                      <div
                        className="progress-inner"
                        style={{ width: this.state.goalProgress + '%' }}
                      />
                      <div className="raised">
                        {this.state.event.show_progress_in_percentage
                          ? `${this.calcProgressPercentage(this.state.event?.goal || 0, this.state.event?.raised || 0)}%`
                          : formatCurrency(
                              this.state.event?.raised || 0,
                              currency,
                              this.props.locale,
                            )}
                      </div>
                      <div className="goal">
                        {this.state.event.show_progress_in_percentage
                          ? '100%'
                          : formatCurrency(
                              this.state.event?.goal || 0,
                              currency,
                              this.props.locale,
                            )}
                      </div>
                    </div>
                  </div>
                )}
                {this.showParentEvent() && (
                  <div className="main-event fp-widget">
                    <Portrait
                      size={128}
                      currentImage={this.state.parentEvent?.profile_image_url}
                    />
                    <div className="main-event-info">
                      <div className="me-tag">
                        <i className="fal fa-ticket-alt" />
                        <span>Main Event</span>
                      </div>
                      <div
                        className="me-title"
                        notranslate="yes"
                      >
                        {this.state.parentEvent?.title}
                      </div>
                      {(this.state.parentEvent?.goal || 0) > 0 && (
                        <div className="me-goal">
                          <span>
                            {formatCurrency(
                              this.state.parentEvent?.raised || 0,
                              defaultCurrency,
                              this.props.locale,
                            )}
                          </span>{' '}
                          raised of{' '}
                          <span>
                            {formatCurrency(
                              this.state.parentEvent?.goal || 0,
                              defaultCurrency,
                              this.props.locale,
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="main-event-action">
                      <Link to={'/event/' + this.state.parentEvent?.handle}>View Event</Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          {donationMatching?.ratio_description && (
            <div className="donation-matching-mobile">
              <div className="donation-matching-mobile-wrap">
                <span className="label">Donation Matching</span>
                <span
                  notranslate="yes"
                  className="description"
                >
                  {donationMatching.ratio_description}
                </span>
                {(donationMatching?.matched_amount || 0) > 0 && (
                  <span className="matched-to-date">
                    &nbsp;
                    <var data-var="matched_to_date">
                      {formatCurrency(donationMatching.matched_amount, currency, this.props.locale)}
                    </var>{' '}
                    Matched-to-Date
                  </span>
                )}
              </div>
            </div>
          )}
          <div className="pages">
            <div
              className={`page-content ${!(this.state.event?.accepting_donations || this.state.event?.allow_checkout_donations) ? 'full-width' : ''}`}
            >
              {this.state.page?.components?.length === 0 &&
                hasPermission('EDIT_EVENT_PAGES', this.state.role) && (
                  <ComponentPlaceholder
                    title={'Welcome to your new page!'}
                    message={'Customize your page with components'}
                    actions={[
                      {
                        label: 'Add New Component',
                        iconClassName: 'far fa-plus',
                        action: () => {
                          this.setState({ showAddComponent: true });
                        },
                      },
                    ]}
                  />
                )}
              {(this.state.page?.components?.length || 0) > 0 &&
                hasPermission('EDIT_EVENT_PAGES', this.state.role) && (
                  <Button
                    onClick={() => {
                      this.setState({ showAddComponent: true });
                    }}
                    className="add-component"
                    text="Add New Component"
                    icon="far fa-plus-circle"
                  />
                )}

              {this.state.event !== null &&
                this.state.page !== null &&
                this.renderPage(this.state.event, this.state.page)}
            </div>
            <div className="sidebar">
              {this.state.ownerObject && isAllowingDonations && this.state.page && (
                <Donations
                  {...this.props}
                  campaign={this.state.campaign}
                  page={this.state.page}
                  edit={false}
                  owner={this.state.ownerObject}
                />
              )}
            </div>
          </div>
        </div>
        {this.state.ownerObject && (
          <ComponentLibrary
            owner={this.state.ownerObject}
            show={this.state.showAddComponent}
            onClose={() => {
              this.setState({ showAddComponent: false });
            }}
            onCancel={() => {
              this.setState({ showAddComponent: false });
            }}
            onContinue={this.addComponent}
            event={this.state.event}
          />
        )}

        {this.props.children &&
          this.state.ownerObject &&
          this.state.ownerObject.ownerId &&
          this.props.children(this.state.ownerObject)}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    cartState: store.cartState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
  getGroupLocations,
  setCartCurrency,
  updateEventCoverImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);
