import './VolunteerForDollarsDetails.scss';
import React, { FC, useEffect, useState } from 'react';
import { IAvailableDonationMatchingProgram } from '@gigit/interfaces';
import { formatCurrency } from '../../../../helpers';
import { useLocale } from '../../../../hooks';
import { localizeHelpers, LocaleDateFormats } from '../../../../localizeHelpers';
import { FormSlider } from '../../../../components/shared/Onboarding/FormSlider/FormSlider';
import TextField from '../../../../components/TextField/TextField';
import { userRequestActions } from '../../../../requestActions';

interface IProps {
  groupId: string;
  currency: string;
  onVolunteerCreditsChange(v: number): void;
}
const DONATION_MIN_AMOUNT = 1;

const VolunteerForDollarsDetails: FC<IProps> = (props) => {
  const { currency } = props;
  const [volunteerCreditsBalance, setVolunteerCreditsBalance] = useState<number>(0);
  const [maxAmountMatchable, setMaxAmountMatchable] = useState<number>(0);

  const locale = useLocale();

  async function getUserCreditBalance() {
    const userCreditBalanceResponse = await userRequestActions.getUserCreditBalanceForGroup(
      props.groupId,
    );
    setVolunteerCreditsBalance(userCreditBalanceResponse.balance);
    setMaxAmountMatchable(userCreditBalanceResponse.balance);
  }

  function handleVolunteerCreditsChange(value: number) {
    if (value > maxAmountMatchable) {
      return;
    }
    setVolunteerCreditsBalance(value);
    props.onVolunteerCreditsChange(value);
  }

  function renderVolunteerMatchingDetails() {
    return (
      <div className="donation-matching-details">
        <div className="donation-matching-inner">
          {/* <div className="dm-title">{program?.hub.title}</div> */}
          <div className="donation-matching-content">
            <div className="dm-icon-wrap">
              <div className="dm-icon">
                <i className="fa fa-hand-holding-heart"></i>
              </div>
            </div>
            <div className="dm-text">
              You have {formatCurrency(volunteerCreditsBalance, currency, locale.currentLocale)}{' '}
              volunteer credits available to donate. Valid until{' '}
              {/* {localizeHelpers.formatDate(
                program?.end_date ?? new Date(),
                LocaleDateFormats.ll,
                locale.currentLocale,
              )} */}
              .
            </div>
          </div>
          <div className="dm-progress">
            <div className="dm-bar">
              {/* Use this to show the progress of the current total donated amount*/}
              {/* <div
                className="dm-bar-fill"
                style={{
                  width: `${
                    (program?.available_donation_match.employee_contribution ?? 0) /
                    (program?.available_donation_match.remaining_employee_matchable ?? 0)
                  }%`,
                }}
              ></div> */}
              {/* Use this to show how much the users donation increases the bar*/}
              {/* <div
                className="dm-bar-fill-user"
                style={{
                  width: `${
                    volunteerCredits /
                    (program?.available_donation_match.remaining_employee_matchable ?? 0)
                  }%`,
                }}
              ></div> */}
            </div>
            {/* <div className="dm-amounts">
              <div className="dm-contributed">
                <span>
                  {formatCurrency(
                    program?.available_donation_match.employee_contribution,
                    currency,
                    locale.currentLocale,
                  )}
                </span>
                <span>Contributed</span>
              </div>
              <div className="dm-remaining">
                <span>
                  {formatCurrency(
                    program?.available_donation_match.remaining_employee_matchable,
                    currency,
                    locale.currentLocale,
                  )}
                </span>
                <span>Remaining</span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    getUserCreditBalance();
  }, []);

  return (
    <div className="VolunteerForDollarsDetails">
      <div className="match-text">How many volunteer credits would you like to donate?</div>
      <div className="match-slider">
        <FormSlider
          label={null}
          defaultValue={volunteerCreditsBalance}
          min={DONATION_MIN_AMOUNT}
          max={maxAmountMatchable}
          step={1}
          onChange={(value: number) => handleVolunteerCreditsChange(value)}
        />
        <div className="option">
          <div className="option-inner">$</div>
          <TextField
            pattern="^[0-9]{8}([,.][0-9]{8})+$"
            value={volunteerCreditsBalance}
            name="goal"
            label=""
            type="number"
            min={DONATION_MIN_AMOUNT.toString()}
            max={maxAmountMatchable.toString()}
            disabled={maxAmountMatchable <= 0}
            onChange={(e) => handleVolunteerCreditsChange(Number(e.target.value))}
          />
        </div>
      </div>
      <div className="match-summary">
        <div className="ms-cta-subtext">
          You have a total of {formatCurrency(maxAmountMatchable, currency, locale.currentLocale)}{' '}
          volunteer credits available to donate. Your donation will show as matched when the company
          makes their payment to the cause.
        </div>
      </div>
      {/* <div className="match-summary"> */}
      {/* <div className="ms-cta">
          <div className="portrait-wrap">
            <Portrait
              size={50}
              source={program?.hub.profile_image_url}
            />
          </div>
          <div className="ms-cta-text">
            {program?.hub.title} is now matching your donation of{' '}
            <span>{formatCurrency(donationAmount, currency, locale.currentLocale)}</span> for a
            total donation of{' '}
            <span>
              {formatCurrency(
                donationAmount + (matchedAmount ?? 0),
                currency,
                locale.currentLocale,
              )}
            </span>
            .
          </div>
        </div>
        <div className="ms-cta-subtext">
          Your donation will show as matched when the company makes their payment to the cause. You
          can view the status of your donation in{' '}
          <Link to="/dashboard?section=activity">My Activity</Link>.
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default VolunteerForDollarsDetails;
