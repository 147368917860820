import React, { ReactNode } from 'react';
import './ModalFooterActionContainer.scss';

interface IProps {
  /** Pass buttons / actions to this */
  children: ReactNode;
}

function ModalFooterActionContainer(props: IProps) {
  return <div className="ModalFooterActionContainer">{props.children}</div>;
}

export default ModalFooterActionContainer;
