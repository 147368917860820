import { Config } from '@gigit/config';
import { ILogMessageBasic } from '@gigit/interfaces';
import axios from 'axios';

export namespace loggerRequestActions {
  /** Logs a message to the Logger API.
   * This allows the FE to log messages for debugging.
   */
  export async function log(payload: ILogMessageBasic) {
    try {
      payload.user_agent = navigator.userAgent;
      await axios.post(Config.web.REACT_APP_LOGGER_API + '/logs', payload).then((response) => {});
    } catch (err: any) {
      console.error(`Got Error ${err.message ?? err} when logging to logging service`);
    }
  }
}
