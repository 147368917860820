import React, { useEffect, useState } from 'react';
import { IEventSummaryFE, IHub, IStoreItem } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import {
  accountRequestActions,
  eventRequestActions,
  hubRequestActions,
} from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import './HubManagementEvents.scss';
import {
  assignableEventStatuses,
  defaultCurrency,
  errorHelpers,
  formatCurrency,
  round,
  typeHelpers,
} from '../../../../helpers';
import Table, { ITableProps } from '../../../shared/Table/Table';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Constants } from '@gigit/constants';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { localeConstants, uiConstants } from '../../../../constants';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import ChangeStatusMenu from '../../../ChangeStatusMenu/ChangeStatusMenu';
import { useHistory } from 'react-router-dom';
import Dropdown from '../../../Dropdown/Dropdown';
import { changeEventStatus, createEvent } from '../../../../actions/event';
import { Config } from '@gigit/config';
import HubManagementSpotlights from '../HubManagementSpotlights/HubManagementSpotlights';

interface IProps {
  hub?: IHub;
}

function HubManagementEvents(props: IProps) {
  const hub = props.hub;
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const [isCheckLoading, setIsCheckLoading] = useState<boolean>(false);
  const [hubEvents, setHubEvents] = useState<IEventSummaryFE[]>([]);
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const history = useHistory();

  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  function renderFundraisingProgressBar(event: IEventSummaryFE) {
    const currency =
      event.group?.account?.currency ?? event.hub?.account?.currency ?? defaultCurrency;
    const raised = event.raised ?? 0;
    const goal = event.goal ?? 0;
    const percent = goal ? round((raised / goal) * 100, 0) : 0;

    return (
      <div className="fundraising-progress-bar-container">
        <div className="fundraising-progress-bar">
          <div
            className="progress-fill"
            style={{ width: `${percent}%` }}
          />
          <span
            notranslate="yes"
            className="raised-amount"
          >
            {formatCurrency(raised, currency, locale, { decimal_places: 0 })}
          </span>
          <span
            notranslate="yes"
            className="goal-amount"
          >
            {formatCurrency(goal, currency, locale, { decimal_places: 0 })}
          </span>
        </div>
        <span className="percent">
          <var data-var="percent_raised">{percent}</var>
          {'% Raised'}
        </span>
      </div>
    );
  }

  async function changeEventStatus(id: string, status: string) {
    try {
      await eventRequestActions.changeEventStatus(id, status);
      refreshTableData();
    } catch (e) {
      dispatchToastError(e, 'Change Event Status');
    }
  }

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  async function duplicateEvent(eventId: string) {
    try {
      typeHelpers.assertNotNullOrUndefined(hub?.id, 'Hub Id');
      const duplicatedEvent = await hubRequestActions.duplicateCompanyEvent(hub?.id, { eventId });
      const OriginalEvent = hubEvents.find((event) => event.id === eventId);
      const translatedString = localizeHelpers.translate('has been duplicated.');
      const notranslate = `${OriginalEvent?.title}`;
      dispatchToastSuccess(`${notranslate} ${translatedString}`, 'Event duplicated successfully.', [
        {
          link: {
            text: 'Ready to make changes? Check out your new event!',
            link: `/event/${duplicatedEvent?.handle}`,
          },
        },
      ]);
      refreshTableData();
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      dispatchToastError(errorObject.translatedMessage, 'Duplicate Event');
    }
  }

  const hubManagementEventsTableProps: ITableProps<IEventSummaryFE> = {
    columns: [
      {
        id: 'title',
        Header: 'Event Name',
        accessor: ({ title }) => title,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ title }) => {
          return (
            <div
              className="item-name-col"
              notranslate="yes"
            >
              <span>{title}</span>
            </div>
          );
        },
      },
      {
        id: 'status',
        Header: 'Event Status',
        accessor: ({ status }) => status?.code,
        Cell: (item) => (
          <Dropdown
            options={assignableEventStatuses.map((status) => {
              return { id: `${status}`, label: status, value: status };
            })}
            shouldSort={true}
            value={item?.status?.code}
            name="Status"
            onChange={(e) => {
              changeEventStatus(item.id, e.target.value);
            }}
          />
        ),
      },
      {
        id: 'start_date',
        Header: 'Start Date',
        accessor: ({ start_date }) => start_date,
        notranslate: 'yes',
        Cell: ({ start_date }) => {
          return (
            <div
              className="col start-date"
              notranslate="yes"
            >
              <span className="date">
                {localizeHelpers.formatDate(start_date, LocaleDateFormats.LL, locale)}
              </span>
            </div>
          );
        },
      },
      {
        id: 'end_date',
        Header: 'End Date',
        accessor: ({ end_date }) => end_date,
        notranslate: 'yes',
        Cell: ({ end_date }) => {
          return (
            <div
              className="col start-date"
              notranslate="yes"
            >
              <span className="date">
                {localizeHelpers.formatDate(end_date, LocaleDateFormats.LL, locale)}
              </span>
            </div>
          );
        },
      },
      {
        id: 'fundraising_progress',
        Header: 'Fundraising Progress',
        Cell: (item) => {
          return renderFundraisingProgressBar(item);
        },
      },
      {
        id: 'revenue',
        Header: 'Total Revenue',
        Cell: (item) => {
          return (
            <div
              className="col total-revenue total-revenue-amount"
              notranslate="yes"
            >
              {formatCurrency(
                item.raised ?? 0,
                item.group?.account?.currency ?? item.hub?.account?.currency ?? defaultCurrency,
                locale,
              )}
            </div>
          );
        },
      },
    ],
    filterOptions: {
      enableTableSearch: true,
    },
    tableActionOptions: {
      enableRowContextMenuActions: true,
      tableActions: [
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Duplicate',
          icon: 'fal fa-copy',
          onClick: (_, eventItem) => {
            duplicateEvent(eventItem.id);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'View',
          icon: 'fal fa-file-alt',
          onClick: (_, eventItem) => {
            history.push(`/event/${eventItem.handle}/`);
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Manage',
          icon: 'fal fa-user-cog',
          onClick: (_, eventItem) => {
            history.push(`/event/${eventItem.handle}/admin`);
          },
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        try {
          typeHelpers.assertNotNullOrUndefined(props.hub?.id);
          const response = await hubRequestActions.getHubEvents(props.hub?.id, queryParams);
          setHubEvents(response);
          return response;
        } catch (error) {
          dispatchToastError(error, 'Get Hub Events');
          return [];
        }
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any events",
    },
  };

  const canCreateHubEvent = async () => {
    setIsCheckLoading(true);
    if (props.hub?.id) {
      const currentAccount = await accountRequestActions.getCurrentAccountPublicSummary(
        uiConstants.ownerType.hub,
        props.hub?.id,
      );

      const hasStripe = !!currentAccount?.charges_enabled;

      const message = localizeHelpers.translate(
        'In order to create Company events, you must first configure your account to accept payments, or connect an existing Stripe account. Go to Balance and Payouts to manage your Stripe settings.',
      );

      if (!hasStripe) {
        dispatchToastError(message, 'Company Events', [
          {
            onClick: () => {
              history.push(`/company/${props.hub?.handle}/admin?t=balance-and-payouts`);
            },
            text: 'Balance & Payouts',
          },
        ]);
        setIsCheckLoading(false);
        return false;
      }
    } else {
      setIsCheckLoading(false);
      return false;
    }
    setIsCheckLoading(false);
    return true;
  };

  const createCompanyEvent = async () => {
    const canCreate = await canCreateHubEvent();

    if (canCreate) {
      history.push(
        `/onboarding/event/eventTypeSelection?createdFor=company&type=custom&hubId=${hub?.id}`,
      );
    }
  };

  return (
    <>
      <div
        className="HubManagementEvents"
        id="events"
      >
        <div className="header-section">
          <h2>Events</h2>
          <div className="header-actions">
            <Button
              type="button"
              text="Create Event"
              icon="fal fa-plus"
              loading={isCheckLoading}
              onClick={() => {
                createCompanyEvent();
              }}
            />
          </div>
        </div>
        <div className="table-section">
          <Table
            {...hubManagementEventsTableProps}
            refreshTableIncrementor={refreshTableIncrementor}
          />
        </div>
        {Config.feature_flags.HUB_DONATION_MATCHING && (
          <>
            <hr />
            <HubManagementSpotlights
              spotlightListConfig={{
                type: 'event',
                hubId: props.hub?.id ?? '',
                typeEntities: hubEvents,
              }}
            />
          </>
        )}
      </div>
    </>
  );
}

export default HubManagementEvents;
