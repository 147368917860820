import React, { useEffect, useState } from 'react';
import { ICause, IHub } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import './HubManagementFocusAreas.scss';
import TextField from '../../../TextField/TextField';
import useToastDispatcher from '../../../../hooks/useToaster';
import { causeRequestActions, hubRequestActions } from '../../../../requestActions';
import Loader from '../../../Loader/Loader';
import PopupMenu, { IPopupMenuItem } from '../../../shared/PopupMenu/PopupMenu';
import Modal from '../../../Modal/Modal';
import { CauseSelection } from '../../../shared/Onboarding/CausesSelection/CauseSelection';
import { onSelectCause } from '../../../shared/Onboarding/helpers';
import { useCauseInfo } from '../../../shared/Onboarding/hooks/useCauseInfo';
import { Prompt } from '../../../Prompt/Prompt';
import { localizeHelpers } from '../../../../localizeHelpers';
interface IProps {
  hub?: IHub;
}

const HubManagementFocusAreas: React.FC<IProps> = (props) => {
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [searchTimeout, setSearchTimeout] = useState<ReturnType<typeof setTimeout>>();
  const [activeSort, setActiveSort] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loadingHubFocusAreas, setLoadingHubFocusAreas] = useState<boolean>(false);
  const [loadingToAddFocusAreas, setLoadingToAddFocusAreas] = useState<boolean>(false);
  const [hubFocusAreas, setHubFocusAreas] = useState<ICause[]>([]);
  const [hubFocusAreaToDelete, setHubFocusAreaToDelete] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [loadingFilteredFocusAreas, setLoadingFilteredFocusAreas] = useState<boolean>(false);
  const [focusAreas, setFocusAreas] = useState<ICause[]>([]);
  const [loadingFocusAreas, setLoadingFocusAreas] = useState<boolean>(false);
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { causeInfo, handleCauseInfo } = useCauseInfo();
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  async function getFocusAreas() {
    try {
      setLoadingFocusAreas(true);
      const result = await causeRequestActions.getCauses();
      setFocusAreas(result);
    } catch (error) {
      dispatchToastError(error, 'Get Focus Areas');
    } finally {
      setLoadingFocusAreas(false);
    }
  }

  async function getFilteredFocusAreas() {
    if (!loadingHubFocusAreas && hubFocusAreas.length !== 0) {
      try {
        setLoadingFilteredFocusAreas(true);
        const filteredFocusAreas = focusAreas.filter(
          (focusArea) => !hubFocusAreas.includes(focusArea),
        );
        causeInfo.causes = filteredFocusAreas;
      } catch (error) {
        dispatchToastError(error, 'Get Focus Areas To Add');
      } finally {
        setLoadingFilteredFocusAreas(false);
      }
    } else if (hubFocusAreas.length === 0) {
      causeInfo.causes = focusAreas;
    }
  }

  function compareFocusAreas(a: ICause, b: ICause) {
    const focusAreaA = a.cause.toUpperCase();
    const focusAreaB = b.cause.toUpperCase();

    let comparison = 0;

    if (focusAreaA > focusAreaB) {
      comparison = 1;
    } else if (focusAreaA < focusAreaB) {
      comparison = -1;
    }

    if (activeSort) {
      return comparison * -1;
    } else {
      return comparison;
    }
  }

  async function getHubFocusAreas() {
    if (props?.hub?.id) {
      try {
        setLoadingHubFocusAreas(true);
        const result = await hubRequestActions.getHubByID(props.hub.id);
        const tmpFocusAreaArray: string[] = result.causes ? result.causes : [];
        let tmpHubFocusAreasArray: ICause[] = [];
        tmpFocusAreaArray.map((tmpFocusAreaId) => {
          const tmpFocusArea = focusAreas.find((focusArea) => focusArea.id === tmpFocusAreaId);
          if (tmpFocusArea) {
            tmpHubFocusAreasArray.push(tmpFocusArea);
          }
        });
        if (searchValue !== '' && searchValue !== null) {
          const searchResult = tmpHubFocusAreasArray.filter((focusArea) =>
            focusArea.cause.toLowerCase().includes(searchValue.toLowerCase()),
          );
          if (searchResult) {
            tmpHubFocusAreasArray = searchResult;
          }
        }

        const sortedHubFocusAreas = tmpHubFocusAreasArray.sort(compareFocusAreas);
        setHubFocusAreas(sortedHubFocusAreas);
      } catch (error) {
        dispatchToastError(error, 'Get Hub Focus Areas');
      } finally {
        setLoadingHubFocusAreas(false);
      }
    }
  }

  async function addHubFocusAreas() {
    if (props?.hub?.id && causeInfo.selectedCauses.length > 0) {
      const tmpHub = props.hub;
      const tmpFocusAreaArray: string[] = hubFocusAreas?.map((focusArea) => {
        return focusArea.id ? focusArea.id : '';
      });
      const toAddFocusAreas = tmpFocusAreaArray.concat(causeInfo.selectedCauses);
      if (tmpHub.id === props.hub.id) {
        const payload: Partial<IHub> = {
          causes: toAddFocusAreas,
        };
        try {
          await hubRequestActions.updateHub(payload, props.hub.id);
          await getHubFocusAreas();
          getFilteredFocusAreas();
          dispatchToastSuccess(
            localizeHelpers.translate('Successfully Added Hub Focus Areas'),
            'Add Hub Focus Areas',
          );
        } catch (error) {
          dispatchToastError(error, 'Add Hub Focus Areas');
        } finally {
          causeInfo.selectedCauses = [];
        }
      }
    }
  }

  async function removeHubFocusAreas() {
    if (props?.hub?.id && hubFocusAreaToDelete) {
      const toDeleteFocusArea = hubFocusAreas.find(
        (focusArea) => focusArea.id === hubFocusAreaToDelete.id,
      );
      if (toDeleteFocusArea) {
        const tmpHub = props.hub;
        const tmpFocusAreaArray: string[] = hubFocusAreas?.map((focusArea) => {
          return focusArea.id ? focusArea.id : '';
        });
        const toDeleteFocusAreaIndex = tmpFocusAreaArray.indexOf(hubFocusAreaToDelete.id);
        tmpFocusAreaArray.splice(toDeleteFocusAreaIndex, 1);
        if (tmpHub) {
          const payload: Partial<IHub> = {
            causes: tmpFocusAreaArray,
          };
          try {
            await hubRequestActions.updateHub(payload, props.hub.id);
            await getHubFocusAreas();
            dispatchToastSuccess('Successfully Deleted Hub Focus Area', 'Delete Hub Focus Area');
            setHubFocusAreaToDelete(null);
            setShowContextMenu(false);
          } catch (error) {
            dispatchToastError(error, 'Remove Hub Focus Area');
          }
        }
      }
    }
  }

  useEffect(() => {
    getFocusAreas();
  }, [props.hub]);

  useEffect(() => {
    getHubFocusAreas();
  }, [props.hub, focusAreas, activeSort]);

  useEffect(() => {
    getFilteredFocusAreas();
  }, [focusAreas, hubFocusAreas]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (searchValue !== null) {
      setSearchTimeout(
        setTimeout(() => {
          getHubFocusAreas();
        }, 500),
      );
    }

    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchValue]);

  const contextMenuItems: IPopupMenuItem[] = [
    {
      id: 'remove',
      label: 'Remove Focus Area',
      icon: 'fas fa-trash',
      onClick: async (focusArea: ICause) => {
        if (focusArea?.id) {
          setHubFocusAreaToDelete({
            id: focusArea.id,
            name: focusArea.cause,
          });
        }
      },
    },
  ];

  return (
    <>
      <div
        className="HubManagementFocusAreas"
        id="focus-areas"
      >
        <div className="header-section">
          <h2>Focus Areas</h2>
          <Button
            type="button"
            text="Add Focus Areas"
            icon="fal fa-plus"
            onClick={async () => {
              await getFilteredFocusAreas();
              setShowModal(true);
            }}
          />
        </div>

        <div className="filter-section">
          <TextField
            autoComplete="off"
            placeholder="Search Focus Areas..."
            icon="fal fa-search"
            value={searchValue || ''}
            name="search"
            type="text"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />

          <span
            className="sort"
            onClick={() => {
              setActiveSort(!activeSort);
            }}
          >
            <i className="fas fa-sort-alt" />
          </span>
        </div>

        <span className="section-title">Focus Area</span>
        <div className="table-section">
          {loadingFocusAreas && <Loader loading={loadingFocusAreas} />}
          {!loadingHubFocusAreas &&
            hubFocusAreas.map((focusArea, index) => (
              <div
                className="hub-focus-area"
                key={index}
              >
                <span
                  className="focus-area-name"
                  key={index}
                >
                  {focusArea.cause}
                </span>
                <PopupMenu
                  showMenuConfig={{
                    showMenu: activeIndex === index && showContextMenu,
                    setShowMenu: setShowContextMenu,
                    position: {
                      type: 'bottom',
                    },
                  }}
                  menuItems={contextMenuItems.map((item) => {
                    return {
                      ...item,
                      onClickParam: focusArea,
                    };
                  })}
                  popupMenuClass="HubManagementFocusAreas-popup-menu"
                  className={`context-menu-container ${showContextMenu ? 'show' : ''}`}
                  onClick={() => {
                    setActiveIndex(index);
                    setShowContextMenu(!showContextMenu);
                  }}
                  onSelect={() => setShowContextMenu(false)}
                >
                  <i className="fal fa-ellipsis-h-alt" />
                </PopupMenu>
              </div>
            ))}
        </div>

        <Modal
          onClose={() => {
            setShowModal(false);
          }}
          show={showModal}
          contentClassName="add-focus-areas-modal"
          closeIcon="fas fa-times"
        >
          <div className="modal-header">
            <h2>Add Focus Areas</h2>
          </div>
          <div className="modal-main">
            {loadingToAddFocusAreas && <Loader loading={loadingToAddFocusAreas} />}
            {!loadingToAddFocusAreas && (
              <CauseSelection
                causeInfo={causeInfo}
                onChange={(value: string) => onSelectCause(causeInfo, value, handleCauseInfo)}
              />
            )}
          </div>
          <div className="button-container">
            <Button
              text="Cancel"
              type="button"
              buttonType="secondary"
              onClick={() => {
                setShowModal(false);
              }}
            />
            <Button
              text="Add Focus Areas"
              type="button"
              buttonType="dark"
              onClick={async () => {
                await addHubFocusAreas();
                setShowModal(false);
              }}
            />
          </div>
        </Modal>
      </div>
      <Prompt
        show={hubFocusAreaToDelete !== null}
        title="Delete Focus Area"
        message={`Are you sure you want to delete focus area ${hubFocusAreaToDelete?.name || ''}?`}
        yesMessage="Yes"
        yesClass="fa fa-trash"
        yesStyle="delete"
        cancelMessage="Cancel"
        onYes={async () => removeHubFocusAreas()}
        onClose={() => setHubFocusAreaToDelete(null)}
      />
    </>
  );
};

export default HubManagementFocusAreas;
