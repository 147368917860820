import './DonationMatchingProgramDetails.scss';
import React, { FC } from 'react';
import { IAvailableDonationMatchingProgram } from '@gigit/interfaces';
import { formatCurrency } from '../../../../helpers';
import { useLocale } from '../../../../hooks';
import { localizeHelpers, LocaleDateFormats } from '../../../../localizeHelpers';
import { FormSlider } from '../../../../components/shared/Onboarding/FormSlider/FormSlider';
import TextField from '../../../../components/TextField/TextField';
import Portrait from '../../../../components/Portrait/Portrait';
import { Link } from 'react-router-dom';
import { uiConstants } from '../../../../constants';

interface IProps {
  program: IAvailableDonationMatchingProgram;
  currency: string;
  donationAmount: number;
  matchedAmount: number;
  setMatchedAmount(v: number): void;
}

const DonationMatchingProgramDetails: FC<IProps> = (props) => {
  const { program, currency, donationAmount, matchedAmount, setMatchedAmount } = props;
  const locale = useLocale();

  return (
    <div className="DonationMatchingProgramDetails">
      <div className="donation-matching-details">
        <div className="donation-matching-inner">
          <div className="dm-title">{program?.name}</div>
          <div className="donation-matching-content">
            <div className="dm-icon-wrap">
              <div className="dm-icon">
                <i className="fa fa-hand-holding-heart"></i>
              </div>
            </div>
            <div className="dm-text">
              {program?.hub.title} will match donations up to{' '}
              {formatCurrency(program?.donation_max_amount, currency, locale.currentLocale)} at a
              ratio of {program?.ratio}:1 until{' '}
              {localizeHelpers.formatDate(
                program?.end_date ?? new Date(),
                LocaleDateFormats.ll,
                locale.currentLocale,
              )}
              .
            </div>
          </div>
          <div className="dm-progress">
            <div className="dm-bar">
              {/* Use this to show the progress of the current total donated amount*/}
              <div
                className="dm-bar-fill"
                style={{
                  width: `${
                    (program?.available_donation_match.employee_contribution ?? 0) /
                    (program?.available_donation_match.remaining_employee_matchable ?? 0)
                  }%`,
                }}
              ></div>
              {/* Use this to show how much the users donation increases the bar*/}
              <div
                className="dm-bar-fill-user"
                style={{
                  width: `${
                    donationAmount /
                    (program?.available_donation_match.remaining_employee_matchable ?? 0)
                  }%`,
                }}
              ></div>
            </div>
            <div className="dm-amounts">
              <div className="dm-contributed">
                <span>
                  {formatCurrency(
                    program?.available_donation_match.employee_contribution,
                    currency,
                    locale.currentLocale,
                  )}
                </span>
                <span>Contributed</span>
              </div>
              <div className="dm-remaining">
                <span>
                  {formatCurrency(
                    program?.available_donation_match.remaining_employee_matchable,
                    currency,
                    locale.currentLocale,
                  )}
                </span>
                <span>Remaining</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-title">Matching Gift</div>
      <div className="match-text">How much of your donation would you like matched?</div>
      <div className="match-slider">
        <FormSlider
          label={null}
          defaultValue={matchedAmount}
          min={0}
          max={
            program?.available_donation_match.max_amount_matchable ?? uiConstants.maxAmountMatchable
          }
          step={1}
          onChange={(value: number) => setMatchedAmount(value)}
        />
        <div className="option">
          <div className="option-inner">$</div>
          <TextField
            pattern="^[0-9]{8}([,.][0-9]{8})+$"
            value={matchedAmount}
            name="goal"
            label=""
            type="number"
            min="0"
            max={(
              program?.available_donation_match.max_amount_matchable ??
              uiConstants.maxAmountMatchable
            ).toString()}
            onChange={(e) => setMatchedAmount(parseFloat(e.target.value))}
          />
        </div>
      </div>
      <div className="match-summary">
        <div className="ms-row">
          <span className="ms-label">My Donation</span>
          <span className="ms-value">
            {formatCurrency(donationAmount, currency, locale.currentLocale)}
          </span>
        </div>
        <div className="ms-row">
          <span className="ms-label">Matched Amount</span>
          <span className="ms-value">
            {formatCurrency(matchedAmount, currency, locale.currentLocale)}
          </span>
        </div>
        <div className="ms-row ms-total">
          <span className="ms-label">Total Donation</span>
          <span className="ms-value">
            {formatCurrency(donationAmount + (matchedAmount ?? 0), currency, locale.currentLocale)}
          </span>
        </div>
        <div className="ms-cta">
          <div className="portrait-wrap">
            <Portrait
              size={50}
              source={program?.hub.profile_image_url}
            />
          </div>
          <div className="ms-cta-text">
            {program?.hub.title} is now matching your donation of{' '}
            <span>{formatCurrency(donationAmount, currency, locale.currentLocale)}</span> for a
            total donation of{' '}
            <span>
              {formatCurrency(
                donationAmount + (matchedAmount ?? 0),
                currency,
                locale.currentLocale,
              )}
            </span>
            .
          </div>
        </div>
        <div className="ms-cta-subtext">
          Your donation will show as matched when the company makes their payment to the cause. You
          can view the status of your donation in{' '}
          <Link to="/dashboard?section=activity">My Activity</Link>.
        </div>
      </div>
    </div>
  );
};

export default DonationMatchingProgramDetails;
