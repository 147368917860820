import React from 'react';

export interface IPropsSingleMetric {
  name: string;
  value: string;
}

const SingleMetric: React.FC<IPropsSingleMetric> = (props) => {
  return (
    <div
      className="SingleMetric"
      notranslate="yes"
    >
      <span className="value">{props.value}</span>
      <span className="title">{props.name}</span>
    </div>
  );
};

export default SingleMetric;
