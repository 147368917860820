import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../../store';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import createVolunteerIcon from '../../../assets/footer/create-volunteer.svg';
import createGroupIcon from '../../../assets/footer/create-group.svg';
import createEventIcon from '../../../assets/footer/create-event.svg';
import createHubIcon from '../../../assets/footer/create-hub.svg';

import './IWantToMenu.scss';
import Modal from '../../Modal/Modal';
import ModalScrollContainer from '../../Modal/ModalScrollContainer/ModalScrollContainer';
import LogVolunteerHours from '../../LogVolunteerHours/LogVolunteerHours';
import { IUserState } from '../../../reducers/user';

export interface ISetupItem {
  className: string;
  flowRoute?: string;
  iconName?: string;
  icon?: string;
  title: string;
  onClick?: () => void;
}

interface IProps extends WithTranslation, RouteComponentProps<any> {
  userState: IUserState;
  showMenu: boolean;
  toggleShowMenu(val: boolean): void;
}

interface IState {
  setupItems: Array<ISetupItem>;
  showReportHoursModal: boolean;
  showIWantToMenu: boolean;
}

class IWantToMenu extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      setupItems: [
        {
          className: 'log-hours',
          onClick: () => {
            this.setState({ showIWantToMenu: false, showReportHoursModal: true });
            // this.props.toggleShowMenu(false);
          },
          iconName: 'fal fa-clock',
          title: 'Log Volunteer Hours',
        },
        {
          className: 'event-fundraiser',
          flowRoute: 'event',
          icon: createEventIcon,
          title: 'Create Event Or Fundraiser',
        },
        {
          className: 'create-group',
          flowRoute: 'group',
          icon: createGroupIcon,
          title: 'Claim or Create a Cause Page',
        },
        {
          className: 'volunteer-paid',
          flowRoute: 'gig',
          icon: createVolunteerIcon,
          title: 'Create Volunteer Opportunity',
        },
        {
          className: 'create-hub',
          flowRoute: 'company',
          icon: createHubIcon,
          title: 'Create a Company Page',
        },
      ],
      showReportHoursModal: false,
      showIWantToMenu: props.showMenu,
    };
  }

  render() {
    return (
      <>
        <Modal
          class="LogVolunteerHoursModal"
          show={this.state.showReportHoursModal}
          onClose={() => {
            this.setState({ showReportHoursModal: false });
            this.props.toggleShowMenu(false);
          }}
        >
          <LogVolunteerHours
            onHoursSubmitted={() => this.setState({ showReportHoursModal: false })}
          />
        </Modal>

        <Modal
          show={this.state.showIWantToMenu}
          title="I want to..."
          onClose={() => this.props.toggleShowMenu(false)}
        >
          {/* <div className="IWantToMenu"> */}
          <ModalScrollContainer className="IWantToMenu">
            <ul>
              {this.state.setupItems.map((item, idx) => {
                if (item.className === 'log-hours' && !this.props.userState.isLoggedIn) {
                  return null;
                }
                return (
                  <li
                    key={idx}
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick();
                        return;
                      }
                      this.props.history.push(`/onboarding/${item.flowRoute}`);
                      this.props.toggleShowMenu(false);
                    }}
                  >
                    <span className={`list-circle ${item.className}`}>
                      {item.iconName && <i className={item.iconName} />}
                      {item.icon && (
                        <img
                          src={item.icon}
                          alt={item.title}
                        />
                      )}
                    </span>
                    <span
                      className="title"
                      notranslate="yes"
                    >
                      {item.title}
                    </span>
                  </li>
                );
              })}
            </ul>
          </ModalScrollContainer>

          {/* </div> */}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

const mapDispatchToProps = {};

export default withRouter(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(IWantToMenu)),
);
