import React, { ReactNode } from 'react';
import Joyride from 'react-joyride';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Config } from '@gigit/config';
import queryString from 'query-string';
import {
  IGroup,
  IPage,
  IPageComponent,
  ICampaign,
  IUserRoleJoin,
  IGroupSummary,
  IUserRole,
} from '@gigit/interfaces';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {
  defaultCurrency,
  IStringMap,
  mapPermissions,
  setSEOMetatags,
  toastError,
  swapRouteParams,
  routes,
  isPageActive,
  toastInfo,
} from '../../helpers';
import errorHelpers from '../../helpers/errorHelpers';
import { createToast } from '../../actions/toaster';
import { setCartCurrency } from '../../actions/cart';
import { IAppState } from '../../store';
import { ICartState } from '../../reducers/cart';
import { initialGroupState } from '../../reducers/group';
import { IUserState } from '../../reducers/user';
import { getUserGroupApplications, getUserGroups } from '../../actions/user';
import { Constants, GroupManagePermissions } from '@gigit/constants';
import {
  createGroup,
  updateGroup,
  getGroup,
  createGroupPage,
  updateGroupPage,
  deleteGroupPage,
  getCurrentUserGroupRole,
  createGroupPageComponent,
  getCurrentGroupPageComponents,
  updateGroupPageComponent,
  joinGroup,
  updateGroupCoverImage,
  updateGroupProfileImage,
  getGroupEvents,
  volunteerForGroup,
  changeGroupStatus,
} from '../../actions/group';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import Share from '../../components/Share/Share';
import Login from '../../routes/Login/Login';
import './Group.scss';
import { BackSplash } from '../../components/ProfilePageComponents/BackSplash/BackSplash';
import { PageSidebar } from '../../components/ProfilePageComponents/PageSidebar/PageSidebar';
import PageHeader from '../../components/ProfilePageComponents/PageHeader/PageHeader';
import { PageBody } from '../../components/ProfilePageComponents/PageBody/PageBody';
import PageCoverImage from '../../components/ProfilePageComponents/PageCoverImage/PageCoverImage';
import { PageContainer } from '../../components/ProfilePageComponents/PageContainer/PageContainer';
import { PageSelector } from '../../components/ProfilePageComponents/PageSelector/PageSelector';
import PageContent from '../../components/ProfilePageComponents/PageContent/PageContent';
import { IFloatingAction } from '../../components/ProfilePageComponents/ProfileFloatingActions/ProfileFloatingActions';
import FundraiserProgressBar from '../../components/ProfilePageComponents/FundraiserProgressBar/FundraiserProgressBar';
import LinkButton from '../../components/ProfilePageComponents/LinkButton/LinkButton';
import ManageTabsModal from '../../components/ProfilePageComponents/PageSelector/ManageTabsModal/ManageTabsModal';
import ContactUsModal from '../../components/ContactUsModal/ContactUsModal';
import { IOwnerObject, IToast } from '../../interfaces';
import typeHelpers from '../../helpers/typeHelpers';
import axios from 'axios';
import { componentConfig } from '../../components/ComponentLibrary/config';
import { userSelectors } from '../../selectors/user';
import { groupRequestActions, userRequestActions } from '../../requestActions';
import { uiConstants } from '../../constants';
import ExternalVolunteerApplicationWarning from '../../components/shared/ExternalVolunteerApplicationWarning/ExternalVolunteerApplicationWarning';
import VolunteerApplicationForm from '../../components/VolunteerApplicationForm/VolunteerApplicationForm';
import { localizeHelpers } from '../../localizeHelpers';
import ContactOrganizerModal from '../../components/ContactOrganizerModal/ContactOrganizerModal';

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  cartState: ICartState;
  isLoggedIn: boolean;
  getUserGroupApplications(groupId: string): void;
  createGroup(payload: any): void;
  getGroup(handle: string): void;
  updateGroup(payload: any, groupId: string, pages?: any): void;
  createGroupPage(groupId: string, payload: any): void;
  updateGroupPage(groupId: string, id: string, payload: any): void;
  deleteGroupPage(groupId: string, id: string): void;
  getCurrentUserGroupRole(groupId: string): void;
  createGroupPageComponent(groupId: string, id: string, payload: any): void;
  getCurrentGroupPageComponents(groupId: string, pageId: string): void;
  updateGroupPageComponent(
    groupId: string,
    pageId: string,
    componentId: string,
    payload: any,
  ): void;
  getGroupEvents(groupId: string): void;
  joinGroup(groupId: string): void;
  updateGroupCoverImage(group: IGroupSummary, img: string): void;
  updateGroupProfileImage(group: IGroupSummary, img: string): void;
  volunteerForGroup(groupId: string, payload: any): void;
  createToast(toast: IToast): void;
  changeGroupStatus(
    groupId: string,
    status: string,
    title: string,
    handle: string,
    callback?: (updatedGroup: IGroup) => void,
  ): void;
  setCartCurrency(currency: string): void;

  /** Used to render optional child content. */
  children?: (ownerObj: IOwnerObject) => ReactNode;
}

interface IStep {
  target: string;
  content: string;
  disableBeacon?: boolean;
  event?: string;
}

interface IState {
  currentPage: number;
  params: any;
  steps: IStep[];
  group: IGroupSummary;
  groupPages: IPage[];
  groupCampaign: ICampaign | null;
  pageComponents: any;
  pendingCreation: boolean;
  pendingJoin: boolean;
  mounted: boolean;
  userPermissions: IStringMap;
  userCapacity: string[];
  showAddComponent: boolean;
  showLoginModal: boolean;
  showShareModal: boolean;
  showManageModal: boolean;
  width: number;
  height: number;
  resize: boolean;
  mobileContext: boolean;
  groupManagers: string[];
  redirect: boolean;
  manageTour: boolean;
  registerRedirectUrl: string;
  registerFunction: string;
  showOnboardDonation: boolean;
  showOnboardVolunteer: boolean;
  showGroupOnboard: boolean;
  groupOnboardStep: number;
  showContactUsModal: boolean;
  loading: boolean;
  pageLoading: boolean;
  isFollowingGroup: boolean;
  showExternalVolunteerApplicationWarning: boolean;
  showVolunteerFormModal: boolean;
}

class Group extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let _params = queryString.parse(this.props.location.search);

    this.state = {
      pendingCreation: false,
      pendingJoin: false,
      currentPage: _params.tab ? Math.max(Number(_params.tab) - 1, 0) : 0,
      params: {},
      steps: [
        {
          disableBeacon: true,
          target: '.PageSidebar .Portrait .profile',
          content: 'Click here to upload a profile image',
        },
        {
          disableBeacon: true,
          target: '.CoverImage',
          content: 'Upload a cover image to make your page more engaging',
        },
        {
          disableBeacon: true,
          target: '.PageSelector',
          content: 'Add, edit and re-order new pages',
        },
        {
          disableBeacon: true,
          target: '.add-component',
          content: 'Easily add components to pages to customize your page',
        },
        {
          disableBeacon: true,
          target: '.PageBody .PageCoverImage .float',
          content: 'Manage your entire organization in one place!',
        },
      ],
      group: initialGroupState.group,
      groupPages: [],
      groupCampaign: null,
      userPermissions: {},
      pageComponents: [],
      mounted: false,
      showAddComponent: false,
      showLoginModal: false,
      showShareModal: false,
      width: window.innerWidth,
      height: window.innerHeight,
      resize: false,
      mobileContext: false,
      groupManagers: [],
      redirect: false,
      manageTour: false,
      registerRedirectUrl: '',
      registerFunction: '',
      showOnboardDonation: false,
      showOnboardVolunteer: false,
      showGroupOnboard: false,
      groupOnboardStep: 1,
      showManageModal: false,
      showContactUsModal: false,
      loading: true,
      pageLoading: true,
      isFollowingGroup: false,
      userCapacity: [],
      showExternalVolunteerApplicationWarning: false,
      showVolunteerFormModal: false,
    };

    this.setPage = this.setPage.bind(this);
    this.saveGroup = this.saveGroup.bind(this);
    this.saveCurrentPage = this.saveCurrentPage.bind(this);
    this.handleGroupInputChange = this.handleGroupInputChange.bind(this);
    this.canEditGroup = this.canEditGroup.bind(this);
    this.createGroupPageComponent = this.createGroupPageComponent.bind(this);
    this.onAddComponent = this.onAddComponent.bind(this);
    this.onCancelAdd = this.onCancelAdd.bind(this);
    this.toggleAddComponent = this.toggleAddComponent.bind(this);
    this.initGroupPage = this.initGroupPage.bind(this);
    this.joinGroup = this.joinGroup.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.toggleAddComponent = this.toggleAddComponent.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.hasManagePermission = this.hasManagePermission.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.createNewPage = this.createNewPage.bind(this);
    this.updatePage = this.updatePage.bind(this);
    this.deletePage = this.deletePage.bind(this);
    this.getUserRole = this.getUserRole.bind(this);
    this.getCampaign = this.getCampaign.bind(this);
    this.getGroup = this.getGroup.bind(this);
    this.getPages = this.getPages.bind(this);
    this.getPageContent = this.getPageContent.bind(this);
    this.getGroupContactInfo = this.getGroupContactInfo.bind(this);
    this.onDeleteComponent = this.onDeleteComponent.bind(this);
    this.onComponentUpdate = this.onComponentUpdate.bind(this);
    this.followGroup = this.followGroup.bind(this);
    this.renderVolunteerButton = this.renderVolunteerButton.bind(this);
    this.renderDonateButton = this.renderDonateButton.bind(this);
    this.renderFundraiseButton = this.renderFundraiseButton.bind(this);
  }

  showNotActivePageWarning() {
    this.props.createToast(
      toastInfo(localizeHelpers.translate(uiConstants.warning.page_not_active), 'Warning'),
    );
    this.props.history.push('/');
  }

  async initGroupPage() {
    let group: IGroupSummary | null = null;
    try {
      group = await this.getGroup();
    } catch (e) {
      this.redirect();
    }

    if (group) {
      let userRoleName: IUserRoleJoin;

      /**
       * Here we check to see if the user can access a 'draft' page.
       * a user can access a draft page if they have the proper admin/on-behalf-of role
       * if a user doesn't have one of those roles or is logged out, we redirect them to the home page
       */
      if (
        !this.props.location.pathname.includes('/accept_invite') &&
        !this.props.location.pathname.includes('/cancel_invite') &&
        this.props.userState.isLoggedIn === false &&
        group?.status?.code === Constants.group_status.draft
      ) {
        this.showNotActivePageWarning();
      }
      try {
        if (
          this.props.userState.isLoggedIn &&
          group?.status?.code === Constants.group_status.draft
        ) {
          userRoleName = await userRequestActions.getUserGroupRole(group.id);
          if (
            !this.props.location.pathname.includes('/accept_invite') &&
            !this.props.location.pathname.includes('/cancel_invite') &&
            !isPageActive({
              status: group?.status?.code,
              isUserLoggedIn: this.props.userState.isLoggedIn,
              roleName: userRoleName.role_name,
            })
          ) {
            this.showNotActivePageWarning();
          }
        }
      } catch (err) {
        const errorObj = errorHelpers.getErrorObject(err);
        if (
          errorObj.errorCode !== 'ERROR.ROLES.NO_OBJECT_PERMISSIONS' &&
          errorObj.errorCode !== 'ERROR.AUTH.UNAUTHORIZED'
        ) {
          this.props.createToast(toastError(errorObj.translatedMessage, 'Cause Members'));
        }
      }

      //we need set card currency because modal "Items Added to your card" is part of the <Header>
      group.account && this.props.setCartCurrency(group.account?.currency);

      this.getPages(group.id)
        .then((pagesResponse) => {
          const pages = pagesResponse.data;

          if (pages.length > 0 && group?.id) {
            this.getPageContent(group.id, pages[0].id)
              .then((content) => {
                if (group) {
                  this.setState(
                    {
                      group,
                      groupPages: pages,
                      pageComponents: content.components,
                    },
                    () => {
                      if (this.state.params.fn && this[this.state.params.fn as keyof Group]) {
                        this[this.state.params.fn as keyof Group]();
                      }

                      if (group?.campaign_id) {
                        this.getCampaign(group.id, group.campaign_id);
                      }

                      if (this.props.isLoggedIn && group?.id) {
                        this.getUserRole(group.id);
                        this.getGroupContactInfo(group.id);
                        this.props.getUserGroupApplications(group.id);
                      }
                    },
                  );
                }
              })
              .catch((response) => {
                this.redirect();
              })
              .finally(() => {
                /*GIG-6034: GG Donations 'Hanging'
                 * Hacky Solution: Update the project count on fetch of the gg group to ensure up to date projects
                 */
                if (this.state.group?.external_id?.GlobalGiving) {
                  groupRequestActions
                    .updateGGProjects(this.state.group.id)
                    .then((response) => {
                      this.setState({
                        group: {
                          ...this.state.group,
                          gg_active_projects_count: response.gg_active_projects_count,
                        },
                      });
                    })
                    .catch((error) => {
                      const errorObj = errorHelpers.getErrorObject(error);
                      let toast = toastError(errorObj.translatedMessage, 'Active Projects');
                      this.props.createToast(toast);
                    })
                    .finally(() => {
                      this.setState({
                        loading: false,
                        pageLoading: false,
                      });
                    });
                } else {
                  this.setState({
                    loading: false,
                    pageLoading: false,
                  });
                }

                if (queryString.parse(this.props.location.search)?.tab) {
                  const tabNumber =
                    (queryString.parse(this.props.location.search)?.tab as string) ?? '1';
                  this.setPage(parseInt(tabNumber) - 1);
                }
              });
          }
        })
        .catch(() => {
          this.redirect();
        });
    }

    this.setState({
      mounted: true,
    });

    let _params = queryString.parse(this.props.location.search);

    this.setState(
      {
        params: _params,
      },
      () => {
        if (this.state.params.help) {
          this.setState({
            showGroupOnboard: true,
          });
        }
      },
    );
  }

  redirect() {
    this.setState({
      redirect: true,
    });
  }

  getUserRole(gId: string): void {
    axios
      .get<IUserRoleJoin>(swapRouteParams(routes.GET_CURRENT_USER_GROUP_ROLE, { groupId: gId }))
      .then((response) => {
        let _permissions: IStringMap = mapPermissions(response.data.permissions);

        this.setState({
          userPermissions: _permissions,
          userCapacity: response.data?.user_capacity || [],
        });
      });
  }

  getCampaign(gId: string, cId: string): void {
    axios
      .get(swapRouteParams(routes.GET_GROUP_CAMPAIGN, { groupId: gId, id: cId }))
      .then((response) => {
        this.setState({
          groupCampaign: response.data,
        });
      });
  }

  async getGroupMembers(groupId: string) {
    let result: IUserRole[] = [];
    try {
      result = await groupRequestActions.getGroupMembers(groupId);
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Get Cause Members');
      this.props.createToast(toast);
    }
    return result;
  }

  async getGroup() {
    let result: IGroupSummary | null = null;
    try {
      result = await groupRequestActions.getGroupByHandleOrId(this.props.match.params.handle);
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Get Cause');
      this.props.createToast(toast);
    }
    return result;
  }

  getPages(id: string) {
    return axios.get(swapRouteParams(routes.GET_GROUP_PAGES, { groupId: id }));
  }

  async getPageContent(gId: string, pId: string, disableLoad?: boolean) {
    try {
      this.setState({
        pageLoading: !disableLoad,
      });

      const response = await axios.get<IPage>(
        swapRouteParams(routes.GET_GROUP_PAGE_COMPONENTS, { groupId: gId, pageId: pId }),
      );
      return response.data;
    } finally {
      this.setState({
        pageLoading: false,
      });
    }
  }

  getGroupContactInfo(gId: string) {
    axios
      .get(swapRouteParams(routes.GET_GROUP_CONVERSATION_MEMBERS, { groupId: gId }))
      .then((response) => {
        this.setState({
          groupManagers: response.data,
        });
      });
  }

  componentDidMount() {
    this.initGroupPage();
    this.updateWindowDimensions();
    (window as any).prerenderReady = false;
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight, resize: true });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.loading && !this.state.loading && !this.props.userState.awaitingToken) {
      if (this.state.group.handle.length === 0) {
        this.setState({
          redirect: true,
        });
      } else {
        setSEOMetatags({
          urlPath: `group/${this.state.group.handle}`,
          title: this.state.group.title,
          description: this.state.group.description,
          imageURL: this.state.group.profile_image_url,
          coverImageURL: this.state.group.cover_image_url,
        });
      }
    }

    if (prevProps.userState.isLoggedIn !== this.props.userState.isLoggedIn) {
      if (
        this.state?.group?.status?.code === Constants.event_status.draft &&
        this.props.userState.isLoggedIn === false
      ) {
        this.showNotActivePageWarning();
      }
    }

    if (
      prevProps.userState.isLoggedIn !== this.props.userState.isLoggedIn &&
      this.props.isLoggedIn
    ) {
      if (this.state.showLoginModal) {
        if (this.state.registerFunction && this[this.state.registerFunction as keyof Group]) {
          this[this.state.registerFunction as keyof Group]();
        }

        this.setState({
          showLoginModal: false,
          registerRedirectUrl: '',
          registerFunction: '',
        });
      }
    }

    if (prevProps.location.key !== this.props.location.key) {
      this.initGroupPage();
    }

    if (this.state.resize) {
      this.setState({
        resize: false,
      });
    }

    if (this.state.pendingCreation && this.props.isLoggedIn) {
      this.setState(
        {
          pendingCreation: false,
        },
        () => {
          this.saveGroup();
        },
      );
    }

    if (this.state.pendingJoin && this.props.isLoggedIn) {
      this.setState(
        {
          pendingJoin: false,
        },
        () => {
          this.joinGroup();
        },
      );
    }

    if (
      this.props.userState.isLoggedIn &&
      this.props.userState.isLoggedIn !== prevProps.userState.isLoggedIn &&
      this.state.group.id !== ''
    ) {
      this.getUserRole(this.state.group.id);
    }

    if (prevState.userPermissions !== this.state.userPermissions) {
      this.setIsFollowingGroup();
    }
  }

  createGroupPageComponent(type: string) {
    if (this.state.groupPages[this.state.currentPage].id && this.state.group.id) {
      axios
        .post(
          swapRouteParams(routes.CREATE_GROUP_PAGE_COMPONENT, {
            groupId: this.state.group.id,
            pageId: this.state.groupPages[this.state.currentPage].id,
          }),
          {
            visibility: 'public',
            sequence: 1,
            title: componentConfig.find((c) => c.type === type)?.title || '',
            component_type: type,
          },
        )
        .then((response) => {
          this.getPageContent(
            this.state.group.id,
            this.state.groupPages[this.state.currentPage].id,
          ).then((content: any) => {
            this.setState({
              pageComponents: content.components,
            });
          });
        })
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          let toast = toastError(errorObj.translatedMessage, 'Create Page Component');
          this.props.createToast(toast);
        });
    }
  }

  setPage(index: number) {
    this.setState(
      {
        currentPage: index,
      },
      () => {
        if (this.state.groupPages[this.state.currentPage] !== undefined) {
          this.getPageContent(
            this.state.group.id,
            this.state.groupPages[this.state.currentPage].id,
          ).then((content: any) => {
            this.setState({
              pageComponents: content.components,
            });
          });
        }
      },
    );
  }

  saveGroup() {
    this.props.updateGroup(this.state.group, this.state.group.id, this.state.groupPages);
  }

  addNewPage() {
    let _newPage = {
      id: '',
      owner_type: '',
      owner_id: '',
      owner_handle: '',
      sequence: 0,
      created_at: new Date(),
      updated_at: new Date(),
      label: 'New Page',
      icon: 'fad fa-info-circle',
    };

    this.setState(
      {
        groupPages: [...this.state.groupPages, _newPage],
        currentPage: this.state.groupPages.length,
        pageComponents: [],
      },
      () => {
        this.props.updateGroup(this.state.group, this.state.group.id, this.state.groupPages);
      },
    );
  }

  saveCurrentPage() {
    let _currentPage = this.state.groupPages[this.state.currentPage];

    this.props.updateGroupPage(this.state.group.id, _currentPage.id, _currentPage);
  }

  canEditGroup() {
    return this.state.group.id.length <= 0 || this.props.isLoggedIn;
  }

  handleGroupInputChange(event: any, self: any, prevent?: boolean, callback?: any) {
    if (prevent === true) {
      event.preventDefault();
    }

    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;
    const name = target.name;

    self.setState(
      {
        group: {
          ...this.state.group,
          [name]: value,
        },
      },
      () => {
        if (callback !== undefined) {
          callback();
        }
      },
    );
  }

  handlePageReorder = (sourceIndex: number, destIndex: number | null) => {
    if (destIndex !== null) {
      let _pages = this.state.groupPages;

      _pages[sourceIndex].sequence = destIndex + 1;
      const pageId = _pages[sourceIndex].id;
      const pagePayload = { ..._pages[sourceIndex] };

      _pages.splice(destIndex, 0, _pages.splice(sourceIndex, 1)[0]);

      this.setState(
        {
          groupPages: _pages,
          currentPage: destIndex,
        },
        () => {
          this.props.updateGroupPage(this.state.group.id, pageId, pagePayload);
        },
      );
    }
  };

  createNewPage(_newPage: Partial<IPage>) {
    axios
      .post(swapRouteParams(routes.CREATE_GROUP_PAGE, { groupId: this.state.group.id }), _newPage)
      .then((response) => {
        let _pages = [...this.state.groupPages];

        this.setState(
          {
            groupPages: [..._pages, ...[response.data]],
          },
          () => {
            this.setPage([..._pages, ...[response.data]].length - 1);
          },
        );
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Cause Page');
        this.props.createToast(toast);
      });
  }

  updatePage(pId: string, page: IPage) {
    axios
      .put(
        swapRouteParams(routes.UPDATE_GROUP_PAGE, { groupId: this.state.group.id, id: pId }),
        page,
      )
      .then((response) => {
        let _pages = [...this.state.groupPages];

        for (let p in _pages) {
          if (_pages[p].id === response.data.id) {
            _pages[p] = response.data;
          }
        }

        this.setState({
          groupPages: [..._pages],
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Cause Page');
        this.props.createToast(toast);
      });
  }

  deletePage(pId: string) {
    axios
      .delete(swapRouteParams(routes.DELETE_GROUP_PAGE, { groupId: this.state.group.id, id: pId }))
      .then((response) => {
        let _pages = [...this.state.groupPages];

        for (let p in _pages) {
          if (_pages[parseInt(p)].id === pId) {
            _pages.splice(parseInt(p), 1);
            break;
          }
        }

        this.setState(
          {
            groupPages: _pages,
          },
          () => {
            this.setPage(
              this.state.currentPage >= _pages.length ? _pages.length - 1 : this.state.currentPage,
            );
          },
        );
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Cause Page');
        this.props.createToast(toast);
      });
  }

  onUpdateComponent = (pageId: string, componentId: string, payload: Partial<IPageComponent>) => {
    this.props.updateGroupPageComponent(this.state.group.id, pageId, componentId, payload);
  };

  joinGroup() {
    if (this.props.isLoggedIn) {
      this.props.joinGroup(this.state.group.id);
    } else {
      this.setState({
        showLoginModal: true,
        registerRedirectUrl: '/group/' + this.state.group.handle,
        registerFunction: 'joinGroup',
      });
    }
  }

  followGroup = () => {
    if (this.props.isLoggedIn) {
      const groupId = this.state.group.id;
      if (!this.state.isFollowingGroup) {
        axios
          .post(swapRouteParams(routes.FOLLOW_GROUP, { groupId }))
          .then((response) => {
            getUserGroups();
            getCurrentUserGroupRole(groupId);
            this.setState({ isFollowingGroup: true });
          })
          .catch((error) => {
            const errorObj = errorHelpers.getErrorObject(error);
            let toast = toastError(errorObj.translatedMessage, 'Follow Cause');
            this.props.createToast(toast);
          });
      } else {
        axios
          .post(swapRouteParams(routes.UNFOLLOW_GROUP, { groupId }))
          .then((response) => {
            getUserGroups();
            getCurrentUserGroupRole(groupId);
            this.setState({ isFollowingGroup: false });
          })
          .catch((error) => {
            const errorObj = errorHelpers.getErrorObject(error);
            let toast = toastError(errorObj.translatedMessage, 'Unfollow Cause');
            this.props.createToast(toast);
          });
      }
    } else {
      this.setState({
        showLoginModal: true,
        registerRedirectUrl: '/group/' + this.state.group.handle,
        registerFunction: 'followGroup',
      });
    }
  };

  onImageChange(fileData: any) {
    switch (fileData.type) {
      case 'cover_image_url':
        this.props.updateGroupCoverImage(this.state.group, fileData.file);
        break;
      case 'profile_image_url':
        //
        this.props.updateGroupProfileImage(this.state.group, fileData.file);
        break;
    }
  }

  toggleAddComponent(value: boolean) {
    this.setState({
      showAddComponent: value,
    });
  }

  onAddComponent(type: string) {
    this.createGroupPageComponent(type);
    this.toggleAddComponent(false);
  }

  onCancelAdd() {
    this.toggleAddComponent(false);
  }

  volunteer() {
    if (this.props.isLoggedIn) {
      let _application = {
        user_id: this.props.userState.user.id,
        user_handle: this.props.userState.user.handle,
        group: this.state.group.handle,
        user_capacity: 'volunteer',
      };

      this.props.volunteerForGroup(this.state.group.id, _application);
    } else {
      this.props.history.push(`/login?redirect=/group/${this.state.group.handle}/&fn=volunteer`);
    }
  }

  getAddComponentClass() {
    if (this.state.pageComponents.length === 0 && this.state.params.help) {
      return 'add-component joyride no-margin';
    } else {
      return 'add-component';
    }
  }

  hasManagePermission() {
    const allowedPermissions = Object.keys(this.state.userPermissions).filter(
      (k) => this.state.userPermissions[k],
    );
    return allowedPermissions.some((allowed) => GroupManagePermissions.includes(allowed));
  }

  handleApply() {
    if (this.props.isLoggedIn) {
      this.setState({ showVolunteerFormModal: true });
    } else {
      this.props.history.push(`/login?redirect=/group/${this.state.group.handle}?fn=handleApply`);
    }
  }

  hasEditPermissions(): boolean {
    return (
      (this.state.userPermissions['EDIT_GROUP_INFO'] ||
        (this.state.group.id && this.state.group.id.length <= 0)) &&
      this.props.isLoggedIn
    );
  }

  hasUserJoined(): boolean {
    return Object.keys(this.state.userPermissions).length > 0;
  }

  setIsFollowingGroup(): void {
    this.setState({
      isFollowingGroup:
        this.state.userCapacity.length > 0 &&
        this.state.userCapacity.includes(Constants.user_capacity.follower),
    });
  }

  async onDeleteComponent(cId: string) {
    const url = swapRouteParams(routes.DELETE_GROUP_PAGE_COMPONENT, {
      groupId: this.state.group.id,
      pageId: this.state.groupPages[this.state.currentPage].id,
      componentId: cId,
    });
    const response = await axios.delete(url);
    let pages: IPage[] = [...this.state.groupPages];
    for (let p in pages) {
      if (pages[p].id === response.data.id) {
        pages[p] = response.data;
        break;
      }
    }
    this.setState({
      groupPages: pages,
      pageComponents: response.data.components,
    });
  }

  onComponentUpdate(disableLoad?: boolean) {
    this.getPageContent(
      this.state.group.id,
      this.state.groupPages[this.state.currentPage].id,
      disableLoad ? disableLoad : undefined,
    ).then((content: any) => {
      this.setState({
        pageComponents: content.components,
      });
    });
  }

  onStatusChanged(updatedGroup: IGroup, callback: () => void) {
    this.setState({
      group: updatedGroup,
    });
    callback();
  }

  canDonate() {
    const { accepting_donations, external_id, gg_active_projects_count, unclaimed } =
      this.state.group;
    const canDonateToGlobalGiving =
      external_id?.GlobalGiving &&
      Boolean(gg_active_projects_count) &&
      accepting_donations &&
      unclaimed;

    return canDonateToGlobalGiving ? canDonateToGlobalGiving : accepting_donations;
  }

  renderVolunteerButton() {
    if (this.state.group.accepting_volunteers) {
      if (this.state.group.status?.code === Constants.group_status.active) {
        return (
          <div>
            {!this.state.group.accepting_external_application ? (
              <>
                {this.props.userState.currentApplications.group.group?.id !==
                  this.state.group.id && (
                  <Button
                    text="Volunteer"
                    onClick={() => this.handleApply()}
                  />
                )}

                {this.props.userState.currentApplications.group.group?.id ===
                  this.state.group.id && (
                  <Button
                    isDisabled={
                      this.props.userState.currentApplications.group.group?.id ===
                      this.state.group.id
                    }
                    icon="fa fa-check"
                    text="Volunteered"
                  />
                )}
              </>
            ) : (
              <>
                <Button
                  text="Volunteer"
                  onClick={() => this.setState({ showExternalVolunteerApplicationWarning: true })}
                />
              </>
            )}
          </div>
        );
      } else {
        return (
          <div
            title="This cause is currently not active."
            className="volunteer disabled"
          >
            <Button
              text="Volunteer"
              className="action-button-disabled"
            />
          </div>
        );
      }
    } else {
      if (this.hasManagePermission()) {
        return (
          <div
            title="This cause is currently not accepting volunteers."
            className="volunteer disabled"
          >
            <Button
              onClick={() =>
                this.hasManagePermission() ? this.setState({ showOnboardVolunteer: true }) : null
              }
              text="Volunteer"
              className="action-button-disabled"
            />
          </div>
        );
      } else {
        return null;
      }
    }
  }

  renderDonateButton() {
    if (this.canDonate()) {
      if (this.state.group.status?.code === Constants.group_status.active) {
        return <LinkButton to={'/group/' + this.state.group.handle + '/donate'}>Donate</LinkButton>;
      } else {
        return (
          <div
            title={'This cause is currently not accepting donations.'}
            className="donate disabled"
          >
            <Button
              onClick={() => {
                if (this.hasManagePermission()) this.setState({ showOnboardDonation: true });
              }}
              text="Donate"
              className="action-button-disabled"
            />
          </div>
        );
      }
    } else {
      if (this.hasManagePermission()) {
        return (
          <div
            title={'This cause is currently not accepting donations.'}
            className="donate disabled"
          >
            <Button
              onClick={() => {
                if (this.hasManagePermission()) this.setState({ showOnboardDonation: true });
              }}
              text="Donate"
              className="action-button-disabled"
            />
          </div>
        );
      } else {
        return null;
      }
    }
  }

  renderFundraiseButton() {
    if (this.state.group.allow_obo_fundraisers) {
      if (this.state.group.status?.code === Constants.group_status.active) {
        return (
          <Button
            onClick={() => {
              this.props.isLoggedIn
                ? this.props.history.push(
                    `/onboarding/event/eventTypeSelection?groupId=${this.state.group.id}&type=custom&createdFor=obo_fundraiser`,
                  )
                : this.props.history.push(
                    '/login?redirect=' +
                      encodeURIComponent(
                        `/onboarding/event/eventTypeSelection?groupId=${this.state.group.id}&type=custom&createdFor=obo_fundraiser`,
                      ),
                  );
            }}
            text={'Fundraise For Us'}
          />
        );
      } else {
        return (
          <Button
            text={'Fundraise For Us'}
            className="action-button-disabled"
          />
        );
      }
    } else {
      return null;
    }
  }

  render() {
    const currency = this.state.group?.account?.currency ?? defaultCurrency;
    let _joyrideStyles = {
      options: {
        arrowColor: '#FFF',
        backgroundColor: '#FFF',
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: '#5E51AB',
        textColor: '#505050',
      },
    };

    const followGroupAction = {
      icon: !this.state.isFollowingGroup ? 'fal fa-eye' : 'fal fa-check',
      onClick: this.followGroup,
      className: this.state.isFollowingGroup ? 'follow-button-followed' : '',
    } as IFloatingAction;

    const ownerObj = typeHelpers.createOwnerObject('group', this.state.group);

    if (
      this.state.redirect ||
      (this.state.group.status && this.state.group.status.code === 'archived')
    ) {
      return <Redirect to="/not-found" />;
    } else {
      return (
        <div className="Group">
          {this.state.params.help !== undefined && !this.state.showGroupOnboard && (
            <Joyride
              locale={{ back: 'Back', close: 'Close', last: 'Last', next: 'Next', skip: 'Skip' }}
              continuous={true}
              disableScrolling={true}
              styles={_joyrideStyles}
              steps={this.state.steps}
              callback={(state: any) => {
                if (state.action === 'reset') {
                  window.history.pushState({}, '', '/group/' + this.state.group.handle);
                  this.setState({ params: {}, manageTour: true });
                }
              }}
            />
          )}
          <BackSplash
            coverImageUrl={this.state.group.cover_image_url}
            onShareClick={() => this.setState({ showShareModal: true })}
            showChat={true}
            onChatClick={() => this.setState({ showContactUsModal: true })}
            extraFloatingActions={this.state.group?.external_id ? undefined : [followGroupAction]}
          />

          <div className="profile">
            <PageSidebar>
              <PageHeader
                ownerType={uiConstants.ownerType.group}
                group={this.state.group}
                history={this.props.history}
                isLoading={this.state.loading}
                showEditPortrait={this.hasEditPermissions()}
                portraitImageUrl={this.state.group.profile_image_url}
                typeName="CAUSE"
                campaign={this.state.groupCampaign}
                showChangeStatus={this.hasEditPermissions()}
                availableStatuses={Object.values(Constants.group_status)}
                currentStatus={this.state.group.status?.code}
                onStatusChanged={(status: string, callback) => {
                  this.props.changeGroupStatus(
                    this.state.group.id,
                    status,
                    this.state.group.title,
                    this.state.group.handle,
                    (updatedGroup) => this.onStatusChanged(updatedGroup, callback),
                  );
                }}
                typeIconClass="fal fa-users"
                displayName={this.state.group.title}
                onDisplayNameChange={(e) =>
                  this.setState({ group: { ...this.state.group, title: e.target.value } })
                }
                onSubmitDisplayNameChange={() => {
                  this.props.updateGroup({ title: this.state.group.title }, this.state.group.id);
                }}
                showEditDisplayName={this.hasEditPermissions()}
                onImageChange={(e) => this.onImageChange(e)}
                handle={this.state.group.handle}
                onShareClick={() => this.setState({ showShareModal: true })}
                showChat={true}
                onChatClick={() => this.setState({ showContactUsModal: true })}
                extraFloatingActions={
                  this.state.group?.external_id ? undefined : [followGroupAction]
                }
              >
                {this.state.group.show_campaign_progress &&
                  this.state.groupCampaign &&
                  (this.state.groupCampaign?.raised ?? 0) >= 0 && (
                    <FundraiserProgressBar
                      raised={this.state.groupCampaign.raised}
                      goal={this.state.groupCampaign.goal}
                      currency={currency}
                    />
                  )}

                <div className="action-row">
                  {this.renderVolunteerButton()}
                  {this.renderDonateButton()}
                  {this.renderFundraiseButton()}
                </div>
              </PageHeader>
              <ContactOrganizerModal
                object_id={this.state.group.id || ''}
                object_type={uiConstants.ownerType.group}
                show={this.state.showContactUsModal}
                email={this.state.group.contact_details?.email}
                phone={this.state.group.contact_details?.phone}
                onClose={() => this.setState({ showContactUsModal: false })}
              />
            </PageSidebar>
            <PageBody>
              <PageCoverImage
                coverImageUrl={this.state.group.cover_image_url}
                onImageChange={this.onImageChange}
                ShowEditCover={this.hasEditPermissions()}
                showManageButton={this.hasManagePermission() && this.props.isLoggedIn}
                manageUrl={
                  this.state.manageTour
                    ? '/group/' + this.props.match.params.handle + '/admin?help=1'
                    : '/group/' + this.props.match.params.handle + '/admin'
                }
              />

              <PageContainer isLoading={this.state.loading}>
                <PageSelector
                  currentPageIndex={this.state.currentPage}
                  isLoading={this.state.loading}
                  pages={this.state.groupPages}
                  editable={this.hasEditPermissions()}
                  onPageClick={(index) => this.setPage(index)}
                  onOpenManageModal={() => this.setState({ showManageModal: true })}
                />
                <PageContent
                  owner={ownerObj}
                  currentPageIndex={this.state.currentPage}
                  pages={this.state.groupPages}
                  pageComponents={this.state.pageComponents}
                  permissions={this.state.userPermissions}
                  isAdmin={this.hasEditPermissions()}
                  routeProps={this.props}
                  createPageComponent={this.onAddComponent}
                  onUpdateComponent={this.onUpdateComponent}
                  isLoading={this.state.pageLoading}
                  onDeleteComponent={async (id: string) => {
                    await this.onDeleteComponent(id);
                  }}
                  onComponentUpdate={(disableLoad?: boolean) => {
                    this.onComponentUpdate(disableLoad ? disableLoad : undefined);
                  }}
                  campaign={this.state.groupCampaign}
                  isUserStillLoggedIn={this.props.isLoggedIn}
                />
              </PageContainer>
            </PageBody>
          </div>
          <ManageTabsModal
            show={this.state.showManageModal}
            isLoading={this.state.loading}
            pages={this.state.groupPages}
            onClose={() => this.setState({ showManageModal: false })}
            onReorderPage={this.handlePageReorder}
            onCreatePage={this.createNewPage}
            onUpdatePage={this.updatePage}
            onDeletePage={this.deletePage}
          />
          <Modal
            show={this.state.showLoginModal}
            onClose={() => {
              this.setState({
                showLoginModal: false,
                registerRedirectUrl: '',
                registerFunction: '',
              });
            }}
          >
            <Login
              registerRedirectUrl={this.state.registerRedirectUrl}
              registerFunction={this.state.registerFunction}
              {...this.props}
            />
          </Modal>
          <Modal
            class="share-modal"
            show={this.state.showShareModal}
            onClose={() => {
              this.setState({ showShareModal: false });
            }}
          >
            <Share
              owner_object={ownerObj}
              {...this.props}
              url={Config.web.REACT_APP_BASE_URL + '/group/' + this.state.group.handle}
            />
          </Modal>
          <Modal
            show={this.state.showOnboardDonation}
            onClose={() => {
              this.setState({ showOnboardDonation: false });
            }}
          >
            <div className="onboarding-container donation">
              <div className="onboard-img"></div>
              <div className="onboard-title">Accepts Donations</div>
              <div className="onboard-info">
                Receive donations, manage donors, campaigns and send customized tax receipts.
              </div>
              <div className="onboard-actions">
                <Link
                  className="donate-link"
                  to={'/group/' + this.state.group.handle + '/admin?t=setup'}
                >
                  Setup donations
                </Link>
                <span onClick={() => this.setState({ showOnboardDonation: false })}>Skip</span>
              </div>
            </div>
          </Modal>
          <Modal
            show={this.state.showOnboardVolunteer}
            onClose={() => {
              this.setState({ showOnboardVolunteer: false });
            }}
          >
            <div className="onboarding-container volunteer">
              <div className="onboard-img"></div>
              <div className="onboard-title">Accepts Volunteers</div>
              <div className="onboard-info">
                Create a custom application form that will help you find and manage your volunteers.
              </div>
              <div className="onboard-actions">
                <Link
                  className="donate-link"
                  to={'/group/' + this.state.group.handle + '/admin?t=setup'}
                >
                  Setup volunteer application
                </Link>
                <span onClick={() => this.setState({ showOnboardVolunteer: false })}>Skip</span>
              </div>
            </div>
          </Modal>
          <Modal
            show={this.state.showGroupOnboard}
            onClose={() => {
              this.setState({ showGroupOnboard: false });
            }}
          >
            <div className="onboarding-container group-onboard">
              {this.state.groupOnboardStep === 1 && (
                <div className="wrapper manage">
                  <div className="onboard-img step1"></div>
                  <div className="onboard-title">Manage</div>
                  <div className="onboard-info">
                    Manage your connections, applications, transactions, causes, events, volunteer
                    opportunities and much more!
                  </div>
                  <div className="steps-select">
                    <i className="fas fa-circle" />
                    <i
                      onClick={() => this.setState({ groupOnboardStep: 2 })}
                      className="far fa-circle"
                    />
                    <i
                      onClick={() => this.setState({ groupOnboardStep: 3 })}
                      className="far fa-circle"
                    />
                  </div>
                </div>
              )}
              {this.state.groupOnboardStep === 2 && (
                <div className="wrapper connect">
                  <div className="onboard-img step1"></div>
                  <div className="onboard-title">Connect</div>
                  <div className="onboard-info">
                    Connect and communicate with your community, volunteers, and donors all in one
                    place.
                  </div>
                  <div className="steps-select">
                    <i
                      onClick={() => this.setState({ groupOnboardStep: 1 })}
                      className="far fa-circle"
                    />
                    <i className="fas fa-circle" />
                    <i
                      onClick={() => this.setState({ groupOnboardStep: 3 })}
                      className="far fa-circle"
                    />
                  </div>
                </div>
              )}
              {this.state.groupOnboardStep === 3 && (
                <div className="wrapper create">
                  <div className="onboard-img step1"></div>
                  <div className="onboard-title">Create</div>
                  <div className="onboard-info">
                    Create a custom profile, cause, event, and volunteer opportunity pages.
                  </div>
                  <div className="steps-select">
                    <i
                      onClick={() => this.setState({ groupOnboardStep: 1 })}
                      className="far fa-circle"
                    />
                    <i
                      onClick={() => this.setState({ groupOnboardStep: 2 })}
                      className="far fa-circle"
                    />
                    <i className="fas fa-circle" />
                  </div>
                </div>
              )}
              {this.state.groupOnboardStep === 1 && (
                <div className="onboard-actions">
                  <Button
                    onClick={() => this.setState({ groupOnboardStep: 2 })}
                    text="Next"
                  />
                  <span onClick={() => this.setState({ showGroupOnboard: false })}>Skip</span>
                </div>
              )}
              {this.state.groupOnboardStep === 2 && (
                <div className="onboard-actions">
                  <Button
                    onClick={() => this.setState({ groupOnboardStep: 3 })}
                    text="Next"
                  />
                  <span onClick={() => this.setState({ showGroupOnboard: false })}>Skip</span>
                </div>
              )}
              {this.state.groupOnboardStep === 3 && (
                <div className="onboard-actions">
                  <Button
                    onClick={() => this.setState({ showGroupOnboard: false, groupOnboardStep: 0 })}
                    text="Setup Your Group"
                  />
                  <span onClick={() => this.setState({ showGroupOnboard: false })}>Skip</span>
                </div>
              )}
            </div>
          </Modal>

          <Modal
            class="modal-volunteer-form"
            show={this.state.showVolunteerFormModal}
            onClose={() => {
              this.setState({ showVolunteerFormModal: !this.state.showVolunteerFormModal });
            }}
          >
            <VolunteerApplicationForm
              group={this.state.group}
              onClose={() => {
                this.setState({ showVolunteerFormModal: false });
              }}
            />
          </Modal>

          <ExternalVolunteerApplicationWarning
            show={this.state.showExternalVolunteerApplicationWarning}
            onClose={() => this.setState({ showExternalVolunteerApplicationWarning: false })}
            url={this.state.group.external_application_url}
            isAvailable={true}
          />

          {this.props.children && ownerObj && ownerObj.ownerId && this.props.children(ownerObj)}
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    cartState: store.cartState,
    isLoggedIn: userSelectors.isUserAuthenticated(store),
  };
};

const mapDispatchToProps = {
  getUserGroupApplications,
  joinGroup,
  updateGroup,
  createGroup,
  getGroup,
  createGroupPage,
  updateGroupPage,
  deleteGroupPage,
  getCurrentUserGroupRole,
  createGroupPageComponent,
  getCurrentGroupPageComponents,
  updateGroupPageComponent,
  updateGroupCoverImage,
  updateGroupProfileImage,
  getGroupEvents,
  volunteerForGroup,
  createToast,
  changeGroupStatus,
  setCartCurrency,
};

export default connect(mapStateToProps, mapDispatchToProps)(Group);
