import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../../../store';
import { RouteComponentProps } from 'react-router-dom';
import { routes, swapRouteParams, handleInputChange } from '../../../../helpers';
import { ICustomField, IUserRole } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import Dropdown from '../../../Dropdown/Dropdown';
import TextField from '../../../TextField/TextField';
import Modal from '../../../Modal/Modal';
import CustomFieldsLibrary from '../CustomFieldsLibrary/CustomFieldsLibrary';

import './AddCustomField.scss';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  contact: IUserRole;
  onCreateCustomField(): void;
}

interface ICustomFieldOption {
  label: string;
  value: string;
  icon: string;
  color?: string;
}

interface IState {
  customFieldOptions: ICustomFieldOption[];
  showAddFieldModal: boolean;
  showLibraryModal: boolean;
  editField: string;
  fieldName: string;
  fieldType: string;
  fieldOptions: string[];
  fieldColours: string[];
  labelColours: string[];
}

class AddCustomField extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      customFieldOptions: [
        { label: 'Text', value: 'text', icon: 'fa fa-font-case' },
        { label: 'Checkbox', value: 'checkbox', icon: 'fa fa-check-square' },
        { label: 'Dropdown', value: 'dropdown', icon: 'fa fa-caret-down' },
        { label: 'Number', value: 'number', icon: 'fa fa-hashtag' },
        { label: 'Currency', value: 'money', icon: 'fa fa-dollar' },
        { label: 'Date', value: 'date', icon: 'fa fa-calendar' },
        { label: 'Labels', value: 'labels', icon: 'fa fa-tags' },
        { label: 'Boolean Flag', value: 'flag', icon: 'fa fa-flag' },
      ],
      showAddFieldModal: false,
      showLibraryModal: false,
      editField: '',
      fieldName: '',
      fieldType: 'text',
      fieldOptions: [''],
      fieldColours: ['#0B61C0'],
      labelColours: ['#0B61C0', '#E43A36', '#D2AB30', '#19A76B', '#F5641C', '#6565D3'],
    };

    this.createCustomField = this.createCustomField.bind(this);
    this.handleFieldOptionChange = this.handleFieldOptionChange.bind(this);
    this.addFieldOption = this.addFieldOption.bind(this);
    this.saveUserCustomField = this.saveUserCustomField.bind(this);
    this.formatCfValue = this.formatCfValue.bind(this);
    this.getDefaultValue = this.getDefaultValue.bind(this);
    this.changeFieldOptionColour = this.changeFieldOptionColour.bind(this);
  }

  createCustomField() {
    let _payload = {
      name: this.state.fieldName,
      type: this.state.fieldType,
      used_on: ['main_contact'],
    } as ICustomField;

    if (
      this.state.fieldType === 'dropdown' ||
      this.state.fieldType === 'checkbox' ||
      this.state.fieldType === 'labels'
    ) {
      let _options: { label: string; value: string; color?: string }[] = [];

      if (this.state.fieldType === 'labels') {
        for (let f in this.state.fieldOptions) {
          _options.push({
            label: this.state.fieldOptions[f],
            value: this.state.fieldOptions[f],
            color: this.state.fieldColours[f],
          });
        }
      } else {
        for (let f in this.state.fieldOptions) {
          _options.push({ label: this.state.fieldOptions[f], value: this.state.fieldOptions[f] });
        }
      }

      _payload.options = _options;
    }

    if (this.props.contact.object_type === 'group') {
      if (this.state.editField === '') {
        axios
          .post(
            swapRouteParams(routes.CREATE_CUSTOM_FIELD, { groupId: this.props.contact.object_id }),
            _payload,
          )
          .then((response) => {
            this.saveUserCustomField(_payload.name, _payload.type, this.getDefaultValue(_payload));
          })
          .finally(() => {
            this.props.onCreateCustomField();
            this.setState({
              showAddFieldModal: false,
              fieldName: '',
              fieldOptions: [''],
              fieldType: 'text',
            });
          });
      } else {
        axios
          .put(
            swapRouteParams(routes.EDIT_CUSTOM_FIELD, {
              groupId: this.props.contact.object_id,
              cfId: this.state.editField,
            }),
            _payload,
          )
          .then((response) => {
            //
          })
          .finally(() => {
            this.props.onCreateCustomField();
            this.setState({
              showAddFieldModal: false,
              fieldName: '',
              fieldOptions: [''],
              fieldType: 'text',
              editField: '',
            });
          });
      }
    }
  }

  handleFieldOptionChange(
    event: any,
    self: any,
    prevent?: boolean,
    callback?: any,
    passCbVal?: boolean,
  ) {
    if (prevent === true) {
      event.preventDefault();
    }

    const target = event.target;
    let value = target.value;

    const name = target.name.split('-');

    let _fieldOptions: string[] = [...this.state.fieldOptions];
    _fieldOptions[name[1]] = value;

    self.setState(
      {
        fieldOptions: _fieldOptions,
      },
      () => {
        if (callback !== undefined) {
          if (passCbVal) {
            callback(value);
          } else {
            callback();
          }
        }
      },
    );
  }

  addFieldOption() {
    let _fieldOptions: string[] = [...this.state.fieldOptions];
    _fieldOptions.push('');

    let _fieldColours: string[] = [...this.state.fieldColours];
    _fieldColours.push('#0B61C0');

    this.setState({
      fieldOptions: _fieldOptions,
      fieldColours: _fieldColours,
    });
  }

  removeFieldOption(index: number) {
    let _fieldOptions: string[] = [...this.state.fieldOptions];
    _fieldOptions.splice(index, 1);

    let _fieldColours: string[] = [...this.state.fieldColours];
    _fieldColours.splice(index, 1);

    this.setState({
      fieldOptions: _fieldOptions,
      fieldColours: _fieldColours,
    });
  }

  saveUserCustomField(name: string, type: string, value: string | number | string[]) {
    axios
      .put(
        swapRouteParams(routes.SET_USER_CUSTOM_FIELDS, {
          groupId: this.props.contact.object_id,
          userId: this.props.contact.user_id,
        }),
        { [name]: this.formatCfValue(type, value) },
      )
      .then((response) => {
        //
      })
      .finally(() => {
        this.setState(
          {
            editField: '',
          },
          () => {
            this.setState({ showLibraryModal: false });
            this.props.onCreateCustomField();
          },
        );
      });
  }

  formatCfValue(type: string, value: string | number | string[]) {
    switch (type) {
      case 'money':
      case 'number':
        return parseFloat(value as string);
      case 'flag':
        return value === 'true';
      default:
        return value;
    }
  }

  getDefaultValue(cf: ICustomField): string | number | string[] {
    switch (cf.type) {
      case 'text':
        return '';
      case 'checkbox':
        return [];
      case 'dropdown':
        return cf.options?.[0]?.value ?? '';
      case 'number':
        return 0;
      case 'money':
        return 0;
      case 'date':
        return moment().format();
      case 'labels':
        return [];
      case 'flag':
        return 'false';
      default:
        throw new Error(`Unsupported custom field type ${cf.type}`);
    }
  }

  changeFieldOptionColour(index: number, colour: string) {
    let _fieldColours: string[] = [...this.state.fieldColours];
    _fieldColours[index] = colour;

    this.setState({
      fieldColours: _fieldColours,
    });
  }

  render() {
    return (
      <div className="AddCustomField">
        <Button
          className="add-field"
          icon="far fa-plus"
          text="Field"
        />
        <div className="custom-fields-menu">
          <div className="cf-title">CREATE NEW FIELD</div>
          <Button
            className="cf-button"
            text="Custom Fields Library"
            onClick={() => {
              this.setState({ showLibraryModal: true });
            }}
          />
          <ul>
            {this.state.customFieldOptions.map((item, index) => {
              return (
                <li
                  key={index}
                  notranslate="yes"
                  onClick={() => {
                    this.setState({
                      fieldType: item.value,
                      showAddFieldModal: true,
                      editField: '',
                      fieldName: '',
                      fieldOptions: [''],
                    });
                  }}
                  className="no-select"
                >
                  <i className={item.icon} />
                  <span>{item.label}</span>
                </li>
              );
            })}
          </ul>
        </div>
        <Modal
          class="add-field"
          show={this.state.showAddFieldModal}
          onClose={() => {
            this.setState({ showAddFieldModal: false });
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.createCustomField();
            }}
            className="af"
          >
            <div className="af-title">
              {this.state.editField ? 'Edit Field' : 'Create New Field'}
            </div>
            <div className="af-subtitle">Details</div>
            <Dropdown
              label="Field Type"
              name="fieldType"
              disabled={this.state.editField !== ''}
              shouldSort={true}
              value={this.state.fieldType}
              options={this.state.customFieldOptions}
              onChange={(e) => {
                handleInputChange(e, this, false);
              }}
            />
            <TextField
              label="Field Name"
              required={true}
              value={this.state.fieldName}
              name="fieldName"
              type="text"
              onChange={(e: any) => {
                handleInputChange(e, this);
              }}
            />
            {(this.state.fieldType === 'dropdown' ||
              this.state.fieldType === 'checkbox' ||
              this.state.fieldType === 'labels') && (
              <React.Fragment>
                {this.state.fieldOptions.map((option: string, index: number) => {
                  return (
                    <div
                      key={index}
                      className="option-wrap"
                    >
                      <TextField
                        required={true}
                        label={'Option ' + (index + 1)}
                        value={option}
                        name={'fieldOption-' + index}
                        type="text"
                        onChange={(e: any) => {
                          this.handleFieldOptionChange(e, this);
                        }}
                      />
                      {this.state.fieldType === 'labels' && (
                        <div className="label-colour">
                          <div className="colour-title">Colour</div>
                          <div className="active-colour">
                            <i
                              className="fas fa-circle"
                              style={{ color: this.state.fieldColours[index] }}
                            />
                          </div>
                          <div className="colour-selector">
                            {this.state.labelColours.map((colour: string, colourIndex: number) => {
                              return (
                                <i
                                  key={colourIndex}
                                  onClick={() => {
                                    this.changeFieldOptionColour(index, colour);
                                  }}
                                  className={
                                    colourIndex < 3 ? 'fas fa-circle padding' : 'fas fa-circle'
                                  }
                                  style={{ color: colour }}
                                />
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {index !== 0 && (
                        <Button
                          text=""
                          type="button"
                          className="delete-option"
                          icon="far fa-trash-alt"
                          onClick={() => {
                            this.removeFieldOption(index);
                          }}
                        />
                      )}
                    </div>
                  );
                })}
                <Button
                  icon="far fa-plus"
                  className="add-option"
                  type="button"
                  text="Add New Option"
                  onClick={() => {
                    this.addFieldOption();
                  }}
                />
              </React.Fragment>
            )}
            <div className="af-actions">
              <Button text="Save" />
              <Button
                text="Cancel"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showAddFieldModal: false });
                }}
              />
            </div>
          </form>
        </Modal>
        <Modal
          class="cf-modal"
          show={this.state.showLibraryModal}
          onClose={() => {
            this.setState({ showLibraryModal: false });
          }}
        >
          <CustomFieldsLibrary
            {...this.props}
            labels={this.state.customFieldOptions}
            contact={this.props.contact}
            onEditCustomField={(cf) => {
              this.setState({
                showLibraryModal: false,
                fieldType: cf.type,
                showAddFieldModal: true,
                editField: cf.id ?? '',
                fieldName: cf.name,
                fieldOptions:
                  cf.options?.map((option, index) => {
                    return option.value;
                  }) ?? [],
                fieldColours:
                  cf.options?.map((option, index) => {
                    return option.color ?? '';
                  }) ?? [],
              });
            }}
            onDeleteCustomField={() => {
              this.props.onCreateCustomField();
            }}
            onSaveUserCustomField={(cf) => {
              this.saveUserCustomField(cf.name, cf.type, this.getDefaultValue(cf));
            }}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(AddCustomField),
);
