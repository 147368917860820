import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IAppState } from '../../store';
import { toastError } from '../../helpers';
import { createToast } from '../../actions/toaster';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';

import './FilterData.scss';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { IActiveFilter, IFilter, IToast } from '../../interfaces';
import DateTimePicker from '../shared/DateTimePicker/DateTimePicker';
import moment, { Moment } from 'moment';
import { userSelectors } from '../../selectors/user';

interface IProps extends RouteComponentProps<any> {
  filterOptions: IFilter[];
  currentFilters: IActiveFilter[];
  locale: string;
  createToast(toast: IToast): void;
  onApply(newFilters: IActiveFilter[]): void;
}

interface IState {
  activeFilters: IActiveFilter[];
  emptyFilter: number;
}

class FilterData extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeFilters: [...this.props.currentFilters],
      emptyFilter: -1,
    };

    this.addFilter = this.addFilter.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.validateFilters = this.validateFilters.bind(this);
  }

  addFilter() {
    if (this.props.filterOptions.length > 0) {
      let _activeFilters: IActiveFilter[] = [...this.state.activeFilters];

      _activeFilters.push({
        ...this.props.filterOptions[0],
        value: '',
      });

      this.setState({
        activeFilters: _activeFilters,
      });
    }
  }

  deleteFilter(index: number) {
    let _activeFilters: IActiveFilter[] = [...this.state.activeFilters];

    _activeFilters.splice(index, 1);

    this.setState({
      activeFilters: _activeFilters,
    });
  }

  updateFilterName(index: number, newName: string) {
    let activeFilters: IActiveFilter[] = [...this.state.activeFilters];

    const filterOption = this.props.filterOptions.find((option) => option.id === newName);

    if (!filterOption) {
      throw new Error(`Filter option ${newName} not found`);
    }

    activeFilters[index] = {
      ...filterOption,
      value: filterOption.options ? filterOption.options?.[0].value ?? '' : '',
      prefix: filterOption.type === 'number' ? '>' : '=',
    };

    this.setState({
      activeFilters: activeFilters,
    });
  }

  updateFilterPrefix(index: number, newPrefix: string) {
    let activeFilters: IActiveFilter[] = [...this.state.activeFilters];

    activeFilters[index].prefix = newPrefix;

    this.setState({
      activeFilters: activeFilters,
    });
  }

  updateFilterValue(index: number, newValue: string | Moment) {
    let activeFilters: IActiveFilter[] = [...this.state.activeFilters];

    activeFilters[index].value = moment.isMoment(newValue)
      ? localizeHelpers.formatDate(moment(newValue), LocaleDateFormats.lll, this.props.locale)
      : newValue;

    this.setState({
      activeFilters: activeFilters,
    });
  }

  validateFilters() {
    this.setState({
      emptyFilter: -1,
    });

    let _columns: string[] = [];

    for (let f in this.state.activeFilters) {
      if (_columns.includes(this.state.activeFilters[f].id)) {
        const toast = toastError(
          localizeHelpers.translate('Duplicate filter found. You can only filter a column once.'),
          'Filter Data',
        );
        this.props.createToast(toast);

        return false;
      } else {
        _columns.push(this.state.activeFilters[f].id);
      }

      if (this.state.activeFilters[f].value === '') {
        this.setState({
          emptyFilter: parseInt(f),
        });

        const toast = toastError(
          localizeHelpers.translate('Filter value required.'),
          'Filter Data',
        );
        this.props.createToast(toast);

        return false;
      }
    }

    return true;
  }

  render() {
    return (
      <form
        className="FilterData"
        onSubmit={(e: any) => {
          e.preventDefault();
          if (this.validateFilters()) {
            this.props.onApply(this.state.activeFilters);
          } else {
            return false;
          }
        }}
      >
        <div className="title">Filter Columns</div>
        <div className="sub-title">Available Filters</div>
        <div className="filters">
          <div className="filter-wrap">
            {this.state.activeFilters.map((filter, index) => {
              return (
                <div
                  key={index}
                  className={index === this.state.emptyFilter ? 'filter error' : 'filter'}
                >
                  <div className="filter-title">
                    Filter <var data-var="filter_index">{index + 1}</var>
                  </div>
                  <div className="filter-inputs">
                    <div className="filter-name">
                      <Dropdown
                        name={'name-' + index}
                        shouldSort={true}
                        value={this.props.filterOptions.filter((f) => f.id === filter.id)[0].id}
                        options={this.props.filterOptions.map((item, i) => {
                          return { label: item.label, value: item.id };
                        })}
                        onChange={(e) => this.updateFilterName(index, e.target.value)}
                      />
                    </div>
                    <div className="filter-value">
                      {filter.type === 'text' && (
                        <TextField
                          type="text"
                          name={'value-' + index}
                          value={filter.value}
                          onChange={(e) => {
                            this.updateFilterValue(index, e.target.value);
                          }}
                        />
                      )}
                      {filter.type === 'dropdown' && filter.options && (
                        <Dropdown
                          name={'value-' + index}
                          options={filter.options}
                          shouldSort={true}
                          value={filter.value}
                          onChange={(e) => this.updateFilterValue(index, e.target.value)}
                        />
                      )}
                      {(filter.type === 'number' || filter.type === 'date') && (
                        <div className="filter-value-inner">
                          <Dropdown
                            name={'prefix-' + index}
                            options={[
                              { label: '>', value: '>' },
                              { label: '>=', value: '>=' },
                              { label: '<', value: '<' },
                              { label: '<=', value: '<=' },
                              { label: '=', value: '=' },
                            ]}
                            shouldSort={true}
                            value={filter.prefix}
                            onChange={(e) => this.updateFilterPrefix(index, e.target.value)}
                          />

                          {filter.type === 'number' && (
                            <TextField
                              name={'value-' + index}
                              value={filter.value}
                              type="number"
                              onChange={(e) => this.updateFilterValue(index, e.target.value)}
                            />
                          )}

                          {filter.type === 'date' && (
                            <DateTimePicker
                              inputProps={{ placeholder: filter.label, readOnly: true }}
                              className="custom-dtp"
                              value={filter.value}
                              onChange={(newDate) => this.updateFilterValue(index, newDate)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="filter-actions">
                      <Button
                        text=""
                        type="button"
                        icon="far fa-trash-alt"
                        onClick={(e) => {
                          e.preventDefault();
                          this.deleteFilter(index);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            {this.state.activeFilters.length === 0 && (
              <div className="empty">You haven't added any filters yet.</div>
            )}
            <div className="add-filter">
              <Button
                type="button"
                icon="fas fa-plus-circle"
                text="Add Filter"
                onClick={(e: any) => {
                  e.preventDefault();
                  this.addFilter();
                }}
              />
            </div>
          </div>
        </div>
        <div className="filters-actions">
          <Button
            text="Apply Changes"
            type="submit"
          />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterData));
