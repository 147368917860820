import React, { FC } from 'react';
import { isMobileScreen } from '../../../helpers';
import Button from '../../Button/Button';
import './TitleSection.scss';
import { Link } from 'react-router-dom';

export interface ITitleSectionTab {
  id: string;
  title: string;
  show?: boolean;
  mobileTitle?: string;
  /** An SVG imported as a ReactComponent so we can dynamically change the fill, stroke, etc
   * import { ReactComponent as IconName } from "./icon/path"
   * Pass to this parameter like: {Icon: () => <IconName />}
   */
  Icon?: FC;
  notification_amt?: number;
}

export interface ITabConfig {
  tabs: ITitleSectionTab[];
  activeTabId: string;
  onTabClick: (activeTabId: string) => void;
}

export interface ICtaConfig {
  buttonText?: string;
  buttonIcon?: string;
  onClick: () => void;
  linkTo?: string;
}

const mobileBreakpoint = 1070;

interface IProps {
  title: string;
  tabConfig?: ITabConfig;
  ctaConfig?: ICtaConfig;
}

const TitleSection: FC<IProps> = (props: IProps) => {
  const { tabConfig, ctaConfig } = props;
  const isMobile = isMobileScreen(mobileBreakpoint);

  const renderTabs = (tabs: ITitleSectionTab[]) => {
    return tabs.map(({ id, title, mobileTitle, notification_amt }) => {
      const getActiveClass = tabConfig?.activeTabId === id ? 'active' : '';

      return (
        <li
          key={id}
          className={`tab ${getActiveClass}`}
          onClick={() => tabConfig?.onTabClick?.(id)}
        >
          <span className="title">{isMobile && mobileTitle ? mobileTitle : title}</span>
          {notification_amt !== 0 && notification_amt && (
            <span className="notification">{notification_amt !== 0 ? notification_amt : ''}</span>
          )}
        </li>
      );
    });
  };

  return (
    <div className="TitleSection">
      <div className="title-container">
        <span className="section-title">{props.title}</span>
        {ctaConfig != null && !ctaConfig?.linkTo && (
          <Button
            className="cta-button"
            onClick={ctaConfig.onClick}
            text={ctaConfig.buttonText}
            icon={ctaConfig.buttonIcon}
            buttonType="dark"
          />
        )}

        {ctaConfig != null && ctaConfig?.linkTo && (
          <Link to={ctaConfig?.linkTo}>
            <Button
              className="cta-button"
              onClick={() => {}}
              text={ctaConfig.buttonText}
              icon={ctaConfig.buttonIcon}
              buttonType="dark"
            />
          </Link>
        )}
      </div>
      {tabConfig && <ul className="tabs-container">{renderTabs(tabConfig.tabs)}</ul>}
    </div>
  );
};

export default TitleSection;
