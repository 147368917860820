import { IPaymentMethod } from '@gigit/interfaces';
import { CardElement } from '@stripe/react-stripe-js';
import {
  PaymentRequestWallet,
  Stripe,
  PaymentRequest,
  StripeElements,
  StripeCardElement,
} from '@stripe/stripe-js';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { formatCardIcon, routes, defaultCurrency } from '../../../../helpers';
import { IUserState } from '../../../../reducers/user';
import TextField from '../../../TextField/TextField';
import './PaymentMethodComponent.scss';
import GPayLogo from './../../../../assets/g_pay_logo.png';
import { ReactComponent as APayLogo } from './../../../../assets/a_pay_logo.svg';
import { uiConstants } from '../../../../constants/uiConstants';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import { IPaymentMethodChange } from './../../Onboarding/interfaces';

interface IProps {
  stripe: Stripe | null;
  elements: StripeElements | null;
  user: IUserState;
  priceAmount?: number;
  showExistingMethods?: boolean;
  selectDefaultMethod?: boolean;
  defaultPaymentMethodId?: string;
  onNewMethod?: (e: boolean) => void;
  onChange: (e: IPaymentMethodChange) => void;
}

const PaymentMethodComponent = (props: IProps) => {
  const [selectedMethodIndex, setSelectedMethodIndex] = useState<number>(-1);
  const [selectedAltMethodIndex, setSelectedAltMethodIndex] = useState<number>(-1);

  const [supportedAltMethods, setSupportedAltMethods] = useState<string[]>();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);

  const [usingAltMethod, setUsingAltMethod] = useState<boolean>(false);
  const [showExistingMethods, setShowExistingMethods] = useState<boolean>(true);
  const [newPaymentMethod, setNewPaymentMethod] = useState<boolean>(false);
  const [cardName, setCardName] = useState<string>('');
  const [newCardElement, setNewCardElement] = useState<StripeCardElement | null>(null);

  const [altPaymentRequest, setAltPaymentRequest] = useState<PaymentRequest | null>();

  useEffect(() => {
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (props.showExistingMethods) {
      setShowExistingMethods(true);
    }
  }, [props.showExistingMethods]);

  useEffect(() => {
    if (props.stripe) {
      getAltMethods();
    }
  }, [props.stripe]);

  useEffect(() => {
    selectDefaultPaymentMethod();
  }, [paymentMethods]);

  useEffect(() => {
    if (props.defaultPaymentMethodId) {
      selectDefaultPaymentMethod();
    }
  }, [props.defaultPaymentMethodId]);

  function getPaymentMethods() {
    let route = routes.GET_PAYMENT_METHODS;

    axios.get(route).then((response) => {
      setPaymentMethods(response.data);
    });
  }

  function selectDefaultPaymentMethod() {
    if (props.selectDefaultMethod) {
      const isDefault = (method: IPaymentMethod) =>
        method.payment_method_id === props.defaultPaymentMethodId;
      const index = paymentMethods.findIndex((method) => isDefault(method));

      setSelectedMethodIndex(index);
    }
  }

  function getAltMethods() {
    let wallets = uiConstants.altPaymentWallets as PaymentRequestWallet[];
    const pr = (props.stripe as Stripe).paymentRequest({
      country: props.user.user.account?.country || 'CA',
      // Hardcoded currency to USD since all products are priced in USD
      currency: 'usd',
      total: {
        label: 'Purple Plus',
        amount: props.priceAmount ? props.priceAmount : 0,
        pending: true,
      },
      wallets,
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        let alternativeMethods = supportedAltMethods || [];
        if (result.applePay && alternativeMethods.indexOf('apple_pay') === -1) {
          alternativeMethods.push('apple_pay');
        }

        if (result.googlePay && alternativeMethods.indexOf('google_pay') === -1) {
          alternativeMethods.push('google_pay');
        }
        setSupportedAltMethods(alternativeMethods);

        setAltPaymentRequest(pr);
      }
    });
  }

  function setSelectedMethod(index: number, isAltMethod?: boolean) {
    if (isAltMethod) {
      setSelectedAltMethodIndex(index);
      setSelectedMethodIndex(-1);
      setUsingAltMethod(true);

      const changeEvent: IPaymentMethodChange = {
        isAltMethod: true,
        isNewMethod: false,
        stripe: props.stripe,
        paymentMethod: altPaymentRequest as PaymentRequest,
      };

      props.onChange(changeEvent);
    } else {
      setSelectedMethodIndex(index);
      setSelectedAltMethodIndex(-1);
      setUsingAltMethod(false);

      const changeEvent: IPaymentMethodChange = {
        isAltMethod: false,
        isNewMethod: false,
        stripe: props.stripe,
        paymentMethod: paymentMethods[index],
      };

      props.onChange(changeEvent);
    }
  }

  function onCardChange(e: StripeElementChangeEvent) {
    if (e.complete && props.elements) {
      const cardElement = props.elements.getElement('card');
      setNewCardElement(cardElement);

      const changeEvent: IPaymentMethodChange = {
        isAltMethod: false,
        isNewMethod: true,
        paymentMethod: cardElement as StripeCardElement,
        stripe: props.stripe,
        cardName: cardName,
        customer: paymentMethods.length ? paymentMethods[0].customer_id : null,
      };

      props.onChange(changeEvent);
    }
  }

  return (
    <div className="PaymentMethodComponent">
      <div className="component-title">Payment method</div>
      <div className="pm-inner">
        {newPaymentMethod && (
          <div className="new-cc">
            <div className="overlay-inner">
              <div className="input-wrapper">
                <TextField
                  required={true}
                  label="Name on Card"
                  value={cardName}
                  name="cardName"
                  type="text"
                  onChange={(e) => {
                    setCardName(e.target.value);
                  }}
                />
                <div className="card-wrap">
                  <label>Credit Card</label>
                  <div
                    className="card-inner"
                    notranslate="yes"
                  >
                    <CardElement
                      onChange={(e: StripeElementChangeEvent) => onCardChange(e)}
                      options={{ hidePostalCode: true }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setNewPaymentMethod(false);
                setShowExistingMethods(true);
                if (typeof props.onNewMethod == 'function') {
                  props.onNewMethod(false);
                }
              }}
              className="pm new"
            >
              <span>Saved Payment Methods</span>
            </div>
          </div>
        )}

        {!newPaymentMethod && (
          <div
            onClick={() => {
              setShowExistingMethods(false);
              setNewPaymentMethod(true);
              if (typeof props.onNewMethod == 'function') {
                props.onNewMethod(true);
              }
            }}
            className="pm new"
          >
            <span>New Payment Method</span>
            <i className="far fa-plus" />
          </div>
        )}

        {showExistingMethods &&
          paymentMethods &&
          paymentMethods.map((item: IPaymentMethod, index) => {
            return (
              <div
                onClick={() => {
                  setSelectedMethod(index, false);
                }}
                className="pm"
                key={index}
              >
                <i className={formatCardIcon(item.card?.brand ?? '')} />
                <span>
                  ending in <var data-var="credit_card_last_4_digits">{item.card?.last4}</var>
                </span>
                <i className={selectedMethodIndex === index ? 'fas fa-circle' : 'far fa-circle'} />
              </div>
            );
          })}
        {showExistingMethods &&
          supportedAltMethods &&
          supportedAltMethods.map((item: string, index: number) => {
            return (
              <div
                className="alt-methods-container pm"
                onClick={() => {
                  setSelectedMethod(index, true);
                }}
                key={index}
              >
                {item === uiConstants.altPaymentMethods.googlePay ? (
                  <img src={GPayLogo} />
                ) : (
                  <APayLogo />
                )}
                {/*
                                    Following the brand guide lines the methods should not be translated. 
                                    https://developers.google.com/pay/api/web/guides/brand-guidelines | https://developer.apple.com/design/human-interface-guidelines/apple-pay/overview/introduction/
                                */}
                {item === uiConstants.altPaymentMethods.googlePay ? (
                  <span notranslate="yes">Google Pay</span>
                ) : (
                  <span notranslate="yes">Apple Pay</span>
                )}
                <i
                  className={selectedAltMethodIndex === index ? 'fas fa-circle' : 'far fa-circle'}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PaymentMethodComponent;
