import React from 'react';
import { connect } from 'react-redux';
import { IGig, IPage, IPageComponent } from '@gigit/interfaces';
import { errorHelpers, handleInputChange, toastError } from '../../helpers';
import { Constants } from '@gigit/constants';
import { IAppState } from '../../store';
import { History } from 'history';
import { IEventState } from '../../reducers/event';
import { createToast } from '../../actions/toaster';
import { IGroupState } from '../../reducers/group';
import { createGroupGig, createEventGig } from '../../actions/gig';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import Modal from '../Modal/Modal';
import ModalScrollContainer from '../Modal/ModalScrollContainer/ModalScrollContainer';
import TextField from '../TextField/TextField';
import './CreateGigModal.scss';
import { IOwnerObject, IToast } from '../../interfaces';
import typeHelpers from '../../helpers/typeHelpers';
import { uiConstants } from '../../constants';
import { eventRequestActions, groupRequestActions } from '../../requestActions';

export interface IProps {
  groupState: IGroupState;
  eventState: IEventState;

  show: boolean;
  history: History;
  owner: IOwnerObject;

  /** When true, redirects to the newly created gig, otherwise closes the modal. */
  redirectToGigAfterCreate?: boolean;
  createToast(toast: IToast): void;
  createGroupGig(
    groupId: string,
    _payload: any,
    options?: { _updateComponent?: any; shouldGetGig?: boolean; callback?: (gig: IGig) => void },
  ): void;
  createEventGig(
    eventId: string,
    _payload: any,
    options?: { _updateComponent?: any; callback?: (gig: IGig) => void },
  ): void;
  onClose(): void;
}

export interface IState {
  gigName: string;
  gigDesc: string;
  priceAmount: string;
  gigType: 'volunteer' | 'paid';
  priceType: 'hourly' | 'fixed';
  page: IPage | null;
  component: IPageComponent | null;
}

/** Modal for creating new gigs. */
class CreateGigModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      gigName: 'My New Volunteer Opportunity',
      gigDesc: 'A brief description of my volunteer opportunity.',
      gigType: 'volunteer',
      priceType: 'hourly',
      priceAmount: '0',
      page: null,
      component: null,
    };
  }

  async getPages(ownerType: string, ownerId: string) {
    try {
      let pages = [] as IPage[];

      if (ownerType === uiConstants.ownerType.group) {
        pages = await groupRequestActions.getPages(ownerId);
      } else if (ownerType === uiConstants.ownerType.event) {
        pages = await eventRequestActions.getPages(ownerId);
      }

      const tmpPage =
        pages.find((p) =>
          p.components?.map((c) => c.component_type).includes(uiConstants.ownerType.gig),
        ) || null;
      const tmpComponent =
        tmpPage?.components?.find((c) => c.component_type === uiConstants.ownerType.gig) || null;

      this.setState({
        page: tmpPage,
        component: tmpComponent,
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Get Pages');
      this.props.createToast(toast);
    }
  }

  componentDidMount() {
    this.getPages(this.props.owner.ownerType, this.props.owner.ownerId);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.show !== this.props.show && this.props.show) {
      this.setState({
        gigName: 'My New Volunteer Opportunity',
        gigDesc: 'A brief description of my volunteer opportunity.',
        gigType: 'volunteer',
        priceType: 'hourly',
        priceAmount: '0',
      });

      const forms = this.props.groupState.groupForms.map((f) => {
        return { value: f.id, label: f.form_name };
      });

      forms.unshift({ value: '', label: 'None' });
    }
  }

  createGig() {
    let _gig = {
      title: this.state.gigName,
      description: this.state.gigDesc,
      gig_type: this.state.gigType,
      price: {
        amount_type: this.state.priceType,
        amount: this.state.gigType === 'volunteer' ? 0 : parseFloat(this.state.priceAmount),
      },
    };

    const _updateComponent =
      this.state.page && this.state.component
        ? {
            component_id: this.state.component.id,
            page_id: this.state.page.id,
            object_ids: this.state.component.content_references?.object_ids,
          }
        : undefined;

    const callback = (gig: IGig) => {
      if (this.props.redirectToGigAfterCreate) {
        this.props.history.push(`/${uiConstants.ownerType.gig}/${gig.handle}?help=1`);
      }
    };

    if (this.props.owner.ownerType === Constants.object_type.group) {
      this.props.createGroupGig(
        typeHelpers.getGroupIdFromOwner(this.props.owner) || this.props.groupState.group.id,
        _gig,
        { _updateComponent, callback },
      );
    } else if (this.props.owner.ownerType === Constants.object_type.event) {
      this.props.createEventGig(this.props.owner.ownerId, _gig, { _updateComponent, callback });
    }

    this.props.onClose();
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onClose={() => {
          this.props.onClose();
        }}
        title="Create a Volunteer Opportunity"
      >
        <ModalScrollContainer>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.createGig();
            }}
            className="CreateGigModal"
          >
            <div className="row">
              <TextField
                required={true}
                label="Name"
                value={this.state.gigName}
                name="gigName"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="row">
              <TextField
                required={true}
                label="Description"
                value={this.state.gigDesc}
                name="gigDesc"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            {this.state.gigType === 'paid' && (
              <div className="row">
                <Dropdown
                  value={this.state.priceType}
                  label="Payment Type"
                  options={[
                    { value: 'hourly', label: 'Hourly' },
                    { value: 'fixed', label: 'Fixed' },
                  ]}
                  onChange={() => {}}
                  name="priceType"
                />
              </div>
            )}
            {this.state.gigType === 'paid' && (
              <div className="row">
                <TextField
                  required={true}
                  label="Amount ($)"
                  value={this.state.priceAmount}
                  name="priceAmount"
                  type="number"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
              </div>
            )}
            <div className="form-actions">
              <Button
                type="submit"
                text="Create Volunteer Opportunity"
              />
            </div>
          </form>
        </ModalScrollContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {
  createGroupGig,
  createEventGig,
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGigModal);
