import React, { useState, useEffect } from 'react';
import Modal from '../../../../Modal/Modal';
import './CommunityFeedDonationModal.scss';
import Loader from '../../../../Loader/Loader';
import useToastDispatcher from '../../../../../hooks/useToaster';
import { formatQuery, OwnerType, routes, swapRouteParams } from '../../../../../helpers';
import { uiConstants } from '../../../../../constants';
import TextField from '../../../../TextField/TextField';
import { Link } from 'react-router-dom';
import PageComponentPaginator from '../../../../PageComponent/shared/PageComponentPaginator/PageComponentPaginator';
import PopupMenu, {
  IPopupMenuItem,
  IShowPopupConfig,
} from '../../../../shared/PopupMenu/PopupMenu';
import { IDropdownOption } from '../../../Applications/Applications';
import { groupRequestActions, userRequestActions } from '../../../../../requestActions';
import {
  IEmbeddedGroup,
  IEventSummaryFE,
  IGroup,
  IMyAvailableDonationMatchingProgram,
} from '@gigit/interfaces';
import { IGeolocationState } from '../../../../../reducers/geolocation';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import axios from 'axios';
import Portrait from '../../../../Portrait/Portrait';
import { Constants } from '@gigit/constants';

interface IProps {
  showDonateModal: boolean;
  onClose(): void;

  /** Replaces link to donation page with this onSelect handler */
  onSelect?: (donationItem: IDonationItem) => void;
  matchingProgramCauses?: IEmbeddedGroup[] | undefined;
}
//TODO: This interface should be converted to use our generic owner object helpers ?
export interface IDonationItem {
  // Group & Event
  id: string;
  handle: string;
  profile_image_url: string;
  title: string;
  ownerType: OwnerType;
  /** either parent group id or group id... */
  group_id: string;

  // Group
  charity_id?: string;

  //Event
  group_name?: string;
}

interface IFilterByOptions {
  recommended: IDropdownOption;
  recentDonations: IDropdownOption;
  following: IDropdownOption;
  trending: IDropdownOption;
  myHubs: IDropdownOption;
}

const filterByOptions: IFilterByOptions = {
  recommended: { value: 'recommended', label: 'Recommended' },
  recentDonations: { value: 'recently_donated_to', label: 'Recent Donations' },
  following: { value: 'following', label: 'Following' },
  trending: { value: 'discover', label: 'Trending' },
  myHubs: { value: 'myhubs', label: 'My Companies' },
};

//TODO: This file should be converted to use our generic owner object helpers
const CommunityFeedDonationModal: React.FC<IProps> = (props) => {
  const { dispatchToastError } = useToastDispatcher();
  const geolocationState: IGeolocationState = useSelector(
    (state: IAppState) => state.geolocationState,
  );

  const [showSearchAndFilter, setShowSearchAndFilter] = useState<boolean>(true);
  const [donationItems, setDonationItems] = useState<IDonationItem[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [activeFilterByOwnerType, setActiveFilterByOwnerType] = useState<OwnerType>('group');
  const [searchDebounce, setSearchDebounce] = useState<ReturnType<typeof setTimeout>>();
  const [showContextMenuFilterBy, setShowContextMenuFilterBy] = useState<boolean>(false);
  const [filterBy, setFilterBy] = useState<IDropdownOption>(filterByOptions.recommended);

  const getShowMenuClassFilterBy = showContextMenuFilterBy ? 'show' : '';

  const popupMenuConfigFilterBy: IShowPopupConfig = {
    showMenu: showContextMenuFilterBy,
    setShowMenu: setShowContextMenuFilterBy,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItemsFilterBy: IPopupMenuItem[] = [
    {
      id: filterByOptions.recommended.value,
      label: filterByOptions.recommended.label,
      isSelected: filterBy.value === filterByOptions.recommended.value,
      onClick: () => {
        setFilterBy(filterByOptions.recommended);
        setShowContextMenuFilterBy(false);
      },
      hide: activeFilterByOwnerType == 'event' ? true : false,
    },
    {
      id: filterByOptions.recentDonations.value,
      label: filterByOptions.recentDonations.label,
      isSelected: filterBy.value === filterByOptions.recentDonations.value,
      onClick: () => {
        setFilterBy(filterByOptions.recentDonations);
        setShowContextMenuFilterBy(false);
      },
    },
    {
      id: filterByOptions.following.value,
      label: filterByOptions.following.label,
      isSelected: filterBy.value === filterByOptions.following.value,
      onClick: () => {
        setFilterBy(filterByOptions.following);
        setShowContextMenuFilterBy(false);
      },
    },
    {
      id: filterByOptions.trending.value,
      label: filterByOptions.trending.label,
      isSelected: filterBy.value === filterByOptions.trending.value,
      onClick: () => {
        setFilterBy(filterByOptions.trending);
        setShowContextMenuFilterBy(false);
      },
    },
    {
      id: filterByOptions.myHubs.value,
      label: filterByOptions.myHubs.label,
      isSelected: filterBy.value === filterByOptions.myHubs.value,
      onClick: () => {
        setFilterBy(filterByOptions.myHubs);
        setShowContextMenuFilterBy(false);
      },
    },
  ];

  function isInstanceOfGroup(object: any): object is IGroup {
    return 'charity_id' in object;
  }

  function isInstanceOfEvent(object: any): object is IEventSummaryFE {
    return 'group' in object;
  }

  async function getTrending(initRoute: string) {
    const query = formatQuery({ search: searchValue });

    let defaultCoords = uiConstants.defaultCoords;
    let location;

    if (geolocationState) {
      location = {
        coordinates: [geolocationState.lng, geolocationState.lat],
        max_distance: 100,
      };
    } else {
      location = {
        coordinates: [defaultCoords.lng, defaultCoords.lat],
        max_distance: 100,
      };
    }

    try {
      const _payload = {
        location,
        filter: { trending: true },
      };

      let route = swapRouteParams(initRoute, { limit: 120, skip: 0 });
      route += `&${query.toString()}`;

      //TODO: HACK - The actual type returned to this route is IDiscoverItem[]
      const result = await axios.post<IEventSummaryFE[] | IGroup[]>(route, _payload);
      setDonationItems(
        result.data.map((res) => {
          return {
            id: res.id,
            handle: res.handle,
            profile_image_url: res.profile_image_url,
            title: res.title,
            charity_id: isInstanceOfGroup(res) ? res.charity_id : undefined,
            group_name: isInstanceOfEvent(res) ? res.group?.title : undefined,
            group_id: isInstanceOfEvent(res) ? res.group?.id! : res.id,
            ownerType: activeFilterByOwnerType,
          };
        }),
      );
    } catch (error) {
      dispatchToastError(error, 'Get Trending Donation Items');
    }
  }

  async function getDonationItems() {
    try {
      setShowLoading(true);
      if (props.matchingProgramCauses && props.matchingProgramCauses.length > 0) {
        setShowSearchAndFilter(false);
        setDonationItems(
          props.matchingProgramCauses.map((causes) => {
            return {
              id: causes.id,
              handle: causes.handle,
              profile_image_url: causes.profile_image_url,
              title: causes.title,
              group_id: causes.id,
              ownerType: 'group',
            };
          }),
        );
      } else {
        switch (filterBy.value) {
          case 'discover':
            await getTrending(
              activeFilterByOwnerType === 'event' ? routes.DISCOVER_EVENTS : routes.DISCOVER_GROUPS,
            );
            break;
          case 'recommended': {
            let result = await groupRequestActions.getRecommendedCauses();
            setDonationItems(
              result.map((res) => {
                return {
                  id: res.id,
                  handle: res.handle,
                  profile_image_url: res.profile_image_url,
                  title: res.title,
                  charity_id: isInstanceOfGroup(res) ? res.charity_id : undefined,
                  group_name: isInstanceOfEvent(res) ? res.group?.title : undefined,
                  ownerType: activeFilterByOwnerType,
                  group_id: res.id,
                };
              }),
            );
            break;
          }
          case 'following':
          case 'recently_donated_to':
          case 'myhubs': {
            const route = `/users/current/${filterBy.value}/${activeFilterByOwnerType === 'event' ? 'event' : 'group'}`;
            const result = await userRequestActions.getDonationItems(
              route,
              formatQuery({
                search: searchValue,
                filters: [
                  {
                    value:
                      activeFilterByOwnerType === 'event'
                        ? Constants.event_status.archived
                        : Constants.group_status.archived,
                    label: 'status_code',
                    id: 'status.code',
                    prefix: '<>',
                    type: 'with_prefix',
                  },
                ],
              }),
            );

            setDonationItems(
              result.map((res) => {
                return {
                  id: res.id,
                  handle: res.handle,
                  profile_image_url: res.profile_image_url,
                  title: res.title,
                  charity_id: isInstanceOfGroup(res) ? res.charity_id : undefined,
                  group_name: isInstanceOfEvent(res) ? res.group?.title : undefined,
                  ownerType: activeFilterByOwnerType,
                  group_id: isInstanceOfEvent(res) ? res.group?.id! : res.id,
                };
              }),
            );
            break;
          }
        }
      }
    } catch (error) {
      dispatchToastError(error, 'Get Donation Items');
    } finally {
      setShowLoading(false);
    }
  }

  function renderListItems(pagedItems: Array<IDonationItem>) {
    const { onSelect } = props;

    if (onSelect != null) {
      {
        return pagedItems.map((item, index) => (
          <div
            className="list-item-container"
            onClick={() => onSelect(item)}
            key={index}
          >
            <div className="item">
              <Portrait
                size={40}
                currentImage={item.profile_image_url}
              />
              <div className="info">
                <span
                  className="title"
                  notranslate="yes"
                >
                  {item.title}
                </span>

                {item?.charity_id && (
                  <span className="charity-id">
                    {`Charity ID : `}
                    <var data-var="charity_id">{item.charity_id}</var>
                  </span>
                )}

                {item?.group_name && (
                  <span className="group-name">
                    <var data-var="group_name">{item.group_name}</var>
                  </span>
                )}
              </div>
            </div>
          </div>
        ));
      }
    } else {
      {
        return pagedItems.map((item, index) => (
          <Link
            className="list-item-container"
            to={`/${activeFilterByOwnerType === 'event' ? 'event' : 'group'}/${item.handle}/donate`}
            key={index}
          >
            <div className="item">
              <Portrait
                size={40}
                currentImage={item.profile_image_url}
              />
              <div className="info">
                <span
                  className="title"
                  notranslate="yes"
                >
                  {item.title}
                </span>

                {item?.charity_id && (
                  <span className="charity-id">
                    {`Charity ID : `}
                    <var data-var="charity_id">{item.charity_id}</var>
                  </span>
                )}

                {item?.group_name && (
                  <span className="group-name">
                    <var data-var="group_name">{item.group_name}</var>
                  </span>
                )}
              </div>
            </div>
          </Link>
        ));
      }
    }
  }

  useEffect(() => {
    if (searchValue !== '') {
      if (searchValue !== undefined) {
        if (searchDebounce) {
          clearTimeout(searchDebounce);
        }
        setSearchDebounce(
          setTimeout(() => {
            getDonationItems();
          }, 500),
        );
      }
    }
  }, [searchValue]);

  useEffect(() => {
    getDonationItems();
  }, [filterBy, activeFilterByOwnerType, props.matchingProgramCauses]);

  return (
    <Modal
      class="CommunityFeedDonationModal"
      show={props.showDonateModal}
      onClose={props.onClose}
      closeIcon="fas fa-times"
      title="Make a Donation"
    >
      {showSearchAndFilter && (
        <div className="search-container">
          <TextField
            autoComplete="off"
            placeholder="Keyword"
            icon="fal fa-search"
            value={searchValue}
            name="search"
            type="text"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />

          <PopupMenu
            showMenuConfig={popupMenuConfigFilterBy}
            menuItems={contextMenuItemsFilterBy}
            popupMenuClass="community-feed-donation-popupMenu-filter-by"
            className={`Dropdown filter-dropdown filter-by ${getShowMenuClassFilterBy}`}
            onClick={() => setShowContextMenuFilterBy(!showContextMenuFilterBy)}
          >
            <label>
              <span>Filter</span>
            </label>
            <div className="input-wrap">
              <span className="label">{filterBy.label}</span>
              <i className={`fas fa-caret-down menu-btn ${getShowMenuClassFilterBy}`} />
            </div>
          </PopupMenu>
        </div>
      )}

      <PageComponentPaginator
        itemsPerPage={+uiConstants.showGroupsInList}
        items={donationItems}
      >
        {(pagedItems: IDonationItem[]) => (
          <>
            <div className="top-container">
              {!props.matchingProgramCauses && (
                <div className="switch-btn-container">
                  <span
                    className={`${activeFilterByOwnerType === 'group' ? 'active' : ''}`}
                    onClick={() => setActiveFilterByOwnerType('group')}
                  >
                    Causes
                  </span>
                  <span
                    className={`${activeFilterByOwnerType === 'event' ? 'active' : ''}`}
                    onClick={() => setActiveFilterByOwnerType('event')}
                  >
                    Events
                  </span>
                </div>
              )}
              <div className="count">
                {`Showing `}
                <var data-var="length">
                  {donationItems.length > +uiConstants.showGroupsInList
                    ? uiConstants.showGroupsInList
                    : donationItems.length}
                </var>
                {` of `}
                <var data-var="max_length">{donationItems.length}</var>
                {` ${activeFilterByOwnerType}`}
              </div>
            </div>

            <div className="list">
              {renderListItems(pagedItems)}
              {showLoading && <Loader loading={showLoading} />}

              {!showLoading && donationItems.length < 1 && (
                <div className="empty">
                  <p>There are no results for {activeFilterByOwnerType}</p>
                </div>
              )}
            </div>
          </>
        )}
      </PageComponentPaginator>
    </Modal>
  );
};

export default CommunityFeedDonationModal;
