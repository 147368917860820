import React, { useState } from 'react';
import { ReactComponent as ApplicationsIcon } from '../../../../assets/dashboard/activity/applications-icon.svg';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { IApplication } from '@gigit/interfaces';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../../shared/PopupMenu/PopupMenu';
import { Link } from 'react-router-dom';
import { capitalizeString } from '../../../../helpers';
import { Constants } from '@gigit/constants';

interface IPropsItemGrid {
  keyValue: string;
  application: IApplication;
  locale: string;
  setActive(): void;
  contextMenuItems: IPopupMenuItem[];
}

export const ApplicationItemGrid: React.FC<IPropsItemGrid> = (props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const popupMenuConfig: IShowPopupConfig = {
    showMenu: showMenu,
    setShowMenu: setShowMenu,
    position: {
      type: 'bottom',
      alternate: 'start',
      additionalLeft: 36,
    },
  };

  return (
    <div
      className="application-grid"
      key={props.keyValue}
      onClick={() => {
        props.setActive();
      }}
    >
      <div className="card-top">
        <div className="card-label">
          <span
            notranslate="yes"
            className="label"
          >
            {props.application.gig ? props.application.gig.title : 'N/A'}
          </span>
          <PopupMenu
            showMenuConfig={popupMenuConfig}
            menuItems={props.contextMenuItems}
            popupMenuClass="grid-menu-content"
            className={`${showMenu ? 'show' : ''}`}
            onClick={(event) => {
              event.stopPropagation();
              setShowMenu(!showMenu);
            }}
          >
            <span className="menu">
              <i className="fas fa-ellipsis-v" />
            </span>
          </PopupMenu>
        </div>
        <span className="group-name-content">
          <span className="logo">
            {props.application?.group?.profile_image_url ? (
              <img
                src={props.application?.group?.profile_image_url}
                alt="group logo"
              />
            ) : (
              <ApplicationsIcon />
            )}
          </span>
          <span
            notranslate="yes"
            className="label"
          >
            <Link to={'/group/' + props.application?.group?.handle}>
              {props.application?.group?.title}
            </Link>
          </span>
        </span>
        <span
          className="location-content"
          notranslate="yes"
        >
          <span className="icon">
            <i className="far fa-map-marker-alt" />
          </span>
          {props?.application?.gig_location
            ? `${props.application.gig_location.city}, ${props.application.gig_location.state}`
            : 'N/A'}
        </span>
        {props.application?.created_at && (
          <span
            className="date-content"
            notranslate="yes"
          >
            {localizeHelpers.translate('Applied on {{date}}', {
              date: localizeHelpers.formatDate(
                props.application.created_at,
                LocaleDateFormats.ll,
                props.locale,
              ),
            })}
          </span>
        )}
      </div>
      <div className="card-bottom">
        {props.application?.status?.code && (
          <span className={`tag ${props.application.status.code}`}>
            {props.application.status.code === Constants.application_status.rejected
              ? capitalizeString('not accepted')
              : capitalizeString(props.application.status.code)}
          </span>
        )}
      </div>
    </div>
  );
};

interface IPropsItemList {
  application: IApplication;
  keyValue: string;
  locale: string;
  setActive(): void;
  contextMenuItems: IPopupMenuItem[];
}
export const ApplicationItemList: React.FC<IPropsItemList> = (props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const popupMenuConfig: IShowPopupConfig = {
    showMenu: showMenu,
    setShowMenu: setShowMenu,
    position: {
      type: 'bottom',
      alternate: 'start',
      additionalLeft: 151,
    },
  };

  return (
    <div
      key={props.keyValue}
      className="application-card"
      onClick={() => {
        props.setActive();
      }}
    >
      <div className="card-label">
        <div className="icon">
          {props.application?.group?.profile_image_url ? (
            <img
              src={props.application?.group?.profile_image_url}
              alt="group logo"
            />
          ) : (
            <ApplicationsIcon />
          )}
        </div>
        <div className="labels">
          <span className="title">
            {props.application.gig ? props.application.gig.title : 'N/A'}
          </span>
          <span className="subtitle">
            <Link to={'/group/' + props.application?.group?.handle}>
              By {props.application?.group?.title}
            </Link>
          </span>
        </div>
      </div>
      <div className="date-content">
        {props.application?.created_at && (
          <span className="col">
            {localizeHelpers.translate('Applied on {{date}}', {
              date: localizeHelpers.formatDate(
                props.application.created_at,
                LocaleDateFormats.ll,
                props.locale,
              ),
            })}
          </span>
        )}
      </div>
      <div
        className="location-content"
        notranslate="yes"
      >
        <span className="icon">
          <i className="far fa-map-marker-alt" />
        </span>
        {`${props.application?.gig_location?.city}, ${props.application?.gig_location?.state}`}
      </div>
      <div className="tag-container">
        {props.application?.status?.code && (
          <span className={`tag ${props.application.status.code}`}>
            {props.application.status.code === Constants.application_status.rejected
              ? capitalizeString('not accepted')
              : capitalizeString(props.application.status.code)}
          </span>
        )}
      </div>
      <PopupMenu
        showMenuConfig={popupMenuConfig}
        menuItems={props.contextMenuItems}
        popupMenuClass="grid-menu-content"
        className={`${showMenu ? 'show' : ''}`}
        onClick={(event) => {
          event.stopPropagation();
          setShowMenu(!showMenu);
        }}
      >
        <div className="menu">
          <i className="fas fa-ellipsis-v" />
        </div>
      </PopupMenu>
    </div>
  );
};
