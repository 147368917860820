import {
  IAuctionItem,
  IAuctionItemSummary,
  ITransactionItem,
  ITransactionItemSummary,
} from '@gigit/interfaces';
import axios from 'axios';
import { Dispatch } from 'redux';
import { routes, swapRouteParams, toastError, toastSuccess, uploadMedias } from '../helpers';
import errorHelpers from '../helpers/errorHelpers';
import { localizeHelpers } from '../localizeHelpers';
import { registerItem } from './group';
import { createToast } from './toaster';
import { AxiosResponse } from 'axios';

export enum AuctionActionTypes {
  UPDATE_AUCTION = 'UPDATE_AUCTION',
}

export interface IUpdateAuctionActions {
  type: AuctionActionTypes.UPDATE_AUCTION;
}

export type AuctionActions = IUpdateAuctionActions;

/** Fields for updating an Auction Item.
 * We use Omit<> to change the type of some fields.
 */
export interface IUpdateAuctionItemParams
  extends Partial<Omit<IAuctionItem, 'start_date' | 'end_date'>> {
  start_date?: string;
  end_date?: string;
}

/** Valid owner object types that can own auction items. */
export type AuctionItemOwnerType = 'group' | 'event';

export const getAuctionPaymentSummary = (_id: string, _payload: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      paymentSummaryLoading: true,
      type: AuctionActionTypes.UPDATE_AUCTION,
    });

    axios
      .post(swapRouteParams(routes.GET_AUCTION_SUMMARY, { id: _id }), _payload)
      .then((response) => {
        dispatch({
          paymentSummary: response.data,
          type: AuctionActionTypes.UPDATE_AUCTION,
        });
      })
      .catch(() => {
        dispatch({
          paymentSummary: null,
          type: AuctionActionTypes.UPDATE_AUCTION,
        });
      })
      .finally(() => {
        dispatch({
          paymentSummaryLoading: false,
          type: AuctionActionTypes.UPDATE_AUCTION,
        });
      });
  };
};

export const createAuctionPaymentIntent = (
  groupId: string,
  _id: string,
  _payload: any,
  callback?: any,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    axios
      .post(swapRouteParams(routes.CREATE_AUCTION_PAYMENT_INTENT, { id: _id }), _payload)
      .then((response) => {
        if (response.data.is_virtual) {
          if (response.data.client_secret) {
            response.data.client_secret.forEach((key: string, i: number) => {
              registerItem(groupId, key)(dispatch, getState);
              if (i === response.data.client_secret.length - 1) {
                if (callback) {
                  callback?.();
                }
              }
            });
          }
        }

        dispatch({
          buyNowIntent: null,
          paymentIntent: response.data,
          type: AuctionActionTypes.UPDATE_AUCTION,
        });
      })
      .catch(() => {
        dispatch({
          paymentIntent: null,
          type: AuctionActionTypes.UPDATE_AUCTION,
        });
      });
  };
};

export const resetAuctionPaymentData = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      paymentSummary: null,
      paymentIntent: null,
      type: AuctionActionTypes.UPDATE_AUCTION,
    });
  };
};

export const createBuyNowPaymentIntent = (
  groupId: string,
  eventId: string | undefined,
  _id: string,
  _payload: any,
  callback?: any,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      buyNowError: null,
      type: AuctionActionTypes.UPDATE_AUCTION,
    });
    const ownerType = eventId ? 'event' : 'group';
    const ownerId = eventId ? eventId : groupId;

    const route =
      ownerType === 'group' ? routes.CREATE_BUY_NOW_INTENT : routes.CREATE_EVENT_BUY_NOW_INTENT;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .post(swapRouteParams(route, { [idField]: ownerId, id: _id }), _payload)
      .then((response) => {
        if (response.data.is_virtual) {
          if (response.data.client_secret) {
            response.data.client_secret.forEach((key: string, i: number) => {
              registerItem(groupId, response.data.client_secret, _id)(dispatch, getState);
              if (i === response.data.client_secret.length - 1) {
                if (callback) {
                  callback?.();
                }
              }
            });
          }
        }
        dispatch({
          paymentIntent: null,
          buyNowIntent: response.data,
          type: AuctionActionTypes.UPDATE_AUCTION,
        });
      })
      .catch((error: any) => {
        const errorObj = errorHelpers.getErrorObject(error);
        dispatch({
          buyNowError: errorObj.translatedMessage,
          buyNowIntent: null,
          type: AuctionActionTypes.UPDATE_AUCTION,
        });
      });
  };
};

export const resetBuyNowData = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      buyNowIntent: null,
      buyNowError: null,
      type: AuctionActionTypes.UPDATE_AUCTION,
    });
  };
};

export const getAuctionTransactions = (
  ownerType: AuctionItemOwnerType,
  ownerId: string,
  query?: URLSearchParams,
  callback?: (results: ITransactionItemSummary[]) => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      auctionPaymentsLoading: true,
      type: AuctionActionTypes.UPDATE_AUCTION,
    });

    let route: string =
      ownerType === 'group'
        ? routes.GET_GROUP_AUCTION_TRANSACTIONS
        : routes.GET_EVENT_AUCTION_TRANSACTIONS;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    if (query) {
      route += `?${query}`;
    }

    let limit = query?.get('limit');

    axios
      .get(swapRouteParams(route, { [idField]: ownerId }))
      .then((response) => {
        let originalResponse = [...response.data];
        if (limit && originalResponse.length > parseInt(limit) - 1) {
          response.data.pop();
        }
        dispatch({
          auctionPayments: response.data,
          type: AuctionActionTypes.UPDATE_AUCTION,
        });

        if (callback) {
          callback(originalResponse);
        }
      })
      .finally(() => {
        dispatch({
          auctionPaymentsLoading: false,
          type: AuctionActionTypes.UPDATE_AUCTION,
        });
      });
  };
};

export const sendAuctionPaymentReceipt = (
  ownerType: AuctionItemOwnerType,
  ownerId: string,
  transactionId: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    let route: string =
      ownerType === 'group' ? routes.SEND_GROUP_AUCTION_RECEIPT : routes.SEND_EVENT_AUCTION_RECEIPT;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .post(swapRouteParams(route, { [idField]: ownerId, transactionId: transactionId }))
      .then((response) => {
        const toast = toastSuccess(
          localizeHelpers.translate('Auction Receipt sent successfully'),
          'Send Auction Receipt',
        );
        createToast(toast)(dispatch, getState);
      })
      .catch((err) => {
        const errObj = errorHelpers.getErrorObject(err);
        const toast = toastError(errObj.translatedMessage, 'Send Auction Receipt');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const refundAuctionPayment = (
  ownerType: AuctionItemOwnerType,
  ownerId: string,
  _id: string,
  _payload: any,
  callback: (transactionItem: ITransactionItem) => void,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group' ? routes.REFUND_AUCTION_ITEM : routes.REFUND_EVENT_AUCTION_ITEM;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .post(swapRouteParams(route, { [idField]: ownerId, item_id: _id }), _payload)
      .then((response) => {
        const toast = toastSuccess(
          localizeHelpers.translate('Auction Payment Refunded.'),
          'Auction Payments',
        );
        createToast(toast)(dispatch, getState);

        callback?.(response.data);
      })
      .then(() => {
        getAuctionTransactions(ownerType, ownerId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);

        let toast = toastError(errorObj.translatedMessage, 'Refund');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getAuctionsExport = (
  ownerType: AuctionItemOwnerType,
  ownerId: string,
  ownerHandle: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group' ? routes.GET_AUCTIONS_EXPORT : routes.GET_EVENT_AUCTIONS_EXPORT;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .get(swapRouteParams(route, { [idField]: ownerId }), { responseType: 'blob' })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'Auctions_for_' + ownerHandle + '.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Auctions Export');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getAuctionsExportDaily = (
  ownerType: AuctionItemOwnerType,
  ownerId: string,
  ownerHandle: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group'
        ? routes.GET_DAILY_AUCTION_WINNERS
        : routes.GET_EVENT_DAILY_AUCTION_WINNERS;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .get(swapRouteParams(route, { [idField]: ownerId }), { responseType: 'blob' })
      .then((response) => {
        let _blob = new Blob([response.data], { type: 'text/csv' });

        let _file = URL.createObjectURL(_blob);
        let _a = document.createElement('a');
        _a.href = _file;
        _a.download = 'Auctions_for_' + ownerHandle + '_daily_winners.csv';
        document.body.appendChild(_a);
        _a.click();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Daily Auction Winners Export');
        createToast(toast)(dispatch, getState);
      });
  };
};
