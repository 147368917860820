import React, { ReactNode } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  routes,
  swapRouteParams,
  handleInputChange,
  toastError,
  toastSuccess,
  defaultCurrency,
  combineClassNames,
  setSEOMetatags,
} from '../../helpers';
import { IEventWidget, IEventSummaryFE, IEventIndividual } from '@gigit/interfaces';
import { IEventState } from '../../reducers/event';
import { createToast } from '../../actions/toaster';
import { getEvent } from '../../actions/event';
import { IAppState } from '../../store';
import { IToast } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import { Constants } from '@gigit/constants';
import ProgressBarWidget from '../../components/widgets/ProgressBarWidget/ProgressBarWidget';
import { IUserState } from '../../reducers/user';
import errorHelpers from '../../helpers/errorHelpers';
import AlertBoxWidget, {
  AlertBoxDisplayState,
} from '../../components/widgets/AlertBoxWidget/AlertBoxWidget';
import { updateUserCoverImage, updateUserProfileImage, getUserInfo } from '../../actions/user';
import ManagementSidebar, {
  IMenuSection,
} from '../../components/ManagementSidebar/ManagementSidebar';
import { individualRequestActions } from '../../requestActions/individual';
import QuillTextEditor from '../../components/QuillTextEditor/QuillTextEditor';
import Portrait from '../../components/Portrait/Portrait';
import TextField from '../../components/TextField/TextField';
import CoverImage from '../../components/CoverImage/CoverImage';
import Button from '../../components/Button/Button';
import './IndividualManage.scss';

interface IWidgetVariant {
  widget_theme: string;
  widget_style: string;
}
interface IProps extends RouteComponentProps<any> {
  createToast(toast: IToast): void;
  eventState: IEventState;
  userState: IUserState;
  getEvent(id: string): void;
  updateUserCoverImage(payload: any): void;
  updateUserProfileImage(payload: any): void;
  getUserInfo(handle: string, cb?: any): void;
}

interface IState {
  activeTab: string;
  widgets: IEventWidget[];
  showWidgets: boolean[];
  goal: string;
  story: string;
  event: IEventSummaryFE | null;
  individual: IEventIndividual | null;
  eventIndividual: IEventIndividual | null;
}

class IndividualManage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: 'overview',
      widgets: [],
      showWidgets: [],
      goal: '0',
      story: '',
      event: null,
      individual: null,
      eventIndividual: null,
    };

    this.isActiveTab = this.isActiveTab.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.copyText = this.copyText.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.saveStory = this.saveStory.bind(this);
    this.handleStoryChange = this.handleStoryChange.bind(this);
    this.getWidgets = this.getWidgets.bind(this);
  }

  componentDidMount() {
    this.props.getEvent(this.props.match.params.eventHandle);
    this.props.getUserInfo(this.props.userState.user.handle);

    Axios.get(
      swapRouteParams(routes.GET_EVENT, { handleOrId: this.props.match.params.eventHandle }),
    ).then((event) => {
      if (event.data && event.data.id) {
        this.setState(
          {
            event: event.data,
          },
          () => {
            Axios.get(
              swapRouteParams(routes.GET_EVENT_INDIVIDUAL, {
                eventId: event.data.id,
                userHandleOrId: this.props.match.params.individualHandle,
              }),
            ).then((individual) => {
              this.setState(
                {
                  individual: individual.data,
                },
                () => {
                  this.getWidgets();
                },
              );
            });
          },
        );
      }
    });
  }

  getWidgets() {
    if (this.state.event !== null && this.state.individual !== null) {
      Axios.get(
        swapRouteParams(routes.GET_STREAM_WIDGETS, {
          eventId: this.state.event.id,
          individualId: this.state.individual?.user?.id,
        }),
      ).then((widgets) => {
        this.setState(
          {
            widgets: widgets.data,
          },
          () => {
            let _settings: boolean[] = [];

            for (let w = 0; w < this.state.widgets.length; w++) {
              _settings.push(false);
            }

            this.setState({
              showWidgets: _settings,
            });
          },
        );
      });
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.eventState.event !== prevProps.eventState.event &&
      this.props.eventState.event.id !== ''
    ) {
      this.getIndividual(this.props.eventState.event.id, this.props.match.params.individualHandle);
    }

    if (this.state.eventIndividual !== prevState.eventIndividual) {
      this.setState({
        goal: this.state.eventIndividual?.goal?.toString() || '0',
        story: this.state.eventIndividual?.story || '',
      });
      if (this.state.eventIndividual && this.state.eventIndividual.user) {
        const {
          handle: userHandle,
          first_name,
          last_name,
          profile_image_url,
          cover_image_url,
        } = this.state.eventIndividual.user;
        const { handle: eventHandle } = this.props.eventState.event;

        setSEOMetatags({
          urlPath: `event/${eventHandle}/individual/${userHandle}`,
          title: `${first_name} ${last_name}`,
          description: undefined,
          imageURL: profile_image_url,
          coverImageURL: cover_image_url,
        });
      }
    }

    if (
      this.state.eventIndividual?.content_creator !== prevState.eventIndividual?.content_creator
    ) {
      this.getWidgets();
    }
  }

  isActiveTab(tab: string) {
    return tab === this.state.activeTab ? `tab active ${tab}` : `tab ${tab}`;
  }

  setActiveTab(tab: string) {
    this.setState({
      activeTab: tab,
    });
  }

  copyText(text: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        () => {
          const toast = toastSuccess(
            localizeHelpers.translate('URL successfully copied.'),
            'Streamer Overlay',
          );
          this.props.createToast(toast);
        },
        (err: any) => {
          const toast = toastError(
            localizeHelpers.translate('Unable to copy URL.'),
            'Streamer Overlay',
          );
          this.props.createToast(toast);
        },
      );
    }
  }

  toggleVisibility(index: number) {
    let _settings: boolean[] = [...this.state.showWidgets];

    _settings[index] = !_settings[index];

    this.setState({
      showWidgets: _settings,
    });
  }

  saveStory() {
    this.updateIndividual(this.props.eventState.event.id, this.props.userState.user.id!, {
      story: this.state.story,
    });

    const toast = toastSuccess(
      localizeHelpers.translate('Story successfully saved.'),
      'Individual Management',
    );
    this.props.createToast(toast);
  }

  handleStoryChange(content: string) {
    this.setState({ story: content });
  }

  onImageChange = (fileData: any) => {
    switch (fileData.type) {
      case 'cover_image_url':
        this.props.updateUserCoverImage(fileData.file);
        break;
      case 'profile_image_url':
        this.props.updateUserProfileImage(fileData.file);
        break;
    }
  };

  getSidebarMenuOptions(): IMenuSection[] {
    const baseOptions = [{ id: 'overview', title: 'overview' }];
    const shouldEnableWidgets =
      this.state.eventIndividual?.user?.twitch && this.state.eventIndividual.content_creator;
    const menuOptions = shouldEnableWidgets
      ? [...baseOptions, { id: 'widgets', title: 'widgets' }]
      : baseOptions;
    return [
      {
        id: 'setup',
        sectionTitle: 'Setup',
        sectionIcon: 'fas fa-sliders-v',
        menuOptions: menuOptions,
      },
    ];
  }

  async updateIndividual(eventId: string, userId: string, payload: Partial<IEventIndividual>) {
    try {
      await individualRequestActions.updateIndividual(eventId, payload);
      this.getIndividual(eventId, userId);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Individual');
      createToast(toast);
    }
  }

  async getIndividual(eventId: string, userId: string) {
    try {
      const eventIndividual = await individualRequestActions.getIndividual(eventId, userId);
      this.setState({ eventIndividual });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Individual');
      createToast(toast);
    }
  }

  async toggleEventIndividualFitness(eventId: string, userId: string) {
    try {
      await individualRequestActions.toggleEventIndividualFitness(eventId, userId);
      this.getIndividual(eventId, userId);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Toggle Individual Fitness');
      createToast(toast);
    }
  }

  render() {
    const userHasTwitch = this.state.eventIndividual?.user?.twitch != null;
    const userHasStrava = this.props.userState.user?.strava != null;

    return (
      <div className="IndividualManage">
        <ManagementSidebar
          logo={this.props.userState.user?.profile_image_url || ''}
          title="Individual Management"
          targetLink={{
            url:
              '/event/' +
              this.props.match.params.eventHandle +
              '/individual/' +
              this.props.match.params.individualHandle,
            title: this.state.eventIndividual?.user?.display_name || '',
          }}
          menuSections={this.getSidebarMenuOptions()}
          activeOptionId={this.state.activeTab}
          onMenuOptionClicked={(menuOptionId: string) => this.setState({ activeTab: menuOptionId })}
        />
        <div className={'section ' + this.state.activeTab + '-wrap'}>
          {this.state.activeTab === 'overview' && (
            <div className="section-wrap">
              <div className="section-title">Settings</div>
              <div className="section-inner">
                <div className="settings-title">General</div>
                <div className="settings-item hover-item no-select">
                  <div className="details">
                    <p>Fundraising Goal</p>
                    <span className="detail">Set your individual fundraising goal.</span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="expansion"
                  >
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.updateIndividual(
                          this.props.eventState.event.id,
                          this.props.userState.user.id!,
                          { goal: Number(this.state.goal) },
                        );
                      }}
                      className="email-wrap"
                    >
                      <TextField
                        label="Goal ($)"
                        value={this.state.goal}
                        name="goal"
                        type="text"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <Button
                        isDisabled={
                          this.state.goal === this.state.eventIndividual?.goal?.toString()
                        }
                        type="submit"
                        text="Update"
                      />
                    </form>
                  </div>
                </div>
                <div className="settings-item hover-item">
                  <div className="details">
                    <p>Story</p>
                    <span className="detail">Write a short description about yourself.</span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="expansion"
                  >
                    <QuillTextEditor
                      value={this.state.story}
                      readOnly={false}
                      preserveWhitespace={true}
                      theme="snow"
                      onChange={this.handleStoryChange}
                    />
                    <div className="expansion-actions">
                      <Button
                        text="Save Story"
                        onClick={() => {
                          this.saveStory();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="settings-item hover-item no-select">
                  <div className="details">
                    <p>Profile Image</p>
                    <span className="detail">Set your profile image.</span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="expansion"
                  >
                    <Portrait
                      size={100}
                      currentImage={this.props.userState.user.profile_image_url}
                      overlay={true}
                      onChange={this.onImageChange}
                    />
                  </div>
                </div>
                <div className="settings-item hover-item no-select">
                  <div className="details">
                    <p>Cover Image</p>
                    <span className="detail">Set your profile cover image.</span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="expansion"
                  >
                    <CoverImage
                      currentImage={this.props.userState.user.cover_image_url}
                      onChange={this.onImageChange}
                      overlay={true}
                    />
                  </div>
                </div>
                {this.state.eventIndividual && this.state.eventIndividual.user && (
                  <div className="settings-item">
                    <div className="details">
                      <p>Content Creator</p>
                      <span className="detail">
                        Are you planning to livestream your fundraiser on twitch?
                      </span>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          if (!this.state.eventIndividual?.content_creator && !userHasTwitch) {
                            const toast = toastError(
                              localizeHelpers.translate('Twitch Account must be connected'),
                              'Twitch',
                              [
                                {
                                  link: {
                                    link: '/dashboard?section=settings',
                                    text: 'Account Settings',
                                  },
                                },
                              ],
                            );
                            this.props.createToast(toast);
                            return;
                          }
                          this.updateIndividual(
                            this.props.eventState.event.id,
                            this.props.userState.user.id!,
                            { content_creator: !this.state.eventIndividual?.content_creator },
                          );
                        }}
                        className={
                          this.state.eventIndividual.content_creator
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      ></i>
                    </div>
                  </div>
                )}
                {this.state.eventIndividual?.user && (
                  <div className="settings-item">
                    <div className="details">
                      <p>Strava</p>
                      <span className="detail">
                        Are you planning on integrating Strava with this fundraiser?
                      </span>
                    </div>
                    <div className="settings-control">
                      <i
                        onClick={() => {
                          if (
                            !this.state.eventIndividual?.fitness_tracking_enabled &&
                            !userHasStrava
                          ) {
                            const toast = toastError(
                              localizeHelpers.translate('Strava Account must be connected'),
                              'Strava',
                              [
                                {
                                  link: {
                                    link: '/dashboard?section=settings',
                                    text: 'Account Settings',
                                  },
                                },
                              ],
                            );
                            this.props.createToast(toast);
                            return;
                          }
                          this.toggleEventIndividualFitness(
                            this.state.eventIndividual!.event_id,
                            this.state.eventIndividual!.user?.handle!,
                          );
                        }}
                        className={
                          this.state.eventIndividual.fitness_tracking_enabled
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {this.state.activeTab === 'widgets' && (
            <div className="section-wrap">
              <div className="section-title">Overlays</div>
              <div className="section-inner">
                {this.state.widgets.map((widget, index) => {
                  return (
                    <div
                      key={index}
                      className={this.state.showWidgets[index] ? 'widget' : 'widget blur'}
                    >
                      <div className="title">
                        {widget.widget_type === 'alert_box' ? 'Alert Box' : 'Progress Bar'}
                      </div>
                      <div className="widget-variant-container">
                        {this.renderWidgetVariants(widget)}
                      </div>
                      <div className="widget-inner">
                        <TextField
                          value={widget.widget_url}
                          name="test"
                          type="Text"
                          onChange={(e) => {}}
                        />
                        <Button
                          onClick={(e) => {
                            this.copyText(widget.widget_url);
                          }}
                          text="Copy"
                        />
                        <i
                          onClick={(e) => {
                            this.toggleVisibility(index);
                          }}
                          className={
                            this.state.showWidgets[index] ? 'fas fa-eye-slash' : 'fas fa-eye'
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderWidgetVariants(widget: IEventWidget) {
    const widgetThemes = Object.values(Constants.event_widget_theme);
    const widgetStyles =
      widget.widget_type === Constants.event_widget_type.progress_bar
        ? Object.values(Constants.event_widget_style.progress_bar)
        : Object.values(Constants.event_widget_style.alert_box);

    return widgetStyles.map((widgetStyle, index) => {
      return (
        <div
          className="widget-variant-row"
          key={index}
        >
          {widgetThemes.map((widgetTheme, index) =>
            this.renderWidgetVariant(
              widget,
              { widget_style: widgetStyle, widget_theme: widgetTheme },
              index,
            ),
          )}
        </div>
      );
    });
  }

  renderWidgetVariant(widget: IEventWidget, widgetVariant: IWidgetVariant, index: number) {
    let widgetVariantNode: ReactNode;

    const currency = this.props.eventState.event.group?.account?.currency ?? defaultCurrency;
    const selectedWidgetTheme = widget.widget_theme ?? Constants.default_widget_theme;
    const selectedWidgetStyle =
      widget.widget_style ??
      Constants.default_widget_style[
        widget.widget_type as keyof typeof Constants.event_widget_type
      ];
    const isSelected =
      selectedWidgetTheme === widgetVariant.widget_theme &&
      selectedWidgetStyle === widgetVariant.widget_style;

    switch (widget.widget_type) {
      case Constants.event_widget_type.progress_bar:
        widgetVariantNode = (
          <ProgressBarWidget
            key={index}
            isPreview={true}
            widget={{
              currency: currency,
              event_name: this.props.eventState.event.title,
              event_profile_image_url: this.props.eventState.event.profile_image_url,
              individual_goal: 1000,
              individual_raised: 500,
              user_handle: this.props.userState.user.handle,
              widget_style: widgetVariant.widget_style,
              widget_theme: widgetVariant.widget_theme,
            }}
          />
        );
        break;
      case Constants.event_widget_type.alert_box:
        widgetVariantNode = (
          <AlertBoxWidget
            key={index}
            displayState={AlertBoxDisplayState.DISPLAY}
            isPreview={true}
            donation={{ amount: 100, display_name: this.props.userState.user.display_name ?? '' }}
            widget={{
              currency: currency,
              widget_style: widgetVariant.widget_style,
              widget_theme: widgetVariant.widget_theme,
            }}
          />
        );
        break;
    }

    return (
      <div
        key={index}
        className={combineClassNames('widget-variant', isSelected ? 'selected' : '')}
        onClick={() =>
          this.updateWidget(widget, {
            widget_theme: widgetVariant.widget_theme,
            widget_style: widgetVariant.widget_style,
          })
        }
      >
        {widgetVariantNode}
      </div>
    );
  }

  updateWidget(widget: IEventWidget, update: Partial<IEventWidget>) {
    Axios.put<IEventWidget>(
      swapRouteParams(routes.UPDATE_WIDGET, {
        eventId: widget.event_id,
        userId: widget.user_id,
        widgetType: widget.widget_type,
      }),
      update,
    )
      .then((response) => {
        const widgets = [...this.state.widgets];
        const widgetIndex = widgets.findIndex((w) => w.id === widget.id);
        if (widgetIndex !== -1) {
          widgets[widgetIndex] = response.data;
          this.setState({
            widgets: widgets,
          });
        }

        const toast = toastSuccess(
          localizeHelpers.translate('Selected Widget variant updated'),
          'Update Widget',
        );
        this.props.createToast(toast);
      })
      .catch((err) => {
        const errorObj = errorHelpers.getErrorObject(err);
        const toast = toastError(errorObj.translatedMessage, 'Update Widget');
        this.props.createToast(toast);
      });
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    eventState: store.eventState,
    userState: store.userState,
  };
};

const mapDispatchToProps = {
  createToast,
  getEvent,
  updateUserCoverImage,
  updateUserProfileImage,
  getUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualManage);
