import React from 'react';
import moment, { Moment } from 'moment';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { Config } from '@gigit/config';
import pwrdByGoogleImg from '../../../../assets/powered_by_google_on_white_hdpi.png';
import DateTimePicker from '../../../shared/DateTimePicker/DateTimePicker';

interface IProps {
  flow: string;
  type: 'group' | 'event' | 'gig';
  entity: any;
  address: string;
  locale: string;

  updateEntity(key: string, val: any): void;
  setLocation(location: any): void;
}

interface IState {
  startDate: Moment;
  endDate: Moment;
  locationType: string;
  query: any;
}

class DateAndLocation extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      startDate: moment(),
      endDate: moment(),
      locationType: '',
      query: { label: '', value: '' },
    };

    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
  }

  componentDidMount() {
    this.initializeData();
  }

  initializeData() {
    if (this.props.entity) {
      this.setState(
        {
          startDate: this.props.entity.startDate,
          endDate: this.props.entity.endDate,
          locationType: this.props.entity.isVirtual
            ? 'virtual'
            : this.props.entity.isLocation
              ? 'location'
              : '',
        },
        () => {
          if (this.props.address) {
            this.setState({
              query: { label: this.props.address, value: this.props.address },
            });
          }
        },
      );
    }
  }

  handleStartDate(value: any) {
    this.setState(
      {
        startDate: value,
      },
      () => {
        this.props.updateEntity('startDate', moment(value));
        if (this.state.startDate.isAfter(this.state.endDate)) {
          this.setState(
            {
              endDate: moment(value),
            },
            () => {
              this.props.updateEntity('endDate', moment(value));
            },
          );
        }
      },
    );
  }

  handleEndDate(value: any) {
    this.setState(
      {
        endDate: value,
      },
      () => {
        this.props.updateEntity('endDate', moment(value));
        if (this.state.endDate.isBefore(this.state.startDate)) {
          this.setState(
            {
              endDate: moment(value),
            },
            () => {
              this.props.updateEntity('startDate', moment(value));
            },
          );
        }
      },
    );
  }

  handleAddressChange(value: any) {
    this.setState({
      query: value,
    });
    geocodeByAddress(value.value.description)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        geocodeByPlaceId(value.value.place_id).then((results) => {
          let addressObject = { result: results[0], coordinates: [lng, lat] };
          if (this.props.setLocation) {
            this.props.setLocation(addressObject);
          }
        });
      });
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} ${this.props.type}-info`}>
        <h2>{`When is the ${this.props.type}?`}</h2>
        <div className="event-date-container">
          <div className={'mr-6'}>
            <label>Start Date &amp; Time</label>
            <div notranslate="yes">
              <DateTimePicker
                inputProps={{ placeholder: 'Start Date', readOnly: true }}
                className="custom-dtp"
                value={this.state.startDate}
                onChange={this.handleStartDate}
                locale={this.props.locale}
              />
            </div>
          </div>
          <div>
            <label>End Date &amp; Time</label>
            <div notranslate="yes">
              <DateTimePicker
                inputProps={{ placeholder: 'End Date', readOnly: true }}
                className="custom-dtp"
                value={this.state.endDate}
                onChange={this.handleEndDate}
                locale={this.props.locale}
              />
            </div>
          </div>
        </div>
        <h2>{`Where is the ${this.props.type} being held?`}</h2>
        <ul>
          <li
            onClick={() => {
              this.setState({ locationType: 'location' }, () =>
                this.props.updateEntity('isLocation', true),
              );
            }}
          >
            <span
              className={
                this.state.locationType === 'location' ? 'list-circle active' : 'list-circle'
              }
            >
              <i className="fal fa-map-marked-alt"></i>
            </span>
            Location
          </li>
          <li
            onClick={() => {
              this.setState({ locationType: 'virtual' }, () =>
                this.props.updateEntity('isVirtual', true),
              );
            }}
          >
            <span
              className={
                this.state.locationType === 'virtual' ? 'list-circle active' : 'list-circle'
              }
            >
              <i className="far fa-desktop"></i>
            </span>
            Virtual
          </li>
        </ul>
        {this.state.locationType === 'location' && (
          <div className="location-wrap">
            <label>Address</label>
            <GooglePlacesAutocomplete
              apiKey={Config.web.REACT_APP_GOOGLE_API_KEY}
              selectProps={{ onChange: this.handleAddressChange, value: this.state.query }}
            />
            <img
              className="google-attribution"
              src={pwrdByGoogleImg}
              alt="Powered By Google"
            />
          </div>
        )}
      </div>
    );
  }
}

export default DateAndLocation;
