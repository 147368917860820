import React, { useState, useEffect } from 'react';
import Modal from '../../../Modal/Modal';
import './HubModalDonate.scss';
import { IHub, IGroup } from '@gigit/interfaces';
import Loader from '../../../Loader/Loader';
import { hubRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import { formatQuery } from '../../../../helpers';
import { uiConstants } from '../../../../constants';
import TextField from '../../../TextField/TextField';
import Button from '../../../Button/Button';
import { Link } from 'react-router-dom';
import PageComponentPaginator from '../../../PageComponent/shared/PageComponentPaginator/PageComponentPaginator';
import Portrait from '../../../Portrait/Portrait';

interface IProps {
  showDonateModal: boolean;
  hub: IHub | null;
  onClose(): void;
  /** Calls this actions instead of linking to donate page */
  onSelectCause?: (selectedCause: IGroup) => void;
}

const HubModalDonate: React.FC<IProps> = (props) => {
  const { dispatchToastError } = useToastDispatcher();

  const [partners, setPartners] = useState<IGroup[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const shouldRenderSelectableCauses = props.onSelectCause != null;

  function getHubPartners() {
    if (props.hub?.id) {
      setShowLoading(true);
      hubRequestActions
        .getHubPartners(
          props.hub.id,
          formatQuery({
            filters:
              searchValue !== ''
                ? [
                    {
                      id: 'title',
                      label: 'title',
                      type: 'text',
                      value: searchValue,
                    },
                  ]
                : undefined,
          }),
        )
        .then((res) => setPartners(res))
        .catch((error) => dispatchToastError(error, 'Get Hub Partners'))
        .finally(() => {
          setShowLoading(false);
        });
    }
  }

  function renderDonationLinks(causes: IGroup[]) {
    return causes.map((item, index) => (
      <Link
        to={`/group/${item.handle}/donate`}
        key={index}
      >
        <div className="item">
          <Portrait
            size={40}
            currentImage={item.profile_image_url}
          />
          <div className="info">
            <span
              className="title"
              notranslate="yes"
            >
              {item.title}
            </span>
            <span className="charity-id">
              {`Charity ID : `}
              <var data-var="charity_id">{item.charity_id}</var>
            </span>
          </div>
        </div>
      </Link>
    ));
  }

  function renderSelectableCauseCards(causes: IGroup[]) {
    function handleSelectCard(selectedCause: IGroup) {
      props.onSelectCause?.(selectedCause);
      props.onClose();
    }

    return causes.map((cause, index) => (
      <div
        className="item"
        onClick={() => handleSelectCard(cause)}
        key={index}
      >
        <Portrait
          size={40}
          currentImage={cause.profile_image_url}
        />
        <div className="info">
          <span
            className="title"
            notranslate="yes"
          >
            {cause.title}
          </span>
          <span className="charity-id">
            {`Charity ID : `}
            <var data-var="charity_id">{cause.charity_id}</var>
          </span>
        </div>
      </div>
    ));
  }

  useEffect(() => {
    getHubPartners();
  }, [props.hub]);

  return (
    <Modal
      class="HubModalDonate"
      show={props.showDonateModal}
      onClose={props.onClose}
      closeIcon="fas fa-times"
      title="Select a Cause to Make a Donation Towards"
    >
      <div className="search-container">
        <TextField
          autoComplete="off"
          placeholder="Search for a Cause or Organization"
          icon="fal fa-search"
          value={searchValue}
          name="search"
          type="text"
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />

        <Button
          text="Search"
          type="button"
          onClick={() => {
            if (searchValue !== '') {
              getHubPartners();
            }
          }}
        />
      </div>

      <PageComponentPaginator
        itemsPerPage={+uiConstants.showGroupsInList}
        items={partners}
      >
        {(pagedItems: IGroup[], pageStartIndex) => (
          <>
            <div className="count">
              {`Showing `}
              <var data-var="length">
                {partners.length > +uiConstants.showGroupsInList
                  ? +uiConstants.showGroupsInList
                  : partners.length}
              </var>
              {` of `}
              <var data-var="max_length">{partners.length}</var>
              {` Causes`}
            </div>

            <div className="list">
              {!shouldRenderSelectableCauses && renderDonationLinks(pagedItems)}
              {shouldRenderSelectableCauses && renderSelectableCauseCards(pagedItems)}
              {showLoading && <Loader loading={showLoading} />}

              {!showLoading && partners.length < 1 && (
                <div className="empty">
                  <p>There are no results for Cause or Organization</p>
                </div>
              )}
            </div>
          </>
        )}
      </PageComponentPaginator>
    </Modal>
  );
};

export default HubModalDonate;
