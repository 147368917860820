import React from 'react';
import { uiConstants } from '../../../constants';
import useMediaQuery from '../../../hooks/useMediaQuery';

interface ISearchControlActions {
  isMapView: boolean;
  filterCount: number;
  onToggleFilterPanel(): void;
  onToggleMap(): void;
}
export default function SearchControlActions(props: ISearchControlActions) {
  const { isMapView, onToggleFilterPanel, onToggleMap } = props;
  const isMobileDimensions = useMediaQuery(`(max-width: ${uiConstants.search.mobileBreakPoint}px)`);
  let searchActions = [
    {
      className: 'icon-container item filters',
      onClick: () => onToggleFilterPanel(),
      iconClass: 'fas fa-filter',
      id: 'filters',
    },
    {
      className: 'icon-container item toggle-map',
      onClick: () => onToggleMap(),
      iconClass: isMapView ? 'fas fa-th-large' : 'fas fa-map',
      id: 'map',
    },
  ];

  if (isMobileDimensions) {
    searchActions.pop();
  }

  return (
    <div className="SearchControlActions">
      <div className="icons-container">
        {searchActions.map((searchAction) => {
          return (
            <div
              key={searchAction.id}
              className={searchAction.className}
              onClick={searchAction.onClick}
            >
              <i className={searchAction.iconClass} />
            </div>
          );
        })}
        {props.filterCount > 0 && <span className="filter-count"> {props.filterCount} </span>}
      </div>
    </div>
  );
}
