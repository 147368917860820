import React, { useState } from 'react';
import Checkbox from '../../Checkbox/Checkbox';
import Dropdown from '../../Dropdown/Dropdown';
import TextField from '../../TextField/TextField';

import './FormPreview.scss';
import TextArea from '../../TextArea/TextArea';

export const FormPreview: React.FC = () => {
  return (
    <div className="FormPreview">
      <div className="row">
        <div className="col">
          <TextField
            label="First Name"
            placeholder="e.g. Jane"
            value={''}
            name="fullName"
            type="text"
            onChange={() => {}}
            disabled={true}
          />
        </div>
        <div className="col">
          <TextField
            label="Last Name"
            placeholder="e.g. Smith"
            value={''}
            name="fullName"
            type="text"
            onChange={() => {}}
            disabled={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TextField
            label="Email"
            placeholder="e.g. name@email.com"
            value={''}
            disabled={true}
            name="email"
            type="text"
            onChange={() => {}}
          />
        </div>
        <div className="col">
          <TextField
            label="Phone"
            placeholder="e.g. +1-000-000-0000"
            value={''}
            name="phone"
            type="tel"
            onChange={() => {}}
            disabled={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TextField
            label="Street Address"
            placeholder="e.g. 123 Main St"
            value={''}
            name="streetAddress"
            type="text"
            onChange={() => {}}
            disabled={true}
          />
        </div>
        <div className="col">
          <TextField
            label="Apartment / Suite #"
            placeholder="e.g. Suite 302"
            required={false}
            value={''}
            name="apt"
            type="text"
            onChange={() => {}}
            disabled={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TextField
            label="City"
            placeholder="e.g. Hamilton"
            value={''}
            name="city"
            type="text"
            onChange={() => {}}
            disabled={true}
          />
        </div>
        <div className="col">
          <TextField
            label="Province / State"
            placeholder="e.g. Ontario"
            value={''}
            name="province"
            type="text"
            onChange={() => {}}
            disabled={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Dropdown
            disabled={true}
            name="country"
            label="Country"
            value={''}
            options={[
              { label: 'e.g. Canada', value: 'Canada' },
              { label: 'United States of America', value: 'USA' },
            ]}
            onChange={() => {}}
          />
        </div>
        <div className="col">
          <TextField
            label="Postal"
            placeholder="e.g. 000 000"
            value={''}
            name="postal"
            type="text"
            onChange={() => {}}
            disabled={true}
          />
        </div>
      </div>
      <div className="row">
        <TextArea
          label="Comment"
          placeholder="e.g. Some comment"
          value=""
          name="comment"
          type="text"
          disabled={true}
          onChange={() => {}}
        />
      </div>
      <div className="row">
        <Checkbox
          name="ageCheck"
          onChange={() => {}}
          checked={false}
          value={'ageCheck'}
          color="work"
          label="Are you over the age of 18?"
          disabled={true}
        />
      </div>
      <h4>Emergency Contact</h4>
      <div className="row">
        <div className="col">
          <TextField
            label="Full Name"
            placeholder="e.g. Jane Smith"
            value={''}
            name="ECFullName"
            type="text"
            onChange={() => {}}
            disabled={true}
          />
        </div>
        <div className="col">
          <TextField
            label="Phone"
            placeholder="e.g. +1-000-000-0000"
            value={''}
            name="ECPhone"
            type="tel"
            onChange={() => {}}
            disabled={true}
          />
        </div>
      </div>
      <h4>Availability</h4>
    </div>
  );
};
