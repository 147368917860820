import React from 'react';
import { useSelector, useStore } from 'react-redux';

import { IShift, IShiftUser, IUser } from '@gigit/interfaces';

import Modal from '../../Modal/Modal';

import ContextMenu from '../../ContextMenu/ContextMenu';
import TableContextMenu, {
  IContextMenuItem,
} from '../../SortableTable/TableContextMenu/TableContextMenu';
import Portrait from '../../Portrait/Portrait';
import { IAppState } from '../../../store';
import { localizeHelpers } from '../../../localizeHelpers';

import './AssignedUsersModal.scss';
import { userSelectors } from '../../../selectors/user';

interface IProps {
  show: boolean;
  onClose: () => void;

  shift: IShift | null;

  /** The shift users. Should just be removed since we have `shift` */
  users: (IShiftUser & IUser)[];

  /** Returns the context menu for a user. TODO: Do this in a better way. */
  getContextMenu: (user: IShiftUser & IUser) => IContextMenuItem[];
}

/** Modal for listing users assigned to a shift. */
export function AssignedUsersModal(props: IProps) {
  const store = useStore<IAppState>();
  const locale = userSelectors.getCurrentLocale(store.getState());

  return (
    <Modal
      class="AssignedUsersModal"
      show={props.show}
      onClose={props.onClose}
      title="Assigned Users"
      description={localizeHelpers.translate('{{capacity}} Capacity', {
        capacity: `${localizeHelpers.formatNumber((props.shift?.assigned_users || []).length || 0, locale)}/${localizeHelpers.formatNumber(props.shift?.num_people || 0, locale)}`,
      })}
      notranslateDecription="yes"
    >
      <div className="assigned-user-list">
        {props.users
          .sort((left, right) => (left.display_name ?? '').localeCompare(right.display_name ?? ''))
          .map((user) => (
            <div
              key={user.id}
              className="assigned-user"
            >
              <Portrait
                className="portrait"
                size={40}
                currentImage={user.profile_image_url}
              />
              <div
                className="name"
                notranslate="yes"
              >
                {user.display_name}
              </div>
              <TableContextMenu
                className="context-menu"
                menuItems={props.getContextMenu(user)}
              />
            </div>
          ))}
      </div>
    </Modal>
  );
}
