import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IEventState } from '../../reducers/event';
import { IUserState } from '../../reducers/user';
import { IAppState } from '../../store';
import './StravaSection.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import StravaIcon from '../../assets/strava-icon.png';
import StravaWordIcon from '../../assets/strava-word-icon.svg';
import { Constants } from '@gigit/constants';
import moment from 'moment';
import { capitalizeString, errorHelpers, toastError } from '../../helpers';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import Button from '../Button/Button';
import { IEventIndividual, IEventTeam } from '@gigit/interfaces';
import { individualRequestActions } from '../../requestActions/individual';
import { createToast } from '../../actions/toaster';
import { teamRequestActions } from '../../requestActions/team';

interface IPassedProps extends WithTranslation, RouteComponentProps<any, any, any> {
  type: 'team' | 'event' | 'individual';
}

interface IPropsFromState {
  eventState: IEventState;
  userState: IUserState;
  locale: string;
}

interface IState {
  team: IEventTeam | null;
  individual: IEventIndividual | null;
}

type IProps = IPassedProps & IPropsFromState;

const activeStatuses = [
  Constants.event_status.published,
  Constants.event_status.active,
  Constants.event_status.completed,
];

class StravaSection extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      team: null,
      individual: null,
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      this.props.eventState.event !== prevProps.eventState.event &&
      this.props.eventState.event.id !== ''
    ) {
      this.getIndividual(this.props.eventState.event.id, this.props.match.params.individualHandle);
    }

    if (
      this.props.eventState.teams[0] !== prevProps.eventState.teams[0] &&
      typeof this.props.eventState.teams[0] !== 'undefined'
    ) {
      this.getTeam(this.props.eventState.event.id, this.props.eventState.teams[0].handle);
    }
  }

  async getTeam(eventId: string, teamId: string) {
    try {
      const team = await teamRequestActions.getTeam(eventId, teamId);
      this.setState({ team });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Team');
      createToast(toast);
    }
  }

  async getIndividual(eventId: string, userId: string) {
    try {
      const individual = await individualRequestActions.getIndividual(eventId, userId);
      this.setState({ individual });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Individual');
      createToast(toast);
    }
  }

  getStravaState = () => {
    const { type, eventState } = this.props;

    switch (type) {
      case 'event':
        return eventState.event.strava;
      case 'team':
        return this.state.team?.strava;
      case 'individual':
        return this.state.individual?.strava;
    }
  };

  canRenderStravaSection = () => {
    const { type, eventState } = this.props;

    if (!eventState.event.fitness_tracking_enabled) {
      return false;
    } else if (type === 'team' && !this.state.team?.fitness_tracking_enabled) {
      return false;
    } else if (type === 'individual' && !this.state.individual?.fitness_tracking_enabled) {
      return false;
    }

    return true;
  };

  isEventDatesValid() {
    const event = this.props.eventState.event;

    const eventStartDate = moment(event.start_date);
    const eventEndDate = moment(event.end_date);
    const now = moment();

    return now.isSameOrAfter(eventStartDate) && now.isSameOrBefore(eventEndDate);
  }

  isEventStatusValid() {
    const { type } = this.props;
    const event = this.props.eventState.event;
    const activeStatuses = [
      Constants.event_status.published,
      Constants.event_status.active,
      Constants.event_status.completed,
    ];

    return activeStatuses.find((status) => status === event.status?.code);
  }

  isOwner() {
    switch (this.props.type) {
      case Constants.pages_type.team:
        return this.state.team?.owner_id === this.props.userState.user.id;
      case Constants.pages_type.individual:
        return this.state.individual?.user_id === this.props.userState.user.id;
      default:
        return false;
    }
  }

  render() {
    const { t, type } = this.props;
    const stravaState = this.getStravaState();

    return (
      this.canRenderStravaSection() && (
        <div className="StravaSection">
          <div className="title-container">
            <span className="title">
              {type === 'individual' ? t('My Activity') : t('Team Activity')}
            </span>
            <img
              src={StravaIcon}
              className="strava-icon"
              alt="strava-icon"
            />
            <img
              src={StravaWordIcon}
              className="strava-word-icon"
              alt="strava-word-icon"
            />
          </div>
          <div className="section-inner">
            {/* {type === "team" && ( TODO: Implement active members
                            <div className="activity-card">
                                <div className="activity-header">

                                </div>
                            </div>
                        )} */}
            <div className="activity-card">
              <div className="activity-header">
                {/* TODO: Implement query to select specific dates of activities */}
                <span className="title">{t('Total Activity')}</span>
                <div className="toggle-container">
                  <div className="toggle">
                    <i className="fa fa-walking" />
                    <span className="label">{t('Walking')}</span>
                  </div>
                  <div className="toggle">
                    <i className="fa fa-running" />
                    <span className="label">{t('Running')}</span>
                  </div>
                  <div className="toggle">
                    <i className="fa fa-biking" />
                    <span className="label">{t('Cycling')}</span>
                  </div>
                </div>
              </div>
              <div className="card-inner">
                <div className="column">
                  <div className="stat-container large">
                    <i className="fal fa-clock" />
                    <span className="label">
                      {stravaState?.moving_time ? Math.round(stravaState?.moving_time / 60) : '0'}
                    </span>
                    <span className="unit">Minutes Moving</span>
                  </div>
                </div>
                <div className="column">
                  <div className="stat-container small">
                    <i className="fal fa-route" />
                    <span className="label">{(this.getStravaState()?.distance || 0) / 1000}</span>
                    <span className="unit">Kilometers</span>
                  </div>
                  <div className="stat-container small">
                    <i className="fal fa-calendar" />
                    <span className="label">
                      {stravaState?.elapsed_time ? Math.round(stravaState?.elapsed_time / 60) : '0'}
                    </span>
                    <span className="unit">Elapsed Time</span>
                  </div>
                  <div className="stat-container small">
                    <i className="fal fa-flag-alt" />
                    <span className="label">{stravaState?.recorded_activities}</span>
                    <span className="unit">Activities</span>
                  </div>
                </div>
              </div>
              {!this.isEventStatusValid() && (
                <div className="warning-message">
                  <i className="fal fa-exclamation-triangle" />
                  <span notranslate="yes">
                    {localizeHelpers.translate(
                      'Strava tracking not active. event status is currently {{current_status}}. Status must be one of ({{active_statuses}}).',
                      {
                        current_status: capitalizeString(this.props.eventState.event.status?.code),
                        active_statuses: activeStatuses
                          .map((status) => capitalizeString(status))
                          .join(', '),
                      },
                    )}
                  </span>
                </div>
              )}

              {!this.isEventDatesValid() && (
                <div className="warning-message">
                  <i className="fal fa-exclamation-triangle" />
                  <span notranslate="yes">
                    {localizeHelpers.translate(
                      'Strava tracking will only be applied between event start of {{start_date}} and end of {{end_date}}.',
                      {
                        start_date: localizeHelpers.formatDate(
                          this.props.eventState.event.start_date,
                          LocaleDateFormats.LLL,
                          this.props.locale,
                        ),
                        end_date: localizeHelpers.formatDate(
                          this.props.eventState.event.end_date,
                          LocaleDateFormats.LLL,
                          this.props.locale,
                        ),
                      },
                    )}
                  </span>
                </div>
              )}
            </div>

            {this.isEventStatusValid() && this.isEventDatesValid() && this.isOwner() && (
              <div className="add-activity">
                <Button
                  buttonClass="add-activity-button"
                  text="+ Add Activity"
                  onClick={(e: any) => window.open('https://www.strava.com/upload/manual')}
                ></Button>
              </div>
            )}
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    eventState: store.eventState,
    userState: store.userState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

export default withRouter(withTranslation('translations')(connect(mapStateToProps)(StravaSection)));
