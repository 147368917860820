import './CardLoaderItem.scss';
import React from 'react';

export enum ECardLoaderStyleType {
  PROFILE_IMG,
  COVER_IMG,
  LONG_TEXT,
  SHORT_TEXT,
  TAGS,
  ONE_BUTTON,
  TWO_BUTTONS,
}

export enum ECardLoaderViewType {
  GRID,
  LIST,
}

interface IProps {
  customClass?: string;
  styleTypes: Array<ECardLoaderStyleType>;
  viewType: ECardLoaderViewType;
}

const CardLoaderItem: React.FC<IProps> = (props) => {
  function doesContainsStyleType(type: ECardLoaderStyleType): boolean {
    return props.styleTypes.includes(type);
  }

  return (
    <div
      className={`CardLoaderItem ${props.customClass || ''} ${props?.viewType ? ECardLoaderViewType[props.viewType] : ECardLoaderViewType[ECardLoaderViewType.GRID]}`}
    >
      {doesContainsStyleType(ECardLoaderStyleType.PROFILE_IMG) && <div className="profile-img" />}

      <div
        className={`container loader ${doesContainsStyleType(ECardLoaderStyleType.COVER_IMG) ? 'abs' : ''}`}
      >
        <span />
      </div>

      {doesContainsStyleType(ECardLoaderStyleType.COVER_IMG) && (
        <div className="cover-img container" />
      )}

      {doesContainsStyleType(ECardLoaderStyleType.LONG_TEXT) && (
        <div className="text container">
          <div className="group">
            <span className="dark" />
            <span className="short dark" />
          </div>
          <div className="group">
            <span className="light" />
            <span className="light" />
          </div>
          <div className="group">
            <span className="light" />
            <span className="light" />
            <span className="light" />
          </div>
        </div>
      )}

      {doesContainsStyleType(ECardLoaderStyleType.SHORT_TEXT) && (
        <div className="text container">
          <div className="group">
            <span className="dark" />
            <span className="dark" />
          </div>
          <div className="group">
            <span className="light" />
            <span className="light" />
          </div>
        </div>
      )}

      {doesContainsStyleType(ECardLoaderStyleType.TAGS) && (
        <div className="tags container">
          <div className="tag" />
        </div>
      )}

      {doesContainsStyleType(ECardLoaderStyleType.ONE_BUTTON) && (
        <div className="one-button container">
          <span className="btn" />
        </div>
      )}

      {doesContainsStyleType(ECardLoaderStyleType.TWO_BUTTONS) && (
        <div className="two-buttons container">
          <span className="btn" />
          <span className="btn" />
        </div>
      )}
    </div>
  );
};

export default CardLoaderItem;
