import React, { ChangeEvent, useEffect, useState } from 'react';
import queryString from 'query-string';
import './CreateAHub.scss';
import { ICreateHubFE, IHub, ILocalization } from '@gigit/interfaces';
import { RouteComponentProps } from 'react-router-dom';
import { FormBottomBar } from '../../components/shared/Onboarding/FormBottomBar/FormBottomBar';
import { FormSection } from '../../components/shared/Onboarding/FormSection/FormSection';
import { FormTopBar } from '../../components/shared/Onboarding/FormTopBar/FormTopBar';
import { hasAllRequiredFields, onBack } from '../../components/shared/Onboarding/helpers';
import { useHubInfo } from '../../components/shared/Onboarding/hooks/useHubBaseInfo';
import { InfoSection } from '../../components/shared/Onboarding/InfoSection/InfoSection';
import { IHubBaseInfo, IOnboardStep } from '../../components/shared/Onboarding/interfaces';
import OnboardingWrapper from '../../components/shared/Onboarding/OnboardingWrapper';
import { hubStepsMaster } from './Config';
import TextField from '../../components/TextField/TextField';
import TextArea from '../../components/TextArea/TextArea';
import { Constants } from '@gigit/constants';
import { lorumIpsumSamples, setSEOMetatags, toastSuccess } from '../../helpers';
import useToastDispatcher from '../../hooks/useToaster';
import { SummaryPage } from '../../components/shared/Onboarding/SummaryPage/SummaryPage';
import { IAppState } from '../../store';
import { connect, useSelector } from 'react-redux';
import { userSelectors } from '../../selectors/user';
import { localizeHelpers } from '../../localizeHelpers';
import { hubRequestActions } from '../../requestActions';
import { FlowConfigComponents, FlowConfigPages } from '../Flows/FlowConfigs';
import { uiConstants } from '../../constants';
import { LocaleSelect } from '../../components/shared/Onboarding/LocaleSelect/LocaleSelect';
import { Config } from '@gigit/config';
import hubDefaultBannerImage from '../../assets/hub-default-banner.png';
import hubDefaultProfileImage from '../../assets/hub-default-profile.png';
import Dropdown from '../../components/Dropdown/Dropdown';
import { resendEmailVerification } from '../../actions/user';

const createAHubLoginRedirect = `/login?redirect=/onboarding/company`;

interface IProps extends RouteComponentProps<{ step: string }> {
  resendEmailVerification(): void;
}
export interface ICreateHubFormData {
  companyInfo: IHubBaseInfo;
  redirect: string;
  steps: Array<IOnboardStep>;
  localization: ILocalization;
  loading: boolean;
}

const CreateAHub = (props: IProps) => {
  const navigateHome = () => props.history.push('/');

  const nxtBtnText = getCurrentStep() === 'summary' ? 'Create Company' : 'Next';
  const params = queryString.parse(window.location.search);
  const baseRedirectParam = decodeURIComponent(params?.redirect?.toString() || '');
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const { companyInfo, handleCompanyInfo } = useHubInfo();
  const [steps, setSteps] = useState<IOnboardStep[]>(hubStepsMaster);
  const [localization, setLocalization] = useState<ILocalization>({
    country: '',
    defaultTimezone: '',
    state: '',
  });
  const [redirect] = useState<string>(baseRedirectParam);
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatchToastError } = useToastDispatcher();

  const showBack = steps.findIndex((step) => step.id === getCurrentStep());

  useEffect(() => {
    setSEOMetatags({
      title: localizeHelpers.translate('Create A Company | Kambeo'),
      urlPath: '/onboarding/company',
    });
    // If we re-mount, move back to first step.
    // This ensure the flow never breaks if navigating to a specific step.
    const firstStepId = hubStepsMaster[0]?.id;
    if (getCurrentStep() !== firstStepId) {
      setCurrentStep(firstStepId);
    }
  }, []);

  function getCurrentStep() {
    return props.match.params.step;
  }

  function renderStep() {
    switch (getCurrentStep()) {
      case 'companyInfo':
        return renderHubInfo();
      case 'summary':
        return renderSumary();
    }
  }

  function updateHubInfo(update: Partial<IHubBaseInfo>) {
    const newData = { ...companyInfo, ...update };
    newData.url = `${Config.web.REACT_APP_BASE_URL}/company/${genHandle(newData.name)}`;
    handleCompanyInfo(newData);
  }

  //TODO: Make this a global utility method
  function genHandle(title: string) {
    let handle = title;
    handle = handle.replace(/ /g, '.'); //replace all spaces with "."
    handle = removeSpecChars(handle.toLowerCase(), ['.']);
    handle = handle.replace(/\.+/g, '.'); //replace many dots with one
    handle = handle.replace(/^\.|\.$/g, ''); //trim dots at the start and end
    handle = handle.substring(0, 27);
    handle = handle.replace(/^\.|\.$/g, ''); //trim dots if exist after substring

    return handle;
  }

  function removeSpecChars(str: string, except: string[] = []): string {
    const lower = str.toLowerCase();
    const upper = str.toUpperCase();

    let res = '';
    for (let i = 0; i < lower.length; i++) {
      if (lower[i] != upper[i] || except.includes(str[i])) {
        res += str[i];
      }
    }
    return res;
  }

  function renderHubInfo() {
    return (
      <div className="form-page column">
        <div className="row">
          <TextField
            placeholder="Enter a name for your company"
            value={companyInfo.name}
            name="name"
            label="Company Name"
            type="text"
            required={true}
            onChange={(e) => {
              updateHubInfo({ name: e.target.value });
            }}
          />
        </div>
        <div className="row">
          <TextField
            placeholder="Enter a website for your company"
            value={companyInfo.url}
            name="url"
            label="Company URL"
            type="text"
            required={false}
            disabled={true}
            onChange={(e) => {
              updateHubInfo({ url: e.target.value });
            }}
          />
        </div>
        <div className="row">
          <TextArea
            className="description-text-field"
            label="Company Description"
            type="text"
            value={companyInfo.description}
            name="description"
            charLength={600}
            required={true}
            spellCheck={false}
            placeholder="Enter Description"
            onChange={(e) => {
              updateHubInfo({ description: e.target.value.replace(/<\/?[^>]+(>|$)/g, '') });
            }}
            maxLength={600}
          />
        </div>
        <div className="row">
          <LocaleSelect
            localization={localization}
            updateLocalization={setLocalization}
          />
        </div>
        <div className="row">
          <TextField
            placeholder="Enter a website for your company"
            value={companyInfo.websiteURL ?? ''}
            name="websiteUrl"
            label="Company Website"
            type="text"
            required={false}
            onChange={(e) => {
              updateHubInfo({ websiteURL: e.target.value });
            }}
          />
        </div>
        <div className="row">
          <Dropdown
            label="Enter the number of employees"
            value={companyInfo?.numberOfEmployees?.type ?? ''}
            name="numberOfEmployees"
            required={false}
            options={uiConstants.hubsEmployeeRange}
            onChange={(e) => {
              updateHubInfo({
                numberOfEmployees: { ...companyInfo?.numberOfEmployees, type: e.target.value },
              });
            }}
          />
        </div>
        <div className="row">
          <Dropdown
            label="How did you hear about us?"
            onChange={(e) => updateHubInfo({ referralSource: e.target.value })}
            value={companyInfo.referralSource ?? ''}
            name="referralSource"
            options={[
              {
                label: 'Google',
                value: 'google',
              },
              {
                label: 'LinkedIn',
                value: 'linkedIn',
              },
              {
                label: 'Facebook',
                value: 'facebook',
              },
              {
                label: 'Other',
                value: 'other',
              },
            ]}
            notranslate="yes"
            placeholder="Select One"
          />
        </div>
        {companyInfo?.referralSource === 'other' && (
          <div className="row">
            <TextArea
              className="description-text-field"
              label="Referral Source"
              type="text"
              value={companyInfo.otherText ?? ''}
              name="description"
              charLength={50}
              required={companyInfo.referralSource === 'other'}
              spellCheck={false}
              placeholder="Provide some info on where you found us"
              onChange={(e) => {
                updateHubInfo({ otherText: e.target.value.replace(/<\/?[^>]+(>|$)/g, '') });
              }}
            />
          </div>
        )}
      </div>
    );
  }

  function renderSumary() {
    return (
      <div className="form-page column">
        <SummaryPage
          locale={locale}
          formData={getFormData()}
          type="hub"
          goToStep={(stepId: string) => {
            setCurrentStep(stepId);
          }}
        />
      </div>
    );
  }

  function getFormData(): ICreateHubFormData {
    return {
      companyInfo,
      redirect,
      steps,
      localization,
      loading,
    };
  }

  const handleError = (error: unknown, errorTitle: string) => {
    dispatchToastError(error, errorTitle, [
      { text: 'Verify your email', onClick: () => props.resendEmailVerification() },
    ]);
    setLoading(false);
  };

  function setCurrentStep(stepId: string) {
    let isExistingStep = steps.filter((step) => step.id === stepId);

    if (isExistingStep.length > 0) {
      props.history.replace({
        pathname: `/onboarding/company/${stepId}`,
        search: props.location.search,
      });
    }
  }

  async function onNext() {
    const index = steps.findIndex((step) => step.id === getCurrentStep());
    const nextStep = steps[index + 1]?.id;
    const currentStep = getCurrentStep();
    const formData = getFormData();
    if (hasAllRequiredFields(steps, currentStep, formData)) {
      if (index + 1 > steps.length) {
        return;
      } else if (index + 1 === steps.length) {
        await createHub();
      } else {
        setCurrentStep(nextStep);
      }
    } else {
      return;
    }
  }

  async function createHub() {
    setLoading(true);

    const onSuccess = (hub: IHub) => {
      let toastMessage = localizeHelpers.translate('Successfully created a Hub');
      toastSuccess(toastMessage, 'Create a Hub');
      let successLink = `/company/${hub.handle}?help=1`;

      setLoading(false);
      props.history.push(successLink);
    };

    const splitOnHyphen = (text: string): string[] => {
      const rangeValues = companyInfo.numberOfEmployees?.type.split('-');
      return rangeValues as string[];
    };

    try {
      let newHub: IHub;
      const components = FlowConfigComponents([], uiConstants.ownerType.hub);
      const pages = FlowConfigPages(components);
      const referral_source =
        companyInfo?.referralSource === 'other'
          ? companyInfo.otherText
          : companyInfo.referralSource;
      const rangeValues = splitOnHyphen(companyInfo.numberOfEmployees?.type || '');
      const numberOfEmployees = {
        type: companyInfo.numberOfEmployees!.type,
        min_value: parseInt(rangeValues[0]),
        max_value: rangeValues.length > 0 ? parseInt(rangeValues[1]) : parseInt(rangeValues[0]),
      };
      let hubPayload: ICreateHubFE = {
        hub: {
          call_to_action_text: {
            donate: 'Donate',
            volunteer: 'Volunteer',
            fundraise: 'Fundraise',
          },
          title: companyInfo.name,
          description: companyInfo.description,
          group_ids_involved: [],
          localization: localization,
          /* Currently only one hub type is available and is required by route */
          hub_type: Constants.hub_types.business,
          banner: {
            image: hubDefaultBannerImage,
          },
          profile_image_url: hubDefaultProfileImage,
          number_of_employees: numberOfEmployees,
          website_url: companyInfo?.websiteURL,
          referral_source: referral_source,
        },
        //TODO: Default components for home, activity, causes, events, volunteering, store, auction
        pages: [
          {
            label: 'Home',
            visibility: Constants.page_component_visibility.public,
            components: [
              {
                title: 'Metrics',
                sequence: 1,
                component_type: Constants.hub_page_component_types.metrics,
                visibility: Constants.page_component_visibility.public,
                meta_data: {
                  render_detailed_version: false,
                },
              },
              {
                title: 'Content Block',
                sequence: 2,
                component_type: Constants.hub_page_component_types.content_block,
                visibility: Constants.page_component_visibility.public,
                content: [
                  {
                    type: 'text',
                    title: 'About Us',
                    text: companyInfo.description,
                  },
                  {
                    type: 'image',
                    media_url: '',
                  },
                ],
              },
              {
                title: 'Social Impact Benefits',
                sequence: 3,
                component_type: 'social_impact_component',
                visibility: Constants.page_component_visibility.public,
              },
              {
                title: 'Focus Areas',
                sequence: 4,
                component_type: Constants.hub_page_component_types.focus_areas,
                visibility: Constants.page_component_visibility.public,
                content_references: {
                  object_type: Constants.object_type.cause,
                  object_ids: [],
                },
              },
              {
                title: 'Causes',
                sequence: 5,
                component_type: Constants.hub_page_component_types.causes,
                visibility: Constants.page_component_visibility.public,
                content_references: {
                  object_type: Constants.object_type.group,
                  object_ids: [],
                },
              },
              {
                title: 'Content Block',
                sequence: 6,
                component_type: Constants.hub_page_component_types.content_block,
                visibility: Constants.page_component_visibility.private,
                content: [
                  {
                    type: 'image',
                    media_url: '',
                  },
                  {
                    type: 'text',
                    title: 'Our Mission',
                    text: lorumIpsumSamples.standard,
                  },
                ],
              },
              {
                title: 'Content Block',
                sequence: 7,
                component_type: Constants.hub_page_component_types.content_block,
                visibility: Constants.page_component_visibility.private,
                content: [
                  {
                    type: 'text',
                    title: 'Our Mission',
                    text: lorumIpsumSamples.standard,
                  },
                  {
                    type: 'image',
                    media_url: '',
                  },
                ],
              },
              // {
              //     title: "Our Title",
              //     sequence: 4,
              //     component_type: Constants.hub_page_component_types.content_block,
              //     visibility: Constants.page_component_visibility.public,
              //     content: [
              //         {
              //             type: "metrics",
              //             media_url: "https://static.wikia.nocookie.net/spongebob/images/d/d7/SpongeBob_stock_art.png/revision/latest?cb=20190921125147"
              //         },
              //         {
              //             type: "text",
              //             title: "Our Mission",
              //             text: `Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,
              //             Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,
              //             Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet, `,
              //         },
              //     ]
              // }
            ],
            sequence: 1,
          },
          {
            label: 'Activity',
            components: [
              {
                title: 'Activity',
                sequence: 1,
                component_type: Constants.hub_page_component_types.activity,
                visibility: Constants.page_component_visibility.public,
                meta_data: {
                  render_detailed_version: true,
                },
              },
            ],
            sequence: 2,
          },
          {
            label: 'Causes',
            components: [
              {
                title: 'Causes',
                sequence: 1,
                component_type: Constants.hub_page_component_types.causes,
                visibility: Constants.page_component_visibility.public,
                meta_data: {
                  render_detailed_version: true,
                },
              },
            ],
            sequence: 3,
          },
          {
            label: 'Events',
            components: [
              {
                title: 'Events',
                sequence: 1,
                component_type: Constants.hub_page_component_types.events,
                visibility: Constants.page_component_visibility.public,
                meta_data: {
                  render_detailed_version: true,
                },
              },
            ],
            sequence: 4,
          },
          {
            label: 'Volunteering',
            components: [
              {
                title: 'Volunteering',
                sequence: 1,
                component_type: Constants.hub_page_component_types.volunteering,
                visibility: Constants.page_component_visibility.public,
                meta_data: {
                  render_detailed_version: true,
                },
              },
            ],
            sequence: 5,
          },
          {
            label: 'Store',
            components: [
              {
                title: 'Store',
                sequence: 1,
                component_type: Constants.hub_page_component_types.store,
                visibility: Constants.page_component_visibility.public,
                meta_data: {
                  render_detailed_version: true,
                },
              },
            ],
            sequence: 6,
          },
          {
            label: 'Auction',
            components: [
              {
                title: 'Auction',
                sequence: 1,
                component_type: Constants.hub_page_component_types.auction,
                visibility: Constants.page_component_visibility.public,
                meta_data: {
                  render_detailed_version: true,
                },
              },
            ],
            sequence: 7,
          },
        ],
      };
      //Commented out because it was throwing an error on hub creation
      // let hubPages: Array<IPage> = makePageSequence(pages);

      newHub = await hubRequestActions.createHub(hubPayload);
      onSuccess(newHub);
      // for (let pageIndex in hubPages) {
      //     try {
      //         await hubRequestActions.createHubPage(newHub?.id as string, hubPages[pageIndex]);
      //         if (parseInt(pageIndex) === hubPages.length - 1) {
      //             onSuccess(newHub);
      //         }
      //     } catch (error) {
      //         handleError(error, "Create a Hub");
      //     }
      // }
    } catch (error) {
      handleError(error, 'Create a Company');
    }
  }

  return (
    <OnboardingWrapper
      requiresAuth={true}
      redirect={createAHubLoginRedirect}
    >
      <form
        className="CreateAGroup CreateAHub"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormTopBar
          onLogoClick={() => navigateHome()}
          onClose={() => props.history.goBack()}
        />
        <InfoSection
          type="hub"
          title="Create a Company Page"
          steps={steps}
          currentStep={getCurrentStep()}
        />
        <FormSection>{renderStep()}</FormSection>
        <FormBottomBar
          isDisabled={!hasAllRequiredFields(steps, getCurrentStep(), getFormData())}
          showBack={showBack !== 0}
          nextBtnTxt={nxtBtnText}
          onBack={() => onBack(steps, getCurrentStep(), setCurrentStep)}
          onNext={async () => await onNext()}
          loading={loading}
        />
      </form>
    </OnboardingWrapper>
  );
};

const mapDispatchToProps = {
  resendEmailVerification,
};

export default connect(null, mapDispatchToProps)(CreateAHub);
