import axios from 'axios';
import { routes, swapRouteParams } from '../helpers';
import {
  IActivityFeedCommentCreateParamsFE,
  IActivityFeedCommentFE,
  IActivityFeedCommentSummaryFE,
  IActivityFeedPost,
  IActivityFeedSharePostContentFE,
  IActivityPostFE,
} from '@gigit/interfaces';
import { IActivityPostCreateParamsFE } from '@gigit/interfaces';

export namespace communityFeedRequestActions {
  export async function getPostComments(
    postId: string,
    query?: URLSearchParams,
  ): Promise<IActivityFeedCommentSummaryFE[]> {
    const response = await axios.get<IActivityFeedCommentSummaryFE[]>(
      swapRouteParams(routes.GET_POST_COMMENTS, { postId }),
      { params: query },
    );
    return response.data;
  }

  export async function sharePost(postId: string): Promise<Omit<IActivityPostFE, 'feed_events'>> {
    const response = await axios.post<Omit<IActivityPostFE, 'feed_events'>>(
      swapRouteParams(routes.SHARE_POST, { postId }),
    );
    return response.data;
  }

  export async function likePost(postId: string): Promise<Omit<IActivityPostFE, 'feed_events'>> {
    const response = await axios.post<Omit<IActivityPostFE, 'feed_events'>>(
      swapRouteParams(routes.LIKE_POST, { postId }),
    );
    return response.data;
  }

  export async function unlikePost(postId: string): Promise<Omit<IActivityPostFE, 'feed_events'>> {
    const response = await axios.post<Omit<IActivityPostFE, 'feed_events'>>(
      swapRouteParams(routes.UNLIKE_POST, { postId }),
    );
    return response.data;
  }

  export async function hidePost(postId: string): Promise<void> {
    await axios.post<void>(swapRouteParams(routes.HIDE_POST, { postId }));
  }

  export async function commentOnPostParentComment(
    postId: string,
    commentId: string,
    params: IActivityFeedCommentCreateParamsFE,
  ): Promise<Omit<IActivityPostFE, 'feed_events'>> {
    const response = await axios.post<Omit<IActivityPostFE, 'feed_events'>>(
      swapRouteParams(routes.COMMENT_ON_POST_PARENT_COMMENT, { postId, commentId }),
      { ...params },
    );
    return response.data;
  }

  export async function commentOnPost(
    postId: string,
    params: IActivityFeedCommentCreateParamsFE,
  ): Promise<Omit<IActivityPostFE, 'feed_events'>> {
    const response = await axios.post<Omit<IActivityPostFE, 'feed_events'>>(
      swapRouteParams(routes.COMMENT_ON_POST, { postId }),
      { ...params },
    );
    return response.data;
  }

  export async function createActivityFeedPost(
    params: IActivityPostCreateParamsFE,
  ): Promise<IActivityFeedCommentFE[]> {
    const response = await axios.post<IActivityFeedCommentFE[]>(routes.CREATE_ACTIVITY_FEED_POST, {
      ...params,
    });
    return response.data;
  }

  export async function createActivitySharePost(
    object_id: string | undefined,
    object_type: string | undefined,
    params: IActivityFeedSharePostContentFE,
  ): Promise<IActivityFeedPost[]> {
    const response = await axios.post<IActivityFeedPost[]>(
      swapRouteParams(routes.CREATE_SHARE_FEED_POST, { object_id, object_type }),
      { ...params },
    );
    return response.data;
  }

  export async function deleteComment(
    postId: string,
    commentId: string,
  ): Promise<Omit<IActivityPostFE, 'feed_events'>> {
    const response = await axios.delete<Omit<IActivityPostFE, 'feed_events'>>(
      swapRouteParams(routes.DELETE_POST_COMMENT, { postId, commentId }),
    );
    return response.data;
  }

  export async function deleteChildComment(
    postId: string,
    commentId: string,
    childCommentId: string,
  ): Promise<Omit<IActivityPostFE, 'feed_events'>> {
    const response = await axios.delete<Omit<IActivityPostFE, 'feed_events'>>(
      swapRouteParams(routes.DELETE_POST_COMMENT_CHILD_COMMENT, {
        postId,
        commentId,
        childCommentId,
      }),
    );
    return response.data;
  }
}
