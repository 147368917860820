import { Dispatch } from 'redux';
import axios from 'axios';
import { routes, swapRouteParams, toastError, uploadMedias } from '../helpers';
import { createToast } from './toaster';
import {
  IDiscountCode,
  IDonationPart,
  IStoreCategory,
  IStoreItem,
  IStoreItemSummary,
} from '@gigit/interfaces';
import { getEvent, updateEventPageComponent } from './event';
import { updateGroupPageComponent } from './group';
import errorHelpers from '../helpers/errorHelpers';
import { uiConstants } from '../constants';

export enum StoreActionTypes {
  UPDATE_STORE = 'UPDATE_STORE',
}

export interface IUpdateStoreActions {
  type: StoreActionTypes.UPDATE_STORE;
}

export type StoreActions = IUpdateStoreActions;
export interface IUpdateComponentParams {
  component_id: string;
  page_id: string;
  object_ids: string[];
}
/** Fields for updating an Store Item.
 * We use Omit<> to change the type of some fields.
 */
export interface IUpdateAuctionItemParams
  extends Partial<Omit<IStoreItemSummary, 'donation_part'>> {
  donation_part?: IDonationPart | null;
}

/** Valid owner object types that can own Store items. */
export type StoreItemOwnerType = 'group' | 'event' | 'hub';

export const getStoreItems = (params: {
  ownerType: StoreItemOwnerType;
  ownerId: string;
  query?: URLSearchParams;
  options?: { callback?: () => void };
}) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      params.ownerType === 'group' ? routes.GET_GROUP_STORE_ITEMS : routes.GET_EVENT_STORE_ITEMS;
    const idField = params.ownerType === 'group' ? 'groupId' : 'eventId';

    let _route = swapRouteParams(route, { [idField]: params.ownerId });

    if (params?.query) {
      _route += `?${params.query}`;
    }

    axios.get(_route).then((response) => {
      const storeItems = response.data;

      dispatch({
        storeItems: storeItems,
        type: StoreActionTypes.UPDATE_STORE,
      });
      params.options?.callback?.();
    });
  };
};

export const createStoreItem = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  _payload: IUpdateAuctionItemParams,
  medias?: string[],
  _updateComponent?: IUpdateComponentParams,
  onComponentUpdate?: () => void,
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      isCreatingStoreItem: true,
      type: StoreActionTypes.UPDATE_STORE,
    });

    const route =
      ownerType === 'group' ? routes.CREATE_GROUP_STORE_ITEM : routes.CREATE_EVENT_STORE_ITEM;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    if (medias && medias.length) {
      _payload.media = await uploadMedias(medias);
    }

    axios
      .post(swapRouteParams(route, { [idField]: ownerId }), _payload)
      .then((response) => {
        getStoreItems({
          ownerType,
          ownerId,
          options: { callback: options?.callback },
        })(dispatch, getState);

        if (_updateComponent) {
          if (ownerType === 'group') {
            updateGroupPageComponent(
              ownerId,
              _updateComponent.page_id,
              _updateComponent.component_id,
              {
                content_references: {
                  object_type: 'store_item',
                  object_ids: [..._updateComponent.object_ids, response.data.id],
                },
              },
            )(dispatch, getState);
          }
          if (ownerType === 'event') {
            updateEventPageComponent(
              ownerId,
              _updateComponent.page_id,
              _updateComponent.component_id,
              {
                content_references: {
                  object_type: 'store_item',
                  object_ids: [..._updateComponent.object_ids, response.data.id],
                },
              },
              onComponentUpdate ? onComponentUpdate : undefined,
            )(dispatch, getState);
          }
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Store Item');
        createToast(toast)(dispatch, getState);
      })
      .finally(() => {
        dispatch({
          isCreatingStoreItem: false,
          type: StoreActionTypes.UPDATE_STORE,
        });
      });
  };
};

export const updateStoreItem = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  _storeItemId: string,
  _payload: IUpdateAuctionItemParams,
  medias?: string[],
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group' ? routes.UPDATE_GROUP_STORE_ITEM : routes.UPDATE_EVENT_STORE_ITEM;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    if (medias) {
      if (medias.length > 0) {
        _payload.media = await uploadMedias(medias);
      } else {
        _payload.media = [];
      }
    }

    axios
      .put(swapRouteParams(route, { [idField]: ownerId, storeItemId: _storeItemId }), _payload)
      .then((response) => {
        getStoreItems({
          ownerType,
          ownerId,
          options: { callback: options?.callback },
        })(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Store Item');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const deleteStoreItem = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  _storeItemId: string,
  options?: { callback?: () => void },
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group' ? routes.DELETE_GROUP_STORE_ITEM : routes.DELETE_EVENT_STORE_ITEM;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .delete(swapRouteParams(route, { [idField]: ownerId, storeItemId: _storeItemId }))
      .then((response) => {
        getStoreItems({
          ownerType,
          ownerId,
          options: { callback: options?.callback },
        })(dispatch, getState);

        getEvent(getState().eventState.event.id)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Store Item');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const getDiscountCodes = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  _search?: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      discountCodes: [],
      type: StoreActionTypes.UPDATE_STORE,
    });

    const route =
      ownerType === uiConstants.ownerType.group
        ? routes.GET_GROUP_DISCOUNTS
        : ownerType === uiConstants.ownerType.hub
          ? routes.GET_HUB_DISCOUNTS
          : routes.GET_EVENT_DISCOUNTS;
    const idField =
      ownerType === uiConstants.ownerType.group
        ? 'groupId'
        : ownerType === uiConstants.ownerType.hub
          ? 'hubId'
          : 'eventId';

    let _route = swapRouteParams(route, { [idField]: ownerId });

    if (_search) {
      _route = _route + '?search=' + _search;
    }

    axios.get(_route).then((response) => {
      const discountCodes = response.data;
      dispatch({
        discountCodes: discountCodes,
        type: StoreActionTypes.UPDATE_STORE,
      });
    });
  };
};

export const createDiscountCode = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  _payload: Partial<IDiscountCode>,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group' ? routes.CREATE_GROUP_DISCOUNT : routes.CREATE_EVENT_DISCOUNT;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .post(swapRouteParams(route, { [idField]: ownerId }), _payload)
      .then((response) => {
        getDiscountCodes(ownerType, ownerId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Discount Code');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateDiscountCode = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  _discountId: string,
  _payload: Partial<IDiscountCode>,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group' ? routes.UPDATE_GROUP_DISCOUNT : routes.UPDATE_EVENT_DISCOUNT;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .put(swapRouteParams(route, { [idField]: ownerId, discountId: _discountId }), _payload)
      .then((response) => {
        getDiscountCodes(ownerType, ownerId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Discount Code');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const deleteDiscountCode = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  _discountId: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group' ? routes.DELETE_GROUP_DISCOUNT : routes.DELETE_EVENT_DISCOUNT;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .post(swapRouteParams(route, { [idField]: ownerId, discountId: _discountId }))
      .then((response) => {
        getStoreItems({ ownerType, ownerId })(dispatch, getState);
        getDiscountCodes(ownerType, ownerId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Cause Discount');
        createToast(toast)(dispatch, getState);
      });
  };
};
export const activateDiscountCode = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  _discountId: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group' ? routes.ACTIVATE_GROUP_DISCOUNT : routes.ACTIVATE_EVENT_DISCOUNT;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .post(swapRouteParams(route, { [idField]: ownerId, discountId: _discountId }))
      .then((response) => {
        getStoreItems({ ownerType, ownerId })(dispatch, getState);
        getDiscountCodes(ownerType, ownerId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Activate Event Discount');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const findStoreCategories = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  _search?: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      storeCategories: [],
      type: StoreActionTypes.UPDATE_STORE,
    });
    let route = '';
    let idField = '';

    // Added a switch for the owbner type, was only covering the group and event types.
    switch (ownerType) {
      case uiConstants.ownerType.group:
        route = routes.FIND_GROUP_STORE_CATEGORIES;
        idField = 'groupId';
        break;
      case uiConstants.ownerType.event:
        route = routes.FIND_EVENT_STORE_CATEGORIES;
        idField = 'eventId';
        break;
      case uiConstants.ownerType.hub:
        route = routes.FIND_HUB_STORE_CATEGORIES;
        idField = 'hubId';
        break;
      default:
        return;
    }

    let _route = swapRouteParams(route, { [idField]: ownerId });

    if (_search) {
      _route = _route + '?search=' + _search;
    }

    axios
      .get(_route)
      .then((response) => {
        const storeCategories = response.data;
        dispatch({
          storeCategories: storeCategories,
          type: StoreActionTypes.UPDATE_STORE,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Find Store Categories');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const createStoreCategory = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  _payload: Partial<IStoreCategory>,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group'
        ? routes.CREATE_GROUP_STORE_CATEGORY
        : routes.CREATE_EVENT_STORE_CATEGORY;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .post(swapRouteParams(route, { [idField]: ownerId }), _payload)
      .then((response) => {
        findStoreCategories(ownerType, ownerId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Create Store Category');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const updateStoreCategory = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  storeCategoryId: string,
  _payload: Partial<IStoreCategory>,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group'
        ? routes.UPDATE_GROUP_STORE_CATEGORY
        : routes.UPDATE_EVENT_STORE_CATEGORY;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .put(
        swapRouteParams(route, { [idField]: ownerId, storeCategoryId: storeCategoryId }),
        _payload,
      )
      .then((response) => {
        findStoreCategories(ownerType, ownerId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Update Store Category');
        createToast(toast)(dispatch, getState);
      });
  };
};

export const deleteStoreCategory = (
  ownerType: StoreItemOwnerType,
  ownerId: string,
  storeCategoryId: string,
) => {
  return async (dispatch: Dispatch, getState: any) => {
    const route =
      ownerType === 'group'
        ? routes.DELETE_GROUP_STORE_CATEGORY
        : routes.DELETE_EVENT_STORE_CATEGORY;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .delete(swapRouteParams(route, { [idField]: ownerId, storeCategoryId: storeCategoryId }))
      .then((response) => {
        findStoreCategories(ownerType, ownerId)(dispatch, getState);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Delete Store Category');
        createToast(toast)(dispatch, getState);
      });
  };
};
