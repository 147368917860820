import { formatCurrency } from '../../../helpers';
import { Constants } from '@gigit/constants';
import React, { Component } from 'react';
import { defaultCurrency } from '../../../helpers';

import './FundraiserProgressBar.scss';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { userSelectors } from '../../../selectors/user';

interface IProps {
  raised?: number | null;
  goal?: number | null;
  currency?: string;
  locale: string;
}

/** Progress bar that can be used on a Profile page to show the progress of a fundraising campaign. */
export class FundraiserProgressBar extends Component<IProps> {
  render() {
    const goal = this.props.goal || this.props.raised || 0;
    const raised = this.props.raised || 0;
    const raisedFormatted = formatCurrency(
      raised,
      this.props.currency ?? defaultCurrency,
      this.props.locale,
    );
    const goalFormatted = formatCurrency(
      goal,
      this.props.currency ?? defaultCurrency,
      this.props.locale,
    );

    return (
      <div className="FundraiserProgressBar">
        <div className="amt">
          <span>
            <var data-var="raised">{raisedFormatted}</var>
          </span>
          <span>
            raised of <var data-var="goal">{goalFormatted}</var>
          </span>
        </div>
        <div className="bar-wrap">
          <div className="bar">
            <div
              style={{ width: Math.min(99, Math.max(3, Math.round((raised / goal) * 100))) + '%' }}
              className="bar-inner"
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

export default connect(mapStateToProps)(FundraiserProgressBar);
