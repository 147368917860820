import axios from 'axios';
import { IEventSummaryFE, IPage, IPageComponent, ICampaign } from '@gigit/interfaces';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { handleInputChange, toastError, swapRouteParams, routes } from '../../helpers';
import { IAppState } from '../../store';
import Button from '../Button/Button';
import DatePicker from '../DatePicker/DatePicker';
import Dropdown, { IOptions } from '../Dropdown/Dropdown';
import Modal from '../Modal/Modal';
import ModalScrollContainer from '../Modal/ModalScrollContainer/ModalScrollContainer';
import TextField from '../TextField/TextField';
import { createToast } from '../../actions/toaster';
import { History } from 'history';

import { createEvent } from '../../actions/event';
import moment, { Moment } from 'moment';

import './CreateEventModal.scss';
import { IOwnerObject, IToast } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import { errorHelpers } from '../../helpers/errorHelpers';
import { uiConstants } from '../../constants/uiConstants';

export interface IProps extends WithTranslation {
  show: boolean;
  owner: IOwnerObject;
  history: History;

  /** When true, redirects to the newly created event, otherwise closes the modal. */
  redirectToEventAfterCreate?: boolean;

  /** When provided, the new event will be attached to the provided page and component. */
  pageComponentToAddEvent?: { page: IPage; component: IPageComponent };

  onClose(): void;
  createToast(toast: IToast): void;
  createEvent(
    groupId: string,
    payload: any,
    options?: {
      _pages?: any;
      _updateComponent?: any;
      isFlow?: boolean;
      callback?: (event: IEventSummaryFE) => void;
    },
  ): void;
}

export interface IState {
  eventName: string;
  campaign_type: string;

  campaigns: Array<IOptions>;
  selectedCampaignId: string | null;
  fundraising_goal: number;

  startDate: Moment;
  endDate: Moment;

  start_time_hour: string;
  start_time_minutes: string;
  end_time_hour: string;
  end_time_minutes: string;
  time_options: IOptions[];
  start_time_am_pm: string;
  end_time_am_pm: string;
  isCreatingEvent: boolean;
}

/** Modal for creating new events. */
class CreateEventModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      eventName: '',
      campaign_type: 'campaign',
      campaigns: [],
      fundraising_goal: 0,
      selectedCampaignId: null,

      startDate: moment(),
      endDate: moment(),

      start_time_hour: '12',
      start_time_minutes: '00',
      end_time_hour: '12',
      end_time_minutes: '00',
      time_options: [
        { value: 'AM', label: 'AM' },
        { value: 'PM', label: 'PM' },
      ],
      start_time_am_pm: 'PM',
      end_time_am_pm: 'PM',
      isCreatingEvent: false,
    };

    this.getGroupCampaigns = this.getGroupCampaigns.bind(this);
  }

  componentDidMount() {
    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      this.getGroupCampaigns();
    }
  }

  getGroupCampaigns() {
    axios
      .get<ICampaign>(
        swapRouteParams(routes.GET_GROUP_CAMPAIGNS, { groupId: this.props.owner.ownerId }),
      )
      .then((response) => {
        let filterCampaigns =
          (response.data &&
            Array.isArray(response.data) &&
            response.data.filter((e) => e.status.code === 'active')) ||
          [];

        const camps =
          filterCampaigns.map((campaign) => {
            return { value: campaign.id!, label: campaign.title };
          }) || [];

        camps.unshift({ value: '', label: 'None' });

        this.setState(
          {
            campaigns: camps,
          },
          () => {
            if (camps[0] && camps[0].value) {
              this.setState({
                selectedCampaignId: camps[0].value,
              });
            }
          },
        );
      })
      .catch((error: any) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Get Cause Campaigns');
        this.props.createToast(toast);
      });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.show !== this.props.show && this.props.show) {
      this.setState({
        eventName: '',
        fundraising_goal: 0,
        selectedCampaignId: null,

        startDate: moment(),
        endDate: moment(),

        start_time_hour: '12',
        start_time_minutes: '00',
        end_time_hour: '12',
        end_time_minutes: '00',
        start_time_am_pm: 'PM',
        end_time_am_pm: 'PM',
      });
    }
  }

  convertTimeFrom12To24(timeStr: string) {
    let colon = timeStr.indexOf(':');
    let hours = timeStr.substr(0, colon),
      minutes = timeStr.substr(colon + 1, 2),
      meridian = timeStr.substr(colon + 4, 2).toUpperCase();

    let hoursInt = parseInt(hours, 10),
      offset = meridian === 'PM' ? 12 : 0;

    if (hoursInt === 12) {
      hoursInt = offset;
    } else {
      hoursInt += offset;
    }
    return hoursInt + ':' + minutes;
  }

  createEvent() {
    this.setState(
      {
        isCreatingEvent: true,
      },
      () => {
        if (this.isStartDateAfterEndDate()) {
          const toast = toastError(
            localizeHelpers.translate('Start date cannot be greater than end date'),
            'Create Event',
          );

          this.props.createToast(toast);
          return;
        }

        let startDateTime = this.convertTimeFrom12To24(
          `${this.state.start_time_hour}:${this.state.start_time_minutes} ${this.state.start_time_am_pm}`,
        );
        let endDateTime = this.convertTimeFrom12To24(
          `${this.state.end_time_hour}:${this.state.end_time_minutes} ${this.state.end_time_am_pm}`,
        );

        let startDate = moment(this.state.startDate).set({
          hour: parseInt(startDateTime.split(':')[0]),
          minute: parseInt(startDateTime.split(':')[1]),
          second: 0,
          millisecond: 0,
        });

        let endDate = moment(this.state.endDate).set({
          hour: parseInt(endDateTime.split(':')[0]),
          minute: parseInt(endDateTime.split(':')[1]),
          second: 0,
          millisecond: 0,
        });

        let _payload = {
          title: this.state.eventName,
          campaign_id:
            this.state.selectedCampaignId !== '' ? this.state.selectedCampaignId : undefined,
          goal: this.state.fundraising_goal,
          start_date: moment(startDate),
          end_date: moment(endDate),
        };

        this.props.createEvent(this.props.owner.ownerId, _payload, {
          _pages: [
            {
              label: 'My First Page',
              icon: 'fas fa-info-circle',
            },
          ],
          _updateComponent: this.props.pageComponentToAddEvent
            ? {
                component_id: this.props.pageComponentToAddEvent.component.id,
                page_id: this.props.pageComponentToAddEvent.page.id,
                object_ids:
                  this.props.pageComponentToAddEvent.component.content_references?.object_ids,
              }
            : undefined,
          callback: (event) => {
            if (this.props.redirectToEventAfterCreate) {
              this.props.history.push(`/event/${event.handle}?help=1`);
            } else {
              this.props.onClose();
            }
          },
        });
      },
    );
  }

  isStartDateAfterEndDate() {
    let startDateTime = moment(
      `${this.state.start_time_hour}:${this.state.start_time_minutes} ${this.state.start_time_am_pm}`,
      ['h:mm A'],
    ).format('HH:mm');
    let endDateTime = moment(
      `${this.state.end_time_hour}:${this.state.end_time_minutes} ${this.state.end_time_am_pm}`,
      ['h:mm A'],
    ).format('HH:mm');

    let startDate = moment(this.state.startDate).set({
      hour: parseInt(startDateTime.split(':')[0]),
      minute: parseInt(startDateTime.split(':')[1]),
      second: 0,
      millisecond: 0,
    });

    let endDate = moment(this.state.endDate).set({
      hour: parseInt(endDateTime.split(':')[0]),
      minute: parseInt(endDateTime.split(':')[1]),
      second: 0,
      millisecond: 0,
    });

    return startDate.isAfter(endDate);
  }

  render() {
    const hoursTemplate = /^(1[0-2]|[1-9]|$)$/; //1..12 | ""
    const minutesTemplate = /^([0-9]|([0-5][0-9])|$)$/; //0..59 | ""

    return (
      <Modal
        show={this.props.show}
        onClose={() => {
          this.props.onClose();
        }}
        title="Create an Event"
      >
        <ModalScrollContainer>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.createEvent();
            }}
            className="CreateEventModal"
          >
            <div className="row">
              <TextField
                required={true}
                label="Name"
                value={this.state.eventName}
                name="eventName"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="row">
              <Dropdown
                value={this.state.selectedCampaignId}
                label="Campaign"
                shouldSort={true}
                name="selectedCampaignId"
                options={this.state.campaigns}
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
                notranslate="yes"
              />
            </div>
            <div className="row">
              <TextField
                required={false}
                label="Fundraising Goal"
                value={this.state.fundraising_goal}
                name="fundraising_goal"
                type="number"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="row">
              <DatePicker
                error={this.isStartDateAfterEndDate()}
                name="startDate"
                label="Start Date"
                onChange={(date: any) => {
                  this.setState({ startDate: date });
                }}
                date={this.state.startDate}
              />
            </div>
            <div className="row">
              <div className="time-fields">
                <TextField
                  className="hour"
                  type="text"
                  name="start_time_hour"
                  required={true}
                  label="Hour"
                  value={this.state.start_time_hour}
                  onChange={(e) => {
                    if (hoursTemplate.test(e.target.value)) {
                      handleInputChange(e, this);
                    }
                  }}
                />
                <TextField
                  className="minute"
                  type="text"
                  name="start_time_minutes"
                  required={true}
                  label="Minute"
                  value={this.state.start_time_minutes}
                  onChange={(e) => {
                    if (minutesTemplate.test(e.target.value)) {
                      handleInputChange(e, this);
                    }
                  }}
                />
                <Dropdown
                  value={this.state.start_time_am_pm}
                  label=" "
                  name="start_time_am_pm"
                  options={this.state.time_options}
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <DatePicker
                error={this.isStartDateAfterEndDate()}
                dayClass="err"
                name="endDate"
                label="End Date"
                onChange={(date: any) => {
                  this.setState({ endDate: date });
                }}
                date={this.state.endDate}
              />
            </div>
            <div className="row">
              <div className="time-fields">
                <TextField
                  className="hour"
                  type="text"
                  name="end_time_hour"
                  required={true}
                  label="Hour"
                  value={this.state.end_time_hour}
                  onChange={(e) => {
                    if (hoursTemplate.test(e.target.value)) {
                      handleInputChange(e, this);
                    }
                  }}
                />
                <TextField
                  className="minute"
                  type="text"
                  name="end_time_minutes"
                  required={true}
                  label="Minute"
                  value={this.state.end_time_minutes}
                  onChange={(e) => {
                    if (minutesTemplate.test(e.target.value)) {
                      handleInputChange(e, this);
                    }
                  }}
                />
                <Dropdown
                  value={this.state.end_time_am_pm}
                  label=" "
                  name="end_time_am_pm"
                  options={this.state.time_options}
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
              </div>
            </div>
            <div className="form-actions">
              <Button
                loading={this.state.isCreatingEvent}
                type="submit"
                text="Create Event"
              />
            </div>
          </form>
        </ModalScrollContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {
  createEvent,
  createToast,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(CreateEventModal),
);
