import './CardLoader.scss';
import React from 'react';
import CardLoaderItem, {
  ECardLoaderStyleType,
  ECardLoaderViewType,
} from './CardLoaderItem/CardLoaderItem';

interface IProps {
  loading: boolean;
  numberOfRows: number;
  numberOfCols: number;
  styleTypes: Array<ECardLoaderStyleType>;
  viewType?: ECardLoaderViewType;
  loaderItemClass?: string;
  loaderWrapperClass?: string;
}

const CardLoader: React.FC<IProps> = (props) => {
  function generateCardLoaderItems(): React.ReactNode[] {
    const componentsArray = [];
    for (let i = 0; i < props.numberOfRows * props.numberOfCols; i++) {
      componentsArray.push(
        <CardLoaderItem
          viewType={props?.viewType || ECardLoaderViewType.GRID}
          key={i}
          customClass={props.loaderItemClass}
          styleTypes={props.styleTypes}
        />,
      );
    }

    return componentsArray;
  }

  return props.loading ? (
    <section
      className={`CardLoader ${props?.viewType ? ECardLoaderViewType[props.viewType] : ECardLoaderViewType[ECardLoaderViewType.GRID]}`}
    >
      <div
        className={`wrapper ${props.loaderWrapperClass || ''}`}
        style={{
          gridTemplate: `repeat(${props.numberOfRows}, auto) / repeat(${props.numberOfCols}, auto)`,
        }}
      >
        {generateCardLoaderItems()}
      </div>
    </section>
  ) : (
    <></>
  );
};

export default CardLoader;
