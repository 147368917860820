import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { IAppState } from '../../store';

import {
  handleInputChange,
  routes,
  swapRouteParams,
  toastError,
  typeHelpers,
  errorHelpers,
} from '../../helpers';

import { ICause } from '@gigit/interfaces';
import { IToast } from '../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { createToast } from '../../actions/toaster';

import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import { Prompt } from '../Prompt/Prompt';
import { userSelectors } from '../../selectors/user';
import SortableTable, { ISortableTableColumn } from '../SortableTable/SortableTable';
import Modal from '../Modal/Modal';
import ModalHeader from '../Modal/ModalHeader/ModalHeader';
import './Causes.scss';

interface IProps {
  createToast(toast: IToast): void;
  locale: string;
}

interface IState {
  causesRecords: Array<ICause>;
  causesColumns: ISortableTableColumn[];
  dataLoading: boolean;
  searchValue: string;
  causeToDelete: ICause | null;
  causeToEdit: ICause | null;
  causeName: string;
  showAddCause: boolean;
  showEditCause: boolean;
}

// TODO: Rename this to have better name. possible suggestion: `CausesAdministration`.
class Causes extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      searchValue: '',
      dataLoading: false,
      causeToDelete: null,
      causeToEdit: null,
      causeName: '',
      showAddCause: false,
      showEditCause: false,
      causesRecords: [],
      causesColumns: [
        { id: 'name', label: 'Focus  Area Name', sortable: false },
        { id: 'date-created', label: 'Date Created', sortable: false },
        { id: 'actions', label: 'Actions', sortable: false },
      ],
    };
  }

  componentDidMount() {
    this.getCauses();
  }

  getCauses() {
    this.setState({ dataLoading: true });
    axios
      .get(routes.GET_CAUSES + '?sort=-created_at')
      .then((response) => {
        this.setState({ causesRecords: response.data });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Get Focus Areas');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  createCause() {
    const payload = {
      cause: this.state.causeName.trim(),
    };

    this.setState({ dataLoading: true });
    axios
      .post<ICause>(routes.CREATE_CAUSE, payload)
      .then((response) => {
        const updatedCausesRecords = [...this.state.causesRecords];
        updatedCausesRecords.unshift(response.data as ICause);
        this.setState({
          causesRecords: updatedCausesRecords,
          showAddCause: false,
        });
        this.resetCauseData();
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Create Focus Area');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  deleteCause(id: string) {
    this.setState({ dataLoading: true });
    axios
      .delete(swapRouteParams(routes.DELETE_CAUSE, { id }))
      .then((response) => {
        const deletedCause = response.data as ICause;
        const updatedCausesList = this.state.causesRecords.filter((c) => c.id !== deletedCause.id);
        this.setState({
          causesRecords: updatedCausesList,
        });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Delete Focus Area');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  editCause() {
    typeHelpers.assertNotNullOrUndefined(this.state.causeToEdit);

    const payload: Partial<ICause> = {
      cause: this.state.causeName.trim(),
    };

    axios
      .put<ICause>(swapRouteParams(routes.UPDATE_CAUSE, { id: this.state.causeToEdit.id }), payload)
      .then((response) => {
        const updatedCause: ICause = response.data;
        const updatedCausesRecords = this.state.causesRecords.map((cause) =>
          cause.id === updatedCause.id ? updatedCause : cause,
        );
        this.setState({
          causesRecords: updatedCausesRecords,
          showEditCause: false,
        });
        this.resetCauseData();
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Edit Focus Area');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  resetCauseData() {
    this.setState({
      causeName: '',
    });
  }

  getCausesTableData() {
    const searchValue = this.state.searchValue.toLowerCase();
    const causesList = searchValue
      ? this.state.causesRecords?.filter((cause) => cause.cause.toLowerCase().includes(searchValue))
      : this.state.causesRecords;

    return causesList.map((cause: ICause) => {
      return {
        row: [
          { content: cause.cause, id: 'name' },
          {
            content: cause.created_at
              ? localizeHelpers.formatDate(
                  cause.created_at,
                  LocaleDateFormats.LL,
                  this.props.locale,
                )
              : '',
            id: 'date-created',
          },
          {
            id: 'actions',
            menu: [
              {
                icon: 'far fa-pencil-alt',
                onClick: () => {
                  this.setState({
                    showEditCause: true,
                    causeToEdit: cause,
                    causeName: cause.cause,
                  });
                },
                label: 'Edit',
              },
              {
                icon: 'far fa-trash-alt',
                onClick: () => {
                  this.setState({ causeToDelete: cause });
                },
                label: 'Delete',
              },
            ],
          },
        ],
      };
    });
  }

  render() {
    return (
      <div className="Causes">
        <div className="section-title">
          <div className="forms-title">Focus Areas</div>
          <div className="forms-controls">
            <Button
              text="+ New Focus Area"
              onClick={() => this.setState({ showAddCause: true })}
            >
              {' '}
            </Button>
          </div>
        </div>

        <div className="section-inner">
          <div className="search">
            <TextField
              icon="fas fa-search"
              placeholder="Search focus areas..."
              value={this.state.searchValue}
              type="text"
              name="searchValue"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e, this);
              }}
            />
            <div className="filters">{/* Filters buttons here if needed */}</div>
          </div>

          <div className="items-list">
            <SortableTable
              {...this.props}
              columns={this.state.causesColumns}
              data={this.getCausesTableData()}
              itemsPerPage={5}
              loading={this.state.dataLoading}
            />
          </div>

          {!this.state.dataLoading && (
            <div className={`no-items-found ${this.getCausesTableData().length ? '' : 'show'}`}>
              No items found
            </div>
          )}
        </div>

        <Prompt
          show={!!this.state.causeToDelete}
          title={'Delete focus area'}
          message={`Are you sure you want to delete focus area ${this.state.causeToDelete?.cause}?`}
          yesMessage="Yes"
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage="Cancel"
          onYes={() => {
            this.deleteCause(this.state.causeToDelete?.id || '');
          }}
          onClose={() => {
            this.setState({ causeToDelete: null });
          }}
        />

        <Modal
          class="add-cause-record"
          show={this.state.showAddCause}
          onClose={(e: any) => {
            this.setState({ showAddCause: false });
            this.resetCauseData();
          }}
        >
          <ModalHeader
            className="header"
            title={'Add Focus Area'}
            onClose={(e: any) => {}}
          />

          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.createCause();
            }}
          >
            <div className="inner">
              <TextField
                label="Focus Area Name"
                required={true}
                value={this.state.causeName}
                name="causeName"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="controls">
              <Button
                buttonClass="cancel-button"
                type="button"
                onClick={(e) => {
                  this.setState({ showAddCause: false });
                  this.resetCauseData();
                }}
                text="Cancel"
              />
              <Button
                buttonClass="add-button"
                type="submit"
                text="Add"
              />
            </div>
          </form>
        </Modal>

        <Modal
          class="edit-cause-record"
          show={this.state.showEditCause}
          onClose={(e: any) => {
            this.setState({ showEditCause: false });
            this.resetCauseData();
          }}
        >
          <ModalHeader
            className="header"
            title={'Edit Focus Area'}
            onClose={(e: any) => {}}
          />

          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.editCause();
            }}
          >
            <div className="inner">
              <TextField
                label="Focus Area Name"
                required={true}
                value={this.state.causeName}
                name="causeName"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="controls">
              <Button
                buttonClass="cancel-button"
                type="button"
                onClick={(e) => {
                  this.setState({ showEditCause: false });
                  this.resetCauseData();
                }}
                text="Cancel"
              />
              <Button
                buttonClass="update-button"
                type="submit"
                text="Update"
              />
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Causes);
