import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { Constants } from '@gigit/constants';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import {
  defaultCurrency,
  handleInputChange,
  formatCurrency,
  formatFilters,
  sortColumn,
  handleDebounce,
  formatUsername,
} from '../../helpers';
import TextField from '../../components/TextField/TextField';
import Portrait from '../../components/Portrait/Portrait';
import CoverImage from '../../components/CoverImage/CoverImage';
import Button from '../../components/Button/Button';
import SortableTable, { ISortableTableActions } from '../SortableTable/SortableTable';
import FilterData from '../FilterData/FilterData';

import { IGroupState } from '../../reducers/group';
import {
  getGroupDonors,
  addDonor,
  createGroupDonationIntent,
  getGroupDonorsExport,
} from '../../actions/group';

import Modal from '../Modal/Modal';
import DatePicker from '../DatePicker/DatePicker';
import Dropdown from '../Dropdown/Dropdown';
import { ICampaignFund, IDonor, IUser } from '@gigit/interfaces';
import './DonorManagement.scss';
import ContactDetails from '../ContactDetails/ContactDetails';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import AddDonation from '../AddDonation/AddDonation';
import { IActiveFilter, IOwnerObject } from '../../interfaces';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  locale: string;
  owner?: IOwnerObject;
  getGroupDonors(groupId: string, query?: string): void;
  addDonor(groupId: string, payload: any): void;
  createGroupDonationIntent(groupId: string, _payload: any, callback?: any): void;
  getGroupDonorsExport(groupId: string, groupHandle: string): void;
}

interface IState {
  searchValue: string;
  selectedDonation: string;
  showAdd: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  genders: Array<any>;
  gender: string;
  address: string;
  apartment: string;
  city: string;
  provinces: Array<any>;
  selectedProvince: string;
  dob: any;
  country: string;
  postal: string;
  showAddDonation: boolean;
  campaignFunds: any[];
  donationAmount: number;
  otherAmount: string;
  comments: string;
  anonymous: boolean;
  dedicate: boolean;
  selectedDedicate: string;
  hFirst: string;
  hLast: string;
  rFirst: string;
  rLast: string;
  rEmail: string;
  rMessage: string;
  dPrefix: string;
  selectedPayment: string;
  formRef: any;
  selectedDonor: IDonor | null;
  sort: string;
  showFilterModal: boolean;
  filters: IActiveFilter[];
  showDetailsModal: boolean;
  selectedUser: IUser | null;
  sendTaxReceipt: boolean;
}

class DonorManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let campFunds = this.props.groupState.currentCampaign
      ? this.getFundOptions(this.props.groupState.currentCampaign.funds)
      : [];

    this.state = {
      searchValue: '',
      selectedDonation: '',
      showAdd: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      genders: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Other', value: 'other' },
      ],
      gender: 'male',
      address: '',
      apartment: '',
      city: '',
      provinces: [],
      selectedProvince: '',
      dob: moment(),
      country: '',
      postal: '',
      showAddDonation: false,
      campaignFunds: campFunds,
      donationAmount: 25,
      comments: '',
      otherAmount: '0',
      anonymous: false,
      dedicate: false,
      selectedDedicate: '',
      hFirst: '',
      hLast: '',
      rFirst: '',
      rLast: '',
      rEmail: '',
      rMessage: '',
      dPrefix: 'memory',
      selectedPayment: 'cash',
      formRef: React.createRef(),
      selectedDonor: null,
      sort: '',
      showFilterModal: false,
      filters: [],
      showDetailsModal: false,
      selectedUser: null,
      sendTaxReceipt: false,
    };

    this.searchDonors = this.searchDonors.bind(this);
    this.updateDOB = this.updateDOB.bind(this);
    this.closeAddDonation = this.closeAddDonation.bind(this);
    this.sortCallback = this.sortCallback.bind(this);
  }

  componentDidMount() {
    this.props.getGroupDonors(this.props.groupState.group.id);
  }

  getFundOptions(funds: any[]) {
    return funds.map((fund: ICampaignFund) => {
      return { label: fund.name, value: fund.name };
    });
  }

  searchDonors() {
    this.props.getGroupDonors(this.props.groupState.group.id, this.state.searchValue);
  }

  toggleRow(id: string) {
    if (this.state.selectedDonation === id) {
      this.setState({
        selectedDonation: '',
      });
    } else {
      this.setState({
        selectedDonation: id,
      });
    }
  }

  updateDOB(date: any) {
    this.setState({
      dob: date,
    });
  }

  addDonor() {
    const payload = {
      donor: {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        gender: this.state.gender,
        dob: this.state.dob,
        phone: this.state.phone,
      },
      address: {
        line1: this.state.address,
        line2: this.state.apartment,
        city: this.state.city,
        state: this.state.selectedProvince,
        country: this.state.country,
        postal_code: this.state.postal,
      },
    };

    this.props.addDonor(this.props.groupState.group.id, payload);
    this.closeAddDonation();
  }

  closeAddDonation() {
    this.setState({
      showAddDonation: false,
      showAdd: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      apartment: '',
      city: '',
      selectedProvince: '',
      country: '',
      postal: '',
      otherAmount: '',
      donationAmount: 25,
      anonymous: false,
      sendTaxReceipt: false,
      selectedDonor: null,
    });
  }

  setDonor(item: IDonor) {
    this.setState({
      selectedDonor: item,
    });
  }

  exportDonors() {
    this.props.getGroupDonorsExport(
      this.props.groupState.group.id,
      this.props.groupState.group.handle,
    );
  }

  sortCallback(_sort: string) {
    this.setState(
      {
        sort: _sort,
      },
      () => {
        this.props.getGroupDonors(
          this.props.groupState.group.id,
          this.state.searchValue +
            '&sort=' +
            this.state.sort +
            '&' +
            formatFilters(this.state.filters),
        );
      },
    );
  }

  render() {
    const currency = this.props.owner?.account?.currency ?? defaultCurrency;

    return (
      <div className="section-wrap">
        <div className="section-title">
          <div className="title-wrap">Donors</div>
          <div className="DonorManagement-title-actions">
            <Button
              className="filter-button"
              icon="fal fa-filter"
              onClick={() => {
                this.setState({ showFilterModal: true });
              }}
              text={`Filter${this.state.filters.length > 0 ? ' (' + this.state.filters.length + ')' : ''}`}
            />
            <Button
              onClick={() => this.setState({ showAdd: true })}
              icon="fal fa-plus"
              text={'Add Donor'}
            />
            <Button
              className="export-button"
              icon="far fa-file-download"
              onClick={() => this.exportDonors()}
              text={'Export'}
            />
          </div>
        </div>
        <div className="section-inner">
          <div className="DonorManagement">
            <div className="DonorManagement-list">
              <div className="search">
                <TextField
                  icon="fas fa-search"
                  placeholder={'Search donors...'}
                  value={this.state.searchValue}
                  type="text"
                  name="searchValue"
                  onChange={(e) => {
                    handleInputChange(e, this, false, () => {
                      handleDebounce(this.state.searchValue).then((res) => {
                        this.searchDonors();
                      });
                    });
                  }}
                />
              </div>
              <div className="list">
                <SortableTable
                  {...this.props}
                  loading={this.props.groupState.donationsLoading}
                  onSort={(sortSettings) => {
                    sortColumn(sortSettings, this.sortCallback);
                  }}
                  columns={[
                    { id: 'portrait', label: 'Portrait' },
                    { id: 'user.last_name', label: 'Donor Name' },
                    { id: 'number_of_donations', label: '# of Donations' },
                    { id: 'total_donated', label: 'Amount Donated' },
                    { id: 'last_donation.created_at', label: 'Last Donation' },
                    { id: 'actions', label: 'Actions' },
                  ]}
                  data={this.props.groupState.groupDonors.map((item: IDonor, index) => {
                    let menuItems: ISortableTableActions[] = [];

                    if (!item.user?.is_dummy_user) {
                      menuItems.push(
                        {
                          icon: 'fas fa-envelope',
                          link: 'mailto:' + item.user?.email,
                          label: 'Email',
                        },
                        {
                          icon: 'fas fa-user',
                          link: '/user/' + item.user?.handle,
                          label: 'View Profile',
                        },
                      );
                    }

                    return {
                      row: [
                        {
                          id: 'portrait',
                          content: (
                            <Portrait
                              currentImage={item.user?.profile_image_url}
                              size={40}
                            />
                          ),
                        },
                        {
                          id: 'user.last_name',
                          content: (
                            <a
                              onClick={(e) => {
                                e.stopPropagation();
                                this.props.history.push(`/user/${item.user?.handle}`);
                              }}
                              notranslate="yes"
                            >
                              {formatUsername(item.user)}
                            </a>
                          ),
                        },
                        {
                          id: 'number_of_donations',
                          content: (
                            <span notranslate="yes">
                              {localizeHelpers.formatNumber(
                                item.number_of_donations || 0,
                                this.props.locale,
                              )}
                            </span>
                          ),
                        },
                        {
                          id: 'total_donated',
                          content: (
                            <span notranslate="yes">
                              {formatCurrency(item.total_donated, currency, this.props.locale)}
                            </span>
                          ),
                        },
                        {
                          id: 'last_donation.created_at',
                          content: (
                            <span notranslate="yes">
                              {item.last_donation !== undefined
                                ? localizeHelpers.formatDate(
                                    item.last_donation?.created_at ?? new Date(),
                                    LocaleDateFormats.LL,
                                    this.props.locale,
                                  )
                                : localizeHelpers.translate('Not Available')}
                            </span>
                          ),
                        },
                        { id: 'actions', menu: menuItems },
                      ],
                      onClick: (e) => {
                        this.toggleRow(item.id!);
                      },
                      details: (
                        <React.Fragment>
                          <div className="third">
                            <CoverImage currentImage={item.user?.cover_image_url} />
                            <div className="inner-user-details">
                              <Portrait
                                currentImage={item.user?.profile_image_url}
                                size={120}
                              />
                              <div
                                className="user-name"
                                notranslate="yes"
                              >
                                {item.user?.display_name}
                              </div>
                              {!item.user?.is_dummy_user && (
                                <Link
                                  className="user-handle"
                                  to={'/user/' + item.user?.handle}
                                  notranslate="yes"
                                >
                                  @{item.user?.handle}
                                </Link>
                              )}

                              <div className="user-actions">
                                {!item.user?.is_dummy_user && (
                                  <div className="sub-actions">
                                    <a
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                      }}
                                      href={'mailto:' + item.user?.email}
                                    >
                                      <i className="far fa-envelope" />
                                      <span>Email</span>
                                    </a>
                                  </div>
                                )}
                                {this.props.owner?.ownerType === Constants.object_type.group && (
                                  <a
                                    className="faded"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        showDetailsModal: true,
                                        selectedUser: item.user || null,
                                      });
                                    }}
                                  >
                                    <i className="fas fa-link" />
                                    <span>View Details</span>
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="third">
                            <div className="title">Contact</div>
                            <div
                              className="email"
                              notranslate="yes"
                            >
                              {!item.user?.is_dummy_user
                                ? item.user?.email
                                : localizeHelpers.translate('No Email Provided')}
                            </div>
                            <div
                              className="phone"
                              notranslate="yes"
                            >
                              {item.user?.phone}
                            </div>
                            {item.location?.city !== undefined && (
                              <div
                                className="address"
                                notranslate="yes"
                              >
                                <div>{item.location.line1}</div>
                                <div>{item.location.line2}</div>
                                <div>
                                  {item.location.city}, {item.location.state}{' '}
                                  {item.location.postal_code}
                                </div>
                                <div>{item.location.country}</div>
                              </div>
                            )}
                            <div className="add-donation-action">
                              <Button
                                icon="fa fa-plus"
                                onClick={() => {
                                  this.setState({ showAddDonation: true });
                                  this.setDonor(item);
                                }}
                                text={'Add Donation'}
                              />
                            </div>
                          </div>
                          <div className="third metrics">
                            <div className="metric long">
                              <div className="metric-inner">
                                {item.recurring_donations && (
                                  <div>
                                    <i className="fas fa-check-circle" />
                                    <span>A recurring donor</span>
                                  </div>
                                )}
                                {!item.recurring_donations && (
                                  <div>
                                    <i className="fas fa-times-circle" />
                                    <span>Not a recurring donor</span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="metric">
                              <div className="metric-inner">
                                <span notranslate="yes">{item.number_of_donations}</span>
                                <div>Total Donations</div>
                              </div>
                            </div>
                            <div className="metric">
                              <div className="metric-inner">
                                <span notranslate="yes">
                                  {formatCurrency(item.total_donated, currency, this.props.locale)}
                                </span>
                                <div>Lifetime Donation</div>
                              </div>
                            </div>
                            <div className="metric">
                              <div className="metric-inner">
                                <span notranslate="yes">
                                  {formatCurrency(
                                    item.average_donation,
                                    currency,
                                    this.props.locale,
                                  )}
                                </span>
                                <div>Average Donation</div>
                              </div>
                            </div>
                            <div className="metric">
                              <div className="metric-inner">
                                <span notranslate="yes">
                                  {formatCurrency(
                                    item.largest_donation?.amount || 0,
                                    currency,
                                    this.props.locale,
                                  )}
                                </span>
                                <div>Largest Donation</div>
                              </div>
                            </div>
                            <div className="metric">
                              <div className="metric-inner">
                                <span notranslate="yes">
                                  {item.last_donation !== undefined
                                    ? localizeHelpers.formatDate(
                                        item.last_donation?.created_at ?? new Date(),
                                        LocaleDateFormats.LL,
                                        this.props.locale,
                                      )
                                    : localizeHelpers.translate('Not Available')}
                                </span>
                                <div>First Donation</div>
                              </div>
                            </div>
                            <div className="metric">
                              <div className="metric-inner">
                                <span notranslate="yes">
                                  {item.last_donation !== undefined
                                    ? localizeHelpers.formatDate(
                                        item.last_donation?.updated_at ?? new Date(),
                                        LocaleDateFormats.LL,
                                        this.props.locale,
                                      )
                                    : localizeHelpers.translate('Not Available')}
                                </span>
                                <div>Last Donation</div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ),
                    };
                  })}
                />
                {this.props.groupState.groupDonors.length === 0 && (
                  <div className="empty">
                    {this.state.searchValue === '' ? (
                      <span>You don't have any donors, yet.</span>
                    ) : (
                      <span>Your search returned 0 results.</span>
                    )}
                  </div>
                )}
              </div>
              <Modal
                show={this.state.showAdd}
                onClose={() => {
                  this.setState({ showAdd: false });
                  this.closeAddDonation();
                }}
              >
                <div className="add-donor">
                  <div className="title-row">Add Donor</div>
                  <div className="subheader">Donor Information</div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.addDonor();
                    }}
                  >
                    <div className="form-1-1">
                      <TextField
                        required={true}
                        label="First Name"
                        value={this.state.firstName}
                        type="text"
                        name="firstName"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        required={true}
                        label="Last Name"
                        value={this.state.lastName}
                        type="text"
                        name="lastName"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="form-1-1">
                      <TextField
                        label="Email"
                        value={this.state.email}
                        type="text"
                        name="email"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        label="Phone"
                        value={this.state.phone}
                        type="text"
                        name="phone"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="details-row">
                      <DatePicker
                        maxYear={moment().year().toString()}
                        name="dob"
                        label="Date of Birth"
                        date={this.state.dob}
                        onChange={this.updateDOB}
                      />
                    </div>
                    <div className="details-row">
                      <Dropdown
                        name="gender"
                        label="Gender"
                        value={this.state.gender}
                        options={this.state.genders}
                        shouldSort={true}
                        onChange={(e: any) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="form-1-1">
                      <TextField
                        required={true}
                        label={'Street Address'}
                        value={this.state.address}
                        type="text"
                        name="address"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        label={'Apartment / Suite'}
                        value={this.state.apartment}
                        type="text"
                        name="apartment"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="form-1-1">
                      <TextField
                        required={true}
                        label={'City'}
                        value={this.state.city}
                        type="text"
                        name="city"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        required={true}
                        label={'State/Province'}
                        value={this.state.selectedProvince}
                        type="text"
                        name="selectedProvince"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="form-1-1">
                      <TextField
                        required={true}
                        label={'Country'}
                        value={this.state.country}
                        type="text"
                        name="country"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        required={true}
                        label={'Postal'}
                        value={this.state.postal}
                        type="text"
                        name="postal"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="actions">
                      <Button text={'Finish'} />
                    </div>
                  </form>
                </div>
              </Modal>
              <Modal
                class="filter-modal"
                show={this.state.showFilterModal}
                onClose={() => {
                  this.setState({ showFilterModal: false });
                }}
              >
                <FilterData
                  {...this.props}
                  currentFilters={this.state.filters}
                  filterOptions={[
                    { id: 'user.display_name', label: 'Donor Name', type: 'text' },
                    { id: 'number_of_donations', label: '# of Donations', type: 'number' },
                    { id: 'total_donated', label: 'Amount Donated', type: 'number' },
                  ]}
                  onApply={(appliedFilters: IActiveFilter[]) => {
                    this.setState({ filters: appliedFilters, showFilterModal: false }, () => {
                      this.props.getGroupDonors(
                        this.props.groupState.group.id,
                        this.state.searchValue +
                          '&sort=' +
                          this.state.sort +
                          '&' +
                          formatFilters(this.state.filters),
                      );
                    });
                  }}
                />
              </Modal>
              <Modal
                class="ContactDetailsModal"
                show={this.state.showDetailsModal}
                onClose={() => {
                  this.setState({ showDetailsModal: false });
                }}
                title={'Contact Details'}
              >
                <ContactDetails
                  userId={this.state.selectedUser?.id!}
                  {...this.props}
                />
              </Modal>

              <Modal
                show={this.state.showAddDonation}
                onClose={() => {
                  this.setState({ showAddDonation: false });
                  this.closeAddDonation();
                }}
              >
                <AddDonation
                  contact={this.state.selectedDonor!}
                  {...this.props}
                  onClose={this.closeAddDonation}
                  uiStateType={'default'}
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getGroupDonors,
  addDonor,
  createGroupDonationIntent,
  getGroupDonorsExport,
};

export default withRouter(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(DonorManagement)),
);
