import { applyMiddleware, CombinedState, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer, PersistConfig, PersistState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { initialUserState, userReducer } from './reducers/user';
import { initialSettingsState, settingsReducer } from './reducers/settings';
import { initialRegisterState, registerReducer } from './reducers/register';
import { initialGroupState, groupReducer } from './reducers/group';
import { initialGeolocationState, geolocationReducer } from './reducers/geolocation';
import { initialProfileState, profileReducer } from './reducers/profile';
import { initialChatState, chatReducer } from './reducers/chat';
import { initialToasterState, toasterReducer } from './reducers/toaster';
import { initialUserSearchState, userSearchReducer } from './reducers/userSearch';
import { initialNotificationsState, notificationsReducer } from './reducers/notifications';
import { initialEventState, eventReducer } from './reducers/event';
import { initialHubState, hubReducer } from './reducers/hub';
import { initialMediaAlbumState, mediaAlbumReducer } from './reducers/mediaAlbum';
import { initialGigState, gigReducer } from './reducers/gig';
import { initialCartState, cartReducer } from './reducers/cart';
import { initialAuctionState, auctionReducer } from './reducers/auction';
import { initialStoreState, storeReducer } from './reducers/store';
import { initialModalState, modalsReducer } from './reducers/modals';
import { footerReducer, initialFooterState } from './reducers/footer';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { addOnlineUser, ISocketInterface, receiveMessage, removeOnlineUser } from './actions/chat';
import { IConversation } from '@gigit/interfaces';
import { Config } from '@gigit/config';
import io from 'socket.io-client';

const appReducer = combineReducers({
  userState: userReducer,
  settingsState: settingsReducer,
  registerState: registerReducer,
  groupState: groupReducer,
  geolocationState: geolocationReducer,
  profileState: profileReducer,
  toasterState: toasterReducer,
  chatState: chatReducer,
  notificationsState: notificationsReducer,
  userSearchState: userSearchReducer,
  eventState: eventReducer,
  hubState: hubReducer,
  mediaAlbumState: mediaAlbumReducer,
  gigState: gigReducer,
  cartState: cartReducer,
  auctionState: auctionReducer,
  storeState: storeReducer,
  modalState: modalsReducer,
  footerState: footerReducer,
});

/** Represents our Application redux state.
 * We extract it dynamically from our appReducer so the type is always correct.
 */
export type IAppState = Exclude<Parameters<typeof appReducer>[0], undefined>;

/** Represents all our Application redux actions.
 * We extract it dynamically from our appReducer so the type is always correct.
 */
export type IAppActions = Parameters<typeof appReducer>[1];

/** Represents our App State + persisted state. */
export type IAppStateFull = IAppState & { _persist: PersistState };

/** Represents our App actions + persist actions. */
export type IAppActionsFull =
  | IAppActions
  | { type: 'RESET' }
  | { type: 'persist/REHYDRATE'; payload?: Partial<IAppState> };

/** Type for our dispatch function. */
export type AppDispatch = ThunkDispatch<IAppState, {}, IAppActions>;

/** Type for our getState() function. */
export type GetAppState = () => IAppState;

// Provide typescript typings for redux state/actions.
// See: https://redux.js.org/usage/usage-with-typescript#define-typed-hooks

/** Should be used instead of `useDispatch()`. Provides correct typing. */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/** Should be used instead of `useSelector()`. Provides correct typing. */
export const useAppSelector: TypedUseSelectorHook<IAppState> = useSelector;

const persistConfig: PersistConfig<CombinedState<IAppStateFull>> = {
  key: 'root',
  storage,

  // attempt at not saving, TODO: remove later
  blacklist: ['userState.awaitingToken'],
};

const rootReducer = (state: IAppState | undefined, action: IAppActions) => {
  if ((action as IAppActionsFull).type === 'RESET') {
    state = {
      userState: initialUserState,
      settingsState: initialSettingsState,
      registerState: initialRegisterState,
      groupState: initialGroupState,
      geolocationState: initialGeolocationState,
      profileState: initialProfileState,
      toasterState: initialToasterState,
      chatState: initialChatState,
      notificationsState: initialNotificationsState,
      userSearchState: initialUserSearchState,
      eventState: initialEventState,
      hubState: initialHubState,
      mediaAlbumState: initialMediaAlbumState,
      gigState: initialGigState,
      cartState: initialCartState,
      auctionState: initialAuctionState,
      storeState: initialStoreState,
      modalState: initialModalState,
      footerState: initialFooterState,
    };
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(
  persistConfig,
  (state: IAppStateFull | undefined, action: IAppActionsFull) => {
    if (action?.type === 'persist/REHYDRATE') {
      const payload = action?.payload as Partial<IAppState>;

      // GIG-740: Don't reload awaitingToken field if it's true. Doing so will cause the user to have to logout and back in if their token has expired.
      // TODO: Doing it this way is kinda hacky, refactor later.
      if (payload?.userState?.awaitingToken === true) {
        payload.userState.awaitingToken = false;
      }

      if (payload?.modalState?.depth) {
        payload.modalState.depth = 0;
      }
    }
    return rootReducer(state, action as IAppActions) as IAppStateFull;
  },
);

const setSocketHandlers = (socket: ISocketInterface, storeAPI: any) => {
  // fires when connection made to io
  socket.chat.on('online', (msg: { user_id: string }) => {
    storeAPI.dispatch(addOnlineUser(msg?.user_id));
  });
  // on logout
  socket.chat.on('offline', (msg: { user_id: string }) => {
    storeAPI.dispatch(removeOnlineUser(msg?.user_id));
  });
};

// Middleware for Socket
const socketMiddleware = () => {
  return (storeAPI: any) => (next: any) => (action: any) => {
    let token = storeAPI.getState().userState?.tokens?.access_token; //TODO: GIG-7896 - Seems like this is going to break

    switch (action.type) {
      case 'LOGIN': {
        if (token) {
          let socket: ISocketInterface = { chat: null };
          socket.chat = io(Config.web.REACT_APP_CHAT_GATEWAY + '/chat?token=' + token || '');
          setSocketHandlers(socket, storeAPI);
        }
        break;
      }
      case 'RECONNECT_CHAT': {
        if (token) {
          let socket: ISocketInterface = { chat: null };
          socket.chat = io(Config.web.REACT_APP_CHAT_GATEWAY + '/chat?token=' + token || '');

          setSocketHandlers(socket, storeAPI);
        }
        break;
      }
    }

    return next(action);
  };
};

export default function configureStore(): any {
  const store = createStore(
    persistedReducer,
    undefined,
    composeWithDevTools(applyMiddleware(thunk, socketMiddleware())),
  );
  let persistor = persistStore(store);

  return { store, persistor };
}
