import { ICause } from '@gigit/interfaces';
import React from 'react';
import Portrait from '../../Portrait/Portrait';

import './FocusAreas.scss';

interface IFocusAreasItem {
  focusAreas: ICause[];
  /** If the entities focus areas are less than the max length it fills empty slots to maintain a balance in UI if desired */
  autofill?: boolean;
}
export default function FocusAreas(props: IFocusAreasItem) {
  // View Helpers
  const maxLength = 4;
  const hasMore = props.focusAreas.length > maxLength;
  const remainder = props.focusAreas.length - maxLength;
  const remainderString = `+ ${remainder}`;
  const emptySet = Array.from({ length: 5 }, (x, i) => i + 1);

  const renderList = () => {
    if (props.focusAreas?.length === 0) {
      return emptySet.map((x, i) => {
        return (
          <div
            key={`empty-${i}`}
            className="no-causes"
          />
        );
      });
    }

    let list = props.focusAreas.map((focusArea, index) => {
      if (index < maxLength) {
        return (
          <Portrait
            size={36}
            hideMask={true}
            key={`${index}-${focusArea.id}`}
            currentImage={focusArea.icon_url ? `${focusArea.icon_url}` : ''}
          />
        );
      }

      if (hasMore) {
        return (
          <span className="empty-portrait">
            <var data-var="groups_involved_length">{remainderString}</var>
          </span>
        );
      }
    });

    /* if list is length + remainder is less than max fill the array with empty divs
     *  - we do this to keep the ui balanced
     */
    if (props?.autofill && list.length + remainder < maxLength + 1) {
      const emptyItems = Array.from(
        { length: maxLength + 1 - list.length - remainder },
        (x, i) => i + 1,
      );
      emptyItems.forEach((el, idx) =>
        list.unshift(
          <div
            key={idx}
            className="no-causes"
          />,
        ),
      );
    }

    return list;
  };

  return <div className="FocusAreas">{renderList()}</div>;
}
