import React, { FC } from 'react';
import { uiConstants } from '../../../constants';
import useSharedModal from '../../../hooks/useSharedModal';
import { OwnerObjectTypes } from '../../../interfaces';
import CoverImage from '../../CoverImage/CoverImage';
import './PageCoverImage.scss';

interface IProps {
  coverImageUrl?: string;
  ShowEditCover: boolean;
  manageUrl: string;
  showManageButton: boolean;
  ownerType?: string;
  ownerObject?: OwnerObjectTypes;
  handle?: string;
  onImageChange(file: any): void;
}

/** Cover Image that can be used in profile pages. */
const PageCoverImage: FC<IProps> = (props) => {
  const {
    handle,
    coverImageUrl,
    onImageChange,
    ShowEditCover,
    ownerType,
    showManageButton,
    manageUrl,
    ownerObject,
  } = props;
  const sharedModal = useSharedModal();

  function handleOpenShareModal() {
    if (ownerType && ownerObject && handle) {
      sharedModal.showShareModal({
        ownerType: ownerType as keyof typeof uiConstants.ownerType,
        ownerObject,
        handle,
        title: 'Share',
        modalClassName: 'ShareModalPageContainer',
      });
    }
  }

  return (
    <div className={'PageCoverImage'}>
      <CoverImage
        currentImage={coverImageUrl}
        onChange={onImageChange}
        overlay={ShowEditCover}
        className={!coverImageUrl ? 'cover-image-wrap' : ''}
      />
      {ownerType === uiConstants.ownerType.gig && (
        <div className={`float ${showManageButton ? 'more-left' : ''}`}>
          <span
            className="share-btn"
            onClick={handleOpenShareModal}
          >
            <i className="fal fa-share-alt" />
          </span>
        </div>
      )}
      {showManageButton && (
        <div className="float">
          <a href={manageUrl}>
            <span className="fad fa-user-shield" />
            <span className="manage-text">Manage</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default PageCoverImage;
