import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { handleInputChange } from '../../helpers';
import { Constants } from '@gigit/constants';
import { IGroupState } from '../../reducers/group';
import { IGigState } from '../../reducers/gig';
import { getGroupGigs } from '../../actions/group';
import { changeGigStatus } from '../../actions/gig';
import TextField from '../TextField/TextField';
import ContextMenu from '../ContextMenu/ContextMenu';
import Button from '../Button/Button';
import ChangeStatusMenu from '../ChangeStatusMenu/ChangeStatusMenu';
import CreateGigModal from '../CreateGigModal/CreateGigModal';
import './GigManagement.scss';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import { IOwnerObject } from '../../interfaces';
import { IUserState } from '../../reducers/user';
import { IEventSummaryFE } from '@gigit/interfaces';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  userState: IUserState;
  gigState: IGigState;
  owner: IOwnerObject;
  locale: string;
  getGroupGigs(groupId: string): void;
  changeGigStatus(gigId: string, status: string): void;
}

interface IState {
  searchValue: string;
  activeContextMenuId: number | null;
  showCreateGigModal: boolean;
}

class GigManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: '',
      activeContextMenuId: null,
      showCreateGigModal: false,
    };
  }

  componentDidMount() {
    this.props.getGroupGigs(this.props.groupState.group.id);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.gigState.gig !== this.props.gigState.gig) {
      this.props.getGroupGigs(this.props.groupState.group.id);
    }
  }

  toggleContextMenu = (key: number) => {
    if (this.state.activeContextMenuId === key) {
      this.setState({ activeContextMenuId: null });
    } else {
      this.setState({ activeContextMenuId: key });
    }
  };

  changeStatus(status: string, gigId: string) {
    if (gigId && status) {
      this.props.changeGigStatus(gigId, status);
    }
  }

  render() {
    let foundResults = false;

    return (
      <div className="GigManagement section-wrap">
        <div className="section-title">
          <div className="section-inner-title">Volunteer Opportunities</div>
          <div className="section-actions">
            <Button
              onClick={() => {
                this.setState({ showCreateGigModal: true });
              }}
              icon="fal fa-plus"
              text="Add Volunteer Opportunity"
              buttonType="dark"
              style={{ whiteSpace: 'nowrap', maxWidth: 'unset' }}
            />
          </div>
        </div>
        <div className="section-inner">
          <div className="GigManagement-list">
            <div className="search">
              <TextField
                icon="fas fa-search"
                placeholder="Search volunteer opportunities..."
                value={this.state.searchValue}
                type="text"
                name="searchValue"
                onChange={(e: any) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="list">
              <div className="headers">
                <div className="col gig-title">Volunteer Opportunity Name</div>
                <div className="col status">Status</div>
                <div className="col event">Event</div>
                <div className="col date">Start Date</div>
                <div className="col date">End Date</div>
                <div className="col actions" />
              </div>
              <div className="list-inner">
                <div className="list-rows">
                  {this.props.groupState.groupGigs &&
                    this.props.groupState.groupGigs.map((gig, index) => {
                      if (
                        this.state.searchValue === '' ||
                        (this.state.searchValue !== '' &&
                          gig.title.toLowerCase().includes(this.state.searchValue.toLowerCase()))
                      ) {
                        foundResults = true;

                        let _menuItems = [
                          {
                            onClick: () => {
                              this.props.history.push(`/gig/${gig.handle}`);
                            },
                            icon: 'fal fa-comment-dots',
                            label: 'View',
                          },
                          {
                            onClick: () => {
                              this.props.history.push(`/gig/${gig.handle}/admin`);
                            },
                            icon: 'fal fa-comment-dots',
                            label: 'Manage',
                          },
                        ];

                        return (
                          <div
                            key={index}
                            className="row"
                          >
                            <div
                              className="col gig-title"
                              notranslate="yes"
                            >
                              {gig.title}
                            </div>
                            <div className="col status">
                              <ChangeStatusMenu
                                {...this.props}
                                itemType={'gig'}
                                event={gig.event as any as IEventSummaryFE}
                                listOfStatuses={Object.keys(Constants.gig_status)}
                                currentStatus={gig.status?.code || ''}
                                onStatusChanged={(status) =>
                                  this.changeStatus(status, gig.id || '')
                                }
                              />
                            </div>
                            <div
                              className="col event"
                              notranslate="yes"
                            >
                              {gig.event ? (
                                <a href={`/event/${gig.event.handle}`}>{gig.event.handle}</a>
                              ) : (
                                ''
                              )}
                            </div>
                            <div
                              className="col date"
                              notranslate="yes"
                            >
                              {gig.start_date
                                ? localizeHelpers.formatDate(
                                    gig.start_date,
                                    LocaleDateFormats.LL,
                                    this.props.locale,
                                  )
                                : ''}
                            </div>
                            <div
                              className="col date"
                              notranslate="yes"
                            >
                              {gig.end_date
                                ? localizeHelpers.formatDate(
                                    gig.end_date,
                                    LocaleDateFormats.LL,
                                    this.props.locale,
                                  )
                                : ''}
                            </div>
                            <div className="col actions">
                              <i className="fal fa-ellipsis-h-alt" />
                              <ContextMenu
                                onMouseLeave={() => {}}
                                showMenu={true}
                                menuItems={_menuItems}
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
                  {this.props.groupState.groupGigs &&
                    this.props.groupState.groupGigs.length === 0 && (
                      <div className="empty">
                        Cause doesn't have any active volunteer opportunities at the moment.
                      </div>
                    )}
                  {this.state.searchValue !== '' && !foundResults && (
                    <div className="empty">Your search returned zero results.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <CreateGigModal
          show={this.state.showCreateGigModal}
          owner={this.props.owner}
          history={this.props.history}
          onClose={() => this.setState({ showCreateGigModal: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    gigState: store.gigState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getGroupGigs,
  changeGigStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GigManagement));
