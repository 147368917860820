import React, { useState, useEffect } from 'react';
import { IUserContact } from '@gigit/interfaces';
import useToastDispatcher from '../../hooks/useToaster';
import './OrganizerContacts.scss';
import TextField from '../TextField/TextField';
import { userRequestActions } from '../../requestActions';
import OrganizerContact from './OrganizerContact/OrganizerContact';
interface IProps {
  objectType: string;
  objectId: string;
}

const OrganizerContacts: React.FC<IProps> = (props: IProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [organizerContacts, setOrganizerContacts] = useState<IUserContact[]>();

  const { dispatchToastError } = useToastDispatcher();

  useEffect(() => {
    props.objectId && props.objectType && fetchOrganizerContacts();
  }, []);

  const fetchOrganizerContacts = async () => {
    try {
      let organizerContacts = await userRequestActions.getAvailableOrganizerContacts(
        props.objectType,
        props.objectId,
      );
      organizerContacts && setOrganizerContacts(organizerContacts);
    } catch (error) {
      dispatchToastError(error, 'Fetch Users');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="OrganizerContacts-list">
      <TextField
        icon="fas fa-search"
        placeholder={'Search...'}
        value={searchValue}
        type="text"
        name="searchValue"
        onChange={handleInputChange}
      />
      {organizerContacts &&
        organizerContacts.map((contact) => <OrganizerContact contact={contact} />)}
    </div>
  );
};

export default OrganizerContacts;
