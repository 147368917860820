import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { defaultCurrency, formatCurrency } from '../../../helpers';
import useToastDispatcher from '../../../hooks/useToaster';
import { IGetMetricParamsBulkFE, metricRequestActions } from '../../../requestActions/metrics';
import { userSelectors } from '../../../selectors/user';
import { IAppState } from '../../../store';
import './MetricsList.scss';
import { Constants } from '@gigit/constants';
import { userRequestActions } from '../../../requestActions';

interface IProps {
  objectType: string;
  userId?: string;
  setShowVolunteerModal?: (v: boolean) => void;
}

const MetricsList: React.FC<IProps> = (props) => {
  const userInfo = useSelector((state: IAppState) => userSelectors.getUser(state));
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));

  const [amountDonated, setAmountDonated] = useState<number>(0);
  const [volunteerHoursTotal, setVolunteerHoursTotal] = useState<number>(0);
  const [creditsApproved, setCreditsApproved] = useState<number>(0);
  const [volunteerHoursApproved, setVolunteerHoursApproved] = useState<number>(0);
  const [volunteerHoursPending, setVolunteerHoursPending] = useState<number>(0);
  const [causesSupported, setCausesSupported] = useState<number>(0);
  const [eventsSupported, setEventsSupported] = useState<number>(0);
  const [donationsMade, setDonationsMade] = useState<number>(0);
  const [fundraisersSupported, setFundraisersSupported] = useState<number>(0);

  const { dispatchToastError } = useToastDispatcher();
  const history = useHistory();

  useEffect(() => {
    getMetrics();
  }, []);

  async function getMetrics() {
    try {
      let metrics = await metricRequestActions.getAvailableMetricsForObjectType(
        Constants.object_type.user,
      );
      metrics = metrics.filter((metric) =>
        metric.object_types.includes(Constants.object_type.user),
      );

      const creditBalance = await userRequestActions.getCreditBalance();
      setCreditsApproved(creditBalance.balance);

      await setMetrics({
        metrics: metrics.map((m) => {
          return {
            object_type: Constants.object_type.user,
            metric: m.metric,
            object_id: props.userId || userInfo.id || '',
            currency:
              m.unit_type === 'currency'
                ? userInfo?.account?.currency || defaultCurrency.toLowerCase()
                : undefined,
          };
        }),
      });
    } catch (error) {
      dispatchToastError(error, 'User Metrics');
    }
  }

  async function setMetrics(metricsList: IGetMetricParamsBulkFE) {
    try {
      const metricInfo = await metricRequestActions.getMetricsBulk(metricsList);
      metricInfo.metrics.forEach((metric) => {
        switch (metric.metric) {
          case 'amount_donated':
            setAmountDonated(metric.value);
            break;
          case 'volunteer_hours_total':
            setVolunteerHoursTotal(metric.value);
            break;
          case 'volunteer_hours_approved':
            setVolunteerHoursApproved(metric.value);
            break;
          case 'volunteer_hours_pending':
            setVolunteerHoursPending(metric.value);
            break;
          case 'num_groups_supported':
            setCausesSupported(metric.value);
            break;
          case 'num_events_supported':
            setEventsSupported(metric.value);
            break;
          case 'num_donations_made':
            setDonationsMade(metric.value);
            break;
          case 'num_fundraisers':
            setFundraisersSupported(metric.value);
            break;
        }
      });
    } catch (error) {
      dispatchToastError(error, 'User Metrics');
    }
  }

  return (
    <div className="MetricsList">
      {props.objectType === 'userProfile' && (
        <ul>
          <li>
            Donations Made <span>{donationsMade}</span>
          </li>
          <li>
            Volunteer Hours <span>{`${volunteerHoursTotal}  h`}</span>
          </li>
          <li>
            Causes Supported <span>{causesSupported}</span>
          </li>
          <li>
            Events Supported <span>{eventsSupported}</span>
          </li>
          {/* TODO: Fix have route fixed for fundraisers supported <li>Fundraisers Supported <span>{fundraisersSupported}</span></li> */}
        </ul>
      )}
      {props.objectType === 'communityFeed' && (
        <ul>
          <li onClick={() => history.push('/dashboard?section=activity&tab=transactions')}>
            Donations Made <span>{donationsMade}</span>
          </li>
          <li onClick={() => props?.setShowVolunteerModal?.(true)}>
            Volunteer Hours <span>{`${volunteerHoursTotal}  h`}</span>
          </li>
          <li onClick={() => history.push('/dashboard?section=pages&tab=group')}>
            Causes Supported <span>{causesSupported}</span>
          </li>
          <li onClick={() => history.push('/dashboard?section=pages&tab=fundraiser')}>
            Events Supported <span>{eventsSupported}</span>
          </li>
          <li onClick={() => history.push('/dashboard?section=activity&tab=transactions')}>
            Amount Donated{' '}
            <span>
              {formatCurrency(
                amountDonated,
                userInfo?.account?.currency || defaultCurrency,
                locale,
              )}
            </span>
          </li>
          <li onClick={() => history.push('/dashboard?section=activity&tab=transactions')}>
            Volunteer Credits{' '}
            <span>
              {formatCurrency(
                creditsApproved,
                userInfo?.account?.currency || defaultCurrency,
                locale,
              )}
            </span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default MetricsList;
