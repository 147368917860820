import React, { RefObject } from 'react';
import { handleDebounce, handleInputChange } from '../../helpers';
import Portrait from '../Portrait/Portrait';
import TextField from '../TextField/TextField';

import './SearchableDropdown.scss';

export interface ISearchableDropdownListItem {
  label: string;
  id: string;
  image?: string;
}

interface ISpecialOption {
  onClick: () => void;
  label: string;
  image?: string;

  /** Controls if the special option appears or not. Default is true. */
  isEnabled?: boolean;
}

interface IProps {
  /** Special option is always displayed regardless of search. */
  specialOption?: ISpecialOption;
  selectedItem: ISearchableDropdownListItem | null;
  label?: string;
  list: ISearchableDropdownListItem[];

  /** (C.H) Used to Show entire list (scrollable) in case users don't know what they want to search in list */
  showFullListOnEmpty?: boolean;

  showOnlySearchValue?: boolean;

  setSelectedItem: (newSelectedItem: ISearchableDropdownListItem | null) => void;

  /** called when search text is entered, allows async fetching of list data. */
  onSearch: (search: string) => void;

  /** used to set input as required in parent forms */
  isRequired?: boolean;

  notranslate?: 'yes';
}

interface IState {
  searchValue: string;
  displayList: boolean;
}

/** A dropdown that can be searched. */
export class SearchableDropdown extends React.Component<IProps, IState> {
  inputRef: RefObject<HTMLInputElement>;
  dropdownRef: RefObject<HTMLUListElement>;
  containerRef: RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: '',
      displayList: false,
    };
    this.inputRef = React.createRef();
    this.dropdownRef = React.createRef();
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = (ev: MouseEvent) => {
    // When the user clicks outside the input and dropdown we want to close the dropdown list.
    if (
      !this.inputRef.current?.contains(ev.target as Node) &&
      !this.dropdownRef.current?.contains(ev.target as Node) &&
      !this.containerRef.current?.contains(ev.target as Node)
    ) {
      this.setState({ displayList: false });
    }
  };

  onSelectItem = (newSelectedItem: ISearchableDropdownListItem | null) => {
    this.props.setSelectedItem(newSelectedItem);

    // When user selects an item, we clear out search.
    if (newSelectedItem != null) {
      this.setState({
        searchValue: '',
        displayList: false,
      });
    }
  };

  onSpecialOptionClicked = () => {
    this.props.specialOption?.onClick();
    this.setState({
      displayList: false,
    });
  };

  render() {
    const isSpecialOptionEnabled =
      this.props.specialOption &&
      (this.props.specialOption.isEnabled === undefined || this.props.specialOption.isEnabled);

    return (
      <div
        className="SearchableDropdown"
        ref={this.containerRef}
        notranslate={this.props.notranslate}
      >
        <TextField
          preTextRenderer={() =>
            this.props.selectedItem && (
              <div className="selectedItem">
                <Portrait
                  size={30}
                  currentImage={this.props.selectedItem?.image || ''}
                />
              </div>
            )
          }
          reference={this.inputRef}
          label={this.props.label || ''}
          autoComplete="off"
          type="text"
          value={
            this.props.selectedItem && !this.props?.showOnlySearchValue
              ? this.props.selectedItem.label
              : this.state.searchValue
          }
          name="searchValue"
          onChange={(e: any) => {
            handleInputChange(e, this, false, () => {
              handleDebounce(this.state.searchValue).then((res) => {
                this.props.onSearch(this.state.searchValue);
                this.props.setSelectedItem(null);
              });
            });
          }}
          required={this.props.isRequired}
          onFocus={() => {
            this.setState({ displayList: true });
          }}
        />

        {!!this.props.list.length && this.state.displayList && (
          <ul
            className="listing"
            ref={this.dropdownRef}
          >
            {/* Special option always displays at top of search list. */}
            {this.props.specialOption && isSpecialOptionEnabled && (
              <li
                onClick={this.onSpecialOptionClicked}
                className="list-item special-option"
              >
                <div className="list-item-inner">
                  <span className={`${this.props.specialOption.image} special-option-icon`} />
                  <div className="special-option-label">{this.props.specialOption?.label}</div>
                </div>
              </li>
            )}
            {this.props.list
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((item, index) => {
                if (
                  item.label
                    .toLowerCase()
                    .trim()
                    .includes(this.state.searchValue.toLowerCase().trim()) ||
                  this.props.showFullListOnEmpty
                ) {
                  return (
                    <li
                      onClick={() => {
                        this.onSelectItem(item);
                      }}
                      key={index}
                      className="list-item"
                    >
                      <div className="list-item-inner">
                        <Portrait
                          size={30}
                          currentImage={item.image || ''}
                        />
                        <div className="list-item-label">{item.label}</div>
                      </div>
                    </li>
                  );
                } else {
                  return null;
                }
              })}
          </ul>
        )}
      </div>
    );
  }
}
