import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import SpotlightTile from './SpotlightTile';

import './SpotlightCarousel.scss';
import {
  IEmbeddedGroup,
  IMyAvailableDonationMatchingProgram,
  ISpotlightReturn,
} from '@gigit/interfaces';
import Modal from '../../Modal/Modal';
import Portrait from '../../Portrait/Portrait';
import { useHistory } from 'react-router-dom';
import Button from '../../Button/Button';
import CommunityFeedDonationModal from '../../UserDashboard/MyActivity/CommunityFeed/CommunityFeedDonationModal/CommunityFeedDonationModal';

interface SpotlightCarouselProps {
  spotlightItems: ISpotlightReturn[];
}
export default function SpotlightCarousel(props: SpotlightCarouselProps) {
  const [showDonationMatchingDonateModal, setShowDonationMatchingDonateModal] =
    useState<boolean>(false);
  const [currentSpotlightItem, setCurrentSpotlightItem] = useState<ISpotlightReturn | null>(null);
  const history = useHistory();

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToScroll: 1,
  };

  const getSpotlightCauses = () => {
    if (currentSpotlightItem) {
      return (currentSpotlightItem.object as IMyAvailableDonationMatchingProgram)
        .allowed_groups_info;
    }
  };

  const getMode = (item: ISpotlightReturn) => {
    if (item.object_type === 'group') {
      return 'entity';
    } else if (item.object_type === 'event') {
      return 'event';
    } else {
      return 'matching';
    }
  };

  const onAction = (id: string) => {
    // back end should properly return the object_id as ID (object_id is probably fine too) for front end to use (ConvertToFrontEndType, I believe)
    const spotlightItem = props.spotlightItems.find((item) => {
      const itemId = item.object_id.toString();
      return itemId === id;
    });

    if (!spotlightItem) return;
    setCurrentSpotlightItem(spotlightItem);
    setShowDonationMatchingDonateModal(true);
  };

  const onCloseDonationMatchingDonateModal = () => {
    setShowDonationMatchingDonateModal(false);
    setCurrentSpotlightItem(null);
  };
  return (
    <section className="SpotlightCarousel">
      <Slider {...sliderSettings}>
        {props.spotlightItems.map((item, index) => {
          const mode = getMode(item);
          return (
            <SpotlightTile
              mode={mode}
              spotlightItem={item}
              key={index}
              buttonAction={{ actionText: 'Donate', onAction }}
              causes={(item?.object as any)?.allowed_groups_info}
            />
          );
        })}
      </Slider>
      <CommunityFeedDonationModal
        showDonateModal={showDonationMatchingDonateModal}
        onClose={onCloseDonationMatchingDonateModal}
        matchingProgramCauses={getSpotlightCauses()}
      />
    </section>
  );
}
