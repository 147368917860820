import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { IAppState } from '../../store';
import { IUserState } from '../../reducers/user';
import { ISettingsState } from '../../reducers/settings';
import { ICartState } from '../../reducers/cart';
import { updateSettings } from '../../actions/settings';
import { removeItem, resetCartError } from '../../actions/cart';
import { createToast } from '../../actions/toaster';
import Modal from '../Modal/Modal';
import ContextMenu from '../../components/ContextMenu/ContextMenu';
import Portrait from '../../components/Portrait/Portrait';
import Button from '../../components/Button/Button';
import logoLight from '../../assets/logo-light.svg';
import logoDark from '../../assets/logo-dark.svg';
import './Header.scss';
import {
  defaultCurrency,
  formatCurrency,
  getUnreadChatCount,
  isMobileScreen,
  toastError,
} from '../../helpers';
import { IToast } from '../../interfaces';
import { userSelectors } from '../../selectors/user';
import UserSearch from '../UserSearch/UserSearch';
import UserMenu from '../UserMenu/UserMenu';
import { INotificationsState } from '../../reducers/notifications';
import { IChatState } from '../../reducers/chat';
import { uiConstants } from '../../constants';

const HEADER_BREAKPOINT = 1010;
interface IProps extends RouteComponentProps<any> {
  createToast(toast: IToast): void;
  resetCartError(): void;
  removeItem(index: number): void;
  navigation: any;
  userState: IUserState;
  settingsState: ISettingsState;
  cartState: ICartState;
  chatState: IChatState;
  notificationState: INotificationsState;
  locale: string;
  updateSettings(payload: any): void;
}

interface IState {
  showUserMenu: boolean;
  showUserSubMenu: boolean;
  locationStyles: any;
  showAddItemModal: boolean;
  showIWantTo: boolean;
  height: number;
  width: number;
  resize: boolean;
}

class Header extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showUserMenu: false,
      showUserSubMenu: false,
      showIWantTo: false,
      locationStyles: {
        '/search': 'fluid border',
      },
      showAddItemModal: false,
      height: window.innerHeight,
      width: window.innerWidth,
      resize: false,
    };
  }

  componentDidMount() {
    this.setBodyClass(this.props.location);
    this.props.history.listen((location) => {
      this.resetContextMenuState();

      this.setBodyClass(location);

      window.scrollTo(0, 0);
    });

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevProps.cartState.totalItems < this.props.cartState.totalItems &&
      this.props.location.pathname !== '/checkout' &&
      !this.props.location.pathname.includes('/admin') &&
      !this.props.location.pathname.includes('setup') &&
      !this.props.location.pathname.includes('/company/')
    ) {
      this.setState({
        showAddItemModal: true,
      });
    }

    if (
      prevProps.cartState.error !== this.props.cartState.error &&
      this.props.cartState.error !== ''
    ) {
      const toast = toastError(this.props.cartState.error, 'Cart Error');
      this.props.createToast(toast);

      this.props.resetCartError();
    }
  }

  toggleDarkMode = () => {
    let _darkMode = true;

    if (this.props.settingsState.darkMode) {
      _darkMode = false;
    }

    this.props.updateSettings({
      darkMode: _darkMode,
    });
  };

  toggleContextMenu = (type: keyof IState, value: boolean) => {
    this.setState<never>(
      {
        [type]: value,
      },
      () => {
        if (this.state[type] === true) {
          this.resetContextMenuState(type);
        }
      },
    );
  };

  resetContextMenuState = (excludeType?: keyof IState) => {
    this.setState({
      showIWantTo: false,
      ...(excludeType ? { [excludeType]: true } : {}),
    });
  };

  setBodyClass(location: any) {
    document.body.removeAttribute('class');
    document.body.removeAttribute('style');

    if (location.pathname.split('/').length > 0 && location.pathname.split('/')[1]) {
      document.body.classList.add(location.pathname.split('/')[1]);
    }
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  isLoggedIn = () => {
    return this.props.userState.isLoggedIn;
  };

  getSubTotal = () => {
    let _total = 0;

    for (let i in this.props.cartState.items) {
      _total += this.props.cartState.items[i].price * this.props.cartState.items[i].quantity;
    }

    return _total;
  };

  renderIWantToContextMenu = () => {
    return (
      <ContextMenu
        title="I want to..."
        onMouseLeave={() => this.toggleContextMenu('showIWantTo', false)}
        showMenu={this.state.showIWantTo}
      >
        <div className="inner">
          <ul className="column first">
            <li>
              <span>
                <Link to="/onboarding/event">Create Event or Fundraiser</Link>
              </span>
            </li>
            <li>
              <span>
                <Link to="/onboarding/group">Claim or Create a Cause Page</Link>
              </span>
            </li>
            <li>
              <span>
                <Link to="/onboarding/gig">Create Volunteer Opportunity</Link>
              </span>
            </li>
            <li>
              <span>
                <Link to="/onboarding/company">Create a Company Page</Link>
              </span>
            </li>
            <li>
              <span>
                <Link to="/story/create">Post a Story</Link>
              </span>
            </li>
          </ul>
          {/* <ul className="column last">
                        <li><span><Link to="/setup/volunteer">Volunteer</Link></span></li>
                        <li><span><Link to="/discover/events">Find Events</Link></span></li>
                    </ul> */}
        </div>
      </ContextMenu>
    );
  };

  toggleMobileMenuIcon = (showIcon: boolean) => {
    this.setState({
      showUserMenu: showIcon,
    });
  };

  render() {
    const isLoggedIn = this.isLoggedIn();
    const isMobile = isMobileScreen(HEADER_BREAKPOINT);
    const currency: string = this.props.cartState?.total?.currency || defaultCurrency;
    const unreadChatCount = getUnreadChatCount(
      this.props.chatState.conversations,
      this.props.userState.user?.id || '',
    );

    return (
      <div
        className={`${
          this.state.locationStyles[this.props.location.pathname] !== undefined
            ? 'Header ' + this.state.locationStyles[this.props.location.pathname]
            : this.props.location.pathname.includes('/company/') &&
                !this.props.location.pathname.includes('/onboarding') &&
                !this.props.location.pathname.includes('/admin') &&
                !this.props.location.pathname.includes('/companyInfo')
              ? 'Header fluid no-fixed'
              : 'Header fluid'
        } ${this.props.location.pathname.includes('/admin') ? 'admin' : ''}`}
      >
        <div className="wrap">
          <div
            className="logo"
            onMouseEnter={() => this.resetContextMenuState()}
          >
            <Link
              className="gigit-logo"
              to="/"
            >
              <img
                alt="Kambeo"
                src={this.props.settingsState.darkMode ? logoDark : logoLight}
              />
            </Link>
          </div>

          <div className="user-search-container">{/* <UserSearch /> */}</div>

          <div className={isLoggedIn ? 'navigation logged-in' : 'navigation logged-out'}>
            <ul className="main-menu-nav with-text">
              {!isMobile && (
                <Fragment>
                  <li
                    onClick={() => this.toggleContextMenu('showIWantTo', true)}
                    className="main-menu-item header-context-menu i-want-to"
                  >
                    <span className={`icon i-want-to ${this.state.showIWantTo ? 'show' : ''}`} />
                    {this.renderIWantToContextMenu()}
                  </li>
                  {this.props.cartState.items.length > 0 && (
                    <Link
                      to="/checkout"
                      className="main-menu-item cart"
                    >
                      <i className="fas fa-shopping-cart" />
                      <div className="count">
                        {this.props.cartState.totalItems > uiConstants.notificationLimit
                          ? uiConstants.overNotificationLimitTxt
                          : this.props.cartState.totalItems}
                      </div>
                    </Link>
                  )}
                  {isLoggedIn && unreadChatCount > 0 && (
                    <Link
                      to="/dashboard?section=activity&tab=network"
                      className="main-menu-item chat"
                    >
                      <i className="fas fa-comment" />
                      <div className="count">
                        {unreadChatCount > uiConstants.notificationLimit
                          ? uiConstants.overNotificationLimitTxt
                          : unreadChatCount}
                      </div>
                    </Link>
                  )}
                </Fragment>
              )}
              <li className="main-menu-item header-context-menu user-menu">
                <UserMenu
                  mobileShow={this.state.showUserMenu}
                  toggleMenuIcon={this.toggleMobileMenuIcon}
                />
              </li>

              {isMobile && (
                <li
                  className={`menu-icon ${this.state.showUserMenu && 'open'}`}
                  onClick={() => this.toggleContextMenu('showUserMenu', !this.state.showUserMenu)}
                >
                  <span /> <span /> <span /> <span />
                </li>
              )}
            </ul>
          </div>
        </div>
        <Modal
          class="add-cart-global"
          show={this.state.showAddItemModal}
          onClose={() => {
            this.setState({ showAddItemModal: false });
          }}
          title="Added to your cart"
        >
          {this.props.cartState.items.length > 0 && (
            <div className="add-item-modal">
              <Portrait
                size={140}
                currentImage={this.props.cartState.lastItem.media[0]?.url || ''}
              />
              <div
                className="item-name"
                notranslate="yes"
              >
                {this.props.cartState.lastItem.name}
              </div>
              <div
                className="item-group"
                notranslate="yes"
              >
                {this.props.cartState.lastItem.owner_handle}
              </div>
              <div
                className="item-price"
                notranslate="yes"
              >
                {formatCurrency(this.props.cartState.lastItem.price, currency, this.props.locale)}
              </div>
              <div className="cart-info">
                <div className="item-count">
                  <var data-var="number_of_items_in_cart">{this.props.cartState.totalItems}</var>
                  &nbsp;items in cart
                </div>
                <div className="item-total">
                  Subtotal
                  <var data-var="sub_total">
                    &nbsp;{formatCurrency(this.getSubTotal(), currency, this.props.locale)}
                  </var>
                </div>
              </div>
              <div className="add-item-actions">
                <Button
                  text="Continue Shopping"
                  onClick={() => {
                    this.setState({ showAddItemModal: false });
                  }}
                />
                <Link
                  className="link"
                  onClick={() => this.setState({ showAddItemModal: false })}
                  to="/checkout"
                >
                  View Cart
                </Link>
              </div>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    settingsState: store.settingsState,
    cartState: store.cartState,
    chatState: store.chatState,
    notificationState: store.notificationsState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  removeItem,
  updateSettings,
  createToast,
  resetCartError,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
