import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IOwnerObject } from '../../interfaces';
import {
  IChangeDonationDestinationRequest,
  IEventSummaryFE,
  IEventIndividual,
  IEventTeam,
  ITransaction,
  ITransactionSummary,
} from '@gigit/interfaces';
import Modal from '../Modal/Modal';
import { createToast } from '../../actions/toaster';
import P2PSelector from '../../routes/Donate/P2PSelector/P2PSelector';
import './DonationDestinationModal.scss';
import Button from '../Button/Button';
import { errorHelpers, toastError, toastSuccess, typeHelpers } from '../../helpers';
import { localizeHelpers } from '../../localizeHelpers';
import { donationRequestActions } from '../../requestActions/donations';

interface IProps {
  owner: IOwnerObject;
  donation: ITransactionSummary | null;
  show: boolean;
  onClose: () => void;
  onDestinationChanged?: (transaction: ITransaction) => void;
}

/** Modal for changing the "destination" of a donation.
 * This allows the admin to change what individual, team or event a donation goes towards.
 */
const DonationDestinationModal: FC<IProps> = (props: IProps) => {
  type SelectedItemType = IEventSummaryFE | IEventIndividual | IEventTeam | null;

  const [selectedItem, setSelectedItem] = useState<{ type: string; item: SelectedItemType } | null>(
    null,
  );
  const [validationError, setValidationError] = useState<string>('');

  const dispatch = useDispatch();

  const submitDonationDestinationChange = () => {
    if (!selectedItem) {
      const toast = toastError(localizeHelpers.translate(validationError), 'Change Destination');
      dispatch(createToast(toast));
      return;
    }

    const payload: { [key: string]: IChangeDonationDestinationRequest } = {
      event: { event_id: selectedItem.item?.id },
      team: { team_id: selectedItem.item?.id },
      individual: { individual_id: selectedItem.item?.id },
    };

    typeHelpers.assertNotNullOrUndefined(props.donation);
    donationRequestActions
      .changeDonationDestination(props.owner, props.donation, payload[selectedItem.type])
      .then((responseData) => {
        const toast = toastSuccess(
          localizeHelpers.translate('Your donation has been moved successfully'),
          'Change Destination',
        );
        dispatch(createToast(toast));
        props.onDestinationChanged?.(responseData);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Change Destination');
        dispatch(createToast(toast));
      })
      .finally(() => {
        setSelectedItem(null);
        props.onClose();
      });
  };

  return (
    <div className="DonationDestinationModal">
      <Modal
        show={props.show}
        onClose={() => props.onClose()}
        title="Edit Donation"
        contentClassName="destination-content"
      >
        <P2PSelector
          owner={props.owner}
          changeDestination={true}
          onP2PSelect={(item) => {
            setSelectedItem(item);
          }}
          validateP2PValue={(error: string) => {
            setValidationError(error);
          }}
        />

        <div className="controls">
          <Button
            buttonClass="cancel-button"
            onClick={() => {
              setSelectedItem(null);
              props.onClose();
            }}
            text="Cancel"
          />
          <Button
            isDisabled={!selectedItem}
            onClick={submitDonationDestinationChange}
            text="Save"
          />
        </div>
      </Modal>
    </div>
  );
};

export default DonationDestinationModal;
