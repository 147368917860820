import React, { useState } from 'react';
import './SelectPayment.scss';
import ModalHeader from '../../../Modal/ModalHeader/ModalHeader';
import Dropdown from '../../../Dropdown/Dropdown';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import { uiConstants } from '../../../../constants/uiConstants';
import { donationRequestActions } from '../../../../requestActions';
import { errorHelpers, toastError, toastSuccess } from '../../../../helpers';
import { localizeHelpers } from '../../../../localizeHelpers';
import { IOwnerObject, IToast } from '../../../../interfaces';
import { ITransactionSummary } from '@gigit/interfaces';

interface IProps {
  showSelectPaymentModal: boolean;
  hideModal(): void;
  createToast(toast: IToast): void;
  owner: IOwnerObject;
  order: ITransactionSummary | null;
  getDonations(params: { groupId?: string; eventId?: string }): void;
}

export const SelectPayment: React.FC<IProps> = (props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    uiConstants.adminPaymentMethods[0].value,
  );

  const updateTransactionPayment = async (): Promise<void> => {
    if (props.order && props.order?.id) {
      try {
        await donationRequestActions.updateTransactionPayment({
          groupId: props.order.group_id,
          transactionId: props.order.id,
          params: { paymentMethod: selectedPaymentMethod },
        });

        const toast = toastSuccess(
          localizeHelpers.translate('Purchase successfully marked as payed'),
          'Store Purchase',
        );
        props.createToast(toast);

        await props.getDonations({
          groupId: props.owner.ownerType === 'group' ? props.owner.ownerId : undefined,
          eventId: props.owner.ownerType === 'event' ? props.owner.ownerId : undefined,
        });
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Mark as Paid');
        props.createToast(toast);
      }
    }
  };

  return (
    <Modal
      show={props.showSelectPaymentModal}
      onClose={() => {
        props.hideModal();
      }}
      contentClassName="SelectPayment"
    >
      <ModalHeader
        className="select-payment-header"
        title="Mark as Paid"
        onClose={() => {}}
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await updateTransactionPayment();
          props.hideModal();
        }}
      >
        <div className="select-payment">
          <div className="payment-info">Payment Information</div>

          <Dropdown
            shouldSort={true}
            value={selectedPaymentMethod}
            label="Type of payment"
            onChange={(e) => {
              setSelectedPaymentMethod(e.target.value);
            }}
            name="purchase-method"
            options={uiConstants.adminPaymentMethods}
          />
        </div>
        <div className="controls">
          <Button
            buttonClass="cancel-button"
            type="button"
            onClick={() => {
              props.hideModal();
            }}
            text="Cancel"
          />
          <Button
            buttonClass="save-button"
            type="submit"
            text="Save"
          />
        </div>
      </form>
    </Modal>
  );
};
