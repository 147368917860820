import React, { ChangeEvent, useState, ReactNode } from 'react';
import { combineClassNames } from '../../../helpers';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../PopupMenu/PopupMenu';
import { ReactComponent as FailedIcon } from '../../../assets/show-error.svg';
import './Dropdown.scss';
import Portrait from '../../Portrait/Portrait';

export interface IOptions {
  label: string;
  value: string;
}

export interface ICustomDropdownProps {
  contextMenuItemsType: IPopupMenuItem[];
  popupMenuConfigType: IShowPopupConfig;
  showMenuClassType: boolean;
  isSearchable: boolean;
  value: string;
  name: string;
  label: string;
  required: boolean;
  disabled: boolean;
  placeholder: string;
  shouldSort: boolean;

  customListElements?: ReactNode[];
  className?: string;
  containerWrapClassNames?: string;
  notranslate?: 'yes';
  dropdownWidth?: number;
  popupMenuClass?: string;
  icon?: string;
  options?: IPopupMenuItem[];

  onClick: (e: ChangeEvent<HTMLSelectElement>) => void;
}

interface IProps extends ICustomDropdownProps {}

export const CustomDropdown: React.FC<IProps> = (props) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [inputValueCopy, setInputValueCopy] = useState<string>('');
  const [options, setOptions] = useState<IPopupMenuItem[]>(props?.options || []);
  const [sortedOptions, setSortedOptions] = useState<IPopupMenuItem[]>([]);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [hasInputError, setHasInputError] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(true);
  const [previousValue, setPreviousValue] = useState<string>('');
  const [previousShowMenu, setPreviousShowMenu] = useState<boolean>(false);

  function sortOptions(options: IPopupMenuItem[], removeSelectedOption: boolean) {
    if (removeSelectedOption) {
      options.map((option) => {
        if (option.isSelected === true) {
          option.isSelected = false;
        }
      });
    }

    let placeholder = props?.placeholder || options.filter((opt) => opt.label === '')[0]?.label;

    options = placeholder ? options.filter((opt) => opt.label !== placeholder) : options;

    if (props?.shouldSort && options.length > 0) {
      options = options.sort((a, b) => a.label!.localeCompare(b.label!));
    }

    setSortedOptions(options);
    setOptions(options);
  }

  function searchInputChange(inputValue: any) {
    setInputValue(inputValue);

    let options = sortedOptions.filter(
      (opt) =>
        opt.label?.toLocaleLowerCase().slice(0, inputValue.length) ===
        inputValue.toLocaleLowerCase(),
    );

    if (options.length === 0) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }

    setOptions(options);
    setHasInputError(true);
  }

  function dropdownLogic() {
    if (props.showMenuClassType === false && previousShowMenu === true) {
      if (showError) {
        let initialCounter = 0;
        props.contextMenuItemsType.map((item) => {
          initialCounter++;
          if (item.isSelected === true) {
            setShowError(false);
            setHasInputError(false);
            setInputValue(props.value);
            return;
          }
        });
        if (initialCounter === props.contextMenuItemsType.length - 1) {
          setShowError(true);
          setHasInputError(true);
        }
      } else {
        if (props.value !== previousValue) {
          setShowError(false);
          setHasInputError(false);
          setInputValue(props.value);
        } else {
          if (inputValue !== inputValueCopy) {
            setShowError(true);
            setHasInputError(true);
          } else {
            setShowError(false);
            setHasInputError(false);
            setInputValue(props.value);
          }
        }
      }
      setShowMenu(false);
      setPreviousShowMenu(false);
    } else if (props.showMenuClassType === true && previousShowMenu === false) {
      sortOptions(props.contextMenuItemsType, showError);
      setShowMenu(true);
      setShowError(false);
      setInputValueCopy(inputValue);
      setPreviousValue(props.value);
      setPreviousShowMenu(true);
    }
  }

  const showDisabled = props.disabled ? 'disabled' : '';
  const getShowErrorClass =
    showError && props.required && props.isSearchable && !props.disabled ? 'showError' : '';

  return (
    <React.Fragment>
      <PopupMenu
        showMenuConfig={props.popupMenuConfigType}
        menuItems={options}
        popupMenuClass={options.length === 0 ? '' : props.popupMenuClass}
        onClick={!props.disabled ? (e) => props.onClick(e) : () => null}
        width={props?.dropdownWidth}
      >
        {dropdownLogic()}
        <div className={combineClassNames('CustomDropdown', props.className)}>
          {props.label && (
            <label className={showDisabled + ' ' + getShowErrorClass}>
              <span>
                {props.label}
                {props.required && (
                  <span className={'required' + showDisabled + getShowErrorClass}>*</span>
                )}
              </span>
            </label>
          )}
          {props.isSearchable && (
            <React.Fragment>
              <div
                className={combineClassNames(
                  'input-wrap',
                  props.containerWrapClassNames,
                  showDisabled,
                  props.showMenuClassType ? 'focused' : '',
                  getShowErrorClass,
                )}
              >
                <span className="label">
                  {props?.icon && (
                    <img
                      className={showDisabled + (hasInputError || showError ? ' hide-icon' : '')}
                      src={props.icon}
                      alt={'icon'}
                    />
                  )}
                  <input
                    className={showDisabled}
                    disabled={props.disabled}
                    type="text"
                    placeholder={'Search'}
                    name="Search Dropdown"
                    value={inputValue}
                    onChange={(e) => {
                      searchInputChange(e.target.value);
                    }}
                  />
                </span>
                <div className="align-icons">
                  {showError && !props.disabled && props.required && (
                    <span className={'input-error'}>
                      <FailedIcon />
                    </span>
                  )}
                  <i className={`fas fa-caret-down menu-btn + ${showMenu ? 'show' : ''}`} />
                </div>
              </div>
              {showError && !props.disabled && !props.showMenuClassType && props.required && (
                <span className={getShowErrorClass}>Please complete this field</span>
              )}
            </React.Fragment>
          )}
          {!props.isSearchable && (
            <div
              className={combineClassNames(
                'input-wrap',
                props.containerWrapClassNames,
                showDisabled,
                props.showMenuClassType ? 'focused' : '',
              )}
            >
              {props?.icon && (
                <Portrait
                  size={50}
                  source={props.icon}
                />
              )}
              <span className="label"> {props.value || props?.placeholder}</span>
              <div className="icon-container">
                <i
                  className={`fas fa-caret-down menu-btn ${props.showMenuClassType ? 'show' : ''}`}
                />
              </div>
            </div>
          )}
        </div>
      </PopupMenu>
    </React.Fragment>
  );
};
