import { ICause, IGroup, IGroupClassification } from '@gigit/interfaces';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { defaultCurrency, formatCurrency } from '../../../../helpers';
import {
  getGroupSubTypeLabelFromId,
  getGroupTypeLabelFromId,
} from '../../../../routes/CreateAGroup/Config';
import { IOnboardStepData } from '../interfaces';
import { ICreateGroupFormData } from '../../../../routes/CreateAGroup/CreateAGroup';
import { ICreateEventFormData } from '../../../../routes/CreateAnEvent/CreateAnEvent';
import { FormSectionHeader } from '../FormSectionHeader/FormSectionHeader';

import './SummaryPage.scss';
import { getEventTypeLabel } from '../../../../routes/CreateAnEvent/Config';
import { Constants } from '@gigit/constants';
import { ICreateHubFormData } from '../../../../routes/CreateAHub/CreateAHub';
import { ICreateGigFormData } from '../../../../routes/CreateAGig/CreateAGig';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';

const hubHelperText =
  "You're almost done! After creating your company, the next steps will walk you through branding your company and getting you started.";
interface PriceListing {
  label: string;
  price: string;
  additionalInfo?: string;
  isTotal?: boolean;
}
interface IProps {
  formData: ICreateGroupFormData | ICreateEventFormData | ICreateHubFormData | ICreateGigFormData;
  locale: string;
  pricingTitle?: string;
  priceDetails?: PriceListing[];
  planType?: string;
  totalAmount?: number;
  /** Needed for entity Specific Logic*/
  type?: string;
  goToStep(step: string): void;
}

export const SummaryPage: React.FC<IProps> = (props: IProps) => {
  const stepsWithoutSummaries = ['summary', 'customApplication', 'planSelection', 'paymentInfo'];
  const hasPricing: boolean = props.planType === Constants.billing.subscriptions.purple_plus.code;
  const isHub = props?.type === 'hub' ? true : null;
  const isVolunteerOpportunity = props?.type === 'volunteer_opportunity' ? true : null;

  function isImagePath(path: string) {
    if (path === 'coverImage' || path === 'profileImage') {
      let imageString = _.get(props.formData, path);
      return imageString;
    } else {
      return false;
    }
  }

  function isRowSection(data: Array<IOnboardStepData>) {
    const path = data.map((step: IOnboardStepData) => {
      return step.path;
    });

    const hasProfileSelection = path.includes('profileImage');
    const hasCoverSelection = path.includes('coverImage');
    const hasCauseSelection = path.includes('causeInfo.selectedCauses');

    return (hasCoverSelection && hasProfileSelection) || hasCauseSelection;
  }

  function renderImageElement(path: string) {
    if (path === 'coverImage') {
      return (
        <div
          key={path}
          className="coverImageContainer"
        >
          <img
            src={_.get(props.formData, path)}
            className="cover-image"
            alt="cover-image"
          />
        </div>
      );
    } else {
      return (
        <div
          key={path}
          className="profileImageContainer"
        >
          <div className="profile-wrapper">
            <img
              src={_.get(props.formData, path)}
              className="profile"
              alt="profile"
            />
          </div>
        </div>
      );
    }
  }

  function getValue(path: string) {
    return _.get(props.formData, path);
  }

  function getReadableCause(causeId: string, causes: ICause[]) {
    return causes?.find((cause: ICause) => cause.id === causeId)?.cause || '';
  }

  function returnPricing(parentIndex: number) {
    return (
      <div
        key={`pricing-${parentIndex}`}
        className="payment-pricing-wrapper"
      >
        {props.pricingTitle && <div className="pricing-subtitle">{props.pricingTitle}</div>}
        {props.priceDetails?.map((item, index) => {
          return (
            <div
              key={index}
              className={item.isTotal ? 'pricing-list total' : 'pricing-list'}
            >
              <div>{item.label}</div>
              {item.additionalInfo && <div>{item.additionalInfo}</div>}
              <div>{item.price}</div>
            </div>
          );
        })}
      </div>
    );
  }

  function getReadableString(path: string, index: number, rawValue?: string | any) {
    const noTranslate =
      path === 'baseInfo.name' ||
      path === 'baseInfo.description' ||
      path === 'baseInfo.revenueGoal' ||
      path === 'baseInfo.startDate' ||
      path === 'baseInfo.endDate' ||
      path === 'baseInfo.country' ||
      path === 'baseInfo.postalCode' ||
      path === 'baseInfo.event_organizer' ||
      path === 'charityId' ||
      path === 'charityUrl' ||
      path === 'charityEmail' ||
      path === 'charityPhone' ||
      path === 'selectedCharities' ||
      path === 'localization.country' ||
      path === 'localization.state' ||
      path === 'selectedGroup' ||
      path === 'selectedHub' ||
      path === 'createdFor';
    const data = getValue(path);

    const returnElement = (value: string) => {
      return noTranslate ? (
        <div
          key={`${index}-${path}`}
          className={`summary-data ${path}`}
          notranslate="yes"
        >
          {value}
        </div>
      ) : (
        <div
          key={`${index}-${value}`}
          className={`summary-data ${path}`}
        >
          {value}
        </div>
      );
    };

    const returnSelectedCharities = (value: IGroup) => {
      return (
        <div
          key={`${index}-${path}`}
          className={`summary-data ${path}`}
          notranslate="yes"
        >
          {value.title}
        </div>
      );
    };

    switch (path) {
      case 'groupType': {
        const type = getGroupTypeLabelFromId(data);
        return returnElement(type);
      }
      case 'subType': {
        const type = getGroupSubTypeLabelFromId(data);
        return returnElement(type);
      }
      case 'type': {
        const type = getEventTypeLabel(data);
        return returnElement(type);
      }
      case 'createdFor': {
        const isOnBehalfOf = data === 'obo_fundraiser';
        return isOnBehalfOf ? returnElement('On Behalf Of Cause') : returnElement(data);
      }
      case 'causeInfo.selectedCauses': {
        if ('causeInfo' in props.formData) {
          return rawValue
            ? returnElement(getReadableCause(rawValue, props.formData.causeInfo.causes))
            : undefined;
        } else {
          return null;
        }
      }
      case 'selectedCharities': {
        return returnSelectedCharities(rawValue); // rawValue ? returnElement(getReadableCause(rawValue)) : undefined;
      }
      case 'baseInfo.revenueGoal': {
        if (!isVolunteerOpportunity) {
          return returnElement(formatCurrency(data || 0, defaultCurrency, props.locale));
        }
      }
      case 'selectedGroup': {
        return returnElement(data.title);
      }
      case 'selectedHub': {
        return returnElement(data.title);
      }
      case 'baseInfo.address.address': {
        return returnElement(data?.formatted_address);
      }
      case 'baseInfo.startDate':
      case 'baseInfo.endDate': {
        const date = localizeHelpers.formatDate(moment(data), LocaleDateFormats.LL, props.locale);
        return returnElement(date);
      }
      default: {
        if (!path.includes('paymentInfo')) {
          return isImagePath(path) ? renderImageElement(path) : returnElement(data);
        }
      }
    }
  }

  function renderAdditionalHubInfo() {
    return <div className="additional-info">{hubHelperText}</div>;
  }

  return (
    <div className="SummaryPage">
      {props.formData.steps.map((step, index) => {
        if (!stepsWithoutSummaries.includes(step.id)) {
          return (
            <div
              className="summaryContainer"
              key={index}
            >
              <FormSectionHeader
                text={step.summaryTitle || ''}
                formAction={{
                  actionText: 'Edit',
                  action: () => {
                    props.goToStep(step.id);
                  },
                }}
              />
              <div className={isRowSection(step.data) ? 'summary-row' : 'summary-column'}>
                {step.data.map((stepData: IOnboardStepData) => {
                  if (
                    Array.isArray(getValue(stepData.path)) &&
                    getValue(stepData.path) &&
                    !stepData.path.includes('paymentInfo')
                  ) {
                    return getValue(stepData.path).map((value: string, idx: number) => {
                      return stepData.path === 'causeInfo.selectedCauses' ||
                        stepData.path === 'selectedCharities'
                        ? getReadableString(stepData.path, idx, value)
                        : getReadableString(value, idx);
                    });
                  } else if (stepData.path.includes('paymentInfo')) {
                    return;
                  } else {
                    return getValue(stepData.path) && getReadableString(stepData.path, index);
                  }
                })}
              </div>
            </div>
          );
        } else if (step.id.includes('paymentInfo') && hasPricing) {
          return returnPricing(index);
        }
      })}

      {hasPricing && (
        <div className="total-wrapper">
          <div className="total-label">Total Payment</div>
          <div className="total-amount">${props.totalAmount ? props.totalAmount / 100 : 0}</div>
        </div>
      )}

      {isHub && renderAdditionalHubInfo()}
    </div>
  );
};
