import { IOnboardStep } from '../../components/shared/Onboarding/interfaces';

export const basicInfo = [];

export const hubStepsMaster: Array<IOnboardStep> = [
  {
    id: 'companyInfo',
    text: "Take your first step to telling your company's social impact story",
    subText:
      'Show the world how your company is committed to making a difference.  Brand your page, invite your employees, and watch your social impact program take off!',
    summaryTitle: 'Create a Company Page',
    data: [
      { path: 'companyInfo.name', required: true },
      { path: 'companyInfo.description', required: true },
      { path: 'localization.country', required: true },
      { path: 'localization.state', required: true },
      { path: 'localization.defaultTimezone', required: false },
    ],
  },
  // {
  //     id: "causesSelection",
  //     text: "Choose focus areas that best describe your hub",
  //     tip: null,
  //     summaryTitle: "Focus Areas",
  //     data: [{ path: "causeInfo.selectedCauses", required: true }],
  // },
  // {
  //     id: "charitiesSelection",
  //     text: "Select the charities that your hub will support",
  //     tip: "You can invite charities to join you on Kambeo after creating your hub on the Hub Management page",
  //     summaryTitle: "Charities",
  //     data: [{ path: "selectedCharities", required: false }],
  // },
  // {
  //     id: "paymentInfo",
  //     text: "You can add more seats to your Hub anytime by inviting others.",
  //     tip: null,
  //     summaryTitle: "Payment Info",
  //     data: [
  //         { path: "paymentInfo.address", required: true },
  //         { path: "paymentInfo.apartment", required: false },
  //         { path: "paymentInfo.city", required: true },
  //         { path: "paymentInfo.state", required: true },
  //         { path: "paymentInfo.country", required: true },
  //         { path: "paymentInfo.zip", required: true },
  //         { path: "paymentInfo.paymentMethod", required: true },
  //     ],
  // },
  {
    id: 'summary',
    text: 'Review your Company information to make sure we got it right',
    tip: null,
    summaryTitle: 'Create a Company Page',
    data: [],
  },
];
