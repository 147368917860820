import { Reducer } from 'redux';
import { GigActions, GigActionTypes } from '../actions/gig';
import {
  IGigSummary,
  IGigCategory,
  IMediaAlbum,
  IPage,
  IPageComponent,
  IShift,
} from '@gigit/interfaces';
import { IFullForm } from './group';

export interface IGigState {
  gig: IGigSummary;
  currentPageComponents: IPageComponent[];
  gigPages: IPage[];
  shifts: IShift[];
  currentGigForm: IFullForm;
  isShiftsLoading: boolean;
  isGigLoading: boolean;
  isGigPagesLoading: boolean;
  isComponentsLoading: boolean;
  mediaAlbums: IMediaAlbum[];
  gigLocations: any[];
  gigCategories: IGigCategory[];
  volunteerError: string;
  volunteerSuccess: boolean;
}

export const initialGigState: IGigState = {
  gig: {
    title: '',
    description: '',
    handle: '',
    is_virtual: false,
  },
  currentPageComponents: [],
  gigPages: [],
  shifts: [],
  mediaAlbums: [],
  gigLocations: [],
  gigCategories: [],
  currentGigForm: {
    questions: [],
    owner_type: '',
    owner_id: '',
    owner_parent_type: '',
    owner_parent_id: '',
    form_name: '',
  },
  isShiftsLoading: false,
  isGigLoading: false,
  isGigPagesLoading: false,
  isComponentsLoading: false,
  volunteerError: '',
  volunteerSuccess: false,
};

export const gigReducer: Reducer<IGigState, GigActions> = (state = initialGigState, action) => {
  switch (action.type) {
    case GigActionTypes.UPDATE_GIG: {
      return {
        ...state,
        ...action,
      };
    }
    case GigActionTypes.UPDATE_GIG_PAGES: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
