import React, { ReactNode } from 'react';

import './PageBody.scss';

interface IProps {
  children?: ReactNode;
}

interface IState {}

/** Renders the body of a page, this is where the content goes. */
export class PageBody extends React.Component<IProps, IState> {
  render() {
    return <div className="PageBody">{this.props.children}</div>;
  }
}
