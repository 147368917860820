import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, Link } from 'react-router-dom';
import { IAppState } from '../../store';

import NotFoundImage from '../../assets/image-error404.svg';
import Button from '../../components/Button/Button';

import './NotFound.scss';

interface IProps extends WithTranslation, RouteComponentProps<any> {}
interface IState {}

class NotFound extends React.Component<IProps, IState> {
  render() {
    const { t } = this.props;

    return (
      <div className="not-found">
        <div className="content">
          <img
            src={NotFoundImage}
            alt="404 Page Not Found"
          />
          <div className="title">Page not found!</div>
          <p>The page you requested could not be found.</p>
          <p>
            If you have any questions, feel free to{' '}
            <a href="https://kambeo.io/contact-us/">contact us</a>.
          </p>
          <Link
            className="home"
            to="/"
          >
            Back to Home
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(NotFound),
);
