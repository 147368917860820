export namespace metricsHelpers {
  export interface IChartKpi {
    labels: string[];
    data: IChartKpiData[];
  }

  export interface IChartKpiData {
    x?: string;
    y?: string;
    meta?: {
      id: string;
      title: string;
    };
    value: number;
  }
}
