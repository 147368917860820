import React, { FC, useEffect, useState } from 'react';
import TextField from '../../TextField/TextField';
import './PaymentComponent.scss';
import { IPaymentInfo } from './../Onboarding/interfaces';
import Dropdown from '../../Dropdown/Dropdown';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
// import { PaymentRequest, Stripe, PaymentRequestWallet } from '@stripe/stripe-js';
// import { CardElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import { CheckoutLocale, loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { userSelectors } from '../../../selectors/user';
import { Config } from '@gigit/config';
import PaymentMethodComponent from './PaymentMethodComponent/PaymentMethodComponent';
import { localeConstants } from '../../../constants';
import { IPaymentMethod } from '@gigit/interfaces';
import useToastDispatcher from '../../../hooks/useToaster';
import { localizeHelpers } from '../../../localizeHelpers';

interface PriceListing {
  label: string;
  price: string;
  additionalInfo?: string;
  isTotal?: boolean;
  totalInCents?: number;
}

interface IProps {
  title: string;
  notCreationFlow?: boolean;
  pricingTitle?: string;
  selectDefaultMethod?: boolean;
  defaultPaymentMethodId?: string;
  showExistingMethods?: boolean;
  showBillingForm: boolean;
  priceDetails: PriceListing[];
  paymentInfo: IPaymentInfo;
  preparePaymentMethod: boolean;
  onPaymentInfoUpdated(update: IPaymentInfo): void;
  onPaymentMethodReady(id?: string): void;
  onMethodChange?: () => void;
}

export const PaymentComponent: FC<IProps> = (props: IProps) => {
  const { dispatchToastError } = useToastDispatcher();
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const userState = useSelector((state: IAppState) => state.userState);

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  const [paymentData, setPaymentData] = useState<IPaymentInfo | null>(null);

  const [methodState, setMethodState] = useState<boolean>(props.preparePaymentMethod);

  const [isNewMethod, setIsNewMethod] = useState<boolean>(false);

  const userAccount = useSelector((state: IAppState) => {
    return userSelectors.getCurrentAccount(state);
  });

  useEffect(() => {
    if (paymentData) {
      if (paymentData.isNewMethod) {
        paymentData.stripe
          ?.createPaymentMethod({
            type: 'card',
            card: paymentData.paymentMethod as StripeCardElement,
            billing_details: {
              address: {
                city: paymentData.city,
                line1: paymentData.address,
                country: paymentData.country,
                postal_code: paymentData.zip,
                state: paymentData.state,
              },
              name: paymentData.cardName,
            },
          })
          .then(async (resp: any) => {
            if (resp.error) {
              dispatchToastError(resp.error, 'Payment Method');
            } else {
              props.onPaymentMethodReady(resp.paymentMethod?.id);
            }
          })
          .catch((err) => {
            dispatchToastError(err, 'Payment Method');
          });
      } else if (!paymentData.isAltMethod && !paymentData.isNewMethod) {
        let pm = paymentData.paymentMethod as IPaymentMethod;
        if (pm) {
          props.onPaymentMethodReady(pm.payment_method_id);
        } else {
          dispatchToastError(
            localizeHelpers.translate('No payment method selected or card info is missing.'),
            'Payment Method',
          );
        }
      } else {
        props.onPaymentMethodReady('');
      }
    }
  }, [props.preparePaymentMethod]);

  function updatePaymentInfo(update: Partial<IPaymentInfo>) {
    if (props.onMethodChange) {
      props.onMethodChange();
    }

    const newData = { ...props.paymentInfo, ...update };
    setPaymentData(newData);
    props.onPaymentInfoUpdated(newData);
  }

  useEffect(() => {
    setStripePromise(
      loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY, {
        locale: locale as CheckoutLocale,
      }),
    );
  }, [userAccount]);

  function renderBillingInfo() {
    if (props.notCreationFlow && !isNewMethod) {
      return null;
    } else {
      return (
        <div className="billing-form-wrapper">
          <label>Billing Information</label>
          <div>
            <div className="row">
              <TextField
                placeholder={'Street Address'}
                value={props.paymentInfo.address}
                name="address"
                label={'Street Address'}
                type="text"
                required={true}
                onChange={(e) => {
                  updatePaymentInfo({ address: e.target.value });
                }}
              />
            </div>
            <div className="row">
              <TextField
                placeholder={'Apartment / Suite #'}
                value={props.paymentInfo.apartment}
                name="apartment"
                label={'Apartment / Suite #'}
                type="text"
                required={false}
                onChange={(e) => {
                  updatePaymentInfo({ apartment: e.target.value });
                }}
              />
            </div>
            <div className="row">
              <TextField
                placeholder={'City'}
                value={props.paymentInfo.city}
                name="city"
                label={'City'}
                type="text"
                required={true}
                onChange={(e) => {
                  updatePaymentInfo({ city: e.target.value });
                }}
              />
            </div>
            <div className="row">
              <TextField
                placeholder={'Province / State'}
                value={props.paymentInfo.state}
                name="state"
                label={'Province / State'}
                type="text"
                required={true}
                onChange={(e) => {
                  updatePaymentInfo({ state: e.target.value });
                }}
              />
            </div>
            <div className="row">
              <Dropdown
                shouldSort={true}
                label="Country"
                onChange={(e) => updatePaymentInfo({ country: e.target.value })}
                value={props.paymentInfo.country}
                name="country"
                options={localeConstants.supportedCountryOptions}
                notranslate="yes"
                required={true}
                placeholder="Select a country"
              />
            </div>
            <div className="row">
              <TextField
                placeholder={'Postal Code / ZIP'}
                value={props.paymentInfo.zip}
                name="zip"
                label={'Postal Code / ZIP'}
                type="text"
                required={true}
                onChange={(e) => {
                  updatePaymentInfo({ zip: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="PaymentComponent">
      {props.title && <div className="payment-title">{props.title}</div>}
      {props.priceDetails.length ? (
        <div className="payment-pricing-wrapper">
          {props.pricingTitle && <div className="pricing-subtitle">{props.pricingTitle}</div>}
          {props.priceDetails.map((item, index) => {
            return (
              <div
                key={index}
                className={item.isTotal ? 'pricing-list total' : 'pricing-list'}
              >
                <div>{item.label}</div>
                {item.additionalInfo && <div>{item.additionalInfo}</div>}
                <div>{item.price}</div>
              </div>
            );
          })}
        </div>
      ) : null}
      <div className="payment-method">
        {stripePromise !== null && (
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <PaymentMethodComponent
                  elements={elements}
                  onChange={(e) => {
                    updatePaymentInfo(e);
                  }}
                  priceAmount={
                    props.priceDetails ? props.priceDetails.find((e) => e.isTotal)?.totalInCents : 0
                  }
                  selectDefaultMethod={props.selectDefaultMethod}
                  defaultPaymentMethodId={props.defaultPaymentMethodId}
                  showExistingMethods={props.showExistingMethods}
                  onNewMethod={(newMethod: boolean) => setIsNewMethod(newMethod)}
                  user={userState}
                  stripe={stripe}
                />
              )}
            </ElementsConsumer>
          </Elements>
        )}
      </div>
      {renderBillingInfo()}
    </div>
  );
};
