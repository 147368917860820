import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { IUserLogin } from '@gigit/interfaces';
import { Link, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { IAppState } from '../../store';
import { handleInputChange, setSEOMetatags, toastError } from '../../helpers';
import { IUserState } from '../../reducers/user';
import { createToast } from '../../actions/toaster';
import { login, resetLogin, getUserGroups, loginWithSSO } from '../../actions/user';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import './Login.scss';
import { IToast } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import { authRequestActions } from '../../requestActions';

interface IProps extends RouteComponentProps<any, any, any> {
  userState: IUserState;
  login(state: any): void;
  resetLogin(): void;
  loginWithSSO(): any;
  getUserGroups(): void;
  createToast(toast: IToast): void;
  onClose?(): void;
  registerRedirectUrl?: string;
  registerFunction?: string;
}

interface IState extends IUserLogin {
  rememberMe: boolean;
  noRedirect: string[];
  showPwd: boolean;
  redirect: string;
  fn: string;

  //non user action state
  continueWithSSO: boolean;
  isEmailVerifiedNonSSO: boolean;
}

class Login extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let _urlParams = queryString.parse(window.location.search);

    if (_urlParams.event) {
      _urlParams.redirect += `&event=${_urlParams.event}`;
    }

    this.state = {
      emailOrHandle: '',
      password: '',
      rememberMe: false,
      noRedirect: ['group', 'user', 'gig', 'volunteer', 'event'],
      showPwd: false,
      redirect: _urlParams.redirect?.toString() || this.props.registerRedirectUrl || '',
      fn: _urlParams.fn?.toString() || this.props.registerFunction || '',

      continueWithSSO: false,
      isEmailVerifiedNonSSO: false,
    };

    this.login = this.login.bind(this);
  }

  async login(e: any) {
    e.preventDefault();
    this.props.resetLogin();
    // If user is SSO, redirect to SSO login page
    const redirectUrl = this.getRedirectLink();
    const ssoLoginRedirectURL = await authRequestActions.checkAuthType(
      this.state.emailOrHandle,
      redirectUrl,
    );

    if (ssoLoginRedirectURL) {
      window.location.replace(ssoLoginRedirectURL);
    } else if (this.state.continueWithSSO) {
      const toast = toastError("Email for SSO doesn't exist", 'Login Error');
      this.props.createToast(toast);
    }

    this.setState({
      isEmailVerifiedNonSSO: !ssoLoginRedirectURL,
    });

    if (
      this.state.isEmailVerifiedNonSSO &&
      !ssoLoginRedirectURL &&
      this.state.emailOrHandle &&
      this.state.password &&
      !this.state.continueWithSSO
    ) {
      this.props.login(this.state);
    }
  }

  componentDidMount() {
    (window as any).prerenderReady = false;
    // this.props.resetLogin();

    const queryParams = queryString.parse(this.props.location.search);

    if (queryParams.sso) {
      this.props.loginWithSSO();
    }

    // GIG-1637: Redirect immediately when already logged in. Fixes white screen bug.
    if (this.props.userState.isLoggedIn) {
      // only want to navigate back if coming from login, otherwise use modal implementation
      if (this.props.history.location.pathname.includes('login')) {
        this.props.history.push('/');
      }
    }

    setSEOMetatags({
      urlPath: `login`,
      title: localizeHelpers.translate('Sign In | Kambeo'),
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const queryParams = queryString.parse(this.props.location.search);
    let skip = false;

    if (
      this.props.userState.isLoggedIn &&
      prevProps.userState.isLoggedIn !== this.props.userState.isLoggedIn
    ) {
      setSEOMetatags({
        urlPath: `login`,
        title: localizeHelpers.translate('Sign In | Kambeo'),
      });

      this.props.getUserGroups();

      // if (this.props.onClose !== undefined) {
      //     this.props.onClose();
      // }

      if (queryParams.sso && queryParams.sso_redirect_url) {
        this.props.history.push(queryParams.sso_redirect_url.toString());

        skip = true;
      }

      // only want to navigate back if coming from login, otherwise use modal implementation
      if (this.props.history.location.pathname.includes('login') && !skip) {
        this.props.history.push('/');
      }

      if (this.props.location.search.includes('/not-found') && !skip) {
        this.props.history.push('/');
      }
    }

    if (this.props.userState.error !== '' && !this.props.userState.isLoginLoading) {
      const toast = toastError(this.props.userState.error, 'Login Error');
      this.props.createToast(toast);

      this.props.resetLogin();
    }
  }

  togglePwd() {
    this.setState({
      showPwd: !this.state.showPwd,
    });
  }

  hasUserInfo() {
    return (
      this.props.userState.user?.first_name &&
      this.props.userState.user?.last_name &&
      this.props.userState.user?.phone &&
      this.props.userState.user?.email &&
      this.props.userState.user?.emergency_contacts?.length &&
      this.props.userState.user?.emergency_contacts?.length > 0
    );
  }

  getRedirectLink() {
    if (this.state.redirect && this.state.fn) {
      return '?redirect=' + encodeURIComponent(this.state.redirect) + '?fn=' + this.state.fn;
    } else if (this.state.redirect) {
      return '?redirect=' + encodeURIComponent(this.state.redirect);
    } else {
      return '';
    }
  }

  signInWithSSO = () => {
    this.setState({
      continueWithSSO: true,
    });
  };

  render() {
    let loginTitle = 'Sign in';
    let loginMessage = 'Welcome back to Kambeo!';

    if (this.props.registerFunction && this.props.registerFunction.includes('fundraise')) {
      loginTitle = 'Login or register to start your fundraising team or page';
    }

    if (
      this.props.location.state?.loginRedirectMessage &&
      !this.props.registerFunction?.includes('fundraise')
    ) {
      loginMessage = this.props.location.state?.loginRedirectMessage;
    }

    if (
      this.props.userState.isLoggedIn &&
      !new RegExp(this.state.noRedirect.join('|')).test(this.props.location.pathname)
    ) {
      if (this.props.onClose !== undefined) {
        this.props.onClose();
      }

      let _params = queryString.parse(this.props.location.search);

      if (_params.redirect) {
        if (
          (_params.redirect.includes('volunteer') && !_params.redirect.includes('discover')) ||
          _params?.fn?.includes('handleApply')
        ) {
          if (this.hasUserInfo()) {
            return (
              <Redirect
                to={{ pathname: _params.redirect.toString(), state: this.props.location.state }}
              />
            );
          } else {
            return <Redirect to={`/setup/volunteer?isRedirect=${_params.redirect.toString()}`} />;
          }
        } else if (_params.event) {
          return <Redirect to={`${_params.redirect}&event=${_params.event}`} />;
        } else {
          // (LG) GIG-1807: Explicitly pass redirect url/query as separate params.
          // Fixes issue where react router doesn't pick up query string after redirect when just using pathname.
          const redirectObj = queryString.parseUrl(
            window.decodeURIComponent(_params.redirect.toString()),
          );
          const redirectUrl = redirectObj.url;
          const redirectQuery = queryString.stringify(redirectObj.query);

          return (
            <Redirect
              to={{
                pathname: redirectUrl,
                search: redirectQuery,
                state: this.props.location.state,
              }}
            />
          );
        }
      } else {
        return null;
      }
    } else {
      return (
        <div className="Login">
          <div className="Login-inner">
            <form onSubmit={this.login}>
              <h2>{loginTitle}</h2>
              <span className="welcome">{loginMessage}</span>

              <div className="row">
                <div className="col">
                  <TextField
                    name="emailOrHandle"
                    label="Email Address"
                    value={this.state.emailOrHandle}
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                    type="text"
                  />
                </div>
                {!this.state.continueWithSSO && (
                  <div className="col pwd-col">
                    <TextField
                      label="Password"
                      name="password"
                      value={this.state.password}
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                      type={this.state.showPwd ? 'text' : 'password'}
                    />
                    {this.state.showPwd && (
                      <i
                        className="fa fa-eye-slash pwd-icon"
                        onClick={() => this.togglePwd()}
                      />
                    )}
                    {!this.state.showPwd && (
                      <i
                        className="fa fa-eye pwd-icon"
                        onClick={() => this.togglePwd()}
                      />
                    )}
                    <div className="forgot-pwd">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                  </div>
                )}
              </div>
              <div className="actions">
                <Button
                  loading={this.props.userState.isLoginLoading}
                  text={this.state.continueWithSSO ? 'Sign In with SSO' : 'Sign In'}
                  className="sign-in"
                />
                <div className="links">
                  {!this.state.continueWithSSO && (
                    <Button
                      type="button"
                      className="continue-with-sso-button"
                      buttonType="secondary"
                      onClick={this.signInWithSSO}
                    >
                      Sign In with SSO
                    </Button>
                  )}

                  <Link
                    className="register-link"
                    to={{
                      pathname: '/register/baseUserInfo',
                      search: this.getRedirectLink(),
                      state: this.props.location.state,
                    }}
                  >
                    Don't have an account? <span>Register now</span>
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <div className="tl-circle" />
          <div className="tr-circle" />
          <div className="bl-circle" />
        </div>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

const mapDispatchToProps = {
  login,
  resetLogin,
  getUserGroups,
  createToast,
  loginWithSSO,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
