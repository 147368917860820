import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import {
  ICampaignFund,
  IDonationRequest,
  IAddressBase,
  IUser,
  IAttachment,
  IAvailableDonationMatchingProgram,
  IOfflineDonationRequest,
} from '@gigit/interfaces';
import { IGroupState } from '../../reducers/group';
import { IAppState } from '../../store';
import {
  handleInputChange,
  formatCurrency,
  defaultCurrency,
  toastError,
  fileHelpers,
  errorHelpers,
} from '../../helpers';
import TextField from '../TextField/TextField';
import Dropdown, { IOptions } from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import './AddDonation.scss';
import { createGroupDonationIntent } from '../../actions/group';
import { createEventDonationIntent } from '../../actions/event';
import { userSelectors } from '../../selectors/user';
import { uiConstants } from '../../constants/uiConstants';
import { IOwnerObject, IToast } from '../../interfaces';
import DatePicker from '../DatePicker/DatePicker';
import { Config } from '@gigit/config';
import Attachments from '../shared/forms/Attachments/Attachments';
import {
  donationRequestActions,
  hubRequestActions,
  userRequestActions,
} from '../../requestActions';
import { IDonationItem } from '../UserDashboard/MyActivity/CommunityFeed/CommunityFeedDonationModal/CommunityFeedDonationModal';
import DonationMatchingProgramDetails from '../../routes/Donate/DonateForm/DonationMatchingProgramDetails/DonationMatchingProgramDetails';
import { Constants } from '@gigit/constants';
import AsyncSelectField from '../shared/forms/select/AsyncSelectField/AsyncSelectField';
import { ISelectOption } from '../shared/forms/select/types';
import { createToast } from '../../actions/toaster';

const donationAmounts = [25, 50, 100, 250, 500];
const donationAmountDefault = donationAmounts[0];

type AddDonationUIState = {
  showTitleRow: boolean;
  showFundRow: boolean;
  donorInformationSection: {
    title: string;
    showAnonDonationField: boolean;
    disabled: boolean;
  };
  dedicateMyDonationSection: {
    showSection: boolean;
  };
  paymentInformationSection: {
    showSendTaxReceiptField: boolean;
    showSendDonationConfirmationField: boolean;
  };
  billingInformationSection: {
    showSection: boolean;
  };
  attachmentsSection: {
    isDisabled: boolean;
    showSection: boolean;
  };
  donationMatchingSection: {
    showSection: boolean;
  };
};

type InitialUIStateType = 'default' | 'offline-donation-from-hub' | 'offline-donation-from-user';

interface IProps {
  //Passed Props
  contact?: {
    user?: IUser;
    location?: IAddressBase;
  };
  locale: string;
  owner?: IOwnerObject;
  /** TODO: HACK - This should really be an owner object... this was done because of the types in CommunityFeedDonationModal
   * This is required for offline donations
   */
  donationItem?: IDonationItem;

  donationTo?: IOwnerObject | null;
  onClose(): void;

  /** Uses a specific UI State object to control which fields are rendered */
  uiStateType?: InitialUIStateType;
  /** Provide if your UI changes based updating vs creating */
  editMode?: boolean;
  //Props from redux
  groupState: IGroupState;
  createToast(toast: IToast): void;
  createGroupDonationIntent(
    groupId: string,
    _payload: IDonationRequest,
    callback?: () => void,
  ): void;
  createEventDonationIntent(
    eventId: string,
    _payload: IDonationRequest,
    groupId?: string,
    callback?: () => void,
  ): void;
}

interface IState {
  formRef: React.RefObject<HTMLFormElement>;
  campaignFunds: Array<IOptions>;
  gift_date: Moment;
  donationAmount: number;
  customDonationAmount: string;
  firstName: string;
  lastName: string;
  comments: string;
  email: string;
  phone: string;
  anonymous: boolean;
  dedicate: boolean;
  selectedDedicate: string;
  hFirst: string;
  hLast: string;
  rFirst: string;
  rLast: string;
  rEmail: string;
  rMessage: string;
  dPrefix: string;
  selectedPayment: 'cash' | 'cheque';
  address: string;
  apartment: string;
  city: string;
  selectedProvince: string;
  country: string;
  postal: string;
  sendTaxReceipt: boolean;
  dontSendDonationConfirmation: boolean;
  selectedFund: string;
  uiState: AddDonationUIState;
  filesToUpload: Array<File>;
  donationMatchingPrograms: IAvailableDonationMatchingProgram[];
  selectedDonationMatchingProgram: IAvailableDonationMatchingProgram | null;
  matchedAmount: number;
}

const defaultUIState: AddDonationUIState = {
  showTitleRow: true,
  showFundRow: true,
  donorInformationSection: {
    title: 'Your Information',
    showAnonDonationField: true,
    disabled: false,
  },
  dedicateMyDonationSection: {
    showSection: true,
  },
  paymentInformationSection: {
    showSendTaxReceiptField: true,
    showSendDonationConfirmationField: true,
  },
  billingInformationSection: {
    showSection: true,
  },
  donationMatchingSection: {
    showSection: false,
  },
  attachmentsSection: {
    isDisabled: false,
    showSection: false,
  },
};

const defaultOfflineDonationFromUserUIState: AddDonationUIState = {
  ...defaultUIState,
  showTitleRow: false,
  showFundRow: false,
  donorInformationSection: {
    title: 'Your Information',
    showAnonDonationField: false,
    disabled: true,
  },
  dedicateMyDonationSection: {
    showSection: false,
  },
  paymentInformationSection: {
    showSendTaxReceiptField: false,
    showSendDonationConfirmationField: false,
  },
  billingInformationSection: {
    showSection: false,
  },
  donationMatchingSection: {
    showSection: true,
  },
  attachmentsSection: {
    ...defaultUIState.attachmentsSection,
    showSection: true,
  },
};

const defaultOfflineDonationFromHubUIState: AddDonationUIState = {
  ...defaultUIState,
  showTitleRow: false,
  showFundRow: false,
  donorInformationSection: {
    title: 'Donor Information',
    showAnonDonationField: false,
    disabled: false,
  },
  dedicateMyDonationSection: {
    showSection: false,
  },
  paymentInformationSection: {
    showSendTaxReceiptField: false,
    showSendDonationConfirmationField: false,
  },
  billingInformationSection: {
    showSection: false,
  },
  attachmentsSection: {
    ...defaultUIState.attachmentsSection,
    showSection: true,
  },
};

class AddDonation extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let funds = this.props.groupState.currentCampaign
      ? this.getFundOptions(this.props.groupState.currentCampaign.funds)
      : [];

    this.state = {
      formRef: React.createRef(),
      campaignFunds: funds || [],
      gift_date: moment(),
      donationAmount: donationAmountDefault,
      customDonationAmount: '$0',
      firstName: this.props?.contact?.user?.first_name || '',
      lastName: this.props?.contact?.user?.last_name || '',
      email: this.props?.contact?.user?.email || '',
      comments: '',
      phone: this.props?.contact?.user?.phone || '',
      address: this.props?.contact?.location?.line1 ?? '',
      apartment: this.props?.contact?.location?.line2 ?? '',
      city: this.props?.contact?.location?.city ?? '',
      selectedProvince: this.props?.contact?.location?.state ?? '',
      country: this.props?.contact?.location?.country ?? '',
      postal: this.props?.contact?.location?.postal_code ?? '',
      anonymous: false,
      dedicate: false,
      selectedDedicate: '',
      hFirst: '',
      hLast: '',
      rFirst: '',
      rLast: '',
      rMessage: '',
      rEmail: '',
      dPrefix: '',
      selectedPayment: 'cash',
      sendTaxReceipt: false,
      dontSendDonationConfirmation: false,
      selectedFund: '',
      uiState: this.getUIState(),
      filesToUpload: [],
      donationMatchingPrograms: [],
      selectedDonationMatchingProgram: null,
      matchedAmount: uiConstants.defaultMatchAmount,
    };

    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.getAvailableDonationMatchingPrograms();
  }

  getAvailableDonationMatchingPrograms = async (
    _params?: URLSearchParams,
  ): Promise<ISelectOption[]> => {
    try {
      const donationMatchingProgramResponse =
        await hubRequestActions.getAvailableDonationMatchingPrograms(
          Constants.donation_matching_program_type.donation_match,
          {
            donation_amount: this.getDonationAmount(),
            group_id: this.props.donationItem?.group_id!,
          },
          _params,
        );
      if (donationMatchingProgramResponse.length > 0) {
        this.setState({
          donationMatchingPrograms: donationMatchingProgramResponse,
        });
      }
      return (
        donationMatchingProgramResponse.map((program) => ({
          label: program.name,
          value: program.id,
        })) || []
      );
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      const toast = toastError(
        errorObj.translatedMessage,
        'Get Available Donation Matching Programs',
      );
      this.props.createToast(toast);
    }

    return [];
  };

  getUIState = (): AddDonationUIState => {
    switch (this.props.uiStateType) {
      case 'offline-donation-from-hub':
        return defaultOfflineDonationFromHubUIState;
      case 'offline-donation-from-user':
        if (this.props.editMode) {
          return {
            ...defaultOfflineDonationFromUserUIState,
            attachmentsSection: {
              ...defaultOfflineDonationFromUserUIState.attachmentsSection,
              showSection: false,
            },
          };
        }
        return defaultOfflineDonationFromUserUIState;
      default:
        return defaultUIState;
    }
  };

  getFundOptions(funds: ICampaignFund[]) {
    const fundOptions = funds.map((fund: ICampaignFund) => {
      return { label: fund.name, value: fund.name };
    });

    fundOptions.unshift({
      label: 'Select One',
      value: '',
    });

    return fundOptions;
  }

  async makeDonation() {
    let _payload: IDonationRequest = {
      payment_method: this.state.selectedPayment,
      donor: {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        //"middle_name": this.state.middleName,
        email: this.state.email,
        phone: this.state.phone,
      },
      address: {
        line1: this.state.address,
        line2: this.state.apartment,
        city: this.state.city,
        state: this.state.selectedProvince,
        country: this.state.country,
        postal_code: this.state.postal,
      },
      fund: this.state.selectedFund || undefined,
      anonymous: this.state.anonymous,
      dont_send_auto_tax_receipt: !this.state.sendTaxReceipt,
      dont_send_auto_donation_confirmation: !this.state.dontSendDonationConfirmation,
      amount:
        this.state.donationAmount !== -1
          ? this.state.donationAmount
          : parseFloat(this.state.customDonationAmount.toString()),
      tip: {
        amount: 0,
        amount_type: 'fixed',
      },
      donation_gift_date: this.state.gift_date.toDate(),
    };

    if (this.state.dedicate) {
      _payload.dedicate = {
        type: this.state.dPrefix === 'memory' ? 'in_memory_of' : 'on_behalf_of',
        first_name: this.state.hFirst,
        last_name: this.state.hLast,
        recepient: {
          email: this.state.rEmail,
          first_name: this.state.rFirst,
          last_name: this.state.rLast,
          message: this.state.rMessage,
        },
      };
    }
    try {
      // TODO: Below code is done in hacky way for release/3.3.0
      switch (this.props.uiStateType) {
        case 'default':
          switch (this.props.owner?.ownerType) {
            case uiConstants.ownerType.group:
              this.props.createGroupDonationIntent(
                this.props.groupState.group.id,
                _payload,
                this.close,
              );
              break;
            case uiConstants.ownerType.event:
              this.props.createEventDonationIntent(
                this.props.owner?.ownerId!,
                _payload,
                this.props.groupState.group.id,
                this.close,
              );
              break;
          }
        case 'offline-donation-from-user':
          if (this.props.donationItem != null) {
            let requestParams: IOfflineDonationRequest = {
              amount: _payload.amount,
              donation_object_id: this.props.donationItem.id,
              donation_object_type: this.props.donationItem.ownerType,
              gift_date: _payload.donation_gift_date!,
              payment_method: _payload.payment_method,
            };

            if (this.state.filesToUpload.length > 0) {
              requestParams.attachments = this.state.filesToUpload.map((file) => ({
                name: file.name,
                type: file.type.split('/')[1],
              }));
            }

            if (this.state.selectedDonationMatchingProgram != null) {
              requestParams.donation_match = {
                donation_matching_program_id: this.state.selectedDonationMatchingProgram.id,
                object_id: this.state.selectedDonationMatchingProgram.owner_id,
                params: {
                  amount_matched: this.state.matchedAmount,
                },
              };
            }

            try {
              const response =
                await donationRequestActions.createOfflineUserDonation(requestParams);

              if (response.attachments.length > 0) {
                await fileHelpers.uploadFileAttachments(
                  response.attachments,
                  this.state.filesToUpload,
                );
              }
            } catch (error) {
              const errorObj = errorHelpers.getErrorObject(error);
              const toast = toastError(errorObj.translatedMessage, 'Offline Donation Request');
              this.props.createToast(toast);
            } finally {
              this.close();
            }
          }
          break;
        case 'offline-donation-from-hub':
          if (this.props.donationTo != null) {
            let requestParams: IOfflineDonationRequest = {
              amount: _payload.amount,
              donation_object_id: this.props.donationTo.ownerId,
              donation_object_type: this.props.donationTo.ownerType,
              gift_date: _payload.donation_gift_date!,
              payment_method: _payload.payment_method,
            };

            if (this.state.filesToUpload.length > 0) {
              requestParams.attachments = this.state.filesToUpload.map((file) => ({
                name: file.name,
                type: file.type.split('/')[1],
              }));
            }

            try {
              const response = await donationRequestActions.createHubOfflineCorporateDonation(
                this.props.owner?.ownerId!,
                requestParams,
              );

              if (response.attachments.length > 0) {
                await fileHelpers.uploadFileAttachments(
                  response.attachments,
                  this.state.filesToUpload,
                );
              }
            } catch (error) {
              console.error('Donation Request: ', error);
            } finally {
              this.close();
            }
          }
          break;
      }
    } catch (error) {
      console.error('Donation Request: ', error);
    }
  }

  close() {
    this.props.onClose();
  }

  isDedicate() {
    if (this.state.selectedDedicate === 'dedicate') {
      this.setState({
        dedicate: false,
        selectedDedicate: '',
      });
    } else {
      this.setState({
        dedicate: true,
        selectedDedicate: 'dedicate',
      });
    }
  }

  isInMemoryOf() {
    if (this.state.selectedDedicate === 'memory') {
      this.setState({
        dedicate: false,
        selectedDedicate: '',
      });
    } else {
      this.setState({
        dedicate: true,
        selectedDedicate: 'memory',
      });
    }
  }

  getDonationAmount = () => {
    return this.state.donationAmount !== -1
      ? this.state.donationAmount
      : parseFloat(this.state.customDonationAmount.toString());
  };

  getDonationMatchingProgramOptions = () => {
    return this.state.donationMatchingPrograms.map((program) => ({
      label: program.name,
      value: program.id,
    }));
  };

  render() {
    let currency = this.props.owner?.account?.currency || defaultCurrency;

    let total =
      this.state.donationAmount !== -1
        ? this.state.donationAmount
        : parseFloat(this.state.customDonationAmount.toString())
          ? parseFloat(this.state.customDonationAmount.toString())
          : 0;
    const { uiState } = this.state;

    return (
      <div className="AddDonation">
        {uiState.showTitleRow && <div className="title-row">Add Donation</div>}
        <div className="subheader">Donation Information</div>
        <form
          ref={this.state.formRef}
          onSubmit={(e) => {
            e.preventDefault();
            this.makeDonation();
          }}
        >
          <div className="details-row">
            <DatePicker
              name={'gift_date'}
              label="Gift date"
              onChange={(date) => {
                this.setState({ gift_date: date });
              }}
              date={this.state.gift_date}
            />
          </div>
          {uiState.showFundRow && this.state.campaignFunds.length > 1 && (
            <div className="details-row">
              <Dropdown
                label={'Which fund would you like to donate to?'}
                value={this.state.selectedFund}
                shouldSort={true}
                onChange={(e) => handleInputChange(e, this)}
                name="selectedFund"
                options={this.state.campaignFunds}
              />
            </div>
          )}
          <div className="subheader">Donation Amount</div>
          <div className="donation-amount-wrap">
            {donationAmounts.map((amt: number, idx: number) => {
              return (
                <div
                  notranslate="yes"
                  key={idx}
                  onClick={() => {
                    this.setState(
                      {
                        donationAmount: amt,
                        customDonationAmount: '',
                      },
                      () => {
                        this.getAvailableDonationMatchingPrograms();
                      },
                    );
                  }}
                  className={
                    this.state.donationAmount === amt ? 'donation-amount active' : 'donation-amount'
                  }
                >
                  {`${formatCurrency(amt, currency, this.props.locale)}`}
                </div>
              );
            })}
            <div
              onClick={() => {
                this.setState({ donationAmount: -1 });
              }}
              className={
                this.state.donationAmount === -1
                  ? 'donation-amount other active'
                  : 'donation-amount other'
              }
            >
              <TextField
                placeholder={'Other Amount'}
                name="customDonationAmount"
                type="number"
                value={this.state.customDonationAmount || ''}
                onChange={(e) => handleInputChange(e, this)}
              />
            </div>
          </div>
          <div className="subheader">{uiState.donorInformationSection.title}</div>
          <div className="form-1-1">
            <TextField
              required={true}
              label={'First Name'}
              value={this.state.firstName}
              type="text"
              name="firstName"
              onChange={(e) => handleInputChange(e, this)}
              disabled={uiState.donorInformationSection.disabled}
            />
            <TextField
              required={true}
              label={'Last Name'}
              value={this.state.lastName}
              type="text"
              name="lastName"
              disabled={uiState.donorInformationSection.disabled}
              onChange={(e) => handleInputChange(e, this)}
            />
          </div>
          <div className="form-1-1">
            <TextField
              label={'Email'}
              disabled={!!this.props.contact}
              required={!this.props.contact}
              value={this.state.email}
              type="text"
              name="email"
              onChange={(e) => handleInputChange(e, this)}
            />
            <TextField
              label={'Phone'}
              value={this.state.phone}
              type="text"
              name="phone"
              disabled={uiState.donorInformationSection.disabled}
              onChange={(e) => handleInputChange(e, this)}
            />
          </div>
          <div className="details-row">
            <TextField
              value={this.state.comments}
              label={'Additional Comments'}
              type="text"
              name="comments"
              onChange={(e) => handleInputChange(e, this)}
            />
          </div>
          {uiState.donorInformationSection.showAnonDonationField && (
            <div className="details-row">
              <div className="details-col">
                <Checkbox
                  name="anonymous"
                  onChange={(e) => handleInputChange(e, this)}
                  checked={this.state.anonymous}
                  value={'anonymous'}
                  color="work"
                  label={'Make my donation anonymous.'}
                />
              </div>
            </div>
          )}
          {uiState.dedicateMyDonationSection.showSection && (
            <Fragment>
              <div className="subheader">
                Dedicate My Donation <span>(Optional)</span>
              </div>
              <div className="donation-dedicate-wrap">
                <div
                  onClick={() => this.isDedicate()}
                  className={
                    this.state.selectedDedicate === 'dedicate'
                      ? 'donation-amount active'
                      : 'donation-amount'
                  }
                >
                  In Honour Of...
                </div>
                <div
                  onClick={() => this.isInMemoryOf()}
                  className={
                    this.state.selectedDedicate === 'memory'
                      ? 'donation-amount active'
                      : 'donation-amount'
                  }
                >
                  In Memory Of...
                </div>
              </div>
              {this.state.dedicate && (
                <div className="dedicated">
                  <div className="details-row">
                    <div className="details-col">
                      <TextField
                        type="text"
                        name="hFirst"
                        required={this.state.dedicate}
                        onChange={(e) => handleInputChange(e, this)}
                        value={this.state.hFirst}
                        label={"Honoree's First Name"}
                      />
                    </div>
                    <div className="details-col">
                      <TextField
                        type="text"
                        name="hLast"
                        required={this.state.dedicate}
                        onChange={(e) => handleInputChange(e, this)}
                        value={this.state.hLast}
                        label={"Honoree's Last Name"}
                      />
                    </div>
                  </div>
                  <div className="details-row">
                    <div className="details-col">
                      <TextField
                        type="text"
                        name="rFirst"
                        required={this.state.dedicate}
                        onChange={(e) => handleInputChange(e, this)}
                        value={this.state.rFirst}
                        label={"Recipient's First Name"}
                      />
                    </div>
                    <div className="details-col">
                      <TextField
                        type="text"
                        name="rLast"
                        required={this.state.dedicate}
                        onChange={(e) => handleInputChange(e, this)}
                        value={this.state.rLast}
                        label={"Recipient's Last Name"}
                      />
                    </div>
                  </div>
                  <div className="details-row">
                    <TextField
                      type="email"
                      name="rEmail"
                      required={this.state.dedicate}
                      onChange={(e) => handleInputChange(e, this)}
                      value={this.state.rEmail}
                      label={"Recipient's Email"}
                    />
                  </div>
                  <div className="details-row">
                    <TextField
                      type="text"
                      name="rMessage"
                      onChange={(e) => handleInputChange(e, this)}
                      value={this.state.rMessage}
                      label={'Message to Recipient'}
                    />
                  </div>
                </div>
              )}
            </Fragment>
          )}
          <div className="subheader">Payment Information</div>
          <div className="details-row">
            <Dropdown
              name="selectedPayment"
              shouldSort={true}
              label={'Type of Payment'}
              value={this.state.selectedPayment}
              options={uiConstants.donationPaymentTypes}
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
          </div>
          {uiState.paymentInformationSection.showSendTaxReceiptField && (
            <div className="details-row">
              <Checkbox
                name="sendTaxReceipt"
                onChange={(e) => handleInputChange(e, this)}
                checked={this.state.sendTaxReceipt}
                value="sendTaxReceipt"
                color="work"
                label={'Do you want to send a tax receipt for this donation?'}
              />
            </div>
          )}
          {uiState.paymentInformationSection.showSendDonationConfirmationField && (
            <div className="details-row">
              <Checkbox
                name="dontSendDonationConfirmation"
                onChange={(e) => handleInputChange(e, this)}
                checked={this.state.dontSendDonationConfirmation}
                value="dontSendDonationConfirmation"
                color="work"
                label={'Send donation confirmation to donor?'}
              />
            </div>
          )}
          {Config.feature_flags.HUB_DONATION_MATCHING &&
            this.state.uiState.donationMatchingSection.showSection &&
            this.state.donationMatchingPrograms.length > 0 && (
              <div>
                <div className="subheader">Donation Matching</div>
                <AsyncSelectField
                  onSearchRequest={this.getAvailableDonationMatchingPrograms}
                  isClearable={true}
                  label="Donation Matching Program"
                  name="selectedDonationMatchingProgram"
                  onChange={(selectedOption) =>
                    this.setState({
                      selectedDonationMatchingProgram:
                        this.state.donationMatchingPrograms.find(
                          (program) => program.id === selectedOption?.value,
                        ) || null,
                    })
                  }
                />
                {this.state.selectedDonationMatchingProgram != null && (
                  <DonationMatchingProgramDetails
                    currency={currency}
                    program={this.state.selectedDonationMatchingProgram}
                    donationAmount={this.getDonationAmount() || 0}
                    matchedAmount={this.state.matchedAmount}
                    setMatchedAmount={(v) => this.setState({ matchedAmount: v })}
                  />
                )}
              </div>
            )}
          {uiState.attachmentsSection.showSection && (
            <div className="details-row">
              <Attachments
                label="Attachments"
                updateFiles={(selectedFiles) => this.setState({ filesToUpload: selectedFiles })}
              />
            </div>
          )}

          {uiState.billingInformationSection.showSection && (
            <Fragment>
              <div className="subheader">Billing Information</div>
              <div className="form-1-1">
                <TextField
                  required={true}
                  label={'Street Address'}
                  value={this.state.address}
                  type="text"
                  name="address"
                  onChange={(e) => handleInputChange(e, this)}
                />
                <TextField
                  label={'Apartment / Suite'}
                  value={this.state.apartment}
                  type="text"
                  name="apartment"
                  onChange={(e) => handleInputChange(e, this)}
                />
              </div>
              <div className="form-1-1">
                <TextField
                  required={true}
                  label={'City'}
                  value={this.state.city}
                  type="text"
                  name="city"
                  onChange={(e) => handleInputChange(e, this)}
                />
                <TextField
                  required={true}
                  label={'State/Province'}
                  value={this.state.selectedProvince}
                  type="text"
                  name="selectedProvince"
                  onChange={(e) => handleInputChange(e, this)}
                />
              </div>
              <div className="form-1-1">
                <TextField
                  required={true}
                  label={'Country'}
                  value={this.state.country}
                  type="text"
                  name="country"
                  onChange={(e) => handleInputChange(e, this)}
                />
                <TextField
                  required={true}
                  label={'Postal'}
                  value={this.state.postal}
                  type="text"
                  name="postal"
                  onChange={(e) => handleInputChange(e, this)}
                />
              </div>
            </Fragment>
          )}
          <div className="summary">
            <div className="summary-info">
              <div className="title">Summary</div>
              <div className="donation">
                <div>{'Donation'}</div>
                <var data-var="total_donation">
                  {formatCurrency(total, currency, this.props.locale)}
                </var>
              </div>
              <div className="donation">
                <div>{'Tip'}</div>
                <var data-var="tip_donation">{formatCurrency(0, currency, this.props.locale)}</var>
              </div>
              <div className="donation">
                <div>{'Transaction Fee'}</div>
                <var data-var="fee_donation">{'N/A'}</var>
              </div>
            </div>
            <div className="total">
              <div>{'Total'}</div>
              <var data-var="total">{formatCurrency(total, currency, this.props.locale)}</var>
            </div>
            <Button
              text={'Donate Now'}
              type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createGroupDonationIntent,
  createEventDonationIntent,
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDonation);
