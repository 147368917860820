import { Countries, Provinces } from 'country-and-province';
import momentTZ from 'moment-timezone';

import { IOptions } from '../components/Dropdown/Dropdown';

/** Constants for locations and languages. */
export namespace localeConstants {
  /** Default language */
  export const defaultLanguage = 'en';

  export const languageOptions: IOptions[] = [
    { label: 'English', value: 'en' },
    { label: 'Français', value: 'fr' },
    { label: 'Español', value: 'es' },
  ];

  // TODO: (LG) GIG-2045: For now we only allow CAD as reporting currency (since we'd have to figure out how to handle updating existing transactions). Eventually this should be implemented proper...
  export const supportedReportingCurrencyOptions: IOptions[] = [
    { value: 'cad', label: 'CAD' },
    { value: 'usd', label: 'USD' },
    { value: 'gbp', label: 'GBP' },
    { value: 'eur', label: 'EUR' },
  ];

  /** These are currencies we support payments in. */
  export const supportedCurrencyOptions: IOptions[] = [
    { value: 'cad', label: 'CAD' },
    { value: 'usd', label: 'USD' },
    { value: 'gbp', label: 'GBP' },
    { value: 'eur', label: 'EUR' },
  ];

  // GIG-840: For now we only support Canada and US, eventually we will support more countries.
  export const supportedCountries = ['CA', 'US', 'GB', 'DE'];

  /** Countries options. Used in dropdowns. */
  export const supportedCountryOptions: IOptions[] = [
    { label: '', value: '' },
    ...Countries.data
      // We limit countries to only those we generally support tax receipts.
      .filter((country) =>
        supportedCountries.find((supportedCountry) => supportedCountry === country.code),
      )
      .map((country) => ({
        label: country.name,
        value: country.code,
      })),
  ];

  /** Province options. Used in dropdowns. */
  export const provinceOptions = [
    // Include empty option.
    { label: '', value: '', countryCode: '' },
    // Include empty options for each Country.
    ...Countries.data.map((country) => ({
      label: '',
      value: '',
      countryCode: country.code,
    })),
    // Include every province from every country.
    ...Provinces.data.map((province) => ({
      label: province.name,
      value: province.code,
      countryCode: province.countryCode,
    })),
  ];

  const supportedTimezoneRegions = ['Canada/', 'US/', 'America/', 'Europe/Berlin', 'Europe/London'];
  const getTimezoneRegionIndex = (timezoneName: string) =>
    supportedTimezoneRegions.findIndex((region) => timezoneName.startsWith(region));

  /** Timezone options. Used in dropdowns */
  export const timezoneOptions: IOptions[] = [
    { label: '', value: '' },
    ...momentTZ.tz
      .names()
      .filter((tzName) => getTimezoneRegionIndex(tzName) !== -1)
      // Sort timezones so Canadian ones show first.
      .sort((left, right) => getTimezoneRegionIndex(left) - getTimezoneRegionIndex(right))
      .map((tzName) => ({
        label: tzName,
        value: tzName,
      })),
  ];

  export const allCountryOptions: IOptions[] = Countries.data
    .map(({ alt, ...item }) => item)
    .map(({ name: label, code: value }) => ({
      label,
      value,
    }));

  export const canadianProvinceOptions: IOptions[] = Provinces.byCountryCode('CA').map((item) => ({
    value: item.name,
    label: item.name,
    code: item.code,
  }));

  export const usStateOptions: IOptions[] = Provinces.byCountryCode('US').map((item) => ({
    value: item.name,
    label: item.name,
    code: item.code,
  }));

  export const ukCountyOptions: IOptions[] = Provinces.byCountryCode('GB').map((item) => ({
    value: item.name,
    label: item.name,
  }));

  export const deStateOptions: IOptions[] = Provinces.byCountryCode('DE').map((item) => ({
    value: item.name,
    label: item.name,
    code: item.code,
  }));

  export const paymentMethodLabels: { [key: string]: string } = {
    card: 'Credit Card',
    cash: 'Cash',
    cheque: 'Cheque',
    'e-transfer': 'E-Transfer',
    invoice: 'Invoice',
    account_debit_and_transfer: 'Account Debit and Transfer',
  };
}
