import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import './ColorPickerInput.scss';

interface IColorPickerProps {
  label: string;
  /** A color provided in hex */
  color: string;
  onChange: (newColorHex: string) => void;
  disableAlpha?: boolean;
}

function ColorPickerInput(props: IColorPickerProps) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const { color, onChange, disableAlpha, label } = props;
  const getIsColorPickerOpenClass = showColorPicker ? 'show' : '';

  function handleToggleShowColorPicker(value: boolean) {
    setShowColorPicker(value);
  }

  return (
    <div className={`ColorPickerInput ${getIsColorPickerOpenClass}`}>
      <label>{label}</label>
      <div
        className="input"
        onClick={() => handleToggleShowColorPicker(!showColorPicker)}
      >
        <div
          className="inner"
          style={{ backgroundColor: color }}
        />
        <div className="icon-container">
          {!showColorPicker && <i className="fas fa-fill-drip" />}
          {showColorPicker && <i className="fas fa-times" />}
        </div>
      </div>
      <ChromePicker
        disableAlpha={disableAlpha ? disableAlpha : true}
        className={`chrome-picker-container ${getIsColorPickerOpenClass}`}
        color={color}
        onChange={(selectedColor) => onChange(selectedColor.hex)}
      />
      <div
        className={`overlay ${getIsColorPickerOpenClass}`}
        onClick={() => handleToggleShowColorPicker(false)}
      />
    </div>
  );
}

export default ColorPickerInput;
