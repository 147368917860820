import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { IAppState } from '../../store';
import queryString from 'query-string';

import { IUserState } from '../../reducers/user';

import { handleInputChange, setSEOMetatags, toastSuccess } from '../../helpers';

import { createToast } from '../../actions/toaster';
import { updatePassword, login } from '../../actions/user';

import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';

import './ResetPassword.scss';
import { IToast } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';
import { IUserLogin } from '@gigit/interfaces';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  userState: IUserState;
  createToast(toast: IToast): void;
  updatePassword(_password: string, _token: string, cb: () => void): void;
  login(loginInfo: IUserLogin): void;
}

interface IState {
  password: string;
  confirm: string;
  token: string;
  redirect: boolean;
  mismatch: boolean;
  noPassProvided: boolean;
  invalid: boolean;
}

class ResetPassword extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      password: '',
      confirm: '',
      token: '',
      redirect: false,
      mismatch: false,
      noPassProvided: false,
      invalid: false,
    };

    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    setSEOMetatags({
      title: localizeHelpers.translate('Reset password | Kambeo'),
      urlPath: 'reset-password',
    });

    let _params = queryString.parse(this.props.location.search);

    if (_params.token !== null || _params.token !== undefined) {
      this.setState({
        token: _params.token?.toString() || '',
      });
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.userState.isLoggedIn !== prevProps.userState.isLoggedIn) {
      const toast = toastSuccess(
        localizeHelpers.translate('Your password has been changed successfully'),
        'Update Password',
      );
      this.props.createToast(toast);

      if (this.props.userState.isLoggedIn) {
        this.props.history.push('/');
      }
    }

    if (
      (prevState.confirm !== this.state.confirm || prevState.password !== this.state.password) &&
      this.state.noPassProvided
    ) {
      this.setState({
        noPassProvided: false,
      });
    }

    if (
      this.state.confirm !== '' &&
      this.state.password !== this.state.confirm &&
      !this.state.mismatch
    ) {
      this.setState({
        mismatch: true,
      });
    }

    if (this.state.password === this.state.confirm && this.state.mismatch) {
      this.setState({
        mismatch: false,
      });
    }
  }

  login = () => {
    if (this.props.userState.user.email !== '' && this.state.password) {
      this.props.login({
        emailOrHandle: this.props.userState.user.email,
        password: this.state.password,
      });
    }
  };

  resetPassword(e: any) {
    e.preventDefault();

    if (this.state.password.trim() === '' || this.state.confirm.trim() === '') {
      this.setState({
        noPassProvided: true,
      });
      return;
    }

    if (this.state.mismatch) {
      return;
    }

    if (
      this.state.password === this.state.confirm &&
      this.state.token !== '' &&
      this.isPassValid(this.state.password)
    ) {
      this.props.updatePassword(this.state.password, this.state.token, this.login);
    }

    if (!this.isPassValid(this.state.password)) {
      this.setState({
        invalid: true,
        redirect: false,
      });
      return;
    }
  }

  doesPasswordContainLower(pass: string) {
    const check = /[a-z]/.test(this.state.password);
    return check;
  }

  doesPasswordContainUpper(pass: string) {
    const check = /[A-Z]/.test(this.state.password);
    return check;
  }

  doesPasswordContainNumber(pass: string) {
    const check = /[0-9]/.test(this.state.password);
    return check;
  }

  doesPasswordContainSymbol(pass: string) {
    const check = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.state.password);
    return check;
  }

  isPassValid(pass: string) {
    return (
      this.doesPasswordContainLower(this.state.password) &&
      this.doesPasswordContainUpper(this.state.password) &&
      this.doesPasswordContainNumber(this.state.password) &&
      this.doesPasswordContainSymbol(this.state.password)
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className="ResetPassword">
        <div className="ResetPassword-inner">
          <div className="title">Change Password</div>
          <form onSubmit={this.resetPassword}>
            <div className="row">
              <span>Password Criteria: </span>
              <ul>
                <li
                  className={
                    this.doesPasswordContainUpper(this.state.password) ? 'valid' : 'invalid'
                  }
                >
                  An uppercase character
                </li>
                <li
                  className={
                    this.doesPasswordContainLower(this.state.password) ? 'valid' : 'invalid'
                  }
                >
                  A lowercase character
                </li>
                <li
                  className={
                    this.doesPasswordContainNumber(this.state.password) ? 'valid' : 'invalid'
                  }
                >
                  A number
                </li>
                <li
                  className={
                    this.doesPasswordContainSymbol(this.state.password) ? 'valid' : 'invalid'
                  }
                >
                  A special character
                </li>
              </ul>
              <div className="col">
                <TextField
                  className={
                    this.state.mismatch || this.state.noPassProvided || this.state.invalid
                      ? 'invalid-password'
                      : ''
                  }
                  label={t('New Password')}
                  name="password"
                  value={this.state.password}
                  onChange={(e: any) => {
                    handleInputChange(e, this);
                  }}
                  type="password"
                />
              </div>
              <div className="col">
                <TextField
                  className={
                    this.state.mismatch || this.state.noPassProvided || this.state.invalid
                      ? 'invalid-password'
                      : ''
                  }
                  label={t('Confirm New Password')}
                  name="confirm"
                  value={this.state.confirm}
                  onChange={(e: any) => {
                    handleInputChange(e, this);
                  }}
                  type="password"
                />
              </div>
              <div className="col">
                <div className="error-message">
                  {this.state.invalid &&
                    !this.state.mismatch &&
                    localizeHelpers.translate(
                      'Please make sure your password follows the criteria.',
                    )}
                  {this.state.mismatch &&
                    !this.state.invalid &&
                    localizeHelpers.translate('Please make sure your passwords match.')}
                  {this.state.noPassProvided &&
                    localizeHelpers.translate('Please enter password and confirmation.')}
                </div>
              </div>
            </div>
            <div className="actions">
              <Button
                isDisabled={this.state.token === ''}
                text={t('Update Password')}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

const mapDispatchToProps = {
  createToast,
  updatePassword,
  login,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword),
);
