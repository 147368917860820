import { Dispatch } from 'redux';
import { IAppState } from '../store';

export enum ModalActionTypes {
  UPDATE_MODAL = 'UPDATE_MODAL',
}

export interface IUpdateModalActions {
  depth: number;
  type: ModalActionTypes.UPDATE_MODAL;
}

export type ModalActions = IUpdateModalActions;

export const closeModal = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      depth: getState().modalState.depth - 1,
      type: ModalActionTypes.UPDATE_MODAL,
    });
  };
};

export const openModal = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      depth: getState().modalState.depth + 1,
      type: ModalActionTypes.UPDATE_MODAL,
    });
  };
};
