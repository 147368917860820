export interface flowInfoConfig {
  flowType: string;
  step: string;
  class: string;
  headerTxt: string;
  features: Array<string>;
  subpoint?: string;
  subpointIdx?: number;
}

export const flowInfoConfig: Array<flowInfoConfig> = [
  {
    flowType: 'any',
    step: 'groupType',
    class: 'group-type',
    headerTxt: 'CAUSE FEATURES',
    features: [
      'Accept donations',
      'Manage Donors, Volunteers, Fundraisers, and Members.',
      'Post Volunteer Opportunities To The Community.',
    ],
  },
  {
    flowType: 'donations',
    step: 'groupSelection',
    class: 'group-selection',
    headerTxt: 'FEATURES',
    features: [
      'Branded donation page you can link to from anywhere.',
      'Automated Tax Receipts.',
      'You get 100% of all donations.',
    ],
  },
  {
    flowType: 'createStore',
    step: 'groupSelection',
    class: 'group-selection create-store',
    headerTxt: 'FEATURES',
    features: [
      'Easy e-commerce store in just minutes.',
      'Sell products and merchandise.',
      '100% free to setup.',
    ],
  },
  {
    flowType: 'createGig',
    step: 'groupSelection',
    class: 'group-selection gig-type',
    headerTxt: 'FEATURES',
    subpoint:
      ' *Transaction fees apply to paid volunteer opportunities when the volunteer opportunity is complete',
    subpointIdx: 2,
    features: [
      'Easily post volunteer or paid opportunities',
      'Manage applications and communicate with volunteers and workers',
      '100% free to user',
    ],
  },
  {
    flowType: 'createGig',
    step: 'gigType',
    class: 'gig-type',
    headerTxt: 'FEATURES',
    features: [
      'Find volunteers or paid workers in your community',
      'Screen and manage your applications to find the right person',
      'Schedule, track hours and make payments all in one place',
    ],
  },
  {
    flowType: 'createGig',
    step: 'gigInfo',
    class: 'gig-info',
    headerTxt: 'TIPS',
    features: [
      'Be creative with your volunteer opportunity names to draw attention to your post',
      'Ensure to include a good description to help attract applicants',
      'Define a proper age range for your opportunity',
    ],
  },
  {
    flowType: 'createGig',
    step: 'gigCategories',
    class: 'gig-categories',
    headerTxt: 'TIPS',
    features: [
      'Pick the categories that most appropriately describe your opportunity',
      'Focus Areas help users search for and find your volunteer opportunity',
      'Attract students to your opportunity if you know it is OSSD eligible',
    ],
  },
  {
    flowType: 'any',
    step: 'groupInfo',
    class: 'group-info',
    headerTxt: 'DETAILS',
    features: [
      'Help people find and understand your focus area.',
      'Based on the UN classifications of not-for-profits.',
      'Tell the community what focus areas your group supports.',
    ],
  },
  {
    flowType: 'any',
    step: 'groupClassifications',
    class: 'group-classifications',
    headerTxt: 'CLASSIFICATION & CAUSES',
    features: [
      'Help people find and understand your focus area.',
      'Based on the UN classifications of not-for-profits.',
      'Tell the community what focus areas your group supports.',
    ],
  },
  {
    flowType: 'donations',
    step: 'isCharity',
    class: 'is-charity',
    headerTxt: 'RECEIPTS',
    features: [
      'Custom branded receipts.',
      'Auto-send receipt immediately or manually.',
      'Re-generate and track replaced receipts.',
    ],
  },
  {
    flowType: 'donations',
    step: 'tax',
    class: 'tax',
    headerTxt: 'RECEIPTS',
    features: [
      'Lorum Ipsum Dolor Sit Amet, Consecteur.',
      'Lorum Ipsum Dolor Sit Amet, Consecteur.',
      'Lorum Ipsum Dolor Sit Amet, Consecteur.',
    ],
  },
  {
    flowType: 'any',
    step: 'eventType',
    class: 'event-type',
    headerTxt: 'EVENT FEATURES',
    features: [
      'Templates make creating an event simple.',
      'You can add / remove additional features to your event at any time.',
      'Create a custom event  to start with an empty event you can customize.',
    ],
  },
  {
    flowType: 'any',
    step: 'eventSelection',
    class: 'group-selection',
    headerTxt: 'EVENT FEATURES',
    features: [
      'Sell tickets and merchandise, accept donations, manage volunteers',
      'Peer-to-peer fundraising, online auctions, live streaming',
      'The most powerful event platform, 100% free to use',
    ],
  },
  {
    flowType: 'createStore',
    step: 'isEvent',
    class: 'group-selection create-store-2',
    headerTxt: 'FEATURES',
    features: [
      'Increase the revenue for an event you are running.',
      'Sell tickets and merchandise for your event.',
      '100% free to use.',
    ],
  },
  {
    flowType: 'createAuction',
    step: 'groupSelection',
    class: 'group-selection create-auction',
    headerTxt: 'FEATURES',
    subpoint:
      '*Transaction fees apply when the auction is over and can be paid by winner or cause.',
    subpointIdx: 2,
    features: [
      'Easy to setup and add items',
      'Buy Now and Auto-Bidding options',
      '100% free to run your auction',
    ],
  },
  {
    flowType: 'createEvent',
    step: 'groupSelection',
    class: 'group-selection create-event-1',
    headerTxt: 'FEATURES',
    features: [
      'Sell tickets and merchandise, accept donations, manage volunteers',
      'Peer-to-peer fundraising, online auctions, live streaming',
      'The most powerful event platform, 100% free to use',
    ],
  },
  {
    flowType: 'any',
    step: 'eventInfo',
    class: 'event-info',
    headerTxt: 'EVENT DETAILS',
    features: [],
  },
  {
    flowType: 'any',
    step: 'eventTimeAndLocation',
    class: 'event-time',
    headerTxt: 'WHEN AND WHERE',
    features: [],
  },
  {
    flowType: 'any',
    step: 'gigTimeAndLocation',
    class: 'event-time',
    headerTxt: 'WHEN AND WHERE',
    features: [
      'Easily post volunteer opportunities for FREE',
      'Manage applications, shifts and schedules',
      'Verify hours and manage communications',
    ],
  },
  {
    flowType: 'createEvent',
    step: 'connect',
    class: 'connect',
    headerTxt: 'TRANSACTIONS',
    features: [
      'Connect a Stripe account to start accepting payments.',
      'Funds are deposited immediately to your Stripe account.',
      'Safe and secure payment processing.',
    ],
  },
  {
    flowType: 'donations',
    step: 'connect',
    class: 'connect',
    headerTxt: 'TRANSACTIONS',
    features: [
      'Connect a Stripe account to start accepting donations.',
      'Funds are deposited immediately to your Stripe account.',
      'Safe and secure payment processing.',
    ],
  },
  {
    flowType: 'findEvents',
    step: 'causeSelection',
    class: 'find-events',
    headerTxt: 'EVENTS',
    features: [],
  },
  {
    flowType: 'findEvents',
    step: 'cta',
    class: 'find-events',
    headerTxt: 'EVENTS',
    features: [],
  },
  {
    flowType: 'findGroups',
    step: 'causeSelection',
    class: 'find-groups',
    headerTxt: 'DONATE',
    features: [],
  },
  {
    flowType: 'findGroups',
    step: 'cta',
    class: 'find-groups',
    headerTxt: 'DONATE',
    features: [],
  },
  {
    flowType: 'volunteer',
    step: 'volunteerInfo',
    class: 'volunteer',
    headerTxt: 'Volunteer',
    features: [],
  },
  {
    flowType: 'volunteer',
    step: 'volunteerEmergency',
    class: 'volunteer',
    headerTxt: 'Volunteer',
    features: [],
  },
  {
    flowType: 'volunteer',
    step: 'causeSelection',
    class: 'volunteer',
    headerTxt: 'Volunteer',
    features: [],
  },
  {
    flowType: 'volunteer',
    step: 'cta',
    class: 'volunteer',
    headerTxt: 'Volunteer',
    features: [],
  },
  {
    flowType: 'any',
    step: 'groupLocalization',
    class: 'group-localization',
    headerTxt: '',
    features: [],
  },
  {
    flowType: 'fundraise',
    step: 'fundraiseType',
    class: 'fundraise-type',
    headerTxt: '',
    features: [],
  },
  {
    flowType: 'fundraise',
    step: 'teamSelect',
    class: 'team-select',
    headerTxt: '',
    features: [],
  },
  {
    flowType: 'fundraise',
    step: 'goalIndividual',
    class: 'goal-individual',
    headerTxt: '',
    features: [],
  },
  {
    flowType: 'fundraise',
    step: 'goalTeam',
    class: 'goal-team',
    headerTxt: '',
    features: [],
  },
  {
    flowType: 'fundraise',
    step: 'isContentCreator',
    class: 'content-creator',
    headerTxt: '',
    features: [],
  },
  {
    flowType: 'fundraise',
    step: 'tickets',
    class: 'tickets',
    headerTxt: '',
    features: [],
  },
  {
    flowType: 'fundraise',
    step: 'addParticipant',
    class: 'add-participant',
    headerTxt: '',
    features: [],
  },
  {
    flowType: 'fundraise',
    step: 'connectStravaAccount',
    class: 'connect-strava-account',
    headerTxt: '',
    features: [],
  },
];
