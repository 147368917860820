import axios from 'axios';

import { IUserRegistration, IUserToken } from '@gigit/interfaces';
import { swapRouteParams, routes } from '../helpers';

export namespace authRequestActions {
  export async function checkIfEmailExists(email: string) {
    const response = await axios.get<{ user_exists: boolean }>(
      swapRouteParams(routes.CHECK_DUPLICATE_EMAIL, { email: email }),
    );

    return response.data.user_exists;
  }

  export async function generateVerificationCode(email: string) {
    const response = await axios.post<Promise<{ was_generated: boolean }>>(
      swapRouteParams(routes.GENERATE_VERIFICATION_CODE, { email }),
    );

    return response.data;
  }

  export async function validateVerificationcode(email: string, code: string) {
    const response = await axios.post<Promise<{ is_valid: boolean }>>(
      swapRouteParams(routes.VALIDATE_VERIFICATION_CODE, { email, code }),
    );

    return response.data;
  }

  export async function checkAuthType(email: string, redirect_url?: string) {
    const response = await axios.post<string | false>(
      swapRouteParams(routes.CHECK_AUTH_TYPE, { email }),
      { redirect_url: redirect_url || '' },
    );

    return response.data;
  }

  export async function registerUser(user: Partial<IUserRegistration>): Promise<IUserToken> {
    const response = await axios.post(routes.REGISTER, user);

    // if (response.data.tokens.access_token) {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.tokens.access_token}`;
    // TODO - reimplement register bits
    // should get unread notifications as well
    // also updates localize from state
    // }

    return response.data;
  }
}
