import React from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { defaultCurrency, routes, swapRouteParams } from '../../../helpers';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  IBroadcastMessage,
  IEventWidgetDetails,
  IIndividualFundraisingDonationBroadcastContent,
} from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { Config } from '@gigit/config';
import io from 'socket.io-client';

import { IAppState } from '../../../store';

import { IUserState } from '../../../reducers/user';

import './ProgressBar.scss';
import { userSelectors } from '../../../selectors/user';
import ProgressBarWidget from '../../../components/widgets/ProgressBarWidget/ProgressBarWidget';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  userState: IUserState;
  locale: string;
}

interface IState {
  widget: IEventWidgetDetails;
  getWidgetDetailsInterval: any;
}

/** Route handler for an embedable event individual progress bar. */
class ProgressBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      widget: {
        group_id: '',
        group_name: '',
        group_profile_image_url: '',
        event_id: '',
        event_name: '',
        event_profile_image_url: '',
        event_short_url: '',
        user_id: '',
        individual_goal: 0,
        individual_raised: 0,
        individual_short_url: '',
        currency: defaultCurrency,
        widget_theme: Constants.default_widget_theme,
        widget_style: Constants.default_widget_style['progress_bar'],
        event_handle: '',
        group_handle: '',
        user_handle: '',
      },
      getWidgetDetailsInterval: null,
    };

    this.getWidgetDetails.bind(this);
  }

  componentDidMount() {
    this.getWidgetDetails();

    // Every 30 seconds we fetch widget details again, just in case they changed.
    // This also allows us to ensure the progress is synced, just in case it gets out of sync.
    let _i = setInterval(() => {
      this.getWidgetDetails();
    }, 30000);

    let socket = io(Config.web.REACT_APP_CHAT_GATEWAY + '/chat');

    // Update progress bar in realtime using websocket.
    socket.on(
      'broadcast',
      (msg: IBroadcastMessage<IIndividualFundraisingDonationBroadcastContent>) => {
        switch (msg.message_type) {
          case 'donation':
            if (
              msg.content.event_id === this.state.widget.event_id &&
              this.state.widget.event_id &&
              msg.content.individual_user_id === this.state.widget.user_id &&
              this.state.widget.user_id
            ) {
              this.setState({
                widget: {
                  ...this.state.widget,
                  individual_raised: this.state.widget.individual_raised + msg.content.amount,
                },
              });
            }
            break;
        }
      },
    );

    this.setState({
      getWidgetDetailsInterval: _i,
    });
  }

  componentWillUnmount() {
    if (this.state.getWidgetDetailsInterval !== null) {
      clearInterval(this.state.getWidgetDetailsInterval);
    }
  }

  getWidgetDetails() {
    Axios.get(
      swapRouteParams(routes.GET_WIDGET_DETAILS, { widgetId: this.props.match.params.id }),
    ).then((response) => {
      this.setState({
        widget: response.data,
      });
    });
  }

  render() {
    let { t } = this.props;

    return (
      <div className={'ProgressBar'}>
        <ProgressBarWidget widget={this.state.widget} />
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ProgressBar),
);
