import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  capitalizeString,
  defaultCurrency,
  formatQuery,
  handleDebounce,
  handleInputChange,
} from '../../helpers';
import { IKpi, IOwnerObject, ReduxActionType, StatisticType } from '../../interfaces';
import { IAppState } from '../../store';

import { IGroupState } from '../../reducers/group';

import { getStoreItems, deleteStoreItem, StoreItemOwnerType } from '../../actions/store';
import { getEventStats } from '../../actions/event';
import { getGroupStats } from '../../actions/group';
import { localizeHelpers } from '../../localizeHelpers';

import Button from '../Button/Button';
import Portrait from '../Portrait/Portrait';
import TextField from '../TextField/TextField';
import ContextMenu from '../ContextMenu/ContextMenu';
import StoreItemForm from '../StoreItemForm/StoreItemForm';
import KpiDisplay from '../KpiDisplay/KpiDisplay';

import './StoreItemManagement.scss';
import Modal from '../Modal/Modal';
import { IEventState } from '../../reducers/event';
import { IStoreState } from '../../reducers/store';
import { formatCurrency } from '../../helpers';
import { Constants } from '@gigit/constants';
import { userSelectors } from '../../selectors/user';

interface IProps extends WithTranslation {
  owner: IOwnerObject;
  groupState: IGroupState;
  eventState: IEventState;
  storeState: IStoreState;
  locale: string;
  getStoreItems: ReduxActionType<typeof getStoreItems>;
  deleteStoreItem: ReduxActionType<typeof deleteStoreItem>;
  getEventStats: ReduxActionType<typeof getEventStats>;
  getGroupStats: ReduxActionType<typeof getGroupStats>;
}

interface IState {
  showAddForm: boolean;
  searchValue: string;
  editObject: any | null;
}

class StoreItemManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showAddForm: false,
      searchValue: '',
      editObject: null,
    };

    this.toggleAddForm = this.toggleAddForm.bind(this);
  }

  componentDidMount() {
    this.props.getStoreItems({
      ownerType: this.props.owner.ownerType as StoreItemOwnerType,
      ownerId: this.props.owner.ownerId,
    });

    if (this.props.owner.ownerType === 'event') {
      this.props.getEventStats(this.props.eventState.event.id, StatisticType.STORE);
    } else {
      this.props.getGroupStats(this.props.groupState.group.id, StatisticType.STORE);
    }
  }

  getKpi() {
    let data: IKpi[] = [];
    const storeStats =
      this.props.owner.ownerType === 'event'
        ? this.props.eventState.storeStats
        : this.props.groupState.storeStats;
    const currency = this.props.owner?.account?.currency ?? defaultCurrency;
    if (storeStats) {
      data.push(
        {
          value: localizeHelpers.formatNumber(
            storeStats.total_number_of_items || 0,
            this.props.locale,
          ),
          text: 'Number of Items',
        },
        {
          value: `${formatCurrency(storeStats.average_ticket_price, currency, this.props.locale)}`,
          text: 'Average Ticket Price',
        },
        {
          value: `${formatCurrency(storeStats.average_merchandise_price, currency, this.props.locale)}`,
          text: 'Average Merchandise Price',
        },
      );
    }

    if (data.length && !this.props.eventState.isStatsLoading) {
      return (
        <div className="section-kpi">
          <KpiDisplay kpiDataList={data} />
        </div>
      );
    } else {
      return null;
    }
  }

  toggleAddForm(value?: boolean) {
    this.setState({
      showAddForm: value ? value : !this.state.showAddForm,
    });
  }

  setEdit(item: any) {
    this.setState({
      editObject: item,
    });
  }

  clearEdit() {
    this.setState({
      editObject: null,
    });
  }

  render() {
    const currency = this.props.owner.account?.currency ?? defaultCurrency;

    return (
      <div className="StoreItemManagement section-wrap">
        <div className="section-title">
          <div className="store-title">
            <span>Store</span>
          </div>
          <div className="store-action">
            <Button
              icon="fal fa-plus"
              text="Create Item"
              onClick={(e) => {
                e.preventDefault();
                this.toggleAddForm(true);
              }}
            />
          </div>
        </div>

        {this.getKpi()}

        <div className="section-inner">
          <div className="StoreItemManagement-list">
            <div className="search">
              <TextField
                icon="fas fa-search"
                placeholder="Search store items..."
                value={this.state.searchValue}
                type="text"
                name="searchValue"
                onChange={(e) => {
                  handleInputChange(e, this, false, () => {
                    handleDebounce(this.state.searchValue).then((res) => {
                      const query = formatQuery({
                        filters:
                          this.state.searchValue !== ''
                            ? [
                                {
                                  id: 'name',
                                  label: 'name',
                                  type: 'text',
                                  value: this.state.searchValue,
                                },
                              ]
                            : undefined,
                      });
                      this.props.getStoreItems({
                        ownerType: this.props.owner.ownerType as StoreItemOwnerType,
                        ownerId: this.props.owner.ownerId,
                        query,
                      });
                    });
                  });
                }}
              />
            </div>
            {this.state.editObject === null && (
              <div className="list">
                <div className="headers">
                  <div className="col portrait" />
                  <div className="col item-name">Item Name</div>
                  <div className="col description">Description</div>
                  <div className="col category">Category</div>
                  <div className="col price">Price</div>
                  <div className="col actions" />
                </div>
                <div className="list-inner">
                  <div className="list-rows">
                    {this.props.storeState.storeItems &&
                      this.props.storeState.storeItems.map((item, index) => {
                        let _menuItems = [
                          {
                            onClick: () => {
                              this.setEdit(item);
                            },
                            icon: 'fas fa-pencil',
                            label: 'Edit',
                          },
                          {
                            onClick: () => {
                              let i = item.id || '';
                              this.props.deleteStoreItem(
                                this.props.owner.ownerType as StoreItemOwnerType,
                                this.props.owner.ownerId,
                                i,
                                {
                                  callback: () => {
                                    this.props.owner.ownerType === 'event'
                                      ? this.props.getEventStats(
                                          this.props.owner.ownerId,
                                          StatisticType.STORE,
                                        )
                                      : this.props.getGroupStats(
                                          this.props.owner.ownerId,
                                          StatisticType.STORE,
                                        );
                                  },
                                },
                              );
                            },
                            icon: 'fas fa-trash',
                            label: 'Delete',
                          },
                        ];

                        return (
                          <div
                            key={index}
                            className="row"
                          >
                            <div className="col portrait">
                              <Portrait
                                source={item.media.length > 0 ? item.media[0].url : ''}
                                size={40}
                              />
                            </div>
                            <div
                              className="col item-name"
                              notranslate="yes"
                            >
                              {item.name}
                            </div>
                            <div
                              className="col description"
                              notranslate="yes"
                            >
                              <div className="description-inner">
                                {item?.description
                                  ?.replace(/<[^>]*>?/gm, '')
                                  .replace(/&nbsp;/g, ' ')}
                              </div>
                            </div>
                            <div className="col category">{capitalizeString(item.item_type)}</div>
                            <div
                              className="col price"
                              notranslate="yes"
                            >
                              {formatCurrency(item.price, currency, this.props.locale)}
                            </div>
                            <div className="col actions">
                              <i className="fal fa-ellipsis-h-alt" />
                              <ContextMenu
                                totalItemCount={this.props.storeState.storeItems.length}
                                currentItemIndex={index}
                                onMouseLeave={() => {}}
                                showMenu={true}
                                menuItems={_menuItems}
                              />
                            </div>
                          </div>
                        );
                      })}
                    {!this.state.showAddForm &&
                      !this.state.editObject &&
                      this.props.storeState.storeItems.length === 0 && (
                        <div className="empty">You don't have any store items, yet.</div>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <Modal
            closeIcon="fas fa-times"
            show={this.state.showAddForm}
            onClose={() => this.setState({ showAddForm: false })}
            title="Create Store Item"
          >
            <StoreItemForm
              onClose={() => this.toggleAddForm(false)}
              {...this.props}
            />
          </Modal>
          <Modal
            closeIcon="fas fa-times"
            show={this.state.editObject}
            onClose={() => this.clearEdit()}
            title="Edit Store Item"
          >
            <StoreItemForm
              storeItem={this.state.editObject}
              onClose={() => this.clearEdit()}
              {...this.props}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    storeState: store.storeState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getStoreItems: getStoreItems,
  deleteStoreItem: deleteStoreItem,
  getEventStats,
  getGroupStats,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(StoreItemManagement),
);
