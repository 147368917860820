import { Constants } from '@gigit/constants';
import { IGroup, IRole } from '@gigit/interfaces';
import axios from 'axios';
import { swapRouteParams, routes } from '../helpers';

export namespace userRequestActions {
  export async function getUserGroupsForPermission(permission: string) {
    const params = {
      params: { 'field_filters[status.code]': `<>${Constants.group_status.archived}` },
    };
    const response = await axios.get<IGroup[]>(
      swapRouteParams(routes.GET_USER_GROUPS_FOR_PERMISSION, { permission }),
      params,
    );

    return response.data;
  }

  export async function getUsersByRoleId(ownerType: string, ownerId: string, roleId: string) {
    let url;
    let idField;
    switch (ownerType) {
      case Constants.object_type.hub:
        url = routes.HUB_USERS_BY_ROLE;
        idField = 'hubId';
        break;
      default:
        return;
    }

    return axios.get<IRole[]>(swapRouteParams(url, { [idField]: ownerId, roleId }));
  }
}
