import './Hub.scss';
import React, { ReactNode, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import Joyride, { CallBackProps } from 'react-joyride';
import { Link, Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import {
  IHub,
  IOrgPremiumFeaturesSummary,
  IPage,
  IUserRole,
  IUserRoleJoin,
} from '@gigit/interfaces';
import Button from '../Button/Button';
import HubModalManage from './HubModals/HubModalManage/HubModalManage';
import HubModalAddComponents from './HubModals/HubModalAddComponents/HubModalAddComponents';
import HubModalTheme from './HubModals/HubModalTheme/HubModalTheme';
import HubModalManageComponents from './HubModals/HubModalManageComponents/HubModalManageComponents';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../shared/PopupMenu/PopupMenu';
import { billingRequestActions, hubRequestActions, userRequestActions } from '../../requestActions';
import useToastDispatcher from '../../hooks/useToaster';
import HubOnBoardingGuide from './HubOnBoardingGuide/HubOnBoardingGuide';
import PageContainer from './Shared/PageContainer/PageContainer';
import {
  errorHelpers,
  getTextColorBasedOnBgColor,
  hasPermission,
  isPageActive,
  joyRideStyles,
  setSEOMetatags,
  toastError,
  typeHelpers,
} from '../../helpers';
import { IAppError, IOwnerObject } from '../../interfaces';
import HubBannerManage from './HubModals/HubBannerManage/HubBannerManage';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { IAppState, useAppSelector } from '../../store';
import { userSelectors } from '../../selectors/user';
import AddMemberModal from '../AddMemberModal/AddMemberModal';
import { uiConstants } from '../../constants';
import HubModalDonate from './HubModals/HubModalDonate/HubModalDonate';
import Portrait from '../Portrait/Portrait';
import { Constants } from '@gigit/constants';
import KambeoLoaderGIF from '../../assets/loaders/kambeo-loading-normal-speed.gif';
import RightHeader from '../RightHeader/RightHeader';
import UserSearch from '../UserSearch/UserSearch';
import useScrollDirection from '../../hooks/useScrollDirection';
import HubInviteMembers from './HubModals/HubInviteMembers/HubInviteMembers';
import HubStatusActive from '../../assets/hub_status_active.svg';
import HubStatusDraft from '../../assets/hub_status_draft.svg';
import HubStatusArchived from '../../assets/hub_status_archived.svg';
import { localizeHelpers } from '../../localizeHelpers';
import HubTrailWarningBanner from './Shared/HubTrailWarningBanner/HubTrailWarningBanner';
import SocialImpactBenefitsModal from './HubModals/SocialImpactBenefitsModal/SocialImpactBenefitsModal';

interface IProps extends RouteComponentProps<any> {
  /** Used to render optional child content. */
  children?: (ownerObj: IOwnerObject) => ReactNode;
}

const statuses = {
  active: { value: Constants.hub_status.active, label: 'Active', icon: HubStatusActive },
  draft: { value: Constants.hub_status.draft, label: 'Draft', icon: HubStatusDraft },
  archived: { value: Constants.hub_status.archived, label: 'Archived', icon: HubStatusArchived },
};

const helpSteps = [
  {
    disableBeacon: true,
    target: '.hub-menu .active',
    content:
      'Welcome to your Kambeo Hub!  The Home page is a customizable page for you to show the world how your employees have given back!',
  },
  {
    disableBeacon: true,
    target: '.HubMetrics',
    content:
      'These are your real-time company metrics.  As your employees discover causes and perform actions on the Kambeo platform they will update automatically. You can hide or show these at any time.',
  },
  {
    disableBeacon: true,
    target: '.hub-menu .Activity',
    content:
      'The activity feed is a perfect place to start.  Engage your employees in a familiar way using social media to share the stories of things they are already doing in the community.',
  },
  {
    disableBeacon: true,
    target: '.hub-menu .Causes',
    content:
      'Adding Causes to your company Hub is what drives your employee engagement in the causes your company wants to support.  By selecting Causes the Events, Volunteering, Store and Auction tabs will automatically fill with content from your supported Causes.  New items posted by supported Causes will also show up here automatically.',
  },
  {
    disableBeacon: true,
    target: '.PopupMenu.PopupMenuHubEdit .MenuItem:nth-of-type(4)',
    content:
      'You can easily update the settings for your Hub, manage billing and users all in one place.',
  },
  {
    disableBeacon: true,
    target: '.PopupMenu.PopupMenuHubEdit .MenuItem:nth-of-type(5)',
    content:
      "When you are ready to kick-off your program, simply invite your employees and watch your company's engagement and commitment to social impact grow!",
  },
];

const scrollOffSet = 650; // used to scroll to view and skip banner image
export interface IHubTab {
  title: string;
  component: React.FC | null;
}
export type EditOverlayParams = {
  onDelete?: () => void;
  onEdit?: () => void;
  draggable?: boolean;
};

//REDUCER TYPES & STATE
type Action =
  | {
      type: 'FETCH_HUB';
    }
  | {
      type: 'FETCH_HUB_SUCCESS';
      payload: {
        hub: IHub;
      };
    }
  | {
      type: 'FETCH_HUB_ERROR';
      payload: {
        error: unknown;
      };
    }
  | {
      type: 'FETCH_HUB_SUBSCRIPTIONS';
    }
  | {
      type: 'FETCH_HUB_SUBSCRIPTIONS_SUCCESS';
      payload: {
        hubSubscriptions: IOrgPremiumFeaturesSummary;
      };
    }
  | {
      type: 'FETCH_HUB_SUBSCRIPTIONS_ERROR';
      payload: {
        error: unknown;
      };
    }
  | {
      type: 'FETCH_HUB_PAGES';
    }
  | {
      type: 'FETCH_HUB_PAGES_SUCCESS';
      payload: {
        pages: IPage[];
      };
    }
  | {
      type: 'FETCH_HUB_PAGES_ERROR';
      payload: {
        error: unknown;
      };
    }
  | {
      type: 'UPDATE_SHOW_MODAL_BY_KEY';
      payload: {
        key: keyof IHubState;
        value: boolean;
      };
    }
  | {
      type: 'UPDATE_ACTIVE_PAGE_INDEX';
      payload: {
        newIndex: number;
      };
    }
  | {
      type: 'UPDATE_EDIT_MODE';
      payload: {
        newValue: boolean;
      };
    }
  | {
      type: 'FETCH_USER_HUB_ROLE';
    }
  | {
      type: 'FETCH_USER_HUB_ROLE_SUCCESS';
      payload: {
        role: IUserRoleJoin;
        userHasEditPermission: boolean;
        doesUserFollowHub: boolean;
      };
    }
  | {
      type: 'FETCH_USER_HUB_ROLE_ERROR';
      payload: {
        error: unknown;
      };
    }
  | {
      type: 'TOGGLE_FOLLOW_HUB';
      payload: {
        doesUserFollowHub: boolean;
      };
    };

/** Provides style objects based on hub theme */
export interface IThemeStyleObjectsByType {
  /** Used on backgrounds with text that needs to be automatically color adjusted to light or dark */
  primaryBgWithText: {
    color: string;
    backgroundColor: string;
  };
  /** Used on backgrounds with no text. backgroundColor is set to hub.theme.primary_color */
  primaryBg: {
    backgroundColor: string;
  };
  /** Used on backgrounds with text that needs to be automatically color adjusted to light or dark */
  secondaryBgWithText: {
    color: string;
    backgroundColor: string;
  };
  /** Style object containing color from hub.theme.secondary_color */
  secondaryText: {
    color: string;
  };
  /** Style object containing color from hub.theme.secondary_color */
  secondaryBg: {
    backgroundColor: string;
  };
}

export interface IHubState {
  //Kambeo State
  hub: IHub | null;
  hubPages: IPage[];
  hubSubscriptions: IOrgPremiumFeaturesSummary | null;
  ownerObject: IOwnerObject | null;
  userHasEditPermission: boolean;
  hubUserRole: IUserRoleJoin | null;
  doesUserFollowHub: boolean;

  //UI State
  editMode: boolean;
  isHubLoading: boolean;
  hubFetchError: unknown | null;
  isHubPagesLoading: boolean;
  isHubUserRoleLoading: boolean;
  hubPagesFetchError: unknown | null;
  hubUserRoleFetchError: unknown | null;
  activePageIndex: number;
  themeObjectByType: IThemeStyleObjectsByType;

  showManageModal: boolean;
  showInviteModal: boolean;
  showAddComponentsModal: boolean;
  showManageComponentsModal: boolean;
  showThemeModal: boolean;
  showSocialImpactBenefitsModal: boolean;
  showContextModal: boolean;
  showBannerEditModal: boolean;
  showDonateModal: boolean;
}

const initialHubState: IHubState = {
  //Kambeo State
  hub: null,
  hubSubscriptions: null,
  hubPages: [],
  ownerObject: null,
  userHasEditPermission: false,
  hubUserRole: null,
  doesUserFollowHub: false,

  //UI State
  editMode: false,
  isHubLoading: true,
  hubFetchError: null,
  isHubPagesLoading: true,
  isHubUserRoleLoading: true,
  hubPagesFetchError: null,
  hubUserRoleFetchError: null,
  activePageIndex: 0,
  themeObjectByType: {
    primaryBgWithText: {
      color: '',
      backgroundColor: '',
    },
    primaryBg: {
      backgroundColor: '',
    },
    secondaryBgWithText: {
      color: '',
      backgroundColor: '',
    },
    secondaryText: {
      color: '',
    },
    secondaryBg: {
      backgroundColor: '',
    },
  },

  showManageModal: false,
  showInviteModal: false,
  showAddComponentsModal: false,
  showManageComponentsModal: false,
  showThemeModal: false,
  showContextModal: false,
  showBannerEditModal: false,
  showDonateModal: false,
  showSocialImpactBenefitsModal: false,
};

function hubReducer(state: IHubState, action: Action): IHubState {
  switch (action.type) {
    case 'FETCH_HUB': {
      return {
        ...state,
        isHubLoading: true,
        hubFetchError: null,
      };
    }
    case 'FETCH_HUB_SUCCESS': {
      const { hub } = action.payload;
      return {
        ...state,
        isHubLoading: false,
        hub: action.payload.hub,
        ownerObject: typeHelpers.createOwnerObject('hub', hub),
        hubFetchError: null,
        themeObjectByType: {
          primaryBg: {
            backgroundColor: hub.theme?.primary_color || '',
          },
          primaryBgWithText: {
            backgroundColor: hub.theme?.primary_color || '',
            color: getTextColorBasedOnBgColor(hub.theme?.primary_color || ''),
          },
          secondaryText: {
            color: hub.theme?.secondary_color || '',
          },
          secondaryBgWithText: {
            backgroundColor: hub.theme?.secondary_color || '',
            color: getTextColorBasedOnBgColor(hub.theme?.secondary_color || ''),
          },
          secondaryBg: {
            backgroundColor: hub.theme?.secondary_color || '',
          },
        },
      };
    }
    case 'FETCH_HUB_ERROR': {
      return {
        ...state,
        isHubLoading: false,
        hubFetchError: action.payload.error,
      };
    }
    case 'FETCH_HUB_SUBSCRIPTIONS': {
      return {
        ...state,
        isHubLoading: true,
        hubFetchError: null,
      };
    }
    case 'FETCH_HUB_SUBSCRIPTIONS_SUCCESS': {
      return {
        ...state,
        isHubLoading: false,
        hubSubscriptions: action.payload.hubSubscriptions,
        hubFetchError: null,
      };
    }
    case 'FETCH_HUB_PAGES': {
      return {
        ...state,
        isHubPagesLoading: true,
        hubPagesFetchError: null,
      };
    }
    case 'FETCH_HUB_PAGES_SUCCESS': {
      return {
        ...state,
        isHubPagesLoading: false,
        hubPages: action.payload.pages,
        hubPagesFetchError: null,
      };
    }
    case 'FETCH_HUB_PAGES_ERROR': {
      return {
        ...state,
        isHubPagesLoading: false,
        hubPagesFetchError: action.payload.error,
      };
    }
    case 'FETCH_USER_HUB_ROLE': {
      return {
        ...state,
        isHubUserRoleLoading: true,
        hubUserRoleFetchError: null,
      };
    }
    case 'FETCH_USER_HUB_ROLE_SUCCESS': {
      return {
        ...state,
        isHubUserRoleLoading: false,
        hubUserRole: action.payload.role,
        userHasEditPermission: action.payload.userHasEditPermission,
        doesUserFollowHub: action.payload.doesUserFollowHub,
        hubUserRoleFetchError: null,
      };
    }
    case 'FETCH_USER_HUB_ROLE_ERROR': {
      return {
        ...state,
        isHubUserRoleLoading: false,
        hubUserRoleFetchError: action.payload.error,
      };
    }
    case 'UPDATE_SHOW_MODAL_BY_KEY': {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    case 'UPDATE_ACTIVE_PAGE_INDEX': {
      return {
        ...state,
        activePageIndex: action.payload.newIndex,
      };
    }
    case 'UPDATE_EDIT_MODE': {
      return {
        ...state,
        editMode: action.payload.newValue,
      };
    }
    case 'TOGGLE_FOLLOW_HUB': {
      return {
        ...state,
        doesUserFollowHub: action.payload.doesUserFollowHub,
      };
    }
    default:
      throw new Error(`Unknown action type`);
  }
}

function Hub(props: IProps) {
  const { dispatchToastError, dispatchToastSuccess, dispatchToastInfo } = useToastDispatcher();
  const statusesDropdown = useRef<HTMLDivElement>(null);
  //TODO: Add these states to IHubState
  const [showOnBoardingGuide, setShowOnBoardingGuide] = useState<boolean>(false);
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [hubSeatsAvailable, setHubSeatsAvailable] = useState<number>(0);
  const [showContextMenuStates, setShowContextMenuStates] = useState<boolean>(false);

  const headerMenuRef = useRef<HTMLDivElement>(null);
  const headerTitleContainerRef = useRef<HTMLDivElement>(null);
  const mainImgContainerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  //TODO: Turn hub banner into a page component
  //TODO: Make separate footer component

  const user = useAppSelector((state: IAppState) => userSelectors.getUser(state));
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const totalItemsInCart = useAppSelector((state) => state.cartState.items.length);
  const [state, dispatch] = useReducer(hubReducer, initialHubState);
  const [userLanguage, setUserLanguage] = useState<string>(user.language ?? 'en');

  //Logic to show hub loader
  const isHubStateLoading =
    state.isHubLoading && state.isHubPagesLoading && state.isHubUserRoleLoading;
  const [showHubOverlayLoader, setShowHubOverlayLoader] = useState<boolean>(true); //Controls opacity
  const [showOverlayLoaderInDOM, setShowOverlayLoaderInDOM] = useState<boolean>(true); //Controls display
  const [showUserMenuModal, setShowUserMenuModal] = useState<boolean>(false);
  const [isMember, setIsMember] = useState<boolean>(false);
  const showLoaderOpacityAnimationClass = showHubOverlayLoader ? 'show' : '';
  const removeLoaderFromDOM = showOverlayLoaderInDOM ? '' : 'remove-from-dom';
  const scrollDetection = useScrollDirection();
  const [userRole, setUserRole] = useState<string>('');

  //Tour when hub is created
  const [editMenuOpen, setEditMenuOpen] = useState<boolean>(false);

  const contextMenuItemsStatuses: IPopupMenuItem[] = [
    {
      id: statuses.active.value,
      label: statuses.active.label,
      iconImg: statuses.active.icon,
      isSelected: state.hub?.status?.code === statuses.active.value,
      onClick: () => {
        updateHubStatus(state.hub?.id || '', statuses.active.value);
        setShowContextMenuStates(false);
      },
    },
    {
      id: statuses.draft.value,
      label: statuses.draft.label,
      iconImg: statuses.draft.icon,
      isSelected: state.hub?.status?.code === statuses.draft.value,
      onClick: () => {
        updateHubStatus(state.hub?.id || '', statuses.draft.value);
        setShowContextMenuStates(false);
      },
    },
    {
      id: statuses.archived.value,
      label: statuses.archived.label,
      iconImg: statuses.archived.icon,
      isSelected: state.hub?.status?.code === statuses.archived.value,
      onClick: () => {
        updateHubStatus(state.hub?.id || '', statuses.archived.value);
        setShowContextMenuStates(false);
      },
    },
  ];

  const popupMenuConfigStates: IShowPopupConfig = {
    showMenu: showContextMenuStates,
    setShowMenu: setShowContextMenuStates,
    position: {
      type: 'bottom',
    },
  };

  async function getHubRoles() {
    const userRoleName = await userRequestActions.getUserHubRole(state.hub?.id || '');
    setUserRole(userRoleName.role_name);
  }

  async function checkIsMember() {
    const result = await hubRequestActions.getHubUser(state.hub?.id || '');
    setIsMember(result.role_name === uiConstants.role.member);
  }

  //Shows a kambeo loader until initial requests are done
  useEffect(() => {
    //Adds delay until opacity starts fading
    let opacityTimeout: NodeJS.Timeout;
    //Timeout until loader is set to display:none
    let domTimeout: NodeJS.Timeout;

    if (!isHubStateLoading && showHubOverlayLoader) {
      opacityTimeout = setTimeout(() => {
        setShowHubOverlayLoader(false);
      }, 1000);
      domTimeout = setTimeout(() => {
        setShowOverlayLoaderInDOM(false);
      }, 1200);
    }

    return () => {
      clearTimeout(domTimeout);
      clearTimeout(opacityTimeout);
    };
  }, [isHubStateLoading]);

  useEffect(() => {
    handleHeaderPosition(state.editMode);
  }, [scrollDetection, state.editMode]);

  useEffect(() => {
    toggleSlideHeader(totalItemsInCart > 0);
  }, [totalItemsInCart]);

  useEffect(() => {
    if (state.hub?.id) {
      checkIsMember();
      getHubRoles();
    }
  }, [state.hub?.id, user.id]);

  useEffect(() => {
    const locationSearchParams = queryString.parse(props.location.search);
    if (locationSearchParams.page) {
      if (state.hubPages.length) {
        const pageIndex = state.hubPages
          .map((page) => page.label)
          .indexOf(locationSearchParams.page as string);
        handleUpdateActivePageIndex(state.hubPages[pageIndex], pageIndex);
      }
    }
  }, [props.match.params.handle, state.hubPages]);

  useEffect(() => {
    fetchHub(props.match.params.handle);

    const locationSearchParams = queryString.parse(props.location.search);

    if (locationSearchParams?.help) {
      setShowOnBoardingGuide(true);
    }
  }, [props.match.params.handle]);

  //Used to fetch when a hub exists, or is altered
  useEffect(() => {
    if (state.hub?.id) {
      tryInitializeHubState(state.hub);
      tryInitializeUserState(state.hub);

      const checkIsPageActive = async () => {
        if (state.hub?.id) {
          let userRoleName: IUserRoleJoin;
          /**
           * Here we check to see if the user can access a 'draft' page.
           * a user can access a draft page if they have the proper admin/on-behalf-of role
           * if a user doesn't have one of those roles or is logged out, we redirect them to the home page
           */
          if (!isUserLoggedIn && state.hub.status?.code === Constants.hub_status.draft) {
            showNotActivePageWarning();
          }
          if (isUserLoggedIn && state.hub.status?.code === Constants.hub_status.draft) {
            try {
              userRoleName = await userRequestActions.getUserHubRole(state.hub.id);
              if (
                !isPageActive({
                  status: state.hub?.status?.code,
                  isUserLoggedIn,
                  roleName: userRoleName.role_name,
                })
              ) {
                showNotActivePageWarning();
              }
            } catch (err) {
              const errorObj = errorHelpers.getErrorObject(err);

              if (
                errorObj.errorCode !== 'ERROR.ROLES.NO_OBJECT_PERMISSIONS' &&
                errorObj.errorCode !== 'ERROR.AUTH.UNAUTHORIZED'
              ) {
                dispatchToastError(errorObj.translatedMessage, 'Company Members');
              }
            }
          }
        }
      };

      checkIsPageActive();

      setSEOMetatags({
        urlPath: `company/${state.hub.handle}`,
        title: `${state.hub.title} | Kambeo`,
        description: undefined,
        imageURL: state.hub.profile_image_url,
        coverImageURL: state.hub.banner?.image || '',
      });
    }

    let _params = queryString.parse(props.match.params);
    if (_params.fn === 'toggleFollowHub') {
      toggleFollowHub();
    }
  }, [state.hub, isUserLoggedIn]);

  useEffect(() => {
    if (state.hubUserRole) {
      const billingPermission = state.hubUserRole.permissions.find(
        (permission) => permission.permission == 'MANAGE_BILLING' && permission.value,
      );
      if (billingPermission && state.hub?.id) {
        fetchHubPremiumFeatures(state.hub?.id);
      }
    }
  }, [state.hubUserRole]);

  useEffect(() => {
    if (props.location.search.includes('tour')) {
      setEditMenuOpen(true);
    }
    if (props.location) {
      props.location.state &&
        props.location.state === 'editTrue' &&
        dispatch({ type: 'UPDATE_EDIT_MODE', payload: { newValue: true } });
    }
  }, [props.location]);

  const tryInitializeHubState = useCallback(
    async (hub: IHub) => {
      if (hub.id) {
        await fetchHubPages(hub.id);
      }
    },
    [state.hub],
  );

  const tryInitializeUserState = useCallback(
    async (hub: IHub) => {
      if (hub.id && isUserLoggedIn) {
        await fetchUserHubRole(hub.id);
      }
    },
    [state.hub?.id, state.hubUserRole, isUserLoggedIn, state.doesUserFollowHub],
  );

  async function fetchGetHubMembers(id: string) {
    return await hubRequestActions.getHubMembers(id);
  }

  function showNotActivePageWarning() {
    dispatchToastInfo(localizeHelpers.translate(uiConstants.warning.page_not_active), 'Warning');
    history.push('/');
  }

  async function fetchUserHubRole(hubId: string) {
    dispatch({ type: 'FETCH_USER_HUB_ROLE' });
    try {
      const role = await userRequestActions.getUserHubRole(hubId);
      const userHasEditPermission = hasPermission('EDIT_HUB_PAGES', role);
      dispatch({
        type: 'FETCH_USER_HUB_ROLE_SUCCESS',
        payload: {
          role,
          userHasEditPermission,
          doesUserFollowHub:
            role?.user_capacity?.includes(Constants.user_capacity.follower) ?? false,
        },
      });
    } catch (error) {
      dispatchToastError(error, 'Error Getting User Role');
      dispatch({ type: 'FETCH_USER_HUB_ROLE_ERROR', payload: { error } });
    }
  }

  async function fetchHub(hubHandle: string) {
    dispatch({ type: 'FETCH_HUB' });
    try {
      const hub = await hubRequestActions.getHub(hubHandle);
      dispatch({ type: 'FETCH_HUB_SUCCESS', payload: { hub } });
    } catch (error) {
      dispatchToastError(error, 'Error Getting Hub');
      dispatch({ type: 'FETCH_HUB_ERROR', payload: { error } });
    }
  }

  async function tryRefreshHub() {
    if (state.hub?.handle) {
      await fetchHub(state.hub.handle);
    }
  }

  async function updateHubStatus(id: string, status: string) {
    try {
      const result = await hubRequestActions.updateHubStatus(id, status);
      if (status === statuses.archived.value) {
        history.push('/dashboard?section=pages&tab=hub');
        dispatchToastSuccess(
          localizeHelpers.translate('Hub status successfully updated.'),
          'Update Company Page Status',
        );
      } else {
        await tryRefreshHub();
      }
      dispatchToastSuccess(
        localizeHelpers.translate('Hub status successfully updated.'),
        'Update Company Page Status',
      );

      if (!result.charges_enabled) {
        dispatchToastInfo(
          localizeHelpers.translate(
            'Your account is currently not setup to accept payments. In order to run Company events or create Store items, please update your Stripe account information.',
          ),
          'Warning: Payments Not Setup',
          [
            {
              link: {
                link: `/company/${result.hub?.handle}/admin?t=balance-and-payouts`,
                text: 'Balances & Payouts',
              },
            },
          ],
        );
      }
    } catch (error) {
      const tmpError = error as IAppError;
      if (
        state.hub?.handle &&
        tmpError?.errorCode === 'ERROR.EXTERNAL.STRIPE_ACCOUNT_CHARGES_DISABLED'
      ) {
        dispatchToastInfo(
          localizeHelpers.translate(error as string),
          'Warning: Payments Not Setup',
          [
            {
              link: {
                link: `/company/${state.hub.handle}/admin?t=balance-and-payouts`,
                text: 'Balances & Payouts',
              },
            },
          ],
        );
      } else {
        dispatchToastError(error, 'Update Company Page Status');
      }
    }
  }

  async function fetchHubPremiumFeatures(hubId: string) {
    dispatch({ type: 'FETCH_HUB_SUBSCRIPTIONS' });
    try {
      const hubSubscriptions = await billingRequestActions.getHubSubscriptionSummary(hubId);
      let seats = hubSubscriptions.features.find((seat) =>
        [
          Constants.billing.subscriptions.hub_seating.code,
          Constants.billing.subscriptions.hub_seating_do.code,
        ].includes(seat.feature_code),
      );
      if (seats && seats.seats) {
        setHubSeatsAvailable(seats.seats?.total - seats.seats?.used);
      }

      dispatch({ type: 'FETCH_HUB_SUBSCRIPTIONS_SUCCESS', payload: { hubSubscriptions } });
    } catch (error) {
      dispatchToastError(error, 'Error Getting Hub');
      dispatch({ type: 'FETCH_HUB_SUBSCRIPTIONS_ERROR', payload: { error } });
    }
  }

  async function fetchHubPages(hubId: string) {
    dispatch({ type: 'FETCH_HUB_PAGES' });
    try {
      let pages = await hubRequestActions.getHubPages(hubId);
      pages = pages.filter((page) => page.label !== 'Store'); //GIG-7653: Filtering to hide store page for now
      dispatch({ type: 'FETCH_HUB_PAGES_SUCCESS', payload: { pages } });
    } catch (error) {
      dispatchToastError(error, 'Error Getting Hub Pages');
      dispatch({ type: 'FETCH_HUB_PAGES_ERROR', payload: { error } });
    }
  }

  //TODO: Maybe make this into a shared Component?
  function renderEditOverlay(param?: EditOverlayParams) {
    return (
      <>
        <div className="edit-overlay">
          {param?.draggable && <Button icon="far fa-grip-lines" />}
          <div className="custom-actions-container">
            {param?.onDelete && (
              <Button
                icon="far fa-trash-alt"
                onClick={param.onDelete}
              />
            )}
            {param?.onEdit && (
              <Button
                icon="fas fa-pen"
                onClick={param.onEdit}
              />
            )}
          </div>
        </div>
      </>
    );
  }

  function toggleSlideHeader(slideOut?: boolean) {
    if (headerMenuRef.current && mainImgContainerRef.current && headerTitleContainerRef.current) {
      if (slideOut) {
        headerMenuRef.current.classList.remove('fixed-logo');
        headerMenuRef.current.classList.add('slide-out');
      } else {
        headerMenuRef.current.classList.remove('slide-out');
        headerMenuRef.current.classList.add('fixed-logo');
      }
    }
  }

  function handleHeaderPosition(editMode?: boolean) {
    if (headerMenuRef.current && mainImgContainerRef.current && headerTitleContainerRef.current) {
      const { scrollY, scrollDirection } = scrollDetection;

      if (scrollY > 80) {
        headerMenuRef.current.classList.add('fixed-header');
        mainImgContainerRef.current.classList.add('fixed');

        if (editMode) {
          headerMenuRef.current.classList.add('edit-mode');
          headerMenuRef.current.classList.remove('fixed-logo');
        } else {
          headerMenuRef.current.classList.add('fixed-logo');
          headerMenuRef.current.classList.remove('edit-mode');
        }

        toggleSlideHeader(scrollDirection === 'UP' && !editMode);
      } else if (scrollY === 0) {
        mainImgContainerRef.current.classList.remove('fixed');
        headerMenuRef.current.classList.remove('fixed-header', 'fixed-logo');

        if (editMode) {
          headerMenuRef.current.classList.add('fixed-logo');
        }
      }
    }
  }

  function dispatchToggleModalByKey(key: keyof IHubState, value: boolean) {
    dispatch({
      type: 'UPDATE_SHOW_MODAL_BY_KEY',
      payload: {
        key,
        value,
      },
    });
  }

  function toggleSocialImpactBenefitsModal() {
    dispatchToggleModalByKey('showSocialImpactBenefitsModal', !state.showSocialImpactBenefitsModal);
  }

  function toggleManageComponentsModal() {
    dispatchToggleModalByKey('showManageComponentsModal', !state.showManageComponentsModal);
  }

  function toggleThemeModal() {
    dispatchToggleModalByKey('showThemeModal', !state.showThemeModal);
  }

  function toggleAddComponentsModal() {
    dispatchToggleModalByKey('showAddComponentsModal', !state.showAddComponentsModal);
  }

  function toggleManageModal() {
    dispatchToggleModalByKey('showManageModal', !state.showManageModal);
  }

  function toggleInviteModal() {
    dispatchToggleModalByKey('showInviteModal', !state.showInviteModal);
  }

  function toggleBannerManageModal() {
    dispatchToggleModalByKey('showBannerEditModal', !state.showBannerEditModal);
  }

  function toggleEditMode(newValue: boolean) {
    dispatch({ type: 'UPDATE_EDIT_MODE', payload: { newValue } });
  }

  function handleAdminSave() {
    toggleEditMode(false);
  }

  /** Permission to see if the user can edit the Hub */
  const isEditable = () => {
    return state.editMode && state.userHasEditPermission && isUserLoggedIn;
  };

  const popupMenuConfig: IShowPopupConfig = {
    showMenu: showContextMenu,
    setShowMenu: setShowContextMenu,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItems: IPopupMenuItem[] = [
    {
      id: 'edit',
      label: 'Edit',
      icon: 'fas fa-pen',
      onClick: () => {
        toggleEditMode(true);
        setShowContextMenu(false);
      },
    },
    {
      id: 'Manage',
      label: 'Manage',
      icon: 'fas fa-cog',
      onClick: () => {
        toggleManageModal();
        setShowContextMenu(false);
      },
    },
    {
      id: 'invite',
      label: 'Invite',
      icon: 'fas fa-user-plus',
      onClick: () => {
        toggleInviteModal();
        setShowContextMenu(false);
      },
    },
  ];

  function handleUpdateActivePageIndex(page: IPage, newIndex: number) {
    // const scrollTop = page.label === "Home" ? 0 : scrollOffSet;
    // window.scrollTo({ top: scrollTop, behavior: "smooth" });
    dispatch({ type: 'UPDATE_ACTIVE_PAGE_INDEX', payload: { newIndex } });
  }

  function switchHubPage(index: number) {
    dispatch({
      type: 'UPDATE_ACTIVE_PAGE_INDEX',
      payload: { newIndex: index },
    });
  }

  async function toggleFollowHub() {
    if (isUserLoggedIn && user?.id && state.hub?.id) {
      try {
        if (state.doesUserFollowHub) {
          await hubRequestActions.userUnfollowHub(user.id, state.hub?.id);

          dispatch({ type: 'TOGGLE_FOLLOW_HUB', payload: { doesUserFollowHub: false } });
        } else {
          await hubRequestActions.userFollowHub(user.id, state.hub?.id);
          dispatch({ type: 'TOGGLE_FOLLOW_HUB', payload: { doesUserFollowHub: true } });
        }
      } catch (error) {
        dispatchToastError(error, `${state.doesUserFollowHub ? 'Unfollow Hub' : 'Follow Hub'}`);
      }
    } else {
      history.push(`/login?redirect=/company/${state.hub?.handle}/&fn=toggleFollowHub`);
    }
  }
  function checkForPageAccess(visibility: string) {
    if (userRole === uiConstants.role.admin) return true;
    if (visibility === Constants.page_component_visibility.members_only && !isMember) return false;
    if (visibility === Constants.page_component_visibility.members_only && isMember) return true;
    if (visibility === Constants.page_component_visibility.public) return true;
    return true;
  }
  // RENDER FUNCTIONS
  function renderPageTabs(pages: IPage[]) {
    return pages.map(
      (page, index) =>
        checkForPageAccess(page.visibility || '') && (
          <li
            key={index}
            onClick={() => handleUpdateActivePageIndex(page, index)}
            className={`${index === state.activePageIndex ? 'active' : page.label}`}
            style={state.themeObjectByType.primaryBgWithText}
          >
            {page.label}
          </li>
        ),
    );
  }

  function renderEditModeHeader() {
    return (
      <div className={`edit-container ${state.editMode ? 'active' : ''}`}>
        <span className="edit-title">Edit Mode</span>
        <div className="actions-container">
          {/* Hidden for initial hubs beta */}
          {/* <Button icon="fal fa-plus" onClick={toggleAddComponentsModal}>
                        Add Components
                    </Button> */}
          <Button
            icon="fal fa-align-justify"
            onClick={toggleManageComponentsModal}
          >
            Manage Components
          </Button>
          <Button
            icon="fas fa-palette"
            onClick={toggleThemeModal}
          >
            Themes
          </Button>
          <Button
            icon="fas fa-save"
            onClick={handleAdminSave}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }

  //TODO: Memoize?
  function renderActivePageContainer(currentPage: IPage) {
    return (
      <PageContainer
        fetchPagesAfterSave={async () => {
          if (state.hub?.id) {
            await fetchHubPages(state.hub.id);
          }
        }}
        updateHubTab={async (index) => switchHubPage(index)}
        ownerObject={state.ownerObject!}
        page={currentPage || null}
        isPageLoading={state.isHubPagesLoading}
        userHasEditPermissions={state.userHasEditPermission}
        hub={state.hub}
        showEditMode={state.editMode}
        theme={state.themeObjectByType}
        dispatchToggleModalByKey={dispatchToggleModalByKey}
        tryRefreshHub={tryRefreshHub}
      />
    );
  }

  function handleCloseHubOnboardingGuide() {
    props.history.replace({ search: 'tour' });
    setShowOnBoardingGuide(false);
  }

  function renderJoyRide() {
    return (
      <Joyride
        locale={{ back: 'Back', close: 'Close', last: 'Finish', next: 'Next', skip: 'Skip' }}
        continuous={true}
        disableScrolling={true}
        styles={joyRideStyles}
        steps={helpSteps}
        callback={(joyrideState: CallBackProps) => {
          if (joyrideState.status === 'finished') {
            history.replace({ search: '' });
            setEditMenuOpen(false);
          }
        }}
      />
    );
  }

  const trialFeature = state?.hubSubscriptions?.features.find((feature) => feature.is_trial);
  const ownerObject = state.hub ? typeHelpers.createOwnerObject('hub', state.hub) : null;
  if (state.hub?.status?.code === Constants.hub_status.archived) {
    return <Redirect to="/not-found" />;
  } else {
    return (
      <div className="Hub">
        <div
          className={`hub-loader-overlay ${showLoaderOpacityAnimationClass} ${removeLoaderFromDOM}`}
        >
          <div className="HubLoadingContainer">
            <img src={KambeoLoaderGIF} />
          </div>
        </div>
        {state.userHasEditPermission && props.location.search.includes('tour') && renderJoyRide()}
        {props.children && ownerObject && ownerObject.ownerId && props.children(ownerObject)}

        {/* Renders Profile image, Tabs for Pages, and Action Buttons */}
        <div className="hub-header">
          {isUserLoggedIn && state.userHasEditPermission && renderEditModeHeader()}
          <div
            ref={headerTitleContainerRef}
            className={`hub-title-container`}
          >
            {isEditable() &&
              renderEditOverlay({
                onEdit: () => {},
              })}
            {/* Renders the Header Profile image, and actions */}
            <div className="detail-container">
              <div className="top">
                <h1 notranslate="yes">{state.hub?.title || 'Hub Title'}</h1>
                <span className="hub-handle">
                  <span notranslate="yes">@{state.hub?.handle}</span>
                  <span>&middot;</span>
                  {!state.userHasEditPermission && (
                    <span
                      className="follow-link"
                      onClick={() => toggleFollowHub()}
                    >
                      {state.doesUserFollowHub ? ' Unfollow' : ' Follow'}
                    </span>
                  )}
                  {state.userHasEditPermission && (
                    <PopupMenu
                      showMenuConfig={popupMenuConfigStates}
                      menuItems={contextMenuItemsStatuses}
                      popupMenuClass="applications-popupMenu-states"
                      className="Dropdown filter-dropdown states"
                      onClick={() => setShowContextMenuStates(!showContextMenuStates)}
                    >
                      <div
                        className="input-wrap"
                        ref={statusesDropdown}
                      >
                        <span className={`label ${state.hub?.status?.code}`}>
                          {state.hub?.status?.code}
                        </span>
                        <i className="fas fa-caret-down menu-btn" />
                      </div>
                    </PopupMenu>
                  )}
                </span>
              </div>
            </div>

            <div className="user-search-container">{/* <UserSearch /> */}</div>

            <div className="right-header-container">
              {state.hub && (
                <RightHeader
                  objectItem={state.hub}
                  userHasEditPermission={state.userHasEditPermission}
                  objectType={Constants.object_type.hub}
                  runEditMode={() => toggleEditMode(true)}
                  openManageComponentsModal={() => toggleManageComponentsModal()}
                  openThemesModal={() => toggleThemeModal()}
                  openInviteUsersModal={() => toggleInviteModal()}
                  showHeaderEditMenu={editMenuOpen}
                  showUserMenuModal={showUserMenuModal}
                  setShowUserMenuModal={setShowUserMenuModal}
                />
              )}
            </div>
          </div>

          {/* This portion renders tabs */}
          <div
            ref={headerMenuRef}
            className={`hub-menu ${showUserMenuModal ? 'is-showing-user-menu' : ''}`}
            style={state.themeObjectByType.primaryBg}
          >
            <div className="hub-logo-container">
              <Portrait
                size={94}
                source={state.hub?.profile_image_url}
              />
            </div>
            <ul>{renderPageTabs(state.hubPages)}</ul>
          </div>
          {/* This portion renders the Header Banner */}
          <div
            ref={mainImgContainerRef}
            className={`main-image-container ${isEditable() ? 'editable' : ''} ${state.activePageIndex !== 0 ? 'shrink' : ''}`}
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${state.hub?.banner?.image}')`,
            }}
          >
            {isEditable() &&
              renderEditOverlay({
                onEdit: () => {
                  toggleBannerManageModal();
                },
              })}
            <div
              className={`inner-header-content ${state.hub?.banner?.layout ? state.hub.banner.layout : ''}`}
            >
              <h1 notranslate="yes">{state.hub?.banner?.banner_info?.title || 'Banner Title'}</h1>
              <div className="desc">
                <p notranslate="yes">
                  {state.hub?.banner?.banner_info?.description || 'Banner Description'}
                </p>
              </div>
              {/*<Link to="">Learn More.</Link>*/}
              <div className="btn-container">
                {state.hub?.call_to_action_text?.fundraise && (
                  <Button
                    buttonClass={userLanguage ? `header-button ${userLanguage}` : `header-button`}
                    onClick={() => {
                      history.push('/onboarding/event');
                    }}
                    style={state.themeObjectByType.primaryBgWithText}
                  >
                    Fundraise
                  </Button>
                )}
                {state.hub?.call_to_action_text?.donate && (
                  <Button
                    buttonClass={userLanguage ? `header-button ${userLanguage}` : `header-button`}
                    onClick={() => dispatchToggleModalByKey('showDonateModal', true)}
                    style={state.themeObjectByType.primaryBgWithText}
                  >
                    Donate
                  </Button>
                )}

                {state.hub?.call_to_action_text?.volunteer && (
                  <Button
                    buttonClass={userLanguage ? `header-button ${userLanguage}` : `header-button`}
                    onClick={() => handleUpdateActivePageIndex(state.hubPages[4], 4)}
                    style={state.themeObjectByType.primaryBgWithText}
                  >
                    Volunteer
                  </Button>
                )}
              </div>
            </div>
          </div>

          {trialFeature && state?.hub?.handle && trialFeature.trial_end_date && (
            <HubTrailWarningBanner
              trial_end_date={trialFeature.trial_end_date}
              handle={state.hub.handle}
            />
          )}
        </div>
        {/* Renders Pages based on selected Tab */}
        <div className="main-hub-content-container">
          {renderActivePageContainer(state.hubPages[state.activePageIndex])}
        </div>
        <div
          className="hub-footer"
          style={state.themeObjectByType.primaryBgWithText}
        >
          <span className="made-by">
            Made with <i className="fas fa-heart" /> by <var data-var="kambeo">Kambeo</var>
          </span>
          <span>
            © <var data-var="year">{new Date().getFullYear()}</var>{' '}
            <var data-var="kambeo_llc"> Kambeo, LLC. </var> All rights reserved.
          </span>
          <div className="social-container">
            <Link
              to={{ pathname: uiConstants.urls.kambeoFacebook }}
              target="_blank"
              notranslate="yes"
            >
              <i
                className="fab fa-facebook-f"
                style={state.themeObjectByType.primaryBgWithText}
              />
            </Link>
            <Link
              to={{ pathname: uiConstants.urls.kambeoTwitter }}
              target="_blank"
              notranslate="yes"
            >
              <i
                className="fab fa-twitter"
                style={state.themeObjectByType.primaryBgWithText}
              />
            </Link>
            <Link
              to={{ pathname: uiConstants.urls.kambeoInstagram }}
              target="_blank"
              notranslate="yes"
            >
              <i
                className="fab fa-instagram"
                style={state.themeObjectByType.primaryBgWithText}
              />
            </Link>
            <Link
              to={{ pathname: uiConstants.urls.kambeoLinkedIn }}
              target="_blank"
              notranslate="yes"
            >
              <i
                className="fab fa-linkedin-in"
                style={state.themeObjectByType.primaryBgWithText}
              />
            </Link>
          </div>
        </div>

        {state.userHasEditPermission && isUserLoggedIn && (
          <>
            <HubModalManage
              show={state.showManageModal}
              onClose={toggleManageModal}
            />
            {state.ownerObject && (
              <AddMemberModal
                owner={state.ownerObject}
                showAddMemberModal={state.showInviteModal}
                toggleShowAddMemberModal={function (newToggleValue: boolean): void {
                  toggleInviteModal();
                }}
                hubSeatsAvailable={hubSeatsAvailable}
                objectType={'hub'}
              />
            )}
            {state.ownerObject && (
              <HubInviteMembers
                owner={state.ownerObject}
                showAddMemberModal={state.showInviteModal}
                toggleShowAddMemberModal={function (newToggleValue: boolean): void {
                  toggleInviteModal();
                }}
                hubSeatsAvailable={hubSeatsAvailable}
                objectType="hub"
              />
            )}

            {/*
                            NOTE: Commented out since the design and features were unclear in the task, using existing invite modal instead
                            Task: https://app.clickup.com/t/8404472/GIG-5281

                            <HubModalInvite
                                show={state.showInviteModal}
                                onClose={toggleInviteModal}
                            />
                        */}
            <SocialImpactBenefitsModal
              hub={state.hub}
              showModal={state.showSocialImpactBenefitsModal}
              tryRefreshHub={tryRefreshHub}
              onClose={toggleSocialImpactBenefitsModal}
            />
            <HubModalTheme
              hub={state.hub}
              tryRefreshHub={tryRefreshHub}
              showModal={state.showThemeModal}
              onClose={toggleThemeModal}
            />
            <HubModalManageComponents
              hubId={state.hub?.id || null}
              show={state.showManageComponentsModal}
              onClose={toggleManageComponentsModal}
              pageId={state.hubPages.find((page) => page.label === 'Home')?.id || ''}
              pageComponents={
                state.hubPages.find((page) => page.label === 'Home')?.components || []
              }
              isHubPagesLoading={state.isHubPagesLoading}
              fetchPagesAfterSave={async () => {
                if (state.hub?.id) {
                  await fetchHubPages(state.hub.id);
                }
              }}
            />
            <HubModalAddComponents
              show={state.showAddComponentsModal}
              onClose={toggleAddComponentsModal}
            />
            <HubBannerManage
              onChange={async () => {
                await tryRefreshHub();
              }}
              hub={state.hub}
              show={state.showBannerEditModal}
              onClose={toggleBannerManageModal}
            />
          </>
        )}

        <HubModalDonate
          showDonateModal={state.showDonateModal}
          onClose={() => dispatchToggleModalByKey('showDonateModal', false)}
          hub={state.hub}
        />

        {state.hub && (
          <HubOnBoardingGuide
            hub={state.hub}
            hubSubscriptions={state.hubSubscriptions}
            show={showOnBoardingGuide}
            onClose={handleCloseHubOnboardingGuide}
            fetchHubOnFinish={() => fetchHub(state.hub?.handle || '')}
          />
        )}
      </div>
    );
  }
}

export default Hub;
