import React, { Fragment } from 'react';

import { Constants } from '@gigit/constants';
import {
  IAuctionItemBidder,
  IAuctionItemBidderSummary,
  IAuctionItemSummary,
  ICampaign,
} from '@gigit/interfaces';

import Modal from '../../Modal/Modal';
import ModalScrollContainer from '../../Modal/ModalScrollContainer/ModalScrollContainer';
import {
  defaultCurrency,
  formatCurrency,
  routes,
  swapRouteParams,
  toastError,
  toastSuccess,
} from '../../../helpers';
import axios from 'axios';
import errorHelpers from '../../../helpers/errorHelpers';
import ContextMenu, { IContextMenuItem } from '../../ContextMenu/ContextMenu';
import AuctionItemBuyNowModal, {
  IAuctionManualPaymentDetails,
} from '../../PageComponent/AuctionItem/AuctionItemBuyNowModal/AuctionItemBuyNowModal';
import auctionItemHelpers from '../../PageComponent/AuctionItem/auctionItemHelpers';
import Portrait from '../../Portrait/Portrait';
import { IToast } from '../../../interfaces';
import { IAppState } from '../../../store';
import { connect } from 'react-redux';
import { createToast } from '../../../actions/toaster';
import { userSelectors } from '../../../selectors/user';
import { IGroupState } from '../../../reducers/group';
import { IEventState } from '../../../reducers/event';
import { localizeHelpers } from '../../../localizeHelpers';

import './AuctionAdminLeaderboardModal.scss';
import { IOwnerObject } from '../../../interfaces/ownerObject';

interface IProps {
  groupState: IGroupState;
  eventState: IEventState;
  owner: IOwnerObject;
  showLeaderModal: boolean;
  leaderItem: IAuctionItemSummary | null;
  locale: string;

  onClose(): void;
  onManualAdminPayment(userId: string, itemId: string): void;
  onWinnerSet(): void;
  isPaymentPendingForBidder(bidder: IAuctionItemBidder): boolean;

  createToast(toast: IToast): void;
}

interface IState {
  showBuyNowModal: boolean;
  manualPaymentDetails: IAuctionManualPaymentDetails | null;
}

class AuctionAdminLeaderboardModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showBuyNowModal: false,
      manualPaymentDetails: null,
    };
  }

  setAuctionWinnerFromBidder(bidder: IAuctionItemBidderSummary) {
    const leaderItem = this.props.leaderItem;

    if (leaderItem) {
      this.setAuctionWinner(leaderItem.id!, bidder.user_id);
    }
  }

  setAuctionWinner(itemId: string, userId: string) {
    const route =
      this.props.owner.ownerType === 'group'
        ? routes.SET_AUCTION_WINNER
        : routes.SET_EVENT_AUCTION_WINNER;
    const idField = this.props.owner.ownerType === 'group' ? 'groupId' : 'eventId';

    axios
      .post(
        swapRouteParams(route, {
          [idField]: this.props.owner.ownerId,
          itemId: itemId,
          userId: userId,
        }),
      )
      .then((response) => {
        this.props.onWinnerSet();
      })
      .catch((error: any) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Auction Item');
        this.props.createToast(toast);
      });
  }

  onAuctionAdminManualPayment = (userId: string, itemId: string) => {
    this.setState({
      showBuyNowModal: false,
    });

    this.props.onManualAdminPayment(userId, itemId);
  };

  sendAuctionConfirmationEmail() {
    const baseRoute =
      this.props.owner.ownerType === 'group'
        ? routes.SEND_GROUP_AUCTION_CONFIRMATION_EMAIL
        : routes.SEND_EVENT_AUCTION_CONFIRMATION_EMAIL;
    const idField = this.props.owner.ownerType === 'group' ? 'groupId' : 'eventId';

    let route = swapRouteParams(baseRoute, {
      [idField]: this.props.owner.ownerId,
      item_id: this.props.leaderItem?.id,
    });

    axios
      .post(route)
      .then((response) => {
        const toast = toastSuccess(
          localizeHelpers.translate('Confirmation email successfully sent.'),
          'Confirmation Email',
        );
        this.props.createToast(toast);
      })
      .catch((error) => {
        const errObbj = errorHelpers.getErrorObject(error);
        const toast = toastError(
          errObbj.translatedMessage,
          'Send Auction Winner Confirmation Email',
        );
        this.props.createToast(toast);
      });
  }

  renderRow(bidder: IAuctionItemBidderSummary, index: number) {
    const leaderItem = this.props.leaderItem;
    if (!leaderItem) {
      return null;
    }

    const currency = this.props.owner.account?.currency ?? defaultCurrency;

    const menuItems: IContextMenuItem[] = [];

    if (auctionItemHelpers.canAdminManuallyPay(leaderItem)) {
      menuItems.push({
        onClick: () => {
          const nameParts = (bidder.display_name ?? '').split(' ');
          this.setState({
            showBuyNowModal: true,
            manualPaymentDetails: {
              id: bidder.user_id,
              email: bidder.email || '',
              firstName: nameParts[0],
              lastName: nameParts[1],
              phone: bidder.phone,
            },
          });
        },
        icon: 'fas fa-dollar-sign',
        label: 'Pay Now',
      });
    }

    if (
      leaderItem.status?.code === Constants.auction_item_status.closed &&
      leaderItem?.current_bid &&
      leaderItem?.current_bid.handle === bidder.handle
    ) {
      menuItems.push({
        onClick: () => {
          this.sendAuctionConfirmationEmail();
        },
        icon: 'fas fa-envelope',
        label: 'Resend Confirmation',
      });
    }

    return (
      <div
        key={index}
        className="leader"
      >
        <Portrait
          size={60}
          currentImage={bidder.profile_image_url || ''}
        />
        <div className="leader-details">
          <div
            className="name"
            notranslate="yes"
          >
            <span>{bidder.display_name}</span>
            {(leaderItem.status?.code === Constants.auction_item_status.closed ||
              leaderItem.payment_status?.status_by?.handle === bidder.handle) &&
              leaderItem?.current_bid && (
                <div>
                  {leaderItem.current_bid.handle === bidder.handle && (
                    <div className="winner">
                      <i className="fa fa-check-circle"></i>
                      <span>Winner</span>
                    </div>
                  )}

                  {leaderItem.current_bid.handle !== bidder.handle &&
                    !(this.props.leaderItem?.payment_status?.code === 'paid') && (
                      <div
                        onClick={() => {
                          this.setAuctionWinnerFromBidder(bidder);
                        }}
                        className="set-winner"
                      >
                        {' '}
                        <span>Set as winner</span>
                      </div>
                    )}
                </div>
              )}
          </div>
          <div className="bid">
            <span notranslate="yes">
              {formatCurrency(bidder.highest_bid_amount, currency, this.props.locale)}
            </span>

            {this.props.isPaymentPendingForBidder(bidder) && (
              <span className="payment-status payment-pending">
                <i className="fas fa-spinner fa-spin"></i>
                Pending Payment
              </span>
            )}
            {leaderItem?.current_bid &&
              leaderItem?.current_bid.handle === bidder.handle &&
              leaderItem?.payment_status?.code === 'paid' && (
                <span className={'payment-status paid'}>
                  <i className={'fal fa-check'}> </i>Paid
                </span>
              )}
          </div>
          <div className="count">
            <var data-var="number_of_bids">{bidder.number_of_bids}</var> bid(s)
          </div>
        </div>
        <div className="right">
          {bidder.phone && (
            <a
              href={'tel:' + bidder.phone}
              className="contact phone"
            >
              <i className="far fa-phone-volume"></i>
            </a>
          )}
          {bidder.email && (
            <a
              href={'mailto:' + bidder.email}
              className="contact email"
            >
              <i className="far fa-envelope"></i>
            </a>
          )}

          {menuItems.length > 0 && (
            <div className="contact context-menu">
              <i className="far fa-ellipsis-h-alt"></i>
              <ContextMenu
                onMouseLeave={() => {}}
                showMenu={true}
                menuItems={menuItems}
              />
            </div>
          )}
        </div>
        <div className="place">{index + 1}</div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <Modal
          class="AuctionAdminLeaderboardModal"
          show={this.props.showLeaderModal}
          onClose={() => this.props.onClose()}
          title={'Auction Leaderboard'}
          description={this.props.leaderItem?.name}
          notranslateDecription="yes"
        >
          {this.props.leaderItem && (
            <div className="leaderboard-modal">
              <ModalScrollContainer>
                <div className="leaders">
                  {this.props.leaderItem?.bidders &&
                    this.props.leaderItem?.bidders.map((bidder, index) =>
                      this.renderRow(bidder, index),
                    )}

                  {this.props.leaderItem?.bidders?.length === 0 && (
                    <div className="no-bids">No one has bid on this item yet.</div>
                  )}
                </div>
              </ModalScrollContainer>
            </div>
          )}
        </Modal>

        {this.props.leaderItem && (
          <AuctionItemBuyNowModal
            showBuyNowModal={this.state.showBuyNowModal}
            onClose={() => this.setState({ showBuyNowModal: false, manualPaymentDetails: null })}
            handleOnSuccessfulSubmitPayment={this.onAuctionAdminManualPayment}
            item={this.props.leaderItem}
            isManualPayment={true}
            manualPaymentDetails={this.state.manualPaymentDetails!}
            owner={this.props.owner}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
    groupState: store.groupState,
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuctionAdminLeaderboardModal);
