import React, { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { combineClassNames } from '../../../helpers';
import './Link.scss';

interface IProps<S> extends LinkProps<S> {
  // TODO: Need to think of a better type, normal is an unstyled link
  styleType?: 'grey' | 'normal';
  /** Applies underline style to link, defaults to false */
  hasUnderline?: boolean;
  className?: string;
  children?: ReactNode;
}

function DefaultLink<S>(props: IProps<S>) {
  function getClassNames() {
    const linkWithUnderLine = props.hasUnderline ? 'with-underline' : '';
    return combineClassNames('DefaultLink', props.styleType, props.className, linkWithUnderLine);
  }

  return (
    <Link
      className={getClassNames()}
      to={props.to}
    >
      {props.children}
    </Link>
  );
}

export default DefaultLink;
