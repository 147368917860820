import React, { createContext, useState } from 'react';
import Modal from '../components/Modal/Modal';
import { OwnerType, typeHelpers } from '../helpers/typeHelpers';
import { Config } from '@gigit/config';
import { uiConstants } from '../constants';
import Share from '../components/Share/Share';
import { OwnerObjectTypes } from '../interfaces';
import { IGig } from '@gigit/interfaces';
import VolunteerApplicationForm from '../components/VolunteerApplicationForm/VolunteerApplicationForm';
import { IFullForm } from '../reducers/group';

export enum ESharedModalType {
  SHARE,
  VOLUNTEER_APPLICATION_FORM,
}

export interface ISharedModalShareData {
  ownerType: OwnerType;
  ownerObject: OwnerObjectTypes;
  handle: string;
  title?: string;
  modalClassName?: string;
}

export interface ISharedModalVolunteerApplicationFormData {
  gig: IGig;
  currentGigForm?: IFullForm;
}

interface ISharedModalContextState {
  showModal?: boolean;
  modalType?: ESharedModalType;
  title?: string;
  className?: string;
  shareModalContent?: ISharedModalShareData;
  volunteerApplicationFormModalContent?: ISharedModalVolunteerApplicationFormData;
}

interface ISharedModalContext {
  state: ISharedModalContextState;
  setState: React.Dispatch<React.SetStateAction<ISharedModalContextState>>;
}

const initState = { showModal: false };
const SharedModalContext = createContext<Partial<ISharedModalContext>>({ state: initState });
const SharedModalContextProvider: React.FC = (props) => {
  const [state, setState] = useState<ISharedModalContextState>(initState);

  function renderModalComponent() {
    switch (state.modalType) {
      case ESharedModalType.SHARE:
        if (state?.shareModalContent) {
          return (
            <Share
              title={state?.shareModalContent.title}
              owner_object={typeHelpers.createOwnerObject(
                state?.shareModalContent.ownerType,
                state?.shareModalContent.ownerObject,
              )}
              url={`${Config.web.REACT_APP_BASE_URL}/${state?.shareModalContent.ownerType}/${state?.shareModalContent.handle}`}
            />
          );
        }
        return;
      case ESharedModalType.VOLUNTEER_APPLICATION_FORM:
        if (state?.volunteerApplicationFormModalContent) {
          return (
            <VolunteerApplicationForm
              gig={state.volunteerApplicationFormModalContent.gig}
              onClose={() => setState({ showModal: false })}
            />
          );
        }
        return;
    }
  }
  return (
    <SharedModalContext.Provider value={{ state, setState }}>
      {props.children}

      {state.showModal && (
        <Modal
          closeIcon="fas fa-times"
          show={state.showModal}
          onClose={() => setState({ showModal: false })}
          title={state?.title}
          class={`SharedModal ${state.className || ''}`}
        >
          {renderModalComponent()}
        </Modal>
      )}
    </SharedModalContext.Provider>
  );
};

export { SharedModalContext, SharedModalContextProvider };
