import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CoverImage from '../../../components/CoverImage/CoverImage';
import Portrait from '../../../components/Portrait/Portrait';
import QuillTextEditor from '../../../components/QuillTextEditor/QuillTextEditor';
import { uiConstants } from '../../../constants';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { userSelectors } from '../../../selectors/user';
import { IAppState } from '../../../store';
import { getSearchRouteFromCategory, searchCategoryOptions } from '../Config';

import './SearchResultPreview.scss';

const fullPreviewImageSize = 150;
const mobilePreviewImageSize = 60;
interface IPreviewData {
  title: string;
  handle: string;
  description: string;
  cover_image_url?: string;
  profile_image_url?: string;
  start_date?: Date | string | undefined;
  accepting_donations?: boolean | undefined;
  accepting_volunteers?: boolean | undefined;
}
interface ISearchResultPreview {
  previewData: IPreviewData;
  type: string; //'group' | 'event' | 'gig'
  showBack: boolean;

  onClose: () => void;
}
export default function SearchResultPreview(props: ISearchResultPreview) {
  const {
    accepting_donations,
    accepting_volunteers,
    handle,
    title,
    description,
    cover_image_url,
    profile_image_url,
    start_date,
  } = props.previewData;
  const isLoggedIn = useSelector((state: IAppState) => userSelectors.isUserAuthenticated(state));
  const history = useHistory();

  function handleApply(e: any) {
    if (isLoggedIn) {
      if (e && e.target) {
        e.target.click();
      }
    } else {
      e.preventDefault();
      history.push(`/login?redirect=/group/${props.previewData.handle}/volunteer`);
    }
  }

  function renderActionButtons() {
    const route = getSearchRouteFromCategory(props.type);
    return (
      <div className="action">
        <Link to={`/${route}/${handle}`}>View</Link>
        {((accepting_donations && accepting_volunteers) || accepting_donations) && (
          <Link to={`/${route}/${handle}/donate`}>Donate</Link>
        )}
        {accepting_volunteers && !accepting_donations && (
          <Link
            onClick={(e) => handleApply(e)}
            to={`/${route}/${handle}/volunteer`}
          >
            Volunteer
          </Link>
        )}
      </div>
    );
  }

  function getTypeSection() {
    switch (props.type) {
      case 'groups':
        return 'Non-Profit | Cause';
      case 'events':
        // TODO: fix locale
        return localizeHelpers.formatDate(start_date || '', LocaleDateFormats.LLL, 'CA');
      case 'gigs':
        return 'Volunteer Opportunity';
    }
  }

  // todo make helper?
  function getLabel() {
    switch (props.type) {
      case 'groups':
        return 'cause';
      case 'events':
        return 'event';
      case 'gigs':
        return 'volunteer opportunity';
    }
  }

  return (
    <div className={`SearchResultPreview groups`}>
      {props.showBack && (
        <div
          className="back-to-results"
          onClick={() => props.onClose()}
        >
          <i className="fa fa-arrow-left"></i> Back to results
        </div>
      )}
      <Link to={`/${getSearchRouteFromCategory(props.type)}/` + handle}>
        <CoverImage currentImage={cover_image_url} />
        {/* TODO: naming could be better */}
        <Portrait
          size={props?.showBack ? fullPreviewImageSize : mobilePreviewImageSize}
          currentImage={profile_image_url}
        />
      </Link>
      <div className="info">
        <div className="type">{getTypeSection()}</div>
        <Link
          to={`/${getSearchRouteFromCategory(props.type)}/${handle}`}
          className="title"
          notranslate="yes"
        >
          {title}
        </Link>
      </div>
      <div className="description">
        <div className="description-inner">
          {description ? (
            <QuillTextEditor
              value={description}
              readOnly={true}
              preserveWhitespace={true}
              theme={uiConstants.quill.readOnlyTheme}
            />
          ) : (
            <p>This {getLabel()} doesn't have a description.</p>
          )}
        </div>
      </div>
      {renderActionButtons()}
    </div>
  );
}
