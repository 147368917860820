//move to config
export const campaignConnectStep: string = 'connect';
export const isEvent: string = 'isEvent';
export const causeSelectionStep: string = 'causeSelection';
export const cta: string = 'cta';

export const groupSteps = {
  group: ['groupSelection', 'groupType', 'groupInfo', 'groupLocalization'],
  groupNew: ['groupType', 'groupInfo', 'groupLocalization'],
  groupNewCharity: [
    'groupType',
    'groupInfo',
    'groupLocalization',
    'groupClassifications',
    'isCharity',
  ],
  charity: [
    'groupSelection',
    'groupType',
    'groupInfo',
    'groupLocalization',
    'groupClassifications',
    'isCharity',
  ],
  existing: ['groupSelection'],
  existingCharity: ['groupSelection', 'isCharity'],
};

export const eventSteps = {
  event: ['eventSelection', 'eventType', 'eventInfo', 'eventTimeAndLocation'],
  noSelection: ['eventType', 'eventInfo', 'eventTimeAndLocation'],
};

export const auctionEventSteps = {
  event: [eventSteps.event[1], eventSteps.event[2], eventSteps.event[3]],
};

export const gigSteps = {
  gig: ['gigType', 'gigInfo', 'gigCategories', 'gigTimeAndLocation'],
};

export const volunteerSteps = {
  volunteer: ['volunteerInfo', 'volunteerEmergency', causeSelectionStep, cta],
  volunteerNoCta: ['volunteerInfo', 'volunteerEmergency', causeSelectionStep],
  volunteerNoEC: ['volunteerInfo', causeSelectionStep, cta],
  volunteerNoECNoCta: ['volunteerInfo', causeSelectionStep],
};

export const stepConfigurations = {
  groupSteps: [...groupSteps.groupNew, campaignConnectStep],
  groupStepsCharity: [...groupSteps.groupNewCharity, campaignConnectStep],
  donationsExistingGroup: [...groupSteps.existing, campaignConnectStep],
  donationsNewGroup: [...groupSteps.group, campaignConnectStep],
  donationsNewCharityGroup: [...groupSteps.charity, campaignConnectStep],
  donationsExistingCharityGroup: [...groupSteps.existingCharity, campaignConnectStep],
  storeExistingGroup: [...groupSteps.existing, isEvent, campaignConnectStep],
  storeExistingGroupExistingEvent: [
    ...groupSteps.existing,
    isEvent,
    eventSteps.event[0],
    campaignConnectStep,
  ],
  storeExistingGroupNewEvent: [
    ...groupSteps.existing,
    isEvent,
    ...eventSteps.event,
    campaignConnectStep,
  ],
  storeNewGroup: [...groupSteps.group, isEvent, campaignConnectStep],
  storeNewGroupCharity: [...groupSteps.charity, isEvent, campaignConnectStep],
  storeNewGroupNewEvent: [...groupSteps.group, isEvent, ...eventSteps.event, campaignConnectStep],
  storeNewGroupExistingEvent: [
    ...groupSteps.group,
    isEvent,
    eventSteps.event[0],
    campaignConnectStep,
  ],
  storeNewGroupNoEvent: [...groupSteps.group, isEvent, campaignConnectStep],
  storeExistingNoEvent: [...groupSteps.existing, isEvent, campaignConnectStep],
  auctionNewGroup: [...groupSteps.group, ...auctionEventSteps.event, campaignConnectStep],
  auctionExistingGroup: [...groupSteps.existing, ...auctionEventSteps.event, campaignConnectStep],
  find: [causeSelectionStep, cta],
  volunteer: [...volunteerSteps.volunteer],
  volunteerNoCta: [...volunteerSteps.volunteerNoCta],
  volunteerNoEC: [...volunteerSteps.volunteerNoEC],
  volunteerNoECNoCta: [...volunteerSteps.volunteerNoECNoCta],
  gigNewGroup: [...groupSteps.group, ...gigSteps.gig],
  gigExistingGroup: [...groupSteps.existing, ...gigSteps.gig],
  eventOrFundraiser: [...groupSteps.group, ...eventSteps.noSelection, campaignConnectStep],
  eventOrFundraiserExistingGroup: [
    ...groupSteps.existing,
    ...eventSteps.noSelection,
    campaignConnectStep,
  ],
  eventOrFundraiserOBO: [...groupSteps.existing, ...eventSteps.noSelection, campaignConnectStep],
  teamJoin: [
    'fundraiseType',
    'teamSelect',
    'goalIndividual',
    'tickets',
    'isContentCreator',
    'connectStravaAccount',
    'addParticipant',
  ],
  teamJoinNoTicket: [
    'fundraiseType',
    'teamSelect',
    'goalIndividual',
    'isContentCreator',
    'connectStravaAccount',
    'addParticipant',
  ],
  teamCreate: [
    'fundraiseType',
    'goalTeam',
    'goalIndividual',
    'tickets',
    'isContentCreator',
    'connectStravaAccount',
    'addParticipant',
  ],
  teamCreateNoTicket: [
    'fundraiseType',
    'goalTeam',
    'goalIndividual',
    'isContentCreator',
    'connectStravaAccount',
    'addParticipant',
  ],
  individual: [
    'fundraiseType',
    'goalIndividual',
    'tickets',
    'isContentCreator',
    'connectStravaAccount',
    'addParticipant',
  ],
  individualNoTicket: [
    'fundraiseType',
    'goalIndividual',
    'isContentCreator',
    'connectStravaAccount',
    'addParticipant',
  ],
};
