import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import Axios from 'axios';
import { capitalizeString } from '../../../helpers';

import { formatAddressLine, swapRouteParams, routes, toastError } from '../../../helpers';
import { IUserEmergencyContact } from '@gigit/interfaces/dist/user/user-emergency-contact.interface';
import { IUserRole, IDonor } from '@gigit/interfaces';

import { IAppState } from '../../../store';
import CoverImage from '../../CoverImage/CoverImage';
import Portrait from '../../Portrait/Portrait';
import { createToast } from '../../../actions/toaster';

import './ContactCard.scss';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  contact: IUserRole;
  groupId: string;
  createToast(toast: IToast): void;
}

interface IState {
  donor: IDonor | null;
}

class ContactCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      donor: null,
    };
  }

  componentDidMount() {
    this.getDonor();
  }

  getAddressPart() {
    let location = this.props.contact?.location;

    if (location?.city && location?.state && location?.postal_code) {
      return `${location.city}, ${location.state}, ${location.postal_code}`;
    } else {
      return formatAddressLine(location);
    }
  }

  getDonor() {
    Axios.get(
      swapRouteParams(routes.GET_DONOR, {
        groupId: this.props.groupId,
        contactId: this.props.contact.user?.id,
      }),
    )
      .then((response) => {
        this.setState({
          donor: response.data,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Donor Info');
        this.props.createToast(toast);
      });
  }

  render() {
    let { t } = this.props;
    let status = this.state.donor?.donor_status || '';

    return (
      <div className="ContactCard">
        <div className="user-card">
          <div className="images">
            <CoverImage currentImage={this.props.contact.user?.cover_image_url} />
            <Portrait
              source={this.props.contact.user?.profile_image_url}
              size={100}
            />
          </div>
          <div
            notranslate="yes"
            className="display-name"
          >{`${this.props.contact.user?.first_name} ${this.props.contact.user?.last_name}`}</div>
          <div className="status">
            <span className={`${status}`}>{capitalizeString(status || 'N/A')}</span>
          </div>
          <div className="user-data">
            <div
              notranslate="yes"
              className="user-location"
            >
              <div className="line1">
                {this.props.contact?.location?.line1} {this.props.contact?.location?.line2}
              </div>
              <div className="line2">{this.getAddressPart()}</div>
            </div>
            <div className="user-details">
              <div className="data-title">{t('Details')}</div>
              <div className="data-row">{capitalizeString(this.props.contact.user?.gender)}</div>
              {!this.props.contact.user?.is_dummy_user ? (
                <div
                  className="data-row"
                  notranslate="yes"
                >
                  {
                    <a href={`mailto:${this.props.contact.user?.email}`}>
                      {this.props.contact.user?.email}
                    </a>
                  }
                </div>
              ) : (
                <div className="data-row">No Email Provided</div>
              )}
              <div
                className="data-row"
                notranslate="yes"
              >
                {this.props.contact.user?.phone}
              </div>
            </div>
            {!!this.props.contact.user?.emergency_contacts?.length && (
              <div className="user-emergency">
                <div className="data-title">{t('Emergency Contacts')}</div>
                {this.props.contact.user?.emergency_contacts?.map(
                  (ec: IUserEmergencyContact, index: number) => {
                    return (
                      <div
                        key={index}
                        notranslate="yes"
                        className="data-row"
                      >
                        {ec.name} | {ec.phone}
                      </div>
                    );
                  },
                )}
              </div>
            )}
            {!!this.props.contact.user_capacity?.length && (
              <div className="user-capacities">
                <div className="data-title">{t('Capacities')}</div>
                {this.props.contact.user_capacity?.map((capacity: string, i: number) => (
                  <span
                    className={capacity}
                    key={i}
                  >
                    {capitalizeString(capacity)}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {
  createToast,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ContactCard),
);
