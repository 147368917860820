import React, { useState, useRef, useEffect, useReducer } from 'react';
import { uiConstants } from '../../../../../constants';
import { IRecommendedFocusAreas } from '../../../../../helpers';
import Button from '../../../../Button/Button';
import './CommunityFeedFocusAreas.scss';

interface IProps {
  focusAreas: IRecommendedFocusAreas[];
  toggleFocusArea: (id?: string) => void;
}

const CommunityFeedFocusAreas = (props: IProps) => {
  const [fullList, setFullList] = useState<boolean>(false);
  const [focusAreaList, setHubFocusAreasList] = useState<IRecommendedFocusAreas[]>([]);

  useEffect(() => {
    if (props.focusAreas && props.focusAreas.length) {
      setHubFocusAreasList(props.focusAreas);
    }
  }, [props.focusAreas]);

  const renderFocusAreaChip = (focusArea: IRecommendedFocusAreas, index: number) => {
    return (
      <div
        onClick={() => props.toggleFocusArea(focusArea.id)}
        className={`focus-area-chip ${focusArea.selected ? 'selected' : ''}`}
        key={index}
      >
        {focusArea.selected ? (
          <i className="fas fa-check"></i>
        ) : (
          <img
            className="focus-area-icon"
            src={focusArea.icon_url}
          />
        )}

        <span className="focus-area-name">{focusArea.cause}</span>
      </div>
    );
  };

  return (
    <div className="CommunityFeedFocusAreas">
      <div className="focus-area-title">Recommended Focus Areas</div>
      <div className="focus-area-list">
        {focusAreaList.map((focusArea, index) => {
          if (!fullList) {
            if (index < uiConstants.communityFeed.focusAreaListDefault) {
              return renderFocusAreaChip(focusArea, index);
            }
          } else {
            return renderFocusAreaChip(focusArea, index);
          }
        })}
      </div>
      <div className="focus-area-action">
        <Button
          onClick={() => setFullList(!fullList)}
          buttonType="outline-dark"
          className="alt log-button"
        >
          {fullList ? 'View Less' : 'View All'}
        </Button>
      </div>
    </div>
  );
};

export default CommunityFeedFocusAreas;
