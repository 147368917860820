import './HubAuctionDetailItemModal.scss';
import React, { useEffect, useState } from 'react';
import Modal from '../../../Modal/Modal';
import {
  ConvertToFrontendType,
  IAuctionItemSummary,
  IEmbeddedAccount,
  IEventSummaryFE,
  IGroup,
  IHub,
} from '@gigit/interfaces';
import Button from '../../../Button/Button';
import CheckDecagram from '../../../../assets/chech-decagram.svg';
import { Link, useHistory } from 'react-router-dom';
import { localizeHelpers, LocaleDateFormats } from '../../../../localizeHelpers';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { connect, useSelector } from 'react-redux';
import { defaultCurrency, formatCurrency } from '../../../../helpers';
import AuctionitemLeaderDisplay from '../../../PageComponent/AuctionItem/AuctionItemLeaderDisplay/AuctionitemLeaderDisplay';
import AuctionItemPlaceBidModal from '../../../PageComponent/AuctionItem/AuctionItemPlaceBidModal/AuctionItemPlaceBidModal';
import { Constants } from '@gigit/constants';
import AuctionItemBuyNowModal from '../../../PageComponent/AuctionItem/AuctionItemBuyNowModal/AuctionItemBuyNowModal';
import { eventRequestActions, groupRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';
import moment from 'moment';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import auctionItemHelpers from '../../../PageComponent/AuctionItem/auctionItemHelpers';

interface IProps {
  hubHandle: string | null;
  show: boolean;
  getHubAuctionItems(): void;
  onCloseDetailModal(): void;
  auctionItem: IAuctionItemSummary;
  hubName?: string;
  hubLogoUrl?: string;
  locale: string;
}

const ACTION_BID = 'bid';
const ACTION_BUY = 'buy';

const HubAuctionDetailItemModal: React.FC<IProps> = (props) => {
  const { dispatchToastError } = useToastDispatcher();

  const userData = useSelector((appState: IAppState) => appState.userState);
  const history = useHistory();

  const [ownerInfo, setOwnerInfo] = useState<IGroup | IEventSummaryFE | null>(null);
  const [accountInfo, setAccountInfo] = useState<IEmbeddedAccount | undefined>(undefined);
  const [currency, setCurrency] = useState<string>(defaultCurrency);
  const [showBuyItemModal, setShowBuyItemModal] = useState<boolean>(false);
  const [showPlaceBidModal, setShowPlaceBidModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<'bid' | 'buy_now'>('bid');
  const [activeImgIndex, setActiveImgIndex] = useState<number>(0);
  const [activeImg, setActiveImg] = useState<string>(
    props.auctionItem.media.length > 0 ? props.auctionItem?.media[activeImgIndex]?.url || '' : '',
  );

  //time remaining
  const [isActive, setIsActive] = useState<boolean>(
    new Date(props.auctionItem.end_date).getTime() - new Date().getTime() > 0,
  );
  const secondsInDay = 86400;
  const secondsInHour = 3600;
  const secondsInMinute = 60;
  const hoursInDay = 24;
  const tmpTimer =
    parseInt(moment(props.auctionItem.end_date).format('X')) -
    parseInt(moment(new Date()).format('X'));

  const [tick, setTick] = useState<ReturnType<typeof setInterval> | null>(null);

  const [timeRemainingDays, setTimeRemainingDays] = useState<number | null>(
    isActive ? Math.floor(tmpTimer / secondsInDay) : null,
  );
  const [timeRemainingHours, setTimeRemainingHours] = useState<number | null>(
    isActive
      ? Math.floor(tmpTimer / secondsInHour) - Math.floor(tmpTimer / secondsInDay) * hoursInDay
      : null,
  );
  const [timeRemainingMinutes, setTimeRemainingMinutes] = useState<number | null>(
    isActive
      ? Math.floor(tmpTimer / secondsInMinute) -
          Math.floor(tmpTimer / secondsInHour) * secondsInMinute
      : null,
  );
  const [timeRemainingSeconds, setTimeRemainingSeconds] = useState<number | null>(
    isActive ? tmpTimer - Math.floor(tmpTimer / secondsInMinute) * secondsInMinute : null,
  );

  // TODO: ask BE to add all owner data to this interface not only owner_type and owner_id
  function getGroupInfo() {
    if (props.auctionItem?.owner_id) {
      groupRequestActions
        .getGroupByHandleOrId(props.auctionItem.owner_id)
        .then((res) => {
          setOwnerInfo(res);
          if (res?.account?.currency) {
            setAccountInfo(res.account);
            setCurrency(res.account.currency);
          }
        })
        .catch((error) => dispatchToastError(error, 'Get Cause Info'));
    }
  }

  function getEventInfo() {
    if (props.auctionItem?.owner_id) {
      eventRequestActions
        .getEventByHandleOrId(props.auctionItem.owner_id)
        .then((res) => {
          setOwnerInfo(res);
          if (res?.group?.account) {
            setAccountInfo(res.group.account);
          }
          if (res.hub?.account) {
            setAccountInfo(res.hub.account);
          }
          if (res?.campaign?.account?.currency) {
            setCurrency(res.campaign.account.currency);
          }
        })
        .catch((error) => dispatchToastError(error, 'Get Cause Info'));
    }
  }

  function canUserBid(action: string) {
    if (userData.isLoggedIn) {
      if (action === ACTION_BID) {
        setShowPlaceBidModal(true);
      }

      if (action === ACTION_BUY) {
        setShowBuyItemModal(true);
      }
    } else {
      if (props.hubHandle) {
        let redirect = encodeURIComponent(
          `/company/${props.hubHandle}?page=Auction&item=${props.auctionItem.id}`,
        );
        let url = `/login?redirect=${redirect}`;
        history.push(url);
      }
    }
  }

  useEffect(() => {
    if (props.auctionItem?.owner_type === Constants.object_type.group) {
      getGroupInfo();
    } else {
      getEventInfo();
    }
  }, [props.auctionItem]);

  useEffect(() => {
    if (!tick && isActive) {
      setTick(
        setInterval(() => {
          const timer =
            parseInt(moment(props.auctionItem.end_date).format('X')) -
            parseInt(moment(new Date()).format('X'));

          setTimeRemainingDays(Math.floor(timer / secondsInDay));
          setTimeRemainingHours(
            Math.floor(timer / secondsInHour) - Math.floor(timer / secondsInDay) * hoursInDay,
          );
          setTimeRemainingMinutes(
            Math.floor(timer / secondsInMinute) -
              Math.floor(timer / secondsInHour) * secondsInMinute,
          );
          setTimeRemainingSeconds(timer - Math.floor(timer / secondsInMinute) * secondsInMinute);

          setIsActive(new Date(props.auctionItem.end_date).getTime() - new Date().getTime() > 0);
        }, 1000),
      );
    }

    return () => {
      if (tick) {
        clearInterval(tick);
        setTick(null);
        setTimeRemainingDays(null);
        setTimeRemainingHours(null);
        setTimeRemainingMinutes(null);
        setTimeRemainingSeconds(null);
      }
    };
  }, [tick]);
  return (
    <>
      <Modal
        class="HubAuctionDetailItemModal"
        show={props.show}
        onClose={props.onCloseDetailModal}
        closeIcon="fas fa-times"
        title=" "
      >
        <div className="header">
          <img
            src={props.hubLogoUrl}
            alt="hub logo"
            className="hub-logo"
          />
          <div className="head-content">
            <div className="title">
              <h3>{props?.hubName || 'N/A'}</h3>
              <span className="checked">
                <img
                  src={CheckDecagram}
                  alt="check mark"
                  width="24px"
                  height="24px"
                />
              </span>
            </div>
            <span className="category">
              {`Auction > ${props.auctionItem.owner_type === Constants.object_type.group ? 'Partner' : 'Company'} Items`}
            </span>
          </div>
        </div>
        <div className="main-container">
          <div className="media-container">
            <img
              src={activeImg}
              alt="main, store item"
              className="main"
            />
            <div className="pick-img-container">
              {props.auctionItem.media.map((img, index) => (
                <img
                  src={img.url}
                  alt={`auction item ${index}`}
                  key={index}
                  onClick={() => setActiveImg(img.url)}
                />
              ))}
            </div>
          </div>
          <div className="desc-container">
            <h4 notranslate="yes">{props.auctionItem.name}</h4>
            <span
              className="owner"
              notranslate="yes"
            >
              <Link to={`/${props.auctionItem.owner_type}/${props.auctionItem.owner_handle}`}>
                {props.auctionItem?.owner_title || 'N/A'}
              </Link>
            </span>

            {props.auctionItem?.category && (
              <div className="tags-container">
                <span className="tag">{props.auctionItem.category}</span>
              </div>
            )}

            <span
              className="price"
              notranslate="yes"
            >
              {`Current bid : `}
              <span className="red">
                <var data-var="bid_amount">
                  {formatCurrency(
                    props.auctionItem?.current_bid?.bid_amount || 0,
                    currency,
                    props.locale,
                  )}
                </var>
              </span>
            </span>
            {/*<span className={`price ${props.storeItem?.locked? 'locked': ''}`}>*/}
            <span
              className="buy-now"
              notranslate="yes"
            >
              {`Buy now : `}
              <var data-var="buy_now_amount">
                {formatCurrency(
                  Number(props.auctionItem?.buy_now_price || 0),
                  currency,
                  props.locale,
                )}
              </var>
              {
                // props.storeItem?.locked &&
                // <i className="fas fa-lock-alt"/>
              }
            </span>
            <span className="time-remaining">
              {`Time remaining : `}
              {isActive && (
                <var data-var="time_remaining">
                  {`${timeRemainingDays}d: ${timeRemainingHours}h: ${timeRemainingMinutes}m: ${timeRemainingSeconds}s`}
                </var>
              )}
              {!isActive && 'Closed'}
            </span>

            <hr />

            <div className="more-info">
              <div className="titles">
                <span>Minimum Increment</span>
                <span>Retail Price</span>
                <span>Close Date</span>
              </div>
              <div className="values">
                <span>
                  {formatCurrency(
                    Number(props.auctionItem?.minimum_bid_increment || 0),
                    currency,
                    props.locale,
                  )}
                </span>
                <span>
                  {formatCurrency(
                    Number(props.auctionItem?.retail_price || 0),
                    currency,
                    props.locale,
                  )}
                </span>
                <span>
                  {localizeHelpers.formatDate(
                    props.auctionItem.end_date,
                    LocaleDateFormats.LLL,
                    props.locale,
                  )}
                </span>
              </div>
            </div>

            <hr />

            <AuctionitemLeaderDisplay
              locale={props.locale}
              item={props.auctionItem}
              onPlaceBidClicked={() => setShowPlaceBidModal(true)}
            />

            <hr />

            <span className="desc-title">Description</span>
            <QuillTextEditor
              value={props.auctionItem.description}
              readOnly={true}
              preserveWhitespace={true}
              theme={'bubble'}
              modules={auctionItemHelpers.quillModules}
              formats={auctionItemHelpers.quillFormats}
              className={'description'}
            />
          </div>
        </div>

        <div className="btn-container">
          <Button
            text="Cancel"
            type="button"
            buttonType="outline-dark"
            onClick={props.onCloseDetailModal}
          />
          {props.auctionItem && (
            <>
              <Button
                text="Place Bid"
                type="button"
                isDisabled={
                  !auctionItemHelpers.canPlaceBid(props.auctionItem) ||
                  new Date().getTime() < new Date(props.auctionItem.start_date).getTime()
                }
                buttonType="dark"
                onClick={() => {
                  canUserBid(ACTION_BID); /*setShowPlaceBidModal(true)*/
                }}
              />
              <Button
                text="Buy Now"
                type="button"
                buttonType="dark"
                onClick={() => canUserBid(ACTION_BUY)}
              />
            </>
          )}
        </div>
      </Modal>

      {props.auctionItem && ownerInfo && (
        <>
          <AuctionItemPlaceBidModal
            setActiveTab={(tab) => setActiveTab(tab)}
            setCurrentImage={(newImageIndex) => setActiveImgIndex(newImageIndex)}
            owner={{
              ownerId: props.auctionItem.owner_id || '',
              ownerType: props.auctionItem.owner_type || '',
              ownerHandle: props.auctionItem.owner_handle || '',
              ownerTitle: props.auctionItem.owner_title || '',
              ownerStatus: { code: 'active' },
              isActive: true,
              parentOwnerType:
                props.auctionItem.owner_type === Constants.page_owner_object_type.event
                  ? Constants.page_owner_object_type.group
                  : null,
              parentOwnerId:
                props.auctionItem.owner_type === Constants.page_owner_object_type.event
                  ? (ownerInfo as ConvertToFrontendType<IEventSummaryFE>).group_id!
                  : null,
              object: ownerInfo,
            }}
            showBidModal={showPlaceBidModal}
            item={props.auctionItem}
            currentImage={activeImgIndex}
            activeTab={activeTab}
            onClose={() => setShowPlaceBidModal(false)}
            onBidPlaced={() => props.getHubAuctionItems()}
            onBuyNowClicked={() => {
              setShowPlaceBidModal(false);
              setShowBuyItemModal(true);
            }}
            onFinish={() => {
              setShowPlaceBidModal(false);
              props.onCloseDetailModal();
            }}
          />

          <AuctionItemBuyNowModal
            item={props.auctionItem}
            owner={{
              ownerId: props.auctionItem.owner_id || '',
              ownerType: props.auctionItem.owner_type || '',
              ownerHandle: props.auctionItem.owner_handle || '',
              ownerTitle: props.auctionItem.owner_title || '',
              ownerStatus: { code: 'active' },
              isActive: true,
              parentOwnerType:
                props.auctionItem.owner_type === Constants.page_owner_object_type.event
                  ? Constants.page_owner_object_type.group
                  : null,
              parentOwnerId:
                props.auctionItem.owner_type === Constants.page_owner_object_type.event
                  ? (ownerInfo as ConvertToFrontendType<IEventSummaryFE>).group_id!
                  : null,
              object: ownerInfo,
              account: accountInfo,
            }}
            showBuyNowModal={showBuyItemModal}
            handleOnSuccessfulSubmitPayment={() => {
              props.getHubAuctionItems();
              setShowBuyItemModal(false);
              props.onCloseDetailModal();
            }}
            onClose={() => setShowBuyItemModal(false)}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HubAuctionDetailItemModal);
