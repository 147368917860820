import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { IUserRole, IContactAccount } from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { IGroupState } from '../../../reducers/group';
import { IAppState } from '../../../store';
import { createToast } from '../../../actions/toaster';
import Loader from '../../Loader/Loader';
import SortableTable from '../../SortableTable/SortableTable';
import {
  routes,
  swapRouteParams,
  handleInputChange,
  timeSince,
  toastError,
} from '../../../helpers';

import TextField from '../../TextField/TextField';
import Dropdown, { IOptions } from '../../Dropdown/Dropdown';
import Button from '../../Button/Button';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';
import { Constants } from '@gigit/constants';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  social: IContactAccount | null;
  onClose(): void;
  createToast(toast: IToast): void;
}

interface IState {
  handle: string;
  social: string;
  options: Array<IOptions>;
}

class AddSocial extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      handle: '',
      social: 'facebook',
      options: [
        { label: 'Facebook', value: Constants.contact_account_type.facebook },
        { label: 'Twitter', value: Constants.contact_account_type.twitter },
        { label: 'Instagram', value: Constants.contact_account_type.instagram },
        { label: 'LinkedIn', value: Constants.contact_account_type.linkedin },
        { label: 'Twitch', value: Constants.contact_account_type.twitch },
        { label: 'YouTube', value: Constants.contact_account_type.youtube },
      ],
    };
  }

  componentDidMount() {
    if (this.props.social) {
      this.setState({
        handle: this.props.social?.handle,
        social: this.props.social?.platform,
      });
    }
  }

  handleSocial() {
    if (this.props.social) {
      this.editSocial();
    } else {
      this.createSocial();
    }
  }

  createSocial() {
    let payload = {
      handle: this.state.handle,
      platform: this.state.social,
    };

    axios
      .post(
        swapRouteParams(routes.GET_SOCIALS, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
        }),
        payload,
      )
      .then((response) => {
        this.props.onClose();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Create Social');
        this.props.createToast(toast);
      });
  }

  editSocial() {
    let payload = {
      handle: this.state.handle,
      platform: this.state.social,
    };

    axios
      .put(
        swapRouteParams(routes.UPDATE_SOCIAL, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
          socialId: this.props.social?.id,
        }),
        payload,
      )
      .then((response) => {
        this.props.onClose();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Update Social');
        this.props.createToast(toast);
      });
  }

  resetSocial() {
    this.setState({
      handle: '',
      social: 'facebook',
    });
  }

  render() {
    return (
      <div className="AddSocial">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSocial();
          }}
          className="af"
        >
          <div className="af-title">Add Account</div>
          <div className="af-subtitle">Details</div>
          <Dropdown
            label="Account"
            name="social"
            shouldSort={true}
            value={this.state.social}
            notranslate="yes"
            options={this.state.options}
            onChange={(e) => {
              handleInputChange(e, this, false);
            }}
          />
          <TextField
            label="Handle"
            required={true}
            value={this.state.handle}
            name="handle"
            type="text"
            onChange={(e) => handleInputChange(e, this)}
          />
          <div className="af-actions">
            <Button text="Save" />
            <Button
              text="Cancel"
              onClick={(e: any) => {
                e.preventDefault();
                this.props.onClose();
              }}
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
  };
};

const mapDispatchToProps = {
  createToast,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(AddSocial),
);
