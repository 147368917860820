import React from 'react';
import TextField from '../../../TextField/TextField';
import { errorHelpers, formatQuery, handleInputChange, toastError } from '../../../../helpers';
import { ICause } from '@gigit/interfaces';
import Button from '../../../Button/Button';
import { causeRequestActions } from '../../../../requestActions/causes';
import { createToast } from '../../../../actions/toaster';

interface IProps {
  flow: string;
  findCauses: Array<string>;

  updateFindCauses(val: string): void;
  removeFindCause(id: string): void;
}

interface IState {
  causes: ICause[];
  causeValue: string;
  selectedCauses: Array<string>;
}

class CausesSelection extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      causes: [],
      causeValue: '',
      selectedCauses: [],
    };
  }

  componentDidMount() {
    this.initializeData();
    this.syncCauses();
  }

  initializeData() {
    this.setState({
      selectedCauses: [...this.props.findCauses],
    });
  }

  async syncCauses() {
    try {
      const causes = await causeRequestActions.getCauses(
        formatQuery({
          sort: [{ id: 'name', order: 'desc' }],
        }),
      );
      this.setState({ causes });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Add Cause');
      createToast(toast);
    }
  }

  renderHeaderTxt() {
    if (this.props.flow === 'findEvents') {
      return 'Find the perfect event or fundraiser';
    } else if (this.props.flow === 'findGroups') {
      return "Find the next group you'll donate to";
    } else {
      return 'Find the perfect volunteer opportunity';
    }
  }

  addCause() {
    let causes = [...this.state.selectedCauses];
    causes.push(this.state.causeValue);
    let c = this.state.causeValue;
    this.setState(
      {
        selectedCauses: causes,
        causeValue: '',
      },
      () => {
        this.props.updateFindCauses(c);
      },
    );
  }

  removeCause(id: string) {
    let causes = [...this.state.selectedCauses];
    causes = causes.filter((e) => e !== id);

    this.setState(
      {
        selectedCauses: causes,
      },
      () => {
        this.props.removeFindCause(id);
      },
    );
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow}`}>
        <div>
          <h2>{this.renderHeaderTxt()}</h2>
          <div className="details-row">
            <span>Find focus areas to support</span>
            <form
              className="add-cause"
              onSubmit={(e: any) => {
                e.preventDefault();
                this.addCause();
              }}
            >
              <TextField
                list="gigit-causes"
                placeholder="Add a focus area (Cancer Research, COVID-19, etc)"
                onChange={(e: any) => {
                  handleInputChange(e, this);
                }}
                value={this.state.causeValue}
                name="causeValue"
                type="text"
              />
              <datalist id="gigit-causes">
                {this.state.causes.map((item, index) => {
                  return (
                    <option
                      key={index}
                      id={item.id}
                      value={item.cause}
                    ></option>
                  );
                })}
              </datalist>
              <Button
                isDisabled={this.state.causeValue === ''}
                icon={'fa fa-plus'}
                text="Add"
                type="submit"
              />
            </form>
            {this.state.selectedCauses.length > 0 && (
              <ul className="user-causes">
                {this.state.selectedCauses.map((item: any, index: number) => {
                  return (
                    <li
                      key={index}
                      className="cause no-select"
                    >
                      <span>
                        {/\d/.test(item)
                          ? this.state.causes.find((cause: ICause) => cause.id === item)?.cause
                          : item}
                      </span>
                      {true && (
                        <i
                          onClick={() => {
                            this.removeCause(item);
                          }}
                          className="fa fa-times-circle"
                        ></i>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CausesSelection;
