import './AsyncSelectField.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select, { ActionMeta } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useDebounce } from '../../../../../hooks/useDebounce';
import useToastDispatcher from '../../../../../hooks/useToaster';
import _ from 'lodash';
import { formatQuery, IQueryParams } from '../../../../../helpers';
import { ISelectOption } from '../types';
import SelectOption from '../shared/SelectOption/SelectOption';

//Take in an async function
export interface IAsyncSelectFieldProps {
  onSearchRequest: (providedQueryParams: URLSearchParams) => Promise<ISelectOption[]>;
  onChange: (option: ISelectOption | null) => void;
  name: string;
  label?: string;
  /** If you want the context menu to appear outside of a modal */
  addContextMenuToPortal?: boolean;
  isClearable?: boolean;

  /** Passes a limit to the callback function 'onSearchRequest'
   * Defaults to 10 results */
  limit?: string;

  /** if input is disabled */
  isDisabled?: boolean;

  /** if you want to pass in a default value */
  defaultValue?: ISelectOption;
}

function AsyncSelectField(props: IAsyncSelectFieldProps) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [options, setOptions] = useState<ISelectOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const doesListHaveIconOrImage = !!options.find((option) => !!option.icon || !!option.imageURL);
  const debouncedSearchValue = useDebounce(searchValue);

  useEffect(() => {
    handleOnSearchRequest(debouncedSearchValue);
  }, [debouncedSearchValue]);

  async function handleOnSearchRequest(searchValue: string) {
    setIsLoading(true);

    const queryParams: IQueryParams = {
      limit: props.limit || '10',
      search: searchValue,
    };

    try {
      const optionsResponse = await props.onSearchRequest(formatQuery(queryParams));

      setOptions(optionsResponse);
    } catch (error) {
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  }

  function handleOnSelectChange(
    option: ISelectOption | null,
    actionMeta: ActionMeta<ISelectOption>,
  ) {
    props.onChange(option);
  }

  return (
    <div className="AsyncSelectFieldContainer">
      {!!props.label && <label>{props.label}</label>}
      <Select
        defaultValue={props.defaultValue}
        isDisabled={props?.isDisabled}
        className="SelectField"
        options={options}
        onChange={handleOnSelectChange}
        onInputChange={setSearchValue}
        menuPortalTarget={props.addContextMenuToPortal ? document.body : undefined}
        menuPosition={props.addContextMenuToPortal ? 'fixed' : undefined}
        isLoading={isLoading}
        name={props.name}
        components={{
          Option: (props) => (
            <SelectOption
              {...props}
              hasIconPadding={doesListHaveIconOrImage}
            />
          ),
        }}
        isClearable={props.isClearable}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#3A3A3A' : '#D0D1D1',
            boxShadow: 'none',
            '&:hover': {
              borderColor: '#A9AAAC',
            },
          }),
        }}
      />
    </div>
  );
}

export default AsyncSelectField;
