import { useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../selectors/user';
import { IAppState } from '../../../../store';
import { IEntityBaseInfo } from '../interfaces';

interface IUseBaseInfo {
  baseInfo: IEntityBaseInfo;
  handleBaseInfo(update: Partial<IEntityBaseInfo>): void;
}

export function useBaseInfo<T>(): IUseBaseInfo {
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  const [baseInfo, setBaseInfo] = useState<IEntityBaseInfo>({
    name: '',
    description: '',
    revenueGoal: 5000,
    event_organizer: `${user?.first_name} ${user?.last_name}`,
  });
  function handleBaseInfo(update: IEntityBaseInfo) {
    setBaseInfo(update);
  }
  return { baseInfo, handleBaseInfo };
}
