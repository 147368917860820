import { IPaymentMethod } from '@gigit/interfaces';
import React, { FC, useEffect, useState } from 'react';
import { PaymentRequest } from '@stripe/stripe-js';
import Button from '../../../../components/Button/Button';
import { uiConstants } from '../../../../constants';
import { formatCardIcon } from '../../../../helpers';
import useToastDispatcher from '../../../../hooks/useToaster';
import { accountRequestActions } from '../../../../requestActions';
import GPayLogo from '../../../../assets/g_pay_logo.png';
import { ReactComponent as APayLogo } from '../../../../assets/a_pay_logo.svg';
import './PickPaymentMethod.scss';

interface IProps {
  className?: string;
  paymentRequest?: PaymentRequest | null;
  onSubmit(): void;
  supportedAltPaymentMethods?: string[];
  onAltMethodSelect?: (item: string, index: number) => void;
  onMethodSelect(item: IPaymentMethod, index: number): void;
  onBack(): void;
  openAddNewPaymentMethod(): void;
  selectedPaymentMethodIndex?: number;
  selectedPaymentMethod?: IPaymentMethod;
  isUsingAlternativePaymentMethod?: boolean;
}

const PickPaymentMethod: FC<IProps> = (props) => {
  const {
    className,
    paymentRequest,
    onSubmit,
    isUsingAlternativePaymentMethod,
    selectedPaymentMethodIndex,
    openAddNewPaymentMethod,
    onMethodSelect,
    supportedAltPaymentMethods,
    onBack,
    onAltMethodSelect,
  } = props;
  const { dispatchToastError } = useToastDispatcher();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);

  async function getPaymentMethods() {
    try {
      const data = await accountRequestActions.getPaymetMethods();
      setPaymentMethods(data);
    } catch (error) {
      dispatchToastError(error, 'Get payment methods');
    }
  }

  useEffect(() => {
    getPaymentMethods();
  }, []);

  return (
    <>
      <div className={`PickPaymentMethod ${className || ''}`}>
        {(paymentMethods.length > 0 || paymentRequest) && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            className="overlay-inner"
          >
            <div className="input-wrapper pm-wrap">
              <div className="sub-title">Select Payment Method</div>
              <div className="pm-inner">
                <div
                  onClick={openAddNewPaymentMethod}
                  className="pm new"
                >
                  <span>New Payment Method</span>
                  <i className="far fa-plus" />
                </div>

                {paymentMethods.map((item, index) => {
                  return (
                    <div
                      onClick={() => onMethodSelect(item, index)}
                      className="pm"
                      key={index}
                    >
                      <i className={formatCardIcon(item.card?.brand ?? '')} />
                      <span>
                        ending in <var data-var="credit_card_last_4_digits">{item.card?.last4}</var>
                      </span>
                      <i
                        className={
                          selectedPaymentMethodIndex === index ? 'fas fa-circle' : 'far fa-circle'
                        }
                      />
                    </div>
                  );
                })}
                {/* TODO [HOTFIX/3.4.4]: Use PaymentMethodOption if we can. */}
                {supportedAltPaymentMethods?.map((item, index) => {
                  return (
                    <div
                      className="alt-methods-container pm"
                      onClick={() => {
                        onAltMethodSelect?.(item, index);
                      }}
                      key={index}
                    >
                      {item === uiConstants.altPaymentMethods.googlePay ? (
                        <img src={GPayLogo} />
                      ) : (
                        <APayLogo />
                      )}
                      {/*
                                            Following the brand guide lines the methods should not be translated.
                                            https://developers.google.com/pay/api/web/guides/brand-guidelines | https://developer.apple.com/design/human-interface-guidelines/apple-pay/overview/introduction/
                                        */}
                      {item === uiConstants.altPaymentMethods.googlePay ? (
                        <span notranslate="yes">Google Pay</span>
                      ) : (
                        <span notranslate="yes">Apple Pay</span>
                      )}
                      <i
                        className={
                          selectedPaymentMethodIndex === index ? 'fas fa-circle' : 'far fa-circle'
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="overlay-actions">
              <Button
                isDisabled={selectedPaymentMethodIndex === -1 && !isUsingAlternativePaymentMethod}
                type="submit"
                text="Continue"
                buttonType="dark"
              />
              <Button
                onClick={onBack}
                text="Back"
                buttonType="outline-dark"
              />
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default PickPaymentMethod;
