import React, { ChangeEvent } from 'react';
import { capitalizeString } from '../../../../helpers';
import { IEntityBaseInfo } from '../interfaces';
import Dropdown from '../../../Dropdown/Dropdown';
import TextArea from '../../../TextArea/TextArea';
import TextField from '../../../TextField/TextField';
import { DateSelection } from '../DateSelection/DateSelection';
import { FormSlider } from '../FormSlider/FormSlider';
import { LocationSelection } from '../LocationSelection/LocationSelection';

import './EntityBaseInfo.scss';
import { localeConstants, uiConstants } from '../../../../constants';

interface IProps {
  type: string;
  subType: string;
  baseInfo: IEntityBaseInfo;

  onBaseInfoUpdated(update: IEntityBaseInfo): void;
}

export const EntityBaseInfo: React.FC<IProps> = (props: IProps) => {
  function generatePlaceholder() {
    if (props.type === uiConstants.ownerType.group) {
      return generateGroupPlaceholderText();
    } else if (props.type === uiConstants.ownerType.event) {
      return `Enter a name for your ${uiConstants.ownerType.event}`;
    }
  }

  function generateGroupPlaceholderText() {
    let type = capitalizeString(props.subType);
    return `Enter a name for your ${type}`;
  }

  function updateBaseInfo(update: Partial<IEntityBaseInfo>) {
    const newData = { ...props.baseInfo, ...update };
    props.onBaseInfoUpdated(newData);
  }

  function handleLocationTypeChange(value: string) {
    if (value !== 'Venue') {
      updateBaseInfo({ address: undefined });
      updateBaseInfo({ locationType: value });
    } else {
      updateBaseInfo({ locationType: value });
    }
  }

  const isGroup = props.type === uiConstants.ownerType.group;
  const isEvent = props.type === uiConstants.ownerType.event;
  const isGig = props.type === uiConstants.ownerType.gig;
  return (
    <div className="EntityBaseInfo">
      <div className="row">
        <TextField
          placeholder={generatePlaceholder()}
          value={props.baseInfo.name}
          name="title"
          label={isGroup ? `${capitalizeString(props.subType)} Name` : 'Name'}
          type="text"
          required={true}
          onChange={(e) => {
            updateBaseInfo({ name: e.target.value });
          }}
        />
      </div>
      {!isGroup && (
        <div className="row">
          <TextArea
            className="description-text-field"
            label={isGroup ? `${props.subType} Description` : 'Description'}
            type="text"
            value={props.baseInfo.description}
            name="description"
            required={true}
            spellCheck={false}
            placeholder="Enter Description"
            onChange={(e) => {
              updateBaseInfo({ description: e.target.value.replace(/<\/?[^>]+(>|$)/g, '') });
            }}
          />
        </div>
      )}
      {(isEvent || isGig) && (
        <>
          <div className="row">
            <DateSelection
              startDate={props.baseInfo?.startDate || ''}
              endDate={props.baseInfo.endDate || ''}
              onStartChange={(e) => updateBaseInfo({ startDate: e })}
              onEndChange={(e) => updateBaseInfo({ endDate: e })}
            />
          </div>
          <div className="row">
            <LocationSelection
              baseInfo={props.baseInfo}
              onChangeAddress={(value) => {
                updateBaseInfo({ address: value });
              }}
              onChange={(value) => {
                handleLocationTypeChange(value);
              }}
            />
          </div>
          {props.baseInfo.locationType !== 'Venue' && (
            <>
              <div className="row">
                <Dropdown
                  shouldSort={true}
                  label="Country"
                  onChange={(e) => updateBaseInfo({ country: e.target.value })}
                  value={props.baseInfo.country}
                  name="country"
                  options={localeConstants.supportedCountryOptions}
                  placeholder="Select a Country"
                />
              </div>
              <div className="row">
                <TextField
                  placeholder="Postal Code / ZIP"
                  value={props.baseInfo?.postalCode || ''}
                  name="postal"
                  label={'Postal Code / ZIP'}
                  type="text"
                  onChange={(e) => {
                    updateBaseInfo({ postalCode: e.target.value });
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
      {!isGig && !isGroup && (
        <>
          <label>
            {props.type === uiConstants.ownerType.event
              ? 'Fundraising Goal'
              : 'Annual Revenue Goal'}
          </label>
          <div className="row slider-custom">
            <FormSlider
              label={null}
              defaultValue={props.baseInfo.revenueGoal}
              min={0}
              max={100000}
              step={500}
              onChange={(value: number) => updateBaseInfo({ revenueGoal: value })}
            />
            <TextField
              pattern="^[0-9]{8}([,.][0-9]{8})+$"
              value={props.baseInfo.revenueGoal}
              name="goal"
              label=""
              type="number"
              min="0"
              max="1000000"
              onChange={(e) => updateBaseInfo({ revenueGoal: parseInt(e.target.value) })}
            />
          </div>
        </>
      )}
    </div>
  );
};
