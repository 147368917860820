import React, { RefObject } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import { IAppState } from '../../store';
import { connect } from 'react-redux';

import {
  combineClassNames,
  extractLettersFromQuillMarkup,
  toastError,
  uploadImage,
} from '../../helpers';
import { IToast } from '../../interfaces';
import { createToast } from '../../actions/toaster';
import { localizeHelpers } from '../../localizeHelpers';

import ImageCropper, { IImageCropperState } from '../ImageCropper/ImageCropper';

import Modal from '../Modal/Modal';

import './QuillTextEditor.scss';

type ReactQuillProps = ReactQuill['props'];

/** Type for React quill modules.
 * https://quilljs.com/docs/modules/
 */
export type ReactQuillModules = Exclude<ReactQuillProps['modules'], undefined>;

interface IProps extends ReactQuillProps {
  maxLength?: number;
  underMaxText?: string;
  idealLength?: number;
  overIdealText?: string;
  createToast(toast: IToast): void;
}

interface IState {
  quill: React.RefObject<ReactQuill>;
  fileURL: string;
  showCrop: boolean;
  loading: boolean;
}
/**
 * Wrapper around ReactQuill. Has the same interface.
 * Wraps ReactQuill component with div and points property 'bounds'
 * to that div to fix Link creation functionality alignment.
 * */
class QuillTextEditor extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      quill: React.createRef(),
      fileURL: '',
      showCrop: false,
      loading: false,
    };

    this.imageHandler = this.imageHandler.bind(this);
    this.onCrop = this.onCrop.bind(this);
  }

  imageHandler(): void {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const reader = new FileReader();
      const file = input?.files && input?.files[0];

      if (file) {
        reader.addEventListener(
          'load',
          () => {
            // convert image file to base64 string
            this.setState(
              {
                fileURL: reader.result?.toString() || '',
              },
              () => {
                this.onFileUpdate();
              },
            );
          },
          false,
        );

        reader.readAsDataURL(file);
      } else {
        const toast = toastError(
          localizeHelpers.translate('No image file detected.'),
          'Image upload',
        );
        this.props.createToast(toast);
      }
    };
  }

  onFileUpdate(): void {
    if (this.state.fileURL) {
      this.setState({
        showCrop: true,
      });
    }
  }

  async onCrop(newImage: IImageCropperState): Promise<void> {
    const fileURL = newImage.fileUrl;
    const triggerToast = (title: string, msg: string) => {
      const toast = toastError(localizeHelpers.translate(msg), title);
      this.props.createToast(toast);
    };

    if (fileURL) {
      this.setState({
        loading: true,
      });
      const res = await uploadImage(fileURL);
      this.setState({
        loading: false,
      });

      const editor = this.state.quill?.current?.getEditor();

      if (editor) {
        const range = editor.getSelection(true);
        // Insert uploaded image
        editor.insertEmbed(range.index, 'image', res);

        // Move cursor to right side of image
        editor.setSelection({ index: range.index + 1, length: range.length });

        this.setState({
          showCrop: false,
        });
      } else {
        triggerToast('Image Upload', 'Editor Error - failed to insert image.');
      }
    } else {
      triggerToast('Image Upload', 'No image file detected.');
    }
  }

  getMsgLength(): number {
    return typeof this.props.value === 'string'
      ? extractLettersFromQuillMarkup(this.props.value).length
      : 0;
  }

  render() {
    return (
      <div
        className="QuillTextEditor"
        notranslate="yes"
      >
        <ReactQuill
          // spreading inherited quill props
          {...this.props}
          modules={{
            toolbar: {
              container: this.props.modules?.toolbar,
              handlers: { image: this.imageHandler },
            },
            clipboard: {
              matchVisual: false,
            },
          }}
          value={this.props.value || ''}
          defaultValue={this.props.defaultValue || ''}
          bounds={this.props.bounds ? this.props.bounds : '.QuillTextEditor'}
          ref={this.state.quill}
        />
        {this.props.maxLength && (
          <div
            className={combineClassNames(
              'max-length',
              this.getMsgLength() > this.props.maxLength ? 'exceeded' : '',
            )}
          >
            {this.getMsgLength()}/{this.props.maxLength}
            <span
              className={
                this.props.idealLength &&
                this.getMsgLength() <= this.props.maxLength &&
                this.getMsgLength() > this.props.idealLength
                  ? 'warning'
                  : ''
              }
            >
              {this.props.underMaxText &&
                this.getMsgLength() <= this.props.maxLength &&
                ` ${this.props.underMaxText}`}
              {this.props.idealLength &&
                this.props.overIdealText &&
                this.getMsgLength() <= this.props.maxLength &&
                this.getMsgLength() > this.props.idealLength &&
                ` - ${this.props.overIdealText}`}
            </span>
          </div>
        )}

        <Modal
          show={this.state.showCrop}
          onClose={() => {
            this.setState({ showCrop: false });
          }}
        >
          <ImageCropper
            recSize={'Any'}
            onSave={this.onCrop}
            src={this.state.fileURL}
            minWidth={80}
            isLoading={this.state.loading}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuillTextEditor);

/**
 * Middleware for ReactQuill text editor
 * This function gets called when user creates hyperlink
 * It formats the link into valid URL (bug GIG-1171)
 */
const Link = Quill.import('formats/link');

Link.sanitize = function (url: string) {
  url = url.trim();
  url = url.replace(/^www\./i, 'https://');

  const linkProtocol = url.substring(0, url.indexOf(':'));
  if (linkProtocol === '') {
    return 'https://' + url;
  }
  return url;
};
