import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import {
  assignableEventStatuses,
  defaultCurrency,
  formatCurrency,
  handleDebounce,
  handleInputChange,
  round,
} from '../../helpers';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import ContextMenu from '../../components/ContextMenu/ContextMenu';
import { IUserState } from '../../reducers/user';
import { IGroupState } from '../../reducers/group';
import { changeEventStatus } from '../../actions/event';
import { getGroupEvents } from '../../actions/group';
import './EventManagement.scss';
import { IEventSummaryFE } from '@gigit/interfaces';
import ChangeStatusMenu from '../ChangeStatusMenu/ChangeStatusMenu';
import { Constants } from '@gigit/constants';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import CreateEventModal from '../CreateEventModal/CreateEventModal';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import { IOwnerObject } from '../../interfaces';
import { IEventState } from '../../reducers/event';

interface IProps extends RouteComponentProps {
  owner: IOwnerObject;
  userState: IUserState;
  groupState: IGroupState;
  eventState: IEventState;
  getGroupEvents(groupId: string, search?: string): void;
  changeEventStatus(
    eventId: string,
    status: string,
    callback?: (updatedEvent: IEventSummaryFE) => void,
  ): void;

  locale: string;
}

interface IState {
  redirect: string | null;
  searchValue: string;
  showCreateEventModal: boolean;
}

/** Admin Page for managing all the events on Group. */
class EventManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      redirect: '',
      searchValue: '',
      showCreateEventModal: false,
    };
  }

  componentDidMount() {
    this.fetchGroupEvents();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.searchValue !== this.state.searchValue) {
      handleDebounce(this.state.searchValue).then((res) => {
        this.fetchGroupEvents();
      });
    }
  }

  deleteEvent(eventId: string) {
    this.props.changeEventStatus(eventId, Constants.event_status.archived, () =>
      this.fetchGroupEvents(),
    );
  }

  changeEventStatus(eventId: string, newStatus: string) {
    this.props.changeEventStatus(eventId, newStatus, () => this.fetchGroupEvents());
  }

  fetchGroupEvents() {
    this.props.getGroupEvents(this.props.groupState.group.id, this.state.searchValue);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className="EventManagement section-wrap">
        <div className="section-title">
          <div className="forms-title">Events</div>
          <div className="forms-create">
            <Button
              icon="fal fa-plus"
              onClick={() => this.setState({ showCreateEventModal: true })}
              text="Add Event"
            />
          </div>
        </div>

        <div className="section-inner">
          <div className="EventManagement-list">
            <div className="search">
              <TextField
                icon="fas fa-search"
                placeholder="Search Events..."
                value={this.state.searchValue}
                type="text"
                name="searchValue"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="list">
              <div className="headers">
                <div className="col title">Event Name</div>
                <div className="col event-status">Event Status</div>
                <div className="col start-date">Start Date</div>
                <div className="col end-date">End Date</div>
                <div className="col fundraising-progress">Fundraising Progress</div>
                <div className="col total-revenue">Total Revenue</div>
                <div className="col actions" />
              </div>
              <div className="list-inner">
                <div className="list-rows">
                  {this.props.groupState.groupEvents.map((event, index) =>
                    this.renderEvent(event, index),
                  )}

                  {this.props.groupState.groupEvents.length === 0 && (
                    <div className="empty">Your search returned 0 results.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <CreateEventModal
          show={this.state.showCreateEventModal}
          history={this.props.history}
          onClose={() => this.setState({ showCreateEventModal: false })}
          owner={this.props.owner}
        />
      </div>
    );
  }

  renderEvent(event: IEventSummaryFE, index: number) {
    const currency = event.group?.account?.currency ?? defaultCurrency;

    return (
      <div
        key={index}
        className="row"
      >
        <div
          className="col title event-title"
          notranslate="yes"
        >
          <Link to={`/event/${event.handle}`}>
            <span>{event.title}</span>
          </Link>
        </div>
        <div className="col event-status">
          <ChangeStatusMenu
            group={this.props.groupState.group}
            event={this.props.eventState.event}
            className="event-status-dropdown"
            listOfStatuses={assignableEventStatuses}
            currentStatus={event.status?.code}
            onStatusChanged={(status: string) => this.changeEventStatus(event.id, status)}
            itemType="EVENT"
          />
        </div>
        <div
          className="col start-date"
          notranslate="yes"
        >
          <span className="date">
            {localizeHelpers.formatDate(event.start_date, LocaleDateFormats.LL, this.props.locale)}
          </span>
        </div>
        <div
          className="col end-date"
          notranslate="yes"
        >
          <span className="date">
            {localizeHelpers.formatDate(event.end_date, LocaleDateFormats.LL, this.props.locale)}
          </span>
        </div>
        <div className="col fundraising-progress">{this.renderFundraisingProgressBar(event)}</div>
        <div
          className="col total-revenue total-revenue-amount"
          notranslate="yes"
        >
          {formatCurrency(event.raised ?? 0, currency, this.props.locale)}
        </div>
        <div className="col actions">
          <i className="fal fa-ellipsis-h-alt" />
          <ContextMenu
            onMouseLeave={() => {}}
            showMenu={true}
            menuItems={[
              { link: `/event/${event.handle}`, icon: 'fal fa-file-alt', label: 'View' },
              { link: `/event/${event.handle}/admin`, icon: 'fal fa-user-cog', label: 'Manage' },
            ]}
          />
        </div>
      </div>
    );
  }

  renderFundraisingProgressBar(event: IEventSummaryFE) {
    const currency = event.group?.account?.currency ?? defaultCurrency;
    const raised = event.raised ?? 0;
    const goal = event.goal ?? 0;
    const percent = goal ? round((raised / goal) * 100, 0) : 0;

    return (
      <div className="fundraising-progress-bar-container">
        <div className="fundraising-progress-bar">
          <div
            className="progress-fill"
            style={{ width: `${percent}%` }}
          />
          <span
            notranslate="yes"
            className="raised-amount"
          >
            {formatCurrency(raised, currency, this.props.locale, { decimal_places: 0 })}
          </span>
          <span
            notranslate="yes"
            className="goal-amount"
          >
            {formatCurrency(goal, currency, this.props.locale, { decimal_places: 0 })}
          </span>
        </div>
        <span className="percent">
          <var data-var="percent_raised">{percent}</var>% Raised
        </span>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  changeEventStatus,
  getGroupEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventManagement);
