import React, { FC, useEffect, useState } from 'react';
import { IAppState } from '../../../../store';
import { CheckoutLocale, loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../selectors/user';
import { useLocale } from '../../../../hooks';
import { useForm } from '../../../../hooks/useForm';
import useToastDispatcher from '../../../../hooks/useToaster';

import {
  IAddressBase,
  IAuctionItem,
  IEventSummaryFE,
  IGroup,
  IHub,
  IPaymentIntentResponse,
  ITransactionAmounts,
} from '@gigit/interfaces';
import {
  defaultCurrency,
  formatAddressLine,
  formatCurrency,
  OwnerType,
  typeHelpers,
} from '../../../../helpers';
import { Constants } from '@gigit/constants';
import { Config } from '@gigit/config/dist/config';
import { uiConstants } from '../../../../constants/uiConstants';
import { localizeHelpers } from '../../../../localizeHelpers';
import CreditCard from '../../../CreditCard/CreditCard';
import Loader from '../../../Loader/Loader';
import Modal from '../../../Modal/Modal';
import ModalHeader from '../../../Modal/ModalHeader/ModalHeader';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import TextField from '../../../TextField/TextField';
import Button from '../../../Button/Button';

import { auctionRequestActions } from '../../../../requestActions/auction';
import {
  eventRequestActions,
  groupRequestActions,
  hubRequestActions,
} from '../../../../requestActions';
import './AuctionItemPayModal.scss';

interface IProps {
  item: IAuctionItem;
  onClose: () => void;
  itemHasBeenPaid?: () => void;
}

const AuctionItemPayModal: FC<IProps> = ({ item, onClose, itemHasBeenPaid }) => {
  const locale = useLocale();
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const [owner, setOwner] = useState<IEventSummaryFE | IHub | IGroup | null>(null);
  const [shipOption, setShipOption] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentIntent, setPaymentIntent] = useState<IPaymentIntentResponse | null>(null);
  const [paymentSummary, setPaymentSummary] = useState<ITransactionAmounts | null>(null);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [sameAsShipping, setSameAsShipping] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [trySubmitPayment, setTrySubmitPayment] = useState(false);
  const [tryCreatePaymentMethod, setTryCreatePaymentMethod] = useState(false);
  const [contactInfo, handleContactInfoChange] = useForm({
    firstName: user.first_name || '',
    lastName: user.last_name || '',
    email: user.email || '',
    phone: user.phone || '',
    cardName: '',
  });
  const [shippingInfo, handleShippingInfoChange] = useForm({
    shipStreetAddress: '',
    shipAptNum: '',
    shipCity: '',
    shipProvince: '',
    shipCountry: '',
    shipPostal: '',
  });
  const [billInfo, handleBillInfoChange] = useForm({
    billStreetAddress: '',
    billAptNum: '',
    billCity: '',
    billProvince: '',
    billCountry: '',
    billPostal: '',
  });

  useEffect(() => {
    getOwner();

    //just one more extra check
    item.payment_status?.code === Constants.payment_status.paid &&
      dispatchToastSuccess(
        localizeHelpers.translate('This item has been already paid'),
        'Auction Payment',
      );
  }, []);

  useEffect(() => {
    if (owner) {
      const ownerObject = typeHelpers.createOwnerObject(item.owner_type as OwnerType, owner);
      const stripePromise = loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY, {
        stripeAccount: ownerObject?.account?.account_number,
        locale: locale.currentLocale as CheckoutLocale,
      });
      setStripePromise(stripePromise);
    }
  }, [owner]);

  useEffect(() => {
    const shipByVendor = item.is_shipping_available && shipOption === 'ship';
    setDataLoading(true);
    auctionRequestActions
      .getAuctionPaymentSummary(item.id!, { payment_method: 'card', ship_by_vendor: shipByVendor })
      .then((pSammary) => {
        setPaymentSummary(pSammary);
      })
      .catch((error) => {
        dispatchToastError(error, 'Get payment summary');
      })
      .finally(() => setDataLoading(false));
  }, [shipOption]);

  useEffect(() => {
    paymentIntent && setTrySubmitPayment(true);
  }, [paymentIntent]);

  useEffect(() => {
    if (tryCreatePaymentMethod && paymentMethod === Constants.payment_method.invoice)
      onSuccessfulPaymentMethod();
  }, [tryCreatePaymentMethod]);

  const getOwner = async (): Promise<void> => {
    switch (item.owner_type) {
      case Constants.object_type.event:
        setOwner(await eventRequestActions.getEventByHandleOrId(item.owner_id!));
        break;
      case Constants.object_type.hub:
        setOwner(await hubRequestActions.getHub(item.owner_id!));
        break;
      case Constants.object_type.group:
        setOwner(await groupRequestActions.getGroupByHandleOrId(item.owner_id!));
        break;
    }
  };

  const submitPayment = () => {
    setTryCreatePaymentMethod(true);
    setDataLoading(true);
  };

  const hasShippingOptions = (item: IAuctionItem): boolean => {
    return !!(item.is_pickup_available || item.is_shipping_available);
  };

  const getFinalPrice = () => {
    if (!paymentSummary) {
      return 0;
    }

    let amount = paymentSummary.amount;

    if (owner?.fee_control?.covers_processing_fees) {
      amount += paymentSummary.payment_platform_fee;
    }

    if (owner?.fee_control?.covers_gigit_fees) {
      amount += paymentSummary.gigit_fee;
    }

    return amount;
  };

  const getPaymentPayload = (response?: any) => {
    let payload: any = {
      payment_method_id: response?.id,
      payment_method_country: response?.card?.country,
      payment_method: paymentMethod === Constants.payment_method.invoice ? paymentMethod : 'card',
      ship_by_vendor: item.is_shipping_available && shipOption === 'ship',
      shipping_address: {
        line1: shippingInfo.shipStreetAddress,
        line2: shippingInfo.shipAptNum,
        city: shippingInfo.shipCity,
        state: shippingInfo.shipProvince,
        country: shippingInfo.shipCountry,
        postal_code: shippingInfo.shipPostal,
      },
    };

    if (sameAsShipping) {
      payload.billing_address = {
        line1: shippingInfo.shipStreetAddress,
        line2: shippingInfo.shipAptNum,
        city: shippingInfo.shipCity,
        state: shippingInfo.shipProvince,
        country: shippingInfo.shipCountry,
        postal_code: shippingInfo.shipPostal,
      };
    } else {
      payload.billing_address = {
        line1: billInfo.billStreetAddress,
        line2: billInfo.billAptNum,
        city: billInfo.billCity,
        state: billInfo.billProvince,
        country: billInfo.billCountry,
        postal_code: billInfo.billPostal,
      };
    }

    const pickupLocations = item.pickup_locations;
    if (shipOption !== 'ship' && pickupLocations) {
      for (let location of pickupLocations) {
        if (formatAddressLine(location) === shipOption) {
          payload.pickup_location = location;
          break;
        }
      }
    }

    if (item.current_bid) {
      payload.bidder = {
        id: item.current_bid?.user_id,
        email: contactInfo.email,
        first_name: contactInfo.firstName,
        last_name: contactInfo.lastName,
      };
    }

    return payload;
  };

  const onSuccessfulPaymentMethod = async (response?: any) => {
    typeHelpers.assertNotNullOrUndefined(owner);
    const payload = getPaymentPayload(response);
    const ownerObject = typeHelpers.createOwnerObject(item.owner_type as OwnerType, owner);
    const group = typeHelpers.getGroupFromOwner(ownerObject);

    auctionRequestActions
      .createAuctionPaymentIntent(group?.id!, item.id!, payload)
      .then((paymentIntent: IPaymentIntentResponse) => {
        if (paymentMethod === Constants.payment_method.invoice) {
          onSuccessfulSubmitPayment();
        } else {
          setPaymentIntent(paymentIntent);
        }
      });
  };

  const onSuccessfulSubmitPayment = () => {
    dispatchToastSuccess(
      localizeHelpers.translate('Successfully paid for auction item.'),
      'Auction Payment',
    );
    setDataLoading(false);
    itemHasBeenPaid?.();
    onClose();
  };

  const onFailedPaymentMethod = (error: any) => {
    dispatchToastError(error.message, 'Auction Payment Error');
    setDataLoading(false);
  };

  const onFailedSubmitPayment = (error: any) => {
    dispatchToastError(localizeHelpers.translate(error.message), 'Auction Payment Error');
    setDataLoading(false);
  };

  return (
    <Modal
      show={!!item && !!owner}
      onClose={onClose}
      contentClassName="double-side-modal"
    >
      <div className="AuctionItemPayModal">
        {
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitPayment();
            }}
            className="auction-payment"
          >
            <div className="details">
              <ModalHeader
                title="Auction Payment"
                description="Fill out the form below to complete your auction purchase"
                onClose={onClose}
                className="modal-header"
              />
              <div className="item-details">
                {item.media && item.media.length > 0 && (
                  <div className="image">
                    <img
                      alt="Auction Item"
                      src={item.media[0].url}
                    />
                  </div>
                )}
                <div className="item-content">
                  <div className="mobile-col">
                    <div
                      className="mobile-price"
                      notranslate="yes"
                    >
                      {formatCurrency(
                        item.retail_price!,
                        item.amounts?.currency ?? defaultCurrency,
                        locale.currentLocale,
                      )}
                    </div>
                    <div
                      className="item-title"
                      notranslate="yes"
                    >
                      {item?.name}
                    </div>
                  </div>
                  <div className="item-description">
                    <QuillTextEditor
                      value={item?.description || 'This item has no description.'}
                      readOnly={true}
                      preserveWhitespace={true}
                      theme={uiConstants.quill.readOnlyTheme}
                      modules={{
                        toolbar: [[], [], []],
                        clipboard: {
                          matchVisual: false,
                        },
                      }}
                      formats={[]}
                      onChange={() => {}}
                    />
                  </div>
                  <div className="winner">Won</div>
                </div>
              </div>
              <div className="form-title">Contact Information</div>

              <div className="row dbl names">
                <TextField
                  required={true}
                  label="First Name"
                  value={contactInfo.firstName}
                  name="firstName"
                  type="text"
                  onChange={handleContactInfoChange}
                />
                <TextField
                  required={true}
                  label="Last Name"
                  value={contactInfo.lastName}
                  name="lastName"
                  type="text"
                  onChange={handleContactInfoChange}
                />
              </div>
              <div className="row dbl info">
                <TextField
                  required={true}
                  label="Email"
                  value={contactInfo.email}
                  name="email"
                  type="email"
                  onChange={handleContactInfoChange}
                />
                <TextField
                  required={true}
                  label="Phone Number"
                  value={contactInfo.phone}
                  name="phone"
                  type="tel"
                  onChange={handleContactInfoChange}
                />
              </div>
              <div className="mobile-wrap">
                {hasShippingOptions(item) && (
                  <div className="form-title">Shipping/Pick-up Options</div>
                )}
                {hasShippingOptions(item) && (
                  <div className="shipping-options">
                    {item.is_shipping_available && (
                      <div onClick={() => setShipOption('ship')}>
                        <span className="ship-title">Shipped by Vendor</span>
                        <div className="shipping-label">
                          <i
                            className={
                              shipOption === 'ship' ? 'fas fa-check-circle' : 'far fa-circle'
                            }
                          />
                          {item.shipping_price_type === 'Amount'
                            ? formatCurrency(
                                item.shipping_price || 0,
                                item.amounts?.currency ?? defaultCurrency,
                                locale.currentLocale,
                              )
                            : item.shipping_price_type === 'Free'
                              ? 'Free'
                              : 'TBD'}
                        </div>
                      </div>
                    )}

                    {item.is_pickup_available &&
                      item.pickup_locations &&
                      item.pickup_locations!.length > 0 && (
                        <div className="pickup-locations">
                          <span className="ship-title">Pick-Up</span>
                          {item.pickup_locations!.map((location: IAddressBase, index: number) => {
                            return (
                              <div
                                key={index}
                                onClick={() => setShipOption(formatAddressLine(location))}
                                className="shipping-label"
                              >
                                <i
                                  className={
                                    shipOption === formatAddressLine(location)
                                      ? 'fas fa-check-circle'
                                      : 'far fa-circle'
                                  }
                                />
                                <span>{location.title}</span>({formatAddressLine(location)})
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                )}
              </div>

              <div className="form-title">Credit Card</div>

              {/* Below we check if invoice payment option is available. NOTE: Hub doesn't support this feature*/}
              {(item.owner_type === Constants.object_type.group ||
                item.owner_type === Constants.object_type.event) &&
                owner &&
                (owner as IGroup | IEventSummaryFE).allow_invoicing && (
                  <div
                    onClick={() => {
                      paymentMethod === ''
                        ? setPaymentMethod(Constants.payment_method.invoice)
                        : setPaymentMethod('');
                    }}
                    className="sub-form-title click"
                  >
                    <i className={paymentMethod ? 'fas fa-check-circle' : 'far fa-circle'} />
                    <span>Request an invoice and pay later</span>
                  </div>
                )}

              {paymentMethod !== Constants.payment_method.invoice && (
                <div className="row">
                  <TextField
                    required={true}
                    label="Name on Card"
                    value={contactInfo.cardName}
                    name="cardName"
                    type="text"
                    onChange={handleContactInfoChange}
                  />
                </div>
              )}
              {paymentMethod !== Constants.payment_method.invoice && (
                <div className="row">
                  {stripePromise !== null && (
                    <Elements stripe={stripePromise}>
                      <ElementsConsumer>
                        {({ elements, stripe }) => (
                          <CreditCard
                            billingDetails={{
                              email: contactInfo.email,
                              name: contactInfo.firstName + ' ' + contactInfo.lastName,
                              phone: contactInfo.phone,
                            }}
                            elements={elements}
                            stripe={stripe}
                            resetPaymentMethodTry={() => setTryCreatePaymentMethod(false)}
                            tryCreatePaymentMethod={tryCreatePaymentMethod}
                            onSuccessfulPaymentMethod={onSuccessfulPaymentMethod}
                            onFailedPaymentMethod={onFailedPaymentMethod}
                            trySubmitPayment={trySubmitPayment}
                            resetSubmitPayment={() => setTrySubmitPayment(false)}
                            onFailedSubmitPayment={onFailedSubmitPayment}
                            onSuccessfulSubmitPayment={onSuccessfulSubmitPayment}
                            type="auction"
                            onSubmitSubscription={() => {}}
                            options={{ hidePostalCode: true }}
                            ownerType={item.owner_type || ''}
                            paymentIntent={paymentIntent}
                          />
                        )}
                      </ElementsConsumer>
                    </Elements>
                  )}
                </div>
              )}
              {shipOption === 'ship' && item.is_shipping_available && (
                <div className="ship">
                  <div className="form-title">Shipping Address</div>
                  <div className="row dbl address">
                    <TextField
                      required={true}
                      label="Street Address"
                      value={shippingInfo.shipStreetAddress}
                      name="shipStreetAddress"
                      type="text"
                      onChange={handleShippingInfoChange}
                    />
                    <TextField
                      label="Apartment / Suite #"
                      value={shippingInfo.shipAptNum}
                      name="shipAptNum"
                      type="text"
                      onChange={handleShippingInfoChange}
                    />
                  </div>
                  <div className="row dbl address">
                    <TextField
                      required={true}
                      label="City"
                      value={shippingInfo.shipCity}
                      name="shipCity"
                      type="text"
                      onChange={handleShippingInfoChange}
                    />
                    <TextField
                      required={true}
                      label="Province"
                      value={shippingInfo.shipProvince}
                      name="shipProvince"
                      type="text"
                      onChange={handleShippingInfoChange}
                    />
                  </div>
                  <div className="row dbl address">
                    <TextField
                      required={true}
                      label="Country"
                      value={shippingInfo.shipCountry}
                      name="shipCountry"
                      type="text"
                      onChange={handleShippingInfoChange}
                    />
                    <TextField
                      required={true}
                      label="Postal Code"
                      value={shippingInfo.shipPostal}
                      name="shipPostal"
                      type="text"
                      onChange={handleShippingInfoChange}
                    />
                  </div>
                </div>
              )}
              <div className="bill">
                <div className="form-title">Billing Address</div>
                {shipOption === 'ship' && item.is_shipping_available && (
                  <div
                    onClick={() => setSameAsShipping(!sameAsShipping)}
                    className="sub-form-title click"
                  >
                    <i className={sameAsShipping ? 'fas fa-check-circle' : 'far fa-circle'} />
                    <span>Same as Shipping Address</span>
                  </div>
                )}
                {!sameAsShipping && (
                  <div className="bill-inner">
                    <div className="row dbl address">
                      <TextField
                        required={true}
                        label="Street Address"
                        value={billInfo.billStreetAddress}
                        name="billStreetAddress"
                        type="text"
                        onChange={handleBillInfoChange}
                      />
                      <TextField
                        label="Apartment / Suite #"
                        value={billInfo.billAptNum}
                        name="billAptNum"
                        type="text"
                        onChange={handleBillInfoChange}
                      />
                    </div>
                    <div className="row dbl address">
                      <TextField
                        required={true}
                        label="City"
                        value={billInfo.billCity}
                        name="billCity"
                        type="text"
                        onChange={handleBillInfoChange}
                      />
                      <TextField
                        required={true}
                        label="Province"
                        value={billInfo.billProvince}
                        name="billProvince"
                        type="text"
                        onChange={handleBillInfoChange}
                      />
                    </div>
                    <div className="row dbl address">
                      <TextField
                        required={true}
                        label="Country"
                        value={billInfo.billCountry}
                        name="billCountry"
                        type="text"
                        onChange={handleBillInfoChange}
                      />
                      <TextField
                        required={true}
                        label="Postal Code"
                        value={billInfo.billPostal}
                        name="billPostal"
                        type="text"
                        onChange={handleBillInfoChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="summary">
              <div className="summary-inner">
                {!dataLoading && paymentSummary !== null && (
                  <div className="summary-wrap">
                    <div className="title">Summary</div>
                    <div className="sub-price">
                      <span>Final Price</span>
                      <span notranslate="yes">
                        {formatCurrency(
                          getFinalPrice() || 0,
                          paymentSummary.currency ?? defaultCurrency,
                          locale.currentLocale,
                        )}
                      </span>
                    </div>
                    <div className="sub-price">
                      <span>Shipping</span>
                      <span notranslate="yes">
                        {formatCurrency(
                          paymentSummary.shipping || 0,
                          paymentSummary.currency ?? defaultCurrency,
                          locale.currentLocale,
                        )}
                      </span>
                    </div>
                    {!owner?.fee_control?.covers_processing_fees && (
                      <div className="sub-price">
                        <span>Processing Fee</span>
                        <span notranslate="yes">
                          {formatCurrency(
                            paymentSummary.payment_platform_fee || 0,
                            paymentSummary.currency ?? defaultCurrency,
                            locale.currentLocale,
                          )}
                        </span>
                      </div>
                    )}
                    {!owner?.fee_control?.covers_gigit_fees && (
                      <div className="sub-price">
                        <span>Platform Fee</span>
                        <span notranslate="yes">
                          {formatCurrency(
                            paymentSummary.gigit_fee || 0,
                            paymentSummary.currency ?? defaultCurrency,
                            locale.currentLocale,
                          )}
                        </span>
                      </div>
                    )}
                    <div className="total">
                      <span>Total</span>
                      <span notranslate="yes">
                        {formatCurrency(
                          paymentSummary.total || 0,
                          paymentSummary.currency ?? defaultCurrency,
                          locale.currentLocale,
                        )}
                      </span>
                    </div>
                    <div className="actions">
                      <Button
                        type="submit"
                        isDisabled={
                          !shipOption || item.payment_status?.code === Constants.payment_status.paid
                        }
                        loading={dataLoading}
                        text="Purchase"
                      />
                      <Button text="Cancel" />
                    </div>
                  </div>
                )}
                {dataLoading && <Loader loading={dataLoading} />}
              </div>
            </div>
          </form>
        }
      </div>
    </Modal>
  );
};

export default AuctionItemPayModal;
