import axios from 'axios';
import { routes, swapRouteParams } from '../helpers';
import { ISkill } from '@gigit/interfaces';

export namespace skillRequestActions {
  export async function getSkills() {
    const response = await axios.get(routes.GET_SKILLS);
    return response.data;
  }

  export async function createSkill(skill: Partial<ISkill>) {
    const response = await axios.post(routes.CREATE_SKILL);
    return response.data;
  }

  export async function deleteSkill(id: string) {
    const response = await axios.delete(swapRouteParams(routes.DELETE_SKILL, { id: id }));
    return response.data;
  }
}
