import { Reducer } from 'redux';
import { UserActions, UserActionTypes } from '../actions/user';
import {
  IUserToken,
  IApplication,
  IAuctionItemSummary,
  IUser,
  IUserMyPageItem,
  IGroup,
  ITransactionItem,
  ITransactionSummary,
  IAddress,
  IShiftUserSummary,
  IUserObjectWithRole,
  ITransactionItemSummary,
} from '@gigit/interfaces';

export interface IUserState {
  error: string;
  isLoginLoading: boolean;
  isUserLoading: boolean;
  groups: IUserObjectWithRole<IGroup>[];
  monetizedGroups: IUserObjectWithRole<IGroup>[];
  tickets: ITransactionItemSummary[];
  sponsorships: ITransactionItemSummary[];
  purchases: ITransactionItemSummary[];
  donations: ITransactionSummary[];
  donationSubscriptions: ITransactionSummary[];
  applications: IApplication[];
  locations: IAddress[];
  gigs: IShiftUserSummary[];
  currentApplications: {
    gigs: IApplication[];
    group: IApplication;
  };
  isLoggedIn: boolean;
  awaitingToken: boolean;
  isApplicationLoading: boolean;
  auctionItemsLoading: boolean;
  auctionItems: IAuctionItemSummary[];
  loggedOutLanguage: string;
  userMyPagesItems: IUserMyPageItem[];
  userMyPagesItemsLoading: boolean;
  user: IUser;
  isUserSessionConnectedToDataDog: boolean;
}

export const initialUserState: IUserState = {
  // tokens: {
  //     access_token: "",
  //     refresh_token: ""
  // },
  user: {
    first_name: '',
    last_name: '',
    referral: '',
    locations: [],
    profile_image_url: '',
    cover_image_url: '',
    gender: '',
    email: '',
    phone: '',
    display_name: '',
    dob: new Date(),
    handle: '',
    created_at: new Date(),
    updated_at: new Date(),
    id: '',
    account: {
      account_number: '',
      display_name: '',
      currency: '',
      country: '',
    },
  },
  isLoggedIn: false,
  error: '',
  isLoginLoading: false,
  isUserLoading: false,
  groups: [],
  monetizedGroups: [],
  tickets: [],
  sponsorships: [],
  purchases: [],
  donations: [],
  donationSubscriptions: [],
  applications: [],
  locations: [],
  gigs: [],
  currentApplications: {
    group: {
      id: '',
      user_id: '',
      //user_handle: "",
      group: {
        id: '',
        title: '',
        //handle : "",
        profile_image_url: '',
        group_type: '',
        cover_image_url: '',
        handle: '',
      },
      shift_ids: [],
      user_capacity: '',
      viewed: false,
      status: {
        code: '',
        status_date: new Date(),
        status_by: {
          id: '',
          handle: '',
          display_name: '',
          profile_image_url: '',
        },
      },
      application_form: [],
    },
    gigs: [],
  },
  isApplicationLoading: false,
  awaitingToken: false,
  auctionItemsLoading: false,
  auctionItems: [],
  loggedOutLanguage: 'en',
  userMyPagesItems: [],
  userMyPagesItemsLoading: false,
  isUserSessionConnectedToDataDog: false,
};

export const userReducer: Reducer<IUserState, UserActions> = (state = initialUserState, action) => {
  switch (action.type) {
    case UserActionTypes.UPDATE_USER: {
      return {
        ...state,
        ...action,
      };
    }
    case UserActionTypes.UPDATE_ERROR: {
      return {
        ...state,
        error: action.error,
        isLoggedIn: false,
        isLoginLoading: action.isLoginLoading !== undefined ? action.isLoginLoading : false,
      };
    }
    default:
      return state;
  }
};
