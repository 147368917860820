import { AxiosError } from 'axios';
import { IAPIError, IAPIErrorMessageArgs, ISimpleValue } from '@gigit/interfaces';
import { IAppError } from '../interfaces';
import { localizeHelpers } from '../localizeHelpers';

/** Generic error helper functions. */
export namespace errorHelpers {
  /** Determines if the given value is an Axios error. */
  export function isAxiosError(error: any): error is AxiosError {
    return (error as AxiosError).isAxiosError;
  }

  /** Determines if the given value is a Error from our own Backend service. */
  export function isAPIError(error: any): error is IAPIError {
    return (error as IAPIError)?.gigitErrorCode !== null || (error as IAPIError)?.message !== null;
  }

  /** Given a simple value response (that we assume has failed), returns a structured error object. */
  export function getErrorObjectFromSimpleValue(err: ISimpleValue<any>): IAppError {
    let messageTemplate: string = err?.errorMessageTemplate || 'Unexpected Error';
    let messageArgs: IAPIErrorMessageArgs | undefined = err?.errorMessageArgs;
    let errorCode: string | null = err?.gigitErrorCode ?? null;
    let translatedMessage: string = localizeHelpers.translate(messageTemplate, messageArgs);

    return {
      errorCode,
      messageTemplate,
      messageArgs,
      translatedMessage,
    };
  }

  /** Given an error object, returns a structured error object. */
  export function getErrorObject(error: any): IAppError {
    let errorCode: string | null = null;
    let messageTemplate: string = 'Unexpected Error';
    let messageArgs: IAPIErrorMessageArgs | undefined;
    let translatedMessage: string = 'Unexpected Error';

    if (typeof error === 'string') {
      errorCode = 'ERROR.SYSTEM.UNEXPECTED';
      messageTemplate = error;
      translatedMessage = error;
    } else {
      if (isAxiosError(error)) {
        const data = error.response?.data;
        if (isAPIError(data)) {
          if (error.message === 'Network Error') {
            messageTemplate =
              'Your connection is currently being blocked by a browser extension. Some functionality may not work correctly on this page. Please whitelist Kambeo or disable any ad blockers or other extensions that may be causing the issue.';
            translatedMessage = messageTemplate;
          } else {
            errorCode = data?.gigitErrorCode || 'ERROR.SYSTEM.UNEXPECTED';
            messageTemplate = data?.messageTemplate || data?.message || 'Unexpected Error';
            messageArgs = data?.messageArgs;

            translatedMessage = localizeHelpers.translate(messageTemplate, messageArgs);
          }
        } else {
          // Unknown API error, Just stringify error object so user at least sees something.
          errorCode = 'ERROR.SYSTEM.UNEXPECTED';
          messageTemplate = JSON.stringify(data);

          translatedMessage = messageTemplate;
        }
      } else if (error?.message) {
        // Error is likely a standard JS error object.
        errorCode = 'ERROR.SYSTEM.UNEXPECTED';
        messageTemplate = error.message;
        translatedMessage = messageTemplate;
      } else {
        // Unknown error, Just stringify error object so user at least sees something.
        errorCode = 'ERROR.SYSTEM.UNEXPECTED';
        messageTemplate = JSON.stringify(error);

        translatedMessage = messageTemplate;
      }
    }

    return {
      errorCode,
      messageTemplate,
      messageArgs,
      translatedMessage,
    };
  }
}

export default errorHelpers;
