import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { IStripeOAuthResponse, IStripeOAuthErrorResponse } from '@gigit/interfaces';
import { useAppSelector, useAppDispatch } from '../../store';
import { createToast } from '../../actions/toaster';
import { toastError, errorHelpers, toastSuccess } from '../../helpers';
import './StripeRedirect.scss';
import { accountRequestActions } from '../../requestActions';
import { localizeHelpers } from '../../localizeHelpers';
import { resetCurrentHub } from '../../actions/hub';

const flowRoutes: Record<string, string> = {
  donations: 'donations',
  createEvent: 'events',
  createGig: 'gigs',
  createStore: 'store',
  createAuction: 'auction',
  findGroups: 'donate',
  findEvents: 'findEvents',
  volunteer: 'volunteer',
  createGroup: 'group',
};

interface IProps extends RouteComponentProps<any> {}

/** Routing component that the user is dropped on after they approve the Stripe connection. */
export const StripeRedirect: React.FC<IProps> = (props) => {
  const registerState = useAppSelector((state) => state.registerState);
  const groupState = useAppSelector((state) => state.groupState);
  const currentHub = useAppSelector((state) => state.hubState.current);
  const dispatch = useAppDispatch();

  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const params = queryString.parse(props.location.search) as unknown as
      | IStripeOAuthResponse
      | IStripeOAuthErrorResponse;

    if (!('error' in params)) {
      connectAccount(params);
    } else {
      // HACK: Wrap in setTimeout() cause calling this.props.createToast() in componentDidMount() doesn't seem to work.
      setTimeout(() => {
        const errorMessage = params.error_description ?? params.error;
        const toast = toastError(errorMessage as string, 'Stripe Connect');

        dispatch(createToast(toast));

        setShouldRedirect(true);
      });
    }
  }, []);

  async function connectAccount(params: IStripeOAuthResponse) {
    try {
      const result = await accountRequestActions.connectAccount(params);
      const toast = toastSuccess(
        localizeHelpers.translate('Account was connected successfully'),
        'Connect Account',
      );
      dispatch(createToast(toast));
    } catch (error) {
      const errorObject = errorHelpers.getErrorObject(error);
      const toast = toastError(errorObject.translatedMessage, 'Connect Account');
      dispatch(createToast(toast));
    } finally {
      setShouldRedirect(true);
    }
  }

  if (shouldRedirect) {
    let url = '';
    let params = '';
    if (registerState?.flowConfig.type) {
      url = `/setup/${flowRoutes[registerState.flowConfig.type]}`;
      params = `currentStep=${registerState?.flowConfig.currentStep}`;
    } else if (currentHub) {
      url = `/company/${currentHub.handle}/admin`;
      params = `t=balance-and-payouts`;
    } else if (groupState?.group?.handle !== '') {
      url = `/group/${groupState.group.handle}/admin`;
      params = `t=balance_and_payouts`;
    } else {
      url = `/dashboard`;
      params = `section=settings`;
    }

    return <Redirect to={{ pathname: url, search: params }} />;
  }

  return (
    <div className="NotFound">
      <i className="star star-1 fas fa-star"></i>
      <i className="star star-2 fas fa-star"></i>
      <i className="star star-3 fas fa-star"></i>
      <i className="star star-4 fas fa-star"></i>
      <i className="star star-5 fas fa-star"></i>
      <i className="star star-6 fas fa-star"></i>
      <i className="star star-7 fas fa-star"></i>
      <i className="star star-8 fas fa-star"></i>
      <i className="star star-9 fas fa-star"></i>
      <i className="star star-10 fas fa-star"></i>
      <i className="star star-11 fas fa-star"></i>
      <i className="star star-12 fas fa-star"></i>
      <i className="mountain mountain-1 fad fa-mountain"></i>
      <i className="mountain mountain-2 fad fa-mountain"></i>
      <i className="mountain mountain-3 fad fa-mountain"></i>
      <i className="mountain mountain-4 fad fa-mountain"></i>
      <i className="mountain mountain-5 fad fa-mountain"></i>
      <i className="cloud cloud-1 fad fa-clouds"></i>
      <i className="cloud cloud-2 fad fa-clouds"></i>
      <i className="cloud cloud-3 fad fa-clouds"></i>
      <h1>Stripe Connected</h1>
      <p className="big">You will now be able to receive payments.</p>
    </div>
  );
};

export default StripeRedirect;
