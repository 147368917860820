import {
  IStoryFE,
  IStoryForegroundContent,
  IStoryMetricsData,
  IStoryResultFE,
} from '@gigit/interfaces';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import Modal from '../../../Modal/Modal';
import Portrait from '../../../Portrait/Portrait';
import Stories from '../Stories/Stories';
import { StoryImage } from '../StoryImage/StoryImage';
import { StoryVideo } from '../StoryVideo/StoryVideo';
import addStory from '../../../../assets/add-story.svg';
import './StoryCarousel.scss';
import { useEffect } from 'react';
import { hubRequestActions, userRequestActions } from '../../../../requestActions';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import Button from '../../../Button/Button';
import useToastDispatcher from '../../../../hooks/useToaster';
import { formatCurrency, formatQuery } from '../../../../helpers';
import { currencyMetrics, metricLabels } from '../../../../routes/CreateStory/CreateStory';
import { storyRequestActions } from '../../../../requestActions/Stories';
import { IThemeStyleObjectsByType } from '../../../Hub/Hub';
import { uiConstants } from '../../../../constants';
import { Constants } from '@gigit/constants';
import StoryCarouselSkeleton from './StoryCarouselSkeleton';

export interface IStoryCarousel {
  /**
   *  @type should be used to determine which routes and stories we want to display
   *  Right now Hub, or User is supported
   */
  ownerType?: string;
  ownerId?: string | null;
  theme?: IThemeStyleObjectsByType;
}

export function StoryCarousel(props: IStoryCarousel) {
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const [showStoryModal, setShowStoryModal] = useState<boolean>(false);
  const [currentStory, setCurrentStory] = useState<IStoryResultFE>({} as IStoryResultFE);
  const [stories, setStories] = useState<IStoryResultFE[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hubsWithCreatePermission, setHubsWithCreatePermission] = useState<any[]>([]);
  const [hubsWithManagePermission, setHubsWithManagePermissions] = useState<any[]>([]);
  const history = useHistory();
  const { dispatchToastError } = useToastDispatcher();
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToScroll: 3,
    variableWidth: true,
    prevArrow: stories.length > 3 ? <CarouselPreviousArrow /> : <></>,
    nextArrow: stories.length > 3 ? <CarouselNextArrow /> : <></>,
  };

  useEffect(() => {
    if (isUserLoggedIn && props.ownerType === 'hub') {
      getHubPermissions();
    } else {
      fetchStories();
    }
  }, []);

  useEffect(() => {
    fetchStories();
  }, [hubsWithCreatePermission, hubsWithManagePermission]);

  async function getHubPermissions() {
    try {
      const [hubsWithCreatePerms, hubsWithManagePerms] = await Promise.all([
        userRequestActions.getUserHubsForPermission('CREATE_STORIES'),
        userRequestActions.getUserHubsForPermission('MANAGE_STORIES'),
      ]);
      setHubsWithCreatePermission(hubsWithCreatePerms);
      setHubsWithManagePermissions(hubsWithManagePerms);
    } catch (error) {
      dispatchToastError(error, 'Hub Permissions');
    } finally {
    }
  }

  function CarouselPreviousArrow(carouselProps: any) {
    return (
      <Button
        icon="fa fa-chevron-left"
        className={`carousel-prev ${carouselProps.className}`}
        onClick={carouselProps.onClick}
      />
    );
  }

  function CarouselNextArrow(carouselProps: any) {
    return (
      <Button
        icon="fa fa-chevron-right"
        className={`carousel-next ${carouselProps.className}`}
        onClick={carouselProps.onClick}
      />
    );
  }

  // if type is hub and userLoggedIn and user has CREATE_STORY we will show add to story
  // if type is user and userLoggedIn show add story
  // if type is hub and user has MANAGE_STORY or they created story they can delete story
  // if type is hub don't show userFollowsStories
  async function fetchStories() {
    try {
      setIsLoading(true);
      let storyResults: IStoryResultFE[] = [];
      if (isUserLoggedIn) {
        if (props?.ownerType === 'user') {
          const [userStories, followedStories] = await Promise.all([
            userRequestActions.getStories(
              user?.id || '',
              formatQuery({
                client_filters: [
                  {
                    id: 'status.code',
                    value: `=${Constants.story_status.published}`,
                  },
                ],
              }),
            ),
            userRequestActions.getStoriesOfFollowed(
              formatQuery({
                client_filters: [
                  {
                    id: 'status.code',
                    value: `=${Constants.story_status.published}`,
                  },
                ],
              }),
            ),
          ]);

          if (userStories.stories.length > 0) storyResults.push(userStories);
          storyResults = [...storyResults, ...followedStories];
        }
      }
      if (props.ownerId && props.ownerType) {
        const [entityStoryResult, entityStoryMemberResults] = await Promise.all([
          storyRequestActions.getStoryForEntity(
            props.ownerType,
            props.ownerId,
            formatQuery({
              client_filters: [
                {
                  id: 'status.code',
                  value: `=${Constants.story_status.published}`,
                },
              ],
            }),
          ),
          hubRequestActions.getHubMemberStories(
            props.ownerId,
            formatQuery({
              client_filters: [
                {
                  id: 'status.code',
                  value: `=${Constants.story_status.published}`,
                },
              ],
            }),
          ),
        ]);
        if (entityStoryResult.stories.length > 0) storyResults.push(entityStoryResult);
        if (entityStoryMemberResults) storyResults.push(...entityStoryMemberResults);

        // If it's the logged in user and they belong to hub we take their stories and move them to front instead of
        // calling both apis which would duplicate said story
        // if (isUserLoggedIn) {
        //     console.log(user.id)
        //     const userStoryIndex = storyResults.findIndex((story) => story.entityId === user.id);
        //     if (userStoryIndex) {
        //         const userStory = storyResults.splice(userStoryIndex, 1);
        //         storyResults.unshift(userStory[0])
        //     }
        // }
      }
      setStories(storyResults);
    } catch (error) {
      dispatchToastError(error, 'Fetching Stories');
    } finally {
      setIsLoading(false);
    }
  }

  function renderStoryContent(story: IStoryFE) {
    if (story) {
      let videoStory = { url: story.media_url || '', duration: 20000, ...story };
      let imageStory = { url: story.media_url || '', duration: 20000, ...story };
      if (story.background_type === 'video') {
        return (
          <StoryVideo
            story={videoStory}
            onPause={() => {}}
            onResume={() => {}}
            handleNextClick={() => {}}
            handlePrevClick={() => {}}
            isPaused={true}
            showSoundControls={false}
          />
        );
      } else if (story.background_type === 'image') {
        return (
          <>
            <StoryImage
              story={imageStory}
              onPause={() => {}}
              onResume={() => {}}
              handleNextClick={() => {}}
              handlePrevClick={() => {}}
              isPaused={true}
            />
            <div className="story-image-overlay" />
          </>
        );
      } else if (story.background_type === 'color') {
        return (
          <div
            className="story-color"
            style={{ backgroundColor: story.background_color }}
          >
            <div className="story-image-overlay" />
          </div>
        );
      }
    } else {
      return null;
    }
  }

  function getNextStory() {
    const currStoryIndex = stories.findIndex((s) => s.entityId === currentStory.entityId);
    if (stories[currStoryIndex + 1]) setCurrentStory(stories[currStoryIndex + 1]);
  }

  function getPrevStory() {
    const currStoryIndex = stories.findIndex((s) => s.entityId === currentStory.entityId);
    if (stories[currStoryIndex - 1]) setCurrentStory(stories[currStoryIndex - 1]);
  }

  function onPortraitClick(story: IStoryResultFE) {
    history.push(
      `/${story.entityType === Constants.object_type.hub ? 'company' : story.entityType}/${story.entityHandle}`,
    );
  }

  function getForegroundElement(
    type: string,
    content: IStoryForegroundContent & IStoryMetricsData,
    index: number,
  ) {
    switch (type) {
      case 'text': {
        return (
          <div
            className="textbox"
            key={index}
            style={{
              fontSize: content.style.font_size,
              position: 'absolute',
              zIndex: '11',
              color: content.style.foreground_color,
              top: `${content.style.position_y}%`,
              left: `${content.style.position_x}%`,
            }}
          >
            {content.text}
          </div>
        );
      }
      case 'metric': {
        const metricData = content?.metrics_data;
        const formattedMetricValue =
          metricData?.metric && currencyMetrics.includes(metricData.metric)
            ? formatCurrency(metricData.value, metricData.unit, 'cad')
            : metricData?.value;
        const metricLabel = metricLabels[metricData?.metric as keyof metricLabels];

        return (
          <div
            className="metric-box"
            key={index}
            style={{
              fontSize: content.style.font_size,
              position: 'absolute',
              zIndex: '11',
              color: content.style.foreground_color,
              top: `${content.style.position_y}%`,
              left: `${content.style.position_x}%`,
            }}
          >
            <div className="text-inner">
              <p className="metric-value">{formattedMetricValue}</p>
              <p className="metric-label">{metricLabel}</p>
            </div>
          </div>
        );
      }
      case 'cta': {
        return (
          <div
            className="call-to-action-container"
            key={index}
            style={{
              position: 'absolute',
              backgroundColor: content?.style?.foreground_color,
              zIndex: '11',
              top: `${content.style.position_y}%`,
              left: `${content.style.position_x}%`,
            }}
          >
            <button className={`cta-btn`}>
              <var data-var="content_text">{content?.text}</var>
            </button>
          </div>
        );
      }
      default:
        break;
    }
  }

  function renderAddStory() {
    const addStoryTemplate = (
      <div
        className="storyPreviewCard add-story-action"
        onClick={() => history.push('/story/create')}
      >
        <div
          className="add-story-background"
          style={{ backgroundImage: `url(${user.profile_image_url})` }}
        />
        <img
          src={addStory}
          alt="add story"
        />
        <div className="entity-title">Add To Story</div>
      </div>
    );
    if (
      (isUserLoggedIn && props?.ownerType === 'user') ||
      (props?.ownerType === 'hub' &&
        hubsWithCreatePermission.find((hub: any) => hub?.id === props?.ownerId))
    ) {
      return addStoryTemplate;
    } else {
      return null;
    }
  }

  function getStoryPlaceholders() {
    const length = 4 - stories.length;

    return [...Array(length)].map((element, index) => (
      <div
        key={index}
        className="story-placeholder"
      >
        <div className="background-img" />
        <div className="profile-img" />
      </div>
    ));
  }

  return (
    <div className="StoryCarousel">
      {!isLoading && (
        <Slider {...sliderSettings}>
          {/* SHOULD HANDLE IF USER HAS PERMS IN CASE OF HUBS */}
          {isUserLoggedIn && renderAddStory()}
          {/* Renders Mini Preview Card For Carousel */}
          {stories.map((story, index) => {
            return (
              <div
                className="slider-wrapper"
                key={index}
              >
                <div
                  className="storyPreviewCard"
                  onClick={() => {
                    setShowStoryModal(true);
                    setCurrentStory(story);
                  }}
                  style={{
                    ...props.theme?.primaryBgWithText,
                    backgroundImage: `url(${story.entityCoverImg})`,
                    backgroundSize: `auto 100%`,
                  }}
                >
                  {/* TODO: COMMENTING PREVIEW UNTIL WE CAN IMPROVE */}
                  {/* { renderStoryContent(story.stories[0]) }
                                { story.stories[0]?.foreground_contents?.map((content, index) => {
                                    return getForegroundElement(content?.foreground_type, content, index)
                                })} */}
                  <div className="entity-title">
                    {story?.entityId === user.id && story?.entityType === 'user'
                      ? 'My Stories'
                      : story.entityName}
                  </div>
                  <Portrait
                    currentImage={story.entityProfileImg}
                    size={30}
                    onClick={() => onPortraitClick(story)}
                  />
                </div>
              </div>
            );
          })}
          {stories.length < 4 && getStoryPlaceholders()}
        </Slider>
      )}
      {isLoading && <StoryCarouselSkeleton />}

      <Modal
        show={showStoryModal}
        onClose={() => setShowStoryModal(false)}
      >
        <div className="StoryModal">
          <div className="story-header">
            <Portrait
              currentImage={currentStory?.entityProfileImg}
              size={30}
              onClick={() => onPortraitClick(currentStory)}
            />
            <div className="entity-title">{currentStory?.entityName}</div>
          </div>
          <div className="story-content">
            <Stories
              width={'100%'}
              height={'100%'}
              stories={
                currentStory?.stories?.map((story) => {
                  return {
                    url: story.media_url || '',
                    duration: uiConstants.story_duration,
                    type: story?.background_type,
                    ...story,
                  };
                }) || []
              }
              onStoryChange={() => {}}
              onAllStoriesEnd={getNextStory}
              getPreviousStory={getPrevStory}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
