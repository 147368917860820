import { Dispatch } from 'redux';
import axios from 'axios';
import { routes } from '../helpers';
import { ISettingsState } from '../reducers/settings';

export enum SettingsActionTypes {
  UPDATE_SETTINGS = 'UPDATE_SETTINGS',
}

export interface IUpdateSettingsAction {
  type: SettingsActionTypes.UPDATE_SETTINGS;
}

export type SettingsActions = IUpdateSettingsAction;

export const updateSettings = (settings: Partial<ISettingsState>) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      ...settings,
      type: SettingsActionTypes.UPDATE_SETTINGS,
    });
  };
};

export const getGroupPermissions = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_GROUP_PERMISSIONS).then((response) => {
      dispatch({
        groupPermissions: response.data.value,
        type: SettingsActionTypes.UPDATE_SETTINGS,
      });
    });
  };
};

export const getEventPermissions = () => {
  return async (dispatch: Dispatch, getState: any) => {
    axios.get(routes.GET_EVENT_PERMISSIONS).then((response) => {
      dispatch({
        eventPermissions: response.data.value,
        type: SettingsActionTypes.UPDATE_SETTINGS,
      });
    });
  };
};

export const resetState = () => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: 'RESET',
    });
  };
};
