import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { ISponsor, IUser } from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import {
  defaultCurrency,
  handleInputChange,
  formatCurrency,
  formatFilters,
  sortColumn,
  formatQuery,
} from '../../helpers';
import SortableTable from '../SortableTable/SortableTable';
import { Constants } from '@gigit/constants';
import { localizeHelpers } from '../../localizeHelpers';

import { IGroupState } from '../../reducers/group';
import { getGroupSponsors } from '../../actions/group';

import Portrait from '../Portrait/Portrait';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import FilterData from '../FilterData/FilterData';

import './SponsorManagement.scss';
import ContactDetails from '../ContactDetails/ContactDetails';
import { userSelectors } from '../../selectors/user';
import { IActiveFilter, IOwnerObject, ISortSettings, ReduxActionType } from '../../interfaces';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  getGroupSponsors: ReduxActionType<typeof getGroupSponsors>;
  locale: string;
}

interface IState {
  sort: ISortSettings[];
  showFilterModal: boolean;
  filters: IActiveFilter[];
  showContactDetailsModal: boolean;
  selectedUser: IUser | null;
}

class SponsorManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      sort: [],
      showFilterModal: false,
      filters: [],
      showContactDetailsModal: false,
      selectedUser: null,
    };
  }

  componentDidMount() {
    this.fetchSponsors();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.state.filters !== prevState.filters || this.state.sort !== prevState.sort) {
      this.fetchSponsors();
    }
  }

  fetchSponsors() {
    this.props.getGroupSponsors(
      this.props.groupState.group.id,
      formatQuery({
        filters: this.state.filters,
        sort: this.state.sort,
      }),
    );
  }

  render() {
    const { t } = this.props;

    const currency = this.props.groupState.group.account?.currency ?? defaultCurrency;

    return (
      <div className="section-wrap SponsorManagement">
        <div className="section-title">
          <div className="title-wrap">{t('Sponsors')}</div>
          <div className="SponsorManagement-title-actions">
            <Button
              className="filter-button"
              icon="fal fa-filter"
              onClick={() => {
                this.setState({ showFilterModal: true });
              }}
              text={
                this.state.filters.length > 0
                  ? 'Filter (' + this.state.filters.length + ')'
                  : 'Filter'
              }
            />
          </div>
        </div>
        <div className="section-inner">
          <div className="SponsorManagement">
            <div className="SponsorManagement-list">
              <div className="search">
                {/* <TextField icon="fas fa-search" placeholder={t("Search donors...")} value={this.state.searchValue} type="text" name="searchValue" onChange={(e: any) => { handleInputChange(e, this, false, this.searchDonors); }} /> */}
              </div>
              <div className="list">
                <div className="list-inner">
                  <div className="list-rows">
                    <SortableTable
                      {...this.props}
                      loading={this.props.groupState.sponsorsLoading}
                      onSort={(sortSettings) => {
                        this.setState({ sort: sortSettings });
                      }}
                      columns={[
                        { id: 'portrait', label: 'Portrait', sortable: false },
                        { id: 'user.display_name', label: 'Sponsor Name' },
                        { id: 'number_of_sponsorships', label: '# of Sponsorships' },
                        { id: 'average_value', label: 'Avg. Sponsor Value' },
                        { id: 'total_value', label: 'Total Value' },
                      ]}
                      data={this.props.groupState.groupSponsors?.map((item: ISponsor, index) => {
                        return {
                          row: [
                            {
                              id: 'portrait',
                              content: (
                                <Portrait
                                  currentImage={item.user?.profile_image_url}
                                  size={50}
                                />
                              ),
                            },
                            {
                              id: 'user.display_name',
                              content: (
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.history.push(`/user/${item.user?.handle}`);
                                  }}
                                  className="sponsor-name"
                                  notranslate="yes"
                                >
                                  {item.user?.display_name}
                                </span>
                              ),
                              notranslate: 'yes',
                            },
                            {
                              id: 'number_of_sponsorships',
                              content: (
                                <span notranslate="yes">
                                  {localizeHelpers.formatNumber(
                                    item.number_of_sponsorships || 0,
                                    this.props.locale,
                                  )}
                                </span>
                              ),
                            },
                            {
                              id: 'average_value',
                              content: (
                                <span notranslate="yes">
                                  {formatCurrency(item.average_value, currency, this.props.locale)}
                                </span>
                              ),
                            },
                            {
                              id: 'total_value',
                              content: (
                                <span notranslate="yes">
                                  {formatCurrency(item.total_value, currency, this.props.locale)}
                                </span>
                              ),
                            },
                          ],
                        };
                      })}
                    />
                    {this.props.groupState.groupSponsors &&
                      this.props.groupState.groupSponsors.length === 0 && (
                        <div className="empty">You don't have any sponsors, yet.</div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          class="filter-modal"
          show={this.state.showFilterModal}
          onClose={() => {
            this.setState({ showFilterModal: false });
          }}
        >
          <FilterData
            {...this.props}
            currentFilters={this.state.filters}
            filterOptions={[
              { id: 'user.display_name', label: 'Sponsor Name', type: 'text' },
              { id: 'number_of_sponsorships', label: '# of Sponsorships', type: 'number' },
              { id: 'average_value', label: 'Avg. Sponsor Value', type: 'number' },
              { id: 'total_value', label: 'Total Value', type: 'number' },
            ]}
            onApply={(appliedFilters: IActiveFilter[]) => {
              this.setState({ filters: appliedFilters, showFilterModal: false });
            }}
          />
        </Modal>
        <Modal
          class="ContactDetailsModal"
          show={this.state.showContactDetailsModal}
          onClose={() => {
            this.setState({ showContactDetailsModal: false });
          }}
          title={t('Contact Details')}
        >
          <ContactDetails
            userId={this.state.selectedUser?.id!}
            {...this.props}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getGroupSponsors,
};

export default withRouter(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SponsorManagement)),
);
