import React from 'react';
import { connect } from 'react-redux';
import { formatCurrency } from '../../../helpers';
import { Constants } from '@gigit/constants';

import { IAppState } from '../../../store';

import './ProgressBarWidget.scss';
import { userSelectors } from '../../../selectors/user';

import kambeoWhite from '../../../assets/kambeo_logo_white.svg';
import kambeoBlack from '../../../assets/logo-dark.svg';

interface IProps {
  locale: string;
  widget: IEventWidgetProgressBarProps;

  isPreview?: boolean;
}

export interface IEventWidgetProgressBarProps {
  individual_raised: number;
  individual_goal: number;
  currency: string;

  widget_style: string;
  widget_theme: string;

  event_name: string;
  event_profile_image_url: string;
  user_handle: string;
}

interface IState {}

/** Stateless widget that displays a progress bar for an event individual.*/
class ProgressBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  getBarWidth() {
    return Math.min(
      100,
      Math.max(0, (this.props.widget.individual_raised / this.props.widget.individual_goal) * 100),
    );
  }

  render() {
    const raised = this.props.widget.individual_raised;
    const goal = this.props.widget.individual_goal;

    const currency = this.props.widget.currency;

    let className = 'ProgressBarWidget';

    className += ` ${this.props.widget.widget_theme}`;

    className += ` ${this.props.widget.widget_style.replace(/_/g, '-')}`;

    if (this.props.isPreview) {
      className += ` preview`;
    }

    return (
      <div className={className}>
        {this.props.widget.event_profile_image_url !== '' && (
          <div className="pb-portrait">
            <div className="portrait-inner">
              <img
                alt={'Event Profile Image'}
                src={this.props.widget.event_profile_image_url}
              />
            </div>
          </div>
        )}
        <div className="pb-content">
          <div className="pb-content-inner">
            <div className="title-area">
              <div className="title">{this.props.widget.event_name}</div>
              {/*<div className="url">Donate at <span>gigit.charity/{this.props.widget.user_handle}</span></div>*/}
            </div>

            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: this.getBarWidth() + '%' }}
              ></div>
            </div>

            <div className="amounts">
              {formatCurrency(raised, currency, this.props.locale, { decimal_places: 0 })} /{' '}
              {formatCurrency(goal, currency, this.props.locale, { decimal_places: 0 })}
            </div>

            <div className="powered-by">
              <div className="powered-by-label">Powered by</div>
              <img
                src={
                  this.props.widget.widget_theme === Constants.event_widget_theme.dark
                    ? kambeoWhite
                    : kambeoBlack
                }
                className="logo"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar);
