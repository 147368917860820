import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { userSelectors } from '../../../selectors/user';
import { IAppState } from '../../../store';

import './OnboardingWrapper.scss';

interface IProps {
  children: ReactNode;
  requiresAuth: boolean;
  redirect: string;
  className?: string;
}

const OnboardingWrapper: React.FC<IProps> = (props: IProps) => {
  const isLoggedIn = useSelector((state: IAppState) => userSelectors.isUserAuthenticated(state));

  if (props.requiresAuth && !isLoggedIn) {
    return <Redirect to={props.redirect} />;
  } else {
    return (
      <div
        className={props.className ? `OnboardingWrapper ${props.className}` : `OnboardingWrapper`}
      >
        {props.children}
      </div>
    );
  }
};

export default OnboardingWrapper;
