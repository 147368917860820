import { Reducer } from 'redux';
import { GeolocationActions, GeolocationActionTypes } from '../actions/geolocation';

export interface IGeolocationState {
  lat: number;
  lng: number;
  locality: string;
  formattedAddress: string;
  isGeolocationLoading: boolean;
  isCached: boolean;
  lastUpdated: number;
  error: string;
}

export const initialGeolocationState: IGeolocationState = {
  lat: 43.2607249,
  lng: -80.0732315,
  locality: 'Hamilton, ON',
  formattedAddress: 'Hamilton, Ontario',
  isGeolocationLoading: false,
  isCached: false,
  lastUpdated: 0,
  error: '',
};

export const geolocationReducer: Reducer<IGeolocationState, GeolocationActions> = (
  state = initialGeolocationState,
  action,
) => {
  switch (action.type) {
    case GeolocationActionTypes.UPDATE_GEOLOCATION: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
