import React, { useEffect, useState } from 'react';
import ListCard from '../../Shared/ListCard/ListCard';
import './HubStoreSingleItem.scss';
import { IGroup, IHub, IStoreItem } from '@gigit/interfaces';
import { IAppState } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { connect } from 'react-redux';
import { defaultCurrency, formatCurrency, stripHtml } from '../../../../helpers';
import PopupMenu, { IPopupMenuItem } from '../../../shared/PopupMenu/PopupMenu';
import { groupRequestActions } from '../../../../requestActions';
import useToastDispatcher from '../../../../hooks/useToaster';

interface IProps {
  storeItem: IStoreItem;
  openStoreItemDetailModal?: () => void;
  locale: string;
  editStoreItem?: (item: IStoreItem) => void;
  onDeleteItemClicked?: (item: IStoreItem) => void;
  editMode?: boolean;
  hub?: IHub;
}

const HubStoreSingleItem: React.FC<IProps> = (props) => {
  const [showEditMenu, setShowEditMenu] = useState<boolean>(false);
  const [groupInfo, setGroupInfo] = useState<IGroup | null>(null);
  const [currency, setCurrency] = useState<string>(props.hub?.account?.currency || defaultCurrency);

  const { dispatchToastError } = useToastDispatcher();

  function isStoreItemVisible(): boolean {
    //TODO: missing BE
    return false;
  }

  function isStoreItemPined(): boolean {
    //TODO: missing BE
    return true;
  }

  function togglePinedItem() {
    //TODO: missing BE
  }

  function toggleItemVisible() {
    //TODO: missing BE
  }

  const editMenuItems: IPopupMenuItem[] = [
    {
      id: 'edit',
      label: 'Edit',
      onClick: () => {
        props?.editStoreItem?.(props.storeItem);
      },
    },
    {
      id: 'delete',
      label: 'Delete',
      onClick: () => {
        props?.onDeleteItemClicked?.(props.storeItem);
      },
    },
    {
      id: 'hide_show_item',
      label: `${isStoreItemVisible() ? 'Hide' : 'Show'} Item`,
      onClick: () => {
        togglePinedItem();
      },
    },
    {
      id: 'pin_item',
      label: `${isStoreItemPined() ? 'Unpin' : 'Pin'} Item`,
      onClick: () => {
        togglePinedItem();
      },
    },
  ];

  function getGroupInfo() {
    groupRequestActions
      .getGroupByHandleOrId(props.storeItem.owner_id)
      .then((res) => {
        setGroupInfo(res);

        if (res?.account?.currency) {
          setCurrency(res.account.currency);
        }
      })
      .catch((error) => dispatchToastError(error, 'Get Cause Info'));
  }

  useEffect(() => {
    getGroupInfo();
  }, [props.storeItem]);

  return (
    <>
      <div
        className="HubStoreSingleItem"
        onClick={() => props.openStoreItemDetailModal?.()}
      >
        <ListCard
          cardData={{
            profile_image_url: groupInfo?.profile_image_url || '',
            cover_image_url:
              props.storeItem.media.length > 0 ? props.storeItem.media[0]?.url || '' : '',
          }}
        >
          {props?.editMode && (
            <>
              <div className="added-icons">
                {isStoreItemPined() && (
                  <span
                    className="pined"
                    onClick={() => togglePinedItem()}
                  >
                    <i className="far fa-thumbtack" />
                  </span>
                )}

                {!isStoreItemVisible() && (
                  <span
                    className="not-visible"
                    onClick={() => toggleItemVisible()}
                  >
                    <i className="far fa-eye-slash" />
                  </span>
                )}
              </div>

              <PopupMenu
                showMenuConfig={{
                  showMenu: showEditMenu,
                  setShowMenu: setShowEditMenu,
                  position: {
                    type: 'bottom',
                  },
                }}
                menuItems={editMenuItems}
                className={`Dropdown edit-menu ${showEditMenu ? 'show' : ''}`}
                onClick={() => setShowEditMenu(true)}
              >
                <i className="fas fa-ellipsis-v" />
              </PopupMenu>
            </>
          )}

          <div
            className="store-item-content"
            notranslate="yes"
          >
            <span className="title">{props.storeItem.name}</span>
            <span className="desc">{stripHtml(props.storeItem.description || '')}</span>

            {/*<span className={`price ${props.storeItem?.locked? 'locked': ''}`}>*/}
            <span className="price">
              {formatCurrency(Number(props.storeItem.price || 0), currency, props.locale)}
              {
                // props.storeItem?.locked &&
                // <i className="fas fa-lock-alt"/>
              }
            </span>
          </div>
        </ListCard>
      </div>
    </>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HubStoreSingleItem);
