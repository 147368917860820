import React, { useEffect, useState, useCallback } from 'react';
import './GenerateAndEditTaxReceipt.scss';
import { Constants } from '@gigit/constants';
import { Link } from 'react-router-dom';
import TextField from '../../../TextField/TextField';
import { localizeHelpers } from '../../../../localizeHelpers';
import Dropdown from '../../../Dropdown/Dropdown';
import { defaultCurrency, errorHelpers, toastError, toastSuccess } from '../../../../helpers';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import { ITransactionSummary } from '@gigit/interfaces';
import { donationRequestActions } from '../../../../requestActions';
import { IOwnerObject, IToast } from '../../../../interfaces';
import { IGroupState } from '../../../../reducers/group';
import { uiConstants } from '../../../../constants/uiConstants';
import { localeConstants } from '../../../../constants';

interface IProps {
  showGenerateAndEditModal: boolean;
  activeDonation: ITransactionSummary | null;
  hideModal(): void;
  owner: IOwnerObject;
  getDonations(params: { groupId?: string; eventId?: string }): void;
  createToast(toast: IToast): void;
  groupState: IGroupState;
}

/** Modal component for generating and/or editing a tax receipt. */
export const GenerateAndEditTaxReceipt: React.FC<IProps> = (props) => {
  const [currency, setCurrency] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const [taxReceiptAmount, setTaxReceiptAmount] = useState<string>(
    `${uiConstants.minimum_tax_receipt_amount}`,
  );
  const [tmpTaxReceiptAmount, setTmpTaxReceiptAmount] = useState<number>(
    uiConstants.minimum_tax_receipt_amount,
  );
  const [taxReceiptNumber, setTaxReceiptNumber] = useState<string>('');
  const [continueSequenceToggle, setContinueSequenceToggle] = useState<boolean>(false);
  const [taxReceiptPrefix, setTaxReceiptPrefix] = useState<string>('');
  const [taxReceiptPadding, setTaxReceiptPadding] = useState<number>(0);
  const [currentSequenceNumber, setCurrentSequenceNumber] = useState<string>('');
  const [tmpCurrentSequenceNumber, setTmpCurrentSequenceNumber] = useState<number>(0);
  const [taxReceiptNumberIsUsed, setTaxReceiptNumberIsUsed] = useState<boolean>(false);
  const [toggleEditReceiptNumber, setToggleEditReceiptNumber] = useState<boolean>(false);

  let title = '';
  switch (props.activeDonation?.tax_receipt_status?.code) {
    case Constants.tax_receipt_status.not_created:
      title = 'Generate Tax Receipt';
      break;
    case Constants.tax_receipt_status.sent:
      title = 'Re-Generate Tax Receipt';
      break;
    case Constants.tax_receipt_status.pending:
      title = 'Edit Tax Receipt';
      break;
  }

  const checkTaxReceiptNumberIsUsed = async (sequenceNumber: string): Promise<void> => {
    try {
      if (props.activeDonation?.campaign_id) {
        const { isUsed, taxReceiptNumber } = await donationRequestActions.isUsedTaxReceiptNumber({
          sequenceNumber: sequenceNumber,
          campaignId: props.activeDonation.campaign_id,
          groupId: props.groupState.group.id,
        });

        setTaxReceiptNumberIsUsed(isUsed);
        setTaxReceiptNumber(taxReceiptNumber);
        setCurrentSequenceNumber(sequenceNumber.toString());
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Check is tax receipt number used');
      props.createToast(toast);
    }
  };

  const getCurrentTaxReceiptInfo = async (
    donation: ITransactionSummary,
  ): Promise<{
    prefix: string;
    padding: number;
    sequence_number: number;
    receiptNumber: string;
  }> => {
    try {
      if (donation?.campaign_id) {
        return await donationRequestActions.getCurrentTaxReceiptInfo({
          groupId: donation.group_id,
          campaignId: donation.campaign_id,
        });
      }
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errObj.translatedMessage, 'Get current sequence number');
      props.createToast(toast);
    }

    return {
      prefix: '',
      padding: 0,
      sequence_number: 0,
      receiptNumber: '',
    };
  };

  const initStates = useCallback(
    async (activeDonation: ITransactionSummary) => {
      if (activeDonation?.id && activeDonation?.campaign_id) {
        const { prefix, padding, sequence_number, receiptNumber } =
          await getCurrentTaxReceiptInfo(activeDonation);

        setTaxReceiptPrefix(prefix);
        setTaxReceiptPadding(padding);
        setCurrentSequenceNumber(String(sequence_number || 1));
        setTmpCurrentSequenceNumber(sequence_number || 1);
        setTaxReceiptNumber(receiptNumber);
        setTmpTaxReceiptAmount(
          activeDonation?.maximum_tax_receipt_amount || activeDonation?.tax_receipt_amount || 0,
        );
        setCurrency(activeDonation.amounts.currency ?? defaultCurrency);
        setLanguage(activeDonation.user?.language || localeConstants.defaultLanguage);
        setTaxReceiptAmount(
          Number(activeDonation?.tax_receipt_amount).toFixed(2).toString() || '0,00',
        );
      }
    },
    [props.activeDonation],
  );

  useEffect(() => {
    if (props.activeDonation) {
      initStates(props.activeDonation);
    }

    return () => {
      setCurrency('');
      setLanguage('');
      setTaxReceiptAmount(`${uiConstants.minimum_tax_receipt_amount}`);
      setTmpTaxReceiptAmount(uiConstants.minimum_tax_receipt_amount);
      setTaxReceiptNumber('');
      setCurrentSequenceNumber('1');
      setTmpCurrentSequenceNumber(1);
      setTaxReceiptNumberIsUsed(false);
      setTaxReceiptPrefix('');
      setTaxReceiptPadding(0);
      setToggleEditReceiptNumber(false);
    };
  }, [initStates, props.activeDonation]);

  const generateTaxReceipt = async (params?: {
    send?: boolean;
    regenerate?: boolean;
    edit?: boolean;
  }): Promise<void> => {
    if (props.activeDonation?.id) {
      try {
        await donationRequestActions.generateTaxReceipt({
          groupId: props.owner.ownerType === 'group' ? props.owner.ownerId : undefined,
          eventId: props.owner.ownerType === 'event' ? props.owner.ownerId : undefined,
          donationId: props.activeDonation.id,
          language,
          send: params?.send,
          edit: params?.edit,
          amount: taxReceiptAmount ? +taxReceiptAmount : undefined,
          regenerate: params?.regenerate,
          override_next_sequence_number:
            continueSequenceToggle ||
            (params?.edit && !toggleEditReceiptNumber
              ? undefined
              : +currentSequenceNumber === tmpCurrentSequenceNumber),
          sequenceNumber:
            params?.edit && !toggleEditReceiptNumber ? undefined : +currentSequenceNumber,
        });

        props.getDonations({
          groupId: props.owner.ownerType === 'group' ? props.owner.ownerId : undefined,
          eventId: props.owner.ownerType === 'event' ? props.owner.ownerId : undefined,
        });
        const toast = toastSuccess('Successfully Generated Tax Receipt', 'Generate Tax Receipt');
        props.createToast(toast);
        props.hideModal();
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(
          errorObj.translatedMessage,
          params?.send
            ? 'Generate And Send Tax Receipt'
            : params?.regenerate
              ? 'Re-Generate Tax Receipt'
              : 'Generate Tax Receipt',
        );
        props.createToast(toast);
      }
    }
  };

  return (
    <Modal
      contentClassName="GenerateTaxReceipt"
      title={title}
      show={props.showGenerateAndEditModal}
      onClose={() => props.hideModal()}
    >
      <form className="generate-tax-receipt">
        <div className="tip">
          You can customize your cause's tax receipt template on the
          <Link to={`/group/${props.owner.ownerHandle}/admin?t=settings`}>
            {' '}
            cause settings page
          </Link>
          .
        </div>

        {props.activeDonation?.tax_receipt_status?.code ===
          Constants.tax_receipt_status.pending && (
          <div className="two-columns">
            <TextField
              type="text"
              disabled={true}
              label="Current Tax Receipt No."
              value={props.activeDonation?.receipt_number?.toString() || ''}
              name="currentTaxReceiptNumber"
              onChange={() => {}}
            />
            <span
              className="toggle"
              onClick={() => {
                setToggleEditReceiptNumber((v) => !v);
              }}
            >
              <i
                className={`fad ${toggleEditReceiptNumber ? 'fa-toggle-on' : 'fa-toggle-off'}`}
                aria-hidden="true"
              />
              Manually Enter Receipt Number
            </span>
          </div>
        )}

        {(props.activeDonation?.tax_receipt_status?.code !== Constants.tax_receipt_status.pending ||
          (props.activeDonation?.tax_receipt_status?.code ===
            Constants.tax_receipt_status.pending &&
            toggleEditReceiptNumber)) && (
          <>
            <div className="three-columns">
              <TextField
                type="text"
                disabled={true}
                label="Prefix"
                value={taxReceiptPrefix}
                name="generateTaxReceiptPrefix"
                onChange={() => {}}
              />
              <TextField
                className="middle"
                type="text"
                disabled={true}
                label="Padding"
                value={taxReceiptPadding.toString()}
                name="generateTaxReceiptPadding"
                onChange={() => {}}
              />
              <TextField
                type="number"
                min="1"
                step="1"
                label="Next available sequence number"
                error={
                  +currentSequenceNumber < 1
                    ? localizeHelpers.translate(
                        'Starting Number must be greater than or equal to {{min}}',
                        { min: 1 },
                      )
                    : undefined
                }
                value={currentSequenceNumber}
                name="generateTaxReceiptStartingNumber"
                onChange={async (e) => {
                  setCurrentSequenceNumber(e.target.value);
                  if (+e.target.value > 0) {
                    await checkTaxReceiptNumberIsUsed(e.target.value);
                  }
                }}
              />
            </div>

            {+currentSequenceNumber !== tmpCurrentSequenceNumber && +currentSequenceNumber > 0 && (
              <div className="sequence-warning">
                <span className="warning">
                  Warning: Overriding receipt number. The next one would have been
                  <b>
                    {' '}
                    <var data-var="next_sequence_number">{taxReceiptNumber}</var>{' '}
                  </b>
                  (
                  <var data-var="skipped_sequence_number">
                    {Math.abs(+currentSequenceNumber - tmpCurrentSequenceNumber)}
                  </var>{' '}
                  skipped numbers)
                </span>

                <span
                  className="toggle"
                  onClick={() => {
                    setContinueSequenceToggle((v) => !v);
                  }}
                >
                  <i
                    className={`fad ${continueSequenceToggle ? 'fa-toggle-on' : 'fa-toggle-off'}`}
                    aria-hidden="true"
                  />
                  Continue sequence at new number
                </span>
              </div>
            )}

            <TextField
              type="text"
              disabled={true}
              label="Next available Tax Receipt No."
              value={taxReceiptNumber.toString()}
              name="generateTaxReceiptNumber"
              onChange={() => {}}
              error={taxReceiptNumberIsUsed ? 'Tax receipt number is already used' : undefined}
            />
          </>
        )}

        <div className="two-columns">
          <TextField
            icon={`fas ${currency === 'eur' ? 'fa-euro-sign' : 'fa-dollar-sign'}`}
            type="text"
            label="Tax Receipt Amount"
            min="0"
            value={taxReceiptAmount}
            name="generateTaxReceiptAmount"
            error={
              +taxReceiptAmount < uiConstants.minimum_tax_receipt_amount ||
              +taxReceiptAmount > tmpTaxReceiptAmount
                ? localizeHelpers.translate(
                    'Amount must be greater than {{min}} and less or equal to {{max}}',
                    { min: uiConstants.minimum_tax_receipt_amount, max: tmpTaxReceiptAmount },
                  )
                : undefined
            }
            onChange={(e) => {
              setTaxReceiptAmount(e.target.value);
            }}
            blurCB={() => {
              setTaxReceiptAmount((v) => {
                return Number(v).toFixed(2).toString();
              });
            }}
          />
          <Dropdown
            disabled={true} // at the moment
            value={currency}
            label="Currency"
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
            name="generateCurrency"
            options={localeConstants.supportedCurrencyOptions}
          />
        </div>
        <Dropdown
          value={language}
          label="Language"
          onChange={(e) => {
            setLanguage(e.target.value);
          }}
          name="generateLanguage"
          options={localeConstants.languageOptions}
        />

        <div className="controls">
          <Button
            buttonClass="cancel-button"
            type="button"
            onClick={() => {
              props.hideModal();
            }}
            text="Cancel"
          />
          {props.activeDonation?.tax_receipt_status?.code ===
            Constants.tax_receipt_status.not_created && (
            <>
              <Button
                buttonClass="generate-button"
                type="button"
                onClick={async () => {
                  await generateTaxReceipt();
                }}
                text="Generate"
                isDisabled={
                  taxReceiptNumberIsUsed ||
                  +currentSequenceNumber < 1 ||
                  +taxReceiptAmount < uiConstants.minimum_tax_receipt_amount ||
                  +taxReceiptAmount > tmpTaxReceiptAmount
                }
              />
              <Button
                buttonClass="generate-send-button"
                type="button"
                onClick={async () => {
                  await generateTaxReceipt({ send: true });
                }}
                text="Generate & Send"
                isDisabled={
                  taxReceiptNumberIsUsed ||
                  +currentSequenceNumber < 1 ||
                  +taxReceiptAmount < uiConstants.minimum_tax_receipt_amount ||
                  +taxReceiptAmount > tmpTaxReceiptAmount
                }
              />
            </>
          )}
          {props.activeDonation?.tax_receipt_status?.code === Constants.tax_receipt_status.sent && (
            <Button
              buttonClass="regenerate-button"
              type="button"
              onClick={async () => {
                await generateTaxReceipt({ regenerate: true });
              }}
              text="Re-Generate"
              isDisabled={
                taxReceiptNumberIsUsed ||
                +currentSequenceNumber < 1 ||
                +taxReceiptAmount < uiConstants.minimum_tax_receipt_amount ||
                +taxReceiptAmount > tmpTaxReceiptAmount
              }
            />
          )}
          {props.activeDonation?.tax_receipt_status?.code ===
            Constants.tax_receipt_status.pending && (
            <Button
              buttonClass="edit-button"
              type="button"
              onClick={async () => {
                await generateTaxReceipt({ edit: true });
              }}
              text="Edit"
              isDisabled={
                (toggleEditReceiptNumber && taxReceiptNumberIsUsed) ||
                +currentSequenceNumber < 1 ||
                +taxReceiptAmount < uiConstants.minimum_tax_receipt_amount
              }
            />
          )}
        </div>
      </form>
    </Modal>
  );
};
