import { userSelectors } from '../../selectors/user';
import Axios from 'axios';
import React from 'react';
import Joyride from 'react-joyride';
import { Config } from '@gigit/config';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { TwitchEmbed } from 'react-twitch-embed';

import {
  defaultCurrency,
  handleInputChange,
  formatCurrency,
  setSEOMetatags,
  toastError,
  toastSuccess,
  swapRouteParams,
  routes,
  errorHelpers,
} from '../../helpers';
import { IToast, IStep } from '../../interfaces';
import { localizeHelpers } from '../../localizeHelpers';

import { IAppState } from '../../store';
import { IUserState } from '../../reducers/user';
import { IEventState } from '../../reducers/event';
import { IFlowStateConfig } from '../../reducers/register';

import Portrait from '../../components/Portrait/Portrait';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import Share from '../../components/Share/Share';
import TextField from '../../components/TextField/TextField';

import { getEvent, joinEventTeam, resetTeamError } from '../../actions/event';
import { createToast } from '../../actions/toaster';
import { addItem } from '../../actions/cart';
import { setFlow } from '../../actions/register';

import TeamDefault from './TeamDefault/TeamDefault';
import TeamStreamer from './TeamStreamer/TeamStreamer';

import './Team.scss';
import { IEventIndividual, IEventTeam } from '@gigit/interfaces';
import { teamRequestActions } from '../../requestActions/team';
import { uiConstants } from '../../constants/uiConstants';
import { BackSplash } from '../../components/ProfilePageComponents/BackSplash/BackSplash';

interface IProps extends RouteComponentProps<any> {
  addItem(item: any, parent_type: string): void;
  userState: IUserState;
  eventState: IEventState;
  locale: string;
  joinEventTeam(event: string, team: string, payload: any): void;
  getEvent(handle: string): void;
  createToast(toast: IToast): void;
  resetTeamError(): void;
  setFlow(flowConig: IFlowStateConfig): void;
}

interface IState {
  headerClass: string;
  sidebarRef: any;
  sidebarClass: string;
  team: any;
  eventTeam: IEventTeam | null;
  isTeamLoading: boolean;
  members: IEventIndividual[];
  steps: IStep[];
  editTeamName: boolean;
  formats: Array<any>;
  modules: any;
  editStory: boolean;
  showShareModal: boolean;
  notFound: boolean;
  showLoginModal: boolean;
  registerRedirectUrl: string;
  registerFunction: string;
  pushUrl: string;
  params: any;
  showFundraisingRegistrationModal: boolean;
  fundraisingTicketQty: string;
  showConfirmModal: boolean;
  subHeaderRef: any;
  showTwitchChat: boolean;
  innerWidth: number;
  subHeaderInitialPosition: number;
  activeContentCreator: string;
  needATour: boolean;
}

const StreamEmbed = React.memo((props: any) => {
  return <TwitchEmbed {...props} />;
});

class Team extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let _params = queryString.parse(this.props.location.search);

    this.state = {
      headerClass: '',
      sidebarRef: React.createRef(),
      subHeaderRef: React.createRef(),
      sidebarClass: '',
      team: {},
      eventTeam: null,
      isTeamLoading: false,
      members: [],
      steps: [
        {
          disableBeacon: true,
          target: '.sidebar .profile',
          content: 'Customize your profile picture to make your page stand out.',
        },
        {
          disableBeacon: true,
          target: '.main .CoverImage',
          content: 'Give your page a cover image to make it exciting!',
        },
        {
          disableBeacon: true,
          target: '.main .story',
          content: 'Tell people why you are raising money to attract more donations.',
        },
        {
          disableBeacon: true,
          target: '.sidebar .manage',
          content: 'Manage your fundraising goal and other settings for your fundraiser.',
        },
      ],
      editTeamName: false,
      modules: {
        toolbar: uiConstants.quill.defaultToolbar,
        clipboard: {
          matchVisual: false,
        },
      },
      formats: uiConstants.quill.defaultFormats,
      editStory: false,
      showShareModal: false,
      notFound: false,
      showLoginModal: false,
      registerRedirectUrl: '',
      registerFunction: '',
      pushUrl: '',
      params: _params,
      showFundraisingRegistrationModal: false,
      fundraisingTicketQty: '1',
      showConfirmModal: false,
      showTwitchChat: true,
      innerWidth: window.innerWidth,
      subHeaderInitialPosition: -1,
      activeContentCreator: '',
      needATour: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.handleTeamInputChange = this.handleTeamInputChange.bind(this);
    this.handleStoryChange = this.handleStoryChange.bind(this);
    this.joinTeam = this.joinTeam.bind(this);
    this.hasContentCreator = this.hasContentCreator.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    (window as any).prerenderReady = false;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    // window.addEventListener('scroll', this.handleScroll);

    this.props.getEvent(this.props.match.params.handle);
    let _params = queryString.parse(this.props.location.search);

    this.setState({
      needATour: !!_params.help,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
    // window.removeEventListener('scroll', this.handleScroll);
  }

  handleResize() {
    if (window.innerWidth <= 1240 && this.state.showTwitchChat) {
      this.setState({
        showTwitchChat: false,
      });
    } else if (window.innerWidth > 1240 && !this.state.showTwitchChat) {
      this.setState({
        showTwitchChat: true,
      });
    }

    this.setState({
      innerWidth: window.innerWidth,
    });
  }

  handleScroll() {
    if (window.scrollY >= 40 && this.state.sidebarClass !== 'fixed') {
      this.setState({
        sidebarClass: 'fixed',
      });
    } else if (window.scrollY < 40 && this.state.sidebarClass !== '') {
      this.setState({
        sidebarClass: '',
      });
    }
  }

  onImageChange(fileData: { type: string; file: string }) {
    switch (fileData.type) {
      case 'cover_image_url':
        this.updateTeamCoverImage(fileData.file);
        break;
      case 'profile_image_url':
        this.updateTeamProfileImage(fileData.file);
        break;
    }
  }

  hasContentCreator() {
    for (let m in this.state.members) {
      let _member = this.state.members[m];

      if (_member.content_creator) {
        return true;
      }
    }

    return false;
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.state.subHeaderRef.current !== null && this.state.subHeaderInitialPosition === -1) {
      this.setState({
        subHeaderInitialPosition: this.state.subHeaderRef.current.offsetTop,
      });
    }

    if (
      this.props.eventState.event !== prevProps.eventState.event &&
      this.props.eventState.event.id !== ''
    ) {
      this.getTeam(this.props.eventState.event.id, this.props.match.params.id);
    }

    if (
      prevState.isTeamLoading &&
      !this.state.isTeamLoading &&
      this.state.eventTeam?.handle === '' &&
      !this.props.userState.awaitingToken
    ) {
      this.setState({
        notFound: true,
      });
    }

    if (prevState.members !== this.state.members) {
      for (let m in this.state.members) {
        let _member = this.state.members[m];

        if (_member.content_creator) {
          this.setState({
            activeContentCreator: _member?.user?.twitch?.login ?? '',
          });
        }
      }
    }

    if (prevProps.eventState.isEventLoading && !this.props.eventState.isEventLoading) {
      if (!this.props.eventState.event.registration_item_id) {
        if (this.state.params.fn && this[this.state.params.fn as keyof Team]) {
          this[this.state.params.fn as keyof Team]();
        }
      }
    }

    if (
      prevProps.eventState.registrationItemLoading !==
        this.props.eventState.registrationItemLoading &&
      !this.props.eventState.registrationItemLoading
    ) {
      if (this.state.params.fn && this[this.state.params.fn as keyof Team]) {
        this[this.state.params.fn as keyof Team]();
      }
    }

    if (
      prevState.eventTeam !== this.state.eventTeam &&
      this.state.eventTeam?.handle === this.props.match.params.id &&
      this.state.eventTeam
    ) {
      const { id, profile_image_url, cover_image_url, name } = this.state.eventTeam;
      const { handle: eventHandle } = this.props.eventState.event;

      setSEOMetatags({
        urlPath: `event/${eventHandle}/team/${id}`,
        title: name,
        description: undefined,
        imageURL: profile_image_url,
        coverImageURL: cover_image_url,
      });

      this.setState({
        team: this.state.eventTeam,
      });
    }

    if (prevProps.eventState.isJoiningTeam && !this.props.eventState.isJoiningTeam) {
      if (this.props.eventState.teamSuccess && this.props.eventState.teamError === '') {
        const toast = toastSuccess(
          localizeHelpers.translate('You successfully joined {{team_name}}.', {
            team_name: this.state.eventTeam?.name,
          }),
          'Team Success',
        );
        this.props.createToast(toast);
        if (this.props.eventState.event.id !== '') {
          this.getTeam(this.props.eventState.event.id, this.state.team.id);
        }
      } else if (!this.props.eventState.teamSuccess && this.props.eventState.teamError !== '') {
        const toast = toastError(this.props.eventState.teamError, 'Team Error');
        this.props.createToast(toast);

        this.props.resetTeamError();
      }
    }
  }

  handleTeamInputChange(event: any, self: any, prevent?: boolean, callback?: any) {
    if (prevent === true) {
      event.preventDefault();
    }

    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'radio'
          ? target.id
          : target.value;
    const name = target.name;

    self.setState(
      {
        team: {
          ...this.state.team,
          [name]: value,
        },
      },
      () => {
        if (callback !== undefined) {
          callback();
        }
      },
    );
  }

  async updateTeamCoverImage(fileAddress: string) {
    try {
      const newTeamCoverImage = await teamRequestActions.updateTeamCoverImage(fileAddress);
      if (this.state.eventTeam) {
        let updatedTeam = { ...this.state.eventTeam, ...{ cover_image_url: newTeamCoverImage } };
        if (updatedTeam.id) {
          this.updateTeam(updatedTeam.event_id, updatedTeam.id, updatedTeam);
        }
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Team Cover Image');
      createToast(toast);
    }
  }

  async updateTeamProfileImage(fileAddress: string) {
    try {
      const newTeamProfileImage = await teamRequestActions.updateTeamProfileImage(fileAddress);
      if (this.state.eventTeam) {
        let updatedTeam = {
          ...this.state.eventTeam,
          ...{ profile_image_url: newTeamProfileImage },
        };
        if (updatedTeam.id) {
          this.updateTeam(updatedTeam.event_id, updatedTeam.id, updatedTeam);
        }
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Team Profile Image');
      createToast(toast);
    }
  }

  async updateTeam(eventId: string, teamId: string, payload: IEventTeam) {
    try {
      await teamRequestActions.updateTeam(eventId, teamId, payload);
      this.getTeam(eventId, teamId);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Team');
      createToast(toast);
    }
  }

  async getTeam(eventId: string, teamId: string) {
    try {
      const eventTeam = await teamRequestActions.getTeam(eventId, teamId);
      this.setState({ eventTeam });
      if (eventTeam.id) {
        this.getTeamMembers(eventId, eventTeam.id);
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Team');
      createToast(toast);
    }
  }

  async getTeamMembers(eventId: string, teamId: string) {
    try {
      const members = await teamRequestActions.getTeamMembers(eventId, teamId);
      this.setState({ members });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Team Members');
      createToast(toast);
    }
  }

  handleStoryChange(content: string) {
    this.setState({
      team: {
        ...this.state.team,
        story: content,
      },
    });
  }

  joinTeam() {
    const userAlreadyJoined = this.state.members.find(
      (m) => m.user_id === this.props.userState.user.id,
    );

    if (userAlreadyJoined) {
      this.props.history.push(
        `/event/${this.props.eventState.event.handle}/individual/${this.props.userState.user.handle}`,
      );
    }

    if (this.props.userState.isLoggedIn) {
      //check if user has an Individual page for this event
      Axios.get(
        swapRouteParams(routes.CHECK_FUNDRAISE_STATUS, {
          eventId: this.props.eventState.event.id,
          email: this.props.userState.user.email,
        }),
      ).then((response) => {
        if (response?.data?.isIndividual) {
          this.props.joinEventTeam(
            this.props.eventState.event.id,
            this.state.eventTeam?.id || '',
            {},
          );
        } else {
          if (this.state.params.fn) {
            //for better browser goBack() flow
            this.props.history.replace(
              `/event/${this.props.eventState.event.handle}/team/${this.state.eventTeam?.handle}`,
            );
          }

          let config: IFlowStateConfig = {
            group: null,
            event: null,
            gig: null,
            type: 'fundraise',
            currentStep: 'goalIndividual',
            groupType: '',
            eventType: '',
            isCharity: false,
            isEvent: false,
            isComplete: false,
            fundraiser: {
              participants: [],
              hasParticipants: false,
              type: 'join',
              teamId: this.state.eventTeam?.id,
            },
          };
          this.props.setFlow(config);
          this.props.history.push(
            `/setup/fundraise?event=${this.props.eventState.event.handle}&currentStep=goalIndividual&joinTeam=${this.state.eventTeam?.id}`,
          );
        }
      });
    } else {
      this.props.history.push(
        '/login?redirect=' +
          encodeURIComponent(
            `/event/${this.props.eventState.event.handle}/team/${this.state.eventTeam?.handle}?fn=joinTeam`,
          ),
      );
    }
  }

  hasDonationMatching() {
    return (
      this.props.eventState.event?.donations_matching &&
      this.props.eventState.event.donations_matching?.organization &&
      this.props.eventState.event.donations_matching?.max_amount &&
      this.props.eventState.event.donations_matching?.ratio &&
      this.props.eventState.event.donations_matching?.ratio_description
    );
  }

  changeStreamer(id: string) {
    window.scrollTo(0, 0);

    this.setState(
      {
        activeContentCreator: '',
      },
      () => {
        this.setState({
          activeContentCreator: id,
        });
      },
    );
  }

  render() {
    const currency = this.props.eventState.event.group?.account?.currency ?? defaultCurrency;

    let _joyrideStyles = {
      options: {
        arrowColor: '#FFF',
        backgroundColor: '#FFF',
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: '#5E51AB',
        textColor: '#505050',
      },
    };

    if (this.state.notFound) {
      return <Redirect to="/not-found" />;
    } else {
      return (
        <>
          <div className={this.hasContentCreator() ? 'Team content-creator' : 'Team'}>
            {this.state.needATour &&
              !this.hasContentCreator() &&
              this.state.team.id &&
              !this.state.isTeamLoading && (
                <Joyride
                  locale={{
                    back: 'Back',
                    close: 'Close',
                    last: 'Last',
                    next: 'Next',
                    skip: 'Skip',
                  }}
                  continuous={true}
                  disableScrolling={true}
                  styles={_joyrideStyles}
                  steps={this.state.steps}
                  callback={(state: any) => {
                    if (state.status === 'finished') {
                      this.props.history.replace(
                        '/event/' +
                          this.props.eventState.event.handle +
                          '/team/' +
                          this.state.eventTeam?.handle,
                      );
                      this.setState({ needATour: false });
                    }
                  }}
                />
              )}
            <BackSplash
              coverImageUrl={this.state.eventTeam?.cover_image_url}
              showChat={false}
              onImageChange={this.onImageChange}
              showEditCover={this.state.eventTeam?.owner_id === this.props.userState.user.id}
            />
            {this.hasContentCreator() && (
              <div
                ref={this.state.subHeaderRef}
                className={'sub-header ' + this.state.headerClass}
              >
                <div className="sub-header-inner">
                  <div className="row left">
                    <Portrait
                      size={60}
                      currentImage={this.state.eventTeam?.profile_image_url}
                    />
                    <div className="streamer-details">
                      <div className="user-type">
                        <i className="fas fa-user" />
                        <span>TEAM</span>
                      </div>
                      <h1
                        className="name"
                        notranslate="yes"
                      >
                        {this.state.eventTeam?.name}
                      </h1>
                    </div>
                  </div>
                  <div className="row right">
                    <Button
                      className="share"
                      icon="fal fa-share-alt"
                      text=""
                      onClick={() => {
                        this.setState({ showShareModal: true });
                      }}
                    />
                    <div className="text-buttons">
                      {this.state.eventTeam?.owner_id === this.props.userState.user.id && (
                        <Link
                          to={
                            '/event/' +
                            this.props.match.params.handle +
                            '/team/' +
                            this.state.eventTeam?.handle +
                            '/manage'
                          }
                          className="manage"
                        >
                          Manage
                        </Link>
                      )}
                      {this.state.eventTeam?.owner_id !== this.props.userState.user.id && (
                        <Button
                          className="manage"
                          onClick={(e: any) => {
                            this.state.members.find(
                              (m) => m.user_id === this.props.userState.user.id,
                            )
                              ? this.joinTeam()
                              : this.setState({ showConfirmModal: true });
                          }}
                          text={
                            this.state.members.find(
                              (m) => m.user_id === this.props.userState.user.id,
                            )
                              ? 'View My Page'
                              : 'Join Team'
                          }
                        />
                      )}
                      <Link
                        to={
                          '/event/' +
                          this.props.match.params.handle +
                          '/team/' +
                          this.state.eventTeam?.handle +
                          '/donate'
                        }
                        className="donate"
                      >
                        Donate
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.hasContentCreator() && this.state.activeContentCreator !== '' && (
              <div className="stream-embed">
                <StreamEmbed
                  withChat={this.state.showTwitchChat}
                  width="100%"
                  height={this.state.showTwitchChat ? 484 : this.state.innerWidth * 0.5625}
                  channel={this.state.activeContentCreator}
                  id={this.state.activeContentCreator}
                  theme="light"
                  muted
                />
              </div>
            )}
            {!this.hasContentCreator() && <TeamDefault {...this.props} />}
            {this.hasContentCreator() && (
              <TeamStreamer
                {...this.props}
                onChangeStreamer={(id: string) => {
                  this.changeStreamer(id);
                }}
              />
            )}
          </div>
          <Modal
            class="share-modal"
            show={this.state.showShareModal}
            onClose={() => {
              this.setState({ showShareModal: false });
            }}
          >
            <Share
              {...this.props}
              url={
                Config.web.REACT_APP_BASE_URL +
                '/event/' +
                this.state.eventTeam?.event?.handle +
                '/team/' +
                this.state.eventTeam?.handle
              }
            />
          </Modal>
          <Modal
            show={this.state.showConfirmModal}
            onClose={() => {
              this.setState({ showConfirmModal: false });
            }}
          >
            <div className="confirm">
              <p>Are you sure you want to join {this.state.eventTeam?.name}?</p>
              <div className="confirm-actions">
                <Button
                  text="No"
                  onClick={() => {
                    this.setState({ showConfirmModal: false });
                    this.props.history.replace(
                      `/event/${this.props.eventState.event.handle}/team/${this.state.team.handle}`,
                    );
                  }}
                />
                <Button
                  text="Yes"
                  onClick={() => {
                    this.setState({ showConfirmModal: false });
                    this.joinTeam();
                  }}
                />
              </div>
            </div>
          </Modal>
          <Modal
            show={this.state.showFundraisingRegistrationModal}
            onClose={() => {
              this.setState({ showFundraisingRegistrationModal: false });
            }}
          >
            {this.props.eventState?.fundraisingTicket && (
              <div className="fundraising-registration">
                <div className="fundraising-title">Fundraising Registration</div>
                <div className="fundraising-handle">{this.props.eventState.event.title}</div>
                <div className="StoreItem">
                  <div className="store-item-inner">
                    <div className="portrait-wrap">
                      <div
                        className={
                          this.props.eventState.fundraisingTicket?.media?.length > 0 &&
                          this.props.eventState.fundraisingTicket?.media[0]?.url
                            ? 'backdrop with-image'
                            : 'backdrop'
                        }
                      >
                        {this.props.eventState.fundraisingTicket?.media?.length > 0 &&
                          this.props.eventState.fundraisingTicket?.media[0]?.url && (
                            <img
                              alt="store item backdrop"
                              src={this.props.eventState.fundraisingTicket?.media[0]?.url}
                            />
                          )}
                      </div>
                      <Portrait
                        source={
                          this.props.eventState.fundraisingTicket?.media?.length > 0
                            ? this.props.eventState.fundraisingTicket?.media[0]?.url
                            : ''
                        }
                        size={120}
                      />
                    </div>
                    <div className="store-item-details">
                      <div className="item-top-row">
                        <div className="title-section">
                          <div className="title">
                            {this.props.eventState.fundraisingTicket?.name}
                          </div>
                          <div className="item-price">
                            {formatCurrency(
                              this.props.eventState.fundraisingTicket?.price,
                              currency,
                              this.props.locale,
                            )}
                          </div>
                        </div>
                        <div className="qty">
                          <TextField
                            value={this.state.fundraisingTicketQty}
                            label="fundraisingTicketQty"
                            name="fundraisingTicketQty"
                            min="1"
                            max="999"
                            type="number"
                            onChange={(e: any) => {
                              handleInputChange(e, this);
                            }}
                          />
                          <Button
                            onClick={() => {
                              this.props.addItem(
                                {
                                  ...this.props.eventState?.fundraisingTicket,
                                  ...{ quantity: parseInt(this.state.fundraisingTicketQty) },
                                },
                                'event',
                              );
                              this.props.history.push(
                                '/checkout?redirect=/event/' +
                                  this.props.eventState.event.handle +
                                  '/team/' +
                                  this.props.match.params.id +
                                  '?fn=joinTeam',
                              );
                            }}
                            text="Add To Cart"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  addItem,
  getEvent,
  joinEventTeam,
  createToast,
  resetTeamError,
  setFlow,
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
