import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useEffect } from 'react';
import queryString from 'query-string';
import moment from 'moment';

import { localizeHelpers } from '../../localizeHelpers';
import {
  ConvertToFrontendType,
  IAddress,
  IEventSummaryFE,
  IGroup,
  IHub,
  IPageCreateParamsFE,
  IPageFE,
} from '@gigit/interfaces';
import { FlowConfigComponents, FlowConfigPages } from '../Flows/FlowConfigs';

import {
  hasAllRequiredFields,
  makePageSequence,
  onBack,
  onSelectCause,
  setCauses,
} from '../../components/shared/Onboarding/helpers';
import { InfoSection } from '../../components/shared/Onboarding/InfoSection/InfoSection';
import { FormSection } from '../../components/shared/Onboarding/FormSection/FormSection';
import { CheckboxCard } from '../../components/shared/Onboarding/CheckboxCard/CheckboxCard';
import { CauseSelection } from '../../components/shared/Onboarding/CausesSelection/CauseSelection';
import { ImageSelect } from '../../components/shared/Onboarding/imageSelect/ImageSelect';
import { EntityBaseInfo } from '../../components/shared/Onboarding/EntityBaseInfo/EntityBaseInfo';
import { eventCreatedForOptions, eventStepsMaster, eventTypeOptions } from './Config';
import { SummaryPage } from '../../components/shared/Onboarding/SummaryPage/SummaryPage';
import { setSEOMetatags, uploadImageToStore } from '../../helpers';
import { FormTopBar } from '../../components/shared/Onboarding/FormTopBar/FormTopBar';
import { FormBottomBar } from '../../components/shared/Onboarding/FormBottomBar/FormBottomBar';
import OnboardingWrapper from '../../components/shared/Onboarding/OnboardingWrapper';
import { eventRequestActions, groupRequestActions, hubRequestActions } from '../../requestActions';
import {
  IAddressFromGoogle,
  ICausesSelection,
  IEntityBaseInfo,
  IOnboardStep,
} from '../../components/shared/Onboarding/interfaces';
import { GroupSelection } from '../../components/shared/Onboarding/GroupSelection/GroupSelection';
import { useCauseInfo } from '../../components/shared/Onboarding/hooks/useCauseInfo';
import { useBaseInfo } from '../../components/shared/Onboarding/hooks/useBaseInfo';
import useToastDispatcher from '../../hooks/useToaster';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { userSelectors } from '../../selectors/user';
import TextField from '../../components/TextField/TextField';
import { uiConstants } from '../../constants';
import { HubSelection } from '../../components/shared/Onboarding/HubSelection/HubSelection';

import './CreateAnEvent.scss';

const createAnEventLoginRedirect = `/login?redirect=/onboarding/event`;

interface IProps extends RouteComponentProps<{ step: string }> {}

export interface ICreateEventFormData {
  baseInfo: IEntityBaseInfo;
  causeInfo: ICausesSelection;
  createdFor: string;
  type: string;
  steps: Array<IOnboardStep>;
  profileImage: string;
  coverImage: string;
  loading: boolean;
  selectedGroup: IGroup | null;
  selectedHub: IHub | null;
}

const CreateAnEvent: React.FC<IProps> = (props: IProps) => {
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const { baseInfo, handleBaseInfo } = useBaseInfo();
  const { causeInfo, handleCauseInfo } = useCauseInfo();
  const [createdFor, setCreatedFor] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [steps, setSteps] = useState<IOnboardStep[]>(eventStepsMaster);
  const [profileImage, setProfileImage] = useState<string>('');
  const [coverImage, setCoverImage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null);
  const [selectedHub, setSelectedHub] = useState<IHub | null>(null);
  const { dispatchToastError } = useToastDispatcher();
  // View Helper Variables
  const nxtBtnText = getCurrentStep() === 'summary' ? 'Create Event' : 'Next';
  const showBack = steps.findIndex((step) => step.id === getCurrentStep());
  const navigateHome = () => props.history.push('/');

  // Sets causeInfo.causes list and step mounting logic
  useEffect(() => {
    setSEOMetatags({
      title: localizeHelpers.translate('Create An Event | Kambeo'),
      urlPath: '/onboarding/event',
    });

    initialize();
  }, []);

  useEffect(() => {
    setAddressRequiredState();
  }, [baseInfo?.locationType]);

  useEffect(() => {
    setEventOrganizerRequiredState();
    setSelectedHubRequiredState();
    const handleCreatedFor = (stepId: string) => {
      const newSteps = eventStepsMaster.filter((step) => step.id !== stepId);
      setSteps(newSteps);
    };

    // Adjusts step list for createdFor Types (obo, cause, company)
    if (createdFor === 'company') {
      handleCreatedFor('groupSelection');
    } else {
      handleCreatedFor('hubSelection');
    }
  }, [createdFor]);

  async function initialize() {
    // If we re-mount, move back to first step.
    // This ensure the flow never breaks if navigating to a specific step.
    const firstStepId = eventStepsMaster[0]?.id;
    if (getCurrentStep() !== firstStepId) {
      setCurrentStep(firstStepId);
    }

    await setCauses(causeInfo, handleCauseInfo);
    handleParams();
  }

  function getCurrentStep() {
    return props.match.params.step;
  }

  function handleParams() {
    let params = queryString.parse(window.location.search);

    // Navigate to second step
    params?.type && handleEventType(params.type as string);

    // Navigate to third step
    params?.createdFor &&
      params?.type &&
      handleCreatedFor(params.createdFor as string, params?.hubId || '');

    // Navigate to fourth step
    params?.createdFor && params?.type && params?.groupId && handleGroup(params.groupId as string);
  }

  function handleEventType(type: string) {
    setType(type as string);
    setCurrentStep(steps[1].id);
  }

  async function handleCreatedFor(createdFor: string, hubId?: string | string[]) {
    const onHubId = (hubId: string | string[]) => hubRequestActions.getHubByID(hubId as string);
    setCreatedFor(createdFor);
    // check if company/hub
    if (createdFor === eventCreatedForOptions[2].id) {
      const newSteps = eventStepsMaster.filter((step) => step.id !== eventStepsMaster[2].id);
      setSteps(newSteps);
      setCurrentStep(newSteps[2].id);
      if (hubId) {
        const hubFromParams = await onHubId(hubId);
        setSelectedHub(hubFromParams);
        setCurrentStep('baseInfo');
      }
    } else {
      setCurrentStep(steps[2].id);
    }
  }

  async function handleGroup(groupId: string) {
    try {
      const result = await groupRequestActions.getGroupByHandleOrId(groupId);
      setSelectedGroup(result);
      setCurrentStep(steps[4].id);
    } catch (error) {
      dispatchToastError(error, 'Group Selection');
    }
  }

  function renderStep() {
    switch (getCurrentStep()) {
      case 'eventTypeSelection': {
        return renderTypeSelection();
      }
      case 'eventCreatedFor': {
        return renderCreatedForSelection();
      }
      case 'groupSelection': {
        return renderGroupSelection();
      }
      case 'hubSelection': {
        return renderHubSelection();
      }
      case 'baseInfo': {
        return renderBasicInfo();
      }
      case 'causesSelection': {
        return renderCausesSelection();
      }
      case 'imageSelection': {
        return renderImageSelection();
      }
      case 'summary': {
        return renderSummary();
      }
      default:
        break;
    }

    return null;
  }

  function renderTypeSelection() {
    return (
      <div className="form-page column">
        {eventTypeOptions.map((option) => {
          return (
            <CheckboxCard
              showCheckbox={false}
              key={option.id}
              id={option.id}
              title={option.text}
              checked={type === option.id}
              subtext={option?.subtext}
              onChange={(value) => {
                setType(value);
              }}
            />
          );
        })}
      </div>
    );
  }

  function renderCreatedForSelection() {
    return (
      <div className="form-page column">
        {eventCreatedForOptions.map((option) => {
          return (
            <CheckboxCard
              showCheckbox={false}
              key={option.id}
              id={option.id}
              title={option.text}
              checked={createdFor === option.id}
              subtext={option?.subtext}
              onChange={(value) => {
                setCreatedFor(value);
              }}
            />
          );
        })}
      </div>
    );
  }

  function renderGroupSelection() {
    return (
      <div className="form-page column">
        <GroupSelection
          type="event"
          isOnBehalfOf={createdFor === 'obo_fundraiser'} // should start using UI const
          navigate={(value) => props.history.push(value)}
          setGroup={setSelectedGroup}
          selectedGroup={selectedGroup}
        />
      </div>
    );
  }

  function renderHubSelection() {
    return (
      <div className="form-page column">
        <HubSelection
          selectedHub={selectedHub}
          navigate={(value) => props.history.push(value)}
          setHub={setSelectedHub}
        />
      </div>
    );
  }

  function renderCausesSelection() {
    return (
      <CauseSelection
        causeInfo={causeInfo}
        onChange={(value: string) => onSelectCause(causeInfo, value, handleCauseInfo)}
      />
    );
  }

  function renderImageSelection() {
    return (
      <div className="form-page">
        <ImageSelect
          flowObjectName="event"
          type="profile"
          showPreselection={false}
          profileImage={profileImage || selectedGroup?.profile_image_url}
          onChange={(fileUrl: string) => setProfileImage(fileUrl)}
        />

        <ImageSelect
          flowObjectName="event"
          type="cover"
          showPreselection={true}
          coverImage={coverImage}
          onChange={(fileUrl: string) => setCoverImage(fileUrl)}
        />
      </div>
    );
  }

  function renderBasicInfo() {
    return (
      <div className="form-page column">
        {createdFor === 'obo_fundraiser' && (
          <div className="row">
            <TextField
              placeholder={'Event Organizer'}
              value={baseInfo?.event_organizer || ''}
              name="Event Organizer"
              label="Event Organizer"
              type="text"
              required={true}
              onChange={(e) => {
                handleBaseInfo({ ...baseInfo, event_organizer: e.target.value });
              }}
              postTextRenderer={() => (
                <p>If you don't want your name to show as the Organizer, you can change it here.</p>
              )}
            />
          </div>
        )}
        <EntityBaseInfo
          type={uiConstants.ownerType.event}
          subType={type}
          baseInfo={baseInfo}
          onBaseInfoUpdated={handleBaseInfo}
        />
      </div>
    );
  }

  function getFormData() {
    const eventFormData: ICreateEventFormData = {
      baseInfo,
      causeInfo,
      createdFor,
      type,
      steps,
      profileImage,
      coverImage,
      loading,
      selectedGroup,
      selectedHub,
    };

    return eventFormData;
  }

  function renderSummary() {
    return (
      <div className="form-page column">
        <SummaryPage
          locale={locale}
          formData={getFormData()}
          goToStep={(stepId: string) => {
            setCurrentStep(stepId);
          }}
        />
      </div>
    );
  }

  function setCurrentStep(stepId: string) {
    let isExistingStep = steps.filter((step) => step.id === stepId);

    if (isExistingStep.length > 0) {
      props.history.replace({
        pathname: `/onboarding/event/${stepId}`,
      });
    }
  }

  function onNext() {
    const index = steps.findIndex((step) => step.id === getCurrentStep());
    const nextStep = steps[index + 1]?.id;

    if (hasAllRequiredFields(steps, getCurrentStep(), getFormData())) {
      if (index + 1 > steps.length) {
        return;
      } else if (index + 1 === steps.length) {
        createEvent();
      } else {
        setCurrentStep(nextStep);
      }
    }
  }

  async function createEvent() {
    const isOBOEvent = createdFor === 'obo_fundraiser';
    const components = FlowConfigComponents(causeInfo.selectedCauses, uiConstants.ownerType.event);
    const pageConfig = FlowConfigPages(components);
    const pages = pageConfig.event[type].pages;
    const isVirtual = baseInfo.locationType === 'Online';
    const address = formatAddressForPayload();
    const onSuccess = (event: IEventSummaryFE) => {
      let successLink = `/setup/event/${event.id}/success`;
      setLoading(false);
      props.history.push(successLink);
    };
    const handleError = (error: unknown, errorTitle: string) => {
      dispatchToastError(error, errorTitle);
      setLoading(false);
    };
    const handlePageCreation = async (
      eventId: string,
      page: IPageCreateParamsFE,
    ): Promise<IPageFE | undefined> => {
      try {
        return await eventRequestActions.createEventPage(eventId, page);
      } catch (error) {
        handleError(error, 'Create Event Page');
      }
    };
    const handleLocationCreation = async (newEvent: IEventSummaryFE, location: IAddress) => {
      try {
        let eventLocation = await eventRequestActions.createEventLocation(newEvent.id, location);

        let stringCreatedAt = moment(eventLocation.created_at).toString();
        let stringUpdatedAt = moment(eventLocation.updated_at).toString();

        let locationFE: ConvertToFrontendType<IAddress> = {
          ...eventLocation,
          created_at: stringCreatedAt,
          updated_at: stringUpdatedAt,
          location_id: eventLocation.id,
        };

        await eventRequestActions.updateEvent(newEvent.id, { default_location: locationFE });
        onSuccess(newEvent);
      } catch (error) {
        handleError(error, 'Create Event Location');
      }
    };

    async function isBase64(img: string) {
      if (img === '' || img.trim() === '') {
        return false;
      }
      try {
        const base = img.split(',')[1];
        return atob(base);
      } catch (err) {
        return false;
      }
    }

    try {
      setLoading(true);
      const coverImageUploaded = await uploadImageToStore(coverImage);
      const isProfileBase64 = await isBase64(profileImage);
      let profileImageUploaded;
      if (isProfileBase64) {
        profileImageUploaded = await uploadImageToStore(profileImage);
      } else {
        profileImageUploaded = profileImage;
      }
      let newEvent: IEventSummaryFE;

      let event: Partial<IEventSummaryFE> = {
        title: baseInfo.name,
        profile_image_url: profileImageUploaded,
        cover_image_url: coverImageUploaded,
        description: baseInfo.description,
        goal: baseInfo.revenueGoal,
        start_date: moment(baseInfo.startDate).toISOString(),
        end_date: moment(baseInfo.endDate).toISOString(),
        causes: causeInfo.selectedCauses,
        is_virtual: isVirtual,
        event_template_type: type,
        show_campaign_progress: true,
        event_organizer_name: baseInfo?.event_organizer,
      };

      if (isOBOEvent) {
        event.event_type = 'obo_fundraiser';
        event.hub_id = selectedHub?.id;
      }

      let eventPages: Array<IPageCreateParamsFE> = makePageSequence(pages);
      newEvent = isOBOEvent
        ? await eventRequestActions.createOnBehalfOfEvent(selectedGroup!.id, event)
        : createdFor === 'company'
          ? await eventRequestActions.createHubEvent(selectedHub!.id || '', event)
          : await eventRequestActions.createEvent(selectedGroup!.id, event); // they can't get this far without a selectedGroup

      for (let pageIndex in eventPages) {
        let isFinalPage = parseInt(pageIndex) === eventPages.length - 1;
        let page = await handlePageCreation(newEvent?.id, eventPages[pageIndex]);

        if (page && isFinalPage) {
          await handleLocationCreation(newEvent, address);
        }
      }
    } catch (error) {
      handleError(error, 'Create Event');
    }
  }

  function setAddressRequiredState() {
    const locationType = baseInfo?.locationType;
    const newSteps = steps.map((step) => {
      if (step.id === 'baseInfo') {
        step.data.map((stepData) => {
          if (stepData.path === 'baseInfo.address.address') {
            return locationType && locationType !== 'Venue'
              ? (stepData.required = false)
              : (stepData.required = true);
          } else if (
            stepData.path === 'baseInfo.country' ||
            stepData.path === 'baseInfo.postalCode'
          ) {
            return locationType && locationType === 'Venue'
              ? (stepData.required = false)
              : (stepData.required = true);
          } else {
            return stepData;
          }
        });

        return step;
      } else {
        return step;
      }
    });

    setSteps(newSteps);
  }

  function setEventOrganizerRequiredState() {
    const newSteps = steps.map((step) => {
      if (step.id === 'baseInfo') {
        step.data.map((stepData) => {
          if (stepData.path === 'baseInfo.event_organizer') {
            return createdFor === 'obo_fundraiser'
              ? (stepData.required = true)
              : (stepData.required = false);
          }
        });

        return step;
      } else {
        return step;
      }
    });

    setSteps(newSteps);
  }

  function setSelectedHubRequiredState() {
    if (createdFor === 'company') {
      const newSteps = steps.map((step) => {
        if (step.id === 'hubSelection') {
          step.data.forEach((singleStep) => {
            singleStep.required = true;
          });
        }

        return step;
      });
      setSteps(newSteps);
    }
  }

  function formatAddressForPayload() {
    if (baseInfo.locationType === 'Venue' && baseInfo.address) {
      const street_number = getAddressParts(baseInfo.address, 'street_number');
      const route = getAddressParts(baseInfo.address, 'route');
      const neighborhood = getAddressParts(baseInfo.address, 'neighborhood');
      const city = getAddressParts(baseInfo.address, 'locality');
      const stateOrProvince = getAddressParts(baseInfo.address, 'administrative_area_level_1');
      const country = getAddressParts(baseInfo.address, 'country');
      const postal_code = getAddressParts(baseInfo.address, 'postal_code');
      const address: IAddress = {
        line1:
          street_number !== undefined && route !== undefined
            ? street_number + ' ' + route
            : undefined,
        line2: neighborhood,
        city,
        state: stateOrProvince,
        country: country,
        postal_code: postal_code,
        searchable: true,
        location: {
          coordinates: [
            baseInfo.address?.coordinates.longitude || 0,
            baseInfo.address?.coordinates.latitude || 0,
          ],
        },
      };

      return address;
    } else {
      const onlineAddress: IAddress = {
        country: baseInfo.country,
        postal_code: baseInfo?.postalCode,
        searchable: true,
        title: 'Default',
        line1: 'N/A',
        line2: 'N/A',
        city: 'N/A',
        state: 'N/A',
      };

      return onlineAddress;
    }
  }

  function getAddressParts(googleAddress: IAddressFromGoogle, type: string) {
    const addressComponent = googleAddress.address.address_components.find(
      (addressComponent: google.maps.GeocoderAddressComponent) =>
        addressComponent.types.includes(type),
    );
    return addressComponent?.long_name;
  }

  return (
    <OnboardingWrapper
      requiresAuth={true}
      redirect={createAnEventLoginRedirect}
    >
      <form
        className="CreateAnEvent"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormTopBar
          onLogoClick={() => navigateHome()}
          onClose={() => props.history.goBack()}
        />
        <InfoSection
          type={type}
          title="Create an Event"
          steps={steps}
          currentStep={getCurrentStep()}
          selectedGroup={selectedGroup || undefined}
        />
        <FormSection>{renderStep()}</FormSection>
        <FormBottomBar
          isDisabled={!hasAllRequiredFields(steps, getCurrentStep(), getFormData())}
          showBack={showBack !== 0}
          nextBtnTxt={nxtBtnText}
          onBack={() => onBack(steps, getCurrentStep(), setCurrentStep)}
          onNext={() => onNext()}
          showSkip={createdFor === 'obo_fundraiser' && getCurrentStep() === 'hubSelection'}
          loading={loading}
        />
      </form>
    </OnboardingWrapper>
  );
};

export default CreateAnEvent;
