import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IAppState } from '../../store';

import './NotificationCenter.scss';
import { INotificationsState } from '../../reducers/notifications';
import { getNotifications } from '../../actions/notifications';
import Notification from '../../components/Notification/Notification';
import Button from '../../components/Button/Button';
import { setSEOMetatags } from '../../helpers';
import { localizeHelpers } from '../../localizeHelpers';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  notificationsState: INotificationsState;
  getNotifications(): void;
}

interface IState {}

class NotificationCenter extends React.Component<IProps, IState> {
  componentDidMount() {
    setSEOMetatags({
      title: localizeHelpers.translate('Notifications | Kambeo'),
      urlPath: 'my-pages',
    });
    this.props.getNotifications();
  }

  render() {
    return (
      <div className="Notification-Center">
        <h1>Notifications</h1>
        <div className="inner">
          {this.props.notificationsState.notifications &&
            this.props.notificationsState.notifications.map((noti) => {
              return (
                <Notification
                  key={noti.id}
                  notification={noti}
                />
              );
            })}
          <div className="load-more">
            <Button text={'Load More'} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    notificationsState: store.notificationsState,
  };
};

const mapDispatchToProps = {
  getNotifications: getNotifications,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(NotificationCenter),
);
