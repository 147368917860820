import React, { useState, Fragment, useEffect } from 'react';
import vmLogo from '../../assets/vm_logo.svg';
import groupIcon from '../../assets/people-logo-dark.svg';
import eventIcon from '../../assets/event-icon-masks.svg';
import companyIcon from '../../assets/people-in-cicle-dark.svg';
import { Link, useHistory } from 'react-router-dom';
import { capitalizeString, OwnerType, stripHtml, typeHelpers } from '../../helpers';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { IAddress, IApplication, ICause, IGig } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import './VolunteerCard.scss';
import CalendarIcon from '../../assets/calendar-icon-grey.svg';
import PopupMenu, { IPopupMenuItem } from '../shared/PopupMenu/PopupMenu';
import { IAppState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button/Button';
import useSharedModal from '../../hooks/useSharedModal';
import { OwnerObjectTypes } from '../../interfaces';
import { userSelectors } from '../../selectors/user';
import { changeGigStatus } from '../../actions/gig';
import { uiConstants } from '../../constants';
import Portrait from '../Portrait/Portrait';
import { Tooltip } from '../shared/Tooltip/Tooltip';
import ExternalVolunteerApplicationWarning from '../shared/ExternalVolunteerApplicationWarning/ExternalVolunteerApplicationWarning';
import useToastDispatcher from '../../hooks/useToaster';
import { gigRequestActions, volunteerRequestActions } from '../../requestActions';

export enum EViewTypeVolunteerCard {
  GRID,
  LIST,
}

export enum EStyleTypeVolunteerCard {
  MANAGE,
  DISCOVER,
  SCHEDULE,
  HUB_PARTNER,
  HUB_ONLY,
}

export interface IBasicInfoVolunteerCard {
  profile_image_url?: string;
  title: string;
  description?: string;
  locations?: IAddress[];
  start_date: string;
  owner_title: string;
  owner_type: string;
  owner_handle: string;
  handle: string;
  status?: string;
  item_type?: string;
  id: string;
  is_volunteer_match?: boolean;
  numOfApplicants?: number;
  is_virtual?: boolean;
  focus_areas?: ICause[];
  accepting_external_application?: boolean;
  external_application_url?: string;
}

interface IProps {
  viewType: EViewTypeVolunteerCard;
  styleType: EStyleTypeVolunteerCard;
  showSocialButtons?: boolean;
  deleteItem?: () => void;
  openDetailModal?: (gig_id: string) => void;
  openCalendarModal?: (gig_id: string) => void;
  logHourClick?: () => void;
  scheduleMenuItems?: IPopupMenuItem[];
  gigBasic: IBasicInfoVolunteerCard;
  userApplications?: IApplication[];
  isInterested?: boolean | null;
}

const VolunteerCard: React.FC<IProps> = (props) => {
  const [showHeaderMenu, setShowHeaderMenu] = useState<boolean>(false);
  const [showBottomMenu, setShowBottomMenu] = useState<boolean>(false);
  const [showMenuCompanyCard, setShowMenuCompanyCard] = useState<boolean>(false);
  const [showExternalVolunteerApplicationWarning, setShowExternalVolunteerApplicationWarning] =
    useState<boolean>(false);
  const [hasApplied, setHasApplied] = useState<boolean>(false);
  const [isInterested, setIsInterested] = useState<boolean | null>(null);
  const history = useHistory();
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));
  const [userLanguage, setUserLanguage] = useState<string>('');
  const sharedModals = useSharedModal();
  const dispatch = useDispatch();
  const { dispatchToastError } = useToastDispatcher();

  const companyCardMenuItems: IPopupMenuItem[] = [
    {
      id: 'interested',
      icon: isInterested ? 'fad fa-circle' : 'far fa-circle',
      label: 'Interested',
      onClick: async () => {
        await changeVolunteerOpportunityUserStatus(true);
      },
    },
    {
      id: 'not_interested',
      icon: isInterested === false ? 'fad fa-circle' : 'far fa-circle',
      label: 'Not Interested',
      onClick: async () => {
        await changeVolunteerOpportunityUserStatus(false);
      },
    },
    {
      id: 'share_to_calendar',
      label: 'Share to Calendar',
      iconImg: CalendarIcon,
      onClick: () => {
        props?.openCalendarModal?.(props.gigBasic.id);
      },
    },
  ];

  const headerMenuItems: IPopupMenuItem[] =
    props.styleType === EStyleTypeVolunteerCard.MANAGE
      ? [
          {
            id: 'manage',
            label: 'Manage',
            onClick: () => {
              history.replace({ pathname: `/gig/${props.gigBasic.handle}/admin` });
            },
          },
          {
            id: 'share',
            label: 'Share',
            onClick: () => {
              showShareModal();
            },
          },
          {
            id: 'delete',
            label: 'Delete',
            onClick: () => {
              props?.deleteItem?.();
            },
          },
        ]
      : props.scheduleMenuItems || [];

  const bottomMenuItems: IPopupMenuItem[] = [
    {
      id: 'posted',
      label: 'Posted',
      isSelected: props.gigBasic.status === Constants.gig_status.published,
      onClick: () => {
        changeStatus(Constants.gig_status.published, props.gigBasic.id);
      },
    },
    {
      id: 'draft',
      label: 'Draft',
      isSelected: props.gigBasic.status === Constants.gig_status.draft,
      onClick: () => {
        changeStatus(Constants.gig_status.draft, props.gigBasic.id);
      },
    },
    {
      id: 'archived',
      label: 'Archived',
      isSelected: props.gigBasic.status === Constants.gig_status.archived,
      onClick: () => {
        changeStatus(Constants.gig_status.archived, props.gigBasic.id);
      },
    },
  ];

  async function changeVolunteerOpportunityUserStatus(flag_value: boolean) {
    if (userState.isLoggedIn) {
      try {
        typeHelpers.assertNotNullOrUndefined(userState?.user?.id, 'Expected User ID');
        typeHelpers.assertNotNullOrUndefined(props.gigBasic?.id, 'Expected Gig ID');

        await volunteerRequestActions.setVolunteerFlag(props.gigBasic.id, userState.user.id, {
          flag: 'is_interested',
          flag_value,
        });

        setIsInterested(flag_value);
      } catch (error) {
        dispatchToastError(error, 'Set Volunteer Flag');
      }
    } else {
      history.push(`/login?redirect=/${props.gigBasic.owner_type}/${props.gigBasic.owner_handle}`);
    }
  }

  function changeStatus(status: string, gigId: string) {
    if (gigId && status) {
      dispatch(changeGigStatus(gigId, status));
    }
  }

  function getOwnerIcon() {
    switch (props.gigBasic.owner_type) {
      case Constants.object_type.hub:
        return companyIcon;
      case Constants.object_type.event:
        return eventIcon;
      case Constants.object_type.group:
      default:
        return groupIcon;
    }
  }

  async function hasUserApplied() {
    if (props.userApplications) {
      setHasApplied(
        props.userApplications.find((application) => application.gig?.id === props.gigBasic.id)
          ? true
          : false,
      );
    } else {
      setHasApplied(false);
    }
  }

  async function getGigVolunteer() {
    try {
      typeHelpers.assertNotNullOrUndefined(props.gigBasic?.id, 'Expected Company ID');
      typeHelpers.assertNotNullOrUndefined(userState?.user?.id, 'Expected User ID');
      const result = await gigRequestActions.getGigVolunteer(
        props.gigBasic.id,
        userState.user.id || '',
      );
      setIsInterested(result.is_interested ?? null);
    } catch (error) {
      dispatchToastError(error, 'Get Applicants');
    }
  }

  useEffect(() => {
    hasUserApplied();
    if (props.styleType === EStyleTypeVolunteerCard.HUB_ONLY) {
      getGigVolunteer();
    }

    if (isUserLoggedIn) {
      if (userState.user.language) {
        setUserLanguage(userState.user.language);
      }
    } else {
      setUserLanguage(userState.loggedOutLanguage);
    }
  }, [userState, isUserLoggedIn, props.userApplications]);

  function showApplyModal() {
    sharedModals.showVolunteerApplicationForm({
      gig: {
        ...props.gigBasic,
        start_date: new Date(props.gigBasic.start_date),
      } as IGig,
    });
  }

  function showShareModal() {
    sharedModals.showShareModal({
      ownerType: uiConstants.ownerType.gig as OwnerType,
      ownerObject: props.gigBasic as OwnerObjectTypes,
      handle: props.gigBasic.handle,
      title: 'Share Volunteer Opportunity',
    });
  }

  function handleApply(e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    e.stopPropagation();
    if (props.gigBasic?.accepting_external_application) {
      setShowExternalVolunteerApplicationWarning(true);
    } else {
      if (isUserLoggedIn) {
        showApplyModal();
      } else {
        history.push(`/login?redirect=${history.location.pathname}${history.location.search}`);
      }
    }
  }

  function handleShare(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    if (isUserLoggedIn) {
      showShareModal();
    } else {
      history.push(`/login?redirect=${history.location.pathname}${history.location.search}`);
    }
  }

  return (
    <div
      className={`VolunteerCard ${EStyleTypeVolunteerCard[props.styleType]} ${EViewTypeVolunteerCard[props.viewType]}`}
      onClick={() => {
        props?.openDetailModal?.(props.gigBasic.id);
      }}
      style={{ cursor: props?.openDetailModal ? 'pointer' : undefined }}
    >
      <div className="header-section">
        <div className="profile-img">
          {props.gigBasic.profile_image_url !== '' && (
            <img
              src={props.gigBasic.profile_image_url}
              alt="profile"
            />
          )}
          {props.gigBasic.profile_image_url === '' && <div className="empty-img" />}
        </div>

        {props.viewType === EViewTypeVolunteerCard.GRID && props.gigBasic.is_volunteer_match && (
          <div className="volunteer-match">
            <img
              src={vmLogo}
              alt="volunteer match"
              height="24px"
            />
          </div>
        )}
      </div>
      <div className="volunteer-card-content">
        <div
          className={`card-title two-lines ${props.styleType === EStyleTypeVolunteerCard.MANAGE || props.styleType === EStyleTypeVolunteerCard.SCHEDULE ? 'with-menu' : ''}`}
        >
          <Link
            notranslate="yes"
            to={`/${Constants.object_type.gig}/${props.gigBasic.handle}`}
          >
            {props.gigBasic.title}
          </Link>
          {(props.styleType === EStyleTypeVolunteerCard.MANAGE ||
            props.styleType === EStyleTypeVolunteerCard.SCHEDULE) && (
            <PopupMenu
              showMenuConfig={{
                showMenu: showHeaderMenu,
                setShowMenu: setShowHeaderMenu,
                position: {
                  type: 'bottom',
                },
              }}
              menuItems={headerMenuItems}
              popupMenuClass="PopupMenu-Volunteer-Card"
              className={`menu ${showHeaderMenu ? 'show' : ''}`}
              onClick={() => setShowHeaderMenu(true)}
              onSelect={() => setShowHeaderMenu(false)}
            >
              <i className="fal fa-ellipsis-v" />
            </PopupMenu>
          )}
        </div>

        {
          <React.Fragment>
            {(props.styleType === EStyleTypeVolunteerCard.HUB_PARTNER ||
              props.styleType === EStyleTypeVolunteerCard.HUB_ONLY) && (
              <div
                className={`description ${!props.gigBasic?.description ? 'no-desc' : ''}`}
                notranslate="yes"
              >
                <p>
                  {props.gigBasic?.description
                    ? stripHtml(props.gigBasic?.description)
                    : 'No description available'}
                </p>
              </div>
            )}
            {props.styleType === EStyleTypeVolunteerCard.DISCOVER && (
              <div className="group-causes">
                <div className="causes-container">
                  {props.gigBasic.focus_areas &&
                    props.gigBasic.focus_areas.map((focusArea, index) => {
                      if (index < 4) {
                        return (
                          <Tooltip message={focusArea.cause}>
                            {(ref) => (
                              <div
                                key={index}
                                ref={ref}
                                className="focus-area-container"
                              >
                                <Portrait
                                  size={30}
                                  hideMask={true}
                                  currentImage={focusArea.icon_url ? `${focusArea.icon_url}` : ''}
                                />
                              </div>
                            )}
                          </Tooltip>
                        );
                      }

                      return <Fragment key={index} />;
                    })}
                  {props.gigBasic.focus_areas && props.gigBasic.focus_areas.length > 4 && (
                    <span className="empty-portrait">
                      <var data-var="props.gigBasic._involved_length">
                        +{props.gigBasic.focus_areas.length - 4}
                      </var>
                    </span>
                  )}
                  {props.gigBasic.focus_areas?.length === 0 &&
                    [1, 2, 3, 4, 5].map((e) => {
                      return <div className="no-causes" />;
                    })}
                </div>
                {props.gigBasic.focus_areas && (
                  <span className={props.gigBasic.focus_areas?.length > 0 ? 'areas' : 'no-areas'}>
                    {props.gigBasic.focus_areas?.length > 0 ? 'Focus Areas' : 'No Focus Areas'}
                  </span>
                )}
              </div>
            )}
          </React.Fragment>
        }

        <div className="content-items">
          {(props.styleType === EStyleTypeVolunteerCard.DISCOVER ||
            props.styleType === EStyleTypeVolunteerCard.HUB_PARTNER ||
            props.styleType === EStyleTypeVolunteerCard.HUB_ONLY ||
            props.styleType === EStyleTypeVolunteerCard.SCHEDULE) && (
            <span className="location item">
              <span className="icon">
                <i className="fal fa-map-marker-alt" />
              </span>
              {props.gigBasic?.locations && props.gigBasic.locations.length > 0 ? (
                <var data-var="gig_location">
                  {props.gigBasic.locations[0].city}
                  {props.gigBasic.locations[0].state && props.gigBasic.locations[0].state !== ''
                    ? ', ' + props.gigBasic.locations[0].state
                    : ''}
                </var>
              ) : (
                'Online'
              )}
            </span>
          )}
          {props.styleType === EStyleTypeVolunteerCard.MANAGE && (
            <span className="applicants item">
              <span className="icon">
                <i className="fal fa-clipboard-list" />
              </span>
              <span>
                <var data-var="numOfApplicants">{props.gigBasic?.numOfApplicants || 0}</var>
                {` Applicants`}
              </span>
            </span>
          )}

          <span
            className="date item"
            notranslate="yes"
          >
            <span className="icon">
              <i className="fal fa-clock" />
            </span>
            {props.gigBasic.start_date && props.gigBasic.start_date !== ''
              ? localizeHelpers.formatDate(props.gigBasic.start_date, LocaleDateFormats.LL, 'en')
              : 'n/a'}
          </span>
          {props.gigBasic.owner_title && (
            <span
              className="owner item"
              notranslate="yes"
            >
              <span className="icon">
                <img
                  src={getOwnerIcon()}
                  alt="owner icon"
                />
              </span>
              <Link to={`/${props.gigBasic.owner_type}/${props.gigBasic.owner_handle}`}>
                {props.gigBasic.owner_title}
              </Link>
            </span>
          )}
        </div>

        {props.styleType === EStyleTypeVolunteerCard.SCHEDULE && (
          <div
            className={`description ${!props.gigBasic?.description ? 'no-desc' : ''}`}
            notranslate="yes"
          >
            <p>
              {props.gigBasic?.description
                ? stripHtml(props.gigBasic.description)
                : 'No description available'}
            </p>
          </div>
        )}

        {(props.styleType === EStyleTypeVolunteerCard.DISCOVER ||
          props.styleType === EStyleTypeVolunteerCard.HUB_PARTNER) &&
          props?.showSocialButtons && (
            <div className={`actions-container ${userLanguage}`}>
              <Button
                isDisabled={hasApplied}
                onClick={(e) => handleApply(e)}
                icon={hasApplied ? 'fas fa-check' : 'fas fa-clipboard-list'}
                text={hasApplied ? 'Applied' : 'Apply'}
              />
              <Button
                className="share"
                onClick={(e) => handleShare(e)}
                text="Share"
                icon="fas fa-share-alt"
              />
            </div>
          )}

        {props.styleType === EStyleTypeVolunteerCard.HUB_ONLY && (
          <div className="actions-container">
            <PopupMenu
              showMenuConfig={{
                showMenu: showMenuCompanyCard,
                setShowMenu: setShowMenuCompanyCard,
                position: {
                  type: 'bottom',
                },
              }}
              menuItems={companyCardMenuItems}
              popupMenuClass="PopupMenu-Volunteer-Card-Company"
              className={`menu ${showMenuCompanyCard ? 'show' : ''}`}
              onClick={(e) => {
                e?.stopPropagation?.();
                setShowMenuCompanyCard(true);
              }}
              onSelect={(e) => {
                e?.stopPropagation?.();
                setShowMenuCompanyCard(false);
              }}
            >
              <div className="interested-container">
                {isInterested && (
                  <>
                    <i className="fas fa-star" /> I'm Interested
                  </>
                )}
                {isInterested === false && (
                  <>
                    <i className="far fa-star" /> I'm Not Interested
                  </>
                )}
                {isInterested === null && 'Are you interested?'}
              </div>
            </PopupMenu>
          </div>
        )}

        {props.styleType === EStyleTypeVolunteerCard.MANAGE && (
          <div className="card-bottom">
            <div className="label-wrap">
              {props.viewType === EViewTypeVolunteerCard.LIST &&
                props.gigBasic?.is_volunteer_match && (
                  <span className="volunteer-match">
                    <img
                      src={vmLogo}
                      alt="volunteer match"
                      height="24px"
                    />
                  </span>
                )}
              <span
                className={`state-label ${props.gigBasic?.status === 'active' ? 'published' : props.gigBasic.status}`}
              >
                {capitalizeString(props.gigBasic.status)}
              </span>
              {props.gigBasic?.item_type && (
                <span className="state-label type">
                  {props.gigBasic.item_type === 'paid gig' && 'Paid'}
                  {props.gigBasic.item_type === 'volunteer gig' && 'Non-Paid'}
                </span>
              )}
            </div>
            <PopupMenu
              showMenuConfig={{
                showMenu: showBottomMenu,
                setShowMenu: setShowBottomMenu,
                position: {
                  type: 'bottom',
                },
              }}
              menuItems={bottomMenuItems}
              popupMenuClass="PopupMenu-Volunteer-Card-bottom"
              className={`bottom-menu ${showBottomMenu ? 'show' : ''}`}
              onClick={() => setShowBottomMenu(true)}
              onSelect={() => setShowBottomMenu(false)}
            >
              <i className="fal fa-ellipsis-v" />
            </PopupMenu>
          </div>
        )}

        {props.styleType === EStyleTypeVolunteerCard.SCHEDULE && (
          <div className="actions-container-schedule">
            <Button
              onClick={() => {
                props?.logHourClick?.();
              }}
              type="button"
              text="Log Hours"
              buttonType="dark"
            />
          </div>
        )}
      </div>

      {props.gigBasic?.accepting_external_application &&
        props.gigBasic?.external_application_url &&
        showExternalVolunteerApplicationWarning && (
          <ExternalVolunteerApplicationWarning
            show={showExternalVolunteerApplicationWarning}
            onClose={() => setShowExternalVolunteerApplicationWarning(false)}
            url={props.gigBasic.external_application_url || ''}
            isAvailable={true}
          />
        )}
    </div>
  );
};

export default VolunteerCard;
