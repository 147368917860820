import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Dropdown, { IOptions } from '../../../Dropdown/Dropdown';
import { handleInputChange } from '../../../../helpers';
import Button from '../../../Button/Button';

interface IProps extends RouteComponentProps {
  flow: string;
  type: 'group' | 'event' | 'gig';
  entity: any;
  campaignOptions: Array<IOptions>;
  selectedCampaign: string;
  isCampaignDefault: boolean;

  handleCampaignSelect(campId: string): void;
  hasCampaignAccount(val: boolean): boolean;
  stripeConnect(): void;
  toggleCampaignSkipModal(val: boolean): void;
  getCampaignOptions(): void;
}

interface IState {
  selectedCampaign: string;
  showList: boolean;
}

class CampaignConnect extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedCampaign: '',
      showList: false,
    };
  }

  componentDidMount() {
    if (this.props.selectedCampaign) {
      this.setState({
        selectedCampaign: this.props.selectedCampaign,
      });
    }

    this.props.getCampaignOptions();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.selectedCampaign !== this.props.selectedCampaign) {
      this.setState({
        selectedCampaign: this.props.selectedCampaign,
      });
    }

    if (prevProps.entity !== this.props.entity) {
      this.setState({
        selectedCampaign: this.props.entity.campaign_id || '',
      });
    }
  }

  render() {
    return (
      <div className={`form-step ${this.props.flow} connect`}>
        <h2>
          Before you can start accepting donations and other purchases you must connect a stripe
          account
        </h2>
        <div className="details-row">
          {this.props.campaignOptions?.length > 2 && (
            <Dropdown
              name="selectedCampaign"
              shouldSort={true}
              label="Select a campaign"
              options={this.props.campaignOptions}
              value={
                this.props.selectedCampaign
                  ? this.props.selectedCampaign
                  : this.state.selectedCampaign
              }
              onChange={(e: any) => {
                handleInputChange(e, this, false, this.props.handleCampaignSelect, true);
              }}
            />
          )}
        </div>
        <p>Connect a stripe account</p>
        <div
          className={
            this.props.hasCampaignAccount(false) ? 'stripe-details has-account' : 'stripe-details'
          }
        >
          {/* if they change the account we need the option to make that default, it needs to check if it has an account and if so just make default for the event, otherwise connect and do default scenario */}
          <Button
            isDisabled={this.props.hasCampaignAccount(false)}
            icon={this.props.hasCampaignAccount(true) ? 'fa fa-check' : 'fab fa-stripe-s'}
            onClick={() => this.props.stripeConnect()}
          >
            {this.props.hasCampaignAccount(false) ? (
              <span>
                <span notranslate="yes">Stripe</span> Account Connected
              </span>
            ) : (
              <span>
                Connect or Add <span notranslate="yes">Stripe</span> Account
              </span>
            )}
          </Button>
          <div className="settings-control">
            {!this.props.hasCampaignAccount(true) && (
              <span
                onClick={() => this.props.toggleCampaignSkipModal(true)}
                className="modal-invoke"
              >
                Skip For Later
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CampaignConnect;
