import React, { MouseEvent } from 'react';
import { combineClassNames } from '../../helpers';

import './Button.scss';

export type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'dark' | 'outline-dark' | 'text';
export interface IButtonProps {
  text?: string;
  imgIcon?: {
    src: string;
    width: string;
    height: string;
    alt?: string;
  };
  icon?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?(e: MouseEvent<HTMLButtonElement>): void;
  loading?: boolean;
  isDisabled?: boolean;
  className?: string;
  buttonClass?: string;
  buttonType?: ButtonType;
  style?: React.CSSProperties | undefined;
  title?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  leaveOnlyIconOnSmallScreen?: boolean;
  formId?: string;
}

interface IState {}

class Button extends React.Component<IButtonProps, IState> {
  render() {
    const loading = this.props.loading ? 'loading' : undefined;
    return (
      <div
        className={combineClassNames(
          'Button',
          this.props.className,
          this.props.buttonType,
          loading,
        )}
      >
        <button
          onMouseEnter={() => {
            if (this.props.onMouseEnter) {
              this.props.onMouseEnter();
            }
          }}
          onMouseLeave={() => {
            if (this.props.onMouseLeave) {
              this.props.onMouseLeave();
            }
          }}
          style={this.props.style ? this.props.style : {}}
          className={this.props.buttonClass}
          disabled={!!(this.props.isDisabled || this.props.loading)}
          type={this.props.type}
          title={this.props.title}
          onClick={(e) => {
            if (this.props.onClick !== undefined) {
              this.props.onClick(e);
            }
          }}
          form={this.props.formId}
        >
          {this.props.icon !== undefined &&
            (this.props.loading === undefined || !this.props.loading) && (
              <span className={`icon ${this.props.leaveOnlyIconOnSmallScreen ? 'leave-only' : ''}`}>
                <i className={this.props.icon} />
              </span>
            )}
          {this.props.imgIcon !== undefined &&
            (this.props.loading === undefined || !this.props.loading) && (
              <span className={`icon ${this.props.leaveOnlyIconOnSmallScreen ? 'leave-only' : ''}`}>
                <img
                  src={this.props.imgIcon.src}
                  width={this.props.imgIcon.width}
                  height={this.props.imgIcon.height}
                  alt={this.props.imgIcon.alt}
                />
              </span>
            )}
          {(this.props.loading === undefined || !this.props.loading) && this.props.text && (
            <span className="text">{this.props.text}</span>
          )}
          {this.props.loading !== undefined && this.props.loading && (
            <i className="fad fa-spinner fa-spin" />
          )}
          {this.props.children}
        </button>
      </div>
    );
  }
}

export default Button;
