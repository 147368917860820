import axios from 'axios';
import {
  IGigVolunteer,
  IGigVolunteerSetFlagParams,
  IGigVolunteerSetFlagParamsBulk,
  IGigVolunteerSummary,
  IShiftUserBasic,
  IShiftUserSummary,
  IVolunteer,
} from '@gigit/interfaces';
import { routes, swapRouteParams } from '../helpers';

export namespace volunteerRequestActions {
  export async function removeUserFromShift(payload: {
    gigId: string;
    shiftId: string;
    userId: string;
  }) {
    const response = await axios.delete(
      swapRouteParams(routes.REMOVE_USER_FROM_SHIFT, {
        gigId: payload.gigId,
        shiftId: payload.shiftId,
        userId: payload.userId,
      }),
    );

    return response.data;
  }

  export async function setVolunteerFlag(
    gig_id: string,
    user_id: string,
    payload: IGigVolunteerSetFlagParams,
  ) {
    const response = await axios.post<IGigVolunteer>(
      swapRouteParams(routes.POST_SET_VOLUNTEER_FLAG, { gig_id, user_id }),
      payload,
    );
    return response.data;
  }

  export async function setVolunteerFlagBulk(
    gig_id: string,
    payload: IGigVolunteerSetFlagParamsBulk[],
  ) {
    const response = await axios.post<IGigVolunteer[]>(
      swapRouteParams(routes.POST_SET_VOLUNTEER_FLAG_BULK, { gig_id }),
      payload,
    );
    return response.data;
  }

  export async function getShiftsForUser(payload: {
    groupId: string;
    userId: string;
  }): Promise<IShiftUserSummary[]> {
    const response = await axios.get<IShiftUserSummary[]>(
      swapRouteParams(routes.GET_VOLUNTEER_SHIFTS_FOR_USER, {
        groupId: payload.groupId,
        userId: payload.userId,
      }),
    );

    return response.data;
  }

  export async function updateUserShiftHours(payload: {
    gigId: string;
    shift_id: string;
    user_id: string;
    hours: number;
  }) {
    const response = await axios.post(
      swapRouteParams(routes.UPDATE_SHIFT_USER_HOURS, {
        gigId: payload.gigId,
        shift_id: payload.shift_id,
        user_id: payload.user_id,
      }),
      { value: payload.hours },
    );

    return response.data;
  }

  export async function verifyShiftHours(payload: {
    gigId: string;
    shift_id: string;
    userId: string;
    hours: number;
  }) {
    const response = await axios.post(
      swapRouteParams(routes.VERIFY_SHIFT_HOURS, {
        gigId: payload.gigId,
        shift_id: payload.shift_id,
        userId: payload.userId,
      }),
      { hours: { value: payload.hours } },
    );

    return response.data;
  }

  export async function bulkVerifyShiftHours(payload: {
    gigId: string;
    shiftUsers: IShiftUserBasic[];
  }) {
    const response = await axios.post(
      swapRouteParams(routes.BULK_VERIFY_SHIFT_HOURS, {
        gigId: payload.gigId,
      }),
      payload.shiftUsers,
    );

    return response.data;
  }

  export async function getVolunteerForUser(payload: { groupId: string; userId: string }) {
    const response = await axios.get<IVolunteer>(
      swapRouteParams(routes.GET_VOLUNTEER_FOR_USER, {
        groupId: payload.groupId,
        userId: payload.userId,
      }),
    );

    return response.data;
  }

  export async function assignUserToShift(payload: {
    gigId: string;
    shiftId: string;
    userId: string;
  }) {
    const response = await axios.post(
      swapRouteParams(routes.ASSIGN_USER_TO_SHIFT, {
        gigId: payload.gigId,
        shiftId: payload.shiftId,
      }),
      { value: [payload.userId] },
    );

    return response.data;
  }

  export async function getVolunteersList(gig_id: string, query?: URLSearchParams) {
    const response = await axios.get<IGigVolunteerSummary[]>(
      swapRouteParams(routes.GET_GIG_VOLUNTEERS_VOLUNTEERING, { gig_id }),
      { params: query },
    );
    return response.data;
  }
}
