import React from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../../store';
import {
  defaultCurrency,
  routes,
  formatCurrency,
  errorHelpers,
  toastError,
} from '../../../helpers';
import { userSelectors } from '../../../selectors/user';

import { IUserState } from '../../../reducers/user';
import { IEventState } from '../../../reducers/event';

import Portrait from '../../../components/Portrait/Portrait';
import QuillTextEditor from '../../../components/QuillTextEditor/QuillTextEditor';
import StravaSection from '../../../components/StravaSection/StravaSection';
import { BenefitingCharityTile } from '../../../components/BenefitingCharityTile/BenefitingCharityTile';

import './TeamStreamer.scss';
import { uiConstants } from '../../../constants/uiConstants';
import typeHelpers from '../../../helpers/typeHelpers';
import { IEventIndividual, IEventTeam, IFundraiserDonation, IGroup } from '@gigit/interfaces';
import { teamRequestActions } from '../../../requestActions/team';
import { createToast } from '../../../actions/toaster';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  eventState: IEventState;
  userState: IUserState;
  locale: string;
  onChangeStreamer(id: string): void;
}

interface IState {
  selectedTab: string;
  editStory: boolean;
  donations: any[];
  innerWidth: number;
  team: any;
  eventTeam: IEventTeam | null;
  teamMembers: IEventIndividual[];
  teamDonations: IFundraiserDonation[];
  members: IMember[];
}

interface IMember {
  display_name: string;
  raised: number;
  profile_image_url: string;
  twitch_id: string;
  is_online: boolean;
  handle: string;
}

class TeamStreamer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedTab: 'details',
      editStory: false,
      donations: [],
      innerWidth: window.innerWidth,
      team: null,
      eventTeam: null,
      teamMembers: [],
      teamDonations: [],
      members: [],
    };

    this.saveTeam = this.saveTeam.bind(this);
    this.handleStoryChange = this.handleStoryChange.bind(this);
    this.getProgressWidth = this.getProgressWidth.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.getTwitchLiveStatus = this.getTwitchLiveStatus.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    // NOTE: Disabled for now since donation broadcast currently doesn't include team id.
    // let socket = io(Config.web.REACT_APP_CHAT_GATEWAY + '/chat');
    // socket.on('broadcast', (msg: IBroadcastMessage<IIndividualFundraisingDonationBroadcastContent>) => {
    //     switch (msg.message_type) {
    //         case 'donation':
    //             if (false) {
    //                 this.addDonation(msg);
    //             }
    //         break;
    //     }
    // });

    if (this.state.teamMembers) {
      let _members: IMember[] = [];

      for (const member of this.state.teamMembers) {
        typeHelpers.assertNotNullOrUndefined(member.user);

        let _member: IMember = {
          display_name: member.user.display_name ?? '',
          raised: member.raised ?? 0,
          profile_image_url: member.user.profile_image_url,
          twitch_id: member.user.twitch?.login || '',
          is_online: false,
          handle: member.user.handle,
        };

        _members.push(_member);
      }

      this.setState({
        team: this.state.eventTeam,
        members: _members,
      });
    }

    this.getTwitchLiveStatus();
  }

  handleResize(e: any) {
    this.setState({
      innerWidth: window.innerWidth,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.eventState.event !== prevProps.eventState.event &&
      this.props.eventState.event.id !== ''
    ) {
      this.getTeam(this.props.eventState.event.id, this.props.match.params.id);
      this.setState({
        team: this.state.eventTeam,
      });
    }

    if (
      prevState.teamDonations !== this.state.teamDonations &&
      this.state.eventTeam?.handle === this.props.match.params.id
    ) {
      this.setState({
        donations: this.state.teamDonations,
      });
    }

    if (prevState.members.length !== this.state.members.length) {
      this.getTwitchLiveStatus();
    }
  }

  getProgressWidth() {
    if (this.state.eventTeam?.raised && this.state.eventTeam.goal) {
      return (this.state.eventTeam.raised / this.state.eventTeam.goal) * 100 + '%';
    }

    return '0%';
  }

  handleStoryChange(content: string) {
    this.setState({
      team: {
        ...this.state.team,
        story: content,
      },
    });
  }

  saveTeam() {
    this.updateTeam(this.props.eventState.event.id, this.state.team.id, this.state.team);
  }

  async updateTeam(eventId: string, teamId: string, payload: IEventTeam) {
    try {
      await teamRequestActions.updateTeam(eventId, teamId, payload);
      this.getTeam(eventId, teamId);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Update Team');
      createToast(toast);
    }
  }

  async getTeam(eventId: string, teamId: string) {
    try {
      const eventTeam = await teamRequestActions.getTeam(eventId, teamId);
      this.setState({ eventTeam });
      if (eventTeam.id) {
        this.getTeamMembers(eventId, eventTeam.id);
        this.getTeamDonations(eventId, eventTeam.id);
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Team');
      createToast(toast);
    }
  }

  async getTeamMembers(eventId: string, teamId: string) {
    try {
      const teamMembers = await teamRequestActions.getTeamMembers(eventId, teamId);
      this.setState({ teamMembers });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Team Members');
      createToast(toast);
    }
  }

  async getTeamDonations(eventId: string, teamId: string) {
    try {
      const teamDonations = await teamRequestActions.getTeamDonations(eventId, teamId);
      this.setState({ teamDonations });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Retrieve Team Donations');
      createToast(toast);
    }
  }

  // NOTE: Disabled for now since donation broadcast currently doesn't include team id. We would also need to set the raised amount.
  // addDonation(msg: IBroadcastMessage<IIndividualFundraisingDonationBroadcastContent>) {
  //     let _donations = [...this.state.donations];

  //     _donations.unshift({
  //         amount: msg.content.amount,
  //         display_name: msg.content.display_name,
  //         profile_image_url: msg.content.profile_image_url,
  //         socket: true
  //     });

  //     this.setState({
  //         donations: _donations,
  //     });
  // }

  getTwitchLiveStatus() {
    let _twitchIds: string[] = [];

    for (let m in this.state.members) {
      let _member = this.state.members[m];

      if (_member.twitch_id !== '') {
        _twitchIds.push(_member.twitch_id);
      }
    }

    if (_twitchIds.length > 0) {
      let _defaultStreamer: string = '';

      Axios.post(routes.TWITCH_STATUS, { value: _twitchIds }).then((response) => {
        let _members = [...this.state.members];

        for (let r in response.data) {
          for (let m in _members) {
            if (_members[m].twitch_id === response.data[r].login) {
              _members[m].is_online = response.data[r].is_online;
              if (_defaultStreamer === '' && response.data[r].is_online) {
                _defaultStreamer = _members[m].twitch_id;
              }
            }
          }
        }

        this.setState(
          {
            members: _members,
          },
          () => {
            if (_defaultStreamer !== '') {
              this.props.onChangeStreamer(_defaultStreamer);
            }
          },
        );
      });
    }
  }

  render() {
    const { t } = this.props;

    const currency = this.props.eventState.event.group?.account?.currency ?? defaultCurrency;

    let _mobileBreakpoint = 1240;

    return (
      <div className="TeamStreamer">
        <div className="Team-content">
          <div className="progress-bar-wrap">
            <div className="progress-bar-bg">
              <div
                className="progress-bar"
                style={{ width: this.getProgressWidth() }}
              ></div>
              <div className="amt">
                {formatCurrency(this.state.eventTeam?.raised || 0, currency, this.props.locale)}
              </div>
              <div className="goal">
                {formatCurrency(this.state.eventTeam?.goal || 0, currency, this.props.locale)}
              </div>
            </div>
          </div>
          <ul className="mobile-tabs">
            <li
              onClick={(e: any) => {
                this.setState({ selectedTab: 'details' });
              }}
              className={this.state.selectedTab === 'details' ? 'active' : ''}
            >
              {t('Details')}
            </li>
            <li
              onClick={(e: any) => {
                this.setState({ selectedTab: 'donations' });
              }}
              className={this.state.selectedTab === 'donations' ? 'active' : ''}
            >
              {t('Donations')}
            </li>
          </ul>
          <div className="main">
            {((this.state.selectedTab === 'details' &&
              this.state.innerWidth <= _mobileBreakpoint) ||
              this.state.innerWidth > _mobileBreakpoint) && (
              <div className="details">
                <div className="details-inner">
                  <div className="title">{t('Details')}</div>
                  <div className="detail-items">
                    <div className="detail">
                      <div className="icon">
                        <i className="far fa-ticket-alt"></i>
                      </div>
                      <div className="detail-item">
                        <span>{t('EVENT')}</span>
                        <div
                          className="detail-val"
                          notranslate="yes"
                        >
                          <Link to={'/event/' + this.props.eventState.event?.handle}>
                            {this.props.eventState.event.title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="members">
                  <div className="title">{t('Team')}</div>
                  <ul>
                    {this.state.members.map((member, index) => {
                      return (
                        <li
                          className={member.is_online ? 'live' : ''}
                          key={index}
                        >
                          <div
                            className="member"
                            onClick={() =>
                              this.props.history.push(
                                `/event/${this.props.eventState.event.handle}/individual/${member.handle}`,
                              )
                            }
                          >
                            <Portrait
                              size={90}
                              currentImage={member.profile_image_url}
                            />
                            <div
                              className="displayName"
                              notranslate="yes"
                            >
                              {member.display_name}
                            </div>
                            <div className="raised">
                              {
                                formatCurrency(
                                  member.raised || 0,
                                  currency,
                                  this.props.locale,
                                ).split('.')[0]
                              }
                            </div>
                            {member.is_online && (
                              <div className="live-tag">
                                <div className="live-dot"></div>
                                <span className="live-text">{t('LIVE')}</span>
                              </div>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="about">
                  <div className="title">
                    <span>Story</span>
                    {this.state.eventTeam?.owner_id === this.props.userState.user.id && (
                      <i
                        onClick={() => {
                          this.setState({ editStory: !this.state.editStory }, () => {
                            if (!this.state.editStory) {
                              this.saveTeam();
                            }
                          });
                        }}
                        className={this.state.editStory ? 'fa fa-save' : 'fa fa-edit'}
                      ></i>
                    )}
                  </div>
                  {!this.state.editStory &&
                    this.state.team !== null &&
                    (this.state.team.story === '' || this.state.team.story === '<p><br></p>') && (
                      <p>{t("This individual doesn't have a story yet.")}</p>
                    )}
                  {this.state.team !== null && (
                    <QuillTextEditor
                      value={this.state.team.story}
                      readOnly={!this.state.editStory}
                      preserveWhitespace={true}
                      theme={
                        this.state.editStory
                          ? uiConstants.quill.editableTheme
                          : uiConstants.quill.readOnlyTheme
                      }
                      modules={{
                        toolbar: uiConstants.quill.defaultToolbar,
                        clipboard: {
                          matchVisual: false,
                        },
                      }}
                      formats={uiConstants.quill.defaultFormats}
                      onChange={this.handleStoryChange}
                    />
                  )}
                </div>
                {this.props.eventState.event.group && (
                  <BenefitingCharityTile group={this.props.eventState.event.group as IGroup} />
                )}
                <StravaSection type="team" />
              </div>
            )}
            {((this.state.selectedTab === 'donations' &&
              this.state.innerWidth <= _mobileBreakpoint) ||
              this.state.innerWidth > _mobileBreakpoint) && (
              <div className="donations">
                <div className="title">{t('Donations')}</div>
                <ul>
                  {this.state.donations.map((donation, index) => {
                    return (
                      <li
                        key={index}
                        className={donation.socket ? 'donation new' : 'donation'}
                      >
                        <div className="info">
                          <div className="portrait">
                            <Portrait
                              size={48}
                              currentImage={donation.profile_image_url}
                            />
                          </div>
                          <div className="name">
                            <span>{donation.display_name || donation.user_handle}</span>
                            {t('donated')}
                          </div>
                          <div className="amt">
                            {formatCurrency(
                              donation.amounts.amount / 100 || 0,
                              currency,
                              this.props.locale,
                            )}
                          </div>
                        </div>
                        <div className="msg">{donation.comments}</div>
                      </li>
                    );
                  })}
                  {this.state.donations.length === 0 && (
                    <li className="empty">{t('No donations have been received.')}</li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="mobile-donate">
          <Link
            to={
              '/event/' +
              this.state.eventTeam?.event?.handle +
              '/team/' +
              this.state.eventTeam?.handle +
              '/donate'
            }
            className="donate"
          >
            {t('Donate')}
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

export default withTranslation('translations')(connect(mapStateToProps)(TeamStreamer));
