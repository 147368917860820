import { useEffect } from 'react';
import { MutableRefObject } from 'react';
export function useOnClickOutside(
  ref: MutableRefObject<HTMLElement>,
  handler: Function,
  optionalRefs?: MutableRefObject<HTMLElement>[],
) {
  useEffect(() => {
    // TODO: Proper Types
    const listener = (event: any) => {
      let containsOptionalRef = false;
      optionalRefs?.forEach((optionalRef: MutableRefObject<HTMLElement>) => {
        if (!optionalRef.current || optionalRef.current.contains(event.target))
          containsOptionalRef = true;
      });
      if (!ref.current || ref.current.contains(event.target) || containsOptionalRef) return;
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
