import React from 'react';
import { combineClassNames } from '../../../../helpers';
import Checkbox from '../../../Checkbox/Checkbox';
import Portrait from '../../../Portrait/Portrait';

import './CheckboxCard.scss';

export interface ICheckboxCardCheckboxOptions {
  checkBoxOptions: {
    checked: boolean;
    showCheckbox: boolean;
    position: 'top' | 'middle' | 'bottom';
  };
}
export interface ICheckboxCardOptions {
  title: string;
  subtext: string;
  id: string;
  imageOptions?: {
    imageSource: string;
  };
  checkboxOptions: ICheckboxCardCheckboxOptions;
}

// TODO IMPLEMENT INTERFACE AND PASS ONE PROP OF OPTIONS
interface IProps {
  title: string;
  checked: boolean;
  id: string;
  imageOptions?: {
    imageSource: string;
  };

  showCheckbox: boolean;
  advanced?: boolean;
  secondaryTitle?: string;
  list?: string[];
  listItemIconClass?: string;
  subtext?: string;
  onChange(e: string): void;
  isDisabled?: boolean;
}

export const CheckboxCard: React.FC<IProps> = (props: IProps) => {
  const { checked, showCheckbox, onChange, id, imageOptions } = props;
  const withImage = imageOptions ? 'with-image' : '';
  return (
    <label
      className={
        props.checked
          ? `CheckboxCard no-select selected ${props.advanced ? 'advanced' : ''} ${withImage}`
          : `CheckboxCard no-select ${props.advanced ? 'advanced' : ''} ${withImage}`
      }
    >
      <Checkbox
        disabled={props.isDisabled}
        className={props.showCheckbox ? 'show' : 'hide'}
        name={props.title}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        checked={props.checked}
        value={props.id}
        color="default"
        label=""
      />
      <div className="portrait-container">
        {imageOptions && (
          <Portrait
            currentImage={imageOptions?.imageSource}
            size={40}
          />
        )}
      </div>
      <div className="title">
        {props.title}
        {props.advanced && props.secondaryTitle && (
          <span className="secondary-title">{props.secondaryTitle}</span>
        )}
      </div>
      {props?.subtext && <div className="subtext">{props.subtext}</div>}
      {props.advanced && props.list && (
        <ul className="advanced-list">
          {props.list.map((item, index) => {
            return (
              <li
                key={index}
                className="advanced-list-item"
              >
                {props.listItemIconClass && <i className={`${props.listItemIconClass}`} />}
                {item}
              </li>
            );
          })}
        </ul>
      )}
    </label>
  );
};
