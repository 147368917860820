import React, { useEffect, useState } from 'react';
import './HubAuctionSimplifiedView.scss';
import Carousel from '../../../Carousel/Carousel';
import HubAuctionSingleItem from '../HubAuctionSingleItem/HubAuctionSingleItem';
import HubAuctionEmptyState from '../HubAuctionEmptyState/HubAuctionEmptyState';
import HubAuctionDetailItemModal from '../HubAuctionDetailItemModal/HubAuctionDetailItemModal';
import { hubRequestActions } from '../../../../requestActions';
import { formatQuery } from '../../../../helpers';
import { uiConstants } from '../../../../constants';
import { IAuctionItemSummary, IHub } from '@gigit/interfaces';
import useToastDispatcher from '../../../../hooks/useToaster';
import { Constants } from '@gigit/constants';

interface IProps {
  hub: IHub | null;
  userHasEditPermissions: boolean;
}

const HubAuctionSimplifiedView: React.FC<IProps> = (props) => {
  const [auctionItems, setAuctionItems] = useState<IAuctionItemSummary[]>([]);
  const [activeAuctionItem, setActiveAuctionItem] = useState<IAuctionItemSummary | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { dispatchToastError } = useToastDispatcher();

  async function getHubAuctionItems() {
    if (props?.hub?.id) {
      try {
        const result = await hubRequestActions.getHubAuctionItems(
          props.hub.id,
          formatQuery({
            limit: uiConstants.hubs.homePageAuctionItemsLimit,
            filters: [
              {
                value: Constants.auction_item_status.running,
                id: 'status.code',
                type: 'text',
                label: 'status',
              },
            ],
          }),
        );

        setAuctionItems(result);
      } catch (error) {
        dispatchToastError(error, 'Get Hub Auction Items');
      }
    }
  }

  useEffect(() => {
    if (props.hub?.id) {
      getHubAuctionItems();
    }
  }, [props.hub]);

  function getCarouselSlides() {
    return auctionItems.map((item, index) => (
      <HubAuctionSingleItem
        key={index}
        auctionItem={item}
        openAuctionItemDetailModal={() => {
          setActiveAuctionItem(item);
          setShowModal(true);
        }}
      />
    ));
  }

  return (
    <div className="HubAuctionSimplifiedView">
      <div className="section-content">
        {auctionItems.length > 0 && (
          <Carousel
            infinite={false}
            slidesToScroll={5}
            slidesToShow={4}
            showArrows={true}
            showDots={true}
            nextArrow={
              <span>
                <i className="fas fa-chevron-right" />
              </span>
            }
            prevArrow={
              <span>
                <i className="fas fa-chevron-left" />
              </span>
            }
            slides={getCarouselSlides()}
          />
        )}

        {auctionItems.length < 1 && (
          <HubAuctionEmptyState
            userHasEditPermissions={props.userHasEditPermissions}
            hub={props.hub}
            onlyActive={true}
          />
        )}

        {activeAuctionItem && (
          <HubAuctionDetailItemModal
            show={showModal}
            onCloseDetailModal={() => {
              setShowModal(false);
              setActiveAuctionItem(null);
            }}
            hubHandle={props.hub?.handle || null}
            auctionItem={activeAuctionItem}
            getHubAuctionItems={() => getHubAuctionItems()}
            hubName={props.hub?.title}
            hubLogoUrl={props.hub?.profile_image_url}
          />
        )}
      </div>
    </div>
  );
};

export default HubAuctionSimplifiedView;
