import { Reducer } from 'redux';
import { HubActions, HubActionTypes } from '../actions/hub';
import { IGroup, IHub } from '@gigit/interfaces';

export interface ICurrentHub extends IHub {
  groups?: IGroup[];
}

export interface IHubState {
  isHubsLoading: boolean;
  hubs: IHub[];
  current: ICurrentHub | null;
  error: string;
  success: boolean;
}

export const initialHubState: IHubState = {
  isHubsLoading: false,
  hubs: [],
  current: null,
  error: '',
  success: false,
};

export const hubReducer: Reducer<IHubState, HubActions> = (state = initialHubState, action) => {
  switch (action.type) {
    case HubActionTypes.UPDATE_HUBS: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
