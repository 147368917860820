import React, { useState, useRef } from 'react';
import PopupMenu from '../../../components/shared/PopupMenu/PopupMenu';
import { searchCategoryOptions } from '../Config';

interface ISearchEntitySelect {
  searchCategory: string;
  setSearchCategory: (type: string) => void;
}
export default function SearchEntitySelect(props: ISearchEntitySelect) {
  const { searchCategory, setSearchCategory } = props;
  const [showTypeMenu, setShowTypeMenu] = useState<boolean>(false);
  const typeSelectRef = useRef<HTMLDivElement>(null);

  const getLabel = () => {
    const category = searchCategoryOptions.find((category) => category.value === searchCategory);
    return category?.label;
  };

  return (
    <div className="SearchEntitySelect filter-dropdown">
      <PopupMenu
        showMenuConfig={{
          showMenu: showTypeMenu,
          setShowMenu: setShowTypeMenu,
          position: {
            type: 'bottom',
          },
        }}
        menuItems={[
          {
            id: 'groups',
            label: 'Causes',
            isSelected: searchCategory === 'groups',
            onClick: () => {
              setSearchCategory('groups');
              setShowTypeMenu(false);
            },
          },
          {
            id: 'events',
            label: 'Events',
            isSelected: searchCategory === 'events',
            onClick: () => {
              setSearchCategory('events');
              setShowTypeMenu(false);
            },
          },
          {
            id: 'volunteer',
            label: 'Volunteer Opportunities',
            isSelected: searchCategory === 'volunteer',
            onClick: () => {
              setSearchCategory('volunteer');
              setShowTypeMenu(false);
            },
          },
        ]}
        popupMenuClass="mypages-popupMenu-sortBy"
        className={`Dropdown filter-dropdown`}
        onClick={() => setShowTypeMenu(!showTypeMenu)}
        width={
          typeSelectRef.current?.clientWidth ? typeSelectRef.current.clientWidth - 38 : undefined
        }
      >
        <label>
          <span>Type</span>
        </label>
        <div className="input-wrap">
          <span className="label">{getLabel()}</span>
          <i className={`fas fa-caret-down menu-btn ${showTypeMenu ? 'show' : ''}`} />
        </div>
      </PopupMenu>
    </div>
  );
}
