import axios from 'axios';

import { Constants } from '@gigit/constants';
import { IInvite, IRole } from '@gigit/interfaces';

import { routes, swapRouteParams } from '../helpers';

export namespace roleRequestActions {
  export async function getRoles(ownerType: string, ownerId: string) {
    let url;
    let idField;
    switch (ownerType) {
      case Constants.object_type.group:
        url = routes.GET_GROUP_ROLES;
        idField = 'groupId';
        break;
      case Constants.object_type.event:
        url = routes.GET_EVENT_ROLES;
        idField = 'eventId';
        break;
      case Constants.object_type.hub:
        url = routes.HUB_ROLES;
        idField = 'hubId';
        break;
      default:
        return;
    }
    // const url = ownerType === Constants.object_type.group ? routes.GET_GROUP_ROLES : routes.GET_EVENT_ROLES;
    // const idField = ownerType === Constants.object_type.group ? 'groupId' : 'eventId';

    return axios.get<IRole[]>(swapRouteParams(url, { [idField]: ownerId }));
  }
}
