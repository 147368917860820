import { useState } from 'react';
import { ICausesSelection } from '../interfaces';

interface IUseCauseInfo {
  causeInfo: ICausesSelection;
  handleCauseInfo(causeInfo: ICausesSelection): void;
}

export function useCauseInfo<T>(): IUseCauseInfo {
  const [causeInfo, setCauseInfo] = useState<ICausesSelection>({ causes: [], selectedCauses: [] });

  function handleCauseInfo(causeInfo: ICausesSelection) {
    setCauseInfo(causeInfo);
  }
  return { causeInfo, handleCauseInfo };
}
