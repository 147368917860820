import { IConversationSummary, IUserBase } from '@gigit/interfaces';

export function getConversationUser(conversation: IConversationSummary, userId: string) {
  return conversation?.users?.find((user: IUserBase) => user.id === userId);
}

export function getProfileImg(conversation: IConversationSummary, userId: string) {
  const user = getConversationUser(conversation, userId);

  return user ? user?.profile_image_url : '';
}

export function getOnlinePresence(userId: string | undefined, onlineUsers: Array<string>) {
  return onlineUsers.find((uId: string) => uId === userId);
}

export function setConvoTitle(conversation: IConversationSummary) {
  if (conversation?.users) {
    const user = conversation.users
      .map((member: IUserBase) => {
        return member.display_name;
      })
      .filter(Boolean);

    if (user.length > 0) {
      return user.join(', ');
    } else {
      return user[0];
    }
  }
}
