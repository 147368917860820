import React, { FC, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DiscoverCard from '../../components/DiscoverCard/DiscoverCard';
import RequestSectionImg from '../../assets/discover_hubs_request_section_img.png';
import axios from 'axios';
import { IDiscoverFilters, IDiscoverItem } from '@gigit/interfaces';
import './DiscoverStyles.scss';
import Button from '../../components/Button/Button';
import {
  combineClassNames,
  formatQuery,
  generateEmptyCards,
  IQueryParams,
  routes,
  setSEOMetatags,
  swapRouteParams,
} from '../../helpers';
import { IGeolocationState } from '../../reducers/geolocation';
import { IAppState } from '../../store';
import { updateGeolocation } from '../../actions/geolocation';
import { uiConstants } from '../../constants';
import { IHorizontalScrollerRenderProps } from '../../components/shared/HorizontalScroller/HorizontalScroller';
import LandingDiscoverHeader from '../../components/LandingDiscoverHeader/LandingDiscoverHeader';
import { localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import useToastDispatcher from '../../hooks/useToaster';
import CreateHubSectionImg from '../../assets/discover_hub_page_create_sectionimg.png';
import { hubRequestActions, userRequestActions } from '../../requestActions';
import { Constants } from '@gigit/constants';
import { IPopupMenuItem } from '../../components/shared/PopupMenu/PopupMenu';
import DiscoverPagesFilter from './DiscoverPagesFilter/DiscoverPagesFilter';
import CardLoader from '../../components/shared/CardLoader/CardLoader';
import { ECardLoaderStyleType } from '../../components/shared/CardLoader/CardLoaderItem/CardLoaderItem';
import { CardCommon, CardHeader, CardContent, CardActions } from '../../components/CardCommon';
import volunteerImg from '../../assets/volunteers-empty-state.png';
import coverTestImg from '../../assets/universities_and_colleges_main.png';
import {
  CardCommonHub,
  EViewTypeCardCommonHub,
  EStyleTypeCardCommonHub,
} from '../../components/CardCommonHub/CardCommonHub';
import { CardCommonHubSkeleton } from '../../components/CardCommonHub/CardCommonHubSkeleton';
import EmptyImg from '../../assets/people-in-cicle.svg';
import { Config } from '@gigit/config';

interface IProps extends RouteComponentProps {}

interface FilterItem {
  icon: string;
  title: string;
  onFilter: () => void;
}

const DiscoverHubs: FC<IProps> = (props: IProps) => {
  const geolocationState: IGeolocationState = useSelector(
    (state: IAppState) => state.geolocationState,
  );
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const user = useSelector((state: IAppState) => userSelectors.getUser(state));
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));

  const dispatch = useDispatch();
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const loadDataLimit = 6;
  const [activeFilter, setActiveFilter] = useState<number>(0);
  const [hubList, setHubList] = useState<IDiscoverItem[]>([]);
  const [skip, setSkip] = useState<number>(0);
  const [lastResponseCount, setLastResponseCount] = useState<number>(6);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [activeSortBy, setActiveSortBy] = useState<{ label: string; value: string }>({
    label: 'Newest',
    value: uiConstants.discoverFilters.recent,
  });
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<string[]>([]);

  const sortByItems: IPopupMenuItem[] = [
    {
      id: uiConstants.discoverFilters.recent,
      label: 'Newest',
      icon: 'far fa-clock',
      isSelected: activeSortBy?.value === uiConstants.discoverFilters.recent,
      onClick: async () => {
        setActiveSortBy({ label: 'Newest', value: uiConstants.discoverFilters.recent });
      },
    },
    {
      id: uiConstants.discoverFilters.trending,
      label: 'Trending',
      icon: 'far fa-fire',
      isSelected: activeSortBy?.value === uiConstants.discoverFilters.trending,
      onClick: async () => {
        setActiveSortBy({ label: 'Trending', value: uiConstants.discoverFilters.trending });
      },
    },
  ];

  useEffect(() => {
    setSEOMetatags({
      title: localizeHelpers.translate('Discover Hubs | Kambeo'),
      urlPath: '',
    });
    dispatch(updateGeolocation());
  }, []);

  useEffect(() => {
    getHubs();
  }, [selectedFocusAreas, activeSortBy.value]);

  const renderPageItem = (
    filter: FilterItem,
    index: number,
    scrollerProps: IHorizontalScrollerRenderProps,
  ) => {
    const onClick = () => {
      scrollerProps.moveItemIntoFocus(index);
      filter.onFilter();
      setActiveFilter(index);
    };

    return (
      <li
        key={index}
        className={combineClassNames(
          `page-item ${filter.icon}`,
          activeFilter === index ? 'fas selected' : 'far',
        )}
        onClick={onClick}
      >
        {filter.title}
      </li>
    );
  };

  const getHubs = async (loadMore = false) => {
    setDataLoading(true);
    let filterData: {
      start_date?: Date | null;
      trending?: boolean;
    } = {
      start_date: null,
    };

    let query = {} as IQueryParams;

    switch (activeSortBy.value) {
      case uiConstants.discoverFilters.happeningSoon:
        filterData.start_date = new Date(Date.now());
        break;
      case uiConstants.discoverFilters.trending:
        filterData.trending = true;
        break;
      case uiConstants.discoverFilters.recent:
        query.sort = [{ id: 'created_at', order: 'desc' }];
        break;
    }

    const defaultCoords = uiConstants.defaultCoords;
    let location = {};
    let userLocation: string = '';

    if (userState && userState.locations[0]) {
      userLocation =
        userState.locations[0].city +
        ' ' +
        userState.locations[0].state +
        ', ' +
        userState.locations[0].country;
    }

    if (isUserLoggedIn && userLocation !== '') {
      try {
        await fetch(
          'https://maps.googleapis.com/maps/api/geocode/json?address=' +
            userLocation +
            '&key=' +
            Config.web.REACT_APP_GOOGLE_API_KEY,
        )
          .then((response) => response.json())
          .then((data) => {
            location = {
              coordinates: [
                data.results[0].geometry.location.lng,
                data.results[0].geometry.location.lat,
              ],
              max_distance: 100,
              distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
            };
          });
      } catch (error) {
        dispatchToastError(error, 'Google Maps');
      }
    } else if (geolocationState) {
      location = {
        coordinates: [geolocationState.lng, geolocationState.lat],
        max_distance: 100,
        distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
      };
    } else {
      location = {
        coordinates: [defaultCoords.lng, defaultCoords.lat],
        max_distance: 100,
        distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
      };
    }

    try {
      let _payload = {
        location,
        filter: filterData,
        common: {
          causes: selectedFocusAreas.length ? selectedFocusAreas : undefined,
        },
      } as IDiscoverFilters;

      let route = swapRouteParams(routes.DISCOVER_HUBS, {
        limit: loadDataLimit,
        skip: loadMore ? skip + loadDataLimit : skip,
      });

      setSkip(loadMore ? skip + loadDataLimit : skip);

      if (query) {
        route += `&${formatQuery(query).toString()}`;
      }

      const response = await axios.post(route, _payload);

      if (loadMore) {
        setHubList([...hubList, ...response.data]);
      } else {
        setHubList(response.data);
      }

      setLastResponseCount(response.data.length);
      setDataLoading(false);
    } catch (error) {
      dispatchToastError(error, 'Discover Hubs');
    }
  };

  async function doesUserFollowHub(hubId: string): Promise<boolean> {
    if (user?.id) {
      try {
        const result = await userRequestActions.getUserHubRole(hubId);

        return result?.user_capacity
          ? result?.user_capacity.includes(Constants.user_capacity.follower)
          : false;
      } catch (error) {
        dispatchToastError(error, 'Does User Follow Hub');
      }
    }

    return false;
  }

  async function toggleFollow(hubId: string) {
    if (user?.id) {
      const isFollowing = await doesUserFollowHub(hubId);

      try {
        if (isFollowing) {
          await hubRequestActions.userUnfollowHub(user.id, hubId);

          dispatchToastSuccess('Successfully unfollowed Hub', 'Unfollow Hub');
        } else {
          await hubRequestActions.userFollowHub(user.id, hubId);

          dispatchToastSuccess('Successfully followed Hub', 'Follow Hub');
        }
      } catch (error) {
        dispatchToastError(error, `${isFollowing ? 'Unfollow Hub' : 'Follow Hub'}`);
      }
    }
  }

  async function toggleFocusArea(value: string) {
    if (selectedFocusAreas.indexOf(value) === -1) {
      setSelectedFocusAreas([...selectedFocusAreas, value]);
    } else {
      setSelectedFocusAreas(selectedFocusAreas.filter((fa) => fa !== value));
    }
  }

  return (
    <React.Fragment>
      <div className="Discover">
        <LandingDiscoverHeader
          pathname={props.location.pathname}
          isUserLoggedIn={isUserLoggedIn}
        />
        <div className="discover-title">
          <div className="title">
            Discover Companies in{' '}
            <u>
              {userState.locations && userState.locations[0] && isUserLoggedIn
                ? userState.locations[0].city +
                  ', ' +
                  userState.locations[0].state?.substring(0, 2).toUpperCase()
                : geolocationState.locality}
            </u>
          </div>
          <div className="mob-title">
            Discover Companies in{' '}
            <u>
              {userState.locations && userState.locations[0] && isUserLoggedIn
                ? userState.locations[0].city +
                  ', ' +
                  userState.locations[0].state?.substring(0, 2).toUpperCase()
                : geolocationState.locality}
            </u>
          </div>
        </div>
        <div className="discover-filters">
          <DiscoverPagesFilter
            sortByItems={sortByItems}
            activeSortBy={activeSortBy}
            selectedFocusAreas={selectedFocusAreas}
            toggleFocusArea={toggleFocusArea}
          />
        </div>
        <div className={hubList.length === 0 ? 'discover-content empty' : 'discover-content'}>
          {!dataLoading && hubList.length > 0 && <div className="cards-background" />}

          <div className="discover-list">
            {hubList.length > 0 && (
              <>
                {hubList.map((hub, index) => (
                  <CardCommonHub
                    hub={hub}
                    key={index}
                    viewType={EViewTypeCardCommonHub.GRID}
                    styleType={EStyleTypeCardCommonHub.DISCOVER}
                    loading={dataLoading}
                    getIsFollowing={true}
                  />
                ))}

                {hubList.length % 3 !== 0 && generateEmptyCards(3 - (hubList.length % 3), 'hub')}

                {lastResponseCount === 6 && (
                  <div className="load-section">
                    <Button
                      text="Load More"
                      buttonClass="explore-button"
                      type="button"
                      onClick={async () => {
                        await getHubs(true);
                      }}
                    />
                  </div>
                )}
              </>
            )}

            {dataLoading && (
              <React.Fragment>
                <CardCommonHubSkeleton
                  styleType={EStyleTypeCardCommonHub.DISCOVER}
                  viewType={EViewTypeCardCommonHub.GRID}
                />
                <CardCommonHubSkeleton
                  styleType={EStyleTypeCardCommonHub.DISCOVER}
                  viewType={EViewTypeCardCommonHub.GRID}
                />
                <CardCommonHubSkeleton
                  styleType={EStyleTypeCardCommonHub.DISCOVER}
                  viewType={EViewTypeCardCommonHub.GRID}
                />
              </React.Fragment>
            )}
          </div>

          {!dataLoading && hubList.length === 0 && (
            <div className="discover-list">{generateEmptyCards(3, 'hub')}</div>
          )}
        </div>
        {/*TODO: All discover shortcut items can go into shared components if their role gets more complicated*/}
        <div className="discover-shortcuts-list">
          <div className="discover-shortcut-item alt light">
            <div className="discover-shortcut">
              <div className="shortcut-text">
                <div className="shortcut-title">Create a Company</div>
                <div className="shortcut-description">
                  Create a company and start tracking how you give back to the community.
                </div>
                <div className="shortcut-link">
                  <Link
                    to={`${
                      isUserLoggedIn
                        ? '/onboarding/company/'
                        : '/register?redirect=onboarding/company/'
                    }`}
                  >
                    Create a Company
                  </Link>
                </div>
              </div>
              <div className="shortcut-image">
                <img src={CreateHubSectionImg} />
              </div>
            </div>
          </div>
          <div className="discover-shortcut-item dark">
            <div className="discover-shortcut">
              <div className="shortcut-image">
                <img src={RequestSectionImg} />
              </div>
              <div className="shortcut-text">
                <div className="shortcut-title">Request a Company for your Area</div>
                <div className="shortcut-description">
                  Kambeo helps organizations raise funds from their community.
                </div>
                <div className="shortcut-link">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://kambeo.io/contact-us/?utm_medium=email&utm_source=hs_email&utm_campaign=U[…]3473285971.12&__hssc=181257784.8.1653473285971&__hsfp=2677427101`}
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiscoverHubs;
