import React, { ReactNode, useState } from 'react';
import PopupMenu, { IPopupMenuItem } from '../../../PopupMenu/PopupMenu';
import './TableSideDrawer.scss';

interface ITableSideDrawerProps<TData> {
  title: string;
  showDrawer: boolean;
  onClose: () => void;
  children: ReactNode;
  headerContextMenuItems?: IPopupMenuItem[];

  /**
   * Additional style options for the table side drawer.
   *
   * Height is automatically 100% of the table height.
   */
  styleOptions?: {
    /** Defaults to 350px */
    width?: number;
  };
}

function TableSideDrawer<TData>(props: ITableSideDrawerProps<TData>) {
  const { title, showDrawer, onClose, children } = props;

  const headerContextMenuItems = props.headerContextMenuItems || [];
  const [showHeaderContextMenu, setShowHeaderContextMenu] = useState<boolean>(
    headerContextMenuItems.length > 0,
  );

  function toggleShowHeaderContextMenu(shouldShow?: boolean) {
    setShowHeaderContextMenu(shouldShow != null ? shouldShow : !showHeaderContextMenu);
  }

  function handleCloseTableSideDrawer() {
    props.onClose();
  }

  return (
    <div className={`TableSideDrawer ${showDrawer ? 'show-drawer' : ''}`}>
      <div className="header-container">
        <span className="title">{title}</span>
        <div className="action-container">
          <PopupMenu
            showMenuConfig={{
              position: {
                type: 'bottom',
                alternate: 'start',
              },
              showMenu: showHeaderContextMenu,
              setShowMenu: setShowHeaderContextMenu,
            }}
            menuItems={headerContextMenuItems}
            className="action-menu-target"
            onClick={() => toggleShowHeaderContextMenu(true)}
          >
            {showHeaderContextMenu && <i className={`fa fa-ellipsis-v`} />}
          </PopupMenu>
          <i
            className={`far fa-times close-table-drawer`}
            onClick={handleCloseTableSideDrawer}
          />
        </div>
      </div>
      <div className="content-container">{children}</div>
    </div>
  );
}

export default TableSideDrawer;
