import { Constants } from '@gigit/constants';
import moment from 'moment';
import React, { FC } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { defaultCurrency, formatCardIcon, formatCurrency, typeHelpers } from '../../helpers';
import useToastDispatcher from '../../hooks/useToaster';
import { IOwnerObject } from '../../interfaces';
import { billingRequestActions, userRequestActions } from '../../requestActions';
import { userSelectors } from '../../selectors/user';
import { IAppState } from '../../store';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import './PlanManagement.scss';
import PurplePlusIcon from '../../assets/purple-plus-icon.png';
import { Stripe, loadStripe, CheckoutLocale } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { IPayment, IPaymentMethod } from '@gigit/interfaces';
import { Config } from '@gigit/config';
import Loader from '../Loader/Loader';
import PaymentMethodsForm from '../PaymentMethods/PaymentMethodsForm/PaymentMethodsForm';
import { Prompt } from '../Prompt/Prompt';
interface IProps {
  owner: IOwnerObject;
}

interface ISubscriptionMetric {
  title: string;
  metric: string;
}

interface ISubscription {
  type: string;
  title: string;
  descriptions: string[];
  isCurrentSubscription: boolean;
  info?: string;
  /** Formatted price */
  price: string;
  metrics?: Array<ISubscriptionMetric>;
  disclaimer?: string;
  subscriptionHighlights: string[];
  callToAction?: {
    text: string;
    onClick: () => void;
    buttonType: 'primary' | 'tertiary' | 'secondary';
  };
}

//TODO: Add concierge plan when BE is implemented for it.
//TODO: Add support for google and apple pay
/** This component manages Kambeo subscriptions */
const PlanManagement: FC<IProps> = (props) => {
  //Modal state
  const [showPurplePlusModal, setShowPurplePlusModal] = useState<boolean>(false);
  const [showPurplePlusSuccessModal, setShowPurplePlusSuccessModal] = useState<boolean>(false);

  const [showCancelPurplePlusModal, setShowCancelPurplePlusModal] = useState<boolean>(false);

  const [userPaymentMethods, setUserPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [isUserPaymentMethodsLoading, setIsUserPaymentMethodsLoading] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentMethod | null>(null);
  const [showNewPaymentMethodOverlay, setShowNewPaymentMethodOverlay] = useState<boolean>(false);

  //Payment state
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [stripePromiseLoaded, setStripePromiseLoaded] = useState<boolean>(false);

  //UI State
  const [subscriptionCards, setSubscriptionCards] = useState<ISubscription[]>([]);
  const [isSubscriptionsLoading, setIsSubscriptionsLoading] = useState(false);

  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const group = typeHelpers.getGroupFromOwner(props.owner);
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  useEffect(() => {
    getUserPaymentMethods();
  }, [showPurplePlusModal, showNewPaymentMethodOverlay]);

  useEffect(() => {
    getSubscriptionCardState();
    setupStripeInstance();
  }, [group?.id]);

  const setupStripeInstance = async () => {
    try {
      const stripePromise = await loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY, {
        locale: locale as CheckoutLocale,
      });

      setStripePromise(stripePromise);
      setStripePromiseLoaded(true);
    } catch (error) {
      dispatchToastError(error, 'Error Processing Stripe Instance');
    }
  };

  const getActivePurplePlusSubscription = async () => {
    if (group?.id) {
      const activePurplePlusSubscription = await billingRequestActions.getSubscriptionsForGroup(
        group.id,
      );
      return activePurplePlusSubscription?.features?.find(
        (feature) => feature.feature_code === Constants.billing.subscriptions.purple_plus.code,
      );
    }
  };

  const getFormattedAverageMonthlyFees = () => {
    const groupCreationDate = moment(group?.created_at, 'DD/MM/YYYY').startOf('month');
    const monthDifferenceCount = Math.abs(groupCreationDate.diff(moment(), 'months'));
    const applicationFeesPayed = group?.tracking_amounts?.application_fees_payed || 0;
    const monthlyAverage =
      monthDifferenceCount > 0 && applicationFeesPayed > 0
        ? applicationFeesPayed / monthDifferenceCount
        : 0;

    return formatCurrency(monthlyAverage, group?.account?.currency || defaultCurrency, locale);
  };

  //For now we're just getting purple plus until more plans exist
  const getPurplePlusSubscription = async () => {
    const availableSubscriptions = await billingRequestActions.getAvailableGroupSubscriptions(
      Constants.object_type.group,
    );

    //Just returns purple_plus for now since that's all we have
    return availableSubscriptions.find(
      (sub) => sub.product_code === Constants.billing.subscriptions.purple_plus.code,
    );
  };

  const getSubscriptionCardState = async () => {
    setIsSubscriptionsLoading(true);
    const currency = group?.account?.currency || defaultCurrency;
    const purplePlusSubscription = await getPurplePlusSubscription();

    const activePurplePlusSubscription = await getActivePurplePlusSubscription();
    const subscriptionCardState: ISubscription[] = [
      {
        type: 'starter',
        title: 'Starter',
        descriptions: ['Starter Plan. No Hidden Fees. Unlimited Opportunities.'],
        isCurrentSubscription: activePurplePlusSubscription == null,
        info: 'Transaction fees apply. See your transaction fees till date below.',
        metrics: [
          {
            title: 'Total Transaction Fees',
            metric: formatCurrency(
              group?.tracking_amounts?.application_fees_payed,
              currency,
              locale,
            ),
          },
          {
            title: 'Monthly Average',
            metric: getFormattedAverageMonthlyFees(),
          },
        ],
        subscriptionHighlights: [
          'Built-in CRM',
          'Donation, Event and Volunteer Management',
          'Online Auctions',
          'Sell Merchandise Online',
          'MailChimp, Stripe and Twitch Integrations',
          'Volunteer Postings',
          'Access to Kambeo Knowledge Base',
          'Basic Email Support (within 72 business hours of submission)',
        ],
        price: 'Free',
        disclaimer: '*Enhanced support available at $75 per 30 minute session',
      },
      {
        type: Constants.billing.subscriptions.purple_plus.code,
        title: 'Purple +',
        descriptions: ['Upgrade to Purple Plus Plan for no transaction fees and enhanced support'],
        isCurrentSubscription: activePurplePlusSubscription != null,
        info: 'Monthly. Billed Annually',
        subscriptionHighlights: [
          'Everything in the Starter Package',
          'Zero Platform Transaction Fees',
          'Highlight in the Kambeo Newsletter',
          '3 one-hour virtual meetings with a Kambeo expert ($450 value)',
          'Enhanced Email Support (within 24 business hours of submission)',
        ],
        price:
          formatCurrency(purplePlusSubscription?.monthly_price, currency) +
          ' / Month (Billed Annually)',
        disclaimer: '*Per Annum',
        callToAction:
          activePurplePlusSubscription != null
            ? {
                text: 'Cancel',
                buttonType: 'secondary',
                onClick: () => setShowCancelPurplePlusModal(true),
              }
            : {
                text: 'Upgrade',
                buttonType: 'primary',
                onClick: () => setShowPurplePlusModal(true),
              },
      },
    ];

    setIsSubscriptionsLoading(false);
    setSubscriptionCards(subscriptionCardState);
  };

  const renderCard = (cardProps: ISubscription) => {
    const {
      title,
      descriptions,
      disclaimer,
      info,
      isCurrentSubscription,
      metrics,
      price,
      callToAction,
      subscriptionHighlights,
      type,
    } = cardProps;

    const renderCardDescriptions = (descriptions: string[]) => {
      return descriptions.map((description, idx) => (
        <div
          key={idx}
          className="main-description"
        >
          {description}
        </div>
      ));
    };

    const renderInfoContainer = (info?: string) => {
      if (info != null) {
        return (
          <div className="info-container">
            <i className="icon far fa-info-circle" />
            <span className="description">
              Transaction fees apply. See your transaction fees till date below.
            </span>
          </div>
        );
      }
    };

    const renderMetricsContainer = (metrics?: ISubscriptionMetric[]) => {
      if (metrics != null && metrics.length > 0) {
        return (
          <div className="metric-container">
            {metrics.map((item, idx) => (
              <div
                className="metric"
                key={idx}
              >
                <span className="title">{item.title}</span>
                <span className="description">{item.metric}</span>
              </div>
            ))}
          </div>
        );
      }
    };

    const renderDisclaimer = (disclaimer?: string) => {
      if (disclaimer) {
        return <span className="disclaimer">{disclaimer}</span>;
      }
    };

    const renderCallToActionButton = () => {
      if (callToAction != null) {
        const { text, buttonType, onClick } = callToAction;

        return (
          <Button
            className="cta-button"
            text={text}
            buttonType={buttonType}
            onClick={onClick}
          />
        );
      }
    };

    return (
      <div
        className="card"
        key={type}
      >
        <div className={`card-header ${type}`}>
          <span className="title">{title}</span>
          {isCurrentSubscription && <span className="current-plan">Current Plan</span>}
          <span className="price">{price}</span>
        </div>
        <div className="card-content">
          <div className="container left">
            {renderCardDescriptions(descriptions)}
            {renderInfoContainer(info)}
            {renderMetricsContainer(metrics)}
            <div className="bottom-container">
              {renderCallToActionButton()}
              {renderDisclaimer(disclaimer)}
            </div>
          </div>

          <div className="container right">
            {renderSubscriptionHighlights(subscriptionHighlights)}
          </div>
        </div>
      </div>
    );
  };

  const renderSubscriptionHighlights = (highlights: string[]) => {
    return highlights.map((highlight, idx) => (
      <div
        className="plan-highlight"
        key={idx}
      >
        <i className="icon fal fa-check" />
        <span className="description">{highlight}</span>
      </div>
    ));
  };

  const getUserPaymentMethods = async () => {
    try {
      setIsUserPaymentMethodsLoading(true);
      const userPaymentMethods = await userRequestActions.getPaymentMethods();
      setUserPaymentMethods(userPaymentMethods);
    } catch (error) {
      dispatchToastError(error, 'Error getting payment methods');
    } finally {
      setIsUserPaymentMethodsLoading(false);
    }
  };

  const renderPaymentForm = (plan: ISubscription) => {
    const handleSubmitPayment = async () => {
      setIsPaymentLoading(true);

      try {
        const paymentPayload: IPayment = {
          payment_method: 'card',
          delegate_group_id: group?.id,
          payment_customer_id: selectedPaymentMethod?.customer_id,
          payment_method_country: selectedPaymentMethod?.card?.country,
          payment_method_id: selectedPaymentMethod?.payment_method_id || selectedPaymentMethod?.id,
        };

        await billingRequestActions.createSubscriptionForGroup(
          group?.id!,
          plan.type,
          paymentPayload,
        );

        setIsPaymentLoading(false);
        setShowPurplePlusSuccessModal(true);
      } catch (error) {
        dispatchToastError(error, 'There was an issue processing your payment.');
      } finally {
        setIsPaymentLoading(false);
      }
    };

    const handleSelectPaymentMethod = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      paymentMethod: IPaymentMethod,
    ) => {
      setSelectedPaymentMethod(paymentMethod);
    };

    const handleNewPaymentMethodClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setSelectedPaymentMethod(null);
      setShowNewPaymentMethodOverlay(true);
    };

    const renderUserPaymentMethodLoadingState = () => {
      return <Loader loading={isUserPaymentMethodsLoading} />;
    };

    const renderNewPaymentMethodOverlay = () => {
      if (stripePromise != null) {
        return (
          <div className="new-payment-method-overlay">
            <div className="overlay-header">
              <i
                className="go-back fas fa-chevron-left"
                onClick={() => setShowNewPaymentMethodOverlay(false)}
              />
              <h3 className="title">New Payment Method</h3>
            </div>
            <div className="overlay-content">
              {stripePromise !== null && (
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({ elements, stripe }) => (
                      <PaymentMethodsForm
                        stripe={stripe}
                        elements={elements}
                        // onAddPaymentMethodComplete={() => setShowNewPaymentMethodOverlay(false)}
                        onClose={() => setShowNewPaymentMethodOverlay(false)}
                        useMobileFormat={true}
                      />
                    )}
                  </ElementsConsumer>
                </Elements>
              )}
            </div>
            <div className="overlay-footer"></div>
          </div>
        );
      }
    };

    return (
      <div
        className="payment-form"
        // onSubmit={handleFormSubmit}
      >
        <div className="user-payment-methods-container">
          <h3 className="title">Select Payment Method</h3>
          <div
            onClick={handleNewPaymentMethodClick}
            className="user-payment-method new-payment-method"
          >
            <span className="label">New Payment Method</span>
            <i className="far fa-plus" />
          </div>

          {showNewPaymentMethodOverlay && renderNewPaymentMethodOverlay()}
          {isUserPaymentMethodsLoading && renderUserPaymentMethodLoadingState()}
          {!isUserPaymentMethodsLoading &&
            userPaymentMethods.map((paymentMethod, idx) => {
              const isSelected =
                selectedPaymentMethod?.payment_method_id === paymentMethod.payment_method_id;

              return (
                <div
                  key={paymentMethod.payment_method_id}
                  className="user-payment-method"
                  onClick={(e) => handleSelectPaymentMethod(e, paymentMethod)}
                >
                  <i className={formatCardIcon(paymentMethod.card?.brand ?? '')} />
                  <span className="card-info">
                    ending in
                    <span className="last-4">
                      <var data-var="credit_card_last_4_digits">{paymentMethod.card?.last4}</var>
                    </span>
                  </span>
                  <i className={isSelected ? 'fas fa-circle' : 'far fa-circle'} />
                </div>
              );
            })}
        </div>

        <div className="bottom-container">
          <Button
            className="cta-button"
            text={'Cancel'}
            onClick={() => setShowPurplePlusModal(false)}
            buttonType="secondary"
          />
          <Button
            className="cta-button"
            text={`Pay ${plan?.price}`}
            loading={isPaymentLoading}
            onClick={handleSubmitPayment}
            buttonType="primary"
          />
        </div>
      </div>
    );
  };

  const renderPaymentSuccessModal = () => {
    const getIsPurplePlusSuccessfulClass = showPurplePlusSuccessModal ? 'show' : '';
    return (
      <Modal
        class="payment-success-modal"
        show={showPurplePlusSuccessModal}
        onClose={() => setShowPurplePlusSuccessModal(false)}
      >
        <div className="payment-success-modal-content">
          <div className={`purple-plus-banner ${getIsPurplePlusSuccessfulClass}`}>
            <h1 className="title">Purple Plus</h1>
            {/* Renders fancy background plus icons */}
            <i className={`fal fa-plus`} />
            <i className={`fal fa-plus`} />
            <i className={`fal fa-plus`} />
            <i className={`fal fa-plus`} />
            <i className={`fal fa-plus`} />
            <i className={`fal fa-plus`} />
          </div>
          <div className="success-body">
            <h3 className="title">Successfully Subscribed</h3>
            <p className="description">
              Your plan is now active. View or edit your plan in Payment & Subscriptions {'>'}{' '}
              Subscriptions.
            </p>
          </div>
          <div className="actions-container">
            <Button
              className="cta-button"
              text="Return to Plans"
              onClick={() => {
                setShowPurplePlusModal(false);
                setShowPurplePlusSuccessModal(false);
              }}
              buttonType="primary"
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderSubscribeToPurplePlusModal = () => {
    const purplePlusPlan = subscriptionCards.find(
      (sub) => sub.type === Constants.billing.subscriptions.purple_plus.code,
    );

    return (
      <Modal
        class="upgrade-plan-modal"
        show={showPurplePlusModal}
        onClose={() => setShowPurplePlusModal(false)}
      >
        <div className="upgrade-plan-modal-content">
          <div className="left-column">
            <div className="header-container">
              <img src={PurplePlusIcon} />
              <div className="title-container">
                <div className="title-container">
                  <span className="title">Purple + Plan</span>
                  <span className="description">Auto Renewed</span>
                </div>
              </div>
            </div>
            <div className="body">
              <div className="plan-highlight-list">
                {renderSubscriptionHighlights(purplePlusPlan?.subscriptionHighlights || [])}
              </div>
            </div>
            <div className="bottom-container">
              <div className="div total-container">
                <span>Total</span>
                <span>{purplePlusPlan?.price}</span>
              </div>
            </div>
          </div>
          <div className="right-column">{purplePlusPlan && renderPaymentForm(purplePlusPlan)}</div>
        </div>
      </Modal>
    );
  };

  const renderCancelPurplePlusModal = () => {
    const cancelPurplePlusPlan = async () => {
      await billingRequestActions.cancelSubscriptionForGroup(
        group?.id!,
        Constants.billing.subscriptions.purple_plus.code,
      );
      setShowCancelPurplePlusModal(false);
    };

    return (
      <Prompt
        show={showCancelPurplePlusModal}
        title="Are you sure?"
        message="Are you sure you want to cancel your subscription?"
        yesMessage="Cancel"
        yesStyle="delete"
        cancelMessage="Back"
        onYes={() => cancelPurplePlusPlan()}
        onClose={() => setShowCancelPurplePlusModal(false)}
      />
    );
  };

  return (
    <div className="PlanManagement section-wrap">
      <div className="section-title">
        <div className="forms-title">Plans</div>
      </div>
      <div className="section-inner">
        <div className="card-container">
          {subscriptionCards.map((cardProps) => renderCard(cardProps))}
          {isSubscriptionsLoading && <Loader loading={isSubscriptionsLoading} />}
        </div>
      </div>
      {renderSubscribeToPurplePlusModal()}
      {renderPaymentSuccessModal()}
      {renderCancelPurplePlusModal()}
    </div>
  );
};

export default PlanManagement;
