import { IApplicationHubSummaryFE, IHub, IHubVolunteerSummary } from '@gigit/interfaces';
import React, { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { uiConstants } from '../../../../constants';
import { errorHelpers, formatNumberCompactNotation, typeHelpers } from '../../../../helpers';
import { useLocale } from '../../../../hooks';
import useToastDispatcher from '../../../../hooks/useToaster';
import { hubRequestActions, userRequestActions } from '../../../../requestActions';
import LogVolunteerHours from '../../../LogVolunteerHours/LogVolunteerHours';
import Modal from '../../../Modal/Modal';
import Portrait from '../../../Portrait/Portrait';
import Table, { ITableProps } from '../../../shared/Table/Table';
import './HubManagementVolunteers.scss';
import { ITab, Tabs } from '../shared/Tabs/Tabs';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../selectors/user';
import { IAppState } from '../../../../store';
import DefaultLink from '../../../shared/Link/Link';

interface IProps {
  hub?: IHub;
}

const tabs: ITab[] = [
  { label: 'Volunteers', value: 'volunteers' },
  { label: 'Volunteer Engagement', value: 'volunteer-engagement' },
];

const HubManagementVolunteers: FC<IProps> = (props) => {
  const { hub } = props;
  const { dispatchToastError } = useToastDispatcher();
  const history = useHistory();
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));

  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const [showReportHoursModal, setShowReportHoursModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('volunteers');

  const volunteersTableProps: ITableProps<IHubVolunteerSummary> = {
    columns: [
      {
        id: 'first_name',
        Header: 'Volunteer Name',
        accessor: ({ user }) => user?.first_name,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ user }) => (
          <Link
            to={`/${uiConstants.ownerType.user}/${user?.handle}`}
            className="user-volunteer-name"
          >
            <Portrait
              currentImage={user?.profile_image_url}
              size={30}
            />
            {user?.first_name} {user?.last_name}
          </Link>
        ),
      },
      {
        id: 'approved_hours_ytd',
        Header: 'YTD Volunteer Hours',
        accessor: ({ approved_hours_ytd }) => approved_hours_ytd?.toString(),
        sortable: true,
        Cell: ({ approved_hours_ytd }) => formatNumberCompactNotation(approved_hours_ytd, locale),
      },
      {
        id: 'total_hours_ytd',
        Header: 'Total Volunteer Hours',
        accessor: ({ total_hours_ytd }) => total_hours_ytd?.toString(),
        sortable: true,
        Cell: ({ total_hours_ytd }) => formatNumberCompactNotation(total_hours_ytd, locale),
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions: true,
      enableRowButtonActions: true,
      tableActions: [
        {
          type: 'HEADER',
          label: 'Create Opportunity',
          onClick: goToCreateFlow,
          buttonType: 'dark',
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Message',
          icon: 'fa fa-pencil',
          onClick: (_, item) => goToMessage(item),
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Log Hours',
          icon: 'fal fa-trash',
          onClick: (_, item) => openLogHours(item),
        },
      ],
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        let data: IHubVolunteerSummary[] = [];
        try {
          typeHelpers.assertNotNullOrUndefined(hub?.id);
          data = await hubRequestActions.getVolunteers(hub.id, queryParams);
        } catch (error) {
          dispatchToastError(error, 'Get Company Volunteers');
        }

        return data;
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any volunteers",
    },
    filterOptions: {
      enableTableSearch: true,
      enableColumnSorting: true,
      enableFilterDrawer: true,
      filterDrawerOptions: [
        {
          type: 'textField',
          fieldId: 'approved_hours_ytd',
          options: { label: 'YTD Volunteer Hours' },
        },
        {
          type: 'textField',
          fieldId: 'total_hours_ytd',
          options: { label: 'Total Volunteer Hours' },
        },
      ],
    },
  };

  const volunteerEngagementTableProps: ITableProps<IApplicationHubSummaryFE> = {
    columns: [
      {
        id: 'user.display_name',
        Header: 'Employee Name',
        accessor: ({ user }) => user?.display_name,
        sortable: true,
        Cell: ({ user }) => (
          <DefaultLink
            to={`/user/${user?.handle}`}
            className="item-name-col"
            notranslate="yes"
          >
            <Portrait
              size={40}
              className="image-container"
              currentImage={user?.profile_image_url}
            />
            <span notranslate="yes">{user?.display_name}</span>
          </DefaultLink>
        ),
        notranslate: 'yes',
      },
      {
        id: 'gig.title',
        Header: 'Volunteer Opportunity',
        accessor: ({ gig }) => gig?.title,
        sortable: true,
        Cell: ({ gig }) => (
          <Link
            to={`/${uiConstants.ownerType.gig}/${gig?.handle}`}
            className="user-volunteer-name"
          >
            {/* <Portrait
              currentImage={""}
              size={30}
            /> */}
            {gig?.title}
          </Link>
        ),
        notranslate: 'yes',
      },
      {
        id: 'group.title',
        Header: 'Cause/Charity Name',
        accessor: ({ group }) => group?.title,
        sortable: true,
        Cell: ({ group }) => (
          <Link
            to={`/${uiConstants.ownerType.group}/${group?.handle}`}
            className="user-volunteer-name"
          >
            {/* <Portrait
              currentImage={""}
              size={30}
            /> */}
            {group?.title}
          </Link>
        ),
        notranslate: 'yes',
      },
      {
        id: 'custom_cause_name',
        Header: 'Custom Cause',
        accessor: ({ custom_cause_name }) => custom_cause_name,
        Cell: ({ custom_cause_name }) => (
          <var data-var="custom_cause_name">{custom_cause_name}</var>
        ),
        sortable: true,
        notranslate: 'yes',
      },
      {
        id: 'logged_date',
        Header: 'Date Hours Logged',
        accessor: ({ logged_date }) => logged_date,
        Cell: ({ logged_date }) =>
          localizeHelpers.formatDate(logged_date ?? new Date(), LocaleDateFormats.LL, locale),
        sortable: true,
        notranslate: 'yes',
      },
      {
        id: 'hours',
        Header: 'Hours Logged',
        accessor: ({ hours }) => hours?.toString(),
        sortable: true,
        Cell: ({ hours }) => formatNumberCompactNotation(hours, locale),
        notranslate: 'yes',
      },
    ],
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        try {
          typeHelpers.assertNotNullOrUndefined(hub?.id);
          return await hubRequestActions.getMemberApplicationSummary(hub.id, queryParams);
        } catch (error) {
          dispatchToastError(error, 'Get Member Application Summary');
        }

        return [];
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any volunteers",
    },
    tableActionOptions: {
      tableActions: [
        {
          type: 'HEADER',
          icon: 'far fa-file-download',
          label: 'Export All',
          buttonType: 'dark',
          onClick: async () => {
            try {
              typeHelpers.assertNotNullOrUndefined(hub?.id);
              return await hubRequestActions.getMemberApplicationSummaryExport(hub.id);
            } catch (error) {
              dispatchToastError(error, 'Export All Member Application Summary');
            }

            return [];
          },
        },
      ],
    },
    filterOptions: {
      enableTableSearch: true,
      enableColumnSorting: true,
    },
  };

  const onTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  function goToCreateFlow() {
    try {
      typeHelpers.assertNotNullOrUndefined(hub?.id);
      history.push({
        pathname: `/onboarding/${uiConstants.ownerType.gig}/hubSelection`,
        search: `?hubId=${hub.id}&createdFor=company`,
      });
    } catch (error) {
      dispatchToastError(error, 'Create Gig Flow');
    }
  }

  async function goToMessage(item: IHubVolunteerSummary) {
    try {
      typeHelpers.assertNotNullOrUndefined(item?.user?.id, 'User ID');
      history.push(`/dashboard?tab=network&contact=${item.user.id}&networkTab=messages`);
    } catch (error) {
      dispatchToastError(error, 'User ID');
    }
  }

  function openLogHours(item: IHubVolunteerSummary) {
    setShowReportHoursModal(true);
  }

  return (
    <>
      <div
        className="HubManagementVolunteers"
        id="volunteers"
      >
        <div className="header-section">
          <h2>Volunteers</h2>
        </div>
        <div className="table-section">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={onTabChange}
          />
          {activeTab === 'volunteers' && (
            <Table
              {...volunteersTableProps}
              refreshTableIncrementor={refreshTableIncrementor}
            />
          )}
          {activeTab === 'volunteer-engagement' && (
            <Table
              {...volunteerEngagementTableProps}
              refreshTableIncrementor={refreshTableIncrementor}
            />
          )}
        </div>

        <Modal
          class="LogVolunteerHoursModal"
          show={showReportHoursModal}
          onClose={() => setShowReportHoursModal(false)}
        >
          <LogVolunteerHours onHoursSubmitted={() => setShowReportHoursModal(false)} />
        </Modal>
      </div>
    </>
  );
};

export default HubManagementVolunteers;
