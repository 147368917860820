import React, { useEffect, useState } from 'react';
import { combineClassNames } from '../../helpers';
import './QuickEditField.scss';

interface IProps {
  value: string;
  type: 'number' | 'text';
  name: string;
  onSave: (value: string) => void;
  editIcon?: string; // @default 'fa fa-edit'
  saveIcon?: string; // @default 'fa fa-save'
  min?: number;
  max?: number;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean; // @default false
  className?: string;
  step?: number;
}

/**
 * @desc
 * The component should be used in the place where is required preview and field edit, like in tables, it enables you to show default value and manage value after change
 * @default saveIcon = 'fa fa-save'
 * @default editIcon = 'fa fa-edit'
 * @default disabled = false
 */
function QuickEditField(props: IProps) {
  const [iconClass, setIconClass] = useState(props?.editIcon || 'fa fa-edit');
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const doesValueChanged = (value: string): boolean => {
    return props.value !== value;
  };

  return (
    <div className={combineClassNames('QuickEditFieldContainer', props?.className || '')}>
      <div className={'input-wrap'}>
        <i
          className={combineClassNames(
            'main-icon',
            iconClass,
            doesValueChanged(value) ? 'pointer' : '',
          )}
          onClick={() => {
            if (doesValueChanged(value)) {
              props.onSave(value);
              setIconClass(props?.editIcon || 'fa fa-edit');
            }
          }}
        />
        <input
          notranslate="yes"
          type={props.type}
          name={props.name}
          min={props?.min}
          max={props?.max}
          step={props?.step}
          minLength={props?.minLength}
          maxLength={props?.maxLength}
          value={value}
          disabled={props?.disabled || false}
          onChange={(e) => {
            setValue(e.target.value);
            setIconClass(
              doesValueChanged(e.target.value)
                ? props?.saveIcon || 'fa fa-save'
                : props?.editIcon || 'fa fa-edit',
            );
          }}
        />
      </div>
    </div>
  );
}

export default QuickEditField;
