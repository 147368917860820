import { Reducer } from 'redux';
import { MediaAlbumActions, MediaAlbumActionTypes } from '../actions/mediaAlbum';

export interface IMediaItem {
  uploaded: boolean;
  url: string;
}

export interface IMediaAlbumState {
  //parent: string,
  parentId: string;
  componentId: string;
  isCreated: boolean;
  totalItems: number;
  itemsLoaded: number;
  albumName: string;
  albumItems: IMediaItem[];
  isUploading: boolean;
}

export const initialMediaAlbumState: IMediaAlbumState = {
  //parent: "",
  parentId: '',
  componentId: '',
  totalItems: 0,
  itemsLoaded: 0,
  albumName: '',
  albumItems: [],
  isUploading: false,
  isCreated: false,
};

export const mediaAlbumReducer: Reducer<IMediaAlbumState, MediaAlbumActions> = (
  state = initialMediaAlbumState,
  action,
) => {
  switch (action.type) {
    case MediaAlbumActionTypes.UPDATE_MEDIA_ALBUM: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
