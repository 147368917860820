import { ILoggedVolunteerHoursCreateParamsFE } from '@gigit/interfaces';
import React from 'react';
import Button from '../Button/Button';
import './HoursTable.scss';
export interface IHoursTableProps {
  hours: ILoggedVolunteerHoursCreateParamsFE[];
  onRemove: (index: number) => void;
}
export default function HoursTable(props: IHoursTableProps) {
  const headers = ['Start Date', 'Hours', 'Minutes', ''];
  /** TODO: Tried to use our new table but it requires pagination fields and this data is F.E. only
   * we should probably extend the tables functionality to support this use case, but for now I'll make something conatined to this component
   */
  return (
    <div className="HoursTable">
      {headers.map((header) => {
        return (
          <div
            key={header}
            className="header"
          >
            {header}
          </div>
        );
      })}
      {props.hours.map((hour, index) => {
        return (
          <React.Fragment key={`${hour.group_id}-index`}>
            <div className="hour-item">{hour.start_date}</div>
            <div className="hour-item">{hour.hours}</div>
            <div className="hour-item">{hour.minutes}</div>
            <div className="hour-item end">
              <Button
                text="Remove"
                onClick={() => props.onRemove(index)}
              />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
