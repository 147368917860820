import React, { useState, useEffect, FC } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import EmptyImg from '../../assets/heart-briefcase-logo.svg';
import CreateVolunteerProfile from '../../assets/discover-volunteers-create.png';
import './DiscoverStyles.scss';
import Button from '../../components/Button/Button';
import { uiConstants } from '../../constants';
import {
  combineClassNames,
  errorHelpers,
  formatQuery,
  generateEmptyCards,
  IQueryParams,
  routes,
  setSEOMetatags,
  swapRouteParams,
  toastError,
} from '../../helpers';
import { IGeolocationState } from '../../reducers/geolocation';
import { IAppState } from '../../store';
import axios from 'axios';
import { createToast } from '../../actions/toaster';
import { updateGeolocation } from '../../actions/geolocation';
import { IHorizontalScrollerRenderProps } from '../../components/shared/HorizontalScroller/HorizontalScroller';
import LandingDiscoverHeader from '../../components/LandingDiscoverHeader/LandingDiscoverHeader';
import { IUserState } from '../../reducers/user';
import { localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import DiscoverPagesFilter from './DiscoverPagesFilter/DiscoverPagesFilter';
import { IPopupMenuItem } from '../../components/shared/PopupMenu/PopupMenu';
import CardLoader from '../../components/shared/CardLoader/CardLoader';
import { ECardLoaderStyleType } from '../../components/shared/CardLoader/CardLoaderItem/CardLoaderItem';
import VolunteerCard, {
  EStyleTypeVolunteerCard,
  EViewTypeVolunteerCard,
} from '../../components/VolunteerCard/VolunteerCard';
import { IDiscoverItem, IDiscoverFilters, IApplication } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { Config } from '@gigit/config';
import { userRequestActions } from '../../requestActions/user';

interface IProps extends RouteComponentProps {
  userState: IUserState;
}

interface FilterItem {
  icon: string;
  title: string;
  onFilter: () => void;
}

const DiscoverVolunteers: FC<IProps> = (props: IProps) => {
  const geolocationState: IGeolocationState = useSelector(
    (state: IAppState) => state.geolocationState,
  );
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );
  const dispatch = useDispatch();
  const loadDataLimit = 6;
  const [gigsList, setGigsList] = useState<IDiscoverItem[]>([]);
  const [skip, setSkip] = useState<number>(0);
  const [activeFilter, setActiveFilter] = useState<number>(0);
  const [lastResponseCount, setLastResponseCount] = useState<number>(6);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [activeSortBy, setActiveSortBy] = useState<{ label: string; value: string }>({
    label: 'Newest',
    value: uiConstants.discoverFilters.recent,
  });
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<string[]>([]);
  const [userApplications, setUserApplications] = useState<IApplication[]>();

  const sortByItems: IPopupMenuItem[] = [
    {
      id: uiConstants.discoverFilters.recent,
      label: 'Newest',
      icon: 'far fa-clock',
      isSelected: activeSortBy?.value === uiConstants.discoverFilters.recent,
      onClick: async () => {
        setActiveSortBy({ label: 'Newest', value: uiConstants.discoverFilters.recent });
      },
    },
    {
      id: uiConstants.discoverFilters.trending,
      label: 'Trending',
      icon: 'far fa-fire',
      isSelected: activeSortBy?.value === uiConstants.discoverFilters.trending,
      onClick: async () => {
        setActiveSortBy({ label: 'Trending', value: uiConstants.discoverFilters.trending });
      },
    },
    {
      id: uiConstants.discoverFilters.happeningSoon,
      label: 'Starting Soon',
      icon: 'far fa-clock',
      isSelected: activeSortBy?.value === uiConstants.discoverFilters.happeningSoon,
      onClick: async () => {
        setActiveSortBy({
          label: 'Starting Soon',
          value: uiConstants.discoverFilters.happeningSoon,
        });
      },
    },
  ];

  async function getUserVolunteerApplications() {
    if (props.userState.user.id) {
      let params = {
        sortDir: 'desc' as const,
        sortBy: 'created_at',
        type: 'all',
        states: 'all',
      };

      try {
        const results = await userRequestActions.getUserApplications(params);
        setUserApplications(results);
      } catch (error) {
        const errObj = errorHelpers.getErrorObject(error);
        toastError(errObj.translatedMessage, 'Get User Volunteer Applications');
      }
    }
  }

  useEffect(() => {
    setSEOMetatags({
      title: localizeHelpers.translate('Discover Volunteering | Kambeo'),
      urlPath: 'discover/volunteers',
    });
    dispatch(updateGeolocation());
  }, []);

  useEffect(() => {
    getGigs();
  }, [selectedFocusAreas, activeSortBy.value]);

  useEffect(() => {
    if (isUserLoggedIn) {
      getUserVolunteerApplications();
    } else {
      setUserApplications(undefined);
    }
  }, [isUserLoggedIn]);

  const renderPageItem = (
    filter: FilterItem,
    index: number,
    scrollerProps: IHorizontalScrollerRenderProps,
  ) => {
    const onClick = () => {
      scrollerProps.moveItemIntoFocus(index);
      filter.onFilter();
      setActiveFilter(index);
    };

    return (
      <li
        key={index}
        className={combineClassNames(
          `page-item ${filter.icon}`,
          activeFilter === index ? 'fas selected' : 'far',
        )}
        onClick={onClick}
      >
        {filter.title}
      </li>
    );
  };

  let getGigs = async (loadMore = false) => {
    setDataLoading(true);
    let filterData: any = {
      start_date: '',
    };

    let query = {} as IQueryParams;

    switch (activeSortBy.value) {
      case uiConstants.discoverFilters.happeningSoon:
        filterData.start_date = new Date(Date.now());
        query.sort = [{ id: 'start_date', order: 'asc' }];
        break;
      case uiConstants.discoverFilters.trending:
        filterData.trending = true;
        break;
      case uiConstants.discoverFilters.recent:
        query.sort = [{ id: 'created_at', order: 'desc' }];
        break;
    }
    const defaultCoords = uiConstants.defaultCoords;

    let location = {};
    let userLocation: string = '';

    if (props.userState && props.userState.locations[0]) {
      userLocation =
        props.userState.locations[0].city +
        ' ' +
        props.userState.locations[0].state +
        ', ' +
        props.userState.locations[0].country;
    }

    if (isUserLoggedIn && userLocation !== '') {
      try {
        await fetch(
          'https://maps.googleapis.com/maps/api/geocode/json?address=' +
            userLocation +
            '&key=' +
            Config.web.REACT_APP_GOOGLE_API_KEY,
        )
          .then((response) => response.json())
          .then((data) => {
            location = {
              coordinates: [
                data.results[0].geometry.location.lng,
                data.results[0].geometry.location.lat,
              ],
              max_distance: 100,
              distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
            };
          });
      } catch (error) {
        const errObj = errorHelpers.getErrorObject(error);
        toastError(errObj.translatedMessage, 'Google Maps');
      }
    } else if (geolocationState) {
      location = {
        coordinates: [geolocationState.lng, geolocationState.lat],
        max_distance: 100,
        distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
      };
    } else {
      location = {
        coordinates: [defaultCoords.lng, defaultCoords.lat],
        max_distance: 100,
        distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
      };
    }

    try {
      let _payload = {
        location,
        filter: filterData,
        common: {
          causes: selectedFocusAreas.length ? selectedFocusAreas : undefined,
        },
      } as IDiscoverFilters;

      let route = swapRouteParams(routes.DISCOVER_VOLUNTEER_OPPORTUNITIES, {
        limit: loadDataLimit,
        skip: loadMore ? skip + loadDataLimit : skip,
      });

      setSkip(loadMore ? skip + loadDataLimit : skip);

      if (query) {
        route += `&${formatQuery(query).toString()}`;
      }

      let response = await axios.post(route, _payload);

      if (loadMore) {
        setGigsList([...gigsList, ...response.data]);
      } else {
        setGigsList(response.data);
      }

      setLastResponseCount(response.data.length);
      setDataLoading(false);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Discover Volunteer Opportunities');
      dispatch(createToast(toast));
    }
  };

  async function toggleFocusArea(value: string) {
    if (selectedFocusAreas.indexOf(value) === -1) {
      setSelectedFocusAreas([...selectedFocusAreas, value]);
    } else {
      setSelectedFocusAreas(selectedFocusAreas.filter((fa) => fa !== value));
    }
  }

  // NOTE: Set the Discover Events page to landing route, to be revisited when new landing page is implemented

  return (
    <React.Fragment>
      <div className="Discover">
        <LandingDiscoverHeader
          pathname={props.location.pathname}
          isUserLoggedIn={isUserLoggedIn}
        />
        <div className="discover-title">
          <div className="title">
            Discover Volunteer Opportunities in{' '}
            <u>
              {props.userState.locations && props.userState.locations[0] && isUserLoggedIn
                ? props.userState.locations[0].city +
                  ', ' +
                  props.userState.locations[0].state?.substring(0, 2).toUpperCase()
                : geolocationState.locality}
            </u>
          </div>
          <div className="mob-title">
            Discover Volunteering in{' '}
            <u>
              {props.userState.locations && props.userState.locations[0] && isUserLoggedIn
                ? props.userState.locations[0].city +
                  ', ' +
                  props.userState.locations[0].state?.substring(0, 2).toUpperCase()
                : geolocationState.locality}
            </u>
          </div>
        </div>
        <div className="discover-filters">
          <DiscoverPagesFilter
            sortByItems={sortByItems}
            activeSortBy={activeSortBy}
            selectedFocusAreas={selectedFocusAreas}
            toggleFocusArea={toggleFocusArea}
          />
        </div>
        <div className={gigsList.length == 0 ? 'discover-content empty' : 'discover-content'}>
          {!dataLoading && gigsList.length > 0 && <div className="cards-background" />}

          {
            <div className="discover-list">
              {!dataLoading && gigsList.length > 0 && (
                <>
                  {gigsList.map((gig, index) => {
                    return (
                      <VolunteerCard
                        key={index}
                        viewType={EViewTypeVolunteerCard.GRID}
                        styleType={EStyleTypeVolunteerCard.DISCOVER}
                        gigBasic={{
                          id: gig.id,
                          profile_image_url: gig.profile_image_url,
                          title: gig.title,
                          description: gig.description,
                          locations: gig.locations,
                          start_date: gig.start_date?.toString() || '',
                          owner_title: gig.owner_title || '',
                          owner_type: gig.owner_type || '',
                          owner_handle: gig.owner_handle || '',
                          handle: gig.handle,
                          is_volunteer_match: !!gig?.external_id?.VolunteerMatch,
                          focus_areas: gig.focus_areas,
                          accepting_external_application: gig.accepting_external_application,
                          external_application_url: gig.external_application_url,
                        }}
                        showSocialButtons={true}
                        userApplications={userApplications}
                      />
                    );
                  })}

                  {gigsList.length % 3 !== 0 &&
                    generateEmptyCards(3 - (gigsList.length % 3), Constants.object_type.gig)}

                  {lastResponseCount == 6 && (
                    <div className="load-section">
                      <Button
                        text="Load More"
                        buttonClass="explore-button"
                        type="button"
                        onClick={async () => {
                          await getGigs(true);
                        }}
                      />
                    </div>
                  )}
                </>
              )}

              {dataLoading && (
                <CardLoader
                  loaderItemClass="gig-loader-card-item"
                  loading={dataLoading}
                  numberOfRows={1}
                  numberOfCols={3}
                  styleTypes={[
                    ECardLoaderStyleType.PROFILE_IMG,
                    ECardLoaderStyleType.LONG_TEXT,
                    ECardLoaderStyleType.TWO_BUTTONS,
                  ]}
                />
              )}
            </div>
          }

          {!dataLoading && gigsList.length == 0 && (
            <div className="discover-list">{generateEmptyCards(3, Constants.object_type.gig)}</div>
          )}
        </div>
        {/*TODO: All discover shortcut items can go into shared components if their role gets more complicated*/}
        <div className="discover-shortcuts-list">
          <div className="discover-shortcut-item alt light">
            <div className="discover-shortcut">
              <div className="shortcut-text">
                <div className="shortcut-title">
                  Apply faster and get volunteering recommendations
                </div>
                <div className="shortcut-description">
                  Kambeo helps organizations connect to all levels of staff needed for your focus
                  area
                </div>
                <div className="shortcut-link">
                  <a
                    onClick={() =>
                      props.history.push(
                        isUserLoggedIn
                          ? `/dashboard?section=settings`
                          : `/register?redirect=/dashboard?section=settings`,
                        { fromVolunteers: true },
                      )
                    }
                  >
                    Create volunteer profile
                  </a>
                  <span className="fa fa-arrow-right" />
                </div>
              </div>
              <div className="shortcut-image">
                <img
                  src={CreateVolunteerProfile}
                  alt="create volunteer profile"
                />
              </div>
            </div>
          </div>
          {/* TODO: Move this to the Paid opprotunities when created
                        <div className="discover-shortcut-item dark">
                        <div className="discover-shortcut">
                            <div className="shortcut-image">
                                <img src={PaidOpprotunities} />
                            </div>
                            <div className="shortcut-text">
                                <div className="shortcut-title">
                                    Looking for experienced staff?
                                </div>
                                <div className="shortcut-description">
                                    Kambeo helps organizations connect to all
                                    levels of staff needed for your focus area
                                </div>
                                <div className="shortcut-link">
                                    <Link to={`/setup/gigs`}>
                                        Post Paid Opportunities
                                    </Link>
                                    <span className="fa fa-arrow-right"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    */}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (store: IAppState) => {
  return { userState: store.userState };
};

export default connect(mapStateToProps)(DiscoverVolunteers);
