import { IPaymentMethod } from '@gigit/interfaces';
import React, { FC } from 'react';
import { uiConstants } from '../../../../constants';
import { formatCardIcon } from '../../../../helpers';
import './PaymentMethodPreview.scss';
import GPayLogo from '../../../../assets/g_pay_logo.png';
import { ReactComponent as APayLogo } from '../../../../assets/a_pay_logo.svg';

interface IProps {
  onClickContainer?: () => void;
  isUsingAlternativePaymentMethod?: boolean;
  paymentMethod: IPaymentMethod | null;
  altPaymentMethod?: string;
}

const PaymentMethodPreview: FC<IProps> = (props) => {
  const { onClickContainer, paymentMethod, altPaymentMethod, isUsingAlternativePaymentMethod } =
    props;
  return (
    <>
      <div
        className="PaymentMethodPreview row-overlay no-select"
        onClick={() => onClickContainer?.()}
      >
        {/* TODO [HOTFIX/3.4.4]: Use PaymentMethodOption if we can. */}
        {isUsingAlternativePaymentMethod ? (
          <div>
            {altPaymentMethod === uiConstants.altPaymentMethods.googlePay ? (
              <img
                className="selected-alt-method-logo"
                src={GPayLogo}
              />
            ) : (
              <APayLogo className="selected-alt-method-logo" />
            )}
            {/*
                            Following the brand guide lines the methods should not be translated.
                            https://developers.google.com/pay/api/web/guides/brand-guidelines | https://developer.apple.com/design/human-interface-guidelines/apple-pay/overview/introduction/
                        */}
            <span notranslate="yes">
              {altPaymentMethod === uiConstants.altPaymentMethods.googlePay
                ? 'Google Pay'
                : 'Apple Pay'}
            </span>
          </div>
        ) : (
          <div>
            {paymentMethod?.card ? (
              <i className={formatCardIcon(paymentMethod.card?.brand ?? '')} />
            ) : (
              <i className="fal fa-credit-card" />
            )}
            <span>Payment Method</span>
            {paymentMethod && (
              <span className="cc">{`${paymentMethod.card?.brand?.toUpperCase()} ****${paymentMethod.card?.last4?.toUpperCase()}`}</span>
            )}
          </div>
        )}
        <i className="far fa-plus" />
      </div>
    </>
  );
};

export default PaymentMethodPreview;
