import Modal from '../../../../Modal/Modal';
import './RequestMatchModal.scss';
import React, { FC, useState, useEffect } from 'react';
import { groupRequestActions, hubRequestActions } from '../../../../../requestActions';
import useToastDispatcher from '../../../../../hooks/useToaster';
import { IAvailableDonationMatchingProgram, IGroupSummary } from '@gigit/interfaces';
import PopupMenu, {
  IPopupMenuItem,
  IShowPopupConfig,
} from '../../../../shared/PopupMenu/PopupMenu';
import DonationMatchingProgramDetails from '../../../../../routes/Donate/DonateForm/DonationMatchingProgramDetails/DonationMatchingProgramDetails';
import { defaultCurrency, formatCurrency } from '../../../../../helpers';
import { Constants } from '@gigit/constants';
import Button from '../../../../Button/Button';
import CoverImage from '../../../../CoverImage/CoverImage';
import Portrait from '../../../../Portrait/Portrait';
import { useLocale } from '../../../../../hooks';
import { uiConstants } from '../../../../../constants';

interface IProps {
  show: boolean;
  onClose(): void;
  hub_id: string;
  hub_title: string;
  group_id: string;
  transaction_id: string;
  currency: string;
  donation_amount: number;
  donation_status: string;
}

const RequestMatchModal: FC<IProps> = (props) => {
  const {
    show,
    donation_status,
    currency,
    onClose,
    hub_id,
    group_id,
    transaction_id,
    donation_amount,
    hub_title,
  } = props;
  const [programs, setPrograms] = useState<IAvailableDonationMatchingProgram[]>([]);
  const [selectedProgram, setSelectedProgram] = useState<IAvailableDonationMatchingProgram | null>(
    null,
  );
  const [programShowSectionContextMenu, setProgramShowSectionContextMenu] =
    useState<boolean>(false);
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const [proccessingData, setProccessingData] = useState<boolean>(false);
  const [matchedAmount, setMatchedAmount] = useState<number>(uiConstants.defaultMatchAmount);
  const [group, setGroup] = useState<IGroupSummary | null>(null);
  const { dispatchToastError } = useToastDispatcher();
  const locale = useLocale();
  const _Program_TYPE = Constants.donation_matching_program_type.donation_match;

  async function handleGetGroup() {
    try {
      const result = await groupRequestActions.getGroupByHandleOrId(group_id);
      setGroup(result);
    } catch (error) {
      dispatchToastError(error, 'Get Cause Info');
    }
  }

  async function handleGetPrograms() {
    try {
      const result = await hubRequestActions.getAvailableDonationMatchingProgramsForTransaction(
        _Program_TYPE,
        transaction_id,
      );
      setPrograms(result);
    } catch (error) {
      dispatchToastError(error, 'Get Donation Match Programs for Company');
    }
  }

  useEffect(() => {
    handleGetGroup();
    handleGetPrograms();
  }, []);

  const programShowMenuConfig: IShowPopupConfig = {
    showMenu: programShowSectionContextMenu,
    setShowMenu: setProgramShowSectionContextMenu,
    position: {
      type: 'bottom',
    },
  };

  const programSectionContextMenuItems: IPopupMenuItem[] =
    programs?.map((program) => ({
      id: program.id,
      label: program.name,
      isSelected: selectedProgram?.id === program.id,
      onClick: () => setSelectedProgram(program),
    })) || [];

  async function handleRequestMatch() {
    try {
      setProccessingData(true);
      await hubRequestActions.requestDonationMatch(
        { hub_id, program_type: _Program_TYPE, transaction_id },
        { amount_matched: matchedAmount },
      );
      setShowThankYou(true);
    } catch (error) {
      dispatchToastError(error, 'Request Match');
    } finally {
      setProccessingData(false);
    }
  }

  const _CURRENCY = currency ?? defaultCurrency;

  return (
    <Modal
      show={show}
      onClose={onClose}
      class="RequestMatchModal"
    >
      {showThankYou && (
        <div className="thank-you">
          <i
            className="fal fa-times close-icon"
            onClick={onClose}
          />
          <div className="info">
            <h1>Thank You!</h1>
            <p>
              You have successfully requested a match of{' '}
              <span
                className="price"
                notranslate="yes"
              >
                {formatCurrency(matchedAmount, currency, locale.currentLocale)}
              </span>{' '}
              from <var data-var="hub_title">{hub_title}</var>
            </p>
            <p>
              You will be notified when your match has been paid to the charity by your company.
            </p>
          </div>
        </div>
      )}
      {!showThankYou && (
        <>
          <div className="main-info">
            <div className="top">
              <h2>Request Matching</h2>
              <h1>Donation Matching</h1>
              <PopupMenu
                className="pick-program"
                showMenuConfig={programShowMenuConfig}
                menuItems={programSectionContextMenuItems}
                onClick={() => setProgramShowSectionContextMenu(!programShowSectionContextMenu)}
                onSelect={() => setProgramShowSectionContextMenu(false)}
              >
                <span className="title">
                  {selectedProgram?.name || 'Select a Donation Matching Program'}
                  <i
                    className={`fas fa-sort-down menu-btn ${programShowSectionContextMenu ? 'show' : ''}`}
                  />
                </span>
              </PopupMenu>
              {selectedProgram && (
                <DonationMatchingProgramDetails
                  currency={_CURRENCY}
                  program={selectedProgram!}
                  donationAmount={donation_amount}
                  matchedAmount={matchedAmount}
                  setMatchedAmount={(v) => setMatchedAmount(v)}
                />
              )}
              {!selectedProgram && <p className="note">Donation matching program is requiered.</p>}
            </div>
            <div className="btn-container">
              <Button
                text="Cancel"
                onClick={onClose}
                buttonType="outline-dark"
                type="button"
              />
              <Button
                loading={proccessingData}
                isDisabled={!selectedProgram || proccessingData}
                text="Request Match"
                onClick={handleRequestMatch}
                buttonType="dark"
                type="button"
              />
            </div>
          </div>
          <div className="donaiton-summary">
            <i
              className="fal fa-times close-icon"
              onClick={onClose}
            />
            <CoverImage
              currentImage={group?.cover_image_url}
              className="cover-img"
              type="group"
              width={400}
              height={140}
            />
            <Portrait
              className="portrait-img"
              size={90}
              currentImage={group?.profile_image_url}
            />
            <div className="info">
              <span
                className="title"
                notranslate="yes"
              >
                {group?.title}
              </span>
              <span className="subtitle">
                Charity ID: <var data-var="group_charity_id">{group?.charity_id}</var>
              </span>
              {donation_status && (
                <span className={`status ${donation_status || ''}`}>
                  Donation {donation_status}
                </span>
              )}
              <div className="amounts with-border">
                <div className="item">
                  <span className="name">Donation Amount</span>
                  <span
                    className="value"
                    notranslate="yes"
                  >
                    {formatCurrency(donation_amount, currency, locale.currentLocale)}
                  </span>
                </div>
                <div className="item">
                  <span className="name">Match Amount</span>
                  <span
                    className="value"
                    notranslate="yes"
                  >
                    {selectedProgram &&
                      formatCurrency(matchedAmount, currency, locale.currentLocale)}
                  </span>
                </div>
              </div>
              <div className="amounts">
                <div className="item total-amount">
                  <span className="name">Total Donation Amount</span>
                  <span
                    className="value"
                    notranslate="yes"
                  >
                    {selectedProgram &&
                      formatCurrency(
                        donation_amount + (matchedAmount ?? 0),
                        currency,
                        locale.currentLocale,
                      )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default RequestMatchModal;
