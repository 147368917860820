import React, { FC, useState, useEffect } from 'react';
import { IOwnerObject } from '../../interfaces';
import { IEventSummaryFE, IGroup } from '@gigit/interfaces';
import { errorHelpers, toastError, toastSuccess, typeHelpers } from '../../helpers';

import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import Modal from '../Modal/Modal';
import { createToast } from '../../actions/toaster';
import ModalHeader from '../Modal/ModalHeader/ModalHeader';
import { eventRequestActions, groupRequestActions } from '../../requestActions';
import { Constants } from '@gigit/constants';
import { localizeHelpers } from '../../localizeHelpers';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import './DonationSettingsModal.scss';

interface IProps {
  owner: IOwnerObject;
  onClose: () => void;
}

interface IDonationAmount {
  amount: number;
  description?: string;
}

const ActionType: { [key: string]: string } = {
  ACCEPTING_DONATIONS: 'accepting_donations',
  ALLOW_SUBSCRIPTIONS: 'allow_subscriptions',
  ALLOW_CHECKOUT_DONATIONS: 'allow_checkout_donations',
};

const DonationSettingsModal: FC<IProps> = (props: IProps) => {
  const [showModal, setShowModal] = useState<boolean>(true);
  const [donationAmounts, setDonationAmounts] = useState<IDonationAmount[]>([]);
  const [amountsChanged, setAmountsChanged] = useState<boolean>(false);
  const [ownerObject, setOwnerObject] = useState<IGroup | IEventSummaryFE>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const ownerObject: IGroup | IEventSummaryFE = typeHelpers.requireOwnerObjectAs(
      props.owner,
      'group',
      'event',
    );
    setOwnerObject(ownerObject);
    if (ownerObject.donation_amounts && ownerObject.donation_amounts.length > 0) {
      setDonationAmounts(ownerObject.donation_amounts as IDonationAmount[]);
    } else {
      setDonationAmounts([
        { amount: 25, description: 'Patients care package' },
        { amount: 50, description: 'Care package for family and care workers' },
        { amount: 100, description: 'Contribute to healthy preventative public policies' },
        { amount: 250, description: 'Community Care Package' },
        { amount: 500, description: 'Prestigious Donor' },
      ]);
    }
  }, []);

  useEffect(() => {
    if (!showModal) props.onClose();
  }, [showModal]);

  const handleAmountChange = (index: number, value: number) => {
    const updated = [...donationAmounts];
    updated[index].amount = value;
    setDonationAmounts(updated);
    setAmountsChanged(true);
  };

  const handleDescriptionChange = (index: number, value: string) => {
    const updated = [...donationAmounts];
    updated[index].description = value;
    setDonationAmounts(updated);
    setAmountsChanged(true);
  };

  const isBankAccountConnected = (): boolean => {
    if (!props.owner.account) {
      const toast = toastError(
        localizeHelpers.translate(
          'Connect a bank account to begin accepting payments for donations',
        ),
        'Donations',
      );
      dispatch(createToast(toast));
      return false;
    }
    return true;
  };

  const saveAmountsSettings = async () => {
    if (!ownerObject || !isBankAccountConnected()) return;

    const upatedObject: Promise<IEventSummaryFE | IGroup> =
      props.owner.ownerType === Constants.object_type.group
        ? groupRequestActions.updateGroup(ownerObject.id, { donation_amounts: donationAmounts })
        : eventRequestActions.updateEvent(ownerObject.id, { donation_amounts: donationAmounts });

    upatedObject
      .then((response) => {
        const toast = toastSuccess(
          localizeHelpers.translate('Donation amounts updated successfully.'),
          'Donations',
        );
        dispatch(createToast(toast));
        setOwnerObject(response);
        setAmountsChanged(false);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(
          errorObj.translatedMessage,
          `Update ${props.owner.ownerType} settings`,
        );
        dispatch(createToast(toast));
      });
  };

  const toggleAction = async (action: string) => {
    if (!ownerObject || !isBankAccountConnected()) return;

    let payload: Partial<IEventSummaryFE | IGroup> = {};

    switch (action) {
      case ActionType.ACCEPTING_DONATIONS:
        payload = { accepting_donations: !ownerObject.accepting_donations };
        break;
      case ActionType.ALLOW_SUBSCRIPTIONS:
        payload = { allow_subscriptions: !ownerObject.allow_subscriptions };
        break;
      case ActionType.ALLOW_CHECKOUT_DONATIONS:
        payload = { allow_checkout_donations: !ownerObject.allow_checkout_donations };
        break;
    }

    const upatedObject: Promise<IEventSummaryFE | IGroup> =
      props.owner.ownerType === Constants.object_type.group
        ? groupRequestActions.updateGroup(ownerObject.id, payload as IGroup)
        : eventRequestActions.updateEvent(ownerObject.id, payload as IEventSummaryFE);

    upatedObject
      .then((response) => {
        if (ActionType.ACCEPTING_DONATIONS in payload && !response.accepting_donations) {
          //notity user if they turning ACCEPT DONATIONS to OFF
          const toast = toastError(
            localizeHelpers.translate(
              'This {{ownerType}} is currently not accepting donations, turn on "Accepting Donations" option in the donation settings.',
              {
                ownerType: props.owner.ownerType,
              },
            ),
            'Donations',
          );
          dispatch(createToast(toast));
        }
        setOwnerObject(response);
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(
          errorObj.translatedMessage,
          `Update ${props.owner.ownerType} settings`,
        );
        dispatch(createToast(toast));
      });
  };

  const link = (
    <Link to={`/${props.owner.ownerType}/${props.owner.ownerHandle}/admin?t=settings`}>
      {props.owner.ownerType} management page
    </Link>
  );

  return (
    <div className="DonationSettingsModal">
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <ModalHeader
          className="header-desc"
          title="Donation Settings"
          description={<span>For more settings, visit your {link}</span>}
        />

        <div className="donation-settings-content">
          <div className="settings-item">
            <div className="details">
              <span>Accepting Donations</span>
              <span className="detail">Are you accepting donations at this time?</span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => toggleAction(ActionType.ACCEPTING_DONATIONS)}
                className={
                  ownerObject?.accepting_donations ? 'fad fa-toggle-on' : 'fad fa-toggle-off'
                }
              ></i>
            </div>
          </div>

          <div className="settings-item">
            <div className="details">
              <span>Allow Monthly Donations</span>
              <span className="detail">
                Do you want donors to have the option to donate monthly?
              </span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => toggleAction(ActionType.ALLOW_SUBSCRIPTIONS)}
                className={
                  ownerObject?.allow_subscriptions ? 'fad fa-toggle-on' : 'fad fa-toggle-off'
                }
              ></i>
            </div>
          </div>

          <div className="settings-item">
            <div className="details">
              <span>Allow Checkout Donations</span>
              <span className="detail">
                Do you want to allow users to make a donation upon checkout?
              </span>
            </div>
            <div className="settings-control">
              <i
                onClick={() => toggleAction(ActionType.ALLOW_CHECKOUT_DONATIONS)}
                className={
                  ownerObject?.allow_checkout_donations ? 'fad fa-toggle-on' : 'fad fa-toggle-off'
                }
              ></i>
            </div>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              saveAmountsSettings();
            }}
          >
            <div className="settings-item amounts">
              <div className="details">
                <span>Preset Donation Amounts</span>
                <span className="detail">
                  Edit the 5 preset donation amounts that visitors see as suggested amounts on your
                  donation page.
                </span>
              </div>

              {donationAmounts.length > 0 &&
                donationAmounts.map((amount: IDonationAmount, index: number) => {
                  return (
                    <div
                      key={index}
                      className="donation-amount"
                    >
                      <div className="index">{index + 1}</div>
                      <div className="donation-amount-text">
                        <TextField
                          required={true}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleAmountChange(index, Number(e.target.value))
                          }
                          value={amount.amount.toString().replace(/^0{2}/, '')}
                          name="amount"
                          type="number"
                          min="5"
                        />
                      </div>

                      <div className="donation-description">
                        <TextField
                          placeholder={
                            amount.description
                              ? amount.description
                              : 'Add your description here (optional)'
                          }
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleDescriptionChange(index, e.target.value)
                          }
                          value={amount.description || ''}
                          name="desc"
                          type="text"
                          maxLength={80}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="controls">
              <Button
                buttonClass="cancel-button"
                type="button"
                isDisabled={false}
                text="Cancel"
                onClick={() => props.onClose()}
              />
              <Button
                isDisabled={!amountsChanged}
                text="Save Preset Amounts"
                type="submit"
              />
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default DonationSettingsModal;
