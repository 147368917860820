import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { TwitchEmbed } from 'react-twitch-embed';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IEventIndividual, IGroup, IPage, IPageComponent } from '@gigit/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  IStringMap,
  mapPermissions,
  swapRouteParams,
  routes,
  handleInputChange,
} from '../../../../helpers';
import { IAppState } from '../../../../store';

import { updateEventPageComponent } from '../../../../actions/event';
import { updateUserPageComponent } from '../../../../actions/user';

import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import TextField from '../../../TextField/TextField';

import './LivestreamManagement.scss';
import {
  ILiveStreamComponent,
  IPageComponentGeneric,
  IStreamerMetadataObj,
} from '../../../../interfaces';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  page: IPage;
  component: ILiveStreamComponent;
  updateEventPageComponent(
    eventId: string,
    _pageId: string,
    _componentId: string,
    _component: any,
    callback?: () => void,
  ): void;
  updateUserPageComponent(pageId: string, componentId: string, payload: any): void;
  onClose?: () => void;
  onComponentUpdate?: () => void;
}

interface IState {
  metaData: IStreamerMetadataObj[];
  showAddStream: boolean;
  addStreamerValue: string;
}

class LivestreamManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      metaData: this.props.component.meta_data ? [...this.props.component.meta_data] : [],
      showAddStream: false,
      addStreamerValue: '',
    };

    this.getEventIndividuals = this.getEventIndividuals.bind(this);
    this.isListed = this.isListed.bind(this);
    this.toggleStreamer = this.toggleStreamer.bind(this);
    this.removeStreamer = this.removeStreamer.bind(this);
    this.handleStreamerDrag = this.handleStreamerDrag.bind(this);
  }

  componentDidMount() {
    if (this.props.page.owner_type === 'event') {
      this.getEventIndividuals();
    }
  }

  isListed(handle: string) {
    for (let m in this.state.metaData) {
      if (this.state.metaData[m].user_handle === handle) {
        return true;
      }
    }

    return false;
  }

  getEventIndividuals() {
    axios
      .get<
        IEventIndividual[]
      >(swapRouteParams(routes.GET_EVENT_INDIVIDUALS, { eventId: this.props.page.owner_id }))
      .then((response) => {
        let streamers: IStreamerMetadataObj[] = [];

        for (const individual of response.data) {
          if (
            individual.content_creator &&
            individual.user?.twitch?.login &&
            !this.isListed(individual.user?.handle ?? '')
          ) {
            streamers.push({
              twitch: individual.user.twitch.login,
              display_name: individual.user.display_name ?? '',
              profile_image_url: individual.user.profile_image_url,
              raised: individual.raised,
              goal: individual.goal,
              user_handle: individual.user.handle,
              visible: false,
            });
          }
        }

        this.setState({
          metaData: [...this.state.metaData, ...streamers],
        });
      });
  }

  updateStreamers() {
    let _payload: any = {
      meta_data: this.state.metaData,
    };

    if (this.props.page.owner_type === 'event') {
      this.props.updateEventPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id ? this.props.component.id : this.props.component.id,
        _payload,
        this.props.onComponentUpdate ? this.props.onComponentUpdate : undefined,
      );
    } else {
      this.props.updateUserPageComponent(this.props.page.id, this.props.component.id, _payload);
    }

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  toggleStreamer(index: number) {
    let _meta: any = [...this.state.metaData];

    _meta[index].visible = !_meta[index].visible;

    this.setState({
      metaData: _meta,
    });
  }

  addStreamer(streamer: any) {
    let _meta: any = [...this.state.metaData];

    _meta.push(streamer);

    this.setState({
      metaData: _meta,
      showAddStream: false,
    });
  }

  getStreamName(item: any) {
    if (item.raised >= 0) {
      return (
        <div className="streamer">
          <span className="displayName">{item.display_name}</span>
          <span className="twitch">({item.twitch})</span>
        </div>
      );
    } else {
      return (
        <div className="streamer">
          <span className="displayName">{item.display_name}</span>
        </div>
      );
    }
  }

  removeStreamer(index: number) {
    let _meta: any = [...this.state.metaData];

    _meta.splice(index, 1);

    this.setState({
      metaData: _meta,
    });
  }

  handleStreamerDrag(result: DropResult) {
    if (result.destination) {
      let _meta: any = [...this.state.metaData];

      _meta.splice(result.destination.index, 0, _meta.splice(result.source.index, 1)[0]);

      this.setState({
        metaData: _meta,
      });
    }
  }

  render() {
    let { t } = this.props;

    return (
      <div className="LivestreamManagement">
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
            this.updateStreamers();
          }}
        >
          {this.state.metaData.length === 0 && (
            <div className="no-streamers">{t("You haven't added any livestreamers, yet.")}</div>
          )}
          <DragDropContext onDragEnd={this.handleStreamerDrag}>
            <Droppable droppableId="streamers">
              {(provided, snapshot) => {
                let result = (
                  <ul
                    className={snapshot.isDraggingOver ? 'streamer dragging' : 'streamer'}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {this.state.metaData.map((item, index) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={'streamer-' + index}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            // FIXME: What does this code do??
                            // const style = provided.draggableProps.style;
                            // if (snapshot.isDragging && style && 'left' in style ) {
                            //     style.left = provided.draggableProps.style.offsetLeft;
                            //     style.top = provided.draggableProps.style.offsetTop;
                            // }

                            return (
                              <li
                                className={
                                  snapshot.isDragging
                                    ? 'stream-inner no-select dragging'
                                    : 'stream-inner no-select'
                                }
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <i
                                  {...provided.dragHandleProps}
                                  className="fas fa-grip-lines"
                                ></i>
                                {this.getStreamName(item)}
                                {(item.raised ?? 0) >= 0 && (
                                  <i
                                    onClick={() => {
                                      this.toggleStreamer(index);
                                    }}
                                    className={
                                      item.visible ? 'fa fa-toggle-on' : 'fad fa-toggle-off'
                                    }
                                  ></i>
                                )}

                                {/* FIXME: Why item.raised === -1 ???? */}
                                {(item.raised === -1 ?? 0) && (
                                  <i
                                    onClick={() => {
                                      this.removeStreamer(index);
                                    }}
                                    className="fal fa-trash-alt"
                                  ></i>
                                )}
                              </li>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                );
                return result;
              }}
            </Droppable>
          </DragDropContext>
          <div
            onClick={() => {
              this.setState({ showAddStream: true, addStreamerValue: '' });
            }}
            className="add-stream no-select"
          >
            <div className="add-stream-inner">
              <i className="far fa-plus-circle"></i>
              <span>{t('Add Livestream')}</span>
            </div>
          </div>
          <div className="actions">
            <Button text={t('Save')} />
            <Button
              text={t('Cancel')}
              onClick={(e: any) => {
                e.preventDefault();
                if (this.props.onClose) {
                  this.props.onClose();
                }
              }}
            />
          </div>
        </form>
        <Modal
          class="add-stream-modal"
          show={this.state.showAddStream}
          onClose={() => {
            this.setState({ showAddStream: false });
          }}
        >
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              this.addStreamer({
                twitch: this.state.addStreamerValue.toLowerCase(),
                display_name: this.state.addStreamerValue,
                profile_image_url: '',
                raised: -1,
                goal: -1,
                handle: '',
                visible: true,
              });
            }}
          >
            <div className="title">{t('Add Livestream')}</div>
            <div className="subtitle">{t('Add a Livestream')}</div>
            <div className="description">
              {t('Manually add a livestream that will be visible in your component.')}
            </div>
            <TextField
              label={t('Twitch Name')}
              required={true}
              type="text"
              name="addStreamerValue"
              value={this.state.addStreamerValue}
              onChange={(e: any) => {
                handleInputChange(e, this);
              }}
            />
            <div className="actions">
              <Button text={t('Add')} />
              <Button
                onClick={(e: any) => {
                  e.preventDefault();
                  this.setState({ showAddStream: false });
                }}
                text={t('Cancel')}
              />
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {};
};

const mapDispatchToProps = {
  updateEventPageComponent,
  updateUserPageComponent,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(LivestreamManagement),
);
