import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  routes,
  swapRouteParams,
  formatCurrency,
  defaultCurrency,
  toastError,
  capitalizeString,
  handleInputChange,
  toastSuccess,
  typeHelpers,
} from '../../../helpers';
import { IUserRole, IPledge, ITransaction } from '@gigit/interfaces';
import { IGroupState } from '../../../reducers/group';
import Button from '../../Button/Button';
import SortableTable, { ISortableTableColumn } from '../../SortableTable/SortableTable';
import Modal from '../../Modal/Modal';
import TextField from '../../TextField/TextField';
import ContactPledges from '../Pledges/ContactPledges';
import DonorSummary from '../DonorSummary/DonorSummary';
import AddDonation from '../../AddDonation/AddDonation';
import AddPledge from '../AddPledge/AddPledge';
import ApplyPledge from '../ApplyPledge/ApplyPledge';
import ActivityLog from '../ActivityLog/ActivityLog';
import { createToast } from '../../../actions/toaster';
import { IAppState } from '../../../store';
import ContactCard from '../ContactCard/ContactCard';
import {
  refundDonation,
  downloadGroupDonationReceipt,
  updateThankYouMessage,
  sendConfirmationEmail,
  sendTaxReceipt,
  regenerateTaxReceipt,
} from '../../../actions/group';
import { refundEventDonation, downloadEventDonationReceipt } from '../../../actions/event';
import './ContactDonor.scss';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast, ReduxActionType } from '../../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { userSelectors } from '../../../selectors/user';
import QuillTextEditor from '../../QuillTextEditor/QuillTextEditor';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  locale: string;
  createToast(toast: IToast): void;
  refundDonation(
    groupId: string,
    donationId: string,
    payload: any,
    callback?: (transaction: ITransaction) => void,
  ): void;
  refundEventDonation(
    eventId: string,
    donationId: string,
    payload: any,
    callback?: (transaction: ITransaction) => void,
  ): void;
  downloadGroupDonationReceipt: ReduxActionType<typeof downloadGroupDonationReceipt>;
  downloadEventDonationReceipt: ReduxActionType<typeof downloadEventDonationReceipt>;
  updateThankYouMessage(
    groupId: string,
    transactionId: string,
    payload: any,
    callback?: (transaction: ITransaction) => void,
  ): void;
  sendConfirmationEmail(groupId: string, transactionId: string): void;
  sendTaxReceipt(groupId: string, transactionId: string): void;
  regenerateTaxReceipt(groupId: string, transactionId: string): void;
}

interface IState {
  tabs: Array<string>;
  activeTab: number;
  columns: ISortableTableColumn[];
  data: Array<ITransaction>;
  pledgeData: Array<IPledge>;
  showAddDonation: boolean;
  showAddPledge: boolean;
  showApplyPledge: boolean;
  donationId: string;
  showActivityLog: boolean;
  loading: boolean;
  showRefundModal: boolean;
  order: ITransaction | null;
  refund_amount: number;
  showThankYouModal: boolean;
  thankYouMessage: string;
}

class ContactDonor extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tabs: ['Summary', 'Donations', 'Pledges'],
      activeTab: 1,
      columns: [
        { label: 'Date', id: 'date', sortable: false },
        { label: 'Recurring', id: 'recurring', sortable: false },
        { label: 'Applied to Pledge', id: 'pledge', sortable: false },
        { label: 'Tax Receipt', id: 'receipt', sortable: false },
        { label: 'Amount', id: 'amount', sortable: false },
        { label: 'Payment Status', id: 'payment_status', sortable: false },
        { label: 'Actions', id: 'actions', sortable: false },
      ],
      data: [],
      pledgeData: [],
      showAddDonation: false,
      showAddPledge: false,
      showApplyPledge: false,
      donationId: '',
      showActivityLog: false,
      loading: false,
      showRefundModal: false,
      order: null,
      refund_amount: 0,
      showThankYouModal: false,
      thankYouMessage: '',
    };

    this.getPledgesData = this.getPledgesData.bind(this);
    this.closeAddDonation = this.closeAddDonation.bind(this);
    this.onApplyPledge = this.onApplyPledge.bind(this);
    this.onApplyPledgeClose = this.onApplyPledgeClose.bind(this);
  }

  componentDidMount() {
    this.getDonationData();
    this.getPledgesData();
  }

  getDonationData() {
    axios
      .get(
        swapRouteParams(routes.GET_CONTACT_DONATIONS, {
          groupId: this.props.groupState.group.id,
          contactId: this.props.contact.user?.id,
        }),
      )
      .then((response) => {
        this.setState({
          data: response.data,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Contact Donor Info');
        this.props.createToast(toast);
      });
  }

  getPledgesData() {
    this.setState({
      loading: true,
    });

    axios
      .get(
        swapRouteParams(routes.GET_GROUP_PLEDGES_FOR_USER, {
          groupId: this.props.groupState.group.id,
          donorId: this.props.contact.user?.id,
        }),
      )
      .then((response) => {
        this.setState({
          pledgeData: response.data,
        });
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Contact Pledges');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  initRefund(transaction: ITransaction) {
    this.setState({
      showRefundModal: true,
      order: transaction,
      refund_amount: transaction.amounts.total / 100,
    });
  }

  refund(transactonId: string) {
    if (this.state.order) {
      if (this.state.order.event_id) {
        this.props.refundEventDonation(
          this.state.order.event_id,
          transactonId,
          { amount: this.state.refund_amount },
          (transaction: ITransaction) => {
            //callback function to update datatable immediately after refund (to show 'Refund' status)
            const updatedData = [...this.state.data];
            const foundIndex = updatedData.findIndex((d) => d.id === transactonId);
            updatedData[foundIndex] = transaction;

            this.setState({
              data: updatedData,
            });
          },
        );
      } else {
        this.props.refundDonation(
          this.state.order.group_id,
          transactonId,
          { amount: this.state.refund_amount },
          (transaction: ITransaction) => {
            //callback function to update datatable immediately after refund (to show 'Refund' status)
            const updatedData = [...this.state.data];
            const foundIndex = updatedData.findIndex((d) => d.id === transactonId);
            updatedData[foundIndex] = transaction;

            this.setState({
              data: updatedData,
            });
          },
        );
      }
    }

    this.setState({
      showRefundModal: false,
      refund_amount: 0,
      order: null,
    });
  }

  downloadReceipt(transaction: ITransaction) {
    if (transaction.event_id) {
      this.props.downloadEventDonationReceipt(transaction.event_id, transaction);
    } else {
      this.props.downloadGroupDonationReceipt(transaction.group_id, transaction);
    }
  }

  saveThankYouMessage() {
    if (this.state.order) {
      const transactionId = this.state.order?.id || '';

      this.props.updateThankYouMessage(
        this.state.order.group_id,
        transactionId,
        { value: this.state.thankYouMessage },
        (transaction: ITransaction) => {
          const updatedData = [...this.state.data];
          const foundIndex = updatedData.findIndex((d) => d.id === transactionId);
          updatedData[foundIndex] = transaction;

          this.setState({
            data: updatedData,
          });

          const toast = toastSuccess(
            localizeHelpers.translate('Thank you message successfully updated.'),
            'Transaction Record',
          );
          this.props.createToast(toast);
        },
      );
    }

    this.setState({
      showThankYouModal: false,
      thankYouMessage: '',
      order: null,
    });
  }

  sendConfirmationEmail(transaction: ITransaction) {
    this.props.sendConfirmationEmail(transaction.group_id, transaction.id || '');

    const toast = toastSuccess(
      localizeHelpers.translate('Confirmation email successfully sent.'),
      'Transaction Record',
    );
    this.props.createToast(toast);
  }

  convertDonationData() {
    let currency = this.props.groupState.group.account?.currency || defaultCurrency;

    return this.state.data.map((d: ITransaction) => {
      let yes = 'Yes';
      let no = 'No';
      let date = localizeHelpers.formatDate(
        d.created_at ?? new Date(),
        LocaleDateFormats.ll,
        this.props.locale,
      );
      let recurring = d?.recurring ? yes : no;
      let hasPledge = d?.pledge_id ? yes : no;
      let shouldDissociate = !d?.pledge_id;
      let applyLabel = shouldDissociate ? 'Apply to Pledge' : 'Dissociate Pledge';
      let total =
        d?.amounts.total && d?.amounts?.amount_multiplier
          ? d?.amounts.total * d?.amounts?.amount_multiplier
          : 0;
      let id = d?.id || '';

      let apply = (id: string) => {
        if (shouldDissociate) {
          this.onApplyPledge(id);
        } else {
          this.dissociatePledge(id);
        }
      };

      return {
        row: [
          { content: `${date}`, id: 'date', notranslate: 'yes' },
          { content: `${recurring}`, id: 'recurring' },
          { content: `${hasPledge}`, id: 'pledge' },
          { content: `${capitalizeString(d?.tax_receipt_status?.code)}`, id: 'receipt' },
          {
            content: `${formatCurrency(total, currency, this.props.locale)}`,
            id: 'amount',
            notranslate: 'yes',
          },
          {
            content: (
              <span
                className={`${d?.amounts.is_refund ? 'refunded' : d.amounts.payment_status?.code}`}
              >
                {capitalizeString(
                  d?.amounts.is_refund ? 'refunded' : d?.amounts.payment_status?.code,
                )}
              </span>
            ),
            id: 'payment_status',
          },
          {
            id: 'actions',
            menu: [
              ...[
                {
                  icon: 'fas fa-hand-holding-heart',
                  onClick: () => {
                    apply(id);
                  },
                  label: applyLabel,
                },
                {
                  icon: 'far fa-file-alt',
                  onClick: () => {
                    this.setState({ showActivityLog: true });
                  },
                  label: 'Activity Log',
                },
              ],

              //Show additional options for transactions that have status 'Paid' or are not refunds
              ...(!d.amounts.is_refund && d.amounts.payment_status?.code === 'paid'
                ? [
                    {
                      icon: 'fa fa-file-pdf-o',
                      onClick: () => this.downloadReceipt(d),
                      label: 'View Tax Receipt',
                    },
                    {
                      icon: 'fa fa-envelope',
                      onClick: () => {
                        this.setState({
                          thankYouMessage: d.confirmation_message || '',
                          showThankYouModal: true,
                          order: d,
                        });
                      },
                      label: 'Edit Thank You Message',
                    },
                    {
                      icon: 'fa fa-envelope',
                      onClick: () => this.sendConfirmationEmail(d),
                      label: 'Send Confirmation Email',
                    },
                    {
                      icon: 'fa fa-envelope',
                      onClick: () => this.props.sendTaxReceipt(d.group_id, d.id || ''),
                      label: 'Send Tax Receipt',
                    },
                    {
                      icon: 'fa fa-file',
                      onClick: () => this.props.regenerateTaxReceipt(d.group_id, d.id || ''),
                      label: 'Re-Generate Tax Receipt',
                    },
                    {
                      icon: 'far fa-dollar-sign',
                      onClick: () => this.initRefund(d),
                      label: 'Refund',
                    },
                  ]
                : []),
            ],
          },
        ],
      };
    });
  }

  closeAddDonation() {
    this.setState({
      showAddDonation: false,
    });
    this.getDonationData();
  }

  onApplyPledge(id: string) {
    this.setState({
      donationId: id,
      showApplyPledge: true,
    });
  }

  onApplyPledgeClose() {
    this.setState(
      {
        donationId: '',
        showApplyPledge: false,
      },
      () => {
        this.getDonationData();
        this.getPledgesData();
      },
    );
  }

  dissociatePledge(id: string) {
    axios
      .put(
        swapRouteParams(routes.DISSOCIATE_DONATION_PLEDGE, {
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user?.id,
          donationId: id,
        }),
      )
      .then((response) => {
        this.getDonationData();
        this.getPledgesData();
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Dissociate Pledge');
        this.props.createToast(toast);
      });
  }

  render() {
    const exchangeRate = this.state.order?.reporting_exchange_rate ?? 1;
    const orderCurrency = this.state.order?.reporting_currency ?? defaultCurrency;
    const total = ((this.state.order?.amounts?.total || 0) / 100) * exchangeRate;
    const refundAmpuntPattern = new RegExp(/^((\d+\.?|\.(?=\d))?\d{0,2})$/); //Empty string or Decimal with 2 numbers after period. No negatives
    const ownerObj = typeHelpers.createOwnerObject('group', this.props.groupState.group);

    return (
      <div className="ContactDonor">
        <ul className="Contact-menu">
          {this.state.tabs.map((tab, index) => {
            return (
              <li
                onClick={() => {
                  this.setState({ activeTab: index });
                }}
                className={this.state.activeTab === index ? 'active no-select' : 'no-select'}
                key={index}
              >
                <span>{tab}</span>
              </li>
            );
          })}
          <li className="add">
            {this.state.activeTab === 1 && (
              <Button
                onClick={() => {
                  this.setState({ showAddDonation: true });
                }}
                className="add-field"
                icon="far fa-plus"
                text={'Add Donation'}
              />
            )}
            {this.state.activeTab === 2 && (
              <Button
                onClick={() => {
                  this.setState({ showAddPledge: true });
                }}
                className="add-field"
                icon="far fa-plus"
                text={'Pledge'}
              />
            )}
          </li>
        </ul>
        <div className="Contact-content">
          <div className="Contact-side">
            <ContactCard
              groupId={this.props.groupState.group.id}
              {...this.props}
            />
          </div>
          <div className="contact-main">
            {this.state.activeTab === 1 && (
              <div className="data">
                <SortableTable
                  {...this.props}
                  columns={this.state.columns}
                  data={this.convertDonationData()}
                  loading={this.state.loading}
                />
              </div>
            )}
            {this.state.activeTab === 2 && (
              <div className="data">
                <ContactPledges
                  loading={this.state.loading}
                  refreshList={this.getPledgesData}
                  pledges={this.state.pledgeData}
                  {...this.props}
                />
              </div>
            )}
            {this.state.activeTab === 0 && (
              <div className="data">
                <DonorSummary {...this.props} />
              </div>
            )}
          </div>
        </div>
        <Modal
          class="add-donation"
          show={this.state.showAddDonation}
          onClose={() => {
            this.setState({ showAddDonation: false });
          }}
        >
          <AddDonation
            {...this.props}
            onClose={this.closeAddDonation}
            uiStateType={'default'}
          />
        </Modal>
        <Modal
          class="add-field pledge"
          show={this.state.showAddPledge}
          onClose={() => {
            this.setState({ showAddPledge: false });
          }}
        >
          <AddPledge
            {...this.props}
            onClose={(response) => {
              if (response) {
                this.setState({
                  pledgeData: [response.data],
                });
              }
              this.setState({ showAddPledge: false });
            }}
          />
        </Modal>
        <Modal
          class="add-field apply-pledge"
          show={this.state.showApplyPledge}
          onClose={() => {
            this.onApplyPledgeClose();
          }}
        >
          <ApplyPledge
            pledges={this.state.pledgeData}
            {...this.props}
            onClose={() => {
              this.onApplyPledgeClose();
            }}
            donationId={this.state.donationId}
          />
        </Modal>
        <Modal
          class="activity-log-modal"
          show={this.state.showActivityLog}
          onClose={() => this.setState({ showActivityLog: false })}
        >
          <ActivityLog {...this.props} />
        </Modal>

        {/* TODO: We need to create separate component - RefundModal. We duplicate this code a lot */}
        <Modal
          onClose={() => this.setState({ showRefundModal: false })}
          show={this.state.showRefundModal}
          contentClassName="refund-content"
        >
          <div className="refund">
            <div className="refund-title">
              <h1>Refund Order</h1>
              <div>A copy of the reciept will be emailed to the customer.</div>
            </div>
            <div className="refund-info">
              <div className="sub-header">Order Summary</div>
              <div className="info">
                <div className="info-part">
                  <span>Name: </span>
                  <var data-var="name">
                    {this.state.order?.user?.first_name && this.state.order?.user?.last_name
                      ? `${this.state.order?.user?.first_name} ${this.state.order?.user?.last_name}`
                      : this.state.order?.user?.display_name}
                  </var>
                </div>
                <div className="info-part">
                  <span>Transaction type: </span>
                  <var data-var="transaction_type">
                    {this.state.order && this.state.order.transaction_type}
                  </var>{' '}
                </div>
                <div className="info-part">
                  <span>Transaction #: </span>
                  <var data-var="transaction_number">
                    {this.state.order && this.state.order.transaction_number}
                  </var>{' '}
                </div>
                <div className="info-part">
                  <span>Amount Charged:</span>{' '}
                  {formatCurrency(total, orderCurrency, this.props.locale)}{' '}
                </div>
              </div>
              <div className="sub-header">Refund Details:</div>
              <div className="refund-details">
                <TextField
                  label={'Refund Amount'}
                  required={true}
                  value={this.state.refund_amount}
                  name="refund_amount"
                  type="string"
                  onChange={(e: any) => {
                    if (
                      refundAmpuntPattern.test(e.target.value) &&
                      e.target.value <= (this.state.order?.amounts?.total ?? 0) / 100
                    ) {
                      handleInputChange(e, this);
                    }
                  }}
                />
              </div>
              <div className="refund-actions">
                <Button
                  isDisabled={!this.state.refund_amount}
                  onClick={() => this.refund(this.state.order?.id || '')}
                  text={'Refund'}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showThankYouModal}
          onClose={() => {
            this.setState({ showThankYouModal: false, thankYouMessage: '', order: null });
          }}
          contentClassName="thank-you-content"
        >
          <div className="thank-you-modal">
            <div className="title">Thank You Message</div>
            <QuillTextEditor
              value={this.state.thankYouMessage}
              preserveWhitespace={true}
              theme="snow"
              modules={{
                toolbar: ['bold', 'italic', 'link', 'clean'],
                clipboard: { matchVisual: false },
              }}
              onChange={(content: string) => {
                this.setState({ thankYouMessage: content });
              }}
            />
            <div className="thank-you-actions">
              <Button
                icon="fa fa-save"
                text={'Save'}
                onClick={() => this.saveThankYouMessage()}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
  refundDonation,
  refundEventDonation,
  downloadEventDonationReceipt,
  downloadGroupDonationReceipt,
  updateThankYouMessage,
  sendConfirmationEmail,
  sendTaxReceipt,
  regenerateTaxReceipt,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactDonor);
