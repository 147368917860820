import { Fragment, useEffect, useRef, useState } from 'react';
import './StoryVideo.scss';
import { IStoryComponentProps } from '../../Types/types';
import * as hooks from '../../Hooks/StoryHooks';
import React from 'react';
import { SoundIcon } from '../SoundIcon/SoundIcon';

const key = 'RSIsMute';
const WINDOW: any = typeof window === 'undefined' ? {} : window;
WINDOW?.localStorage?.setItem(key, 'true');

export function StoryVideo(props: IStoryComponentProps) {
  const { isPaused } = hooks.useStoriesContext();
  const { handlePause, handleResume } = hooks.useStoriesContext();
  const [isMuted, setIsMuted] = useState(WINDOW?.localStorage?.getItem(key) === 'true');
  const [showLoader, setShowLoader] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  function setMute(value: boolean) {
    WINDOW?.localStorage?.setItem(key, String(value));
    setIsMuted(value);
  }

  useEffect(() => {
    props.onPause();
    setShowLoader(true);
  }, []);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    if (isPaused && !videoRef.current.paused) {
      videoRef.current.pause();
      return;
    }
    videoRef.current.play().catch(() => {
      setMute(true);
      videoRef.current?.play();
    });
  }, [isPaused]);

  function handleLoad() {
    setTimeout(() => {
      props.onResume();
      setShowLoader(false);
    }, 4);
  }
  return (
    <Fragment>
      <video
        className={'StoryVideo'}
        ref={videoRef}
        playsInline={true}
        controls={false}
        src={props.story.media_url}
        onLoadedData={handleLoad}
        muted={props?.isPreview ? props?.isPreview : isMuted}
        loop
      >
        <source
          src={props.story.media_url}
          type="video/mp4"
        />
        <source
          src={props.story.media_url}
          type="video/webm"
        />
        <source
          src={props.story.media_url}
          type="video/ogg"
        />
        <p>Video not supported</p>
      </video>
      {props.showSoundControls && (
        <div
          className="toggle-playing-state"
          onClick={() => {
            if (isPaused) {
              handleResume();
            } else {
              handlePause();
            }
          }}
        >
          {isPaused ? <i className="fa fa-play" /> : <i className="fa fa-pause" />}
        </div>
      )}
      {props.showSoundControls && (
        <div
          className={'sound-icon'}
          onClick={() => setMute(!isMuted)}
        >
          <SoundIcon type={isMuted ? 'off' : 'on'} />
        </div>
      )}
      {showLoader && (
        <div className={'loader-wrapper'}>
          <div className={'loader'} />
        </div>
      )}
    </Fragment>
  );
}
