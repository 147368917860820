import { Constants } from '@gigit/constants';
import { Link } from 'react-router-dom';
import {
  IDonationMatchingGroupSummary,
  IDonationMatchingProgram,
  IHub,
  ITransactionSummary,
} from '@gigit/interfaces';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { defaultCurrency, downloadFile, formatCurrency, typeHelpers } from '../../../../../helpers';
import useToastDispatcher from '../../../../../hooks/useToaster';
import { IKpi } from '../../../../../interfaces';
import { localizeHelpers } from '../../../../../localizeHelpers';
import { hubRequestActions } from '../../../../../requestActions';
import { userSelectors } from '../../../../../selectors/user';
import { IAppState } from '../../../../../store';
import Button from '../../../../Button/Button';
import Modal from '../../../../Modal/Modal';
import ModalFooterActionContainer from '../../../../Modal/ModalFooterActions/ModalFooterActionContainer';
import ModalScrollContainer from '../../../../Modal/ModalScrollContainer/ModalScrollContainer';
import Table, { ITableProps } from '../../../../shared/Table/Table';
import GenerateDisbursementPackageModal from '../GenerateDisbursementPackageModal/GenerateDisbursementPackageModal';
import HubManagementMetricContainer from '../HubManagementMetricContainer/HubManagementMetricContainer';
import './MatchingProgramSummaryModal.scss';
import { uiConstants } from '../../../../../constants';
import DonationMatchingPaymentModal from '../DonationMatchingPaymentModal/DonationMatchingPaymentModal';
import { CheckoutLocale, loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { Config } from '@gigit/config';
import DefaultLink from '../../../../shared/Link/Link';
import Portrait from '../../../../Portrait/Portrait';
interface IProps {
  hub?: IHub;
  donationMatchingProgram: IDonationMatchingProgram | null;
  showModal: boolean;
  onClose: () => void;
}

interface IGeneratePackageData {
  amount_matched: string;
  number_of_cause: string;
  generate_id: string;
}

interface IPayoutModalData {
  amount_donated: number;
  matched_amount: number;
  group_id: string;
}

function MatchingProgramSummaryModal(props: IProps) {
  const { showModal, onClose, donationMatchingProgram } = props;

  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const currency = props.hub?.account?.currency ?? defaultCurrency;
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const [payoutModalData, setPayoutModalData] = useState<IPayoutModalData | null>(null);
  const [showGenerateDisbursementPackageModal, setShowGenerateDisbursementPackageModal] =
    useState<boolean>(false);
  const [showDonationMatchingPaymentModal, setShowDonationMatchingPaymentModal] =
    useState<boolean>(false);
  const [KPIs, setKPIs] = useState<IKpi[]>([]);
  const [generatePackageData, setGeneratePackageData] = useState<null | IGeneratePackageData>(null);
  const [refreshTableIncrementor, setRefreshTableIncrementor] = useState<number>(0);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const tableProps: ITableProps<IDonationMatchingGroupSummary> = {
    columns: [
      {
        id: 'group_title',
        Header: 'Cause',
        accessor: ({ group }) => group.title,
        sortable: true,
        notranslate: 'yes',
        Cell: ({ group }) => (
          <DefaultLink
            to={`/${uiConstants.ownerType.group}/${group.handle}`}
            className="item-name-col"
            notranslate="yes"
          >
            <div className="name-portrait">
              <Portrait
                size={30}
                className="image-container"
                currentImage={group.profile_image_url}
              />
              <span>{group.title}</span>
            </div>
          </DefaultLink>
        ),
      },
      {
        id: 'currency_dollar_amount',
        Header: 'Amount Donated',
        notranslate: 'yes',
        accessor: ({ amount_donated }) => formatCurrency(amount_donated, currency, locale),
        sortable: true,
      },
      {
        id: 'donation_match.amount_matched',
        Header: 'Matched Amount',
        notranslate: 'yes',
        accessor: ({ amount_matched }) => formatCurrency(amount_matched, currency, locale),
        sortable: true,
      },
      {
        id: 'donation.donation_match.payment_status.code',
        sortable: true,
        Header: 'Status',
        accessor: (donation) => donation.payment_status.code,
        predefinedColumnType: {
          type: 'STATUS',
          columnObject: () => ({
            [Constants.donation_matching_group_payment_status.paid]: {
              label: 'Paid',
              color: 'GREEN',
            },
            [Constants.donation_matching_group_payment_status.unpaid]: {
              label: 'Unpaid',
              color: 'RED',
            },
          }),
        },
      },
    ],
    tableActionOptions: {
      enableRowContextMenuActions:
        donationMatchingProgram?.status.code === 'completed' ? true : false,
      tableActions: [
        // {
        //   type: 'HEADER',
        //   label: 'Generate Package',
        //   icon: 'fal fa-download',
        //   onClick: () => {
        //     setShowGenerateDisbursementPackageModal(true);
        //   },
        //   buttonType: 'outline-dark',
        // },
        {
          type: 'HEADER',
          icon: 'far fa-file-download',
          label: 'Export All',
          buttonType: 'outline-dark',
          onClick: async () => {
            try {
              typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
              typeHelpers.assertNotNullOrUndefined(
                props?.donationMatchingProgram?.id,
                'Expected Program ID',
              );

              const result = await hubRequestActions.exportMatchingProgramSummary(
                props.hub.id,
                props.donationMatchingProgram.id,
              );
              downloadFile('Export_Matching_Program_Summary.csv', result);
            } catch (error) {
              dispatchToastError(error, 'Export Summary Data');
            }
          },
        },
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'Pay Now',
          onClick: (_, row) =>
            handlePayNow({
              group_id: row.group_id,
              matched_amount: row?.amount_matched || 0,
              amount_donated: row?.amount_donated || 0,
            }),
          hideIf: (row) => row?.payment_status.code === 'paid',
        },
      ],
    },
    filterOptions: {
      enableTableSearch: true,
    },
    pagination: {
      pageSizeOptions: [10],
      queryAction: async (queryParams) => {
        try {
          typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Hub Id');
          typeHelpers.assertNotNullOrUndefined(
            `${props.donationMatchingProgram?._id}`,
            'Expected Program Id',
          );

          const donations = await hubRequestActions.getGroupsForDonationMatchingProgram(
            props.hub.id,
            `${props.donationMatchingProgram?._id}`,
            queryParams,
          );
          return donations;
        } catch (error) {
          dispatchToastError(error, 'Get Causes');
          return [];
        }
      },
    },
    emptyStateConfig: {
      title: 'No data',
      description: "We couldn't find any Causes",
    },
  };

  useEffect(() => {
    getKPIs();
  }, []);

  useEffect(() => {
    getKPIs();
  }, [refreshTableIncrementor]);

  function handlePayNow(params: {
    group_id: string;
    matched_amount: number;
    amount_donated: number;
  }) {
    setPayoutModalData(params);
    setShowDonationMatchingPaymentModal(true);
  }

  async function getKPIs() {
    let KPIData: IDonationMatchingProgram | undefined = undefined;
    try {
      typeHelpers.assertNotNullOrUndefined(props.hub?.id, 'Expected Company ID');
      typeHelpers.assertNotNullOrUndefined(donationMatchingProgram?.id, 'Expected Program ID');
      const program = await hubRequestActions.getHubDonationMatchingPrograms(props.hub.id);
      KPIData = program.find((program) => program.id === donationMatchingProgram.id);
      setGeneratePackageData({
        amount_matched: formatCurrency(KPIData?.amount_matched || 0, currency, locale),
        number_of_cause: localizeHelpers.formatNumber(KPIData?.groups.length || 0, locale),
        generate_id: '', //TODO: BE pending
      });
    } catch (error) {
      dispatchToastError(error, 'Get KPIs');
    }

    const convertToKPI: IKpi[] = [
      {
        text: 'Total Amount Raised',
        value: formatCurrency(KPIData?.amount_donated || 0, currency, locale),
      },
      {
        text: 'Total Amount Matched',
        value: formatCurrency(KPIData?.amount_matched || 0, currency, locale),
      },
      {
        text: 'Causes Supported',
        value: localizeHelpers.formatNumber(KPIData?.groups.length || 0, locale),
      },
      {
        text: 'Total No. of Donations',
        value: localizeHelpers.formatNumber(KPIData?.transaction_ids.length || 0, locale),
      },
    ];

    setKPIs(convertToKPI);
  }

  function refreshTableData() {
    setRefreshTableIncrementor((prevValue) => prevValue + 1);
  }

  return (
    <Fragment>
      <Modal
        show={showModal}
        onClose={onClose}
        class="MatchingProgramSummaryModal"
        closeIcon="fas fa-times"
        title={props.donationMatchingProgram?.name}
      >
        <ModalScrollContainer className="MatchingProgramSummaryModal-Container">
          <HubManagementMetricContainer metrics={KPIs} />
          <Table
            {...tableProps}
            refreshTableIncrementor={refreshTableIncrementor}
          />
        </ModalScrollContainer>
        <ModalFooterActionContainer>
          <Button
            buttonType="secondary"
            onClick={onClose}
            text="Cancel"
          />
        </ModalFooterActionContainer>
      </Modal>
      {generatePackageData && (
        <GenerateDisbursementPackageModal
          hub={props.hub}
          amount_matched={generatePackageData.amount_matched}
          number_of_cause={generatePackageData.number_of_cause}
          generate_id={generatePackageData.generate_id}
          showModal={showGenerateDisbursementPackageModal}
          // programId={props.donationMatchingProgram?.id}
          onClose={() => setShowGenerateDisbursementPackageModal(false)}
        />
      )}
      {props.hub && props.donationMatchingProgram !== null && payoutModalData && (
        <DonationMatchingPaymentModal
          hub={props.hub}
          matched_amount={payoutModalData.matched_amount}
          amount_donated={payoutModalData.amount_donated}
          group_id={payoutModalData.group_id}
          program={props.donationMatchingProgram}
          showModal={showDonationMatchingPaymentModal}
          onPayoutFinish={refreshTableData}
          onClose={() => setShowDonationMatchingPaymentModal(false)}
        />
      )}
    </Fragment>
  );
}

export default MatchingProgramSummaryModal;
