import { IDiscoverFilters, IDiscoverItem, IGroup } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import React, { useState } from 'react';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { uiConstants } from '../../../../constants';
import { formatQuery, IQueryParams } from '../../../../helpers';
import { useDebounce } from '../../../../hooks/useDebounce';
import { IGeolocationState } from '../../../../reducers/geolocation';
import { groupRequestActions } from '../../../../requestActions';
import { userRequestActions } from '../../../../requestActions/user';
import { IAppState } from '../../../../store';
import Loader from '../../../Loader/Loader';
import Portrait from '../../../Portrait/Portrait';
import TextField from '../../../TextField/TextField';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../../PopupMenu/PopupMenu';
import { FormSectionHeader } from '../FormSectionHeader/FormSectionHeader';
import NoResults from './../../../../assets/no_results_onboarding.png';

//no_results_onboarding

import './GroupSelection.scss';

interface IProps {
  type: 'event' | 'gig';
  selectedGroup: IGroup | null;
  isOnBehalfOf?: boolean;

  navigate(url: string): void;
  setGroup(group: IGroup): void;
}

interface IDropdownOption {
  value: string;
  label: string;
}

interface ITypeDropdownOptions {
  [index: string]: IDropdownOption;
}

const groupSelectionSubtextEvent = `A cause represents your organization and is the home for all your data and activity. 
    When you run a fundraiser on Kambeo for an organization, the funds go directly to the organization you choose instantly. 
    Select an existing cause or create a new one!`;

const groupSelectionSubTextGig = `When creating a volunteer opportunity on Kambeo you will need to associate it
    with an organization. Select an existing cause or create a new one!`;

export const GroupSelection: React.FC<IProps> = (props: IProps) => {
  const limit = 8;

  const geolocationState: IGeolocationState = useSelector(
    (state: IAppState) => state.geolocationState,
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [groups, setGroups] = useState<IGroup[] | IDiscoverItem[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [prevSearch, setPrevSearch] = useState<string>('');
  const [selectedSort, setSelectedSort] = useState<IDropdownOption>({ value: 'all', label: 'All' });
  const [skip, setSkip] = useState(0);
  const [showContextMenuSort, setShowContextMenuSort] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);

  const searchValue = useDebounce(inputValue);

  const sortOptions: ITypeDropdownOptions = {
    all: { value: 'all', label: 'All' },
    following: { value: 'following', label: 'Following' },
    trending: { value: 'trending', label: 'Trending' },
    myHubs: { value: 'myHub', label: 'My Hubs' },
  };

  const popupMenuConfigSortBy: IShowPopupConfig = {
    showMenu: showContextMenuSort,
    setShowMenu: setShowContextMenuSort,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuSortOptions: IPopupMenuItem[] = [
    {
      id: sortOptions.all.value,
      label: sortOptions.all.label,
      isSelected: selectedSort?.value === sortOptions.all.value,
      onClick: () => {
        setSkip(0);
        setSelectedSort(sortOptions.all);
        setShowContextMenuSort(false);
      },
    },
    {
      id: sortOptions.following.value,
      label: sortOptions.following.label,
      isSelected: selectedSort?.value === sortOptions.following.value,
      onClick: () => {
        setSkip(0);
        setSelectedSort(sortOptions.following);
        setShowContextMenuSort(false);
      },
    },
    {
      id: sortOptions.trending.value,
      label: sortOptions.trending.label,
      isSelected: selectedSort?.value === sortOptions.trending.value,
      onClick: () => {
        setSkip(0);
        setSelectedSort(sortOptions.trending);
        setShowContextMenuSort(false);
      },
    },
    {
      id: sortOptions.myHubs.value,
      label: sortOptions.myHubs.label,
      isSelected: selectedSort?.value === sortOptions.myHubs.value,
      onClick: () => {
        setSkip(0);
        setSelectedSort(sortOptions.myHubs);
        setShowContextMenuSort(false);
      },
    },
  ];

  const getShowMenuClassType = showContextMenuSort ? 'show' : '';

  useEffect(() => {
    setInitialLoading(true);
    getGroupsFiltered(true);
  }, [selectedSort]);

  useEffect(() => {
    let resetSkip = false;
    if (searchValue !== prevSearch) {
      setInitialLoading(true);
      resetSkip = true;
    }

    getGroupsFiltered(resetSkip);
  }, [searchValue]);

  async function getGroupsFiltered(resetSkip?: boolean) {
    const query = {
      limit: limit.toString(),
      skip: skip.toString(),
    } as IQueryParams;

    if (props.isOnBehalfOf) {
      query.filters = [
        {
          id: 'allow_obo_fundraisers',
          label: 'allow_obo_fundraisers',
          type: 'boolean',
          value: 'true',
        },
      ];
    }

    if (resetSkip) {
      query.skip = '0';
    }

    if (searchValue) {
      query.search = searchValue;
    }
    switch (selectedSort.value) {
      case 'all':
        if (props.isOnBehalfOf) {
          getOnBehalfOfGroups(formatQuery(query));
        } else {
          getGroups(formatQuery(query));
        }

        break;
      case 'following':
        const following = await userRequestActions.getUserObjectFollowing(
          uiConstants.ownerType.group,
          formatQuery(query),
        );
        if (resetSkip) {
          setGroups([...(following as IGroup[])]);
        } else {
          setGroups((groups as IGroup[]).concat(following as IGroup[]));
        }

        if (!following.length || following.length < limit) {
          setHasMore(false);
        } else if (following.length == limit) {
          setHasMore(true);
        }

        break;
      case 'trending':
        const defaultCoords = uiConstants.defaultCoords;

        let location: {
          coordinates: number[];
          max_distance: number;
          distance_widen_thresholds: number[];
        } = {
          coordinates: [defaultCoords.lng, defaultCoords.lat],
          max_distance: 99999,
          distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
        };

        let filterData = {
          trending: true,
        };

        if (geolocationState) {
          location = {
            coordinates: [geolocationState.lng, geolocationState.lat],
            max_distance: 99999,
            distance_widen_thresholds: Constants.discover.distance_widen_thresholds,
          };
        }

        let payload = {
          location,
          filter: filterData,
        } as IDiscoverFilters;

        const trendingGroups = await groupRequestActions.discoverGroups(
          payload,
          formatQuery(query),
        );

        if (!trendingGroups.length || trendingGroups.length < limit) {
          setHasMore(false);
        } else if (trendingGroups.length == limit) {
          setHasMore(true);
        }

        if (resetSkip) {
          setGroups([...(trendingGroups as IDiscoverItem[])]);
        } else {
          setGroups((groups as IDiscoverItem[]).concat(trendingGroups as IDiscoverItem[]));
        }

        break;
      case 'myHub':
        const hubGroups = await userRequestActions.getUserHubObjects(
          uiConstants.ownerType.group,
          formatQuery(query),
        );
        if (resetSkip) {
          setGroups([...(hubGroups as IGroup[])]);
        } else {
          setGroups((groups as IGroup[]).concat(hubGroups as IGroup[]));
        }
        if (!hubGroups.length || hubGroups.length < limit) {
          setHasMore(false);
        } else if (hubGroups.length == limit) {
          setHasMore(true);
        }

        break;
    }
    if (resetSkip) {
      setSkip(0);
    } else {
      setSkip(skip + limit);
    }
    setInitialLoading(false);
    setPrevSearch(searchValue);
  }

  async function getGroups(query?: URLSearchParams) {
    let defaultQuery: IQueryParams = {};
    if (!query || !query.has('limit')) {
      defaultQuery = {
        limit: limit.toString(),
        skip: skip.toString(),
      } as IQueryParams;

      if (searchValue) {
        defaultQuery.search = searchValue;
      }

      query = formatQuery(defaultQuery);
    }

    let newGroups = await userRequestActions.getUserGroupsForPermission(
      'MANAGE_GROUP_EVENTS',
      query,
    );
    if (!newGroups.length || newGroups.length < limit) {
      setHasMore(false);
    } else if (newGroups.length == limit) {
      setHasMore(true);
    }
    if (query.get('skip') !== '0') {
      setGroups((groups as IGroup[]).concat(newGroups.reverse()));
    } else {
      setGroups([...newGroups.reverse()]);
    }
    setLoading(false);
  }

  async function getOnBehalfOfGroups(query?: URLSearchParams) {
    let defaultQuery: IQueryParams = {};
    if (!query) {
      defaultQuery = {
        limit: limit.toString(),
        skip: skip.toString(),
      } as IQueryParams;

      if (searchValue) {
        defaultQuery.search = searchValue;
      }

      query = formatQuery(defaultQuery);
    }

    let onBehalfOfEnabledGroups = await groupRequestActions.getOnBehalfOfEnabledGroups(query);
    if (!onBehalfOfEnabledGroups.length || onBehalfOfEnabledGroups.length < limit) {
      setHasMore(false);
    } else if (onBehalfOfEnabledGroups.length == limit) {
      setHasMore(true);
    }
    if (skip && query.get('skip') !== '0') {
      if (searchValue !== prevSearch) {
        setGroups([...onBehalfOfEnabledGroups.reverse()]);
      } else {
        setGroups((groups as IGroup[]).concat(onBehalfOfEnabledGroups.reverse()));
      }
    } else {
      setGroups([...onBehalfOfEnabledGroups.reverse()]);
    }
    setSkip(skip + limit);
    setLoading(false);
  }

  async function setSelectedGroup(group: IGroup | IDiscoverItem) {
    if (selectedSort.value === 'trending') {
      let groupData = await groupRequestActions.getGroupByHandleOrId(
        (group as IDiscoverItem).handle,
      );
      props.setGroup(groupData);
    } else {
      props.setGroup(group as IGroup);
    }
  }

  return (
    <div className="GroupSelection">
      <FormSectionHeader
        text="Select Cause"
        subtext={props.type === 'event' ? groupSelectionSubtextEvent : groupSelectionSubTextGig}
      />
      {props.isOnBehalfOf && <div className="search-groups"></div>}
      <div id="scrollableDiv">
        <div className="filter-wrapper">
          <TextField
            placeholder="Search for a Charity or Non-Profit"
            value={inputValue}
            name="name"
            type="text"
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
          <PopupMenu
            menuItems={contextMenuSortOptions}
            showMenuConfig={popupMenuConfigSortBy}
            popupMenuClass="applications-popupMenu-sort"
            className={`Dropdown filter-dropdown sort ${getShowMenuClassType}`}
            onClick={() => setShowContextMenuSort(!showContextMenuSort)}
          >
            <label>
              <span>Filter</span>
            </label>
            <div className="input-wrap">
              <span className="label">{selectedSort.label}</span>
              <i className={`fas fa-caret-down menu-btn ${getShowMenuClassType}`} />
            </div>
          </PopupMenu>
        </div>
        {groups.length && !initialLoading ? (
          <InfiniteScroll
            dataLength={groups.length}
            next={async () => {
              if (props.isOnBehalfOf) {
                await getOnBehalfOfGroups();
              } else {
                await getGroupsFiltered();
              }
            }}
            loader={
              <p style={{ textAlign: 'center' }}>
                <b>Loading...</b>
              </p>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>No more results.</b>
              </p>
            }
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <ul>
              {groups.map((group) => {
                return (
                  <div
                    className={
                      props.selectedGroup?.id === group.id ? 'selected list-item' : 'list-item'
                    }
                    key={group.id}
                    onClick={() => setSelectedGroup(group)}
                  >
                    <Portrait
                      source={group?.profile_image_url}
                      size={40}
                    />
                    <div className="title-section">
                      <div
                        notranslate="yes"
                        className="title"
                      >
                        {group.title}
                      </div>
                      <div className="charity">
                        Charity ID:{' '}
                        <span notranslate="yes">{group.charity_id ? group.charity_id : 'n/a'}</span>
                      </div>
                    </div>
                    <div className="item-hub-list">
                      {group.partner_hubs &&
                        group.partner_hubs.map((hub, index) => {
                          if (index < 3) {
                            return (
                              <div
                                key={hub.id}
                                title={hub.title}
                                className="hub-image"
                              >
                                <Portrait
                                  source={hub?.profile_image_url}
                                  size={30}
                                />
                              </div>
                            );
                          }
                        })}
                      {group.partner_hubs && group.partner_hubs.length > 4 && (
                        <div className="hub-counter">+{group.partner_hubs.length - 4}</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </ul>
          </InfiniteScroll>
        ) : (
          <div className="no-results">
            <img src={NoResults} />
            <div className="no-result-message">We didn't find any results</div>
            <Link
              className="no-result-link"
              to="/onboarding/group/charitiesSelectionGroup"
            >
              Create a New Cause
            </Link>
          </div>
        )}
      </div>
      {loading && <Loader loading={loading} />}
    </div>
  );
};
