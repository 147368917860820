import React from 'react';

import './CommunityFeedAsideCardSkeleton.scss';

export default function CommunityFeedAsideCardSkeleton() {
  return (
    <div className="CommunityFeedAsideCardSkeleton">
      <div className="card-title"></div>
      <div className="card-content">
        <div className="card-img"></div>
        <div className="card-content-detail"></div>
      </div>
      <div className="card-content">
        <div className="card-img"></div>
        <div className="card-content-detail"></div>
      </div>
      <div className="card-button"></div>
    </div>
  );
}
