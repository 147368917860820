import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  defaultCurrency,
  handleInputChange,
  formatCurrency,
  capitalizeString,
  toastError,
  errorHelpers,
  appendFilterToUrlParmas,
} from '../../helpers';
import { IGroupState } from '../../reducers/group';
import { IEventState } from '../../reducers/event';
import {
  refundPurchase,
  downloadGroupPurchaseReceipt,
  getTransactionItems,
  getPurchaseExport,
  updateTransactionPayment,
  getPurchaseExportDaily,
} from '../../actions/group';
import {
  getEventTransactionItems,
  refundEventPurchase,
  downloadEventPurchaseReceipt,
  getPurchaseExportEventDaily,
  getPurchaseExportEvent,
} from '../../actions/event';
import { createToast } from '../../actions/toaster';
import Modal from '../Modal/Modal';
import ModalHeader from '../Modal/ModalHeader/ModalHeader';
import Button from '../Button/Button';
import TextField from '../../components/TextField/TextField';
import Dropdown, { IOptions } from '../../components/Dropdown/Dropdown';
import './StorePurchases.scss';
import { IActiveFilter, IOwnerObject, ReduxActionType } from '../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import { ITransactionSummary } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { uiConstants } from '../../constants';
import Table, { ITableProps } from '../shared/Table/Table';
import { eventRequestActions, groupRequestActions } from '../../requestActions';
import StorePurchasesListItemDetails from './StorePurchasesListItemDetails/StorePurchasesListItemDetails';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  eventState: IEventState;
  getPurchaseExportDaily: ReduxActionType<typeof getPurchaseExportDaily>;
  getPurchaseExportEventDaily: ReduxActionType<typeof getPurchaseExportEventDaily>;
  getPurchaseExportEvent: ReduxActionType<typeof getPurchaseExportEvent>;
  refundPurchase: ReduxActionType<typeof refundPurchase>;
  refundEventPurchase: ReduxActionType<typeof refundEventPurchase>;
  updateTransactionPayment: ReduxActionType<typeof updateTransactionPayment>;
  downloadGroupPurchaseReceipt: ReduxActionType<typeof downloadGroupPurchaseReceipt>;
  downloadEventPurchaseReceipt: ReduxActionType<typeof downloadEventPurchaseReceipt>;
  getTransactionItems: ReduxActionType<typeof getTransactionItems>;
  getEventTransactionItems: ReduxActionType<typeof getEventTransactionItems>;
  getPurchaseExport: ReduxActionType<typeof getPurchaseExport>;
  createToast: ReduxActionType<typeof createToast>;
  owner: IOwnerObject;
  locale: string;
}

interface IState {
  showRefundModal: boolean;
  refund_amount: number;
  order: any;
  selectedPurchase: string;
  showFormModal: boolean;
  selectedPurchaseItem: any;
  showSelectPaymentModal: boolean;
  selectedPaymentMethod: string;
  purchasesTableConfig: ITableProps<ITransactionSummary>;
  purchasesRefreshTableIncrementor: number;
}

class StorePurchasesList extends React.Component<IProps, IState> {
  readonly PAYMENT_METHODS = [
    { label: 'Cash', value: 'cash' },
    { label: 'Cheque ', value: 'cheque' },
    { label: 'E-Transfer', value: 'e-transfer' },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      showRefundModal: false,
      refund_amount: 0,
      order: {},
      selectedPurchase: '',
      showFormModal: false,
      selectedPurchaseItem: null,
      showSelectPaymentModal: false,
      selectedPaymentMethod: this.PAYMENT_METHODS[0].value,
      purchasesRefreshTableIncrementor: 0,
      purchasesTableConfig: {
        columns: [
          {
            sortable: true,
            Header: 'Purchaser Name',
            id: 'user.full_name',
            Cell: (purchase) => (
              <span notranslate="yes">
                {purchase.user?.full_name || purchase.user?.display_name}
              </span>
            ),
            accessor: (purchase) => purchase.user?.full_name || purchase.user?.display_name,
          },
          {
            sortable: true,
            Header: 'Transaction #',
            id: 'transaction_number',
            Cell: (purchase) => <span notranslate="yes">{purchase.transaction_number}</span>,
            accessor: (purchase) => purchase.transaction_number,
          },
          {
            sortable: true,
            Header: '# of Items',
            id: 'number_of_items',
            Cell: (purchase) => (
              <span notranslate="yes">
                {localizeHelpers.formatNumber(purchase.number_of_items || 0, this.props.locale)}
              </span>
            ),
            accessor: (purchase) => purchase.number_of_items,
          },
          {
            sortable: true,
            Header: 'Type',
            id: 'amounts.payment_method',
            Cell: (purchase) => <span>{purchase.amounts.payment_method}</span>,
            accessor: (purchase) => purchase.amounts.payment_method,
          },
          {
            sortable: true,
            Header: 'Payment Status',
            id: 'amounts.payment_status',
            predefinedColumnType: {
              type: 'STATUS',
              columnObject: () => ({
                refunded: {
                  label: 'Refunded',
                  color: 'RED',
                },
                unpaid: {
                  label: 'Unpaid',
                  color: 'GREY',
                },
                pending: {
                  label: 'Pending',
                  color: 'GREY',
                },
                paid: {
                  label: 'Paid',
                  color: 'GREEN',
                },
              }),
            },
            Cell: (purchase) => (
              <span className={this.getPurchaseStatus(purchase)}>
                {capitalizeString(this.getPurchaseStatus(purchase))}
              </span>
            ),
            accessor: (purchase) => purchase.amounts.payment_status?.code,
          },
          {
            sortable: true,
            Header: 'Order Total',
            id: 'amounts.total',
            Cell: (purchase) => {
              const exchangeRate = purchase.reporting_exchange_rate ?? 1;
              const currency = purchase.reporting_currency ?? defaultCurrency;
              const amount =
                purchase.amounts.total * purchase.amounts.amount_multiplier! * exchangeRate;
              return (
                <span notranslate="yes">{formatCurrency(amount, currency, this.props.locale)}</span>
              );
            },
            accessor: (purchase) => purchase.amounts.total,
          },
          {
            sortable: true,
            Header: 'Date',
            id: 'created_at',
            Cell: (purchase) => (
              <span notranslate="yes">
                {purchase.created_at
                  ? localizeHelpers.formatDate(
                      purchase.created_at,
                      LocaleDateFormats.LLL,
                      this.props.locale,
                    )
                  : ''}
              </span>
            ),
            accessor: (purchase) => purchase.created_at?.toString(),
          },
        ],
        filterOptions: {
          enableColumnSorting: true,
          enableTableSearch: true,
          enableFilterDrawer: true,
          filterDrawerOptions: [
            {
              fieldId: 'user.full_name',
              type: 'textField',
              options: {
                label: 'Purchaser Name',
              },
            },
            {
              fieldId: 'transaction_number',
              options: {
                label: 'Transaction #',
              },
              type: 'textField',
            },
            {
              fieldId: 'number_of_items',
              options: {
                label: '# of Items',
              },
              type: 'textField',
            },
            {
              fieldId: 'amounts.total',
              options: {
                label: 'Order Total',
              },
              type: 'textField',
            },
          ],
        },
        emptyStateConfig: {
          title: 'No data',
          description: "We couldn't find any purchases",
        },
        pagination: {
          pageSizeOptions: [+uiConstants.adminListRows],
          queryAction: async (params) => await this.fetchStorePurchases(params),
        },
        tableActionOptions: {
          enableRowContextMenuActions: true,
          tableActions: [
            {
              icon: 'far fa-file-download',
              onClick: () => this.exportCSV(),
              label: 'Export',
              buttonType: 'outline-dark',
              type: 'HEADER',
            },
            {
              icon: 'far fa-file-download',
              onClick: () => this.exportDailyCSV(),
              label: 'Daily Sales Report',
              buttonType: 'outline-dark',
              type: 'HEADER',
            },
            {
              type: 'ROW_CONTEXT_MENU',
              icon: 'far fa-dollar-sign',
              onClick: (e, purchase) => {
                if (this.props.owner.ownerType === uiConstants.ownerType.group) {
                  this.props.downloadGroupPurchaseReceipt(this.props.owner.ownerId, purchase.id!);
                } else if (this.props.owner.ownerType === uiConstants.ownerType.event) {
                  this.props.downloadEventPurchaseReceipt(
                    this.props.eventState.event.id,
                    purchase.id!,
                  );
                }
              },
              label: 'View Statement',
            },
            {
              type: 'ROW_CONTEXT_MENU',
              icon: 'far fa-dollar-sign',
              onClick: (e, purchase) => this.initRefund(purchase),
              label: 'Refund',
              hideIf: (purchase) =>
                !!purchase.amounts.is_refund ||
                !(
                  !purchase.amounts?.refunds?.length && this.getPurchaseStatus(purchase) === 'paid'
                ),
            },
            {
              type: 'ROW_CONTEXT_MENU',
              icon: 'far fa-ballot-check',
              onClick: (e, purchase) => this.markAsPaid(purchase),
              label: 'Mark as Paid',
              hideIf: (purchase) =>
                !!purchase.amounts.is_refund || !(this.getPurchaseStatus(purchase) === 'unpaid'),
            },
          ],
          rowDetailDrawerOptions: (purchase: ITransactionSummary) => ({
            title: '',
            renderContent: () => (
              <StorePurchasesListItemDetails
                ownerId={this.props.owner.ownerId}
                ownerType={this.props.owner.ownerType}
                noForm={() => this.noForm()}
                setPurchaseItem={(item) => this.setPurchaseItem(item)}
                purchase={purchase}
              />
            ),
          }),
        },
      },
    };

    this.toggleRefundModal = this.toggleRefundModal.bind(this);
    this.getPurchaseStatus = this.getPurchaseStatus.bind(this);
    this.markAsPaid = this.markAsPaid.bind(this);
    this.updateTransactionPayment = this.updateTransactionPayment.bind(this);
  }

  async fetchStorePurchases(query: URLSearchParams) {
    let data = [] as ITransactionSummary[];

    try {
      if (this.props.owner.ownerType === uiConstants.ownerType.group) {
        data = await groupRequestActions.getGroupPurchases(this.props.owner.ownerId, query);
      } else {
        data = await eventRequestActions.getEventPurchases(this.props.owner.ownerId, query);
      }
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errObj.translatedMessage, 'Get Purchases');
      this.props.createToast(toast);
    }

    return data;
  }

  initRefund(purchase: any) {
    this.setOrder(purchase);
  }

  refund() {
    let groupTitle = '',
      id = '';

    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      groupTitle = this.props.owner.ownerHandle;
      id = this.props.owner.ownerId;
    } else if (this.props.owner.ownerType === uiConstants.ownerType.event) {
      id = this.props.owner.ownerId;
    }
    const _payload = {
      amount: this.state.refund_amount,
      group_title: groupTitle,
    };

    if (typeof _payload.amount === 'string') {
      _payload.amount = parseFloat(_payload.amount);
    }

    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      this.props.refundPurchase(id, this.state.order.id, _payload);
    } else if (this.props.owner.ownerType === uiConstants.ownerType.event) {
      this.props.refundEventPurchase(id, this.state.order.id, _payload);
    }

    this.toggleRefundModal(false);
    this.setState({
      refund_amount: 0,
      purchasesRefreshTableIncrementor: this.state.purchasesRefreshTableIncrementor + 1,
    });
  }

  toggleRefundModal(value: boolean) {
    this.setState({
      showRefundModal: value,
    });
  }

  setOrder(_order: any) {
    this.setState(
      {
        order: _order,
      },
      () => {
        this.toggleRefundModal(true);
      },
    );
  }

  toggleFormModal(value: boolean) {
    this.setState({
      showFormModal: value,
    });
  }

  setPurchaseItem(item: any) {
    this.setState(
      {
        selectedPurchaseItem: item,
      },
      () => {
        this.toggleFormModal(true);
      },
    );
  }

  exportCSV() {
    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      this.props.getPurchaseExport(
        this.props.groupState.group.id,
        this.props.groupState.group.handle,
      );
    } else if (this.props.owner.ownerType === uiConstants.ownerType.event) {
      this.props.getPurchaseExportEvent(
        this.props.eventState.event.id,
        this.props.eventState.event.handle,
      );
    }
  }

  exportDailyCSV() {
    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      this.props.getPurchaseExportDaily(
        this.props.groupState.group.id,
        this.props.groupState.group.handle,
      );
    } else if (this.props.owner.ownerType === uiConstants.ownerType.event) {
      this.props.getPurchaseExportEventDaily(
        this.props.eventState.event.id,
        this.props.eventState.event.handle,
      );
    }
  }

  noForm() {
    const toast = toastError(
      localizeHelpers.translate(`This store item does not have an associated form.`),
      'No Form',
    );
    this.props.createToast(toast);
  }

  getPurchaseStatus(purchase: any): string {
    if (purchase.amounts.is_refund) {
      return 'refunded';
    }

    if (purchase.amounts.payment_status) {
      return purchase.amounts.payment_status.code;
    }

    if (purchase.amounts.payment_method === 'card') {
      return 'paid';
    }

    return 'unpaid';
  }

  markAsPaid(purchase: any) {
    this.setState({
      order: purchase,
      showSelectPaymentModal: true,
      purchasesRefreshTableIncrementor: this.state.purchasesRefreshTableIncrementor + 1,
    });
  }

  updateTransactionPayment() {
    const transactionId = this.state.order.id;
    const groupId = this.state.order.group_id;
    this.props.updateTransactionPayment(
      groupId,
      transactionId,
      { paymentMethod: this.state.selectedPaymentMethod },
      () => {
        this.setState({
          purchasesRefreshTableIncrementor: this.state.purchasesRefreshTableIncrementor + 1,
        });
      },
    );
  }

  render() {
    return (
      <div className="StorePurchases-list">
        {/*<Button icon="far fa-file-download" onClick={() => this.exportCSV() } text={"Export"}/>*/}
        <div className="list">
          <div className="list-inner">
            <div className="list-rows">
              <Table
                {...this.state.purchasesTableConfig}
                refreshTableIncrementor={this.state.purchasesRefreshTableIncrementor}
              />
            </div>
          </div>
        </div>

        {this.state.order.id && this.renderRefundModal()}

        <Modal
          onClose={() => {
            this.toggleFormModal(false);
          }}
          show={this.state.showFormModal}
          title={this.state.selectedPurchaseItem && this.state.selectedPurchaseItem.name}
        >
          <div className="purchase-item-form">
            {this.state.selectedPurchaseItem &&
              this.state.selectedPurchaseItem.form.map((form: any, i: any) => {
                switch (form.question_type) {
                  case 'text':
                    return (
                      <div
                        className="question"
                        key={i}
                      >
                        <div className="label">{form.question_label}</div>
                        <div className="answer">{form.answer}</div>
                      </div>
                    );
                  case 'dropdown':
                    let _options: IOptions[] = [];

                    for (let i in form.options) {
                      //@ts-ignore
                      _options.push({
                        label: form.options[i].question_label,
                        value: form.options[i].question_label,
                      });
                    }

                    return (
                      <div
                        className="question"
                        key={i}
                      >
                        <div className="label">{form.question_label}</div>
                        <div className="answer">{form.answer}</div>
                      </div>
                    );
                  default:
                    return (
                      <div
                        className="question"
                        key={i}
                      >
                        <div className="label">{form.question_label}</div>
                        <div className="answer">{form.answer}</div>
                      </div>
                    );
                }
              })}
          </div>
        </Modal>

        <Modal
          show={this.state.showSelectPaymentModal}
          onClose={() => {
            this.setState({ showSelectPaymentModal: false });
          }}
        >
          <ModalHeader
            className="select-payment-header"
            title="Mark as Paid"
            onClose={() => {}}
          />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({ showSelectPaymentModal: false });
              this.updateTransactionPayment();
            }}
          >
            <div className="select-payment">
              <div className="payment-info">Payment Information</div>
              <Dropdown
                value={this.state.selectedPaymentMethod}
                label="Type of payment"
                onChange={(e) => {
                  this.setState({ selectedPaymentMethod: e.target.value });
                }}
                name="purchase-method"
                options={this.PAYMENT_METHODS}
              />
            </div>

            <div className="controls">
              <Button
                buttonClass="cancel-button"
                type="button"
                onClick={(e) => {
                  this.setState({ showSelectPaymentModal: false });
                }}
                text="Cancel"
              />
              <Button
                buttonClass="save-button"
                type="submit"
                text="Save"
              />
            </div>
          </form>
        </Modal>
      </div>
    );
  }

  renderRefundModal() {
    const exchangeRate = this.state.order.reporting_exchange_rate ?? 1;
    const currency = this.state.order.reporting_currency ?? defaultCurrency;
    const total = (this.state.order.amounts.total / 100) * exchangeRate;

    return (
      <Modal
        onClose={() => {
          this.toggleRefundModal(false);
        }}
        show={this.state.showRefundModal}
        title="Refund Order"
        description="A copy of the reciept will be emailed to the customer."
      >
        <div className="refund">
          <div className="refund-title"></div>
          <div className="refund-info">
            <div className="sub-header">Order Summary</div>
            <div className="info">
              <div className="info-part">
                <span>Name: </span>
                <var data-var="display_name">{this.state.order.user.display_name}</var>
              </div>
              <div className="info-part">
                <span>Transaction type:</span>{' '}
                <var data-var="transaction_type">
                  {this.state.order && this.state.order.transaction_type}
                </var>
              </div>
              <div className="info-part">
                <span>Transaction #:</span>{' '}
                <var data-var="transaction_number">
                  {this.state.order && this.state.order.transaction_number}
                </var>
              </div>
              <div className="info-part">
                <span>Amount Charged:</span>{' '}
                <var data-var="amount_charged">
                  {formatCurrency(total, currency, this.props.locale)}
                </var>
              </div>
            </div>
            <div className="sub-header">Refund Details:</div>
            <div className="refund-details">
              <TextField
                label="Refund Amount"
                required={true}
                value={this.state.refund_amount}
                name="refund_amount"
                type="number"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="refund-actions">
              <Button
                onClick={() => this.refund()}
                text="Refund"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  refundPurchase,
  updateTransactionPayment,
  downloadGroupPurchaseReceipt,
  getTransactionItems,
  getEventTransactionItems,
  getPurchaseExport,
  createToast,
  refundEventPurchase,
  downloadEventPurchaseReceipt,
  getPurchaseExportDaily,
  getPurchaseExportEventDaily,
  getPurchaseExportEvent,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StorePurchasesList));
