import React, { useEffect } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { localizeHelpers } from '../../../../localizeHelpers';

import './PageComponentPaginator.scss';

interface IProps<TItem> {
  itemsPerPage: number;
  items: TItem[];

  simple?: boolean;
  triggerNext?(): void;
  triggerPrev?(): void;
  droppableItem?: {
    droppableType: string;
    onPageDrop(pageNumber: number | null): void;
  };
  setCurrentPage?(index: number): void;

  /** This function will be passed the current page items that should be rendered.
   *
   * @param pageIndexOffset is an offset from the current page.
   * @param formatCurrentPageInfo display string to keep track of the current page. Ex - 1-8 of 72
   */
  children: (
    itemsToRender: TItem[],
    pageIndexOffset: number,
    formatCurrentPageInfo: string,
  ) => React.ReactNode;
  // hacky fix to support pagination https://app.clickup.com/t/8404472/GIG-6167
  showNext?: boolean;
  showPrev?: boolean;
}

interface IState {
  currentPageIndex: number;
  numberOfPages: number;
}

/** Component that implements and renders a pagination for a Page Component list.
 * This component uses .children as render props and renders the actual paginator underneath.
 */
class PageComponentPaginator<TItem> extends React.Component<IProps<TItem>, IState> {
  constructor(props: IProps<TItem>) {
    super(props);

    this.state = {
      currentPageIndex: 0,
      numberOfPages: 0,
    };
  }

  componentDidMount() {
    this.calculateNumberOfPages();
  }

  componentDidUpdate(prevProps: IProps<TItem>, prevState: IState) {
    if (prevProps.items !== this.props.items) {
      this.calculateNumberOfPages();
    }

    if (prevState.currentPageIndex !== this.state.currentPageIndex && this.props.setCurrentPage) {
      this.props.setCurrentPage(this.state.currentPageIndex);
    }
  }

  calculateNumberOfPages() {
    // BUGFIX: GIG-5529 Avoid dividing 0 by 0
    let numberOfPages =
      this.props.items.length && this.props.itemsPerPage
        ? Math.floor(this.props.items.length / this.props.itemsPerPage)
        : 0;
    if (this.props.items.length % this.props.itemsPerPage !== 0) {
      numberOfPages++;
    }

    if (numberOfPages === 0) {
      numberOfPages++;
    }

    this.setState({
      numberOfPages: numberOfPages,
      currentPageIndex: Math.min(this.state.currentPageIndex, numberOfPages - 1),
    });
  }

  renderPageIndicators(isDraggedOver?: boolean): React.ReactNode[] {
    const indicatorElements: React.ReactNode[] = [];

    const currentPageIndex = this.state.currentPageIndex;
    const numberOfPages = this.state.numberOfPages;
    const currentPageNumber = currentPageIndex + 1;

    // Create base indicators.
    let visibleIndicators: number[] = [];
    visibleIndicators.push(currentPageNumber);

    visibleIndicators.unshift(currentPageNumber - 1);
    visibleIndicators.unshift(currentPageNumber - 2);

    visibleIndicators.push(currentPageNumber + 1);
    visibleIndicators.push(currentPageNumber + 2);

    // When we're at either ends, insert an extra indicator on other side, looks better visually.
    if (currentPageNumber === numberOfPages) {
      visibleIndicators.unshift(currentPageNumber - 3);
    }

    if (currentPageNumber === 1) {
      visibleIndicators.push(currentPageNumber + 3);
    }

    // If the left-most/right-most indicator isn't at the end, we move it to the end.
    if (visibleIndicators[0] > 1) {
      visibleIndicators[0] = 1;
    }

    if (visibleIndicators[visibleIndicators.length - 1] < numberOfPages) {
      visibleIndicators[visibleIndicators.length - 1] = numberOfPages;
    }

    // Render indicators.
    for (let index = 0; index < visibleIndicators.length; index++) {
      let indicatorNum = visibleIndicators[index];

      if (indicatorNum >= 1 && indicatorNum <= numberOfPages) {
        // Add separator last indicator isn't in sequence.
        if (
          index === visibleIndicators.length - 1 &&
          visibleIndicators[index - 1] !== indicatorNum - 1
        ) {
          indicatorElements.push(
            <div
              key="separator-before"
              className="separator"
            >
              ...
            </div>,
          );
        }

        indicatorElements.push(this.renderIndicator(indicatorNum, isDraggedOver));

        // Add separator first indicator isn't in sequence.
        if (index === 0 && visibleIndicators[index + 1] !== indicatorNum + 1) {
          indicatorElements.push(
            <div
              key="separator-after"
              className="separator"
            >
              ...
            </div>,
          );
        }
      }
    }

    return indicatorElements;
  }

  renderIndicator(pageNumber: number, isDraggedOver?: boolean) {
    return (
      <div
        key={pageNumber}
        onClick={() => this.setState({ currentPageIndex: pageNumber - 1 })}
        onMouseEnter={() => {
          this.props.droppableItem?.onPageDrop(isDraggedOver ? pageNumber : null);
        }}
        className={`page-indicator ${pageNumber - 1 === this.state.currentPageIndex ? 'current' : ''}`}
      >
        {pageNumber}
      </div>
    );
  }

  renderCurrentPage() {
    const pageStartIndex = this.state.currentPageIndex * this.props.itemsPerPage;
    const pageEndIndex = Math.min(
      pageStartIndex + this.props.itemsPerPage,
      this.props.items.length,
    );

    const itemsToRender =
      this.props.itemsPerPage > 0
        ? this.props.items.slice(pageStartIndex, pageEndIndex)
        : this.props.items;
    const currentPageString = localizeHelpers.translate(
      `{{start_index}}-{{end_index}} of {{page_count}}`,
      {
        start_index: pageStartIndex + 1,
        end_index: pageStartIndex + itemsToRender.length,
        page_count: this.props.items.length,
      },
    );

    return this.props.children(itemsToRender, pageStartIndex, currentPageString);
  }

  changePage(newPageIndex: number) {
    this.setState({
      currentPageIndex: Math.min(Math.max(newPageIndex, 0), this.state.numberOfPages - 1),
    });
  }

  //TODO: Maybe it make sence to split PageComponentPaginator into 2 components
  //
  //Paginator: A view only component that renders the paginator. Takes props for number of pages and current page.
  //PaginatorController: A component that manages the paging state. Would take itemsPerPage and items. Should take a render prop as children that returns the items to render, and a setter for the current page.

  render() {
    const hasDroppableItem = this.props.droppableItem != null;
    return (
      <div className="PageComponentPaginatorContainer">
        <div className="page-container">{this.renderCurrentPage()}</div>

        {this.props.simple && (
          <div className="PageComponentPaginator simple">
            <button
              className="arrow-button left fal fa-arrow-left fa-5x text-success"
              onClick={() => (this.props.triggerPrev ? this.props.triggerPrev() : null)}
            />
            <button
              className="arrow-button right fal fa-arrow-right fa-5x text-success"
              onClick={() => (this.props.triggerNext ? this.props.triggerNext() : null)}
            />
          </div>
        )}

        {this.props.itemsPerPage !== 0 &&
          this.props.items.length > 0 &&
          hasDroppableItem &&
          !this.props.simple && (
            <Droppable
              type={this.props.droppableItem?.droppableType}
              droppableId={`${this.props.droppableItem?.droppableType}-paginator`}
            >
              {(provided, snapshot) => {
                return (
                  <div
                    className="PageComponentPaginator"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <button
                      className="arrow-button left fal fa-arrow-left fa-5x text-success"
                      onClick={() => this.changePage(this.state.currentPageIndex - 1)}
                    />

                    <div className="page-indicator-container">
                      {this.renderPageIndicators(snapshot.isDraggingOver)}
                    </div>

                    <button
                      className="arrow-button right fal fa-arrow-right fa-5x text-success"
                      onClick={() => this.changePage(this.state.currentPageIndex + 1)}
                    />
                  </div>
                );
              }}
            </Droppable>
          )}

        {/* inject trigger next and trigger previous here? */}
        {this.props.itemsPerPage !== 0 &&
          this.props.items.length > 0 &&
          !hasDroppableItem &&
          !this.props.simple && (
            <div className="PageComponentPaginator">
              <button
                className="arrow-button left fal fa-arrow-left fa-5x text-success"
                onClick={() => this.changePage(this.state.currentPageIndex - 1)}
              />

              <div className="page-indicator-container">{this.renderPageIndicators()}</div>

              <button
                className="arrow-button right fal fa-arrow-right fa-5x text-success"
                onClick={() => this.changePage(this.state.currentPageIndex + 1)}
              />
            </div>
          )}
      </div>
    );
  }
}

export default PageComponentPaginator;
