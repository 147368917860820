import { IStory, IStoryCreateParamsFE, IStoryResultFE } from '@gigit/interfaces';
import axios from 'axios';
import { routes, swapRouteParams } from '../helpers';

export namespace storyRequestActions {
  export async function createStory(payload: IStoryCreateParamsFE) {
    const response = await axios.post<IStory>(
      swapRouteParams(routes.CREATE_STORY, {
        entity_type: payload.owner_type,
        id: payload.owner_id,
      }),
      payload,
    );
    return response.data;
  }

  export async function deleteStory(entityType: string, entityId: string, storyId: string) {
    const response = await axios.delete(
      swapRouteParams(routes.DELETE_STORY, {
        entity_type: entityType,
        entity_id: entityId,
        storyid: storyId,
      }),
    );
    return response.data;
  }

  export async function getStoryForEntity(
    entityType: string,
    entityId: string,
    query?: URLSearchParams,
  ) {
    const response = await axios.get<IStoryResultFE>(
      swapRouteParams(routes.GET_STORIES_FOR_ENTITY, { entityType, entityId }),
      { params: query },
    );
    return response.data;
  }
}
