import React from 'react';

import './DetailedToggle.scss';

// TODO: Combine with Dropdown IOptions
export interface IToggleOptions {
  label: string;
  value: boolean;
}

interface IProps {
  label: string;
  currentValue: boolean | null;
  onChange: (e: { newValue: boolean }) => void;
  yesMessage: string;
  noMessage: string;
  required?: boolean;
  error?: string;
}

interface IState {}

/** A selection of radio buttons. Used when a detailed yes/no readio combination is needed. */
export class DetailedToggle extends React.Component<IProps, IState> {
  render() {
    const options: IToggleOptions[] = [
      {
        label: this.props.yesMessage,
        value: true,
      },
      {
        label: this.props.noMessage,
        value: false,
      },
    ];
    return (
      <div className="DetailedToggle">
        <label className="label">
          {this.props.label}
          {this.props.required && <span className="required">*</span>}
        </label>
        {options.map((option, index) => {
          return (
            <div
              key={index}
              className="option"
              onClick={() => this.props.onChange({ newValue: option.value })}
            >
              <i
                className={
                  this.props.currentValue === option.value
                    ? 'far fa-check-circle selected'
                    : 'far fa-circle'
                }
              />
              <label>{option.label}</label>
            </div>
          );
        })}
        {this.props.error && <span className="error_text">{this.props.error}</span>}
      </div>
    );
  }
}
