import { IChildComponent } from '../ForOrganizations';
import HostEvent from '../../../assets/for_organizations_host_event.png';
import ManageDonations from '../../../assets/for_organizations_manage_donations.png';
import BoostFundraising from '../../../assets/for_organizations_boost_fundraising.png';
import ManageVolunteers from '../../../assets/for_organizations_manage_volunteers.png';
import Main from '../../../assets/for_organizations_businesses_main.png';
import MainMobile from '../../../assets/for_organizations_businesses_main_mobile.png';
import SellMerchandise2 from '../../../assets/for_organizations_sell_merchandise2.png';
import RunOnlineAuctions from '../../../assets/for_organizations_run_online_auctions.png';

export const Businesses = {
  keyValue: 'businesses',
  menu_title: 'Businesses',
  main_title: 'Kambeo for Businesses',
  main_subtitle: 'Encourage community involvement & earn recognition',
  main_image: {
    src: Main,
  },
  main_image_mobile: {
    src: MainMobile,
    alt: 'Main image businesses',
  },
  cards: [
    {
      image: {
        src: HostEvent,
      },
      link: '/onboarding/event',
      title: 'Host Events',
    },
    {
      image: {
        src: ManageDonations,
      },
      link: '/setup/donations',
      title: 'Manage Donations',
    },
    {
      image: {
        src: BoostFundraising,
      },
      link: '/onboarding/group',
      title: 'Boost Fundraising',
    },
    {
      image: {
        src: ManageVolunteers,
      },
      link: '/onboarding/gig',
      title: 'Manage Volunteers',
    },
    {
      image: {
        src: SellMerchandise2,
      },
      link: '/onboarding/group',
      title: 'Sell Merchandise',
    },
    {
      image: {
        src: RunOnlineAuctions,
      },
      link: '/onboarding/event?type=online_auction',
      title: 'Run Online Auctions',
    },
  ],
  collapseItems: [
    {
      title: 'Request a Demo',
      description: 'Kambeo helps organizations raise funds from their community.',
      additionalLink: {
        href: '/book-a-demo/',
        title: 'Contact Us',
        linkNewTab: true,
      },
    },
    {
      title: 'Reach more people!',
      description:
        'With Kambeo’s online tools, you can easily create and share a number of different events to your current marketing channels to help raise awareness and money for your causes.',
    },
    {
      title: 'Save your time!',
      description:
        'By having one centralized hub to create fundraisers, run an auction, manage volunteers, and collect donations, Kambeo eliminates the need of juggling multiple software.',
    },
    {
      title: 'Eliminate your risk!',
      description:
        'Completely free to get started with no monthly fee, Kambeo lets you create what you need immediately so you can start raising awareness and money for the causes that matter to you.',
    },
  ],
} as IChildComponent;
