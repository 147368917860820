import React from 'react';
import { connect } from 'react-redux';
import { localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import { IAppState } from '../../store';
import { IGroupState } from '../../reducers/group';
import {
  getGroup,
  getGroupCampaigns,
  getGroupCampaign,
  getGroupLocations,
} from '../../actions/group';
import './GuidedSetup.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { accountRequestActions } from '../../requestActions';
import { IAccountDetails } from '@gigit/interfaces';
import { Constants } from '@gigit/constants';

interface IProps extends RouteComponentProps {
  groupState: IGroupState;
  locale: string;
}
interface IState {
  steps: Array<any>;
  total: number;
  completed: number;
  overall: number;
  account: IAccountDetails | null;
}

class GuidedSetup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      steps: [],
      total: 0,
      completed: 0,
      overall: 0,
      account: null,
    };
  }

  componentDidMount() {
    this.setState(
      {
        steps: this.getSteps(),
      },
      () => {
        this.getTotalTasks();
        this.fetchAccountInfo();
      },
    );
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevProps.groupState.groupCampaigns?.length !==
        this.props.groupState.groupCampaigns?.length ||
      prevProps.groupState.group.campaign_id !== this.props.groupState.group.campaign_id ||
      prevState.account !== this.state.account
    ) {
      this.setState({
        steps: this.getSteps(),
      });
    }
  }

  async fetchAccountInfo() {
    const fetchedAccounts = await accountRequestActions.getAccountsWithDetails(
      Constants.object_type.group,
      this.props.groupState.group.id,
    );
    const account = fetchedAccounts.find(
      (ac) => ac.account_number === this.props.groupState.group.account?.account_number,
    );
    if (account) this.setState({ account });
  }

  getSteps() {
    return [
      {
        title: 'Payouts',
        info: 'Connect your bank account information or another payment process to receive your funds.',
        warning:
          'You must do this within 90 days of your first transaction or all transactions will be automatically refunded to your supporters.',
        tasks: [
          {
            title: 'Enter banking information to receive payouts',
            logic: this.state.account && this.state.account.onboarding_status !== 'past_due',
          },
        ],
      },
      {
        title: 'Volunteers',
        info: 'Create a custom application form that will help you quickly find the perfect volunteers for your cause.',
        tasks: [
          {
            title: 'Create a volunteer application form',
            logic: this.props.groupState.groupForms.length > 0 && true,
          },
          {
            title: 'Set up cause application form',
            logic: this.props.groupState.group.form_id && true,
          },
          {
            title: 'Switch on Accepting Volunteer Applications',
            logic: this.props.groupState.group.accepting_volunteers && true,
          },
        ],
      },
      {
        title: 'Tax Receipts',
        info: 'Complete these steps to have your own customized tax receipt.',
        tasks: [
          { title: 'Location', logic: this.props.groupState.group.default_location_id && true },
          { title: 'Charity ID', logic: this.props.groupState.group.charity_id && true },
          {
            title: 'Signature',
            logic:
              this.props.groupState.groupSignature &&
              this.props.groupState.groupSignature.signature &&
              this.props.groupState.groupSignature.signature !== 'empty',
          },
          {
            title: 'Logo',
            logic:
              this.props.groupState.group.receipt_logo_image_url &&
              this.props.groupState.group.receipt_logo_image_url.length > 0,
          },
          {
            title: 'Thank you message',
            logic:
              this.props.groupState.group.confirmation_message &&
              this.props.groupState.group.confirmation_message.length > 0,
          },
          {
            title: 'Switch on Auto Thank you messages',
            logic: this.props.groupState.group.auto_confirmations,
          },
          {
            title: 'Switch on Auto Send Tax Receipts',
            logic: this.props.groupState.group.auto_tax_receipts,
          },
        ],
      },
      {
        title: 'Locations',
        info: 'Create a location to show people where you are! Locations will show on the Kambeo map search and allow users to find you for donations, volunteer positions, and more.',
        tasks: [
          {
            title: 'Create a location',
            logic: this.props.groupState.groupLocations.length > 0 && true,
          },
        ],
      },
    ];
  }

  getTotalTasks() {
    let total: number = 0;
    let completed: number = 0;

    this.state.steps.forEach((steps) => {
      total += steps.tasks.length;
      completed += this.getCompleted(steps.tasks);
    });

    this.setState(
      {
        total: total,
        completed: completed,
      },
      () => {
        this.setState({
          overall: Math.round((this.state.completed / this.state.total) * 100),
        });
      },
    );
  }

  getCompleted(tasks: Array<any>) {
    let completedTasks = tasks.filter((t: any) => {
      return Boolean(t.logic);
    });

    return completedTasks.length;
  }

  getSectionCompletion(tasks: Array<any>) {
    let comp = this.getCompleted(tasks);
    return (comp / tasks.length) * 100;
  }

  render() {
    return (
      <div className="GuidedSetup guided-wrap">
        <div className="section-title">
          <div className="forms-title">Guided Setup</div>
        </div>
        <div className="section-inner">
          <div className="setup-overall">
            <div className="setup-overall-progress">
              <div className="overall">{this.state.overall}%</div>
              <div className="bar-wrap">
                <div className="bar">
                  <div
                    style={{ width: this.state.overall + '%' }}
                    className="bar-inner"
                  />
                </div>
              </div>
            </div>
          </div>
          {!this.props.groupState.isGroupLoading &&
            this.state.steps.map((step, i) => {
              if (
                step.title == 'Payouts' &&
                this.state.account?.account_sub_type == Constants.account_sub_type.standard
              ) {
                return null;
              }
              return (
                <div
                  key={i}
                  className="setup-section"
                >
                  <div className="progress-circle">
                    <div className="progress-status">
                      <div
                        className="circle"
                        data-progress={Math.round(this.getSectionCompletion(step.tasks))}
                        notranslate="yes"
                      />
                      <div className="status">
                        <span data-isolate="yes">Status: </span>{' '}
                        <span data-isolate="yes">
                          {Math.round(this.getSectionCompletion(step.tasks)) === 100
                            ? 'Complete'
                            : 'In Progress'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="setup-details">
                    <div className="setup-title">{step.title}</div>
                    <div className="setup-info">{step.info}</div>
                    <div className="setup-warning">{step.warning}</div>
                    <div className="tasks">
                      <div className="tasks-count">
                        <var data-var="tasks_completed">
                          {' '}
                          {localizeHelpers.formatNumber(
                            this.getCompleted(step.tasks) || 0,
                            this.props.locale,
                          )}
                          /{localizeHelpers.formatNumber(step.tasks.length || 0, this.props.locale)}
                        </var>{' '}
                        Tasks completed
                      </div>
                      <ul className="task-list">
                        {step.tasks.map((t: any, idx: any) => {
                          return (
                            <li key={idx}>
                              {t.logic ? (
                                <i className="fa fa-check complete" />
                              ) : (
                                <i className="fa fa-circle incomplete" />
                              )}{' '}
                              <span>{t.title}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getGroup,
  getGroupCampaigns,
  getGroupCampaign,
  getGroupLocations,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GuidedSetup));
