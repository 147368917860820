import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { IAppState } from '../../store';

import {
  handleInputChange,
  routes,
  swapRouteParams,
  toastError,
  typeHelpers,
  errorHelpers,
} from '../../helpers';

import { ISkill } from '@gigit/interfaces';
import { IToast } from '../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { createToast } from '../../actions/toaster';

import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import { Prompt } from '../Prompt/Prompt';
import { userSelectors } from '../../selectors/user';
import SortableTable, { ISortableTableColumn } from '../SortableTable/SortableTable';
import Modal from '../Modal/Modal';
import ModalHeader from '../Modal/ModalHeader/ModalHeader';
import './Skills.scss';

interface IProps {
  createToast(toast: IToast): void;
  locale: string;
}

interface IState {
  skillsRecords: Array<ISkill>;
  skillsColumns: ISortableTableColumn[];
  dataLoading: boolean;
  searchValue: string;
  skillToDelete: ISkill | null;
  skillToEdit: ISkill | null;
  skillName: string;
  skillCategory: string;
  showAddSkill: boolean;
  showEditSkill: boolean;
}

// TODO: Rename this to have better name. possible suggestion: `SkillsAdministration`.
class Skills extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      searchValue: '',
      dataLoading: false,
      skillToDelete: null,
      skillToEdit: null,
      skillName: '',
      skillCategory: '',
      showAddSkill: false,
      showEditSkill: false,
      skillsRecords: [],
      skillsColumns: [
        { id: 'name', label: 'Skill Name', sortable: false },
        { id: 'category', label: 'Skill Category', sortable: false },
        { id: 'date-created', label: 'Date Created', sortable: false },
        { id: 'actions', label: 'Actions', sortable: false },
      ],
    };
  }

  componentDidMount() {
    this.getSkills();
  }

  getSkills() {
    this.setState({ dataLoading: true });
    axios
      .get(routes.GET_SKILLS + '?sort=-created_at')
      .then((response) => {
        this.setState({ skillsRecords: response.data });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Get Skills');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  createSkill() {
    const payload = {
      skill: this.state.skillName.trim(),
      category: this.state.skillCategory.trim(),
    };

    this.setState({ dataLoading: true });
    axios
      .post<ISkill>(routes.CREATE_SKILL, payload)
      .then((response) => {
        const updatedSkillsRecords = [...this.state.skillsRecords];
        updatedSkillsRecords.unshift(response.data as ISkill);
        this.setState({
          skillsRecords: updatedSkillsRecords,
          showAddSkill: false,
        });
        this.resetSkillData();
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Create Skill');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  deleteSkill(id: string) {
    this.setState({ dataLoading: true });
    axios
      .delete(swapRouteParams(routes.DELETE_SKILL, { id }))
      .then((response) => {
        const deletedSkill = response.data as ISkill;
        const updatedSkillsList = this.state.skillsRecords.filter((s) => s.id !== deletedSkill.id);
        this.setState({
          skillsRecords: updatedSkillsList,
        });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Delete Skill');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  editSkill() {
    typeHelpers.assertNotNullOrUndefined(this.state.skillToEdit);

    const payload: Partial<ISkill> = {
      skill: this.state.skillName.trim(),
      category: this.state.skillCategory.trim(),
    };

    axios
      .put<ISkill>(swapRouteParams(routes.UPDATE_SKILL, { id: this.state.skillToEdit.id }), payload)
      .then((response) => {
        const updatedSkill: ISkill = response.data;
        const updatedSkillsRecords = this.state.skillsRecords.map((skill) =>
          skill.id === updatedSkill.id ? updatedSkill : skill,
        );
        this.setState({
          skillsRecords: updatedSkillsRecords,
          showEditSkill: false,
        });
        this.resetSkillData();
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Edit Skill');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  resetSkillData() {
    this.setState({
      skillName: '',
      skillCategory: '',
    });
  }

  getSkillsTableData() {
    const searchValue = this.state.searchValue.toLowerCase();
    const skillsList = searchValue
      ? this.state.skillsRecords?.filter(
          (skill) =>
            skill.skill.toLowerCase().includes(searchValue) ||
            skill.category.toLowerCase().includes(searchValue),
        )
      : this.state.skillsRecords;

    return skillsList.map((skill: ISkill) => {
      return {
        row: [
          { content: skill.skill, id: 'name' },
          { content: skill.category, id: 'category' },
          {
            content: skill.created_at
              ? localizeHelpers.formatDate(
                  skill.created_at,
                  LocaleDateFormats.LL,
                  this.props.locale,
                )
              : '',
            id: 'date-created',
          },
          {
            id: 'actions',
            menu: [
              {
                icon: 'far fa-pencil-alt',
                onClick: () => {
                  this.setState({
                    showEditSkill: true,
                    skillToEdit: skill,
                    skillName: skill.skill,
                    skillCategory: skill.category,
                  });
                },
                label: 'Edit',
              },
              {
                icon: 'far fa-trash-alt',
                onClick: () => {
                  this.setState({ skillToDelete: skill });
                },
                label: 'Delete',
              },
            ],
          },
        ],
      };
    });
  }

  render() {
    return (
      <div className="Skills">
        <div className="section-title">
          <div className="forms-title">Skills</div>
          <div className="forms-controls">
            <Button
              text="+ New Skill"
              onClick={() => this.setState({ showAddSkill: true })}
            >
              {' '}
            </Button>
          </div>
        </div>

        <div className="section-inner">
          <div className="search">
            <TextField
              icon="fas fa-search"
              placeholder="Search skills..."
              value={this.state.searchValue}
              type="text"
              name="searchValue"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e, this);
              }}
            />
            <div className="filters">{/* Filters buttons here if needed */}</div>
          </div>

          <div className="items-list">
            <SortableTable
              {...this.props}
              columns={this.state.skillsColumns}
              data={this.getSkillsTableData()}
              itemsPerPage={5}
              loading={this.state.dataLoading}
            />
          </div>

          {!this.state.dataLoading && (
            <div className={`no-items-found ${this.getSkillsTableData().length ? '' : 'show'}`}>
              No items found
            </div>
          )}
        </div>

        <Prompt
          show={!!this.state.skillToDelete}
          title={'Delete skill'}
          message={`Are you sure you want to delete skill ${this.state.skillToDelete?.skill}?`}
          yesMessage="Yes"
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage="Cancel"
          onYes={() => {
            this.deleteSkill(this.state.skillToDelete?.id || '');
          }}
          onClose={() => {
            this.setState({ skillToDelete: null });
          }}
        />

        <Modal
          class="add-skill-record"
          show={this.state.showAddSkill}
          onClose={(e: any) => {
            this.setState({ showAddSkill: false });
            this.resetSkillData();
          }}
        >
          <ModalHeader
            className="header"
            title={'Add Skill'}
            onClose={(e: any) => {}}
          />

          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.createSkill();
            }}
          >
            <div className="inner">
              <TextField
                label="Skill Name"
                required={true}
                value={this.state.skillName}
                name="skillName"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
              <TextField
                label="Skill Category"
                required={true}
                value={this.state.skillCategory}
                name="skillCategory"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="controls">
              <Button
                buttonClass="cancel-button"
                type="button"
                onClick={(e) => {
                  this.setState({ showAddSkill: false });
                  this.resetSkillData();
                }}
                text="Cancel"
              />
              <Button
                buttonClass="add-button"
                type="submit"
                text="Add"
              />
            </div>
          </form>
        </Modal>

        <Modal
          class="edit-skill-record"
          show={this.state.showEditSkill}
          onClose={(e: any) => {
            this.setState({ showEditSkill: false });
            this.resetSkillData();
          }}
        >
          <ModalHeader
            className="header"
            title={'Edit Skill'}
            onClose={(e: any) => {}}
          />

          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.editSkill();
            }}
          >
            <div className="inner">
              <TextField
                label="Skill Name"
                required={true}
                value={this.state.skillName}
                name="skillName"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
              <TextField
                label="Skill Category"
                required={true}
                value={this.state.skillCategory}
                name="skillCategory"
                type="text"
                onChange={(e) => {
                  handleInputChange(e, this);
                }}
              />
            </div>
            <div className="controls">
              <Button
                buttonClass="cancel-button"
                type="button"
                onClick={(e) => {
                  this.setState({ showEditSkill: false });
                  this.resetSkillData();
                }}
                text="Cancel"
              />
              <Button
                buttonClass="update-button"
                type="submit"
                text="Update"
              />
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Skills);
