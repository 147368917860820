import {
  IEventSummaryFE,
  IGroup,
  IHub,
  IPage,
  IInvitesCreateInfo,
  IPageComponent,
  IAuctionItemSummary,
  IStoreItem,
  IAuctionItem,
  IGigSummaryFE,
  ICause,
  IInvite,
  IUserRole,
  IRole,
  ISimpleValue,
  ICreateHubFE,
  IHubPartnerInviteCreateParamsFE,
  IHubPartnerInvite,
  CreateOrUpdateActionResult,
  IStoreStats,
  IAuctionStats,
  IPaymentIntentResponse,
  IAddress,
  IDonationMatchingProgram,
  IDonationMatchingProgramCreateParams,
  IDonationMatchingGroupSummary,
  IAvailableDonationMatchingParams,
  IAvailableDonationMatchingProgram,
  ISpotlightList,
  IDonationMatchRequestParams,
  ITransactionAmounts,
  IDonationMatchingPayoutParams,
  IDonationMatchingPayoutResponse,
  ITransactionSummary,
  IDonationMatchingProgramUpdateParams,
  IApplication,
  IHubVolunteerSummary,
  IVolunteerHoursMatchList,
  IVolunteerMatchStats,
  ILoggedVolunteerHours,
  IUserCreditPaymentPackageFE,
  IUserCreditPaymentPackageStats,
  IApplicationHubSummaryFE,
} from '@gigit/interfaces';
import axios from 'axios';
import { downloadFile, routes, swapRouteParams, uploadMedias } from '../helpers';
import { IUpdateAuctionItemParams } from '../actions/store';

export namespace hubRequestActions {
  // GET REQUESTS
  export async function getHub(handle: string) {
    const response = await axios.get<IHub>(swapRouteParams(routes.GET_HUB, { handle }));
    return response.data;
  }

  export async function getVolunteerOpportunities(hubId: string, queryParams?: URLSearchParams) {
    const response = await axios.get<IGigSummaryFE[]>(
      swapRouteParams(routes.GET_HUB_VOLUNTEER_OPPORTUNITIES, { hubId }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getVolunteers(hub_id: string, queryParams?: URLSearchParams) {
    const response = await axios.get<IHubVolunteerSummary[]>(
      swapRouteParams(routes.GET_HUB_VOLUNTEERS, { hub_id }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getHubByID(id: string) {
    const response = await axios.get<IHub>(swapRouteParams(routes.GET_HUB_BY_ID, { id }));
    return response.data;
  }

  export async function exportMatchingProgramSummary(object_id: string, program_id: string) {
    const response = await axios.get<string>(
      swapRouteParams(routes.EXPORT_HUB_DONATION_MATCHING_PROGRAM, { object_id, program_id }),
    );
    return response.data;
  }

  export async function exportVolunteerMatchingSummary(object_id: string, package_id: string) {
    const response = await axios.get<string>(
      swapRouteParams(routes.EXPORT_HUB_VOLUNTEER_MATCH_SUMMARY, { id: object_id, package_id }),
    );
    return response.data;
  }

  export async function exportDonations(object_id: string) {
    const response = await axios.get<string>(
      swapRouteParams(routes.EXPORT_HUB_DONATIONS, { object_id }),
    );
    return response.data;
  }

  export async function getDonations(
    id: string,
    queryParams?: URLSearchParams,
  ): Promise<ITransactionSummary[]> {
    const response = await axios.get<ITransactionSummary[]>(
      swapRouteParams(routes.GET_HUB_DONATIONS, { id }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getDonationsBetween(
    id: string,
    from: string,
    to: string,
    queryParams?: URLSearchParams,
  ): Promise<ITransactionSummary[]> {
    const response = await axios.get<ITransactionSummary[]>(
      swapRouteParams(routes.GET_HUB_DONATIONS_BETWEEN_DATES, {
        id,
        start_date: from,
        end_date: to,
      }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getBulkDonationReceipt(hubId: string, dateFrom: string, dateTo: string) {
    const response = await axios
      .post(
        swapRouteParams(routes.EXPORT_HUB_BULK_DONATION_RECEIPT, { id: hubId }),
        {
          startDate: dateFrom,
          endDate: dateTo,
        },
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        downloadFile(`bulk_donation_receipts.zip`, response.data, 'application/zip');
      });
  }

  export async function getDonationReceipt(hubId: string, transaction_id: string) {
    const response = await axios
      .get(swapRouteParams(routes.EXPORT_HUB_DONATION_RECEIPT, { id: hubId, transaction_id }), {
        responseType: 'blob',
      })
      .then((response) => {
        downloadFile(`donation_receipt.pdf`, response.data, 'application/pdf');
      });
  }

  export async function getHubUserRole(id: string, userId: string) {
    const response = await axios.get<IUserRole>(
      swapRouteParams(routes.GET_HUB_SINGLE_MEMBER, { id, userId }),
    );
    return response.data;
  }

  export async function getHubRoles(id: string) {
    const response = await axios.get<IRole[]>(swapRouteParams(routes.GET_HUB_ROLES, { id }));
    return response.data;
  }

  export async function getPaymentMethodDetails(
    hub_id: string,
    child_object_type: string,
    child_object_id: string,
    transaction_id: string,
  ) {
    let route = swapRouteParams(routes.GET_HUB_DONATION_PAYMENT_METHOD_DETAILS, {
      hub_id,
      child_object_type,
      child_object_id,
      transaction_id,
    });

    const response = await axios.get<{ last_4_digits: string; card_brand: string }>(route);
    return response.data;
  }

  export async function getHubMembers(id: string, queryParams?: URLSearchParams) {
    const response = await axios.get<IUserRole[]>(swapRouteParams(routes.GET_HUB_MEMBERS, { id }), {
      params: queryParams,
    });
    return response.data;
  }

  export async function getHubPartners(id: string, queryParams?: URLSearchParams) {
    const route = swapRouteParams(routes.GET_HUB_PARTNERS, { id });
    const response = await axios.get<IGroup[]>(route, { params: queryParams });

    return response.data;
  }

  export async function getHubPartnerEvents(
    id: string,
    queryParams?: URLSearchParams,
  ): Promise<IEventSummaryFE[]> {
    const response = await axios.get<IEventSummaryFE[]>(
      swapRouteParams(routes.GET_HUB_PARTNER_EVENTS, { id }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getHubEvents(
    id: string,
    queryParams?: URLSearchParams,
  ): Promise<IEventSummaryFE[]> {
    const response = await axios.get<IEventSummaryFE[]>(
      swapRouteParams(routes.GET_HUB_EVENTS, { id }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getHubPartnersCauses(id: string): Promise<ICause[]> {
    const response = await axios.get<ICause[]>(
      swapRouteParams(routes.GET_HUB_PARTNER_CAUSES, { id }),
    );
    return response.data;
  }

  export async function getHubEventCauses(id: string): Promise<ICause[]> {
    const response = await axios.get<ICause[]>(
      swapRouteParams(routes.GET_HUB_EVENTS_CAUSES, { id }),
    );

    return response.data;
  }

  export async function getHubCauses(id: string): Promise<ICause[]> {
    const response = await axios.get<ICause[]>(swapRouteParams(routes.GET_HUB_CAUSES, { id }));

    return response.data;
  }

  export async function getHubAuctionItems(
    id: string,
    queryParams?: URLSearchParams,
  ): Promise<IAuctionItemSummary[]> {
    const response = await axios.get<IAuctionItemSummary[]>(
      swapRouteParams(routes.GET_HUB_AUCTION_ITEMS, { id }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getHubDonationMatchingProgramById(hub_id: string, program_id: string) {
    const response = await axios.get<IDonationMatchingProgram>(
      swapRouteParams(routes.GET_HUB_DONATION_MATCHING_PROGRAM_BY_ID, {
        object_id: hub_id,
        program_id,
      }),
    );
    return response.data;
  }

  /**
   *
   * @param queryParams Optionally pass { type: "partner_groups" | "hub" | "all"}. Defaults to 'all'
   * @returns IStoreItem[] associated with partner groups, only hub items, or all (partner & hub).
   */
  export async function getHubStoreItems(
    id: string,
    queryParams?: URLSearchParams,
  ): Promise<IStoreItem[]> {
    const response = await axios.get<IStoreItem[]>(
      swapRouteParams(routes.GET_HUB_STORE_ITEMS, { id }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getHubPages(id: string, queryParams?: URLSearchParams) {
    const response = await axios.get<IPage[]>(swapRouteParams(routes.GET_HUB_PAGES, { id }), {
      params: queryParams,
    });

    return response.data;
  }

  export async function getHubAuctionAdminItems(
    id: string,
    queryParams?: URLSearchParams,
  ): Promise<IAuctionItemSummary[]> {
    const response = await axios.get<IAuctionItemSummary[]>(
      swapRouteParams(routes.GET_HUB_AUCTION_ADMIN_ITEMS, { id }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getHubSpecificStoreItems(
    id: string,
    queryParams?: URLSearchParams,
  ): Promise<IStoreItem[]> {
    const response = await axios.get<IStoreItem[]>(
      swapRouteParams(routes.GET_HUB_STORE_ITEMS, { id }),
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  export async function getGigsFromHubPartnerGroups(
    id: string,
    queryParams?: URLSearchParams,
  ): Promise<IGigSummaryFE[]> {
    const response = await axios.get<IGigSummaryFE[]>(
      swapRouteParams(routes.GET_HUB_PARTNERS_GIG_ITEMS, { id }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getHubPageComponentWithContent(
    id: string,
    pageId: string,
    componentId: string,
  ) {
    const response = await axios.get<IPageComponent>(
      swapRouteParams(routes.GET_HUB_PAGE_COMPONENT, { id, page_id: pageId, comp_id: componentId }),
    );

    return response.data;
  }

  export async function validateHubHandle(hubHandle: string) {
    const response = await axios.get<ISimpleValue<boolean>>(
      swapRouteParams(routes.VALIDATE_HUB_HANDLE, { handle: hubHandle }),
    );
    return response.data;
  }

  export async function getHubStoreStats(id: string) {
    const response = await axios.get<IStoreStats>(
      swapRouteParams(routes.GET_HUB_STORE_STATS, { id }),
    );

    return response.data;
  }

  export async function getHubAuctionStats(id: string) {
    const response = await axios.get<IAuctionStats>(
      swapRouteParams(routes.GET_HUB_AUCTION_STATS, { id }),
    );

    return response.data;
  }

  export async function downloadSeatReceipt(
    hub_id: string,
    subscription_code: string,
    invoice_id: string,
  ) {
    const response = await axios.get(
      swapRouteParams(routes.DOWNLOAD_HUB_SEAT_RECEIPT, { hub_id, subscription_code, invoice_id }),
      { responseType: 'blob' },
    );
    return response.data;
  }

  export async function getHubLocations(id: string, queryParams?: URLSearchParams) {
    const response = await axios.get(swapRouteParams(routes.GET_HUB_LOCATIONS, { id }), {
      params: queryParams,
    });
    return response.data;
  }

  export async function getHubDonationMatchingPrograms(id: string, queryParams?: URLSearchParams) {
    const response = await axios.get<IDonationMatchingProgram[]>(
      swapRouteParams(routes.GET_HUB_DONATION_MATCHING_PROGRAMS, {
        object_id: id,
      }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getAvailableDonationMatchingPrograms(
    program_type: string,
    payload: IAvailableDonationMatchingParams,
    queryParams?: URLSearchParams,
  ) {
    const response = await axios.post<IAvailableDonationMatchingProgram[]>(
      swapRouteParams(routes.GET_AVAILABLE_DONATION_MATCHING_PROGRAMS, {
        program_type,
      }) + '?flags[show_only_available]=true',
      payload,
    );
    return response.data;
  }

  export async function getAvailableDonationMatchingProgramsForTransaction(
    program_type: string,
    transaction_id: string,
  ) {
    const response = await axios.post<IAvailableDonationMatchingProgram[]>(
      swapRouteParams(routes.GET_AVAILABLE_DONATION_MATCHING_PROGRAMS_FOR_TRANSACTION, {
        program_type,
        transaction_id,
      }) + '?flags[show_only_available]=true',
    );
    return response.data;
  }

  export async function getGroupsForDonationMatchingProgram(
    id: string,
    program_id: string,
    queryParams?: URLSearchParams,
  ) {
    const response = await axios.get<IDonationMatchingGroupSummary[]>(
      swapRouteParams(routes.GET_GROUPS_FOR_DONATION_MATCHING_PROGRAM, {
        object_id: id,
        program_id,
      }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getHubMemberStories(hubId: string, query?: URLSearchParams) {
    const response = await axios.get(
      swapRouteParams(routes.GET_MEMBER_STORIES, { entity_type: 'hub', id: hubId }),
      { params: query },
    );
    return response.data;
  }

  export async function getHubApplicationsByUser(hubId: string, userId: string) {
    const response = await axios.get<IApplication[]>(
      swapRouteParams(routes.GET_HUB_APPLICATIONS_BY_USER, { hubId, userId }),
    );
    return response.data;
  }

  export async function getHubDonations(hubId: string, query?: URLSearchParams) {
    const response = await axios.get<ITransactionSummary[]>(
      swapRouteParams(routes.GET_HUB_DONATIONS_BY_ID, { hubId }),
      { params: query },
    );
    return response.data;
  }

  export async function getMemberApplicationSummary(hubId: string, query?: URLSearchParams) {
    const response = await axios.get<IApplicationHubSummaryFE[]>(
      swapRouteParams(routes.GET_MEMBER_APPLICATION_SUMMARY, { id: hubId }),
      { params: query },
    );
    return response.data;
  }

  export async function getMemberApplicationSummaryExport(hubId: string, query?: URLSearchParams) {
    await axios
      .get(swapRouteParams(routes.GET_MEMBER_APPLICATION_SUMMARY_EXPORT, { id: hubId }), {
        responseType: 'blob',
      })
      .then((response) => {
        downloadFile(`volunteer_engagement_export.csv`, response.data, 'text/csv');
      });
  }

  export async function getDonationsForDonationMatchingProgram(
    object_id: string,
    program_id: string,
    query?: URLSearchParams,
  ) {
    const response = await axios.get<ITransactionSummary[]>(
      swapRouteParams(routes.GET_DONATIONS_FOR_DONATION_MATCHING_PROGRAM, {
        object_id,
        program_id,
      }),
      { params: query },
    );
    return response.data;
  }

  // POST REQUESTS

  export async function getDonationMatchingPayoutSummary(
    object_id: string,
    program_id: string,
    group_id: string,
    payload: IDonationMatchingPayoutParams,
  ) {
    const response = await axios.post<ITransactionAmounts>(
      swapRouteParams(routes.GET_DONATION_MATCHING_PAYOUT_SUMMARY, {
        object_id,
        program_id,
        group_id,
      }),
      payload,
    );
    return response.data;
  }

  export async function getDonationMatchingAvailablePaymentMethods(
    object_id: string,
    program_id: string,
    group_id: string,
  ) {
    const response = await axios.post<{ available_payment_methods: string[] }>(
      swapRouteParams(routes.GET_DONATION_MATCHING_PAYMENT_METHOD, {
        object_id,
        program_id,
        group_id,
      }),
    );
    return response.data;
  }

  export async function createDonationMatchingPayout(
    object_id: string,
    program_id: string,
    group_id: string,
    payload: IDonationMatchingPayoutParams,
  ) {
    const response = await axios.post<IDonationMatchingPayoutResponse>(
      swapRouteParams(routes.CREATE_DONATION_MATCHING_PAYOUT, { object_id, program_id, group_id }),
      payload,
    );
    return response.data;
  }

  export async function requestDonationMatch(
    params: {
      hub_id: string;
      program_type: string;
      transaction_id: string;
    },
    payload: IDonationMatchRequestParams,
  ) {
    const response = await axios.post<IDonationMatchingProgram>(
      swapRouteParams(routes.REQUEST_DONATION_MATCH, {
        object_id: params.hub_id,
        program_type: params.program_type,
        transaction_id: params.transaction_id,
      }),
      payload,
    );

    return response.data;
  }

  export async function approveDonationMatch(params: {
    hub_id: string;
    program_id: string;
    transaction_id: string;
  }) {
    const response = await axios.post<IDonationMatchingProgram>(
      swapRouteParams(routes.APPROVE_DONATION_MATCHING, {
        object_id: params.hub_id,
        program_id: params.program_id,
        transaction_id: params.transaction_id,
      }),
    );
    return response.data;
  }

  export async function declineDonationMatch(params: {
    hub_id: string;
    program_id: string;
    transaction_id: string;
  }) {
    const response = await axios.post<IDonationMatchingProgram>(
      swapRouteParams(routes.DECLINE_DONATION_MATCHING, {
        object_id: params.hub_id,
        program_id: params.program_id,
        transaction_id: params.transaction_id,
      }),
    );
    return response.data;
  }

  export async function approveVolunteerMatch(params: {
    hubId: string;
    programId: string;
    loggedHoursId: string;
  }) {
    const response = await axios.post<ILoggedVolunteerHours>(
      swapRouteParams(routes.APPROVE_VOLUNTEER_MATCH, {
        id: params.hubId,
        program_id: params.programId,
        logged_hours_id: params.loggedHoursId,
      }),
    );
    return response.data;
  }

  export async function approveVolunteerMatchBulk(params: {
    hubId: string;
    programId: string;
    loggedHoursIds: string[];
  }) {
    const response = await axios.post<ILoggedVolunteerHours>(
      swapRouteParams(routes.BULK_APPROVE_VOLUNTEER_MATCH, {
        object_id: params.hubId,
        program_id: params.programId,
      }),
      { logged_hours_id: params.loggedHoursIds },
    );
    return response.data;
  }

  export async function rejectVolunteerMatch(params: {
    hubId: string;
    programId: string;
    loggedHoursId: string;
  }) {
    const response = await axios.post<ILoggedVolunteerHours>(
      swapRouteParams(routes.REJECT_VOLUNTEER_MATCH, {
        id: params.hubId,
        program_id: params.programId,
        logged_hours_id: params.loggedHoursId,
      }),
    );
    return response.data;
  }

  export async function rejectVolunteerMatchBulk(params: {
    hubId: string;
    programId: string;
    loggedHoursIds: string[];
  }) {
    const response = await axios.post<ILoggedVolunteerHours>(
      swapRouteParams(routes.BULK_REJECT_VOLUNTEER_MATCH, {
        object_id: params.hubId,
        program_id: params.programId,
      }),
      { logged_hours_id: params.loggedHoursIds },
    );
    return response.data;
  }

  export async function createPaymentPackage(
    hubId: string,
    payload: {
      title: string;
      startDate: Date;
      endDate: Date;
    },
  ) {
    const response = await axios.post<IUserCreditPaymentPackageFE>(
      swapRouteParams(routes.CREATE_HUB_PAYMENT_PACKAGE, { id: hubId }),
      {
        start_date: payload.startDate,
        end_date: payload.endDate,
        title: payload.title,
      },
    );

    return response.data;
  }

  //WIP
  export async function generatePaymentPackage(hubId: string, programId: string) {
    const response = await axios.post<{
      matching_program: IDonationMatchingProgram;
      payout_transaction_ids: string[];
    }>(
      swapRouteParams(routes.GENERATE_HUB_PAYMENT_PACKAGE, {
        object_id: hubId,
        program_id: programId,
      }),
    );

    return response.data;
  }

  export async function getPaymentPackage(hubId: string, packageId: string) {
    const response = await axios.get<IUserCreditPaymentPackageFE>(
      swapRouteParams(routes.GET_HUB_PAYMENT_PACKAGE, { id: hubId, package_id: packageId }),
    );

    return response.data;
  }

  export async function getPaymentPackages(hubId: string, query?: URLSearchParams) {
    const response = await axios.get<IUserCreditPaymentPackageFE[]>(
      swapRouteParams(routes.GET_HUB_PAYMENT_PACKAGES, { id: hubId }),
      { params: query },
    );

    return response.data;
  }

  export async function payPaymentPackage(
    hubId: string,
    packageId: string,
    groupId: string,
    payload: IDonationMatchingPayoutParams,
  ) {
    const response = await axios.post<IDonationMatchingPayoutResponse & { needs_confirm: boolean }>(
      swapRouteParams(routes.PAY_HUB_PAYMENT_PACKAGE, {
        id: hubId,
        package_id: packageId,
        group_id: groupId,
      }),
      payload,
    );

    return response.data;
  }

  export async function createDonationMatchingProgram(
    hubId: string,
    payload: IDonationMatchingProgramCreateParams,
  ) {
    const response = await axios.post<IDonationMatchingProgram>(
      swapRouteParams(routes.CREATE_HUB_DONATION_MATCHING_PROGRAM, { object_id: hubId }),
      payload,
    );

    return response.data;
  }

  export async function createPurchaseRequest(hubId: string, payload: any) {
    const paymentIntentResponse = await axios.post<IPaymentIntentResponse>(
      swapRouteParams(routes.HUB_PURCHASE, { id: hubId }),
      payload,
    );

    return paymentIntentResponse;
  }

  export async function createAuctionItem(
    hubId: string,
    payload: IUpdateAuctionItemParams,
  ): Promise<IAuctionItem> {
    const response = await axios.post<IAuctionItem>(
      swapRouteParams(routes.CREATE_HUB_AUCTION_ITEM, { hubId }),
      payload,
    );

    return response.data;
  }

  export async function createStoreItem(
    hubId: string,
    payload: IUpdateAuctionItemParams,
    medias?: string[],
  ): Promise<IStoreItem> {
    const _payload = payload;

    if (medias) {
      if (medias.length > 0) {
        _payload.media = await uploadMedias(medias);
      } else {
        _payload.media = [];
      }
    }

    const response = await axios.post<IStoreItem>(
      swapRouteParams(routes.CREATE_HUB_STORE_ITEM, { hubId }),
      _payload,
    );

    return response.data;
  }

  export async function createHub(hubSettings: ICreateHubFE): Promise<IHub> {
    const response = await axios.post(routes.CREATE_HUB, hubSettings);

    return response.data;
  }

  export async function createHubPage(hubId: string, page: Partial<IPage>) {
    const response = await axios.post(swapRouteParams(routes.CREATE_HUB_PAGE, { hubId }), page);
    return response.data;
  }

  export async function addHubPartner(id: string, groupId: string): Promise<IGroup[]> {
    const response = await axios.post<IGroup[]>(swapRouteParams(routes.HUB_PARTNERS, { id }), {
      group_id: groupId,
    });

    return response.data;
  }

  export async function inviteUsersToGroup(
    id: string,
    invites: IInvitesCreateInfo,
  ): Promise<IInvite[]> {
    const response = await axios.post<IInvite[]>(
      swapRouteParams(routes.HUB_INVITE_USERS, { id }),
      invites,
    );

    return response.data;
  }

  export async function inviteGroupToHub(
    id: string,
    payload: IHubPartnerInviteCreateParamsFE,
  ): Promise<{ invite: IHubPartnerInvite; result: CreateOrUpdateActionResult }> {
    const response = await axios.post<{
      invite: IHubPartnerInvite;
      result: CreateOrUpdateActionResult;
    }>(swapRouteParams(routes.HUB_INVITE_GROUP, { id }), { ...payload });

    return response.data;
  }

  export async function findMember(userId: string, hubId: string): Promise<IUserRole> {
    const response = await axios.get<IUserRole>(
      swapRouteParams(routes.FIND_HUB_MEMBER, {
        hubId,
        userId,
      }),
    );
    return response.data;
  }
  export async function getHubUser(hubId: string): Promise<IUserRole> {
    const response = await axios.get<IUserRole>(
      swapRouteParams(routes.GET_USER_HUB_ROLE, {
        hub_id: hubId,
      }),
    );
    return response.data;
  }

  export async function userFollowHub(userId: string, hubId: string): Promise<IUserRole> {
    const response = await axios.post<IUserRole>(swapRouteParams(routes.FOLLOW_HUB, { hubId }), {
      user: { id: userId },
    });
    return response.data;
  }

  export async function userUnfollowHub(userId: string, hubId: string): Promise<IUserRole> {
    const response = await axios.post<IUserRole>(swapRouteParams(routes.UNFOLLOW_HUB, { hubId }), {
      user: { id: userId },
    });
    return response.data;
  }

  export async function createHubPageComponent(
    id: string,
    pageId: string,
    componentId: string,
    payload: IPageComponent,
  ) {
    const response = await axios.post<IPage>(
      swapRouteParams(routes.CREATE_HUB_PAGE_COMPONENT, {
        id,
        page_id: pageId,
        comp_id: componentId,
      }),
      { ...payload },
    );

    return response.data;
  }

  export async function updateMemberRole(id: string, roleId: string, memberId: string) {
    const response = await axios.post<IUserRole>(
      swapRouteParams(routes.UPDATE_HUB_MEMBER_ROLE, { id, roleId, memberId }),
    );
    return response.data;
  }

  export async function createHubLocation(id: string, payload: Partial<IAddress>) {
    const response = await axios.post(swapRouteParams(routes.CREATE_HUB_LOCATION, { id }), payload);
    return response.data;
  }

  export async function approveHubApplication(hubId: string, applicationId: string) {
    const response = await axios.post(
      swapRouteParams(routes.APPROVE_HUB_APPLICATION, { hubId, applicationId }),
    );
    return response.data;
  }

  export async function rejectHubApplication(hubId: string, applicationId: string) {
    const response = await axios.post(
      swapRouteParams(routes.REJECT_HUB_APPLICATION, { hubId, applicationId }),
    );
    return response.data;
  }
  // PUT REQUESTS

  export async function updateDonationMatchingProgram(
    object_id: string,
    program_id: string,
    payload: IDonationMatchingProgramUpdateParams,
  ) {
    const response = await axios.put<IDonationMatchingProgram>(
      swapRouteParams(routes.UPDATE_HUB_DONATION_MATCHING_PROGRAM, { object_id, program_id }),
      payload,
    );

    return response.data;
  }

  export async function updateHubStatus(id: string, status: string) {
    const response = await axios.put<{ hub: IHub; charges_enabled: boolean }>(
      swapRouteParams(routes.UPDATE_HUB_STATUS, { id }),
      { status },
    );
    return response.data;
  }

  export async function changeHubHandle(hubId: string, newHandle: string): Promise<IHub> {
    const response = await axios.put<IHub>(
      swapRouteParams(routes.CHANGE_HUB_HANDLE, { hubId: hubId }),
      { value: newHandle },
    );

    return response.data;
  }

  export async function updateAuctionItem(
    hubId: string,
    item_id: string,
    payload: IUpdateAuctionItemParams,
  ): Promise<IAuctionItem> {
    const response = await axios.put<IAuctionItem>(
      swapRouteParams(routes.UPDATE_HUB_AUCTION_ITEM, { hubId, item_id }),
      payload,
    );

    return response.data;
  }

  export async function updateStoreItem(
    hubId: string,
    item_id: string,
    payload: IStoreItem,
    medias?: string[],
  ): Promise<IStoreItem> {
    const _payload = payload;

    if (medias) {
      if (medias.length > 0) {
        _payload.media = await uploadMedias(medias);
      } else {
        _payload.media = [];
      }
    }

    const response = await axios.put<IStoreItem>(
      swapRouteParams(routes.UPDATE_HUB_STORE_ITEM, { hubId, item_id }),
      _payload,
    );

    return response.data;
  }

  export async function updateHub(payload: Partial<IHub>, hubId?: string) {
    const response = await axios.put<IHub>(
      swapRouteParams(routes.UPDATE_HUB, { hubId: payload.id || hubId }),
      { ...payload },
    );

    return response.data;
  }

  export async function updateHubPage(id: string, pageId: string, payload: IPage) {
    const response = await axios.put<IPage>(
      swapRouteParams(routes.UPDATE_HUB_PAGE, { id, page_id: pageId }),
      payload,
    );

    return response.data;
  }

  export async function updateBulkHubPages(id: string, payload: IPage[]) {
    const response = await axios.put<IPage>(
      swapRouteParams(routes.UPDATE_BULK_HUB_PAGES, { id }),
      payload,
    );

    return response.data;
  }

  export async function updateHubPageComponent(
    hubId: string,
    pageId: string,
    componentId: string,
    payload: IPageComponent,
  ) {
    const response = await axios.put<IPage>(
      swapRouteParams(routes.UPDATE_HUB_PAGE_COMPONENT, {
        id: hubId,
        page_id: pageId,
        comp_id: componentId,
      }),
      payload,
    );

    return response.data;
  }

  export async function addSpotlight(spotlights: ISpotlightList[], hubId: string) {
    return await updateHub({ spotlights }, hubId);
  }

  // DELETE REQUESTS

  export async function archiveHubLocation(id: string, locationId: string) {
    const response = await axios.delete(
      swapRouteParams(routes.DELETE_HUB_LOCATION, { id, locationId }),
    );
    return response.data;
  }

  export async function removeHubPartner(id: string, groupId: string) {
    const response = await axios.delete(swapRouteParams(routes.DELETE_HUB_PARTNERS, { id }), {
      data: { group_id: groupId },
    });
    return response.data;
  }

  export async function deleteAuctionItem(id: string, item_id: string) {
    const response = await axios.delete<IAuctionItem>(
      swapRouteParams(routes.DELETE_HUB_AUCTION_ITEM, { id, item_id }),
    );
    return response.data;
  }

  export async function deleteStoreItem(id: string, item_id: string) {
    const response = await axios.delete<IStoreItem>(
      swapRouteParams(routes.DELETE_HUB_STORE_ITEM, { id, item_id }),
    );
    return response.data;
  }

  export async function deleteHubPage(id: string, pageId: string) {
    const response = await axios.delete<IPage>(
      swapRouteParams(routes.DELETE_HUB_PAGE, { id, page_id: pageId }),
    );

    return response.data;
  }

  export async function deleteHubPageComponent(id: string, pageId: string, componentId: string) {
    const response = await axios.delete<IPage>(
      swapRouteParams(routes.DELETE_HUB_PAGE_COMPONENT, {
        id,
        page_id: pageId,
        comp_id: componentId,
      }),
    );

    return response.data;
  }

  export async function removeHubMember(id: string, memberId: string) {
    const response = await axios.delete<IUserRole>(
      swapRouteParams(routes.REMOVE_HUB_MEMBERS, { id, user_id: memberId }),
    );
    return response.data;
  }

  export async function getHubSpotlights(hubId: string) {
    const response = await axios.get<ISpotlightList[]>(
      swapRouteParams(routes.GET_HUB_SPOTLIGHTS, { id: hubId }),
    );
    return response.data;
  }

  /** Spotlights are current saved on the hub entity itself so we really just update the hub, minus the removed spotlight */
  export async function deleteHubSpotlight(hubId: string, spotlights: ISpotlightList[]) {
    const hub = updateHub({ spotlights }, hubId);
    return hub;
  }

  export async function duplicateCompanyEvent(
    hubId: string,
    body: { eventId: string },
  ): Promise<IEventSummaryFE> {
    const response = await axios.post(swapRouteParams(routes.DUPLICATE_HUB_EVENT, { hubId }), body);
    return response.data;
  }

  export async function getVolunteerMatchList(
    hubId: string,
    programId: string,
    query?: URLSearchParams,
  ) {
    const response = await axios.get<IVolunteerHoursMatchList[]>(
      swapRouteParams(routes.GET_VOLUNTEER_MATCH_LIST, { id: hubId, program_id: programId }),
      {
        params: query,
      },
    );
    return response.data;
  }

  export async function getAllVolunteerMatchList(hubId: string, query?: URLSearchParams) {
    const response = await axios.get<IVolunteerHoursMatchList[]>(
      swapRouteParams(routes.GET_VOLUNTEER_MATCH_LIST_ALL, { id: hubId }),
      {
        params: query,
      },
    );
    return response.data;
  }

  export async function getVolunteerMatchStatsForHub(hubId: string) {
    const response = await axios.post<IVolunteerMatchStats>(
      swapRouteParams(routes.GET_VOLUNTEER_MATCH_STATS_FOR_HUB, { id: hubId }),
    );
    return response.data;
  }

  export async function getVolunteerMatchStatsForHubAndProgram(hubId: string, programId: string) {
    const response = await axios.post<IVolunteerMatchStats>(
      swapRouteParams(routes.GET_VOLUNTEER_MATCH_STATS_FOR_PROGRAM, {
        id: hubId,
        program_id: programId,
      }),
    );
    return response.data;
  }

  export async function getPaymentPackageStats(hubId: string, packageId: string) {
    const response = await axios.get<IUserCreditPaymentPackageStats>(
      swapRouteParams(routes.GET_PAYMENT_PACKAGE_STATS, { id: hubId, package_id: packageId }),
    );
    return response.data;
  }
}
