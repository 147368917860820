import React, { LegacyRef, useRef, useState } from 'react';

import './CollapsibleSection.scss';

interface ICollapsibleSection {
  label: string;
  children?: React.ReactNode;
}

export default function CollapsibleSection(props: ICollapsibleSection) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // contentRef for animation
  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <div className="CollapsibleSection">
      <div
        className="filter-expandable"
        onClick={() => setIsOpen(!isOpen)}
      >
        {' '}
        {props.label}{' '}
      </div>

      <div
        className="content-parent"
        ref={contentRef}
        style={isOpen ? { height: contentRef?.current?.scrollHeight + 'px' } : { height: '0px' }}
      >
        <div className="collapsible-content"> {props.children} </div>
      </div>
    </div>
  );
}
