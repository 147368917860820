import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as CTooltip,
  Legend,
} from 'chart.js';
import { IUserRole, IDonor } from '@gigit/interfaces';
import { IAppState } from '../../../store';
import {
  routes,
  swapRouteParams,
  handleInputChange,
  defaultCurrency,
  formatCurrency,
  toastError,
} from '../../../helpers';
import { IGroupState } from '../../../reducers/group';
import { createToast } from '../../../actions/toaster';
import Button from '../../Button/Button';
import TextField from '../../TextField/TextField';
import Modal from '../../Modal/Modal';
import { Tooltip } from '../../shared/Tooltip/Tooltip';

import './DonorSummary.scss';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { userSelectors } from '../../../selectors/user';
import { ChartData, ChartOptions } from 'chart.js';

const ChartHeight = 100;
const DefaultForecast = 1000;
const DefaultGoal = 1000;

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, CTooltip, Legend);

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  locale: string;

  createToast(toast: IToast): void;
}

interface IState {
  donor: IDonor | null;
  data: ChartData<'bar'>;
  options: ChartOptions;
  showGoalUpdate: boolean;
  showForecastUpdate: boolean;
  goalAmt: number;
  forecast: number;
  lastContact: string;
  isMounted: boolean;
}

class DonorSummary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      donor: null,
      data: {
        labels: ['Progress', 'Forecast', 'Goal'],
        datasets: [
          {
            label: '',
            data: [0, DefaultForecast, DefaultGoal],
            barThickness: 20,
          },
        ],
      },
      options: {
        indexAxis: 'y' as const,
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'right' as const,
          },
          title: {
            display: true,
            text: 'Chart.js Horizontal Bar Chart',
          },
        },

        // maintainAspectRatio: false,
        // legend: {
        //     display: false
        // },
        // scales: {
        //     xAxes: [{
        //         gridLines: {
        //             display:false
        //         },
        //         ticks: {
        //             beginAtZero: true
        //         }
        //     }],
        //     yAxes: [{
        //         ticks: {
        //             beginAtZero: true
        //         }
        //     }]
        // }
      },
      showGoalUpdate: false,
      showForecastUpdate: false,
      goalAmt: 1000,
      forecast: 1000,
      lastContact: '',
      isMounted: false,
    };
  }

  componentDidMount() {
    this.getDonor();
    this.setState({
      isMounted: true,
    });
  }

  setData() {
    let canvas = document.getElementsByTagName('canvas')[0].getContext('2d'),
      gradient = canvas ? canvas.createLinearGradient(0, 0, 0, 450) : null;
    let donated = this.state.donor?.total_donated || 0;
    let forecast = this.state.donor?.current_forecast?.value || DefaultForecast;
    let goal = this.state.donor?.current_year_goal?.value || DefaultGoal;

    if (canvas && gradient) {
      gradient.addColorStop(0, '#008599');
      gradient.addColorStop(1, '#734FD5');
    }
    let data: ChartData<'bar'> = {
      labels: ['Progress', 'Forecast', 'Goal'],
      datasets: [
        {
          label: '',
          data: [donated, forecast, goal],
          barThickness: 20,
          borderWidth: 2,
          backgroundColor: gradient || '#0C9FB5',
        },
      ],
    };

    this.setState({
      data: data,
    });
  }

  getCommunicationRecords() {
    axios
      .get(
        swapRouteParams(routes.CREATE_COMMUNICATION_RECORD, {
          groupId: this.props.groupState.group.id,
          memberId: this.props.contact.user?.id,
        }),
      )
      .then((response) => {
        if (response.data?.length > 0) {
          let latest = response.data.reverse()[0];
          latest = localizeHelpers.formatDate(
            latest.created_at,
            LocaleDateFormats.XLL,
            this.props.locale,
          );
          this.setState({
            lastContact: latest,
          });
        }
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Communication Records');
        this.props.createToast(toast);
      });
  }

  getDonor() {
    axios
      .get(
        swapRouteParams(routes.GET_DONOR, {
          groupId: this.props.groupState.group.id,
          contactId: this.props.contact.user?.id,
        }),
      )
      .then((response) => {
        this.setState(
          {
            donor: response.data,
            goalAmt: response.data?.current_year_goal?.value || DefaultGoal,
            forecast: response.data?.current_forecast?.value || DefaultForecast,
          },
          () => {
            this.getCommunicationRecords();
            this.setData();
          },
        );
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Donor Info');
        this.props.createToast(toast);
      });
  }

  updateDonor() {
    let payload = {
      current_forecast: { value: this.state.forecast },
      current_year_goal: { value: this.state.goalAmt },
    };
    axios
      .put(
        swapRouteParams(routes.GET_DONOR, {
          groupId: this.props.groupState.group.id,
          contactId: this.props.contact.user?.id,
        }),
        payload,
      )
      .then(() => {
        this.setState(
          {
            showGoalUpdate: false,
            showForecastUpdate: false,
          },
          () => {
            this.getDonor();
          },
        );
      })
      .catch((error) => {
        const errorObj = errorHelpers.getErrorObject(error);
        let msg;

        if (errorObj.errorCode === 'ERROR.DONORS.DONOR_DOES_NOT_EXIST') {
          msg = localizeHelpers.translate(
            'This user is not a donor yet, once they are a donor these settings can be set',
          );
        }

        const toast = toastError(msg || errorObj.translatedMessage, 'Update Donor Info');
        this.props.createToast(toast);
      });
  }

  render() {
    let currency = this.props.groupState.group.account?.currency || defaultCurrency;

    return (
      <div className="DonorSummary">
        <div className="cd-stat-row">
          <div className="cd-stat-box">
            <div
              notranslate="yes"
              className="stat"
            >
              {this.state.lastContact || 'N/A'}
            </div>
            <div className="stat-label">Last contact</div>
          </div>
          <div className="cd-stat-box">
            <div className="stat">
              <var data-var="last_donation_amount">
                {formatCurrency(
                  this.state.donor?.last_donation?.amount || 0,
                  currency,
                  this.props.locale,
                )}
              </var>
            </div>
            <div className="stat-label">Last gift amount</div>
          </div>
          <div className="cd-stat-box">
            <div
              notranslate="yes"
              className="stat"
            >
              {localizeHelpers.formatDate(
                this.state.donor?.last_donation?.created_at ?? new Date(),
                LocaleDateFormats.XLL,
                this.props.locale,
              )}
            </div>
            <div className="stat-label">Last gift date</div>
          </div>
          <div className="cd-stat-box">
            <div className="stat">
              <var data-var="amount_donated_last_year">
                {formatCurrency(
                  this.state.donor?.amount_donated_last_year || 0,
                  currency,
                  this.props.locale,
                )}
              </var>
            </div>
            <div className="stat-label">Donated last year</div>
          </div>
        </div>
        <div className="donor-metrics">
          <div className="donor-chart">
            <div className="metric-label">
              <div>
                <label>Goals</label>
                <Tooltip
                  className="donor-summary-tooltip"
                  direction="right"
                  renderTooltip={() => {
                    return (
                      <React.Fragment>
                        <div className="donor-summary-tt">
                          The goal to raise from that donor in the current year.
                        </div>
                      </React.Fragment>
                    );
                  }}
                >
                  {(ref) => (
                    <i
                      ref={ref}
                      className="fas fa-question-circle"
                    />
                  )}
                </Tooltip>
              </div>
              <i
                className="fa fa-pencil"
                onClick={() => {
                  this.setState({ showGoalUpdate: true });
                }}
              />
            </div>
            {this.state.isMounted && (
              <Bar
                data={this.state.data}
                options={this.state.options}
                height={ChartHeight}
              />
            )}
            <span>
              {' '}
              {`Last Updated at `}{' '}
              <var data-var="last_updated_at">
                {localizeHelpers.formatDate(
                  this.state.donor?.updated_at ?? new Date(),
                  LocaleDateFormats.lll,
                  this.props.locale,
                )}{' '}
              </var>
            </span>
          </div>
        </div>

        <Modal
          class="add-field"
          show={this.state.showGoalUpdate}
          onClose={() => {
            this.setState({ showGoalUpdate: false });
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.updateDonor();
            }}
            className="af"
          >
            <div className="af-title">Update Goal</div>
            <div className="af-subtitle">Details</div>
            <TextField
              label={'Goal'}
              required={true}
              value={this.state.goalAmt}
              name="goalAmt"
              type="text"
              onChange={(e) => handleInputChange(e, this)}
            />
            <TextField
              label={'Forecast'}
              required={true}
              value={this.state.forecast}
              name="forecast"
              type="text"
              onChange={(e) => handleInputChange(e, this)}
            />
            <div className="af-actions">
              <Button text={'Save'} />
              <Button
                text={'Cancel'}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showGoalUpdate: false });
                }}
              />
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(DonorSummary);
