import { Constants } from '@gigit/constants';
import {
  IAvailablePremiumFeature,
  IHub,
  IOrgPremiumFeaturesSummary,
  IPremiumFeatureBillingHistoryItem,
  IRole,
} from '@gigit/interfaces';

import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { uiConstants } from '../../../../constants';
import {
  defaultCurrency,
  downloadFile,
  formatCurrency,
  routes,
  typeHelpers,
} from '../../../../helpers';
import useToastDispatcher from '../../../../hooks/useToaster';
import { IOwnerObject } from '../../../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../../localizeHelpers';
import { billingRequestActions, hubRequestActions } from '../../../../requestActions';
import { userRequestActions } from '../../../../requestActions/users';
import { userSelectors } from '../../../../selectors/user';
import Button from '../../../Button/Button';
import { usePaymnetInfo } from '../../../shared/Onboarding/hooks/usePaymentInfo';
import { PaymentComponent } from '../../../shared/PaymentComponent/PaymentComponent';
import SortableTable, { ISortableTableActions } from '../../../SortableTable/SortableTable';
import HubInviteMembers from '../../HubModals/HubInviteMembers/HubInviteMembers';
import { useSelector } from 'react-redux';
import { useLocale } from '../../../../hooks';
import './HubManagementSeats.scss';
import HubSeatPurchaseModal from './HubSeatPurchaseModal/HubSeatPurchaseModal';
import { IAppState } from '../../../../store';
import { useHistory, useLocation } from 'react-router-dom';
import HubTrailWarningBanner from '../../Shared/HubTrailWarningBanner/HubTrailWarningBanner';
import HubPaymentModal from '../../HubModals/HubPaymentModal/HubPaymentModal';

import Modal from '../../../Modal/Modal';
import HubManagementInvoiceModal, {
  IInvoiceModalInfo,
} from '../HubManagementInvoiceModal/HubManagementInvoiceModal';
import { Config } from '@gigit/config';

interface IProps {
  hub?: IHub;
  hubRoles?: IRole[];
}

const HubManagementSeats = (props: IProps) => {
  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const local = useLocale();
  const [showAfterSavePayment, setShowAfterSavePayment] = useState<'invite' | 'seats' | null>(null);
  const [showWarningTrialModal, setShowWarningTrialModal] = useState<boolean>(false);
  const { paymentInfo, handlePaymentInfo } = usePaymnetInfo();
  const [showPurchaseSeats, setShowPurchaseSeats] = useState<boolean>(false);
  const [invoiceTableItems, setInvoiceTableItems] = useState<IPremiumFeatureBillingHistoryItem[]>(
    [],
  );
  const [isInvoiceTableLoading, setIsInoviceTableLoading] = useState<boolean>(false);
  const [seatPricing, setSeatPricing] = useState<IAvailablePremiumFeature | null>(null);
  const [seatsUsed, setSeatsUsed] = useState<number>(0);
  const [totalSeats, setTotalSeats] = useState<number>(0);
  const [invoiceAmount, setInvoiceAmount] = useState<number>(0);
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(null);
  const [invoiceCurrency, setInvoiceCurrency] = useState<string>(defaultCurrency);
  const [adminCount, setAdminCount] = useState<number>(0);
  const [showPaymentMethods, setShowPaymentMethods] = useState<boolean>(false);
  const [triggerPaymentPrep, setTriggerPaymentPrep] = useState<boolean>(false);
  const [triggerShowExitingMethods, setTriggerShowExitingMethods] = useState<boolean>(false);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [disablePaymentMethodSave, setDisablePaymentMethodSave] = useState<boolean>(true);
  const [owner, setOwner] = useState<IOwnerObject>();
  const [subscriptionSummary, setSubscriptionSummary] = useState<IOrgPremiumFeaturesSummary>();
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<string>('');
  const [isVirtual, setIsVirtual] = useState<boolean>(false);
  const [hubSeatsAvailable, setHubSeatsAvailable] = useState<number>(0);
  const [togglePaymentButtonLoading, setTogglePaymentButtonLoading] = useState<boolean>(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<boolean>(false);
  const [showInvoiceModalInfo, setShowInvoiceModalInfo] = useState<IInvoiceModalInfo | null>(null);
  const [cardLast4Digits, setCardLast4Digits] = useState<string>('');
  const [cardName, setCardName] = useState<string>('');
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const location = useLocation();
  const history = useHistory();
  const currency = props.hub?.account?.currency;

  const trialFeature = subscriptionSummary?.features.find((feature) => feature.is_trial);

  useEffect(() => {
    billingRequestActions
      .getAvailableSubscriptions(Constants.billing.subscriptions.hub_seating.object_type)
      .then((resp) => {
        setSeatPricing(resp[0]);
      })
      .catch((error) => {
        dispatchToastError(error, 'Subscriptions');
      });
  }, []);

  useEffect(() => {
    setIsInoviceTableLoading(true);
    if (props.hub && props.hub?.id) {
      let ownerObj = typeHelpers.createOwnerObject('hub', props.hub);
      setOwner(ownerObj);
      setMinSeatCounter(props.hub?.id);
      if (props.hub.id) {
        getInvoiceTableItems(props.hub.id);
      }
    }
  }, [props.hub]);

  useEffect(() => {
    if (props.hubRoles && props.hub) {
      let adminRole = props.hubRoles.find((role) => role.role_name.toLowerCase() === 'admin');

      if (adminRole && adminRole.id && props.hub.id) {
        userRequestActions
          .getUsersByRoleId(uiConstants.ownerType.hub, props.hub.id, adminRole.id)
          .then((response) => {
            setAdminCount(response?.data.length || 0);
          })
          .catch((error) => {
            dispatchToastError(error, 'Hub Roles');
          });
      }
    }
  }, [props.hubRoles]);

  useEffect(() => {
    if (subscriptionSummary) {
      const queryParams = new URLSearchParams(location.search);
      const openInviteModal = queryParams.get('invitesModal');

      if (openInviteModal) {
        attemptOpenInviteMembersModal();
        history.replace({ search: '' });
      }
    }
  }, [location.search, subscriptionSummary]);

  async function getInvoiceTableItems(id: string) {
    let info = await billingRequestActions.getPremiumFeatureSummary(uiConstants.ownerType.hub, id);
    let isShared = info.features.find(
      (feature) => feature.feature_code === Constants.billing.subscriptions.share_package.code,
    );
    if (!isShared) {
      let currentSub = info.features.find((feature) => feature.seats.payed > 0);
      if (currentSub) {
        billingRequestActions
          .getHubSubscriptionHistory(id, currentSub?.feature_code)
          .then((response) => {
            setInvoiceTableItems(response);
            setIsInoviceTableLoading(false);
          })
          .catch((error) => {
            dispatchToastError(error, 'Subscriptions');
          });
      }
    } else {
      setInvoiceTableItems([]);
      setIsInoviceTableLoading(false);
    }
  }

  function setMinSeatCounter(hubId: string) {
    getInvoiceTableItems(hubId);
    billingRequestActions
      .getHubSubscriptionSummary(hubId)
      .then((response) => {
        setSubscriptionSummary(response);
        const seatsFeature = response.features.find((feature) => feature.seats.payed > 0);
        /*
                const seatsFeature = response.features.find(
                    (feature) => feature.feature_code === Constants.billing.subscriptions.hub_seating.code
                );
                */

        if (seatsFeature && seatsFeature.seats) {
          setHubSeatsAvailable(seatsFeature.seats?.total - seatsFeature.seats?.used);
        }

        const isVirtualSub = response.features.find((feature) => feature.is_virtual);

        setIsVirtual(!!isVirtualSub);

        if (
          seatsFeature?.payment_method_details &&
          seatsFeature?.payment_method_details.payment_method_id
        ) {
          setDefaultPaymentMethodId(seatsFeature?.payment_method_details?.payment_method_id);
          setCardLast4Digits(seatsFeature?.payment_method_details?.credit_card_last_4_digits);
          setCardName(seatsFeature?.payment_method_details?.credit_card_brand);
        }
        setSeatsUsed(seatsFeature?.seats?.used || 0);
        setTotalSeats(seatsFeature?.seats?.total || 0);
        setInvoiceAmount(seatsFeature?.next_invoice_cost || 0);
        setInvoiceDate(seatsFeature?.period_end_date || null);
        setInvoiceCurrency(seatsFeature?.currency || defaultCurrency);
      })
      .catch((err) => {
        dispatchToastError(err, 'Purchase Seats');
      })
      .catch((error) => {
        dispatchToastError(error, 'Subscriptions');
      });
  }

  async function handlePaymentMethodId(id: string) {
    if (id) {
      try {
        setDisablePaymentMethodSave(false);
        if (paymentInfo.isNewMethod) {
          let payload = {
            payment_method_id: id,
          };

          const response = await axios.post(routes.GET_PAYMENT_METHODS, payload);
          let confirm = await paymentInfo.stripe?.confirmCardSetup(response.data.client_secret);

          if (confirm && confirm.error) {
            dispatchToastError(localizeHelpers.translate(confirm.error.message), 'Payment Method');
            return;
          }
        }

        const premiumFeaturesSummary = await billingRequestActions.updateHubSubscription(
          props.hub?.id!,
          Config.feature_flags.HUB_SEATING_DO
            ? subscriptionSummary?.features[0].feature_code!
            : Constants.billing.subscriptions.hub_seating.code,
          { payment_method_id: id },
        );
        setSubscriptionSummary(premiumFeaturesSummary);

        setShowPaymentMethods(false);

        dispatchToastSuccess('Payment method changed successfully.', 'Payment Method');
      } catch (error) {
        dispatchToastError(error, 'Payment Method');
      }
    }
  }

  const hubSeatFeature = subscriptionSummary?.features?.find((feature) =>
    [
      Constants.billing.subscriptions.hub_seating.code,
      Constants.billing.subscriptions.hub_seating_do.code,
    ].includes(feature.feature_code),
  );

  function attemptOpenInviteMembersModal() {
    if (trialFeature && trialFeature.seats.used >= trialFeature.seats.total) {
      setShowAfterSavePayment('invite');
      setShowWarningTrialModal(true);
    } else {
      if (
        hubSeatFeature?.payment_method_details?.payment_method_id ||
        subscriptionSummary ||
        trialFeature
      ) {
        setShowInviteModal(true);
      } else {
        dispatchToastError(
          localizeHelpers.translate(
            "Your hub doesn't have a default payment method setup. Before you can invite additional members you must have a valid payment method.",
          ),
          'Invite Members',
          [
            {
              text: 'Click here to add a payment method',
              onClick: () => setShowPaymentMethods(true),
            },
          ],
        );
      }
    }
  }

  async function onSaveAddPaymentMethod(payment_method_id: string) {
    if (trialFeature?.seats?.total && owner?.ownerId) {
      const payload = {
        payment_method_id,
      };

      // Leaving this hardcoded to hub_seating as a default when upgrading directly from a trial
      const updated = await billingRequestActions.createHubSeatSubscriptionEndTrial(
        owner.ownerId,
        Constants.billing.subscriptions.hub_seating.code,
        payload,
      );
      setSubscriptionSummary(updated.premiumFeatures);

      if (props.hub?.id) {
        setMinSeatCounter(props.hub?.id);
        getInvoiceTableItems(props.hub.id);
      }

      setShowPaymentMethodModal(false);

      if (showAfterSavePayment === 'invite') {
        setShowInviteModal(true);
      } else if (showAfterSavePayment === 'seats') {
        setShowPurchaseSeats(true);
      }

      setShowAfterSavePayment(null);
    }
  }

  function getPaymentDescription() {
    if (trialFeature && trialFeature?.trial_end_date) {
      const price =
        (Constants.billing.subscriptions.hub_seating.price_in_cents / 100) *
        12 *
        trialFeature.seats.payed;
      const endTrialDate = localizeHelpers.formatDate(
        trialFeature.trial_end_date,
        LocaleDateFormats.LL,
        local.currentLocale,
      );
      const renewDate = localizeHelpers.formatDate(
        new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        LocaleDateFormats.LL,
        local.currentLocale,
      );

      if (new Date(trialFeature.trial_end_date).getTime() > new Date().getTime()) {
        return `<p>You are attempting to update your company payment method.</p>

                        <p>Your trial is currently set to end on <b>${endTrialDate}</b>. If you upgrade now, you will be charged <b>${formatCurrency(price, trialFeature.currency || defaultCurrency, locale)}</b> today for the <b>${trialFeature.seats.payed}</b> trial seats already in use and you will be able to start purchasing additional seats.</p>
                        
                        <p>Your new subscription amount will be <b>${formatCurrency(price, trialFeature.currency || defaultCurrency, locale)}</b> and renew on <b>${renewDate}</b></p>
                        
                        <p>Do you wish to continue?</p>`;
      }
      return `<p>You are attempting to update your company payment method.</p>

                    <p>You will be charged <b>${formatCurrency(price, trialFeature.currency || defaultCurrency, locale)}</b> immediately for the <b>${trialFeature.seats.payed}</b> seats that have already been added.</p> 
                    
                    <p>Your new subscription amount will be <b>${formatCurrency(price, trialFeature.currency || defaultCurrency, locale)}</b> and will renew on <b>${renewDate}</b>.</p> 
                    
                    <p>Do you wish to continue?</p>`;
    }

    return '';
  }

  function renderMetricBoxInner(label: string, value: string | number | Date) {
    return (
      <>
        <div className="metric-label">{label}</div>
        <div className="metric-data">{value}</div>
      </>
    );
  }

  async function downloadReceipt(invoice_id: string, invoice_number: string) {
    if (props?.hub?.id) {
      try {
        const data = await hubRequestActions.downloadSeatReceipt(
          props.hub.id,
          Constants.billing.subscriptions.hub_seating.code,
          invoice_id,
        );

        downloadFile(`Seats_Invoice_${invoice_number}`, data, 'application/pdf');
      } catch (err) {
        dispatchToastError(err, 'Download Seat Receipt');
      }
    }
  }

  return (
    <div className="HubManagementSeats">
      <div className="container-header">
        <h2>Seats</h2>
      </div>
      <h2>Billing</h2>
      <div className="seats-metrics">
        <div className="metric-box first">{renderMetricBoxInner('Admins', adminCount)}</div>
        <div className="metric-box no-right-border">
          {renderMetricBoxInner('Total Seats', `${seatsUsed}/${totalSeats}`)}
        </div>
        <div className="metric-box">
          {renderMetricBoxInner(
            'Invoice Total',
            formatCurrency(invoiceAmount, currency || defaultCurrency, locale),
          )}
        </div>
        <div
          className="metric-box last"
          notranslate="yes"
        >
          {trialFeature && trialFeature?.trial_end_date
            ? renderMetricBoxInner(
                localizeHelpers.translate('Trial End Date'),
                localizeHelpers.formatDate(
                  trialFeature.trial_end_date,
                  LocaleDateFormats.LL,
                  locale,
                ),
              )
            : renderMetricBoxInner(
                localizeHelpers.translate('Billed Annually on'),
                invoiceDate
                  ? localizeHelpers.formatDate(invoiceDate, LocaleDateFormats.LL, locale)
                  : 'N/A',
              )}
        </div>
      </div>
      {trialFeature && trialFeature.trial_end_date && props.hub?.handle && (
        <div className="trail-banner-container">
          <HubTrailWarningBanner
            trial_end_date={trialFeature.trial_end_date}
            handle={props.hub.handle}
          />
        </div>
      )}
      {!isVirtual && (
        <>
          <h3 className="section-title">Payment Method</h3>
          <div
            onClick={() => setShowPaymentMethods(!showPaymentMethods)}
            className={`edit-content-container ${!showPaymentMethods ? 'collapsed' : ''}`}
          >
            <div className="section-subtitle">
              <h4>Update Payment Method</h4>
              <span>
                Select your method of payment that will be billed annually for seats attached to
                your company.
              </span>
            </div>
          </div>
          {showPaymentMethods && (
            <>
              <div className="payment-method-container">
                <PaymentComponent
                  title=""
                  notCreationFlow={true}
                  priceDetails={[]}
                  showBillingForm={true}
                  selectDefaultMethod={true}
                  paymentInfo={paymentInfo}
                  showExistingMethods={triggerShowExitingMethods}
                  preparePaymentMethod={triggerPaymentPrep}
                  onPaymentInfoUpdated={handlePaymentInfo}
                  onPaymentMethodReady={handlePaymentMethodId}
                  onMethodChange={() => setDisablePaymentMethodSave(false)}
                  defaultPaymentMethodId={hubSeatFeature?.payment_method_details?.payment_method_id}
                />
              </div>
              <div className="payment-action">
                <Button
                  isDisabled={disablePaymentMethodSave}
                  onClick={() => setTriggerPaymentPrep(!triggerPaymentPrep)}
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </>
      )}

      <div>
        <SortableTable
          loading={isInvoiceTableLoading}
          columns={[
            { id: 'date', label: 'Date', sortable: false },
            { id: 'seats', label: 'Seats' },
            { id: 'total', label: 'Total' },
            { id: 'status', label: 'Status' },
            { id: 'actions', label: '' },
          ]}
          onSort={(sortSettings) => {}}
          data={invoiceTableItems.map((item) => {
            let status = uiConstants.invoiceStatus.draft;
            switch (item.status) {
              case uiConstants.invoiceStatus.draft.toLowerCase():
                status = uiConstants.invoiceStatus.draft;
                break;
              case uiConstants.invoiceStatus.open.toLowerCase():
                status = uiConstants.invoiceStatus.open;
                break;
              case uiConstants.invoiceStatus.paid.toLowerCase():
                status = uiConstants.invoiceStatus.paid;
                break;
              case uiConstants.invoiceStatus.uncollectible.toLowerCase():
                status = uiConstants.invoiceStatus.uncollectible;
                break;
              case uiConstants.invoiceStatus.void.toLowerCase():
                status = uiConstants.invoiceStatus.void;
                break;
            }

            let _actions: ISortableTableActions[] = [
              {
                label: 'View Receipt',
                icon: '',
                onClick: () => {
                  setShowInvoiceModalInfo({
                    currency: item.currency || defaultCurrency,
                    total: formatCurrency(
                      item.total / 100,
                      item.currency || defaultCurrency,
                      locale,
                    ),
                    num_of_seats: item?.quantity || 0,
                    date: item.created_at,
                    product: props.hub?.title || '',
                    send_to: props.hub?.created_by?.display_name || '',
                    status: item.status || 'N/A',
                    rate_per_seat: formatCurrency(
                      item.total > 0
                        ? Constants.billing.subscriptions.hub_seating.price_in_cents / 100
                        : 0,
                      item.currency || defaultCurrency,
                      locale,
                    ),
                    invoice_number: item.invoice_number || 'N/A',
                    receipt_number: item?.receipt_number || 'N/A',
                    card_last_4_digits: cardLast4Digits,
                    card_name: cardName,
                  });
                },
              },
              {
                label: 'Download Receipt',
                icon: '',
                onClick: async () => {
                  await downloadReceipt(item.id, item?.receipt_number || '');
                },
              },
            ];

            return {
              row: [
                {
                  id: 'date',
                  content: (
                    <div notranslate="yes">
                      {localizeHelpers.formatDate(item.created_at, LocaleDateFormats.LL, locale)}
                    </div>
                  ),
                },
                {
                  id: 'seats',
                  content: <div>{item.quantity}</div>,
                },
                {
                  id: 'total',
                  content: (
                    <div>
                      {formatCurrency(item.total / 100, item.currency || defaultCurrency, locale)}
                    </div>
                  ),
                },
                { id: 'status', content: <div>{status}</div> },
                {
                  id: 'actions',
                  menu: _actions,
                },
              ],
            };
          })}
        />
      </div>
      {owner && (
        <>
          <HubInviteMembers
            owner={owner}
            showAddMemberModal={showInviteModal}
            toggleShowAddMemberModal={function (newToggleValue: boolean): void {
              setShowInviteModal(!showInviteModal);
            }}
            objectType="hub"
            hubSeatsAvailable={hubSeatsAvailable}
          />
          <HubPaymentModal
            ownerId={owner?.ownerId || ''}
            showModal={showPaymentMethodModal}
            description={getPaymentDescription()}
            title="Add Payment Method"
            confirmButton="Save changes"
            onConfirmation={async (paymentMethodId: string) => {
              await onSaveAddPaymentMethod(paymentMethodId);
            }}
            toggleCloseModal={() => setShowPaymentMethodModal(false)}
            toggleButtonLoading={togglePaymentButtonLoading}
          />
        </>
      )}

      <Modal
        class="HubWarningTrial-Modal"
        title="Oops! You’re still in trial mode"
        closeIcon="fas fa-times"
        onClose={() => setShowWarningTrialModal(false)}
        show={showWarningTrialModal}
      >
        <div className="inner">
          {trialFeature?.seats.total && trialFeature?.trial_end_date && (
            <>
              <p>
                Your account is currently in trial mode. Your trial for {trialFeature.seats.payed}{' '}
                users will end on{' '}
                {
                  <b>
                    {localizeHelpers.formatDate(
                      trialFeature.trial_end_date,
                      LocaleDateFormats.LL,
                      local.currentLocale,
                    )}
                  </b>
                }
                .
              </p>
              <p>
                Each new user is{' '}
                <b>
                  {formatCurrency(
                    Constants.billing.subscriptions.hub_seating.price_in_cents / 100,
                    trialFeature.currency || defaultCurrency,
                    locale,
                  )}
                </b>{' '}
                per month /{' '}
                <b>
                  {formatCurrency(
                    (Constants.billing.subscriptions.hub_seating.price_in_cents / 100) * 12,
                    trialFeature.currency || defaultCurrency,
                    locale,
                  )}
                </b>{' '}
                a year. To upgrade your account and start inviting new users, you will be charged{' '}
                {
                  <b>
                    {formatCurrency(
                      (Constants.billing.subscriptions.hub_seating.price_in_cents / 100) *
                        trialFeature.seats.payed *
                        12,
                      trialFeature.currency || defaultCurrency,
                      locale,
                    )}
                  </b>
                }{' '}
                today. Your renewal amount will be{' '}
                {
                  <b>
                    {formatCurrency(
                      (Constants.billing.subscriptions.hub_seating.price_in_cents / 100) *
                        trialFeature.seats.payed *
                        12,
                      trialFeature.currency || defaultCurrency,
                      locale,
                    )}
                  </b>
                }{' '}
                and renew on{' '}
                {
                  <b>
                    {localizeHelpers.formatDate(
                      new Date(
                        new Date(trialFeature.trial_end_date).setFullYear(
                          new Date(trialFeature.trial_end_date).getFullYear() + 1,
                        ),
                      ),
                      LocaleDateFormats.LL,
                      local.currentLocale,
                    )}
                  </b>
                }
                .
              </p>
            </>
          )}
          <div className="action">
            <Button
              className="cancel-btn"
              buttonType="outline-dark"
              onClick={() => setShowWarningTrialModal(false)}
              text="Cancel"
            />
            <Button
              buttonType="dark"
              isDisabled={false}
              onClick={() => {
                setShowWarningTrialModal(false);
                setShowPaymentMethodModal(true);
              }}
              text="Yes, upgrade"
            />
          </div>
        </div>
      </Modal>

      {showInvoiceModalInfo && (
        <HubManagementInvoiceModal
          show={!!showInvoiceModalInfo}
          onClose={() => setShowInvoiceModalInfo(null)}
          info={showInvoiceModalInfo}
        />
      )}
    </div>
  );
};

export default HubManagementSeats;
function createOwnerObject() {
  throw new Error('Function not implemented.');
}
