import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { IStringMap, handleInputChange } from '../../../helpers';
import { RouteComponentProps } from 'react-router-dom';
import { IPage, IGig } from '@gigit/interfaces';

import { getGroupGigs, updateGroupPageComponent } from '../../../actions/group';
import { getEventGigs, updateEventPageComponent } from '../../../actions/event';

import { IGroupState } from '../../../reducers/group';
import { IEventState } from '../../../reducers/event';

import GigListItem from '../GigListItem/GigListItem';

import './GigList.scss';
import TextField from '../../TextField/TextField';
import ComponentPlaceholder from '../ComponentPlaceholder/ComponentPlaceholder';
import { IOwnerObject } from '../../../interfaces/ownerObject';
import { IVolunteerOpportunitiesListComponent, ReduxActionType } from '../../../interfaces';
import { IUserState } from '../../../reducers/user';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { Constants } from '@gigit/constants';
import { uiConstants } from '../../../constants';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  userState: IUserState;
  eventState: IEventState;
  getGroupGigs: ReduxActionType<typeof getGroupGigs>;
  getEventGigs: ReduxActionType<typeof getEventGigs>;
  updateGroupPageComponent: ReduxActionType<typeof updateGroupPageComponent>;
  updateEventPageComponent: ReduxActionType<typeof updateEventPageComponent>;
  permissions?: IStringMap;
  component: IVolunteerOpportunitiesListComponent;
  page: IPage;
  edit: boolean;
  onCreateGigClicked: () => void;
  onComponentUpdate?(): void;
  owner: IOwnerObject;
  hasEditPermissions?: boolean;
}

interface IState {
  title: string;
  gigList: IGig[];
}

// Using GigList still as the VolunteerOpportunities component
// refactoring GigList and GigListItems causes issues with pre-existing components
class GigList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      title: this.props.component.title || 'Volunteer Opportunities',
      gigList: this.props.component.content_objects || [],
    };
    this.handleComponentDragEnd = this.handleComponentDragEnd.bind(this);
  }

  componentDidMount() {
    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      if (this.props.permissions && this.props.permissions['EDIT_GROUP_PAGES']) {
        this.props.getGroupGigs(this.props.owner.ownerId);
      }
    } else {
      if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
        this.props.getEventGigs(this.props.owner.ownerId);
      }
    }

    if (
      this.props.permissions &&
      this.props.permissions['EDIT_EVENT_PAGES'] &&
      this.props.owner.ownerType === uiConstants.ownerType.event &&
      this.props.eventState.gigs.length > this.state.gigList.length
    ) {
      const objectIdsDifferenceById = this.props.eventState.gigs
        .filter((gig) => !this.state.gigList.find((item) => item.id === gig.id))
        .map((gig) => gig.id);
      const objectIds = [...this.state.gigList.map((item) => item.id), ...objectIdsDifferenceById];
      // this.props.eventState.gigs = this.state.gigList;
      this.props.updateEventPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: uiConstants.ownerType.gig,
            object_ids: objectIds,
          },
        },
        () => {
          this.setState({ gigList: this.props.eventState.gigs });
        },
      );
    }

    if (
      this.props.permissions &&
      this.props.permissions['EDIT_GROUP_PAGES'] &&
      this.props.owner.ownerType === uiConstants.ownerType.group &&
      this.props.groupState.groupGigs.length > this.state.gigList.length
    ) {
      const objectIdsDifferenceById = this.props.groupState.groupGigs
        .filter((gig) => !this.state.gigList.find((item) => item.id === gig.id))
        .map((gig) => gig.id);
      const objectIds = [...this.state.gigList.map((item) => item.id), ...objectIdsDifferenceById];
      // this.props.eventState.gigs = this.state.gigList;
      this.props.updateGroupPageComponent(
        this.props.owner.ownerId,
        this.props.page.id,
        this.props.component.id,
        {
          content_references: {
            object_type: uiConstants.ownerType.gig,
            object_ids: objectIds,
          },
        },
        () => {
          this.setState({ gigList: this.props.groupState.groupGigs });
        },
      );
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.edit && !this.props.edit) {
      this.update();
    }

    if (prevProps.eventState.components !== this.props.eventState.components) {
      this.props.getEventGigs(this.props.owner.ownerId);
    }
  }

  update() {
    const payload = this.props.component;

    payload.title = this.state.title;

    if (this.props.permissions && this.props.permissions['EDIT_GROUP_PAGES']) {
      this.props.updateGroupPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    } else if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
      this.props.updateEventPageComponent(
        this.props.page.owner_id,
        this.props.page.id,
        this.props.component.id,
        payload,
      );
    }
  }

  handleComponentDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (result.destination !== null) {
      let _gigList = this.state.gigList;

      _gigList.splice(
        Number(result.destination?.index),
        0,
        _gigList.splice(result.source.index, 1)[0],
      );

      this.setState(
        {
          gigList: _gigList,
        },
        () => {
          let _object_ids: string[] = [];

          for (let i in _gigList) {
            _object_ids.push(String(_gigList[i].id));
          }
          if (
            this.props.permissions &&
            this.props.permissions['EDIT_GROUP_PAGES'] &&
            this.props.owner.ownerType !== uiConstants.ownerType.gig
          ) {
            this.props.groupState.groupGigs = this.state.gigList;
            if (this.props.updateGroupPageComponent) {
              this.props.updateGroupPageComponent(
                this.props.page.owner_id,
                this.props.page.id,
                this.props.component.id,
                {
                  content_references: {
                    object_type: Constants.object_type.gig,
                    object_ids: _object_ids,
                  },
                },
              );
            }
          } else if (this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES']) {
            this.props.eventState.gigs = this.state.gigList;
            this.props.updateEventPageComponent(
              this.props.owner.ownerId,
              this.props.page.id,
              this.props.component.id,
              {
                content_references: {
                  object_type: uiConstants.ownerType.gig,
                  object_ids: _object_ids,
                },
              },
            );
          }
        },
      );
    }
  };

  render() {
    let entityHasGigs =
      this.props.owner.ownerType === uiConstants.ownerType.group
        ? this.props.groupState.groupGigs.length > 0
        : this.props.eventState.gigs.length > 0;
    let entityHasPermissions =
      this.props.owner.ownerType === uiConstants.ownerType.group
        ? this.props.permissions && this.props.permissions['EDIT_GROUP_PAGES']
        : this.props.permissions && this.props.permissions['EDIT_EVENT_PAGES'];
    return (
      <div className="GigList">
        {this.props.edit && (
          <TextField
            value={this.state.title}
            name="title"
            type="text"
            onChange={(e: any) => {
              handleInputChange(e, this);
            }}
          />
        )}
        {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}

        {/* TODO: Refactor to avoid duplication. */}
        {this.props.permissions &&
          entityHasPermissions &&
          !entityHasGigs &&
          this.props.hasEditPermissions && (
            <ComponentPlaceholder
              title="Welcome to your new Volunteer Opportunities component!"
              message="Post volunteer & paid opportunities to the community"
              actions={[
                {
                  label: 'Create Volunteer Opportunity',
                  iconClassName: 'fas fa-plus-circle',
                  action: () => this.props.onCreateGigClicked(),
                },
              ]}
            />
          )}
        <DragDropContext onDragEnd={this.handleComponentDragEnd}>
          <Droppable droppableId="components">
            {(provided, snapshot) => (
              <div
                className={snapshot.isDraggingOver ? 'components-wrap dragging' : 'components-wrap'}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {this.state.gigList.map((item: IGig, index: number) => {
                  if (item?.status && item.status.code !== Constants.gig_status.archived) {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={'gig-' + item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <GigListItem
                              key={index}
                              {...this.props}
                              dragProps={provided.dragHandleProps}
                              gig={item}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                  return null;
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
  };
};

const mapDispatchToProps = {
  getGroupGigs,
  getEventGigs,
  updateGroupPageComponent,
  updateEventPageComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(GigList);
