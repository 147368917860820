import './SelectOption.scss';
import React from 'react';
import { ISelectOption } from '../../types';
import { components, GroupBase, OptionProps } from 'react-select';
import Portrait from '../../../../../Portrait/Portrait';
const { Option } = components;

type SelectOptionProps<IsMulti extends boolean> = React.PropsWithChildren<
  OptionProps<ISelectOption, IsMulti, GroupBase<ISelectOption>>
>;

interface ISelectOptionProps<IsMulti extends boolean> extends SelectOptionProps<IsMulti> {
  hasIconPadding?: boolean;
}

function SelectOption<IsMulti extends boolean>(props: ISelectOptionProps<IsMulti>) {
  const { data } = props;
  const hasIconOrImageClassName =
    !!data.icon || !!data.imageURL || props.hasIconPadding ? 'has-icon' : '';

  function renderIcon(icon?: string) {
    if (!!icon) {
      return <i className={icon} />;
    }
  }

  function renderImagePortrait(imageURL?: string) {
    if (!!imageURL) {
      return (
        <Portrait
          size={30}
          currentImage={imageURL}
        />
      );
    }
  }

  return (
    <Option {...props}>
      <div className="SelectOptionContainer">
        <div className={`image-container ${hasIconOrImageClassName}`}>
          {renderImagePortrait(data.imageURL)}
          {renderIcon(data.icon)}
        </div>
        <span className="label">{data.label}</span>
      </div>
    </Option>
  );
}

export default SelectOption;
