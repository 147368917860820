import { createContext } from 'react';
import { FilterValues } from '@gigit/interfaces';
import { IOptions } from '../../components/Dropdown/Dropdown';

export const searchCategoryOptions = [
  { value: 'groups', label: 'Causes', route: 'group' }, // was groups
  { value: 'events', label: 'Events', route: 'event' }, // was events
  { value: 'volunteer', label: 'Volunteer Opportunities', route: 'gig' }, // was volunteer
];

export const getDefaultFilterValues = (): FilterValues => {
  return {
    types: [],
    classification_ids: [],
    sub_classifications: [],
    cause_ids: [],
    skill_ids: [],
    eligible_for_my_age: null,
    accepting_volunteers: false,
    is_virtual: false,
    has_police_check: false,
    is_ossd_eligible: false,
    max_start: '',
    min_start: '',
  };
};
export interface ISearchContext {
  isMapView: boolean;
  type: string | string[];
  keywords: string;
  query: string;
  types: IOptions[];
  filterValues: FilterValues;

  toggleAdvancedFilter: () => void;
  toggleMap: () => void;
  setTypeValue: (value: string | string[]) => void;
  setKeywordsValue: (value: string) => void;
  setSearchSpecificItem: (value: string) => void;
  renderFilter: () => React.ReactElement[];
  search(): void;
}

export const SearchContext = createContext({} as ISearchContext);

export const mapStyleOptions = {
  gestureHandling: 'greedy',
  fullscreenControl: false,
  zoomControl: false,
  minZoom: 4,
};

export const getSearchRouteFromCategory = (type: string) => {
  return searchCategoryOptions.find((searchCategory) =>
    searchCategory.value === type ? searchCategory.route : '',
  )?.route;
};

export const getSearchLabelFromCategory = (type: string) => {
  return searchCategoryOptions.find((searchCategory) =>
    searchCategory.value === type ? searchCategory.route : '',
  )?.label;
};
