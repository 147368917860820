import React, { FC, useState } from 'react';
import './../../../../Scheduler/Scheduler.scss';
import {
  IAttendingEventSummary,
  IShiftUserSummary,
  IGigSummaryFE,
  IApplication,
  IUser,
} from '@gigit/interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../../../localizeHelpers';
import { IAppState } from '../../../../../store';
import { userSelectors } from '../../../../../selectors/user';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Constants } from '@gigit/constants';
import eventIcon from '../../../../../assets/event-icon-masks.svg';
import groupIcon from '../../../../../assets/people-logo-dark.svg';

import { uiConstants } from '../../../../../constants';
import QuillTextEditor from '../../../../QuillTextEditor/QuillTextEditor';
import PopupMenu, { IPopupMenuItem } from '../../../../shared/PopupMenu/PopupMenu';
import { gigRequestActions } from '../../../../../requestActions';
import Button from '../../../../Button/Button';
import ToggleButton from '../../../../shared/ToggleButton/ToggleButton';
import TextField from '../../../../TextField/TextField';
import { isURL } from '../../../../../helpers';

interface IProps {
  selected: IGigSummaryFE | null;
  applicants: IApplication[];
  isCausePosting: boolean;
  onUpdate(): void;
}

// TODO: Add the agenda types in constants and use the constants in checks instead of strings.
interface IAgendaShiftSummary extends IShiftUserSummary {
  type: string;
}

interface IAgendaEventSummary extends IAttendingEventSummary {
  type: string;
}

interface ScheduleListSummary {
  day: string;
  date: string;
  dateMs: number;
  list: (IAgendaEventSummary | IAgendaShiftSummary)[];
}

interface IDropdownOption {
  label: string;
  value: string;
}

interface ITypeDropdownOptions {
  all: IDropdownOption;
  shifts: IDropdownOption;
  events: IDropdownOption;
}

interface ContactData {
  phone: string;
  email: string;
}

const Settings: FC<IProps> = (props: IProps) => {
  const locale = useSelector((state: IAppState) => userSelectors.getCurrentLocale(state));
  const [showVolunteerMenu, setShowVolunteerMenu] = useState<string>('');
  const [showSaveUrlOption, setShowSaveUrlOption] = useState<boolean>(
    props.selected?.accepting_external_application || false,
  );
  const [externalApplicationUrlError, setExternalApplicationUrlError] = useState<boolean>(false);
  const [externalApplicationUrl, setExternalApplicationUrl] = useState<string>(
    props?.selected?.external_application_url || '',
  );

  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [showLogHourModal, setShowLogHourModal] = useState<boolean>(false);
  const history = useHistory();

  const owner = props.selected?.event
    ? {
        title: props.selected.event.title,
        handle: props.selected.event.handle,
        icon: eventIcon,
        type: Constants.object_type.event,
      }
    : ({
        title: props.selected?.group?.title || props.selected?.group_title,
        handle: props.selected?.group?.handle || props.selected?.group_handle,
        icon: groupIcon,
        type: Constants.object_type.group,
      } as {
        title: string;
        handle: string;
        icon: string;
        type: string;
      });

  const checkExternalApplicationUrl = (v: string) => {
    if (v.length > 0 && !isURL(v)) {
      setExternalApplicationUrlError(true);
    } else {
      setExternalApplicationUrlError(false);
    }
  };
  return (
    <div className="settings">
      <div className="text">
        <div className="applications">
          <span className="heading">Enable Applications</span>
          <span>Enable or disable applications on your volunteer opportunity </span>
        </div>
        <ToggleButton
          isActive={props.selected?.accepting_applications || false}
          onToggleClick={async () => {
            await gigRequestActions.updateGig(props?.selected?.id || '', {
              accepting_applications: !props.selected?.accepting_applications,
            });
            props.onUpdate();
          }}
        />
      </div>

      {props.isCausePosting && (
        <div className="text">
          <div className="applications">
            <span className="heading">External Applications</span>
            <span>Are you accepting external applications? </span>
          </div>
          <ToggleButton
            isActive={props.selected?.accepting_external_application || false}
            onToggleClick={async () => {
              if (!props.selected?.accepting_external_application) setShowSaveUrlOption(true);
              else setShowSaveUrlOption(false);
              await gigRequestActions.updateGig(props?.selected?.id || '', {
                accepting_external_application: !props.selected?.accepting_external_application,
              });
              props.onUpdate();
            }}
          />
        </div>
      )}
      {(props.selected?.accepting_external_application || showSaveUrlOption) && (
        <div
          onClick={(e) => e.stopPropagation()}
          className="expansion"
        >
          <TextField
            label="Enter Application URL"
            className="application-url"
            placeholder="Enter the url for your application"
            onChange={(e) => {
              checkExternalApplicationUrl(e.target.value || '');
              setExternalApplicationUrl(e.target.value || '');
            }}
            value={externalApplicationUrl || props?.selected?.external_application_url || ''}
            name="external_application_url"
            type="text"
            error={externalApplicationUrlError ? 'Incorrect value' : undefined}
          />
          <div className="actions">
            <Button
              text="Save"
              onClick={async () => {
                await gigRequestActions.updateGig(props?.selected?.id || '', {
                  external_application_url: externalApplicationUrl,
                });
                props.onUpdate();
              }}
              isDisabled={
                !props.selected?.accepting_external_application || externalApplicationUrlError
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
