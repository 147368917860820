import React from 'react';
import { handleInputChange } from '../../helpers';

import TextField from '../../components/TextField/TextField';
import Portrait from '../../components/Portrait/Portrait';

import './SearchList.scss';

interface IListItem {
  label?: string;
  image?: string;
}
interface IProps {
  label?: string;
  list: IListItem[];
  disabled?: boolean;
  onItemClick?: any;
  notranslate?: 'yes';
}

interface IState {
  searchValue: string;
}

class SearchList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: '',
    };
  }

  render() {
    const foundItems = this.props.list.filter((item) =>
      item.label?.toLowerCase().includes(this.state.searchValue.toLowerCase()),
    );

    return (
      <div
        className="SearchList"
        notranslate={this.props.notranslate}
      >
        <i className="fal fa-search main-icon"></i>
        <TextField
          label={this.props.label || ''}
          autoComplete="off"
          type="text"
          value={this.state.searchValue}
          name="searchValue"
          disabled={this.props.disabled || false}
          onChange={(e: any) => {
            handleInputChange(e, this);
          }}
        />
        {this.state.searchValue !== '' && (
          <ul className="listing">
            {foundItems.map((item, index) => {
              return (
                <li
                  onClick={() => {
                    if (this.props.onItemClick) {
                      this.props.onItemClick(item);
                      this.setState({ searchValue: '' });
                    }
                  }}
                  key={index}
                  className="list-item"
                >
                  <div className="list-item-inner">
                    <Portrait
                      size={30}
                      currentImage={item.image || ''}
                    />
                    <div className="list-item-label">{item.label}</div>
                  </div>
                </li>
              );
            })}
            {foundItems.length === 0 && (
              <li className="list-item nothing-found">Nothing was found.</li>
            )}
          </ul>
        )}
      </div>
    );
  }
}

export default SearchList;
