import { IEventIndividual, IFundraiserDonation } from '@gigit/interfaces';
import axios from 'axios';
import { routes, swapRouteParams } from '../helpers';

/** Fetches an Individual user by its handle or id
 * @param userHandleOrId
 */

export namespace individualRequestActions {
  export async function getIndividual(
    eventId: string,
    userHandleOrId: string,
  ): Promise<IEventIndividual> {
    const response = await axios.get<IEventIndividual>(
      swapRouteParams(routes.GET_EVENT_INDIVIDUAL, {
        eventId: eventId,
        userHandleOrId: userHandleOrId,
      }),
    );
    return response.data;
  }

  export async function updateIndividual(
    eventId: string,
    payload: Partial<IEventIndividual>,
  ): Promise<IEventIndividual> {
    const response = await axios.put<IEventIndividual>(
      swapRouteParams(routes.UPDATE_INDIVIDUAL, { event: eventId }),
      payload,
    );
    return response.data;
  }

  export async function getIndividualDonations(
    eventId: string,
    userId: string,
    pagination?: string,
  ): Promise<IFundraiserDonation[]> {
    let route = swapRouteParams(routes.GET_EVENT_INDIVIDUAL_DONATIONS, {
      eventId: eventId,
      userId: userId,
    });

    if (pagination) {
      route = route + pagination;
    }

    const response = await axios.get<IFundraiserDonation[]>(route);
    return response.data;
  }

  export async function toggleEventIndividualFitness(
    eventId: string,
    userIdOrHandle: string,
  ): Promise<IEventIndividual> {
    const response = await axios.put<IEventIndividual>(
      swapRouteParams(routes.TOGGLE_EVENT_INDIVIDUAL_FITNESS, {
        eventId,
        user_handle: userIdOrHandle,
      }),
    );
    return response.data;
  }
}
