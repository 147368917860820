import React from 'react';
import { IKpi } from '../../../../../interfaces';
import './HubManagementMetricContainer.scss';

interface IProps {
  metrics: IKpi[];
}

//TODO: Fancy loading state
function HubManagementMetricContainer(props: IProps) {
  const { metrics } = props;
  return (
    <div className="HubManagementMetricContainer">
      {metrics.map(({ text, value }, idx) => (
        <div
          key={idx}
          className="metric"
          notranslate="yes"
        >
          <span
            className="value"
            notranslate="yes"
          >
            {value || '-'}
          </span>
          <span className="label">{text}</span>
        </div>
      ))}
    </div>
  );
}

export default HubManagementMetricContainer;
