import React, { ReactNode } from 'react';

import './ContextPopup.scss';

interface IPassedProps {
  /** Typically "clientX" from a page click event */
  cursorX: number;
  /** Typically "clientY" from a page click event */
  cursorY: number;
  /** Children to render in the ContextPopup component */
  children: ReactNode;
  /** Controls weather to show the Popup or not */
  show: boolean;
}

interface IState {}

/** A generic reusable container element to popup where the cursor is located. 
 * 
 * contextPopupProps descriptions are on the IPassedProps interface
 * @example
 *  <ContextPopup {...contextPopupProps} >
        <div className="CustomElementClass">
            HEY, I'M CUSTOM!
        </div>
    </ContextPopup>
*/
class ContextPopup extends React.Component<IPassedProps, IState> {
  constructor(props: IPassedProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { cursorX, cursorY, show, children } = this.props;
    const style = {
      left: cursorX + window.scrollX + 'px',
      top: cursorY + window.scrollY + 'px',
    };

    return (
      <div
        className={`ContextPopup ${show ? 'show' : 'hidden'}`}
        style={style}
      >
        {children}
      </div>
    );
  }
}

export default ContextPopup;
