import { Reducer } from 'redux';
import { EventActions, EventActionTypes } from '../actions/event';
import {
  IPage,
  IRole,
  IGigSummary,
  ILocation,
  IPageComponent,
  IUserRole,
  ISponsor,
  IEventSponsor,
  IDonationStats,
  IStorePurchaseStats,
  IStoreStats,
  IAuctionStats,
  IAuctionPurchaseStats,
  IInvite,
  ITransactionSummary,
  IMediaAlbum,
  IEventTeam,
  IEventIndividual,
  IAttendee,
  IPagesComponentStructure,
  IPaymentIntentResponse,
  ICampaign,
  ITransactionItem,
  IStoreItem,
  IShiftUserSummary,
  IAddress,
  IEventSummary,
  IEventSummaryFE,
} from '@gigit/interfaces';

export interface IEventState {
  event: IEventSummaryFE;
  isEventLoading: boolean;
  isEventPagesLoading: boolean;
  isCurrentRoleLoading: boolean;
  isCreatingEvent: boolean;
  isTeamsLoading: boolean;
  isEventGigsLoading: boolean;
  pages: IPage[];
  components: any; //TODO: Not sure if needed
  mediaAlbums: IMediaAlbum[];
  teams: IEventTeam[];
  individuals: IEventIndividual[];
  gigs: IGigSummary[];
  attendees: IAttendee[];
  eventDonations: ITransactionSummary[];
  eventDonationsPublic: ITransactionSummary[];
  currentUserRole: IRole;
  eventRoles: IRole[];
  success: boolean;
  error: string;
  newTeam: string; // TODO: Rename to newTeamHandle
  teamSuccess: boolean;
  teamError: string;
  isCreatingTeam: boolean;
  isIndividualsLoading: boolean;
  componentCount: IPagesComponentStructure[];
  locations: IAddress[];
  isEventRolesLoading: boolean;
  purchaseError: string;
  purchaseIntent: IPaymentIntentResponse | null;
  currentCampaign: ICampaign | null;
  ticketComponents: IPageComponent[] | null;
  donationIntent: IPaymentIntentResponse | null;
  subscriptionSuccess: boolean | null;
  subscriptionError: string;
  donationsLoading: boolean;
  eventPurchases: ITransactionSummary[];
  eventPurchasesLoading: boolean;
  checkInSuccess: boolean;
  checkInError: string;
  checkInLoading: boolean;
  isComponentsLoading: boolean;
  isJoiningTeam: boolean;
  currentTransactionItems: ITransactionItem[];
  fundraisingTicket: IStoreItem | null;
  fundraisingTickets: IStoreItem[];
  hasRegistrationItem: boolean;
  registrationItemLoading: boolean;
  fundraisingTicketLoading: boolean;
  isMembersLoading: boolean;
  members: IUserRole[];
  sponsors: IEventSponsor[];
  isSponsorsLoading: boolean;
  eventManagers: IUserRole[];
  isStatsLoading: boolean;
  donationsStats: IDonationStats | null;
  storeStats: IStoreStats | null;
  storePurchasesStats: IStorePurchaseStats | null;
  auctionStats: IAuctionStats | null;
  auctionPurchasesStats: IAuctionPurchaseStats | null;
  childEvents: IEventSummaryFE[];
  isChildEventsLoading: boolean;
  eventGigPayments: ITransactionSummary[];
  gigPaymentsLoading: boolean;
}

export const initialEventState: IEventState = {
  event: {
    _id: '',
    id: '',
    title: '',
    handle: '',
    profile_image_url: '',
    cover_image_url: '',
    group_id: '',
    group: undefined,
    //group_handle: "",
    //group_title: "",
    is_virtual: false,
    start_date: '',
    end_date: '',
  },
  isTeamsLoading: false,
  isEventLoading: false,
  isEventPagesLoading: false,
  isCurrentRoleLoading: false,
  isCreatingEvent: false,
  isEventGigsLoading: false,
  pages: [],
  components: [],
  mediaAlbums: [],
  teams: [],
  individuals: [],
  gigs: [],
  attendees: [],
  eventDonations: [],
  eventDonationsPublic: [],
  currentUserRole: {
    id: '',
    role_name: '',
    is_user_defined: false,
    permissions: [],
    created_at: new Date(),
    updated_at: new Date(),
  },
  success: false,
  error: '',
  newTeam: '',
  teamSuccess: false,
  teamError: '',
  isCreatingTeam: false,
  isIndividualsLoading: false,
  componentCount: [],
  locations: [],
  isEventRolesLoading: false,
  eventRoles: [],
  purchaseError: '',
  purchaseIntent: null,
  currentCampaign: null,
  ticketComponents: null,
  donationIntent: null,
  subscriptionSuccess: null,
  subscriptionError: '',
  donationsLoading: false,
  eventPurchases: [],
  eventPurchasesLoading: false,
  checkInSuccess: false,
  checkInError: '',
  checkInLoading: false,
  isComponentsLoading: false,
  isJoiningTeam: false,
  currentTransactionItems: [],
  fundraisingTicket: null,
  fundraisingTickets: [],
  hasRegistrationItem: false,
  registrationItemLoading: false,
  fundraisingTicketLoading: false,
  isMembersLoading: false,
  members: [],
  sponsors: [],
  isSponsorsLoading: false,
  eventManagers: [],
  isStatsLoading: false,
  donationsStats: null,
  storeStats: null,
  storePurchasesStats: null,
  auctionStats: null,
  auctionPurchasesStats: null,
  childEvents: [],
  isChildEventsLoading: false,
  eventGigPayments: [],
  gigPaymentsLoading: false,
};

export const eventReducer: Reducer<IEventState, EventActions> = (
  state = initialEventState,
  action,
) => {
  switch (action.type) {
    case EventActionTypes.UPDATE_EVENT: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
