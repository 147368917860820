import './HubActivityFeed.scss';
import React from 'react';
import { useState } from 'react';
import { IEventSummaryFE, IHub } from '@gigit/interfaces';
import CommunityFeed from '../../UserDashboard/MyActivity/CommunityFeed/CommunityFeed';
import { Constants } from '@gigit/constants';
import { IThemeStyleObjectsByType } from '../Hub';
import { IEvent, IGroup, ICause } from '@gigit/interfaces';
import HubEventSingleItem from '../HubStoriesEvents/HubEventSingleItem/HubEventSingleItem';

interface IProps {
  adminMode: boolean;
  hub: IHub | null;
  theme: IThemeStyleObjectsByType;
}

const HubActivityFeed: React.FC<IProps> = (props) => {
  const [events, setEvents] = useState<IEventSummaryFE[]>([]);
  const [currentGroups, setCurrentGroups] = useState<IGroup[]>([]);

  function getEventCarouselSlides() {
    return events.map((item: IEventSummaryFE, index: number) => (
      <HubEventSingleItem
        key={index}
        event={item}
        group={currentGroups.filter((group) => group.id === item.group_id)[0]}
        // // TODO: uncomment this after we have BE for causes
        // causes={hubCauses.filter(cause=> item.causes?.includes(cause))}
      />
    ));
  }

  return (
    <div className="HubActivityFeed">
      {props?.hub && (
        <CommunityFeed
          notUserCommunityFeed={true}
          objectId={props?.hub.id}
          objectType={Constants.object_type.hub}
          theme={props.theme}
          hub={props.hub}
          feedTitle={'Stories & Activity Feed'}
        />
      )}
    </div>
  );
};

export default HubActivityFeed;
