import React, { useState, useEffect } from 'react';
import './UserMenu.scss';
import PopupMenu, { IPopupMenuItem } from '../shared/PopupMenu/PopupMenu';
import Portrait from '../Portrait/Portrait';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { userSelectors } from '../../selectors/user';
import { localeConstants, uiConstants } from '../../constants';
import myActivityLogo from '../../assets/header/my_activity.png';
import myPagesLogo from '../../assets/header/my_pages.svg';
import myProfileLogo from '../../assets/header/my_profile.svg';
import mySettingsLogo from '../../assets/header/my_settings.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  updateLocalizeLanguageFromState,
  updateLoggedOutLanguage,
  updateLocalizeLanguage,
  updateUser,
  logout,
} from '../../actions/user';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { notificationSelector } from '../../selectors/notification';
import { getUnreadNotifications } from '../../actions/notifications';
import { getUnreadChatCount, toastError } from '../../helpers';
import { localizeHelpers } from '../../localizeHelpers';
import { createToast } from '../../actions/toaster';
import logoKDark from '../../assets/logo-K-dark.svg';
import { userRequestActions } from '../../requestActions';
import { getCurrentConversations } from '../../actions/chat';

interface IProps {
  mobileShow: boolean;
  object_type?: string;
  hubProps?: {
    email?: string;
    phone?: string;
    userHasEditPermission?: boolean;
    setShowContactModal?: (v: boolean) => void;
    setShowShareModal?: (v: boolean) => void;
  };
  toggleMenuIcon?: (showIcon: boolean) => void;
}

// NOTE: Set the landing route to Discover Events page to be revisited when new landing page is implemented;
const DISCOVER_MENU_ITEMS = [
  // { label: "Home", link: "/" },
  { label: 'Events & Fundraisers', link: '/discover/events' },
  // { label: "Hubs", link: "" },
  { label: 'Volunteering', link: '/discover/volunteers' },
  // { label: "Paid Work", link: "" },
  { label: 'Causes', link: '/discover/groups' },
  // { label: "Auctions & Stores", link: "" },
  { label: 'Companies', link: '/discover/companies' },
];

const UserMenu: React.FC<IProps> = (props) => {
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
  const [showUserMenuMobile, setShowUserMenuMobile] = useState<boolean>(props.mobileShow);
  const [showUserSubMenu, setShowUserSubMenu] = useState<boolean>(false);
  const [showLanguageModal, setShowLanguageModal] = useState<boolean>(false);
  const [showDiscoverMenu, setShowDiscoverMenu] = useState<boolean>(true);
  const [unreadChatCount, setUnreadChatCount] = useState<number>(0);
  const isLoggedIn = useSelector((state: IAppState) => userSelectors.isUserAuthenticated(state));
  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));
  const notificationsState = useSelector((state: IAppState) =>
    notificationSelector.getNotificationState(state),
  );
  const cartState = useSelector((state: IAppState) => state.cartState);
  const chatState = useSelector((state: IAppState) => state.chatState);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [userInvitesAmt, setUserInvitesAmt] = useState<number>(0);
  const [loggedOutMenuItems, setLoggedOutMenuItems] = useState<IPopupMenuItem[]>([
    {
      id: 'explore_kambeo',
      label: 'Explore Kambeo',
      isSelected: false,
      hide: props.object_type !== uiConstants.ownerType.hub,
      iconImg: logoKDark,
      onClick: () => {
        history.push(`/discover/events`);
      },
    },
    {
      id: 'language',
      label:
        localeConstants.languageOptions.find(
          (ln) => ln.value === (userState.user?.language || userState.loggedOutLanguage),
        )?.label || 'English',
      isSelected: false,
      icon: 'far fa-globe',
      subMenuItems: localeConstants.languageOptions,
      subMenuSelect: (e: string) => {
        updateLanguage(e);
        setShowUserMenu(false);
      },
    },
    {
      id: 'sign_in',
      label: 'Sign In',
      isSelected: false,
      icon: 'far fa-sign-in',
      onClick: () => {
        history.push(`/login?redirect=${history.location.pathname}${history.location.search}`);
      },
    },
    {
      id: 'register',
      label: 'Register',
      isSelected: false,
      icon: 'far fa-address-book',
      onClick: () => {
        history.push(`/register?redirect=${history.location.pathname}${history.location.search}`);
      },
    },
    {
      id: 'search',
      label: 'Search',
      isSelected: false,
      icon: 'far fa-search',
      onClick: () => {
        history.push(`/search`);
      },
    },
    {
      id: 'help',
      label: 'Help',
      isSelected: false,
      icon: 'far fa-question',
      onClick: () => {
        history.push(`/help`);
      },
    },
    {
      id: 'contact_support',
      label: 'Contact Support',
      isSelected: false,
      icon: 'far fa-headset',
      onClick: () => {
        history.push(`/contact_support`);
      },
    },
  ]);
  const [loggedInMenuItems, setLoggedInMenuItems] = useState<IPopupMenuItem[]>([
    {
      id: 'explore_kambeo',
      label: 'Explore Kambeo',
      isSelected: false,
      hide: props.object_type !== uiConstants.ownerType.hub,
      iconImg: logoKDark,
      onClick: () => {
        history.push(`/discover/events`);
      },
    },
    {
      id: 'language',
      label:
        localeConstants.languageOptions.find(
          (ln) => ln.value === (userState.user?.language || userState.loggedOutLanguage),
        )?.label || 'English',
      isSelected: false,
      icon: 'far fa-globe',
      separator: true,
      subMenuItems: localeConstants.languageOptions,
      subMenuSelect: (e: string) => {
        updateLanguage(e);
        setShowUserMenu(false);
      },
    },
    {
      id: 'my_activity',
      label: 'My Activity',
      isSelected: false,
      iconImg: myActivityLogo,
      onClick: () => {
        history.push(`/dashboard?section=activity`);
      },
    },
    {
      id: 'my_pages',
      label: 'My Pages',
      isSelected: false,
      iconImg: myPagesLogo,
      notification_count: userInvitesAmt,
      onClick: () => {
        history.push(`/dashboard?section=pages`);
      },
    },
    {
      id: 'my_profile',
      label: 'My Profile',
      isSelected: false,
      iconImg: myProfileLogo,
      onClick: () => {
        history.push(`/dashboard?section=profile`);
      },
    },
    {
      id: 'settings',
      label: 'Settings',
      isSelected: false,
      iconImg: mySettingsLogo,
      separator: true,
      onClick: () => {
        history.push(`/dashboard?section=settings`);
      },
    },
    {
      id: 'admin',
      label: 'Admin',
      isSelected: false,
      icon: 'far fa-lock-alt',
      separator: true,
      hide: !isLoggedIn || !userState.user.is_super_admin,
      onClick: () => {
        history.push(`/admin`);
      },
    },
    {
      id: 'cart',
      label: 'View Cart',
      isSelected: false,
      icon: 'far fa-shopping-cart',
      notification_count: cartState.totalItems,
      onClick: () => {
        history.push(`/checkout`);
      },
    },
    {
      id: 'chat',
      label: 'Messages',
      isSelected: false,
      notification_count: unreadChatCount,
      icon: 'far fa-comment',
      onClick: () => {
        history.push(`/dashboard?section=activity&tab=network`);
      },
    },
    {
      id: 'notifications',
      label: 'Notifications',
      isSelected: false,
      icon: 'far fa-bell',
      separator: true,
      notification_count: notificationsState.unread.length,
      onClick: () => {
        history.push(`/notifications`);
      },
    },
    {
      id: 'search',
      label: 'Search',
      isSelected: false,
      icon: 'far fa-search',
      onClick: () => {
        history.push(`/search`);
      },
    },
    {
      id: 'help',
      label: 'Help',
      isSelected: false,
      icon: 'far fa-question',
      onClick: () => {
        history.push(`/help`);
      },
    },
    {
      id: 'contact_support',
      label: 'Contact Support',
      isSelected: false,
      icon: 'far fa-headset',
      onClick: () => {
        history.push(`/contact_support`);
      },
    },
    {
      id: 'log_out',
      label: 'Log out',
      isSelected: false,
      customClass: 'logout',
      icon: 'far fa-sign-out',
      onClick: clickLogout,
    },
  ]);
  const USER_MENU_ITEMS = [
    { label: 'My Activity', link: '/dashboard', icon: myActivityLogo },
    {
      label: 'My Pages',
      link:
        userInvitesAmt > 0 ? '/dashboard?section=pages&tab=invites' : '/dashboard?section=pages',
      icon: myPagesLogo,
      notifications: userInvitesAmt,
    },
    { label: 'My Profile', link: '/dashboard?section=profile', icon: myProfileLogo },
    { label: 'Settings', link: '/dashboard?section=settings', icon: mySettingsLogo },
  ];

  function clickLogout() {
    setShowUserMenu(false);
    dispatch(logout());
  }

  //NOTE: GIG-7714 - Multi-Language Clean up working around because of the strange behaviour of the isLoggedIn variable and updateLanguage function
  // useEffect(() => {
  //   dispatch(updateLocalizeLanguageFromState());
  // }, [userState.user.language]);

  function updateLanguage(newLanguage: string) {
    if (isLoggedIn) {
      dispatch(
        updateUser(
          {
            handle: userState.user.handle,
            language: newLanguage,
          },
          true,
        ),
      );
    }
    updateLoggedInOutMenuItems(
      'language',
      'label',
      localeConstants.languageOptions.find((ln) => ln.value === newLanguage)?.label || 'English',
    );
    dispatch(updateLocalizeLanguage(newLanguage));
    dispatch(updateLoggedOutLanguage(newLanguage));
    setShowUserMenu(false);
  }

  function onHelp() {
    window.open('https://www.kambeo.io/contact-us', '_blank');
  }

  function updateLoggedInOutMenuItems(
    id: string,
    property: keyof IPopupMenuItem,
    value: string | number | boolean,
  ) {
    if (isLoggedIn) {
      setLoggedInMenuItems(
        loggedInMenuItems.map((item) => {
          if (item.id === id) {
            item[property] = value;
          }

          return item;
        }),
      );
    } else {
      setLoggedOutMenuItems(
        loggedOutMenuItems.map((item) => {
          if (item.id === id) {
            item[property] = value;
          }

          return item;
        }),
      );
    }
  }

  async function getInvites() {
    setUserInvitesAmt(0);
    const response = await userRequestActions.getUserInvites();
    setUserInvitesAmt(response.length);
  }

  useEffect(() => {
    setShowUserMenuMobile(props.mobileShow);
  }, [props.mobileShow]);

  useEffect(() => {
    if (isLoggedIn) {
      updateLoggedInOutMenuItems('my_pages', 'notification_count', userInvitesAmt);
    }
  }, [userInvitesAmt]);

  useEffect(() => {
    if (isLoggedIn) {
      updateLoggedInOutMenuItems(
        'notifications',
        'notification_count',
        notificationsState.total_unread_count,
      );
    }
  }, [notificationsState]);

  useEffect(() => {
    if (isLoggedIn) {
      const tmpCount = getUnreadChatCount(chatState.conversations, userState.user?.id || '');
      setUnreadChatCount(tmpCount);
      updateLoggedInOutMenuItems('chat', 'notification_count', tmpCount);
    }
  }, [chatState]);

  useEffect(() => {
    updateLoggedInOutMenuItems('cart', 'notification_count', cartState.totalItems);
  }, [cartState]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUnreadNotifications());
      dispatch(getCurrentConversations());
      getInvites();
    }

    updateLoggedInOutMenuItems('admin', 'hide', !isLoggedIn || !userState.user.is_super_admin);

    updateLoggedInOutMenuItems(
      'language',
      'label',
      localeConstants.languageOptions.find(
        (ln) => ln.value === (userState.user?.language || userState.loggedOutLanguage),
      )?.label || 'English',
    );
  }, [userState.loggedOutLanguage, userState.user.language]);

  useEffect(() => {
    // If the `lang` param is provided, change the "logged out language" to the value of the param.
    let urlSearch = new URLSearchParams(location.search);
    let urlLanguage = urlSearch.get('lang');
    let isValidUrl = localeConstants.languageOptions.find(
      (language) => language.value === urlLanguage,
    );

    // update lang based on url, else initialize lang based on user state
    if (urlLanguage) {
      if (!isValidUrl) {
        if (!isLoggedIn) {
          updateLanguage('en');
        }

        urlSearch.delete('lang');
        history.replace({
          pathname: location.pathname,
          search: urlSearch.toString(),
        });

        let toast = toastError(
          localizeHelpers.translate(
            'Selected language not supported. Supported languages: English (en), French (fr), Spanish (es).',
          ),
          'Language not Supported',
        );
        dispatch(createToast(toast));
      } else if (isValidUrl) {
        if (!isLoggedIn) {
          updateLanguage(urlLanguage);
        }

        urlSearch.delete('lang');
        history.replace({
          pathname: location.pathname,
          search: urlSearch.toString(),
        });
      }
    } else {
      dispatch(updateLocalizeLanguageFromState());
    }
  }, []);

  return (
    <div
      className="UserMenu"
      onClick={() => {
        setShowUserMenu(true);
      }}
    >
      {isLoggedIn && !showUserMenuMobile && (
        <PopupMenu
          showMenuConfig={{
            showMenu: showUserMenu,
            setShowMenu: (v) => setShowUserMenu(false),
            position: {
              type: 'bottom',
            },
          }}
          menuItems={loggedInMenuItems}
          popupMenuClass="PopupMenuLoggedIn"
          className={`PopupMenuLoggedIn-Dropdown filter-dropdown ${showUserMenu ? 'show' : ''}`}
          onSelect={() => setShowUserMenu(true)}
          onClick={() => setShowUserMenu(!showUserMenu)}
        >
          <div className="title-container">
            <Portrait
              size={35}
              currentImage={userState.user.profile_image_url}
              hasNotification={
                notificationsState.unread.length > 0 ||
                cartState.totalItems > 0 ||
                unreadChatCount > 0
              }
            />
          </div>
        </PopupMenu>
      )}
      {!isLoggedIn && !showUserMenuMobile && (
        <div className="main-menu-item menu-profile-icon header-context-menu">
          <PopupMenu
            showMenuConfig={{
              showMenu: showUserMenu,
              setShowMenu: (v) => setShowUserMenu(false),
              position: {
                type: 'bottom',
              },
            }}
            menuItems={loggedOutMenuItems}
            popupMenuClass="PopupMenuLoggedOut"
            className={`Dropdown filter-dropdown ${showUserMenu ? 'show' : ''}`}
            onSelect={() => setShowUserMenu(false)}
            onClick={() => setShowUserMenu(!showUserMenu)}
          >
            <i className="far fa-user-circle" />
          </PopupMenu>
        </div>
      )}

      <Modal
        class="HeaderUserMenuMobile"
        show={showUserMenuMobile}
      >
        <div className="inner-modal">
          <div className="user-actions">
            <div className="section">
              {props?.object_type === uiConstants.ownerType.hub && (
                <div className="title-container">
                  <span className="title">
                    <Link to="/discover/events">Explore Kambeo</Link>
                  </span>
                </div>
              )}
              <div className="title-container">
                <span
                  className="title"
                  onClick={() => setShowLanguageModal(!showLanguageModal)}
                >
                  {`Language - ${
                    localeConstants.languageOptions.find(
                      (ln) =>
                        ln.value === (userState.user?.language || userState.loggedOutLanguage),
                    )?.label || 'English'
                  }`}
                </span>
              </div>
              <div
                className="title-container"
                onClick={() => setShowDiscoverMenu(!showDiscoverMenu)}
              >
                <span className="title">Discover</span>
                <i className={`fal fa-angle-right ${showDiscoverMenu ? 'show' : ''}`} />
              </div>
              <div
                className={`dropdown-section ${showDiscoverMenu ? 'show' : ''}`}
                onClick={() => {
                  setShowUserMenuMobile(false);
                  props?.toggleMenuIcon?.(false);
                }}
              >
                {DISCOVER_MENU_ITEMS.map((item, index) => (
                  <Link
                    to={item.link}
                    className="link"
                    key={index}
                  >
                    <span>{item.label}</span>
                  </Link>
                ))}
              </div>
            </div>

            {props.object_type === uiConstants.ownerType.hub &&
              (props?.hubProps?.email || props?.hubProps?.phone) &&
              !props?.hubProps?.userHasEditPermission && (
                <div className="section actions">
                  <div className="title-container">
                    <span
                      className="title"
                      onClick={() => props?.hubProps?.setShowContactModal?.(true)}
                    >
                      Contact Hub Admin
                    </span>
                  </div>
                  <div className="title-container">
                    <span
                      className="title"
                      onClick={() => props?.hubProps?.setShowShareModal?.(true)}
                    >
                      Share
                    </span>
                  </div>
                </div>
              )}

            <div
              className="section actions"
              onClick={() => {
                setShowUserMenuMobile(false);
                props?.toggleMenuIcon?.(false);
              }}
            >
              <Link
                className="link"
                to="/search"
              >
                <i className="fal fa-search" />
                Search
              </Link>
              <Link
                className="link"
                to="/checkout"
              >
                <i className="fas fa-shopping-cart" />
                View Cart
                <span className="metric">
                  {cartState.totalItems > uiConstants.notificationLimit
                    ? uiConstants.overNotificationLimitTxt
                    : cartState.totalItems}
                </span>
              </Link>
              {isLoggedIn && (
                <>
                  <Link
                    className="link"
                    to="/dashboard?section=activity&tab=network"
                  >
                    <i className="fas fa-comments-alt" />
                    Messages
                    {unreadChatCount > 0 && (
                      <span className="metric">
                        {unreadChatCount > uiConstants.notificationLimit
                          ? uiConstants.overNotificationLimitTxt
                          : unreadChatCount}
                      </span>
                    )}
                  </Link>
                  <Link
                    className="link"
                    to="/notifications"
                  >
                    <i className="fas fa-bell" />
                    Notifications
                    {notificationsState.unread.length > 0 && (
                      <span className="metric">
                        {notificationsState.unread.length > uiConstants.notificationLimit
                          ? uiConstants.overNotificationLimitTxt
                          : notificationsState.unread.length}
                      </span>
                    )}
                  </Link>
                </>
              )}
              <span
                className="link"
                onClick={onHelp}
              >
                <i className="fas fa-question" />
                Help
              </span>
            </div>

            {!isLoggedIn && (
              <div className="section login-action">
                <Link
                  to={`/login?redirect=${history.location.pathname}${history.location.search}`}
                  className="link"
                >
                  <Button
                    text="Sign In"
                    buttonType="outline-dark"
                    type="button"
                    onClick={() => {
                      setShowUserMenuMobile(false);
                      props?.toggleMenuIcon?.(false);
                    }}
                  />
                </Link>

                <Link
                  to={`/register?redirect=${location.pathname}${history.location.search}`}
                  className="link"
                >
                  <Button
                    text="Register"
                    buttonType="dark"
                    type="button"
                    onClick={() => {
                      setShowUserMenuMobile(false);
                      props?.toggleMenuIcon?.(false);
                    }}
                  />
                </Link>
              </div>
            )}

            {isLoggedIn && (
              <div className="user-logged-in section">
                <div
                  className="title-container"
                  onClick={() => setShowUserSubMenu(!showUserSubMenu)}
                >
                  <span className="title">
                    <Portrait
                      size={35}
                      currentImage={userState.user.profile_image_url}
                      onClick={() => setShowUserSubMenu(!showUserSubMenu)}
                      className="profile-image"
                      hasNotification={userInvitesAmt > 0}
                    />
                    <Link to={`/user/${userState.user.handle}`}>
                      {`${userState.user.first_name} ${userState.user.last_name}`}
                    </Link>
                  </span>
                  <i className={`fal fa-angle-right ${showUserSubMenu ? 'show' : ''}`} />
                </div>
                <div
                  className={`dropdown-section ${showUserSubMenu ? 'show' : ''}`}
                  onClick={() => {
                    setShowUserMenuMobile(false);
                    props?.toggleMenuIcon?.(false);
                  }}
                >
                  {USER_MENU_ITEMS.map((item, index) => (
                    <Link
                      to={item.link}
                      className="link"
                      key={index}
                    >
                      <img
                        src={item.icon}
                        alt="icon"
                      />{' '}
                      {item.label}
                      {item.notifications !== 0 && item.notifications && (
                        <div className="metric">{item.notifications}</div>
                      )}
                    </Link>
                  ))}
                  <span
                    className="link logout"
                    onClick={clickLogout}
                  >
                    <i className="fas fa-sign-out" />
                    Log out
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        onClose={() => setShowLanguageModal(false)}
        class="HeaderUserMenuMobile-language"
        show={showLanguageModal}
        closeIcon="fas fa-times"
      >
        <div className="lng-container">
          <div className="header-title">
            <span>Default language</span>
          </div>
          <div className="lng-content">
            {localeConstants.languageOptions.map((language, index) => (
              <div
                key={index}
                className="lng-item"
                onClick={() => {
                  updateLanguage(language.value);
                  setShowLanguageModal(false);
                }}
              >
                <span>
                  <i
                    className={
                      (userState.user?.language || userState.loggedOutLanguage) === language.value
                        ? 'fad fa-circle'
                        : 'far fa-circle'
                    }
                  />
                  {`${language.value.charAt(0).toUpperCase() + language.value.slice(1)}      ${language.label}`}
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserMenu;
