import { Constants } from '@gigit/constants';
import { ITransactionItemSummary, ITransactionSummary } from '@gigit/interfaces';
import React, { useEffect, FC, useState } from 'react';
import './StorePurchasesListItemDetails.scss';
import { defaultCurrency, formatCurrency } from '../../../helpers';
import { useLocale } from '../../../hooks';
import useToastDispatcher from '../../../hooks/useToaster';
import { eventRequestActions, groupRequestActions } from '../../../requestActions';
import ContextMenu from '../../ContextMenu/ContextMenu';
import Portrait from '../../Portrait/Portrait';

interface IProps {
  purchase: ITransactionSummary;
  setPurchaseItem(item: ITransactionItemSummary): void;
  noForm(): void;
  ownerType: string;
  ownerId: string;
}

const StorePurchasesListItemDetails: FC<IProps> = (props) => {
  const { purchase, setPurchaseItem, noForm, ownerType, ownerId } = props;
  const exchangeRate = purchase.reporting_exchange_rate ?? 1;
  const currency = purchase.reporting_currency ?? defaultCurrency;
  const amount = purchase.amounts.total * purchase.amounts.amount_multiplier! * exchangeRate;
  const locale = useLocale();
  const [items, setItems] = useState<ITransactionItemSummary[]>([]);
  const { dispatchToastError } = useToastDispatcher();

  async function getTransactionItems() {
    let tmpItems: ITransactionItemSummary[] = [];
    if (ownerType === Constants.object_type.group) {
      try {
        const items = await groupRequestActions.getGroupPurchaseItems(ownerId, purchase.id!);
        if (items) {
          tmpItems = items;
        }
      } catch (error) {
        dispatchToastError(error, 'Get Purchase Items');
      }
    } else {
      try {
        const items = await eventRequestActions.getEventPurchaseItems(ownerId, purchase.id!);
        if (items) {
          tmpItems = items;
        }
      } catch (error) {
        dispatchToastError(error, 'Get Purchase Items');
      }
    }

    setItems(tmpItems);
  }

  useEffect(() => {
    getTransactionItems();
  }, []);

  return (
    <div className="StorePurchasesListItemDetails">
      <div className="detail-section">
        <div className="detail-title">
          <div className="title">Items</div>
        </div>
        <div className="detail-items">
          {items.map((item, idx) => {
            let _subMenuItems;
            if (item.form?.length! > 0) {
              _subMenuItems = [
                { icon: 'fa fa-list', onClick: () => setPurchaseItem(item), label: 'View Form' },
              ];
            } else {
              _subMenuItems = [{ icon: 'fa fa-list', onClick: () => noForm(), label: 'View Form' }];
            }
            return (
              <div
                key={idx}
                className="item"
              >
                <div className="portrait-container">
                  <Portrait
                    source={item.media?.length! > 0 ? item.media?.[0].url : ''}
                    size={60}
                  />
                </div>
                <div className="item-name">
                  <div> Name </div>
                  <div notranslate="yes"> {item.name} </div>
                </div>
                <div className="item-price">
                  <div> Price </div>
                  <div notranslate="yes">
                    {' '}
                    {formatCurrency(amount, currency, locale.currentLocale)}{' '}
                  </div>
                </div>
                <div className="item-qty">
                  <div> Quantity </div>
                  <div notranslate="yes"> {item.quantity} </div>
                </div>
                <div className="actions">
                  <i className="fal fa-ellipsis-h-alt" />
                  <ContextMenu
                    onMouseLeave={() => {}}
                    showMenu={true}
                    menuItems={_subMenuItems}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="detail-section">
        <div className="title">Contact Details</div>
        <div
          className="email"
          notranslate="yes"
        >
          {purchase.user.email}
        </div>
        {purchase.user.phone && (
          <div
            className="phone"
            notranslate="yes"
          >
            {purchase.user.phone}
          </div>
        )}
        <div className="title">Billing Address</div>
        {purchase.user.address?.city && (
          <div
            className="address"
            notranslate="yes"
          >
            <div>{purchase.user.address.line1}</div>
            <div>{purchase.user.address.line2}</div>
            <div>
              {purchase.user.address.city}, {purchase.user.address.state}{' '}
              {purchase.user.address.postal_code}
            </div>
            <div>{purchase.user.address.country}</div>
          </div>
        )}
        {purchase.user.billing_address && purchase.user.billing_address.city && (
          <div
            className="address"
            notranslate="yes"
          >
            <div>{purchase.user.billing_address.line1}</div>
            <div>{purchase.user.billing_address.line2}</div>
            <div>
              {purchase.user.billing_address.city}, {purchase.user.billing_address.state}{' '}
              {purchase.user.billing_address.postal_code}
            </div>
            <div>{purchase.user.billing_address.country}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StorePurchasesListItemDetails;
