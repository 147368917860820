import './Tabs.scss';
import React from 'react';

export interface ITab {
  label: string;
  value: string;
}

interface IProps {
  tabs: ITab[];
  activeTab: string;
  onTabChange: (tab: string) => void;
}

// this was copied from HubManagementBilling
export const Tabs = (props: IProps) => {
  const { tabs, activeTab, onTabChange } = props;

  return (
    <div className="TabsContainer">
      {tabs.map((tab) => (
        <div
          key={tab.value}
          className={`tab ${tab.value === activeTab ? 'selected' : ''}`}
          onClick={() => onTabChange(tab.value)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};
