import React, { ReactNode, MouseEvent } from 'react';
import './SortableTable.scss';
import Loader from '../Loader/Loader';
import PageComponentPaginator from '../PageComponent/shared/PageComponentPaginator/PageComponentPaginator';
import { ISortSettings } from '../../interfaces';
import TableContextMenu from './TableContextMenu/TableContextMenu';
import { Tooltip } from '../shared/Tooltip/Tooltip';

export interface ISortableTableColumn {
  id: string;
  label: string;
  sortable?: boolean;
  content?: ReactNode;
  hoverText?: string;
}

export interface ISortableTableActions {
  icon: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  label: string;
  target?: string;
  link?: string;
}

export interface ISortableTableRow {
  id: string;
  content?: string | ReactNode;
  menu?: ISortableTableActions[];
  multiSelectValue?: string;
  menuIcon?: string;
  hoverText?: string;
}

export interface ISortableTableData {
  row: ISortableTableRow[];
  /** TODO: Caveat - omitting this removes row styling and pointer cursor on hover seemingly */
  onClick?(e: any): void;
  details?: any;
}

interface IProps {
  columns: ISortableTableColumn[];
  data: ISortableTableData[];
  bulkActions?: ISortableTableActions[];
  actions?: ISortableTableActions[];
  onSort?(sortSettings: ISortSettings[]): void;
  onSelect?(items: string[]): void;
  loading?: boolean;
  multiSelect?: boolean;
  onRowSelected?(index: number): void;
  /**
     @desc if we want to use ExpandedDetails we need to enable it (set this to true)
     */
  enableExpandedDetails?: boolean;
  renderExpandedCell?: (rowIndex: number) => ReactNode;
  itemsPerPage?: number;
  simplePagination?: boolean;
  triggerNext?(): void;
  triggerPrevious?(): void;
  emptyStateString?: string;
  setCurrentPage?(page: number): void;

  // C.H. - hacky additions for https://app.clickup.com/t/8404472/GIG-6167
  showNext?: boolean;
  showPrev?: boolean;
}

interface IState {
  sortSettings: ISortSettings[];
  selectedRow: number;
  allSelected: boolean;
  selectedBulk: string[];
  currentPage: number;
}

class SortableTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      sortSettings: [],
      selectedRow: -1,
      selectedBulk: [],
      allSelected: false,
      currentPage: 0,
    };

    this.sortColumn = this.sortColumn.bind(this);
    this.isSorted = this.isSorted.bind(this);
    this.getSortSettingById = this.getSortSettingById.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.setAllSelected = this.setAllSelected.bind(this);
    this.isAllSelected = this.isAllSelected.bind(this);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.props.setCurrentPage && this.props.setCurrentPage(this.state.currentPage);
    }
  }

  sortColumn(id: string) {
    let _sortSettings: ISortSettings[] = [...this.state.sortSettings];
    let _foundSetting: number = -1;
    let _maxSettings: number = 3;

    for (let s = 0; s < _sortSettings.length; s++) {
      if (_sortSettings[s].id === id) {
        _foundSetting = s;
        break;
      }
    }

    if (_foundSetting >= 0) {
      if (_sortSettings[_foundSetting].order !== 'desc') {
        _sortSettings[_foundSetting].order = 'desc';
      } else if (_sortSettings[_foundSetting].order === 'desc') {
        _sortSettings.splice(_foundSetting, 1);
      }
    } else {
      if (_sortSettings.length >= _maxSettings) {
        _sortSettings.splice(0, 1);
      }

      _sortSettings.push({
        id: id,
        order: 'asc',
      });
    }

    this.setState(
      {
        sortSettings: _sortSettings,
      },
      () => {
        if (this.props.onSort) {
          this.props.onSort(this.state.sortSettings);
        }
      },
    );
  }

  isSorted(id: string): boolean {
    for (let s in this.state.sortSettings) {
      if (this.state.sortSettings[s].id === id) {
        return true;
      }
    }

    return false;
  }

  getSortSettingById(id: string) {
    for (let s in this.state.sortSettings) {
      if (this.state.sortSettings[s].id === id) {
        return this.state.sortSettings[s];
      }
    }

    return null;
  }

  setSelected(e: MouseEvent, value: string | null) {
    e.stopPropagation();
    if (value) {
      let currentlySelected = this.state.selectedBulk;

      if (currentlySelected.indexOf(value) !== -1) {
        let index = currentlySelected.indexOf(value);
        currentlySelected.splice(index, 1);
      } else {
        currentlySelected.push(value);
      }

      this.setState(
        {
          selectedBulk: currentlySelected,
        },
        () => {
          if (this.props.onSelect) {
            this.props.onSelect(this.state.selectedBulk);
          }
        },
      );
    }
  }

  isSelected(value: string) {
    return this.state.selectedBulk.indexOf(value) !== -1;
  }

  setAllSelected() {
    let currentlySelected = this.state.selectedBulk;
    if (currentlySelected.length > 0) {
      if (this.isAllSelected()) {
        this.setState({ allSelected: false });
        currentlySelected = [];
      } else {
        this.setState({ allSelected: true });
        currentlySelected = [];
        this.props.data.map((rows: any, index) => {
          rows.row.map((column: ISortableTableRow, index: number) => {
            if (column.multiSelectValue) {
              currentlySelected.push(column.multiSelectValue);
            }
          });
        });
      }
    } else {
      currentlySelected = [];
      this.setState({ allSelected: true });
      this.props.data.map((rows: any, index) => {
        rows.row.map((column: ISortableTableRow, index: number) => {
          if (column.multiSelectValue) {
            currentlySelected.push(column.multiSelectValue);
          }
        });
      });
    }
    this.setState(
      {
        selectedBulk: currentlySelected,
      },
      () => {
        if (this.props.onSelect) {
          this.props.onSelect(this.state.selectedBulk);
        }
      },
    );
  }

  isAllSelected() {
    return this.state.selectedBulk.length === this.props.data.length;
  }

  render() {
    return (
      <div className="SortableTable">
        {this.props.multiSelect && (
          <div className="multi-select-actions">
            {this.state.selectedBulk.length > 0 && (
              <div className="selected-counter">
                <span notranslate="yes">{this.state.selectedBulk.length}</span> Items Selected
              </div>
            )}
            {this.state.selectedBulk.length > 0 && (
              <div className="selected-actions">
                {this.props.bulkActions?.map((action, index) => {
                  return (
                    <div
                      key={index}
                      title={action.label}
                      onClick={action.onClick}
                    >
                      <i className={action.icon} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {this.props.multiSelect &&
          this.state.selectedBulk.length > 0 &&
          this.props.bulkActions?.map((item, index) => {})}
        <div className="table-row table-header">
          {this.props.multiSelect && (
            <div
              className={this.state.allSelected ? 'custom_select selected' : 'custom_select'}
              onClick={() => this.setAllSelected()}
            >
              <i className={`fas ${this.state.allSelected ? 'fa-check-square' : 'fa-square'}`} />
            </div>
          )}

          {!this.props.loading &&
            this.props.columns.map((column: ISortableTableColumn, index) => {
              return (
                <div
                  key={index}
                  title={column.label}
                  className={
                    'col ' +
                    (column.id?.replace('.', '_') + (column.sortable === false ? ' no-sort' : ''))
                  }
                >
                  {!column.content ? (
                    <div
                      className="table-header-content"
                      onClick={() => {
                        if (column.sortable !== false) {
                          this.sortColumn(column.id);
                        }
                      }}
                    >
                      <div className="header-content-name">{column.label}</div>
                      <div className="header-content-sort">
                        {column.sortable !== false && (
                          <div className={this.isSorted(column.id) ? 'sort active' : 'sort'}>
                            <i
                              className={
                                this.getSortSettingById(column.id)?.order === 'asc'
                                  ? 'fas fa-caret-up active'
                                  : 'fas fa-caret-up'
                              }
                            />
                            <i
                              className={
                                this.getSortSettingById(column.id)?.order === 'desc'
                                  ? 'fas fa-caret-down active'
                                  : 'fas fa-caret-down'
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    column.content
                  )}
                </div>
              );
            })}
        </div>
        {this.props.loading && this.props.loading && <Loader loading={true} />}
        <PageComponentPaginator
          simple={this.props.simplePagination}
          triggerNext={this.props.triggerNext}
          triggerPrev={this.props.triggerPrevious}
          itemsPerPage={this.props.itemsPerPage || 0}
          items={this.props.data}
          showNext={this.props.showNext}
          showPrev={this.props.showPrev}
          setCurrentPage={(page) => {
            this.setState({ currentPage: page });
          }}
        >
          {(itemsToRender) => {
            return itemsToRender.map((d, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={`
                                                ${this.props.loading && this.props.loading ? 'table-row loading' : d.onClick ? 'table-row expandable no-select' : 'table-row'}
                                                ${this.props.enableExpandedDetails ? 'pointer' : ''}
                                                ${this.state.selectedRow === index && this.props.enableExpandedDetails ? 'active-expended-row' : ''}
                                            `}
                    onClick={(e) => {
                      if (this.props?.onRowSelected && this.state.selectedRow !== index)
                        this.props.onRowSelected(index);
                      this.setState({ selectedRow: this.state.selectedRow === index ? -1 : index });
                      if (d.onClick) {
                        d.onClick(e);
                      }
                    }}
                  >
                    {d.row.map((column, cIndex) => {
                      if (cIndex === 0 && this.props.multiSelect && column.multiSelectValue) {
                        return (
                          <div
                            key={cIndex}
                            className={
                              this.isSelected(column.multiSelectValue)
                                ? 'custom_select selected'
                                : 'custom_select'
                            }
                            onClick={(e) => this.setSelected(e, column.multiSelectValue || null)}
                          >
                            <i
                              className={`fas ${this.isSelected(column.multiSelectValue) ? 'fa-check-square' : 'fa-square'}`}
                            />
                          </div>
                        );
                      }
                      if (column.menu) {
                        return (
                          <TableContextMenu
                            menuIcon={column.menuIcon}
                            key={cIndex}
                            className={'col ' + column.id?.replace('.', '')}
                            menuItems={column.menu}
                          />
                        );
                      } else {
                        const toolTipTemplate = (
                          <Tooltip
                            message={`${column.hoverText || ''}`}
                            direction="bottom"
                          >
                            {(ref) => (
                              <div
                                ref={ref}
                                id="hoverOver"
                              >{`${column.content || ''}`}</div>
                            )}
                          </Tooltip>
                        );
                        return (
                          <div
                            key={cIndex}
                            className={'col ' + column.id?.replace('.', '_')}
                          >
                            {column?.hoverText ? toolTipTemplate : column.content}
                          </div>
                        );
                      }
                    })}
                  </div>
                  {this.props.enableExpandedDetails &&
                    this.props.renderExpandedCell &&
                    this.state.selectedRow === index && (
                      <div className="expended-container">
                        {this.props.renderExpandedCell(index)}
                      </div>
                    )}
                  {d.details && this.state.selectedRow === index && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="row-details"
                    >
                      {d.details}
                    </div>
                  )}
                </React.Fragment>
              );
            });
          }}
        </PageComponentPaginator>
        {/* Display an empty state string */}
        {!this.props.loading &&
          this.props.data.length === 0 &&
          this.props.emptyStateString != null && (
            <div className="empty-state">{this.props.emptyStateString}</div>
          )}
      </div>
    );
  }
}

export default SortableTable;
