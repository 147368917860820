import React, { Component, Fragment } from 'react';
import { IEventSponsor, IPageComponent, IPage } from '@gigit/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IAppState } from '../../../store';
import './Sponsors.scss';
import { connect } from 'react-redux';
import TextField from '../../TextField/TextField';
import {
  combineClassNames,
  defaultCurrency,
  handleInputChange,
  IStringMap,
} from '../../../helpers';
import Portrait from '../../Portrait/Portrait';
import { IGroupState } from '../../../reducers/group';
import { IEventState } from '../../../reducers/event';
import { getGroupSponsorshipSettings } from '../../../actions/group';
import {
  getEventSponsors,
  updateEventPageComponent,
  updateEventSponsorshipItem,
  getCurrentEventPageComponents,
} from '../../../actions/event';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { IUserState } from '../../../reducers/user';
import { formatCurrency } from '../../../helpers';
import { userSelectors } from '../../../selectors/user';
import { IOwnerObject } from '../../../interfaces/ownerObject';
import typeHelpers from '../../../helpers/typeHelpers';
import { ReduxActionType } from '../../../interfaces';

interface IProps extends WithTranslation {
  userId: string;
  edit: boolean;
  page: IPage;
  component: IPageComponent;
  owner: IOwnerObject;
  permissions?: IStringMap;
  onComponentUpdate?: () => void;

  userState: IUserState;
  groupState: IGroupState;
  eventState: IEventState;
  locale: string;

  getEventSponsors: ReduxActionType<typeof getEventSponsors>;
  updateEventPageComponent: ReduxActionType<typeof updateEventPageComponent>;
  updateEventSponsorshipItem: ReduxActionType<typeof updateEventSponsorshipItem>;
  getGroupSponsorshipSettings: ReduxActionType<typeof getGroupSponsorshipSettings>;
  getCurrentEventPageComponents: ReduxActionType<typeof getCurrentEventPageComponents>;
  hasEditPermissions?: boolean;
  showManageSponsorVisibility: boolean;
  setShowManageSponsorVisibility: (value: boolean) => void;
}

interface IState {
  title: string;
  tierCarouselStates: { tier: number; carouselIndex: number }[];

  width: number;
  sponsorIds: string[];

  showEditSponsorModal: boolean;
  editSponsor: IEventSponsor | null;
  editSponsorImageData: string | null;
}

interface ISponsorTierInfo {
  tier: number;
  title: string;
  object_ids: string[];
}

interface ISponsorsComponentMetadata {
  tiers?: ISponsorTierInfo[];
}

interface ITierDefinition {
  tier: number;
  title: string;
  numberOfTiles: number;
  tileLayout: 'horizontal' | 'vertical';
  imageSize: number;
}

const tierDefinitions: ITierDefinition[] = [
  {
    tier: 1,
    title: 'Tier 1',
    numberOfTiles: 2,
    tileLayout: 'horizontal',
    imageSize: 180,
  },
  {
    tier: 2,
    title: 'Tier 2',
    numberOfTiles: 2,
    tileLayout: 'horizontal',
    imageSize: 150,
  },
  {
    tier: 3,
    title: 'Tier 3',
    numberOfTiles: 4,
    tileLayout: 'vertical',
    imageSize: 125,
  },
  {
    tier: 4,
    title: 'Tier 4',
    numberOfTiles: 3,
    tileLayout: 'horizontal',
    imageSize: 80,
  },
  {
    tier: 5,
    title: 'Tier 5',
    numberOfTiles: 4,
    tileLayout: 'vertical',
    imageSize: 60,
  },
];

export class Sponsors extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      title: this.props.component.title || 'Sponsors',
      tierCarouselStates: tierDefinitions.map((tierDef) => ({
        tier: tierDef.tier,
        carouselIndex: 0,
      })),
      width: window.innerWidth,
      showEditSponsorModal: false,
      editSponsor: null,
      editSponsorImageData: null,
      sponsorIds: this.props.component.content_references?.object_ids ?? [],
    };
  }

  componentDidMount() {
    const ownerGroupId = typeHelpers.getGroupIdFromOwner(this.props.owner);
    ownerGroupId && this.props.getGroupSponsorshipSettings(ownerGroupId);
    if (this.isAdmin()) {
      this.props.getEventSponsors(this.props.page.owner_id);
    }

    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  // Called when window size gets updated
  onWindowResize = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.permissions !== prevProps.permissions) {
      if (this.isAdmin()) {
        this.props.getEventSponsors(this.props.page.owner_id);
      }
    }
  }

  getSponsorById(transactionItemId: string): IEventSponsor | undefined {
    // If admin, use event sponsor list (includes sponsor_value field)
    if (this.isAdmin()) {
      const sponsor = this.props.eventState.sponsors.find(
        (sponsor) => sponsor.transaction_item_id === transactionItemId,
      );
      if (sponsor) {
        return sponsor;
      }
    }

    const sponsorList: IEventSponsor[] =
      (this.props.component.content_objects as IEventSponsor[]) ?? [];
    return sponsorList.find((sponsor) => sponsor.transaction_item_id === transactionItemId);
  }

  isAdmin() {
    return this.props.permissions?.['EDIT_EVENT_PAGES'] && this.props.hasEditPermissions;
  }

  saveSponsorsVisilbility() {
    this.props.updateEventPageComponent(
      this.props.eventState.event.id,
      this.props.page.id,
      this.props.component.id,
      {
        content_references: {
          object_ids: this.state.sponsorIds, // Gets only unique object ids
          object_type: 'sponsor',
        },
      },
      () => {
        if (this.props.onComponentUpdate) {
          this.props.onComponentUpdate();
        }
      },
    );

    this.props.setShowManageSponsorVisibility(false);
  }

  updateSponsorVisibility(transactionItemId: string, isVisible: boolean) {
    let sponsorIds = [...this.state.sponsorIds];
    const existingIndex = sponsorIds.findIndex((objectId) => objectId === transactionItemId);

    if (isVisible) {
      if (existingIndex === -1) {
        sponsorIds.push(transactionItemId);
      }
    } else {
      if (existingIndex !== -1) {
        sponsorIds.splice(existingIndex, 1);
      }
    }

    this.setState({
      sponsorIds: sponsorIds,
    });
  }

  updateCarouselState(tierNumber: number, index: number) {
    const tierCarouselStates = [...this.state.tierCarouselStates];
    const tier = tierCarouselStates.find((tier) => tier.tier === tierNumber);

    if (tier) {
      tier.carouselIndex = index;

      this.setState({
        tierCarouselStates: tierCarouselStates,
      });
    }
  }

  isSponsorVisible(transactionItemId: string) {
    const existingIndex = this.state.sponsorIds.findIndex(
      (objectId) => objectId === transactionItemId,
    );

    return existingIndex !== -1;
  }

  handleSponsorInputChange(fieldName: string, value: any) {
    this.setState({
      editSponsor: {
        ...this.state.editSponsor!,
        [fieldName]: value,
      },
    });
  }

  onMediaItemSelect = (file: any) => {
    this.setState({
      editSponsorImageData: file.file,
    });
  };

  saveSponsor() {
    if (this.state.editSponsor) {
      this.props.updateEventSponsorshipItem(
        this.props.owner.ownerId,
        this.state.editSponsor?.transaction_item_id,
        {
          sponsor_name: this.state.editSponsor.name,
          sponsor_img: this.state.editSponsor.image_url,
          sponsor_url: this.state.editSponsor.website,
        },
        this.state.editSponsorImageData,
        undefined,
        {
          callback: () => {
            if (this.props.onComponentUpdate) {
              this.props.onComponentUpdate();
            } else {
              this.props.getCurrentEventPageComponents(
                this.props.eventState.event.id,
                this.props.page.id,
              );
            }
          },
        },
      );

      this.setState({
        showEditSponsorModal: false,
      });
    }
  }

  // Determines how many items to show per page for the given tier
  calculateItemsPerPage(tierDef: ITierDefinition) {
    const baseNumTiles = tierDef?.numberOfTiles ?? 1;
    let maxItemsPerPage = 4;

    if (this.state.width < 800) {
      maxItemsPerPage = 2;
    } else if (this.state.width < 940) {
      maxItemsPerPage = 3;
    } else if (this.state.width < 1240) {
      maxItemsPerPage = 3;
    }

    // Vertical tiles can have more room for an extra tile.
    if (tierDef.tileLayout === 'horizontal') {
      maxItemsPerPage -= 1;
    }

    return Math.min(baseNumTiles, maxItemsPerPage);
  }

  getSponsorsForTier(tierNumber: number): IEventSponsor[] {
    const sponsorList: IEventSponsor[] =
      (this.props.component.content_objects as IEventSponsor[]) ?? [];

    return sponsorList.filter((sponsor) => sponsor.tier === tierNumber);
  }

  render() {
    const currency = this.props.owner.account?.currency ?? defaultCurrency;

    return (
      <div className={combineClassNames('Sponsors')}>
        <div className="title">
          {this.props.edit && (
            <TextField
              value={this.state.title}
              name="title"
              type="text"
              onChange={(e) => {
                handleInputChange(e, this);
              }}
            />
          )}
          {!this.props.edit && <h3 notranslate="yes">{this.state.title}</h3>}
        </div>

        {tierDefinitions.map((tier, index) => this.renderTier(tier, index))}

        <Modal
          title="Manage Sponsor Visibility"
          show={this.props.showManageSponsorVisibility}
          onClose={() => this.props.setShowManageSponsorVisibility(false)}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.saveSponsorsVisilbility();
            }}
            className="tier-visibility-modal"
          >
            <div className="table">
              <label className="header col0">Name</label>
              <label className="header col1">Value of Sponsorship</label>
              <label className="header col2">Visibility</label>
              {this.props.eventState.sponsors.map((sponsor, index) => (
                <Fragment key={index}>
                  <p
                    className="item col0"
                    notranslate="yes"
                  >
                    {sponsor.name}
                  </p>
                  <p
                    className="item col1"
                    notranslate="yes"
                  >
                    {formatCurrency((sponsor as any).sponsor_value, currency, this.props.locale)}
                  </p>
                  <div className="item col2 toggle-container">
                    <div className="col visibility">
                      <i
                        onClick={() => {
                          this.updateSponsorVisibility(
                            sponsor.transaction_item_id,
                            !this.isSponsorVisible(sponsor.transaction_item_id),
                          );
                        }}
                        className={
                          this.isSponsorVisible(sponsor.transaction_item_id)
                            ? 'fad fa-toggle-on'
                            : 'fad fa-toggle-off'
                        }
                      />
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
            <div className="form-actions">
              <Button
                type="button"
                className="cancel-button"
                text="Cancel"
                onClick={() => this.props.setShowManageSponsorVisibility(false)}
              />
              <Button
                type="submit"
                className="save-button"
                text="Apply Changes"
              />
            </div>
          </form>
        </Modal>

        <Modal
          title="Edit Sponsorship"
          show={this.state.showEditSponsorModal && this.state.editSponsor != null}
          onClose={() => this.setState({ showEditSponsorModal: false })}
        >
          {this.state.editSponsor && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.saveSponsor();
              }}
              className="edit-sponsorship-modal"
            >
              <div className="form-inputs">
                <div className="row">
                  <span className="image-label">Sponsor Image</span>
                  <Portrait
                    onChange={this.onMediaItemSelect}
                    currentImage={this.state.editSponsorImageData ?? undefined}
                    size={80}
                    overlay={true}
                  />
                </div>

                <div className="row">
                  <TextField
                    type="text"
                    onChange={(e) => {
                      this.handleSponsorInputChange(e.target.name, e.target.value);
                    }}
                    name="name"
                    value={`${this.state.editSponsor.name}`}
                    required={true}
                    label="Sponsor Name"
                  />
                </div>

                <div className="row">
                  <TextField
                    type="text"
                    onChange={(e) => {
                      this.handleSponsorInputChange(e.target.name, e.target.value);
                    }}
                    name="website"
                    value={`${this.state.editSponsor.website}`}
                    label="Sponsor URL"
                  />
                </div>
              </div>
              <div className="form-actions">
                <Button
                  type="button"
                  buttonClass="cancel-button"
                  text="Cancel"
                  onClick={() => this.setState({ showEditSponsorModal: false })}
                />
                <Button
                  type="submit"
                  className="save-button"
                  text="Apply Changes"
                />
              </div>
            </form>
          )}
        </Modal>
      </div>
    );
  }

  renderTier(tier: { tier: number; title: string }, tierIndex: number) {
    const tierDef = tierDefinitions.find((tierDef) => tierDef.tier === tier.tier)!;
    const itemsPerPage = this.calculateItemsPerPage(tierDef);

    const sponsors = this.getSponsorsForTier(tier.tier);

    const sponsorStateIndex =
      this.state.tierCarouselStates.find((cs) => cs.tier === tier.tier)?.carouselIndex ?? 0;
    const numberOfPages = Math.max(
      Math.floor(sponsors.length / itemsPerPage) + (sponsors.length % itemsPerPage !== 0 ? 1 : 0),
      1,
    );
    const sponsorOffset = sponsorStateIndex * itemsPerPage;

    const tierCarouselState = this.state.tierCarouselStates.find((ts) => ts.tier === tier.tier);

    const tierName =
      this.props.groupState.groupSponsorshipSettings?.tiers?.find(
        (tierSettings) => tierSettings.tier === tier.tier,
      )?.tier_name ?? tierDef.title;

    if (!this.isAdmin() && sponsors.length === 0) {
      return null;
    }

    const pages: number[] = [];

    for (let i = 0; i < numberOfPages; i++) {
      pages.push(i);
    }

    return (
      <div
        className={combineClassNames('tier', this.isAdmin() ? 'admin' : undefined)}
        key={tierIndex}
      >
        <p
          className="tier-title"
          notranslate="yes"
        >
          {tierName}
        </p>

        <div className="component-sponsors-box">
          {sponsors
            .filter(
              (sponsor, index) => index >= sponsorOffset && index < sponsorOffset + itemsPerPage,
            )
            .map((sponsor, index) => this.renderSponsor(tierDef, sponsor, index))}
        </div>

        <div className="carousel-container">
          {pages.length > 1 &&
            pages.map((page) => (
              <Fragment key={page}>
                {' '}
                <div
                  key={page}
                  className={
                    tierCarouselState?.carouselIndex === page ? 'notch-filled' : 'notch-empty'
                  }
                  onClick={() => this.updateCarouselState(tier.tier, page)}
                />{' '}
              </Fragment>
            ))}
        </div>
      </div>
    );
  }

  renderSponsor(tierDef: ITierDefinition, sponsor: IEventSponsor, index: number) {
    if (!sponsor) {
      return null;
    }

    return (
      <div
        className={combineClassNames(
          `sponsor ${tierDef.tileLayout}`,
          this.isAdmin() ? 'admin' : undefined,
        )}
        key={index}
      >
        <Portrait
          size={tierDef.imageSize}
          currentImage={sponsor.image_url}
        />
        <div className="info">
          <div
            className="title"
            notranslate="yes"
          >
            {sponsor.name}
          </div>
          <a
            className="link"
            target="_blank"
            href={sponsor.website}
            notranslate="yes"
          >
            {sponsor.website}
          </a>
        </div>

        <div className="list-admin-actions">
          <ul>
            <li
              onClick={() => {
                this.setState({
                  showEditSponsorModal: true,
                  editSponsor: sponsor,
                  editSponsorImageData: sponsor.image_url,
                });
              }}
            >
              <i className="fas fa-pencil" />
              <span>Edit</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    userState: store.userState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getEventSponsors,
  updateEventPageComponent,
  updateEventSponsorshipItem,
  getGroupSponsorshipSettings,
  getCurrentEventPageComponents,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(Sponsors),
);
