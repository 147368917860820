import moment from 'moment';
import {
  IAuctionItem,
  IAuctionItemBid,
  IAuctionItemSummary,
  IAuctionPayment,
  ITransactionAmounts,
} from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { capitalizeString, routes, swapRouteParams } from '../../../helpers';
import { AuctionItemOwnerType } from '../../../actions/auction';
import axios from 'axios';

/** Helper functions for auction items. */
export const auctionItemHelpers = {
  validPaymentStatuses: [
    Constants.auction_item_payment_status.awaiting_payment,
    Constants.auction_item_payment_status.failed,
  ],
  canPlaceBid(auctionItem: IAuctionItem) {
    return (
      auctionItem.status?.code === Constants.auction_item_status.running &&
      auctionItem.payment_status?.code === Constants.auction_item_payment_status.awaiting_payment
    );
  },
  canEndUserPay(auctionItem: IAuctionItem) {
    return (
      auctionItem.status?.code === Constants.auction_item_status.closed &&
      this.validPaymentStatuses.includes(auctionItem.payment_status?.code ?? '') &&
      auctionItem?.current_bid &&
      !auctionItem.bids_require_payment_method
    );
  },
  canAdminManuallyPay(auctionItem: IAuctionItem) {
    return (
      auctionItem.status?.code === Constants.auction_item_status.closed &&
      auctionItem?.current_bid &&
      this.validPaymentStatuses.includes(auctionItem.payment_status?.code ?? '')
    );
  },
  getAuctionItemPaymentStatusString(paymentStatus: string) {
    switch (paymentStatus) {
      case Constants.auction_item_payment_status.awaiting_payment:
      case Constants.auction_item_payment_status.awaiting_payment_buy_now:
        return 'Awaiting Payment';
      default:
        return capitalizeString(paymentStatus);
    }
  },
  /** Returns an array of profile image urls for an auction item. */
  getLeaderboardProfileImageStack(auctionItem: IAuctionItemSummary) {
    let _stack: (string | undefined)[] = [];

    for (let i = 0; i < 4; i++) {
      if (auctionItem.bidders && auctionItem.bidders[i] && !auctionItem.bidders[i]?.anonymous) {
        _stack.push(auctionItem.bidders[i].profile_image_url);
      } else {
        _stack.push('');
      }
    }

    _stack.reverse();

    return _stack;
  },
  async bidOnAuctionItem(
    ownerType: AuctionItemOwnerType,
    ownerId: string,
    id: string,
    payload: IAuctionItemBid,
  ) {
    const route = ownerType === 'group' ? routes.GROUP_BID_ITEM : routes.EVENT_BID_ITEM;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    const response = await axios.post<IAuctionItemSummary>(
      swapRouteParams(route, { [idField]: ownerId, id: id }),
      payload,
    );
    return response.data;
  },
  async getBuyNowPaymentSummary(
    ownerType: AuctionItemOwnerType,
    ownerId: string,
    _id: string,
    _payload: IAuctionPayment,
  ) {
    const route =
      ownerType === 'group' ? routes.GET_BUY_NOW_SUMMARY : routes.GET_EVENT_BUY_NOW_SUMMARY;
    const idField = ownerType === 'group' ? 'groupId' : 'eventId';

    const response = await axios.post<ITransactionAmounts>(
      swapRouteParams(route, { [idField]: ownerId, id: _id }),
      _payload,
    );
    return response.data;
  },

  // React quill modules/formats to use on description of auction item.
  quillModules: {
    toolbar: [
      ['bold', 'italic'],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link', 'clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  },
  quillFormats: [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ],
};

export default auctionItemHelpers;
