import React, { FC, useState, useEffect, useRef } from 'react';
import './HubManagementAnalyticsFundraising.scss';
import HubManagementMetricContainer from '../../shared/HubManagementMetricContainer/HubManagementMetricContainer';
import { IKpi } from '../../../../../interfaces';
import moment, { Moment } from 'moment';
import DateTimePicker from '../../../../shared/DateTimePicker/DateTimePicker';
import { useLocale } from '../../../../../hooks';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IHub } from '@gigit/interfaces';
import useToastDispatcher from '../../../../../hooks/useToaster';
import { metricRequestActions } from '../../../../../requestActions/metrics';
import { uiConstants } from '../../../../../constants';
import {
  defaultCurrency,
  metricsHelpers,
  formatCurrency,
  formatNumberCompactNotation,
  focusOnInputField,
} from '../../../../../helpers';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface IProps {
  hub: IHub;
}

const _SESSION_STORAGE_FROM = 'hubAnalyticsDonations_fundraisingDateFrom',
  _SESSION_STORAGE_TO = 'hubAnalyticsDonations_fundraisingDateTo';

const HubManagementAnalyticsFundraising: FC<IProps> = (props) => {
  const { hub } = props;
  const { dispatchToastError } = useToastDispatcher();
  const locale = useLocale();
  const [metrics, setMetrics] = useState<IKpi[]>([]);
  const [fundraisingKpi, setFundraisingKpi] = useState<metricsHelpers.IChartKpi | null>(null);
  const [fundraisingDateFrom, setFundraisingDateFrom] = useState<Moment>(
    moment(localStorage.getItem(_SESSION_STORAGE_FROM) || `${new Date().getFullYear()}-01-01`),
  );
  const [fundraisingDateTo, setFundraisingDateTo] = useState<Moment>(
    moment(localStorage.getItem(_SESSION_STORAGE_TO) || `${new Date().getFullYear()}-12-31`),
  );

  const fundraisingDateFromRef = useRef<HTMLInputElement>(null);
  const fundraisingDateToRef = useRef<HTMLInputElement>(null);

  async function handleGetMetrics() {
    try {
      const metricNames = ['amount_raised', 'num_fundraisers'];
      const result = (
        await metricRequestActions.getMetricsBulk({
          metrics: metricNames.map((metric) => ({
            metric,
            object_type: uiConstants.ownerType.hub,
            object_id: hub.id!,
            currency: hub.account?.currency ?? defaultCurrency,
          })),
        })
      ).metrics;

      setMetrics([
        {
          value: formatCurrency(result[0].value, result[0].unit, locale.currentLocale),
          text: 'Total Fundraised',
        },
        {
          value: formatNumberCompactNotation(result[1].value, locale.currentLocale),
          text: 'Number of Fundraisers',
        },
      ]);
    } catch (error) {
      dispatchToastError(error, 'Get Metrics');
    }
  }

  async function handleGetFundraisingKpi() {
    try {
      const kpiData = (
        await metricRequestActions.getMetricsKpi({
          object_type: uiConstants.ownerType.hub,
          object_id: hub.id!,
          kpi: 'fundraised_amounts_trend',
          start_date: fundraisingDateFrom.toDate(),
          end_date: fundraisingDateTo.toDate(),
          currency: hub.account?.currency ?? defaultCurrency,
        })
      ).kpi_data;
      let chartDate = [] as metricsHelpers.IChartKpiData[];
      kpiData.labels.forEach((label, index) => {
        chartDate.push({
          x: label,
          value: kpiData.datasets[0].data[index],
        });
      });

      setFundraisingKpi({ data: chartDate, labels: kpiData.labels });
    } catch (error) {
      dispatchToastError(error, 'Get Fundraising KPI');
    }
  }

  useEffect(() => {
    handleGetMetrics();
  }, []);

  useEffect(() => {
    handleGetFundraisingKpi();
  }, [fundraisingDateTo, fundraisingDateFrom]);

  const optionsFundraising = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value: any) {
            return formatCurrency(
              Number(value),
              hub.account?.currency ?? defaultCurrency,
              locale.currentLocale,
            );
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return formatCurrency(
              Number(context.parsed.y),
              hub.account?.currency ?? defaultCurrency,
              locale.currentLocale,
            );
          },
        },
        backgroundColor: '#ffffff',
        borderColor: '#333333',
        borderWidth: 1,
        bodyColor: '#000000',
        titleColor: '#000000',
        padding: 16,
        titleFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
        bodyFont: {
          family: 'Lato',
          weight: '800',
          size: 12,
        },
      },
    },
  };
  const chartDataFundraising = {
    labels: fundraisingKpi?.labels || [],
    datasets: [
      {
        label: 'Fundraising',
        data: fundraisingKpi?.data || [],
        borderColor: '#5E51AB',
        backgroundColor: '#5E51AB',
        parsing: {
          yAxisKey: 'value',
        },
      },
    ],
  };

  return (
    <div className="HubManagementAnalyticsFundraising">
      <h3>Fundraising Analytics</h3>
      <HubManagementMetricContainer metrics={metrics} />

      <section className="chart-container">
        <h4>Monthly Fundraising Trend</h4>
        <div className="date-container">
          <div className="date">
            <label htmlFor="fundraisingDateFrom">From:</label>
            <DateTimePicker
              shouldValidate={false}
              inputProps={{
                ref: fundraisingDateFromRef,
                name: 'fundraisingDateFrom',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              value={fundraisingDateFrom}
              onChange={(value) => {
                setFundraisingDateFrom(moment(value));
                localStorage.setItem(_SESSION_STORAGE_FROM, value as string);
              }}
              locale={locale.currentLocale}
              className="dPicker"
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(fundraisingDateFromRef)}
            />
          </div>
          <div className="date">
            <label htmlFor="fundraisingDateTo">To:</label>
            <DateTimePicker
              inputProps={{
                ref: fundraisingDateToRef,
                name: 'fundraisingDateTo',
                placeholder: 'mm/dd/yyyy',
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MM-DD-YYYY"
              value={fundraisingDateTo}
              onChange={(value) => {
                setFundraisingDateTo(moment(value));
                localStorage.setItem(_SESSION_STORAGE_TO, value as string);
              }}
              locale={locale.currentLocale}
              className="dPicker"
              disableDatesBefore={fundraisingDateFrom}
            />
            <i
              className="fal fa-calendar-alt"
              onClick={() => focusOnInputField(fundraisingDateToRef)}
            />
          </div>
        </div>
        <Line
          options={optionsFundraising}
          data={chartDataFundraising}
          className="chart"
        />
      </section>
    </div>
  );
};

export default HubManagementAnalyticsFundraising;
