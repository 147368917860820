import './NewPaymentMethod.scss';
import React, { FC, useState } from 'react';
import TextField from '../../../../components/TextField/TextField';
import { CardElement } from '@stripe/react-stripe-js';
import Button from '../../../../components/Button/Button';
import SelectField from '../../../../components/shared/forms/select/SelectField/SelectField';
import { localeConstants } from '../../../../constants';
import { useAppSelector } from '../../../../store';
import { userSelectors } from '../../../../selectors/user';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { IPaymentMethod } from '@gigit/interfaces';
import useToastDispatcher from '../../../../hooks/useToaster';
import { accountRequestActions } from '../../../../requestActions';
interface IProps {
  className?: string;
  elements?: StripeElements | null;
  stripe?: Stripe | null;
  onSubmit(newPaymentMethod: IPaymentMethod): void;
  onBack(): void;
}

const initialPaymentMethodForm: IPaymentMethodForm = {
  type: 'card',
  billing_details: {
    email: '',
    name: '',
    phone: '',
    address: {
      city: '',
      country: '',
      state: '',
      line1: '',
      line2: '',
    },
  },
};

export interface IPaymentMethodForm {
  type: string;
  billing_details: {
    email: string;
    name: string;
    phone: string;
    address: {
      city: string;
      country: string;
      state: string;
      line1: string;
      line2: string;
    };
  };
}

const NewPaymentMethod: FC<IProps> = (props) => {
  const { className, stripe, elements, onSubmit, onBack } = props;
  const userState = useAppSelector((state) => userSelectors.getUserState(state));
  const isUserLoggedIn = useAppSelector((state) => userSelectors.isUserAuthenticated(state));
  const { dispatchToastError } = useToastDispatcher();

  const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = useState<boolean>(false);
  const [paymentMethodForm, setPaymentMethodForm] =
    useState<IPaymentMethodForm>(initialPaymentMethodForm);

  async function handleSubmitNewPaymentMethod(paymentInfo: IPaymentMethodForm) {
    setIsCreatingPaymentMethod(true);
    const { billing_details } = paymentInfo;
    const cardElement = elements?.getElement('card');

    try {
      const stripePaymentMethod = await stripe?.createPaymentMethod({
        type: 'card',
        card: cardElement!,
        billing_details: {
          email: userState.user.email,
          name: billing_details.name,
          phone: userState.user.phone,
          address: {
            city: billing_details.address.city,
            state: billing_details.address.state,
            country: billing_details.address.country,
            line1: billing_details.address.line1,
            line2: billing_details.address.line2,
          },
        },
      });
      if (stripePaymentMethod?.error) {
        dispatchToastError(stripePaymentMethod.error, 'Stripe Error');
        return;
      }

      const paymentMethod = stripePaymentMethod?.paymentMethod;
      const createPaymentMethodPayload: IPaymentMethod = {
        payment_method_id: paymentMethod?.id,
        customer_id: paymentMethod?.customer ?? undefined,
        type: 'stripe',
        billing_details: {
          address: paymentMethod?.billing_details?.address,
          name: paymentMethod?.billing_details?.name,
          email: paymentMethod?.billing_details?.email,
          phone: paymentMethod?.billing_details?.phone,
        },
        card: {
          brand: paymentMethod?.card?.brand,
          country: paymentMethod?.card?.country,
          exp_month: paymentMethod?.card?.exp_month,
          exp_year: paymentMethod?.card?.exp_year,
          last4: paymentMethod?.card?.last4,
        },
      } as IPaymentMethod;

      const createPaymentMethodRequest = await accountRequestActions.createPaymentMethodRequest(
        createPaymentMethodPayload,
      );

      const setupStripeCard = await stripe?.confirmCardSetup(
        createPaymentMethodRequest.client_secret.toString(),
        {
          payment_method: paymentMethod?.id,
        },
      );

      if (setupStripeCard?.error) {
        dispatchToastError(setupStripeCard.error, 'Stripe Error');
        return;
      }

      setPaymentMethodForm(initialPaymentMethodForm);

      onSubmit(createPaymentMethodPayload);
    } catch (error) {
      dispatchToastError(error, 'Error adding payment method');
    } finally {
      setIsCreatingPaymentMethod(false);
    }
  }

  return (
    <div className={`NewPaymentMethod ${className || ''}`}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!isUserLoggedIn) {
            props.onSubmit(initialPaymentMethodForm);
            return;
          }
          handleSubmitNewPaymentMethod(paymentMethodForm);
        }}
        className="overlay-inner"
      >
        <div className="input-wrapper">
          <h3 className="sub-title">New Payment Method</h3>
          <div className="form-row">
            <TextField
              required={true}
              label="Name on Card"
              value={paymentMethodForm.billing_details.name}
              name="cardName"
              type="text"
              onChange={(e) =>
                setPaymentMethodForm({
                  ...paymentMethodForm,
                  billing_details: { ...paymentMethodForm.billing_details, name: e.target.value },
                })
              }
            />
          </div>
          <div className="card-wrap">
            <label>Credit Card</label>
            <div
              className="card-inner"
              notranslate="yes"
            >
              <CardElement options={{ hidePostalCode: true }} />
            </div>
          </div>
          {isUserLoggedIn && (
            <>
              <h3 className="sub-title">Billing Information</h3>
              <div className="form-row">
                <TextField
                  required={true}
                  label="Address Line 1"
                  value={paymentMethodForm.billing_details.address.line1}
                  name="addressLine1"
                  type="text"
                  onChange={(e) =>
                    setPaymentMethodForm({
                      ...paymentMethodForm,
                      billing_details: {
                        ...paymentMethodForm.billing_details,
                        address: {
                          ...paymentMethodForm.billing_details.address,
                          line1: e.target.value,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="form-row">
                <TextField
                  label="Address Line 2"
                  value={paymentMethodForm.billing_details.address.line2}
                  name="addressLine2"
                  type="text"
                  onChange={(e) =>
                    setPaymentMethodForm({
                      ...paymentMethodForm,
                      billing_details: {
                        ...paymentMethodForm.billing_details,
                        address: {
                          ...paymentMethodForm.billing_details.address,
                          line2: e.target.value,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="form-row">
                <TextField
                  required={true}
                  label="City"
                  value={paymentMethodForm.billing_details.address.city}
                  name="city"
                  type="text"
                  onChange={(e) =>
                    setPaymentMethodForm({
                      ...paymentMethodForm,
                      billing_details: {
                        ...paymentMethodForm.billing_details,
                        address: {
                          ...paymentMethodForm.billing_details.address,
                          city: e.target.value,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="form-row">
                <SelectField
                  label="Country"
                  name="country"
                  required={true}
                  options={localeConstants.allCountryOptions}
                  addContextMenuToPortal
                  onChange={(option) =>
                    setPaymentMethodForm({
                      ...paymentMethodForm,
                      billing_details: {
                        ...paymentMethodForm.billing_details,
                        address: {
                          ...paymentMethodForm.billing_details.address,
                          country: option?.value || '',
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="form-row">
                {(paymentMethodForm.billing_details.address.country === 'CA' ||
                  paymentMethodForm.billing_details.address.country === 'US') && (
                  <SelectField
                    label="Province/State"
                    onChange={(e) => {
                      setPaymentMethodForm({
                        ...paymentMethodForm,
                        billing_details: {
                          ...paymentMethodForm.billing_details,
                          address: {
                            ...paymentMethodForm.billing_details.address,
                            state: e?.value || '',
                          },
                        },
                      });
                    }}
                    addContextMenuToPortal
                    options={
                      paymentMethodForm.billing_details.address.country === 'CA'
                        ? localeConstants.canadianProvinceOptions
                        : paymentMethodForm.billing_details.address.country === 'US'
                          ? localeConstants.usStateOptions
                          : [{ value: 'N/A', label: 'N/A' }]
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>
        <div className="overlay-actions">
          <Button
            type="submit"
            text="Continue"
            loading={isCreatingPaymentMethod}
            isDisabled={isCreatingPaymentMethod}
            buttonType="dark"
          />
          <Button
            onClick={onBack}
            text="Back"
            buttonType="outline-dark"
          />
        </div>
      </form>
    </div>
  );
};

export default NewPaymentMethod;
