import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, Link } from 'react-router-dom';
import queryString from 'query-string';

import { IAppState } from '../../store';

import './GetStarted.scss';
import { setSEOMetatags } from '../../helpers';
import { localizeHelpers } from '../../localizeHelpers';

interface IProps extends WithTranslation, RouteComponentProps<any> {}

interface IState {
  selected: string;
}

// TODO: Remove this component (Not being used anymore).
class GetStarted extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selected: 'group',
    };

    this.setSelected = this.setSelected.bind(this);
    this.goToSelected = this.goToSelected.bind(this);
  }

  setSelected(selected: string) {
    this.setState(
      {
        selected,
      },
      () => {
        this.goToSelected();
      },
    );
  }

  componentDidMount() {
    setSEOMetatags({
      title: localizeHelpers.translate('Get Started | Kambeo'),
      urlPath: 'get-started',
    });
    let _params = queryString.parse(this.props.location.search);
    if (
      _params.fn === 'toggleJoinFundraiser' ||
      _params.fn === 'joinTeam' ||
      _params.fn === 'fundraise' ||
      _params.fn === 'joinGroup' ||
      _params.fn === 'placeBid'
    ) {
      this.setSelected('profile');
    }
  }

  goToSelected() {
    let _params = queryString.parse(this.props.location.search);
    let _url = '/create/group?';

    if (this.state.selected === 'profile') {
      _url = '/create/group?skipGroup=true&';
    }

    if (_params.redirect) {
      _url += 'redirect=' + _params.redirect + '&';
    }

    if (_params.fn) {
      _url += 'fn=' + _params.fn;
    }

    this.props.history.push(_url);
  }

  render() {
    return (
      <div className="GetStarted">
        <div className="curve-wrap">
          <div className="curve"></div>
          <div className="bottom-fill"></div>
        </div>
        <div className="GetStarted-inner">
          <div className="title-section">
            <h1 className="title">Get started on Kambeo</h1>
            <div className="instructions">
              Select the option that describes you best, you can make changes later.
            </div>
          </div>
          <div className="options">
            <div className="option-row">
              <div
                onClick={() => this.setSelected('group')}
                className={this.state.selected === 'group' ? 'option group' : 'option group'}
              >
                <div className="option-inner">
                  <div className="option-img-container">
                    <div className="option-img group"></div>
                  </div>
                  <div className="option-info">
                    <div className="option-title">Business</div>
                    <div className="option-desc">
                      I represent a for-profit business that provides products or services to my
                      local community and beyond.
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => this.setSelected('non-profit')}
                className={
                  this.state.selected === 'non-profit' ? 'option non-profit' : 'option non-profit'
                }
              >
                <div className="option-inner">
                  <div className="option-img-container">
                    <div className="option-img non-profit"></div>
                  </div>
                  <div className="option-info">
                    <div className="option-title">Non-Profit or Charity</div>
                    <div className="option-desc">
                      I represent a non-profit or charitable organization or foundation.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="option-row">
              <div
                onClick={() => this.setSelected('community')}
                className={
                  this.state.selected === 'community' ? 'option community' : 'option community'
                }
              >
                <div className="option-inner">
                  <div className="option-img-container">
                    <div className="option-img community"></div>
                  </div>
                  <div className="option-info">
                    <div className="option-title">Community Cause / Event</div>
                    <div className="option-desc">
                      I represent a community cause, activity, or event that is not a non-profit or
                      charity, that is seeking members, or attendees for an event or activity.
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => this.setSelected('profile')}
                className={this.state.selected === 'profile' ? 'option' : 'option'}
              >
                <div className="option-inner">
                  <div className="option-img-container">
                    <div className="option-img profile"></div>
                  </div>
                  <div className="option-info">
                    <div className="option-title">Individual</div>
                    <div className="option-desc">
                      I want to create a unique experience profile for myself to find volunteer/paid
                      opportunities, purchase event tickets, make donations and more.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="actions-row">
            <div className="login">
              Already have an account? <Link to="/login">Login</Link>
            </div>
            {/* <Button onClick={() => this.goToSelected() } text="Next"/> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
  };
};

const mapDispatchToProps = {};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(GetStarted),
);
