import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { IToast } from '../../interfaces';
import { createToast } from '../../actions/toaster';
import { groupRequestActions } from '../../requestActions';
import TextField from '../TextField/TextField';
import SortableTable, { ISortableTableColumn } from '../SortableTable/SortableTable';
import { IGroup } from '@gigit/interfaces';
import { formatQuery, handleInputChange } from '../../helpers';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../shared/PopupMenu/PopupMenu';
import Loader from '../Loader/Loader';
import Portrait from '../Portrait/Portrait';
import Checkbox from '../Checkbox/Checkbox';

import './RecommendedCauses.scss';
import useToastDispatcher from '../../hooks/useToaster';
import { getCauses } from '../shared/Onboarding/helpers';
import { closeModal } from '../../actions/modals';
import { Prompt } from '../Prompt/Prompt';

interface IProps {
  createToast(toast: IToast): void;
}

const RecommendedCauses: FC = () => {
  const [recommendedCauses, setRecommendedCauses] = useState<IGroup[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalSearch, setModalSearch] = useState<string>('');
  const [showContextMenuModalSort, setShowContextMenuModalSort] = useState<boolean>(false);
  const [loadingToAddPartners, setLoadingToAddPartners] = useState<boolean>(false);
  const [selectedCauses, setSelectedCauses] = useState<string[]>([]);
  const [toAddCauses, setToAddCauses] = useState<IGroup[]>([]);
  const [modalSortBy, setModalSortBy] = useState<'asc' | 'desc'>('asc');
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [selectedForRemoval, setSelectedForRemoval] = useState<string>('');
  const [modalSearchTimeout, setModalSearchTimeout] = useState<ReturnType<typeof setTimeout>>();

  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();

  const groupColumns = [
    { id: 'title', label: 'Name', sortable: false },
    { id: 'handle', label: 'Handle', sortable: false },
    { id: 'actions', label: '', sortable: false },
  ];

  const popupMenuConfigModalSort: IShowPopupConfig = {
    showMenu: showContextMenuModalSort,
    setShowMenu: setShowContextMenuModalSort,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItemsModalSort: IPopupMenuItem[] = [
    {
      id: 'a_z',
      label: 'A to Z',
      isSelected: 'asc' === modalSortBy,
      onClick: () => {
        setModalSortBy('asc');
        setShowContextMenuModalSort(false);
      },
    },
    {
      id: 'z_a',
      label: 'Z to A',
      isSelected: 'desc' === modalSortBy,
      onClick: () => {
        setModalSortBy('desc');
        setShowContextMenuModalSort(false);
      },
    },
  ];

  useEffect(() => {
    getRecommendedCauses();
  }, []);

  useEffect(() => {
    if (modalSearchTimeout) {
      clearTimeout(modalSearchTimeout);
    }

    if (modalSearch !== null) {
      setModalSearchTimeout(
        setTimeout(() => {
          getGroups();
        }, 500),
      );
    }

    return () => {
      if (modalSearchTimeout) {
        clearTimeout(modalSearchTimeout);
      }
    };
  }, [modalSearch]);

  async function getGroups() {
    if (modalSearch) {
      try {
        setLoadingToAddPartners(true);
        const result = await groupRequestActions.getGroups(
          formatQuery({
            limit: '24',
            sort: [{ id: 'title', order: modalSortBy }],
            filters:
              modalSearch !== '' && modalSearch !== null
                ? [
                    {
                      id: 'title',
                      label: 'title',
                      type: 'text',
                      value: modalSearch,
                    },
                  ]
                : undefined,
          }),
        );
        const tmpIds = recommendedCauses.map((cause) => cause.id) as string[];
        const filteredGroups = result.filter((group) => tmpIds.indexOf(group.id) === -1);
        setToAddCauses(filteredGroups);
      } catch (error) {
        dispatchToastError(error, 'Get Partners To Add');
      } finally {
        setLoadingToAddPartners(false);
      }
    }
  }

  const getRecommendedCauses = async () => {
    try {
      let causes = await groupRequestActions.getRecommendedCauses();

      setRecommendedCauses(causes);
    } catch (error) {
      dispatchToastError(error, 'Recommended Causes');
    }
  };

  const isAddToCauseChecked = (causeId: string): boolean => {
    return selectedCauses.indexOf(causeId) !== -1;
  };

  const addCauses = async () => {
    await groupRequestActions.updateRecommendedCauses(selectedCauses);
    await getRecommendedCauses();
  };

  const removeRecommended = async (id: string) => {
    if (id) {
      await groupRequestActions.removeRecommendedCauses([id]);
      await getRecommendedCauses();
      setShowPrompt(false);
    }
  };

  const getGropsTableData = () => {
    const lcSearchValue = searchValue.toLowerCase();

    return recommendedCauses.map((cause: IGroup) => {
      return {
        row: [
          { content: cause.title, id: 'title', hoverText: cause.title },
          { content: cause.handle, id: 'handle', hoverText: cause.handle },
          {
            id: 'actions',
            menu: [
              {
                icon: 'fa fa-ban',
                onClick: () => {
                  setSelectedForRemoval(cause.id);
                  setShowPrompt(true);
                },
                label: 'Remove',
              },
            ],
          },
        ],
      };
    });
  };

  const closeCauseModal = () => {
    setSelectedCauses([]);
    setToAddCauses([]);
    setShowModal(false);
  };

  return (
    <>
      <div className="Charities">
        <div className="section-title">
          <div className="forms-title">Recommended Causes</div>
          <div className="forms-controls">{/* Actions buttons here if needed */}</div>
        </div>

        <div className="section-inner">
          <div className="search">
            <TextField
              icon="fas fa-search"
              placeholder="Search cause..."
              value={searchValue}
              type="text"
              name="searchValue"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchValue(e.target.value);
              }}
            />
            <div className="filters">
              <Button
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add Cause
              </Button>
            </div>
          </div>

          <div className="items-list">
            <SortableTable
              columns={groupColumns}
              data={getGropsTableData()}
              itemsPerPage={5}
              loading={dataLoading}
            />
          </div>

          {!dataLoading && (
            <div className={`no-items-found ${getGropsTableData().length ? '' : 'show'}`}>
              No items found
            </div>
          )}
        </div>
      </div>
      <Modal
        onClose={() => closeCauseModal()}
        show={showModal}
        contentClassName="add-causes-modal"
        closeIcon="fas fa-times"
      >
        <div className="modal-header">
          <h2>Add Causes</h2>
          <span>Select the causes that you want to support with your company</span>
          <div className="filter-container">
            <TextField
              icon="fal fa-search"
              placeholder="Search for a Charity or Non-Profit"
              value={modalSearch || ''}
              name="modalSearch"
              type="text"
              onChange={(e) => {
                setModalSearch(e.target.value);
              }}
            />

            <PopupMenu
              showMenuConfig={popupMenuConfigModalSort}
              menuItems={contextMenuItemsModalSort}
              popupMenuClass="hubManagementBenefitingCauses-popupMenu"
              className={`Dropdown filter-dropdown ${showContextMenuModalSort ? 'show' : ''}`}
              onClick={() => setShowContextMenuModalSort(!showContextMenuModalSort)}
            >
              <label>
                <span>Sort by</span>
              </label>
              <div className="input-wrap">
                <span
                  className="label"
                  notranslate="yes"
                >
                  {modalSortBy === 'asc' ? 'A to Z' : 'Z to A'}
                </span>
                <i
                  className={`fas fa-caret-down menu-btn ${showContextMenuModalSort ? 'show' : ''}`}
                />
              </div>
            </PopupMenu>
          </div>
        </div>

        <div className="modal-main">
          <div className="partners-container">
            <Loader loading={loadingToAddPartners} />
            {!modalSearch && (
              <div className="empty-state">
                <span>Use the search bar to find causes you want to support with your company</span>
              </div>
            )}
            {!loadingToAddPartners && modalSearch && toAddCauses.length === 0 && (
              <div className="empty-state">
                <span>No results found</span>
              </div>
            )}
            {toAddCauses.map((cause, index) => (
              <div
                className="to-add-partner"
                key={index}
              >
                <Portrait
                  size={40}
                  currentImage={cause.profile_image_url}
                />
                <div className="info">
                  <span
                    className="title"
                    notranslate="yes"
                  >
                    {cause.title}
                  </span>
                  <span className="charity-id">
                    {`Charity ID : `}
                    <var data-var="charity_id">{cause.charity_id}</var>
                  </span>
                </div>
                <Checkbox
                  name={`modal-cb-${index}`}
                  label=""
                  value=""
                  checked={isAddToCauseChecked(cause.id)}
                  onChange={() => {
                    if (isAddToCauseChecked(cause.id)) {
                      setSelectedCauses(selectedCauses.filter((id) => id !== cause.id));
                    } else {
                      setSelectedCauses([...selectedCauses, cause.id]);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="btn-container">
          <Button
            text="Cancel"
            type="button"
            onClick={() => setShowModal(false)}
            buttonType="secondary"
          />
          <Button
            text="Save"
            type="button"
            buttonType="dark"
            onClick={async () => {
              await addCauses();
              setShowModal(false);
            }}
          />
        </div>
      </Modal>

      <Prompt
        show={!!showPrompt}
        title={'Remove cause from recommended list'}
        message={`Are you sure you want to remove this cause from recommended list?`}
        yesMessage={'Remove'}
        yesClass={'fa fa-ban'}
        yesStyle={'delete'}
        cancelMessage="Cancel"
        onYes={() => {
          removeRecommended(selectedForRemoval);
        }}
        onClose={() => {
          setSelectedForRemoval('');
          setShowPrompt(false);
        }}
      />
    </>
  );
};

export default RecommendedCauses;
