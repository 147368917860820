import React from 'react';
import Button from '../../../../Button/Button';
import Modal from '../../../../Modal/Modal';
import './EditModal.scss';

interface IEditModalProps {
  title: string;
  showModal: boolean;
  /** There are default components under Hub/HubModals/shared/ that you can utilize to build your Modals */
  children: React.ReactNode;
  modalClassName?: string;
  contentClassName?: string;
  onClose?: () => void;
  isSaving?: boolean;
  onSave?: () => void;
  /** Controls size of modal, default is large */
  modalSize?: 'medium' | 'large';
  formId?: string;
  disableSave?: boolean;
}

/** This provides the base modal for editing page components for Hubs with an optional action footer for both save and close buttons.
 * You can pass other generic container components as children to this component
 */
function EditModal(props: IEditModalProps) {
  const { showModal, title, children, onSave, onClose } = props;
  //controls the min width applied in scss
  const modalSize = props.modalSize ? props.modalSize : 'large';

  return (
    <Modal
      show={showModal}
      title={title}
      onClose={onClose}
      class={`EditComponentModal ${props.modalClassName} ${modalSize}`}
      closeIcon="fas fa-times"
    >
      <div className={`edit-modal-content ${props.contentClassName}`}>{children}</div>
      <div className="edit-modal-actions">
        {onClose && (
          <Button
            text="Close"
            buttonType="secondary"
            onClick={onClose}
            type="button"
          />
        )}
        {onSave && (
          <Button
            text="Save"
            buttonType="primary"
            onClick={onSave}
            loading={props.isSaving}
            formId={props.formId}
            type={props.formId ? 'submit' : 'button'}
            isDisabled={props.disableSave || false}
          />
        )}
      </div>
    </Modal>
  );
}

export default EditModal;
