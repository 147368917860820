import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../Modal/Modal';
import { Config } from '@gigit/config';
import './AuctionItemBuyNowModal.scss';
import auctionItemHelpers from '../auctionItemHelpers';
import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import Button from '../../../Button/Button';
import ModalHeader from '../../../Modal/ModalHeader/ModalHeader';
import TextField from '../../../TextField/TextField';

import { IGroupState } from '../../../../reducers/group';
import { IUserState } from '../../../../reducers/user';
import { IEventState } from '../../../../reducers/event';
import { IAuctionState } from '../../../../reducers/auction';
import { IAppState } from '../../../../store';
import {
  createBuyNowPaymentIntent,
  resetBuyNowData,
  AuctionItemOwnerType,
} from '../../../../actions/auction';
import { createToast } from '../../../../actions/toaster';
import {
  defaultCurrency,
  formatAddressLine,
  handleInputChange,
  toastError,
  toastSuccess,
} from '../../../../helpers';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import CreditCard from '../../../CreditCard/CreditCard';
import { CheckoutLocale, loadStripe, Stripe } from '@stripe/stripe-js';
import Loader from '../../../Loader/Loader';
import Dropdown, { IOptions } from '../../../Dropdown/Dropdown';
import moment from 'moment';
import {
  IAccountPublicSummary,
  IAuctionItemSummary,
  IEvent,
  IEventSummaryFE,
  ITransactionAmounts,
  PaymentMethodType,
} from '@gigit/interfaces';
import { userSelectors } from '../../../../selectors/user';
import { formatCurrency } from '../../../../helpers';
import { Constants } from '@gigit/constants';
import errorHelpers from '../../../../helpers/errorHelpers';
import { localizeHelpers } from '../../../../localizeHelpers';
import { IToast } from '../../../../interfaces';
import { uiConstants } from '../../../../constants';
import { IOwnerObject } from '../../../../interfaces';
import typeHelpers from '../../../../helpers/typeHelpers';

export interface IAuctionManualPaymentDetails {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
}

interface IPassedProps {
  showBuyNowModal: boolean;
  item: IAuctionItemSummary;
  isManualPayment?: boolean;
  manualPaymentDetails?: IAuctionManualPaymentDetails;
  handleOnSuccessfulSubmitPayment?: (userId: string, itemId: string) => void;
  onClose(): void;
  owner: IOwnerObject;
}

interface IPropsFromState {
  groupState: IGroupState;
  userState: IUserState;
  eventState: IEventState;
  auctionState: IAuctionState;
  locale: string;
}

interface IPropsFromDispatch {
  resetBuyNowData(): void;
  createBuyNowPaymentIntent(
    groupId: string,
    eventId: string | undefined,
    _id: string,
    _payload: any,
    callback?: any,
  ): void;
  createToast(toast: IToast): void;
}

type IProps = IPassedProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  tryCreatePaymentMethod: boolean;
  trySubmitPayment: boolean;
  paymentLoading: boolean;
  stripePromise: Promise<Stripe | null> | null;

  shipOption: string;
  shipStreetAddress: string;
  shipAptNum: string;
  shipCity: string;
  shipProvince: string;
  shipCountry: string;
  shipPostal: string;
  billStreetAddress: string;
  billAptNum: string;
  billCity: string;
  billProvince: string;
  billCountry: string;
  billPostal: string;
  feeControl: any;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  cardName: string;
  sameAsShipping: boolean;
  typeOfPayment: PaymentMethodType;
  typeOfPaymentOptions: IOptions[];
  cashAmount: string;
  chequePayee: string;
  chequeAmount: string;
  chequeDate: any;
  chequeAccountNumber: string;
  eTransferAmount: string;
  buyNowSummary: ITransactionAmounts | null;
  buyNowSummaryLoading: boolean;
  dontSendAutoPurchaseReceipt: boolean;
}

/** Modal for displaying auction item details. Mainly used in mobile view. */
class AuctionItemBuyNowModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tryCreatePaymentMethod: false,
      trySubmitPayment: false,
      paymentLoading: false,
      stripePromise: null,

      shipOption: this.props.item.is_shipping_available
        ? 'ship'
        : formatAddressLine(this.props.item.pickup_locations?.[0] || ''),
      shipStreetAddress: '',
      shipAptNum: '',
      shipCity: '',
      shipProvince: '',
      shipCountry: '',
      shipPostal: '',
      billStreetAddress: '',
      billAptNum: '',
      billCity: '',
      billProvince: '',
      billCountry: '',
      billPostal: '',
      feeControl: null,
      firstName: !this.props.isManualPayment
        ? this.props.userState.user.first_name
        : this.props.manualPaymentDetails?.firstName ?? '',
      lastName: !this.props.isManualPayment
        ? this.props.userState.user.last_name
        : this.props.manualPaymentDetails?.lastName ?? '',
      email: !this.props.isManualPayment
        ? this.props.userState.user.email
        : this.props.manualPaymentDetails?.email ?? '',
      phone: !this.props.isManualPayment
        ? this.props.userState.user.phone ?? ''
        : this.props.manualPaymentDetails?.phone ?? '',
      cardName: '',
      sameAsShipping: false,
      typeOfPayment: this.props.isManualPayment ? 'cash' : 'card',
      typeOfPaymentOptions: [
        { label: 'Cash', value: 'cash' },
        { label: 'Cheque', value: 'cheque' },
        { label: 'E-Transfer', value: 'e-transfer' },
      ],
      cashAmount: '',
      chequePayee: '',
      chequeAmount: '',
      chequeDate: moment(),
      chequeAccountNumber: '',
      eTransferAmount: '',
      buyNowSummary: null,
      buyNowSummaryLoading: false,
      dontSendAutoPurchaseReceipt: false,
    };

    this.onSuccessfulPaymentMethod = this.onSuccessfulPaymentMethod.bind(this);
    this.onFailedPaymentMethod = this.onFailedPaymentMethod.bind(this);
    this.onFailedSubmitPayment = this.onFailedSubmitPayment.bind(this);
    this.onSuccessfulSubmitPayment = this.onSuccessfulSubmitPayment.bind(this);
    this.getBuyNowPrice = this.getBuyNowPrice.bind(this);
    this.hasShippingOptions = this.hasShippingOptions.bind(this);
    this.handleSubmitPayment = this.handleSubmitPayment.bind(this);
    this.getPaymentPayload = this.getPaymentPayload.bind(this);
  }

  componentDidMount() {
    if (this.props.owner.ownerType === 'group') {
      this.setState({
        feeControl: this.props.groupState.group.fee_control,
      });
    } else if (this.props.owner.ownerType === 'event') {
      this.setState({
        feeControl: this.props.eventState.event.fee_control,
      });
    }

    this.setState({
      cashAmount: String(this.props.item.current_bid?.bid_amount),
      chequeAmount: String(this.props.item.current_bid?.bid_amount),
      eTransferAmount: String(this.props.item.current_bid?.bid_amount),
    });
  }

  /** Initializes Stripe instance based on owner account info. */
  setupStripeInstance() {
    if (!this.props?.owner?.account || !this.props?.owner?.account?.account_number) {
      throw new Error(
        localizeHelpers.translate(
          'Expected an account to receive payment. Contact administrator if this error persists.',
        ),
      );
    }

    this.setState({
      stripePromise: loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY, {
        stripeAccount: this.props?.owner?.account?.account_number,
        locale: this.props.locale as CheckoutLocale,
      }),
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevProps.auctionState.buyNowLocked !== this.props.auctionState.buyNowLocked &&
      this.props.auctionState.buyNowLocked
    ) {
      this.props.onClose();
    }
    if (
      prevProps.auctionState.buyNowError !== this.props.auctionState.buyNowError &&
      this.props.auctionState.buyNowError !== null &&
      this.state.paymentLoading
    ) {
      const toast = toastError(this.props.auctionState.buyNowError, 'Auction Payment Error');
      this.props.createToast(toast);

      this.props.resetBuyNowData();

      this.setState({
        paymentLoading: false,
      });
    }

    if (
      prevProps.auctionState.buyNowIntent !== this.props.auctionState.buyNowIntent &&
      this.props.auctionState.buyNowError == null &&
      this.props.isManualPayment
    ) {
      this.onSuccessfulSubmitPayment();

      this.props.onClose();
    }

    if (
      prevProps.auctionState.buyNowIntent !== this.props.auctionState.buyNowIntent &&
      this.props.auctionState.buyNowIntent !== null &&
      !this.state.trySubmitPayment
    ) {
      this.setState({
        trySubmitPayment: true,
      });
    }

    if (
      prevState.typeOfPayment !== this.state.typeOfPayment ||
      prevState.shipOption !== this.state.shipOption ||
      (!prevProps.showBuyNowModal && this.props.showBuyNowModal)
    ) {
      this.loadBuyNowSummary();

      this.setState({
        chequeDate: moment(),
        chequeAccountNumber: '',
        chequePayee: '',
      });
    }

    if (
      this.props.isManualPayment &&
      prevProps.manualPaymentDetails !== this.props.manualPaymentDetails
    ) {
      this.setState({
        firstName: this.props.manualPaymentDetails?.firstName ?? '',
        lastName: this.props.manualPaymentDetails?.lastName ?? '',
        email: this.props.manualPaymentDetails?.email ?? '',
        phone: this.props.manualPaymentDetails?.phone ?? '',
      });
    }

    if (prevState.chequeAmount !== this.state.chequeAmount && this.state.chequeAmount) {
    }

    if (prevProps.owner?.account !== this.props.owner?.account && this.props.owner?.account) {
      this.setupStripeInstance();
    }
  }

  hasShippingOptions(item: any) {
    if (item.is_pickup_available || item.is_shipping_available) {
      return true;
    }

    return false;
  }

  getManualAmount() {
    if (this.state.typeOfPayment === 'cash') {
      return this.state.cashAmount;
    } else if (this.state.typeOfPayment === 'cheque') {
      return this.state.chequeAmount;
    } else if (this.state.typeOfPayment === 'e-transfer') {
      return this.state.eTransferAmount;
    }
    throw new Error(`Unsupported payment type ${this.state.typeOfPayment}`);
  }

  getBuyNowPrice() {
    let _amount: number = this.state.buyNowSummary?.amount || 0;

    if (this.props.isManualPayment) {
      return parseFloat(this.getManualAmount()) || 0;
    }

    if (this.state.feeControl?.covers_processing_fees) {
      _amount += this.state.buyNowSummary?.payment_platform_fee ?? 0;
    }

    if (this.state.feeControl?.covers_gigit_fees) {
      _amount += this.state.buyNowSummary?.gigit_fee ?? 0;
    }

    return _amount;
  }

  getTotalPrice() {
    if (!this.state.buyNowSummary) {
      return 0;
    }

    const { amount, total, shipping, discount } = this.state.buyNowSummary;

    if (this.props.isManualPayment) {
      let customAmount = parseFloat(this.getManualAmount()) || 0;
      return customAmount;
    }
    if (this.state.typeOfPayment === Constants.payment_method.invoice) {
      return amount - (discount ?? 0) + (shipping ?? 0);
    }

    return total;
  }

  onSuccessfulSubmitPayment() {
    const toast = toastSuccess(
      localizeHelpers.translate(
        "Successfully paid for auction item. The transaction record will show up shortly on your account page under 'Auctions'.",
      ),
      'Auction Payment',
    );
    this.props.createToast(toast);

    this.setState({
      paymentLoading: false,
    });

    if (this.props.handleOnSuccessfulSubmitPayment) {
      const userId = !this.props.isManualPayment
        ? this.props.userState.user.id
        : this.props.manualPaymentDetails?.id;
      if (userId && this.props.item?.id) {
        this.props.handleOnSuccessfulSubmitPayment(userId, this.props.item.id);
      }
    }
  }

  onFailedPaymentMethod(_error: any) {
    const errorObj = errorHelpers.getErrorObject(_error);
    const toast = toastError(errorObj.translatedMessage, 'Auction Payment Error');
    this.props.createToast(toast);

    this.setState({
      paymentLoading: false,
    });
  }

  getPaymentPayload(response?: any) {
    let _payload: any = {
      payment_method: response != null ? 'card' : this.state.typeOfPayment,
      ship_by_vendor: this.props.item.is_shipping_available && this.state.shipOption === 'ship',
      bid_owner_type: this.props.owner.ownerType,
      bid_owner_id:
        this.props.owner.ownerType === 'group'
          ? this.props.groupState.group.id
          : this.props.eventState.event.id,
      dont_send_auto_purchase_receipt: this.state.dontSendAutoPurchaseReceipt,
      shipping_address: {
        line1: this.state.shipStreetAddress,
        line2: this.state.shipAptNum,
        city: this.state.shipCity,
        state: this.state.shipProvince,
        country: this.state.shipCountry,
        postal_code: this.state.shipPostal,
      },
      bidder: {
        id: !this.props.isManualPayment
          ? this.props.userState.user.id
          : this.props.manualPaymentDetails?.id,
        email: !this.props.isManualPayment ? this.props.userState.user.email : this.state.email,
        first_name: !this.props.isManualPayment
          ? this.props.userState.user.first_name
          : this.state.firstName,
        last_name: !this.props.isManualPayment
          ? this.props.userState.user.last_name
          : this.state.lastName,
      },
    };

    if (response) {
      _payload.payment_method_id = response.id;
      _payload.payment_method_country = response.card.country;
    } else {
      if (this.props.isManualPayment) {
        _payload.amount = parseFloat(this.getManualAmount()) || 0;
      } else {
        if (this.state.typeOfPayment === Constants.payment_method.invoice) {
          _payload.amount = this.getBuyNowPrice();
        } else {
          throw new Error(
            `Expected a payment intent response for non-admin payment with payment method ${this.state.typeOfPayment}`,
          );
        }
      }
    }

    if (this.state.shipOption === 'ship') {
      _payload.ship_by_vendor = true;
    } else if (this.props.item?.pickup_locations) {
      for (let l in this.props.item.pickup_locations) {
        if (formatAddressLine(this.props.item.pickup_locations[l]) === this.state.shipOption) {
          _payload.pickup_location = this.props.item.pickup_locations[l];
          break;
        }
      }
    }

    // Admin payment, doing this will cause shipping cost to be 0.
    if (this.props.isManualPayment) {
      _payload.ignore_shipping_cost = true;
    }

    if (this.state.sameAsShipping) {
      _payload.billing_address = {
        line1: this.state.shipStreetAddress,
        line2: this.state.shipAptNum,
        city: this.state.shipCity,
        state: this.state.shipProvince,
        country: this.state.shipCountry,
        postal_code: this.state.shipPostal,
      };
    } else {
      _payload.billing_address = {
        line1: this.state.billStreetAddress,
        line2: this.state.billAptNum,
        city: this.state.billCity,
        state: this.state.billProvince,
        country: this.state.billCountry,
        postal_code: this.state.billPostal,
      };
    }

    if (this.props.userState.isLoggedIn === false) {
      _payload.bidder.language = this.props.userState.loggedOutLanguage;
    }

    return _payload;
  }

  onSuccessfulPaymentMethod(response: any) {
    this.handleSubmitCreateBuyNowPaymentIntent(response);
  }

  handleSubmitCreateBuyNowPaymentIntent(response?: any) {
    const _payload = this.getPaymentPayload(response);
    const groupId =
      typeHelpers.getGroupIdFromOwner(this.props.owner) || this.props.groupState.group.id;

    if (this.props.item?.id) {
      this.props.createBuyNowPaymentIntent(
        groupId,
        this.props.owner.ownerType === 'event' ? this.props.owner.ownerId : undefined,
        this.props.item.id,
        _payload,
      );
    }
  }

  onFailedSubmitPayment(_error: any) {
    const errorObj = errorHelpers.getErrorObject(_error);
    const toast = toastError(errorObj.translatedMessage, 'Auction Payment Error');
    this.props.createToast(toast);

    this.setState({
      paymentLoading: false,
    });
  }

  handleSubmitPayment(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (this.props.isManualPayment) {
      this.setState({ paymentLoading: true });
      this.handleSubmitCreateBuyNowPaymentIntent();
    } else {
      if (this.state.typeOfPayment === Constants.payment_method.invoice) {
        //if user pay invoice
        const _payload = this.getPaymentPayload();
        const groupId =
          typeHelpers.getGroupIdFromOwner(this.props.owner) || this.props.groupState.group.id;

        if (this.props.item?.id) {
          this.props.createBuyNowPaymentIntent(
            groupId,
            this.props.owner.ownerType === 'event' ? this.props.owner.ownerId : undefined,
            this.props.item.id,
            _payload,
            this.onSuccessfulSubmitPayment,
          );
        }
      } else {
        //if user pay by card
        this.setState({ tryCreatePaymentMethod: true, paymentLoading: true });
      }
    }
  }

  async loadBuyNowSummary() {
    try {
      this.setState({ buyNowSummaryLoading: true });

      if (this.props.item?.id) {
        const summary = await auctionItemHelpers.getBuyNowPaymentSummary(
          this.props.owner.ownerType as AuctionItemOwnerType,
          this.props.owner.ownerId,
          this.props.item.id,
          {
            ship_by_vendor:
              this.props.item.is_shipping_available && this.state.shipOption === 'ship',
            payment_method: this.state.typeOfPayment,
          },
        );

        this.setupStripeInstance();
        this.setState({ buyNowSummary: summary });
      }
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Fetch Buy Now Payment Summary');
      this.props.createToast(toast);
    } finally {
      this.setState({ buyNowSummaryLoading: false });
    }
  }

  isInvoicingAllowed() {
    return (
      (this.props.owner.ownerType === 'group' && this.props.groupState.group.allow_invoicing) ||
      typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'event')?.allow_invoicing
    );
  }

  render() {
    const currency = this.props.owner.account?.currency ?? defaultCurrency;

    return (
      <div className="AuctionItemBuyNowModal">
        <Modal
          contentClassName="no-background"
          show={this.props.showBuyNowModal}
          onClose={this.props.onClose}
        >
          <ModalHeader
            title="Auction Payment"
            description="Fill out the form below to complete your auction purchase"
            className="modal-header-mobile"
            onClose={this.props.onClose}
          />

          <form
            onSubmit={(e) => {
              this.handleSubmitPayment(e);
            }}
            className="auction-payment"
          >
            <div className="details">
              <ModalHeader
                title="Auction Payment"
                description="Fill out the form below to complete your auction purchase"
                className="modal-header-desktop"
                onClose={this.props.onClose}
              />
              <div className="item-details">
                {this.props.item.media && this.props.item.media.length > 0 && (
                  <div className="image">
                    <img
                      alt="Auction Item"
                      src={this.props.item.media[0].url}
                    />
                  </div>
                )}
                <div className="item-content">
                  <div className="item-title">{this.props.item.name}</div>
                  <div className="item-description">
                    <QuillTextEditor
                      value={this.props.item.description || 'This item has no description.'}
                      readOnly={true}
                      preserveWhitespace={true}
                      theme={uiConstants.quill.readOnlyTheme}
                      modules={auctionItemHelpers.quillModules}
                      formats={auctionItemHelpers.quillFormats}
                    />
                  </div>
                  <div className="winner">Winner</div>
                </div>
              </div>
              <div className="form-title">Contact Information</div>
              <div className="row dbl">
                <TextField
                  required={true}
                  label="First Name"
                  value={this.state.firstName}
                  name="firstName"
                  type="text"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
                <TextField
                  required={true}
                  label="Last Name"
                  value={this.state.lastName}
                  name="lastName"
                  type="text"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
              </div>
              <div className="row dbl">
                <TextField
                  required={true}
                  disabled={true}
                  label="Email"
                  value={this.state.email}
                  name="email"
                  type="email"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
                <TextField
                  required={true}
                  label="Phone Number"
                  value={this.state.phone}
                  name="phone"
                  type="tel"
                  onChange={(e) => {
                    handleInputChange(e, this);
                  }}
                />
              </div>
              {this.hasShippingOptions(this.props.item) && (
                <div className="form-title">Shipping/Pick-up Options</div>
              )}
              {this.hasShippingOptions(this.props.item) && (
                <div className="shipping-options">
                  {this.props.item.is_shipping_available && (
                    <div
                      onClick={() => {
                        this.setState({ shipOption: 'ship' });
                      }}
                      className="option shipping-option no-select"
                    >
                      <span className="ship-title">Shipped by Vendor</span>
                      <div className="shipping-label">
                        <i
                          className={
                            this.state.shipOption === 'ship'
                              ? 'fas fa-check-circle'
                              : 'far fa-circle'
                          }
                        />
                        {this.props.item.shipping_price_type === 'Amount'
                          ? formatCurrency(
                              this.props.item.shipping_price || 0,
                              currency,
                              this.props.locale,
                            )
                          : this.props.item.shipping_price_type === 'Free'
                            ? 'Free'
                            : 'TBD'}
                      </div>
                    </div>
                  )}
                  {this.props.item.is_pickup_available &&
                    this.props.item.pickup_locations &&
                    this.props.item.pickup_locations.length > 0 && (
                      <div className="pickup-locations">
                        <span className="ship-title">Pick-Up</span>
                        {this.props.item.pickup_locations.map((location: any, index: number) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                this.setState({ shipOption: formatAddressLine(location) });
                              }}
                              className="shipping-label"
                            >
                              <i
                                className={
                                  this.state.shipOption === formatAddressLine(location)
                                    ? 'fas fa-check-circle'
                                    : 'far fa-circle'
                                }
                              />
                              <span>{location.title}</span>
                              {formatAddressLine(location)}
                            </div>
                          );
                        })}
                      </div>
                    )}
                </div>
              )}
              {this.state.shipOption === 'ship' && this.props.item.is_shipping_available && (
                <div className="ship">
                  <div className="form-title">Shipping Address</div>
                  <div className="row dbl">
                    <TextField
                      required={true}
                      label="Street Address"
                      value={this.state.shipStreetAddress}
                      name="shipStreetAddress"
                      type="text"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                    <TextField
                      label="Apartment / Suite #"
                      value={this.state.shipAptNum}
                      name="shipAptNum"
                      type="text"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                  </div>
                  <div className="row dbl">
                    <TextField
                      required={true}
                      label="City"
                      value={this.state.shipCity}
                      name="shipCity"
                      type="text"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                    <TextField
                      required={true}
                      label="Province"
                      value={this.state.shipProvince}
                      name="shipProvince"
                      type="text"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                  </div>
                  <div className="row dbl">
                    <TextField
                      required={true}
                      label="Country"
                      value={this.state.shipCountry}
                      name="shipCountry"
                      type="text"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                    <TextField
                      required={true}
                      label="Postal Code"
                      value={this.state.shipPostal}
                      name="shipPostal"
                      type="text"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                  </div>
                </div>
              )}
              {this.props.isManualPayment === true && (
                <>
                  <div className="form-title">Payment Information</div>
                  <div className="row">
                    <Dropdown
                      label="Type of Payment"
                      name="typeOfPayment"
                      value={this.state.typeOfPayment}
                      shouldSort={true}
                      options={[...this.state.typeOfPaymentOptions]}
                      onChange={(e) => handleInputChange(e, this)}
                    />
                  </div>
                  <div
                    onClick={() => {
                      this.setState({
                        dontSendAutoPurchaseReceipt: !this.state.dontSendAutoPurchaseReceipt,
                      });
                    }}
                    className="sub-form-title click"
                  >
                    <i
                      className={
                        !this.state.dontSendAutoPurchaseReceipt
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'
                      }
                    />
                    <span>Send Receipt Confirmation Email</span>
                  </div>
                </>
              )}
              {this.state.typeOfPayment === 'cash' && this.props.isManualPayment && (
                <>
                  <div className="form-title">Cash</div>
                  <div className="row">
                    <TextField
                      required={true}
                      min="1"
                      label="Amount"
                      value={this.state.cashAmount}
                      name="cashAmount"
                      type="number"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                  </div>
                </>
              )}
              {this.state.typeOfPayment === 'cheque' && this.props.isManualPayment === true && (
                <>
                  <div className="form-title">Cheque</div>
                  <div className="row">
                    {/* <TextField required={true} label={t("Payee")} value={this.state.chequePayee} name="chequePayee" type="text" onChange={(e) => { handleInputChange(e, this); }} /> */}
                    <TextField
                      required={true}
                      min="1"
                      label="Amount"
                      value={this.state.chequeAmount}
                      name="chequeAmount"
                      type="number"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                  </div>
                  {/* <div className="row dbl">
                                        <div className="date-wrapper">
                                            <label>{t("Date")}</label>
                                            <Datetime
                                                inputProps={ { readOnly: true } }
                                                timeFormat={false}
                                                className="custom-dtp"
                                                value={ this.state.chequeDate }
                                                onChange={(newDate) => this.setState({ chequeDate: newDate})}
                                            />
                                        </div>
                                        <TextField required={true} label={t("Account Number")} value={this.state.chequeAccountNumber} name="chequeAccountNumber" type="text" onChange={(e) => { handleInputChange(e, this); }} />
                                    </div> */}
                </>
              )}
              {this.state.typeOfPayment === 'e-transfer' && this.props.isManualPayment && (
                <>
                  <div className="form-title">E-Transfer</div>
                  <div className="row">
                    <TextField
                      required={true}
                      min="1"
                      label="Amount"
                      value={this.state.eTransferAmount}
                      name="eTransferAmount"
                      type="number"
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                    />
                  </div>
                </>
              )}

              {this.props.isManualPayment == null && (
                <>
                  <div className="form-title">Credit Card</div>
                  {this.isInvoicingAllowed() && (
                    <div
                      onClick={() => {
                        this.setState({
                          typeOfPayment:
                            this.state.typeOfPayment !== Constants.payment_method.invoice
                              ? 'invoice'
                              : 'card',
                        });
                      }}
                      className="sub-form-title click"
                    >
                      <i
                        className={
                          this.state.typeOfPayment === Constants.payment_method.invoice
                            ? 'fas fa-check-circle'
                            : 'far fa-circle'
                        }
                      />
                      <span>Request an invoice and pay later</span>
                    </div>
                  )}
                  {this.state.typeOfPayment !== Constants.payment_method.invoice && (
                    <div className="row">
                      <TextField
                        required={true}
                        label="Name on Card"
                        value={this.state.cardName}
                        name="cardName"
                        type="text"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                  )}
                  {this.state.typeOfPayment !== Constants.payment_method.invoice && (
                    <div className="row">
                      {this.state.stripePromise !== null && (
                        <Elements stripe={this.state.stripePromise}>
                          <ElementsConsumer>
                            {({ elements, stripe }) => (
                              <CreditCard
                                billingDetails={{
                                  email: this.state.email,
                                  name: this.state.firstName + ' ' + this.state.lastName,
                                  phone: this.state.phone,
                                }}
                                elements={elements}
                                stripe={stripe}
                                resetPaymentMethodTry={() => {
                                  this.setState({ tryCreatePaymentMethod: false });
                                }}
                                tryCreatePaymentMethod={this.state.tryCreatePaymentMethod}
                                onSuccessfulPaymentMethod={this.onSuccessfulPaymentMethod}
                                onFailedPaymentMethod={this.onFailedPaymentMethod}
                                trySubmitPayment={this.state.trySubmitPayment}
                                resetSubmitPayment={() => {
                                  this.setState({ trySubmitPayment: false });
                                }}
                                onFailedSubmitPayment={this.onFailedSubmitPayment}
                                onSuccessfulSubmitPayment={this.onSuccessfulSubmitPayment}
                                onSubmitSubscription={() => {}}
                                options={{ hidePostalCode: true }}
                                ownerType={this.props.owner.ownerType}
                                {...this.props}
                                type="auction"
                              />
                            )}
                          </ElementsConsumer>
                        </Elements>
                      )}
                    </div>
                  )}
                </>
              )}
              <div className="bill">
                <div className="form-title">Billing Address</div>
                {this.state.shipOption === 'ship' && this.props.item.is_shipping_available && (
                  <div
                    onClick={() => {
                      this.setState({ sameAsShipping: !this.state.sameAsShipping });
                    }}
                    className="sub-form-title click"
                  >
                    <i
                      className={
                        this.state.sameAsShipping ? 'fas fa-check-circle' : 'far fa-circle'
                      }
                    ></i>
                    <span>Same as Shipping Address</span>
                  </div>
                )}
                {!this.state.sameAsShipping && (
                  <div className="bill-inner">
                    <div className="row dbl">
                      <TextField
                        required={true}
                        label="Street Address"
                        value={this.state.billStreetAddress}
                        name="billStreetAddress"
                        type="text"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        label="Apartment / Suite #"
                        value={this.state.billAptNum}
                        name="billAptNum"
                        type="text"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="row dbl">
                      <TextField
                        required={true}
                        label="City"
                        value={this.state.billCity}
                        name="billCity"
                        type="text"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        required={true}
                        label="Province"
                        value={this.state.billProvince}
                        name="billProvince"
                        type="text"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="row dbl">
                      <TextField
                        required={true}
                        label="Country"
                        value={this.state.billCountry}
                        name="billCountry"
                        type="text"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        required={true}
                        label="Postal Code"
                        value={this.state.billPostal}
                        name="billPostal"
                        type="text"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="summary">
              <div className="summary-inner">
                {!this.state.buyNowSummaryLoading && this.state.buyNowSummary && (
                  <div className="summary-wrap">
                    <div className="title">Summary</div>
                    {!this.props.isManualPayment && (
                      <React.Fragment>
                        <div className="sub-price">
                          <span>Final Price</span>
                          <var data-var="buy_now_price">
                            {formatCurrency(this.getBuyNowPrice(), currency, this.props.locale)}
                          </var>
                        </div>
                        <div className="sub-price">
                          <span>Shipping</span>
                          <span>
                            {formatCurrency(
                              this.state.buyNowSummary?.shipping || 0,
                              currency,
                              this.props.locale,
                            )}
                          </span>
                        </div>
                      </React.Fragment>
                    )}

                    {!this.state.feeControl?.covers_processing_fees &&
                      !this.props.isManualPayment &&
                      this.state.typeOfPayment !== Constants.payment_method.invoice && (
                        <div className="sub-price">
                          <span>Processing Fee</span>
                          <span>
                            {formatCurrency(
                              this.state.buyNowSummary?.payment_platform_fee || 0,
                              currency,
                              this.props.locale,
                            )}
                          </span>
                        </div>
                      )}
                    {!this.state.feeControl?.covers_gigit_fees &&
                      !this.props.isManualPayment &&
                      this.state.typeOfPayment !== Constants.payment_method.invoice && (
                        <div className="sub-price">
                          <span>Platform Fee</span>
                          <span>
                            {formatCurrency(
                              this.state.buyNowSummary?.gigit_fee || 0,
                              currency,
                              this.props.locale,
                            )}
                          </span>
                        </div>
                      )}
                    <div className="total">
                      <span>Total</span>
                      <var data-var="total_price">
                        {formatCurrency(this.getTotalPrice(), currency, this.props.locale)}
                      </var>
                    </div>
                    <div className="actions">
                      <Button
                        type="submit"
                        loading={this.state.paymentLoading}
                        text="Purchase"
                      />
                      <Button
                        text="Cancel"
                        onClick={this.props.onClose}
                      />
                    </div>
                  </div>
                )}
                {this.state.buyNowSummaryLoading && (
                  <Loader loading={this.state.buyNowSummaryLoading} />
                )}
              </div>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    groupState: store.groupState,
    userState: store.userState,
    eventState: store.eventState,
    auctionState: store.auctionState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  resetBuyNowData,
  createBuyNowPaymentIntent,
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuctionItemBuyNowModal);
