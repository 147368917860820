import React from 'react';

import './PaymentMethodOption.scss';
import { uiConstants } from '../../constants/uiConstants';

import GPayLogo from './../../assets/g_pay_logo.png';
import { ReactComponent as APayLogo } from './../../assets/a_pay_logo.svg';
import { combineClassNames } from '../../helpers';

//This type supports "card", "applyPay", and "googlePay"
export type PaymentMethodItemType = keyof typeof uiConstants.altPaymentMethods | 'card';
interface IProps {
  item: PaymentMethodItemType;
  index: number;

  /** -1 means normal credit card. */
  selectedAltMethod: number;
  setSelectedAltMethod(index: number): void;
}

/** Renders a payment method option. Allowing the user to choose which payment method to use.
 */
export const PaymentMethodOption: React.FC<IProps> = (props) => {
  const logo =
    props.item === uiConstants.altPaymentMethods.googlePay ? (
      <img src={GPayLogo} />
    ) : props.item === uiConstants.altPaymentMethods.applePay ? (
      <APayLogo />
    ) : (
      <i className="fab fa-cc-visa credit-card" />
    );

  const name =
    props.item === uiConstants.altPaymentMethods.googlePay
      ? 'Google Pay'
      : props.item === uiConstants.altPaymentMethods.applePay
        ? 'Apple Pay'
        : 'Credit Card';

  const isExpanded = props.index === -1 && props.selectedAltMethod === props.index;
  const isAfterExpanded = props.selectedAltMethod === -1 && props.index === 0;

  return (
    <div
      onClick={() => props.setSelectedAltMethod(props.index)}
      className={combineClassNames(
        'PaymentMethodOption',
        isExpanded ? 'expanded' : '',
        isAfterExpanded ? 'after-expanded' : '',
      )}
    >
      {logo}
      {/*
                Following the brand guide lines the methods should not be translated. 
                https://developers.google.com/pay/api/web/guides/brand-guidelines | https://developer.apple.com/design/human-interface-guidelines/apple-pay/overview/introduction/
            */}
      <span notranslate="yes">{name}</span>
      <i className={props.selectedAltMethod === props.index ? 'fas fa-circle' : 'far fa-circle'} />
    </div>
  );
};
