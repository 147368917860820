import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IAppState } from '../../store';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  handleInputChange,
  capitalizeString,
  toastSuccess,
  routes,
  swapRouteParams,
  toastError,
  errorHelpers,
  typeHelpers,
} from '../../helpers';
import { IOptions } from '../../components/Dropdown/Dropdown';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import Modal from '../../components/Modal/Modal';
import { IGroupState } from '../../reducers/group';
import { getGroupApplications, addVolunteer, getGroup } from '../../actions/group';
import { createToast } from '../../actions/toaster';
import Portrait from '../Portrait/Portrait';
import DatePicker from '../DatePicker/DatePicker';
import moment from 'moment';
import Dropdown from '../Dropdown/Dropdown';
import CoverImage from '../CoverImage/CoverImage';
import FormSteps from '../FormSteps/FormSteps';
import Checkbox from '../Checkbox/Checkbox';
import './VolunteerManagement.scss';
import {
  IGigVolunteerSummary,
  ILoggedVolunteerHoursCreateParamsFE,
  IUser,
  IVolunteer,
} from '@gigit/interfaces';
import ContactDetails from '../ContactDetails/ContactDetails';
import { IActiveFilter, IOwnerObject, ISortSettings, ReduxActionType } from '../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import { IGigState } from '../../reducers/gig';
import { Constants } from '@gigit/constants';
import axios from 'axios';
import {
  gigRequestActions,
  groupRequestActions,
  userRequestActions,
  volunteerRequestActions,
} from '../../requestActions';
import { downloadFile, formatNumberCompactNotation } from '../../helpers/helpers';
import { uiConstants } from '../../constants/uiConstants';
import VolunteerApplicationModal from '../VolunteerApplicationModal/VolunteerApplicationModal';
import Table, { ITableProps } from '../shared/Table/Table';
import { ITableAction } from '../shared/Table/tableState';
import ModalFooterActionContainer from '../Modal/ModalFooterActions/ModalFooterActionContainer';
import ModalScrollContainer from '../Modal/ModalScrollContainer/ModalScrollContainer';
import DateDayPicker from '../shared/DateDayPicker/DateDayPicker';
import DateTimePicker from '../shared/DateTimePicker/DateTimePicker';

enum ELogHoursModalView {
  LOG_HOURS,
  CONFIRM,
}

interface IUserModalData {
  first_name?: string;
  last_name?: string;
  handle?: string;
  profile_image_url?: string;
  id: string;
}

interface ILogedHoursModalData {
  users: IUserModalData[];
  date: Date;
  hours: string;
  minutes?: string;
  view: ELogHoursModalView;
}

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  gigState: IGigState;
  owner: IOwnerObject;
  locale: string;
  getGroup: ReduxActionType<typeof getGroup>;
  getGroupApplications: ReduxActionType<typeof getGroupApplications>;
  addVolunteer: ReduxActionType<typeof addVolunteer>;
  createToast: ReduxActionType<typeof createToast>;
  changeAdminTab(tab: string): void;
}

interface IState {
  checkInUsers: IUserModalData[] | null;
  logHoursData: ILogedHoursModalData | null;
  refreshTableIncrementor: number;
  searchValue: string;
  showAdd: boolean;
  firstName: string;
  lastName: string;
  handle: string;
  email: string;
  phone: string;
  genders: Array<{ label: string; value: string }>;
  gender: string;
  address: string;
  apartment: string;
  city: string;
  province: string;
  dob: moment.Moment;
  country: string;
  postal: string;
  showApplications: boolean;
  currentUser: IUser | null;
  addVolunteerStep: number;
  responses: any[];
  sort: ISortSettings[];
  showFilterModal: boolean;
  filters: IActiveFilter[];
  showContactDetailsModal: boolean;
  selectedUser: IUser | null;
  volunteers: IVolunteer[];
}

class VolunteerManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      logHoursData: null,
      checkInUsers: null,
      refreshTableIncrementor: 0,
      searchValue: '',
      showAdd: false,
      firstName: '',
      lastName: '',
      handle: '',
      email: '',
      phone: '',
      genders: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Other', value: 'other' },
      ],
      gender: 'male',
      address: '',
      apartment: '',
      city: '',
      province: '',
      dob: moment(),
      country: '',
      postal: '',
      showApplications: false,
      currentUser: null,
      addVolunteerStep: 0,
      responses: [],
      sort: [],
      showFilterModal: false,
      filters: [],
      selectedUser: null,
      showContactDetailsModal: false,
      volunteers: [],
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleViewApplications = this.toggleViewApplications.bind(this);
    this.updateDOB = this.updateDOB.bind(this);
    this.customFormOnClose = this.customFormOnClose.bind(this);
    this.resetFormQuestions = this.resetFormQuestions.bind(this);
    this.handleConfirmUsers = this.handleConfirmUsers.bind(this);
    this.handleConfirmLogHours = this.handleConfirmLogHours.bind(this);
  }

  componentDidMount() {
    this.resetFormQuestions();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.groupState.groupVolunteers.length ===
      prevProps.groupState.groupVolunteers.length + 1
    ) {
      const toast = toastSuccess(
        localizeHelpers.translate('Volunteer added successfully.'),
        'Add Volunteer',
      );
      this.props.createToast(toast);
    }
  }

  renderTableItemDetail(item: IGigVolunteerSummary | IVolunteer) {
    return (
      <div className="row-details">
        <div className="third">
          <CoverImage
            currentImage={item?.user?.cover_image_url}
            height={160}
          />
          <div className="inner-user-details">
            <Link to={'/user/' + item.user?.handle}>
              <Portrait
                currentImage={item.user?.profile_image_url}
                size={100}
              />
            </Link>
            <div
              className="user-name"
              notranslate="yes"
            >
              <span
                className="volunteer-name"
                notranslate="yes"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({
                    showContactDetailsModal: true,
                    selectedUser: (item?.user as IUser) || null,
                  });
                }}
              >
                {item.user?.display_name}
              </span>
            </div>
            {!(item?.user as IUser)?.is_dummy_user && (
              <Link
                className="user-handle"
                to={'/user/' + item.user?.handle}
                notranslate="yes"
              >
                @{item.user?.handle}
              </Link>
            )}
            <div className="user-actions">
              {!(item.user as IUser)?.is_dummy_user && (
                <div className="sub-actions">
                  <Button
                    onClick={(e) => {
                      this.openEmail(item.user?.email!);
                      e.stopPropagation();
                    }}
                    icon="far fa-envelope"
                    text={'Email'}
                  />
                </div>
              )}
              <div className="main-action">
                <Button
                  icon="fa fa-list"
                  onClick={() => {
                    this.viewApplication(item.user as IUser);
                  }}
                  text="View Application"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="third">
          <div className="title">Details</div>
          <div className="sub-title">Contact</div>
          <div
            className="email"
            notranslate="yes"
          >
            {!(item.user as IUser)?.is_dummy_user
              ? item.user?.email
              : localizeHelpers.translate('No Email Provided')}
          </div>
          <div
            className="phone"
            notranslate="yes"
          >
            {item.user?.phone}
          </div>
          {(item.user as IUser)?.age_privacy_agreement === false && (
            <div className="age-check">
              <i className="fas fa-exclamation-circle" />
              <span>This volunteer is under the age of 18</span>
            </div>
          )}
          <div className="sub-title">Address</div>
          {(item as IVolunteer)?.location && (item as IVolunteer)?.location?.city !== undefined && (
            <div
              className="address"
              notranslate="yes"
            >
              <div>{(item as IVolunteer)?.location?.line1}</div>
              <div>{(item as IVolunteer)?.location?.line2}</div>
              <div>
                {(item as IVolunteer)?.location?.city}, {(item as IVolunteer)?.location?.state}{' '}
                {(item as IVolunteer)?.location?.postal_code}
              </div>
              <div>{(item as IVolunteer)?.location?.country}</div>
            </div>
          )}
        </div>
        <div className="third metrics">
          <div className="metric">
            <div className="metric-inner">
              <span notranslate="yes">
                {localizeHelpers.formatNumber(item.approved_hours || 0, this.props.locale)}
              </span>
              {this.props.owner.parentOwnerType !== uiConstants.ownerType.event ? (
                <div>Total Approved Volunteer Hours</div>
              ) : (
                <div>Approved Volunteer Hours</div>
              )}
            </div>
          </div>
          <div className="metric">
            <div className="metric-inner">
              <span notranslate="yes">{localizeHelpers.formatNumber(0, this.props.locale)}</span>
              {this.props.owner.parentOwnerType !== uiConstants.ownerType.event ? (
                <div>Total Volunteer Opportunities Completed</div>
              ) : (
                <div>Volunteer Opportunities Completed</div>
              )}
            </div>
          </div>
          <div className="metric">
            <div className="metric-inner">
              <span notranslate="yes">
                {this.returnMonthDay(String((item.user as IUser)?.created_at))}
              </span>
              <span notranslate="yes">
                {this.returnYear(String((item.user as IUser)?.created_at))}
              </span>
              <div>Cause Application</div>
            </div>
          </div>
          <div className="metric">
            {(item as IVolunteer)?.status && (
              <div className="metric-inner">
                <span notranslate="yes">
                  {this.returnMonthDay(String((item as IVolunteer)?.status!.status_date))}
                </span>
                <span notranslate="yes">
                  {this.returnYear(String((item as IVolunteer)?.status!.status_date))}
                </span>
                <div>{`${capitalizeString((item as IVolunteer)?.status.code)} as volunteer`}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  getCompanyGigStatus(data: IGigVolunteerSummary) {
    if (data.is_checked_in) {
      return <span className="company-status checked">Checked In</span>;
    }

    if (data.is_confirmed) {
      return <span className="company-status confirmed">Confirmed</span>;
    }

    if (data.is_interested !== undefined && data.is_interested !== null) {
      if (data.is_interested) {
        return <span className="company-status interested">Interested</span>;
      }

      return <span className="company-status not_interested">Not Interested</span>;
    }

    return <span className="company-status unknown">Unknown</span>;
  }

  async getVolunteers(params: URLSearchParams) {
    let data: Array<IVolunteer | IGigVolunteerSummary> = [];
    let route;

    if (!!this.props.gigState.gig?.hub_id) {
      route = swapRouteParams(routes.GET_COMPANY_GIG_VOLUNTEERS, {
        gigId: this.props.gigState.gig.id,
      });
    } else if (this.props.owner.ownerType === Constants.object_type.group) {
      route = swapRouteParams(routes.GET_GROUP_VOLUNTEERS, {
        groupId: this.props.groupState.group.id,
      });
    } else if (this.props.owner.ownerType === Constants.object_type.gig) {
      route = swapRouteParams(routes.GET_GIG_VOLUNTEERS, { gigId: this.props.gigState.gig.id });
    }

    if (route) {
      try {
        const response = await axios.get(route, { params });
        data = response.data;
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Get Volunteers');
        this.props.createToast(toast);
      }
    }

    return data;
  }

  exportVolunteers() {
    const owner = this.props.owner;
    const groupId = typeHelpers.getGroupFromOwner(owner)?.id;
    const eventId = typeHelpers.getEventFromOwner(owner)?.id;

    if (
      owner.ownerType === Constants.object_type.group ||
      (owner.ownerType === Constants.object_type.gig && !eventId)
    ) {
      groupId &&
        groupRequestActions
          .getVolunteersExport(groupId)
          .then((response) => {
            downloadFile(`Volunteers_for_${owner.ownerHandle}.csv`, response);
          })
          .catch((error) => {
            const errorObj = errorHelpers.getErrorObject(error);
            let toast = toastError(errorObj.translatedMessage, 'Cause Volunteers Export');
            this.props.createToast(toast);
          });
    }

    if (owner.ownerType === Constants.object_type.gig && eventId) {
      gigRequestActions
        .getVolunteersExport(owner.ownerId)
        .then((response) => {
          downloadFile(`Volunteers_for_${owner.ownerHandle}.csv`, response);
        })
        .catch((error) => {
          const errorObj = errorHelpers.getErrorObject(error);
          let toast = toastError(errorObj.translatedMessage, 'Opportunity Volunteers Export');
          this.props.createToast(toast);
        });
    }
  }

  resetFormQuestions() {
    if (this.props.groupState.currentGroupForm) {
      let _responses = [];

      for (let i = 0; i < this.props.groupState.currentGroupForm.questions.length; i++) {
        if (
          this.props.groupState.currentGroupForm.questions[i].question_type === 'dropdown' &&
          this.props.groupState.currentGroupForm.questions[i].options!.length > 0
        ) {
          _responses.push(
            this.props.groupState.currentGroupForm.questions[i].options![0].value || '',
          );
        } else if (
          this.props.groupState.currentGroupForm.questions[i].question_type === 'checkbox' &&
          this.props.groupState.currentGroupForm.questions[i].options!.length > 0
        ) {
          _responses.push([]);
        } else {
          _responses.push('');
        }
      }

      this.setState({
        responses: _responses,
      });
    }
  }

  updateDOB(dob: moment.Moment) {
    this.setState({ dob });
  }

  toggleModal(value: boolean) {
    if (value) {
      this.resetFormQuestions();

      this.setState({
        firstName: '',
        lastName: '',
        handle: '',
        email: '',
        phone: '',
        gender: 'male',
        address: '',
        apartment: '',
        city: '',
        province: '',
        dob: moment(),
        country: '',
        postal: '',
      });
    }

    this.setState({
      showAdd: value,
      addVolunteerStep: 0,
    });
  }

  toggleViewApplications(value: boolean) {
    this.setState({
      showApplications: value,
    });
  }

  returnDOB(date: string) {
    return localizeHelpers.formatDate(date, LocaleDateFormats.LL, this.props.locale);
  }

  returnMonthDay(date: string) {
    return localizeHelpers.formatDate(date, LocaleDateFormats.XLL, this.props.locale);
  }

  returnYear(date: string) {
    return localizeHelpers.formatDate(date, LocaleDateFormats.YYYY, this.props.locale);
  }

  addVolunteer() {
    if (
      (this.props.groupState.currentGroupForm &&
        this.props.groupState.currentGroupForm.questions.length === 0) ||
      this.state.addVolunteerStep === 1
    ) {
      let _payload: any = {
        user: {
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          email: this.state.email,
          phone: this.state.phone,
          gender: this.state.gender,
          dob: this.state.dob,
        },
        location: {
          line1: this.state.address,
          line2: `${this.state.apartment}`,
          city: `${this.state.city}`,
          postal_code: this.state.postal,
          country: this.state.country,
          state: this.state.province,
        },
      };

      if (
        this.props.groupState.currentGroupForm &&
        this.props.groupState.currentGroupForm.questions.length > 0
      ) {
        let _application = [];

        for (var q in this.props.groupState.currentGroupForm.questions) {
          let _question = this.props.groupState.currentGroupForm.questions[q];

          _application.push({
            form_id: this.props.groupState.currentGroupForm.id,
            question_id: _question.id,
            question_type: _question.question_type,
            question_label: _question.label,
            answer: this.state.responses[q],
          });
        }

        _payload.application_forms = _application;
      }

      const entityId =
        this.props.owner.ownerType === uiConstants.ownerType.group
          ? this.props.owner.ownerId
          : typeHelpers.getGroupIdFromOwner(this.props.owner);
      this.props.addVolunteer(entityId ?? '', _payload, {
        callback: () =>
          this.setState({ refreshTableIncrementor: this.state.refreshTableIncrementor + 1 }),
      });
      this.setState({ addVolunteerStep: 2 });
      this.toggleModal(false);
    } else {
      this.setState({ addVolunteerStep: 1 });
    }
  }

  viewApplication(user: IUser) {
    this.setState(
      {
        currentUser: user,
      },
      () => {
        this.toggleViewApplications(true);
      },
    );
  }

  customFormOnClose() {
    this.toggleViewApplications(false);
  }

  openEmail(email: string) {
    window.location.href = 'mailto:' + email;
  }

  async handleConfirmLogHours() {
    try {
      typeHelpers.assertNotNullOrUndefined(
        this.props.gigState.gig?.id,
        'Expected Volunteer Opportunity ID',
      );
      typeHelpers.assertNotNullOrUndefined(this.state.logHoursData, 'Expected Selected Users');
      const basePayload = {
        gig_id: this.props.gigState.gig.id,
        group_id: this.props.gigState.gig.group_id,
        event_id: this.props.gigState.gig.event_id,
        hub_id: this.props.gigState.gig.hub_id,
        start_date: this.state.logHoursData.date.toString(),
        hours: Number(this.state.logHoursData?.hours),
        minutes: Number(this.state.logHoursData?.minutes),
      };
      let payload: ILoggedVolunteerHoursCreateParamsFE[] = this.state.logHoursData.users.map(
        (user) => ({
          ...basePayload,
          user_id: user.id,
        }),
      );
      await userRequestActions.logVolunteeringHoursSelectedBulk(
        this.props.gigState.gig.id,
        payload,
      );
      this.setState({
        logHoursData: null,
        refreshTableIncrementor: this.state.refreshTableIncrementor + 1,
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Log Hours for Users');
      this.props.createToast(toast);
    }
  }

  async handleConfirmUsers() {
    try {
      typeHelpers.assertNotNullOrUndefined(
        this.props.gigState.gig?.id,
        'Expected Volunteer Opportunity ID',
      );
      typeHelpers.assertNotNullOrUndefined(this.state.checkInUsers, 'Expected Selected Users');
      await volunteerRequestActions.setVolunteerFlagBulk(
        this.props.gigState.gig.id,
        this.state.checkInUsers.map((user) => ({
          user_id: user.id,
          flag: 'is_checked_in',
          flag_value: true,
        })),
      );
      this.setState({
        checkInUsers: null,
        refreshTableIncrementor: this.state.refreshTableIncrementor + 1,
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Check In Users');
      this.props.createToast(toast);
    }
  }

  handleResponseChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
  ) {
    const target = event.target;
    const value = target.value;
    let _responses = [...this.state.responses];

    if (target.type === 'checkbox') {
      if (!this.state.responses[index].includes(value)) {
        _responses[index] = [..._responses[index], value];
      } else {
        let r = _responses[index];
        let idx = r.findIndex((i: any) => {
          return i === value;
        });
        r.splice(idx, 1);
        _responses[index] = r;
      }
    } else {
      _responses[index] = value;
    }

    this.setState({
      responses: _responses,
    });
  }

  handleMinutesChange(event: React.ChangeEvent<HTMLInputElement>) {
    const minutes = event.target.value;

    if (parseInt(minutes) < 0 || parseInt(minutes) > 59) return;

    this.setState({
      logHoursData: {
        ...this.state.logHoursData!,
        minutes: minutes,
      },
    });
  }

  handleHoursChange(event: React.ChangeEvent<HTMLInputElement>) {
    const hours = event.target.value;
    if (parseInt(hours) < 0 || parseInt(hours) > 24) return;

    this.setState(
      {
        logHoursData: {
          ...this.state.logHoursData!,
          hours,
        },
      },
      () => {
        if (parseInt(hours) === 24)
          this.setState({ logHoursData: { ...this.state.logHoursData!, minutes: '' } });
      },
    );
  }

  render() {
    const isEvent = this.props.owner.parentOwnerType === Constants.object_type.event;
    const isHub = !!this.props.gigState.gig?.hub_id;
    const tableConfigTableActions: Array<ITableAction<IVolunteer | IGigVolunteerSummary>> = [];
    if (!isHub) {
      tableConfigTableActions.push(
        {
          type: 'ROW_CONTEXT_MENU',
          label: 'View Application',
          icon: 'fas fa-list',
          onClick: (_, item) => {
            this.viewApplication(item.user as IUser);
          },
        },
        {
          icon: 'fas fa-envelope',
          type: 'ROW_CONTEXT_MENU',
          onClick: (_, item) => (window.location.href = 'mailto:' + item.user?.email),
          label: 'Email',
        },
        {
          icon: 'far fa-file-download',
          type: 'HEADER',
          buttonType: 'outline-dark',
          onClick: () => {
            this.exportVolunteers();
          },
          label: 'Export',
        },
      );

      if (!isEvent) {
        tableConfigTableActions.push({
          icon: 'fal fa-plus',
          type: 'HEADER',
          buttonType: 'dark',
          onClick: () => {
            this.toggleModal(true);
          },
          label: 'Add Volunteer',
        });
      }
    }

    if (isHub) {
      tableConfigTableActions.push(
        {
          type: 'HEADER_SELECTED_ROW_ACTION',
          onClick: (_, selectedRows) =>
            this.setState({
              logHoursData: {
                users: selectedRows.map((row) => ({
                  first_name: row.user?.first_name,
                  last_name: row.user?.last_name,
                  handle: row.user?.handle,
                  profile_image_url: row.user?.profile_image_url,
                  id: row.user?.id || row.user_id,
                })),
                hours: '0',
                date: new Date(),
                view: ELogHoursModalView.LOG_HOURS,
              },
            }),
          label: 'Log Hours',
        },
        {
          type: 'HEADER_SELECTED_ROW_ACTION',
          onClick: (_, selectedRows) => {
            this.setState({
              checkInUsers: selectedRows.map((row) => ({
                first_name: row.user?.first_name,
                last_name: row.user?.last_name,
                handle: row.user?.handle,
                profile_image_url: row.user?.profile_image_url,
                id: row.user?.id || row.user_id,
              })),
            });
          },
          label: 'Check In',
        },
        {
          type: 'ROW_CONTEXT_MENU',
          onClick: (_, row) =>
            this.setState({
              logHoursData: {
                users: [
                  {
                    first_name: row.user?.first_name,
                    last_name: row.user?.last_name,
                    handle: row.user?.handle,
                    profile_image_url: row.user?.profile_image_url,
                    id: row.user?.id || row.user_id,
                  },
                ],
                hours: '0',
                date: new Date(),
                view: ELogHoursModalView.LOG_HOURS,
              },
            }),
          label: 'Log Hours',
        },
        {
          type: 'ROW_CONTEXT_MENU',
          onClick: (_, row) =>
            this.setState({
              checkInUsers: [
                {
                  first_name: row.user?.first_name,
                  last_name: row.user?.last_name,
                  handle: row.user?.handle,
                  profile_image_url: row.user?.profile_image_url,
                  id: row.user?.id || row.user_id,
                },
              ],
            }),
          hideIf: (item) => Boolean((item as IGigVolunteerSummary)?.is_checked_in),
          label: 'Check In',
        },
      );
    }
    const tableConfig: ITableProps<IGigVolunteerSummary | IVolunteer> = {
      pagination: {
        pageSizeOptions: [10],
        queryAction: async (params) => await this.getVolunteers(params),
      },
      emptyStateConfig: {
        title: 'No data',
        description: "We couldn't find any volunteers",
      },
      filterOptions: {
        enableColumnSorting: true,
        enableTableSearch: true,
        enableFilterDrawer: true,
        filterDrawerOptions: [
          {
            type: 'textField',
            fieldId: 'user.first_name',
            options: { label: 'User first name' },
          },
          {
            type: 'textField',
            fieldId: 'user.last_name',
            options: { label: 'User last name' },
          },
          {
            type: 'textField',
            fieldId: 'total_hours',
            options: { label: 'Logged Hours' },
          },
        ],
      },
      columns: [
        {
          id: 'user.first_name',
          sortable: true,
          notranslate: 'yes',
          Header: 'Volunteer Name',
          Cell: ({ user }) => (
            <Link
              to={`/user/${user?.handle}`}
              className="user-name"
            >
              <Portrait
                size={30}
                currentImage={user?.profile_image_url}
              />
              <span>
                {user?.first_name} {user?.last_name}
              </span>
            </Link>
          ),
          accessor: ({ user }) => user?.first_name,
        },
        {
          id: 'total_hours',
          sortable: true,
          notranslate: 'yes',
          Header: 'Hours Logged',
          Cell: ({ total_hours }) => (
            <span>{formatNumberCompactNotation(total_hours || 0, this.props.locale)}</span>
          ),
          accessor: ({ total_hours }) => total_hours,
        },
        {
          id: 'application_status.code',
          sortable: true,
          Header: 'Status',
          predefinedColumnType: {
            type: 'STATUS',
            columnObject: () => ({
              [Constants.application_status.pending]: {
                label: 'Pending',
                color: 'YELLOW',
              },
              [Constants.application_status.cancelled]: {
                label: 'Cancelled',
                color: 'RED',
              },
              [Constants.application_status.rejected]: {
                label: 'Rejected',
                color: 'RED',
              },
              [Constants.application_status.archived]: {
                label: 'Archived',
                color: 'GREY',
              },
              [Constants.application_status.approved]: {
                label: 'Approved',
                color: 'GREEN',
              },
            }),
          },
          accessor: (data) => (data as IVolunteer)?.status?.code,
          showColumn: isHub ? false : undefined,
        },
        {
          id: 'is_confirmed',
          showColumn: isHub ? undefined : false,
          sortable: false,
          Header: 'Status',
          Cell: (data) => this.getCompanyGigStatus(data as IGigVolunteerSummary),
          accessor: ({ is_confirmed }) => is_confirmed,
        },
        {
          id: 'updated_at',
          sortable: true,
          notranslate: 'yes',
          Header: 'Last Updated',
          Cell: ({ updated_at }) => (
            <span notranslate="yes">{this.returnDOB(updated_at?.toString() || '')}</span>
          ),
          accessor: ({ updated_at }) => updated_at?.toString(),
        },
      ],
      rowSelectionOptions: {
        // TODO: BE seems that in some cases applies _id instead of id property
        //@ts-ignore
        rowId: (row) => typeHelpers.tryGetAssertNotNullOrUndefinedValue(row?.id || row?._id || ''),
        enableRowSelectionMode: isHub ? 'multi-select' : undefined,
        isRowSelectable: (row) => !!row.id,
      },
      tableActionOptions: {
        enableMultiSelectActions: isHub,
        enableRowButtonActions: true,
        enableRowContextMenuActions: true,
        tableActions: tableConfigTableActions,
        rowDetailDrawerOptions: isHub
          ? undefined
          : (data) => ({
              title: 'Details',
              renderContent: () => this.renderTableItemDetail(data),
            }),
      },
    };

    return (
      <div className="VolunteerManagement section-wrap">
        <div className="section-title">
          <div className="section-inner-title">Volunteers</div>
        </div>
        <div className="section-inner">
          <div className="VolunteerManagement-list">
            <div className="list">
              <div className="list-inner">
                <div className="list-rows">
                  <Table
                    refreshTableIncrementor={this.state.refreshTableIncrementor}
                    {...tableConfig}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal
            class="ContactDetailsModal"
            show={this.state.showContactDetailsModal}
            onClose={() => {
              this.setState({ showContactDetailsModal: false });
            }}
            title="Contact Details"
          >
            <ContactDetails
              userId={this.state.selectedUser?.id!}
              {...this.props}
            />
          </Modal>
          <Modal
            show={this.state.showAdd}
            onClose={() => this.toggleModal(false)}
            title="Add Volunteer"
          >
            <div className="add-volunteer">
              {this.props.groupState.currentGroupForm &&
                this.props.groupState.currentGroupForm.questions.length > 0 && (
                  <FormSteps
                    current={this.state.addVolunteerStep}
                    steps={['Basic Information', 'Form Information', 'Finish']}
                  />
                )}
              {((this.props.groupState.currentGroupForm &&
                this.props.groupState.currentGroupForm.questions.length === 0) ||
                this.state.addVolunteerStep === 0) && (
                <div className="step one">
                  <div className="subheader">Basic Information</div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.addVolunteer();
                    }}
                  >
                    <div className="form-1-1">
                      <TextField
                        required={true}
                        label="First Name"
                        value={this.state.firstName}
                        type="text"
                        name="firstName"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        required={true}
                        label="Last Name"
                        value={this.state.lastName}
                        type="text"
                        name="lastName"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="form-1-1">
                      <TextField
                        label="Email"
                        value={this.state.email}
                        type="text"
                        name="email"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        label="Phone"
                        value={this.state.phone}
                        type="text"
                        name="phone"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div>
                      <DatePicker
                        maxYear={moment().year().toString()}
                        name="dob"
                        label="Date of Birth"
                        date={this.state.dob}
                        onChange={this.updateDOB}
                      />
                    </div>
                    <div>
                      <Dropdown
                        name="gender"
                        label="Gender"
                        shouldSort={true}
                        value={this.state.gender}
                        options={this.state.genders}
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="form-1-1">
                      <TextField
                        label="Street Address"
                        value={this.state.address}
                        type="text"
                        name="address"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        label="Apartment / Suite"
                        value={this.state.apartment}
                        type="text"
                        name="apartment"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="form-1-1">
                      <TextField
                        label="City"
                        value={this.state.city}
                        type="text"
                        name="city"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        label="Province"
                        value={this.state.province}
                        type="text"
                        name="province"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="form-1-1">
                      <TextField
                        label="Country"
                        value={this.state.country}
                        type="text"
                        name="country"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                      <TextField
                        label="Postal"
                        value={this.state.postal}
                        type="text"
                        name="postal"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                    <div className="actions">
                      {this.props.groupState.currentGroupForm &&
                        this.props.groupState.currentGroupForm.questions.length > 0 &&
                        this.state.addVolunteerStep === 0 && <Button text="Next" />}
                      {((this.props.groupState.currentGroupForm &&
                        this.props.groupState.currentGroupForm.questions.length === 0) ||
                        this.state.addVolunteerStep === 1) && (
                        <Button
                          type="submit"
                          icon="fas fa-plus-circle"
                          text="Add"
                        />
                      )}
                    </div>
                  </form>
                </div>
              )}
              {this.state.addVolunteerStep === 1 && (
                <div className="step two">
                  <div className="subheader">Form Information</div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.addVolunteer();
                    }}
                  >
                    {this.state.responses &&
                      this.state.responses.length ===
                        this.props.groupState.currentGroupForm.questions.length &&
                      this.props.groupState.currentGroupForm.questions.map((item, index) => {
                        switch (item.question_type) {
                          case 'text':
                            return (
                              <div
                                className="question"
                                key={index}
                              >
                                <div className="label">{item.label}</div>
                                <TextField
                                  required={true}
                                  value={this.state.responses[index]}
                                  name=""
                                  type="text"
                                  onChange={(e) => {
                                    this.handleResponseChange(e, index);
                                  }}
                                />
                              </div>
                            );
                          case 'dropdown':
                            let _options: IOptions[] = [];

                            for (let i in item.options) {
                              //@ts-ignore
                              _options.push({
                                label: item.options[i as any].label,
                                value: item.options[i as any].label,
                              });
                            }

                            return (
                              <div
                                className="question"
                                key={index}
                              >
                                <div className="label">{item.label}</div>
                                <Dropdown
                                  name={item.label}
                                  shouldSort={true}
                                  value={this.state.responses[index]}
                                  onChange={(e) => {
                                    this.handleResponseChange(e, index);
                                  }}
                                  options={_options}
                                />
                              </div>
                            );
                          default:
                            return (
                              <div
                                className="question"
                                key={index}
                              >
                                <div className="label">{item.label}</div>
                                {item.options &&
                                  item.options.map((option, optionIndex) => {
                                    return (
                                      <Checkbox
                                        key={optionIndex}
                                        name={option.label}
                                        onChange={(e) => {
                                          this.handleResponseChange(e, index);
                                        }}
                                        checked={this.state.responses[index].includes(option.label)}
                                        value={option.label}
                                        color="work"
                                        label={option.label}
                                      />
                                    );
                                  })}
                              </div>
                            );
                        }
                      })}
                    <div className="actions">
                      <Button
                        icon="fas fa-plus-circle"
                        text="Add"
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
          </Modal>
          {this.state.currentUser && this.state.showApplications && (
            <VolunteerApplicationModal
              closeModal={() => {
                this.toggleViewApplications(false);
                this.setState({ refreshTableIncrementor: this.state.refreshTableIncrementor + 1 });
              }}
              owner={this.props.owner}
              user={this.state.currentUser}
              show={this.state.showApplications}
            />
          )}
        </div>

        <Modal
          show={!!this.state.checkInUsers}
          onClose={() => this.setState({ checkInUsers: null })}
          title="Confirm Check In"
          closeIcon="fas fa-times"
        >
          <ModalScrollContainer className="VolunteerManagementChekInsModal">
            <p>
              You are about to mark{' '}
              <b>
                <var data-var="volunteers_length">{`${this.state.checkInUsers?.length} `}</var>
              </b>
              <span>{this.state?.checkInUsers?.length! > 1 ? 'Volunteers ' : 'Volunteer '} </span>
              as Checked In to your Volunteer Opportunity{' '}
              <b>
                <var data-var="gig_title">{this.props.gigState.gig.title}</var>
              </b>
              . Do you wish to continue?
            </p>
            <span className="title">Volunteers:</span>
            <div className="list">
              {this.state?.checkInUsers?.map((user, index: number) => (
                <div
                  key={index}
                  className="item"
                >
                  <Portrait
                    currentImage={user.profile_image_url}
                    size={30}
                  />
                  <Link to={`/user/${user.handle}`}>
                    <var data-var="user_name">{`${user.first_name} ${user.last_name}`}</var>
                  </Link>
                </div>
              ))}
            </div>
          </ModalScrollContainer>

          <ModalFooterActionContainer>
            <Button
              text="Cancel"
              onClick={() => this.setState({ checkInUsers: null })}
              buttonType="outline-dark"
              type="button"
            />
            <Button
              text="Confirm"
              onClick={this.handleConfirmUsers}
              buttonType="dark"
              type="button"
            />
          </ModalFooterActionContainer>
        </Modal>

        <Modal
          show={!!this.state.logHoursData}
          onClose={() => this.setState({ logHoursData: null })}
          title={
            this.state.logHoursData?.view === ELogHoursModalView.LOG_HOURS
              ? 'Log Hours'
              : 'Confirm Log Hours'
          }
          closeIcon="fas fa-times"
        >
          {this.state.logHoursData?.view === ELogHoursModalView.LOG_HOURS && (
            <>
              <ModalScrollContainer className="VolunteerManagementLogHoursModal">
                <div className="tab-container">
                  <div className="tab-item">
                    <span>Log Hours</span>
                  </div>
                </div>
                <h3>Track Volunteer Contributions</h3>
                <div className="fields-container">
                  <label>
                    Date
                    <DateTimePicker
                      shouldValidate={false}
                      inputProps={{
                        name: 'date',
                        placeholder: 'mm/dd/yyyy',
                        readOnly: true,
                      }}
                      timeFormat={false}
                      dateFormat="MM-DD-YYYY"
                      value={this.state.logHoursData.date}
                      onChange={(value) => {
                        this.setState({
                          logHoursData: {
                            ...this.state.logHoursData!,
                            date: moment(value).toDate(),
                          },
                        });
                      }}
                      locale={this.props.locale}
                      className="dPicker"
                    />
                  </label>
                  <TextField
                    type="number"
                    name="hours"
                    label="Hours"
                    value={this.state.logHoursData.hours.toString()}
                    onChange={(e) => this.handleHoursChange(e)}
                  />
                  <TextField
                    type="number"
                    name="minutes"
                    label="Minutes"
                    min="0"
                    max="59"
                    value={(this.state.logHoursData.minutes || '').toString()}
                    onChange={(e) => this.handleMinutesChange(e)}
                  />
                </div>
              </ModalScrollContainer>

              <ModalFooterActionContainer>
                <Button
                  text="Cancel"
                  onClick={() => this.setState({ logHoursData: null })}
                  buttonType="outline-dark"
                  type="button"
                />
                <Button
                  text="Log Hours"
                  onClick={() =>
                    this.setState({
                      logHoursData: {
                        ...this.state.logHoursData!,
                        view: ELogHoursModalView.CONFIRM,
                      },
                    })
                  }
                  buttonType="dark"
                  type="button"
                  isDisabled={+this.state.logHoursData.hours < 1}
                />
              </ModalFooterActionContainer>
            </>
          )}
          {this.state.logHoursData?.view === ELogHoursModalView.CONFIRM && (
            <>
              <ModalScrollContainer className="VolunteerManagementLogHoursModal">
                <p>
                  You are about to log{' '}
                  <b>
                    <var data-var="volunteers_hours">{`${+this.state.logHoursData.hours * this.state.logHoursData.users.length} `}</var>
                  </b>
                  hours for{' '}
                  <span>
                    {this.state.logHoursData.users.length! > 1 ? 'Employees ' : 'Employee '}{' '}
                  </span>
                  to your Volunteer Opportunity{' '}
                  <b>
                    <var data-var="gig_title">{this.props.gigState.gig.title}</var>
                  </b>
                  . Do you wish to continue?
                </p>
                <span className="title">Volunteers:</span>
                <div className="list">
                  {this.state?.logHoursData.users.map((user, index: number) => (
                    <div
                      key={index}
                      className="item"
                    >
                      <Portrait
                        currentImage={user.profile_image_url}
                        size={30}
                      />
                      <Link to={`/user/${user.handle}`}>
                        <var data-var="user_name">{`${user.first_name} ${user.last_name}`}</var>
                      </Link>
                    </div>
                  ))}
                </div>
              </ModalScrollContainer>

              <ModalFooterActionContainer>
                <Button
                  text="Back"
                  onClick={() =>
                    this.setState({
                      logHoursData: {
                        ...this.state.logHoursData!,
                        view: ELogHoursModalView.LOG_HOURS,
                      },
                    })
                  }
                  buttonType="outline-dark"
                  type="button"
                />
                <Button
                  text="Confirm"
                  onClick={this.handleConfirmLogHours}
                  buttonType="dark"
                  type="button"
                />
              </ModalFooterActionContainer>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    gigState: store.gigState,
    settingsState: store.settingsState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  getGroupApplications,
  addVolunteer,
  getGroup,
  createToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VolunteerManagement));
