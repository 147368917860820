import './HubStore.scss';
import React, { useEffect, useState } from 'react';
import HubFilter from '../Shared/HubFilterBar/HubFilterBar';
import { IPopupMenuItem, IShowPopupConfig } from '../../shared/PopupMenu/PopupMenu';
import { uiConstants } from '../../../constants';
import { IActiveFilter, ISortSettings } from '../../../interfaces';
import { hubRequestActions } from '../../../requestActions';
import { IHub, IStoreItem } from '@gigit/interfaces';
import useToastDispatcher from '../../../hooks/useToaster';
import Loader from '../../Loader/Loader';
import Button from '../../Button/Button';
import { formatQuery } from '../../../helpers';
import HubToggleButtons from '../Shared/HubToggleButtons/HubToggleButtons';
import HubStoreEmptyState from './HubStoreEmptyState/HubStoreEmptyState';
import HubStoreSingleItem from './HubStoreSingleItem/HubStoreSingleItem';
import HubStoreDetailItemModal from './HubStoreDetailItemModal/HubStoreDetailItemModal';
import HubStoreSettingsModal from './HubStoreSettingsModal/HubStoreSettingsModal';
import { IThemeStyleObjectsByType } from '../Hub';

interface IProps {
  adminMode: boolean;
  hub: IHub | null;
  theme: IThemeStyleObjectsByType;
  userHasEditPermissions: boolean;
}

const sortByNew = { label: 'Newest', value: 'desc' } as { label: string; value: 'asc' | 'desc' };
const sortByOld = { label: 'Oldest', value: 'asc' } as { label: string; value: 'asc' | 'desc' };

export const HubStore: React.FC<IProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchDebounce, setSearchDebounce] = useState<ReturnType<typeof setTimeout>>();
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [showContextMenuSelectCauses, setShowContextMenuSelectCauses] = useState<boolean>(false);
  const [selectCauses, setSelectCauses] = useState<string | null>(null);
  const [showContextMenuSortBy, setShowContextMenuSortBy] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<{ label: string; value: 'asc' | 'desc' }>(sortByNew);
  const [page, setPage] = useState<number>(0);
  const [sortValue, setSortValue] = useState<ISortSettings[]>([
    { id: 'created_at', order: sortByNew.value },
  ]);
  const [contextMenuItemsSelectCauses, setContextMenuItemsSelectCauses] = useState<
    IPopupMenuItem[]
  >([
    {
      id: '',
      label: 'Select a Cause',
      onClick: () => {
        setSelectCauses(null);
      },
    },
  ]);
  const [itemFilter, setItemFilter] = useState<'all' | 'hub' | 'partner_groups'>('all');
  const [storeItems, setStoreItems] = useState<IStoreItem[]>([]);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [activeStoreItem, setActiveStoreItem] = useState<IStoreItem | null>(null);

  const { dispatchToastError } = useToastDispatcher();

  function getHubStoreItems(append = false) {
    if (props?.hub?.id) {
      setIsLoading(true);

      let filters = [] as IActiveFilter[];

      if (searchValue !== '' && searchValue !== null) {
        filters.push({
          label: 'name',
          id: 'name',
          type: 'text',
          value: searchValue,
        });
      }

      if (selectCauses !== null) {
        filters.push({
          label: 'group_title',
          id: 'group_title',
          type: 'text',
          value: selectCauses,
        });
      }

      hubRequestActions
        .getHubStoreItems(
          props.hub.id,
          formatQuery({
            limit: uiConstants.hubs.listViewPageSize,
            skip: String(page * +uiConstants.hubs.listViewPageSize),
            filters,
            sort: sortValue,
            type: itemFilter,
          }),
        )
        .then((res) => {
          if (append && res && res?.length > 0) {
            setStoreItems([...storeItems, ...res]);
          } else {
            setStoreItems(res);
          }
        })
        .catch((error) => dispatchToastError(error, 'Get Hub Store Items'))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  function loadMoreItems() {
    setPage(page + 1);
    getHubStoreItems(true);
  }

  function getHubPartners() {
    if (props?.hub?.id) {
      hubRequestActions
        .getHubPartners(props.hub.id)
        .then((partners) => {
          setContextMenuItemsSelectCauses([
            ...contextMenuItemsSelectCauses,
            ...partners.map((partner) => {
              return {
                id: partner.title,
                label: partner.title,
                onClick: () => {
                  setSelectCauses(partner.title);
                },
              };
            }),
          ]);
        })
        .catch((error) => dispatchToastError(error, 'Get Hub Partners'));
    }
  }

  function handleSortBy() {
    setPage(0);
    setSortValue([{ id: 'created_at', order: sortBy.value }]);
  }

  // Config
  const popupMenuConfigSortBy: IShowPopupConfig = {
    showMenu: showContextMenuSortBy,
    setShowMenu: setShowContextMenuSortBy,
    position: {
      type: 'bottom',
    },
  };
  const contextMenuItemsSortBy: IPopupMenuItem[] = [
    {
      id: uiConstants.sortOptions.createdAtAscending,
      label: sortByNew.label,
      isSelected: sortBy.value === sortByNew.value,
      onClick: () => {
        setSortBy(sortByNew);
        handleSortBy();
      },
    },
    {
      id: uiConstants.sortOptions.createdAtDescending,
      label: sortByOld.label,
      isSelected: sortBy.value === sortByOld.value,
      onClick: () => {
        setSortBy(sortByOld);
        handleSortBy();
      },
    },
  ];

  const popupMenuConfigSelectCauses: IShowPopupConfig = {
    showMenu: showContextMenuSelectCauses,
    setShowMenu: setShowContextMenuSelectCauses,
    position: {
      type: 'bottom',
    },
  };

  useEffect(() => {
    getHubPartners();
  }, []);

  useEffect(() => {
    setPage(0);
    getHubStoreItems();
  }, [searchValue]);

  useEffect(() => {
    setPage(0);
    getHubStoreItems();
  }, [itemFilter, sortValue, selectCauses]);

  return (
    <div className="HubStore">
      <HubFilter
        header="Store"
        adminMode={props.adminMode}
        searchValue={searchValue || ''}
        onSearch={setSearchValue}
        searchPlaceholder="Search items by name"
        onClickEdit={() => setShowSettingsModal(true)}
        sortByConfig={[
          {
            menuItems: contextMenuItemsSelectCauses,
            showMenuConfig: popupMenuConfigSelectCauses,
            className: 'selectCauses',
            label: 'Causes',
            valueLabel: selectCauses === null ? 'Select a Cause' : selectCauses,
            popupMenuClass: 'HubStore-popupMenu-selectCauses',
            showMenu: showContextMenuSelectCauses,
            onClick: () => {
              setShowContextMenuSelectCauses(true);
            },
            onSelect: () => {
              setShowContextMenuSelectCauses(false);
            },
          },
          {
            menuItems: contextMenuItemsSortBy,
            showMenuConfig: popupMenuConfigSortBy,
            className: 'sortBy',
            label: 'Sort By',
            valueLabelIcon: 'far fa-clock',
            valueLabel: sortBy.label,
            popupMenuClass: 'HubStore-popupMenu-sortBy',
            showMenu: showContextMenuSortBy,
            onClick: () => {
              setShowContextMenuSortBy(true);
            },
            onSelect: () => {
              setShowContextMenuSortBy(false);
            },
          },
        ]}
      />
      <hr />

      <HubToggleButtons
        activeStyle={props.theme.secondaryBgWithText}
        buttons={[
          {
            text: 'All Items',
            active: 'all' === itemFilter,
            onClick: () => {
              setItemFilter('all');
            },
          },
          {
            text: 'Company Items',
            active: 'hub' === itemFilter,
            onClick: () => {
              setItemFilter('hub');
            },
          },
          {
            text: 'Partner Items',
            active: 'partner_groups' === itemFilter,
            onClick: () => {
              setItemFilter('partner_groups');
            },
          },
        ]}
      />

      {!isLoading && storeItems.length > 0 && (
        <>
          <div className="list-container">
            {storeItems.map((item, index) => (
              <HubStoreSingleItem
                openStoreItemDetailModal={() => {
                  setActiveStoreItem(item);
                  setShowDetailModal(true);
                }}
                storeItem={item}
                key={index}
                hub={props?.hub || undefined}
              />
            ))}
          </div>
          {storeItems.length === +uiConstants.hubs.listViewPageSize * (page || 1) && (
            <div className="load-more-container">
              <Button
                text="Load more"
                type="button"
                buttonType="outline-dark"
                onClick={() => loadMoreItems()}
              />
            </div>
          )}
        </>
      )}

      {!isLoading && storeItems.length < 1 && (
        <HubStoreEmptyState
          userHasEditPermissions={props.userHasEditPermissions}
          hub={props.hub}
        />
      )}

      {isLoading && <Loader loading={isLoading} />}

      {activeStoreItem && (
        <HubStoreDetailItemModal
          show={showDetailModal}
          onClose={() => {
            setShowDetailModal(false);
            setActiveStoreItem(null);
          }}
          storeItem={activeStoreItem}
          hubName={props.hub?.title}
          hubLogoUrl={props.hub?.profile_image_url}
          hub={props.hub || undefined}
        />
      )}

      {props.adminMode && (
        <HubStoreSettingsModal
          hub={props.hub}
          onCloseSettingsModal={() => setShowSettingsModal(false)}
          showSettingsModal={showSettingsModal}
        />
      )}
    </div>
  );
};
