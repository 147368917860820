import { IFundraiserSummary, IUser } from '@gigit/interfaces';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Portrait from '../../components/Portrait/Portrait';
import SortableTable from '../../components/SortableTable/SortableTable';
import TextField from '../../components/TextField/TextField';
import { createToast } from '../../actions/toaster';
import {
  defaultCurrency,
  downloadFile,
  errorHelpers,
  formatCurrency,
  formatQuery,
  handleDebounce,
  handleInputChange,
  routes,
  swapRouteParams,
  toastError,
} from '../../helpers';
import { IActiveFilter, IOwnerObject, ISortSettings, ReduxActionType } from '../../interfaces';
import { IEventState } from '../../reducers/event';
import { IGroupState } from '../../reducers/group';
import { IAppState } from '../../store';
import axios from 'axios';
import './GroupFundraiserManage.scss';
import { userSelectors } from '../../selectors/user';
import CoverImage from '../../components/CoverImage/CoverImage';
import Button from '../../components/Button/Button';
import moment from 'moment';
import ContactDetails from '../../components/ContactDetails/ContactDetails';
import Modal from '../../components/Modal/Modal';
import { localizeHelpers } from '../../localizeHelpers';

interface IPassedProps extends RouteComponentProps<any, any, any> {
  owner: IOwnerObject;
}
interface IPropsFromState {
  groupState: IGroupState;
  eventState: IEventState;
  locale: string;
}
interface IPropsFromDispatch {
  createToast: ReduxActionType<typeof createToast>;
}
type Props = IPassedProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  fundraisers: IFundraiserSummary[];
  isFundraisersLoading: boolean;
  searchFundraiserValue: string;
  showFundraiserModal: boolean;
  detailRowId: string;
  showContactDetailsModal: boolean;
  selectedUser: IUser | null;
  sort: ISortSettings[];
  filters: IActiveFilter[];
}
// TODO: This should not be in /routes since it's a component
class GroupFundraiserManage extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fundraisers: [],
      isFundraisersLoading: false,
      searchFundraiserValue: '',
      showFundraiserModal: false,
      detailRowId: '',
      showContactDetailsModal: false,
      selectedUser: null,
      sort: [],
      filters: [],
    };
  }

  componentDidMount() {
    this.getFundraisers();
  }

  componentDidUpdate(prevProps: Props, prevState: IState) {
    if (this.state.filters !== prevState.filters || this.state.sort !== prevState.sort) {
      this.getFundraisers();
    }
  }

  /**
   * @param showModal Toggle value to show or hide the showFundraiser modal,
   * if it's left out it toggles the value.
   */
  toggleAddFundraiserModal = (showModal?: boolean) => {
    this.setState({
      showFundraiserModal: showModal ? showModal : !showModal,
    });
  };

  getFundraisers = async () => {
    try {
      this.setState({ isFundraisersLoading: true });

      let query = formatQuery({
        search: this.state.searchFundraiserValue,
        sort: this.state.sort,
        filters: this.state.filters,
      });
      let _route = swapRouteParams(routes.GET_FUNDRAISERS, { groupId: this.props.owner.ownerId });
      _route += `?${query}`;

      const groupFundraisersResponse = await axios.get<IFundraiserSummary[]>(_route);

      this.setState({
        fundraisers: [...groupFundraisersResponse.data],
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      this.props.createToast(toastError(errorObj.translatedMessage, 'Get Fundraisers'));
    } finally {
      this.setState({ isFundraisersLoading: false });
    }
  };

  openEmail(email: string) {
    window.location.href = 'mailto:' + email;
  }

  renderRowDetails = (fundraiser: IFundraiserSummary) => {
    const { user } = fundraiser;

    return (
      <Fragment>
        <div className="third">
          <CoverImage
            currentImage={user.cover_image_url}
            height={160}
          />
          <div className="inner-user-details">
            <Link to={'/user/' + user.handle}>
              <Portrait
                currentImage={user.profile_image_url}
                size={100}
              />
            </Link>
            <div
              className="user-name"
              notranslate="yes"
            >
              <span
                className="volunteer-name"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ showContactDetailsModal: true, selectedUser: user || null });
                }}
              >
                {user.display_name}
              </span>
            </div>
            <Link
              className="user-handle"
              to={'/user/' + user?.handle}
              notranslate="yes"
            >
              @{user?.handle}
            </Link>
            <div className="user-actions">
              <div className="sub-actions">
                <Button
                  onClick={(e: any) => {
                    this.openEmail(user.email);
                    e.stopPropagation();
                  }}
                  icon="far fa-envelope"
                  text={'Email'}
                />
              </div>
              <div className="main-action">
                <Link to={`/user/${fundraiser.user.handle}`}>View Profile</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="third">
          <div className="title">Details</div>
          <div
            className="email"
            notranslate="yes"
          >
            {user?.email}
          </div>
          <div
            className="phone"
            notranslate="yes"
          >
            {user?.phone}
          </div>

          <div className="title">Personal</div>
          <div
            className="email"
            notranslate="yes"
          >
            {user.gender}
          </div>
          <div
            className="dob"
            notranslate="yes"
          >
            {moment(user?.dob).format('MMMM Do YYYY')}
          </div>
        </div>
        <div className="third metrics">
          <div className="metric long">
            <div className="metric-inner">
              <span
                className="metric-value"
                notranslate="yes"
              >
                {fundraiser.number_of_fundraisers_created}
              </span>
              <span className="metric-label">Fundraisers ran</span>
            </div>
          </div>
          <div className="metric">
            <div className="metric-inner">
              <span
                className="metric-value"
                notranslate="yes"
              >
                {this.formatCurrency(fundraiser.total_raised)}
              </span>
              <span className="metric-label">In total raised</span>
            </div>
          </div>
          <div className="metric">
            <div className="metric-inner">
              <span
                className="metric-value"
                notranslate="yes"
              >
                {this.formatCurrency(fundraiser.average_raised_per_fundraiser)}
              </span>
              <span className="metric-label">Average raised per fundraiser</span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  formatCurrency = (amount: number) => {
    let currency = this.props.owner.account?.currency || defaultCurrency;

    return formatCurrency(amount, currency, this.props.locale);
  };

  setDetailRowId = (id: string) => {
    if (id === this.state.detailRowId) {
      this.setState({
        detailRowId: '',
      });
    } else {
      this.setState({
        detailRowId: id,
      });
    }
  };

  exportIndividuals = async () => {
    try {
      const response = await axios.get(
        swapRouteParams(routes.GET_GROUP_INDIVIDUALS_EXPORT, {
          groupId: this.props.groupState.group.id,
        }),
        { responseType: 'blob' },
      );

      downloadFile(`Fundraisers_for_${this.props.groupState.group.handle}.csv`, response.data);
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      this.props.createToast(toastError(errorObj.translatedMessage, 'Fundraisers Export'));
    }
  };

  render() {
    return (
      <div className="GroupFundraiserManage section-wrap">
        <div className="section-title">
          <div className="section-inner-title">Fundraisers</div>
          <Button
            className="export-button"
            icon="far fa-file-download"
            onClick={() => this.exportIndividuals()}
            text={'Export Fundraisers'}
          />
        </div>
        <div className="section-inner">
          <div className="GroupFundraiserManagement-list">
            <div className="search">
              <TextField
                icon="fas fa-search"
                placeholder="Search fundraisers..."
                value={this.state.searchFundraiserValue}
                type="text"
                name="searchFundraiserValue"
                onChange={(e) => {
                  handleInputChange(e, this, false, () => {
                    handleDebounce(this.state.searchFundraiserValue).then((res) => {
                      this.getFundraisers();
                    });
                  });
                }}
              />
            </div>
            <div className="list">
              <div className="list-inner">
                <div className="list-rows">
                  <SortableTable
                    loading={this.state.isFundraisersLoading}
                    onSort={(sortSettings) => {
                      this.setState({ sort: sortSettings });
                    }}
                    columns={[
                      { id: 'portrait', label: 'Portrait', sortable: false },
                      { id: 'user.display_name', label: 'Fundraiser Name' },
                      { id: 'number_of_fundraisers_created', label: '# Of Fundraisers' },
                      { id: 'total_raised', label: 'Total $ Raised' },
                      { id: 'average_raised_per_fundraiser', label: 'Average Per Fundraiser' },
                      { id: 'actions', label: 'Actions' },
                    ]}
                    data={this.state.fundraisers.map((fundraiser) => {
                      const _menuItems = [
                        {
                          icon: 'fas fa-envelope',
                          link: 'mailto:' + fundraiser.user?.id,
                          label: 'Email',
                        },
                        {
                          icon: 'fas fa-user',
                          link: `/user/${fundraiser.user.handle}`,
                          label: 'View Profile',
                        },
                      ];

                      return {
                        row: [
                          {
                            id: 'portrait',
                            content: (
                              <Portrait
                                source={fundraiser.user.profile_image_url}
                                size={40}
                              />
                            ),
                          },
                          {
                            id: 'user.display_name',
                            content: (
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({
                                    showContactDetailsModal: true,
                                    selectedUser: fundraiser.user || null,
                                  });
                                }}
                                className="fundraiser-name-link"
                                notranslate="yes"
                              >
                                {fundraiser.user.display_name}
                              </span>
                            ),
                          },
                          {
                            id: 'number_of_fundraisers_created',
                            content: (
                              <span notranslate="yes">
                                {localizeHelpers.formatNumber(
                                  fundraiser.number_of_fundraisers_created,
                                  this.props.locale,
                                )}
                              </span>
                            ),
                          },
                          {
                            id: 'total_raised',
                            content: (
                              <span notranslate="yes">
                                {this.formatCurrency(fundraiser.total_raised)}
                              </span>
                            ),
                          },
                          {
                            id: 'average_raised_per_fundraiser',
                            content: (
                              <span notranslate="yes">
                                {this.formatCurrency(fundraiser.average_raised_per_fundraiser)}
                              </span>
                            ),
                          },
                          { id: 'actions', menu: _menuItems },
                        ],
                        onClick: (e: any) => {
                          this.setDetailRowId(fundraiser.id);
                        },
                        details: this.renderRowDetails(fundraiser),
                      };
                    })}
                  />
                  {this.state.fundraisers.length === 0 && (
                    <div className="empty">
                      {this.state.searchFundraiserValue === '' ? (
                        <span>You don't have any fundraisers, yet.</span>
                      ) : (
                        <span>Your search returned 0 results.</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          class="ContactDetailsModal"
          show={this.state.showContactDetailsModal}
          onClose={() => {
            this.setState({ showContactDetailsModal: false });
          }}
          title="Contact Details"
        >
          <ContactDetails
            userId={this.state.selectedUser?.id!}
            {...this.props}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    eventState: store.eventState,
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupFundraiserManage);
