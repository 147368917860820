import {
  IHub,
  IDonationMatchingPayoutParams,
  IGroupSummary,
  IPaymentMethod,
  PaymentMethodType,
  IUserCreditPaymentPackageFE,
  IUserCreditPaymentPackageGroupSummaryFE,
} from '@gigit/interfaces';
import React, { useEffect, useState } from 'react';
import {
  defaultCurrency,
  donationShowError,
  formatCurrency,
  getAddressParts,
  typeHelpers,
} from '../../../../../helpers';
import { useLocale } from '../../../../../hooks';
import useToastDispatcher from '../../../../../hooks/useToaster';
import { groupRequestActions, hubRequestActions } from '../../../../../requestActions';
import Button from '../../../../Button/Button';
import CoverImage from '../../../../CoverImage/CoverImage';
import Modal from '../../../../Modal/Modal';
import Portrait from '../../../../Portrait/Portrait';
import PopupMenu, {
  IPopupMenuItem,
  IShowPopupConfig,
} from '../../../../shared/PopupMenu/PopupMenu';
import './PayoutModal.scss';
import PaymentMethodPreview from '../../../../../routes/Donate/DonateForm/PaymentMethodPreview/PaymentMethodPreview';
import PickPaymentMethod from '../../../../../routes/Donate/DonateForm/PickPaymentMethod/PickPaymentMethod';
import NewPaymentMethod from '../../../../../routes/Donate/DonateForm/NewPaymentMethod/NewPaymentMethod';
import { CheckoutLocale, Stripe, loadStripe } from '@stripe/stripe-js';
import { localeConstants } from '../../../../../constants';
import { Config } from '@gigit/config';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

interface IProps {
  hub: IHub;
  groupToPay: IUserCreditPaymentPackageGroupSummaryFE;
  paymentPackage: IUserCreditPaymentPackageFE;
  showModal: boolean;
  onClose(): void;
  onPayoutFinish(): void;
}

function PayoutModal(props: IProps) {
  const { showModal, onClose, paymentPackage, groupToPay, hub, onPayoutFinish } = props;

  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<PaymentMethodType[]>([]);
  const [selectedAvailablePaymentMethod, setSelectedAvailablePaymentMethod] = useState<
    string | null
  >(null);
  const [showPaymentMethodContextMenu, setShowPaymentMethodContextMenu] = useState<boolean>(false);
  const [showAddPaymentMethodContent, setShowAddPaymentMethodContent] = useState<boolean>(false);
  const [group, setGroup] = useState<IGroupSummary | null>(null);
  const [showPickPaymentMethodContent, setShowPickPaymentMethodContent] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>();
  const [paymentMethodIndex, setPaymentMethodIndex] = useState<number>();
  const currency = hub.account?.currency || defaultCurrency;
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [isMakePaymentLoading, setIsMakePaymentLoading] = useState<boolean>(false);

  const locale = useLocale();

  async function getGroupInfo() {
    try {
      const data = await groupRequestActions.getGroupByHandleOrId(groupToPay._id);
      setGroup(data);
    } catch (error) {
      dispatchToastError(error, 'Get Cause Info');
    }
  }

  async function handleGetDonationMatchingPayoutSummary() {
    try {
      typeHelpers.assertNotNullOrUndefined(hub?.id, 'Expected Company Id');
      const payload = {
        payment_method: 'card',
      } as IDonationMatchingPayoutParams;
      const data = await hubRequestActions.getDonationMatchingPayoutSummary(
        hub.id,
        paymentPackage.id,
        groupToPay._id,
        payload,
      );
      // setPayoutSummary(data);
    } catch (error) {
      dispatchToastError(error, 'Get Payout Summary');
    }
  }

  async function handleGetDonationMatchingAvailablePaymentMethods() {
    try {
      typeHelpers.assertNotNullOrUndefined(hub?.id, 'Expected Company Id');
      const data = await hubRequestActions.getDonationMatchingAvailablePaymentMethods(
        hub.id,
        paymentPackage.id,
        groupToPay._id,
      );
      setAvailablePaymentMethods(data.available_payment_methods as PaymentMethodType[]);
      if (data.available_payment_methods.length === 1) {
        setSelectedAvailablePaymentMethod(data.available_payment_methods[0]);
      }
    } catch (error) {
      dispatchToastError(error, 'Get Available Payment Methods');
    }
  }

  async function handleMakePayment() {
    try {
      typeHelpers.assertNotNullOrUndefined(hub?.id, 'Expected Company Id');
      const tmpPaymentMethod = paymentMethod;
      const payload = {
        payment_method_country: tmpPaymentMethod?.billing_details?.address?.country,
        payment_method: selectedAvailablePaymentMethod,
        delegate_group_id: groupToPay._id,
        address: tmpPaymentMethod?.billing_details?.address,
        payment_method_id: tmpPaymentMethod?.id || paymentMethod?.payment_method_id,
        payment_customer_id: tmpPaymentMethod?.customer_id,
      } as IDonationMatchingPayoutParams;
      setIsMakePaymentLoading(true);
      await hubRequestActions.payPaymentPackage(hub.id, paymentPackage.id, groupToPay._id, payload);
      dispatchToastSuccess('You successfully made a payment.', 'Make Payment');
    } catch (error) {
      dispatchToastError(error, 'Make Payment');
    } finally {
      setIsMakePaymentLoading(false);
      onPayoutFinish();
      onClose();
    }
  }

  function handleSubmitNewPaymentMethod(paymentMethod: IPaymentMethod) {
    setPaymentMethod(paymentMethod);
    setShowAddPaymentMethodContent(false);
    setShowPickPaymentMethodContent(true);
    setPaymentMethodIndex(undefined);
  }

  function getBillingInfo() {
    const tmpPaymentMethod = paymentMethod;
    return tmpPaymentMethod?.billing_details?.address
      ? getAddressParts(tmpPaymentMethod.billing_details.address, [
          'line1',
          'line2',
          'city',
          'country',
        ])
      : '';
  }

  const paymentMethodShowMenuConfig: IShowPopupConfig = {
    showMenu: showPaymentMethodContextMenu,
    setShowMenu: setShowPaymentMethodContextMenu,
    position: { type: 'bottom' },
  };

  const paymentMethodContextMenuItems: IPopupMenuItem[] =
    availablePaymentMethods.map((method) => ({
      id: method,
      label: localeConstants.paymentMethodLabels[method],
      isSelected: selectedAvailablePaymentMethod === method,
      onClick: () => setSelectedAvailablePaymentMethod(method),
    })) || [];

  const getPaymentMethodLabel = (method: string) => {
    return localeConstants.paymentMethodLabels[method];
  };

  useEffect(() => {
    getGroupInfo();
    handleGetDonationMatchingAvailablePaymentMethods();
    // handleGetDonationMatchingPayoutSummary();
  }, [props.groupToPay._id]);

  useEffect(() => {
    setStripePromise(
      loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY, {
        locale: locale.currentLocale as CheckoutLocale,
      }),
    );
  }, []);

  return (
    <Modal
      show={showModal}
      onClose={onClose}
      closeIcon="fas fa-times"
      class="PayoutModal"
    >
      <div className="dmp">
        <div className="dmp-main">
          <h1>Volunteer Credit Payment</h1>
          <div className="dmp-main--info">
            <span className="title">Payout Package</span>
            <div className="info">
              <span className="info--program-name">{paymentPackage.title}</span>
              <div className="info--group">
                {group && (
                  <>
                    <Portrait
                      currentImage={group?.profile_image_url}
                      size={60}
                    />
                    <span className="name">{group?.title}</span>
                  </>
                )}
              </div>
              <progress
                max={groupToPay.amount || 0}
                value={groupToPay.amount_paid || 0}
                // max={paymentPackage.max_amount}
                // value={paymentPackage.amount_payed_out}
              />
              <div className="progress-info">
                <div className="progress-info--item">
                  {/* <span className="value">{paymentPackage.amount_payed_out}</span> */}
                  <span className="value">
                    {formatCurrency(groupToPay.amount_paid || 0, currency, locale.currentLocale)}
                  </span>
                  <span className="name">Your Amount Paid</span>
                </div>
                <div className="progress-info--item">
                  {/* <span className="value">{paymentPackage.max_amount}</span> */}
                  <span className="value">
                    {formatCurrency(groupToPay.amount || 0, currency, locale.currentLocale)}
                  </span>
                  <span className="name">Total Donations</span>
                </div>
              </div>
            </div>
            <span className="title">Payment Information</span>
            <PopupMenu
              className="payment-type-popupmenu"
              showMenuConfig={paymentMethodShowMenuConfig}
              menuItems={paymentMethodContextMenuItems}
              onClick={() => setShowPaymentMethodContextMenu(!showPaymentMethodContextMenu)}
              onSelect={() => setShowPaymentMethodContextMenu(false)}
            >
              <label>Payment Type</label>
              <span className="title-popupmenu">
                {getPaymentMethodLabel(selectedAvailablePaymentMethod || '')}
                <i
                  className={`fas fa-sort-down menu-btn ${showPaymentMethodContextMenu ? 'show' : ''}`}
                />
              </span>
            </PopupMenu>
            <div className="section">
              <span className="section--title">Payment Method</span>
              <PaymentMethodPreview
                isUsingAlternativePaymentMethod={false}
                paymentMethod={paymentMethod!}
                onClickContainer={() => setShowPickPaymentMethodContent(true)}
              />
            </div>
            <div className="section">
              <span className="section--title">Billing Information</span>
              <div className="section--content">{getBillingInfo() || '-'}</div>
            </div>
          </div>
          <div className="dmp-main--actions">
            <Button
              buttonType="outline-dark"
              onClick={onClose}
              text="Cancel"
            />
            <Button
              buttonType="dark"
              onClick={handleMakePayment}
              text="Make Payment"
              loading={isMakePaymentLoading}
              isDisabled={!paymentMethod || !selectedAvailablePaymentMethod || isMakePaymentLoading}
            />
          </div>

          <div className={`pick-payment-method ${showPickPaymentMethodContent ? 'active' : ''}`}>
            {showPickPaymentMethodContent && (
              <PickPaymentMethod
                onMethodSelect={(item, index) => {
                  setPaymentMethod(item);
                  setPaymentMethodIndex(index);
                }}
                onBack={() => {
                  setShowPickPaymentMethodContent(false);
                }}
                onSubmit={() => {
                  setShowPickPaymentMethodContent(false);
                }}
                isUsingAlternativePaymentMethod={false}
                openAddNewPaymentMethod={() => {
                  setShowAddPaymentMethodContent(true);
                  setShowPickPaymentMethodContent(false);
                }}
                selectedPaymentMethod={paymentMethod}
                selectedPaymentMethodIndex={paymentMethodIndex}
              />
            )}
          </div>
          <div className={`add-payment-method ${showAddPaymentMethodContent ? 'active' : ''}`}>
            {stripePromise !== null && (
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({ elements, stripe }) => (
                    <NewPaymentMethod
                      className="new-cc"
                      stripe={stripe}
                      elements={elements}
                      onBack={() => {
                        setShowPickPaymentMethodContent(true);
                        setShowAddPaymentMethodContent(false);
                      }}
                      onSubmit={handleSubmitNewPaymentMethod}
                    />
                  )}
                </ElementsConsumer>
              </Elements>
            )}
          </div>
        </div>
        <div className="dmp-summary">
          {group && (
            <>
              <CoverImage currentImage={group.cover_image_url} />
              <Portrait
                currentImage={group.profile_image_url}
                size={90}
              />
              <span
                className="name"
                notranslate="yes"
              >
                {group.title}
              </span>
              <span className="charity">
                Charity ID: <var data-var="group_charity_id">{group.charity_id}</var>
              </span>
              <div className="amounts">
                <div className="amounts--row">
                  <span className="amounts--name">Donation</span>
                  <span
                    className="amounts--value"
                    notranslate="yes"
                  >
                    {formatCurrency(groupToPay.amount || 0, currency, locale.currentLocale)}
                  </span>
                </div>
              </div>
              <div className="row total">
                <span className="row--name">Total Payout Amount</span>
                <span
                  className="row--value"
                  notranslate="yes"
                >
                  {formatCurrency(groupToPay.amount || 0, currency, locale.currentLocale)}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default PayoutModal;
