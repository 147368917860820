import React, { createRef, Fragment, MutableRefObject, RefObject } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { PaymentRequest, Stripe, PaymentRequestWallet, StripeElements } from '@stripe/stripe-js';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import {
  routes,
  handleInputChange,
  swapRouteParams,
  defaultCurrency,
  toastError,
  toastSuccess,
  combineClassNames,
  IStringMap,
  donationShowError,
} from '../../../helpers';
import {
  IGroup,
  ICampaign,
  IGroupDelegation,
  IEventTeam,
  IEventIndividual,
  IDonationRequest,
  IPaymentMethod,
  ISubscriptionRequest,
  IAvailableDonationMatchingProgram,
} from '@gigit/interfaces';
import { IUserState } from '../../../reducers/user';
import { createToast } from '../../../actions/toaster';
import { getGroupDelegations } from '../../../actions/group';
import TextField from '../../../components/TextField/TextField';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Dropdown, { IOptions } from '../../../components/Dropdown/Dropdown';
import P2PSelector from '../P2PSelector/P2PSelector';
import './DonateForm.scss';
import { formatCurrency, formatCardIcon } from '../../../helpers';
import { Constants, GroupManagePermissions } from '@gigit/constants';
import QuillTextEditor from '../../../components/QuillTextEditor/QuillTextEditor';
import { SearchableDropdown } from '../../../components/SearchableDropdown/SearchableDropdown';
import { userSelectors } from '../../../selectors/user';
import { IOwnerObject, IToast } from '../../../interfaces';
import { localizeHelpers } from '../../../localizeHelpers';
import errorHelpers from '../../../helpers/errorHelpers';
import { uiConstants } from '../../../constants/uiConstants';
import typeHelpers from '../../../helpers/typeHelpers';
import { IAppState } from '../../../store';
import { IGroupState } from '../../../reducers/group';
import { IEventState } from '../../../reducers/event';
import { localeConstants } from '../../../constants';
import { Config } from '@gigit/config';
import {
  donationRequestActions,
  hubRequestActions,
  userRequestActions,
} from '../../../requestActions';
import DonationMatchingProgramDetails from './DonationMatchingProgramDetails/DonationMatchingProgramDetails';
import PaymentMethodPreview from './PaymentMethodPreview/PaymentMethodPreview';
import PickPaymentMethod from './PickPaymentMethod/PickPaymentMethod';
import NewPaymentMethod from './NewPaymentMethod/NewPaymentMethod';
import VolunteerForDollarsDetails from './VolunteerForDollarsDetails/VolunteerForDollarsDetails';

const anonymityOptions: IOptions[] = [
  { label: 'Select One', value: '' },
  {
    label:
      "Yes, I do want to be contacted by the organization(s) I'm supporting with this donation.",
    value: 'yes',
  },
  {
    label:
      "No, I don't want to be contacted by the organization(s) I'm supporting with this donation.",
    value: 'no',
  },
];

interface DonationAmount {
  amount: number;
  description: string;
}

interface IProps extends RouteComponentProps<any> {
  createToast(toast: IToast): void;
  getGroupDelegations(
    ownerType: string,
    ownerId: string,
    query?: string,
    postalCodeSearch?: string,
    options?: { callback?: () => void },
  ): void;
  stripe: Stripe | null;
  elements: StripeElements | null;
  userState: IUserState;
  groupState: IGroupState;
  eventState: IEventState;
  useDonationDescriptions: boolean;
  campaign: ICampaign | null;
  isP2P: boolean;
  autoSendTaxReceipts?: boolean;
  minimumTaxReceiptAmount?: number;
  donationCheckoutMessage?: string;
  groupDelegations: IGroupDelegation[];
  allowDonationDelegation: boolean;
  locale: string;
  hubOwnerGroup: IGroup | null;
  owner: IOwnerObject;
  userPermissions: IStringMap;
  warning?: {
    show: boolean;
    text: string;
  };
}

interface IState {
  // TODO [HOTFIX/3.4.4]: Fix `any` type.
  isUsingAlternativePaymentMethod: boolean;
  supportedAltPaymentMethods: any[];
  donation_summary: any;
  newPaymentMethod: IPaymentMethod | null;
  overlay: string;
  email: string;
  phone: string;
  address: string;
  address2: string;
  city: string;
  country: string;
  province: string;
  postal: string;
  message: string;
  honorFullName: string;
  receiptFullName: string;
  receiptEmail: string;
  receiptMessage: string;
  cardName: string;
  firstName: string;
  lastName: string;
  displayName: string;
  formRef: RefObject<HTMLDivElement>;
  scrollTop: number;
  paymentMethods: IPaymentMethod[];
  donationAmounts: DonationAmount[];
  tipAmounts: number[];
  selectedPaymentMethod: number;
  selectedAltPaymentMethod: number;
  customDonationAmount: number;
  customTipAmount: number;
  tipAmount: number;
  donationAmount: number;
  donorType: 'personal' | 'corporate';
  donationType: 'once' | 'monthly' | 'volunteer-for-dollars';
  dedicateType: 'honour' | 'memory';
  donationLoading: boolean;
  intent: any;
  paymentMethod: IPaymentMethod | null;
  teams: IEventTeam[];
  individuals: IEventIndividual[];
  P2PValue: { type: string; item: any | null };
  funds: IOptions[];
  fund: string;
  fundError: boolean;
  hFirst: string;
  hLast: string;
  rFirst: string;
  rLast: string;
  rMessage: string;
  rEmail: string;
  allowMarketingNotifications: string;
  useOnlineHandle: boolean;
  groupDelegationSearch: string;
  selectedGroupDelegation: IGroupDelegation | null;
  anonymous: boolean;
  P2PValueError: string | null;
  embedDefaultColours: string[];
  embedHoverColours: string[];
  reviewHover: boolean;
  continueHover: boolean;
  backHover: boolean;
  isEmbed: boolean;
  showEmbedComplete: boolean;
  paymentRequest: PaymentRequest | null;
  donationMatchingPrograms: IAvailableDonationMatchingProgram[];
  volunteerMatchingPrograms: IAvailableDonationMatchingProgram[];
  selectedDonationMatchingProgram: IAvailableDonationMatchingProgram | null;
  selectedVolunteerForDollarsProgram: IAvailableDonationMatchingProgram | null;
  matchedAmount: number;
  tipsEnabled: boolean;
  userCreditsBalanceForGroup: number;
  userHasVolunteerForDollarsProgram: boolean;
}
class DonateForm extends React.Component<IProps, IState> {
  // Holds refs of different sections so we can jump to them.
  private formSectionRefLookup = createRef<{
    [index: string]: HTMLDivElement | null;
  }>() as MutableRefObject<{ [index: string]: HTMLDivElement | null }>;

  constructor(props: IProps) {
    super(props);

    let params = queryString.parse(this.props.location.search);
    let shouldShowTips = true;

    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      let ownerObj = this.props.owner.object as IGroup;
      shouldShowTips = ownerObj?.fee_control?.show_tip ?? true;
    }

    this.state = {
      isUsingAlternativePaymentMethod: false,
      newPaymentMethod: null,
      supportedAltPaymentMethods: [],
      donation_summary: {},
      overlay: '',
      email: '',
      phone: '',
      address: '',
      address2: '',
      city: '',
      country:
        this.props.groupState.group.localization?.country !== undefined
          ? this.props.groupState.group.localization.country
          : '',
      province:
        this.props.groupState.group.localization?.state !== undefined
          ? this.props.groupState.group.localization.state
          : '',
      postal: '',
      message: '',
      hFirst: '',
      hLast: '',
      rFirst: '',
      rLast: '',
      rMessage: '',
      rEmail: '',
      cardName: '',
      firstName: '',
      lastName: '',
      displayName: '',
      formRef: React.createRef(),
      scrollTop: 0,
      paymentMethods: [],
      selectedPaymentMethod: -1,
      selectedAltPaymentMethod: -1,
      donationAmounts: [],
      tipAmounts: [2, 5, 10, 25, -1],
      customDonationAmount: 0,
      customTipAmount: 0,
      donationAmount: 0,
      tipAmount: 5,
      donorType: 'personal',
      donationType: 'once',
      dedicateType: 'honour',
      donationLoading: false,
      intent: null,
      paymentMethod: null,
      teams: [],
      individuals: [],
      P2PValue: { type: 'event', item: null },
      funds: [],
      fund:
        typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'group', 'event')?.default_fund_name ||
        '',
      fundError: false,
      receiptFullName: '',
      receiptEmail: '',
      receiptMessage: '',
      honorFullName: '',
      allowMarketingNotifications: '',
      useOnlineHandle: false,
      groupDelegationSearch: '',
      selectedGroupDelegation: null,
      anonymous: false,
      P2PValueError: null,
      embedDefaultColours:
        params.default && typeof params.default === 'string' ? params.default.split(',') : [],
      embedHoverColours:
        params.hover && typeof params.hover === 'string' ? params.hover.split(',') : [],
      reviewHover: false,
      continueHover: false,
      backHover: false,
      isEmbed: !!params.embed,
      showEmbedComplete: false,
      paymentRequest: null,
      donationMatchingPrograms: [],
      volunteerMatchingPrograms: [],
      selectedDonationMatchingProgram: null,
      matchedAmount: uiConstants.defaultMatchAmount,
      // tipsEnabled: this.props.groupState.group.fee_control?.show_tip ?? true,
      tipsEnabled: shouldShowTips,
      selectedVolunteerForDollarsProgram: null,
      userCreditsBalanceForGroup: 0,
      userHasVolunteerForDollarsProgram: false,
    };

    this.formSectionRefLookup.current = {};

    this.setOverlay = this.setOverlay.bind(this);
    this.formatCardName = this.formatCardName.bind(this);
    this.getPaymetMethods = this.getPaymetMethods.bind(this);
    this.setupDonation = this.setupDonation.bind(this);
    this.submitDonation = this.submitDonation.bind(this);
    this.getPaymentPayload = this.getPaymentPayload.bind(this);
    this.getDonationAmount = this.getDonationAmount.bind(this);
    this.redirect = this.redirect.bind(this);
    this.showError = this.showError.bind(this);
    this.setDonationAmounts = this.setDonationAmounts.bind(this);
    this.renderTipSection = this.renderTipSection.bind(this);
  }

  componentDidMount() {
    if (this.props.userState.isLoggedIn) {
      this.getPaymetMethods();
      this.setState({
        firstName: this.props.userState.user.first_name,
        lastName: this.props.userState.user.last_name,
        email: this.props.userState.user.email,
        phone: this.props.userState.user.phone || '',
      });

      this.getAvailableVolunteerMatchingPrograms();
      this.getUserCreditBalanceForGroup();
      this.doesUserHaveVolunteerForDollarsProgram();
      this.getPaymetMethods();
      this.updateShouldRenderTipSection();
    }

    this.fetchGroupDelegations();
    this.setDonationAmounts();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      !this.state.funds.length &&
      this.props.campaign &&
      this.props.campaign!?.funds?.length > 0
    ) {
      this.setFunds();
    }

    if (!prevProps.stripe && this.props.stripe) {
      this.getPaymentRequest();
    }

    if (prevProps.userState.isLoggedIn !== this.props.userState.isLoggedIn) {
      //TODO: GIG-7896 - inspect this, unsure
      if (this.props.userState.isLoggedIn === false) {
        this.setState({
          paymentMethods: [],
        });
      }
    }

    if (
      prevState.postal !== this.state.postal ||
      prevProps.hubOwnerGroup !== this.props.hubOwnerGroup
    ) {
      this.fetchGroupDelegations();
    }

    if (this.props.owner !== prevProps.owner) {
      this.setDonationAmounts();
    }

    if (this.props.userState.isLoggedIn && this.state.donationType !== 'volunteer-for-dollars') {
      if (
        this.state.donationAmount != prevState.donationAmount ||
        this.state.customDonationAmount != prevState.customDonationAmount
      ) {
        this.getAvailableDonationMatchingPrograms();
      }
    }
  }

  updateShouldRenderTipSection = async () => {
    const isUserInAHub = await userRequestActions.isUserInAHub();
    if (isUserInAHub) {
      this.setState({
        tipsEnabled: false,
        tipAmount: 0,
        customTipAmount: 0,
      });
    }
  };

  getAvailableVolunteerMatchingPrograms = async () => {
    const volunteerMatchingProgramResponse =
      await hubRequestActions.getAvailableDonationMatchingPrograms(
        Constants.donation_matching_program_type.volunteer_for_dollars,
        {
          donation_amount: this.getDonationAmount(),
          group_id: this.props.owner.ownerId,
        },
      );
    const programsForGroup = volunteerMatchingProgramResponse.filter((program) =>
      program.allowed_groups?.find((allowedGroups) => allowedGroups === this.props.owner.ownerId),
    );

    if (programsForGroup.length > 0) {
      this.setState({
        volunteerMatchingPrograms: programsForGroup,
        selectedVolunteerForDollarsProgram: programsForGroup[0],
      });
    } else {
      this.setState({
        volunteerMatchingPrograms: volunteerMatchingProgramResponse,
        selectedVolunteerForDollarsProgram: volunteerMatchingProgramResponse[0],
      });
    }
  };

  getAvailableDonationMatchingPrograms = async () => {
    const donationMatchingProgramResponse =
      await hubRequestActions.getAvailableDonationMatchingPrograms(
        Constants.donation_matching_program_type.donation_match,
        {
          donation_amount: this.getDonationAmount(),
          group_id: this.props.owner.ownerId,
        },
      );
    const programsForGroup = donationMatchingProgramResponse.filter((program) =>
      program.allowed_groups?.find((allowedGroups) => allowedGroups === this.props.owner.ownerId),
    );

    if (programsForGroup.length > 0) {
      this.setState({
        donationMatchingPrograms: programsForGroup,
        selectedDonationMatchingProgram: programsForGroup[0],
        matchedAmount:
          programsForGroup[0]?.available_donation_match?.max_amount_matchable ??
          uiConstants.maxAmountMatchable,
      });
    } else {
      this.setState({
        donationMatchingPrograms: donationMatchingProgramResponse,
        selectedDonationMatchingProgram: donationMatchingProgramResponse[0],
        matchedAmount:
          donationMatchingProgramResponse[0]?.available_donation_match?.max_amount_matchable ??
          uiConstants.maxAmountMatchable,
      });
    }
  };

  getDonationMatchingProgramOptions = () => {
    return this.state.donationMatchingPrograms.map((program) => ({
      label: program.name,
      value: program.id,
    }));
  };

  getSelectedProgram = () => {
    if (this.state.donationType === 'volunteer-for-dollars') {
      return this.state.volunteerMatchingPrograms.find(
        (program) => program.id === this.state.selectedVolunteerForDollarsProgram?.id,
      );
    }

    return this.state.donationMatchingPrograms.find(
      (program) => program.id === this.state.selectedDonationMatchingProgram?.id,
    );
  };

  setFunds() {
    let _funds: IOptions[] = [];

    _funds.push({ label: 'Select One', value: '' });

    if (this.props.campaign?.funds) {
      for (const fund of this.props.campaign?.funds) {
        typeHelpers.assertNotNullOrUndefined(fund.id);
        _funds.push({ label: fund.name, value: fund.id });
      }
    }

    this.setState({
      funds: _funds,
    });
  }

  setDonationAmounts() {
    let amounts: DonationAmount[] = [];
    const ownerObject = typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'group', 'event');

    if (ownerObject?.donation_amounts && ownerObject.donation_amounts.length > 0) {
      for (const donationAmount of ownerObject.donation_amounts) {
        if (typeof donationAmount === 'object') {
          amounts.push({
            amount: donationAmount.amount,
            description: donationAmount.description || '',
          });
        } else {
          amounts.push({ amount: donationAmount, description: '' });
        }
      }
    } else {
      amounts = [
        { amount: 25, description: '' },
        { amount: 50, description: '' },
        { amount: 100, description: '' },
        { amount: 250, description: '' },
        { amount: 500, description: '' },
        { amount: 1000, description: '' },
      ];
    }

    let defaultTipAmount = this.state.tipAmount;

    if (
      this.props.owner.ownerType === uiConstants.ownerType.group &&
      !this.props.groupState.group.fee_control?.show_tip
    ) {
      defaultTipAmount = 0;
    }

    this.setState({
      tipAmount: defaultTipAmount,
      donationAmounts: amounts,
      donationAmount: amounts[0].amount,
    });
  }

  getPaymetMethods() {
    try {
      axios.get(routes.GET_PAYMENT_METHODS).then((response: { data: IPaymentMethod[] }) => {
        this.setState(
          {
            paymentMethods: response.data,
          },
          () => {
            this.getPaymentRequest();
          },
        );
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      this.showError(errorObj.translatedMessage, true, { errorCode: errorObj.errorCode });
    }
  }

  // TODO [HOTFIX/3.4.4]: Combine with getPaymentPayload(). This method could be called getBaseDonationPayload(), which the other one could call.
  setupDonationRequest(): IDonationRequest {
    const payload: IDonationRequest = {
      payment_method: 'card',
      donor: {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        //"middle_name": this.state.middleName,
        email: this.state.email,
        phone: this.state.phone,
      },
      address: {
        line1: this.state.address,
        line2: this.state.address2,
        city: this.state.city,
        state: this.state.province,
        country: this.state.country,
        postal_code: this.state.postal,
      },
      anonymous: this.state.anonymous,
      dont_send_auto_tax_receipt: !this.state.receiptEmail,
      dont_send_auto_donation_confirmation: !this.state.receiptMessage,
      amount: this.getDonationAmount(),
      tip: {
        amount:
          this.state.tipAmount !== -1
            ? this.state.tipAmount
            : parseFloat(this.state.customTipAmount.toString()),
        amount_type: 'fixed',
      },
    };

    // TODO [HOTFIX/3.4.4]: Do we need dedicate when getting donation summary?
    if (this.state.dedicateType) {
      payload.dedicate = {
        type: this.state.dedicateType === 'memory' ? 'in_memory_of' : 'on_behalf_of',
        first_name: this.state.hFirst,
        last_name: this.state.hLast,
        recepient: {
          email: this.state.rEmail,
          first_name: this.state.rFirst,
          last_name: this.state.rLast,
          message: this.state.rMessage,
        },
      };
    }

    return payload;
  }

  getDonationSummary() {
    let payload = this.setupDonationRequest();
    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      const tmpSelectedDonationMatchingProgram = this.getSelectedProgram();
      if (
        Config.feature_flags.HUB_DONATION_MATCHING &&
        this.state.donationMatchingPrograms.length > 0 &&
        tmpSelectedDonationMatchingProgram
      ) {
        payload = {
          ...payload,
          donation_match: {
            object_id: tmpSelectedDonationMatchingProgram.hub.id,
            donation_matching_program_id: tmpSelectedDonationMatchingProgram.id,
            params: {
              amount_matched: this.state.matchedAmount,
            },
          },
        };
      }

      const url = swapRouteParams(routes.GET_GROUP_DONATION_SUMMARY, {
        groupId: this.props.owner.ownerId,
      });
      axios
        .post(url, payload)
        .then((response) => {
          if (this.state.isUsingAlternativePaymentMethod) {
            this.state.paymentRequest?.update({
              total: {
                label: 'Donation Amount',
                amount: response.data.total * 100,
              },
            });
          }
          this.setState({ donation_summary: response.data }, () => {
            this.setOverlay('review');
          });
        })
        .catch((error: any) => {
          const errorObj = errorHelpers.getErrorObject(error);
          this.showError(errorObj.translatedMessage, true, { errorCode: errorObj.errorCode });
        });
    } else if (this.props.owner.ownerType === uiConstants.ownerType.event) {
      const url = swapRouteParams(routes.GET_EVENT_DONATION_SUMMARY, {
        eventId: this.props.owner.ownerId,
      });
      axios
        .post(url, payload)
        .then((response) => {
          this.state.paymentRequest?.update({
            total: {
              label: 'Donation Amount',
              amount: this.getDonationAmount() * 100,
            },
          });
          this.setState({ donation_summary: response.data }, () => {
            this.setOverlay('review');
          });
        })
        .catch((error: any) => {
          const errObj = errorHelpers.getErrorObject(error);
          this.showError(errObj.translatedMessage, true, { errorCode: errObj.errorCode });
        });
    }
  }

  doesUserHaveVolunteerForDollarsProgram = async () => {
    const userHubs = await userRequestActions.getActiveUserHubs();
    if (userHubs.length > 0) {
      const hasVolunteerForDollarsProgram =
        await userRequestActions.hasVolunteerForDollarsProgram();
      this.setState({
        userHasVolunteerForDollarsProgram: hasVolunteerForDollarsProgram,
      });
    }
  };

  getUserCreditBalanceForGroup = async () => {
    const userCredits = await userRequestActions.getUserCreditBalanceForGroup(
      this.props.owner.ownerId,
    );
    this.setState({
      userCreditsBalanceForGroup: userCredits.balance,
    });
  };

  /** Called to get what alt payment methods are available. */
  getPaymentRequest() {
    const ownerObj: any = this.props.owner?.object;
    const account =
      this.props.owner?.parentOwnerType === 'hub'
        ? ownerObj?.hub?.account
        : this.props.owner.account;

    if (!!ownerObj?.external_id?.Change) {
      return;
    }

    if (!account) {
      throw new Error(
        localizeHelpers.translate(
          'Expected an account to receive payment. Contact administrator if this error persists.',
        ),
      );
    }

    let wallets = uiConstants.altPaymentWallets as PaymentRequestWallet[];

    let initialAmount = Math.round(this.getDonationAmount() * 100);

    if (!initialAmount || isNaN(initialAmount) || initialAmount === 0) {
      // NOTE: Arbitrary amount of 10, it's being updated on donation setup
      initialAmount = 1000;
    }

    const pr = (this.props.stripe as Stripe).paymentRequest({
      country: account.country,
      currency: account.currency ?? defaultCurrency,
      total: {
        label: 'Donation Amount',
        amount: initialAmount,
        pending: true,
      },
      wallets,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        let alternativeMethods = this.state.supportedAltPaymentMethods || [];
        if (result.applePay && alternativeMethods.indexOf('apple_pay') === -1) {
          alternativeMethods.push('apple_pay');
        }

        if (result.googlePay && alternativeMethods.indexOf('google_pay') === -1) {
          alternativeMethods.push('google_pay');
        }

        this.setState({
          paymentRequest: pr,
          supportedAltPaymentMethods: alternativeMethods,
        });

        // This is called when the payment is finished in the alt payment method dialog.
        pr.on('paymentmethod', async (ev) => {
          const pm = ev.paymentMethod;

          const paymentMethod: IPaymentMethod = {
            payment_method_id: pm.id,
            customer_id: pm.customer ?? undefined,
            type: 'stripe',
            billing_details: {
              address: pm.billing_details?.address,
              name: pm.billing_details?.name,
              email: pm.billing_details?.email,
              phone: pm.billing_details?.phone,
            },
            card: {
              brand: pm.card?.brand,
              country: pm.card?.country,
              exp_month: pm.card?.exp_month,
              exp_year: pm.card?.exp_year,
              last4: pm.card?.last4,
            },
          } as IPaymentMethod;
          try {
            const intent = await this.createIntent(paymentMethod);

            // TODO: Combine this submitPayment().
            if (this.state.donationType === 'once') {
              this.props.stripe
                ?.confirmCardPayment(
                  intent.client_secret,
                  { payment_method: pm.id },
                  { handleActions: false },
                )
                .then((response: any) => {
                  if (response.error) {
                    // TODO: This should be changed to properly handle localization.
                    this.showError(
                      response.error.message + 'Reason: ' + response.error.decline_code,
                    );

                    this.setState({
                      donationLoading: true,
                    });
                  } else {
                    ev.complete('success');
                    this.redirect();
                  }
                });
            } else if (this.state.donationType === 'monthly') {
              // TODO [HOTFIX/3.4.4]: Fix `any` type.
              let _payload: any = this.getPaymentPayload(this.state.paymentMethod);

              axios
                .post(
                  swapRouteParams(routes.SUBMIT_GROUP_SUBSCRIPTION, {
                    groupId: this.props.owner.ownerId,
                  }),
                  _payload,
                )
                .then(() => {
                  ev.complete('success');
                  this.redirect();
                })
                .catch((error: any) => {
                  const errorObj = errorHelpers.getErrorObject(error);
                  this.showError(errorObj.translatedMessage, true, {
                    errorCode: errorObj.errorCode,
                  });
                });
            }
          } catch (error) {
            const errorObj = errorHelpers.getErrorObject(error);
            this.showError(errorObj.translatedMessage, true, { errorCode: errorObj.errorCode });
          }
        });
      }
    });
  }

  setOverlay(type: string) {
    const body = document.querySelector('body') as HTMLBodyElement;

    if (type !== '' && this.state.formRef.current) {
      this.setState({
        scrollTop: this.state.formRef.current.scrollTop,
      });
    }

    this.setState(
      {
        overlay: type,
      },
      () => {
        if (type === '') {
          if (this.state.formRef.current) {
            this.state.formRef.current.scrollTop = this.state.scrollTop;
          }

          body.style.overflow = 'unset';
        } else {
          body.style.overflow = 'hidden';
        }
      },
    );
  }

  // TODO: Move this into helpers and merge with other formatCardName() functions.
  formatCardName(item: any) {
    let _name = 'VISA';

    switch (item.card.brand) {
      case 'unionpay':
        _name = 'Union Pay';
        break;
      case 'jcb':
        _name = 'JCB';
        break;
      case 'diners':
        _name = 'Diners Club';
        break;
      case 'discover':
        _name = 'Discover';
        break;
      case 'amex':
        _name = 'American Express';
        break;
      case 'mastercard':
        _name = 'Mastercard';
        break;
    }

    return _name;
  }

  donateWithCredits = async () => {
    const userHubs = await userRequestActions.getActiveUserHubs();

    if (userHubs.length > 0) {
      typeHelpers.assertNotNullOrUndefined(userHubs[0].id, 'User hub id is not defined');
      this.setState({
        donationLoading: true,
      });
      const paymentRequest = await donationRequestActions.donateWithCredits(
        this.props.owner.ownerId,
        {
          amount: this.getDonationAmount(),
          hub_id: userHubs[0].id,
        },
      );

      if (paymentRequest) {
        this.redirect();
      }

      this.setState({
        donationLoading: false,
      });
    }
  };

  setupDonation() {
    this.setState(
      {
        donationLoading: true,
        intent: null,
        paymentMethod: null,
        fundError: false,
      },
      () => {
        if (!this.state.tipsEnabled) {
          this.setState({
            tipAmount: 0,
            customTipAmount: 0,
          });
        }

        if (this.state.P2PValueError != null) {
          this.showError(this.state.P2PValueError, true);
          this.setState({
            donationLoading: false,
          });
          return;
        }

        const allowFundSelection = typeHelpers.tryGetOwnerObjectAs(
          this.props.owner,
          'group',
          'event',
        )?.allow_donation_fund_selection;

        if (this.state.donationType === 'volunteer-for-dollars') {
          this.setState({
            donationLoading: false,
          });
          this.setOverlay('volunteer-for-dollars');
          return;
        }

        if ((allowFundSelection && this.state.fund !== '') || !allowFundSelection) {
          if (
            (this.state.paymentMethods.length > 0 && this.state.selectedPaymentMethod >= 0) ||
            this.state.isUsingAlternativePaymentMethod
          ) {
            this.getDonationSummary();
            this.setState({
              donationLoading: false,
            });
          } else {
            const cardElement = this.props.elements?.getElement('card');

            this.props.stripe
              ?.createPaymentMethod({
                type: 'card',
                card: cardElement!,
                billing_details: {
                  email: this.state.email,
                  name: this.state.cardName,
                  phone: this.state.phone,
                },
              })
              .then(({ error, paymentMethod }) => {
                if (error) {
                  this.setState(
                    {
                      donationLoading: false,
                    },
                    () => {
                      this.showError(error?.message || '', true);

                      if (error.code === 'incomplete_number') {
                        if (
                          this.state.paymentMethods.length > 0 &&
                          this.state.selectedPaymentMethod >= 0
                        ) {
                          this.setOverlay('payment-method');
                        } else {
                          this.setOverlay('new-cc');
                        }
                      }
                    },
                  );
                  return;
                }
                const tmpPaymentMethodNew = paymentMethod;
                const newPaymentMethod: IPaymentMethod = {
                  payment_method_id: tmpPaymentMethodNew?.id,
                  customer_id: tmpPaymentMethodNew?.customer ?? undefined,
                  type: 'stripe',
                  billing_details: {
                    address: tmpPaymentMethodNew?.billing_details?.address,
                    name: tmpPaymentMethodNew?.billing_details?.name,
                    email: tmpPaymentMethodNew?.billing_details?.email,
                    phone: tmpPaymentMethodNew?.billing_details?.phone,
                  },
                  card: {
                    brand: tmpPaymentMethodNew?.card?.brand,
                    country: tmpPaymentMethodNew?.card?.country,
                    exp_month: tmpPaymentMethodNew?.card?.exp_month,
                    exp_year: tmpPaymentMethodNew?.card?.exp_year,
                    last4: tmpPaymentMethodNew?.card?.last4,
                  },
                } as IPaymentMethod;
                this.setState(
                  {
                    newPaymentMethod,
                  },
                  () => {
                    this.getDonationSummary();
                    this.setState({
                      donationLoading: false,
                    });
                  },
                );
              });
          }
        } else {
          this.setState({
            donationLoading: false,
            fundError: true,
          });

          this.showError('Please select a fund.');
        }
      },
    );
  }

  // pm is PaymentMethod
  async createIntent(pm: any) {
    if (!this.state.tipsEnabled) {
      this.setState({
        tipAmount: 0,
        customTipAmount: 0,
      });
    }

    if (pm.error) {
      this.showError(pm.error.message, true);

      if (pm.error.code === 'incomplete_number') {
        if (this.state.paymentMethods.length > 0 && this.state.selectedPaymentMethod >= 0) {
          this.setOverlay('payment-method');
        } else {
          this.setOverlay('new-cc');
        }
      }

      this.setState({
        donationLoading: false,
      });
    } else if (pm.paymentMethod || pm.payment_method_id) {
      let _method: IDonationRequest = this.getPaymentPayload(
        pm.paymentMethod ? pm.paymentMethod : pm,
      );

      // if the user is not logged in
      if (this.props.userState.isLoggedIn === false) {
        _method.donor.language = this.props.userState.loggedOutLanguage;
      }

      this.setState({
        paymentMethod: pm.paymentMethod ? pm.paymentMethod : pm,
      });

      let _parentId: string = this.props.owner.ownerId;

      if (this.state.selectedDonationMatchingProgram !== null) {
        let program: IAvailableDonationMatchingProgram | undefined = this.getSelectedProgram();
        if (program) {
          _method.donation_match = {
            object_id: program.owner_id,
            donation_matching_program_id: program.id,
            params: { amount_matched: this.state.matchedAmount },
          };
        }
      }

      if (this.props.match.params.type === Constants.object_type.group) {
        if (this.state.donationType === 'once') {
          try {
            let response = await axios.post(
              swapRouteParams(routes.CREATE_GROUP_DONATION_INTENT, { groupId: _parentId }),
              _method,
            );
            return response.data;
          } catch (error) {
            const errorObj = errorHelpers.getErrorObject(error);
            this.showError(errorObj.translatedMessage, true, { errorCode: errorObj.errorCode });
          }
        } else if (this.state.donationType === 'monthly') {
          try {
            let response = await axios.post(
              swapRouteParams(routes.CREATE_GROUP_DONATION_SUBSCRIPTION_INTENT, {
                groupId: _parentId,
              }),
              _method,
            );
            return { amounts: { ...response.data } };
          } catch (error) {
            const errorObj = errorHelpers.getErrorObject(error);
            this.showError(errorObj.translatedMessage, true, { errorCode: errorObj.errorCode });
          }
        }
      } else if (this.props.match.params.type === Constants.object_type.event) {
        if (this.state.donationType === 'once') {
          try {
            let response = await axios.post(
              swapRouteParams(routes.CREATE_EVENT_DONATION_INTENT, { eventId: _parentId }),
              _method,
            );
            return response.data;
          } catch (error) {
            const errorObj = errorHelpers.getErrorObject(error);
            this.showError(errorObj.translatedMessage, true, { errorCode: errorObj.errorCode });
          }
        } else if (this.state.donationType === 'monthly') {
          try {
            let response = await axios.post(
              swapRouteParams(routes.CREATE_EVENT_DONATION_SUBSCRIPTION_INTENT, {
                eventId: _parentId,
              }),
              _method,
            );
            return { amounts: { ...response.data } };
          } catch (error) {
            const errorObj = errorHelpers.getErrorObject(error);
            this.showError(errorObj.translatedMessage, true, { errorCode: errorObj.errorCode });
          }
        }
      }
    }
  }

  hasManagePermission() {
    const allowedPermissions = Object.keys(this.props.userPermissions).filter(
      (k) => this.props.userPermissions[k],
    );
    return allowedPermissions.some((allowed) => GroupManagePermissions.includes(allowed));
  }

  showError(error: string, hasBeenTranslated?: boolean, options?: { errorCode?: string | null }) {
    const isAdmin = this.hasManagePermission();
    donationShowError({
      error,
      hasBeenTranslated,
      isAdmin,
      owner: this.props.owner,
      options,
      fireToast: (toast) => {
        this.props.createToast(toast);
      },
    });
  }

  submitDonation() {
    this.setState(
      {
        donationLoading: true,
      },
      async () => {
        if (!this.state.tipsEnabled) {
          this.setState({
            tipAmount: 0,
            customTipAmount: 0,
          });
        }

        if (this.state.donationType === 'once') {
          let intent = await this.createIntent(
            this.state.paymentMethods[this.state.selectedPaymentMethod]
              ? this.state.paymentMethods[this.state.selectedPaymentMethod]
              : this.state.newPaymentMethod,
          );

          if (!intent) {
            this.setState({ donationLoading: false });
            return;
          }

          let payload = { payment_method: intent.payment_method_id };

          this.props.stripe
            ?.confirmCardPayment(intent.client_secret, payload)
            .then((response: any) => {
              if (response.error) {
                this.showError(response.error.message + 'Reason: ' + response.error.decline_code);

                this.setState({
                  donationLoading: true,
                });
              } else {
                this.redirect();
              }
            });
        } else if (this.state.donationType === 'monthly') {
          let _payload: ISubscriptionRequest = this.getPaymentPayload(
            this.state.paymentMethods[this.state.selectedPaymentMethod]
              ? this.state.paymentMethods[this.state.selectedPaymentMethod]
              : this.state.newPaymentMethod!,
          );

          axios
            .post(
              swapRouteParams(routes.SUBMIT_GROUP_SUBSCRIPTION, {
                groupId: this.props.owner.ownerId,
              }),
              _payload,
            )
            .then(() => {
              this.redirect();
            })
            .catch((error) => {
              this.showError(
                localizeHelpers.translate(
                  error.response.data.gigitErrorCode || 'Something went wrong. Please try again.',
                ),
              );
            })
            .finally(() => {
              this.setState({
                donationLoading: false,
              });
            });
        } else if (this.state.donationType === 'volunteer-for-dollars') {
          this.donateWithCredits();
        }
      },
    );
  }

  redirect() {
    const toast = toastSuccess(this.getTranslatedSuccessMessage(), 'Donation Successful');
    this.props.createToast(toast);

    if (this.state.isEmbed) {
      this.setState({
        showEmbedComplete: true,
      });
    } else {
      let pathname = `/${this.props.owner.ownerType}/${this.props.owner.ownerHandle}`;
      if (this.props.owner.child) {
        pathname += `/${this.props.owner.child.ownerType}/${this.props.owner.child.ownerHandle}`;
      }
      this.props.history.replace({ pathname });
    }
  }

  getTranslatedSuccessMessage() {
    const onceMessage = "You've successfully donated to {{donation_target}}";
    const monthlyMessage = "You've successfully set up a monthly donation to {{donation_target}}";
    const volunteerForDollarsMessage =
      "You've successfully donated volunteer credits for {{donation_target}}";
    const messageTemplate =
      this.state.donationType === 'once'
        ? onceMessage
        : this.state.donationType === 'monthly'
          ? monthlyMessage
          : volunteerForDollarsMessage;

    return localizeHelpers.translate(messageTemplate, {
      donation_target: this.props.owner.ownerTitle,
    });
  }

  // TODO: Add a proper interface
  getPaymentPayload(pm: any) {
    let _payload: any = {
      payment_method_id: pm.id || pm.payment_method_id,
      payment_method_country: pm.card.country,
      payment_method: 'card',
      donor: {
        user_type: this.state.donorType === 'personal' ? 'person' : 'corporation',
        email: this.state.email,
        phone: this.state.phone,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
      },
      address: {
        line1: this.state.address,
        line2: this.state.address2,
        city: this.state.city,
        state: this.state.province,
        country: this.state.country,
        postal_code: this.state.postal,
      },
      anonymous: this.state.anonymous,
      tax_receipt: true,
      amount: this.getDonationAmount(),
      allow_marketing_notifications: this.state.allowMarketingNotifications === 'yes',
      tip: {
        amount:
          this.state.tipAmount !== -1
            ? this.state.tipAmount
            : parseFloat(this.state.customTipAmount.toString()),
        amount_type: 'fixed',
      },
    };

    if (this.state.paymentMethods.length > 0 && this.state.selectedPaymentMethod >= 0) {
      _payload.payment_customer_id = pm.customer_id;
    }

    if (
      this.state.donorType === 'corporate' ||
      (this.state.donorType === 'personal' && this.state.useOnlineHandle)
    ) {
      _payload.donor.display_name = this.state.displayName;
    }

    if (this.state.message !== '') {
      _payload.comments = this.state.message;
    }

    if (this.props.match.params.subType) {
      switch (this.props.match.params.subType) {
        case 'individual':
          _payload.individual_handle = this.props.match.params.subHandle;
          break;
        case 'team':
          _payload.team_handle = this.props.match.params.subHandle;
          break;
        default:
          //
          break;
      }
    }

    if (this.state.P2PValue && this.state.P2PValue.type !== Constants.object_type.event) {
      if (this.state.P2PValue.type === 'team') {
        _payload.team_handle = this.state.P2PValue.item.handle;
      } else if (this.state.P2PValue.type === 'individual') {
        _payload.individual_handle = this.state.P2PValue.item.user.handle;
      }
    }

    for (let f in this.state.funds) {
      if (this.state.funds[f].value === this.state.fund && this.state.fund !== '') {
        _payload.fund = this.state.funds[f].label;
        break;
      }
    }

    if (this.state.hFirst !== '' && this.state.hLast !== '') {
      _payload.dedicate = {
        type: this.state.dedicateType === 'memory' ? 'in_memory_of' : 'on_behalf_of',
        first_name: this.state.hFirst,
        last_name: this.state.hLast,
        recepient: {
          first_name: this.state.rFirst,
          last_name: this.state.rLast,
          message: this.state.rMessage,
        },
      };

      if (this.state.rEmail !== '') {
        _payload.dedicate.recepient.email = this.state.rEmail;
      }
    }

    if (this.state.selectedGroupDelegation) {
      _payload.delegate_group_id = this.state.selectedGroupDelegation.group_id;
    }

    return _payload;
  }

  getDonationAmount() {
    return this.state.donationAmount !== -1
      ? this.state.donationAmount
      : parseFloat(this.state.customDonationAmount.toString());
  }

  fetchGroupDelegations() {
    if (this.props.hubOwnerGroup) {
      const parentType = this.props.match.params.type;

      this.props.getGroupDelegations(
        Constants.object_type.group,
        this.props.hubOwnerGroup.id,
        undefined,
        this.state.postal,
        {
          callback: () => {
            const matchingGroupDelegation = this.props.groupDelegations.find(
              (groupDelegation) => groupDelegation.is_postal_code_match,
            );
            this.setState({
              selectedGroupDelegation: matchingGroupDelegation ?? null,
            });
          },
        },
      );
    }
  }

  jumpToSection(sectionName: string) {
    const element = this.formSectionRefLookup.current[sectionName];
    if (element) {
      element.scrollIntoView();
    }

    if (this.state.overlay) {
      this.setState({
        overlay: '',
      });
    }
  }

  setSectionRef(sectionName: string, refObj: HTMLDivElement | null) {
    this.formSectionRefLookup.current[sectionName] = refObj;
  }

  validateP2PValue = (error: string | null) => {
    this.setState({
      P2PValueError: error,
    });
  };

  getDonationDelegationName() {
    if (this.state.P2PValue.item) {
      switch (this.state.P2PValue.type) {
        case Constants.pages_type.team:
          return this.state.P2PValue.item.name;
        case Constants.pages_type.individual:
          return this.state.P2PValue.item.user?.display_name;
      }
    }
    return null;
  }

  renderTipSection(currency: string, tipMessageTemplate: JSX.Element) {
    if (!this.state.tipsEnabled) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="sub-title tip">Tip</div>
        <div className="label">Platform Tip Amount</div>
        <div className="options single-line">
          {this.state.tipAmounts.map((item, index) => {
            if (item !== -1) {
              return (
                <div
                  key={index}
                  onClick={() => {
                    this.setState({ tipAmount: item, customTipAmount: 0 });
                  }}
                  className={item === this.state.tipAmount ? 'option active' : 'option'}
                >
                  <div
                    className="option-inner"
                    notranslate="yes"
                    style={
                      item === this.state.tipAmount && this.state.embedDefaultColours.length === 3
                        ? { border: '2px solid #' + this.state.embedDefaultColours[2] }
                        : {}
                    }
                  >
                    <span>{formatCurrency(item, currency, this.props.locale)}</span>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  onClick={() => {
                    this.setState({ tipAmount: item });
                  }}
                  className={item === this.state.tipAmount ? 'option other active' : 'option other'}
                >
                  <div
                    className="option-inner"
                    style={
                      item === this.state.tipAmount && this.state.embedDefaultColours.length === 3
                        ? { backgroundColor: '#' + this.state.embedDefaultColours[2] }
                        : {}
                    }
                  >
                    $
                  </div>
                  <TextField
                    min="0"
                    placeholder="Other"
                    value={this.state.customTipAmount || ''}
                    name="customTipAmount"
                    type="number"
                    onChange={(e) =>
                      handleInputChange(e, this, false, () => {
                        if (this.state.tipAmount !== -1) {
                          this.setState({ tipAmount: -1 });
                        }
                      })
                    }
                    required={this.state.tipAmount === -1}
                    style={
                      item === this.state.tipAmount && this.state.embedDefaultColours.length === 3
                        ? { border: '1px solid #' + this.state.embedDefaultColours[2] }
                        : {}
                    }
                  />
                </div>
              );
            }
          })}
        </div>
        <div className="tip-msg">{tipMessageTemplate}</div>
      </React.Fragment>
    );
  }

  getStateOptions() {
    let stateOptions;
    switch (this.state.country) {
      case 'CA':
        stateOptions = localeConstants.canadianProvinceOptions;
        break;
      case 'US':
        stateOptions = localeConstants.usStateOptions;
        break;
      case 'GB':
        stateOptions = localeConstants.ukCountyOptions;
        break;
      case 'DE':
        stateOptions = localeConstants.deStateOptions;
        break;
      default:
        stateOptions = [{ value: 'N/A', label: 'N/A' }];
        break;
    }
    return stateOptions;
  }

  render() {
    // const currency = this.props.owner.account?.currency ?? defaultCurrency;
    let currency = defaultCurrency;

    if (this.props.owner?.ownerType === 'group' && !this.props.owner?.account) {
      const group: IGroup = this.props.owner.object as IGroup;
      if (group.external_id?.Change) {
        currency = 'usd';
      } else {
        currency = this.props.owner?.account?.currency ?? defaultCurrency;
      }
    } else {
      currency = this.props.owner?.account?.currency ?? defaultCurrency;
    }

    const minimumTaxReceiptMessage =
      this.props.minimumTaxReceiptAmount && this.props.autoSendTaxReceipts
        ? localizeHelpers.translate(
            'Tax Receipts will be automatically issued for donations equal to or greater than {{minimum_tax_receipt_amount}}',
            {
              minimum_tax_receipt_amount: formatCurrency(
                this.props.minimumTaxReceiptAmount,
                currency,
                this.props.locale,
              ),
            },
          )
        : '';
    const group = typeHelpers.getGroupFromOwner(this.props.owner);
    const tipMessageTemplate = (
      <Fragment>
        <var data-var="app_name">{uiConstants.appName} </var>
        provides a free fundraising, donation and volunteer management solution for non-profits. We
        rely on tips from donors to continue to provide this service. Please consider adding a tip
        to your donation! The transaction fee is a third party charge to process the credit card.
      </Fragment>
    );

    const isDonateWithVolunteerCreditsDisabled =
      this.state.donationType === 'volunteer-for-dollars' &&
      (this.state.userCreditsBalanceForGroup < this.state.donationAmount ||
        this.state.userCreditsBalanceForGroup === 0);

    return (
      <div
        ref={this.state.formRef}
        className={this.state.overlay === '' ? 'DonateForm' : 'DonateForm overlay'}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (this.state.donationType === 'volunteer-for-dollars') {
              this.donateWithCredits();
            } else {
              this.setupDonation();
            }
          }}
        >
          <div className="DonateForm-inner">
            {this.props?.warning?.show && (
              <div className="donate-warning">
                <i className="fas fa-exclamation-triangle" />
                <p>{this.props?.warning?.text}</p>
              </div>
            )}

            {this.props.isP2P && (
              <React.Fragment>
                <div className="sub-title">Direct my Donation</div>
                <P2PSelector
                  owner={this.props.owner}
                  validateP2PValue={(error) => this.validateP2PValue(error)}
                  onP2PSelect={(selection: any) => {
                    this.setState({ P2PValue: selection });
                  }}
                />
              </React.Fragment>
            )}
            <div className="sub-title">Donation</div>
            {typeHelpers.getGroupFromOwner(this.props.owner)?.allow_subscriptions && (
              <React.Fragment>
                <div className="label">Donation Type</div>
                <div className="options single-line donation-type">
                  <div
                    onClick={() => {
                      this.setState({ donationType: 'once' });
                    }}
                    className={this.state.donationType === 'once' ? 'option active' : 'option'}
                  >
                    <div
                      className="option-inner"
                      style={
                        this.state.donationType === 'once' &&
                        this.state.embedDefaultColours.length === 3
                          ? { border: '2px solid #' + this.state.embedDefaultColours[2] }
                          : {}
                      }
                    >
                      <span>One-time Donation</span>
                    </div>
                  </div>

                  {this.props.match.params.type === 'group' && !this.props.match.params.subType && (
                    <div
                      onClick={() => {
                        this.setState({ donationType: 'monthly' });
                      }}
                      className={this.state.donationType === 'monthly' ? 'option active' : 'option'}
                    >
                      <div
                        className="option-inner"
                        style={
                          this.state.donationType === 'monthly' &&
                          this.state.embedDefaultColours.length === 3
                            ? { border: '2px solid #' + this.state.embedDefaultColours[2] }
                            : {}
                        }
                      >
                        <span>Monthly Donation</span>
                      </div>
                    </div>
                  )}
                  {this.props.match.params.type === 'group' &&
                    this.props.userState.isLoggedIn &&
                    this.state.userHasVolunteerForDollarsProgram && (
                      <div
                        onClick={() => {
                          this.setState({
                            donationType: 'volunteer-for-dollars',
                            donationAmount: this.state.userCreditsBalanceForGroup,
                          });
                        }}
                        className={`option ${
                          this.state.donationType === 'volunteer-for-dollars' ? 'active' : ''
                        }`}
                      >
                        <div
                          className="option-inner"
                          style={
                            this.state.donationType === 'volunteer-for-dollars' &&
                            this.state.embedDefaultColours.length === 3
                              ? { border: '2px solid #' + this.state.embedDefaultColours[2] }
                              : {}
                          }
                        >
                          <span>Volunteer Credits</span>
                        </div>
                      </div>
                    )}
                </div>
              </React.Fragment>
            )}
            {(this.state.donationType === 'once' || this.state.donationType === 'monthly') && (
              <React.Fragment>
                <div className="label">Donation Amount</div>
                <div
                  className={
                    this.props.useDonationDescriptions
                      ? 'options single-line no-tip with-descriptions'
                      : 'options single-line no-tip'
                  }
                >
                  {this.state.donationAmounts.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          this.setState({ donationAmount: item.amount, customDonationAmount: 0 });
                        }}
                        className={
                          item.amount === this.state.donationAmount ? 'option active' : 'option'
                        }
                      >
                        <div
                          className="option-inner"
                          notranslate="yes"
                          style={
                            item.amount === this.state.donationAmount &&
                            this.state.embedDefaultColours.length === 3
                              ? { border: '2px solid #' + this.state.embedDefaultColours[2] }
                              : {}
                          }
                        >
                          <span>{formatCurrency(item.amount, currency, this.props.locale)}</span>
                          {this.props.useDonationDescriptions && <span>{item.description}</span>}
                        </div>
                      </div>
                    );
                  })}

                  <div
                    onClick={() => {
                      this.setState({ donationAmount: -1 });
                    }}
                    className={
                      this.state.donationAmount === -1 ? 'option other active' : 'option other'
                    }
                  >
                    <div
                      className="option-inner"
                      style={
                        this.state.customDonationAmount &&
                        this.state.embedDefaultColours.length === 3
                          ? { backgroundColor: '#' + this.state.embedDefaultColours[2] }
                          : {}
                      }
                    >
                      $
                    </div>
                    <TextField
                      min="5"
                      value={this.state.customDonationAmount || ''}
                      placeholder="Other"
                      name="customDonationAmount"
                      type="number"
                      onChange={(e) => handleInputChange(e, this)}
                      required={this.state.donationAmount === -1}
                      style={
                        this.state.customDonationAmount === this.state.donationAmount &&
                        this.state.embedDefaultColours.length === 3
                          ? { border: '1px solid #' + this.state.embedDefaultColours[2] }
                          : {}
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            )}

            {this.state.donationType !== 'volunteer-for-dollars' &&
              this.state.donationMatchingPrograms.length > 0 && (
                <React.Fragment>
                  <div className="sub-title">Donation Matching</div>
                  <div className="label">Donation Matching Program</div>
                  <Dropdown
                    value={this.state.selectedDonationMatchingProgram}
                    onChange={(e) => {
                      this.setState({
                        selectedDonationMatchingProgram: this.state.donationMatchingPrograms.find(
                          (program) => program.id === e.target.value,
                        )!,
                      });
                    }}
                    name="selectedDonationMatchingProgram"
                    options={this.getDonationMatchingProgramOptions()}
                  />
                  {this.getSelectedProgram() && (
                    <DonationMatchingProgramDetails
                      currency={currency}
                      program={this.getSelectedProgram()!}
                      donationAmount={this.getDonationAmount() || 0}
                      matchedAmount={this.state.matchedAmount}
                      setMatchedAmount={(v) => this.setState({ matchedAmount: v })}
                    />
                  )}
                </React.Fragment>
              )}
            {this.state.donationType === 'volunteer-for-dollars' && (
              <VolunteerForDollarsDetails
                currency={currency}
                groupId={group?.id!}
                onVolunteerCreditsChange={(v) => {
                  this.setState({ donationAmount: v });
                }}
              />
            )}
            {this.renderTipSection(currency, tipMessageTemplate)}
            {typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'group', 'event')
              ?.allow_donation_fund_selection &&
              this.props.campaign &&
              this.props.campaign.funds?.length > 0 && (
                <React.Fragment>
                  <div className="sub-title">Fund</div>
                  <div className={this.state.fundError ? 'funds error' : 'funds'}>
                    <Dropdown
                      shouldSort={true}
                      value={this.state.fund}
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                      name="fund"
                      options={this.state.funds}
                    />
                  </div>
                  <div className="tip-msg">
                    Select a fund to tell the organization where you would most prefer your donation
                    to be used.
                  </div>
                </React.Fragment>
              )}
            {this.state.donationType !== 'volunteer-for-dollars' && (
              <Fragment>
                <div
                  className="sub-title"
                  ref={(refObj) => this.setSectionRef('donor_details', refObj)}
                >
                  Donor Details
                </div>
                <div className="label">Donor Type</div>
                <div className="options single-line donor-type">
                  <div
                    onClick={() => {
                      this.setState({ donorType: 'personal', displayName: '' });
                    }}
                    className={this.state.donorType === 'personal' ? 'option active' : 'option'}
                  >
                    <div
                      className="option-inner"
                      style={
                        this.state.donorType === 'personal' &&
                        this.state.embedDefaultColours.length === 3
                          ? { border: '2px solid #' + this.state.embedDefaultColours[2] }
                          : {}
                      }
                    >
                      <span>Personal</span>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.setState({ donorType: 'corporate', displayName: '' });
                    }}
                    className={this.state.donorType === 'corporate' ? 'option active' : 'option'}
                  >
                    <div
                      className="option-inner"
                      style={
                        this.state.donorType === 'corporate' &&
                        this.state.embedDefaultColours.length === 3
                          ? { border: '2px solid #' + this.state.embedDefaultColours[2] }
                          : {}
                      }
                    >
                      <span>Corporate Group</span>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
            {this.state.donationType !== 'volunteer-for-dollars' && (
              <Fragment>
                {this.props.owner.child?.isContentCreator &&
                  this.state.donorType === 'personal' && (
                    <div className="form-inputs">
                      <Checkbox
                        name="useOnlineHandle"
                        label="Use an online handle instead of my name."
                        value="useOnlineHandle"
                        checked={this.state.useOnlineHandle}
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                  )}
                {this.state.useOnlineHandle && this.state.donorType === 'personal' && (
                  <div className="form-inputs">
                    <TextField
                      label="Online Handle"
                      required={true}
                      value={this.state.displayName}
                      name="displayName"
                      type="text"
                      onChange={(e) => handleInputChange(e, this)}
                    />
                  </div>
                )}
                {this.state.donorType === 'personal' && !this.state.useOnlineHandle && (
                  <div className="form-inputs">
                    <TextField
                      label="First Name"
                      required={true}
                      value={this.state.firstName}
                      name="firstName"
                      type="text"
                      onChange={(e) => handleInputChange(e, this)}
                    />
                    <TextField
                      label="Last Name"
                      required={true}
                      value={this.state.lastName}
                      name="lastName"
                      type="text"
                      onChange={(e) => handleInputChange(e, this)}
                    />
                  </div>
                )}
                {this.state.donorType === 'corporate' && (
                  <div className="form-inputs">
                    <TextField
                      label="Company Name"
                      required={true}
                      value={this.state.displayName}
                      name="displayName"
                      type="text"
                      onChange={(e) => handleInputChange(e, this)}
                    />
                  </div>
                )}
                <div className="form-inputs">
                  <TextField
                    label="Email"
                    required={true}
                    value={this.state.email}
                    name="email"
                    type="email"
                    onChange={(e) => handleInputChange(e, this)}
                  />
                  <TextField
                    label="Phone"
                    required={true}
                    value={this.state.phone}
                    name="phone"
                    type="tel"
                    onChange={(e) => handleInputChange(e, this)}
                  />
                </div>
                <div className="form-inputs">
                  <TextField
                    label="Address"
                    required={true}
                    value={this.state.address}
                    name="address"
                    type="text"
                    onChange={(e) => handleInputChange(e, this)}
                  />
                </div>
                <div className="form-inputs">
                  <TextField
                    label="Address Line 2"
                    required={false}
                    value={this.state.address2}
                    name="address2"
                    type="text"
                    onChange={(e) => handleInputChange(e, this)}
                  />
                </div>
                <div className="form-inputs">
                  <Dropdown
                    shouldSort={true}
                    value={this.state.country}
                    notranslate="yes"
                    label="Country"
                    placeholder="Select Country"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                    name="country"
                    options={localeConstants.allCountryOptions}
                  />
                  {localeConstants.supportedCountries.includes(this.state.country) && (
                    <Dropdown
                      value={this.state.province}
                      label="Province/State/County"
                      placeholder="Select state"
                      notranslate="yes"
                      shouldSort={true}
                      onChange={(e) => {
                        handleInputChange(e, this);
                      }}
                      name="province"
                      options={this.getStateOptions()}
                    />
                  )}
                </div>
                <div className="form-inputs">
                  <TextField
                    label="City"
                    required={true}
                    value={this.state.city}
                    name="city"
                    type="text"
                    onChange={(e) => handleInputChange(e, this)}
                  />
                  <TextField
                    label="Postal/ZIP Code"
                    required={true}
                    value={this.state.postal}
                    name="postal"
                    type="text"
                    onChange={(e) => handleInputChange(e, this)}
                  />
                </div>
                <div
                  className={
                    this.state.message.length >= 260 ? 'form-inputs long-message' : 'form-inputs'
                  }
                >
                  <TextField
                    label={localizeHelpers.translate('Message ({{message_length}}/280)', {
                      message_length: this.state.message.length,
                    })}
                    translateLabel={false}
                    value={this.state.message}
                    name="message"
                    type="text"
                    maxLength={280}
                    onChange={(e) => handleInputChange(e, this)}
                  />
                </div>
              </Fragment>
            )}

            {this.state.donationType !== 'volunteer-for-dollars' && (
              <Fragment>
                <div className="sub-title dedicate-title">Dedicate My Donation</div>
                <div
                  onClick={() => {
                    this.setOverlay('dedicate');
                  }}
                  className="row-overlay no-select"
                >
                  <div>
                    <i className="far fa-hands-heart" />
                    <span>Dedicate my Donation</span>
                    <span className="optional">(optional)</span>
                  </div>
                  {this.state.receiptFullName === '' && <i className="far fa-plus" />}
                  {this.state.receiptFullName !== '' && <i className="fal fa-angle-right" />}
                </div>
              </Fragment>
            )}
            {this.state.donationType !== 'volunteer-for-dollars' && (
              <Fragment>
                <div
                  className="sub-title anonymous-title"
                  ref={(refObj) => this.setSectionRef('anonymity', refObj)}
                >
                  Anonymity and Contact Preferences
                </div>
                <div className="form-inputs">
                  <Dropdown
                    label="Please select whether or not you would like the organization you are supporting to contact you in the future."
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                    name="allowMarketingNotifications"
                    required={true}
                    value={this.state.allowMarketingNotifications}
                    options={anonymityOptions}
                  />
                </div>
                <div className="form-inputs">
                  <Checkbox
                    className="anonymous"
                    name="anonymous"
                    onChange={(e) => {
                      handleInputChange(e, this);
                    }}
                    checked={this.state.anonymous}
                    value={'anonymous'}
                    color="work"
                    label="Make my donation anonymous."
                  />
                </div>
              </Fragment>
            )}

            {this.state.donationType !== 'volunteer-for-dollars' && (
              <Fragment>
                <div className="sub-title dedicate-title">Payment</div>
                <PaymentMethodPreview
                  onClickContainer={() => {
                    this.props.userState.isLoggedIn &&
                    (this.state.paymentMethods.length > 0 || this.state.paymentRequest)
                      ? this.setOverlay('payment-method')
                      : this.setOverlay('new-cc');
                  }}
                  paymentMethod={
                    this.state.paymentMethod ||
                    this.state.paymentMethods[this.state.selectedPaymentMethod]
                  }
                  altPaymentMethod={
                    this.state.supportedAltPaymentMethods[this.state.selectedAltPaymentMethod]
                  }
                  isUsingAlternativePaymentMethod={this.state.isUsingAlternativePaymentMethod}
                />
              </Fragment>
            )}

            {this.props.allowDonationDelegation &&
              this.state.donationType !== 'volunteer-for-dollars' && (
                <React.Fragment>
                  <div
                    className="sub-title anonymous-title"
                    ref={(refObj) => this.setSectionRef('group_selection', refObj)}
                  >
                    Associated Group Selection
                  </div>
                  <SearchableDropdown
                    label="Select the group associated with the national organization to direct your donation towards."
                    list={this.props.groupDelegations.map((groupDelegation) => ({
                      id: groupDelegation.group_id,
                      label: groupDelegation.title!,
                    }))}
                    selectedItem={
                      this.state.selectedGroupDelegation
                        ? {
                            id: this.state.selectedGroupDelegation.group_id,
                            label: this.state.selectedGroupDelegation.title!,
                          }
                        : null
                    }
                    onSearch={() => {}}
                    setSelectedItem={(newSelectedItem) =>
                      this.setState({
                        selectedGroupDelegation:
                          this.props.groupDelegations.find(
                            (delegation) => delegation.group_id === newSelectedItem?.id,
                          ) ?? null,
                      })
                    }
                  />
                </React.Fragment>
              )}

            {this.props.donationCheckoutMessage && (
              <div className="warning-message">
                <i className="fal fa-exclamation-triangle" />
                <QuillTextEditor
                  value={this.props.donationCheckoutMessage}
                  readOnly={true}
                  preserveWhitespace={true}
                  theme={uiConstants.quill.readOnlyTheme}
                  modules={{
                    toolbar: [],
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                  formats={[]}
                />
              </div>
            )}

            {minimumTaxReceiptMessage && (
              <div className="warning-message non-quil">
                <i className="fal fa-exclamation-triangle" />
                {minimumTaxReceiptMessage}
              </div>
            )}
          </div>

          <div className="DonateForm-actions">
            <Button
              isDisabled={this.props?.warning?.show || isDonateWithVolunteerCreditsDisabled}
              onMouseEnter={() => this.setState({ reviewHover: true })}
              onMouseLeave={() => this.setState({ reviewHover: false })}
              loading={this.state.donationLoading}
              type="submit"
              onClick={() => this.setOverlay('')}
              text={
                this.state.donationType === 'volunteer-for-dollars' ? 'Donate' : 'Review Donation'
              }
              buttonType="dark"
            />
            {this.props?.warning?.show && (
              <span className="donate-warning">
                <i className="fas fa-exclamation-triangle" />
              </span>
            )}
          </div>
        </form>
        <div
          className={
            this.state.overlay === 'dedicate'
              ? 'dedicate overlay active'
              : 'fal fa-angle-left overlay-back back-hidden'
          }
        >
          <form
            className="overlay-inner"
            onSubmit={(e) => {
              e.preventDefault();
              this.setOverlay('');
            }}
          >
            <div className="input-wrapper">
              <div className="sub-title">Dedicate my Donation</div>
              <div className="options single-line">
                <div
                  onClick={() => {
                    this.setState({ dedicateType: 'honour' });
                  }}
                  className={this.state.dedicateType === 'honour' ? 'option active' : 'option'}
                >
                  <div
                    className="option-inner"
                    style={
                      this.state.dedicateType === 'honour' &&
                      this.state.embedDefaultColours.length === 3
                        ? { border: '2px solid #' + this.state.embedDefaultColours[2] }
                        : {}
                    }
                  >
                    <span>In honour of...</span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    this.setState({ dedicateType: 'memory' });
                  }}
                  className={this.state.dedicateType === 'memory' ? 'option active' : 'option'}
                >
                  <div
                    className="option-inner"
                    style={
                      this.state.dedicateType === 'memory' &&
                      this.state.embedDefaultColours.length === 3
                        ? { border: '2px solid #' + this.state.embedDefaultColours[2] }
                        : {}
                    }
                  >
                    <span>In memory of...</span>
                  </div>
                </div>
              </div>
              <div className="form-inputs">
                <TextField
                  label="Honoree's First Name"
                  required={true}
                  value={this.state.hFirst}
                  name="hFirst"
                  type="text"
                  onChange={(e) => handleInputChange(e, this)}
                />
                <TextField
                  label="Honoree's Last Name"
                  required={true}
                  value={this.state.hLast}
                  name="hLast"
                  type="text"
                  onChange={(e) => handleInputChange(e, this)}
                />
              </div>
              <div className="form-inputs">
                <TextField
                  label="Recipient's First Name"
                  required={false}
                  value={this.state.rFirst}
                  name="rFirst"
                  type="text"
                  onChange={(e) => handleInputChange(e, this)}
                />
                <TextField
                  label="Recipient's Last Name"
                  required={false}
                  value={this.state.rLast}
                  name="rLast"
                  type="text"
                  onChange={(e) => handleInputChange(e, this)}
                />
              </div>
              <div className="form-inputs">
                <TextField
                  label="Recipient's Email"
                  required={false}
                  value={this.state.rEmail}
                  name="rEmail"
                  type="email"
                  onChange={(e) => handleInputChange(e, this)}
                />
              </div>
              <div className="form-inputs">
                <TextField
                  label="Message to recipient"
                  required={false}
                  value={this.state.rMessage}
                  name="rMessage"
                  type="text"
                  onChange={(e) => handleInputChange(e, this)}
                />
              </div>
            </div>
            <div className="overlay-actions">
              <Button
                onMouseEnter={() => {
                  this.setState({ continueHover: true });
                }}
                onMouseLeave={() => {
                  this.setState({ continueHover: false });
                }}
                type="submit"
                text="Continue"
                buttonType="dark"
              />
              <Button
                onMouseEnter={() => {
                  this.setState({ backHover: true });
                }}
                onMouseLeave={() => {
                  this.setState({ backHover: false });
                }}
                onClick={() => {
                  this.setOverlay('');
                  this.setState({
                    honorFullName: '',
                    receiptFullName: '',
                    receiptEmail: '',
                    receiptMessage: '',
                  });
                }}
                text="Back"
                buttonType="outline-dark"
              />
            </div>
          </form>
        </div>
        {this.state.overlay === 'payment-method' && (
          <PickPaymentMethod
            paymentRequest={this.state.paymentRequest}
            selectedPaymentMethodIndex={this.state.selectedPaymentMethod}
            selectedPaymentMethod={this.state.paymentMethods[this.state.selectedPaymentMethod]}
            openAddNewPaymentMethod={() => {
              this.setState({ selectedPaymentMethod: -1, isUsingAlternativePaymentMethod: false });
              this.setOverlay('new-cc');
            }}
            isUsingAlternativePaymentMethod={this.state.isUsingAlternativePaymentMethod}
            className={this.state.overlay === 'payment-method' ? 'active' : ''}
            onSubmit={() => this.setOverlay('')}
            onBack={() => this.setOverlay('')}
            onMethodSelect={(item, index) => {
              this.setState({
                selectedPaymentMethod: index,
                selectedAltPaymentMethod: -1,
                isUsingAlternativePaymentMethod: false,
              });
            }}
            onAltMethodSelect={(item, index) => {
              this.setState({
                isUsingAlternativePaymentMethod: true,
                selectedAltPaymentMethod: index,
                selectedPaymentMethod: -1,
              });
            }}
          />
        )}
        <NewPaymentMethod
          onSubmit={() => this.setOverlay('')}
          onBack={() => {
            this.setOverlay('');
            this.setState({
              honorFullName: '',
              receiptFullName: '',
              receiptEmail: '',
              receiptMessage: '',
            });
          }}
          className={
            this.state.overlay === 'new-cc'
              ? 'new-cc overlay active'
              : 'fal fa-angle-left overlay-back back-hidden'
          }
        />
        <div
          className={this.state.overlay === 'review' ? 'review overlay active' : 'review overlay'}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.submitDonation();
            }}
            className="overlay-inner"
          >
            <div className="input-wrapper">
              <div className="sub-title">Review</div>
              {this.getDonationDelegationName() && (
                <React.Fragment>
                  <div className="review-title first-title">Donation Details</div>
                  <div
                    className="review-line"
                    notranslate="yes"
                  >
                    {this.getDonationDelegationName()}
                  </div>
                </React.Fragment>
              )}
              <div className={`review-title ${this.getDonationDelegationName() ?? 'first-title'}`}>
                Donor Details{' '}
                <a
                  className="edit-link"
                  onClick={() => this.jumpToSection('donor_details')}
                >
                  Edit
                </a>
              </div>
              <div
                className="review-line"
                notranslate="yes"
              >
                {this.state.firstName} {this.state.lastName}
              </div>
              <div
                className="review-line"
                notranslate="yes"
              >
                {this.state.email}
              </div>
              <div
                className="review-line"
                notranslate="yes"
              >
                {this.state.phone}
              </div>
              <div
                className="review-line"
                notranslate="yes"
              >
                {this.state.address}
              </div>
              <div
                className="review-line"
                notranslate="yes"
              >
                {this.state.address2}
              </div>
              <p
                className="review-msg"
                notranslate="yes"
              >
                {this.state.message}
              </p>

              {this.state.hFirst !== '' && this.state.hLast !== '' && (
                <React.Fragment>
                  <div className="review-title">
                    Dedicate My Donation{' '}
                    <a
                      className="edit-link"
                      onClick={() => this.setOverlay('dedicate')}
                      style={
                        this.state.embedDefaultColours.length === 3
                          ? { color: '#' + this.state.embedDefaultColours[2] }
                          : {}
                      }
                    >
                      Edit
                    </a>
                  </div>
                  <div className="review-line">
                    {this.state.dedicateType === 'honour' ? 'In honour of...' : 'In memory of...'}
                  </div>
                  <div
                    className="review-line"
                    notranslate="yes"
                  >
                    {this.state.hFirst} {this.state.hLast}
                  </div>
                  <div
                    className="review-line"
                    notranslate="yes"
                  >
                    {this.state.rFirst} {this.state.rLast}
                  </div>
                  <div
                    className="review-line"
                    notranslate="yes"
                  >
                    {this.state.rEmail}
                  </div>
                  <p
                    className="review-msg"
                    notranslate="yes"
                  >
                    {this.state.rMessage}
                  </p>
                </React.Fragment>
              )}

              <div className="review-title">
                Anonymity Preference
                <a
                  className="edit-link"
                  onClick={() => this.jumpToSection('anonymity')}
                  style={
                    this.state.embedDefaultColours.length === 3
                      ? { color: '#' + this.state.embedDefaultColours[2] }
                      : {}
                  }
                >
                  Edit
                </a>
              </div>
              <div className="review-line">
                {this.state.allowMarketingNotifications === 'yes'
                  ? "Yes, I do want to be contacted by the organization(s) I'm supporting with this donation"
                  : "No, I don't want to be contacted by the organization(s) I'm supporting with this donation"}
              </div>
              <div className="review-line">
                {!this.state.anonymous
                  ? 'Please display my name, message and donation amount.'
                  : 'Please hide my name, message and donation amount.'}
              </div>

              {this.props.allowDonationDelegation && (
                <React.Fragment>
                  <div className="review-title">
                    Associated Cause Selection{' '}
                    <a
                      className="edit-link"
                      onClick={() => this.jumpToSection('group_selection')}
                      style={
                        this.state.embedDefaultColours.length === 3
                          ? { color: '#' + this.state.embedDefaultColours[2] }
                          : {}
                      }
                    >
                      Edit
                    </a>
                  </div>
                  <div
                    className="review-line"
                    notranslate="yes"
                  >
                    {this.state.selectedGroupDelegation ? (
                      this.state.selectedGroupDelegation?.title
                    ) : (
                      <span>
                        <var data-var="group_title">{this.props.owner?.ownerTitle}</var> (Default)
                      </span>
                    )}
                  </div>
                </React.Fragment>
              )}

              <div className="review-title">
                Payment{' '}
                <a
                  className="edit-link"
                  onClick={() => this.setOverlay('payment-method')}
                  style={
                    this.state.embedDefaultColours.length === 3
                      ? { color: '#' + this.state.embedDefaultColours[2] }
                      : {}
                  }
                >
                  Edit
                </a>
              </div>
              {this.state.paymentMethod && (
                <div className="review-column">
                  <div>
                    <var data-var="credit_card_brand">
                      {this.formatCardName(this.state.paymentMethod)}
                    </var>
                    ****{' '}
                    <var data-var="credit_card_last_4_digits">
                      {this.state.paymentMethod?.card?.last4 || ''}
                    </var>
                  </div>
                  <i
                    className={combineClassNames(
                      formatCardIcon(this.state.paymentMethod?.card?.brand || ''),
                      'value credit-card-brand-icon',
                    )}
                  />
                </div>
              )}
              {this.state.donation_summary !== null && (
                <React.Fragment>
                  <div className="review-column">
                    <div>Donation</div>
                    <div
                      className="value"
                      notranslate="yes"
                    >
                      {formatCurrency(this.getDonationAmount() || 0, currency, this.props.locale)}
                    </div>
                  </div>
                  {!typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'group', 'event')?.fee_control
                    ?.covers_processing_fees && (
                    <div className="review-column">
                      <div>Processing Fee</div>
                      <div
                        className="value"
                        notranslate="yes"
                      >
                        {formatCurrency(
                          this.state.donation_summary.payment_platform_fee || 0,
                          currency,
                          this.props.locale,
                        )}
                      </div>
                    </div>
                  )}
                  {!typeHelpers.tryGetOwnerObjectAs(this.props.owner, 'group', 'event')?.fee_control
                    ?.covers_gigit_fees && (
                    <div className="review-column">
                      <div>Platform Fee</div>
                      <div
                        className="value"
                        notranslate="yes"
                      >
                        {formatCurrency(
                          this.state.donation_summary.gigit_fee || 0,
                          currency,
                          this.props.locale,
                        )}
                      </div>
                    </div>
                  )}
                  {this.state.tipsEnabled && (
                    <div className="review-column">
                      <div>Tip</div>
                      <div
                        className="value"
                        notranslate="yes"
                      >
                        {formatCurrency(
                          this.state.donation_summary.tip || 0,
                          currency,
                          this.props.locale,
                        )}
                      </div>
                    </div>
                  )}
                  <div className="review-column total-title">
                    <div>Total</div>
                    <div
                      className="value"
                      notranslate="yes"
                    >
                      {formatCurrency(
                        this.state.donation_summary.total || 0,
                        currency,
                        this.props.locale,
                      )}
                    </div>
                  </div>

                  <div className="review-line summary-message">
                    <var data-var="amount">
                      {formatCurrency(this.getDonationAmount() || 0, currency, this.props.locale)}
                    </var>
                    &nbsp;of your donation will be in support of&nbsp;
                    <var data-var="donation_target">{this.props.owner.ownerTitle}</var>
                  </div>
                  {Config.feature_flags.HUB_DONATION_MATCHING && this.getSelectedProgram() && (
                    <div className="review-line summary-message">
                      <var data-var="matched">
                        {formatCurrency(this.state.matchedAmount || 0, currency, this.props.locale)}{' '}
                        will also be matched by {this.getSelectedProgram()?.hub.title}, making your
                        total contribution{' '}
                        {formatCurrency(
                          this.getDonationAmount() + this.state.matchedAmount || 0,
                          currency,
                          this.props.locale,
                        )}
                      </var>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
            <div className="overlay-actions">
              {this.state.isUsingAlternativePaymentMethod && this.state.paymentRequest ? (
                <PaymentRequestButtonElement
                  className="Button"
                  options={{
                    style: { paymentRequestButton: { type: 'donate' } },
                    paymentRequest: this.state.paymentRequest,
                  }}
                />
              ) : (
                <>
                  <Button
                    isDisabled={this.props?.warning?.show}
                    onMouseEnter={() => {
                      this.setState({ continueHover: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ continueHover: false });
                    }}
                    loading={this.state.donationLoading}
                    type="submit"
                    text="Donate"
                    buttonType="dark"
                  />
                </>
              )}
              <Button
                onMouseEnter={() => this.setState({ backHover: true })}
                onMouseLeave={() => this.setState({ backHover: false })}
                onClick={(e) => {
                  e.preventDefault();
                  this.setOverlay('');
                }}
                text="Back"
                buttonType="outline-dark"
              />
            </div>
          </form>
        </div>
        <i
          onClick={(e) => {
            this.setOverlay('');
          }}
          className={
            this.state.overlay !== ''
              ? 'fal fa-angle-left overlay-back'
              : 'fal fa-angle-left overlay-back back-hidden'
          }
        />
        {this.state.showEmbedComplete && (
          <div className="embed-complete">
            <p>Thank you for your generous donation!</p>
            {!group?.auto_tax_receipts && (
              <p>
                Tax receipts are sent by{' '}
                <Link
                  to={'/group/' + group?.handle}
                  style={
                    this.state.embedDefaultColours.length === 3
                      ? {
                          color: '#' + this.state.embedDefaultColours[2],
                        }
                      : {}
                  }
                >
                  {group?.title}
                </Link>{' '}
                and may be sent at a later date.
              </p>
            )}
            {group?.auto_tax_receipts && (
              <p>
                A confirmation email with your tax receipt will be sent to you at{' '}
                <span>{this.state.email}</span> momentarily.
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    userState: store.userState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
  getGroupDelegations,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DonateForm));
