import React, { useEffect, useState } from 'react';
import Checkbox from '../../../Checkbox/Checkbox';
import './CauseSelectionStep.scss';
import { ICause } from '@gigit/interfaces';
import useToastDispatcher from '../../../../hooks/useToaster';
import { causeRequestActions } from '../../../../requestActions';

interface ICauseSelectionState {
  isSelected: boolean;
  cause: ICause;
}
interface IProps {
  handleSelectFocusAreas: (selectedFocusAreas: ICause[]) => void;
}

export function CausesSelectionStep(props: IProps) {
  const [causeSelectionState, setCauseSelectionState] = useState<ICauseSelectionState[]>([]);
  const [isCausesLoading, setIsCausesLoading] = useState<boolean>(false);
  const { dispatchToastError } = useToastDispatcher();

  useEffect(() => {
    fetchCauses();
  }, []);

  async function fetchCauses() {
    try {
      setIsCausesLoading(true);
      const causeResponse = await causeRequestActions.getCauses();
      setCauseSelectionState(
        causeResponse.map((cause) => ({
          cause,
          isSelected: false,
        })),
      );
    } catch (error) {
      dispatchToastError(error, 'Error Getting Focus Areas');
    } finally {
      setIsCausesLoading(false);
    }
  }

  function handleSelectCause(selectedCause: ICause) {
    const updateSelectedCauseState = causeSelectionState.map((causeSelection) => {
      if (causeSelection.cause.id === selectedCause.id) {
        return {
          cause: selectedCause,
          isSelected: !causeSelection.isSelected,
        };
      }

      return causeSelection;
    });

    setCauseSelectionState(updateSelectedCauseState);
    props.handleSelectFocusAreas(
      updateSelectedCauseState
        .filter((causeState) => causeState.isSelected)
        .map((causeState) => causeState.cause),
    );
  }

  function renderCauses() {
    return causeSelectionState.map(({ cause, isSelected }) => {
      return (
        <li
          key={cause.id}
          className="cause-item"
          onClick={() => {}}
        >
          <Checkbox
            name={`cause-${cause.id}`}
            label=""
            value={cause.cause}
            checked={isSelected}
            onChange={() => handleSelectCause(cause)}
          />
          {/* TODO: Implement Icons */}
          <div className="img-container">
            <img
              src={cause.icon_url}
              onClick={() => {
                handleSelectCause(cause);
              }}
            />
          </div>
          <label
            onClick={() => {
              handleSelectCause(cause);
            }}
          >
            {cause.cause}
          </label>
        </li>
      );
    });
  }

  return <ul className="CauseSelectionStep">{renderCauses()}</ul>;
}
