import './RightHeader.scss';
import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { getUnreadChatCount, OwnerType, typeHelpers } from '../../helpers';
import { Constants } from '@gigit/constants';
import logoKDark from '../../assets/logo-K-dark.svg';
import Share from '../Share/Share';
import { uiConstants } from '../../constants';
import { Config } from '@gigit/config';
import Modal from '../Modal/Modal';
import { IEventSummaryFE, IGroup, IHub } from '@gigit/interfaces';
import PopupMenu, { IPopupMenuItem, IShowPopupConfig } from '../shared/PopupMenu/PopupMenu';
import { IAppState, useAppSelector } from '../../store';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import UserMenu from '../UserMenu/UserMenu';
import { userSelectors } from '../../selectors/user';
import ContactOrganizerModal from '../ContactOrganizerModal/ContactOrganizerModal';

interface IProps extends RouteComponentProps<any> {
  userHasEditPermission: boolean;
  objectType: string;
  objectItem: IHub | IEventSummaryFE | IGroup;

  // hub
  openManageComponentsModal?: () => void;
  openThemesModal?: () => void;
  openInviteUsersModal?: () => void;
  runEditMode?: () => void;
  showHeaderEditMenu?: boolean;
  showUserMenuModal: boolean;
  setShowUserMenuModal: (newValue: boolean) => void;
}

const RightHeader: React.FC<IProps> = (props) => {
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [showOrganizerModal, setShowOrganizerModal] = useState<boolean>(false);

  const userState = useSelector((state: IAppState) => userSelectors.getUserState(state));
  const cartState = useAppSelector((state) => state.cartState);
  const notificationState = useAppSelector((state) => state.notificationsState);
  const chatState = useAppSelector((state) => state.chatState);

  // hub
  const [showEditMenu, setShowEditMenu] = useState<boolean>(false);

  useEffect(() => {
    props.showHeaderEditMenu != undefined && setShowEditMenu(props.showHeaderEditMenu || false);
  }, [props.showHeaderEditMenu]);

  const editContextMenuItems: IPopupMenuItem[] = [
    {
      id: 'Edit-Components',
      label: 'Edit Components',
      isSelected: false,
      onClick: () => props?.runEditMode?.(),
      icon: 'far fa-pen',
    },
    {
      id: 'Manage-Components',
      label: 'Manage Components',
      isSelected: false,
      onClick: () => props?.openManageComponentsModal?.(),
      icon: 'far fa-align-justify',
    },
    {
      id: 'Themes',
      label: 'Themes',
      isSelected: false,
      onClick: () => props?.openThemesModal?.(),
      icon: 'fas fa-palette',
    },
    {
      id: 'Hub-Management',
      label: 'Company Management',
      isSelected: false,
      onClick: () => props.history.replace({ pathname: props.location.pathname + '/admin' }),
      icon: 'far fa-cog',
    },
    {
      id: 'Invite-Users',
      label: 'Invite Members',
      isSelected: false,
      onClick: () => props?.openInviteUsersModal?.(),
      icon: 'far fa-user-plus',
    },
  ];

  const editPopupMenuConfig: IShowPopupConfig = {
    showMenu: showEditMenu,
    setShowMenu: setShowEditMenu,
    position: {
      type: 'bottom',
    },
  };

  const unreadChatCount = getUnreadChatCount(chatState.conversations, userState.user?.id || '');

  const getShareLink = () => {
    const objectType =
      props.objectType === Constants.object_type.hub ? 'company' : props.objectType;
    return `${Config.web.REACT_APP_BASE_URL}/${objectType}/${props.objectItem.handle}`;
  };

  return (
    <>
      <div className="RightHeader">
        <div className="not-mobile">
          <div
            className="rh-menu-item"
            onClick={() => setShowOrganizerModal(true)}
          >
            <div id="edit-icon-container">
              <i className="far fa-envelope" />
            </div>
          </div>
          {props.userHasEditPermission &&
            userState.isLoggedIn &&
            props.objectType === Constants.object_type.hub && (
              <div
                className="rh-menu-item"
                onMouseOver={() => setShowEditMenu(true)}
              >
                <PopupMenu
                  showMenuConfig={editPopupMenuConfig}
                  menuItems={editContextMenuItems}
                  popupMenuClass="PopupMenuHubEdit"
                  className={`Dropdown filter-dropdown ${showEditMenu ? 'show' : ''}`}
                  onSelect={() => setShowEditMenu(false)}
                  onClick={() => {}}
                >
                  {
                    <div id="edit-icon-container">
                      <i className="far fa-pen" />
                    </div>
                  }
                </PopupMenu>
              </div>
            )}
          {cartState.items.length > 0 && (
            <Link
              className="rh-menu-item with-txt-info cart"
              to="/checkout"
            >
              <div className="count">
                {cartState.totalItems > uiConstants.notificationLimit
                  ? uiConstants.overNotificationLimitTxt
                  : cartState.totalItems}
              </div>
              <i className="fas fa-shopping-cart" />
              <span className="txt-info">Cart</span>
            </Link>
          )}
          {userState.isLoggedIn && unreadChatCount > 0 && (
            <Link
              className="rh-menu-item with-txt-info chat"
              to="/dashboard?section=activity&tab=network"
            >
              <div className="count">
                {unreadChatCount > uiConstants.notificationLimit
                  ? uiConstants.overNotificationLimitTxt
                  : unreadChatCount}
              </div>
              <i className="fas fa-comment" />
              <span className="txt-info">Messages</span>
            </Link>
          )}
          <span
            className="rh-menu-item with-txt-info"
            onClick={() => setShowShareModal(true)}
          >
            <i className="far fa-share-alt" />
            <span className="txt-info">Share Company</span>
          </span>
          <Link
            className="rh-menu-item with-txt-info"
            to="/"
          >
            <img
              alt="Kambeo"
              src={logoKDark}
            />
            <span className="txt-info">Home</span>
          </Link>

          {props.objectType === Constants.object_type.hub &&
            (props.objectItem?.contact_details?.email ||
              props.objectItem?.contact_details?.phone) &&
            !props.userHasEditPermission && (
              <span className="rh-menu-item with-txt-info">
                <i
                  className="far fa-envelope"
                  onClick={() => setShowContactModal(true)}
                />
                <span className="txt-info">Contact Company Admin</span>
              </span>
            )}
          <div className="user-container rh-menu-item">
            <UserMenu
              mobileShow={props.showUserMenuModal}
              object_type={uiConstants.ownerType.hub}
              hubProps={{
                email: props.objectItem?.contact_details?.email,
                phone: props.objectItem?.contact_details?.phone,
                userHasEditPermission: props.userHasEditPermission,
                setShowShareModal: (v) => setShowShareModal(v),
                setShowContactModal: (v) => setShowContactModal(v),
              }}
            />
          </div>
        </div>

        <div className="mobile">
          <div className="rh-menu-item">
            <div
              className={`menu-icon ${props.showUserMenuModal && 'open'}`}
              onClick={() => props.setShowUserMenuModal(!props.showUserMenuModal)}
            >
              <span /> <span /> <span /> <span />
            </div>
          </div>
        </div>
      </div>

      <Modal
        class="share-modal"
        show={showShareModal}
        onClose={() => setShowShareModal(false)}
      >
        <Share
          owner_object={typeHelpers.createOwnerObject(
            props.objectType as OwnerType,
            props.objectItem,
          )}
          url={getShareLink()}
        />
      </Modal>

      <Modal
        closeIcon="fas fa-times"
        show={showContactModal}
        title="Contact Us"
        onClose={() => setShowContactModal(false)}
        class="contact-modal"
      >
        <div className="contact-content-container">
          {props.objectItem.contact_details?.phone && (
            <div>
              <i className="fas fa-phone" />
              <a href={`tel:${props.objectItem?.contact_details?.phone}`}>
                {props.objectItem?.contact_details?.phone}
              </a>
            </div>
          )}
          {props.objectItem?.contact_details?.email && (
            <div>
              <i className="fas fa-envelope" />
              <a href={`mailto:${props.objectItem?.contact_details?.email}`}>
                {props.objectItem?.contact_details?.email}
              </a>
            </div>
          )}
        </div>
        <Button
          text="Cancel"
          onClick={() => {
            setShowContactModal(false);
          }}
        />
      </Modal>

      <ContactOrganizerModal
        object_type={props.objectType}
        object_id={props.objectItem.id || ''}
        show={showOrganizerModal}
        email={props.objectItem.contact_details?.email}
        phone={props.objectItem.contact_details?.phone}
        onClose={() => setShowOrganizerModal(false)}
      />
    </>
  );
};

export default withRouter(RightHeader);
