import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import {
  routes,
  swapRouteParams,
  formatCurrency,
  defaultCurrency,
  toastError,
  capitalizeString,
} from '../../../helpers';
import { IUserRole, IPledge } from '@gigit/interfaces';
import { IGroupState } from '../../../reducers/group';
import SortableTable, { ISortableTableColumn } from '../../SortableTable/SortableTable';
import Modal from '../../Modal/Modal';
import AddPledge from '../AddPledge/AddPledge';
import { Prompt } from '../../Prompt/Prompt';
import ActivityLog from '../ActivityLog/ActivityLog';
import { createToast } from '../../../actions/toaster';
import { IAppState } from '../../../store';

import './ContactPledge.scss';
import errorHelpers from '../../../helpers/errorHelpers';
import { IToast } from '../../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import { userSelectors } from '../../../selectors/user';

interface IProps extends WithTranslation, RouteComponentProps<any> {
  groupState: IGroupState;
  contact: IUserRole;
  loading?: boolean;
  locale: string;

  pledges: Array<IPledge>;
  refreshList(): void;
  createToast(toast: IToast): void;
}

interface IState {
  columns: ISortableTableColumn[];
  showEdit: boolean;
  pledge: IPledge | undefined;
  showApply: boolean;
  pledgeToDelete: string;
  showActivityLog: boolean;
}

class ContactPledges extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      columns: [
        { label: 'Start Date', id: 'startDate', sortable: false },
        { label: 'End Date', id: 'endDate', sortable: false },
        { label: 'Pledge amount', id: 'pledgeAmt', sortable: false },
        { label: 'Donations Applied', id: 'applied', sortable: false },
        { label: 'Progress', id: 'progress', sortable: false },
        { label: 'Status', id: 'status', sortable: false },
        { label: 'Actions', id: 'actions', sortable: false },
      ],
      showEdit: false,
      pledge: undefined,
      showApply: false,
      pledgeToDelete: '',
      showActivityLog: false,
    };

    this.deletePledge = this.deletePledge.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  getProgressWidth(a: number, b: number) {
    if (a && b) {
      return Math.round((a / b) * 100) + '%';
    }

    return '0%';
  }

  convertTableData() {
    let currency = this.props.groupState.group.account?.currency || defaultCurrency;

    return this.props.pledges.map((d: IPledge) => {
      const startDate = d?.start_date ?? d?.recurrence_start_date ?? new Date();

      let startDateFormatted = localizeHelpers.formatDate(
        startDate,
        LocaleDateFormats.ll,
        this.props.locale,
      );
      let endDate = d?.end_date
        ? localizeHelpers.formatDate(d.end_date, LocaleDateFormats.ll, this.props.locale)
        : 'N/A';

      return {
        row: [
          { content: `${startDateFormatted}`, id: 'startDate', notranslate: 'yes' },
          { content: `${endDate}`, id: 'endDate', notranslate: 'yes' },
          {
            content: `${formatCurrency(d?.total_yearly_amount || 0, currency, this.props.locale)}`,
            id: 'pledgeAmt',
            notranslate: 'yes',
          },
          {
            content: `${localizeHelpers.formatNumber(d?.number_of_donations_applied || 0, this.props.locale)}`,
            id: 'applied',
            notranslate: 'yes',
          },
          {
            content: (
              <div className="progress-bar-wrap">
                <div className="progress-bar-bg">
                  <div
                    className="progress-bar"
                    style={{
                      width: this.getProgressWidth(
                        d?.amount_raised || 0,
                        d?.total_yearly_amount || 0,
                      ),
                    }}
                  ></div>
                  <div className="amt">
                    <var data-var="amount_raised">
                      {formatCurrency(d?.amount_raised || 0, currency, this.props.locale)}
                    </var>
                  </div>
                  <div className="goal">
                    <var data-var="total_yearly_amount">
                      {formatCurrency(d?.total_yearly_amount || 0, currency, this.props.locale)}
                    </var>
                  </div>
                </div>
                <span>
                  <var data-var="amount_raised">
                    {this.getProgressWidth(d?.amount_raised || 0, d?.total_yearly_amount || 0)}
                  </var>{' '}
                  {this.props.t('Raised')}
                </span>
              </div>
            ),
            id: 'progress',
          },
          { content: <span>{capitalizeString(d?.status?.code)}</span>, id: 'status' },
          {
            id: 'actions',
            menu: [
              {
                icon: 'far fa-file-alt',
                onClick: () => {
                  this.setState({ showActivityLog: true });
                },
                label: this.props.t('Activity Log'),
              },
              {
                icon: 'fa fa-pencil',
                onClick: () => {
                  this.handleEdit(d.id || '');
                },
                label: this.props.t('Edit'),
              },
              {
                icon: 'fa fa-trash',
                onClick: () => {
                  this.setState({ pledgeToDelete: d.id || '' });
                },
                label: this.props.t('Delete'),
              },
            ],
          },
        ],
      };
    });
  }

  handleEdit(id: string) {
    let pledge = this.props.pledges.find((p: IPledge) => {
      return id === p.id;
    });

    if (pledge) {
      this.setState(
        {
          pledge: pledge,
        },
        () => {
          this.setState({
            showEdit: true,
          });
        },
      );
    }
  }

  deletePledge(id: string) {
    let resetPledge = () => {
      this.setState({
        pledgeToDelete: '',
      });
    };
    axios
      .delete(
        swapRouteParams(routes.DELETE_PLEDGE, {
          groupId: this.props.groupState.group.id,
          userId: this.props.contact.user?.id,
          pledgeId: id,
        }),
      )
      .then(() => {
        resetPledge();
        this.props.refreshList();
      })
      .catch((error) => {
        resetPledge();
        const errorObj = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObj.translatedMessage, 'Delete Pledge');
        this.props.createToast(toast);
      });
  }

  onEditClose() {
    this.setState(
      {
        showEdit: false,
        pledge: undefined,
      },
      () => {
        if (this.props.contact?.user?.id) {
          this.props.refreshList();
        }
      },
    );
  }

  render() {
    let { t } = this.props;
    let data = this.convertTableData();
    let isDataEmpty = data.length === 0;

    return (
      <div className="ContactPledges">
        <SortableTable
          {...this.props}
          columns={this.state.columns}
          data={data}
          loading={this.props?.loading}
        />

        {isDataEmpty && (
          <div className="empty-data">{this.props.t('This user has yet to pledge.')}</div>
        )}

        <Prompt
          show={this.state.pledgeToDelete.length > 0}
          title={t('Delete Pledge')}
          message={t('')}
          yesMessage={t('Delete Pledge')}
          yesClass="fa fa-trash"
          yesStyle="delete"
          cancelMessage={t('Cancel')}
          onClose={() => this.setState({ pledgeToDelete: '' })}
          onYes={() => this.deletePledge(this.state.pledgeToDelete)}
        />

        <Modal
          class="add-field"
          show={this.state.showEdit}
          onClose={() => this.setState({ showEdit: false })}
        >
          <AddPledge
            {...this.props}
            onClose={() => {
              this.onEditClose();
            }}
            pledge={this.state.pledge}
          />
        </Modal>
        <Modal
          class="activity-log-modal"
          show={this.state.showActivityLog}
          onClose={() => this.setState({ showActivityLog: false })}
        >
          <ActivityLog {...this.props} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ContactPledges),
);
