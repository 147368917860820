import React, { FC, FormEvent } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { errorHelpers, formatCurrency } from '../../../helpers';
import { IEventState } from '../../../reducers/event';
import { IGroupState } from '../../../reducers/group';
import { IUserState } from '../../../reducers/user';
import queryString from 'query-string';
import { useLocale } from '../../../hooks';
import TextField from '../../../components/TextField/TextField';
import { IOwnerObject } from '../../../interfaces';
import {
  IAvailableDonationMatchingProgram,
  IGlobalGivingActiveProjectsReturn,
  IGlobalGivingPaymentDetailsRequest,
  IGroup,
} from '@gigit/interfaces';
import Dropdown, { IOptions } from '../../../components/Dropdown/Dropdown';
import { localizeHelpers } from '../../../localizeHelpers';
import { localeConstants } from '../../../constants';

import './GlobalGivingDonateForm.scss';

import useScript from '../../../hooks/useScript';
import { groupRequestActions, hubRequestActions } from '../../../requestActions';
import Button from '../../../components/Button/Button';
import useToastDispatcher from '../../../hooks/useToaster';
import { Config } from '@gigit/config';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { Constants } from '@gigit/constants';
import DonationMatchingProgramDetails from '../DonateForm/DonationMatchingProgramDetails/DonationMatchingProgramDetails';

interface IProps extends RouteComponentProps<any> {
  userState: IUserState;
  groupState: IGroupState;
  eventState: IEventState;
  locale: string;
  hubOwnerGroup: IGroup | null;
  owner: IOwnerObject;
  warning?: {
    show: boolean;
    text: string;
  };
}

interface DonationAmount {
  amount: number;
  description: string;
}

interface DonationDetails {
  [index: string]: string;
}

declare const braintree: any;

const GlobalGivingDonateForm: FC<IProps> = (props: IProps) => {
  const braintreeConfig = {
    styles: {
      input: {
        'font-size': '16px',
        'font-family': 'courier, monospace',
        'font-weight': 'lighter',
        color: '#ccc',
      },
      ':focus': {
        color: 'black',
      },
      '.valid': {
        color: '#8bdda8',
      },
    },
    fields: {
      number: {
        selector: '#ggCardNumber',
        placeholder: '4111 1111 1111 1111',
      },
      cvv: {
        selector: '#ggCardCvv',
        placeholder: '123',
      },
      expirationDate: {
        selector: '#ggCardExpiration',
        placeholder: 'MM/YYYY',
      },
      postalCode: {
        selector: '#ggCardPostal',
        placeholder: '11111',
      },
    },
  };

  const initialDetails = {
    firstName: '',
    lastName: '',
    displayName: '',
    email: '',
    phone: '',
    address: '',
    address2: '',
    country: 'CA',
    province: 'Ontario',
    city: '',
    postal: '',
    message: '',
  };

  const locale = useLocale();

  // TODO: Right now only USD used for GG donations.
  const availableCurrency = 'USD';

  const user: IUserState = useSelector((state: IAppState) => state.userState);

  const defaultDonationAmounts: DonationAmount[] = [
    { amount: 25, description: '' },
    { amount: 50, description: '' },
    { amount: 100, description: '' },
    { amount: 250, description: '' },
    { amount: 500, description: '' },
  ];

  const { dispatchToastError, dispatchToastSuccess } = useToastDispatcher();
  const [donationAmount, setDonationAmount] = useState<number>(0);
  const [customDonationAmount, setCustomDonationAmount] = useState<number>(0);
  const [embedDefaultColours, setEmbedDefaultColours] = useState<string[]>([]);
  const [embedHoverColours, setEmbedHoverColours] = useState<string[]>([]);
  const [donationDetails, setDonationDetails] = useState<DonationDetails>(initialDetails);
  const [currency, setCurrency] = useState<string>(availableCurrency);
  const [hostedFields, setHostedFields] = useState<any>();
  const [availableProjects, setAvailableProjects] = useState<IOptions[]>([]);
  const [availableProjectsFull, setAvailableProjectsFull] = useState<
    IGlobalGivingActiveProjectsReturn[]
  >([]);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [reviewHover, setReviewHover] = useState<boolean>(false);
  const [donationAmounts, setDonationAmounts] = useState<DonationAmount[]>(defaultDonationAmounts);
  const [successRedirect, setSuccessRedirect] = useState<string>('');
  const [donationLoading, setDonationLoading] = useState<boolean>(false);
  const [donationMatchingPrograms, setDonationMatchingPrograms] = useState<
    IAvailableDonationMatchingProgram[]
  >([]);
  const [selectedDonationMatchingProgram, setSelectedDonationMatchingProgram] =
    useState<IAvailableDonationMatchingProgram>();
  const [matchedAmount, setMatchedAmount] = useState<number>(0);
  const [donationSubmitted, setDonationSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (user && user.isLoggedIn) {
      setDonationDetails({
        ...donationDetails,
        firstName: user.user.first_name,
        lastName: user.user.last_name,
        email: user.user.email,
        phone: user.user.phone || '',
      });
      getAvailableDonationMatchingPrograms();
    }
  }, [user]);

  useEffect(() => {
    if (props.owner) {
      groupRequestActions
        .getGGProjects(props.owner.ownerId)
        .then((response) => {
          if (response.length > 0) {
            let options = response.map((option) => {
              return {
                label: option.title,
                value: option.id.toString(),
              };
            });
            options.push({
              label: 'Select a project',
              value: '',
            });
            setAvailableProjects(options as IOptions[]);
            setAvailableProjectsFull(response);
          }
        })
        .catch((error) => {
          dispatchToastError(error, 'Global Giving Donation');
        });
    }
  }, [props.owner]);

  useEffect(() => {
    let params = queryString.parse(props.location.search);
    setEmbedDefaultColours(
      params.default && typeof params.default === 'string' ? params.default.split(',') : [],
    );
    setEmbedHoverColours(
      params.hover && typeof params.hover === 'string' ? params.hover.split(',') : [],
    );
    setDonationAmount(donationAmounts[0].amount);
  }, [props.location.search]);

  // Using the external libraries used in the GG example
  const statusClient = useScript('https://js.braintreegateway.com/web/3.6.2/js/client.js');
  const statusFields = useScript('https://js.braintreegateway.com/web/3.6.2/js/hosted-fields.js');

  useEffect(() => {
    if (statusClient === 'ready' && statusFields === 'ready') {
      braintree.client.create(
        {
          authorization: Config.web.REACT_APP_GLOBAL_GIVING_PAYMENT_GATEWAY_KEY,
        },
        (error: any, instance: any) => {
          if (error) {
            const errorObj = errorHelpers.getErrorObject(error);
            dispatchToastError(errorObj.translatedMessage, 'GlobalGiving Donate');
            return;
          }
          braintree.hostedFields.create(
            {
              client: instance,
              styles: braintreeConfig.styles,
              fields: braintreeConfig.fields,
            },
            function (hostedFieldsErr: any, hostedFieldsInstance: any) {
              if (hostedFieldsErr) {
                const errorObj = errorHelpers.getErrorObject(hostedFieldsErr);
                dispatchToastError(errorObj.translatedMessage, 'GlobalGiving Donate');
                return;
              }
              setHostedFields(hostedFieldsInstance);
            },
          );
        },
      );
    }
  }, [statusClient, statusFields]);

  function handleDonation(e: FormEvent) {
    e.preventDefault();
    setDonationLoading(true);
    hostedFields.tokenize((tokenizeErr: any, payload: any) => {
      if (tokenizeErr) {
        const errorObj = errorHelpers.getErrorObject(tokenizeErr);
        dispatchToastError(errorObj.translatedMessage, 'GlobalGiving Donate');
        setDonationLoading(false);
        return;
      }

      const nonce = payload.nonce;

      let donationPayload: IGlobalGivingPaymentDetailsRequest = {
        amount: donationAmount || customDonationAmount,
        email: donationDetails.email,
        project: {
          id: parseInt(selectedProject),
        },
        payment_detail: {
          firstname: donationDetails.firstName,
          lastname: donationDetails.lastName,
          address: donationDetails.address,
          address2: donationDetails.address2,
          city: donationDetails.city,
          state: donationDetails.province,
          iso3166CountryCode: donationDetails.country,
          postalCode: donationDetails.postal,
          paymentGatewayNonce: nonce,
          phone: donationDetails.phone,
        },
        currencyCode: availableCurrency,
      };

      if (selectedDonationMatchingProgram) {
        let program: IAvailableDonationMatchingProgram | undefined = getSelectedProgram();
        if (program) {
          donationPayload.donation_match = {
            object_id: program.owner_id,
            donation_matching_program_id: program.id,
            params: { amount_matched: matchedAmount },
          };
        }
      }

      groupRequestActions
        .postGGDonation(props.owner.ownerId, donationPayload)
        .then((resp) => {
          dispatchToastSuccess(
            localizeHelpers.translate('You have successfully donated {{sum}} to {{name}}.', {
              name: props.owner.ownerTitle,
              sum: formatCurrency(donationPayload.amount, currency, locale.currentLocale),
            }),
            'Global Giving Donation',
          );
          if (user.isLoggedIn) {
            setSuccessRedirect('/dashboard?section=activity&tab=transactions');
          } else {
            setSuccessRedirect('/');
          }
          setDonationLoading(false);
        })
        .catch((error) => {
          setDonationLoading(false);
          dispatchToastError(error, 'Global Giving Donation');
        });
    });
  }

  function handleDonationAmounts(id: string) {
    const project = availableProjectsFull.find((project) => project.id == parseInt(id));

    if (project?.donationOptions.length) {
      // Limit the suggested donation amounts to 6 because of the layout restrictions.

      let validOptions = project?.donationOptions.filter((donationOpt) => donationOpt.amount >= 10);
      if (validOptions.length > 6) {
        setDonationAmounts(validOptions.slice(0, 5));
      } else {
        setDonationAmounts(validOptions);
      }
      setDonationAmount(validOptions[0].amount);
    } else {
      setDonationAmounts(defaultDonationAmounts);
      setDonationAmount(defaultDonationAmounts[0].amount);
    }
  }

  async function getAvailableDonationMatchingPrograms() {
    const donationMatchingResponse = await hubRequestActions.getAvailableDonationMatchingPrograms(
      Constants.donation_matching_program_type.donation_match,
      {
        donation_amount: donationAmount,
        group_id: props.owner.ownerId,
      },
    );
    const programsForGroup = donationMatchingResponse.filter((program) =>
      program.allowed_groups?.find((allowedGroups) => allowedGroups === props.owner.ownerId),
    );

    if (programsForGroup.length > 0) {
      setDonationMatchingPrograms(programsForGroup);
      setSelectedDonationMatchingProgram(programsForGroup[0]);
    } else {
      setDonationMatchingPrograms(donationMatchingResponse);
      setSelectedDonationMatchingProgram(donationMatchingResponse[0]);
    }
  }

  function getSelectedProgram() {
    return donationMatchingPrograms.find(
      (program) => program.id === selectedDonationMatchingProgram?.id,
    );
  }

  function getDonationMatchingProgramOptions() {
    return donationMatchingPrograms.map((program) => ({
      label: program.name,
      value: program.id,
    }));
  }

  return (
    <div className="DonateForm">
      {successRedirect && <Redirect to={successRedirect} />}
      <form
        onSubmit={(e: FormEvent) => {
          handleDonation(e);
        }}
      >
        <div className="DonateForm-inner">
          {props?.warning?.show && availableProjects.length < 1 && (
            <div className="donate-warning">
              <i className="fas fa-exclamation-triangle" />
              <p>{props?.warning?.text}</p>
            </div>
          )}

          <div className="form-inputs">
            <Dropdown
              shouldSort={true}
              value={selectedProject}
              required={true}
              notranslate="yes"
              label="Available Projects"
              onChange={(e) => {
                setSelectedProject(e.target.value);
                handleDonationAmounts(e.target.value);
              }}
              name="project"
              options={availableProjects}
            />
          </div>
          <div className="label">Donation Amount</div>
          <div className="options single-line no-tip">
            {donationAmounts.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setDonationAmount(item.amount);
                    setCustomDonationAmount(0);
                  }}
                  className={item.amount === donationAmount ? 'option active' : 'option'}
                >
                  <div
                    className="option-inner"
                    notranslate="yes"
                    style={
                      item.amount === donationAmount && embedDefaultColours.length === 3
                        ? { border: '2px solid #' + embedDefaultColours[2] }
                        : {}
                    }
                  >
                    <span>{formatCurrency(item.amount, currency, locale.currentLocale)}</span>
                  </div>
                </div>
              );
            })}

            <div
              onClick={() => {
                setDonationAmount(-1);
              }}
              className={donationAmount === -1 ? 'option other active' : 'option other'}
            >
              <div
                className="option-inner"
                style={
                  customDonationAmount && embedDefaultColours.length === 3
                    ? { backgroundColor: '#' + embedDefaultColours[2] }
                    : {}
                }
              >
                $
              </div>
              <TextField
                min="10"
                value={customDonationAmount || ''}
                placeholder="Other"
                name="customDonationAmount"
                type="number"
                onChange={(e) => setCustomDonationAmount(parseInt(e.target.value))}
                required={donationAmount === -1}
                style={
                  customDonationAmount === donationAmount && embedDefaultColours.length === 3
                    ? { border: '1px solid #' + embedDefaultColours[2] }
                    : {}
                }
              />
            </div>
          </div>
          {Config.feature_flags.HUB_DONATION_MATCHING &&
            donationMatchingPrograms &&
            donationMatchingPrograms.length > 0 && (
              <React.Fragment>
                <div className="sub-title">Donation Matching</div>
                <div className="label">Donation Matching Program</div>
                <Dropdown
                  value={selectedDonationMatchingProgram}
                  onChange={(e) => {
                    setSelectedDonationMatchingProgram(
                      donationMatchingPrograms.find((program) => program.id === e.target.value)!,
                    );
                  }}
                  name="selectedDonationMatchingProgram"
                  options={getDonationMatchingProgramOptions()}
                />
                {getSelectedProgram() && (
                  <DonationMatchingProgramDetails
                    currency={currency}
                    program={getSelectedProgram()!}
                    donationAmount={donationAmount}
                    matchedAmount={matchedAmount}
                    setMatchedAmount={(v) => setMatchedAmount(v)}
                  />
                )}
              </React.Fragment>
            )}
          <div className="sub-title">Donor Details</div>
          <div className="form-inputs">
            <TextField
              label="First Name"
              required={true}
              value={donationDetails.firstName}
              name="firstName"
              type="text"
              onChange={(e) =>
                setDonationDetails({ ...donationDetails, firstName: e.target.value })
              }
            />
            <TextField
              label="Last Name"
              required={true}
              value={donationDetails.lastName}
              name="lastName"
              type="text"
              onChange={(e) => setDonationDetails({ ...donationDetails, lastName: e.target.value })}
            />
          </div>
          <div className="form-inputs">
            <TextField
              label="Email"
              required={true}
              value={donationDetails.email}
              name="email"
              type="email"
              onChange={(e) => setDonationDetails({ ...donationDetails, email: e.target.value })}
            />
            <TextField
              label="Phone"
              required={true}
              value={donationDetails.phone}
              name="phone"
              type="tel"
              onChange={(e) => setDonationDetails({ ...donationDetails, phone: e.target.value })}
            />
          </div>
          <div className="form-inputs">
            <TextField
              label="Address"
              required={true}
              value={donationDetails.address}
              name="address"
              type="text"
              onChange={(e) => setDonationDetails({ ...donationDetails, address: e.target.value })}
            />
          </div>
          <div className="form-inputs">
            <TextField
              label="Address Line 2"
              required={false}
              value={donationDetails.address2}
              name="address2"
              type="text"
              onChange={(e) => setDonationDetails({ ...donationDetails, address2: e.target.value })}
            />
          </div>
          <div className="form-inputs">
            <Dropdown
              shouldSort={true}
              value={donationDetails.country}
              notranslate="yes"
              label="Country"
              onChange={(e) => {
                setDonationDetails({ ...donationDetails, country: e.target.value });
              }}
              name="country"
              options={localeConstants.allCountryOptions}
            />
            {(donationDetails.country === 'CA' || donationDetails.country === 'US') && (
              <Dropdown
                value={donationDetails.province}
                label="Province/State"
                notranslate="yes"
                shouldSort={true}
                onChange={(e) => {
                  setDonationDetails({ ...donationDetails, province: e.target.value });
                }}
                name="province"
                options={
                  donationDetails.country === 'CA'
                    ? localeConstants.canadianProvinceOptions
                    : donationDetails.country === 'US'
                      ? localeConstants.usStateOptions
                      : [{ value: 'N/A', label: 'N/A' }]
                }
              />
            )}
          </div>
          <div className="form-inputs">
            <TextField
              label="City"
              required={true}
              value={donationDetails.city}
              name="city"
              type="text"
              onChange={(e) => setDonationDetails({ ...donationDetails, city: e.target.value })}
            />
            <TextField
              label="Postal/ZIP Code"
              required={true}
              value={donationDetails.postal}
              name="postal"
              type="text"
              onChange={(e) => setDonationDetails({ ...donationDetails, postal: e.target.value })}
            />
          </div>
          <div
            className={
              donationDetails.message.length >= 260
                ? 'form-inputs details-last long-message'
                : 'form-inputs details-last'
            }
          >
            <TextField
              label={localizeHelpers.translate('Message ({{message_length}}/280)', {
                message_length: donationDetails.message.length,
              })}
              translateLabel={false}
              value={donationDetails.message}
              name="message"
              type="text"
              maxLength={280}
              onChange={(e) => setDonationDetails({ ...donationDetails, message: e.target.value })}
            />
          </div>
          <form
            className="payment-form"
            id="ggPaymentForm"
            method="POST"
          >
            <input
              type="hidden"
              name="paymentNonce"
              id="paymentNonce"
            />
            <div id="paymentInfo">
              <input
                hidden={true}
                type="text"
                name="donation.project.id"
                className="local-field"
                id="project"
                value={selectedProject}
                onChange={() => {}}
              />

              <input
                hidden={true}
                type="text"
                name="donation.amount"
                className="local-field"
                id="amount"
                value={donationAmount || customDonationAmount}
              />

              <input
                hidden={true}
                type="text"
                name="donation.payment_detail.firstname"
                className="local-field"
                id="firstName"
                value={donationDetails.firstName}
              />

              <input
                hidden={true}
                type="text"
                name="donation.payment_detail.lastname"
                className="local-field"
                id="lastName"
                value={donationDetails.lastname}
              />

              <input
                value={donationDetails.email}
                hidden={true}
                type="text"
                name="donation.email"
                className="local-field"
                id="email"
              />

              <label className="hosted-fields--label">Card Number</label>
              <div
                id="ggCardNumber"
                className="hosted-field card-no"
              />

              <label className="hosted-fields--label">Expiration Date</label>
              <div
                id="ggCardExpiration"
                className="hosted-field exp-date"
              />

              <label className="hosted-fields--label">CVV</label>
              <div
                id="ggCardCvv"
                className="hosted-field cvv"
              />

              <label className="hosted-fields--label">Postal Code</label>
              <div
                id="ggCardPostal"
                className="hosted-field postal"
              />
            </div>
          </form>
        </div>
        <div className="DonateForm-actions">
          <Button
            isDisabled={props?.warning?.show}
            onMouseEnter={() => {
              setReviewHover(true);
            }}
            onMouseLeave={() => {
              setReviewHover(false);
            }}
            loading={donationLoading}
            type="submit"
            text="Donate"
            style={
              embedDefaultColours.length === 3 && embedHoverColours.length == 3
                ? !reviewHover
                  ? {
                      backgroundColor: '#' + embedDefaultColours[2],
                      color: '#' + embedDefaultColours[1],
                      border: '2px solid #' + embedDefaultColours[0],
                    }
                  : {
                      backgroundColor: '#' + embedHoverColours[2],
                      color: '#' + embedHoverColours[1],
                      border: '2px solid #' + embedHoverColours[0],
                    }
                : {}
            }
          />
          {props?.warning?.show && availableProjects.length < 1 && (
            <span className="donate-warning">
              <i className="fas fa-exclamation-triangle" />
            </span>
          )}
        </div>
      </form>
    </div>
  );
};

export default GlobalGivingDonateForm;
