import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  errorHelpers,
  handleInputChange,
  toastError,
  toastSuccess,
  typeHelpers,
} from '../../helpers';
import { createToast } from '../../actions/toaster';
import { userSelectors } from '../../selectors/user';
import TextField from '../TextField/TextField';
import Portrait from '../Portrait/Portrait';
import { localizeHelpers } from '../../localizeHelpers';
import { IGroupState } from '../../reducers/group';
import './AddMemberModal.scss';
import { IEventState } from '../../reducers/event';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { IInvite, IInviteCreateInfo, IRole } from '@gigit/interfaces';
import { IOwnerObject, ReduxActionType } from '../../interfaces';
import Dropdown from '../Dropdown/Dropdown';
import { inviteRequestActions, roleRequestActions } from '../../requestActions';
import { uiConstants } from '../../constants';

interface IPassedProps extends RouteComponentProps<any, any, any> {
  owner: IOwnerObject;
  showAddMemberModal: boolean;
  toggleShowAddMemberModal: (newToggleValue: boolean) => void;
  objectType: 'event' | 'group' | 'hub';
  noModal?: boolean;
  isHubOnboarding?: boolean;
  hubSeatsAvailable?: number;
}

interface IPropsFromState {
  eventState: IEventState;
  groupState: IGroupState;
  locale: string;
}

interface IPropsFromDispatch {
  createToast: ReduxActionType<typeof createToast>;
}

type IProps = IPassedProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  searchConnectionsValue: string;
  showSearchMemberList: boolean;
  invites: IInvite[];
  invitesPending: number;
  invitesSent: number;
  roles: IRole[];
  selectedRoleId: string | null;
  showModal: boolean;
}

class AddMemberModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchConnectionsValue: '',
      showSearchMemberList: false,
      invites: [],
      invitesPending: 0,
      invitesSent: 0,
      roles: [],
      selectedRoleId: null,
      showModal: this.props.showAddMemberModal,
    };
  }

  componentDidMount() {
    this.getInvites();
    this.fetchRoles();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.state.showSearchMemberList &&
      prevState.showSearchMemberList != this.state.showSearchMemberList
    ) {
      this.getInvites();
    }

    if (this.props.showAddMemberModal !== prevProps.showAddMemberModal) {
      this.setState({ showModal: this.props.showAddMemberModal });
    }
  }

  getInvites = async () => {
    try {
      const inviteResponse = await inviteRequestActions.getInvites(
        this.props.owner.ownerType,
        this.props.owner.ownerId,
      );
      this.setState({
        invites: inviteResponse?.data || [],
      });
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Get Roles');
      this.props.createToast(toast);
    }
  };

  async fetchRoles() {
    try {
      const roleResponse = await roleRequestActions.getRoles(
        this.props.owner.ownerType,
        this.props.owner.ownerId,
      );
      this.setState({
        roles: roleResponse?.data || [],
        selectedRoleId:
          roleResponse && roleResponse.data.length > 0 ? roleResponse.data[0].id! : null,
      });
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Get Roles');
      this.props.createToast(toast);
    }
  }

  inviteMember = async () => {
    try {
      let emailArray: string[] = [];

      let emailValidate = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');

      if (this.state.searchConnectionsValue.indexOf(';') !== -1) {
        emailArray = this.state.searchConnectionsValue.split(';');
        for (let email of emailArray) {
          email = email.trim();
          if (!emailValidate.test(email)) {
            const error = localizeHelpers.translate('{{email}} is not a valid email', { email });
            const toast = toastError(error, 'Invite');
            this.props.createToast(toast);
            return;
          }
        }
      } else {
        if (!emailValidate.test(this.state.searchConnectionsValue)) {
          const error = localizeHelpers.translate('{{email}} is not a valid email', {
            email: this.state.searchConnectionsValue,
          });
          const toast = toastError(error, 'Invite');
          this.props.createToast(toast);
          return;
        }
      }

      let payload: IInviteCreateInfo[] = [];
      if (emailArray.length) {
        for (let email of emailArray) {
          if (email) {
            payload.push({
              email,
              role_id: this.state.selectedRoleId ?? undefined,
            });
          }
        }
      } else {
        payload = [
          {
            email: this.state.searchConnectionsValue,
            role_id: this.state.selectedRoleId ?? undefined,
          },
        ];
      }

      await inviteRequestActions.createInvite(
        this.props.owner.ownerType,
        this.props.owner.ownerId,
        {
          invites: payload,
        },
      );

      this.getInvites();

      this.setState({
        searchConnectionsValue: '',
      });
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Invite Member');
      this.props.createToast(toast);
    }
  };

  cancelInvite = async (inviteId: string) => {
    try {
      await inviteRequestActions.cancelInvite(
        this.props.owner.ownerType,
        this.props.owner.ownerId,
        inviteId,
      );
      this.getInvites();
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Cancel Invite');
      this.props.createToast(toast);
    }
  };

  sendInviteReminder = async (invite: IInvite) => {
    typeHelpers.assertNotNullOrUndefined(invite.id);

    try {
      await inviteRequestActions.sendEventInviteReminder(
        this.props.owner.ownerType,
        this.props.owner.ownerId,
        invite.id,
      );
      this.getInvites();
      const toast = toastSuccess(
        localizeHelpers.translate('Invitation Reminder Successfully Sent'),
        'Invitation Reminder',
      );
      this.props.createToast(toast);
    } catch (err) {
      const errObj = errorHelpers.getErrorObject(err);
      const toast = toastError(errObj.translatedMessage, 'Send Invitation Reminder');
      this.props.createToast(toast);
    }
  };

  renderContactRow = (invite?: IInvite, isPlaceholder?: boolean) => {
    const { user } = invite || {};

    if (!isPlaceholder && invite) {
      return (
        <div
          key={user?.id}
          className="contact-row"
        >
          <div className="portrait">
            <Portrait
              currentImage={user?.profile_image_url}
              size={40}
            />
          </div>
          <div className="contact-info">
            <span
              className="name"
              notranslate="yes"
            >
              {user?.display_name ?? localizeHelpers.translate('No Name Provided')}
            </span>
            <div className="description">
              <p
                className="email"
                notranslate="yes"
              >
                {invite.email} -{' '}
              </p>
              <p className="status">{invite?.status?.code}</p>
              {invite?.status?.code !== 'accepted' && (
                <a
                  className="reminder"
                  onClick={() => this.sendInviteReminder(invite)}
                >
                  {` - Send a reminder`}
                </a>
              )}
            </div>
          </div>
          {invite?.status?.code === 'pending' ? (
            <Button
              className="action-button"
              buttonType="secondary"
              onClick={() => this.cancelInvite(invite.id!)}
              text="Cancel"
            />
          ) : (
            <Button
              className="action-button"
              buttonType="primary"
              isDisabled={invite?.status?.code === 'accepted'}
              onClick={this.inviteMember}
              text="Send"
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="contact-row">
          <div className="portrait">
            <Portrait
              currentImage={user?.profile_image_url}
              size={40}
            />
          </div>
          <div className="contact-info">
            <div className="description">
              <span
                className="email"
                notranslate="yes"
              >
                {this.state.searchConnectionsValue}
              </span>
            </div>
          </div>
          <Button
            className="action-button"
            buttonType="primary"
            onClick={this.inviteMember}
            text="Send"
          />
        </div>
      );
    }
  };

  renderSearchList = () => {
    const { invites: contactRows, searchConnectionsValue } = this.state;

    if (searchConnectionsValue.length > 0) {
      const foundContacts = contactRows
        .filter(
          (row) =>
            row.user?.display_name
              ?.toLocaleLowerCase()
              .includes(searchConnectionsValue.toLowerCase()) ||
            row.user?.email?.toLocaleLowerCase().includes(searchConnectionsValue.toLowerCase()),
        )
        .map((row) => this.renderContactRow(row));

      if (foundContacts.length > 0) {
        return foundContacts;
      } else {
        //user doesn't exist, so return placeholder invite row
        return this.renderContactRow(undefined, true);
      }
    }
  };

  getInviteCount = (filter: 'pending' | 'all'): number => {
    return this.state.invites.filter((invite) => {
      return filter === 'pending' ? invite.status?.code === filter : true;
    }).length;
  };

  render() {
    const { toggleShowAddMemberModal } = this.props;

    let seatsAvailable;
    if (this.props.owner.ownerType === uiConstants.ownerType.hub) {
      seatsAvailable = this.props.hubSeatsAvailable;
    }

    return this.props.noModal ? (
      <div className="AddMemberModal no-modal">
        <div className="header-container">
          {!this.props.isHubOnboarding && (
            <>
              <span className="title">Invite Contacts</span>
              <span className="description">
                An email will be sent asking them to join your
                {this.props.objectType === 'group'
                  ? 'Cause'
                  : this.props.objectType === 'event'
                    ? 'Event'
                    : 'Hub'}
                <div>Invite multiple contacts separating them with a semicolon</div>
              </span>
            </>
          )}
          <div className="confirmed-invites-container">
            {this.props.owner.ownerType === uiConstants.ownerType.hub ? (
              <>
                <span notranslate="yes">{seatsAvailable}</span> seats Available
              </>
            ) : (
              <>
                <var data-var="send_count">
                  {localizeHelpers.formatNumber(this.getInviteCount('all') || 0, this.props.locale)}
                </var>{' '}
                Sent |{' '}
                <var data-var="pending_count">
                  {localizeHelpers.formatNumber(
                    this.getInviteCount('pending') || 0,
                    this.props.locale,
                  )}
                </var>{' '}
                Pending
              </>
            )}
          </div>
          <div className="search-container">
            <TextField
              icon="fas fa-search"
              placeholder="Search connections / invite by email..."
              value={this.state.searchConnectionsValue}
              type="email"
              name="searchConnectionsValue"
              onChange={(e: any) => handleInputChange(e, this)}
              postTextRenderer={() => {
                return (
                  <div className="role-dropdown-container">
                    <span className="invite-as-label">Invite as</span>
                    <Dropdown
                      inputWrapClassName="role-dropdown"
                      name="role"
                      options={this.state.roles.map((role) => ({
                        label: role.role_name,
                        value: role.id ?? '',
                      }))}
                      value={this.state.selectedRoleId}
                      onChange={(e) => this.setState({ selectedRoleId: e.target.value })}
                    />
                  </div>
                );
              }}
            />
            <div className="search-list">{this.renderSearchList()}</div>
          </div>
        </div>
        <div className="contact-list">
          {this.state.invites.map((row) => this.renderContactRow(row))}
        </div>
      </div>
    ) : (
      <Modal
        class="MemberAddModal-Con"
        show={this.state.showModal}
        onClose={() => toggleShowAddMemberModal(false)}
        closeIcon="fas fa-times"
      >
        <div className="AddMemberModal">
          <div className="header-container">
            <span className="title">Invite Members</span>
            <span className="description">
              {'An email will be sent asking them to join your '}
              {this.props.objectType === 'group'
                ? 'Cause'
                : this.props.objectType === 'event'
                  ? 'Event'
                  : 'Company'}
              <div>
                Invite multiple members separating them with a semicolon.
                {this.props.objectType === 'hub' &&
                  ' A seat will automatically be purchased when the person accepts the invite.'}
              </div>
            </span>
            <div className="confirmed-invites-container margin-top">
              {this.props.owner.ownerType === uiConstants.ownerType.hub ? (
                <>
                  <span notranslate="yes">{seatsAvailable}</span> seats Available
                </>
              ) : (
                <>
                  <var data-var="send_count">
                    {localizeHelpers.formatNumber(
                      this.getInviteCount('all') || 0,
                      this.props.locale,
                    )}
                  </var>{' '}
                  Sent |{' '}
                  <var data-var="pending_count">
                    {localizeHelpers.formatNumber(
                      this.getInviteCount('pending') || 0,
                      this.props.locale,
                    )}
                  </var>{' '}
                  Pending
                </>
              )}
            </div>
            <div className="search-container">
              <TextField
                icon="fas fa-search"
                placeholder="Search connections / invite by email..."
                value={this.state.searchConnectionsValue}
                type="email"
                name="searchConnectionsValue"
                onChange={(e: any) => handleInputChange(e, this)}
                postTextRenderer={() => {
                  return (
                    <div className="role-dropdown-container">
                      <span className="invite-as-label">Invite as</span>
                      <Dropdown
                        inputWrapClassName="role-dropdown"
                        name="role"
                        options={this.state.roles.flatMap((role) =>
                          role.role_name == 'Supporter'
                            ? []
                            : {
                                label: role.role_name,
                                value: role.id ?? '',
                              },
                        )}
                        value={this.state.selectedRoleId}
                        onChange={(e) => this.setState({ selectedRoleId: e.target.value })}
                      />
                    </div>
                  );
                }}
              />
              <div className="search-list">{this.renderSearchList()}</div>
            </div>
          </div>
          <div className="contact-list">
            {this.state.invites.map((row) => this.renderContactRow(row))}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: IAppState): IPropsFromState => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  createToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddMemberModal));
